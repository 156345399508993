import cn from 'classnames';
import * as React from 'react';
import ResizeDetector from 'react-resize-detector';
import { isFunction } from 'underscore';

import BemCssTransition from 'components/BemCssTransition';
import { episodeListBlock as block } from '../../utils';
import EpisodeListRowContext from './EpisodeListRowContext';

const { useCallback, useContext, useState } = React;

export interface RenderProps {
  entered: boolean;
}

export interface EpisodeListRowContentsProps {
  children?: React.ReactNode | ((props: RenderProps) => React.ReactNode);
  className?: string;
}

/**
 * what appears in the expanded view of the row (the sub-section below the main
 * row content)
 */
const EpisodeListRowContents: React.FC<EpisodeListRowContentsProps> = ({
  children,
  className,
}) => {
  const { active, setContentsHeight } = useContext(EpisodeListRowContext);
  const [entered, setEntered] = useState(false);

  const handleResize = useCallback(
    (_, height) => {
      setContentsHeight(height);
    },
    [setContentsHeight],
  );

  const handleClick = useCallback(e => e.stopPropagation(), []);

  const handleEntered = useCallback(() => setEntered(true), []);
  const handleExit = useCallback(() => setEntered(false), []);

  return (
    <BemCssTransition
      className={cn(block('row-contents'), className)}
      in={active}
      mountOnEnter
      onEntered={handleEntered}
      onExit={handleExit}
      timeout={300}
      transitionClassName={block('row-contents-transition')}
      unmountOnExit
    >
      <div onClick={handleClick}>
        <ResizeDetector handleHeight onResize={handleResize}>
          <div className={block('row-contents-inner')}>
            {!isFunction(children) ? children : children({ entered })}
          </div>
        </ResizeDetector>
      </div>
    </BemCssTransition>
  );
};

export default EpisodeListRowContents;
