import cn from 'classnames';
import * as React from 'react';

import { authCardBlock as block } from '../utils';

export interface AuthCardProps {
  children?: React.ReactNode;
  className?: string;
  footer?: JSX.Element;
  footerClassName?: string;
  title?: string;
}

const AuthCard: React.SFC<AuthCardProps> = ({
  children,
  className,
  footer,
  footerClassName,
  title,
}) => (
  <div className={cn(block(), className)}>
    {title && <div className={block('title')}>{title}</div>}
    {children}
    {footer && (
      <div className={cn(block('footer'), footerClassName)}>{footer}</div>
    )}
  </div>
);

export default AuthCard;
