import * as React from 'react';

import { Step } from '../../types';
import StepItem from '../StepItem';

const EditorPageSteps: Step[] = [
  {
    content: (
      <StepItem
        content="Add some text and customize it to look how you want!"
        videoSrc="https://static.sparemin.com/static/webapp-assets/onboarding/videos/20190201/add-text-asset.mp4"
      />
    ),
    name: 'addText',
    placement: 'top-start',
    target: '.timeline__tracks .timeline__track--text',
  },
  {
    content: (
      <StepItem
        content="Add an image, video or GIF to your project."
        videoSrc="https://static.sparemin.com/static/webapp-assets/onboarding/videos/20190201/add-media-asset.mp4"
      />
    ),
    name: 'addMedia',
    placement: 'top-start',
    target: '.timeline__tracks .timeline__track--media',
  },
  {
    content: (
      <StepItem
        content="Add some audio to your project. Turn on the waveform after it's been added."
        videoSrc="https://static.sparemin.com/static/webapp-assets/onboarding/videos/20190201/enable-waveform.mp4"
      />
    ),
    name: 'addAudio',
    placement: 'top-start',
    target: '.timeline__tracks .timeline__track--audio',
  },
  {
    content: (
      <StepItem
        content="Edit your transcript here."
        videoSrc="https://static.sparemin.com/static/webapp-assets/onboarding/videos/20190201/edit-caption.mp4"
      />
    ),
    name: 'editTranscript',
    placement: 'right-start',
    target: '.video-editor__assets-pane',
  },
  {
    content: (
      <StepItem
        content="Hit play to preview your video."
        videoSrc="https://static.sparemin.com/static/webapp-assets/onboarding/videos/20190201/play-preview.mp4"
      />
    ),
    name: 'clickPreview',
    placement: 'right-start',
    target: '.media-controls__play',
  },
  {
    content: (
      <StepItem
        content="When you're done, hit export to get your finished video!"
        videoSrc="https://static.sparemin.com/static/webapp-assets/onboarding/videos/20190201/export-download-video.mp4"
      />
    ),
    name: 'clickExport',
    placement: 'left-start',
    target: '.playbar__export-button',
  },
];

export default EditorPageSteps;
