import { isNumber } from 'underscore';
import bem from 'utils/bem';
import { CropInfo, Dimension } from './types';

const block = bem('video-cropper');

function calculateDimension(
  videoWidth: number,
  videoHeight: number,
  workspaceWidth: number,
  workspaceHeight: number,
  zoom: number,
): Dimension {
  const widthRatio = workspaceWidth / videoWidth;
  const heighRatio = workspaceHeight / videoHeight;
  const fitRatio = Math.min(widthRatio, heighRatio);
  const ratio = fitRatio * zoom;

  return { width: ratio * videoWidth, height: ratio * videoHeight };
}

function calculateCropInfo(
  { videoWidth, videoHeight }: HTMLVideoElement,
  workspaceWidth: number,
  workspaceHeight: number,
  zoom: number,
  top?: number,
  left?: number,
  prevZoom?: number,
): CropInfo {
  const dimension = calculateDimension(
    videoWidth,
    videoHeight,
    workspaceWidth,
    workspaceHeight,
    zoom,
  );

  let newTop = isNumber(top) ? top : undefined;
  let newLeft = isNumber(left) ? left : undefined;
  if (isNumber(prevZoom)) {
    const prevDimension = calculateDimension(
      videoWidth,
      videoHeight,
      workspaceWidth,
      workspaceHeight,
      prevZoom,
    );
    newTop = isNumber(top)
      ? top - (dimension.height - prevDimension.height) / 2
      : undefined;
    newLeft = isNumber(left)
      ? left - (dimension.width - prevDimension.width) / 2
      : undefined;
  }
  const widthDiff = dimension.width - workspaceWidth;
  const heightDiff = dimension.height - workspaceHeight;

  const initialTop = -heightDiff / 2;
  const initialLeft = -widthDiff / 2;

  return {
    dimension,
    axis: 'both',
    initialPosition: {
      left: isNumber(newLeft) ? newLeft : initialLeft,
      top: isNumber(newTop) ? newTop : initialTop,
    },
  };
}

function calculateFillZoom(
  aspectRatio: number,
  videoWidth: number,
  videoHeight: number,
) {
  const videoAspectRatio = videoWidth / videoHeight;
  if (videoAspectRatio < aspectRatio) {
    return (videoHeight / videoWidth) * aspectRatio;
  }

  if (videoAspectRatio > aspectRatio) {
    return videoWidth / videoHeight / aspectRatio;
  }

  return 1;
}

export { block, calculateCropInfo, calculateFillZoom };
