import { soundwaveResolved } from 'redux/modules/embed/actions/soundwave';
import { recordingsSelector } from 'redux/modules/entities/selectors';
import { ThunkAction } from 'redux/types';
import reduxPoll from 'utils/redux-poll';
import { WAVEFORM_POLL_ID } from '../constants';
import { cancelPolling } from './core';

export const cancelWaveformPolling = (
  pollId: string = WAVEFORM_POLL_ID,
): ThunkAction<void> => dispatch => dispatch(cancelPolling(pollId));

export const getRecordingWaveform = (
  recordingId: number,
): ThunkAction<Promise<any>> => (dispatch, getState) => {
  const recordings = recordingsSelector(getState());
  const versionId = recordings.getIn([recordingId.toString(), 'versionId']);

  const pollingFn = () =>
    dispatch(soundwaveResolved(versionId)).then(
      ({ response }) => response.isResolved,
    );

  return reduxPoll(dispatch, pollingFn, {
    id: WAVEFORM_POLL_ID,
    intervalMillis: spareminConfig.waveformPollIntervalMillis,
    maxAttempts: spareminConfig.waveformPollMaxAttempts,
    shouldContinue: (err, isResolved) => !err && !isResolved,
  });
};
