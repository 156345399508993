import * as React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import CancellationDetailsModalContent from './CancellationDetailsModalContent';
import { block } from './utils';

const CancellationDetailsModal = () => {
  return (
    <ConnectedModal
      name="CancelReasonDetails"
      title="Cancel my plan"
      className={block()}
    >
      <CancellationDetailsModalContent />
    </ConnectedModal>
  );
};

export default CancellationDetailsModal;
