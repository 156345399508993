export enum Type {
  INSTAGRAM_ACCOUNTS_GET_REQUEST = 'app/social/instagram/ACCOUNTS_GET_REQUEST',
  INSTAGRAM_ACCOUNTS_GET_SUCCESS = 'app/social/instagram/ACCOUNTS_GET_SUCCESS',
  INSTAGRAM_ACCOUNTS_GET_FAILURE = 'app/social/instagram/ACCOUNTS_GET_FAILURE',

  INSTAGRAM_ACCOUNTS_CLEAR = 'app/social/instagram/ACCOUNTS_CLEAR',

  INSTAGRAM_USER_ID_SELECT = 'app/social/instagram/INSTAGRAM_USER_ID_SELECT',

  INSTAGRAM_USER_DATA_GET_REQUEST = 'app/social/linkedin/INSTAGRAM_USER_DATA_GET_REQUEST',
  INSTAGRAM_USER_DATA_GET_SUCCESS = 'app/social/linkedin/INSTAGRAM_USER_DATA_GET_SUCCESS',
  INSTAGRAM_USER_DATA_GET_FAILURE = 'app/social/linkedin/INSTAGRAM_USER_DATA_GET_FAILURE',

  INSTAGRAM_VIDEO_POST_REQUEST = 'app/social/instagram/VIDEO_POST_REQUEST',
  INSTAGRAM_VIDEO_POST_SUCCESS = 'app/social/instagram/VIDEO_POST_SUCCESS',
  INSTAGRAM_VIDEO_POST_FAILURE = 'app/social/instagram/VIDEO_POST_FAILURE',

  INSTAGRAM_VIDEO_POST_AWAIT_REQUEST = 'app/social/instagram/VIDEO_POST_AWAIT_REQUEST',
  INSTAGRAM_VIDEO_POST_AWAIT_SUCCESS = 'app/social/instagram/VIDEO_POST_AWAIT_SUCCESS',
  INSTAGRAM_VIDEO_POST_AWAIT_FAILURE = 'app/social/instagram/VIDEO_POST_AWAIT_FAILURE',

  INSTAGRAM_ACCOUNT_INFO_REQUEST = 'app/social/instagram/INSTAGRAM_ACCOUNT_INFO_REQUEST',
  INSTAGRAM_ACCOUNT_INFO_SUCCESS = 'app/social/instagram/INSTAGRAM_ACCOUNT_INFO_SUCCESS',
  INSTAGRAM_ACCOUNT_INFO_FAILURE = 'app/social/instagram/INSTAGRAM_ACCOUNT_INFO_FAILURE',

  INSTAGRAM_AUTHORIZE_SUCCESS = 'app/social/instagram/AUTHORIZE_SUCCESS',
}
