import { Heading } from '@sparemin/blockhead';
import * as React from 'react';

import NewProjectSelector from 'blocks/ProjectsPage/components/NewProjectSelector';

import ProjectsPageSection from 'blocks/ProjectsPage/components/ProjectsPageSection';
import ProjectTemplates from 'blocks/ProjectTemplates';

import { WaveIcon } from 'components/icons';
import CreateTabBannerSlot from '../containers/CreateTabBannerSlot';
import { createTabBlock as block } from '../utils';
import {
  AudiogramButton,
  AutomationButton,
  BlankProjectButton,
  TranscribeButton,
  VideoTranscriptionButton,
} from './buttons';
import { ProjectButtonProps } from './buttons/types';
import CreateTabCard from './CreateTabCard/CreateTabCard';

export interface CreateTabProps {
  onNewProjectClick?: ProjectButtonProps['onClick'];
}

const CreateTab: React.FC<CreateTabProps> = ({ onNewProjectClick }) => {
  const buttonProps = {
    className: block('project-button'),
    onClick: onNewProjectClick,
    sizes: {
      md: true,
      lg: false,
    },
  };

  return (
    <ProjectsPageSection
      className={block()}
      contentsClassName={block('contents')}
    >
      <CreateTabBannerSlot />

      <Heading level={1} className={block('title')}>
        What shall we create today?
      </Heading>

      <NewProjectSelector>
        <CreateTabCard.Contents className={block('create-cards')}>
          <AudiogramButton
            {...buttonProps}
            description="Turn audio content into videos with a waveform to share across the web"
            icon={<WaveIcon />}
            title="Manual audiograms"
            projectType="manual"
            data-joyride-tooltip="manual"
          />

          <AutomationButton
            className="automation-button"
            {...buttonProps}
            data-joyride-tooltip="automation"
          />

          <TranscribeButton className="transcribe-button" {...buttonProps} />

          <VideoTranscriptionButton {...buttonProps} />

          <BlankProjectButton {...buttonProps} />
        </CreateTabCard.Contents>
      </NewProjectSelector>
      <ProjectTemplates />
    </ProjectsPageSection>
  );
};

export default CreateTab;
