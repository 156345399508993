import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { prefix } from 'utils/ui';
import { block } from './utils';

interface IProps {
  className?: string;
  color?: string;
  domRef?: (el: HTMLElement) => void;
  onClick?: () => void;
}

const Swatch: React.SFC<IProps> = ({ className, color, domRef, onClick }) => (
  <div
    className={cn(block('swatch'), className)}
    ref={domRef}
    onClick={onClick}
  >
    <div className={block('swatch-inner')}>
      <div
        className={block('swatch-inner-color-overlay')}
        style={prefix({ backgroundColor: color })}
        data-testid="color-picker-swatch"
      />
    </div>
  </div>
);

Swatch.defaultProps = {
  onClick: noop,
};

export default Swatch;
export { IProps as SwatchProps };
