import * as React from 'react';
import { isFunction } from 'underscore';
import LinkButton from 'components/LinkButton';

export type LinkProps = {
  href: string;
  target: string;
  rel: string;
  disabled?: boolean;
};
type ChildrenCallback = (props: LinkProps) => React.ReactNode;

interface Props extends Pick<LinkProps, 'href' | 'disabled'> {
  children: React.ReactNode | ChildrenCallback;
}

const BillingHistoryLinkView: React.FC<Props> = props => {
  const { children, href, disabled } = props;

  const linkProps: LinkProps = {
    href,
    target: '_blank',
    rel: 'noopener noreferrer',
    disabled,
  };

  if (isFunction(children)) {
    return <>{children(linkProps)}</>;
  }

  return (
    <LinkButton {...linkProps} theme="cta">
      {children}
    </LinkButton>
  );
};

BillingHistoryLinkView.displayName = 'BillingHistoryLinkView';

export default BillingHistoryLinkView;
