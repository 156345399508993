import React from 'react';
import { useDispatch } from 'react-redux';

import { ButtonTheme } from 'components/Button';
import DialogContent, { ButtonProps } from 'components/DialogContent';
import { hideModal } from 'redux/modules/modal';

import { block } from './FakeFeatureModalUtils';

interface FakeFeatureModalContentsProps {
  acceptButtonTheme?: ButtonTheme;
  contentTitle?: string;
  description?: string;
  icon?: React.ReactNode;
  mainActionText?: string;
}

const FakeFeatureModalContents: React.FunctionComponent<FakeFeatureModalContentsProps> = props => {
  const {
    acceptButtonTheme = 'submit',
    contentTitle,
    description,
    icon,
    mainActionText,
  } = props;

  const dispatch = useDispatch();

  const handleClose = React.useCallback(
    (params: ButtonProps) => {
      const { action = 'declined' } = params ?? {};
      dispatch(hideModal(action));
    },
    [dispatch],
  );

  return (
    <DialogContent
      actions={[
        {
          children: 'no thanks',
          key: 'negative-cta',
          params: { action: 'declined' },
          theme: 'submit-alt',
        },
        {
          children: mainActionText,
          key: 'positive-cta',
          params: { action: 'accepted' },
          theme: acceptButtonTheme,
        },
      ]}
      details={description}
      detailsClassName={block('details')}
      icon={icon}
      onClose={handleClose}
      title={contentTitle}
    />
  );
};

export default FakeFeatureModalContents;
