import * as React from 'react';

import TextareaAutosize from 'react-textarea-autosize';
import DialogRadioContent, { ActionProps } from 'components/DialogRadioContent';
import { H3 } from 'components/Heading';
import IconButton from 'components/IconButton';
import { ArrowRight } from 'components/icons';
import Modal from 'components/Modal';
import { PlanCancellationReason } from 'types';
import { Props } from './types';
import useCancellationDetailsModalState from './useCancellationDetailsModalState';
import { block } from './utils';

interface Option {
  value: string;
  label: string;
}

type ReasonDetailsQuestion =
  | {
      type: 'select';
      title: string;
      options: Option[];
    }
  | {
      type: 'text';
      title: string;
      placeholder: string;
    };

const questions: Record<PlanCancellationReason, ReasonDetailsQuestion> = {
  [PlanCancellationReason.DONT_RUN_OUT_OF_VIDEOS]: {
    type: 'select',
    title: 'How many do you usually make a month?',
    options: [
      '20 videos or less per month',
      '15 videos or less per month',
      '10 videos or less per month',
      '5 videos or less per month',
      'Zero videos a month',
    ].map(value => ({ value, label: value })),
  },
  [PlanCancellationReason.ANOTHER_VIDEO_EDITOR]: {
    type: 'text',
    title: 'What video editor do you use instead?',
    placeholder: 'i.e. Adobe After Effects',
  },
  [PlanCancellationReason.NOT_ENOUGH_FEATURES]: {
    type: 'text',
    title: 'What features are missing?',
    placeholder: 'Please suggest anything you can imagine',
  },
  [PlanCancellationReason.CANT_AFFORD]: {
    type: 'select',
    title: 'Sorry to hear, want to just pause your plan?',
    options: [
      {
        label: 'Pause my plan for this month',
        value: '1 Month',
      },
      {
        label: 'Pause my plan for the next three months',
        value: '3 Months',
      },
      {
        label: 'No thanks, just cancel my plan completely',
        value: 'Cancel Plan',
      },
    ],
  },
  [PlanCancellationReason.FILE_NOT_SUPPORTED]: {
    type: 'select',
    title: 'Which problem was most common?',
    options: [
      {
        label: 'My file sizes are often larger than accepted',
        value: 'File Size',
      },
      {
        label: 'My video/audio clips are often longer than accepted',
        value: 'File Length',
      },
      {
        label: 'My usual file type is not accepted',
        value: 'File Type',
      },
    ],
  },
  [PlanCancellationReason.OTHER]: {
    type: 'text',
    title: 'How can we improve Headliner Pro?',
    placeholder: 'Please suggest anything you can imagine',
  },
  [PlanCancellationReason.TECHNICAL_PROBLEMS]: {
    type: 'select',
    title: 'Which issue was most frustrating?',
    options: [
      {
        label: 'It takes too long to export videos',
        value: 'SlowExports',
      },
      {
        label: 'The overall experience is too slow',
        value: 'SlowProduct',
      },
      {
        label: 'I encountered too many errors',
        value: 'TooManyErrors',
      },
      {
        label: 'The overall experience is not reliable enough',
        value: 'UnreliableOverall',
      },
    ],
  },
};

const CancellationDetailsModalContent: React.FC<Props> = props => {
  const { reason } = props;
  const {
    details,
    disabled,
    onDetailsChange,
    onSubmit,
    onBack,
  } = useCancellationDetailsModalState(props);
  const question = questions[reason];

  if (!question) {
    return null;
  }

  const title = (
    <H3>
      <IconButton className={block('back-button')} onButtonClick={onBack}>
        <ArrowRight className={block('back-arrow')} />
      </IconButton>
      {question.title}
    </H3>
  );

  const footerButtonProps: ActionProps = {
    children:
      reason === PlanCancellationReason.CANT_AFFORD
        ? 'Submit'
        : 'Cancel my plan',
    disabled: disabled || !details,
    fluid: true,
    key: 'submit',
    theme: 'submit',
    onClick: onSubmit,
  };

  if (question.type === 'select') {
    return (
      <DialogRadioContent
        actions={[footerButtonProps]}
        label={title}
        onChange={onDetailsChange}
        options={question.options}
        value={details}
      />
    );
  }

  return (
    <>
      <Modal.Body className={block('body')}>
        {title}
        <TextareaAutosize
          autoFocus
          className={block('input')}
          minRows={3}
          placeholder={question.placeholder}
          value={details}
          onChange={event => {
            onDetailsChange(event.target.value);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButton {...footerButtonProps} />
      </Modal.Footer>
    </>
  );
};

export default CancellationDetailsModalContent;
