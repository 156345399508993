import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import DowngradeUpsellContents from './DowngradeUpsellContents';
import { block } from './utils';

export interface DowngradeUpsellModalProps {}

const DowngradeUpsellModal: React.FC<DowngradeUpsellModalProps> = () => (
  <ConnectedModal
    className={block()}
    name="DowngradeUpsellModal"
    title="headliner basic"
  >
    <DowngradeUpsellContents />
  </ConnectedModal>
);

export default DowngradeUpsellModal;
