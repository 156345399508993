import { useCallback, useMemo, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { noop } from 'underscore';
import {
  AutoPostOptions,
  DestinationSteps,
} from 'blocks/AutomationOptions/types';
import { EpisodeOption } from 'blocks/AutomationWorkflowWizard/components/EpisodesStep/types';
import { AutogramStartingPointValue } from 'blocks/AutomationWorkflowWizard/types';
import { CaptionsConfig } from 'blocks/TextOverlayModal/v2';
import {
  TemplateEditorStateExport,
  VideoTemplateState,
} from 'components/VideoTemplateEditor';
import Wizard, { Navigator } from 'components/Wizard';
import {
  DESTINATION_ID_TO_MIXPANEL_DESTINATION_MAP,
  VIDEO_TYPE_TO_MIXPANEL_VIDEO_TYPE_MAP,
} from 'containers/AudiogramDestinationModal/constants';
import { AddPodcastStepProps } from 'containers/ProjectWizards/AddPodcastStep';
import useFacebookAutomationAuthenticator from 'hooks/useFacebookAutomationAuthenticator';
import useInstagramAutomationAuthenticator from 'hooks/useInstagramAutomationAuthenticator';
import useLinkedInAutomationAuthenticator from 'hooks/useLinkedInAutomationAuthenticator';
import useOnMount from 'hooks/useOnMount';
import useThreadsAutomationAuthenticator from 'hooks/useThreadsAutomationAuthenticator';
import useTikTokAutomationAuthenticator from 'hooks/useTikTokAutomationAuthenticator';
import useTwitterAutomationAuthenticator from 'hooks/useTwitterAutomationAuthenticator';
import useYouTubeAutomationAuthentication from 'hooks/useYouTubeAutomationAuthenticator';
import {
  FacebookPostType,
  InstagramMediaType,
} from 'redux/middleware/api/podcast-service';

import {
  clearAutomationTemplates,
  createWorkflow,
  pollForWorkflowPreview,
  previewWorkflow,
  updateWorkflow,
} from 'redux/modules/automation-cms';
import {
  isPollingSubscriptionPreviewSelector,
  seasonsSelector,
  subscriptionPreviewDataSelector,
} from 'redux/modules/automation-cms/selectors';

import { checkIsAutoPostPlatform } from 'redux/modules/automation-cms/utils';
import {
  podcastsSelector,
  podcastSubscriptionsSelector,
} from 'redux/modules/entities';
import {
  onCompleteWizard,
  onPreviewAutomation,
  onWizardNext,
} from 'redux/modules/mixpanel';
import { getMixpanelBlurValue } from 'redux/modules/mixpanel/utils';
import { showNotification } from 'redux/modules/notification/actions';
import { clearSearchResults } from 'redux/modules/podcast-search';
import { goToCreate } from 'redux/modules/router';
import {
  facebookPagesSelector,
  facebookUserDataSelector,
} from 'redux/modules/social';
import { Dispatch } from 'redux/types';
import {
  AspectRatioName,
  AutogramSubscriptionOptions,
  DeepPartial,
} from 'types';
import { omitUndefined } from 'utils/collections';
import merge from 'utils/deepmerge';
import { getBlurRadius } from 'utils/embed/embed';
import { getNormalizedSeasonsList } from '../../AutogramSeasonsStep/SeasonsSelector';
import {
  DEFAULT_LANGUAGE,
  DEFAULT_WIZARD_STATE,
  FACEBOOK_POST_TYPE_MAP,
  INSTAGRAM_MEDIA_TYPE_MAP,
  MIXPANEL_WIZARD_STEP_MAP,
  STEPS,
} from '../constants';
import {
  AutogramWizardStep,
  AutogramWizardSubscriptionOptions,
  AutomationCustomizeStepDraftState,
  DestinationOptions,
  UseAutomationWorkflowResult,
  UseAutomationWorkflowWizardConfig,
} from '../types';
import {
  checkViewIsAutoPosting,
  getAspectRatioNameFromSubscription,
  getMixpanelLength,
  getOptionsFromSubscription,
  initializeDestinationOptions,
} from '../utils';
import reducer from './reducer';

// Describes the overloaded function that handles changes to the subscription options.
// If the strategy is "overwrite", the entire options argument is required, however
// if the strategy is "merge", then a partial object can be provided since it will
// be merged into the subscription in state.
export interface OnSubscriptionOptionsChange {
  (options: AutogramSubscriptionOptions, strategy?: 'overwrite'): void;
  (options: DeepPartial<AutogramSubscriptionOptions>, strategy: 'merge'): void;
}

const subscriptionSelector = createSelector(
  podcastSubscriptionsSelector,
  (_, subscriptionId) => subscriptionId,
  (subscriptions, subscriptionId) => subscriptions?.get(String(subscriptionId)),
);

export default function(
  config: UseAutomationWorkflowWizardConfig = {},
): UseAutomationWorkflowResult {
  const { defaultPodcastId, defaultSubscriptionId } = config;
  const wizard = useRef<Wizard<AutogramWizardStep>>();
  const [customizeStepDraftState, setCustomizeStepDraftState] = useState<
    AutomationCustomizeStepDraftState
  >({});
  const {
    authenticate: authenticateYouTube,
  } = useYouTubeAutomationAuthentication();
  const {
    authenticate: authenticateTikTok,
  } = useTikTokAutomationAuthenticator();
  const {
    authenticate: authenticateInstagram,
  } = useInstagramAutomationAuthenticator();
  const {
    authenticate: authenticateLinkedIn,
  } = useLinkedInAutomationAuthenticator();
  const {
    authenticate: authenticateFacebook,
  } = useFacebookAutomationAuthenticator();
  const {
    authenticate: authenticateTwitter,
  } = useTwitterAutomationAuthenticator();
  const {
    authenticate: authenticateThreads,
  } = useThreadsAutomationAuthenticator();
  const subscription = useSelector(state =>
    subscriptionSelector(state, defaultSubscriptionId),
  );
  const seasons = useSelector(seasonsSelector);
  const pollingWorkflowPreview = useSelector(
    isPollingSubscriptionPreviewSelector,
  );
  const subscriptionPreviewConfig = useSelector(
    subscriptionPreviewDataSelector,
  )?.toJS();
  const facebookUser = useSelector(facebookUserDataSelector);
  const facebookPages = useSelector(facebookPagesSelector);

  const reduxDispatch = useDispatch<Dispatch>();
  const [state, dispatch] = useReducer(
    reducer,
    DEFAULT_WIZARD_STATE,
    defaultState => {
      const language =
        subscription?.get('subscriptionPodcastLanguage') || DEFAULT_LANGUAGE;
      const subscriptionOptions = getOptionsFromSubscription(subscription);
      const podcastId = defaultPodcastId || subscription?.get('podcastId');

      return merge(
        defaultState,
        // full episode subscription options from the backend might have clip
        // duration and other values set to null since they don't make sense for
        // that clip type.  omitUndefined will recurse through the object and remove
        // and null or undefined values so that undefined server values don't override
        // the UI defaults and cause issues
        omitUndefined(
          {
            destinationOptions: initializeDestinationOptions(subscription),
            episodeOption: undefined,
            language,
            podcastId,
            subscriptionOptions: {
              ...subscriptionOptions,
            },
            aspectRatioName: getAspectRatioNameFromSubscription(subscription),
            captions: {},
            transcription: {},
          },
          true,
        ),
      );
    },
  );

  const isAutopost = useMemo((): boolean => {
    const { automationOption, platform } = state.destinationOptions;

    return (
      automationOption === AutoPostOptions.AUTO_POST &&
      checkIsAutoPostPlatform(platform)
    );
  }, [state.destinationOptions]);

  const podcastName: string | undefined = useSelector(reduxState => {
    const podcasts = podcastsSelector(reduxState);
    return podcasts?.getIn([state.podcastId, 'title']);
  });

  useOnMount(
    noop,
    useCallback(() => {
      reduxDispatch(clearSearchResults());
      reduxDispatch(clearAutomationTemplates());
    }, [reduxDispatch]),
  );

  const onSubscriptionOptionsChange = useCallback<OnSubscriptionOptionsChange>(
    (options, strategy) => {
      dispatch({
        payload: { options, strategy: strategy ?? 'overwrite' },
        type: 'SUBSCRIPTION_OPTIONS_CHANGE',
      });
    },
    [],
  );

  const onAspectRatioChange = useCallback((name: AspectRatioName) => {
    dispatch({
      type: 'ASPECT_RATIO_SELECT',
      payload: name,
    });
  }, []);

  const onSubmitTemplateCustomization = useCallback(async () => {
    const { exportState } = customizeStepDraftState;

    if (!exportState) {
      throw new Error('Export data not available');
    }

    dispatch({ type: 'SUBMIT_REQUEST' });

    const mixpanelLength = getMixpanelLength(state.subscriptionOptions);
    let transcription;

    if (mixpanelLength === 'short') {
      transcription = state.subscriptionOptions.isCaptionEnabled;
    }

    reduxDispatch(
      onCompleteWizard({
        transcription,
        templateId: state.templateId,
        aspectRatio: state.aspectRatioName,
        autopostPlatform: state.subscriptionOptions.autopost.platform,
        frequency: state.subscriptionOptions.frequency,
        length: mixpanelLength,
        platform:
          DESTINATION_ID_TO_MIXPANEL_DESTINATION_MAP[
            state.destinationOptions.platform
          ],
        type: 'autoVideosMVP',
        clipSelectionMethod: state.subscriptionOptions.clipSelectionMethod,
        blurValue: getMixpanelBlurValue(getBlurRadius(exportState)),
        videoType:
          VIDEO_TYPE_TO_MIXPANEL_VIDEO_TYPE_MAP[
            state.destinationOptions.platformVideoType
          ],
        captions: state.transcription.transcribe,
      }),
    );

    const { subscriptionOptions } = state;
    const { autopost } = subscriptionOptions;

    const automationSubscriptionOptions: AutogramWizardSubscriptionOptions = {
      ...subscriptionOptions,
      autopost: {
        ...autopost,
        ...(autopost.platform === 'facebook' && {
          facebookPageId: facebookPages[0].pageId,
          facebookId: facebookUser.providerUserId,
        }),
      },
    };

    try {
      if (defaultSubscriptionId) {
        await reduxDispatch(
          updateWorkflow(
            state.aspectRatioName,
            defaultSubscriptionId,
            state.language,
            state.templateId,
            automationSubscriptionOptions,
            exportState,
          ),
        );
      } else {
        await reduxDispatch(
          createWorkflow(
            state.aspectRatioName,
            state.podcastId,
            state.language,
            state.templateId,
            automationSubscriptionOptions,
            exportState,
          ),
        );
      }

      wizard.current.jump('completed');
      dispatch({ type: 'SUBMIT_COMPLETE' });
    } catch (err) {
      reduxDispatch(
        showNotification({
          message: 'Please try again or {{link}} so we can help',
          code: 'ER013',
          level: 'error',
          dismissAfterSec: 5,
          type: 'help',
          title: "Sorry, we couldn't add that podcast.",
          actionLabel: 'contact us',
        }),
      );
    }
  }, [
    customizeStepDraftState,
    defaultSubscriptionId,
    facebookPages,
    facebookUser.providerUserId,
    reduxDispatch,
    state,
  ]);

  const onBackClick = useCallback(() => {
    wizard.current?.previous();
  }, []);

  const onPodcastClick: AddPodcastStepProps['onPodcastClick'] = useCallback(
    id => {
      dispatch({ type: 'PODCAST_SELECT', payload: { podcastId: id } });
      wizard.current?.next();
    },
    [],
  );

  const onStepChange = useCallback(
    async (to, from) => {
      dispatch({
        payload: { from, to },
        type: 'STEP_CHANGE',
      });

      // Editor state should be cleared when going to step different than preview or customize.
      // This allows keeping editor state saved when going back and forth between preview and
      // customize steps.
      if (to.stepId !== 'preview' && to.stepId !== 'customize') {
        setCustomizeStepDraftState({});
      }

      // Wizard next step is not fired when switching to preview or completed steps.
      if (
        from &&
        from.stepId !== 'preview' &&
        to.stepId !== 'completed' &&
        to.stepId !== 'preview'
      ) {
        reduxDispatch(
          onWizardNext({
            step: MIXPANEL_WIZARD_STEP_MAP[from.stepId],
            templateId: state.templateId,
            type: 'autoVideosMVP',
          }),
        );
      }

      if (
        STEPS.indexOf(from?.stepId) >= STEPS.indexOf('template') &&
        STEPS.indexOf(to.stepId) < STEPS.indexOf('template')
      ) {
        reduxDispatch(clearAutomationTemplates());
      }
    },
    [reduxDispatch, state.templateId],
  );

  const onGetPreviewConfig = useCallback(
    async (previewJobId: number) => {
      try {
        await reduxDispatch(pollForWorkflowPreview(previewJobId));
      } catch {
        reduxDispatch(
          showNotification({
            message: 'Please try again or {{link}} so we can help',
            code: 'ER040',
            level: 'error',
            dismissAfterSec: 5,
            type: 'help',
            title: "Sorry, we couldn't generate the preview.",
            actionLabel: 'contact us',
          }),
        );
        wizard.current?.previous();
      }
    },
    [reduxDispatch],
  );

  const onPreviewProject = useCallback(
    async (
      updatedExportState: TemplateEditorStateExport,
      updatedEditorState: VideoTemplateState,
      updatedIsTemplateDirty: boolean,
    ) => {
      try {
        dispatch({ type: 'PREVIEW_REQUEST' });

        // Dispatches the MP event for automation preview navigation
        reduxDispatch(onPreviewAutomation());

        const { subscriptionPreviewJobId } = await reduxDispatch(
          previewWorkflow(
            state.aspectRatioName,
            state.podcastId,
            state.templateId,
            updatedExportState,
          ),
        );

        dispatch({
          type: 'PREVIEW_COMPLETE',
          payload: { subscriptionPreviewJobId },
        });

        // Saves the current editor state to a draft state that will be commited only if
        // preview step is confirmed.
        setCustomizeStepDraftState({
          editorState: updatedEditorState,
          exportState: updatedExportState,
          isTemplateDirty: updatedIsTemplateDirty,
        });

        // Moves wizard step
        wizard.current?.next();

        onGetPreviewConfig(subscriptionPreviewJobId);
      } catch (err) {
        onBackClick();
        reduxDispatch(
          showNotification({
            message: 'Please try again or {{link}} so we can help',
            code: 'ER040',
            level: 'error',
            dismissAfterSec: 5,
            type: 'help',
            title: "Sorry, we couldn't generate the preview.",
            actionLabel: 'contact us',
          }),
        );
        dispatch({ type: 'PREVIEW_FAILURE' });
      }
    },
    [
      onBackClick,
      onGetPreviewConfig,
      reduxDispatch,
      state.aspectRatioName,
      state.podcastId,
      state.templateId,
    ],
  );

  const onTemplateSelect = useCallback(id => {
    dispatch({
      payload: { templateId: id },
      type: 'TEMPLATE_SELECT',
    });
    wizard.current?.next();
  }, []);

  const onTranscriptionChange = useCallback(
    (language?: string, transcribe?: boolean): void => {
      dispatch({
        type: 'TRANSCRIPTION_CHANGE',
        payload: {
          language,
          transcribe,
        },
      });
    },
    [],
  );

  const onCaptionsChange = useCallback((captions?: CaptionsConfig): void => {
    dispatch({
      type: 'CAPTIONS_CONFIG_UPDATE',
      payload: {
        captions,
      },
    });
  }, []);

  const onLanguageChange = useCallback(
    (language: string) => {
      dispatch({
        payload: language,
        type: 'LANGUAGE_CHANGE',
      });

      onTranscriptionChange(language, false);
    },
    [onTranscriptionChange],
  );

  const onTargetedSeasonsChange = useCallback((targetedSeasons: number[]) => {
    dispatch({
      payload: targetedSeasons,
      type: 'TARGETED_SEASONS_CHANGE',
    });
  }, []);

  const onCancel = useCallback(() => {
    reduxDispatch(goToCreate());
  }, [reduxDispatch]);

  const onStartingPointChange = useCallback(
    (newStartingPoint: AutogramStartingPointValue) => {
      dispatch({
        type: 'SUBSCRIPTION_OPTIONS_CHANGE',
        payload: {
          options: {
            frequency: newStartingPoint,
          },
          strategy: 'merge',
        },
      });
    },
    [],
  );

  const saveDestinationOptions = useCallback(
    (options: DestinationOptions): void => {
      const {
        autopostVideoToYouTube,
        platform,
        videoType,
        aspectRatioName,
        clipSelectionMethod,
        clipDuration,
      } = options;

      onSubscriptionOptionsChange(
        {
          ...(autopostVideoToYouTube && {
            autopost: {
              platform,
            },
          }),
          durationSeconds: clipDuration === undefined ? 60 : clipDuration,
          clipSelectionMethod,
          videoType,
        },
        'merge',
      );
      onAspectRatioChange(aspectRatioName);
    },
    [onAspectRatioChange, onSubscriptionOptionsChange],
  );

  const handleNextViewSettings = useCallback(
    (options: DestinationOptions): void => {
      const updatedOptions = { ...state.destinationOptions, ...options };

      if (updatedOptions.isDestinationOptionsComplete) {
        saveDestinationOptions(updatedOptions);
        wizard.current.next();
      }
    },
    [saveDestinationOptions, state.destinationOptions],
  );

  const onDestinationOptionsChange = useCallback(
    (options: DestinationOptions): void => {
      handleNextViewSettings(options);

      return dispatch({
        type: 'DESTINATION_OPTIONS_CHANGE',
        payload: {
          ...options,
        },
      });
    },
    [handleNextViewSettings],
  );

  const onEpisodeOptionChange = useCallback(
    (option: EpisodeOption): void => {
      if (option === 'onPublish') {
        onStartingPointChange(option);
        wizard.current.next();
      }

      return dispatch({
        type: 'EPISODE_OPTION_CHANGE',
        payload: option,
      });
    },
    [onStartingPointChange],
  );

  const checkShouldAuthenticate = useCallback((): boolean => {
    return (
      isAutopost && checkViewIsAutoPosting(state.destinationOptions?.stepView)
    );
  }, [isAutopost, state.destinationOptions]);

  const onEpisodesComplete = useCallback(() => {
    // If all episodes were selected, then targetedSeasons
    // can be reseted.
    if (
      state.subscriptionOptions.targetedSeasons?.length ===
      getNormalizedSeasonsList(seasons)?.length
    ) {
      onStartingPointChange('fromFirstEpisode');
      onTargetedSeasonsChange(undefined);
    } else {
      onStartingPointChange('targetedSeason');
    }

    wizard.current.next();
  }, [
    onStartingPointChange,
    onTargetedSeasonsChange,
    seasons,
    state.subscriptionOptions.targetedSeasons,
  ]);

  const handleDestinationSelectionFinished = useCallback((): void => {
    const { platformVideoType, stepView } = state.destinationOptions;

    if (
      platformVideoType === 'episode' ||
      stepView === DestinationSteps.CLIP_SELECTION
    ) {
      onDestinationOptionsChange({
        isDestinationOptionsComplete: true,
      });

      return;
    }

    onDestinationOptionsChange({
      stepView: DestinationSteps.CLIP_SELECTION,
    });
  }, [onDestinationOptionsChange, state.destinationOptions]);

  const handleYouTubeAuthentication = useCallback(async (): Promise<void> => {
    const result = await authenticateYouTube(podcastName, state.podcastId);

    if (result) {
      onSubscriptionOptionsChange(result, 'merge');
      handleDestinationSelectionFinished();
    }
  }, [
    authenticateYouTube,
    handleDestinationSelectionFinished,
    onSubscriptionOptionsChange,
    podcastName,
    state.podcastId,
  ]);

  const handleTikTokAuthentication = useCallback(async (): Promise<void> => {
    const result = await authenticateTikTok();

    if (result) {
      onSubscriptionOptionsChange(result, 'merge');
      handleDestinationSelectionFinished();
    }
  }, [
    authenticateTikTok,
    handleDestinationSelectionFinished,
    onSubscriptionOptionsChange,
  ]);

  const handleInstagramAuthentication = useCallback(
    async (mediaType: InstagramMediaType): Promise<void> => {
      const result = await authenticateInstagram(mediaType);

      if (result) {
        onSubscriptionOptionsChange(result, 'merge');
        handleDestinationSelectionFinished();
      }
    },
    [
      authenticateInstagram,
      handleDestinationSelectionFinished,
      onSubscriptionOptionsChange,
    ],
  );

  const handleLinkedInAuthentication = useCallback(async (): Promise<void> => {
    const result = await authenticateLinkedIn();

    if (result) {
      onSubscriptionOptionsChange(result, 'merge');
      handleDestinationSelectionFinished();
    }
  }, [
    authenticateLinkedIn,
    handleDestinationSelectionFinished,
    onSubscriptionOptionsChange,
  ]);

  const handleFacebookAuthentication = useCallback(
    async (postType: FacebookPostType): Promise<void> => {
      const result = await authenticateFacebook(postType);

      if (result) {
        onSubscriptionOptionsChange(result, 'merge');
        handleDestinationSelectionFinished();
      }
    },
    [
      authenticateFacebook,
      handleDestinationSelectionFinished,
      onSubscriptionOptionsChange,
    ],
  );

  const handleTwitterAuthentication = useCallback(async (): Promise<void> => {
    const result = await authenticateTwitter();

    if (result) {
      onSubscriptionOptionsChange(result, 'merge');
      handleDestinationSelectionFinished();
    }
  }, [
    authenticateTwitter,
    handleDestinationSelectionFinished,
    onSubscriptionOptionsChange,
  ]);

  const handleThreadsAuthentication = useCallback(async (): Promise<void> => {
    const result = await authenticateThreads();

    if (result) {
      onSubscriptionOptionsChange(result, 'merge');
      handleDestinationSelectionFinished();
    }
  }, [
    authenticateThreads,
    handleDestinationSelectionFinished,
    onSubscriptionOptionsChange,
  ]);

  const handleAutomationPlatformAuthentication = useCallback((): void => {
    switch (state.destinationOptions.platform) {
      case 'youtube':
        handleYouTubeAuthentication();
        break;

      case 'tiktok':
        handleTikTokAuthentication();
        break;

      case 'instagram':
        handleInstagramAuthentication(
          INSTAGRAM_MEDIA_TYPE_MAP[state.destinationOptions.platformVideoType],
        );
        break;

      case 'linkedin':
        handleLinkedInAuthentication();
        break;

      case 'facebook':
        handleFacebookAuthentication(
          FACEBOOK_POST_TYPE_MAP[state.destinationOptions.platformVideoType],
        );
        break;

      case 'twitter':
        handleTwitterAuthentication();
        break;

      case 'threads':
        handleThreadsAuthentication();
        break;

      default:
    }
  }, [
    state.destinationOptions.platform,
    state.destinationOptions.platformVideoType,
    handleYouTubeAuthentication,
    handleTikTokAuthentication,
    handleInstagramAuthentication,
    handleLinkedInAuthentication,
    handleFacebookAuthentication,
    handleTwitterAuthentication,
    handleThreadsAuthentication,
  ]);

  const onNextClick = useCallback(async (nav: Navigator) => {
    nav.next();
  }, []);

  const onSkipSeasonsSelection = useCallback((): void => {
    onStartingPointChange('fromFirstEpisode');
    wizard.current.next();
  }, [onStartingPointChange]);

  return {
    ...state,
    customizeStepDraftState,
    pollingWorkflowPreview,
    onAspectRatioChange,
    onBackClick,
    onCancel,
    onGetPreviewConfig,
    onPreviewProject,
    onLanguageChange,
    onTranscriptionChange,
    onCaptionsChange,
    onEpisodesComplete,
    onTargetedSeasonsChange,
    onNextClick,
    onSkipSeasonsSelection,
    onPodcastClick,
    onStartingPointChange,
    onSubmitTemplateCustomization,
    onDestinationOptionsChange,
    onEpisodeOptionChange,
    onStepChange,
    onSubscriptionOptionsChange,
    onTemplateSelect,
    subscriptionPreviewConfig,
    handleYouTubeAuthentication,
    handleDestinationSelectionFinished,
    isAutopost,
    checkShouldAuthenticate,
    handleAutomationPlatformAuthentication,
    wizard,
    isLastStep: state.step === 'completed',
  };
}
