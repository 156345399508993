import { connect } from 'react-redux';
import { compose } from 'redux';

import { hideUnlessAuthenticated } from 'components/hoc/auth';
import { SubTier } from 'redux/middleware/api/plan-service';
import {
  onClickHeaderBadge,
  onFreeTrialExtendTrialCta,
  onFreeTrialLearnMoreCta,
} from 'redux/modules/mixpanel/actions';
import {
  planEndSecSelector,
  subtierSelector,
  tierSelector,
} from 'redux/modules/pricing';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import PricingTierButton, {
  PricingTierButtonProps as Props,
} from './PricingTierButton';

type StateProps = Pick<Props, 'pricingSubTier' | 'pricingTier' | 'planEndSec'>;
type DispatchProps = Pick<
  Props,
  'onClickHeaderBadge' | 'onExtendClick' | 'onLearnMoreClick'
>;

const mapStateToProps = (state: State): StateProps => ({
  pricingSubTier: subtierSelector(state),
  pricingTier: tierSelector(state),
  planEndSec: planEndSecSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onClickHeaderBadge: (subtier: SubTier) =>
    dispatch(onClickHeaderBadge(subtier)),
  onExtendClick: () =>
    dispatch(onFreeTrialExtendTrialCta({ source: 'Popper' })),
  onLearnMoreClick: () =>
    dispatch(onFreeTrialLearnMoreCta({ source: 'Popper' })),
});

const connectComponent = connect(mapStateToProps, mapDispatchToProps);

const enhance = (compose as any)(hideUnlessAuthenticated, connectComponent);

const component: ReturnType<typeof connectComponent> = enhance(
  PricingTierButton,
) as any;
export type PricingTierButtonProps = PropsOf<typeof component>;
export default component;
