import { useEffect } from 'react';

import { GetWaveSurfer, WaveSurferProps } from './types';

/**
 * redraw waveform when peaks change
 *
 * NOTE: getWaveSurfer should be a stable function that doesn't change, otherwise
 * peaks will get redrawn unnecessarily
 */
export default function usePeaks(
  { peaks }: WaveSurferProps,
  getWaveSurfer: GetWaveSurfer,
) {
  useEffect(
    () => {
      const ws = getWaveSurfer();

      if (ws) {
        const redraw = () => {
          if (peaks !== ws.backend.peaks) {
            ws.backend.peaks = peaks;
            ws.drawBuffer();
          }
        };

        if (ws.isReady) {
          redraw();
        } else {
          ws.on('ready', redraw);
          return () => ws.un('ready', redraw);
        }
      }
      return undefined;
    },
    // the only dep that really changes here is peaks.  getWaveSurfer should be
    // stable
    [getWaveSurfer, peaks],
  );
}
