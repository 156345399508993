import cn from 'classnames';
import * as React from 'react';

import { Scissors } from 'components/icons';
import bem from 'utils/bem';
import ProjectButton from '../../containers/ProjectButton';
import { INewProjectButtonProps } from './types';

export const block = bem('blank-project-button');

const BlankProjectButton: React.SFC<INewProjectButtonProps> = ({
  className,
  icon,
  onClick,
  title,
  ...rest
}) => (
  <ProjectButton
    animate={false}
    className={cn(block(), className)}
    icon={icon}
    onClick={onClick}
    projectType="blank"
    title={title}
    titleClassName={block('title')}
    to={{ pathname: '/project' }}
    {...rest}
  />
);

BlankProjectButton.defaultProps = {
  icon: <Scissors className={block('icon')} />,
  title: 'Advanced editor',
};

export default BlankProjectButton;
