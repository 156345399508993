import { Spacer } from '@sparemin/blockhead';
import React, { FC } from 'react';
import { PlusBold, Replace } from 'components/icons';
import LinkButton from 'components/LinkButton';
import { Popper, PopperTrigger } from 'components/Popper';
import { saveTemplatePopper as block } from '../utils';

export interface SaveTemplatePopperProps {
  isOpen: boolean;
  onSave: () => void;
  onOverwrite: () => void;
  onClose: () => void;
}

const SaveTemplatePopper: FC<SaveTemplatePopperProps> = props => {
  const { isOpen, onSave, onOverwrite, onClose } = props;

  return (
    <PopperTrigger open={isOpen} onClose={onClose}>
      <Spacer
        as={Popper}
        orientation="vertical"
        align="flex-start"
        justify="center"
        className={block()}
      >
        <Spacer as={LinkButton} className={block('button')} onClick={onSave}>
          <PlusBold className={block('button-icon')} /> Save as new template
        </Spacer>
        <Spacer
          as={LinkButton}
          className={block('button')}
          onClick={onOverwrite}
        >
          <Replace className={block('button-icon')} />
          Overwrite existing
        </Spacer>
      </Spacer>
    </PopperTrigger>
  );
};

export default SaveTemplatePopper;
