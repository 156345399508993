import * as React from 'react';

import { IconProps } from './types';

const Padded: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path d="M5 4h2c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1H5c-.552 0-1-.448-1-1V5c0-.552.448-1 1-1z" />
      <rect width="1" height="12" rx=".5" />
      <rect width="1" height="12" x="11" rx=".5" />
    </g>
  </svg>
);

export default Padded;
