import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExportVideoStep from 'containers/ProjectWizards/ExportVideoStep';
import useStaticCallback from 'hooks/useStaticCallback';
import {
  cancelWaitForWidgetId,
  waitForWidgetId,
  widgetIdSelector,
} from 'redux/modules/clip-select';
import { showError } from 'redux/modules/notification';
import { goToCreate } from 'redux/modules/router';
import { Dispatch } from 'redux/types';
import { useClipSelect } from './ClipSelectContext';

export interface ClipSelectVideoExportProps {
  className?: string;
}

const MIN_ESTIMATED_DURATION_SEC = 300;

const ClipSelectVideoExport: React.FC<ClipSelectVideoExportProps> = ({
  className,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const widgetId = useSelector(widgetIdSelector);
  const { region } = useClipSelect();
  const durationSec = (region.endMillis - region.startMillis) / 1000;

  const handleError = useStaticCallback(() => {
    dispatch(
      showError({
        code: 'ER002',
        message: 'There was an error exporting your video',
      }),
    );
    dispatch(goToCreate());
  });

  useEffect(() => {
    dispatch(waitForWidgetId()).catch(handleError);

    return () => {
      dispatch(cancelWaitForWidgetId());
    };
  }, [dispatch, handleError]);

  return (
    <ExportVideoStep
      className={className}
      estimatedDurationSec={Math.max(
        MIN_ESTIMATED_DURATION_SEC,
        durationSec * 2.5,
      )}
      onError={handleError}
      widgetId={widgetId}
    />
  );
};

export default ClipSelectVideoExport;
