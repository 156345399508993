import React from 'react';

import CreditCardRecoveryBanner from 'blocks/Pricing/containers/CreditCardRecoveryBanner';

import ClaimPodcastBanner from '../ClaimPodcastBanner';
import EddyAiBanner from '../EddyAiBanner';
import FeedCollectBanner from '../FeedCollectBanner';
import SelfServeDiscoBanner from '../SelfServeDiscoBanner';

import useBannerSlot from './useBannerSlot';
import { block } from './utils';

export interface CreateTabBannerSlotProps {}

const CreateTabBannerSlot: React.FC<CreateTabBannerSlotProps> = () => {
  const { targetBanner } = useBannerSlot();

  const getBanner = React.useCallback(() => {
    switch (targetBanner) {
      case 'retry-banner':
        return <CreditCardRecoveryBanner />;
      case 'feed-collect-banner':
        return <FeedCollectBanner />;
      case 'claim-podcast-banner':
        return <ClaimPodcastBanner />;
      case 'self-serve-disco-banner':
        return <SelfServeDiscoBanner source="CreateTab" />;
      case 'eddy-ai-banner':
        return <EddyAiBanner />;
      default:
        return null;
    }
  }, [targetBanner]);

  const Banner = getBanner();

  return !Banner ? null : <div className={block('banner')}>{Banner}</div>;
};

export default CreateTabBannerSlot;
