import cn from 'classnames';
import * as React from 'react';
import { isUndefined } from 'underscore';

import { block } from './utils';

export interface ColorPickerContainerProps {
  children?: React.ReactNode;
  className?: string;
  componentClass?: React.ElementType<{ className: string }>;
}

const ColorPickerContainer: React.SFC<ColorPickerContainerProps> = ({
  children,
  className,
  componentClass: Component,
}) =>
  isUndefined(Component) ? (
    <>{children}</>
  ) : (
    <Component className={cn(block(), className)}>{children}</Component>
  );

export default ColorPickerContainer;
