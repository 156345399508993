import * as React from 'react';

import AssetTile from 'components/AssetTile';
import { TemplateItem } from '../../types';
import ProjectTemplateIcons from '../ProjectTemplateIcons';

interface IProps {
  className?: string;
  domRef?: React.Ref<HTMLDivElement>;
  onClickTemplate: () => void;
  template: TemplateItem;
}

export default class HeadlinerDefault extends React.Component<IProps> {
  public render() {
    const { className, domRef, onClickTemplate, template } = this.props;

    return (
      <AssetTile
        className={className}
        background={{
          src: template.previewVideos.reduce((acc, videoData) => {
            acc.push({
              type: videoData.mediaType,
              url: videoData.videoUrl,
            });
            return acc;
          }, []),
          thumbnail: template.thumbnailImageUrl,
          type: 'video',
        }}
        onClick={onClickTemplate}
        permanent
        ref={domRef}
      >
        <ProjectTemplateIcons icons={template.exportPlatforms} />
      </AssetTile>
    );
  }
}

export { IProps as HeadlinerDefaultProps };
