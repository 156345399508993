import cn from 'classnames';
import * as React from 'react';

import { block } from './utils';

export interface PaidSubscriptionBodyProps {
  children?: React.ReactNode;
  className?: string;
}

const PaidSubscriptionBody: React.SFC<PaidSubscriptionBodyProps> = ({
  children,
  className,
}) => <div className={cn(block('body'), className)}>{children}</div>;

export default PaidSubscriptionBody;
