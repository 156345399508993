import { useBlocker } from 'hooks/useBlocker';
import { MIXPANEL_WIZARD_STEP_MAP } from './constants';
import { AutogramWizardStep } from './types';

const useAutomationWorkflowWizardBlocker = (stepId: AutogramWizardStep) => {
  const shouldAllow =
    stepId === 'podcast' || stepId === 'completed' || stepId === 'preview';
  return useBlocker(!shouldAllow, MIXPANEL_WIZARD_STEP_MAP[stepId]);
};

export default useAutomationWorkflowWizardBlocker;
