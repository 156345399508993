enum BemSeparators {
  ELEMENT_SEPARATOR = '__',
  MODIFIER_SEPARATOR = '--',
}

interface Modifiers {
  [key: string]: boolean;
}

let prefix: string = '';

function joinBEMModifiers(
  blockOrElement: string,
  modifiers: Modifiers = {},
  separator: BemSeparators,
) {
  return [blockOrElement].concat(
    Object.keys(modifiers)
      .filter(m => modifiers[m])
      .map(m => [blockOrElement, m].join(separator)),
  );
}

function bem(blockName: string) {
  return (
    blockModifiersOrElementName?: string | Modifiers,
    elementModifiers: Modifiers = {},
  ) =>
    (typeof blockModifiersOrElementName === 'string'
      ? joinBEMModifiers(
          [`${prefix}${blockName}`, blockModifiersOrElementName].join(
            BemSeparators.ELEMENT_SEPARATOR,
          ),
          elementModifiers,
          BemSeparators.MODIFIER_SEPARATOR,
        )
      : joinBEMModifiers(
          `${prefix}${blockName}`,
          blockModifiersOrElementName,
          BemSeparators.MODIFIER_SEPARATOR,
        )
    ).join(' ');
}

(bem as any).setPrefix = (p: string) => {
  prefix = p;
};

export default bem;
