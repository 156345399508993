import * as React from 'react';
import useEditAssetModal from 'containers/ConnectedModal/useEditAssetModal';
import EditWaveformModalContents from './EditWaveformModalContents';
import WaveformModal from './WaveformModal';

export type EditWaveformModalProps = {};

const EditWaveformModal: React.FC<EditWaveformModalProps> = () => {
  const { show, onExited, onHide } = useEditAssetModal('EditWaveformModal');

  return (
    <WaveformModal {...{ onExited, onHide, show }}>
      <EditWaveformModalContents onHide={onHide} />
    </WaveformModal>
  );
};

export default EditWaveformModal;
