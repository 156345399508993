import { createSelector } from 'reselect';
import { isUndefined } from 'underscore';

import { gifSearchEnginesOverrideSelector } from 'redux/modules/display-pref/selectors';
import { State } from 'redux/types';
import { getValue } from 'utils/collections';
import { capitalize } from 'utils/string';
import { supportsWebM } from 'utils/video';
import { Engine, GifSearchState } from './types';

const GIF_ENGINES: Engine[] = ['giphy', 'giphySticker', 'tenor'];

export const gifSearchSelector = (state: State): GifSearchState =>
  state.get('gifSearch');

const giphySelector = createSelector(gifSearchSelector, gifSearch =>
  getValue(gifSearch, 'giphy'),
);

const giphyStickerSelector = createSelector(gifSearchSelector, gifSearch =>
  getValue(gifSearch, 'giphySticker'),
);

const tenorSelector = createSelector(gifSearchSelector, gifSearch =>
  getValue(gifSearch, 'tenor'),
);

const engineStatesSelector = createSelector(
  [giphySelector, giphyStickerSelector, tenorSelector],
  (giphy, giphySticker, tenor) => ({
    giphy,
    giphySticker,
    tenor,
  }),
);

const engineAccessSelector = createSelector(
  gifSearchEnginesOverrideSelector,
  gifEngineOverrides =>
    gifEngineOverrides
      ? gifEngineOverrides
          .toJS()
          .filter(engine => GIF_ENGINES.indexOf(engine) >= 0)
      : GIF_ENGINES,
);

function getEngineDisplayName(engine: Engine) {
  switch (engine) {
    case 'giphy':
      return 'GIPHY GIFs';
    case 'giphySticker':
      return 'GIPHY Stickers';
    default:
      return capitalize(engine);
  }
}

function isEngineSupported(engine: Engine) {
  switch (engine) {
    case 'giphySticker':
      return supportsWebM();
    default:
      return true;
  }
}

export const searchResultsSelector = createSelector(
  [tenorSelector, giphySelector, giphyStickerSelector, engineAccessSelector],
  (tenor, giphy, giphySticker, engineAccess) => {
    const engines = { giphy, giphySticker, tenor };
    return Object.keys(engines)
      .filter(
        name => engineAccess.indexOf(name) >= 0 && !isUndefined(engines[name]),
      )
      .reduce((acc, key: Engine) => {
        const engine = engines[key];
        const data = getValue(engine, 'data');

        acc[key] = {
          data:
            data &&
            data.map(d => ({
              aspectRatio: {
                height: getValue(d, ['gif', 'height']),
                width: getValue(d, ['gif', 'width']),
              },
              gif: d.gif,
              poster: getValue(d, ['previewThumbnail', 'url']),
              preview: d.previewGif,
              video: d.video,
              tooltip: getValue(d, ['metadata', 'author']),
            })),
          displayName: getEngineDisplayName(key),
          error: engine.get('error'),
          isSearching: engine.get('isFetching'),
          page: engine.get('page'),
          query: engine.get('q'),
          totalResults: engine.get('totalResults'),
          isSupported: isEngineSupported(key),
        };
        return acc;
      }, {});
  },
);

export const gifSearchQueriesSelector = createSelector(
  engineStatesSelector,
  engineStates =>
    Object.keys(engineStates).reduce((queries, name) => {
      const query = getValue(engineStates, [name, 'q']);
      if (query) {
        queries.push(query);
      }
      return queries;
    }, []),
);
