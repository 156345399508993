import * as React from 'react';

import Image from 'components/Image';
import { Omit } from 'types';
import { block } from '../utils';
import TrackAsset, { TrackAssetProps } from './TrackAsset';

type InheritedProps = Omit<
  TrackAssetProps,
  'itemClassName' | 'position' | 'sizeBounds'
>;

export interface ImageAssetProps extends InheritedProps {
  minWidth?: number;
  position: TrackAssetProps['position']['x'];
  src: string;
}

const ImageAsset: React.SFC<ImageAssetProps> = ({
  minWidth,
  position,
  src,
  ...props
}) => (
  <TrackAsset
    {...props}
    itemClassName={block('image-asset')}
    position={{ x: position, y: 0 }}
    sizeBounds={{ minWidth, maxWidth: Infinity }}
  >
    <div className={block('image-asset-inner')}>
      <Image
        className={block('image-asset-image')}
        draggable={false}
        src={src}
      />
    </div>
  </TrackAsset>
);

export default ImageAsset;
