import React, { useCallback, useEffect, useRef, useState } from 'react';
import Wizard, { Navigator } from 'components/Wizard';
import { AspectRatioName } from 'types';
import AspectRatioStep from '../../AspectRatioStep';
import VideoClipWizardProgressStep from './VideoClipWizardProgressStep';

export interface VideoClipWizardProps {
  onMount?: () => void;
  onError?: (error: Error) => void;
  onCancel?: (nav: Navigator) => void;
  onSubmit?: (aspectRatioName: AspectRatioName) => void;
  onComplete?: () => void;
}

const VideoClipWizard: React.FC<VideoClipWizardProps> = ({
  onMount,
  onError,
  onCancel,
  onSubmit,
  onComplete,
}) => {
  const [aspectRatioName, setAspectRatioName] = useState<AspectRatioName>(
    'square',
  );

  const wizardRef = useRef<Wizard>();

  const handleAspectRatioNameSelect = useCallback(
    (__, name: AspectRatioName): void => {
      setAspectRatioName(name);
    },
    [],
  );

  const handleSubmit = useCallback((): void => {
    onSubmit(aspectRatioName);

    wizardRef.current.next();
  }, [aspectRatioName, onSubmit]);

  useEffect(() => {
    onMount?.();
  }, [onMount]);

  return (
    <Wizard
      canBacktrack
      onCancelClick={onCancel}
      steps={[
        {
          stepId: 'aspectRatio',
          name: 'Choose aspect ratio',
          title: 'Choose aspect ratio',
          description:
            'Select the format best fit for where you plan to share your video clip.',
          component: (
            <AspectRatioStep
              onSelect={handleAspectRatioNameSelect}
              value={aspectRatioName}
            />
          ),
          renderNextButton: buttonProps => (
            <Wizard.Button {...buttonProps} theme="next" onClick={handleSubmit}>
              create project
            </Wizard.Button>
          ),
          showInNav: false,
        },
        {
          stepId: 'submitting',
          name: 'submitting',
          component: (
            <VideoClipWizardProgressStep
              onCompleted={onComplete}
              onError={onError}
            />
          ),
          renderCancelButton: () => null,
          renderNextButton: () => null,
          showInNav: false,
        },
      ]}
      ref={wizardRef}
    />
  );
};

export default VideoClipWizard;
