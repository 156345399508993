import bem from 'utils/bem';

export const block = bem('color-picker');

const defaultPresetColors = [
  '#FFFFFF',
  '#9B9B9B',
  '#4A4A4A',
  '#000000',
  '#B8E986',
  '#50E3C2',
  '#4A90E2',
  '#9013FE',
  '#BD10E0',
  '#417505',
  '#7ED321',
  '#8B572A',
  '#F8E71C',
  '#F5A623',
  '#D0021B',
];

const PRESET_SIZE = 16;

export const fillPresetColors = (presetColors?: string[]) => {
  if (Array.isArray(presetColors)) {
    return [...presetColors, ...defaultPresetColors].slice(0, PRESET_SIZE);
  }

  return defaultPresetColors;
};
