import React from 'react';

import DialogContent, {
  Props as DialogContentProps,
} from 'components/DialogContent';
import { CircularGradientMicrophone } from 'components/icons/circular-gradient';
import { PodcastClaimPodcastOwnershipResult } from 'redux/middleware/api/podcast-service';
import { MAIL_TO_SUPPORT_LINK } from 'utils/constants';

import { block } from './utils';

interface ClaimPodcastResultModalContentsProps {
  claimResponse: PodcastClaimPodcastOwnershipResult;
  onClaimAnotherPodcast: () => void;
  requestStatus?: 'emailMismatch' | 'failure' | 'success';
}

const SUPPORT_LINK = `mailto:${MAIL_TO_SUPPORT_LINK}`;

const ClaimPodcastResultModalContents: React.FunctionComponent<ClaimPodcastResultModalContentsProps> = props => {
  const { claimResponse, onClaimAnotherPodcast, requestStatus } = props;

  let dialogProps: DialogContentProps;

  if (requestStatus === 'failure') {
    return null;
  }

  const commonDialogProps = {
    detailsClassName: block('result-details'),
    icon: <CircularGradientMicrophone />,
  };

  switch (requestStatus) {
    case 'success':
      dialogProps = {
        actions: [
          {
            children: 'visit your podcast page',
            href: claimResponse?.playAppPodcastUrl,
            key: 'visit-podcast',
            params: false,
            rel: 'noreferrer noopener',
            target: '_blank',
            theme: 'submit',
          },
        ],
        details: 'Visit your new podcast page and let us know what you think.',
        title: 'Your podcast is live!',
      };
      break;
    case 'emailMismatch':
      dialogProps = {
        actions: [
          {
            children: 'claim another podcast',
            key: 'claim-another',
            params: false,
            theme: 'submit-alt',
            onClick: onClaimAnotherPodcast,
          },
          {
            children: 'contact support',
            href: SUPPORT_LINK,
            key: 'contact-support',
            params: false,
            theme: 'submit',
          },
        ],
        details: `The email address included in the podcast feed that you selected doesn’t match your account. To claim this podcast, please sign into Headliner with this email: ${claimResponse?.podcastOwnerEmail}`,
        title: 'Your email doesn’t match...',
      };
      break;
    default:
      break;
  }

  return <DialogContent {...commonDialogProps} {...dialogProps} />;
};

export default ClaimPodcastResultModalContents;
