import { ViewportHeight, ViewportWidth } from 'utils/measurement';
import { Line } from './types';

export const CENTERING_LINES: [Line, Line] = [
  {
    id: 'center-x',
    from: {
      x: new ViewportWidth(0),
      y: new ViewportHeight(50),
    },
    to: {
      x: new ViewportWidth(100),
      y: new ViewportHeight(50),
    },
  },
  {
    id: 'center-y',
    from: {
      x: new ViewportWidth(50),
      y: new ViewportHeight(0),
    },
    to: {
      x: new ViewportWidth(50),
      y: new ViewportHeight(100),
    },
  },
];
