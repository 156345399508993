import * as React from 'react';
import { noop } from 'underscore';

import { EditorTranscriptionOptionsContextType } from './types';

const { useContext } = React;

const Context = React.createContext<EditorTranscriptionOptionsContextType>({
  balanceMillis: undefined,
  canTranscribe: undefined,
  disabled: false,
  hasBalance: undefined,
  language: undefined,
  newBalanceMillis: undefined,
  outOfTimeMessage: undefined,
  onLanguageSelected: noop,
  onToggleTranscription: noop,
  showBalance: undefined,
  transcriptionEnabled: undefined,
});

export const EditorTranscriptionOptionsProvider: React.FC<EditorTranscriptionOptionsContextType> = ({
  balanceMillis,
  canTranscribe,
  children,
  disabled,
  hasBalance,
  language,
  newBalanceMillis,
  onLanguageSelected,
  onToggleTranscription,
  outOfTimeMessage,
  showBalance,
  transcriptionEnabled,
}) => (
  <Context.Provider
    value={{
      balanceMillis,
      canTranscribe,
      disabled,
      hasBalance,
      language,
      newBalanceMillis,
      onLanguageSelected,
      onToggleTranscription,
      outOfTimeMessage,
      showBalance,
      transcriptionEnabled,
    }}
  >
    {children}
  </Context.Provider>
);

export function useEditorTranscriptionOptionsContext() {
  return useContext(Context);
}
