import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMessage from 'components/LoadingMessage';
import useOnMount from 'hooks/useOnMount';
import { isLoadingWorkflowTemplatesSelector } from 'redux/modules/automation-cms/selectors';
import { pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { AspectRatioName } from 'types';

import useLoadTemplateList from '../useLoadTemplateList';
import { TemplateStepSubSteps } from './types';
import { block } from './utils';
import VideoTemplateSelector, {
  VideoTemplateSelectorProps,
} from './VideoTemplateSelector';

const { useEffect } = React;

type PickedSelectorProps = Pick<
  VideoTemplateSelectorProps,
  | 'aspectRatioName'
  | 'compatibilityTypes'
  | 'templateTypes'
  | 'isProjectEditable'
>;

export interface TemplateStepProps extends PickedSelectorProps {
  aspectRatioName: AspectRatioName;
  initialSubStep?: TemplateStepSubSteps;
  onChangeAspectRatio?: (aspectRatio: AspectRatioName) => void;
  onSelect?: (templateId: string) => void;
  podcastId?: string;
  transcriptionEnabled?: boolean;
}

const TemplateStep: React.FC<TemplateStepProps> = ({
  aspectRatioName,
  compatibilityTypes,
  initialSubStep,
  isProjectEditable,
  onChangeAspectRatio,
  onSelect,
  podcastId,
  templateTypes,
  transcriptionEnabled,
}) => {
  const isLoading = useSelector(isLoadingWorkflowTemplatesSelector);
  const dispatch = useDispatch<Dispatch>();

  const { loadTemplateList } = useLoadTemplateList();

  useEffect(() => {
    loadTemplateList(aspectRatioName);
  }, [aspectRatioName, loadTemplateList, podcastId]);

  const handleChangeAspectRatio = React.useCallback(async (): Promise<void> => {
    const aspectRatioResult = await dispatch(
      pushModal({
        name: 'ChangeAspectRatio',
        params: {
          selectedAspectRatio: aspectRatioName,
        },
      }),
    );

    const { meta } = aspectRatioResult || {};

    if (meta?.ratioName) {
      onChangeAspectRatio(meta.ratioName);
    }
  }, [aspectRatioName, dispatch, onChangeAspectRatio]);

  useOnMount(() => {
    if (initialSubStep === 'aspect-ratio') {
      handleChangeAspectRatio();
    }
  });

  return (
    <div
      className={block({
        [aspectRatioName]: !!aspectRatioName,
      })}
    >
      {isLoading ? (
        <LoadingMessage message="Loading templates" />
      ) : (
        <VideoTemplateSelector
          {...{
            aspectRatioName,
            compatibilityTypes,
            onSelect,
            templateTypes,
            isProjectEditable,
            transcriptionEnabled,
          }}
          onChangeAspectRatio={handleChangeAspectRatio}
        />
      )}
    </div>
  );
};

export default TemplateStep;
