import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A>(method: M, args: A) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export function analyzeRecordingVersion(
  versionId: number,
  recommendation: boolean,
  aspectRatio: types.IAspectRatio,
): types.AnalyzeRecordingVersionAction {
  return apiCallAction(types.ServiceMethod.ANALYZE_RECORDING_VERSION, [
    versionId,
    recommendation,
    aspectRatio,
  ] as types.AnalyzeRecordingVersionArgs);
}

export function analyzeTextBlob(
  textBlobId: string,
  recommendation: boolean,
  aspectRatio: types.IAspectRatio,
): types.AnalyzeTextBlobAction {
  return apiCallAction(types.ServiceMethod.ANALYZE_TEXT_BLOB, [
    textBlobId,
    recommendation,
    aspectRatio,
  ] as types.AnalyzeTextBlobArgs);
}

export function summarize(url: string): types.SummarizeAction {
  return apiCallAction(types.ServiceMethod.SUMMARIZE, [
    url,
  ] as types.SummarizeArgs);
}
