import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import {
  EditAutomationAudioSelectionLengthModal,
  EditAutomationAudioSelectionTypeModal,
} from './EditAutomationAudioSelection';
import {
  EditAutomationEpisodeSelectionModal,
  EditAutomationEpisodeSelectSeasonModal,
} from './EditAutomationEpisode';
import EditAutomationModalContents from './EditAutomationModalContents';
import { EditAutomationOutputModal } from './EditAutomationOutput';

const EditAudioModal: React.FunctionComponent = () => {
  return (
    <>
      <ConnectedModal
        backdrop="static"
        name="EditAutomationModal"
        title="Edit automation"
      >
        <EditAutomationModalContents />
      </ConnectedModal>
      <EditAutomationAudioSelectionLengthModal />
      <EditAutomationAudioSelectionTypeModal />
      <EditAutomationOutputModal />
      <EditAutomationEpisodeSelectionModal />
      <EditAutomationEpisodeSelectSeasonModal />
    </>
  );
};

export default EditAudioModal;
