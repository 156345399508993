import * as React from 'react';
import { connect } from 'react-redux';

import { onClickTemplateThumbnail } from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal/actions';
import { getProjectTemplatesAction } from 'redux/modules/project-templates/actions';
import { projectTemplatesSelector } from 'redux/modules/project-templates/selectors';
import { Dispatch, State } from 'redux/types';
import { TemplateItem } from '../../types';
import { ProjectTemplatesProps } from './ProjectTemplates';

type StateProps = Pick<ProjectTemplatesProps, 'projectTemplates'>;
export type DispatchProps = Pick<
  ProjectTemplatesProps,
  'onClickDeleteTemplate' | 'onClickTemplate' | 'onMount'
>;

const mapStateToProps = (state: State): StateProps => ({
  projectTemplates: projectTemplatesSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onClickDeleteTemplate: (templateId: string) =>
    dispatch(pushModal({ name: 'DeleteTemplate', params: { templateId } })),
  onClickTemplate: (template: TemplateItem) => {
    dispatch(
      onClickTemplateThumbnail(
        template.id,
        template.name || template.displayName,
      ),
    );
    dispatch(
      pushModal({
        name: 'OpenTemplate',
        params: { templateId: template.id },
      }),
    );
  },
  onMount: () => {
    dispatch(getProjectTemplatesAction());
  },
});

export default function(component: React.ComponentType<ProjectTemplatesProps>) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
