import {
  DataInterval,
  IntervalTree as NodeIntervalTree,
} from 'node-interval-tree';

type DataCallback<T> = (interval: DataInterval<T>, isLower: boolean) => T;

/**
 * adds functionality to node-interval-tree
 * inspired by https://github.com/chaimleib/intervaltree
 */
export default class IntervalTree<T> {
  private itree: NodeIntervalTree<DataInterval<T>>;

  constructor() {
    this.itree = new NodeIntervalTree<DataInterval<T>>();
  }

  // https://github.com/chaimleib/intervaltree/blob/1ff87c12cc8b6af6e524ff3b9d63f21eb4ce58b9/intervaltree/intervaltree.py#L333
  private update(intervals: Array<DataInterval<T>> = []) {
    intervals.forEach(interval => this.itree.insert(interval));
  }

  // https://github.com/chaimleib/intervaltree/blob/1ff87c12cc8b6af6e524ff3b9d63f21eb4ce58b9/intervaltree/intervaltree.py#L406
  private differenceUpdate(intervals: Array<DataInterval<T>> = []) {
    intervals.forEach(interval => this.itree.remove(interval));
  }

  // https://github.com/chaimleib/intervaltree/blob/1ff87c12cc8b6af6e524ff3b9d63f21eb4ce58b9/intervaltree/intervaltree.py#L475
  private removeEnvelope(start: number, end: number) {
    this.itree.search(start, end).forEach(interval => {
      const { high, low } = interval;
      if (low >= start && low <= end && high >= start && high <= end) {
        this.itree.remove(interval);
      }
    });
  }

  public insert(start: number, end: number, data: T) {
    this.itree.insert({
      data,
      high: end,
      low: start,
    });
  }

  public remove(start: number, end: number, data: T) {
    this.itree.remove({
      data,
      high: end,
      low: start,
    });
  }

  public search(start: number, end: number) {
    return this.itree.search(start, end);
  }

  // https://github.com/chaimleib/intervaltree/blob/1ff87c12cc8b6af6e524ff3b9d63f21eb4ce58b9/intervaltree/intervaltree.py#L488
  public chop(
    start: number,
    end: number,
    dataFunc: DataCallback<T> = iv => iv.data,
  ) {
    const overlaps = this.itree.search(start, end);

    const beginOverlaps = overlaps.filter(o => o.low < start);
    const endOverlaps = overlaps.filter(o => o.high > end);

    const beginInsertions = beginOverlaps.map(interval => ({
      data: dataFunc(interval, true),
      high: start,
      low: interval.low,
    }));

    const endInsertions = endOverlaps.map(interval => ({
      data: dataFunc(interval, false),
      high: interval.high,
      low: end,
    }));

    const insertions = [...beginInsertions, ...endInsertions];

    this.removeEnvelope(start, end);
    this.differenceUpdate(beginOverlaps);
    this.differenceUpdate(endOverlaps);
    this.update(insertions);
  }

  public get count() {
    return this.itree.count;
  }

  public inOrder() {
    return this.itree.inOrder();
  }
}
