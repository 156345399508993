import memoize from 'memoizee';
import { isNumber, isString } from 'underscore';

import { VIEWPORTS } from 'utils/constants';
import merge from 'utils/deepmerge';
import { AspectRatioName } from '../../types';
import {
  bbcTemplateConfig,
  buTemplateConfig,
  caption1TemplateConfig,
  copyright1TemplateConfig,
  copyrightTextAmharicTemplateConfig,
  copyrightTextBengaliTemplateConfig,
  copyrightTextRtlTemplateConfig,
  defaultTemplateConfig,
  detailAnimated1Config,
  facebookTemplateConfig,
  fullScreen1Config,
  gradient1TemplateConfig,
  gradientSubtitleAmharicTemplateConfig,
  gradientSubtitleBengaliTemplateConfig,
  gradientSubtitlePrisaTemplateConfig,
  gradientSubtitleRtlTemplateConfig,
  leftInsert1TemplateConfig,
  leftInsertAmharicTemplateConfig,
  leftInsertBengaliTemplateConfig,
  leftInsertRtlTemplateConfig,
  micTemplateConfig,
  nowthisTemplateConfig,
  quote1TemplateConfig,
  quote2TemplateConfig,
  rightInsert1TemplateConfig,
  rightInsertAmharicTemplateConfig,
  rightInsertBengaliTemplateConfig,
  rightInsertRtlTemplateConfig,
  shortcutTemplateConfig,
  socialMediaTextStyleConfig,
  sportscastTitleTemplateConfig,
  sportsQuoteLeftTemplateConfig,
  sportsQuoteRightTemplateConfig,
  srseCaptionsTemplateConfig,
  srseNameTemplateConfig,
  srseOccupationTemplateConfig,
  srseP4TemplateConfig,
  srseRubrikTemplateConfig,
  title1TemplateConfig,
  titleAnimated1Config,
} from './text-templates';
import { ITextTemplateConfig, TextTemplate } from './types';

const templateConfigs = {
  gradientSubtitlePrisa: gradientSubtitlePrisaTemplateConfig,
  sportsCast: sportscastTitleTemplateConfig,
  sportsQuoteLeft: sportsQuoteLeftTemplateConfig,
  sportsQuoteRight: sportsQuoteRightTemplateConfig,
  default: defaultTemplateConfig,
  facebook: facebookTemplateConfig,
  micdotcom: micTemplateConfig,
  shortcut: shortcutTemplateConfig,
  bbc: bbcTemplateConfig,
  nowthis: nowthisTemplateConfig,
  bu: buTemplateConfig,
  title1: title1TemplateConfig,
  quote1: quote1TemplateConfig,
  quote2: quote2TemplateConfig,
  caption1: caption1TemplateConfig,
  copyright1: copyright1TemplateConfig,
  copyrightTextAmharic: copyrightTextAmharicTemplateConfig,
  copyrightTextRtl: copyrightTextRtlTemplateConfig,
  copyrightTextBengali: copyrightTextBengaliTemplateConfig,
  gradient1: gradient1TemplateConfig,
  gradientSubtitleAmharic: gradientSubtitleAmharicTemplateConfig,
  gradientSubtitleRtl: gradientSubtitleRtlTemplateConfig,
  gradientSubtitleBengali: gradientSubtitleBengaliTemplateConfig,
  leftInsert1: leftInsert1TemplateConfig,
  leftInsertAmharic: leftInsertAmharicTemplateConfig,
  leftInsertRtl: leftInsertRtlTemplateConfig,
  leftInsertBengali: leftInsertBengaliTemplateConfig,
  rightInsert1: rightInsert1TemplateConfig,
  rightInsertAmharic: rightInsertAmharicTemplateConfig,
  rightInsertRtl: rightInsertRtlTemplateConfig,
  rightInsertBengali: rightInsertBengaliTemplateConfig,
  fullScreen1: fullScreen1Config,
  titleAnimated1: titleAnimated1Config,
  detailAnimated1: detailAnimated1Config,
  instagram: socialMediaTextStyleConfig,
  srseName: srseNameTemplateConfig,
  srseOccupation: srseOccupationTemplateConfig,
  srseCaptions: srseCaptionsTemplateConfig,
  srseP4: srseP4TemplateConfig,
  srseRubrik: srseRubrikTemplateConfig,
};

export const templateIds = Object.keys(templateConfigs);

type ViewportToPxConverter = (val: number) => number;

function convertViewportToPx(
  obj: any,
  vwToPx: ViewportToPxConverter,
  vhToPx: ViewportToPxConverter,
) {
  if (isNumber(obj)) {
    return obj;
  }

  if (isString(obj)) {
    if (obj.endsWith('vw')) {
      return vwToPx(parseFloat(obj));
    }

    if (obj.endsWith('vh')) {
      return vhToPx(parseFloat(obj));
    }

    return obj;
  }

  if (Array.isArray(obj)) {
    return [...obj].map(val => convertViewportToPx(val, vwToPx, vhToPx));
  }

  return Object.keys(obj).reduce((acc, key) => {
    acc[key] = convertViewportToPx(obj[key], vwToPx, vhToPx);
    return acc;
  }, {});
}

function createTemplate(
  config: ITextTemplateConfig,
  aspectRatio: AspectRatioName,
): TextTemplate {
  const viewport = VIEWPORTS[aspectRatio];
  const { height, width } = viewport;
  const pctOfHeight = (val: number) => (val / 100) * height;
  const pctOfWidth = (val: number) => (val / 100) * width;

  const { overrides = {}, ...configWithoutOverrides } = config;
  const override = overrides[aspectRatio] || {};

  // TODO exclude non-style keys
  const template = convertViewportToPx(
    merge(configWithoutOverrides, override),
    pctOfWidth,
    pctOfHeight,
  );

  template.viewport = { ...viewport };

  return template;
}

export const getTemplate = memoize((id: string, aspectRatio: AspectRatioName) =>
  createTemplate(templateConfigs[id], aspectRatio),
);

export function getTemplates(aspectRatio: AspectRatioName) {
  return templateIds.reduce((acc, id) => {
    acc[id] = getTemplate(id, aspectRatio);
    return acc;
  }, {});
}

export { lastUsedStyle } from './text-templates';
export { TextTemplate, TextTemplates, TemplateId } from './types';
export { VIEWPORTS };
