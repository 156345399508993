import * as React from 'react';

import { WaveformProps } from '../../types';

const Equalizer = React.forwardRef<SVGSVGElement, WaveformProps>(
  (props, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 85 32"
      preserveAspectRatio="none"
      ref={ref}
      {...props}
    >
      <rect fill="#B5EA51" x="0" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="0" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="0" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="0" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="0" y="27.9148936" width="1" height="1" />
      <rect
        fill="#B5EA51"
        x="0.595744681"
        y="31.3191489"
        width="1"
        height="1"
      />
      <rect
        fill="#BBE348"
        x="0.595744681"
        y="30.6382979"
        width="1"
        height="1"
      />
      <rect
        fill="#C1DC41"
        x="0.595744681"
        y="29.6170213"
        width="1"
        height="1"
      />
      <rect
        fill="#C9D43A"
        x="0.595744681"
        y="28.5957447"
        width="1"
        height="1"
      />
      <rect
        fill="#D0CC35"
        x="0.595744681"
        y="27.9148936"
        width="1"
        height="1"
      />
      <rect fill="#D7C332" x="0.595744681" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="0.595744681" y="26.212766" width="1" height="1" />
      <rect
        fill="#E6B22E"
        x="0.595744681"
        y="25.1914894"
        width="1"
        height="1"
      />
      <rect
        fill="#EDA92E"
        x="0.595744681"
        y="24.5106383"
        width="1"
        height="1"
      />
      <rect
        fill="#F4A030"
        x="0.595744681"
        y="23.4893617"
        width="1"
        height="1"
      />
      <rect fill="#B5EA51" x="1.19148936" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="1.19148936" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="1.19148936" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="1.19148936" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="1.19148936" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="1.19148936" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="1.19148936" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="1.19148936" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="1.19148936" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="1.19148936" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="1.19148936" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="1.19148936" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="1.19148936" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="1.19148936" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="1.19148936" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="1.19148936" y="18.0425532" width="1" height="1" />
      <rect fill="#FF6857" x="1.19148936" y="17.3617021" width="1" height="1" />
      <rect fill="#FF615F" x="1.19148936" y="16.3404255" width="1" height="1" />
      <rect fill="#FF5B68" x="1.19148936" y="15.6595745" width="1" height="1" />
      <rect fill="#FF5570" x="1.19148936" y="14.6382979" width="1" height="1" />
      <rect fill="#FF5179" x="1.19148936" y="13.9574468" width="1" height="1" />
      <rect fill="#FF4C81" x="1.19148936" y="12.9361702" width="1" height="1" />
      <rect fill="#FA4889" x="1.19148936" y="12.2553191" width="1" height="1" />
      <rect fill="#F34591" x="1.19148936" y="11.2340426" width="1" height="1" />
      <rect fill="#EB4398" x="1.19148936" y="10.5531915" width="1" height="1" />
      <rect fill="#E3409E" x="1.19148936" y="9.53191489" width="1" height="1" />
      <rect fill="#DA3FA4" x="1.19148936" y="8.85106383" width="1" height="1" />
      <rect fill="#D03EA9" x="1.19148936" y="7.82978723" width="1" height="1" />
      <rect fill="#C53DAD" x="1.19148936" y="7.14893617" width="1" height="1" />
      <rect fill="#B5EA51" x="1.78723404" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="1.78723404" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="1.78723404" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="1.78723404" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="1.78723404" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="1.78723404" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="1.78723404" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="1.78723404" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="1.78723404" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="1.78723404" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="1.78723404" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="1.78723404" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="1.78723404" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="1.78723404" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="1.78723404" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="1.78723404" y="18.0425532" width="1" height="1" />
      <rect fill="#FF6857" x="1.78723404" y="17.3617021" width="1" height="1" />
      <rect fill="#FF615F" x="1.78723404" y="16.3404255" width="1" height="1" />
      <rect fill="#FF5B68" x="1.78723404" y="15.6595745" width="1" height="1" />
      <rect fill="#FF5570" x="1.78723404" y="14.6382979" width="1" height="1" />
      <rect fill="#B5EA51" x="2.9787234" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="2.9787234" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="2.9787234" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="2.9787234" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="2.9787234" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="2.9787234" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="2.9787234" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="2.9787234" y="25.1914894" width="1" height="1" />
      <rect fill="#B5EA51" x="3.57446809" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="3.57446809" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="3.57446809" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="3.57446809" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="3.57446809" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="3.57446809" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="3.57446809" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="3.57446809" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="3.57446809" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="3.57446809" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="3.57446809" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="3.57446809" y="21.787234" width="1" height="1" />
      <rect fill="#B5EA51" x="4.17021277" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="4.17021277" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="4.17021277" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="4.17021277" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="4.17021277" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="4.17021277" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="4.17021277" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="4.17021277" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="4.17021277" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="4.17021277" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="4.17021277" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="4.17021277" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="4.17021277" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="4.17021277" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="4.17021277" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="4.17021277" y="18.0425532" width="1" height="1" />
      <rect fill="#FF6857" x="4.17021277" y="17.3617021" width="1" height="1" />
      <rect fill="#FF615F" x="4.17021277" y="16.3404255" width="1" height="1" />
      <rect fill="#B5EA51" x="4.76595745" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="4.76595745" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="4.76595745" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="4.76595745" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="4.76595745" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="4.76595745" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="4.76595745" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="4.76595745" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="4.76595745" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="4.76595745" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="4.76595745" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="4.76595745" y="21.787234" width="1" height="1" />
      <rect fill="#B5EA51" x="5.36170213" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="5.36170213" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="5.36170213" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="5.95744681" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="5.95744681" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="5.95744681" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="6.55319149" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="6.55319149" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="6.55319149" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="6.55319149" y="28.5957447" width="1" height="1" />
      <rect fill="#B5EA51" x="7.74468085" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="7.74468085" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="7.74468085" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="7.74468085" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="7.74468085" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="7.74468085" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="7.74468085" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="7.74468085" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="7.74468085" y="24.5106383" width="1" height="1" />
      <rect fill="#B5EA51" x="8.34042553" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="8.34042553" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="8.34042553" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="8.34042553" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="8.34042553" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="8.34042553" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="8.34042553" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="8.34042553" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="8.34042553" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="8.34042553" y="23.4893617" width="1" height="1" />
      <rect fill="#B5EA51" x="8.34042553" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="8.34042553" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="8.34042553" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="8.34042553" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="8.34042553" y="27.9148936" width="1" height="1" />
      <rect fill="#B5EA51" x="8.93617021" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="8.93617021" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="8.93617021" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="8.93617021" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="8.93617021" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="8.93617021" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="8.93617021" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="8.93617021" y="25.1914894" width="1" height="1" />
      <rect fill="#B5EA51" x="9.53191489" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="9.53191489" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="9.53191489" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="9.53191489" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="9.53191489" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="9.53191489" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="9.53191489" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="9.53191489" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="9.53191489" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="9.53191489" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="9.53191489" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="9.53191489" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="9.53191489" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="9.53191489" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="9.53191489" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="9.53191489" y="18.0425532" width="1" height="1" />
      <rect fill="#FF6857" x="9.53191489" y="17.3617021" width="1" height="1" />
      <rect fill="#FF615F" x="9.53191489" y="16.3404255" width="1" height="1" />
      <rect fill="#FF5B68" x="9.53191489" y="15.6595745" width="1" height="1" />
      <rect fill="#FF5570" x="9.53191489" y="14.6382979" width="1" height="1" />
      <rect fill="#FF5179" x="9.53191489" y="13.9574468" width="1" height="1" />
      <rect fill="#FF4C81" x="9.53191489" y="12.9361702" width="1" height="1" />
      <rect fill="#FA4889" x="9.53191489" y="12.2553191" width="1" height="1" />
      <rect fill="#B5EA51" x="10.1276596" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="10.1276596" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="10.1276596" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="10.1276596" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="10.1276596" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="10.1276596" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="10.1276596" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="10.1276596" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="10.1276596" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="10.1276596" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="10.1276596" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="10.1276596" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="10.1276596" y="20.7659574" width="1" height="1" />
      <rect fill="#B5EA51" x="10.7234043" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="10.7234043" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="10.7234043" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="11.3191489" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="11.3191489" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="11.3191489" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="11.3191489" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="11.3191489" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="11.3191489" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="11.3191489" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="11.3191489" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="11.3191489" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="11.3191489" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="11.3191489" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="11.3191489" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="11.3191489" y="20.7659574" width="1" height="1" />
      <rect fill="#B5EA51" x="12.5106383" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="12.5106383" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="12.5106383" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="12.5106383" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="12.5106383" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="12.5106383" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="12.5106383" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="12.5106383" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="12.5106383" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="12.5106383" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="12.5106383" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="12.5106383" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="12.5106383" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="12.5106383" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="12.5106383" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="12.5106383" y="18.0425532" width="1" height="1" />
      <rect fill="#B5EA51" x="13.106383" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="13.106383" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="13.106383" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="13.106383" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="13.106383" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="13.106383" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="13.106383" y="26.212766" width="1" height="1" />
      <rect fill="#B5EA51" x="13.7021277" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="13.7021277" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="13.7021277" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="13.7021277" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="13.7021277" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="13.7021277" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="13.7021277" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="13.7021277" y="25.1914894" width="1" height="1" />
      <rect fill="#B5EA51" x="14.2978723" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="14.2978723" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="14.2978723" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="14.893617" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="14.893617" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="14.893617" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="15.4893617" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="15.4893617" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="15.4893617" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="15.4893617" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="15.4893617" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="15.4893617" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="15.4893617" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="15.4893617" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="15.4893617" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="15.4893617" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="15.4893617" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="15.4893617" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="15.4893617" y="20.7659574" width="1" height="1" />
      <rect fill="#B5EA51" x="16.6808511" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="16.6808511" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="16.6808511" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="16.6808511" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="16.6808511" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="16.6808511" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="16.6808511" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="16.6808511" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="16.6808511" y="24.5106383" width="1" height="1" />
      <rect fill="#B5EA51" x="17.2765957" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="17.2765957" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="17.2765957" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="17.2765957" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="17.2765957" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="17.2765957" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="17.2765957" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="17.2765957" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="17.2765957" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="17.2765957" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="17.2765957" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="17.2765957" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="17.2765957" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="17.2765957" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="17.2765957" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="17.2765957" y="18.0425532" width="1" height="1" />
      <rect fill="#B5EA51" x="17.8723404" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="17.8723404" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="17.8723404" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="17.8723404" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="17.8723404" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="17.8723404" y="26.893617" width="1" height="1" />
      <rect fill="#B5EA51" x="18.4680851" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="18.4680851" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="18.4680851" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="18.4680851" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="18.4680851" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="18.4680851" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="18.4680851" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="18.4680851" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="18.4680851" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="18.4680851" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="18.4680851" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="18.4680851" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="18.4680851" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="18.4680851" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="18.4680851" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="18.4680851" y="18.0425532" width="1" height="1" />
      <rect fill="#FF6857" x="18.4680851" y="17.3617021" width="1" height="1" />
      <rect fill="#FF615F" x="18.4680851" y="16.3404255" width="1" height="1" />
      <rect fill="#B5EA51" x="19.0638298" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="19.0638298" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="19.0638298" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="19.0638298" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="19.0638298" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="19.0638298" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="19.0638298" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="19.0638298" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="19.0638298" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="19.0638298" y="23.4893617" width="1" height="1" />
      <rect fill="#B5EA51" x="19.6595745" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="19.6595745" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="19.6595745" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="19.6595745" y="28.5957447" width="1" height="1" />
      <rect fill="#B5EA51" x="20.2553191" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="20.2553191" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="20.2553191" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="20.2553191" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="20.2553191" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="20.2553191" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="20.2553191" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="20.2553191" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="20.2553191" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="20.2553191" y="23.4893617" width="1" height="1" />
      <rect fill="#B5EA51" x="21.4468085" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="21.4468085" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="21.4468085" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="21.4468085" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="21.4468085" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="21.4468085" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="21.4468085" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="21.4468085" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="21.4468085" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="21.4468085" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="21.4468085" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="21.4468085" y="21.787234" width="1" height="1" />
      <rect fill="#B5EA51" x="22.0425532" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="22.0425532" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="22.0425532" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="22.0425532" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="22.0425532" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="22.0425532" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="22.0425532" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="22.0425532" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="22.0425532" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="22.0425532" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="22.0425532" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="22.0425532" y="21.787234" width="1" height="1" />
      <rect fill="#B5EA51" x="22.6382979" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="22.6382979" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="22.6382979" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="22.6382979" y="28.5957447" width="1" height="1" />
      <rect fill="#B5EA51" x="23.2340426" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="23.2340426" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="23.2340426" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="23.8297872" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="23.8297872" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="23.8297872" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="23.8297872" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="23.8297872" y="27.9148936" width="1" height="1" />
      <rect fill="#B5EA51" x="24.4255319" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="24.4255319" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="24.4255319" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="24.4255319" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="24.4255319" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="24.4255319" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="24.4255319" y="26.212766" width="1" height="1" />
      <rect fill="#B5EA51" x="25.0212766" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="25.0212766" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="25.0212766" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="25.0212766" y="28.5957447" width="1" height="1" />
      <rect fill="#B5EA51" x="25.6170213" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="25.6170213" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="25.6170213" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="26.212766" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="26.212766" y="30.6382979" width="1" height="1" />
      <rect fill="#B5EA51" x="26.8085106" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="26.8085106" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="26.8085106" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="27.4042553" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="27.4042553" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="27.4042553" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="27.4042553" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="27.4042553" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="27.4042553" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="27.4042553" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="27.4042553" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="27.4042553" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="27.4042553" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="27.4042553" y="22.4680851" width="1" height="1" />
      <rect fill="#B5EA51" x="28" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="28" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="28" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="28" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="28" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="28" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="28" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="28" y="25.1914894" width="1" height="1" />
      <rect fill="#B5EA51" x="28.5957447" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="28.5957447" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="28.5957447" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="29.1914894" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="29.1914894" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="29.1914894" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="29.1914894" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="29.1914894" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="29.1914894" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="29.1914894" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="29.1914894" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="29.1914894" y="24.5106383" width="1" height="1" />
      <rect fill="#B5EA51" x="30.3829787" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="30.3829787" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="30.3829787" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="30.3829787" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="30.3829787" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="30.3829787" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="30.3829787" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="30.3829787" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="30.3829787" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="30.3829787" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="30.3829787" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="30.3829787" y="21.787234" width="1" height="1" />
      <rect fill="#B5EA51" x="30.9787234" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="30.9787234" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="30.9787234" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="30.9787234" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="30.9787234" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="30.9787234" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="30.9787234" y="26.212766" width="1" height="1" />
      <rect fill="#B5EA51" x="31.5744681" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="31.5744681" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="31.5744681" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="31.5744681" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="31.5744681" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="31.5744681" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="31.5744681" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="31.5744681" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="31.5744681" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="31.5744681" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="31.5744681" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="31.5744681" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="31.5744681" y="20.7659574" width="1" height="1" />
      <rect fill="#B5EA51" x="32.1702128" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="32.1702128" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="32.1702128" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="32.1702128" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="32.1702128" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="32.1702128" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="32.1702128" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="32.1702128" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="32.1702128" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="32.1702128" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="32.1702128" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="32.1702128" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="32.1702128" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="32.1702128" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="32.1702128" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="32.1702128" y="18.0425532" width="1" height="1" />
      <rect fill="#FF6857" x="32.1702128" y="17.3617021" width="1" height="1" />
      <rect fill="#FF615F" x="32.1702128" y="16.3404255" width="1" height="1" />
      <rect fill="#FF5B68" x="32.1702128" y="15.6595745" width="1" height="1" />
      <rect fill="#FF5570" x="32.1702128" y="14.6382979" width="1" height="1" />
      <rect fill="#FF5179" x="32.1702128" y="13.9574468" width="1" height="1" />
      <rect fill="#FF4C81" x="32.1702128" y="12.9361702" width="1" height="1" />
      <rect fill="#FA4889" x="32.1702128" y="12.2553191" width="1" height="1" />
      <rect fill="#F34591" x="32.1702128" y="11.2340426" width="1" height="1" />
      <rect fill="#EB4398" x="32.1702128" y="10.5531915" width="1" height="1" />
      <rect fill="#E3409E" x="32.1702128" y="9.53191489" width="1" height="1" />
      <rect fill="#DA3FA4" x="32.1702128" y="8.85106383" width="1" height="1" />
      <rect fill="#D03EA9" x="32.1702128" y="7.82978723" width="1" height="1" />
      <rect fill="#C53DAD" x="32.1702128" y="7.14893617" width="1" height="1" />
      <rect fill="#BB3CB0" x="32.1702128" y="6.12765957" width="1" height="1" />
      <rect fill="#B5EA51" x="32.7659574" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="32.7659574" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="32.7659574" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="32.7659574" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="32.7659574" y="27.9148936" width="1" height="1" />
      <rect fill="#B5EA51" x="33.3617021" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="33.3617021" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="33.3617021" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="33.3617021" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="33.3617021" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="33.3617021" y="26.893617" width="1" height="1" />
      <rect fill="#B5EA51" x="33.9574468" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="33.9574468" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="33.9574468" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="33.9574468" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="33.9574468" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="33.9574468" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="33.9574468" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="33.9574468" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="33.9574468" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="33.9574468" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="33.9574468" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="33.9574468" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="33.9574468" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="33.9574468" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="33.9574468" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="33.9574468" y="18.0425532" width="1" height="1" />
      <rect fill="#FF6857" x="33.9574468" y="17.3617021" width="1" height="1" />
      <rect fill="#FF615F" x="33.9574468" y="16.3404255" width="1" height="1" />
      <rect fill="#B5EA51" x="34.5531915" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="34.5531915" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="34.5531915" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="34.5531915" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="34.5531915" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="34.5531915" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="34.5531915" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="34.5531915" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="34.5531915" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="34.5531915" y="23.4893617" width="1" height="1" />
      <rect fill="#B5EA51" x="35.7446809" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="35.7446809" y="30.6382979" width="1" height="1" />
      <rect fill="#B5EA51" x="36.3404255" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="36.3404255" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="36.3404255" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="36.3404255" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="36.3404255" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="36.3404255" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="36.3404255" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="36.3404255" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="36.3404255" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="36.3404255" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="36.3404255" y="22.4680851" width="1" height="1" />
      <rect fill="#B5EA51" x="36.9361702" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="36.9361702" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="36.9361702" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="36.9361702" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="36.9361702" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="36.9361702" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="36.9361702" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="36.9361702" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="36.9361702" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="36.9361702" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="36.9361702" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="36.9361702" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="36.9361702" y="20.7659574" width="1" height="1" />
      <rect fill="#B5EA51" x="37.5319149" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="37.5319149" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="37.5319149" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="37.5319149" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="37.5319149" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="37.5319149" y="26.893617" width="1" height="1" />
      <rect fill="#B5EA51" x="38.1276596" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="38.1276596" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="38.1276596" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="38.1276596" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="38.1276596" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="38.1276596" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="38.1276596" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="38.1276596" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="38.1276596" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="38.1276596" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="38.1276596" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="38.1276596" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="38.1276596" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="38.1276596" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="38.1276596" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="38.1276596" y="18.0425532" width="1" height="1" />
      <rect fill="#B5EA51" x="38.7234043" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="38.7234043" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="38.7234043" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="38.7234043" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="38.7234043" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="38.7234043" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="38.7234043" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="38.7234043" y="25.1914894" width="1" height="1" />
      <rect fill="#B5EA51" x="39.3191489" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="39.3191489" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="39.3191489" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="39.3191489" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="39.3191489" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="39.3191489" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="39.3191489" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="39.3191489" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="39.3191489" y="24.5106383" width="1" height="1" />
      <rect fill="#B5EA51" x="40.5106383" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="40.5106383" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="40.5106383" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="40.5106383" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="40.5106383" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="40.5106383" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="40.5106383" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="40.5106383" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="40.5106383" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="40.5106383" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="40.5106383" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="40.5106383" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="40.5106383" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="40.5106383" y="20.0851064" width="1" height="1" />
      <rect fill="#B5EA51" x="41.106383" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="41.106383" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="41.106383" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="41.106383" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="41.106383" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="41.106383" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="41.106383" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="41.106383" y="25.1914894" width="1" height="1" />
      <rect fill="#B5EA51" x="41.7021277" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="41.7021277" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="41.7021277" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="41.7021277" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="41.7021277" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="41.7021277" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="41.7021277" y="26.212766" width="1" height="1" />
      <rect fill="#B5EA51" x="42.2978723" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="42.2978723" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="42.2978723" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="42.2978723" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="42.2978723" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="42.2978723" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="42.2978723" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="42.2978723" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="42.2978723" y="24.5106383" width="1" height="1" />
      <rect fill="#B5EA51" x="42.2978723" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="42.2978723" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="42.2978723" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="42.2978723" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="42.2978723" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="42.2978723" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="42.2978723" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="42.2978723" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="42.2978723" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="42.2978723" y="23.4893617" width="1" height="1" />
      <rect fill="#B5EA51" x="42.893617" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="42.893617" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="42.893617" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="44.0851064" y="31.3191489" width="1" height="1" />
      <rect fill="#B5EA51" x="44.6808511" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="44.6808511" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="44.6808511" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="44.6808511" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="44.6808511" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="44.6808511" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="44.6808511" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="44.6808511" y="25.1914894" width="1" height="1" />
      <rect fill="#B5EA51" x="45.2765957" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="45.2765957" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="45.2765957" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="45.2765957" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="45.2765957" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="45.2765957" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="45.2765957" y="26.212766" width="1" height="1" />
      <rect fill="#B5EA51" x="45.8723404" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="45.8723404" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="45.8723404" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="45.8723404" y="28.5957447" width="1" height="1" />
      <rect fill="#B5EA51" x="46.4680851" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="46.4680851" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="46.4680851" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="46.4680851" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="46.4680851" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="46.4680851" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="46.4680851" y="26.212766" width="1" height="1" />
      <rect fill="#B5EA51" x="47.0638298" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="47.0638298" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="47.0638298" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="47.0638298" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="47.0638298" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="47.0638298" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="47.0638298" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="47.0638298" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="47.0638298" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="47.0638298" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="47.0638298" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="47.0638298" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="47.0638298" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="47.0638298" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="47.0638298" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="47.0638298" y="18.0425532" width="1" height="1" />
      <rect fill="#FF6857" x="47.0638298" y="17.3617021" width="1" height="1" />
      <rect fill="#FF615F" x="47.0638298" y="16.3404255" width="1" height="1" />
      <rect fill="#FF5B68" x="47.0638298" y="15.6595745" width="1" height="1" />
      <rect fill="#FF5570" x="47.0638298" y="14.6382979" width="1" height="1" />
      <rect fill="#FF5179" x="47.0638298" y="13.9574468" width="1" height="1" />
      <rect fill="#FF4C81" x="47.0638298" y="12.9361702" width="1" height="1" />
      <rect fill="#FA4889" x="47.0638298" y="12.2553191" width="1" height="1" />
      <rect fill="#B5EA51" x="47.6595745" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="47.6595745" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="47.6595745" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="47.6595745" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="47.6595745" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="47.6595745" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="47.6595745" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="47.6595745" y="25.1914894" width="1" height="1" />
      <rect fill="#B5EA51" x="48.8510638" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="48.8510638" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="48.8510638" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="48.8510638" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="48.8510638" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="48.8510638" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="48.8510638" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="48.8510638" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="48.8510638" y="24.5106383" width="1" height="1" />
      <rect fill="#B5EA51" x="49.4468085" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="49.4468085" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="49.4468085" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="49.4468085" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="49.4468085" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="49.4468085" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="49.4468085" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="49.4468085" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="49.4468085" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="49.4468085" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="49.4468085" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="49.4468085" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="49.4468085" y="20.7659574" width="1" height="1" />
      <rect fill="#B5EA51" x="50.0425532" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="50.0425532" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="50.0425532" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="50.0425532" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="50.0425532" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="50.0425532" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="50.0425532" y="26.212766" width="1" height="1" />
      <rect fill="#B5EA51" x="50.6382979" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="50.6382979" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="50.6382979" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="50.6382979" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="50.6382979" y="27.9148936" width="1" height="1" />
      <rect fill="#B5EA51" x="51.2340426" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="51.2340426" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="51.2340426" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="51.8297872" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="51.8297872" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="51.8297872" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="51.8297872" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="51.8297872" y="27.9148936" width="1" height="1" />
      <rect fill="#B5EA51" x="52.4255319" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="52.4255319" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="52.4255319" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="52.4255319" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="52.4255319" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="52.4255319" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="52.4255319" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="52.4255319" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="52.4255319" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="52.4255319" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="52.4255319" y="22.4680851" width="1" height="1" />
      <rect fill="#B5EA51" x="53.0212766" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="53.0212766" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="53.0212766" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="53.0212766" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="53.0212766" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="53.0212766" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="53.0212766" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="53.0212766" y="25.1914894" width="1" height="1" />
      <rect fill="#B5EA51" x="54.212766" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="54.212766" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="54.212766" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="54.212766" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="54.212766" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="54.212766" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="54.212766" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="54.212766" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="54.212766" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="54.212766" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="54.212766" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="54.212766" y="21.787234" width="1" height="1" />
      <rect fill="#B5EA51" x="54.8085106" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="54.8085106" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="54.8085106" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="54.8085106" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="54.8085106" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="54.8085106" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="54.8085106" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="54.8085106" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="54.8085106" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="54.8085106" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="54.8085106" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="54.8085106" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="54.8085106" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="54.8085106" y="20.0851064" width="1" height="1" />
      <rect fill="#B5EA51" x="55.4042553" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="55.4042553" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="55.4042553" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="55.4042553" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="55.4042553" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="55.4042553" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="55.4042553" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="55.4042553" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="55.4042553" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="55.4042553" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="55.4042553" y="22.4680851" width="1" height="1" />
      <rect fill="#B5EA51" x="56" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="56" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="56" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="56" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="56" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="56" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="56" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="56" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="56" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="56" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="56" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="56" y="21.787234" width="1" height="1" />
      <rect fill="#B5EA51" x="56.5957447" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="56.5957447" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="56.5957447" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="56.5957447" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="56.5957447" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="56.5957447" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="56.5957447" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="56.5957447" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="56.5957447" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="56.5957447" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="56.5957447" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="56.5957447" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="56.5957447" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="56.5957447" y="20.0851064" width="1" height="1" />
      <rect fill="#B5EA51" x="57.1914894" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="57.1914894" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="57.1914894" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="57.1914894" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="57.1914894" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="57.1914894" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="57.1914894" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="57.1914894" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="57.1914894" y="24.5106383" width="1" height="1" />
      <rect fill="#B5EA51" x="57.787234" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="57.787234" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="57.787234" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="57.787234" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="57.787234" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="57.787234" y="26.893617" width="1" height="1" />
      <rect fill="#B5EA51" x="58.9787234" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="58.9787234" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="58.9787234" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="58.9787234" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="58.9787234" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="58.9787234" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="58.9787234" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="58.9787234" y="27.9148936" width="1" height="1" />
      <rect fill="#B5EA51" x="59.5744681" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="59.5744681" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="59.5744681" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="59.5744681" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="59.5744681" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="59.5744681" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="59.5744681" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="59.5744681" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="59.5744681" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="59.5744681" y="23.4893617" width="1" height="1" />
      <rect fill="#B5EA51" x="60.1702128" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="60.1702128" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="60.1702128" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="60.1702128" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="60.1702128" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="60.1702128" y="26.893617" width="1" height="1" />
      <rect fill="#B5EA51" x="60.7659574" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="60.7659574" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="60.7659574" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="61.3617021" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="61.3617021" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="61.3617021" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="61.3617021" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="61.3617021" y="27.9148936" width="1" height="1" />
      <rect fill="#B5EA51" x="61.9574468" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="61.9574468" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="61.9574468" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="61.9574468" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="61.9574468" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="61.9574468" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="61.9574468" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="61.9574468" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="61.9574468" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="61.9574468" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="61.9574468" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="61.9574468" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="61.9574468" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="61.9574468" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="61.9574468" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="61.9574468" y="18.0425532" width="1" height="1" />
      <rect fill="#FF6857" x="61.9574468" y="17.3617021" width="1" height="1" />
      <rect fill="#FF615F" x="61.9574468" y="16.3404255" width="1" height="1" />
      <rect fill="#FF5B68" x="61.9574468" y="15.6595745" width="1" height="1" />
      <rect fill="#FF5570" x="61.9574468" y="14.6382979" width="1" height="1" />
      <rect fill="#FF5179" x="61.9574468" y="13.9574468" width="1" height="1" />
      <rect fill="#FF4C81" x="61.9574468" y="12.9361702" width="1" height="1" />
      <rect fill="#FA4889" x="61.9574468" y="12.2553191" width="1" height="1" />
      <rect fill="#F34591" x="61.9574468" y="11.2340426" width="1" height="1" />
      <rect fill="#EB4398" x="61.9574468" y="10.5531915" width="1" height="1" />
      <rect fill="#E3409E" x="61.9574468" y="9.53191489" width="1" height="1" />
      <rect fill="#DA3FA4" x="61.9574468" y="8.85106383" width="1" height="1" />
      <rect fill="#D03EA9" x="61.9574468" y="7.82978723" width="1" height="1" />
      <rect fill="#C53DAD" x="61.9574468" y="7.14893617" width="1" height="1" />
      <rect fill="#BB3CB0" x="61.9574468" y="6.12765957" width="1" height="1" />
      <rect fill="#B03CB2" x="61.9574468" y="5.44680851" width="1" height="1" />
      <rect fill="#A53CB3" x="61.9574468" y="4.42553191" width="1" height="1" />
      <rect fill="#993DB3" x="61.9574468" y="3.40425532" width="1" height="1" />
      <rect fill="#8E3EB2" x="61.9574468" y="2.72340426" width="1" height="1" />
      <rect fill="#833EB0" x="61.9574468" y="1.70212766" width="1" height="1" />
      <rect fill="#783FAE" x="61.9574468" y="1.0212766" width="1" height="1" />
      <rect fill="#6E40AA" x="61.9574468" y="0" width="1" height="1" />
      <rect fill="#B5EA51" x="63.1489362" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="63.1489362" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="63.1489362" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="63.1489362" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="63.1489362" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="63.1489362" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="63.1489362" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="63.1489362" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="63.1489362" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="63.1489362" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="63.1489362" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="63.1489362" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="63.1489362" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="63.1489362" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="63.1489362" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="63.1489362" y="18.0425532" width="1" height="1" />
      <rect fill="#FF6857" x="63.1489362" y="17.3617021" width="1" height="1" />
      <rect fill="#FF615F" x="63.1489362" y="16.3404255" width="1" height="1" />
      <rect fill="#FF5B68" x="63.1489362" y="15.6595745" width="1" height="1" />
      <rect fill="#FF5570" x="63.1489362" y="14.6382979" width="1" height="1" />
      <rect fill="#FF5179" x="63.1489362" y="13.9574468" width="1" height="1" />
      <rect fill="#FF4C81" x="63.1489362" y="12.9361702" width="1" height="1" />
      <rect fill="#B5EA51" x="63.7446809" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="63.7446809" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="63.7446809" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="63.7446809" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="63.7446809" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="63.7446809" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="63.7446809" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="63.7446809" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="63.7446809" y="24.5106383" width="1" height="1" />
      <rect fill="#B5EA51" x="64.3404255" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="64.3404255" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="64.3404255" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="64.3404255" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="64.3404255" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="64.3404255" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="64.3404255" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="64.3404255" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="64.3404255" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="64.3404255" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="64.3404255" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="64.3404255" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="64.3404255" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="64.3404255" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="64.3404255" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="64.3404255" y="18.0425532" width="1" height="1" />
      <rect fill="#FF6857" x="64.3404255" y="17.3617021" width="1" height="1" />
      <rect fill="#FF615F" x="64.3404255" y="16.3404255" width="1" height="1" />
      <rect fill="#FF5B68" x="64.3404255" y="15.6595745" width="1" height="1" />
      <rect fill="#B5EA51" x="64.9361702" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="64.9361702" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="64.9361702" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="64.9361702" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="64.9361702" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="64.9361702" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="64.9361702" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="64.9361702" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="64.9361702" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="64.9361702" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="64.9361702" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="64.9361702" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="64.9361702" y="20.7659574" width="1" height="1" />
      <rect fill="#B5EA51" x="65.5319149" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="65.5319149" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="65.5319149" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="66.1276596" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="66.1276596" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="66.1276596" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="66.7234043" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="66.7234043" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="66.7234043" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="67.9148936" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="67.9148936" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="67.9148936" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="67.9148936" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="67.9148936" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="67.9148936" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="67.9148936" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="67.9148936" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="67.9148936" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="67.9148936" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="67.9148936" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="67.9148936" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="67.9148936" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="67.9148936" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="67.9148936" y="19.0638298" width="1" height="1" />
      <rect fill="#B5EA51" x="68.5106383" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="68.5106383" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="68.5106383" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="68.5106383" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="68.5106383" y="27.9148936" width="1" height="1" />
      <rect fill="#B5EA51" x="69.106383" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="69.106383" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="69.106383" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="69.106383" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="69.106383" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="69.106383" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="69.106383" y="26.212766" width="1" height="1" />
      <rect fill="#B5EA51" x="69.7021277" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="69.7021277" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="69.7021277" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="69.7021277" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="69.7021277" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="69.7021277" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="69.7021277" y="26.212766" width="1" height="1" />
      <rect fill="#B5EA51" x="70.2978723" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="70.2978723" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="70.2978723" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="70.2978723" y="28.5957447" width="1" height="1" />
      <rect fill="#B5EA51" x="70.893617" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="70.893617" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="70.893617" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="70.893617" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="70.893617" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="70.893617" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="70.893617" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="70.893617" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="70.893617" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="70.893617" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="70.893617" y="22.4680851" width="1" height="1" />
      <rect fill="#B5EA51" x="71.4893617" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="71.4893617" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="71.4893617" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="71.4893617" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="71.4893617" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="71.4893617" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="71.4893617" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="71.4893617" y="25.1914894" width="1" height="1" />
      <rect fill="#B5EA51" x="72.6808511" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="72.6808511" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="72.6808511" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="72.6808511" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="72.6808511" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="72.6808511" y="26.893617" width="1" height="1" />
      <rect fill="#B5EA51" x="73.2765957" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="73.2765957" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="73.2765957" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="73.2765957" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="73.2765957" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="73.2765957" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="73.2765957" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="73.2765957" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="73.2765957" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="73.2765957" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="73.2765957" y="22.4680851" width="1" height="1" />
      <rect fill="#B5EA51" x="73.8723404" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="73.8723404" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="73.8723404" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="73.8723404" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="73.8723404" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="73.8723404" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="73.8723404" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="73.8723404" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="73.8723404" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="73.8723404" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="73.8723404" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="73.8723404" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="73.8723404" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="73.8723404" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="73.8723404" y="19.0638298" width="1" height="1" />
      <rect fill="#B5EA51" x="74.4680851" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="74.4680851" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="74.4680851" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="74.4680851" y="28.5957447" width="1" height="1" />
      <rect fill="#B5EA51" x="75.0638298" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="75.0638298" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="75.0638298" y="29.6170213" width="1" height="1" />
      <rect fill="#B5EA51" x="75.6595745" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="75.6595745" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="75.6595745" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="75.6595745" y="28.5957447" width="1" height="1" />
      <rect fill="#B5EA51" x="75.6595745" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="75.6595745" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="75.6595745" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="75.6595745" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="75.6595745" y="27.9148936" width="1" height="1" />
      <rect fill="#B5EA51" x="76.8510638" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="76.8510638" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="76.8510638" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="76.8510638" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="76.8510638" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="76.8510638" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="76.8510638" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="76.8510638" y="25.1914894" width="1" height="1" />
      <rect fill="#B5EA51" x="77.4468085" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="77.4468085" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="77.4468085" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="77.4468085" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="77.4468085" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="77.4468085" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="77.4468085" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="77.4468085" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="77.4468085" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="77.4468085" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="77.4468085" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="77.4468085" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="77.4468085" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="77.4468085" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="77.4468085" y="19.0638298" width="1" height="1" />
      <rect fill="#B5EA51" x="78.0425532" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="78.0425532" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="78.0425532" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="78.0425532" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="78.0425532" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="78.0425532" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="78.0425532" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="78.0425532" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="78.0425532" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="78.0425532" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="78.0425532" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="78.0425532" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="78.0425532" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="78.0425532" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="78.0425532" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="78.0425532" y="18.0425532" width="1" height="1" />
      <rect fill="#FF6857" x="78.0425532" y="17.3617021" width="1" height="1" />
      <rect fill="#FF615F" x="78.0425532" y="16.3404255" width="1" height="1" />
      <rect fill="#FF5B68" x="78.0425532" y="15.6595745" width="1" height="1" />
      <rect fill="#FF5570" x="78.0425532" y="14.6382979" width="1" height="1" />
      <rect fill="#FF5179" x="78.0425532" y="13.9574468" width="1" height="1" />
      <rect fill="#FF4C81" x="78.0425532" y="12.9361702" width="1" height="1" />
      <rect fill="#FA4889" x="78.0425532" y="12.2553191" width="1" height="1" />
      <rect fill="#F34591" x="78.0425532" y="11.2340426" width="1" height="1" />
      <rect fill="#EB4398" x="78.0425532" y="10.5531915" width="1" height="1" />
      <rect fill="#E3409E" x="78.0425532" y="9.53191489" width="1" height="1" />
      <rect fill="#B5EA51" x="78.6382979" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="78.6382979" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="78.6382979" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="78.6382979" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="78.6382979" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="78.6382979" y="26.893617" width="1" height="1" />
      <rect fill="#B5EA51" x="79.2340426" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="79.2340426" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="79.2340426" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="79.2340426" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="79.2340426" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="79.2340426" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="79.2340426" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="79.2340426" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="79.2340426" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="79.2340426" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="79.2340426" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="79.2340426" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="79.2340426" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="79.2340426" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="79.2340426" y="19.0638298" width="1" height="1" />
      <rect fill="#B5EA51" x="79.8297872" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="79.8297872" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="79.8297872" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="79.8297872" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="79.8297872" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="79.8297872" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="79.8297872" y="26.212766" width="1" height="1" />
      <rect fill="#B5EA51" x="80.4255319" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="80.4255319" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="80.4255319" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="80.4255319" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="80.4255319" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="80.4255319" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="80.4255319" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="80.4255319" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="80.4255319" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="80.4255319" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="80.4255319" y="22.4680851" width="1" height="1" />
      <rect fill="#B5EA51" x="81.6170213" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="81.6170213" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="81.6170213" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="81.6170213" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="81.6170213" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="81.6170213" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="81.6170213" y="26.212766" width="1" height="1" />
      <rect fill="#B5EA51" x="82.212766" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="82.212766" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="82.212766" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="82.212766" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="82.212766" y="27.9148936" width="1" height="1" />
      <rect fill="#B5EA51" x="82.8085106" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="82.8085106" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="82.8085106" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="82.8085106" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="82.8085106" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="82.8085106" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="82.8085106" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="82.8085106" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="82.8085106" y="24.5106383" width="1" height="1" />
      <rect fill="#F4A030" x="82.8085106" y="23.4893617" width="1" height="1" />
      <rect fill="#FA9733" x="82.8085106" y="22.4680851" width="1" height="1" />
      <rect fill="#FF8F37" x="82.8085106" y="21.787234" width="1" height="1" />
      <rect fill="#FF863C" x="82.8085106" y="20.7659574" width="1" height="1" />
      <rect fill="#FF7E41" x="82.8085106" y="20.0851064" width="1" height="1" />
      <rect fill="#FF7648" x="82.8085106" y="19.0638298" width="1" height="1" />
      <rect fill="#FF6F4F" x="82.8085106" y="18.0425532" width="1" height="1" />
      <rect fill="#FF6857" x="82.8085106" y="17.3617021" width="1" height="1" />
      <rect fill="#B5EA51" x="83.4042553" y="31.3191489" width="1" height="1" />
      <rect fill="#BBE348" x="83.4042553" y="30.6382979" width="1" height="1" />
      <rect fill="#C1DC41" x="83.4042553" y="29.6170213" width="1" height="1" />
      <rect fill="#C9D43A" x="83.4042553" y="28.5957447" width="1" height="1" />
      <rect fill="#D0CC35" x="83.4042553" y="27.9148936" width="1" height="1" />
      <rect fill="#D7C332" x="83.4042553" y="26.893617" width="1" height="1" />
      <rect fill="#DFBB2F" x="83.4042553" y="26.212766" width="1" height="1" />
      <rect fill="#E6B22E" x="83.4042553" y="25.1914894" width="1" height="1" />
      <rect fill="#EDA92E" x="83.4042553" y="24.5106383" width="1" height="1" />
    </svg>
  ),
);

export default Equalizer;
