import * as React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { isUndefined } from 'underscore';
import {
  savePaymentMethodStatusSelector,
  updateSubscriptionStatusSelector,
} from 'redux/modules/pricing/selectors';
import { RequestStatus } from 'types/common';
import BillingCycleModal from '../BillingCycleModal';
import AddBillingCycleModalContent from './AddBillingCycleModalContent';
import { block } from './utils';

export interface Props {}

const { useState } = React;

const disabledSelector = createSelector(
  [savePaymentMethodStatusSelector, updateSubscriptionStatusSelector],
  (savePaymentStatus, updateSubscriptionStatus) => {
    if (
      isUndefined(savePaymentStatus) ||
      savePaymentStatus === RequestStatus.FAILURE ||
      updateSubscriptionStatus === RequestStatus.SUCCESS ||
      updateSubscriptionStatus === RequestStatus.FAILURE
    ) {
      return false;
    }

    return true;
  },
);

const AddBillingCycleModal: React.FC<Props> = () => {
  const [visible, setVisibility] = useState(true);
  const disabled = useSelector(disabledSelector);
  return (
    <BillingCycleModal
      className={block({ hidden: !visible })}
      title="checkout"
      name="AddBillingCycle"
      disabled={disabled}
    >
      <AddBillingCycleModalContent
        onVisibilityChange={setVisibility}
        disabled={disabled}
      />
    </BillingCycleModal>
  );
};

export default AddBillingCycleModal;
