import { ThunkAction } from 'redux/types';
import { EMBED_CANVAS_COLOR_SET } from '../action-types';
import { canvasColorSelector, configCanvasColorSelector } from '../selectors';
import { saveConfiguration } from './embed';

export const setCanvasColor = (color: string) => ({
  payload: { color },
  type: EMBED_CANVAS_COLOR_SET,
});

export const saveCanvasColor = (): ThunkAction<Promise<any>> => async (
  dispatch,
  getState,
) => {
  const currentColor = canvasColorSelector(getState());
  const configColor = configCanvasColorSelector(getState());

  if (currentColor !== configColor) {
    return dispatch(saveConfiguration());
  }

  return undefined;
};
