import * as React from 'react';
import Spinner from 'react-spinkit';

import bem from 'utils/bem';

import { block } from './utils';

export interface LoadingOverlayProps {}

const LoadingOverlay: React.FC<LoadingOverlayProps> = () => {
  return (
    <div className={block('overlay')}>
      <div className={block('overlay-contents')}>
        <span className={block('overlay-title')}>Opening Image</span>
        <Spinner
          className={bem('spinner')({ blue: true, large: true, wave: true })}
          spinnerName="wave"
          noFadeIn
        />
      </div>
    </div>
  );
};

export default LoadingOverlay;
