import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import { SocialYoutube } from 'components/icons';
import { youtubeRecommendedStamp as block } from './utils';

const YouTubeRecommendedStamp: React.FC = () => {
  return (
    <Spacer
      orientation="horizontal"
      align="center"
      justify="space-between"
      className={block()}
    >
      <SocialYoutube className={block('youtube-icon')} />
      <span className={block('youtube-text')}>Recommended for YouTube</span>
    </Spacer>
  );
};

export default YouTubeRecommendedStamp;
