import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ProBadge } from 'components/icons';
import { subscriptionSelector, subtierSelector } from 'redux/modules/pricing';
import { DEFAULT_BLACKLIST } from './constants';
import { ProIndicatorBlacklist } from './types';

interface UseProIndicatorConfig {
  blacklist?: ProIndicatorBlacklist;
}

interface UseProIndicatorResult {
  Badge: typeof ProBadge | null;
  isFree: boolean;
}

export default function useProIndicator({
  blacklist = DEFAULT_BLACKLIST,
}: UseProIndicatorConfig = {}): UseProIndicatorResult {
  const { pauseSchedule } = useSelector(subscriptionSelector) || {};
  const pauseStatus = pauseSchedule?.pauseStatus ?? null;

  const isPlanPaused = pauseStatus === 'active';
  const subtier = useSelector(subtierSelector);
  const isFree = blacklist.includes(subtier) || isPlanPaused;
  const Badge = useMemo(() => (isFree ? ProBadge : null), [isFree]);

  return {
    Badge,
    isFree,
  };
}
