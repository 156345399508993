import { CarouselSettings } from 'components/Carousel';
import bem from 'utils/bem';
import { GetItemKey, UserGeneratedEmptyFrom } from './types';

export const block = bem('template-tabs');

export const blockItems = bem('template-tab-items');

export const blockUserGenerated = bem('template-user-generated');

export const carouselProps: CarouselSettings = {
  dots: false,
  slidesToScroll: 1,
  slidesToShow: 1,
  swipeToSlide: true,
  variableWidth: true,
};

export const extractItemKey: GetItemKey = (index, items) => {
  const item = items[index];
  return item.props.id;
};

export const userGeneratedEmptyTitleMessages: Record<
  UserGeneratedEmptyFrom,
  string
> = {
  createTab: 'You haven’t saved a template yet',
  templateWizard: 'No templates saved in this size',
};
