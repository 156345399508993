import * as React from 'react';
import { IconProps } from './types';

function Resize(props: IconProps) {
  return (
    <svg width={15} height={15} viewBox="0 0 15 15" {...props}>
      <path
        fill="currentColor"
        d="M0 1.071C0 .48.448 0 1 0h13c.552 0 1 .48 1 1.071V13.93c0 .59-.448 1.07-1 1.07H1c-.552 0-1-.48-1-1.071V1.07zm5 8.984H2v2.802h3v-2.802zm8-7.912H2v1.813h8c.552 0 1 .48 1 1.071v7.83h2V2.143zM9 6.099H2v1.813h4c.552 0 1 .48 1 1.072v3.873h2V6.1z"
      />
    </svg>
  );
}

export default Resize;
