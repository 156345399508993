import React from 'react';
import ConfirmationModal from 'components/ConfirmationModal';
import FontAwesome from 'components/FontAwesome';
import { H2 } from 'components/Heading';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import Modal from 'components/Modal';
import useBillingCycleModal from 'hooks/useBillingCycleModal';
import { Tier } from 'redux/middleware/api/plan-service/types';
import { replaceModal } from 'redux/modules/modal';

function FullEpisodesExportModalContent() {
  const { openBillingCycleModal } = useBillingCycleModal();

  const handleUpgradeClick = () => {
    openBillingCycleModal(
      {
        tier: Tier.PRO,
        defaultSubscriptionPeriod: 'yearly',
        disableSuccessRedirect: true,
      },
      replaceModal,
    );
  };

  return (
    <>
      <ConfirmationModal.Body>
        <ConfirmationModal.Icon>
          <CircularGradient>
            <FontAwesome icon="video" size="3x" />
          </CircularGradient>
        </ConfirmationModal.Icon>
        <ConfirmationModal.Title>
          You discovered a premium feature!
        </ConfirmationModal.Title>
        <ConfirmationModal.Content>
          <H2>Export any full episode in Full HD to get 1080p</H2>
          <H2>dimensions. Upgrade to unlock this feature and much more.</H2>
        </ConfirmationModal.Content>
      </ConfirmationModal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton theme="rainbow" onClick={handleUpgradeClick}>
            Unlock this feature
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
}

export default FullEpisodesExportModalContent;
