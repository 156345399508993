import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import TryEddyAtCancellationModalContents from './TryEddyAtCancellationModalContents';
import { block } from './utils';

export interface TryEddyAtCancellationModalProps {}

const TryEddyAtCancellationModal: React.FC<TryEddyAtCancellationModalProps> = () => (
  <ConnectedModal name="TryEddyAtCancellation" className={block()}>
    <TryEddyAtCancellationModalContents />
  </ConnectedModal>
);

export default TryEddyAtCancellationModal;
