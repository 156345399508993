import React from 'react';

import { block } from '../../utils';
import TextOverlayEffectsToolbar from './TextOverlayEffectsToolbar';
import TextOverlayFontStylesToolbar from './TextOverlayFontStylesToolbar';
import TextOverlayTextStylesToolbar from './TextOverlayTextStylesToolbar';

interface TextOverlayToolbarProps {
  showEmoji: boolean;
}

const TextOverlayToolbar: React.FunctionComponent<TextOverlayToolbarProps> = props => {
  const { showEmoji } = props;

  return (
    <div className={block('toolbar-container')}>
      <TextOverlayFontStylesToolbar />
      <TextOverlayTextStylesToolbar showEmoji={showEmoji} />
      <TextOverlayEffectsToolbar />
    </div>
  );
};

export default TextOverlayToolbar;
