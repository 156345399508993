import { useSelector } from 'react-redux';
import { useBlocker } from 'hooks/useBlocker';
import { CreationStatus } from 'redux/middleware/api/creation-service/types';
import {
  creationStatusSelector,
  pollStatusSelector,
  statusSelector,
} from 'redux/modules/wizard-export/selectors';
import { RequestStatus } from 'types/common';
import { MIXPANEL_WIZARD_STEP_MAP } from './constants';

const useVideoWizardBlocker = (stepId: string) => {
  const creationStatus = useSelector(creationStatusSelector);
  const requestStatus = useSelector(statusSelector);
  const pollStatus = useSelector(pollStatusSelector);
  const shouldAllow =
    stepId === 'aspectRatio' ||
    requestStatus === RequestStatus.FAILURE ||
    pollStatus === RequestStatus.FAILURE ||
    (stepId === 'submitting' && creationStatus === CreationStatus.COMPLETED) ||
    (stepId === 'submitting' && creationStatus === CreationStatus.FAILED) ||
    (stepId === 'submitting' &&
      creationStatus === CreationStatus.EXPORTING_VIDEO);

  return useBlocker(!shouldAllow, MIXPANEL_WIZARD_STEP_MAP[stepId]);
};

export default useVideoWizardBlocker;
