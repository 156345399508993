import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getPodcastWorkflowTemplate } from 'redux/middleware/api/podcast-service/actions';
import { podcastWorkflowTemplateSelector } from 'redux/modules/entities';
import { TemplateType, VideoTypes } from 'types';

const useEditAutomationTemplate = (
  templateId: string,
  templateType: TemplateType,
) => {
  const dispatch = useDispatch();

  const template = useSelector(
    React.useMemo(() => podcastWorkflowTemplateSelector(templateId), [
      templateId,
    ]),
  );

  const checkTemplate = React.useCallback(
    (id: string, type: TemplateType) => {
      dispatch(getPodcastWorkflowTemplate(id, type));
    },
    [dispatch],
  );

  React.useEffect(() => {
    checkTemplate(templateId, templateType);
  }, [templateId, templateType, checkTemplate]);

  const isEntireEpisodeAllowed = template?.compatibleVideoTypes.includes(
    VideoTypes.FULL_EPISODE,
  );

  return { isEntireEpisodeAllowed };
};

export default useEditAutomationTemplate;
