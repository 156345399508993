import { HeadySparkle as HeadySparkleIcon } from '@sparemin/blockhead';
import React from 'react';

import Banner from 'components/Banner';
import Button from 'components/Button';
import bem from 'utils/bem';
import { EDDY_URL } from 'utils/constants';

interface Props {
  className?: string;
  onClick?: () => void;
}

const block = bem('eddy-ai-banner');

function EddyAiBanner(props: Props) {
  const { onClick } = props;

  return (
    <Banner
      theme="blank"
      icon={
        <HeadySparkleIcon
          width="77px"
          height="67px"
          className={block('icon')}
        />
      }
    >
      <div>
        <Banner.Title>
          AI generated episode art, show notes, keywords & more!
        </Banner.Title>
        <Banner.Content>
          Let Headliner AI generate assets personalized to your podcast by
          uploading audio into our new app called Eddy.
        </Banner.Content>
      </div>
      <Banner.Actions>
        <Button
          theme="submit"
          onClick={onClick}
          href={`${EDDY_URL}/dashboard`}
          target="_blank"
          rel="noopener noreferrer"
        >
          try our AI
        </Button>
      </Banner.Actions>
    </Banner>
  );
}

export default EddyAiBanner;
