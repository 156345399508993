import React from 'react';

import { IconProps } from './types';

const Threads: React.FunctionComponent<IconProps> = ({
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <radialGradient
        cx="42.983%"
        cy="0%"
        fx="42.983%"
        fy="0%"
        r="119.324%"
        gradientTransform="matrix(.20986 .83801 -.98643 .18147 .34 -.36)"
        id="7oklvssiec"
      >
        <stop stopColor="#F9D262" offset="0%" />
        <stop stopColor="#F6A650" offset="22.135%" />
        <stop stopColor="#F14E2C" offset="50.167%" />
        <stop stopColor="#98069F" offset="82.431%" />
        <stop stopColor="#5E18D1" offset="100%" />
      </radialGradient>
      <filter
        x="-50%"
        y="-40.7%"
        width="200%"
        height="185.7%"
        filterUnits="objectBoundingBox"
        id="i0fglqinra"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="6.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path
        d="M61.126 48.296c-.2-.097-.404-.19-.61-.278-.359-6.637-3.972-10.437-10.039-10.476h-.082c-3.63 0-6.647 1.555-8.505 4.384l3.337 2.297c1.388-2.113 3.565-2.563 5.17-2.563h.055c1.997.012 3.505.595 4.48 1.732.71.828 1.185 1.972 1.42 3.415-1.77-.302-3.686-.395-5.734-.277-5.768.333-9.476 3.71-9.227 8.402.126 2.38 1.307 4.428 3.326 5.765 1.706 1.13 3.904 1.684 6.189 1.559 3.016-.166 5.383-1.322 7.034-3.434 1.254-1.604 2.047-3.683 2.397-6.302 1.438.87 2.503 2.016 3.092 3.394 1 2.342 1.059 6.19-2.07 9.328-2.74 2.748-6.035 3.937-11.015 3.974-5.523-.041-9.7-1.82-12.416-5.285C35.384 60.686 34.07 56 34.02 50c.05-6 1.363-10.686 3.907-13.931 2.716-3.466 6.893-5.244 12.416-5.285 5.564.042 9.814 1.828 12.634 5.31 1.382 1.708 2.425 3.855 3.112 6.36L70 41.405c-.833-3.082-2.144-5.738-3.927-7.94-3.616-4.464-8.903-6.752-15.715-6.8h-.027c-6.799.048-12.027 2.344-15.54 6.825-3.125 3.988-4.737 9.537-4.791 16.493v.032c.054 6.956 1.666 12.505 4.792 16.493 3.512 4.48 8.74 6.777 15.539 6.824h.027c6.044-.042 10.305-1.63 13.814-5.15 4.592-4.604 4.454-10.376 2.94-13.92-1.085-2.54-3.155-4.604-5.986-5.967zM50.69 58.144c-2.528.143-5.155-.996-5.284-3.436-.096-1.808 1.282-3.827 5.439-4.067.476-.028.943-.041 1.402-.041 1.51 0 2.922.147 4.206.429-.479 6.004-3.288 6.979-5.763 7.115z"
        id="q86cqxlsnb"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle
        fill="#FFF"
        transform="rotate(180 50 50)"
        cx="50"
        cy="50"
        r="50"
      />
      <use fill="#000" filter="url(#i0fglqinra)" xlinkHref="#q86cqxlsnb" />
      <use fill="url(#7oklvssiec)" xlinkHref="#q86cqxlsnb" />
    </g>
  </svg>
);

export default Threads;
