import React from 'react';
import { optionTileBlock as block } from '../utils';

interface BrandingBannerProps {
  integratorBrand?: 'acast';
}

const BrandingBanner: React.FunctionComponent<BrandingBannerProps> = props => {
  const { integratorBrand } = props;

  switch (integratorBrand) {
    case 'acast':
      return (
        <div className={block('brand-banner', { acast: true })}>
          Acast branded
        </div>
      );
    default:
      return null;
  }
};

export default BrandingBanner;
