import * as React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

function Blur(props: IProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="0 0 30 30"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#282E38"
          d="M4 0h22c2.21 0 4 1.79 4 4v22c0 2.21-1.79 4-4 4H4c-2.21 0-4-1.79-4-4V4c0-2.21 1.79-4 4-4z"
        />
        <path
          fill="#DCE1EB"
          d="M15 19.308a1.845 1.845 0 11.001 3.69 1.845 1.845 0 01-.001-3.69zm-6.154.615a1.23 1.23 0 110 2.462 1.23 1.23 0 110-2.461zm12.308 0a1.23 1.23 0 110 2.462 1.23 1.23 0 110-2.461zM15 12.538a2.462 2.462 0 110 4.925 2.462 2.462 0 010-4.925zm6.154.616a1.845 1.845 0 11.001 3.69 1.845 1.845 0 01-.001-3.69zm-12.308 0a1.845 1.845 0 11.001 3.69 1.845 1.845 0 01-.001-3.69zM15 7a1.846 1.846 0 110 3.692A1.846 1.846 0 0115 7zm-6.154.615a1.23 1.23 0 110 2.458 1.23 1.23 0 010-2.457zm12.308 0a1.23 1.23 0 110 2.458 1.23 1.23 0 010-2.457z"
        />
      </g>
    </svg>
  );
}

export default Blur;
