import { groupBy } from 'underscore';
import { TemplateType } from 'types';
import bem from 'utils/bem';
import { TEMPLATE_TAB_KEY_ALL } from 'utils/constants';
import { Templates } from './types';

export const blockProjectTemplates = bem('project-templates');

export const blockProjectTemplateIcons = bem('project-template-icons');

export const blockProjectTemplateItems = bem('project-template-items');

export const blockDeleteTemplateModal = bem('delete-template-modal');

export const blockProjectTemplateTabs = bem('project-template-tabs');

export function getTemplatesByFilter(templates: Templates, filterKey: string) {
  const templatesByType = groupBy(
    Object.values(templates),
    template => template.type,
  );

  if (filterKey === TEMPLATE_TAB_KEY_ALL) {
    return [
      ...(templatesByType[TemplateType.USER_GENERATED] ?? []),
      ...(templatesByType[TemplateType.HEADLINER_DEFAULT] ?? []),
    ];
  }

  return templatesByType[filterKey] ?? [];
}
