import * as React from 'react';

import { IconProps } from './types';

const Ban: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeWidth="3"
      transform="translate(2 2)"
    >
      <circle cx="25" cy="25" r="25" />
      <path strokeLinecap="square" d="M42.654 7.475L7.527 42.359" />
    </g>
  </svg>
);

export default Ban;
