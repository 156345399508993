import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import usePlanEndDateString from 'blocks/Pricing/usePlanEndDateString';
import DialogContent from 'components/DialogContent';
import CircularGradientGift from 'components/icons/circular-gradient/CircularGradientGift';
import { onShowFreeTrialModal } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';

export interface FreeTrialWelcomeModalContentsProps {
  onHide?: () => void;
}

const FreeTrialWelcomeModalContents: React.FC<FreeTrialWelcomeModalContentsProps> = ({
  onHide,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const expirationDateString = usePlanEndDateString();

  useEffect(() => {
    dispatch(onShowFreeTrialModal('RUXWelcome'));
  }, [dispatch]);

  return (
    <DialogContent
      actions={[
        {
          children: 'start creating',
          fluid: true,
          key: 'submit',
          onClick: onHide,
          theme: 'rainbow',
        },
      ]}
      details={`You've activated your free trial of Headliner Pro, enjoy all of our premium features until your trial ends on ${expirationDateString}`}
      icon={<CircularGradientGift />}
      title="Congrats, you've gone Pro!"
    />
  );
};

export default FreeTrialWelcomeModalContents;
