import * as React from 'react';

import Button from 'components/Button';
import { block } from '../utils';
import FontImportModal from './FontImportModal';

interface FontUploadProps {
  fontsAvailable?: boolean;
}

function FontUpload({ fontsAvailable }: FontUploadProps) {
  const [showFontImportModal, setShowFontImportModal] = React.useState(false);

  function handleClick() {
    setShowFontImportModal(true);
  }

  return (
    <>
      <Button
        theme="submit"
        onClick={handleClick}
        className={block('button-action')}
      >
        {fontsAvailable ? 'ADD FONT' : 'ADD YOUR FIRST FONT'}
      </Button>
      <FontImportModal
        show={showFontImportModal}
        title="ADD FONT"
        onHide={() => setShowFontImportModal(false)}
      />
    </>
  );
}

export default FontUpload;
