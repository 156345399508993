import { BLANK_CANVAS_TEMPLATE_ID } from './constants';

export function isBlankCanvas(templateId: string) {
  // before we had an actual blank template with an id, we used the id "blank"
  // to indicate blank canvas.  while that should no longer be in use, the first
  // check here ensures that we return the correct result if we see that id
  return (
    templateId === 'blank' ||
    Object.values(BLANK_CANVAS_TEMPLATE_ID).includes(templateId)
  );
}
