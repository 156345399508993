import React from 'react';

import { useTextOverlay } from '../state/TextOverlayProvider';
import { mapDraftEditorDataToTextOverlay } from '../style-export-utils';
import { TextOverlayV2 } from '../types';
import useTextOverlayStyle from './useTextOverlayStyle';

interface UseTextOverlayPartialSubmitConfig {
  onPartialSubmit?: (overlay: TextOverlayV2) => void;
}

type UseTextOverlayPartialSubmit = () => void | undefined;

const useTextOverlayPartialSubmit = (
  config: UseTextOverlayPartialSubmitConfig,
): UseTextOverlayPartialSubmit => {
  const { onPartialSubmit } = config;

  const {
    canvasSize,
    draftEditorData,
    editorHtmlRef,
    overlayTimingExtension,
    textValue,
  } = useTextOverlay();

  const { textBuilderStyles } = useTextOverlayStyle();

  // the timing extension will append the time entry to the output overlay
  // if necessary.
  const { appendTimingToOverlay } = overlayTimingExtension;

  const handlePartialSubmit = React.useMemo(
    () => () => {
      const htmlString = String(editorHtmlRef.current?.innerHTML);

      const customizedTextOverlay = mapDraftEditorDataToTextOverlay(
        draftEditorData,
        htmlString,
        textValue,
        canvasSize,
        textBuilderStyles,
      );

      onPartialSubmit(appendTimingToOverlay(customizedTextOverlay));
    },
    [
      appendTimingToOverlay,
      canvasSize,
      draftEditorData,
      editorHtmlRef,
      onPartialSubmit,
      textBuilderStyles,
      textValue,
    ],
  );

  return onPartialSubmit ? handlePartialSubmit : undefined;
};

export default useTextOverlayPartialSubmit;
