import React from 'react';
import LinkButton from 'components/LinkButton';
import SelectMenuContainer from './SelectMenuContainer';
import SelectOptions from './SelectOptions';
import { Option, SelectMenuProps } from './types';
import { block } from './utils';

export interface SelectActionMenuProps<T extends Option = Option>
  extends SelectMenuProps<T> {
  ctaLabel: string;
  onCtaClick: () => void;
}

/**
 * A select menu with a clickable header that is used to perform an action
 */
function SelectActionMenu<T extends Option = Option>({
  ctaLabel: header,
  onChange,
  onCtaClick,
  options,
  value,
}: SelectActionMenuProps<T>) {
  return (
    <SelectMenuContainer>
      <LinkButton
        className={block('menu-cta')}
        onClick={onCtaClick}
        theme="cta"
      >
        {header}
      </LinkButton>
      <SelectOptions {...{ onChange, options, value }} />
    </SelectMenuContainer>
  );
}

export default SelectActionMenu;
