import { Set } from 'immutable';
import { isEqual, isUndefined } from 'underscore';

import Audio from 'utils/audio';
import { AudioClips } from './types';

export const PLAYER_STEP_IN_SEC = 5;

export function clipsChanged(audio: Audio, clips: AudioClips) {
  const audioClipIds = Set(audio.sourceIds);
  return !audioClipIds.equals(clips.ids);
}

export function updateClips(audio: Audio, clips: AudioClips) {
  Object.values(clips.data).forEach(
    ({ id, startOffsetSeconds, audioLevels, fromSeconds, toSeconds }) => {
      if (
        !isUndefined(startOffsetSeconds) &&
        startOffsetSeconds !== audio.getSourceAudioOffset(id)
      ) {
        audio.setSourceAudioOffsetSeconds(id, startOffsetSeconds);
      }

      if (
        !isUndefined(audioLevels) &&
        !isEqual(audioLevels, audio.getAudioLevels(id))
      ) {
        audio.setAudioLevels(id, audioLevels);
      }

      if (fromSeconds !== audio.getSourceFromSeconds(id)) {
        audio.setSourceFromSeconds(id, fromSeconds);
      }

      if (toSeconds !== audio.getSourceToSeconds(id)) {
        audio.setSourceToSeconds(id, toSeconds);
      }
    },
  );
}
