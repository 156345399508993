import { useEffect } from 'react';
import { noop } from 'underscore';
import { OneOrMore } from 'types';
import { asArray } from 'utils/collections';
import { loadElementFonts } from 'utils/fonts';
import useStaticCallback from './useStaticCallback';

export interface UseLoadElementFontsConfig {
  html: OneOrMore<string>;
  onComplete?: (status: 'success' | 'failure') => void;
}

/**
 * Load fonts used by elements in an html string
 */
export default function useLoadElementFonts({
  html,
  onComplete = noop,
}: UseLoadElementFontsConfig) {
  const htmls = asArray(html);
  const onCompleteCallback = useStaticCallback(onComplete);

  useEffect(() => {
    let cancelled = false;

    async function loadFonts() {
      try {
        await loadElementFonts(htmls);

        if (!cancelled) {
          onCompleteCallback('success');
        }
      } catch {
        if (!cancelled) {
          onCompleteCallback('failure');
        }
      }
    }

    loadFonts();

    return () => {
      cancelled = true;
    };
  }, [htmls, onCompleteCallback]);
}
