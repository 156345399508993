import cn from 'classnames';
import * as React from 'react';

import FormInput from 'components/FormInput';
import MaxLengthTextarea, {
  MaxLengthTextareaProps,
} from 'components/MaxLengthTextarea';
import Modal, { ModalProps } from 'components/Modal';
import usePrevious from 'hooks/usePrevious';
import bem from 'utils/bem';

const { useCallback, useEffect, useState } = React;

type PickedModalProps = Pick<
  ModalProps,
  'onExited' | 'onHide' | 'show' | 'title'
>;
type PickedInputProps = Pick<
  MaxLengthTextareaProps,
  'maxChars' | 'placeholder'
>;

export interface TextInputModalProps
  extends PickedModalProps,
    Partial<PickedInputProps> {
  className?: string;
  defaultValue?: string;
  onSubmit?: (value: string) => void;
  submitText?: string;
  noCancelButton?: boolean;
}

const block = bem('text-input-modal');

const TextInputModal: React.FC<TextInputModalProps> = ({
  className,
  defaultValue = '',
  maxChars,
  placeholder,
  onExited,
  onHide,
  onSubmit,
  show,
  title,
  noCancelButton,
  submitText,
}) => {
  const [value, setValue] = useState(defaultValue);
  const prevShow = usePrevious(show);

  const handleClick = useCallback(() => {
    onSubmit(value);
  }, [value, onSubmit]);

  useEffect(() => {
    if (show && !prevShow) {
      setValue(defaultValue);
    }
  }, [defaultValue, show, prevShow]);

  return (
    <Modal
      backdrop="static"
      className={cn(block(), className)}
      {...{ onExited, onHide, show, title }}
    >
      <Modal.Body className={block('body')}>
        {maxChars ? (
          <MaxLengthTextarea
            className={block('input-container')}
            inputClassName={block('input')}
            maxChars={maxChars}
            onChange={setValue}
            placeholder={placeholder}
            type="input"
            value={value}
          />
        ) : (
          <fieldset className={block('input-container')}>
            <FormInput
              bsSize="large"
              type="input"
              controlSizes={{ xs: 12 }}
              className={block('input')}
              value={value}
              onChange={(e: any) => setValue(e.target.value)}
            />
          </fieldset>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButtons>
          {!noCancelButton && (
            <Modal.FooterButton onClick={onHide}>Cancel</Modal.FooterButton>
          )}
          <Modal.FooterButton
            theme="submit"
            onClick={handleClick}
            disabled={value.length === 0}
            fluid={noCancelButton}
          >
            {submitText || 'save'}
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

export default TextInputModal;
