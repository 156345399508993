import cn from 'classnames';
import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import List from 'components/List';
import { episodeListBlock as block } from '../../utils';

type PickedHtmlProps = Pick<React.HTMLProps<any>, 'onMouseOver' | 'onMouseOut'>;

export interface EpisodeListRowContainerProps extends PickedHtmlProps {
  children?: React.ReactNode;
  className?: string;
  expanderClassName?: string;
  collapsible?: boolean;
}

/**
 * all of the contents in the "collapsed" view of the row. this typically
 * contains the artwork, description and status
 */
const EpisodeListRowContainer: React.FC<EpisodeListRowContainerProps> = ({
  children,
  className,
  expanderClassName,
  onMouseOut,
  onMouseOver,
  collapsible = false,
}) => (
  <List.Item.Contents className={className} {...{ onMouseOut, onMouseOver }}>
    {children}
    {collapsible && (
      <FontAwesome
        className={cn(block('expander'), expanderClassName)}
        icon="angle-down"
        style={{ height: 25, width: 'auto' }}
      />
    )}
  </List.Item.Contents>
);

export default EpisodeListRowContainer;
