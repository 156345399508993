import { Record, RecordOf } from 'immutable';

interface IDataState {
  errorMessage?: string;
}

export interface IState {
  data: RecordOf<IDataState>;
}

export const dataFactory = Record<IDataState>({ errorMessage: undefined });
