import * as React from 'react';

import Modal, { ModalProps } from 'components/Modal';

export interface ConnectedModalComponentProps
  extends Pick<
    ModalProps,
    | 'show'
    | 'title'
    | 'className'
    | 'closeButton'
    | 'backdrop'
    | 'onExited'
    | 'onHide'
    | 'size'
    | 'keyboard'
    | 'v2'
  > {
  params?: any;
}

export interface ConnectedModalInjectedProps {
  // Prop is injected and won't be declared directly on the component, so has to
  // be marked as optional so that TS won't complain when it's missing
  onHide?: (value?: any) => void;
}

const ConnectedModalComponent: React.FC<ConnectedModalComponentProps> = ({
  children,
  title,
  className,
  closeButton,
  backdrop,
  onExited,
  onHide,
  params,
  show,
  size,
  v2,
}) => (
  <Modal
    {...{
      backdrop,
      show,
      title,
      className,
      closeButton,
      onExited,
      onHide,
      size,
      v2,
    }}
  >
    {React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          ...params,
          onHide,
        });
      }
      return child;
    })}
  </Modal>
);

export default ConnectedModalComponent;
