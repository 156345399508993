import * as React from 'react';

import MaskedDurationInput, {
  MaskedDurationInputProps,
} from 'components/MaskedDurationInput';
import FormField, { FormFieldProps } from './FormField';
import { block } from './utils';

type PickedInputProps = Pick<
  MaskedDurationInputProps,
  'mask' | 'minMillis' | 'maxMillis' | 'onChange' | 'onBlur'
>;

type PickedMaskedDurationInputProps = Pick<MaskedDurationInputProps, 'name'>;

interface IProps extends PickedInputProps, PickedMaskedDurationInputProps {
  /**
   * duration value in milliseconds
   */
  value?: MaskedDurationInputProps['millis'];
}

type Props = FormFieldProps<IProps>;

const DurationField: React.SFC<Props> = ({ value, name, ...props }) => (
  <FormField<IProps> {...props} light>
    <MaskedDurationInput
      className={block('duration-input')}
      millis={value}
      autoComplete="off"
      {...{ name }}
    />
  </FormField>
);

export { Props as DurationFieldProps };
export default DurationField;
