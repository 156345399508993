import * as React from 'react';

import DialogContent, {
  Props as DialogContentProps,
} from 'components/DialogContent';
import CircularGradientVideoCameraAlt from 'components/icons/circular-gradient/CircularGradientVideoCameraAlt';
import { UploadLimit, Variant } from './types';

type PickedDialogProps = Pick<DialogContentProps, 'actions' | 'title'>;

export interface FileSizeUpsellDialogProps extends PickedDialogProps {
  className?: string;
  uploadLimit?: UploadLimit;
  variant: Variant;
}

const DEFAULT_UPLOAD_LIMIT = '500MB';
const UPLOAD_LIMIT_WILDCARD = '{uploadLimit}';
const TITLES: Record<Variant, string> = {
  default: `Increase your upload limit to ${UPLOAD_LIMIT_WILDCARD}`,
  file: 'This file is too big for our free tier',
};

const getTitle = (uploadLimit: UploadLimit, variant: Variant): string => {
  return TITLES[variant]?.replace(UPLOAD_LIMIT_WILDCARD, uploadLimit);
};

const FileSizeUpsellDialog: React.FC<FileSizeUpsellDialogProps> = ({
  actions,
  className,
  uploadLimit = DEFAULT_UPLOAD_LIMIT,
  variant,
}) => (
  <DialogContent
    {...{ actions, className }}
    icon={<CircularGradientVideoCameraAlt />}
    title={getTitle(uploadLimit, variant)}
    details={`Our Forever Free plan only supports up to 350MB file uploads, but you can upgrade to increase your upload limit to ${uploadLimit}`}
  />
);

export default FileSizeUpsellDialog;
