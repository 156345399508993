import * as React from 'react';
import { IconProps } from './types';

const Linkedin: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="105"
    height="105"
    viewBox="17 5 70 90"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M65.857 34h-25.72c-1.18 0-2.137.971-2.137 2.163v25.674C38 63.03 38.958 64 40.136 64h25.721C67.036 64 68 63.029 68 61.837V36.163A2.157 2.157 0 0065.857 34zm-18.79 25.714h-4.446V45.397h4.453v14.317h-.007zm-2.223-16.272a2.58 2.58 0 010-5.156 2.584 2.584 0 012.578 2.578 2.575 2.575 0 01-2.578 2.578zm18.89 16.272h-4.446V52.75c0-1.66-.034-3.797-2.31-3.797-2.317 0-2.672 1.808-2.672 3.676v7.085h-4.447V45.397h4.266v1.956h.06c.596-1.125 2.05-2.31 4.212-2.31 4.5 0 5.337 2.966 5.337 6.823v7.848z"
    />
  </svg>
);

export default Linkedin;
