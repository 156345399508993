import { Record } from 'immutable';

export type Axis = 'x' | 'y' | 'both' | 'none';

export interface ICoords {
  x?: number;
  y?: number;
}

export type ISnapPoint = ICoords;

export interface ISnapState {
  axis: Axis;
  x?: number;
  y?: number;
}

export interface IResizeState {
  resizing: boolean;
}

export const snapFactory = Record<ISnapState>({
  axis: undefined,
  x: undefined,
  y: undefined,
});

export const resizeFactory = Record<IResizeState>({
  resizing: undefined,
});
