import { connect } from 'react-redux';

import { actions as embedActions } from 'redux/modules/embed';
import * as notificationActions from 'redux/modules/notification';
import { Dispatch, State } from 'redux/types';
import { NotificationsProps } from './Notifications';

const { selectors } = notificationActions;

type StateProps = Pick<NotificationsProps, 'notifications'>;
type DispatchProps = Pick<
  NotificationsProps,
  'dismissNotification' | 'saveConfiguration' | 'onClickAction'
>;

const mapStateToProps = (state: State): StateProps => ({
  notifications: selectors.mapStateToNotifications(state) as any,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  dismissNotification: id =>
    dispatch(notificationActions.clearNotification(id)),
  onClickAction: action => dispatch(action),
  saveConfiguration: () => dispatch(embedActions.saveConfiguration()),
});

export default function(component: React.ComponentType<NotificationsProps>) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
