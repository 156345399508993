import { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';

import { podcastsSelector } from 'redux/modules/entities/selectors';
import { showError } from 'redux/modules/notification/actions';
import { podcastResultsSelector } from 'redux/modules/podcast-search';
import {
  clearSearchResults,
  searchPodcastsByName,
  setSelectedPodcast,
} from 'redux/modules/podcast-search/actions';
import { Dispatch, State } from 'redux/types';
import { ReadyState } from 'types';

export default function usePreloadedPodcast(podcastId: string) {
  const store = useStore<State>();
  const dispatch = useDispatch<Dispatch>();

  const [status, setStatus] = useState<ReadyState>('idle');

  useEffect(() => {
    const loadPodcast = async () => {
      if (!podcastId) {
        setStatus('ready');
        return;
      }

      try {
        setStatus('loading');

        const podcasts = podcastsSelector(store.getState());
        const podcastTitle = podcasts.getIn([podcastId, 'title']);

        dispatch(clearSearchResults());

        // podcast-service endpoints sometimes return different ids for the same
        // podcast.  in order to make sure podcast-search components render correctly,
        // make sure to call the setSelectedPodcast action with an id that comes
        // back from searching for podcasts
        await dispatch(searchPodcastsByName(podcastTitle));

        const searchResultIds = podcastResultsSelector(store.getState());
        const resultPodcastId = searchResultIds.get(0);
        dispatch(setSelectedPodcast(resultPodcastId));

        setStatus('ready');
      } catch (err) {
        dispatch(showError('Error loading podcast', 5));
        setStatus('error');
      }
    };

    loadPodcast();
  }, [dispatch, podcastId, store]);

  return { status };
}
