import * as React from 'react';

import HistoricalColorPicker from 'containers/HistoricalColorPicker';
import OptionTile, { OptionTileIconProps, OptionTileProps } from './OptionTile';

const { useCallback, useState } = React;

type PickedPickerProps = Pick<
  React.ComponentProps<typeof HistoricalColorPicker>,
  'color' | 'onChangeComplete' | 'disableAlpha'
>;
type PickedTileProps = Pick<OptionTileProps, 'disabled' | 'id' | 'label'>;

interface IconProps extends OptionTileIconProps {
  style: React.CSSProperties;
}

export interface ColorOptionTileProps
  extends PickedPickerProps,
    PickedTileProps {
  icon: React.ReactElement<IconProps>;
  className?: string;
}

const ColorOptionTile: React.FC<ColorOptionTileProps> = ({
  className,
  disabled,
  color,
  icon,
  id,
  label,
  onChangeComplete,
  disableAlpha,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = useCallback(() => setShow(false), []);
  const handleOpen = useCallback(() => setShow(true), []);

  return (
    <div className={className}>
      <HistoricalColorPicker
        color={color}
        disabled={disabled}
        disableAlpha={disableAlpha}
        displayColorPicker={show}
        onChangeComplete={onChangeComplete}
        onClose={handleClose}
        onOpen={handleOpen}
        renderSwatch={({ onClick, domRef }) => (
          <OptionTile
            active={show}
            disabled={disabled}
            icon={React.cloneElement(icon, {
              style: {
                ...icon.props.style,
                color,
              },
            })}
            id={id}
            label={label}
            onClick={onClick}
            ref={domRef}
          />
        )}
      />
    </div>
  );
};

export default ColorOptionTile;
