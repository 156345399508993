import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isUndefined } from 'underscore';

import { getPodcastEpisodes } from 'redux/modules/podcast-search/actions';
import {
  isLoadingEpisodesSelector,
  selectedPodcastEpisodeIdsSelector,
  selectedPodcastSelector,
} from 'redux/modules/podcast-search/selectors';
import { Dispatch, State } from 'redux/types';
import EpisodeExplorer, {
  EpisodeExplorerProps as Props,
} from './EpisodeExplorer';

type StateProps = Pick<Props, 'artworkUrl' | 'hasResults' | 'isLoading'>;
type DispatchProps = Pick<Props, 'onMount'>;

const artworkUrlSelector = createSelector(
  selectedPodcastSelector,
  podcast => podcast?.thumbnailUrl,
);

const hasResultsSelector = createSelector(
  selectedPodcastEpisodeIdsSelector,
  ids => !isUndefined(ids),
);

const mapStateToProps = (state: State): StateProps => ({
  artworkUrl: artworkUrlSelector(state),
  hasResults: hasResultsSelector(state),
  isLoading: isLoadingEpisodesSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onMount: hasResults => {
    // only fetch episodies if we don't have any results (e.g. component mounts
    // when user clicks on a podcast).  if we have results, component is just
    // remounting with the same state
    if (!hasResults) {
      return dispatch(getPodcastEpisodes());
    }
    return undefined;
  },
});

const component = connect(mapStateToProps, mapDispatchToProps)(EpisodeExplorer);
export type EpisodeExplorerProps = React.ComponentPropsWithoutRef<
  typeof component
>;
export default component;
