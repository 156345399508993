import cn from 'classnames';
import * as React from 'react';
import { Row } from 'react-bootstrap';
import { noop } from 'underscore';

import { getFileInputValue } from 'blocks/VideoExportModal/utils';
import { FrameRate, FrameSize, PropUpgradeFrom } from 'types';

import { FormErrorState, FormState } from '../../types';
import EmailAddressField from './EmailAddressField';
import ExportSpeedField from './ExportSpeedField';
import FrameRateField from './FrameRateField';
import UploadVideoField, { UploadArgs } from './UploadVideoField';
import { block } from './utils';
import VideoSizeField from './VideoSizeField';

type Field = keyof FormState;
export type LockableField = Exclude<Field, 'email'>;

interface IOnChange {
  (value: UploadArgs, field: 'intro' | 'outro'): void;
  (value: FrameRate, field: 'frameRate'): void;
  (value: string, field: 'email'): void;
  (value: FrameSize, field: 'size'): void;
  (value: any, field: Field): void;
}

interface IProps {
  className?: string;
  errors?: FormErrorState;
  hideEmailInput?: boolean;
  lockedFields?: Set<LockableField>;
  onChange?: IOnChange;
  onPriorityQueueClick: () => void;
  onUpgradePlan: (from?: PropUpgradeFrom) => void;
  value?: FormState;
}

export default class VideoExportForm extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    hideEmailInput: false,
    lockedFields: new Set(),
    onChange: noop,
    value: {},
  };

  private handleEmailChange = (email: string[]) =>
    this.props.onChange(email, 'email');

  private handleFrameRateChange = (rate: number) =>
    this.props.onChange(rate, 'frameRate');

  private handleSizeChange = (size: string) =>
    this.props.onChange(size, 'size');

  private handleIntroFileChange = (intro: UploadArgs) =>
    this.props.onChange(intro, 'intro');

  private handleOutroFileChange = (outro: UploadArgs) =>
    this.props.onChange(outro, 'outro');

  public render() {
    const {
      className,
      errors,
      hideEmailInput,
      lockedFields,
      onPriorityQueueClick,
      onUpgradePlan,
      value,
    } = this.props;

    return (
      <Row componentClass="form" className={cn(block(), className)}>
        {!hideEmailInput && (
          <EmailAddressField
            onChange={this.handleEmailChange}
            value={value.email}
            errors={errors?.email}
          />
        )}
        <FrameRateField
          onChange={this.handleFrameRateChange}
          value={value.frameRate}
          variant={lockedFields.has('frameRate') ? 'locked' : 'default'}
        />
        <VideoSizeField
          onChange={this.handleSizeChange}
          value={value.size}
          variant={lockedFields.has('size') ? 'locked' : 'default'}
        />
        <UploadVideoField
          label="Intro Clip"
          onChange={this.handleIntroFileChange}
          onUpgradePlan={onUpgradePlan}
          tooltipId="export-intro-clip"
          value={getFileInputValue(value.intro?.value, value.intro?.type)}
          locked={lockedFields.has('intro')}
        >
          Add Intro
        </UploadVideoField>
        <UploadVideoField
          label="Outro Clip"
          onChange={this.handleOutroFileChange}
          onUpgradePlan={onUpgradePlan}
          tooltipId="export-outro-clip"
          value={getFileInputValue(value.outro?.value, value.outro?.type)}
          locked={lockedFields.has('outro')}
        >
          Add Outro
        </UploadVideoField>
        <ExportSpeedField
          label="Export Speed"
          tooltipId="export-speed"
          onChange={onPriorityQueueClick}
        />
      </Row>
    );
  }
}

export { IProps as VideoExportFormProps };
