import * as React from 'react';

import { IconProps } from './types';

const ThinWand: React.SFC<IconProps> = props => (
  <svg width={50} height={49} viewBox="0 0 50 49" {...props}>
    <g fill="#14191E" fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M21.976 9.798l1.508-3.016L26.5 5.274l-3.016-1.509L21.976.75l-1.508 3.016-3.016 1.509 3.016 1.508 1.508 3.016zM8.403 15.831l2.513-5.027 5.027-2.514-5.027-2.514L8.403.749 5.89 5.776.862 8.29l5.028 2.514 2.513 5.027zm33.179 12.065l-2.513 5.027-5.028 2.514 5.028 2.514 2.513 5.027 2.513-5.027 5.028-2.514-5.028-2.514-2.513-5.027zM40.53 4.377l4.964 4.964c.943.943.943 2.47 0 3.413L10.748 47.5a2.413 2.413 0 01-3.412 0l-4.964-4.964a2.413 2.413 0 010-3.412L37.118 4.377a2.414 2.414 0 013.413 0zM5.01 40.83l4.033 4.033 33.815-33.816-4.033-4.033L5.01 40.83z"
      />
      <path d="M30.138 14.77l6.204 6.205-24.818 24.819a2.633 2.633 0 01-3.723 0l-2.482-2.482a2.633 2.633 0 010-3.723L30.138 14.77z" />
    </g>
  </svg>
);

export default ThinWand;
