import { IMediaMetadata } from 'redux/middleware/api/image-search-service/types';
import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'KEYWORD_EXTRACTOR_SERVICE';
export const ACTION_KEY: ActionKey = 'KEYWORD_EXTRACTOR_SERVICE';

export enum ServiceMethod {
  ANALYZE_RECORDING_VERSION = 'ANALYZE_RECORDING_VERSION',
  ANALYZE_TEXT_BLOB = 'ANALYZE_TEXT_BLOB',
  SUMMARIZE = 'SUMMARIZE',
}

export type Id = string | number;

export interface IAspectRatio {
  height: number;
  width: number;
}

interface IKeywordDetail {
  estAppearancesTranscript: number[];
  freqDesc?: number;
  keyword: string;
  knowledgeMid?: string;
  searchTerm: string;
  wikiTitle?: string;
}

interface IKeyword {
  detail: IKeywordDetail[];
  id: Id;
}

interface IImageRecommendationDetail {
  assetDetailType: 'imageAssetDetail';
  imageEffect: string;
  entryTransition: string;
  origImageUrl: string;
  recommendationUrl: string;
  imageMetadata: IMediaMetadata;
  isBackfill: boolean;
}

interface IVideoTransition {
  durationMilli: number;
  // TODO string literals for to and effect
  to: string;
  effect: string;
}

interface IVideoRecommendationDetail {
  assetDetailType: 'videoAssetDetail';
  videoId: number;
  playOffsetMilli: number;
  transition: {
    out: IVideoTransition;
    in: IVideoTransition;
  };
}

interface IRecommendedAsset<T extends 'image' | 'video'> {
  keyword: string;
  startMilli: number;
  endMilli: number;
  type: T;
  assetDetail: T extends 'image'
    ? IImageRecommendationDetail
    : T extends 'video'
    ? IVideoRecommendationDetail
    : never;
}

export type RecommendedImageAsset = IRecommendedAsset<'image'>;
export type RecommendedVideoAsset = IRecommendedAsset<'video'>;

interface IBaseNormalizedAnalysis<T extends string | number> {
  result: T;
  isResolved: boolean;
  typedRecommendation: Array<RecommendedImageAsset | RecommendedVideoAsset>;
}

interface INormalizedRecordingAnalysis extends IBaseNormalizedAnalysis<number> {
  entities: {
    keywords: { [id: number]: IKeyword };
  };
}

interface INormalizedTextBlobAnalysis extends IBaseNormalizedAnalysis<string> {
  entities: {
    keywords: { [id: string]: IKeyword };
  };
}

interface INormalizedSummary {
  entities: {
    summaries: {
      parsedText: string;
      summarizedText: string;
      urlSummaryId: string;
      summaryDetails: Array<{
        pos: number;
        text: string;
      }>;
    };
  };
  result: string;
}

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export type AnalyzeRecordingVersionArgs = [number, boolean, IAspectRatio];
export type AnalyzeRecordingVersionResult = INormalizedRecordingAnalysis;
export type AnalyzeRecordingVersionAction = ApiAction<
  ServiceMethod.ANALYZE_RECORDING_VERSION,
  AnalyzeRecordingVersionArgs
>;

export type AnalyzeTextBlobArgs = [string, boolean, IAspectRatio];
export type AnalyzeTextBlobResult = INormalizedTextBlobAnalysis;
export type AnalyzeTextBlobAction = ApiAction<
  ServiceMethod.ANALYZE_TEXT_BLOB,
  AnalyzeTextBlobArgs
>;

export type SummarizeArgs = [string];
export type SummarizeAction = ApiAction<ServiceMethod.SUMMARIZE, SummarizeArgs>;

export interface KeywordExtractorServiceDispatch {
  (action: AnalyzeRecordingVersionAction): Promise<
    IApiResponse<AnalyzeRecordingVersionResult>
  >;
  (action: AnalyzeTextBlobAction): Promise<IApiResponse<AnalyzeTextBlobResult>>;
  (action: SummarizeAction): Promise<IApiResponse<INormalizedSummary>>;
}
