import * as React from 'react';

import { IconProps } from 'components/icons/types';

/* tslint:disable-next-line:variable-name */
const InstagramStories: React.SFC<IconProps> = (props: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" {...props}>
    <path
      fill="currentColor"
      /* tslint:disable-next-line:max-line-length */
      d="M44.78 90a45.086 45.086 0 0 1-21.16-5.277 3.105 3.105 0 1 1 2.925-5.477 38.843 38.843 0 0 0 18.235 4.546 39 39 0 0 0 8.846-1.013 3.105 3.105 0 1 1 1.411 6.047A45.27 45.27 0 0 1 44.78 90zM15.981 78.527a3.09 3.09 0 0 1-2.134-.851C7.524 71.687 3.103 64.072 1.061 55.655a3.105 3.105 0 1 1 6.035-1.463c1.758 7.251 5.57 13.813 11.021 18.977a3.104 3.104 0 0 1-2.136 5.358zM3.108 43.949a3.104 3.104 0 0 1-3.093-3.41A44.664 44.664 0 0 1 3.977 26.05a3.105 3.105 0 0 1 5.63 2.621A38.443 38.443 0 0 0 6.193 41.15a3.105 3.105 0 0 1-3.086 2.799zm13.023-26.293a3.104 3.104 0 0 1-2.397-5.074c.277-.338.558-.671.845-1a3.103 3.103 0 0 1 4.38-.301 3.103 3.103 0 0 1 .301 4.38c-.247.283-.489.571-.728.861a3.1 3.1 0 0 1-2.4 1.134zm50.133 66.423a3.105 3.105 0 0 1-1.598-5.768c11.651-6.976 18.89-19.734 18.89-33.295 0-21.381-17.396-38.776-38.777-38.776a3.105 3.105 0 1 1 0-6.21c24.805 0 44.985 20.18 44.985 44.985 0 15.733-8.395 30.532-21.909 38.622-.499.3-1.048.442-1.59.442z"
    />
    <path
      fill="currentColor"
      d="M65.763 42.072H47.976V24.285h-6.209v17.787H23.98v6.209h17.787v17.787h6.21V48.281h17.786z"
    />
  </svg>
);

export default InstagramStories;
