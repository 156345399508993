import AutosaveControl from './AutosaveControl';
import AutosaveType from './AutosaveType';
import { AUTOSAVE } from './types';

const DEFAULT_ACTION = {
  type: AUTOSAVE,
};

function extractAutosaveMeta(action) {
  const meta = action && action.meta;
  return meta && meta.autosave;
}

function extractAutosaveTypeMeta(action) {
  const autosave = extractAutosaveMeta(action);
  return autosave && autosave.type;
}

function extractAutosaveControlMeta(action) {
  const autosave = extractAutosaveMeta(action);
  return autosave && autosave.control;
}

export function isImmediate(action) {
  const type = extractAutosaveTypeMeta(action);
  return AutosaveType.enumValueOf(type) === AutosaveType.IMMEDIATE;
}

export function isDelayed(action) {
  const type = extractAutosaveTypeMeta(action);
  return AutosaveType.enumValueOf(type) === AutosaveType.DELAYED;
}

export function isFlush(action) {
  const type = extractAutosaveTypeMeta(action);
  return AutosaveType.enumValueOf(type) === AutosaveType.FLUSH;
}

export function isPause(action) {
  const control = extractAutosaveControlMeta(action);
  return AutosaveControl.enumValueOf(control) === AutosaveControl.PAUSE;
}

export function isResume(action) {
  const control = extractAutosaveControlMeta(action);
  return AutosaveControl.enumValueOf(control) === AutosaveControl.RESUME;
}

export function isClear(action) {
  const control = extractAutosaveControlMeta(action);
  return AutosaveControl.enumValueOf(control) === AutosaveControl.CLEAR;
}

export function autosaveActionBuilder(action = DEFAULT_ACTION) {
  let enrichedAction = action;
  let immediateSave = false;
  let delayedSave = false;
  let flushSave = false;
  let pause = false;
  let resume = false;
  let clear = false;

  function getAutosaveTypeMeta() {
    if (immediateSave) {
      return { type: AutosaveType.IMMEDIATE.name };
    }

    if (delayedSave) {
      return { type: AutosaveType.DELAYED.name };
    }

    if (flushSave) {
      return { type: AutosaveType.FLUSH.name };
    }

    return {};
  }

  function getAutosaveControlMeta() {
    if (pause) {
      return { control: AutosaveControl.PAUSE.name };
    }

    if (resume) {
      return { control: AutosaveControl.RESUME.name };
    }

    if (clear) {
      return { control: AutosaveControl.CLEAR.name };
    }

    return {};
  }

  return {
    action(a) {
      enrichedAction = a;
      return this;
    },

    immediateSave() {
      immediateSave = true;
      delayedSave = false;
      flushSave = false;
      return this;
    },

    delayedSave() {
      delayedSave = true;
      immediateSave = false;
      flushSave = false;
      return this;
    },

    flushSave() {
      flushSave = true;
      delayedSave = false;
      immediateSave = false;
      return this;
    },

    pause() {
      pause = true;
      resume = false;
      clear = false;
      return this;
    },

    resume() {
      resume = true;
      pause = false;
      clear = false;
      return this;
    },

    clear() {
      clear = true;
      pause = false;
      resume = false;
      return this;
    },

    build() {
      if (!enrichedAction) return undefined;

      const typeMeta = getAutosaveTypeMeta();
      const controlMeta = getAutosaveControlMeta();
      return {
        ...enrichedAction,
        meta: {
          ...(enrichedAction.meta || {}),
          autosave: {
            ...typeMeta,
            ...controlMeta,
          },
        },
      };
    },
  };
}

export default {
  autosaveActionBuilder,
};
