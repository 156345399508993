import { connect } from 'react-redux';

import { organizationNameSelector } from 'redux/modules/pricing';
import { State } from 'redux/types';
import { PropsOf } from 'types';
import EnterpriseSubscription, {
  EnterpriseSubscriptionProps as Props,
} from './EnterpriseSubscription';

type StateProps = Pick<Props, 'enterpriseName'>;

const mapStateToProps = (state: State): StateProps => ({
  enterpriseName: organizationNameSelector(state),
});

const component = connect(mapStateToProps)(EnterpriseSubscription);
export type EnterpriseSubscriptionProps = PropsOf<typeof component>;
export default component;
