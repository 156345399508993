import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router';
import { clearDownload } from 'redux/modules/download';
import { getDownloadPath } from 'utils/routes';
import ExportVideoStepContainer from './ExportVideoStepContainer';
import ExportVideoStepMainSection from './ExportVideoStepMainSection';
import ProgressBlock from './ProgressBlock';
import { ExportVideoStepProps } from './types';
import useExportVideoStep from './useExportVideoStep';

const ExportVideoStepProgressOnly: React.FC<ExportVideoStepProps> = ({
  className,
  estimatedDurationSec,
  onError,
  pollIntervalMillis,
  widgetId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { progress, status } = useExportVideoStep({
    onError,
    pollIntervalMillis,
    widgetId,
  });

  useEffect(() => {
    if (status === 'completed') {
      history.push(getDownloadPath(widgetId));
      // Clearing download to have a smoother transition.
      // This prevents the flickering when changing the pages in which
      // the download page is displayed then a few seconds later the
      // 'Preparing Download' message and then the download page again.
      dispatch(clearDownload());
    }
  }, [dispatch, history, status, widgetId]);

  return (
    <ExportVideoStepContainer className={className}>
      <ExportVideoStepMainSection>
        <ProgressBlock
          layout="vertical"
          {...{ estimatedDurationSec, progress }}
        />
      </ExportVideoStepMainSection>
    </ExportVideoStepContainer>
  );
};

export default ExportVideoStepProgressOnly;
