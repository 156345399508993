import * as React from 'react';
import { connect } from 'react-redux';

import { logout } from 'redux/modules/auth/actions';
import { userNameSelector } from 'redux/modules/auth/selectors';
import { onClickAccountSettings } from 'redux/modules/mixpanel/actions';
import { goToLabs, goToSettings } from 'redux/modules/router/actions';
import { Dispatch, State } from 'redux/types';
import { IProps } from './UserDropdown';

type StateProps = Pick<IProps, 'username'>;
type DispatchProps = Pick<
  IProps,
  'onLabsClick' | 'onLogoutClick' | 'onSettingsClick'
>;
type OwnProps = Pick<IProps, 'shouldLogoutRedirect'>;

const mapStateToProps = (state: State): StateProps => ({
  username: userNameSelector(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { shouldLogoutRedirect }: OwnProps,
): DispatchProps => ({
  onLabsClick: () => dispatch(goToLabs()),
  onLogoutClick: () => dispatch(logout(shouldLogoutRedirect)),
  onSettingsClick: () => {
    dispatch(onClickAccountSettings());
    dispatch(goToSettings());
  },
});

export default function(component: React.ComponentType<IProps>) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
