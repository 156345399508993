import * as React from 'react';

import PlacementPopper from '../Popper/PlacementPopper';
import WaveformPlacementPopper, {
  WaveformPlacementPopperProps,
} from './WaveformPlacementPopper';

type PickedPopperProps = Pick<
  WaveformPlacementPopperProps,
  'onChange' | 'value'
>;

export interface WaveformPlacementTileProps extends PickedPopperProps {}

const WaveformPlacementTile: React.FC<WaveformPlacementTileProps> = ({
  onChange,
  value,
}) => (
  <PlacementPopper>
    <WaveformPlacementPopper {...{ onChange, value }} />
  </PlacementPopper>
);

export default WaveformPlacementTile;
