import * as React from 'react';
import ReactResizeDetector from 'react-resize-detector';

import FontAwesome from 'components/FontAwesome';
import IconButton from 'components/IconButton/IconButton';
import bem from 'utils/bem';

type OnSeek = (seekToSec: number) => void;
type OnTogglePlaying = () => void;

interface IProps {
  playing: boolean;
  bufferMillis: number;
  currentTimeMillis: number;
  disabled: boolean;
  durationMillis: number;
  seekToSec?: number;
  onSeek: OnSeek;
  onTogglePlaying: OnTogglePlaying;
}

interface IState {
  timeIndicatorWidth?: number;
}

const block = bem('playback-control-bar');

class PlaybackControlBar extends React.Component<IProps, IState> {
  public state = {
    timeIndicatorWidth: undefined,
  };

  private handleTimeIndicatorResize = (width: number) =>
    this.setState({ timeIndicatorWidth: width });

  private handleTimeClick = ({ nativeEvent: { offsetX } }) => {
    const { durationMillis, onSeek } = this.props;
    const { timeIndicatorWidth } = this.state;
    const seekToSec = ((offsetX / timeIndicatorWidth) * durationMillis) / 1000;
    onSeek(seekToSec);
  };

  public render() {
    const {
      onTogglePlaying,
      playing,
      currentTimeMillis,
      durationMillis,
      bufferMillis,
      disabled,
    } = this.props;
    const playPosition = currentTimeMillis
      ? (currentTimeMillis / durationMillis) * 100
      : 0;
    const bufferPosition = bufferMillis
      ? (bufferMillis / durationMillis) * 100
      : 0;

    return (
      <div className={block()}>
        <IconButton onButtonClick={onTogglePlaying} disabled={disabled}>
          {playing ? (
            <FontAwesome className={block('icon')} icon="pause" />
          ) : (
            <FontAwesome className={block('icon')} icon="play" />
          )}
        </IconButton>
        <div
          className={block('time-indicator')}
          onMouseUp={this.handleTimeClick}
        >
          <ReactResizeDetector
            handleWidth
            onResize={this.handleTimeIndicatorResize}
          />
          <div
            className={block('buffer')}
            style={{ width: `${bufferPosition}%` }}
          />
          <div className={block('duration')} />
          <div
            className={block('current-time')}
            style={{ width: `${playPosition}%` }}
          >
            <div className={block('dot')} />
          </div>
        </div>
      </div>
    );
  }
}

export default PlaybackControlBar;
