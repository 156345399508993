import * as React from 'react';
import { scaleInlineStyles } from 'utils/embed/text-overlay';
import { Measurement, ViewportWidth } from 'utils/measurement';
import { createTextShadowString } from 'utils/ui';
import { useCanvasSize } from '../context/CanvasSizeContext';
import { useNavigation } from '../context/NavigationContext';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { TextOverlay, ToPx } from '../types';
import useEditTextModal from '../useEditTextModal';
import RndAsset, { RndAssetCallback } from './RndAsset';

const { useCallback } = React;

type Style = Omit<React.CSSProperties, 'height' | 'left' | 'top' | 'width'> & {
  height: Measurement;
  left: Measurement;
  top: Measurement;
  width: Measurement;
};

export interface TextAssetProps {
  id: string;
}

function getTextBoxStyle(overlay: TextOverlay, toPx: ToPx) {
  if (!overlay) return undefined;

  const { position, size, style } = overlay;
  const toPxValue = (m: Measurement) => toPx(m)?.value;

  return {
    ...style,
    fontSize: toPxValue(style?.fontSize),
    height: size?.height,
    left: position?.left,
    paddingBottom: toPxValue(style?.paddingBottom),
    paddingLeft: toPxValue(style?.paddingLeft),
    paddingRight: toPxValue(style?.paddingRight),
    paddingTop: toPxValue(style?.paddingTop),
    position: 'absolute',
    textShadow: createTextShadowString(style?.textShadow),
    top: position?.top,
    width: size?.width,
  } as Style;
}
const TextAsset: React.FC<TextAssetProps> = ({ id }) => {
  const { toPx } = useCanvasSize();
  const [, send] = useNavigation();
  const { textOverlays } = useTemplateState();
  const dispatch = useTemplateDispatch();
  const showEditModal = useEditTextModal();

  const overlay = textOverlays.data[id];
  const { height, left, top, width, ...style } = getTextBoxStyle(overlay, toPx);

  const handleMouseDown = useCallback(() => {
    send({
      type: 'CHILD_VIEW_OPEN',
      payload: 'text',
      meta: { source: 'preview' },
    });
  }, [send]);

  const handleDimensionsChange: RndAssetCallback = useCallback(
    value =>
      dispatch({
        type: 'TEXT_DIMENSIONS_CHANGE',
        payload: {
          id,
          dimensions: value,
        },
      }),
    [dispatch, id],
  );

  const handleDoubleClick = useCallback(() => {
    showEditModal(id);
  }, [id, showEditModal]);

  return (
    <RndAsset
      assetId={id}
      bounds="parent"
      lockAspectRatio={false}
      onDragStop={handleDimensionsChange}
      onResizeStop={handleDimensionsChange}
      onMouseDown={handleMouseDown}
      params={id}
      {...{ height, left, top, width }}
    >
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: scaleInlineStyles(
            overlay.textHtml,
            ['fontSize'],
            val => `${toPx(new ViewportWidth(val))?.value}px`,
          ),
        }}
        key={overlay.id}
        onDoubleClick={handleDoubleClick}
        style={{
          ...style,
          height: '100%',
          width: '100%',
          wordBreak: 'break-word',
        }}
      />
    </RndAsset>
  );
};

export default TextAsset;
