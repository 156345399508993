import { useDispatch } from 'react-redux';

import { clearReplacedTrackElement } from 'redux/modules/embed/actions/tracks';
import { AddAssetModalName } from 'redux/modules/modal/types';
import { clearSelectedAudio } from 'redux/modules/sample-audio/actions';
import { createChainedFunction } from 'utils/functions';
import useConnectedModal, {
  UseConnectedModalReturnType,
} from './useConnectedModal';

export function useAddAssetModal(
  name: AddAssetModalName,
): UseConnectedModalReturnType {
  const dispatch = useDispatch();
  const { onHide: onConnectedHide, ...rest } = useConnectedModal(name);

  return {
    ...rest,
    onHide: createChainedFunction(onConnectedHide, () => {
      dispatch(clearSelectedAudio());
      dispatch(clearReplacedTrackElement());
    }),
  };
}
