import * as React from 'react';

import BemCssTransition from 'components/BemCssTransition';
import { Arrow } from 'components/icons';
import { block } from './utils';

export interface TemplateNuxOverlayProps {
  className?: string;
}

const TemplateNuxOverlay: React.SFC<TemplateNuxOverlayProps> = ({
  className,
}) => (
  <div className={className}>
    <BemCssTransition appear in className={block('line-1')} timeout={2500}>
      <div>Replace the clips below</div>
    </BemCssTransition>
    <BemCssTransition
      appear
      in
      className={block('line-2')}
      delay={500}
      timeout={2000}
    >
      <div>by clicking on them</div>
    </BemCssTransition>
    <BemCssTransition
      appear
      in
      className={block('icon')}
      timeout={1500}
      delay={1000}
    >
      <div>
        <Arrow style={{ height: 36 }} />
      </div>
    </BemCssTransition>
  </div>
);

export default TemplateNuxOverlay;
