import classNames from 'classnames';
import * as React from 'react';

import { block } from './utils';

interface IPosition {
  x: number;
  y: number;
}

interface IProps {
  className: string;
  display?: boolean;
  position: IPosition;
}

const WorkspacePositionOverlay: React.SFC<IProps> = ({
  className,
  display,
  position,
}) =>
  display && (
    <div className={classNames(block(), className)}>
      <span className={block('text')}>
        {`${position.x.toFixed(0)}, ${position.y.toFixed(0)}`}
      </span>
    </div>
  );

WorkspacePositionOverlay.defaultProps = {
  display: false,
};

export default WorkspacePositionOverlay;
export { IProps as WorkspacePositionOverlayProps };
