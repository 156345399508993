import * as React from 'react';
import { noop } from 'underscore';

import Modal from 'components/Modal';
import { block } from '../utils';
import ProgressAnimationModalContents, {
  ProgressAnimationModalContentsProps,
} from './ProgressAnimationModalContents';

export interface ProgressAnimationModalProps
  extends ProgressAnimationModalContentsProps {
  onHide?: () => void;
  show?: boolean;
}

const ProgressAnimationModal: React.SFC<ProgressAnimationModalProps> = ({
  show,
  onHide,
  ...props
}) => {
  return (
    <Modal
      className={block()}
      title="Progress"
      onHide={onHide}
      show={show}
      backdrop="static"
    >
      <ProgressAnimationModalContents onHide={onHide} {...props} />
    </Modal>
  );
};

ProgressAnimationModal.defaultProps = {
  onHide: noop,
  onSubmit: noop,
  show: false,
};

export default ProgressAnimationModal;
