import { compose } from 'redux';

import { AnyObject, Dimensions, FitType, Position, Size } from 'types';
import measurement, {
  Pixels,
  ViewportHeight,
  ViewportWidth,
} from 'utils/measurement';

export function hasPosition<T extends AnyObject>(obj: T): boolean {
  return ['left', 'top'].every(key => obj?.[key] !== undefined);
}

export function hasSize<T extends AnyObject>(obj: T): boolean {
  return ['height', 'width'].every(key => obj?.[key] !== undefined);
}

export function hasDimensions<T extends AnyObject>(obj: T): boolean {
  return hasPosition(obj) && hasSize(obj);
}

type ConversionResult<U, T, L = T, H = T, W = L> = U extends Dimensions<any>
  ? Omit<U, keyof Dimensions<any>> & Dimensions<T, L, H, W>
  : U extends Size<any>
  ? Omit<U, keyof Size<any>> & Size<H, W>
  : U extends Position<any>
  ? Omit<U, keyof Position<any>> & Position<T, L>
  : never;

const DIMENSION_VIEWPORT_UNITS = {
  height: 'vh',
  left: 'vw',
  top: 'vh',
  width: 'vw',
} as const;

// TODO types
export function placementTransformer<T extends AnyObject>(
  obj: T,
  transform: (val: any, k: keyof Dimensions) => any,
): any {
  if (!obj) return undefined;

  return (['height', 'left', 'top', 'width'] as const).reduce(
    (acc: any, key) => {
      if (acc[key] !== undefined) {
        acc[key] = transform(acc[key], key);
      }
      return acc;
    },
    { ...obj },
  );
}

export function measurementToViewport<T extends AnyObject>(
  obj: T,
  container: Size<Pixels> | Size<number>,
): ConversionResult<T, ViewportHeight, ViewportWidth> {
  return placementTransformer(obj, (val, key) => {
    return val.toUnit(DIMENSION_VIEWPORT_UNITS[key], container);
  });
}

export function measurementToPx<T extends AnyObject>(
  obj: T,
  container: Size<Pixels> | Size<number>,
): ConversionResult<T, Pixels> {
  return placementTransformer(obj, val => {
    return val.toUnit('px', container);
  });
}

export function stringToViewport<T extends AnyObject>(
  obj: T,
): ConversionResult<T, ViewportHeight, ViewportWidth> {
  return placementTransformer(obj, (val, key) =>
    measurement(val, DIMENSION_VIEWPORT_UNITS[key]),
  );
}

export function viewportToPct<T extends AnyObject>(
  obj: T,
): ConversionResult<T, string> {
  return placementTransformer(obj, val => `${parseFloat(val.value)}%`);
}

export function stringToPct<T extends AnyObject>(
  obj: T,
  container?: Size<Pixels> | Size<number>,
): ConversionResult<T, string> {
  const viewport = placementTransformer(obj, (val, key) =>
    measurement(val).toUnit(DIMENSION_VIEWPORT_UNITS[key], container),
  );
  return viewportToPct<T>(viewport);
}

export function numberOrPxToPx<T extends AnyObject>(
  obj: T,
): ConversionResult<T, Pixels> {
  return placementTransformer(obj, val => {
    if (typeof val === 'number') {
      return new Pixels(val);
    }
    return val;
  });
}

export function numberToViewport<T extends AnyObject>(
  obj: T,
  container: Size<Pixels> | Size<number>,
) {
  return placementTransformer(obj, (val, key) => {
    const px = new Pixels(val);
    return px.toUnit(DIMENSION_VIEWPORT_UNITS[key], container);
  });
}

export function measurementToString<T extends AnyObject>(
  obj: T,
): ConversionResult<T, string> {
  return placementTransformer(obj, val => val.toString());
}

export const measurementToViewportString = compose(
  measurementToString,
  measurementToViewport,
);

export function stringToPx<T extends AnyObject>(
  obj: T,
  container: Size<Pixels> | Size<number>,
): ConversionResult<T, number> {
  return placementTransformer(
    obj,
    (val, key) =>
      measurement(val)
        .toUnit(DIMENSION_VIEWPORT_UNITS[key], container)
        .toUnit('px', container).value,
  );
}

export function fitElement(
  element: Size<Pixels>,
  container: Size<number | Pixels>,
  fitType: FitType,
): Dimensions<Pixels> {
  const containerPx = numberOrPxToPx(container);

  const multiplier = (fitType === 'fit' ? Math.min : Math.max)(
    containerPx.width.divideBy(element.width).value,
    containerPx.height.divideBy(element.height).value,
  );

  const scaledElementPx = {
    height: element.height.times(multiplier),
    width: element.width.times(multiplier),
  };

  return {
    ...scaledElementPx,
    left: containerPx.width.minus(scaledElementPx.width).divideBy(2),
    top: containerPx.height.minus(scaledElementPx.height).divideBy(2),
  };
}

export function replaceRect(
  current: Dimensions<Pixels>,
  replacement: Size<Pixels>,
  canvas: Size<number | Pixels>,
): Dimensions<Pixels> {
  const canvasPx = numberOrPxToPx(canvas);

  const scaleBy = (multiplier: number) => ({
    width: replacement.width.times(multiplier),
    height: replacement.height.times(multiplier),
  });

  const minScale = Math.min(
    current.height.value / replacement.height.value,
    current.width.value / replacement.width.value,
  );

  const getSize = () => {
    const maxScaled = scaleBy(
      Math.max(
        current.height.value / replacement.height.value,
        current.width.value / replacement.width.value,
      ),
    );

    if (
      current.left.plus(maxScaled.width).value <= canvasPx.width.value &&
      current.top.plus(maxScaled.height).value <= canvasPx.height.value
    ) {
      return maxScaled;
    }

    return scaleBy(minScale);
  };

  return {
    left: current.left,
    top: current.top,
    ...getSize(),
  };
}

export function isFill(
  element: Dimensions<Pixels>,
  container: Size<number | Pixels>,
): boolean {
  const fill = fitElement(element, container, 'fill');
  return (['height', 'left', 'top', 'width'] as const).every(key =>
    element[key].eq(fill[key], 0.001),
  );
}

export function isFit(
  element: Dimensions<Pixels>,
  container: Size<number | Pixels>,
): boolean {
  const fit = fitElement(element, container, 'fit');
  return (['height', 'left', 'top', 'width'] as const).every(key =>
    element[key].eq(fit[key], 0.001),
  );
}

interface ScaleConfig {
  minArea?: number;
  maxArea?: number;
}

export function scale(
  element: Dimensions<Pixels>,
  multiplier: number,
  { minArea = -Infinity, maxArea = Infinity }: ScaleConfig = {},
): Dimensions<Pixels> {
  const negativeDimensionGuard = (px: Pixels) => {
    px.value = Math.max(0, px.value);
    return px;
  };

  const height = negativeDimensionGuard(element.height.times(1 + multiplier));
  const width = negativeDimensionGuard(element.width.times(1 + multiplier));
  const area = height.times(width);

  if (area.value > maxArea || area.value < minArea) {
    return element;
  }

  return {
    height,
    width,
    left: element.left.minus(element.width.times(multiplier / 2)),
    top: element.top.minus(element.height.times(multiplier / 2)),
  };
}
