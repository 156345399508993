import * as Immutable from 'immutable';
import _ from 'underscore';

import { getValue } from './collections';

function createAspectRatio(width, height) {
  return { height, width };
}

export const ASPECT_RATIOS = Immutable.fromJS({
  landscape: createAspectRatio(16, 9),
  square: createAspectRatio(1, 1),
  portrait: createAspectRatio(9, 16),
});

export function getAspectRatio(name) {
  return ASPECT_RATIOS.get(name);
}

export function getAspectRatioName(height, width) {
  const parseRatio = () => {
    if (_.isObject(height)) {
      return getValue(height, 'width') / getValue(height, 'height');
    }

    if (_.isUndefined(width)) {
      return height;
    }

    return width / height;
  };

  const targetRatio = parseRatio();

  if (!_.isFinite(targetRatio)) return undefined;

  return ASPECT_RATIOS.findKey(dimensions => {
    const ratio = dimensions.get('width') / dimensions.get('height');
    return ratio === targetRatio;
  });
}

export function getAspectRatioNameFromRatio(targetRatio) {
  const dimensions = getAspectRatioDimensions(targetRatio);
  if (!dimensions || dimensions.isEmpty()) {
    return null;
  }

  return getAspectRatioName(dimensions.get('height'), dimensions.get('width'));
}

export function getAspectRatioDimensions(targetRatio) {
  return ASPECT_RATIOS.find(dimensions => {
    const ratio = dimensions.get('width') / dimensions.get('height');
    return ratio === targetRatio;
  });
}

export function getMaxDimensions(area, aspectRatio) {
  if (_.isUndefined(area) || _.isUndefined(aspectRatio)) return undefined;

  const width = Math.sqrt(area * aspectRatio);
  const height = area / width;

  return { width, height };
}

export function getAspectRatioValue(name) {
  const dims = getAspectRatio(name);
  return dims && dims.get('width') / dims.get('height');
}

export default { getAspectRatio, getMaxDimensions };
