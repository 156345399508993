import cn from 'classnames';
import React from 'react';
import { formatDurationMillis } from 'utils/time';
import { block } from './utils';

interface Props {
  className?: string;
  positionMillis: number;
  duration: number;
}

const Timestamps = (props: Props) => {
  const { className, positionMillis, duration = 0 } = props;

  return (
    <div className={cn(className, block('times'))}>
      <span className={block('position')}>
        {formatDurationMillis(positionMillis, {
          hour: 'numeric',
          minute: '2-digit',
          second: '2-digit',
          trim: false,
        })}
      </span>
      {' | '}
      <span className={block('duration')}>
        {formatDurationMillis(duration, {
          hour: 'numeric',
          minute: '2-digit',
          second: '2-digit',
          trim: false,
        })}
      </span>
    </div>
  );
};

export default Timestamps;
