import { useCallback } from 'react';
import { noop } from 'underscore';

import { ViewportWidth } from 'utils/measurement';

import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import useMainMedia from './useMainMedia';

const useImageBlur = () => {
  const dispatch = useTemplateDispatch();
  const mainMedia = useMainMedia();

  const onBlurChange = useCallback(
    (blurRadius: ViewportWidth) => {
      // Blur can only be set for image type assets
      if (mainMedia.type !== 'image') {
        return;
      }

      dispatch({
        type: 'IMAGE_BLUR_RADIUS_CHANGE',
        payload: {
          id: mainMedia.asset.id,
          blurRadius,
        },
      });
    },
    [dispatch, mainMedia],
  );

  // When asset type is not image a defaut config is returned. In this case,
  // disabled is set to true.
  if (mainMedia.type !== 'image') {
    return {
      blurRadius: new ViewportWidth(0),
      disabled: true,
      onBlurChange: noop,
    };
  }

  return {
    blurRadius: mainMedia.asset.blurRadius,
    disabled: false,
    onBlurChange,
  };
};

export default useImageBlur;
