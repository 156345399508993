import * as React from 'react';

import { ModalProps } from 'components/Modal';
import ConnectedModal from 'containers/ConnectedModal';
import bem from 'utils/bem';
import CreateProfileModalContents from './CreateProfileModalContents';

type PickedModalProps = Pick<ModalProps, 'onHide' | 'show'>;

export type CreateProfileModalProps = PickedModalProps;

const block = bem('auth-modal');

const CreateProfileModal: React.FC<CreateProfileModalProps> = () => (
  <ConnectedModal className={block()} name="CreateProfile">
    <CreateProfileModalContents />
  </ConnectedModal>
);

export default CreateProfileModal;
