import cn from 'classnames';
import React from 'react';

import { block } from './utils';

export interface ExportVideoStepMainSectionProps {
  children?: React.ReactNode;
  className?: string;
}

const ExportVideoStepMainSection: React.FC<ExportVideoStepMainSectionProps> = ({
  children,
  className,
}) => (
  <section className={cn(block('main-section'), className)}>{children}</section>
);

export default ExportVideoStepMainSection;
