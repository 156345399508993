import Saver from './Saver';
import {
  isClear,
  isDelayed,
  isFlush,
  isImmediate,
  isPause,
  isResume,
} from './utils';

/**
 * creates a function that can be used to test if an action is blacklisted (i.e. an action that
 * should be completely ignored and passed through to the next middleware).
 *
 * @param {object} actionTypes can be `undefined`, a string, array of strings, or a function that
 *   returns `true` or `false` when given an action. when `undefined`, no actions are considered
 *   blacklisted
 */
function createBlacklistActionTester(actionTypes) {
  if (typeof actionTypes === 'string') {
    return createBlacklistActionTester([actionTypes]);
  }

  if (Array.isArray(actionTypes)) {
    return action => action && actionTypes.indexOf(action.type) >= 0;
  }

  if (typeof actionTypes === 'function') {
    return actionTypes;
  }

  // if no blacklist is passed, this function must evaluate to false to allow all actions through
  return () => false;
}

/**
 * Create the autosave middleware
 *
 * @param {function} saveAction the function to execute when a save occurs.  this will be called
 *   as `saveAction(dispatch, getState)`, much like redux-thunk (although this does not rely on
 *   the redux-thunk middleware). This action must return a promise.
 * @param {number} debounceMillis duration for which no activity must be seen in order for the save
 *   function to be run
 * @param {(string|string[]|function)} [blacklistActionTypes] action types which should have no
 *   affect on save/autosave.  If `undefined`, no actions are blacklisted. If a string or array
 *   of strings, only actions with a matching `action.type` will be blacklisted.  If a function,
 *   actions are only blacklisted when the function called on the action returns `true`, i.e.
 *   `blacklistActionTypes(action) === true`
 */
export default function createAutosave(
  saveAction,
  debounceMillis,
  blacklistActionTypes,
) {
  const isBlacklisted = createBlacklistActionTester(blacklistActionTypes);

  return ({ dispatch, getState }) => {
    const dispatchSave = () => saveAction(dispatch, getState);
    const saver = new Saver(dispatchSave, debounceMillis);

    return next => action => {
      if (!isBlacklisted(action)) {
        if (isImmediate(action)) {
          saver.saveNow();
        } else if (isDelayed(action)) {
          saver.scheduleSave();
        } else if (isFlush(action)) {
          saver.flush();
        }

        if (isPause(action)) {
          saver.pause();
        } else if (isResume(action)) {
          saver.resume();
        } else if (isClear(action)) {
          saver.cancelSave();
        }
      }

      return next(action);
    };
  };
}
