import { SuperAgentStatic } from 'superagent';
import { createRequest } from '../utils';

import {
  ACTION_KEY,
  CreateImageToVideoArgs,
  CreateImageToVideoResult,
  CreateTextToImageArgs,
  CreateTextToImageResult,
  GetImageToVideoArgs,
  GetImageToVideoResult,
  GetTextToImageArgs,
  GetTextToImageResult,
  SearchForGifsArgs,
  SearchForGifsResult,
  SearchForImagesArgs,
  SearchForImagesResult,
  SearchForVideosArgs,
  SearchForVideosResult,
  ServiceMethod,
} from './types';

const searchForImages = (
  args: SearchForImagesArgs,
  request: SuperAgentStatic,
): Promise<SearchForImagesResult> => {
  const [q, engine, page, pageSize] = args;
  return request
    .get('/api/v1/image-search/search')
    .timeout(spareminConfig.imageSearchTimeoutMillis)
    .query({
      engine,
      page,
      pageSize,
      q,
      rights: 'freeToUse',
      safe: 'high',
    })
    .then(res => ({
      engine,
      content: res.body.content,
      queries: res.body.queries,
    }));
};

const searchForVideos = (
  args: SearchForVideosArgs,
  request: SuperAgentStatic,
): Promise<SearchForVideosResult> => {
  const [q, engine, page, pageSize] = args;
  return (
    request
      .get('/api/v1/image-search/video-search/search')
      // TODO do we need a separate config variable for this?
      .timeout(spareminConfig.imageSearchTimeoutMillis)
      .query({
        engine,
        page,
        pageSize,
        q,
        rights: 'freeToUse',
        safe: 'high',
      })
      .then(res => ({
        engine,
        content: res.body.content,
        queries: res.body.queries,
      }))
  );
};

const searchForGifs = (
  args: SearchForGifsArgs,
  request: SuperAgentStatic,
): Promise<SearchForGifsResult> => {
  const [q, engine, page, pageSize] = args;
  return request
    .get('/api/v1/image-search/gif-search/search')
    .timeout(spareminConfig.imageSearchTimeoutMillis)
    .query({
      engine,
      page,
      pageSize,
      q,
      rights: 'freeToUse',
      safe: 'high',
    })
    .then(res => ({
      engine,
      content: res.body.content,
      queries: res.body.queries,
    }));
};

const createTextToImage = (
  args: CreateTextToImageArgs,
  request: SuperAgentStatic,
): Promise<CreateTextToImageResult> => {
  const [prompt, provider, width, height, imageType, colors] = args;

  return request
    .post('/api/v1/image-search/text-to-image/text-to-image-job')
    .send({
      prompt,
      provider,
      dimensionWidth: width,
      dimensionHeight: height,
      imageType,
      colors,
    })
    .then(res => res.body);
};

const getTextToImage = (
  args: GetTextToImageArgs,
  request: SuperAgentStatic,
): Promise<GetTextToImageResult> => {
  const [textToImageJobId] = args;

  return request
    .get(
      `/api/v1/image-search/text-to-image/text-to-image-job/${textToImageJobId}`,
    )
    .then(res => res.body);
};

const createImageToVideo = (
  args: CreateImageToVideoArgs,
  request: SuperAgentStatic,
): Promise<CreateImageToVideoResult> => {
  const [sourceImageUrl, provider] = args;

  return request
    .post('/api/v1/image-search/image-to-video/image-to-video-job')
    .send({
      sourceImageUrl,
      provider,
    })
    .then(res => res.body);
};

const getImageToVideo = (
  args: GetImageToVideoArgs,
  request: SuperAgentStatic,
): Promise<GetImageToVideoResult> => {
  const [imageToVideoJobId] = args;

  return request
    .get(
      `/api/v1/image-search/image-to-video/image-to-video-job/${imageToVideoJobId}`,
    )
    .then(res => res.body);
};

export const handle = (method: any, args: any, token: string): any => {
  const request = createRequest({
    token,
    baseUrl: spareminConfig.services.imageSearch,
  });

  switch (method) {
    case ServiceMethod.SEARCH_FOR_IMAGES:
      return searchForImages(args, request);

    case ServiceMethod.SEARCH_FOR_VIDEOS:
      return searchForVideos(args, request);

    case ServiceMethod.SEARCH_FOR_GIFS:
      return searchForGifs(args, request);

    case ServiceMethod.CREATE_TEXT_TO_IMAGE:
      return createTextToImage(args, request);

    case ServiceMethod.GET_TEXT_TO_IMAGE:
      return getTextToImage(args, request);

    case ServiceMethod.CREATE_IMAGE_TO_VIDEO:
      return createImageToVideo(args, request);

    case ServiceMethod.GET_IMAGE_TO_VIDEO:
      return getImageToVideo(args, request);

    default:
      throw new Error(`${ACTION_KEY} cannot handle method ${method}`);
  }
};
