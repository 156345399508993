import { fromJS } from 'immutable';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AudioWaveOptions from 'blocks/AudioWaveOptions';
import {
  ModalBody,
  ModalBodyCol,
  ModalBodyRow,
  ModalFooter,
  ModalFooterButton,
  ModalFooterButtons,
} from 'components/Modal';
import { ConnectedModalInjectedProps } from 'containers/ConnectedModal/ConnectedModalComponent';
import {
  addingToTrack,
  removeFromAudioTrack,
  replaceAudioTrack,
  replaceSelectedTrackElement,
  saveAudioProperties,
} from 'redux/modules/embed/actions';
import {
  selectedAudioAssetSelector,
  selectedTrackElementSelector,
} from 'redux/modules/embed/selectors';
import { onReplaceAsset } from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal/actions';
import { Dispatch } from 'redux/types';
import { getValue } from 'utils/collections';
import { millisToSec } from 'utils/time';
import { block } from './utils';
import WaveformModalLink from './WaveformModalLink';

const { useCallback, useState } = React;

export interface EditAudioModalContentsProps {}

const EditAudioModalContents: React.FC<EditAudioModalContentsProps &
  ConnectedModalInjectedProps> = ({ onHide }) => {
  const dispatch = useDispatch<Dispatch>();
  const audio = useSelector(selectedAudioAssetSelector);
  const trackId = useSelector(selectedTrackElementSelector)?.get('trackId');
  const [fadeInSec, setFadeInSec] = useState<number>(
    millisToSec(getValue(audio, ['transition', 'in', 'durationMilli'], 0)),
  );
  const [fadeOutSec, setFadeOutSec] = useState<number>(
    millisToSec(getValue(audio, ['transition', 'out', 'durationMilli'], 0)),
  );

  const handleDelete = useCallback(() => {
    dispatch(removeFromAudioTrack(audio.get('id')));
    onHide();
  }, [audio, dispatch, onHide]);

  const handleReplace = useCallback(() => {
    dispatch(onReplaceAsset('audio'));
    dispatch(replaceAudioTrack());
    dispatch(replaceSelectedTrackElement());
    onHide();
    dispatch(pushModal({ name: 'AddAudioModal' }));
    dispatch(addingToTrack(trackId));
  }, [dispatch, onHide, trackId]);

  const handleSave = useCallback(async () => {
    dispatch(
      saveAudioProperties(
        audio.withMutations(a => {
          a.set(
            'transition',
            fromJS({
              in: {
                durationMilli: fadeInSec * 1000,
                effect: 'fade',
              },
              out: {
                durationMilli: fadeOutSec * 1000,
                effect: 'fade',
              },
            }),
          );
        }),
      ),
    );
    onHide();
  }, [audio, dispatch, fadeInSec, fadeOutSec, onHide]);

  return (
    <>
      <ModalBody>
        <ModalBodyRow className={block('row')}>
          <ModalBodyCol size={6} className={block('col', { waveform: true })}>
            <WaveformModalLink onHide={onHide} />
          </ModalBodyCol>
          <ModalBodyCol size={6} className={block('col', { form: true })}>
            <AudioWaveOptions
              audioFadeInDuration={fadeInSec}
              audioFadeOutDuration={fadeOutSec}
              className={block('options')}
              onAudioFadeInChange={setFadeInSec}
              onAudioFadeOutChange={setFadeOutSec}
              showAudioFadeControls
              showWaveGenerationField={false}
              showWaveStyleFields={false}
            />
          </ModalBodyCol>
        </ModalBodyRow>
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons>
          <ModalFooterButton onClick={onHide}>cancel</ModalFooterButton>
          <ModalFooterButton onClick={handleReplace}>replace</ModalFooterButton>
          <ModalFooterButton onClick={handleDelete} theme="delete">
            delete
          </ModalFooterButton>
          <ModalFooterButton onClick={handleSave} theme="submit">
            save
          </ModalFooterButton>
        </ModalFooterButtons>
      </ModalFooter>
    </>
  );
};

export default EditAudioModalContents;
