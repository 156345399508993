import React from 'react';

// types
import { IconProps } from './types';

const FooterTikTok: React.FC<IconProps> = ({ ...svgProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    viewBox="0 0 18 20"
    {...svgProps}
  >
    <g fillRule="nonzero" fill="none">
      <path
        d="M13.34 7.22A8.081 8.081 0 0 0 18 8.685V5.4c-.329 0-.657-.034-.979-.1v2.586a8.083 8.083 0 0 1-4.66-1.465v6.706c0 3.355-2.767 6.074-6.18 6.074a6.227 6.227 0 0 1-3.44-1.027A6.215 6.215 0 0 0 7.158 20c3.414 0 6.181-2.72 6.181-6.074V7.22zm1.207-3.316a4.544 4.544 0 0 1-1.207-2.681V.8h-.927a4.6 4.6 0 0 0 2.134 3.104zM4.9 15.598a2.736 2.736 0 0 1-.577-1.682c0-1.534 1.266-2.778 2.828-2.778.29 0 .58.043.857.13v-3.36a6.342 6.342 0 0 0-.978-.055v2.615a2.876 2.876 0 0 0-.858-.13c-1.562 0-2.827 1.244-2.827 2.778 0 1.085.632 2.025 1.555 2.482z"
        fill="#FF004F"
      />
      <path
        d="M12.361 6.42a8.083 8.083 0 0 0 4.66 1.465V5.298a4.697 4.697 0 0 1-2.474-1.394A4.6 4.6 0 0 1 12.413.8H9.977v13.126c-.006 1.53-1.27 2.769-2.828 2.769a2.84 2.84 0 0 1-2.25-1.097 2.774 2.774 0 0 1-1.555-2.482c0-1.534 1.265-2.778 2.827-2.778.3 0 .587.045.858.13V7.853c-3.353.068-6.05 2.76-6.05 6.073a6 6 0 0 0 1.761 4.247A6.228 6.228 0 0 0 6.18 19.2c3.414 0 6.182-2.72 6.182-6.074V6.419z"
        fill="#FFF"
      />
      <path
        d="M17.021 5.298v-.7a4.72 4.72 0 0 1-2.474-.694 4.693 4.693 0 0 0 2.474 1.394zM12.413.8a4.613 4.613 0 0 1-.052-.377V0H8.998v13.126c-.005 1.53-1.269 2.769-2.827 2.769-.458 0-.89-.107-1.272-.297a2.84 2.84 0 0 0 2.25 1.097c1.559 0 2.822-1.24 2.828-2.77V.8h2.436zM7.029 7.853v-.745a6.347 6.347 0 0 0-.848-.056C2.767 7.052 0 9.772 0 13.126c0 2.103 1.088 3.956 2.74 5.046A6 6 0 0 1 .98 13.926c0-3.312 2.697-6.005 6.05-6.073z"
        fill="#00F2EA"
      />
    </g>
  </svg>
);

export default FooterTikTok;
