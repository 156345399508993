import _ from 'underscore';

import * as types from './types';

export function isStepList(
  arg: types.StepList | types.StepListFactory,
): arg is types.StepList {
  return _.isArray(arg);
}

export function isFooterButtons(
  arg: types.FooterButtons | types.FooterButtonsFactory,
): arg is types.FooterButtons {
  return _.isArray(arg);
}
