import cn from 'classnames';
import React from 'react';
import { block } from './utils';

interface BetaBadgeProps {
  className?: string;
}

const BetaBadge: React.FC<BetaBadgeProps> = ({ className }) => (
  <span className={cn(block(), className)}>BETA</span>
);
export default BetaBadge;
