import cn from 'classnames';
import * as React from 'react';
import bem from 'utils/bem';

const block = bem('video');

type PickedVideoProps = Pick<
  React.VideoHTMLAttributes<HTMLVideoElement>,
  'muted' | 'loop' | 'autoPlay' | 'className'
>;

interface Props extends PickedVideoProps {
  sources?: string[];
}

const Video: React.FC<Props> = props => {
  const { sources, className, ...rest } = props;
  return (
    <video
      muted
      loop
      autoPlay
      preload="auto"
      className={cn(className, block())}
      {...rest}
    >
      {sources.map(source => (
        <source key={source} src={source} />
      ))}
    </video>
  );
};

export default Video;
