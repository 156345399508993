import React from 'react';

import { block } from './utils';

export interface ClipTagProps {
  clipIndex: number;
  totalClips: number;
}

const ClipTag: React.FC<ClipTagProps> = ({ clipIndex, totalClips }) => (
  <div className={block('tag')}>
    Clip {clipIndex + 1} of {totalClips}
  </div>
);

export default ClipTag;
