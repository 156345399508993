import { ShareVideoOptions } from 'redux/middleware/api/podcast-service';
import { shareVideo } from 'redux/middleware/api/podcast-service/actions';
import { getFacebookPages as getPages } from 'redux/middleware/api/third-party-authentication-service/actions';
import { GetSocialProfileAction } from 'redux/middleware/api/third-party-authentication-service/types';
import { isShareStatusResolved } from 'redux/middleware/api/video-export-service';
import { getSocialShare } from 'redux/middleware/api/video-export-service/actions';
import { videoIdSelector } from 'redux/modules/download';
import { onFacebookVideoUploadedSuccessfully } from 'redux/modules/mixpanel/actions';
import { showNotification } from 'redux/modules/notification/actions';
import { ThunkAction } from 'redux/types';
import { SocialSharePlatform } from 'types';
import reduxPoll from 'utils/redux-poll';
import { Type } from './action-types';
import {
  facebookProviderUserIdSelector,
  facebookSocialShareIdSelector,
  facebookSocialSharesSelector,
  facebookUserDataSelector,
} from './selectors';
import {
  FacebookAuthorizeSuccessAction,
  FacebookPostMessageData,
} from './types';

const FACEBOOK_POST_ID = 'app/facebook/post-poll';

const showSuccessNotification = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const { sharePostUrl } = facebookSocialSharesSelector(getState());

  dispatch(
    showNotification(
      'You can see your post {{link}}',
      'success',
      0,
      'link',
      'Your video was posted',
      undefined,
      sharePostUrl,
      'here',
    ),
  );
};

const showErrorNotification = (): ThunkAction<void> => (dispatch, getState) => {
  const { errorDetail } = facebookSocialSharesSelector(getState());

  if (errorDetail.errorReason === 'invalidAccountType') {
    dispatch(
      showNotification({
        message: 'Please try again or {{link}} so we can help',
        code: 'ER013',
        level: 'error',
        type: 'help',
        title: "Sorry, we couldn't post your video",
        actionLabel: 'contact us',
      }),
    );
  }
};

export const facebookAuthorizeSuccess = (
  data: Pick<
    FacebookPostMessageData['tokenInfo'],
    'accessToken' | 'idToken' | 'providerUserId'
  >,
): FacebookAuthorizeSuccessAction => ({
  type: Type.FACEBOOK_AUTHORIZE_SUCCESS,
  payload: data,
});

export const getFacebookUser = (
  response: GetSocialProfileAction,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.FACEBOOK_USER_DATA_GET_REQUEST });

  try {
    dispatch({
      payload: response,
      type: Type.FACEBOOK_USER_DATA_GET_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: new Error('Error getting user data'),
      type: Type.FACEBOOK_USER_DATA_GET_FAILURE,
    });

    throw error;
  }
};

export const clearFacebookUser = () => ({
  type: Type.FACEBOOK_PAGES_CLEAR,
});

export const getFacebookPages = (
  facebookId?: string,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.FACEBOOK_PAGES_GET_REQUEST });

  try {
    const {
      providerUserId = facebookId,
      accessToken,
    } = facebookUserDataSelector(getState());

    const { response } = await dispatch(getPages(providerUserId, accessToken));

    dispatch({
      type: Type.FACEBOOK_PAGES_GET_SUCCESS,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: Type.FACEBOOK_PAGES_GET_FAILURE,
      error: new Error('Error getting Facebook pages'),
    });

    throw err;
  }
};

const waitForFacebookPost = (): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  dispatch({ type: Type.FACEBOOK_VIDEO_POST_AWAIT_REQUEST });

  const socialShareId = facebookSocialShareIdSelector(getState());

  try {
    await reduxPoll(dispatch, () => dispatch(getSocialShare(socialShareId)), {
      id: FACEBOOK_POST_ID,
      intervalMillis: 3000,
      maxAttempts: spareminConfig.videoExportPollMaxAttempts,
      shouldContinue: err => {
        if (err) {
          return false;
        }

        const share = facebookSocialSharesSelector(getState());

        if (share.shareStatus === 'error') {
          throw err;
        }

        return isShareStatusResolved(share, ['uploaded']);
      },
    });

    dispatch({
      type: Type.FACEBOOK_VIDEO_POST_AWAIT_SUCCESS,
    });
    dispatch(showSuccessNotification());
    dispatch(onFacebookVideoUploadedSuccessfully());
  } catch (err) {
    // NB: Error isn't send in the action because it is thrown and handled by
    // postFacebookVideo, which will send it to the reducer.
    dispatch({ type: Type.FACEBOOK_VIDEO_POST_AWAIT_FAILURE });

    throw err;
  }
};

export const postFacebookVideo = (
  pageAccessToken: string,
  opts: ShareVideoOptions,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.FACEBOOK_VIDEO_POST_REQUEST });

  const embedVideoId = videoIdSelector(getState());
  const providerUserId = facebookProviderUserIdSelector(getState());

  try {
    const {
      response: { socialShareId },
    } = await dispatch(
      shareVideo(embedVideoId, SocialSharePlatform.FACEBOOK, pageAccessToken, {
        ...opts,
        providerUserId,
      }),
    );

    dispatch({
      payload: { socialShareId },
      type: Type.FACEBOOK_VIDEO_POST_SUCCESS,
    });

    await dispatch(waitForFacebookPost());
  } catch (err) {
    dispatch({
      error: err,
      type: Type.FACEBOOK_VIDEO_POST_FAILURE,
    });

    dispatch(showErrorNotification());
  }
};
