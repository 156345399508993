import { EmojiData } from 'emoji-mart';
import React from 'react';

import TextToolbar from 'components/TextToolbar';

import useTextOverlayTextFormat from '../../hooks/useTextOverlayTextFormat';
import { useTextOverlay } from '../../state/TextOverlayProvider';
import { block } from '../../utils';
import TextOverlayControlsGroup from './TextOverlayControlsGroup';

interface TextOverlayTextStylesToolbarProps {
  showEmoji: boolean;
}

const TextOverlayTextStylesToolbar: React.FunctionComponent<TextOverlayTextStylesToolbarProps> = props => {
  const { showEmoji } = props;

  const {
    onChangeFontStyle,
    onChangeFontWeight,
    onChangeTextAlign,
    onChangeTextDecoration,
    selectedFontStyle,
    selectedFontWeight,
    selectedTextAlign,
    selectedTextDecoration,
  } = useTextOverlayTextFormat();

  const { onChangeText, textValue } = useTextOverlay();

  const handleToggleBoldStyle = React.useCallback(
    (active: boolean): void => {
      onChangeFontWeight(active ? 'bold' : 'normal');
    },
    [onChangeFontWeight],
  );

  const handleToggleItalicsStyle = React.useCallback(
    (active: boolean): void => {
      onChangeFontStyle(active ? 'italic' : 'normal');
    },
    [onChangeFontStyle],
  );

  const handleToggleUnderlineStyle = React.useCallback(
    (active: boolean): void => {
      onChangeTextDecoration(active ? 'underline' : 'none');
    },
    [onChangeTextDecoration],
  );

  const handleSelectEmoji = React.useCallback(
    (emoji: EmojiData): void => {
      onChangeText(textValue.concat(emoji.native));
    },
    [onChangeText, textValue],
  );

  const isBoldActive = selectedFontWeight === 'bold';
  const isItalicsActive = selectedFontStyle === 'italic';
  const isUnderlineActive = selectedTextDecoration === 'underline';

  return (
    <div className={block('toolbar-row')}>
      <TextOverlayControlsGroup>
        <TextToolbar.TextAlignSelectorV2
          onChange={onChangeTextAlign}
          value={selectedTextAlign}
        />
        <TextToolbar.BoldToggle
          active={isBoldActive}
          className={block('toggle-v2', { active: isBoldActive })}
          onToggle={handleToggleBoldStyle}
        />
        <TextToolbar.ItalicsToggle
          active={isItalicsActive}
          className={block('toggle-v2', { active: isItalicsActive })}
          onToggle={handleToggleItalicsStyle}
        />
        <TextToolbar.UnderlineToggle
          active={isUnderlineActive}
          className={block('toggle-v2', { active: isUnderlineActive })}
          onToggle={handleToggleUnderlineStyle}
        />
        {showEmoji && (
          <TextToolbar.EmojiPickerV2 onSelectEmoji={handleSelectEmoji} />
        )}
      </TextOverlayControlsGroup>
    </div>
  );
};

export default TextOverlayTextStylesToolbar;
