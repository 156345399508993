import * as React from 'react';

import { CircularGradientExclamation } from 'components/icons/circular-gradient';
import { welcomeBlock as block } from '../utils';
import CardWizardStep, {
  CardWizardControlButton,
  CardWizardControls,
} from './CardWizard/CardWizardStep';

interface Props {
  onSubmit: () => void;
}

const UpsellErrorStep: React.FC<Props> = ({ onSubmit }) => {
  return (
    <CardWizardStep className={block()}>
      <div className={block('icon')}>
        <CircularGradientExclamation />
      </div>
      <div className={block('message')}>
        <CardWizardStep.H1>Sorry for the delay…</CardWizardStep.H1>
        <CardWizardStep.H2>
          There was a problem processing your upgrade, please continue exploring
          Headliner while we fix it.
        </CardWizardStep.H2>
      </div>
      <CardWizardControls className={block('controls')}>
        <CardWizardControlButton onClick={onSubmit}>
          next
        </CardWizardControlButton>
      </CardWizardControls>
    </CardWizardStep>
  );
};

export default UpsellErrorStep;
