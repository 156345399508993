import * as Immutable from 'immutable';

export type RenderInputCallback = (
  value: string,
  handleInputChange: (e: React.ChangeEvent<HTMLElement>) => void,
  handleInputKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void,
) => React.ReactNode;

export type RenderValueCallback = (value: string) => React.ReactNode;

export interface IProps {
  className?: string;
  activeClassName?: string;
  formClassName?: string;
  inputMode?: boolean;
  onCancel?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onChange?: (value: string) => void;
  onClickOut?: (value: string, e: React.MouseEvent<HTMLElement>) => void;
  onEnterPressed?: (value: string, e: React.KeyboardEvent<HTMLElement>) => void;
  onEscPressed?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onOpen?: (e: React.MouseEvent<HTMLElement>) => void;
  onSubmit?: (value: string, e: React.SyntheticEvent<HTMLElement>) => void;
  onTabPressed?: (e: React.KeyboardEvent<HTMLElement>) => void;
  renderInput?: RenderInputCallback;
  renderValue?: RenderValueCallback;
  value?: string;
}

interface IDataState {
  value: string;
}

export const dataStateFactory = Immutable.Record<IDataState>({
  value: undefined,
});

export interface IState {
  data: Immutable.RecordOf<IDataState>;
}
