import _ from 'underscore';

import IntervalTree from 'utils/IntervalTree';
import { round } from 'utils/numbers';
import {
  GainTransition,
  IAudioLevel,
  IAudioTransition,
  IWebAudioSource,
} from './types';

type Source = Pick<
  IWebAudioSource,
  'audioLevels' | 'buffer' | 'fromSeconds' | 'startOffsetSeconds' | 'toSeconds'
>;

function transitionDuration(transition: IAudioTransition) {
  return !transition ||
    transition.effect === 'cut' ||
    _.isUndefined(transition.durationSec)
    ? 0
    : transition.durationSec;
}

function createGainIntervals(source: Source) {
  const bufferDuration = source.buffer.duration;

  const itree = new IntervalTree<
    Pick<IAudioLevel, 'value' | 'transitionIn' | 'transitionOut'>
  >();
  (source.audioLevels || []).forEach(level => {
    const {
      startSec,
      endSec = bufferDuration,
      value,
      transitionIn,
      transitionOut,
    } = level;

    /*
     * cut out a sgement in the interval tree to fit the new interval.  this will ensure that
     * there are no overlaps when the new interval is inserted.  when the segment is chopped out,
     * the remaining segments on either side will have the correct gain level.  all we have
     * to do to schedule gain changes is iterate through the tree in order
     */
    itree.chop(startSec, endSec);
    itree.insert(startSec, endSec, { value, transitionIn, transitionOut });
  });

  return Array.from(itree.inOrder());
}

/**
 * schedule gain changes for intervals handling overlaps.
 * overlaps are handled by the order of `source.audioLevels`. Audio levels with a higher index in
 * the array win out over levels with a lower index when audio level intervals overlap.
 */
export function scheduleGainChanges(
  source: Source,
  setGainAtTime: (transition: GainTransition) => void,
  defaultGain: number = 1,
) {
  const intervals = createGainIntervals(source);
  const sourceEndSec = round(
    source.startOffsetSeconds + (source.toSeconds - source.fromSeconds),
    -3,
  );

  /*
   * set the gain at time 0 for this source. if the audioLevels has an entry for time 0, it will
   * override this setting , which is exactly what we want
   */
  setGainAtTime({
    atSec: 0,
    effect: 'cut',
    toGain: defaultGain,
  });

  for (const { low: startSec, high: endSec, data } of intervals) {
    const { transitionIn, transitionOut, value: gain } = data;

    // duration for which this source should have the given audio level
    const segDuration = endSec - startSec;

    // global time that this segment ends
    const segEndSec = round(source.startOffsetSeconds + endSec, -3);

    const inEffect = transitionIn ? transitionIn.effect : 'cut';
    const outEffect = transitionOut ? transitionOut.effect : 'cut';

    // raw transition durations - doesn't take the segment duration into account
    const inDuration = transitionDuration(transitionIn);
    const outDuration = transitionDuration(transitionOut);
    const totalTransitionDuration = inDuration + outDuration;

    const effectiveDuration = (duration: number) =>
      totalTransitionDuration < segDuration
        ? duration
        : (duration / totalTransitionDuration) * segDuration;

    // effective durations - if the segment isn't long enough to transition in and out, scale the
    // the transition durations so that they fit within the segment
    const effectiveInDuration = effectiveDuration(inDuration);
    const effectiveOutDuration = effectiveDuration(outDuration);

    if (inEffect === 'fade') {
      setGainAtTime({
        atSec: startSec,
        durationSec: effectiveInDuration,
        effect: 'fade',
        fromGain: startSec === 0 ? 0 : defaultGain,
        toGain: gain,
      });
    } else {
      setGainAtTime({
        atSec: startSec,
        effect: 'cut',
        toGain: gain,
      });
    }

    if (outEffect === 'fade') {
      const fadeOutStart = endSec - effectiveOutDuration;
      setGainAtTime({
        atSec: fadeOutStart,
        durationSec: effectiveOutDuration,
        effect: 'fade',
        fromGain: gain,
        toGain: segEndSec >= sourceEndSec ? 0 : defaultGain,
      });
    } else {
      setGainAtTime({
        atSec: endSec,
        effect: 'cut',
        toGain: defaultGain,
      });
    }
  }
}

/**
 * Checks if a transition should be immediate. For this it checks the type to be "cut",
 * the atSec to be 0 (meaning it is the initial gain change) and that the gain change is
 * 0.
 * @param {Object} transition Transition object
 * @returns {boolean} Wheter if the transition should be immediate or not.
 */
export const shouldUseImmediateTransition = (
  transition: GainTransition,
): boolean => {
  const { effect, atSec, toGain } = transition;
  return effect === 'cut' && atSec === 0 && toGain === 0;
};
