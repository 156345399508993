export const DEFAULT_LANGUAGE = 'en-US';

export const languages = [
  { value: 'en-US', label: 'English (United States)' },
  { value: 'es-ES', label: 'Spanish (Spain)' },
  { value: 'de-DE', label: 'German (Germany)' },
  { value: 'fr-FR', label: 'French (France)' },
  { value: 'it-IT', label: 'Italian (Italy)' },
  { value: 'pt-PT', label: 'Portuguese (Portugal)' },
  { value: 'af-ZA', label: 'Afrikaans (South Africa)' },
  { value: 'sq-AL', label: 'Albanian (Albania)' },
  { value: 'am-ET', label: 'Amharic (Ethiopia)' },
  { value: 'ar-DZ', label: 'Arabic (Algeria)' },
  { value: 'ar-BH', label: 'Arabic (Bahrain)' },
  { value: 'ar-EG', label: 'Arabic (Egypt)' },
  { value: 'ar-IQ', label: 'Arabic (Iraq)' },
  { value: 'ar-IL', label: 'Arabic (Israel)' },
  { value: 'ar-JO', label: 'Arabic (Jordan)' },
  { value: 'ar-KW', label: 'Arabic (Kuwait)' },
  { value: 'ar-LB', label: 'Arabic (Lebanon)' },
  { value: 'ar-MA', label: 'Arabic (Morocco)' },
  { value: 'ar-OM', label: 'Arabic (Oman)' },
  { value: 'ar-QA', label: 'Arabic (Qatar)' },
  { value: 'ar-SA', label: 'Arabic (Saudi Arabia)' },
  { value: 'ar-PS', label: 'Arabic (State of Palestine)' },
  { value: 'ar-TN', label: 'Arabic (Tunisia)' },
  { value: 'ar-AE', label: 'Arabic (United Arab Emirates)' },
  { value: 'ar-YE', label: 'Arabic (Yemen)' },
  { value: 'hy-AM', label: 'Armenian (Armenia)' },
  { value: 'az-AZ', label: 'Azerbaijani (Azerbaijan)' },
  { value: 'eu-ES', label: 'Basque (Spain)' },
  { value: 'bn-BD', label: 'Bengali (Bangladesh)' },
  { value: 'bn-IN', label: 'Bengali (India)' },
  { value: 'bs-BA', label: 'Bosnian (Bosnia and Herzegovina)' },
  { value: 'bg-BG', label: 'Bulgarian (Bulgaria)' },
  { value: 'my-MM', label: 'Burmese (Myanmar)' },
  { value: 'ca-ES', label: 'Catalan (Spain)' },
  {
    value: 'yue-Hant-HK',
    label: 'Chinese, Cantonese (Traditional, Hong Kong)',
  },
  {
    value: 'cmn-Hans-CN',
    label: 'Chinese, Mandarin (Simplified, China)',
  },
  {
    value: 'cmn-Hans-HK',
    label: 'Chinese, Mandarin (Simplified, Hong Kong)',
  },
  {
    value: 'cmn-Hant-TW',
    label: 'Chinese, Mandarin (Traditional, Taiwan)',
  },
  { value: 'hr-HR', label: 'Croatian (Croatia)' },
  { value: 'cs-CZ', label: 'Czech (Czech Republic)' },
  { value: 'da-DK', label: 'Danish (Denmark)' },
  { value: 'nl-BE', label: 'Dutch (Belgium)' },
  { value: 'nl-NL', label: 'Dutch (Netherlands)' },
  { value: 'en-AU', label: 'English (Australia)' },
  { value: 'en-CA', label: 'English (Canada)' },
  { value: 'en-GH', label: 'English (Ghana)' },
  { value: 'en-HK', label: 'English (Hong Kong)' },
  { value: 'en-IN', label: 'English (India)' },
  { value: 'en-IE', label: 'English (Ireland)' },
  { value: 'en-KE', label: 'English (Kenya)' },
  { value: 'en-NZ', label: 'English (New Zealand)' },
  { value: 'en-NG', label: 'English (Nigeria)' },
  { value: 'en-PK', label: 'English (Pakistan)' },
  { value: 'en-PH', label: 'English (Philippines)' },
  { value: 'en-SG', label: 'English (Singapore)' },
  { value: 'en-ZA', label: 'English (South Africa)' },
  { value: 'en-TZ', label: 'English (Tanzania)' },
  { value: 'en-GB', label: 'English (United Kingdom)' },
  { value: 'et-EE', label: 'Estonian (Estonia)' },
  { value: 'fil-PH', label: 'Filipino (Philippines)' },
  { value: 'fi-FI', label: 'Finnish (Finland)' },
  { value: 'fr-BE', label: 'French (Belgium)' },
  { value: 'fr-CA', label: 'French (Canada)' },
  { value: 'fr-CH', label: 'French (Switzerland)' },
  { value: 'gl-ES', label: 'Galician (Spain)' },
  { value: 'ka-GE', label: 'Georgian (Georgia)' },
  { value: 'de-AT', label: 'German (Austria)' },
  { value: 'de-CH', label: 'German (Switzerland)' },
  { value: 'el-GR', label: 'Greek (Greece)' },
  { value: 'gu-IN', label: 'Gujarati (India)' },
  { value: 'he-IL', label: 'Hebrew (Israel)' },
  { value: 'hi-IN', label: 'Hindi (India)' },
  { value: 'hu-HU', label: 'Hungarian (Hungary)' },
  { value: 'is-IS', label: 'Icelandic (Iceland)' },
  { value: 'id-ID', label: 'Indonesian (Indonesia)' },
  { value: 'it-CH', label: 'Italian (Switzerland)' },
  { value: 'ja-JP', label: 'Japanese (Japan)' },
  { value: 'jv-ID', label: 'Javanese (Indonesia)' },
  { value: 'kn-IN', label: 'Kannada (India)' },
  { value: 'kk-KZ', label: 'Kazakh (Kazakhstan)' },
  { value: 'km-KH', label: 'Khmer (Cambodia)' },
  { value: 'ko-KR', label: 'Korean (South Korea)' },
  { value: 'lo-LA', label: 'Lao (Laos)' },
  { value: 'lv-LV', label: 'Latvian (Latvia)' },
  { value: 'lt-LT', label: 'Lithuanian (Lithuania)' },
  { value: 'mk-MK', label: 'Macedonian (North Macedonia)' },
  { value: 'ms-MY', label: 'Malay (Malaysia)' },
  { value: 'ml-IN', label: 'Malayalam (India)' },
  { value: 'mr-IN', label: 'Marathi (India)' },
  { value: 'mn-MN', label: 'Mongolian (Mongolia)' },
  { value: 'ne-NP', label: 'Nepali (Nepal)' },
  { value: 'nb-NO', label: 'Norwegian Bokmål (Norway)' },
  { value: 'fa-IR', label: 'Persian (Iran)' },
  { value: 'pl-PL', label: 'Polish (Poland)' },
  { value: 'pt-BR', label: 'Portuguese (Brazil)' },
  { value: 'pa-Guru-IN', label: 'Punjabi (Gurmukhi India)' },
  { value: 'ro-RO', label: 'Romanian (Romania)' },
  { value: 'ru-RU', label: 'Russian (Russia)' },
  { value: 'sr-RS', label: 'Serbian (Serbia)' },
  { value: 'si-LK', label: 'Sinhala (Sri Lanka)' },
  { value: 'sk-SK', label: 'Slovak (Slovakia)' },
  { value: 'sl-SI', label: 'Slovenian (Slovenia)' },
  { value: 'es-AR', label: 'Spanish (Argentina)' },
  { value: 'es-BO', label: 'Spanish (Bolivia)' },
  { value: 'es-CL', label: 'Spanish (Chile)' },
  { value: 'es-CO', label: 'Spanish (Colombia)' },
  { value: 'es-CR', label: 'Spanish (Costa Rica)' },
  { value: 'es-DO', label: 'Spanish (Dominican Republic)' },
  { value: 'es-EC', label: 'Spanish (Ecuador)' },
  { value: 'es-SV', label: 'Spanish (El Salvador)' },
  { value: 'es-GT', label: 'Spanish (Guatemala)' },
  { value: 'es-HN', label: 'Spanish (Honduras)' },
  { value: 'es-MX', label: 'Spanish (Mexico)' },
  { value: 'es-NI', label: 'Spanish (Nicaragua)' },
  { value: 'es-PA', label: 'Spanish (Panama)' },
  { value: 'es-PY', label: 'Spanish (Paraguay)' },
  { value: 'es-PE', label: 'Spanish (Peru)' },
  { value: 'es-PR', label: 'Spanish (Puerto Rico)' },
  { value: 'es-US', label: 'Spanish (United States)' },
  { value: 'es-UY', label: 'Spanish (Uruguay)' },
  { value: 'es-VE', label: 'Spanish (Venezuela)' },
  { value: 'su-ID', label: 'Sundanese (Indonesia)' },
  { value: 'sw-KE', label: 'Swahili (Kenya)' },
  { value: 'sw-TZ', label: 'Swahili (Tanzania)' },
  { value: 'sv-SE', label: 'Swedish (Sweden)' },
  { value: 'ta-IN', label: 'Tamil (India)' },
  { value: 'ta-MY', label: 'Tamil (Malaysia)' },
  { value: 'ta-SG', label: 'Tamil (Singapore)' },
  { value: 'ta-LK', label: 'Tamil (Sri Lanka)' },
  { value: 'te-IN', label: 'Telugu (India)' },
  { value: 'th-TH', label: 'Thai (Thailand)' },
  { value: 'tr-TR', label: 'Turkish (Turkey)' },
  { value: 'uk-UA', label: 'Ukrainian (Ukraine)' },
  { value: 'ur-IN', label: 'Urdu (India)' },
  { value: 'ur-PK', label: 'Urdu (Pakistan)' },
  { value: 'uz-UZ', label: 'Uzbek (Uzbekistan)' },
  { value: 'vi-VN', label: 'Vietnamese (Vietnam)' },
  { value: 'zu-ZA', label: 'Zulu (South Africa)' },
];
