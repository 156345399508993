import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import useOnMount from 'hooks/useOnMount';
import {
  logout,
  tokenSelector,
  userRegisteredSelector,
} from 'redux/modules/auth';
import { getDisplayName } from 'utils/react';

// hoc to log a user out if the route cannot be wrapped in userIsAuthenticated
export default function withLogout() {
  return <P extends {}>(Component: React.ComponentType<P>) => {
    function EnhancedComponent(props: P) {
      const dispatch = useDispatch();
      const hasToken = !!useSelector(tokenSelector);
      const isTokenValid = useSelector(userRegisteredSelector);

      useOnMount(() => {
        if (hasToken && !isTokenValid) {
          dispatch(logout(false));
        }
      });

      return <Component {...props} />;
    }

    EnhancedComponent.displayName = `withLogout(${getDisplayName(Component)})`;

    return EnhancedComponent;
  };
}
