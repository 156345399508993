import cn from 'classnames';
import * as React from 'react';
import Spinner from 'react-spinkit';

import BemCssTransition from 'components/BemCssTransition';
import bem from 'utils/bem';

interface IProps {
  className?: string;
  fadeout?: boolean;
  message: React.ReactNode;
  show?: boolean;
  spinner?: JSX.Element;
}

const block = bem('loading-screen');

const LoadingScreen: React.SFC<IProps> = ({
  className,
  fadeout,
  message,
  show,
  spinner,
}) => (
  <BemCssTransition
    exit={fadeout}
    in={show}
    className={block()}
    timeout={250}
    unmountOnExit
  >
    <div className={cn(block(), className)}>
      <div className={block('contents')}>
        <div className={block('message')}>{message}</div>
        {React.cloneElement(spinner, {
          className: cn(block('spinner'), spinner.props.className),
        })}
      </div>
    </div>
  </BemCssTransition>
);

LoadingScreen.defaultProps = {
  fadeout: false,
  show: true,
  spinner: (
    <Spinner className="spinner--wave spinner--large" spinnerName="wave" />
  ),
};

export default LoadingScreen;
export { IProps as LoadingScreenProps };
