import { useSelector } from 'react-redux';
import { isInvoicePastDueSelector } from 'redux/modules/pricing';

export default function useCreditCardRecoveryBanner() {
  const pastDue = useSelector(isInvoicePastDueSelector);

  return {
    show: pastDue,
  };
}
