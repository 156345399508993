import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import { Props } from './types';
import { block } from './utils';

const DownloadCaptionsModal: React.FC<Props> = ({ children, name, title }) => {
  return (
    <ConnectedModal backdrop="static" className={block()} {...{ name, title }}>
      {children}
    </ConnectedModal>
  );
};

export default DownloadCaptionsModal;
