import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'AUDIOGRAM_DATA';
export const ACTION_KEY: ActionKey = 'AUDIOGRAM_DATA';

export enum ServiceMethod {
  GET_AUDIOGRAM_DATA_BY_RECORDING_VERSION = 'GET_AUDIOGRAM_DATA_BY_RECORDING_VERSION',
  WAVEFORM_RESOLVED = 'WAVEFORM_RESOLVED',
}

export type WaveformStatus =
  | 'inProgress'
  | 'completed'
  | 'failed'
  | 'failureAcked'
  | 'queued'
  | 'notFound';

export interface IWaveform {
  framesPerSecond: number;
  id: number;
  link: string;
  samplesPerFrame: number;
  status: WaveformStatus;
}

export interface INormalizedWaveform {
  entities: {
    waveforms: {
      [id: number]: IWaveform;
    };
  };
  result: number;
}

export type GetByVersionIdArgs = [number];
export type GetByVersionIdResult = INormalizedWaveform;
export type WaveformResolvedResult = INormalizedWaveform & {
  isResolved: boolean;
};

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export type GetByVersionIdAction = ApiAction<
  ServiceMethod.GET_AUDIOGRAM_DATA_BY_RECORDING_VERSION,
  GetByVersionIdArgs
>;

export type WaveformResolvedAction = ApiAction<
  ServiceMethod.WAVEFORM_RESOLVED,
  GetByVersionIdArgs
>;

export interface IHandle {
  (
    method: ServiceMethod.GET_AUDIOGRAM_DATA_BY_RECORDING_VERSION,
    args: GetByVersionIdArgs,
    token?: string,
  ): Promise<GetByVersionIdResult>;

  (
    method: ServiceMethod.WAVEFORM_RESOLVED,
    args: GetByVersionIdArgs,
    token?: string,
  ): Promise<WaveformResolvedResult>;
}

export interface AudiogramDataServiceDispatch {
  (action: GetByVersionIdAction): Promise<IApiResponse<GetByVersionIdResult>>;
  (action: WaveformResolvedAction): Promise<
    IApiResponse<WaveformResolvedResult>
  >;
}
