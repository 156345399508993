import React from 'react';

import { useTextOverlay } from '../state/TextOverlayProvider';
import {
  scaleFontStyles,
  transformShadowStyle,
} from '../style-transform-utils';
import {
  getTextAreaStyles,
  getTextBuilderStyles,
} from '../text-builder-styles';
import { TextBuilderStyles } from '../types';

interface UseTextOverlayStyle {
  textAreaStyles: React.CSSProperties;
  textBuilderStyles: TextBuilderStyles;
}

const useTextOverlayStyle = (): UseTextOverlayStyle => {
  const { canvasSize, draftEditorData } = useTextOverlay();

  if (!draftEditorData) {
    return {
      textAreaStyles: {},
      textBuilderStyles: {
        emptyLineStyle: {},
        lineStyle: {},
        paragraphStyle: {},
      },
    };
  }

  const displayTextStyles = transformShadowStyle(
    scaleFontStyles(draftEditorData, canvasSize),
  );

  const textAreaStyles = getTextAreaStyles(displayTextStyles);
  const textBuilderStyles = getTextBuilderStyles(displayTextStyles);

  return {
    textAreaStyles,
    textBuilderStyles,
  };
};

export default useTextOverlayStyle;
