import classNames from 'classnames';
import * as React from 'react';
import _ from 'underscore';

import { FileUploaderProps as BaseFileUploaderProps } from 'components/FileUploader';
import FontAwesome from 'components/FontAwesome';
import { MediaFile } from 'components/icons';
import ImageUploader from 'components/ImageUploader';
import LoadingOverlay from 'components/LoadingOverlay';
import { SUPPORTED_WATERMARK_IMAGE_TYPES } from 'utils/formats';
import LogoPreview, { IProps as LogoPreviewProps } from './LogoPreview';
import { logoModalBlock } from './utils';

type PreviewProps = Pick<
  LogoPreviewProps,
  | 'aspectRatio'
  | 'logoImageSrc'
  | 'onChangeWatermarkPosition'
  | 'onChangeWatermarkSize'
  | 'watermark'
>;

type FileUploaderProps = BaseFileUploaderProps & {
  multiple: false;
};

type UploaderProps = Pick<
  FileUploaderProps,
  'onFileAccepted' | 'onFileRejected'
>;

export interface IProps extends PreviewProps, UploaderProps {
  showLoadingOverlay?: boolean;
}

const AddLogoModalBody: React.SFC<IProps> = ({
  aspectRatio,
  logoImageSrc,
  onFileAccepted,
  onFileRejected,
  onChangeWatermarkPosition,
  onChangeWatermarkSize,
  showLoadingOverlay,
  watermark,
}) => {
  const className = classNames(
    logoModalBlock('add-logo-body'),
    logoModalBlock('add-logo-body', { nologo: _.isUndefined(logoImageSrc) }),
  );

  return (
    <div className={className}>
      {showLoadingOverlay && <LoadingOverlay title="Adding logo to video" />}

      {logoImageSrc ? (
        <LogoPreview
          aspectRatio={aspectRatio}
          logoImageSrc={logoImageSrc}
          onChangeWatermarkPosition={onChangeWatermarkPosition}
          onChangeWatermarkSize={onChangeWatermarkSize}
          watermark={watermark}
        />
      ) : (
        <div>
          <div className={logoModalBlock('instructions')}>
            <p>
              This image will be overlaid as the watermark over the entire
              video.
            </p>

            <p>
              <span
                className={classNames('fa-stack', logoModalBlock('info-icon'))}
              >
                <FontAwesome icon="question-circle" />
              </span>
              <span>
                Learn more about watermarks in our{' '}
                <a
                  href="https://learn.headliner.app/hc/en-us/articles/360003811873-How-To-Upload-A-Logo-Watermark-And-Use-The-Media-Tab"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Knowledge Base article
                </a>
                .
              </span>
            </p>
          </div>

          <ImageUploader
            className={logoModalBlock('uploader')}
            onFileAccepted={onFileAccepted}
            onFileRejected={onFileRejected}
            accept={SUPPORTED_WATERMARK_IMAGE_TYPES.join(',')}
            image={<MediaFile width={84} height={94} />}
            supportedFileTypes={SUPPORTED_WATERMARK_IMAGE_TYPES}
          />

          <div className={logoModalBlock('terms')}>
            By uploading any files and/or images through our service, you
            represent and warrant that you own or otherwise have the valid right
            to use such files and images in connection with the SpareMin
            software and this site, and acknowledge that you have read and
            accepted our{' '}
            <a
              href={spareminConfig.legalUrls.termsOfServiceUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms of Service
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

AddLogoModalBody.defaultProps = {
  onChangeWatermarkPosition: _.noop,
  onChangeWatermarkSize: _.noop,
  showLoadingOverlay: false,
  watermark: null,
};

export default AddLogoModalBody;
