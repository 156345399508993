import {
  InTransition,
  OutTransition,
} from 'components/TextOverlayTransitionsForm/types';

export enum InTransitions {
  FADE_IN = 'fadeIn',
  SLIDE = 'slide',
  ZOOM_IN = 'zoomIn',
  ZOOM_OUT = 'zoomOut',
  FADE_IN_LEFT = 'fadeInLeft',
  WIPE_IN_RIGHT = 'wipeInRight',
  PER_WORD_CUT = 'perWordCut',
  PER_WORD_SLIDE_UP = 'perWordSlideUp',
  IN_FRAME_SLIDE = 'inFrameSlide',
  IN_FRAME_SLIDE_UP = 'inFrameSlideUp',
  CUT = 'cut',
}

export enum OutTransitions {
  FADE_OUT = 'fadeOut',
  SLIDE = 'slide',
  ZOOM_IN = 'zoomIn',
  ZOOM_OUT = 'zoomOut',
  FADE_OUT_RIGHT = 'fadeOutRight',
  WIPE_OUT_RIGHT = 'wipeOutRight',
  OUT_FRAME_SLIDE = 'outFrameSlide',
  OUT_FRAME_SLIDE_UP = 'outFrameSlideUp',
  CUT = 'cut',
}

export interface TextTransitionSelectProps {
  options: typeof InTransitions | typeof OutTransitions;
  value?: InTransition | OutTransition;
  onChange: (value: InTransition | OutTransition) => void;
  disabled?: boolean;
  direction: TransitionDirections;
  defaultValue?: string;
}

export interface TextTransitionOptionProps {
  value: InTransitions | OutTransitions;
  selected: boolean;
  onSelect: (value: InTransitions | OutTransitions) => void;
  direction: TransitionDirections;
  disabled?: boolean;
}

export enum TransitionDirections {
  IN = 'in',
  OUT = 'out',
}
