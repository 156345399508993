import * as React from 'react';

import { IconProps } from './types';

function FontFile({ fill = 'currentColor', ...props }: IconProps) {
  return (
    <svg
      width="90"
      height="104"
      viewBox="0 0 90 104"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <filter
          x="-53.7%"
          y="-36.8%"
          width="207.4%"
          height="185.3%"
          filterUnits="objectBoundingBox"
          id="ybgsfgbqha"
        >
          <feMorphology
            radius="1"
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy="4"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="8"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M3 0h36.25a3 3 0 0 1 2.13.887l11.75 11.836a3 3 0 0 1 .87 2.113V65a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z"
          id="sfkot3ycib"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(18 14)">
          <use fill="#000" filter="url(#ybgsfgbqha)" xlinkHref="#sfkot3ycib" />
          <use fill="#323746" xlinkHref="#sfkot3ycib" />
        </g>
        <path
          d="M55.111 35A2.889 2.889 0 0 1 58 37.889V58.11A2.889 2.889 0 0 1 55.111 61H34.89A2.889 2.889 0 0 1 32 58.111V37.89A2.889 2.889 0 0 1 34.889 35H55.11zm-8.974 6h-2.274a.5.5 0 0 0-.472.336L39.336 53H38.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h4.25a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H42l.83-2.524h4.32L47.98 53h-.73a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h4.25a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-.836L46.61 41.336a.5.5 0 0 0-.472-.336zm-1.139 2.424c.026.204.092.609.242 1.085l1.291 3.875H43.45l1.312-3.877c.136-.476.207-.88.237-1.083z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default FontFile;
