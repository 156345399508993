import { ParsedQuery, ParseOptions } from 'query-string';
import React from 'react';
import { WithRouterProps, WithRouterStatics } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Omit } from 'types';
import { getDisplayName } from 'utils/react';
import { parseSearch } from 'utils/routes';

type QueryValueType = string | number | boolean;

type Query<T extends QueryValueType> = ParsedQuery<T>;
export interface WithRouterComponentProps<
  T extends QueryValueType = QueryValueType
> extends Omit<RouteComponentProps, 'location'> {
  location: RouteComponentProps['location'] & { query?: Query<T> };
}

export default function<
  T extends QueryValueType,
  P extends WithRouterComponentProps<T>,
  C extends React.ComponentType<P>
>(
  Component: C & React.ComponentType<P>,
  opts?: ParseOptions,
): React.ComponentClass<
  Omit<P, keyof WithRouterComponentProps> & WithRouterProps<C>
> &
  WithRouterStatics<C> {
  const Wrapper: React.FC<RouteComponentProps> = ({ location, ...props }) => {
    const query = parseSearch(location.search, opts);

    return (
      <>
        {/*
        // @ts-ignore */}
        <Component
          {...props}
          location={{
            ...location,
            query,
          }}
        />
      </>
    );
  };

  (Wrapper as any).displayName = `WithRouter(${getDisplayName(Component)})`;

  return withRouter(Wrapper) as any;
}
