import { createSelector } from 'reselect';
import { isUndefined } from 'underscore';

import {
  favoritePodcastsSelector,
  podcastDetailEpisodesSelector,
  podcastsSelector,
} from 'redux/modules/entities/selectors';
import { State } from 'redux/types';
import { getValue } from 'utils/collections';
import { PodcastSearchState } from './types';

const stateSelector = (state: State): PodcastSearchState =>
  state.get('podcastSearch');

export const podcastResultsSelector = createSelector(
  stateSelector,
  state => state.results,
);

export const hasPodcastResultsSelector = createSelector(
  podcastResultsSelector,
  results => !isUndefined(results),
);

export const descriptSelector = createSelector(
  stateSelector,
  state => state.descript,
);

export const isLoadingPodcastsSelector = createSelector(
  stateSelector,
  state => state.isLoadingPodcasts,
);

export const isLoadingEpisodesSelector = createSelector(
  stateSelector,
  state => state.isLoadingEpisodes,
);

export const podcastListScrollOffsetSelector = createSelector(
  stateSelector,
  state => state.podcastListScrollOffset,
);

export const episodeListScrollOffsetSelector = createSelector(
  stateSelector,
  state => state.episodeListScrollOffset,
);

export const selectedPodcastIdSelector = createSelector(
  stateSelector,
  state => state.selectedPodcastId,
);

export const selectedEpisodeIdSelector = createSelector(
  stateSelector,
  state => state.selectedEpisodeId,
);

export const selectedPodcastSelector = createSelector(
  [podcastsSelector, favoritePodcastsSelector, selectedPodcastIdSelector],
  (podcasts, favoritePodcasts, podcastId) =>
    podcasts?.get(podcastId)?.toJS() ??
    favoritePodcasts?.get(podcastId)?.toJS(),
);

export const selectedPodcastEpisodeIdsSelector = createSelector(
  selectedPodcastSelector,
  podcast => podcast?.episodes,
);

export const selectedPodcastCanRefreshFeedSelector = createSelector(
  selectedPodcastSelector,
  podcast => podcast?.canRefreshFeed,
);

export const selectedEpisodeSelector = createSelector(
  [podcastDetailEpisodesSelector, selectedEpisodeIdSelector],
  (episodes, episodeId) => {
    if (!episodes || !episodeId) return undefined;
    return getValue(episodes, episodeId);
  },
);

export const querySelector = createSelector(stateSelector, state =>
  state.getIn(['query', 'q']),
);

export const podcastResultsOffsetSelector = createSelector(
  stateSelector,
  state => state.getIn(['query', 'offset']),
);

export const podcastResultsNextOffsetSelector = createSelector(
  stateSelector,
  state => state.getIn(['query', 'nextOffset']),
);

export const nextEpisodePublishMillisSelector = createSelector(
  stateSelector,
  state => state.nextEpisodePublishMillis,
);

const podcastResultCountSelector = createSelector(
  podcastResultsSelector,
  results => (!results ? 0 : results.size),
);

const podcastTotalResultsSelector = createSelector(stateSelector, state =>
  state.getIn(['query', 'totalResults']),
);

export const hasMorePodcastsSelector = createSelector(
  [podcastResultCountSelector, podcastTotalResultsSelector],
  (resultCount, totalResults) => resultCount < totalResults,
);

export const selectedPodcastThumbnailUrlSelector = createSelector(
  selectedPodcastSelector,
  podcast => podcast?.thumbnailUrl,
);

export const searchTermSelector = createSelector(stateSelector, state =>
  state.get('searchTerm'),
);

export const searchHandlerIntegrationSelector = createSelector(
  stateSelector,
  state => state.get('searchHandlerIntegration'),
);

export const favoritePodcastIdsSelector = createSelector(
  stateSelector,
  state => {
    return state.get('favorites')?.toJS();
  },
);

export const defaultPodcastIdSelector = createSelector(
  favoritePodcastIdsSelector,
  ids => {
    return ids?.[0];
  },
);

const makePodcastSelector = (podcastId: string) => {
  return createSelector(
    favoritePodcastsSelector,
    podcastsSelector,
    (favoritePodcasts, podcasts) => {
      const podcast =
        podcasts?.get(podcastId) ?? favoritePodcasts?.get(podcastId);

      return podcast;
    },
  );
};

const makeEpisodeSelector = (episodeId: string) => {
  return createSelector(podcastDetailEpisodesSelector, episodes => {
    return episodes?.get(episodeId);
  });
};

export const makePodcastArtworkSelector = (podcastId: string) => {
  return createSelector(makePodcastSelector(podcastId), podcast => {
    return podcast?.get('imageUrl') ?? podcast?.get('thumbnailUrl');
  });
};

export const makePodcastTitleSelector = (podcastId: string) => {
  return createSelector(makePodcastSelector(podcastId), podcast => {
    return podcast?.get('title');
  });
};

const makeEpisodeArtworkSelector = (episodeId: string) => {
  return createSelector(makeEpisodeSelector(episodeId), episode => {
    return episode?.get('imageUrl') ?? episode?.get('thumbnailUrl');
  });
};

export const makeArtworkUrlSelector = (
  podcastId: string,
  episodeId: string,
) => {
  return createSelector(
    makePodcastArtworkSelector(podcastId),
    makeEpisodeArtworkSelector(episodeId),
    (podcastArt, episodeArt) => episodeArt || podcastArt,
  );
};

export const makePodcastImageUrlSelector = (podcastId: string) => {
  return createSelector(makePodcastSelector(podcastId), podcast => {
    return podcast?.get('imageUrl');
  });
};
