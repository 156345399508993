import { ColorPaletteState } from '@sparemin/blockhead';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiImageType } from 'redux/middleware/api/image-search-service';

import {
  clearAllSearchResults as clearAllGifSearchResults,
  clearSearchResults as clearGifSearchResults,
  searchForGifs,
} from 'redux/modules/gif-search/actions';
import { gifSearchQueriesSelector } from 'redux/modules/gif-search/selectors';
import {
  clearAllSearchResults as clearAllImageSearchResults,
  clearSearchResults as clearImageSearchResults,
  searchForImages,
} from 'redux/modules/image-search/actions';
import { imageSearchQueriesSelector } from 'redux/modules/image-search/selectors';
import { showError } from 'redux/modules/notification';
import { createTextToImage } from 'redux/modules/text-to-image';
import { MediaType as TextToImageMediaType } from 'redux/modules/text-to-image/types';
import {
  clearAllSearchResults as clearAllVideoSearchResults,
  clearSearchResults as clearVideoSearchResults,
  searchForVideos,
} from 'redux/modules/video-search/actions';
import { videoSearchQueriesSelector } from 'redux/modules/video-search/selectors';
import { AspectRatioName } from 'types';
import { getAspectRatio } from 'utils/aspect-ratio';

type MediaType = 'image' | 'video' | 'gif-video';
type GifSearchEngine = 'giphy' | 'giphySticker' | 'tenor';
type ImageSearchEngine = 'google' | 'microsoft' | 'pixabay' | 'textToImage';
type VideoSearchEngine = 'pixabay' | 'textToVideo';

interface UseMediaSearchConfig {
  aspectRatioName?: AspectRatioName;
  imageType?: AiImageType;
  colorPaletteState?: ColorPaletteState;
  defaultGifEngine?: GifSearchEngine;
  defaultImageEngine?: ImageSearchEngine;
  defaultVideoEngine?: VideoSearchEngine;
}

interface UseMediaSearch {
  onSelectMediaType: (mediaType: MediaType) => void;
  onSelectSearchEngine: (searchEngine: string) => void;
  onSubmitSearch: (query: string) => void;
  searchMediaType: MediaType;
  selectedGifSearchEngine: string;
  selectedImgSearchEngine: string;
  selectedVideoSearchEngine: string;
}

const useMediaSearch = (config: UseMediaSearchConfig = {}): UseMediaSearch => {
  const {
    aspectRatioName,
    imageType,
    colorPaletteState,
    defaultGifEngine = 'giphy',
    defaultImageEngine = 'textToImage',
    defaultVideoEngine = 'textToVideo',
  } = config;

  const [searchMediaType, setSearchMediaType] = React.useState<MediaType>(
    'image',
  );
  const [selectedGifSearchEngine, setSelectedGifSearchEngine] = React.useState<
    GifSearchEngine
  >(defaultGifEngine);
  const [selectedImgSearchEngine, setSelectedImgSearchEngine] = React.useState<
    ImageSearchEngine
  >(defaultImageEngine);
  const [
    selectedVideoSearchEngine,
    setSelectedVideoSearchEngine,
  ] = React.useState<VideoSearchEngine>(defaultVideoEngine);

  const dispatch = useDispatch();
  const gifQueries = useSelector(gifSearchQueriesSelector);
  const imageQueries = useSelector(imageSearchQueriesSelector);
  const videoQueries = useSelector(videoSearchQueriesSelector);

  const handleSelectMediaType = React.useCallback(
    (mediaType: MediaType): void => {
      setSearchMediaType(mediaType);
    },
    [],
  );

  const handleSelectSearchEngine = React.useCallback(
    (searchEngine: string) => {
      switch (searchMediaType) {
        case 'gif-video':
          setSelectedGifSearchEngine(searchEngine as GifSearchEngine);
          break;
        case 'image':
          setSelectedImgSearchEngine(searchEngine as ImageSearchEngine);
          break;
        case 'video':
          setSelectedVideoSearchEngine(searchEngine as VideoSearchEngine);
          break;
        default:
          break;
      }
    },
    [searchMediaType],
  );

  const handleClearQueries = React.useCallback(
    (
      searchData: { engine: string; query: string },
      opts: {
        singleQueryClearer: (engine: string) => void;
        allQueriesClearer: () => void;
        queries?: unknown[];
      },
    ) => {
      const { engine, query } = searchData;
      const { allQueriesClearer, queries, singleQueryClearer } = opts;
      const matchingQuery = queries?.indexOf?.(query) >= 0;
      if (matchingQuery) {
        dispatch(singleQueryClearer(engine));
      } else {
        dispatch(allQueriesClearer());
      }
    },
    [dispatch],
  );

  const handleClearGifQuery = React.useCallback(
    (engine: string, query: string): void => {
      handleClearQueries(
        { engine, query },
        {
          allQueriesClearer: clearAllGifSearchResults,
          queries: gifQueries,
          singleQueryClearer: clearGifSearchResults,
        },
      );
    },
    [gifQueries, handleClearQueries],
  );

  const handleClearImageQuery = React.useCallback(
    (engine: string, query: string): void => {
      handleClearQueries(
        { engine, query },
        {
          allQueriesClearer: clearAllImageSearchResults,
          queries: imageQueries,
          singleQueryClearer: clearImageSearchResults,
        },
      );
    },
    [handleClearQueries, imageQueries],
  );

  const handleClearVideoQuery = React.useCallback(
    (engine: string, query: string): void => {
      handleClearQueries(
        { engine, query },
        {
          allQueriesClearer: clearAllVideoSearchResults,
          queries: videoQueries,
          singleQueryClearer: clearVideoSearchResults,
        },
      );
    },
    [handleClearQueries, videoQueries],
  );

  const handleSearchGifs = React.useCallback(
    async (engine: string, query: string): Promise<void> => {
      handleClearGifQuery(engine, query);
      try {
        await dispatch(searchForGifs(engine, query));
      } catch {
        dispatch(showError('Gifs search failed'));
      }
    },
    [dispatch, handleClearGifQuery],
  );

  const handleCreateTextToImage = React.useCallback(
    async (query: string, mediaType: TextToImageMediaType): Promise<void> => {
      const newAspectRatio = getAspectRatio(aspectRatioName);

      await dispatch(
        createTextToImage(
          mediaType,
          query,
          'stability',
          newAspectRatio.get('width'),
          newAspectRatio.get('height'),
          imageType,
          colorPaletteState?.colors('hex'),
        ),
      );
    },
    [aspectRatioName, colorPaletteState, dispatch, imageType],
  );

  const handleSearchRegularImages = React.useCallback(
    async (engine: string, query: string): Promise<void> => {
      await dispatch(searchForImages(engine, query));
    },
    [dispatch],
  );

  const handleSearchImages = React.useCallback(
    async (engine: string, query: string): Promise<void> => {
      handleClearImageQuery(engine, query);
      try {
        switch (engine) {
          case 'textToImage':
            await handleCreateTextToImage(query, 'image');
            break;
          default:
            await handleSearchRegularImages(engine, query);
            break;
        }
      } catch {
        dispatch(showError('Images search failed'));
      }
    },
    [
      dispatch,
      handleClearImageQuery,
      handleCreateTextToImage,
      handleSearchRegularImages,
    ],
  );

  const handleSearchRegularVideos = React.useCallback(
    async (engine: string, query: string): Promise<void> => {
      await dispatch(searchForVideos(engine, query));
    },
    [dispatch],
  );

  const handleSearchVideos = React.useCallback(
    async (engine: string, query: string): Promise<void> => {
      handleClearVideoQuery(engine, query);

      try {
        switch (engine) {
          case 'textToVideo':
            await handleCreateTextToImage(query, 'video');
            break;
          default:
            await handleSearchRegularVideos(engine, query);
            break;
        }
      } catch {
        dispatch(showError('Videos search failed'));
      }
    },
    [
      dispatch,
      handleClearVideoQuery,
      handleCreateTextToImage,
      handleSearchRegularVideos,
    ],
  );

  const handleSearchSubmit = React.useCallback(
    (query: string): void => {
      switch (searchMediaType) {
        case 'gif-video':
          handleSearchGifs(selectedGifSearchEngine, query);
          break;
        case 'image':
          handleSearchImages(selectedImgSearchEngine, query);
          break;
        case 'video':
          handleSearchVideos(selectedVideoSearchEngine, query);
          break;
        default:
          break;
      }
    },
    [
      handleSearchGifs,
      handleSearchImages,
      handleSearchVideos,
      searchMediaType,
      selectedGifSearchEngine,
      selectedImgSearchEngine,
      selectedVideoSearchEngine,
    ],
  );

  return {
    onSelectMediaType: handleSelectMediaType,
    onSelectSearchEngine: handleSelectSearchEngine,
    onSubmitSearch: handleSearchSubmit,
    searchMediaType,
    selectedGifSearchEngine,
    selectedImgSearchEngine,
    selectedVideoSearchEngine,
  };
};

export default useMediaSearch;
