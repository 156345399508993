import React from 'react';

import FontAwesome from 'components/FontAwesome';
import { ProBadge } from 'containers/ProIndicator';
import { useToggleCard } from './ToggleCardContext';
import ToggleCardIcon from './ToggleCardIcon';

import { block } from './utils';

export interface ToggleCardTogglerProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isDisabled?: boolean;
}

const ToggleCardToggler: React.FC<ToggleCardTogglerProps> = ({
  onClick,
  isDisabled,
}) => {
  const { checked, isProGated } = useToggleCard();

  return (
    <div
      className={block('toggler', { disabled: isDisabled })}
      onClick={onClick}
    >
      {isProGated ? (
        <ProBadge />
      ) : (
        <>
          <ToggleCardIcon hidden={checked} isDisabled={isDisabled}>
            <FontAwesome
              className={block('toggle-plus', {
                checked,
                disabled: isDisabled,
              })}
              icon="plus"
              style={{ height: 12, width: 12 }}
            />
          </ToggleCardIcon>
          <ToggleCardIcon className={block('toggle-active')} hidden={!checked}>
            <FontAwesome className={block('toggle-check')} icon="check" />
            <FontAwesome className={block('toggle-x')} icon="plus" />
          </ToggleCardIcon>
        </>
      )}
    </div>
  );
};

export default ToggleCardToggler;
