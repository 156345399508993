import * as React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  underlineColor?: string;
}

const FontColorIcon: React.SFC<IProps> = ({ underlineColor, ...svgProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 60 78"
    {...svgProps}
  >
    {/* tslint:disable max-line-length */}
    <path
      fill="currentColor"
      d="m43.311,47.297l-26.666,0l-5.99,16.703l-8.655,0l24.306,-64l7.344,0l24.35,64l-8.612,0l-6.077,-16.703zm-24.13,-6.945l21.639,0l-10.842,-29.934l-10.798,29.934l0.001,0z"
    />
    <path
      fill={underlineColor}
      d="m56.97904,70l-53.98015,0c-1.65189,0 -2.9989,1.795 -2.9989,4s1.34616,4 2.9989,4l53.98015,0c1.65189,0 2.9989,-1.795 2.9989,-4s-1.33451,-4 -2.9989,-4z"
    />
    {/* tslint:enable */}
  </svg>
);

FontColorIcon.defaultProps = {
  underlineColor: 'currentColor',
};

export default FontColorIcon;
export { IProps as FontColorIconProps };
