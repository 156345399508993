import {
  PodcastSubscription,
  PodcastSubscriptionType,
  SubscriptionItem,
} from 'redux/middleware/api/podcast-service';
import {
  DurationOption,
  PartialUpdateAutomationData,
} from 'redux/modules/edit-automation';
import { EditAutomationProperties } from 'redux/modules/mixpanel/types';
import { getAutogramStartingPoint } from 'redux/modules/mixpanel/utils';
import {
  AutogramAutopostPlatform,
  AutogramMixpanelPlatform,
  AutogramVideoType,
  VideoTypes,
} from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';

import bem from 'utils/bem';

export const block = bem('edit-automation-modal');

export const AUTOMATION_DURATION_OPTIONS_MIXPANEL_MAP: Record<
  DurationOption,
  AutogramMixpanelPlatform
> = {
  15: 'InstagramStories',
  30: 'InstagramLinkedIn',
  45: 'X',
  60: 'Facebook',
  600: 'YouTube',
};

export const DurationAsText: Record<DurationOption, string> = {
  15: '15 sec',
  30: '30 sec',
  45: '45 sec',
  60: '60 sec',
  600: '10 min',
};

export const AUTOMATION_AUTOPOST_PLATFORM_MIXPANEL_MAP: Record<
  string,
  AutogramAutopostPlatform
> = {
  youtube: 'YouTube',
};

export const AUTOMATION_LENGTH_MIXPANEL_MAP: Record<
  AutogramVideoType,
  'long' | 'short' | undefined
> = {
  fullEpisode: 'long',
  randomClip: 'short',
};

/**
 * extracts the first subscription item from a subscription object
 * @param subscription current subscriptionData
 * @returns the first subscription item found for the subscription object
 */
export const getSubscriptionItem = (
  subscription?: PodcastSubscription,
): SubscriptionItem | undefined => subscription?.subscriptionItems?.[0];

/**
 * Gets Mixpanel autopost platform value (YouTube for youtube and None for others)
 * @param opts mapped subscription options
 * @returns YouTube or None depending on the autopost plaform selection
 */
export const getMixpanelAutopostPlaform = (opts: PodcastSubscriptionType) => {
  return (
    AUTOMATION_AUTOPOST_PLATFORM_MIXPANEL_MAP[
      opts.autoPostVideoPreference?.platform
    ] || 'None'
  );
};

/**
 * Gets Mixpanel audio length property (short or long)
 * @param opts mapped subscription options
 * @returns short (for random clips), long (for full episodes) or undefined depending on
 * the current selection
 */
export const getMixpanelLength = (opts: PodcastSubscriptionType) => {
  return AUTOMATION_LENGTH_MIXPANEL_MAP[opts.videoType];
};

/**
 * Gets Mixpanel platform property. It implements the value depending on the
 * selected duration for the clip. If the clip is not a random clip or no
 * duration was selection this prop will be set to undefined
 * @param opts mapped subscription options
 * @returns the mapped Mixpanel plaform option (one of: InstagramStories,
 * InstagramLinkedIn, Twitter or Facebook) or undefined if no duration option
 * has been selected.
 */
export const getMixpanelPlatform = (opts: PodcastSubscriptionType) => {
  if (opts.videoType !== VideoTypes.RANDOM_CLIP) {
    return undefined;
  }

  return AUTOMATION_DURATION_OPTIONS_MIXPANEL_MAP[opts.videoLengthSec];
};

/**
 * Maps onEditAutomation Mixpanel event props. For that it uses mapped data
 * and raw subscription data (retrieved from BE) as input
 * @param updateData mapped subscription data (after procesing user selection)
 * @param rawSubscriptionData raw subscription data in the same way it is
 * retrieved from the BE
 * @returns A dict with the mapped props for calling the onEditAutomation
 * Mixpanel event dispatcher.
 */
export const mapMixpanelEditEventData = (
  source: 'audio selection' | 'output' | 'starting point',
  updateData: PartialUpdateAutomationData,
  rawSubscriptionData?: PodcastSubscription,
): EditAutomationProperties => {
  const subscriptionOptions = updateData.subscriptionTypes[0];
  const { height = 1, width = 1 } = rawSubscriptionData?.dimension ?? {};

  return {
    aspectRatio: getAspectRatioName(height, width),
    autopostPlatform: getMixpanelAutopostPlaform(subscriptionOptions),
    startingPoint: getAutogramStartingPoint(subscriptionOptions.videoFrequency),
    length: getMixpanelLength(subscriptionOptions),
    mixpanelPlatform: getMixpanelPlatform(subscriptionOptions),
    source,
    templateId: rawSubscriptionData?.templateId,
    templateName: rawSubscriptionData?.templateName,
    templateType: rawSubscriptionData?.templateType,
  };
};
