import * as React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import bem from 'utils/bem';
import { getCreatePath } from 'utils/routes';

export interface PaidSubscriptionSuccessMessageProps {
  icon: React.ReactElement;
  message: string;
}

const block = bem('paid-sub-success-message');

const ButtonAsAny = Button as any;

const PaidSubscriptionSuccessMessage: React.FC<PaidSubscriptionSuccessMessageProps> = ({
  icon,
  message,
}) => (
  <div className={block()}>
    <video className={block('video')} playsInline autoPlay muted loop>
      <source src="https://static.sparemin.com/videos/pricing/welcome-to-pro-20190430.webm" />
      <source src="https://static.sparemin.com/videos/pricing/welcome-to-pro-20190430-1924.mp4" />
    </video>
    {icon}
    <div className={block('message')}>{message}</div>
    <ButtonAsAny componentClass={Link} theme="submit" to={getCreatePath()}>
      let's start creating
    </ButtonAsAny>
  </div>
);

export default PaidSubscriptionSuccessMessage;
