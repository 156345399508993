import * as React from 'react';
import { isFinite, noop } from 'underscore';

import { InfinityGradient } from 'components/icons';
import { CalloutCta, CalloutCtaProps } from '../PlanDecisionModal';
import { block } from './utils';

type PickedCtaProps = Pick<CalloutCtaProps, 'ctaLabel' | 'onClick' | 'pro'>;

export interface DowngradeCtaCalloutProps extends PickedCtaProps {
  exportLimit: number;
}

const DowngradeCtaCallout: React.FC<DowngradeCtaCalloutProps> = ({
  ctaLabel,
  exportLimit,
  pro,
  onClick = noop,
}) => (
  <CalloutCta {...{ ctaLabel, onClick, pro }}>
    {isFinite(exportLimit) ? (
      <span className={block('number', { pro })}>{exportLimit}</span>
    ) : (
      <InfinityGradient className={block('number', { infinite: true })} />
    )}
    <span className={block('message')}>Unwatermarked videos a month</span>
  </CalloutCta>
);

export default DowngradeCtaCallout;
