import React from 'react';

import Tile from '../Tile';

import { introOutroChildViewBlock as block } from '../utils';

interface IntroOutroChildViewPreviewProps {
  disabled?: boolean;
  id: string;
  loaded: boolean;
  onClick: () => void;
  thumbnailUrl: string;
}

const IntroOutroChildViewPreview: React.FunctionComponent<IntroOutroChildViewPreviewProps> = props => {
  const { disabled, id, loaded, onClick, thumbnailUrl } = props;

  const Image = loaded ? (
    <img
      className={block('thumbnail')}
      src={thumbnailUrl}
      alt="intro_outro_thumbnail"
    />
  ) : null;

  return (
    <Tile
      className={block('thumbnail-tile')}
      disabled={disabled}
      onClick={onClick}
      params={id}
    >
      {Image}
    </Tile>
  );
};

export default IntroOutroChildViewPreview;
