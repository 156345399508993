import * as React from 'react';

import { IconProps } from './types';

const ArrowUpDash: React.FC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="15"
    fill="currentColor"
    {...props}
  >
    <path
      d="m10.845 5.368 6.757 6.498c.468.449.468 1.175 0 1.62l-1.122 1.08c-.468.448-1.223.448-1.685 0L10 9.963 5.21 14.57c-.467.45-1.222.45-1.685 0l-1.127-1.08a1.111 1.111 0 0 1 0-1.62l6.757-6.497a1.223 1.223 0 0 1 1.69-.005zM19 0a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2h18z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default ArrowUpDash;
