import * as React from 'react';

import ConfirmationModal, {
  ConfirmationModalMessage,
  ConfirmationModalProps,
} from 'components/ConfirmationModal';

type Props = Pick<ConfirmationModalProps, 'show' | 'onCancel' | 'onConfirm'>;

const CopyAllConfirmationModal: React.SFC<Props> = props => {
  return (
    <ConfirmationModal
      {...props}
      confirmButtonText="Copy Captions"
      title="Copy Captions to Timeline"
    >
      <ConfirmationModalMessage
        primaryMessage="Copy all captions to timeline?"
        secondaryMessage="This will copy all captions to a new Text track."
      />
    </ConfirmationModal>
  );
};

export default CopyAllConfirmationModal;
export { Props as CopyAllConfirmationModalProps };
