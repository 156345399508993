import { Machine } from 'xstate';
import { ChildView } from 'components/VideoTemplateEditor/types';
import {
  CloseChildViewEvent,
  OpenChildViewEvent,
} from '../../types/navigation-actions';

interface ChildViewMachineContextType {
  childView: ChildView;
}

interface ChildViewMachineSchema {
  states: {
    closed: {};
    open: {};
  };
}

type ChildViewMachineEvent = CloseChildViewEvent | OpenChildViewEvent;

export type ChildViewMachineEventType = ChildViewMachineEvent['type'];

const isDifferentChildView = (
  ctx: ChildViewMachineContextType,
  event: OpenChildViewEvent,
) => {
  return event.payload !== ctx.childView;
};

export const childViewMachine = Machine<
  ChildViewMachineContextType,
  ChildViewMachineSchema,
  ChildViewMachineEvent
>(
  {
    id: 'childView',
    context: {
      childView: undefined,
    },
    initial: 'open',
    states: {
      closed: {
        data: {
          childView: (ctx: ChildViewMachineContextType) => ctx.childView,
        },
        type: 'final',
      },
      open: {
        on: {
          // pass through event from parent machine
          CHILD_VIEW_CLOSE: 'closed',
          CHILD_VIEW_OPEN: {
            target: 'closed',
            cond: 'isDifferentChildView',
          },
        },
      },
    },
  },
  {
    guards: {
      isDifferentChildView,
    },
  },
);
