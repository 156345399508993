import cn from 'classnames';
import * as React from 'react';
import { Flipped } from 'react-flip-toolkit';
import { noop } from 'underscore';

import useOnMount from 'hooks/useOnMount';
import { GUTTER_PX } from '../constants';
import { block } from '../utils';

const { useCallback, useRef } = React;

export interface TrackProps {
  children?: React.ReactNode;
  className?: string;
  id: string;
  onMouseEnter?: (id: string, ref: HTMLElement) => void;
  onMouseLeave?: (id: string, ref: HTMLElement) => void;
  onMount?: (id: string, ref: HTMLElement) => void;
  onUnmount?: (id: string, ref: HTMLElement) => void;
}

const Track: React.FC<TrackProps> = ({
  children,
  className,
  id,
  onMount = noop,
  onMouseEnter = noop,
  onMouseLeave = noop,
  onUnmount = noop,
}) => {
  const elementRef = useRef<HTMLDivElement>();

  useOnMount(
    () => onMount(id, elementRef.current),
    () => onUnmount(id, elementRef.current),
  );

  const handleMouseEnter = useCallback(() => {
    onMouseEnter(id, elementRef.current);
  }, [id, onMouseEnter]);

  const handleMouseLeave = useCallback(() => {
    onMouseLeave(id, elementRef.current);
  }, [id, onMouseLeave]);

  return (
    <Flipped flipId={`${id}-track`}>
      <div
        className={cn(block('track'), className)}
        ref={elementRef}
        /*
         * the goal is to end up with a track that has a gutter.  the entire track
         * should contain the border but the tracks contents should be pushed to the
         * right by GUTTER_PX. the track has to overflow its parent's left border
         * by GUTTER_PX to get this to work out.
         */
        style={{
          marginRight: -GUTTER_PX,
          paddingLeft: GUTTER_PX,
          position: 'relative',
        }}
      >
        <div
          className={block('track-inner')}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {children}
        </div>
      </div>
    </Flipped>
  );
};

export default Track;
