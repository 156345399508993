import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import AnimateImagesUpsellModalContents from './AnimateImagesUpsellModalContents';

export interface AnimateImagesUpsellModalProps {}

const AnimateImagesUpsellModal: React.FC<AnimateImagesUpsellModalProps> = () => {
  return (
    <ConnectedModal name="AnimateImagesUpsell" title="animate images">
      <AnimateImagesUpsellModalContents />
    </ConnectedModal>
  );
};

export default AnimateImagesUpsellModal;
