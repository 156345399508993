import { Spacer } from '@sparemin/blockhead';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthModal } from 'blocks/Authentication/containers/UseAuthModal';
import Button from 'components/Button';
import Divider from 'components/Divider';
import { H2 } from 'components/Heading';
import { ProBadge, Template } from 'components/icons';
import LinkButton from 'components/LinkButton';
import { useProIndicator } from 'containers/ProIndicator';
import { loggedInSelector } from 'redux/modules/auth';
import { onClickProFeature, onClickSaveTemplate } from 'redux/modules/mixpanel';
import { pushModal } from 'redux/modules/modal';
import { showError } from 'redux/modules/notification';
import { AutogramVideoType } from 'types';
import { getAspectRatioDimensions } from 'utils/aspect-ratio';
import { useTemplateState } from './context/VideoTemplateStateContext';
import SaveTemplatePopper from './Popper/SaveTemplatePopper';
import { VideoTemplateState } from './types';
import useSaveTemplate from './useSaveTemplate';
import useTemplatesButton from './useTemplatesButton';
import useTemplateValidationCheck from './useTemplateValidationCheck';
import { optionsTemplateControls as block } from './utils';

export interface OptionsPaneTemplateControlsProps {
  isTemplateDirty: boolean;
  videoType?: AutogramVideoType;
  state: VideoTemplateState;
  resetTemplateDirtyState: () => void;
}

const OptionsPaneTemplateControls: FC<OptionsPaneTemplateControlsProps> = props => {
  const { isTemplateDirty, videoType, state, resetTemplateDirtyState } = props;
  const { withAuthentication } = useAuthModal();

  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const { handleCheckIsValidTemplate } = useTemplateValidationCheck();
  const { onClick } = useTemplatesButton();
  const template = useTemplateState();
  const dispatch = useDispatch();
  const { isFree } = useProIndicator();
  const isAuthenticated = useSelector(loggedInSelector);
  const isProGated = isFree || !isAuthenticated;

  const {
    isSaving,
    saveSuccess,
    handleSaveTemplate,
    handleOverwriteTemplate,
  } = useSaveTemplate({
    aspectRatio: getAspectRatioDimensions(template.aspectRatio),
    creationMethod: template.template?.templateId ? 'template' : 'blankProject',
    templateId: template.template?.templateId,
  });

  const closePopper = useCallback((): void => {
    setIsPopperOpen(false);
  }, []);

  const handleSaveAsNewTemplate = useCallback(async (): Promise<void> => {
    closePopper();

    try {
      await handleSaveTemplate(state);
      resetTemplateDirtyState();
    } catch {
      dispatch(showError('Error saving template. Please try again'));
    }
  }, [
    closePopper,
    dispatch,
    handleSaveTemplate,
    resetTemplateDirtyState,
    state,
  ]);

  const handleOverwriteExistingTemplate = useCallback(async (): Promise<
    void
  > => {
    closePopper();

    try {
      await handleOverwriteTemplate(state);
      resetTemplateDirtyState();
    } catch {
      dispatch(showError('Error overwriting template. Please try again'));
    }
  }, [
    closePopper,
    dispatch,
    handleOverwriteTemplate,
    resetTemplateDirtyState,
    state,
  ]);

  const handleUpgradeClick = useCallback((): void => {
    onClickProFeature({ from: 'SaveTemplateInUCS' });

    dispatch(
      pushModal({
        name: 'SaveAsTemplateIntro',
        params: {
          showAnimatedVideo: false,
          unlockThisFeature: 'Modal = SaveTemplateInUCS',
        },
      }),
    );
  }, [dispatch]);

  const handleSaveTemplateClick = useCallback((): void => {
    if (isFree) {
      handleUpgradeClick();
      return;
    }

    if (!handleCheckIsValidTemplate(template, videoType)) {
      return;
    }

    onClickSaveTemplate({ from: 'UCS' });

    if (template.template?.templateType === 'userGenerated' || saveSuccess) {
      setIsPopperOpen(true);
      return;
    }

    handleSaveAsNewTemplate();
  }, [
    handleCheckIsValidTemplate,
    handleSaveAsNewTemplate,
    handleUpgradeClick,
    isFree,
    saveSuccess,
    template,
    videoType,
  ]);

  return (
    <div className={block()}>
      <Spacer
        as="div"
        orientation="horizontal"
        align="center"
        justify="space-between"
        className={block('template-info-container')}
      >
        <Spacer
          as="div"
          orientation="horizontal"
          align="center"
          justify="flex-start"
          space={2}
        >
          <Template />

          <div>
            <p className={block('created-from')}>
              Created from a{' '}
              {template.template?.templateId ? 'template' : 'blank template'}
            </p>

            <H2>{template.template?.displayName}</H2>
          </div>
        </Spacer>

        <Spacer
          as="div"
          orientation="horizontal"
          align="center"
          justify="flex-start"
          space={2}
          className={block('control-buttons')}
        >
          <LinkButton theme="cta" uppercase size="md" onClick={onClick}>
            Change template
          </LinkButton>

          <Button
            theme="submit-alt"
            className={block('save-button', { gated: isProGated })}
            type="button"
            disabled={(!isFree && !isTemplateDirty) || isSaving}
            onClick={withAuthentication(handleSaveTemplateClick)}
          >
            Save template
            {isProGated && <ProBadge className={block('pro-gate')} />}
          </Button>

          <SaveTemplatePopper
            isOpen={isPopperOpen}
            onSave={handleSaveAsNewTemplate}
            onOverwrite={handleOverwriteExistingTemplate}
            onClose={closePopper}
          />
        </Spacer>
      </Spacer>

      <Divider className={block('divider')} />
    </div>
  );
};

export default OptionsPaneTemplateControls;
