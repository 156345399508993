import * as React from 'react';
import { connect } from 'react-redux';

import { ISocialIconsBottomBarProps } from 'containers/SocialIconsBottomBar';
import { onClickMarketingLink } from 'redux/modules/mixpanel/actions';
import { subtierSelector } from 'redux/modules/pricing';
import { Dispatch, State } from 'redux/types';
import { isEnterpriseSubTier } from 'utils/pricing';

export type StateProps = Pick<ISocialIconsBottomBarProps, 'isEnterpriseUser'>;

export type DispatchProps = Pick<
  ISocialIconsBottomBarProps,
  'onClickMarketingLink'
>;

const mapStateToProps = (state: State): StateProps => ({
  isEnterpriseUser: isEnterpriseSubTier(subtierSelector(state)),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onClickMarketingLink: () => dispatch(onClickMarketingLink()),
});

export default function(
  component: React.ComponentType<ISocialIconsBottomBarProps>,
) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
