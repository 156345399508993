import { PurchaseableTier } from 'redux/middleware/api/plan-service';
import { Subscription } from 'redux/modules/pricing/types';

import bem from 'utils/bem';

export const block = bem('plan');
export const basicPlanBlock = bem('basic-plan');
export const freePlanBlock = bem('free-plan');

export const planIsCancelled = (
  planTier: PurchaseableTier,
  subscription: Subscription,
) => {
  if (planTier === subscription.tier && !subscription.autoRenewal) return true;
  return false;
};
