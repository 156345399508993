import cn from 'classnames';
import * as React from 'react';
import LinkedinOrganizationSelector from './LinkedinOrganizationSelector';
import { LinkedinOrganizationSelectorOption } from './LinkedinOrganizationSelector/types';
import { block } from './utils';

export interface LinkedinSettingsProps {
  className?: string;
  organization: LinkedinOrganizationSelectorOption;
  organizations: LinkedinOrganizationSelectorOption[];
  onOrganizationChange: (
    visibility: LinkedinOrganizationSelectorOption,
  ) => void;
}

const LinkedinSettings: React.FC<LinkedinSettingsProps> = ({
  className,
  onOrganizationChange,
  organizations,
  organization,
}) => (
  <div className={cn(block('settings'), className)}>
    <LinkedinOrganizationSelector
      options={organizations}
      onChange={onOrganizationChange}
      value={organization}
    />
  </div>
);

export default LinkedinSettings;
