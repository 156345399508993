import * as React from 'react';
import Carousel from 'components/Carousel';

interface Props {
  children?: React.ReactNode;
}

function WaveformPresetList(props: Props) {
  const { children } = props;
  const isEmpty = React.Children.count(children) === 0;

  if (isEmpty) {
    return null;
  }

  return (
    <Carousel dots={false} slidesToShow={1} slidesToScroll={1} variableWidth>
      {children}
    </Carousel>
  );
}

export default WaveformPresetList;
