import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import Tooltip from 'blocks/OnboardingAnimations/components/Tooltip';
import useQuery from 'hooks/useQuery';
import { stringifyQuery } from 'utils/routes';
import AspectRatioSteps from './AspectRatioSteps';
import FullEpisodeSteps from './FullEpisodeSteps';
import Joyride, { STATUS } from './Joyride';

interface Props {}

const STEPS = {
  'aspect-ratio': AspectRatioSteps,
  'full-episode': FullEpisodeSteps,
};

const PostDownloadJoyride: React.FC<Props> = () => {
  const location = useLocation();
  const query = useQuery();
  const history = useHistory();

  const { nux: variant } = query ?? {};
  const steps = STEPS[variant as string];

  if (!steps) {
    return null;
  }

  return (
    <Joyride
      continuous
      beaconComponent={null}
      scrollToFirstStep={false}
      disableOverlayClose
      disableScrolling
      disableCloseOnEsc
      disableScrollParentFix
      tooltipComponent={tooltipProps => <Tooltip {...tooltipProps} />}
      steps={steps}
      callback={({ status }) => {
        if (STATUS.FINISHED === status || STATUS.SKIPPED === status) {
          const { nux, ...restQuery } = query;
          if (nux) {
            history.replace({
              ...location,
              search: stringifyQuery(restQuery),
            });
          }
        }
      }}
    />
  );
};

PostDownloadJoyride.displayName = 'PostDownloadJoyride';

export default PostDownloadJoyride;
