import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import _ from 'underscore';

import Modal from 'components/Modal';
import { ApplicationError } from 'utils/ApplicationError';
import AddLogoModalBody, {
  IProps as AddLogoBodyProps,
} from './AddLogoModalBody';
import LogoModal, { IProps as LogoModalProps } from './LogoModal';
import { IWatermarkPosition, IWatermarkSize } from './types';

type ModalProps = Pick<LogoModalProps, 'aspectRatio' | 'onHide' | 'show'>;
type ModalBodyProps = Pick<AddLogoBodyProps, 'onFileAccepted' | 'watermark'>;

export interface IProps extends ModalProps, ModalBodyProps {
  ctaLabel?: string;
  defaultFile?: File;
  isAdding?: boolean;
  onCancel?: () => void;
  onSubmit?: (
    file: File,
    watermarkPosition: IWatermarkPosition,
    watermarkSize: IWatermarkSize,
  ) => void;
  onFileRejected: (error: ApplicationError, file: File) => void;
  onFileUpload?: (file: File) => void;
  /**
   * whether or not to show the back button when the user is on the second
   * "step" of the modal
   */
  showBackButton?: boolean;
  title?: string;
}

interface IDataState {
  showOverlay: boolean;

  /**
   * logoImageSrc is copied from props to state so that we can render the "deleting" state which
   * shows the logo with the "deleting" overlay.  If we used props alone, the logo would disappear
   * almost immediately since the first step in deletion is to remove from redux state, resulting
   * in the logoImageSrc prop being undefined
   */
  logoImageSrc?: string;
  watermarkPosition: IWatermarkPosition;
  watermarkSize: IWatermarkSize;
}

const dataFactory = Record<IDataState>({
  logoImageSrc: undefined,
  showOverlay: false,
  watermarkPosition: undefined,
  watermarkSize: undefined,
});

interface IState {
  data: RecordOf<IDataState>;
}

export default class AddLogoModal extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    ctaLabel: 'add watermark',
    isAdding: false,
    onCancel: _.noop,
    onFileRejected: _.noop,
    onSubmit: _.noop,
    showBackButton: true,
    title: 'Add Watermark',
    watermark: null,
  };

  private logoFile: File;

  constructor(props: IProps) {
    super(props);

    const { defaultFile, isAdding } = props;

    this.state = {
      data: dataFactory({
        showOverlay: isAdding,
        watermarkPosition: {
          left: 0,
          top: 0,
        },
        watermarkSize: {
          height: 0,
          width: 0,
        },
      }),
    };

    if (defaultFile) {
      this.setLogo(defaultFile);
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const { isAdding: nextIsAdding, onHide: nextOnHide } = nextProps;
    const { isAdding, show } = this.props;

    // if the logo just finished getting added to the video...
    if (isAdding && !nextIsAdding) {
      if (!show) {
        // ...and the modal is closed, hide the overlay and clear the logo to reset everything
        this.hideOverlay();
        this.clearLogo();
      } else {
        /*
         * ...and the modal is open, close it.  once the close animation finishes, state will be
         * cleaned up in handleModalExited
         */
        nextOnHide();
      }
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { defaultFile, show } = this.props;
    const { show: prevShow } = prevProps;

    if (show && !prevShow && defaultFile) {
      this.setLogo(defaultFile);
    }
  }

  private handleChangeWatermarkPosition = (
    watermarkPosition: IWatermarkPosition,
  ) => {
    this.setState(({ data }) => ({
      data: data.set('watermarkPosition', watermarkPosition),
    }));
  };

  private handleChangeWatermarkSize = (watermarkSize: IWatermarkSize) => {
    this.setState(({ data }) => ({
      data: data.set('watermarkSize', watermarkSize),
    }));
  };

  private handleModalExited = () => {
    const { isAdding } = this.props;

    if (!isAdding) {
      // if the modal is closed and a logo is not being added, reset state
      this.hideOverlay();
      this.clearLogo();
    } else {
      /*
       * if the modal is closed and a logo is being added, show the overlay.  since the modal
       * automatically closes as soon as the user clicks submit, showing the overlay immediately
       * causes weird ui artificats as the modal closes (you see the overlay start to render).
       * if we show the overlay only when the modal is closed we can avoid the ui artifacts
       */
      this.showOverlay();
    }
  };

  private handleFileAccepted: ModalBodyProps['onFileAccepted'] = file => {
    const { onFileUpload } = this.props;

    this.setLogo(file);

    onFileUpload(file);
  };

  private handleFileRejected = (error: ApplicationError, file: File) => {
    const { onFileRejected } = this.props;

    onFileRejected(error, file);
  };

  private handleSubmit = () => {
    const { onSubmit } = this.props;

    this.logoFile &&
      onSubmit(
        this.logoFile,
        this.state.data.get('watermarkPosition'),
        this.state.data.get('watermarkSize'),
      );
  };

  private handleBackClick = () => this.clearLogo();

  private setLogo(file: File) {
    this.logoFile = file;
    const url = URL.createObjectURL(file);
    this.setState(({ data }) => ({
      data: data.set('logoImageSrc', url),
    }));
  }

  private clearLogo() {
    const { data } = this.state;

    delete this.logoFile;

    if (data.logoImageSrc) {
      URL.revokeObjectURL(data.logoImageSrc);
      this.setState(({ data: dataState }) => ({
        data: dataState.delete('logoImageSrc'),
      }));
    }
  }

  private showOverlay() {
    this.setState(({ data }) => ({
      data: data.set('showOverlay', true),
    }));
  }

  private hideOverlay() {
    this.setState(({ data }) => ({
      data: data.set('showOverlay', false),
    }));
  }

  private renderBody = () => {
    const { aspectRatio, watermark } = this.props;
    const { data } = this.state;

    return (
      <AddLogoModalBody
        aspectRatio={aspectRatio}
        logoImageSrc={data.logoImageSrc}
        onFileAccepted={this.handleFileAccepted}
        onFileRejected={this.handleFileRejected}
        onChangeWatermarkPosition={this.handleChangeWatermarkPosition}
        onChangeWatermarkSize={this.handleChangeWatermarkSize}
        showLoadingOverlay={data.showOverlay}
        watermark={watermark}
      />
    );
  };

  private renderFooter: LogoModalProps['renderFooter'] = cancelConfig => {
    const { ctaLabel, isAdding, onCancel, showBackButton } = this.props;
    const { data } = this.state;

    return (
      <Modal.FooterButtons>
        {data.logoImageSrc && showBackButton && (
          <Modal.FooterButton
            disabled={isAdding}
            onClick={this.handleBackClick}
          >
            Back
          </Modal.FooterButton>
        )}
        <Modal.FooterButton
          className={cancelConfig.className}
          onClick={onCancel}
        >
          {cancelConfig.text}
        </Modal.FooterButton>
        {data.logoImageSrc && (
          <Modal.FooterButton
            disabled={isAdding}
            onClick={this.handleSubmit}
            theme="submit"
          >
            {ctaLabel}
          </Modal.FooterButton>
        )}
      </Modal.FooterButtons>
    );
  };

  public render() {
    const { aspectRatio, onHide, show, title } = this.props;

    return (
      <LogoModal
        name="AddWatermark"
        aspectRatio={aspectRatio}
        onExited={this.handleModalExited}
        onHide={onHide}
        show={show}
        title={title}
        renderBody={this.renderBody}
        renderFooter={this.renderFooter}
      />
    );
  }
}
