import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import {
  personalFonts as personalFontsSelector,
  recentFontsSelector,
} from 'redux/modules/fonts/selectors';
import { IFont } from './FontFamilySelector';

export function useFontSectionSelector(fonts: IFont[]) {
  const personalFonts = useSelector(personalFontsSelector) ?? [];
  const recentFonts = useSelector(recentFontsSelector) ?? [];

  function processFontsForDisplay(fontsToProcess: IFont[] = []) {
    return fontsToProcess.map(({ name, family }) => ({
      label: name,
      style: { fontFamily: family },
      value: name,
    }));
  }

  const getFonts = React.useMemo(() => {
    const newRecentFonts = recentFonts.reduce((acc, recentFont) => {
      const font = _.findWhere(fonts, { name: recentFont });
      if (font) acc.push(font);
      return acc;
    }, []);

    return {
      userFonts: processFontsForDisplay(personalFonts),
      allFonts: processFontsForDisplay(fonts),
      newRecentFonts: processFontsForDisplay(newRecentFonts),
    };
  }, [fonts, personalFonts, recentFonts]);

  return getFonts;
}
