import * as headlinerUserService from 'redux/middleware/api/headliner-user-service';
import { ThunkAction } from 'redux/types';
import { loggedInSelector } from '../auth';
import { deleteEntities } from '../entities';
import { showError } from '../notification';
import { Type } from './action-types';

export const getUserPersonalFonts = (): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  if (!loggedInSelector(getState())) {
    return;
  }

  dispatch({
    type: Type.PERSONAL_FONT_GET_REQUEST,
  });

  try {
    await dispatch(headlinerUserService.fetchMyFonts());

    dispatch({
      type: Type.PERSONAL_FONT_GET_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: Type.PERSONAL_FONT_GET_FAILURE,
    });
    dispatch(showError({ message: 'Error loading fonts' }));
  }
};

export const uploadUserPersonalFonts = (
  arg: headlinerUserService.PostPersonalFontsArgs,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    type: Type.FONT_UPLOAD_REQUEST,
  });

  try {
    await dispatch(
      headlinerUserService.postPersonalFonts(arg.fontFiles, arg.googleFamily),
    );
    dispatch({
      type: Type.FONT_UPLOAD_SUCCESS,
    });
    dispatch(deleteEntities(['personalFonts']));
    dispatch(getUserPersonalFonts());
  } catch (err) {
    dispatch({
      type: Type.FONT_UPLOAD_FAILURE,
    });
    dispatch(showError({ message: 'Error uploading fonts.' }));
  }
};

export const deleteUserFont = (
  arg: headlinerUserService.DeleteFontArgs,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    type: Type.FONT_DELETE_REQUEST,
  });

  try {
    await dispatch(headlinerUserService.deleteFont(arg.fontId));
    dispatch({
      type: Type.FONT_DELETE_SUCCESS,
    });
    dispatch(deleteEntities(['personalFonts']));
    dispatch(getUserPersonalFonts());
  } catch (err) {
    dispatch({
      type: Type.FONT_DELETE_FAILURE,
    });

    dispatch(showError({ message: 'Error deleting font.' }));
  }
};

export const renameUserFont = (
  arg: headlinerUserService.UpdateFontNameArgs,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    type: Type.FONT_NAME_UPDATE_REQUEST,
  });
  try {
    await dispatch(
      headlinerUserService.updateFontName(arg.displayName, arg.fontId),
    );
    dispatch({
      type: Type.FONT_NAME_UPDATE_SUCCESS,
    });
    dispatch(deleteEntities(['personalFonts']));
    dispatch(getUserPersonalFonts());
  } catch (err) {
    dispatch({
      type: Type.FONT_NAME_UPDATE_FAILURE,
    });
    dispatch(showError({ message: 'Error renaming fonts.' }));
  }
};

export const getGoogleFonts = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  dispatch({
    type: Type.GOOGLE_FONT_GET_REQUEST,
  });

  try {
    await dispatch(headlinerUserService.fetchGoogleFonts());

    dispatch({
      type: Type.GOOGLE_FONT_GET_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: Type.GOOGLE_FONT_GET_FAILURE,
    });
    dispatch(showError({ message: 'Error getting Google fonts. ' }));
  }
};

export const addRecentFont = (value: string): ThunkAction<void> => dispatch => {
  dispatch({ type: Type.RECENT_FONT_ADD, payload: value });
};
