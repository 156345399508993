import { createSelector } from 'reselect';

import { getValue } from 'utils/collections';
import { announcementsSelector } from '../entities';

const validAnnouncementsSelector = createSelector(
  announcementsSelector,
  announcements => {
    if (!announcements) return undefined;

    return announcements.reduce((acc, announcement) => {
      const validStartMillis = getValue(announcement, [
        'validBetween',
        'startAtMillis',
      ]);
      const validEndMillis = getValue(announcement, [
        'validBetween',
        'endAtMillis',
      ]);
      const nowMillis = new Date().getTime();

      if (
        !validStartMillis ||
        !validEndMillis ||
        (nowMillis < validEndMillis && nowMillis > validStartMillis)
      ) {
        acc.push(announcement.toJS());
      }
      return acc;
    }, []);
  },
);

export const announcementToShowSelector = createSelector(
  validAnnouncementsSelector,
  validAnnouncements => validAnnouncements && validAnnouncements[0],
);

export default { validAnnouncementsSelector };
