import cn from 'classnames';
import * as React from 'react';
import { Loader } from 'react-loaders';

import Spinner from 'components/Spinner';
import { block } from './utils';

const { useMemo } = React;

export interface LoadingMessageProps {
  className?: string;
  message?: string;
  messageClassName?: string;
  noFadeIn?: boolean;
  fill?: boolean;
  spinner?: JSX.Element;
  variation?: 'dots' | 'bars';
}

const LoadingMessage: React.SFC<LoadingMessageProps> = ({
  className,
  message,
  messageClassName,
  noFadeIn,
  variation,
  spinner: PropsSpinnerElement,
  fill,
}) => {
  const SpinnerElement = useMemo(() => {
    if (PropsSpinnerElement) {
      return PropsSpinnerElement;
    }

    if (variation === 'bars') {
      return (
        <Loader
          className={block('loader')}
          innerClassName={block('loader-inner')}
          type="line-scale-pulse-out"
        />
      );
    }

    return <Spinner noFadeIn />;
  }, [PropsSpinnerElement, variation]);

  return (
    <div
      className={cn(
        block({
          [variation]: !!variation,
          'no-fade': noFadeIn,
          fill,
        }),
        className,
      )}
    >
      {SpinnerElement}
      <span className={cn(block('message'), messageClassName)}>{message}</span>
    </div>
  );
};

export default LoadingMessage;
