import {
  INormalizedVideoUpload,
  IVideoUpload,
} from 'redux/middleware/api/media-upload-service';
import {
  directUpload,
  searchVideo,
  uploadVideo,
} from 'redux/middleware/api/media-upload-service/actions';
import { ThunkAction } from 'redux/types';
import { md5File } from 'utils/file';
import { preSignedUpload } from 'utils/request';
import { VideoUploadArgs } from './types';

export const searchForVideo = (
  args: Partial<VideoUploadArgs>,
): ThunkAction<Promise<IVideoUpload>> => async dispatch => {
  const { src, trimStartMillis, trimEndMillis, language } = args;

  if (typeof src === 'string') {
    return undefined;
  }

  const md5 = await md5File(src);

  const { response } = await dispatch(
    searchVideo(md5, trimStartMillis, trimEndMillis, true, language),
  );

  return response[0];
};

export const handleUploadVideo = (
  video: File,
  args: Partial<VideoUploadArgs>,
): ThunkAction<Promise<INormalizedVideoUpload>> => async dispatch => {
  const {
    trimStartMillis,
    trimEndMillis,
    language,
    initiateProcessing = false,
  } = args;

  return dispatch(directUpload(video, 'video')).then(async ({ response }) => {
    const { bucket, key, upload } = response;

    return preSignedUpload(upload.url, video, upload.fields).then(() =>
      dispatch(
        uploadVideo(undefined, {
          trimStart: trimStartMillis,
          trimEnd: trimEndMillis,
          transcribe: true,
          language,
          directUploadBucket: bucket,
          directUploadKey: key,
          initiateProcessing,
        }),
      ).then(res => {
        return res.response;
      }),
    );
  });
};
