import * as React from 'react';
import Button, { ButtonProps } from 'components/Button';
import { Callout, CalloutPro } from 'components/icons';

import { block } from './utils';

type PickedButtonProps = Pick<ButtonProps, 'onClick'>;

export interface CalloutCtaProps extends PickedButtonProps {
  children?: React.ReactNode;
  ctaLabel: string;
  pro?: boolean;
}

const CalloutCta: React.FC<CalloutCtaProps> = ({
  children,
  ctaLabel,
  onClick,
  pro,
}) => (
  <div className={block('callout-cta', { pro })}>
    <div className={block('callout')}>
      {pro ? (
        <CalloutPro className={block('callout-icon')} />
      ) : (
        <Callout className={block('callout-icon')} />
      )}
      <div className={block('callout-contents')} role="tooltip">
        {children}
      </div>
    </div>
    <Button
      className={block('callout-button')}
      theme={pro ? 'rainbow' : 'cancel'}
      fluid
      onClick={onClick}
    >
      {ctaLabel}
    </Button>
  </div>
);

export default CalloutCta;
