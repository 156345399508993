import * as React from 'react';

import { IconProps } from './types';

const VolumeUp: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 16" {...props}>
    <path
      fill="currentcolor"
      d="M7.093 1.835L4.158 4.8H.792C.354 4.8 0 5.158 0 5.6v4.8c0 .441.354.8.792.8h3.366l2.935 2.964c.496.501 1.351.15 1.351-.565V2.4c0-.716-.856-1.066-1.351-.566zM14.789.132c-.368-.244-.863-.14-1.105.232s-.14.873.23 1.117c2.185 1.45 3.49 3.887 3.49 6.52 0 2.632-1.305 5.069-3.49 6.518-.37.244-.472.745-.23 1.117.232.357.723.485 1.105.231C17.426 14.12 19 11.177 19 8c0-3.178-1.574-6.119-4.21-7.868zM15.833 8c0-2.118-1.057-4.065-2.829-5.208-.369-.238-.859-.127-1.092.249-.234.376-.125.873.244 1.112C13.467 4.999 14.25 6.437 14.25 8c0 1.563-.783 3-2.094 3.847-.37.238-.478.736-.244 1.112.214.345.696.505 1.092.249 1.772-1.144 2.83-3.09 2.83-5.208zm-4.676-2.562c-.382-.211-.864-.072-1.076.315-.21.387-.071.873.312 1.086.426.237.69.682.69 1.16 0 .48-.264.925-.69 1.161-.383.214-.522.7-.311 1.087.212.389.694.527 1.075.315.931-.518 1.51-1.5 1.51-2.563 0-1.062-.579-2.043-1.51-2.561z"
    />
  </svg>
);

export default VolumeUp;
