import React from 'react';

import LoadingMessage from 'components/LoadingMessage';
import LoadingOverlay from 'components/LoadingOverlay';

import { block } from '../../utils';

interface ClaimPodcastLoadingOverlayProps {
  loading: boolean;
}

const ClaimPodcastLoadingOverlay: React.FunctionComponent<ClaimPodcastLoadingOverlayProps> = props => {
  const { loading } = props;

  if (!loading) {
    return null;
  }

  return (
    <LoadingOverlay
      customContent={
        <div className={block('loading-body')}>
          <LoadingMessage message="Checking RSS feed" />
        </div>
      }
    />
  );
};

export default ClaimPodcastLoadingOverlay;
