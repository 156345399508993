import React from 'react';
import DialogContent from 'components/DialogContent';
import { CircularGradientList } from 'components/icons/circular-gradient';
import LoadingOverlay from 'components/LoadingOverlay';
import ShareFormTextarea from 'components/ShareForm/ShareFormTextarea';
import YoutubeVisibilitySelector from 'containers/YoutubePostModal/YoutubeVisibilitySelector';
import useOnMount from 'hooks/useOnMount';
import { YoutubeVideoVisibility } from 'types';
import { block } from '../../utils';

const PLAYLIST_NAME_MAX_CHARS = 150;

export interface CreatePlaylistModalBodyProps {
  isSubmitting?: boolean;
  /**
   * Called when the component mounts.
   *
   * A bit of an antipattern but useful for resetting state.  See component comment
   */
  onMount?: () => void;
  onPlaylistNameChange?: (value: string) => void;
  /**
   * Called when the component unmounts.
   *
   * A bit of an antipattern but useful for resetting state.  See component comment
   */
  onUnmount?: () => void;
  onVisibilityChange?: (value: YoutubeVideoVisibility) => void;
  playlistName: string;
  visibility: YoutubeVideoVisibility;
}

/**
 * A wrapper around DialogContent that adds a mount/unmount callback. This is a
 * workaround since state cannot be moved "inside" of the modal.
 *
 * The playlist modal state needs to be reset when the component mounts and/or
 * unmounts.  For a modal, this isn't usually an issue becuase state management
 * typically lives within the modal contents component which mounts/unmounts as the
 * modal is opened and closed.
 *
 * The YouTube modal is a SteppedModal, which makes this pattern a little difficult
 * because components need to be passed into the modal as `step` objects.  The component
 * and footer buttons are passed as separate properties of the step object, so if
 * we were to manage all state in this component, for example, the footer buttons
 * would have no way of accessing state since they are a separate component.  Since
 * this is the case, we'd have to move state out of this component so that it could
 * be passed to this component and the footer buttons - and that's exactly the problem.
 * Once the state lives outside of hte modal contents, we have to reset it
 * manually.
 */
const CreatePlaylistModalBody: React.FC<CreatePlaylistModalBodyProps> = ({
  isSubmitting,
  onMount,
  onPlaylistNameChange,
  onUnmount,
  onVisibilityChange,
  playlistName,
  visibility,
}) => {
  useOnMount(onMount, onUnmount);

  return (
    <DialogContent
      className={block('create-playlist-contents')}
      details={
        <>
          {isSubmitting && <LoadingOverlay title="Creating playlist" />}
          <form className={block('create-playlist-contents-form')}>
            <ShareFormTextarea
              label="Playlist title"
              onChange={onPlaylistNameChange}
              placeholder="Add title"
              type="input"
              maxChars={PLAYLIST_NAME_MAX_CHARS}
              value={playlistName}
            />
            <YoutubeVisibilitySelector
              onChange={onVisibilityChange}
              value={visibility}
            />
          </form>
        </>
      }
      detailsClassName={block('create-playlist-contents-details')}
      icon={<CircularGradientList />}
      title="Create a new playlist"
      titleClassName={block('create-playlist-contents-title')}
    />
  );
};

export default CreatePlaylistModalBody;
