import * as React from 'react';
import { isString, noop } from 'underscore';

import useObjectUrl from 'hooks/useObjectUrl';
import { Omit, PropsOf } from 'types';

const { useCallback } = React;

type DefaultImgProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

interface Props extends Omit<DefaultImgProps, 'src'> {
  src?: Blob | string;
  disableRightClick?: boolean;
}

const Image = React.forwardRef<HTMLImageElement, Props>(
  ({ disableRightClick, onContextMenu, src, ...props }, ref) => {
    const objectUrl = useObjectUrl(src);

    const handleContextMenu = useCallback(
      (e: React.MouseEvent<HTMLImageElement>) => {
        if (disableRightClick) {
          e.preventDefault();
        }
        onContextMenu(e);
      },
      [disableRightClick, onContextMenu],
    );

    const source = isString(src) ? src : objectUrl;

    return (
      source && (
        <img
          onContextMenu={handleContextMenu}
          ref={ref}
          src={source}
          {...props}
        />
      )
    );
  },
);

Image.defaultProps = {
  disableRightClick: true,
  onContextMenu: noop,
};

Image.displayName = 'Image';
export type ImageProps = PropsOf<typeof Image>;
export default Image;
