import cn from 'classnames';
import React, { useRef, useState } from 'react';

import { CircularGradientComments } from 'components/icons/circular-gradient';
import { referrerBlock as block } from '../utils';
import CardWizardStep, {
  CardWizardControlButton,
  CardWizardControls,
} from './CardWizard/CardWizardStep';

export interface ReferrerStepProps {
  className?: string;
  onSubmit?: (referrer: string) => void;
}

const ReferrerStep: React.FC<ReferrerStepProps> = props => {
  const { className, onSubmit } = props;
  const inputRef = useRef<HTMLTextAreaElement>();
  const [referrer, setReferrer] = useState('');
  const handleSubmit = () => {
    onSubmit(referrer);
  };

  // must use textarea instead of react-textarea-autosize, because later
  // breaks the step animation: https://github.com/Andarist/react-textarea-autosize/issues/48
  //
  // autoFocus also breaks the step animation, CardWizard will focus the first input
  // when animation completes
  return (
    <CardWizardStep className={cn(block(), className)}>
      <div className={block('icon')}>
        <CircularGradientComments />
      </div>
      <div className={block('message')}>
        <CardWizardStep.H1>How did you hear about us?</CardWizardStep.H1>
      </div>
      <textarea
        ref={inputRef}
        className={block('input')}
        rows={3}
        placeholder="i.e. Google, friends, or your favorite podcast"
        value={referrer}
        onChange={event => {
          setReferrer(event.target.value);
        }}
      />
      <CardWizardControls className={block('controls')}>
        <CardWizardControlButton onClick={handleSubmit}>
          Next
        </CardWizardControlButton>
      </CardWizardControls>
    </CardWizardStep>
  );
};

export default ReferrerStep;
