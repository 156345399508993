import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import { TrackType } from 'types';
import bem from 'utils/bem';
import AddTrackModalContents from './AddTrackModalContents';

export interface AddTrackModalProps {
  onSubmit?: (trackType: TrackType) => void;
  show?: boolean;
}

const AddTrackModal: React.FC<AddTrackModalProps> = ({ onSubmit }) => {
  const block = bem('add-track-modal');
  return (
    <ConnectedModal
      backdrop="static"
      className={block()}
      name="AddTrackModal"
      title="Add Track"
    >
      <AddTrackModalContents onSubmit={onSubmit} />
    </ConnectedModal>
  );
};

export default AddTrackModal;
