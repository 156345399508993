import { Button, Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import Tooltip from 'components/Tooltip';
import InputLabel from './InputLabel';
import { block } from './utils';

interface InputFieldAsButtonProps {
  label: React.ReactNode;
  content: React.ReactNode;
  tooltipContent: React.ReactNode;
  icon?: React.ElementType;
  className?: string;
  onClick: () => void;
}

const InputFieldAsButton: React.FC<InputFieldAsButtonProps> = props => {
  const {
    label,
    content,
    tooltipContent,
    icon: Icon,
    className,
    onClick,
  } = props;

  return (
    <Spacer
      className={block('input-field-as-button')}
      orientation="vertical"
      align="flex-start"
      justify="flex-start"
    >
      <InputLabel>{label}</InputLabel>

      <Tooltip content={tooltipContent} placement="top" rootClose>
        <Spacer
          as={Button}
          className={cn(block('input-field-as-button-wrapper'), className)}
          orientation="horizontal"
          align="center"
          justify="space-between"
          space={4}
          onClick={onClick}
        >
          <pre>{content}</pre>

          {Icon && (
            <Icon
              height={16}
              width={16}
              className={block('input-field-as-button-icon')}
            />
          )}
        </Spacer>
      </Tooltip>
    </Spacer>
  );
};

export default InputFieldAsButton;
