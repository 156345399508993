import { connect } from 'react-redux';

import SwitchAccountMessage, {
  SwitchAccountMessageProps,
} from 'components/SwitchAccountMessage';
import { facebookUserNameSelector } from 'redux/modules/social';
import { State } from 'redux/types';
import { PropsOf } from 'types';

type StateProps = Pick<SwitchAccountMessageProps, 'username'>;

const mapStateToProps = (state: State): StateProps => ({
  username: facebookUserNameSelector(state),
});

const component = connect(mapStateToProps)(SwitchAccountMessage);

export type SwitchFacebookAccountMessageProps = PropsOf<typeof component>;

export default component;
