import React, { useCallback } from 'react';
import { Flipper } from 'react-flip-toolkit';
import Slider from 'react-slick';

import { selectorBlock as block } from '../../utils';
import { useClipSelectAnimation } from '../ClipSelectAnimationContext';
import { useClipSelect } from '../ClipSelectContext';
import AudioClipSuggestion from './AudioClipSuggestion';
import ClipSelectorControls from './ClipSelectorControls';

export interface ClipSelectorProps {
  className?: string;
}

const ClipSelector: React.FC<ClipSelectorProps> = ({ className }) => {
  const {
    activeSuggestionId,
    setActiveSuggestionId,
    visibleSuggestionIds,
  } = useClipSelect();

  const {
    clipSelectorSliderRef,
    clipSelectorFlipper,
  } = useClipSelectAnimation();

  const handleBeforeChange = useCallback(
    (_, index) => {
      setActiveSuggestionId(visibleSuggestionIds[index]);
    },
    [setActiveSuggestionId, visibleSuggestionIds],
  );

  return (
    <Flipper className={className} {...clipSelectorFlipper}>
      {visibleSuggestionIds?.length > 1 && (
        <>
          <Slider
            arrows={false}
            beforeChange={handleBeforeChange}
            className={block()}
            centerMode
            focusOnSelect
            speed={600}
            variableWidth
            infinite={false}
            ref={clipSelectorSliderRef}
          >
            {visibleSuggestionIds.map(id => (
              <AudioClipSuggestion
                active={id === activeSuggestionId}
                id={id}
                key={id}
              />
            ))}
          </Slider>
          <ClipSelectorControls />
        </>
      )}
      {visibleSuggestionIds?.length === 1 && (
        <>
          <div className={block({ single: true })}>
            <AudioClipSuggestion active id={visibleSuggestionIds[0]} />
          </div>
          <ClipSelectorControls />
        </>
      )}
    </Flipper>
  );
};

export default ClipSelector;
