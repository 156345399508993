import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  debouncedSaveConfiguration,
  updateVideo,
} from 'redux/modules/embed/actions';
import {
  embedSlideIdsSelector,
  videoIdsSelector,
  videosByIdSelector,
} from 'redux/modules/embed/selectors';
import { Dispatch, State } from 'redux/types';
import { VideoAssetProps } from '../../components/VideoAsset';
import { millisToPosition, positionToMillis } from '../../utils';

interface OwnProps<T extends VideoAssetProps> extends Pick<T, 'id'> {
  onClick;
  pxPerSec: number;
}

type StateProps<T extends VideoAssetProps> = Pick<
  T,
  | 'fullDurationWidth'
  | 'minWidth'
  | 'order'
  | 'playFromOffset'
  | 'position'
  | 'posterSrc'
  | 'scaling'
  | 'width'
>;
type DispatchProps<T extends VideoAssetProps> = Pick<
  T,
  'onDrag' | 'onDragStop' | 'onResize' | 'onResizeStop'
>;

const idSelector = <T extends VideoAssetProps>(_, props: OwnProps<T>) =>
  props.id;

const pxPerSecSelector = <T extends VideoAssetProps>(_, props: OwnProps<T>) =>
  props.pxPerSec;

const nSlidesSelector = createSelector(embedSlideIdsSelector, slideIds =>
  slideIds ? slideIds.length : 0,
);

const makeMapStateToProps = <T extends VideoAssetProps>() => {
  const videoOrderSelector = createSelector(
    [nSlidesSelector, videoIdsSelector, idSelector],
    (nSlides, videoIds, id) =>
      !videoIds || !id ? undefined : nSlides + videoIds.indexOf(id) + 1,
  );
  const videoSelector = createSelector(
    [videosByIdSelector, idSelector],
    (videosById, id) => (!videosById || !id ? undefined : videosById.get(id)),
  );

  const fullDurationWidthSelector = createSelector(
    [videoSelector, pxPerSecSelector],
    (video, pxPerSec) =>
      !video ? 0 : millisToPosition(video.sourceDurationMillis, pxPerSec),
  );

  const playFromOffsetSelector = createSelector(
    [videoSelector, pxPerSecSelector],
    (video, pxPerSec) =>
      !video ? 0 : millisToPosition(video.playFromMillis || 0, pxPerSec),
  );

  const videoTimesSelector = createSelector(
    videoSelector,
    video =>
      video && {
        endMillis: video.endMillis,
        startMillis: video.startMillis,
      },
  );

  const positionSelector = createSelector(
    [videoTimesSelector, pxPerSecSelector],
    (times, pxPerSec) =>
      !times ? 0 : millisToPosition(times.startMillis, pxPerSec),
  );

  const posterSrcSelector = createSelector(
    videoSelector,
    video => video && video.previewThumbnailUrl,
  );

  const scalingSelector = createSelector(
    videoSelector,
    video => video && video.scaling,
  );

  const widthSelector = createSelector(
    [videoTimesSelector, pxPerSecSelector],
    (times, pxPerSec) =>
      !times
        ? 0
        : millisToPosition(times.endMillis - times.startMillis, pxPerSec),
  );

  return (state: State, props: OwnProps<T>): StateProps<T> => ({
    fullDurationWidth: fullDurationWidthSelector(state, props),
    minWidth: millisToPosition(
      spareminConfig.minSlideDurationMillis,
      props.pxPerSec,
    ),
    order: videoOrderSelector(state, props),
    playFromOffset: playFromOffsetSelector(state, props),
    position: positionSelector(state, props),
    posterSrc: posterSrcSelector(state, props),
    scaling: scalingSelector(state, props),
    width: widthSelector(state, props),
  });
};

const makeMapDispatchToProps = () => <T extends VideoAssetProps>() => (
  dispatch: Dispatch,
  props: OwnProps<T>,
): DispatchProps<T> => ({
  onDrag: data =>
    dispatch(
      updateVideo(props.id, {
        endMillis: positionToMillis(data.x + data.width, props.pxPerSec),
        id: props.id,
        startMillis: positionToMillis(data.x, props.pxPerSec),
      }),
    ),
  onDragStop: (_, moved) => {
    if (moved) {
      dispatch(debouncedSaveConfiguration());
    }
  },
  onResize: (playFromOffset, width, _, pos) =>
    dispatch(
      updateVideo(props.id, {
        endMillis: positionToMillis(pos + width, props.pxPerSec),
        id: props.id,
        playFromMillis: positionToMillis(playFromOffset, props.pxPerSec),
        startMillis: positionToMillis(pos, props.pxPerSec),
      }),
    ),
  onResizeStop: () => dispatch(debouncedSaveConfiguration()),
});

export default function(component: React.ComponentType<VideoAssetProps>) {
  return connect(makeMapStateToProps, makeMapDispatchToProps())(component);
}
