import cn from 'classnames';
import * as React from 'react';
import { isNumber, noop } from 'underscore';

import Timeline, { TimelineProps } from 'blocks/Timeline';
import TimelineUtilityBar from 'components/TimelineUtilityBar';
import PlayBar, { PlayBarProps } from 'containers/PlayBar';
import useVideoEditorPlaybackTimeContext from 'containers/VideoEditor/useVideoEditorPlaybackTimeContext';
import usePrevious from 'hooks/usePrevious';
import bem from 'utils/bem';

const block = bem('timeline-pane');

const DEFAULT_ZOOM_PCT = 50;

type InheritedProps = Pick<
  PlayBarProps,
  'onPause' | 'onPlay' | 'onTimeupdate' | 'playing'
>;

interface Interval {
  startSec: number;
  endSec: number;
}

export interface IProps extends InheritedProps {
  className?: string;
  onReady?: () => void;
  onSeek?: (sec: number) => void;
  onTimelineModalHide?: TimelineProps['onModalHide'];
  onTimelineModalShow?: TimelineProps['onModalShow'];
}

const { useCallback, useEffect, useState } = React;

const calculatePixelsPerSec = (pct: number) => (pct / 100) * 200;

const TimelinePane: React.FC<IProps> = ({
  className,
  onPause,
  onPlay,
  onSeek,
  onTimeupdate,
  onTimelineModalHide,
  onTimelineModalShow,
  playing,
}) => {
  const [pxPerSec, setPxPerSec] = useState<number>(
    calculatePixelsPerSec(DEFAULT_ZOOM_PCT),
  );
  const [zoomPct, setZoomPct] = useState<number>(DEFAULT_ZOOM_PCT);
  const [viewportInterval, setViewportInterval] = useState<Interval>();

  const videoEditorPlaybackTimeContext = useVideoEditorPlaybackTimeContext();
  const { positionSec } = videoEditorPlaybackTimeContext;

  const prevPositionSec = usePrevious(positionSec);

  useEffect(() => {
    const isPlayheadOffscreen =
      positionSec < viewportInterval?.startSec ||
      positionSec > viewportInterval?.endSec;

    /*
     * if playback is in progress, keep the playhead in the middle of the
     * timeline while scrolling the viewport.
     * if new playhead position is > mid viewport by some delta, scroll viewport
     * to the left by that same delta
     */
    if (playing && !isPlayheadOffscreen) {
      const vpMidSec =
        (viewportInterval?.startSec + viewportInterval?.endSec) / 2;

      if (positionSec > vpMidSec) {
        const diffSec = positionSec - vpMidSec;

        setViewportInterval({
          startSec: viewportInterval?.startSec + diffSec,
          endSec: viewportInterval?.endSec + diffSec,
        });
      }
    }

    const isPositionChange = prevPositionSec !== positionSec;

    // center the playhead on the timeline if it's moved and offscreen
    if (isPlayheadOffscreen && isPositionChange) {
      const vpWidth = viewportInterval?.endSec - viewportInterval?.startSec;
      const startSec = Math.max(0, positionSec - vpWidth / 2);
      const endSec = startSec + vpWidth;

      setViewportInterval({ endSec, startSec });
    }
  }, [playing, positionSec, prevPositionSec, viewportInterval]);

  const handleTimelineZoom2 = useCallback(
    (pct: number | ((zoom: number) => number)) => {
      const currentZoom = zoomPct;
      const newZoom = isNumber(pct) ? pct : pct(currentZoom);
      const currentPxPerSec = pxPerSec;
      const newPxPerSec = calculatePixelsPerSec(newZoom);
      const pxPerSecRatio = currentPxPerSec / newPxPerSec;

      const viewportWidthSec =
        (viewportInterval?.endSec - viewportInterval?.startSec) * pxPerSecRatio;
      const viewportMidSec =
        (viewportInterval?.endSec + viewportInterval?.startSec) / 2;
      const secToLeft = viewportMidSec - viewportInterval?.startSec;

      const startSec = Math.max(0, viewportMidSec - pxPerSecRatio * secToLeft);
      const endSec = startSec + viewportWidthSec;

      setZoomPct(newZoom);
      setPxPerSec(calculatePixelsPerSec(newZoom));
      setViewportInterval({ startSec, endSec });
    },
    [pxPerSec, viewportInterval, zoomPct],
  );

  const handleTimelineSeek = useCallback(
    (millis: number) => onSeek(millis / 1000),
    [onSeek],
  );

  const handleViewportChange = useCallback(({ startMillis, endMillis }) => {
    setViewportInterval({
      endSec: endMillis / 1000,
      startSec: startMillis / 1000,
    });
  }, []);

  const handlePlaybarSeek: PlayBarProps['onSeek'] = useCallback(
    ({ seconds }) => onSeek(seconds),
    [onSeek],
  );

  const handleZoomInClick = useCallback(
    () => handleTimelineZoom2(pct => Math.min(pct + 5, 100)),
    [handleTimelineZoom2],
  );

  const handleZoomOutClick = useCallback(
    () => handleTimelineZoom2(pct => Math.max(pct - 5, 1)),
    [handleTimelineZoom2],
  );

  const handleMinimapWindowDrag = useCallback(
    (startSec: number, endSec: number) => {
      setViewportInterval({ startSec, endSec });
    },
    [],
  );

  return (
    <div
      className={cn(block(), className)}
      style={{ width: '100%', height: '100%' }}
    >
      <PlayBar
        className={block('playbar')}
        onPause={onPause}
        onPlay={onPlay}
        onTimeupdate={onTimeupdate}
        onSeek={handlePlaybarSeek}
        playing={playing}
      />
      <TimelineUtilityBar
        className={block('utilbar')}
        onMinimapWindowDrag={handleMinimapWindowDrag}
        onZoomInClick={handleZoomInClick}
        onZoomOutClick={handleZoomOutClick}
        onZoomSliderChange={handleTimelineZoom2}
        onMinimapSeek={onSeek}
        viewport={viewportInterval}
        zoomPct={zoomPct}
      />
      <Timeline
        onModalHide={onTimelineModalHide}
        onModalShow={onTimelineModalShow}
        onSeek={handleTimelineSeek}
        onViewportChange={handleViewportChange}
        playing={playing}
        pxPerSec={pxPerSec}
        viewport={
          viewportInterval && {
            endMillis: viewportInterval.endSec * 1000,
            startMillis: viewportInterval.startSec * 1000,
          }
        }
      />
    </div>
  );
};

TimelinePane.defaultProps = {
  onReady: noop,
  onSeek: noop,
  playing: false,
};

export default TimelinePane;
