import React from 'react';
import useTranscriptionBalance from 'hooks/useTranscriptionBalance';

import { TranscriptionTimeBankUpgradeSource } from 'types/common';
import { createChainedFunction } from 'utils/functions';
import TranscriptionTimeRemaining from './TranscriptionTimeRemaining';

export interface FreeTranscriptionBalanceProps {
  className?: string;
  source: TranscriptionTimeBankUpgradeSource;
  onClick?: () => void;
  durationInMilliseconds?: number;
}

const FreeTranscriptionBalance: React.FC<FreeTranscriptionBalanceProps> = props => {
  const { className, onClick, source, durationInMilliseconds = 0 } = props;
  const { balanceMillis, onClick: handleClick } = useTranscriptionBalance({
    source,
    durationMillis: durationInMilliseconds,
  });

  return (
    <TranscriptionTimeRemaining
      remainingMillis={balanceMillis - durationInMilliseconds}
      className={className}
      onClick={createChainedFunction(handleClick, onClick)}
    />
  );
};

export default FreeTranscriptionBalance;
