import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import CaptionsUpsellModalContents from './CaptionsUpsellModalContents';
import { block } from './utils';

export interface CaptionsUpsellModalProps {}

const CaptionsUpsellModal: React.FC<CaptionsUpsellModalProps> = () => (
  <ConnectedModal name="CaptionsUpsell" title="captions" className={block()}>
    <CaptionsUpsellModalContents />
  </ConnectedModal>
);

export default CaptionsUpsellModal;
