import * as React from 'react';
import { Col } from 'react-bootstrap';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  sm?: number;
  xs?: number;
}

const VideoExportFormField: React.SFC<IProps> = ({ children, ...props }) => (
  <Col {...props}>{children}</Col>
);

export default VideoExportFormField;
export { IProps as VideoExportFormFieldProps };
