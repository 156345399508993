import React from 'react';
import ChildViewTipTip from '../assets/ChildViewTip';

import TileGrid from '../TileGrid';
import { imageViewBlock as block } from '../utils';
import { CropWatermarkTile, ReplaceWatermarkTile } from '../watermark-cta';
import DeleteWatermarkTile from '../watermark-cta/DeleteWatermarkTile';

export interface StaticImageChildContentsProps {}

const WatermarkChildContents: React.FC<StaticImageChildContentsProps> = () => (
  <>
    <ChildViewTipTip />
    <TileGrid className={block('grid')}>
      <DeleteWatermarkTile />
      <CropWatermarkTile />
      <ReplaceWatermarkTile />
    </TileGrid>
  </>
);

export default WatermarkChildContents;
