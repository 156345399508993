import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import CircularGradient, { CircularGradientProps } from './CircularGradient';

export type CircularGradientCheckProps = Pick<
  CircularGradientProps,
  'className' | 'contentsClassName'
>;

const CircularGradientCheck: React.FC<CircularGradientCheckProps> = ({
  className,
  contentsClassName,
}) => (
  <CircularGradient {...{ className, contentsClassName }}>
    <FontAwesomeIcon icon="check" style={{ height: 'auto', width: '40%' }} />
  </CircularGradient>
);

export default CircularGradientCheck;
