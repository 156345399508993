import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';

export interface UsePodcastIdModalResult {
  defaultPodcastFeedId: string | undefined;
  getDefaultPodcastFeedId: () => Promise<string | undefined>;
}

/**
 * This hook is designed to manage the workflow between displaying and transitioning
 * among the `PodcastId` and `PodcastConnect` modals in response to user interactions.
 *
 * It keeps track of the `defaultPodcastFeedId` and offers a function to retrieve this ID
 * through modal interactions.
 *
 * Ideally, one would expect to initiate the process by executing:
 *
 * ```
 * const podcastId = await dispatch(pushModal({
 *   name: 'PodcastId'
 * }));
 * ```
 *
 * This would manage the entire process within the `PodcastId` modal. However,
 * a challenge arises when there is a need to switch to the `PodcastConnect` modal,
 * leading to the initial promise being resolved with an `undefined` value. Consequently,
 * if the `PodcastId` modal is reopened, the returned value for the `defaultPodcastFeedId` would
 * remain undefined since the original promise has already been resolved.
 */
export default function usePodcastIdModal() {
  const [defaultPodcastFeedId, setDefaultPodcastFeedId] = useState<
    string | undefined
  >();

  const dispatch = useDispatch<Dispatch>();

  const getDefaultPodcastFeedId = useCallback(async (): Promise<
    string | undefined
  > => {
    let podcastIdResponse = await dispatch(
      pushModal({
        name: 'PodcastId',
      }),
    );

    while (!podcastIdResponse?.podcastId) {
      if (podcastIdResponse === undefined) {
        return undefined;
      }

      await dispatch(
        pushModal({
          name: 'PodcastConnect',
        }),
      );

      podcastIdResponse = await dispatch(
        pushModal({
          name: 'PodcastId',
        }),
      );
    }

    setDefaultPodcastFeedId(podcastIdResponse.podcastId);

    return podcastIdResponse.podcastId;
  }, [dispatch]);

  return {
    defaultPodcastFeedId,
    getDefaultPodcastFeedId,
  };
}
