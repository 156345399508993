import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useQuery from 'hooks/useQuery';
import useStaticCallback from 'hooks/useStaticCallback';

import {
  loadTemplateWizard,
  unloadTemplateWizard,
} from 'redux/modules/template-wizard/actions';
import { Dispatch } from 'redux/types';
import { TemplateType } from 'types';

type TemplateLoaderStatus = 'idle' | 'pending' | 'success' | 'failure';

export interface UseTemplateLoaderConfig {
  onError?: (err: Error) => void;
}

export default function useTemplateLoader({
  onError,
}: UseTemplateLoaderConfig) {
  const query = useQuery();
  const errorHandler = useStaticCallback(onError);
  const [status, setStatus] = useState<TemplateLoaderStatus>('idle');
  const dispatch = useDispatch<Dispatch>();

  const templateId = query?.templateId as string;
  const templateType = query?.templateType as TemplateType;

  useEffect(() => {
    let identifier = templateId;

    async function loadTemplate() {
      setStatus('pending');

      try {
        await dispatch(loadTemplateWizard(templateId, templateType));

        if (identifier === templateId) {
          setStatus('success');
        }
      } catch {
        if (identifier === templateId) {
          setStatus('failure');
          errorHandler(new Error('Error loading template'));
        }
      }
    }

    identifier = templateId;
    loadTemplate();

    return () => {
      identifier = undefined;
      dispatch(unloadTemplateWizard());
    };
  }, [dispatch, errorHandler, templateId, templateType]);

  return {
    status,
    templateId,
    templateType,
  };
}
