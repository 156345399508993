import { Record, RecordOf, Set } from 'immutable';
import { omitUndefined } from 'utils/collections';
import { withValue } from 'utils/control';
import { reviveList, reviveRecord } from 'utils/immutable';

import { Coupon, CustomerCard, State, Subscription } from './types';

export const mySubscriptionFactory = Record<Subscription>(
  {
    exportBalance: undefined,
    eddyDurationLimitHours: undefined,
    fullEpisodeDurationLimitHours: undefined,
    discoEnabled: undefined,
    organizationName: undefined,
    plan: undefined,
    planEndSec: undefined,
    pauseSchedule: undefined,
    planStartSec: undefined,
    purchaseDateSec: undefined,
    purchaseManagementPlatform: undefined,
    subtier: undefined,
    tier: undefined,
    transcriptionBalanceMillis: undefined,
    eddyUploadMB: undefined,
    videoUploadLimitMb: undefined,
    autoRenewal: undefined,
    invoicePastDue: undefined,
    forcedCancellationNotice: undefined,
  },
  'MySubscriptionRecord',
);

export const myCardFactory = Record<CustomerCard>({
  brand: undefined,
  last4Digits: undefined,
});

const couponFactory = Record<Coupon>({
  couponDisplayHint: undefined,
  id: undefined,
});

export const myCouponsFactory = (coupons: Coupon[]) =>
  reviveList(couponFactory, coupons);

export const stateFactory = Record<State>(
  {
    isLoading: undefined,
    isSendingInvites: false,
    myCard: undefined,
    myCoupons: undefined,
    myReferralUrl: undefined,
    mySubscription: mySubscriptionFactory(),
    savePaymentMethodStatus: undefined,
    updateSubscriptionStatus: undefined,
    seenFreeTrialReminders: Set([]),
    externalPortalLink: undefined,
    customerId: undefined,
  },
  'PricingStateRecord',
);

export function stateFromJs({
  myCard,
  myCoupons,
  mySubscription,
  seenFreeTrialReminders,
  ...rest
}: any = {}): RecordOf<State> {
  return reviveRecord(
    stateFactory,
    omitUndefined({
      myCoupons: myCouponsFactory(myCoupons),
      mySubscription: reviveRecord(mySubscriptionFactory, mySubscription),
      myCard: reviveRecord(myCardFactory, myCard),
      seenFreeTrialReminders: withValue(seenFreeTrialReminders, Set),
      ...rest,
    }),
  );
}
