import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { twitterSelector } from 'redux/modules/social/twitter/selectors';

const usernameSelector = createSelector(
  twitterSelector,
  twitter => twitter.username,
);

const avatarSrcSelector = createSelector(
  twitterSelector,
  twitter => twitter.profileImageUrl,
);

const tweetStatusSelector = createSelector(
  twitterSelector,
  twitter => twitter.postStatus,
);

export default function useTwitterPostModalSelector() {
  const username = useSelector(usernameSelector);
  const avatarSrc = useSelector(avatarSrcSelector);
  const postStatus = useSelector(tweetStatusSelector);

  return {
    avatarSrc,
    postStatus,
    username,
  };
}
