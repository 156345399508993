import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'RECORDING_SERVICE';
export const ACTION_KEY: ActionKey = 'RECORDING_SERVICE';

export enum ServiceMethod {
  UPDATE_RECORDING = 'UPDATE_RECORDING',
  RECORDING_BY_ID_GET = 'RECORDING_BY_ID_GET',
}

export interface Recording {
  createdAt: number;
  duration: number;
  durationMillis: number;
  published: boolean;
  recordingId: number;
  recordingUrl: string;
  versionId: number;
  [key: string]: any;
}

export interface NormalizedRecording {
  entities: {
    recordings: Recording;
  };
  result: number;
}

export type UpdateRecordingArgs = [number, boolean];
export type UpdateRecordingResult = any;

export type GetRecordingByIdArgs = [number];
export type GetRecordingByIdResult = NormalizedRecording;

export type IHandle = (
  method: ServiceMethod,
  args: any,
  token?: string,
) => Promise<any>;

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export type UpdateRecordingAction = ApiAction<
  ServiceMethod.UPDATE_RECORDING,
  UpdateRecordingArgs
>;

export type GetRecordingByIdAction = ApiAction<
  ServiceMethod.RECORDING_BY_ID_GET,
  GetRecordingByIdArgs
>;

export interface RecordingServiceDispatch {
  (action: UpdateRecordingAction): Promise<IApiResponse<UpdateRecordingResult>>;
  (action: GetRecordingByIdAction): Promise<
    IApiResponse<GetRecordingByIdResult>
  >;
}
