import { fromJS } from 'immutable';
import { isEmpty, isNumber, isString } from 'underscore';

import { TextShadowV2 } from 'components/TextToolbar';
import { ICaptions } from 'types';
import merge from 'utils/deepmerge';
import { getFamilyName } from 'utils/fonts';
import measurement from 'utils/measurement';
import { parseTextShadowString } from 'utils/ui';

import { createDefaultOverlayV2 } from './style-import-utils';
import { applyPaddingBoxTransformation } from './style-legacy-parser';
import { TEXT_SHADOW_OFFSET_VALUE_TO_EM_COEF } from './style-transform-utils';
import { CaptionsConfig, TextOverlayV2 } from './types';

const DEFAULT_CAPTIONS_STYLES = {
  color: 'rgba(255, 255, 255, 1)',
  fontFamily: 'Open Sans',
  fontWeight: 'normal',
  lineHeight: 1.2,
  textAlign: 'center',
  fontSize: 18,
  textHighlight: 'rgba(0, 0, 0, 0.7)',
};

const DEFAULT_CAPTIONS_ANIMATION_TEXT_STYLE = {
  color: 'rgba(255, 255, 255, 1)',
};

const DEFAULT_BOX_POSITION_TOP_PERC = 0.75;
const DEFAULT_BOX_POSITION_LEFT_PERC = 0.1;

// Necessary height for fitting two lines of text using the default fontSize * lineHeight
const DEFAULT_BOX_HEIGHT_PX =
  DEFAULT_CAPTIONS_STYLES.fontSize * DEFAULT_CAPTIONS_STYLES.lineHeight * 2;
const DEFAULT_BOX_SIZE_WIDTH_PERC = 0.8;

type CaptionsConfigValidator = (captionsConfig?: ICaptions) => boolean;

/**
 * Validates captions config object exists and it is not empty.
 */
const validateCaptionsConfigExists: CaptionsConfigValidator = captionsConfig => {
  return !!captionsConfig && !isEmpty(captionsConfig);
};

/**
 * Validates the relevant entries for defining the captions box
 * are present at the captions config object. The entries are:
 * - height
 * - width
 * - left position
 * - top position
 */
const validateCaptionsConfigBox: CaptionsConfigValidator = captionsConfig => {
  const width = captionsConfig?.containerStyle?.width;
  const height = captionsConfig?.editor?.styleContext?.textBoxHeight;
  const left = captionsConfig?.region?.properties?.left;
  const top = captionsConfig?.region?.properties?.top;

  return [width, height, left, top].every(
    dim => isNumber(dim) || isString(dim),
  );
};

/**
 * Validates that the captions config contains a viewport definition
 * and that the definition has both height and width dimensions.
 */
const validateViewport: CaptionsConfigValidator = captionsConfig => {
  const { height, width } =
    captionsConfig?.editor?.styleContext?.viewport ?? {};

  return [height, width].every(dim => isNumber(dim) && dim);
};

/**
 * Validates the font relevant style keys are defined
 */
const validateStyleKeys: CaptionsConfigValidator = captionsConfig => {
  const fontSize = captionsConfig?.containerStyle?.fontSize;
  const fontFamily = captionsConfig?.containerStyle?.fontFamily;

  return !!(fontSize && fontFamily);
};

const VALIDATORS: CaptionsConfigValidator[] = [
  validateCaptionsConfigExists,
  validateCaptionsConfigBox,
  validateViewport,
  validateStyleKeys,
];

export const validateCaptionsConfigIntegrity = (
  captionsConfig?: ICaptions,
): boolean => {
  return VALIDATORS.every(validator => validator(captionsConfig));
};

/**
 * Appends the default box size & height.
 */
export const appendInitialBoxSize = (overlay: TextOverlayV2): TextOverlayV2 => {
  return overlay.withMutations(s =>
    s
      .setIn(['size', 'height'], DEFAULT_BOX_HEIGHT_PX)
      .setIn(
        ['size', 'width'],
        s.getIn(['viewport', 'width']) * DEFAULT_BOX_SIZE_WIDTH_PERC,
      ),
  );
};

/**
 * Appends the default intial box position to the a just created base overlay.
 * The box position is set 75% from top and 10% from left regardless of the
 * aspect ratio of the project.
 */
export const appendInitialBoxPosition = (
  overlay: TextOverlayV2,
): TextOverlayV2 => {
  return overlay.withMutations(s =>
    s
      .setIn(
        ['position', 'top'],
        s.getIn(['viewport', 'height']) * DEFAULT_BOX_POSITION_TOP_PERC,
      )
      .setIn(
        ['position', 'left'],
        s.getIn(['viewport', 'width']) * DEFAULT_BOX_POSITION_LEFT_PERC,
      ),
  );
};

/**
 * Maps the text shadow style to the expected output.
 */
export const mapTextShadowStyle = (
  overlay: TextOverlayV2,
): TextShadowV2 | undefined => {
  const textShadow = overlay
    .getIn(['editor', 'textStyle', 'textShadow'])
    ?.toJS();

  return textShadow
    ? {
        ...textShadow,
        x: (textShadow.x ?? 0) * TEXT_SHADOW_OFFSET_VALUE_TO_EM_COEF,
        y: (textShadow.y ?? 0) * TEXT_SHADOW_OFFSET_VALUE_TO_EM_COEF,
      }
    : undefined;
};

/**
 * Maps the styles that should be applied to the captions overlay config
 * override that is exported when the modal is submitted.
 * As the modal does not allow padding edition, all paddings will be
 * removed from the style.
 */
export const mapCaptionsContainerStyle = (
  overlay: TextOverlayV2,
): CaptionsConfig['containerStyle'] => {
  return {
    color: overlay.getIn(['editor', 'textStyle', 'color']),
    fontFamily: overlay.getIn(['editor', 'textStyle', 'fontFamily']),
    fontSize: `${overlay.getIn(['editor', 'scaler', 'fontSizeVw'])}vw`,
    fontStyle: overlay.getIn(['editor', 'textStyle', 'fontStyle']),
    fontWeight: overlay.getIn(['editor', 'textStyle', 'fontWeight']),
    lineHeight: overlay.getIn(['editor', 'textStyle', 'lineHeight']),
    textAlign: overlay.getIn(['editor', 'textStyle', 'textAlign']),
    textDecoration: overlay.getIn(['editor', 'textStyle', 'textDecoration']),
    textShadow: mapTextShadowStyle(overlay),
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  };
};

/**
 * Maps the style context from the text overlay. This is useful for
 * some transformations the video template export does.
 */
export const mapCaptionsStyleContext = (
  overlay: TextOverlayV2,
): CaptionsConfig['editor']['styleContext'] => {
  return {
    fontSize: overlay.getIn(['editor', 'textStyle', 'fontSize']),
    textBoxHeight: overlay.getIn(['size', 'height']),
    viewport: overlay.get('viewport').toJS(),
  };
};

/**
 * Maps the text styles to apply to the captions overlay config override that is
 * exported when the modal is submitted.
 */
export const mapCaptionsTextStyle = (
  overlay: TextOverlayV2,
): CaptionsConfig['textStyle'] => {
  return {
    background: overlay.getIn(['textBuilderStyles', 'lineStyle', 'background']),
    boxDecorationBreak: 'clone',
    paddingLeft: '0',
    paddingRight: '0',
    WebkitBoxDecorationBreak: 'clone',
  };
};

/**
 * Converts the current text overlay that is submitted by the text overlay
 * modal into a captions overide object.
 * The util extracts: container style, editor, position, text box height,
 * text style and the box size.
 * An additional hasBeenEdited flag is added. This flag indicates the overlay
 * has been edited using the text modal editor and is used when exporting the
 * embed for knowing how to threat the overrides.
 */
export const mapTextOverlayToCaptionsOverride = (
  overlay: TextOverlayV2,
): CaptionsConfig => {
  const top = measurement(overlay.getIn(['position', 'top']), 'px').toUnit(
    'vh',
    overlay.get('viewport').toJS(),
  );
  const left = measurement(overlay.getIn(['position', 'left']), 'px').toUnit(
    'vw',
    overlay.get('viewport').toJS(),
  );
  const height = measurement(overlay.getIn(['size', 'height']), 'px').toUnit(
    'vh',
    overlay.get('viewport').toJS(),
  );
  const width = measurement(overlay.getIn(['size', 'width']), 'px').toUnit(
    'vw',
    overlay.get('viewport').toJS(),
  );

  return {
    animation: overlay.get('animation')?.toJS(),
    containerStyle: mapCaptionsContainerStyle(overlay),
    editor: {
      styleContext: mapCaptionsStyleContext(overlay),
    },
    hasBeenEdited: true,
    position: {
      top,
      left,
    },
    region: {
      type: 'absolute',
      properties: {
        top: top.toString(),
        left: left.toString(),
      },
    },
    size: {
      height,
      width,
    },
    textStyle: mapCaptionsTextStyle(overlay),
  };
};

/**
 * Captions can be expressed in px or in vw. For the case when the value is expressed
 * as a number, it is assumed it is a px value.
 * When the value is not a string it will be threated as a vw based value.
 */
const getCaptionsOverrideFontSize = (
  baseOverlay: TextOverlayV2,
  captionsOverride: CaptionsConfig,
): number => {
  const baseFontSizeValue = captionsOverride.containerStyle.fontSize;

  if (typeof baseFontSizeValue === 'number') {
    return Math.round(baseFontSizeValue);
  }

  return Math.round(
    measurement(captionsOverride.containerStyle.fontSize, 'vw').toUnit(
      'px',
      baseOverlay.get('viewport').toJS(),
    ).value,
  );
};

/**
 * As font weight style value can take several different values that match "bold",
 * this util checks for several of them. If any of them matches it will set the font
 * weight to bold, otherwise it will set it to normal.
 */
const getCaptionsOverrideFontWeight = (
  captionsOverride: CaptionsConfig,
): string => {
  const overrideFontWeight = captionsOverride.containerStyle.fontWeight;
  return overrideFontWeight === 700 ||
    overrideFontWeight === '700' ||
    overrideFontWeight === 'bold'
    ? 'bold'
    : 'normal';
};

/**
 * Maps the current captions override text shadow style to the expected
 * overlay one.
 */
const getTextShadowStyle = (
  captionsOverride: CaptionsConfig,
): TextShadowV2 | undefined => {
  const { textShadow } = captionsOverride.containerStyle;

  if (!textShadow) {
    return undefined;
  }

  if (typeof textShadow === 'string') {
    return parseTextShadowString(textShadow);
  }

  return {
    ...textShadow,
    x: textShadow.x / TEXT_SHADOW_OFFSET_VALUE_TO_EM_COEF,
    y: textShadow.y / TEXT_SHADOW_OFFSET_VALUE_TO_EM_COEF,
  };
};

/**
 * Maps the style override from the captions override object.
 */
export const mapCaptionsOverrideToStyle = (
  baseOverlay: TextOverlayV2,
  captionsOverride: CaptionsConfig,
): Record<string, unknown> => {
  const fontFamily = getFamilyName(
    captionsOverride.containerStyle.fontFamily ?? '',
  );
  const fontSize = getCaptionsOverrideFontSize(baseOverlay, captionsOverride);
  const fontWeight = getCaptionsOverrideFontWeight(captionsOverride);
  const textHighlight = captionsOverride.textStyle.background;
  const textShadow = getTextShadowStyle(captionsOverride);
  const {
    color,
    fontStyle,
    textAlign,
    textDecoration,
  } = captionsOverride.containerStyle;

  const editorStyleObject = {
    color,
    fontFamily,
    fontStyle,
    fontWeight,
    fontSize,
    textAlign,
    textDecoration,
    textHighlight,
    textShadow,
  };

  return merge(DEFAULT_CAPTIONS_STYLES, editorStyleObject);
};

/**
 * Applies all the calculated style overrides to the text overlay.
 */
export const applyStyleOverridesToOverlay = (
  baseOverlay: TextOverlayV2,
  styleOverrides: Record<string, unknown>,
): TextOverlayV2 => {
  return baseOverlay.withMutations(s =>
    s.setIn(['editor', 'textStyle'], fromJS(styleOverrides)),
  );
};

/**
 * Recalculates the scaler attributes with the adjusted fontSizePx
 * value for the text overlay. This transformer should be used after
 * applying all the styles.
 */
export const recalculateScaler = (
  baseOverlay: TextOverlayV2,
): TextOverlayV2 => {
  const fontSizePx = baseOverlay.getIn(['editor', 'textStyle', 'fontSize']);
  const fontSizeVw = measurement(fontSizePx, 'px').toUnit(
    'vw',
    baseOverlay.get('viewport')?.toJS(),
  ).value;
  const fontWidthPercPerPx = fontSizeVw / fontSizePx / 100;

  return baseOverlay.withMutations(s =>
    s
      .setIn(['editor', 'textStyle', 'fontSize'], fontSizePx)
      .setIn(['editor', 'scaler', 'fontSizeVw'], fontSizeVw)
      .setIn(['editor', 'scaler', 'fontWidthPercPerPx'], fontWidthPercPerPx),
  );
};

/**
 * Obtains the adjusted box position based on the captions override
 * region object.
 */
export const adjustBoxPosition = (
  baseOverlay: TextOverlayV2,
  captionsOverride: CaptionsConfig,
): TextOverlayV2 => {
  const viewport = baseOverlay.get('viewport').toJS();

  const left =
    captionsOverride.position?.left?.toUnit('px', viewport).value ?? 0;
  const top = captionsOverride.position?.top?.toUnit('px', viewport).value ?? 0;

  return baseOverlay.withMutations(s =>
    s.setIn(['position', 'top'], top).setIn(['position', 'left'], left),
  );
};

/**
 * In the same way as some of the legacy text templates have padding applied to
 * them. The captions can have padding. When that happen, the textbox size should
 * be adjusted for matching it.
 */
export const adjustBoxSizeWithPadding = (
  baseOverlay: TextOverlayV2,
  captionsOverride: CaptionsConfig,
): TextOverlayV2 => {
  const {
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  } = captionsOverride.containerStyle;
  const viewport = baseOverlay.get('viewport')?.toJS();

  const paddings = {
    paddingTop: paddingTop
      ? measurement(paddingTop)
          .toUnit('px', viewport)
          .toUnit('vh', viewport).value
      : 0,
    paddingRight: paddingRight
      ? measurement(paddingRight).toUnit('vw', viewport).value
      : 0,
    paddingBottom: paddingBottom
      ? measurement(paddingBottom)
          .toUnit('px', viewport)
          .toUnit('vh', viewport).value
      : 0,
    paddingLeft: paddingLeft
      ? measurement(paddingLeft).toUnit('vw', viewport).value
      : 0,
  };

  return applyPaddingBoxTransformation(baseOverlay, paddings);
};

/**
 * Obtains an adjusted size object. For that it obtains the box height from the
 * captions override textBoxHeight attribute (which assumed to be in px).
 * For the box width, it obtains the width property from the container style
 * which assumed to be expressed in vw.
 */
export const adjustBoxSize = (
  baseOverlay: TextOverlayV2,
  captionsOverride: CaptionsConfig,
): TextOverlayV2 => {
  const viewport = baseOverlay.get('viewport').toJS();

  const height =
    captionsOverride.size?.height?.toUnit('px', viewport).value ?? 0;
  const width = captionsOverride.size?.width?.toUnit('px', viewport).value ?? 0;

  return baseOverlay.withMutations(s =>
    s.setIn(['size', 'height'], height).setIn(['size', 'width'], width),
  );
};

/**
 * Obtains the animations config from the captions config object and appends
 * it to the overlay object.
 * As right now the only available animation is Karaoke the only entry that
 * is checked is "follow".
 * This util should be updated if new animations are added.
 */
export const addAnimationsConfig = (
  baseOverlay: TextOverlayV2,
  captionsOverride: CaptionsConfig,
): TextOverlayV2 => {
  const animationConfig = captionsOverride.animation;
  const enabled = animationConfig?.follow?.enabled ?? false;
  const textStyle =
    animationConfig?.follow?.textStyle ?? DEFAULT_CAPTIONS_ANIMATION_TEXT_STYLE;

  return baseOverlay.withMutations(s =>
    s.set(
      'animation',
      fromJS({
        follow: {
          enabled,
          textStyle,
        },
      }),
    ),
  );
};

/**
 * Generates a base overlay for captions with the default styles.
 */
export const generateDefaultCaptionsOverlay = (
  aspectRatio: number,
): TextOverlayV2 => {
  const baseOverlay = createDefaultOverlayV2(
    aspectRatio,
    DEFAULT_CAPTIONS_STYLES,
  );
  return appendInitialBoxSize(appendInitialBoxPosition(baseOverlay));
};

/**
 * Creates a base overlay using a captions override object. It serially applies
 * a series of transformations to the overlay for:
 * - Applying all the style overrides present at the style overrides.
 * - Recalculates the scaler object in order for it to match the real font size.
 * - Recalculates the position of the captions box using the captions override object.
 * - Recalculates the size of the captions box using the captions override object.
 * - Adjust both the size and position and the box by removing the padding.
 *
 * If any exception is detected while performing all the necessary transformations,
 * a default text overlay will be returned.
 */
export const getOverlayFromCaptionsOverride = (
  aspectRatio: number,
  captionsOverride: CaptionsConfig,
): TextOverlayV2 => {
  try {
    const baseOverlay = createDefaultOverlayV2(aspectRatio);
    return baseOverlay.withMutations(s => {
      const styleOverrides = mapCaptionsOverrideToStyle(s, captionsOverride);

      s.merge(applyStyleOverridesToOverlay(s, styleOverrides))
        .merge(recalculateScaler(s))
        .merge(adjustBoxPosition(s, captionsOverride))
        .merge(adjustBoxSize(s, captionsOverride))
        .merge(adjustBoxSizeWithPadding(s, captionsOverride))
        .merge(addAnimationsConfig(s, captionsOverride));
    });
  } catch {
    return generateDefaultCaptionsOverlay(aspectRatio);
  }
};

/**
 * Generates a text overlay object for the text overlay modal.
 * If the captions override object is provided it will create a base overlay and
 * then append all the styles present at the captions override to the overlay object.
 * If the captions are not present it will just create a default overlay object
 * using the default styles.
 */
export const createCaptionsTextOverlay = (
  aspectRatio: number,
  captionsOverride?: CaptionsConfig,
): TextOverlayV2 => {
  if (captionsOverride) {
    return getOverlayFromCaptionsOverride(aspectRatio, captionsOverride);
  }

  return generateDefaultCaptionsOverlay(aspectRatio);
};
