import { createSelector } from 'reselect';
import { State } from 'redux/types';

import { RequestStatus } from 'types';
import { podcastsSelector } from '../entities';
import { ClaimPodcastState } from './types';

const stateSelector = (state: State): ClaimPodcastState =>
  state.get('claimPodcast');

export const claimPodcastRequestStatusSelector = createSelector(
  [stateSelector],
  (state: ClaimPodcastState) => {
    return state.get('claimRequestStatus');
  },
);

export const ownedPodcastRequestStatusSelector = createSelector(
  [stateSelector],
  (state: ClaimPodcastState) => {
    return state.get('ownedPodcastsRequestStatus');
  },
);

export const claimResponseSelector = createSelector(
  [stateSelector],
  (state: ClaimPodcastState) => {
    return state.get('claimResponse')?.toJS();
  },
);

export const claimPodcastIdSelector = createSelector(
  [stateSelector],
  (state: ClaimPodcastState) => {
    return state.get('podcastId');
  },
);

export const ownedPodcastsSelector = createSelector(
  [stateSelector],
  (state: ClaimPodcastState) => {
    return state.get('ownedPodcasts')?.toJS();
  },
);

export const selectedPodcastNameSelector = createSelector(
  [claimPodcastIdSelector, podcastsSelector],
  (podcastId, podcasts) => {
    return podcasts?.get(podcastId)?.get('title');
  },
);

export const claimRequestResponseStatusSelector = createSelector(
  [claimPodcastRequestStatusSelector, claimResponseSelector],
  (status, claimResponse) => {
    if (status === 'failure') {
      return 'failure';
    }

    if (claimResponse?.claimStatus === 'success') {
      return 'success';
    }

    return 'emailMismatch';
  },
);

export const hasOwnedPodcastsSelector = createSelector(
  [ownedPodcastRequestStatusSelector, ownedPodcastsSelector],
  (requestStatus, ownedPodcasts) => {
    return requestStatus === RequestStatus.SUCCESS && !!ownedPodcasts.length;
  },
);
