import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A>(method: M, args: A) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export function getTraceByCustomId(
  customTraceId: string,
): types.GetTraceByCustomIdAction {
  return apiCallAction(types.ServiceMethod.GET_TRACE_BY_CUSTOM_ID, [
    customTraceId,
  ] as types.GetTraceByCustomIdArgs);
}

export function getTraceById(traceId: string): types.GetTraceByIdAction {
  return apiCallAction(types.ServiceMethod.GET_TRACE_BY_ID, [
    traceId,
  ] as types.GetTraceByIdArgs);
}
