import React from 'react';

import { ButtonTheme } from 'components/Button';
import DialogContent from 'components/DialogContent';
import { Emoji } from 'components/icons';
import { SM_BLUE } from 'utils/constants';

import { block } from './FakeFeatureModalUtils';

interface FakeFeatureChosenModalContentsProps {
  acceptButtonTheme?: ButtonTheme;
  contentTitle?: string;
  description?: string;
  icon?: React.ReactNode;
  mainActionText?: string;
}

const FakeFeatureChosenModalContents: React.FunctionComponent<FakeFeatureChosenModalContentsProps> = props => {
  const {
    acceptButtonTheme = 'submit',
    contentTitle,
    description,
    mainActionText,
  } = props;

  return (
    <DialogContent
      actions={[
        {
          children: mainActionText,
          key: 'positive-cta',
          theme: acceptButtonTheme,
        },
      ]}
      details={description}
      detailsClassName={block('details')}
      icon={<Emoji fill={SM_BLUE} />}
      title={contentTitle}
    />
  );
};

export default FakeFeatureChosenModalContents;
