import { isString } from 'underscore';
import { createApiAction } from '../utils';
import {
  ACTION_KEY,
  CreationAction,
  CreationActionOptions,
  GetCreationAction,
  ServiceMethod,
} from './types';

function apiCallAction<M extends ServiceMethod, A>(method: M, args: A) {
  return createApiAction(ACTION_KEY, method, args);
}

export function create(opts: CreationActionOptions): CreationAction {
  const { audioSource, videoSource, baseConfigJson, ...restOpts } = opts;

  return apiCallAction(ServiceMethod.CREATE, [
    {
      ...restOpts,
      ...(audioSource && {
        [isString(audioSource) ? 'audioUrl' : 'audioFile']: audioSource,
      }),
      ...(videoSource && {
        [isString(videoSource) ? 'videoUrl' : 'videoFile']: videoSource,
      }),
      baseConfigJson: JSON.stringify(baseConfigJson),
    },
  ]);
}

export function getCreation(id: number): GetCreationAction {
  return apiCallAction(ServiceMethod.CREATION_GET, [id]);
}
