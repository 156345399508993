import React from 'react';
import { Flipped } from 'react-flip-toolkit';
import FontAwesome from 'components/FontAwesome';
import Tooltip from 'components/Tooltip';
import { podcastSidebarBlock as block } from '../utils';
import PodcastSidebarItem from './PodcastSidebarItem';

interface Props {
  active?: boolean;
  onClick?: () => void;
}

function AllProjectsButton(props: Props) {
  const { active, onClick } = props;
  return (
    <Flipped flipId="all">
      {flippedProps => (
        <PodcastSidebarItem
          className={block('podcast-item', { active, all: true })}
          flippedProps={flippedProps}
          onClick={onClick}
        >
          <Tooltip
            content="All projects"
            id="all-projects-tooltip"
            placement="right"
            hideArrow
          >
            <div className={block('artwork-container')}>
              <div className={block('all-button')}>
                <FontAwesome icon="bars" />
              </div>
            </div>
          </Tooltip>
        </PodcastSidebarItem>
      )}
    </Flipped>
  );
}

export default AllProjectsButton;
