import cn from 'classnames';
import React from 'react';

import Button from 'components/Button';
import { SelectActionMenu } from 'components/Select';
import { prepareValue } from 'components/SelectMultiple/utils';
import { ShareFormField } from 'components/ShareForm';
import ShareFormMultiSelect from 'components/ShareForm/ShareFormMultiSelect';
import { YoutubePlaylistSelectorProps } from './types';
import { block, formatPlaylistOptions } from './utils';

const fieldProps = {
  helperText:
    'We suggest selecting a playlist focused on your podcast to help viewers easily discover other episodes.',
  label: 'Playlists',
};

const YoutubePlaylistSelector: React.FC<YoutubePlaylistSelectorProps> = ({
  createPlaylist = true,
  onChange,
  onCreatePlaylistClick,
  options,
  value,
}) => {
  const formattedOptions = formatPlaylistOptions(options, value);

  const getValue = prepareValue({
    noSelected: 'No playlist selected',
    selected: 'playlist',
    selectedPlural: 'playlists',
  });

  if (options.length === 0) {
    return (
      <ShareFormField {...fieldProps}>
        <Button
          className={block('create-button')}
          onClick={onCreatePlaylistClick}
          fluid
        >
          create your first playlist
        </Button>
      </ShareFormField>
    );
  }

  return (
    <ShareFormMultiSelect
      {...fieldProps}
      {...{ onChange, options: formattedOptions }}
      overrides={
        !createPlaylist
          ? undefined
          : {
              control: {
                props: p => ({
                  ...p,
                  className: cn(block('control'), p.className),
                }),
              },
              menu: {
                component: menuProps => (
                  <SelectActionMenu
                    ctaLabel="new playlist"
                    onCtaClick={onCreatePlaylistClick}
                    {...menuProps}
                  />
                ),
              },
            }
      }
      value={getValue(formattedOptions)}
    />
  );
};

export default YoutubePlaylistSelector;
