import cn from 'classnames';
import React, { cloneElement, FunctionComponent, useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { SupportIcon } from 'components/icons';
import LinkButton from 'components/LinkButton';
import { sendPodcastSupport } from 'redux/modules/podcast-search';
import { Dispatch } from 'redux/types';
import { intersperse } from 'utils/collections';
import { block } from '../utils';
import NoSearchResultSeparator from './NoSearchResultSeparator';
import NoSearchResultTip from './NoSearchResultTip';
import { useIntegrationsContext } from './PodcastSearchIntegrationsContext';

const NoSearchResult: FunctionComponent = () => {
  const { integrations, searchTerm } = useIntegrationsContext();
  const dispatch = useDispatch<Dispatch>();

  const handleNotifyUs = useCallback((): void => {
    dispatch(
      sendPodcastSupport({ requestType: 'podcastSearchNoResult', searchTerm }),
    );
  }, [dispatch, searchTerm]);

  return (
    <div className={block('no-search-result')}>
      <p className={block('no-search-result-title')}>
        No luck. <br /> Did you try one of these:
      </p>
      <div className={block('no-search-result-icons')}>
        {intersperse(
          integrations
            .filter(({ hidden }) => !hidden)
            .map(({ color, icon, id, tip }) => (
              <NoSearchResultTip
                color={color}
                icon={cloneElement(icon, {
                  className: cn(
                    icon.props.className,
                    block('no-results-icon', { [id]: true }),
                  ),
                })}
                key={id}
              >
                {tip}
              </NoSearchResultTip>
            )),
          (_, index) => (
            <NoSearchResultSeparator
              key={`${integrations[index].id}-separator`}
            />
          ),
        )}
      </div>

      <div className={block('no-search-result-support-line')}>
        <SupportIcon className={block('support-icon')} />
        <span>
          Still not seeing it? <br />
          <LinkButton
            theme="cta"
            className={block('notify-us-button')}
            onClick={handleNotifyUs}
          >
            <strong>Notify us</strong>
          </LinkButton>
        </span>
      </div>
    </div>
  );
};

export default NoSearchResult;
