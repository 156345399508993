import React from 'react';

import AspectRatioModalContents from 'containers/AspectRatioModalContents/AspectRatioModalContents';
import ConnectedModal from 'containers/ConnectedModal';
import { AspectRatioName } from 'types';

export interface ChangeAspectRatioModalProps {
  selectedAspectRatio?: AspectRatioName;
}

const ChangeAspectRatioModal: React.FC<ChangeAspectRatioModalProps> = props => {
  const { selectedAspectRatio } = props;

  return (
    <ConnectedModal
      name="ChangeAspectRatio"
      title="Change Aspect Ratio"
      className="aspect-modal"
    >
      <AspectRatioModalContents
        modalName="ChangeAspectRatio"
        selectedAspectRatio={selectedAspectRatio}
        shouldRedirectOnSelect={false}
      />
    </ConnectedModal>
  );
};

export default ChangeAspectRatioModal;
