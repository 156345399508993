import * as React from 'react';

import { IconProps } from 'components/icons/types';

/* tslint:disable-next-line:variable-name */
const TextBoxIcon: React.SFC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      {/* tslint:disable max-line-length */}
      <path
        id="textboxIconPath"
        d="M21 6c.551 0 1 .449 1 1v10c0 .551-.449 1-1 1h-9c-.551 0-1-.449-1-1 0-.551.449-1 1-1h8V8h-8c-.551 0-1-.449-1-1 0-.551.449-1 1-1h9zm-9-3c0 .551-.449 1-1 1h-1v16h1c.551 0 1 .449 1 1 0 .551-.449 1-1 1H7c-.551 0-1-.449-1-1 0-.551.449-1 1-1h1V4H7c-.551 0-1-.449-1-1 0-.551.449-1 1-1h4c.551 0 1 .449 1 1zM6 16c.551 0 1 .449 1 1 0 .551-.449 1-1 1H3c-.551 0-1-.449-1-1V7c0-.551.449-1 1-1h3c.551 0 1 .449 1 1 0 .551-.449 1-1 1H4v8h2z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="textboxIconMask" fill="#fff">
        <use xlinkHref="#textboxIconPath" />
      </mask>
      <use fill="#FFF" fillRule="nonzero" xlinkHref="#textboxIconPath" />
      <g fill="currentColor" mask="url(#textboxIconMask)">
        <path d="M-8-8h35v35H-8z" />
      </g>
    </g>
  </svg>
);

export default TextBoxIcon;
