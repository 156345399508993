import * as React from 'react';

import { useDispatch } from 'react-redux';
import ConfirmationModal, {
  ConfirmationModalProps,
} from 'components/ConfirmationModal';
import FontAwesome from 'components/FontAwesome';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import { toggleWorkflow } from 'redux/modules/automation-cms/actions';
import { Dispatch } from 'redux/types';

type PickedModalProps = Pick<ConfirmationModalProps, 'onCancel' | 'show'>;

export interface AutomationWorkflowDisableModalProps extends PickedModalProps {
  onToggle?: (workflowId: number, togglePromise: Promise<void>) => void;
  workflowId: number;
}

const AutomationWorkflowDisableModal: React.FC<AutomationWorkflowDisableModalProps> = props => {
  const { show, workflowId, onCancel, onToggle } = props;
  const dispatch = useDispatch<Dispatch>();

  const handleConfirm = React.useCallback(() => {
    onToggle(workflowId, dispatch(toggleWorkflow(workflowId, false)));
  }, [dispatch, onToggle, workflowId]);

  return (
    <ConfirmationModal
      {...{ show, onCancel }}
      title="Automation"
      confirmButtonText="Turn it off"
      onConfirm={handleConfirm}
    >
      <ConfirmationModal.Icon>
        <CircularGradient>
          <FontAwesome icon="magic" style={{ height: '44%', width: '44%' }} />
        </CircularGradient>
      </ConfirmationModal.Icon>
      <ConfirmationModal.Title>
        Turn this automation off?
      </ConfirmationModal.Title>
      <ConfirmationModal.Content>
        If you turn this automation off it will stop
        <br />
        sending you automated videos.
      </ConfirmationModal.Content>
    </ConfirmationModal>
  );
};

export default AutomationWorkflowDisableModal;
