import Immutable from 'immutable';
import ids from 'short-id';

import { uploadImage } from 'redux/modules/common/actions';
import { createEmbedConfigurationFromState } from 'redux/modules/embed/actions';
import {
  EMBED_WATERMARK_ADD_FAILURE,
  EMBED_WATERMARK_ADD_REQUEST,
  EMBED_WATERMARK_ADD_SUCCESS,
  EMBED_WATERMARK_DELETE_FAILURE,
  EMBED_WATERMARK_DELETE_REQUEST,
  EMBED_WATERMARK_DELETE_SUCCESS,
  EMBED_WATERMARK_EDIT_FAILURE,
  EMBED_WATERMARK_EDIT_REQUEST,
  EMBED_WATERMARK_EDIT_SUCCESS,
  EMBED_WATERMARKS_UPDATE,
} from '../action-types';
import { watermarkByIdSelector, watermarkSelector } from '../selectors';

const updateWatermarksAction = watermarkById => dispatch =>
  dispatch({
    type: EMBED_WATERMARKS_UPDATE,
    payload: { watermarkById },
  });

const updateWatermarks = updateFn => (dispatch, getState) => {
  const watermarksById = watermarkByIdSelector(getState()) || Immutable.Map();
  const updatedWatermarksById = updateFn(watermarksById);

  dispatch(updateWatermarksAction(updatedWatermarksById));
};

const addToWatermarks = watermark => dispatch =>
  dispatch(
    updateWatermarks(watermarks =>
      watermarks.set(watermark.get('id'), watermark),
    ),
  );

const removeFromWatermarks = watermarkId => dispatch =>
  dispatch(updateWatermarks(watermarks => watermarks.delete(watermarkId)));

export const uploadWatermarkFile = file => dispatch =>
  dispatch(uploadImage(file)).then(res => res.url);

/*
 * NB: because of the way watermark works right now, no saving takes place when adding a watermark.
 *     the watermark is only saved on export
 */
export const addWatermark = (file, position, size) => dispatch => {
  dispatch({ type: EMBED_WATERMARK_ADD_REQUEST });

  const id = ids.generate();

  const futWatermark = dispatch(uploadWatermarkFile(file)).then(imageUrl =>
    Immutable.fromJS({
      id,
      position,
      size,
      src: imageUrl,
    }),
  );

  const futUpdatedWatermarks = futWatermark.then(watermark =>
    dispatch(addToWatermarks(watermark)),
  );

  return Promise.all([futWatermark, futUpdatedWatermarks])
    .then(() => dispatch(createEmbedConfigurationFromState()))
    .then(() =>
      dispatch({
        type: EMBED_WATERMARK_ADD_SUCCESS,
      }),
    )
    .catch(error =>
      dispatch({
        ...error,
        type: EMBED_WATERMARK_ADD_FAILURE,
      }),
    );
};

/*
 * NB: because of the way watermark works right now, no saving takes place when removing a
 *     watermark. the watermark is only saved on export
 */
export const removeWatermark = watermarkId => (dispatch, getState) => {
  const id = (() => {
    if (watermarkId) {
      return watermarkId;
    }

    const watermark = watermarkSelector(getState());
    return watermark && watermark.get('id');
  })();

  if (!id) return Promise.resolve();

  dispatch({ type: EMBED_WATERMARK_DELETE_REQUEST });
  dispatch(removeFromWatermarks(id));
  return dispatch(createEmbedConfigurationFromState())
    .then(() => dispatch({ type: EMBED_WATERMARK_DELETE_SUCCESS }))
    .then(() => dispatch({ type: EMBED_WATERMARK_DELETE_FAILURE }));
};

/**
 * NB: specific to having only one watermark for the project.  will need to be replaced or at least
 *     modified when multiple watermarks are supported
 */
export const replaceWatermark = (file, position, size) => (
  dispatch,
  getState,
) => {
  const watermark = watermarkSelector(getState());

  if (watermark) {
    dispatch(removeWatermark(watermark.get('id')));
  }

  return dispatch(addWatermark(file, position, size));
};

export const editWatermark = (position, size) => (dispatch, getState) => {
  const watermark = watermarkSelector(getState());

  if (watermark) {
    const updatedWatermark = Immutable.fromJS({
      id: watermark.get('id'),
      position,
      size,
      src: watermark.get('src'),
    });

    dispatch(
      updateWatermarks(watermarks =>
        watermarks.set(watermark.get('id'), updatedWatermark),
      ),
    );
  }

  dispatch({ type: EMBED_WATERMARK_EDIT_REQUEST });
  dispatch(createEmbedConfigurationFromState())
    .then(() =>
      dispatch({
        type: EMBED_WATERMARK_EDIT_SUCCESS,
      }),
    )
    .catch(error =>
      dispatch({
        ...error,
        type: EMBED_WATERMARK_EDIT_FAILURE,
      }),
    );
};

export default {
  addWatermark,
  removeWatermark,
  replaceWatermark,
};
