import isUrl from 'is-url';
import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { LinkIcon } from 'components/icons';
import {
  clearSearchResults,
  collectFeedback,
  searchPodcastsByFeed,
} from 'redux/modules/podcast-search/actions';
import { podcastResultsSelector } from 'redux/modules/podcast-search/selectors';
import { Dispatch } from 'redux/types';
import PodcastExplorerBody, {
  PodcastExplorerBodyProps,
} from './containers/PodcastExplorerBody';
import { PodcastSearchIntegration } from './types';

type PickedIntegrationProps = Pick<
  PodcastSearchIntegration,
  'priority' | 'feedCollectionEnabled'
>;
type PickedBodyProps = Pick<
  PodcastExplorerBodyProps,
  | 'episodeExplorerEnabled'
  | 'isProcessingEpisode'
  | 'onAudioAdded'
  | 'onPodcastClick'
  | 'onEpisodeClick'
>;

interface UseRssIntegrationConfig
  extends PickedIntegrationProps,
    PickedBodyProps {
  maxFileSizeMb?: number;
}

// TODO duplicate with default int
const resultCountSelector = createSelector(
  podcastResultsSelector,
  results => results && results.size,
);

export default function useRssIntegration({
  episodeExplorerEnabled,
  feedCollectionEnabled = true,
  isProcessingEpisode,
  maxFileSizeMb = spareminConfig.episodeMaxAudioFileSizeMb,
  onAudioAdded,
  onPodcastClick,
  onEpisodeClick,
  priority,
}: UseRssIntegrationConfig): PodcastSearchIntegration<'rss'> {
  const dispatch = useDispatch<Dispatch>();
  const resultCount = useSelector(resultCountSelector);
  const handlePodcastClick = (podcastId: string) => {
    if (feedCollectionEnabled) {
      dispatch(collectFeedback(podcastId));
    }
    onPodcastClick?.(podcastId);
  };

  return {
    resultCount,
    canHandle: (q: string) => isUrl(q),
    color: '#e77ac0',
    description: {
      part1: 'Enter in a',
      part2: 'RSS feed link',
    },
    icon: <LinkIcon />,
    id: 'rss',
    onSubmit: async (q: string) => {
      dispatch(clearSearchResults());
      return dispatch(searchPodcastsByFeed(q));
    },
    placeholder: 'RSS feed',
    priority,
    results: height => (
      <PodcastExplorerBody
        onPodcastClick={handlePodcastClick}
        {...{
          episodeExplorerEnabled,
          height,
          isProcessingEpisode,
          maxFileSizeMb,
          onAudioAdded,
          onEpisodeClick,
        }}
      />
    ),
    tip: 'RSS feed link',
    episodeExplorerEnabled,
  };
}
