import * as React from 'react';
import { noop } from 'underscore';

import { Fill, Padded, Reset } from 'components/icons';
import { PopperRadio } from 'components/Popper';
import Popper from 'components/Popper/Popper';
import PopperVerticalAlignRadio from '../Popper/PopperVerticalAlignRadio';
import { WaveformAlignment, WaveformPlacement, WaveformSize } from '../types';
import { waveformPlacementPopperBlock as block } from '../utils';

const { useCallback } = React;

export interface WaveformPlacementPopperProps {
  onChange?: (value: WaveformPlacement) => void;
  value: WaveformPlacement;
}

const WaveformPlacementPopper: React.FC<WaveformPlacementPopperProps> = ({
  onChange = noop,
  value,
}) => {
  const alignment = value?.alignment;
  const size = value?.size;

  const handleAlignmentClick = useCallback(
    (newAlignment: WaveformAlignment) => {
      if (newAlignment !== alignment) {
        onChange({ alignment: newAlignment, size });
      }
    },
    [onChange, alignment, size],
  );

  const handleSizeClick = useCallback(
    (newSize: WaveformSize | 'reset') => {
      if (newSize !== size) {
        if (newSize === 'reset') {
          onChange(undefined);
        } else {
          onChange({ alignment, size: newSize });
        }
      }
    },
    [onChange, alignment, size],
  );

  return (
    <Popper className={block()}>
      <PopperVerticalAlignRadio
        onChange={handleAlignmentClick}
        value={value.alignment}
      />
      <PopperRadio
        onChange={handleSizeClick}
        options={[
          {
            icon: <Fill />,
            value: 'fill',
          },
          {
            icon: <Padded />,
            value: 'padded',
          },
          {
            icon: <Reset />,
            value: 'reset',
          },
        ]}
        value={value.size}
      />
    </Popper>
  );
};

export default WaveformPlacementPopper;
