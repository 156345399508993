import { useState } from 'react';
import { ProgressAndTimerOptions } from 'types';
import { Measurement } from 'utils/measurement';

export default function useProgressAnimationOptionsState(
  defaultValue: ProgressAndTimerOptions<Measurement>,
) {
  const [value, setValue] = useState<ProgressAndTimerOptions<Measurement>>(
    defaultValue,
  );

  return {
    handleChange: setValue,
    value,
  };
}
