import { createSelector } from 'reselect';
import { isNumber, isUndefined } from 'underscore';

import { Trace } from 'redux/middleware/api/url-generator-service';
import {
  podcastEpisodesSelector,
  tracesSelector,
} from 'redux/modules/entities/selectors';

import { State } from 'redux/types';
import { DeepImmutableMap } from 'types';
import { isAudiogram } from 'utils/embed/embed';
import { isBasicOrFreeSelector } from '../pricing';
import { entireAudioEnhancer } from './enhancers';
import { AudioWizardState } from './types';

const stateSelector = (state: State): AudioWizardState =>
  state.get('audioWizard');

export const traceIdSelector = createSelector(
  stateSelector,
  state => state.traceId,
);

export const episodeIdSelector = createSelector(
  stateSelector,
  state => state.episodeId,
);

const traceSelector = createSelector(
  [tracesSelector, traceIdSelector],
  (traces, traceId) =>
    !traces || !traceId
      ? undefined
      : (traces.get(traceId) as DeepImmutableMap<Trace>),
);

export const episodeSelector = createSelector(
  [podcastEpisodesSelector, episodeIdSelector],
  (episodes, episodeId) => {
    return episodes?.get(String(episodeId));
  },
);

export const customTraceIdSelector = createSelector(
  traceSelector,
  trace => trace && trace.get('customTraceId'),
);

export const allowAudioClippingSelector = createSelector(
  traceSelector,
  episodeSelector,
  (trace, episode) => {
    return !!episode || trace?.getIn(['displayProperties', 'useAudioClipper']);
  },
);

export const imageUrlSelector = createSelector(
  traceSelector,
  episodeSelector,
  (trace, episode): string => {
    return (
      trace?.getIn(['properties', 'imageUrl']) ??
      episode?.get('imageUrl') ??
      episode?.getIn(['podcast', 'imageUrl'])
    );
  },
);

export const clipTitleSelector = createSelector(
  traceSelector,
  episodeSelector,
  (trace, episode): string => {
    return (
      trace?.getIn(['properties', 'clipTitle']) ??
      episode?.get('title') ??
      episode?.getIn(['podcast', 'title'])
    );
  },
);

export const isLoadingSelector = createSelector(stateSelector, state => {
  return state.isLoading;
});

export const hasLoadErrorSelector = createSelector(stateSelector, state => {
  return state.wizardLoadError;
});

export const colorSelector = createSelector(
  traceSelector,
  trace => trace && (trace.getIn(['properties', 'color']) as string),
);

export const audioUrlSelector = createSelector(
  traceSelector,
  episodeSelector,
  (trace, episode) => {
    return trace?.getIn(['properties', 'audioUrl']) ?? episode?.get('audioUrl');
  },
);

export const transcriptUrlSelector = createSelector(
  traceSelector,
  trace => trace && trace.getIn(['properties', 'transcriptUrl']),
);

export const traceAudioDurationMillisSelector = createSelector(
  traceSelector,
  trace => {
    const duration = trace?.getIn(['properties', 'audioDurationSeconds']);
    return isNumber(duration) ? duration * 1000 : undefined;
  },
);

const originalAudioDurationMillisSelector = createSelector(
  stateSelector,
  state => state.originalAudioDurationMillis,
);

const fullAudioDurationMillisSelector = createSelector(
  [
    traceAudioDurationMillisSelector,
    originalAudioDurationMillisSelector,
    episodeSelector,
  ],
  (traceDuration, audioDuration, episode): number =>
    traceDuration ?? audioDuration ?? episode?.get('audioDurationMillis'),
);

export const audioClipSelector = createSelector(
  stateSelector,
  state => state.audioClip,
);

const clippedAudioDurationMillisSelector = createSelector(
  audioClipSelector,
  clip => {
    return !clip ? undefined : clip.endMillis - clip.startMillis;
  },
);

export const finalAudioDurationMillisSelector = createSelector(
  [clippedAudioDurationMillisSelector, fullAudioDurationMillisSelector],
  (clippedDuration, fullDuration) =>
    isUndefined(clippedDuration) ? fullDuration : clippedDuration,
);

export const audioClipperMaxDurationMillisSelector = createSelector(
  traceSelector,
  trace => {
    const maxMillis =
      trace &&
      trace.getIn(['configurations', 'audioClipperSelectionMaxMillis']);
    return maxMillis || Infinity;
  },
);

export const shouldPollForAssetsSelector = createSelector(
  traceSelector,
  trace => trace?.getIn(['configurations', 'pollForAsset']) ?? false,
);

export const traceIntegratorNameSelector = createSelector(
  traceSelector,
  (trace): string => trace?.getIn(['integrator', 'name']),
);

export const transcriptionConfigSelector = createSelector(
  traceSelector,
  episodeSelector,
  isBasicOrFreeSelector,
  traceIntegratorNameSelector,
  (trace, episode, isBasicOrFreeUser, integratorName) => {
    // checks if transcription config toggle should be shown
    const transcriptionToggleAllowed = Boolean(
      (!trace?.getIn(['properties', 'transcriptUrl']) &&
        trace?.getIn(['displayProperties', 'showAudioTranscriptionToggle'])) ||
        !!episode,
    );

    // defines a locked config based on:
    // - if config toggle is allowed: no locked config is defined (will be defined based on prefs by the clipper)
    // - if config toggle is not allowed: it sets the transcribe config as true if there is a transcript url and if
    // the user is not on basic or free tier and not an integrator.
    const lockedConfig =
      !transcriptionToggleAllowed &&
      !!trace?.getIn(['properties', 'transcriptUrl'])
        ? {
            transcribe: !isBasicOrFreeUser && !integratorName,
            language: undefined,
          }
        : undefined;

    return {
      lockedConfig,
      transcriptionToggleAllowed,
    };
  },
);

export const widgetOwnerNameSelector = createSelector(
  traceSelector,
  (trace): string => trace && trace.getIn(['widgetOwner', 'ownerName']),
);

export const thirdPartyNameSelector = createSelector(
  widgetOwnerNameSelector,
  traceIntegratorNameSelector,
  (owner, integrator) => owner || integrator,
);

export const isAudiogramSelector = createSelector(
  finalAudioDurationMillisSelector,
  durationMillis => isAudiogram(durationMillis),
);

export const {
  entireAudioInstanceIdSelector,
  uploadStatusSelector,
} = entireAudioEnhancer.selectors;
