import * as actions from './actions';
import { handle } from './service';
import * as types from './types';

const { ACTION_KEY, ServiceMethod, ...restTypes } = types;

export { actions };

export default {
  handle,
  ActionKey: ACTION_KEY,
  Method: ServiceMethod,
  ...restTypes,
};
