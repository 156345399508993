const BRAND_ICON_MAPPINGS = {
  'facebook-f': ['fab', 'facebook-f'],
  'facebook-square': ['fab', 'facebook-square'],
  instagram: ['fab', 'instagram'],
  linkedin: ['fab', 'linkedin'],
  'snapchat-ghost': ['fab', 'snapchat-ghost'],
  twitter: ['fab', 'twitter'],
  youtube: ['fab', 'youtube'],
};

export const mapIcon = (icon: string, isRegular: boolean) => {
  // brand icons
  if (BRAND_ICON_MAPPINGS[icon]) return BRAND_ICON_MAPPINGS[icon];

  // regular icons
  if (isRegular) return ['far', icon];

  // solid icons
  return icon;
};
