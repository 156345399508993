import * as React from 'react';

import Tooltip from 'components/Tooltip';
import { block } from '../../utils';

export interface ProjectTileTooltipProps {
  children: React.ReactNode;
  isDisabled: boolean;
  message?: string;
  projectId: string;
}

const ProjectTileTooltip: React.FC<ProjectTileTooltipProps> = ({
  children,
  isDisabled,
  message,
  projectId,
}) => {
  return !isDisabled ? (
    <>{children}</>
  ) : (
    <Tooltip
      animation={false}
      content={message}
      id={`${projectId}-tooltip`}
      placement="top"
      preventHideOnHover={false}
      className={block('disabled-tooltip')}
    >
      {children}
    </Tooltip>
  );
};

export default ProjectTileTooltip;
