import * as React from 'react';

import { IconProps } from './types';

const Wave: React.SFC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 50" {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      stroke="#14191E"
      strokeLinejoin="round"
      d="M9.5 24.5v25h-9v-25h9zm16-24v49h-9V.5h9zm16 12v37h-9v-37h9zm16 20v17h-9v-17h9z"
    />
  </svg>
);

export default Wave;
