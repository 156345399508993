import * as React from 'react';
import { noop } from 'underscore';

import ProjectTile, { ProjectTileProps } from '../containers/ProjectTile';
import { block } from '../utils';
import ProjectListHeading from './ProjectListHeading';

const { useEffect } = React;

type PickedProps = Pick<ProjectTileProps, 'onControlClick'>;

interface IProps extends PickedProps {
  hideHeading?: boolean;
  /**
   * called every time a different number of projects is rendered.  useful for
   * infinite scrolling so that the loader doesn't get into a race condition where
   * it keeps fetching the same page because the results have come back but
   * haven't yet rendered so the scroll offset hasn't changed.
   */
  onItemsRendered?: () => void;
  projects: string[];
}

const ProjectList: React.FC<IProps> = ({
  hideHeading,
  onItemsRendered,
  projects,
  ...props
}) => {
  const nProjects = projects?.length ?? 0;

  useEffect(onItemsRendered, [onItemsRendered, nProjects]);

  return (
    <div className={block('project-list')}>
      {!hideHeading && <ProjectListHeading />}
      {projects.map(id => {
        return <ProjectTile {...props} key={id} projectId={id} />;
      })}
    </div>
  );
};

ProjectList.defaultProps = {
  onItemsRendered: noop,
};

export default ProjectList;
export { IProps as ProjectListProps };
