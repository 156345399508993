import { Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  textTemplateHideOptionSelector,
  textTemplateOverrideIdsSelector,
} from 'redux/modules/display-pref/selectors';
import { actions as embedActions } from 'redux/modules/embed';
import {
  aspectRatioSelector,
  captionsConfigSelector,
  captionsLockSelector,
  textTemplatesSelector,
} from 'redux/modules/embed/selectors';
import { manualTranscriptsSelector } from 'redux/modules/entities/selectors';
import * as mixpanelActions from 'redux/modules/mixpanel';
import { fontsSelector } from 'redux/selectors';
import { Dispatch, State } from 'redux/types';
import { CAPTION_STYLE_FIT_TEXT } from 'utils/constants';
import { formatCaptionsTemplate } from 'utils/embed/captions';
import { getFilteredTemplates } from 'utils/text-templates/utils';
import { LegacyEditCaptionsModalProps } from './LegacyEditCaptionsModal';
import { formatCaptionAsProp } from './utils';

type StateProps = Pick<
  LegacyEditCaptionsModalProps,
  | 'caption'
  | 'fonts'
  | 'lockCaptions'
  | 'styleTemplates'
  | 'workspaceAspectRatio'
>;

type DispatchProps = Pick<
  LegacyEditCaptionsModalProps,
  'onCaptionsLockChange' | 'onTemplateSelect'
>;

const styleTemplatesSelector = createSelector(
  textTemplatesSelector,
  textTemplates =>
    textTemplates &&
    textTemplates.reduce((acc, template) => {
      // Skip over templates that have container decorators since they are not supported in captions
      if (!template.template.get('containerDecorator')) {
        acc.push({
          ...template,
          template: formatCaptionsTemplate(template.template),
        });
      }
      return acc;
    }, []),
);

const filteredTemplatesSelector = createSelector(
  [
    styleTemplatesSelector,
    textTemplateOverrideIdsSelector,
    textTemplateHideOptionSelector,
  ],
  (templates, overrideIds, hideOptionIds) =>
    getFilteredTemplates(templates, overrideIds, hideOptionIds),
);

const captionsSelector = createSelector(
  [captionsConfigSelector, manualTranscriptsSelector],
  (captionsConfig, manualTranscripts) => {
    if (!captionsConfig || !captionsConfig.get('style') || !manualTranscripts) {
      return undefined;
    }

    const style = captionsConfig.get('style', Map());
    return formatCaptionAsProp(style).set('text', CAPTION_STYLE_FIT_TEXT);
  },
);

const mapStateToProps = (state: State): StateProps => ({
  caption: captionsSelector(state) as any,
  fonts: fontsSelector(state),
  lockCaptions: captionsLockSelector(state),
  styleTemplates: filteredTemplatesSelector(state),
  workspaceAspectRatio: aspectRatioSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onCaptionsLockChange: lock => dispatch(embedActions.setCaptionsLock(lock)),
  onTemplateSelect: displayName =>
    dispatch(mixpanelActions.onApplyTextTemplate('caption', displayName)),
});

export default function(
  component: React.ComponentType<LegacyEditCaptionsModalProps>,
) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
