import { createSelector } from 'reselect';

import { State } from 'redux/types';
import { entireAudioEnhancer } from './enhancers';
import { AutomatedWizardState } from './types';

const stateSelector = (state: State): AutomatedWizardState =>
  state.get('automatedWizard');

export const statusSelector = createSelector(stateSelector, state =>
  state.get('status'),
);

export const aspectRatioDimensionsSelector = createSelector(
  stateSelector,
  state => state.get('aspectRatioDimensions'),
);

export const widgetIdSelector = createSelector(stateSelector, state =>
  state.get('widgetId'),
);

export const projectIdSelector = createSelector(stateSelector, state =>
  state.get('projectId'),
);

export const {
  entireAudioInstanceIdSelector,
  uploadStatusSelector,
  uploadProgressSelector,
} = entireAudioEnhancer.selectors;
