import * as React from 'react';

import FontAwesome, { IconProp } from 'components/FontAwesome';
import { blockNavigation } from './utils';

export interface IProps {
  iconName: IconProp;
  title: React.ReactNode;
}

const UserDropdown: React.SFC<IProps> = ({ iconName, title }) => (
  <div className={blockNavigation('menu-header')}>
    <FontAwesome
      className={blockNavigation('menu-header-icon')}
      icon={iconName}
      size="2x"
    />

    <span className={blockNavigation('menu-header-title')}>{title}</span>
  </div>
);

export default UserDropdown;
export { IProps as DropdownMenuHeaderProps };
