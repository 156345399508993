import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import IntroOutroUpsellModalContents from './IntroOutroUpsellModalContents';

export interface IntroOutroUpsellModalProps {}

const IntroOutroUpsellModal: React.FC<IntroOutroUpsellModalProps> = () => (
  <ConnectedModal name="IntroOutroUpsell" title="intros and outros">
    <IntroOutroUpsellModalContents />
  </ConnectedModal>
);

export default IntroOutroUpsellModal;
