import * as React from 'react';

import CaptionsChildView from './CaptionsChildView';
import ChildViewZoomEffect from './ChildViewZoomEffect';
import { useNavigation } from './context/NavigationContext';
import ImageChildView from './ImageChildView';
import IntroOutroChildView from './IntroOutroChildView';
import IntroOutroEditChildView from './IntroOutroChildView/IntroOutroEditChildView';
import MediaChildView from './MediaChildView';
import ProgressChildView from './ProgressChildView';
import TextChildView from './TextChildView';
import WatermarkChildView from './WatermarkChildView';
import WaveformChildView from './WaveformChildView';

const { useCallback } = React;

const OptionsChildViewManager: React.FC = () => {
  const [state, send] = useNavigation();

  const handleViewClose = useCallback(() => {
    send('CHILD_VIEW_CLOSE');
  }, [send]);

  return (
    <>
      <WaveformChildView
        active={state.matches({ child: 'waveform' })}
        onClose={handleViewClose}
      />
      <ImageChildView
        active={state.matches({ child: 'image' })}
        onClose={handleViewClose}
      />
      <IntroOutroChildView
        active={state.matches({ child: 'intro_outro' })}
        onClose={handleViewClose}
      />
      <IntroOutroEditChildView
        active={state.matches({ child: 'intro_outro_edit' })}
        onClose={handleViewClose}
      />
      <TextChildView
        active={state.matches({ child: 'text' })}
        onClose={handleViewClose}
      />
      <MediaChildView
        active={state.matches({ child: 'images' })}
        onClose={handleViewClose}
      />
      <WatermarkChildView
        active={state.matches({ child: 'watermark' })}
        onClose={handleViewClose}
      />
      <ProgressChildView
        active={state.matches({ child: 'progress' })}
        onClose={handleViewClose}
      />
      <CaptionsChildView
        active={state.matches({ child: 'captions' })}
        onClose={handleViewClose}
      />
      <ChildViewZoomEffect />
    </>
  );
};

export default OptionsChildViewManager;
