import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import TierSelectionModalContents from './TierSelectionModalContents';
import { block } from './utils';

export interface TierSelectionModalProps {}

const TierSelectionModal: React.FC<TierSelectionModalProps> = () => (
  <ConnectedModal
    className={block()}
    name="TierSelection"
    title="upgrade your plan"
  >
    <TierSelectionModalContents />
  </ConnectedModal>
);

export default TierSelectionModal;
