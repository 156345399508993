import * as React from 'react';

import EmptyTemplatesButton from './UserGeneratedEmptyButton';

const UpgradeToProButton: React.FC = () => (
  <EmptyTemplatesButton href="/settings/my-plan" theme="rainbow">
    upgrade to pro
  </EmptyTemplatesButton>
);

export default UpgradeToProButton;
