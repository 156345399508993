import { connect } from 'react-redux';

import { onClickProgressBar } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import { PropsOf } from 'types';
import ProgressBarTile, {
  ProgressBarTileProps as Props,
} from './ProgressBarTile';

type DispatchProps = Pick<Props, 'onClick'>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onClick: () => dispatch(onClickProgressBar()),
});

const component = connect(null, mapDispatchToProps)(ProgressBarTile);
export type ProgressBarTileProps = PropsOf<typeof component>;
export default component;
