import { useCallback } from 'react';

import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import { useModalManager } from './ModalManager';
import { hasWatermark } from './state';

export default function useDeleteWatermark() {
  const { showModal } = useModalManager();
  const state = useTemplateState();
  const hasWatermarkAdded = hasWatermark(state);
  const dispatch = useTemplateDispatch();

  return useCallback(() => {
    if (!hasWatermarkAdded) return;

    showModal('delete', {
      assetName: 'this watermark',
      onDelete: () => {
        dispatch({
          type: 'WATERMARK_DELETE',
        });
      },
      title: 'delete watermark',
    });
  }, [dispatch, hasWatermarkAdded, showModal]);
}
