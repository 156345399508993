import { Heading, Spacer } from '@sparemin/blockhead';
import React from 'react';
import { block } from '../utils';
import EddyProjectCreationOptionsList from './EddyProjectCreationOptionsList';

export interface EddyProjectCreationContentsProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  imageSrc: string;
  shouldGenerateAssets: boolean;
  onShouldGenerateAssetsChange: (shouldGenerateAssets: boolean) => void;
}

const EddyProjectCreationContents: React.FC<EddyProjectCreationContentsProps> = ({
  title,
  description,
  imageSrc,
  shouldGenerateAssets,
  onShouldGenerateAssetsChange,
}) => {
  return (
    <Spacer
      orientation="vertical"
      align="center"
      justify="center"
      space="0"
      className={block('creation-wrapper')}
    >
      <img src={imageSrc} className={block('creation-image')} />

      <Spacer
        orientation="vertical"
        align="center"
        justify="center"
        space="32px"
        className={block('creation-contents')}
      >
        <Spacer
          orientation="vertical"
          align="center"
          justify="center"
          space="4px"
        >
          <Heading level={3}>{title}</Heading>

          {description && description}
        </Spacer>

        <EddyProjectCreationOptionsList
          {...{ shouldGenerateAssets, onShouldGenerateAssetsChange }}
        />
      </Spacer>
    </Spacer>
  );
};

export default EddyProjectCreationContents;
