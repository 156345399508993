import * as React from 'react';
import { noop } from 'underscore';

import VideoPlayer, { VideoPlayerProps } from 'components/VideoPlayer';
import { Omit } from 'types';
import PlayTimer from './PlayTimer';
import { block } from './utils';

interface IProps extends Omit<VideoPlayerProps, 'controls' | 'bigPlayButton'> {
  playerRef?: (el: VideoPlayer) => void;
  positionMillis: number;
}

interface IState {
  durationMillis: number;
}

export default class VideoClipperPlayer extends React.Component<
  IProps,
  IState
> {
  public static defaultProps: Partial<IProps> = {
    onDurationChange: noop,
    onTimeUpdate: noop,
    playerRef: noop,
    positionMillis: 0,
  };

  public state: IState = {
    durationMillis: 0,
  };

  private handleDurationChange = (durationMillis: number) => {
    const { onDurationChange } = this.props;
    this.setState({ durationMillis });
    onDurationChange(durationMillis);
  };

  public render() {
    const {
      playerRef,
      positionMillis,
      onTimeUpdate,
      ...playerProps
    } = this.props;
    const { durationMillis } = this.state;

    return (
      <VideoPlayer
        {...playerProps}
        bigPlayButton={false}
        controls="hide"
        onDurationChange={this.handleDurationChange}
        onTimeUpdate={onTimeUpdate}
        ref={playerRef}
      >
        <PlayTimer
          className={block('play-timer')}
          currentMillis={positionMillis}
          totalMillis={durationMillis}
        />
      </VideoPlayer>
    );
  }
}

export { IProps as VideoClipperPlayerProps };
