import * as Sentry from '@sentry/browser';
import { List, Record } from 'immutable';
import * as ids from 'short-id';
import _ from 'underscore';

import { VideoPosition } from 'blocks/VideoCropper';
import { MediaImportedArgs } from 'containers/MediaUploadModal/types';
import {
  IVideoUpload,
  UploadVideoOptions,
} from 'redux/middleware/api/media-upload-service/';
import {
  actions as embedActions,
  selectors as embedSelectors,
} from 'redux/modules/embed';
import { aspectRatioDimensionsSelector } from 'redux/modules/embed/selectors';
import { manualTranscriptsSelector } from 'redux/modules/entities/selectors';
import { replaceModal } from 'redux/modules/modal';
import { videoAssetTypeSelector } from 'redux/modules/video-edit/selectors';
import { actions as uploadActions } from 'redux/modules/video-upload';
import { Dispatch, GetState, ThunkAction } from 'redux/types';
import { IImmutableMap } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import embedUtils from 'utils/embed';
import { createWizardCaptions } from 'utils/embed/captions';
import {
  getVideoStyle,
  hasAllDimensions,
  VideoScaling,
} from 'utils/embed/video';
import { round } from 'utils/numbers';
import { millisToSec } from 'utils/time';
import * as types from '../action-types';
import {
  AddVideoFailureAction,
  AddVideoSuccessAction,
  EmbedVideoState,
  IEmbedVideoState,
  RemoveVideoSuccessAction,
} from '../types';
import { videoFactory } from '../utils';
import { setupCaptions } from './captions';
import { removeReplacedTrackElement } from './editor';
import { addingToTrack } from './tracks';

interface IAddVideoParams {
  file: File | string;
  startMillis: number;
  endMillis: number;
  audioLevel: number;
  mainAudioLevel: number;
  audioFadeInDurationMillis: number;
  audioFadeOutDurationMillis: number;
  transitionIn: string;
  transitionOut: string;
  clipStartMillis: number;
  clipEndMillis: number;
  durationMillis: number;
  scaling: VideoScaling;
  blurredBackground: boolean;
  mediaToReplaceId?: string;
  preloadedVideoEntity: IImmutableMap<IVideoUpload>;
  transcriptionEnabled?: boolean;
  transcriptionLanguage?: string;
}

export { IAddVideoParams as AddVideoParams };

export const positionFactory = Record<VideoPosition>({
  left: undefined,
  top: undefined,
});

const addToVideoCollection = (
  video: EmbedVideoState,
): ThunkAction<void> => dispatch => {
  dispatch({
    payload: { video },
    type: types.EMBED_VIDEO_COLLECTION_ADD,
  });
  dispatch(embedActions.setEmbedDuration(true));
};

const getVideoSrc = videoEntity => {
  const defaultUrl = videoEntity.get('transcodedVideoUrl', '');
  const variations = videoEntity.get('variations', List()).toArray();

  if (variations.length && variations[0].get('isTransparent', false)) {
    return variations[0].get('url', defaultUrl);
  }

  return defaultUrl;
};

export const addVideoToTrack = (
  params: IAddVideoParams,
  mediaImportedArgs: MediaImportedArgs,
): ThunkAction<Promise<
  AddVideoSuccessAction | AddVideoFailureAction
>> => async (dispatch, getState) => {
  const replaceVideoId = embedSelectors
    .replaceTrackElementSelector(getState())
    .get('dataId');
  const replaceVideo =
    replaceVideoId &&
    embedSelectors.videosByIdSelector(getState()).get(replaceVideoId);

  const checkMediaToReplaceId = params.mediaToReplaceId;
  const mediaToReplaceIsVideo = !!(checkMediaToReplaceId && replaceVideo);

  if (mediaToReplaceIsVideo) {
    params.scaling = replaceVideo.get('scaling');
  }

  const assetType = videoAssetTypeSelector(getState());
  const {
    file,
    startMillis,
    audioLevel,
    mainAudioLevel,
    audioFadeInDurationMillis,
    audioFadeOutDurationMillis,
    transitionIn,
    transitionOut,
    clipStartMillis,
    clipEndMillis,
    durationMillis,
    scaling,
    blurredBackground,
    preloadedVideoEntity,
    transcriptionEnabled = undefined,
    transcriptionLanguage = undefined,
    mediaToReplaceId,
  } = params;

  dispatch({ type: types.EMBED_VIDEO_ADD_REQUEST });

  const trackId = embedSelectors.selectAddingToTrack(getState());

  const transcriptionOptions: Pick<
    UploadVideoOptions,
    'language' | 'transcribe'
  > = {
    language: undefined,
    transcribe: undefined,
  };

  if (transcriptionEnabled) {
    transcriptionOptions.language = transcriptionLanguage;
    transcriptionOptions.transcribe = true;
  }

  try {
    let videoEntity: IImmutableMap<IVideoUpload>;

    // When a preloaded video entity has been provided it will be used instead of
    // uploading it again. This is generally the case for gifs.
    if (preloadedVideoEntity) {
      videoEntity = preloadedVideoEntity;
      // Otherwise the video file/url will be uploaded. This is the case for video files.
    } else {
      const uploadVideoResponse = await dispatch(
        uploadActions.uploadVideoToStorage(
          {
            src: file,
            trimStartMillis: round(clipStartMillis),
            trimEndMillis: round(clipEndMillis),
            language: transcriptionLanguage,
          },
          mediaImportedArgs,
        ),
      );

      videoEntity = uploadVideoResponse.payload.video;
    }

    if (
      millisToSec(videoEntity.get('durationMillis')) >
      videoEntity.get('acceptableFinalDurationSecs')
    ) {
      const shouldContinueWithVideo = await dispatch(
        replaceModal({
          name: 'QualityReductionAlert',
        }),
      );

      if (!shouldContinueWithVideo) {
        dispatch(addingToTrack(trackId));

        dispatch(
          replaceModal({
            name: 'AddMediaModal',
          }),
        );

        return dispatch({
          type: types.EMBED_VIDEO_ADD_FAILURE,
        } as AddVideoFailureAction);
      }
    }

    const videoId = videoEntity.get('id');
    const aspectRatio = aspectRatioDimensionsSelector(getState()).toJS();
    const videoTranscriptStatus = videoEntity.getIn(['transcript', 'status']);
    const audioSrc =
      videoEntity.getIn(['audioExtract', 'status']) === 'audioStreamNotFound'
        ? null
        : videoEntity.getIn(['audioExtract', 'url']);

    if (audioSrc && transcriptionEnabled) {
      dispatch(embedActions.setCaptionsMediaSource('video', videoId));

      dispatch(getVideoTranscript(videoId, videoTranscriptStatus)).then(() =>
        dispatch(setupCaptions(videoId, 'video')).then(({ transcriptId }) => {
          const manualTranscripts = manualTranscriptsSelector(getState());
          const transcript = manualTranscripts.get(transcriptId);
          const revisionId = transcript.get('revisionId');

          const captionsInfo = createWizardCaptions(
            getAspectRatioName(aspectRatio.height, aspectRatio.width),
            videoId,
            'video',
            transcriptId,
            revisionId,
          );
          dispatch(embedActions.deleteCaptionsSource());
          dispatch(embedActions.setCaptionsConfig(captionsInfo));
          dispatch(embedActions.saveConfiguration());
        }),
      );
    }

    const previewThumbnailUrl = videoEntity.getIn([
      'previewThumbnail',
      'thumbnails',
      0,
      'url',
    ]);

    const style = getVideoStyle(
      scaling,
      videoEntity.get('videoWidth'),
      videoEntity.get('videoHeight'),
      aspectRatio,
    );

    // when replacing video, we overwrite some properties to apply them on the new one
    if (mediaToReplaceIsVideo) {
      style.top = replaceVideo.getIn(['position', 'top'], style.top);
      style.left = replaceVideo.getIn(['position', 'left'], style.left);
      style.width = replaceVideo.get('width', style.width);
      style.height = replaceVideo.get('height', style.height);
      style.zoom = replaceVideo.get('zoom', style.zoom);
    }

    const video = videoFactory({
      audioFadeInDurationMillis,
      audioFadeOutDurationMillis,
      audioSrc,
      blurredBackground,
      previewThumbnailUrl,
      scaling,
      startMillis,
      transitionIn,
      transitionOut,
      assetType: assetType === 'gif-video' ? 'gif' : assetType,
      audioLevel: audioLevel / 100,
      endMillis: startMillis + round(clipEndMillis - clipStartMillis),
      height: style.height,
      id: ids.generate(),
      mainAudioLevel: mainAudioLevel / 100,
      playFromMillis: clipStartMillis,
      position: positionFactory({ top: style.top, left: style.left }),
      serverId: videoEntity.get('id'),
      sourceDurationMillis: durationMillis,
      src: getVideoSrc(videoEntity),
      width: style.width,
      zoom: style.zoom,
    });

    if (!hasAllDimensions(video)) {
      const error = new Error('Error adding asset. Please try again');
      Sentry.captureException(error);
      throw error;
    }

    dispatch(addToVideoCollection(video));
    dispatch(updateVideoOrder);
    dispatch(embedActions.addToTrack(trackId, video.id));
    dispatch(embedActions.setEmbedDuration(true));
    dispatch(embedActions.createEmbedConfigurationFromState());

    if (checkMediaToReplaceId) {
      dispatch(removeReplacedTrackElement(mediaToReplaceId));
    }

    return dispatch({
      type: types.EMBED_VIDEO_ADD_SUCCESS,
    } as AddVideoSuccessAction);
  } catch (err) {
    dispatch({ type: types.EMBED_VIDEO_ADD_FAILURE });
    throw err;
  }
};

const getVideoTranscript = (
  videoId: number,
  videoTranscriptStatus,
): ThunkAction<Promise<void>> => dispatch =>
  dispatch(uploadActions.awaitVideoTranscription(videoId))
    .then(() => {
      if (['failed', 'failureAcked'].indexOf(videoTranscriptStatus) >= 0) {
        throw new Error('Video transcription failed.');
      }
    })
    .catch(err => {
      throw err;
    });

export const removeFromVideoTrack = (
  id: string,
): ThunkAction<Promise<RemoveVideoSuccessAction>> => (dispatch, getState) => {
  const videos = embedSelectors.videosByIdSelector(getState());
  const videoServerId = videos.getIn([id, 'serverId']);
  dispatch({
    payload: { id },
    type: types.EMBED_VIDEO_REMOVE_REQUEST,
  });
  dispatch(embedActions.deleteCaptionsSource(videoServerId, 'video'));
  dispatch(embedActions.setEmbedDuration(true));
  return dispatch(embedActions.createEmbedConfigurationFromState())
    .then(() => dispatch({ type: types.EMBED_VIDEO_REMOVE_SUCCESS as any }))
    .catch(e => {
      dispatch({ type: types.EMBED_VIDEO_REMOVE_FAILURE });
      throw e;
    });
};

const getMergedVideoWithCurrentVideo = (currentVideo, video) =>
  currentVideo.mergeWith((oldVal, newVal, key) => {
    if (key === 'audioLevel' || key === 'mainAudioLevel') {
      return _.isUndefined(newVal) ? oldVal : newVal / 100;
    }
    return newVal;
  }, video);

export const updateVideo = (
  id: string,
  video: Partial<IEmbedVideoState>,
): ThunkAction<void> => (dispatch, getState) => {
  const currentVideo = embedSelectors.videosByIdSelector(getState()).get(id);
  const newVideo = getMergedVideoWithCurrentVideo(currentVideo, video);

  dispatch({
    payload: {
      video: newVideo,
    },
    type: types.EMBED_VIDEO_UPDATE,
  });

  dispatch(embedActions.setEmbedDuration(true));
  dispatch(updateVideoOrder);
};

export const saveVideo = (
  id: string,
  video: Partial<IEmbedVideoState>,
): ThunkAction<void> => dispatch => {
  dispatch(updateVideo(id, video));
  dispatch(embedActions.saveConfiguration());
};

const updateVideoOrder = _.debounce(
  (dispatch: Dispatch, getState: GetState) => {
    const videosById = embedSelectors.videosByIdSelector(getState());
    const orderedIds = embedUtils.sortVideos(videosById);

    dispatch({
      payload: {
        videos: orderedIds,
      },
      type: types.EMBED_VIDEO_ORDER_SET,
    });
  },
  250,
);
