import cn from 'classnames';
import * as React from 'react';

import { useSelector } from 'react-redux';
import Spinner from 'react-spinkit';
import { createSelector } from 'reselect';
import useTranscriptProgress from 'blocks/AssetsPane/containers/TranscriptTab/TranscriptStatusDisplay/useTranscriptProgress';
import CircleProgress from 'components/CircleProgress';
import { H1, H2 } from 'components/Heading';
import { autoTranscriptIdSelector } from 'redux/modules/embed/selectors';
import { transcriptsSelector } from 'redux/modules/entities';
import { block } from '../../../utils';

export interface TranscriptInProgressDisplayProps {}

const transcriptProgressSelector = createSelector(
  autoTranscriptIdSelector,
  transcriptsSelector,
  (id, transcripts) =>
    !id || !transcripts
      ? undefined
      : transcripts.getIn([id.toString(), 'progress']),
);

const TranscriptInProgressDisplay: React.FC<TranscriptInProgressDisplayProps> = () => {
  const progress = useSelector(transcriptProgressSelector);
  const fakeProgress = useTranscriptProgress({
    progress,
    interval: 1000,
    incrementBy: 1,
    maxValue: 99,
  });

  return (
    <div className={block('transcript', { 'in-progress': true })}>
      <H1 className={block('transcript-progress-heading')}>
        Your transcript will be ready soon
      </H1>
      {progress === null || progress === undefined ? (
        <Spinner
          className={cn(block('spinner'), 'spinner--large', 'spinner--wave')}
          spinnerName="wave"
        />
      ) : (
        <>
          <H2 className={block('transcript-progress-subheading')}>
            Usually takes 1/2 the time of your source file (
            <a
              href="https://learn.headliner.app/hc/en-us/articles/360050782334-Transcription-overview"
              target="_blank"
              rel="noopener noreferrer"
            >
              learn about our process
            </a>
            ).
          </H2>
          <CircleProgress
            className={block('transcript-progress')}
            percentage={fakeProgress}
          />
        </>
      )}
    </div>
  );
};

export default TranscriptInProgressDisplay;
