import * as React from 'react';
import { useSelector } from 'react-redux';
import Callout from 'components/Callout';
import FontAwesome from 'components/FontAwesome';
import Link from 'components/Link';
import { videoExportEmailEnabledSelector } from 'redux/selectors';
import { getEmailSettingsPath } from 'utils/routes';

interface Props {
  className?: string;
}

const EmailDisabledCallout: React.FC<Props> = props => {
  const isEmailEnabled = useSelector(videoExportEmailEnabledSelector);

  if (isEmailEnabled) {
    return null;
  }

  return (
    <Callout
      {...props}
      icon={
        <FontAwesome icon="envelope" style={{ fontSize: 17, marginLeft: 2 }} />
      }
    >
      Want us to email your finished video?{' '}
      <Link to={getEmailSettingsPath()}>Turn that setting on here!</Link>
    </Callout>
  );
};

export default EmailDisabledCallout;
