/**
 * Autosave redux middleware
 *
 * Allows controlling autosave functionality via the `meta` action attribute.  The `meta` attribute
 * provides save options as well as autosave middelware control.
 *
 * Save options:
 *    - immediate: execute the save operation right now.  if one is in progress, queue this one up
 *      to happen immediately after the current one finishes
 *
 *    - delayed: schedules a save to happen `debounceMillis`in the future.  any non-blacklisted
 *      actions that are dispatched while the save is scheduled will reschedule the save to happen
 *      `debounceMillis` after the non-blacklisted action is seen.
 *
 *    - flush: executes a save immediately IFF a "delayed" save is already scheduled
 *
 * Control options:
 *    - pause: suspends scheduled saves.  any immediate saves are still executed
 *    - resume: resumes scheduled saves
 *
 * Save options can be combined with control options, but only one option of each type is supported.
 *
 * @see utils.autosaveActionBuilder
 */
import createAutosave from './autosave';
import { autosaveActionBuilder } from './utils';

export default createAutosave;
export { autosaveActionBuilder };
