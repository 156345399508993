import React from 'react';
import LoadingMessage from 'components/LoadingMessage';
import { AppsProvider, AppsProviderStatus } from 'redux/modules/apps-connected';
import { RequestStatus } from 'types';
import SettingsContent from '../SettingsContent';
import AppsSettingCard from './components/AppsSettingsCard/AppsSettingsCard';
import AppsSettingsModal from './components/AppsSettingsModal/AppsSettingsModal';
import { block } from './utils/AppsSettingsUtils';

interface AppsSettingsProps {
  appsConnected: {
    providers: AppsProviderStatus[];
    status?: RequestStatus;
  };
  disconnectStatus?: RequestStatus;
  onDisconnect: (provider: AppsProvider) => Promise<void>;
  selectedApp?: AppsProvider;
  setSelectedApp: (provider: AppsProvider) => void;
}

const AppsSettings: React.FunctionComponent<AppsSettingsProps> = props => {
  const {
    appsConnected,
    disconnectStatus,
    onDisconnect,
    selectedApp,
    setSelectedApp,
  } = props;

  const availableApps = appsConnected.providers.filter(app => app.hasAccess);
  const disconnectModalVisible = !!selectedApp;
  const deleteLoading = disconnectStatus === RequestStatus.REQUEST;

  const handleCancelDisconnection = React.useCallback(() => {
    setSelectedApp(undefined);
  }, [setSelectedApp]);

  const handleConfirmDisconnection = React.useCallback(() => {
    onDisconnect(selectedApp);
  }, [onDisconnect, selectedApp]);

  const handleDisconnectApp = React.useCallback(
    (provider: AppsProvider) => {
      setSelectedApp(provider);
    },
    [setSelectedApp],
  );

  const renderAppConnectedCards = React.useCallback(
    (apps: AppsProviderStatus[]) => {
      return apps.map(app => {
        return (
          <AppsSettingCard
            key={app.provider}
            disconnectActionStatus={disconnectStatus}
            isConnected={app.hasAccess}
            onDisconnect={handleDisconnectApp}
            provider={app.provider}
          />
        );
      });
    },
    [disconnectStatus, handleDisconnectApp],
  );

  const renderContent = React.useCallback(():
    | React.ReactElement
    | React.ReactElement[]
    | null => {
    const { status } = appsConnected;
    if (status === RequestStatus.REQUEST) {
      return <LoadingMessage message="Loading connected apps" />;
    }
    if (status) {
      if (!availableApps.length) {
        return <p className={block('empty-state')}>No apps connected</p>;
      }
      return renderAppConnectedCards(availableApps);
    }
    return null;
  }, [appsConnected, availableApps, renderAppConnectedCards]);

  return (
    <>
      <SettingsContent
        className={block('section-card-container')}
        subtitle="Manage apps connected to Headliner"
        title="Apps"
      >
        {renderContent()}
      </SettingsContent>
      <AppsSettingsModal
        loading={deleteLoading}
        onCancel={handleCancelDisconnection}
        onConfirm={handleConfirmDisconnection}
        visible={disconnectModalVisible}
      />
    </>
  );
};

export default AppsSettings;
