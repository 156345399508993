import * as React from 'react';

import { GetItemKey } from '../types';
import { extractItemKey } from '../utils';
import TemplateTabsContent, {
  TemplateTabsContentProps,
} from './TemplateTabsContent';
import TemplateTabsContentItem from './TemplateTabsContentItem';

type PickedContentProps = Pick<
  TemplateTabsContentProps,
  'active' | 'emptyContent'
>;

export interface TemplateTabsGridContentProps extends PickedContentProps {
  getItemKey?: GetItemKey;
  items?: React.ReactElement[];
  tabKey: string;
}

const TemplateTabsGridContent: React.FC<TemplateTabsGridContentProps> = ({
  active,
  emptyContent,
  getItemKey = extractItemKey,
  items,
  tabKey,
}) => (
  <TemplateTabsContent
    itemCount={items?.length ?? 0}
    grid
    {...{ active, emptyContent }}
  >
    {items.map((item, index) => (
      <TemplateTabsContentItem key={getItemKey(index, items, tabKey)}>
        {item}
      </TemplateTabsContentItem>
    ))}
  </TemplateTabsContent>
);

export default TemplateTabsGridContent;
