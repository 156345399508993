import * as React from 'react';

import { podcastSidebarBlock as block } from '../utils';

export interface PodcastSidebarIndicatorProps {
  activeItemIndex?: number;
}

const PodcastSidebarIndicator: React.FC<PodcastSidebarIndicatorProps> = ({
  activeItemIndex,
}) => (
  <div
    className={block('indicator')}
    style={{ transform: `translateY(${activeItemIndex * 100}%)` }}
  >
    <div className={block('indicator-inner')} />
  </div>
);

export default PodcastSidebarIndicator;
