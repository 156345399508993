import * as React from 'react';

import { Flipper } from 'react-flip-toolkit';
import AutomationCMSActionButton from '../containers/AutomationCMSActionButton';
import PodcastSidebarPodcast from '../containers/PodcastSidebarPodcast';
import { PodcastSidebarPodcastProps } from '../containers/PodcastSidebarPodcast/types';
import { podcastSidebarBlock as block } from '../utils';
import AllProjectsButton from './AllProjectsButton';
import PodcastSidebarIndicator from './PodcastSidebarIndicator';
import PodcastSidebarItem from './PodcastSidebarItem';

const { useCallback } = React;

export interface PodcastSidebarProps {
  onSelectPodcast?: PodcastSidebarPodcastProps['onClick'];
  onDeletePodcast?: PodcastSidebarPodcastProps['onDeleteClick'];
  podcasts: string[];
  selectedPodcastIndex: number;
}

const PodcastSidebar: React.FC<PodcastSidebarProps> = ({
  onDeletePodcast,
  onSelectPodcast,
  podcasts,
  selectedPodcastIndex,
}) => {
  const handlePodcastClick = useCallback(
    (id: string) => {
      onSelectPodcast(id);
    },
    [onSelectPodcast],
  );

  return (
    <div className={block()}>
      <PodcastSidebarItem className={block('action-button-item')}>
        <AutomationCMSActionButton />
      </PodcastSidebarItem>
      <Flipper className={block('podcasts')} flipKey={podcasts.length}>
        <PodcastSidebarItem>
          <AllProjectsButton
            onClick={() => onSelectPodcast(null)}
            active={selectedPodcastIndex === 0}
          />
        </PodcastSidebarItem>
        {podcasts.map((id, idx) => (
          <PodcastSidebarPodcast
            active={idx + 1 === selectedPodcastIndex}
            key={id}
            onClick={handlePodcastClick}
            onDeleteClick={onDeletePodcast}
            podcastFeedId={id}
          />
        ))}
        {selectedPodcastIndex > -1 && (
          <PodcastSidebarIndicator activeItemIndex={selectedPodcastIndex} />
        )}
      </Flipper>
    </div>
  );
};

export default PodcastSidebar;
