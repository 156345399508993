import * as React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import EmailVerificationFailureModalContent from './EmailVerificationFailureModalContent';
import { block } from './utils';

const EmailVerificationFailureModal = () => {
  return (
    <ConnectedModal
      name="EmailVerificationFailure"
      title="Verify your email"
      className={block()}
    >
      <EmailVerificationFailureModalContent />
    </ConnectedModal>
  );
};

export default EmailVerificationFailureModal;
