import * as React from 'react';

import { IconProps } from 'components/icons/types';

const Upload: React.FC<IconProps> = ({
  fill = 'currentColor',
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="-2 -3 24 24"
    {...props}
  >
    <path
      fill={fill}
      id="upload-icon"
      d="M19.261 11.49v.005c.407.001.736.33.737.737v5.01a1.228 1.228 0 0 1-1.22 1.215H1.412a1.228 1.228 0 0 1-1.216-1.215v-5.01a.739.739 0 0 1 .74-.737h.923c.407.001.736.33.737.737v3.792h14.968v-3.792a.739.739 0 0 1 .737-.743h.96zM9.305.298A.958.958 0 0 1 9.976 0h.198c.256.002.501.107.68.291L11 .438l4.12 4.132c.34.371.34.94 0 1.311l-.395.34a.97.97 0 0 1-1.311 0l-2.178-2.177v7.661a.954.954 0 0 1-.923.923h-.481a.954.954 0 0 1-.923-.923v-7.67L6.73 6.215a.97.97 0 0 1-1.311 0l-.345-.333a.97.97 0 0 1 0-1.31L9.305.298z"
    />
  </svg>
);

export default Upload;
