import * as React from 'react';

import NewBadge from 'components/NewBadge';
import useProject from 'hooks/useProject';
import ProjectTileControls from '../../components/ProjectTileControls';
import ProjectTileLink from '../../components/ProjectTileLink';
import { ProjectTileControl } from '../../types';
import { block } from '../../utils';
import ProjectTileContents from './ProjectTileContents';
import ProjectTileTooltip from './ProjectTileTooltip';

const { useCallback } = React;

export interface ProjectTileProps {
  onControlClick?: (type: ProjectTileControl, projectId: string) => void;
  projectId: string;
}

function getTooltipMessage(
  isLongProject: boolean,
  isEditable: boolean,
  isGenerating: boolean,
) {
  if (isGenerating) {
    return 'Sit tight! We’ll have your video ready soon.';
  }

  if (isLongProject) {
    return 'Full episodes cannot be edited... yet!';
  }

  if (!isEditable) {
    return 'This project cannot be edited';
  }

  return null;
}

const ProjectTile: React.FC<ProjectTileProps> = ({
  onControlClick,
  projectId,
}) => {
  const project = useProject(projectId);

  const isLongProject = project?.isLongProject;
  const isEditable = project?.isEditable;
  const isGenerating = !isEditable && !project?.lastSuccessfulVideoWid;

  const tooltipContent = getTooltipMessage(
    isLongProject,
    isEditable,
    isGenerating,
  );
  const isDisabled = !!tooltipContent;

  const handleControlClick = useCallback(
    (type: ProjectTileControl) => {
      onControlClick(type, projectId);
    },
    [onControlClick, projectId],
  );

  return (
    <div
      className={block('tile', { disabled: isDisabled })}
      data-joyride-spotlight="copy"
    >
      <ProjectTileTooltip
        {...{ isDisabled, projectId }}
        message={tooltipContent}
      >
        <ProjectTileLink projectId={projectId} disabled={isDisabled}>
          {isGenerating && <NewBadge>Generating</NewBadge>}
          <ProjectTileContents
            projectName={project?.name}
            updatedAtMillis={project?.updatedAtMillis}
          />
        </ProjectTileLink>
      </ProjectTileTooltip>
      <ProjectTileControls
        lastSuccessfulVideoWid={project?.lastSuccessfulVideoWid}
        onClick={handleControlClick}
        disabledCopyButton={isDisabled}
      />
    </div>
  );
};

export default ProjectTile;
