import * as React from 'react';
import Select, { Overrides } from 'components/Select';
import SelectOption from './SelectOption';
import { SelectMultipleOption, SelectMultipleProps } from './types';
import { block } from './utils';

const selectComponentOverrides: Overrides<SelectMultipleOption> = {
  option: {
    component: props => <SelectOption {...props} />,
  },
};

const SelectMultiple: React.FC<SelectMultipleProps> = ({
  onChange,
  onMouseOver,
  onMouseOut,
  value,
  options,
  overrides,
  disabled,
}) => {
  return (
    <Select
      className={block({ disabled })}
      onChange={onChange}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      options={options}
      overrides={{ ...selectComponentOverrides, ...overrides }}
      value={value}
      disabled={disabled}
      keepOpenOnClick
    />
  );
};

export default SelectMultiple;
