import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'MEDIA_SERVICE';
export const ACTION_KEY: ActionKey = 'MEDIA_SERVICE';

export enum ServiceMethod {
  GET_CONTENT_TYPE = 'GET_CONTENT_TYPE',
}

interface IContentType {
  contentType: string;
}

export type GetContentTypeArgs = [string];
export type GetContentTypeResult = IContentType;

export type IHandle = (
  method: ServiceMethod.GET_CONTENT_TYPE,
  args: GetContentTypeArgs,
  token?: string,
) => Promise<GetContentTypeResult>;

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;
export type GetContentTypeAction = ApiAction<
  ServiceMethod.GET_CONTENT_TYPE,
  GetContentTypeArgs
>;

export type MediaServiceDispatch = (
  action: GetContentTypeAction,
) => Promise<IApiResponse<GetContentTypeResult>>;
