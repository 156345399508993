import cn from 'classnames';
import React from 'react';

import DialogContent from 'components/DialogContent';
import Modal, { ModalProps } from 'components/Modal';
import { deleteDynamicImageModalBlock as block } from '../utils';

export interface DeleteDynamicImageModalProps
  extends Pick<ModalProps, 'onExited'> {
  details: React.ReactNode;
  icon: React.ReactNode;
  onCancel?: () => void;
  onSubmit?: () => void;
  show?: boolean;
  title: string;
}

const DeleteDynamicImageModal: React.FC<DeleteDynamicImageModalProps> = ({
  details,
  icon,
  onCancel,
  onExited,
  onSubmit,
  show,
  title,
}) => {
  return (
    <Modal
      className={block()}
      title="episode art"
      show={show}
      onExited={onExited}
      onHide={onCancel}
    >
      <DialogContent
        actions={[
          {
            children: 'cancel',
            fluid: true,
            key: 'cancel',
            theme: 'cancel',
          },
          {
            children: 'continue',
            fluid: true,
            key: 'submit',
            onClick: onSubmit,
            theme: 'submit',
          },
        ]}
        className={block('body')}
        titleClassName={block('title')}
        details={<span className={block('details')}>{details}</span>}
        icon={
          <div className={block('icon-container')}>
            {React.isValidElement(icon) &&
              React.cloneElement(icon, {
                className: cn(icon.props.className, block('icon')),
              })}
          </div>
        }
        onClose={onCancel}
        title={title}
      />
    </Modal>
  );
};

export default DeleteDynamicImageModal;
