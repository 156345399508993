import {
  EpisodeId,
  actions as podcastService,
} from 'redux/middleware/api/podcast-service';
import { getSubscriptionById } from 'redux/middleware/api/podcast-service/actions';
import { actions as recordingUploadService } from 'redux/middleware/api/recording-upload-service';
import { ThunkAction } from 'redux/types';
import { Type } from '../clip-select/action-types';
import { ClipSelectAction } from '../clip-select/types';
import { getMyPreferences } from '../common/actions/preferences';
import { getMyDisplayPref } from '../display-pref/actions';
import { makePodcastEpisodeSelector } from '../entities/selectors';
import { MixpanelAction } from '../mixpanel/types';
import { showError } from '../notification/actions';
import { goToCreate } from '../router';

export const loadClippingData = (
  episodeId: EpisodeId,
  subscriptionId: number,
  subscriptionItemId: number,
): ThunkAction<Promise<void>, ClipSelectAction | MixpanelAction> => async (
  dispatch,
  getState,
) => {
  dispatch({ type: Type.LOAD_DATA_REQUEST });

  try {
    await Promise.all([
      dispatch(getMyDisplayPref()),
      dispatch(podcastService.getEpisodeById(episodeId)),
      dispatch(getSubscriptionById(subscriptionId)),
    ]);

    const episode = makePodcastEpisodeSelector(episodeId)(getState());
    const { entireAudioInstanceId } = episode;

    await dispatch(
      recordingUploadService.getAudioUpload(entireAudioInstanceId),
    );

    dispatch({
      type: Type.LOAD_DATA_SUCCESS,
      payload: {
        episodeId,
        subscriptionId,
        subscriptionItemId,
        entireAudioInstanceId,
      },
    });
  } catch (error) {
    dispatch({ type: Type.LOAD_DATA_FAILURE });
    dispatch(goToCreate());
    dispatch(showError('Error loading episode'));
  }
};

export const selectEpisodeClip = (
  episodeId: number,
  subscriptionItemId: number,
  subscriptionId: number,
  startMillis: number,
  endMillis: number,
  isCaptionEnabled: boolean,
): ThunkAction<
  Promise<void>,
  ClipSelectAction | MixpanelAction
> => async dispatch => {
  try {
    dispatch({ type: Type.CLIP_AUDIO_REQUEST });
    const [{ response }] = await Promise.all([
      dispatch(
        podcastService.createPreSelectVariation(
          episodeId,
          subscriptionItemId,
          subscriptionId,
          startMillis,
          endMillis,
          isCaptionEnabled,
        ),
      ),
      dispatch(getMyPreferences()),
    ]);
    const { autoCreateEpisodeId } = response;
    dispatch({
      type: Type.CLIP_AUDIO_SUCCESS,
      payload: { autoCreateEpisodeId },
    });
  } catch (err) {
    dispatch({ type: Type.CLIP_AUDIO_FAILURE });
    throw err;
  }
};
