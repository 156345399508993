import React from 'react';
import { IconProps } from './types';

export interface AlertIconProps extends IconProps {
  background?: string;
}

const Alert: React.FC<AlertIconProps> = ({
  fill = '#C82828',
  background = '#FFFFFF',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 100 100"
    {...props}
  >
    <defs>
      <filter
        id="r4qdtzi8wa"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" />
      </filter>
      <path
        id="g5drvz5mub"
        d="M100 50c0 27.62-22.388 50-50 50S0 77.62 0 50C0 22.396 22.388 0 50 0s50 22.396 50 50zM50 60.08c-5.122 0-9.274 4.153-9.274 9.275 0 5.122 4.152 9.274 9.274 9.274 5.122 0 9.274-4.152 9.274-9.274 0-5.122-4.152-9.274-9.274-9.274zm-8.805-33.335l1.496 27.42c.07 1.282 1.13 2.287 2.415 2.287h9.788c1.285 0 2.346-1.005 2.415-2.288l1.496-27.42c.076-1.385-1.028-2.55-2.416-2.55H43.61c-1.387 0-2.49 1.165-2.415 2.55z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-950 -149) translate(715 49) translate(235 100)">
            <circle
              cx="50"
              cy="50"
              r="40"
              fill={background}
              filter="url(#r4qdtzi8wa)"
            />
            <g>
              <mask id="3dnr30mr6c" fill="#fff">
                <use xlinkHref="#g5drvz5mub" />
              </mask>
              <use fill="#000" fillRule="nonzero" xlinkHref="#g5drvz5mub" />
              <g fill={fill} mask="url(#3dnr30mr6c)">
                <path d="M0 0H150V150H0z" transform="translate(-25 -25)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Alert;
