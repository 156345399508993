import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from 'components/ConfirmationModal';
import FontAwesome from 'components/FontAwesome';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import Modal from 'components/Modal';
import { Tier } from 'redux/middleware/api/plan-service/types';
import { onUnlockThisFeature } from 'redux/modules/mixpanel/actions';
import { modalParamsSelector } from 'redux/modules/modal';
import { replaceModal } from 'redux/modules/modal/actions';

function WatermarkUpsellModalContent() {
  const dispatch = useDispatch();
  const modalParams = useSelector(modalParamsSelector);

  const handleUpgradeClick = () => {
    dispatch(
      onUnlockThisFeature(modalParams?.unlockThisFeature || 'Watermark'),
    );

    dispatch(
      replaceModal({
        name: 'AddBillingCycle',
        params: {
          tier: Tier.PRO,
          defaultSubscriptionPeriod: 'yearly',
          disableSuccessRedirect: true,
        },
      }),
    );
  };

  return (
    <>
      <ConfirmationModal.Body>
        <ConfirmationModal.Icon>
          <CircularGradient>
            <FontAwesome icon="stamp" size="3x" />
          </CircularGradient>
        </ConfirmationModal.Icon>
        <ConfirmationModal.Title>
          Add watermarks to your videos
        </ConfirmationModal.Title>
        <ConfirmationModal.Content>
          Increase brand awareness across the web by easily adding
          <br />
          logos and other brand assets to each video you create.
        </ConfirmationModal.Content>
      </ConfirmationModal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton theme="rainbow" onClick={handleUpgradeClick}>
            Unlock this feature
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
}

export default WatermarkUpsellModalContent;
