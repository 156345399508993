export enum Type {
  AUTOMATED_WIZARD_CREATE_REQUEST = 'app/automated-wizard/AUTOMATED_WIZARD_CREATE_REQUEST',

  AUTOMATED_WIZARD_CONFIGURATION_CREATE_REQUEST = 'app/automated-wizard/AUTOMATED_WIZARD_CONFIGURATION_CREATE_REQUEST',
  AUTOMATED_WIZARD_CONFIGURATION_CREATE_SUCCESS = 'app/automated-wizard/AUTOMATED_WIZARD_CONFIGURATION_CREATE_SUCCESS',
  AUTOMATED_WIZARD_CONFIGURATION_CREATE_FAILURE = 'app/automated-wizard/AUTOMATED_WIZARD_CONFIGURATION_CREATE_FAILURE',

  AUTOMATED_WIZARD_PROJECT_CREATE_REQUEST = 'app/automated-wizard/AUTOMATED_WIZARD_PROJECT_CREATE_REQUEST',
  AUTOMATED_WIZARD_PROJECT_CREATE_SUCCESS = 'app/automated-wizard/AUTOMATED_WIZARD_PROJECT_CREATE_SUCCESS',
  AUTOMATED_WIZARD_PROJECT_CREATE_FAILURE = 'app/automated-wizard/AUTOMATED_WIZARD_PROJECT_CREATE_FAILURE',

  AUTOMATED_WIZARD_CLEAR = 'app/automated-wizard/AUTOMATED_WIZARD_CLEAR',
}
