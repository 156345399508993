import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import TiktokUploadedModalContent from './TiktokUploadedModalContent';
import { block } from './utils';

const TiktokUploadedModal: React.FC = () => {
  return (
    <ConnectedModal
      name="TiktokUploaded"
      title="Open the tiktok app"
      className={block()}
    >
      <TiktokUploadedModalContent />
    </ConnectedModal>
  );
};

export default TiktokUploadedModal;
