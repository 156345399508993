import cn from 'classnames';
import * as React from 'react';

import { cloneOnlyChild } from 'utils/react';
import { block } from './utils';

interface IProps {
  children: React.ReactElement;
}

const SummaryPanelTextContainer: React.SFC<IProps> = ({ children }) =>
  cloneOnlyChild(children, child => ({
    className: cn(block('text-container'), child.props.className),
  }));

export default SummaryPanelTextContainer;
export { IProps as SummaryPanelTextContainerProps };
