import cn from 'classnames';
import * as React from 'react';
import { isUndefined, noop } from 'underscore';

import { Omit } from 'types';
import SelectInput, { SelectInputProps } from '../SelectInput';
import ToolbarButton from '../ToolbarButton';
import { block } from '../utils';
import NumericSpinners from './NumericSpinners';

type SelectProps = Omit<
  SelectInputProps,
  | 'onChange'
  | 'onControlClick'
  | 'onToggleOpen'
  | 'options'
  | 'renderControlButton'
  | 'value'
>;

interface IProps extends SelectProps {
  args?: any;
  className?: string;
  disabled?: boolean;
  displayValues?: number[];
  maxValue?: number;
  minValue?: number;
  onChange?: (val: number, args: any) => void;
  onControlClick?: (args: any) => void;
  onToggleOpen?: (isOpen: boolean, args: any) => void;
  spinButtons?: boolean;
  value?: number;
}

export default class NumericSelect extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    displayValues: [],
    onChange: noop,
    onControlClick: noop,
    onToggleOpen: noop,
  };

  private handleChange = (val: number) =>
    this.props.onChange(val, this.props.args);

  private handleUpClick = () => {
    const { args, value, maxValue, onChange } = this.props;

    const newValue = value + 1;
    if (newValue <= maxValue) {
      onChange(newValue, args);
    }
    this.handleToggleOpen(false);
  };

  private handleDownClick = () => {
    const { args, value, minValue, onChange } = this.props;

    const newValue = value - 1;
    if (newValue >= minValue) {
      onChange(newValue, args);
    }
    this.handleToggleOpen(false);
  };

  private handleToggleOpen = (isOpen: boolean) =>
    this.props.onToggleOpen(isOpen, this.props.args);

  private handleControlClick = () => this.props.onControlClick(this.props.args);

  private getSpinButtonDisabledState() {
    const { disabled, maxValue, minValue, value } = this.props;

    if (disabled) return 'both';
    if (value === maxValue) return 'up';
    if (value === minValue) return 'down';

    return undefined;
  }

  public render() {
    const {
      className,
      disabled,
      displayValues,
      isOpen,
      spinButtons,
      value,
    } = this.props;

    return (
      <SelectInput
        className={cn(
          block('numeric-select', { spin: spinButtons }),
          className,
        )}
        disabled={disabled}
        isOpen={isOpen}
        onChange={this.handleChange}
        optionClassName={block('numeric-select-option')}
        options={displayValues.map(val => ({ label: val, value: val }))}
        onControlClick={this.handleControlClick}
        onToggleOpen={this.handleToggleOpen}
        value={value}
        renderControlButton={({ buttonProps, caret }) => (
          <div className={block('numeric-select-control')}>
            {spinButtons && (
              <NumericSpinners
                disabled={this.getSpinButtonDisabledState()}
                onDownClick={this.handleDownClick}
                onUpClick={this.handleUpClick}
              />
            )}
            <ToolbarButton {...buttonProps}>
              <span className={block('numeric-select-value')}>
                {isUndefined(value) ? '--' : value}
              </span>
              <div className={block('numeric-select-caret')}>{caret}</div>
            </ToolbarButton>
          </div>
        )}
      />
    );
  }
}

export { IProps as NumericSelectProps };
