import * as React from 'react';
import { noop } from 'underscore';

import LinkButton from 'components/LinkButton';
import Modal from 'components/Modal';
import PostTask from '../../components/PostTask';
import { PostTaskOverlayProps } from '../../types';
import { blockOverlay } from '../../utils';

const { useCallback } = React;

const PostTaskOverlay: React.SFC<PostTaskOverlayProps> = ({
  isVisible,
  onClickDismissPostTaskPrompt,
  onHide,
  subTitle,
  title,
}) => {
  const handleDismiss = useCallback(() => {
    onClickDismissPostTaskPrompt();
    onHide();
  }, [onClickDismissPostTaskPrompt, onHide]);

  return (
    <Modal
      backdrop="static"
      show={isVisible}
      backdropClassName={blockOverlay('backdrop')}
      className={blockOverlay()}
    >
      <div>
        <PostTask title={title} subTitle={subTitle} />

        <LinkButton
          onClick={handleDismiss}
          className={blockOverlay('dismiss-action')}
        >
          No thanks
        </LinkButton>
      </div>
    </Modal>
  );
};

PostTaskOverlay.defaultProps = {
  onClickDismissPostTaskPrompt: noop,
  onHide: noop,
};

export default PostTaskOverlay;
