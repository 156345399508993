import cn from 'classnames';
import dayjs from 'dayjs';
import * as React from 'react';
import GradientBorder from 'components/GradientBorder';
import { ArrowRight } from 'components/icons';
import { block, hasTimeRemaining, timeRemainingFormat } from './utils';

const { useMemo } = React;

export interface Props {
  remainingMillis: number;
  className?: string;
  onClick: (event: React.MouseEvent) => void;
}

const TranscriptionTimeRemaining = (props: Props) => {
  const { remainingMillis, className, onClick } = props;
  const remainingDuration = useMemo(() => dayjs.duration(remainingMillis), [
    remainingMillis,
  ]);
  const formattedRemainingTime = useMemo(
    () => remainingDuration.format(timeRemainingFormat(remainingDuration)),
    [remainingDuration],
  );

  const UpgradeLink = useMemo(
    () => ({ children, className: linkClassName }) => (
      <a className={linkClassName} onClick={onClick}>
        {children}
      </a>
    ),
    [onClick],
  );

  return (
    <GradientBorder as={UpgradeLink} className={cn(block(), className)}>
      <div className={block('container')}>
        <span className={block('time-remaining-message')}>
          {hasTimeRemaining(remainingDuration) ? (
            <>
              Free transcription this month:{' '}
              <span className={block('time')}>{formattedRemainingTime}</span>
            </>
          ) : (
            <span className={block('time')}>
              Out of free transcription this month
            </span>
          )}
        </span>
        <span className={block('upgrade-label')}>
          GET UNLIMITED TRANSCRIPTION{' '}
          <ArrowRight style={{ marginLeft: 5, width: 11 }} />
        </span>
      </div>
    </GradientBorder>
  );
};

export default TranscriptionTimeRemaining;
