import Immutable from 'immutable';
import { createTransform } from 'redux-persist-immutable';

/**
 * A very simple port of https://github.com/edy/redux-persist-transform-filter to work with
 * Immutable JS
 */
export function persistFilter(
  state,
  statePaths = [],
  transformType = 'whitelist',
) {
  // support for only one key
  const paths = typeof statePaths === 'string' ? [statePaths] : statePaths;

  if (transformType === 'whitelist') {
    return paths.reduce((subset, path) => {
      const pathState = state.get(path);
      return pathState ? subset.set(path, pathState) : subset;
    }, Immutable.Map());
  }

  if (transformType === 'blacklist') {
    return paths.reduce((subset, path) => subset.delete(path), state);
  }

  return state;
}

export default function createFilter(
  reducerName,
  inboundPaths,
  outboundPaths,
  transformType = 'whitelist',
) {
  return createTransform(
    // inbound
    inboundState =>
      inboundPaths
        ? persistFilter(inboundState, inboundPaths, transformType)
        : inboundState,

    // outbound
    outboundState =>
      outboundPaths
        ? persistFilter(outboundState, outboundPaths, transformType)
        : outboundState,

    { whitelist: [reducerName] },
  );
}

export function createWhitelistFilter(
  reducerName,
  inboundPaths,
  outboundPaths,
) {
  return createFilter(reducerName, inboundPaths, outboundPaths, 'whitelist');
}

export function createBlacklistFilter(
  reducerName,
  inboundPaths,
  outboundPaths,
) {
  return createFilter(reducerName, inboundPaths, outboundPaths, 'blacklist');
}
