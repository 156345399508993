import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PodcastSearch from 'blocks/PodcastSearch';
import useDefaultIntegration from 'blocks/PodcastSearch/useDefaultIntegration';
import useRssIntegration from 'blocks/PodcastSearch/useRssIntegration';
import LinkButton from 'components/LinkButton';
import { onSkipOnboardingFeedCollect } from 'redux/modules/mixpanel/actions';
import { markPodcastDefault } from 'redux/modules/podcast-search/actions';
import { searchTermSelector } from 'redux/modules/podcast-search/selectors';
import { getAudiogramWizardPath } from 'utils/routes';
import { feedCollectBlock as block } from '../../utils';
import CardWizardStep from '../CardWizard/CardWizardStep';

interface Props {
  onPodcastSelect: (podcastId: string) => void;
  onSkip: (event: React.MouseEvent) => void;
  onBack: (event: React.MouseEvent) => void;
}

const useSkipAvailable = () => {
  const query = useSelector(searchTermSelector);
  const allowSkip = useRef(false);

  if (query) {
    allowSkip.current = true;
  }

  return allowSkip.current;
};

function FeedCollectStep(props: Props) {
  const { onPodcastSelect, onSkip, onBack } = props;
  const dispatch = useDispatch();
  const allowSkip = useSkipAvailable();

  const handlePodcastClick = (podcastId: string) => {
    onPodcastSelect(podcastId);
    dispatch(markPodcastDefault(podcastId));
  };

  const handleSkip = (event: React.MouseEvent) => {
    dispatch(onSkipOnboardingFeedCollect());
    onSkip?.(event);
  };

  return (
    <CardWizardStep className={block()}>
      <CardWizardStep.H1>Let’s add a podcast</CardWizardStep.H1>
      <LinkButton
        onClick={onBack}
        theme="cta"
        uppercase
        size="md"
        className={block('back-link')}
      >
        ← return to previous step
      </LinkButton>
      <PodcastSearch
        light
        className={block('podcast-search')}
        integrations={[
          useDefaultIntegration({
            episodeExplorerEnabled: false,
            feedCollectionEnabled: false,
            onPodcastClick: handlePodcastClick,
            priority: 1,
          }),
          useRssIntegration({
            episodeExplorerEnabled: false,
            feedCollectionEnabled: false,
            onPodcastClick: handlePodcastClick,
            priority: 0,
          }),
        ]}
      />
      {allowSkip && (
        <div className={block('footer')}>
          <Link
            className={block('footer-link')}
            onClick={handleSkip}
            to={getAudiogramWizardPath()}
          >
            skip for now
          </Link>
        </div>
      )}
    </CardWizardStep>
  );
}

export default FeedCollectStep;
