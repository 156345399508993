import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import AutomationSuccessStep from 'blocks/AutomationWorkflowWizard/AutomationSuccessStep';
import { episodeWizardBackCatalogFinalMessage as block } from './utils';

interface EpisodeWizardBackCatalogFinalMessageProps {
  isAutopostYoutube: boolean;
  podcastId: string;
}

const EpisodeWizardBackCatalogFinalMessage: React.FC<EpisodeWizardBackCatalogFinalMessageProps> = ({
  isAutopostYoutube,
  podcastId,
}) => {
  return (
    <Spacer
      orientation="vertical"
      align="center"
      justify="space-between"
      space={2.5}
      className={block()}
    >
      <AutomationSuccessStep
        autopost={isAutopostYoutube}
        autopostPlatform={isAutopostYoutube ? 'youtube' : undefined}
        podcastId={podcastId}
      />
    </Spacer>
  );
};

export default EpisodeWizardBackCatalogFinalMessage;
