import * as React from 'react';
import _ from 'underscore';

import LoadingOverlay from 'components/LoadingOverlay';
import LogoPreview, { IProps as PreviewProps } from './LogoPreview';

export interface IProps
  extends Pick<
    PreviewProps,
    | 'aspectRatio'
    | 'logoImageSrc'
    | 'onChangeWatermarkPosition'
    | 'onChangeWatermarkSize'
    | 'watermark'
  > {
  showLoadingOverlay?: boolean;
}

const EditLogoModalBody: React.SFC<IProps> = ({
  aspectRatio,
  logoImageSrc,
  onChangeWatermarkPosition,
  onChangeWatermarkSize,
  showLoadingOverlay,
  watermark,
}) => {
  const className = 'logo-modal__delete-logo-body';

  return (
    <div className={className}>
      {showLoadingOverlay && (
        <LoadingOverlay title="Removing logo from video" />
      )}
      <LogoPreview
        aspectRatio={aspectRatio}
        logoImageSrc={logoImageSrc}
        onChangeWatermarkPosition={onChangeWatermarkPosition}
        onChangeWatermarkSize={onChangeWatermarkSize}
        watermark={watermark}
      />
    </div>
  );
};

EditLogoModalBody.defaultProps = {
  onChangeWatermarkPosition: _.noop,
  onChangeWatermarkSize: _.noop,
  showLoadingOverlay: false,
  watermark: null,
};

export default EditLogoModalBody;
