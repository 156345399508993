import * as React from 'react';

import { useDispatch } from 'react-redux';
import PaidSubscription, {
  basicFeatures,
  PaidSubscriptionHeader,
} from 'blocks/Pricing/components/PaidSubscription';
import { BasicIcon } from 'components/icons';
import { Tier } from 'redux/middleware/api/plan-service/types';
import { pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import PausedSubscriptionInfo from '../PausedSubscriptionInfo';
import usePausedSubscriptionInfo from '../PausedSubscriptionInfo/usePausedSubscriptionInfo';
import PaymentDetailsGate from '../PaymentDetailsGate';
import PaymentInfo from '../PaymentInfo';
import TierUpgradeBanner from '../TierUpgradeBanner';

export interface BasicSubscriptionProps {}

const BasicSubscription: React.FC<BasicSubscriptionProps> = () => {
  const dispatch = useDispatch<Dispatch>();
  const { showPausedInfo, title, subtitle } = usePausedSubscriptionInfo();

  return (
    <>
      <PaidSubscription>
        <PaidSubscriptionHeader icon={<BasicIcon />} title="Headliner Basic">
          <PaidSubscriptionHeader.Features features={basicFeatures} />
          <TierUpgradeBanner
            source="BasicMyPlanPage"
            modal="UpgradePlan"
            targetTier={Tier.PRO}
          />
        </PaidSubscriptionHeader>
        <PaidSubscription.Body>
          <PaymentDetailsGate>
            {showPausedInfo ? (
              <PausedSubscriptionInfo title={title} subtitle={subtitle} />
            ) : (
              <PaymentInfo
                onUpdatePlanClick={() =>
                  dispatch(pushModal({ name: 'AdjustPlan' }))
                }
              />
            )}
          </PaymentDetailsGate>
        </PaidSubscription.Body>
      </PaidSubscription>
    </>
  );
};

export default BasicSubscription;
