import * as React from 'react';

import { IconProps } from './types';

const Error: React.FC<IconProps> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd" {...props}>
      <circle fill="fill" cx="15" cy="15" r="15" />
      <path
        d="m19 11-8 8m0-8 8 8"
        stroke="#FFF"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default Error;
