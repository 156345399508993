import cn from 'classnames';
import React from 'react';
import FadeTransition from 'components/FadeTransition';
import { block } from './utils';

interface Props {
  visible: boolean;
  children: React.ReactNode;
  className?: string;
}

function NonIdealStateOverlay(props: Props) {
  const { visible, children, className } = props;
  return (
    <FadeTransition in={visible} unmountOnExit mountOnEnter>
      <div className={cn(className, block('overlay'))}>{children}</div>
    </FadeTransition>
  );
}

export default NonIdealStateOverlay;
