import bem from 'utils/bem';

export const block = bem('text-highlight-picker-v2');

const COLOR_PICKER_TRIGGER_VERTICAL_DIST = 10;
const COLOR_PICKER_TRIGGER_CENTER_COEF = -15;

export const calculateColorPickerPosition = (
  currTopPos: number,
  swatchRect: DOMRect,
  popoverHeight: number,
  exceedsViewportHeight: boolean,
) => {
  const top = exceedsViewportHeight
    ? currTopPos -
      (swatchRect.height + popoverHeight + COLOR_PICKER_TRIGGER_VERTICAL_DIST)
    : currTopPos;
  return {
    top,
    left: swatchRect.left + COLOR_PICKER_TRIGGER_CENTER_COEF,
  };
};
