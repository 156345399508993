import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { Tier } from 'redux/middleware/api/plan-service';
import { subscriptionSelector } from 'redux/modules/pricing';
import PlanAction from '../PlanAction';
import { freePlanBlock as block, planIsCancelled } from '../utils';

export interface FreePlanCtaProps {
  children?: React.ReactNode;
  className?: string;
}

const FreePlanCta: React.FC<FreePlanCtaProps> = ({ className, children }) => {
  const subscription = useSelector(subscriptionSelector);
  const getLabel = () => {
    if (
      planIsCancelled(Tier.BASIC, subscription) ||
      planIsCancelled(Tier.PRO, subscription) ||
      subscription.subtier === 'proTrial'
    ) {
      return 'your upcoming plan';
    }
    return 'your current plan';
  };
  return (
    <PlanAction className={cn(block('cta'), className)}>
      <div>{children ?? getLabel()}</div>
    </PlanAction>
  );
};

export default FreePlanCta;
