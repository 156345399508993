import cn from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import CloseIconButton from 'components/CloseIconButton';
import { hideModal } from 'redux/modules/modal';
import ModalHeading, { ModalHeadingProps } from './ModalHeading';
import { block } from './utils';

export interface ModalHeaderProps
  extends Pick<ModalHeadingProps, 'title' | 'subtitle'> {
  className?: string;
  icon?: React.ReactNode;
  onHide?: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
  className,
  icon,
  onHide,
  subtitle,
  title,
}) => {
  const dispatch = useDispatch();

  const handleHide = React.useCallback(() => {
    if (onHide) {
      onHide();
    } else {
      dispatch(hideModal());
    }
  }, [dispatch, onHide]);

  return (
    <div className={cn(block('header'), className)}>
      <CloseIconButton className={block('close-button')} onClick={handleHide} />
      <ModalHeading {...{ icon, title, subtitle }} />
    </div>
  );
};

export default ModalHeader;
