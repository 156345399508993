import * as React from 'react';
import { noop } from 'underscore';

import { ModalManagerContextType } from './types';

const { useContext, useMemo } = React;

export interface ModalManagerProviderProps extends ModalManagerContextType {
  children?: React.ReactNode;
}

const ModalManagerContext = React.createContext<ModalManagerContextType>({
  hideModal: noop,
  showModal: noop,
});

export function useModalManager() {
  return useContext(ModalManagerContext);
}

export const ModalManagerProvider: React.FC<ModalManagerProviderProps> = ({
  children,
  hideModal,
  showModal,
}) => {
  const contextValue = useMemo(
    () => ({
      hideModal,
      showModal,
    }),
    [hideModal, showModal],
  );

  return (
    <ModalManagerContext.Provider value={contextValue}>
      {children}
    </ModalManagerContext.Provider>
  );
};
