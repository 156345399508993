import * as React from 'react';

import { IconProps } from './types';

const PaintCan: React.SFC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 77" {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      stroke="#14191E"
      strokeWidth="2"
      d="M77.444 47.875s-9.555 13.572-9.555 18.75c0 5.178 4.279 9.375 9.555 9.375C82.721 76 87 71.803 87 66.625s-9.556-18.75-9.556-18.75zm-1.399-15.08L45.036 2.374A4.81 4.81 0 0 0 41.657 1a4.804 4.804 0 0 0-3.377 1.373L26.1 14.323 13.231 1.697a2.42 2.42 0 0 0-3.377 0L6.478 5.011a2.313 2.313 0 0 0 0 3.313l12.868 12.624L5.198 34.83a13.873 13.873 0 0 0 0 19.885l17.497 17.167C25.493 74.626 29.162 76 32.83 76c3.668 0 7.335-1.373 10.133-4.12l33.082-32.456a4.625 4.625 0 0 0 .001-6.628zM58.693 43.193h-47.85a4.535 4.535 0 0 1 1.11-1.733l1.963-1.926 12.185-11.955 8.75 8.584c1.864 1.83 4.89 1.83 6.754 0a4.624 4.624 0 0 0 0-6.627l-8.75-8.584 8.802-8.635L65.91 36.11l-7.217 7.08z"
    />
  </svg>
);

export default PaintCan;
