import { useColorPaletteState } from '@sparemin/blockhead';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/Modal';
import { MediaType, TabName } from 'containers/AddMediaModal';
import { imageTypeOptions } from 'containers/AddMediaModal/MediaImport/AIGeneration/constants';
import MediaImport from 'containers/AddMediaModal/MediaImport/MediaImport';
import { ContentTypeStatus } from 'containers/AddMediaModal/MediaImport/types';
import useMediaSearch from 'hooks/useMediaSearch';
import { AiImageType } from 'redux/middleware/api/image-search-service';
import { isPollingImageToVideoSelector } from 'redux/modules/image-to-video/selectors';
import {
  getContentTypeSelector,
  getContentTypeStatusSelector,
} from 'redux/modules/media/selectors';
import { onClickSearchGif, onClickSearchImage } from 'redux/modules/mixpanel';

import { videoUploadLimitMbSelector } from 'redux/modules/pricing';
import { AspectRatioName } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { block } from './utils';

export interface VideoTemplateAddMediaModalContentProps {
  aspectRatio: number;
  onAnimateGatedClick?: () => void;
  onSubmit: (
    result:
      | {
          asset: string;
          isUrl: true;
          type: MediaType;
        }
      | {
          isUrl?: false;
          asset: File;
        },
  ) => void;
}

const VideoTemplateAddMediaModalContent: React.FunctionComponent<VideoTemplateAddMediaModalContentProps> = props => {
  const { aspectRatio, onAnimateGatedClick, onSubmit } = props;

  const [searchInputValue, setSearchInputValue] = useState('');
  const [aspectRatioName, setAspectRatioName] = React.useState<AspectRatioName>(
    getAspectRatioName(aspectRatio),
  );
  const [imageType, setImageType] = React.useState<AiImageType>(
    imageTypeOptions[0].value,
  );
  const colorPaletteState = useColorPaletteState();
  const isPollingImageToVideo = useSelector(isPollingImageToVideoSelector);

  const contentType: string = useSelector(getContentTypeSelector);
  const contentTypeStatus: ContentTypeStatus = useSelector(
    getContentTypeStatusSelector,
  );
  const maxFileSizeMb: number = useSelector(videoUploadLimitMbSelector);

  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = React.useState<TabName>('image');

  const {
    onSelectMediaType,
    onSelectSearchEngine,
    onSubmitSearch,
    selectedGifSearchEngine,
    selectedImgSearchEngine,
    selectedVideoSearchEngine,
  } = useMediaSearch({
    aspectRatioName,
    imageType,
    colorPaletteState,
  });

  const handleFileAccepted = React.useCallback(
    (file: File, type: MediaType) => {
      onSubmit({
        asset: file,
        type,
      });
    },
    [onSubmit],
  );

  const handleGifClick = React.useCallback(
    (url, _, engine) => {
      dispatch(onClickSearchGif(engine, url));

      onSubmit({
        asset: url,
        isUrl: true,
        type: 'gif-video',
      });
    },
    [dispatch, onSubmit],
  );

  const handleAiAssetClick = React.useCallback(
    (url, _) => {
      onSubmit({
        asset: url,
        isUrl: true,
        type: 'ai-asset',
      });
    },
    [onSubmit],
  );

  const handleImageClick = React.useCallback(
    (imageUrl, _, engine) => {
      dispatch(onClickSearchImage(engine, imageUrl));

      onSubmit({
        asset: imageUrl,
        isUrl: true,
        type: 'image',
      });
    },
    [dispatch, onSubmit],
  );

  const handleVideoClick = React.useCallback(
    (url, _, engine) => {
      dispatch(onClickSearchImage(engine, url));

      onSubmit({
        asset: url,
        isUrl: true,
        type: 'video',
      });
    },
    [dispatch, onSubmit],
  );

  const handleUrlAccepted = React.useCallback(
    (url: string, type: MediaType) => {
      onSubmit({
        asset: url,
        isUrl: true,
        type,
      });
    },
    [onSubmit],
  );

  return (
    <>
      <Modal.Body className={block('body')}>
        <MediaImport
          {...{
            aspectRatio,
            contentType,
            contentTypeStatus,
            maxFileSizeMb,
            isPollingImageToVideo,
          }}
          disableAudioUploadSuggestion
          isControlledModal
          onFileAccepted={handleFileAccepted}
          onGifClick={handleGifClick}
          onAiAssetClick={handleAiAssetClick}
          onImageClick={handleImageClick}
          onVideoClick={handleVideoClick}
          onMediaTypeSelect={onSelectMediaType}
          onSearchEngineSelect={onSelectSearchEngine}
          onSearchSubmit={onSubmitSearch}
          onUrlAccepted={handleUrlAccepted}
          onTabSelect={setSelectedTab}
          selectedTab={selectedTab}
          selectedGifEngine={selectedGifSearchEngine}
          selectedImageEngine={selectedImgSearchEngine}
          selectedVideoEngine={selectedVideoSearchEngine}
          aspectRatioName={aspectRatioName}
          searchInputValue={searchInputValue}
          imageType={imageType}
          colorPaletteState={colorPaletteState}
          onSearchInputChange={setSearchInputValue}
          onAspectRatioNameChange={setAspectRatioName}
          onImageTypeChange={setImageType}
          onAnimateGatedClick={onAnimateGatedClick}
        />
      </Modal.Body>
      <Modal.Footer className={block('footer')} />
    </>
  );
};

export default VideoTemplateAddMediaModalContent;
