import * as React from 'react';
import { ProFeatureTooltipContent } from 'blocks/Pricing';
import { FormField } from 'components/Form';
import ToggleButtonGroup, { ToggleButton } from 'components/ToggleButtonGroup';
import Tooltip from 'components/Tooltip';
import ProIndicator, { ProBadge } from 'containers/ProIndicator';
import { SubTier } from 'redux/middleware/api/plan-service';
import { block } from './utils';
import VideoExportFormField from './VideoExportFormField';

enum ExportSpeedOption {
  NORMAL = 'normal',
  FAST = 'fast',
}

interface Props {
  onChange: (value: ExportSpeedOption) => void;
  label: string;
  tooltipId: string;
}

const ExportSpeedField: React.FC<Props> = props => {
  const { onChange, label, tooltipId } = props;
  return (
    <ProIndicator
      blacklist={[
        SubTier.FREE,
        SubTier.EDUCATION_NONPROFIT,
        SubTier.BASIC_PAUSED,
        SubTier.PRO_PAUSED,
      ]}
    >
      {({ isFree }) => (
        <VideoExportFormField xs={12}>
          <FormField {...{ label }}>
            {isFree ? (
              <ToggleButtonGroup<ExportSpeedOption>
                id="export-speed-group"
                value={ExportSpeedOption.NORMAL}
                onChange={onChange}
              >
                <ToggleButton name="speed" value={ExportSpeedOption.NORMAL}>
                  Normal
                </ToggleButton>
                <Tooltip
                  id={tooltipId}
                  placement="top"
                  className={block('speed-tooltip')}
                  content={ProFeatureTooltipContent}
                >
                  <ToggleButton name="speed" value={ExportSpeedOption.FAST}>
                    <span>Priority</span>
                    <ProBadge />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            ) : (
              <div className={block('callout')}>
                <b>PRIORITY</b>: your video will get processed immediately!
              </div>
            )}
          </FormField>
        </VideoExportFormField>
      )}
    </ProIndicator>
  );
};

export default ExportSpeedField;
