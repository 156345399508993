import * as React from 'react';

import ClippingOptions, {
  ClippingOptionsProps,
} from 'components/ClippingOptions';
import { Omit } from 'types';
import VideoOptionsSection from './VideoOptionsSection';

interface IProps extends Omit<ClippingOptionsProps, 'onBlur'> {
  onBlur?: (startMillis: number, endMillis: number) => void;
}

export default class ClippingSection extends React.Component<IProps> {
  private handleBlur: ClippingOptionsProps['onBlur'] = (val, field) => {
    const { endMillis, onBlur, startMillis } = this.props;

    switch (field) {
      case 'start':
        onBlur(val, endMillis);
        break;

      case 'end':
        onBlur(startMillis, val);
        break;

      case 'duration':
        onBlur(startMillis, startMillis + val);
        break;

      default:
    }
  };

  public render() {
    const { onBlur, ...props } = this.props;

    return (
      <VideoOptionsSection title="Clip Time">
        <ClippingOptions {...props} onBlur={this.handleBlur} />
      </VideoOptionsSection>
    );
  }
}

export { IProps as ClippingSectionProps };
