import { List } from 'immutable';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { selectTrackElement } from 'redux/modules/embed/actions/tracks';
import {
  aspectRatioSelector,
  embedSlideIdsSelector,
  tracksByIdSelector,
  videosByIdSelector,
} from 'redux/modules/embed/selectors';
import { pushModal } from 'redux/modules/modal/actions';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import MediaTrack, { MediaTrackProps as Props } from './MediaTrack';

type StateProps = Pick<Props, 'assets' | 'projectAspectRatio'>;
type DispatchProps = Pick<Props, 'onAssetClick'>;
type OwnProps = Pick<Props, 'id'>;

const trackIdSelector = (_, props: OwnProps) => props.id;

const slideIdsSelector = createSelector(
  // FIXME embedSlideIds in redux should be an immutable list, not an array
  embedSlideIdsSelector,
  (ids = []) => new Set(ids),
);

const gifIdsSelector = createSelector(videosByIdSelector, videos => {
  const gifIds = videos
    .filter(v => v.assetType === 'gif')
    .keySeq()
    .toJS();
  return new Set(gifIds);
});

const makeMapStateToProps = () => {
  const trackAssetIdsSelector = createSelector(
    [tracksByIdSelector, trackIdSelector],
    (tracks, trackId) => tracks.getIn([trackId, 'data'], List()).toJS(),
  );

  const assetsSelector = createSelector(
    [trackAssetIdsSelector, slideIdsSelector, gifIdsSelector],
    (assetIds, slideIds, gifIds) =>
      assetIds.map(id => {
        if (slideIds.has(id)) {
          return { id, type: 'image' };
        }

        if (gifIds.has(id)) {
          return { id, type: 'gif-video' };
        }

        return { id, type: 'video' };
      }),
  );

  return (state: State, props: OwnProps): StateProps => ({
    assets: assetsSelector(state, props),
    projectAspectRatio: aspectRatioSelector(state),
  });
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onAssetClick: (id, type) => {
    dispatch(selectTrackElement(id, props.id));

    if (type === 'image') {
      dispatch(pushModal({ name: 'EditSlideModal' }));
    } else {
      dispatch(pushModal({ name: 'EditVideoModal' }));
    }
  },
});

const component = connect(makeMapStateToProps, mapDispatchToProps)(MediaTrack);

export default component;
export type MediaTrackProps = PropsOf<typeof component>;
