import * as React from 'react';

import { Omit } from 'types';
import TextBoxColorField, { TextBoxColorFieldProps } from './TextBoxColorField';

type Props = Omit<TextBoxColorFieldProps, 'label'>;

const HighlightField: React.SFC<Props> = props => (
  <TextBoxColorField label="Text Highlight Color" {...props} />
);

export default HighlightField;
export { Props as HighlightFieldProps };
