import { AudioSelectorMethod } from 'redux/middleware/api/headliner-user-service';
import { WizardType } from 'types';
import bem from 'utils/bem';

import { TabKey } from './AddAudioStep';

export const block = bem('add-audio-step');

const PLAN_AUDIO_MAX_DURATION_USAGE_WIZARD_TYPES = ['episode', 'audiogram'];

export const AUDIO_SELECTOR_TO_TAB_KEY: Record<AudioSelectorMethod, TabKey> = {
  podcastSearch: 'search',
  upload: 'upload',
};

export const checkPlanEpisodeMaxDurationUsage = (
  wizardType: WizardType,
): boolean => PLAN_AUDIO_MAX_DURATION_USAGE_WIZARD_TYPES.includes(wizardType);
