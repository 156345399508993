import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import useRedirectOnClose from 'hooks/useRedirectOnClose';
import { ClickSaveTemplateFrom } from 'redux/modules/mixpanel/types';
import SaveAsTemplateModalContents from './SaveAsTemplateModalContents';
import { block } from './utils';

interface SaveAsTemplateModalProps {
  onSave: (displayName: string) => void;
  disabled?: boolean;
  from?: ClickSaveTemplateFrom;
  useMockAudioForPreview?: boolean;
}

const SaveAsTemplateModal: React.FC<SaveAsTemplateModalProps> = props => {
  const { onSave, disabled } = props;
  const { onExited } = useRedirectOnClose();

  return (
    <ConnectedModal
      name="SaveAsTemplate"
      title="Save as a template"
      className={block()}
      onExited={onExited}
    >
      <SaveAsTemplateModalContents onSave={onSave} disabled={disabled} />
    </ConnectedModal>
  );
};

export default SaveAsTemplateModal;
