import { Heading, Search, Spacer, UnstyledButton } from '@sparemin/blockhead';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  defaultPodcastIdSelector,
  makePodcastArtworkSelector,
  makePodcastTitleSelector,
} from 'redux/modules/podcast-search';
import { block } from './utils';

export interface DefaultPodcastProps {
  onPress: () => void;
}

const DefaultPodcast: React.FC<DefaultPodcastProps> = ({ onPress }) => {
  const defaultPodcastId = useSelector(defaultPodcastIdSelector);
  const artwork = useSelector(makePodcastArtworkSelector(defaultPodcastId));
  const title = useSelector(makePodcastTitleSelector(defaultPodcastId));

  return (
    <UnstyledButton
      className={block('default-podcast-button')}
      onPress={onPress}
    >
      <Spacer align="center" space={2} className={block('podcast-info')}>
        <img src={artwork} alt={title} className={block('artwork')} />

        <Heading level={3}>{title}</Heading>
      </Spacer>

      <div className={block('search-icon-wrapper')}>
        <Search width={12} height={12} />
      </div>
    </UnstyledButton>
  );
};

export default DefaultPodcast;
