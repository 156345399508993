import * as React from 'react';

import { SelectField, SelectFieldProps } from 'components/Form';
import { videoTransitionTypes } from 'utils/embed/video';
import VideoOptionsField from './VideoOptionsField';

type Props = Pick<SelectFieldProps, 'value' | 'onChange' | 'label'>;

function getTransitionLabel(type: string) {
  switch (type) {
    case videoTransitionTypes.FADE:
      return 'Dissolve';
    case videoTransitionTypes.CUT:
      return 'Cut';
    case videoTransitionTypes.SLIDE_DOWN:
      return 'Slide Down';
    case videoTransitionTypes.SLIDE_UP:
      return 'Slide Up';
    case videoTransitionTypes.SLIDE_LEFT:
      return 'Slide Left';
    case videoTransitionTypes.SLIDE_RIGHT:
      return 'Slide Right';
    default:
      return 'None';
  }
}

const VideoTransitionField: React.SFC<Props> = props => (
  <VideoOptionsField>
    <SelectField {...props}>
      {Object.keys(videoTransitionTypes).map(type => (
        <option key={`transition_${type}`} value={type}>
          {getTransitionLabel(type)}
        </option>
      ))}
    </SelectField>
  </VideoOptionsField>
);

export default VideoTransitionField;
export { Props as VideoTransitionFieldProps };
