import cn from 'classnames';
import * as React from 'react';

import { cloneOnlyChild } from 'utils/react';
import { episodeListBlock as block } from '../../utils';

export interface EpisodeListRowArtworkProps {
  children?: React.ReactNode;
}

/**
 * the artwork on the left side of the row
 */
const EpisodeListRowArtwork: React.FC<EpisodeListRowArtworkProps> = ({
  children,
}) =>
  cloneOnlyChild(children, child => ({
    className: cn(block('artwork'), child.props.className),
  }));

export default EpisodeListRowArtwork;
