import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinkButton from 'components/LinkButton';
import { podcastInfoSelector } from 'redux/modules/download';
import { showNotification } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { AddCategoriesFromFeedProps } from './types';
import { block, convertTagsListToString } from './utils';

export const AddCategoriesFromFeed: FC<AddCategoriesFromFeedProps> = ({
  isMetadataEnabled,
  onClick,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const podcastInfo = useSelector(podcastInfoSelector);

  const handleAutoPostAddCategories = useCallback((): void => {
    onClick('<categories>');
  }, [onClick]);

  const handleManualAddCategories = useCallback((): void => {
    if (podcastInfo?.tags) {
      onClick(convertTagsListToString(podcastInfo.tags));
    } else {
      dispatch(
        showNotification({
          title: "We couldn't find any categories",
          message: 'Please make sure they exist in this RSS feed',
          level: 'error',
        }),
      );
    }
  }, [dispatch, onClick, podcastInfo]);

  return (
    <div className={block()}>
      <LinkButton
        className={block('field')}
        onClick={
          isMetadataEnabled
            ? handleAutoPostAddCategories
            : handleManualAddCategories
        }
      >
        Use categories from your feed as tags
      </LinkButton>
      {isMetadataEnabled && (
        <label className={block('label')}>
          (shows nothing if not found in feed)
        </label>
      )}
    </div>
  );
};
