import withRouter from 'components/hoc/withRouter';
import { PropsOf } from 'types';
import AudioWizardLoader from './AudioWizardLoader';

const EnhancedComponent = withRouter(AudioWizardLoader, {
  parseBooleans: false,
  parseNumbers: false,
});

export default EnhancedComponent;
export type AudioWizardLoaderProps = PropsOf<typeof EnhancedComponent>;
