import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import MediaUploadModalContents from './MediaUploadModalContents';
import { block } from './utils';

export interface MediaUploadModalProps {}

const MediaUploadModal: React.FC<MediaUploadModalProps> = () => {
  return (
    <ConnectedModal name="MediaUpload" className={block('container')}>
      <MediaUploadModalContents />
    </ConnectedModal>
  );
};

export default MediaUploadModal;
