import { AnyObject } from 'types';

const DEFAULT_ACTION = {
  type: 'persistPurge/PURGE',
};

export const purge = (action: AnyObject = DEFAULT_ACTION) => ({
  ...action,
  meta: {
    ...action.meta,
    persistPurge: true,
  },
});
