import { useDispatch } from 'react-redux';
import { pushModal } from 'redux/modules/modal';

export default () => {
  const dispatch = useDispatch();

  const onEdit = (subscriptionId: number) => {
    dispatch(
      pushModal({
        name: 'EditAutomationModal',
        params: {
          subscriptionId,
        },
      }),
    );
  };

  return {
    onEdit,
  };
};
