import * as React from 'react';
import LoadingOverlay from 'components/LoadingOverlay';

import { AutogramVideoType } from 'types';
import CallToAction from './CallToAction';
import { useNavigation } from './context/NavigationContext';
import { useEditorState } from './context/VideoEditorStateContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import EditorPane from './EditorPane';
import {
  BackgroundTile,
  IntroOutroTile,
  MediaTile,
  ProgressTile,
  TextTile,
  WatermarkTile,
  WaveformTile,
} from './option-tiles';
import CaptionsTile from './option-tiles/CaptionsTile';
import OptionsChildViewManager from './OptionsChildViewManager';
import OptionsPaneTemplateControls from './OptionsPaneTemplateControls';
import { OptionTilesProvider } from './OptionTilesContext';
import { OPTION_TILE_FEATURE_PATHS } from './state/constants';
import { getFeatureState } from './state/features-utils';
import TemplatesButton from './TemplatesButton';
import TileGrid from './TileGrid';
import {
  CallToActionProps,
  ChildView,
  CustomizeStepBrandingOptions,
  VideoTemplateState,
} from './types';
import USTMessage from './USTMessage';
import { optionsBlock as block } from './utils';

const { useCallback, useRef } = React;

export interface OptionsPaneProps {
  brandingOptions?: CustomizeStepBrandingOptions;
  ctaLabel?: CallToActionProps['label'];
  onCtaClick?: CallToActionProps['onClick'];
  renderCta?: CallToActionProps['render'];
  isTemplateDirty: boolean;
  resetTemplateDirtyState: () => void;
  videoType?: AutogramVideoType;
  state: VideoTemplateState;
}

const tileFeaturePaths = Object.values(OPTION_TILE_FEATURE_PATHS);

/*
 * Column with various controls and options to modify the preview
 */
const OptionsPane: React.FC<OptionsPaneProps> = ({
  ctaLabel,
  onCtaClick,
  renderCta,
  isTemplateDirty,
  resetTemplateDirtyState,
  videoType,
  state,
}) => {
  const [, send] = useNavigation();
  const { features, isLoading } = useEditorState();
  const { template } = useTemplateState();
  const { isUCSEditorCompatible, isUCSEditorCompatibleLoading } =
    template || {};
  const tilesRef = useRef<{ [k in ChildView]?: HTMLButtonElement }>({});

  const nTiles = tileFeaturePaths
    .map(path => getFeatureState(features, path))
    .filter(value => {
      return value !== 'hidden';
    }).length;

  const handleTileClick = useCallback(
    (view: ChildView) => {
      send({
        type: 'CHILD_VIEW_OPEN',
        payload: view,
        meta: { source: 'options' },
      });
    },
    [send],
  );

  const setWaveformRef = useCallback((el: HTMLButtonElement) => {
    tilesRef.current.waveform = el;
  }, []);

  const setImageRef = useCallback((el: HTMLButtonElement) => {
    tilesRef.current.images = el;
    tilesRef.current.image = el;
  }, []);

  const setWatermarkRef = useCallback((el: HTMLButtonElement) => {
    tilesRef.current.watermark = el;
  }, []);

  const setTextRef = useCallback((el: HTMLButtonElement) => {
    tilesRef.current.text = el;
  }, []);

  const setProgressRef = useCallback((el: HTMLButtonElement) => {
    tilesRef.current.progress = el;
  }, []);

  const setCaptionsRef = useCallback((el: HTMLButtonElement) => {
    tilesRef.current.captions = el;
  }, []);

  const setIntroOutroRef = useCallback((el: HTMLButtonElement) => {
    tilesRef.current.intro_outro = el;
    tilesRef.current.intro_outro_edit = el;
  }, []);

  const handleGetTile = useCallback((view: ChildView) => {
    return tilesRef.current[view];
  }, []);

  return (
    <>
      <EditorPane className={block()}>
        {!isLoading && !isUCSEditorCompatibleLoading && !isUCSEditorCompatible && (
          <>
            <USTMessage />
            <TemplatesButton />
          </>
        )}
        {isUCSEditorCompatible && (
          <OptionTilesProvider getTile={handleGetTile}>
            <OptionsPaneTemplateControls
              isTemplateDirty={isTemplateDirty}
              resetTemplateDirtyState={resetTemplateDirtyState}
              videoType={videoType}
              state={state}
            />

            <TileGrid className={block('grid', { small: nTiles <= 7 })}>
              <WaveformTile
                params="waveform"
                onClick={handleTileClick}
                ref={setWaveformRef}
              />
              <MediaTile
                params="images"
                onClick={handleTileClick}
                ref={setImageRef}
              />
              <TextTile
                params="text"
                onClick={handleTileClick}
                ref={setTextRef}
              />
              <ProgressTile
                params="progress"
                onClick={handleTileClick}
                ref={setProgressRef}
              />
              <BackgroundTile onClick={handleTileClick} />
              <WatermarkTile
                params="watermark"
                onClick={handleTileClick}
                ref={setWatermarkRef}
              />
              <CaptionsTile
                params="captions"
                onClick={handleTileClick}
                ref={setCaptionsRef}
              />
              <IntroOutroTile
                params="intro_outro"
                onClick={handleTileClick}
                ref={setIntroOutroRef}
              />
              <OptionsChildViewManager />
            </TileGrid>
          </OptionTilesProvider>
        )}
        <CallToAction
          label={ctaLabel}
          onClick={onCtaClick}
          render={renderCta}
        />
      </EditorPane>
      {(isLoading || isUCSEditorCompatibleLoading) && <LoadingOverlay />}
    </>
  );
};

export default OptionsPane;
