import { createSelector } from 'reselect';
import { Trace } from 'redux/middleware/api/url-generator-service';
import { State } from 'redux/types';
import { DeepImmutableMap } from 'types';
import { formatWatermarkSrcForConfig } from 'utils/embed/watermark';
import { defaultLogoUrlSelector } from '../display-pref/selectors';
import { tracesSelector } from '../entities';
import { ExportConfiguration } from '../wizard-export/types';
import { VideoClipWizardState } from './types';

const stateSelector = (state: State): VideoClipWizardState =>
  state.get('videoClipWizard');

export const traceIdSelector = createSelector(
  stateSelector,
  state => state.traceId,
);

const traceSelector = createSelector(
  [tracesSelector, traceIdSelector],
  (traces, traceId) =>
    !traces || !traceId
      ? undefined
      : (traces.get(traceId) as DeepImmutableMap<Trace>),
);

export const customTraceIdSelector = createSelector(
  traceSelector,
  trace => trace && trace.get('customTraceId'),
);

export const shouldPollForAssetsSelector = createSelector(
  traceSelector,
  trace => trace?.getIn(['configurations', 'pollForAsset']) ?? false,
);

export const videoUrlSelector = createSelector(traceSelector, trace => {
  return trace?.getIn(['properties', 'videoUrl']);
});

export const clipTitleSelector = createSelector(traceSelector, trace => {
  return trace?.getIn(['properties', 'clipTitle']);
});

export const transcriptUrlSelector = createSelector(
  traceSelector,
  trace => trace && trace.getIn(['properties', 'transcriptUrl']),
);

export const isLoadingSelector = createSelector(stateSelector, state => {
  return state.isLoading;
});

export const defaultWatermarkSelector = createSelector(
  defaultLogoUrlSelector,
  (url): ExportConfiguration['watermark'] => {
    if (!url) return undefined;

    const watermark = formatWatermarkSrcForConfig(url);

    return {
      url,
      originalUrl: url,
      position: {
        left: watermark.position.properties.left,
        top: watermark.position.properties.top,
      },
      size: {
        height: watermark.style.height,
        width: watermark.style.width,
      },
      integrationData: undefined,
      metadata: undefined,
    };
  },
);
