import WaveSurfer from 'wavesurfer.js';
import { Observer } from 'wavesurfer.js/src/util';
import CustomMultiCanvas from '../MultiCanvas';
import Region from './Region';

export interface RegionsPluginParams {
  /** Regions that should be added upon initialisation. */
  initialStart: number;
  initialEnd: number;
  color: string;
  /** The sensitivity of the mouse dragging (default: 2). */
  slop?: number;
  /** Snap the regions to a grid of the specified multiples in seconds? */
  snapToGridInterval?: number;
  /** Shift the snap-to-grid by the specified seconds. May also be negative. */
  snapToGridOffset?: number;
  /** Allows custom formating for region tooltip. */
  formatTimeCallback?: () => string;
  /** from container edges' Optional width for edgeScroll to start (default: 5% of viewport width). */
  edgeScrollWidth?: number;

  minLength?: number;

  maxLength?: number;

  deferInit?: boolean;

  scrollSpeed?: number;

  scrollThreshold?: number;
}

/**
 * Wavesurfer adds observer methods at runtime.
 */
interface RegionsPlugin extends Observer {}

/**
 * Forked version of Wavesurfer's official [RegionsPlugin](https://github.com/katspaugh/wavesurfer.js/blob/19afccbe8cde790986989105d99f5935c8088f03/src/plugin/regions/index.js)
 */
class RegionsPlugin {
  private params: RegionsPluginParams & {};

  private wavesurfer: WaveSurfer;

  private drawer: CustomMultiCanvas;

  private util: typeof WaveSurfer.util & {
    getRegionSnapToGridValue: (value: number) => number;
  };

  private wrapper: HTMLElement;

  public region: Region;

  static create(params: RegionsPluginParams) {
    return {
      name: 'regions',
      deferInit: params && params.deferInit ? params.deferInit : false,
      params,
      staticProps: {
        enableDragSelection(options) {
          if (!this.initialisedPluginList.regions) {
            this.initPlugin('regions');
          }
          this.regions.enableDragSelection(options);
        },

        disableDragSelection() {
          this.regions.disableDragSelection();
        },
      },
      instance: RegionsPlugin,
    };
  }

  constructor(params: RegionsPluginParams, ws: WaveSurfer) {
    this.params = params;
    this.wavesurfer = ws;
    this.drawer = ws.drawer;
    this.util = {
      ...ws.util,
      getRegionSnapToGridValue: value => {
        return this.getRegionSnapToGridValue(value, params);
      },
    };
  }

  private onBackendCreated = () => {
    // By default, scroll the container if the user drags a region
    // within 5% of its edge
    const scrollWidthProportion = 0.05;
    this.wrapper = this.wavesurfer.drawer.wrapper;
    this.region = new Region(
      {
        ...this.params,
        start: this.params.initialStart,
        end: this.params.initialEnd,
        color: this.params.color,
        edgeScrollWidth:
          this.params.edgeScrollWidth ||
          this.drawer.getWidth() * scrollWidthProportion,
      },
      this.util,
      this.wavesurfer,
    );
  };

  private onReady() {
    this.wrapper = this.wavesurfer.drawer.wrapper;
    this.enableDragSelection(this.params);
    this.region.updateRender();
  }

  init() {
    // Check if ws is ready
    if (this.wavesurfer.isReady) {
      this.onBackendCreated();
      this.onReady();
    } else {
      this.wavesurfer.once('ready', this.onReady);
      this.wavesurfer.once('backend-created', this.onBackendCreated);
    }
  }

  destroy() {
    this.wavesurfer.un('ready', this.onReady);
    this.wavesurfer.un('backend-created', this.onBackendCreated);
    this.disableDragSelection();
    this.region?.remove();
  }

  enableDragSelection(params: RegionsPluginParams) {
    this.disableDragSelection();

    const slop = params.slop || 2;
    const scrollSpeed = params.scrollSpeed || 1;
    const scrollThreshold = params.scrollThreshold || 20;
    let drag: boolean;
    let duration = this.wavesurfer.getDuration();
    let maxScroll: number;
    let start: number;
    let touchId;
    let pxMove = 0;
    let scrollDirection: 1 | -1 | null;
    let wrapperRect: DOMRect;
    let region: Region;

    // Scroll when the user is dragging within the threshold
    const edgeScroll = e => {
      if (!region || !scrollDirection) {
        return;
      }

      // Update scroll position
      const scrollLeft =
        this.drawer.getScrollX() + scrollSpeed * scrollDirection;
      this.drawer.setScrollX(scrollLeft);

      // Update range
      const end = this.wavesurfer.drawer.handleEvent(e);
      this.region.update({
        start: Math.min(end * duration, start * duration),
        end: Math.max(end * duration, start * duration),
      });

      // Check that there is more to scroll and repeat
      if (scrollLeft < maxScroll && scrollLeft > 0) {
        window.requestAnimationFrame(() => {
          edgeScroll(e);
        });
      }
    };

    const eventDown = e => {
      if (e.touches && e.touches.length > 1) {
        return;
      }
      duration = this.wavesurfer.getDuration();
      touchId = e.targetTouches ? e.targetTouches[0].identifier : null;

      // Store for scroll calculations
      maxScroll = this.wavesurfer.drawer.getMaxScroll();
      wrapperRect = this.drawer.container.getBoundingClientRect();

      drag = true;
      start = this.wavesurfer.drawer.handleEvent(e, true);
      region = null;
      scrollDirection = null;
    };
    this.wrapper.addEventListener('mousedown', eventDown);
    this.wrapper.addEventListener('touchstart', eventDown);
    this.on('disable-drag-selection', () => {
      this.wrapper.removeEventListener('touchstart', eventDown);
      this.wrapper.removeEventListener('mousedown', eventDown);
    });

    const eventUp = e => {
      if (!drag || (e.touches && e.touches.length > 1)) {
        return;
      }

      drag = false;
      pxMove = 0;
      scrollDirection = null;

      if (region) {
        this.util.preventClick();
        region.fireEvent('update-end', e);
        this.wavesurfer.fireEvent('region-update-end', this.region, e);
      }

      region = null;
    };
    this.wrapper.addEventListener('mouseleave', eventUp);
    this.wrapper.addEventListener('mouseup', eventUp);
    this.wrapper.addEventListener('touchend', eventUp);

    document.body.addEventListener('mouseup', eventUp);
    document.body.addEventListener('touchend', eventUp);
    this.on('disable-drag-selection', () => {
      document.body.removeEventListener('mouseup', eventUp);
      document.body.removeEventListener('touchend', eventUp);
      this.wrapper.removeEventListener('touchend', eventUp);
      this.wrapper.removeEventListener('mouseup', eventUp);
      this.wrapper.removeEventListener('mouseleave', eventUp);
    });

    const eventMove = event => {
      if (!drag) {
        return;
      }

      pxMove += 1;
      if (pxMove <= slop) {
        return;
      }

      if (event.touches && event.touches.length > 1) {
        return;
      }
      if (
        event.targetTouches &&
        event.targetTouches[0].identifier !== touchId
      ) {
        return;
      }

      region = this.region;
      const end = this.wavesurfer.drawer.handleEvent(event);
      const startUpdate = this.util.getRegionSnapToGridValue(start * duration);
      const endUpdate = this.util.getRegionSnapToGridValue(end * duration);
      this.region.update({
        start: Math.min(endUpdate, startUpdate),
        end: Math.max(endUpdate, startUpdate),
      });

      // If scrolling is enabled
      if (this.drawer.getMaxScroll()) {
        // Check threshold based on mouse
        const x = event.clientX - wrapperRect.left;
        if (x <= scrollThreshold) {
          scrollDirection = -1;
        } else if (x >= wrapperRect.right - scrollThreshold) {
          scrollDirection = 1;
        } else {
          scrollDirection = null;
        }
        if (scrollDirection) {
          edgeScroll(event);
        }
      }
    };
    this.wrapper.addEventListener('mousemove', eventMove);
    this.wrapper.addEventListener('touchmove', eventMove);
    this.on('disable-drag-selection', () => {
      this.wrapper.removeEventListener('touchmove', eventMove);
      this.wrapper.removeEventListener('mousemove', eventMove);
    });
  }

  disableDragSelection() {
    this.fireEvent('disable-drag-selection');
  }

  /**
   * Match the value to the grid, if required
   *
   * If the regions plugin params have a snapToGridInterval set, return the
   * value matching the nearest grid interval. If no snapToGridInterval is set,
   * the passed value will be returned without modification.
   */
  getRegionSnapToGridValue = (
    value: number,
    params: RegionsPluginParams,
  ): number => {
    if (params.snapToGridInterval) {
      // the regions should snap to a grid
      const offset = params.snapToGridOffset || 0;
      return (
        Math.round((value - offset) / params.snapToGridInterval) *
          params.snapToGridInterval +
        offset
      );
    }

    // no snap-to-grid
    return value;
  };

  getCurrentRegion() {
    return this.region;
  }
}

export default RegionsPlugin;
