import * as React from 'react';
import Tooltip from 'components/Tooltip';
import { MetadataProps } from './types';
import { block } from './utils';

export const Metadata: React.FC<MetadataProps> = ({
  label,
  tooltip,
  fields,
  onClick,
}) => {
  return (
    <div className={block()}>
      <ul>
        <li className={block('label')}>{label} </li>
        {fields.map(field => {
          return (
            <Tooltip
              id={`${field.label}-tooltip`}
              content={tooltip}
              placement="top"
              key={field.value}
            >
              <li
                className={block('field')}
                onClick={() => onClick(field.value)}
              >
                {field.label}
              </li>
            </Tooltip>
          );
        })}
      </ul>
    </div>
  );
};
