import cn from 'classnames';
import * as React from 'react';

import { VideoCamera } from 'components/icons';
import { episodeListBlock as block } from '../utils';

export interface EpisodeRowCameraIconProps {
  className?: string;
}

const EpisodeRowCameraIcon: React.FC<EpisodeRowCameraIconProps> = ({
  className,
}) => <VideoCamera className={cn(block('video-count-icon'), className)} />;

export default EpisodeRowCameraIcon;
