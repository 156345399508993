import * as React from 'react';
import { Position, RndDragCallback } from 'react-rnd';
import { noop } from 'underscore';

import DraggableWorkspace, {
  DraggableWorkspaceProps,
} from 'components/DraggableWorkspace';
import Rnd from 'components/Rnd';
import {
  EditorVideoFramePreview,
  EditorVideoFramePreviewProps,
} from 'containers/VideoFramePreview';
import useObjectUrl from 'hooks/useObjectUrl';
import { useWheel } from 'hooks/useWheel';
import { Dimensions, FitType, Size } from 'types';
import { formatCSSFilter } from 'utils/dom';
import { Pixels, ViewportHeight, ViewportWidth } from 'utils/measurement';
import {
  fitElement,
  hasDimensions,
  isFill,
  measurementToPx,
  measurementToViewport,
  numberToViewport,
  replaceRect,
  scale,
} from 'utils/placement';
import LoadingOverlay from './LoadingOverlay';
import { SlideEditorStatus } from './types';
import { block } from './utils';

const { useCallback, useEffect, useRef, useState } = React;

export type SlidePlacerValue = Dimensions<ViewportHeight, ViewportWidth>;

export interface SlidePlacerProps
  extends Pick<DraggableWorkspaceProps, 'aspectRatio'>,
    Pick<EditorVideoFramePreviewProps, 'positionMillis'> {
  // if no default placement is provided, the SlidePlacer will automatically place
  // the slide within the frame using either a "fit" or "fill" algorithm
  defaultFitType?: FitType;
  onChange?: (value: SlidePlacerValue) => void;
  onStatusChange?: (status: SlideEditorStatus) => void;
  slideId?: string;
  source: Blob | string;
  value: SlidePlacerValue;
  blurRadius?: ViewportWidth;
}

const SlidePlacer: React.FC<SlidePlacerProps> = ({
  aspectRatio,
  defaultFitType = 'fit',
  onChange = noop,
  onStatusChange = noop,
  slideId,
  source,
  value,
  blurRadius,
}) => {
  const url = useObjectUrl(source);
  const [containerSize, setContainerSize] = useState<Size<Pixels>>();
  const [naturalSize, setNaturalSize] = useState<Size<Pixels>>();
  const [status, setStatus] = useState<SlideEditorStatus>('loading');

  useEffect(() => {
    onStatusChange(status);
  }, [onStatusChange, status]);

  useEffect(() => {
    setStatus('loading');
  }, [url]);

  const resizeImageToContainer = useCallback(
    (type: FitType) => {
      onChange(
        measurementToViewport(
          fitElement(naturalSize, containerSize, type),
          containerSize,
        ),
      );
    },
    [containerSize, naturalSize, onChange],
  );

  const valueRef = useRef(value);
  const onChangeRef = useRef(onChange);
  const resizeImageToContainerRef = useRef(resizeImageToContainer);

  useEffect(() => {
    valueRef.current = value;
    onChangeRef.current = onChange;
    resizeImageToContainerRef.current = resizeImageToContainer;
  });

  useEffect(() => {
    if (containerSize && naturalSize) {
      if (!valueRef.current) {
        resizeImageToContainerRef.current(defaultFitType);
      } else {
        const placement = {
          left: new ViewportWidth(0),
          height: new ViewportHeight(100),
          top: new ViewportHeight(0),
          width: new ViewportWidth(100),
          ...valueRef.current,
        };
        onChangeRef.current(
          measurementToViewport(
            replaceRect(
              measurementToPx(placement, containerSize),
              naturalSize,
              containerSize,
            ),
            containerSize,
          ),
        );
      }
    }
  }, [containerSize, defaultFitType, naturalSize]);

  const handleSizeChange = useCallback(({ height, width }) => {
    setContainerSize({ height: new Pixels(height), width: new Pixels(width) });
  }, []);

  const handleImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const target = e.currentTarget;
      setNaturalSize({
        height: new Pixels(target.naturalHeight),
        width: new Pixels(target.naturalWidth),
      });
      setStatus('ready');
    },
    [],
  );

  const handleDragStop: RndDragCallback = useCallback(
    (_, { x, y }) => {
      if (!value) return;

      const left = new Pixels(x);
      const top = new Pixels(y);

      // if the element didn't move, bail.  there's a lot of math involved so
      // use a margin of error when calculating equality
      if (
        value.left.toUnit('px', containerSize).eq(left, 0.001) &&
        value.top.toUnit('px', containerSize).eq(top, 0.001)
      ) {
        return;
      }

      onChange({
        ...value,
        left: new Pixels(x).toUnit('vw', containerSize),
        top: new Pixels(y).toUnit('vh', containerSize),
      });
    },
    [containerSize, onChange, value],
  );

  const handleResize = useCallback(
    (_1, _2, ref, _3, position: Position) => {
      if (!value) return;
      onChange(
        numberToViewport(
          {
            height: ref.offsetHeight,
            left: position.x,
            top: position.y,
            width: ref.offsetWidth,
          },
          containerSize,
        ),
      );
    },
    [containerSize, onChange, value],
  );

  const handleDoubleClick = useCallback(() => {
    resizeImageToContainer(
      isFill(measurementToPx(value, containerSize), containerSize)
        ? 'fit'
        : 'fill',
    );
  }, [containerSize, resizeImageToContainer, value]);

  const handleWheel = useWheel(
    useCallback(
      (e: React.WheelEvent<Element>) => {
        const multiplier = e.deltaY * 0.001;
        onChange(
          measurementToViewport(
            scale(measurementToPx(value, containerSize), multiplier, {
              minArea: 500,
            }),
            containerSize,
          ),
        );
      },
      [containerSize, onChange, value],
    ),
  );

  return (
    <DraggableWorkspace
      aspectRatio={aspectRatio}
      className={block('placer')}
      onDoubleClick={handleDoubleClick}
      onSizeChange={handleSizeChange}
      onWheel={handleWheel}
    >
      {(status === 'loading' || !hasDimensions(value)) && <LoadingOverlay />}
      <EditorVideoFramePreview
        aspectRatio={aspectRatio}
        canvasDimensions={
          containerSize
            ? {
                height: containerSize?.height.value,
                width: containerSize?.width.value,
              }
            : undefined
        }
        backgroundFor={{
          id: slideId,
          type: 'slideshowInfo',
        }}
      />
      {containerSize && (
        <Rnd
          active
          lockAspectRatio
          onDragStop={handleDragStop}
          onResize={handleResize}
          position={{
            x: value?.left?.toUnit('px', containerSize).value ?? 0,
            y: value?.top?.toUnit('px', containerSize).value ?? 0,
          }}
          size={{
            height: value?.height?.toUnit('px', containerSize).value,
            width: value?.width?.toUnit('px', containerSize).value,
          }}
        >
          <img
            className={block('image')}
            onLoad={handleImageLoad}
            src={url}
            style={{
              height: '100%',
              pointerEvents: 'none',
              width: '100%',
              filter: formatCSSFilter({
                blur: {
                  radius: blurRadius?.toUnit('px', containerSize)?.toString(),
                },
              }),
            }}
          />
        </Rnd>
      )}
    </DraggableWorkspace>
  );
};

export default SlidePlacer;
