import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';

import EditAutomationEpisodeSelectSeasonModalContents from './EditAutomationEpisodeSelectSeasonModalContents';

const EditAutomationEpisodeSelectSeasonModal: React.FunctionComponent = () => {
  return (
    <ConnectedModal
      backdrop="static"
      name="EditAutomationEpisodeSelectSeason"
      title="edit episode selection"
    >
      <EditAutomationEpisodeSelectSeasonModalContents />
    </ConnectedModal>
  );
};

export default EditAutomationEpisodeSelectSeasonModal;
