import * as React from 'react';
import { noop } from 'underscore';

import { ColorChangeField } from 'components/Form';
import { ProgressAndTimerOptions, Replace, Size, TimerOptions } from 'types';
import {
  getProgressPositionId,
  getProgressSizeId,
  progressReducer,
} from 'utils/embed/progress';
import { Measurement } from 'utils/measurement';
import { block } from '../../utils';
import ProgressPositionField from '../ProgressPositionField';
import ProgressSizeField from '../ProgressSizeField';
import ProgressStyleField, {
  Value as ProgressStyleValue,
} from '../ProgressStyleField';
import TimerField, { TimerFieldValue } from './TimerField';

const { useCallback } = React;

export type ProgressOptionsValue = Replace<
  ProgressAndTimerOptions<Measurement>,
  {
    timer: Pick<TimerOptions, 'color' | 'enabled'>;
  }
>;

export interface ProgressAnimationOptionsProps {
  hideTimer?: boolean;
  value: ProgressOptionsValue;
  onChange?: (value: ProgressOptionsValue) => void;
  workspaceSize: Size<number>;
}

const ProgressAnimationOptions: React.FC<ProgressAnimationOptionsProps> = ({
  hideTimer,
  onChange,
  value,
  workspaceSize,
}) => {
  const handleFromColorChange = useCallback(
    fromColor => {
      onChange({
        ...value,
        progress: {
          ...value.progress,
          fillColor: fromColor,
        },
      });
    },
    [onChange, value],
  );

  const handleToColorChange = useCallback(
    toColor => {
      onChange({
        ...value,
        progress: {
          ...value.progress,
          color: toColor,
        },
      });
    },
    [onChange, value],
  );

  const handleTimerChange = useCallback(
    (timer: TimerFieldValue) => {
      onChange({
        ...value,
        timer,
      });
    },
    [onChange, value],
  );

  const handleProgressStyleChange = (style: ProgressStyleValue) => {
    onChange({
      ...value,
      progress: progressReducer(value.progress, {
        type: 'UPDATE_TYPE',
        payload: style,
        meta: workspaceSize,
      }),
    });
  };

  // to override some inline styles on the color picker
  const colorPickerPopoverStyle: React.CSSProperties = {
    position: 'absolute',
    top: 35,
  };

  return (
    <form className={block('form')}>
      <ProgressStyleField
        value={value.progress.enabled ? value.progress.type : null}
        onChange={handleProgressStyleChange}
      />
      <ProgressPositionField
        onChange={positionId =>
          onChange({
            ...value,
            progress: progressReducer(value.progress, {
              type: 'UPDATE_POSITION',
              payload: positionId,
              meta: workspaceSize,
            }),
          })
        }
        disabled={!value.progress.enabled}
        value={getProgressPositionId(value.progress)}
      />
      <ProgressSizeField
        onChange={sizeId =>
          onChange({
            ...value,
            progress: progressReducer(value.progress, {
              type: 'UPDATE_SIZE',
              payload: sizeId,
              meta: workspaceSize,
            }),
          })
        }
        disabled={!value.progress.enabled}
        value={getProgressSizeId(value.progress)}
      />
      <ColorChangeField
        className={block('color-change')}
        disabled={!value.progress.enabled}
        fromPopoverStyle={colorPickerPopoverStyle}
        horizontal={{
          control: { xs: 6 },
          label: { xs: 6 },
        }}
        label="Colors"
        onFromColorChange={handleFromColorChange}
        onToColorChange={handleToColorChange}
        toPopoverStyle={colorPickerPopoverStyle}
        value={{
          fromColor: value.progress.fillColor,
          toColor: value.progress.color,
        }}
      />
      {!hideTimer && (
        <TimerField onChange={handleTimerChange} value={value.timer} />
      )}
    </form>
  );
};

ProgressAnimationOptions.defaultProps = {
  onChange: noop,
};

export default ProgressAnimationOptions;
