import classNames from 'classnames';
import * as React from 'react';
import { isEqual, isUndefined, noop } from 'underscore';

import { TEXT_SHADOW_EM_MULTIPLIER } from 'utils/embed/ui';
import { round } from 'utils/numbers';
import ToolbarColorPicker from '../ToolbarColorPicker';
import TextShadowBlurField from './TextShadowBlurField';
import TextShadowField from './TextShadowField';
import TextShadowOffsetField from './TextShadowOffsetField';

interface ITextShadow {
  blur?: number;
  color?: string;
  x?: number;
  y?: number;
}

export type ShadowDimension = keyof ITextShadow;

interface IProps {
  disabled?: boolean;
  openMenu?: ShadowDimension | '';
  onOpenMenuChange?: (dimension: ShadowDimension) => void;
  onControlClick?: (dimension: ShadowDimension) => void;
  onChange?: (shadow: ITextShadow) => void;
  value?: ITextShadow;
}

export default class TextShadowInput extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onChange: noop,
    onControlClick: noop,
    onOpenMenuChange: noop,
    openMenu: undefined,
    value: {},
  };

  public shouldComponentUpdate(nextProps: IProps) {
    return !isEqual(nextProps, this.props);
  }

  private handleColorChange = (color: string) => {
    const { value, onChange } = this.props;
    onChange({ ...value, color });
  };

  private handleNumericChange = <K extends keyof ITextShadow>(
    value: number,
    key: K,
  ) => {
    const { onChange, value: shadow } = this.props;
    switch (key) {
      case 'x':
      case 'y':
        onChange({
          ...shadow,
          [key]: (value as number) * TEXT_SHADOW_EM_MULTIPLIER,
        });
        break;

      default:
        onChange({
          ...shadow,
          [key]: value,
        });
    }
  };

  private handleToggleOpen = (isOpen: boolean, key: ShadowDimension) => {
    const { onOpenMenuChange, openMenu } = this.props;

    if (key === openMenu && !isOpen) {
      onOpenMenuChange(undefined);
    } else if (isOpen) {
      onOpenMenuChange(key);
    }
  };

  private handleControlClick = (dimension: ShadowDimension) =>
    this.props.onControlClick(dimension);

  public render() {
    const { disabled, openMenu, value } = this.props;
    const { x, y, blur, color } = value;

    return (
      <div
        className={classNames('text-toolbar__text-shadow', {
          'text-toolbar__text-shadow--disabled': disabled,
        })}
      >
        <TextShadowField label="Shadow">
          <ToolbarColorPicker
            args="color"
            className="text-toolbar__text-shadow-color-picker"
            controlClassName="text-toolbar__text-shadow-color-picker-control"
            value={color}
            disabled={disabled}
            isOpen={openMenu === 'color'}
            onChange={this.handleColorChange}
            onControlClick={this.handleControlClick}
            onToggleOpen={this.handleToggleOpen}
          />
        </TextShadowField>
        <TextShadowOffsetField
          args="x"
          disabled={disabled}
          label="X"
          isOpen={openMenu === 'x'}
          value={isUndefined(x) ? x : round(x / TEXT_SHADOW_EM_MULTIPLIER)}
          onChange={this.handleNumericChange}
          onControlClick={this.handleControlClick}
          onToggleOpen={this.handleToggleOpen}
        />
        <TextShadowOffsetField
          args="y"
          label="Y"
          disabled={disabled}
          isOpen={openMenu === 'y'}
          value={isUndefined(y) ? y : round(y / TEXT_SHADOW_EM_MULTIPLIER)}
          onChange={this.handleNumericChange}
          onControlClick={this.handleControlClick}
          onToggleOpen={this.handleToggleOpen}
        />
        <TextShadowBlurField
          args="blur"
          disabled={disabled}
          isOpen={openMenu === 'blur'}
          value={blur}
          onChange={this.handleNumericChange}
          onControlClick={this.handleControlClick}
          onToggleOpen={this.handleToggleOpen}
        />
      </div>
    );
  }
}

export { IProps as TextShadowInputProps, ITextShadow as TextShadow };
