import * as React from 'react';
import { noop } from 'underscore';

import { SelectField, SelectFieldProps } from 'components/Form';
import { audioFadeUiOptToDurationMillis } from 'utils/embed/video';
import { int } from 'utils/numbers';
import VideoOptionsField from './VideoOptionsField';

type PickedProps = Pick<SelectFieldProps, 'label'>;

interface IProps extends PickedProps {
  value: number;
  onChange?: (val: number, e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const options = [
  <option key="none" value={`${audioFadeUiOptToDurationMillis('none')}`}>
    None
  </option>,
  <option key="short" value={`${audioFadeUiOptToDurationMillis('short')}`}>
    Short
  </option>,
  <option key="long" value={`${audioFadeUiOptToDurationMillis('long')}`}>
    Long
  </option>,
];

export default class AudioFadeField extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onChange: noop,
  };

  private handleChange = (
    val: string,
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { onChange } = this.props;
    onChange(int(val), e);
  };

  public render() {
    const { onChange, value, ...props } = this.props;

    return (
      <VideoOptionsField>
        <SelectField {...props} onChange={this.handleChange} value={`${value}`}>
          {options}
        </SelectField>
      </VideoOptionsField>
    );
  }
}

export { IProps as AudioFadeFieldProps };
