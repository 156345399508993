import * as React from 'react';

import { IconProps } from './types';

const MediaFile: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="90"
    height="104"
    viewBox="0 0 90 104"
    {...props}
  >
    <defs>
      <path
        id="mediafile-path"
        d="M3 0h36.25a3 3 0 0 1 2.13.887l11.75 11.836a3 3 0 0 1 .87 2.113V65a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z"
      />
      <filter
        id="mediafile-filter"
        width="207.4%"
        height="185.3%"
        x="-53.7%"
        y="-36.8%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="1"
          result="shadowSpreadOuter1"
        />
        <feOffset dy="4" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="8"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(18 14)">
        <use
          fill="#000"
          filter="url(#mediafile-filter)"
          xlinkHref="#mediafile-path"
        />
        <use fill="#323746" xlinkHref="#mediafile-path" />
      </g>
      <path
        fill="#646E82"
        fillRule="nonzero"
        d="M58.667 55.273V40.727C58.667 39.221 57.473 38 56 38H34.667C33.194 38 32 39.221 32 40.727v14.546C32 56.779 33.194 58 34.667 58H56c1.473 0 2.667-1.221 2.667-2.727zM40.889 43a2.778 2.778 0 1 1-5.556 0 2.778 2.778 0 0 1 5.556 0zm-5.556 9.04l3.155-3.038a.7.7 0 0 1 .964 0l2.245 2.162 7.7-7.416a.7.7 0 0 1 .964 0l4.972 4.79v6.129h-20V52.04z"
      />
    </g>
  </svg>
);

export default MediaFile;
