import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import IconButton, { IconButtonProps } from 'components/IconButton';
import { Resize } from 'components/icons';
import { pushModal } from 'redux/modules/modal/actions';
import { showError, showSuccess } from 'redux/modules/notification/actions';
import { copyProject } from 'redux/modules/project/actions';
import { projectIdSelector } from 'redux/modules/project/selectors';
import { Dispatch } from 'redux/types';

type Props = Pick<IconButtonProps, 'className' | 'disabled'>;

function CopyProjectButton(props: Props) {
  const { className, disabled, ...rest } = props;
  const dispatch = useDispatch<Dispatch>();
  const projectId = useSelector(projectIdSelector);
  const handleButtonClick = async () => {
    try {
      const copyResult = await dispatch(
        pushModal({
          name: 'ProjectCopy',
          params: { projectId, showCopyMessage: true },
        }),
      );

      if (copyResult) {
        const { aspectRatio, projectName } = copyResult;
        await dispatch(copyProject(projectId, projectName, aspectRatio, true));
        dispatch(
          showSuccess(
            'You are now working in your copied project.',
            10,
            'copy-project-success',
            'Project copied successfully',
          ),
        );
      }
    } catch {
      dispatch(
        showError({
          title: 'Something went wrong',
          message: 'Please try again',
          dismissAfterSec: 5,
        }),
      );
    }
  };

  return (
    <div className={className}>
      <IconButton
        {...rest}
        onButtonClick={handleButtonClick}
        theme="primary"
        disabled={disabled}
      >
        <Resize />
        <span>resize</span>
      </IconButton>
    </div>
  );
}

export default CopyProjectButton;
