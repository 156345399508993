import * as React from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'underscore';

import DeleteModal, { DeleteModalProps } from 'components/DeleteModal';
import { deleteWorkflow } from 'redux/modules/automation-cms/actions';
import { onDeleteAutomationWorkflow } from 'redux/modules/mixpanel/actions';
import { Dispatch } from 'redux/types';
import { workflowDeleteModalBlock as block } from '../../utils';

const { useCallback } = React;

type PickedModalProps = Pick<DeleteModalProps, 'onCancel' | 'show'>;

export interface AutomationWorkflowDeleteModalProps extends PickedModalProps {
  onDelete?: (workflowId: number, deletePromise: Promise<void>) => void;
  workflowId: number;
}

const AutomationWorkflowDeleteModal: React.FC<AutomationWorkflowDeleteModalProps> = ({
  onCancel,
  onDelete,
  show,
  workflowId,
}) => {
  const dispatch = useDispatch<Dispatch>();

  const deleteAutomationWorkflow = useCallback(async () => {
    dispatch(onDeleteAutomationWorkflow());
    await dispatch(deleteWorkflow(workflowId));
    return undefined;
  }, [workflowId, dispatch]);

  const handleDeleteClick = useCallback(() => {
    onDelete(workflowId, deleteAutomationWorkflow());
  }, [onDelete, deleteAutomationWorkflow, workflowId]);

  return (
    <DeleteModal
      {...{ onCancel, show }}
      title="Remove automation"
      bodyText="Are you sure you want us to stop creating these videos for you?"
      bodyClassName={block('delete-modal-body')}
      className={block()}
      deleteButtonTitle="Remove"
      onDelete={handleDeleteClick}
    />
  );
};

AutomationWorkflowDeleteModal.defaultProps = {
  onDelete: noop,
};

export default AutomationWorkflowDeleteModal;
