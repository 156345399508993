import cn from 'classnames';
import * as React from 'react';
import {
  ModalHeader as BsModalHeader,
  ModalTitle as BsModalTitle,
} from 'react-bootstrap';
import { isString } from 'underscore';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  /**
   * whether or not to render a close button in the modal header
   */
  closeButton?: boolean;
  // title?: string;
}

const ModalHeader: React.SFC<IProps> = ({
  children,
  className,
  closeButton,
}) => (
  <BsModalHeader
    className={cn('hl-modal__header', className)}
    closeButton={closeButton}
  >
    {/* {title && <BsModalTitle className="hl-modal__title">{title}</BsModalTitle>} */}
    {isString(children) ? (
      <BsModalTitle className="hl-modal__title">{children}</BsModalTitle>
    ) : (
      <>{children}</>
    )}
  </BsModalHeader>
);

ModalHeader.defaultProps = {
  closeButton: true,
};

export default ModalHeader;
export { IProps as ModalHeaderProps };
