import { Map, Record } from 'immutable';
import { omit } from 'underscore';

import { reviveRecord } from 'utils/immutable';
import { IEngineResults } from './types';

export const engineFactory = Record<IEngineResults>({
  data: undefined,
  error: undefined,
  isFetching: undefined,
  page: undefined,
  pageSize: undefined,
  q: undefined,
  totalResults: undefined,
});

export function stateFromJs({ pixabay }: any) {
  return Map({
    pixabay: reviveRecord(engineFactory, omit(pixabay, 'data'), {
      data: pixabay?.data,
    }),
  });
}
