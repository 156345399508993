import * as React from 'react';
import LanguageSelector, {
  LanguageSelectorProps,
} from 'components/LanguageSelector';
import { useEditorTranscriptionOptionsContext } from './EditorTranscriptionOptionsContext';

export type TranscriptionLanguageSelectorProps = Pick<
  LanguageSelectorProps,
  'className' | 'horizontal' | 'label' | 'labelClassName'
>;

const TranscriptionLanguageSelector: React.FC<TranscriptionLanguageSelectorProps> = ({
  className,
  horizontal = {
    control: { xs: 8 },
    label: { xs: 4 },
  },
  label = 'Language',
  labelClassName,
}) => {
  const {
    canTranscribe,
    disabled,
    language,
    onLanguageSelected,
    transcriptionEnabled,
  } = useEditorTranscriptionOptionsContext();

  return (
    <LanguageSelector
      disabled={disabled || !transcriptionEnabled || !canTranscribe}
      onChange={onLanguageSelected}
      value={language}
      {...{ className, horizontal, label, labelClassName }}
    />
  );
};

export default TranscriptionLanguageSelector;
