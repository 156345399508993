import React from 'react';
import { IconProps } from './types';

const PlusBold: React.FC<IconProps> = props => (
  <svg viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 5.534H7.466V0H5.534v5.534H0v1.932h5.534V13h1.932V7.466H13z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default PlusBold;
