import { useEffect, useState } from 'react';

interface Props {
  progress: number;
  interval: number;
  incrementBy: number;
  maxValue: number;
}

export default function useTranscriptProgress({
  progress,
  interval,
  incrementBy,
  maxValue,
}: Props) {
  const [fakeProgress, setFakeProgress] = useState(progress);

  useEffect(() => {
    const id = setInterval(() => {
      if (fakeProgress >= maxValue) clearInterval(id);

      setFakeProgress(value => {
        const newValue = Math.min(value + incrementBy, maxValue);
        return newValue;
      });
    }, interval);

    if (progress === 100) {
      setFakeProgress(100);
      clearInterval(id);
    }

    return () => clearInterval(id);
  }, [fakeProgress, incrementBy, interval, maxValue, progress]);

  return fakeProgress;
}
