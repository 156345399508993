import React from 'react';
import DownloadCaptionsDialogModal from './DownloadCaptionsDialogModal';
import EpisodeTranscriptionErrorModalContents from './EpisodeTranscriptionErrorModalContents';

export interface EpisodeTranscriptionErrorModalProps {}

const EpisodeTranscriptionErrorModal: React.FC<EpisodeTranscriptionErrorModalProps> = () => (
  <DownloadCaptionsDialogModal
    name="EpisodeTranscriptionError"
    title="transcription error"
  >
    <EpisodeTranscriptionErrorModalContents />
  </DownloadCaptionsDialogModal>
);

export default EpisodeTranscriptionErrorModal;
