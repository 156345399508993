import * as React from 'react';

import withWindowResize from 'components/hoc/WithWindowResize';
import { PropsOf } from 'types';
import connect from './connect';
import { Size } from './types';
import withBrowserSizeMonitor from './withBrowserSizeMonitor';

/*
 * typescript needs some help here.
 * compose doesn't really work with generics https://github.com/Microsoft/TypeScript/issues/10247
 * type inference on curried functions isn't great either.
 */
export default function(minSize: Size) {
  return <Props>(component: React.ComponentType<Props>) => {
    const componentWithSizeMonitor = withBrowserSizeMonitor(minSize)<Props>(
      component,
    );
    const componentWithResize = withWindowResize()<
      PropsOf<typeof componentWithSizeMonitor>
    >(componentWithSizeMonitor);
    return connect(componentWithResize as any);
  };
}
