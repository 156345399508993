import cn from 'classnames';
import React, { useState } from 'react';

import BemCssTransition from 'components/BemCssTransition';
import LoadingOverlay from 'components/LoadingOverlay';
import { createChainedFunction } from 'utils/functions';
import { cardWizardBlock as block } from '../../utils';
import CardWizardStepper, { CardWizardStepperProps } from './CardWizardStepper';

type PickedStepperProps = Pick<CardWizardStepperProps, 'onStepClick'>;

interface WizardStepProps {
  onSubmit?: () => void;
}

export interface CardWizardProps extends PickedStepperProps {
  children?: React.ReactNode;
  className?: string;
  step: number;
  submitting?: boolean;
  showStepper?: boolean;
}

const focusFirstInput = (node: HTMLElement) => {
  const firstInput = node?.querySelector<
    HTMLInputElement | HTMLTextAreaElement
  >('input,textarea');
  if (firstInput) {
    firstInput.focus();
  }
};

const CardWizard: React.FC<CardWizardProps> = ({
  children,
  className,
  onStepClick,
  step,
  submitting = false,
  showStepper = true,
}) => {
  const [mounted, setMounted] = useState(false);
  const childrenAry = React.Children.toArray(children)
    .filter(React.isValidElement)
    .filter(Boolean);
  const nChildren = childrenAry.length;

  return (
    <div className={cn(block(), className)}>
      <div className={block('card')}>
        {showStepper && (
          <CardWizardStepper
            {...{ onStepClick, step }}
            totalSteps={nChildren}
          />
        )}
        <div className={block('step-container')}>
          {childrenAry.map(
            (child: React.ReactElement<WizardStepProps>, index) => (
              <BemCssTransition
                key={child.key}
                mountOnEnter
                unmountOnExit
                enter={mounted}
                in={index === step}
                onEntered={(node: HTMLElement) => {
                  // HACK: because our implementation of BemCssTransition breaks the appear prop
                  if (index === 0) {
                    setMounted(true);
                  }
                  focusFirstInput(node);
                }}
                timeout={{
                  enter: 600,
                  exit: 300,
                }}
                transitionClassName={block('step-transition')}
              >
                {React.cloneElement(child, {
                  onSubmit: createChainedFunction(child.props.onSubmit, () =>
                    onStepClick(Math.min(index + 1, nChildren - 1)),
                  ),
                })}
              </BemCssTransition>
            ),
          )}
        </div>
        {submitting && <LoadingOverlay title="Submitting..." />}
      </div>
    </div>
  );
};

export default CardWizard;
