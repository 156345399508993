import * as React from 'react';

import { CircularGradientCheck } from 'components/icons/circular-gradient';
import { welcomeBlock as block } from '../utils';
import CardWizardStep, {
  CardWizardControlButton,
  CardWizardControls,
} from './CardWizard/CardWizardStep';

interface Props {
  onSubmit: () => void;
}

const UpsellCompletedStep: React.FC<Props> = ({ onSubmit }) => (
  <CardWizardStep className={block()}>
    <div className={block('icon')}>
      <CircularGradientCheck
        contentsClassName={block('upsell-icon-contents')}
      />
    </div>
    <div className={block('message')}>
      <CardWizardStep.H1>Your plan is upgraded!</CardWizardStep.H1>
      <CardWizardStep.H2>
        Now let’s put it to use by making some amazing content.
      </CardWizardStep.H2>
    </div>
    <CardWizardControls className={block('controls')}>
      <CardWizardControlButton onClick={onSubmit}>next</CardWizardControlButton>
    </CardWizardControls>
  </CardWizardStep>
);

export default UpsellCompletedStep;
