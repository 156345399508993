import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import CircleProgress from 'components/CircleProgress';
import useToggle from 'hooks/useToggle';
import {
  standardizationProgressSelector,
  standardizationStatusSelector,
} from 'redux/modules/async-audio-clipper/selectors';
import { lerp } from 'utils/numbers';
import useProcessAudioEventTracking from './useProcessAudioEventTracking';

interface Props {
  audioLoading: boolean;
  uploadProgress: number;
  isFile: boolean;
  src: string | Blob;
}

const getPodcastSearchProgress = ({
  standardizationStatus,
  standardizationProgress,
}) => {
  if (standardizationStatus === 'processing' && !standardizationProgress) {
    return {
      label: 'Processing',
      value: 29,
    };
  }

  if (standardizationStatus === 'queued') {
    return {
      label: 'Processing',
      value: 12,
    };
  }

  return {
    label: 'Processing',
    value: 1,
  };
};

const getUploadProgress = ({
  standardizationProgress,
  standardizationStatus,
  uploadProgress,
}) => {
  if (standardizationStatus === 'processing' && !standardizationProgress) {
    return {
      label: 'Processing',
      value: 66,
    };
  }

  if (standardizationStatus === 'queued') {
    return {
      label: 'Processing',
      value: 61,
    };
  }

  return {
    label: 'Uploading',
    value: lerp(0, 48, uploadProgress / 100),
  };
};

function AsyncAudioClipperProgress(props: Props) {
  const { audioLoading, uploadProgress = 0, isFile, src } = props;
  const [visible, toggle] = useToggle(true);
  const standardizationProgress = useSelector(standardizationProgressSelector);
  const standardizationStatus = useSelector(standardizationStatusSelector);

  const overallProgress = useMemo(() => {
    if (audioLoading === false) {
      return {
        label: 'Finalizing',
        value: 100,
      };
    }

    if (
      standardizationStatus === 'completed' ||
      standardizationStatus === 'skipStandardization'
    ) {
      return {
        label: 'Finalizing',
        value: 95,
      };
    }

    if (standardizationStatus === 'processing' && standardizationProgress) {
      return {
        label: 'Re-encoding',
        value: lerp(72, 95, standardizationProgress / 100),
      };
    }

    if (isFile) {
      return getUploadProgress({
        standardizationProgress,
        standardizationStatus,
        uploadProgress,
      });
    }

    return getPodcastSearchProgress({
      standardizationStatus,
      standardizationProgress,
    });
  }, [
    audioLoading,
    isFile,
    standardizationProgress,
    standardizationStatus,
    uploadProgress,
  ]);

  useProcessAudioEventTracking({
    src,
    progressPercentage: overallProgress.value,
  });

  const isReady = overallProgress.value === 100;

  useEffect(() => {
    if (isReady) {
      const handler = setTimeout(() => {
        toggle(false);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }
    toggle(true);
    return () => {};
  }, [isReady, toggle]);

  if (visible) {
    return (
      <CircleProgress
        percentage={overallProgress.value}
        size="sm"
        overlay
        label={overallProgress.label}
      />
    );
  }

  return null;
}

export default AsyncAudioClipperProgress;
