import * as React from 'react';
import { AspectRatio } from 'components/AspectRatioSelector';
import ConnectedModal from 'containers/ConnectedModal';
import ProjectCopyModalContent from './ProjectCopyModalContent';
import { block } from './utils';

export interface ProjectCopyModalResult {
  projectId: string;
  name: string;
  aspectRatio: AspectRatio;
}

export interface ProjectCopyModalProps {}

const ProjectCopyModal: React.FC<ProjectCopyModalProps> = () => {
  return (
    <ConnectedModal name="ProjectCopy" className={block()} title="Copy Project">
      <ProjectCopyModalContent />
    </ConnectedModal>
  );
};

export default ProjectCopyModal;
