import cn from 'classnames';
import * as React from 'react';
import Spinner from 'react-spinkit';
import _ from 'underscore';

import FillAspectRatio from 'components/FillAspectRatio';
import ProjectVideoPreview, {
  ProjectVideoPreviewProps,
} from 'components/ProjectVideoPreview';
import { getAspectRatioName } from 'utils/aspect-ratio';
import EmptyPreview from './EmptyPreview';

type PreviewProps = Pick<
  ProjectVideoPreviewProps,
  'aspectRatio' | 'playing' | 'volume'
>;

export interface PreviewPaneProps extends PreviewProps {
  className?: string;
  embedUrl?: string;
  onPlayerDestroy?: ProjectVideoPreviewProps['onDestroy'];
  onPlayerPlay?: ProjectVideoPreviewProps['onPlay'];
  onPlayerReady?: () => void;
  onPlayerPause?: ProjectVideoPreviewProps['onPause'];
  renderDragFixOverlay?: ProjectVideoPreviewProps['dragFixOverlay'];
  savingOverlayClassName?: string;
  showSavingOverlay?: boolean;
}

export default class PreviewPane extends React.Component<PreviewPaneProps> {
  public static defaultProps: Partial<PreviewPaneProps> = {
    onPlayerDestroy: _.noop,
    onPlayerPlay: _.noop,
    onPlayerReady: _.noop,
    renderDragFixOverlay: false,
    showSavingOverlay: false,
  };

  public componentDidMount() {
    const { onPlayerReady, embedUrl } = this.props;

    /*
     * if there's no embedUrl, we won't render the EmbedPlayer, so EmbedPlayer's onReady will never
     * fire.  fire it here in case anyone else is waiting for it.
     */
    if (!embedUrl) {
      onPlayerReady();
    }
  }

  private renderSavingOverlay() {
    const { savingOverlayClassName, showSavingOverlay } = this.props;

    const overlayClassName = cn(
      'preview-pane__saving',
      {
        'preview-pane__saving--fade-in': showSavingOverlay,
        'preview-pane__saving--fade-out': !showSavingOverlay,
      },
      savingOverlayClassName,
    );

    const spinnerClassName = cn(
      'preview-pane__spinner',
      'spinner--wave',
      'spinner--large',
      'spinner--blue',
    );

    return (
      <div className={overlayClassName}>
        <div className="preview-pane__saving-text">Saving</div>
        <Spinner className={spinnerClassName} spinnerName="wave" />
      </div>
    );
  }

  private renderPlayer() {
    const {
      aspectRatio,
      embedUrl,
      onPlayerDestroy,
      onPlayerReady,
      onPlayerPlay,
      onPlayerPause,
      playing,
      renderDragFixOverlay,
      volume,
    } = this.props;

    const player = (
      <ProjectVideoPreview
        aspectRatio={aspectRatio}
        className="preview-pane__preview"
        dragFixOverlay={renderDragFixOverlay}
        src={embedUrl}
        onPlay={onPlayerPlay}
        onPause={onPlayerPause}
        onReady={onPlayerReady}
        onDestroy={onPlayerDestroy}
        playing={playing}
        volume={volume}
      />
    );

    return (
      <FillAspectRatio
        aspectRatio={aspectRatio}
        className="preview-pane__preview-wrapper"
        element={player}
      />
    );
  }

  public render() {
    const { aspectRatio, className, embedUrl } = this.props;

    const isEmpty = !embedUrl;
    const ratioName = getAspectRatioName(aspectRatio);
    const ratioClass = `preview-pane--${ratioName}`;
    const containerClassName = cn(ratioClass, className, {
      'preview-pane': true,
      'preview-pane--default': !isEmpty,
      'preview-pane--empty': isEmpty,
    });

    if (isEmpty) {
      return (
        <div className={containerClassName}>
          <EmptyPreview />
        </div>
      );
    }

    return (
      <div className={containerClassName}>
        {this.renderPlayer()}
        {this.renderSavingOverlay()}
      </div>
    );
  }
}
