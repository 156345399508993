import { SubscriptionPeriod } from 'blocks/Pricing/types';
import {
  EnterpriseSubTier,
  PlanName,
  PurchaseableSubTier,
  PurchaseableTier,
  SubscribablePlanName,
  SubTier,
  Tier,
} from 'redux/middleware/api/plan-service';

const PURCHASEABLE_SUB_TIERS: Readonly<SubTier[]> = [
  SubTier.PRO_PAID,
  SubTier.PRO_20200922_PAID,
  SubTier.PRO_20211112_PAID,
  SubTier.BASIC_20200922_PAID,
  SubTier.BASIC_20211112_PAID,
  SubTier.UNLIMITED_20211112_PAID,
];

const PURCHASEABLE_TIERS: Readonly<Tier[]> = [
  Tier.BASIC,
  Tier.PRO,
  Tier.UNLIMITED,
];

const PRO_SUB_TIERS: Readonly<SubTier[]> = [
  SubTier.PRO_PAID,
  SubTier.PRO_TRIAL,
  SubTier.PRO_ASSIGNED,
  SubTier.PRO_FREE_TEMPORARY,
  SubTier.PRO_EDUCATION,
  SubTier.PRO_20200922_PAID,
  SubTier.PRO_20211112_PAID,
];

const ENTERPRISE_SUB_TIERS: Readonly<SubTier[]> = [
  SubTier.ENTERPRISE,
  SubTier.ENTERPRISE_API,
];

export function isPurchaseableSubTier(
  subTier: SubTier,
): subTier is PurchaseableSubTier {
  return PURCHASEABLE_SUB_TIERS.includes(subTier);
}

export function isPurchaseableTier(tier: Tier): tier is PurchaseableTier {
  return PURCHASEABLE_TIERS.includes(tier);
}

export function isFreeSubTier(subTier: SubTier): boolean {
  return subTier === SubTier.FREE;
}

export function isProSubTier(subTier: SubTier): boolean {
  return PRO_SUB_TIERS.includes(subTier);
}

export function isBasicSubTier(subTier: SubTier): boolean {
  return [SubTier.BASIC_20200922_PAID, SubTier.BASIC_20211112_PAID].includes(
    subTier,
  );
}

export function isUnlimitedSubTier(subTier: SubTier): boolean {
  return [SubTier.UNLIMITED_20211112_PAID, SubTier.UNLIMITED_ASSIGNED].includes(
    subTier,
  );
}

export function isEnterpriseSubTier(
  subTier: SubTier,
): subTier is EnterpriseSubTier {
  return ENTERPRISE_SUB_TIERS.includes(subTier);
}

export function getSubscriptionPeriod(planName: PlanName): SubscriptionPeriod {
  switch (planName) {
    case PlanName.PRO_YEARLY:
    case PlanName.PRO_20200922_YEARLY:
    case PlanName.PRO_20211112_YEARLY:
    case PlanName.BASIC_20200922_YEARLY:
    case PlanName.BASIC_20211112_YEARLY:
    case PlanName.UNLIMITED_20211112_YEARLY:
      return 'yearly';

    case PlanName.PRO_MONTHLY:
    case PlanName.PRO_20200922_MONTHLY:
    case PlanName.PRO_20211112_MONTHLY:
    case PlanName.BASIC_20200922_MONTHLY:
    case PlanName.BASIC_20211112_MONTHLY:
    case PlanName.UNLIMITED_20211112_MONTHLY:
      return 'monthly';

    default:
      return undefined;
  }
}

// downgrading is only available for pro plans
export function getDowngradePlan(
  currentPlanName: PlanName,
): SubscribablePlanName {
  switch (currentPlanName) {
    case PlanName.PRO_20211112_MONTHLY:
    case PlanName.PRO_20200922_MONTHLY:
    case PlanName.PRO_MONTHLY:
      return PlanName.BASIC_20211112_MONTHLY;

    case PlanName.PRO_20211112_YEARLY:
    case PlanName.PRO_20200922_YEARLY:
    case PlanName.PRO_YEARLY:
      return PlanName.BASIC_20211112_YEARLY;

    case PlanName.UNLIMITED_20211112_MONTHLY:
      return PlanName.PRO_20211112_MONTHLY;

    case PlanName.UNLIMITED_20211112_YEARLY:
      return PlanName.PRO_20211112_YEARLY;

    default:
      return undefined;
  }
}

// SPAR:15945 why does this have to exist?
export function getTierFromPlan(name: PlanName): Tier {
  switch (name) {
    case PlanName.FREE_FOREVER:
      return Tier.FREE;

    case PlanName.PRO_20200922_MONTHLY:
    case PlanName.PRO_20200922_YEARLY:
    case PlanName.PRO_20211112_YEARLY:
    case PlanName.PRO_20211112_MONTHLY:
    case PlanName.PRO_ASSIGNED:
    case PlanName.PRO_EDUCATION_FOREVER:
    case PlanName.PRO_MONTHLY:
    case PlanName.PRO_TRIAL:
    case PlanName.PRO_YEARLY:
      return Tier.PRO;

    case PlanName.ENTERPRISE_API_FOREVER:
    case PlanName.ENTERPRISE_FOREVER:
      return Tier.ENTERPRISE;

    case PlanName.BASIC_20200922_YEARLY:
    case PlanName.BASIC_20200922_MONTHLY:
    case PlanName.BASIC_20211112_YEARLY:
    case PlanName.BASIC_20211112_MONTHLY:
      return Tier.BASIC;

    case PlanName.UNLIMITED_20211112_MONTHLY:
    case PlanName.UNLIMITED_20211112_YEARLY:
      return Tier.UNLIMITED;

    default:
      return undefined;
  }
}
