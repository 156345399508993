import { createApiAction } from '../utils';
import * as types from './types';

const ALL_PLAN_NAMES: types.PlanName[] = Object.values(types.PlanName);

function apiCallAction<M extends types.ServiceMethod, A = undefined>(
  method: M,
  args?: A,
) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export function getPlans(
  planNames: types.PlanName[] = ALL_PLAN_NAMES,
): types.GetPlansAction {
  return apiCallAction(types.ServiceMethod.GET_PLANS, planNames);
}

export function getMySubscription(): types.GetMySubscriptionAction {
  return apiCallAction(types.ServiceMethod.GET_MY_SUBSCRIPTION);
}

interface UpdateMySubscriptionArgs {
  subscribeTo?: types.SubscribablePlanName;
  autoRenewal?: boolean;
  forcedCancellationNotice?: boolean;
  pausePlanDurationMonths?: number;
  cancelPause?: boolean;
  promotionCode?: types.SubscriptionPromotionCode;
}

export function updateMySubscription({
  subscribeTo,
  autoRenewal,
  forcedCancellationNotice,
  pausePlanDurationMonths,
  cancelPause,
  promotionCode,
}: UpdateMySubscriptionArgs): types.UpdateMySubscriptionAction {
  return apiCallAction(types.ServiceMethod.UPDATE_MY_SUBSCRIPTION, [
    subscribeTo,
    autoRenewal,
    forcedCancellationNotice,
    pausePlanDurationMonths,
    cancelPause,
    promotionCode,
  ] as types.UpdateMySubscriptionArgs);
}

export function createPaymentMethod({
  stripeToken,
  paymentMethodId,
}: {
  stripeToken?: string;
  paymentMethodId?: string;
}): types.CreatePaymentMethodAction {
  return apiCallAction(types.ServiceMethod.CREATE_PAYMENT_METHOD, [
    stripeToken,
    paymentMethodId,
  ] as types.CreatePaymentMethodArgs);
}

export function getMyCustomerDetails(): types.GetCustomerAction {
  return apiCallAction(types.ServiceMethod.GET_CUSTOMER);
}

export function getMyCoupons(): types.GetMyCouponsAction {
  return apiCallAction(types.ServiceMethod.GET_MY_COUPONS);
}

export function getMyReferral(): types.GetMyReferralAction {
  return apiCallAction(types.ServiceMethod.GET_MY_REFERRAL);
}

export function sendReferralInvitations(
  emailAddresses: string[],
): types.SendReferralInvitationsAction {
  const invitations = emailAddresses.map(address => ({ email: address }));
  return apiCallAction(types.ServiceMethod.SEND_REFERRAL_INVITATIONS, [
    invitations,
  ] as types.SendReferralInvitationsArgs);
}

export function getExternalPortalLink(
  returnUrl?: string,
): types.GetExternalPortalLinkAction {
  return apiCallAction(types.ServiceMethod.GET_EXTERNAL_PORTAL_LINK, [
    returnUrl,
  ] as types.GetExternalPortalLinkArgs);
}

export function acceptPromotion(
  promotionKey: string,
): types.AcceptPromotionAction {
  return apiCallAction(types.ServiceMethod.ACCEPT_PROMOTION, { promotionKey });
}
