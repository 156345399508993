import * as React from 'react';

import { IconProps } from './types';

const Pro: React.SFC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 28" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#pro-gradient)"
        fillRule="nonzero"
        d="M43.154 4C44.174 4 45 4.827 45 5.846v24.308c0 1.02-.827 1.846-1.846 1.846H1.846A1.846 1.846 0 0 1 0 30.154V5.846C0 4.826.827 4 1.846 4h41.308zM42 7H3v22h39V7zm-10.431 7c.664 0 1.257.157 1.777.47.521.314.926.76 1.215 1.34.29.58.435 1.243.439 1.992v.358c0 .76-.142 1.432-.426 2.016a3.13 3.13 0 0 1-1.209 1.35c-.522.316-1.117.474-1.785.474-.668 0-1.26-.152-1.778-.457a3.126 3.126 0 0 1-1.209-1.308c-.289-.567-.44-1.217-.455-1.952v-.438c0-.763.141-1.436.423-2.019a3.122 3.122 0 0 1 1.212-1.35c.526-.317 1.125-.476 1.796-.476zm-18.44.107c.6 0 1.13.11 1.592.329.461.22.819.531 1.072.936.253.404.38.863.38 1.377 0 .759-.274 1.366-.822 1.82-.547.455-1.299.682-2.254.682h-1.206v2.642H10v-7.786h3.13zm9.129 0c.93 0 1.657.205 2.181.615.524.41.786.99.786 1.738 0 .542-.11.991-.33 1.347-.222.357-.567.646-1.038.867l1.638 3.139v.08H23.47l-1.385-2.749h-1.023v2.749h-1.89v-7.786h3.086zm9.311 1.364c-.9 0-1.398.637-1.491 1.91l-.012.207-.005.572c0 .763.127 1.35.382 1.76.255.41.634.615 1.137.615.477 0 .845-.202 1.104-.605.23-.358.359-.855.387-1.492l.006-.245v-.359c0-.773-.13-1.36-.39-1.762-.26-.4-.633-.601-1.118-.601zm-18.418.085h-1.26v2.246h1.238c.367 0 .646-.09.838-.273.192-.181.288-.438.288-.77 0-.363-.099-.654-.296-.871-.198-.218-.467-.328-.808-.332zm9.107 0h-1.196v2.14h1.196c.359 0 .629-.094.81-.282.182-.187.272-.448.272-.783s-.091-.598-.274-.789c-.184-.19-.453-.286-.808-.286z"
        transform="translate(0 -4)"
      />
    </g>
  </svg>
);

export default Pro;
