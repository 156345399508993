import { Button, Heading, Spacer, Switch, Tooltip } from '@sparemin/blockhead';
import React, { useCallback, useMemo, useState } from 'react';

import AutomationPostModalBackButton from 'components/AutomationPostModalBackButton';
import ModalContentWithMedia from 'components/Modal/ModalContentWithMedia';
import { Step } from 'components/SteppedModal';
import { block } from '../../utils';
import DisclosureSection from './DisclosureSection';

export interface UseDisclosureStepProps {
  isDisclosureVisible: boolean;
  brandContent: boolean;
  brandOrganic: boolean;
  onBrandContentChange: (brandContent: boolean) => void;
  onBrandOrganicChange: (brandOrganic: boolean) => void;
  onBackClick: () => void;
  onHideModal: () => void;
  onVisibilityChange: (isDisclosureVisible: boolean) => void;
}

export default function useDisclosureStep({
  isDisclosureVisible,
  brandContent,
  brandOrganic,
  onBrandContentChange,
  onBrandOrganicChange,
  onBackClick,
  onHideModal,
  onVisibilityChange,
}: UseDisclosureStepProps): Step {
  const [localBrandContent, setLocalBrandContent] = useState<
    boolean | undefined
  >();
  const [localBrandOrganic, setLocalBrandOrganic] = useState<
    boolean | undefined
  >();

  const isUntouched = useMemo(
    (): boolean =>
      localBrandContent === undefined && localBrandOrganic === undefined,
    [localBrandContent, localBrandOrganic],
  );

  const handleToggleDisclosureVisibility = useCallback(
    (isVisible: boolean): void => {
      onVisibilityChange(isVisible);
      setLocalBrandContent(isVisible ? brandContent : false);
      setLocalBrandOrganic(isVisible ? brandOrganic : false);
    },
    [brandContent, brandOrganic, onVisibilityChange],
  );

  const handleResetValues = useCallback((): void => {
    setLocalBrandContent(undefined);
    setLocalBrandOrganic(undefined);
  }, []);

  const handleBackClick = useCallback((): void => {
    if (!brandContent && !brandOrganic) {
      onVisibilityChange(false);
    }

    if (!isUntouched && !isDisclosureVisible) {
      onVisibilityChange(true);
    }

    setLocalBrandContent(brandContent);
    setLocalBrandOrganic(brandOrganic);
    onBackClick();
    handleResetValues();
  }, [
    brandContent,
    brandOrganic,
    handleResetValues,
    isDisclosureVisible,
    isUntouched,
    onBackClick,
    onVisibilityChange,
  ]);

  const handleSaveChanges = useCallback((): void => {
    if (localBrandContent === false && localBrandOrganic === false) {
      onVisibilityChange(false);
    }

    onBrandContentChange(localBrandContent ?? brandContent);
    onBrandOrganicChange(localBrandOrganic ?? brandOrganic);
    onBackClick();
    handleResetValues();
  }, [
    brandContent,
    brandOrganic,
    handleResetValues,
    localBrandContent,
    localBrandOrganic,
    onBackClick,
    onBrandContentChange,
    onBrandOrganicChange,
    onVisibilityChange,
  ]);

  return {
    id: 'disclosure',
    hideFooter: true,
    replaceBody: true,
    component: (
      <>
        <ModalContentWithMedia
          onClose={onHideModal}
          className="composer-step-content"
        >
          <ModalContentWithMedia.ModalContentWithMediaColumn>
            <img
              src="https://static.sparemin.com/static/webapp-assets/auto-posting-detail/auto-posting-detail-tik-tok-image-a-202403191010.png"
              className={block('composer-image-left')}
            />
          </ModalContentWithMedia.ModalContentWithMediaColumn>

          <ModalContentWithMedia.ModalContentWithMediaColumn className="composer-content-column">
            <Spacer
              orientation="vertical"
              align="center"
              justify="center"
              space={2.5}
              className={block('step-container')}
            >
              <AutomationPostModalBackButton
                text="Disclose video content"
                onClick={handleBackClick}
              />

              <Spacer
                orientation="horizontal"
                align="center"
                justify="space-between"
                space="20px"
              >
                <Heading level={4} className={block('disclosure-description')}>
                  Turn on to disclose that your videos promote goods or services
                  in exchange for something of value. Your videos could promote
                  yourself, a third party, or both.
                </Heading>

                <Switch
                  isSelected={isDisclosureVisible}
                  onChange={handleToggleDisclosureVisibility}
                />
              </Spacer>

              <Spacer
                orientation="vertical"
                align="flex-start"
                space="30px"
                className={block('disclosure-bottom')}
              >
                {isDisclosureVisible && (
                  <DisclosureSection
                    brandContent={localBrandContent ?? brandContent}
                    brandOrganic={localBrandOrganic ?? brandOrganic}
                    onBrandContentChange={setLocalBrandContent}
                    onBrandOrganicChange={setLocalBrandOrganic}
                  />
                )}

                <Tooltip
                  arrow
                  placement="top"
                  contents="You need to indicate if your content promotes yourself, a third party, or both."
                  isDisabled={!isUntouched}
                >
                  <Button
                    fluid
                    isDisabled={isUntouched}
                    onPress={handleSaveChanges}
                  >
                    Save changes
                  </Button>
                </Tooltip>
              </Spacer>
            </Spacer>
          </ModalContentWithMedia.ModalContentWithMediaColumn>
        </ModalContentWithMedia>
      </>
    ),
  };
}
