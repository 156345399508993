import * as React from 'react';
import { Step } from 'react-joyride';
import StepItem from 'blocks/OnboardingAnimations/components/StepItem';

export const AUDIOGRAM_KEY = 'audiogram';
export const FULL_EPISODE_KEY = 'full-episode';

const steps: Step[] = [
  {
    target: `[data-joyride-spotlight=${FULL_EPISODE_KEY}]`,
    spotlightPadding: 10,
    content: (
      <StepItem content="For videos up to 10 minutes, choose this. It provides access to all our amazing features." />
    ),
    disableBeacon: true,
    floaterProps: {
      target: `[data-joyride-tooltip=${AUDIOGRAM_KEY}]`,
    } as any,
  },
  {
    target: `[data-joyride-spotlight=${FULL_EPISODE_KEY}]`,
    spotlightPadding: 10,
    content: (
      <StepItem content="For videos up to 2 hours, choose this. It has less features, but lets you create longer videos." />
    ),
    disableBeacon: true,
    floaterProps: {
      target: `[data-joyride-tooltip=${FULL_EPISODE_KEY}]`,
    } as any,
  },
];

export default steps;
