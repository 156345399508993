import * as React from 'react';

import { block } from './utils';

export interface CalloutsProps {
  children?: React.ReactNode;
}

const Callouts: React.FC<CalloutsProps> = ({ children }) => (
  <div className={block('callouts')}>{children}</div>
);

export default Callouts;
