import cn from 'classnames';
import React from 'react';
import bem from 'utils/bem';

export interface SocialCircleProps {
  background?: string;
  children?: React.ReactNode;
  className?: string;
}

const block = bem('social-circle');

const SocialCircle: React.FC<SocialCircleProps> = ({
  background,
  children,
  className,
}) => (
  <div
    className={cn(block(), className)}
    style={{ ['--background' as any]: background }}
  >
    {children}
  </div>
);

export default SocialCircle;
