import { createSelector } from 'reselect';

export const getContentTypeSelector = state =>
  state.getIn(['media', 'contentType']);
export const getContentTypeStatusSelector = state =>
  state.getIn(['media', 'getContentTypeStatus']);

const animatedSelector = state => state.getIn(['media', 'animated']);

export const animatedGifSelector = createSelector(
  [getContentTypeSelector, animatedSelector],
  (contentType, animated) => contentType === 'image/gif' && animated,
);
