import classNames from 'classnames';
import * as React from 'react';
import _ from 'underscore';

import FontAwesome from 'components/FontAwesome';

interface IProps {
  className?: string;
  disabled?: boolean;
  keywords?: string[];
  onKeywordClick?: (keyword: string) => void;
}

const SearchKeywords: React.SFC<IProps> = ({
  className,
  disabled,
  keywords,
  onKeywordClick,
}) => {
  const handleKeywordClick = (keyword: string) => () => onKeywordClick(keyword);

  return (
    <div
      className={classNames('media-search__keywords', {
        'media-search__keywords--disabled': disabled,
        [className]: !!className,
      })}
    >
      {keywords.map(keyword => (
        <div
          className="media-search__keyword"
          key={keyword}
          onClick={disabled ? undefined : handleKeywordClick(keyword)}
        >
          {keyword}
          <FontAwesome
            className="media-search__icon"
            icon="long-arrow-alt-up"
          />
        </div>
      ))}
    </div>
  );
};

SearchKeywords.defaultProps = {
  disabled: false,
  keywords: [],
  onKeywordClick: _.noop,
};

export default SearchKeywords;
export { IProps as SearchKeywordsProps };
