import cn from 'classnames';
import * as React from 'react';
import Draggable, { DraggableProps } from 'react-draggable';
import { isEqual } from 'underscore';

import { block } from './utils';

type PickedDraggableProps = Pick<
  DraggableProps,
  'onDrag' | 'onStart' | 'onStop' | 'position'
>;

export interface ResizeHandleProps extends PickedDraggableProps {
  dragging?: boolean;
  type: 'horizontal' | 'vertical';
}

export default class ResizeHandle extends React.Component<ResizeHandleProps> {
  public shouldComponentUpdate(nextProps: Readonly<ResizeHandleProps>) {
    const { position, ...props } = this.props;
    const { position: nextPosition, ...next } = nextProps;

    return !isEqual(nextPosition, position) || !isEqual(next, props);
  }

  public render() {
    const { dragging, type, ...draggableProps } = this.props;
    return (
      <Draggable
        {...draggableProps}
        axis={type === 'horizontal' ? 'y' : 'x'}
        bounds="parent"
      >
        <div
          className={cn(block('resizer', { dragging }))}
          style={{ position: dragging ? 'absolute' : 'relative' }}
        />
      </Draggable>
    );
  }
}
