import cn from 'classnames';
import React from 'react';
import ListItem from './ListItem';
import { block } from './utils';

export interface ListProps {
  children?: React.ReactNode;
  className?: string;
}

interface ListComponent extends React.FC<ListProps> {
  Item: typeof ListItem;
}

/**
 * Basic list component.
 *
 * This was originally refactored out of the Automation CMS, so the component is
 * a little more focused on that use case than others.
 *
 * The general structure of the list should be as follows:
 *
 * ```
 *  <List>
 *    <List.Item>
 *      <List.Item.Contents>
 *        <List.Item.Artwork />
 *        <List.Item.Text />
 *      </List.Item.Contents />
 *    </List.Item>
 *  </List>
 * ```
 */
const List: ListComponent = ({ children, className }) => (
  <ul className={cn(block(), className)}>{children}</ul>
);

List.Item = ListItem;

export default List;
