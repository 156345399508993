import Cropperjs from 'cropperjs';
import _ from 'underscore';

import { CropMetadata, Omit } from 'types';
import { CropperData, DefaultMetadata, Size } from './types';

/** * converts data from workspace size specified in data.containerSize to
 * workspace size specified in workspaceSize
 *
 * @param data
 * @param workspaceSize
 */
export function scaleData(
  data: DefaultMetadata,
  workspaceSize: Size,
): DefaultMetadata & { crop: Cropperjs.Data } {
  const scaledData: DefaultMetadata & { crop: Cropperjs.Data } = {
    container: { ...workspaceSize },
    crop: {
      ...data.crop,
      rotate: 0,
      scaleX: 1,
      scaleY: 1,
    },
  };

  const { canvas } = data;
  if (canvas) {
    const containerScale = Math.min(
      data.container.width / workspaceSize.width,
      data.container.height / workspaceSize.height,
    );

    // don't distort the canvas.  it has to maintain the same AR
    const ratio = canvas.width / canvas.height;
    const canvasWidth = canvas.width * containerScale;
    const canvasHeight = (canvasWidth * containerScale) / ratio;

    /*
     * calculate the new canvas position offset from the center of the container
     * in case the source container was a different size.  for example if the
     * source container had a lot of vertical space with a positive `top` value
     * for the canvas and the new workspace has significantly less vertical
     * space, the `top` offset might have to be negative, but scaling it would
     * just give a smaller positive number.
     */

    // original canvas offset from center
    const originalCenterOffsetX = data.container.width / 2 - canvas.left;
    const originalCenterOffsetY = data.container.height / 2 - canvas.top;

    const centerOffsetX = originalCenterOffsetX * containerScale;
    const centerOffsetY = originalCenterOffsetY * containerScale;

    scaledData.canvas = {
      height: canvasHeight,
      width: canvasWidth,

      // calculate center offset from workspace top left
      left: workspaceSize.width / 2 - centerOffsetX,
      top: workspaceSize.height / 2 - centerOffsetY,
    };
  }

  return scaledData;
}

/**
 * extracts CallbackData from the cropperjs instance
 *
 * @param cropper
 */
export function getData(
  cropper: Cropperjs,
): Omit<CropMetadata, 'constrained'> & Pick<CropperData, 'croppedCanvas'> {
  /*
   * getContainerData() and getCropBoxData() both return some keys that we
   * don't necessarily need.  rather than pass these keys around and
   * potentially have them wind up in the configuration, just omit them.  can
   * remove this filter if we ever need them
   */
  return {
    canvas: _.pick(cropper.getCanvasData(), 'height', 'width', 'left', 'top'),
    container: cropper.getContainerData(),
    crop: cropper.getData(),
    croppedCanvas: cropper.getCroppedCanvas(),
  };
}
