import cn from 'classnames';
import * as React from 'react';

import { block } from '../../utils';

export interface TrackAssetBodyProps {
  children?: React.ReactNode;
  dragging?: boolean;
  id: string;
  itemClassName?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLElement>) => void;
  onTransitionEnd?: (e: React.TransitionEvent<HTMLElement>) => void;
  resizing?: boolean;
  style?: React.CSSProperties;
  width: number;
}

const TrackAssetBody: React.SFC<TrackAssetBodyProps> = ({
  children,
  dragging,
  id,
  itemClassName,
  onClick,
  onMouseDown,
  onMouseUp,
  onTransitionEnd,
  resizing,
  style,
  width,
}) => (
  <div id={id} className={block('track-asset')} style={style}>
    <div
      className={cn(
        block('track-asset-item', {
          dragging,
          active: dragging || resizing,
        }),
        itemClassName,
      )}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTransitionEnd={onTransitionEnd}
      style={{ width }}
    >
      {children}
    </div>
  </div>
);

TrackAssetBody.defaultProps = {
  dragging: false,
  resizing: false,
};

export default TrackAssetBody;
