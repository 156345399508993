import { useDispatch } from 'react-redux';
import { updateMyPreferences } from 'redux/modules/common/actions';
import { pushModal } from 'redux/modules/modal';

export default function useEmailSettings() {
  const dispatch = useDispatch();

  function unsubscribeFromAllEmails() {
    dispatch(
      updateMyPreferences({
        videoExportEmailEnabled: false,
        reminderEmailEnabled: false,
        automationEmailAlertEnabled: false,
      }),
    );
  }

  function onEmailChange() {
    dispatch(
      pushModal({
        name: 'UpdateEmail',
      }),
    );
  }

  return { onEmailChange, unsubscribeFromAllEmails };
}
