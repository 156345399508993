import * as React from 'react';
import { connect } from 'react-redux';

import { aspectRatioSelector } from 'redux/modules/embed/selectors';
import { isPollingImageToVideoSelector } from 'redux/modules/image-to-video/selectors';
import { getContentType } from 'redux/modules/media/actions';
import {
  animatedGifSelector,
  getContentTypeSelector,
  getContentTypeStatusSelector,
} from 'redux/modules/media/selectors';
import * as mixpanelActions from 'redux/modules/mixpanel';
import { updateModal } from 'redux/modules/modal/actions';
import { showError } from 'redux/modules/notification';
import { videoUploadLimitMbSelector } from 'redux/modules/pricing';
import { prepareVideoForEditing } from 'redux/modules/video-edit';
import { VideoAssetType } from 'redux/modules/video-edit/types';
import { Dispatch, State, ThunkAction } from 'redux/types';
import { DropZoneType } from 'utils/constants';
import { isAnimatedGif } from 'utils/image';
import { IProps, MediaType } from './MediaImport';

type DispatchProps = Pick<
  IProps,
  | 'onError'
  | 'onFileAccepted'
  | 'onImageAccepted'
  | 'onUrlRequested'
  | 'onVideoAccepted'
>;
type StateProps = Pick<
  IProps,
  | 'aspectRatio'
  | 'contentType'
  | 'contentTypeStatus'
  | 'maxFileSizeMb'
  | 'isPollingImageToVideo'
>;
type OwnProps = Pick<
  IProps,
  'isControlledModal' | 'onFileAccepted' | 'onUrlAccepted'
>;

type OnUrlAccepted = IProps['onUrlAccepted'];
type OnFileAccepted = IProps['onFileAccepted'];

const handleVideoAccepted = (
  url: string,
  onUrlAccepted: OnUrlAccepted,
  type: VideoAssetType = 'video',
): ThunkAction<void> => dispatch => {
  dispatch(prepareVideoForEditing(url, undefined, type));
  onUrlAccepted(url, type);
};

const handleImageAccepted = (
  url: string,
  onUrlAccepted: OnUrlAccepted,
): ThunkAction<void> => (dispatch, getState) => {
  const animatedGif = animatedGifSelector(getState());
  if (animatedGif) {
    dispatch(handleVideoAccepted(url, onUrlAccepted, 'gif-video'));
  } else {
    onUrlAccepted(url, 'image');
  }
};

const handleFileAccepted = (
  file: File,
  type: MediaType,
  onFileAccepted: OnFileAccepted,
): ThunkAction<void> => dispatch => {
  if (type === 'image') {
    isAnimatedGif(file).then(animated => {
      if (animated) {
        dispatch(prepareVideoForEditing(file, undefined, 'gif-video'));
      }
      onFileAccepted(
        file,
        type === 'image' && animated ? 'gif-video' : 'image',
      );
    });
  } else {
    dispatch(prepareVideoForEditing(file));
    onFileAccepted(file, type);
  }
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { isControlledModal, onFileAccepted, onUrlAccepted }: OwnProps,
): DispatchProps => ({
  onError: (error, file = undefined) => {
    if (!isControlledModal) {
      dispatch(
        updateModal({
          name: 'AddMediaModal',
          params: { file: null },
        }),
      );
    }
    if (file) {
      dispatch(
        mixpanelActions.onFileUpload(
          DropZoneType.EDITOR_MEDIA,
          file,
          error.message,
        ),
      );
    }

    dispatch(
      showError({
        message: error.message,
        code: error.code,
        dismissAfterSec: 5,
      }),
    );
  },
  onFileAccepted: (file, type) => {
    dispatch(mixpanelActions.onFileUpload(DropZoneType.EDITOR_MEDIA, file));
    dispatch(handleFileAccepted(file, type, onFileAccepted));
    if (!isControlledModal) {
      dispatch(
        updateModal({
          name: 'AddMediaModal',
          params: { file: null },
        }),
      );
    }
  },
  onImageAccepted: url => dispatch(handleImageAccepted(url, onUrlAccepted)),
  onUrlRequested: url => dispatch((getContentType as any)(url)),
  onVideoAccepted: url => dispatch(handleVideoAccepted(url, onUrlAccepted)),
});

const mapStateToProps = (state: State): StateProps => ({
  isPollingImageToVideo: isPollingImageToVideoSelector(state),
  aspectRatio: aspectRatioSelector(state),
  contentType: getContentTypeSelector(state),
  contentTypeStatus: getContentTypeStatusSelector(state),
  maxFileSizeMb: videoUploadLimitMbSelector(state),
});

export default function(component: React.ComponentType<IProps>) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
