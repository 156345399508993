import { connect } from 'react-redux';

import {
  audioClipperMaxDurationMillisSelector,
  audioUrlSelector,
  isAudiogramSelector,
  setAudioClip,
  transcriptionConfigSelector,
} from 'redux/modules/audio-wizard';
import {
  audioClipSelector,
  entireAudioInstanceIdSelector,
} from 'redux/modules/audio-wizard/selectors';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import AudioWizardClipStep, {
  AudioWizardClipStepProps as Props,
} from './AudioWizardClipStep';

type DispatchProps = Pick<Props, 'onRegionUpdate'>;
type StateProps = Pick<
  Props,
  | 'audioSrc'
  | 'entireAudioInstanceId'
  | 'isAudiogram'
  | 'maxDurationMillis'
  | 'region'
  | 'transcriptionConfig'
>;

const mapStateToProps = (state: State): StateProps => ({
  audioSrc: audioUrlSelector(state),
  entireAudioInstanceId: entireAudioInstanceIdSelector(state),
  isAudiogram: isAudiogramSelector(state),
  maxDurationMillis: audioClipperMaxDurationMillisSelector(state),
  region: audioClipSelector(state),
  transcriptionConfig: transcriptionConfigSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onRegionUpdate: region =>
    dispatch(setAudioClip(region.startMillis, region.endMillis)),
});

const component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioWizardClipStep);
export type AudioWizardClipStepProps = PropsOf<typeof component>;
export default component;
