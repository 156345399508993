import cn from 'classnames';
import React, { useCallback } from 'react';
import { InstagramAccountOption } from './types';
import { block } from './utils';

export interface AccountOptionProps {
  option: InstagramAccountOption;
  selected?: boolean;
  onClick?: (newOption: InstagramAccountOption) => void;
}

const AccountOption: React.FC<AccountOptionProps> = ({
  option,
  selected,
  onClick,
}) => {
  const handleClick = useCallback((): void => {
    onClick(option);
  }, [onClick, option]);

  return (
    <div
      className={cn(block('account-option', { selected }))}
      onClick={handleClick}
    >
      <img src={option?.logo} className={block('account-option--logo')} />

      <span className={block('account-option--label')}>{option?.label}</span>
    </div>
  );
};

export default AccountOption;
