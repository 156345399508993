import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import FormField, { FormFieldProps } from './FormField';
import { block } from './utils';

type InputProps = Pick<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  'value' | 'placeholder'
>;

type Props = FormFieldProps<InputProps> & {
  onChange?: (text: string, e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  resizable?: boolean;
};

export default class TextAreaInput extends React.Component<Props> {
  private textarea;

  public static defaultProps: Partial<Props> = {
    onChange: noop,
    resizable: false,
  };

  private handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    this.props.onChange(e.target.value, e);

  private textareaRef = el => (this.textarea = el);

  public focus = () => this.textarea && this.textarea.focus();

  public render() {
    const { resizable, onChange, ...props } = this.props;
    return (
      <FormField<InputProps> {...props}>
        <textarea
          className={cn(block('textarea-input', { noresize: !resizable }))}
          onChange={this.handleChange}
          ref={this.textareaRef}
        />
      </FormField>
    );
  }
}

export { Props as TextAreaFieldProps };
