import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFreeTrialReminder from 'blocks/Pricing/containers/FreeTrialReminderModal/useFreeTrialReminder';
import usePlanCancelledModal from 'blocks/Pricing/containers/PlanCancelledModal/usePlanCancelledModal';
import { SubTier } from 'redux/middleware/api/plan-service';
import { rehydratedSelector } from 'redux/modules/app/selectors';
import { pushModal } from 'redux/modules/modal';
import { subtierSelector } from 'redux/modules/pricing';

export default function useModalOnLoad() {
  const dispatch = useDispatch();
  const rehydrated = useSelector(rehydratedSelector);
  const [loaded, setLoaded] = useState(false);

  // wait for rehydration and then save the rehydrated subtier to local state
  // so that it can be passed to useFreeTrialReminder as the previous subtier
  const [rehydratedSubtier, setRehydratedSubtier] = useState<SubTier>();
  const subtier = useSelector(subtierSelector);

  const freeTrialModalType = useFreeTrialReminder({
    prevSubtier: rehydratedSubtier,
  });
  const showPlanCancelledModal = usePlanCancelledModal();

  // track when loading is complete so that we know when to evaluate conditions
  // to auto-show modal
  const handleLoaded = useCallback(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (rehydrated && !rehydratedSubtier) {
      setRehydratedSubtier(subtier);
    }
  }, [rehydrated, rehydratedSubtier, subtier]);

  useEffect(() => {
    // once rehydration and loading is complete, modal coditions can be evaluated
    if (rehydrated && loaded) {
      // cancelation modal shouldn't conflict with free trial modals, but in the
      // event it does, it should take precedence
      if (showPlanCancelledModal) {
        dispatch(pushModal({ name: 'PlanCancelled' }));
      } else if (freeTrialModalType) {
        dispatch(
          pushModal({
            name: 'FreeTrialReminder',
            params: {
              type: freeTrialModalType,
            },
          }),
        );
      }
    }
  }, [
    dispatch,
    freeTrialModalType,
    loaded,
    rehydrated,
    showPlanCancelledModal,
  ]);

  return {
    onLoad: handleLoaded,
  };
}
