import { LottieConfiguration } from 'redux/middleware/api/embed-service';
import {
  PlaceholderStringReplacements,
  TextPlaceholder,
  TextReplacements,
} from './types';

const TEXT_PLACEHOLDERS: TextPlaceholder[] = [
  {
    textType: 'podcastTitle',
    placeholderText: '[[PODCAST_TITLE]]',
  },
  {
    textType: 'episodeTitle',
    placeholderText: '[[EPISODE_TITLE]]',
  },
];

function getMainImageIndex(config: LottieConfiguration): number {
  return config?.dynamicAssets.mainImage.assetIdx;
}

export function getMainImageUrl(config: LottieConfiguration): string {
  return config?.animationData.assets[getMainImageIndex(config)].p;
}

export function replaceMainImageUrl(
  config: LottieConfiguration,
  url: string,
): LottieConfiguration {
  if (!config) return undefined;

  if (url === getMainImageUrl(config)) return config;

  const assets = [...config.animationData.assets];
  const assetIndex = getMainImageIndex(config);
  assets[assetIndex] = {
    ...assets[assetIndex],
    p: url,
  };

  return {
    ...config,
    animationData: {
      ...config.animationData,
      assets,
    },
  };
}

function textIncludesSubText(text: string, subTexts: string[]): boolean {
  if (!text) return false;
  return subTexts.find(subText => text.includes(subText)) !== undefined;
}

function replacePlaceholdersInString(
  text: string,
  placeholderToText: PlaceholderStringReplacements,
) {
  return Object.keys(placeholderToText).reduce((acc, placeholder) => {
    return acc.replace(placeholder, placeholderToText[placeholder]);
  }, text);
}

function setElementText(elem: any, text: string) {
  elem.canResizeFont(true);
  elem.setMinimumFontSize(1);
  elem.updateDocumentData({ t: text }, 0);
}

function getElementTextWithPlaceholders(
  elem: any,
  placeholders: string[],
): string {
  if (!('textSpans' in elem)) return undefined;

  if (textIncludesSubText(elem.data?.nm, placeholders)) return elem.data.nm;

  if (textIncludesSubText(elem.baseElement?.textContent, placeholders)) {
    return elem.baseElement.textContent;
  }

  return undefined;
}

function replaceTextPlaceholders(
  elems: any,
  placeholderToText: PlaceholderStringReplacements,
) {
  if (!elems || elems.length === 0) return;

  const placeholders = Object.keys(placeholderToText);

  elems.forEach(elem => {
    replaceTextPlaceholders(elem.elements, placeholderToText);

    const subjectText = getElementTextWithPlaceholders(elem, placeholders);

    if (!subjectText) return;
    setElementText(
      elem,
      replacePlaceholdersInString(subjectText, placeholderToText),
    );
  });
}

export function replaceText(animObj: any, replacements: TextReplacements) {
  const placeholderToText: PlaceholderStringReplacements = TEXT_PLACEHOLDERS.reduce(
    (acc, { textType, placeholderText }) => {
      acc[placeholderText] = replacements[textType] ?? '';
      return acc;
    },
    {},
  );

  replaceTextPlaceholders(animObj.renderer.elements, placeholderToText);
}
