import * as React from 'react';
import Spinner from 'react-spinkit';

import { FormField, FormFieldProps } from 'components/Form';
import { block } from '../utils';

interface IProps extends FormFieldProps {
  message: string;
}

const SpinnerInput: React.SFC<IProps> = ({ message, ...props }) => (
  <FormField {...props}>
    <div className={block('spinner-input')}>
      <span className={block('spinner-message')}>{message}</span>
      <Spinner className={block('spinner')} spinnerName="wave" />
    </div>
  </FormField>
);

export default SpinnerInput;
export { IProps as SpinnerInputProps };
