import * as React from 'react';
import { omit, pick } from 'underscore';

import { FormFieldProps } from 'components/Form';
import SettingsFormLabel from './SettingsFormLabel';

interface IProps {
  primaryLabel?: string;
  secondaryLabel?: string;
}

const asSettingsFormField = () => <T extends FormFieldProps>(
  WrappedComponent: React.ComponentType<T>,
) => {
  type Props = IProps & T;

  const AsSettingsFormField: React.SFC<Props> = props => {
    const { primaryLabel, secondaryLabel } = pick(
      props,
      'primaryLabel',
      'secondaryLabel',
    );
    const restProps = omit(props, 'primaryLabel', 'secondaryLabel');

    return (
      <WrappedComponent
        horizontal={{
          control: { xs: 4 },
          label: { xs: 8 },
        }}
        label={
          <SettingsFormLabel
            primaryLabel={primaryLabel}
            secondaryLabel={secondaryLabel}
          />
        }
        {...restProps}
      />
    );
  };

  return AsSettingsFormField;
};

export default asSettingsFormField;
export { IProps as AsSettingsFormFieldProps };
