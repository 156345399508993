import classNames from 'classnames';
import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import _ from 'underscore';

import BemCssTransition from 'components/BemCssTransition';
import FontAwesome from 'components/FontAwesome';

import Takeover from 'components/Takeover';
import { block } from './utils';
import WizardControlButton from './WizardControlButton';

interface IButtonProps {
  className: string;
  onClick: () => void;
}

interface INavigator {
  step: number;
  stepId?: string;
  next: () => void;
  previous: () => void;
  jump: (stepId: string) => void;
}

type StepType = 'regular' | 'takeover';

interface IBaseStep<Id extends string = string> {
  component: JSX.Element | ((currentStep: IIndexedStep) => JSX.Element);
  stepType?: StepType;
  stepId?: Id;
}

interface IWizardStep<Id extends string = string> extends IBaseStep<Id> {
  completedName?: string;
  description?: string | React.ReactElement<any>;
  keepMounted?: boolean;
  name: string;
  renderCancelButton?: (props: IButtonProps) => JSX.Element;
  renderNextButton?: (props: IButtonProps) => JSX.Element;
  showInNav?: boolean;
  title?: string | React.ReactElement<any>;
  titleClassName?: string;
}

interface IFinalStep<Id extends string = string> extends IBaseStep<Id> {
  stepType: 'takeover';
}

type IStep<Id extends string = string> = IWizardStep<Id> | IFinalStep<Id>;

type IIndexedStep<Id extends string = string> = IStep<Id> & {
  index: number;
};

interface IProps<Id extends string = string> {
  bodyClassName?: string;
  buttonClassName?: string;
  canBacktrack?: boolean;
  className?: string;
  disabledSteps?: number[];
  navClassName?: string;
  onCancelClick?: (nav: INavigator) => void;
  onFileUpload?: (file: File) => void;
  onNextClick?: (nav: INavigator) => void;
  onStepClick?: (toStep: IIndexedStep<Id>) => Promise<boolean>;
  onStepChange?: (
    toStep: IIndexedStep<Id>,
    fromStep?: IIndexedStep<Id>,
    action?: WizardNavigationAction,
  ) => void;
  step?: number;
  steps?: IStep<Id>[];
}

export enum WizardNavigationAction {
  STEP_CLICK = 'STEP_CLICK',
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
  JUMP = 'JUMP',
}

interface IDataState {
  step?: number;
  action?: WizardNavigationAction;
}

const dataFactory = Record<IDataState>({ step: undefined, action: undefined });

interface IState {
  data: RecordOf<IDataState>;
  inTransitionData?: RecordOf<IDataState>;
}

const SUPPORTED_TRANSITION_STEP_TYPES: StepType[] = ['regular', 'takeover'];

export default class Wizard<Id extends string = string> extends React.Component<
  IProps<Id>,
  IState
> {
  public static Button = WizardControlButton;

  public static defaultProps: Partial<IProps> = {
    canBacktrack: true,
    disabledSteps: [],
    onCancelClick: _.noop,
    onStepChange: _.noop,
    onStepClick: () => Promise.resolve(true),
    step: 0,
  };

  constructor(props: IProps<Id>) {
    super(props);

    const { step } = this.props;

    this.state = {
      data: dataFactory({ step }),
      inTransitionData: undefined,
    };
  }

  public componentDidMount() {
    const { onStepChange, steps } = this.props;
    const { data } = this.state;

    onStepChange({
      ...steps[data.step],
      index: data.step,
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps<Id>>,
    prevState: Readonly<IState>,
  ) {
    const { steps: prevSteps } = prevProps;
    const { onStepChange, steps } = this.props;
    const { data: prevData } = prevState;
    const { data } = this.state;

    if (prevData.step !== data.step) {
      const fromStep = {
        ...prevSteps[prevData.step],
        index: prevData.step,
      };
      const toStep = {
        ...steps[data.step],
        index: data.step,
      };

      onStepChange(toStep, fromStep, data.action);
    }
  }

  private handleStepClick = (step: number) => async (
    e: React.SyntheticEvent<any>,
  ) => {
    const { onStepClick, steps } = this.props;
    const { data } = this.state;

    e.preventDefault();

    const allowNavigation = await onStepClick({
      ...steps[data.step],
      index: data.step,
    });

    if (allowNavigation) {
      this.setState(({ data: prevData }) => ({
        data: prevData
          .set('step', step)
          .set('action', WizardNavigationAction.STEP_CLICK),
      }));
    }
  };

  private handleNextClick = () => {
    const { onNextClick, steps } = this.props;
    const { data } = this.state;

    if (_.isUndefined(onNextClick)) {
      this.next();
    } else {
      onNextClick({
        jump: this.jump,
        next: this.next,
        previous: this.previous,
        step: data.step,
        stepId: steps[data.step].stepId || '',
      });
    }
  };

  private getNextButton(data: RecordOf<IDataState>) {
    const { steps } = this.props;

    const props = {
      className: 'wizard__next',
      onClick: this.handleNextClick,
    };

    const stepData = steps[data.step];

    if (stepData.stepType === 'takeover') {
      return null;
    }

    const { renderNextButton } = stepData;
    const nextButton = renderNextButton && renderNextButton(props);

    if (nextButton === null) {
      return null;
    }

    return (
      nextButton || (
        <Wizard.Button {...props} theme="next">
          Next
        </Wizard.Button>
      )
    );
  }

  private getCancelButton(data: RecordOf<IDataState>) {
    const { onCancelClick, steps } = this.props;

    const props = {
      className: 'wizard__cancel',
      onClick: () =>
        onCancelClick({
          jump: this.jump,
          next: this.next,
          previous: this.previous,
          step: data.step,
          stepId: steps[data.step].stepId || '',
        }),
    };

    const stepData = steps[data.step];

    if (stepData.stepType === 'takeover') {
      return null;
    }

    const { renderCancelButton } = stepData;
    const cancelButton = renderCancelButton && renderCancelButton(props);

    if (cancelButton === null) {
      return null;
    }

    return cancelButton || <Wizard.Button {...props}>Cancel</Wizard.Button>;
  }

  private isStepTypeTransition = (
    fromStep?: IStep,
    toStep?: IStep,
  ): boolean => {
    const fromStepType = fromStep?.stepType ?? 'regular';
    const toStepType = toStep?.stepType ?? 'regular';

    // Only step type within a supported type are qualified for triggering a transition.
    // If that condition is met, from and to step types are checked to be different.
    return (
      SUPPORTED_TRANSITION_STEP_TYPES.includes(fromStepType) &&
      SUPPORTED_TRANSITION_STEP_TYPES.includes(toStepType) &&
      fromStepType !== toStepType
    );
  };

  private checkHasMultipleVisibleSteps = (): boolean => {
    const { steps } = this.props;

    return (
      steps.filter(
        step =>
          // Takeover steps have no support for nav.
          step.stepType !== 'takeover' &&
          // If `showInNav` is omitted then we consider the
          // step to be visible.
          (_.isUndefined(step.showInNav) || step.showInNav),
      ).length > 1
    );
  };

  public next = () => {
    const { steps } = this.props;
    const { data } = this.state;

    const hasTransition = this.isStepTypeTransition(
      steps[data.step],
      steps[data.step + 1],
    );
    const updatedData = data
      .update('step', step => Math.min(steps.length - 1, step + 1))
      .set('action', WizardNavigationAction.NEXT);

    this.setState({
      data: updatedData,
      inTransitionData: hasTransition ? data : undefined,
    });
  };

  public previous = () => {
    const { steps } = this.props;
    const { data } = this.state;

    const hasTransition = this.isStepTypeTransition(
      steps[data.step],
      steps[data.step - 1],
    );
    const updatedData = data
      .update('step', step => Math.max(0, step - 1))
      .set('action', WizardNavigationAction.PREVIOUS);

    this.setState({
      data: updatedData,
      inTransitionData: hasTransition ? data : undefined,
    });
  };

  public jump = (stepId: string) => {
    const { steps } = this.props;
    const { data } = this.state;
    const idx = steps.findIndex(step => step.stepId === stepId);

    const hasTransition = this.isStepTypeTransition(
      steps[data.step],
      steps[idx],
    );
    const updatedData = data
      .set('step', idx)
      .set('action', WizardNavigationAction.JUMP);

    if (idx > -1) {
      this.setState({
        data: updatedData,
        inTransitionData: hasTransition ? data : undefined,
      });
    }
  };

  private onStepTransitionEnded = () => {
    this.setState({
      inTransitionData: undefined,
    });
  };

  private renderNav(data?: RecordOf<IDataState>) {
    const { canBacktrack, disabledSteps, navClassName, steps } = this.props;

    if (!data) {
      return null;
    }

    const step = data.get('step');

    const className = classNames(block('nav'), navClassName);

    let stepDisplayNumber = 1;

    return (
      <div className={className}>
        {this.checkHasMultipleVisibleSteps() &&
          steps.reduce((acc, currStep, index) => {
            // Takeover steps have no support for nav
            if (currStep.stepType === 'takeover') {
              return acc;
            }

            const { completedName, showInNav = true, name } = currStep;

            if (!showInNav) {
              return acc;
            }

            const isStepCompleted = index < step;
            const isStepDisabled =
              disabledSteps.includes(index) ||
              (isStepCompleted && !canBacktrack);

            const stepClassName = classNames('wizard__step', {
              'wizard__step--active': index === step,
              'wizard__step--disabled': isStepDisabled,
              'wizard__step--complete': isStepCompleted,
              'wizard__step--incomplete': index > step,
            });

            if (acc.length > 0) {
              acc.push(
                <div className="wizard__connector" key={`${name}-divider`} />,
              );
            }

            acc.push(
              <div className={stepClassName} key={name}>
                <button
                  className="wizard__step-num"
                  disabled={!isStepCompleted || isStepDisabled}
                  onClick={this.handleStepClick(index)}
                  type="button"
                >
                  {!isStepCompleted ? (
                    stepDisplayNumber
                  ) : (
                    <FontAwesome icon="check" />
                  )}
                </button>
                <div className="wizard__step-name">
                  {isStepCompleted && completedName ? completedName : name}
                </div>
              </div>,
            );

            stepDisplayNumber += 1;
            return acc;
          }, [])}
      </div>
    );
  }

  private renderRegularSteps = (
    data?: RecordOf<IDataState>,
  ): React.ReactElement[] => {
    const { steps } = this.props;

    return steps.reduce((acc, currStep, index) => {
      // Takeover steps keep mounted have no support
      if (currStep.stepType === 'takeover') {
        return acc;
      }

      const { keepMounted, component } = currStep;

      const element = !_.isFunction(component)
        ? component
        : component({
            ...steps[data?.step],
            index: data?.step,
          });
      if (index === data?.step || keepMounted) {
        /*
         * some steps use a key on the component to remount it.
         *
         * if the element already has a key, concatenate it with the key
         * that Wizard is applying to reduce the chance of key collision
         * since the caller likely has no idea that keys are being added
         * here
         */
        acc.push(
          React.cloneElement(element, {
            key: element.key ? `${element.key}-${index}` : index,
          }),
        );
      }
      return acc;
    }, []);
  };

  private renderRegularStepBody(data?: RecordOf<IDataState>) {
    const { bodyClassName, steps } = this.props;

    const className = classNames('wizard__body', bodyClassName);
    const stepIndex = data?.get('step');
    const stepData = steps[stepIndex];

    const stepComponents = this.renderRegularSteps(data);

    // Allows keeping a step mounted when the step type is takeover.
    // This avoids a regular step not being kept when its keep mounted flag is
    // enabled.
    if (!data || stepData.stepType === 'takeover') {
      return <div className={className}>{stepComponents}</div>;
    }

    const { title, titleClassName, description } = stepData;

    const titleClass = classNames(
      'wizard__title',
      { 'wizard__title--first': stepIndex === 0 },
      titleClassName,
    );

    const titleElement = (() => {
      if (!title) return null;
      if (_.isString(title)) {
        return (
          <div role="heading" className={titleClass}>
            {title}
          </div>
        );
      }
      return React.cloneElement(title, {
        className: classNames(titleClass, title.props.className),
      });
    })();

    const descriptionElement = (() => {
      if (!description) return null;
      const descriptionClassName = 'wizard__description';
      if (_.isString(description)) {
        return <div className={descriptionClassName}>{description}</div>;
      }
      return React.cloneElement(description, {
        className: classNames(
          descriptionClassName,
          description.props.className,
        ),
      });
    })();

    return (
      <div className={className}>
        <>
          {titleElement}
          {descriptionElement}
          {stepComponents}
        </>
      </div>
    );
  }

  private renderTakeoverStepBody = (
    data?: RecordOf<IDataState>,
    transitionActive?: boolean,
  ) => {
    const { steps } = this.props;

    if (!data) {
      return null;
    }

    const step = data.get('step');
    const stepData = steps[step];
    const { component } = stepData;

    // Hides the takeover step if current step nor in transition is a takeover one.
    if (stepData.stepType !== 'takeover') {
      return null;
    }

    const BodyComponent = !_.isFunction(component)
      ? React.cloneElement(component, { transitionActive })
      : component({
          ...steps[data?.step],
          index: data?.step,
        });

    return <Takeover>{BodyComponent}</Takeover>;
  };

  private renderButtons(data?: RecordOf<IDataState>) {
    const { buttonClassName } = this.props;

    if (!data) {
      return null;
    }

    const className = classNames('wizard__buttons', buttonClassName);

    const next = this.getNextButton(data);
    const cancel = this.getCancelButton(data);

    return (
      (next || cancel) && (
        <div className={className}>
          {next}
          {cancel}
        </div>
      )
    );
  }

  public render() {
    const { className, steps } = this.props;
    const { data: currData, inTransitionData } = this.state;
    const stepIndex = currData.get('step');

    const containerClassName = classNames(
      'wizard',
      'wizard--default',
      { 'wizard--last-step': stepIndex === steps.length - 1 },
      className,
    );

    // As the typing states that steps type is regular by default, step type is set to
    // regular when it is not defined
    const currStepData = steps[stepIndex];
    const currStepType = currStepData?.stepType ?? 'regular';
    const isTransitionActive = !!inTransitionData;

    // Data is defined for each type of step. Current step is checked for knowing which
    // is the current step mode.
    // - For regular step data, currData is used for rendering both nav, body and buttons.
    // Otherwise, transition one will be used for maintaining it mounted while the transition
    // is being performed.
    // - For takeover step data, curr data is using for rendering the takeover. Otherwise,
    // transition data will be used for keeping the step mounted while the transition lasts.
    const regularStepData =
      currStepType === 'regular' ? currData : inTransitionData;
    const takeoverStepData =
      currStepType === 'takeover' ? currData : inTransitionData;

    // The double transitions allow a simultaneous fade-out/fade-in for the steps
    // that change the step type. When a regular step switches to a takeover (or the opposite)
    // the transitions are enabled for triggering the animations.
    return (
      <>
        <BemCssTransition
          appear
          className={block('regular-step')}
          in={currStepType === 'regular'}
          onEntered={this.onStepTransitionEnded}
          timeout={600}
        >
          <div className={containerClassName}>
            {this.renderNav(regularStepData)}
            {this.renderRegularStepBody(regularStepData)}
            {this.renderButtons(regularStepData)}
          </div>
        </BemCssTransition>
        <BemCssTransition
          appear
          className={block('takeover-step')}
          in={currStepType === 'takeover'}
          onEntered={this.onStepTransitionEnded}
          timeout={600}
        >
          <Takeover>
            {this.renderTakeoverStepBody(takeoverStepData, isTransitionActive)}
          </Takeover>
        </BemCssTransition>
      </>
    );
  }
}

export {
  IStep as Step,
  IIndexedStep as IndexedStep,
  IButtonProps as ButtonProps,
  INavigator as Navigator,
  IProps as WizardProps,
};
