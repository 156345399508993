import { CustomizeStepSubmitType } from 'containers/ProjectWizards/AudiogramWizard/AudiogramCustomizeStep';
import { SubmitConfig } from 'containers/ProjectWizards/AudiogramWizard/types';
import { ThunkAction } from 'redux/types';
import { AUDIOGRAM_CUTOFF_MILLIS } from 'utils/constants';
import { cancelWaveformPolling } from '../common/actions';
import { sentryAction } from '../sentry';
import { exportFromWizard } from '../wizard-export/actions';
import { Type } from './action-types';
import { entireAudioEnhancer } from './enhancers';

export const createAudiogram = (
  type: CustomizeStepSubmitType,
  config: SubmitConfig,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const { audioClip, audioSourceType, ...rest } = config;
  const { aspectRatio, transcription } = rest;
  const entireAudioInstanceId = entireAudioEnhancer.selectors.entireAudioInstanceIdSelector(
    getState(),
  );
  const shouldTranscribe =
    transcription.transcribe &&
    audioClip.endMillis - audioClip.startMillis <= AUDIOGRAM_CUTOFF_MILLIS;

  dispatch(
    sentryAction(
      {
        payload: {
          aspectRatioDimensions: aspectRatio,
        },
        type: Type.AUDIOGRAM_WIZARD_CREATE_REQUEST,
      },
      {
        tags: { wizard: 'audiogram', wizardStage: 'createProject' },
      },
    ),
  );

  await dispatch(
    exportFromWizard({
      ...rest,
      initiateExport: type === 'export',
      projectCreatMethod: 'audiogram',
      audio: {
        ...audioClip,
        entireAudioInstanceId,
      },
      transcription: {
        ...transcription,
        transcribe: shouldTranscribe,
      },
    }),
  );
};

export const clearAudiogramWizard = (): ThunkAction<void> => dispatch => {
  dispatch(cancelWaveformPolling());
  dispatch(entireAudioEnhancer.actions.cancelPolling());
  dispatch({ type: Type.AUDIOGRAM_WIZARD_CLEAR });
};

export const {
  clearEntireAudio,
  uploadEntireAudio,
} = entireAudioEnhancer.actions;
