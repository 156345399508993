import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';
import { omitUndefined } from 'utils/collections';
import { createRequest } from '../utils';

import * as types from './types';

const BASE_PATH = '/api/v1/plan';
const MY_SUBSCRIPTION_PATH = `${BASE_PATH}/subscription/me`;

const plansSchema = new schema.Entity('plans', {}, { idAttribute: 'name' });

async function getPlans(
  request: SuperAgentStatic,
  args: types.GetPlansArgs[],
): Promise<types.GetPlanResult> {
  const res = await request.get(BASE_PATH).query({ plan: args });

  return normalize(res.body.data, [plansSchema]);
}

async function getMySubscription(
  request: SuperAgentStatic,
): Promise<types.GetMySubscriptionResult> {
  const res = await request.get(MY_SUBSCRIPTION_PATH);
  return res.body;
}

async function updateMySubscription(
  request: SuperAgentStatic,
  args: types.UpdateMySubscriptionArgs,
): Promise<types.UpdateMySubscriptionResult> {
  const [
    plan,
    autoRenewal,
    forcedCancellationNotice,
    pausePlanDurationMonths,
    cancelPause,
    promotionCode,
  ] = args;

  const res = await request.put(MY_SUBSCRIPTION_PATH).send(
    omitUndefined({
      subscribeTo: plan,
      autoRenewal,
      forcedCancellationNotice,
      pausePlanDurationMonths,
      cancelPause,
      promotionCode,
    }),
  );
  return res.body;
}

async function createPaymentMethod(
  request: SuperAgentStatic,
  args: types.CreatePaymentMethodArgs,
  userId: number,
): Promise<types.CreatePaymentMethodResult> {
  const [stripeToken, paymentMethodId] = args;
  const res = await request
    .post(`${BASE_PATH}/user/${userId}/payment-method`)
    .send({ stripeToken, paymentMethodId });

  return res.body;
}

async function getCustomer(
  request: SuperAgentStatic,
  _: types.GetCustomerArgs,
  userId: number,
): Promise<types.GetCustomerResult> {
  const res = await request.get(`${BASE_PATH}/user/${userId}/customer`);

  return res.body;
}

async function getMyCoupons(
  request: SuperAgentStatic,
): Promise<types.GetMyCouponsResult> {
  const res = await request.get(`${BASE_PATH}/coupon/offer/me`);
  return res.body;
}

async function getMyReferral(
  request: SuperAgentStatic,
): Promise<types.GetMyReferralResult> {
  const res = await request.get(`${BASE_PATH}/referral/owner/me`);
  return res.body;
}

async function sendReferralInvitations(
  request: SuperAgentStatic,
  args: types.SendReferralInvitationsArgs,
): Promise<types.SendReferralInvitationResult> {
  const [invitations] = args;
  await request.post(`${BASE_PATH}/referral/email-invitation`).send({
    referralInvitations: invitations,
  });

  return undefined;
}

async function getExternalPortalLink(
  request: SuperAgentStatic,
  args: types.GetExternalPortalLinkArgs,
): Promise<types.GetExternalPortalLinkResult> {
  const [returnUrl] = args;
  const res = await request
    .post(`${BASE_PATH}/subscription/external-portal-link`)
    .send({ returnUrl });
  return res.body;
}

async function acceptPromotion(
  request: SuperAgentStatic,
  { promotionKey }: types.AcceptPromotionArgs,
): Promise<types.AcceptPromotionResult> {
  const res = await request
    .post(`${BASE_PATH}/promotion/accept`)
    .send({ promotionKey });
  return res.body;
}

export const handle = (
  method: any,
  args: any,
  token: string,
  userId: number,
): any => {
  const request = createRequest({
    token,
    baseUrl: spareminConfig.services.plan,
  });

  switch (method) {
    case types.ServiceMethod.GET_PLANS:
      return getPlans(request, args);

    case types.ServiceMethod.GET_MY_SUBSCRIPTION:
      return getMySubscription(request);

    case types.ServiceMethod.UPDATE_MY_SUBSCRIPTION:
      return updateMySubscription(request, args);

    case types.ServiceMethod.CREATE_PAYMENT_METHOD:
      return createPaymentMethod(request, args, userId);

    case types.ServiceMethod.GET_CUSTOMER:
      return getCustomer(request, args, userId);

    case types.ServiceMethod.GET_MY_COUPONS:
      return getMyCoupons(request);

    case types.ServiceMethod.GET_MY_REFERRAL:
      return getMyReferral(request);

    case types.ServiceMethod.SEND_REFERRAL_INVITATIONS:
      return sendReferralInvitations(request, args);

    case types.ServiceMethod.GET_EXTERNAL_PORTAL_LINK:
      return getExternalPortalLink(request, args);

    case types.ServiceMethod.ACCEPT_PROMOTION:
      return acceptPromotion(request, args);
  }

  return undefined;
};
