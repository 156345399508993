import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  episodeIdSelector,
  subscriptionItemIdSelector,
} from 'redux/modules/clip-select';
import { loadClipSuggestions, reset } from 'redux/modules/clip-select/actions';
import { Dispatch } from 'redux/types';
import { ClipSelectPageSource } from 'types';
import { FormValues } from '../types';
import { pageBlock as block } from '../utils';
import { ClipSelectAnimationProvider } from './ClipSelectAnimationContext';
import { ClipSelectProvider } from './ClipSelectContext';
import { ClipSelectNavigationProvider } from './ClipSelectNavigationContext';
import { useNavigation } from './ClipSelectNavigationContext/navigation-machine';
import ClipSelectPageContents from './ClipSelectPageContents';
import { ClipSelectTrackingProvider } from './ClipSelectTrackingContext';

export interface ClipSelectPageProps {
  onSubmit?: (values: FormValues) => void;
  source: ClipSelectPageSource;
}

const ClipSelectPage: React.FC<ClipSelectPageProps> = ({
  source,
  onSubmit,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const episodeId = useSelector(episodeIdSelector);
  const subscriptionItemId = useSelector(subscriptionItemIdSelector);
  const machine = useNavigation();

  const [state] = machine;

  useEffect(() => {
    if (!episodeId) {
      return;
    }

    dispatch(loadClipSuggestions(episodeId, subscriptionItemId));
  }, [dispatch, episodeId, subscriptionItemId]);

  useEffect(
    () => () => {
      dispatch(reset);
    },
    [dispatch],
  );

  const isLoading = state.matches('loading');
  const isIntro = state.matches('intro');

  return (
    <div
      className={block({
        exporting: state.matches('export'),
        scrollable: !isIntro && !isLoading,
      })}
    >
      <ClipSelectNavigationProvider value={machine}>
        <ClipSelectTrackingProvider source={source}>
          <ClipSelectProvider onSubmit={onSubmit}>
            <ClipSelectAnimationProvider>
              <ClipSelectPageContents />
            </ClipSelectAnimationProvider>
          </ClipSelectProvider>
        </ClipSelectTrackingProvider>
      </ClipSelectNavigationProvider>
    </div>
  );
};

export default ClipSelectPage;
