import * as React from 'react';

import AudioLibraryTile, { AudioLibraryTileProps } from './AudioLibraryTile';

interface IProps extends Pick<AudioLibraryTileProps, 'selected'> {
  onSelect?: (value: 'none') => void;
}

export default class NoAudioTile extends React.Component<IProps> {
  private handleSelect = () => this.props.onSelect('none');

  public render() {
    const { selected } = this.props;

    return (
      <AudioLibraryTile
        className="no-audio-tile"
        onClick={this.handleSelect}
        selected={selected}
      >
        <span className="no-audio-tile__text">No Audio</span>
      </AudioLibraryTile>
    );
  }
}

export { IProps as NoAudioTileProps };
