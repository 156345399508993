import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { selectors as embedSelectors } from 'redux/modules/embed';
import { State } from 'redux/types';
import { MinimapProps } from './Minimap';

type StateProps = Pick<MinimapProps, 'durationSeconds'>;

const mapStateToDurationSeconds = createSelector(
  [embedSelectors.embedDurationMillisSelector],
  durationMillis => durationMillis / 1000,
);

const mapStateToProps = (state: State): StateProps => ({
  durationSeconds: mapStateToDurationSeconds(state),
});

export default function(component: React.ComponentType<MinimapProps>) {
  return connect(mapStateToProps)(component);
}
