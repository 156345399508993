import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';
import FontAwesome from 'components/FontAwesome';

import { LinkedinGhost } from 'components/icons';
import { LinkedinOrganizationSelectorOption } from './types';
import { block } from './utils';

export interface OrganizationOptionProps {
  className?: string;
  option: LinkedinOrganizationSelectorOption;
  onClick?: (value: any) => void;
  selected?: boolean;
}

const { useCallback } = React;

const OrganizationOption: React.FC<OrganizationOptionProps> = ({
  className,
  onClick = noop,
  option,
  selected = false,
}) => {
  const { id, label, value, imageUrl } = option;
  const handleClick = useCallback(() => onClick(value), [onClick, value]);
  const isSwitchAccount = id === 'switch-account';

  return (
    <div
      className={cn(block('option', { selected }), className)}
      onClick={handleClick}
    >
      {isSwitchAccount && (
        <FontAwesome
          className={block('switch-account-icon')}
          icon="sign-out-alt"
        />
      )}

      {imageUrl && <img src={imageUrl} alt={value} />}

      {!imageUrl && !isSwitchAccount && (
        <LinkedinGhost className={block('ghost')} />
      )}

      <span className={block('option-label')}>{label}</span>
    </div>
  );
};

export default OrganizationOption;
