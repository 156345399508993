import { extractCancelId, extractId, extractTimeoutMillis } from './utils';

/**
 * delay middleware for redux.  schedules actions to be dispatched in the future.
 *
 * `delayActionBuilder` can be used to add metadata to actions in order to execute them at some
 * point in the future.
 *
 * dispatching delayed actions returns an object.  invoking `object.cancel()` will cancel the
 * delayed object.  alternatively, if you don't have access to the dispatch response, you can
 * create a cancellation action using `delayActionBuilder` as long as you have the id used to
 * schedule the action to be cancelled.
 */
export default function() {
  const timers = {};

  function cancelTimer(id) {
    const scheduleId = timers[id];
    if (!scheduleId) return;

    clearTimeout(scheduleId);
    delete timers[id];
  }

  return next => action => {
    const timeoutMillis = extractTimeoutMillis(action);
    const id = extractId(action);
    const cancelId = extractCancelId(action);

    const isScheduleAction =
      typeof timeoutMillis !== 'undefined' && typeof id !== 'undefined';
    const isCancelAction = typeof cancelId !== 'undefined';

    if (isScheduleAction) {
      // if this action is already scheduled, clear the existing one in preparation for rescheduling
      cancelTimer(id);

      // schedule the action.  save the schedule id so it can be cancelled later
      timers[id] = setTimeout(() => {
        delete timers[id];
        next(action);
      }, timeoutMillis);

      return {
        cancel() {
          cancelTimer(id);
        },
      };
    }

    if (isCancelAction) {
      cancelTimer(cancelId);
      return next(action);
    }

    return next(action);
  };
}
