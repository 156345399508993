import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { TEXT_STYLE_MAP } from 'blocks/TextOverlayModal/utils';
import {
  defaultInTransitionSelector,
  defaultOutTransitionSelector,
  textTemplateHideOptionSelector,
  textTemplateOverrideIdsSelector,
} from 'redux/modules/display-pref/selectors';
import { selectors as embedSelectors } from 'redux/modules/embed';
import * as mixpanelActions from 'redux/modules/mixpanel';
import { fontsSelector } from 'redux/selectors';
import { Dispatch, State } from 'redux/types';
import { lastUsedStyle } from 'utils/text-templates';
import { getFilteredTemplates } from 'utils/text-templates/utils';
import { TextOverlayModalContentsProps } from './TextOverlayModalContents';

type StateProps = Pick<
  TextOverlayModalContentsProps,
  | 'defaultInTransitionName'
  | 'defaultOutTransitionName'
  | 'fonts'
  | 'styleTemplates'
>;
type DispatchProps = Pick<
  TextOverlayModalContentsProps,
  'onEmojiSelect' | 'onTemplateSelect'
>;

const lastUsedTemplateSelector = createSelector(
  embedSelectors.lastTextOverlayTemplateSelector,
  lastUsedTemplate =>
    lastUsedTemplate && {
      displayName: lastUsedStyle.ui.displayName,
      id: 'lastUsedStyle',
      imageUrl: lastUsedStyle.ui.imageUrl,
      template: lastUsedTemplate,
      templateId: lastUsedTemplate.templateId,
    },
);

const templatesSelector = createSelector(
  [embedSelectors.textTemplatesSelector, lastUsedTemplateSelector],
  (textTemplates, lastUsedTemplate) => {
    if (!textTemplates) return undefined;

    const formattedTemplates = textTemplates.map(template => {
      const styleTemplate = template.template;
      return {
        ...template,
        template: styleTemplate.withMutations(st => {
          st.set('style', st.get('containerStyle'));
          st.delete('containerStyle');

          TEXT_STYLE_MAP.forEach(([textStyleProp, styleProp]) => {
            const propValue = st.getIn(['textStyle', textStyleProp]);
            if (propValue) {
              st.setIn(['style', styleProp], propValue);
            }
          });
          st.delete('textStyle');

          return st;
        }),
        templateId: template.id,
      };
    });

    if (lastUsedTemplate) {
      lastUsedTemplate.templateId = lastUsedTemplate.template.get('templateId');
    }

    // lastUsedTemplate will be undefined if no overlays added yet, so filter out if necessary
    return [lastUsedTemplate, ...formattedTemplates].filter(Boolean);
  },
);

const filteredTemplatesSelector = createSelector(
  [
    templatesSelector,
    textTemplateOverrideIdsSelector,
    textTemplateHideOptionSelector,
  ],
  (templates, overrideIds, hideOptionIds) =>
    getFilteredTemplates(templates, overrideIds, hideOptionIds),
);

const mapStateToProps = (state: State): StateProps => ({
  defaultInTransitionName: defaultInTransitionSelector(state),
  defaultOutTransitionName: defaultOutTransitionSelector(state),
  fonts: fontsSelector(state),
  styleTemplates: filteredTemplatesSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onEmojiSelect: emoji => dispatch(mixpanelActions.onAddEmoji(emoji)),
  onTemplateSelect: displayName =>
    dispatch(mixpanelActions.onApplyTextTemplate('text', displayName)),
});

export default function(
  component: React.ComponentType<TextOverlayModalContentsProps>,
) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
