import React, { useContext } from 'react';
import { MediaCardContextType } from './types';

interface MediaCardProviderProps extends MediaCardContextType {
  children?: React.ReactNode;
}

const MediaCardContext = React.createContext<MediaCardContextType | undefined>(
  undefined,
);

export const MediaCardProvider: React.FC<MediaCardProviderProps> = ({
  isLocked,
  isDisabled,
  tooltipContent,
  children,
}) => (
  <MediaCardContext.Provider value={{ isLocked, isDisabled, tooltipContent }}>
    {children}
  </MediaCardContext.Provider>
);

export function useMediaCard() {
  const context = useContext(MediaCardContext);

  if (context === undefined) {
    throw new Error('useMediaCard must be used within MediaCardProvider');
  }

  return context;
}
