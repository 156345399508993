import { SubscriptionPeriod } from 'blocks/Pricing/types';
import { PlanName } from 'redux/middleware/api/plan-service';
import bem from 'utils/bem';
import { BillingCyclePlanOptions } from '../BillingCycleModal/BillingCycleOptions';

const PLAN_OPTIONS: BillingCyclePlanOptions[] = [
  { monthly: PlanName.PRO_MONTHLY, yearly: PlanName.PRO_YEARLY },
  {
    monthly: PlanName.PRO_20200922_MONTHLY,
    yearly: PlanName.PRO_20200922_YEARLY,
  },
  {
    monthly: PlanName.PRO_20211112_MONTHLY,
    yearly: PlanName.PRO_20211112_YEARLY,
  },

  {
    monthly: PlanName.BASIC_20200922_MONTHLY,
    yearly: PlanName.BASIC_20200922_YEARLY,
  },
  {
    monthly: PlanName.BASIC_20211112_MONTHLY,
    yearly: PlanName.BASIC_20211112_YEARLY,
  },
  {
    monthly: PlanName.UNLIMITED_20211112_MONTHLY,
    yearly: PlanName.UNLIMITED_20211112_YEARLY,
  },
];

export function getPlanOptionsFromCurrentPlan(
  currentPlan: PlanName,
): BillingCyclePlanOptions {
  return PLAN_OPTIONS.find(opt => Object.values(opt).includes(currentPlan));
}

export const block = bem('update-billing-cycle');

export const isDowngradingCycle = (
  initialCycle: SubscriptionPeriod,
  selectedCycle: SubscriptionPeriod,
): boolean => initialCycle === 'yearly' && selectedCycle === 'monthly';
