import * as React from 'react';
import { pick } from 'underscore';

import TimerPreview from 'components/TimerPreview';
import VideoProgressPreview from 'components/VideoProgressPreview';
import { ProgressType } from 'types';
import { measurementToViewport } from 'utils/placement';
import RndAsset, { RndAssetCallback } from '../assets/RndAsset';
import { useChildViewState, useNavigation } from '../context/NavigationContext';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { canvasBlock as block } from '../utils';
import Layer from './Layer';

const { useCallback } = React;

export interface ProgressLayerProps {}

const ProgressLayer: React.FC<ProgressLayerProps> = () => {
  const [state, send] = useNavigation();
  const { service: childService } = useChildViewState();
  const { canvas, progress, timer } = useTemplateState();
  const dispatch = useTemplateDispatch();

  const handleProgressDimensionsChange: RndAssetCallback = useCallback(
    value => {
      dispatch({
        payload: measurementToViewport(value, canvas),
        type: 'PROGRESS_CHANGE',
      });
    },
    [canvas, dispatch],
  );

  const handleTimerDimensionsChange: RndAssetCallback = useCallback(
    value => {
      const viewportValue = measurementToViewport(value, canvas);
      dispatch({
        payload: {
          position: pick(viewportValue, 'left', 'top'),
          timerSize: pick(viewportValue, 'height', 'width'),
        },
        type: 'TIMER_CHANGE',
      });
    },
    [canvas, dispatch],
  );

  const handleMouseDown = useCallback(() => {
    send({
      type: 'CHILD_VIEW_OPEN',
      payload: 'progress',
      meta: { source: 'preview' },
    });
  }, [send]);

  return (
    <Layer
      active={
        state.matches({ child: 'progress' }) &&
        childService?.state.matches('open')
      }
      className={block('progress-layer')}
    >
      {progress?.enabled && (
        <RndAsset
          assetId="progress"
          bounds="parent"
          lockAspectRatio={false}
          onMouseDown={handleMouseDown}
          onDragStop={handleProgressDimensionsChange}
          onResizeStop={handleProgressDimensionsChange}
          enableResizing={{
            bottom: progress.type !== ProgressType.PLAYER,
            bottomLeft: true,
            bottomRight: true,
            left: progress.type !== ProgressType.PLAYER,
            right: progress.type !== ProgressType.PLAYER,
            top: progress.type !== ProgressType.PLAYER,
            topLeft: true,
            topRight: true,
          }}
          {...progress}
        >
          <VideoProgressPreview
            className={block('progress-preview')}
            color={progress.color}
            fillColor={progress.fillColor}
            type={progress.type}
          />
        </RndAsset>
      )}
      {timer?.enabled && (
        <RndAsset
          assetId="timer"
          bounds="parent"
          enableResizing={{
            bottom: false,
            bottomLeft: false,
            bottomRight: false,
            left: false,
            right: false,
            top: false,
            topLeft: false,
            topRight: false,
          }}
          lockAspectRatio
          onDragStop={handleTimerDimensionsChange}
          onMouseDown={handleMouseDown}
          {...timer.timerSize}
          {...timer.position}
        >
          <TimerPreview
            color={timer.color}
            fontSize={timer.fontSize.toUnit('px', canvas)}
          />
        </RndAsset>
      )}
    </Layer>
  );
};

export default ProgressLayer;
