import Measurement from './Measurement';
import Pixels from './Pixels';
import { MeasurementContext, Unit } from './types';

export default class ViewportWidth extends Measurement<'vw', ViewportWidth> {
  constructor(value: number) {
    super(value, 'vw');
  }

  public toUnit(unit: Unit, context: MeasurementContext): any {
    if (unit === 'vw') {
      return this;
    }

    const contextValue = ViewportWidth.getContextValue(context, 'width');

    if (unit === 'px') {
      return new Pixels((this.value / 100) * contextValue);
    }

    throw new Error(`cannot convert from vw to ${unit}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public update(val: number) {
    return new ViewportWidth(val);
  }
}
