import cn from 'classnames';
import * as React from 'react';
import { ToggleField } from 'components/Form';

import Popper from 'components/Popper/Popper';
import { timerPopperBlock as block } from '../utils';
import VideoTemplateEditorColorPicker from '../VideoTemplateEditorColorPicker';

const { useCallback } = React;

export interface TimerPopperValue {
  enabled: boolean;
  color: string;
}

export interface TimerPopperProps {
  className?: string;
  onChange?: (value: TimerPopperValue) => void;
  value: TimerPopperValue;
}

const TimerPopper: React.FC<TimerPopperProps> = ({
  className,
  onChange,
  value,
}) => {
  const handleToggle = useCallback(
    (checked: boolean) => {
      onChange({
        ...value,
        enabled: checked,
      });
    },
    [onChange, value],
  );

  const handleColorChange = useCallback(
    (color: string) => {
      onChange({
        ...value,
        color,
      });
    },
    [onChange, value],
  );

  return (
    <Popper className={cn(block(), className)} title="timer" role="menu">
      <ToggleField
        className={block('switch')}
        onChange={handleToggle}
        checked={value?.enabled}
      />
      <VideoTemplateEditorColorPicker
        color={value?.color}
        disabled={!value?.enabled}
        onChangeComplete={handleColorChange}
        // TODO SPAR-12842 why is this necessary ?
        popoverStyle={{ top: 150 }}
      />
    </Popper>
  );
};

export default TimerPopper;
