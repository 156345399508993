import * as React from 'react';
import _ from 'underscore';

import DurationSlider from 'components/DurationSlider';
import { FormField } from 'components/Form';
import { AUDIO_FADE_DURATION_MAX_MILLIS } from 'utils/constants';
import { millisToSec } from 'utils/time';

interface IProps {
  audioFadeInDuration?: number;
  audioFadeOutDuration?: number;
  onAudioFadeInChange?: (value: number) => void;
  onAudioFadeOutChange?: (value: number) => void;
}

const MAX_FADE_DURATION_SEC = millisToSec(AUDIO_FADE_DURATION_MAX_MILLIS);

const getMarksForDurationSlider = () => ({
  0: {
    label: '0s',
    style: {
      left: -4,
      marginLeft: 0,
      textAlign: 'left',
      width: 'auto',
    },
  },
  2: {
    label: '2s',
    style: {
      left: 'auto',
      marginLeft: 0,
      right: -4,
      textAlign: 'right',
      width: 'auto',
    },
  },
});

const AudioFadeControls: React.SFC<IProps> = ({
  audioFadeInDuration,
  audioFadeOutDuration,
  onAudioFadeInChange,
  onAudioFadeOutChange,
}) => (
  <div>
    <div style={{ marginBottom: 35 }}>
      <FormField
        label="Audio Fade-in"
        horizontal={{
          control: { xs: 7 },
          label: { xs: 5 },
        }}
      >
        <DurationSlider
          minValue={0}
          maxValue={MAX_FADE_DURATION_SEC}
          marks={getMarksForDurationSlider()}
          onChange={onAudioFadeInChange}
          tooltipId="tooltip-audio-fadein"
          value={audioFadeInDuration}
        />
      </FormField>
    </div>

    <div style={{ marginBottom: 35 }}>
      <FormField
        label="Audio Fade-out"
        horizontal={{
          control: { xs: 7 },
          label: { xs: 5 },
        }}
      >
        <DurationSlider
          minValue={0}
          maxValue={MAX_FADE_DURATION_SEC}
          marks={getMarksForDurationSlider()}
          onChange={onAudioFadeOutChange}
          tooltipId="tooltip-audio-fadein"
          value={audioFadeOutDuration}
        />
      </FormField>
    </div>
  </div>
);

AudioFadeControls.defaultProps = {
  audioFadeInDuration: 0,
  audioFadeOutDuration: 0,
  onAudioFadeInChange: _.noop,
  onAudioFadeOutChange: _.noop,
};

export default AudioFadeControls;
export { IProps as AudioFadeControlsProps };
