import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { debounce } from 'underscore';

import {
  getNextPodcastsPage,
  hasMorePodcastsSelector,
  isLoadingPodcastsSelector,
  podcastListScrollOffsetSelector,
  podcastResultsSelector,
  setSearchScrollOffset,
  setSelectedPodcast,
} from 'redux/modules/podcast-search';
import { Dispatch, State } from 'redux/types';
import { createChainedFunction } from 'utils/functions';
import PodcastList, { PodcastListProps as Props } from './PodcastList';

type StateProps = Pick<
  Props,
  'defaultScrollOffset' | 'hasMore' | 'isLoading' | 'results'
>;
type DispatchProps = Pick<Props, 'onLoadPage' | 'onScroll' | 'onPodcastClick'>;
type OwnProps = Pick<Props, 'onPodcastClick'>;

const resultsSelector = createSelector(podcastResultsSelector, results =>
  results.toArray(),
);

const mapStateToProps = (state: State): StateProps => ({
  defaultScrollOffset: podcastListScrollOffsetSelector(state),
  hasMore: hasMorePodcastsSelector(state),
  isLoading: isLoadingPodcastsSelector(state),
  results: resultsSelector(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onLoadPage: () => dispatch(getNextPodcastsPage()),
  onPodcastClick: createChainedFunction(props.onPodcastClick, id =>
    dispatch(setSelectedPodcast(id)),
  ),
  onScroll: debounce(
    ({ scrollOffset }) =>
      dispatch(setSearchScrollOffset(scrollOffset, 'podcast')),
    150,
  ),
});

const component = connect(mapStateToProps, mapDispatchToProps)(PodcastList);
export type PodcastListProps = React.ComponentPropsWithRef<typeof component>;
export default component;
