import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { unpauseRevokedPermissionsWorkflow } from 'redux/modules/automation-cms';
import { isUpdatingRevokedPermissionsSelector } from 'redux/modules/automation-cms/selectors';
import { showError, showSuccess } from 'redux/modules/notification';
import GoogleAccountMismatchError from 'utils/GoogleAccountMismatchError';

interface UseYoutubePermissionsRestoreConfig {
  onHideModal: () => void;
  reAuthAutoCreateSubId: number;
}

interface UseYoutubePermissionsRestore {
  isLoading: boolean;
  onYoutubeReauthenticationFailure: () => void;
  onYoutubeReauthenticationSuccess: () => Promise<void>;
}

const useYoutubePermissionsRestore = (
  config: UseYoutubePermissionsRestoreConfig,
): UseYoutubePermissionsRestore => {
  const { onHideModal, reAuthAutoCreateSubId } = config;

  const isLoading = useSelector(isUpdatingRevokedPermissionsSelector);

  const dispatch = useDispatch();

  const handleYoutubeReauthenticationFailure = React.useCallback((): void => {
    dispatch(
      showError({
        message: 'An error occured while authenticating your YouTube account.',
        dismissAfterSec: 10,
      }),
    );
  }, [dispatch]);

  const handleYoutubeReauthenticationSuccess = React.useCallback(async (): Promise<
    void
  > => {
    try {
      await dispatch(unpauseRevokedPermissionsWorkflow(reAuthAutoCreateSubId));
      dispatch(
        showSuccess(
          'We’ll continue autoposting your videos',
          10,
          'Your automation is turned off',
        ),
      );
      onHideModal();
    } catch (err) {
      const message =
        err instanceof GoogleAccountMismatchError
          ? 'The YouTube account selected does not match the one selected for this automation project'
          : 'There was an error updating your automation';
      dispatch(
        showError({
          message,
          dismissAfterSec: 10,
        }),
      );
    }
  }, [dispatch, onHideModal, reAuthAutoCreateSubId]);

  return {
    isLoading,
    onYoutubeReauthenticationFailure: handleYoutubeReauthenticationFailure,
    onYoutubeReauthenticationSuccess: handleYoutubeReauthenticationSuccess,
  };
};

export default useYoutubePermissionsRestore;
