import { LoginCard as BaseLoginCard } from '@sparemin/auth';
import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import BrowserCheck from 'components/BrowserCheck';
import { pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import useAuthenticationSuccessHandler from '../hooks/useAuthenticationSuccessHandler';

const LoginCard: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const { onAuthenticationSuccess } = useAuthenticationSuccessHandler();

  const handleForgotPasswordPress = useCallback(() => {
    dispatch(
      pushModal({
        name: 'Authentication',
        params: {
          initialStep: 'forgot-password',
        },
      }),
    );
  }, [dispatch]);

  return (
    <>
      <BrowserCheck dismissible={false} />
      <BaseLoginCard
        onAuthenticateSuccess={onAuthenticationSuccess}
        onForgotPasswordPress={handleForgotPasswordPress}
      />
    </>
  );
};

export default LoginCard;
