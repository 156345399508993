import * as React from 'react';
import { useDispatch } from 'react-redux';
import { SwitchTransition } from 'react-transition-group';
import _ from 'underscore';
import BemCSSTransition from 'components/BemCssTransition';
import Button from 'components/Button';
import FileUploader from 'components/FileUploader';
import FontAwesome from 'components/FontAwesome';
import { FontFile } from 'components/icons';
import Modal from 'components/Modal';
import { GoogleFont } from 'redux/middleware/api/headliner-user-service';
import { uploadUserPersonalFonts } from 'redux/modules/fonts/actions';
import { Dispatch } from 'redux/types';
import bem from 'utils/bem';
import { SUPPORTED_FONT_TYPES } from 'utils/formats';
import { useFontSettings } from '../useFontSettings';
import DisplayGoogleFonts from './DisplayGoogleFonts';

export interface FontImportModalProps {
  show: boolean;
  title: string;
  onHide: () => void;
}

const block = bem('font-import-modal');

function FontImportModal({ show, title, onHide }: FontImportModalProps) {
  const { googleFonts } = useFontSettings();
  const [googleSearchTerm, setGoogleSearchTerm] = React.useState('');
  const dispatch = useDispatch<Dispatch>();

  const filteredGoogleFonts = React.useMemo(() => {
    const searchTerm = googleSearchTerm.toLowerCase();
    const results = _.filter(googleFonts, (googleFont: GoogleFont) => {
      return googleFont.family.toLowerCase().includes(searchTerm);
    });
    return results.slice(0, 5);
  }, [googleFonts, googleSearchTerm]);

  const addGoogleFont = React.useCallback(
    (googleFamily: string) => {
      dispatch(
        uploadUserPersonalFonts({
          googleFamily,
        }),
      );
    },
    [dispatch],
  );

  const clearGoogleSearchTerm = React.useCallback(() => {
    setGoogleSearchTerm('');
  }, []);

  function handleFontFilesUpload(fontFiles: File[]) {
    dispatch(
      uploadUserPersonalFonts({
        fontFiles,
      }),
    );
  }

  function handleGoogleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setGoogleSearchTerm(e.target.value);
  }

  return (
    <Modal
      className={block()}
      show={show}
      title={title}
      backdrop="static"
      onHide={onHide}
    >
      <div className={block('font-display')}>
        <div className={block('font-input-container')}>
          <FontAwesome className={block('font-svg')} icon="search" />
          <input
            className={block('font-input', {
              'show-cancel': !!googleSearchTerm,
            })}
            value={googleSearchTerm}
            onChange={handleGoogleInputChange}
            placeholder="Search for a font"
          />
          <BemCSSTransition
            in={!!googleSearchTerm}
            timeout={100}
            delay={{ enter: 300 }}
            className={block('font-cancel')}
            unmountOnExit
          >
            <Button
              className={block('font-input-button')}
              theme="ghost"
              onClick={clearGoogleSearchTerm}
            >
              CANCEL
            </Button>
          </BemCSSTransition>
        </div>
        <>
          <SwitchTransition>
            <BemCSSTransition
              key={googleSearchTerm ? 'show-suggestions' : 'show-uploader'}
              timeout={300}
              className={block('font-transitions')}
            >
              {googleSearchTerm ? (
                <DisplayGoogleFonts
                  googleFonts={filteredGoogleFonts}
                  addGoogleFont={addGoogleFont}
                  clearGoogleSearchTerm={clearGoogleSearchTerm}
                />
              ) : (
                <FileUploader
                  image={<FontFile />}
                  supportedFileTypes={SUPPORTED_FONT_TYPES}
                  accept={SUPPORTED_FONT_TYPES.join(',')}
                  onFileAccepted={handleFontFilesUpload}
                  className={block('dropzone')}
                  multiple
                />
              )}
            </BemCSSTransition>
          </SwitchTransition>
        </>
      </div>
    </Modal>
  );
}

export default FontImportModal;
