import cn from 'classnames';
import * as React from 'react';
// TODO update to use Headliner's Tooltip component
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Button from 'components/Button';
import { isOverflowing } from 'utils/dom';
import { block } from './utils';

const { useCallback, useRef, useState } = React;

export interface SelectedFileProps {
  className?: string;
  fileName: string;
  onCancelClick?: () => void;
  tooltipId: string;
}

const SelectedFile = React.forwardRef<HTMLDivElement, SelectedFileProps>(
  ({ className, fileName, onCancelClick, tooltipId }, ref) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const element = useRef(null);

    const handleOverlayEnter = useCallback(() => {
      if (isOverflowing(element.current)) {
        setShowTooltip(true);
      }
    }, []);

    const handleOverlayExited = useCallback(() => {
      setShowTooltip(false);
    }, []);

    const handleClickCancel = useCallback(() => {
      onCancelClick();
    }, [onCancelClick]);

    return (
      <div className={cn(block('selected-file'), className)} ref={ref}>
        <OverlayTrigger
          delayShow={1000}
          onEnter={handleOverlayEnter}
          onExited={handleOverlayExited}
          overlay={
            <Tooltip
              id={tooltipId}
              className={block('tooltip')}
              style={{ visibility: showTooltip ? 'visible' : 'hidden' }}
            >
              {fileName}
            </Tooltip>
          }
          placement="top"
        >
          <span className={block('text')} ref={element}>
            {fileName}
          </span>
        </OverlayTrigger>
        <Button
          className={block('cancel')}
          onClick={handleClickCancel}
          size="small"
        >
          <span
            className={cn(
              'glyphicon',
              'glyphicon-remove',
              block('cancel-icon'),
            )}
          />
        </Button>
      </div>
    );
  },
);

export default SelectedFile;
