import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import { episodeTranscriptionModalBlock as block } from '../utils';
import EpisodeExceedsLimitsModalContents from './EpisodeExceedsLimitsModalContents';

const EpisodeExceedsLimitsModal = () => (
  <ConnectedModal
    name="EpisodeExceedsLimits"
    title="transcript"
    className={block()}
  >
    <EpisodeExceedsLimitsModalContents />
  </ConnectedModal>
);

export default EpisodeExceedsLimitsModal;
