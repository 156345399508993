import * as React from 'react';

import { useOnboardingWizard } from 'blocks/OnboardingWizard/OnboardingWizardContext';
import DefaultWelcomeStep from './DefaultWelcomeStep';
import PaidWelcomeStep from './PaidWelcomeStep';
import TrialWelcomeStep from './TrialWelcomeStep';
import { WelcomeType } from './types';
import useWelcomeStep from './useWelcomeStep';

export interface WelcomeStepProps {
  onSubmit?: () => void;
}

const WelcomeStep: React.FC<WelcomeStepProps> = props => {
  const { onSubmit } = props;
  const { title } = useWelcomeStep();
  const { welcomeType } = useOnboardingWizard();

  if (welcomeType === WelcomeType.TRIAL) {
    return <TrialWelcomeStep {...{ title }} onNextClick={onSubmit} />;
  }

  if (
    welcomeType === WelcomeType.PRO ||
    welcomeType === WelcomeType.BASIC ||
    welcomeType === WelcomeType.UNLIMITED
  ) {
    return <PaidWelcomeStep {...{ title }} onNextClick={onSubmit} />;
  }

  return <DefaultWelcomeStep {...{ title }} onNextClick={onSubmit} />;
};

export default WelcomeStep;
