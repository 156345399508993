import { createSelector } from 'reselect';
import {
  AssetStatuses,
  CreationStatus,
} from 'redux/middleware/api/creation-service/types';
import { creationsSelector } from '../entities/selectors';
import { WizardExportState } from './types';

export const creationStatusExtractor = (creation: any) =>
  creation?.get('status');
export const creationConfigIdExtractor = (creation: any) =>
  creation?.getIn(['requestObj', 'configId']);

const stateSelector = (state): WizardExportState => state.get('wizardExport');

export const creationIdSelector = createSelector(
  stateSelector,
  state => state.creationId,
);

export const creationSelector = createSelector(
  creationIdSelector,
  creationsSelector,
  (creationId, creations) => creations?.get(creationId?.toString()),
);

export const creationStatusSelector = createSelector(
  creationSelector,
  (creation): CreationStatus => creationStatusExtractor(creation),
);

export const assetCreationStatusSelector = createSelector(
  creationSelector,
  (creation): AssetStatuses => creation?.get('assetStatuses')?.toJS(),
);

export const creationConfigurationIdSelector = createSelector(
  creationSelector,
  creation => creationConfigIdExtractor(creation),
);

export const statusSelector = createSelector(stateSelector, state =>
  state.get('status'),
);

export const pollStatusSelector = createSelector(stateSelector, state =>
  state.get('pollStatus'),
);

export const projectIdSelector = createSelector(creationSelector, creation =>
  creation?.getIn(['requestObj', 'projectUuid']),
);
