import * as React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

const WaveIcon: React.SFC<IProps> = ({ color, ...svgProps }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 34" {...svgProps}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M24 0c.631 0 1.143.512 1.143 1.143v31.714a1.143 1.143 0 1 1-2.286 0V1.143C22.857.512 23.37 0 24 0zm-4.571 5.681c.63 0 1.142.512 1.142 1.143v20.381a1.143 1.143 0 0 1-2.285 0V6.825c0-.632.511-1.144 1.143-1.144zm9.142-.014c.632 0 1.143.511 1.143 1.143v20.38a1.143 1.143 0 1 1-2.285 0V6.81c0-.632.511-1.143 1.142-1.143zm-13.714 4.56c.631 0 1.143.51 1.143 1.142v11.314a1.143 1.143 0 1 1-2.286 0V11.37c0-.631.512-1.143 1.143-1.143zm18.286-.027c.631 0 1.143.512 1.143 1.143v11.314a1.143 1.143 0 0 1-2.286 0V11.343c0-.631.512-1.143 1.143-1.143zm-22.857 2.299c.63 0 1.143.511 1.143 1.142v6.781a1.143 1.143 0 1 1-2.286 0v-6.78c0-.632.512-1.143 1.143-1.143zm27.428-.032c.631 0 1.143.511 1.143 1.143v6.78a1.143 1.143 0 0 1-2.286 0v-6.78c0-.632.512-1.143 1.143-1.143zm-32 2.304c.631 0 1.143.512 1.143 1.143v2.248a1.143 1.143 0 0 1-2.286 0v-2.248c0-.631.512-1.143 1.143-1.143zm36.572-.038c.63 0 1.143.512 1.143 1.143v2.248a1.143 1.143 0 1 1-2.286 0v-2.248c0-.631.512-1.143 1.143-1.143zM1.152 15.907a1.133 1.133 0 0 1 0 2.267h-.019a1.133 1.133 0 1 1 0-2.267h.02zm45.715-.04a1.133 1.133 0 1 1 0 2.266h-.02a1.133 1.133 0 1 1 0-2.266h.02z"
    />
  </svg>
);

WaveIcon.defaultProps = {
  color: 'currentColor',
};

export default WaveIcon;
export { IProps as WaveIconProps };
