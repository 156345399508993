import { ButtonBase } from '@sparemin/blockhead';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  ImmutableTextPreset,
  makePresetSelector,
} from 'redux/modules/text-presets';

import { block } from './utils';

interface TextPresetButtonProps {
  isSelected: boolean;
  mode: 'horizontal' | 'vertical';
  onClick: (presetStyles: ImmutableTextPreset) => void;
  presetId: string;
}

const PRESET_SAMPLE_TEXT = 'Aa';

const TextPresetButton: React.FunctionComponent<TextPresetButtonProps> = props => {
  const { isSelected, mode, onClick, presetId } = props;

  const preset = useSelector(makePresetSelector(presetId));

  const handleClickPreset = React.useCallback(() => {
    onClick(preset);
  }, [onClick, preset]);

  if (!preset || !preset.get('cssStyles')) {
    return null;
  }

  const presetStyle = preset.get('cssStyles').toJS();

  return (
    <ButtonBase
      className={block('preset', { [mode]: true, selected: isSelected })}
      onPress={handleClickPreset}
    >
      <span
        className={block('sample-text', { [mode]: true, selected: isSelected })}
        style={presetStyle}
      >
        {PRESET_SAMPLE_TEXT}
      </span>
    </ButtonBase>
  );
};

export default TextPresetButton;
