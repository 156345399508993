import cn from 'classnames';
import * as React from 'react';
import PlanAction from './PlanAction';
import PlanDescription from './PlanDescription';
import PlanFeature from './PlanFeature';
import PlanHeader from './PlanHeader';
import SubPeriodPlanHeader from './SubPeriodPlanHeader';

import { block } from './utils';

export interface PlanProps {
  children?: React.ReactNode;
  className?: string;
}

interface Plan extends React.FC<PlanProps> {
  Action: typeof PlanAction;
  Description: typeof PlanDescription;
  Feature: typeof PlanFeature;
  Header: typeof PlanHeader;
  SubPeriodHeader: typeof SubPeriodPlanHeader;
}

const Plan: Plan = ({ children, className }) => (
  <div className={cn(block(), className)}>{children}</div>
);

Plan.Action = PlanAction;
Plan.Description = PlanDescription;
Plan.Feature = PlanFeature;
Plan.Header = PlanHeader;
Plan.SubPeriodHeader = SubPeriodPlanHeader;

export default Plan;
