import { useState } from 'react';

export interface UseOnChangeOptions<T> {
  isEqual?: (newValue: T, oldValue: T) => boolean;
  onChange?: (newValue: T, oldValue: T) => void;
}

const defaultEqualityCheck = <T>(newValue: T, oldValue: T) =>
  newValue === oldValue;

/**
 * A hook to support a new react pattern found here https://beta.reactjs.org/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
 */
export default function useOnChange<T>(value: T, opts?: UseOnChangeOptions<T>) {
  const { isEqual = defaultEqualityCheck, onChange } = opts ?? {};

  const [prevValue, setPrevValue] = useState(value);

  if (!isEqual(value, prevValue)) {
    setPrevValue(value);
    onChange?.(value, prevValue);
  }
}
