import * as React from 'react';
import { useSelector } from 'react-redux';
import { finalAudioDurationMillisSelector } from 'redux/modules/audio-wizard/selectors';
import { creationConfigurationIdSelector } from 'redux/modules/wizard-export/selectors';
import { WizardExportVideoStep } from '../ExportVideoStep';

interface Props {
  onError: (error: Error) => void;
}

function AudioWizardExportVideoStep(props: Props) {
  const { onError } = props;
  const widgetId = useSelector(creationConfigurationIdSelector);
  const durationMillis = useSelector(finalAudioDurationMillisSelector);
  const durationSec = Math.floor(durationMillis / 1000);
  return (
    <WizardExportVideoStep
      widgetId={widgetId}
      estimatedDurationSec={Math.max(durationSec * 2, 60)}
      onError={onError}
    />
  );
}

export default AudioWizardExportVideoStep;
