import _ from 'underscore';

import { actions as searchActions } from 'redux/middleware/api/image-search-service';
import { gifSearchSelector } from 'redux/modules/gif-search/selectors';
import { onGifSearch } from 'redux/modules/mixpanel';
import { ThunkAction } from 'redux/types';
import { Type } from './action-types';
import { GifSearchFailureAction, GifSearchSuccessAction } from './types';

export const searchForGifs = (
  engine: string,
  query: string,
  page?: number,
): ThunkAction<GifSearchFailureAction | Promise<GifSearchSuccessAction>> => (
  dispatch,
  getState,
) => {
  dispatch({
    payload: { engine },
    type: Type.GIFS_GET_REQUEST,
  });

  const engineState = gifSearchSelector(getState()).get(engine);

  const pageNumber = !_.isUndefined(page) ? page : engineState.page;
  const pageSize = !_.isUndefined(engineState.pageSize)
    ? engineState.pageSize
    : 10;
  const q = query || engineState.q;

  if (!q) {
    return dispatch({
      error: 'no query string',
      payload: { engine },
      type: Type.GIFS_GET_FAILURE,
    } as GifSearchFailureAction);
  }

  if (!pageNumber) {
    dispatch(onGifSearch(engine, q));
  }

  return dispatch(searchActions.searchForGifs(q, engine, pageNumber, pageSize))
    .then(
      ({ response }) =>
        dispatch({
          payload: response,
          type: Type.GIFS_GET_SUCCESS,
        }) as GifSearchSuccessAction,
    )
    .catch(err => {
      dispatch({
        error: err.message,
        payload: { engine },
        type: Type.GIFS_GET_FAILURE,
      });
      throw err;
    });
};

export const clearSearchResults = (engine: string) => ({
  payload: { engine },
  type: Type.GIF_SEARCH_RESULTS_CLEAR,
});

export const clearAllSearchResults = () => ({
  type: Type.GIF_SEARCH_RESULTS_CLEAR_ALL,
});
