import * as React from 'react';
import { isFinite } from 'underscore';

import Modal from 'components/Modal';
import { block } from '../../utils';

export interface VideoExportModalContentsFooterProps {
  onSubmit?: () => void;
  onUpgrade?: (event: React.MouseEvent) => void;
  videoBalance?: number;
}

const VideoExportModalContentsFooter: React.SFC<VideoExportModalContentsFooterProps> = ({
  onSubmit,
  onUpgrade,
  videoBalance,
}) => {
  const isOutOfVideos = isFinite(videoBalance) && videoBalance <= 0;

  return (
    <Modal.Footer>
      <Modal.FooterButtons className={block('footer-buttons', { form: true })}>
        {isOutOfVideos && (
          <Modal.FooterButton
            className={block('footer-upgrade-button')}
            onClick={onUpgrade}
            theme="rainbow"
          >
            remove watermark
          </Modal.FooterButton>
        )}
        <Modal.FooterButton onClick={onSubmit} theme="submit">
          {!isOutOfVideos ? 'export my video' : 'export with watermark'}
        </Modal.FooterButton>
      </Modal.FooterButtons>
    </Modal.Footer>
  );
};

export default VideoExportModalContentsFooter;
