import React from 'react';
import { Metadata } from 'components/Metadata';
import ShareFormTextarea, {
  ShareFormTextareaProps,
} from 'components/ShareForm/ShareFormTextarea';
import {
  MAX_CHARS_TITLE,
  MAX_CHARS_TITLE_EXTRA,
  videoTitleMetadata,
} from 'containers/YoutubePostModal/utils';

export interface YoutubeVideoTitleInputProps
  extends Pick<ShareFormTextareaProps, 'onChange' | 'readonly' | 'value'> {
  isMetadataEnabled?: boolean;
  showLabel?: boolean;
  hideProgress?: boolean;
  placeholder?: string;
}

const YoutubeVideoTitleInput: React.FC<YoutubeVideoTitleInputProps> = ({
  isMetadataEnabled,
  showLabel = true,
  hideProgress,
  placeholder,
  onChange,
  readonly,
  value,
}) => (
  <>
    <ShareFormTextarea
      hideProgress={isMetadataEnabled || readonly || hideProgress}
      label={showLabel ? 'Video title' : null}
      labelHint={isMetadataEnabled && '(up to 100 characters)'}
      maxChars={isMetadataEnabled ? MAX_CHARS_TITLE_EXTRA : MAX_CHARS_TITLE}
      type="input"
      {...{ onChange, readonly, value, placeholder }}
    />
    {isMetadataEnabled && (
      <Metadata
        fields={videoTitleMetadata}
        label="Add metadata (shows nothing if not found in feed):"
        tooltip="Add content from podcast feed into video title"
        onClick={metaValue => onChange(`${value} ${metaValue}`)}
      />
    )}
  </>
);

export default YoutubeVideoTitleInput;
