const VIEWPORTS = {
  landscape: { width: 768, height: 432 },
  portrait: { width: 242, height: 430.22 },
  square: { width: 380, height: 380 },
};

// these templates are hidden from normal users,
const HIDDEN_TEMPLATE_IDS: Readonly<string[]> = [
  // these get added to Prisa users from overrideOptions
  'gradientSubtitlePrisa',
  // these get added to Entercom users from overrideOptions
  'sportsCast',
  'sportsQuoteLeft',
  'sportsQuoteRight',
  // these get added to DW users from overrideOptions
  'copyright1',
  'copyrightTextAmharic',
  'copyrightTextRtl',
  'gradient1',
  'gradientSubtitleAmharic',
  'gradientSubtitleRtl',
  'leftInsert1',
  'leftInsertAmharic',
  'leftInsertRtl',
  'rightInsert1',
  'rightInsertAmharic',
  'rightInsertRtl',
  // SR.SE
  'srseName',
  'srseOccupation',
  'srseCaptions',
  'srseP4',
  'srseRubrik',
  // Bengali
  'copyrightTextBengali',
  'gradientSubtitleBengali',
  'leftInsertBengali',
  'rightInsertBengali',
];

function createConverter(width: number, height: number) {
  return {
    pctOfHeight(pct: number) {
      return (pct / 100) * height;
    },
    pctOfWidth(pct: number) {
      return (pct / 100) * width;
    },
  };
}

export const pixelConverter = Object.keys(VIEWPORTS).reduce(
  (acc, ratioName) => {
    const { width, height } = VIEWPORTS[ratioName];
    acc[ratioName] = createConverter(width, height);
    return acc;
  },
  {},
);

export const getFilteredTemplates = (
  defaultTemplates,
  textTemplateOverrideIds,
  textTemplateHideOptionIds,
) => {
  if (!defaultTemplates) return [];

  if (textTemplateOverrideIds) {
    const lastUsedTemplate = defaultTemplates.find(
      t => t.id === 'lastUsedStyle',
    );
    const filteredTemplates = textTemplateOverrideIds
      .map(id => defaultTemplates.find(template => template.id === id))
      .filter(Boolean);

    if (lastUsedTemplate) {
      filteredTemplates.unshift(lastUsedTemplate);
    }

    return filteredTemplates;
  }

  if (textTemplateHideOptionIds) {
    return defaultTemplates.filter(
      t => !textTemplateHideOptionIds.includes(t.id),
    );
  }

  return defaultTemplates.filter(t => !HIDDEN_TEMPLATE_IDS.includes(t.id));
};
