import React from 'react';
import { IconProps } from './types';

const HeadphonesHeady: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106 100" {...props}>
    <defs>
      <linearGradient
        id="73zdq5x33a"
        x1="1.096%"
        x2="98.904%"
        y1="0%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#5DBEFC" />
        <stop offset="100%" stopColor="#DD92FE" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#8CA0C3"
              d="M53.19 0c13.84 0 27.08 5.216 38.478 14.854 1.175.994 1.52 2.609.96 3.96 8.128 8.644 13.14 19.97 13.364 32.405l.008.829V63.14c0 .817-.66 1.478-1.472 1.478-.284 0-.548-.08-.773-.22v2.31c0 7.62-5.849 13.875-13.302 14.517 2.739-5.63 4.276-11.962 4.276-18.656 0-8.207-2.31-15.87-6.312-22.361h.767c6.44 0 11.904 4.178 13.83 9.971-.475-11.255-5.141-21.501-12.572-29.386-1.072.323-2.28.113-3.2-.665C77.04 11.504 65.348 6.897 53.19 6.897c-11.907 0-23.368 4.417-33.425 12.708-1.028.848-2.4.996-3.544.5-7.453 7.601-12.283 17.518-13.14 28.47 2.332-4.944 7.36-8.365 13.189-8.365h.922c-4.001 6.49-6.312 14.153-6.312 22.36 0 6.7 1.54 13.036 4.282 18.669-7.53-.566-13.463-6.854-13.463-14.53v-2.107c-.074.011-.15.017-.227.017-.765 0-1.394-.586-1.465-1.335L0 63.14V51.998c.025-13.2 5.443-25.193 14.278-34.125-.358-1.277.04-2.702 1.127-3.598C26.651 5.005 39.635 0 53.19 0z"
              transform="translate(-947.000000, -751.000000) translate(760.000000, 349.000000) translate(187.000000, 402.000000)"
            />
            <path
              fill="url(#73zdq5x33a)"
              fillRule="nonzero"
              d="M89.822 62.57c0 20.671-16.573 37.43-37.019 37.43-20.443-.002-37.016-16.759-37.016-37.43 0-20.67 16.573-37.43 37.019-37.43 20.443 0 37.016 16.757 37.016 37.43zM56.35 66.453v4.928c0 3.38 2.708 6.117 6.05 6.117 3.342 0 6.05-2.738 6.05-6.117V49.279c0-3.38-2.708-6.118-6.05-6.118-3.342 0-6.05 2.738-6.05 6.118v12.986h-7.095V49.28c0-3.38-2.708-6.118-6.05-6.118-3.343 0-6.05 2.738-6.05 6.118V71.38c0 3.38 2.707 6.117 6.05 6.117 3.342 0 6.05-2.738 6.05-6.117v-4.928h7.095zM68.449 81.73c-.43 0-.87.14-1.243.42-4.189 3.138-9.13 4.792-14.057 4.792-2.607 0-5.21-.386-7.702-1.176-2.492-.792-4.871-1.989-7.044-3.614-.37-.28-.81-.415-1.241-.415h-.005c-.636 0-1.27.293-1.678.853-.275.375-.411.817-.411 1.257 0 .646.294 1.285.846 1.7 2.558 1.919 5.365 3.325 8.28 4.25 2.916.926 5.941 1.371 8.955 1.371 5.854 0 11.661-1.96 16.548-5.623.552-.413.846-1.054.846-1.7 0-.438-.133-.88-.41-1.257-.409-.558-1.04-.856-1.68-.856h-.013"
              transform="translate(-947.000000, -751.000000) translate(760.000000, 349.000000) translate(187.000000, 402.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default HeadphonesHeady;
