import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import { block } from './utils';

interface IProps {
  children?: React.ReactNode;
}

const FormFieldWarning: React.SFC<IProps> = ({ children }) => (
  <div className={block('field')}>
    <div className={block('field-warning')}>
      <FontAwesome
        icon="exclamation-triangle"
        className={block('field-warning-icon')}
      />{' '}
      {children}
    </div>
  </div>
);

export default FormFieldWarning;
export { IProps as FormFieldWarningProps };
