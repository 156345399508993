import { connect } from 'react-redux';

import { onClickKnowledgeBaseUrl } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import { PropsOf } from 'types';
import ShareKbLink, { ShareKbLinkProps as Props } from './ShareKbLink';

type DispatchProps = Pick<Props, 'onClick'>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onClick: () => dispatch(onClickKnowledgeBaseUrl()),
});

const component = connect(null, mapDispatchToProps)(ShareKbLink);
export type ShareKbLinkProps = PropsOf<typeof component>;
export default component;
