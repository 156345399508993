import * as React from 'react';
import { noop } from 'underscore';

import FontAwesome from 'components/FontAwesome';
import FormField, { FormFieldProps } from './FormField';
import InputAdornment from './InputAdornment';

type InputProps = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  'value' | 'placeholder' | 'autoComplete'
>;
type Props = FormFieldProps<InputProps> & {
  onChange?: (val: string, e: React.ChangeEvent<HTMLInputElement>) => void;
};

const { useState } = React;

const PasswordField: React.FC<Props> = props => {
  const { onChange = noop, autoComplete, ...rest } = props;
  const [secure, setSecure] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value, e);

  return (
    <FormField<InputProps>
      {...rest}
      endAdornment={
        <InputAdornment position="end" onClick={() => setSecure(!secure)}>
          <FontAwesome icon={secure ? 'eye-slash' : 'eye'} />
        </InputAdornment>
      }
    >
      <input
        type={secure ? 'password' : 'text'}
        onChange={handleChange}
        {...{ autoComplete }}
      />
    </FormField>
  );
};

export default PasswordField;
export { Props as PasswordFieldProps };
