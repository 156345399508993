import * as React from 'react';

import { useDispatch } from 'react-redux';
import useDefaultIntegration from 'blocks/PodcastSearch/useDefaultIntegration';
import useFavoriteIntegration from 'blocks/PodcastSearch/useFavoriteIntegration';
import useRssIntegration from 'blocks/PodcastSearch/useRssIntegration';
import { Step } from 'components/Wizard';
import { uploadEntireAudio } from 'redux/modules/template-wizard/actions';
import { Dispatch } from 'redux/types';
import { AudioSourceType, PodcastIdentifier } from 'types/common';
import { pick } from 'utils/collections';
import { AddAudioStepProps } from '../AddAudioStep';
import AddAudioStep from '../AddAudioStep/AddAudioStep';
import { useAudioUpload } from '../UseAudioUpload';
import useSelectAudioTab from '../useSelectAudioTab';
import { TemplateWizardStep } from './types';

const { useState } = React;

type PickedAudioStepProps = Pick<AddAudioStepProps, 'onAudioAdded' | 'onError'>;

export type UseAddAudioStepConfig = PickedAudioStepProps;

interface UseAudioStepResult
  extends Pick<
    ReturnType<typeof useAudioUpload>,
    'afterUploadTransfer' | 'audioRegion' | 'source'
  > {
  audioSourceType: AudioSourceType;
  podcastIdentifier: PodcastIdentifier;
  resetAudioState: ReturnType<typeof useAudioUpload>['reset'];
  step: Step<TemplateWizardStep.ADD_AUDIO>;
}

export default function useAddAudioStep({
  onAudioAdded: onAudioAddedProp,
  onError,
}): UseAudioStepResult {
  const dispatch = useDispatch<Dispatch>();
  const [activeTab, setActiveTab] = useSelectAudioTab();
  const [audioSourceType, setAudioSourceType] = useState<AudioSourceType>(
    undefined,
  );
  const [podcastIdentifier, setPodcastIdentifier] = useState<PodcastIdentifier>(
    undefined,
  );

  const {
    afterUploadTransfer,
    audioRegion,
    onAudioAdded,
    reset,
    source,
  } = useAudioUpload({
    onAudioAdded: async (src, meta) => {
      onAudioAddedProp(src, meta);
      if (meta?.podcastId || meta?.episodeId) {
        setPodcastIdentifier(pick(meta, 'podcastId', 'episodeId'));
      }

      if (src) {
        return dispatch(uploadEntireAudio(src, meta));
      }

      return undefined;
    },
    onAudioUploadError: onError,
    onChange: ({ audioSourceType: type }) => setAudioSourceType(type),
  });

  return {
    afterUploadTransfer,
    audioRegion,
    audioSourceType,
    podcastIdentifier,
    source,
    resetAudioState: reset,
    step: {
      completedName: 'Audio Selected',
      component: (
        <AddAudioStep
          {...{ activeTab, onAudioAdded, onError }}
          isFileSelected={source !== undefined}
          maxFileSizeMb={500}
          onTabSelect={setActiveTab}
          podcastSearchIntegrations={[
            useDefaultIntegration({
              onAudioAdded,
              priority: 3,
            }),
            useRssIntegration({
              onAudioAdded,
              episodeExplorerEnabled: true,
              priority: 2,
            }),
            useFavoriteIntegration({
              onAudioAdded,
              priority: 3,
            }),
          ]}
          showTranscriptionForm={false}
          wizardType="templateWizard"
        />
      ),
      description: 'Add audio to create a shareable video.',
      name: 'Select Audio',
      renderCancelButton: () => null,
      renderNextButton: () => null,
      stepId: TemplateWizardStep.ADD_AUDIO,
      title: 'Add audio',
    },
  };
}
