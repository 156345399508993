import React from 'react';

import DialogContent from 'components/DialogContent';
import { H2 } from 'components/Heading';
import { Youtube } from 'components/icons';
import { Step } from 'components/SteppedModal';
import useYouTubeAuthenticator, {
  UseYouTubeAuthenticatorConfig,
} from 'hooks/useYouTubeAuthenticator';
import GoogleButton from '../GoogleButton/GoogleButton';
import { block } from '../utils'; // TODO move

interface UseAuthorizationStepConfig
  extends Pick<UseYouTubeAuthenticatorConfig, 'onAuthSuccess' | 'onError'> {}

export default function useAuthorizationStep({
  onAuthSuccess,
  onError,
}: UseAuthorizationStepConfig): Step {
  const { authenticate, authenticating } = useYouTubeAuthenticator({
    onAuthSuccess,
    onError,
    force: true,
  });

  return {
    component: (
      <DialogContent
        details={
          <H2>
            We'll quickly send you to Google so you can give us permission to
            add videos to your YouTube account.
          </H2>
        }
        icon={
          <div className={block('authorization-icon-wrapper')}>
            <Youtube height={100} width={100} />
          </div>
        }
        onClose={() => null}
        title="Let's add your account"
      />
    ),
    fluidFooterButtons: true,
    id: 'authorization',
    renderFooterButtons: ({ submit }) => [
      <GoogleButton
        {...submit}
        disabled={submit.disabled || authenticating}
        theme="submit"
        onClick={() => authenticate()}
      >
        Sign in with Google
      </GoogleButton>,
    ],
    submitButtonLabel: 'connect youtube account',
  };
}
