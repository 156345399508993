import * as React from 'react';

import { IconProps } from './types';

const IntroOutro: React.SFC<IconProps> = ({
  fill = 'currentColor',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="11"
    viewBox="0 0 14 11"
    {...props}
  >
    <path
      fill={fill}
      fillRule="nonzero"
      d="M7 .75a.5.5 0 0 1 .5.5v9a.5.5 0 1 1-1 0v-9a.5.5 0 0 1 .5-.5zM0 2.839c0-.524.62-.786.982-.416l2.85 2.914c.224.227.224.599 0 .829L.981 9.077C.62 9.447 0 9.185 0 8.661V2.84zm14 0V8.66c0 .524-.62.786-.982.416l-2.85-2.911a.594.594 0 0 1 0-.83l2.85-2.913c.362-.37.982-.108.982.416z"
    />
  </svg>
);

export default IntroOutro;
