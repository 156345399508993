import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { TemplateItem } from 'blocks/ProjectTemplates/types';
import ProgressOverlay from 'components/ProgressOverlay';
import { PodcastWorkflowTemplate } from 'redux/middleware/api/podcast-service';
import { podcastSubscriptionsSelector } from 'redux/modules/entities';
import { TemplateThumbnail, TemplateThumbnailStatus } from 'types';

type ProjectTemplate = Pick<
  TemplateItem,
  'templateThumbnail' | 'previewVideos'
>;
type PodcastTemplate = Pick<
  PodcastWorkflowTemplate,
  'subscriptionTemplateThumbnail' | 'previewVideos'
>;

type Template = ProjectTemplate | PodcastTemplate;

interface UseTemplateThumbnailConfig {
  podcastSubscriptionId?: number;
  template?: Template;
}

interface UseTemplateThumbnailResult {
  imageUrl: string | undefined;
  isGenerating: boolean;
  progressOverlay: React.ReactElement;
}

const TEMPLATE_BUSY_STATUS: TemplateThumbnailStatus[] = [
  'queued',
  'processing',
  'error',
];

function getThumbnail(template: any): TemplateThumbnail {
  return (
    template?.templateThumbnail || template?.subscriptionTemplateThumbnail || {}
  );
}

const createTemplateSelector = (id?: number) =>
  createSelector(podcastSubscriptionsSelector, subs => {
    return subs?.get(String(id))?.toJS();
  });

export default function useTemplateThumbnail({
  podcastSubscriptionId,
  template: templateProp,
}: UseTemplateThumbnailConfig): UseTemplateThumbnailResult {
  const template =
    useSelector(
      useMemo(() => createTemplateSelector(podcastSubscriptionId), [
        podcastSubscriptionId,
      ]),
    ) || templateProp;
  const { status, url } = getThumbnail(template);
  const inProgress = TEMPLATE_BUSY_STATUS.includes(status);

  return {
    imageUrl: inProgress ? undefined : url,
    isGenerating: inProgress,
    progressOverlay: <ProgressOverlay in={inProgress} />,
  };
}
