import withRouter from 'components/hoc/withRouter';
import { PropsOf } from 'types';
import VideoClipWizardLoader from './VideoClipWizardLoader';

const EnhancedComponent = withRouter(VideoClipWizardLoader, {
  parseBooleans: false,
  parseNumbers: false,
});

export default EnhancedComponent;
export type VideoClipWizardLoaderProps = PropsOf<typeof EnhancedComponent>;
