import cn from 'classnames';
import * as React from 'react';

import { cardWizardBlock as block } from '../../../utils';
import CardWizardControlButton from './CardWizardControlButton';

export interface CardWizardControlsProps {
  children?: React.ReactNode;
  className?: string;
  horizontal?: boolean;
}

interface CardWizardControls extends React.FC<CardWizardControlsProps> {
  Button: typeof CardWizardControlButton;
}

const CardWizardControls: CardWizardControls = ({
  children,
  className,
  horizontal,
}) => (
  <div className={cn(block('controls', { horizontal }), className)}>
    {children}
  </div>
);

CardWizardControls.Button = CardWizardControlButton;

export default CardWizardControls;
