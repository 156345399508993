import {
  actions as imageSearch,
  ImageToVideoProvider,
} from 'redux/middleware/api/image-search-service';
import { ThunkAction } from 'redux/types';
import reduxPoll from 'utils/redux-poll';
import { Type } from './action-types';
import { IMAGE_TO_VIDEO_POLL_ID } from './constants';
import {
  isPollingImageToVideoSelector,
  shouldCancelPollingSelector,
} from './selectors';
import { ImageToVideoResult } from './types';

export const pollForImageToVideo = (
  imageToVideoJobId: number,
): ThunkAction<Promise<ImageToVideoResult | undefined>> => async (
  dispatch,
  getState,
) => {
  if (isPollingImageToVideoSelector(getState())) {
    return undefined;
  }

  dispatch({
    type: Type.IMAGE_TO_VIDEO_POLLING_BEGIN,
  });

  return reduxPoll<ImageToVideoResult>(
    dispatch,
    async () => {
      if (shouldCancelPollingSelector(getState())) {
        return { status: 'cancelled' };
      }

      const { response } = await dispatch(
        imageSearch.getImageToVideo(imageToVideoJobId),
      );

      if (response.status === 'completed') {
        dispatch({
          type: Type.IMAGE_TO_VIDEO_POLLING_SUCCESS,
          payload: { videos: response.videos },
        });
      }

      return response;
    },
    {
      id: IMAGE_TO_VIDEO_POLL_ID,
      shouldContinue: (_, value) => {
        if (value.status === 'error') {
          dispatch({
            type: Type.IMAGE_TO_VIDEO_POLLING_FAILURE,
          });

          return false;
        }

        if (value.status === 'completed') {
          dispatch({
            type: Type.IMAGE_TO_VIDEO_POLLING_END,
          });
          return false;
        }

        if (value.status === 'cancelled') {
          dispatch({
            type: Type.IMAGE_TO_VIDEO_CREATE_ABORTED,
          });
          return false;
        }

        return true;
      },
      intervalMillis: 2500,
      maxAttempts: Infinity,
    },
  );
};

export const cancelImageToVideoCreation = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  dispatch({
    type: Type.IMAGE_TO_VIDEO_CREATE_CANCEL,
  });
};

export const createImageToVideo = (
  sourceImageUrl: string,
  provider: ImageToVideoProvider,
): ThunkAction<Promise<ImageToVideoResult | undefined>> => async dispatch => {
  dispatch({
    type: Type.IMAGE_TO_VIDEO_CREATE_REQUEST,
    payload: { sourceImageUrl, provider },
  });

  try {
    const {
      response: { imageToVideoJobId },
    } = await dispatch(
      imageSearch.createImageToVideo(sourceImageUrl, provider),
    );

    dispatch({
      type: Type.IMAGE_TO_VIDEO_CREATE_SUCCESS,
      payload: { imageToVideoJobId },
    });

    return dispatch(pollForImageToVideo(imageToVideoJobId));
  } catch (err) {
    dispatch({
      type: Type.IMAGE_TO_VIDEO_CREATE_FAILURE,
    });

    throw err;
  }
};

export const resetImageToVideo = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  dispatch({
    type: Type.IMAGE_TO_VIDEO_RESET,
  });
};
