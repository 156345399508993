import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UseAuthModal from 'blocks/Authentication/containers/UseAuthModal';
import WaveformPresetListHeader from 'components/WaveformPresetList/WaveformPresetListHeader';
import { createWaveformPref } from 'redux/modules/user-pref/actions';
import {
  createWaveformPrefsEmptySelector,
  isFetchingUserPrefSelector,
  isSavingWaveformPrefSelector,
} from 'redux/modules/user-pref/selectors';
import { Dispatch } from 'redux/types';
import { Soundwave } from 'types/common';

type Props = {
  disabled?: boolean;
  aspectRatio: number;
  getSoundwaveToSave?: () => Soundwave;
  onSaved?: (soundwave: Soundwave) => void;
};

function WaveformPresetsHeader(props: Props) {
  const { disabled, aspectRatio, onSaved, getSoundwaveToSave } = props;
  const isFetching = useSelector(isFetchingUserPrefSelector);
  const isSaving = useSelector(isSavingWaveformPrefSelector);
  const dispatch = useDispatch<Dispatch>();
  const isEmpty = useSelector(
    useMemo(() => createWaveformPrefsEmptySelector(aspectRatio), [aspectRatio]),
  );
  const handleSave = async () => {
    const currentSoundwave = getSoundwaveToSave();
    const waveformPrefId = await dispatch(
      createWaveformPref(aspectRatio, currentSoundwave),
    );
    if (waveformPrefId) {
      onSaved?.({
        ...currentSoundwave,
        waveformPrefId,
      });
    }
  };

  return (
    <UseAuthModal>
      {({ withAuthentication }) => (
        <WaveformPresetListHeader
          empty={isEmpty}
          disabled={disabled || isFetching || isSaving}
          onSaveClick={withAuthentication(handleSave)}
          tooltip={disabled && 'Select a waveform style to save it'}
        />
      )}
    </UseAuthModal>
  );
}

export default WaveformPresetsHeader;
