import { showError } from 'redux/modules/notification/actions';
import { ThunkAction } from 'redux/types';
import { downloadFile } from 'utils/file';
import { ActionType } from './action-types';

export const clearSelectedAudio = () => ({
  type: ActionType.CLEAR_SELECTED_AUDIO,
});

export const downloadSampleAudio = (
  url,
  id,
  name,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: ActionType.SAMPLE_AUDIO_DOWNLOAD_REQUEST });
  try {
    const file = await downloadFile(url, name);
    dispatch({
      payload: { file, id },
      type: ActionType.SAMPLE_AUDIO_DOWNLOAD_SUCCESS,
    });
  } catch {
    dispatch({ type: ActionType.SAMPLE_AUDIO_DOWNLOAD_FAILURE });
    dispatch(
      showError(
        'Error occurred when downloading the selected audio. Please try again later.',
        15,
      ),
    );
  }
};
