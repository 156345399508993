import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { useCallback, useMemo } from 'react';
import useField from './useField';

export default function useStripeField() {
  const { error, onChange, setError, value } = useField();

  const handleStripeChange = useCallback(
    (event: StripeCardElementChangeEvent) => {
      if (event.error) {
        setError(error);
      }
      onChange(event.complete ? 'OK' : '');
    },
    [error, onChange, setError],
  );

  return useMemo(
    () => ({
      error,
      onChange: handleStripeChange,
      setError,
      value,
    }),
    [error, handleStripeChange, setError, value],
  );
}
