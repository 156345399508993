/* eslint-disable no-underscore-dangle */
import ExtendoError from 'extendo-error';
import { EntireEpisodeTranscriptCreationStatus } from 'redux/middleware/api/podcast-service';

export const ENTIRE_EPISODE_TRANSCRIPTION_POLL_ID =
  'app/automation-cms/episode-transcription-poll-id';
export const SUBSCRIPTION_PREVIEW_POLL_ID =
  'app/automation-cms/subscription-preview-poll-id';

type Reason =
  | Extract<
      EntireEpisodeTranscriptCreationStatus,
      | 'transcribeTimeLimitReached'
      | 'concurrentLimitReached'
      | 'languageNotSupported'
    >
  | 'conflict';

export class EpisodeTranscriptError extends ExtendoError {
  private _reason: Reason;
  private _language: string;

  constructor(reason?: Reason, language?: string) {
    super(undefined);
    this._reason = reason;
    this._language = language;
  }

  get reason() {
    return this._reason;
  }

  get language() {
    return this._language;
  }
}

export const PREVIEW_PROCESSING_STATES = ['queued', 'processing'];
