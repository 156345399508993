import React, { useMemo } from 'react';
import { mapObject, noop } from 'underscore';
import { formatCSSFilter } from 'utils/dom';

import { useCanvasSize } from '../context/CanvasSizeContext';
import useSlide from '../useSlide';
import ImageAsset from './ImageAsset';
import useSlideSrc from './useSlideSrc';

const { useCallback } = React;

export interface SlideshowAssetProps {
  hidden?: boolean;
  fixed?: boolean;
  id: string;
  onLoad?: (e: React.SyntheticEvent<HTMLImageElement>, id: string) => void;
  onError?: (e: React.SyntheticEvent<HTMLImageElement>, id: string) => void;
  onLoadStart?: (e: React.SyntheticEvent<HTMLImageElement>, id: string) => void;
}

/*
 * if asset is fixed, slide position will be used to place the slide in a
 * particular location.  If asset is not fixed, a draggable parent should
 * provide position and size information (see DraggableSlideshowAsset)
 */
const SlideshowAsset: React.FC<SlideshowAssetProps> = ({
  fixed,
  hidden,
  id,
  onLoad = noop,
  onError = noop,
  onLoadStart = noop,
}) => {
  const slide = useSlide(id);
  const imageUrl = useSlideSrc(id);
  const { toPx } = useCanvasSize();

  const { top, left, height, width } = slide.placement || {};

  const position = useMemo(() => {
    if (!fixed) return undefined;
    return mapObject({ top, left, height, width }, val => toPx(val).value);
  }, [fixed, height, left, toPx, top, width]);

  const handleImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      onLoad(e, id);
    },
    [id, onLoad],
  );

  const handleImageError = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      onError(e, id);
    },
    [id, onError],
  );

  const handleImageLoadStart = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      onLoadStart(e, id);
    },
    [id, onLoadStart],
  );

  return !imageUrl ? null : (
    <ImageAsset
      imageUrl={imageUrl}
      onLoad={handleImageLoad}
      onError={handleImageError}
      onLoadStart={handleImageLoadStart}
      style={{
        display: hidden ? 'none' : 'block',
        pointerEvents: 'none',
        filter: formatCSSFilter({
          blur: {
            radius: toPx(slide.blurRadius)?.toString(),
          },
        }),
        ...position,
      }}
    />
  );
};

export default SlideshowAsset;
