import * as React from 'react';
import { InputAdornment } from 'components/Form';
import { PlayButtonCircle } from 'components/icons';
import Tooltip from 'components/Tooltip';

function ClipStartTimeInputAdornment(
  props: React.ComponentPropsWithoutRef<typeof InputAdornment>,
) {
  return (
    <Tooltip
      content="Preview first 5 seconds"
      id="start-time-tooltip"
      placement="top"
    >
      <InputAdornment {...props}>
        <PlayButtonCircle />
      </InputAdornment>
    </Tooltip>
  );
}

export default ClipStartTimeInputAdornment;
