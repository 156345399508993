import { connect } from 'react-redux';

import {
  performDpa,
  sendDownloadPageActionEvent,
  videoUrlSelector,
} from 'redux/modules/download';
import { onClickDownloadVideo } from 'redux/modules/mixpanel';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import DownloadButton, { DownloadButtonProps as Props } from './DownloadButton';

type StateProps = Pick<Props, 'url'>;
type DispatchProps = Pick<Props, 'onClick'>;

const mapStateToProps = (state: State): StateProps => ({
  url: videoUrlSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onClick: () => {
    dispatch(performDpa);
    dispatch(onClickDownloadVideo());
    dispatch(sendDownloadPageActionEvent());
  },
});

const component = connect(mapStateToProps, mapDispatchToProps)(DownloadButton);
export type DownloadButtonProps = PropsOf<typeof component>;
export default component;
