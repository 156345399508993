import * as React from 'react';

function SaveAsTemplateCentered(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={38}
      height={37}
      viewBox="0 0 38 37"
      {...props}
    >
      <path
        d="M21.344 2.852c.747 0 1.352.619 1.352 1.381 0 .763-.605 1.381-1.352 1.381h0H5.032c-.73 0-1.327.616-1.327 1.382h0v24.86c0 .766.597 1.382 1.327 1.382h24.082c.73 0 1.327-.616 1.327-1.381h0V19.412c0-.763.606-1.38 1.353-1.38s1.352.617 1.352 1.38h0v12.445c0 2.285-1.802 4.143-4.032 4.143h0H5.032C2.802 36 1 34.142 1 31.857h0V6.996C1 4.71 2.802 2.852 5.032 2.852h0zm-7.051 12.59c0-.72.76-1.002 1.256-.71h0l6.105 3.58a.822.822 0 010 1.422h0l-6.105 3.58c-.55.323-1.256-.066-1.256-.71h0zM34.607 1A2.392 2.392 0 0137 3.393h0v10.69a.797.797 0 01-1.416.503h0l-2.979-3.655a.798.798 0 00-1.232-.005h0L28.31 14.62a.798.798 0 01-1.412-.509h0V3.391A2.394 2.394 0 0129.291.999h0z"
        fill="#282E37"
        fillRule="nonzero"
        stroke="#282E37"
      />
    </svg>
  );
}

export default SaveAsTemplateCentered;
