import * as React from 'react';

import { WaveformProps } from '../../types';

const WideRoundBars = React.forwardRef<SVGSVGElement, WaveformProps>(
  (props, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 104 32"
      preserveAspectRatio="none"
      ref={ref}
      {...props}
    >
      <path d="M0.324 13.043c0.179 0 0.324 0.145 0.324 0.324v5.253c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-5.253c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M1.539 10.37c0.179 0 0.324 0.145 0.324 0.324v10.568c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-10.568c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M2.754 0.324c0.179 0 0.324 0.145 0.324 0.324v30.655c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-30.655c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M3.97 5.104c0.179 0 0.324 0.145 0.324 0.324v21.042c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-21.042c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M5.185 11.585c0.179 0 0.324 0.145 0.324 0.324v8.184c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-8.184c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M6.4 9.559c0.179 0 0.324 0.145 0.324 0.324v12.248c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-12.248c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M7.615 6.319c0.179 0 0.324 0.145 0.324 0.324v18.619c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-18.619c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M8.83 9.397c0.179 0 0.324 0.145 0.324 0.324v12.483c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-12.483c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M10.046 14.015c0.179 0 0.324 0.145 0.324 0.324v3.221c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-3.221c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M11.261 14.096c0.179 0 0.324 0.145 0.324 0.324v3.104c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-3.104c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M12.476 13.772c0.179 0 0.324 0.145 0.324 0.324v3.729c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-3.729c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M13.691 11.018c0.179 0 0.324 0.145 0.324 0.324v9.2c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-9.2c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M14.906 10.451c0.179 0 0.324 0.145 0.324 0.324v10.334c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-10.334c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M16.122 12.962c0.179 0 0.324 0.145 0.324 0.324v5.409c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-5.409c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M17.337 11.504c0.179 0 0.324 0.145 0.324 0.324v8.34c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-8.34c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M18.552 3.565c0.179 0 0.324 0.145 0.324 0.324v24.207c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-24.207c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M19.767 8.668c0.179 0 0.324 0.145 0.324 0.324v14.046c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-14.046c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M20.982 13.853c0.179 0 0.324 0.145 0.324 0.324v3.533c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-3.534c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M22.197 8.668c0.179 0 0.324 0.145 0.324 0.324v13.89c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-13.89c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M23.413 7.048c0.179 0 0.324 0.145 0.324 0.324v17.134c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-17.134c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M24.628 12.233c0.179 0 0.324 0.145 0.324 0.324v6.816c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-6.816c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M25.843 11.585c0.179 0 0.324 0.145 0.324 0.324v8.184c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-8.184c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M27.058 14.015c0.179 0 0.324 0.145 0.324 0.324v3.299c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-3.299c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M28.273 14.015c0.179 0 0.324 0.145 0.324 0.324v3.338c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-3.338c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M29.489 8.992c0.179 0 0.324 0.145 0.324 0.324v13.343c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-13.343c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M30.623 10.937c0.179 0 0.324 0.145 0.324 0.324v9.357c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-9.357c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M31.838 7.453c0.179 0 0.324 0.145 0.324 0.324v16.352c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-16.352c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M33.053 12.719c0.179 0 0.324 0.145 0.324 0.324v5.8c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-5.8c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M34.268 6.238c0.179 0 0.324 0.145 0.324 0.324v18.814c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-18.814c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M35.484 10.451c0.179 0 0.324 0.145 0.324 0.324v10.451c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-10.451c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M36.699 13.61c0.179 0 0.324 0.145 0.324 0.324v4.081c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-4.081c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M37.914 10.208c0.179 0 0.324 0.145 0.324 0.324v10.92c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-10.92c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M39.129 9.559c0.179 0 0.324 0.145 0.324 0.324v12.131c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-12.131c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M40.344 9.478c0.179 0 0.324 0.145 0.324 0.324v12.366c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-12.366c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M41.559 13.367c0.179 0 0.324 0.145 0.324 0.324v4.511c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-4.511c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M42.775 14.096c0.179 0 0.324 0.145 0.324 0.324v3.104c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-3.104c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M43.99 13.124c0.179 0 0.324 0.145 0.324 0.324v5.097c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-5.097c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M45.205 11.99c0.179 0 0.324 0.145 0.324 0.324v7.324c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-7.324c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M46.42 13.853c0.179 0 0.324 0.145 0.324 0.324v3.612c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-3.612c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M47.635 14.096c0.179 0 0.324 0.145 0.324 0.324v3.143c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-3.143c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M48.851 14.582c0.179 0 0.324 0.145 0.324 0.324v2.166c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-2.166c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M50.066 14.258c0.179 0 0.324 0.145 0.324 0.324v2.83c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-2.83c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M51.281 10.127c0.179 0 0.324 0.145 0.324 0.324v11.037c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-11.037c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M52.496 11.585c0.179 0 0.324 0.145 0.324 0.324v8.067c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-8.067c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M53.711 13.934c0.179 0 0.324 0.145 0.324 0.324v3.377c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-3.377c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M54.927 10.856c0.179 0 0.324 0.145 0.324 0.324v9.591c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-9.591c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M56.142 9.559c0.179 0 0.324 0.145 0.324 0.324v12.131c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-12.131c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M57.357 11.909c0.179 0 0.324 0.145 0.324 0.324v7.481c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-7.481c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M58.572 8.911c0.179 0 0.324 0.145 0.324 0.324v13.538c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-13.538c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M59.787 0c0.179 0 0.324 0.145 0.324 0.324v31.32c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-31.32c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M61.003 13.124c0.179 0 0.324 0.145 0.324 0.324v5.058c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-5.058c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M62.218 12.638c0.179 0 0.324 0.145 0.324 0.324v6.035c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-6.035c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M63.433 6.4c0.179 0 0.324 0.145 0.324 0.324v18.501c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-18.501c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M64.648 10.37c0.179 0 0.324 0.145 0.324 0.324v10.529c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-10.529c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M65.863 14.663c0.179 0 0.324 0.145 0.324 0.324v2.048c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-2.048c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M67.078 9.884c0.179 0 0.324 0.145 0.324 0.324v11.584c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-11.584c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M68.294 8.83c0.179 0 0.324 0.145 0.324 0.324v13.616c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-13.616c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M69.509 12.557c0.179 0 0.324 0.145 0.324 0.324v6.191c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-6.191c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M70.724 7.048c0.179 0 0.324 0.145 0.324 0.324v17.134c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-17.134c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M71.939 11.261c0.179 0 0.324 0.145 0.324 0.324v8.77c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-8.77c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M73.154 11.18c0.179 0 0.324 0.145 0.324 0.324v8.927c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-8.927c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M74.37 8.182c0.179 0 0.324 0.145 0.324 0.324v14.945c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-14.945c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M75.585 11.423c0.179 0 0.324 0.145 0.324 0.324v8.536c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-8.536c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M76.8 12.314c0.179 0 0.324 0.145 0.324 0.324v6.738c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-6.738c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M78.015 10.856c0.179 0 0.324 0.145 0.324 0.324v9.513c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-9.513c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M79.23 10.37c0.179 0 0.324 0.145 0.324 0.324v10.607c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-10.607c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M80.446 14.177c0.179 0 0.324 0.145 0.324 0.324v2.986c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-2.986c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M81.661 15.068c0.179 0 0.324 0.145 0.324 0.324v1.189c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-1.189c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M82.876 11.585c0.179 0 0.324 0.145 0.324 0.324v8.106c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-8.106c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M84.091 12.233c0.179 0 0.324 0.145 0.324 0.324v6.816c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-6.816c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M85.306 13.853c0.179 0 0.324 0.145 0.324 0.324v3.651c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-3.651c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M86.522 11.909c0.179 0 0.324 0.145 0.324 0.324v7.442c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-7.442c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M87.737 3.727c0.179 0 0.324 0.145 0.324 0.324v23.894c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-23.894c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M88.952 11.504c0.179 0 0.324 0.145 0.324 0.324v8.34c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-8.34c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M90.167 10.856c0.179 0 0.324 0.145 0.324 0.324v9.591c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-9.591c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M91.382 8.83c0.179 0 0.324 0.145 0.324 0.324v13.616c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-13.616c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M92.597 12.314c0.179 0 0.324 0.145 0.324 0.324v6.738c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-6.738c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M93.813 13.124c0.179 0 0.324 0.145 0.324 0.324v5.136c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-5.136c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M94.947 14.339c0.179 0 0.324 0.145 0.324 0.324v2.556c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-2.556c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M96.162 12.881c0.179 0 0.324 0.145 0.324 0.324v5.566c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-5.566c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M97.377 10.208c0.179 0 0.324 0.145 0.324 0.324v10.959c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-10.959c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M98.592 11.504c0.179 0 0.324 0.145 0.324 0.324v8.223c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-8.223c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M99.808 9.559c0.179 0 0.324 0.145 0.324 0.324v12.248c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-12.248c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M101.023 8.425c0.179 0 0.324 0.145 0.324 0.324v14.398c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-14.398c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M102.238 10.046c0.179 0 0.324 0.145 0.324 0.324v11.193c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-11.193c0-0.179 0.145-0.324 0.324-0.324z" />
      <path d="M103.453 9.316c0.179 0 0.324 0.145 0.324 0.324v12.717c0 0.179-0.145 0.324-0.324 0.324s-0.324-0.145-0.324-0.324v-12.717c0-0.179 0.145-0.324 0.324-0.324z" />
    </svg>
  ),
);

export default WideRoundBars;
