import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { block } from '../utils';
import EditableKeywordPhrase, {
  EditableKeywordPhraseProps,
} from './EditableKeywordPhrase';
import EditablePhraseTiming, {
  EditablePhraseTimingProps,
} from './EditablePhraseTiming';
import PhraseControls, { PhraseControlsProps } from './PhraseControls';

type TimingProps = Pick<
  EditablePhraseTimingProps,
  | 'startBoundsMillis'
  | 'startMillis'
  | 'onStartTimeSubmit'
  | 'endBoundsMillis'
  | 'endMillis'
  | 'onEndTimeSubmit'
>;

type KeywordPhraseProps = Pick<
  EditableKeywordPhraseProps,
  'isSaving' | 'keywords' | 'onKeywordClick' | 'phrase'
>;

type ControlsProps = Pick<PhraseControlsProps, 'onAdd' | 'onRemove'>;

export interface PhraseProps
  extends TimingProps,
    KeywordPhraseProps,
    ControlsProps {
  active?: boolean;
  className?: string;
  disabledControls?: PhraseControlsProps['disabled'];
  onCopyToTimeline?: (params: any) => void;
  onMount?: (el: HTMLDivElement) => void;
  onPhraseCancel?: () => void;
  onPhraseSubmit?: (text: string, params: any) => void;
  onPlay?: (params: any) => void;
  onTogglePlay?: (params: any) => void;
  onUnmount?: () => void;
  params?: any;
  phraseDefaultInputMode?: boolean;
  selected?: boolean;
}

export default class Phrase extends React.Component<PhraseProps> {
  public static defaultProps: Partial<PhraseProps> = {
    onCopyToTimeline: noop,
    onMount: noop,
    onPhraseSubmit: noop,
    onUnmount: noop,
  };

  private element: HTMLDivElement;

  public componentDidMount() {
    const { onMount } = this.props;
    onMount(this.element);
  }

  public componentWillUnmount() {
    const { onUnmount } = this.props;
    onUnmount();
  }

  private handlePhraseSubmit = (text: string) => {
    const { onPhraseSubmit, params } = this.props;
    onPhraseSubmit(text, params);
  };

  private handleCopyToTimeline = () => {
    const { onCopyToTimeline, params } = this.props;
    onCopyToTimeline(params);
  };

  private handlePlay = () => {
    const { onPlay, params } = this.props;
    onPlay(params);
  };

  private handleTogglePlay = () => {
    const { onTogglePlay, params } = this.props;
    onTogglePlay(params);
  };

  private setElement = el => {
    this.element = el;
  };

  public render() {
    const {
      active,
      className,
      disabledControls,
      endBoundsMillis,
      endMillis,
      isSaving,
      keywords,
      onAdd,
      onEndTimeSubmit,
      onKeywordClick,
      onPhraseCancel,
      onRemove,
      onStartTimeSubmit,
      phrase,
      phraseDefaultInputMode,
      selected,
      startBoundsMillis,
      startMillis,
    } = this.props;
    return (
      <div
        className={cn(
          block('phrase', {
            active: selected,
            attention: active,
          }),
          className,
        )}
        ref={this.setElement}
      >
        <EditablePhraseTiming
          endBoundsMillis={endBoundsMillis}
          endMillis={endMillis}
          startBoundsMillis={startBoundsMillis}
          startMillis={startMillis}
          onEndTimeSubmit={onEndTimeSubmit}
          onStartTimeSubmit={onStartTimeSubmit}
        />
        <EditableKeywordPhrase
          defaultInputMode={phraseDefaultInputMode}
          isSaving={isSaving}
          keywords={keywords}
          phrase={phrase}
          onCancelInput={onPhraseCancel}
          onKeywordClick={onKeywordClick}
          onSubmit={this.handlePhraseSubmit}
          onTogglePlay={this.handleTogglePlay}
        />
        <PhraseControls
          disabled={disabledControls}
          onAdd={onAdd}
          onCopyToTimeline={this.handleCopyToTimeline}
          onPlay={this.handlePlay}
          onRemove={onRemove}
        />
      </div>
    );
  }
}
