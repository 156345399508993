import * as React from 'react';
import SelectTile, { SelectTileProps } from './SelectTile';

type Props = Pick<
  SelectTileProps,
  'className' | 'onClick' | 'params' | 'selected'
>;

const ProgressPlayerTile: React.FC<Props> = ({
  className,
  onClick,
  params,
  selected,
}) => (
  <SelectTile {...{ className, onClick, params, selected }}>
    <svg width={68} height={13} viewBox="0 0 68 13">
      <g transform="translate(2)" fill="none" fillRule="evenodd">
        <path
          stroke="#5B5F6A"
          strokeLinecap="round"
          strokeWidth={4}
          d="M34 6.5h30"
        />
        <path
          stroke="#19B5FE"
          strokeLinecap="round"
          strokeWidth={4}
          d="M0 6.5h34"
        />
        <circle cx={34.5} cy={6.5} r={6.5} fill="#19B5FE" />
      </g>
    </svg>
  </SelectTile>
);

export default ProgressPlayerTile;
