import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CanvaDesignType, useCanva } from 'components/CanvaContext';
import { onClickCanvaButton } from 'redux/modules/mixpanel';
import { AspectRatioName } from 'types';
import usePreviousRef from './usePreviousRef';

export interface UseCanvaDesignConfig {
  onPublish?: (imageUrl: string, designId: string) => void;
  source: Parameters<typeof onClickCanvaButton>[0];
}

function getDesignType(aspectRatioName: AspectRatioName): CanvaDesignType {
  switch (aspectRatioName) {
    case 'square':
      return 'InstagramPost';

    case 'portrait':
      return 'InstagramStory';

    default:
      return 'YouTubeThumbnail';
  }
}

export default function useCanvaDesign(config?: UseCanvaDesignConfig) {
  const { onPublish, source } = config || {};
  const dispatch = useDispatch();
  const canva = useCanva();
  const onPublishRef = usePreviousRef(onPublish);
  const canvaRef = usePreviousRef(canva);

  return {
    createDesign: useCallback(
      (aspectRatioName: AspectRatioName) => {
        source && dispatch(onClickCanvaButton(source));
        canvaRef.current.createDesign(
          getDesignType(aspectRatioName),
          ({ designId, exportUrl }) => {
            onPublishRef.current(exportUrl, designId);
          },
        );
      },
      [canvaRef, dispatch, onPublishRef, source],
    ),
  };
}
