import { Slider } from '@sparemin/blockhead';
import React from 'react';

import TextShadowControl from './TextShadowControl';

import { block } from './utils';

interface TextShadowSliderProps {
  icon: React.ReactNode;
  label: string;
  maxValue: number;
  minValue: number;
  onChange: (value: number) => void;
  value: number;
}

const TextShadowSlider: React.FunctionComponent<TextShadowSliderProps> = props => {
  const { icon, label, maxValue, minValue, onChange, value } = props;

  return (
    <TextShadowControl icon={icon} label={label} layout="vertical">
      <Slider
        aria-label={`Slider control for ${label}`}
        className={block('slider')}
        maxValue={maxValue}
        minValue={minValue}
        onChange={onChange}
        value={value}
      />
    </TextShadowControl>
  );
};

export default TextShadowSlider;
