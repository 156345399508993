import {
  AudiogramDestination,
  PlatformVideoType,
} from 'blocks/AudiogramDestinations/types';
import {
  MixpanelAudiogramDestination,
  MixpanelAudiogramVideoTypes,
} from 'redux/modules/mixpanel/types';

export const DESTINATION_ID_TO_MIXPANEL_DESTINATION_MAP: Record<
  AudiogramDestination,
  MixpanelAudiogramDestination
> = {
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'LinkedIn',
  tiktok: 'TikTok',
  twitter: 'X',
  youtube: 'YouTube',
  threads: 'Threads',
};

export const VIDEO_TYPE_TO_MIXPANEL_VIDEO_TYPE_MAP: Record<
  PlatformVideoType,
  MixpanelAudiogramVideoTypes
> = {
  clip: 'EpisodeClip',
  episode: 'FullEpisode',
  post: 'Post',
  reel: 'Reel',
  short: 'Short',
  story: 'Story',
};
