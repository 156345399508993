import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WithRouterComponentProps } from 'components/hoc/withRouter';
import { loadWizardData } from 'redux/modules/audio-wizard/actions';
import { isLoadingSelector } from 'redux/modules/audio-wizard/selectors';
import { showError } from 'redux/modules/notification/actions';
import { goToCreate } from 'redux/modules/router/actions';
import { Dispatch } from 'redux/types';
import { isApplicationError } from 'utils/ApplicationError';
import AudioWizard from '../AudioWizard';
import { getTraceIdsFromQuery } from '../utils';
import LoadingAssetsSpinner from './LoadingAssetsSpinner';

interface IProps extends WithRouterComponentProps<string> {}

const ERROR_ID = 'audio-wizard-loader-error';

function AudioWizardLoader(props: IProps) {
  const { location } = props;
  const dispatch = useDispatch<Dispatch>();
  const isLoading = useSelector(isLoadingSelector);
  const { query } = location;
  const { traceId, customTraceId, episodeId } = getTraceIdsFromQuery(query);

  useEffect(() => {
    dispatch(loadWizardData(customTraceId, traceId, episodeId)).catch(err => {
      if (isApplicationError(err)) {
        dispatch(
          showError({
            message: err.message,
            dismissAfterSec: 0,
            code: err.code,
          }),
        );
      } else {
        dispatch(showError(err.message, 0, ERROR_ID));
      }
      dispatch(goToCreate());
    });
  }, [customTraceId, traceId, dispatch, episodeId]);

  return isLoading ? (
    <LoadingAssetsSpinner />
  ) : (
    <AudioWizard
      traceId={traceId}
      customTraceId={customTraceId}
      episodeId={query.episodeId as string}
    />
  );
}

export default memo(AudioWizardLoader);
export { IProps as AudioWizardLoaderProps };
