import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { showError } from 'redux/modules/notification';

import { useEditorState } from './context/VideoEditorStateContext';
import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import { IntroOutroType } from './types';
import useVideoSource, { ProcessedVideoConfig } from './useVideoSource';

const VIDEO_INTEGRATION_INDEX = 0;

const MODE_ACTION_TYPE_MAP: Record<
  'add' | 'replace',
  'INTRO_OUTRO_ADD' | 'INTRO_OUTRO_REPLACE'
> = {
  add: 'INTRO_OUTRO_ADD',
  replace: 'INTRO_OUTRO_REPLACE',
};

const useAddIntroOutro = () => {
  const [lastUsedType, setLastUsedType] = useState<IntroOutroType>();
  // As video media processing is detached from its processing, it is required to have a
  // way for knowing both add or replace mode and if target is intro or outro. As using a state
  // based variable may lead to using an outdated state or to regenerating the callbacks refs
  // (which can lead to unwanted sideffects with video processor), using a ref is the best
  // option for being able to read this value in real time.
  // This solution assumes that only one upload at once happens for each of the hooks instances.
  const operationOptionsRef = React.useRef<{
    mode: 'add' | 'replace';
    type: IntroOutroType;
  }>({
    mode: 'add',
    type: 'intro',
  });

  const reduxDispatch = useDispatch();
  const dispatch = useTemplateDispatch();
  const state = useTemplateState();

  const { integrations } = useEditorState();

  const { edgeVideosIntegration } = integrations;
  const videoMediaIntegration = edgeVideosIntegration[VIDEO_INTEGRATION_INDEX];

  const handleError = React.useCallback(
    (file, reason) => {
      const { type } = operationOptionsRef.current;
      // this particular error code needs to be handled in a different way. The error could be written
      // at integration but on that scope there is no reference to the type (intro/outro) and also
      // the title can not be handled
      if (reason?.code === 'IN008') {
        reduxDispatch(
          showError({
            dismissAfterSec: 5,
            message: `Make sure your ${type} is not greater than ${
              spareminConfig.introOutroClipMaxMb
            }MB or ${spareminConfig.introOutroClipMaxMins} minute${
              spareminConfig.introOutroClipMaxMins > 1 ? 's' : ''
            }`,
            title: 'Sorry, that clip exceeds our limits',
          }),
        );
      } else {
        dispatch({
          error: true,
          meta: { file },
          payload: reason,
          type: 'INTRO_OUTRO_ADD_ERROR',
        });
      }
    },
    [dispatch, reduxDispatch],
  );

  const handleIntroOutroProcessed = React.useCallback(
    (payload: ProcessedVideoConfig) => {
      const { mode, type } = operationOptionsRef.current;
      const { fileName, src } = payload;
      const actionType = MODE_ACTION_TYPE_MAP[mode];

      dispatch({
        payload: {
          fileName,
          src,
          type,
        },
        type: actionType,
      });
    },
    [dispatch],
  );

  const handleAddIntegrationVideo = (
    type: IntroOutroType,
    mode: 'add' | 'replace',
  ) => () => {
    setLastUsedType(type);
    operationOptionsRef.current = { mode, type };
    videoMediaIntegration?.onAddMedia(
      {
        accept: src => {
          processVideo(src);
        },
        reject: handleError,
      },
      state,
    );
  };

  const processVideo = useVideoSource({
    onUploadAnotherFile: handleAddIntegrationVideo(lastUsedType, 'replace'),
    onError: handleError,
    onVideoProcessed: handleIntroOutroProcessed,
  });

  return handleAddIntegrationVideo;
};

export default useAddIntroOutro;
