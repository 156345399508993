import * as React from 'react';

import Modal from 'components/Modal';
import { Omit, ProgressAndTimerOptions } from 'types';
import { Measurement } from 'utils/measurement';
import ProgressAnimationModalBody, {
  ProgressAnimationModalBodyProps,
} from './ProgressAnimationModalBody';
import { useProgressAnimationOptionsState } from './ProgressAnimationOptions';

const { useCallback } = React;

type PickedBodyProps = Omit<
  ProgressAnimationModalBodyProps,
  'onChange' | 'value'
>;

export interface ProgressAnimationModalContentsProps extends PickedBodyProps {
  defaultValue: ProgressAndTimerOptions<Measurement>;
  hideTimer?: boolean;
  onHide?: () => void;
  onSubmit?: (value: ProgressAndTimerOptions<Measurement>) => void;
}

const ProgressAnimationModalContents: React.SFC<ProgressAnimationModalContentsProps> = ({
  aspectRatio,
  background,
  defaultValue,
  hideTimer,
  onHide,
  onSubmit,
}) => {
  const { value, handleChange } = useProgressAnimationOptionsState(
    defaultValue,
  );

  const handleSubmit = useCallback(() => {
    onSubmit(value);
    onHide();
  }, [value, onHide, onSubmit]);

  return (
    <>
      <ProgressAnimationModalBody
        onChange={handleChange}
        {...{ aspectRatio, background, hideTimer, value }}
      />
      <Modal.Footer>
        <Modal.FooterButtons>
          <Modal.FooterButton onClick={onHide}>cancel</Modal.FooterButton>
          <Modal.FooterButton theme="submit" onClick={handleSubmit}>
            save
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default ProgressAnimationModalContents;
