import Immutable from 'immutable';
import { combineReducers } from 'redux-immutable';

import announcement from './announcement';
import app from './app';
import appsConnected from './apps-connected';
import asyncAudioClipper from './async-audio-clipper';
import audioWizard from './audio-wizard';
import audiogramWizard from './audiogram-wizard';
import { actionTypes as authTypes } from './auth';
import auth from './auth/reducer';
import automatedWizard from './automated-wizard';
import automationCms from './automation-cms';
import claimPodcast from './claim-podcast';
import clipSelect from './clip-select';
import displayPref from './display-pref';
import download from './download';
import editAutomation from './edit-automation';
import embed from './embed';
import embedExport from './embed-export';
import entities from './entities';
import fonts from './fonts';
import gifSearch from './gif-search';
import imageSearch from './image-search';
import imageToVideo from './image-to-video';
import media from './media';
import mixpanel from './mixpanel';
import modal from './modal';
import notification from './notification';
import oauth from './oauth';
import onboarding from './onboarding';
import podcast from './podcast';
import podcastSearch from './podcast-search';
import postTask from './post-task';
import pricing from './pricing';
import project from './project';
import projectTemplates from './project-templates';
import recordingUpload from './recording-upload';
import router from './router';
import sampleAudio from './sample-audio';
import social from './social';
import survey from './survey';
import templateWizard from './template-wizard';
import textPresets from './text-presets';
import textToImage from './text-to-image';
import textWizard from './text-wizard';
import userPref from './user-pref';
import videoClipWizard from './video-clip-wizard';
import videoEdit from './video-edit';
import videoSearch from './video-search';
import videoUpload from './video-upload';
import wizardExport from './wizard-export';
import wizardLastUsedStyle from './wizard-last-used-style';

const createApplicationReducer = history =>
  combineReducers({
    announcement,
    app,
    appsConnected,
    asyncAudioClipper,
    audiogramWizard,
    audioWizard,
    auth,
    automatedWizard,
    automationCms,
    claimPodcast,
    clipSelect,
    displayPref,
    download,
    editAutomation,
    embed,
    embedExport,
    entities,
    fonts,
    gifSearch,
    imageSearch,
    media,
    mixpanel,
    modal,
    notification,
    oauth,
    onboarding,
    podcast,
    podcastSearch,
    postTask,
    pricing,
    project,
    projectTemplates,
    recordingUpload,
    router: router(history),
    sampleAudio,
    social,
    survey,
    templateWizard,
    textPresets,
    textToImage,
    imageToVideo,
    textWizard,
    userPref,
    videoClipWizard,
    videoEdit,
    videoSearch,
    videoUpload,
    wizardExport,
    wizardLastUsedStyle,
  });

export default history => {
  const applicationReducer = createApplicationReducer(history);

  return (incomingState, action) => {
    /*
     * when we see `USER_LOGOUT` delete the entire redux state except the `app` key.  `app` stores a
     * flag for whether or not the application has been rehydrated.  if we delete the `app` state key,
     * the application will think that rehydration is in progress and will not render the app
     *
     * leverages the fact that all reducers must return their default state if the incoming state is
     * undefined.  keep in mind reducers only see their state key, so even when we see `USER_LOGOUT`
     * an create a new state object that only has an `app` key, all other reducers beisdes `app`
     * will see incoming state as undefined
     */
    const state =
      action.type !== authTypes.USER_LOGOUT
        ? incomingState
        : Immutable.Map({ app: incomingState.get('app') });

    return applicationReducer(state, action);
  };
};
