import React from 'react';
import { YouTubeSocialCircle } from 'components/SocialCircle';
import SocialButton, { SocialButtonProps } from './SocialButton';

export interface YouTubeButtonProps
  extends Pick<SocialButtonProps, 'disabled' | 'onClick' | 'params' | 'size'> {}

const YouTubeButton: React.FC<YouTubeButtonProps> = ({
  disabled,
  onClick,
  size,
}) => (
  <SocialButton
    icon={<YouTubeSocialCircle className="white-background" />}
    label="YouTube"
    size="small"
    {...{ disabled, onClick, size }}
  />
);

export default YouTubeButton;
