import { useCallback, useEffect } from 'react';
import { isFinite } from 'underscore';
import WaveSurferJs from 'wavesurfer.js';

import { GetWaveSurfer, WaveSurferProps } from './types';
import { positionToProgress } from './utils';

export default function useSeek(
  { options, position }: WaveSurferProps,
  getWaveSurfer: GetWaveSurfer,
) {
  const seek = useCallback(
    (ws: WaveSurferJs = getWaveSurfer()) => {
      if (!ws || !isFinite(position)) {
        return;
      }

      const progress = positionToProgress(ws, position);
      if (!options.autoCenter) {
        ws.seekTo(progress);
      } else {
        ws.seekAndCenter(progress);
      }
    },
    [getWaveSurfer, position, options.autoCenter],
  );

  useEffect(() => {
    const ws = getWaveSurfer();

    if (ws) {
      if (ws.isReady) {
        seek(ws);
      } else {
        const fn = () => seek(ws);
        ws.on('ready', fn);
        return () => ws.un('ready', fn);
      }
    }
    return undefined;
  }, [options.autoCenter, position, seek, getWaveSurfer]);

  return seek;
}
