import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'URL_GENERATOR';
export const ACTION_KEY: ActionKey = 'URL_GENERATOR';

export enum ServiceMethod {
  GET_TRACE_BY_CUSTOM_ID = 'GET_TRACE_BY_CUSTOM_ID',
  GET_TRACE_BY_ID = 'GET_TRACE_BY_ID',
}

export interface Trace {
  traceId: string;
  customTraceId: string;
  properties: {
    clipTitle: string;
    color: string;
    audioUrl: string;
    audioDurationSeconds: number;
    imageUrl: string;
    podcastLanguage?: string;
    rssUrl?: string;
    transcriptUrl: string;
  };
  displayProperties: {
    useAudioClipper: boolean;
  };
  integratorConfigurations: {
    audioClipperSelectionMaxMillis: number;
  };
  widgetOwner?: {
    ownerName?: string;
  };
  configurations: {
    audioClipperSelectionMaxMillis: number;
    pollForAsset: boolean;
  };
}

export interface NormalizedTrace {
  entities: {
    traces: {
      [id: string]: Trace;
    };
  };
  result: string;
}

export type GetTraceByCustomIdArgs = [string];
export type GetTraceByCustomIdResult = NormalizedTrace;

export type GetTraceByIdArgs = [string];
export type GetTraceByIdResult = NormalizedTrace;

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export type GetTraceByCustomIdAction = ApiAction<
  ServiceMethod.GET_TRACE_BY_CUSTOM_ID,
  GetTraceByCustomIdArgs
>;

export type GetTraceByIdAction = ApiAction<
  ServiceMethod.GET_TRACE_BY_ID,
  GetTraceByIdArgs
>;

export interface Handle {
  (
    method: ServiceMethod.GET_TRACE_BY_CUSTOM_ID,
    args: GetTraceByCustomIdArgs,
    token?: string,
  ): Promise<GetTraceByCustomIdResult>;

  (
    method: ServiceMethod.GET_TRACE_BY_ID,
    args: GetTraceByIdArgs,
    token?: string,
  ): Promise<GetTraceByIdResult>;
}

export interface UrlGeneratorServiceDispatch {
  (action: GetTraceByCustomIdAction): Promise<
    IApiResponse<GetTraceByCustomIdResult>
  >;

  (action: GetTraceByIdAction): Promise<IApiResponse<GetTraceByIdResult>>;
}
