import * as React from 'react';

import { useDispatch } from 'react-redux';
import { ModalFooterButton, ModalFooterButtons } from 'components/Modal';
import VideoEditorPlaybackTimeContext from 'containers/VideoEditor/VideoEditorPlaybackTimeContext';
import { addingToTrack, addSoundwave } from 'redux/modules/embed/actions';
import {
  audioTracksSelector,
  embedMainAudioSelector,
} from 'redux/modules/embed/selectors';
import { pushModal } from 'redux/modules/modal/actions';
import { Dispatch } from 'redux/types';
import { defaultSoundwaveState } from 'utils/embed/soundwave';
import { WaveformModalValue } from './types';
import WaveformModalContents from './WaveformModalContents';

const { useCallback, useContext, useState } = React;

export interface AddWaveformModalContentsProps {
  onHide?: () => void;
}

const AddWaveformModalContents: React.FC<AddWaveformModalContentsProps> = ({
  onHide,
}) => {
  const { positionSec } = useContext(VideoEditorPlaybackTimeContext);

  const dispatch = useDispatch<Dispatch>();
  const [soundwave, setSoundwave] = useState<WaveformModalValue>(
    defaultSoundwaveState.toJS(),
  );

  const handleSubmit = useCallback(() => {
    dispatch((_, getState) => {
      const audio = embedMainAudioSelector(getState());
      dispatch(
        addSoundwave({
          ...soundwave,
          time: !audio
            ? {
                startMillis: Math.round(positionSec * 1000),
                endMillis: Math.round(positionSec * 1000 + 5000),
              }
            : {
                startMillis: audio.get('startMillis'),
                endMillis: audio.get('endMillis'),
              },
        }),
      );

      onHide();

      if (!audio) {
        const audioTracks = audioTracksSelector(getState());
        dispatch(addingToTrack(audioTracks.first().get('id')));
        dispatch(pushModal({ name: 'AddAudioModal' }));
      }
    });
  }, [dispatch, onHide, positionSec, soundwave]);

  return (
    <WaveformModalContents
      value={soundwave}
      onChange={setSoundwave}
      footer={
        <ModalFooterButtons>
          <ModalFooterButton onClick={onHide}>cancel</ModalFooterButton>
          <ModalFooterButton onClick={handleSubmit} theme="submit">
            add to video
          </ModalFooterButton>
        </ModalFooterButtons>
      }
    />
  );
};

export default AddWaveformModalContents;
