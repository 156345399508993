import * as React from 'react';

import { IconProps } from 'components/icons/types';

/* tslint:disable-next-line:variable-name */
const InstagramTemplate: React.SFC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    {...props}
  >
    <path
      fill="#23282C"
      fillRule="nonzero"
      d="M10 1.068v7.829c0 .59-.48 1.067-1.071 1.067H7.025v-3.94h1.352l.194-1.504H7.025v-.961c0-.436.12-.732.747-.732h.8V1.484c-.139-.018-.612-.06-1.166-.06-1.152 0-1.942.7-1.942 1.988v1.11H4.107v1.503h1.36v3.94H1.07A1.07 1.07 0 0 1 0 8.896v-7.83C0 .479.48 0 1.071 0H8.93A1.07 1.07 0 0 1 10 1.068z"
    />
  </svg>
);

export default InstagramTemplate;
