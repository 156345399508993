import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  onClickEddyAiBanner,
  onShowEddyAiBanner,
} from 'redux/modules/mixpanel';

import EddyAiBanner from './EddyAiBanner';

const EddyAiBannerContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const handleEddyAiBannerLinkClick = useCallback(() => {
    dispatch(onClickEddyAiBanner());
  }, [dispatch]);

  useEffect(() => {
    dispatch(onShowEddyAiBanner());
  }, [dispatch]);

  return <EddyAiBanner onClick={handleEddyAiBannerLinkClick} />;
};

export default EddyAiBannerContainer;
