import * as React from 'react';

import {
  ApiSubscription,
  CancelledSubscription,
  EnterpriseSubscription,
  FreeSubscription,
  ProEducationSubscription,
  ProSubscription,
  ProTrialSubscription,
} from 'blocks/Pricing';
import BasicSubscription from 'blocks/Pricing/containers/BasicSubscription/BasicSubscription';
import EducationSubscription from 'blocks/Pricing/containers/EducationSubscription';
import UnlimitedSubscription from 'blocks/Pricing/containers/UnlimitedSubscription';
import LoadingOverlay from 'components/LoadingOverlay';
import { PRO_TIERS, SubTier } from 'redux/middleware/api/plan-service';
import bem from 'utils/bem';
import SettingsContent from '../SettingsContent';

export interface SubscriptionSettingsProps {
  isLoading?: boolean;
  pricingTier?: SubTier;
  autoRenewal?: boolean;
}

const block = bem('subscription-settings');

/*
 * NB: subscription data is loaded when settings page mounts, that way we don't
 *  query the api every time the user switches settings tabs. this also avoids
 *  the "if we have this data don't query for it" optimization which can lead to
 *  stale data
 */

const SubscriptionSettings: React.SFC<SubscriptionSettingsProps> = ({
  isLoading,
  pricingTier,
  autoRenewal,
}) => {
  if (isLoading) {
    return (
      <LoadingOverlay
        className={block('loading-overlay')}
        title="Loading Plan"
      />
    );
  }

  const element = (() => {
    if (
      pricingTier !== SubTier.FREE &&
      pricingTier !== SubTier.PRO_TRIAL &&
      autoRenewal === false
    ) {
      return <CancelledSubscription />;
    }

    switch (pricingTier) {
      case SubTier.FREE:
        return <FreeSubscription />;

      case SubTier.BASIC_20200922_PAID:
      case SubTier.BASIC_20211112_PAID:
        return <BasicSubscription />;

      case SubTier.PRO_TRIAL:
        return <ProTrialSubscription />;

      // TODO: SPAR_15945 really need an easier way than adding new enums everywhere...
      case SubTier.PRO_PAID:
      case SubTier.PRO_20200922_PAID:
      case SubTier.PRO_20211112_PAID:
        return <ProSubscription />;

      case SubTier.PRO_EDUCATION:
      case SubTier.PRO_ASSIGNED:
      case SubTier.PRO_FREE_TEMPORARY:
        return <ProEducationSubscription />;

      case SubTier.UNLIMITED_20211112_PAID:
      case SubTier.UNLIMITED_ASSIGNED:
        return <UnlimitedSubscription />;

      case SubTier.ENTERPRISE:
        return <EnterpriseSubscription />;

      case SubTier.ENTERPRISE_API:
        return <ApiSubscription />;

      case SubTier.EDUCATION_NONPROFIT:
        return <EducationSubscription />;

      case SubTier.PRO_PAUSED:
        return <ProSubscription />;

      case SubTier.BASIC_PAUSED:
        return <BasicSubscription />;
    }

    return null;
  })();

  const fullBleed =
    [...PRO_TIERS, SubTier.EDUCATION_NONPROFIT].includes(pricingTier) &&
    autoRenewal !== false;

  return (
    <SettingsContent
      bodyClassName={block('body')}
      className={block({ free: !fullBleed })}
      fullBleed={fullBleed}
    >
      {element}
    </SettingsContent>
  );
};

export default SubscriptionSettings;
