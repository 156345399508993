import * as React from 'react';

import { useSelector } from 'react-redux';
import * as waveforms from 'blocks/AudioWaveImage/components/Waveforms';
import { isAspectRatioLocked } from 'blocks/AudioWaveImage/utils';
import { defaultWaveformPrefOnlyEnabledSelector } from 'redux/modules/display-pref/selectors';
import { ViewportHeight, ViewportWidth } from 'utils/measurement';
import { camelToPascal } from 'utils/string';
import RndAsset, { RndAssetCallback } from '../assets/RndAsset';
import { useChildViewState, useNavigation } from '../context/NavigationContext';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import Layer from './Layer';

const { useCallback } = React;

export interface SoundwaveLayerProps {
  layerId?: string;
}

const SoundwaveLayer: React.FC<SoundwaveLayerProps> = () => {
  const [state, send] = useNavigation();
  const { soundwave } = useTemplateState();
  const dispatch = useTemplateDispatch();
  const { service: childService } = useChildViewState();
  const isWaveformPrefOnly = useSelector(
    defaultWaveformPrefOnlyEnabledSelector,
  );

  const {
    height,
    left = new ViewportWidth(0),
    top = new ViewportHeight(0),
    width,
  } = soundwave || {};

  const handleDimensionsChange: RndAssetCallback = useCallback(
    value => {
      dispatch({
        type: 'SOUNDWAVE_DIMENSIONS_CHANGE',
        payload: value,
      });
    },
    [dispatch],
  );

  const handleMouseDown = useCallback(() => {
    send({
      type: 'CHILD_VIEW_OPEN',
      payload: 'waveform',
      meta: { source: 'preview' },
    });
  }, [send]);

  const Waveform = waveforms[camelToPascal(soundwave?.type)];
  const isLayerActive =
    state.matches({ child: 'waveform' }) && childService?.state.matches('open');
  const lockAspectRatio = isAspectRatioLocked(soundwave?.type);

  return !soundwave || !Waveform ? null : (
    <Layer active={isLayerActive}>
      <RndAsset
        assetId="soundwave"
        bounds="parent"
        onMouseDown={handleMouseDown}
        onDragStop={handleDimensionsChange}
        onResizeStop={handleDimensionsChange}
        lockAspectRatio={lockAspectRatio}
        {...{ height, left, top, width }}
        {...(isWaveformPrefOnly && {
          enableResizing: false,
          disableDragging: true,
        })}
      >
        <Waveform
          style={{
            color: soundwave.type === 'blob' ? 'transparent' : undefined,
            height: '100%',
            fill: soundwave.color,
            width: '100%',
          }}
        />
      </RndAsset>
    </Layer>
  );
};

export default SoundwaveLayer;
