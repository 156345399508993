import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import { State } from 'redux/types';
import { IModal, ModalName } from './types';

export const stateSelector = (state: State): Immutable.List<IModal> =>
  state.get('modal');

export const activeModalSelector = createSelector(stateSelector, modals =>
  modals.first(),
);

export const isActiveSelector = (name: ModalName) => state => {
  const activeModal = activeModalSelector(state);
  return activeModal?.name === name && activeModal?.show;
};

export const modalParamsSelector = state => {
  const activeModal = activeModalSelector(state);
  return activeModal?.params;
};
