import * as React from 'react';

import { IconProps } from './types';

const Clock: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor" fillRule="nonzero">
        <g>
          <path
            d="M25 13c-6.629 0-12 5.371-12 12s5.371 12 12 12 12-5.371 12-12-5.371-12-12-12zm4.475 15.145l-.967 1.21c-.129.16-.315.263-.52.286-.204.022-.408-.037-.569-.165l-3.242-2.406c-.458-.367-.725-.923-.725-1.511v-7.527c0-.427.346-.774.774-.774h1.548c.428 0 .774.347.774.774V25l2.807 2.056c.16.129.263.316.286.52.022.204-.037.409-.166.57z"
            transform="translate(-1523 -552) translate(1510 539)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Clock;
