import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';

import ClaimPodcastModalContents from './ClaimPodcastModalContentsContainer';

const ClaimPodcastModal: React.FunctionComponent = () => {
  return (
    <>
      <ConnectedModal
        backdrop="static"
        name="ClaimPodcastModal"
        title="Claim your podcast"
      >
        <ClaimPodcastModalContents />
      </ConnectedModal>
    </>
  );
};

export default ClaimPodcastModal;
