import React from 'react';

import { SubscriptionPeriod } from 'blocks/Pricing/types';
import { SwitchField } from 'components/Form';
import { H1, H2 } from 'components/Heading';
import { block } from './utils';

export interface PlanSelectorHeaderProps {
  message: React.ReactNode;
  subMessage: React.ReactNode;
  onSubscriptionPeriodChange: (period: SubscriptionPeriod) => void;
  subscriptionPeriod: SubscriptionPeriod;
}

const PlanSelectorHeader: React.SFC<PlanSelectorHeaderProps> = props => {
  const {
    message,
    subMessage,
    onSubscriptionPeriodChange,
    subscriptionPeriod,
  } = props;

  return (
    <div className={block('header')}>
      <H1 className={block('header-message')}>{message}</H1>
      <H2 className={block('header-sub-message')}>{subMessage}</H2>
      <SwitchField
        className={block('switch')}
        controlClassName={block('switch-control')}
        left="monthly"
        onChange={onSubscriptionPeriodChange}
        right="yearly"
        value={subscriptionPeriod}
      />
    </div>
  );
};

export default PlanSelectorHeader;
