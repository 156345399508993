import * as React from 'react';

import { IconProps } from './types';

const BasicIcon: React.FC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 100 100"
    width="100"
    height="100"
    {...props}
  >
    <defs>
      <filter
        id="u53571qhra"
        width="153%"
        height="153%"
        x="-26.5%"
        y="-21.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="8"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
      </filter>
      <circle id="n51km0ofrb" cx="50" cy="50" r="50" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <use
                fill="#000"
                filter="url(#u53571qhra)"
                xlinkHref="#n51km0ofrb"
              />
              <use fill="#6E6EDE" xlinkHref="#n51km0ofrb" />
            </g>
            <path
              fill="#000"
              fillRule="nonzero"
              stroke="currentColor"
              d="M58.648 36c2.56 0 4.6 2.177 4.6 4.82l-.001 2.47 6.237-3.142c1.909-.956 4.516.026 4.516 1.689v16.815c0 1.67-2.616 2.645-4.516 1.689l-6.238-3.139.002 2.978c0 2.643-2.04 4.82-4.6 4.82H33.6c-2.56 0-4.6-2.177-4.6-4.82V40.82c0-2.643 2.04-4.82 4.6-4.82h25.048zm0 3.222H33.6c-.761 0-1.414.697-1.414 1.598v19.36c0 .901.653 1.598 1.414 1.598h25.048c.761 0 1.414-.697 1.414-1.598V40.82c0-.901-.653-1.598-1.414-1.598zm12.166 3.856l-7.567 3.811v6.714l.052.026 7.515 3.782V43.078z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BasicIcon;
