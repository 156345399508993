import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import LoadingOverlay from 'components/LoadingOverlay';
import AsyncAudioClipper from 'containers/AsyncAudioClipper';
import { entireAudioInstanceIdSelector } from 'redux/modules/clip-select/selectors';
import {
  entireAudioInstancesSelector,
  entireAudiosSelector,
} from 'redux/modules/entities/selectors';
import { RequestStatus } from 'types';
import AddToVideoButton from './AddToVideoButton';
import { useClipSelect } from './ClipSelectContext';

interface Props {
  className?: string;
}

const ClipSelectAudioClipper: React.FC<Props> = ({ className }) => {
  const { region, onRegionChange, submitStatus, clipperRef } = useClipSelect();
  const entireAudioInstanceId = useSelector(entireAudioInstanceIdSelector);
  const entireAudio = useSelector(state => {
    const entireAudioInstance = entireAudioInstancesSelector(state)?.get(
      String(entireAudioInstanceId),
    );
    return entireAudiosSelector(state)?.get(
      String(entireAudioInstance?.get('entireAudio')),
    );
  });

  const isSubmitting = submitStatus === RequestStatus.REQUEST;

  useLayoutEffect(() => {
    if (region) {
      clipperRef.current?.seek(region.startMillis);
    }
  }, [clipperRef, region]);

  return (
    <AsyncAudioClipper
      {...{ className, region }}
      entireAudioInstanceId={entireAudioInstanceId}
      src={entireAudio?.get('url')}
      ref={clipperRef}
      onRegionUpdate={onRegionChange}
      renderOverlay={() =>
        isSubmitting && <LoadingOverlay title="Submitting..." small />
      }
      captionsControl={
        <AddToVideoButton
          disabled={region.endMillis - region.startMillis < 1000}
          theme="submit"
        />
      }
    />
  );
};

export default ClipSelectAudioClipper;
