import { Spacer } from '@sparemin/blockhead';
import React, { useState } from 'react';

import ChipsList from 'components/Chips/ChipsList';
import { PlusBold } from 'components/icons';
import LinkButton from 'components/LinkButton';
import { MetadataField } from 'components/Metadata/types';
import { Popper, PopperTrigger } from 'components/Popper';
import { block } from './utils';

interface DynamicElementsPopperProps {
  buttonContent: React.ReactNode;
  elementsList: MetadataField[];
  onClick: (value: string) => void;
}

const DynamicElementsPopper: React.FC<DynamicElementsPopperProps> = ({
  buttonContent,
  elementsList,
  onClick,
}) => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const createPopperStateHandler = (newPopperState: boolean) => () => {
    setIsPopperOpen(newPopperState);
  };

  return (
    <Spacer
      orientation="horizontal"
      align="baseline"
      justify="flex-start"
      className={block('container')}
    >
      <LinkButton
        theme="cta"
        className={block('container-button')}
        onClick={createPopperStateHandler(true)}
      >
        <PlusBold height={13} width={13} />
        {buttonContent}
      </LinkButton>

      <PopperTrigger
        open={isPopperOpen}
        onClose={createPopperStateHandler(false)}
      >
        <Popper className={block('container-content')}>
          <ChipsList elementsList={elementsList} onClick={onClick} />
        </Popper>
      </PopperTrigger>
    </Spacer>
  );
};

export default DynamicElementsPopper;
