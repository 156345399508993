import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import CircularGradient, { CircularGradientProps } from './CircularGradient';

export type CircularGradientInfinityProps = Pick<
  CircularGradientProps,
  'className'
>;

const CircularGradientInfinity: React.FC<CircularGradientInfinityProps> = ({
  className,
}) => (
  <CircularGradient className={className}>
    <FontAwesome icon="infinity" style={{ height: '44%', width: '44%' }} />
  </CircularGradient>
);

export default CircularGradientInfinity;
