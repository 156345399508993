import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

const { useRef } = React;

type Props = Pick<
  CSSTransitionProps,
  'in' | 'unmountOnExit' | 'mountOnEnter'
> & {
  className?: string;
};

const FadeTransition: React.FC<Props> = props => {
  const { children, className, ...rest } = props;
  const ref = useRef<HTMLDivElement>(null);
  return (
    <CSSTransition
      {...rest}
      classNames="fade"
      nodeRef={ref}
      addEndListener={done => {
        ref.current.addEventListener('transitionend', done, false);
      }}
    >
      <div {...{ ref, className }}>{children}</div>
    </CSSTransition>
  );
};

export default FadeTransition;
