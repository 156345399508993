import cn from 'classnames';
import * as React from 'react';

import { Col } from 'components/Grid';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  size?: number;
  style?: React.CSSProperties;
}

const ModalBodyCol: React.SFC<IProps> = ({
  children,
  className,
  size,
  style,
}) => (
  <Col
    className={cn('hl-modal__col', className)}
    style={style}
    xs={size}
    divider
  >
    {children}
  </Col>
);

export default ModalBodyCol;
export { IProps as ModalBodyColProps };
