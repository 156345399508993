import { RouteChildrenProps } from 'react-router';
import bem from 'utils/bem';
import { removeQueryParam } from 'utils/routes';

export const block = bem('download-page');

export const AUTOGRAM_REVIEW_PARAM = 'source';
export const AUTOGRAM_REVIEW_VALUE = 'automation-email';

export function isAutogramReview(query: { [k: string]: any }) {
  const { [AUTOGRAM_REVIEW_PARAM]: source } = query;
  return source === AUTOGRAM_REVIEW_VALUE;
}

export function exitAutogramReviewLink(
  props: Pick<RouteChildrenProps, 'location'>,
) {
  return removeQueryParam(props, AUTOGRAM_REVIEW_PARAM);
}

export function reclipSuccessLink(props: RouteChildrenProps) {
  const { wid } = props.match.params as any;
  return `/download/${wid}/reclip-success`;
}
