import { OnboardingName } from 'blocks/OnboardingAnimations/types';
import { DeepPartial, ISoundwave } from 'types';
import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A = undefined>(
  method: M,
  args?: A,
) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export const fetchSampleAudios = (): types.GetSampleAudiosAction => {
  return apiCallAction(types.ServiceMethod.GET_SAMPLE_AUDIOS);
};

export const getViewConfirmation = (
  announcementId: number,
): types.GetViewConfirmationAction => {
  return apiCallAction(types.ServiceMethod.GET_VIEW_CONFIRMATION, [
    announcementId,
  ] as [number]);
};

export const getAnnouncements = (): types.GetAnnouncementsAction => {
  return apiCallAction(types.ServiceMethod.GET_ANNOUNCMENTS);
};

export const getMyDisplayPrefs = (): types.GetMyDisplayPrefAction =>
  apiCallAction(types.ServiceMethod.GET_MY_DISPLAY_PREF);

export const updateMyCaptionsPrefs = (
  transcribe = false,
  language = 'en-US',
): types.PutMyCaptionsPrefAction =>
  apiCallAction(types.ServiceMethod.PUT_MY_CAPTIONS_PREF, [
    transcribe,
    language,
  ]);

export const getUserDisplayPref = (
  userId: types.UserId,
): types.GetUserDisplayPrefAction =>
  apiCallAction(types.ServiceMethod.GET_USER_DISPLAY_PREF, [userId]);

export const getOnboarding = (
  name: OnboardingName,
): types.GetOnboardingAction =>
  apiCallAction(types.ServiceMethod.GET_ONBOARDING, [name] as [OnboardingName]);

export const getOnboardingViewConfirmation = (
  name: OnboardingName,
): types.GetOnboardingViewConfirmationAction =>
  apiCallAction(types.ServiceMethod.GET_ONBOARDING_VIEW_CONFIRMATION, [
    name,
  ] as [OnboardingName]);

export const getProjectTemplates = (): types.GetProjectTemplatesAction =>
  apiCallAction(types.ServiceMethod.GET_PROJECT_TEMPLATES);

export const createProjectTemplate = (
  configurationId: string,
  displayName: string,
  useMockAudioForPreview: boolean,
): types.CreateProjectTemplateAction =>
  apiCallAction(types.ServiceMethod.CREATE_PROJECT_TEMPLATE, [
    configurationId,
    displayName,
    useMockAudioForPreview,
  ] as [string, string, boolean]);

export const overwriteProjectTemplate = (
  configurationId: string,
  templateId: string,
  useMockAudioForPreview: boolean,
): types.OverwriteProjectTemplateAction =>
  apiCallAction(types.ServiceMethod.OVERWRITE_PROJECT_TEMPLATE, [
    configurationId,
    templateId,
    useMockAudioForPreview,
  ] as [string, string, boolean]);

export const deleteProjectTemplate = (
  templateId: string,
): types.DeleteProjectTemplateAction =>
  apiCallAction(types.ServiceMethod.DELETE_PROJECT_TEMPLATE, [templateId] as [
    string,
  ]);

export const getProjectTemplateCompatibilityFromConfigurationId = (
  configurationId: string,
): types.ProjectTemplateCompatibilityFromConfigurationIdResponse =>
  apiCallAction(
    types.ServiceMethod
      .GET_PROJECT_TEMPLATE_COMPATIBILITY_FROM_CONFIGURATION_ID,
    [configurationId] as [string],
  );

export const getUserTemplateCompatibility = (
  templateId: string,
): types.UserTemplateCompatibilityResponse =>
  apiCallAction(types.ServiceMethod.GET_USER_TEMPLATE_COMPATIBILITY, [
    templateId,
  ] as [string]);

function createQuestionResponse(id: string, response: string) {
  return {
    response,
    questionIdentifier: id,
  };
}

// todo role -> sector
export const createQuestionnaireResponse = (
  podcast: string,
  role: string,
  referrer?: string,
): types.CreateQuestionnaireResponseAction =>
  apiCallAction(types.ServiceMethod.CREATE_QUESTIONNAIRE_RESPONSE, [
    createQuestionResponse(types.QuestionId.PODCAST, podcast),
    createQuestionResponse(types.QuestionId.ROLE, role),
    createQuestionResponse(types.QuestionId.REFERRER, referrer),
  ] as types.CreateQuestionnaireResponseArgs);

export const getQuestionnaireResponse = (): types.GetQuestionnaireResponseAction =>
  apiCallAction(types.ServiceMethod.GET_QUESTIONNAIRE_RESPONSE);

export const getQuestionnaireStatus = () =>
  apiCallAction(types.ServiceMethod.GET_QUESTIONNAIRE_STATUS);

export function fetchMyFonts(): types.GetMyFontsAction {
  return apiCallAction(types.ServiceMethod.GET_MY_FONTS);
}

export function fetchPrefVideoSizes(): types.GetPrefVideoSizesAction {
  return apiCallAction(types.ServiceMethod.GET_PREF_VIDEOS_SIZES);
}

export function fetchEddySupportedProjectLanguages(): types.GetEddySupportedProjectLanguagesAction {
  return apiCallAction(
    types.ServiceMethod.GET_EDDY_SUPPORTED_PROJECT_LANGUAGES,
  );
}

export function fetchGoogleFonts(): types.GetGoogleFontsAction {
  return apiCallAction(types.ServiceMethod.GET_GOOGLE_FONTS);
}

export function postPersonalFonts(
  fontFiles?: File[],
  googleFamily?: string,
): types.PostPersonalFontsAction {
  return apiCallAction(types.ServiceMethod.POST_PERSONAL_FONTS, {
    fontFiles,
    googleFamily,
  });
}

export function sendPodcastSupportRequest(
  args: types.SendPodcastSupportArgs,
): types.PodcastSupportAction {
  return apiCallAction(types.ServiceMethod.PODCAST_SUPPORT, args);
}

export function updateFontName(
  displayName: string,
  fontId: string,
): types.UpdateFontNameAction {
  return apiCallAction(types.ServiceMethod.UPDATE_FONT_NAME, {
    displayName,
    fontId,
  });
}

export function deleteFont(fontId: string): types.DeleteFontAction {
  return apiCallAction(types.ServiceMethod.DELETE_FONT, { fontId });
}

export function getMyUserPref(): types.GetMyUserPrefAction {
  return apiCallAction(types.ServiceMethod.GET_MY_USER_PREF);
}

export function createWaveformPref(
  dimensionWidth: number,
  dimensionHeight: number,
  waveformConfig: DeepPartial<ISoundwave>,
): types.CreateWaveformPrefAction {
  return apiCallAction(types.ServiceMethod.CREATE_WAVEFORM_PREF, [
    dimensionWidth,
    dimensionHeight,
    waveformConfig,
  ]);
}

export function deleteWaveformPref(
  waveformPrefId: number,
): types.DeleteWaveformPrefAction {
  return apiCallAction(types.ServiceMethod.DELETE_WAVEFORM_PREF, [
    waveformPrefId,
  ]);
}

export function getExperimentVariant(
  experimentNames: string[],
): types.GetExperimentVariantAction {
  return apiCallAction(types.ServiceMethod.GET_EXPERIMENT_VARIANT, [
    experimentNames,
  ]);
}

export function updateColorPref(
  colorPrefs: string[],
): types.UpdateColorPrefAction {
  return apiCallAction(types.ServiceMethod.UPDATE_COLOR_PREF, [colorPrefs]);
}

export function updateUserVideoExportPrefsArgs(
  args: types.VideoExportPrefsArgs,
): types.UpdateVideoExportPrefAction {
  return apiCallAction(types.ServiceMethod.UPDATE_VIDEO_EXPORT_PREF, args);
}

export function getTextPresets(): types.GetTextPresetsAction {
  return apiCallAction(types.ServiceMethod.GET_TEXT_PRESETS, undefined);
}
