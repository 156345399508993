import * as React from 'react';
import uncontrollable from 'uncontrollable';
import { noop } from 'underscore';

import AlignCenterToggle from './AlignCenterToggle';
import AlignLeftToggle from './AlignLeftToggle';
import AlignRightToggle from './AlignRightToggle';
import { block } from './utils';

export type Alignment = 'left' | 'center' | 'right';

interface IProps {
  disabled?: boolean;
  onChange?: (value: Alignment) => void;
  value?: Alignment;
}

export class TextAlignSelector extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    disabled: false,
    onChange: noop,
  };

  private handleToggle = (alignment: Alignment) => () => {
    const { onChange } = this.props;
    onChange(alignment);
  };

  public render() {
    const { disabled, value } = this.props;

    return (
      <div className={block('text-align')}>
        <AlignLeftToggle
          active={value === 'left'}
          disabled={disabled}
          onToggle={this.handleToggle('left')}
        />
        <AlignCenterToggle
          active={value === 'center'}
          disabled={disabled}
          onToggle={this.handleToggle('center')}
        />
        <AlignRightToggle
          active={value === 'right'}
          disabled={disabled}
          onToggle={this.handleToggle('right')}
        />
      </div>
    );
  }
}

export type TextAlignSelectorProps = IProps & {
  defaultValue?: IProps['value'];
};

const uncontrolled: React.ComponentType<TextAlignSelectorProps> = uncontrollable(
  TextAlignSelector,
  {
    value: 'onChange',
  },
);

export default uncontrolled;
