import cn from 'classnames';
import dayjs from 'dayjs';
import * as React from 'react';
import { formatDurationMillis } from 'utils/time';

import { episodeExplorerBlock as block } from '../utils';

const { useMemo } = React;

export interface PodcastEpisodeProps {
  className?: string;
  durationMillis: number;
  publishedAtMillis: number;
  title: string;
}

const PodcastEpisode: React.SFC<PodcastEpisodeProps> = ({
  className,
  durationMillis,
  publishedAtMillis,
  title,
}) => {
  const publishedAtString = useMemo(
    () => dayjs(publishedAtMillis).format('MMM D, YYYY'),
    [publishedAtMillis],
  );

  const durationString = useMemo(
    () =>
      formatDurationMillis(durationMillis, {
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
      }),
    [durationMillis],
  );

  return (
    <div className={cn(block('episode'), className)}>
      <span className={block('episode-title')}>{title}</span>
      <span className={block('episode-date')}>{publishedAtString}</span>
      <span className={block('episode-duration')}>{durationString}</span>
    </div>
  );
};

export default PodcastEpisode;
