import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { PropsOf } from 'types';
import { block } from './utils';

interface IProps extends PropsOf<'button'> {
  domRef?: (el: HTMLButtonElement) => void;
}

const ToolbarButton: React.SFC<IProps> = ({
  className,
  children,
  domRef,
  ...buttonProps
}) => (
  <button
    className={cn(block('button'), className)}
    ref={domRef}
    type="button"
    {...buttonProps}
  >
    {children}
  </button>
);

ToolbarButton.defaultProps = {
  domRef: noop,
};

export default ToolbarButton;
export { IProps as ToolbarButtonProps };
