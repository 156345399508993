import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import LoadingMessage from 'components/LoadingMessage';
import { getFavoritePodcasts } from 'redux/modules/podcast-search/actions';
import { favoritePodcastIdsSelector } from 'redux/modules/podcast-search/selectors';
import bem from 'utils/bem';
import AutomationCMSRich, {
  AutomationCMSRichProps,
} from '../AutomationCMSRich';

type PickedRichProps = Pick<AutomationCMSRichProps, 'getScrollParent'>;

export interface AutomationCMSProps extends PickedRichProps {}

const block = bem('automation-cms');

const AutomationCMS: React.FC<AutomationCMSProps> = ({ getScrollParent }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFavoritePodcasts());
  }, [dispatch]);
  const podcastIds = useSelector(favoritePodcastIdsSelector);

  if (!podcastIds) {
    return <LoadingMessage className={block('loading')} />;
  }

  return <AutomationCMSRich getScrollParent={getScrollParent} />;
};

export default AutomationCMS;
