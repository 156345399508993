import * as React from 'react';

import { IconProps } from './types';

const Emoji: React.SFC<IconProps> = props => {
  const { fill = 'url(#emoji-gradient)' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M20 0C8.952 0 0 8.952 0 20s8.952 20 20 20 20-8.952 20-20S31.048 0 20 0zm0 36.129c-8.895 0-16.129-7.234-16.129-16.129 0-8.895 7.234-16.129 16.129-16.129 8.895 0 16.129 7.234 16.129 16.129 0 8.895-7.234 16.129-16.129 16.129zm-6.452-17.42a2.578 2.578 0 0 0 2.581-2.58 2.578 2.578 0 0 0-2.58-2.58 2.578 2.578 0 0 0-2.581 2.58 2.578 2.578 0 0 0 2.58 2.58zm12.904 0a2.578 2.578 0 0 0 2.58-2.58 2.578 2.578 0 0 0-2.58-2.58 2.578 2.578 0 0 0-2.581 2.58 2.578 2.578 0 0 0 2.58 2.58zm.322 5.856A8.801 8.801 0 0 1 20 27.742a8.775 8.775 0 0 1-6.774-3.177 1.941 1.941 0 0 0-2.726-.25 1.94 1.94 0 0 0-.25 2.725A12.66 12.66 0 0 0 20 31.605a12.66 12.66 0 0 0 9.75-4.565 1.933 1.933 0 0 0-.25-2.725 1.941 1.941 0 0 0-2.726.25z"
      />
    </svg>
  );
};

export default Emoji;
