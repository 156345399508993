export const LOGIN_REQUEST = 'app/auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'app/auth/LOGIN_FAILURE';

export const AUTHENTICATE_SUCCESS = 'app/auth/AUTHENTICATE_SUCCESS';

export const AUTHENTICATE_SOCIAL_REQUEST =
  'app/auth/AUTHENTICATE_SOCIAL_REQUEST';
export const AUTHENTICATE_SOCIAL_SUCCESS =
  'app/auth/AUTHENTICATE_SOCIAL_SUCCESS';
export const AUTHENTICATE_SOCIAL_FAILURE =
  'app/auth/AUTHENTICATE_SOCIAL_FAILURE';

export const USER_LOGOUT = 'app/auth/USER_LOGOUT';

export const RENEW_SUCCESS = 'app/auth/RENEW_SUCCESS';
export const RENEW_FAILURE = 'app/auth/RENEW_FAILURE';

export const REGISTER_REQUEST = 'app/auth/REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'app/auth/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'app/auth/REGISTER_FAILURE';

export const ACCOUNT_CREATE_SUCCESS = 'app/auth/ACCOUNT_CREATE_SUCCESS';

export const FORGOT_PASSWORD_REQUEST = 'app/auth/FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'app/auth/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'app/auth/FORGOT_PASSWORD_FAILURE';

export const PASSWORD_STATUS_CLEAR = 'app/auth/PASSWORD_STATUS_CLEAR';

export const PASSWORD_UPDATE_REQUEST = 'app/auth/PASSWORD_UPDATE_REQUEST';
export const PASSWORD_UPDATE_SUCCESS = 'app/auth/PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_FAILURE = 'app/auth/PASSWORD_UPDATE_FAILURE';

export const EMAIL_UPDATE_REQUEST = 'app/auth/EMAIL_UPDATE_REQUEST';
export const EMAIL_UPDATE_SUCCESS = 'app/auth/EMAIL_UPDATE_SUCCESS';
export const EMAIL_UPDATE_FAILURE = 'app/auth/EMAIL_UPDATE_FAILURE';

export const CONFIRM_EMAIL_REQUEST = 'app/auth/CONFIRM_EMAIL_REQUEST';
export const CONFIRM_EMAIL_SUCCESS = 'app/auth/CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_FAILURE = 'app/auth/CONFIRM_EMAIL_FAILURE';

export const RESEND_VERIFICATION_EMAIL_REQUEST =
  'app/auth/RESEND_VERIFICATION_EMAIL_REQUEST';
export const RESEND_VERIFICATION_EMAIL_SUCCESS =
  'app/auth/RESEND_VERIFICATION_EMAIL_SUCCESS';
export const RESEND_VERIFICATION_EMAIL_FAILURE =
  'app/auth/RESEND_VERIFICATION_EMAIL_FAILURE';

export const AUTH_GET_INTEGRATORS_REQUEST =
  'app/auth/AUTH_GET_INTEGRATORS_REQUEST';
export const AUTH_GET_INTEGRATORS_SUCCESS =
  'app/auth/AUTH_GET_INTEGRATORS_SUCCESS';
export const AUTH_GET_INTEGRATORS_FAILURE =
  'app/auth/AUTH_GET_INTEGRATORS_FAILURE';

export const MY_PROFILE_GET_SUCCESS = 'app/auth/MY_PROFILE_GET_SUCCESS';

export const USER_DELETE_REQUEST = 'app/auth/USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'app/auth/USER_DELETE_SUCCESS';
export const USER_DELETE_FAILURE = 'app/auth/USER_DELETE_FAILURE';
