import React from 'react';

import Image from 'components/Image';
import { Slide } from 'components/VideoTemplateEditor/types';
import { viewportToPct } from 'utils/placement';

import Layer from '../../layers/Layer';
import { editorModalPreviewBlock as block } from '../../utils';

interface EditorModalSlideshowLayerProps {
  slideshow: Slide;
}

const EditorModalSlideshowLayer: React.FunctionComponent<EditorModalSlideshowLayerProps> = props => {
  const { slideshow } = props;

  const placement = viewportToPct(slideshow.placement);
  return (
    <Layer>
      <Image
        key={slideshow.id}
        className={block('image')}
        src={slideshow.imageSrc}
        style={{
          ...placement,
        }}
      />
    </Layer>
  );
};

export default EditorModalSlideshowLayer;
