import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A = undefined>(
  method: M,
  args?: A,
) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export function getAnimationConfig(
  name: string,
  mainImageUrl?: string,
): types.GetAnimationConfigAction {
  return apiCallAction(types.ServiceMethod.GET_ANIMATION_CONFIG, [
    name,
    mainImageUrl,
  ]);
}
