import { Identifiable, SocialSharePlatform } from 'types';
import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'VIDEO_EXPORT_SERVICE';
export const ACTION_KEY: ActionKey = 'VIDEO_EXPORT_SERVICE';

export enum ServiceMethod {
  QUEUE_VIDEO_CREATE = 'QUEUE_VIDEO_CREATE',
  GET_VIDEO_CREATE_STATUS = 'GET_VIDEO_CREATE_STATUS',
  SOCIAL_SHARE_CREATE = 'SOCIAL_SHARE_CREATE',
  GET_EMBED_VIDEOS = 'GET_EMBED_VIDEOS',
  GET_SOCIAL_SHARE = 'GET_SOCIAL_SHARE',
}

export interface IQueueVideoCreate {
  emailsToNotify: string[];
  frameHeight: number;
  frameQuality: number;
  frameRate: number;
  frameWidth: number;
  headlinerWatermarkEnabled: boolean;
  introVideo?: File | string;
  outroVideo?: File | string;
  referrerProjectId: string;
  widgetId: string;
}

interface IProgressInfo {
  progress: number;
  renderedFrameCount?: number;
  status?: string;
  totalFrameCount?: number;
}

interface IPodcastInfo {
  episode?: {
    playAppUrl: string | null;
    url: string | null;
  };
  podcast?: {
    title: string | null;
  };
  tags?: string[];
}

export interface IEmbedExport {
  id: number;
  durationMillis: number;
  podcastInfo: IPodcastInfo;
  isResolved: boolean;
  progressInfo: IProgressInfo;
  referrerProjectId: string;
  status: string;
  videoUrl?: string;
  widgetId: string;
  queuerUserId?: number;
  isPodcastAutomation: boolean;
}

export type ShareStatus =
  | 'queued'
  | 'processing'
  | 'uploaded'
  | 'completed'
  | 'errorAck'
  | 'error';

export interface SocialPost {
  embedVideoId: number;
  platform: SocialSharePlatform;
  sharePostUrl: string;
  shareStatus: ShareStatus;
  videoShareType: 'manual' | 'autoPost';
  userId: number;
}

export type QueueVideoCreateArgs = IQueueVideoCreate;
export interface QueueVideoCreateResult {
  id: number;
}

export type GetCreateStatusArgs = [string];

export type GetCreateStatusResult = {
  result: string;
  entities: {
    embedExports: {
      [id: string]: IEmbedExport;
    };
  };
};

export type CreateSocialShareArgs = Pick<
  SocialPost,
  'embedVideoId' | 'platform' | 'sharePostUrl'
>;
export type CreateSocialShareResult = void;

export type GetSocialShareArgs = [number /* social share id */];
export interface GetSocialShareResult {
  result: string;
  entities: {
    socialShares: {
      [id: number]: SocialPost & Identifiable<number>;
    };
  };
}

export type GetEmbedVideosArgs = [number];
export type GetEmbedVideosResult = {
  result: string[];
  entities: {
    embedExports: {
      [id: string]: IEmbedExport;
    };
  };
};

export type ServiceResults =
  | QueueVideoCreateResult
  | GetCreateStatusResult
  | CreateSocialShareResult
  | GetEmbedVideosResult
  | GetSocialShareResult;

export type IHandle = (
  method: ServiceMethod,
  args: any,
  token?: string,
) => Promise<ServiceResults>;

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export type QueueVideoCreateAction = ApiAction<
  ServiceMethod.QUEUE_VIDEO_CREATE,
  QueueVideoCreateArgs
>;

export type GetCreateStatusAction = ApiAction<
  ServiceMethod.GET_VIDEO_CREATE_STATUS,
  GetCreateStatusArgs
>;

export type CreateSocialShareAction = ApiAction<
  ServiceMethod.SOCIAL_SHARE_CREATE,
  CreateSocialShareArgs
>;

export type GetSocialShareAction = ApiAction<
  ServiceMethod.GET_SOCIAL_SHARE,
  GetSocialShareArgs
>;

export type GetEmbedVideosAction = ApiAction<
  ServiceMethod.GET_EMBED_VIDEOS,
  GetEmbedVideosArgs
>;

export interface VideoExportServiceDispatch {
  (action: QueueVideoCreateAction): Promise<
    IApiResponse<QueueVideoCreateResult>
  >;
  (action: GetCreateStatusAction): Promise<IApiResponse<GetCreateStatusResult>>;
  (action: CreateSocialShareAction): Promise<
    IApiResponse<CreateSocialShareResult>
  >;
  (action: GetSocialShareAction): Promise<IApiResponse<GetSocialShareResult>>;
  (action: GetEmbedVideosAction): Promise<IApiResponse<GetEmbedVideosResult>>;
}
