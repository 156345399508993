import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import WatermarkUpsellModalContent from './WatermarkUpsellModalContent';

const FileSizeUpsellModal = () => (
  <ConnectedModal name="WatermarkUpsell" title="add watermarks">
    <WatermarkUpsellModalContent />
  </ConnectedModal>
);

export default FileSizeUpsellModal;
