import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TextOverlayModal, {
  isLegacyOverlayConvertAble,
  TextOverlayFooterExternalControls,
  TextOverlayV2,
  updateOverlayToV2,
  validateOverlayV2Integrity,
} from 'blocks/TextOverlayModal/v2';
import { EditorVideoFramePreview } from 'containers/VideoFramePreview';
import {
  clearSelectedTrackElement,
  duplicateTextOverlayToTrack,
  removeTextOverlay,
  saveTextOverlay,
} from 'redux/modules/embed/actions';
import {
  aspectRatioSelector,
  selectedOverlaySelector,
} from 'redux/modules/embed/selectors';
import { EmbedTextOverlay } from 'redux/modules/embed/types';

import { block } from './utils';

interface EditTextModalProps {
  onExited: () => void;
  onHide: () => void;
  onSwitchToLegacy: (partialOverlay?: TextOverlayV2) => void;
  selectedTrackId?: string;
  show: boolean;
  transitionOverlay: TextOverlayV2;
}

const EditTextModal: React.FunctionComponent<EditTextModalProps> = props => {
  const {
    onExited,
    onHide,
    onSwitchToLegacy,
    selectedTrackId,
    show,
    transitionOverlay,
  } = props;

  const [prevShow, setPrevShow] = React.useState(show);
  const [currOverlay, setCurrOverlay] = React.useState<
    EmbedTextOverlay | undefined
  >(undefined);

  const dispatch = useDispatch();

  const textOverlay = useSelector(selectedOverlaySelector);
  const aspectRatio = useSelector(aspectRatioSelector);

  // this prevents the overlay that is selected from producing a small blink at
  // the background when the modal is hidden. This happens because the background
  // loses the reference to the selected overlay when show changes to false.
  // In combination with handleExited, it keeps the temporal selection and it is
  // cleared once it has fully exited.
  if (prevShow !== show) {
    setPrevShow(show);
    if (show) {
      setCurrOverlay(textOverlay);
    }
  }

  const overlayId = currOverlay?.get('id');
  const startMillis = currOverlay?.getIn(['time', 'startMillis']);
  const endMillis = currOverlay?.getIn(['time', 'endMillis']);

  const handleDelete = React.useCallback(() => {
    dispatch(removeTextOverlay(overlayId));
    onHide();
  }, [dispatch, onHide, overlayId]);

  const handleExited = React.useCallback(() => {
    setCurrOverlay(undefined);
    onExited();
  }, [onExited]);

  const handleDuplicate = React.useCallback(
    (duplicatedOverlayV2: TextOverlayV2): void => {
      dispatch(
        duplicateTextOverlayToTrack(duplicatedOverlayV2, selectedTrackId),
      );
    },
    [dispatch, selectedTrackId],
  );

  const handleSubmit = React.useCallback(
    (updatedOverlay: TextOverlayV2): void => {
      dispatch(saveTextOverlay(updatedOverlay));
      dispatch(clearSelectedTrackElement());
    },
    [dispatch],
  );

  const convertedOverlay = React.useMemo(() => {
    if (!show) {
      return undefined;
    }

    if (transitionOverlay) {
      return transitionOverlay;
    }

    if (validateOverlayV2Integrity(textOverlay)) {
      return textOverlay;
    }

    return isLegacyOverlayConvertAble(textOverlay)
      ? updateOverlayToV2(textOverlay)
      : undefined;
  }, [textOverlay, show, transitionOverlay]);

  return (
    <TextOverlayModal
      aspectRatio={aspectRatio}
      background={({ viewport }) => (
        <EditorVideoFramePreview
          // This preview component does not re render when the viewport
          // size is changed by a browser viewport size change.
          // This is not an event likely to happen in practise, but it is
          // good to support it.
          key={`${viewport?.height}-${viewport?.width}-${overlayId}`}
          aspectRatio={aspectRatio}
          canvasDimensions={viewport}
          className={block('video-frame-preview')}
          backgroundFor={{
            id: overlayId,
            type: 'textOverlayInfo',
          }}
        />
      )}
      editable
      extensions={{
        overlayTiming: {
          enabled: true,
          startMillis,
          endMillis,
        },
      }}
      footerLeftActions={
        <>
          <TextOverlayFooterExternalControls.DeleteOverlayButton
            onDelete={handleDelete}
          />
          <TextOverlayFooterExternalControls.DuplicateOverlayButton
            onHide={onHide}
            onSubmit={handleDuplicate}
          />
        </>
      }
      isCompatibilityMode
      onExited={handleExited}
      onHide={onHide}
      onSwitchToLegacy={onSwitchToLegacy}
      onSubmit={handleSubmit}
      showPresetSelector={false}
      submitButtonText="Save changes"
      shouldDisableSubmitIfEmpty
      showEmoji
      show={show}
      textOverlay={convertedOverlay}
      title="Add text"
    />
  );
};

export default EditTextModal;
