import cn from 'classnames';
import React from 'react';
import Button, { ButtonTheme } from 'components/Button';
import { block } from './utils';

export interface TierSelectionAltCtaProps {
  children?: React.ReactNode;
  className?: string;
  theme: Extract<ButtonTheme, 'confirm-alt' | 'submit-alt'>;
}

const TierSelectionAltCta: React.FC<TierSelectionAltCtaProps> = ({
  children,
  className,
  theme,
}) => (
  <Button className={cn(block('cta'), className)} disabled theme={theme}>
    {children}
  </Button>
);

export default TierSelectionAltCta;
