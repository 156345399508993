import { EditorDataV2, Size } from '../types';
import { AnimationsTab, useAnimationsTab } from './AnimationsTab';
import { BoxSizeFitChecker, useBoxSizeFitChecker } from './BoxSizeFitChecker';
import { OverlayTiming, useOverlayTiming } from './OverlayTiming';
import { TextOverlayModalExtensions } from './types';

interface UseTextOverlayExtensionsConfig {
  context: {
    baseTextValue: string;
    canvasSize: Size;
    draftEditorData: EditorDataV2;
    initialEditorData: EditorDataV2;
  };
  extensions: TextOverlayModalExtensions;
}

interface UseTextOverlayExtensions {
  animationsTabExtension?: AnimationsTab;
  boxSizeFitCheckerExtension?: BoxSizeFitChecker;
  overlayTimingExtension: OverlayTiming;
}

const useTextOverlayExtensions = (
  config: UseTextOverlayExtensionsConfig,
): UseTextOverlayExtensions => {
  const { context, extensions } = config;

  const {
    baseTextValue,
    canvasSize,
    draftEditorData,
    initialEditorData,
  } = context;

  const animationsTabExtension = useAnimationsTab({
    canvasSize,
    draftEditorData,
    extensionConfig: extensions?.animationsTab,
  });

  const boxSizeFitCheckerExtension = useBoxSizeFitChecker({
    baseTextValue,
    canvasSize,
    draftEditorData,
    initialEditorData,
    extensionConfig: extensions?.boxSizeFitChecker,
  });

  const overlayTimingExtension = useOverlayTiming({
    extensionConfig: extensions?.overlayTiming,
  });

  return {
    animationsTabExtension,
    boxSizeFitCheckerExtension,
    overlayTimingExtension,
  };
};

export default useTextOverlayExtensions;
