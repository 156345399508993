import classNames from 'classnames';
import * as React from 'react';
import { Col, Nav, NavItem, Row, Tab } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ScrollBars from './ScrollBars';

interface IBsSize {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
}

interface IBaseNavItem {
  key: string;
  label: React.ReactNode;
}

interface ITabNavItem extends IBaseNavItem {
  content: React.ReactNode;
}

interface IHrefNavItem extends IBaseNavItem {
  href: string;
}

interface IProps {
  animation?: boolean;
  children?: React.ReactNode;
  className?: string;
  contentBsSize?: IBsSize;
  contentColClassName?: string;
  contentClassName?: string;
  defaultActiveKey?: string;
  id: string;
  navItems?: ITabNavItem[] | IHrefNavItem[];
  paneClassName?: string;
  sidebarBsSize?: IBsSize;
  title?: string;
}

function isTabbedNav(
  ary: ITabNavItem[] | IHrefNavItem[],
): ary is ITabNavItem[] {
  return !(ary as IHrefNavItem[]).some(({ href }) => href !== undefined);
}

function isHrefNav(ary: ITabNavItem[] | IHrefNavItem[]): ary is IHrefNavItem[] {
  return !isTabbedNav(ary);
}

function isHrefNavItem(obj: IBaseNavItem): obj is IHrefNavItem {
  return (obj as IHrefNavItem).href !== undefined;
}

function renderNavItem(item: IHrefNavItem | ITabNavItem) {
  const navItemProps = {
    className: 'sidebar-nav__label',
    eventKey: item.key,
  };

  if (isHrefNavItem(item)) {
    return (
      <LinkContainer key={item.key} to={{ pathname: item.href }} replace>
        <NavItem {...navItemProps}>{item.label}</NavItem>
      </LinkContainer>
    );
  }

  return (
    <NavItem {...navItemProps} key={item.key}>
      {item.label}
    </NavItem>
  );
}

/**
 * can be used as both a nav or tab component.
 *
 * when used as a nav, each nav item should be passed along with an href that will load when
 * the user clicks on that item.
 *
 * when used as a tab, each nav item should be passed with a content component that will render
 * when the user clicks on that nav item
 */

const SidebarNav: React.SFC<IProps> = ({
  animation,
  children,
  className,
  contentBsSize,
  contentColClassName,
  contentClassName,
  defaultActiveKey,
  id,
  navItems,
  paneClassName,
  sidebarBsSize,
  title,
}) => {
  const containerClassName = classNames(
    'sidebar-nav',
    'sidebar-nav--default',
    className,
  );
  const tabPaneClassName = classNames('sidebar-nav__pane', paneClassName);
  const tabContentClassName = classNames(
    'sidebar-nav__content',
    contentClassName,
  );

  // tabs have to be wrapped in Tab.Container.  wrapping a nav in Tab.Container will break it
  const wrapTab = (val: React.ReactNode): React.ReactElement<any> => (
    <Tab.Container defaultActiveKey={defaultActiveKey}>{val}</Tab.Container>
  );

  // only wrap tab style nav in Tab.Container
  const wrap = (val: React.ReactElement<any>): React.ReactElement<any> =>
    isHrefNav(navItems) ? val : wrapTab(val);

  return wrap(
    <Row id={id} className={containerClassName}>
      <Col className="sidebar-nav__nav" {...sidebarBsSize}>
        {title && <div className="sidebar-nav__title">{title}</div>}
        <Nav className="sidebar-nav__tablist" bsStyle="pills" stacked>
          {/* something definitely up with the typedef for array union types.  this is messy */}
          {(navItems as IBaseNavItem[]).map((val: IHrefNavItem | ITabNavItem) =>
            renderNavItem(val),
          )}
        </Nav>
      </Col>
      <Col
        className={classNames('sidebar-nav__content-col', contentColClassName)}
        {...contentBsSize}
      >
        <ScrollBars>
          {navItems.length > 0 && isTabbedNav(navItems) && (
            <Tab.Content className={tabContentClassName} animation={animation}>
              {navItems.map(({ key, content }) => (
                <Tab.Pane className={tabPaneClassName} eventKey={key} key={key}>
                  {content}
                </Tab.Pane>
              ))}
            </Tab.Content>
          )}
          {navItems.length > 0 && isHrefNav(navItems) && (
            <div className={tabPaneClassName}>{children}</div>
          )}
        </ScrollBars>
      </Col>
    </Row>,
  );
};

SidebarNav.defaultProps = {
  animation: false,
  contentBsSize: {
    sm: 10,
  },
  navItems: [],
  sidebarBsSize: {
    sm: 2,
  },
};

export default SidebarNav;
