import React from 'react';
import VideoTypeIcon from 'blocks/AudiogramDestinations/components/VideoTypes/VideoTypeIcon';

import { AudiogramDestinationConfig } from 'blocks/AudiogramDestinations/types';
import { DestinationSteps } from 'blocks/AutomationOptions/types';
import { TiktokLogo } from 'components/icons';
import { TikTokSocialCircle } from 'components/SocialCircle';
import { VideoTypes } from 'types';

import { getAudiogramWizardPath } from 'utils/routes';
import { millisToSec } from 'utils/time';
import { DESTINATION_PRESETS } from '../../destinationPresets/utils';

const tiktok: AudiogramDestinationConfig = {
  id: 'tiktok',
  icon: <TikTokSocialCircle />,
  videoTypes: [
    {
      description: 'Share a short clip to go viral',
      destination: getAudiogramWizardPath({
        defaultClipDurationMillis: DESTINATION_PRESETS.tiktok.post.durationMs,
        defaultAspectRatio: DESTINATION_PRESETS.tiktok.post.aspectRatio,
        defaultPresetKey: DESTINATION_PRESETS.tiktok.post.key,
      }),
      id: 'post',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.tiktok.post.aspectRatio}
          background="#000"
        >
          <TiktokLogo width="13px" />
        </VideoTypeIcon>
      ),
      title: 'TikTok Post',
      autogramWizardSettings: {
        destination: DestinationSteps.AUTO_POSTING,
        videoType: VideoTypes.RANDOM_CLIP,
        clipDuration: millisToSec(DESTINATION_PRESETS.tiktok.post.durationMs),
        aspectRatioName: DESTINATION_PRESETS.tiktok.post.aspectRatio,
      },
    },
    {
      description: 'Share an ephemeral clip for 24 hours',
      destination: getAudiogramWizardPath({
        defaultClipDurationMillis: DESTINATION_PRESETS.tiktok.story.durationMs,
        defaultAspectRatio: DESTINATION_PRESETS.tiktok.story.aspectRatio,
        defaultPresetKey: DESTINATION_PRESETS.tiktok.story.key,
      }),
      id: 'story',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.tiktok.story.aspectRatio}
          background="#000"
        >
          <TiktokLogo width="13px" />
        </VideoTypeIcon>
      ),
      title: 'TikTok Story',
      autogramWizardSettings: {
        destination: DestinationSteps.AUTO_POSTING,
        videoType: VideoTypes.RANDOM_CLIP,
        clipDuration: millisToSec(DESTINATION_PRESETS.tiktok.story.durationMs),
        aspectRatioName: DESTINATION_PRESETS.tiktok.story.aspectRatio,
      },
    },
  ],
};

export default tiktok;
