import React, { useContext } from 'react';

import { ClipSelectNavigationContextType } from 'blocks/ClipSelect/types';

const ClipSelectNavigationContext = React.createContext<
  ClipSelectNavigationContextType | undefined
>(undefined);

interface ClipSelectNavigationProviderProps {
  children?: React.ReactNode;
  value: ClipSelectNavigationContextType;
}

export const ClipSelectNavigationProvider: React.FC<ClipSelectNavigationProviderProps> = ({
  children,
  value,
}) => (
  <ClipSelectNavigationContext.Provider value={value}>
    {children}
  </ClipSelectNavigationContext.Provider>
);

export function useClipSelectNavigation() {
  const context = useContext(ClipSelectNavigationContext);

  if (context === undefined) {
    throw new Error(
      'useClipSelectNavigation must be used within ClipSelectNavigationProvider',
    );
  }

  return context;
}
