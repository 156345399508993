import * as React from 'react';
import Spinner from 'react-spinkit';

const VideoExportNotificationSpinner: React.SFC = () => (
  <Spinner
    className="export-alert__spinner spinner--blue spinner--wave spinner--large"
    spinnerName="wave"
    noFadeIn
  />
);

export default VideoExportNotificationSpinner;
