import * as React from 'react';

import { secToPosition } from '../../utils';
import { formatTime, getTicks, getTickScale } from './utils';

const { useCallback, useEffect, useRef } = React;

export interface RulerCanvasProps {
  endSec: number;
  height?: number;
  pxPerSec: number;
  startSec: number;
  width?: number;
}

const TICK_HEIGHT_PX = 7;
const TICK_WIDTH_PX = 4;

export const RulerCanvas: React.SFC<RulerCanvasProps> = ({
  endSec,
  height,
  pxPerSec,
  startSec,
  width,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const drawTicks = useCallback(() => {
    const { majorSec, stepSec } = getTickScale(pxPerSec);
    const ticks = getTicks(startSec, endSec, stepSec);

    const ctx = canvasRef.current.getContext('2d');
    const leftEdge = secToPosition(startSec, pxPerSec);

    // clear the current canvas
    // ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    // set the font for the tick mark labels
    ctx.font = '500 9px Roboto, sans-serif';

    ticks.forEach(tickSec => {
      const position = secToPosition(tickSec, pxPerSec) - leftEdge;
      const isMajor = tickSec % majorSec === 0;

      // save the position of the context so we can restore it after translate
      ctx.save();

      // move the canvas to the next tick
      // ctx.translate(position, this.canvas.height - TICK_HEIGHT_PX);
      ctx.translate(position, canvasRef.current.height - TICK_HEIGHT_PX);

      // set the correct color (major is different) and render the tick
      ctx.fillStyle = isMajor ? '#bec8d2' : 'rgba(190, 200, 210, 0.3)';
      ctx.fillRect(0, 0, TICK_WIDTH_PX, TICK_HEIGHT_PX);

      // if major render the label
      if (isMajor) {
        const label = formatTime(tickSec);
        ctx.translate(TICK_WIDTH_PX / 2, -8);
        ctx.fillStyle = '#bec8d2';
        ctx.textAlign = 'center';
        ctx.fillText(label, 0, 0);
      }

      // move the canvas back to where it started
      ctx.restore();
    });
  }, [startSec, endSec, pxPerSec]);

  useEffect(() => {
    window.requestAnimationFrame(drawTicks);
  }, [drawTicks, height, width]);

  return (
    <canvas
      width={width}
      height={height}
      style={{ letterSpacing: 1.2, marginBottom: 2 }}
      ref={canvasRef}
    />
  );
};

RulerCanvas.displayName = 'RulerCanvas';

export default RulerCanvas;
