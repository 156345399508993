import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancelImageToVideoCreation } from 'redux/modules/image-to-video';

import {
  isPollingImageToVideoSelector,
  shouldCancelPollingSelector,
} from 'redux/modules/image-to-video/selectors';
import { isPollingTextToImageSelector } from 'redux/modules/text-to-image/selectors';
import { MediaType } from 'redux/modules/text-to-image/types';
import { AspectRatioName } from 'types';

import AIMediaLoader from './AIMediaLoader';

interface AIMediaLoaderContainerProps {
  aspectRatio: AspectRatioName;
  sourceImageUrl?: string;
  targerMediaType: MediaType;
}

const AIMediaLoaderContainer: React.FunctionComponent<AIMediaLoaderContainerProps> = props => {
  const { aspectRatio, sourceImageUrl, targerMediaType } = props;

  const dispatch = useDispatch();

  const isGeneratingImageToVideo = useSelector(isPollingImageToVideoSelector);
  const isGeneratingTextToImage = useSelector(isPollingTextToImageSelector);
  const isCancelling = useSelector(shouldCancelPollingSelector);

  const handleCancelGeneration = React.useCallback(() => {
    dispatch(cancelImageToVideoCreation());
  }, [dispatch]);

  return (
    <AIMediaLoader
      aspectRatio={aspectRatio}
      isCancelling={isCancelling}
      isGeneratingImageToVideo={isGeneratingImageToVideo}
      isGeneratingTextToImage={isGeneratingTextToImage[targerMediaType]}
      onCancelGeneration={handleCancelGeneration}
      sourceImageUrl={sourceImageUrl}
    />
  );
};

export default AIMediaLoaderContainer;
