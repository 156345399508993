import React from 'react';

import PodcastSearch from 'blocks/PodcastSearch';
import useDefaultIntegration from 'blocks/PodcastSearch/useDefaultIntegration';
import useRssIntegration from 'blocks/PodcastSearch/useRssIntegration';

import Modal from 'components/Modal';
import { RequestStatus } from 'types';

import ClaimPodcastLoadingOverlay from './components/ClaimPodcastLoadingOverlay';
import { block } from './utils';

interface ClaimPodcastModalContentsProps {
  onSelectPodcast: (podcastId: string) => void;
  requestStatus?: RequestStatus | null;
}

const PODCAST_SEARCH_HIDDEN_STATES = ['request', 'success'];

const ClaimPodcastModalContents: React.FunctionComponent<ClaimPodcastModalContentsProps> = props => {
  const { onSelectPodcast, requestStatus } = props;

  const searchIntegrations = [
    useDefaultIntegration({
      episodeExplorerEnabled: false,
      onPodcastClick: onSelectPodcast,
      priority: 1,
    }),
    useRssIntegration({
      episodeExplorerEnabled: false,
      onPodcastClick: onSelectPodcast,
      priority: 0,
    }),
  ];

  const SearchComponent = !PODCAST_SEARCH_HIDDEN_STATES.includes(
    requestStatus,
  ) ? (
    <PodcastSearch
      className={block('podcast-search')}
      integrations={searchIntegrations}
    />
  ) : null;

  return (
    <>
      <Modal.Body className={block('body')}>{SearchComponent}</Modal.Body>
      <ClaimPodcastLoadingOverlay
        loading={requestStatus === RequestStatus.REQUEST}
      />
    </>
  );
};

export default ClaimPodcastModalContents;
