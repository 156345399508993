import * as React from 'react';

import { SelectField, SelectFieldProps } from 'components/Form';
import { languages } from 'constants/languages';

interface Props extends SelectFieldProps {
  label?: string;
}

const LanguageSelector: React.SFC<Props> = ({ label, ...props }) => (
  <SelectField label={label} {...props}>
    {languages.map(language => (
      <option value={language.value}>{language.label}</option>
    ))}
  </SelectField>
);

LanguageSelector.defaultProps = {
  label: 'Transcription Language',
  value: 'en-US',
};

export default LanguageSelector;

export { Props as LanguageSelectorProps };
