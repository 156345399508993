import { fromJS, Iterable, Map } from 'immutable';
import { isUndefined } from 'underscore';

import {
  AspectRatioName,
  CaptionsMediaSourceType,
  CaptionsOverride,
  createMap,
  DeepImmutableMap,
  ICaptionsRegionProperties,
  ICaptions as IEmbedCaptions,
  IEmbedConfig,
  IEmbedConfiguration,
  Size,
} from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { getValue } from 'utils/collections';
import { CAPTION_STYLE_FIT_TEXT } from 'utils/constants';
import { isImmutable } from 'utils/immutable';
import { ViewportWidth } from 'utils/measurement';
import { int } from 'utils/numbers';
import { createTextHtml, splitStyle } from 'utils/rte';
import { getTemplate, TemplateId, TextTemplate } from 'utils/text-templates';
import {
  calculateVisibleText,
  formatHeadlinerStyleRules,
  getUpperLeft,
} from 'utils/ui';
import StyleScaler from './StyleScaler';
import { Captions, Style } from './types';

export const DEFAULT_TEMPLATE_MAX_CHARACTERS = 122;
export const CAPTIONS_OVERRIDE_OMITTED_KEYS = ['animation'];

export const switchCaptionsVieport = (
  captions: IEmbedCaptions,
  toViewport: Size<number>,
): IEmbedCaptions => {
  try {
    // gets the font size in px for the editor config assuming the same vw but the target
    // viewport instead.
    const currentFontSizePx = new ViewportWidth(
      parseFloat(captions.containerStyle.fontSize),
    ).toUnit('px', toViewport);

    return {
      ...captions,
      containerStyle: {
        ...captions.containerStyle,
      },
      editor: {
        ...captions.editor,
        styleContext: {
          ...captions.editor?.styleContext,
          // updates both the fontsize and the viewport
          fontSize: currentFontSizePx.value,
          viewport: toViewport,
        },
      },
    };
  } catch (e) {
    return captions;
  }
};

export function formatCaptionsForConfig(captions: Captions): IEmbedCaptions {
  const vh = captions.getIn(['style', 'viewport', 'height']);
  const vw = captions.getIn(['style', 'viewport', 'width']);

  const transcriptId = captions.get('transcriptId');
  const captionSource = !transcriptId
    ? {}
    : {
        captionSource: {
          transcriptId,
          revisionId: captions.get('transcriptRevisionId'),
        },
      };

  const scaler = new StyleScaler(vh, vw);
  const formatStyleRules = (rules: Iterable<string, any>) =>
    rules.reduce(
      (acc, val, key) => ({
        ...acc,
        ...scaler.forConfig(key, val),
      }),
      {},
    );

  const style = captions.get('style');
  const hasStyleData = !!style;

  const styleData = !hasStyleData
    ? {}
    : {
        animation: {
          follow: {
            enabled: style.getIn(['animation', 'enabled']),
            textStyle: {
              color: style.getIn(['animation', 'color']),
            },
          },
        },
        containerStyle: formatStyleRules(
          style.get('containerStyle').concat(style.get('size')),
        ),
        editor: {
          mediaSource: {
            id: captions.get('mediaSourceId'),
            type: captions.get('mediaSourceType'),
          },
          styleContext: {
            fontSize: style.getIn(['containerStyle', 'fontSize']),
            textBoxHeight: style.get('textBoxHeight'),
            viewport: {
              height: vh,
              width: vw,
            },
          },
          templateName: style.get('templateName'),
        },
        region: {
          properties: formatStyleRules(
            style.get('position'),
          ) as ICaptionsRegionProperties,
          type: 'absolute' as 'absolute',
        },
        textStyle: formatStyleRules(style.get('textStyle')),
      };

  return {
    ...captionSource,
    ...styleData,
    editor: {
      ...(styleData.editor || {}),
      mediaSource: {
        id: captions.get('mediaSourceId'),
        type: captions.get('mediaSourceType'),
      },
    },
    enabled: captions.get('enabled'),
  };
}

function getCaptionsOffset(config: IEmbedConfig) {
  const mediaSourceId = getValue(config, [
    'captions',
    'editor',
    'mediaSource',
    'id',
  ]);
  const mediaSourceType = getValue(config, [
    'captions',
    'editor',
    'mediaSource',
    'type',
  ]);

  if (mediaSourceType === 'audio') {
    const captionsAudioSourceInfo = config.audioInfo?.find(
      info => info.recordingId === mediaSourceId,
    );
    return captionsAudioSourceInfo?.startAtMilli;
  }

  if (mediaSourceType === 'video') {
    const videos = getValue(config, 'videoClips');
    const video = videos.find(v => v.videoId === mediaSourceId);
    return video.startMilli;
  }

  return 0;
}

export function formatCaptionsFromConfig(config: IEmbedConfig): Captions {
  const captions = getValue(config, 'captions');
  if (!captions) return (createMap({}) as unknown) as Captions;

  const vh = getValue(captions, [
    'editor',
    'styleContext',
    'viewport',
    'height',
  ]);
  const vw = getValue(captions, [
    'editor',
    'styleContext',
    'viewport',
    'width',
  ]);
  const scaler = new StyleScaler(vh, vw);

  const formatStyleRules = rules =>
    !rules
      ? undefined
      : Map(
          Object.keys(rules).reduce(
            (acc, key) => ({
              ...acc,
              ...scaler.fromConfig(key, rules[key]),
            }),
            {},
          ),
        );

  const { enabled } = captions;
  const shouldRechunkCaptionsOnEditorLoad = getValue(captions, [
    'editor',
    'shouldRechunkCaptionsOnEditorLoad',
  ]);
  const transcriptId = getValue(captions, ['captionSource', 'transcriptId']);
  const transcriptRevisionId = getValue(captions, [
    'captionSource',
    'revisionId',
  ]);
  const style = (() => {
    // style data is only defined if there's a template name.  no matter what the user does, all
    // captions styling originates from a template - so no name means no style yet
    const templateName = getValue(captions, ['editor', 'templateName']);
    if (!templateName) {
      const defaultTemplateName = 'default';
      const aspectRatioName = getAspectRatioName(
        config.dimensions.height,
        config.dimensions.width,
      );
      const template = formatCaptionsStyleFromTemplate(
        defaultTemplateName,
        aspectRatioName,
      );

      return template
        .get('style')
        .set('templateName', defaultTemplateName)
        .toJS();
    }

    const {
      height,
      width,
      ...restContainerStyle
    }: IEmbedCaptions['containerStyle'] = captions.containerStyle || {};

    const fontSize = getValue(captions, ['editor', 'styleContext', 'fontSize']);
    const containerStyle = formatStyleRules(restContainerStyle).set(
      'fontSize',
      fontSize,
    );
    const position = formatStyleRules(
      getValue(captions, ['region', 'properties']),
    );
    const size = formatStyleRules({ height, width });
    const textStyle = formatStyleRules(captions.textStyle);
    const viewport = getValue(captions, ['editor', 'styleContext', 'viewport']);
    const textBoxHeight = getValue(captions, [
      'editor',
      'styleContext',
      'textBoxHeight',
    ]);

    const animationEnabled = getValue(captions, [
      'animation',
      'follow',
      'enabled',
    ]);
    const animationColor = getValue(captions, [
      'animation',
      'follow',
      'textStyle',
      'color',
    ]);
    const animation = formatStyleRules({
      color: animationColor,
      enabled: animationEnabled,
    });

    return {
      animation,
      containerStyle,
      position,
      size,
      templateName,
      textBoxHeight,
      textStyle,
      viewport,
    };
  })();

  return fromJS({
    enabled,
    style,
    transcriptId,
    transcriptRevisionId,
    mediaSourceId: getValue(captions, ['editor', 'mediaSource', 'id']),
    mediaSourceType: getValue(captions, ['editor', 'mediaSource', 'type']),
    offsetMillis: getCaptionsOffset(config),
    shouldRechunkCaptionsOnEditorLoad,
  });
}

export function formatCaptionsTemplate(
  textTemplate: DeepImmutableMap<TextTemplate>,
) {
  return textTemplate.withMutations(t => {
    const height = t.getIn(['size', 'height']);

    t.set('size', t.get('size').delete('height'));
    t.set('textBoxHeight', height);

    if (t.getIn(['position', 'bottom'])) {
      const bottom = t.getIn(['position', 'bottom']);
      const vh = t.getIn(['viewport', 'height']);
      const top = vh - bottom - height;

      t.deleteIn(['position', 'bottom']);
      t.setIn(['position', 'top'], top);
    }

    return t;
  });
}

export function formatCaptionsStyle(
  style: Style,
  templateName: string,
): Captions {
  return (createMap({
    style: style.set('templateName', templateName),
  }) as any) as Captions;
}

export function formatCaptionsStyleFromTemplate(
  templateName: string,
  aspectRatioName: AspectRatioName,
) {
  const template = formatCaptionsTemplate(
    createMap(getTemplate(templateName, aspectRatioName)),
  );
  const templateStyle = template.delete('ui') as Style;
  return formatCaptionsStyle(templateStyle, templateName);
}

/**
 * converts a caption to a text overlay.
 *
 * FIXME: this should probably live in a more neutral place like a conversion module.  if we ever
 * add a function to convert a text overlay to a caption, we might create a circular dependency
 * between the captions and text-overlay utils modules
 *
 * TODO need type info here
 */
export function convertCaptionToOverlay(
  phrase: Map<string, any>,
  captionsStyle: Map<string, any> = Map(),
  transcriptOffsetMillis: number = 0,
) {
  const { outerStyle, innerStyle } = splitStyle(
    captionsStyle.get('containerStyle') || Map(),
  );

  const textHtml = createTextHtml(
    phrase.get('text'),
    innerStyle,
    captionsStyle.get('textStyle'),
  );

  const durationMillis = phrase.get('endMillis') - phrase.get('startMillis');
  const size = {
    height: captionsStyle.get('textBoxHeight'),
    width: captionsStyle.getIn(['size', 'width']),
  };

  return fromJS({
    size,
    textHtml,
    position: getUpperLeft(
      captionsStyle.get('position'),
      size,
      captionsStyle.get('viewport'),
    ),
    // FIXME types of innerStyle and outerStyle can be set
    style: (outerStyle as any)
      .withMutations(os => {
        const innerStyleAsAny = innerStyle as any;
        if (innerStyleAsAny.get('background')) {
          os.set('textHighlight', innerStyleAsAny.get('background'));
        }

        os.set(
          'lineHeight',
          captionsStyle.getIn(['containerStyle', 'lineHeight']),
        );
        return os;
      })
      .filter(Boolean),
    time: {
      endMillis:
        durationMillis >= spareminConfig.minTextDurationMillis
          ? phrase.get('endMillis') + transcriptOffsetMillis
          : phrase.get('startMillis') +
            spareminConfig.minTextDurationMillis +
            transcriptOffsetMillis,
      startMillis: phrase.get('startMillis') + transcriptOffsetMillis,
    },
    transitions: {
      in: { value: 'fadeInLeft' },
      out: { value: 'fadeOutRight' },
    },
    viewport: captionsStyle.get('viewport'),
  });
}

/**
 * wizards need to create captions outside of the normal flow of the editor
 */
/*
 * TODO lots of optional params.  maybe options bag works better. for example to pass a template
 * name, without the other optional args, you'd need to pass 3 undefined values
 */
export function createWizardCaptions(
  ratioName: AspectRatioName,
  mediaSourceId: number | string,
  mediaSourceType: CaptionsMediaSourceType,
  transcriptId?: string,
  revisionId?: string,
  enabled?: boolean,
  templateId: TemplateId = 'default',
) {
  // transcript might have come up empty, in which cases these ids will be undefined
  const hasTranscript = !isUndefined(transcriptId) && !isUndefined(revisionId);

  return (formatCaptionsStyleFromTemplate(templateId, ratioName).withMutations(
    m => {
      m.set('enabled', !isUndefined(enabled) ? enabled : hasTranscript);
      m.set('mediaSourceId', mediaSourceId);
      m.set('mediaSourceType', mediaSourceType);

      if (hasTranscript) {
        m.set('transcriptId', transcriptId);
        m.set('transcriptRevisionId', revisionId);
      }

      return m;
    },
  ) as any) as Captions;
}

/**
 * Applies the captions override object to the already generated captions object.
 * If no captions override is provided, the captions object will be returned as
 * it was originally.
 */
export function applyWizardCaptionsOverrideToStyle(
  captions: Captions,
  captionsOverride?: CaptionsOverride,
): Captions {
  if (!captionsOverride) {
    return captions;
  }

  return captions.withMutations(s => {
    Object.entries(captionsOverride).forEach(([entry, value]) => {
      if (!CAPTIONS_OVERRIDE_OMITTED_KEYS.includes(entry)) {
        s.setIn(['style', entry], fromJS(value));
      }
    });
    // in order not to change the way in which captions config is parsed,
    // when saving captions from the wizard, it is necessary to map
    // the animation entry to what Caption['style'] type is expecting.
    s.setIn(
      ['style', 'animation'],
      fromJS({
        enabled: captionsOverride.animation?.follow.enabled ?? false,
        color: captionsOverride.animation?.follow.textStyle?.color,
      }),
    );
  });
}

export function getCaptionsMediaSource(config: IEmbedConfiguration) {
  const mediaSource = getValue(config, [
    'embedConfig',
    'captions',
    'editor',
    'mediaSource',
  ]);

  if (!mediaSource) return undefined;

  const { type } = mediaSource;
  const id = type === 'text' ? mediaSource.id : int(mediaSource.id);

  return { id, type };
}

/*
 * extracts style from the captions object as defined by the embed configuration.
 * this style object is used by the rechunking algorithm to figure out how many
 * words fit in the captions box
 */
export function getCaptionsConfigStyle(captions) {
  if (!hasCaptions({ captions } as any)) return undefined;

  const { containerStyle } = captions;

  const { width } = captions.editor.styleContext.viewport;
  const vwToPx = (vw: string) =>
    `${!vw ? 0 : (parseFloat(vw) / 100) * width}px`;

  return {
    containerStyle: {
      ...containerStyle,
      fontSize: vwToPx(containerStyle.fontSize),
      height: captions.editor.styleContext.textBoxHeight,
      paddingBottom: vwToPx(containerStyle.paddingBottom),
      paddingLeft: vwToPx(containerStyle.paddingLeft),
      paddingRight: vwToPx(containerStyle.paddingRight),
      paddingTop: vwToPx(containerStyle.paddingTop),
      width: vwToPx(containerStyle.width),
    },
    textStyle: captions.textStyle,
  };
}

/*
 * extracts style from captions object as defined by redux.  this style object
 * is used by the rechunking algorithm to figure out how many words fit in the
 * captions box
 */
export function getCaptionsStateStyle(captions) {
  const style = captions.get('style');

  if (!style) return undefined;

  const canvasSize = style.get('viewport').toJS();

  // NB: padding values are stored in redux as numbers, with an implicit unit
  // of "vw", meanwhile for pretty much every other measurement in redux, numbers
  // indicates a px value.  formatHeadlinerStyleRules takes the padding values
  // and appends them with a "px" unit.  First, convert all of the padding values
  // from vw to px, then feed into formatHeadlinerStyleRules so that the return
  // value of that function is correct
  const paddingStyle = [
    'paddingBottom',
    'paddingLeft',
    'paddingRight',
    'paddingTop',
  ].reduce((acc, paddingKey) => {
    const value = style.getIn(['containerStyle', paddingKey]);

    if (value !== undefined) {
      acc[paddingKey] = new ViewportWidth(value).toUnit('px', canvasSize).value;
    }

    return acc;
  }, {});

  const containerStyle = {
    ...formatHeadlinerStyleRules(
      style.get('containerStyle').merge(paddingStyle),
    ),
    width: style.getIn(['size', 'width']),
    height: style.get('textBoxHeight'),
  };
  const textStyle = formatHeadlinerStyleRules(style.get('textStyle'));

  return { containerStyle, textStyle };
}

export function hasCaptions(config: IEmbedConfig): boolean {
  // the editor creates USTs without captions that have the captions object set
  // to { editor { mediaSource: {}}}.  to check if captions exist, look for the
  // "enabled" field
  return config?.captions?.enabled !== undefined;
}

export function calculateMaxChars(captions) {
  const style = isImmutable(captions)
    ? getCaptionsStateStyle(captions)
    : getCaptionsConfigStyle(captions);

  if (!style) return undefined;

  const [, maxChars] = calculateVisibleText(
    CAPTION_STYLE_FIT_TEXT,
    style.containerStyle,
    style.textStyle,
  );

  return maxChars;
}

export default {
  formatCaptionsForConfig,
  formatCaptionsFromConfig,
};
