import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FacebookPostType } from 'redux/middleware/api/podcast-service';
import { pushModal } from 'redux/modules/modal';
import { facebookUserDataSelector } from 'redux/modules/social';
import { Dispatch } from 'redux/types';
import { AutogramSubscriptionOptions, DeepPartial } from 'types';

export interface UseFacebookAutomationAuthenticatorResults {
  authenticate: (
    postType: FacebookPostType,
  ) => Promise<DeepPartial<AutogramSubscriptionOptions>>;
}

export default function useFacebookAutomationAuthenticator(): UseFacebookAutomationAuthenticatorResults {
  const reduxDispatch = useDispatch<Dispatch>();
  const { accessToken } = useSelector(facebookUserDataSelector);

  const authenticate = useCallback(
    async (
      postType: FacebookPostType,
    ): Promise<DeepPartial<AutogramSubscriptionOptions>> => {
      if (postType === 'facebookStory' && accessToken) {
        return {
          autopost: {
            description: '',
            postType: 'facebookStory',
          },
        };
      }

      const result = await reduxDispatch(
        pushModal({
          name: 'FacebookAutoPost',
          params: {
            postType,
          },
        }),
      );

      if (result) {
        return {
          autopost: result,
        };
      }

      return undefined;
    },
    [accessToken, reduxDispatch],
  );

  return {
    authenticate,
  };
}
