import * as React from 'react';
import { noop } from 'underscore';

import AuthFormSelect, { AuthFormSelectProps } from './AuthFormSelect';

type PickedSelectProps = Pick<AuthFormSelectProps, 'onChange'>;

export interface PodcastFieldProps extends PickedSelectProps {
  disabled?: boolean;
  value: string;
}

const PodcastField: React.SFC<PodcastFieldProps> = ({
  disabled,
  onChange,
  value,
}) => (
  <AuthFormSelect
    label="Do you plan on using Headliner for a Podcast?"
    onChange={onChange}
    value={value}
    disabled={disabled}
  >
    <option value="" />
    <option value="yes">yes</option>
    <option value="no">no</option>
  </AuthFormSelect>
);

PodcastField.defaultProps = {
  disabled: false,
  onChange: noop,
  value: '',
};

export default PodcastField;
