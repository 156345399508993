import React from 'react';
import { identity } from 'underscore';

import { INTRO_OUTRO_LIMIT } from 'redux/modules/mixpanel';
import { ApplicationError } from 'utils/ApplicationError';
import { getDuration } from 'utils/audio';
import { INTRO_OUTRO_MAX_DURATION_SECS } from 'utils/constants';
import {
  getSupportedVideoMimeTypes,
  isSupportedVideoFile,
} from 'utils/formats';

import MediaFileSelector, {
  MediaFileSelectorInstance,
} from '../MediaFileSelector';
import { MediaIntegrationId, VideoSource } from '../types';
import {
  IntegrationProps,
  MediaSourceHandlers,
  OnAddIntegrationMedia,
  UploadEdgeVideosIntegration,
} from '../types/integrations';

interface MediaUploadIntegrationProps extends IntegrationProps {
  accept?: (string | string[])[];
}

const { useCallback, useRef, useState } = React;

const INTEGRATION_ID = MediaIntegrationId.VIDEOCLIP;

const COMPATIBLE_INTEGRATION_IDS = [MediaIntegrationId.VIDEOCLIP];

const useIntroOutroUploadIntegration = ({
  priority,
}: MediaUploadIntegrationProps): UploadEdgeVideosIntegration => {
  const [handlers, setHandlers] = useState<MediaSourceHandlers>();
  const ref = useRef<MediaFileSelectorInstance>();

  const handleFileAccepted = useCallback(
    async (source: VideoSource) => {
      // if video is not a supported video file the action is rejected
      if (!isSupportedVideoFile(source)) {
        handlers.reject(
          source,
          new ApplicationError('File type not supported', 'IN001'),
        );
      } else {
        // if file is valid, duration and size are checked
        const duration = await getDuration(source);
        const sizeMb = (source as File).size / 1024 ** 2;
        if (
          duration > INTRO_OUTRO_MAX_DURATION_SECS ||
          sizeMb > spareminConfig.introOutroClipMaxMb
        ) {
          handlers.reject(
            source,
            new ApplicationError(INTRO_OUTRO_LIMIT, 'IN008'),
          );
        } else {
          handlers.accept(source, { id: MediaIntegrationId.VIDEOCLIP });
        }
      }
      setHandlers(undefined);
    },
    [handlers],
  );

  const handleFileRejected = useCallback(
    error => {
      handlers.reject(error.file, error);
      setHandlers(undefined);
    },
    [handlers],
  );

  const handleAddMedia: OnAddIntegrationMedia = useCallback(mediaHandlers => {
    setHandlers(mediaHandlers);
    ref.current?.open();
  }, []);

  return {
    priority,
    creationType: 'static',
    id: INTEGRATION_ID,
    compatibleIntegrationIds: COMPATIBLE_INTEGRATION_IDS,
    mediaTypeCardProps: { icon: <></>, info: '' },
    onAddMedia: handleAddMedia,
    onDeleteMedia: identity,
    onReplaceMedia: identity,
    postProcessor: identity,
    replaceMainMedia: identity,
    staticSelectionModalProps: { icon: <></>, label: '' },
    type: 'edge-videos-source',
    ui: (
      <MediaFileSelector
        accept={getSupportedVideoMimeTypes()}
        key={1}
        onFileAccepted={handleFileAccepted}
        onFileRejected={handleFileRejected}
        ref={ref}
        testId="intro-outro-upload"
      />
    ),
  };
};

export default useIntroOutroUploadIntegration;
