import * as React from 'react';

import { IconProps } from 'components/icons/types';

/* tslint:disable-next-line:variable-name */
const LinkIcon: React.SFC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="18"
    viewBox="0 0 15 18"
    {...props}
  >
    <path
      fill="#8CA0C3"
      fillRule="nonzero"
      d="M4.286 6.469c0-1.086.84-1.969 1.875-1.969h4.553V1.969c0-.466-.36-.844-.803-.844h-2.7C6.842.453 6.15 0 5.358 0c-.791 0-1.483.453-1.854 1.125h-2.7c-.443 0-.803.378-.803.844V13.78c0 .466.36.844.804.844h3.482V6.469zm1.071-5.063c.444 0 .804.378.804.844 0 .466-.36.844-.804.844-.444 0-.803-.378-.803-.844 0-.466.36-.844.803-.844zm6.16 8.719H15v7.031c0 .466-.36.844-.804.844H6.161c-.444 0-.804-.378-.804-.844V6.47c0-.466.36-.844.804-.844h4.553v3.656c0 .464.362.844.804.844zM15 8.787V9h-3.214V5.625h.203c.213 0 .417.089.568.247l2.208 2.318c.15.159.235.373.235.597z"
      opacity=".5"
    />
  </svg>
);

export default LinkIcon;
