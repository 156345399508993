import * as React from 'react';

import { FileAudio, FileVideo } from 'components/icons';
import { getCreatePath } from 'utils/routes';
import { FileStepProps, WizardRouterOption } from '../types';
import { iconicSelectorBlock } from '../utils';
import IconicSelectorStep from './IconicSelectorStep';

const { useCallback } = React;

const FileStep: React.FC<FileStepProps> = ({ className, onSelect }) => {
  const handleClickNoFile = useCallback(
    (e: React.MouseEvent<any>) => {
      onSelect('other', e);
    },
    [onSelect],
  );

  return (
    <>
      <IconicSelectorStep<WizardRouterOption>
        className={className}
        onClick={onSelect}
        options={[
          {
            className: iconicSelectorBlock('file-option'),
            href: '/wizard?type=audiogram',
            icon: <FileAudio />,
            label: 'Audio file',
            value: 'audio',
          },
          {
            className: iconicSelectorBlock('file-option'),
            href: '/wizard?type=video-transcription',
            icon: <FileVideo />,
            label: 'Video file',
            value: 'video',
          },
        ]}
        title="Do you have a file to start with?"
        footer={
          <a
            href={getCreatePath()}
            onClick={handleClickNoFile}
            className={iconicSelectorBlock('footer-link')}
          >
            I don’t have a file to start with
          </a>
        }
      />
    </>
  );
};

export default FileStep;
