import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { destinationPresetTypes } from 'blocks/AudiogramDestinations/destinationPresets';
import { getPresetMaxDurationKey } from 'blocks/AudiogramDestinations/destinationPresets/utils';
import { AudioClipperComponent } from 'components/AudioClipper';
import Wizard, { Step } from 'components/Wizard';
import { onCompleteWizard, onWizardNext } from 'redux/modules/mixpanel';
import { getMixpanelBlurValue } from 'redux/modules/mixpanel/utils';
import { showError } from 'redux/modules/notification';
import { goToCreate, goToProject } from 'redux/modules/router/actions';
import { clearSelectedAudio } from 'redux/modules/sample-audio';
import {
  clearEntireAudio,
  createTemplateProject,
} from 'redux/modules/template-wizard/actions';
import {
  templateIdSelector,
  templateSelector,
} from 'redux/modules/template-wizard/selectors';
import { projectIdSelector } from 'redux/modules/wizard-export/selectors';
import { Dispatch } from 'redux/types';
import { ImageOriginId, SoundwavePositionValue } from 'types';
import { isApplicationError } from 'utils/ApplicationError';
import { createClippingOption } from 'utils/audio';
import {
  getBlurRadius,
  hasSlideFromOrigin,
  isAudiogram,
} from 'utils/embed/embed';
import {
  getPositionIdFromWave,
  mapSoundwaveGeneration,
} from 'utils/embed/soundwave';
import { FatalError } from 'utils/FatalError';
import { CustomizeStepSubmitType } from '../CustomizeStep';
import LoadingStep from '../LoadingStep';
import { MIXPANEL_WIZARD_STEP_MAP } from './constants';
import { TemplateWizardStep } from './types';
import useAddAudioStep from './useAddAudioStep';
import useClipAudioStep from './useClipAudioStep';
import useCustomizeStep from './useCustomizeStep';
import useExportingStep from './useExportingStep';
import useProgressStep from './useProgressStep';
import useTemplateLoader from './useTemplateLoader';
import useAudiogramWizardBlocker from './useTemplateWizardBlocker';
import { block } from './utils';

const { useCallback, useRef, useState } = React;

export type TemplateWizardProps = {};

const ERROR_ID = 'template-wizard-error';

const TemplateWizard: React.FC<TemplateWizardProps> = () => {
  const templateId = useSelector(templateIdSelector);
  const template = useSelector(templateSelector);
  const wizardRef = useRef<Wizard<TemplateWizardStep>>();
  const dispatch = useDispatch<Dispatch>();
  const [step, setStep] = useState<TemplateWizardStep>();
  const submitTypeRef = useRef<CustomizeStepSubmitType>();
  const [clipDurationMillis, setClipDurationMillis] = useState<number>(
    undefined,
  );
  const [selectedPreset, setSelectedPreset] = useState<
    destinationPresetTypes.DestinationPreset
  >();

  const dimensions = template?.dimension;

  const clipperRef = useRef<AudioClipperComponent>();

  const handleError = useCallback(
    (error: Error, bail: boolean = false) => {
      if (error instanceof FatalError) {
        dispatch(
          showError({
            message: 'There was an error creating your project',
            code: 'ER007',
            dismissAfterSec: 5,
            id: ERROR_ID,
          }),
        );
        dispatch(goToCreate());
      } else {
        dispatch(
          showError({
            message: error.message,
            code: isApplicationError(error) ? error.code : undefined,
            dismissAfterSec: 5,
            id: ERROR_ID,
          }),
        );
      }

      if (bail) {
        dispatch(goToCreate());
      }
    },
    [dispatch],
  );

  const { status: templateStatus } = useTemplateLoader({
    onError: err => handleError(err, true),
  });

  const {
    afterUploadTransfer,
    audioRegion: defaultAudioRegion,
    audioSourceType,
    podcastIdentifier,
    source,
    step: addAudioStep,
    resetAudioState,
  } = useAddAudioStep({
    onAudioAdded: src => src && wizardRef.current.next(),
    onError: handleError,
  });

  const { step: clipAudioStep, transcription } = useClipAudioStep({
    clipperRef: clipperRef as any,
    hidden: step !== TemplateWizardStep.CLIP_AUDIO,
    onClearError: defaultAudioRegion.setSelected,
    onError: defaultAudioRegion.setUnselected,
    onReady: defaultAudioRegion.setSelected,
    onRegionUpdate: region => {
      setClipDurationMillis(region.endMillis - region.startMillis);
    },
    onPresetChange: setSelectedPreset,
    regionSelected: defaultAudioRegion !== undefined,
    src: source,
  });

  const { step: customizeStep } = useCustomizeStep({
    podcastIdentifier,
    aspectRatio: dimensions?.width / dimensions?.height,
    durationMillis: clipDurationMillis,
    source,
    onSubmit: useCallback(
      ({ type, projectName, transcriptionEnabled }, config) => {
        wizardRef.current.next();
        submitTypeRef.current = type;
        const audioClip = clipperRef.current.clip;

        afterUploadTransfer(async () => {
          try {
            dispatch(
              // TODO helper to create this action from config
              onCompleteWizard({
                templateId,
                addedImage: config.slideshow?.length > 0,
                addedText: config.textOverlays?.length > 0,
                audioSource: audioSourceType,
                backgroundColor: config.backgroundColor,
                canvaImage: hasSlideFromOrigin(ImageOriginId.CANVA, config),
                enabledProgressBar: config.progress.enabled,
                length: isAudiogram(audioClip) ? 'short' : 'long',
                originalAudioDuration: audioClip.originalDurationMillis,
                submissionType: type,
                transcription: transcriptionEnabled,
                type: 'templateWizard',
                waveColor: config.soundwave?.waveColor,
                waveGeneration: mapSoundwaveGeneration(
                  config.soundwave?.waveGeneration,
                ),
                wavePosition: getPositionIdFromWave(
                  config.soundwave,
                ) as SoundwavePositionValue,
                waveStyle: config.soundwave?.waveType,
                blurValue: getMixpanelBlurValue(getBlurRadius(config)),
                ...createClippingOption(audioClip),
              }),
            );

            const submitConfig = {
              ...config,
              audioClip,
              audioSourceType,
              projectName,
              templateId,
              aspectRatio: dimensions,
              transcription: {
                ...transcription,
                transcribe: transcriptionEnabled,
              },
            };

            await dispatch(createTemplateProject(type, submitConfig));
          } catch (err) {
            handleError(err, true);
          }
        })();
      },
      [
        afterUploadTransfer,
        audioSourceType,
        dimensions,
        dispatch,
        handleError,
        templateId,
        transcription,
      ],
    ),
    onTemplatesClick: () => wizardRef.current.previous(),
    transcriptionEnabled: transcription.transcribe,
  });

  const { step: progressStep } = useProgressStep({
    onCompleted: () => {
      if (isAudiogram(clipDurationMillis) && submitTypeRef.current === 'edit') {
        dispatch((_, getState) => {
          const projectId = projectIdSelector(getState());
          dispatch(
            goToProject({ projectId, history: false, fromWizard: true }),
          );
        });
      } else {
        wizardRef.current.next();
      }
    },
    onError: handleError,
  });

  const { step: exportingStep } = useExportingStep({
    durationMillis: clipDurationMillis,
    onError: error => {
      wizardRef.current?.jump('customize');
      handleError(error);
    },
  });

  const handleStepChange = useCallback(
    (dest: Step<TemplateWizardStep>, src: Step<TemplateWizardStep>) => {
      setStep(dest.stepId);

      if (src) {
        dispatch(
          onWizardNext({
            templateId,
            step: MIXPANEL_WIZARD_STEP_MAP[dest.stepId],
            type: 'templateWizard',
            ...(src.stepId === 'clip-audio' && {
              destination: selectedPreset?.key ?? 'Custom',
              maxDuration: getPresetMaxDurationKey(selectedPreset?.key),
            }),
          }),
        );
      }

      // When accessing the step, selected preset should be reset when re entering
      // the clip step from the add-audio one.
      if (
        dest.stepId === TemplateWizardStep.CLIP_AUDIO &&
        src.stepId === TemplateWizardStep.ADD_AUDIO
      ) {
        setSelectedPreset(undefined);
      }

      if (dest.stepId === TemplateWizardStep.ADD_AUDIO) {
        resetAudioState();
        dispatch(clearEntireAudio());
        dispatch(clearSelectedAudio());
      }
    },
    [dispatch, resetAudioState, selectedPreset, templateId],
  );

  const handleCancel = useCallback(() => {
    dispatch(goToCreate());
  }, [dispatch]);

  const confirm = useAudiogramWizardBlocker(step);

  return templateStatus !== 'success' ? (
    <LoadingStep className={block('loading')} message="Loading" />
  ) : (
    <Wizard<TemplateWizardStep>
      bodyClassName={block('body', { [step]: true })}
      className={block()}
      onCancelClick={handleCancel}
      onStepChange={handleStepChange}
      onStepClick={confirm}
      canBacktrack={
        step !== TemplateWizardStep.SUBMITTING &&
        step !== TemplateWizardStep.EXPORTING
      }
      ref={wizardRef}
      steps={[
        addAudioStep,
        clipAudioStep,
        customizeStep,
        progressStep,
        exportingStep,
      ]}
    />
  );
};

export default TemplateWizard;
