import cn from 'classnames';
import * as React from 'react';

import IconButton, { IconButtonProps } from 'components/IconButton';
import Tooltip from 'components/Tooltip';
import { block } from '../../utils';

type PickedButtonProps = Pick<IconButtonProps, 'disabled' | 'onClick'>;

export interface StyleButtonProps extends PickedButtonProps {
  buttonClassName?: string;
  buttonContainerClassName?: string;
  icon: JSX.Element;
  label: React.ReactNode;
  onClick?: () => void;
  tooltip?: React.ReactNode;
  tooltipId?: string;
  badge?: JSX.Element;
}

const StyleButton = React.forwardRef<HTMLDivElement, StyleButtonProps>(
  (
    {
      disabled,
      icon,
      label,
      onClick,
      tooltip,
      tooltipId,
      buttonContainerClassName,
      buttonClassName,
      badge,
    },
    ref,
  ) => (
    <div
      className={cn(block('tile-button-container'), buttonContainerClassName)}
      ref={ref}
    >
      <IconButton
        className={cn(block('tile-button'), buttonClassName)}
        disabled={disabled}
        onButtonClick={onClick}
      >
        {badge}
        {React.cloneElement(icon, {
          className: cn(block('tile-icon'), icon.props.className),
        })}
        <div className={block('tile-label')}>{label}</div>
        {tooltip && (
          <Tooltip
            content={tooltip}
            id={tooltipId}
            placement="bottom"
            preventHideOnHover={false}
          >
            <div className={block('tile-overlay')} />
          </Tooltip>
        )}
      </IconButton>
    </div>
  ),
);

export default StyleButton;
