import * as React from 'react';
import { useSelector } from 'react-redux';
import { isUndefined, noop } from 'underscore';

import { AudioClipperComponent } from 'components/AudioClipper';
import Wizard, { IndexedStep } from 'components/Wizard';
import useMediaPlayback from 'hooks/useMediaPlayback';
import useOnMount from 'hooks/useOnMount';
import { waitingForStandardizationSelector } from 'redux/modules/async-audio-clipper';
import { uploadProgressSelector } from 'redux/modules/automated-wizard';
import { AspectRatioName } from 'types';
import { getAspectRatio } from 'utils/aspect-ratio';
import { createChainedFunction } from 'utils/functions';
import { capitalize } from 'utils/string';
import { TabKey } from '../AddAudioStep';
import AspectRatioStep from '../AspectRatioStep';
import ClipAudioStep from '../ClipAudioStep';
import AutomatedAddAudioStep from './AutomatedAddAudioStep';
import AutomatedWizardProgressStep from './AutomatedWizardProgressStep';
import { AutomatedWizardProps, Step, Transcription } from './types';
import UseAutomatedAudio from './UseAutomatedAudio';
import { block } from './utils';

const returnNull = () => null;

const { useCallback, useRef, useState } = React;

const DEFAULT_TRANSCRIPTION_CONFIG = {
  transcriptionToggleAllowed: true,
};

const AutomatedWizard: React.FC<AutomatedWizardProps> = ({
  entireAudioInstanceId,
  onCancel,
  onError,
  onMount,
  onUnmount,
  onStepChange,
  onSubmit,
  onComplete,
}) => {
  const clipperRef = useRef<AudioClipperComponent>();
  const wizardRef = useRef<Wizard>();

  const [step, setStep] = useState<Step>();
  const [aspectRatioName, setAspectRatioName] = useState<AspectRatioName>(
    'square',
  );
  const [addAudioTab, setAddAudioTab] = useState<TabKey>('upload');
  const [transcription, setTranscription] = useState<Transcription>({
    language: 'en-US',
    transcribe: true,
  });

  const clipperPlayback = useMediaPlayback();

  useOnMount(onMount, onUnmount);

  const waitingForStandardization = useSelector(
    waitingForStandardizationSelector,
  );

  const uploadProgress = useSelector(uploadProgressSelector);

  const handleAspectRatioSubmit = useCallback(
    (__, ratioName: AspectRatioName) => setAspectRatioName(ratioName),
    [setAspectRatioName],
  );

  const handleCreateProjectClick = useCallback(() => {
    onSubmit({
      transcription,
      aspectRatio: {
        dimensions: getAspectRatio(aspectRatioName).toJS(),
        name: aspectRatioName,
      },
      audioClip: clipperRef.current.clip,
    });
  }, [aspectRatioName, onSubmit, transcription]);

  const handleStepChange = useCallback(
    resetAudioState => (
      toStep: IndexedStep<Step>,
      fromStep?: IndexedStep<Step>,
    ) => {
      setStep(toStep.stepId);
      if (fromStep && fromStep.stepId === 'clip') {
        clipperPlayback.pause();
      }

      if (toStep.stepId === 'upload') {
        resetAudioState();
      }
    },
    [clipperPlayback],
  );

  return (
    <UseAutomatedAudio>
      {({ afterUploadTransfer, audioRegion, onAudioAdded, source, reset }) => (
        <Wizard
          canBacktrack={step !== 'submitting'}
          className={block()}
          ref={wizardRef}
          onCancelClick={onCancel}
          onStepChange={createChainedFunction(
            onStepChange,
            handleStepChange(reset),
          )}
          steps={[
            {
              completedName: 'Audio Selected',
              component: (
                <AutomatedAddAudioStep
                  activeTab={addAudioTab}
                  isFileSelected={!isUndefined(source)}
                  onAudioAdded={createChainedFunction(onAudioAdded, src => {
                    if (src) {
                      wizardRef.current.next();
                    }
                  })}
                  onError={onError}
                  onTabSelect={setAddAudioTab}
                />
              ),
              description:
                'Headliner can create a video automatically by transcribing your audio and finding relevant images.',
              name: 'Select Audio',
              renderCancelButton: () => null,
              renderNextButton: () => null,
              stepId: 'upload',
              title: 'Create a video automatically',
            },
            {
              completedName: 'Audio Clipped',
              component: ({ stepId }: IndexedStep) => (
                <ClipAudioStep
                  className={block('step', { hidden: stepId !== 'clip' })}
                  entireAudioInstanceId={entireAudioInstanceId}
                  src={source}
                  clipperRef={clipperRef as any}
                  onError={audioRegion.setUnselected}
                  onClearError={audioRegion.setSelected}
                  onPause={clipperPlayback.pause}
                  onPlay={clipperPlayback.play}
                  onReady={audioRegion.setSelected}
                  onTranscriptionChange={setTranscription}
                  onStop={clipperPlayback.pause}
                  playing={clipperPlayback.playing}
                  showTranscriptionToggle={false}
                  transcription={transcription}
                  transcriptionConfig={DEFAULT_TRANSCRIPTION_CONFIG}
                  uploadProgress={uploadProgress}
                />
              ),
              keepMounted: true,
              name: 'Clip Audio',
              renderNextButton: props => (
                <Wizard.Button
                  {...props}
                  disabled={
                    !audioRegion.regionSelected ||
                    !transcription.transcribe ||
                    waitingForStandardization
                  }
                  theme="next"
                >
                  Next
                </Wizard.Button>
              ),
              stepId: 'clip',
              description: (
                <div>
                  Looking for more than 10 minutes? Check out{' '}
                  <a href="/wizard?type=episode">Full Episode!</a>
                </div>
              ),
              title: 'Select 10 minutes or less of audio',
            },
            {
              completedName: aspectRatioName
                ? capitalize(aspectRatioName)
                : undefined,
              component: (
                <AspectRatioStep
                  onSelect={handleAspectRatioSubmit}
                  value={aspectRatioName}
                />
              ),
              name: 'Choose Aspect Ratio',
              renderNextButton: ({ onClick, ...props }) => (
                <Wizard.Button
                  {...props}
                  disabled={!aspectRatioName}
                  onClick={createChainedFunction(
                    onClick,
                    afterUploadTransfer(handleCreateProjectClick),
                  )}
                  theme="next"
                >
                  Create Project
                </Wizard.Button>
              ),
              stepId: 'aspect-ratio',
              title: 'Choose aspect ratio',
            },
            {
              component: (
                <AutomatedWizardProgressStep onCompleted={onComplete} />
              ),
              name: 'Creating project',
              stepId: 'submitting',
              renderCancelButton: returnNull,
              renderNextButton: returnNull,
              showInNav: false,
            },
          ]}
        />
      )}
    </UseAutomatedAudio>
  );
};

AutomatedWizard.defaultProps = {
  onCancel: noop,
  onError: noop,
  onMount: noop,
  onStepChange: noop,
  onSubmit: noop,
  onUnmount: noop,
};

export default AutomatedWizard;
