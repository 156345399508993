import { Record, RecordOf } from 'immutable';

import {
  GetInstagramAccountsResult,
  InstagramAccountInfo,
} from 'redux/middleware/api/third-party-authentication-service/types';
import { Action } from 'redux/types';
import { PostStatus } from '../constants';
import { Type } from './action-types';

export interface InstagramPostMessageData {
  tokenInfo: {
    accessToken: string;
    idToken: string;
    provider: 'instagram';
    providerUserId: string;
  };
}

export type InstagramAuthData = Pick<
  InstagramPostMessageData['tokenInfo'],
  'accessToken' | 'idToken' | 'providerUserId'
>;

interface State extends InstagramAuthData {
  accounts: GetInstagramAccountsResult[];
  instagramUserId: string;
  username: string;
  profileImageUrl: string;
  accountsLoading: boolean;
  accessToken: string;
  postStatus: PostStatus;
  socialShareId: number;
  accountInfo?: InstagramAccountInfo;
  accountInfoLoading?: boolean;
}

export type InstagramState = RecordOf<State>;

export const instagramStateFactory = Record<State>({
  accounts: [],
  instagramUserId: undefined,
  username: undefined,
  profileImageUrl: undefined,
  accountsLoading: true,
  accessToken: undefined,
  postStatus: undefined,
  socialShareId: undefined,
  idToken: undefined,
  providerUserId: undefined,
  accountInfo: undefined,
  accountInfoLoading: false,
});

export type InstagramAuthorizeSuccessAction = Action<
  Type.INSTAGRAM_AUTHORIZE_SUCCESS,
  InstagramAuthData
>;

type GetInstagramUserRequestAction = Action<
  Type.INSTAGRAM_USER_DATA_GET_REQUEST
>;

type GetInstagramUserSuccessAction = Action<
  Type.INSTAGRAM_USER_DATA_GET_SUCCESS,
  {
    username: string;
    profileImageUrl: string;
  }
>;

type GetInstagramUserFailureAction = Action<
  Type.INSTAGRAM_USER_DATA_GET_FAILURE
>;

type SelectInstagramUserIdAction = Action<Type.INSTAGRAM_USER_ID_SELECT>;

type GetInstagramAccountsRequestAction = Action<
  Type.INSTAGRAM_ACCOUNTS_GET_REQUEST
>;

type GetInstagramAccountsSuccessAction = Action<
  Type.INSTAGRAM_ACCOUNTS_GET_SUCCESS,
  GetInstagramAccountsResult[]
>;

type GetInstagramAccountsFailureAction = Action<
  Type.INSTAGRAM_ACCOUNTS_GET_FAILURE
>;

type ClearInstagramUserAction = Action<Type.INSTAGRAM_ACCOUNTS_CLEAR>;

type PostVideoRequestAction = Action<Type.INSTAGRAM_VIDEO_POST_REQUEST>;

type PostVideoFailureAction = Action<Type.INSTAGRAM_VIDEO_POST_FAILURE>;

type PostVideoSuccessAction = Action<
  Type.INSTAGRAM_VIDEO_POST_SUCCESS,
  {
    socialShareId: number;
  }
>;

type AwaitPostRequestAction = Action<Type.INSTAGRAM_VIDEO_POST_AWAIT_REQUEST>;

type AwaitPostSuccessAction = Action<Type.INSTAGRAM_VIDEO_POST_AWAIT_SUCCESS>;

type AwaitPostFailureAction = Action<Type.INSTAGRAM_VIDEO_POST_AWAIT_FAILURE>;

type GetInstagramAccountInfoRequestAction = Action<
  Type.INSTAGRAM_ACCOUNT_INFO_REQUEST
>;
type GetInstagramAccountInfoSuccessAction = Action<
  Type.INSTAGRAM_ACCOUNT_INFO_SUCCESS,
  InstagramAccountInfo
>;
type GetInstagramAccountInfoFailureAction = Action<
  Type.INSTAGRAM_ACCOUNT_INFO_FAILURE
>;

export type InstagramAction =
  | GetInstagramUserRequestAction
  | GetInstagramUserSuccessAction
  | GetInstagramUserFailureAction
  | GetInstagramAccountsRequestAction
  | GetInstagramAccountsSuccessAction
  | GetInstagramAccountsFailureAction
  | ClearInstagramUserAction
  | SelectInstagramUserIdAction
  | PostVideoRequestAction
  | PostVideoSuccessAction
  | PostVideoFailureAction
  | InstagramAuthorizeSuccessAction
  | AwaitPostRequestAction
  | AwaitPostSuccessAction
  | AwaitPostFailureAction
  | GetInstagramAccountInfoRequestAction
  | GetInstagramAccountInfoSuccessAction
  | GetInstagramAccountInfoFailureAction;
