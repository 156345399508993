import { Heading, Spacer } from '@sparemin/blockhead';
import React from 'react';
import { Alert } from 'components/icons';
import { block } from './utils';

export interface AIGenerationErrorMessageProps {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}

const AIGenerationErrorMessage: React.FC<AIGenerationErrorMessageProps> = ({
  title,
  subtitle,
}) => {
  return (
    <Spacer
      orientation="vertical"
      align="center"
      justify="center"
      space={2}
      className={block('ai-generated-contents')}
    >
      <Alert
        width="100px"
        height="100px"
        className={block('ai-generated-icon')}
      />

      {title && <Heading level={1}>{title}</Heading>}

      {subtitle && <Heading level={2}>{subtitle}</Heading>}
    </Spacer>
  );
};

export default AIGenerationErrorMessage;
