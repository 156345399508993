import { createSelector } from 'reselect';
import { socialSharesSelector } from 'redux/modules/entities';
import { stateSelector } from '../selectors';

export const tiktokSelector = createSelector(stateSelector, state =>
  state.get('tiktok'),
);

export const tiktokSocialShareIdSelector = createSelector(
  tiktokSelector,
  li => li.socialShareId,
);

export const tiktokProviderUserIdSelector = createSelector(
  tiktokSelector,
  tiktok => tiktok.providerUserId,
);

export const tiktokUserDataSelector = createSelector(tiktokSelector, tk => ({
  accessToken: tk.accessToken,
  idToken: tk.idToken,
  providerUserId: tk.providerUserId,
}));

export const tiktokIsLoading = createSelector(
  tiktokSelector,
  tk => !tk.username,
);

export const tiktokUserNameSelector = createSelector(
  tiktokSelector,
  tk => tk.username,
);

export const tiktokSocialSharesSelector = createSelector(
  tiktokSocialShareIdSelector,
  socialSharesSelector,
  (id, shares) =>
    !id || !shares || shares.size === 0
      ? undefined
      : shares.get(String(id))?.toJS(),
);

export const tikTokCreatorInfoSelector = createSelector(
  tiktokSelector,
  tk => tk.creatorInfo,
);

export const tikTokPrivacyLevelOptionsSelector = createSelector(
  tikTokCreatorInfoSelector,
  ci => ci?.privacyLevelOptions,
);

export const tikTokDefaultPrivacyLevelOptionSelector = createSelector(
  tikTokPrivacyLevelOptionsSelector,
  po => po?.[0].name,
);
