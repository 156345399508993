import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { searchFavoritePodcast } from 'redux/modules/podcast-search/actions';
import { Dispatch } from 'redux/types';
import { TabKey } from './AddAudioStep';

const useSelectAudioTab = (): [TabKey, (tab: TabKey) => void] => {
  const dispatch = useDispatch<Dispatch>();
  const [activeTab, setActiveTab] = useState<TabKey>('search');

  useEffect(() => {
    dispatch(searchFavoritePodcast());
  }, [dispatch]);

  return [activeTab, setActiveTab];
};

export default useSelectAudioTab;
