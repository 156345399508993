import cn from 'classnames';
import * as React from 'react';
import { block } from './utils';

type Props = React.HTMLAttributes<HTMLDivElement>;

export const NewProjectSelector: React.FC<Props> = props => {
  const { className, ...rest } = props;
  return <div {...rest} className={cn(className, block())} />;
};

export default NewProjectSelector;
