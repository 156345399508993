import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookF,
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faSnapchatGhost,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faClock,
  faCopy,
  faCreditCard as faCreditCardRegular,
  faNewspaper,
  faSmile,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faAngleDown,
  faAppleAlt,
  faArrowsAlt,
  faArrowsAltH,
  faBackward,
  faBars,
  faBold,
  faBook,
  faBroadcastTower,
  faBuilding,
  faBullhorn,
  faCamera,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faCheck,
  faClock as faClockSolid,
  faCog,
  faCogs,
  faCommentDots,
  faComments,
  faCreditCard,
  faCut,
  faDollarSign,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFilm,
  faFlask,
  faFont,
  faForward,
  faGraduationCap,
  faHandHoldingHeart,
  faHeadset,
  faImage,
  faInfinity,
  faInfoCircle,
  faItalic,
  faKeyboard,
  faLocationArrow,
  faLock,
  faLongArrowAltUp,
  faMagic,
  faMap,
  faMicrophone,
  faMusic,
  faPause,
  faPlay,
  faPlayCircle,
  faPlus,
  faQuestionCircle,
  faQuoteLeft,
  faReply,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faShare,
  faShareAlt,
  faSignOutAlt,
  faSlidersH,
  faSquareFull,
  faStamp,
  faStepBackward,
  faStepForward,
  faStop,
  faStoreAlt,
  faTimes,
  faTrashAlt,
  faUnderline,
  faUser,
  faUserCircle,
  faUsers,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';

export default () =>
  library.add(
    // brand icons
    faFacebookF,
    faFacebookSquare,
    faInstagram,
    faLinkedin,
    faSnapchatGhost,
    faTwitter,
    faYoutube,

    // regular icons
    faClock,
    faCopy,
    faNewspaper,
    faSmile,
    faCreditCardRegular,

    // solid icons
    faAlignCenter,
    faAlignLeft,
    faAlignRight,
    faAngleDown,
    faAppleAlt,
    faArrowsAlt,
    faArrowsAltH,
    faBackward,
    faBars,
    faBold,
    faBook,
    faBroadcastTower,
    faBuilding,
    faBullhorn,
    faCamera,
    faCaretDown,
    faCaretUp,
    faChartLine,
    faCheck,
    faClockSolid,
    faCog,
    faCogs,
    faCut,
    faCommentDots,
    faComments,
    faCreditCard,
    faDollarSign,
    faEdit,
    faEllipsisH,
    faEnvelope,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faFilm,
    faFlask,
    faFont,
    faForward,
    faGraduationCap,
    faHandHoldingHeart,
    faHeadset,
    faImage,
    faInfinity,
    faInfoCircle,
    faItalic,
    faKeyboard,
    faLocationArrow,
    faLock,
    faLongArrowAltUp,
    faMagic,
    faMap,
    faMicrophone,
    faMusic,
    faPause,
    faPlay,
    faPlayCircle,
    faPlus,
    faQuestionCircle,
    faQuoteLeft,
    faReply,
    faSearch,
    faSearchMinus,
    faSearchPlus,
    faShare,
    faShareAlt,
    faSignOutAlt,
    faSlidersH,
    faSquareFull,
    faStamp,
    faStepBackward,
    faStepForward,
    faStop,
    faStoreAlt,
    faTimes,
    faTrashAlt,
    faUnderline,
    faUser,
    faUserCircle,
    faUsers,
    faVideo,
  );
