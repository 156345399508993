import React, { useCallback } from 'react';

import { Picture } from 'components/icons';
import AddMediaButton from './AddMediaButton';
import { useNavigation } from './context/NavigationContext';
import { useEditorState } from './context/VideoEditorStateContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import ImageChildTile from './ImageChildTile';
import OptionChildView, { OptionChildViewProps } from './OptionChildView';
import TileGrid from './TileGrid';
import useAddMedia from './useAddMedia';
import { textViewBlock as block } from './utils';
import VideoChildTile from './VideoChildTile';

type PickedViewProps = Pick<OptionChildViewProps, 'active' | 'onClose'>;

export interface TextChildViewProps extends PickedViewProps {}

const MediaChildView: React.FC<TextChildViewProps> = ({ active, onClose }) => {
  const state = useTemplateState();
  const [, send] = useNavigation();
  const { isLoading } = useEditorState();
  const onAddMedia = useAddMedia();
  const { isComplex, slideshow, videoClips } = state;

  const isDisabled = isLoading;

  const handleEditMedia = useCallback(
    (id: string) => {
      send({
        type: 'CHILD_VIEW_OPEN',
        payload: 'image',
        meta: { source: 'options', id },
      });
    },
    [send],
  );

  return (
    <OptionChildView
      className={block()}
      bodyClassName={block('body')}
      icon={<Picture style={{ height: 22 }} />}
      title="media"
      {...{ active, onClose }}
    >
      <TileGrid>
        {!isComplex && (
          <AddMediaButton disabled={isDisabled} onClick={onAddMedia} />
        )}
        {slideshow.order.map(id => (
          <ImageChildTile
            key={id}
            disabled={isDisabled}
            onClick={handleEditMedia}
            id={id}
          />
        ))}
        {videoClips.order.map(id => (
          <VideoChildTile
            key={id}
            disabled={isDisabled}
            onClick={handleEditMedia}
            id={id}
          />
        ))}
      </TileGrid>
    </OptionChildView>
  );
};

export default MediaChildView;
