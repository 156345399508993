import cn from 'classnames';
import * as React from 'react';
import ReactSpinner from 'react-spinkit';

export interface SpinnerProps
  extends Pick<ReactSpinner.SpinnerProps, 'noFadeIn'> {
  className?: string;
}

const Spinner: React.SFC<SpinnerProps> = ({ className, ...props }) => (
  <ReactSpinner
    className={cn('spinner--blue', className)}
    spinnerName="three-bounce"
    {...props}
  />
);

export default Spinner;
