import { Button, Spacer } from '@sparemin/blockhead';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { isBoolean } from 'underscore';

import TemplateTabs, { UserGeneratedEmpty } from 'blocks/TemplateTabs';
import { TemplateTabsProps } from 'blocks/TemplateTabs/components/TemplateTabs';
import { AspectRatio, Refresh } from 'components/icons';

import Tooltip from 'components/Tooltip';
import { workflowTemplateIdsSelector } from 'redux/modules/automation-cms/selectors';
import { podcastWorkflowTemplatesSelector } from 'redux/modules/entities';
import { AspectRatioName, CompatibilityVideoType, TemplateType } from 'types';
import {
  BLANK_CANVAS_TEMPLATE_ID,
  TEMPLATE_TAB_KEY_ALL,
  TEMPLATE_TAB_KEY_REFRESH_TEMPLATE_LIST,
} from 'utils/constants';
import BlankProjectTile from './BlankProjectTile';
import TemplateSelectorTemplateTile, {
  TemplateSelectorTemplateTileProps,
} from './TemplateSelectorTemplateTile';
import { block } from './utils';

const { useCallback } = React;

export type VideoTemplateType = TemplateType | 'blank';

export interface VideoTemplateSelectorProps {
  aspectRatioName: AspectRatioName;
  transcriptionEnabled?: boolean;
  isProjectEditable?: boolean;
  compatibilityTypes?: CompatibilityVideoType[];
  onSelect?: TemplateSelectorTemplateTileProps['onClick'];
  onChangeAspectRatio: () => void;
  templateTypes?: VideoTemplateType[];
}

type TemplateIdsSelectorProps = Pick<
  VideoTemplateSelectorProps,
  | 'aspectRatioName'
  | 'compatibilityTypes'
  | 'templateTypes'
  | 'isProjectEditable'
  | 'transcriptionEnabled'
>;

interface Template {
  type: VideoTemplateType;
  value?: string;
}

const templateIdsSelector = createSelector(
  workflowTemplateIdsSelector,
  podcastWorkflowTemplatesSelector,
  (_, props: TemplateIdsSelectorProps) => props,
  (
    templateIds,
    templates,
    { aspectRatioName, templateTypes, isProjectEditable, transcriptionEnabled },
  ) => {
    if (!templateIds || !aspectRatioName) return undefined;

    const projectEditable = transcriptionEnabled ? true : isProjectEditable;

    return templateIds.get(aspectRatioName)?.filter(id => {
      const template = templates.get(id);

      if (!templateTypes.includes(template.get('templateType'))) {
        return false;
      }

      if (
        isBoolean(projectEditable) &&
        template.get('isProjectEditable') !== projectEditable
      ) {
        return false;
      }

      if (
        isBoolean(template.get('isCaptionEnabled')) &&
        transcriptionEnabled !== template.get('isCaptionEnabled')
      ) {
        return false;
      }

      return true;
    });
  },
);

const templateCompatibilitySelector = createSelector(
  workflowTemplateIdsSelector,
  podcastWorkflowTemplatesSelector,
  (_, props: TemplateIdsSelectorProps) => props,
  (templateIds, templates, { aspectRatioName, compatibilityTypes }) => {
    if (!templateIds || !aspectRatioName) return undefined;

    const compatibleTemplates = templateIds.get(aspectRatioName)?.filter(id => {
      const template = templates.get(id);

      const isCompatible = compatibilityTypes.find(type =>
        template.get('compatibleVideoTypes').includes(type),
      );

      if (!isCompatible) {
        return false;
      }

      return true;
    });

    return compatibleTemplates?.toArray();
  },
);

const templatesSelector = createSelector(
  podcastWorkflowTemplatesSelector,
  (_, templateIds) => templateIds,
  (templates, templateIds): Template[] => {
    if (!templates || !templateIds) return undefined;

    return templateIds
      .map(id => ({
        type: templates.getIn([id, 'templateType']),
        value: id,
      }))
      .toArray()
      .sort((t1, t2) => t2.type.localeCompare(t1.type));
  },
);

const VideoTemplateSelector: React.FC<VideoTemplateSelectorProps> = ({
  aspectRatioName,
  compatibilityTypes,
  onSelect,
  onChangeAspectRatio,
  templateTypes,
  isProjectEditable,
  transcriptionEnabled,
}) => {
  const [deletedTemplates, setDeletedTemplates] = React.useState<string[]>([]);

  const templateIds = useSelector(state =>
    templateIdsSelector(state, {
      aspectRatioName,
      templateTypes,
      isProjectEditable,
      transcriptionEnabled,
    }),
  );

  const compatibleTamplatesIds = useSelector(state =>
    templateCompatibilitySelector(state, {
      aspectRatioName,
      compatibilityTypes,
    }),
  );

  const templates = useSelector(state => templatesSelector(state, templateIds));

  const preMadeTemplates = templates?.filter(
    ({ type }) =>
      type === TemplateType.HEADLINER_DEFAULT || type === TemplateType.ORIGINAL,
  );

  const userTemplates = templates?.filter(
    ({ type, value }) =>
      type === TemplateType.USER_GENERATED && !deletedTemplates.includes(value),
  );

  const handleDeletedTemplates = (newDeletedTemplate: string): void => {
    setDeletedTemplates([...deletedTemplates, newDeletedTemplate]);
  };

  const getItems = (items: Template[]) =>
    items.map(item => {
      // If the template is not userGenerated and it is not included
      // in the compatibleTemplatesIds list, then it should not be rendered.
      // userGenerated templates should be rendered in all cases, if not compatible then a
      // messaged is displayed.
      // For non user generated templates, it should only be rendered if it is compatible.
      if (
        item.type !== 'userGenerated' &&
        !compatibleTamplatesIds.includes(item.value)
      ) {
        return null;
      }

      return (
        <TemplateSelectorTemplateTile
          onClick={onSelect}
          id={item.value}
          isCompatible={compatibleTamplatesIds.includes(item.value)}
          onDeleteClick={handleDeletedTemplates}
        />
      );
    });

  const getItemKey: TemplateTabsProps['getItemKey'] = useCallback(
    (index, items) => {
      const item = items[index];
      return item.props.id;
    },
    [],
  );

  const handleEmptyStateClick = useCallback((): void => {
    onSelect(BLANK_CANVAS_TEMPLATE_ID[aspectRatioName]);
  }, [aspectRatioName, onSelect]);

  return !templates ? null : (
    <Spacer orientation="vertical" align="center" space={2.5}>
      <Button
        className={block('change-aspect-ratio-button')}
        onPress={onChangeAspectRatio}
      >
        <AspectRatio /> Change aspect ratio
      </Button>

      <TemplateTabs
        aspectRatioName={aspectRatioName}
        contentItems={[
          {
            items: [
              templateTypes.includes('blank') && (
                <BlankProjectTile
                  aspectRatioName={aspectRatioName}
                  onClick={onSelect}
                  id={BLANK_CANVAS_TEMPLATE_ID[aspectRatioName]}
                  key="blank"
                />
              ),
              ...getItems(userTemplates.concat(preMadeTemplates)),
            ].filter(Boolean),
            key: TEMPLATE_TAB_KEY_ALL,
            title: 'all styles',
          },
          {
            emptyContent: (
              <UserGeneratedEmpty
                className={block('empty-state')}
                from="templateWizard"
                cta="createTemplate"
                onClick={handleEmptyStateClick}
              />
            ),
            items: getItems(userTemplates),
            key: TemplateType.USER_GENERATED,
            title: 'saved only',
          },
          {
            key: TEMPLATE_TAB_KEY_REFRESH_TEMPLATE_LIST,
            items: [],
            title: (
              <Tooltip content="Refresh template list" placement="top">
                <Refresh
                  width={50}
                  height={50}
                  className={block('refresh-icon')}
                />
              </Tooltip>
            ),
            titleClassName: block('refresh-template-list'),
          },
        ]}
        getItemKey={getItemKey}
        centered
      />
    </Spacer>
  );
};

export default VideoTemplateSelector;
