import { RequestStatus } from 'types';

import { EditAutomationActionTypes } from './action-types';
import { stateFactory } from './factories';
import { EditAutomationActions, EditAutomationState } from './types';

const initialState = stateFactory();

const editAutomationReducer = (
  state = initialState,
  action: EditAutomationActions,
): EditAutomationState => {
  switch (action.type) {
    case EditAutomationActionTypes.EDIT_AUTOMATION_UPDATE_REQUEST:
      return state.set('status', RequestStatus.REQUEST);

    case EditAutomationActionTypes.EDIT_AUTOMATION_UPDATE_FAILURE:
      return state.set('status', RequestStatus.FAILURE);

    case EditAutomationActionTypes.EDIT_AUTOMATION_UPDATE_SUCCESS:
      return state.set('status', RequestStatus.SUCCESS);

    case EditAutomationActionTypes.EDIT_AUTOMATION_INIT_AUDIO_SELECTION:
      return state.withMutations(s => {
        s.setIn(['audioSelection', 'duration'], action.payload.duration);
        s.setIn(['audioSelection', 'type'], action.payload.type);
        return s;
      });

    case EditAutomationActionTypes.EDIT_AUTOMATION_SET_AUDIO_SELECTION_DURATION:
      return state.setIn(
        ['audioSelection', 'duration'],
        action.payload.duration,
      );

    case EditAutomationActionTypes.EDIT_AUTOMATION_SET_AUDIO_SELECTION_TYPE:
      return state.setIn(['audioSelection', 'type'], action.payload.type);

    case EditAutomationActionTypes.EDIT_AUTOMATION_INIT_OUTPUT:
      return state.setIn(['output', 'option'], action.payload.option);

    case EditAutomationActionTypes.EDIT_AUTOMATION_SET_OUTPUT_OPTION:
      return state.setIn(['output', 'option'], action.payload.option);

    case EditAutomationActionTypes.EDIT_AUTOMATION_STARTING_POINT_CHANGE: {
      const { frequency } = action.payload;
      return state.set('frequency', frequency);
    }

    default:
      return state;
  }
};

export default editAutomationReducer;
