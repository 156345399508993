import { useContext, useEffect, useRef } from 'react';
import { addPlugin } from '../utils';
import WaveSurferContext from '../WaveSurferContext';
import ClipRegionPlugin, { RegionsPluginParams } from './ClipRegionPlugin';
import Region from './Region';

interface Props {
  start: number;
  end: number;
  color: string;
  onMaxLengthExceeded: () => void;
  onRegionUpdated(region: Region): void;
  minLength?: number;
  maxLength?: number;
}

function ClipRegion(props: Props) {
  const {
    start,
    end,
    color,
    onMaxLengthExceeded,
    onRegionUpdated,
    minLength,
    maxLength,
  } = props;
  const ws = useContext(WaveSurferContext);
  const initialConfig = useRef<RegionsPluginParams>({
    color,
    initialStart: start,
    initialEnd: end,
    minLength,
    maxLength,
  });
  const { isReady, regions } = ws ?? {};
  const region = regions?.getCurrentRegion();

  useEffect(() => {
    if (!ws) {
      return () => {};
    }

    const plugin = ClipRegionPlugin.create(initialConfig.current) as any;

    return addPlugin(ws, plugin);
  }, [ws]);

  useEffect(() => {
    const handler = ws?.on('region-updated', onRegionUpdated);

    return () => {
      handler?.un();
    };
  }, [onRegionUpdated, ws]);

  useEffect(() => {
    region?.on('max-length-exceeded', onMaxLengthExceeded);

    return () => {
      // Assigning handler to variable and calling "un" is not working fine
      region?.un('max-length-exceeded', onMaxLengthExceeded);
    };
  }, [onMaxLengthExceeded, region]);

  useEffect(() => {
    if (!isReady || !region) {
      return;
    }

    // There are three update cases
    // Case 1: maxLength is updated alongside the clip selected region. This is the case
    // when a preset is selected.
    // Case 2: only clip region selection is updated. This happens mostly at initial load or
    // because of a specific use case
    // Case 3: only max length is updated. This happens when a preset is deselected restoring
    // the maxLength to the default value.
    if (
      maxLength !== region.maxLength &&
      (region.start !== start || region.end !== end)
    ) {
      region.update({ maxLength, start, end });
    } else if (region.start !== start || region.end !== end) {
      region.update({ start, end });
    } else if (maxLength !== region.maxLength) {
      region.update({ maxLength });
    }
  }, [start, end, region, isReady, maxLength]);

  // adjust playhead position when clip start changes
  useEffect(() => {
    if (isReady) {
      ws.setCurrentTime(start);
    }
  }, [start, ws, isReady]);

  return null;
}

export default ClipRegion;
