import React from 'react';

import { formatDurationMillis } from 'utils/time';
import { block } from './utils';

export interface ClipTimecodesProps {
  clipEndMillis: number;
  clipStartMillis: number;
}

function formatDuration(value: number) {
  return formatDurationMillis(value, {
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
    trim: true,
  });
}

const ClipTimecodes: React.FC<ClipTimecodesProps> = ({
  clipEndMillis,
  clipStartMillis,
}) => (
  <span className={block('timecodes')}>
    {formatDuration(clipStartMillis)} - {formatDuration(clipEndMillis)}
  </span>
);

export default ClipTimecodes;
