import * as React from 'react';

import { useAddAssetModal } from 'containers/ConnectedModal/useAddAssetModal';
import AddWaveformModalContents from './AddWaveformModalContents';
import WaveformModal from './WaveformModal';

export type AddWaveformModalProps = {};

const AddWaveformModal: React.FC<AddWaveformModalProps> = () => {
  const { show, onExited, onHide } = useAddAssetModal('AddWaveformModal');

  return (
    <WaveformModal {...{ onExited, onHide, show }}>
      <AddWaveformModalContents onHide={onHide} />
    </WaveformModal>
  );
};

export default AddWaveformModal;
