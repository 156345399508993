import React from 'react';
import { ModalFooterButton } from 'components/Modal';
import PermissionNotGrantedMessage from 'components/PermissionNotGrantedMessage';
import { Step } from 'components/SteppedModal';
import useTikTokAuthenticator from 'hooks/useTikTokAuthenticator';

export interface UseAccessDeniedStepConfig extends Pick<Step, 'title'> {
  onError?: (message: string) => void;
  onReviseAccess?: () => void;
}

export default function useAccessDeniedStep({
  onError,
  onReviseAccess,
  title = 'we need permission to upload',
}: UseAccessDeniedStepConfig): Step {
  const { authenticating, withAuthentication } = useTikTokAuthenticator({
    onError: error => onError?.(error.message),
    force: true,
  });

  return {
    title,
    component: <PermissionNotGrantedMessage />,
    id: 'access-denied',
    renderFooterButtons: ({ cancel, submit }) => [
      <ModalFooterButton {...cancel} key="cancel">
        cancel
      </ModalFooterButton>,
      <ModalFooterButton
        {...submit}
        disabled={authenticating}
        key="submit"
        onClick={withAuthentication(onReviseAccess)}
        theme="submit"
      >
        revise options
      </ModalFooterButton>,
    ],
  };
}
