import { Button, Eyedrop } from '@sparemin/blockhead';
import cn from 'classnames';
import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { RootCloseWrapper } from 'react-overlays';
import _, { isEqual, isNumber } from 'underscore';

import { rgbObjectToString } from 'utils/color';
import { callRender } from 'utils/react';
import { prefix } from 'utils/ui';
import ColorPickerContainer, {
  ColorPickerContainerProps,
} from './ColorPickerContainer';
import Swatch, { SwatchProps } from './Swatch';
import { block, fillPresetColors } from './utils';

const DEFAULT_BACKGROUND_COLOR = 'rgba(0, 0, 0, 1)';
// Predetermined color picker height. If the EyeDropper is available, add its height.
const EXPECTED_COLOR_PICKER_HEIGHT = 313 + ('EyeDropper' in window ? 52 : 0);

type PickedContainerProps = Pick<
  ColorPickerContainerProps,
  'className' | 'componentClass'
>;

interface PopoverPosition {
  top?: string | number;
  right?: string | number;
  bottom?: string | number;
  left?: string | number;
}

interface IProps extends PickedContainerProps {
  color?: string;
  defaultDisplayColorPicker?: boolean;
  displayColorPicker?: boolean;
  disableAlpha?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (color: string) => void;
  onChangeComplete?: (color: string) => void;
  onClose?: () => void;
  onOpen?: () => void;
  onSwatchClick?: () => void;
  popoverClassName?: string;
  popoverPositioner?: (
    currTop: number,
    swatchRect: DOMRect,
    popoverHeight: number,
    exceedsViewportHeight: boolean,
  ) => PopoverPosition;
  popoverStyle?: React.CSSProperties;
  renderSwatch?: React.ComponentType<SwatchProps>;
  rootCloseEvent?: 'click' | 'mousedown';
  swatchClassName?: string;
  presetColors?: string[];
  shouldOnlyRenderPicker?: boolean;
}

interface IUiState {
  displayColorPicker?: boolean;
  color: string;
  popoverOffsetTop: number;
}

interface IState {
  ui: RecordOf<IUiState>;
}

const uiFactory = Record<IUiState>({
  color: undefined,
  displayColorPicker: undefined,
  popoverOffsetTop: undefined,
});

export default class ColorPicker extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    color: DEFAULT_BACKGROUND_COLOR,
    defaultDisplayColorPicker: false,
    disabled: false,
    onChange: _.noop,
    onChangeComplete: _.noop,
    onClose: _.noop,
    onOpen: _.noop,
    onSwatchClick: _.noop,
    popoverStyle: {},
    rootCloseEvent: 'click',
  };

  private static getPopoverOffsetTop(swatchElem) {
    if (!swatchElem) {
      return undefined;
    }
    const swatchRect = swatchElem.getBoundingClientRect();
    const viewportHeight = document.documentElement.clientHeight;

    let popoverTop = swatchRect.top + swatchRect.height;
    const popoverBottom = popoverTop + EXPECTED_COLOR_PICKER_HEIGHT;

    if (popoverBottom > viewportHeight) {
      // if it seems like the color picker is going to
      // overflow through the bottom of the viewport, adjust it
      popoverTop -= popoverBottom - viewportHeight;
    }

    return popoverTop;
  }

  private getPopoverPosition = (swatchElem: HTMLElement): PopoverPosition => {
    const { popoverPositioner } = this.props;
    const { ui } = this.state;

    const { popoverOffsetTop } = ui;

    if (!popoverPositioner) {
      return {
        top: `${popoverOffsetTop}px`,
      };
    }

    if (!swatchElem) {
      return {};
    }

    const swatchRect = swatchElem.getBoundingClientRect();
    const viewportHeight = document.documentElement.clientHeight;

    const popoverTop = swatchRect.top + swatchRect.height;
    const popoverBottom = popoverTop + EXPECTED_COLOR_PICKER_HEIGHT;
    const exceedsViewportHeight = popoverBottom > viewportHeight;

    return popoverPositioner(
      popoverTop,
      swatchRect,
      EXPECTED_COLOR_PICKER_HEIGHT,
      exceedsViewportHeight,
    );
  };

  private swatchDiv: HTMLElement;

  constructor(props) {
    super(props);

    const { color, defaultDisplayColorPicker, displayColorPicker } = props;

    this.state = {
      ui: uiFactory({
        color,
        displayColorPicker: this.managesColorPickerDisplay()
          ? defaultDisplayColorPicker
          : displayColorPicker,
        popoverOffsetTop: undefined,
      }),
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const { color: currColor } = this.props;
    const { color } = nextProps;

    if (!isEqual(nextProps.color, currColor)) {
      this.setColor(color);
    }
  }

  public componentDidUpdate(prevProps, prevState) {
    const prevIsOpen = this.displayColorPicker(prevProps, prevState);
    const isOpen = this.displayColorPicker();

    if (!prevIsOpen && isOpen) {
      this.setState(({ ui }) => ({
        ui: ui.set(
          'popoverOffsetTop',
          ColorPicker.getPopoverOffsetTop(this.swatchDiv),
        ),
      }));
    } else if (prevIsOpen && !isOpen) {
      this.setState(({ ui }) => ({
        ui: ui.set('popoverOffsetTop', undefined),
      }));
    }
  }

  private handleSwatchClick = () => {
    const { onOpen, onSwatchClick } = this.props;
    onSwatchClick();
    onOpen();
    this.setColorPickerDisplay(() => true);
  };

  private handleColorClose = () => {
    const { onClose } = this.props;
    onClose();
    this.setColorPickerDisplay(false);
  };

  private isSwatchDisabled = (color: ColorResult) => {
    const { disableAlpha } = this.props;
    return disableAlpha && isNumber(color.rgb.a) && color.rgb.a !== 1;
  };

  private handleColorChange = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { onChange } = this.props;

    if (this.isSwatchDisabled(color)) {
      event.preventDefault();
      return;
    }

    const rgbColor = rgbObjectToString(color.rgb);
    onChange(rgbColor);
    this.setColor(rgbColor);
  };

  private handleColorChangeComplete = (color: ColorResult) => {
    const { onChangeComplete } = this.props;

    if (this.isSwatchDisabled(color)) {
      return;
    }

    const rgbColor = rgbObjectToString(color.rgb);
    onChangeComplete(rgbColor);
    this.setColor(rgbColor);
  };

  private handleEyeDropperColorPick = async () => {
    if (!('EyeDropper' in window)) return;

    try {
      const eyeDropper = new window.EyeDropper();
      const { sRGBHex } = await eyeDropper.open();

      this.props.onChangeComplete(sRGBHex);
      this.setColor(sRGBHex);
    } catch (err) {
      throw new Error(err);
    }
  };

  private handleSwatchHover = (color: ColorResult, event: MouseEvent) => {
    // This is a not ideal workaround to disable swatch when alpha channel
    // disabled and the hovered swatch value has alpha channel set.
    if (this.isSwatchDisabled(color)) {
      (event.target as HTMLDivElement).style.cursor = 'not-allowed';
    }
  };

  private managesColorPickerDisplay() {
    const { displayColorPicker } = this.props;
    return _.isUndefined(displayColorPicker);
  }

  private setColorPickerDisplay(
    visible: boolean | ((current: boolean) => boolean),
  ) {
    if (!this.managesColorPickerDisplay()) return;

    this.setState(({ ui }) => ({
      ui: ui.update('displayColorPicker', current =>
        _.isBoolean(visible) ? visible : visible(current),
      ),
    }));
  }

  private displayColorPicker(props = this.props, state = this.state) {
    const { displayColorPicker } = props;
    const { ui } = state;

    return this.managesColorPickerDisplay()
      ? ui.displayColorPicker
      : displayColorPicker;
  }

  private setColor(color: string) {
    this.setState(({ ui }) => ({
      ui: ui.set('color', color),
    }));
  }

  private setSwatchElement = (el: HTMLElement) => {
    this.swatchDiv = el;
  };

  public render() {
    const {
      className,
      componentClass,
      disabled,
      disableAlpha,
      popoverClassName,
      popoverStyle: popoverStyleProp,
      renderSwatch,
      rootCloseEvent,
      swatchClassName,
      presetColors,
      shouldOnlyRenderPicker,
    } = this.props;

    const { ui } = this.state;

    const { popoverOffsetTop } = ui;
    const isOpen = this.displayColorPicker();

    // const popoverOffsetTop = ColorPicker.getPopoverOffsetTop(this.swatchDiv);
    const popoverStyle: React.CSSProperties = {
      position: popoverOffsetTop ? 'fixed' : 'relative',
    };

    // if offset cannot be calculated, fallback to relative behavior
    // it should never reach here
    if (popoverStyle) {
      const popoverPosition = this.getPopoverPosition(this.swatchDiv);
      popoverStyle.top = popoverPosition.top;
      popoverStyle.right = popoverPosition.right;
      popoverStyle.bottom = popoverPosition.bottom;
      popoverStyle.left = popoverPosition.left;
    }

    const swatchProps = {
      className: swatchClassName,
      color: ui.color,
      domRef: this.setSwatchElement,
      onClick: !disabled ? this.handleSwatchClick : undefined,
    };

    if (shouldOnlyRenderPicker) {
      return (
        <SketchPicker
          color={ui.color}
          disableAlpha={disableAlpha}
          onChange={this.handleColorChange}
          onChangeComplete={this.handleColorChangeComplete}
          presetColors={fillPresetColors(presetColors)}
          onSwatchHover={this.handleSwatchHover}
        />
      );
    }

    return (
      <ColorPickerContainer
        componentClass={componentClass}
        className={className}
      >
        {renderSwatch ? (
          callRender(renderSwatch, swatchProps)
        ) : (
          <Swatch {...swatchProps} />
        )}
        {!isOpen || popoverOffsetTop === undefined ? null : (
          <RootCloseWrapper
            disabled={!isOpen}
            onRootClose={this.handleColorClose}
            event={rootCloseEvent}
          >
            <div
              className={cn(block('popover'), popoverClassName)}
              style={prefix({
                ...popoverStyle,
                ...popoverStyleProp,
              })}
            >
              <SketchPicker
                color={ui.color}
                disableAlpha={disableAlpha}
                onChange={this.handleColorChange}
                onChangeComplete={this.handleColorChangeComplete}
                presetColors={fillPresetColors(presetColors)}
                onSwatchHover={this.handleSwatchHover}
                className={block('sketch-picker')}
              />

              {'EyeDropper' in window && (
                <div className={block('sample-color-button-container')}>
                  <Button
                    fluid
                    size="small"
                    startIcon={<Eyedrop width={12} height={12} />}
                    className={block('sample-color-button')}
                    onPress={this.handleEyeDropperColorPick}
                  >
                    sample color
                  </Button>
                </div>
              )}
            </div>
          </RootCloseWrapper>
        )}
      </ColorPickerContainer>
    );
  }
}

export { IProps as ColorPickerProps };
