import { createSelector } from 'reselect';

import { PodcastWorkflowTemplate } from 'redux/middleware/api/podcast-service/types';
import { podcastWorkflowTemplatesSelector } from '../entities/selectors';
import { entireAudioEnhancer } from './enhancers';
import { TemplateWizardState } from './types';

const stateSelector = (state): TemplateWizardState =>
  state.get('templateWizard');

export const templateIdSelector = createSelector(
  stateSelector,
  state => state.templateId,
);

export const templateSelector = createSelector(
  templateIdSelector,
  podcastWorkflowTemplatesSelector,
  (templateId, templates): PodcastWorkflowTemplate =>
    templates?.get(templateId)?.toJS(),
);

export const {
  entireAudioInstanceIdSelector,
  uploadStatusSelector,
  uploadProgressSelector,
} = entireAudioEnhancer.selectors;
