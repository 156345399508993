import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
// using default import to avoid circular dependency.  importing from 'blocks/Pricing' causes issues
import ProFeatureTooltipContent from 'blocks/Pricing/ProFeatureTooltipContent';
import Tooltip from 'components/Tooltip';
import { onClickProFeature } from 'redux/modules/mixpanel/actions';
import { createChainedFunction } from 'utils/functions';
import { getMyPlanPath } from 'utils/routes';
import { ProIndicatorOverlayProps } from './types';

const ProIndicatorOverlay: React.FC<ProIndicatorOverlayProps> = props => {
  const { from, tooltipPlacement = 'top', onClick } = props;

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(onClickProFeature({ from }));
  };

  const content = ProFeatureTooltipContent;

  return (
    <Tooltip
      animation={false}
      content={content}
      id={from}
      placement={tooltipPlacement}
      preventHideOnHover={false}
    >
      <Link
        to={getMyPlanPath()}
        onClick={createChainedFunction(onClick, handleClick)}
        className="stretch"
      >
        <span className="visually-hidden">{content}</span>
      </Link>
    </Tooltip>
  );
};

export default ProIndicatorOverlay;
