import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  performDpa,
  sendDownloadPageActionEvent,
} from 'redux/modules/download';
import { onClickTweetVideo } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';

export default function useTwitterPostButton() {
  const dispatch = useDispatch<Dispatch>();

  const onClick = useCallback(() => {
    dispatch(performDpa);
    dispatch(onClickTweetVideo());
    dispatch(sendDownloadPageActionEvent());
  }, [dispatch]);

  return { onClick };
}
