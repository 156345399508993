import * as React from 'react';

import { H2 } from 'components/Heading';
import LinkButton from 'components/LinkButton';
import { block } from './utils';

const FreeSubscriptionFooter: React.SFC = () => (
  <div className={block('footer')}>
    <H2>
      Education and nonprofit organizations get some paid features for free:{' '}
      <LinkButton
        href="https://forms.gle/Cpd8h6wgYfKcdT9GA"
        theme="cta"
        rel="noopener noreferrer"
        target="_blank"
      >
        apply here
      </LinkButton>
    </H2>
  </div>
);

export default FreeSubscriptionFooter;
