import * as React from 'react';
import { Link } from 'react-router-dom';
import { noop } from 'underscore';

import Button from 'components/Button';
import FreeTrialPopover from 'components/FreeTrialPopover';
import { SubTier, Tier } from 'redux/middleware/api/plan-service';
import NavIcon from '../../components/NavIcon';
import { block } from '../../utils';

interface IProps {
  onClickHeaderBadge?: (subtier: SubTier) => void;
  pricingTier?: Tier;
  pricingSubTier?: SubTier;
  planEndSec?: number;
  onLearnMoreClick?: () => void;
  onExtendClick?: () => void;
}

export default class PricingTierButton extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onClickHeaderBadge: noop,
    pricingTier: undefined,
  };

  private shouldRender() {
    const { pricingTier } = this.props;
    return pricingTier !== undefined;
  }

  private renderButton() {
    const { pricingSubTier, pricingTier } = this.props;

    switch (pricingTier) {
      case Tier.FREE:
        return (
          <Button className={block('pricing-tier-button')} theme="rainbow">
            <span>Get Unlimited Videos</span>
          </Button>
        );

      case Tier.BASIC:
        return (
          <Button
            className={block('pricing-tier-button', { basic: true })}
            theme="confirm-alt"
          >
            basic
          </Button>
        );

      case Tier.PRO:
        return (
          <Button
            className={block('pricing-tier-button', { pro: true })}
            theme="submit-alt"
          >
            <span>
              {pricingSubTier === SubTier.PRO_TRIAL ? (
                <>
                  Pro{' '}
                  <span className={block('text-understate')}>free trial</span>
                </>
              ) : (
                'Pro'
              )}
            </span>
          </Button>
        );

      case Tier.EDUCATION_NONPROFIT:
        return (
          <Button
            className={block('pricing-tier-button', { edu: true })}
            theme="orange-alt"
          >
            EDU / NPO
          </Button>
        );

      case Tier.UNLIMITED:
        return (
          <Button
            className={block('pricing-tier-button', { unlimited: true })}
            theme="rainbow-bordered"
          >
            <span>unlimited</span>
          </Button>
        );

      default:
        return undefined;
    }
  }

  private handleClickBadge = () => {
    const { onClickHeaderBadge, pricingSubTier } = this.props;

    onClickHeaderBadge(pricingSubTier);
  };

  public render() {
    const {
      pricingSubTier,
      planEndSec,
      onExtendClick,
      onLearnMoreClick,
    } = this.props;

    return (
      this.shouldRender() && (
        <div className={block('pricing-tier')}>
          <NavIcon className={block('pricing-tier-icon')}>
            <FreeTrialPopover
              {...{ onExtendClick, onLearnMoreClick }}
              endSec={planEndSec}
              pricingTier={pricingSubTier}
            >
              <Link
                to="/settings/my-plan"
                className={block('pricing-tier-link')}
                onClick={this.handleClickBadge}
              >
                {this.renderButton()}
              </Link>
            </FreeTrialPopover>
          </NavIcon>
        </div>
      )
    );
  }
}

export { IProps as PricingTierButtonProps };
