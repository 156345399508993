import { PurchasePlatform } from 'types';
import bem from 'utils/bem';

const MOBILE_SUBSCRIPTION_PLATFORMS: PurchasePlatform[] = [
  'itunesAppStore',
  'playStore',
];

export const block = bem('payment-details-gate');

export const isMobileManagedSubscription = (
  subscriptionPurchasePlaform: PurchasePlatform,
): boolean => {
  return MOBILE_SUBSCRIPTION_PLATFORMS.includes(subscriptionPurchasePlaform);
};
