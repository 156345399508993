import * as React from 'react';

import { CircularGradientVideoCamera } from 'components/icons/circular-gradient';

import CardWizardStep, {
  CardWizardControlButton,
  CardWizardControls,
} from '../../components/CardWizard/CardWizardStep';
import { welcomeBlock as block } from '../../utils';
import { WelcomeStepProps } from './types';

const DefaultWelcomeStep: React.FC<WelcomeStepProps> = ({
  onNextClick,
  title,
}) => {
  return (
    <CardWizardStep className={block()}>
      <div className={block('icon')}>
        <CircularGradientVideoCamera />
      </div>
      <div className={block('message')}>
        <CardWizardStep.H1>{title}</CardWizardStep.H1>
        <CardWizardStep.H2>
          The next few questions will help us make videos you’ll love.
        </CardWizardStep.H2>
      </div>
      <CardWizardControls className={block('controls')}>
        <CardWizardControlButton onClick={() => onNextClick()}>
          next
        </CardWizardControlButton>
      </CardWizardControls>
    </CardWizardStep>
  );
};

export default DefaultWelcomeStep;
