import { connect } from 'react-redux';

import { saveCanvasColor, setCanvasColor } from 'redux/modules/embed/actions';
import { canvasColorSelector } from 'redux/modules/embed/selectors';
import { onChangeCanvasColor } from 'redux/modules/mixpanel';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import { DEFAULT_MEDIA_CONTAINER_OPTIONS } from 'utils/embed/media-container';
import { createChainedFunction } from 'utils/functions';
import BackgroundColorTile, {
  BackgroundColorTileProps as Props,
} from './BackgroundColorTile';

type DispatchProps = Pick<Props, 'onChange' | 'onClose'>;
type StateProps = Pick<Props, 'color'>;
type OwnProps = Pick<Props, 'onClose'>;

const mapStateToProps = (state: State): StateProps => ({
  color:
    canvasColorSelector(state) ||
    DEFAULT_MEDIA_CONTAINER_OPTIONS.style.backgroundColor,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onChange: color => dispatch(setCanvasColor(color)),
  onClose: createChainedFunction(() => {
    dispatch(saveCanvasColor());
    dispatch(onChangeCanvasColor());
  }, props.onClose),
});

const component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BackgroundColorTile);
export type BackgroundColorTileProps = PropsOf<typeof component>;
export default component;
