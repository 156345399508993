import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AutogramWizardIntent } from 'blocks/AutomationWorkflowWizard/types';
import LoadingStep from 'containers/ProjectWizards/LoadingStep';
import { loadMyWorkflows } from 'redux/modules/automation-cms';
import {
  isWorkflowLimitReachedSelector,
  workflowIdsSelector,
} from 'redux/modules/automation-cms/selectors';
import { pushModal } from 'redux/modules/modal/actions';
import { isFreeSelector } from 'redux/modules/pricing';
import { Dispatch, ThunkAction } from 'redux/types';
import { getProjectsPath } from 'utils/routes';
import { wizardBlock as block } from '../../utils';

const checkWorkflowLimit = (): ThunkAction<Promise<boolean>> => async (
  dispatch,
  getState,
) => {
  const workflowIds = workflowIdsSelector(getState());
  const isFree = isFreeSelector(getState());

  if (isFree && !Array.isArray(workflowIds)) {
    await dispatch(loadMyWorkflows());
  }

  return isWorkflowLimitReachedSelector(getState());
};

interface Props {
  children: React.ReactNode;
  intent: AutogramWizardIntent;
}

const WorkflowLimitGuard = (props: Props) => {
  const { children, intent } = props;
  const workflowIds = useSelector(workflowIdsSelector);
  const isFree = useSelector(isFreeSelector);
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const [isLoading, setLoading] = useState(
    isFree && !Array.isArray(workflowIds),
  );

  useEffect(() => {
    if (intent === 'create') {
      dispatch(checkWorkflowLimit())
        .then(isWorkflowLimitReached => {
          if (isWorkflowLimitReached) {
            history.push(getProjectsPath());
            dispatch(
              pushModal({
                name: 'AddAutomationUpgrade',
                params: { podcastId: null },
              }),
            );
          } else {
            setLoading(false);
          }
        })
        .catch(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [dispatch, history, intent]);

  return isLoading ? (
    <LoadingStep className={block('loader')} message="Loading" />
  ) : (
    <>{children}</>
  );
};

export default WorkflowLimitGuard;
