import { isUndefined } from 'underscore';

import bem from 'utils/bem';
import { PodcastSearchBody } from './types';

export const block = bem('podcast-search');
export const podcastListBlock = bem('podcast-list');
export const episodeExplorerBlock = bem('episode-explorer');

export function getBodyType(
  isPodcastActive: boolean,
  isLoadingPodcasts: boolean,
  podcastResultCount: number | undefined,
  episodeExplorerEnabled: boolean,
): PodcastSearchBody {
  if (episodeExplorerEnabled && isPodcastActive) {
    return 'episode';
  }

  if (isLoadingPodcasts && isUndefined(podcastResultCount)) {
    return 'spinner';
  }

  if (!isLoadingPodcasts && podcastResultCount === 0) {
    return 'no-results';
  }

  if (podcastResultCount > 0) {
    return 'podcast';
  }

  return 'default';
}
