import React from 'react';

// types
import { IconProps } from './types';

const HeadlinerMarkColor: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1280px"
    height="1280px"
    viewBox="0 0 1280 1280"
    version="1.1"
    {...props}
  >
    <title>Headliner.Logo.2021.Mark.GradientForDarkBackgrounds</title>
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="linearGradient-1">
        <stop stopColor="#5DBEFC" offset="0%" />
        <stop stopColor="#DD92FE" offset="100%" />
      </linearGradient>
    </defs>
    <g
      id="Headliner.Logo.2021.Mark.GradientForDarkBackgrounds"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M1279.69,639.85 C1279.69,993.23 993.22,1279.7 639.84,1279.7 C286.47,1279.7 0,993.22 0,639.85 C0,286.48 286.47,0 639.85,0 C993.22,0 1279.69,286.47 1279.69,639.85 Z M701.16,706.24 L701.16,790.49 C701.16,848.24 747.98,895.06 805.73,895.06 C863.48,895.06 910.3,848.24 910.3,790.49 L910.3,412.65 C910.3,354.9 863.48,308.08 805.73,308.08 C747.98,308.08 701.16,354.9 701.16,412.65 L701.16,634.66 L578.53,634.66 L578.53,412.65 C578.53,354.9 531.71,308.08 473.96,308.08 C416.21,308.08 369.4,354.89 369.4,412.65 L369.4,790.49 C369.4,848.24 416.22,895.06 473.97,895.06 C531.72,895.06 578.54,848.24 578.54,790.49 L578.54,706.24 L701.16,706.24 Z M910.43,971.36 L910.32,971.36 M910.49,967.5 L910.38,967.5 M910.26,967.43 C902.8,967.43 895.21,969.82 888.77,974.61 L888.78,974.6 C816.4,1028.25 731,1056.51 645.8,1056.51 C600.74,1056.51 555.73,1049.91 512.66,1036.39 C469.58,1022.85 428.45,1002.41 390.92,974.6 C384.5,969.83 376.92,967.49 369.45,967.49 L369.37,967.49 C358.36,967.49 347.42,972.5 340.36,982.06 C335.6,988.47 333.26,996.05 333.26,1003.55 C333.26,1014.58 338.34,1025.51 347.88,1032.59 C392.12,1065.39 440.61,1089.45 491.01,1105.27 C541.41,1121.1 593.71,1128.71 645.81,1128.71 C746.99,1128.71 847.36,1095.2 931.83,1032.59 C941.37,1025.51 946.45,1014.58 946.45,1003.55 C946.45,996.07 944.12,988.49 939.34,982.05 C932.28,972.52 921.35,967.43 910.33,967.43 L910.26,967.43 L910.12,967.43"
        id="Shape"
        fill="url(#linearGradient-1)"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default HeadlinerMarkColor;
