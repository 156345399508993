import * as React from 'react';

import { SelectField, SelectFieldProps } from 'components/Form';
import { ProgressSizeId, ProgressSizeOption } from 'types';

interface IProps
  extends Omit<SelectFieldProps, 'label' | 'onChange' | 'value'> {
  onChange?: (value: ProgressSizeId) => void;
  value: ProgressSizeId;
}

export const options: ProgressSizeOption[] = [
  {
    value: 'small',
    displayName: 'Small',
  },
  {
    value: 'medium',
    displayName: 'Medium',
  },
  {
    value: 'large',
    displayName: 'Large',
  },
  {
    value: 'custom',
    displayName: 'Custom',
  },
];

const ProgressSizeField: React.FC<IProps> = props => {
  const { disabled, onChange, value } = props;

  return (
    <SelectField
      disabled={disabled}
      horizontal={{
        control: { xs: 7 },
        label: { xs: 5 },
      }}
      label="Size"
      onChange={onChange}
      value={value}
    >
      {options.map(option => (
        <option
          key={option.value}
          value={option.value}
          hidden={option.value === 'custom'}
          disabled={option.value === 'custom'}
        >
          {option.displayName}
        </option>
      ))}
    </SelectField>
  );
};

export default ProgressSizeField;
