import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { compose } from 'underscore';

import { userIsAuthenticated } from 'components/hoc/auth';
import { tokenLogin } from 'redux/modules/auth/actions';
import {
  authInProgressSelector,
  loggedInSelector,
  tokenSelector,
} from 'redux/modules/auth/selectors';
import { Dispatch, State } from 'redux/types';
import { Omit } from 'types';
import { ACCESS_TOKEN_NAME, getAccessToken } from 'utils/routes';
import { QueryStringAuthenticatorProps } from './QueryStringAuthenticator';
import RedirectWithoutToken from './RedirectWithoutToken';
import withQueryStringAuth from './withQueryStringAuth';

export interface Config {
  requireAuth?: boolean;
}

const DEFAULT_CONFIG = {
  requireAuth: true,
};

export default function<Props>(
  Component: React.ComponentType<Props>,
  config?: Config,
) {
  const { requireAuth } = {
    ...DEFAULT_CONFIG,
    ...config,
  };
  type DispatchProps = Pick<QueryStringAuthenticatorProps, 'authenticate'>;
  type StateProps = Pick<
    QueryStringAuthenticatorProps,
    'isAuthenticated' | 'isAuthenticating'
  >;
  type OwnProps = QueryStringAuthenticatorProps & Props;

  const queryTokenSelector = (_, ownProps: OwnProps) =>
    getAccessToken(ownProps, ACCESS_TOKEN_NAME);

  const isAuthenticatedSelector = createSelector(
    [queryTokenSelector, tokenSelector, loggedInSelector],
    (queryToken, reduxToken, loggedIn) => queryToken === reduxToken && loggedIn,
  );

  const isAuthenticatingSelector = createSelector(
    [queryTokenSelector, tokenSelector, authInProgressSelector],
    (queryToken, reduxToken, authInProgress) =>
      queryToken !== reduxToken || authInProgress,
  );

  const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    isAuthenticated: isAuthenticatedSelector(state, ownProps),
    isAuthenticating: isAuthenticatingSelector(state, ownProps),
  });

  const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    authenticate: async token => {
      if (!token) return;

      try {
        dispatch(tokenLogin(token));
      } catch {
        /*
         * swallow error.  it's handled outside of this fn and will just produce an "unhandled
         * promise rejection"
         */
      }
    },
  });

  type FinalProps = Omit<
    Props & OwnProps & QueryStringAuthenticatorProps,
    keyof StateProps | keyof DispatchProps
  >;
  type Enhancer = (
    component: React.ComponentType<Props>,
  ) => React.ComponentClass<FinalProps>;

  const enhance = compose(
    ...[
      connect(mapStateToProps, mapDispatchToProps),
      withQueryStringAuth({
        accessTokenName: ACCESS_TOKEN_NAME,
        AuthenticatingComponent: () => null,
        FailureComponent: RedirectWithoutToken,
      }),
      // TODO need types for userIsAuthenticated
      requireAuth &&
        (userIsAuthenticated as (arg: any) => React.ComponentType<any>),
    ].filter(Boolean),
  ) as Enhancer;
  return enhance(Component);
}
