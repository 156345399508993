import * as React from 'react';

import { Alert } from 'components/icons';
import { grey2 } from 'utils/colors';
import ChildViewCard from './ChildViewCard';
import { ustPaneBlock as block } from './utils';

export interface USTMessageProps {}

const USTMessage: React.FC = () => (
  <ChildViewCard
    icon={
      <Alert
        fill={grey2}
        background="transparent"
        height={40}
        className={block('icon')}
      />
    }
  >
    This template is too complex to
    <br />
    adjust here, please open it in the
    <br />
    Advanced Editor to make changes.
  </ChildViewCard>
);

export default USTMessage;
