import * as Immutable from 'immutable';
import _ from 'underscore';

import { round, scale } from '../numbers';

/**
 * text shadow inputs are whole numbers.  the whole number values are used in css rules along with
 * the em unit.  the input should be multiplied by this value before applying to text via css rules
 */
export const TEXT_SHADOW_EM_MULTIPLIER = 0.04;

export const GRID_SIZE = Immutable.Map({
  width: 1920,
  height: 1080,
});

export function scaleToGrid(x, y, width, height) {
  const gridX = scale(x, width, GRID_SIZE.get('width'));
  const gridY = scale(y, height, GRID_SIZE.get('height'));

  return {
    x: gridX && round(gridX),
    y: gridY && round(gridY),
  };
}

export function scaleFromGrid(gridX, gridY, width, height) {
  const x = scale(gridX, GRID_SIZE.get('width'), width);
  const y = scale(gridY, GRID_SIZE.get('height'), height);
  return {
    ...(_.isUndefined(x) ? {} : { x }),
    ...(_.isUndefined(y) ? {} : { y }),
  };
}

export function scaleXToGrid(x, width) {
  const { x: gridX } = scaleToGrid(x, undefined, width, undefined);
  return gridX;
}

export function scaleXFromGrid(gridX, width) {
  const { x } = scaleFromGrid(gridX, undefined, width, undefined);
  return x;
}

export function scaleYToGrid(y, height) {
  const { y: gridY } = scaleToGrid(undefined, y, undefined, height);
  return gridY;
}

export function scaleYFromGrid(gridY, height) {
  const { y } = scaleFromGrid(undefined, gridY, undefined, height);
  return y;
}

// Given a value as a vh/vw value and a max size in pixels (eg. 30vh and 100 pixels),
// this function will return the size as a number scaled in pixels (returns 30).
export function scaleVhVwToPixel(valueInVhVw, maxPixels) {
  const numberVal = parseFloat(valueInVhVw);
  return scale(numberVal, 100, maxPixels);
}

// Given a pixel value, scale it to vh or vw
export function scalePixelToVhVw(pixelVal, maxPixels, suffix) {
  const val = scale(pixelVal, maxPixels, 100);
  return isNaN(val) ? undefined : `${val}${suffix}`;
}
