import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';
import Url from 'url-parse';
import { createRequest } from '../utils';

import * as types from './types';

const projectSchema = new schema.Entity(
  'projects',
  {},
  {
    idAttribute: project => project.projectUuid,
  },
);

const revisionHistorySchema = new schema.Entity(
  'revisionHistory',
  {},
  {
    idAttribute: revisionHistory => revisionHistory.embedConfigurationUuid,
  },
);

const createProject = (
  args: types.CreateProjectArgs,
  request: SuperAgentStatic,
): Promise<types.CreateProjectResult> => {
  const [
    embedConfigurationId,
    projectName,
    aspectRatio,
    createMethod,
    thumbnailUrl,
    copiedFromProjectId,
    traceId,
    customTraceId,
    createdFromTemplateId,
    createdFromTemplateType,
  ] = args;

  return request
    .post('/api/v1/video-project-mgmt/project/')
    .send({
      aspectRatio,
      copiedFromProjectId,
      createdFromTemplateId,
      createdFromTemplateType,
      createMethod,
      customTraceId,
      embedConfigurationId,
      projectName,
      thumbnailUrl,
      traceId,
    })
    .then(res => normalize(res.body, projectSchema));
};

const getProjectById = (
  args: types.GetProjectByIdArgs,
  request: SuperAgentStatic,
): Promise<types.GetProjectByIdResult> => {
  const [id] = args;

  return request
    .get(`/api/v1/video-project-mgmt/project/${id}`)
    .then(res => normalize(res.body, projectSchema));
};

const getAllProjects = (
  args: types.GetAllProjectsArgs,
  request: SuperAgentStatic,
): Promise<types.GetAllProjectsResult> => {
  const [ownerId, createMethod, page, size] = args;

  return request
    .get('/api/v1/video-project-mgmt/project/')
    .query({ ownerId, createMethod, page, size })
    .then(res => ({
      ...normalize(res.body.content, [projectSchema]),
      page: res.body.page,
    }));
};

const getProjectsByUrl = async (
  [url]: types.GetProjectsByUrlArgs,
  request: SuperAgentStatic,
): Promise<types.GetProjectsByUrlResult> => {
  const { pathname, query } = new Url(url);
  const {
    body: { content, page },
  } = await request.get(`${pathname}/${query}`);
  return {
    ...normalize(content, [projectSchema]),
    page,
  };
};

const updateProject = (
  args: types.UpdateProjectArgs,
  request: SuperAgentStatic,
): Promise<types.UpdateProjectResult> => {
  const [
    id,
    embedConfigurationId,
    projectName,
    aspectRatio,
    thumbnailUrl,
  ] = args;

  return request
    .put(`/api/v1/video-project-mgmt/project/${id}`)
    .send({ embedConfigurationId, projectName, thumbnailUrl, aspectRatio })
    .then(res => res.body);
};

const deleteProject = (
  args: types.DeleteProjectArgs,
  request: SuperAgentStatic,
): Promise<types.DeleteProjectResult> => {
  const [id] = args;

  return request
    .delete(`/api/v1/video-project-mgmt/project/${id}`)
    .then(res => res.body);
};

const getRevisionHistoryByProjectId = (
  args: types.GetRevisionHistoryByProjectIdArgs,
  request: SuperAgentStatic,
): Promise<types.GetRevisionHistoryByProjectIdResult> => {
  const [id, page, size] = args;

  return request
    .get(
      `/api/v1/video-project-mgmt/project/${id}/history?page=${page}&size=${size}`,
    )
    .then(res => ({
      ...normalize(res.body.content, [revisionHistorySchema]),
      page: res.body.page,
    }));
};

export const handle: types.IHandle = (
  method: types.ServiceMethod,
  args: any,
  token?: string,
): Promise<types.ServiceResults> => {
  const request = createRequest({
    token,
    baseUrl: spareminConfig.services.videoProjectManagement,
  });

  switch (method) {
    case types.ServiceMethod.CREATE_PROJECT:
      return createProject(args, request);

    case types.ServiceMethod.GET_ALL_PROJECTS:
      return getAllProjects(args, request);

    case types.ServiceMethod.GET_PROJECT_BY_ID:
      return getProjectById(args, request);

    case types.ServiceMethod.UPDATE_PROJECT:
      return updateProject(args, request);

    case types.ServiceMethod.DELETE_PROJECT:
      return deleteProject(args, request);

    case types.ServiceMethod.GET_REVISION_HISTORY_BY_PROJECT_ID:
      return getRevisionHistoryByProjectId(args, request);

    case types.ServiceMethod.GET_PROJECTS_BY_URL:
      return getProjectsByUrl(args, request);

    default:
      throw new Error(`${types.ACTION_KEY} cannot handle method ${method}`);
  }
};
