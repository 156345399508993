import { AlertCircle, Button, Spacer } from '@sparemin/blockhead';
import React from 'react';

import Modal from 'components/Modal/v2';

import { block } from './utils';

interface TextOverlaySwitchModalContentsProps {
  onHide?: ({ shouldSwitch: boolean }) => void;
}

const TextOverlaySwitchModalContents: React.FunctionComponent<TextOverlaySwitchModalContentsProps> = props => {
  const { onHide } = props;

  const handleCancel = React.useCallback(() => {
    onHide({ shouldSwitch: false });
  }, [onHide]);

  const handleContinue = React.useCallback(() => {
    onHide({ shouldSwitch: true });
  }, [onHide]);

  return (
    <div>
      <Modal.Header
        icon={<AlertCircle className={block('alert-icon')} />}
        onHide={handleCancel}
        subtitle="Each editor supports a different set of features, so by switching you may lose some existing styles in this text box forever."
        title="You may lose some text styles"
      />
      <Modal.Footer>
        <Spacer className={block('footer')} orientation="vertical" space={2}>
          <Button fluid onPress={handleContinue} variant="solid">
            switch editors
          </Button>
          <Button fluid onPress={handleCancel} variant="ghost">
            no thanks
          </Button>
        </Spacer>
      </Modal.Footer>
    </div>
  );
};

export default TextOverlaySwitchModalContents;
