import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isFinite, isNumber, noop } from 'underscore';
import { onTimeBankUpgrade } from 'redux/modules/mixpanel/actions';
import { replaceModal } from 'redux/modules/modal/actions';
import { transcriptionBalanceMillisSelector } from 'redux/modules/pricing';
import { TranscriptionTimeBankUpgradeSource } from 'types/common';
import usePreviousRef from './usePreviousRef';

export interface UseTranscriptionBalanceOptions {
  durationMillis?: number;
  onToggleTranscription?: (enabled: boolean) => void;
  transcriptionEnabled?: boolean;
  source?: TranscriptionTimeBankUpgradeSource;
}

export default function useTranscriptionBalance({
  durationMillis = 0,
  onToggleTranscription = noop,
  transcriptionEnabled = false,
  source,
}: UseTranscriptionBalanceOptions) {
  const dispatch = useDispatch();
  const balanceMillis = useSelector(transcriptionBalanceMillisSelector);
  const newBalanceMillis = balanceMillis - durationMillis;
  const hasBalance = isNumber(newBalanceMillis) && isFinite(newBalanceMillis);
  const canTranscribe = newBalanceMillis >= 0;

  const transcriptionEnabledRef = usePreviousRef(transcriptionEnabled);
  const onToggleTranscriptionRef = usePreviousRef(onToggleTranscription);
  const cachedTranscriptionEnabled = useRef<boolean>(undefined);

  useEffect(() => {
    if (!canTranscribe && transcriptionEnabledRef.current) {
      cachedTranscriptionEnabled.current = true;
      onToggleTranscriptionRef.current(false);
      return;
    }

    if (canTranscribe && cachedTranscriptionEnabled.current !== undefined) {
      onToggleTranscriptionRef.current(cachedTranscriptionEnabled.current);
      cachedTranscriptionEnabled.current = undefined;
    }
  }, [canTranscribe, onToggleTranscriptionRef, transcriptionEnabledRef]);

  const onClick = useCallback(
    event => {
      event.preventDefault();
      dispatch(onTimeBankUpgrade(source));
      dispatch(
        replaceModal({
          name: 'UnlimitedTranscription',
        }),
      );
    },
    [dispatch, source],
  );

  return {
    balanceMillis,
    canTranscribe,
    hasBalance,
    newBalanceMillis,
    outOfTimeMessage: 'Not enough free transcription time left this month',
    showBalance: hasBalance && (transcriptionEnabled || newBalanceMillis <= 0),
    onClick,
  };
}
