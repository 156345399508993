import * as React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { BetaIcon } from 'components/icons';
import { podcastSubscriptionsSelector } from 'redux/modules/entities/selectors';
import { episodeListBlock as block } from '../utils';
import EpisodeListRow from './EpisodeListRow';

export interface EpisodeListAutomationsRowDescriptionProps {
  workflows: number[];
}

const workflowIdsSelector = (
  _,
  { workflows }: EpisodeListAutomationsRowDescriptionProps,
) => workflows;

const enabledCountSelector = createSelector(
  [podcastSubscriptionsSelector, workflowIdsSelector],
  (subs, ids) => {
    return ids.filter(id => subs.getIn([String(id), 'isEnabled'])).length;
  },
);

const EpisodeListAutomationsRowDescription: React.FC<EpisodeListAutomationsRowDescriptionProps> = props => {
  const { workflows = [] } = props;
  const enabledCount = useSelector(state => enabledCountSelector(state, props));
  const disabledCount = workflows.length - enabledCount;
  return (
    <EpisodeListRow.Description
      className={block('automations-description')}
      primary={
        <div>
          <span className={block('automations-description-title')}>
            Automations
          </span>
          <BetaIcon className={block('beta-icon')} />
        </div>
      }
      secondary={
        <div className={block('automations-description-subtitle')}>
          {enabledCount > 0 && (
            <span className={block('automations-description-enabled')}>
              {enabledCount} running
            </span>
          )}
          {!!enabledCount && !!disabledCount && '  •  '}
          {disabledCount > 0 && (
            <span className={block('automations-description-disabled')}>
              {disabledCount} inactive
            </span>
          )}
          {!enabledCount && !disabledCount && <span>none added</span>}
        </div>
      }
    />
  );
};

export default EpisodeListAutomationsRowDescription;
