import * as React from 'react';

import { SelectField, SelectFieldProps } from 'components/Form';
import { FrameSize, Omit } from 'types';
import { frameSizeOptions, getOptionDisplayName } from 'utils/video-export';
import VideoExportFormField from './VideoExportFormField';

interface IProps extends Omit<SelectFieldProps, 'label'> {
  value: FrameSize;
}

const VideoSizeField: React.SFC<IProps> = ({ value, ...props }) => {
  return (
    <VideoExportFormField xs={12} sm={6}>
      <SelectField
        {...props}
        label="Video Size"
        value={value}
        defaultValueText={getOptionDisplayName(frameSizeOptions, value)}
      >
        {frameSizeOptions.map(option => (
          <option key={option.value} value={option.value}>
            {option.displayName}
          </option>
        ))}
      </SelectField>
    </VideoExportFormField>
  );
};

export default VideoSizeField;
export { IProps as VideoSizeFieldProps };
