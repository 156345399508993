import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import FontAwesome from 'components/FontAwesome';
import Searchbar from 'components/Searchbar';
import usePromise from 'hooks/usePromise';
import { onClickPodcastSearch } from 'redux/modules/mixpanel/actions';
import { showNotification } from 'redux/modules/notification';
import {
  clearPodcastEpisodes,
  setDefaultPodcast,
  setSelectedEpisode,
  setSelectedPodcast,
} from 'redux/modules/podcast-search/actions';
import {
  defaultPodcastIdSelector,
  selectedPodcastSelector,
} from 'redux/modules/podcast-search/selectors';
import { Dispatch } from 'redux/types';
import { useIntegrationsContext } from '../components/PodcastSearchIntegrationsContext';
import { block } from '../utils';
import FavoritePodcastBar from './FavoritePodcastBar/FavoritePodcastBar';

const { useCallback, useMemo, useState } = React;

export interface PodcastSearchBarProps {
  defaultValue?: string;
  disabled?: boolean;
  light?: boolean;
  onDescriptImport?: (audioUrl: string, transcriptUrl: string) => void;
}

const PodcastSearchBar = React.forwardRef<
  HTMLInputElement,
  PodcastSearchBarProps
>((props, ref) => {
  const { defaultValue, disabled, light } = props;
  const dispatch = useDispatch<Dispatch>();
  const [query, setQuery] = useState(defaultValue);
  const selectedPodcast = useSelector(selectedPodcastSelector);
  const defaultPodcastId = useSelector(defaultPodcastIdSelector);
  const {
    integrations,
    activeIntegration,
    setIsSearching,
    setSearchTerm,
  } = useIntegrationsContext();
  const searchPromise = usePromise();
  const placeholder = useMemo(() => {
    const placeholders = integrations
      .filter(({ hidden }) => !hidden)
      .map(integration => integration.placeholder);
    return `Enter a ${[
      placeholders.slice(0, -1).join(', '),
      placeholders[placeholders.length - 1],
    ].join(
      placeholders.length === 1
        ? ''
        : `${placeholders.length > 2 ? ', ' : ' '}or `,
    )}`;
  }, [integrations]);
  const hasFavoriteIntegration = integrations.some(
    ({ id }) => id === 'favorite',
  );

  const handleSubmit = useCallback(async () => {
    const q = query.trim();

    if (!q) return;

    dispatch(onClickPodcastSearch(q));

    const integration = integrations
      .sort((i1, i2) => i1.priority - i2.priority)
      .find(({ canHandle }) => canHandle(q));

    if (integration) {
      setIsSearching(true);
      try {
        await searchPromise.setPromise(integration.onSubmit(q));

        setSearchTerm(q);
      } catch (err) {
        // integrations can prevent this default behavior by handling their
        // own onSubmit errors
        dispatch(
          showNotification({
            message: 'Please try again or {{link}} so we can help',
            code: 'ER013',
            level: 'error',
            dismissAfterSec: 5,
            type: 'help',
            title: "Sorry, we couldn't search for that term.",
            actionLabel: 'contact us',
          }),
        );
      } finally {
        setIsSearching(false);
      }
    }
  }, [
    dispatch,
    integrations,
    query,
    searchPromise,
    setIsSearching,
    setSearchTerm,
  ]);

  const handleBackClick = () => {
    dispatch(clearPodcastEpisodes());
    dispatch(setSelectedPodcast(undefined));
    dispatch(setSelectedEpisode(undefined));
  };

  const handleFavoriteClick = () => {
    dispatch(setDefaultPodcast());
  };

  if (activeIntegration?.episodeExplorerEnabled && selectedPodcast) {
    return (
      <div className={block('search-bar')}>
        <FavoritePodcastBar>
          <h2 className={block('podcast-name')}>{selectedPodcast.title}</h2>
        </FavoritePodcastBar>
        <Button
          theme="submit"
          className={block('back-button')}
          onClick={handleBackClick}
        >
          <FontAwesome icon="search" size="lg" />
          <span>Search podcasts</span>
        </Button>
      </div>
    );
  }

  return (
    <Searchbar
      light={light}
      className={block('search-bar')}
      onSubmit={handleSubmit}
    >
      <Searchbar.Input
        {...{ placeholder, disabled }}
        value={query}
        onChange={e => setQuery(e.target.value)}
        ref={ref}
      />
      <Searchbar.Button
        icon={<FontAwesome icon="search" size="lg" />}
        {...{ disabled }}
      >
        {hasFavoriteIntegration && defaultPodcastId ? '' : 'Search'}
      </Searchbar.Button>
      {hasFavoriteIntegration && defaultPodcastId && (
        <Button
          theme="ghost"
          onClick={handleFavoriteClick}
          className={block('cancel-button')}
        >
          Cancel
        </Button>
      )}
    </Searchbar>
  );
});

PodcastSearchBar.displayName = 'ForwardRef(PodcastSearchBar)';

export default PodcastSearchBar;
