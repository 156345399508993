import cn from 'classnames';
import * as React from 'react';
import { Col as BSCol, ColProps as BSColProps } from 'react-bootstrap';

import { block } from './utils';

export interface ColProps extends BSColProps {
  divider?: boolean;
}

const Col: React.SFC<ColProps> = ({ className, divider, ...colProps }) => (
  <BSCol {...colProps} className={cn(block('col', { divider }), className)} />
);

Col.defaultProps = {
  divider: false,
};

export default Col;
