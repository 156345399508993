import { createSelector } from 'reselect';

import { userIdSelector } from 'redux/modules/auth/selectors';
import {
  personalFontsSelector,
  userPreferencesSelector,
} from 'redux/modules/entities/selectors';
import { RequestStatus } from 'types';
import { getCombinedFontList } from 'utils/fonts';

const recordingsSelector = state => state.getIn(['entities', 'recordings']);

export const selectedRecordingSelector = state =>
  state.getIn(['recordingSearch', 'selectedRecording']);

export const recordingUploadStatusSelector = state =>
  state.getIn(['recordingUpload', 'status']);

export const userSearchResultSelector = state =>
  state.getIn(['recordingSearch', 'user']);

export const selectedRecordingVersionSelector = createSelector(
  [recordingsSelector, selectedRecordingSelector],
  (recordings, recordingId) =>
    recordings.getIn([`${recordingId}`, 'versionId']),
);

export const fontsSelector = createSelector(
  personalFontsSelector,
  (personalFonts = []) => getCombinedFontList(personalFonts),
);

export const myPreferencesSelector = createSelector(
  [userIdSelector, userPreferencesSelector],
  (userId, preferences) => preferences && preferences.get(userId.toString()),
);

export const videoExportEmailEnabledSelector = createSelector(
  myPreferencesSelector,
  preferences => preferences && preferences.get('videoExportEmailAlertEnabled'),
);

export const reminderEmailEnabledSelector = createSelector(
  myPreferencesSelector,
  preferences => preferences && preferences.get('reminderEmailEnabled'),
);

export const automationEmailAlertEnabledSelector = createSelector(
  myPreferencesSelector,
  preferences => preferences && preferences.get('automationEmailAlertEnabled'),
);

export const combineRequestStatuses = (
  ...statuses: RequestStatus[]
): RequestStatus | null => {
  if (statuses.some(s => s === RequestStatus.REQUEST)) {
    return RequestStatus.REQUEST;
  }
  if (statuses.every(s => s === RequestStatus.SUCCESS)) {
    return RequestStatus.SUCCESS;
  }
  if (statuses.some(s => s === RequestStatus.FAILURE)) {
    return RequestStatus.FAILURE;
  }
  return null;
};
