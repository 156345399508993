import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { isEqual } from 'underscore';
import Banner from 'components/Banner';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import useStaticCallback from 'hooks/useStaticCallback';
import { podcastRemoteEpisodeSelector } from 'redux/modules/entities/selectors';
import { fetchEpisodeExcerpts } from 'redux/modules/podcast-search/actions';
import { makePodcastArtworkSelector } from 'redux/modules/podcast-search/selectors';
import { Timing } from 'types/common';

interface Props {
  region: Partial<Timing>;
  onRegionUpdate?: (region: Timing) => void;
  episodeId?: string;
  podcastId?: string;
  className?: string;
}

const makeSoundbiteRegionSelector = (episodeId: string) =>
  createSelector(
    [podcastRemoteEpisodeSelector(episodeId)],
    episodeTranscriptSearch => {
      const [firstExcerpt] = episodeTranscriptSearch?.excerpts ?? [];

      if (!firstExcerpt) {
        return null;
      }

      return {
        startMillis: firstExcerpt.startMillis,
        endMillis: firstExcerpt.startMillis + firstExcerpt.durationMillis,
      };
    },
  );

function SoundbiteBanner(props: Props) {
  const { region, onRegionUpdate, episodeId, podcastId, className } = props;
  const dispatch = useDispatch();
  const artworkUrl = useSelector(
    useMemo(() => makePodcastArtworkSelector(podcastId), [podcastId]),
  );
  const soundbite = useSelector(
    useMemo(() => makeSoundbiteRegionSelector(episodeId), [episodeId]),
  );
  const disabled = isEqual(region, soundbite);

  const handleSelect = useStaticCallback(() => {
    if (soundbite) {
      onRegionUpdate(soundbite);
    }
  });

  useEffect(() => {
    if (episodeId) {
      dispatch(fetchEpisodeExcerpts(podcastId, episodeId));
    }
  }, [dispatch, episodeId, podcastId]);

  useEffect(() => {
    handleSelect();
  }, [handleSelect, soundbite]);

  if (!soundbite) {
    return null;
  }

  return (
    <Banner
      theme="primary"
      icon={<img src={artworkUrl} />}
      className={className}
    >
      <div>
        <Banner.Title>We found a Soundbite!</Banner.Title>
        <Banner.Content>
          This episode has a clip specified in the RSS.
        </Banner.Content>
      </div>
      <Banner.Actions>
        <Tooltip
          id="soundbite-tooltip"
          content="The soundbite is already selected below"
          placement="top"
          {...(!disabled && { show: false })}
        >
          <Tooltip.Target disabled={disabled}>
            <Button theme="submit" onClick={handleSelect} disabled={disabled}>
              select soundbite
            </Button>
          </Tooltip.Target>
        </Tooltip>
      </Banner.Actions>
    </Banner>
  );
}

export default SoundbiteBanner;
