import { Map } from 'immutable';
import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { ApiDispatch } from './middleware/api/types';
import { ModalDispatch } from './modules/modal/types';

export type State = Map<string, any>;
export type GetState = () => State;

export type Dispatch<A extends Action = AnyAction> = ModalDispatch &
  ApiDispatch &
  ThunkDispatch<State, undefined, A>;

export type ThunkAction<R, A extends Action = AnyAction> = (
  dispatch: Dispatch<A>,
  getState: () => State,
) => R;

interface IAction<T extends string, P = undefined> extends Action {
  type: T;
  payload?: P;
  error?: string;
  meta?: any;
}

export enum RouterActionType {
  LOCATION_CHANGE = '@@router/LOCATION_CHANGE',
}

export type RouterAction = IAction<
  RouterActionType.LOCATION_CHANGE,
  {
    pathname: string;
  }
>;

export { IAction as Action };
