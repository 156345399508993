import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { onExportingStepLoaded } from 'redux/modules/mixpanel';

import { discoEnabledSelector } from 'redux/modules/pricing';
import ExportVideoStepProgressOnly from './ExportVideoStepProgressOnly';
import ExportVideoStepWithActivity from './ExportVideoStepWithActivity';

import { ExportVideoStepProps } from './types';

const ExportVideoStep: React.FC<ExportVideoStepProps> = props => {
  const dispatch = useDispatch();
  const discoEnabled = useSelector(discoEnabledSelector);

  useEffect(() => {
    dispatch(onExportingStepLoaded);
  }, [dispatch]);

  return discoEnabled ? (
    <ExportVideoStepWithActivity {...props} />
  ) : (
    <ExportVideoStepProgressOnly {...props} />
  );
};

export default ExportVideoStep;
