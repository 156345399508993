import cn from 'classnames';
import React from 'react';
import Tooltip, { TooltipProps } from '../Tooltip';
import PopoverTitle from './PopoverTitle';
import { block } from './utils';

interface Props extends TooltipProps {
  variant?: 'default' | 'bordered' | 'chip';
  wrapperClassName?: string;
}

function Popover(props: Props) {
  const {
    children,
    className,
    variant = 'default',
    wrapperClassName,
    ...rest
  } = props;
  return (
    <Tooltip {...rest} className={cn(block({ [variant]: true }), className)}>
      <div className={wrapperClassName}>{children}</div>
    </Tooltip>
  );
}

Popover.Title = PopoverTitle;

export default Popover;
