import { duration } from 'dayjs';
import React from 'react';
import { formatDurationMillis } from 'utils/time';

interface Props {
  durationMillis?: number;
  hideDuration?: boolean;
  label: string;
}

function ClipOption(props: Props) {
  const { durationMillis, hideDuration, label } = props;
  return (
    <>
      {label}{' '}
      {!hideDuration && durationMillis && (
        <time dateTime={duration(durationMillis).toISOString()}>
          {formatDurationMillis(durationMillis, {
            minute: '2-digit',
            second: '2-digit',
            trim: false,
          })}
        </time>
      )}
    </>
  );
}

export default ClipOption;
