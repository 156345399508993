import cn from 'classnames';
import * as React from 'react';

import { block } from './utils';

export interface CircularGradientProps {
  children?: React.ReactNode;
  className?: string;
  contentsClassName?: string;
}

const CircularGradient: React.FC<CircularGradientProps> = ({
  children,
  className,
  contentsClassName,
}) => (
  <div className={cn(block(), className)}>
    <div className={cn(block('contents'), contentsClassName)}>{children}</div>
  </div>
);

export default CircularGradient;
