import * as React from 'react';
import { EditAssetModalName } from 'redux/modules/modal/types';
import { createChainedFunction } from 'utils/functions';
import ConnectedModalComponent, {
  ConnectedModalComponentProps,
} from './ConnectedModalComponent';
import useEditAssetModal from './useEditAssetModal';

export interface ConnectedEditAssetModalProps
  extends ConnectedModalComponentProps {
  children: React.ReactElement;
  name: EditAssetModalName;
}

const ConnectedEditAssetModal: React.FC<ConnectedEditAssetModalProps> = ({
  children,
  name,
  title,
  className,
  backdrop,
  onExited,
  onHide,
}) => {
  const {
    show,
    onHide: onConnectedHide,
    onExited: onConnectedExited,
    params,
  } = useEditAssetModal(name);

  return (
    <ConnectedModalComponent
      {...{ backdrop, show, title, className, params }}
      onHide={createChainedFunction(onHide, onConnectedHide)}
      onExited={createChainedFunction(onExited, onConnectedExited)}
    >
      {children}
    </ConnectedModalComponent>
  );
};

export default ConnectedEditAssetModal;
