import * as React from 'react';

import AssetTile, { AssetTileProps } from 'components/AssetTile';
import useTemplateThumbnail from 'hooks/useTemplateThumbnail';
import { AutogramClipSelectionMethod, AutoPostPlatform } from 'types';
import { automationWorkflowTileBlock as block } from '../../utils';
import AutogramInfo, { AutogramInfoProps } from './AutogramInfo';
import ComingSoonOverlay from './ComingSoonOverlay';
import EditAutogramButton from './EditAutogramButton';

type PickedTileProps = Pick<AssetTileProps, 'onDeleteClick'>;
type PickedAutogramInfoProps = Pick<AutogramInfoProps, 'durationSec'>;

export interface AutomationWorkflowTileProps
  extends PickedTileProps,
    PickedAutogramInfoProps {
  aspectRatioName: string;
  platform?: AutoPostPlatform;
  frequency?: string;
  id: number;
  isAuthError?: boolean;
  latestAutogramUrl?: string;
  enabled: boolean;
  clipSelectionMethod?: AutogramClipSelectionMethod;
  onToggleClick?: (enabled: boolean, hasAuthError: boolean) => void;
}

const AutomationWorkflowTile: React.SFC<AutomationWorkflowTileProps> = ({
  aspectRatioName,
  platform,
  frequency,
  durationSec,
  id,
  isAuthError,
  latestAutogramUrl,
  enabled,
  clipSelectionMethod,
  onDeleteClick,
  onToggleClick,
}) => {
  const { imageUrl, progressOverlay } = useTemplateThumbnail({
    podcastSubscriptionId: id,
  });

  const handleToggleClick = React.useCallback(
    (nextEnabled: boolean): void => {
      onToggleClick(nextEnabled, isAuthError);
    },
    [isAuthError, onToggleClick],
  );

  return (
    <AssetTile
      background={
        imageUrl && {
          src: imageUrl,
          type: 'image',
        }
      }
      className={block({
        [aspectRatioName]: true,
        resolved: !!latestAutogramUrl,
        inactive: !enabled,
      })}
      onDeleteClick={onDeleteClick}
      deleteButtonClassName={block('delete-button')}
    >
      {!latestAutogramUrl && <ComingSoonOverlay platform={platform} />}
      <AutogramInfo
        {...{
          frequency,
          durationSec,
          enabled,
          clipSelectionMethod,
          onToggleClick: handleToggleClick,
        }}
      />
      {latestAutogramUrl && (
        <div className={block('buttons-container')}>
          <div className={block('button-container')}>
            <EditAutogramButton workflowId={id} />
          </div>
        </div>
      )}
      {progressOverlay}
    </AssetTile>
  );
};

export default AutomationWorkflowTile;
