import * as React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

const MagnifyingGlassIcon: React.SFC<IProps> = ({ color, ...svgProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 59 62"
    {...svgProps}
  >
    <defs>
      {/* tslint:disable max-line-length */}
      <path
        id="a"
        d="M24.882 0h-.395C17.872.1 11.653 2.863 7.013 7.602 2.373 12.34-.096 18.659.003 25.274.2 38.701 11.357 49.66 24.783 49.66h.395c4.147-.1 8.293-1.185 11.946-3.258l.494-.297.296.395c2.37 2.962 4.837 5.924 7.207 8.885l4.047 4.937c.889 1.086 1.876 1.58 3.357 1.678h.197c.889 0 1.778-.395 2.666-1.086.592-.494 1.185-.987 1.678-1.58l.198-.197c.987-.889 1.48-1.975 1.48-3.16 0-1.184-.493-2.369-1.381-3.257-1.975-1.975-4.048-3.95-6.121-6.023l-2.469-2.468c-.69-.69-1.382-1.283-2.073-1.974l-1.974-1.975-.395-.395.296-.395c3.357-4.442 5.035-9.674 4.936-15.203C49.366 10.959 38.31 0 24.883 0h-.001zm10.564 42.057l-.395.296c-3.06 1.777-6.417 2.666-9.774 2.765h-.296c-10.96 0-20.042-8.885-20.24-19.844-.197-7.8 4.147-15.007 11.058-18.56 2.666-1.383 5.628-2.074 8.688-2.074h.296c10.959 0 20.042 8.885 20.239 19.844.099 7.108-3.554 13.822-9.576 17.573zM31.694 20.14c-.69-1.283-1.678-1.481-2.073-1.481-.79 0-1.58.494-2.073 1.283-.099.1-.099.198-.197.297l-4.048 6.515c-.494.79-.79.987-.79.987s-.099 0-.197-.098c-.198-.099-.494-.395-.79-.691-.691-.691-1.679-1.679-3.258-1.679-1.481 0-2.863.79-3.85 2.271-.198.296-1.778 2.468-2.864 3.95a2.69 2.69 0 0 0 0 2.96c.1.198.297.396.395.593 3.258 4.048 8.293 6.516 13.822 6.121 4.936-.296 9.181-2.764 11.946-6.417a3 3 0 0 0 .493-.69c.494-.79.494-1.778.1-2.568-1.58-2.665-5.036-8.688-6.616-11.353zm-9.477-7.207c-.395-.395-.889-.691-1.382-.987-.494-.198-1.086-.296-1.679-.296-2.37 0-4.344 1.974-4.344 4.344 0 1.184.494 2.27 1.284 3.06a4.331 4.331 0 0 0 3.06 1.284c2.37 0 4.344-1.975 4.344-4.344a4.262 4.262 0 0 0-1.283-3.061z"
      />
    </defs>
    <use fill="currentColor" fillRule="nonzero" xlinkHref="#a" />
    {/* tslint:enable */}
  </svg>
);

MagnifyingGlassIcon.defaultProps = {
  color: 'currentColor',
};

export default MagnifyingGlassIcon;
export { IProps as MagnifyingGlassIconProps };
