import { AuthStep } from '@sparemin/auth';
import { useDispatch, useSelector } from 'react-redux';
import { loggedInSelector } from 'redux/modules/auth';
import { pushModal } from 'redux/modules/modal';
import { CreateProfileModal } from 'redux/modules/modal/types';
import { hasAnsweredSurveySelector } from 'redux/modules/survey';
import { Dispatch } from 'redux/types';

type Callback<A extends any[], R> = (...args: A[]) => R;

type WithAuthentication = <A extends any[], R = void>(
  fn: Callback<A, R>,
  initialStep?: AuthStep,
) => (...args: A) => Promise<R>;

export interface UseAuthModalConfig {
  origin?: CreateProfileModal['params']['origin'];
}

export interface UseAuthModalResult {
  withAuthentication: WithAuthentication;
}

/**
 * A hook for opening the authentication modal when interacting with an element
 * that requires authentication.  For simply opening the login modal, use
 * `dispatch(pushModal({ type: 'Authentication' }))`.
 *
 * Given a page that does not require authentication and a button on that page
 * which does, the button's onClick handler can be wrapped in `withAuthetication` so
 * that when the user clicks on the button, one of two things happens:
 *  1. If the user is already authenticated, the onClick handler passed to withAuthentication
 *     is called
 *  2. If the user is not authenticated, the authentication modal is opened.  After
 *     the user successfully authenticates, the underlying onClick handler will be
 *     called an subsequent calls will follow path #1.
 */
export default function useAuthModal(
  config?: UseAuthModalConfig,
): UseAuthModalResult {
  const { origin } = config ?? {};

  const dispatch = useDispatch<Dispatch>();

  const isAuthenticated = useSelector(loggedInSelector);

  const onboard = async (): Promise<boolean> => {
    const result = await dispatch(
      pushModal({
        name: 'CreateProfile',
        params: { origin },
      }),
    );
    return !!result;
  };

  const authenticate = async (initialStep?: AuthStep): Promise<boolean> => {
    const result = await dispatch(
      pushModal({
        name: 'Authentication',
        params: { initialStep },
      }),
    );

    return !!result.isAuthenticated;
  };

  const withAuthentication = <A extends any[], R>(
    fn: Callback<A, R>,
    initialStep?: AuthStep,
  ) => async (...args: A): Promise<R> => {
    if (!isAuthenticated) {
      const result = await authenticate(initialStep);

      if (!result) {
        return null;
      }
    }

    // isOnboarded cannot be read along with isAuthenticated at the top of the function
    // because authenticating will change the result of isOnboarded, however this
    // function would close over isOnboarded the same way it does isAuthenticated,
    // so we would never get the updated value.
    // reading from redux like this allows us to ge the value of isOnboarded even
    // if it changes as a result of authenticate()
    const isOnboarded = dispatch((_, getState) =>
      hasAnsweredSurveySelector(getState()),
    );

    if (!isOnboarded) {
      const result = await onboard();

      if (!result) {
        return null;
      }
    }

    return fn(...args);
  };

  return { withAuthentication };
}
