import React from 'react';

import HiddenAudioPlayer from 'components/HiddenAudio';
import ProjectVideoPreview from 'components/ProjectVideoPreview';

interface UseAutogramPreviewConfig {
  embedUrl: string;
  recordingUrl: string;
}

interface UseAutogramPreview {
  audioPlayerRef: React.MutableRefObject<HiddenAudioPlayer>;
  isLoaded: boolean;
  isMuted: boolean;
  isPlaying: boolean;
  onAudioReady: () => void;
  onEndReached: () => void;
  onPreviewReady: () => void;
  onToggleMuted: () => void;
  previewPlayerRef: React.MutableRefObject<ProjectVideoPreview>;
}

const PREVIEW_LOOP_DELAY_MS = 500;

const useAutogramPreview = (
  config: UseAutogramPreviewConfig,
): UseAutogramPreview => {
  const { embedUrl, recordingUrl } = config;

  const audioPlayerRef = React.useRef<HiddenAudioPlayer>();
  const previewPlayerRef = React.useRef<ProjectVideoPreview>();

  const [previousEmbedUrl, setPreviousEmbedUrl] = React.useState(embedUrl);
  const [previousRecordingUrl, setPreviousRecordingUrl] = React.useState(
    recordingUrl,
  );

  const [muted, setMuted] = React.useState(false);
  const [audioLoaded, setAudioLoaded] = React.useState(false);
  const [iframeLoaded, setIframeLoaded] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);

  const isLoaded = audioLoaded && iframeLoaded;

  // If either the embedUrl or recordingUrl is modified the playback should be stopped
  // for awaiting the new iframe / audio to be loaded.
  if (embedUrl !== previousEmbedUrl) {
    setPreviousEmbedUrl(embedUrl);
    setPlaying(false);
    setIframeLoaded(false);
  }

  if (recordingUrl !== previousRecordingUrl) {
    setPreviousRecordingUrl(previousRecordingUrl);
    setPlaying(false);
    setAudioLoaded(false);
  }

  const handleEndReached = React.useCallback((): void => {
    setPlaying(false);
    // This timeout prevents an issue where the iframe could get stucked.
    // As it is just 500ms it is unnoticeable for the user as it happens
    // when the audio is has no sound.
    setTimeout(() => {
      previewPlayerRef.current?.embedPlayer?.setCurrentTime?.(0);
      audioPlayerRef.current?.setCurrentTime?.(0);
      setPlaying(true);
    }, PREVIEW_LOOP_DELAY_MS);
  }, [previewPlayerRef]);

  // Playing state is enabled only when both iframe and audio have been loaded.
  const handleAudioReady = React.useCallback((): void => {
    setAudioLoaded(true);
    if (iframeLoaded) {
      setPlaying(true);
    }
  }, [iframeLoaded]);

  const handlePreviewReady = React.useCallback((): void => {
    setIframeLoaded(true);
    if (audioLoaded) {
      setPlaying(true);
    }
  }, [audioLoaded]);

  const handleToggleMuted = React.useCallback((): void => {
    setMuted(currMuted => !currMuted);
  }, []);

  return {
    audioPlayerRef,
    isLoaded,
    isMuted: muted,
    isPlaying: playing,
    onAudioReady: handleAudioReady,
    onEndReached: handleEndReached,
    onPreviewReady: handlePreviewReady,
    onToggleMuted: handleToggleMuted,
    previewPlayerRef,
  };
};

export default useAutogramPreview;
