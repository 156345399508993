import * as React from 'react';
import { useDispatch } from 'react-redux';

import ClipSelectPage from 'blocks/ClipSelect';
import { loadClippingData, reclipAudio } from 'redux/modules/download/actions';
import { showError } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';

export interface ReclipPageProps {}

type HandleSubmit = React.ComponentProps<typeof ClipSelectPage>['onSubmit'];

const { useEffect } = React;

const ReclipPage: React.FC<ReclipPageProps> = () => {
  const dispatch = useDispatch<Dispatch>();

  const handleSubmit: HandleSubmit = async ({ region, isCaptionEnabled }) => {
    try {
      await dispatch(
        reclipAudio(region.startMillis, region.endMillis, isCaptionEnabled),
      );
    } catch {
      dispatch(showError('Error updating clip', 5));
    }
  };

  useEffect(() => {
    dispatch(loadClippingData());
  }, [dispatch]);

  return <ClipSelectPage onSubmit={handleSubmit} source="ReClip" />;
};

export default ReclipPage;
