import { connect } from 'react-redux';

import {
  moveTrack,
  selectTrackForStyling,
} from 'redux/modules/embed/actions/tracks';
import { pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { PropsOf } from 'types';
import { TrackLabelsProps as Props } from '../../types';
import TrackLabels from './TrackLabels';

export type DispatchProps = Pick<Props, 'onStyleButtonClick' | 'onTrackMove'>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onStyleButtonClick: ({ id, type }) => {
    if (type === 'text') {
      dispatch(selectTrackForStyling(id));
      dispatch(pushModal({ name: 'GlobalStyleModal' }));
    }
  },
  onTrackMove: (id, index) => dispatch(moveTrack(id, index)),
});

const component = connect(null, mapDispatchToProps)(TrackLabels);

export default component;
export type TrackLabelsProps = PropsOf<typeof component>;
