import * as React from 'react';

import { Picture } from 'components/icons';
import { RefType } from 'types';

import { useEditorState } from '../context/VideoEditorStateContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { hasMedia } from '../state';
import { OPTION_TILE_FEATURE_PATHS } from '../state/constants';
import useAddMedia from '../useAddMedia';

import AccessControlOptionTile, {
  AccessControlOptionTileProps,
} from './AccessControlOptionTile';

const { useCallback } = React;

type PickedTileProps<P = any> = Pick<
  AccessControlOptionTileProps<P>,
  'onClick' | 'params'
>;

export interface MediaTileProps<P = any> extends PickedTileProps<P> {
  className?: string;
}

const MediaTile = React.forwardRef(
  <P extends {} = any>(
    { className, onClick, params }: MediaTileProps<P>,
    ref: RefType<typeof AccessControlOptionTile>,
  ) => {
    const { disabled, isLoading } = useEditorState();
    const state = useTemplateState();
    const onAddMedia = useAddMedia();
    const canOpenChildView = hasMedia(state);

    const isDisabled = disabled || isLoading;

    const handleClick: AccessControlOptionTileProps['onClick'] = useCallback(
      (p, e) => {
        if (canOpenChildView) {
          onClick(p, e);
        } else {
          onAddMedia();
        }
      },
      [canOpenChildView, onAddMedia, onClick],
    );

    return (
      <>
        <AccessControlOptionTile
          className={className}
          disabled={isDisabled}
          featurePath={OPTION_TILE_FEATURE_PATHS.image}
          icon={<Picture width={54} />}
          id="template-media-tile"
          label="media"
          onClick={handleClick}
          params={params}
          ref={ref}
        />
      </>
    );
  },
);

export default MediaTile;
