import { Type } from './action-types';
import { entireAudioEnhancer } from './enhancers';
import { audioClipFactory, stateFactory } from './factories';
import { AudioWizardAction, AudioWizardState } from './types';

const defaultState = stateFactory();

function reducer(
  state: AudioWizardState = defaultState,
  action: AudioWizardAction,
) {
  switch (action.type) {
    case Type.AUDIO_WIZARD_DATA_LOAD_REQUEST:
      return defaultState.withMutations(s => {
        s.set('isLoading', true);
        s.set('wizardLoadError', false);
        return s;
      });

    case Type.AUDIO_WIZARD_DATA_LOAD_SUCCESS:
      return state.set('isLoading', false);

    case Type.AUDIO_WIZARD_DATA_LOAD_FAILURE:
      return defaultState.withMutations(s => {
        s.set('isLoading', false);
        s.set('wizardLoadError', true);
        return s;
      });

    case Type.AUDIO_WIZARD_TRACE_ID_SET: {
      const { traceId } = action.payload;
      return state.set('traceId', traceId);
    }

    case Type.AUDIO_WIZARD_EPISODE_ID_SET: {
      const { episodeId } = action.payload;
      return state.set('episodeId', episodeId);
    }

    case Type.AUDIO_WIZARD_ORIGINAL_AUDIO_DURATION_SET: {
      const { durationMillis } = action.payload;
      return state.set('originalAudioDurationMillis', durationMillis);
    }

    case Type.AUDIO_WIZARD_AUDIO_CLIP_SET: {
      const { startMillis, endMillis } = action.payload;
      return state.update('audioClip', (clip = audioClipFactory()) =>
        clip.withMutations(c => {
          c.set('startMillis', startMillis);
          c.set('endMillis', endMillis);
          return c;
        }),
      );
    }

    case Type.AUDIO_WIZARD_CLEAR:
      return defaultState;

    default:
      return state;
  }
}

export default entireAudioEnhancer.createReducer(reducer, stateFactory());
