import { Heading, Spacer } from '@sparemin/blockhead';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'components/Modal';
import {
  onEddyPromoModalCTA,
  onEddyPromoModalLoad,
} from 'redux/modules/mixpanel';
import { hideModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { popperItems } from './constants';
import { block } from './utils';

export interface OptInProps {
  source: 'Audiogram' | 'FullEpisode';
  onSubmit: () => void;
}

const OptIn: React.FC<OptInProps> = ({ source, onSubmit }) => {
  const dispatch = useDispatch<Dispatch>();

  const handleHideModal = useCallback((): void => {
    dispatch(
      onEddyPromoModalCTA({
        source,
        buttonClick: 'NoThanks',
      }),
    );

    dispatch(hideModal());
  }, [dispatch, source]);

  const handleSubmit = useCallback((): void => {
    dispatch(
      onEddyPromoModalCTA({
        source,
        buttonClick: 'Generate',
      }),
    );

    onSubmit();
  }, [dispatch, onSubmit, source]);

  useEffect(() => {
    dispatch(onEddyPromoModalLoad('audiogram'));
  }, [dispatch]);

  return (
    <Modal.Body className={block('opt-in-body')}>
      <img
        src="https://static.sparemin.com/static/eddy/promopack-popper-header-image-202312121904.jpg"
        width="100%"
        height="210px"
      />

      <Spacer
        orientation="vertical"
        align="center"
        className={block('contents')}
      >
        <Spacer orientation="vertical" align="center" space={1.6}>
          <Heading level={1}>
            Do you want Headliner AI to also generate additional promo assets?
          </Heading>

          <Heading level={2}>
            At no additional charge, we can create the following assets:
          </Heading>
        </Spacer>

        <div className={block('popper-items')}>
          {popperItems.map(({ icon, label }) => (
            <Spacer key={label} align="center" space="10px">
              <Spacer
                align="center"
                justify="center"
                className={block('icon-container')}
              >
                {icon}
              </Spacer>

              <Heading level={3} className={block('popper-label')}>
                {label}
              </Heading>
            </Spacer>
          ))}
        </div>
      </Spacer>

      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton theme="submit-alt" onClick={handleHideModal}>
            No thanks
          </Modal.FooterButton>

          <Modal.FooterButton theme="submit" onClick={handleSubmit}>
            Generate assets for free
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </Modal.Body>
  );
};

export default OptIn;
