import React from 'react';
import { IconProps } from './types';

const InstagramReels: React.FC<IconProps> = props => (
  <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.316 0h6.368c1.186 0 2.264.483 3.046 1.26A4.262 4.262 0 0 1 15 4.29v6.422a4.265 4.265 0 0 1-1.267 3.026l-.003.002A4.316 4.316 0 0 1 10.686 15h-6.37a4.321 4.321 0 0 1-3.049-1.26l-.03-.033A4.26 4.26 0 0 1 0 10.71V4.29c0-1.18.485-2.253 1.267-3.03A4.314 4.314 0 0 1 4.316 0zm6.922 3.787.009.013H13.9a3.23 3.23 0 0 0-.919-1.793 3.252 3.252 0 0 0-2.297-.95H9.595l1.643 2.73zm-1.227.013L8.36 1.057H4.737L6.408 3.8h3.603zm-4.84 0L3.556 1.146c-.59.142-1.12.446-1.536.86-.479.477-.81 1.1-.919 1.794h4.072zm8.765 1.057H1.064v5.855c0 .875.354 1.672.928 2.256l.027.025c.59.586 1.404.951 2.297.951h6.368a3.25 3.25 0 0 0 2.296-.948l.002-.003a3.212 3.212 0 0 0 .954-2.281V4.857zm-7.679 1.85 3.295 2.13a.474.474 0 0 1 .016.793l-3.281 1.926a.479.479 0 0 1-.305.108.479.479 0 0 1-.48-.477V7.098h.002a.481.481 0 0 1 .753-.39z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default InstagramReels;
