import React from 'react';
import { Linkedin } from 'components/icons';
import SocialCircle from './SocialCircle';

export interface LinkedinSocialCircleProps {
  className?: string;
}

const LinkedinSocialCircle: React.FC<LinkedinSocialCircleProps> = ({
  className,
}) => (
  <SocialCircle background="#2867b2" className={className}>
    <Linkedin height="100%" width="100%" />
  </SocialCircle>
);

export default LinkedinSocialCircle;
