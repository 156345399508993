import React, { useCallback } from 'react';

import ModalFooterButton from 'components/Modal/ModalFooterButton';
import { Handle } from 'components/SocialAccountInfo';
import { Step } from 'components/SteppedModal';
import SwitchAccountMessage from 'components/SwitchAccountMessage';
import useTikTokAuthenticator, {
  UseTikTokAuthenticatorConfig,
} from 'hooks/useTikTokAuthenticator';

import useTikTokPostModalSelector from '../useTikTokPostModalSelector';

export interface UseSwitchAccountStepConfig
  extends Partial<Pick<UseTikTokAuthenticatorConfig, 'onError'>> {
  onSwitchAccount?: () => void;
  onSwitchAccountSuccess?: () => void;
}

export default function useSwitchAccountStep(
  config: UseSwitchAccountStepConfig = {},
): Step {
  const { onSwitchAccount, onSwitchAccountSuccess, onError } = config;

  const { username } = useTikTokPostModalSelector();

  const { authenticate, authenticating } = useTikTokAuthenticator({
    force: true,
    onAuthSuccess: onSwitchAccountSuccess,
    onError,
  });

  const handleAuthenticate = useCallback((): void => {
    onSwitchAccount();
    authenticate();
  }, [authenticate, onSwitchAccount]);

  return {
    id: 'switch',
    title: 'switch tiktok accounts',
    component: (
      <SwitchAccountMessage username={<Handle username={username} />} />
    ),
    renderFooterButtons: ({ cancel, submit }) => [
      <ModalFooterButton {...cancel} key="cancel">
        cancel
      </ModalFooterButton>,
      <ModalFooterButton
        {...submit}
        key="submit"
        disabled={authenticating}
        onClick={handleAuthenticate}
        theme="submit"
      >
        switch accounts
      </ModalFooterButton>,
    ],
  };
}
