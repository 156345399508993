import cn from 'classnames';
import * as React from 'react';
import { omit, pick } from 'underscore';

import { cloneOnlyChild } from 'utils/react';
import { block } from './utils';

interface IProps {
  children: React.ReactNode;
}

type Props<T> = IProps & T;

/**
 * although no components are passing props through explicitly, this component was made to support
 * passthrough props for the CopyAllCaptionsButton component.
 *
 * since the copy button component has a tooltip, the child of the tooltip needs to support
 * a few event handlers (mouse enter, mouse out, etc.) to support rendering the tooltip on hover.
 * without support for passthrough props, the event handlers would get swallowed by
 * CaptionsFormControl and hovering would not render the tooltip.
 */

function CaptionsFormControl<T = {}>(props: Props<T>) {
  const { children } = pick(props, 'children');
  const restProps = omit(props, 'children');

  return cloneOnlyChild(children, child => ({
    className: cn(block('form-control'), child.props.className),
    ...restProps,
  }));
}

export default CaptionsFormControl;
export { Props as CaptionsFormControlProps };
