import * as React from 'react';

import { WaveformProps } from '../../types';

const Pulse = React.forwardRef<SVGSVGElement, WaveformProps>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    ref={ref}
    {...props}
  >
    <path d="M16.882 15.983c0 0.592-0.48 1.072-1.072 1.072s-1.072-0.48-1.073-1.072c-0-0.592 0.479-1.073 1.072-1.073s1.073 0.479 1.073 1.071z" />
    <path d="M21.933 15.983c0 0.497-0.403 0.899-0.899 0.899s-0.899-0.402-0.9-0.899c-0-0.497 0.402-0.9 0.899-0.9s0.9 0.402 0.9 0.899z" />
    <path d="M20.722 19.338c0 0.497-0.403 0.899-0.899 0.899s-0.899-0.402-0.9-0.899c-0-0.497 0.402-0.9 0.899-0.9s0.9 0.402 0.9 0.899z" />
    <path d="M17.643 21.137c0 0.497-0.403 0.899-0.899 0.899s-0.899-0.402-0.9-0.899c-0-0.497 0.402-0.9 0.899-0.9s0.9 0.402 0.9 0.899z" />
    <path d="M14.115 20.515c0 0.497-0.403 0.899-0.899 0.899s-0.899-0.402-0.9-0.899c-0-0.497 0.402-0.9 0.899-0.9s0.9 0.402 0.9 0.899z" />
    <path d="M11.831 17.782c0 0.497-0.403 0.899-0.899 0.899s-0.899-0.402-0.9-0.899c-0-0.497 0.402-0.9 0.899-0.9s0.9 0.402 0.9 0.899z" />
    <path d="M11.831 14.218c0 0.497-0.403 0.899-0.899 0.899s-0.899-0.402-0.9-0.899c-0-0.497 0.402-0.9 0.899-0.9s0.9 0.402 0.9 0.899z" />
    <path d="M14.115 11.485c0 0.497-0.403 0.899-0.899 0.899s-0.899-0.402-0.9-0.899c-0-0.497 0.402-0.9 0.899-0.9s0.9 0.402 0.9 0.899z" />
    <path d="M17.643 10.863c0 0.497-0.403 0.899-0.899 0.899s-0.899-0.402-0.9-0.899c-0-0.497 0.402-0.9 0.899-0.9s0.9 0.402 0.9 0.899z" />
    <path d="M20.722 12.662c0 0.497-0.403 0.899-0.899 0.899s-0.899-0.402-0.9-0.899c-0-0.497 0.402-0.9 0.899-0.9s0.9 0.402 0.9 0.899z" />
    <path d="M26.845 16c0 0.334-0.271 0.605-0.605 0.605s-0.605-0.271-0.606-0.605c-0-0.334 0.271-0.605 0.605-0.606s0.606 0.271 0.606 0.605z" />
    <path d="M25.669 20.843c0 0.334-0.271 0.605-0.605 0.605s-0.605-0.271-0.606-0.605c-0-0.334 0.271-0.605 0.605-0.606s0.606 0.27 0.606 0.605z" />
    <path d="M22.348 24.579c0 0.334-0.271 0.605-0.605 0.605s-0.605-0.271-0.606-0.605c-0-0.334 0.271-0.605 0.605-0.606s0.606 0.27 0.606 0.605z" />
    <path d="M17.678 26.344c0 0.334-0.271 0.605-0.605 0.605s-0.605-0.271-0.606-0.605c-0-0.334 0.271-0.605 0.605-0.606s0.606 0.27 0.606 0.605z" />
    <path d="M12.731 25.756c0 0.334-0.271 0.605-0.605 0.605s-0.605-0.271-0.606-0.605c-0-0.334 0.271-0.605 0.605-0.606s0.606 0.27 0.606 0.605z" />
    <path d="M8.649 22.919c0 0.334-0.271 0.605-0.605 0.605s-0.605-0.271-0.606-0.605c-0-0.334 0.271-0.605 0.605-0.606s0.606 0.27 0.606 0.605z" />
    <path d="M6.331 18.491c0 0.334-0.271 0.605-0.605 0.605s-0.605-0.271-0.606-0.605c-0-0.334 0.271-0.605 0.605-0.606s0.606 0.27 0.606 0.605z" />
    <path d="M6.331 13.509c0 0.334-0.271 0.605-0.605 0.605s-0.605-0.271-0.606-0.605c-0-0.334 0.271-0.605 0.605-0.606s0.606 0.271 0.606 0.605z" />
    <path d="M8.649 9.081c0 0.334-0.271 0.605-0.605 0.605s-0.605-0.271-0.606-0.605c-0-0.334 0.271-0.605 0.605-0.606s0.606 0.271 0.606 0.605z" />
    <path d="M12.731 6.244c0 0.334-0.271 0.605-0.605 0.605s-0.605-0.271-0.606-0.605c-0-0.334 0.271-0.605 0.605-0.606s0.606 0.271 0.606 0.605z" />
    <path d="M17.678 5.656c0 0.334-0.271 0.605-0.605 0.605s-0.605-0.271-0.606-0.605c-0-0.334 0.271-0.605 0.605-0.606s0.606 0.271 0.606 0.605z" />
    <path d="M22.348 7.421c0 0.334-0.271 0.605-0.605 0.605s-0.605-0.271-0.606-0.605c-0-0.334 0.271-0.605 0.605-0.606s0.606 0.271 0.606 0.605z" />
    <path d="M25.669 11.157c0 0.334-0.271 0.605-0.605 0.605s-0.605-0.271-0.606-0.605c-0-0.334 0.271-0.605 0.605-0.606s0.606 0.271 0.606 0.605z" />
    <path d="M31.896 15.983c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M30.858 21.656c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M27.814 26.534c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M23.248 29.994c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M17.712 31.55c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M12.004 31.031c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M6.85 28.471c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M2.975 24.216c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M0.899 18.889c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M0.899 13.111c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M2.975 7.784c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M6.85 3.529c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M12.004 0.969c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M17.712 0.45c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M23.248 2.006c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M27.814 5.466c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
    <path d="M30.858 10.344c0 0.248-0.201 0.45-0.45 0.45s-0.45-0.201-0.45-0.45c-0-0.248 0.201-0.45 0.449-0.45s0.45 0.201 0.45 0.449z" />
  </svg>
));

export default Pulse;
