import React from 'react';

import LegacyEditCaptionsModal from 'containers/LegacyEditCaptionsModal';
import { ICaptions } from 'utils/embed/captions/types';
import { createChainedFunction } from 'utils/functions';

interface LegacyCaptionsStyleEditorProps {
  onHide: () => void;
  onSubmit: (
    style: ICaptions['style'],
    maxChars: number,
    templateId: string,
    rechunk: boolean,
  ) => void;
  onSwitchLegacyMode: () => void;
  show: boolean;
}

const LegacyCaptionsStyleEditor: React.FunctionComponent<LegacyCaptionsStyleEditorProps> = props => {
  const { onHide, onSubmit, onSwitchLegacyMode, show } = props;

  const handleSubmit = React.useMemo(
    () => createChainedFunction(onSubmit, onHide),
    [onHide, onSubmit],
  );

  return (
    <LegacyEditCaptionsModal
      onHide={onHide}
      onSubmit={handleSubmit}
      onSwitchLegacyMode={onSwitchLegacyMode}
      show={show}
    />
  );
};

export default LegacyCaptionsStyleEditor;
