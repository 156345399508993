import cn from 'classnames';
import * as React from 'react';

interface IProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactChild;
  className?: string;
  selected?: boolean;
}

const AudioLibraryTile: React.SFC<IProps> = ({
  className,
  children,
  selected,
  ...props
}) => (
  <div
    {...props}
    className={cn(
      'audio-library-tile',
      { 'audio-library-tile--selected': selected },
      className,
    )}
  >
    {children}
  </div>
);

export default AudioLibraryTile;
export { IProps as AudioLibraryTileProps };
