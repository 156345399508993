import cn from 'classnames';
import React from 'react';
import useObjectUrl from 'hooks/useObjectUrl';
import Tile from './Tile';
import useSlide from './useSlide';
import { imageViewBlock as block } from './utils';

interface Props {
  className?: string;
  disabled?: boolean;
  id: string;
  onClick(id: string): void;
}

function ImageChildTile(props: Props) {
  const { id, className, disabled, onClick } = props;
  const slide = useSlide(id);
  const imageSrc = useObjectUrl(slide.imageSrc);

  return (
    <Tile
      disabled={disabled}
      onClick={onClick}
      params={id}
      className={cn(className, block('image-child-tile'))}
    >
      <img style={{ height: '100%' }} src={imageSrc} alt={slide.fileName} />
    </Tile>
  );
}

export default ImageChildTile;
