import { useTikTokLogin, UseTikTokLoginConfig } from '@sparemin/auth';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'underscore';
import { tokenSelector } from 'redux/modules/auth';
import {
  clearTiktokUser,
  tiktokAuthorizeSuccess,
  waitForTiktokProfile,
} from 'redux/modules/social';
import { FatalError } from 'utils/FatalError';

export interface UseTikTokAuthenticatorConfig
  extends Pick<UseTikTokLoginConfig, 'force'> {
  onError?: (error: Error) => void;
  onAuthSuccess?: () => void;
}

export default function useTikTokAuthenticator({
  onAuthSuccess,
  onError,
  force,
}: UseTikTokAuthenticatorConfig) {
  const dispatch = useDispatch();
  const spareminToken = useSelector(tokenSelector);

  const showGenericErrorMessage = useCallback(() => {
    dispatch('Error authenticating with TikTok');
  }, [dispatch]);

  return useTikTokLogin({
    force,
    spareminToken,
    onSuccess: async data => {
      dispatch(clearTiktokUser());

      if (data) {
        dispatch(
          tiktokAuthorizeSuccess(
            pick(data.tokenInfo, 'accessToken', 'idToken', 'providerUserId'),
          ),
        );
      }

      try {
        await dispatch(waitForTiktokProfile());
        onAuthSuccess?.();
      } catch (error) {
        showGenericErrorMessage();
        throw new FatalError(error.message);
      }
    },
    onError: error => {
      if (error.message === 'access_denied') {
        onError?.(error);
      } else {
        showGenericErrorMessage();
      }
    },
  });
}
