import { Heading, MicGradientCircle, Spacer } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Modal';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import { onClickPodcastPersonalizationModal } from 'redux/modules/mixpanel';
import { hideModal } from 'redux/modules/modal';
import { defaultPodcastIdSelector } from 'redux/modules/podcast-search';
import { Dispatch } from 'redux/types';
import AddYourPodcast from './AddYourPodcast';
import DefaultPodcast from './DefaultPodcast';
import { block } from './utils';

export interface PodcastIdModalContentsProps {}

const PodcastIdModalContents: React.FC<PodcastIdModalContentsProps> = () => {
  const { onHide } = useConnectedModal('PodcastId');
  const defaultPodcastId = useSelector(defaultPodcastIdSelector);
  const dispatch = useDispatch<Dispatch>();
  const modalState = defaultPodcastId ? 'Filled' : 'Empty';

  const handleCloseModal = useCallback((): void => {
    dispatch(onClickPodcastPersonalizationModal(modalState, 'Skip'));

    dispatch(hideModal());
  }, [dispatch, modalState]);

  const handlePodcastButtonPress = useCallback((): void => {
    dispatch(
      onClickPodcastPersonalizationModal(
        modalState,
        defaultPodcastId ? 'Search' : 'AddYourPodcast',
      ),
    );

    dispatch(hideModal({ podcastId: undefined }));
  }, [defaultPodcastId, dispatch, modalState]);

  const handleContinue = useCallback((): void => {
    dispatch(onClickPodcastPersonalizationModal('Filled', 'Continue'));

    onHide({ podcastId: defaultPodcastId });
  }, [defaultPodcastId, dispatch, onHide]);

  return (
    <>
      <Modal.Body className={block('body')}>
        <Spacer orientation="vertical" align="center" space="32px">
          <MicGradientCircle width="100px" height="100px" />

          <Spacer orientation="vertical" align="center" space="13px">
            <Heading level={1}>Which podcast are you working on?</Heading>

            <Heading level={2}>
              This lets us personalize your Headliner experience to your feed!
            </Heading>
          </Spacer>

          {defaultPodcastId ? (
            <DefaultPodcast onPress={handlePodcastButtonPress} />
          ) : (
            <AddYourPodcast onPress={handlePodcastButtonPress} />
          )}
        </Spacer>
      </Modal.Body>

      <Modal.Footer>
        <Modal.FooterButton fluid theme="submit-alt" onClick={handleCloseModal}>
          skip
        </Modal.FooterButton>

        <Modal.FooterButton
          fluid
          theme="submit"
          disabled={!defaultPodcastId}
          onClick={handleContinue}
        >
          continue
        </Modal.FooterButton>
      </Modal.Footer>
    </>
  );
};

export default PodcastIdModalContents;
