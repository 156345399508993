import { createSelector } from 'reselect';

import { OnboardingName } from 'blocks/OnboardingAnimations/types';
import { State } from 'redux/types';
import { OnboardingState } from './types';

const onboardingSelector = (state: State) =>
  state.get('onboarding') as OnboardingState;

export const createIsOnboardingViewedSelector = (name: OnboardingName) =>
  createSelector(onboardingSelector, onboarding =>
    onboarding.onboardingItems.get(name, undefined),
  );

export const onboardingSubmitStatusSelector = createSelector(
  onboardingSelector,
  state => state.get('status'),
);

export const isNpsSurveyViewedSelector = createIsOnboardingViewedSelector(
  OnboardingName.NPS_SURVEY,
);
