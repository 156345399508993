import { isFinite, isNaN, isNumber, isString, isUndefined } from 'underscore';

/**
 * I dunno...seems like this should be the default behavior, but...
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
 */
export function round(value: number, exponent?: number) {
  if (isUndefined(value)) return undefined;

  const exp = typeof exponent === 'undefined' ? 0 : exponent;
  const shifted = value.toString().split('e');
  const rounded = Math.round(
    +`${shifted[0]}e${shifted[1] ? +shifted[1] - exp : -exp}`,
  );
  const unshifted = rounded.toString().split('e');
  return +`${unshifted[0]}e${unshifted[1] ? +unshifted[1] + exp : exp}`;
}

export function scale(val: number, from: number, to: number) {
  if (isUndefined(val) || isUndefined(from) || isUndefined(to) || from === 0) {
    return undefined;
  }

  return (val / from) * to;
}

export function percentageOf(part: number, whole: number) {
  return scale(part, whole, 100);
}

export function max(...vals: number[]) {
  const filteredVals = vals.filter(v => isNumber(v) && !isNaN(v));
  return filteredVals.length === 0 ? 0 : Math.max(...filteredVals);
}

export function min(...vals: number[]) {
  const filteredVals = vals.filter(v => isNumber(v) && !isNaN(v));
  return filteredVals.length === 0 ? 0 : Math.min(...filteredVals);
}

/**
 * it's really convenient with objects to be able to write things like obj && obj.val.  since 0
 * is considered false, this syntax doesn't work with numbers, e.g. num && n + 1 just returns 0.
 *
 * if val is undefined, returns undefined (or returnIfUndefined if passed), otherwise returns
 * fn(val)
 */
export function guard<T, U>(
  val: number,
  fn: (val: number) => T,
  returnIfUndef: U,
) {
  if (isUndefined(val)) return returnIfUndef;
  return fn(val);
}

export function int(val: string) {
  return parseInt(val, 10);
}

export function clamp(val: number, minVal: number, maxVal: number) {
  if (val < minVal) {
    return minVal;
  }
  return val > maxVal ? maxVal : val;
}

export function divideEvenly(dividend: number, divisor: number) {
  const value = Math.trunc(dividend / divisor);
  const remainder = dividend % divisor;
  return { value, remainder };
}

export function float(stringValue: string, defaultValue?: number) {
  const floatValue = parseFloat(stringValue);
  return isFinite(floatValue) ? floatValue : defaultValue;
}

export function getPrecision(value: number) {
  if (!isFinite(value)) return 0;
  let e = 1;
  let p = 0;
  while (Math.round(value * e) / e !== value) {
    e *= 10;
    p += 1;
  }
  return p;
}

export function roundToStep(value: number | string, step?: number | string) {
  const valueFloat = isString(value) ? parseFloat(value) : value;
  const stepFloat = isString(step) ? parseFloat(step) : step;
  if (isFinite(valueFloat) && isFinite(stepFloat)) {
    const precision = getPrecision(stepFloat);
    return +(
      round((valueFloat + Number.EPSILON) / stepFloat) * stepFloat
    ).toFixed(precision);
  }
  return valueFloat;
}

/**
 * Takes a number and returns a numerator and denominator in simplest form
 *
 * @param n the number
 * @param epsilon
 * @returns {[number, number]} a tuple containing the numerator and denominator
 */
export function getRatio(n: number, epsilon = 0.0001) {
  const gcd = (a: number, b: number) => (b < epsilon ? a : gcd(b, a % b));
  const c = gcd(1, Math.abs(n));

  return [Math.floor(n / c), Math.floor(1 / c)];
}

/**
 * Linear Interpolation
 *
 * Returns the value between two numbers at a specified, decimal midpoint
 *
 * Source: https://www.trysmudford.com/blog/linear-interpolation-functions/
 */
export const lerp = (x: number, y: number, a: number): number =>
  x * (1 - a) + y * a;

/**
 * Inverse Linear Interpolation
 *
 * This works in the opposite way to the lerp. Instead of passing a decimal midpoint,
 * you pass any value, and it’ll return that decimal, wherever it falls on that spectrum
 *
 * Source: https://www.trysmudford.com/blog/linear-interpolation-functions/
 */
export const invlerp = (x: number, y: number, a: number) => {
  return clamp((a - x) / (y - x), 0, 1);
};

/**
 * Range interpolation
 *
 * Converts a value from one data range to another
 * Eg.: range(10, 100, 2000, 20000, 50) => 10000
 *
 * Source: https://www.trysmudford.com/blog/linear-interpolation-functions/
 */
export const range = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  a: number,
) => lerp(x2, y2, invlerp(x1, y1, a));
