import { Heading, Quote } from '@sparemin/blockhead';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmationModal from 'components/ConfirmationModal';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import Modal from 'components/Modal';
import { Tier } from 'redux/middleware/api/plan-service';
import { onUnlockThisFeature } from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal';
import { subscriptionSelector } from 'redux/modules/pricing';
import {
  PRO_TIER_MAX_MEDIA_DURATION_HOURS,
  PRO_TIER_MAX_MEDIA_SIZE_MB,
} from 'utils/constants';
import { episodeTranscriptionModalBlock as block } from '../utils';

const EpisodeTranscriptionUpgradeModalContents: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { eddyUploadMB, eddyDurationLimitHours } = useSelector(
    subscriptionSelector,
  );
  const handleUpgrade = React.useCallback(() => {
    dispatch(onUnlockThisFeature('FullTranscriptUploadLimit'));

    dispatch(
      replaceModal({
        name: 'AddBillingCycle',
        params: {
          tier: Tier.PRO,
          defaultSubscriptionPeriod: 'yearly',
          disableSuccessRedirect: true,
        },
      }),
    );
  }, [dispatch]);

  return (
    <>
      <ConfirmationModal.Body className={block('body')}>
        <ConfirmationModal.Icon>
          <CircularGradient>
            <Quote width="35px" height="30px" className={block('icon')} />
          </CircularGradient>
        </ConfirmationModal.Icon>

        <ConfirmationModal.Title>
          This episode is too long for your tier
        </ConfirmationModal.Title>

        <ConfirmationModal.Content>
          <Heading level={2}>
            Your current plan only permits transcripts of files up to{' '}
            {eddyUploadMB}MB and {eddyDurationLimitHours} hours long. Upgrade
            today to transcribe files up to {PRO_TIER_MAX_MEDIA_SIZE_MB}
            MB and {PRO_TIER_MAX_MEDIA_DURATION_HOURS} hours long.
          </Heading>
        </ConfirmationModal.Content>
      </ConfirmationModal.Body>

      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton theme="rainbow" onClick={handleUpgrade}>
            upgrade your plan
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default EpisodeTranscriptionUpgradeModalContents;
