import { Record, RecordOf } from 'immutable';
import { partial } from 'underscore';
import { omitUndefined } from 'utils/collections';
import { withValue } from 'utils/control';

import { reviveRecord } from 'utils/immutable';
import { stateFactory as entireAudioFactory } from '../../factories/entire-audio';
import { State } from './types';

export const stateFactory = Record<State>({
  entireAudio: undefined,
  templateId: undefined,
  templateType: undefined,
});

export function stateFromJs({ entireAudio, ...rest }: any = {}): RecordOf<
  State
> {
  const audio = withValue(
    entireAudio,
    partial(reviveRecord, entireAudioFactory),
  );

  return reviveRecord(
    stateFactory,
    omitUndefined({
      entireAudio: audio,
      ...rest,
    }),
  );
}
