import * as React from 'react';
import { noop } from 'underscore';

import TextBoxColorField from './TextBoxColorField';

type DynamicFieldType = 'background-color';

interface IDynamicStyleConfig {
  displayName: React.ReactNode;
  propName: DynamicFieldType;
  propValue?: any;
}

interface IProps {
  dynamicStyle: IDynamicStyleConfig[];
  onChange?: (style: IDynamicStyleConfig[]) => void;
}

export default class DynamicStyleOptions extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onChange: noop,
  };

  private handleColorChange = (config: IDynamicStyleConfig) => (
    color: string,
  ) => {
    const { dynamicStyle, onChange } = this.props;
    const newStyle = dynamicStyle.map(c =>
      c.propName === config.propName ? { ...c, propValue: color } : c,
    );
    onChange(newStyle);
  };

  private renderInput(config: IDynamicStyleConfig) {
    switch (config.propName) {
      case 'background-color':
        return (
          <TextBoxColorField
            key={config.propName}
            label={config.displayName}
            value={config.propValue}
            onChange={this.handleColorChange(config)}
          />
        );
    }

    return null;
  }

  public render() {
    const { dynamicStyle } = this.props;

    return <div>{dynamicStyle.map(config => this.renderInput(config))}</div>;
  }
}

export {
  IDynamicStyleConfig as DynamicStyleConfig,
  IProps as DynamicOptionsProps,
};
