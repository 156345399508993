import * as React from 'react';
import { IconProps } from './types';

function ImageWithStars(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="5dgs5q6wva">
          <stop stopColor="#5DBEFC" offset="0%" />
          <stop stopColor="#DD92FE" offset="100%" />
        </linearGradient>
        <filter
          x="0%"
          y="0%"
          width="100%"
          height="100%"
          filterUnits="objectBoundingBox"
          id="amesqvk3pb"
        >
          <feGaussianBlur in="SourceGraphic" />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle
          fill="url(#5dgs5q6wva)"
          filter="url(#amesqvk3pb)"
          cx="50"
          cy="50"
          r="50"
        />
        <path
          d="M24.835 35.694c-2.118 0-3.835 1.65-3.835 3.686v22.24c0 2.035 1.717 3.686 3.835 3.686H56.73l-1.586-5.349-28.762.001v-4.053l4.508-4.688a.948.948 0 0 1 1.378 0l3.208 3.336L46.478 43.11a.948.948 0 0 1 1.378 0l2.354 2.447 4.179-1.907 2.34-7.956zm47.88 20.5 1.443 4.049 4.137 1.451-4.137 1.488-1.442 4.012-1.475-4.012-4.105-1.488 4.105-1.451 1.475-4.049zM61.36 30l-4.62 15.944L45.186 50.5l11.555 4.556L61.36 71l4.62-15.944L77.533 50.5 65.98 45.944 61.36 30zm12.71 3.417-1.541 3.037-3.081 1.518 3.081 1.519 1.54 3.037 1.54-3.037 3.081-1.519-3.08-1.518-1.54-3.037zM34.175 43.744c0 2.239-1.745 4.054-3.897 4.054-2.153 0-3.898-1.815-3.898-4.054 0-2.238 1.745-4.053 3.898-4.053 2.152 0 3.897 1.815 3.897 4.053z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default ImageWithStars;
