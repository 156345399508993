import * as React from 'react';
import { noop } from 'underscore';

import { EditorDispatchContextType } from '../types';

const { useContext } = React;

export interface VideoEditorDispatchProviderProps {
  children?: React.ReactNode;
  dispatch: EditorDispatchContextType;
}

const DispatchContext = React.createContext<EditorDispatchContextType>(noop);

export function useEditorDispatch() {
  return useContext(DispatchContext);
}

export const VideoEditorDispatchProvider: React.FC<VideoEditorDispatchProviderProps> = ({
  children,
  dispatch,
}) => (
  <DispatchContext.Provider value={dispatch}>
    {children}
  </DispatchContext.Provider>
);
