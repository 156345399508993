import * as React from 'react';
import Scrollbars, {
  positionValues,
  ScrollbarProps,
} from 'react-custom-scrollbars';

import { Omit } from 'types';

interface IProps extends Omit<ScrollbarProps, 'ref'> {
  children?: React.ReactNode;
}

type UpdateCallback = (vals?: positionValues) => void;

/*
 * we use these properties via the scrollbars ref, however the scrollbars type defintion file
 * does not define them
 */
interface IScrollbarsApi extends Scrollbars {
  container: HTMLElement;
  update: (cb?: UpdateCallback) => void;
  view: HTMLElement;
}

export default class ScrollBars extends React.Component<IProps> {
  private scrollbars: IScrollbarsApi;

  public update(cb?: UpdateCallback) {
    return this.scrollbars.update(cb);
  }

  /**
   * requires a polyfill for smooth scrolling on all browsers
   */
  public scrollTo(opts: ScrollToOptions) {
    /*
     * react-custom-scrollbars doesn't support scrollTo, so have to use the
     * view ref directly
     */
    (this.scrollbars.refs.view as HTMLElement).scrollTo(opts);
  }

  set scrollLeft(left: number) {
    this.scrollbars.scrollLeft(left);
  }

  get scrollLeft(): number {
    return this.scrollbars.getScrollLeft();
  }

  set scrollTop(top: number) {
    this.scrollbars.scrollTop(top);
  }

  get scrollTop(): number {
    return this.scrollbars.getScrollTop();
  }

  get scrollWidth(): number {
    return this.scrollbars.getScrollWidth();
  }

  get clientWidth(): number {
    return this.scrollbars.getClientWidth();
  }

  get view(): HTMLElement {
    return this.scrollbars.view;
  }

  get container(): HTMLElement {
    return this.scrollbars.container;
  }

  get values(): positionValues {
    return this.scrollbars.getValues();
  }

  private setScrollbars = el => (this.scrollbars = el);

  public render() {
    const { children, ...restProps } = this.props;

    return (
      <Scrollbars {...restProps} ref={this.setScrollbars}>
        {children}
      </Scrollbars>
    );
  }
}

export { IProps as ScrollBarsProps, positionValues };
