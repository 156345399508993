import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { PodcastFeedEpisode } from 'redux/middleware/api/podcast-service';
import { manualEpisodeTriggerStatusesSelector } from 'redux/modules/automation-cms/selectors';
import { podcastFeedEpisodesSelector } from 'redux/modules/entities/selectors';
import { makePodcastArtworkSelector } from 'redux/modules/podcast-search/selectors';
import { CMSEpisode } from 'types/common';

const makePodcastFeedEpisodeSelector = () =>
  createSelector(
    podcastFeedEpisodesSelector,
    (_, episodeId) => episodeId,
    (podcastFeedEpisodes, episodeId) =>
      podcastFeedEpisodes?.get(episodeId)?.toJS() as PodcastFeedEpisode,
  );

const makeIsEpisodeManuallyTriggeredSelector = () =>
  createSelector(
    manualEpisodeTriggerStatusesSelector,
    (_, episodeId) => episodeId,
    (manualAutograms, episodeId) => manualAutograms.get(episodeId),
  );

export default function usePodcastFeedEpisode(episodeId: string): CMSEpisode {
  const feedEpisodeSelector = useMemo(makePodcastFeedEpisodeSelector, []);
  const manualTriggerStatusSelector = useMemo(
    makeIsEpisodeManuallyTriggeredSelector,
    [],
  );
  const feedEpisode = useSelector(state =>
    feedEpisodeSelector(state, episodeId),
  );
  const { podcastFeedId } = feedEpisode ?? {};
  const podcastArtworkUrl = useSelector(
    useMemo(() => makePodcastArtworkSelector(podcastFeedId), [podcastFeedId]),
  );

  const manualTriggerStatus = useSelector(state =>
    manualTriggerStatusSelector(state, episodeId),
  );

  return {
    ...feedEpisode,
    manualTriggerStatus,
    thumbnailImageUrl: feedEpisode?.thumbnailImageUrl || podcastArtworkUrl,
  };
}
