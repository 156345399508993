import { CustomizeStepSubmitType } from 'containers/ProjectWizards/CustomizeStep/types';
import { SubmitConfig } from 'containers/ProjectWizards/TemplateWizard/types';
import { getPodcastWorkflowTemplate } from 'redux/middleware/api/podcast-service/actions';
import { ThunkAction } from 'redux/types';
import { TemplateType } from 'types';
import { isAudiogram } from 'utils/embed/embed';
import { clearSearchResults } from '../podcast-search';
import { exportFromWizard } from '../wizard-export/actions';
import { Type } from './action-types';
import { entireAudioEnhancer } from './enhancers';
import { entireAudioInstanceIdSelector } from './selectors';

export const loadTemplateWizard = (
  templateId: string,
  templateType: TemplateType,
): ThunkAction<Promise<any>> => dispatch => {
  dispatch(clearSearchResults());
  dispatch({
    payload: { templateId, templateType },
    type: Type.LOAD,
  });

  if (!templateId || !templateType) {
    throw new Error('Error loading template');
  }

  return dispatch(getPodcastWorkflowTemplate(templateId, templateType));
};

export const unloadTemplateWizard = () => ({
  type: Type.UNLOAD,
});

export const createTemplateProject = (
  type: CustomizeStepSubmitType,
  config: SubmitConfig,
): ThunkAction<Promise<any>> => async (dispatch, getState) => {
  const { audioClip, transcription, ...rest } = config;
  const entireAudioInstanceId = entireAudioInstanceIdSelector(getState());

  await dispatch(
    exportFromWizard({
      ...rest,
      initiateExport: type === 'export',
      projectCreatMethod: isAudiogram(audioClip) ? 'template' : 'templateLong',
      audio: {
        ...audioClip,
        entireAudioInstanceId,
      },
      soundwave: {
        waveGeneration: 'amplitudeBased',
        ...config.soundwave,
      },
      transcription: {
        ...transcription,
        transcriptUrl: undefined,
      },
    }),
  );
};

export const selectTemplate = (templateId: string) => ({
  type: Type.TEMPLATE_SELECT,
  payload: { templateId },
});

export const {
  clearEntireAudio,
  uploadEntireAudio,
} = entireAudioEnhancer.actions;
