import mixpanel from 'mixpanel-browser';
import { createSelector } from 'reselect';

import { State } from 'redux/types';
import { ImageOriginId } from 'types';
import { capitalize } from 'utils/string';
import { authProviderSelector } from '../auth/selectors';
import {
  aspectRatioNameSelector,
  audioVariationTypeSelector,
  automationSubIdSelector,
  blurRadiusSelector,
  captionsEnabledSelector,
  hasSlideFromOriginSelector,
  hasSocialPostCaptionInfoSelector,
  projectOwnerIdSelector,
  projectTypeSelector,
  subscriptionItemSelector,
  subscriptionSelector,
  templateIdSelector,
  videoFrequencySelector,
  waveformPrefIdSelector,
} from '../download/selectors';
import { AUTH_PROVIDER_LOGIN_SOURCE_MAP } from './constants';
import {
  DownloadPageActionProperties,
  DownloadPageProperties,
  MixpanelState,
} from './types';
import { getLanguageProperty, getMixpanelBlurValue } from './utils';

const stateSelector = (state: State): MixpanelState => state.get('mixpanel');

export const completeWizardPropsSelector = createSelector(
  stateSelector,
  state => state.completeWizardProps,
);

export const downloadPagePropertiesSelector = (
  state: State,
): DownloadPageProperties => {
  const aspectRatio = aspectRatioNameSelector(state);
  const frequency = videoFrequencySelector(state);
  const projectType = projectTypeSelector(state);
  const projectOwner = projectOwnerIdSelector(state);
  const templateId = templateIdSelector(state);
  const subscription = subscriptionSelector(state);
  const automationSubscriptionItem = subscriptionItemSelector(state);
  const automationSubId = automationSubIdSelector(state);
  const captionsEnabled = captionsEnabledSelector(state);
  const audioVariationType = audioVariationTypeSelector(state);
  const canvaImage = hasSlideFromOriginSelector(ImageOriginId.CANVA, state);
  const waveformPrefId = waveformPrefIdSelector(state);
  const blurValue = getMixpanelBlurValue(blurRadiusSelector(state));
  const aiGenSocialCaptions = hasSocialPostCaptionInfoSelector(state);

  const properties: DownloadPageProperties = {
    automationSubId,
    aspectRatio,
    canvaImage,
    frequency,
    projectType,
    templateId,
    projectOwner,
    blurValue,
    aiGenSocialCaptions,
    waveSource: waveformPrefId ? 'Preset' : 'New',
    ...(!!audioVariationType && {
      audioVariationType: capitalize(audioVariationType),
    }),
  };

  if (subscription) {
    properties.templateName = subscription?.templateName;
    properties.templateType = subscription?.templateType;
    properties.automationSubscriptionLanguage =
      subscription?.subscriptionPodcastLanguage;
  }

  if (automationSubscriptionItem) {
    properties.autogramCaption = automationSubscriptionItem?.isCaptionEnabled
      ? 'enabled'
      : 'disabled';
  } else {
    properties.captions = captionsEnabled ? 'On' : 'Off';
  }

  return properties;
};

export const downloadPageActionPropertiesSelector = (
  state: State,
): DownloadPageActionProperties => ({
  ...downloadPagePropertiesSelector(state),
  ...getLanguageProperty(),
});

export const authSourceSelector = createSelector(
  authProviderSelector,
  provider => AUTH_PROVIDER_LOGIN_SOURCE_MAP[provider],
);

export const mixpanelInitializedSelector = createSelector(
  stateSelector,
  state => state.get('initialized'),
);

export const mixpanelDistinctIdSelector = createSelector(
  mixpanelInitializedSelector,
  isInitialized => (isInitialized ? mixpanel.get_distinct_id() : undefined),
);
