import cn from 'classnames';
import * as React from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';
import ProgressList from 'components/ProgressList';
import usePageUnloadGuard from 'hooks/usePageUnloadGuard';
import FakeProgressItem from './FakeProgressItem';
import { WizardSubmitProgress } from './types';
import { block } from './utils';
import WizardProgressItem from './WizardProgressItem';

interface IProps {
  steps: WizardSubmitProgress[];
  completedSteps: WizardSubmitProgress[];
  onCompleted?: () => void;
}

enum AnimationStatus {
  INITIAL = 'initial',
  ANIMATING_IN = 'animating-in',
  ANIMATING_IN_COMPLETE = 'animating-in-complete',
  ANIMATING_OUT = 'animating-out',
  ANIMATING_OUT_COMPLETE = 'animating-out-complete',
}

const { useState, useEffect } = React;

const OFFSET_STEP_IN_DEGREES = 25;

const WizardProgressStep: React.FC<IProps> = ({
  steps,
  completedSteps,
  onCompleted,
}) => {
  const [animStatus, setAnimStatus] = useState<AnimationStatus>(
    AnimationStatus.INITIAL,
  );

  usePageUnloadGuard();

  useEffect(() => {
    setAnimStatus(AnimationStatus.ANIMATING_IN);
  }, []);

  useEffect(() => {
    if (
      document.visibilityState === 'hidden' &&
      steps.length === completedSteps.length
    ) {
      onCompleted();
    }
  }, [completedSteps.length, steps.length, onCompleted]);

  return (
    <Flipper
      flipKey={animStatus}
      className={block({ [animStatus]: true })}
      onComplete={
        animStatus === AnimationStatus.ANIMATING_IN
          ? () => setAnimStatus(AnimationStatus.ANIMATING_IN_COMPLETE)
          : animStatus === AnimationStatus.ANIMATING_OUT
          ? () => {
              setAnimStatus(AnimationStatus.ANIMATING_OUT_COMPLETE);
              onCompleted();
            }
          : null
      }
    >
      <Flipped
        flipId="title"
        stagger={animStatus === AnimationStatus.ANIMATING_IN}
      >
        <div className={cn('wizard__title', block('title'))}>
          We're creating your project
        </div>
      </Flipped>
      <Flipped
        flipId="description"
        stagger={animStatus === AnimationStatus.ANIMATING_IN}
      >
        <div className={cn('wizard__description', block('description'))}>
          This may take a few minutes, please don't refresh
        </div>
      </Flipped>
      <Flipped flipId="list">
        <div className={block('list')}>
          <ProgressList
            onCompleted={() => setAnimStatus(AnimationStatus.ANIMATING_OUT)}
          >
            {steps.map((key: WizardSubmitProgress, index) => (
              <WizardProgressItem
                key={key}
                progressId={key}
                loading={!completedSteps.includes(key)}
                offsetInDegrees={index * OFFSET_STEP_IN_DEGREES}
                shouldFlip={() => animStatus !== AnimationStatus.ANIMATING_OUT}
              />
            ))}
            <FakeProgressItem
              loading={steps.length !== completedSteps.length}
              offsetInDegrees={steps.length * OFFSET_STEP_IN_DEGREES}
            />
          </ProgressList>
        </div>
      </Flipped>
    </Flipper>
  );
};

export default WizardProgressStep;
