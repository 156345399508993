import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ConnectedModal from 'containers/ConnectedModal';
import { clearTrackForStyling } from 'redux/modules/embed/actions';
import { aspectRatioSelector } from 'redux/modules/embed/selectors';
import { Dispatch } from 'redux/types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { block } from '../../utils/block';
import GlobalStyleModalContents from '../GlobalStyleModalContents';

const { useCallback } = React;

export interface IProps {
  className?: string;
}

const GlobalStyleModal: React.FC<IProps> = ({ className }) => {
  const dispatch = useDispatch<Dispatch>();
  const aspectRatio = useSelector(aspectRatioSelector);
  const ratioName = getAspectRatioName(aspectRatio);
  const ratioClass = ratioName && `${block()}--${ratioName}`;

  const modalClassName = classNames(block(), ratioClass, className);

  const handleHide = useCallback(() => {
    dispatch(clearTrackForStyling());
  }, [dispatch]);

  return (
    <ConnectedModal
      className={modalClassName}
      name="GlobalStyleModal"
      onHide={handleHide}
      backdrop="static"
      title="Track Properties"
    >
      <GlobalStyleModalContents />
    </ConnectedModal>
  );
};

export { GlobalStyleModal as default, IProps as GlobalStyleModalProps };
