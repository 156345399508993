import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isFinite } from 'underscore';

import {
  captionsDeletedPhraseIdsSelector,
  phrasesSelector,
  transcriptOffsetMillisSelector,
} from 'redux/modules/embed/selectors';
import { State } from 'redux/types';
import { PropsOf } from 'types';
import Transcript, { TranscriptProps as Props } from './Transcript';

type StateProps = Pick<Props, 'activePhraseId' | 'phrases'>;
interface OwnProps {
  position: number;
}

const positionSelector = (_, props: OwnProps) => props.position;

const currentPhrasesSelector = createSelector(
  [phrasesSelector, captionsDeletedPhraseIdsSelector],
  (phrases, deletedPhraseIds) => {
    if (!phrases) return undefined;

    return phrases
      .filter(phrase => !deletedPhraseIds.includes(phrase.get('id')))
      .map(p => ({
        endMillis: p.get('endMillis'),
        id: p.get('id'),
        startMillis: p.get('startMillis'),
      }));
  },
);

const orderedPhraseIdsSelector = createSelector(
  currentPhrasesSelector,
  phrases =>
    !phrases
      ? undefined
      : phrases
          .sort((p1, p2) => p1.startMillis - p2.startMillis)
          .map(p => p.id)
          .toArray(),
);

const absolutePositionSelector = createSelector(
  [positionSelector, transcriptOffsetMillisSelector],
  (position, offset) => position + offset,
);

const activePhraseIdSelector = createSelector(
  [absolutePositionSelector, currentPhrasesSelector],
  (position, phrases) => {
    if (!isFinite(position) || !phrases) return undefined;
    const phrase = phrases.find(
      p => position >= p.startMillis && position <= p.endMillis,
    );
    return phrase && phrase.id;
  },
);

const mapStateToProps = (state: State, props: OwnProps): StateProps => ({
  activePhraseId: activePhraseIdSelector(state, props),
  phrases: orderedPhraseIdsSelector(state),
});

const component = connect(mapStateToProps)(Transcript);
export type TranscriptProps = PropsOf<typeof component>;
export default component;
