import cn from 'classnames';
import memoize from 'memoizee';
import * as React from 'react';
import { Flipper } from 'react-flip-toolkit';
import { noop } from 'underscore';

import TimelineContext from 'blocks/Timeline/TimelineContext';
import { VideoEditorPlaybackTimeContext as VideoEditorPlaybackTimeContextType } from 'containers/VideoEditor/types';
import VideoEditorPlaybackTimeContext from 'containers/VideoEditor/VideoEditorPlaybackTimeContext';
import { TrackType } from 'types';
import { getValue } from 'utils/collections';
import Ruler from '../../components/Ruler';
import TimelineBody from '../../components/TimelineBody';
import TimelineHeader from '../../components/TimelineHeader';
import TimelineModals from '../../components/TimelineModals';
import Tracks from '../../components/Tracks';
import { GUTTER_PX } from '../../constants';
import {
  TimelineContext as TimelineContextValue,
  TrackConfig,
} from '../../types';
import {
  block,
  getNewTrackIds,
  millisToPosition,
  positionToMillis,
} from '../../utils';

interface Viewport {
  startMillis: number;
  endMillis: number;
}

export interface TimelineProps {
  className?: string;
  durationMillis?: number;
  enableTrackAnimations?: boolean;
  onDeleteTrackClick?: (trackId: string) => void;
  onAddTrackClick?: () => void;
  onAddTrack?: (type: TrackType) => void;
  onModalHide?: () => void;
  onModalShow?: () => void;
  onSeek?: (millis: number) => void;
  // this only fires as a result of a user-initiated track addition.  it won't
  // fire if the user doesn't add the track via the AddTrackModal
  onTrackEntered?: (track: TrackConfig) => void;
  onViewportChange?: (viewport: Viewport) => void;
  playing?: boolean;
  pxPerSec: number;
  tracks: TrackConfig[];
  viewport?: Viewport;
}

interface State {
  bodyWidth: number;
  draggingPlayhead: boolean;
  prevPositionMillis: number;
  loadedTracks: string[];
}

export default class Timeline extends React.Component<TimelineProps, State> {
  private previousContext: VideoEditorPlaybackTimeContextType;

  public static defaultProps: Partial<TimelineProps> = {
    durationMillis: 0,
    onAddTrack: noop,
    onModalHide: noop,
    onModalShow: noop,
    onSeek: noop,
    onViewportChange: noop,
  };

  private body: TimelineBody;
  private tracks: { [id: string]: HTMLElement } = {};

  public state: Readonly<State> = {
    bodyWidth: 0,
    loadedTracks: [],
    draggingPlayhead: false,
    prevPositionMillis: 0,
  };

  public componentDidMount() {
    this.previousContext = this.context;
  }

  public componentDidUpdate(
    prevProps: Readonly<TimelineProps>,
    prevState: Readonly<State>,
  ) {
    const {
      durationMillis,
      enableTrackAnimations,
      onSeek,
      onViewportChange,
      tracks,
      viewport,
    } = this.props;
    const { bodyWidth } = this.state;
    const { positionSec } = this.context;
    const {
      durationMillis: prevDurationMillis,
      tracks: prevTracks,
    } = prevProps;

    const prevPositionMillis = this.previousContext.positionSec * 1000;
    const positionMillis = positionSec * 1000;

    const { bodyWidth: prevBodyWidth } = prevState;

    if (bodyWidth !== prevBodyWidth) {
      /*
       * if viewport is undefined  then this is the update from the first
       * render.  in that case the viewport starts at -GUTTER_PX and not 0.
       */
      const startMillis = getValue(
        viewport,
        'startMillis',
        this.positionToMillis(-GUTTER_PX),
      );

      onViewportChange({
        startMillis,
        endMillis: startMillis + this.positionToMillis(bodyWidth),
      });
    }

    if (
      durationMillis < prevDurationMillis &&
      positionMillis > durationMillis
    ) {
      onSeek(durationMillis);
    }

    if (positionMillis !== prevPositionMillis) {
      this.setState({ prevPositionMillis: positionMillis });
    }

    if (tracks !== prevTracks) {
      const newTrackIds = getNewTrackIds(prevTracks, tracks);

      if (newTrackIds.length > 0) {
        if (enableTrackAnimations) {
          this.body.scrollBody(0);
          window.setTimeout(() => {
            this.appendLoadedTracks(newTrackIds);
          }, 500);
        } else {
          this.appendLoadedTracks(newTrackIds);
        }
      }
    }

    this.previousContext = this.context;
  }

  private handleTimelineResize = (width: number) => {
    this.setState({ bodyWidth: width });
  };

  private handleRulerSeek = (positionSec: number) => {
    const { onSeek } = this.props;
    onSeek(positionSec * 1000);
  };

  private handleTimelineClick = (position: number) => {
    const { onSeek } = this.props;
    onSeek(this.positionToMillis(position));
  };

  private handleUserScroll = (e: React.UIEvent<HTMLElement>) => {
    const { onViewportChange } = this.props;
    const { bodyWidth } = this.state;

    /*
     * NB: this can be negative.  there is a gutter between scrollLeft === 0 and
     * timecode 0, meaning that if scrollLeft <= GUTTER_PX, the user is still
     * only seeing timecode 0 on the left side.  as the user scrolls right, it's
     * as if the viewport grows by GUTTER_PX
     */
    const left = (e.target as HTMLElement).scrollLeft - GUTTER_PX;
    const right = left + bodyWidth;

    const startMillis = this.positionToMillis(left);
    const endMillis = this.positionToMillis(right);

    onViewportChange({ startMillis, endMillis });
  };

  private handlePlayheadDragStart = () =>
    this.setState({ draggingPlayhead: true });

  private handlePlayheadDragStop = () =>
    this.setState({ draggingPlayhead: false });

  private handleDeleteTrack = labelConfig => {
    const { onDeleteTrackClick } = this.props;
    onDeleteTrackClick(labelConfig.id);
  };

  private handleTrackMount = (id: string, el: HTMLElement) => {
    this.tracks[id] = el;
  };

  private handleTrackUnmount = (id: string) => {
    delete this.tracks[id];
  };

  private handleTrackEntered = (id: string) => {
    const { enableTrackAnimations, onTrackEntered, tracks } = this.props;

    if (!enableTrackAnimations) return;

    onTrackEntered(tracks.find(t => t.id === id));
  };

  private appendLoadedTracks = (trackIds: string[]) => {
    this.setState(({ loadedTracks }) => ({
      loadedTracks: [...loadedTracks, ...trackIds],
    }));
  };

  private positionToMillis(pos: number) {
    const { pxPerSec } = this.props;
    return positionToMillis(pos, pxPerSec);
  }

  private millisToPosition(millis: number) {
    const { pxPerSec } = this.props;
    return millisToPosition(millis, pxPerSec);
  }

  private setBody = (el: TimelineBody) => {
    this.body = el;
  };

  private getContextValue = memoize(
    (
      tracks: TimelineProps['tracks'],
      loadedTrackIds: State['loadedTracks'],
    ): TimelineContextValue => ({
      tracks: tracks.filter(t => loadedTrackIds.indexOf(t.id) >= 0),
    }),
  );

  private getFlipKey = memoize(
    (tracks: TimelineProps['tracks']) =>
      `${tracks.length}-${tracks.map(t => t.id).join('')}`,
    { max: 1 },
  );

  public render() {
    const {
      className,
      durationMillis,
      onAddTrack,
      onAddTrackClick,
      onModalHide,
      onModalShow,
      playing,
      pxPerSec,
      tracks,
      viewport,
    } = this.props;

    const {
      bodyWidth,
      draggingPlayhead,
      loadedTracks,
      prevPositionMillis,
    } = this.state;

    // TODO confusing to have TimelineBody.width !== bodyWidth
    const renderedWidth =
      this.millisToPosition(durationMillis) + bodyWidth + GUTTER_PX;

    const context = this.getContextValue(tracks, loadedTracks);

    return (
      <TimelineContext.Provider value={context}>
        <VideoEditorPlaybackTimeContext.Consumer>
          {({ positionSec }) => {
            const playheadMoving = prevPositionMillis !== positionSec * 1000;

            return (
              <Flipper
                className={cn(block(), className)}
                flipKey={this.getFlipKey(context.tracks)}
              >
                {viewport && pxPerSec && (
                  <TimelineHeader onAddTrackClick={onAddTrackClick}>
                    <Ruler
                      draggingPlayhead={draggingPlayhead}
                      endSec={viewport.endMillis / 1000}
                      onPlayheadDragStart={this.handlePlayheadDragStart}
                      onPlayheadDragStop={this.handlePlayheadDragStop}
                      onSeek={this.handleRulerSeek}
                      pxPerSec={pxPerSec}
                      startSec={viewport.startMillis / 1000}
                    />
                  </TimelineHeader>
                )}
                <TimelineBody
                  onClick={this.handleTimelineClick}
                  onDeleteTrack={this.handleDeleteTrack}
                  onLabelEntered={this.handleTrackEntered}
                  onResize={this.handleTimelineResize}
                  onUserScroll={this.handleUserScroll}
                  pxPerSec={pxPerSec}
                  ref={this.setBody}
                  scrollLeft={
                    viewport &&
                    this.millisToPosition(viewport.startMillis) + GUTTER_PX
                  }
                  width={renderedWidth}
                >
                  {context.tracks && (
                    <Tracks
                      disabled={draggingPlayhead || playing}
                      onTrackMount={this.handleTrackMount}
                      onTrackUnmount={this.handleTrackUnmount}
                      pxPerSec={pxPerSec}
                      showButton={
                        !playing && !draggingPlayhead && !playheadMoving
                      }
                    />
                  )}
                </TimelineBody>
                <TimelineModals
                  onHide={onModalHide}
                  onShow={onModalShow}
                  onSubmit={onAddTrack}
                />
              </Flipper>
            );
          }}
        </VideoEditorPlaybackTimeContext.Consumer>
      </TimelineContext.Provider>
    );
  }
}

Timeline.contextType = VideoEditorPlaybackTimeContext;
