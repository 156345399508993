import cn from 'classnames';
import React from 'react';

import { block } from './utils';

interface TakeoverProps {
  className?: string;
}

const Takeover: React.FunctionComponent<TakeoverProps> = props => {
  const { children, className } = props;

  return <div className={cn(block(), className)}>{children}</div>;
};

export default Takeover;
