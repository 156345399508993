import { fromJS, Record, RecordOf } from 'immutable';
import { omitUndefined } from 'utils/collections';
import { withValue } from 'utils/control';
import { ILibrary, ISelectedAudio } from './types';

export const selectedAudioFactory = Record<ISelectedAudio>({
  file: undefined,
  id: undefined,
  isFetching: false,
});

export const libraryFactory = Record<ILibrary>({
  isFetching: false,
});

export function stateFromJs({ library, selectedAudio }: any = {}): RecordOf<
  ISelectedAudio
> {
  return fromJS(
    omitUndefined({
      library: withValue(library, libraryFactory),
      selectedAudio: withValue(selectedAudio, selectedAudioFactory),
    }),
  );
}
