import cn from 'classnames';
import * as React from 'react';

import { MagnifyingGlassIcon } from 'components/icons';
import ProjectButton from '../../containers/ProjectButton';
import { INewProjectButtonProps } from './types';

const AutomatedButton: React.SFC<INewProjectButtonProps> = ({
  className,
  description,
  icon,
  title,
}) => (
  <ProjectButton
    beta
    className={cn('automated-button', className)}
    icon={icon}
    projectType="recommended"
    title={title}
    description={description}
    to={{
      pathname: '/wizard',
      query: {
        type: 'automated',
      },
    }}
  />
);

AutomatedButton.defaultProps = {
  description:
    'Prefill a video with media based on your news, sports, and other audio',
  icon: <MagnifyingGlassIcon />,
  title: 'Find My Content',
};

export default AutomatedButton;
