import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A>(method: M, args: A) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export function getProfileById(userId: number): types.GetProfileByIdAction {
  return apiCallAction(types.ServiceMethod.GET_PROFILE_BY_ID, [
    userId,
  ] as types.GetProfileByIdArgs);
}

export function getMyProfile(): types.GetMyProfileAction {
  return apiCallAction(
    types.ServiceMethod.GET_MY_PROFILE,
    undefined as types.GetMyProfileArgs,
  );
}

export function getMyIntegrators(): types.GetIntegratorsAction {
  return apiCallAction(types.ServiceMethod.GET_INTEGRATORS_FOR_USER, undefined);
}

export function getMyOrganization(): types.GetOrganizationAction {
  return apiCallAction(
    types.ServiceMethod.GET_ORGANIZATION_FOR_USER,
    undefined,
  );
}

export function createProfile(email: string, fullName?: string) {
  return apiCallAction(types.ServiceMethod.CREATE_PROFILE, [
    email,
    fullName,
  ] as types.CreateProfileArgs);
}

export function updateProfile(email: string) {
  return apiCallAction(types.ServiceMethod.UPDATE_PROFILE, [
    email,
  ] as types.UpdateProfileArgs);
}

export function updateUserPreferences(
  videoExportEmailAlertEnabled: boolean,
  marketingEmailEnabled: boolean,
  automationEmailAlertEnabled: boolean,
  reminderEmailEnabled: boolean,
) {
  return apiCallAction(types.ServiceMethod.UPDATE_PREFERENCES_FOR_USER, [
    videoExportEmailAlertEnabled,
    marketingEmailEnabled,
    automationEmailAlertEnabled,
    reminderEmailEnabled,
  ] as types.UpdatePreferencesArgs);
}

export function deleteUserAccount() {
  return apiCallAction(types.ServiceMethod.DELETE_USER_ACCOUNT, undefined);
}

export function getMyPreferences(): types.GetPreferencesAction {
  return apiCallAction(types.ServiceMethod.GET_PREFERENCES_FOR_USER, undefined);
}

/**
 * if token arg is passed, will try to exchange that token for a new one.
 * If no token is passed, will used authenticated user's token from redux
 */
export function postRenew(tokenOverride?: string) {
  return apiCallAction(types.ServiceMethod.RENEW_TOKEN, [
    tokenOverride,
  ] as types.RenewTokenArgs);
}
