import React from 'react';
import { AUTO_POSTING_PLATFORM_NAME_MAP } from './constants';
import {
  AutomationOptionsCardsOptions,
  AutoPostOptions,
  GetAutoPostOptionArgs,
} from './types';

export function getAutoPostOptions({
  id,
  platform,
  icon,
}: GetAutoPostOptionArgs): AutomationOptionsCardsOptions {
  return {
    id,
    contents: (
      <>
        <strong>
          Auto-post videos to {AUTO_POSTING_PLATFORM_NAME_MAP[platform]}
        </strong>
        <br />
        as a private or public video
      </>
    ),
    icon,
    value: {
      automationOption: AutoPostOptions.AUTO_POST,
    },
  };
}
