import { actions as urlGenApiActions } from 'redux/middleware/api/url-generator-service';
import { ThunkAction } from 'redux/types';
import { AspectRatioName } from 'types';
import { getAspectRatio } from 'utils/aspect-ratio';
import { DEFAULT_TRACK_ORDER } from 'utils/embed/tracks';
import { pollForThirdPartyAsset } from '../common/actions';
import { exportFromWizard } from '../wizard-export/actions';
import { Type } from './action-types';
import {
  clipTitleSelector,
  customTraceIdSelector,
  defaultWatermarkSelector,
  shouldPollForAssetsSelector,
  traceIdSelector,
  transcriptUrlSelector,
  videoUrlSelector,
} from './selectors';

const pollForAssets = (): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  const shouldPoll = shouldPollForAssetsSelector(getState());

  if (!shouldPoll) {
    return;
  }

  try {
    await dispatch(pollForThirdPartyAsset(videoUrlSelector));
  } catch {
    throw new Error('Error accessing video clip, please try again shortly');
  }

  try {
    await dispatch(pollForThirdPartyAsset(transcriptUrlSelector));
  } catch {
    throw new Error('Error accessing transcript, please try again shortly');
  }
};

const loadTrace = (
  customTraceId: string | undefined,
  traceId: string | undefined,
): ThunkAction<Promise<void>> => async dispatch => {
  const { response } = await (traceId
    ? dispatch(urlGenApiActions.getTraceById(traceId))
    : dispatch(urlGenApiActions.getTraceByCustomId(customTraceId)));

  dispatch({
    payload: {
      traceId: response.result,
    },
    type: Type.VIDEO_CLIP_WIZARD_TRACE_ID_SET,
  });

  await dispatch(pollForAssets());
};

export const loadWizardData = (
  customTraceId: string | undefined,
  traceId: string | undefined,
): ThunkAction<Promise<void>> => async dispatch => {
  if (!customTraceId && !traceId) {
    throw new Error('Insufficient data for loading the wizard.');
  }

  dispatch({
    type: Type.VIDEO_CLIP_WIZARD_DATA_LOAD_REQUEST,
  });

  try {
    if (customTraceId || traceId) {
      await dispatch(loadTrace(customTraceId, traceId));
    }

    dispatch({ type: Type.VIDEO_CLIP_WIZARD_DATA_LOAD_SUCCESS });
  } catch (err) {
    dispatch({ type: Type.VIDEO_CLIP_WIZARD_DATA_LOAD_FAILURE });

    throw err;
  }
};

export const createVideoClipProject = (
  aspectRatioName: AspectRatioName,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const ratio = getAspectRatio(aspectRatioName);

  return dispatch(
    exportFromWizard({
      aspectRatio: {
        width: ratio.get('width'),
        height: ratio.get('height'),
      },
      edgeVideos: undefined,
      projectName: clipTitleSelector(getState()),
      initiateExport: false,
      projectCreatMethod: 'videoTranscript',
      transcription: {
        transcribe: false,
        transcriptUrl: transcriptUrlSelector(getState()),
      },
      video: {
        source: videoUrlSelector(getState()),
        endMillis: undefined,
        startMillis: undefined,
        originalDurationMillis: undefined,
      },
      textOverlays: [],
      slideshow: [],
      layers: [...DEFAULT_TRACK_ORDER],
      watermark: defaultWatermarkSelector(getState()),
      backgroundColor: undefined,
      templateId: undefined,
      mainImage: undefined,
      progress: undefined,
      soundwave: undefined,
      timer: undefined,
      traceId: traceIdSelector(getState()),
      customTraceId: customTraceIdSelector(getState()),
    }),
  );
};
