import * as React from 'react';

import { IconProps } from 'components/icons/types';

/* tslint:disable-next-line:variable-name */
const Download: React.SFC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.95 10.87a.56.56 0 0 1 .55.55v3.76a.92.92 0 0 1-.92.91H2.56a.92.92 0 0 1-.91-.9v-3.77a.55.55 0 0 1 .55-.55h.7a.56.56 0 0 1 .55.55v2.85h11.22v-2.85a.55.55 0 0 1 .56-.55h.72zm-7.47.63L5.3 8.3a.73.73 0 0 1 0-.99l.25-.25a.73.73 0 0 1 .99 0l1.63 1.63V2.94a.72.72 0 0 1 .7-.69h.35c.38.01.68.31.7.7v5.74l1.63-1.64a.73.73 0 0 1 .98 0l.3.26a.73.73 0 0 1 0 .98l-3.09 3.1-.11.11a.72.72 0 0 1-.51.22h-.15a.72.72 0 0 1-.5-.22z"
    />
  </svg>
);

export default Download;
