import cn from 'classnames';
import React from 'react';
import Tooltip from 'components/Tooltip';
import bem from 'utils/bem';
import { capitalize } from 'utils/string';
import { formatDurationInFull } from 'utils/time';

export interface MaxDurationShareButtonProps {
  children: React.ReactElement<{ disabled: boolean }>;
  className?: string;
  maxVideoDurationSec: number;
  platform: string;
  videoDurationSec: number;
  tooltTipContent?: React.ReactNode;
}

const block = bem('max-duration-share-button');

function MaxDurationShareButton({
  children,
  className,
  maxVideoDurationSec,
  platform,
  videoDurationSec,
  tooltTipContent,
}: MaxDurationShareButtonProps) {
  return (
    <Tooltip
      id={`${platform}-too-long-tooltip`}
      className={block('tooltip')}
      content={
        tooltTipContent ??
        `${capitalize(
          platform,
        )} does not accept videos longer than ${formatDurationInFull(
          maxVideoDurationSec * 1000,
        )}`
      }
      placement="top"
      show={videoDurationSec <= maxVideoDurationSec ? false : undefined}
    >
      <div className={cn(block(), className)}>
        {React.cloneElement(children, {
          disabled:
            videoDurationSec > maxVideoDurationSec || children.props.disabled,
        })}
      </div>
    </Tooltip>
  );
}

export default MaxDurationShareButton;
