import * as React from 'react';
import { isUndefined } from 'underscore';
import DraggableWorkspace, {
  DraggableWorkspaceProps,
} from 'components/DraggableWorkspace';

import { block } from '../utils';

type PickedWorkspaceProps = Pick<DraggableWorkspaceProps, 'onSizeChange'>;

export interface ProgressAnimationWorkspaceProps extends PickedWorkspaceProps {
  aspectRatio: number;
  children?: React.ReactNode;
}

const ProgressAnimationWorkspace: React.SFC<ProgressAnimationWorkspaceProps> = ({
  aspectRatio,
  children,
  onSizeChange,
}) => (
  <DraggableWorkspace
    className={block('workspace')}
    style={{
      paddingTop: isUndefined(aspectRatio)
        ? undefined
        : `${100 / aspectRatio}%`,
      width: '100%',
    }}
    {...{ aspectRatio, onSizeChange }}
  >
    {children}
  </DraggableWorkspace>
);

export default ProgressAnimationWorkspace;
