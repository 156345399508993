import * as React from 'react';
import { IconProps } from './types';

function Pfizer(props: IconProps) {
  return (
    <svg width={60} height={35} {...props}>
      <path
        d="M37.155 0h-.003c-3.448 0-7.096.368-10.798 1.143C11.187 4.318.004 13.256 0 21.754c0 .65.065 1.296.198 1.936 1.097 5.281 6.593 9.029 14.227 10.553 1.614-6.247 5.325-20.544 5.325-20.544.728-2.828 1.135-3.92 1.855-5.15.938-1.591 2.442-2.906 4.55-3.098 1.146-.103 2.853.041 3.808.714.901.635 1.016 1.3.986 1.905-.062 1.25-.824 2.087-2.042 2.087-1.25 0-1.638-.92-1.644-1.526-.008-.826.48-.953.474-1.564-.003-.313-.286-.801-1.067-.679-.964.152-1.766.92-2.288 2.257-.123.31-.273.727-.46 1.438l-.959 3.738h6.32c-.826 3.003-2.225 8.338-2.617 9.8h-3.192l2.19-8.193h-3.129l-4.998 19.298c1.617.184 3.307.277 5.053.274 3.36-.007 6.924-.372 10.566-1.135C48.359 30.682 59.989 21.66 60 13.137c0-.63-.062-1.258-.191-1.879C58.356 4.272 48.912 0 37.157 0h-.002M9.088 8.93h3.734c5.727 0 6.13 2.933 5.645 5.021-.6 2.585-2.143 4.429-8.719 4.44l-1.41 5.23H5.126C5.715 21.441 9.088 8.93 9.088 8.93m28.814 11.228c0-3.547 3.182-6.571 6.582-6.571 1.692 0 3.152.788 3.152 2.13 0 1.393-1.104 2.826-2.775 3.369-1.039.35-2.09.555-3.853.555-.042.373-.025.517-.025.718 0 .946.802 1.753 1.917 1.753 1.497 0 2.91-1.378 3.075-1.545l.557 1.493a5.91 5.91 0 01-1.514 1.166c-.894.467-1.919.718-3.04.718-2.43 0-4.076-1.525-4.076-3.786m17.81-6.506c1.773 0 2.04 1.058 2.04 1.879 0 .963-.679 1.624-1.455 1.624-.59 0-.849-.237-.953-.341-.27-.272-.316-.55-.347-.781-.038-.272-.127-.535-.437-.586-.45-.071-.817.11-1.199.45-.515.46-.955 1.187-1.275 1.886-.222.482-.386.94-.478 1.265l-1.24 4.572h-3.215l2.636-9.8h3.118l-.56 1.965c.733-.89 2.104-2.133 3.365-2.133m-24.978.169h7.992c-.92 3.047-5.248 5.522-6.443 7.828h5.036l-.527 1.971h-8.178c0-3.096 5.164-6.673 6.146-8.192h-4.458l.432-1.607zm-18.83-3.43l-1.735 6.437c2.548-.007 4.149-.45 4.935-2.98.641-2.072.39-3.456-2.639-3.456h-.56zm30.983 5.29c-.799.614-1.412 1.582-1.651 2.663 1.453-.05 2.274-.268 3.028-.862.678-.534.983-1.135.983-1.641 0-.278-.17-.714-.95-.714-.356 0-.935.187-1.41.554"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Pfizer;
