import React from 'react';
import FadingScrollBars from 'components/FadingScrollBars';
import { block } from './utils';

export interface SelectMenuContainerProps {}

const View = props => (
  <div {...props} className={block('menu-scrollbars-view')} />
);

/**
 * Helper to build menus with the same look and feel as the rest of the component
 */
const SelectMenuContainer: React.FC<SelectMenuContainerProps> = ({
  children,
}) => (
  <div className={block('menu')}>
    <FadingScrollBars
      autoHeight
      className={block('menu-scrollbars')}
      faderClassName={block('menu-scrollbars-fader')}
      renderView={View}
    >
      {children}
    </FadingScrollBars>
  </div>
);

export default SelectMenuContainer;
