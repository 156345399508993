import cn from 'classnames';
import React, { useLayoutEffect } from 'react';
import uncontrollable from 'uncontrollable';

import TabContent from 'components/Tabs/TabContent';
import TabTitle from 'components/Tabs/TabTitle';
import { TabsProps } from 'components/Tabs/types';
import { callRender } from 'utils/react';
import TabsHeader from './TabsHeader';
import { block as b } from './utils';

const DEFAULT_OVERRIDES = {
  content: TabContent,
  header: TabsHeader,
  tabsContent: 'div' as const,
};

const Tabs: React.SFC<TabsProps> = ({
  tabs,
  activeTabKey,
  onTabSelect,
  className,
  condensed,
  overrides,
  padding,
  theme,
}) => {
  const { content, header, tabsContent } = {
    ...DEFAULT_OVERRIDES,
    ...overrides,
  };

  useLayoutEffect(() => {
    const tabKeys = tabs.map(tab => tab.tabKey);

    // if an invalid tab is selected, switch to the first valid one
    if (activeTabKey !== undefined && !tabKeys.includes(activeTabKey)) {
      onTabSelect(tabKeys[0]);
    }
  }, [activeTabKey, onTabSelect, tabs]);

  return (
    <div className={cn(b(), className)}>
      {callRender(header, {
        children: tabs.map(tab => (
          <TabTitle
            key={`tab-${tab.tabKey}-title`}
            tabKey={tab.tabKey}
            title={tab.title}
            isDisabled={tab.isDisabled}
            onSelect={onTabSelect}
            className={cn(
              tab.titleClassName,
              b('title', {
                active: tab.tabKey === activeTabKey,
                condensed: !!condensed,
                disabled: tab.isDisabled,
                [theme]: !!theme,
              }),
            )}
          />
        )),
        className: b('header'),
        nTabs: tabs.length,
      })}
      {callRender(tabsContent, {
        children: tabs.map(tab => (
          <React.Fragment key={tab.tabKey}>
            {callRender(content, {
              padding,
              theme,
              active: tab.tabKey === activeTabKey,
              children: tab.content,
              className: tab.contentClassName,
              tabKey: tab.tabKey,
            })}
          </React.Fragment>
        )),
        className: b('content'),
      })}
    </div>
  );
};

Tabs.defaultProps = {
  className: undefined,
  condensed: false,
  padding: undefined,
  theme: undefined,
};

export default uncontrollable(Tabs, { activeTabKey: 'onTabSelect' });
