import * as React from 'react';

import { IconProps } from './types';

interface ExportSpeedProps extends IconProps {
  pro: boolean;
}

function ExportSpeed({
  fill = 'currentColor',
  pro,
  ...props
}: ExportSpeedProps) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {pro ? (
        <path
          d="M18 9a9 9 0 0 1-9 9 9 9 0 0 1-9-9 9 9 0 0 1 9-9 9 9 0 0 1 9 9zm-6.75 3.375c0-.559-.204-1.069-.538-1.462l2.693-5.183a.847.847 0 0 0-.359-1.139.849.849 0 0 0-1.139.359l-2.693 5.186a2.252 2.252 0 0 0-2.464 2.24A2.252 2.252 0 0 0 9 14.624a2.252 2.252 0 0 0 2.25-2.25z"
          fill={fill}
          fillRule="nonzero"
        />
      ) : (
        <path
          d="M18 9a9 9 0 0 1-9 9 9 9 0 0 1-9-9 9 9 0 0 1 9-9 9 9 0 0 1 9 9zm-6.75 3.375c0-.946-.58-1.754-1.406-2.085V3.094A.842.842 0 0 0 9 2.25a.842.842 0 0 0-.844.844v7.196A2.248 2.248 0 0 0 9 14.625a2.252 2.252 0 0 0 2.25-2.25z"
          fill={fill}
          fillRule="nonzero"
        />
      )}
    </svg>
  );
}

export default ExportSpeed;
