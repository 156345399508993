import cn from 'classnames';
import * as React from 'react';
import { ModalBody as BsModalBody } from 'react-bootstrap';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const ModalBody: React.SFC<IProps> = ({ children, className, style }) => (
  <BsModalBody className={cn('hl-modal__body', className)} style={style}>
    {children}
  </BsModalBody>
);

export default ModalBody;
export { IProps as ModalBodyProps };
