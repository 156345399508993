import * as React from 'react';

import { block } from '../../utils';

export interface ShareKbLinkProps {
  onClick?: () => void;
}

const ShareKbLink: React.SFC<ShareKbLinkProps> = ({ onClick }) => (
  <a
    className={block('kb')}
    href="https://learn.headliner.app/hc/en-us/sections/360000828573-Sharing-Videos"
    onClick={onClick}
    rel="noopener noreferrer"
    target="_blank"
  >
    Knowledge Base
  </a>
);

export default ShareKbLink;
