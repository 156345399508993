import * as React from 'react';

import { MultiVideoCamera } from 'components/icons';
import {
  PaidSubscriptionHeader,
  proFeatures,
} from '../../components/PaidSubscription';
import { block } from './utils';

const ProSubscriptionHeader: React.FC = () => {
  return (
    <PaidSubscriptionHeader
      icon={
        <div className={block('icon-container')}>
          <MultiVideoCamera className={block('icon')} />
        </div>
      }
      title="Headliner Pro"
    >
      <PaidSubscriptionHeader.Features features={proFeatures} />
    </PaidSubscriptionHeader>
  );
};

export default ProSubscriptionHeader;
