import { DestinationPreset, MaxDurationKey } from './types';

const MAX_DURATION_VALID_KEYS: MaxDurationKey[] = [
  'InstagramReel',
  'InstagramStory',
  'InstagramPost',
  'FacebookReel',
  'FacebookStory',
  'FacebookPost',
  'YouTubeShort',
  'TikTokStory',
  'TikTokPost',
];

export const FACEBOOK_REEL_PRESET: DestinationPreset = {
  aspectRatio: 'portrait',
  durationMs: 60000,
  key: 'FacebookReel',
  type: 'reel',
};

export const FACEBOOK_STORY_PRESET: DestinationPreset = {
  aspectRatio: 'portrait',
  durationMs: 120000,
  key: 'FacebookStory',
  type: 'story',
};

export const FACEBOOK_POST_PRESET: DestinationPreset = {
  aspectRatio: 'portrait',
  durationMs: 600000,
  key: 'FacebookPost',
  type: 'post',
};

export const FACEBOOK_EPISODE_PRESET: DestinationPreset = {
  aspectRatio: 'landscape',
  key: 'FacebookEpisode',
  type: 'episode',
};

export const INSTAGRAM_REEL_PRESET: DestinationPreset = {
  aspectRatio: 'portrait',
  durationMs: 60000,
  key: 'InstagramReel',
  type: 'reel',
};

export const MORE_OPTIONS_TWITTER_PRESET: DestinationPreset = {
  aspectRatio: 'square',
  durationMs: 60000,
  key: 'Twitter',
  type: 'post',
};

export const MORE_OPTIONS_LINKEDIN_PRESET: DestinationPreset = {
  aspectRatio: 'square',
  durationMs: 60000,
  key: 'LinkedIn',
  type: 'post',
};

export const MORE_OPTIONS_SNAPCHAT_PRESET: DestinationPreset = {
  aspectRatio: 'square',
  durationMs: 10000,
  key: 'SnapChat',
  type: 'post',
};

export const MORE_OPTIONS_FULLCLIP_PRESET: DestinationPreset = {
  aspectRatio: 'square',
  durationMs: 600000,
  key: 'FullClip',
  type: 'clip',
};

export const INSTAGRAM_STORY_PRESET: DestinationPreset = {
  aspectRatio: 'portrait',
  durationMs: 60000,
  key: 'InstagramStory',
  type: 'story',
};

export const INSTAGRAM_POST_PRESET: DestinationPreset = {
  aspectRatio: 'square',
  durationMs: 60000,
  key: 'InstagramPost',
  type: 'post',
};

export const TIKTOK_STORY_PRESET: DestinationPreset = {
  aspectRatio: 'portrait',
  durationMs: 15000,
  key: 'TikTokStory',
  type: 'story',
};

export const TIKTOK_POST_PRESET: DestinationPreset = {
  aspectRatio: 'portrait',
  durationMs: 60000,
  key: 'TikTokPost',
  type: 'post',
};

export const YOUTUBE_EPISODE_PRESET: DestinationPreset = {
  aspectRatio: 'landscape',
  key: 'YouTubeEpisode',
  type: 'episode',
};

export const YOUTUBE_SHORT_PRESET: DestinationPreset = {
  aspectRatio: 'portrait',
  durationMs: 60000,
  key: 'YouTubeShort',
  type: 'short',
};

export const YOUTUBE_CLIP_PRESET: DestinationPreset = {
  aspectRatio: 'landscape',
  durationMs: 600000,
  key: 'YouTubeClip',
  type: 'clip',
};

export const DESTINATION_PRESETS = {
  facebook: {
    reel: FACEBOOK_REEL_PRESET,
    story: FACEBOOK_STORY_PRESET,
    post: FACEBOOK_POST_PRESET,
    episode: FACEBOOK_EPISODE_PRESET,
  },
  instagram: {
    reel: INSTAGRAM_REEL_PRESET,
    story: INSTAGRAM_STORY_PRESET,
    post: INSTAGRAM_POST_PRESET,
  },
  more: {
    twitter: MORE_OPTIONS_TWITTER_PRESET,
    linkedin: MORE_OPTIONS_LINKEDIN_PRESET,
    snapchat: MORE_OPTIONS_SNAPCHAT_PRESET,
    fullclip: MORE_OPTIONS_FULLCLIP_PRESET,
  },
  tiktok: {
    story: TIKTOK_STORY_PRESET,
    post: TIKTOK_POST_PRESET,
  },
  youtube: {
    short: YOUTUBE_SHORT_PRESET,
    episode: YOUTUBE_EPISODE_PRESET,
    clip: YOUTUBE_CLIP_PRESET,
  },
};

export function checkIfDestinationPresetExists(presetName: string): boolean {
  return presetName in DESTINATION_PRESETS;
}

export const getPresetMaxDurationKey = (
  selectedPreset: DestinationPreset['key'],
): MaxDurationKey => {
  return MAX_DURATION_VALID_KEYS.includes(selectedPreset as MaxDurationKey)
    ? (selectedPreset as MaxDurationKey)
    : 'not set';
};
