import cn from 'classnames';
import React from 'react';

import { episodeListBlock as block } from '../../../utils';

export interface EpisodeRowActionButtonLabelProps {
  children?: React.ReactNode;
  className?: string;
}

const EpisodeRowActionButtonLabel: React.FC<EpisodeRowActionButtonLabelProps> = ({
  children,
  className,
}) => (
  <span className={cn(block('episode-action-button-label'), className)}>
    {children}
  </span>
);

export default EpisodeRowActionButtonLabel;
