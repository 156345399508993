import * as React from 'react';

import { Omit } from 'types';
import StyleButton, { StyleButtonProps } from './StyleButton';
import StyleTile, { StyleTileProps } from './StyleTile';

export type StyleTileButtonProps = StyleButtonProps &
  Omit<StyleTileProps, 'children'>;

const StyleTileButton: React.SFC<StyleTileButtonProps> = ({
  className,
  ...buttonProps
}) => (
  <StyleTile className={className}>
    <StyleButton {...buttonProps} />
  </StyleTile>
);

export default StyleTileButton;
