import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hideModal, pushModal, replaceModal } from 'redux/modules/modal';
import { subtierSelector } from 'redux/modules/pricing';
import { PlanCancellationReason } from 'types';
import { isProSubTier } from 'utils/pricing';

export default () => {
  const dispatch = useDispatch();
  const subtier = useSelector(subtierSelector);

  const onDeleteAccount = React.useCallback(() => {
    dispatch(replaceModal({ name: 'DeleteAccount' }));
  }, [dispatch]);

  const onSubmit = (reason: PlanCancellationReason) => {
    dispatch(hideModal());

    if (
      isProSubTier(subtier) &&
      reason === PlanCancellationReason.CANT_AFFORD
    ) {
      dispatch(pushModal({ name: 'DowngradeUpsellModal', params: { reason } }));
    } else {
      dispatch(
        pushModal({
          name: 'CancelReasonDetails',
          params: { reason },
        }),
      );
    }
  };

  return { onDeleteAccount, onSubmit };
};
