import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { userIsNotEnterpriseOrApi } from 'components/hoc/auth';

import AppsSettings from './AppsSettings/AppsSettingsContainer';
import EmailSettings from './EmailSettings';
import FontSettings from './FontSettings';
import PasswordSettings from './PasswordSettings';
import ReferralsSettings from './ReferralsSettings';
import SubscriptionSettings from './SubscriptionSettings';
import VideoSettings from './VideoSettings';

const NotEnterpriseReferralSettings = userIsNotEnterpriseOrApi(
  ReferralsSettings,
);

export interface RoutesProps {}

const Routes: React.FC<RoutesProps> = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={url}
        render={() => <Redirect to={`${url}/my-plan`} />}
      />
      <Route exact path={`${url}/my-plan`} component={SubscriptionSettings} />
      <Route
        exact
        path={`${url}/referrals`}
        component={NotEnterpriseReferralSettings}
      />
      <Route exact path={`${url}/email`} component={EmailSettings} />
      <Route exact path={`${url}/password`} component={PasswordSettings} />
      <Route exact path={`${url}/fonts`} component={FontSettings} />
      <Route exact path={`${url}/apps`} component={AppsSettings} />
      <Route exact path={`${url}/video`} component={VideoSettings} />
    </Switch>
  );
};

export default Routes;
