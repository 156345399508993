import React from 'react';

import { TextOverlay } from 'blocks/TextOverlayModal';
import { validateOverlayV2Integrity } from 'blocks/TextOverlayModal/v2/style-import-utils';
import { OverlayScaler, TextOverlayV2 } from 'blocks/TextOverlayModal/v2/types';
import { DeepImmutableMap, IViewport } from 'types';

/**
 * Adjusts the font size px at the text style. For it, uses the current
 * scaler in order get a px value based on the font size vw calculation
 * with the new viewport width.
 */
export const recalculateFontSize = (
  textStyle: DeepImmutableMap<React.CSSProperties>,
  scaler: DeepImmutableMap<OverlayScaler>,
  targetViewport: IViewport,
): DeepImmutableMap<React.CSSProperties> => {
  const recalculatedFontSize = Math.round(
    (scaler.get('fontSizeVw') / 100) * targetViewport.width,
  );

  return textStyle.set('fontSize', recalculatedFontSize);
};

/**
 * Performs the necessary calculations for updating a v2 text overlay
 * when copying to a different aspect ratio.
 */
export const rescaleOverlayToViewport = (
  overlayItem: TextOverlay | TextOverlayV2,
  targetViewport: IViewport,
): TextOverlay => {
  const isV2 = validateOverlayV2Integrity(overlayItem);

  // if the overlay is not valid v2 overlay, no transformation should be done.
  if (!isV2) {
    return overlayItem;
  }

  // v2 integrity already ensures that both textStyle and scaler objects exist.
  const textStyle = overlayItem.getIn(['editor', 'textStyle']);
  const scaler = overlayItem.getIn(['editor', 'scaler']);

  return overlayItem.withMutations(s => {
    s.setIn(
      ['editor', 'textStyle'],
      recalculateFontSize(textStyle, scaler, targetViewport),
    );
  });
};
