import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useGiveUpMyPlan from 'blocks/Pricing/useGiveUpMyPlan';
import ConfirmationModal from 'components/ConfirmationModal';
import { Alert } from 'components/icons';
import LoadingOverlay from 'components/LoadingOverlay';
import Modal from 'components/Modal';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import { replaceModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { block } from './utils';

export interface ExitRetryPeriodModalContentsProps {}

const ExitRetryPeriodModalContents: React.FC<ExitRetryPeriodModalContentsProps> = () => {
  const dispatch = useDispatch<Dispatch>();
  const { onHide } = useConnectedModal('ExitRetryPeriod');

  const handleKeepMyPlan = useCallback((): void => {
    dispatch(replaceModal({ name: 'UpdateCreditCard' }));
  }, [dispatch]);

  const { isGivingUp, onGiveUpMyPlan } = useGiveUpMyPlan({
    onGiveUpComplete: onHide,
  });

  return (
    <>
      <Modal.Body className={block('body')}>
        {isGivingUp && <LoadingOverlay title="Updating Subscription" />}

        <Alert className={block('alert-icon')} />
        <ConfirmationModal.Title>
          Do you want to give up your plan?
        </ConfirmationModal.Title>
        <ConfirmationModal.Content>
          If you give up your plan you’ll lose all of your premium features.
        </ConfirmationModal.Content>
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton theme="submit-alt" onClick={handleKeepMyPlan}>
            Keep my plan
          </Modal.FooterButton>

          <Modal.FooterButton theme="submit" onClick={onGiveUpMyPlan}>
            Give up my plan
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default ExitRetryPeriodModalContents;
