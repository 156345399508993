import * as React from 'react';
import { connect } from 'react-redux';

import { Navigator } from 'components/Wizard';
import {
  clearMixpanel,
  onCancelWizard,
  onCompleteWizard,
  onStartVideoClipWizard,
  onWizardNext,
} from 'redux/modules/mixpanel';
import {
  clearNotification,
  showError,
} from 'redux/modules/notification/actions';
import { goToCreate, goToProject } from 'redux/modules/router/actions';
import { createVideoClipProject } from 'redux/modules/video-clip-wizard';
import { projectIdSelector } from 'redux/modules/wizard-export/selectors';
import { Dispatch, ThunkAction } from 'redux/types';
import { AspectRatioName } from 'types';
import { isApplicationError } from 'utils/ApplicationError';
import { FatalError } from 'utils/FatalError';
import { MIXPANEL_WIZARD_STEP_MAP } from '../constants';
import { VideoClipWizardProps } from './VideoClipWizard';

type DispatchProps = Pick<
  VideoClipWizardProps,
  'onMount' | 'onError' | 'onCancel' | 'onSubmit' | 'onComplete'
>;

const ERROR_ID = 'video-clip-wizard-error';

const onComplete = (): ThunkAction<void> => (dispatch, getState) => {
  const projectId = projectIdSelector(getState());

  dispatch(goToProject({ projectId, history: false, fromWizard: true }));
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onMount: () => {
    dispatch(clearNotification(ERROR_ID));
    dispatch(clearMixpanel());
    dispatch(onStartVideoClipWizard);
  },

  onError: error => {
    if (error instanceof FatalError) {
      dispatch(
        showError({
          message: 'There was an error creating your project',
          code: 'ER007',
          dismissAfterSec: 5,
          id: ERROR_ID,
        }),
      );

      dispatch(goToCreate());
    } else {
      const code = isApplicationError(error) ? error.code : null;

      dispatch(showError({ message: error.message, id: ERROR_ID, code }));
    }
  },

  onCancel: (nav: Navigator) => {
    dispatch(onCancelWizard('videoClip', MIXPANEL_WIZARD_STEP_MAP[nav.stepId]));
    dispatch(goToCreate());
  },

  onSubmit: (aspectRatioName: AspectRatioName) => {
    dispatch(
      onWizardNext({
        step: MIXPANEL_WIZARD_STEP_MAP.aspectRatio,
        type: 'videoClipWizard',
      }),
    );

    dispatch(
      onCompleteWizard({
        type: 'videoClipWizard',
      }),
    );

    dispatch(createVideoClipProject(aspectRatioName)).catch(() => {
      dispatch(
        showError({
          message: 'There was an error creating your project.',
          code: 'ER009',
          id: ERROR_ID,
        }),
      );

      dispatch(goToCreate());
    });
  },

  onComplete: () => dispatch(onComplete()),
});

export default function(component: React.ComponentType<VideoClipWizardProps>) {
  return connect(undefined, mapDispatchToProps)(component);
}
