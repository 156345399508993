import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { has } from 'underscore';
import { ArrowRight } from 'components/icons';
import ExportSuccessStep from 'containers/ProjectWizards/ExportSuccessStep';
import usePodcastCover from 'hooks/usePodcastCover';
import { userNameSelector } from 'redux/modules/auth';
import { checkIsAutoPostPlatform } from 'redux/modules/automation-cms/utils';
import { getAutomationPath } from 'utils/routes';
import {
  SUCCESS_STEP_ICON_BY_PLATFORM,
  SUCCESS_STEP_SUBTITLE_BY_PLATFORM,
} from './constants';
import { AutoPostingPlatform } from './types';
import { autogramSuccessBlock as block } from './utils';

export interface AutomationSuccessStepProps {
  autopost?: boolean;
  autopostPlatform?: AutoPostingPlatform;
  podcastId?: string;
}

function getTitle(autopost: boolean) {
  return autopost ? 'Your automation is live!' : undefined;
}

function getSubtitle(
  autopostPlatform: AutoPostingPlatform,
  emailAddress: string,
) {
  if (has(SUCCESS_STEP_SUBTITLE_BY_PLATFORM, autopostPlatform)) {
    return SUCCESS_STEP_SUBTITLE_BY_PLATFORM[autopostPlatform];
  }

  return `We'll email your first video to ${emailAddress}.`;
}

const AutomationSuccessStep: React.FC<AutomationSuccessStepProps> = ({
  autopost,
  autopostPlatform,
  podcastId,
}) => {
  const emailAddress = useSelector(userNameSelector);
  const { artworkUrl } = usePodcastCover({ podcastId });

  const icon = useMemo(() => {
    if (autopost && checkIsAutoPostPlatform(autopostPlatform)) {
      return (
        <>
          <div className={block('icon-fg')}>
            <div className={block('artwork-container')}>
              <div className={block('artwork-inner')}>
                <img
                  className={block('artwork')}
                  src={artworkUrl}
                  alt="podcast-artwork"
                />
              </div>
            </div>
            <ArrowRight
              className={block('arrow')}
              style={{ height: 'auto', width: 40 }}
            />
            <div className={block('platform-container')}>
              {SUCCESS_STEP_ICON_BY_PLATFORM?.[autopostPlatform]}
            </div>
          </div>
        </>
      );
    }

    // undefined will cause the default icon to be used
    return undefined;
  }, [artworkUrl, autopost, autopostPlatform]);

  return (
    <div className={block('container')}>
      <ExportSuccessStep
        icon={icon}
        iconClassName={block('icon-bg')}
        subtitle={getSubtitle(autopostPlatform, emailAddress)}
        primaryCta={{
          label: 'return home',
          to: getAutomationPath(),
        }}
        title={getTitle(autopost)}
      />
    </div>
  );
};

export default AutomationSuccessStep;
