import * as Immutable from 'immutable';

import {
  isArray,
  isFunction,
  isNull,
  isObject,
  isUndefined,
  negate,
  chunk as underscoreChunk,
} from 'underscore';

const isDefined = negate(isUndefined);
const isNotNull = negate(isNull);

export function isImmutable(object) {
  return Immutable.Iterable.isIterable(object);
}

/**
 * because it's super annoying to have to do:
 *  const sub1 = obj && obj.key1
 *  const sub2 = sub1 && sub1.key2
 *  const val = sub2 && sub2.key3
 */
export function getValue(coll, path, defaultValue = undefined) {
  if (!coll) return defaultValue;

  const keys = Array.isArray(path) ? path : [path];
  const val = keys.reduce(
    (res, key) => res && (isImmutable(res) ? res.get(key) : res[key]),
    coll,
  );
  return typeof val === 'undefined' ? defaultValue : val;
}

export function setValue(coll, key, value) {
  if (!coll) return undefined;

  return isImmutable(coll)
    ? coll.set(key, value)
    : {
        ...coll,
        key: value,
      };
}

export function keyIn(...keys) {
  const keySet = new Set(keys);
  return (v, k) => keySet.has(k);
}

export function pick(obj, ...keys) {
  const predicate = keyIn(...keys);
  if (isImmutable(obj)) {
    return obj.filter(predicate);
  }

  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (!predicate(value, key)) return acc;
    acc[key] = value;
    return acc;
  }, {});
}

export function omit(obj, ...keys) {
  const predicate = keyIn(...keys);
  if (isImmutable(obj)) {
    return obj.filterNot(predicate);
  }

  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (predicate(value, key)) return acc;
    acc[key] = value;
    return acc;
  }, {});
}

export function asArray(obj) {
  return Array.isArray(obj) ? obj : [obj];
}

export function omitUndefined(obj, deep = false) {
  const processValue = val => {
    if (isDefined(val) && isNotNull(val)) {
      if (deep) {
        return omitUndefined(val, true);
      }
      return val;
    }
    return undefined;
  };

  if (isArray(obj)) {
    return obj.reduce((acc, val) => {
      const newValue = processValue(val);
      if (newValue !== undefined) {
        acc.push(newValue);
      }
      return acc;
    }, []);
  }

  if (isObject(obj)) {
    return Object.keys(obj).reduce((acc, key) => {
      const val = obj[key];
      const newValue = processValue(val);
      if (newValue !== undefined) {
        acc[key] = newValue;
      }
      return acc;
    }, {});
  }

  return obj;
}

export function lastIndexOf(ary, data) {
  const dataAry = isImmutable(data) ? data.toJS() : data;

  const dataIndexes = dataAry.map(d => ary.lastIndexOf(d));

  if (dataIndexes.length === 0) {
    return -1;
  }

  return Math.max(...dataIndexes);
}

export function intersperse(ary, val) {
  return ary.reduce((res, current, index) => {
    res.push(current);

    if (index < ary.length - 1) {
      const value = isFunction(val) ? val(res.length, index) : val;
      res.push(value);
    }
    return res;
  }, []);
}

export function chunk(ary, size) {
  if (!isImmutable(ary)) {
    return underscoreChunk(ary, size);
  }

  return Immutable.Range(0, ary.count(), size).map(chunkStart =>
    ary.slice(chunkStart, chunkStart + size),
  );
}

export function zip(ary1, ary2) {
  const getAry2Value = isImmutable(ary2) ? i => ary2.get(i) : i => ary2[i];
  // return ary1.map((v1, index) => [v1, getAry2Value(index)]);
  const res = ary1.map((v1, index) => [v1, getAry2Value(index)]);

  if (res.length < ary2.length) {
    ary2.slice(res.length).forEach(v2 => {
      res.push([undefined, v2]);
    });
  }
  return res;
}

/**
 *
 * @param {Array} arrA
 * @param {Array} arrB
 */
export function shallowEquals(arrA, arrB) {
  if (arrA === arrB) {
    return true;
  }

  if (!arrA || !arrB) {
    return false;
  }

  const len = arrA.length;

  if (arrB.length !== len) {
    return false;
  }

  for (let i = 0; i < len; i += 1) {
    if (arrA[i] !== arrB[i]) {
      return false;
    }
  }

  return true;
}

export default { getValue, keyIn };
