import cn from 'classnames';
import * as React from 'react';

import FadingScrollBars from 'components/FadingScrollBars';
import LoadingOverlay from 'components/LoadingOverlay';
import AudioTile from './AudioTile';
import NoAudioTile from './NoAudioTile';
import { IAudioLibraryProps, IAudioLibraryState } from './types';

const renderView = props => (
  <div {...props} className="audio-library__container" />
);

export default class AudioLibrary extends React.Component<
  IAudioLibraryProps,
  IAudioLibraryState
> {
  public state: IAudioLibraryState = {
    isScrolling: false,
  };

  private handleOnScrollStart = () => {
    this.setState({
      isScrolling: true,
    });
  };

  private handleOnScrollStop = () => {
    this.setState({
      isScrolling: false,
    });
  };

  public render() {
    const {
      audios,
      className,
      disclaimerClassName,
      hideAudioTileDuration,
      noAudioOption,
      onPreview,
      onSelect,
      selectedId,
      selectedIsFetching,
      libraryIsFetching,
    } = this.props;
    const { isScrolling } = this.state;

    return (
      <div className={cn('audio-library', className)}>
        {selectedIsFetching && (
          <LoadingOverlay
            title="Downloading selected audio"
            description="This may take a while, so please keep your browser open and check back later"
          />
        )}
        {libraryIsFetching ? (
          <LoadingOverlay
            title="Downloading audio library"
            description="This may take a while, so please keep your browser open and check back later"
          />
        ) : (
          audios &&
          audios.length && (
            <FadingScrollBars
              className="audio-library__scroll"
              hideTracksWhenNotNeeded
              renderView={renderView}
              onScrollStart={this.handleOnScrollStart}
              onScrollStop={this.handleOnScrollStop}
            >
              {noAudioOption && (
                <NoAudioTile
                  onSelect={onSelect}
                  selected={selectedId === 'none'}
                />
              )}
              {audios.map(a => (
                <AudioTile
                  key={a.get('id')}
                  audio={a}
                  hideDuration={hideAudioTileDuration}
                  onSelect={onSelect}
                  onPreview={onPreview}
                  disabled={selectedIsFetching}
                  selected={a.get('id') === selectedId}
                  isParentScrolling={isScrolling}
                />
              ))}
            </FadingScrollBars>
          )
        )}
        <p className={cn('audio-library__disclaimer', disclaimerClassName)}>
          The audio library is completely free to use for any purpose without
          any attribution. Enjoy!
        </p>
      </div>
    );
  }
}
