import { isBoolean, isFunction, mapObject, max, noop } from 'underscore';
import { MODIFIERS } from './constants';
import { KeyBinding, KeyBindingMap, UseKeyBindOptions } from './types';

const DEFAULT_HANDLERS = {
  onKeyDown: noop,
  onKeyUp: noop,
};

export function parseKeyPressConfig(opts: UseKeyBindOptions): KeyBindingMap {
  return mapObject(opts.bindings, (handlers, str) => {
    const tokens = str.split('+');

    const modifiers = tokens.filter(token => MODIFIERS.includes(token));
    const keys = tokens.filter(token => !MODIFIERS.includes(token));

    // when the keybind is only a modifier (e.g. shift, control, etc.), then
    // the key is set to the modifier and modifiers are left empty.  this is done
    // because if the keybind is only a modifier like Control, then on key down
    // e.key === "Control" and e.getModifierState('Control') === true, but on
    // key up e.key === 'Control' and e.getModifierState('Control') === false
    const isSingleModifierKeybind = keys.length === 0 && modifiers.length === 1;

    if (keys.length > 1) {
      throw new Error(
        `error parsing keybind "${str}" - useKeyPress only supports a single non-modifier key`,
      );
    }

    const key = keys[0];

    const { onKeyDown, onKeyUp } = isBoolean(handlers)
      ? DEFAULT_HANDLERS
      : {
          ...DEFAULT_HANDLERS,
          ...handlers,
        };

    return {
      onKeyDown,
      onKeyUp,
      description: str,
      key: isSingleModifierKeybind ? modifiers[0] : key,
      length: modifiers.length + keys.length,
      modifiers: isSingleModifierKeybind ? [] : modifiers,
    };
  });
}

export function getActiveKeybind(
  e: KeyboardEvent,
  bindings: KeyBindingMap,
): KeyBinding {
  if (!isFunction(e.getModifierState)) {
    return undefined;
  }

  const activeBindingStrings = Object.keys(bindings).filter(bindingString => {
    const binding = bindings[bindingString];
    const keyPressed = binding.key === e.key;
    const modifiersPressed = binding.modifiers.every(mod =>
      e.getModifierState(mod),
    );

    return keyPressed && modifiersPressed;
  });

  const activeBindingString = max(
    activeBindingStrings,
    bindingString => bindings[bindingString].length,
  );

  return bindings[activeBindingString];
}
