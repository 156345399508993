import * as React from 'react';

import { SelectField, SelectFieldProps } from 'components/Form';
import TextBoxField from './TextBoxField';

const TextBoxSelectField: React.SFC<SelectFieldProps> = props => (
  <TextBoxField>
    <SelectField
      horizontal={{
        control: { xs: 6 },
        label: { xs: 6 },
      }}
      {...props}
    />
  </TextBoxField>
);

export default TextBoxSelectField;
export { SelectFieldProps as TextBoxSelectFieldProps };
