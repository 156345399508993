import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { clamp, roundToStep } from 'utils/numbers';
import FormField, { FormFieldProps } from './FormField';
import { block } from './utils';

type Value = number | '';

type InputProps = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  'max' | 'maxLength' | 'min' | 'size' | 'step' | 'placeholder'
>;

interface IProps extends InputProps {
  onBlur?: (value: Value, e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: Value, e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: Value;
}

type Props = FormFieldProps<IProps>;

export default class NumericField extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    max: Infinity,
    min: -Infinity,
    onBlur: noop,
    onChange: noop,
  };

  private handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur, value } = this.props;
    onBlur(this.formatValue(value), e);
  };

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    onChange(this.formatValue(e.target.value), e);
  };

  private formatValue(val: number | string) {
    const { max, min, step } = this.props;

    if (val === '') return '';

    const parsed = Number(val);
    const rounded = roundToStep(parsed, step);
    return clamp(rounded, Number(min), Number(max));
  }

  public render() {
    const {
      className,
      onBlur,
      onChange,
      step,
      value,
      min,
      max,
      ...props
    } = this.props;

    return (
      <FormField<IProps>
        {...props}
        className={cn(block('numeric-field'), className)}
        value={this.formatValue(value)}
      >
        <input
          className={cn(block('numeric-input'))}
          max={max}
          min={min}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          step={step}
          type="number"
        />
      </FormField>
    );
  }
}

export { Props as NumericFieldProps, Value as NumericValue };
