import classNames from 'classnames';
import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import _ from 'underscore';
import FontAwesome from 'components/FontAwesome';

import bem from 'utils/bem';
import { KEYCODE } from 'utils/keycodes';
import { clamp } from 'utils/numbers';
import { replaceCharAt } from 'utils/string';
import { Omit } from '../../types';
import Caret from './Caret';
import { DurationMask } from './types';
import {
  durationToValue,
  getPositionMillisMultiplier,
  valueToDuration,
} from './utils';

type EventHandler = (millis: number, el: HTMLInputElement) => void;

type InputProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  'onChange' | 'onFocus' | 'onBlur'
>;

const block = bem('masked-duration-input');

export interface IProps extends InputProps {
  className?: string;
  inputRef?: (el: HTMLInputElement) => void;
  mask?: DurationMask;
  maxMillis?: number;
  millis?: number;
  minMillis?: number;
  onBlur?: EventHandler;
  onChange?: EventHandler;
  onFocus?: EventHandler;
  allowArrows?: boolean;
}

interface IChangeData {
  /**
   * the position that the caret should move to after modification is made
   */
  caretEnd: number;
  /**
   * the index of the caret before any modifications were made
   */
  caretStart: number;
  /**
   * the character to either be added or deleted
   */
  char: string;
  /**
   * index of the character in the value string that is to be replaced
   */
  charIndex: number;
  /**
   * whether a character is being added or deleted
   */
  charMod: 'add' | 'delete';
}

interface IDataState {
  caretIndex: number;
  focused: boolean;
  value: string;
}

const dataFactory = Record<IDataState>({
  caretIndex: undefined,
  focused: undefined,
  value: undefined,
});

interface IState {
  data: RecordOf<IDataState>;
}

export default class MaskedDurationInput extends React.Component<
  IProps,
  IState
> {
  public static defaultProps: Partial<IProps> = {
    inputRef: _.noop,
    mask: '00:00.000',
    maxMillis: Infinity,
    minMillis: 0,
    onBlur: _.noop,
    onChange: _.noop,
    onFocus: _.noop,
    onKeyDown: _.noop,
    onSelect: _.noop,
  };

  private caret: Caret;
  private input: HTMLInputElement;
  private prevValue: string;

  constructor(props: IProps) {
    super(props);

    const { millis } = props;

    this.state = {
      data: dataFactory({
        caretIndex: undefined,
        focused: false,
        value: this.boundedDurationToValue(millis || 0),
      }),
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const { mask: nextMask, millis: nextMillis } = nextProps;
    const { mask, millis } = this.props;

    if (_.isFinite(nextMillis) && nextMillis !== millis) {
      this.updateValue(this.restrictToBounds(nextMillis, nextProps));
    }

    if (nextMask !== mask) {
      this.setState(({ data: dataState }) => ({
        data: dataState.update('value', v =>
          this.durationToValue(valueToDuration(v), nextMask),
        ),
      }));
    }
  }

  public componentDidUpdate() {
    const { data } = this.state;

    if (
      _.isFinite(data.caretIndex) &&
      (data.caretIndex !== this.caret.start ||
        data.caretIndex + 1 !== this.caret.end)
    ) {
      this.caret.set(data.caretIndex, data.caretIndex + 1);
    }
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { mask } = this.props;

    e.preventDefault();

    const {
      target: { value },
    } = e;
    const { caretEnd, charIndex, char, charMod } = this.getChangeData(value);
    const replacementChar =
      charMod === 'delete' ? mask.charAt(charIndex) : char;
    const isValidOp =
      charMod === 'delete' ||
      (charMod === 'add' && MaskedDurationInput.isNumber(char));

    /*
     * NOTE: it's tempting to move the `isValidOp` check "outside" of updateValue and only call
     * this.updateValue if it's true.  This will work but will lead to issues with the caret
     * if an invalid character is entred.  `updateValue` still updates state and triggers a render
     * even when both arguments are undefined (or in the case of the first arg, returns undefined).
     * this will just replace the current state with its same value, which will trigger the caret to
     * move to the correct position after update.
     */
    this.updateValue(
      val =>
        !isValidOp
          ? undefined
          : this.boundedReplace(val, charIndex, replacementChar),
      isValidOp ? caretEnd : undefined,
    );
  };

  private handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { onKeyDown } = this.props;

    const code = e.keyCode;

    if (code === KEYCODE.UP || code === KEYCODE.DOWN) {
      e.preventDefault();
      this.adjustValueAtPosition(
        this.caret.start,
        code === KEYCODE.UP ? 1 : -1,
      );
    }

    if (code === KEYCODE.LEFT || code === KEYCODE.RIGHT) {
      e.preventDefault();
      this.adjustCaretPosition(code === KEYCODE.RIGHT ? 1 : -1);
    }

    if (code === KEYCODE.ESCAPE) {
      e.preventDefault();
      this.updateValue(this.prevValue, undefined, () => this.input.blur());
    }

    if (code === KEYCODE.ENTER) {
      e.preventDefault();
      this.input.blur();
    }

    onKeyDown(e);
  };

  private handleCaretUpClick = (e: React.MouseEvent) => {
    e.preventDefault();
    this.adjustValueAtPosition(this.caret.start, 1);
    this.input.focus();
  };

  private handleCaretDownClick = (e: React.MouseEvent) => {
    e.preventDefault();
    this.adjustValueAtPosition(this.caret.start, -1);
    this.input.focus();
  };

  private handleFocus = () => {
    const { onFocus } = this.props;
    const { data } = this.state;

    this.prevValue = data.value;
    onFocus(valueToDuration(data.value), this.input);

    // https://stackoverflow.com/a/8189408/5148535
    _.defer(() => {
      this.updateCaret(
        this.caretStartSeparatorGuard(this.caret.start, pos => pos - 1),
      );
      this.setState(({ data: dataState }) => ({
        data: dataState.set('focused', true),
      }));
    });
  };

  private handleBlur = () => {
    const { onBlur } = this.props;
    const { data } = this.state;

    onBlur(valueToDuration(data.value), this.input);

    this.setState(({ data: dataState }) => ({
      data: dataState.set('focused', false),
    }));
  };

  private handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    const { data } = this.state;

    /*
     * on some OS's (e.g. mac) you can scroll just by having the mouse wheel over an element, even
     * if it's not "focused".  the "focused" par to fthis check prevents the input from reacting to
     * the mouse wheel when it doesn't have focus
     */
    if (e.deltaY === 0 || !data.focused) return;

    const adjustment = e.deltaY < 0 ? -1 : 1;
    this.adjustValueAtPosition(this.caret.start, adjustment);
  };

  private handleSelect = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { onSelect } = this.props;

    this.updateCaret(
      this.caretStartSeparatorGuard(this.caret.start, pos => pos - 1),
    );
    onSelect(e);
  };

  private getChangeData(value: string): IChangeData {
    const { data } = this.state;

    /*
     * every non-placeholder character in the input is highlighted, meaning it has a selection start
     * and selection end. when the user enters or delets a character, the selection will collapse
     * to one side of the character.  when deleting a character, selectionEnd snaps to
     * selection start and selection start doesn't move.  when adding a character, selectionStart
     * snaps to selectionEnd, thereby moving one spot to the right of the previons selectionStart.
     */
    const caretDiff = this.caret.start - data.caretIndex;

    // caret starts at the last position we recorded for it
    const caretStart = data.caretIndex;

    // see comment above.  if caret index didn't move, it's a delete otherwise an add
    const mod = caretDiff === 0 ? 'delete' : 'add';

    // next position of the caret, ignoring any invalid indices or mask tokens
    const caretEnd = mod === 'delete' ? caretStart - 1 : caretStart + 1;

    return {
      caretStart,
      caretEnd: this.caretStartSeparatorGuard(
        caretEnd,
        pos => pos + (mod === 'delete' ? -1 : 1),
      ),
      char:
        mod === 'add'
          ? value.charAt(caretStart)
          : data.value.charAt(caretStart),
      charIndex: caretStart,
      charMod: mod,
    };
  }

  /**
   * for a given index in the value string, get the multiplier necessary to change that value
   */
  private getPositionMillisMultiplier(index: number) {
    const { mask } = this.props;
    return getPositionMillisMultiplier(mask, index);
  }

  private adjustValueAtPosition(caretIndex: number, by: number) {
    const { data } = this.state;

    const index = clamp(
      this.caretStartSeparatorGuard(caretIndex, p => p + 1),
      0,
      data.value.length - 1,
    );

    this.updateValue(current => {
      const millis = valueToDuration(current);
      return this.restrictToBounds(
        millis + by * this.getPositionMillisMultiplier(index),
      );
    }, index);
  }

  private adjustCaretPosition(by: number) {
    const currentPos = this.caret.start;
    const newPos = currentPos + by;
    const adjPos = this.caretStartSeparatorGuard(
      newPos,
      pos => pos + (by < 0 ? -1 : 1),
    );
    this.updateCaret(adjPos);
  }

  /**
   * checks if the caret position corresponds to a separator
   *
   * if character at `startIndex` is not a separater, returns `startIndex` otherwise calls
   * `ifSeparator` on `startIndex` and returns the value returned by `ifSeparator`.
   *
   * also ensures that any value returned is within the min/max bounds
   */
  private caretStartSeparatorGuard(
    startIndex?: number,
    ifSeparator?: (index: number) => number,
  ) {
    const { data } = this.state;
    const start = !_.isUndefined(startIndex) ? startIndex : this.caret.start;
    const char = data.value.charAt(start);
    const adjStart = !MaskedDurationInput.isSeparator(char)
      ? start
      : ifSeparator(start);

    return clamp(adjStart, 0, data.value.length - 1);
  }

  private durationToValue = (
    durationMillis: number,
    mask: DurationMask = this.props.mask,
  ) => {
    return durationToValue(durationMillis, mask);
  };

  private boundedDurationToValue = _.compose(
    this.durationToValue,
    this.restrictToBounds,
  ) as (millis: number) => string;

  private boundedValueToDuration = _.compose(
    this.restrictToBounds,
    valueToDuration,
  ) as (val: string) => number;

  private boundedReplace = _.compose(
    this.boundedValueToDuration,
    replaceCharAt,
  ) as (string: string, index: number, char: string) => number;

  private static isSeparator(char: string) {
    return /[:.]/.test(char);
  }

  private static isNumber(value: string) {
    return /\d/.test(value);
  }

  private restrictToBounds(val: number, props: Readonly<IProps> = this.props) {
    const { minMillis, maxMillis } = props;
    return clamp(val, minMillis, maxMillis);
  }

  /**
   * updates the input value in state
   * if update is:
   *  - a number, convert it to a string
   *  - a string, use it
   *  - a function, give it the current input value and handle the string/number response as above
   *
   * NOTE: does not restrict value to bounds
   */
  private updateValue(
    update: string | number | ((current: string) => string | number),
    caretIndex?: number,
    cb: () => void = _.noop,
  ) {
    const { onChange } = this.props;

    let prevValue: string;

    this.setState(
      ({ data }) => ({
        data: data.withMutations(d => {
          prevValue = d.value;
          const res = _.isFunction(update) ? update(d.value) : update;
          const newValue = _.isString(res) ? res : this.durationToValue(res);
          if (!_.isUndefined(newValue)) {
            d.set('value', newValue);
          }
          if (_.isFinite(caretIndex)) {
            d.set('caretIndex', caretIndex);
          }
          return d;
        }),
      }),
      () => {
        const { data } = this.state;
        if (data.value !== prevValue) {
          const millis = valueToDuration(data.value);
          onChange(millis, this.input);
        }
        cb();
      },
    );
  }

  private updateCaret(startIndex: number, cb: () => void = _.noop) {
    this.setState(
      ({ data }) => ({
        data: data.set('caretIndex', startIndex),
      }),
      cb,
    );
  }

  public render() {
    const {
      className,
      inputRef,
      onSubmit,
      mask,
      millis,
      maxMillis,
      minMillis,
      allowArrows,
      ...inputProps
    } = this.props;

    const { data } = this.state;

    return (
      <>
        <input
          {...inputProps}
          className={classNames(
            'masked-duration-input',
            'masked-duration-input--default',
            {
              'masked-duration-input--focused': data.focused,
              'masked-duration-input--unfocused': !data.focused,
              [className]: !!className,
            },
          )}
          type="text"
          value={data.value}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          onSelect={this.handleSelect}
          onWheel={this.handleWheel}
          ref={el => {
            this.input = el;
            this.caret = new Caret(el);
            inputRef(el);
          }}
          size={mask.length}
        />
        {allowArrows && (
          <>
            <button
              type="button"
              onClick={this.handleCaretUpClick}
              className={block('caret', { up: true })}
            >
              <FontAwesome icon="caret-up" fixedWidth />
            </button>
            <button
              type="button"
              onClick={this.handleCaretDownClick}
              className={block('caret', { down: true })}
            >
              <FontAwesome icon="caret-up" fixedWidth rotation={180} />
            </button>
          </>
        )}
      </>
    );
  }
}
