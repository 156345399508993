import cn from 'classnames';
import * as React from 'react';
import bem from 'utils/bem';

const block = bem('callout');

export interface Props {
  icon: React.ReactNode;
  className?: string;
}

const Callout: React.FC<Props> = props => {
  const { children, icon, className } = props;
  return (
    <div className={cn(block(), className)}>
      <div className={block('icon')}>{icon}</div>
      <div className={block('text')}>{children}</div>
    </div>
  );
};

export default Callout;
