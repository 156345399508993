import classNames from 'classnames';
import * as React from 'react';
import _ from 'underscore';

import FontAwesome from 'components/FontAwesome';
import IconButton from 'components/IconButton/IconButton';
import Tooltip from 'components/Tooltip';
import VolumeControl from '../VolumeControl';
import { PLAYER_STEP_IN_SEC } from './utils';

interface IProps {
  buttonClassName?: string;
  className?: string;
  onBackClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onRewindClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onForwardClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onEndClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onPauseClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onPlayClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onVolumeChange?: (volume: number) => void;
  playbackGroupClassName?: string;
  playing?: boolean;
  volumeGroupClassName?: string;
}

const MediaControls: React.SFC<IProps> = ({
  buttonClassName,
  className,
  onBackClick,
  onRewindClick,
  onForwardClick,
  onEndClick,
  onPauseClick,
  onPlayClick,
  onVolumeChange,
  playbackGroupClassName,
  playing,
  volumeGroupClassName,
}) => {
  const createButtonClassName = (buttonName: string) =>
    classNames(
      'media-controls__button',
      `media-controls__${buttonName}`,
      buttonClassName,
    );

  return (
    <div
      className={classNames('media-controls', 'media-controls--std', className)}
    >
      <div
        className={classNames(
          'media-controls__playback-group',
          playbackGroupClassName,
        )}
      >
        <Tooltip
          animation={false}
          content="Move playhead to start"
          id="back-tooltip"
          placement="top"
          preventHideOnHover={false}
        >
          <IconButton
            className={createButtonClassName('back')}
            onButtonClick={onBackClick}
          >
            <FontAwesome
              className="media-controls__back-icon"
              icon="step-backward"
            />
          </IconButton>
        </Tooltip>

        <Tooltip
          animation={false}
          content={`Rewind ${PLAYER_STEP_IN_SEC}s`}
          id="rewind-tooltip"
          placement="top"
          preventHideOnHover={false}
        >
          <IconButton
            className={createButtonClassName('rewind')}
            onButtonClick={onRewindClick}
          >
            <FontAwesome
              className="media-controls__rewind-icon"
              icon="backward"
            />
          </IconButton>
        </Tooltip>

        {playing ? (
          <Tooltip
            animation={false}
            content="Pause"
            id="pause-tooltip"
            placement="top"
            preventHideOnHover={false}
            key="pause"
          >
            <IconButton
              className={createButtonClassName('pause')}
              onButtonClick={onPauseClick}
            >
              <FontAwesome
                className="media-controls__pause-icon"
                icon="pause"
              />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip
            animation={false}
            content="Play"
            id="play-tooltip"
            placement="top"
            preventHideOnHover={false}
            key="play"
          >
            <IconButton
              className={createButtonClassName('play')}
              onButtonClick={onPlayClick}
            >
              <FontAwesome className="media-controls__play-icon" icon="play" />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip
          animation={false}
          content={`Fast forward ${PLAYER_STEP_IN_SEC}s`}
          id="forward-tooltip"
          placement="top"
          preventHideOnHover={false}
        >
          <IconButton
            className={createButtonClassName('forward')}
            onButtonClick={onForwardClick}
          >
            <FontAwesome
              className="media-controls__forward-icon"
              icon="forward"
            />
          </IconButton>
        </Tooltip>

        <Tooltip
          animation={false}
          content="Move playhead to end"
          id="end-tooltip"
          placement="top"
          preventHideOnHover={false}
        >
          <IconButton
            className={createButtonClassName('end')}
            onButtonClick={onEndClick}
          >
            <FontAwesome
              className="media-controls__end-icon"
              icon="step-forward"
            />
          </IconButton>
        </Tooltip>
      </div>
      <div
        className={classNames(
          'media-controls__volume-group',
          volumeGroupClassName,
        )}
      >
        <VolumeControl
          className="media-controls__volume-container"
          buttonClassName={createButtonClassName('volume')}
          onChange={onVolumeChange}
          sliderClassName="media-controls__volume-slider"
        />
      </div>
    </div>
  );
};

MediaControls.defaultProps = {
  onBackClick: _.noop,
  onEndClick: _.noop,
  onForwardClick: _.noop,
  onPauseClick: _.noop,
  onPlayClick: _.noop,
  onRewindClick: _.noop,
  onVolumeChange: _.noop,
  playing: false,
};

export default MediaControls;
