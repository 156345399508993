import * as React from 'react';

import { blockItems } from '../utils';

interface TemplateTabsContentItemProps {
  children: React.ReactNode;
}

const TemplateTabsContentItem = React.forwardRef<
  HTMLDivElement,
  TemplateTabsContentItemProps
>(({ children }, ref) => (
  <div className={blockItems('item-container')} ref={ref}>
    {children}
  </div>
));

export default TemplateTabsContentItem;
