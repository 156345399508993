import cn from 'classnames';
import * as React from 'react';
import { blockNavigation } from './utils';

export interface DropdownSectionProps {
  children?: React.ReactNode;
  className?: string;
  hideOnMobile?: boolean;
  /*
   * onSelect is needed to make sure the DropdownMenu closes when an
   * option is clicked.  see DropdownMenu
   */
  onSelect?: () => void;
}

const DropdownSection: React.SFC<DropdownSectionProps> = ({
  children,
  className,
  hideOnMobile,
  onSelect,
}) => (
  <div
    className={cn(
      blockNavigation('dropdown-section', { 'no-mobile': hideOnMobile }),
      className,
    )}
    onClick={onSelect}
  >
    {children}
  </div>
);

export default DropdownSection;
