import { Heading, Spacer, Switch } from '@sparemin/blockhead';
import * as React from 'react';

import Button from 'components/Button';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { captionsViewBlock as block } from '../utils';

export interface CaptionsChildViewContentsProps {
  topSlot: React.ReactNode;
  bottomSlot: React.ReactNode;
  switchLabel: React.ReactNode;
  editButtonLabel: string;
  onTranscriptionChange: () => void;
  onEditCaptionsStyle: () => void;
}

const CaptionsChildViewContents: React.FC<CaptionsChildViewContentsProps> = ({
  topSlot,
  bottomSlot,
  switchLabel,
  editButtonLabel,
  onTranscriptionChange,
  onEditCaptionsStyle,
}) => {
  const { transcription } = useTemplateState();

  return (
    <Spacer orientation="vertical" space="24px" className={block('contents')}>
      {topSlot}

      <Spacer className={block('card')} orientation="vertical" space={3}>
        <Spacer
          className={block('toggle-row')}
          orientation="horizontal"
          justify="space-between"
        >
          <Heading level={2} className={block('text')}>
            {switchLabel}
          </Heading>

          <Switch
            isSelected={transcription?.transcribe}
            onChange={onTranscriptionChange}
          />
        </Spacer>
        <Button
          disabled={!transcription?.transcribe}
          fluid
          onClick={onEditCaptionsStyle}
          theme="submit"
        >
          {editButtonLabel}
        </Button>
      </Spacer>

      {bottomSlot}
    </Spacer>
  );
};

export default CaptionsChildViewContents;
