import React from 'react';

import { IconProps } from './types';

const Captions: React.FC<IconProps> = ({ ...svgProps }) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <defs>
      <filter
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        <feGaussianBlur in="SourceGraphic" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="#646E82" filter="url(#a)" cx="50" cy="50" r="50" />
      <path
        d="m30.97 41.564 18.198 8.256a2 2 0 0 0 1.663 0l18.199-8.256c1.292-.586 1.292-2.54 0-3.127l-18.198-8.256a2 2 0 0 0-1.663 0l-18.2 8.255c-1.292.587-1.292 2.542 0 3.128zm38.06 6.897-4.538-2.057-12.628 5.724a4.483 4.483 0 0 1-1.864.404 4.485 4.485 0 0 1-1.864-.404l-12.627-5.724-4.54 2.057c-1.292.586-1.292 2.54 0 3.125l18.199 8.25c.531.24 1.132.24 1.663 0l18.2-8.25c1.292-.586 1.292-2.539 0-3.125zm0 9.984-4.52-2.049-12.646 5.732a4.483 4.483 0 0 1-1.864.404 4.485 4.485 0 0 1-1.864-.404l-12.644-5.732-4.522 2.05c-1.293.585-1.293 2.539 0 3.124l18.198 8.25c.531.24 1.132.24 1.663 0l18.2-8.25c1.292-.585 1.292-2.539 0-3.125z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default Captions;
