export default {
  v: '5.5.10',
  fr: 29.9940032958984,
  ip: 0,
  op: 179.000019669461,
  w: 1800,
  h: 1800,
  nm: 'Spinner',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Line',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 60,
              s: [360],
            },
            { t: 110.000012087378, s: [660] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [900, 900, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [-0.513, 165.999],
                          [-303.625, 30.184],
                          [-44.57, 0.801],
                        ],
                        o: [
                          [1.557, -503.194],
                          [25.061, -2.491],
                          [0.5, -0.009],
                        ],
                        v: [
                          [-814, 0],
                          [-97.27, -812.554],
                          [0, -818],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 108,
                    s: [
                      {
                        i: [
                          [-0.513, 165.999],
                          [-303.625, 30.184],
                          [-44.57, 0.801],
                        ],
                        o: [
                          [1.557, -503.194],
                          [25.061, -2.491],
                          [0.5, -0.009],
                        ],
                        v: [
                          [-814, 0],
                          [-97.27, -812.554],
                          [0, -818],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 110,
                    s: [
                      {
                        i: [
                          [-0.513, 165.999],
                          [-360.23, 20.141],
                          [-39.874, -3.065],
                        ],
                        o: [
                          [1.557, -503.194],
                          [25.145, -1.406],
                          [0.499, 0.038],
                        ],
                        v: [
                          [-814, 0],
                          [-72.27, -769.253],
                          [28.5, -768.637],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 112,
                    s: [
                      {
                        i: [
                          [-0.583, 151.579],
                          [-311.273, -124.478],
                          [-33.78, -18.47],
                        ],
                        o: [
                          [60.893, -401.859],
                          [8.19, 2.849],
                          [0.619, 0.214],
                        ],
                        v: [
                          [-798.27, -179.315],
                          [27.024, -729.856],
                          [104.644, -696.788],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 114,
                    s: [
                      {
                        i: [
                          [-0.653, 137.158],
                          [-300.838, -298.818],
                          [-18.172, -28.399],
                        ],
                        o: [
                          [129.407, -356.63],
                          [1.657, 1.118],
                          [0.739, 0.437],
                        ],
                        v: [
                          [-757.54, -315.329],
                          [127.198, -645.935],
                          [170.589, -589.606],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 115,
                    s: [
                      {
                        i: [
                          [-0.688, 129.948],
                          [-211.743, -365.71],
                          [-9.216, -25.368],
                        ],
                        o: [
                          [141.217, -347.094],
                          [1.19, 2.101],
                          [0.798, 0.548],
                        ],
                        v: [
                          [-728.675, -368.614],
                          [114.274, -590.112],
                          [140.314, -533.563],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 116,
                    s: [
                      {
                        i: [
                          [-0.723, 122.737],
                          [-122.649, -432.601],
                          [-0.26, -22.337],
                        ],
                        o: [
                          [153.027, -337.558],
                          [0.722, 3.084],
                          [0.858, 0.66],
                        ],
                        v: [
                          [-699.81, -421.898],
                          [79.7, -521.788],
                          [88.388, -465.02],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 117,
                    s: [
                      {
                        i: [
                          [-0.779, 111.09],
                          [117.042, -416.793],
                          [1.146, -9.36],
                        ],
                        o: [
                          [309.355, -430.631],
                          [-0.341, 4.894],
                          [0.955, 0.84],
                        ],
                        v: [
                          [-642.8, -496.914],
                          [22.442, -431.614],
                          [9.97, -391.388],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 118,
                    s: [
                      {
                        i: [
                          [-0.835, 99.442],
                          [161.159, -449.728],
                          [2.551, 3.618],
                        ],
                        o: [
                          [367.787, -335.664],
                          [-1.405, 6.704],
                          [1.051, 1.02],
                        ],
                        v: [
                          [-585.79, -571.929],
                          [-107.758, -287.78],
                          [-130.051, -224.455],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 120,
                    s: [
                      {
                        i: [
                          [-0.964, 72.819],
                          [76.51, -327.52],
                          [-0.696, -17.841],
                        ],
                        o: [
                          [195.403, 3.439],
                          [0.883, 11.012],
                          [-3.514, 3.193],
                        ],
                        v: [
                          [-474.389, -650.657],
                          [-151.318, -160.452],
                          [-161.296, -112.653],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 123,
                    s: [
                      {
                        i: [
                          [-1.157, 32.885],
                          [97.691, -340.597],
                          [-9.249, 0.592],
                        ],
                        o: [
                          [145.637, 120.761],
                          [5.907, -0.767],
                          [-10.361, 6.454],
                        ],
                        v: [
                          [-189.608, -524.922],
                          [-184.739, 39.825],
                          [-188.246, 43.549],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 133.000014614739,
                    s: [
                      {
                        i: [
                          [-1.318, -0.394],
                          [-3.205, 0.962],
                          [-0.419, 0.54],
                        ],
                        o: [
                          [0.484, 0.145],
                          [1.118, -0.336],
                          [291.425, -375.962],
                        ],
                        v: [
                          [-105.864, -219.472],
                          [-184.637, 40.472],
                          [315.439, 160.356],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 150, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180.000019779347,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Filled Circle',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [900, 900, 0], ix: 2 },
        a: { a: 0, k: [13, 49, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 123,
              s: [0, 0, 100],
            },
            { t: 133.000014614739, s: [110, 110, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [1632.391, 1632.391], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.098039215686, 0.709803921569, 0.996078431373, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [12.195, 48.195], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180.000019779347,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Empty Circle',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [900, 900, 0], ix: 2 },
        a: { a: 0, k: [13, 49, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [1632.391, 1632.391], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.274509803922, 0.305882352941, 0.36862745098, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 150, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [12.195, 48.195], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180.000019779347,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
