import React from 'react';
import { useSelector } from 'react-redux';

import LoadingOverlay from 'components/LoadingOverlay';

import { isUpdatingAutomationSelector } from 'redux/modules/edit-automation';

const EditAutomationLoader: React.FunctionComponent = () => {
  const isUpdatingWorkflow = useSelector(isUpdatingAutomationSelector);

  return isUpdatingWorkflow ? <LoadingOverlay /> : null;
};

export default EditAutomationLoader;
