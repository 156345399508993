import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PodcastRemoteEpisode } from 'redux/middleware/api/podcast-service';
import {
  getPodcastEpisodes,
  getPodcastRemoteEpisode,
} from 'redux/middleware/api/podcast-service/actions';
import { showError } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';

/**
 * Retrieves the latest episode information for a given podcast.
 *
 * @returns `getLatestEpisodeInfo`, which is a function that takes a `podcastId` parameter and returns a
 * Promise that resolves to an `IApiResponse<GetPodcastRemoteEpisodeResult>` object containing information about
 * the latest episode of the podcast with the given `podcastId`. If there is an error, the function dispatches a
 * `showError` action with a message indicating that there and issue getting the latest episode.
 */
export default function useLatestEpisodeInfo() {
  const [latestEpisodeInfo, setLatestEpisodeInfo] = useState<
    PodcastRemoteEpisode
  >();

  const dispatch = useDispatch<Dispatch>();

  const getLatestEpisodeInfo = useCallback(
    async (podcastId: string): Promise<PodcastRemoteEpisode> => {
      if (!podcastId) {
        return undefined;
      }

      try {
        const {
          response: { entities },
        } = await dispatch(getPodcastEpisodes(podcastId));

        const episodes = entities.podcasts?.[podcastId]?.episodes || [];

        if (!episodes.length) {
          return undefined;
        }

        const episodeId = episodes[0];

        const latesEpisodeInfo = await dispatch(
          getPodcastRemoteEpisode(podcastId, episodeId),
        );

        setLatestEpisodeInfo(
          latesEpisodeInfo.response.entities.podcastRemoteEpisodes[episodeId],
        );
      } catch {
        dispatch(
          showError({
            message: 'Error getting latest episode',
          }),
        );
      }

      return undefined;
    },
    [dispatch],
  );

  return {
    latestEpisodeInfo,
    getLatestEpisodeInfo,
  };
}
