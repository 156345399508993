import * as React from 'react';

import { ImageBackgroundType } from './types';
import { block } from './utils';

export interface ImageBackgroundProps {
  aspectRatio?: number;
  background: ImageBackgroundType;
}

const ImageBackground: React.SFC<ImageBackgroundProps> = ({
  aspectRatio,
  background,
}) => (
  <div
    className={block('image-container')}
    style={
      aspectRatio === undefined
        ? undefined
        : {
            paddingTop: `${(1 / aspectRatio) * 100}%`,
          }
    }
  >
    <img className={block('image')} src={background.src} />
  </div>
);

export default ImageBackground;
