import * as React from 'react';
import { isFunction } from 'underscore';

import { MediaType } from 'containers/AddMediaModal';
import { CropMetadata } from 'types';
import { useModalManager } from './ModalManager';
import { VideoTemplateAddMediaModalProps } from './ModalManager/VideoTemplateAddMediaModal';

const { useCallback, useImperativeHandle } = React;

export interface MediaFileSelectorRenderProps {
  onClick?: () => void;
}

export interface MediaModalSelectorInstance {
  open: () => void;
}
export interface BackgroundImagePayload {
  src: Blob;
  original: Blob;
  metadata: CropMetadata;
  fileName: string;
}

export interface MediaModalSelectorProps {
  children?: React.FC<MediaFileSelectorRenderProps>;
  onFileAccepted?: (file: File) => void;
  onUrlAccepted: (source: string, mediaType: MediaType) => void;
  testId?: string;
}

const MediaModalSelector = React.forwardRef<
  MediaModalSelectorInstance,
  MediaModalSelectorProps
>(({ children, onFileAccepted, onUrlAccepted }, ref) => {
  const onFileAcceptedRef = React.useRef(onFileAccepted);
  const onUrlAcceptedRef = React.useRef(onUrlAccepted);

  const modalManager = useModalManager();

  const handleSubmit: VideoTemplateAddMediaModalProps['onSubmit'] = useCallback(
    result => {
      if (result.isUrl === true) {
        onUrlAcceptedRef.current(result.asset, result.type);
      } else {
        onFileAcceptedRef.current(result.asset);
      }
    },
    [],
  );

  const handleClick = useCallback(() => {
    modalManager.showModal('add-media-modal', {
      onSubmit: handleSubmit,
    });
  }, [handleSubmit, modalManager]);

  useImperativeHandle(
    ref,
    () => ({
      open: handleClick,
    }),
    [handleClick],
  );

  return <>{isFunction(children) && children({ onClick: handleClick })}</>;
});

export default MediaModalSelector;
