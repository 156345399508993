import { Record, RecordOf } from 'immutable';
import { reviveRecord } from 'utils/immutable';

import { State } from './types';

export const stateFactory = Record<State>({
  entireAudioInstanceId: undefined,
  waveform: undefined,
  standardizationCancelled: false,
  error: undefined,
});

export function stateFromJs(obj: Object): RecordOf<State> {
  return reviveRecord(stateFactory, obj);
}
