import * as React from 'react';
import { noop } from 'underscore';

import AlignmentGuidelines from 'components/AlignmentGuidelines/AlignmentGuidelines';
import { CENTERING_LINES } from 'components/AlignmentGuidelines/constants';
import { Line, UseSnapLinesConfig } from 'components/AlignmentGuidelines/types';
import useSnapLines from 'components/AlignmentGuidelines/useSnapLines';
import { Omit } from 'types';
import { useCanvasSize } from './context/CanvasSizeContext';
import { DragAlignmentContextType } from './types';

const { useContext, useState } = React;

const DragAlignmentContext = React.createContext<DragAlignmentContextType>({
  availableLines: [],
  setActiveLines: noop,
  setDragging: noop,
});

export function useDragAlignment(
  config: Omit<UseSnapLinesConfig, 'containerSize' | 'lines'>,
) {
  const { availableLines, setActiveLines, setDragging } = useContext(
    DragAlignmentContext,
  );
  const { canvas } = useCanvasSize();

  return useSnapLines({
    ...config,
    containerSize: canvas,
    lines: availableLines,
    onDragStart: (e, drag, snap) => {
      setDragging(true);
      setActiveLines(snap.lines);
      config.onDragStart?.(e, drag, snap);
    },
    onDrag: (e, drag, snap) => {
      setActiveLines(snap.lines);
      config.onDrag?.(e, drag, snap);
    },
    onDragStop: (e, drag, snap) => {
      setActiveLines([]);
      setDragging(false);
      config.onDragStop?.(e, drag, snap);
    },
  });
}

export const DragAlignmentProvider: React.FC = ({ children }) => {
  const [dragging, setDragging] = useState(false);
  const [activeLines, setActiveLines] = useState<Line[]>([]);
  const { canvas } = useCanvasSize();

  return (
    <DragAlignmentContext.Provider
      value={{ setActiveLines, setDragging, availableLines: CENTERING_LINES }}
    >
      <AlignmentGuidelines
        active={dragging}
        containerSize={canvas}
        lines={activeLines}
      />
      {children}
    </DragAlignmentContext.Provider>
  );
};
