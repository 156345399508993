import React from 'react';
import { H2 } from 'components/Heading';
import { ModalFooterButton } from 'components/Modal';
import { Step } from 'components/SteppedModal';
import useTikTokAuthenticator, {
  UseTikTokAuthenticatorConfig,
} from 'hooks/useTikTokAuthenticator';
import { block } from '../utils';

export interface UseAccessDeniedStepConfig
  extends Pick<UseTikTokAuthenticatorConfig, 'onError'> {
  onReviseAccess?: () => void;
}

export default function useAccessDeniedStep({
  onError,
  onReviseAccess,
}: UseAccessDeniedStepConfig): Step {
  const { authenticating, withAuthentication } = useTikTokAuthenticator({
    force: true,
    onError,
  });

  return {
    id: 'access-denied',
    title: 'we need permission to post',
    fluidFooterButtons: true,
    component: (
      <H2 className={block('access-denied')}>
        We can’t post your video unless you allow us to manage and publish to
        your account.
      </H2>
    ),
    renderFooterButtons: ({ cancel, submit }) => [
      <ModalFooterButton {...cancel} key="cancel">
        cancel
      </ModalFooterButton>,
      <ModalFooterButton
        {...submit}
        key="submit"
        theme="submit"
        disabled={authenticating}
        onClick={withAuthentication(onReviseAccess)}
      >
        revise options
      </ModalFooterButton>,
    ],
  };
}
