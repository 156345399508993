import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import SearchInput, { SearchInputProps } from 'components/SearchInput';
import { keywordsSelector } from 'redux/modules/embed/selectors';
import { searchForGifs } from 'redux/modules/gif-search';
import { searchResultsSelector } from 'redux/modules/gif-search/selectors';
import { State } from 'redux/types';
import { getValue } from 'utils/collections';

import SearchKeywords from '../SearchKeywords';
import GIFsSearchResults, { GIFsSearchResultsProps } from './GIFsSearchResults';

type PickedSearchProps = Pick<SearchInputProps, 'onSubmit'>;
type PickedGifResultsProps = Pick<GIFsSearchResultsProps, 'onGifClick'>;

export interface GIFsSearchProps
  extends PickedSearchProps,
    PickedGifResultsProps {
  id: string;
  selectedGifEngine?: string;
  query?: string;
  className?: string;
  onInputChange?: (value: string) => void;
  onKeywordClick?: (keyword: string) => void;
  onEngineSelect?: (engine: string) => void;
}

const GIFsSearch: React.FC<GIFsSearchProps> = ({
  id,
  selectedGifEngine,
  query,
  className,
  onGifClick,
  onInputChange,
  onKeywordClick,
  onEngineSelect,
  onSubmit,
}) => {
  const keywords = useSelector(keywordsSelector);
  const gifSearchResults = useSelector((state: State) =>
    searchResultsSelector(state),
  );
  const dispatch = useDispatch();

  const handleLoadMoreGifResults = useCallback(
    (engine: string, page: number): void => {
      dispatch(searchForGifs(engine, query, page));
    },
    [dispatch, query],
  );

  const isSearching = useMemo(
    () =>
      !isEmpty(gifSearchResults) &&
      getValue(gifSearchResults, [selectedGifEngine, 'isSearching']),
    [gifSearchResults, selectedGifEngine],
  );

  return (
    <Tab.Container
      id={id}
      activeKey={id}
      className={classNames('media-search', 'media-search--default', className)}
      onSelect={() => {}}
    >
      <div>
        <div className="media-search__right">
          <SearchInput
            placeholder="Enter Keyword"
            value={query}
            disabled={isSearching}
            onChange={onInputChange}
            onSubmit={onSubmit}
          />

          <SearchKeywords
            keywords={keywords}
            disabled={isSearching}
            onKeywordClick={onKeywordClick}
          />

          <Tab.Content
            className="media-search__results-content"
            unmountOnExit
            animation={false}
          >
            <Tab.Pane eventKey={id}>
              <GIFsSearchResults
                selectedEngine={selectedGifEngine}
                results={gifSearchResults || {}}
                navContainerClassName="media-search__providers"
                onEngineSelect={onEngineSelect}
                onGifClick={onGifClick}
                onLoadMoreResults={handleLoadMoreGifResults}
              />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
};

export default GIFsSearch;
