import React from 'react';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import OptIn from './OptIn';
import OptInSuccess from './OptInSuccess';

export interface EddyPromoModalContentsProps {
  isSubmitting: boolean;
  onSubmit: () => void;
}

const EddyPromoModalContents: React.FC<EddyPromoModalContentsProps> = ({
  isSubmitting,
  onSubmit,
}) => {
  const {
    params: { source },
  } = useConnectedModal('EddyPromo');

  return isSubmitting ? <OptInSuccess /> : <OptIn {...{ onSubmit, source }} />;
};

export default EddyPromoModalContents;
