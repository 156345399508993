import * as React from 'react';

import { RefType } from 'types';
import AccessControl, { AccessControlProps } from '../AccessControl';
import OptionTile, { OptionTileProps } from './OptionTile';

export type AccessControlOptionTileProps<P = any> = AccessControlProps &
  OptionTileProps<P>;

const AccessControlOptionTile = React.forwardRef(
  <P extends {} = any>(
    {
      active,
      className,
      disabled,
      featurePath,
      icon,
      id,
      integratorBrand,
      label,
      onClick,
      params,
      showProLabel,
      type,
    }: AccessControlOptionTileProps<P>,
    ref: RefType<typeof OptionTile>,
  ) => (
    <AccessControl featurePath={featurePath}>
      {({ state }) => (
        <OptionTile
          {...{
            active,
            className,
            disabled,
            icon,
            id,
            integratorBrand,
            label,
            onClick,
            params,
            showProLabel,
            ref,
          }}
          type={(() => {
            if (state === 'locked' || state === 'coming-soon') {
              return state;
            }
            return type;
          })()}
        />
      )}
    </AccessControl>
  ),
);

export default AccessControlOptionTile;
