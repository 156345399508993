import * as React from 'react';
import { useDispatch } from 'react-redux';

import { ProCard } from 'components/icons';
import { pushModal } from 'redux/modules/modal/actions';
import bem from 'utils/bem';

import PaidSubscription from '../../components/PaidSubscription';
import PausedSubscriptionInfo from '../PausedSubscriptionInfo';
import usePausedSubscriptionInfo from '../PausedSubscriptionInfo/usePausedSubscriptionInfo';
import PaymentDetailsGate from '../PaymentDetailsGate/PaymentDetailsGateContainer';
import PaymentInfo from '../PaymentInfo';
import ProSubscriptionHeader from './ProSubscriptionHeader';

export interface ProSubscriptionProps {}

const block = bem('pro-subscription');

const ProSubscription: React.SFC<ProSubscriptionProps> = () => {
  const dispatch = useDispatch();
  const { showPausedInfo, title, subtitle } = usePausedSubscriptionInfo();

  return (
    <PaidSubscription className={block()}>
      <ProSubscriptionHeader />
      <PaidSubscription.Body>
        <PaymentDetailsGate>
          {showPausedInfo ? (
            <PausedSubscriptionInfo title={title} subtitle={subtitle} />
          ) : (
            <PaymentInfo
              cardElement={<ProCard />}
              onUpdatePlanClick={() =>
                dispatch(pushModal({ name: 'AdjustPlan' }))
              }
            />
          )}
        </PaymentDetailsGate>
      </PaidSubscription.Body>
    </PaidSubscription>
  );
};

export default ProSubscription;
