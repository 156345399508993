import CaptionsTimesLock from './CaptionsTimesLock';
import DeleteOverlayButton from './DeleteOverlayButton';
import DuplicateOverlayButton from './DuplicateOverlayButton';

const TextOverlayFooterExternalControls = {
  CaptionsTimesLock,
  DeleteOverlayButton,
  DuplicateOverlayButton,
};

export default TextOverlayFooterExternalControls;
