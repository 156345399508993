import cn from 'classnames';
import React from 'react';

import { CircularGradientEnvelope } from 'components/icons/circular-gradient';
import ExportSuccessButton, {
  ExportSuccessButtonProps,
} from './ExportSuccessButton';
import { block } from './utils';

interface ExportSuccessCtaConfig {
  label: string;
  to: ExportSuccessButtonProps['to'];
}

export interface ExportSuccessStepProps {
  secondaryCta?: ExportSuccessCtaConfig;
  primaryCta: ExportSuccessCtaConfig;
  icon?: React.ReactElement;
  iconClassName?: string;
  subtitle: string;
  title?: string;
}

const ExportSuccessStep: React.FC<ExportSuccessStepProps> = ({
  icon,
  iconClassName,
  primaryCta,
  secondaryCta,
  title = 'Check your inbox soon!',
  subtitle,
}) => (
  <div className={block()}>
    <div className={block('title')}>{title}</div>
    <div className={block('subtitle')}>{subtitle}</div>
    <div className={cn(block('icon'), iconClassName)}>
      {icon || <CircularGradientEnvelope />}
    </div>
    {secondaryCta && (
      <ExportSuccessButton to={secondaryCta.to}>
        {secondaryCta.label}
      </ExportSuccessButton>
    )}
    <ExportSuccessButton theme="submit-alt" to={primaryCta.to}>
      {primaryCta.label}
    </ExportSuccessButton>
  </div>
);

export default ExportSuccessStep;
