import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SubscriptionPeriod } from 'blocks/Pricing/types';
import usePlanCancellation from 'blocks/Pricing/usePlanCancellation';
import Button from 'components/Button';
import { SwitchField } from 'components/Form';
import { H1 } from 'components/Heading';
import LinkButton from 'components/LinkButton';
import LoadingOverlay from 'components/LoadingOverlay';
import Modal from 'components/Modal';
import { onClickFromDowngradeUpsellModal } from 'redux/modules/mixpanel';
import { hideModal, modalParamsSelector } from 'redux/modules/modal';
import { createChainedFunction } from 'utils/functions';
import usePlanDowngrade from '../../usePlanDowngrade';
import BasicPlanHeader from '../Plan/BasicPlan/BasicPlanHeader';
import { basicFeatures } from '../Plan/features';
import PlanFeatureList from '../Plan/PlanFeatureList';
import { block } from './utils';

const { useCallback, useState } = React;

export interface DowngradeUpsellContentsProps {}

const features = [
  basicFeatures.unwatermarkedVideos,
  basicFeatures.captions,
  basicFeatures.templates,
];

const DowngradeUpsellContents: React.FC<DowngradeUpsellContentsProps> = () => {
  const [subscriptionPeriod, setSubscriptionPeriod] = useState<
    SubscriptionPeriod
  >('monthly');
  const dispatch = useDispatch();
  const { details, reason } = useSelector(modalParamsSelector) || {};
  const { onSubmit } = usePlanCancellation({ reason });

  const closeModal = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  const { disabled, onDowngrade } = usePlanDowngrade({
    onDowngradeComplete: closeModal,
  });

  const handleCancel = useCallback(async () => {
    await onSubmit(details);
    closeModal();
  }, [closeModal, details, onSubmit]);

  return (
    <Modal.Body className={block('body')}>
      {disabled && <LoadingOverlay title="Updating Subscription" />}
      <H1 className={block('heading')}>Have you seen our cheaper plan?</H1>
      <SwitchField
        className={block('cost-switch')}
        left="monthly"
        onChange={setSubscriptionPeriod as any}
        right="yearly"
        value={subscriptionPeriod}
      />
      <div className={block('callout')}>
        <BasicPlanHeader
          className={block('cost')}
          density="comfortable"
          period={subscriptionPeriod}
        />
        <PlanFeatureList
          className={block('features')}
          featureClassName={block('feature')}
          features={features}
        />
      </div>
      <Button
        className={block('primary-cta')}
        theme="confirm"
        fluid
        onClick={createChainedFunction(() => {
          dispatch(onClickFromDowngradeUpsellModal('Switch'));
        }, onDowngrade)}
      >
        switch to basic
      </Button>
      <LinkButton
        className={block('secondary-cta')}
        onClick={createChainedFunction(() => {
          dispatch(onClickFromDowngradeUpsellModal('Cancel'));
        }, handleCancel)}
      >
        NO THANKS, JUST CANCEL MY PLAN
      </LinkButton>
    </Modal.Body>
  );
};

export default DowngradeUpsellContents;
