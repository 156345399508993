import { Spacer } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';
import AutomationOptionsCards from 'blocks/AutomationOptions/AutomationOptionsCards';
import {
  AutomationCardsOptions,
  DestinationSteps,
} from 'blocks/AutomationOptions/types';
import useAutomationStepOptions from 'blocks/AutomationOptions/useAutomationStepOptions';
import { DestinationOptions } from 'blocks/AutomationWorkflowWizard/containers/AutomationWorkflowWizard/types';
import { H1 } from 'components/Heading';
import LinkButton from 'components/LinkButton';
import { episodeWizardDeliveryMethodStep as block } from './utils';

interface EpisodeWizardDeliveryMethodStepProps {
  onClick: (automationOption: AutomationCardsOptions) => void;
  onBackClick: () => void;
}

const EpisodeWizardDeliveryMethodStep: React.FC<EpisodeWizardDeliveryMethodStepProps> = ({
  onClick,
  onBackClick,
}) => {
  const [automationOption, setAutomationOption] = useState<
    AutomationCardsOptions
  >();

  const { autoPostingOptions } = useAutomationStepOptions();

  const onDestinationOptionsChange = useCallback(
    (props: DestinationOptions) => {
      const selectedOption = props.automationOption;

      setAutomationOption(props.automationOption);
      onClick(selectedOption);
    },
    [onClick],
  );

  return (
    <Spacer
      orientation="vertical"
      align="center"
      justify="space-between"
      space={2.5}
    >
      <H1>How should we deliver videos?</H1>
      <LinkButton
        onClick={onBackClick}
        className={block('choose-another-button')}
        size="md"
        theme="cta"
        uppercase
      >
        ← choose another podcast
      </LinkButton>

      <AutomationOptionsCards
        options={autoPostingOptions.youtube}
        value={automationOption}
        currentStep={DestinationSteps.AUTO_POSTING}
        onChange={onDestinationOptionsChange}
      />
    </Spacer>
  );
};

export default EpisodeWizardDeliveryMethodStep;
