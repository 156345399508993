import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import _ from 'underscore';

import Modal from 'components/Modal';
import EditLogoModalBody, {
  IProps as EditLogoBodyProps,
} from './EditLogoModalBody';
import LogoModal, { IProps as LogoModalProps } from './LogoModal';
import { IWatermarkPosition, IWatermarkSize } from './types';

type ModalProps = Pick<LogoModalProps, 'show'>;
type BodyProps = EditLogoBodyProps;

export interface IProps extends ModalProps, BodyProps {
  isDeleting?: boolean;
  onCancel?: () => void;
  onHide?: () => void;
  onDelete?: () => void;
  onSubmit?: (
    watermarkPosition: IWatermarkPosition,
    watermarkSize: IWatermarkSize,
  ) => void;
  showDeleteButton?: boolean;
  title?: string;
}

interface IDataState {
  logoImageSrc?: string;
  showOverlay: boolean;
  watermarkPosition: IWatermarkPosition;
  watermarkSize: IWatermarkSize;
}

const dataFactory = Record<IDataState>({
  logoImageSrc: undefined,
  showOverlay: undefined,
  watermarkPosition: undefined,
  watermarkSize: undefined,
});

interface IState {
  data: RecordOf<IDataState>;
}

export default class EditLogoModal extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    isDeleting: false,
    onCancel: _.noop,
    onDelete: _.noop,
    onSubmit: _.noop,
    title: 'Edit Watermark',
    watermark: null,
  };

  constructor(props: IProps) {
    super(props);

    const { isDeleting, logoImageSrc } = this.props;

    this.state = {
      data: dataFactory({
        logoImageSrc,
        showOverlay: isDeleting,
        watermarkPosition: {
          left: 0,
          top: 0,
        },
        watermarkSize: {
          height: 0,
          width: 0,
        },
      }),
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const {
      logoImageSrc: nextLogoImageSrc,
      isDeleting: nextIsDeleting,
      onHide: nextOnHide,
      show: nextShow,
    } = nextProps;
    const { isDeleting, logoImageSrc, show } = this.props;

    /**
     * receiving a new logo is defined as:
     *  - having no existing logo (undefined) and receiving a new logo (not undefined)
     *  - having an existing logo (not undefined) and receiving a new logo (not undefined)
     */
    const receivingNewLogo =
      (!logoImageSrc && nextLogoImageSrc) ||
      (logoImageSrc && nextLogoImageSrc && logoImageSrc !== nextLogoImageSrc);

    // if we got a new logo, set it
    if (receivingNewLogo) {
      this.setLogo(nextLogoImageSrc);
    }

    // if the logo just finished getting removed from the video...
    if (isDeleting && !nextIsDeleting) {
      if (!show) {
        // ...and the modal is hidden, reset state
        this.hideOverlay();
        this.clearLogo();
      } else {
        // ...and the modal is open, close it. state will be reset by handleModalExited
        nextOnHide();
      }
    }

    /*
     * when the modal exits (see handleOnExited), we clear the local logo state is a delete isn't
     * in progress.  when the modal opens again, copy the logo back into state otherwise the logo
     * will be there the first time the modal opens, but then won't be there if closed and reopened
     */
    if (!show && nextShow) {
      this.setLogo(nextLogoImageSrc);
    }
  }

  private handleChangeWatermarkPosition = (
    watermarkPosition: IWatermarkPosition,
  ) => {
    this.setState(({ data }) => ({
      data: data.set('watermarkPosition', watermarkPosition),
    }));
  };

  private handleChangeWatermarkSize = (watermarkSize: IWatermarkSize) => {
    this.setState(({ data }) => ({
      data: data.set('watermarkSize', watermarkSize),
    }));
  };

  private handleModalExited = () => {
    const { isDeleting } = this.props;

    if (isDeleting) {
      this.showOverlay();
    } else {
      this.hideOverlay();
    }
  };

  private handleSubmit = () => {
    const { onSubmit } = this.props;
    onSubmit(
      this.state.data.get('watermarkPosition'),
      this.state.data.get('watermarkSize'),
    );
  };

  private setLogo(logoSrc: string) {
    this.setState(({ data }) => ({
      data: data.set('logoImageSrc', logoSrc),
    }));
  }

  private clearLogo() {
    this.setState(({ data }) => ({
      data: data.delete('logoImageSrc'),
    }));
  }

  private showOverlay() {
    this.setState(({ data }) => ({
      data: data.set('showOverlay', true),
    }));
  }

  private hideOverlay() {
    this.setState(({ data }) => ({
      data: data.set('showOverlay', false),
    }));
  }

  private renderBody: LogoModalProps['renderBody'] = () => {
    const { aspectRatio, watermark } = this.props;
    const { data } = this.state;

    return (
      <EditLogoModalBody
        aspectRatio={aspectRatio}
        logoImageSrc={data.logoImageSrc}
        onChangeWatermarkPosition={this.handleChangeWatermarkPosition}
        onChangeWatermarkSize={this.handleChangeWatermarkSize}
        showLoadingOverlay={data.showOverlay}
        watermark={watermark}
      />
    );
  };

  private renderFooter: LogoModalProps['renderFooter'] = cancelConfig => {
    const { isDeleting, onCancel, onDelete, showDeleteButton } = this.props;

    return (
      <Modal.FooterButtons>
        <Modal.FooterButton
          className={cancelConfig.className}
          onClick={onCancel}
        >
          {cancelConfig.text}
        </Modal.FooterButton>
        {showDeleteButton && (
          <Modal.FooterButton
            disabled={isDeleting}
            onClick={onDelete}
            theme="delete"
          >
            Delete
          </Modal.FooterButton>
        )}
        <Modal.FooterButton onClick={this.handleSubmit} theme="submit">
          Save
        </Modal.FooterButton>
      </Modal.FooterButtons>
    );
  };

  public render() {
    const { aspectRatio, onHide, show, title } = this.props;

    return (
      <LogoModal
        name="EditWatermark"
        aspectRatio={aspectRatio}
        onExited={this.handleModalExited}
        onHide={onHide}
        show={show}
        title={title}
        renderBody={this.renderBody}
        renderFooter={this.renderFooter}
      />
    );
  }
}
