import React from 'react';

import Modal, { ModalProps } from 'components/Modal';
import { addImageTypeModalBlock as block } from '../../utils';
import AddImageTypeSelectionModalContents, {
  AddImageTypeSelectionModalContentsProps,
} from './AddImageTypeSelectionModalContents';

export interface AddImageTypeSelectionModalProps
  extends AddImageTypeSelectionModalContentsProps,
    Pick<ModalProps, 'onExited'> {
  blockDynamicMedia?: boolean;
  onCancel?: () => void;
  show?: boolean;
}

const AddImageTypeSelectionModal: React.FC<AddImageTypeSelectionModalProps> = ({
  onCancel,
  onExited,
  onSubmit,
  show,
}) => {
  return (
    <Modal
      className={block()}
      onExited={onExited}
      onHide={onCancel}
      show={show}
      title="add media"
    >
      <AddImageTypeSelectionModalContents onSubmit={onSubmit} />
    </Modal>
  );
};

export default AddImageTypeSelectionModal;
