import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import DialogContent from 'components/DialogContent';
import { H2 } from 'components/Heading';
import CircularGradientCaptions from 'components/icons/circular-gradient/CircularGradientCaptions';
import { TranscriptionTimeRemaining } from 'containers/FreeTranscriptionBalance';
import useTranscriptionBalance from 'hooks/useTranscriptionBalance';
import { onTimeBankUpgrade } from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { captionsModalBlock as block } from '../../utils';

export interface AddClipCaptionsModalContentsProps {
  clipDurationMillis?: number;
}

const AddClipCaptionsModalContents: React.FC<AddClipCaptionsModalContentsProps> = ({
  clipDurationMillis,
}) => {
  const source = 'ClipSelectionPage';
  const dispatch = useDispatch<Dispatch>();
  const {
    canTranscribe,
    hasBalance,
    newBalanceMillis,
    showBalance,
  } = useTranscriptionBalance({
    durationMillis: clipDurationMillis,
    source,
    transcriptionEnabled: true,
  });

  const handleClick = useCallback(
    event => {
      event.preventDefault();
      dispatch(onTimeBankUpgrade(source));
      dispatch(
        replaceModal({
          name: 'UnlimitedTranscription',
        }),
      );
    },
    [dispatch],
  );

  return (
    <DialogContent
      actions={[
        {
          children: 'no thanks',
          key: 'cancel',
          params: false,
          theme: 'submit-alt',
        },
        {
          children: 'add captions',
          disabled: hasBalance && !canTranscribe,
          key: 'submit',
          params: true,
          theme: 'submit',
        },
      ]}
      className={block()}
      details={
        <H2>Captions will be styled based on the template you selected.</H2>
      }
      icon={<CircularGradientCaptions />}
      title="Add captions to this video?"
    >
      {showBalance && (
        <TranscriptionTimeRemaining
          className={block('balance')}
          onClick={handleClick}
          remainingMillis={newBalanceMillis}
        />
      )}
    </DialogContent>
  );
};

export default AddClipCaptionsModalContents;
