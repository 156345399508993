import { first, last } from 'underscore';

import {
  IManualTranscriptChunk,
  ISubChunk,
  Word,
} from 'redux/middleware/api/transcript-service/types';
import { parseChunk } from './core';

function calculateTextLength(words: Word[]): number {
  const textLen = words.reduce((len, word) => len + word.text.length, 0);
  const spacesLen = Math.max(words.length - 1, 0);
  return textLen + spacesLen;
}

function createSubchunks(words: Word[], maxLen: number): ISubChunk[] {
  // check if words is empty and return an empty array rather than creating
  // an empty subchunk
  return !words || words.length === 0
    ? []
    : words
        .reduce(
          (subchunks: Word[][], word: Word) => {
            const currentSubchunk = last(subchunks);

            if (calculateTextLength([...currentSubchunk, word]) <= maxLen) {
              currentSubchunk.push(word);
            } else {
              subchunks.push([word]);
            }

            return subchunks;
          },
          [[]],
        )
        .map(subchunk => ({
          endMillis: last(subchunk).endMillis,
          id: undefined,
          startMillis: first(subchunk).startMillis,
          text: subchunk.map(s => s.text).join(' '),
          words: subchunk,
        }));
}

export default function rechunk(
  transcript: IManualTranscriptChunk[],
  maxLen: number,
): IManualTranscriptChunk[] {
  if (maxLen === undefined) return transcript;

  return transcript.map(chunk => {
    const words = parseChunk(chunk);
    const subchunks = createSubchunks(words, maxLen);
    return { ...chunk, subchunks };
  });
}
