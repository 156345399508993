import * as React from 'react';
import FloatingActionButton from 'components/FloatingActionButton';
import FontAwesome from 'components/FontAwesome';
import Tooltip from 'components/Tooltip';
import { block } from './utils';

interface Props {
  onSaveClick?: () => void;
  empty: boolean;
  disabled: boolean;
  tooltip?: string;
}

function WaveformPresetListHeader(props: Props) {
  const { onSaveClick, empty, disabled, tooltip = '' } = props;
  return (
    <header className={block('header')}>
      <div className={block('icon')}>
        <FontAwesome icon="sliders-h" />
      </div>
      <div>Your Presets</div>
      <Tooltip
        animation={false}
        content={tooltip}
        id="save-waveform-preset-tooltip"
        placement="top"
        preventHideOnHover={false}
        show={tooltip ? undefined : false}
      >
        <div>
          {empty && (
            <span className={block('empty-message')}>
              Save this waveform for later →
            </span>
          )}
          <FloatingActionButton
            onClick={onSaveClick}
            disabled={disabled}
            theme="submit"
            size="small"
          >
            <FontAwesome icon="plus" />
          </FloatingActionButton>
        </div>
      </Tooltip>
    </header>
  );
}

export default WaveformPresetListHeader;
