import { fromJS } from 'immutable';
import * as React from 'react';
import {
  getAspectRatioDimensions,
  getAspectRatioName,
} from 'utils/aspect-ratio';
import bem from 'utils/bem';

export const block = bem('file-uploader');

export const getSupportedFormatsInText = (supportedFormats: string[]) => {
  const formats = supportedFormats
    .map(format => format.replace(/\./g, ''))
    .map(format => format.toUpperCase());

  if (formats.length === 1) return `${formats[0]} file`;

  if (formats.length === 2) {
    return (
      <>
        {formats.slice(0, formats.length - 1).join(', ')}
        <span> or </span>
        {formats[formats.length - 1]}
      </>
    );
  }

  return formats.join(', ');
};

export const getSupportedFileMaxInfoInText = (
  maxFileSizeInMb: number = undefined,
  additionalRestrictions: string = undefined,
) => {
  const text = [];

  if (maxFileSizeInMb) {
    text.push(`${maxFileSizeInMb / 1000}GB`);
  }

  if (additionalRestrictions) {
    text.push(additionalRestrictions);
  }

  return text.join(', ');
};

const TEMPLATE_DIMENSIONS_BY_ASPECT_RATIO = fromJS({
  landscape: '1920 ✕ 1080',
  square: '1080 ✕ 1080',
  portrait: '1080 ✕ 1920',
});

export const renderTemplateDimensionsInfoByAspectRatio = (
  aspectRatio: number,
) => {
  const aspectRatioDimensions = getAspectRatioDimensions(aspectRatio);

  if (!aspectRatioDimensions) return undefined;

  const width = aspectRatioDimensions.get('width');
  const height = aspectRatioDimensions.get('height');
  const aspectRationName = getAspectRatioName(height, width);
  const dimensionTextByAspectRatio = TEMPLATE_DIMENSIONS_BY_ASPECT_RATIO.get(
    aspectRationName,
  );

  return (
    <>
      {dimensionTextByAspectRatio} PX&nbsp;&nbsp;/&nbsp;&nbsp;{width}:{height}
    </>
  );
};
