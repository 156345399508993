import * as React from 'react';
import { connect } from 'react-redux';
import { noop } from 'underscore';

import LinkButton, { LinkButtonProps } from 'components/LinkButton';
import { onUseSampleAudio } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';

type DispatchProps = Pick<LinkButtonProps, 'onClick'>;

interface OwnProps {
  onClick?: (src: string) => void;
  source?: string;
}

const mapDispatchToProps = (
  dispatch: Dispatch,
  { onClick = noop, source }: OwnProps,
): DispatchProps => ({
  onClick: () => {
    source && dispatch(onUseSampleAudio(source));
    onClick(spareminConfig.sampleAudioUrl);
  },
});

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { source, ...own } = ownProps;
  return {
    ...own,
    ...stateProps,
    ...dispatchProps,
  };
}

const component = connect(null, mapDispatchToProps, mergeProps)(LinkButton);

export type SelectSampleAudioButtonProps = React.ComponentPropsWithoutRef<
  typeof component
>;

export default component;
