import * as React from 'react';

import { IconProps } from './types';

const FileVideo: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="112"
    height="135"
    viewBox="0 0 112 135"
    {...props}
  >
    <defs>
      <path
        id="b"
        d="M5.75 0h60.868a5.75 5.75 0 0 1 4.066 1.684l19.632 19.632A5.75 5.75 0 0 1 92 25.382v83.868a5.75 5.75 0 0 1-5.75 5.75H5.75A5.75 5.75 0 0 1 0 109.25V5.75A5.75 5.75 0 0 1 5.75 0z"
      />
      <filter
        id="a"
        width="137%"
        height="129.6%"
        x="-18.5%"
        y="-11.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuterFileVideo" />
        <feGaussianBlur
          in="shadowOffsetOuterFileVideo"
          result="shadowBlurOuterFileVideo"
          stdDeviation="5"
        />
        <feColorMatrix
          in="shadowBlurOuterFileVideo"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(10 6)">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="#F29E5E" xlinkHref="#b" />
      </g>
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M61.114 50H41.286C39.471 50 38 51.56 38 53.485v21.03C38 76.44 39.471 78 41.286 78h19.828c1.815 0 3.286-1.56 3.286-3.485v-21.03C64.4 51.56 62.929 50 61.114 50zm13.358 2.956L66.8 58.368v11.27l7.672 5.405C75.956 76.088 78 75.022 78 73.196V54.803c0-1.819-2.037-2.893-3.528-1.847z"
      />
    </g>
  </svg>
);

export default FileVideo;
