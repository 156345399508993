import Clipboard from 'clipboard';
import * as React from 'react';

import { LinkIcon } from 'components/icons';
import Tooltip from 'components/Tooltip';
import useOnMount from 'hooks/useOnMount';
import Searchbar from '../Searchbar';

const { useCallback, useEffect, useRef, useState } = React;

export interface CopyToClipboardProps {
  buttonClassName?: string;
  buttonLabel: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id: string;
  onCopyClick?: () => void;
  value: string;
  valueClassName?: string;
}

/**
 * A component which displays a read-only text value that can be copied to the
 * clipboard by clicking the text or the copy button.
 */
const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  buttonClassName,
  buttonLabel,
  className,
  disabled,
  id,
  onCopyClick,
  value,
  valueClassName,
}) => {
  const buttonId = `${id}-button`;
  const valueId = `${id}-value`;
  const [showTooltip, setShowTooltip] = useState(false);
  const [focused, setFocused] = useState(false);
  const tooltipTaskId = useRef(null);
  const valueElement = useRef(null);
  const clipboard = useRef(null);

  useEffect(() => {
    clipboard.current = new Clipboard(`#${buttonId}, #${valueId}`);
    clipboard.current.on('success', () => {
      clearScheduledTask();
      setShowTooltip(true);
      tooltipTaskId.current = setInterval(() => {
        clearScheduledTask();
        setShowTooltip(false);
      }, 750);
    });
  });

  useOnMount(undefined, () => {
    clearScheduledTask();
    clipboard.current && clipboard.current.destroy();
  });

  const clearScheduledTask = useCallback(() => {
    if (tooltipTaskId.current) {
      clearInterval(tooltipTaskId.current);
      tooltipTaskId.current = null;
    }
  }, []);

  const selectAll = useCallback(() => {
    const range = document.createRange();
    range.selectNode(valueElement.current);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }, []);

  const handleValueFocus = useCallback(() => {
    if (!focused) {
      selectAll();
      setFocused(true);
    }
  }, [focused, selectAll]);

  const handleValueBlur = useCallback(() => {
    if (focused) {
      window.getSelection().removeAllRanges();
    }
  }, [focused]);

  const handleValueSelect = useCallback(() => {
    selectAll();
  }, [selectAll]);

  return (
    <Searchbar className={className} compact>
      <Searchbar.Input
        id={valueId}
        className={valueClassName}
        data-clipboard-target={`#${valueId}`}
        onBlur={handleValueBlur}
        onClick={onCopyClick}
        onFocus={handleValueFocus}
        onSelect={handleValueSelect}
        readOnly
        ref={valueElement}
        type="text"
        value={value}
        disabled={disabled}
      />
      <Tooltip
        id={`${id}-tooltip`}
        placement="top"
        show={showTooltip}
        content="copied!"
      >
        <Searchbar.Button
          data-clipboard-target={`#${valueId}`}
          id={buttonId}
          className={buttonClassName}
          disabled={disabled}
          onClick={onCopyClick}
          icon={<LinkIcon height={14} />}
        >
          {buttonLabel}
        </Searchbar.Button>
      </Tooltip>
    </Searchbar>
  );
};

export default CopyToClipboard;
