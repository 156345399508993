import { HeadlinerUsage } from 'types';

interface CohortQuestion {
  id: HeadlinerUsage;
  displayName: string;
}

const cohortQuestions: Readonly<CohortQuestion[]> = [
  { id: 'education', displayName: 'Education (teacher, student)' },
  { id: 'smallBusiness', displayName: 'Small Business' },
  { id: 'nonprofit', displayName: 'Non-profit or Charity' },
  { id: 'largeCompany', displayName: 'Large Company' },
  { id: 'personal', displayName: 'Personal' },
];

export { cohortQuestions };
