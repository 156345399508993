import { replace } from 'connected-react-router/immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { Dispatch } from 'redux/types';
import { RedirectProps } from './Redirect';

export type DispatchProps = Pick<RedirectProps, 'redirect'>;
export type OwnProps = Partial<Pick<RedirectProps, 'redirect'>>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  redirect: (path: any) => dispatch(replace(path, undefined)),
});

export default function(component: React.ComponentType<RedirectProps>) {
  return connect(
    null,
    mapDispatchToProps,
    (_, dispatchProps, ownProps: OwnProps) => ({
      ...ownProps,
      ...dispatchProps,
      redirect: ownProps.redirect || dispatchProps.redirect,
    }),
  )(component);
}
