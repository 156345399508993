import * as React from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';

import { DndItemType } from 'blocks/Timeline/constants';
import { block } from '../../utils';
import TrackLabelDragPreview from './TrackLabelDragPreview';

type Coords = XYCoord | null;

export interface TrackLabelDragLayerProps {}

function getItemStyle(
  initialClientOffset: Coords,
  initialSourceOffset: Coords,
  sourceOffset: Coords,
) {
  if (initialClientOffset === null || sourceOffset === null) {
    return { display: 'none' };
  }

  const mouseSourceOffsetX = initialClientOffset.x - initialSourceOffset.x;
  const mouseSourceOffsetY = initialClientOffset.y - initialSourceOffset.y;

  const x = sourceOffset.x + mouseSourceOffsetX - 37;
  const y = sourceOffset.y + mouseSourceOffsetY - 30;

  return { transform: `translate(${x}px, ${y}px)` };
}

const TrackLabelDragLayer: React.FC<TrackLabelDragLayerProps> = () => {
  const {
    sourceOffset,
    initialClientOffset,
    initialSourceOffset,
    item,
    itemType,
  } = useDragLayer(monitor => ({
    sourceOffset: monitor.getSourceClientOffset(),
    initialClientOffset: monitor.getInitialClientOffset(),
    initialSourceOffset: monitor.getInitialSourceClientOffset(),
    item: monitor.getItem(),
    itemType: monitor.getItemType() as DndItemType,
  }));

  function renderItem(type: DndItemType) {
    switch (type) {
      case DndItemType.TRACK:
        return <TrackLabelDragPreview label={item.label} />;

      default:
        return null;
    }
  }

  return (
    <div className={block('track-label-drag-layer')}>
      <div
        style={getItemStyle(
          initialClientOffset,
          initialSourceOffset,
          sourceOffset,
        )}
      >
        {renderItem(itemType)}
      </div>
    </div>
  );
};

export default TrackLabelDragLayer;
