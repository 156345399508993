import { AnimationSegment } from 'lottie-web';
import * as React from 'react';
import Lottie from 'components/Lottie';
import animationData from './animation-data';

interface Props {
  loading: boolean;
  size?: number;
  offsetInDegrees?: number;
  onComplete?: () => void;
}

const loadingSegments: AnimationSegment = [45, 105];
const finishedSegments: AnimationSegment = [105, 135];
const [loadingStart, loadingEnd] = loadingSegments;
const loadingLength = loadingEnd - loadingStart;

const { useState, useLayoutEffect } = React;

export const LottieProgressCheckmark: React.FC<Props> = props => {
  const { loading, offsetInDegrees = 0, size = 30, onComplete } = props;
  const offset =
    Math.floor((offsetInDegrees / 360) * loadingLength) % loadingLength;
  const [segments, setSegments] = useState<AnimationSegment>([
    loadingStart + offset,
    105,
  ]);

  useLayoutEffect(() => {
    setSegments(loadingSegments);
  }, []);

  return (
    <Lottie
      width={size}
      height={size}
      options={{
        animationData,
        loop: loading,
      }}
      segments={loading ? segments : finishedSegments}
      eventListeners={[
        onComplete && { eventName: 'complete', callback: onComplete },
      ].filter(Boolean)}
    />
  );
};

export default LottieProgressCheckmark;
