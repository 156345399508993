import { Record } from 'immutable';
import { reviveRecord } from 'utils/immutable';

import { State, WizardExportState } from './types';

export const stateFactory = Record<State>({
  creationId: undefined,
  status: undefined,
  pollStatus: undefined,
});

export function stateFromJs(obj: any): WizardExportState {
  return reviveRecord(stateFactory, obj);
}
