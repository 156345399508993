import * as React from 'react';
import { useLocation } from 'react-router';

import Redirect from 'containers/Redirect';
import { removeQueryParam } from 'utils/routes';

interface Props {
  accessTokenName: string;
}

const RedirectWithoutToken: React.SFC<Props> = ({
  accessTokenName,
  ...props
}) => {
  const location = useLocation();
  return (
    <Redirect
      {...props}
      path={removeQueryParam({ location }, accessTokenName)}
    />
  );
};

export default RedirectWithoutToken;
export { Props as RedirectWithoutTokenProps };
