import React from 'react';
import { YoutubeSmall } from 'components/icons';
import { IconProps } from 'components/icons/types';
import LinkButton from 'components/LinkButton';
import { AppsProvider } from 'redux/modules/apps-connected';
import { RequestStatus } from 'types';
import { block } from '../../utils/AppsSettingsUtils';

interface AppsSettingCardProps {
  disconnectActionStatus?: RequestStatus;
  isConnected: boolean;
  onDisconnect: (provider: AppsProvider) => void;
  provider: AppsProvider;
}

const DISCONNECT_BUTTON_DISABLED_STATUSES = [RequestStatus.REQUEST];
const NAMES_MAP: Record<AppsProvider, string> = {
  youtube: 'YouTube',
};
const ICONS_MAP: Record<AppsProvider, React.FunctionComponent<IconProps>> = {
  youtube: YoutubeSmall,
};

const AppsSettingCard: React.FunctionComponent<AppsSettingCardProps> = props => {
  const { disconnectActionStatus, isConnected, onDisconnect, provider } = props;

  const appName = NAMES_MAP[provider];
  const disconnectButtonDisabled = DISCONNECT_BUTTON_DISABLED_STATUSES.includes(
    disconnectActionStatus,
  );
  const Icon = ICONS_MAP[provider];

  const handleDisconnect = React.useCallback(() => {
    onDisconnect(provider);
  }, [onDisconnect, provider]);

  const renderLeftContainer = React.useCallback((): React.ReactElement | null => {
    if (!isConnected) {
      return null;
    }

    return (
      <div className={block('app-right-container')}>
        <LinkButton
          className={block('disconnect-button')}
          disabled={disconnectButtonDisabled}
          onClick={handleDisconnect}
        >
          Disconnect
        </LinkButton>
      </div>
    );
  }, [disconnectButtonDisabled, handleDisconnect, isConnected]);

  return (
    <div className={block('app-card')}>
      <div className={block('app-left-container')}>
        <Icon />
        <span className={block('app-name')}>{appName}</span>
      </div>
      {renderLeftContainer()}
    </div>
  );
};

export default AppsSettingCard;
