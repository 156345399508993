import * as React from 'react';

import { GRID_SIZE } from 'utils/embed/ui';

import TextBoxNumericField, {
  TextBoxNumericFieldProps,
} from './TextBoxNumericField';

type Coordinate = 'x' | 'y';
type PickedProps = Pick<
  TextBoxNumericFieldProps<Coordinate>,
  'value' | 'onChange' | 'onBlur' | 'disabled'
>;

interface IProps extends PickedProps {
  coordinate: Coordinate;
}

const TextBoxPositionField: React.SFC<IProps> = ({ coordinate, ...props }) => (
  <TextBoxNumericField
    {...props}
    args={coordinate}
    label={`Position ${coordinate.toUpperCase()}`}
    min={0}
    max={GRID_SIZE.get(coordinate === 'x' ? 'width' : 'height')}
    step={1}
  />
);

export default TextBoxPositionField;
export { IProps as TextBoxPositionFieldProps };
