import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOwnedPodcastsRequest } from 'redux/modules/claim-podcast';
import {
  claimRequestResponseStatusSelector,
  claimResponseSelector,
} from 'redux/modules/claim-podcast/selectors';
import * as mixpanelActions from 'redux/modules/mixpanel';
import { pushModal } from 'redux/modules/modal';

import ClaimPodcastResultModalContents from './ClaimPodcastResultModalContents';

const OUTCOMES_MAP = {
  success: 'Success',
  emailMismatch: 'FailureIncorrectEmail',
};

const ClaimPodcastResultModalContentsContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const requestResponseStatus = useSelector(claimRequestResponseStatusSelector);
  const claimResponse = useSelector(claimResponseSelector);
  const claimStatus = claimResponse?.claimStatus;

  React.useEffect(() => {
    // gets the correct outcome value according to the claim status and dispatches
    // the MP event with the particular outcome
    const outcome = OUTCOMES_MAP[claimStatus];
    if (outcome) {
      dispatch(mixpanelActions.onClaimPodcastCompleteFlow(outcome));
    }
    // updates owned podcasts status when claim request was successful for updating the
    // status and hiding the banner
    if (claimStatus === 'success') {
      dispatch(getOwnedPodcastsRequest());
    }
  }, [claimStatus, dispatch]);

  const handleClaimAnotherPodcast = React.useCallback(() => {
    dispatch(pushModal({ name: 'ClaimPodcastModal' }));
  }, [dispatch]);

  return (
    <ClaimPodcastResultModalContents
      claimResponse={claimResponse}
      onClaimAnotherPodcast={handleClaimAnotherPodcast}
      requestStatus={requestResponseStatus}
    />
  );
};

export default ClaimPodcastResultModalContentsContainer;
