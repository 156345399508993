import { isEmpty } from 'underscore';

import { Measurement } from 'utils/measurement';

import { getCaptionStyle } from '../../assets/utils';
import { CaptionsState, SoundwaveState, TextOverlay, ToPx } from '../../types';
import { SoundwaveLayerStyle } from './types';

export const toPxValueConverter = (toPx: ToPx) => (m: Measurement) =>
  toPx(m)?.value;

export const getTextBoundingBoxStyle = (
  overlay: TextOverlay,
  toPx: ToPx,
): React.CSSProperties => {
  if (!overlay) {
    return undefined;
  }

  const toPxValue = toPxValueConverter(toPx);

  const { position, size, style } = overlay;

  return {
    height: toPxValue(size?.height),
    left: toPxValue(position?.left),
    // padding is necessary for supporting some legacy templates
    paddingBottom: toPxValue(style?.paddingBottom),
    paddingLeft: toPxValue(style?.paddingLeft),
    paddingRight: toPxValue(style?.paddingRight),
    paddingTop: toPxValue(style?.paddingTop),
    position: 'absolute',
    top: toPxValue(position?.top),
    width: toPxValue(size?.width),
  };
};

export const getCaptionsLayerStyle = (
  captions: CaptionsState,
  toPx: ToPx,
): React.CSSProperties => {
  if (!captions || isEmpty(captions)) {
    return undefined;
  }

  const toPxValue = toPxValueConverter(toPx);

  const captionsAssetStyle = getCaptionStyle(captions, toPx);

  return {
    ...captionsAssetStyle,
    height: toPxValue(captionsAssetStyle.height),
    width: toPxValue(captionsAssetStyle.width),
    left: toPxValue(captionsAssetStyle.left),
    top: toPxValue(captionsAssetStyle.top),
  };
};

export const getSoundwaveLayerStyle = (
  soundwave: SoundwaveState,
  toPx: ToPx,
): SoundwaveLayerStyle => {
  if (!soundwave) {
    return undefined;
  }

  return {
    color: soundwave.type === 'blob' ? 'transparent' : undefined,
    height: toPx(soundwave.height),
    left: toPx(soundwave.left),
    fill: soundwave.color,
    position: 'absolute',
    top: toPx(soundwave.top),
    width: toPx(soundwave.width),
  };
};
