import measurement from 'utils/measurement';
import { createOverlayParagraphHtml } from 'utils/rte';

import { createDefaultOverlay } from '../legacy';
import { convertLegacyOverlay } from './style-legacy-parser';
import { getTextBuilderStyles } from './text-builder-styles';
import { TextOverlayV2 } from './types';
import { TEXT_OVERLAY_MODAL_VERSION } from './utils';

/**
 * Calculates the font width percentage rate for each font size px. With
 * this rate the font size can be accurately scaled within the modal.
 */
export const calculateFontWidthPercPerPx = (
  baseOverlay: TextOverlayV2,
): {
  fontSizePx: number;
  fontSizeVw: number;
  fontWidthPercPerPx: number;
} => {
  const isNativeOverlay =
    baseOverlay.get('version') >= TEXT_OVERLAY_MODAL_VERSION;

  if (isNativeOverlay) {
    const fontSizePx = baseOverlay.getIn(['editor', 'textStyle', 'fontSize']);
    const fontSizeVw = baseOverlay.getIn(['editor', 'scaler', 'fontSizeVw']);

    return {
      fontSizePx,
      fontSizeVw,
      fontWidthPercPerPx: fontSizeVw / fontSizePx / 100,
    };
  }

  const fontSizePx = baseOverlay.getIn(['style', 'fontSize']);
  const fontSizeVw = measurement(fontSizePx, 'px').toUnit(
    'vw',
    baseOverlay.get('viewport')?.toJS(),
  ).value;

  return {
    fontSizePx,
    fontSizeVw,
    fontWidthPercPerPx: fontSizeVw / fontSizePx / 100,
  };
};

/**
 * Adds a series of transformations to the base overlays style for injecting
 * the v2 expected default styles for a given style.
 */
export const injectDefaultStyles = (overlay: TextOverlayV2): TextOverlayV2 =>
  overlay.withMutations(s =>
    s
      .setIn(['style', 'textHighlight'], undefined)
      .setIn(['style', 'textShadow'], undefined),
  );

/**
 * Creates a default overlay over the original v1 default overlay.
 * - It moves the just created style to textStyle for being used by the modal
 * toolbar state.
 * - It sets/overrides the viewport using the base static base viewpoer defined
 * for the current aspect ratio.
 */
export const createDefaultOverlayV2 = (
  aspectRatio: number,
  styleOverrides: Record<string, unknown> = {},
): TextOverlayV2 => {
  const baseOverlay = injectDefaultStyles(
    createDefaultOverlay(aspectRatio),
  ).withMutations(s =>
    Object.entries(styleOverrides).forEach(([styleKey, value]) => {
      s.setIn(['style', styleKey], value);
    }),
  );

  const {
    fontSizePx,
    fontSizeVw,
    fontWidthPercPerPx,
  } = calculateFontWidthPercPerPx(baseOverlay);

  // For all new overlays, the textStyle is set based in the original
  // style. Also the scaler prop for the font width is set.
  return baseOverlay.withMutations(s =>
    s
      .set('version', 2)
      .setIn(['editor', 'textStyle'], s.get('style'))
      .setIn(['editor', 'textStyle', 'fontSize'], fontSizePx)
      .setIn(['editor', 'scaler', 'fontSizeVw'], fontSizeVw)
      .setIn(['editor', 'scaler', 'fontWidthPercPerPx'], fontWidthPercPerPx),
  );
};

/**
 * Precond: The text is a single line. It can break into multiple lines but
 * it should not contain line breaks.
 *
 * Dynamic text overlays creation is different from the static ones:
 * - As html is created by the editor modal, static ones only require the
 * the styles to be defined. The html is later generated when the editor
 * is submitted.
 * - On the other hand the dynamic overlays are created by a button click,
 * this means that not only the overlay but also the html string is required.
 * Otherwise the preview would not be available or accurate in the best case.
 *
 * For that reason this util creates a base overlay with an initial html
 * string. This html is generated and styled in a similar way than the one
 * that is used later by the editor.
 */
export const createDynamicBaseOverlay = (
  aspectRatio: number,
  text: string,
): TextOverlayV2 => {
  if (text.includes('\n')) {
    throw new Error('Line breaks are not supported');
  }

  const defaultOverlay = createDefaultOverlayV2(aspectRatio);
  const textStyles = defaultOverlay.getIn(['editor', 'textStyle']);

  const { lineStyle, paragraphStyle } = getTextBuilderStyles({
    ...textStyles,
    textHighlight: 'rgba(0,0,0,0)',
  });

  const textHtml = createOverlayParagraphHtml(text, paragraphStyle, lineStyle);

  return defaultOverlay.withMutations(s =>
    s.set('text', text).set('textHtml', textHtml),
  );
};

/**
 * Formats the base overlay and appends the font scaler value to the
 * overlay config.
 */
export const formatBaseOverlay = (
  baseOverlay: TextOverlayV2,
): TextOverlayV2 => {
  const { fontSizeVw, fontWidthPercPerPx } = calculateFontWidthPercPerPx(
    baseOverlay,
  );

  return baseOverlay.withMutations(s =>
    s
      .setIn(['editor', 'scaler', 'fontWidthPercPerPx'], fontWidthPercPerPx)
      .setIn(['editor', 'scaler', 'fontSizeVw'], fontSizeVw),
  );
};

/**
 * Validates if an overlay matches the expected integrity for a v2 overlay
 */
export const validateOverlayV2Integrity = (
  baseOverlay?: TextOverlayV2,
): boolean => {
  if (!baseOverlay) {
    return false;
  }

  const hasScaler = Boolean(baseOverlay.get('editor')?.get('scaler'));
  const hasTextStyle = Boolean(baseOverlay.get('editor')?.get('textStyle'));
  const matchesVersion =
    baseOverlay.get('version') >= TEXT_OVERLAY_MODAL_VERSION;

  return hasScaler && hasTextStyle && matchesVersion;
};

/**
 * Upgrades a legacy text overlay for being used at the V2 modal.
 * Precond: It assumes isLegacyOverlayConvertAble has been checked for
 * the overlay
 */
export const updateOverlayToV2 = (
  baseOverlay: TextOverlayV2,
): TextOverlayV2 => {
  const convertedOverlay = convertLegacyOverlay(baseOverlay);

  // For all new overlays, the textStyle is set based in the original
  // style. Also the scaler prop for the font width is set.
  return convertedOverlay.withMutations(s =>
    s.set('version', 2).setIn(['editor', 'textStyle'], s.get('style')),
  );
};

/**
 * Gets the initial text overlay config:
 * - It returns a base overlay when the base overlay is not defined or it has
 * a previous version to 2.
 * - Otherwise it loads the overlay config directly.
 *
 * Preventing loading a non compatible overlay info prevents unwanted crashes.
 * The responsibility of picking the legacy template is not a concern of this
 * component.
 */
export const getInitialOverlay = (
  aspectRatio: number,
  baseOverlay?: TextOverlayV2,
): TextOverlayV2 => {
  if (!baseOverlay) {
    return createDefaultOverlayV2(aspectRatio);
  }
  if (!validateOverlayV2Integrity(baseOverlay)) {
    return updateOverlayToV2(baseOverlay);
  }

  return formatBaseOverlay(baseOverlay);
};
