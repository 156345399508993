import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  hideModal,
  isActiveSelector,
  modalParamsSelector,
  popModal,
  popModalIfActive,
} from 'redux/modules/modal';
import { ModalName } from 'redux/modules/modal/types';
import { Dispatch } from 'redux/types';

interface Modal {
  name: string;
  params?: any;
}

export interface UseConnectedModalReturnType<T extends Modal = any> {
  onExited: () => void;
  onHide: (value?: any) => any;
  params: T['params'];
  show: boolean;
}

export default function useConnectedModal<T extends Modal = any>(
  name: ModalName,
): UseConnectedModalReturnType<T> {
  const dispatch = useDispatch<Dispatch>();
  const onHide = useCallback(value => dispatch(hideModal(value)), [dispatch]);
  const onExited = useCallback(() => dispatch(popModal()), [dispatch]);
  const show = useSelector(isActiveSelector(name));
  const params = useSelector(modalParamsSelector);

  // cleanup modal if it is still active after unmount
  useEffect(
    () => () => {
      dispatch(popModalIfActive(name));
    },
    [dispatch, name],
  );

  return {
    onExited,
    onHide,
    params,
    show,
  };
}
