import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { onAutoCmsBackCatalogNewButton } from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { getAudioWizardPath } from 'utils/routes';
import AutomationCMSModalContext from '../containers/AutomationCMSRich/AutomationCMSModalContext';

export default function useEpisodeAutogram() {
  const dispatch = useDispatch<Dispatch>();
  const modalDispatch = useContext(AutomationCMSModalContext);
  const history = useHistory();

  const createAutogramForEpisode = useCallback(
    async (episodeId: string, isEmpty: boolean, workflowCount: number) => {
      dispatch(onAutoCmsBackCatalogNewButton(isEmpty));
      const result = await dispatch(
        pushModal({
          name: 'EpisodeCreationMethodSelect',
          params: { episodeId },
        }),
      );

      if (result === 'manual') {
        history.push(getAudioWizardPath(episodeId));
        return;
      }

      if (result === 'auto') {
        if (workflowCount) {
          modalDispatch({
            type: 'show-episode-create',
            payload: { episodeId },
          });
        } else {
          modalDispatch({
            type: 'show-workflow-create',
            payload: { episodeId },
          });
        }
      }
    },
    [dispatch, history, modalDispatch],
  );

  return {
    createAutogramForEpisode,
  };
}
