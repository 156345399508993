import React from 'react';
import { ActionStepCardSpacer } from 'components/ActionStepCard';

import Divider from 'components/Divider';
import EditAutomationAudioSelection from './EditAutomationAudioSelection';
import EditAutomationEpisode from './EditAutomationEpisode';
import EditAutomationInput from './EditAutomationInput';
import EditAutomationOutput from './EditAutomationOutput';
import EditAutomationVisuals from './EditAutomationVisuals';

import useEditAutomationModal from './hooks/useEditAutomationModal';
import { block } from './utils';

interface EditAutomationModalContentsProps {
  subscriptionId?: number;
}

const EditAutomationModalContents: React.FunctionComponent<EditAutomationModalContentsProps> = props => {
  const { subscriptionId } = props;

  const { subscription } = useEditAutomationModal(subscriptionId);

  return (
    <div className={block('container')}>
      <EditAutomationInput subscription={subscription} />
      <ActionStepCardSpacer />
      <EditAutomationAudioSelection subscription={subscription} />
      <ActionStepCardSpacer />
      <EditAutomationVisuals subscription={subscription} />
      <ActionStepCardSpacer />
      <EditAutomationOutput subscription={subscription} />
      <Divider className={block('divider')} />
      <EditAutomationEpisode subscriptionId={subscriptionId} />
    </div>
  );
};

export default EditAutomationModalContents;
