import { ShareVideoOptions } from 'redux/middleware/api/podcast-service';
import { shareVideo } from 'redux/middleware/api/podcast-service/actions';
import {
  getOrganizations as getOrganizationsAction,
  getSocialProfile,
} from 'redux/middleware/api/third-party-authentication-service/actions';
import { GetSocialProfileAction } from 'redux/middleware/api/third-party-authentication-service/types';
import {
  hasShareFailed,
  isShareStatusResolved,
} from 'redux/middleware/api/video-export-service';
import { getSocialShare } from 'redux/middleware/api/video-export-service/actions';
import { onPostLinkedinVideoSuccess } from 'redux/modules/mixpanel/actions';
import { showNotification } from 'redux/modules/notification/actions';
import { ThunkAction } from 'redux/types';
import { SocialSharePlatform } from 'types';
import reduxPoll from 'utils/redux-poll';
import { Type } from './action-types';
import {
  linkedinSelector,
  linkedinSocialShareIdSelector,
  linkedinSocialSharesSelector,
  linkedinUserDataSelector,
} from './selectors';
import {
  LinkedinAuthorizeSuccessAction,
  LinkedinPostMessageData,
} from './types';
import { checkIsLinkedinOrganizationProfile } from './utils';

const LINKEDIN_POST_ID = 'app/linkedin/post-poll';
const LINKEDIN_PROFILE_ID = 'app/linkedin/profile-poll';

const checkServiceUnavailableError = (err: unknown) =>
  String(err).includes('SERVICE UNAVAILABLE');

export const getLinkedinUser = (
  response: GetSocialProfileAction,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.LINKEDIN_USER_DATA_GET_REQUEST });

  try {
    dispatch({
      payload: response,
      type: Type.LINKEDIN_USER_DATA_GET_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: new Error('Error getting user data'),
      type: Type.LINKEDIN_USER_DATA_GET_FAILURE,
    });

    throw error;
  }
};
export const getOrganizations = (
  linkedinUserId?: string,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.LINKEDIN_ORGANIZATIONS_GET_REQUEST });

  try {
    const {
      providerUserId = linkedinUserId,
      idToken,
      accessToken,
    } = linkedinUserDataSelector(getState());
    const { response } = await dispatch(
      getOrganizationsAction(
        SocialSharePlatform.LINKEDIN,
        providerUserId,
        idToken,
        accessToken,
      ),
    );

    dispatch({
      payload: response,
      type: Type.LINKEDIN_ORGANIZATIONS_GET_SUCCESS,
    });
  } catch (err) {
    if (!checkServiceUnavailableError(err)) {
      dispatch({
        error: new Error('Error getting linkedin pages'),
        type: Type.LINKEDIN_ORGANIZATIONS_GET_FAILURE,
      });
    }

    throw err;
  }
};

export const clearLinkedinUser = () => ({
  type: Type.LINKEDIN_USER_DATA_CLEAR,
});

const showSuccessNotification = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const { sharePostUrl } = linkedinSocialSharesSelector(getState());

  dispatch(
    showNotification(
      'You can see your post {{link}}',
      'success',
      0,
      'link',
      'Your video was posted',
      undefined,
      sharePostUrl,
      'here',
    ),
  );
};

const showErrorNotification = () =>
  showNotification({
    message: 'Please try again or {{link}} so we can help',
    code: 'ER013',
    level: 'error',
    type: 'help',
    title: "Sorry, we couldn't post your video",
    actionLabel: 'contact us',
  });

const waitForLinkedinPost = (
  isOrganizationProfile: boolean,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.LINKEDIN_VIDEO_POST_AWAIT_REQUEST });

  const socialShareId = linkedinSocialShareIdSelector(getState());

  try {
    await reduxPoll(dispatch, () => dispatch(getSocialShare(socialShareId)), {
      id: LINKEDIN_POST_ID,
      intervalMillis: 3000,
      maxAttempts: spareminConfig.videoExportPollMaxAttempts,
      shouldContinue: err => {
        if (err) return false;
        const share = linkedinSocialSharesSelector(getState());
        // if share status is an error, the action should fail
        if (hasShareFailed(share)) {
          throw new Error('Social share failed');
        }
        return isShareStatusResolved(share);
      },
    });

    dispatch({
      type: Type.LINKEDIN_VIDEO_POST_AWAIT_SUCCESS,
    });
    dispatch(showSuccessNotification());
    dispatch(
      onPostLinkedinVideoSuccess(
        isOrganizationProfile ? 'BusinessPage' : 'PersonalFeed',
      ),
    );
  } catch (err) {
    // NB: error isn't send in the action because it is thrown and handled by
    // postLinkedinVideo, which will send it to the reducer
    dispatch({ type: Type.LINKEDIN_VIDEO_POST_AWAIT_FAILURE });
    throw err;
  }
};
export const waitForProfile = (): // onSuccess: Function,
ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.LINKEDIN_USER_DATA_GET_AWAIT_REQUEST });

  try {
    await reduxPoll(
      dispatch,
      async () => {
        const {
          providerUserId,
          idToken,
          accessToken,
        } = linkedinUserDataSelector(getState());
        const { response } = await dispatch(
          getSocialProfile(
            SocialSharePlatform.LINKEDIN,
            providerUserId,
            idToken,
            accessToken,
          ),
        );

        if (response) {
          // operations have to be retrieved before setting linkedin user
          // otherwise "shouldContinue" will find username and finish polling cicle
          await dispatch(getOrganizations());
          await dispatch(getLinkedinUser(response));
        }
      },
      {
        id: LINKEDIN_PROFILE_ID,
        intervalMillis: 3000,
        maxAttempts: spareminConfig.videoExportPollMaxAttempts,
        shouldContinue: err => {
          if (err && !checkServiceUnavailableError(err)) {
            return false;
          }

          const { username } = linkedinSelector(getState());
          return username === undefined;
        },
      },
    );

    dispatch({
      type: Type.LINKEDIN_USER_DATA_GET_AWAIT_SUCCESS,
    });
  } catch (err) {
    // NB: error isn't send in the action because it is thrown and handled by
    // postLinkedinVideo, which will send it to the reducer
    dispatch({ type: Type.LINKEDIN_USER_DATA_GET_AWAIT_FAILURE });
    throw err;
  }
};

export const postLinkedinVideo = (
  embedVideoId: string,
  opts: ShareVideoOptions,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.LINKEDIN_VIDEO_POST_REQUEST });

  const { accessToken } = linkedinUserDataSelector(getState());

  try {
    const {
      response: { socialShareId },
    } = await dispatch(
      shareVideo(embedVideoId, SocialSharePlatform.LINKEDIN, accessToken, opts),
    );

    dispatch({
      payload: { socialShareId },
      type: Type.LINKEDIN_VIDEO_POST_SUCCESS,
    });

    const isOrganizationProfile = checkIsLinkedinOrganizationProfile(
      opts.linkedinAuthorId,
    );

    await dispatch(waitForLinkedinPost(isOrganizationProfile));
  } catch (err) {
    dispatch({
      error: err,
      type: Type.LINKEDIN_VIDEO_POST_FAILURE,
    });
    dispatch(showErrorNotification());
  }
};

export const linkedinAuthorizeSuccess = (
  data: Pick<
    LinkedinPostMessageData['tokenInfo'],
    'accessToken' | 'idToken' | 'providerUserId'
  >,
): LinkedinAuthorizeSuccessAction => ({
  type: Type.LINKEDIN_AUTHORIZE_SUCCESS,
  payload: data,
});
