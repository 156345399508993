import { fromJS } from 'immutable';
import { useCallback } from 'react';
import { last } from 'underscore';
import { ITextOverlayV2 } from 'blocks/TextOverlayModal/v2';
import { TranscriptionFormValue } from 'containers/TranscriptionForm';
import { DeepImmutableMap } from 'types';
import {
  ProgressAnimationOptions,
  Soundwave,
  TimerOptions,
} from 'types/common';
import {
  ActionType,
  ImageActionPayload,
  TemplateAction,
  TextIntegrationData,
  VideoActionPayload,
  VideoTemplateStateContent,
} from './types';
import { EditorState } from '.';

export interface Style {
  backgroundColor?: string;
  image?: File | ImageActionPayload;
  progress?: ProgressAnimationOptions;
  soundwave?: Soundwave;
  textOverlay?: DeepImmutableMap<
    ITextOverlayV2 & {
      integrationData?: TextIntegrationData;
    }
  >;
  timer?: TimerOptions<string>;
  video?: File | VideoActionPayload;
  transcription?: TranscriptionFormValue;
}

export type OnStyleChange = (style: Style, actionType: ActionType) => void;

interface UseOnStyleChangeConfig {
  onStyleChange: OnStyleChange;
}

export default function useOnStyleChange({
  onStyleChange,
}: UseOnStyleChangeConfig) {
  return useCallback(
    (content: VideoTemplateStateContent, action: TemplateAction) => {
      if (
        action.type === 'SOUNDWAVE_COLOR_CHANGE' ||
        action.type === 'SOUNDWAVE_DIMENSIONS_CHANGE' ||
        action.type === 'SOUNDWAVE_FIDELITY_CHANGE' ||
        action.type === 'SOUNDWAVE_PLACEMENT_SELECT' ||
        action.type === 'SOUNDWAVE_TYPE_CHANGE' ||
        action.type === 'SOUNDWAVE_PRESET_SELECT'
      ) {
        onStyleChange(
          { soundwave: EditorState.getSoundwave(content) },
          action.type,
        );
      } else if (action.type === 'BACKGROUND_COLOR_CHANGE') {
        onStyleChange(
          { backgroundColor: EditorState.getBackgroundColor(content) },
          action.type,
        );
      } else if (action.type === 'TEXT_ADD' || action.type === 'TEXT_UPDATE') {
        const { textOverlay, integrationData } = action.payload;
        onStyleChange(
          { textOverlay: textOverlay.set('integrationData', integrationData) },
          action.type,
        );
      } else if (action.type === 'TEXT_DELETE') {
        onStyleChange({ textOverlay: undefined }, action.type);
      } else if (
        action.type === 'PROGRESS_CHANGE' ||
        action.type === 'PROGRESS_ALIGNMENT_CHANGE' ||
        action.type === 'PROGRESS_SIZE_CHANGE' ||
        action.type === 'PROGRESS_TYPE_CHANGE'
      ) {
        onStyleChange(
          { progress: EditorState.getProgressAnimationOptions(content) },
          action.type,
        );
      } else if (action.type === 'TIMER_CHANGE') {
        onStyleChange(
          {
            timer: EditorState.getTimerOptions(content),
          },
          action.type,
        );
      } else if (
        action.type === 'IMAGE_ADD' ||
        action.type === 'IMAGE_REPLACE' ||
        action.type === 'IMAGE_FILE_SELECT'
      ) {
        onStyleChange({ image: action?.payload }, action.type);
      } else if (
        action.type === 'VIDEOCLIP_ADD' ||
        action.type === 'VIDEOCLIP_REPLACE'
      ) {
        onStyleChange({ video: action?.payload }, action.type);
      } else if (action.type === 'UNDO' || action.type === 'REDO') {
        const textOverlays = EditorState.exportTextOverlays(content);
        onStyleChange(
          {
            backgroundColor: EditorState.getBackgroundColor(content),
            soundwave: EditorState.getSoundwave(content),
            progress: EditorState.getProgressAnimationOptions(content),
            textOverlay: textOverlays && fromJS(last(textOverlays)),
            timer: EditorState.getTimerOptions(content),
          },
          action.type,
        );
      } else if (action.type === 'TRANSCRIPTION_CHANGE') {
        onStyleChange(
          {
            transcription: action?.payload.transcription,
          },
          action.type,
        );
      }
    },
    [onStyleChange],
  );
}
