import * as React from 'react';
import { connect } from 'react-redux';
import { getMyUserPref } from 'redux/middleware/api/headliner-user-service';

import { clearPasswordStatus } from 'redux/modules/auth/actions';
import { loadMyWorkflows } from 'redux/modules/automation-cms';
import { getMyPreferences } from 'redux/modules/common/actions';
import { getMyDisplayPref } from 'redux/modules/display-pref/actions';
import {
  getGoogleFonts,
  getUserPersonalFonts,
} from 'redux/modules/fonts/actions';
import { subtierSelector } from 'redux/modules/pricing';
import {
  loadMyReferralData,
  loadPricingData,
} from 'redux/modules/pricing/actions';
import { Dispatch, State } from 'redux/types';
import { IProps } from './AccountSettings';

type DispatchProps = Pick<IProps, 'onWillMount'>;
type StateProps = Pick<IProps, 'subtier'>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onWillMount: () => {
    dispatch(getMyDisplayPref());
    dispatch(getMyUserPref());
    dispatch(clearPasswordStatus());
    dispatch(getMyPreferences());
    dispatch(loadPricingData({ force: true }));
    dispatch(loadMyReferralData());
    dispatch(loadMyWorkflows());
    dispatch(getUserPersonalFonts());
    dispatch(getGoogleFonts());
  },
});

const mapStateToProps = (state: State): StateProps => ({
  subtier: subtierSelector(state),
});

export default function(component: React.ComponentType<IProps>) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
