import * as React from 'react';

import bem from 'utils/bem';

export const block = bem('common-svg-properties');

const stopColorBegin = '#5DBEFC';
const stopColorEnd = '#DD92FE';

const CommonSvgProperties: React.SFC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className={block()}>
    <defs>
      <linearGradient id="pro-badge-gradient" x1="0%" y1="35.651%" y2="64.349%">
        <stop offset="0%" stopColor={stopColorBegin} />
        <stop offset="100%" stopColor={stopColorEnd} />
      </linearGradient>
      <filter id="pro-badge-filter" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" />
      </filter>

      <linearGradient
        id="instagram-gradient"
        x1="0%"
        y1="100%"
        x2="100%"
        y2="0%"
      >
        <stop offset="0%" stopColor="#fcc160" />
        <stop offset="47%" stopColor="#cd3f6a" />
        <stop offset="100%" stopColor="#6852c0" />
      </linearGradient>

      <radialGradient
        id="instagram-gradient-circle-button-gradient"
        cx="42.983%"
        cy="0%"
        r="103.088%"
        fx="42.983%"
        fy="0%"
        gradientTransform="matrix(.24291 .97005 -.97863 .24506 .325 -.417)"
      >
        <stop offset="0%" stopColor="#F9D262" />
        <stop offset="22.135%" stopColor="#F6A650" />
        <stop offset="50.167%" stopColor="#F14E2C" />
        <stop offset="82.431%" stopColor="#98069F" />
        <stop offset="100%" stopColor="#5E18D1" />
      </radialGradient>
      <filter
        id="instagram-gradient-circle-button-filter"
        width="141.2%"
        height="141.2%"
        x="-20.6%"
        y="-18.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="5.5"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
      </filter>
      <circle
        id="instagram-gradient-circle-button-circle"
        cx="42.5"
        cy="42.5"
        r="42.5"
      />

      <radialGradient
        id="instagram-gradient-square-button-gradient"
        cx="42.983%"
        cy="0%"
        r="108.52%"
        fx="42.983%"
        fy="0%"
        gradientTransform="matrix(.44764 .89421 -.94503 .47309 .237 -.384)"
      >
        <stop offset="0%" stopColor="#F9D262" />
        <stop offset="22.135%" stopColor="#F6A650" />
        <stop offset="50.167%" stopColor="#F14E2C" />
        <stop offset="82.368%" stopColor="#98069F" />
        <stop offset="100%" stopColor="#5E18D1" />
      </radialGradient>
      <filter
        id="instagram-gradient-square-button-filter"
        width="140.8%"
        height="140.8%"
        x="-20.4%"
        y="-18.1%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="5.5"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
      </filter>
      <rect
        id="instagram-gradient-square-button-rect"
        width="85.714"
        height="85.714"
        x="0"
        y="0"
        rx="18.815"
      />

      <linearGradient id="template-gradient" x1="0%" y1="8.898%" y2="91.102%">
        <stop offset="0%" stopColor={stopColorBegin} />
        <stop offset="100%" stopColor={stopColorEnd} />
      </linearGradient>
      <filter
        id="template-filter"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      />

      <linearGradient id="post-task-path-gradient" x1="0%" y1="32%" y2="68%">
        <stop offset="0%" stopColor="#72B7FC" />
        <stop offset="100%" stopColor="#C899FD" />
      </linearGradient>
      <linearGradient id="post-task-circle-gradient" x1="0%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={stopColorBegin} />
        <stop offset="100%" stopColor={stopColorEnd} />
      </linearGradient>
      <filter
        id="post-task-circle-filter"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" />
      </filter>

      <linearGradient
        id="users-gradient"
        x1="-3.937%"
        x2="102.431%"
        y1="22.876%"
        y2="75.284%"
      >
        <stop offset="0%" stopColor={stopColorBegin} />
        <stop offset="100%" stopColor={stopColorEnd} />
      </linearGradient>

      <linearGradient
        id="emoji-gradient"
        x1="-3.937%"
        x2="102.431%"
        y1="-3.639%"
        y2="100%"
      >
        <stop offset="0%" stopColor={stopColorBegin} />
        <stop offset="100%" stopColor={stopColorEnd} />
      </linearGradient>

      <linearGradient
        id="infinity-gradient"
        x1="-3.937%"
        x2="102.431%"
        y1="38.319%"
        y2="60.889%"
      >
        <stop offset="0%" stopColor={stopColorBegin} />
        <stop offset="100%" stopColor={stopColorEnd} />
      </linearGradient>

      <linearGradient id="pro-gradient" x1="0%" y1="30.642%" y2="69.358%">
        <stop offset="0%" stopColor="#63BCFC" />
        <stop offset="100%" stopColor="#D694FD" />
      </linearGradient>

      <linearGradient
        id="beta-icon-gradient"
        x1="0%"
        x2="100%"
        y1="39.111%"
        y2="60.889%"
      >
        <stop offset="0%" stopColor="#5DBEFC" />
        <stop offset="100%" stopColor="#DD92FE" />
      </linearGradient>
      <filter
        id="beta-icon-filter"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" />
      </filter>
    </defs>
  </svg>
);

export default CommonSvgProperties;
