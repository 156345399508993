import { ButtonBase, EditorDialog } from '@sparemin/blockhead';
import React from 'react';
import useTextOverlayPartialSubmit from '../../hooks/useTextOverlayPartialSubmit';

import useTextOverlayPresets from '../../hooks/useTextOverlayPresets';
import { useTextOverlay } from '../../state/TextOverlayProvider';
import { TextOverlayV2 } from '../../types';
import { block } from '../../utils';
import TextOverlayFooter from '../TextOverlayFooter';
import { TextOverlayFooterProps } from '../TextOverlayFooter/TextOverlayFooter';
import TextOverlayControlPaneTabs from './TextOverlayControlPaneTabs';
import TextOverlayPresets from './TextOverlayPresets';

export interface TextOverlayControlPaneProps {
  footerLeftActions?: TextOverlayFooterProps['leftActions'];
  isCompatibilityMode: boolean;
  onHide: () => void;
  onSubmit: () => void;
  onSwitchToLegacy?: (partialOverlay: TextOverlayV2) => void;
  shouldDisableSubmitIfEmpty: boolean;
  showEmoji: boolean;
  showPresetSelector: boolean;
  submitButtonText: string;
  subTitle?: string;
  title: string;
}

const TextOverlayControlPane: React.FunctionComponent<TextOverlayControlPaneProps> = props => {
  const {
    footerLeftActions,
    isCompatibilityMode,
    onHide,
    onSubmit,
    onSwitchToLegacy,
    shouldDisableSubmitIfEmpty,
    showEmoji,
    showPresetSelector,
    submitButtonText,
    subTitle,
    title,
  } = props;

  const { textValue } = useTextOverlay();
  const { onSelectPreset, selectedPreset } = useTextOverlayPresets();
  const onPartialSubmit = useTextOverlayPartialSubmit({
    onPartialSubmit: onSwitchToLegacy,
  });

  const isSubmitDisabled = shouldDisableSubmitIfEmpty && !textValue;

  const handleSwitchToLegacy = React.useCallback(() => {
    onPartialSubmit?.();
  }, [onPartialSubmit]);

  return (
    <>
      <EditorDialog.ControlPaneTitle>
        {title}
        {isCompatibilityMode && (
          <h6 className={block('subtitle')}>
            Welcome to our new text editor! However, if you prefer our legacy
            controls{' '}
            <ButtonBase
              as="span"
              className={block('subtitle-button')}
              onPress={handleSwitchToLegacy}
            >
              click here.
            </ButtonBase>
          </h6>
        )}
        {subTitle && <h6 className={block('subtitle')}>{subTitle}</h6>}
        <EditorDialog.CloseButton onClick={onHide} />
      </EditorDialog.ControlPaneTitle>
      <EditorDialog.ControlPaneBody threshold={0.05}>
        {showPresetSelector && (
          <EditorDialog.ControlPaneSubheaderCard>
            <TextOverlayPresets
              onPickPreset={onSelectPreset}
              selectedPreset={selectedPreset}
            />
          </EditorDialog.ControlPaneSubheaderCard>
        )}
        <EditorDialog.ControlPaneBodyCard>
          <TextOverlayControlPaneTabs showEmoji={showEmoji} />
        </EditorDialog.ControlPaneBodyCard>
      </EditorDialog.ControlPaneBody>
      <TextOverlayFooter
        isDisabled={isSubmitDisabled}
        leftActions={footerLeftActions}
        onSubmit={onSubmit}
        submitButtonText={submitButtonText}
      />
    </>
  );
};

export default TextOverlayControlPane;
