import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { projectsSelector } from 'redux/modules/entities';

const LONG_PROJECT_TYPES = [
  'episode',
  'audioLong',
  'podcastFeedAutoCreateEpisode',
];

const makeProjectSelector = () =>
  createSelector(
    projectsSelector,
    (_, projectId) => projectId,
    (projects, projectId) => {
      const project = projects?.get(projectId);
      return !project
        ? undefined
        : {
            aspectRatio: project
              .getIn(['projectConfig', 'aspectRatio'])
              ?.toJS(),
            createMethod: project.get('createMethod'),
            isEditable: project.getIn(['projectConfig', 'isEditable']),
            isLongProject: LONG_PROJECT_TYPES.includes(
              project.get('createMethod'),
            ),
            lastSuccessfulVideoWid: project.getIn([
              'latestSuccessfulVideo',
              'widgetUuid',
            ]),
            name: project.getIn(['projectConfig', 'projectName']),
            updatedAtMillis: project.getIn(['projectConfig', 'updatedAt']),
            automationSubscriptionId: project.getIn([
              'automationSubscriptionSource',
              'subId',
            ]),
          };
    },
  );

export default function useProject(projectId: string) {
  const projectSelector = useMemo(makeProjectSelector, []);
  return useSelector(state => projectSelector(state, projectId));
}
