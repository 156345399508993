import * as React from 'react';

import { IconProps } from './types';

const Replace: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 36" {...props}>
    <path
      fill="currentColor"
      d="M11.15 11.374l.123.114 7.54 7.54c.549.549.552 1.437.007 1.99l-1.334 1.353c-.548.555-1.442.561-1.997.014l-.007-.007-3.461-3.462.005 7.85c0 2.482 2.08 4.487 4.6 4.483l15.538.004c.788 0 1.426.638 1.426 1.426v1.895c0 .788-.638 1.426-1.426 1.426h-15.33c-5.221 0-9.47-3.995-9.613-8.976l-.004-.258v-7.86l-3.47 3.471c-.55.552-1.445.552-1.996 0l-.008-.006L.41 21.018c-.545-.553-.542-1.441.007-1.99l7.54-7.54c.878-.877 2.272-.916 3.195-.114zM31.166 0c5.222 0 9.472 3.995 9.614 8.976l.004.258-.001 7.859 3.47-3.47c.552-.552 1.446-.552 1.997 0l.008.006 1.334 1.353c.545.553.542 1.441-.007 1.99l-7.54 7.54c-.878.877-2.272.916-3.195.114l-.122-.114-7.54-7.54c-.549-.549-.552-1.437-.007-1.99l1.334-1.353c.548-.555 1.442-.561 1.997-.014l.007.007 3.46 3.461-.004-7.849c0-2.482-2.08-4.487-4.6-4.483l-15.538-.004c-.788 0-1.426-.638-1.426-1.426V1.426C14.41.638 15.048 0 15.836 0h15.33z"
    />
  </svg>
);

export default Replace;
