import { Banner, Spacer } from '@sparemin/blockhead';
import React from 'react';
import { block } from '../utils';

export interface EddyProjectCreationUnsupportedBannerProps {}

const EddyProjectCreationUnsupportedBanner: React.FC<EddyProjectCreationUnsupportedBannerProps> = () => {
  return (
    <Banner
      icon={
        <img
          src="https://static.sparemin.com/static/eddy/promopack-popper-header-transcript-202405311611.png"
          className={block('creation-unsupported-image')}
        />
      }
    >
      <Spacer
        orientation="vertical"
        align="flex-start"
        className={block('creation-unsupported-container')}
      >
        <Banner.Title>More features are coming</Banner.Title>

        <Banner.Content>
          We currently support captions for the language you selected and we’ll
          be adding more features like transcripts soon!{' '}
        </Banner.Content>
      </Spacer>
    </Banner>
  );
};

export default EddyProjectCreationUnsupportedBanner;
