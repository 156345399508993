import * as React from 'react';

import { block } from '../../utils';

export interface TrackLabelDragPreviewProps {
  label: string;
}

const TrackLabelDragPreview: React.FC<TrackLabelDragPreviewProps> = React.memo(
  ({ label }) => <div className={block('track-label-preview')}>{label}</div>,
);

export default TrackLabelDragPreview;
