import * as React from 'react';

import BemCssTransition from 'components/BemCssTransition';
import { blockItems as block } from '../utils';

export interface TemplateTabsContentProps {
  active?: boolean;
  animate?: boolean;
  children?: React.ReactNode;
  emptyContent?: React.ReactElement;
  grid?: boolean;
  itemCount: number;
  onEntered?: () => void;
}

const TemplateTabsContent = React.forwardRef<
  HTMLDivElement,
  TemplateTabsContentProps
>(({ active, children, emptyContent, grid, itemCount, onEntered }, ref) => (
  <BemCssTransition
    className={block({ active, grid })}
    transitionClassName={block('transition')}
    delay={{ enter: 300 }}
    in={active}
    onEntered={onEntered}
    timeout={300}
  >
    <div ref={ref}>{itemCount === 0 ? emptyContent : children}</div>
  </BemCssTransition>
));

TemplateTabsContent.defaultProps = {
  emptyContent: null,
  itemCount: 0,
};

export default TemplateTabsContent;
