import * as React from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { noop } from 'underscore';

import FileSizeUpsellBanner from 'components/FileSizeUpsellBanner';
import { SelectedMessage } from 'components/FileUploader';
import { FileUploadMethod } from 'components/FileUploader/FileUploaderDropzone';
import { MediaImportOption } from 'components/FileUploader/types';
import LanguageSelector from 'components/LanguageSelector';
import MediaUploadModal from 'containers/MediaUploadModal/MediaUploadModal';
import { MediaImportedArgs } from 'containers/MediaUploadModal/types';
import VideoUploader, { VideoUploaderProps } from 'containers/VideoUploader';
import { Tier } from 'redux/middleware/api/plan-service';
import { onIncreaseUploadLimit } from 'redux/modules/mixpanel/actions';
import { hideModal, pushModal } from 'redux/modules/modal/actions';
import { Dispatch } from 'redux/types';
import bem from 'utils/bem';

interface IProps extends VideoUploaderProps {
  onLanguageSelect?: (language: string) => void;
  onMediaAccepted?: (args: MediaImportedArgs) => void;
  language: string;
  videoSelected?: boolean;
  tier: Tier;
  defaultFile?: File;
  uploadMethod?: FileUploadMethod;
}

const UploadVideoStep: React.FC<IProps> = props => {
  const {
    language,
    onLanguageSelect = noop,
    onMediaAccepted,
    videoSelected = false,
    tier,
    ...uploaderProps
  } = props;
  const dispatch = useDispatch<Dispatch>();
  const isUsingMediaImporter = uploaderProps.uploadMethod === 'mediaImporter';

  const b = bem('upload-video-step');

  const handleClickMediaImportOption = React.useCallback(
    async (mediaImportOption: MediaImportOption): Promise<void> => {
      const mediaImportedArgs: MediaImportedArgs = await dispatch(
        pushModal({
          name: 'MediaUpload',
          params: {
            mediaImportOption,
          },
        }),
      );

      dispatch(hideModal());

      onMediaAccepted(mediaImportedArgs);
    },
    [dispatch, onMediaAccepted],
  );

  return (
    <div
      className={b({
        'media-importer': isUsingMediaImporter,
      })}
    >
      {tier === Tier.FREE && (
        <FileSizeUpsellBanner
          className={b('banner')}
          onUpgradeClick={e => {
            e.preventDefault();
            dispatch(onIncreaseUploadLimit('videoTranscription'));
            dispatch(pushModal({ name: 'FileSizeUpsell' }));
          }}
          upgradeLimit="500MB"
        />
      )}
      <div
        className={b('body', {
          contents: isUsingMediaImporter,
        })}
      >
        <VideoUploader
          {...uploaderProps}
          onClickMediaImportOption={handleClickMediaImportOption}
        >
          {videoSelected && (
            <SelectedMessage
              fileType="video"
              additionalMessage="Please selected a language and continue."
            />
          )}
        </VideoUploader>
        <Form className="upload-video-step__language-form" horizontal>
          <LanguageSelector
            className="upload-video-step__language-field"
            horizontal={{
              control: { lg: 7, xs: 12 },
              label: { lg: 5, xs: 12 },
            }}
            labelClassName="upload-video-step__form-label"
            onChange={lang => {
              onLanguageSelect(lang);
            }}
            value={language}
          />
        </Form>
      </div>

      <MediaUploadModal />
    </div>
  );
};

export default UploadVideoStep;
