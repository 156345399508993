import { ShareVideoOptions } from 'redux/middleware/api/podcast-service';
import { shareVideo } from 'redux/middleware/api/podcast-service/actions';
import {
  getSocialProfile,
  getTikTokCreatorInfoAction,
} from 'redux/middleware/api/third-party-authentication-service/actions';
import { GetSocialProfileAction } from 'redux/middleware/api/third-party-authentication-service/types';
import { isShareStatusResolved } from 'redux/middleware/api/video-export-service';
import { getSocialShare } from 'redux/middleware/api/video-export-service/actions';
import {
  onTiktokSubmitForUpload,
  onTikTokVideoUploadedSuccessfully,
} from 'redux/modules/mixpanel/actions';
import { showNotification } from 'redux/modules/notification/actions';
import { ThunkAction } from 'redux/types';
import { SocialSharePlatform } from 'types';
import reduxPoll from 'utils/redux-poll';
import { pushModal } from '../../modal/actions';
import { Type } from './action-types';
import {
  tiktokSelector,
  tiktokSocialShareIdSelector,
  tiktokSocialSharesSelector,
  tiktokUserDataSelector,
} from './selectors';
import { TiktokAuthorizeSuccessAction, TiktokPostMessageData } from './types';

const TIKTOK_POST_ID = 'app/tiktok/post-poll';
const TIKTOK_PROFILE_ID = 'app/tiktok/profile-poll';

export const getTiktokUser = (
  response: GetSocialProfileAction,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.TIKTOK_USER_DATA_GET_REQUEST });

  try {
    dispatch({
      payload: response,
      type: Type.TIKTOK_USER_DATA_GET_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: new Error('Error getting user data'),
      type: Type.TIKTOK_USER_DATA_GET_FAILURE,
    });

    throw error;
  }
};

export const clearTiktokUser = () => ({
  type: Type.TIKTOK_USER_DATA_CLEAR,
});

const showUploadedModal = (): ThunkAction<void> => dispatch => {
  dispatch(pushModal({ name: 'TiktokUploaded' }));
};

const showErrorNotification = () =>
  showNotification({
    message: 'Please try again or {{link}} so we can help',
    code: 'ER013',
    level: 'error',
    type: 'help',
    title: "Sorry, we couldn't post your video",
    actionLabel: 'contact us',
  });

const waitForTiktokPost = (): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  dispatch({ type: Type.TIKTOK_VIDEO_POST_AWAIT_REQUEST });

  const socialShareId = tiktokSocialShareIdSelector(getState());

  try {
    await reduxPoll(dispatch, () => dispatch(getSocialShare(socialShareId)), {
      id: TIKTOK_POST_ID,
      intervalMillis: 3000,
      maxAttempts: spareminConfig.videoExportPollMaxAttempts,
      shouldContinue: err => {
        if (err) return false;
        const share = tiktokSocialSharesSelector(getState());
        return isShareStatusResolved(share, ['uploaded']);
      },
    });

    dispatch({
      type: Type.TIKTOK_VIDEO_POST_AWAIT_SUCCESS,
    });
    dispatch(showUploadedModal());
    dispatch(onTikTokVideoUploadedSuccessfully());
  } catch (err) {
    // NB: error isn't send in the action because it is thrown and handled by
    // postLinkedinVideo, which will send it to the reducer
    dispatch({ type: Type.TIKTOK_VIDEO_POST_AWAIT_FAILURE });
    throw err;
  }
};
export const waitForTiktokProfile = (
  tikTokUserId?: string,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.TIKTOK_USER_DATA_GET_AWAIT_REQUEST });

  try {
    await reduxPoll(
      dispatch,
      async () => {
        const {
          providerUserId = tikTokUserId,
          idToken,
          accessToken,
        } = tiktokUserDataSelector(getState());

        const { response } = await dispatch(
          getSocialProfile(
            SocialSharePlatform.TIKTOK,
            providerUserId,
            idToken,
            accessToken,
          ),
        );

        if (response) {
          dispatch(getTiktokUser(response));
        }
      },
      {
        id: TIKTOK_PROFILE_ID,
        intervalMillis: 3000,
        maxAttempts: spareminConfig.videoExportPollMaxAttempts,
        shouldContinue: err => {
          if (err) return false;
          const { username } = tiktokSelector(getState());
          return username === undefined;
        },
      },
    );

    dispatch({
      type: Type.TIKTOK_USER_DATA_GET_AWAIT_SUCCESS,
    });
  } catch (err) {
    // NB: error isn't send in the action because it is thrown and handled by
    // postLinkedinVideo, which will send it to the reducer
    dispatch({ type: Type.TIKTOK_USER_DATA_GET_AWAIT_FAILURE });
    throw err;
  }
};

export const postTiktokVideo = (
  embedVideoId: string,
  opts: ShareVideoOptions,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.TIKTOK_VIDEO_POST_REQUEST });

  const { accessToken } = tiktokUserDataSelector(getState());

  dispatch(onTiktokSubmitForUpload());

  try {
    const {
      response: { socialShareId },
    } = await dispatch(
      shareVideo(embedVideoId, SocialSharePlatform.TIKTOK, accessToken, opts),
    );

    dispatch({
      payload: { socialShareId },
      type: Type.TIKTOK_VIDEO_POST_SUCCESS,
    });

    await dispatch(waitForTiktokPost());
  } catch (err) {
    dispatch({
      error: err,
      type: Type.TIKTOK_VIDEO_POST_FAILURE,
    });
    dispatch(showErrorNotification());
  }
};

export const tiktokAuthorizeSuccess = (
  data: Pick<
    TiktokPostMessageData['tokenInfo'],
    'accessToken' | 'idToken' | 'providerUserId'
  >,
): TiktokAuthorizeSuccessAction => ({
  type: Type.TIKTOK_AUTHORIZE_SUCCESS,
  payload: data,
});

export const getTikTokCreatorInfo = (
  tikTokUserId?: string,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.TIKTOK_CREATOR_INFO_REQUEST });

  try {
    const {
      providerUserId = tikTokUserId,
      accessToken,
    } = tiktokUserDataSelector(getState());

    const { response } = await dispatch(
      getTikTokCreatorInfoAction(providerUserId, accessToken),
    );

    dispatch({
      payload: response,
      type: Type.TIKTOK_CREATOR_INFO_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: new Error('Error getting TikTok creator info'),
      type: Type.TIKTOK_CREATOR_INFO_FAILURE,
    });

    throw error;
  }
};
