import cn from 'classnames';
import * as React from 'react';

import AutomationWorkflowTile from '../containers/AutomationWorkflowTile';
import { workflowGridBlock as block } from '../utils';

export interface AutomationWorkflowGridProps {
  className?: string;
  onClickDeleteTemplate: (id: number) => void;
  onClickToggleTemplate?: (
    id: number,
    enabled: boolean,
    hasAuthError: boolean,
  ) => void;
  workflows: number[];
}

const AutomationWorkflowGrid: React.SFC<AutomationWorkflowGridProps> = ({
  className,
  onClickDeleteTemplate,
  onClickToggleTemplate,
  workflows,
}) => (
  <div className={cn(block(), className)}>
    {workflows.map(id => (
      <AutomationWorkflowTile
        id={id}
        key={id}
        onClickDeleteTemplate={onClickDeleteTemplate}
        onClickToggleTemplate={onClickToggleTemplate}
      />
    ))}
  </div>
);

export default AutomationWorkflowGrid;
