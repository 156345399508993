import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseKeyBindOptions } from './types';
import { getActiveKeybind, parseKeyPressConfig } from './utils';

export default function useKeyBind(opts: UseKeyBindOptions): string {
  const { useCapture } = opts;
  const keyBinds = useMemo(() => parseKeyPressConfig(opts), [opts]);
  const [pressedKeybind, setPressedKeybind] = useState<string>();

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const activeKeybind = getActiveKeybind(e, keyBinds);
      if (activeKeybind) {
        setPressedKeybind(activeKeybind.description);
        activeKeybind.onKeyDown(e);
      }
    },
    [keyBinds],
  );

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      const activeKeybind = getActiveKeybind(e, keyBinds);
      if (activeKeybind) {
        setPressedKeybind(undefined);
        activeKeybind.onKeyUp(e);
      }
    },
    [keyBinds],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown, useCapture);
    window.addEventListener('keyup', handleKeyUp, useCapture);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, useCapture);
      window.removeEventListener('keyup', handleKeyUp, useCapture);
    };
  }, [handleKeyDown, handleKeyUp, useCapture]);

  return pressedKeybind;
}
