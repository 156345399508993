import * as React from 'react';

import {
  TemplateNuxOverlay,
  TemplateNuxOverlayProps,
} from 'blocks/ProjectTemplates';

type PickedOverlayProps = Pick<TemplateNuxOverlayProps, 'onExited'>;

export interface TemplateInstructionOverlayProps extends PickedOverlayProps {
  show?: boolean;
}

const TemplateInstructionOverlay: React.SFC<TemplateInstructionOverlayProps> = ({
  onExited,
  show,
}) =>
  !show ? null : (
    <TemplateNuxOverlay
      className="video-editor__template-overlay"
      onExited={onExited}
    />
  );

export default TemplateInstructionOverlay;
