import dayjs from 'dayjs';
import * as React from 'react';
import _ from 'underscore';

import FileUploader, {
  FileUploaderProps,
  SelectedMessage,
} from 'components/FileUploader';
import { AudioFile } from 'components/icons';
import { FileSource } from 'types';
import { ApplicationError } from 'utils/ApplicationError';
import { getDuration } from 'utils/audio';
import { EPISODE_MAX_DURATION_UPGRADE_SECONDS_VALUE } from 'utils/constants';

type PickedUploaderProps = Pick<
  FileUploaderProps,
  | 'className'
  | 'inProgress'
  | 'statusMessage'
  | 'maxFileSizeMb'
  | 'footer'
  | 'defaultFile'
>;

export interface IProps extends PickedUploaderProps {
  checkDurationIncreaseForTier?: boolean;
  isFileSelected?: boolean;
  maxFileDurationSeconds?: number;
  onDrop?: () => void;
  onDropAccepted?: (file: File | string, fileSource: FileSource) => void;
  onError?: (error: ApplicationError, file: File) => void;
  onLongAudioError?: () => void;
  onLongAudioForTierError?: () => void;

  // FIXME: this prop shouldn't exist.  the AudioUploader shouldn't be hardcoded to show
  // the "select a language" transcription text.
  renderTranscriptInstructions?: boolean;
  title?: FileUploaderProps['title'];
}

export default class AudioUploader extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    inProgress: false,
    isFileSelected: false,
    maxFileDurationSeconds: spareminConfig.rawAudioMaxDurationSeconds,
    onDrop: _.noop,
    onDropAccepted: _.noop,
    onError: _.noop,
    renderTranscriptInstructions: true,
  };

  private static createMaxFileDurationString(seconds: number) {
    return dayjs
      .duration(seconds, 'seconds')
      .format('H [hours] m [minutes]')
      .replace('0 hours', '')
      .replace(' 0 minutes', '');
  }

  private handleFileAccepted = (file: File) => {
    const { onDrop } = this.props;

    onDrop();
    this.handleDropAccepted(file);
  };

  private handleFileRejected = (error: ApplicationError, file: File) => {
    const { onDrop, onError } = this.props;

    onDrop();
    onError(error, file);
  };

  private handleDropAccepted = file => {
    const {
      checkDurationIncreaseForTier,
      maxFileDurationSeconds,
      onDropAccepted,
      onError,
      onLongAudioError,
      onLongAudioForTierError,
    } = this.props;

    getDuration(file, (duration, err) => {
      if (err) {
        onError(
          new ApplicationError(
            'There was an error uploading your file',
            'ER005',
          ),
          file,
        );
        // If duration increase check for tier is enabled, an extra check is enabled for validating
        // if episode duration is between the current max and the target max episode duration to be
        // obtained with a paid plan
      } else if (
        checkDurationIncreaseForTier &&
        duration > maxFileDurationSeconds &&
        duration <= EPISODE_MAX_DURATION_UPGRADE_SECONDS_VALUE
      ) {
        onLongAudioForTierError?.();
      } else if (duration > maxFileDurationSeconds) {
        onError(
          new ApplicationError(
            `Uploaded audio must be ${AudioUploader.createMaxFileDurationString(
              maxFileDurationSeconds,
            )} or shorter.  Please choose a shorter recording.`,
            'IN004',
          ),
          file,
        );
        onLongAudioError?.();
      } else {
        onDropAccepted(file, FileSource.UPLOAD);
      }
    });
  };

  private renderAudioChosenMessage = () => {
    const { renderTranscriptInstructions } = this.props;
    const additionalMessage = renderTranscriptInstructions
      ? 'Please select a language and continue.'
      : undefined;

    return (
      <SelectedMessage fileType="audio" additionalMessage={additionalMessage} />
    );
  };

  public render() {
    const {
      className,
      inProgress,
      statusMessage,
      title,
      isFileSelected,
      maxFileSizeMb,
      footer,
      maxFileDurationSeconds,
      defaultFile,
    } = this.props;

    return (
      <FileUploader
        defaultFile={defaultFile}
        className={className}
        title={title}
        accept=".mp3, .wav, .m4a"
        inProgress={inProgress}
        maxFileSizeMb={maxFileSizeMb}
        onFileAccepted={this.handleFileAccepted}
        onFileRejected={this.handleFileRejected}
        statusMessage={statusMessage}
        footer={footer}
        image={<AudioFile />}
        supportedFileTypes={['MP3', 'WAV', 'M4A']}
        supportedFileMaxSizeInMb={maxFileSizeMb}
        additionalRestrictions={
          maxFileDurationSeconds &&
          `${dayjs
            .duration(maxFileDurationSeconds, 'second')
            .format(
              maxFileDurationSeconds === 3600 ? 'H [hour]' : 'H [hrs]',
            )} max`
        }
      >
        {isFileSelected && !inProgress
          ? this.renderAudioChosenMessage()
          : undefined}
      </FileUploader>
    );
  }
}
