import { useProIndicator } from 'containers/ProIndicator';
import { SubTier } from 'redux/middleware/api/plan-service';

export default function useEpisodeTranscriptionProIndicator() {
  return useProIndicator({
    blacklist: [
      SubTier.FREE,
      SubTier.BASIC_20200922_PAID,
      SubTier.BASIC_20211112_PAID,
      SubTier.EDUCATION_NONPROFIT,
      SubTier.BASIC_PAUSED,
      SubTier.PRO_PAUSED,
    ],
  });
}
