import cn from 'classnames';
import * as React from 'react';

import { clamp, max, min } from 'utils/numbers';
import { block } from '../../utils';
import DragHandle, { DragHandleProps } from './DragHandle';

export const DRAG_HANDLE_WIDTH_PX = 11;

export type ResizingHandle = 'left' | 'right';

export type ResizeCallback = (
  width: number,
  handle: ResizingHandle,
  pos: number,
) => void;

export interface TrackAssetDragHandlesProps {
  handleClassName?: string;
  id: string;
  bounds: {
    maxPos: number;
    maxWidth: number;
    minPos: number;
    minWidth?: number;
  };
  onResize?: ResizeCallback;
  onResizeStart?: ResizeCallback;
  onResizeStop?: ResizeCallback;
  parentHandle?: string;
  position: {
    left: number;
    right: number;
  };
  pxPerSec: number;
  resizable?: boolean;
}

interface State {
  activeHandle: ResizingHandle;
}

export default class TrackAssetDragHandles extends React.Component<
  TrackAssetDragHandlesProps,
  State
> {
  public static defaultProps: Partial<TrackAssetDragHandlesProps> = {
    resizable: true,
  };

  public state: Readonly<State> = {
    activeHandle: undefined,
  };

  private handleItemBeginningDrag: DragHandleProps['onDrag'] = data => {
    const { bounds, onResize, position } = this.props;

    const { minPos, maxWidth, minWidth } = bounds;
    const { left, right } = position;

    const adjustedMinWidth = max(minWidth, 2 * DRAG_HANDLE_WIDTH_PX);
    const leftLimit = max(minPos, right - maxWidth);
    const rightLimit = right - adjustedMinWidth;

    const newPos = clamp(left + data.x, leftLimit, rightLimit);
    const newWidth = right - newPos;

    onResize(newWidth, 'left', newPos);
  };

  private handleItemBeginningDragStart: DragHandleProps['onStart'] = (
    __,
    e,
  ) => {
    const { onResizeStart, position } = this.props;
    e.stopPropagation();
    const width = position.right - position.left;
    onResizeStart(width, 'left', position.left);
    this.setActiveHandle('left');
  };

  private handleItemBeginningDragStop: DragHandleProps['onStop'] = () => {
    const { onResizeStop, position } = this.props;
    const width = position.right - position.left;
    onResizeStop(width, 'left', position.left);
    this.setActiveHandle();
  };

  private handleItemEndDrag: DragHandleProps['onDrag'] = data => {
    const { bounds, onResize, position } = this.props;

    const { maxPos, maxWidth, minWidth } = bounds;

    const { left } = position;

    const adjustedMinWidth = max(minWidth, 2 * DRAG_HANDLE_WIDTH_PX);
    const leftLimit = left + adjustedMinWidth - DRAG_HANDLE_WIDTH_PX;
    const rightLimit = min(maxPos, left + maxWidth) - DRAG_HANDLE_WIDTH_PX;

    const newPos = clamp(left + data.x, leftLimit, rightLimit);

    const newWidth = newPos - left + DRAG_HANDLE_WIDTH_PX;

    onResize(newWidth, 'right', left);
  };

  private handleItemEndDragStart: DragHandleProps['onStart'] = (_, e) => {
    e.stopPropagation();
    const { onResizeStart, position } = this.props;
    const width = position.right - position.left;
    onResizeStart(width, 'right', position.left);
    this.setActiveHandle('right');
  };

  private handleItemEndDragStop: DragHandleProps['onStop'] = () => {
    const { onResizeStop, position } = this.props;
    const width = position.right - position.left;
    onResizeStop(width, 'right', position.left);
    this.setActiveHandle();
  };

  private setActiveHandle(handle?: ResizingHandle) {
    this.setState({ activeHandle: handle });
  }

  public render() {
    const {
      handleClassName,
      id,
      parentHandle,
      position,
      pxPerSec,
      resizable,
    } = this.props;
    const { activeHandle } = this.state;

    return (
      <div className={block('track-asset-handles')}>
        <DragHandle
          className={cn(block('track-asset-handle-left'), handleClassName)}
          displayX={position.left}
          id={`track-${id}-asset-handle-left`}
          showTooltip={resizable && activeHandle === 'left'}
          onDrag={this.handleItemBeginningDrag}
          onStart={this.handleItemBeginningDragStart}
          onStop={this.handleItemBeginningDragStop}
          parentHandle={parentHandle}
          pxPerSec={pxPerSec}
          width={DRAG_HANDLE_WIDTH_PX}
          x={0}
        />
        <DragHandle
          className={cn(block('track-asset-handle-right'), handleClassName)}
          displayX={position.right}
          id={`track-${id}-asset-handle-right`}
          showTooltip={resizable && activeHandle === 'right'}
          onDrag={this.handleItemEndDrag}
          onStart={this.handleItemEndDragStart}
          onStop={this.handleItemEndDragStop}
          parentHandle={parentHandle}
          pxPerSec={pxPerSec}
          width={DRAG_HANDLE_WIDTH_PX}
          x={position.right - position.left - DRAG_HANDLE_WIDTH_PX}
        />
      </div>
    );
  }
}
