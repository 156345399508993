import { connect } from 'react-redux';

import { applyCaptionsStyle } from 'redux/modules/embed/actions';
import { showError } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { PropsOf } from 'types';
import CaptionsModals, {
  CaptionsModal,
  CaptionsModalsProps as Props,
} from './CaptionsModals';

type DispatchProps = Pick<Props, 'onEditCaptionsSubmit'>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onEditCaptionsSubmit: async (
    style,
    maxChars,
    templateName,
    shouldRechunk,
  ) => {
    try {
      await dispatch(
        applyCaptionsStyle({ style, maxChars, templateName, shouldRechunk }),
      );
    } catch {
      dispatch(
        showError({ message: 'Error updating captions style', code: 'ER012' }),
      );
    }
  },
});

const component = connect(null, mapDispatchToProps)(CaptionsModals);
export type CaptionsModalsProps = PropsOf<typeof component>;
export default component;

export { CaptionsModal };
