import { useTextAreaAutoResize } from '@sparemin/blockhead';
import React from 'react';

import { block } from '../../utils';
import { cleanupText, getTextLines, mergeTextLines } from './utils';

interface TextOverlayTextAreaProps {
  autoFocus: boolean;
  onBlur: () => void;
  onChangeText: (value: string) => void;
  textAreaStyles: React.CSSProperties;
  textValue: string;
}

const TextOverlayTextArea: React.FunctionComponent<TextOverlayTextAreaProps> = props => {
  const { autoFocus, onBlur, onChangeText, textAreaStyles, textValue } = props;

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const preventReposRef = React.useRef<number>(null);

  const { onAutoresize } = useTextAreaAutoResize({
    autoResize: true,
    textAreaRef,
  });

  // This is a hack that allows the text value to be changed without
  // loosing the selection position.
  // The text is saved as plain text in the state, but the display text
  // at the textarea is formatted for handling the spaces.
  // As a consequence of the text value being different, selection is
  // naturally moved to the text end when value is artificially changed.
  // The prevent repositioning ref helds the last valid position produced by
  // the change event.
  // As spurious change events can be triggered by the formatted value, the
  // conjunction between onChange event and onSelect event, locks this events:
  // --------------------------------------------------------------------------
  // ==> Valid change event:
  // preventPosRef is empty. This allows the change event to be processed.
  // preventPosRef is set with the current selection end position. After that
  // select event is triggered, as preventPosRef is populated, it is procceded,
  // the text area selection is updated and the preventPosRef cleared.
  // --------------------------------------------------------------------------
  // ==> Spurious change event
  // When the textValue is replaced, change event is fired immediately before
  // select event happens. That second change event is the spurious one and it
  // should be ignored. As the preventReposRef that was set by the first event
  // was not yet cleared, that second change event is dropped. After that two
  // select events are triggered, in this case the first one is proccesed
  // because the ref has a value, and the second is dropped because now the ref
  // is empty.
  // --------------------------------------------------------------------------
  const handleChangeText: React.ChangeEventHandler<HTMLTextAreaElement> = React.useCallback(
    (e): void => {
      if (preventReposRef.current === null) {
        preventReposRef.current = textAreaRef.current.selectionEnd;
        onChangeText(cleanupText(e.target.value));
      }
    },
    [onChangeText],
  );

  const handleSelectionChange = React.useCallback((): void => {
    if (preventReposRef.current !== null) {
      textAreaRef.current.selectionStart = preventReposRef.current;
      textAreaRef.current.selectionEnd = preventReposRef.current;
      preventReposRef.current = null;
    }
  }, []);

  const handleBlur = React.useCallback((): void => {
    preventReposRef.current = null;
    onBlur();
  }, [onBlur]);

  React.useEffect(() => {
    if (autoFocus) {
      textAreaRef.current?.focus();
    }
    onAutoresize();
  }, [autoFocus, onAutoresize]);

  return (
    <textarea
      ref={textAreaRef}
      className={block('text-area')}
      onBlur={handleBlur}
      onChange={handleChangeText}
      onInput={onAutoresize}
      style={textAreaStyles}
      wrap="hard"
      onSelect={handleSelectionChange}
      value={mergeTextLines(getTextLines(textValue))}
    />
  );
};

export default TextOverlayTextArea;
