import cn from 'classnames';
import * as React from 'react';

import { block } from './utils';

export interface ShareFormGroupProps {
  children?: React.ReactNode;
  className?: string;
  label?: string;
}

const ShareFormGroup: React.FC<ShareFormGroupProps> = ({
  children,
  className,
  label,
}) => (
  <div className={cn(block('group'), className)}>
    {label && <span className={block('group-label')}>{label}</span>}
    {React.Children.map(
      children,
      child =>
        React.isValidElement(child) &&
        React.cloneElement(child, {
          className: cn(block('group-element'), child.props.className),
        }),
    )}
  </div>
);

export default ShareFormGroup;
