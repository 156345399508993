import cn from 'classnames';
import * as React from 'react';

import { ArgsOf, Omit } from 'types';
import { createChainedFunction } from 'utils/functions';
import EmbedPlayer, { EmbedPlayerProps } from './EmbedPlayer';
import { block } from './utils';

type Props = Omit<EmbedPlayerProps, 'lastPlaybackPos'>;

export default class ProjectVideoPreview extends React.Component<Props> {
  private lastPlaybackPos: number;
  public embedPlayer: EmbedPlayer;

  private handleDestroy = (pos: number) => (this.lastPlaybackPos = pos);

  private handleSeeked = () => {
    this.lastPlaybackPos = undefined;
  };

  private setEmbedPlayer = (el: EmbedPlayer): void => {
    this.embedPlayer = el;
  };

  public render() {
    const { className, onDestroy, onSeeked, ...props } = this.props;

    return (
      <EmbedPlayer
        className={cn(block(), className)}
        {...props}
        ref={this.setEmbedPlayer}
        key={props.src}
        onDestroy={createChainedFunction(this.handleDestroy, onDestroy)}
        onSeeked={createChainedFunction<ArgsOf<Props['onSeeked']>>(
          this.handleSeeked,
          onSeeked,
        )}
        lastPlaybackPos={this.lastPlaybackPos}
      />
    );
  }
}

export { Props as ProjectVideoPreviewProps };
