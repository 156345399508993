import {
  getPodcastRefreshStatus,
  refreshPodcastFeed,
} from 'redux/middleware/api/podcast-service/actions';
import { ThunkAction } from 'redux/types';
import reduxPoll, { cancelReduxPoll } from 'utils/redux-poll';
import { showError } from '../notification';
import { Type } from './action-types';

export const requestPodcastFeedRefreshAction = () => ({
  type: Type.PODCAST_REFRESH_FEED_REQUEST,
});

export const finishedPodcastFeedRequestAction = () => ({
  type: Type.PODCAST_REFRESH_FEED_FINISHED,
});

export const waitForPodcastFeedRefresh = (
  podcastId: string,
): ThunkAction<Promise<void>> => async dispatch => {
  try {
    const result = await dispatch(refreshPodcastFeed(podcastId));
    const refreshJobId = result.response?.podcastFeedRefreshJobId;

    dispatch(requestPodcastFeedRefreshAction());

    reduxPoll(dispatch, () => dispatch(getPodcastRefreshStatus(refreshJobId)), {
      id: podcastId,
      intervalMillis: 2500,
      maxAttempts: Infinity,
      shouldContinue: (err, res) => {
        const { status } = res.response;

        if (err || status === 'error') {
          return false;
        }

        return status !== 'completed';
      },
    }).then(() => {
      dispatch(finishedPodcastFeedRequestAction());
    });
  } catch {
    dispatch(showError('There was an error refreshing the podcast feed'));
  }
};

export const cancelWaitForPodcastFeedRefresh = (
  podcastId: string,
): ThunkAction<void> => dispatch => {
  dispatch(finishedPodcastFeedRequestAction());
  cancelReduxPoll(dispatch, podcastId);
};
