import * as React from 'react';

import ReferralsPage from 'blocks/Pricing/containers/ReferralsPage';
import bem from 'utils/bem';
import SettingsContent from './SettingsContent';

const block = bem('referrals-settings');

const ReferralsSettings: React.SFC = () => (
  <SettingsContent className={block()} fullBleed>
    <ReferralsPage />
  </SettingsContent>
);

export default ReferralsSettings;
