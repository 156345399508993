import cn from 'classnames';
import React, { useCallback } from 'react';
import { noop } from 'underscore';

import DownloadDetailsSection from 'blocks/DownloadPage/components/DownloadDetailsSection';
import SelfServeDiscoBanner from 'blocks/ProjectsPage/containers/SelfServeDiscoBanner';
import PrivacyPolicyMessage from 'components/PrivacyPolicyMessage';
import NpsSurvey from 'containers/NpsSurvey';
import useOnMount from 'hooks/useOnMount';
import DownloadPreviewSection from '../../components/DownloadPreviewSection';
import ReclipMobileMessage from '../../components/ReclipMobileMessage';
import { block } from '../../utils';
import AutogramReviewDetails from '../AutogramReviewDetails';
import DownloadPageBannerSlot from '../DownloadPageBannerSlot';
import DownloadShareDetails from '../DownloadShareDetails';
import PromoPopper from '../PromoPopper';
import useDownloadPageState from '../useDownloadPageState';

export interface DownloadPageProps {
  aspectRatioName?: string;
  eddyProjectUrl?: string;
  canReclip: boolean;
  className?: string;
  loggedIn: boolean;
  onMount?: (isAutogramReview: boolean) => void;
  onUnmount?: () => void;
  videoUrl?: string;
}

const DownloadPage: React.FC<DownloadPageProps> = ({
  aspectRatioName,
  eddyProjectUrl,
  canReclip,
  className,
  loggedIn,
  onMount,
  onUnmount,
  videoUrl,
}) => {
  const { createMethod, isAutogramReview } = useDownloadPageState();

  useOnMount(
    useCallback(() => {
      onMount(isAutogramReview);
    }, [isAutogramReview, onMount]),
    onUnmount,
  );

  const isPodcastFeedAutoCreateEpisode =
    createMethod === 'podcastFeedAutoCreateEpisode';

  const downloadShareDetailsHidden =
    isAutogramReview && !isPodcastFeedAutoCreateEpisode;
  const showDownloadDetailsReclip =
    canReclip && !isPodcastFeedAutoCreateEpisode;
  const showAutogramReviewDetails =
    isAutogramReview && canReclip && !isPodcastFeedAutoCreateEpisode;

  return (
    <div className={cn(block(), className)}>
      <div
        className={block('container', {
          [aspectRatioName]: !!aspectRatioName,
        })}
      >
        <DownloadPageBannerSlot />
        {canReclip && <ReclipMobileMessage />}
        <div className={block('contents')}>
          <DownloadPreviewSection
            aspectRatioName={aspectRatioName}
            videoUrl={videoUrl}
          />
          <DownloadDetailsSection>
            <DownloadShareDetails
              canReclip={showDownloadDetailsReclip}
              hidden={downloadShareDetailsHidden}
              isAutogramReviewDetailsActive={showAutogramReviewDetails}
              videoUrl={videoUrl}
            />
            <AutogramReviewDetails show={showAutogramReviewDetails} />
          </DownloadDetailsSection>
        </div>
        {loggedIn && <SelfServeDiscoBanner source="DownloadPage" />}
      </div>
      <NpsSurvey />

      <PrivacyPolicyMessage />

      <PromoPopper eddyProjectUrl={eddyProjectUrl} />
    </div>
  );
};

DownloadPage.defaultProps = {
  onMount: noop,
};

export default DownloadPage;
