import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';
import IconButton from 'components/IconButton';
import { PauseCircle, PlayCircle, SkipCircle } from 'components/icons';
import Timestamps from './Timestamps';
import { block } from './utils';

interface IProps {
  className?: string;
  disabled?: boolean;
  onPause?: () => void;
  onPlay?: () => void;
  onPlaySelection?: () => void;
  playing?: boolean;
  positionMillis: number;
  durationMillis: number;
}

const PlaybackControls: React.SFC<IProps> = props => {
  const {
    className,
    disabled,
    playing,
    onPause,
    onPlay,
    onPlaySelection,
    positionMillis,
    durationMillis,
  } = props;
  const buttonClassName = block('control-button', { disabled });

  function disabledGuard<T>(arg: T) {
    if (disabled) return undefined;
    return arg;
  }

  return (
    <div className={cn(block('controls'), className)}>
      {playing ? (
        <IconButton
          disabled={disabled}
          className={cn(buttonClassName, block('pause-button'))}
          onButtonClick={disabledGuard(onPause)}
        >
          <PauseCircle />
        </IconButton>
      ) : (
        <IconButton
          disabled={disabled}
          className={cn(buttonClassName, block('play-button'))}
          onButtonClick={disabledGuard(onPlay)}
        >
          <PlayCircle />
        </IconButton>
      )}
      <IconButton
        disabled={disabled}
        className={buttonClassName}
        onButtonClick={disabledGuard(onPlaySelection)}
      >
        <SkipCircle />
      </IconButton>
      <Timestamps positionMillis={positionMillis} duration={durationMillis} />
    </div>
  );
};

PlaybackControls.defaultProps = {
  disabled: false,
  onPause: noop,
  onPlay: noop,
  onPlaySelection: noop,
  playing: false,
};

export default PlaybackControls;
