import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';

import TextOverlaySwitchModalContents from './TextOverlaySwitchModalContents';
import { block } from './utils';

const TextOverlaySwitchModal: React.FunctionComponent = () => {
  return (
    <ConnectedModal className={block()} name="TextOverlaySwitch" v2>
      <TextOverlaySwitchModalContents />
    </ConnectedModal>
  );
};

export default TextOverlaySwitchModal;
