import { Spacer } from '@sparemin/blockhead';
import React, { useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Divider from 'components/Divider';

import { VideoExportPrefsArgs } from 'redux/middleware/api/headliner-user-service';
import { pushModal } from 'redux/modules/modal';
import { isBasicOrFreeSelector } from 'redux/modules/pricing';
import { updateUserVideoExportPrefs } from 'redux/modules/user-pref/actions';
import {
  userVideoPrefsSelector,
  videoExportPrefsSelector,
} from 'redux/modules/user-pref/selectors';
import { FrameRate, FrameSize, VideoExportPreferences } from 'types';
import { VIDEO_EXPORT_PREFS_DEFAULTS } from 'utils/constants';
import { int } from 'utils/numbers';
import {
  frameRateOptions,
  frameSizeOptions,
  fullEpisodeFrameRateOptions,
} from 'utils/video-export';
import SettingsContent from '../SettingsContent';
import VSBanner from './components/VSBanner';
import VSFieldset from './components/VSFieldset';
import VSOptionsHeading from './components/VSOptionsHeading';
import { Actions } from './types';
import { block } from './utils';

function reducer(state: VideoExportPreferences, action: Actions) {
  switch (action.type) {
    case 'changeFrameRate':
      return { ...state, frameRate: action.payload };
    case 'changeFullEpisodeFrameRate':
      return { ...state, fullEpisodeFrameRate: action.payload };
    case 'changeFrameSize':
      return { ...state, frameSize: action.payload };
    case 'changeFullEpisodeFrameSize':
      return { ...state, fullEpisodeFrameSize: action.payload };
    default:
      return state;
  }
}

function VideoSettings() {
  const isFreeTier = useSelector(isBasicOrFreeSelector);
  const dispatch = useDispatch();
  const videoPrefs = useSelector(userVideoPrefsSelector);
  const initialState = useSelector(videoExportPrefsSelector);
  const [state, reducerDispatch] = useReducer(reducer, initialState);

  const saveVideoSettings = (newValues: Partial<VideoExportPrefsArgs>) => {
    dispatch(
      updateUserVideoExportPrefs({
        ...state,
        ...newValues,
      }),
    );
  };

  const handleLockedClick = () => {
    dispatch(pushModal({ name: 'FullEpisodesExport' }));
  };

  return (
    <SettingsContent
      subtitle="Manage the quality of your video exports"
      title={<>Video Settings</>}
      bodyClassName={block('body')}
      className={block()}
    >
      <VSBanner />

      <Divider />

      <div className={block('options-container')}>
        <VSOptionsHeading
          title="Short videos"
          subtitle="Exports that are 10 minutes or less"
        />

        <Spacer as="div" space="15px" className={block('options')}>
          <VSFieldset
            label="Frame rate"
            onChange={value => {
              const frameRate = int(value) as FrameRate;
              saveVideoSettings({ frameRate });
              reducerDispatch({ type: 'changeFrameRate', payload: frameRate });
            }}
            options={frameRateOptions}
            value={state.frameRate}
            variant={videoPrefs?.frameRate?.readonly ? 'locked' : 'default'}
          />
          <VSFieldset
            label="Video size"
            onChange={(frameSize: FrameSize) => {
              saveVideoSettings({ frameSize });
              reducerDispatch({ type: 'changeFrameSize', payload: frameSize });
            }}
            options={frameSizeOptions}
            value={state.frameSize}
            variant={videoPrefs?.frameSize?.readonly ? 'locked' : 'default'}
          />
        </Spacer>
      </div>

      <Divider />

      <div className={block('options-container')}>
        <VSOptionsHeading
          title="Full episodes"
          subtitle="Exports that are greater than 10 minutes"
        />

        <Spacer
          as="div"
          space="15px"
          align="flex-start"
          className={block('options')}
        >
          <VSFieldset
            label="Frame rate"
            onChange={value => {
              const fullEpisodeFrameRate = int(value) as FrameRate;
              saveVideoSettings({ fullEpisodeFrameRate });
              reducerDispatch({
                type: 'changeFullEpisodeFrameRate',
                payload: fullEpisodeFrameRate,
              });
            }}
            options={fullEpisodeFrameRateOptions}
            value={state.fullEpisodeFrameRate}
            variant={videoPrefs?.frameSize?.readonly ? 'locked' : 'default'}
          />
          <VSFieldset
            label="Video size"
            onChange={(fullEpisodeFrameSize: FrameSize) => {
              saveVideoSettings({ fullEpisodeFrameSize });
              reducerDispatch({
                type: 'changeFullEpisodeFrameSize',
                payload: fullEpisodeFrameSize,
              });
            }}
            options={frameSizeOptions}
            value={
              isFreeTier
                ? VIDEO_EXPORT_PREFS_DEFAULTS.fullEpisodeFrameSize
                : state.fullEpisodeFrameSize
            }
            variant={
              videoPrefs?.fullEpisodeFrameSize?.readonly || isFreeTier
                ? 'pro'
                : 'default'
            }
            onLockedClick={handleLockedClick}
          />
        </Spacer>
      </div>
    </SettingsContent>
  );
}

export default VideoSettings;
