import * as React from 'react';
import { useDispatch } from 'react-redux';
import DeleteModal from 'components/DeleteModal';
import Divider from 'components/Divider';
import FontAwesome from 'components/FontAwesome';
import FontLoader from 'components/hoc/FontLoader';
import TextInputModal from 'components/TextInputModal';
import Tooltip from 'components/Tooltip';
import { UpdateFontNameArgs } from 'redux/middleware/api/headliner-user-service';
import { deleteUserFont, renameUserFont } from 'redux/modules/fonts/actions';
import { FormattedPersonalFont } from 'redux/modules/fonts/types';
import { Dispatch } from 'redux/types';
import bem from 'utils/bem';

const block = bem('content-pro-fonts');

interface Props {
  fonts: FormattedPersonalFont[];
}

function ContentProFonts({ fonts }: Props) {
  const dispatch = useDispatch<Dispatch>();
  const [deleteId, setDeleteId] = React.useState('');
  const [fontRenameParams, setFontRenameParams] = React.useState<
    UpdateFontNameArgs
  >({
    displayName: '',
    fontId: '',
  });
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showRenameFontModal, setShowRenameFontModal] = React.useState(false);

  function openDeleteModal(id: string) {
    setDeleteId(id);
    setShowDeleteModal(true);
  }

  function openRenameFontModal({ displayName, fontId }: UpdateFontNameArgs) {
    setFontRenameParams({
      displayName,
      fontId,
    });
    setShowRenameFontModal(true);
  }

  function onDeleteFont() {
    setShowDeleteModal(false);
    dispatch(
      deleteUserFont({
        fontId: deleteId,
      }),
    );
  }

  function onFontNameChange(value: string) {
    setShowRenameFontModal(false);
    dispatch(
      renameUserFont({
        ...fontRenameParams,
        displayName: value,
      }),
    );
  }

  return (
    <>
      <div className={block()}>
        <div>
          {fonts.map(font => {
            const { name, family, id, url, isOrganizationAccess } = font;
            return (
              <React.Fragment key={id}>
                <div
                  className={block('font', {
                    disabled: isOrganizationAccess,
                  })}
                >
                  <FontLoader family={family} url={url} weight={400} />
                  <Tooltip
                    animation={false}
                    content="Only your administrator has permission to adjust this font"
                    id="font-upload-tooltip"
                    placement="top"
                    preventHideOnHover={false}
                    show={isOrganizationAccess}
                  >
                    <p
                      className={block('text')}
                      onClick={() => {
                        if (isOrganizationAccess) return;
                        openRenameFontModal({
                          displayName: name,
                          fontId: id,
                        });
                      }}
                      style={{ fontFamily: family }}
                    >
                      {name}
                    </p>
                  </Tooltip>
                  <div
                    className={block('delete')}
                    onClick={() => {
                      if (isOrganizationAccess) return;
                      openDeleteModal(id);
                    }}
                  >
                    <FontAwesome icon="trash-alt" />
                  </div>
                </div>
                <Divider />
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <DeleteModal
        bodyText={
          <>
            <p>Are you sure you want to delete this font?</p>
            <p>
              <strong>Existing projects will be affected.</strong>
            </p>
          </>
        }
        cancelButtonTitle="NEVERMIND, KEEP IT"
        deleteButtonTitle="YES, DELETE IT"
        onDelete={onDeleteFont}
        onCancel={() => setShowDeleteModal(false)}
        show={showDeleteModal}
        title="Delete Your Font"
      />
      <TextInputModal
        noCancelButton
        onSubmit={onFontNameChange}
        submitText="SAVE CHANGES"
        defaultValue={fontRenameParams.displayName}
        onHide={() => setShowRenameFontModal(false)}
        title="Rename Your Font"
        show={showRenameFontModal}
      />
    </>
  );
}

export default ContentProFonts;
