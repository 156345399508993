import * as React from 'react';
import { ActionCreators } from 'redux-undo';

import useKeyBind from 'hooks/useKeyBind';
import { useNavigation } from './context/NavigationContext';
import { useEditorState } from './context/VideoEditorStateContext';
import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import useDeleteLayer from './useDeleteLayer';
import useLayerOrder from './useLayerOrder';
import { getLayerIdByAssetId } from './utils';

const { useCallback, useMemo } = React;

export interface KeyHandlerProps {}

const KeyHandler: React.FC<KeyHandlerProps> = () => {
  const state = useTemplateState();
  const { overlayOpen, selectedAsset } = useEditorState();
  const [, send] = useNavigation();
  const dispatch = useTemplateDispatch();
  const { handleChangeLayerPosition } = useLayerOrder();
  const { handleDeleteLayer } = useDeleteLayer();

  const handleCloseChildView = useCallback(() => {
    if (!overlayOpen) {
      send({ type: 'CHILD_VIEW_CLOSE' });
    }
  }, [overlayOpen, send]);

  const handleUndo = useCallback(() => {
    if (!overlayOpen) {
      dispatch(ActionCreators.undo());
    }
  }, [dispatch, overlayOpen]);

  const handleRedo = useCallback(() => {
    if (!overlayOpen) {
      dispatch(ActionCreators.redo());
    }
  }, [dispatch, overlayOpen]);

  const sendToFront = useCallback(() => {
    handleChangeLayerPosition('front');
  }, [handleChangeLayerPosition]);

  const sendForward = useCallback(() => {
    handleChangeLayerPosition('forward');
  }, [handleChangeLayerPosition]);

  const sendBackward = useCallback(() => {
    handleChangeLayerPosition('backward');
  }, [handleChangeLayerPosition]);

  const sendToBack = useCallback(() => {
    handleChangeLayerPosition('back');
  }, [handleChangeLayerPosition]);

  const deleteLayer = useCallback(() => {
    const currentLayerId = getLayerIdByAssetId(state, selectedAsset.id);

    if (currentLayerId) {
      handleDeleteLayer(currentLayerId);
    }
  }, [handleDeleteLayer, selectedAsset.id, state]);

  const config = useMemo(
    () => ({
      bindings: {
        Escape: { onKeyDown: handleCloseChildView },
        'Control+z': { onKeyDown: handleUndo },
        'Meta+z': { onKeyDown: handleUndo },
        'Control+Shift+z': { onKeyDown: handleRedo },
        'Meta+Shift+z': { onKeyDown: handleRedo },
        ']': { onKeyDown: sendToFront },
        'Control+]': { onKeyDown: sendForward },
        'Control+[': { onKeyDown: sendBackward },
        '[': { onKeyDown: sendToBack },
        Delete: { onKeyDown: deleteLayer },
      },
      useCapture: true,
    }),
    [
      deleteLayer,
      handleCloseChildView,
      handleRedo,
      handleUndo,
      sendBackward,
      sendForward,
      sendToBack,
      sendToFront,
    ],
  );

  useKeyBind(config);

  return null;
};

export default KeyHandler;
