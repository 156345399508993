import * as React from 'react';

import Searchbar, { SearchbarProps } from 'components/Searchbar';
import { SearchbarInputProps } from 'components/Searchbar/SearchbarInput';

type PickedSearchbarProps = Pick<SearchbarProps, 'onSubmit'>;
type PickedInputProps = Pick<SearchbarInputProps, 'onChange' | 'value'>;

export type UrlImportFormProps = PickedSearchbarProps & PickedInputProps;

const UrlImportForm: React.FC<UrlImportFormProps> = ({
  onChange,
  onSubmit,
  value,
}) => (
  <Searchbar className="add-media-modal__searchbar" onSubmit={onSubmit}>
    <label className="add-media-modal__label" htmlFor="media-search-input">
      Add media from a website
    </label>
    <div className="add-media-modal__input-wrapper">
      <Searchbar.Input
        id="media-search-input"
        onChange={onChange}
        placeholder="Enter a video or image URL"
        value={value}
      />
      <Searchbar.Button disabled={!value}>ADD MEDIA</Searchbar.Button>
    </div>
  </Searchbar>
);

export default UrlImportForm;
