import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

import {
  performDpa,
  sendDownloadPageActionEvent,
} from 'redux/modules/download';
import { onClickCloudSave } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import { PropsOf } from 'types';
import CloudUploadSection, {
  CloudUploadSectionProps as Props,
} from './CloudUploadSection';

type DispatchProps = Pick<Props, 'onCloudSave'>;
type StateProps = Pick<Props, 'isMobile'>;

const mapStateToProps = (): StateProps => ({
  isMobile,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onCloudSave: provider => {
    dispatch(performDpa);
    dispatch(onClickCloudSave(provider));
    dispatch(sendDownloadPageActionEvent());
  },
});

const component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CloudUploadSection);
export type CloudUploadSectionProps = PropsOf<typeof component>;
export default component;
