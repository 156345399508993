import Immutable from 'immutable';

import videoExportService from '../../middleware/api/video-export-service';
import constants from './constants';
import * as types from './types';

const { AudioExportStatus } = constants;

const defaultState = Immutable.Map();

function setAudioExportStatus(state, status) {
  return state.setIn(['audioExport', 'status'], status.name);
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.EMBED_EXPORT_CLEAR:
      return defaultState;

    case types.EMBED_EXPORT_SAVE_REQUEST: {
      return state.withMutations(s => s.set('saveInProgress', true));
    }

    case types.EMBED_EXPORT_SAVE_FAILURE: {
      return state.withMutations(s => s.set('saveInProgress', false));
    }

    case types.EMBED_EXPORT_REQUEST: {
      const { widgetId, options } = action.payload;
      const { introVideo, outroVideo, ...rest } = options;
      return defaultState.withMutations(s =>
        s
          .set('widgetId', widgetId)
          .set('saveInProgress', false)
          .set('options', Immutable.Map(rest))
          .set('videoExport', Immutable.Map())
          .set('audioExport', Immutable.Map()),
      );
    }

    case types.EMBED_EXPORT_RECORDING_ID_SET: {
      const { recordingId } = action.payload;
      return state.set('recordingId', recordingId);
    }

    case types.EMBED_VIDEO_EXPORT_STATUS_SET: {
      const { status } = action.payload;
      return state.setIn(['videoExport', 'status'], status);
    }

    case types.EMBED_EXPORT_RENDERED_CONFIG_SET: {
      const { configuration } = action.payload;
      return state.set('renderedConfiguration', configuration);
    }

    case `${videoExportService.Method.GET_VIDEO_CREATE_STATUS}_SUCCESS`: {
      const { response } = action;
      const id = response.result;
      const {
        isResolved,
        status,
        progressInfo,
        videoUrl,
      } = response.entities.embedExports[id];

      // Progress is a number from 0 to 1
      const progress = progressInfo ? progressInfo.progress : 0;
      return state.withMutations(s =>
        s
          .set('videoUrl', videoUrl)
          .setIn(['videoExport', 'isResolved'], isResolved)
          .setIn(['videoExport', 'status'], status)
          .setIn(['videoExport', 'progress'], progress)
          .setIn(['videoExport', 'maxProgress'], 1),
      );
    }

    case types.EMBED_VIDEO_EXPORT_STATUS_CLEAR:
      return state.set('videoExport', Immutable.Map());

    case types.EMBED_EXPORT_SUCCESS: {
      const { widgetId } = action.payload;
      return state.withMutations(s =>
        s
          .setIn(['videoExport', 'isResolved'], true)
          .setIn(
            ['videoExport', 'status'],
            constants.VIDEO_EXPORT_STATE_COMPLETED,
          )
          .set('widgetId', widgetId),
      );
    }

    case types.EMBED_EXPORT_FAILURE:
      return state.withMutations(s =>
        s
          .setIn(['videoExport', 'status'], constants.VIDEO_EXPORT_STATE_ERROR)
          .setIn(['videoExport', 'isResolved'], true),
      );

    case types.EMBED_AUDIO_EXPORT_REQUEST:
      return setAudioExportStatus(state, AudioExportStatus.STARTED);

    case types.EMBED_AUDIO_EXPORT_SUCCESS:
      return setAudioExportStatus(state, AudioExportStatus.SUCCEEDED);

    case types.EMBED_AUDIO_EXPORT_STATUS_SET: {
      const { status } = action.payload;
      return setAudioExportStatus(state, status);
    }

    case types.EMBED_AUDIO_EXPORT_FAILURE:
      return setAudioExportStatus(state, AudioExportStatus.FAILED);

    default:
      return state;
  }
}
