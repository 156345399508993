import cn from 'classnames';
import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Spinner from 'react-spinkit';
import { noop } from 'underscore';

import FontAwesome from 'components/FontAwesome';
import InputToggler, { InputTogglerProps } from 'components/InputToggler';

export interface NavInputProps extends Pick<InputTogglerProps, 'onChange'> {
  /**
   * className to apply to root element when in input mode
   */
  activeClassName?: string;

  /**
   * className that gets applied to the root element
   */
  className?: string;

  /**
   * if set, will display an error message and prevent the user from submitting
   */
  errorMessage?: string;

  /**
   * className to apply to the input element
   */
  inputClassName?: string;

  /**
   * true when save is in progress, false otherwise
   */
  isSaving?: boolean;

  /**
   * max length allowed on input.  user will not be able to enter more than this number of
   * characters
   */
  maxLength?: number;

  /**
   * min length allowed on input. user will not be able to enter fewer than this number of
   * characters
   */
  minLength?: number;

  /**
   * calld when user submits by pressing enter
   */
  onSubmit?: (value?: string) => void;

  /**
   * the value of the input when not in input mode
   */
  value?: string;
}

interface IDataState {
  value: string;
  inputMode: boolean;
  showSpinner: boolean;
}

interface IState {
  data: RecordOf<IDataState>;
}

const dataFactory = Record<IDataState>({
  inputMode: false,
  showSpinner: false,
  value: undefined,
});

export default class NavInput extends React.Component<NavInputProps, IState> {
  public static defaultProps: Readonly<NavInputProps> = {
    isSaving: false,
    minLength: 0,
    onChange: noop,
    onSubmit: noop,
  };

  private input: HTMLInputElement;

  public state: Readonly<IState> = {
    data: dataFactory({
      value: this.props.value,
    }),
  };

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { isSaving: nextIsSaving, value: nextValue } = nextProps;
    const { isSaving } = this.props;

    /*
     * this closes input mode when the value prop changes. occurs after pressing enter - input
     * gets disabled while form saves. The isSaving prop changes to "true" while saving is occuring.
     * This should keep the input mode enabled, but with a spinner indicating the saving progress.
     * When isSaving prop changes to "false", save is complete and the input mode should be
     * disabled indicticating to the user that the save is complete.
     * TODO any cases where we wouldn't want to exit out of input mode when value changes?
     */
    if (isSaving !== nextIsSaving) {
      if (nextIsSaving) {
        this.setState(({ data }) => ({
          data: data.withMutations(d => d.set('showSpinner', true)),
        }));
      } else {
        this.setState(({ data }) => ({
          data: data.withMutations(d =>
            d
              .set('inputMode', false)
              .set('value', nextValue)
              .set('showSpinner', false),
          ),
        }));
      }
    }
  }

  public componentDidUpdate(_, prevState) {
    const { data: prevData } = prevState;
    const { data } = this.state;

    if (!prevData.get('inputMode') && data.get('inputMode') && this.input) {
      this.input.focus();
      this.input.select();
    }
  }

  private handleClickOut: InputTogglerProps['onClickOut'] = (value, e) => {
    const { errorMessage } = this.props;

    if (errorMessage) {
      e.stopPropagation();
      e.preventDefault();
      this.closeInputMode();
      return;
    }

    this.handleSubmit(value, e);
  };

  private handleSubmit = (value: string, e: React.SyntheticEvent<any>) => {
    const { errorMessage, onSubmit } = this.props;

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    // if we already have an error, bail
    if (errorMessage) return;

    onSubmit(value);
  };

  private handleOpenInput = (e: React.SyntheticEvent<any>) => {
    e.preventDefault();
    this.setState(({ data }) => ({
      data: data.set('inputMode', true),
    }));
  };

  private handleCancelInput = () => this.closeInputMode();

  private closeInputMode() {
    this.setState(({ data }) => ({
      data: data.set('inputMode', false),
    }));
  }

  private renderErrorIcon() {
    const { errorMessage } = this.props;

    const tooltip = <Tooltip id="project-name-error">{errorMessage}</Tooltip>;

    return (
      <div className="nav-input__error-icon-wrapper">
        <OverlayTrigger placement="bottom" overlay={tooltip}>
          <FontAwesome
            className="nav-input__error-icon"
            icon="exclamation-triangle"
          />
        </OverlayTrigger>
      </div>
    );
  }

  private renderInput: InputTogglerProps['renderInput'] = (
    value,
    onChange,
    onKeyDown,
  ) => {
    const {
      errorMessage,
      inputClassName,
      isSaving,
      minLength,
      maxLength,
    } = this.props;
    const { data } = this.state;

    const className = cn('nav-input__input', inputClassName);

    const ref = el => {
      this.input = el;
    };

    return (
      <div className="nav-input__form-body">
        <div className="nav-input__wrapper">
          <input
            className={className}
            disabled={isSaving}
            minLength={minLength}
            maxLength={maxLength}
            onChange={onChange}
            onKeyDown={onKeyDown}
            ref={ref}
            type="text"
            value={value}
          />
          {!!errorMessage && this.renderErrorIcon()}
        </div>
        {data.get('showSpinner') && (
          <Spinner
            className="nav-input__spinner spinner--blue"
            spinnerName="double-bounce"
          />
        )}
      </div>
    );
  };

  public render() {
    const {
      activeClassName,
      className,
      errorMessage,
      onChange,
      value,
    } = this.props;
    const { data } = this.state;

    const togglerClassName = cn('nav-input', 'nav-input--default', className, {
      'nav-input--error': !!errorMessage,
    });

    return (
      <InputToggler
        className={togglerClassName}
        activeClassName={activeClassName}
        formClassName="nav-input__form"
        inputMode={data.get('inputMode')}
        onChange={onChange}
        onOpen={this.handleOpenInput}
        onCancel={this.handleCancelInput}
        onClickOut={this.handleClickOut}
        onEnterPressed={this.handleSubmit}
        renderInput={this.renderInput}
        value={value}
      />
    );
  }
}
