import { EXPERIMENTS_NAMES } from 'blocks/ABTest';
import { State } from 'redux/types';
import { experimentsSelector } from '../entities/selectors';

export const experimentVariantSelector = (experimentName: string) => (
  state: State,
): string => {
  const experiments = experimentsSelector(state);
  return experiments?.get(experimentName)?.get('variantName');
};

export const experimentVariantVariablesSelector = (experimentName: string) => (
  state: State,
): undefined => {
  const experiments = experimentsSelector(state);
  return experiments?.getIn([experimentName, 'variantVariables'])?.toJS();
};

/**
 * Temporary selector created to avoid duplicates while checking
 * if the variant A of the Experiment0045 is active. This should be
 * removed once the Experiment0045 is finished.
 */
export const isExperiment0045VariantAActiveSelector = () => (
  state: State,
): boolean => {
  const experimentVariant = experimentVariantSelector(
    EXPERIMENTS_NAMES.eddyProjectCreationSelector,
  )(state);

  return experimentVariant === 'A';
};
