import cn from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import FontAwesome from 'components/FontAwesome';
import LinkButton from 'components/LinkButton';
import ProIndicator from 'containers/ProIndicator';
import { SubTier, Tier } from 'redux/middleware/api/plan-service';
import { onClickFontUpgrade } from 'redux/modules/mixpanel/actions';
import { hideModal, pushModal } from 'redux/modules/modal/actions';
import bem from 'utils/bem';
import { useFontFamilyProIndicatorStore } from './store/FontFamilyProIndicatorContext';

interface FontFamilyProIndicatorProps {
  contentClassName?: string;
}

const block = bem('font-family-pro-indicator');

const FontFamilyProIndicator: React.FunctionComponent<FontFamilyProIndicatorProps> = props => {
  const { contentClassName } = props;

  const dispatch = useDispatch();

  const { hideParentModal } = useFontFamilyProIndicatorStore();

  const handleClick = React.useCallback(() => {
    // hides local modals when embeded on Template Editor text editor.
    hideParentModal();
    // hides global modal for when embeded in a globa modal.
    // this happens at advanced editor for example.
    dispatch(hideModal());
    dispatch(onClickFontUpgrade());
    dispatch(
      pushModal({
        name: 'AddBillingCycle',
        params: {
          tier: Tier.PRO,
          defaultSubscriptionPeriod: 'yearly',
          disableSuccessRedirect: true,
        },
      }),
    );
  }, [dispatch, hideParentModal]);

  return (
    <ProIndicator blacklist={[SubTier.FREE, SubTier.EDUCATION_NONPROFIT]}>
      {({ badge, isFree }) =>
        isFree ? (
          <div className={block()}>
            <div className={contentClassName}>
              {badge}
              <p className={block('content')}>Add custom fonts</p>
              <LinkButton onClick={handleClick} theme="rainbow">
                Upgrade now
              </LinkButton>
            </div>
          </div>
        ) : (
          <div className={block()}>
            <div className={cn(block('content-pro'), contentClassName)}>
              <LinkButton
                componentClass={Link}
                to="/settings/fonts"
                target="_blank"
                size="lg"
                theme="cta"
              >
                <FontAwesome className={block('plus-svg')} icon="plus" />{' '}
                <p>ADD FONTS</p>
              </LinkButton>
            </div>
          </div>
        )
      }
    </ProIndicator>
  );
};

export default FontFamilyProIndicator;
