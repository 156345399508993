import cn from 'classnames';
import * as React from 'react';

import { TabHeaderProps } from 'components/Tabs';
import { Omit } from 'types';
import { tabsBlock as block } from '../../utils';

export interface ProjectsNavHeaderProps extends Omit<TabHeaderProps, 'nTabs'> {
  activeIndex: number;
}

/**
 * Used to override the header element in Tabs to support the sliding tab
 * indicator beneath the tab titles.
 */
const ProjectsNavHeader: React.FC<ProjectsNavHeaderProps> = ({
  activeIndex,
  children,
  className,
}) => (
  <div className={block('header-container')}>
    <ul className={cn(className, block('header'))}>
      {children}
      <hr
        className={block('indicator')}
        style={{ transform: `translateX(${activeIndex * 100}%)` }}
      />
    </ul>
  </div>
);

export default ProjectsNavHeader;
