import { fromJS, List, RecordOf } from 'immutable';

import { PROJECT_DELETE } from 'redux/modules/project/types';
import { RouterActionType } from 'redux/types';
import { RequestStatus } from 'types';
import { omit } from 'utils/collections';
import { getAutomationPath } from 'utils/routes';
import { Type } from './action-types';
import {
  podcastEpisodeDataFactory,
  podcastFeedDataFactory,
  stateFactory,
  templatesFactory,
} from './factories';
import {
  AutomationCMSAction,
  AutomationCMSState,
  PodcastEpisodeData,
  PodcastFeedData,
} from './types';

const defaultState = stateFactory();

export default function reducer(
  state: AutomationCMSState = defaultState,
  action: AutomationCMSAction,
) {
  switch (action.type) {
    case Type.AUTOMATION_CMS_WORKFLOW_LOAD_SUCCESS: {
      const { workflows } = action.payload;
      return state.set('workflows', List(workflows));
    }

    case Type.AUTOMATION_CMS_WORKFLOW_DELETE: {
      const { id } = action.payload;
      return state.withMutations(s => {
        s.update('workflows', ws => {
          const index = ws?.indexOf(id);
          return index >= 0 ? ws.delete(index) : ws;
        });

        // remove the workflow from any of the podcast feeds it's associated with
        s.update('podcastFeedData', feedData =>
          feedData.map(fd =>
            fd.update('workflows', (ws: any) => ws.filter(w => w !== id)),
          ),
        );
        return s;
      });
    }

    case Type.AUTOMATION_CMS_TEMPLATES_LOAD_REQUEST:
      return state.set('isLoadingTemplates', true);

    case Type.AUTOMATION_CMS_TEMPLATES_LOAD_FAILURE:
      return state.set('isLoadingTemplates', false);

    case Type.AUTOMATION_CMS_TEMPLATES_LOAD_SUCCESS: {
      const { aspectRatio, templates } = action.payload;
      return state.withMutations(s => {
        s.setIn(['templates', aspectRatio], List(templates));
        s.set('isLoadingTemplates', false);
        return s;
      });
    }

    case Type.AUTOMATION_CMS_SEASONS_LOAD_REQUEST:
      return state.set('isLoadingSeasons', true);

    case Type.AUTOMATION_CMS_SEASONS_LOAD_FAILURE:
      return state.set('isLoadingSeasons', false);

    case Type.AUTOMATION_CMS_SEASONS_LOAD_SUCCESS: {
      const { seasons, totalEpisodes } = action.payload;
      return state.withMutations(s => {
        s.set('seasons', List(seasons));
        s.set('totalEpisodes', totalEpisodes);
        s.set('isLoadingSeasons', false);
        return s;
      });
    }

    case Type.AUTOMATION_CMS_SEASONS_CLEAR: {
      return state.withMutations(s => {
        s.set('seasons', undefined);
        s.set('totalEpisodes', undefined);
        return s;
      });
    }

    case Type.AUTOMATION_CMS_TEMPLATES_CLEAR:
      return state.set('templates', templatesFactory());

    case Type.AUTOMATION_CMS_PODCAST_FEEDS_GET_REQUEST:
      return state.set('isFetchingPodcastFeeds', true);

    case Type.AUTOMATION_CMS_PODCAST_FEEDS_GET_SUCCESS: {
      const { podcastFeedIds } = action.payload;

      return state.withMutations(s => {
        s.set('isFetchingPodcastFeeds', false);
        s.update('podcastFeedIds', (value = List<string>()) =>
          value.merge(podcastFeedIds.map(id => id.toString())),
        );
        return s;
      });
    }

    case Type.AUTOMATION_CMS_PODCAST_FEEDS_GET_FAILURE:
      return state.set('isFetchingPodcastFeeds', false);

    case Type.AUTOMATION_CMS_PODCAST_FEED_GET_REQUEST: {
      const { podcastFeedId } = action.payload;
      return (state as any).updateIn(
        ['podcastFeedData', podcastFeedId],
        (feedData = podcastFeedDataFactory()) =>
          feedData.set('status', RequestStatus.REQUEST),
      );
    }

    case Type.AUTOMATION_CMS_PODCAST_FEED_GET_SUCCESS: {
      const {
        episodes,
        page,
        podcastFeedId,
        workflows,
        canRefreshFeed,
      } = action.payload;

      const pageData = {
        ...omit(page, 'size'),
        pageSize: page.size,
      };

      return (state as any).updateIn(
        ['podcastFeedData', podcastFeedId],
        (feedData: RecordOf<PodcastFeedData>) =>
          feedData?.withMutations(fd => {
            fd.set('status', RequestStatus.SUCCESS);
            // fd.set('workflows', List(workflows));
            fd.update('episodes', (es: any) => es.concat(episodes));
            fd.merge(pageData);

            if (canRefreshFeed) {
              fd.set('canRefreshFeed', canRefreshFeed);
            }

            if (workflows) {
              fd.set('workflows', List(workflows));
            }
            return fd;
          }),
      );
    }

    case Type.AUTOMATION_CMS_PODCAST_FEED_GET_FAILURE: {
      const { podcastFeedId } = action.payload;
      return (state as any).updateIn(
        ['podcastFeedData', podcastFeedId],
        (feedData: RecordOf<PodcastFeedData>) =>
          feedData?.set('status', RequestStatus.FAILURE),
      );
    }

    case Type.AUTOMATION_CMS_EPISODE_PROJECTS_GET_REQUEST: {
      const { episodeId } = action.payload;
      return (state as any).updateIn(
        ['podcastEpisodeData', episodeId],
        (episodeData = podcastEpisodeDataFactory()) =>
          episodeData.set('isFetching', true),
      );
    }

    case Type.AUTOMATION_CMS_EPISODE_PROJECTS_GET_SUCCESS: {
      const { episodeId, projects } = action.payload;
      return (state as any).updateIn(
        ['podcastEpisodeData', episodeId],
        (episodeData: RecordOf<PodcastEpisodeData>) =>
          episodeData?.withMutations(ed => {
            ed.set('isFetching', false);
            ed.update('projects', (ps: any = List()) => ps.concat(projects));
            return ed;
          }),
      );
    }

    case Type.AUTOMATION_CMS_EPISODE_PROJECTS_GET_FAILURE: {
      const { episodeId } = action.payload;
      return (state as any).updateIn(
        ['podcastEpisodeData', episodeId],
        (episodeData: RecordOf<PodcastEpisodeData>) =>
          episodeData?.set('isFetching', false),
      );
    }

    case PROJECT_DELETE: {
      const { id: projectId } = action.payload;
      return state.update('podcastEpisodeData', episodeData =>
        episodeData.map(ed =>
          ed.update('projects', (ps: any) => ps.filter(p => p !== projectId)),
        ),
      );
    }

    case Type.AUTOMATION_CMS_EPISODE_VIDEO_CREATE_REQUEST: {
      const { episodeId } = action.payload;
      return state.update('manualEpisodeTriggerStatuses', es =>
        es.set(episodeId, RequestStatus.REQUEST),
      );
    }

    case Type.AUTOMATION_CMS_EPISODE_VIDEO_CREATE_SUCCESS: {
      const { episodeId } = action.payload;
      return state.update('manualEpisodeTriggerStatuses', es =>
        es.set(episodeId, RequestStatus.SUCCESS),
      );
    }

    case Type.AUTOMATION_CMS_EPISODE_VIDEO_CREATE_FAILURE: {
      const { episodeId } = action.payload;
      return state.update('manualEpisodeTriggerStatuses', es =>
        es.set(episodeId, RequestStatus.FAILURE),
      );
    }

    case Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_CREATE_REQUEST: {
      const { episodeId } = action.payload;
      return state.set('createTranscriptionEpisodeId', episodeId);
    }

    case Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_CREATE_FAILURE: {
      return state.set('createTranscriptionEpisodeId', undefined);
    }

    case Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_CREATE_SUCCESS: {
      return state.withMutations(s => {
        const id = s.createTranscriptionEpisodeId;
        s.update('pollTranscriptionEpisodeIds', ids => ids.add(id));
        s.set('createTranscriptionEpisodeId', undefined);
        return s;
      });
    }

    case Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_REQUEST: {
      const { episodeId } = action.payload;
      return state.update('pollTranscriptionEpisodeIds', ids =>
        ids.add(episodeId),
      );
    }

    case Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_ABORT:
    case Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_SUCCESS: {
      const { episodeId } = action.payload;
      return state.update('pollTranscriptionEpisodeIds', ids =>
        ids.remove(episodeId),
      );
    }

    case Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_POLLING_BEGIN: {
      return state.set('isPollingEpisodeTranscriptions', true);
    }

    case Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_POLLING_END: {
      return state.set('isPollingEpisodeTranscriptions', false);
    }

    case Type.PODCAST_SELECT: {
      const { podcastId } = action.payload;
      return (state as any).updateIn(
        ['podcastFeedData', podcastId],
        (feedData: RecordOf<PodcastFeedData>) =>
          feedData?.set('status', undefined),
      );
    }

    case RouterActionType.LOCATION_CHANGE: {
      const { pathname } = action.payload;

      if (pathname === getAutomationPath()) {
        return state.update('podcastFeedData', feed =>
          feed.map(fd => fd.set('status', undefined)),
        );
      }

      return state;
    }

    case Type.CLEAR_PODCAST_FEED:
      return state.set('podcastFeedData', defaultState.podcastFeedData);

    case Type.AUTOMATION_CMS_RESTORE_REVOKED_PERMISSION_REQUEST:
      return state.set('isUpdatingRevokedPermissions', true);

    case Type.AUTOMATION_CMS_RESTORE_REVOKED_PERMISSION_FAILURE:
    case Type.AUTOMATION_CMS_RESTORE_REVOKED_PERMISSION_SUCCESS:
      return state.set('isUpdatingRevokedPermissions', false);

    case Type.AUTOMATION_CMS_WORKFLOW_PREVIEW_REQUEST:
      return state.withMutations(s => {
        s.set('isPollingSubscriptionPreview', true);
        s.set(
          'subscriptionPreviewPollingId',
          action.payload.subscriptionPreviewJobId,
        );
        s.set('subscriptionPreviewData', undefined);
        return s;
      });

    case Type.AUTOMATION_CMS_WORKFLOW_PREVIEW_FAILURE:
      return state.withMutations(s => {
        s.set('isPollingSubscriptionPreview', false);
        s.set('subscriptionPreviewPollingId', undefined);
        return s;
      });

    case Type.AUTOMATION_CMS_WORKFLOW_PREVIEW_SUCCESS:
      return state.withMutations(s => {
        s.set('isPollingSubscriptionPreview', false);
        s.set('subscriptionPreviewPollingId', undefined);
        s.set(
          'subscriptionPreviewData',
          fromJS(action.payload.subscriptionPreviewData),
        );
        return s;
      });

    default:
      return state;
  }
}
