import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import { MultiToggleField, MultiToggleFieldProps } from 'components/Form';
import TextBoxField from './TextBoxField';

type Props = Pick<MultiToggleFieldProps, 'value' | 'onChange'>;

const FontStyleField: React.SFC<Props> = props => (
  <TextBoxField>
    <MultiToggleField
      {...props}
      label="Font Style"
      horizontal={{
        control: { xs: 5 },
        label: { xs: 7 },
      }}
      options={[
        {
          icon: (
            <span>
              <FontAwesome icon="bold" />
            </span>
          ),
          id: 'bold',
        },
        {
          icon: (
            <span>
              <FontAwesome icon="italic" />
            </span>
          ),
          id: 'italic',
        },
        {
          icon: (
            <span>
              <FontAwesome icon="underline" />
            </span>
          ),
          id: 'underline',
        },
      ]}
    />
  </TextBoxField>
);

export default FontStyleField;
export { Props as FontStyleFieldProps };
