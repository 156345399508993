import React, { useCallback, useMemo } from 'react';
import { Col } from 'react-bootstrap';

import BlockImage from 'components/BlockImage';
import FadeTransition from 'components/FadeTransition';
import { EmojiSad } from 'components/icons';
import NonIdealState from 'components/NonIdealState';

import ProvidersList, { ProvidersListProps } from '../ProvidersList';
import SearchFooter from '../SearchFooter';
import SearchResults, {
  IFooterProps,
  OnLoadMore,
  SearchResultsProps,
} from '../SearchResults';

import { EngineRegistry } from '../types';
import { GIFData, GIFSearchResults } from './types';

export interface GIFsSearchResultsProps
  extends Pick<SearchResultsProps, 'onEngineSelect'>,
    Pick<ProvidersListProps, 'navContainerClassName'> {
  selectedEngine?: string;
  results: GIFSearchResults;
  onLoadMoreResults?: OnLoadMore;
  onGifClick?: (url: string, poster: string, engine: string) => void;
}
const GIFsSearchResults: React.FC<GIFsSearchResultsProps> = ({
  results,
  onLoadMoreResults,
  selectedEngine = 'giphy',
  navContainerClassName,
  onGifClick,
  onEngineSelect,
}) => {
  const handleGifClick = useCallback(
    (result: GIFData, engine: string) => () => {
      const { poster, video, gif } = result;

      const url = engine === 'giphySticker' ? gif.url : video.url;

      onGifClick(url, poster, engine);
    },
    [onGifClick],
  );

  const engines = useMemo((): EngineRegistry => {
    return Object.keys(results).reduce((acc, engine) => {
      const engineData = results[engine];

      acc[engine] = {
        displayName: engineData.displayName,
        errorMessage: engineData.error,
        hasMoreResults:
          engineData.data && engineData.data.length < engineData.totalResults,
        hasResults: !!engineData.data,
        isSearching: engineData.isSearching,
        notSupportedMessage:
          engineData.isSupported === false ? (
            <NonIdealState
              icon={<EmojiSad />}
              title="This browser doesn’t support stickers"
            >
              <p>
                You can access them in a browser like{' '}
                <a
                  href="https://www.google.com/chrome/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Chrome
                </a>{' '}
                or{' '}
                <a
                  href="https://www.mozilla.org/en-US/firefox/new/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Firefox
                </a>{' '}
                instead!
              </p>
            </NonIdealState>
          ) : null,
        poweredBy:
          engine === 'giphy' || engine === 'giphySticker' ? (
            <FadeTransition key="giphy">
              <img
                src="https://static.sparemin.com/static/webapp-assets/powered-by-giphy.png"
                alt="Giphy"
              />
            </FadeTransition>
          ) : (
            <FadeTransition />
          ),
      };

      return acc;
    }, {});
  }, [results]);

  const handleRenderProvidersList = useCallback(() => {
    return (
      <ProvidersList
        engines={engines}
        navContainerClassName={navContainerClassName}
        noProvidersMessage="Sorry, but there are no gif providers for this account."
      />
    );
  }, [engines, navContainerClassName]);

  const handleRenderFooter = useCallback(
    (footerProps: IFooterProps) => {
      const engineData = results[selectedEngine];

      if (!engineData?.isSupported) {
        return null;
      }

      return (
        <SearchFooter
          className={footerProps.className}
          engineList={Object.keys(results)}
          selectedEngine={selectedEngine}
          searchType="gif-video"
        />
      );
    },
    [results, selectedEngine],
  );

  const handleRenderResults = useCallback(
    (engine: string) => {
      return results[engine].data.map(result => {
        const { preview, gif, aspectRatio, tooltip } = result;
        const url = preview ? preview.url : gif.url;
        const style = preview
          ? { width: preview.width, height: preview.height }
          : aspectRatio;

        return (
          <Col
            className="media-search__image-result"
            xs={6}
            sm={4}
            lg={3}
            onClick={handleGifClick(result, engine)}
            key={url}
          >
            <BlockImage
              height={style.height}
              width={style.width}
              src={url}
              noDimension
              translucent={engine === 'giphySticker'}
              tooltip={tooltip}
            />
          </Col>
        );
      });
    },
    [handleGifClick, results],
  );

  return (
    <SearchResults
      id="media-search__image-results"
      active={selectedEngine}
      className="media-search__image-results"
      engines={engines}
      itemSelector=".media-search__image-result"
      onEngineSelect={onEngineSelect}
      onLoadMore={onLoadMoreResults}
      providersList={handleRenderProvidersList()}
      renderFooter={handleRenderFooter}
      renderResults={handleRenderResults}
    />
  );
};

export default GIFsSearchResults;
