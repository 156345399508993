import Immutable from 'immutable';
import { createSelector } from 'reselect';
import { DEFAULT_PROJECT_NAME } from 'utils/constants';
import { getFileNameWithtoutExtension } from 'utils/file';

import {
  podcastDetailEpisodeSelector,
  projectsSelector,
} from '../entities/selectors';

export const projectHistoryIdsSelector = state =>
  state.getIn(['project', 'history', 'data']);
export const projectHistoryPageSelector = state =>
  state.getIn(['project', 'history', 'page']);
export const projectIdSelector = state => state.getIn(['project', 'id']);
export const isLoadingHistorySelector = state =>
  state.getIn(['project', 'history', 'isFetching']);
export const projectUpdateSelector = state =>
  state.getIn(['project', 'update']);
export const newProjectSettingsSelector = state =>
  state.getIn(['project', 'newProject']);
export const projectDeleteStatusSelector = state =>
  state.getIn(['project', 'deleteProjectStatus']);
export const projectCopyStatusSelector = state =>
  state.getIn(['project', 'copyStatus']);
export const projectsCountSelector = state =>
  state.getIn(['project', 'projectsCount']);
export const redirectIdSelector = state =>
  state.getIn(['project', 'redirectId']);
export const isProjectsTabActiveSelector = state =>
  state.getIn(['project', 'isProjectsTabActive']);
export const projectsPageFetchCountSelector = state =>
  state.getIn(['project', 'projectsPageFetchCount']);

export const projectHistoryHasMoreSelector = createSelector(
  projectHistoryPageSelector,
  page => page && page.get('number') < page.get('totalPages'),
);

export const projectSelector = createSelector(
  [projectsSelector, projectIdSelector],
  (projects, id) => id && projects && projects.get(id),
);

export const projectHistorySelector = createSelector(
  [projectHistoryIdsSelector, projectsSelector],
  (projectHistoryIds, projects) => {
    if (!projectHistoryIds) {
      return undefined;
    }

    const data = projectHistoryIds.reduce(
      (res, id) =>
        res.set(
          id,
          Immutable.Map({
            id: projects.getIn([id, 'projectUuid']),
            name: projects.getIn([id, 'projectConfig', 'projectName']),
            wid: projects.getIn([
              id,
              'projectConfig',
              'embedConfigurationUuid',
            ]),
            updatedAt: projects.getIn([id, 'projectConfig', 'updatedAt']),
            aspectRatio: projects.getIn([id, 'projectConfig', 'aspectRatio']),
            createMethod: projects.getIn([id, 'createMethod']),
            latestSuccessfulVideoWid: projects.getIn([
              id,
              'latestSuccessfulVideo',
              'widgetUuid',
            ]),
          }),
        ),
      Immutable.Map(),
    );

    return Immutable.Map({
      data,
      order: projectHistoryIds,
    });
  },
);

export const projectNameSelector = createSelector(
  projectSelector,
  project => project && project.getIn(['projectConfig', 'projectName']),
);

export const projectNameUpdateInProgressSelector = createSelector(
  [projectSelector, projectUpdateSelector],
  (project, projectUpdate) => {
    if (!project || !projectUpdate) return false;
    if (project.get('projectUuid') !== projectUpdate.get('id')) return false;

    return projectUpdate.get('inProgress');
  },
);

export const newProjectAspectRatioSelector = createSelector(
  newProjectSettingsSelector,
  settings =>
    settings && {
      height: settings.getIn(['aspectRatio', 'height']),
      width: settings.getIn(['aspectRatio', 'width']),
    },
);

export const projectAspectRatioDimensionsSelector = createSelector(
  projectSelector,
  project => project && project.getIn(['projectConfig', 'aspectRatio']),
);

export const projectAspectRatioSelector = createSelector(
  projectAspectRatioDimensionsSelector,
  dimensions =>
    dimensions && dimensions.get('width') / dimensions.get('height'),
);

export const projectEmbedConfigurationIdSelector = createSelector(
  projectSelector,
  project =>
    project && project.getIn(['projectConfig', 'embedConfigurationUuid']),
);

export const createDefaultProjectNameSelector = (
  options: {
    podcastId?: string;
    episodeId?: string;
    source?: string | File;
  },
  defaultValue?: string,
) => {
  const { episodeId, source } = options;
  return createSelector(podcastDetailEpisodeSelector(episodeId), episode => {
    const fileName = getFileNameWithtoutExtension(source);
    return fileName || episode?.title || defaultValue || DEFAULT_PROJECT_NAME;
  });
};
