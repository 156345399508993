import * as React from 'react';
import { noop } from 'underscore';

import { ArgsOf, Omit } from 'types';
import TrackAsset, {
  ResizeCallback,
  ResizingHandle,
  TrackAssetProps,
  TrackAssetResizeStopCallback,
} from './TrackAsset';

export type ClippableResizeCallback = (
  playFromOffset: number,
  ...args: ArgsOf<ResizeCallback>
) => void;

export type ClippableResizeStopCallback = (
  playFromOffset: number,
  resized: boolean,
  ...args: ArgsOf<ResizeCallback>
) => void;

export interface ClippableTrackAssetProps
  extends Omit<
      TrackAssetProps,
      'sizeBounds' | 'onResize' | 'onResizeStart' | 'onResizeStop'
    > {
  fullDurationWidth: number;
  loopable?: boolean;
  onResize?: ClippableResizeCallback;
  onResizeStart?: ClippableResizeCallback;
  onResizeStop?: ClippableResizeStopCallback;
  playFromOffset: number;
  minWidth: number;
}

interface State {
  maxWidth: number;
}

export default class ClippableTrackAsset extends React.Component<
  ClippableTrackAssetProps,
  State
> {
  public static defaultProps: Partial<ClippableTrackAssetProps> = {
    loopable: false,
    onResize: noop,
    onResizeStart: noop,
    onResizeStop: noop,
    position: { x: 0, y: 0 },
  };

  public state: Readonly<State> = {
    maxWidth: undefined,
  };

  private handleResizeStart: ResizeCallback = (width, handle, pos) => {
    const { onResizeStart, playFromOffset } = this.props;
    this.setState({ maxWidth: this.calculateMaxWidth(handle) });
    onResizeStart(playFromOffset, width, handle, pos);
  };

  private handleResizeStop: TrackAssetResizeStopCallback = (
    resized,
    width,
    handle,
    pos,
  ) => {
    const { onResizeStop, playFromOffset } = this.props;
    this.setState({ maxWidth: this.calculateMaxWidth(handle) });
    onResizeStop(playFromOffset, resized, width, handle, pos);
  };

  private handleResize: ResizeCallback = (width, handle, pos) => {
    const {
      fullDurationWidth,
      loopable,
      onResize,
      position,
      playFromOffset,
    } = this.props;

    const positionDelta = position.x - pos;
    const newPlayFromOffset =
      handle === 'left' ? playFromOffset - positionDelta : playFromOffset;

    /*
     * NB: newPlayOffset % (newPlayOffset + 1) === newPlayOffset
     * just hack so we can unconditionally calculate the mod below
     */
    const divisor = loopable ? fullDurationWidth : newPlayFromOffset + 1;

    onResize(newPlayFromOffset % divisor, width, handle, pos);
  };

  private calculateMaxLoops() {
    const { fullDurationWidth, playFromOffset, loopable, width } = this.props;
    return !loopable ? 1 : (width + playFromOffset) / fullDurationWidth;
  }

  private calculateMaxWidth(handle: ResizingHandle) {
    const {
      fullDurationWidth,
      loopable,
      playFromOffset,
      position,
      width,
    } = this.props;

    const startPosition = position.x;
    const endPosition = startPosition + width;

    const playDurationWidth = endPosition - startPosition;

    if (loopable) {
      const val =
        handle === 'left'
          ? fullDurationWidth * this.calculateMaxLoops()
          : Infinity;
      return val;
    }

    const leftTrimOffset = playFromOffset;
    const rightTrimOffset =
      fullDurationWidth - (playFromOffset + playDurationWidth);
    const trimOffset = handle === 'left' ? leftTrimOffset : rightTrimOffset;

    return playDurationWidth + trimOffset;
  }

  public render() {
    const {
      fullDurationWidth,
      minWidth,
      playFromOffset,
      ...trackAssetProps
    } = this.props;
    const { maxWidth } = this.state;

    return (
      <TrackAsset
        {...trackAssetProps}
        onResize={this.handleResize}
        onResizeStart={this.handleResizeStart}
        onResizeStop={this.handleResizeStop}
        sizeBounds={{
          maxWidth,
          minWidth,
        }}
      />
    );
  }
}
