/* An error class that can be extended
 *
 *  https://stackoverflow.com/questions/31089801/extending-error-in-javascript-with-es6-syntax
 */
export default class ExtendableError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;

    if (typeof Error.captureStackTrace === 'function') {
      /*
       * NB: not mentioned in the SO link above, but I remember reading that this operation is
       *     pretty expensive.  might want to omit the stack trace on custom errors if it becomes
       *     a problem
       */
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}
