import React, { useCallback } from 'react';
import RadioOptions from 'components/RadioOptions';
import { SHOULD_GENERATE_ASSETS_KEY } from 'containers/ProjectWizards/constants';
import { block } from '../utils';

export interface EddyProjectCreationOptionsListProps {
  shouldGenerateAssets: boolean;
  onShouldGenerateAssetsChange: (shouldGenerateAssets: boolean) => void;
}

const EddyProjectCreationOptionsList: React.FC<EddyProjectCreationOptionsListProps> = ({
  shouldGenerateAssets,
  onShouldGenerateAssetsChange,
}) => {
  const handleSelectionChange = useCallback(
    (generateAssets: boolean): void => {
      onShouldGenerateAssetsChange(generateAssets);

      localStorage.setItem(
        SHOULD_GENERATE_ASSETS_KEY,
        generateAssets.toString(),
      );
    },
    [onShouldGenerateAssetsChange],
  );

  return (
    <RadioOptions
      listClassName={block('creation-options-list')}
      optionClassName={block('creation-option')}
      options={[
        {
          label: 'Yes please',
          value: true,
        },
        {
          label: 'No thanks',
          value: false,
        },
      ]}
      value={shouldGenerateAssets}
      onChange={handleSelectionChange}
    />
  );
};

export default EddyProjectCreationOptionsList;
