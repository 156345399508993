import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import UpdateCreditCardModalContents from './UpdateCreditCardModalContents';

const UpdateCreditCardModal: React.FC = () => (
  <ConnectedModal name="UpdateCreditCard" title="update credit card">
    <UpdateCreditCardModalContents />
  </ConnectedModal>
);

export default UpdateCreditCardModal;
