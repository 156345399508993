import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import AccessControl from '../AccessControl';
import PopperTile from '../Popper/PopperTile';
import WaveformFidelityPopper, {
  WaveformFidelityPopperProps,
} from './WaveformFidelityPopper';

type PickedPopperProps = Pick<
  WaveformFidelityPopperProps,
  'onChange' | 'value'
>;

export interface WaveformFidelityTileProps extends PickedPopperProps {}

const WaveformFidelityTile: React.FC<WaveformFidelityTileProps> = ({
  onChange,
  value,
}) => (
  <AccessControl featurePath={['waveform', 'fidelity']}>
    {({ state, reason }) => (
      <PopperTile
        disabled={state === 'disabled'}
        icon={<FontAwesome icon="cog" style={{ height: 'auto', width: 20 }} />}
        id="waveform-fidelity"
        tooltip={reason}
      >
        <WaveformFidelityPopper {...{ onChange, value }} />
      </PopperTile>
    )}
  </AccessControl>
);

export default WaveformFidelityTile;
