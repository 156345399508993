export enum Type {
  LINKEDIN_USER_DATA_GET_AWAIT_REQUEST = 'app/social/linkedin/USER_DATA_GET_AWAIT_REQUEST',
  LINKEDIN_USER_DATA_GET_AWAIT_SUCCESS = 'app/social/linkedin/USER_DATA_GET_AWAIT_SUCCESS',
  LINKEDIN_USER_DATA_GET_AWAIT_FAILURE = 'app/social/linkedin/USER_DATA_GET_AWAIT_FAILURE',

  LINKEDIN_USER_DATA_GET_REQUEST = 'app/social/linkedin/USER_DATA_GET_REQUEST',
  LINKEDIN_USER_DATA_GET_SUCCESS = 'app/social/linkedin/USER_DATA_GET_SUCCESS',
  LINKEDIN_USER_DATA_GET_FAILURE = 'app/social/linkedin/USER_DATA_GET_FAILURE',

  LINKEDIN_ORGANIZATIONS_GET_REQUEST = 'app/social/linkedin/ORGANIZATIONS_GET_REQUEST',
  LINKEDIN_ORGANIZATIONS_GET_SUCCESS = 'app/social/linkedin/ORGANIZATIONS_GET_SUCCESS',
  LINKEDIN_ORGANIZATIONS_GET_FAILURE = 'app/social/linkedin/ORGANIZATIONS_GET_FAILURE',

  LINKEDIN_USER_DATA_CLEAR = 'app/social/linkedin/USER_DATA_CLEAR',

  LINKEDIN_VIDEO_POST_REQUEST = 'app/social/linkedin/VIDEO_POST_REQUEST',
  LINKEDIN_VIDEO_POST_SUCCESS = 'app/social/linkedin/VIDEO_POST_SUCCESS',
  LINKEDIN_VIDEO_POST_FAILURE = 'app/social/linkedin/VIDEO_POST_FAILURE',

  LINKEDIN_VIDEO_POST_AWAIT_REQUEST = 'app/social/linkedin/VIDEO_POST_AWAIT_REQUEST',
  LINKEDIN_VIDEO_POST_AWAIT_SUCCESS = 'app/social/linkedin/VIDEO_POST_AWAIT_SUCCESS',
  LINKEDIN_VIDEO_POST_AWAIT_FAILURE = 'app/social/linkedin/VIDEO_POST_AWAIT_FAILURE',

  LINKEDIN_AUTHORIZE_SUCCESS = 'app/social/linkedin/AUTHORIZE_SUCCESS',
}
