import * as React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import CancellationReasonModalContent from './CancellationReasonModalContent';
import { block } from './utils';

const CancellationReasonModal = () => {
  return (
    <ConnectedModal
      name="CancelReason"
      title="Cancel my plan"
      className={block()}
    >
      <CancellationReasonModalContent />
    </ConnectedModal>
  );
};

export default CancellationReasonModal;
