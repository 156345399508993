export enum Type {
  ONBOADING_GET_REQUEST = 'app/onboarding/ONBOADING_GET_REQUEST',
  ONBOADING_GET_SUCCESS = 'app/onboarding/ONBOADING_GET_SUCCESS',
  ONBOADING_GET_FAILURE = 'app/onboarding/ONBOADING_GET_FAILURE',
  ONBOADING_GET_VIEW_CONFIRMATION_REQUEST = 'app/onboarding/ONBOADING_GET_VIEW_CONFIRMATION_REQUEST',
  ONBOADING_GET_VIEW_CONFIRMATION_SUCCESS = 'app/onboarding/ONBOADING_GET_VIEW_CONFIRMATION_SUCCESS',
  ONBOADING_GET_VIEW_CONFIRMATION_FAILURE = 'app/onboarding/ONBOADING_GET_VIEW_CONFIRMATION_FAILURE',
  ONBOADING_CLEAR = 'app/onboarding/ONBOADING_CLEAR',

  ONBOARDING_WIZARD_COMPLETE_REQUEST = 'app/onboarding/ONBOARDING_WIZARD_COMPLETE_REQUEST',
  ONBOARDING_WIZARD_COMPLETE_SUCCESS = 'app/onboarding/ONBOARDING_WIZARD_COMPLETE_SUCCESS',
  ONBOARDING_WIZARD_COMPLETE_FAILURE = 'app/onboarding/ONBOARDING_WIZARD_COMPLETE_FAILURE',
}
