import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTextPresets,
  textPresetIdsSelector,
} from 'redux/modules/text-presets';

interface UseTextPresets {
  presetIds: [];
}

const useTextPresets = (): UseTextPresets => {
  const dispatch = useDispatch();
  const presetIds = useSelector(textPresetIdsSelector);

  React.useEffect(() => {
    dispatch(getTextPresets());
  }, [dispatch]);

  return {
    presetIds,
  };
};

export default useTextPresets;
