import React from 'react';
import { identity } from 'underscore';

import { Upload } from 'components/icons';
import { MediaType } from 'containers/AddMediaModal';
import { ApplicationError } from 'utils/ApplicationError';
import { smBlue } from 'utils/colors';
import { isSupportedImageFile, isSupportedVideoFile } from 'utils/formats';
import { isAnimatedGif } from 'utils/image';

import MediaModalSelector, {
  MediaModalSelectorInstance,
} from '../MediaModalSelector';
import { replaceMainImage } from '../state';
import { ImageSource, MediaIntegrationId, VideoSource } from '../types';
import {
  IntegrationProps,
  MediaSourceHandlers,
  OnAddIntegrationMedia,
  OnDeleteMediaIntegration,
  ReplaceMainMedia,
  UploadMediaIntegration,
} from '../types/integrations';

const { useCallback, useRef } = React;

const INTEGRATION_ID = MediaIntegrationId.UPLOAD;

const COMPATIBLE_INTEGRATION_IDS = [
  MediaIntegrationId.UPLOAD,
  MediaIntegrationId.GIF,
  MediaIntegrationId.VIDEOCLIP,
];

const MediaTypeIntegrationMap: Record<
  MediaType,
  | MediaIntegrationId.GIF
  | MediaIntegrationId.VIDEOCLIP
  | MediaIntegrationId.UPLOAD
  | MediaIntegrationId.AI_ASSET
> = {
  'gif-video': MediaIntegrationId.GIF,
  'ai-asset': MediaIntegrationId.AI_ASSET,
  image: MediaIntegrationId.UPLOAD,
  video: MediaIntegrationId.VIDEOCLIP,
};

export default function useMediaUploadIntegration({
  priority,
}: IntegrationProps): UploadMediaIntegration {
  const ref = useRef<MediaModalSelectorInstance>();
  const handlersRef = useRef<MediaSourceHandlers | undefined>();

  // handles File upload submission
  const handleFileAccepted = useCallback(
    async (source: ImageSource | VideoSource) => {
      // if source type is a valid video, accept handler is called with a media integration
      // for videoclip
      if (isSupportedVideoFile(source)) {
        handlersRef.current.accept(source, {
          id: MediaIntegrationId.VIDEOCLIP,
        });
        handlersRef.current = undefined;
        // if video check fails, source is check in order to find if it is a gif, if this happens
        // accept callback is invoked with gif media integration
      } else if (await isAnimatedGif(source)) {
        handlersRef.current.accept(source, { id: MediaIntegrationId.GIF });
        handlersRef.current = undefined;
        // when the other checks fail, source is checked to find out if it is a non valid image
        // file. if that happends, rejected handler is called.
      } else if (!(await isSupportedImageFile(source))) {
        handlersRef.current.reject(
          source,
          new ApplicationError('File type not supported', 'IN001'),
        );
        handlersRef.current = undefined;
        // otherwise accepted handler is called with the integration type for images.
      } else {
        handlersRef.current.accept(source, { id: MediaIntegrationId.UPLOAD });
        handlersRef.current = undefined;
      }
    },
    [],
  );

  // handles media URL like submissions (url gifs, video, images or canvas)
  const handleUrlAccepted = useCallback(
    async (source: string, type: MediaType) => {
      handlersRef.current?.accept(source, {
        id: MediaTypeIntegrationMap[type],
      });
      handlersRef.current = undefined;
    },
    [],
  );

  const handleDeleteMedia: OnDeleteMediaIntegration = useCallback(
    (modalManager, deleteMedia) => {
      modalManager.showModal('delete', {
        assetName: 'this media',
        onDelete: deleteMedia,
        title: 'delete media',
      });
    },
    [],
  );

  const handleAddMedia: OnAddIntegrationMedia = useCallback(mediaHandlers => {
    handlersRef.current = mediaHandlers;
    ref.current?.open();
  }, []);

  const handleReplaceMedia = useCallback((_, addImagePrompt) => {
    addImagePrompt();
  }, []);

  const handleReplaceMainMedia: ReplaceMainMedia = useCallback((state, src) => {
    return replaceMainImage(state, src, {
      integrationData: { id: MediaIntegrationId.UPLOAD },
    });
  }, []);

  return {
    priority,
    creationType: 'static',
    id: INTEGRATION_ID,
    compatibleIntegrationIds: COMPATIBLE_INTEGRATION_IDS,
    mediaTypeCardProps: {
      icon: <Upload style={{ height: 38 }} />,
      info: (
        <>
          <strong>Manually</strong> add media to include it in every video
        </>
      ),
    },
    onAddMedia: handleAddMedia,
    onDeleteMedia: handleDeleteMedia,
    onReplaceMedia: handleReplaceMedia,
    postProcessor: identity,
    replaceMainMedia: handleReplaceMainMedia,
    staticSelectionModalProps: {
      icon: <Upload width={40} height="100%" color={smBlue} />,
      label: 'upload',
    },
    type: 'media-source',
    ui: (
      <MediaModalSelector
        onFileAccepted={handleFileAccepted}
        onUrlAccepted={handleUrlAccepted}
        ref={ref}
        key={1}
      />
    ),
  };
}
