import { Map } from 'immutable';

import { VideoTemplateEditorFeatures } from 'components/VideoTemplateEditor';
import { createMap, DeepImmutableMap, Soundwave } from 'types';
import bem from 'utils/bem';

import { defaultSoundwaveState } from 'utils/embed/soundwave';

export const addPodcastStepBlock = bem('add-podcast-step');

export const DEFAULT_SOUNDWAVE_STATE: Readonly<Soundwave> = {
  ...defaultSoundwaveState.toJS(),
  waveGeneration: 'amplitudeBased',
  waveType: 'roundBars',
};

export const EPISODE_TEMPLATE_EDITOR_FEATURES: VideoTemplateEditorFeatures = {
  progress: 'hidden',
  waveform: {
    fidelity: {
      disabled: true,
      reason: 'Full episodes use lo-fi wave generation',
    },
  },
};

function isMap<T>(obj: T | DeepImmutableMap<T>): obj is DeepImmutableMap<T> {
  return Map.isMap(obj);
}

export function createDefaultSoundwave(
  waveform:
    | DeepImmutableMap<Partial<Soundwave>>
    | Partial<Soundwave> = createMap({}),
) {
  return {
    ...DEFAULT_SOUNDWAVE_STATE,
    ...(isMap(waveform) ? waveform.toJS() : waveform),
  };
}
