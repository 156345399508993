import * as React from 'react';

import InfoboxOverlay from 'components/InfoboxOverlay';
import Modal from 'components/Modal';
import VideoProgressPreview from 'components/VideoProgressPreview';
import { EditorVideoFramePreview } from 'containers/VideoFramePreview';
import { ProgressAndTimerOptions, Size } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { Measurement } from 'utils/measurement';
import { block } from '../utils';
import DragableProgress from './DragableProgress';
import DraggableTimer from './DraggableTimer';
import ProgressAnimationModalCol from './ProgressAnimationModalCol';
import ProgressAnimationOptions, {
  ProgressAnimationOptionsProps,
} from './ProgressAnimationOptions';
import ProgressAnimationWorkspace, {
  ProgressAnimationWorkspaceProps,
} from './ProgressAnimationWorkspace';

const { useCallback, useState } = React;

export interface ProgressAnimationModalBodyProps
  extends ProgressAnimationWorkspaceProps,
    Omit<ProgressAnimationOptionsProps, 'workspaceSize'> {
  background?: React.ReactNode;
  hideTimer?: boolean;
  value: ProgressAndTimerOptions<Measurement>;
  onChange: (value: ProgressAndTimerOptions<Measurement>) => void;
}

const ProgressAnimationModalBody: React.SFC<ProgressAnimationModalBodyProps> = ({
  aspectRatio,
  hideTimer,
  onChange,
  value,
}) => {
  const [workspaceSize, setWorkspaceSize] = useState<Size<number>>();

  const [showOverlay, setShowOverlay] = useState(true);

  const overlayShow = useCallback(() => setShowOverlay(true), []);
  const overlayHide = useCallback(() => setShowOverlay(false), []);

  return (
    <Modal.Body>
      <Modal.Row>
        <ProgressAnimationModalCol
          className={block('workspace-column', {
            [getAspectRatioName(aspectRatio)]: true,
          })}
        >
          <ProgressAnimationWorkspace
            aspectRatio={aspectRatio}
            onSizeChange={setWorkspaceSize}
          >
            <EditorVideoFramePreview
              aspectRatio={aspectRatio}
              canvasDimensions={workspaceSize}
              className={block('progress-preview-container')}
              backgroundFor={{
                type: 'progress',
              }}
            />
            {showOverlay && <InfoboxOverlay text="Drag edges to resize" />}
            {value.progress.enabled && (
              <DragableProgress
                {...{ aspectRatio }}
                value={value.progress}
                onChange={progress => {
                  onChange({ ...value, progress });
                }}
                onDragStart={overlayHide}
                onDragStop={overlayShow}
                onResizeStart={overlayHide}
                onResizeStop={overlayShow}
                workspaceSize={
                  workspaceSize || {
                    height: 0,
                    width: 0,
                  }
                }
              >
                <VideoProgressPreview
                  color={value.progress.color}
                  fillColor={value.progress.fillColor}
                  type={value.progress.type}
                />
              </DragableProgress>
            )}
            {!hideTimer && value.timer.enabled && (
              <DraggableTimer
                canvas={workspaceSize}
                color={value.timer.color}
                fontSize={value.timer.fontSize}
                onChange={({ height, left, top, width }) => {
                  onChange({
                    ...value,
                    timer: {
                      ...value.timer,
                      position: { top, left },
                      timerSize: { height, width },
                    },
                  });
                }}
                onDragStart={overlayHide}
                onDragStop={overlayShow}
                defaultValue={{
                  ...value.timer.position,
                  ...value.timer.timerSize,
                }}
              />
            )}
          </ProgressAnimationWorkspace>
        </ProgressAnimationModalCol>
        <ProgressAnimationModalCol className={block('form-column')}>
          <ProgressAnimationOptions
            value={value}
            onChange={onChange}
            hideTimer={hideTimer}
            workspaceSize={workspaceSize}
          />
        </ProgressAnimationModalCol>
      </Modal.Row>
    </Modal.Body>
  );
};

export default ProgressAnimationModalBody;
