import { isPlainObject } from 'is-plain-object';
import React, { useCallback, useMemo, useState } from 'react';
import {
  createDynamicBaseOverlay,
  isLegacyOverlayConvertAble,
  validateOverlayV2Integrity,
} from 'blocks/TextOverlayModal/v2';
import FontAwesome from 'components/FontAwesome';

import { ToggleCardToggleBody } from 'components/ToggleCard';
import { KeyTextType } from 'types';
import merge from 'utils/deepmerge';
import DynamicTextInfobox, {
  DynamicTextInfoboxProps,
} from '../ModalManager/DynamicTextInfobox';
import {
  checkIsDynamicElement,
  getTextModalOverlay,
  replaceKeyTextAsset,
  updateDynamicTextElements,
} from '../state';
import {
  DynamicElementType,
  DynamicTextIntegrationData,
  DynamicTitleType,
  TextIntegrationData,
  TextIntegrationId,
  VideoTemplateStateContent,
} from '../types';
import {
  DynamicTextIntegration,
  IntegrationPostProcessor,
  IntegrationProps,
  OnAddIntegrationText,
  onEditIntegrationText,
  ReplaceIntegrationKeyTextAssets,
} from '../types/integrations';
import { formatDynamicTextOverlays, popperTileBlock } from '../utils';

interface UseDynamicPodcastTextIntegrationConfig extends IntegrationProps {
  episodeNamePlaceholder: string;
  podcastNamePlaceholder: string;
  replaceImageEffectDynamicTextWildcards?: boolean;
}

type SupportedKeyTextType = Exclude<KeyTextType, 'mainText'>;

const INTEGRATION_ID = TextIntegrationId.DYNAMIC;
const INTEGRATION_DATA = { id: INTEGRATION_ID };

// title of the text modal based on the dynamic title type of the text asset
const TEXT_MODAL_TITLES: Record<DynamicTitleType, string> = {
  podcast: 'Podcast title',
  episode: 'Episode title',
};

// text placeholder value based on type of dynamic text asset
export const DYNAMIC_ASSET_VALUE: Record<
  DynamicTitleType,
  DynamicElementType
> = {
  episode: '%%Episode%%',
  podcast: '%%Podcast%%',
};

const DYNAMIC_KEY_TO_TYPE = Object.keys(DYNAMIC_ASSET_VALUE).reduce(
  (acc, key) => {
    const val = DYNAMIC_ASSET_VALUE[key];

    acc[val] = key;

    return acc;
  },
  {},
);

// asset type for DynamicTextInfobox based on type of dynamic asset
const DYNAMIC_ASSET_TYPE: Record<
  DynamicTitleType,
  DynamicTextInfoboxProps['assetType']
> = {
  episode: 'episodeTitle',
  podcast: 'podcastTitle',
};

const KEY_TEXT_TITLE_TYPE: Record<SupportedKeyTextType, DynamicTitleType> = {
  episodeTitle: 'episode',
  podcastTitle: 'podcast',
};

function getModalTitle(
  integrationDataOrTitleType: TextIntegrationData | string,
) {
  if (typeof integrationDataOrTitleType === 'string') {
    return TEXT_MODAL_TITLES[integrationDataOrTitleType];
  }

  if (integrationDataOrTitleType.id === TextIntegrationId.DYNAMIC) {
    return TEXT_MODAL_TITLES[integrationDataOrTitleType.type];
  }

  return undefined;
}

export default function useDynamicPodcastTextIntegration({
  episodeNamePlaceholder,
  podcastNamePlaceholder,
  priority,
  replaceImageEffectDynamicTextWildcards,
}: UseDynamicPodcastTextIntegrationConfig): DynamicTextIntegration {
  const [titleType, setTitleType] = useState<DynamicTitleType>('podcast');
  const placeholders: Record<DynamicTitleType, string> = useMemo(
    () => ({
      episode: episodeNamePlaceholder,
      podcast: podcastNamePlaceholder,
    }),
    [episodeNamePlaceholder, podcastNamePlaceholder],
  );

  const handleTitleChange = useCallback((value: string) => {
    setTitleType(value as DynamicTitleType);
  }, []);

  const handleAddText: OnAddIntegrationText = useCallback(
    async (state, modalManager, addText) => {
      const text = placeholders[titleType];
      const defaultOverlay = createDynamicBaseOverlay(state.aspectRatio, text);

      modalManager.showModal('add-text', {
        subTitle: `This textbox will be automatically filled with the selected ${
          titleType === 'podcast'
            ? "podcast's title."
            : "podcast episode's title."
        }`,
        editable: false,
        isLegacy: false,
        onSubmit: overlay => {
          addText(overlay, {
            ...INTEGRATION_DATA,
            type: titleType,
          });
        },
        showEmoji: false,
        textOverlay: defaultOverlay,
        title: getModalTitle(titleType),
      });
    },
    [placeholders, titleType],
  );

  const handleEditText: onEditIntegrationText = useCallback(
    (state, modalManager, id, props) => {
      const overlay = getTextModalOverlay(state, id);
      const isV2CompatibilityMode =
        !validateOverlayV2Integrity(overlay) &&
        isLegacyOverlayConvertAble(overlay);
      const isV2 = validateOverlayV2Integrity(overlay);
      const isLegacy = !isV2 && !isV2CompatibilityMode;
      const { integrationData } = state.textOverlays.data[id];

      if (integrationData.id !== INTEGRATION_ID) {
        return;
      }

      const handleOpenLegacyModal = () => {
        modalManager.showModal('edit-text', {
          assetId: id,
          isLegacy: true,
          banner: (
            <DynamicTextInfobox
              assetType={DYNAMIC_ASSET_TYPE[integrationData.type]}
            />
          ),
          editable: false,
          showEmoji: false,
          textOverlay: overlay,
          title: getModalTitle(integrationData),
          ...props,
        });
      };

      modalManager.showModal('edit-text', {
        ...(isLegacy
          ? {
              banner: (
                <DynamicTextInfobox
                  assetType={DYNAMIC_ASSET_TYPE[integrationData.type]}
                />
              ),
            }
          : {
              subTitle: `This textbox will be automatically filled with the selected ${
                integrationData.type === 'podcast'
                  ? "podcast's title."
                  : "podcast episode's title."
              }`,
              isV2CompatibilityMode,
              onOpenLegacyModal: handleOpenLegacyModal,
            }),
        assetId: id,
        editable: false,
        isLegacy,
        showEmoji: false,
        textOverlay: overlay,
        title: getModalTitle(integrationData),
        ...props,
      });
    },
    [],
  );

  const replaceDynamicTextElements = useCallback(
    (state: VideoTemplateStateContent): VideoTemplateStateContent => {
      return Object.values(state.textOverlays.data).reduce(
        (prevState, textOverlay) => {
          if (!checkIsDynamicElement(textOverlay.text)) {
            return prevState;
          }

          const dynamicTitleType = DYNAMIC_KEY_TO_TYPE[textOverlay.text];

          return updateDynamicTextElements(
            prevState,
            textOverlay.id,
            textOverlay,
            placeholders[dynamicTitleType],
            dynamicTitleType,
          );
        },
        state,
      );
    },
    [placeholders],
  );

  const replaceKeyAssets: ReplaceIntegrationKeyTextAssets = useCallback(
    state => {
      if (state.template.templateType === 'userGenerated') {
        return replaceDynamicTextElements(state);
      }

      const keyTextTypes: KeyTextType[] = ['podcastTitle', 'episodeTitle'];
      return keyTextTypes.reduce((currentState, type) => {
        const dynamicTitleType = KEY_TEXT_TITLE_TYPE[type];
        return replaceKeyTextAsset(
          currentState,
          type,
          placeholders[dynamicTitleType],
          {
            integrationData: {
              ...INTEGRATION_DATA,
              type: dynamicTitleType,
            },
          },
        );
      }, state);
    },
    [placeholders, replaceDynamicTextElements],
  );

  const postProcessor: IntegrationPostProcessor = useCallback(
    (exportState, editorState) => {
      const { slideEffectText } = editorState;
      const slideIdsWithEffectText = Object.keys(slideEffectText)
        .map(id => slideEffectText[id])
        .filter(effect => effect.integrationData?.id === INTEGRATION_ID)
        .map(effect => effect.slideId);

      const slideshow = exportState.slideshow.map(slide => {
        if (!slideIdsWithEffectText.includes(slide.id)) {
          return slide;
        }

        const editorSlide = editorState.slideshow.data[slide.id];

        return merge(
          slide,
          {
            imageEffect: {
              options: {
                embeddedTexts: editorSlide.imageEffect.options.embeddedTexts.map(
                  embeddedTextId => {
                    const effectText = slideEffectText[embeddedTextId];
                    const {
                      type: effectTextTitleType,
                    } = effectText.integrationData as DynamicTextIntegrationData;

                    // dynamic texts should not be replaced when exporting a regular audiogram.
                    return replaceImageEffectDynamicTextWildcards
                      ? {
                          text: DYNAMIC_ASSET_VALUE[effectTextTitleType],
                          textType: effectText.textType,
                        }
                      : {
                          text: effectText.text,
                          textType: effectText.textType,
                        };
                  },
                ),
              },
            },
          },
          {
            isMergeableObject: isPlainObject,
          },
        );
      });

      return {
        ...exportState,
        slideshow,
      };
    },
    [replaceImageEffectDynamicTextWildcards],
  );

  const formatDynamicTexts: IntegrationPostProcessor = useCallback(
    exportState => {
      return {
        ...exportState,
        textOverlays: formatDynamicTextOverlays(exportState?.textOverlays),
      };
    },
    [],
  );

  return {
    postProcessor,
    formatDynamicTexts,
    priority,
    replaceKeyAssets,
    creationType: 'dynamic',
    id: TextIntegrationId.DYNAMIC,
    onAddText: handleAddText,
    onEditText: handleEditText,
    textTypeCardProps: {
      children: (
        <ToggleCardToggleBody
          className={popperTileBlock('modal-tile')}
          onChange={handleTitleChange}
          title="select title type"
          options={[
            {
              description: 'Podcast title will be added to each video',
              value: 'podcast',
            },
            {
              description: 'Episode title will be added to each video',
              value: 'episode',
            },
          ]}
          value={titleType}
        />
      ),
      icon: (
        <FontAwesome icon="microphone" style={{ height: 35, width: 'auto' }} />
      ),

      info: (
        <>
          <strong>Automatically</strong> add show or episode title into every
          video
        </>
      ),
    },
    type: 'text',
  };
}
