import { createSelector } from 'reselect';
import { socialSharesSelector } from 'redux/modules/entities';
import { RequestStatus } from '../../../../types/common';
import { stateSelector } from '../selectors';

export const linkedinSelector = createSelector(stateSelector, state =>
  state.get('linkedin'),
);

export const linkedinSocialShareIdSelector = createSelector(
  linkedinSelector,
  li => li.socialShareId,
);

export const linkedinProviderUserIdSelector = createSelector(
  linkedinSelector,
  linkedin => linkedin.providerUserId,
);

export const linkedinUserDataSelector = createSelector(
  linkedinSelector,
  li => ({
    accessToken: li.accessToken,
    idToken: li.idToken,
    providerUserId: li.providerUserId,
  }),
);

export const linkedinIsLoading = createSelector(
  linkedinSelector,
  li =>
    !li.username ||
    !li.organizations.postStatus ||
    li.organizations.postStatus === RequestStatus.REQUEST,
);

export const linkedinSocialSharesSelector = createSelector(
  linkedinSocialShareIdSelector,
  socialSharesSelector,
  (id, shares) =>
    !id || !shares || shares.size === 0
      ? undefined
      : shares.get(String(id))?.toJS(),
);

export const linkedinOrganizationsSelector = createSelector(
  linkedinSelector,
  li => li.organizations?.data ?? [],
);
