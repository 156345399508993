import cn from 'classnames';
import React from 'react';
import { Plus } from 'components/icons';
import Tile from './Tile';
import { imageViewBlock as block } from './utils';

interface AddMediaButtonProps {
  className?: string;
  disabled?: boolean;
  locked?: boolean;
  hoverExpandLocked?: boolean;
  onClick(): void;
  tooltipText?: string;
}

function AddMediaButton(props: AddMediaButtonProps) {
  const {
    onClick,
    className,
    disabled,
    hoverExpandLocked,
    locked,
    tooltipText = 'Add a new image',
  } = props;

  return (
    <Tile
      disabled={disabled}
      onClick={onClick}
      className={cn(className, block('add-image'))}
      hoverExpandLocked={hoverExpandLocked}
      tooltip={tooltipText}
      tooltipPlacement="top"
      id="add-image"
    >
      {!locked ? <Plus /> : null}
    </Tile>
  );
}

export default AddMediaButton;
