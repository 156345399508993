import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import { episodeTranscriptionModalBlock as block } from '../utils';
import EpisodeUnsupportedLanguageModalContents from './EpisodeUnsupportedLanguageModalContents';

const EpisodeUnsupportedLanguageModal = () => (
  <ConnectedModal
    name="EpisodeUnsupportedLanguage"
    title="transcript"
    className={block()}
  >
    <EpisodeUnsupportedLanguageModalContents />
  </ConnectedModal>
);

export default EpisodeUnsupportedLanguageModal;
