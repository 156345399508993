import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';
import ClipDurationInputAdornment from 'components/AudioClipper/ClipDurationInputAdornment';
import ClipEndTimeInputAdornment from 'components/AudioClipper/ClipEndTimeInputAdornment';
import ClipStartTimeInputAdornment from 'components/AudioClipper/ClipStartTimeInputAdornment';
import ClippingOptionsField from './ClippingOptionsField';
import { block } from './utils';

export type ClippingOption = 'start' | 'end' | 'duration';

interface IProps {
  className?: string;
  startMillis: number;
  endMillis: number;
  maxMillis: number;
  minDurationMillis?: number;
  /**
   * bootstrap pads the bottom of each column so that when columns wrap, there is vertical spacing.
   * noWrap will remove this bottom padding.
   */
  noWrap?: boolean;
  onBlur?: (millis: number, option: ClippingOption) => void;
  onStartPreview?: () => void;
  onEndPreview?: () => void;
}

export default class ClippingOptions extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    noWrap: false,
    onBlur: noop,
  };

  private handleBlur = (option: ClippingOption) => (millis: number) => {
    const { onBlur } = this.props;
    onBlur(millis, option);
  };

  public render() {
    const {
      className,
      endMillis,
      maxMillis,
      minDurationMillis,
      noWrap,
      startMillis,
      onStartPreview,
      onEndPreview,
    } = this.props;

    const hasAdornment = onStartPreview && onEndPreview;

    return (
      <div className={cn(block({ 'no-wrap': noWrap }), className)}>
        <ClippingOptionsField
          label="Clip Start"
          maxMillis={endMillis - minDurationMillis}
          value={startMillis}
          minMillis={0}
          onBlur={this.handleBlur('start')}
          startAdornment={
            hasAdornment && (
              <ClipStartTimeInputAdornment onClick={onStartPreview} />
            )
          }
        />
        <ClippingOptionsField
          label="Clip End"
          maxMillis={maxMillis}
          minMillis={startMillis + minDurationMillis}
          value={endMillis}
          onBlur={this.handleBlur('end')}
          startAdornment={
            hasAdornment && <ClipEndTimeInputAdornment onClick={onEndPreview} />
          }
        />
        <ClippingOptionsField
          label="Duration"
          maxMillis={maxMillis - startMillis}
          minMillis={minDurationMillis}
          value={endMillis - startMillis}
          onBlur={this.handleBlur('duration')}
          startAdornment={
            hasAdornment && (
              <ClipDurationInputAdornment
                maxMillis={maxMillis}
                onChange={this.handleBlur('duration')}
              />
            )
          }
        />
      </div>
    );
  }
}

ClippingOptions.defaultProps = {
  minDurationMillis: spareminConfig.minSlideDurationMillis,
};

export { IProps as ClippingOptionsProps };
