import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import { Headset, InfinityIcon, SaveAsTemplate } from 'components/icons';
import { block } from './utils';

export const allFeatures = {
  adminSettings: {
    icon: <FontAwesome icon="lock" />,
    label: 'Admin settings',
  },
  brandedTemplates: {
    icon: <SaveAsTemplate height={35} width="100%" />,
    label: 'Branded templates',
  },
  dedicatedSupport: {
    icon: <Headset height="100%" width={34} />,
    label: 'Dedicated support',
  },
  limitedVideos: (limit: number) => ({
    icon: <span className={block('number-value')}>{limit}</span>,
    label: 'Unwatermarked videos a month',
  }),
  support: {
    icon: <Headset height="100%" width={34} />,
    label: 'Priority support',
  },
  unlimitedVideos: {
    icon: <InfinityIcon height="100%" width={50} stroke="black" />,
    label: 'Unlimited videos',
  },
  unlimitedAutomations: {
    icon: <InfinityIcon height="100%" width={50} stroke="black" />,
    label: 'Unlimited automations',
  },
  limitedTranscriptions: {
    icon: <span className={block('number-value')}>10</span>,
    label: 'Minutes a month of transcription',
  },
};

export const proFeatures = [
  allFeatures.unlimitedVideos,
  allFeatures.brandedTemplates,
  allFeatures.support,
];

export const enterpriseFeatures = [
  allFeatures.adminSettings,
  allFeatures.brandedTemplates,
  allFeatures.dedicatedSupport,
];

export const basicFeatures = [
  allFeatures.limitedVideos(10),
  allFeatures.brandedTemplates,
  allFeatures.support,
];

export const unlimitedFeatures = [
  allFeatures.unlimitedVideos,
  allFeatures.unlimitedAutomations,
  allFeatures.dedicatedSupport,
];

export const educationFeatures = [
  allFeatures.limitedVideos(10),
  allFeatures.brandedTemplates,
  allFeatures.limitedTranscriptions,
];

export const apiFeatures = enterpriseFeatures;
