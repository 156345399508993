import React from 'react';

// types
import { IconProps } from './types';

const FooterTwitter: React.FC<IconProps> = ({ ...svgProps }) => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M21.533 4.984c.015.22.015.438.015.657C21.548 12.312 16.6 20 7.553 20 4.767 20 2.178 19.172 0 17.734a9.92 9.92 0 0 0 1.188.063c2.3 0 4.416-.797 6.106-2.156-2.162-.047-3.974-1.5-4.599-3.5.305.046.61.078.93.078.441 0 .883-.063 1.294-.172-2.254-.469-3.944-2.5-3.944-4.953V7.03a4.86 4.86 0 0 0 2.223.64A5.079 5.079 0 0 1 1.005 3.47c0-.938.244-1.797.67-2.547 2.421 3.062 6.061 5.062 10.142 5.281a5.84 5.84 0 0 1-.122-1.156c0-2.781 2.193-5.047 4.92-5.047a4.85 4.85 0 0 1 3.593 1.594A9.536 9.536 0 0 0 23.33.375a5.012 5.012 0 0 1-2.162 2.781c.99-.11 1.949-.39 2.832-.781-.67 1-1.508 1.89-2.467 2.61z"
      fill="#38A1F3"
      fillRule="nonzero"
    />
  </svg>
);

export default FooterTwitter;
