import React from 'react';
import VideoTypeIcon from 'blocks/AudiogramDestinations/components/VideoTypes/VideoTypeIcon';

import { AudiogramDestinationConfig } from 'blocks/AudiogramDestinations/types';
import { DestinationSteps } from 'blocks/AutomationOptions/types';

import {
  Facebook,
  InstagramReels,
  InstagramStories,
  PlusBold,
} from 'components/icons';
import { FacebookSocialCircle } from 'components/SocialCircle';
import { VideoTypes } from 'types';
import { getAudiogramWizardPath, getFullEpisodeWizardPath } from 'utils/routes';
import { millisToSec } from 'utils/time';
import { DESTINATION_PRESETS } from '../../destinationPresets/utils';

const FACEBOOK_BLUE = '#3b5998';

const facebook: AudiogramDestinationConfig = {
  id: 'facebook',
  icon: <FacebookSocialCircle />,
  videoTypes: [
    {
      description: 'Share a short clip to go viral',
      destination: getAudiogramWizardPath({
        defaultClipDurationMillis: DESTINATION_PRESETS.facebook.reel.durationMs,
        defaultAspectRatio: DESTINATION_PRESETS.facebook.reel.aspectRatio,
        defaultPresetKey: DESTINATION_PRESETS.facebook.reel.key,
      }),
      id: 'reel',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.facebook.reel.aspectRatio}
          background={FACEBOOK_BLUE}
        >
          <InstagramReels width="15px" color="white" />
        </VideoTypeIcon>
      ),
      title: 'Facebook Reel',
      autogramWizardSettings: {
        destination: DestinationSteps.AUTO_POSTING,
        videoType: VideoTypes.RANDOM_CLIP,
        clipDuration: millisToSec(DESTINATION_PRESETS.facebook.reel.durationMs),
        aspectRatioName: DESTINATION_PRESETS.facebook.reel.aspectRatio,
      },
    },
    {
      description: 'Share an ephemeral clip for 24 hours',
      destination: getAudiogramWizardPath({
        defaultClipDurationMillis:
          DESTINATION_PRESETS.facebook.story.durationMs,
        defaultAspectRatio: DESTINATION_PRESETS.facebook.story.aspectRatio,
        defaultPresetKey: DESTINATION_PRESETS.facebook.story.key,
      }),
      id: 'story',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.facebook.story.aspectRatio}
          background={FACEBOOK_BLUE}
        >
          <InstagramStories width="15px" color="white" />
        </VideoTypeIcon>
      ),
      title: 'Facebook Story',
      autogramWizardSettings: {
        destination: DestinationSteps.AUTO_POSTING,
        videoType: VideoTypes.RANDOM_CLIP,
        clipDuration: millisToSec(
          DESTINATION_PRESETS.facebook.story.durationMs,
        ),
        aspectRatioName: DESTINATION_PRESETS.facebook.story.aspectRatio,
      },
    },
    {
      description: 'Clip a full segment from an episode',
      destination: getAudiogramWizardPath({
        defaultClipDurationMillis: DESTINATION_PRESETS.facebook.post.durationMs,
        defaultAspectRatio: DESTINATION_PRESETS.facebook.post.aspectRatio,
        defaultPresetKey: DESTINATION_PRESETS.facebook.post.key,
      }),
      id: 'post',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.facebook.post.aspectRatio}
          background={FACEBOOK_BLUE}
        >
          <PlusBold width="9px" color="white" />
        </VideoTypeIcon>
      ),
      title: 'Facebook Post',
      autogramWizardSettings: {
        destination: DestinationSteps.AUTO_POSTING,
        videoType: VideoTypes.RANDOM_CLIP,
        clipDuration: millisToSec(DESTINATION_PRESETS.facebook.post.durationMs),
        aspectRatioName: DESTINATION_PRESETS.facebook.post.aspectRatio,
      },
    },
    {
      description: 'Add an entire podcast episode',
      destination: getFullEpisodeWizardPath({
        defaultAspectRatio: DESTINATION_PRESETS.facebook.episode.aspectRatio,
      }),
      id: 'episode',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.facebook.episode.aspectRatio}
          background={FACEBOOK_BLUE}
        >
          <Facebook width="12px" color="white" />
        </VideoTypeIcon>
      ),
      title: 'Full episode',
      autogramWizardSettings: {
        destination: DestinationSteps.AUTO_POSTING,
        videoType: VideoTypes.FULL_EPISODE,
        clipDuration: null,
        aspectRatioName: DESTINATION_PRESETS.facebook.episode.aspectRatio,
      },
    },
  ],
};

export default facebook;
