import * as React from 'react';

import { EditorProgressAnimationModal } from 'blocks/ProgressAnimationModal';
import { ProgressBar } from 'components/icons';
import { createChainedFunction } from 'utils/functions';
import StyleTileButton from '../../components/StyleTileButton';

const { useCallback, useState } = React;

export interface ProgressBarTileProps {
  onClick?: () => void;
  onHide?: () => void;
  onShow?: () => void;
}

const ProgressBarTile: React.SFC<ProgressBarTileProps> = ({
  onClick,
  onHide,
  onShow,
}) => {
  const [show, setShow] = useState(false);

  const handleClick = useCallback(() => setShow(true), []);
  const handleHide = useCallback(() => setShow(false), []);

  return (
    <>
      <StyleTileButton
        icon={<ProgressBar color="#19b5fe" style={{ width: '65%' }} />}
        label="progress animation"
        onClick={createChainedFunction(handleClick, onShow, onClick)}
      />
      <EditorProgressAnimationModal
        onHide={createChainedFunction(handleHide, onHide)}
        show={show}
      />
    </>
  );
};

export default ProgressBarTile;
