import { Spacer } from '@sparemin/blockhead';
import React from 'react';

import DialogContent from 'components/DialogContent';
import { H2 } from 'components/Heading';
import ModalFooterButton from 'components/Modal/ModalFooterButton';
import SocialAccountInfo from 'components/SocialAccountInfo';
import { Step } from 'components/SteppedModal';

import useYoutubePostModalSelector from '../useYoutubePostModalSelector';
import { block } from '../utils';

const VERIFICATION_LINK = 'https://www.youtube.com/verify';

export interface UseElegibleAccountStepConfig {
  onStartVerification: () => void;
  onSwitchAccount: () => void;
}

const useElegibleAccountStep = (config: UseElegibleAccountStepConfig): Step => {
  const { onStartVerification, onSwitchAccount } = config;

  const { avatarSrc, username } = useYoutubePostModalSelector();

  return {
    title: 'verification needed',
    modalClassName: block('dialog-content'),
    component: (
      <DialogContent
        className={block('dialog')}
        details={
          <H2>
            To ensure all your videos get posted, let's quickly hop over to
            YouTube to verify your account. If we don't then some of your videos
            might get blocked during posting.
          </H2>
        }
        detailsClassName={block('dialog-details')}
        headerRow={
          <Spacer>
            <SocialAccountInfo
              avatarSrc={avatarSrc}
              onSwitchAccountClick={onSwitchAccount}
              username={username}
            />
          </Spacer>
        }
        onClose={() => null}
        title="Let’s verify your account"
        titleClassName={block('dialog-title')}
      />
    ),
    fluidFooterButtons: true,
    id: 'elegible-account',
    renderFooterButtons: ({ submit }) => [
      <ModalFooterButton
        {...submit}
        href={VERIFICATION_LINK}
        key="submit"
        onClick={onStartVerification}
        rel="noreferrer noopener"
        size="large"
        target="_blank"
        theme="submit"
      >
        verify your account
      </ModalFooterButton>,
    ],
  };
};

export default useElegibleAccountStep;
