import { block } from './utils';

export const TIMELINE_INNER_CLASSNAME = block('inner');
export const ASSET_PARENT_DRAG_HANDLE = `.${TIMELINE_INNER_CLASSNAME}`;
export const GUTTER_PX = 30;
export const TRACK_IN_ANIMATION_DURATION_MILLIS = 350;
export const ADD_ASSET_BUTTON_WIDTH = 26;

export enum DndItemType {
  TRACK = 'track',
}
