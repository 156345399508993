import { Action, Middleware } from 'redux';
import { persistor } from 'redux/store';

interface PersistPurgeMiddlewareMeta extends Action {
  meta?: {
    persistPurge?: boolean;
  };
}

const persistPurge: Middleware = _ => next => <
  A extends PersistPurgeMiddlewareMeta
>(
  action: A,
) => {
  const purge = action?.meta?.persistPurge ?? false;

  if (purge) {
    persistor.purge();
  }

  return next(action);
};

export default persistPurge;
