import * as React from 'react';

import { IconProps } from './types';

const TiktokIcon: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="87"
    height="86"
    viewBox="0 0 87 86"
    {...props}
  >
    <g fill="none" fillRule="evenodd" transform="translate(.5)">
      <rect width="86" height="86" fill="#000" rx="20" />
      <g fillRule="nonzero">
        <path
          fill="#FF004F"
          d="M53.645 35.883A18.084 18.084 0 0064 39.107v-7.23c-.73 0-1.46-.073-2.175-.22v5.69a18.087 18.087 0 01-10.355-3.224v14.754c0 7.38-6.15 13.363-13.735 13.363-2.83 0-5.46-.833-7.646-2.26 2.494 2.48 5.972 4.02 9.82 4.02 7.586 0 13.736-5.983 13.736-13.364V35.883zm2.682-7.294a9.95 9.95 0 01-2.682-5.9v-.929h-2.061c.519 2.879 2.288 5.338 4.743 6.83zm-21.44 25.727a5.98 5.98 0 01-1.282-3.7c0-3.376 2.813-6.113 6.283-6.113.647 0 1.29.096 1.906.286v-7.391a14.233 14.233 0 00-2.174-.122v5.753a6.45 6.45 0 00-1.907-.286c-3.47 0-6.282 2.736-6.282 6.113 0 2.387 1.406 4.453 3.456 5.46z"
        />
        <path
          fill="#FFF"
          d="M51.47 34.123a18.087 18.087 0 0010.355 3.224v-5.691a10.465 10.465 0 01-5.498-3.067c-2.455-1.49-4.224-3.95-4.743-6.829H46.17v28.876c-.013 3.367-2.82 6.092-6.283 6.092a6.335 6.335 0 01-5.002-2.412c-2.05-1.007-3.456-3.074-3.456-5.46 0-3.376 2.813-6.113 6.283-6.113.665 0 1.306.1 1.907.286v-5.753c-7.452.15-13.445 6.074-13.445 13.36 0 3.637 1.492 6.935 3.914 9.344a13.94 13.94 0 007.646 2.26c7.586 0 13.735-5.983 13.735-13.363V34.123z"
        />
        <path
          fill="#00F2EA"
          d="M61.825 31.656v-1.539a10.568 10.568 0 01-5.498-1.528 10.456 10.456 0 005.498 3.067zM51.584 21.76a9.679 9.679 0 01-.114-.83V20h-7.474v28.876c-.012 3.366-2.82 6.092-6.283 6.092a6.4 6.4 0 01-2.827-.652 6.335 6.335 0 005.002 2.412c3.462 0 6.27-2.725 6.283-6.092V21.76h5.413zM39.62 37.276v-1.638a14.246 14.246 0 00-1.884-.124C30.149 35.514 24 41.497 24 48.876c0 4.627 2.417 8.705 6.09 11.103-2.423-2.409-3.915-5.706-3.915-9.343 0-7.286 5.993-13.21 13.445-13.36z"
        />
      </g>
    </g>
  </svg>
);

export default TiktokIcon;
