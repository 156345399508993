import React from 'react';

// types
import { IconProps } from './types';

const YoutubeSmall: React.FC<IconProps> = ({ ...svgProps }) => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <defs>
      <filter
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        <feGaussianBlur in="SourceGraphic" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M2 0h41a2 2 0 0 1 2 2v41a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
        fill="#FFF"
        filter="url(#a)"
      />
      <path fill="#FFF" d="M15.3 17.143h14.3v9.643H15.3z" />
      <path
        d="M33.54 17.347a2.723 2.723 0 0 0-1.945-1.898C29.88 15 23 15 23 15s-6.88 0-8.595.449a2.723 2.723 0 0 0-1.945 1.898C12 19.021 12 22.515 12 22.515s0 3.494.46 5.168c.253.924.998 1.621 1.945 1.868C16.12 30 23 30 23 30s6.88 0 8.595-.449c.947-.247 1.692-.944 1.945-1.868.46-1.674.46-5.168.46-5.168s0-3.494-.46-5.168zm-12.79 8.34v-6.344l5.75 3.172-5.75 3.172z"
        fill="red"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default YoutubeSmall;
