import { combineReducers } from 'redux-immutable';
import { ActionType } from 'redux/modules/sample-audio/action-types';
import headlinerUserService from '../../middleware/api/headliner-user-service';
import { libraryFactory, selectedAudioFactory } from './factories';

const defaultSelectedAudioState = selectedAudioFactory();

const defaultLibraryState = libraryFactory();

const selectedAudio = (state = defaultSelectedAudioState, action) => {
  switch (action.type) {
    case ActionType.SAMPLE_AUDIO_DOWNLOAD_REQUEST:
      return state.set('isFetching', true);

    case ActionType.SAMPLE_AUDIO_DOWNLOAD_SUCCESS:
      return state.withMutations(s =>
        s
          .set('isFetching', false)
          .set('id', action.payload.id)
          .set('file', action.payload.file),
      );

    case ActionType.SAMPLE_AUDIO_DOWNLOAD_FAILURE:
      return state.set('isFetching', false);

    case ActionType.CLEAR_SELECTED_AUDIO: {
      return defaultSelectedAudioState;
    }

    default:
      return state;
  }
};

const library = (state = defaultLibraryState, action) => {
  switch (action.type) {
    case `${headlinerUserService.Method.GET_SAMPLE_AUDIOS}_REQUEST`: {
      return state.set('isFetching', true);
    }

    case `${headlinerUserService.Method.GET_SAMPLE_AUDIOS}_SUCCESS`: {
      return state.set('isFetching', false);
    }

    case `${headlinerUserService.Method.GET_SAMPLE_AUDIOS}_FAILURE`: {
      return state.set('isFetching', false);
    }

    default:
      return state;
  }
};

export default combineReducers({
  library,
  selectedAudio,
});
