import {
  EpisodeId,
  FeedbackType,
  actions as podcastService,
} from 'redux/middleware/api/podcast-service';
import { actions as videoExportService } from 'redux/middleware/api/video-export-service';
import { ThunkAction } from 'redux/types';
import { ClipSuggestionDislikeReason } from 'types/common';
import { cancelReduxPoll, reduxPoll } from 'utils/redux-poll';
import { Type } from './action-types';
import {
  autoCreateEpisodeIdSelector,
  autoCreateEpisodeSelector,
  subscriptionItemIdSelector,
} from './selectors';

export const loadClipSuggestions = (
  episodeId: EpisodeId,
  subscriptionItemId: number,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.LOAD_CLIP_SUGGESTIONS_REQUEST });

  try {
    const { response } = await dispatch(
      podcastService.getEpisodeTranscriptInfo(episodeId, subscriptionItemId),
    );

    dispatch({
      type: Type.LOAD_CLIP_SUGGESTIONS_SUCCESS,
      payload: response.result,
    });
  } catch (error) {
    dispatch({ type: Type.LOAD_CLIP_SUGGESTIONS_FAILURE });
  }
};

const EXPORT_POLL_ID = 'clip-select';

const waitForVideoId = (): ThunkAction<Promise<number>> => async (
  dispatch,
  getState,
) => {
  const autoCreateEpisodeId = autoCreateEpisodeIdSelector(getState());

  if (!autoCreateEpisodeId) {
    throw new Error('Auto create episode missing');
  }

  await reduxPoll(
    dispatch,
    () => dispatch(podcastService.getAutoCreateEpisode(autoCreateEpisodeId)),
    {
      id: EXPORT_POLL_ID,
      intervalMillis: spareminConfig.videoExportPollIntervalMillis,
      maxAttempts: spareminConfig.videoExportPollMaxAttempts,
      retryAttempts: spareminConfig.videoExportRetryAttempts,
      shouldContinue: (err, value) => {
        if (err) return false;
        const { response } = value;
        const { entities } = response;
        const autoCreateEpisode =
          entities.autoCreateEpisodes[autoCreateEpisodeId];

        if (autoCreateEpisode.status === 'completed') {
          return false;
        }

        if (
          autoCreateEpisode.status === 'queued' ||
          autoCreateEpisode.status === 'processing'
        ) {
          return true;
        }

        throw new Error('Error generating video');
      },
    },
  );

  const autoCreateEpisode = autoCreateEpisodeSelector(getState());
  return autoCreateEpisode?.videoId;
};

export const waitForWidgetId = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  const videoId = await dispatch(waitForVideoId());

  const { response } = await dispatch(
    videoExportService.fetchEmbedVideos(videoId),
  );

  const [widgetId] = response.result;

  dispatch({
    type: Type.EMBED_EXPORT_READY,
    payload: { widgetId },
  });
};

export const cancelWaitForWidgetId = (): ThunkAction<void> => dispatch => {
  cancelReduxPoll(dispatch, EXPORT_POLL_ID);
};

export const dislikeSuggestedClip = (
  suggestionId: number,
  reason: ClipSuggestionDislikeReason,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const subscriptionItemId = subscriptionItemIdSelector(getState());
  const reasonToType: Record<ClipSuggestionDislikeReason, FeedbackType> = {
    [ClipSuggestionDislikeReason.Advertisement]:
      FeedbackType.ItsAnAdvertisement,
    [ClipSuggestionDislikeReason.CutsOffMidSentence]:
      FeedbackType.ItCutsOffMidSentence,
    [ClipSuggestionDislikeReason.NotInteresting]:
      FeedbackType.ItsNotInteresting,
    [ClipSuggestionDislikeReason.Other]: FeedbackType.OtherReason,
  };

  dispatch({
    type: Type.CLIP_SUGGESTION_DISLIKE,
    payload: { suggestionId },
  });

  await dispatch(
    podcastService.createEpisodeClipSuggestionFeedback({
      suggestionId,
      subscriptionItemId,
      feedbackType: reasonToType[reason],
    }),
  );
};

export const reset = () => ({
  type: Type.RESET,
});
