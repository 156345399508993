import {
  createQuestionnaireResponse,
  getQuestionnaireResponse,
  getQuestionnaireStatus,
  QuestionId,
  QuestionnaireStatus,
} from 'redux/middleware/api/headliner-user-service';
import { ThunkAction } from 'redux/types';
import { omitUndefined } from 'utils/collections';
import { setSurveyGa } from 'utils/ga';
import { addSurveyResponseToLiveChatTags } from 'utils/helpdesk';
import { Type } from './action-types';

const setSurveyResponse = (
  status: QuestionnaireStatus,
  podcast?: string,
  sector?: string,
  referrer?: string,
) => ({
  payload: {
    status,
    data: omitUndefined({
      [QuestionId.PODCAST]: podcast,
      [QuestionId.REFERRER]: referrer,
      [QuestionId.ROLE]: sector,
    }),
  },
  type: Type.COHORT_SET_SURVEY_DATA,
});

export const loadSurveyData = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  const {
    response: { status },
  } = await dispatch(getQuestionnaireStatus());
  const {
    response: { questionResponses },
  } = await dispatch(getQuestionnaireResponse());

  /*
   * NB: the order of this array should be the same order as the arguments
   *     of setSurveyResponse, that way spreading the results sends the
   *     correct args in the correct order
   */
  const data = [QuestionId.PODCAST, QuestionId.ROLE, QuestionId.REFERRER].map(
    id => {
      const question = questionResponses.find(
        sr => sr.questionIdentifier === id,
      );
      return question && question.response;
    },
  );

  dispatch(setSurveyResponse(status, ...(data as [string, string, string])));
};

export const saveCohortSurveyResponse = (
  podcast: string,
  sector: string,
  referrer?: string,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.COHORT_RESPONSE_SAVE_REQUEST });

  try {
    await dispatch(createQuestionnaireResponse(podcast, sector, referrer));
    setSurveyGa(podcast, sector);
    addSurveyResponseToLiveChatTags(podcast, sector);
    await dispatch(setSurveyResponse('completed', podcast, sector, referrer));
    dispatch({ type: Type.COHORT_RESPONSE_SAVE_SUCCESS });
  } catch (err) {
    dispatch({ type: Type.COHORT_RESPONSE_SAVE_FAILURE });
    throw err;
  }
};

export const getCohortSurveyStatus = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  const {
    response: { status },
  } = await dispatch(getQuestionnaireStatus());
  dispatch({
    payload: { status },
    type: Type.COHORT_SURVEY_STATUS_GET_SUCCESS,
  });
};
