import * as React from 'react';
import { isEqual } from 'underscore';

import { block } from '../../utils';
import AddPhraseButton from './AddPhraseButton';
import CopyToTimelineButton from './CopyToTimelineButton';
import DeletePhraseButton from './DeletePhraseButton';
import PlayPhraseButton from './PlayPhraseButton';

export interface PhraseControlsProps {
  disabled?: Array<'add' | 'remove' | 'playback' | 'timeline'>;
  onAdd?: () => void;
  onPlay?: () => void;
  onRemove?: () => void;
  onCopyToTimeline?: () => void;
}

export default class PhraseControls extends React.Component<
  PhraseControlsProps
> {
  public static defaultProps: Partial<PhraseControlsProps> = {
    disabled: [],
  };

  public shouldComponentUpdate(nextProps: Readonly<PhraseControlsProps>) {
    return !isEqual(nextProps, this.props);
  }

  public render() {
    const { disabled, onAdd, onPlay, onRemove, onCopyToTimeline } = this.props;

    return (
      <div className={block('phrase-controls')}>
        <div className={block('phrase-controls-left')}>
          <CopyToTimelineButton
            disabled={disabled.indexOf('timeline') >= 0}
            onClick={onCopyToTimeline}
          />
          <AddPhraseButton
            disabled={disabled.indexOf('add') >= 0}
            onClick={onAdd}
          />
          <DeletePhraseButton
            disabled={disabled.indexOf('remove') >= 0}
            onClick={onRemove}
          />
        </div>
        <div className={block('phrase-controls-right')}>
          <PlayPhraseButton
            disabled={disabled.indexOf('playback') >= 0}
            onClick={onPlay}
          />
        </div>
      </div>
    );
  }
}
