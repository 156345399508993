import React, { useEffect, useMemo } from 'react';
import { Flipped } from 'react-flip-toolkit';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import AudioClipCard from 'components/AudioClipCard/AudioClipCard';
import { suggestionIdsSelector } from 'redux/modules/clip-select';
import { episodeClipSuggestionSelector } from 'redux/modules/entities';
import { range } from 'utils/numbers';
import { selectorBlock as block } from '../../utils';
import { useClipSelectAnimation } from '../ClipSelectAnimationContext';
import { useClipSelect } from '../ClipSelectContext';

export interface AudioClipSuggestionProps {
  active?: boolean;
  id: number;
}

const makeSuggestionSelector = (id: number) =>
  createSelector(episodeClipSuggestionSelector, suggestions => {
    if (!suggestions || id === undefined) return undefined;
    return suggestions.get(`${id}`)?.toJS();
  });

const makeSuggestionIndexSelector = (id: number) =>
  createSelector(suggestionIdsSelector, ids => {
    if (!ids) return [];
    const index = ids.indexOf(id);
    return [index, ids.length];
  });

const AudioClipSuggestion: React.FC<AudioClipSuggestionProps> = ({
  active,
  id,
}) => {
  const { endMillis, startMillis, text } =
    useSelector(useMemo(() => makeSuggestionSelector(id), [id])) ?? {};
  const [index, total] = useSelector(
    useMemo(() => makeSuggestionIndexSelector(id), [id]),
  );
  const {
    dislikedSuggestionIds,
    onSuggestionSelect,
    togglePlayback,
    playbackMillis,
    playing,
    selectedRegion,
  } = useClipSelect();

  const { createSuggestionCardAnimationConfig } = useClipSelectAnimation();

  useEffect(() => {
    if (
      active &&
      (!selectedRegion ||
        selectedRegion.startMillis !== startMillis ||
        selectedRegion.endMillis !== endMillis)
    ) {
      onSuggestionSelect({ startMillis, endMillis });
    }
  }, [active, endMillis, onSuggestionSelect, selectedRegion, startMillis]);

  const disliked = dislikedSuggestionIds.includes(id);

  const {
    sizer,
    sizerInverter,
    vanisher,
  } = createSuggestionCardAnimationConfig(id);
  const { className: sizerClassName, ...sizerFlippedProps } = sizer;
  const { className: inverterClassName, ...inverterProps } = sizerInverter;
  const { className: vanisherClassName, ...vanisherFlippedProps } = vanisher;

  return (
    <Flipped {...sizerFlippedProps}>
      <div className={sizerClassName}>
        <Flipped {...inverterProps}>
          <div style={{ position: 'relative' }}>
            {!disliked && (
              <Flipped {...vanisherFlippedProps}>
                <div className={vanisherClassName}>
                  <AudioClipCard
                    className={block('suggestion', { active })}
                    clipEndMillis={endMillis}
                    clipIndex={index}
                    clipStartMillis={startMillis}
                    onClickControlButton={togglePlayback}
                    playable={active}
                    playing={active && playing}
                    progress={range(
                      startMillis,
                      endMillis,
                      0,
                      1,
                      playbackMillis,
                    )}
                    text={text}
                    totalClips={total}
                  />
                </div>
              </Flipped>
            )}
          </div>
        </Flipped>
      </div>
    </Flipped>
  );
};

export default AudioClipSuggestion;
