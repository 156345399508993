import { List } from 'immutable';

import { Type } from './action-types';
import { stateFactory } from './factories';
import { AsyncAudioClipperAction, AsyncAudioClipperState } from './types';

const defaultState = stateFactory();

export default function(
  state: AsyncAudioClipperState = defaultState,
  action: AsyncAudioClipperAction,
) {
  switch (action.type) {
    case Type.WAIT_FOR_WAVEFORM_REQUEST: {
      const { entireAudioInstanceId } = action.payload;
      return state.set('entireAudioInstanceId', entireAudioInstanceId);
    }

    case Type.WAIT_FOR_WAVEFORM_SUCCESS: {
      const { waveform } = action.payload;
      return state.set('waveform', List(waveform));
    }

    case Type.WAVEFORM_CLEAR:
      return defaultState;

    case Type.CANCEL_STANDARDIZATION: {
      const { entireAudioInstanceId } = action.payload;
      if (state.entireAudioInstanceId === entireAudioInstanceId) {
        return state.set('standardizationCancelled', true);
      }

      return state;
    }

    case Type.LOAD_MEDIA_FAILURE: {
      const error = action.payload;
      return state.set('error', error);
    }

    default:
      return state;
  }
}
