import { createSelector } from 'reselect';
import { State } from 'redux/types';

import { RequestStatus } from 'types';

import { AudioSelectionState, EditAutomationState, OutputState } from './types';

const stateSelector = (state: State): EditAutomationState =>
  state.get('editAutomation');

export const editAutomationRequestStatusSelector = createSelector(
  stateSelector,
  state => state.status,
);

export const isUpdatingAutomationSelector = createSelector(
  editAutomationRequestStatusSelector,
  status => status === RequestStatus.REQUEST,
);

export const editAutomatioAudioSelectionSelector = createSelector(
  stateSelector,
  state => state.audioSelection.toJS() as AudioSelectionState,
);

export const editAutomationOutputSelector = createSelector(
  stateSelector,
  state => state.output.toJS() as OutputState,
);

export const editAutomatioSubscriptionIdSelector = createSelector(
  stateSelector,
  state => state.subscriptionId,
);

export const editAutomationFrequencySelector = createSelector(
  stateSelector,
  state => state.frequency,
);
