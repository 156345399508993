import { Split } from '../../utils/constants';
import { Position, Size } from './types';

export const renderSingleAndDualPane = (
  singlePanePosition: Position,
  renderSinglePane: (size: Size) => void,
  renderDualPane: (size: Size) => void,
) => {
  const createResponse = (
    renderFirstPane: (size: Size) => void,
    renderSecondPane: (size: Size) => void,
  ) => ({
    renderFirstPane,
    renderSecondPane,
  });

  if (
    singlePanePosition === Position.TOP ||
    singlePanePosition === Position.LEFT
  ) {
    return createResponse(renderSinglePane, renderDualPane);
  }

  return createResponse(renderDualPane, renderSinglePane);
};

export const getSplitByPosition = (singlePanePosition: Position): Split => {
  if (
    singlePanePosition === Position.BOTTOM ||
    singlePanePosition === Position.TOP
  ) {
    return Split.HORIZONTAL;
  }

  return Split.VERTICAL;
};
