import { List } from 'immutable';
import { createSelector } from 'reselect';
import {
  entireAudioInstancesSelector,
  entireAudiosSelector,
} from 'redux/modules/entities/selectors';

import { State } from 'redux/types';
import { getValue } from 'utils/collections';
import { AsyncAudioClipperState } from './types';

const stateSelector = (state: State): AsyncAudioClipperState =>
  state.get('asyncAudioClipper');

export const waveformSelector = createSelector(stateSelector, state =>
  getValue(state, 'waveform', List()).toArray(),
);

export const entireAudioInstanceIdSelector = createSelector(
  stateSelector,
  state => state && state.entireAudioInstanceId,
);

export const isStandardizationCancelledSelector = createSelector(
  stateSelector,
  state => state && state.standardizationCancelled,
);

export const entireAudioIdSelector = createSelector(
  [entireAudioInstanceIdSelector, entireAudioInstancesSelector],
  (instanceId, instances) => {
    if (!instanceId || !instances) return undefined;
    return instances.getIn([`${instanceId}`, 'entireAudio']);
  },
);

const entireAudioSelector = createSelector(
  [entireAudioIdSelector, entireAudiosSelector],
  (audioId, audios) => {
    if (!audioId || !audios) return undefined;
    return audios.get(`${audioId}`);
  },
);

export const waveformUrlSelector = createSelector(
  entireAudioSelector,
  audio => audio && audio.getIn(['waveform', 'url']),
);

export const standardizationStatusSelector = createSelector(
  entireAudioSelector,
  audio => audio?.getIn(['standardized', 'status']),
);

export const statusSelector = createSelector(entireAudioSelector, audio =>
  audio?.getIn(['status']),
);

export const standardizationProgressSelector = createSelector(
  entireAudioSelector,
  standardizationStatusSelector,
  (audio, status) => {
    if (status === 'skipStandardization') {
      return 100;
    }
    const progress = audio?.getIn(['standardized', 'progress']);
    return progress;
  },
);

export const standardizedUrlSelector = createSelector(
  entireAudioSelector,
  audio => audio?.getIn(['standardized', 'url']) || audio?.getIn(['url']),
);

export const entireAudioByEntireAudioInstanceIdSelector = (
  entireAudioInstanceId: number,
) => (state: State) => {
  const entireAudioInstances = entireAudioInstancesSelector(state);
  const entireAudios = entireAudiosSelector(state);

  const entireAudioId = entireAudioInstances.getIn([
    `${entireAudioInstanceId}`,
    'entireAudio',
  ]);
  const entireAudio = entireAudios.get(`${entireAudioId}`);
  return entireAudio;
};

export const waitingForStandardizationSelector = createSelector(
  standardizationStatusSelector,
  isStandardizationCancelledSelector,
  (standardizationStatus, cancelled) => {
    return (
      !cancelled &&
      standardizationStatus !== 'completed' &&
      standardizationStatus !== 'skipStandardization'
    );
  },
);

export const errorSelector = createSelector(stateSelector, state =>
  state.get('error'),
);
