import FontFaceObserver from 'fontfaceobserver';
import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import _ from 'underscore';
import WebFont from 'webfontloader';

import { getValue } from 'utils/collections';

interface IProps {
  family?: string;
  weight?: number;
  style?: string;
  url?: string;
  onError?: (err: Error) => void;
  onLoad?: (family: string, weight: number, style: string) => void;
  render?: (
    family: string,
    weight: number,
    style: string,
    isLoaded: boolean,
  ) => JSX.Element;
}

interface IDataState {
  family: string;
  weight: number;
  style: string;
  url: string;
  isLoaded: boolean;
}

const dataFactory = Record<IDataState>({
  family: undefined,
  isLoaded: undefined,
  style: undefined,
  url: undefined,
  weight: undefined,
});

interface IState {
  data: RecordOf<IDataState>;
}

export default class FontLoader extends React.Component<IProps, IState> {
  public static defaultProps = {
    onError: _.noop,
    onLoad: _.noop,
    render: () => null,
  };

  constructor(props) {
    super(props);

    const { family, weight, style, url } = props;

    this.state = {
      data: dataFactory({
        family,
        style,
        url,
        weight,
        isLoaded: false,
      }),
    };

    this.loadFont(family, weight, style, url);
  }

  private static isSameFont(font1, font2) {
    const keys = ['family', 'weight', 'style'];
    return keys.reduce(
      (acc, key) => acc && getValue(font1, key) === getValue(font2, key),
      true,
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const { family, weight, style, url } = nextProps;

    const sameFont = FontLoader.isSameFont(this.props, nextProps);
    if (family && !sameFont) {
      this.setState({
        data: dataFactory({
          family,
          style,
          url,
          weight,
          isLoaded: false,
        }),
      });
    }
  }

  public componentDidUpdate(__: Readonly<IProps>, prevState: Readonly<IState>) {
    const { onLoad } = this.props;
    const { data: prevData } = prevState;
    const { data } = this.state;

    const sameFont = FontLoader.isSameFont(prevData, data);
    if (sameFont && !prevData.get('isLoaded') && data.get('isLoaded')) {
      onLoad(data.get('family'), data.get('weight'), data.get('style'));
    }

    if (!sameFont) {
      this.loadFont(
        data.get('family'),
        data.get('weight'),
        data.get('style'),
        data.get('url'),
      );
    }
  }

  private handleFontLoad = (family: string, weight: number, style: string) =>
    this.setState(({ data }) => {
      if (
        family !== data.get('family') ||
        weight !== data.get('weight') ||
        style !== data.get('style')
      ) {
        return undefined;
      }

      return {
        data: data.set('isLoaded', true),
      };
    });

  private loadFont(family: string, weight: number, style: string, url: string) {
    const { onError } = this.props;

    /*
     * if font has a url, it needs to be loaded.  webfont will load it, observer will alert us
     * when the font is ready.
     *
     * webfont can also handle alerting us when the font is ready, but it's easier to handle the
     * "ready" event using fontfaceobserver for fonts that don't need to be loaded via url,
     * e.g. our stock fonts.
     */
    if (url) {
      WebFont.load({
        classes: false,
        custom: {
          families: [family],
          urls: [url],
        },
      });
    }

    if (family) {
      const opts = { weight, style };
      new FontFaceObserver(family, opts)
        .load()
        .then(() => this.handleFontLoad(family, weight, style))
        .catch(err => onError(err));
    }
  }

  public render() {
    const { render } = this.props;
    const { data } = this.state;

    return render(
      data.get('family'),
      data.get('weight'),
      data.get('style'),
      data.get('isLoaded'),
    );
  }
}
