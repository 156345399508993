import { useEffect, useState } from 'react';
import { getDuration } from 'utils/audio';

const useDuration = (source: File | string) => {
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    let cancelled = false;
    setDuration(0);
    if (source) {
      getDuration(source).then(d => {
        if (!cancelled) {
          setDuration(d);
        }
      });
    }
    return () => {
      cancelled = true;
    };
  }, [source]);

  return duration;
};

export default useDuration;
