import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'components/Modal';
import { CreateProfileModal } from 'redux/modules/modal/types';
import { surveyStatusSelector } from 'redux/modules/survey';
import CreateProfileCard from '../CreateProfileCard';

export type CreateProfileModalContentsProps = CreateProfileModal['params'] & {
  onHide?: (completed?: boolean | undefined) => void;
};

const CreateProfileModalContents: React.FC<CreateProfileModalContentsProps> = ({
  onHide,
  origin,
}) => {
  const surveyStatus = useSelector(surveyStatusSelector);

  useEffect(() => {
    if (surveyStatus === 'completed') {
      onHide?.(true);
    }
  }, [onHide, surveyStatus]);

  return (
    <Modal.Body>
      <CreateProfileCard origin={origin} />
    </Modal.Body>
  );
};

export default CreateProfileModalContents;
