import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined, noop, omit } from 'underscore';

import { destinationPresetUtils } from 'blocks/AudiogramDestinations/destinationPresets';
import { DestinationWizardMixpanelKey } from 'blocks/AudiogramDestinations/destinationPresets/types';
import { AudioClipperComponent } from 'components/AudioClipper';
import { IRegion, SocialPresetOption } from 'components/AudioClipper/types';
import { TemplateEditorStateExport } from 'components/VideoTemplateEditor';
import { OnStyleChange } from 'components/VideoTemplateEditor/useOnStyleChange';
import Wizard, { IndexedStep, WizardNavigationAction } from 'components/Wizard';
import { DEFAULT_LANGUAGE } from 'constants/languages';
import usePodcastIdModal from 'containers/PodcastIdModal/usePodcastIdModal';
import useLastUsedStyleDispatch from 'hooks/useLastUsedStyleDispatch';
import useLastUsedStyleSelector from 'hooks/useLastUsedStyleSelector';
import useMediaPlayback from 'hooks/useMediaPlayback';
import useOnMount from 'hooks/useOnMount';
import { fetchEddySupportedProjectLanguages } from 'redux/middleware/api/headliner-user-service';
import { waitingForStandardizationSelector } from 'redux/modules/async-audio-clipper';
import { hasLockedCustomizationSelector } from 'redux/modules/display-pref';

import { onWizardNext } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import {
  AddAudioMeta,
  AspectRatioName,
  AudioSourceType,
  PodcastIdentifier,
  TemplateType,
  VideoTypes,
} from 'types';

import { getAspectRatio, getAspectRatioValue } from 'utils/aspect-ratio';
import { BLANK_CANVAS_TEMPLATE_ID } from 'utils/constants';
import { createChainedFunction } from 'utils/functions';
import { isBlankCanvas } from 'utils/templates';
import ClipAudioStep, { ClipSelectionMethod } from '../ClipAudioStep';
import {
  CustomizeStepCtaSubmitConfig,
  CustomizeStepProps,
} from '../CustomizeStep';

import { CustomizeStepView } from '../CustomizeStep/CustomizeStep';
import LanguageStep from '../LanguageStep';
import { TranscriptionFormValue } from '../types';
import useSelectAudioTab from '../useSelectAudioTab';
import AudiogramAddAudioStep from './AudiogramAddAudioStep';
import AudiogramCustomizeStep, {
  CustomizeStepSubmitType,
} from './AudiogramCustomizeStep';
import AudiogramExportVideoStep from './AudiogramExportVideoStep';
import AudiogramWizardProgressStep from './AudiogramWizardProgressStep';
import { AudiogramWizardProps, Step } from './types';
import UseAudiogramAudio from './UseAudiogramAudio';
import useAudiogramQueryParams from './useAudiogramQueryParams';
import useAudiogramWizardBlocker from './useAudiogramWizardBlocker';
import { block } from './utils';

const { useCallback, useEffect, useRef, useState } = React;

const DEFAULT_TRANSCRIPTION_CONFIG = {
  transcriptionToggleAllowed: true,
};

const AudiogramWizard: React.FC<AudiogramWizardProps> = ({
  defaultTranscription,
  entireAudioInstanceId,
  onBackgroundImageChange,
  onCancel,
  onError,
  onComplete,
  onMount,
  onStepChange,
  onSubmit,
  onUnmount,
  uploadProgress,
}) => {
  const dispatch = useDispatch<Dispatch>();

  // NOTE - default values from query string should override other defaults
  // (i.e. last used style)
  const {
    defaultAspectRatio,
    defaultClipDurationMillis,
    defaultPresetKey,
  } = useAudiogramQueryParams();
  const { setLastUsedStyle } = useLastUsedStyleDispatch('audiogram');
  const { lastUsedStyle } = useLastUsedStyleSelector('audiogram');
  const { getDefaultPodcastFeedId } = usePodcastIdModal();
  const initialAspectRatio =
    defaultAspectRatio || lastUsedStyle.get('aspectRatioName');
  const [aspectRatioName, setAspectRatioName] = useState<AspectRatioName>(
    initialAspectRatio,
  );
  const [customizeStepView, setCustomizeStepView] = useState<CustomizeStepView>(
    'templates',
  );
  const [audioClipDurationSec, setAudioClipDurationSec] = useState<number>(
    undefined,
  );
  const clipperRef = useRef<AudioClipperComponent>();
  const wizardRef = useRef<Wizard>();

  const [step, setStep] = useState<Step>();
  const [addAudioTab, setAddAudioTab] = useSelectAudioTab();
  const [transcription, setTranscription] = useState(defaultTranscription);
  const [audioLanguage, setAudioLanguage] = useState<string>(DEFAULT_LANGUAGE);
  const [shouldGenerateAssets, setShouldGenerateAssets] = useState<
    boolean | undefined
  >();
  const clipperPlayback = useMediaPlayback();
  const audioSourceTypeRef = useRef<AudioSourceType>();
  const submitTypeRef = useRef<CustomizeStepSubmitType>();

  const skipTemplateSelection = useSelector(hasLockedCustomizationSelector);
  const [podcastIdentifier, setPodcastIdentifier] = useState<
    PodcastIdentifier
  >();
  const [templateId, setTemplateId] = useState<string>();

  const waitingForStandardization = useSelector(
    waitingForStandardizationSelector,
  );
  const lastClipSelectionMethodRef = useRef<ClipSelectionMethod>(
    ClipSelectionMethod.DEFAULT,
  );
  const lastSocialPresetKeyRef = useRef<DestinationWizardMixpanelKey>(
    defaultPresetKey ?? 'Custom',
  );
  const [region, setRegion] = useState<IRegion>(null);

  useOnMount(onMount, onUnmount);

  const confirm = useAudiogramWizardBlocker(step);

  const updateAspectRatio = React.useCallback(
    (name: AspectRatioName) => {
      setAspectRatioName(name);
      setLastUsedStyle({ aspectRatioName: name });
    },
    [setAspectRatioName, setLastUsedStyle],
  );

  const handleAspectRatioSubmit = (name: AspectRatioName) => {
    updateAspectRatio(name);
  };

  const handleStepChange = resetAudioState => (
    toStep: IndexedStep<Step>,
    fromStep: IndexedStep<Step>,
    action?: WizardNavigationAction,
  ) => {
    setStep(toStep.stepId);
    if (fromStep && fromStep.stepId === 'clip') {
      clipperPlayback.pause();
    }

    if (toStep.stepId === 'add-audio') {
      resetAudioState();
      setTranscriptUrl(undefined);
      // Resets the last social preset key when going back to audio clip step.
      // It also resets the selected aspect ratio to the default one for consistency.
      lastSocialPresetKeyRef.current = defaultPresetKey ?? 'Custom';
      setAspectRatioName(initialAspectRatio);
    }

    onStepChange(toStep, fromStep, action, {
      templateId,
      audioSource: audioSourceTypeRef.current,
      clipSelectionMethod: lastClipSelectionMethodRef.current,
      destination: lastSocialPresetKeyRef.current,
      maxDuration: destinationPresetUtils.getPresetMaxDurationKey(
        lastSocialPresetKeyRef.current,
      ),
    });
  };

  const handleNext = useCallback(() => wizardRef.current?.next(), []);

  const handleTranscriptionChange = useCallback(
    (value: TranscriptionFormValue) => {
      setTranscription(s => ({
        ...s,
        ...value,
      }));
    },
    [],
  );

  const handleCreateProjectClick = useCallback(
    (
      { type, projectName, transcriptionEnabled }: CustomizeStepCtaSubmitConfig,
      config: TemplateEditorStateExport,
    ) => {
      submitTypeRef.current = type;
      onSubmit(type, {
        ...config,
        projectName,
        transcription: {
          ...transcription,
          transcribe: transcriptionEnabled,
        },
        podcastIdentifier,
        aspectRatio: getAspectRatio(aspectRatioName)?.toJS(),
        audioClip: clipperRef.current.clip,
        audioSourceType: audioSourceTypeRef.current,
        templateId: isBlankCanvas(templateId) ? undefined : templateId,
        shouldGenerateAssets,
        audioLanguage,
      });
    },
    [
      aspectRatioName,
      audioLanguage,
      onSubmit,
      podcastIdentifier,
      shouldGenerateAssets,
      templateId,
      transcription,
    ],
  );

  const handleAudioChange = ({ audioSourceType }) => {
    audioSourceTypeRef.current = audioSourceType;
  };

  const setTranscriptUrl = useCallback((transcriptUrl: string) => {
    setTranscription(s => ({
      ...s,
      transcriptUrl,
    }));
  }, []);

  const handleAudioAdded = useCallback(
    async (src, type: AudioSourceType, meta?: AddAudioMeta) => {
      audioSourceTypeRef.current = type;

      if (meta) {
        const { podcastId, episodeId } = meta;
        if (podcastId || episodeId) {
          setPodcastIdentifier({ podcastId, episodeId });
        }
        // if transcriptUrl is undefined, then the transcriptUrl state will correctly
        // get unset
        setTranscriptUrl(meta.transcriptUrl);
      } else {
        setPodcastIdentifier(null);
      }

      // in order to signal to the parent that state should be reset,
      // AddAudioStep calls handleAudioAdded with undefined values when
      // the step mounts.  only advance to the next step if we have an audio source
      if (src) {
        if (!meta) {
          const podcastId = await getDefaultPodcastFeedId();

          setPodcastIdentifier({ podcastId, episodeId: undefined });
        }

        handleNext();
      }
    },
    [getDefaultPodcastFeedId, handleNext, setTranscriptUrl],
  );

  const handleStyleChange: OnStyleChange = useCallback(
    (style, actionType) => {
      // onBackgroundImageChange should be called as a result of the user
      // interacting with the file picker
      if (actionType === 'IMAGE_FILE_SELECT') {
        onBackgroundImageChange(style.image as File);
      } else {
        // IMAGE_FILE_SELECT has type "image", but other than that no image
        // actions are handled. the rest get handled by setLastUsedStyle
        setLastUsedStyle(omit(style, 'image', 'video'));
      }
    },
    [onBackgroundImageChange, setLastUsedStyle],
  );

  const handleChangePreset = useCallback((preset?: SocialPresetOption) => {
    lastSocialPresetKeyRef.current = preset?.key ?? 'Custom';
    // As it was defined, when clearing the preset, aspect ratio name should be
    // kept.
    if (preset?.aspectRatio) {
      setAspectRatioName(preset.aspectRatio);
    }
  }, []);

  const handleCustomizeError: CustomizeStepProps['onError'] = useCallback(
    (error, type, meta) => {
      if (type === 'MEDIA_ADD_ERROR' || type === 'MEDIA_REPLACE_ERROR') {
        onBackgroundImageChange(meta, error?.message);
      }
      onError(error, null);
    },
    [onBackgroundImageChange, onError],
  );

  const handleRegionUpdate = (value: IRegion, method: ClipSelectionMethod) => {
    setRegion(value);
    lastClipSelectionMethodRef.current = method;

    if (method === ClipSelectionMethod.SOUNDBITE) {
      audioSourceTypeRef.current = 'soundbite';
    }
  };

  const handleSetAudioClipDuration = useCallback((): void => {
    if (!clipperRef?.current?.clip) {
      return;
    }

    setAudioClipDurationSec(
      Math.floor(
        (clipperRef.current.clip.endMillis -
          clipperRef.current.clip.startMillis) /
          1000,
      ),
    );

    handleNext();
  }, [handleNext]);

  const handleContinueFromLanguageStep = useCallback(
    (isLanguageSupported: boolean, generateAssets: boolean): void => {
      dispatch(
        onWizardNext({
          step: 'Language',
          type: 'audiogram',
          languageSelection: audioLanguage,
          eddyOptIn: isLanguageSupported
            ? generateAssets
              ? 'Yes'
              : 'No'
            : 'Disabled',
        }),
      );

      handleNext();
    },
    [audioLanguage, dispatch, handleNext],
  );

  useEffect(() => {
    setTemplateId(BLANK_CANVAS_TEMPLATE_ID[aspectRatioName]);
  }, [aspectRatioName, skipTemplateSelection]);

  useEffect(() => {
    dispatch(fetchEddySupportedProjectLanguages());
  }, [dispatch]);

  return (
    <UseAudiogramAudio onChange={handleAudioChange}>
      {({ afterUploadTransfer, audioRegion, onAudioAdded, source, reset }) => (
        <Wizard
          bodyClassName={block('body', {
            [step]: true,
            'customize-with-button':
              step === 'customize' && !skipTemplateSelection,
          })}
          canBacktrack={step !== 'export' && step !== 'submitting'}
          className={block({ template: step === 'template' })}
          ref={wizardRef}
          onCancelClick={onCancel}
          onStepChange={handleStepChange(reset)}
          onStepClick={confirm}
          steps={[
            {
              completedName: 'Audio Selected',
              component: (
                <AudiogramAddAudioStep
                  activeTab={addAudioTab}
                  isFileSelected={!isUndefined(source)}
                  onAudioAdded={createChainedFunction(
                    handleAudioAdded,
                    onAudioAdded,
                  )}
                  onError={onError}
                  onTabSelect={setAddAudioTab}
                />
              ),
              name: 'Select Audio',
              renderCancelButton: () => null,
              renderNextButton: () => null,
              stepId: 'add-audio',
            },
            {
              stepId: 'language',
              name: 'Language',
              component: (
                <LanguageStep
                  language={audioLanguage}
                  defaultShouldGenerateAssets={shouldGenerateAssets}
                  onLanguageChange={setAudioLanguage}
                  onShouldGenerateAssetsChange={setShouldGenerateAssets}
                  onContinue={handleContinueFromLanguageStep}
                />
              ),
              renderCancelButton: () => null,
              renderNextButton: () => null,
            },
            {
              completedName: 'Audio Clipped',
              component: ({ stepId }: IndexedStep) => (
                <ClipAudioStep
                  className={block('clip-step', { hidden: stepId !== 'clip' })}
                  defaultClipDurationMillis={defaultClipDurationMillis}
                  defaultPresetKey={defaultPresetKey}
                  entireAudioInstanceId={entireAudioInstanceId}
                  uploadProgress={uploadProgress}
                  src={source}
                  disabled={stepId !== 'clip'}
                  clipperRef={clipperRef as any}
                  onError={audioRegion.setUnselected}
                  onClearError={audioRegion.setSelected}
                  onPause={clipperPlayback.pause}
                  onPlay={clipperPlayback.play}
                  onReady={audioRegion.setSelected}
                  onPresetChange={handleChangePreset}
                  onStop={clipperPlayback.pause}
                  onTranscriptionChange={handleTranscriptionChange}
                  playing={clipperPlayback.playing}
                  transcription={transcription}
                  podcastIdentifier={podcastIdentifier}
                  region={region}
                  onRegionUpdate={handleRegionUpdate}
                  transcriptionConfig={DEFAULT_TRANSCRIPTION_CONFIG}
                />
              ),
              description: (
                <div>
                  Looking for more than 10 minutes? Check out{' '}
                  <a href="/wizard?type=episode">Full Episode!</a>
                </div>
              ),
              keepMounted: true,
              name: 'Clip Audio',
              renderNextButton: props => (
                <Wizard.Button
                  {...props}
                  disabled={
                    !audioRegion.regionSelected || waitingForStandardization
                  }
                  onClick={handleSetAudioClipDuration}
                  theme="next"
                >
                  next
                </Wizard.Button>
              ),
              stepId: 'clip',
              title: 'Select 10 minutes or less of audio',
            },
            {
              component: (
                <AudiogramCustomizeStep
                  compatibilityTypes={[
                    VideoTypes.SHORT_CLIP,
                    VideoTypes.FULL_EPISODE,
                  ]}
                  defaults={{
                    aspectRatio: getAspectRatioValue(aspectRatioName),
                  }}
                  features={{
                    templates: skipTemplateSelection ? 'hidden' : true,
                  }}
                  shouldGenerateAssets={shouldGenerateAssets}
                  lastUsedStyle={lastUsedStyle}
                  onChangeView={setCustomizeStepView}
                  onError={handleCustomizeError}
                  onSelectTemplate={setTemplateId}
                  onSelectAspectRatio={handleAspectRatioSubmit}
                  onStyleChange={handleStyleChange}
                  onSubmit={createChainedFunction(
                    handleNext as any,
                    afterUploadTransfer(handleCreateProjectClick),
                  )}
                  podcastIdentifier={podcastIdentifier}
                  source={source}
                  transcriptionEnabled={transcription.transcribe}
                  templateId={templateId}
                  templateTypes={[
                    TemplateType.HEADLINER_DEFAULT,
                    TemplateType.USER_GENERATED,
                    'blank',
                  ]}
                  view={customizeStepView}
                  audioSourceType={audioSourceTypeRef.current}
                  audioClipDurationSec={audioClipDurationSec}
                  language={audioLanguage}
                />
              ),
              name: 'Template',
              renderNextButton: () => null,
              renderCancelButton: () => null,
              stepId: 'customize',
              title: customizeStepView === 'edit' ? null : 'Choose a template',
            },
            {
              component: (
                <AudiogramWizardProgressStep
                  onError={onError}
                  onCompleted={() => {
                    if (submitTypeRef.current === 'edit') {
                      onComplete();
                    } else {
                      handleNext();
                    }
                  }}
                />
              ),
              animationEnabled: true,
              name: 'loading',
              stepId: 'submitting',
              renderCancelButton: () => null,
              renderNextButton: () => null,
              showInNav: false,
            },
            {
              component: (
                <AudiogramExportVideoStep
                  durationSec={audioClipDurationSec}
                  onError={error => {
                    wizardRef.current?.jump('customize');
                    onError(error, null);
                  }}
                />
              ),
              animationEnabled: true,
              name: 'finish',
              renderCancelButton: () => null,
              renderNextButton: () => null,
              showInNav: false,
              stepId: 'export',
            },
          ].filter(Boolean)}
        />
      )}
    </UseAudiogramAudio>
  );
};

AudiogramWizard.defaultProps = {
  onMount: noop,
  onUnmount: noop,
};

export default AudiogramWizard;
