import { createSelector } from 'reselect';
import { formatWatermarkSrcForConfig } from 'utils/embed/watermark';
import { defaultLogoUrlSelector } from '../display-pref/selectors';
import { ExportConfiguration } from '../wizard-export/types';

export const defaultWatermarkSelector = createSelector(
  defaultLogoUrlSelector,
  (url): ExportConfiguration['watermark'] => {
    if (!url) return undefined;
    const watermark = formatWatermarkSrcForConfig(url);
    return {
      url,
      originalUrl: url,
      position: {
        left: watermark.position.properties.left,
        top: watermark.position.properties.top,
      },
      size: {
        height: watermark.style.height,
        width: watermark.style.width,
      },
      integrationData: undefined,
      metadata: undefined,
    };
  },
);
