import { MixpanelWizardStep } from 'redux/modules/mixpanel/types';
import { Step } from './types';

export const MIXPANEL_WIZARD_STEP_MAP: Record<Step, MixpanelWizardStep> = {
  'add-audio': 'Select Audio',
  clip: 'Clip Audio',
  language: 'Language',
  'aspect-ratio': 'Choose Aspect Ratio',
  template: 'Choose Template',
  customize: 'Customize',
  export: 'loading',
  submitting: 'loading',
};
