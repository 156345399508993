import cn from 'classnames';
import * as React from 'react';
import { Overlay, Popover } from 'react-bootstrap';

import FontAwesome from 'components/FontAwesome';

interface IProps {
  className?: string;
  id: string;
  message: string;
  overlayProps?: Overlay.OverlayProps;

  // TODO support for other types like 'info', 'warning', etc.
  type?: 'error';
}

const PopoverAlert: React.StatelessComponent<IProps> = ({
  className,
  id,
  message,
  overlayProps,
}) => {
  return (
    <Overlay {...overlayProps} shouldUpdatePosition>
      <Popover
        arrowOffsetLeft={37}
        id={id}
        className={cn('popover-alert', 'popover-alert--default', className)}
      >
        <FontAwesome
          className="popover-alert__icon"
          icon="exclamation-circle"
        />
        <div className="popover-alert__message">{message}</div>
      </Popover>
    </Overlay>
  );
};

PopoverAlert.defaultProps = {
  type: 'error',
};

export default PopoverAlert;
