import * as React from 'react';

import { IconProps } from 'components/icons/types';

const AspectRatio: React.SFC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="16"
    viewBox="0 0 42 16"
    {...props}
  >
    <path
      d="M38.667 0C40.507 0 42 1.433 42 3.2v9.6c0 1.767-1.492 3.2-3.333 3.2h-3.334C33.493 16 32 14.567 32 12.8V3.2C32 1.433 33.492 0 35.333 0zm0 1.6h-3.334c-.92 0-1.666.716-1.666 1.6v9.6c0 .884.746 1.6 1.666 1.6h3.334c.92 0 1.666-.716 1.666-1.6V3.2c0-.884-.746-1.6-1.666-1.6zM25.57 2A3.429 3.429 0 0 1 29 5.429v5.142A3.429 3.429 0 0 1 25.571 14H20.43A3.429 3.429 0 0 1 17 10.571V5.43A3.429 3.429 0 0 1 20.429 2zm0 1.714H20.43c-.947 0-1.715.768-1.715 1.715v5.142c0 .947.768 1.715 1.715 1.715h5.142c.947 0 1.715-.768 1.715-1.715V5.43c0-.947-.768-1.715-1.715-1.715zM10.5 4C12.433 4 14 5.433 14 7.2v1.6c0 1.767-1.567 3.2-3.5 3.2h-7C1.567 12 0 10.567 0 8.8V7.2C0 5.433 1.567 4 3.5 4zm0 1.6h-7c-.966 0-1.75.716-1.75 1.6v1.6c0 .884.784 1.6 1.75 1.6h7c.966 0 1.75-.716 1.75-1.6V7.2c0-.884-.784-1.6-1.75-1.6z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default AspectRatio;
