import React from 'react';
import {
  ActionStepCard,
  ActionStepCardEditButton,
} from 'components/ActionStepCard';
import useEditAutomationModal from '../hooks/useEditAutomationModal';
import useEditAutomationEpisode from './useEditAutomationEpisode';

export interface EditAutomationEpisodeProps {
  subscriptionId?: number;
}

const EditAutomationEpisode: React.FC<EditAutomationEpisodeProps> = ({
  subscriptionId,
}) => {
  const { onOpenEpisodeSelection } = useEditAutomationEpisode({
    subscriptionId,
  });
  const { subscription } = useEditAutomationModal(subscriptionId);
  const { episodeId, title, imageUrl } = subscription?.backCatalogNextEpisode;

  return (
    <ActionStepCard
      description={episodeId ? title : 'Waiting for next release'}
      image={episodeId ? imageUrl : subscription?.thumbnailUrl}
      renderAction={() => (
        <ActionStepCardEditButton onClickEdit={onOpenEpisodeSelection} />
      )}
      title="Upcoming episode"
    />
  );
};

export default EditAutomationEpisode;
