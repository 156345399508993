import React from 'react';
import { Facebook } from 'components/icons';
import SocialCircle from './SocialCircle';

export interface FacebookSocialCircleProps {
  className?: string;
}

const FacebookSocialCircle: React.FC<FacebookSocialCircleProps> = ({
  className,
}) => (
  <SocialCircle background="#3b5998" className={className}>
    <Facebook
      width="35%"
      height="auto"
      style={{ position: 'relative', left: '0.833%' }}
      color="white"
    />
  </SocialCircle>
);

export default FacebookSocialCircle;
