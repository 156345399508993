import cn from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import FontAwesome from 'components/FontAwesome';
import { VideoCamera } from 'components/icons';
import Tabs from 'components/Tabs';
import { onProjectsTabLoad } from 'redux/modules/mixpanel';
import {
  getCreatePath,
  getProjectsPath,
  isCreateActive,
  isProjectsActive,
} from 'utils/routes';
import { tabsBlock as block } from '../../utils';
import AutomationCMSTab from '../AutomationCMSTab';
import CreateTab, { CreateTabProps } from '../CreateTab';
import AnalyticsTabTitle from './AnalyticsTabTitle';
import ProjectsNavHeader from './ProjectsNavHeader';
import ProjectsNavTabTitle from './ProjectsNavTabTitle';
import ProjectsPageTab from './ProjectsPageTab';
import ProjectsPageTabControl from './ProjectsPageTabControl';

const { useCallback } = React;

type ProjectsPageTabsProps = Pick<CreateTabProps, 'onNewProjectClick'>;

const tabConfig = (disabled?: boolean) => ({
  titleClassName: block('title', { disabled }),
});

/**
 * Tabbed navigator on the projects page
 */
const ProjectsPageTabs: React.FC<ProjectsPageTabsProps> = ({
  onNewProjectClick,
}) => {
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const activeTab = pathname;
  const createPath = getCreatePath();
  const projectsPath = getProjectsPath();

  const handleTabSelect = useCallback(
    key => {
      if (activeTab !== projectsPath && key === projectsPath) {
        dispatch(onProjectsTabLoad);
      }
    },
    [activeTab, dispatch, projectsPath],
  );

  const tabs = [
    {
      ...tabConfig(),
      content: (
        <ProjectsPageTabControl active={isCreateActive()} lazy>
          <CreateTab onNewProjectClick={onNewProjectClick} />
        </ProjectsPageTabControl>
      ),
      tabKey: createPath,
      title: (
        <ProjectsNavTabTitle
          to={createPath}
          icon={
            <FontAwesome
              className={block('plus')}
              icon="plus"
              style={{
                height: 15,
                marginTop: 4,
                width: 15,
              }}
            />
          }
          label="CREATE"
        />
      ),
    },
    {
      ...tabConfig(),
      content: (
        <ProjectsPageTabControl active={isProjectsActive()} lazy>
          <AutomationCMSTab />
        </ProjectsPageTabControl>
      ),
      tabKey: projectsPath,
      title: (
        <ProjectsNavTabTitle
          to={projectsPath}
          icon={<VideoCamera width={21} style={{ marginTop: 2 }} />}
          label="PROJECTS"
        />
      ),
    },
    {
      ...tabConfig(),

      title: <AnalyticsTabTitle />,
      tabKey: undefined,
    },
  ];

  const activeTabIndex = tabs.findIndex(t => t.tabKey === activeTab);

  return (
    <Tabs
      activeTabKey={activeTab}
      className={block()}
      onTabSelect={handleTabSelect}
      overrides={{
        content: ({ active, children, className, tabKey }) => (
          <>
            <ProjectsPageTab
              {...{ active, className }}
              expand={tabKey === projectsPath}
            >
              {children}
            </ProjectsPageTab>
          </>
        ),
        header: ({ children, className }) => (
          <ProjectsNavHeader activeIndex={activeTabIndex} className={className}>
            {children}
          </ProjectsNavHeader>
        ),
        tabsContent: ({ children, className }) => (
          <div className={cn(block('contents'), className)}>{children}</div>
        ),
      }}
      tabs={tabs}
    />
  );
};

export { ProjectsPageTabs };
export default ProjectsPageTabs;
