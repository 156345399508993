import { RouteChildrenProps } from 'react-router';
import { omit, pick } from 'underscore';

import { QueryStringAuthenticatorProps } from './QueryStringAuthenticator';

export function splitProps<T extends {}>(
  props: QueryStringAuthenticatorProps & T,
) {
  const propsWithoutAuth = omit(
    props,
    'authenticate',
    'isAuthenticated',
    'isAuthenticating',
  );
  const routerProps: RouteChildrenProps = pick(
    propsWithoutAuth,
    'match',
    'history',
    'location',
  );
  const componentProps: T = omit(
    propsWithoutAuth,
    ...Object.keys(routerProps),
    'render',
  );

  return { routerProps, componentProps };
}
