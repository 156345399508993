import { useSelector } from 'react-redux';

import { userIdSelector } from 'redux/modules/auth/selectors';
import {
  isQueuerUserVerifiedSelector,
  queuerUserProfileSelector,
} from 'redux/modules/download/selectors';

export default function useVerificationBanner() {
  const profile = useSelector(queuerUserProfileSelector);
  const currentUserId = useSelector(userIdSelector);
  const isVerified = useSelector(isQueuerUserVerifiedSelector);

  return {
    show: !isVerified && currentUserId && currentUserId === profile?.userId,
  };
}
