import classNames from 'classnames';
import { Record, RecordOf } from 'immutable';
import Slider from 'rc-slider';
import * as React from 'react';
import _ from 'underscore';

export const DEFAULT_VOLUME = 50;
export type OnVolumeChange = (value: number) => void;

interface IProps {
  buttonClassName?: string;
  className?: string;
  onChange?: OnVolumeChange;
  sliderClassName?: string;
  disabled?: boolean;
}

interface IDataState {
  volume: number;
  muted: boolean;
}

interface IState {
  data: RecordOf<IDataState>;
}

const dataFactory = Record<IDataState>({
  muted: undefined,
  volume: undefined,
});

export default class VolumeControl extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    disabled: false,
    onChange: _.noop,
  };

  constructor(props: Partial<IProps>) {
    super(props);

    this.state = {
      data: dataFactory({
        muted: false,
        volume: DEFAULT_VOLUME,
      }),
    };
  }

  public componentDidUpdate(__, prevState: Readonly<IState>) {
    const { data: prevData } = prevState;
    const { data } = this.state;

    if (!data.equals(prevData)) {
      this.fireOnChange();
    }
  }

  private handleSliderChange = (value: number) => {
    const muted = value === 0;
    this.setState(({ data }) => ({
      data: data.withMutations(d => d.set('volume', value).set('muted', muted)),
    }));
  };

  private handleMuteClick = () =>
    this.setState(({ data }) => ({
      data: data.withMutations(d => {
        const muted = !d.get('muted');
        d.set('muted', muted);

        if (!muted) {
          d.update('volume', v => (v === 0 ? DEFAULT_VOLUME : v));
        }
        return d;
      }),
    }));

  private getEffectiveVolume() {
    const { data } = this.state;
    return data.get('muted') ? 0 : data.get('volume');
  }

  private fireOnChange() {
    const { onChange } = this.props;
    onChange(this.getEffectiveVolume());
  }

  private renderMuteButton() {
    const { buttonClassName: propsButtonClassName, disabled } = this.props;
    const { data } = this.state;

    const glyphiconClassName = data.get('muted')
      ? 'glyphicon-volume-off'
      : 'glyphicon-volume-up';

    const buttonClassName = classNames({
      'volume-control__button': true,
      'volume-control__mute-button': true,
      [propsButtonClassName]: !!propsButtonClassName,
    });

    const iconClassName = classNames({
      glyphicon: true,
      'volume-control__mute-icon': true,
      [glyphiconClassName]: true,
    });

    return (
      <button
        className={buttonClassName}
        onClick={this.handleMuteClick}
        disabled={disabled}
        type="button"
      >
        <span className={iconClassName} aria-hidden="true" />
      </button>
    );
  }

  public render() {
    const {
      className,
      sliderClassName: propsSliderClassName,
      disabled,
    } = this.props;

    const containerClassName = classNames({
      'volume-control': true,
      [className]: !!className,
    });

    const sliderClassName = classNames({
      'volume-control__slider': true,
      [propsSliderClassName]: !!propsSliderClassName,
    });

    const volume = this.getEffectiveVolume();

    return (
      <div className={containerClassName}>
        {this.renderMuteButton()}
        <Slider
          className={sliderClassName}
          onChange={this.handleSliderChange}
          value={volume}
          disabled={disabled}
        />
      </div>
    );
  }
}
