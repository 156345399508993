import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import { block } from './utils';

export interface RainbowFieldContainerProps {
  children?: React.ReactNode;
}

const RainbowFieldContainer: React.FC<RainbowFieldContainerProps> = ({
  children,
}) => {
  return (
    <Spacer
      orientation="vertical"
      space="8px"
      align="center"
      justify="space-between"
      className={block('rainbow-border-field-container')}
    >
      {children}
    </Spacer>
  );
};

export default RainbowFieldContainer;
