import bem from 'utils/bem';
import { TrackConfig } from './types';

export const block = bem('timeline');

export function positionToMillis(pos: number, pxPerSec: number) {
  return Math.round(positionToSec(pos, pxPerSec) * 1000);
}

export function millisToPosition(millis: number, pxPerSec: number) {
  return Math.round(secToPosition(millis / 1000, pxPerSec));
}

export function positionToSec(pos: number, pxPerSec: number) {
  return pos / pxPerSec;
}

export function secToPosition(sec: number, pxPerSec: number) {
  return sec * pxPerSec;
}

export function getNewTrackIds(
  oldTracks: TrackConfig[],
  newTracks: TrackConfig[],
): string[] {
  return newTracks.reduce((acc, nt) => {
    if (oldTracks.findIndex(ot => ot.id === nt.id) < 0) {
      acc.push(nt.id);
    }
    return acc;
  }, []);
}

export function canMoveTrack(
  tracks: TrackConfig[],
  insertBeforeIndex: number,
  sourceIndex: number,
): boolean {
  // audio track cannot be moved
  if (tracks[sourceIndex]?.type === 'audio') return false;

  // nothing can be placed below audio
  const audioTrackIndex = tracks.findIndex(t => t.type === 'audio');
  return insertBeforeIndex <= audioTrackIndex;
}
