import { Record, RecordOf } from 'immutable';

import { Action } from 'redux/types';
import { RequestStatus } from 'types';
import { PostStatus } from '../constants';
import { Type } from './action-types';

export interface LinkedinPostMessageData {
  tokenInfo: {
    accessToken: string;
    idToken: string;
    provider: 'linkedin';
    providerUserId: string;
  };
}

export interface LinkedinOrganization {
  id: string;
  name: string;
  logoImageUrl: string;
}

export interface LinkedinOrganizations {
  postStatus?: RequestStatus;
  data: LinkedinOrganization[];
}

export type OrganizationsState = RecordOf<LinkedinOrganizations>;

export type LinkedinAuthData = Pick<
  LinkedinPostMessageData['tokenInfo'],
  'accessToken' | 'idToken' | 'providerUserId'
>;

interface State extends LinkedinAuthData {
  accessToken: string;
  organizations: OrganizationsState;
  postStatus: PostStatus;
  profileImageUrl: string;
  socialShareId: number;
  username: string;
}

export type LinkedinState = RecordOf<State>;

export const linkedinOrganizationsFactory = Record<LinkedinOrganizations>({
  postStatus: undefined,
  data: undefined,
});

export const linkedinStateFactory = Record<State>({
  accessToken: undefined,
  organizations: linkedinOrganizationsFactory(),
  idToken: undefined,
  postStatus: undefined,
  profileImageUrl: undefined,
  providerUserId: undefined,
  socialShareId: undefined,
  username: undefined,
});

type GetLinkedinUserRequestAction = Action<Type.LINKEDIN_USER_DATA_GET_REQUEST>;
type GetLinkedinUserSuccessAction = Action<
  Type.LINKEDIN_USER_DATA_GET_SUCCESS,
  {
    username: string;
    profileImageUrl: string;
  }
>;
type GetLinkedinUserFailureAction = Action<Type.LINKEDIN_USER_DATA_GET_FAILURE>;

type ClearLinkedinUserAction = Action<Type.LINKEDIN_USER_DATA_CLEAR>;

type PostVideoRequestAction = Action<Type.LINKEDIN_VIDEO_POST_REQUEST>;
type PostVideoFailureAction = Action<Type.LINKEDIN_VIDEO_POST_FAILURE>;
type PostVideoSuccessAction = Action<
  Type.LINKEDIN_VIDEO_POST_SUCCESS,
  {
    socialShareId: number;
  }
>;

type GetLinkedinOrganizationsRequest = Action<
  Type.LINKEDIN_ORGANIZATIONS_GET_REQUEST
>;
type GetLinkedinOrganizationsFailure = Action<
  Type.LINKEDIN_ORGANIZATIONS_GET_FAILURE
>;
type GetLinkedinOrganizationsSuccess = Action<
  Type.LINKEDIN_ORGANIZATIONS_GET_SUCCESS,
  {
    data: [
      {
        id: 'string';
        name: 'string';
        logoImageUrl: 'string';
      },
    ];
  }
>;

type AwaitGetUserRequestAction = Action<
  Type.LINKEDIN_USER_DATA_GET_AWAIT_REQUEST
>;
type AwaitGetUserSuccessAction = Action<
  Type.LINKEDIN_USER_DATA_GET_AWAIT_SUCCESS
>;
type AwaitGetUserFailureAction = Action<
  Type.LINKEDIN_USER_DATA_GET_AWAIT_FAILURE
>;

type AwaitPostRequestAction = Action<Type.LINKEDIN_VIDEO_POST_AWAIT_REQUEST>;
type AwaitPostSuccessAction = Action<Type.LINKEDIN_VIDEO_POST_AWAIT_SUCCESS>;
type AwaitPostFailureAction = Action<Type.LINKEDIN_VIDEO_POST_AWAIT_FAILURE>;

export type LinkedinAuthorizeSuccessAction = Action<
  Type.LINKEDIN_AUTHORIZE_SUCCESS,
  LinkedinAuthData
>;

export type LinkedinAction =
  | GetLinkedinUserRequestAction
  | GetLinkedinUserSuccessAction
  | GetLinkedinUserFailureAction
  | GetLinkedinOrganizationsRequest
  | GetLinkedinOrganizationsFailure
  | GetLinkedinOrganizationsSuccess
  | ClearLinkedinUserAction
  | PostVideoRequestAction
  | PostVideoSuccessAction
  | PostVideoFailureAction
  | LinkedinAuthorizeSuccessAction
  | AwaitPostRequestAction
  | AwaitPostSuccessAction
  | AwaitPostFailureAction
  | AwaitGetUserRequestAction
  | AwaitGetUserSuccessAction
  | AwaitGetUserFailureAction;
