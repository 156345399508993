import * as React from 'react';
import { IconProps } from './types';

function ImageSpeechBubble(props: IconProps) {
  return (
    <svg
      width="110"
      height="100"
      viewBox="0 0 110 100"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="0%"
          y1="8.678%"
          x2="100%"
          y2="91.322%"
          id="uhicaaloja"
        >
          <stop stopColor="#5DBEFC" offset="0%" />
          <stop stopColor="#DD92FE" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M24 0h62c13.255 0 24 10.745 24 24v34.668c0 13.255-10.745 24-24 24H68.043a8 8 0 0 0-4.997 1.752L44.39 99.343A3 3 0 0 1 39.515 97V86.668a4 4 0 0 0-4-4H24c-13.255 0-24-10.745-24-24V24C0 10.745 10.745 0 24 0z"
          fill="url(#uhicaaloja)"
        />
        <path
          d="M81.431 17.15c5.738 0 10.421 4.615 10.569 10.363l-.011.28L92 54.881c.001 5.88-4.725 10.646-10.557 10.647l-53.874-.011c-5.83-.001-10.556-4.766-10.558-10.643L17 27.785c-.001-5.878 4.725-10.645 10.557-10.646l53.874.01zM40.325 38.174l1.15-2.32a.904.904 0 0 1 .404-.406l2.32-1.17a.904.904 0 0 0 0-1.615l-2.32-1.17a.904.904 0 0 1-.403-.405l-1.15-2.32a.904.904 0 0 0-1.621 0l-1.15 2.32a.904.904 0 0 1-.404.406l-2.32 1.17a.904.904 0 0 0 0 1.614l2.32 1.17c.175.088.316.23.403.405l1.15 2.32a.904.904 0 0 0 1.621 0zm-14.264 19.04 11.672-12.012a3 3 0 0 1 3.898-.348l8.651 6.195a3 3 0 0 0 3.962-.415l13.97-15.289a3 3 0 0 1 4.187-.236l11.003 9.604a3 3 0 0 0 4.973-2.26V28.436a7.35 7.35 0 0 0-7.35-7.35H28.259a7.35 7.35 0 0 0-7.35 7.35v26.687a3 3 0 0 0 5.152 2.091z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default ImageSpeechBubble;
