import React from 'react';
import { useSelector } from 'react-redux';
import { ExportSpeed } from 'components/icons';
import Infobox from 'components/Infobox';
import LinkButton from 'components/LinkButton';
import useContextualUpgrade from 'hooks/useContextualUpgrade';
import { isFreeSelector } from 'redux/modules/pricing';
import { block } from '../utils';

function VSBanner() {
  const isFree = useSelector(isFreeSelector);
  const { onUpgrade } = useContextualUpgrade();

  return (
    <Infobox
      className={block('banner')}
      icon={<ExportSpeed pro={!isFree} height={18} width={18} />}
      theme="gradient"
    >
      <span className={block('title')}>
        {isFree ? 'Normal Export Speed: ' : 'Priority Export Speed:'}
      </span>
      <span className={block('subtitle')}>
        {!isFree ? (
          'your video will get processed immediately!'
        ) : (
          <LinkButton onClick={onUpgrade} theme="cta">
            upgrade to get your video processed faster!
          </LinkButton>
        )}
      </span>
    </Infobox>
  );
}

export default VSBanner;
