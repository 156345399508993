import MultiCanvas from './MultiCanvas';

interface PluginParams {
  /** Set to true to manually call (default: false). */
  deferInit?: boolean;
}

class WheelScrollPlugin {
  private drawer: MultiCanvas;

  private wavesurfer: WaveSurfer;

  static create(params: PluginParams) {
    return {
      name: 'wheelscroll',
      deferInit: params?.deferInit ?? false,
      params,
      staticProps: {},
      instance: WheelScrollPlugin,
    };
  }

  constructor(_: PluginParams, ws: WaveSurfer) {
    this.wavesurfer = ws;

    this.handleReady = this.handleReady.bind(this);
    this.handleWheel = this.handleWheel.bind(this);
  }

  init() {
    // Check if ws is ready
    if (this.wavesurfer.isReady) {
      this.handleReady();
    } else {
      this.wavesurfer.once('ready', this.handleReady);
    }
  }

  destroy() {
    this.wavesurfer.un('ready', this.handleReady);
    if (this.drawer) {
      this.drawer.wrapper.removeEventListener('wheel', this.handleWheel);
    }
  }

  handleReady() {
    this.drawer = this.wavesurfer.drawer;
    this.drawer.wrapper.addEventListener('wheel', this.handleWheel);
  }

  handleWheel(event: WheelEvent) {
    const { deltaX, deltaY } = event;
    const delta = deltaX || deltaY;

    event.preventDefault();

    const scrollX = this.drawer.getScrollX();
    this.drawer.setScrollX(scrollX + delta);
  }
}

export default WheelScrollPlugin;
