import * as React from 'react';
import { connect } from 'react-redux';

import { State } from 'redux/types';
import { ChangeAspectRatioModalProps } from '.';

type StateProps = Pick<ChangeAspectRatioModalProps, 'selectedAspectRatio'>;

type OwnProps = Pick<ChangeAspectRatioModalProps, 'selectedAspectRatio'>;

const mapStateToProps = (_: State, props: OwnProps): StateProps => ({
  selectedAspectRatio: props.selectedAspectRatio,
});

export default function(
  component: React.ComponentType<ChangeAspectRatioModalProps>,
) {
  return connect(mapStateToProps)(component);
}
