import { IEmbedConfig, MediaContainer } from 'types';
import { getValue } from 'utils/collections';

export const DEFAULT_MEDIA_CONTAINER_OPTIONS: Readonly<MediaContainer> = {
  style: {
    backgroundColor: '#000000',
  },
};

export function formatMediaContainerForConfig(canvasColor: string) {
  if (!canvasColor) return DEFAULT_MEDIA_CONTAINER_OPTIONS;

  return {
    ...DEFAULT_MEDIA_CONTAINER_OPTIONS,
    style: {
      backgroundColor: canvasColor,
    },
  };
}

export function formatMediaContainerFromConfig(mediaContainer: MediaContainer) {
  const canvasColor = getValue(mediaContainer, ['style', 'backgroundColor']);
  return { canvasColor };
}

export function getCanvasColorFromConfig(config: IEmbedConfig) {
  return getValue(config, [
    'embedConfig',
    'mainMediaContainer',
    'style',
    'backgroundColor',
  ]);
}
