import * as React from 'react';

import { useDispatch } from 'react-redux';
import { SubscriptionPeriod } from 'blocks/Pricing/types';
import { SwitchField } from 'components/Form';
import { PlanName, Tier } from 'redux/middleware/api/plan-service';
import { hideModal, pushModal } from 'redux/modules/modal';
import { DowngradeIntent } from 'redux/modules/modal/types';
import { Callouts, PlanDecisionModalBody } from '../PlanDecisionModal';
import LegacyDowngradeModalCta from './LegacyDowngradeModalCta';
import { block } from './utils';

const { useCallback, useState } = React;

export interface LegacyDowngradeModalContentsProps {
  intent?: DowngradeIntent;
}

const LegacyDowngradeModalContents: React.FC<LegacyDowngradeModalContentsProps> = ({
  intent,
}) => {
  const dispatch = useDispatch();
  const [subscriptionPeriod, setSubscriptionPeriod] = useState<
    SubscriptionPeriod
  >('monthly');

  const handleCancelClick = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    dispatch(hideModal());

    if (intent === 'downgrade') {
      // the LegacyDowngradeModal is only shown for OG Pro users (see /redux/modules/pricing/actions legacyDowngradeGuard)
      // the only downgrade path is to downgrade to basic
      dispatch(
        pushModal({
          name: 'PlanDowngradeModal',
          params: {
            toTier: Tier.BASIC,
          },
        }),
      );
    }
  }, [dispatch, intent]);

  return (
    <PlanDecisionModalBody
      headerClassName={block('header')}
      heading="Give up your special discount?"
      subheading="Your plan is part of our legacy pricing, if you cancel you will lose your special discount."
    >
      <SwitchField
        className={block('period')}
        left="monthly"
        onChange={setSubscriptionPeriod as any}
        right="yearly"
        value={subscriptionPeriod}
      />
      <Callouts>
        <LegacyDowngradeModalCta
          ctaLabel="keep my discount"
          onClick={handleCancelClick}
          period={subscriptionPeriod}
          cost={{
            monthly: PlanName.PRO_MONTHLY,
            yearly: PlanName.PRO_YEARLY,
          }}
          pro
          theme="pro-upsell"
          title="Your Price"
        />
        <LegacyDowngradeModalCta
          ctaLabel="give up my discount"
          onClick={handleSubmit}
          period={subscriptionPeriod}
          cost={{
            monthly: PlanName.PRO_20211112_MONTHLY,
            yearly: PlanName.PRO_20211112_YEARLY,
          }}
          theme="muted"
          title="Normal Price"
        />
      </Callouts>
    </PlanDecisionModalBody>
  );
};

export default LegacyDowngradeModalContents;
