import * as React from 'react';
import AspectRatioModalContents from 'containers/AspectRatioModalContents/AspectRatioModalContents';
import ConnectedModal from 'containers/ConnectedModal';

const BlankProjectModal: React.FC = () => {
  return (
    <ConnectedModal
      name="BlankProject"
      title="choose project aspect ratio"
      className="aspect-modal"
    >
      <AspectRatioModalContents
        modalName="BlankProject"
        shouldRedirectOnSelect
      />
    </ConnectedModal>
  );
};

export default BlankProjectModal;
