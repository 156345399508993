import { Button, Spacer } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';

import AutomationPostModalBackButton from 'components/AutomationPostModalBackButton';
import DynamicElementsPopper from 'components/DynamicElementsPopper';
import EducationalCard from 'components/EducationalCard/EducationalCard';
import ModalContentWithMedia from 'components/Modal/ModalContentWithMedia';
import { Step } from 'components/SteppedModal';
import { captionsMetadata, MAX_CHARS_CAPTIONS } from '../../constants';
import { block } from '../../utils';
import CaptionsInput from './CaptionsInput';

interface UseCaptionsStepProps {
  captions: string;
  isActive: boolean;
  readonly?: boolean;
  onCaptionsChange: (captions: string) => void;
  onBackClick: () => void;
  onHideModal: () => void;
}

export default function useCaptionsStep({
  captions,
  isActive,
  readonly,
  onCaptionsChange,
  onBackClick,
  onHideModal,
}: UseCaptionsStepProps): Step {
  const [defaultCaptions, setDefaultCaptions] = useState(captions);

  const handleBackClick = useCallback((): void => {
    onCaptionsChange(defaultCaptions);
    onBackClick();
  }, [defaultCaptions, onBackClick, onCaptionsChange]);

  if (!isActive && captions !== defaultCaptions) {
    setDefaultCaptions(captions);
  }

  return {
    id: 'captions',
    hideFooter: true,
    replaceBody: true,
    component: (
      <>
        <ModalContentWithMedia
          onClose={onHideModal}
          className="composer-step-content"
        >
          <ModalContentWithMedia.ModalContentWithMediaColumn>
            <img
              src="https://static.sparemin.com/static/webapp-assets/auto-posting-detail/auto-posting-detail-tik-tok-image-b-202403191010.png"
              className={block('composer-image-left')}
            />
          </ModalContentWithMedia.ModalContentWithMediaColumn>

          <ModalContentWithMedia.ModalContentWithMediaColumn className="composer-content-column">
            <Spacer
              orientation="vertical"
              align="center"
              justify="center"
              space={2.5}
              className={block('step-container')}
            >
              <AutomationPostModalBackButton
                text="Captions"
                description={`Format how captions post with each video. You can add up to ${MAX_CHARS_CAPTIONS} characters.`}
                onClick={handleBackClick}
              />

              <CaptionsInput
                onChange={onCaptionsChange}
                readonly={readonly}
                value={captions}
                placeholder="Add captions"
                showLabel={false}
                hideProgress
              />

              <EducationalCard>
                <DynamicElementsPopper
                  buttonContent="add content from your rss"
                  elementsList={captionsMetadata}
                  onClick={metaValue =>
                    onCaptionsChange(`${captions} ${metaValue}`)
                  }
                />
              </EducationalCard>

              <Button
                onClick={onBackClick}
                variant="solid"
                className={block('composer-step-submit-button')}
              >
                save changes
              </Button>
            </Spacer>
          </ModalContentWithMedia.ModalContentWithMediaColumn>
        </ModalContentWithMedia>
      </>
    ),
  };
}
