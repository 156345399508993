import React from 'react';

import DialogContent from 'components/DialogContent';
import { H2 } from 'components/Heading';
import { HeadphonesHeady } from 'components/icons';
import { educationModalBlock as block } from '../../utils';

export interface ClipSelectEducationModalContentsProps {}

const ClipSelectEducationModalContents: React.FC<ClipSelectEducationModalContentsProps> = () => (
  <DialogContent
    className={block('contents')}
    actions={[
      {
        children: 'got it',
        key: 'confirm',
        theme: 'submit',
      },
    ]}
    icon={<HeadphonesHeady />}
    title="How we select clips"
    details={
      <H2>
        Our Headliner AI <em>listens</em> to each episode in search of the most
        representative parts, while trying to avoid things like ads and filler
        words.
      </H2>
    }
  />
);

export default ClipSelectEducationModalContents;
