import * as React from 'react';

import { DurationField, DurationFieldProps } from 'components/Form';
import { Omit } from 'types';
import SlideOptionsField from './SlideOptionsField';

type Props = Omit<DurationFieldProps, 'label'>;

const SlideDurationField: React.SFC<Props> = ({ className, ...props }) => (
  <SlideOptionsField className={className}>
    <DurationField {...props} label="Duration" />
  </SlideOptionsField>
);

export default SlideDurationField;
export { Props as SlideDurationFieldProps };
