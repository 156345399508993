import * as React from 'react';
import { checkIfDestinationPresetExists } from 'blocks/AudiogramDestinations/destinationPresets/utils';
import { AudiogramDestination } from 'blocks/AudiogramDestinations/types';
import {
  DestinationSteps,
  DestinationStepView,
} from 'blocks/AutomationOptions/types';
import { H1, H2 } from 'components/Heading';
import {
  FacebookButton,
  InstagramButton,
  LinkedInButton,
  ThreadsButton,
  TikTokButton,
  TwitterButton,
  YouTubeButton,
} from 'components/SocialButton';
import { stepsBlock as block } from './utils';

export interface StepsProps {
  createChangeHandler: (
    newPlatform: AudiogramDestination | null,
    shouldSelectVideoTypes: boolean,
    nextStepView?: DestinationStepView,
  ) => () => void;
}

const Steps: React.FC<StepsProps> = props => {
  const { createChangeHandler } = props;

  return (
    <>
      <div className={block('headers')}>
        <H1 className={block('title')}>Choose a destination</H1>
        <H2 className={block('subtitle')}>
          Where would you like to promote your podcast?
        </H2>
      </div>

      <div className={block('steps-container')}>
        <YouTubeButton
          onClick={createChangeHandler(
            'youtube',
            checkIfDestinationPresetExists('youtube'),
          )}
        />
        <InstagramButton
          onClick={createChangeHandler(
            'instagram',
            checkIfDestinationPresetExists('instagram'),
          )}
        />
        <FacebookButton
          onClick={createChangeHandler(
            'facebook',
            checkIfDestinationPresetExists('facebook'),
          )}
        />
        <TikTokButton
          onClick={createChangeHandler(
            'tiktok',
            checkIfDestinationPresetExists('tiktok'),
          )}
        />
        <LinkedInButton
          onClick={createChangeHandler(
            'linkedin',
            checkIfDestinationPresetExists('linkedin'),
            DestinationSteps.AUTO_POSTING,
          )}
        />
        <ThreadsButton
          onClick={createChangeHandler(
            'threads',
            checkIfDestinationPresetExists('threads'),
            DestinationSteps.AUTO_POSTING,
          )}
        />
        <TwitterButton
          onClick={createChangeHandler(
            'twitter',
            checkIfDestinationPresetExists('twitter'),
            DestinationSteps.AUTO_POSTING,
          )}
        />
      </div>
    </>
  );
};

export default Steps;
