import { createSelector } from 'reselect';

import { omit } from 'underscore';
import { userNameSelector } from 'redux/modules/auth';
import { defaultVideoExportSettingsSelector } from 'redux/modules/display-pref';
import { embedConfigSelector } from 'redux/modules/embed/selectors';
import { videoExportEmailEnabledSelector } from 'redux/selectors';
import { omitUndefined } from 'utils/collections';

import { FormState, FormStateDefaultPartials } from './types';

export const emailAddressSelector = createSelector(
  [userNameSelector, videoExportEmailEnabledSelector],
  (username, emailEnabled) => (!emailEnabled ? undefined : username),
);

export const defaultFormStateSelector = createSelector(
  [
    emailAddressSelector,
    defaultVideoExportSettingsSelector,
    embedConfigSelector,
  ],
  (email, defaultSettings, embedConfiguration) => {
    const defaultPartials = Object.keys(defaultSettings).reduce(
      (acc, key) => {
        acc[key] = defaultSettings[key].value;
        return acc;
      },
      { email: [email] } as FormStateDefaultPartials,
    );

    // Base default are built by omitting preferences intro/outro options, they will be filled
    // on a second step
    const defaults: FormState = omit(defaultPartials, ['intro', 'outro']);

    // When embedConfig object contains edgeVideos, it can be assumed that it was set (either)
    // as empty or with content.
    const isSet = !!embedConfiguration?.embedConfig?.edgeVideos;

    // Intro/outro is obtained from embed configuration
    const embedIntro = embedConfiguration?.embedConfig?.edgeVideos?.intro;
    const embedOutro = embedConfiguration?.embedConfig?.edgeVideos?.outro;

    // For setting the default Form State:
    // - if it is set by embed config, both embed intro/outro embed config values are used and
    // type is set to id or file depending on if the field has any content (file when empty).
    // - if it is not set by embed config, prefs default value is used and type is set to url
    // depending on if the default pref value exists or not (file if it does not exist)
    if (isSet) {
      defaults.intro = {
        type: embedIntro?.videoId ? 'id' : 'file',
        value: embedIntro?.videoId,
      };
      defaults.outro = {
        type: embedOutro?.videoId ? 'id' : 'file',
        value: embedOutro?.videoId,
      };
    } else {
      defaults.intro = {
        type: defaultPartials?.intro ? 'url' : 'file',
        value: defaultPartials?.intro,
      };
      defaults.outro = {
        type: defaultPartials?.outro ? 'url' : 'file',
        value: defaultPartials?.outro,
      };
    }

    return omitUndefined(defaults);
  },
);
