import * as React from 'react';

import LockOverlay from 'components/LockOverlay';
import TextTransitionOption from './TextTransitionOption';
import { TextTransitionSelectProps } from './types';
import { block } from './utils';

const TextTransitionSelect: React.SFC<TextTransitionSelectProps> = ({
  value,
  options,
  onChange,
  disabled,
  direction,
  defaultValue,
}) => (
  <div className={block()}>
    <div className={block('inner', { locked: !!defaultValue })}>
      {Object.values(options).map(v => (
        <TextTransitionOption
          key={v}
          selected={value === v}
          onSelect={onChange}
          value={v}
          direction={direction}
          disabled={disabled}
        />
      ))}
    </div>

    {defaultValue && <LockOverlay />}
  </div>
);

TextTransitionSelect.defaultProps = {
  defaultValue: '',
  disabled: false,
  value: undefined,
};

export default TextTransitionSelect;
