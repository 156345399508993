import cn from 'classnames';
import * as React from 'react';

import SlideAttributionXField from './SlideAttributionXField';
import SlideAttributionYField from './SlideAttributionYField';
import SlideBlurField from './SlideBlurField';
import SlideCropField from './SlideCropField';
import SlideDurationField from './SlideDurationField';
import SlideEffectField from './SlideEffectField';
import SlideEndTimeField from './SlideEndTimeField';
import SlideStartTimeField from './SlideStartTimeField';
import SlideTransitionField from './SlideTransitionField';

import { block, isSlideOptionsChild } from './utils';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

export default class SlideOptions extends React.Component<IProps> {
  public static AttributionX = SlideAttributionXField;
  public static AttributionY = SlideAttributionYField;
  public static Duration = SlideDurationField;
  public static Effect = SlideEffectField;
  public static EndTime = SlideEndTimeField;
  public static StartTime = SlideStartTimeField;
  public static Transition = SlideTransitionField;
  public static AdjustCrop = SlideCropField;
  public static Blur = SlideBlurField;

  public render() {
    const { children, className, disabled } = this.props;

    return (
      <form className={cn(block(), className)}>
        {React.Children.map(children, (child: React.ReactElement) =>
          !isSlideOptionsChild(child)
            ? child
            : React.cloneElement(child, {
                disabled: disabled || child.props.disabled,
              }),
        )}
      </form>
    );
  }
}

export { IProps as SlideOptionsProps };
