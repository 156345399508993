import React from 'react';
import { XSocialCircle } from 'components/SocialCircle';
import SocialButton, { SocialButtonProps } from './SocialButton';

export interface TwitterButtonProps
  extends Pick<SocialButtonProps, 'disabled' | 'onClick' | 'params' | 'size'> {}

const TwitterButton: React.FC<TwitterButtonProps> = ({
  disabled,
  onClick,
  size,
}) => (
  <SocialButton
    icon={<XSocialCircle />}
    label="X (Twitter)"
    {...{ disabled, onClick, size }}
  />
);

export default TwitterButton;
