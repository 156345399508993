import { isNumber } from 'underscore';

import Pixels from './Pixels';
import { MeasurementContext, Unit } from './types';
import ViewportHeight from './ViewportHeight';
import ViewportWidth from './ViewportWidth';

export default abstract class Measurement<
  U extends Unit = any,
  M extends Measurement<any, any> = any
> {
  protected static getContextValue(
    context: MeasurementContext,
    dimension: 'height' | 'width',
  ) {
    if (!context) return undefined;
    const value =
      typeof context === 'number' || context instanceof Pixels
        ? context
        : context[dimension];

    return typeof value === 'number' ? value : value.value;
  }

  constructor(public value: number, public unit: U) {
    // constructor
  }

  protected abstract update(value: number): M;

  public abstract toUnit(
    unit: 'vh',
    context: MeasurementContext,
  ): ViewportHeight;
  public abstract toUnit(
    unit: 'vw',
    context: MeasurementContext,
  ): ViewportWidth;
  public abstract toUnit(unit: 'px', context: MeasurementContext): Pixels;
  public abstract toUnit(unit: Unit, context: MeasurementContext): any;

  public plus(other: Measurement<any, any> | number): M {
    if (isNumber(other)) {
      return this.update(this.value + other);
    }

    if (other.unit !== this.unit) {
      throw new Error(`cannot add ${other} to ${this}`);
    }

    return this.update(this.value + other.value);
  }

  public minus(other: Measurement<any, any> | number): M {
    if (isNumber(other)) {
      return this.update(this.value - other);
    }

    if (other.unit !== this.unit) {
      throw new Error(`cannot subtract ${other} from ${this}`);
    }

    return this.update(this.value - other.value);
  }

  public times(other: Measurement<any, any> | number): M {
    if (isNumber(other)) {
      return this.update(this.value * other);
    }

    if (other.unit !== this.unit) {
      throw new Error(`cannot multiply ${other} and ${this}`);
    }

    return this.update(this.value * other.value);
  }

  public divideBy(other: Measurement<any, any> | number): M {
    if (isNumber(other)) {
      return this.update(this.value / other);
    }

    if (other.unit !== this.unit) {
      throw new Error(`cannot divide ${this} by ${other}`);
    }

    return this.update(this.value / other.value);
  }

  private isEq(other: number, margin: number) {
    const diff = Math.abs(this.value - other);
    return diff <= margin;
  }

  public eq(
    other: Measurement<any, any> | number,
    margin: number = 0,
  ): boolean {
    if (other === null || other === undefined) {
      return false;
    }

    if (isNumber(other)) {
      return this.isEq(other, margin);
    }

    // 0 can be compared to anything.  0 === 0px === 0vw === 0vh
    if (other.value === 0 || this.value === 0) {
      return this.isEq(other.value, margin);
    }

    if (other.unit !== this.unit) {
      throw new Error(`cannot compare ${other} to ${this}`);
    }

    return this.isEq(other.value, margin);
  }

  public toString() {
    return `${this.value}${this.unit}`;
  }

  public toJSON() {
    return this.toString();
  }
}
