import React from 'react';
import ShareFormTextarea, {
  ShareFormTextareaProps,
} from 'components/ShareForm/ShareFormTextarea';
import { MAX_CHARS_CAPTIONS } from '../../constants';

export interface CaptionsInputProps
  extends Pick<ShareFormTextareaProps, 'onChange' | 'readonly' | 'value'> {
  showLabel?: boolean;
  hideProgress?: boolean;
  placeholder?: string;
}

const CaptionsInput: React.FC<CaptionsInputProps> = ({
  showLabel = true,
  hideProgress,
  placeholder,
  onChange,
  readonly,
  value,
}) => (
  <ShareFormTextarea
    hideProgress={readonly || hideProgress}
    label={showLabel ? 'Video captions' : null}
    maxChars={MAX_CHARS_CAPTIONS}
    type="input"
    {...{ onChange, readonly, value, placeholder }}
  />
);

export default CaptionsInput;
