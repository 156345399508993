import { Tooltip } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';

import { Option } from 'components/Select';

import { block } from '../utils';
import { TikTokVisibilityOption as TikTokVisibilityOptionType } from './types';

export interface TikTokVisibilityOptionProps {
  option: TikTokVisibilityOptionType;
  selected?: boolean;
  className?: string;
  onClick?: (value: Option) => void;
}

const TikTokVisibilityOption: React.FunctionComponent<TikTokVisibilityOptionProps> = props => {
  const { className, option, onClick, selected } = props;
  const { label, tooltipConfig } = option || {};

  const handleClick = React.useCallback(() => onClick(option), [
    onClick,
    option,
  ]);

  return (
    <Tooltip arrow {...tooltipConfig}>
      <div
        className={cn(block('option', { selected }), className)}
        onClick={handleClick}
      >
        <span className={block('option-label')}>{label}</span>
      </div>
    </Tooltip>
  );
};

export default TikTokVisibilityOption;
