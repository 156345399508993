export const DEFAULT_SQUARE_CAPTIONS_OVERRIDES = {
  containerStyle: {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'Open Sans',
    fontSize: '4.736842105263158vw',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.2,
    textAlign: 'center',
    textDecoration: 'none',
    width: '80vw',
  },
  editor: {
    styleContext: {
      fontSize: 18,
      textBoxHeight: 43.199999999999996,
      viewport: { height: 380, width: 380 },
    },
  },
  region: {
    type: 'absolute',
    properties: { top: '75vh', left: '10vw' },
  },
  textBoxHeight: 43.199999999999996,
  textStyle: {
    background: 'rgba(0, 0, 0, 0.7)',
    boxDecorationBreak: 'clone',
    paddingLeft: '0',
    paddingRight: '0',
    WebkitBoxDecorationBreak: 'clone',
  },
};

export const DEFAULT_PORTRAIT_CAPTIONS_OVERRIDES = {
  containerStyle: {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'Open Sans',
    fontSize: '7.43801652892562vw',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.2,
    textAlign: 'center',
    textDecoration: 'none',
    width: '80vw',
  },
  editor: {
    styleContext: {
      fontSize: 18,
      textBoxHeight: 43.199999999999996,
      viewport: { height: 430.22, width: 242 },
    },
  },
  region: {
    type: 'absolute',
    properties: { top: '75vh', left: '10vw' },
  },
  textBoxHeight: 43.199999999999996,
  textStyle: {
    background: 'rgba(0, 0, 0, 0.7)',
    boxDecorationBreak: 'clone',
    paddingLeft: '0',
    paddingRight: '0',
    WebkitBoxDecorationBreak: 'clone',
  },
};

export const DEFAULT_LANDSCAPE_CAPTIONS_OVERRIDES = {
  containerStyle: {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'Open Sans',
    fontSize: '2.34375vw',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.2,
    textAlign: 'center',
    textDecoration: 'none',
    width: '80vw',
  },
  editor: {
    styleContext: {
      fontSize: 18,
      textBoxHeight: 43.199999999999996,
      viewport: { height: 432, width: 768 },
    },
  },
  region: {
    type: 'absolute',
    properties: { top: '75vh', left: '10vw' },
  },
  textBoxHeight: 43.199999999999996,
  textStyle: {
    background: 'rgba(0, 0, 0, 0.7)',
    boxDecorationBreak: 'clone',
    paddingLeft: '0',
    paddingRight: '0',
    WebkitBoxDecorationBreak: 'clone',
  },
};

export const BASE_CAPTION_OVERRIDES_MAP = {
  landscape: DEFAULT_LANDSCAPE_CAPTIONS_OVERRIDES,
  portrait: DEFAULT_PORTRAIT_CAPTIONS_OVERRIDES,
  square: DEFAULT_SQUARE_CAPTIONS_OVERRIDES,
};
