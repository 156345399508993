import classnames from 'classnames';
import React from 'react';

import Microphone from '../Microphone';
import CircularGradient, { CircularGradientProps } from './CircularGradient';
import { block } from './utils';

export interface CircularGradientMicrophoneProps
  extends Pick<CircularGradientProps, 'className'> {
  iconClassName?: string;
}

const CircularGradientMicrophone: React.FC<CircularGradientMicrophoneProps> = ({
  className,
  iconClassName,
}) => (
  <CircularGradient className={className}>
    <Microphone
      className={classnames(block('microphone-icon'), iconClassName)}
      strokeWidth={1}
      style={{
        height: 'auto',
        width: '30%',
      }}
      viewBox="0 0 10 15"
    />
  </CircularGradient>
);

export default CircularGradientMicrophone;
