import * as React from 'react';
import { connect } from 'react-redux';

import { uploadEntireAudio } from 'redux/modules/automated-wizard';
import { Dispatch } from 'redux/types';
import UseAudioUpload, {
  UseAudioUploadProps as Props,
} from '../UseAudioUpload';

type DispatchProps = Pick<Props, 'onAudioAdded'>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onAudioAdded: (src, metadata) => {
    if (src) {
      return dispatch(uploadEntireAudio(src, metadata));
    }
    return Promise.resolve();
  },
});

const component = connect(null, mapDispatchToProps)(UseAudioUpload);
export type UseAudioUploadProps = React.ComponentPropsWithoutRef<
  typeof component
>;
export default component;
