export default class Caret {
  private element: HTMLInputElement;

  constructor(element: HTMLInputElement) {
    this.element = element;
  }

  public get start() {
    return this.element.selectionStart;
  }

  public get end() {
    return this.element.selectionEnd;
  }

  public set(start: number, end: number = start) {
    this.element.setSelectionRange(start, end);
  }
}
