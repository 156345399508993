import * as React from 'react';

import FormInput, { FormInputProps } from 'components/FormInput';
import bem from 'utils/bem';

export interface AuthFormInputProps extends FormInputProps {
  optional?: boolean;
}

const block = bem('auth-input');

const AuthFormInput: React.SFC<AuthFormInputProps> = ({
  label,
  optional,
  ...props
}) => (
  <FormInput
    bsSize="md"
    className={block()}
    controlClassName={block('control')}
    label={
      !optional ? (
        label
      ) : (
        <>
          {label}
          <span className={block('optional')}>optional</span>
        </>
      )
    }
    labelClassName={block('label')}
    horizontal={false}
    {...props}
  />
);

AuthFormInput.defaultProps = {
  optional: false,
};

export default AuthFormInput;
