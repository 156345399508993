import { Heading, Spacer } from '@sparemin/blockhead';
import React from 'react';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import { block } from './utils';

export interface EddyToolProps {
  icon: React.ReactNode;
  description: React.ReactNode;
}

const EddyTool: React.FC<EddyToolProps> = ({ icon, description }) => {
  return (
    <Spacer orientation="vertical" align="center" className={block('tool')}>
      <CircularGradient className={block('tool-icon')}>{icon}</CircularGradient>

      <Heading level={2} className={block('tool-text')}>
        {description}
      </Heading>
    </Spacer>
  );
};

export default EddyTool;
