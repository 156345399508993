import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getUserTemplateCompatibility } from 'redux/middleware/api/headliner-user-service';
import { Dispatch } from 'redux/types';
import { TemplateType } from 'types';
import { OnStateChange } from '../types';
import useTemplateDispatch from './useTemplateDispatch';

interface UseTemplateCompaibilityCheck {
  onChange?: OnStateChange;
}

export default function useTemplateCompatibilityCheck(
  props: UseTemplateCompaibilityCheck,
) {
  const { onChange } = props;

  const templateDispatch = useTemplateDispatch({ onChange });
  const dispatch = useDispatch<Dispatch>();

  const handleUpdateUCSCompatibility = useCallback(
    isCompatible => {
      templateDispatch({
        payload: isCompatible,
        type: 'TEMPLATE_UCS_COMPATIBILITY',
      });
    },
    [templateDispatch],
  );

  const handleUCSCompatibilityLoading = useCallback(
    isLoading => {
      templateDispatch({
        payload: isLoading,
        type: 'TEMPLATE_UCS_COMPATIBILITY_LOADING',
      });
    },
    [templateDispatch],
  );

  const checkTemplateCompatibility = useCallback(
    async (templateId: string, templateType: TemplateType): Promise<void> => {
      if (templateType !== 'userGenerated') {
        handleUpdateUCSCompatibility(true);

        return;
      }

      handleUCSCompatibilityLoading(true);

      const {
        response: {
          ucsEditor: { isCompatible },
        },
      } = await dispatch(getUserTemplateCompatibility(templateId));

      handleUCSCompatibilityLoading(false);
      handleUpdateUCSCompatibility(isCompatible);
    },
    [dispatch, handleUCSCompatibilityLoading, handleUpdateUCSCompatibility],
  );

  return {
    checkTemplateCompatibility,
  };
}
