import { List, Record, RecordOf } from 'immutable';

import { PageData } from 'redux/middleware/api/podcast-service/types';
import { omitUndefined } from 'utils/collections';
import { withValue } from 'utils/control';
import { reviveRecord } from 'utils/immutable';
import { DescriptState, State } from './types';

export const queryFactory = Record<PageData>({
  nextOffset: undefined,
  offset: undefined,
  pageSize: undefined,
  q: undefined,
  totalResults: undefined,
});

export const descriptFactory = Record<DescriptState>({
  audioUrl: undefined,
  transcriptUrl: undefined,
});

export const stateFactory = Record<State>({
  descript: undefined,
  episodeListScrollOffset: 0,
  isLoadingEpisodes: true,
  isLoadingPodcasts: false,
  nextEpisodePublishMillis: undefined,
  podcastListScrollOffset: 0,
  query: undefined,
  results: undefined,
  searchHandlerIntegration: undefined,
  searchTerm: undefined,
  selectedEpisodeId: undefined,
  selectedPodcastId: undefined,
  favorites: undefined,
});

export function stateFromJs({
  descript,
  query,
  favorites,
  ...rest
}: any = {}): RecordOf<State> {
  return reviveRecord(
    stateFactory,
    omitUndefined({
      descript: withValue(descript, descriptFactory),
      query: withValue(query, queryFactory),
      favorites: withValue(favorites, List),
      ...rest,
    }),
  );
}
