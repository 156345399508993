// eslint-disable-next-line import/order
import * as tinymce from 'tinymce';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/paste';
import 'tinymce/themes/modern';
import '../../tinymce/text-shadow';

import classNames from 'classnames';
import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import { compose, identity, isFunction, isUndefined, noop } from 'underscore';

import { DefaultTextToolbar } from 'components/TextToolbar';
import { Alignment } from 'components/TextToolbar/TextAlignSelector';
import { getValue } from 'utils/collections';
import { fontList } from 'utils/fonts';
import * as utils from './tiny-utils';
import { Fonts, ITextShadow } from './types';

type ToggleStyle = 'bold' | 'italic' | 'underline';

interface IFormatter<T, S> {
  toolbarToStyle?: (toolbarVal: T) => S;
  styleToToolbar?: (styleVal: S) => T;
}

interface IFormatters {
  fontsize?: IFormatter<number, number>;
}

interface IProps {
  bodyClassName?: string;
  className?: string;
  content?: string;
  fonts?: Fonts;
  placeholderContent?: string;
  defaultContent?: string;
  /**
   * "default" to indicate that it is only applied when tinymce initializes and changes to the
   * iframe style are not applied as props update
   */
  defaultIframeStyle?: React.CSSProperties;
  disableIframe?: boolean;
  formatters: IFormatters;
  onBlur?: () => void;
  onContentChange?: (content: string) => void;
  onEmojiSelect?: (emoji: string) => void;
  onInit?: (editor: RichTextEditor) => void;
  onPresetTargetStyleChanged?: () => void;
  defaultStyle?: React.CSSProperties;
  defaultTextStyle?: React.CSSProperties;
  showEmoji?: boolean;
  target?: Element;
}

export interface IData {
  hilitecolor?: string;
  bold?: boolean;
  forecolor?: string;
  fontname?: string;
  fontsize?: number;
  italic?: boolean;
  justify: Alignment;
  textshadow?: RecordOf<ITextShadow>;
  underline?: boolean;
}

interface IState {
  data: RecordOf<IData>;
}

const textShadowFactory = Record<ITextShadow>({
  blur: undefined,
  color: undefined,
  x: undefined,
  y: undefined,
});

const dataFactory = Record<IData>({
  bold: false,
  fontname: undefined,
  fontsize: undefined,
  forecolor: undefined,
  hilitecolor: undefined,
  italic: false,
  justify: undefined,
  textshadow: textShadowFactory(),
  underline: false,
});

type SetSelectedValue = <K extends keyof IData>(
  key: K,
  value: IData[K] | ((current: IData[K]) => IData[K]),
  cb?: () => void,
) => void;

export default class RichTextEditor extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    disableIframe: false,
    fonts: fontList,
    formatters: {},
    onBlur: noop,
    onEmojiSelect: noop,
    onInit: noop,
    placeholderContent: '',
    showEmoji: true,
  };

  private editor: tinymce.Editor;
  private toolbar: DefaultTextToolbar;

  constructor(props: IProps) {
    super(props);
    this.state = { data: dataFactory() };
  }

  public componentDidMount() {
    const { target } = this.props;
    if (target) {
      this.initializeTinymce(target);
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const { content, disableIframe, placeholderContent, target } = this.props;
    const {
      content: nextContent,
      disableIframe: nextDisableIframe,
      target: nextTarget,
    } = nextProps;

    if (!target && nextTarget) {
      this.initializeTinymce(nextTarget);
    }

    if (target && !nextTarget) {
      this.destroyEditor();
    }

    if (!disableIframe && nextDisableIframe) {
      this.setIframeEnabled(false);
      this.withEditor(ed => ed.off('NodeChange', this.handleNodeChange));
    }

    if (disableIframe && !nextDisableIframe) {
      this.setIframeEnabled(true);
      this.withEditor(ed => ed.on('NodeChange', this.handleNodeChange));
    }

    if (nextContent !== content) {
      this.withEditor(editor => {
        utils.setEditorContent(editor, nextContent);
        utils.setDefaultContent(editor, placeholderContent);
      });
    }
  }

  public componentWillUnmount() {
    this.destroyEditor();
  }

  private handleFontChange = (name: string) => {
    const { fonts } = this.props;
    const font = fonts.find(f => f.name === name);

    if (!font) return;

    this.setStyle('fontname', font.family);
    this.setSelectedValue('fontname', name);
    this.onPresetTargetStyleChange();
  };

  private handleFontSizeChange = (size: number) => {
    this.setSelectedValue('fontsize', size);
    this.formatAndSetStyle('fontsize', size);
  };

  private handleFontColorChange = (color: string) => {
    const { data } = this.state;

    this.setSelectedValue('forecolor', color);

    if (color !== data.forecolor) {
      this.setStyle('forecolor', color);
    }
  };

  private handleTextHighlightChange = (color: string) => {
    const { data } = this.state;

    this.setSelectedValue('hilitecolor', color);

    if (color !== data.hilitecolor) {
      this.setStyle('hilitecolor', color);
    }

    this.onPresetTargetStyleChange();
  };

  private handleTextShadowChange = (shadow: ITextShadow) => {
    this.setSelectedValue('textshadow', current => current.merge(shadow));
    this.setStyle('textshadow', shadow);
    this.onPresetTargetStyleChange();
  };

  private handleJustifyChange = (value: Alignment) => {
    const { data } = this.state;

    if (value === data.justify) return;
    this.setStyle(`justify${value}`, true);
    this.setSelectedValue('justify', value);
  };

  private handleToggleValue = (key: ToggleStyle) => () => {
    this.setSelectedValue(key, enabled => {
      const newValue = !enabled;
      this.setStyle(key, newValue);
      return newValue;
    });
    this.onPresetTargetStyleChange();
  };

  private handleEmojiSelect = (emoji: string) => {
    const { disableIframe, onEmojiSelect } = this.props;
    this.withEditor(editor => {
      if (disableIframe) {
        utils.appendContent(editor, emoji);
      } else {
        utils.insertContent(editor, emoji);
      }
    });
    onEmojiSelect(emoji);
  };

  private handleSubmenuOpen = (open: boolean) => {
    if (open) {
      this.removeDocumentEventListeners();
    } else {
      this.addDocumentEventListeners();
    }
  };

  private handleEditorClick = () => {
    this.toolbar && this.toolbar.closeSubmenu();
  };

  private handleNodeChange = () =>
    this.withEditor(editor => {
      const { fonts } = this.props;

      const activeStyles = utils.getStyles(editor, fonts);

      this.setSelectedValue('fontname', activeStyles.fontName);
      this.setSelectedValue('bold', activeStyles.bold);
      this.setSelectedValue('italic', activeStyles.italic);
      this.setSelectedValue('underline', activeStyles.underline);
      this.setSelectedValue('justify', activeStyles.justify);

      /*
       * if we get a node change while the menu was open, the user likely clicked off of the menu
       * and onto some other text. in that case the new color takes priority.
       */
      this.setSelectedValue('forecolor', activeStyles.foreColor);

      this.setSelectedValue('hilitecolor', activeStyles.hiliteColor);

      this.setSelectedValue(
        'textshadow',
        textShadowFactory(activeStyles.textShadow),
      );

      this.formatAndSetValue('fontsize', activeStyles.fontSize);
    });

  private handleContentChange = () => {
    const { onContentChange } = this.props;

    this.withEditor(editor => {
      onContentChange?.(editor.getContent({ format: 'text' }));
    });
  };

  private handleDocumentClick = e => {
    if (!this.toolbar) return;
    // if the click didn't occur on a toolbar element
    if (!this.toolbar.containsEventTarget(e)) {
      this.blurEditor();
    }
  };

  private handleFocus = () => {
    this.selectDefaultContent();
  };

  private initInstanceCallback = () => {
    const {
      content,
      defaultContent,
      defaultIframeStyle,
      defaultStyle,
      defaultTextStyle,
      disableIframe,
      onInit,
    } = this.props;

    this.setIframeStyle(defaultIframeStyle);

    if (content) {
      this.withEditor(editor => editor.setContent(content));
    } else {
      this.setDefaultContent(defaultContent);
    }

    /*
     * set container style after content is set since container style checks if content
     * is default
     */
    this.setContainerStyle(defaultStyle);

    // can only set text style after content is set
    this.setTextStyle(defaultTextStyle);
    if (disableIframe) {
      this.setIframeEnabled(false);
    }

    onInit(this);

    /**
     * HACK to get toolbar to reflect the correct text-shadow values. might be an issue with
     * our custom text-shadow plugin, but by default the toolbar will reflect the text-shadow
     * of the container even if a different text shadow has been applied to the entire contents
     * of the text box.
     *
     * moving cursor to start results in tinymce selecting the node we need to reflect the
     * correct text-shadow state
     */
    this.withEditor(utils.moveCursorToStart);
  };

  private pastePostProcess = (_, args) => {
    const { defaultStyle, defaultTextStyle } = this.props;

    return this.withEditor(ed =>
      utils.pastePostprocess(ed, args, {
        ...defaultStyle,
        ...defaultTextStyle,
      }),
    );
  };

  private initializeTinymce(target: Element) {
    const { bodyClassName, fonts } = this.props;

    const fontUrls = fonts.map(f => f.url).filter(url => !isUndefined(url));

    tinymce.init({
      target,
      autoresize_bottom_margin: 50,
      autoresize_overflow_padding: 0,
      body_class: bodyClassName,
      branding: false,
      browser_spellcheck: true,
      content_css: [...fontUrls, utils.getHeadlinerCss()],
      custom_ui_selector: '.inline-text-toolbar',
      elementpath: false,
      extended_valid_elements: 'span[*],p[*],#span[*]',
      forced_root_block_attrs: {
        style: 'line-height:0;',
      },
      formats: {
        bold: { inline: 'span', styles: { fontWeight: 'bold' } },
        italic: { inline: 'span', styles: { fontStyle: 'italic' } },
        underline: { inline: 'span', styles: { textDecoration: 'underline' } },
        hilitecolor: {
          inline: 'span',
          classes: 'hilitecolor',
          styles: {
            backgroundColor: '%value',
            paddingLeft: '0.25em',
            paddingRight: '0.25em',
            'box-decoration-break': 'clone',
            '-webkit-box-decoration-break': 'clone',
          },
        },
      },
      init_instance_callback: this.initInstanceCallback,
      menubar: false,
      min_width: 0,
      paste_postprocess: this.pastePostProcess,
      plugins: 'textshadow, autoresize, paste',
      setup: editor => {
        this.editor = editor;
        editor.on('Click', this.handleEditorClick);
        editor.on('NodeChange', this.handleNodeChange);
        editor.on('Focus', this.handleFocus);
        editor.on('KeyUp', this.handleContentChange);
        this.addDocumentEventListeners();
      },
      skin_url: spareminConfig.tinymce.skinUrl,
      statusbar: false,
      toolbar: false,
    });
  }

  private destroyEditor() {
    this.withEditor(utils.destroyEditor);
    this.editor = undefined;
    this.removeDocumentEventListeners();
  }

  private setSelectedValue: SetSelectedValue = (key, value, cb = noop) =>
    this.setState(
      ({ data }) => ({
        data: data.update(key, current =>
          isFunction(value) ? value(current) : value,
        ),
      }),
      cb,
    );

  private formatAndSetValue: SetSelectedValue = (key, value) => {
    const { formatters } = this.props;
    const format = getValue(formatters, [key, 'styleToToolbar'], identity);
    this.setSelectedValue(key, format(value));
  };

  private formatAndSetStyle = (command: string, val?: any) => {
    const { formatters } = this.props;
    const propFormatter = getValue(
      formatters,
      [command, 'toolbarToStyle'],
      identity,
    );
    const valueFormatter = value => utils.formatCommandValue(command, value);
    const format = compose(valueFormatter, propFormatter);
    this.setStyle(command, format(val));
  };

  private setStyle(command: string, val: any) {
    const { disableIframe } = this.props;

    this.withEditor(editor => {
      if (disableIframe) {
        utils.selectAll(editor);
      }
      utils.setStyle(editor, command, val);
    });
  }

  public setContainerStyle(style?: React.CSSProperties) {
    const { defaultStyle } = this.props;
    this.withEditor(utils.setContainerStyle, style ?? defaultStyle);
  }

  public setTextStyle(style: React.CSSProperties) {
    this.withEditor(utils.setTextStyle, style);
    this.blurEditor();
  }

  private onPresetTargetStyleChange = (): void => {
    const { onPresetTargetStyleChanged } = this.props;
    onPresetTargetStyleChanged();
  };

  public setSelectedFont = (name: string) => {
    this.handleFontChange(name);
  };

  private addDocumentEventListeners() {
    document.body.addEventListener('click', this.handleDocumentClick);
  }

  private removeDocumentEventListeners() {
    document.body.removeEventListener('click', this.handleDocumentClick);
  }

  private setIframeStyle(style: React.CSSProperties) {
    this.withEditor(utils.setIframeStyle, style);
  }

  private setIframeEnabled(enabled: boolean) {
    this.setIframeStyle({ pointerEvents: enabled ? 'auto' : 'none' });
  }

  private isDefault() {
    const { placeholderContent } = this.props;
    return this.withEditor(utils.isDefaultContent, placeholderContent);
  }

  private setDefaultContent(defaultContent?: string) {
    const { placeholderContent } = this.props;
    this.withEditor(
      utils.setDefaultContent,
      defaultContent ?? placeholderContent,
    );
  }

  private selectDefaultContent() {
    if (this.isDefault()) {
      this.withEditor(utils.selectAll);
    }
  }

  public clearAllStyles() {
    this.withEditor(utils.clearStyles, this.handleFocus);
  }

  public focusEditor() {
    this.withEditor(utils.focusEditor);
  }

  private blurEditor = () => {
    const { onBlur } = this.props;
    this.setDefaultContent();
    this.withEditor(utils.clearSelection);
    onBlur();
  };

  /*
   * inline ref gets called twice on render, once with null and once with the element, which messes
   * with our document.onclick handler where we expect to have `this.toolbar` but it's missing in
   * some cases (namely clicking the text shadow color input).
   * https://github.com/facebook/react/issues/11258#issuecomment-337379350
   * https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
   */
  private setToolbar = el => (this.toolbar = el);

  public get content() {
    const { placeholderContent } = this.props;
    return this.withEditor(utils.getEditorContent, placeholderContent);
  }

  private withEditor<T, U extends any[]>(
    fn: (editor: tinymce.Editor, ...args: U) => T,
    ...args: U
  ) {
    if (!this.editor) return undefined;
    return fn(this.editor, ...args);
  }

  public render() {
    const { className, fonts, showEmoji } = this.props;
    const { data } = this.state;

    return (
      <DefaultTextToolbar
        className={classNames('inline-text-toolbar', className)}
        ref={this.setToolbar}
        fonts={fonts}
        fontsize={data.fontsize}
        fontname={data.fontname}
        onFontChange={this.handleFontChange}
        onFontSizeChange={this.handleFontSizeChange}
        bold={data.bold}
        onToggleBold={this.handleToggleValue('bold')}
        italic={data.italic}
        onToggleItalic={this.handleToggleValue('italic')}
        underline={data.underline}
        onToggleUnderline={this.handleToggleValue('underline')}
        fontcolor={data.forecolor}
        onFontColorChange={this.handleFontColorChange}
        highlightColor={data.hilitecolor}
        onHighlightColorChange={this.handleTextHighlightChange}
        justify={data.justify}
        onJustifyChange={this.handleJustifyChange}
        textShadow={data.textshadow.toJS()}
        onTextShadowChange={this.handleTextShadowChange}
        onEmojiSelect={this.handleEmojiSelect}
        onSubmenuOpen={this.handleSubmenuOpen}
        showEmoji={showEmoji}
      />
    );
  }
}

export { IProps as RichTextEditorProps };
