import cn from 'classnames';
import * as React from 'react';

import FillAspectRatio, {
  FillAspectRatioProps,
} from 'components/FillAspectRatio';
import VideoPoster from 'components/VideoPoster';
import { VideoScaling } from 'utils/embed/video';
import { prefix } from 'utils/ui';
import { block } from '../../utils';

export interface VideoAssetThumbnailProps
  extends Pick<FillAspectRatioProps, 'aspectRatio'> {
  className?: string;
  scaling?: VideoScaling;
  src?: string;
}

const VideoAssetThumbnail: React.SFC<VideoAssetThumbnailProps> = ({
  aspectRatio,
  className,
  scaling,
  src,
}) => (
  <div className={cn(block('video-asset-thumb'), className)}>
    {src ? (
      <FillAspectRatio
        aspectRatio={aspectRatio}
        innerClassName={block('video-asset-still-inner')}
        wrapperClassName={block('video-asset-still-wrapper')}
        element={
          <VideoPoster
            className={block('video')}
            src={src}
            style={prefix({
              objectFit: scaling === VideoScaling.FILL ? 'cover' : 'contain',
            })}
          />
        }
      />
    ) : (
      <VideoPoster src="https://static.sparemin.com/static/font-awesome/%2323282D/png/64/video-camera.png" />
    )}
  </div>
);

export default VideoAssetThumbnail;
