import * as React from 'react';

import Page from 'components/Page';
import bem from 'utils/bem';
import { AutomatedButton, TextToVideoButton } from './buttons';
import NewProjectSelector from './NewProjectSelector';
import ProjectsPageSection from './ProjectsPageSection';

const block = bem('labs-page');

const LabsPage: React.FC = () => (
  <Page className={block()}>
    <ProjectsPageSection className={block('wizard-section')} title="Labs">
      <NewProjectSelector>
        <TextToVideoButton />
        <AutomatedButton />
      </NewProjectSelector>
    </ProjectsPageSection>
  </Page>
);

export default LabsPage;
