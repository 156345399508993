import * as React from 'react';
import uncontrollable from 'uncontrollable';

import TextShadowInput, {
  TextShadow,
  TextShadowInputProps,
} from './TextShadowInput';

type UncontrollableProps = TextShadowInputProps & {
  defaultOpenMenu?: TextShadowInputProps['openMenu'];
  defaultValue?: TextShadowInputProps['value'];
};

const UncontrollableTextShadowInput: React.ComponentType<UncontrollableProps> = uncontrollable(
  TextShadowInput,
  {
    openMenu: 'onOpenMenuChange',
    value: 'onChange',
  },
);

export default UncontrollableTextShadowInput;
export { TextShadow, UncontrollableProps as TextShadowInputProps };
