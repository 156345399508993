import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useCallback } from 'react';
import useStripeField from './useStripeField';

export default function useCardField() {
  const card = useStripeField();

  const stripe = useStripe();
  const elements = useElements();

  const createToken = useCallback(async () => {
    const { error, token } = await stripe.createToken(
      elements.getElement(CardElement),
    );

    if (error) {
      card.setError(error.message);
    }

    return token;
  }, [card, elements, stripe]);

  return {
    card,
    createToken,
    hasError: !!card.error,
    helperText: card.error,
  };
}
