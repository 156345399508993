import React, { useState } from 'react';
import destinations from 'blocks/AudiogramDestinations/components/Destinations';
import { AudiogramDestinationModalStep } from 'blocks/AudiogramDestinations/types';
import ChooseDestinationModalContents from './ChooseDestinationModalContents';
import VideoTypesContents from './VideoTypesModalContents';

export interface AudiogramDestinationModalContentsProps {}

const AudiogramDestinationModalContents: React.FC<AudiogramDestinationModalContentsProps> = () => {
  const [step, setStep] = useState<AudiogramDestinationModalStep>(
    'destination',
  );

  if (step === 'destination') {
    return (
      <ChooseDestinationModalContents
        onDestinationClick={val => setStep(val)}
      />
    );
  }

  return (
    <VideoTypesContents
      destination={destinations[step]}
      onBackClick={() => setStep('destination')}
    />
  );
};

export default AudiogramDestinationModalContents;
