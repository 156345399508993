import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import useMaxSizeFile from 'hooks/useMaxSizeFile';
import { showError } from 'redux/modules/notification';
import {
  podcastResultsSelector,
  selectedPodcastCanRefreshFeedSelector,
  selectedPodcastIdSelector,
} from 'redux/modules/podcast-search/selectors';
import { AddAudioMeta, AudioSourceType } from 'types';
import { PodcastEpisodeData } from '../types';
import EpisodeExplorer, { EpisodeExplorerProps } from './EpisodeExplorer';
import PodcastList, { PodcastListProps } from './PodcastList';

const { useCallback, useRef, useState } = React;

export type ErrorType = 'episodeTooLarge';

type OnAudioAdded = (
  source: File | string,
  type: AudioSourceType,
  meta?: AddAudioMeta,
) => void;

type PickedEpisodeProps = Pick<EpisodeExplorerProps, 'isProcessingEpisode'>;

type PickedPodcastListProps = Pick<PodcastListProps, 'onPodcastClick'>;

export interface PodcastExplorerBodyProps
  extends PickedEpisodeProps,
    PickedPodcastListProps {
  episodeExplorerEnabled?: boolean;
  height: number;
  maxFileSizeMb: number;
  onAudioAdded?: OnAudioAdded;
  onEpisodeClick?: (episode: PodcastEpisodeData) => void;
}

const resultCountSelector = createSelector(
  podcastResultsSelector,
  results => results && results.size,
);

const PodcastExplorerBody: React.FC<PodcastExplorerBodyProps> = ({
  episodeExplorerEnabled = false,
  isProcessingEpisode,
  height,
  maxFileSizeMb,
  onAudioAdded = () => null,
  onEpisodeClick,
  onPodcastClick,
}) => {
  const resultCount = useSelector(resultCountSelector);
  const selectedPodcastId = useSelector(selectedPodcastIdSelector);
  const canRefreshFeed = useSelector(selectedPodcastCanRefreshFeedSelector);
  const [showProcessingEpisode, setShowProcessingEpisode] = useState(false);
  const dispatch = useDispatch();

  const episodeRef = useRef<PodcastEpisodeData>();
  const { setSource } = useMaxSizeFile(maxFileSizeMb, {
    onAccept: (_, source) => {
      onAudioAdded(source, 'podcast', {
        podcastId: episodeRef.current.podcastId,
        episodeId: episodeRef.current.id,
        originalAudioUrl: episodeRef.current.originalAudioUrl,
      });
    },
    onReject: () => {
      setShowProcessingEpisode(false);
      dispatch(
        showError(
          'Please try a different episode instead',
          5,
          undefined,
          `Sorry, that episode was over ${maxFileSizeMb}MB`,
        ),
      );
    },
  });

  const handleEpisodeClick: EpisodeExplorerProps['onEpisodeClick'] = useCallback(
    episode => {
      episodeRef.current = episode;

      onEpisodeClick?.(episode);
      setSource(episode.audioUrl);
      setShowProcessingEpisode(true);
    },
    [onEpisodeClick, setSource],
  );

  if (!height) return null;

  if (episodeExplorerEnabled && selectedPodcastId) {
    return (
      <EpisodeExplorer
        key={selectedPodcastId}
        {...{ height, isProcessingEpisode }}
        onEpisodeClick={handleEpisodeClick}
        isProcessingEpisode={showProcessingEpisode}
        podcastId={selectedPodcastId}
        canRefreshFeed={canRefreshFeed}
      />
    );
  }

  if (resultCount > 0) {
    return <PodcastList {...{ height, onPodcastClick }} />;
  }

  return null;
};

export default PodcastExplorerBody;
