import * as React from 'react';
import { FormFieldWarning } from 'components/Form';
import withWrapper, {
  CreateWithWrapperProps,
} from 'components/hoc/withWrapper';
import { useEditorTranscriptionOptionsContext } from './EditorTranscriptionOptionsContext';

export type Props = {};

const ReplaceTranscriptWarning: React.FC<Props> = () => {
  const { transcriptionEnabled } = useEditorTranscriptionOptionsContext();

  return !transcriptionEnabled ? null : (
    <FormFieldWarning>
      This will replace the current transcript
    </FormFieldWarning>
  );
};

export type ReplaceTranscriptWarningProps<T> = CreateWithWrapperProps<
  typeof ReplaceTranscriptWarning,
  T
>;

export default withWrapper()(ReplaceTranscriptWarning);
