import { useSelector } from 'react-redux';
import { isNumber } from 'underscore';
import { SubTier } from 'redux/middleware/api/plan-service';
import {
  planEndInDaysSelector,
  seenFreeTrialRemindersSelector,
  subtierSelector,
} from 'redux/modules/pricing';
import { FreeTrialReminder } from 'redux/modules/pricing/types';

interface UseFreeTrialReminderConfig {
  prevSubtier: SubTier;
}

type UseFreeTrialReminderReturnType = FreeTrialReminder | false;

export default function useFreeTrialReminder({
  prevSubtier,
}: UseFreeTrialReminderConfig): UseFreeTrialReminderReturnType {
  const subtier = useSelector(subtierSelector);
  const planEndInDays = useSelector(planEndInDaysSelector);
  const seenFreeTrialReminders = useSelector(seenFreeTrialRemindersSelector);

  return !seenFreeTrialReminders.includes(FreeTrialReminder.LAST_WEEK) &&
    subtier === SubTier.PRO_TRIAL &&
    isNumber(planEndInDays) &&
    planEndInDays < 7
    ? FreeTrialReminder.LAST_WEEK
    : prevSubtier === SubTier.PRO_TRIAL && subtier === SubTier.FREE
    ? FreeTrialReminder.OVER
    : false;
}
