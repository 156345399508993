import { useCallback, useRef } from 'react';

/**
 * Returns a referentially stable version of the callback function.
 *
 * https://medium.com/hyperexponential/static-callbacks-with-react-hooks-what-class-components-did-right-bd2e31d59597
 *
 */
const useStaticCallback = <Params extends any[], Result>(
  callback: (...args: Params) => Result,
): ((...args: Params) => Result) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  return useCallback((...args: Params) => callbackRef.current(...args), []);
};

export default useStaticCallback;
