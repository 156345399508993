import { Record, RecordOf } from 'immutable';
import { reviveRecord } from 'utils/immutable';
import { IState } from './types';

export const stateFactory = Record<IState>({
  aspectRatioDimensions: undefined,
  aspectRatioName: undefined,
  projectId: undefined,
  status: undefined,
  textBlobId: undefined,
  transcriptId: undefined,
  transcriptRevisionId: undefined,
  urlSummaryId: undefined,
  widgetId: undefined,
});

export function stateFromJs(obj: any = {}): RecordOf<IState> {
  return reviveRecord(stateFactory, obj);
}
