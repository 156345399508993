import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { AudiogramDestination } from 'blocks/AudiogramDestinations/types';
import Steps from 'blocks/AutomationWorkflowWizard/components/DestinationStep/Steps';
import { onOnboardingWizardNext } from 'redux/modules/mixpanel';
import { onboardingDestinationStepBlock as block } from '../utils';
import CardWizardStep from './CardWizard/CardWizardStep';

interface DestinationStepProps {
  onSelectDestinationPlatform: (
    newPlatform: AudiogramDestination,
    shouldSelectVideoTypes: boolean,
  ) => void;
}

function DestinationStep(props: DestinationStepProps) {
  const { onSelectDestinationPlatform } = props;

  const dispatch = useDispatch<Dispatch>();

  const createSetDestinationHandler = (
    newPlatform: AudiogramDestination,
    shouldSelectVideoTypes: boolean,
  ) => () => {
    onSelectDestinationPlatform(newPlatform, shouldSelectVideoTypes);

    dispatch(
      onOnboardingWizardNext('destination', { destination: newPlatform }),
    );
  };

  return (
    <CardWizardStep className={block()}>
      <Spacer
        as="div"
        orientation="vertical"
        align="center"
        justify="center"
        className={block('destination-step-wrapper')}
      >
        <Steps createChangeHandler={createSetDestinationHandler} />
      </Spacer>
    </CardWizardStep>
  );
}

export default DestinationStep;
