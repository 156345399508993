import React, { useEffect } from 'react';

import { Picture } from 'components/icons';
import useObjectUrl from 'hooks/useObjectUrl';
import { useNavigation } from '../context/NavigationContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import OptionChildView, { OptionChildViewProps } from '../OptionChildView';
import { imageViewBlock as block } from '../utils';
import WatermarkChildContents from './WatermarkChildContents';

export type WatermarkChildViewProps = Pick<
  OptionChildViewProps,
  'active' | 'onClose'
>;

const WatermarkChildView: React.FC<WatermarkChildViewProps> = ({
  active,
  onClose,
}) => {
  const [, send] = useNavigation();
  const state = useTemplateState();

  let thumbnailSrc: string | File | Blob;

  if (state?.watermark) thumbnailSrc = state.watermark.originalUrl;

  const watermarkUrl = useObjectUrl(state?.watermark?.originalUrl);

  useEffect(() => {
    if (!watermarkUrl && !thumbnailSrc && active)
      send({ type: 'CHILD_VIEW_CLOSE' });
  }, [active, thumbnailSrc, send, watermarkUrl]);

  return (
    <OptionChildView
      {...{ active, onClose }}
      title="Watermark"
      className={block()}
      icon={<Picture style={{ height: 22 }} />}
    >
      <div
        className={block('background-image')}
        style={{
          background: [
            'linear-gradient(to bottom, rgba(50, 55, 50, 0.75), #323746)',
            watermarkUrl &&
              `url(${watermarkUrl}) center center / cover no-repeat`,
          ]
            .filter(Boolean)
            .join(','),
        }}
      />
      <div className={block('contents')}>
        <WatermarkChildContents />
      </div>
    </OptionChildView>
  );
};

export default WatermarkChildView;
