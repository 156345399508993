import * as React from 'react';

import { IconProps } from './types';

const GoogleAuth: React.FC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={322}
    height={47}
    viewBox="0 0 322 47"
    {...props}
  >
    <g transform="translate(1.338 1.23)" fill="none" fillRule="evenodd">
      <rect width={320} height={45} fill="#FFF" stroke="#DCE1EB" rx={3} />
      <g fillRule="nonzero">
        <path
          fill="#4285F4"
          d="M31.981 22.445c0-.692-.056-1.387-.175-2.067h-9.597v3.917h5.496a4.708 4.708 0 01-2.034 3.092v2.542h3.279c1.925-1.772 3.031-4.389 3.031-7.484z"
        />
        <path
          fill="#34A853"
          d="M22.21 32.385c2.743 0 5.057-.9 6.743-2.456l-3.278-2.542c-.912.62-2.09.972-3.462.972-2.654 0-4.905-1.79-5.712-4.198h-3.383v2.62a10.174 10.174 0 009.091 5.604z"
        />
        <path
          fill="#FBBC04"
          d="M16.497 24.16a6.095 6.095 0 010-3.895v-2.62h-3.38a10.188 10.188 0 000 9.136l3.38-2.62z"
        />
        <path
          fill="#EA4335"
          d="M22.21 16.064a5.523 5.523 0 013.902 1.525l2.905-2.905a9.78 9.78 0 00-6.808-2.647 10.173 10.173 0 00-9.091 5.608l3.38 2.62c.803-2.41 3.057-4.201 5.711-4.201z"
        />
      </g>
    </g>
  </svg>
);

export default GoogleAuth;
