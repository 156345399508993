import { useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { updateSubscriptionStatusSelector } from 'redux/modules/pricing';
import { RequestStatus } from 'types';
import { Props } from './types';
import useCancellationDetailsModalDispatch from './useCancellationDetailsModalDispatch';

const disabledSelector = createSelector(
  updateSubscriptionStatusSelector,
  status => status === RequestStatus.REQUEST,
);

export default (props: Props) => {
  const [details, onDetailsChange] = useState('');
  const disabled = useSelector(disabledSelector);
  const { onSubmit, onBack } = useCancellationDetailsModalDispatch(props);

  const handleSubmit = () => {
    onSubmit(details);
  };

  return { disabled, details, onDetailsChange, onSubmit: handleSubmit, onBack };
};
