import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ColorPicker, { ColorPickerProps } from 'components/ColorPicker';
import {
  getMyUserPref,
  savePresetColors,
} from 'redux/modules/user-pref/actions';
import { presetColorsSelector } from 'redux/modules/user-pref/selectors';

type Props = ColorPickerProps;

function HistoricalColorPicker(props: Props) {
  const { onClose, onChangeComplete, ...rest } = props;
  const dispatch = useDispatch();
  const lastColor = useRef<string>();
  const presetColors = useSelector(presetColorsSelector) ?? [];

  useEffect(() => {
    dispatch(getMyUserPref());
  }, [dispatch]);

  const handleClose = () => {
    if (lastColor.current) {
      dispatch(savePresetColors(lastColor.current));
      lastColor.current = null;
    }
    onClose?.();
  };

  const handleChangeComplete = (color: string) => {
    lastColor.current = color;
    onChangeComplete?.(color);
  };

  return (
    <ColorPicker
      {...rest}
      presetColors={presetColors}
      onClose={handleClose}
      onChangeComplete={handleChangeComplete}
    />
  );
}

export default HistoricalColorPicker;
