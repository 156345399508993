import * as React from 'react';

import { IconProps } from './types';

const TheLetterT: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.364 0h12.272C14.39 0 15 .61 15 1.364v12.272C15 14.39 14.39 15 13.636 15H1.364C.61 15 0 14.39 0 13.636V1.364C0 .61.61 0 1.364 0zM6.79 10.784h-.883a.28.28 0 0 0-.28.281v.842c0 .155.125.28.28.28H9.09a.28.28 0 0 0 .281-.28v-.842a.28.28 0 0 0-.28-.28h-.893l.01-6.57h2.571v1.126c0 .155.126.28.28.28h.848a.28.28 0 0 0 .28-.28V3.093a.28.28 0 0 0-.28-.28H3.093a.28.28 0 0 0-.28.28v2.248c0 .155.125.28.28.28h.847a.28.28 0 0 0 .28-.28V4.216H6.8l-.01 6.568z"
    />
  </svg>
);

export default TheLetterT;
