import { ColorPaletteState } from '@sparemin/blockhead';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import SearchInput, { SearchInputProps } from 'components/SearchInput';
import { AiImageType } from 'redux/middleware/api/image-search-service';
import { keywordsSelector } from 'redux/modules/embed/selectors';
import { isPollingImageToVideoSelector } from 'redux/modules/image-to-video/selectors';
import { isFreeSelector } from 'redux/modules/pricing';
import { isVideoPollingTextToImageSelector } from 'redux/modules/text-to-image/selectors';
import { searchForVideos } from 'redux/modules/video-search';
import { searchResultsSelector } from 'redux/modules/video-search/selectors';
import { State } from 'redux/types';
import { AspectRatioName } from 'types';
import { getValue } from 'utils/collections';
import SearchKeywords from '../SearchKeywords';
import VideoSearchResults, {
  VideoSerchResultsProps,
} from './VideoSearchResults';

type PickedVideoResultsProps = Pick<
  VideoSerchResultsProps,
  'onVideoClick' | 'onTextToVideoSuccess'
>;
type PickedSearchProps = Pick<SearchInputProps, 'onSubmit'>;

export interface VideoSearchProps
  extends PickedSearchProps,
    PickedVideoResultsProps {
  id: string;
  query?: string;
  selectedVideoEngine?: string;
  className?: string;
  aspectRatioName?: AspectRatioName;
  imageType?: AiImageType;
  colorPaletteState: ColorPaletteState;
  onImageTypeChange: (value: AiImageType) => void;
  onAspectRatioNameChange: (value: AspectRatioName) => void;
  onInputChange?: (value: string) => void;
  onKeywordClick?: (keyword: string) => void;
  onEngineSelect?: (engine: string) => void;
  onUnlockFeatureClick?: () => void;
}

const VideoSearch: React.FC<VideoSearchProps> = ({
  id,
  query,
  selectedVideoEngine,
  className,
  aspectRatioName,
  onAspectRatioNameChange,
  imageType,
  colorPaletteState,
  onImageTypeChange,
  onInputChange,
  onKeywordClick,
  onEngineSelect,
  onVideoClick,
  onTextToVideoSuccess,
  onUnlockFeatureClick,
  onSubmit,
}) => {
  const isTextToVideo = selectedVideoEngine === 'textToVideo';

  const isGeneratingTextToImage = useSelector(
    isVideoPollingTextToImageSelector,
  );
  const isGeneratingImageToVideo = useSelector(isPollingImageToVideoSelector);
  const isFree = useSelector(isFreeSelector);
  const keywords = useSelector(keywordsSelector);
  const videoSearchResults = useSelector((state: State) =>
    searchResultsSelector(state),
  );

  const dispatch = useDispatch();

  const handleLoadMoreVideoResults = useCallback(
    (engine: string, page: number): void => {
      dispatch(searchForVideos(engine, query, page));
    },
    [dispatch, query],
  );

  const handleOnGenerate = useCallback((): void => {
    onSubmit(query);
  }, [onSubmit, query]);

  const isSearching = useMemo(
    () =>
      (!isEmpty(videoSearchResults) &&
        getValue(videoSearchResults, [selectedVideoEngine, 'isSearching'])) ||
      isGeneratingTextToImage ||
      isGeneratingImageToVideo,
    [
      isGeneratingImageToVideo,
      isGeneratingTextToImage,
      selectedVideoEngine,
      videoSearchResults,
    ],
  );

  return (
    <Tab.Container
      id="videos"
      activeKey={id}
      className={classNames('media-search', 'media-search--default', className)}
      onSelect={() => {}}
    >
      <div>
        <div className="media-search__right">
          {isFree && isTextToVideo ? null : (
            <>
              <SearchInput
                placeholder={
                  isTextToVideo
                    ? 'Describe the video you would like to generate as specifically as possible'
                    : 'Enter Keyword'
                }
                value={query}
                theme={isTextToVideo ? 'light' : 'dark'}
                hideButton={isTextToVideo}
                disabled={isSearching}
                onChange={onInputChange}
                onSubmit={value => (isTextToVideo ? null : onSubmit(value))}
              />

              <SearchKeywords
                keywords={keywords}
                disabled={isSearching}
                onKeywordClick={onKeywordClick}
              />
            </>
          )}

          <Tab.Content
            unmountOnExit
            className="media-search__results-content"
            animation={false}
          >
            <Tab.Pane eventKey={id}>
              <VideoSearchResults
                {...{
                  query,
                  aspectRatioName,
                  imageType,
                  colorPaletteState,
                  onImageTypeChange,
                  onAspectRatioNameChange,
                  onTextToVideoSuccess,
                  onUnlockFeatureClick,
                }}
                selectedEngine={selectedVideoEngine}
                results={videoSearchResults}
                onGenerate={handleOnGenerate}
                navContainerClassName="media-search__providers"
                onEngineSelect={onEngineSelect}
                onVideoClick={onVideoClick}
                onLoadMoreResults={handleLoadMoreVideoResults}
              />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
};

export default VideoSearch;
