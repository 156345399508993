import cn from 'classnames';
import * as React from 'react';

import { block } from './utils';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  title?: string;
}

const SummaryStepPanel: React.SFC<IProps> = ({
  children,
  className,
  title,
}) => (
  <div className={cn(block('panel'), className)}>
    {title && <div className={block('panel-title')}>{title}</div>}
    {children}
  </div>
);

export default SummaryStepPanel;
export { IProps as SummaryStepPanelProps };
