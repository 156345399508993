import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { cloneOnlyChild } from 'utils/react';
import { block } from '../utils';

interface IProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id: string;
  onClick?: (id: string, e: React.SyntheticEvent<any>) => void;
  active?: boolean;
}

export default class MutliToggleFieldOption extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    active: false,
    disabled: false,
    onClick: noop,
  };

  private handleClick = (e: React.SyntheticEvent<any>) =>
    this.props.onClick(this.props.id, e);

  public render() {
    const { children, active, disabled } = this.props;

    return cloneOnlyChild(children, child => ({
      className: cn(
        block('multi-option', { active, disabled }),
        child.props.className,
      ),
      onClick: disabled ? undefined : this.handleClick,
    }));
  }
}

export { IProps as MultiToggleFieldOptionProps };
