import * as React from 'react';

import Tooltip from 'components/Tooltip';
import { ProjectTypeByAssets } from 'utils/constants';
import { block } from '../../utils';

export interface WatermarkToolTipsProps {
  defaultWatermark?: string;
  disableButtons?: boolean;
  projectType: ProjectTypeByAssets;
  children: React.ReactNode;
}

const WatermarkToolTip: React.SFC<WatermarkToolTipsProps> = ({
  defaultWatermark,
  disableButtons,
  children,
}) => {
  return (
    <Tooltip
      animation={false}
      content={
        defaultWatermark
          ? 'Your administrator has locked this option'
          : 'Add assets below first'
      }
      id="video-options-watermark-tooltip"
      placement="bottom"
      preventHideOnHover={false}
      className={block('tooltip', {
        enabled: disableButtons,
      })}
    >
      <div className={block('tile-wrapper')}>{children}</div>
    </Tooltip>
  );
};

export default WatermarkToolTip;
