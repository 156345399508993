import { actions as imageSearch } from 'redux/middleware/api/image-search-service';
import * as mixpanelActions from '../mixpanel';
import * as types from './types';

// Array of blacklisted domains.  Expected to be lowercase
const imageDomainBlacklist = ['pixabay.com'];

const IMAGE_AREA_MIN = 700 * 393;

const smallImageFilterEngines = ['google', 'microsoft'];

const getImagesForKeywordUsingEngine = (
  keyword,
  engine,
  page,
  pageSize,
) => dispatch =>
  dispatch(imageSearch.searchForImages(keyword, engine, page, pageSize));

const imageLargeEnough = (imageObj, engine) => {
  if (smallImageFilterEngines.some(filterEngine => engine === filterEngine)) {
    const imageArea = imageObj.image.width * imageObj.image.height;
    return imageArea > IMAGE_AREA_MIN;
  }
  return true;
};

const isImageBlacklisted = (imageObj, engine) =>
  engine !== 'pixabay' &&
  imageDomainBlacklist.some(blacklistDomain =>
    imageObj.image.url.toLowerCase().includes(blacklistDomain),
  );

const filterSearchResponse = (res, engine) => {
  if (Array.isArray(res.response.content)) {
    res.response.content = res.response.content.filter(
      imageObj =>
        !isImageBlacklisted(imageObj, engine) &&
        imageLargeEnough(imageObj, engine),
    );
  }
  return res;
};

const getDefaultPageSize = engine => (engine === 'google' ? 10 : 50);

export const searchForImages = (engine, queryString, page) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: types.IMAGES_GET_REQUEST,
    payload: {
      engine,
    },
  });

  const pageNumber =
    page || getState().getIn(['imageSearch', engine, 'page'], 0);
  const pageSize = getState().getIn(
    ['imageSearch', engine, 'pageSize'],
    getDefaultPageSize(engine),
  );
  const q = queryString || getState().getIn(['imageSearch', engine, 'q']);

  if (!q) {
    return dispatch({
      type: types.IMAGES_GET_FAILURE,
      error: 'no query string',
      payload: {
        engine,
      },
    });
  }

  if (pageNumber === 0) {
    dispatch(mixpanelActions.onImageSearch(engine, q));
  }

  if (pageNumber >= 10 && engine === 'google') {
    return dispatch({
      type: types.IMAGES_GET_FAILURE,
      error: 'trying to fetch too many pages -- ignoring',
      payload: {
        engine,
      },
    });
  }

  return dispatch(
    getImagesForKeywordUsingEngine(q, engine, pageNumber, pageSize),
  )
    .then(res => {
      const filteredRes = filterSearchResponse(res, engine);
      return dispatch({
        type: types.IMAGES_GET_SUCCESS,
        response: filteredRes.response,
      });
    })
    .catch(err => {
      dispatch({
        type: types.IMAGES_GET_FAILURE,
        error: err.message,
        payload: {
          engine,
        },
      });
      throw err; // rethrow the error so that we can show a centralized error
    });
};

export const clearSearchResults = engine => dispatch => {
  dispatch({
    type: types.IMAGE_SEARCH_RESULTS_CLEAR,
    payload: {
      engine,
    },
  });
};

export const clearAllSearchResults = () => dispatch =>
  dispatch({
    type: types.IMAGE_SEARCH_RESULTS_CLEAR_ALL,
  });
