import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogRadioContent from 'components/DialogRadioContent';
import LoadingOverlay from 'components/LoadingOverlay';
import { Option } from 'components/RadioOptions';
import { onSubmitPauseMyPlanDuration } from 'redux/modules/mixpanel/actions';
import {
  pauseSubscription,
  updateSubscriptionStatusSelector,
} from 'redux/modules/pricing';

const { useState } = React;

export interface PauseSubscriptionDetailsModalContentsProps {}

const OPTIONS: Array<Option<string>> = [
  {
    label: 'Pause my plan for this month',
    value: '1 month',
  },
  {
    label: 'Pause my plan for the next two months',
    value: '2 months',
  },
  {
    label: 'Pause my plan for the next three months',
    value: '3 months',
  },
];

const PauseSubscriptionDetailsModalContents: React.FC<PauseSubscriptionDetailsModalContentsProps> = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<string>();
  const isLoading = useSelector(updateSubscriptionStatusSelector) === 'request';

  return (
    <>
      {isLoading && <LoadingOverlay title="Pausing your subscription" />}
      <DialogRadioContent
        actions={[
          {
            children: 'submit',
            disabled: !value,
            key: 'submit',
            onClick: () => {
              const period = parseInt(value, 10);
              dispatch(onSubmitPauseMyPlanDuration(value));
              dispatch(pauseSubscription(period));
            },
            fluid: true,
            theme: 'submit',
          },
        ]}
        label="How long would you like to pause for?"
        onChange={setValue}
        options={OPTIONS}
        value={value}
      />
    </>
  );
};

export default PauseSubscriptionDetailsModalContents;
