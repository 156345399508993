import * as React from 'react';

import { IconProps } from './types';

const ArrowRight: React.SFC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M5.102 11.196l.595.61a.629.629 0 0 0 .908 0l5.206-5.339a.669.669 0 0 0 0-.931L6.605.194a.629.629 0 0 0-.908 0l-.595.61a.672.672 0 0 0 .01.942L8.34 4.901H.643A.65.65 0 0 0 0 5.56v.879c0 .365.287.66.643.66H8.34l-3.227 3.154a.667.667 0 0 0-.01.942z"
    />
  </svg>
);

export default ArrowRight;
