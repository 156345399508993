import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { podcastsSelector } from 'redux/modules/entities/selectors';
import { onClickPodcastName } from 'redux/modules/mixpanel';
import { Dispatch, State } from 'redux/types';
import { getValue } from 'utils/collections';
import { createChainedFunction } from 'utils/functions';
import PodcastSearchResult, {
  PodcastSearchResultProps,
} from './PodcastSearchResult';

type StateProps = Pick<
  PodcastSearchResultProps,
  'artworkUrl' | 'episodeCount' | 'onClick' | 'title' | 'host'
>;
type DispatchProps = Pick<PodcastSearchResultProps, 'onClick'>;
type OwnProps = Pick<PodcastSearchResultProps, 'onClick' | 'result'>;

const podcastIdSelector = (_, props: OwnProps) => props.result;

const makeMapStateToProps = () => {
  const podcastSelector = createSelector(
    [podcastsSelector, podcastIdSelector],
    (podcasts, podcastId) => {
      if (!podcasts || !podcastId) return undefined;
      return podcasts.get(podcastId);
    },
  );

  return (state: State, props: OwnProps): StateProps => {
    const podcast = podcastSelector(state, props);

    return {
      artworkUrl: getValue(podcast, 'thumbnailUrl'),
      episodeCount: getValue(podcast, 'totalEpisodes'),
      host: getValue(podcast, 'publisher'),
      title: getValue(podcast, 'title'),
    };
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onClick: createChainedFunction(props.onClick, () => {
    dispatch(onClickPodcastName());
  }),
});

const component = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)(PodcastSearchResult);
export type PodcastProps = React.ComponentPropsWithRef<typeof component>;
export default component;
