import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { H3, H4 } from 'components/Heading';
import LinkButton from 'components/LinkButton';
import useContextualUpgrade from 'hooks/useContextualUpgrade';
import useDisco from 'hooks/useDisco';
import useStaticCallback from 'hooks/useStaticCallback';
import { Tier } from 'redux/middleware/api/plan-service';
import { onExportDiscoWidgetInitialized } from 'redux/modules/mixpanel';
import { DISCO_ADS_PROMO_LINK, DISCO_LISTENS_STATUS } from 'utils/constants';
import fullstory from 'utils/fullstory';
import { block } from './utils';

export interface DiscoSectionProps {
  className?: string;
}

const DiscoSection: React.FC<DiscoSectionProps> = ({ className }) => {
  const dispatch = useDispatch();
  const [injectionPoint, setInjectionPoint] = useState<HTMLDivElement>();
  const { displayVariant } = DISCO_LISTENS_STATUS;

  const { initializeDisco } = useDisco({
    defaultInitializeOnLoad: false,
    injectionPoint,
    displayVariant,
  });

  const { onUpgrade: onContextualUpgrade } = useContextualUpgrade({
    allowTierSelection: false,
    purchaseTier: Tier.PRO,
  });
  const staticInitializeDisco = useStaticCallback(initializeDisco);

  useEffect(() => {
    fullstory.log(`displayVariantId is ${displayVariant}`);
  }, [displayVariant]);

  useEffect(() => {
    staticInitializeDisco();
    dispatch(onExportDiscoWidgetInitialized(displayVariant));
  }, [dispatch, displayVariant, staticInitializeDisco]);

  return (
    <section className={cn(block('disco-section'), className)}>
      <div className={block('widget')}>
        <H3 className={block('disco-heading')}>
          Enjoy these clips while you wait!
        </H3>
        <H4 className={block('disco-subheading')}>
          These sponsored clips keep Headliner free & support the podcast
          community.
        </H4>
        <div>
          <LinkButton
            href={DISCO_ADS_PROMO_LINK}
            theme="cta"
            rel="noopener noreferrer"
            target="_blank"
            className={block('disco-link')}
          >
            Promote your podcast here
          </LinkButton>
          <span className={block('link-divider')}>|</span>
          <LinkButton
            onClick={onContextualUpgrade}
            theme="cta"
            className={cn(block('upgrade'), block('disco-link'))}
          >
            Upgrade to remove clips
          </LinkButton>
        </div>
        <div className={block('disco-placeholder')} ref={setInjectionPoint} />
      </div>
    </section>
  );
};

export default DiscoSection;
