import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { TranscriptStatus } from 'utils/constants';
import CaptionsDownload from './CaptionsDownload';
import CaptionsStyleButton from './CaptionsStyleButton';
import CaptionsUpload from './CaptionsUpload';
import CopyAllCaptionsButton from './CopyAllCaptionsButton';
import CopyAllConfirmationModal from './CopyAllConfirmationModal';
import EnableCaptionsField from './EnableCaptionsField';
import { block } from './utils';

interface IProps {
  captionsEnabled?: boolean;
  className?: string;
  transcriptStatus: TranscriptStatus;
  onClickCaptionUpload?: () => void;
  onCopyAllToTimeline?: () => void;
  onDownloadClick?: () => void;
  onStyleButtonClick?: () => void;
  onToggleCaptions?: (enabled: boolean) => void;
}

interface IState {
  showCopyAllConfirmationModal: boolean;
}

export default class CaptionsForm extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    onClickCaptionUpload: noop,
    onCopyAllToTimeline: noop,
    onDownloadClick: noop,
    onStyleButtonClick: noop,
    onToggleCaptions: noop,
  };

  public state: Readonly<IState> = {
    showCopyAllConfirmationModal: false,
  };

  private handleModalCancel = () => this.setShowModal(false);

  private handleCopyAllButtonClick = () => this.setShowModal(true);

  private handleCopyAllConfirm = () => {
    const { onCopyAllToTimeline } = this.props;
    this.setShowModal(false);
    onCopyAllToTimeline();
  };

  private setShowModal(showCopyAllConfirmationModal: boolean) {
    this.setState({ showCopyAllConfirmationModal });
  }

  private isTranscriptAvailable = () => {
    const { transcriptStatus } = this.props;

    return transcriptStatus === TranscriptStatus.TRANSCRIPT_STATUS_AVAILABLE;
  };

  public render() {
    const {
      captionsEnabled,
      className,
      onClickCaptionUpload,
      onDownloadClick,
      onStyleButtonClick,
      onToggleCaptions,
    } = this.props;

    const { showCopyAllConfirmationModal } = this.state;

    return (
      this.isTranscriptAvailable() && (
        <div className={cn(block(), className)}>
          <a
            className={block('label')}
            href="https://learn.headliner.app/hc/en-us/articles/360036539313"
            rel="noopener noreferrer"
            target="_blank"
          >
            FAQs
          </a>

          <div className={block('actions')}>
            <CaptionsDownload onDownloadClick={onDownloadClick} />
            <CaptionsUpload onUploadClick={onClickCaptionUpload} />
          </div>

          <form className={block('form')}>
            <EnableCaptionsField
              onChange={onToggleCaptions}
              value={captionsEnabled}
            />
            <CaptionsStyleButton
              disabled={!captionsEnabled}
              onClick={onStyleButtonClick}
            />
            <CopyAllCaptionsButton onClick={this.handleCopyAllButtonClick} />
          </form>

          <CopyAllConfirmationModal
            onCancel={this.handleModalCancel}
            show={showCopyAllConfirmationModal}
            onConfirm={this.handleCopyAllConfirm}
          />
        </div>
      )
    );
  }
}

export { IProps as CaptionsFormProps };
