import * as React from 'react';

import TosLink from 'components/TosLink';
import MediaSearchTermsLink from './MediaSearchTermsLink';

interface IProps {
  children?: React.ReactNode;
  className?: string;
}

const Disclaimer: React.SFC<IProps> = ({ children, className }) => (
  <span className={className}>
    By using our media search features, you acknowledge that you have read and
    accepted our <MediaSearchTermsLink /> and our <TosLink />.{children}
  </span>
);

export default Disclaimer;
