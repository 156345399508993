import cn from 'classnames';
import * as React from 'react';

import { block } from '../utils';

export interface PodcastSearchDefaultStatePanelProps {
  children?: React.ReactNode;
  className?: string;
}

const PodcastSearchDefaultStatePanel: React.FC<PodcastSearchDefaultStatePanelProps> = ({
  children,
  className,
}) => (
  <div className={cn(block('default-state-panel'), className)}>{children}</div>
);

export default PodcastSearchDefaultStatePanel;
