import { connect } from 'react-redux';

import {
  defaultWaveformPrefOnlyEnabledSelector,
  defaultWavePositionReadonlySelector,
} from 'redux/modules/display-pref/selectors';
import { State } from 'redux/types';
import AudioWaveImage, { AudioWaveImageProps as Props } from './AudioWaveImage';

type StateProps = Pick<Props, 'lockedWavePosition'>;

const mapStateToProps = (state: State): StateProps => ({
  lockedWavePosition:
    defaultWavePositionReadonlySelector(state) ||
    defaultWaveformPrefOnlyEnabledSelector(state),
});

const component = connect(mapStateToProps)(AudioWaveImage);
export type AudioWaveImageProps = React.ComponentPropsWithoutRef<
  typeof component
>;
export default component;
