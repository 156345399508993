import React from 'react';

import { IconProps } from './types';

const InstagramPreset: React.FC<IconProps> = props => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <linearGradient
        x1="20.728%"
        y1="-13.85%"
        x2="65.808%"
        y2="112.084%"
        id="t0ud2srgma"
      >
        <stop stopColor="#6B13C5" offset="0%" />
        <stop stopColor="#F1502D" offset="53.589%" />
        <stop stopColor="#F8C55D" offset="100%" />
      </linearGradient>
    </defs>
    <path
      d="M6.001 2.923A3.071 3.071 0 0 0 2.925 6a3.071 3.071 0 0 0 3.076 3.077A3.071 3.071 0 0 0 9.077 6a3.071 3.071 0 0 0-3.076-3.077zm0 5.077c-1.1 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.9 2-2 2zm3.92-5.202a.716.716 0 0 1-.718.717.718.718 0 1 1 .717-.718zm2.037.728c-.046-.961-.265-1.813-.97-2.515-.7-.701-1.552-.92-2.513-.969-.99-.056-3.96-.056-4.95 0-.958.046-1.81.265-2.514.967-.704.701-.92 1.553-.969 2.514-.056.99-.056 3.96 0 4.951.046.961.265 1.813.97 2.515.703.701 1.552.92 2.513.969.99.056 3.96.056 4.95 0 .961-.046 1.812-.265 2.514-.97.701-.701.92-1.553.969-2.514.056-.99.056-3.957 0-4.948zm-1.28 6.011c-.209.525-.613.93-1.14 1.14-.79.314-2.664.242-3.537.242-.872 0-2.749.07-3.536-.241a2.025 2.025 0 0 1-1.14-1.14C1.01 8.747 1.083 6.872 1.083 6c0-.873-.07-2.75.24-3.537.21-.525.614-.93 1.14-1.14.79-.314 2.665-.242 3.537-.242.873 0 2.75-.07 3.537.241.524.209.929.613 1.14 1.14.313.79.241 2.665.241 3.538 0 .873.072 2.75-.24 3.537z"
      fill="url(#t0ud2srgma)"
      fillRule="nonzero"
    />
  </svg>
);

export default InstagramPreset;
