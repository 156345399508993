import { useSelector } from 'react-redux';
import { useAsyncAudioClipperError } from 'containers/AsyncAudioClipper';
import { useBlocker } from 'hooks/useBlocker';
import { CreationStatus } from 'redux/middleware/api/creation-service/types';
import {
  creationStatusSelector,
  pollStatusSelector,
  statusSelector,
} from 'redux/modules/wizard-export/selectors';
import { RequestStatus } from 'types';
import { MIXPANEL_WIZARD_STEP_MAP } from './constants';
import { TemplateWizardStep } from './types';

const useAudiogramWizardBlocker = (stepId: TemplateWizardStep) => {
  const creationStatus = useSelector(creationStatusSelector);
  const requestStatus = useSelector(statusSelector);
  const pollStatus = useSelector(pollStatusSelector);
  const { hasError } = useAsyncAudioClipperError();

  const shouldAllow =
    hasError ||
    stepId === TemplateWizardStep.ADD_AUDIO ||
    stepId === TemplateWizardStep.EXPORTING ||
    requestStatus === RequestStatus.FAILURE ||
    pollStatus === RequestStatus.FAILURE ||
    (stepId === TemplateWizardStep.SUBMITTING &&
      creationStatus === CreationStatus.COMPLETED) ||
    (stepId === TemplateWizardStep.SUBMITTING &&
      creationStatus === CreationStatus.FAILED) ||
    (stepId === TemplateWizardStep.SUBMITTING &&
      creationStatus === CreationStatus.EXPORTING_VIDEO);

  return useBlocker(!shouldAllow, MIXPANEL_WIZARD_STEP_MAP[stepId]);
};

export default useAudiogramWizardBlocker;
