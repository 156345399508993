import * as React from 'react';
import { noop } from 'underscore';

import WrappedSelect from 'components/WrappedSelect';
import { Omit } from 'types';
import bem from 'utils/bem';
import AuthFormInput, { AuthFormInputProps } from './AuthFormInput';

const { useCallback } = React;

const AuthFormInputAsAny = AuthFormInput as any;
type PickedInputProps = Omit<
  AuthFormInputProps,
  'className' | 'componentClass' | 'onChange'
>;

export interface AuthFormSelectProps extends PickedInputProps {
  onChange?: (value: string) => void;
}

const block = bem('auth-select');

const Select = props => (
  <WrappedSelect inputClassName={block('select')} {...props} />
);

const AuthFormSelect: React.SFC<AuthFormSelectProps> = ({
  onChange,
  ...props
}) => {
  const handleChange = useCallback(e => onChange(e.target.value), [onChange]);

  return (
    <AuthFormInputAsAny
      caretClassName={block('caret')}
      className={block()}
      componentClass={Select}
      onChange={handleChange}
      {...props}
    />
  );
};

AuthFormSelect.defaultProps = {
  onChange: noop,
};

export default AuthFormSelect;
