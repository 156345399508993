import * as React from 'react';

import { IconProps } from './types';

const ClosedCaptioning: React.FC<IconProps> = ({
  fill = 'currentColor',
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 22" {...props}>
    <path
      fill={fill}
      d="M26.281 0H2.72C1.218 0 0 1.232 0 2.75v16.5C0 20.768 1.218 22 2.719 22H26.28C27.782 22 29 20.768 29 19.25V2.75C29 1.232 27.782 0 26.281 0zM12.353 12.816c.159-.143.402-.12.521.052l1.105 1.587c.096.137.085.32-.028.44-3.036 3.255-9.788 1.84-9.788-3.89 0-5.574 6.893-6.846 9.77-4.015.12.114.142.183.057.326L13 9.064c-.108.177-.351.229-.515.097-2.311-1.833-5.359-.854-5.359 1.787.006 2.75 2.895 4.04 5.228 1.868zm10.785 0c.158-.143.402-.12.52.052l1.105 1.587c.097.137.085.32-.028.44-3.03 3.26-9.782 1.84-9.782-3.89 0-5.574 6.893-6.846 9.77-4.015.12.114.142.183.057.326l-.99 1.748c-.109.177-.352.229-.516.097-2.311-1.833-5.359-.854-5.359 1.787 0 2.75 2.89 4.04 5.223 1.868z"
    />
  </svg>
);

export default ClosedCaptioning;
