import cn from 'classnames';
import * as React from 'react';

import Button, { ButtonProps } from 'components/Button';
import { Omit } from '../../types';

interface IProps extends Omit<ButtonProps, 'theme'> {
  theme?: 'cancel' | 'next';
}

const WizardControlButton: React.SFC<IProps> = ({
  className,
  theme,
  ...buttonProps
}) => (
  <Button
    {...buttonProps}
    className={cn(
      'wizard__button',
      {
        'wizard__button--cancel': theme === 'cancel',
        'wizard__button--next': theme === 'next',
      },
      className,
    )}
    size="small"
    theme={theme === 'next' ? 'submit' : 'cancel'}
    fluid
  />
);

WizardControlButton.defaultProps = {
  theme: 'cancel',
};

export default WizardControlButton;
