import * as React from 'react';
import { noop } from 'underscore';

import { VideoEditorPlaybackTimeContext } from './types';

const defaultContext = {
  positionSec: 0,
  seekToSec: undefined,
  setPositionSec: noop,
} as VideoEditorPlaybackTimeContext;

export default React.createContext<VideoEditorPlaybackTimeContext>(
  defaultContext,
);
