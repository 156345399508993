import { Record } from 'immutable';
import { State } from './types';

export const stateFactory = Record<State>(
  {
    mediaType: undefined,
    prompt: undefined,
    providers: undefined,
    width: undefined,
    height: undefined,
    textToImageJobIds: undefined,
    isPollingTextToImage: {
      image: false,
      video: false,
    },
    images: {
      image: [],
      video: [],
    },
    hasError: {
      image: false,
      video: false,
    },
    errorMessage: undefined,
  },
  'TextToImageRecord',
);
