import React from 'react';
import { useDispatch } from 'react-redux';
import WarningModalContents from 'containers/WarningModal/WarningModalContents';
import { hideModal } from 'redux/modules/modal/actions';

function UnsavedWorkModalContent() {
  const dispatch = useDispatch();

  return (
    <WarningModalContents
      title="You'll lose your work"
      description={
        <>
          Are you sure you want to leave this step?
          <br />
          Doing so will erase any work you've done.
        </>
      }
      actions={[
        {
          theme: 'submit-alt',
          onClick: () => {
            dispatch(hideModal(true));
          },
          children: 'leave this step',
          id: 'leave',
        },
        {
          theme: 'submit',
          onClick: () => {
            dispatch(hideModal(false));
          },
          children: 'stay here',
          id: 'stay',
        },
      ]}
    />
  );
}

export default UnsavedWorkModalContent;
