import classNames from 'classnames';
import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import bem from 'utils/bem';

const block = bem('infobox-overlay');

export interface IProps {
  className?: string;
  text: string;
}

const InfoboxOverlay: React.SFC<IProps> = ({ className, text }) => (
  <div className={classNames(block(), className)}>
    <div className={block('infobox')}>
      <FontAwesome icon="info-circle" className={block('infobox-icon')} />
      <span>{text}</span>
    </div>
  </div>
);

export default InfoboxOverlay;
