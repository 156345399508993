import * as React from 'react';

import AutogramEpisodesStep from '../containers/AutogramEpisodesStep';
import AutogramSeasonsStep from '../containers/AutogramSeasonsStep';
import { EpisodeOption } from './EpisodesStep/types';

export interface AutomationEpisodesStepProps {
  podcastId: string;
  isFullEpisode: boolean;
  episodeOption: EpisodeOption;
  targetedSeasons: number[];
  onEpisodeOptionChange: (option: EpisodeOption) => void;
  onTargetedSeasonsChange: (targetedSeasons: number[]) => void;
  onSkipSeasonsSelection: () => void;
  onSubmit: () => void;
}

const AutomationEpisodesStep: React.FC<AutomationEpisodesStepProps> = props => {
  const {
    podcastId,
    isFullEpisode,
    episodeOption,
    targetedSeasons,
    onTargetedSeasonsChange,
    onSkipSeasonsSelection,
    onEpisodeOptionChange,
    onSubmit,
  } = props;

  const [hasSelectedOption, setHasSelectedOption] = React.useState(false);

  const handleOnEpisodeOptionChange = React.useCallback(
    (option: EpisodeOption): void => {
      onEpisodeOptionChange(option);
      setHasSelectedOption(true);
    },
    [onEpisodeOptionChange],
  );

  // Reset the previous user's selection.
  // This is useful when the user has already made a selection, proceeded
  // to the next step, and then returned to this step.
  // It allows users to select new options from the beginning.
  React.useEffect(() => {
    onEpisodeOptionChange(undefined);
    onTargetedSeasonsChange(undefined);
  }, [onEpisodeOptionChange, onTargetedSeasonsChange]);

  // Checking if `hasSelectedOption` is true prevents the wizard from
  // automatically skipping this step if the user has already made
  // a selection and then returned to this step.
  if (hasSelectedOption && episodeOption === 'targetedSeason') {
    return (
      <AutogramSeasonsStep
        {...{
          podcastId,
          targetedSeasons,
          onTargetedSeasonsChange,
          onSkipSeasonsSelection,
          onSubmit,
        }}
      />
    );
  }

  return (
    <AutogramEpisodesStep
      isFullEpisode={isFullEpisode}
      onEpisodeOptionChange={handleOnEpisodeOptionChange}
    />
  );
};

export default AutomationEpisodesStep;
