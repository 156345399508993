import { PostStatus } from '../constants';
import { Type } from './action-types';
import { TiktokAction, TiktokState, tiktokStateFactory } from './types';

const defaultState = tiktokStateFactory();

export default function(
  state: TiktokState = defaultState,
  action: TiktokAction,
) {
  switch (action.type) {
    case Type.TIKTOK_USER_DATA_GET_SUCCESS: {
      const { profileImageUrl, username } = action.payload;

      return state
        .set('username', username)
        .set('profileImageUrl', profileImageUrl);
    }

    case Type.TIKTOK_USER_DATA_CLEAR:
      return tiktokStateFactory();

    case Type.TIKTOK_VIDEO_POST_REQUEST:
      return state.set('postStatus', PostStatus.IN_PROGRESS);

    case Type.TIKTOK_VIDEO_POST_SUCCESS: {
      const { socialShareId } = action.payload;
      return state.set('socialShareId', socialShareId);
    }

    case Type.TIKTOK_VIDEO_POST_FAILURE:
    case Type.TIKTOK_VIDEO_POST_AWAIT_FAILURE:
      return state.set('postStatus', PostStatus.FAILURE);

    case Type.TIKTOK_VIDEO_POST_AWAIT_SUCCESS:
      return state.set('postStatus', PostStatus.SUCCESS);

    case Type.TIKTOK_AUTHORIZE_SUCCESS: {
      return state.merge(action.payload);
    }

    case Type.TIKTOK_CREATOR_INFO_SUCCESS: {
      const creatorInfo = action.payload;

      return state.set('creatorInfo', creatorInfo);
    }

    default:
      return state;
  }
}
