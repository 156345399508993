import * as React from 'react';

import { CircularGradientEnvelope } from 'components/icons/circular-gradient';
import { AutoPostPlatform } from 'types';
import { automationWorkflowTileBlock as block } from '../../utils';
import { getComingSoonOverlayMessage } from './utils';

export interface ComingSoonOverlayProps {
  platform?: AutoPostPlatform;
}

const ComingSoonOverlay: React.FC<ComingSoonOverlayProps> = ({ platform }) => (
  <div className={block('coming-soon-overlay')}>
    <div className={block('coming-soon-overlay-contents')}>
      <div className={block('coming-soon-overlay-icon')}>
        <CircularGradientEnvelope />
      </div>
      <span className={block('coming-soon-overlay-text')}>
        {getComingSoonOverlayMessage(platform)}
      </span>
    </div>
  </div>
);

export default ComingSoonOverlay;
