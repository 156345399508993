import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import CardField from 'components/Form/CardField';
import useCardField from 'components/Form/useCardField';
import { H3 } from 'components/Heading';
import { ModalBody, ModalFooter, ModalFooterButton } from 'components/Modal';
import {
  onClickUpdateCreditCardModal,
  onShowCreditCardUpdateModal,
} from 'redux/modules/mixpanel';
import { showNotification } from 'redux/modules/notification';
import { loadMyPricingData, savePaymentMethod } from 'redux/modules/pricing';
import bem from 'utils/bem';

const block = bem('update-cc-modal');

export interface UpdateCreditCardModalContentsProps {
  onHide?: () => void;
  successNotificationTexts?: {
    dismissAfterSec?: number;
    message: string;
    title: string;
  };
}

const DEFAULT_SUCCESS_NOTIF_TEXTS = {
  message: 'Your plan will continue without interruption',
  title: 'Your card has been updated',
};

const UpdateCreditCardModalContents: React.FC<UpdateCreditCardModalContentsProps> = ({
  onHide,
  successNotificationTexts = DEFAULT_SUCCESS_NOTIF_TEXTS,
}) => {
  const { card, createToken, hasError, helperText } = useCardField();
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  const canSubmit = card.value && !saving;

  useEffect(() => {
    dispatch(onShowCreditCardUpdateModal());
  }, [dispatch]);

  const handleSubmit = useCallback(async () => {
    try {
      setSaving(true);
      const token = await createToken();

      try {
        await dispatch(savePaymentMethod({ stripeToken: token.id }));
        dispatch(onClickUpdateCreditCardModal(true));
      } catch (e) {
        dispatch(onClickUpdateCreditCardModal(false));
        throw e;
      }

      dispatch(loadMyPricingData());
      dispatch(
        showNotification({
          ...successNotificationTexts,
          level: 'success',
        }),
      );
      onHide?.();
    } catch {
      dispatch(
        showNotification({
          message: 'Please try again or {{link}} so we can help',
          level: 'error',
          type: 'help',
          title: 'Sorry, we couldn’t update your account',
          actionLabel: 'contact us',
        }),
      );
    } finally {
      setSaving(false);
    }
  }, [createToken, dispatch, onHide, successNotificationTexts]);

  return (
    <>
      <ModalBody className={block('body')}>
        <H3 className={block('title')}>Please enter a valid credit card</H3>
        <CardField
          density="comfortable"
          error={hasError}
          helperText={helperText}
          light
          onCardChange={card.onChange}
        />
      </ModalBody>
      <ModalFooter>
        <ModalFooterButton
          disabled={!canSubmit}
          onClick={handleSubmit}
          theme="submit"
          fluid
        >
          update your credit card
        </ModalFooterButton>
      </ModalFooter>
    </>
  );
};

export default UpdateCreditCardModalContents;
