import {
  Button,
  ColorPaletteField,
  ColorPaletteState,
  darkTheme,
  Select,
  Spacer,
  ThemeProvider,
} from '@sparemin/blockhead';
import React from 'react';
import { AiImageType } from 'redux/middleware/api/image-search-service';
import { AspectRatioName } from 'types';
import { aspectRatioOptions, imageTypeOptions } from './constants';

export interface AIGenerationTopSlotProps {
  isLoading: boolean;
  hasQuery: boolean;
  aspectRatioName?: AspectRatioName;
  imageType?: AiImageType;
  colorPaletteState: ColorPaletteState;
  onImageTypeChange: (value: AiImageType) => void;
  onAspectRatioNameChange: (value: AspectRatioName) => void;
  onGenerate?: () => void;
}

const AIGenerationTopSlot: React.FC<AIGenerationTopSlotProps> = ({
  isLoading,
  hasQuery,
  aspectRatioName,
  onAspectRatioNameChange,
  imageType,
  colorPaletteState,
  onImageTypeChange,
  onGenerate,
}) => {
  return (
    <ThemeProvider
      theme={{
        ...darkTheme,
        zIndex: {
          overlay: 10000,
        },
      }}
    >
      <Spacer align="flex-end" space="16px">
        <Select
          fluid
          label="Aspect ratio"
          selectedKey={aspectRatioName}
          isDisabled={isLoading}
          onSelectionChange={value => {
            onAspectRatioNameChange(value as AspectRatioName);
          }}
        >
          {aspectRatioOptions.map(({ value, label }) => (
            <Select.Option key={value} textValue={label}>
              {label}
            </Select.Option>
          ))}
        </Select>

        <Select
          fluid
          label="Style"
          selectedKey={imageType}
          isDisabled={isLoading}
          onSelectionChange={value => {
            onImageTypeChange(value as AiImageType);
          }}
        >
          {imageTypeOptions.map(({ value, label }) => (
            <Select.Option key={value} textValue={label}>
              {label}
            </Select.Option>
          ))}
        </Select>

        <ColorPaletteField
          disableAlpha
          label="Color palette"
          isDisabled={isLoading}
          state={colorPaletteState}
        />

        <Button isDisabled={isLoading || !hasQuery} onPress={onGenerate}>
          generate
        </Button>
      </Spacer>
    </ThemeProvider>
  );
};

export default AIGenerationTopSlot;
