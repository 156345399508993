import * as React from 'react';
import { noop } from 'underscore';
import Button from 'components/Button';
import { H1, H2 } from 'components/Heading';

import useCanvaDesign, { UseCanvaDesignConfig } from 'hooks/useCanvaDesign';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { block } from './utils';

const { useCallback } = React;

export interface CreateTabContentsProps {
  aspectRatio: number;
  onImageCreated?: UseCanvaDesignConfig['onPublish'];
}

const CreateTabContents: React.FC<CreateTabContentsProps> = ({
  aspectRatio,
  onImageCreated = noop,
}) => {
  const { createDesign } = useCanvaDesign({
    onPublish: onImageCreated,
    source: 'Editor',
  });

  const handleCtaClick = useCallback(() => {
    createDesign(getAspectRatioName(aspectRatio));
  }, [aspectRatio, createDesign]);

  return (
    <div className={block('create-tab-contents')}>
      <div className={block('create-tab-body')}>
        <img
          className={block('create-tab-icon')}
          src="https://static.sparemin.com/static/webapp-assets/canva@3x.png"
        />
        <H1 className={block('create-tab-heading')}>Create an image</H1>
        <H2>Easily create an image with Canva</H2>
        <Button
          className={block('create-tab-cta')}
          onClick={handleCtaClick}
          theme="submit"
        >
          Create an image
        </Button>
      </div>
    </div>
  );
};

export default CreateTabContents;
