import cn from 'classnames';
import * as React from 'react';
import bem from 'utils/bem';

const block = bem('divider');

interface DividerProps {
  className?: string;
}

const Divider: React.FC<DividerProps> = ({ className }) => {
  return <hr className={cn(block(), className)} />;
};

export default Divider;
