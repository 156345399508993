import { Heading, Spacer, TextGeneration } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'components/ConfirmationModal';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import Modal from 'components/Modal';
import useContextualUpgrade from 'hooks/useContextualUpgrade';
import { onUnlockThisFeature } from 'redux/modules/mixpanel';
import { hideModal, replaceModal } from 'redux/modules/modal';
import { block } from './utils';

export interface AICaptionsUpsellModalContentsProps {
  onPlanUpgrade: () => void;
}

const AICaptionsUpsellModalContents: React.FC<AICaptionsUpsellModalContentsProps> = ({
  onPlanUpgrade,
}) => {
  const dispatch = useDispatch();

  const { onUpgrade } = useContextualUpgrade({
    openModal: replaceModal,
  });

  const handleUpgrade = useCallback(async () => {
    dispatch(onUnlockThisFeature('AiCopywriter'));

    const planHasBeenUpgraded = await onUpgrade();

    if (planHasBeenUpgraded) {
      onPlanUpgrade();
    }
  }, [dispatch, onPlanUpgrade, onUpgrade]);

  const handleCancel = useCallback((): void => {
    dispatch(hideModal(true));
  }, [dispatch]);

  return (
    <>
      <ConfirmationModal.Body className={block('body')}>
        <ConfirmationModal.Icon>
          <CircularGradient>
            <TextGeneration
              style={{ height: 40, width: 'auto' }}
              color="#ffffff"
            />
          </CircularGradient>
        </ConfirmationModal.Icon>

        <ConfirmationModal.Title>
          Let us be your copywriter!
        </ConfirmationModal.Title>

        <Spacer
          orientation="vertical"
          align="center"
          justify="center"
          space="24px"
        >
          <Heading level={2} className={block('description')}>
            Upgrade to Headliner PRO to unlock automated copywriting for all
            your social captions that is personalized to your voice and tone.
          </Heading>
        </Spacer>
      </ConfirmationModal.Body>

      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton theme="submit-alt" onClick={handleCancel}>
            cancel
          </Modal.FooterButton>

          <Modal.FooterButton theme="rainbow" onClick={handleUpgrade}>
            unlock this feature
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default AICaptionsUpsellModalContents;
