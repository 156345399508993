import { useSelector } from 'react-redux';
import { pagesSelector } from 'containers/FacebookPostModal';
import { FacebookPostType } from 'redux/middleware/api/podcast-service';
import { MAX_CHARS_CAPTIONS_PREVIEW } from './constants';

function formatDescription(val: string) {
  if (typeof val !== 'string') return undefined;

  return val.substring(0, MAX_CHARS_CAPTIONS_PREVIEW);
}

interface UseFacebookPostModalConfig {
  defaultDescription?: string;
  defaultPostType?: FacebookPostType;
  defaultFacebookPageId?: string;
}

export default function useFacebookPostModalSelector(
  config: UseFacebookPostModalConfig = {},
) {
  const { defaultDescription, defaultPostType, defaultFacebookPageId } = config;
  const pages = useSelector(pagesSelector);

  return {
    defaultDescription: formatDescription(defaultDescription),
    defaultPostType,
    defaultUserId: defaultFacebookPageId ?? pages[0]?.value,
    pages,
  };
}
