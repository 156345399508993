import { connect } from 'react-redux';

import {
  createMethodSelector,
  videoDurationMillisSelector,
  videoFileNameSelector,
} from 'redux/modules/download/selectors';
import { onReclipButtonVisible } from 'redux/modules/mixpanel';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import DownloadShareDetails, {
  DownloadShareDetailsProps as Props,
} from './DownloadShareDetails';

type StateProps = Pick<
  Props,
  'videoDurationMillis' | 'videoFileName' | 'projectCreateMethod'
>;

type DispatchProps = Pick<Props, 'onInitialLoad'>;

const mapStateToProps = (state: State): StateProps => ({
  videoDurationMillis: videoDurationMillisSelector(state),
  videoFileName: videoFileNameSelector(state),
  projectCreateMethod: createMethodSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onInitialLoad: () => dispatch(onReclipButtonVisible()),
});

const component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DownloadShareDetails);
export type DownloadShareDetailsProps = PropsOf<typeof component>;
export default component;
