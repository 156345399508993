import { Button } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BetaBadge from 'components/BetaBadge';
import { VideoCameraSpeechBubble } from 'components/icons';
import { AiAsset } from 'redux/middleware/api/image-search-service';
import { onClickAnimateImage } from 'redux/modules/mixpanel';
import { isFreeSelector } from 'redux/modules/pricing';
import {
  isVideoPollingTextToImageSelector,
  videoHasTextToImagesSelector,
  videoTextToImageHasErrorSelector,
  videoTextToImagesSelector,
} from 'redux/modules/text-to-image/selectors';
import {
  AIGenerationErrorMessage,
  AIGenerationInitialContents,
  AIGenerationResults,
  useImageToVideo,
} from '../AIGeneration';
import { UseAiAssetGenerationResults } from '../types';
import { block } from './utils';

export interface UseTextToVideoProps {
  onUnlockFeatureClick?: () => void;
  onTextToVideoSuccess: (url: string, poster: string) => void;
}

export default function useTextToVideo({
  onUnlockFeatureClick,
  onTextToVideoSuccess,
}: UseTextToVideoProps): UseAiAssetGenerationResults {
  const [selectedImage, setSelectedImage] = useState<string | undefined>();

  const images = useSelector(videoTextToImagesSelector);
  const isFree = useSelector(isFreeSelector);
  const isTextToImageLoading = useSelector(isVideoPollingTextToImageSelector);
  const hasTextToImage = useSelector(videoHasTextToImagesSelector);
  const hasTextToImageError = useSelector(videoTextToImageHasErrorSelector);

  const {
    isLoading: isImageToVideoLoading,
    hasError: hasImageToVideoError,
    onAnimate,
  } = useImageToVideo({
    image: selectedImage,
    onImageToVideoSuccess: onTextToVideoSuccess,
  });

  const dispatch = useDispatch();

  const isLoading = isTextToImageLoading || isImageToVideoLoading;
  const hasError = hasTextToImageError || hasImageToVideoError;
  const hasResults = hasTextToImage || hasError;

  const handleAnimate = useCallback(
    (image: AiAsset): void => {
      dispatch(onClickAnimateImage('Ungated', 'textToVideo'));

      const { url } = image;

      setSelectedImage(url);
      onAnimate(url);
    },
    [dispatch, onAnimate],
  );

  return {
    hasError,
    engine: {
      displayName: 'Text to video',
      hasResults,
      isLoading,
    },
    renderInitialContent: (
      <AIGenerationInitialContents
        icon={
          <VideoCameraSpeechBubble className={block('ai-generated-icon')} />
        }
        title="Text-to-video"
        subtitle={
          isFree
            ? 'Bring your podcast to life by adding any video you can imagine.'
            : 'Type any prompt above and create a video that is uniquely yours.'
        }
        onUnlockFeatureClick={onUnlockFeatureClick}
        showUnlockThisFeature
        beta
      />
    ),
    renderResults: (
      <AIGenerationResults
        aiAssets={images}
        overlayActions={image => (
          <>
            <Button
              variant="ghost"
              className={block('ai-generated-overlay-action')}
              onPress={() => handleAnimate(image)}
            >
              Animate
              <BetaBadge />
            </Button>
          </>
        )}
      />
    ),
    renderErrorMessage: (
      <AIGenerationErrorMessage
        title="Sorry, something went wrong"
        subtitle="Please try to submit your prompt again to generate your videos."
      />
    ),
    selectedImage,
  };
}
