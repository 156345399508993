import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardDetails from 'blocks/Pricing/containers/PaymentInfo/CardDetails';
import ConfirmationModal from 'components/ConfirmationModal';
import { HappyFace } from 'components/icons';
import LinkButton from 'components/LinkButton';
import LoadingOverlay from 'components/LoadingOverlay';
import Modal from 'components/Modal';
import { onCompleteUnpausePlan } from 'redux/modules/mixpanel';
import {
  unpauseSubscription,
  updateSubscriptionStatusSelector,
} from 'redux/modules/pricing';
import useUnpauseSubscriptionModal from './useUnpauseSubscriptionModal';

function UnpauseSubscriptionModalContent() {
  const dispatch = useDispatch();
  const { hasCardInfo, card, onUpdateCard } = useUnpauseSubscriptionModal();
  const isLoading = useSelector(updateSubscriptionStatusSelector) === 'request';

  const handleUnpauseClick = () => {
    dispatch(unpauseSubscription());
    dispatch(onCompleteUnpausePlan());
  };

  return (
    <>
      {isLoading && <LoadingOverlay title="Unpausing your subscription" />}
      <ConfirmationModal.Body>
        <ConfirmationModal.Icon>
          <HappyFace width={100} />
        </ConfirmationModal.Icon>
        <ConfirmationModal.Title>
          Regain all of your features!
        </ConfirmationModal.Title>
        <ConfirmationModal.Content>
          Your paid plan is paused but this will unpause it immediately and
          we'll resume charging this card:{' '}
          {hasCardInfo && (
            <CardDetails brand={card.brand} last4Digits={card.last4Digits} />
          )}
          {hasCardInfo && (
            <LinkButton onClick={onUpdateCard} theme="cta">
              (update card)
            </LinkButton>
          )}
          .
        </ConfirmationModal.Content>
      </ConfirmationModal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton theme="rainbow" onClick={handleUnpauseClick}>
            Unpause my plan
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
}

export default UnpauseSubscriptionModalContent;
