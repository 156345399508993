import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { podcastDetailEpisodesSelector } from 'redux/modules/entities';
import { onClickEpisodeName } from 'redux/modules/mixpanel';
import { selectedPodcastIdSelector } from 'redux/modules/podcast-search';
import {
  fullEpisodeDurationLimitHoursSelector,
  isBasicOrFreeSelector,
} from 'redux/modules/pricing';
import { Dispatch, State } from 'redux/types';
import { createChainedFunction } from 'utils/functions';
import EpisodeSearchResult, {
  EpisodeSearchResultProps as Props,
} from './EpisodeSearchResult';

type StateProps = Pick<
  Props,
  'episode' | 'fullEpisodeDurationLimitHours' | 'isFreeOrBasic'
>;
type DispatchProps = Pick<Props, 'onClick'>;
interface OwnProps extends Pick<Props, 'onClick'> {
  result: string;
}

const episodeIdSelector = (_, props: OwnProps) => props.result;

const makeMapStateToProps = () => {
  const episodeSelector = createSelector(
    [
      podcastDetailEpisodesSelector,
      episodeIdSelector,
      selectedPodcastIdSelector,
    ],
    (episodes, id, podcastId) => {
      if (!episodes || !id) return undefined;
      const ep = episodes.get(id);
      return (
        ep && {
          podcastId,
          audioUrl: ep.get('audioUrl'),
          originalAudioUrl: ep.get('originalAudioUrl'),
          durationMillis: ep.get('durationSec') * 1000,
          id: ep.get('episodeId'),
          publishedAtMillis: ep.get('publishedAtMillis'),
          thumbnailUrl: ep.get('thumbnailUrl'),
          title: ep.get('title'),
        }
      );
    },
  );

  return (state: State, props: OwnProps): StateProps => {
    const episode = episodeSelector(state, props);
    const fullEpisodeDurationLimitHours = fullEpisodeDurationLimitHoursSelector(
      state,
    );
    const isFreeOrBasic = isBasicOrFreeSelector(state);

    return { episode, fullEpisodeDurationLimitHours, isFreeOrBasic };
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onClick: createChainedFunction(props.onClick, () => {
    dispatch(onClickEpisodeName());
  }),
});

const component = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)(EpisodeSearchResult);
export type EpisodeSearchResultProps = React.ComponentPropsWithoutRef<
  typeof component
>;
export default component;
