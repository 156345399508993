import * as React from 'react';
import { Flipped } from 'react-flip-toolkit';
import { noop } from 'underscore';

import BemCssTransition from 'components/BemCssTransition';
import { GripVertical } from 'components/icons';
import { TrackLabelProps } from '../../types';
import { block } from '../../utils';
import useLabelDnd from './useLabelDnd';

const { useCallback } = React;

const TrackLabel: React.FC<TrackLabelProps> = ({
  animationDelay,
  children,
  draggable = false,
  id,
  index,
  label,
  onEntered = noop,
  onTrackDragStart = noop,
  onTrackDragStop = noop,
  onTrackDrop = noop,
  onTrackMoveIntent = noop,
  ...divProps
}) => {
  const { isDragging, ref } = useLabelDnd({
    id,
    index,
    label,
    onTrackDragStart,
    onTrackDragStop,
    onTrackDrop,
    onTrackMoveIntent,
  });

  const handleEntered = useCallback(() => {
    onEntered(id);
  }, [id, onEntered]);

  return (
    <BemCssTransition
      appear
      delay={animationDelay}
      in
      onEntered={handleEntered}
      timeout={600}
      transitionClassName={block('track-label-transition')}
    >
      <Flipped flipId={`${id}-label`}>
        <div
          className={block('track-label', { draggable, dragging: isDragging })}
          ref={ref}
          {...divProps}
        >
          <GripVertical className={block('track-drag-icon')} />
          {label}
          {children}
        </div>
      </Flipped>
    </BemCssTransition>
  );
};

export default TrackLabel;
