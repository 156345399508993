import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import useQuery from 'hooks/useQuery';
import { confirmEmail } from 'redux/modules/auth';
import { pushModal } from 'redux/modules/modal/actions';
import { getHomepage } from 'utils/routes';

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { vuuid } = useQuery();

  const onConfirm = useCallback(async () => {
    try {
      await dispatch(confirmEmail(vuuid));
      history.replace(getHomepage());
      dispatch(
        pushModal({
          name: 'EmailVerificationSuccess',
        }),
      );
    } catch (error) {
      history.replace(getHomepage());
      dispatch(
        pushModal({
          name: 'EmailVerificationFailure',
        }),
      );
    }
  }, [dispatch, history, vuuid]);

  return { onConfirm };
};
