import { Record, RecordOf } from 'immutable';
import { Action } from 'redux/types';
import { getActionTypes } from './actions';
import { UploadStatus } from './constants';
import {
  ActionTypes,
  EntireAudioState,
  State,
  UploadAudioSuccessAction,
  WrappedState,
} from './types';

export const stateFactory = Record<State>({
  entireAudioInstanceId: undefined,
  isUploadingEntireAudio: false,
  recordingId: undefined,
  recordingUploadId: undefined,
  uploadStatus: undefined,
  uploadProgress: undefined,
});

function isUploadSuccessAction(
  action: Action<string, any>,
  actionTypes: ActionTypes,
): action is UploadAudioSuccessAction {
  return action.type === actionTypes.transferSuccess;
}

export default function createReducer<
  S extends EntireAudioState,
  A extends Action<string, any>
>(
  originalReducer: (state: WrappedState<S>, action: A) => WrappedState<S>,
  underlyingDefaultState: WrappedState<S>,
  actionTypesOrName: ActionTypes | string,
) {
  const actionTypes = getActionTypes(actionTypesOrName);
  const defaultState = underlyingDefaultState.set(
    'entireAudio',
    stateFactory(),
  );

  const reducer = (state: RecordOf<State>, action: A) => {
    if (action.type === actionTypes.transferRequest) {
      return state.withMutations(s => {
        s.set('isUploadingEntireAudio', true);
        s.set('uploadStatus', UploadStatus.UPLOADING);
        s.set('uploadProgress', 0);
        return s;
      });
    }

    if (action.type === actionTypes.transferProgress) {
      const { progress } = action.payload;
      return state.set('uploadProgress', progress);
    }

    if (isUploadSuccessAction(action, actionTypes)) {
      const { id } = action.payload;
      return state.withMutations(s => {
        s.set('isUploadingEntireAudio', false);
        s.set('entireAudioInstanceId', id);
        return s;
      });
    }

    if (action.type === actionTypes.transferFailure) {
      return state.withMutations(s => {
        s.set('isUploadingEntireAudio', false);
        s.set('uploadStatus', UploadStatus.FAILED);
        return s;
      });
    }

    if (action.type === actionTypes.setRecordingId) {
      const { recordingId, recordingUploadId } = action.payload;
      return state.withMutations(s => {
        s.set('recordingId', recordingId);
        s.set('recordingUploadId', recordingUploadId);
        return s;
      });
    }

    if (action.type === actionTypes.clear) {
      return stateFactory();
    }

    if (action.type === actionTypes.awaitImport) {
      return state.set('uploadStatus', UploadStatus.IMPORTING);
    }

    if (action.type === actionTypes.clipRequest) {
      return state.set('uploadStatus', UploadStatus.CLIPPING);
    }

    if (action.type === actionTypes.awaitUploadRequest) {
      return state.set('uploadStatus', UploadStatus.UPLOADING);
    }

    if (action.type === actionTypes.awaitUploadSuccess) {
      return state.set('uploadStatus', UploadStatus.UPLOADED);
    }

    if (
      action.type === actionTypes.awaitUploadFailure ||
      action.type === actionTypes.clipFailure
    ) {
      return state.set('uploadStatus', UploadStatus.FAILED);
    }

    if (action.type === actionTypes.clipSuccess) {
      return state.set('uploadStatus', UploadStatus.SUCCEEDED);
    }

    return state;
  };

  return (state: any = defaultState, action: A) => {
    const entireAudioState = state.get('entireAudio') as RecordOf<State>;
    const newUnderlyingState = originalReducer(state || defaultState, action);
    const newEntireAudioState = reducer(entireAudioState, action);
    return newUnderlyingState.set('entireAudio', newEntireAudioState);
  };
}
