import { CaptionFormat } from 'types';
import bem from 'utils/bem';
import { CAPTIONS_FORMAT_OPTIONS } from './constants';
import { CaptionFormatOption } from './types';

export const block = bem('download-captions-modal');
export const episodeModalBlock = bem('download-episode-captions-modal');
export const dialogModalBlock = bem('download-captions-dialog-modal');
export const transcribeModalBlock = bem('transcribe-episode-modal');

export function filterCationsFormatOptions(
  formats: CaptionFormat[],
): CaptionFormatOption[] {
  return CAPTIONS_FORMAT_OPTIONS.filter(opt => formats.includes(opt.value));
}
