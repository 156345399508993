import * as React from 'react';
import Rnd, { RndProps } from 'components/Rnd';
import { ProgressAnimationOptions, ProgressType, Size } from 'types';
import { createChainedFunction } from 'utils/functions';
import measurement, { Measurement } from 'utils/measurement';

type PickedRndProps = Pick<
  RndProps,
  'onDragStart' | 'onDragStop' | 'onResizeStart' | 'onResizeStop'
>;

interface Props extends PickedRndProps {
  aspectRatio: number;
  value: ProgressAnimationOptions<Measurement>;
  onChange: (value: ProgressAnimationOptions<Measurement>) => void;
  workspaceSize?: Size<number>;
}

const { useMemo, useCallback } = React;

const DragableProgress: React.FC<Props> = props => {
  const {
    children,
    onDragStart,
    onDragStop,
    onResizeStart,
    onResizeStop,
    value,
    onChange,
    workspaceSize,
  } = props;

  const { top, left, width, height } = value;

  const toPx = useCallback((v: Measurement) => v.toUnit('px', workspaceSize), [
    workspaceSize,
  ]);
  const position = useMemo(
    () => ({
      x: toPx(left).value,
      y: toPx(top).value,
    }),
    [left, toPx, top],
  );

  const size = useMemo(
    () => ({
      width: toPx(width).value,
      height: toPx(height).value,
    }),
    [height, toPx, width],
  );

  const handleDragStop: RndProps['onDragStop'] = (_event, dragData) => {
    const { x, y } = dragData;
    onChange({
      ...value,
      top: measurement(y).toUnit('vh', workspaceSize),
      left: measurement(x).toUnit('vw', workspaceSize),
    });
  };

  const handleResize: RndProps['onResize'] = (_, __, { style }, ___, pos) => {
    onChange({
      ...value,
      height: measurement(parseFloat(style.height)).toUnit('vh', workspaceSize),
      width: measurement(parseFloat(style.width)).toUnit('vw', workspaceSize),
      top: measurement(pos.y).toUnit('vh', workspaceSize),
      left: measurement(pos.x).toUnit('vw', workspaceSize),
    });
  };

  return (
    <>
      <Rnd
        active
        bounds="parent"
        enableResizing={{
          bottom: value.type !== ProgressType.PLAYER,
          bottomLeft: true,
          bottomRight: true,
          left: value.type !== ProgressType.PLAYER,
          right: value.type !== ProgressType.PLAYER,
          top: value.type !== ProgressType.PLAYER,
          topLeft: true,
          topRight: true,
        }}
        position={position}
        size={size}
        lockAspectRatio={false}
        onDragStop={createChainedFunction(handleDragStop, onDragStop)}
        onResize={handleResize}
        minHeight={6}
        resizeGrid={[0.5, 0.5]}
        {...{ onDragStart, onResizeStart, onResizeStop }}
      >
        {children}
      </Rnd>
    </>
  );
};

export default DragableProgress;
