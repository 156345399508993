import * as React from 'react';

import { SelectField, SelectFieldProps } from 'components/Form';
import { VideoScaling } from 'utils/embed/video';
import VideoOptionsField from './VideoOptionsField';

type Props = Pick<SelectFieldProps, 'onChange' | 'value'>;

function getResizeLabel(type: string) {
  switch (type) {
    case VideoScaling.FILL:
      return 'Entire Video';

    case VideoScaling.FIT:
      return 'Black Bars';
  }

  return undefined;
}

const VideoResizeField: React.SFC<Props> = props => (
  <VideoOptionsField>
    <SelectField label="Resize" {...props}>
      <option value="" disabled hidden>
        Custom
      </option>
      {Object.keys(VideoScaling).map(type => (
        <option key={type} value={type}>
          {getResizeLabel(type)}
        </option>
      ))}
    </SelectField>
  </VideoOptionsField>
);

VideoResizeField.defaultProps = {
  value: '',
};

export default VideoResizeField;
export { Props as VideoResizeFieldProps };
