import cn from 'classnames';
import * as React from 'react';

import Divider from 'components/Divider';
import { H1, H4 } from 'components/Heading';
import Alert, { IProps as AlertProps } from './Alert';
import FontUpload from './FontSettings/components/FontUpload';
import { block } from './utils';

interface IProps {
  alert?: Pick<AlertProps, 'severity'> & {
    message: React.ReactNode;
  };
  bodyClassName?: string;
  children?: React.ReactNode;
  className?: string;
  fullBleed?: boolean;
  subtitle?: string;
  title?: React.ReactNode;
  fontsAvailable?: boolean;
}

function SettingsContent({
  alert,
  bodyClassName,
  children,
  className,
  fullBleed,
  subtitle,
  title,
  fontsAvailable,
}: IProps) {
  return (
    <div
      className={cn(block('content', { 'full-bleed': fullBleed }), className)}
    >
      {title && (
        <>
          <header className={block('header')}>
            <hgroup className={block('header-title')}>
              <H1>{title}</H1>
              <H4 as="div">{subtitle}</H4>
            </hgroup>
            {fontsAvailable && <FontUpload fontsAvailable={fontsAvailable} />}
          </header>
          {!fontsAvailable && <Divider />}
        </>
      )}
      <div className={cn(block('body'), bodyClassName)}>
        {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
        {children}
      </div>
    </div>
  );
}

SettingsContent.defaultProps = {
  fullBleed: false,
};

export default SettingsContent;
