import classNames from 'classnames';
import * as React from 'react';
import {
  Col,
  ControlLabel,
  FormControl,
  FormControlProps,
  FormGroup,
} from 'react-bootstrap';

import bem from 'utils/bem';
import { Omit } from '../types';

interface IColSizes {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
}

export interface IProps extends Omit<FormControlProps, 'bsSize' | 'label'> {
  bsSize?: 'small' | 'sm' | 'large' | 'lg' | 'medium' | 'md';
  children?: React.ReactNode;
  /**
   * outermost classname which is applied to a FormGroup component
   */
  className?: string;
  /**
   * className to put on the FormControl component
   */
  controlClassName?: string;
  /**
   * className for the column that holds the input element
   */
  controlColumnClassName?: string;
  /**
   * Bootstrap col sizes to use on the form control column
   */
  controlSizes?: IColSizes;
  /**
   * Bootstrap has a concept of horizontal forms where the labels are inline with controls (as
   * opposed to above them).  Passing true for this prop will layout the label and control using
   * the column props.  If this value is omitted or false, it's assumed that the form is not
   * horizontal and so the column props won't do anything
   */
  horizontal?: boolean;
  label?: React.ReactNode;
  /**
   * class Name for the column holding the label
   */
  labelClassName?: string;
  /**
   * Bootstrap col sizes to use on the label column
   */
  labelSizes?: IColSizes;

  endAdornment?: React.ReactNode;
}

const block = bem('form-input');

function renderLabel(
  horizontal: boolean,
  label: React.ReactNode,
  labelClassName: string,
  labelSizes: IColSizes,
) {
  if (!label) return null;

  if (horizontal) {
    return (
      <Col
        {...labelSizes}
        className={labelClassName}
        componentClass={ControlLabel}
      >
        {label}
      </Col>
    );
  }

  return <ControlLabel className={labelClassName}>{label}</ControlLabel>;
}

function renderControl(
  horizontal: boolean,
  controlProps: FormControlProps,
  controlClassName: string,
  children: React.ReactNode,
  controlColumnClassName: string,
  controlSizes: IColSizes,
  endAdornment?: React.ReactNode,
) {
  const controlContainerClassName = classNames({
    [controlClassName]: !!controlClassName,
  });

  const control = endAdornment ? (
    <div className={block('group')}>
      <FormControl className={controlContainerClassName} {...controlProps}>
        {children}
      </FormControl>
      {endAdornment}
    </div>
  ) : (
    <FormControl className={controlContainerClassName} {...controlProps}>
      {children}
    </FormControl>
  );

  if (horizontal) {
    return (
      <Col {...controlSizes} className={controlColumnClassName}>
        {control}
      </Col>
    );
  }

  return control;
}

const FormInput: React.SFC<IProps> = ({
  bsSize,
  className,
  children,
  controlColumnClassName,
  controlClassName,
  labelClassName,
  labelSizes,
  controlSizes,
  label,
  horizontal,
  endAdornment,
  ...rest
}) => {
  const labelComponent = renderLabel(
    horizontal,
    label,
    labelClassName,
    labelSizes,
  );
  const controlComponent = renderControl(
    horizontal,
    rest,
    controlClassName,
    children,
    controlColumnClassName,
    controlSizes,
    endAdornment,
  );

  /*
   * react-bootstrap defaults FormGroup's bsSize to "medium", however it doesn't accept medium
   * as a bsSize prop value.  The only values are large and small.  To force medium, we have to
   * send undefined for bsSize
   */
  const formGroupBsSize =
    bsSize === 'medium' || bsSize === 'md' ? undefined : bsSize;

  return (
    <FormGroup className={className} bsSize={formGroupBsSize}>
      {labelComponent}
      {controlComponent}
    </FormGroup>
  );
};

FormInput.defaultProps = {
  bsSize: 'small',
  controlSizes: {
    xs: 6,
  },

  horizontal: true,

  labelSizes: {
    xs: 6,
  },
};

export default FormInput;

export { IProps as FormInputProps };
