import React, { useContext } from 'react';

import { SubscriptionPeriod } from 'blocks/Pricing/types';

interface PlanSelectorContextType {
  period: SubscriptionPeriod;
}

interface PlanSelectorProviderProps extends PlanSelectorContextType {
  children?: React.ReactNode;
}

const PlanSelectorContext = React.createContext<
  PlanSelectorContextType | undefined
>(undefined);

export const PlanSelectorProvider: React.FC<PlanSelectorProviderProps> = ({
  children,
  period,
}) => (
  <PlanSelectorContext.Provider value={{ period }}>
    {children}
  </PlanSelectorContext.Provider>
);

export function usePlanSelector() {
  const context = useContext(PlanSelectorContext);

  if (context === undefined) {
    throw new Error('usePlanSelector must be used within PlanSelectorContext');
  }

  return context;
}
