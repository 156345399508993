import React from 'react';

import { Upload } from 'components/icons';
import UpsellBanner, { UpsellBannerProps } from 'components/UpsellBanner';
import { EPISODE_MAX_DURATION_UPGRADE_HOURS_VALUE } from 'utils/constants';

export interface AudioDurationUpsellBannerProps
  extends Pick<UpsellBannerProps, 'onUpgradeClick'> {
  className?: string;
  durationLimitHours?: number;
  show: boolean;
}

const AudioDurationUpsellBanner: React.FC<AudioDurationUpsellBannerProps> = ({
  className,
  onUpgradeClick,
  durationLimitHours = EPISODE_MAX_DURATION_UPGRADE_HOURS_VALUE,
  show,
}) => {
  if (!show) {
    return null;
  }

  return (
    <UpsellBanner
      {...{ className, onUpgradeClick }}
      heading={`Use audio files up to ${durationLimitHours} hours long`}
      actionLabel="Upgrade now"
      icon={<Upload />}
    />
  );
};

export default AudioDurationUpsellBanner;
