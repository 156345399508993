import * as React from 'react';
import { Col } from 'react-bootstrap';
import { noop } from 'underscore';

import EditorTranscriptionOptions from 'components/EditorTranscriptionOptions';
import { block } from './utils';

export interface TranscriptionSectionProps {
  clipDurationMillis?: number;
  language?: string;
  onChangeLanguage?: (language: string) => void;
  onToggleTranscription?: (enabled: boolean) => void;
  onUpgradeClick?: () => void;
  transcriptionEnabled: boolean;
}

const TranscriptionSection: React.FC<TranscriptionSectionProps> = ({
  clipDurationMillis,
  language,
  onChangeLanguage,
  onToggleTranscription,
  transcriptionEnabled,
  onUpgradeClick,
}) => (
  <EditorTranscriptionOptions
    className={block('section')}
    onLanguageSelected={onChangeLanguage}
    {...{
      clipDurationMillis,
      language,
      onToggleTranscription,
      transcriptionEnabled,
    }}
  >
    <Col xs={12}>
      <EditorTranscriptionOptions.Toggle
        className={block('transcription-section')}
        labelClassName={block('transcription-section-title')}
      />
    </Col>
    <EditorTranscriptionOptions.Warning
      wrapper={Col}
      wrapperProps={{ xs: 12 }}
    />
    <Col xs={12}>
      <EditorTranscriptionOptions.Language
        className={block('language-selector')}
      />
    </Col>
    <EditorTranscriptionOptions.Balance
      onClick={onUpgradeClick}
      wrapper={Col}
      wrapperProps={{ xs: 12 }}
    />
  </EditorTranscriptionOptions>
);

TranscriptionSection.defaultProps = {
  onChangeLanguage: noop,
  onToggleTranscription: noop,
};

export default TranscriptionSection;
