import { connect } from 'react-redux';

import { onDismissPostTaskPrompt } from 'redux/modules/mixpanel/actions';
import { hidePostTask } from 'redux/modules/post-task/actions';
import { hiddenPostTaskSelector } from 'redux/modules/post-task/selectors';
import { Dispatch, State } from 'redux/types';
import { PostTaskOverlayProps as Props } from '../../types';
import PostTaskOverlay from './PostTaskOverlay';

type StateProps = Pick<Props, 'isVisible'>;
type DispatchProps = Pick<Props, 'onClickDismissPostTaskPrompt'>;

interface OwnProps {
  show: boolean;
}

const mapStateToProps = (state: State, props: OwnProps): StateProps => ({
  isVisible: !hiddenPostTaskSelector(state) && props.show,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onClickDismissPostTaskPrompt: () => {
    dispatch(onDismissPostTaskPrompt());
    dispatch(hidePostTask());
  },
});

const component = connect(mapStateToProps, mapDispatchToProps)(PostTaskOverlay);
export type PostTaskOverlayProps = React.ComponentPropsWithRef<
  typeof component
>;
export default component;
