import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import IconButton, { IconButtonProps } from 'components/IconButton';
import { podcastSidebarBlock as block } from '../utils';

export interface PodcastDeleteButtonProps {
  onClick?: IconButtonProps['onButtonClick'];
}

const PodcastDeleteButton: React.FC<PodcastDeleteButtonProps> = ({
  onClick,
}) => (
  <IconButton className={block('delete-button')} onButtonClick={onClick}>
    <FontAwesome className={block('delete-icon')} icon="trash-alt" />
  </IconButton>
);

export default PodcastDeleteButton;
