import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'components/ConfirmationModal';
import FontAwesome from 'components/FontAwesome';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import Modal from 'components/Modal';
import { ConnectedModalInjectedProps } from 'containers/ConnectedModal';
import { replaceModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { block } from './utils';

export interface BackCatalogDisclaimerModalContentsProps
  extends ConnectedModalInjectedProps {}

const BackCatalogDisclaimerModalContents: React.FC<BackCatalogDisclaimerModalContentsProps> = ({
  onHide,
}) => {
  const dispatch = useDispatch<Dispatch>();

  const handleConfirmClick = useCallback(() => {
    dispatch(replaceModal({ name: 'CancelReason' }));
  }, [dispatch]);

  return (
    <>
      <ConfirmationModal.Body className={block('body')}>
        <ConfirmationModal.Icon>
          <CircularGradient>
            <FontAwesome icon="magic" size="3x" />
          </CircularGradient>
        </ConfirmationModal.Icon>
        <ConfirmationModal.Title>
          Your back catalog automations will be paused
        </ConfirmationModal.Title>
        <ConfirmationModal.Content>
          Cancelling your plan means you no longer have access to back catalog
          automations, so we’ll pause them for now but you can resume them
          whenever you’re ready to upgrade again.
        </ConfirmationModal.Content>
      </ConfirmationModal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton onClick={handleConfirmClick} theme="delete">
            cancel my plan
          </Modal.FooterButton>
          <Modal.FooterButton onClick={onHide} theme="rainbow">
            nevermind
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default BackCatalogDisclaimerModalContents;
