import * as React from 'react';

import { block } from './utils';

const ConfirmationContent: React.FC = props => {
  const { children } = props;
  return <div className={block('content')}>{children}</div>;
};

export default ConfirmationContent;
