import * as React from 'react';

import { block } from '../utils';

export interface NoSearchResultSeparatorProps {}

const NoSearchResultSeparator: React.FC<NoSearchResultSeparatorProps> = () => (
  <span className={block('no-search-result-icons-separator')}>or</span>
);

export default NoSearchResultSeparator;
