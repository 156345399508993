import cn from 'classnames';
import * as React from 'react';
import { Col } from 'react-bootstrap';

import { block } from './utils';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  title: string;
}

const VideoOptionsSection: React.SFC<IProps> = ({
  className,
  title,
  children,
}) => (
  <div className={cn(block('section'), className)}>
    <Col xs={12} className={block('section-title')}>
      {title}
    </Col>
    {children}
  </div>
);

export default VideoOptionsSection;
export { IProps as VideoOptionsSectionProps };
