import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStaticCallback from 'hooks/useStaticCallback';
import { CreationStatus } from 'redux/middleware/api/creation-service/types';
import {
  cancelWaitForCreation,
  waitForCreation,
} from 'redux/modules/wizard-export/actions';
import {
  assetCreationStatusSelector,
  creationIdSelector,
  creationStatusExtractor,
  creationStatusSelector,
} from 'redux/modules/wizard-export/selectors';
import { Dispatch } from 'redux/types';
import { FatalError } from 'utils/FatalError';
import WizardProgressStep, {
  WizardSubmitProgress,
} from '../WizardProgressStep';

interface Props {
  onCompleted: () => void;
  onError?: (e: Error) => void;
}

export const AudiogramWizardProgressStep: React.FC<Props> = props => {
  const { onCompleted, onError } = props;
  const dispatch = useDispatch<Dispatch>();
  const creationId = useSelector(creationIdSelector);
  const creationStatus = useSelector(creationStatusSelector);
  const { audioStatus, waveformStatus } =
    useSelector(assetCreationStatusSelector) || {};
  const handleError = useStaticCallback(onError);

  useEffect(() => {
    const waitForExport = async () => {
      try {
        await dispatch(
          waitForCreation(creation =>
            [CreationStatus.COMPLETED, CreationStatus.EXPORTING_VIDEO].includes(
              creationStatusExtractor(creation),
            ),
          ),
        );
      } catch {
        handleError?.(new FatalError('Error creation video'));
      }
    };

    // hook should effectively only run when creationId changes, so this conditional
    // should be executed only once
    if (creationId) {
      waitForExport();
    }

    return () => {
      dispatch(cancelWaitForCreation());
    };
  }, [creationId, dispatch, handleError]);

  const completedSteps: WizardSubmitProgress[] = useMemo(() => {
    const steps = [];

    if (audioStatus === 'completed') {
      steps.push(WizardSubmitProgress.AUDIO_UPLOADING);
    }

    if (waveformStatus === 'completed') {
      steps.push(WizardSubmitProgress.WAVEFORM_GENERATING);
    }

    if (
      [
        CreationStatus.CREATING_CONFIG,
        CreationStatus.COMPLETED,
        CreationStatus.EXPORTING_VIDEO,
      ].includes(creationStatus)
    ) {
      steps.push(WizardSubmitProgress.IMAGE_UPLOADING);
    }

    if (
      [CreationStatus.COMPLETED, CreationStatus.EXPORTING_VIDEO].includes(
        creationStatus,
      )
    ) {
      steps.push(WizardSubmitProgress.PROJECT_CREATING);
    }

    return steps;
  }, [audioStatus, creationStatus, waveformStatus]);

  return (
    <WizardProgressStep
      steps={[
        WizardSubmitProgress.AUDIO_UPLOADING,
        WizardSubmitProgress.WAVEFORM_GENERATING,
        WizardSubmitProgress.IMAGE_UPLOADING,
        WizardSubmitProgress.PROJECT_CREATING,
      ]}
      completedSteps={completedSteps}
      onCompleted={onCompleted}
    />
  );
};

export default AudiogramWizardProgressStep;
