import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import LockIcon from 'components/IconWithTooltip';
import HistoricalColorPicker from 'containers/HistoricalColorPicker';
import FormField, { FormFieldProps } from './FormField';
import { block } from './utils';

interface IProps
  extends Pick<
    React.ComponentProps<typeof HistoricalColorPicker>,
    'onChange' | 'disableAlpha'
  > {
  locked?: boolean;
  lockedWrapperClassName?: string;
  /**
   * any valid css color string
   */
  value?: string;
}

type Props = FormFieldProps<IProps>;

interface IState {
  showLockTooltip: boolean;
}

class ColorField extends React.Component<Props, IState> {
  public static defaultProps: Partial<Props> = {
    locked: false,
    onChange: noop,
  };

  public state: Readonly<IState> = {
    showLockTooltip: false,
  };

  private handleMouseEnter = () => this.setState({ showLockTooltip: true });

  private handleMouseLeave = () => this.setState({ showLockTooltip: false });

  private renderColorPicker = () => {
    const { disabled, locked, value, ...props } = this.props;

    return (
      <FormField<IProps> {...props} disabled={disabled || locked}>
        <HistoricalColorPicker
          className={block('color-input')}
          color={value}
          componentClass="div"
          swatchClassName={block('color-swatch')}
        />
      </FormField>
    );
  };

  private renderLockedColorPicker = () => {
    const { horizontal, label, lockedWrapperClassName } = this.props;
    const { showLockTooltip } = this.state;

    return (
      <div
        className={cn(block('locked-wrapper'), lockedWrapperClassName)}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {this.renderColorPicker()}

        <LockIcon
          tooltipId="input-lock"
          className={block('input-lock', {
            vertical: !!(!horizontal && label),
          })}
          showTooltip={showLockTooltip}
        />
      </div>
    );
  };

  public render() {
    const { locked } = this.props;

    return locked ? this.renderLockedColorPicker() : this.renderColorPicker();
  }
}

export default ColorField;
export { Props as ColorFieldProps };
