import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Blur } from 'components/icons';
import RangeInput from 'components/RangeInput';

type Props = Pick<
  React.ComponentProps<typeof RangeInput>,
  'disabled' | 'value' | 'onChange'
>;

const SlideBlurField = (props: Props) => {
  const { disabled, value, onChange } = props;
  return (
    <Col xs={12}>
      <RangeInput
        min={0}
        max={10}
        step={0.1}
        icon={<Blur />}
        label="Blur"
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
    </Col>
  );
};

export default SlideBlurField;
