import * as React from 'react';

import { block } from './utils';

export interface SliderProps {
  offset?: number;
}

const Slider: React.SFC<SliderProps> = ({ offset }) => (
  <div
    className={block('slider')}
    style={{ transform: `translate(${offset}px, 0px)` }}
  />
);

Slider.defaultProps = {
  offset: 0,
};

export default Slider;
