import * as React from 'react';
import { connect } from 'react-redux';

import {
  authErrorSelector,
  authInProgressSelector,
  clearPasswordStatus,
  errorMessageSelector,
} from 'redux/modules/auth';
import { Dispatch, State, ThunkAction } from 'redux/types';
import AuthForm, { AuthFormProps as Props } from './AuthForm';

type StateProps = Pick<Props, 'inProgress' | 'error' | 'errorMessage'>;
type DispatchProps = Pick<Props, 'onChange' | 'onUnmount'>;

const handleChange = (): ThunkAction<void> => (dispatch, getState) => {
  if (authErrorSelector(getState())) {
    dispatch(clearPasswordStatus());
  }
};

const mapStateToProps = (state: State): StateProps => ({
  error: authErrorSelector(state),
  errorMessage: errorMessageSelector(state),
  inProgress: authInProgressSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onChange: () => dispatch(handleChange()),
  onUnmount: () => dispatch(clearPasswordStatus()),
});

const component = connect(mapStateToProps, mapDispatchToProps)(AuthForm);
export type AuthFormProps = React.ComponentPropsWithoutRef<typeof component>;
export default component;
