import memoize from 'memoizee';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { throttle } from 'underscore';

import usePrevious from 'hooks/usePrevious';
import { createChainedFunction } from 'utils/functions';
import { DndItemType } from '../../constants';
import {
  TrackInsertionPoint,
  TrackLabelDragItem,
  TrackLabelProps,
} from '../../types';

type Config = Pick<
  TrackLabelProps,
  | 'id'
  | 'index'
  | 'label'
  | 'onTrackDragStart'
  | 'onTrackDragStop'
  | 'onTrackDrop'
  | 'onTrackMoveIntent'
>;

// only call the callback function when something changes.  this combined with
// the hover throttling significantly improves performance
const handleOnTrackMoveIntent = memoize(
  (
    item: TrackLabelDragItem,
    hoverIndex: number,
    insertionPoint: TrackInsertionPoint,
    callback: TrackLabelProps['onTrackMoveIntent'],
  ) => callback(item, hoverIndex, insertionPoint),
  { max: 1 },
);

export default function useLabelDnd({
  id,
  index,
  label,
  onTrackDragStart,
  onTrackDragStop,
  onTrackDrop,
  onTrackMoveIntent,
}: Config) {
  const elementRef = useRef<HTMLDivElement>();

  const [{ isDragging, item: dragItem }, dragRef, previewRef] = useDrag({
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
      item: monitor.getItem(),
    }),
    item: {
      id,
      index,
      label,
      type: DndItemType.TRACK,
    },
  });

  const prevIsDragging = usePrevious(isDragging);

  const handleHover = useMemo(
    () =>
      throttle((item: TrackLabelDragItem, monitor: DropTargetMonitor) => {
        if (!elementRef.current) {
          return;
        }

        // mouse position
        const clientOffset = monitor.getClientOffset();

        if (clientOffset === null) {
          return;
        }

        const hoverIndex = index;

        // rectangle of track label being hovered
        const hoverRect = elementRef.current.getBoundingClientRect();

        // vertical middle of label.  hovering above middle inserts above, below
        // middle inserts below
        const hoverMiddleY = (hoverRect.bottom - hoverRect.top) / 2;

        // pixels from top of track label being hovered
        const hoverClientY = clientOffset.y - hoverRect.top;

        handleOnTrackMoveIntent(
          item,
          hoverIndex,
          hoverClientY <= hoverMiddleY ? 'before' : 'after',
          onTrackMoveIntent,
        );
      }, 50),
    [index, onTrackMoveIntent],
  );

  const [, dropRef] = useDrop({
    accept: DndItemType.TRACK,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
    drop: (item: TrackLabelDragItem) => onTrackDrop(item),
    hover: handleHover,
  });

  const ref = useCallback(
    createChainedFunction(
      (el: HTMLDivElement) => {
        elementRef.current = el;
      },
      dragRef,
      dropRef,
    ),
    [dragRef, dropRef],
  );

  useEffect(() => {
    if (!prevIsDragging && isDragging) {
      onTrackDragStart(dragItem);
    } else if (prevIsDragging && !isDragging) {
      onTrackDragStop(dragItem);
    }
  }, [dragItem, isDragging, onTrackDragStart, onTrackDragStop, prevIsDragging]);

  useEffect(() => {
    previewRef(getEmptyImage(), { captureDraggingState: true });
  }, [previewRef]);

  return { ref, isDragging };
}
