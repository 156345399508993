import React from 'react';
import { LinkedinSocialCircle } from 'components/SocialCircle';
import SocialButton, { SocialButtonProps } from './SocialButton';

export interface LinkedInButtonProps
  extends Pick<SocialButtonProps, 'disabled' | 'onClick' | 'params' | 'size'> {}

const LinkedInButton: React.FC<LinkedInButtonProps> = ({
  disabled,
  onClick,
  size,
}) => (
  <SocialButton
    icon={<LinkedinSocialCircle />}
    label="LinkedIn"
    {...{ disabled, onClick, size }}
  />
);

export default LinkedInButton;
