import * as React from 'react';

import { IconProps } from './types';

const Users: React.SFC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 32" {...props}>
    <path
      fill="url(#users-gradient)"
      fillRule="evenodd"
      d="M6.75 16.714c2.482 0 4.5-2.05 4.5-4.571 0-2.522-2.018-4.572-4.5-4.572s-4.5 2.05-4.5 4.572c0 2.521 2.018 4.571 4.5 4.571zm31.5 0c2.482 0 4.5-2.05 4.5-4.571 0-2.522-2.018-4.572-4.5-4.572s-4.5 2.05-4.5 4.572c0 2.521 2.018 4.571 4.5 4.571zM40.5 19H36a4.452 4.452 0 0 0-3.171 1.329 10.449 10.449 0 0 1 5.28 7.814h4.641c1.245 0 2.25-1.022 2.25-2.286v-2.286C45 21.05 42.982 19 40.5 19zm-18 0c4.352 0 7.875-3.579 7.875-8 0-4.421-3.523-8-7.875-8s-7.875 3.579-7.875 8c0 4.421 3.523 8 7.875 8zm5.4 2.286h-.584c-1.462.714-3.086 1.143-4.816 1.143s-3.347-.429-4.816-1.143H17.1c-4.472 0-8.1 3.685-8.1 8.228v2.057C9 33.464 10.512 35 12.375 35h20.25C34.488 35 36 33.464 36 31.571v-2.057c0-4.543-3.628-8.228-8.1-8.228zm-15.729-.957A4.452 4.452 0 0 0 9 19H4.5C2.018 19 0 21.05 0 23.571v2.286c0 1.264 1.005 2.286 2.25 2.286h4.634a10.475 10.475 0 0 1 5.287-7.814z"
      transform="translate(0 -3)"
    />
  </svg>
);

export default Users;
