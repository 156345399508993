import { useState } from 'react';

export default function useAudioRegion() {
  const [regionSelected, setRegionSelected] = useState(false);

  const reset = () => {
    setRegionSelected(false);
  };

  const setSelected = () => setRegionSelected(true);
  const setUnselected = () => setRegionSelected(false);

  return {
    regionSelected,
    reset,
    setSelected,
    setUnselected,
  };
}
