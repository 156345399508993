import cn from 'classnames';
import * as React from 'react';

import bem from 'utils/bem';

export interface TipProps {
  className?: string;
  children?: React.ReactNode;
}

const block = bem('tip');

const Tip: React.FC<TipProps> = ({ children, className }) => (
  <div className={cn(block(), className)}>
    <span className={block('icon')}>Tip</span>
    <span className={block('message')}>{children}</span>
  </div>
);

export default Tip;
