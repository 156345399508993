import cn from 'classnames';
import * as React from 'react';

import { TabContentProps } from './types';
import { block } from './utils';

const TabContent: React.FC<TabContentProps> = ({
  active,
  children,
  className,
  padding,
  theme,
}) => (
  <div
    className={cn(
      block('content-pane', {
        active,
        [`padding-${padding}`]: !!padding,
        [theme]: !!theme,
      }),
      className,
    )}
  >
    {children}
  </div>
);

export default TabContent;
