import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { errorSelector } from 'redux/modules/async-audio-clipper/selectors';
import { showError } from 'redux/modules/notification';
import { getCreatePath } from 'utils/routes';

const useAsyncAudioClipperError = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const error = useSelector(errorSelector);

  useEffect(() => {
    if (error) {
      history.push(getCreatePath());
      dispatch(
        showError({
          message: 'Unable to load audio',
          dismissAfterSec: 5,
          code: 'ER018',
        }),
      );
    }
  }, [dispatch, error, history]);

  return {
    error,
    hasError: !!error,
  };
};

export default useAsyncAudioClipperError;
