import {
  fetchPrefVideoSizes,
  getMyDisplayPrefs,
} from 'redux/middleware/api/headliner-user-service';
import { loggedInSelector } from 'redux/modules/auth/selectors';
import { ThunkAction } from 'redux/types';
import { ApplicationError } from 'utils/ApplicationError';
import { getValue } from 'utils/collections';
import { Type } from './action-types';

export const getMyDisplayPref = (): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  // function gets preferences for logged in user.  if user isn't logged in,
  // request will fail so abort
  if (!loggedInSelector(getState())) {
    // dispatch success action so that components know that getting display
    // preferences is complete
    dispatch({ type: Type.DISPLAY_PREF_GET_SUCCESS });
    return;
  }

  dispatch({ type: Type.DISPLAY_PREF_GET_REQUEST });

  try {
    const res = await dispatch(getMyDisplayPrefs());
    await dispatch(fetchPrefVideoSizes());
    dispatch({
      payload: getValue(res, ['response', 'displayAdjustment']),
      type: Type.DISPLAY_PREF_GET_SUCCESS,
    });
  } catch (err) {
    dispatch({ type: Type.DISPLAY_PREF_GET_FAILURE });
    throw new ApplicationError('Error loading user settings', 'ER010');
  }
};
