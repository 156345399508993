import * as React from 'react';
import { isUndefined, noop } from 'underscore';

import ClippingOptions, { ClippingOption } from 'components/ClippingOptions';
import LoadingOverlay from 'components/LoadingOverlay';
import VideoClipper, { VideoClipperProps } from 'components/VideoClipper';
import { VideoScaling } from 'components/VideoPlayer';
import FreeTranscriptionBalance from 'containers/FreeTranscriptionBalance';
import { AspectRatioName } from 'types';
import { getAspectRatio } from 'utils/aspect-ratio';
import bem from 'utils/bem';
import { min } from 'utils/numbers';

type ClipperProps = Pick<
  VideoClipperProps,
  'src' | 'onDurationChange' | 'onSelectedMillisChange'
>;

const clipMaxDuration = spareminConfig.videoClipMaxDurationSeconds * 1000;
const PREVIEW_LENGTH_MS = 5000;

interface IProps extends ClipperProps {
  aspectRatioName: AspectRatioName;
  clipEndMillis: VideoClipperProps['endMillis'];
  clipStartMillis: VideoClipperProps['startMillis'];
  showTranscriptionBalance: boolean;
  transcriptionBalanceMillis: number;
}

interface IState {
  durationMillis: number;
  playing: boolean;
}

export default class ClipVideoStep extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onDurationChange: noop,
  };

  public state: Readonly<IState> = {
    durationMillis: 0,
    playing: false,
  };

  private clipperRef = React.createRef<VideoClipper>();

  private handlePlay = () => this.setPlaying(true);

  private handlePause = () => this.setPlaying(false);

  private handleDurationChange = (durationMillis: number) => {
    const { onDurationChange } = this.props;
    this.setState({ durationMillis });
    onDurationChange(durationMillis);
  };

  private handleClippingOptionBlur = (
    millis: number,
    option: ClippingOption,
  ) => {
    const {
      onSelectedMillisChange,
      clipStartMillis,
      clipEndMillis,
    } = this.props;
    const { durationMillis } = this.state;
    switch (option) {
      case 'start':
        onSelectedMillisChange(
          millis,
          min(clipEndMillis, millis + clipMaxDuration),
        );
        break;

      case 'end':
        onSelectedMillisChange(
          clipStartMillis,
          min(millis, clipStartMillis + clipMaxDuration),
        );
        break;

      case 'duration':
        onSelectedMillisChange(
          clipStartMillis,
          min(durationMillis, clipStartMillis + min(millis, clipMaxDuration)),
        );
        break;

      default:
    }
  };

  private handleStartPreview = () => {
    const { clipStartMillis, clipEndMillis } = this.props;
    this.clipperRef.current.playSection(
      clipStartMillis,
      Math.min(clipStartMillis + PREVIEW_LENGTH_MS, clipEndMillis),
    );
  };

  private handleEndPreview = () => {
    const { clipStartMillis, clipEndMillis } = this.props;
    this.clipperRef.current.playSection(
      Math.max(clipEndMillis - PREVIEW_LENGTH_MS, clipStartMillis),
      clipEndMillis,
    );
  };

  private setPlaying(playing: boolean) {
    this.setState({ playing });
  }

  public render() {
    const {
      aspectRatioName,
      clipEndMillis,
      clipStartMillis,
      onSelectedMillisChange,
      src,
      showTranscriptionBalance,
    } = this.props;
    const { durationMillis, playing } = this.state;

    const ratio = getAspectRatio(aspectRatioName);
    const ratioString = `${ratio.get('width')}:${ratio.get('height')}`;

    const b = bem('clip-video-step');

    return (
      <div className={b()}>
        {isUndefined(durationMillis) && (
          <LoadingOverlay className={b('overlay')} title="Loading Video" />
        )}
        <VideoClipper
          aspectRatio={ratioString}
          endMillis={clipEndMillis}
          lengthMillis={durationMillis}
          onDurationChange={this.handleDurationChange}
          onPause={this.handlePause}
          onPlay={this.handlePlay}
          onSelectedMillisChange={onSelectedMillisChange}
          playerClassName={`clip-video-step__player--${aspectRatioName}`}
          playing={playing}
          scaling={VideoScaling.FIT}
          src={src}
          startMillis={clipStartMillis}
          ref={this.clipperRef}
        />
        <ClippingOptions
          className={b('form')}
          endMillis={clipEndMillis}
          maxMillis={min(durationMillis, clipStartMillis + clipMaxDuration)}
          onBlur={this.handleClippingOptionBlur}
          startMillis={clipStartMillis}
          noWrap
          onStartPreview={this.handleStartPreview}
          onEndPreview={this.handleEndPreview}
        />
        {showTranscriptionBalance && (
          <div className={b('balance-container')}>
            <FreeTranscriptionBalance
              className={b('balance')}
              source="wizard"
            />
          </div>
        )}
      </div>
    );
  }
}
