import * as React from 'react';
import { isUndefined, noop, omit } from 'underscore';

import { Size } from './types';

const DEFAULT_MIN_SIZE: Readonly<Size> = {
  height: 0,
  width: 0,
};

export interface WithBrowserSizeMonitorProps {
  onResizeAboveMin?: (size: Size, min: Size) => void;
  onResizeBelowMin?: (size: Size, min: Size) => void;
  onUnmount?: () => void;
  windowSize?: Size;
}

export default function(minSize = DEFAULT_MIN_SIZE) {
  return <Props extends {}>(WrappedComponent: React.ComponentType<Props>) =>
    class WithBrowserSizeMonitor extends React.Component<
      Props & WithBrowserSizeMonitorProps
    > {
      public static defaultProps: Partial<
        Props & WithBrowserSizeMonitorProps
      > = {
        onResizeAboveMin: noop,
        onResizeBelowMin: noop,
        onUnmount: noop,
        windowSize: {},
      } as any;

      private static hasBrowserSizeChanged(
        newDimensions: Size = {},
        dimensions: Size = {},
      ) {
        const { height, width } = dimensions;
        const { height: newHeight, width: newWidth } = newDimensions;

        return newWidth !== width || newHeight !== height;
      }

      private static compareBrowserSizeToMin(dimensions: Size = {}) {
        const { height, width } = dimensions;
        const { height: minHeight, width: minWidth } = minSize;

        if (isUndefined(height) || isUndefined(width)) {
          return undefined;
        }

        const h = height - minHeight;
        const w = width - minWidth;

        if (h < 0 || w < 0) {
          return -1;
        }

        if (h > 0 || w > 0) {
          return 1;
        }

        return 0;
      }

      constructor(props: Props & WithBrowserSizeMonitorProps) {
        super(props);

        const { onResizeBelowMin, windowSize } = props;
        if (WithBrowserSizeMonitor.compareBrowserSizeToMin(windowSize) < 0) {
          onResizeBelowMin(windowSize, minSize);
        }
      }

      public UNSAFE_componentWillReceiveProps(nextProps) {
        const { onResizeAboveMin, onResizeBelowMin, windowSize } = this.props;
        const { windowSize: nextWindowSize } = nextProps;

        if (
          WithBrowserSizeMonitor.hasBrowserSizeChanged(
            nextWindowSize,
            windowSize,
          )
        ) {
          const currentComparison = WithBrowserSizeMonitor.compareBrowserSizeToMin(
            windowSize,
          );
          const nextComparison = WithBrowserSizeMonitor.compareBrowserSizeToMin(
            nextWindowSize,
          );

          if (
            (currentComparison >= 0 || isUndefined(currentComparison)) &&
            nextComparison < 0
          ) {
            // browser was big but then made too small or browser started off as being too small
            onResizeBelowMin(nextWindowSize, minSize);
          }

          if (currentComparison < 0 && nextComparison >= 0) {
            // browser was too small but then made big
            onResizeAboveMin(nextWindowSize, minSize);
          }
        }
      }

      public componentWillUnmount() {
        const { onUnmount } = this.props;
        onUnmount();
      }

      public render() {
        const restProps = omit(
          this.props,
          'onResizeAboveMin',
          'onResizeBelowMin',
          'onUnmount',
          'windowSize',
        );
        return <WrappedComponent {...restProps} />;
      }
    };
}
