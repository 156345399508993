import * as React from 'react';

const { useEffect, useState } = React;

export interface ProjectsPageTabControlProps {
  active?: boolean;
  children?: React.ReactNode;
  lazy?: boolean;
}

const ProjectsPageTabControl: React.FC<ProjectsPageTabControlProps> = ({
  children,
  active,
  lazy,
}) => {
  const [seen, setSeen] = useState(active);

  useEffect(() => {
    if (active) {
      setSeen(true);
    }
  }, [active]);

  return lazy && !seen ? null : <>{children}</>;
};

export default ProjectsPageTabControl;
