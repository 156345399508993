import React from 'react';
import { IconProps } from './types';

function Feedback(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={16}
      viewBox="0 0 18 16"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1="0%"
          x2="100%"
          y1="10.494%"
          y2="89.506%"
        >
          <stop offset="0%" stopColor="#5DBEFC" />
          <stop offset="100%" stopColor="#DD92FE" />
        </linearGradient>
      </defs>
      <path
        d="M1258 35c-4.971 0-9 3.325-9 7.429 0 1.771.752 3.392 2.004 4.667-.44 1.8-1.91 3.404-1.927 3.422a.286.286 0 00-.052.31.273.273 0 00.256.172c2.331 0 4.078-1.136 4.943-1.836 1.15.44 2.426.693 3.776.693 4.971 0 9-3.325 9-7.428 0-4.104-4.029-7.429-9-7.429z"
        transform="translate(-1249 -35)"
        fill="url(#prefix__a)"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default Feedback;
