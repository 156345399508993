import React from 'react';
import { useDispatch } from 'react-redux';

import { Tier } from 'redux/middleware/api/plan-service';
import { onIncreaseUploadLimit } from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal';

import DurationLimitModalContents from './DurationLimitModalContents';

const DurationLimitModalContentsContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const handleIncreaseDurationLimit = React.useCallback(() => {
    dispatch(onIncreaseUploadLimit('fullEpisode'));
    dispatch(
      replaceModal({
        name: 'AddBillingCycle',
        params: {
          tier: Tier.PRO,
          defaultSubscriptionPeriod: 'yearly',
        },
      }),
    );
  }, [dispatch]);

  return (
    <DurationLimitModalContents
      onIncreaseDurationLimit={handleIncreaseDurationLimit}
    />
  );
};

export default DurationLimitModalContentsContainer;
