import cn from 'classnames';
import * as React from 'react';

import { BetaIcon } from 'components/icons';
import Link from 'components/Link';
import { ProjectButtonProps } from '../../components/buttons/types';
import { block } from './utils';

export default class ProjectButton extends React.Component<ProjectButtonProps> {
  public static defaultProps: Partial<ProjectButtonProps> = {
    animate: true,
    beta: false,
    style: {},
    sizes: {
      md: true,
      lg: true,
    },
  };

  private handleClick = (e: React.MouseEvent<any>) => {
    const { onClick, projectType, to } = this.props;
    onClick(projectType, to, e);
  };

  public render() {
    const {
      animate,
      beta,
      className,
      description,
      disabled,
      icon,
      slideClassName,
      style,
      title,
      titleClassName,
      to,
      sizes,
      ...rest
    } = this.props;

    return (
      <div className={cn(block({ disabled, ...sizes }), className)}>
        <Link
          {...rest}
          className={block('link', { animate })}
          onClick={this.handleClick}
          style={style}
          to={to}
        >
          <div className={block('body')}>
            {beta && <BetaIcon className={block('beta')} />}
            <div className={cn(block('slide'), slideClassName)}>
              <div className={block('icon-container')}>
                {React.cloneElement(icon, {
                  className: cn(block('icon'), icon.props.className),
                })}
              </div>
              <div className={cn(block('title'), titleClassName)}>{title}</div>
              <div className={block('description')}>{description}</div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export { ProjectButton };
