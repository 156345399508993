import * as React from 'react';
import { connect } from 'react-redux';

import { Navigator } from 'components/Wizard';
import * as mixpanelActions from 'redux/modules/mixpanel/actions';
import {
  clearNotification,
  showError,
} from 'redux/modules/notification/actions';
import { goToCreate, goToProject } from 'redux/modules/router/actions';
import {
  cancelPolling,
  clearTextWizardState,
  createTextProject,
  isSummarizingSelector,
  parsedTextSelector,
  projectIdSelector,
  summarizeUrl,
  summarySelector,
} from 'redux/modules/text-wizard';
import { Dispatch, State, ThunkAction } from 'redux/types';
import { getDuration } from 'utils/audio';
import { MIXPANEL_WIZARD_STEP_MAP } from './constants';
import { Steps, TextWizardProps, TextWizardState } from './TextWizard';

type StateProps = Pick<
  TextWizardProps,
  'originalArticle' | 'summarizing' | 'summary'
>;
type DispatchProps = Pick<
  TextWizardProps,
  | 'onCancel'
  | 'onMount'
  | 'onNextClick'
  | 'onStepChange'
  | 'onUnmount'
  | 'onComplete'
>;

const ERROR_ID = 'text-wizard-error';

const onComplete = (): ThunkAction<void> => (dispatch, getState) => {
  const projectId = projectIdSelector(getState());
  dispatch(goToProject({ projectId, history: false, fromWizard: true }));
};

const nextClickAction = (
  nav: Navigator,
  state: TextWizardState,
): ThunkAction<void> => async dispatch => {
  if (state.stepIndex === Steps.IMPORT_TEXT) {
    dispatch(clearNotification(ERROR_ID));
    dispatch(summarizeUrl(state.url))
      .then(() => nav.next())
      .catch(() =>
        dispatch(
          showError(
            "We're not able to read the URL. Please try a different one.",
            0,
            undefined,
            ERROR_ID,
          ),
        ),
      );
    return;
  }

  if (state.stepIndex === Steps.SELECT_AUDIO) {
    const audioUrl =
      state.audio === 'none' ? undefined : state.audio.get('audioUrl');
    const originalAudioDuration = audioUrl && (await getDuration(audioUrl));

    dispatch(
      mixpanelActions.onCompleteWizard({
        originalAudioDuration,
        audioSource: 'upload',
        type: 'textToVideo',
      }),
    );
    dispatch(
      createTextProject(state.summary, state.aspectRatioName, audioUrl),
    ).catch(() => {
      dispatch(
        showError({
          message: 'There was an error creating your project.',
          code: 'ER009',
          id: ERROR_ID,
        }),
      );
      dispatch(goToCreate());
    });
  }

  nav.next();
};

const mapStateToProps = (state: State): StateProps => ({
  originalArticle: parsedTextSelector(state),
  summarizing: isSummarizingSelector(state),
  summary: summarySelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onCancel: (nav: Navigator) => {
    dispatch(
      mixpanelActions.onCancelWizard(
        'textToVideo',
        MIXPANEL_WIZARD_STEP_MAP[nav.stepId],
      ),
    );
    dispatch(goToCreate());
  },
  onMount: () => {
    dispatch(clearTextWizardState());
    dispatch(clearNotification(ERROR_ID));
    dispatch(mixpanelActions.clearMixpanel());
  },
  onNextClick: (nav, state) => dispatch(nextClickAction(nav, state)),
  onStepChange: (to, from) => {
    // when component first mounts, only "to" step will be set
    if (from && to.stepId !== 'create') {
      dispatch(
        mixpanelActions.onWizardNext({
          step: MIXPANEL_WIZARD_STEP_MAP[from.stepId],
          type: 'textToVideo',
        }),
      );
    }
  },
  onUnmount: () => {
    dispatch(cancelPolling());
  },
  onComplete: () => {
    dispatch(onComplete());
  },
});

export default function(component: React.ComponentType<TextWizardProps>) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
