import { createApiAction } from '../utils';
import * as types from './types';

interface WithDirectUpload {
  directUploadBucket?: string;
  directUploadKey?: string;
}

interface UploadAudioArgs extends WithDirectUpload {
  src?: Blob | string;
  md5?: string;
  listenNotesPodcastId?: string;
  listenNotesEpisodeId?: string;
  onProgress?: (progress: number) => void;
}

interface PostRecordingArgs extends WithDirectUpload {
  file?: Blob | string;
  language?: string;
  waveGeneration?: string;
}

function apiCallAction<M extends types.ServiceMethod, A>(method: M, args: A) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export const postRecording = ({
  directUploadBucket,
  directUploadKey,
  file,
  language,
  waveGeneration,
}: PostRecordingArgs): types.UploadRecordingAction =>
  apiCallAction(types.ServiceMethod.UPLOAD_RECORDING, [
    file,
    directUploadBucket,
    directUploadKey,
    language,
    waveGeneration,
  ] as types.UploadRecordingArgs);

export function uploadAudio({
  src,
  md5,
  directUploadBucket,
  directUploadKey,
  listenNotesPodcastId,
  listenNotesEpisodeId,
  onProgress,
}: UploadAudioArgs): types.UploadAudioAction {
  return apiCallAction(types.ServiceMethod.UPLOAD_AUDIO, [
    src,
    md5,
    directUploadBucket,
    directUploadKey,
    listenNotesPodcastId,
    listenNotesEpisodeId,
    onProgress,
  ] as types.UploadAudioArgs);
}

export function getAudioUpload(id: number): types.GetAudioUploadAction {
  return apiCallAction(types.ServiceMethod.GET_AUDIO_UPLOAD, [
    id,
  ] as types.GetAudioUploadArgs);
}

export function clipAudio(
  entireAudioInstanceId: number,
  startMillis: number,
  endMillis: number,
  language: string,
  waveType: string,
  transcribe?: boolean,
): types.ClipAudioAction {
  return apiCallAction(types.ServiceMethod.CLIP_AUDIO, [
    entireAudioInstanceId,
    startMillis,
    endMillis,
    language,
    waveType,
    transcribe,
  ] as types.ClipAudioArgs);
}

export function getAudioClipByRecordingId(
  recordingId: number,
): types.GetEntireAudioClipsAction {
  return apiCallAction(types.ServiceMethod.GET_AUDIO_CLIP, [
    recordingId,
  ] as types.GetAudioClipArgs);
}

export function getEntireAudios(md5: string): types.GetEntireAudiosAction {
  return apiCallAction(types.ServiceMethod.GET_ENTIRE_AUDIOS, [
    md5,
  ] as types.GetEntireAudiosArgs);
}
