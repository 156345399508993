import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  podcastDetailEpisodesSelector,
  podcastEpisodesSelector,
  podcastsSelector,
} from 'redux/modules/entities/selectors';
import { PodcastIdentifier } from 'types';
import usePodcastCover from './usePodcastCover';

interface UsePodcastDataConfig extends Partial<PodcastIdentifier> {
  defaultEpisodeTitle?: string;
  defaultPodcastTitle?: string;
}

interface UsePodcastDataResult {
  artworkUrl: string;
  episodeTitle: string;
  podcastTitle: string;
}

export const PODCAST_NAME_PLACEHOLDER = 'My Podcast';
export const PODCAST_EPISODE_TITLE_PLACEHOLDER = 'New Episode Out Now!';

/*
 * The usePodcastData hook does not know where or how it's going to be used.  In some
 * cases, the data the hook needs is in redux as a result of podcast search.  Other
 * times, the data is in redux from loading the automation CMS.
 *
 * Podcast Strings priority:
 *
 * 1. redux state where podcast-search results are stored
 * (podcastsSelector, podcastDetailEpisodesSelector)
 *
 * 2. redux state where podcastEpisodes are stored from the automation CMS
 * (podcastEpisodesSelector)
 *
 * 3. Default podcast/episode titles passed to the hook
 *
 * 4. Placeholder podcast/episode titles
 */
const createPodcastStringsSelector = () =>
  createSelector(
    podcastsSelector,
    podcastDetailEpisodesSelector,
    podcastEpisodesSelector,
    (_, config: UsePodcastDataConfig) => config,
    (podcasts, episodes, podcastEpisodes, config) => {
      const episodeTitle =
        episodes?.getIn([config.episodeId, 'title']) ??
        podcastEpisodes?.getIn([config.episodeId, 'title']) ??
        config.defaultEpisodeTitle ??
        PODCAST_EPISODE_TITLE_PLACEHOLDER;

      const podcastTitle =
        podcasts?.getIn([config.podcastId, 'title']) ??
        podcastEpisodes?.getIn([config.episodeId, 'podcast', 'title']) ??
        config.defaultPodcastTitle ??
        PODCAST_NAME_PLACEHOLDER;

      return [podcastTitle, episodeTitle];
    },
  );

export default function usePodcastData(
  config: UsePodcastDataConfig = {},
): UsePodcastDataResult {
  const { podcastId, episodeId } = config;
  const podcastStringsSelector = useMemo(createPodcastStringsSelector, []);
  const [podcastTitle, episodeTitle] = useSelector(state =>
    podcastStringsSelector(state, config),
  );
  const { artworkUrl } = usePodcastCover({ episodeId, podcastId });

  return {
    artworkUrl,
    episodeTitle,
    podcastTitle,
  };
}
