import React from 'react';
import { useDispatch } from 'react-redux';
import {
  AudioSelectionType,
  DurationOption,
} from 'redux/modules/edit-automation';

import { replaceModal } from 'redux/modules/modal';
import useEditAutomationModal from '../hooks/useEditAutomationModal';

import { mapAudioSelectionUpdateData } from './utils';

const useEditAudioSelection = (subscriptionId?: number) => {
  const dispatch = useDispatch();
  const { onUpdateAutomation, subscription } = useEditAutomationModal(
    subscriptionId,
  );

  const onGoBackToAudioSelection = React.useCallback(() => {
    dispatch(
      replaceModal({
        name: 'EditAutomationAudioSelectionTypeModal',
        params: {
          subscriptionId,
        },
      }),
    );
  }, [dispatch, subscriptionId]);

  const onConfirmAudioSelection = React.useCallback(
    async (type: AudioSelectionType) => {
      if (type === 'HEADLINER_AI') {
        dispatch(
          replaceModal({
            name: 'EditAutomationAudioSelectionLengthModal',
            params: {
              subscriptionId,
            },
          }),
        );
      } else {
        const updateAutomationData = mapAudioSelectionUpdateData({
          selectedAudioKey: type,
          selectedDuration: undefined,
          subscription,
        });
        onUpdateAutomation(updateAutomationData, 'audio selection');
      }
    },
    [dispatch, onUpdateAutomation, subscription, subscriptionId],
  );

  const onConfirmDurationSelection = React.useCallback(
    async (duration: DurationOption, type: AudioSelectionType) => {
      const updateAutomationData = mapAudioSelectionUpdateData({
        selectedAudioKey: type,
        selectedDuration: duration,
        subscription,
      });
      onUpdateAutomation(updateAutomationData, 'audio selection');
    },
    [onUpdateAutomation, subscription],
  );

  return {
    onConfirmAudioSelection,
    onConfirmDurationSelection,
    onGoBackToAudioSelection,
  };
};

export default useEditAudioSelection;
