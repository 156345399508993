import _, { omit } from 'underscore';

import {
  getProjectTypeByProject,
  getTemplateId,
} from 'redux/modules/project/utils';
import { ImageOriginId } from 'types';
import { getValue } from 'utils/collections';
import { hasTransition } from 'utils/embed/audio-clips';
import {
  cancelReduxPoll,
  PollingTimeoutError,
  reduxPoll,
} from 'utils/redux-poll';
import { exportAudio } from '../../../utils/audio';
import { actions as recordingServiceActions } from '../../middleware/api/recording-service';
import { actions as videoExportServiceActions } from '../../middleware/api/video-export-service';
import { autosaveActionBuilder } from '../../middleware/autosave';
import { getRecordingWaveform } from '../common/actions';
import { actions as embedActions, selectors as embedSelectors } from '../embed';
import { hasImageFromOriginSelector } from '../embed/selectors';
import * as mixpanelActions from '../mixpanel';
import { getMixpanelBlurValue } from '../mixpanel/utils';
import { showError, showNotification } from '../notification/actions';
import {
  actions as projectActions,
  selectors as projectSelectors,
} from '../project';
import * as uploadActions from '../recording-upload';
import { AudioExportStatus } from './constants';
import * as embedExportSelectors from './selectors';
import * as types from './types';

const EXPORT_POLL_ID = 'app/embed-export/video-export-poll';

const requestVideoExport = ({ widgetId, ...options }) => dispatch =>
  dispatch({
    type: types.EMBED_EXPORT_REQUEST,
    payload: { widgetId, options },
  });

const embedExportSuccessAction = widgetId => ({
  type: types.EMBED_EXPORT_SUCCESS,
  payload: { widgetId },
});

const embedExportFailure = err => dispatch => {
  dispatch(
    showError({
      code: 'ER002',
      message: 'There was an error exporting your video',
    }),
  );
  dispatch({
    type: types.EMBED_EXPORT_FAILURE,
  });
};

const setVideoExportStatus = status => dispatch =>
  dispatch({
    type: types.EMBED_VIDEO_EXPORT_STATUS_SET,
    payload: { status },
  });

const setAudioExportStatus = status => dispatch =>
  dispatch({
    type: types.EMBED_AUDIO_EXPORT_STATUS_SET,
    payload: { status },
  });

const audioExportSuccessAction = {
  type: types.EMBED_AUDIO_EXPORT_SUCCESS,
};

const setExportRecordingId = recordingId => dispatch =>
  dispatch({
    type: types.EMBED_EXPORT_RECORDING_ID_SET,
    payload: { recordingId },
  });

const setRenderedConfig = configuration => dispatch =>
  dispatch({
    type: types.EMBED_EXPORT_RENDERED_CONFIG_SET,
    payload: { configuration },
  });

const embedExportSaveRequest = {
  type: types.EMBED_EXPORT_SAVE_REQUEST,
};

const embedExportSaveSuccess = {
  type: types.EMBED_EXPORT_SAVE_SUCCESS,
};

const embedExportSaveFailure = {
  type: types.EMBED_EXPORT_SAVE_FAILURE,
};

const saveRenderedAudioToConfig = recordingId => (dispatch, getState) => {
  dispatch(setAudioExportStatus(AudioExportStatus.SAVING_TO_CONFIG));
  dispatch(setExportRecordingId(recordingId));

  const config = embedSelectors.mapStateToEmbedConfig(getState());
  config.recordingId = recordingId;

  return dispatch(embedActions.createEmbedConfiguration(config)).then(res => {
    const { configuration } = res.response;
    dispatch(setRenderedConfig(configuration));
  });
};

const shouldRenderAudio = state => {
  const audio = embedSelectors.embedMainAudioSelector(state);
  const bgAudio = embedSelectors.embedBgAudioSelector(state);
  const clips = embedSelectors.audioClipsSelector(state);

  // this will only return a value when the audio doesn't need to be exported
  const recordingId = embedSelectors.embedExportRecordingIdSelector(state);
  const videoIds = embedSelectors.videoIdsSelector(state);
  const videosByIds = embedSelectors.videosByIdSelector(state);

  const hasBgAudio = bgAudio && bgAudio.length > 0;

  const hasVideoAudio = videoIds.isEmpty()
    ? false
    : videoIds.find(val => {
        const audioSrc = videosByIds.getIn([val, 'audioSrc']);
        return !_.isUndefined(audioSrc) && audioSrc != null;
      }) !== undefined;

  // audio that has been moved or cropped in audio track
  const hasModifiedAudio = !!audio && _.isUndefined(recordingId);

  const hasAudioTransition = hasTransition(clips);

  return hasVideoAudio || hasModifiedAudio || hasAudioTransition || hasBgAudio;
};

const exportEmbedAudio = () => (dispatch, getState) => {
  const audio = embedSelectors.embedAudioSelector(getState());
  const mainAudio = embedSelectors.embedMainAudioSelector(getState());
  const durationMillis = embedSelectors.embedDurationMillisSelector(getState());
  const soundwave = embedSelectors.soundwaveSelector(getState());

  if (!shouldRenderAudio(getState())) {
    return dispatch(embedActions.createEmbedConfiguration()).then(res => {
      const { configuration } = res.response;
      dispatch(setRenderedConfig(configuration));
      dispatch(audioExportSuccessAction);
    });
  }

  dispatch({ type: types.EMBED_AUDIO_EXPORT_REQUEST });

  dispatch(setAudioExportStatus(AudioExportStatus.RENDERING));

  const futExportAudio = exportAudio(
    embedSelectors.audioClipsSelector(getState()),
    durationMillis,
  )
    .then(exported => new Blob([exported], { type: 'audio/wav' }))
    .then(blob => {
      dispatch(setAudioExportStatus(AudioExportStatus.UPLOADING));
      const futUpload = dispatch(
        uploadActions.uploadRenderedAudio(
          blob,
          getValue(mainAudio, 'language'),
          getValue(soundwave, 'waveGeneration'),
        ),
      );
      const futWaveform = futUpload.then(({ recordingId }) =>
        dispatch(getRecordingWaveform(recordingId)),
      );
      return Promise.all([futUpload, futWaveform]).then(([upload]) => upload);
    });

  const futConfigWithAudio = futExportAudio.then(({ recordingId }) =>
    dispatch(saveRenderedAudioToConfig(recordingId)),
  );

  const futPublishRecording = getValue(audio, 'published')
    ? futExportAudio.then(({ recording }) =>
        dispatch(recordingServiceActions.updateRecording(recording, true)),
      )
    : Promise.resolve();

  return Promise.all([futConfigWithAudio, futPublishRecording, futExportAudio])
    .then(() => dispatch(audioExportSuccessAction))
    .catch(err => {
      dispatch({ type: types.EMBED_AUDIO_EXPORT_FAILURE });
      throw err;
    });
};

export const startVideoExport = config => dispatch =>
  dispatch(videoExportServiceActions.postVideoExport(config));

const pollUntilExported = (widgetId, dispatch, getState) =>
  reduxPoll(
    dispatch,
    () => dispatch(videoExportServiceActions.fetchVideoExportStatus(widgetId)),
    {
      id: EXPORT_POLL_ID,
      intervalMillis: spareminConfig.videoExportPollIntervalMillis,
      maxAttempts: spareminConfig.videoExportPollMaxAttempts,
      retryAttempts: spareminConfig.videoExportRetryAttempts,
      shouldContinue: (err, res, cancel) => {
        const currentWid = embedSelectors.embedWidgetIdSelector(getState());
        const exportWid = embedExportSelectors.embedExportWidgetIdSelector(
          getState(),
        );

        if (currentWid !== exportWid || !currentWid || !exportWid) {
          return cancel();
        }

        if (err) return false;

        const { response } = res;
        const id = response.result;
        const embedExport = response.entities.embedExports[id];
        if (embedExport.status === 'error') {
          throw new Error('Error generating video');
        }
        return !embedExport.isResolved;
      },
    },
  ).catch(err => {
    dispatch({ type: types.EMBED_EXPORT_FAILURE });
    const error =
      err instanceof PollingTimeoutError
        ? new Error('Application timed out waiting for video to export.')
        : err;
    throw error;
  });

function getEmailsToNotify(emailsToNotify) {
  if (emailsToNotify == null) {
    return null;
  }

  if (!Array.isArray(emailsToNotify)) {
    return [emailsToNotify];
  }

  return emailsToNotify;
}

const showExportSuccessNotification = () => dispatch =>
  dispatch(
    showNotification({
      level: 'success',
      type: 'exportSuccess',
      title: 'Your video is ready',
    }),
  );

const exportVideo = config => (dispatch, getState) => {
  if (config === undefined) {
    return Promise.reject(new Error('No export configuration specified'));
  }
  const { emailsToNotify } = config;
  const renderedWid = embedExportSelectors.renderedEmbedConfigurationWidgetIdSelector(
    getState(),
  );
  const renderedProjectId = projectSelectors.projectIdSelector(getState());
  const exportConfig = {
    ...config,
    widgetId: renderedWid,
    referrerProjectId: renderedProjectId,
    emailsToNotify: getEmailsToNotify(emailsToNotify),
  };

  dispatch(setVideoExportStatus('queued'));
  return dispatch(startVideoExport(exportConfig)).then(() =>
    pollUntilExported(renderedWid, dispatch, getState),
  );
};

const embedExportSuccess = () => (dispatch, getState) => {
  // if a save took place during the export, we don't want to update the embed configuration
  const configWid = embedSelectors.embedWidgetIdSelector(getState());
  const exportWid = embedExportSelectors.embedExportWidgetIdSelector(
    getState(),
  );
  const renderedConfig = embedExportSelectors.renderedEmbedConfigurationSelector(
    getState(),
  );

  const result =
    configWid !== exportWid
      ? Promise.resolve()
      : Promise.resolve(
          dispatch(embedActions.setEmbedConfiguration(renderedConfig)),
        ).then(() => dispatch(projectActions.updateCurrentProject()));

  return result.then(() => {
    dispatch(showExportSuccessNotification());
    return dispatch(embedExportSuccessAction(renderedConfig.wid));
  });
};

// Before doing an export, we will clear out any autosaves and do a manual save first
const exportSave = (exportConfigOverride = {}) => dispatch => {
  dispatch(
    autosaveActionBuilder(embedExportSaveRequest)
      .clear()
      .build(),
  );
  return dispatch(
    embedActions.createEmbedConfigurationFromState(exportConfigOverride),
  )
    .then(() => dispatch(embedExportSaveSuccess))
    .catch(err => {
      dispatch(embedExportSaveFailure);
      throw err;
    });
};

const onExportVideo = config => async (dispatch, getState) => {
  const { emailsToNotify } = config;
  const textTracks = embedSelectors.textTracksSelector(getState());
  const mediaTracks = embedSelectors.mediaTracksSelector(getState());
  const project = projectSelectors.projectSelector(getState());
  const projectType = getProjectTypeByProject(project);
  const projectId = projectSelectors.projectIdSelector(getState());
  const subscription = await dispatch(
    projectActions.getProjectSubscriptionIfExists(projectId),
  );

  const props = {
    canvaImage: hasImageFromOriginSelector(ImageOriginId.CANVA, getState()),
    email: emailsToNotify,
    numberOfEmails: Array.isArray(emailsToNotify) ? emailsToNotify.length : 0,
    framerate: config.frameRate,
    frameQuality: config.frameQuality,
    frameWidth: config.frameWidth,
    frameHeight: config.frameHeight,
    headlinerWatermarkEnabled: config.headlinerWatermarkEnabled,
    numberOfTextTracks: textTracks.size,
    numberOfMediaTracks: mediaTracks.size,
    projectType,
    templateId: getTemplateId(project),
    introVideo: config.introVideo,
    outroVideo: config.outroVideo,
    blurValue: getMixpanelBlurValue(
      embedSelectors.blurRadiusSelector(getState()),
    ),
  };

  if (subscription) {
    props.templateType = subscription.templateType;
    props.templateName = subscription.templateName;
  }

  dispatch(mixpanelActions.onExportVideo(props));
};

/**
 * Watermark logic
 *  - if the config doesn't contain a `logoImage`, then no action is taken.  If there's a logo in
 *    redux state, then it will be in each configuration that gets created by save/export
 *    operations
 *  - if the config contains a `logoImage` of type File, that file is uploaded and any existing logo
 *    is replaced
 *  - if the config contains a `logoImage` that is of any type other than File, it is assumed that
 *    this is the id of a logo that should be deleted.  once deleted, eacn configuration created
 *    by save or export operations will not have the logo
 *
 * @param {object} config - should contain form fields according to the API spec. `widgetId` will
 *    be added to the config by the action creator and doesn't need to be passed in with the config
 */
export const exportEmbedVideo = config => (dispatch, getState) => {
  // clear any old video export state and any polling that might be happening
  cancelReduxPoll(dispatch, EXPORT_POLL_ID);
  dispatch({ type: types.EMBED_VIDEO_EXPORT_STATUS_CLEAR });

  dispatch(onExportVideo(config));

  // As the new intro/outro arrangement does not include intro or outro at export (it
  // takes it to the embed config) intro and outro are removed from export config and
  // sent to exportSave as an override for the embed config that is made after saved.
  // This will save the config before sending it to the BE.
  const { intro, outro } = config;
  const exportConfig = omit(config, ['intro', 'outro']);
  const exportConfigOverride = { edgeVideos: { intro, outro } };

  return dispatch(exportSave(exportConfigOverride))
    .then(() => {
      // "source" because by the time exporting is done, we may have created one more
      // configuration with the rendered audio's recording id
      const sourceWidgetId = embedSelectors.embedWidgetIdSelector(getState());
      dispatch(
        requestVideoExport({
          widgetId: sourceWidgetId,
          ...exportConfig,
        }),
      );
    })
    .then(() => dispatch(exportEmbedAudio()))
    .then(() => dispatch(exportVideo(exportConfig)))
    .then(() => dispatch(embedExportSuccess()))
    .catch(err => dispatch(embedExportFailure(err)));
};

export const clearEmbedExport = () => dispatch =>
  dispatch({ type: types.EMBED_EXPORT_CLEAR });

export const cancelEmbedExportPolling = () => dispatch => {
  cancelReduxPoll(dispatch, EXPORT_POLL_ID);
};

export default {
  exportEmbedVideo,
  cancelEmbedExportPolling,
};
