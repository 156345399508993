import { useCallback } from 'react';

import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import { useModalManager } from './ModalManager';
import { MediaAssetType } from './types';
import useMainMedia from './useMainMedia';

const ASSET_TYPE_TO_ACTION_MAP: Record<
  MediaAssetType,
  'IMAGE_DELETE' | 'VIDEOCLIP_DELETE'
> = {
  image: 'IMAGE_DELETE',
  videoClip: 'VIDEOCLIP_DELETE',
};

export default function useDeleteMedia() {
  const mainMedia = useMainMedia();
  const dispatch = useTemplateDispatch();
  const modalManager = useModalManager();

  return useCallback(() => {
    if (mainMedia.type === 'none') {
      return;
    }

    const { asset, integration, type } = mainMedia;

    integration?.onDeleteMedia(modalManager, () => {
      dispatch({
        payload: { id: asset?.id },
        type: ASSET_TYPE_TO_ACTION_MAP[type],
      });
    });
  }, [dispatch, mainMedia, modalManager]);
}
