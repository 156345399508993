import { useEffect } from 'react';

import { WaveSurferProps } from './types';

/**
 * wavesurfer.js' responsive feature only listens to window resize events.
 * for timeline use case, we need to redraw the waveform whenever the width of
 * the asset changes.
 *
 * this hook will listen for changes to props that should cause a redraw and
 * will redraw the waveform.
 */
export default function useResponsive(
  { options }: WaveSurferProps,
  width: number,
  wavesurfer,
) {
  useEffect(() => {
    if (!wavesurfer) {
      return undefined;
    }

    const redraw = () => wavesurfer.drawBuffer();

    if (options.responsive && wavesurfer) {
      if (wavesurfer.isReady) {
        redraw();
      } else {
        wavesurfer.on('ready', redraw);
        return () => wavesurfer.un('ready', redraw);
      }
    }
    return undefined;
  }, [
    options.responsive,
    options.height,
    options.minPxPerSec,
    width,
    wavesurfer,
  ]);

  useEffect(() => {
    if (!wavesurfer) {
      return undefined;
    }
    let prevWidth = 0;
    const onResize = wavesurfer.util.debounce(() => {
      if (prevWidth !== wavesurfer.drawer.getWidth()) {
        prevWidth = wavesurfer.drawer.getWidth();
        wavesurfer.drawer.fireEvent('redraw');
      }
    });
    window.addEventListener('resize', onResize, true);

    return () => {
      window.removeEventListener('resize', onResize, true);
    };
  }, [wavesurfer]);
}
