import React from 'react';
import Heading from 'components/Heading';
import { infoBodyBlock as block } from './utils';

export interface ToggleCardInfoBodyProps {
  chilren?: React.ReactNode;
  icon: React.ReactNode;
  title: string;
}

const ToggleCardInfoBody: React.FC<ToggleCardInfoBodyProps> = ({
  children,
  icon,
  title,
}) => (
  <div className={block()}>
    <div className={block('header')}>
      <div className={block('icon')}>{icon}</div>
      {title}
    </div>
    <Heading level={2} className={block('title')}>
      {children}
    </Heading>
  </div>
);

export default ToggleCardInfoBody;
