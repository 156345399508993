import { useSelector } from 'react-redux';
import { nameSelector } from 'redux/modules/auth';

export default () => {
  const name = useSelector(nameSelector);
  const displayName = (name?.length ?? 0) === 0 || name.length > 25 ? '' : name;
  const title = `Welcome to Headliner${displayName ? `, ${displayName}` : ''}!`;

  return { title };
};
