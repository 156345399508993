import React from 'react';

import LegacyEditTextModal from 'containers/LegacyEditTextModal';

import EditTextModal from './EditTextModal';
import useEditTextModalSwitcher from './useEditTextModalSwitcher';

const EditTextModalContainer: React.FunctionComponent = () => {
  const {
    activeModal,
    onExited,
    onHide,
    onToggleLegacyMode,
    selectedTrackId,
    transitionOverlay,
  } = useEditTextModalSwitcher();

  return (
    <>
      <LegacyEditTextModal
        onEditAssetHide={onHide}
        onExited={onExited}
        onSwitchLegacyMode={onToggleLegacyMode}
        show={activeModal === 'legacy'}
        transitionOverlay={transitionOverlay}
      />
      <EditTextModal
        onExited={onExited}
        onHide={onHide}
        onSwitchToLegacy={onToggleLegacyMode}
        selectedTrackId={selectedTrackId}
        show={activeModal === 'current'}
        transitionOverlay={transitionOverlay}
      />
    </>
  );
};

export default EditTextModalContainer;
