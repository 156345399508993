import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import { HeadlinerUsage } from 'types';
import IconicSelectorStep from './IconicSelectorStep';

export interface HeadlinerUsageStepProps {
  className?: string;
  onSubmit?: (value: HeadlinerUsage) => void;
}

const HeadlinerUsageStep: React.FC<HeadlinerUsageStepProps> = ({
  className,
  onSubmit,
}) => (
  <IconicSelectorStep
    className={className}
    onClick={onSubmit}
    options={[
      {
        icon: <FontAwesome icon="building" style={{ height: 30, width: 26 }} />,
        label: 'For a large business',
        value: 'largeCompany',
      },
      {
        icon: (
          <FontAwesome icon="store-alt" style={{ height: 30, width: 38 }} />
        ),
        label: 'For a small business',
        value: 'smallBusiness',
      },
      {
        icon: (
          <FontAwesome
            icon="hand-holding-heart"
            style={{ height: 30, width: 35 }}
          />
        ),
        label: 'For a charity or non-profit',
        value: 'nonprofit',
      },
      {
        icon: (
          <FontAwesome icon="apple-alt" style={{ height: 30, width: 26 }} />
        ),
        label: 'As a teacher',
        value: 'education',
      },
      {
        icon: (
          <FontAwesome
            icon="graduation-cap"
            style={{ height: 'auto', width: 42 }}
          />
        ),
        label: 'As a student',
        value: 'education',
      },
      {
        icon: <FontAwesome icon="user" style={{ height: 30, width: 26 }} />,
        label: 'Personal',
        value: 'personal',
      },
    ]}
    title="How will you use Headliner?"
  />
);

export default HeadlinerUsageStep;
