export enum Type {
  FONT_UPLOAD_REQUEST = 'app/font/FONT_UPLOAD_REQUEST',
  FONT_UPLOAD_SUCCESS = 'app/font/FONT_UPLOAD_SUCCESS',
  FONT_UPLOAD_FAILURE = 'app/font/FONT_UPLOAD_FAILURE',

  PERSONAL_FONT_GET_REQUEST = 'app/font/PERSONAL_FONT_GET_REQUEST',
  PERSONAL_FONT_GET_SUCCESS = 'app/font/PERSONAL_FONT_GET_SUCCESS',
  PERSONAL_FONT_GET_FAILURE = 'app/font/PERSONAL_FONT_GET_FAILURE',

  GOOGLE_FONT_GET_REQUEST = 'app/font/GOOGLE_FONT_GET_REQUEST',
  GOOGLE_FONT_GET_SUCCESS = 'app/font/GOOGLE_FONT_GET_SUCCESS',
  GOOGLE_FONT_GET_FAILURE = 'app/font/GOOGLE_FONT_GET_FAILURE',

  FONT_NAME_UPDATE_REQUEST = 'app/font/FONT_NAME_UPDATE_REQUEST',
  FONT_NAME_UPDATE_SUCCESS = 'app/font/FONT_NAME_UPDATE_SUCCESS',
  FONT_NAME_UPDATE_FAILURE = 'app/font/FONT_NAME_UPDATE_FAILURE',

  FONT_DELETE_REQUEST = 'app/font/FONT_DELETE_REQUEST',
  FONT_DELETE_SUCCESS = 'app/font/FONT_DELETE_SUCCESS',
  FONT_DELETE_FAILURE = 'app/font/FONT_DELETE_FAILURE',

  RECENT_FONT_ADD = 'app/font/RECENT_FONT_ADD',
}
