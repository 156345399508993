import { Button, Spacer, Text } from '@sparemin/blockhead';
import React from 'react';

import AutomationPostModalHeader from 'components/AutomationPostModalHeader';

import DynamicElementsPopper from 'components/DynamicElementsPopper';
import EducationalCard from 'components/EducationalCard/EducationalCard';
import ModalContentWithMedia from 'components/Modal/ModalContentWithMedia';
import ShareFormTextarea from 'components/ShareForm/ShareFormTextarea';
import SocialAccountInfo from 'components/SocialAccountInfo';
import { Step } from 'components/SteppedModal';
import {
  captionsMetadata,
  MAX_CHARS_CAPTIONS,
} from 'containers/ThreadsPostModal/constants';

import { block } from '../../utils';
import { ThreadsPostModalSteps } from '../types';
import { UsePostComposerBaseConfig } from './types';

export interface UsePostComposerStepConfig extends UsePostComposerBaseConfig {
  description: string;
  allowSubmit: boolean;
  onDescriptionChange: (newDescription: string) => void;
  onHideModal?: () => void;
  onSubmit?: () => void;
}

export default function usePostComposerStep({
  username,
  avatarSrc,
  description,
  allowSubmit,
  onSwitchAccountClick,
  onDescriptionChange,
  onHideModal,
  onSubmit,
}: UsePostComposerStepConfig): Step<ThreadsPostModalSteps> {
  return {
    id: 'composer',
    hideFooter: true,
    replaceBody: true,
    component: (
      <>
        <ModalContentWithMedia
          className="composer-step-content"
          onClose={onHideModal}
        >
          <ModalContentWithMedia.ModalContentWithMediaColumn>
            <img
              src="https://static.sparemin.com/static/webapp-assets/auto-posting-detail/auto-posting-detail-threads-image-a-202408162030.png"
              className={block('composer-image')}
            />
          </ModalContentWithMedia.ModalContentWithMediaColumn>

          <ModalContentWithMedia.ModalContentWithMediaColumn className="composer-content-column">
            <Spacer space="20px" orientation="vertical" align="flex-start">
              <SocialAccountInfo
                {...{
                  username,
                  avatarSrc,
                  onSwitchAccountClick,
                }}
              />

              <AutomationPostModalHeader
                title="Let’s set up your video captions"
                description="We'll post these captions with each video. You can pull information from your RSS feed, such as episode titles, to make each post unique."
              />

              <ShareFormTextarea
                hideProgress
                type="textarea"
                maxChars={MAX_CHARS_CAPTIONS}
                value={description}
                className={block('description-container')}
                controlClassName={block('description-input')}
                label={
                  <Text variant="label">
                    <b>Example of your captions</b> (up to {MAX_CHARS_CAPTIONS}{' '}
                    characters)
                  </Text>
                }
                onChange={onDescriptionChange}
              />

              <EducationalCard>
                <DynamicElementsPopper
                  buttonContent="add content from your rss"
                  elementsList={captionsMetadata}
                  onClick={metaValue =>
                    onDescriptionChange(`${description} ${metaValue}`)
                  }
                />
              </EducationalCard>
            </Spacer>

            <Button
              fluid
              variant="solid"
              onPress={onSubmit}
              isDisabled={!allowSubmit}
            >
              confirm auto-posting details
            </Button>
          </ModalContentWithMedia.ModalContentWithMediaColumn>
        </ModalContentWithMedia>
      </>
    ),
  };
}
