import cn from 'classnames';
import React from 'react';
import { cloneOnlyChild } from 'utils/react';
import { block } from './utils';

export interface ListItemArtworkProps {
  children?: React.ReactNode;
}

const ListItemArtwork: React.FC<ListItemArtworkProps> = ({ children }) =>
  cloneOnlyChild(children, child => ({
    className: cn(block('item-artwork'), child.props.className),
  }));

export default ListItemArtwork;
