import { isEqual } from 'underscore';
import { keyIn } from 'utils/collections';

import { calculateVisibleText } from 'utils/ui';

import { EditorDataV2, Size } from '../../types';

export const getBoxMaxLines = (
  textValue: string,
  draftEditorState: EditorDataV2,
  resizeDelta: Size,
): [number, number] => {
  if (!draftEditorState) {
    return [0, 0];
  }

  const draftTextStyle = draftEditorState
    .getIn(['editor', 'textStyle'])
    ?.toJS();

  const { fontSize, lineHeight, ...textStyle } = draftTextStyle;

  const height = Math.ceil(
    draftEditorState.getIn(['size', 'height']) + resizeDelta.height,
  );
  const width = draftEditorState.getIn(['size', 'width']) + resizeDelta.width;

  const containerStyle = {
    ...textStyle,
    fontSize,
    height,
    lineHeight,
    paddingBottom: '0',
    paddingLeft: '0',
    paddingRight: '0',
    paddingTop: '0',
    width,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  };

  return calculateVisibleText(textValue, containerStyle, {
    boxDecorationBreak: 'clone',
    paddingLeft: '0',
    paddingRight: '0',
    WebkitBoxDecorationBreak: 'clone',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  });
};

export const isTextRangeEmpty = (range: number[] = []): boolean =>
  range.every(entry => entry === 0);

export const shouldDisableSubmitButton = (
  textRange: [number, number],
  disableSubmitButtonFeatureEnabled = false,
): boolean => {
  if (!disableSubmitButtonFeatureEnabled) {
    return false;
  }

  return isTextRangeEmpty(textRange);
};

export const shouldShowRechunkAlert = (
  initialEditorData: EditorDataV2,
  currEditorData: EditorDataV2,
  rechunkAlertEnabled = false,
): boolean => {
  if (!rechunkAlertEnabled) {
    return false;
  }

  const hasBeenResized = !isEqual(
    initialEditorData?.get('size').toJS(),
    currEditorData?.get('size').toJS(),
  );

  const hasChangedFont = !isEqual(
    initialEditorData
      ?.getIn(['editor', 'textStyle'])
      .filter(keyIn('fontSize', 'fontFamily', 'fontWeight', 'fontStyle')),
    currEditorData
      ?.getIn(['editor', 'textStyle'])
      .filter(keyIn('fontSize', 'fontFamily', 'fontWeight', 'fontStyle')),
  );

  return hasBeenResized || hasChangedFont;
};
