import * as React from 'react';
import Banner from 'components/Banner';
import Button from 'components/Button';
import { Enterprise } from 'components/icons';
import MailToSupport from 'components/MailToSupport';

interface Props {}

const EnterpriseBanner: React.FC<Props> = () => {
  return (
    <Banner icon={<Enterprise width="60%" />}>
      <div>
        <Banner.Title>Enterprise & API</Banner.Title>
        <Banner.Content>
          Personalized pricing plans, admin settings, dedicated support and
          more.
        </Banner.Content>
      </div>
      <Banner.Actions>
        <MailToSupport
          subject="Enterprise Pricing"
          render={({ href }) => (
            <Button
              theme="submit-alt"
              href={href}
              target="_blank"
              rel="noopener noreferrer"
            >
              contact us
            </Button>
          )}
        />
      </Banner.Actions>
    </Banner>
  );
};

EnterpriseBanner.displayName = 'EnterpriseBanner';

export default EnterpriseBanner;
