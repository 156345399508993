import cn from 'classnames';
import * as React from 'react';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  fluid?: boolean;
}

/**
 * Wrapper for the centered buttons in the footer of the modal.  Responsible for properly spacing
 * the buttons
 */

const ModalFooterButtons: React.SFC<IProps> = ({
  children,
  className,
  fluid,
}) => (
  <div
    className={cn(
      'hl-modal__footer-buttons',
      { 'hl-modal__footer-buttons--fluid': fluid },
      className,
    )}
  >
    {children}
  </div>
);

export default ModalFooterButtons;
export { IProps as ModalFooterButtonsProps };
