import cn from 'classnames';
import * as playerjs from 'player.js';
import * as React from 'react';
import { isUndefined, noop } from 'underscore';

import FeaturePolicyIFrame from 'components/FeaturePolicyIFrame';
import { VideoEditorPlaybackTimeContext as VideoEditorPlaybackTimeContextType } from 'containers/VideoEditor/types';
import VideoEditorPlaybackTimeContext from 'containers/VideoEditor/VideoEditorPlaybackTimeContext';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { block } from './utils';

interface IProps {
  aspectRatio: number;
  className?: string;
  dragFixOverlay?: boolean;
  lastPlaybackPos?: number;
  onDestroy?: (pos: number) => void;
  onEnded?: playerjs.EventHandler<'ended'>;
  onPause?: playerjs.EventHandler<'pause'>;
  onPlay?: playerjs.EventHandler<'play'>;
  onSeeked?: (pos: number) => void;
  onTimeupdate?: playerjs.EventHandler<'timeupdate'>;
  onReady?: () => void;
  playing?: boolean;
  src: string;
  volume?: number;
}

export default class EmbedPlayer extends React.Component<IProps> {
  private previousContext: VideoEditorPlaybackTimeContextType;

  public static defaultProps: Partial<IProps> = {
    onDestroy: noop,
    onEnded: noop,
    onPause: noop,
    onPlay: noop,
    onReady: noop,
    onSeeked: noop,
    onTimeupdate: noop,
    volume: 70,
  };

  private player: playerjs.Player;
  private iframe: HTMLIFrameElement;
  private ready: boolean;
  private currentTime: number;

  public componentDidMount() {
    this.previousContext = this.context;
  }

  public componentDidUpdate() {
    this.previousContext = this.context;
  }

  public shouldComponentUpdate(nextProps: Readonly<IProps>) {
    const { dragFixOverlay } = this.props;
    const { dragFixOverlay: nextDragFixOverlay } = nextProps;

    return nextDragFixOverlay !== dragFixOverlay;
  }

  private getPosition(
    props: IProps = this.props,
    context: VideoEditorPlaybackTimeContextType,
  ) {
    const { lastPlaybackPos } = props;
    const { seekToSec } = context;

    return isUndefined(lastPlaybackPos) ? seekToSec : lastPlaybackPos;
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<IProps>,
    nextContext: VideoEditorPlaybackTimeContextType,
  ) {
    const { playing: nextPlaying, volume: nextVolume } = nextProps;
    const { playing, volume } = this.props;
    const position = this.getPosition(this.props, this.previousContext);
    const nextPosition = this.getPosition(nextProps, nextContext);

    if (!isUndefined(nextPlaying) && nextPlaying !== playing) {
      nextPlaying ? this.play() : this.pause();
    }

    if (!isUndefined(nextPosition) && nextPosition !== position) {
      this.withPlayer(player => player.setCurrentTime(nextPosition));
    }

    if (!isUndefined(nextVolume) && nextVolume !== volume) {
      this.withPlayer(player => player.setVolume(nextVolume));
    }
  }

  public componentWillUnmount() {
    const { onDestroy } = this.props;
    onDestroy(this.currentTime);
    this.removeEventListeners();
  }

  private handleSeeked = () => {
    const { onSeeked } = this.props;
    this.withPlayer(player => player.getCurrentTime(pos => onSeeked(pos)));
  };

  private handleReady = () => {
    const { playing, onReady } = this.props;
    const { positionSec: position } = this.context;

    if (!isUndefined(position)) {
      this.player.setCurrentTime(position);
    }

    if (playing) {
      this.player.play();
    }

    this.ready = true;
    onReady();
  };

  private handleTimeupdate = args => {
    const { onTimeupdate } = this.props;
    this.currentTime = args.seconds;
    onTimeupdate(args);
  };

  private play = () =>
    this.withPlayer(player =>
      player.getPaused(paused => paused && this.player.play()),
    );

  private pause = () =>
    this.withPlayer(player =>
      player.getPaused(paused => !paused && this.player.pause()),
    );

  private handleIFrameLoad = () => {
    const { volume } = this.props;

    this.player = new playerjs.Player(this.iframe);
    this.player.setVolume(volume);
    this.attachEventListeners();
  };

  private withPlayer<T>(fn: (player: playerjs.Player) => T): T {
    if (this.player && this.ready) {
      return fn(this.player);
    }
    return undefined;
  }

  private attachEventListeners() {
    const { onEnded, onPause, onPlay } = this.props;

    this.player.on('timeupdate', this.handleTimeupdate);
    this.player.on('play', () => onPlay());
    this.player.on('pause', () => onPause());
    this.player.on('ended', () => onEnded());
    this.player.on('seeked', this.handleSeeked);
    this.player.on('ready', this.handleReady);
  }

  private removeEventListeners() {
    this.withPlayer(player => {
      player.off('timeupdate');
      player.off('play');
      player.off('pause');
      player.off('ended');
      player.off('seeked');
      player.off('ready');
    });
  }

  private setIframe = (el: HTMLIFrameElement) => (this.iframe = el);

  public setCurrentTime = (posSecs: number): void => {
    this.player.setCurrentTime(posSecs);
  };

  public render() {
    const { aspectRatio, className, dragFixOverlay, src } = this.props;

    const aspectRatioName = getAspectRatioName(aspectRatio);

    return (
      <div
        className={cn(
          block('player', { [aspectRatioName]: !isUndefined(aspectRatioName) }),
          className,
        )}
      >
        <FeaturePolicyIFrame
          allowFullScreen
          allow="autoplay"
          className={block('iframe')}
          iframeRef={this.setIframe}
          onLoad={this.handleIFrameLoad}
          scrolling="no"
          src={src}
          style={dragFixOverlay ? { pointerEvents: 'none' } : undefined}
        />
      </div>
    );
  }
}

export { IProps as EmbedPlayerProps };

EmbedPlayer.contextType = VideoEditorPlaybackTimeContext;
