const TEXT_RESIZE_SIDE_HANDLE_OFFSET = '12.5px';
const TEXT_RESIZE_CORNER_HANDLE_OFFSET = '-9px';

export const GLOBAL_STYLES = {
  border: `2px solid #19b5fe`,
  backgroundColor: 'white',
  zIndex: 10,
};

export const VERTICAL_SIDE_HANDLE_STYLES = {
  left: `calc(50% - ${TEXT_RESIZE_SIDE_HANDLE_OFFSET})`,
  width: 25,
  height: 10,
  borderRadius: 5,
};

export const HORIZONTAL_SIDE_HANDLE_STYLES = {
  top: `calc(50% - ${TEXT_RESIZE_SIDE_HANDLE_OFFSET})`,
  width: 10,
  height: 25,
  borderRadius: 5,
};

export const CORNER_HANDLER_STYLES = {
  width: 20,
  height: 20,
  borderRadius: 10,
};

export const RESIZE_HANDLES_STYLES = {
  top: {
    ...GLOBAL_STYLES,
    ...VERTICAL_SIDE_HANDLE_STYLES,
  },
  topRight: {
    ...GLOBAL_STYLES,
    ...CORNER_HANDLER_STYLES,
    top: TEXT_RESIZE_CORNER_HANDLE_OFFSET,
    right: TEXT_RESIZE_CORNER_HANDLE_OFFSET,
  },
  right: {
    ...GLOBAL_STYLES,
    ...HORIZONTAL_SIDE_HANDLE_STYLES,
  },
  bottomRight: {
    ...GLOBAL_STYLES,
    ...CORNER_HANDLER_STYLES,
    bottom: TEXT_RESIZE_CORNER_HANDLE_OFFSET,
    right: TEXT_RESIZE_CORNER_HANDLE_OFFSET,
  },
  bottom: {
    ...GLOBAL_STYLES,
    ...VERTICAL_SIDE_HANDLE_STYLES,
  },
  bottomLeft: {
    ...GLOBAL_STYLES,
    ...CORNER_HANDLER_STYLES,
    bottom: TEXT_RESIZE_CORNER_HANDLE_OFFSET,
    left: TEXT_RESIZE_CORNER_HANDLE_OFFSET,
  },
  left: {
    ...GLOBAL_STYLES,
    ...HORIZONTAL_SIDE_HANDLE_STYLES,
  },
  topLeft: {
    ...GLOBAL_STYLES,
    ...CORNER_HANDLER_STYLES,
    top: TEXT_RESIZE_CORNER_HANDLE_OFFSET,
    left: TEXT_RESIZE_CORNER_HANDLE_OFFSET,
  },
};
