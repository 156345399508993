import React from 'react';
import { IconProps } from 'components/icons/types';

function HeadlinerMark(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="0 0 30 30"
      {...props}
    >
      <path
        d="M30 15c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0c8.284 0 15 6.716 15 15zm-13.563 1.556v1.975a2.452 2.452 0 004.903 0V9.674a2.451 2.451 0 10-4.903 0v5.204h-2.874V9.674a2.452 2.452 0 00-4.903 0v8.857a2.452 2.452 0 004.903 0v-1.975h2.874zm4.949 6.254h-.011m.012-.09h-.01m-.038-.04a.84.84 0 00-.503.168c-1.697 1.257-3.699 1.92-5.696 1.92a10.43 10.43 0 01-3.122-.472 9.638 9.638 0 01-2.854-1.448.841.841 0 00-.503-.167h-.002a.844.844 0 00-.846.845c0 .259.119.515.342.681a11.319 11.319 0 003.356 1.704c1.181.37 2.407.55 3.629.55 2.372 0 4.725-.786 6.705-2.254a.846.846 0 00-.504-1.528h-.005"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default HeadlinerMark;
