import { SuperAgentStatic } from 'superagent';
import { createRequest } from '../utils';
import * as types from './types';

const BASE_PATH = '/api/v1/eddy';
const PROJECT_PATH = `${BASE_PATH}/project`;

async function createEddyProject(
  request: SuperAgentStatic,
  [name, associatedMedia]: types.CreateEddyProjectActionArgs,
): Promise<types.CreateEddyProjectResult> {
  const { body } = await request
    .post(PROJECT_PATH)
    .send({ associatedMedias: [associatedMedia], name });

  return body;
}

export function handle(method: any, args: any, token: string) {
  const request = createRequest({
    token,
    baseUrl: spareminConfig.services.eddy,
  });

  switch (method) {
    case types.ServiceMethod.CREATE_EDDY_PROJECT:
      return createEddyProject(request, args);

    default:
      return undefined;
  }
}
