import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectPodcast } from 'redux/modules/automation-cms';

export default function usePodcastTabs(podcasts: string[]) {
  const dispatch = useDispatch();
  const [activePodcastIndex, setActivePodcastIndex] = useState(0);

  const activePodcast = useMemo(() => podcasts?.[activePodcastIndex - 1], [
    podcasts,
    activePodcastIndex,
  ]);

  const setActivePodcast = useCallback(
    (id: string | null) => {
      if (id) {
        setActivePodcastIndex(podcasts.indexOf(id) + 1);
        dispatch(selectPodcast(id));
      } else {
        setActivePodcastIndex(0);
      }
    },
    [dispatch, podcasts],
  );

  useEffect(() => {
    if (activePodcastIndex > podcasts.length) {
      setActivePodcastIndex(podcasts.length);
    }
  }, [activePodcastIndex, podcasts]);

  return { activePodcast, activePodcastIndex, setActivePodcast };
}
