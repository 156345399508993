type ErrorMessageHandler = (message: string) => void;
type ErrorHandler = (
  fn: (...args: any[]) => any,
  getMessage?: string | ((e: Error) => string),
) => void;

export const withErrorMessageHandling = (
  handleMessage: ErrorMessageHandler,
): ErrorHandler => (fn, getMessage = e => e.message) => {
  try {
    fn();
  } catch (error) {
    const message =
      typeof getMessage === 'string' ? getMessage : getMessage(error);
    handleMessage(message);
  }
};

export function withValue<T, R>(
  value: T | undefined | null,
  fn: (value: T) => R,
  defaultValue: R = undefined,
): R {
  if (value !== undefined && value !== null) {
    return fn(value);
  }
  return defaultValue;
}
