import * as Immutable from 'immutable';
import Prefixer from 'inline-style-prefixer';
import _ from 'underscore';

import { getValue, pick } from 'utils/collections';
import { camelToSnake } from './string';

const prefixer = new Prefixer();

/*
 * NOTE: special handling for boxDecorationBreak, which can only be used unprefixed or prefixed
 *  with webkit. inline-style-prefixer doesn't prefix past chrome 62 and as of 63, the webkit
 *  prefix is still required.
 */
export function prefix(css) {
  const { boxDecorationBreak, ...restCss } = css;
  const prefixed = prefixer.prefix(restCss);

  if (boxDecorationBreak) {
    prefixed.WebkitBoxDecorationBreak = boxDecorationBreak;
    prefixed.boxDecorationBreak = boxDecorationBreak;
  }

  return prefixed;
}

/* eslint-disable no-param-reassign */
export function setStyle(element, style, resolver = _.identity) {
  const prefixedStyle = prefix(style);
  Object.keys(prefixedStyle).forEach(key => {
    const currentValue = element.style[key];
    const newValue = prefixedStyle[key];
    element.style[key] =
      currentValue === '' ? newValue : resolver(newValue, currentValue, key);
  });
}
/* eslint-enable no-param-reassign */

export function createTextShadowString(
  textShadow = { x: 0, y: 0, blur: 0, color: 'rgba(0, 0, 0, 0)' },
  coordUnit = 'em',
  blurUnit = 'px',
) {
  const x = `${getValue(textShadow, 'x')}${coordUnit}`;
  const y = `${getValue(textShadow, 'y')}${coordUnit}`;
  const blur = `${getValue(textShadow, 'blur')}${blurUnit}`;
  const color = getValue(textShadow, 'color');

  return `${x} ${y} ${blur} ${color}`;
}

export function parseTextShadowString(textShadowStr) {
  const createResult = (color, x, y, blur) => ({
    color,
    blur: parseFloat(blur),
    x: parseFloat(x),
    y: parseFloat(y),
  });

  // matches strings that start with # for hex or any letter, covering named colors, rgb, hsl, etc.
  const startsWithColorRegex = /^(#|[a-zA-Z])/;
  if (startsWithColorRegex.test(textShadowStr)) {
    const [blur, y, x, ...colorParts] = textShadowStr.split(' ').reverse();
    const color = colorParts
      .reverse()
      .join(' ')
      .trim();
    return createResult(color, x, y, blur);
  }

  const [x, y, blur, ...colorParts] = textShadowStr.split(' ');
  const color = colorParts.join(' ').trim();
  return createResult(color, x, y, blur);
}

export function isBold(fontWeight) {
  return fontWeight === 700;
}

export function isItalic(fontStyle) {
  return fontStyle === 'italic';
}

export function isUnderline(textDecoration) {
  return textDecoration === 'underline';
}

export function getFontWeight(bold) {
  return bold ? 700 : 400;
}

export function getFontStyle(italic) {
  return italic ? 'italic' : 'normal';
}

export function getTextDecoration(underline) {
  return underline ? 'underline' : 'none';
}

export const PX_KEYS = ['borderRadius', 'fontSize', 'height', 'width'];
export const RENDER_OFFSCREEN_RULES = {
  /*
   * NOTE: Firefox doesn't like numbers that are too big/small here.  was previously set to
   * Number.MIN_SAFE_INTEGER and the calculations weren't coming out right, leading to the text
   * never getting truncated
   */
  left: '-5000000px',
  position: 'absolute',
  top: '-5000000px',
  visibility: 'hidden',
};

function addPx(val) {
  if (_.isNumber(val)) {
    return `${val}px`;
  }

  if (Array.isArray(val)) {
    return val.map(v => `${v}px`).join(' ');
  }

  return val;
}

export function pxify(style) {
  return Object.keys(style).reduce((acc, key) => {
    const val = PX_KEYS.indexOf(key) < 0 ? style[key] : addPx(style[key]);
    return {
      ...acc,
      [key]: val,
    };
  }, {});
}

export function getIndexOfLastVisibleWord(
  words,
  containerStyle = {},
  textStyle = {},
  parent = document.body,
) {
  // container is the root of the dom used for calculations
  const container = document.createElement('div');
  setStyle(container, pxify({ ...containerStyle, ...RENDER_OFFSCREEN_RULES }));

  // inner is where textStyles are applied so that they don't need to be applied individually
  // to each span
  const inner = document.createElement('div');
  setStyle(inner, pxify(textStyle));

  // crate a span for each word
  const spans = words.map(word => {
    const span = document.createElement('span');
    span.innerText = word;
    return span;
  });

  // setup the dom structure
  spans.forEach(span => inner.appendChild(span));
  container.appendChild(inner);
  parent.appendChild(container);

  /*
   * if the container has padding it will throw off calculations since the the spans might
   * flow outside of the area defined by the padding yet still fall within the container's
   * rectangle.  adjust the container bottom to account for padding
   */
  const containerBottomPadding = parseFloat(
    getComputedStyle(container).paddingBottom,
  );
  const containerBottom =
    container.getBoundingClientRect().bottom - containerBottomPadding;

  const getSpanBottom = _.memoize(index => {
    /*
     * a little hacky.  browsers return weird values for vertical dimensions of non-replaced
     * inline elements because the height usually doesn't affect layout. these spans have a tendency
     * to bleed past the container by a few px - which means that the algorithm will determine that
     * such words don't fit in the box, whereas rendered on screen everything looks fine.
     *
     * this wraps the span in a block element to get a height that isn't subject to this bleedover
     */
    const span = spans[index];
    const wrapper = document.createElement('span');
    setStyle(wrapper, { display: 'inline-block' });

    inner.replaceChild(wrapper, span);
    wrapper.appendChild(span);

    const { bottom } = wrapper.getBoundingClientRect();
    inner.replaceChild(span, wrapper);
    return bottom;
  });

  /*
   * binary search to find the last word that fits in the container.
   * check each span against bottom of container.  since span wraps, words will overflow at the
   * bottom
   */
  function findLastWord(lo, hi) {
    const index = Math.floor((hi + lo) / 2);
    const bottom = getSpanBottom(index);

    // if current span is outside the container, check the span to the immediate left.  if it's
    // in the container, then it must be the last one that fit and we're done
    if (bottom > containerBottom) {
      const prevIndex = index - 1;
      if (prevIndex < 0) {
        return -1;
      }

      const prevBottom = getSpanBottom(prevIndex);
      if (prevBottom <= containerBottom) {
        return prevIndex;
      }

      return findLastWord(lo, index);
    }

    // if the current span is inside the container, check the span to the immediate right. if it's
    // outside the container, then the current span must be the last one that fit and we're done
    const nextIndex = index + 1;
    if (nextIndex >= spans.length) {
      return index;
    }

    const nextBottom = getSpanBottom(nextIndex);
    if (nextBottom > containerBottom) {
      return index;
    }

    return findLastWord(index, hi);
  }

  const lastSpanIndex = findLastWord(0, spans.length);
  container.remove();

  return lastSpanIndex;
}

/**
 * obj should be the same format as React.CSSProperties
 */
export function toCssString(style = {}) {
  const pxifiedStyle = pxify(style);
  const styleRules = Object.keys(pxifiedStyle)
    .reduce((acc, key) => {
      const cssProperty = camelToSnake(key);
      const value = pxifiedStyle[key];
      acc.push(`${cssProperty}:${value};`);
      return acc;
    }, [])
    .join('');

  return `{${styleRules}}`;
}

/**
 * "Headliner" is in the function name because this is very specific to how we use styles within the
 * app - mainly padding.
 */
export function formatHeadlinerStyleRules(rules) {
  if (!rules) return undefined;

  const rulesObj = Immutable.Map.isMap(rules) ? rules.toJS() : rules;
  return Object.keys(rulesObj).reduce((acc, key) => {
    const val = rulesObj[key];
    switch (key) {
      case 'paddingTop':
      case 'paddingBottom':
      case 'paddingRight':
      case 'paddingLeft':
        // don't touch em values
        if (String(val).endsWith('em')) {
          acc[key] = val;
          break;
        }

        acc[key] = `${val}px`;
        break;

      case 'textShadow':
        acc[key] = createTextShadowString(val);
        break;

      default:
        acc[key] = val;
    }
    return acc;
  }, {});
}

/**
 * takes a position object which can contain css values for top, left, bottom, or right and a size
 * object with height and width and returns a position object with left and top.
 */
export function getUpperLeft(position, size, workspaceSize) {
  const top = getValue(position, 'top');
  const right = getValue(position, 'right');
  const bottom = getValue(position, 'bottom');
  const left = getValue(position, 'left');
  const height = getValue(size, 'height');
  const width = getValue(size, 'width');
  const workspaceHeight = getValue(workspaceSize, 'height');
  const workspaceWidth = getValue(workspaceSize, 'width');

  const isDefined = _.negate(_.isUndefined);

  const needTop = _.isUndefined(top);
  const canCalculateTop = [bottom, height, workspaceHeight].every(isDefined);

  const needLeft = _.isUndefined(left);
  const canCalculateLeft = [right, width, workspaceWidth].every(isDefined);

  // if we have top and left, nothing to do here
  if (!needTop && !needLeft) {
    return pick(position, 'top', 'left');
  }

  // if we need values but can't calculate them, just return input.  can't do anything
  if ((needTop && !canCalculateTop) || (needLeft && !canCalculateLeft)) {
    return position;
  }

  const offsetBottom = bottom + height;
  const topValue = !needTop ? top : workspaceHeight - offsetBottom;

  const offsetRight = right + width;
  const leftValue = !needLeft ? left : workspaceWidth - offsetRight;

  const result = { top: topValue, left: leftValue };

  return Immutable.Map.isMap(position) ? Immutable.fromJS(result) : result;
}

/**
 *
 * @param {*} text
 * @param {*} containerStyle
 * @param {*} textStyle
 * @param {*} root
 * @returns {[Number, Number]}
 */
export function calculateVisibleText(
  text,
  containerStyle,
  textStyle,
  root = document.body,
) {
  // TODO put in srings utils and use everywhere we're splitting string into words
  const words = Array.isArray(text) ? text : text.match(/.+?(\s+|$)/g);

  const lastVisibleIndex = getIndexOfLastVisibleWord(
    words,
    containerStyle,
    textStyle,
    root,
  );

  const indexOfLastVisibleChar =
    words
      .slice(0, lastVisibleIndex + 1)
      .reduce((sum, word) => sum + word.length, 0) - 1;

  // NB: in the case where no words fit, indexOfLastVisibleChar will be -1, which means the slice
  // will be [0, 0] and everything should render correctly
  return [0, indexOfLastVisibleChar + 1];
}
