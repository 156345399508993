import * as React from 'react';

import CopyToClipboard, {
  CopyToClipboardProps,
} from 'components/CopyToClipboard';
import bem from 'utils/bem';
import ReferralsInvitationForm from '../ReferralsInvitationForm';

type PickedClipboardProps = Pick<CopyToClipboardProps, 'onCopyClick'>;

export interface UrlInvitationFormProps extends PickedClipboardProps {
  inviteUrl: string;
}

const block = bem('url-invitation-form');

/**
 * "Form" which displays the referral invitation url that can be copied to the
 * user's clipboard.
 */

const UrlInvitationForm: React.SFC<UrlInvitationFormProps> = ({
  inviteUrl,
  onCopyClick,
}) => {
  return (
    <ReferralsInvitationForm
      className={block()}
      subtitle="Got a podcast? Mention this URL in your episodes!"
      title="Invite friends by link"
    >
      <CopyToClipboard
        className={block('clipboard')}
        disabled={!inviteUrl}
        buttonLabel="copy"
        id="referral-link"
        onCopyClick={onCopyClick}
        value={inviteUrl}
      />
    </ReferralsInvitationForm>
  );
};

export default UrlInvitationForm;
