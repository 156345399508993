import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FontAwesome from 'components/FontAwesome';
import { Emoji } from 'components/icons';
import Circular from 'components/icons/circular';
import NonIdealState from 'components/NonIdealState/NonIdealState';
import AudioUploader from 'containers/AudioUploader';
import { addingToTrack } from 'redux/modules/embed/actions/tracks';
import { firstMediaTrackIdSelector } from 'redux/modules/embed/selectors';
import {
  onExtractAudioFromVideo,
  onIncorrectFileType,
  onTranscribeVideoFile,
} from 'redux/modules/mixpanel/actions';
import { replaceModal } from 'redux/modules/modal/actions';
import { fullEpisodeDurationLimitHoursSelector } from 'redux/modules/pricing';
import { ThunkAction } from 'redux/types';
import { ApplicationError } from 'utils/ApplicationError';
import { getAudioMaxDurationSeconds } from 'utils/audiopicker';
import { SM_BLUE } from 'utils/constants';
import { isSupportedVideoFile } from 'utils/formats';
import { block, maxFileSizeMb } from './utils';

type Props = Pick<
  React.ComponentProps<typeof AudioUploader>,
  'onError' | 'statusMessage' | 'onDropAccepted' | 'defaultFile'
>;

const openAddMediaModal = (file: File): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const mediaTrackId = firstMediaTrackIdSelector(getState());
  dispatch(addingToTrack(mediaTrackId));
  dispatch(
    replaceModal({
      name: 'AddMediaModal',
      params: { file },
    }),
  );
};

function AudioUploadTab(props: Props) {
  const { onDropAccepted, onError, statusMessage, defaultFile } = props;
  const dispatch = useDispatch();
  const audioMaxDurationHours = useSelector(
    fullEpisodeDurationLimitHoursSelector,
  );
  const lastSelectedFile = useRef<File>();
  const [overlay, setOverlay] = useState<'video' | 'extract'>(null);

  const maxFileDurationSeconds = getAudioMaxDurationSeconds(
    audioMaxDurationHours,
  );

  const handleError = (error: ApplicationError, file: File) => {
    lastSelectedFile.current = file;
    if (isSupportedVideoFile(file)) {
      dispatch(onIncorrectFileType('Video'));
      setOverlay('video');
    } else {
      onError?.(error, file);
    }
  };

  const handleUploadVideoClick = () => {
    dispatch(onTranscribeVideoFile());
    dispatch(openAddMediaModal(lastSelectedFile.current));
  };

  const handleExtractClick = () => {
    dispatch(onExtractAudioFromVideo());
    setOverlay('extract');
  };

  const handleBackClick = () => {
    setOverlay(null);
  };

  return (
    <div>
      <AudioUploader
        className={block('uploader')}
        maxFileDurationSeconds={maxFileDurationSeconds}
        onDropAccepted={onDropAccepted}
        onError={handleError}
        statusMessage={statusMessage}
        maxFileSizeMb={maxFileSizeMb}
        defaultFile={defaultFile}
      />
      <div className={block('uploader-warning')}>
        The uploaded audio will replace any existing audio on the track.
      </div>
      <NonIdealState.Overlay
        visible={overlay === 'video'}
        className={block('overlay')}
      >
        <NonIdealState
          icon={
            <Circular variant="orange">
              <FontAwesome icon="video" size="sm" />
            </Circular>
          }
          title="Want to add a video file?"
          description="You’re actually in the wrong place, but we can easily fix that. Just let us know what you’re trying to do:"
        >
          <NonIdealState.Button theme="submit" onClick={handleUploadVideoClick}>
            Upload this video file
          </NonIdealState.Button>
          <NonIdealState.Button theme="submit-alt" onClick={handleExtractClick}>
            Extract audio from video
          </NonIdealState.Button>
          <NonIdealState.LinkButton onClick={handleBackClick}>
            ← Return to uploader
          </NonIdealState.LinkButton>
        </NonIdealState>
      </NonIdealState.Overlay>
      <NonIdealState.Overlay
        visible={overlay === 'extract'}
        className={block('overlay')}
      >
        <NonIdealState
          icon={<Emoji fill={SM_BLUE} width={100} height={100} />}
          title="This feature is coming soon"
          description="We’re still developing the ability to extract audio from video & will notify you when it’s available."
        >
          <NonIdealState.LinkButton onClick={handleBackClick}>
            ← Return to uploader
          </NonIdealState.LinkButton>
        </NonIdealState>
      </NonIdealState.Overlay>
    </div>
  );
}

export default AudioUploadTab;
