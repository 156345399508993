import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  claimPodcastInitFlow,
  claimPodcastRequest,
} from 'redux/modules/claim-podcast';
import { claimPodcastRequestStatusSelector } from 'redux/modules/claim-podcast/selectors';
import * as mixpanelActions from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal';
import { showNotification } from 'redux/modules/notification';

import ClaimPodcastModalContents from './ClaimPodcastModalContents';

const ClaimPodcastModalContentsContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const requestStatus = useSelector(claimPodcastRequestStatusSelector);

  const handleSelectPodcast = React.useCallback(
    async (podcastId: string) => {
      try {
        await dispatch(claimPodcastRequest(podcastId));
        dispatch(
          replaceModal({
            name: 'ClaimPodcastResultModal',
            params: {
              podcastId,
            },
          }),
        );
      } catch {
        dispatch(
          mixpanelActions.onClaimPodcastCompleteFlow('FailureGeneralError'),
        );
        dispatch(
          showNotification({
            message: 'Please try again or {{link}} so we can help',
            level: 'error',
            dismissAfterSec: 5,
            code: 'ER013',
            title: 'Sorry, we encountered an error',
            type: 'help',
            actionLabel: 'contact us',
          }),
        );
      }
    },
    [dispatch],
  );

  React.useEffect(() => {
    dispatch(claimPodcastInitFlow());
  }, [dispatch]);

  return (
    <ClaimPodcastModalContents
      onSelectPodcast={handleSelectPodcast}
      requestStatus={requestStatus}
    />
  );
};

export default ClaimPodcastModalContentsContainer;
