import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

export type CanvaDesignType =
  | 'InstagramPost'
  | 'InstagramStory'
  | 'YouTubeThumbnail';

interface PublishOpts {
  designId: string;
  exportUrl: string;
}

type OnPublish = (opts: PublishOpts) => void;

type CreateDesign = (designType: CanvaDesignType, onPublish: OnPublish) => void;

interface CanvaContextType {
  createDesign: CreateDesign;
}

const CanvaContext = React.createContext<CanvaContextType | undefined>(
  undefined,
);

export const CanvaProvider: React.FC = ({ children }) => {
  const [url, setUrl] = useState<string>();
  const [iframe, setIframe] = useState<HTMLIFrameElement>();
  const onPublishRef = useRef<OnPublish>();

  const createDesign: CreateDesign = useCallback((designType, onPublish) => {
    onPublishRef.current = onPublish;
    setUrl(`/canva?designType=${designType}`);
  }, []);

  const handleMessage = useCallback(event => {
    if (event.origin !== window.origin) {
      return;
    }

    if (event.data.type === 'onDesignPublish') {
      const publishOpts = event.data.payload;
      onPublishRef.current?.(publishOpts);
      onPublishRef.current = null;
      setUrl(undefined);
    }

    if (event.data.type === 'onDesignClose') {
      setUrl(undefined);
    }
  }, []);

  useEffect(() => {
    if (iframe) {
      window.addEventListener('message', handleMessage, false);

      return () => {
        window.removeEventListener('message', handleMessage, false);
      };
    }
    return undefined;
  }, [handleMessage, iframe]);

  return (
    <CanvaContext.Provider value={{ createDesign }}>
      {children}
      {url && (
        <iframe
          allowTransparency
          name="canva-iframe"
          ref={setIframe}
          src={url}
          style={{
            border: 'none',
            height: '100vh',
            left: 0,
            overflow: 'hidden',
            position: 'absolute',
            top: 0,
            width: '100vw',
            zIndex: 1051,
          }}
        />
      )}
    </CanvaContext.Provider>
  );
};

export function useCanva() {
  const context = useContext(CanvaContext);

  if (context === undefined) {
    throw new Error('useCanva must be used within CanvaProvider');
  }

  return context;
}
