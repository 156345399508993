import * as React from 'react';

import { IconProps } from './types';

const SocialSolidCircleYouTube: React.FC<IconProps> = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <circle fill="#222831" cx="16" cy="16" r="16" />
      <path
        d="M23.666 12.721a1.988 1.988 0 0 0-1.415-1.392C21.003 11 16 11 16 11s-5.003 0-6.251.329a1.988 1.988 0 0 0-1.415 1.392C8 13.95 8 16.511 8 16.511s0 2.562.334 3.79c.184.678.727 1.189 1.415 1.37C10.997 22 16 22 16 22s5.003 0 6.251-.329c.688-.181 1.23-.692 1.415-1.37.334-1.228.334-3.79.334-3.79s0-2.562-.334-3.79zm-9.302 6.116v-4.652l4.181 2.326-4.181 2.326z"
        fill="#BEC8D2"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SocialSolidCircleYouTube;
