import React from 'react';

import { IconProps } from './types';

const InstagramRounded: React.FunctionComponent<IconProps> = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <radialGradient
        cx="42.983%"
        cy="0%"
        fx="42.983%"
        fy="0%"
        r="103.088%"
        gradientTransform="matrix(.24291 .97005 -.97863 .24506 .325 -.417)"
        id="nj0wg4q2pc"
      >
        <stop stopColor="#F9D262" offset="0%" />
        <stop stopColor="#F6A650" offset="22.135%" />
        <stop stopColor="#F14E2C" offset="50.167%" />
        <stop stopColor="#98069F" offset="82.431%" />
        <stop stopColor="#5E18D1" offset="100%" />
      </radialGradient>
      <filter
        x="-25%"
        y="-21.9%"
        width="150%"
        height="150%"
        filterUnits="objectBoundingBox"
        id="a1brr3yiwa"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="2.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <circle id="9gr1jybklb" cx="16" cy="16" r="16" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="rotate(180 16 16)">
        <use fill="#000" filter="url(#a1brr3yiwa)" xlinkHref="#9gr1jybklb" />
        <use fill="url(#nj0wg4q2pc)" xlinkHref="#9gr1jybklb" />
      </g>
      <path
        d="M16.002 11.898A4.095 4.095 0 0 0 11.9 16a4.095 4.095 0 0 0 4.1 4.102A4.095 4.095 0 0 0 20.104 16a4.095 4.095 0 0 0-4.101-4.102zm0 6.769A2.672 2.672 0 0 1 13.335 16a2.67 2.67 0 0 1 2.667-2.667A2.67 2.67 0 0 1 18.668 16c0 1.47-1.2 2.667-2.666 2.667zm5.225-6.937a.954.954 0 0 1-.956.957.957.957 0 1 1 .957-.957zm2.717.971c-.06-1.282-.354-2.417-1.292-3.352-.936-.936-2.07-1.229-3.352-1.293-1.32-.075-5.28-.075-6.6 0-1.278.06-2.413.354-3.352 1.29-.938.934-1.228 2.07-1.292 3.352-.075 1.32-.075 5.28 0 6.6.06 1.283.354 2.418 1.292 3.353.94.936 2.07 1.229 3.352 1.293 1.32.075 5.28.075 6.6 0 1.281-.06 2.416-.354 3.352-1.293.935-.935 1.228-2.07 1.292-3.352.075-1.321.075-5.277 0-6.598zm-1.706 8.015a2.7 2.7 0 0 1-1.521 1.521c-1.053.418-3.552.322-4.715.322-1.164 0-3.666.092-4.715-.322a2.7 2.7 0 0 1-1.521-1.52c-.418-1.054-.321-3.553-.321-4.717 0-1.164-.093-3.667.321-4.716a2.7 2.7 0 0 1 1.52-1.521c1.054-.418 3.552-.322 4.716-.322 1.163 0 3.666-.092 4.715.322a2.7 2.7 0 0 1 1.52 1.52c.418 1.054.322 3.553.322 4.717 0 1.164.096 3.667-.321 4.716z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default InstagramRounded;
