import { fromJS, Map } from 'immutable';
import { createSelector } from 'reselect';
import { isUndefined } from 'underscore';

import { IDisplayAdjustment } from 'redux/middleware/api/headliner-user-service';
import { aspectRatioNameSelector } from 'redux/modules/embed/selectors';
import { State } from 'redux/types';
import { DeepImmutableMap, Soundwave, WatermarkOption } from 'types';
import { soundwavePositionToDimensions } from 'utils/embed/soundwave';
import {
  defaultWaveGenerations,
  defaultWavePositions,
  defaultWaveTypes,
  FetchStatus,
  hiddenWavePositions,
} from './constants';
import { DisplayPrefState } from './types';
import {
  getFilteredWaveOptions,
  lockedFieldsFormatter,
  mapApiTextTemplateOverrideToTemplateId,
  videoExportSettingsFormatter,
} from './utils';

const stateSelector = (state: State): DisplayPrefState =>
  state.get('displayPref', Map());

export const exportPreferencesSelector = createSelector(stateSelector, state =>
  state.get('videoExport'),
);

export const fetchStatusSelector = createSelector(
  stateSelector,
  (state): FetchStatus => state.get('fetchStatus'),
);

export const imageSearchEnginesOverrideSelector = createSelector(
  stateSelector,
  state => {
    return state.getIn([
      'mediaSearchEngine',
      'provider',
      'image',
      'overrideOptions',
    ]);
  },
);

export const videoSearchEnginesOverrideSelector = createSelector(
  stateSelector,
  state =>
    state.getIn(['mediaSearchEngine', 'provider', 'video', 'overrideOptions']),
);

export const gifSearchEnginesOverrideSelector = createSelector(
  stateSelector,
  state =>
    state.getIn(['mediaSearchEngine', 'provider', 'gif', 'overrideOptions']),
);

export const hasTextToImageAccessSelector = createSelector(
  [imageSearchEnginesOverrideSelector],
  imageEngineOverrides =>
    !imageEngineOverrides
      ? true
      : imageEngineOverrides.toJS().includes('textToImage'),
);

export const hasTextToVideoAccessSelector = createSelector(
  [videoSearchEnginesOverrideSelector],
  videoEngineOverrides =>
    !videoEngineOverrides
      ? true
      : videoEngineOverrides.toJS().includes('textToVideo'),
);

export const defaultWaveformSelector = createSelector(
  stateSelector,
  (state): DeepImmutableMap<Partial<Soundwave>> => {
    const correctFields = ['waveColor', 'waveGeneration', 'waveType'];
    const paths = {
      waveColor: ['waveform', 'color', 'default'],
      waveGeneration: ['waveform', 'generation', 'default'],
      wavePosition: ['waveform', 'position', 'default'],
      waveType: ['waveform', 'type', 'default'],
    };
    const overrides = Object.keys(paths).reduce((acc, key) => {
      const val = state.getIn(paths[key]);
      if (isUndefined(val)) {
        // filter out keys with no value
        return acc;
      }

      // these fields are in the correct format from the API
      if (correctFields.includes(key)) {
        acc[key] = val;
      }

      /*
       * here we know that the field is "position" and it's defined.  this comes back from the API
       * as a string, but we use an object to describe position so convert it
       */
      const dimensions = soundwavePositionToDimensions(val);

      if (dimensions) {
        acc.waveSize = { height: dimensions.height, width: dimensions.width };
        acc.wavePosition = { left: dimensions.left, top: dimensions.top };
      }
      return acc;
    }, {} as any);
    return fromJS(overrides);
  },
);

export const defaultWaveColorOverrideSelector = createSelector(
  stateSelector,
  state => state.getIn(['waveform', 'color', 'overrideOptions']),
);

export const defaultWaveColorReadonlySelector = createSelector(
  stateSelector,
  state => state.getIn(['waveform', 'color', 'readonly']),
);

export const defaultWaveGenerationOverrideSelector = createSelector(
  stateSelector,
  state => state.getIn(['waveform', 'generation', 'overrideOptions']),
);

export const defaultWaveGenerationReadonlySelector = createSelector(
  stateSelector,
  state => state.getIn(['waveform', 'generation', 'readonly']),
);

export const defaultWavePositionOverrideSelector = createSelector(
  stateSelector,
  state => state.getIn(['waveform', 'position', 'overrideOptions']),
);

export const defaultWavePositionReadonlySelector = createSelector(
  stateSelector,
  state => state.getIn(['waveform', 'position', 'readonly']),
);

export const defaultWaveTypeOverrideSelector = createSelector(
  stateSelector,
  state => state.getIn(['waveform', 'type', 'overrideOptions']),
);

export const defaultWaveTypeReadOnlySelector = createSelector(
  stateSelector,
  state => state.getIn(['waveform', 'type', 'readonly']),
);

export const defaultWaveformPrefOnlyEnabledSelector = createSelector(
  stateSelector,
  (state): boolean =>
    state.getIn(['waveform', 'userPreference', 'enabled', 'default']) === false,
);

export const defaultWaveGenerationsSelector = createSelector(
  defaultWaveGenerationOverrideSelector,
  overrides => getFilteredWaveOptions(defaultWaveGenerations, overrides),
);

export const defaultWavePositionsSelector = createSelector(
  defaultWavePositionOverrideSelector,
  overrides =>
    getFilteredWaveOptions(
      defaultWavePositions,
      overrides,
      hiddenWavePositions,
    ),
);

export const defaultWaveTypesSelector = createSelector(
  defaultWaveTypeOverrideSelector,
  overrides => getFilteredWaveOptions(defaultWaveTypes, overrides),
);

export const defaultWaveformEnabledSelector = createSelector(
  defaultWaveformSelector,
  waveform => !isUndefined(waveform.get('waveType')),
);

export const hasLockedCustomizationSelector = createSelector(
  defaultWaveTypeReadOnlySelector,
  defaultWaveColorReadonlySelector,
  defaultWavePositionReadonlySelector,
  defaultWaveGenerationReadonlySelector,
  (type, color, position, fidelity) => type || color || position || fidelity,
);

export const textTemplateOverrideIdsSelector = createSelector(
  stateSelector,
  state => {
    const overrides = state.getIn(['text', 'presetStyle', 'overrideOptions']);
    if (!overrides) return undefined;
    return mapApiTextTemplateOverrideToTemplateId(overrides);
  },
);

export const textTemplateShowOptionSelector = createSelector(
  stateSelector,
  state => {
    const overrides = state.getIn(['text', 'presetStyle', 'showOptions']);
    if (!overrides) return undefined;
    return mapApiTextTemplateOverrideToTemplateId(overrides);
  },
);

export const textTemplateHideOptionSelector = createSelector(
  stateSelector,
  state => {
    const overrides = state.getIn(['text', 'presetStyle', 'hideOptions']);
    if (!overrides) return undefined;
    return mapApiTextTemplateOverrideToTemplateId(overrides);
  },
);

export const defaultVideoExportSettingsSelector = createSelector(
  [exportPreferencesSelector, aspectRatioNameSelector],
  videoExportSettingsFormatter,
);

export const lockedFieldsSelector = createSelector(
  defaultVideoExportSettingsSelector,
  lockedFieldsFormatter,
);

export const defaultFullEpisodeSizePrefSelector = createSelector(
  defaultVideoExportSettingsSelector,
  (
    videoExportSettings,
  ): IDisplayAdjustment['videoExport']['fullEpisodeSize'] => {
    return videoExportSettings.fullEpisodeSize?.toJS();
  },
);

export const defaultVideoExportSettingsSelectorByRatio = (ratioName: string) =>
  createSelector([exportPreferencesSelector], prefs => {
    return videoExportSettingsFormatter(prefs, ratioName);
  });

export const lockedFieldsSelectorByRatio = (ratioName: string) =>
  createSelector(
    defaultVideoExportSettingsSelectorByRatio(ratioName),
    lockedFieldsFormatter,
  );

export const defaultLogoUrlSelector = createSelector(
  stateSelector,
  (state): string => state.getIn(['watermark', 'default']),
);

export const defaultTranscriptionEnabledSelector = createSelector(
  stateSelector,
  (state): boolean =>
    state.getIn(['audio', 'transcription', 'enabled', 'default']),
);

export const defaultInTransitionSelector = createSelector(
  stateSelector,
  (state): string => state.getIn(['text', 'inTransition', 'effect', 'default']),
);

export const defaultOutTransitionSelector = createSelector(
  stateSelector,
  (state): string =>
    state.getIn(['text', 'outTransition', 'effect', 'default']),
);

export const defaultWatermarkSelector = createSelector(
  stateSelector,
  (state): string => state.getIn(['watermark', 'default']),
);

export const headerLogoDisabledSelector = createSelector(
  stateSelector,
  (state): boolean =>
    state.getIn(['navigation', 'headerLogo', 'enabled', 'default']) === false &&
    state.getIn(['navigation', 'headerLogo', 'enabled', 'readonly']) === true,
);

export const navigationSelector = createSelector(stateSelector, state =>
  state.get('navigation'),
);

export const navigationBarHiddenSelector = createSelector(
  navigationSelector,
  navigation => navigation && navigation.getIn(['bar', 'hidden', 'default']),
);

export const watermarkOptionsSelector = createSelector(stateSelector, state =>
  state.get('watermarkOptions'),
);

export const watermarkOverrideSelector = createSelector(
  watermarkOptionsSelector,
  aspectRatioNameSelector,
  (watermarkOptions, aspectRatioName): WatermarkOption[] => {
    return watermarkOptions
      ?.getIn([aspectRatioName, 'overrideOptions'])
      ?.toJS();
  },
);

export const watermarkReadOnlySelector = createSelector(
  stateSelector,
  watermarkOptionsSelector,
  (state): boolean => state.getIn(['watermark', 'readonly']),
);

const videoShareSelector = createSelector(stateSelector, state =>
  state?.get('videoShare'),
);

export const shareMethodOverrideSelector = createSelector(
  videoShareSelector,
  videoShare =>
    videoShare
      ?.get('shareMethod')
      ?.get('overrideOptions')
      ?.toJS(),
);

export const autogramHiddenSelector = createSelector(
  navigationSelector,
  navigation => navigation?.getIn(['automation', 'hidden', 'default']),
);

export const audioSelectorMethodBlacklist = createSelector(
  stateSelector,
  state => state?.getIn(['audio', 'selectorMethods', 'hideOptions']) || [],
);
