import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { isEmpty } from 'underscore';
import { CaptionsConfig } from 'blocks/TextOverlayModal/v2';
import { getBaseCaptionsOverride } from 'utils/embed/captions/utils';
import { parseSearch } from 'utils/routes';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { useModalManager } from '../ModalManager';
import { getCaptionsFromConfig } from '../state/captions';
import AutoVideoChildViewTopSlot from './AutoVideoChildViewTopSlot';
import { CaptionsChildViewContentsProps } from './CaptionsChildViewContents';

export default function useCaptionsChildViewContentsProps(): CaptionsChildViewContentsProps {
  const { aspectRatio, captions, transcription } = useTemplateState();
  const dispatch = useTemplateDispatch();
  const modalManager = useModalManager();
  const location = useLocation();

  const query = parseSearch(location.search);
  const wizard = query.type;

  const handleTranscriptionChange = useCallback((): void => {
    const baseCaptionsOverride = !isEmpty(captions)
      ? captions
      : getCaptionsFromConfig(getBaseCaptionsOverride(aspectRatio));

    dispatch({
      type: 'TRANSCRIPTION_CHANGE',
      payload: {
        transcription: {
          ...transcription,
          transcribe: !transcription?.transcribe,
        },
      },
    });

    dispatch({
      type: 'CAPTIONS_CONFIG_UPDATE',
      payload: {
        captions: !transcription?.transcribe
          ? baseCaptionsOverride
          : ({} as CaptionsConfig),
      },
    });
  }, [aspectRatio, captions, dispatch, transcription]);

  const handleEditCaptionsStyle = useCallback((): void => {
    modalManager.showModal('edit-captions', {
      captionsOverride: captions,
      onSubmit: (captionsConfig: CaptionsConfig) => {
        dispatch({
          type: 'CAPTIONS_CONFIG_UPDATE',
          payload: {
            captions: captionsConfig,
          },
        });
      },
    });
  }, [captions, dispatch, modalManager]);

  return useMemo((): CaptionsChildViewContentsProps => {
    let propsByWizardType: Partial<CaptionsChildViewContentsProps> = {};

    switch (wizard) {
      case 'autovideo': {
        propsByWizardType = {
          topSlot: <AutoVideoChildViewTopSlot />,
          switchLabel: 'Include captions',
          editButtonLabel: 'edit caption style',
        };

        break;
      }

      default: {
        propsByWizardType = {
          switchLabel: 'Captions',
          editButtonLabel: 'edit caption style',
        };

        break;
      }
    }

    return {
      ...propsByWizardType,
      onTranscriptionChange: handleTranscriptionChange,
      onEditCaptionsStyle: handleEditCaptionsStyle,
    } as CaptionsChildViewContentsProps;
  }, [handleEditCaptionsStyle, handleTranscriptionChange, wizard]);
}
