import { SuperAgentStatic } from 'superagent';
import superagentAbsolute from 'superagent-absolute';
import superagentDefaults from 'superagent-defaults';
import { API_CALL, IApiAction } from './types';

const TYPE = 'API';

export type CreateRequestOptions = {
  baseUrl?: string;
  omitBearerPrefix?: boolean;
  token?: string;
};

export function createApiAction<S extends string, M, A>(
  service: S,
  method: M,
  args?: A,
): IApiAction<S, M, A> {
  return {
    type: TYPE,
    [API_CALL]: { args, method, service },
  };
}

export function createRequest(opts?: CreateRequestOptions): SuperAgentStatic {
  const { baseUrl, omitBearerPrefix, token } = opts ?? {};

  const defaults = superagentDefaults();

  if (token) {
    defaults.set('Authorization', omitBearerPrefix ? token : `Bearer ${token}`);
  }

  return baseUrl ? superagentAbsolute(defaults)(baseUrl) : defaults;
}
