import { ProjectCreationMethod, TemplateType } from 'types';
import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'VIDEO_PROJECT_MANAGEMENT';
export const ACTION_KEY: ActionKey = 'VIDEO_PROJECT_MANAGEMENT';

export enum ServiceMethod {
  CREATE_PROJECT = 'CREATE_PROJECT',
  GET_ALL_PROJECTS = 'GET_ALL_PROJECTS',
  GET_PROJECT_BY_ID = 'GET_PROJECT_BY_ID',
  GET_PROJECTS_BY_URL = 'GET_PROJECTS_BY_URL',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  DELETE_PROJECT = 'DELETE_PROJECT',
  GET_REVISION_HISTORY_BY_PROJECT_ID = 'GET_REVISION_HISTORY_BY_PROJECT_ID',
}

interface IProjectConfig {
  aspectRatio: {
    height: number;
    width: number;
  };
  customTraceId?: string;
  eddyProjectCuid: string | null;
  eddyProjectUrl: string | null;
  embedConfigurationUuid: string;
  isEditable: boolean;
  ownerUserId: number;
  projectName: string;
  thumbnailUrl?: string;
  traceId?: string;
  updatedAt: number;
  updatedBy: number;
}

interface IProject {
  createMethod: ProjectCreationMethod;
  createdAt: number;
  creatorUserId: number;
  latestSuccessfulVideo: {
    widgetUuid: string;
  };
  link: string;
  projectConfig: IProjectConfig;
  projectUuid: string;
}

interface IProjectResult {
  entities: {
    projects: {
      [key: string]: IProject;
    };
  };
}

interface IPage {
  next?: number;
  number: number;
  prev?: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

interface IRevisionHistory {
  embedConfigurationUuid: string;
  projectName: string;
  updatedBy: number;
  updatedAt: number;
}

interface IRevisionHistoryResult {
  entities: {
    revisionHistory: {
      [key: string]: IRevisionHistory;
    };
  };
}

export interface IAspectRatio {
  height: number;
  width: number;
}

export type CreateProjectArgs = [
  string, // id
  string, // name
  IAspectRatio,
  ProjectCreationMethod,
  string | undefined, // thumbnail url
  string | undefined, // referrer project id
  string | undefined, // trace id
  string | undefined, // custom trace id
  string | undefined, // createdFromTemplateId
  TemplateType, // createdFromTemplateType
];
export interface CreateProjectResult extends IProjectResult {
  result: string;
}

export type GetProjectByIdArgs = [string];
export interface GetProjectByIdResult extends IProjectResult {
  result: string;
}

export type GetAllProjectsArgs = [
  number,
  ProjectCreationMethod,
  number,
  number,
];
export interface GetAllProjectsResult extends IProjectResult {
  result?: string[];
  page: IPage;
}

export type GetProjectsByUrlArgs = [string /* url */];
export type GetProjectsByUrlResult = GetAllProjectsResult;

export type UpdateProjectArgs = [
  string,
  string,
  string,
  any,
  string | undefined,
];
export interface UpdateProjectResult extends IProjectResult {
  result: string;
}

export type DeleteProjectArgs = [string];
export type DeleteProjectResult = null;

export type GetRevisionHistoryByProjectIdArgs = [string, number, number];
export interface GetRevisionHistoryByProjectIdResult
  extends IRevisionHistoryResult {
  result: string[];
  page: IPage;
}

export type ServiceResults =
  | CreateProjectResult
  | GetProjectByIdResult
  | GetAllProjectsResult
  | UpdateProjectResult
  | DeleteProjectResult
  | GetRevisionHistoryByProjectIdResult;

export type IHandle = (
  method: ServiceMethod,
  args: any,
  token?: string,
) => Promise<ServiceResults>;

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export type CreateProjectAction = ApiAction<
  ServiceMethod.CREATE_PROJECT,
  CreateProjectArgs
>;
export type GetProjectByIdAction = ApiAction<
  ServiceMethod.GET_PROJECT_BY_ID,
  GetProjectByIdArgs
>;
export type GetAllProjectsAction = ApiAction<
  ServiceMethod.GET_ALL_PROJECTS,
  GetAllProjectsArgs
>;
export type GetProjectsByUrlAction = ApiAction<
  ServiceMethod.GET_PROJECTS_BY_URL,
  GetProjectsByUrlArgs
>;
export type UpdateProjectAction = ApiAction<
  ServiceMethod.UPDATE_PROJECT,
  UpdateProjectArgs
>;
export type DeleteProjectAction = ApiAction<
  ServiceMethod.DELETE_PROJECT,
  DeleteProjectArgs
>;
export type GetRevisionHistoryByProjectIdAction = ApiAction<
  ServiceMethod.GET_REVISION_HISTORY_BY_PROJECT_ID,
  GetRevisionHistoryByProjectIdArgs
>;

export interface VideoProjectManagementServiceDispatch {
  (action: CreateProjectAction): Promise<IApiResponse<CreateProjectResult>>;
  (action: GetProjectByIdAction): Promise<IApiResponse<GetProjectByIdResult>>;
  (action: GetAllProjectsAction): Promise<IApiResponse<GetAllProjectsResult>>;
  (action: GetProjectsByUrlAction): Promise<
    IApiResponse<GetProjectsByUrlResult>
  >;
  (action: UpdateProjectAction): Promise<IApiResponse<UpdateProjectResult>>;
  (action: DeleteProjectAction): Promise<IApiResponse<DeleteProjectResult>>;
  (action: GetRevisionHistoryByProjectIdAction): Promise<
    IApiResponse<GetRevisionHistoryByProjectIdResult>
  >;
}
