import * as React from 'react';
import Modal from 'components/Modal';

import { PurchaseableTier } from 'redux/middleware/api/plan-service';
import { Discount } from '../../../constants';
import { SubscriptionPeriod } from '../../../types';
import AddBillingCycleModalSection from '../AddBillingCycleModalSection';
import BogoDetails from '../BogoDetails';
import TierInfobox from '../TierInfobox';

export interface AddBillingCycleModalBodyProps {
  children?: React.ReactNode;
  costCents: number;
  discount?: string;
  subscriptionPeriod: SubscriptionPeriod;
  className?: string;
  tier: PurchaseableTier;
}

const AddBillingCycleModalBody: React.SFC<AddBillingCycleModalBodyProps> = ({
  children,
  costCents,
  discount,
  subscriptionPeriod,
  className,
  tier,
}) => (
  <Modal.Body className={className}>
    <AddBillingCycleModalSection title="Your plan">
      <TierInfobox tier={tier} />
    </AddBillingCycleModalSection>
    {children}
    {discount === Discount.BOGO && (
      <BogoDetails
        costCents={costCents}
        subscriptionPeriod={subscriptionPeriod}
      />
    )}
  </Modal.Body>
);

export default AddBillingCycleModalBody;
