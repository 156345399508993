import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'components/Carousel';
import useMeasure from 'hooks/useMeasure';
import { setSelectedPodcast } from 'redux/modules/podcast-search/actions';
import {
  favoritePodcastIdsSelector,
  selectedPodcastIdSelector,
} from 'redux/modules/podcast-search/selectors';
import bem from 'utils/bem';
import FavoritePodcastBarImage from './FavoritePodcastBarImage';

const block = bem('favorite-podcast-bar');
const SLIDE_WIDTH = 65;

interface Props {
  children: React.ReactNode;
}

function FavoritePodcastBar(props: Props) {
  const { children } = props;
  const dispatch = useDispatch();
  const favoritePodcastIds = useSelector(favoritePodcastIdsSelector);
  const selectedPodcastId = useSelector(selectedPodcastIdSelector);
  const [containerRef, { width }] = useMeasure();

  const handlePodcastClick = useCallback(
    (podcastId: string) => {
      dispatch(setSelectedPodcast(podcastId));
    },
    [dispatch],
  );

  if (Array.isArray(favoritePodcastIds) && favoritePodcastIds.length > 1) {
    return (
      <div ref={containerRef} className={block()}>
        <Carousel
          dots={false}
          slidesToShow={Math.floor(width / SLIDE_WIDTH) || 1}
          slidesToScroll={1}
          variableWidth
        >
          {favoritePodcastIds.map(podcastId => (
            <FavoritePodcastBarImage
              key={podcastId}
              podcastId={podcastId}
              onClick={handlePodcastClick}
              className={block('image', {
                selected: podcastId === selectedPodcastId,
              })}
            />
          ))}
        </Carousel>
      </div>
    );
  }

  return <>{children}</>;
}

export default FavoritePodcastBar;
