import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import useRedirectOnClose from 'hooks/useRedirectOnClose';
import OpenTemplateModalContents from './OpenTemplateModalContents';
import { block } from './utils';

export type OpenTemplateModalProps = {};

const OpenTemplateModal: React.FC<OpenTemplateModalProps> = () => {
  const { onClickLink: handleRedirect, onExited } = useRedirectOnClose();

  return (
    <ConnectedModal className={block()} name="OpenTemplate" onExited={onExited}>
      <OpenTemplateModalContents onRedirect={handleRedirect} />
    </ConnectedModal>
  );
};

export default OpenTemplateModal;
