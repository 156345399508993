import { fromJS } from 'immutable';
import { omit } from 'underscore';
import { getVideoCount } from 'blocks/AutomationCMS/utils';
import {
  MediaIntegrationId,
  SlideExport,
  TemplateEditorStateExport,
} from 'components/VideoTemplateEditor/types';
import * as podcastService from 'redux/middleware/api/podcast-service/actions';
import {
  CreateSubscriptionPreviewResult,
  DeleteSubscriptionResult,
  PodcastWorkflowTemplate,
} from 'redux/middleware/api/podcast-service/types';
import { TranscriptNotFoundError } from 'redux/middleware/api/transcript-service';
import { IApiResponse } from 'redux/middleware/api/types';
import * as projectService from 'redux/middleware/api/video-project-management-service/actions';
import * as commonActions from 'redux/modules/common/actions';
import {
  selectors as entitySelectors,
  podcastFeedEpisodesSelector,
  podcastSubscriptionsSelector,
  podcastWorkflowTemplatesSelector,
} from 'redux/modules/entities';
import { ThunkAction } from 'redux/types';
import {
  AspectRatioName,
  AutogramSubscriptionOptions,
  ISlideshowItem,
  ISoundwave,
  ITextOverlayItem,
  TemplateAdjustment,
  TemplateType,
} from 'types';
import { ApplicationError } from 'utils/ApplicationError';
import { getAspectRatio } from 'utils/aspect-ratio';
import { getValue } from 'utils/collections';
import { withValue } from 'utils/control';
import { addDefaultAudioLayers } from 'utils/embed/audio';
import {
  applyWizardCaptionsOverrideToStyle,
  createWizardCaptions,
  formatCaptionsForConfig,
} from 'utils/embed/captions';
import { formatMediaContainerForConfig } from 'utils/embed/media-container';
import { formatProgressForConfig } from 'utils/embed/progress';
import { createSlide, formatSlideForConfig } from 'utils/embed/slideshow';
import { formatSoundwaveForConfig } from 'utils/embed/soundwave';
import { formatOverlayForConfig } from 'utils/embed/text-overlay';
import { formatTimerForConfig } from 'utils/embed/timer';
import { formatWatermarkForConfig } from 'utils/embed/watermark';
import GoogleAccountMismatchError from 'utils/GoogleAccountMismatchError';
import reduxPoll, { PollingTimeoutError } from 'utils/redux-poll';
import { uploadImage } from '../common/actions/image';

import {
  deleteCaptionsSource,
  setCaptionsMediaSource,
  setupCaptions,
} from '../embed/actions';
import { captionsMediaSourceSelector } from '../embed/selectors';
import { onUpdateAutomationSubscription } from '../mixpanel/actions';
import {
  showError,
  showNotification,
  showSuccess,
} from '../notification/actions';
import { clearYoutubeUser, youtubeUserDataSelector } from '../social';
import { Type } from './action-types';
import {
  ENTIRE_EPISODE_TRANSCRIPTION_POLL_ID,
  EpisodeTranscriptError,
  PREVIEW_PROCESSING_STATES,
  SUBSCRIPTION_PREVIEW_POLL_ID,
} from './constants';
import {
  isPollingEpisodeTranscriptionsSelector,
  podcastEpisodeDataSelector,
  podcastFeedDataSelector,
  pollTranscriptEpisodeIdsSelector,
  pollTranscriptEpisodesSelector,
  subscriptionPreviewPollingIdSelector,
} from './selectors';
import { Season } from './types';
import {
  createSubscriptionTypes,
  mapSubscriptionItemToSubscriptionTypes,
} from './utils';

const PODCAST_EPISODE_PAGE_SIZE = 8;

const errorHandler = err => {
  if (!(err instanceof TranscriptNotFoundError)) {
    throw err;
  }
};

export const generateCaptions = (
  id: number,
  language = 'en-US',
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  try {
    dispatch(deleteCaptionsSource());
    await dispatch(commonActions.addToWhitelist(id, language));
    dispatch(setCaptionsMediaSource('audio', id));

    const mediaSource = captionsMediaSourceSelector(getState());

    const mediaSourceId = getValue(mediaSource, 'mediaSourceId');
    const recordings = entitySelectors.recordingsSelector(getState());
    const versionId = recordings.getIn([mediaSourceId.toString(), 'versionId']);

    return dispatch(commonActions.pollForTranscript(versionId))
      .then(() => dispatch(setupCaptions(mediaSourceId, 'audio')))
      .catch(errorHandler);
  } catch (err) {
    const error =
      err instanceof PollingTimeoutError
        ? new Error(
            'Application timed out waiting for transcription to complete',
          )
        : err;
    throw error;
  }
};

export const loadMyWorkflows = (): ThunkAction<Promise<void>> => dispatch => {
  dispatch({ type: Type.AUTOMATION_CMS_WORKFLOW_LOAD_REQUEST });

  return dispatch(podcastService.getMyPodcastSubscriptions())
    .then(({ response }) => {
      dispatch({
        payload: {
          workflows: response.result,
        },
        type: Type.AUTOMATION_CMS_WORKFLOW_LOAD_SUCCESS,
      });
    })
    .catch(() => {
      dispatch({ type: Type.AUTOMATION_CMS_WORKFLOW_LOAD_FAILURE });
    });
};

export const checkWorkflowAuthError = (
  workflowId: string,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  await dispatch(loadMyWorkflows());

  const subscriptions = podcastSubscriptionsSelector(getState());

  const targetSubscription = subscriptions?.get(String(workflowId));

  if (!targetSubscription?.get('isAuthError')) {
    throw new Error('Invalid subscription');
  }
};

const uploadImageSource = (
  src: string | Blob,
): ThunkAction<Promise<string>> => async dispatch => {
  try {
    return !src
      ? Promise.resolve(undefined)
      : dispatch(uploadImage(src)).then(({ url }) => url);
  } catch {
    throw new ApplicationError(
      'Error uploading image, unable to create project',
      'ER017',
    );
  }
};

const uploadSlide = (
  slide: SlideExport,
): ThunkAction<Promise<[string, string]>> => async dispatch => {
  // NB: the server sometimes throws a 500 error when uploading the same image
  // multiple times in parallel.  Although there's a higher chance of this happening
  // with parallel requests, the server also seems to occassionally throw the
  // error for serial requests.  To work around this:
  //  - check if the blobs are the same and if so, only upload one
  //  - when multiple images are uploaded, submit the requests serially
  const originalUrl = await dispatch(uploadImageSource(slide.originalSrc));
  const croppedUrl =
    slide.originalSrc === slide.imageSrc
      ? originalUrl
      : await dispatch(uploadImageSource(slide.imageSrc));

  return [originalUrl, croppedUrl];
};

const getSlideSources = (
  originalSlide: ISlideshowItem,
  slide: SlideExport,
): ThunkAction<Promise<
  [string | Blob, string | undefined]
>> => async dispatch => {
  const originalSrc = originalSlide?.imageUrl;
  const isSameImage =
    typeof slide.imageSrc === 'string' && slide.imageSrc === originalSrc;

  if (isSameImage) {
    return [slide.imageSrc, originalSlide.sourceImage?.url];
  }

  if (slide.integrationData?.id === MediaIntegrationId.DYNAMIC) {
    return [slide.imageSrc, undefined];
  }

  const [originalUrl, croppedUrl] = await dispatch(uploadSlide(slide));

  return [croppedUrl, originalUrl];
};

const createSlideshow = (
  state: TemplateEditorStateExport,
  aspectRatioName: AspectRatioName,
  template: PodcastWorkflowTemplate | undefined,
): ThunkAction<Promise<ISlideshowItem[]>> => dispatch => {
  const aspectRatioDimensions = getAspectRatio(aspectRatioName);

  const { slideshowInfo = [] } = template?.previewConfiguration ?? {};

  return Promise.all(
    state.slideshow.map(async slide => {
      const originalSlide = slideshowInfo[slide.originalSlideshowIndex];

      const [croppedUrl, originalUrl] = await dispatch(
        getSlideSources(originalSlide, slide),
      );

      let imageEffect =
        slide.imageEffect || originalSlide.imageEffect || 'none';

      if (slide.imageType !== 'mainImage' && template && originalSlide) {
        imageEffect = originalSlide.imageEffect || slide.imageEffect || 'none';
      }

      return formatSlideForConfig(
        fromJS(
          createSlide(
            croppedUrl,
            originalSlide?.entryTransition || 'cut',
            0,
            0,
            imageEffect,
            originalSlide?.imageMetadata,
            fromJS(aspectRatioDimensions),
            originalUrl,
            slide.metadata,
            slide.placement,
            slide.sourceImageOrigin,
            slide.blurRadius,
          ),
        ),
        slide.layerId,
      );
    }),
  ) as any;
};

const createTemplateAdjustment = (
  state: TemplateEditorStateExport,
  aspectRatioName: AspectRatioName,
): ThunkAction<Promise<TemplateAdjustment>> => async (dispatch, getState) => {
  const templates = podcastWorkflowTemplatesSelector(getState());

  const template: PodcastWorkflowTemplate = templates
    .get(state.templateId)
    ?.toJS();

  const soundwave: ISoundwave = withValue(state.soundwave, s =>
    formatSoundwaveForConfig(
      fromJS(omit(s, 'waveGeneration', 'layerId')),
      s.layerId,
    ),
  );

  // If no 'textOverlayInfo' is available, it defaults to an empty array, to explicitly
  // indicate to backend that no text should be included in the final configuration.
  const textOverlayInfo =
    ((state.textOverlays?.map(overlay =>
      formatOverlayForConfig(fromJS(overlay), overlay.layerId),
    ) as unknown) as ITextOverlayItem[]) || [];

  const watermarkUrl = await dispatch(uploadImageSource(state?.watermark?.url));

  const watermarks = state.watermark && [
    formatWatermarkForConfig(
      {
        ...state.watermark,
        src: watermarkUrl,
      },
      0,
    ),
  ];

  return {
    soundwave,
    textOverlayInfo,
    layerOrder: addDefaultAudioLayers(state.layers),
    mainMediaContainer: formatMediaContainerForConfig(state.backgroundColor),
    progress: formatProgressForConfig(state.progress),
    slideshowInfo: await dispatch(
      createSlideshow(state, aspectRatioName, template),
    ),
    ...(state.captions && {
      captions: formatCaptionsForConfig(
        applyWizardCaptionsOverrideToStyle(
          createWizardCaptions(
            aspectRatioName,
            undefined,
            undefined,
            undefined,
            undefined,
            true,
            undefined,
          ),
          state.captions,
        ),
      ),
    }),
    timer: formatTimerForConfig(state.timer),
    // edgeVideos formatting is covered by UCS template "exportState" util, no further transformation
    // is required for sending information to the subscription create service.
    edgeVideos: state.edgeVideos,
    // videoclips formatting is covered by UCS template "exportState" util, no further transformation
    // is required for sending information to the subscription create service.
    videoClips: state.videoClips,
    watermark: watermarks,
  };
};

export const createWorkflow = (
  aspectRatioName: AspectRatioName,
  podcastId: string,
  podcastLanguage: string,
  templateId: string,
  subscriptionOptions: AutogramSubscriptionOptions,
  state: TemplateEditorStateExport,
): ThunkAction<Promise<IApiResponse<void>>> => async (dispatch, getState) => {
  const templateAdjustment = await dispatch(
    createTemplateAdjustment(state, aspectRatioName),
  );
  const templates = podcastWorkflowTemplatesSelector(getState());
  const templateType =
    templates.getIn([templateId, 'templateType']) ||
    TemplateType.HEADLINER_DEFAULT;

  return dispatch(
    podcastService.createPodcastSubscription(
      podcastId,
      podcastLanguage,
      templateId,
      createSubscriptionTypes(subscriptionOptions),
      {
        ...templateAdjustment,
        captions: {
          ...templateAdjustment.captions,
          enabled: !!templateAdjustment.captions,
        },
      },
      templateType,
    ),
  );
};

export const previewWorkflow = (
  aspectRatioName: AspectRatioName,
  podcastId: string,
  templateId: string,
  state: TemplateEditorStateExport,
): ThunkAction<Promise<
  IApiResponse<CreateSubscriptionPreviewResult>['response']
>> => async (dispatch, getState) => {
  const templateAdjustment = await dispatch(
    createTemplateAdjustment(state, aspectRatioName),
  );
  const templates = podcastWorkflowTemplatesSelector(getState());
  const templateType =
    templates.getIn([templateId, 'templateType']) ||
    TemplateType.HEADLINER_DEFAULT;

  const { response } = await dispatch(
    podcastService.createPodcastSubscriptionPreview(
      podcastId,
      {
        ...templateAdjustment,
        captions: {
          ...templateAdjustment.captions,
          enabled: !!templateAdjustment.captions,
        },
      },
      templateId,
      templateType,
    ),
  );

  return response;
};

export const pollForWorkflowPreview = (
  subscriptionPreviewJobId: number,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  try {
    dispatch({
      type: Type.AUTOMATION_CMS_WORKFLOW_PREVIEW_REQUEST,
      payload: { subscriptionPreviewJobId },
    });

    const subscriptionPreviewData = await reduxPoll(
      dispatch,
      async () => {
        const { response } = await dispatch(
          podcastService.getPoscastSubscriptionPreview(
            subscriptionPreviewJobId,
          ),
        );

        return response;
      },
      {
        id: SUBSCRIPTION_PREVIEW_POLL_ID,
        shouldContinue: (err, value) => {
          const inPollingSubscriptionPreviewId = subscriptionPreviewPollingIdSelector(
            getState(),
          );
          return (
            !err &&
            inPollingSubscriptionPreviewId === subscriptionPreviewJobId &&
            PREVIEW_PROCESSING_STATES.includes(value.status)
          );
        },
        intervalMillis: 2500,
        maxAttempts: Infinity,
      },
    );

    if (subscriptionPreviewData.status === 'error') {
      throw new Error('Preview job generation failed');
    }

    dispatch({
      type: Type.AUTOMATION_CMS_WORKFLOW_PREVIEW_SUCCESS,
      payload: { subscriptionPreviewData },
    });
  } catch (err) {
    dispatch({ type: Type.AUTOMATION_CMS_WORKFLOW_PREVIEW_FAILURE });
    throw err;
  }
};

export const deleteWorkflow = (
  workflowId: number,
): ThunkAction<Promise<IApiResponse<DeleteSubscriptionResult>>> => dispatch => {
  dispatch({
    payload: {
      id: workflowId,
    },
    type: Type.AUTOMATION_CMS_WORKFLOW_DELETE,
  });
  return dispatch(podcastService.deletePodcastSubscription(workflowId));
};

export const updateWorkflow = (
  aspectRatioName: AspectRatioName,
  workflowId: number,
  podcastLanguage: string,
  templateId: string,
  subscriptionOptions: AutogramSubscriptionOptions,
  state: TemplateEditorStateExport,
): ThunkAction<Promise<IApiResponse<void>>> => async (dispatch, getState) => {
  const templateAdjustment = await dispatch(
    createTemplateAdjustment(state, aspectRatioName),
  );
  const templates = podcastWorkflowTemplatesSelector(getState());
  const templateType =
    templates.getIn([templateId, 'templateType']) ||
    TemplateType.HEADLINER_DEFAULT;

  return dispatch(
    podcastService.updatePodcastSubscription({
      subscriptionId: workflowId,
      podcastLanguage,
      templateId,
      templateAdjustment,
      templateType,
      subscriptionTypes: createSubscriptionTypes(subscriptionOptions),
    }),
  );
};

export const unpauseRevokedPermissionsWorkflow = (
  workflowId: number,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const ytAccessData = youtubeUserDataSelector(getState());
  const subscriptions = podcastSubscriptionsSelector(getState());
  const targetSubscription = subscriptions?.get(String(workflowId))?.toJS();
  const loggedGoogleId = ytAccessData?.providerUserId;
  const subscriptionItems = targetSubscription?.subscriptionItems;
  const subscriptionGoogleId =
    subscriptionItems?.[0]?.autoPostVideoPreference?.options?.googleId;

  // clears the just logged in YT user
  dispatch(clearYoutubeUser());

  try {
    dispatch({ type: Type.AUTOMATION_CMS_RESTORE_REVOKED_PERMISSION_REQUEST });

    // Validates that the obtained google id is the same used for the current subscription
    // autoposting preferences Google id
    if (
      !subscriptionGoogleId ||
      !loggedGoogleId ||
      loggedGoogleId !== subscriptionGoogleId
    ) {
      throw new GoogleAccountMismatchError('YouTube account mismatch');
    }

    // maps the ID and subscription types as the logic for a mass unpausing requires the
    // subscription types to be sent too.
    const subscriptionTypes = mapSubscriptionItemToSubscriptionTypes(
      subscriptionItems,
    );

    // The bulk update is not triggered if only the isEnabled prop is updated, subscription item
    // must be also sent.
    await dispatch(
      podcastService.updatePodcastSubscription({
        isEnabled: true,
        subscriptionId: workflowId,
        subscriptionTypes,
      }),
    );

    // reloads all subscriptions for updating all subs states
    await dispatch(loadMyWorkflows());
    dispatch({ type: Type.AUTOMATION_CMS_RESTORE_REVOKED_PERMISSION_SUCCESS });
  } catch (err) {
    dispatch({ type: Type.AUTOMATION_CMS_RESTORE_REVOKED_PERMISSION_FAILURE });
    throw err;
  }
};

export const toggleWorkflow = (
  workflowId: number,
  enabled: boolean,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const subscriptions = podcastSubscriptionsSelector(getState());
  const subscription = subscriptions.get(String(workflowId));

  dispatch({
    type: Type.AUTOMATION_CMS_WORKFLOW_TOGGLE_REQUEST,
    response: {
      entities: {
        podcastSubscriptions: {
          [workflowId]: subscription.set('isEnabled', enabled),
        },
      },
    },
  });

  try {
    await dispatch(
      podcastService.togglePodcastSubscription(workflowId, enabled),
    );
    dispatch(onUpdateAutomationSubscription(enabled));
    dispatch({
      type: Type.AUTOMATION_CMS_WORKFLOW_TOGGLE_SUCCESS,
    });
    dispatch(
      showSuccess(
        enabled
          ? 'We’ll start sending you new videos soon'
          : 'We’ll stop sending you videos for now',
        10,
        'automation-cms-workflow-toggle-success',
        enabled
          ? 'Your automation is back on'
          : 'Your automation is turned off',
      ),
    );
  } catch (error) {
    dispatch({
      type: Type.AUTOMATION_CMS_WORKFLOW_TOGGLE_FAILURE,
      response: {
        entities: {
          podcastSubscriptions: {
            [workflowId]: subscription.set('isEnabled', !enabled),
          },
        },
      },
    });
    dispatch(showError('There was an error updating your automation', 10));
  }
};

export const loadWorkflowTemplates = (
  aspectRatio: AspectRatioName,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.AUTOMATION_CMS_TEMPLATES_LOAD_REQUEST });

  try {
    const {
      response: { result },
    } = await dispatch(podcastService.getPodcastWorkflowTemplates(aspectRatio));

    dispatch({
      payload: {
        aspectRatio,
        templates: result,
      },
      type: Type.AUTOMATION_CMS_TEMPLATES_LOAD_SUCCESS,
    });
  } catch (err) {
    dispatch({ type: Type.AUTOMATION_CMS_TEMPLATES_LOAD_FAILURE });

    throw err;
  }
};

export const loadSeasons = (
  podcastId: string,
): ThunkAction<Promise<Season[]>> => dispatch => {
  dispatch({ type: Type.AUTOMATION_CMS_SEASONS_LOAD_REQUEST });
  return dispatch(podcastService.getSeasons(podcastId))
    .then(res => {
      const payload = res.response;

      dispatch({
        payload,
        type: Type.AUTOMATION_CMS_SEASONS_LOAD_SUCCESS,
      });

      return res.response.seasons;
    })
    .catch(err => {
      dispatch({ type: Type.AUTOMATION_CMS_SEASONS_LOAD_FAILURE });
      throw err;
    });
};

export const clearAutomationTemplates = () => ({
  type: Type.AUTOMATION_CMS_TEMPLATES_CLEAR,
});

export const clearSeasons = () => ({
  type: Type.AUTOMATION_CMS_SEASONS_CLEAR,
});

export const getMyPodcastFeeds = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  dispatch({ type: Type.AUTOMATION_CMS_PODCAST_FEEDS_GET_REQUEST });
  try {
    const { response } = await dispatch(podcastService.getMyPodcastFeeds());
    dispatch({
      payload: {
        podcastFeedIds: response.result,
      },
      type: Type.AUTOMATION_CMS_PODCAST_FEEDS_GET_SUCCESS,
    });
  } catch (err) {
    dispatch({ type: Type.AUTOMATION_CMS_PODCAST_FEEDS_GET_FAILURE });
    throw err;
  }
};

export const getMyPodcastFeed = (
  podcastFeedId: string,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const podcastFeedData = podcastFeedDataSelector(getState()).get(
    podcastFeedId,
  );

  // api pagination starts at 1, not 0
  const currentPage = podcastFeedData?.number ?? 0;
  const nextPage = currentPage + 1;

  // currentPage is forced to a number above.  totalPages might be undefined
  if (currentPage === podcastFeedData?.totalPages) {
    return;
  }

  dispatch({
    payload: { podcastFeedId },
    type: Type.AUTOMATION_CMS_PODCAST_FEED_GET_REQUEST,
  });
  try {
    const {
      response: { result: episodes, page, canRefreshFeed },
    } = await dispatch(
      podcastService.getMyPodcastFeedDetails(
        podcastFeedId,
        nextPage,
        podcastFeedData?.size ?? PODCAST_EPISODE_PAGE_SIZE,
      ),
    );

    const workflowResponse =
      nextPage === 1
        ? await dispatch(
            podcastService.getMyPodcastSubscriptions(podcastFeedId),
          )
        : undefined;

    const workflows = workflowResponse?.response?.result;

    dispatch({
      payload: {
        page,
        podcastFeedId,
        episodes,
        workflows,
        canRefreshFeed,
      },
      type: Type.AUTOMATION_CMS_PODCAST_FEED_GET_SUCCESS,
    });
  } catch (err) {
    dispatch(
      showNotification({
        message: 'Please try again or {{link}} so we can help',
        code: 'ER013',
        level: 'error',
        dismissAfterSec: 5,
        type: 'help',
        title: "Sorry, we couldn't load your automations",
        actionLabel: 'contact us',
      }),
    );
    dispatch({
      payload: { podcastFeedId },
      type: Type.AUTOMATION_CMS_PODCAST_FEED_GET_FAILURE,
    });
  }
};

export const getMyEpisodeProjects = (
  episodeId: string,
  projectsApiUrl: string,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    payload: { episodeId },
    type: Type.AUTOMATION_CMS_EPISODE_PROJECTS_GET_REQUEST,
  });

  try {
    const {
      response: { result },
    } = await dispatch(projectService.fetchProjectsByUrl(projectsApiUrl));
    dispatch({
      payload: {
        episodeId,
        projects: result,
      },
      type: Type.AUTOMATION_CMS_EPISODE_PROJECTS_GET_SUCCESS,
    });
  } catch (err) {
    dispatch({
      payload: { episodeId },
      type: Type.AUTOMATION_CMS_EPISODE_PROJECTS_GET_FAILURE,
    });
  }
};

export const createEpisodeVideo = (
  episodeId: string,
  workflows: number[],
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const payload = { episodeId };
  const episode = podcastFeedEpisodesSelector(getState())?.get(episodeId);
  const { projects } =
    podcastEpisodeDataSelector(getState())?.get(episodeId) ?? {};
  const nextStatus =
    getVideoCount(projects?.toJS(), episode.toJS()) > 0
      ? 'partialProcessing'
      : 'processing';

  dispatch({
    payload,
    type: Type.AUTOMATION_CMS_EPISODE_VIDEO_CREATE_REQUEST,
  });

  try {
    await dispatch(podcastService.createEpisodeVideo(episodeId, workflows));
    dispatch({
      payload,
      type: Type.AUTOMATION_CMS_EPISODE_VIDEO_CREATE_SUCCESS,
      response: {
        entities: {
          podcastFeedEpisodes: {
            [episodeId]: episode.setIn(
              ['subscriptionInfo', 'status'],
              nextStatus,
            ),
          },
        },
      },
    });
  } catch (err) {
    dispatch({
      payload,
      type: Type.AUTOMATION_CMS_EPISODE_VIDEO_CREATE_FAILURE,
      response: {
        entities: {
          podcastFeedEpisodes: {
            [episodeId]: episode,
          },
        },
      },
    });
    throw err;
  }
};

const pollForEpisodeTranscriptions = (): ThunkAction<void> => async (
  dispatch,
  getState,
) => {
  if (isPollingEpisodeTranscriptionsSelector(getState())) {
    return;
  }

  dispatch({
    type: Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_POLLING_BEGIN,
  });

  try {
    await reduxPoll(
      dispatch,
      async () => {
        const episodeIds = pollTranscriptEpisodeIdsSelector(
          getState(),
        )?.toArray();

        const {
          response: { result },
        } = await dispatch(
          podcastService.getEntireEpisodeTranscriptInfos(episodeIds),
        );

        const episodes = pollTranscriptEpisodesSelector(getState());

        episodes.forEach(episode => {
          const episodeId = String(episode.get('id'));

          const isResolved = episode.getIn(['transcriptInfo', 'isResolved']);

          // if the episode id (from list in redux) is not listed in the response
          // from the server, something might have gone wrong - remove it from the
          // array to prevent unnecessary infinite polling for that id
          if (isResolved || !result.includes(episodeId)) {
            dispatch({
              type: Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_SUCCESS,
              payload: { episodeId },
            });
          }
        });
      },
      {
        id: ENTIRE_EPISODE_TRANSCRIPTION_POLL_ID,
        shouldContinue: () => {
          const ids = pollTranscriptEpisodeIdsSelector(getState());
          return !ids.isEmpty();
        },
        intervalMillis: 5000,
        maxAttempts: Infinity,
      },
    );
  } finally {
    dispatch({
      type: Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_POLLING_END,
    });
  }
};

export const awaitEpisodeTranscription = (
  episodeId: string,
): ThunkAction<void> => dispatch => {
  dispatch({
    type: Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_REQUEST,
    payload: { episodeId },
  });
  dispatch(pollForEpisodeTranscriptions());
};

export const abortAwaitEpisodeTranscription = (
  episodeId: string,
): ThunkAction<void> => dispatch => {
  dispatch({
    type: Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_ABORT,
    payload: { episodeId },
  });
};

export const transcribeEpisode = (
  episodeId: string,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    type: Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_CREATE_REQUEST,
    payload: { episodeId },
  });

  try {
    const {
      response: { status, language },
    } = await dispatch(podcastService.createEntireEpisodeTranscript(episodeId));

    if (
      status === 'concurrentLimitReached' ||
      status === 'transcribeTimeLimitReached' ||
      status === 'languageNotSupported'
    ) {
      throw new EpisodeTranscriptError(status, language);
    }

    // refresh this episode's status so that the UI sees it as in progress
    await dispatch(podcastService.getEntireEpisodeTranscriptInfo(episodeId));
    dispatch(
      showSuccess({
        dismissAfterSec: 5,
        message: "We'll notify you here when it's ready",
        title: "We're generating your transcript",
      }),
    );
    dispatch({
      type: Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_CREATE_SUCCESS,
    });
    dispatch(pollForEpisodeTranscriptions());
  } catch (err) {
    dispatch({
      type: Type.AUTOMATION_CMS_EPISODE_TRANSCRIPTION_CREATE_FAILURE,
    });

    if (err.status === 409) {
      await dispatch(podcastService.getEntireEpisodeTranscriptInfo(episodeId));
      throw new EpisodeTranscriptError('conflict');
    }

    throw err;
  }
};

export const selectPodcast = (podcastId: string) => ({
  type: Type.PODCAST_SELECT,
  payload: { podcastId },
});

export const clearPodcastFeed = () => ({
  type: Type.CLEAR_PODCAST_FEED,
});
