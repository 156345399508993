import React from 'react';

import {
  ActionStepCard,
  ActionStepCardCompositeIcon,
  ActionStepCardEditButton,
} from 'components/ActionStepCard';
import { WaveIcon } from 'components/icons';

import { SubscriptionItem } from 'redux/middleware/api/podcast-service';

import { VideoTypes } from 'types';
import { getAudioSelectionText } from './utils';

interface EditAutomationAudioSelectionCardProps {
  onEdit: () => void;
  subscriptionItem?: SubscriptionItem;
}

const EditAutomationAudioSelectionCard: React.FunctionComponent<EditAutomationAudioSelectionCardProps> = props => {
  const { onEdit, subscriptionItem } = props;

  const selectionText = getAudioSelectionText(subscriptionItem);

  const renderAction = React.useCallback(
    () => <ActionStepCardEditButton onClickEdit={onEdit} />,
    [onEdit],
  );

  return (
    <ActionStepCard
      description={selectionText}
      image={
        <ActionStepCardCompositeIcon>
          <WaveIcon />
        </ActionStepCardCompositeIcon>
      }
      renderAction={
        subscriptionItem.videoType !== VideoTypes.FULL_EPISODE
          ? renderAction
          : null
      }
      title="Audio selection"
    />
  );
};

export default EditAutomationAudioSelectionCard;
