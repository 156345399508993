import * as React from 'react';

import { BottomAlign, MiddleAlign, TopAlign } from 'components/icons';
import { PopperRadio } from 'components/Popper';

export type PopperVerticalAlignRadioValue = 'top' | 'middle' | 'bottom';

export interface PopperVerticalAlignRadioProps {
  onChange?: (value: PopperVerticalAlignRadioValue) => void;
  value: PopperVerticalAlignRadioValue;
}

const PopperVerticalAlignRadio: React.FC<PopperVerticalAlignRadioProps> = ({
  onChange,
  value,
}) => (
  <PopperRadio
    options={[
      {
        icon: <TopAlign />,
        value: 'top',
      },
      {
        icon: <MiddleAlign />,
        value: 'middle',
      },
      {
        icon: <BottomAlign />,
        value: 'bottom',
      },
    ]}
    {...{ value, onChange }}
  />
);

export default PopperVerticalAlignRadio;
