import * as Sentry from '@sentry/browser';

import { getValue } from 'utils/collections';
import fullstory from '../fullstory';
import { ERROR_MESSAGES_TO_BE_FILTERED } from './constants';
import { isErrorReported, isReportableError } from './utils';

function configure() {
  Sentry.init({
    blacklistUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    dsn: process.env.NODE_ENV === 'local' ? null : spareminConfig.sentry.dsn,
    environment: process.env.NODE_ENV,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',

      ...ERROR_MESSAGES_TO_BE_FILTERED,
    ],
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
      new Sentry.Integrations.GlobalHandlers({
        // unhandled promise rejections is configured in the install() function
        onerror: undefined,
        onunhandledrejection: false,
      }),
    ],
    release: spareminConfig.version,
    sampleRate: spareminConfig.sentry.sampleRate,
  });
}

function install() {
  configure();

  /**
   * most browsers don't support the global unhandled promise rejection event.  we polyfill promises
   * with bluebird in order to get support for this event.  the check below determines whether or
   * not bluebird is installed.
   */
  if (
    // @ts-ignore
    !Promise.onPossiblyUnhandledRejection &&
    process.env.NODE_ENV !== 'production'
  ) {
    /* eslint-disable no-console */
    console.warn(
      'raven not listening for unhandled promise rejections.  make sure promise polyfill is ' +
        'installed before calling raven.install()',
    );
    /* eslint-enable no-console */

    return;
  }

  window.addEventListener('unhandledrejection', (e: any) => {
    /*
     * the "reason" (actual error with stacktrace) can be found in one of two places -
     * e.reason or e.detail.reason.  not sure why this is, but from testing it seems that whenever
     * e.reason exists, e.detail does not, and vice versa.
     */
    const errorObj = e.reason || e.detail.reason;

    /*
     * our sentry redux middleware will add metadata to errors once they are sent to sentry. even if
     * the error is "unhandled", it might have already been sent to sentry, which means we don't
     * need to report it here
     */
    if (
      isErrorReported(e) ||
      isErrorReported(getValue(e, ['detail', 'reason'])) ||
      !isReportableError(errorObj) ||
      !(errorObj instanceof Error)
    ) {
      return;
    }

    // recommended per bluebird docs
    e.preventDefault();

    // eslint-disable-next-line no-console
    console.error('unhandled promise rejection', errorObj);

    Sentry.captureException(errorObj);
  });

  /*
   * link fullstory sessions to sentry exceptions.  note that the sentry middleware also calls
   * `Raven.setDataCallback`, so need to handle the case where this is called multiple times
   */
  Sentry.configureScope(scope => {
    scope.addEventProcessor(event => {
      return {
        ...event,
        extra: {
          ...event.extra,
          fullstory: (fullstory as any).getCurrentSessionURL(true),
        },
      };
    });
  });
}

export default { install };
