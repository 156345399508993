import { Location } from 'history';
import * as queryString from 'query-string';

import { omit } from 'underscore';
import {
  getReferralKeyFromQuery,
  getReferralTypeFromQuery,
  getTierFromQuery,
} from 'blocks/ProjectsPage/utils';
import { getTraceIdsFromQuery } from 'containers/ProjectWizards/AudioWizard/utils';
import bem from 'utils/bem';
import {
  REFERRAL_KEY_QUERY_PARAM,
  REFERRAL_TYPE_QUERY_PARAM,
} from 'utils/constants';
import { removeQueryParamFromURL } from 'utils/routes';
import { QueryStringData } from './types';

const REFERRAL_QUERY_PARAMS = [
  REFERRAL_KEY_QUERY_PARAM,
  REFERRAL_TYPE_QUERY_PARAM,
];

export const authCardBlock = bem('auth-card');

export function getTierFromLocation(location: Location) {
  const { redirect: projectsPageUrl } = queryString.parse(location.search);

  if (projectsPageUrl) {
    return getTierFromQuery(
      queryString.parse(queryString.extract(projectsPageUrl as string)),
    );
  }
  return undefined;
}

// based on the URL format being like: /sign-up?redirect=%2Fprojects%3Ftier%3Dpro
export function parseQueryParams(location: Location): QueryStringData {
  if (!location) return {};

  const locationQuery = queryString.parse(location.search);
  const query = !locationQuery.redirect
    ? locationQuery
    : queryString.parse(queryString.extract(locationQuery.redirect as string));

  return {
    referralKey: getReferralKeyFromQuery(query),
    referralType: getReferralTypeFromQuery(query),
    tier: getTierFromQuery(query),
    traceId: getTraceIdsFromQuery(query)?.traceId,
  };
}

export function removeReferralQueryParams(location: Location) {
  if (!location) return {};

  const locationQuery = queryString.parse(location.search);
  const redirectUrl = locationQuery.redirect as string | undefined;

  const sanitizedRedirectUrl = !redirectUrl
    ? undefined
    : removeQueryParamFromURL(redirectUrl, ...REFERRAL_QUERY_PARAMS);

  const sanitizedQuery = {
    ...omit(locationQuery, ...REFERRAL_QUERY_PARAMS),
    ...(!sanitizedRedirectUrl ? undefined : { redirect: sanitizedRedirectUrl }),
  };

  return {
    ...omit(location, 'action'),
    search: queryString.stringify(sanitizedQuery),
  };
}
