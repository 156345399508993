import * as React from 'react';

import { IconProps } from './types';

const SocialSolidCircleX: React.FC<IconProps> = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <circle fill="#222831" cx="16" cy="16" r="16" />
      <path
        d="M19.45 11h1.84l-4.02 4.236L22 21h-3.703l-2.9-3.496L12.078 21h-1.841l4.3-4.53L10 11h3.797l2.622 3.195L19.45 11zm-.645 8.985h1.02l-6.582-8.023h-1.094l6.656 8.023z"
        fill="#BEC8D2"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SocialSolidCircleX;
