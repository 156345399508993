import * as React from 'react';

import BemCssTransition, {
  BemCssTransitionProps,
} from 'components/BemCssTransition';
import { episodeListBlock as block } from '../../utils';

type PickedTransitionProps = Pick<
  BemCssTransitionProps,
  'delay' | 'onEntered' | 'onExited' | 'timeout'
>;

export interface EpisodeRowProgressOverlayProps extends PickedTransitionProps {
  transitionIn: boolean;
}

const EpisodeRowProgressOverlay: React.FC<EpisodeRowProgressOverlayProps> = ({
  delay,
  onEntered,
  onExited,
  timeout,
  transitionIn,
}) => (
  <BemCssTransition
    className={block('progress-overlay')}
    {...{ delay, onEntered, onExited, timeout }}
    in={transitionIn}
    mountOnEnter
    unmountOnExit
  >
    <div />
  </BemCssTransition>
);

export default EpisodeRowProgressOverlay;
