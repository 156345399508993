import { List, RecordOf, Set } from 'immutable';

import {
  Coupon as ApiCoupon,
  PlanName,
  SubTier,
  Tier,
} from 'redux/middleware/api/plan-service';
import { Action } from 'redux/types';
import { PurchasePlatform, RequestStatus } from 'types';
import { Type } from './action-types';

export enum FreeTrialReminder {
  LAST_WEEK = 'LastWeek',
  OVER = 'TrialOver',
}

export interface LoadPricingDataOptions {
  force?: boolean;
}

export interface Subscription {
  tier: Tier;
  subtier: SubTier;
  plan: PlanName;
  pauseSchedule: {
    pauseEnd: number | null;
    pauseStart: number | null;
    // queued the plan is on it's way to be paused
    // active then plan is paused
    // it’ll be null if they don’t have any active pauses or anything queued
    // so if they cancelled for example, it’ll be null
    pauseStatus: 'queued' | 'active' | null;
  };
  organizationName: string | undefined;
  planStartSec: number | undefined;
  purchaseManagementPlatform: PurchasePlatform | null;
  planEndSec: number | undefined;
  purchaseDateSec: number | undefined;
  eddyDurationLimitHours: number;
  fullEpisodeDurationLimitHours: number;
  exportBalance: number;
  transcriptionBalanceMillis: number;
  eddyUploadMB: number;
  videoUploadLimitMb: number;
  autoRenewal?: boolean;
  invoicePastDue: boolean;
  forcedCancellationNotice: boolean;
  discoEnabled: boolean;
}

export type Coupon = Pick<ApiCoupon, 'id' | 'couponDisplayHint'>;

export interface CustomerCard {
  brand: string;
  last4Digits: string;
}

export interface State {
  isLoading: boolean | undefined;
  isSendingInvites: boolean;
  savePaymentMethodStatus: RequestStatus;
  updateSubscriptionStatus: RequestStatus;
  myCoupons: List<RecordOf<Coupon>>;
  mySubscription: RecordOf<Subscription>;
  myCard: RecordOf<CustomerCard>;
  myReferralUrl: string;
  seenFreeTrialReminders: Set<FreeTrialReminder>;
  externalPortalLink: string;
  customerId?: string;
}

export type PricingState = RecordOf<State>;

export interface PaymentMethod {
  stripeToken?: string;
  paymentMethodId?: string;
}

type LoadSubscriptionSuccessAction = Action<
  Type.PRICING_DATA_SUBSCRIPTION_LOAD_SUCCESS,
  {
    mySubscription: Subscription;
  }
>;

type LoadCouponsSuccessAction = Action<
  Type.PRICING_DATA_COUPONS_LOAD_SUCCESS,
  {
    myCoupons: Coupon[];
  }
>;

type LoadDataRequestAction = Action<Type.PRICING_DATA_LOAD_REQUEST>;
type LoadDataFailureAction = Action<Type.PRICING_DATA_LOAD_FAILURE>;
type LoadDataSuccessAction = Action<Type.PRICING_DATA_LOAD_SUCCESS>;

type CreatePaymentMethodRequestAction = Action<
  Type.PRICING_CREATE_PAYMENT_METHOD_REQUEST
>;
type CreatePaymentMethodSuccessAction = Action<
  Type.PRICING_CREATE_PAYMENT_METHOD_SUCCESS
>;
type CreatePaymentMethodFailureAction = Action<
  Type.PRICING_CREATE_PAYMENT_METHOD_FAILURE
>;

type UpdateSubscriptionRequestAction = Action<
  Type.PRICING_UPDATE_SUBSCRIPTION_REQUEST
>;
type UpdateSubscriptionSuccessAction = Action<
  Type.PRICING_UPDATE_SUBSCRIPTION_SUCCESS
>;
type UpdateSubscriptionFailureAction = Action<
  Type.PRICING_UPDATE_SUBSCRIPTION_FAILURE
>;
type GetCustomerCardRequestAction = Action<
  Type.PRICING_CUSTOMER_CARD_GET_REQUEST
>;
type GetCustomerCardSuccessAction = Action<
  Type.PRICING_CUSTOMER_CARD_GET_SUCCESS,
  {
    myCard: CustomerCard;
    customerId: string;
  }
>;
type GetCustomerCardFailureAction = Action<
  Type.PRICING_CUSTOMER_CARD_GET_FAILURE
>;
type LoadReferralSuccessAction = Action<
  Type.PRICING_REFERRAL_DATA_LOAD_SUCCESS,
  {
    myReferralUrl: string;
  }
>;

type SendReferralInvitesRequestAction = Action<
  Type.PRICING_REFERRAL_INVITATIONS_SEND_REQUEST
>;
type SendReferralInvitesSuccessAction = Action<
  Type.PRICING_REFERRAL_INVITATIONS_SEND_SUCCESS
>;
type SendReferralInvitesFailureAction = Action<
  Type.PRICING_REFERRAL_INVITATIONS_SEND_FAILURE
>;

type MarkFreeTrialReminderSeenAction = Action<
  Type.MARK_FREE_TRIAL_REMINDER_SEEN,
  FreeTrialReminder
>;

type LoadExternalPortalLinkRequestAction = Action<
  Type.LOAD_EXTERNAL_PORTAL_LINK_REQUEST,
  string
>;
type LoadExternalPortalLinkSuccessAction = Action<
  Type.LOAD_EXTERNAL_PORTAL_LINK_SUCCESS,
  string | undefined
>;
type LoadExternalPortalLinkFailureAction = Action<
  Type.LOAD_EXTERNAL_PORTAL_LINK_FAILURE
>;

export type PricingAction =
  | LoadSubscriptionSuccessAction
  | LoadCouponsSuccessAction
  | LoadDataRequestAction
  | LoadDataSuccessAction
  | LoadDataFailureAction
  | LoadExternalPortalLinkRequestAction
  | LoadExternalPortalLinkSuccessAction
  | LoadExternalPortalLinkFailureAction
  | CreatePaymentMethodRequestAction
  | CreatePaymentMethodSuccessAction
  | CreatePaymentMethodFailureAction
  | UpdateSubscriptionRequestAction
  | UpdateSubscriptionSuccessAction
  | UpdateSubscriptionFailureAction
  | GetCustomerCardRequestAction
  | GetCustomerCardSuccessAction
  | GetCustomerCardFailureAction
  | LoadReferralSuccessAction
  | SendReferralInvitesRequestAction
  | SendReferralInvitesSuccessAction
  | SendReferralInvitesFailureAction
  | MarkFreeTrialReminderSeenAction;
