import * as React from 'react';
import { connect } from 'react-redux';
import { isString } from 'underscore';

import { Navigator } from 'components/Wizard';
import {
  clearAutomatedWizard,
  clearEntireAudio,
  createAutomatedProject,
} from 'redux/modules/automated-wizard/actions';
import {
  entireAudioInstanceIdSelector,
  projectIdSelector,
} from 'redux/modules/automated-wizard/selectors';
import {
  clearMixpanel,
  onCancelWizard,
  onCompleteWizard,
  onFileUpload,
  onWizardNext,
} from 'redux/modules/mixpanel';
import { clearNotification, showError } from 'redux/modules/notification';
import { clearSearchResults } from 'redux/modules/podcast-search';
import { goToCreate, goToProject } from 'redux/modules/router';
import { Dispatch, State, ThunkAction } from 'redux/types';
import { isApplicationError } from 'utils/ApplicationError';
import { createClippingOption } from 'utils/audio';
import { DropZoneType } from 'utils/constants';
import AutomatedWizard from './AutomatedWizard';
import { MIXPANEL_WIZARD_STEP_MAP } from './constants';
import { AutomatedWizardProps as Props } from './types';

type StateProps = Pick<Props, 'entireAudioInstanceId'>;
type DispatchProps = Pick<
  Props,
  | 'onCancel'
  | 'onError'
  | 'onMount'
  | 'onSubmit'
  | 'onStepChange'
  | 'onUnmount'
  | 'onComplete'
>;

const ERROR_ID = 'automated-wizard-error';

const onComplete = (): ThunkAction<void> => (dispatch, getState) => {
  const projectId = projectIdSelector(getState());
  dispatch(goToProject({ projectId, history: false, fromWizard: true }));
};

const mapStateToProps = (state: State): StateProps => ({
  entireAudioInstanceId: entireAudioInstanceIdSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onCancel: (nav: Navigator) => {
    dispatch(onCancelWizard('automated', MIXPANEL_WIZARD_STEP_MAP[nav.stepId]));
    dispatch(goToCreate());
  },
  onError: (err, file = undefined, shouldGoToProjects = false) => {
    const message = isString(err) ? err : err.message;
    const code = !isString(err) && isApplicationError(err) ? err.code : null;

    if (file) {
      dispatch(onFileUpload(DropZoneType.AUTOMATED, file, message));
    }

    dispatch(showError({ message, code, id: ERROR_ID }));

    if (shouldGoToProjects) dispatch(goToCreate());
  },
  onMount: () => {
    dispatch(clearAutomatedWizard());
    dispatch(clearNotification(ERROR_ID));
    dispatch(clearMixpanel());
  },
  onStepChange: (to, from) => {
    if (from && to.stepId !== 'create') {
      dispatch(
        onWizardNext({
          step: MIXPANEL_WIZARD_STEP_MAP[from.stepId],
          type: 'automated',
        }),
      );
    }

    if (to.stepId === 'upload') {
      dispatch(clearEntireAudio());
    }
  },
  onSubmit: config => {
    const { aspectRatio, audioClip, transcription } = config;
    const clippingOption = createClippingOption(audioClip);

    dispatch(
      onCompleteWizard({
        audioSource: 'upload',
        originalAudioDuration: audioClip.originalDurationMillis,
        transcription: true,
        type: 'automated',
        ...clippingOption,
      }),
    );
    dispatch(
      createAutomatedProject(
        audioClip,
        aspectRatio.dimensions,
        transcription.language,
      ),
    ).catch(() => {
      dispatch(
        showError({
          message: 'There was an error creating your project.',
          code: 'ER009',
          id: ERROR_ID,
        }),
      );
      dispatch(goToCreate());
    });
  },
  onUnmount: () => {
    dispatch(clearSearchResults());
  },
  onComplete: () => {
    dispatch(onComplete());
  },
});

const component = connect(mapStateToProps, mapDispatchToProps)(AutomatedWizard);
export type AutomatedWizardProps = React.ComponentPropsWithoutRef<
  typeof component
>;
export default component;
