import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import { InputAdornment, TextField, TextFieldProps } from 'components/Form';
import IconButton from 'components/IconButton/IconButton';
import { Omit } from 'types';
import EmailAddressRemoveButton from './EmailAddressRemoveButton';
import { block } from './utils';
import VideoExportFormField from './VideoExportFormField';

interface Props
  extends Omit<TextFieldProps, 'label' | 'placeholder' | 'onChange' | 'value'> {
  onChange: (value: string[]) => void;
  value: string[];
  errors?: string[];
}

const EmailAddressField: React.SFC<Props> = props => {
  const { value, onChange, errors, ...rest } = props;
  return (
    <VideoExportFormField xs={12} className={block('email-addresses')}>
      {value.map((v, index) => (
        <TextField
          {...rest}
          key={index} // eslint-disable-line
          light
          density="comfortable"
          error={!!errors?.[index]}
          helperText={errors?.[index]}
          value={v}
          onChange={newValue => {
            const copy = [...value];
            copy[index] = newValue;
            onChange(copy);
          }}
          label={index === 0 ? 'Email When Video Is Ready (optional)' : null}
          placeholder="Email"
          autoComplete="off"
          name={`notify-email-${index}`}
          startAdornment={
            <InputAdornment>
              <FontAwesome icon="envelope" />
            </InputAdornment>
          }
          endAdornment={
            index !== 0 ? (
              <EmailAddressRemoveButton
                onClick={() => {
                  const copy = [...value];
                  copy.splice(index, 1);
                  onChange(copy);
                }}
              />
            ) : null
          }
        />
      ))}
      <IconButton
        theme="primary"
        onButtonClick={() => {
          onChange([...value, '']);
        }}
      >
        <FontAwesome icon="plus" size="sm" />
        <span>Add email address</span>
      </IconButton>
    </VideoExportFormField>
  );
};

export default EmailAddressField;
export { Props as EmailAddressFieldProps };
