import * as React from 'react';

import LoadingOverlay from 'components/LoadingOverlay';
import Modal from 'components/Modal';
import { ApplicationError } from 'utils/ApplicationError';
import CaptionUploader from './CaptionUploader';
import { block } from './utils';

interface IProps {
  onCancel: () => void;
  onCaptionAccepted: (file: File) => any | Promise<any>;
  onError: (error: ApplicationError, file: File) => void;
  uploadInProgress: boolean;
  show: boolean;
}

export default class UploadCaptionsModal extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    show: false,
  };

  private handleOnFileAccepted = (file: File) => {
    const { onCancel, onCaptionAccepted } = this.props;

    onCaptionAccepted(file).then(() => onCancel());
  };

  public render() {
    const { onCancel, onError, show, uploadInProgress } = this.props;

    return (
      <Modal
        className={block()}
        show={show}
        onHide={onCancel}
        backdrop="static"
        title="Upload captions"
      >
        <Modal.Body>
          {uploadInProgress && (
            <LoadingOverlay
              title="Uploading captions"
              description="This may take a while, so please keep your browser open and check back later"
            />
          )}

          <CaptionUploader
            onFileAccepted={this.handleOnFileAccepted}
            onFileRejected={onError}
          />

          <div className={block('uploader-warning')}>
            The uploaded file will replace exisiting captions
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.FooterButtons>
            <Modal.FooterButton onClick={onCancel}>Cancel</Modal.FooterButton>
          </Modal.FooterButtons>
        </Modal.Footer>
      </Modal>
    );
  }
}

export { IProps as UploadCaptionsModalProps };
