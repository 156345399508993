import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalFooterButton } from 'components/Modal';
import { Step } from 'components/SteppedModal';
import { showError } from 'redux/modules/notification';
import { makePodcastImageUrlSelector } from 'redux/modules/podcast-search';
import {
  createPlaylistStatusSelector,
  createYoutubePlaylist,
} from 'redux/modules/social';
import { Dispatch } from 'redux/types';
import { RequestStatus, YoutubeVideoVisibility } from 'types';
import { createChainedFunction } from 'utils/functions';
import CreatePlaylistModalBody from './CreatePlaylistModalBody';

export interface UseCreatePlaylistStepOptions {
  defaultPlaylistName?: string;
  defaultVisibility?: YoutubeVideoVisibility;
  podcastId?: string;
  onSuccess?: () => void;
}

export default function useCreatePlaylistStep(
  opts?: UseCreatePlaylistStepOptions,
): Step {
  const {
    defaultPlaylistName,
    defaultVisibility = 'public',
    podcastId,
    onSuccess,
  } = opts ?? {};

  const dispatch = useDispatch<Dispatch>();
  const createPlaylistStatus = useSelector(createPlaylistStatusSelector);
  const podcastImageUrl = useSelector(
    useMemo(() => makePodcastImageUrlSelector(podcastId), [podcastId]),
  );

  const [visibility, setVisibility] = useState(defaultVisibility);
  const [playlistName, setPlaylistName] = useState(defaultPlaylistName);

  const isSubmitting = createPlaylistStatus === RequestStatus.REQUEST;

  const handleSubmit = async () => {
    try {
      await dispatch(
        createYoutubePlaylist(playlistName, visibility, podcastImageUrl),
      );
      onSuccess?.();
    } catch {
      dispatch(
        showError({
          message: 'Please try again or {{link}} so we can help',
          dismissAfterSec: 10,
          type: 'help',
          title: 'Error creating YouTube playlist',
          actionLabel: 'contact us',
        }),
      );
    }
  };

  const resetState = () => {
    setVisibility(defaultVisibility);
    setPlaylistName(defaultPlaylistName);
  };

  return {
    title: 'create playlist',
    component: (
      <CreatePlaylistModalBody
        onMount={resetState}
        onPlaylistNameChange={setPlaylistName}
        onVisibilityChange={setVisibility}
        {...{ isSubmitting, playlistName, visibility }}
      />
    ),
    fluidFooterButtons: true,
    id: 'playlist',
    renderFooterButtons: ({ back, submit }) => [
      <ModalFooterButton
        {...back}
        disabled={back.disabled || isSubmitting}
        key="back"
        theme="submit-alt"
      >
        cancel
      </ModalFooterButton>,
      <ModalFooterButton
        {...submit}
        disabled={submit.disabled || !playlistName || isSubmitting}
        key="submit"
        onClick={createChainedFunction(submit.onClick, handleSubmit)}
        theme="submit"
      >
        create playlist
      </ModalFooterButton>,
    ],
    replaceBody: true,
  };
}
