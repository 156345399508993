import { RecordOf } from 'immutable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  DraggableCore,
  DraggableCoreProps,
  DraggableData,
} from 'react-draggable';
import _ from 'underscore';

import { clamp } from 'utils/numbers';
import { cloneOnlyChild } from 'utils/react';
import { Omit } from '../../types';
import * as types from './types';

const DEFAULT_BOUNDS = {
  bottom: Infinity,
  left: -Infinity,
  right: Infinity,
  top: -Infinity,
};

type CoreProps = Partial<
  Omit<DraggableCoreProps, 'onStart' | 'onStop' | 'onDrag' | 'onMouseDown'>
>;
export interface IProps extends CoreProps {
  /**
   * drag boundaries.  item cannot be dragged outside of this alrea
   */
  bounds?: types.IBounds;
  children?: React.ReactElement;
  /**
   * query selector for the draggable element
   */
  handle?: string;
  /**
   * called each time the mouse moves while an item is being dragged
   * arguments:
   *    - event: the mousemove event
   *    - dragData: of the shape { x: number, offset: { x: number }} where x is the new position
   *                of the dragged element and offset is the drag offset - i.e. the distance
   *                between where the user clicked on the draggable element and the element's
   *                left edge
   */
  onDrag?: types.DragEventHandler;

  /**
   * called when dragging begins
   */
  onStart?: types.DragEventHandler;
  onStop?: types.ReactDraggableEventHandler;
  onMouseDown?: types.ReactDraggableEventHandler;

  /**
   * if specified, calculations will be made as if the draggable element were a child of
   * the element defined by this query selector
   */
  parentHandle?: string;

  /**
   * the position of the draggable object.  Note that Draggable doesn't change the position of
   * the object, but rather notifies the parent of the position change, only moving the
   * element when a new position is passed via props
   */
  position: {
    x?: number;
    y?: number;
  };
}

interface IState {
  data: RecordOf<types.IDataState>;
}

/**
 * A modification to react-draggable's Draggable that solves our use-case a little better
 *
 * react-draggable's Draggable has a few issues that make it unusable for us out-of-the-box
 *  - it always moves the draggable even if the object is controlled (passed a "position")
 *  - the "disabled" prop would be useful however it doesn't seem to take effect mid-drag.  so, if
 *    trying to detect if a dragged object has crossed some boundary and if so disabling the
 *    draggable, they'll still be able to continue their current drag, just not start a new one
 */
export default class Draggable extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    onDrag: _.noop,
    onMouseDown: _.noop,
    onStart: _.noop,
    onStop: _.noop,
  };

  private static isNum(n: any) {
    return typeof n === 'number' && !isNaN(n);
  }

  private static bounded(
    position: types.IPosition,
    size: types.ISize,
    bounds: types.IBounds,
  ) {
    if (!bounds) return position;

    let { x, y } = position;
    const { left, right, top, bottom } = bounds;
    const { height, width } = size;

    if (Draggable.isNum(right)) x = Math.min(x, right - width);
    if (Draggable.isNum(bottom)) y = Math.min(y, bottom - height);
    if (Draggable.isNum(left)) x = Math.max(x, left);
    if (Draggable.isNum(top)) y = Math.max(y, top);

    return { x, y };
  }

  private element: HTMLElement;
  private parent: HTMLElement;

  constructor(props: Readonly<IProps>) {
    super(props);

    this.state = {
      data: types.dataFactory({
        lastX: (props.position && props.position.x) || 0,
        offset: 0,
        width: 0,
      }),
    };
  }

  public componentDidMount() {
    const { handle, parentHandle } = this.props;

    // TODO can a ref or some other pattern be used here?
    // eslint-disable-next-line react/no-find-dom-node
    const doc = ReactDOM.findDOMNode(this).ownerDocument;

    this.element = doc.querySelector(handle);
    this.parent = parentHandle
      ? doc.querySelector(parentHandle)
      : this.element.parentElement;
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const { position, bounds } = nextProps;

    const x = position && position.x;
    const boundedX = bounds ? clamp(x, bounds.left, bounds.right) : x;
    this.setState(({ data }) => ({
      data: data.set('lastX', boundedX),
    }));
  }

  private handleDragStart = (event: React.MouseEvent<HTMLElement>) => {
    const { onStart } = this.props;

    const mouseX = event.clientX;
    const elementRect = this.element.getBoundingClientRect();
    const elementX = elementRect.left;
    const offset = mouseX - elementX;
    this.setState(({ data }) => ({
      data: data.withMutations(d =>
        d.set('offset', offset).set('width', elementRect.width),
      ),
    }));
    return onStart(
      event,
      this.createCallbackData(
        this.state.data.get('lastX'),
        offset,
        elementRect.width,
      ),
    );
  };

  private handleDragWithBounds = (event: React.MouseEvent<HTMLElement>) => {
    const { bounds, onDrag } = this.props;
    const { data } = this.state;

    const nextPosX = this.calculatePosition(event, data);

    const nextBoundedPos = Draggable.bounded(
      { x: nextPosX },
      { width: data.get('width') },
      bounds,
    );
    const callbackData = this.createCallbackData(
      nextBoundedPos.x,
      data.get('offset'),
      data.get('width'),
    );

    // only call the onDrag from props if the position has changed.  useful so we don't keep calling
    // the onDrag handler unnecessarily while the draggable is one of its bounds and cannot move
    // any further
    if (nextBoundedPos.x !== data.get('lastX')) {
      onDrag(event, callbackData);
    }
  };

  private convertHandler = (handler: types.ReactDraggableEventHandler) => (
    e: MouseEvent,
    data?: DraggableData,
  ) => {
    const event = (e as any) as React.MouseEvent<HTMLElement>;
    handler(event, data);
  };

  private createCallbackData(position: number, offset: number, width: number) {
    const { bounds: boundsProp } = this.props;
    const { data } = this.state;

    return {
      width,
      bounds: {
        ...(boundsProp || DEFAULT_BOUNDS),
      },
      lastX: data.get('lastX'),
      offset: {
        x: offset,
      },
      x: position,
    };
  }

  private calculatePosition(
    event: React.MouseEvent<HTMLElement>,
    data: RecordOf<types.IDataState>,
  ) {
    const mouseX = event.clientX;
    const targetClientX = mouseX - data.get('offset');
    const parentX = this.parent.getBoundingClientRect().left;
    const marginLeft =
      parseFloat(getComputedStyle(this.element).marginLeft) || 0;
    return targetClientX - parentX - marginLeft;
  }

  public render() {
    const { data } = this.state;
    const { children, onMouseDown, onStop } = this.props;

    return (
      <DraggableCore
        {...this.props}
        onDrag={this.convertHandler(this.handleDragWithBounds)}
        onStart={this.convertHandler(this.handleDragStart)}
        onStop={this.convertHandler(onStop)}
        onMouseDown={this.convertHandler(onMouseDown)}
      >
        {cloneOnlyChild(children, child => ({
          style: {
            ...child.props.style,
            transform: `translate(${data.get('lastX')}px, 0px)`,
          },
        }))}
      </DraggableCore>
    );
  }
}
