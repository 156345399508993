import React from 'react';

// types
import { IconProps } from './types';

const RSS: React.FunctionComponent<IconProps> = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    version="1.1"
    {...props}
  >
    <title>RSS</title>
    <g
      id="Platform"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M8.5768452,25.7114865 C8.5768452,28.079939 6.65684687,29.999933 4.28838912,29.999933 C1.91993136,29.999933 0,28.079939 0,25.7114865 C0,23.3430341 1.91999832,21.42304 4.28845608,21.42304 C6.65691383,21.42304 8.5768452,23.343101 8.5768452,25.7114865 L8.5768452,25.7114865 Z M20.3397814,28.8755392 C19.7803622,18.5228928 11.488115,10.2202177 1.12439634,9.66019708 C0.512678124,9.62711682 0,10.1186332 0,10.7312205 L0,13.9501168 C0,14.5136195 0.434597835,14.986185 0.996896452,15.0227474 C8.48563992,15.5105137 14.4883802,21.4983076 14.9771521,29.0029718 C15.0137815,29.5652692 15.4863481,29.9998661 16.0497851,29.9998661 L19.2686885,29.9998661 C19.8813443,29.9998661 20.3728617,29.4872561 20.3397814,28.8755392 Z M29.9993042,28.8948249 C29.4372065,13.2372842 16.8391594,0.565537811 1.10511064,0.000695814387 C0.500423671,-0.0210005468 0,0.467502401 0,1.07252284 L0,4.29135214 C0,4.86891731 0.457700494,5.33900513 1.0347982,5.36271042 C13.8369522,5.88764182 24.112479,16.1650876 24.6373446,28.9652041 C24.6609829,29.5423005 25.1310718,30 25.7087052,30 L28.9275417,30 C29.5324966,30 30.0210006,29.4995105 29.9993042,28.8948249 L29.9993042,28.8948249 Z"
        id="RSS"
        fill="#5DBEFC"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default RSS;
