import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, LinkProps, Route, Switch, useHistory } from 'react-router-dom';
import HeadlinerLogoLink from 'blocks/AuthNavBar/components/HeadlinerLogoLink';
import { Headliner } from 'components/icons';
import { headerLogoDisabledSelector } from 'redux/modules/display-pref/selectors';
import {
  AUTH_ROUTES,
  EDITOR_ROUTES,
  getHomepage,
  hasHeadlinerReferrer,
  isEditorActive,
  isSettingsActive,
  isWizardActive,
} from 'utils/routes';
import { block } from '../../utils';

const { useCallback, useMemo } = React;

const HeadlinerNavLogo = () => {
  const history = useHistory();
  const disabled = useSelector(headerLogoDisabledSelector);

  const showBackButton =
    isEditorActive() || isSettingsActive() || isWizardActive();

  const linkTo = useMemo(() => {
    if (showBackButton && hasHeadlinerReferrer()) {
      return '';
    }
    return getHomepage();
  }, [showBackButton]);

  const handleClick: LinkProps['onClick'] = useCallback(
    e => {
      if (showBackButton && hasHeadlinerReferrer()) {
        e.preventDefault();
        history.goBack();
      }
    },
    [showBackButton, history],
  );

  return (
    <Switch>
      <Route path={['/onboarding']}>
        <Link className={block('logo', { auth: true })} to={undefined}>
          <Headliner width={186} height={40} />
        </Link>
      </Route>
      <Route path={[...AUTH_ROUTES]}>
        <a
          className={block('logo', { auth: true })}
          href="https://www.headliner.app"
        >
          <Headliner width={186} height={40} />
        </a>
      </Route>
      <Route path={['/wizard', ...EDITOR_ROUTES]}>
        <HeadlinerLogoLink
          onClick={handleClick}
          disabled={disabled}
          to={linkTo}
          mark
        />
      </Route>
      <Route>
        <HeadlinerLogoLink
          onClick={handleClick}
          disabled={disabled}
          to={linkTo}
        />
      </Route>
    </Switch>
  );
};

export default HeadlinerNavLogo;
