export enum Type {
  USER_PREF_GET_REQUEST = 'app/user-pref/USER_PREF_GET_REQUEST',
  USER_PREF_GET_SUCCESS = 'app/user-pref/USER_PREF_GET_SUCCESS',
  USER_PREF_GET_FAILURE = 'app/user-pref/USER_PREF_GET_FAILURE',

  WAVEFORM_PREF_DELETE_REQUEST = 'app/user-pref/WAVEFORM_PREF_DELETE_REQUEST',
  WAVEFORM_PREF_DELETE_SUCCESS = 'app/user-pref/WAVEFORM_PREF_DELETE_SUCCESS',
  WAVEFORM_PREF_DELETE_FAILURE = 'app/user-pref/WAVEFORM_PREF_DELETE_FAILURE',

  WAVEFORM_PREF_CREATE_REQUEST = 'app/user-pref/WAVEFORM_PREF_CREATE_REQUEST',
  WAVEFORM_PREF_CREATE_SUCCESS = 'app/user-pref/WAVEFORM_PREF_CREATE_SUCCESS',
  WAVEFORM_PREF_CREATE_FAILURE = 'app/user-pref/WAVEFORM_PREF_CREATE_FAILURE',

  COLOR_PREF_UPDATE_REQUEST = 'app/user-pref/COLOR_PREF_UPDATE_REQUEST',
  COLOR_PREF_UPDATE_SUCCESS = 'app/user-pref/COLOR_PREF_UPDATE_SUCCESS',
  COLOR_PREF_UPDATE_FAILURE = 'app/user-pref/COLOR_PREF_UPDATE_FAILURE',

  CAPTIONS_PREF_PUT_REQUEST = 'app/captions-pref/PREF_PUT_REQUEST',
  CAPTIONS_PREF_PUT_SUCCESS = 'app/captions-pref/PREF_PUT_SUCCESS',
  CAPTIONS_PREF_PUT_FAILURE = 'app/captions-pref/PREF_PUT_FAILURE',

  VIDEO_EXPORT_PREF_UPDATE_REQUEST = 'app/user-pref/VIDEO_EXPORT_PREF_UPDATE_REQUEST',
  VIDEO_EXPORT_PREF_UPDATE_SUCCESS = 'app/user-pref/VIDEO_EXPORT_PREF_UPDATE_SUCCESS',
  VIDEO_EXPORT_PREF_UPDATE_FAILURE = 'app/user-pref/VIDEO_EXPORT_PREF_UPDATE_FAILURE',
}
