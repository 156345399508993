import React from 'react';
import { useDispatch } from 'react-redux';
import ExportBalanceBanner from 'components/ExportBalanceBanner';
import FadeTransition from 'components/FadeTransition';
import { H1 } from 'components/Heading';
import LinkButton from 'components/LinkButton';
import { onClickExportUpgrade } from 'redux/modules/mixpanel';
import { ExportUpgradeFrom } from 'types';
import { block } from './utils';

export interface CustomizeStepCtaOverlayProps {
  show: boolean;
  onCancelClick?: () => void;
  overlaySource: ExportUpgradeFrom;
  children: React.ReactNode;
}

const CustomizeStepCtaOverlay: React.FC<CustomizeStepCtaOverlayProps> = ({
  onCancelClick,
  show,
  overlaySource,
  children,
}) => {
  const dispatch = useDispatch();

  const handleUpgradeClick = (event: React.MouseEvent) => {
    event.preventDefault();
    dispatch(onClickExportUpgrade(overlaySource));
  };

  return (
    <FadeTransition
      className={block('cta-overlay')}
      mountOnEnter
      unmountOnExit
      in={show}
    >
      <div className={block('cta-overlay-contents')}>
        <H1>How would you like to proceed?</H1>
        <ExportBalanceBanner
          className={block('balance')}
          onUpgradeClick={handleUpgradeClick}
        />
        <div className={block('export-buttons')}>{children}</div>
        <LinkButton
          className={block('cta-back-button')}
          onClick={onCancelClick}
        >
          RETURN TO CUSTOMIZE OPTIONS
        </LinkButton>
      </div>
    </FadeTransition>
  );
};

export default CustomizeStepCtaOverlay;
