import { isUndefined } from 'underscore';
import { DeepImmutableMap } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { DEFAULT_TEXT_OVERLAY } from 'utils/constants';
import { getPosition, getSize, getViewportMap } from 'utils/viewport';

import { ITextOverlay } from '../types';

export function createDefaultOverlay(
  aspectRatio: number,
): DeepImmutableMap<ITextOverlay> {
  const aspectRatioName = getAspectRatioName(aspectRatio);
  const size = getSize(aspectRatioName);
  const viewport = getViewportMap(aspectRatioName);
  const position = getPosition(aspectRatioName);

  return (DEFAULT_TEXT_OVERLAY as any).mergeWith(
    (oldVal, newVal) => (isUndefined(newVal) ? oldVal : newVal),
    { viewport, size, position },
  );
}
