import { List } from 'immutable';
import { isUndefined } from 'underscore';

import { IVideoExportAdjustment } from 'redux/middleware/api/headliner-user-service';
import { DeepImmutableMap, SoundwaveOption, SoundwaveType } from 'types';
import { omitUndefined } from 'utils/collections';
import { circleWaveTypes, customWaveTypes } from './constants';

/*
 * maps values from "text.presetStyle.overrideOptions" to the ids used for these text templates
 * in the code
 */
export function mapApiTextTemplateOverrideToTemplateId(overrideIds: string[]) {
  return overrideIds.map(override => {
    /*
     * TODO confirm with Youhyun where these override names come from.  for now assume it is the
     * name of the template (displayed in the image) in camel case
     */
    switch (override) {
      case 'boldDescription':
        return 'facebook';

      case 'italicsSpeech':
        return 'micdotcom';

      case 'largeHeading':
        return 'shortcut';

      case 'darkBackground':
        return 'bbc';

      case 'blockyDescription':
        return 'nowthis';

      case 'centeredHeading':
        return 'bu';

      case 'title':
        return 'title1';

      case 'quote':
        return 'quote1';

      case 'quote2':
        return 'quote2';

      case 'caption':
        return 'caption1';

      case 'copyrightText':
        return 'copyright1';

      case 'gradientSubtitle':
        return 'gradient1';

      case 'leftInsert':
        return 'leftInsert1';

      case 'rightInsert':
        return 'rightInsert1';

      case 'fullScreenOverlay':
        return 'fullScreen1';

      case 'animatedTitle':
        return 'titleAnimated1';

      case 'animatedDetail':
        return 'detailAnimated1';

      case 'headliner':
        return 'default';

      case 'socialMediaTextStyle':
        return 'instagram';

      default:
        return override;
    }
  });
}

export const getFilteredWaveOptions = (
  defaultOptions: SoundwaveOption[],
  overrides: List<string>,
  hiddenOptionValues: string[] = [],
) => {
  if (overrides && overrides.size) {
    return defaultOptions.filter((option: SoundwaveOption) =>
      overrides.includes(option.value),
    );
  }

  return defaultOptions.filter(
    (option: SoundwaveOption) => !hiddenOptionValues.includes(option.value),
  );
};

export const getWaveOptionDisplayNameByValue = (
  options: SoundwaveOption[],
  value: string,
) => {
  const selectedOption: SoundwaveOption = options.find(
    (option: SoundwaveOption) => option.value === value,
  );

  return (selectedOption && selectedOption.displayName) || '';
};

export const isCircleWaveType = (waveType: SoundwaveType) =>
  circleWaveTypes.includes(waveType);

export const isCustomWaveType = (waveType: SoundwaveType) =>
  customWaveTypes.includes(waveType);

export const createDefaultExportSetting = obj => {
  if (!obj) return undefined;

  const value = obj.get('default');
  if (isUndefined(value)) return undefined;

  return {
    value,
    readonly: obj.get('readonly'),
  };
};

export const lockedFieldsFormatter = (defaultSettings: unknown) =>
  Object.keys(defaultSettings).reduce((acc, key: any) => {
    if (defaultSettings[key].readonly) {
      acc.add(key);
    }
    return acc;
  }, new Set<any>());

export const videoExportSettingsFormatter = (
  prefs: DeepImmutableMap<IVideoExportAdjustment>,
  ratioName: string,
) => {
  const frameRate = prefs?.get('frameRate');
  const fullEpisodeSize = prefs?.get('fullEpisodeSize');
  const quality = prefs?.get('quality');
  const size = prefs?.getIn(['size', ratioName]);
  const outro = prefs?.getIn(['outroVideo', ratioName]);
  const intro = prefs?.getIn(['introVideo', ratioName]);

  return omitUndefined({
    frameRate: createDefaultExportSetting(frameRate),
    fullEpisodeSize,
    intro: createDefaultExportSetting(intro),
    outro: createDefaultExportSetting(outro),
    quality: createDefaultExportSetting(quality),
    size: createDefaultExportSetting(size),
  });
};
