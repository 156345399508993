import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'IMAGE_SEARCH_SERVICE';
export const ACTION_KEY: ActionKey = 'IMAGE_SEARCH_SERVICE';

export enum ServiceMethod {
  SEARCH_FOR_IMAGES = 'SEARCH_FOR_IMAGES',
  SEARCH_FOR_VIDEOS = 'SEARCH_FOR_VIDEOS',
  SEARCH_FOR_GIFS = 'SEARCH_FOR_GIFS',
  CREATE_TEXT_TO_IMAGE = 'CREATE_TEXT_TO_IMAGE',
  GET_TEXT_TO_IMAGE = 'GET_TEXT_TO_IMAGE',
  CREATE_IMAGE_TO_VIDEO = 'CREATE_IMAGE_TO_VIDEO',
  GET_IMAGE_TO_VIDEO = 'GET_IMAGE_TO_VIDEO',
}

interface IRemoteMedia {
  height: number;
  width: number;
  url: string;
}

interface ISearchQuery {
  page: number;
  pageSize: number;
  q: string;
  totalResults: number;
}

export interface IMediaMetadata {
  attributionHtml?: {
    default: string;
  };
  attributionText: {
    default: string;
  };
  author?: string;
  contextUrl: string;
  domain: string;
  purchaseUrl?: string;
  usageTerm?: string;
  description?: string;
}

interface IImageSearchResult {
  image: IRemoteMedia;
  metadata: IMediaMetadata;
  thumbnail: IRemoteMedia;
}

interface IVideoSearchResult {
  video: IRemoteMedia & {
    durationMillis: number;
    aspectRatioWidth: number;
    aspectRatioHeight: number;
  };
  previewThumbnail: IRemoteMedia;
  metadata: IMediaMetadata;
}

interface IGifSearchResult {
  gif: IRemoteMedia;
  video: IRemoteMedia & {
    durationMillis: number;
    aspectRatioWidth: number;
    aspectRatioHeight: number;
  };
  previewGif: IRemoteMedia;
  previewThumbnail: IRemoteMedia;
  metadata: IMediaMetadata;
}
interface ISearchResponse<T> {
  content: T[];
  engine: string;
  queries: {
    current: ISearchQuery;
  };
}

type ImageSearchResponse = ISearchResponse<IImageSearchResult>;
export type VideoSearchResponse = ISearchResponse<IVideoSearchResult>;
export type GifSearchResponse = ISearchResponse<IGifSearchResult>;

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export type SearchForGifsArgs = [string, string, number, number];
export type SearchForGifsAction = ApiAction<
  ServiceMethod.SEARCH_FOR_GIFS,
  SearchForGifsArgs
>;
export type SearchForGifsResult = GifSearchResponse;

export type AiImageType =
  | 'stabilityCinematic'
  | 'stabilityIsometric'
  | 'stabilityDigitalArt'
  | 'stabilityPhotographic'
  | '';

export interface TextToImageColor {
  hexColorCode: string;
}

export type CreateTextToImageArgs = [
  string, // Prompt
  TextToImageProvider, // Provider
  number, // Width
  number, // Height
  AiImageType, // Image type,
  TextToImageColor[], // Colors
];

export type CreateTextToImageAction = ApiAction<
  ServiceMethod.CREATE_TEXT_TO_IMAGE,
  CreateTextToImageArgs
>;

export type CreateTextToImageResult = {
  textToImageJobId: number;
};

export type GetTextToImageArgs = [number];

export type GetTextToImageAction = ApiAction<
  ServiceMethod.GET_TEXT_TO_IMAGE,
  GetTextToImageArgs
>;

export type AiAssetStatus = 'queued' | 'processing' | 'completed' | 'error';

export type CreateImageToVideoArgs = [
  string, // sourceImageUrl
  TextToImageProvider, // provider
];

export type GetImageToVideoArgs = [number];

export type CreateImageToVideoAction = ApiAction<
  ServiceMethod.CREATE_IMAGE_TO_VIDEO,
  CreateImageToVideoArgs
>;

export type CreateImageToVideoResult = {
  imageToVideoJobId: number;
};

export type GetImageToVideoAction = ApiAction<
  ServiceMethod.GET_IMAGE_TO_VIDEO,
  GetImageToVideoArgs
>;

export type GetImageToVideoResult = {
  id: number;
  status: AiAssetStatus;
  videos: AiAsset[];
};

export type TextToImageProvider = 'stability';

export type ImageToVideoProvider = 'stability';

export interface AiAsset {
  url: string;
  width: number;
  height: number;
}

export type GetTextToImageResult = {
  id: number;
  status: AiAssetStatus;
  images: AiAsset[];
};

export type SearchForImagesArgs = [string, string, number, number];
export type SearchForImagesAction = ApiAction<
  ServiceMethod.SEARCH_FOR_IMAGES,
  SearchForImagesArgs
>;
export type SearchForImagesResult = ImageSearchResponse;

export type SearchForVideosArgs = [string, string, number, number];
export type SearchForVideosAction = ApiAction<
  ServiceMethod.SEARCH_FOR_VIDEOS,
  SearchForVideosArgs
>;
export type SearchForVideosResult = VideoSearchResponse;

export { IVideoSearchResult as VideoSearchResult };
export { IGifSearchResult as GifSearchResult };

export interface ImageSearchServiceDispatch {
  (action: SearchForGifsAction): Promise<IApiResponse<SearchForGifsResult>>;
  (action: SearchForImagesAction): Promise<IApiResponse<SearchForImagesResult>>;
  (action: SearchForVideosAction): Promise<IApiResponse<SearchForVideosResult>>;
  (action: CreateTextToImageAction): Promise<
    IApiResponse<CreateTextToImageResult>
  >;
  (action: GetTextToImageAction): Promise<IApiResponse<GetTextToImageResult>>;
  (action: CreateImageToVideoAction): Promise<
    IApiResponse<CreateImageToVideoResult>
  >;
  (action: GetImageToVideoAction): Promise<IApiResponse<GetImageToVideoResult>>;
}
