import * as React from 'react';

import { IconProps } from './types';

const Headset: React.SFC<IconProps> = ({ fill = 'currentColor', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="14"
    viewBox="0 0 13 14"
    {...props}
  >
    <path
      fill={fill}
      fillRule="nonzero"
      d="M4.875 5.531a.813.813 0 0 0-.813-.812h-.406c-.897 0-1.625.727-1.625 1.625v1.218c0 .898.728 1.625 1.625 1.625h.406a.813.813 0 0 0 .813-.812V5.531zm4.469 3.657c.897 0 1.625-.728 1.625-1.626V6.345c0-.898-.728-1.625-1.625-1.625h-.406a.813.813 0 0 0-.813.812v2.844c0 .449.364.813.813.813h.406zM6.5.25C2.874.25.116 3.267 0 6.75v.406c0 .225.182.407.406.407h.406a.406.406 0 0 0 .407-.407V6.75A5.287 5.287 0 0 1 6.5 1.469a5.287 5.287 0 0 1 5.281 5.281h-.003c.002.062.003 4.208.003 4.208 0 .593-.48 1.073-1.073 1.073H8.125c0-.673-.546-1.219-1.219-1.219h-.812a1.219 1.219 0 1 0 0 2.438h4.614A2.292 2.292 0 0 0 13 10.958V6.75c-.116-3.483-2.874-6.5-6.5-6.5z"
    />
  </svg>
);

export default Headset;
