import { useCallback, useEffect, useRef } from 'react';
import { isFunction } from 'underscore';

type EventHandler<A extends any[]> = (...args: A) => void;
type CleanupFunction = () => void;
type AttachHandler<E, A extends any[]> = (
  element: E,
  handler: EventHandler<A>,
) => void | CleanupFunction;

function isCleanupFunction(
  val: void | CleanupFunction,
): val is CleanupFunction {
  return isFunction(val);
}

export default function useEventHandler<E, A extends any[] = any[]>(
  handler: EventHandler<A>,
  attach: AttachHandler<E, A>,
) {
  // wrap the attach function in a ref so that we can use it in the callback ref
  // and don't have to pass it in the dependencies, which might cause react to
  // call it unnecessarily, having undesired or unexpected effects
  const attachRef = useRef(attach);

  // the underlying handler passed into the hook.  this will get wrapped in a
  // stable callback that will never change
  const sourceHandler = useRef<EventHandler<A>>(handler);

  // this will typically be used to remove the event handler
  const cleanupFunction = useRef<CleanupFunction>();

  // stable event handler callback
  const handleEvent = useCallback((...args: A) => {
    if (sourceHandler.current) {
      sourceHandler.current(...args);
    }
  }, []);

  // updates the underlying callback when it changes
  useEffect(() => {
    sourceHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    attachRef.current = attach;
  }, [attach]);

  // helper function to call cleanup and clear it if it's defined
  const cleanup = () => {
    if (cleanupFunction.current) {
      cleanupFunction.current();
    }
    cleanupFunction.current = null;
  };

  // callback ref.  used to update the state of this hook as the ref changes
  const handleRef = useCallback(
    (el: E | null) => {
      // received a new element. might be null.  either way, should cleanup
      // this will result in cleanupFunction.current set to null
      cleanup();

      if (el) {
        const result = attachRef.current(el, handleEvent);
        if (isCleanupFunction(result)) {
          cleanupFunction.current = result;
        }
      }
    },
    // callback ref so it's important that this array is either empty or the
    // dependencies don't change
    [handleEvent],
  );

  return handleRef;
}
