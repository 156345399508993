import cn from 'classnames';
import * as React from 'react';

import bem from 'utils/bem';
import {
  ModalBody,
  ModalFooter,
  ModalFooterButton,
  ModalFooterButtonProps,
  ModalFooterButtons,
} from './Modal';
import RadioOptions, { Option, RadioOptionsProps } from './RadioOptions';

export interface ActionProps extends ModalFooterButtonProps {
  key?: string | number;
}

export interface DialogRadioContentProps<T extends Option<V>, V = any> {
  actions?: ActionProps[];
  className?: string;
  label: RadioOptionsProps<T, V>['label'];
  onChange: RadioOptionsProps<T, V>['onChange'];
  options: RadioOptionsProps<T, V>['options'];
  value: RadioOptionsProps<T, V>['value'];
  appearance?: RadioOptionsProps<T, V>['appearance'];
}

const block = bem('dialog-radio-content');

export default function DialogRadioContent<T extends Option<V>, V = any>({
  actions,
  className,
  label,
  onChange,
  options,
  value,
  appearance = 'option',
}: DialogRadioContentProps<T, V>) {
  return (
    <>
      <ModalBody className={cn(block(), className)}>
        <RadioOptions<T, V>
          label={label}
          listClassName={block('list')}
          optionClassName={block('option')}
          {...{ onChange, options, value }}
          appearance={appearance}
        />
      </ModalBody>
      {actions.length > 0 && (
        <ModalFooter>
          <ModalFooterButtons fluid>
            {actions.map(action => (
              <ModalFooterButton {...action} />
            ))}
          </ModalFooterButtons>
        </ModalFooter>
      )}
    </>
  );
}
