import * as React from 'react';

import { IconProps } from 'components/icons/types';

const SupportIcon: React.FC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M5.906 6.398a.984.984 0 0 0-.984-.984H4.43a1.969 1.969 0 0 0-1.97 1.969v1.476c0 1.088.882 1.97 1.97 1.97h.492c.543 0 .984-.442.984-.985V6.398zm5.414 4.43a1.969 1.969 0 0 0 1.97-1.969V7.383a1.969 1.969 0 0 0-1.97-1.969h-.492a.984.984 0 0 0-.984.984v3.446c0 .543.44.984.984.984h.492zM7.875 0C3.482 0 .141 3.655 0 7.875v.492c0 .272.22.492.492.492h.492c.272 0 .493-.22.493-.492v-.492a6.406 6.406 0 0 1 6.398-6.398 6.406 6.406 0 0 1 6.398 6.398h-.003c.002.075.003 5.098.003 5.098a1.3 1.3 0 0 1-1.3 1.3h-3.13c0-.815-.66-1.476-1.476-1.476h-.984a1.477 1.477 0 1 0 0 2.953h5.59a2.777 2.777 0 0 0 2.777-2.777V7.875C15.61 3.655 12.268 0 7.875 0z"
    />
  </svg>
);

export default SupportIcon;
