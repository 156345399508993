import classNames from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';
import { isUndefined } from 'underscore';
import VideoPlayer from 'components/VideoPlayer';
import dayjs from 'utils/dayjs';
import { formatDurationMillis } from 'utils/time';
import { Dimensions } from './types';

export interface VideoTileProps {
  poster?: string;
  src: string;
  aspectRatio: Dimensions;
  durationMillis: number;
  className?: string;
}

const VideoTile: React.FC<VideoTileProps> = ({
  poster,
  src,
  aspectRatio,
  durationMillis,
  className,
}) => {
  const playerRef = useRef<VideoPlayer>(null);
  const playDelayTimerIdRef = useRef<number>();

  const cancelDelayPlayTimer = useCallback((): void => {
    if (!isUndefined(playDelayTimerIdRef.current)) {
      window.clearTimeout(playDelayTimerIdRef.current);

      playDelayTimerIdRef.current = undefined;
    }
  }, []);

  const handleMouseEnter = useCallback((): void => {
    playDelayTimerIdRef.current = window.setTimeout(() => {
      if (playerRef.current) {
        playerRef.current.play();
      }
    }, 1000);
  }, []);

  /**
   * NOTE: don't seek back to 0 here. In the original implementation, we seeked back to zero on
   * pause which, for some reason, caused Chrome to hold onto open sockets. This would eventually
   * prevent other videos from loading. Something about not seeking back to 0 prevents this behavior.
   */
  const handleMouseLeave = useCallback(() => {
    cancelDelayPlayTimer();

    if (playerRef.current) {
      playerRef.current.pause();
    }
  }, [cancelDelayPlayTimer]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.volume = 0;
    }

    return () => {
      cancelDelayPlayTimer();
    };
  }, [cancelDelayPlayTimer]);

  return (
    <div
      className={classNames('media-search__video-tile', className)}
      data-testid="video-tile"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="media-search__video-mouse-overlay">
        <VideoPlayer
          poster={poster}
          preload="none"
          src={src}
          controls="hide"
          ref={el => (playerRef.current = el)}
        />
      </div>

      <div className="media-search__video-tile-footer">
        <span className="media-search__video-tile-ratio">
          {`${aspectRatio.width}:${aspectRatio.height}`}
        </span>

        <span className="media-search__video-tile-duration">
          {formatDurationMillis(durationMillis, {
            hour: dayjs.duration(durationMillis).hours()
              ? 'numeric'
              : undefined,
            minute: '2-digit',
            second: '2-digit',
            trim: false,
          })}
        </span>
      </div>
    </div>
  );
};

export default VideoTile;
