import { Text } from '@sparemin/blockhead';
import React from 'react';
import AutomationPostModalHeader from 'components/AutomationPostModalHeader';
import DynamicElementsPopper from 'components/DynamicElementsPopper';
import EducationalCard from 'components/EducationalCard/EducationalCard';
import Select, { Overrides } from 'components/Select';
import ShareFormTextarea from 'components/ShareForm/ShareFormTextarea';
import {
  captionsMetadata,
  MAX_CHARS_CAPTIONS,
} from 'containers/InstagramPostModal/constants';
import { InstagramAccountOption } from 'containers/InstagramPostVideoModal/types';
import { block } from '../../utils';

export interface ComposerDefaultViewProps {
  description: string;
  accounts: InstagramAccountOption[];
  instagramUserId: string | number;
  selectComponentOverrides: Overrides<InstagramAccountOption>;
  onDescriptionChange: (newDescription: string) => void;
  onUserChange?: (newUserId: string | number) => void;
}

const ComposerDefaultView: React.FC<ComposerDefaultViewProps> = ({
  description,
  accounts,
  instagramUserId,
  selectComponentOverrides,
  onDescriptionChange,
  onUserChange,
}) => {
  return (
    <>
      {!!accounts.length && (
        <Select
          options={accounts}
          overrides={selectComponentOverrides}
          // The accounts list contains the same values as those returned by
          // `instagramAccountsSelector`, but with different property names
          // assigned to accommodate the Select component. The `value`
          // property corresponds to the selected instagram user id.
          value={accounts.find(({ value }) => value === instagramUserId)}
          className={block('accounts-list')}
          onChange={({ value }) => onUserChange(value)}
        />
      )}

      <AutomationPostModalHeader
        title="Let’s set up your video captions"
        description="We'll post these captions with each video. You can pull information from your RSS feed, such as episode titles, to make each post unique."
      />

      <ShareFormTextarea
        hideProgress
        type="textarea"
        maxChars={MAX_CHARS_CAPTIONS}
        value={description}
        className={block('description-container')}
        controlClassName={block('description-input')}
        label={
          <Text variant="label">
            <b>Example of your captions</b> (up to {MAX_CHARS_CAPTIONS}{' '}
            characters)
          </Text>
        }
        onChange={onDescriptionChange}
      />

      <EducationalCard>
        <DynamicElementsPopper
          buttonContent="add content from your rss"
          elementsList={captionsMetadata}
          onClick={metaValue =>
            onDescriptionChange(`${description} ${metaValue}`)
          }
        />
      </EducationalCard>
    </>
  );
};

export default ComposerDefaultView;
