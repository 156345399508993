import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import _, { isNumber } from 'underscore';

import { DEFAULT_VOLUME } from 'components/VolumeControl';
import { clamp, min, round } from 'utils/numbers';
import { callRender } from 'utils/react';
import { ComponentOrFunction } from '../../types';
import { RegionUpdateAction, SocialPresetOption } from './types';

const SEEK_PADDING_MILLIS = 0.1;

type SetDuration = (millis: number) => void;
type Seek = (progress: number) => void;
type Zoom = (pxPerSec: number) => void;
type SetPlaybackPos = (millis: number) => void;
type Play = () => void;
type Pause = () => void;
type Stop = () => void;
type OnPresetChange = (preset: SocialPresetOption) => void;
type PlaySelection = (region?: IRegion) => void;
type SetRegion = (region: IRegion) => void;
type SetRegionDuration = (millis: number) => void;
type SetInputError = (
  inputType: 'start' | 'end' | 'duration',
  message?: string,
) => void;
type SetAudioLoading = (isLoading: boolean) => void;
type SetBuffer = (buffer: AudioBuffer) => void;
type SetWaveSurferReady = (ready?: boolean) => void;

interface Data
  extends Pick<
    IDataState,
    | 'audioLoading'
    | 'durationErrorMessage'
    | 'durationMillis'
    | 'endErrorMessage'
    | 'seekToMillis'
    | 'playing'
    | 'positionMillis'
    | 'startErrorMessage'
    | 'volume'
    | 'waveSurferReady'
    | 'zoom'
  > {
  region: IRegion;
}

interface IRenderProps {
  data: Readonly<Data>;
  onPresetChange?: OnPresetChange;
  pause: Pause;
  play: Play;
  playSelection: PlaySelection;
  seek: Seek;
  setBuffer: SetBuffer;
  setDuration: SetDuration;
  setInputError: SetInputError;
  setAudioLoading: SetAudioLoading;
  setPlaybackPos: SetPlaybackPos;
  setRegion: SetRegion;
  setRegionDuration: SetRegionDuration;
  setWaveSurferReady: SetWaveSurferReady;
  stop: Stop;
  zoom: Zoom;
}

interface IRegion {
  endMillis?: number;
  startMillis?: number;
}

interface IProps {
  defaultAudioClipDurationMillis?: number;
  defaultPositionMillis?: number;
  maxDurationMillis?: number;
  minDurationMillis?: number;
  onAudioProcess?: (millis: number) => void;
  onPresetChange?: (preset: SocialPresetOption) => void;
  onError?: () => void;
  onMediaError?: (message: string) => void;
  onClearError?: () => void;
  onPause?: () => void;
  onPlay?: () => void;
  onReady?: (buffer: AudioBuffer) => void;
  onRegionUpdate?: (region: IRegion, action: RegionUpdateAction) => void;
  onStop?: () => void;
  playing?: boolean;
  region?: IRegion;
  render?: ComponentOrFunction<IRenderProps>;
}

interface IDataState {
  audioLoading: boolean;
  durationErrorMessage?: string;
  durationMillis: number;
  endErrorMessage?: string;
  playing: boolean;
  positionMillis: number;
  seekToMillis: number;
  seekPaddingMillis: number;
  startErrorMessage?: string;
  stopPlaybackAtMillis: number;
  volume: number;
  zoom: number;
  waveSurferReady: boolean;
}

interface IState {
  data: RecordOf<IDataState>;
}

const dataFactory = Record<IDataState>({
  audioLoading: undefined,
  durationErrorMessage: undefined,
  durationMillis: undefined,
  endErrorMessage: undefined,
  playing: undefined,
  positionMillis: undefined,
  seekPaddingMillis: undefined,
  seekToMillis: undefined,
  startErrorMessage: undefined,
  stopPlaybackAtMillis: undefined,
  volume: undefined,
  zoom: undefined,
  waveSurferReady: undefined,
});

export default class AudioClipperContainer extends React.Component<
  IProps,
  IState
> {
  public static defaultProps: Partial<IProps> = {
    defaultAudioClipDurationMillis:
      spareminConfig.defaultAudioClipDurationMillis,
    maxDurationMillis: Infinity,
    onAudioProcess: _.noop,
    onClearError: _.noop,
    onError: _.noop,
    onPause: _.noop,
    onPlay: _.noop,
    onReady: _.noop,
    onRegionUpdate: _.noop,
    onStop: _.noop,
    region: {},
    render: () => null,
  };

  private buffer: AudioBuffer;

  constructor(props: IProps) {
    super(props);

    this.state = {
      data: dataFactory({
        audioLoading: true,
        durationErrorMessage: undefined,
        durationMillis: undefined,
        endErrorMessage: undefined,
        playing: false,
        positionMillis: 0,
        // regionEndMillis: undefined,
        seekToMillis: undefined,
        startErrorMessage: undefined,
        stopPlaybackAtMillis: undefined,
        volume: DEFAULT_VOLUME / 100,
        zoom: 1,
      }),
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const { playing: nextPlaying } = nextProps;
    const { data } = this.state;

    if (!_.isUndefined(nextPlaying) && nextPlaying !== data.playing) {
      this.setState(({ data: dataState }) => ({
        data: dataState.set('playing', nextPlaying),
      }));
    }
  }

  public componentWillUnmount() {
    delete this.buffer;
  }

  private setDuration: SetDuration = millis => {
    const {
      defaultAudioClipDurationMillis,
      maxDurationMillis,
      onRegionUpdate,
      region,
    } = this.props;

    const { data } = this.state;

    if (_.isUndefined(data.durationMillis)) {
      const allowedDuration = _.isUndefined(millis)
        ? maxDurationMillis
        : min(Math.trunc(millis), defaultAudioClipDurationMillis);

      if (
        !_.isNumber(region?.startMillis) ||
        !_.isNumber(region?.endMillis) ||
        region.startMillis > allowedDuration ||
        region.endMillis > allowedDuration
      ) {
        onRegionUpdate(
          {
            endMillis: allowedDuration,
            startMillis: 0,
          },
          'init',
        );
      }
    }

    this.setState(({ data: prevData }) => ({
      data: prevData.set(
        'durationMillis',
        isNumber(millis) ? Math.trunc(millis) : millis,
      ),
    }));
  };

  private seek = (progress: number) => {
    const { region } = this.props;

    this.setState(({ data }) => ({
      data: data.withMutations(d => {
        if (!d.durationMillis) {
          return d;
        }

        const posMillis = progress * d.durationMillis;
        d.set('positionMillis', posMillis);

        /*
         * when the user seeks, we want to clear the stop playback time in case they seek past the
         * end of the region.  in that case we should alow them to keep playing.
         *
         * play selection involves a seek to the beginning of the region, so we only clear the stop
         * playback time if we get a seek event that's not the seek event for play selection.
         */
        if (
          round(posMillis) < round(region.startMillis) ||
          round(posMillis) > round(region.endMillis)
        ) {
          d.delete('stopPlaybackAtMillis');
        }
        return d;
      }),
    }));
  };

  private zoom = (pxPerSec: number) => {
    this.setState(({ data: prevData }) => ({
      data: prevData.set('zoom', pxPerSec),
    }));
  };

  private setPlaybackPos: SetPlaybackPos = millis => {
    const { onAudioProcess } = this.props;

    this.setState(
      ({ data }) => ({
        data: data.withMutations(d => {
          d.set('positionMillis', clamp(millis, 0, d.durationMillis));

          if (d.playing && d.positionMillis >= d.stopPlaybackAtMillis) {
            d.set('playing', false);
            this.seekTo(d.stopPlaybackAtMillis, d);
            d.delete('stopPlaybackAtMillis');
          }

          return d;
        }),
      }),
      () => {
        onAudioProcess(this.state.data.positionMillis);
      },
    );
  };

  private setRegion: SetRegion = newRegion => {
    const { onClearError, region } = this.props;

    // only set the region if the amount of milliseconds have changed
    const startMillis = round(newRegion.startMillis);
    const endMillis = round(newRegion.endMillis);
    if (
      round(region?.startMillis) === startMillis &&
      round(region?.endMillis) === endMillis
    ) {
      return;
    }

    const roundedRegion = { startMillis, endMillis };

    this.setState(({ data }) => ({
      data: data.withMutations(d => {
        this.setRegionMillis(roundedRegion, d);
        d.delete('startErrorMessage');
        d.delete('endErrorMessage');
        d.delete('durationErrorMessage');

        /*
         * if we have a value for `stopPlaybackAtMillis`, then the user clicked play selection which
         * should stop playback at the end of the region.  if the new region end is to the right of
         * the playhead, we can still stop playback there. if it's to the left of the playhead, then
         * just let it keep playing
         */
        if (
          !_.isUndefined(d.stopPlaybackAtMillis) &&
          region.endMillis > d.positionMillis
        ) {
          d.set('stopPlaybackAtMillis', region.endMillis);
        }
        return d;
      }),
    }));

    if (
      !_.isUndefined(roundedRegion.startMillis) &&
      !_.isUndefined(roundedRegion.endMillis)
    ) {
      onClearError();
    }
  };

  public play: Play = () => {
    const { onPlay, region } = this.props;
    onPlay();

    this.setState(({ data }) => ({
      data: data.withMutations(d => {
        if (
          !d.playing &&
          d.positionMillis >= region.startMillis &&
          d.positionMillis < region.endMillis
        ) {
          // d.set('stopPlaybackAtMillis', d.regionEndMillis);
          d.set('stopPlaybackAtMillis', region.endMillis);
        }
        d.set('playing', true);
        return d;
      }),
    }));
  };

  public pause: Pause = () => {
    const { onPause } = this.props;
    onPause();
    this.setState(({ data }) => ({
      data: data.withMutations(d =>
        d.set('playing', false).delete('stopPlaybackAtMillis'),
      ),
    }));
  };

  private stop: Stop = () => {
    const { onStop } = this.props;
    onStop();

    this.setState(({ data }) => ({
      data: data.withMutations(d => {
        d.set('playing', false);
        this.seekTo(0, d);
        return d;
      }),
    }));
  };

  public playSelection: PlaySelection = region => {
    const { onPlay, region: defaultRegion } = this.props;
    const { startMillis, endMillis } = region ?? defaultRegion;
    onPlay();
    this.setState(({ data }) => ({
      data: data.withMutations(d => {
        // this.seekTo(d.regionStartMillis, d);
        this.seekTo(startMillis, d);
        d.set('playing', true);
        // d.set('stopPlaybackAtMillis', d.regionEndMillis);
        d.set('stopPlaybackAtMillis', endMillis);
        return d;
      }),
    }));
  };

  private setRegionDuration: SetRegionDuration = millis => {
    const {
      maxDurationMillis,
      minDurationMillis,
      onRegionUpdate,
      region,
    } = this.props;
    const { data } = this.state;
    const { durationMillis } = data;
    const clampedDuration = min(
      clamp(millis, minDurationMillis, maxDurationMillis),
      durationMillis,
    );
    const startMillis = clamp(
      region.startMillis,
      0,
      durationMillis - clampedDuration,
    );
    onRegionUpdate(
      {
        startMillis,
        endMillis: startMillis + clampedDuration,
      },
      'duration-change',
    );
  };

  private setInputError: SetInputError = (type, message) => {
    const { onError } = this.props;
    const key = (() => {
      switch (type) {
        case 'start':
          return 'startErrorMessage';
        case 'end':
          return 'endErrorMessage';
        case 'duration':
          return 'durationErrorMessage';
      }
      return undefined;
    })();
    this.setState(({ data }) => ({
      data: data.set(key, message),
    }));

    if (!_.isUndefined(message)) {
      onError();
    }
  };

  private setAudioLoading: SetAudioLoading = isLoading => {
    const { onReady } = this.props;
    this.setState(({ data }) => ({
      data: data.set('audioLoading', isLoading),
    }));

    if (!isLoading) {
      onReady(this.buffer);
    }
  };

  private setBuffer = (buffer: AudioBuffer) => (this.buffer = buffer);

  private setRegionMillis(
    { startMillis, endMillis }: IRegion = {},
    data: RecordOf<IDataState>,
  ) {
    const {
      maxDurationMillis,
      minDurationMillis,
      onRegionUpdate,
      region,
    } = this.props;
    const { durationMillis } = data;

    const receivedStart = !_.isUndefined(startMillis);
    const receivedEnd = !_.isUndefined(endMillis);

    return data.withMutations(d => {
      if (receivedStart) {
        const boundedStart = Math.max(0, startMillis);
        const newDuration = Math.min(
          durationMillis,
          // (receivedEnd ? endMillis : d.regionEndMillis) - boundedStart,
          (receivedEnd ? endMillis : region.endMillis) - boundedStart,
        );

        if (
          newDuration >= minDurationMillis &&
          newDuration <= maxDurationMillis
        ) {
          onRegionUpdate(
            {
              endMillis: round(boundedStart + newDuration),
              startMillis: round(boundedStart),
            },
            'start-change',
          );
        }
      }

      if (receivedEnd) {
        const boundedEnd = Math.min(durationMillis, endMillis);
        const newDuration = Math.min(
          durationMillis,
          endMillis - (receivedStart ? startMillis : region.startMillis),
        );

        if (
          newDuration >= minDurationMillis &&
          newDuration <= maxDurationMillis
        ) {
          onRegionUpdate(
            {
              endMillis: round(boundedEnd),
              startMillis: round(boundedEnd - newDuration),
            },
            'end-change',
          );
        }
      }
      return d;
    });
  }

  public seekTo(millis: number, data?: RecordOf<IDataState>) {
    const mutate = (d: RecordOf<IDataState>) => {
      if (_.isUndefined(d.seekPaddingMillis)) {
        d.set('seekPaddingMillis', SEEK_PADDING_MILLIS);
        d.set('seekToMillis', millis + SEEK_PADDING_MILLIS);
      } else {
        d.delete('seekPaddingMillis');
        d.set('seekToMillis', millis);
      }
      return d;
    };

    if (data) {
      data.withMutations(mutate);
      return;
    }

    this.setState(({ data: prevData }) => ({
      data: prevData.withMutations(mutate),
    }));
  }

  private setWaveSurferReady = (ready: boolean = true) =>
    this.setState(({ data }) => ({
      data: data.set('waveSurferReady', ready),
    }));

  private createRenderProp(): Readonly<IRenderProps> {
    const { onPresetChange, region } = this.props;
    const { data } = this.state;

    return {
      data: {
        ...data.toJS(),
        region: region || {},
      },
      onPresetChange,
      pause: this.pause,
      play: this.play,
      playSelection: this.playSelection,
      seek: this.seek,
      setAudioLoading: this.setAudioLoading,
      setBuffer: this.setBuffer,
      setDuration: this.setDuration,
      setInputError: this.setInputError,
      setPlaybackPos: this.setPlaybackPos,
      setRegion: this.setRegion,
      setRegionDuration: this.setRegionDuration,
      setWaveSurferReady: this.setWaveSurferReady,
      stop: this.stop,
      zoom: this.zoom,
    };
  }

  public get data() {
    const { data } = this.state;
    return data;
  }

  public render() {
    const { render } = this.props;
    return callRender(render, this.createRenderProp());
  }
}

export { IProps as ContainerProps, IRenderProps as RenderProps };
