import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Modal';
import SocialAccountInfo from 'components/SocialAccountInfo';
import {
  onClickInstagramPostModalCTA,
  onInstagramPostModalLoad,
} from 'redux/modules/mixpanel';
import { instagramUserDataSelector } from 'redux/modules/social';
import { Dispatch } from 'redux/types';
import { StepProps } from './types';
import { block } from './utils';

const NoAccountFoundStep: React.FC<StepProps> = ({ goToStep }) => {
  const dispatch = useDispatch<Dispatch>();
  const { profileImageUrl, username } = useSelector(instagramUserDataSelector);

  const handleHyperlinkClick = useCallback((): void => {
    dispatch(onClickInstagramPostModalCTA('Hyperlink'));
  }, [dispatch]);

  const handleGoToSwitchAccountStep = useCallback((): void => {
    goToStep('switch-account');
  }, [goToStep]);

  useEffect(() => {
    dispatch(onInstagramPostModalLoad('NoAccountsExist'));
  }, [dispatch]);

  return (
    <Modal.Body className={block('no-account-found-step')}>
      <SocialAccountInfo
        avatarSrc={profileImageUrl}
        onSwitchAccountClick={handleGoToSwitchAccountStep}
        username={username}
        className={block('no-account-found-step--social')}
      />

      <p className={block('title')}>No Instagram account found</p>

      <p className={block('description')}>
        The Meta account you are currently signed in with doesn’t appear to have
        any Instagram accounts associated with it.{' '}
        <a
          href="https://www.facebook.com/help/1148909221857370"
          target="_blank"
          rel="noopener noreferrer"
          className={block('no-account-found-step--help-link')}
          onClick={handleHyperlinkClick}
        >
          Learn how to connect one here
        </a>{' '}
        or easily post from our mobile app instead! Select a device below to
        download Headliner to your phone.
      </p>
    </Modal.Body>
  );
};

export default NoAccountFoundStep;
