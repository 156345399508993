import { darkTheme, ThemeProvider } from '@sparemin/blockhead';
import React from 'react';
import useCustomFontsLoader from 'hooks/useCustomFontsLoader';

import { TextOverlayModalExtensions } from './extensions';
import TextOverlayProvider from './state/TextOverlayProvider';
import TextOverlayModalView, {
  TextOverlayModalViewProps,
} from './TextOverlayModalView';
import { TextOverlayV2 } from './types';
import { DEFAULT_TEXT } from './utils';

export interface TextOverlayModalProps extends TextOverlayModalViewProps {
  defaultText?: string;
  extensions?: TextOverlayModalExtensions;
  textOverlay?: TextOverlayV2;
}

const OVERLAY_Z_INDEX = 1050;

const TextOverlayModalContainer: React.FunctionComponent<TextOverlayModalProps> = props => {
  const {
    aspectRatio,
    defaultText = DEFAULT_TEXT,
    extensions = {},
    show,
    textOverlay,
    ...rest
  } = props;

  useCustomFontsLoader();

  return (
    <ThemeProvider
      theme={{
        ...darkTheme,
        zIndex: {
          overlay: OVERLAY_Z_INDEX,
        },
      }}
    >
      <TextOverlayProvider
        aspectRatio={aspectRatio}
        defaultText={defaultText}
        extensions={extensions}
        show={show}
        textOverlay={textOverlay}
      >
        <TextOverlayModalView aspectRatio={aspectRatio} show={show} {...rest} />
      </TextOverlayProvider>
    </ThemeProvider>
  );
};

export default TextOverlayModalContainer;
