import { useRef } from 'react';
import { shallowEquals } from 'utils/collections';
import usePrevious from './usePrevious';

export default function useKey(...deps: any[]) {
  const prevDeps = usePrevious(deps);
  const keyRef = useRef(0);

  if (!shallowEquals(deps, prevDeps)) {
    keyRef.current += 1;
  }

  return keyRef.current;
}
