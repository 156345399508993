import * as React from 'react';

import { useSelector } from 'react-redux';
import LoadingOverlay from 'components/LoadingOverlay';
import ProIndicator from 'containers/ProIndicator';
import { SubTier } from 'redux/middleware/api/plan-service/types';
import { fontLoadingText } from 'redux/modules/fonts/selectors';
import { isFreeSelector } from 'redux/modules/pricing';
import SettingsContent from '../SettingsContent';
import ContentFree from './ContentFree';
import ContentPro from './ContentPro';
import { useFontSettings } from './useFontSettings';
import { block } from './utils';

function FontSettings() {
  const {
    fontUpgrade,
    priceLoading,
    fontsLoading,
    personalFonts,
  } = useFontSettings();
  const loadingText = useSelector(fontLoadingText);
  const isFreeTier = useSelector(isFreeSelector);

  if (priceLoading || (fontsLoading && !isFreeTier)) {
    return (
      <LoadingOverlay
        className={block('loading-overlay')}
        title={isFreeTier ? 'Loading' : loadingText || 'Loading'}
      />
    );
  }

  return (
    <ProIndicator
      blacklist={[SubTier.FREE, SubTier.EDUCATION_NONPROFIT]}
      className={block('title-badge')}
    >
      {({ isFree, badge }) => (
        <SettingsContent
          subtitle="Manage custom fonts"
          title={<>My Fonts {badge}</>}
          bodyClassName={block('body', { free: isFree })}
          className={block()}
          fontsAvailable={!!personalFonts?.length && !isFree}
        >
          {isFree ? (
            <ContentFree onClickFontUpgrade={fontUpgrade} />
          ) : (
            <ContentPro />
          )}
        </SettingsContent>
      )}
    </ProIndicator>
  );
}

export default FontSettings;
