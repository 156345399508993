import React from 'react';

import * as waveforms from 'blocks/AudioWaveImage/components/Waveforms';
import { camelToPascal } from 'utils/string';

import Layer from '../../layers/Layer';
import { SoundwaveState, ToPx } from '../../types';
import { getSoundwaveLayerStyle } from './utils';

interface EditorModalSoundwaveLayerProps {
  canvasSize: { height: number; width: number };
  soundwave: SoundwaveState;
}

const EditorModalSoundwaveLayer: React.FunctionComponent<EditorModalSoundwaveLayerProps> = props => {
  const { canvasSize, soundwave } = props;

  const toPx: ToPx = React.useCallback(
    value => {
      return value?.toUnit('px', canvasSize);
    },
    [canvasSize],
  );

  const soundwaveStyle = React.useMemo(() => {
    if (!soundwave) {
      return {};
    }

    return getSoundwaveLayerStyle(soundwave, toPx);
  }, [toPx, soundwave]);

  if (!soundwave) {
    return null;
  }

  const Waveform = waveforms[camelToPascal(soundwave?.type)];

  return (
    <Layer>
      <Waveform style={soundwaveStyle} />
    </Layer>
  );
};

export default EditorModalSoundwaveLayer;
