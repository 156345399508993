import classNames from 'classnames';
import * as React from 'react';

import AspectRatioSelector, {
  AspectRatioSelectorProps,
} from 'components/AspectRatioSelector';

type Props = AspectRatioSelectorProps;

const AspectRatioStep: React.SFC<Props> = ({ className, ...selectorProps }) => (
  <div className={classNames('ar-step', 'ar-step--default', className)}>
    <AspectRatioSelector
      className={classNames('ar-step__selector')}
      {...selectorProps}
    />
  </div>
);

export default AspectRatioStep;
export { Props as AspectRatioStepProps };
