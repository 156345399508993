import cn from 'classnames';
import React from 'react';
import { block } from './utils';

export interface ListItemContentsProps
  extends Pick<React.HTMLProps<HTMLDivElement>, 'onMouseOut' | 'onMouseOver'> {
  children?: React.ReactNode;
  className?: string;
}

const ListItemContents: React.FC<ListItemContentsProps> = ({
  children,
  className,
  onMouseOut,
  onMouseOver,
}) => (
  <div
    className={cn(block('item-contents'), className)}
    {...{ onMouseOut, onMouseOver }}
  >
    {children}
  </div>
);

export default ListItemContents;
