import * as React from 'react';

import TextAsset from '../assets/TextAsset';
import { useCanvasSize } from '../context/CanvasSizeContext';
import { useChildViewState, useNavigation } from '../context/NavigationContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { canvasBlock as block } from '../utils';
import Layer from './Layer';

export interface TextLayerProps {
  layerId?: string;
}

const TextLayer: React.FC<TextLayerProps> = ({ layerId }) => {
  const [state] = useNavigation();
  const { service: childService } = useChildViewState();
  const { canvas } = useCanvasSize();
  const { textOverlays } = useTemplateState();

  return canvas.height === undefined || canvas.width === undefined ? null : (
    <Layer
      active={
        state.matches({ child: 'text' }) && childService?.state.matches('open')
      }
      className={block('text-layer')}
    >
      {textOverlays.order
        .filter(id => textOverlays.data[id].layerId === layerId)
        .map(id => (
          <TextAsset id={id} key={id} />
        ))}
    </Layer>
  );
};

export default TextLayer;
