import { useEffect, useRef, useState } from 'react';
import useStaticCallback from './useStaticCallback';

interface State {
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  error?: Error;
}

const initialState: State = {
  status: 'idle',
};

const useAsyncCallback = <T extends (...args: any[]) => Promise<any>>(
  callback: T,
): [() => Promise<void>, State] => {
  const [state, setState] = useState<State>(initialState);
  const isMountedRef = useRef(true);

  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    [],
  );

  const execute = useStaticCallback(async (...args) => {
    if (!isMountedRef.current) {
      return;
    }
    setState({ status: 'pending' });
    try {
      await callback(...args);
      if (isMountedRef.current) {
        setState({ status: 'fulfilled' });
      }
    } catch (error) {
      if (isMountedRef.current) {
        setState({ status: 'rejected', error });
      }
      throw error;
    }
  });

  return [execute, state];
};

export default useAsyncCallback;
