import * as React from 'react';

import { block } from '../utils';

interface TabHeaderProps {
  children?: React.ReactNode;
  onClickHeader: (event: React.SyntheticEvent<any>) => void;
  selectedKey: string;
}

const TemplateTabsHeader: React.SFC<TabHeaderProps> = ({ children }) => (
  <ul className={block('header')}>{children}</ul>
);

export default TemplateTabsHeader;
