import * as React from 'react';

import { block } from './utils';

export interface BillingCycleOptionLabelProps {
  children: React.ReactNode;
  cost: React.ReactNode;
}

const BillingCycleOptionLabel: React.SFC<BillingCycleOptionLabelProps> = ({
  children,
  cost,
}) => (
  <div className={block('label')}>
    <div className={block('description')}>{children}</div>
    <div className={block('cost')}>{cost}</div>
  </div>
);

export default BillingCycleOptionLabel;
