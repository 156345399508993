import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import FontAwesome from 'components/FontAwesome';
import { Help } from 'components/icons';
import Tooltip from 'components/Tooltip';
import { isWorkflowLimitReachedSelector } from 'redux/modules/automation-cms/selectors';
import { onNewAutomation } from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal/actions';
import { Dispatch } from 'redux/types';
import { autogramWizardLocation } from 'utils/routes';
import EpisodeListRow from '../components/EpisodeListRow';
import { episodeListBlock as block } from '../utils';
import PodcastFeedDataContext from './PodcastFeedData/PodcastFeedDataContext';

const { useCallback, useContext } = React;

export interface EpisodeListAutomationsRowStatusProps {
  workflowCount: number;
}

const EpisodeListAutomationsRowStatus: React.FC<EpisodeListAutomationsRowStatusProps> = ({
  workflowCount,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { podcastId } = useContext(PodcastFeedDataContext);
  const autogramWizardDescriptor = autogramWizardLocation({ podcastId });

  const handleNewAutogramClick = useCallback(
    async e => {
      e.stopPropagation();
      dispatch(onNewAutomation('Automation Row'));

      const isWorkflowLimitReached = dispatch((_, getState) =>
        isWorkflowLimitReachedSelector(getState()),
      );

      if (isWorkflowLimitReached) {
        e.preventDefault();
        dispatch(
          pushModal({
            name: 'AddAutomationUpgrade',
            params: { podcastId },
          }),
        );
      }
    },
    [dispatch, podcastId],
  );

  return (
    <EpisodeListRow.Status
      className={block('automations-status')}
      innerClassName={block('automations-status-inner')}
    >
      <Link
        className={block('autogram-button', { add: true })}
        onClick={handleNewAutogramClick}
        to={autogramWizardDescriptor}
      >
        <FontAwesome
          className={block('autogram-icon')}
          icon="plus"
          style={{ height: 16, width: 16 }}
        />
        <span className={block('autogram-button-text')}>
          {workflowCount === 0 ? 'ADD YOUR FIRST AUTOMATION' : 'NEW'}
        </span>
      </Link>
      <Tooltip content="Get help with automations" placement="top">
        <a
          className={block('autogram-button')}
          href="https://learn.headliner.app/hc/en-us/articles/360035560553-Auto-Gram-FAQ?_ga=2.140752816.343576862.1622437779-2133713685.1609271204"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Help />
        </a>
      </Tooltip>
    </EpisodeListRow.Status>
  );
};

EpisodeListAutomationsRowStatus.defaultProps = {
  workflowCount: 0,
};

export default EpisodeListAutomationsRowStatus;
