import { Button, Search } from '@sparemin/blockhead';
import React from 'react';
import { block } from './utils';

export interface AddYourPodcastProps {
  onPress: () => void;
}

const AddYourPodcast: React.FC<AddYourPodcastProps> = ({ onPress }) => {
  return (
    <div className={block('podcast-button-container')}>
      <Button
        className={block('podcast-button')}
        onPress={onPress}
        variant="ghost"
        startIcon={<Search width={12} height={12} />}
      >
        add your podcast
      </Button>
    </div>
  );
};

export default AddYourPodcast;
