import * as React from 'react';

import PlanFeatureList, { PlanFeatureListProps } from './PlanFeatureList';
import { block } from './utils';

export interface PlanDescriptionProps extends PlanFeatureListProps {
  callToAction: JSX.Element;
  headline?: string;
}

const PlanDescription: React.SFC<PlanDescriptionProps> = ({
  callToAction,
  features,
  headline,
  theme,
}) => (
  <div className={block('description')}>
    <div className={block('description-inner')}>
      {headline && (
        <div className={block('description-headline')}>{headline}</div>
      )}
      {callToAction}
      <PlanFeatureList {...{ features, theme }} />
    </div>
  </div>
);

export default PlanDescription;
