import WaveSurferJs from 'wavesurfer.js';
import MultiCanvas from 'wavesurfer.js/src/drawer.multicanvas';
import { getTranslateValues } from 'utils/dom';
import { clamp } from 'utils/numbers';
import CustomCanvasEntry from './CanvasEntry';
import { block } from './utils';

const { util } = WaveSurferJs;

/**
 * Modified version of MultiCanvas renderer for wavesurfer. It replaces the overflow based
 * scrolling with a translateX based one, which allow us to overflow the scrolling area.
 *
 * TODO:
 * * does not support progress canvas, Headliner does not utilize it,
 *   so I didn't bother adding it
 */
export default class CustomMultiCanvas extends MultiCanvas {
  // Padding offset corrector is the side padding that our current implementation uses, it
  // has 50px on each side. We should make this number customizable if in future we need to
  // use a different value for padding.
  PADDING_OFFSET_CORRECTOR = 50;

  EntryClass = CustomCanvasEntry;

  wrapper: HTMLElement & {
    domElement: HTMLElement;
  };

  // Calculates the overlap correction based on the pixel ratio, this value allows fixing the
  // overlap that is not applied because of the position of the canvas being set to static
  // this calculation is made to obtain the difference between each canvas theorical width and
  // the real one assigned.
  // - maxCanvasWidth is theorical, it gets divided by pixel ratio including the overlap
  // - when "real" canvas width is calculated theorical maxCanvasWidth + overlap is divided by pixel
  // ratio, but this value is rounded so a small different appears due to rounding. This value is
  // calculated by "roundingDelta"
  // - The other component of correction is obtained by dividing the overlap by the pixelRatio.
  // This value is calculated by "overlapDelta"
  //
  // Finally the two values obtained need to be added and multiplied by -1 as the translation needs
  // to be done to the left.
  getOverlapCorrection() {
    const roundingDelta =
      Math.round(
        (this.maxCanvasWidth + this.overlap) / this.params.pixelRatio,
      ) -
      (this.maxCanvasWidth + this.overlap) / this.params.pixelRatio;
    const overlapDelta = this.overlap / this.params.pixelRatio;
    return -(roundingDelta + overlapDelta);
  }

  createWrapper() {
    super.createWrapper();

    this.style(this.wrapper, {
      overflow: 'visible',
      display: 'inline-block',
    });
  }

  createElements() {
    super.createElements();

    this.progressWave.classList.add(block('progress'));
  }

  /**
   * Add a canvas to the canvas list
   */
  addCanvas() {
    super.addCanvas();

    const lastEntry = this.canvases[this.canvases.length - 1];

    this.style(lastEntry.wave, {
      position: 'static',
      marginLeft:
        this.canvases.length > 1 ? `${this.getOverlapCorrection()}px` : 0,
    });
  }

  resetScroll() {
    if (this.wrapper !== null) {
      this.style(this.wrapper, {
        transform: 'translateX(0)',
      });
    }
  }

  handleEvent(e: MouseEvent, noPrevent: boolean) {
    if (!noPrevent) {
      e.preventDefault();
    }

    const { clientX } = util.withOrientation(e, this.params.vertical);

    return this.getProgress(clientX);
  }

  getProgress(clientX: number) {
    const bbox = this.wrapper.getBoundingClientRect();

    const nominalWidth = this.width;
    const parentWidth = this.getWidth();

    const progressPixels = this.getProgressPixels(bbox, clientX);

    let progress: number;
    if (!this.params.fillParent && nominalWidth < parentWidth) {
      progress = progressPixels * (this.params.pixelRatio / nominalWidth) || 0;
    } else {
      progress = progressPixels / this.wrapper.offsetWidth || 0;
    }

    return util.clamp(progress, 0, 1);
  }

  recenterOnPosition(position: number, immediate: boolean) {
    const { pixelRatio } = this.params;
    const scrollLeft = this.getScrollX();
    const half = Math.floor(this.getWidth() / pixelRatio / 2);
    const maxScroll = this.getMaxScroll();
    let target = position - half;
    let offset = target - scrollLeft;

    if (maxScroll === 0) {
      // no need to continue if scrollbar is not there
      this.setScrollX(0);
      return;
    }

    // if the cursor is currently visible...
    if (!immediate && -half <= offset && offset < half) {
      // set rate at which waveform is centered
      let rate = this.params.autoCenterRate;

      // make rate depend on width of view and length of waveform
      rate /= half;
      rate *= maxScroll;

      offset = Math.max(-rate, Math.min(rate, offset));
      target = scrollLeft + offset;
    }

    // limit target to valid range (0 to maxScroll)
    target = Math.max(0, Math.min(maxScroll, target));
    // no use attempting to scroll if we're not moving

    this.setScrollX(target);
  }

  getScrollX() {
    let x = 0;
    if (this.wrapper) {
      const translate = getTranslateValues(this.wrapper.domElement);
      x = Math.round(Math.abs(translate.x));
    }
    return x;
  }

  setScrollX(scrollX: number) {
    const initialScrollX = this.getScrollX();
    const maxScroll = this.getMaxScroll();
    const clampedScrollLeft = clamp(scrollX, 0, maxScroll);

    if (clampedScrollLeft !== initialScrollX) {
      this.style(this.wrapper, {
        transform: `translateX(${-clampedScrollLeft}px)`,
      });
      this.fireEvent('scroll', clampedScrollLeft);
    }
  }

  getMaxScroll() {
    const { pixelRatio } = this.params;
    return Math.max(0, this.wrapper.clientWidth - this.getWidth() / pixelRatio);
  }

  /**
   * Tell the canvas entries to render their portion of the waveform
   * IMPORTANT: This is an enhanced version of the original wavesurfer.js MultiCanvas
   * method. It splits line drawing for setting transform and styles after the line has
   * been drawn. Then it takes advantage of the CustomCanvasEntry for filling the shape
   * after the path has been set.
   *
   * @param {number[]} peaks Peaks data
   * @param {number} absmax Maximum peak value (absolute)
   * @param {number} halfH Half the height of the waveform
   * @param {number} offsetY Offset to the top
   * @param {number} start The x-offset of the beginning of the area that
   * should be rendered
   * @param {number} end The x-offset of the end of the area that
   * should be rendered
   * @param {channelIndex} channelIndex The channel index of the line drawn
   */
  drawLine(peaks, absmax, halfH, offsetY, start, end, channelIndex) {
    this.canvases.forEach(entry => {
      entry.drawLines(peaks, absmax, halfH, offsetY, start, end);
    });

    this.paintCanvases(channelIndex);
  }

  /**
   * This is a drawing specialization for avoiding long redrawing times with long audio files/episodes.
   * It draws each of the canvases inside asyncronously. In this way the main thread is not locked while
   * drawing them and performance is smoother.
   * @param {numbel} channelIndex - Channle index for the splitted channels
   */
  private paintCanvases(channelIndex: number) {
    const { waveColor, progressColor } =
      this.params.splitChannelsOptions.channelColors[channelIndex] || {};

    this.canvases.forEach(entry => {
      setImmediate(() => {
        try {
          this.applyCanvasTransforms(entry, this.params.vertical);
          this.setFillStyles(entry, waveColor, progressColor);
          entry.waveCtx.fill();
        } catch {
          // No need to handle this error as this condition should only happen if
          // ctx no longer exists
        }
      });
    });
  }
}
