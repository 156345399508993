import * as React from 'react';

import { IconProps } from './types';

const FacebookPreset: React.FunctionComponent<IconProps> = props => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 1.286v9.428c0 .71-.576 1.286-1.286 1.286H8.43V7.254h1.624l.233-1.811H8.429V4.286c0-.525.145-.882.898-.882h.959V1.787a12.892 12.892 0 0 0-1.399-.073c-1.382 0-2.33.844-2.33 2.395v1.337H4.93v1.81h1.63V12H1.287C.576 12 0 11.424 0 10.714V1.286C0 .576.576 0 1.286 0h9.428C11.424 0 12 .576 12 1.286z"
      fill="#3B5998"
      fillRule="nonzero"
    />
  </svg>
);

export default FacebookPreset;
