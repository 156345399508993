import cn from 'classnames';
import * as React from 'react';
import uncontrollable from 'uncontrollable';
import { noop } from 'underscore';

import FadingScrollBars from 'components/FadingScrollBars';
import { Omit } from 'types';
import MenuButton, { MenuButtonProps } from '../MenuButton';
import { block } from '../utils';
import SelectOption, { SelectOptionProps } from './SelectOption';

type Option = Pick<SelectOptionProps, 'label' | 'value' | 'style'>;

type PickedMenuButtonProps = Omit<MenuButtonProps, 'onIconClick' | 'onClose'>;

interface IProps extends PickedMenuButtonProps {
  className?: string;
  dropdownClassName?: string;
  isOpen?: boolean;
  onChange?: (val: any) => void;
  onControlClick?: () => void;
  onToggleOpen?: (isOpen: boolean) => void;
  options?: Option[];
  optionClassName?: string;
  value?: any;
  footer?: React.ReactNode;
}

function SelectInput(props: IProps) {
  const {
    onControlClick,
    className,
    dropdownClassName,
    isOpen,
    onChange,
    onToggleOpen,
    optionClassName,
    options,
    value,
    footer,
    ...menuButtonProps
  } = props;

  function handleOptionClick(val: string) {
    onChange(val);
    onToggleOpen(false);
  }

  function handleMenuIconClick() {
    onControlClick();
    onToggleOpen(!isOpen);
  }

  function handleClose() {
    onToggleOpen(false);
  }

  return (
    <MenuButton
      {...menuButtonProps}
      className={cn(block('select'), className)}
      onClose={handleClose}
      onIconClick={handleMenuIconClick}
    >
      {isOpen && (
        <div className={cn(block('select-dropdown'), dropdownClassName)}>
          <FadingScrollBars className={block('select-scrollbars')}>
            {options.map(opt => (
              <SelectOption
                {...opt}
                className={optionClassName}
                key={opt.label}
                onClick={handleOptionClick}
                selected={opt.value === value}
              />
            ))}
          </FadingScrollBars>
          {footer}
        </div>
      )}
    </MenuButton>
  );
}

SelectInput.defaultProps = {
  onChange: noop,
  onControlClick: noop,
  onToggleOpen: noop,
} as Partial<IProps>;

export type SelectInputProps = IProps & {
  defaultIsOpen?: IProps['isOpen'];
  defaultValue?: IProps['value'];
};

const UncontrollableSelectInput: React.ComponentClass<SelectInputProps> = uncontrollable(
  SelectInput,
  {
    isOpen: 'onToggleOpen',
    value: 'onChange',
  },
);

export { SelectInput };
export default UncontrollableSelectInput;
