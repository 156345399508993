import React from 'react';

import Modal, { ModalProps } from 'components/Modal';
import { addTextTypeModalBlock as block } from '../../utils';
import AddTextTypeSelectionModalContents, {
  AddTextTypeSelectionModalContentsProps,
} from './AddTextTypeSelectionModalContents';

export interface AddTextTypeSelectionModalProps
  extends AddTextTypeSelectionModalContentsProps,
    Pick<ModalProps, 'onExited'> {
  onCancel?: () => void;
  show?: boolean;
}

const AddTextTypeSelectionModal: React.FC<AddTextTypeSelectionModalProps> = ({
  onCancel,
  onExited,
  onSubmit,
  show,
}) => {
  return (
    <Modal
      className={block()}
      onExited={onExited}
      onHide={onCancel}
      show={show}
      title="add text"
    >
      <AddTextTypeSelectionModalContents onSubmit={onSubmit} />
    </Modal>
  );
};

export default AddTextTypeSelectionModal;
