import { Map, Record } from 'immutable';
import { RequestStatus } from 'types';
import { State } from './types';

export const stateFactory = Record<State>({
  deleteWaveformPrefStatuses: Map<number, RequestStatus>({}),
  fetchCaptionPrefStatus: undefined,
  fetchUserPrefStatus: undefined,
  saveWaveformPrefStatus: undefined,
});
