import * as React from 'react';
import { isNumber, isString } from 'underscore';

import bem from 'utils/bem';
import SlideAttributionXField from './SlideAttributionXField';
import SlideAttributionYField from './SlideAttributionYField';
import SlideCropField from './SlideCropField';
import SlideDurationField from './SlideDurationField';
import SlideEffectField from './SlideEffectField';
import SlideEndTimeField from './SlideEndTimeField';
import SlideStartTimeField from './SlideStartTimeField';
import SlideTransitionField from './SlideTransitionField';

export const block = bem('slide-options');

const children = [
  SlideAttributionXField,
  SlideAttributionYField,
  SlideCropField,
  SlideDurationField,
  SlideEffectField,
  SlideEndTimeField,
  SlideStartTimeField,
  SlideTransitionField,
];

export function isSlideOptionsChild(
  component: React.ReactChild,
): component is React.ReactElement<any> {
  if (
    isString(component) ||
    isNumber(component) ||
    !component ||
    isString(component.type)
  ) {
    return false;
  }

  const componentType = component.type as React.ComponentType<any>;
  return children.some(child => componentType === child);
}
