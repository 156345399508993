export enum Type {
  PODCAST_SEARCH_REQUEST = 'app/podcast-search/PODCAST_SEARCH_REQUEST',
  PODCAST_SEARCH_SUCCESS = 'app/podcast-search/PODCAST_SEARCH_SUCCESS',
  PODCAST_SEARCH_FAILURE = 'app/podcast-search/PODCAST_SEARCH_FAILURE',

  PODCAST_EPISODES_GET_REQUEST = 'app/podcast-search/PODCAST_EPISODES_GET_REQUEST',
  PODCAST_EPISODES_GET_SUCCESS = 'app/podcast-search/PODCAST_EPISODES_GET_SUCCESS',
  PODCAST_EPISODES_GET_FAILURE = 'app/podcast-search/PODCAST_EPISODES_GET_FAILURE',

  PODCAST_EPISODES_CLEAR = 'app/podcast-search/PODCAST_EPISODES_CLEAR',
  PODCAST_SEARCH_RESULTS_CLEAR = 'app/podcast-search/PODCAST_SEARCH_RESULTS_CLEAR',

  PODCAST_SELECTED_PODCAST_SET = 'app/podcast-search/PODCAST_SELECTED_PODCAST_SET',

  PODCAST_SELECTED_EPISODE_SET = 'app/podcast-search/PODCAST_SELECTED_EPISODE_SET',

  PODCAST_SEARCH_SCROLL_OFFSET_SET = 'app/podcast-search/PODCAST_SEARCH_SCROLL_OFFSET_SET',

  FAVORITE_PODCASTS_GET_REQUEST = 'app/podcast-search/FAVORITE_PODCASTS_GET_REQUEST',
  FAVORITE_PODCASTS_GET_SUCCESS = 'app/podcast-search/FAVORITE_PODCASTS_GET_SUCCESS',
  FAVORITE_PODCASTS_GET_FAILURE = 'app/podcast-search/FAVORITE_PODCASTS_GET_FAILURE',

  FAVORITE_PODCAST_DELETE_REQUEST = 'app/podcast-search/FAVORITE_PODCAST_DELETE_REQUEST',
  FAVORITE_PODCAST_DELETE_SUCCESS = 'app/podcast-search/FAVORITE_PODCAST_DELETE_SUCCESS',
  FAVORITE_PODCAST_DELETE_FAILURE = 'app/podcast-search/FAVORITE_PODCAST_DELETE_FAILURE',
}
