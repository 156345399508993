import attrAccept from 'attr-accept';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { isUndefined, noop } from 'underscore';
import useOnMount from 'hooks/useOnMount';
import { ApplicationError } from 'utils/ApplicationError';

import FileUploaderDropzone, {
  FileUploaderDropzoneProps,
} from './FileUploaderDropzone';
import FileUploaderOverlay from './FileUploaderOverlay';
import { block } from './utils';

export enum RejectedReason {
  UNSUPPORTED_FILE_TYPE,
  SIZE_LIMIT_EXCEEDED,
}

type PickedProps = Pick<
  FileUploaderDropzoneProps,
  | 'accept'
  | 'title'
  | 'inProgress'
  | 'footer'
  | 'image'
  | 'supportedFileTypes'
  | 'supportedFileMaxSizeInMb'
  | 'additionalRestrictions'
  | 'templateDimensionsInfo'
  | 'uploadMethod'
  | 'onClickMediaImportOption'
>;

interface BaseProps extends PickedProps {
  /**
   * when this prop is non empty, will render children inside of the dropzone.  children take
   * priority over all other content, meaning when children is passed it will override whatever
   * is otherwise supposed to be rendered in the dropzone
   */
  children?: React.ReactNode;
  className?: string;
  maxFileSizeMb?: number;
  onFileRejected?: (
    error: ApplicationError,
    file: File,
    reason: RejectedReason,
  ) => void;
  statusMessage?: string;
  defaultFile?: File;
}

export interface SingleUploaderProps extends BaseProps {
  multiple?: false;
  onFileAccepted?: (file: File) => void;
}

export interface MultiFileUploaderProps extends BaseProps {
  multiple: true;
  onFileAccepted?: (files: File[]) => void;
}

type Props = MultiFileUploaderProps | SingleUploaderProps;

function isMultipleFileProps(props: Props): props is MultiFileUploaderProps {
  return props.multiple === true;
}

function FileUploader(props: Props) {
  const {
    accept,
    children,
    className,
    inProgress = false,
    statusMessage = 'uploading',
    title,
    footer,
    image,
    supportedFileTypes,
    supportedFileMaxSizeInMb,
    additionalRestrictions,
    templateDimensionsInfo,
    maxFileSizeMb,
    defaultFile,
    onFileAccepted = noop,
    onFileRejected = noop,
    multiple,
    uploadMethod,
    onClickMediaImportOption,
  } = props;

  const checkFileSize = useCallback(
    (file: File) => {
      if (!isUndefined(maxFileSizeMb)) {
        const size = file.size / 1024 ** 2;
        if (size > maxFileSizeMb) {
          onFileRejected(
            new ApplicationError(
              `File must be ${maxFileSizeMb}MB or smaller`,
              'IN003',
            ),
            file,
            RejectedReason.SIZE_LIMIT_EXCEEDED,
          );
          return true;
        }
      }
      return false;
    },
    [maxFileSizeMb, onFileRejected],
  );

  const handleDropAccepted = useCallback(
    (files: File[]) => {
      for (const file of files) {
        if (checkFileSize(file)) return;
      }

      const partialProps = { multiple, onFileAccepted } as Props;

      if (isMultipleFileProps(partialProps)) {
        partialProps.onFileAccepted(files);
      } else {
        partialProps.onFileAccepted(files[0]);
      }
    },
    [checkFileSize, multiple, onFileAccepted],
  );

  const handleDropRejected = (files: File[]) => {
    const [file] = files;
    onFileRejected(
      new ApplicationError('File type not supported', 'IN001'),
      file,
      RejectedReason.UNSUPPORTED_FILE_TYPE,
    );
  };

  const hasChildren = React.isValidElement(children);

  useOnMount(() => {
    if (!defaultFile) {
      return;
    }

    if (attrAccept(defaultFile, accept)) {
      handleDropAccepted([defaultFile]);
    } else {
      handleDropRejected([defaultFile]);
    }
  });

  return (
    <div
      className={cn(
        block({ 'hide-dropzone': inProgress || hasChildren }),
        className,
      )}
    >
      <FileUploaderDropzone
        accept={accept}
        onDropAccepted={handleDropAccepted}
        onDropRejected={handleDropRejected}
        title={title}
        footer={footer}
        image={image}
        supportedFileTypes={supportedFileTypes}
        supportedFileMaxSizeInMb={supportedFileMaxSizeInMb}
        additionalRestrictions={additionalRestrictions}
        templateDimensionsInfo={templateDimensionsInfo}
        multiple={multiple}
        uploadMethod={uploadMethod}
        onClickMediaImportOption={onClickMediaImportOption}
      />
      {inProgress && !hasChildren && (
        <FileUploaderOverlay message={statusMessage} />
      )}
      {hasChildren && <div className={block('custom')}>{children}</div>}
    </div>
  );
}

export default FileUploader;
export { Props as FileUploaderProps };
