import { fromJS } from 'immutable';
import { isUndefined } from 'underscore';

import { Recording } from 'redux/middleware/api/recording-service/types';
import { getKeywordsForRecordingVersion } from 'redux/modules/common/actions';
import { createEmbedConfiguration } from 'redux/modules/embed/actions/embed';
import {
  createManualTranscript,
  rechunkTranscript,
} from 'redux/modules/embed/actions/transcript';
import {
  manualTranscriptsSelector,
  recordingsSelector,
  transcriptsSelector,
} from 'redux/modules/entities/selectors';
import { postNewProject } from 'redux/modules/project/actions';
import { ThunkAction } from 'redux/types';
import { AspectRatioDimensions, AudioClipOffsets, ICaptions } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { getValue } from 'utils/collections';
import { DEFAULT_PROJECT_NAME } from 'utils/constants';
import {
  createWizardCaptions,
  DEFAULT_TEMPLATE_MAX_CHARACTERS,
  formatCaptionsForConfig,
} from 'utils/embed/captions';
import { getSlideshowFromRecommendation } from 'utils/embed/slideshow';
import { getVideoClipsFromRecommendation } from 'utils/embed/video';
import { Type } from './action-types';
import { entireAudioEnhancer } from './enhancers';
import { aspectRatioDimensionsSelector, widgetIdSelector } from './selectors';
import { CreateConfigurationSuccessAction, IAssets } from './types';
import { createConfigForEmbed } from './utils';

// FIXME dethunk
const processRecording = (
  audioClip: AudioClipOffsets,
  language: string,
): ThunkAction<ReturnType<
  ReturnType<typeof entireAudioEnhancer.actions.clipEntireAudio>
>> => dispatch =>
  dispatch(
    entireAudioEnhancer.actions.clipEntireAudio(
      audioClip.startMillis,
      audioClip.endMillis,
      language,
      undefined,
      true,
    ),
  );

const processAssets = (
  futRecording: Promise<Recording>,
  futTranscript: Promise<void>,
): ThunkAction<Promise<any>> => (dispatch, getState) => {
  const aspectRatioDimensions = aspectRatioDimensionsSelector(getState());

  return Promise.all([futRecording, futTranscript])
    .then(([recording]) => {
      const versionId = recording.get('versionId');

      return dispatch(
        getKeywordsForRecordingVersion(versionId, true, aspectRatioDimensions),
      );
    })
    .then(({ response }) => response.typedRecommendation)
    .then(rec => ({
      slideshowInfo: getSlideshowFromRecommendation(
        rec,
        fromJS(aspectRatioDimensions),
      ),
      videoClips: getVideoClipsFromRecommendation(rec, aspectRatioDimensions),
    }));
};

const processCaptions = (
  futRecordingId: Promise<string>,
  futTranscript: Promise<void>,
): ThunkAction<Promise<ICaptions>> => (
  dispatch,
  getState,
): Promise<ICaptions> => {
  const aspectRatioDimensions = aspectRatioDimensionsSelector(getState());

  const futCaptionsConfig = futRecordingId.then(id =>
    createWizardCaptions(
      getAspectRatioName(aspectRatioDimensions),
      id,
      'audio',
      undefined,
      undefined,
      true,
    ),
  );

  return futCaptionsConfig.then(captionsConfig =>
    Promise.all([futRecordingId, futTranscript])
      .then(([recordingId]) =>
        dispatch(
          processWizardTranscript(recordingId, captionsConfig.get('style')),
        ),
      )
      .then(({ transcriptId } = {} as any) => {
        const manualTranscripts = manualTranscriptsSelector(getState());
        const transcript = manualTranscripts.get(transcriptId);
        const revisionId = transcript.get('revisionId');

        return captionsConfig
          .set('transcriptId', transcriptId)
          .set('transcriptRevisionId', revisionId)
          .set('enabled', !isUndefined(transcriptId));
      })
      .then(captions => formatCaptionsForConfig(captions)),
  );
};

/**
 * NOTE: this exists in addition to `processTranscript` in common actions because that function
 *  dispatches various actions and saves the embed configuration, which is unnecessary here
 */
const processWizardTranscript = (
  recordingId,
  style,
): ThunkAction<Promise<{ transcriptId: string }>> => (dispatch, getState) => {
  const recordings = recordingsSelector(getState());
  const transcripts = transcriptsSelector(getState());

  const versionId = recordings.getIn([recordingId.toString(), 'versionId']);
  const transcript = transcripts.get(versionId.toString());

  if (!transcript || transcript.get('transcript').size <= 0) {
    return Promise.resolve(undefined);
  }

  const futManualTranscriptId = dispatch(
    createManualTranscript(transcript),
  ).then(({ response }) => {
    const transcriptId = response.result;

    return { transcriptId };
  });

  futManualTranscriptId.then(({ id }) =>
    dispatch(rechunkTranscript(DEFAULT_TEMPLATE_MAX_CHARACTERS, id, style)),
  );

  return futManualTranscriptId;
};

const createConfiguration = (
  assets: IAssets,
  captions: ICaptions,
  recording: Recording,
): ThunkAction<Promise<CreateConfigurationSuccessAction>> => dispatch => {
  dispatch({ type: Type.AUTOMATED_WIZARD_CONFIGURATION_CREATE_REQUEST });

  const config = dispatch(createConfigForEmbed(assets, captions, recording));

  return dispatch(createEmbedConfiguration(config))
    .then(({ response }) =>
      dispatch({
        payload: {
          widgetId: getValue(response, ['configuration', 'wid']),
        },
        type: Type.AUTOMATED_WIZARD_CONFIGURATION_CREATE_SUCCESS,
      }),
    )
    .catch(err => {
      dispatch({ type: Type.AUTOMATED_WIZARD_CONFIGURATION_CREATE_FAILURE });
      throw err;
    });
};

const createProject = (): ThunkAction<Promise<void>> => (
  dispatch,
  getState,
) => {
  dispatch({
    type: Type.AUTOMATED_WIZARD_PROJECT_CREATE_REQUEST,
  });

  const widgetId = widgetIdSelector(getState());
  const aspectRatioDimensions = aspectRatioDimensionsSelector(getState());

  return dispatch(
    postNewProject(
      widgetId,
      DEFAULT_PROJECT_NAME,
      aspectRatioDimensions,
      'recommended',
    ),
  )
    .then(({ response }) => {
      dispatch({
        type: Type.AUTOMATED_WIZARD_PROJECT_CREATE_SUCCESS,
        payload: {
          projectId: response.result,
        },
      });
    })
    .catch(err => {
      dispatch({ type: Type.AUTOMATED_WIZARD_PROJECT_CREATE_FAILURE });
      throw err;
    });
};

export const createAutomatedProject = (
  audioClip: AudioClipOffsets,
  aspectRatioDimensions: AspectRatioDimensions,
  language: string,
): ThunkAction<Promise<void>> => dispatch => {
  dispatch({
    payload: {
      aspectRatioDimensions,
    },
    type: Type.AUTOMATED_WIZARD_CREATE_REQUEST,
  });

  const futRecording = dispatch(processRecording(audioClip, language));
  const futRecordingId = futRecording.then((recording: Recording) =>
    recording.get('recordingId'),
  );
  const futTranscript = futRecording.then(() =>
    dispatch(entireAudioEnhancer.actions.waitForTranscript()),
  );
  const futAssets = dispatch(processAssets(futRecording, futTranscript));
  const futCaptions = dispatch(processCaptions(futRecordingId, futTranscript));

  return Promise.all([futRecording, futAssets, futCaptions])
    .then(([recording, assets = {}, captions]) =>
      dispatch(createConfiguration(assets, captions, recording)),
    )
    .then(() => dispatch(createProject()));
};

export const clearAutomatedWizard = (): ThunkAction<void> => dispatch =>
  dispatch({ type: Type.AUTOMATED_WIZARD_CLEAR });

export const {
  clearEntireAudio,
  uploadEntireAudio,
} = entireAudioEnhancer.actions;
