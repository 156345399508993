import { useState } from 'react';

const useField = () => {
  const [value, onChange] = useState<string>('');
  const [error, setError] = useState(null);

  const handleChange = (v: string) => {
    setError(null);
    onChange(v);
  };

  return {
    value,
    error,
    setError,
    onChange: handleChange,
  };
};

export default useField;
