import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { block } from '../utils';

export interface PostTaskBannerLinkProps {
  href: string;
  image: React.ReactElement;
  onClick?: () => void;
}

const PostTaskBannerLink: React.SFC<PostTaskBannerLinkProps> = ({
  href,
  image,
  onClick,
}) => (
  <a
    className={block('banner')}
    href={href}
    rel="noopener noreferrer"
    target="_blank"
    onClick={onClick}
  >
    {React.cloneElement(image, {
      className: cn(image.props.className, block('banner-image')),
    })}
  </a>
);

PostTaskBannerLink.defaultProps = {
  onClick: noop,
};

export default PostTaskBannerLink;
