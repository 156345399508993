import * as React from 'react';

import { IconProps } from './types';

const GripVertical: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 20" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <circle cx="10" cy="18" r="2" />
      <circle cx="2" cy="18" r="2" />
      <circle cx="10" cy="10" r="2" />
      <circle cx="2" cy="10" r="2" />
      <circle cx="10" cy="2" r="2" />
      <circle cx="2" cy="2" r="2" />
    </g>
  </svg>
);

export default GripVertical;
