import { List } from 'immutable';
import { RequestStatus } from 'types';
import { AppsConnectedActionTypes } from './action-types';
import { appsProviderStatusFromJs, stateFactory } from './factories';
import { AppsConnectedAction, AppsConnectedState } from './types';

const initialState = stateFactory();

const appsConnectedReducer = (
  state = initialState,
  action: AppsConnectedAction,
): AppsConnectedState => {
  switch (action.type) {
    case AppsConnectedActionTypes.APPS_CONNECTED_DISCONECT:
      return state.set('deleteStatus', RequestStatus.REQUEST);

    case AppsConnectedActionTypes.APPS_CONNECTED_DISCONECT_FAILURE:
      return state.set('deleteStatus', RequestStatus.FAILURE);

    case AppsConnectedActionTypes.APPS_CONNECTED_DISCONECT_SUCCESS: {
      const { provider } = action.payload;

      return state.withMutations(s => {
        s.set('deleteStatus', RequestStatus.SUCCESS);
        s.updateIn(['appsStatus', 'providers'], providers =>
          providers
            .map(p => (p.provider === provider ? p.set('hasAccess', false) : p))
            .toList(),
        );
        return s;
      });
    }

    case AppsConnectedActionTypes.APPS_CONNECTED_REQUEST:
      return state.withMutations(s => {
        s.setIn(['appsStatus', 'status'], RequestStatus.REQUEST);
        s.setIn(['appsStatus', 'providers'], List([]));
        return s;
      });

    case AppsConnectedActionTypes.APPS_CONNECTED_REQUEST_FAILURE:
      return state.setIn(['appsStatus', 'status'], RequestStatus.FAILURE);

    case AppsConnectedActionTypes.APPS_CONNECTED_REQUEST_SUCCESS: {
      const { appsConnected } = action.payload;

      return state.withMutations(s => {
        s.setIn(['appsStatus', 'status'], RequestStatus.SUCCESS);
        s.setIn(
          ['appsStatus', 'providers'],
          appsProviderStatusFromJs(appsConnected),
        );
        return s;
      });
    }

    default:
      return state;
  }
};

export default appsConnectedReducer;
