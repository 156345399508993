import { IApiAction, IApiResponse } from '../types';

export const ACTION_KEY = 'EMBED_SERVICE' as const;

export enum ServiceMethod {
  GET_ANIMATION_CONFIG = 'GET_ANIMATION_CONFIG',
}

export type AnimationType = 'lottie';

export type CreateEmbedPreviewUrlProps = {
  recordingId?: number;
  headlinerWatermarkEnabled?: boolean;
  token: string;
  widgetId: string;
};

export interface LottieConfiguration {
  animationData: any;
  dynamicAssets: {
    mainImage: {
      assetIdx: number;
    };
  };
  textPlaceholders: {
    podcastTitle?: string;
    episodeTitle?: string;
  };
  isLooped: boolean;
}

export interface AnimationConfiguration {
  animationName: string;
  animationType: AnimationType;
  configuration: LottieConfiguration;
}

export type GetAnimationConfigArgs = [
  string /* name */,
  string /* image url */,
];
export type GetAnimationConfigResult = AnimationConfiguration;

type ApiAction<M extends ServiceMethod, A> = IApiAction<
  typeof ACTION_KEY,
  M,
  A
>;

export type GetAnimationConfigAction = ApiAction<
  ServiceMethod.GET_ANIMATION_CONFIG,
  GetAnimationConfigArgs
>;

export interface EmbedServiceDispatch {
  (action: GetAnimationConfigAction): Promise<
    IApiResponse<GetAnimationConfigResult>
  >;
}
