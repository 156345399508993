import * as React from 'react';
import ReactToggle, { ToggleProps } from 'react-toggle';

import FormField, { FormFieldProps } from './FormField';
import { block } from './utils';

type InputProps = Pick<ToggleProps, 'checked'>;

interface IProps extends InputProps {
  onChange?: (checked: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
}

type Props = FormFieldProps<IProps>;

export default class ToggleField extends React.Component<Props> {
  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    onChange(e.target.checked, e);
  };

  public render() {
    const { checked, disabled, onChange, ...props } = this.props;

    return (
      <FormField<InputProps> {...props} disabled={disabled}>
        <div className={block('toggle-container', { disabled })}>
          <ReactToggle
            className={block('toggle-input')}
            checked={checked}
            disabled={disabled}
            icons={{
              checked: (
                <div className={block('toggle-icon', { on: true })}>On</div>
              ),
              unchecked: (
                <div className={block('toggle-icon', { off: true })}>Off</div>
              ),
            }}
            onChange={this.handleChange}
            role="switch"
          />
        </div>
      </FormField>
    );
  }
}

export { Props as ToggleFieldProps };
