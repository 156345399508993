import { Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import { block } from './utils';

export interface ModalContentWithMediaColumnProps {
  children: React.ReactNode;
  className?: string;
}

const ModalContentWithMediaColumn: React.FC<ModalContentWithMediaColumnProps> = ({
  children,
  className,
}) => {
  return (
    <Spacer
      orientation="horizontal"
      align="center"
      justify="center"
      className={cn(block('column'), className)}
    >
      {children}
    </Spacer>
  );
};

export default ModalContentWithMediaColumn;
