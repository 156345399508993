import { isString } from 'underscore';

import useObjectUrl from 'hooks/useObjectUrl';
import { WaveSurferProps } from './types';

function isUrl(val: WaveSurferProps['src']): val is string {
  return isString(val);
}

/**
 * takes a wavesurfer source and returns a url
 *
 * wavesurfer.js has different loading functions for files vs. urls.  to make
 * the integration easier, we convert files to object urls so that we don't
 * have to fork off any wavesurfer loading logic.
 */
export default function useAudioUrl(src: WaveSurferProps['src']) {
  const objectUrl = useObjectUrl(src);
  return isUrl(src) ? src : objectUrl;
}
