import cn from 'classnames';
import * as React from 'react';
import { isUndefined, noop } from 'underscore';

import LockIcon from 'components/IconWithTooltip';
import { Omit, PropsOf } from 'types';
import bem from 'utils/bem';

export type ButtonTheme =
  | 'submit'
  | 'cancel'
  | 'confirm'
  | 'confirm-alt'
  | 'orange'
  | 'orange-alt'
  | 'delete'
  | 'ghost'
  | 'ghost-bordered'
  | 'input'
  | 'rainbow'
  | 'rainbow-bordered'
  | 'submit-alt';

export type ButtonSize = 'large' | 'medium' | 'small';

export type ButtonShape = 'square' | 'round' | 'chip';

// TODO figure out if we can parameterize this to include props of componentClass
interface IButtonProps {
  children?: React.ReactNode;
  className?: string;
  componentClass?: React.ReactType;
  /**
   * used to disable the button
   */
  disabled?: boolean;
  /**
   * used to get a reference to the underlying html element
   */
  domRef?: (el: HTMLElement) => void;
  /**
   * when true, button will take up 100% width of its container
   */
  fluid?: boolean;
  /**
   * if href is passed, the button will render as an anchor element as opposed to a button element
   */
  href?: string;
  locked?: boolean;
  onClick?: (e: React.MouseEvent<any>, params?: any) => void;
  /**
   * data passed-through as the last param of callback functions.  only
   * implemented for callbacks as-needed
   */
  params?: any;
  /**
   * size of the button.  "large" has a minimum width, but "small" has no limit.
   */
  size?: ButtonSize;
  /**
   * theme determines the style of the button - e.g. colors, hover states, disabled state, etc.
   */
  theme?: ButtonTheme;
}

type IProps = IButtonProps &
  Omit<React.HTMLProps<any>, 'onClick' | 'size' | 'ref' | 'key'>;

interface IState {
  showLockTooltip: boolean;
}

const block = bem('button');

/**
 * Basic Headliner button.
 *
 * Renders a button using either a button or anchor tag.  Any additional props will be passed
 * through to the underlying component.
 */
export default class Button extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    fluid: false,
    locked: false,
    onClick: noop,
    shape: 'round',
    size: 'large',
    theme: 'cancel',
  };

  public state: Readonly<IState> = {
    showLockTooltip: false,
  };

  private handleMouseEnter = () => this.setState({ showLockTooltip: true });

  private handleMouseLeave = () => this.setState({ showLockTooltip: false });

  private getCommonProps = () => {
    const {
      children,
      className,
      componentClass,
      domRef,
      fluid,
      href,
      locked,
      onClick,
      params,
      shape,
      size,
      theme,
      ...elementProps
    } = this.props;

    return {
      ...elementProps,
      children,
      className: this.getButtonClassname(),
      onClick: (e: React.MouseEvent<any>) => onClick(e, params),
      ref: domRef,
      ...(domRef ? { ref: domRef } : undefined),
    };
  };

  private getButtonClassname = () => {
    const { className, fluid, locked, size, shape, theme } = this.props;

    return cn(
      block({
        fluid,
        locked,
        small: size === 'small',
        medium: size === 'medium',
        [shape]: true,
        [theme]: true,
      }),
      className,
    );
  };

  private renderButton = () => {
    const { componentClass: Component, href, locked } = this.props;

    const isAnchor = !isUndefined(href);

    if (locked) {
      return this.renderLockedButton();
    }

    if (Component) {
      return <Component {...(this.getCommonProps() as any)} />;
    }

    return isAnchor ? (
      <a href={href} {...(this.getCommonProps() as PropsOf<'a'>)} />
    ) : (
      <button type="button" {...(this.getCommonProps() as PropsOf<'button'>)} />
    );
  };

  private renderLockedButton = () => {
    const { children } = this.props;
    const { showLockTooltip } = this.state;

    return (
      <div
        className={this.getButtonClassname()}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <span className={block('lock-text')}>{children}</span>
        <LockIcon
          tooltipId="button-lock"
          className={block('lock-icon')}
          showTooltip={showLockTooltip}
        />
      </div>
    );
  };

  public render() {
    return this.renderButton();
  }
}

export { Button, IProps as ButtonProps };
