import cn from 'classnames';
import * as React from 'react';

import { block } from './utils';

export interface RadioIndicatorProps {
  className?: string;
  selected?: boolean;
}

const RadioIndicator: React.FC<RadioIndicatorProps> = ({
  className,
  selected,
}) => <div className={cn(block('indicator', { selected }), className)} />;

RadioIndicator.defaultProps = {
  selected: false,
};

export default RadioIndicator;
