import cn from 'classnames';
import React from 'react';
import { noop } from 'underscore';
import { PlanInstanceProps } from 'blocks/Pricing/types';
import { MultiVideoCamera } from 'components/icons';
import { PlanName } from 'redux/middleware/api/plan-service';

import { proFeatureList } from '../features';
import Plan from '../Plan';
import ProPlanCta from './ProPlanCta';
import { block } from './utils';

const ProPlan: React.FC<PlanInstanceProps> = ({
  className,
  cta: ctaOverride,
  onUpgradeClick,
  period,
}) => (
  <Plan className={cn(block(), className)}>
    <Plan.SubPeriodHeader
      className={block('header')}
      cost={{
        monthly: PlanName.PRO_20211112_MONTHLY,
        yearly: PlanName.PRO_20211112_YEARLY,
      }}
      description="Completely automate the promotion of any podcast."
      icon={<MultiVideoCamera />}
      period={period}
      theme="pro"
      title="Pro"
    />
    <Plan.Description
      callToAction={
        <ProPlanCta
          ctaOverride={ctaOverride}
          onClick={onUpgradeClick}
          subscriptionPeriod={period}
        />
      }
      features={proFeatureList}
    />
  </Plan>
);

ProPlan.defaultProps = {
  onUpgradeClick: noop,
};

export default ProPlan;
