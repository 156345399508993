import { CaptionFormat } from 'types';
import { CaptionFormatOption } from './types';

export const CAPTIONS_FORMAT_OPTIONS: CaptionFormatOption[] = [
  { value: CaptionFormat.VTT, label: 'VTT' },
  { value: CaptionFormat.SRT, label: 'SRT' },
  { value: CaptionFormat.PDF, label: 'PDF' },
  { value: CaptionFormat.TXT, label: 'TXT' },
  { value: CaptionFormat.DOC, label: 'DOC' },
];
