import React from 'react';

import { block } from './utils';

interface TextShadowControlProps {
  children: React.ReactNode;
  icon: React.ReactNode;
  label: string;
  layout: 'horizontal' | 'vertical';
}

const TextShadowControl: React.FunctionComponent<TextShadowControlProps> = props => {
  const { children, icon, label, layout } = props;

  return (
    <div className={block('control-row')}>
      <div className={block('icon-wrapper')}>{icon}</div>
      <div className={block('control-container', { [layout]: true })}>
        <h5 className={block('control-label')}>{label}</h5>
        {children}
      </div>
    </div>
  );
};

export default TextShadowControl;
