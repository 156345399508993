import cn from 'classnames';
import * as React from 'react';

import { cardWizardBlock as block } from '../../../utils';

export interface CardWizardHeadingProps {
  children?: React.ReactNode;
  className?: string;
  level: 1 | 2;
}

const CardWizardHeading: React.FC<CardWizardHeadingProps> = ({
  children,
  className,
  level,
}) => (
  <span
    className={cn(block('heading', { [`level-${level}`]: true }), className)}
  >
    {children}
  </span>
);

export default CardWizardHeading;
