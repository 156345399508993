import { isEmpty } from 'underscore';
import { Measurement, ViewportWidth } from 'utils/measurement';
import { createTextShadowString } from 'utils/ui';

import { CaptionsState, ToPx } from '../types';
import { AssetStyle } from './types';

export const getCaptionStyle = (
  captionsState: CaptionsState,
  toPx: ToPx,
): AssetStyle => {
  if (!captionsState || isEmpty(captionsState)) {
    return undefined;
  }

  const { containerStyle } = captionsState;

  const toPxValue = (m: Measurement) => toPx(m).value;

  const textShadow = containerStyle?.textShadow;
  const textShadowStyle =
    typeof textShadow === 'string'
      ? textShadow
      : createTextShadowString(containerStyle?.textShadow);

  return {
    ...containerStyle,
    width: captionsState.size?.width,
    height: captionsState.size?.height,
    left: captionsState.position?.left,
    top: captionsState.position?.top,
    paddingLeft: toPxValue(
      new ViewportWidth(parseFloat(containerStyle?.paddingLeft)),
    ),
    paddingTop: toPxValue(
      new ViewportWidth(parseFloat(containerStyle?.paddingTop)),
    ),
    paddingRight: toPxValue(
      new ViewportWidth(parseFloat(containerStyle?.paddingRight)),
    ),
    paddingBottom: toPxValue(
      new ViewportWidth(parseFloat(containerStyle?.paddingBottom)),
    ),
    position: captionsState.region?.type as React.CSSProperties['position'],
    textShadow: textShadowStyle,
    fontSize: toPxValue(
      new ViewportWidth(parseFloat(containerStyle?.fontSize)),
    ),
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  };
};
