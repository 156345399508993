import * as React from 'react';

import { ChevronCircleLeft, ChevronCircleRight } from 'components/icons';
import { block } from './utils';

export interface ArrowProps {
  className?: string;
  direction: 'prev' | 'next';
  onClick?: () => void;
}

const Arrow: React.FC<ArrowProps> = ({ className, direction, onClick }) => (
  <div className={className} onClick={onClick}>
    {direction === 'prev' ? (
      <ChevronCircleLeft className={block('arrow-icon')} />
    ) : (
      <ChevronCircleRight className={block('arrow-icon')} />
    )}
  </div>
);

export default Arrow;
