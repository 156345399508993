import { AudioSelectorMethod } from 'redux/middleware/api/headliner-user-service';
import bem from 'utils/bem';

export const block = bem('add-audio-modal');

export const maxFileSizeMb = 500;

export const AUDIO_SELECTOR_TO_TAB_KEY: Record<
  AudioSelectorMethod | 'sample',
  number
> = {
  upload: 1,
  podcastSearch: 2,
  sample: 3,
};
