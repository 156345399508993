import cn from 'classnames';
import * as React from 'react';

import { gridBlock as block } from './utils';

export interface TileGridProps {
  children?: React.ReactNode;
  className?: string;
}

const TileGrid: React.FC<TileGridProps> = ({ children, className }) => (
  <div className={cn(block(), className)}>
    {React.Children.map(children, child =>
      !React.isValidElement(child)
        ? null
        : React.cloneElement(child, {
            className: cn(block('tile'), child.props.className),
          }),
    )}
  </div>
);

export default TileGrid;
