import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import FontAwesome from 'components/FontAwesome';
import { ProBadge } from 'components/icons';
import { RefType } from 'types';
import Tile from '../Tile';
import { optionTileBlock as block } from '../utils';
import BrandingBanner from './BrandingBanner';

export type OptionTileType =
  | 'default'
  | 'coming-soon'
  | 'not-available'
  | 'locked';

const DISABLED_OPTION_TYPES: OptionTileType[] = [
  'coming-soon',
  'not-available',
  'locked',
];

export interface OptionTileIconProps {
  className: string;
}

export interface OptionTileProps<P = any> {
  active?: boolean;
  className?: string;
  disabled?: boolean;
  icon: React.ReactElement<OptionTileIconProps>;
  id: string;
  integratorBrand?: 'acast';
  label: string;
  onClick?: (params: P, event: React.MouseEvent<HTMLButtonElement>) => void;
  params?: P;
  showProLabel?: boolean;
  type?: OptionTileType;
}

const TOOLTIP_MESSAGES: { [key in OptionTileType]?: string } = {
  'coming-soon': 'This feature is coming soon',
  locked: 'Your administrator has locked this option',
  'not-available': 'Not available for this template',
};

const OptionTile = React.forwardRef(
  <P extends {} = any>(
    {
      active,
      className,
      disabled,
      icon,
      id,
      integratorBrand,
      label,
      onClick = noop,
      params,
      showProLabel,
      type = 'default',
    }: OptionTileProps<P>,
    ref: RefType<typeof Tile>,
  ) => {
    const comingSoon = type === 'coming-soon';
    const locked = type === 'locked';
    const isDisabled = disabled || DISABLED_OPTION_TYPES.some(t => t === type);

    return (
      <Tile
        {...{ active, id, ref, onClick, params }}
        className={cn(
          block({
            active,
            'coming-soon': comingSoon,
            'option-tile': true,
          }),
          className,
        )}
        disabled={isDisabled}
        tooltip={TOOLTIP_MESSAGES[type]}
      >
        {showProLabel && (
          <div className={block('pro-gate')}>
            <ProBadge />
          </div>
        )}
        {comingSoon && <div className={block('banner')}>Coming soon</div>}
        {locked && (
          <div className={block('locked')}>
            <FontAwesome icon="lock" style={{ height: 'auto', width: 12 }} />
          </div>
        )}
        <div
          className={block('body', {
            branded: !!integratorBrand,
            'branded-acast': integratorBrand === 'acast',
            'pro-gate': showProLabel,
          })}
        >
          <BrandingBanner integratorBrand={integratorBrand} />
          {React.cloneElement(icon, { className: block('icon') })}
          <span className={block('label')}>{label}</span>
        </div>
      </Tile>
    );
  },
);

export default OptionTile;
