import React from 'react';

import { EditorDataV2, Size } from '../../types';
import animationModules from './animations';
import { AnimationsTab, AnimationsTabConfig } from './types';

interface UseBoxSizeFitCheckerConfig {
  canvasSize: Size;
  draftEditorData: EditorDataV2;
  extensionConfig: AnimationsTabConfig;
}

type UseAnimationsTab = AnimationsTab;

const DEFAULT_ANIMATIONS = [];

const useAnimationsTab = (
  config: UseBoxSizeFitCheckerConfig,
): UseAnimationsTab => {
  const { extensionConfig = {} } = config;

  const { animations = DEFAULT_ANIMATIONS, enabled = false } = extensionConfig;

  const targetAnimationModules = React.useMemo(() => {
    return animations.map(animation => animationModules[animation]);
  }, [animations]);

  return {
    animationModules: targetAnimationModules,
    enabled,
  };
};

export default useAnimationsTab;
