import * as React from 'react';

import { Font } from 'components/icons';
import AddTextButton from './AddTextButton';
import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import { useModalManager } from './ModalManager';
import OptionChildView, { OptionChildViewProps } from './OptionChildView';
import TextAssetTile from './TextAssetTile';
import useAddTextModal from './useAddTextModal';
import useEditTextModal from './useEditTextModal';
import { textViewBlock as block } from './utils';

const { useCallback } = React;

type PickedViewProps = Pick<OptionChildViewProps, 'active' | 'onClose'>;

export interface TextChildViewProps extends PickedViewProps {}

const TextChildView: React.FC<TextChildViewProps> = ({ active, onClose }) => {
  const state = useTemplateState();
  const { showModal } = useModalManager();
  const dispatch = useTemplateDispatch();
  const showAddTextModal = useAddTextModal();
  const showEditTextModal = useEditTextModal();

  const {
    episodeTitle,
    isComplex,
    podcastTitle,
    slideEffectText,
    textOverlays,
  } = state;

  const handleEditText = useCallback(
    (id: string) => {
      showEditTextModal(id);
    },
    [showEditTextModal],
  );

  const handleEditEffectText = useCallback(
    (id: string) => {
      showModal('edit-effect-text', {
        id,
        onSubmit: (text: string) => {
          dispatch({
            payload: { id, text },
            type: 'IMAGE_EFFECT_TEXT_UPDATE',
          });
        },
      });
    },
    [showModal, dispatch],
  );

  return (
    <OptionChildView
      className={block()}
      bodyClassName={block('body')}
      icon={<Font />}
      title="text"
      {...{ active, onClose }}
    >
      {Object.keys(slideEffectText).map(id => (
        <TextAssetTile key={id} onClick={handleEditEffectText} params={id}>
          {slideEffectText[id].text}
        </TextAssetTile>
      ))}
      {!isComplex && (
        <>
          {textOverlays.order
            .filter(
              id =>
                id === episodeTitle?.id ||
                id === podcastTitle?.id ||
                textOverlays.data[id],
            )
            .map(id => (
              <TextAssetTile key={id} onClick={handleEditText} params={id}>
                {textOverlays.data[id].text}
              </TextAssetTile>
            ))}
        </>
      )}
      {!isComplex && <AddTextButton onClick={showAddTextModal} />}
    </OptionChildView>
  );
};

export default TextChildView;
