import * as React from 'react';
import { isUndefined, noop } from 'underscore';

import VideoEditorPlaybackTimeContext from 'containers/VideoEditor/VideoEditorPlaybackTimeContext';
import { ADD_ASSET_BUTTON_WIDTH, GUTTER_PX } from '../constants';
import AddAssetButton from '../containers/AddAssetButton';
import AudioTrack from '../containers/AudioTrack';
import MediaTrack from '../containers/MediaTrack';
import TextTrack from '../containers/TextTrack';
import WaveformTrack from '../containers/WaveformTrack';
import TimelineContext from '../TimelineContext';
import { block, millisToPosition } from '../utils';
import { TrackProps } from './Track';

const { useCallback, useContext, useRef, useState } = React;

export interface TracksProps {
  disabled?: boolean;
  onTrackMount?: TrackProps['onMount'];
  onTrackUnmount?: TrackProps['onUnmount'];
  pxPerSec: number;
  showButton?: boolean;
}

interface ButtonState {
  assetButtonTop: number;
  hoveredTrackId: string;
}

const Tracks = React.memo<TracksProps>(
  ({
    disabled,
    onTrackMount,
    onTrackUnmount = noop,
    pxPerSec,
    showButton = false,
  }) => {
    const taskIdRef = useRef<number>();

    const { tracks } = useContext(TimelineContext);
    const { positionSec } = useContext(VideoEditorPlaybackTimeContext);

    const [{ assetButtonTop, hoveredTrackId }, setButtonState] = useState<
      ButtonState
    >({
      assetButtonTop: undefined,
      hoveredTrackId: undefined,
    });

    const setButtonPosition = useCallback((id: string, el: HTMLElement) => {
      const trackTop = el.offsetTop;
      const trackHeight = el.getBoundingClientRect().height;
      const buttonHeight = ADD_ASSET_BUTTON_WIDTH;

      setButtonState({
        assetButtonTop: trackTop + (trackHeight - buttonHeight) / 2,
        hoveredTrackId: id,
      });
    }, []);

    const handleTrackMount = useCallback(
      (id: string, el: HTMLElement) => {
        const firstTrack = tracks[0];
        if (id === firstTrack.id) {
          setButtonPosition(id, el);
        }

        onTrackMount(id, el);
      },
      [tracks, setButtonPosition, onTrackMount],
    );

    const handleTrackMouseEnter = useCallback(
      (id: string, el: HTMLElement) => {
        taskIdRef.current = window.setTimeout(
          () => setButtonPosition(id, el),
          250,
        );
      },
      [setButtonPosition],
    );

    const handleTrackMouseLeave = useCallback(() => {
      if (taskIdRef.current) {
        window.clearTimeout(taskIdRef.current);
      }
    }, []);

    const buttonX =
      millisToPosition(positionSec * 1000, pxPerSec) + GUTTER_PX + 3;
    const buttonY = assetButtonTop || 0;

    return (
      <div
        className={block('tracks', { disabled })}
        style={{ marginLeft: -GUTTER_PX }}
      >
        <div>
          {!isUndefined(assetButtonTop) && showButton && (
            <AddAssetButton
              style={{
                height: ADD_ASSET_BUTTON_WIDTH,
                transform: `translate(${buttonX}px, ${buttonY}px)`,
                width: ADD_ASSET_BUTTON_WIDTH,
              }}
              trackId={hoveredTrackId}
            />
          )}
          {tracks.map(({ id, type }) => {
            const props = {
              id,
              pxPerSec,
              onMount: handleTrackMount,
              onMouseEnter: handleTrackMouseEnter,
              onMouseLeave: handleTrackMouseLeave,
              onUnmount: onTrackUnmount,
              key: id,
            };
            if (type === 'text') {
              return <TextTrack {...props} />;
            }

            if (type === 'media') {
              return <MediaTrack {...props} />;
            }

            if (type === 'audio') {
              return <AudioTrack {...props} />;
            }

            if (type === 'waveform') {
              return <WaveformTrack {...props} />;
            }

            return null;
          })}
        </div>
      </div>
    );
  },
);

Tracks.displayName = 'Tracks';

export default Tracks;
