import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Flipped } from 'react-flip-toolkit';
import { sample } from 'underscore';
import { Emoji } from 'components/icons';
import ProgressList from 'components/ProgressList';

interface Props {
  loading: boolean;
  offsetInDegrees: number;
}

type Variation = Omit<
  React.ComponentProps<typeof ProgressList.Item>,
  'loading'
>;

const variations: Variation[] = [
  {
    color: 'rainbow',
    icon: <FontAwesomeIcon icon="flask" />,
    children: 'adding our secret potion',
  },
  {
    color: 'rainbow',
    icon: <Emoji fill="currentColor" width={20} />,
    children: 'adding our good vibes',
  },
  {
    color: 'rainbow',
    icon: <FontAwesomeIcon icon="magic" />,
    children: 'adding our magic touch',
  },
];

export const FakeProgressItem: React.FC<Props> = props => {
  const { loading, offsetInDegrees } = props;
  const variationProps = React.useMemo(() => sample<Variation>(variations), []);
  return (
    <Flipped
      flipId="list-item-fake"
      stagger
      translate={false}
      scale={false}
      opacity
    >
      <ProgressList.Item
        {...variationProps}
        {...{ loading, offsetInDegrees }}
      />
    </Flipped>
  );
};

export default FakeProgressItem;
