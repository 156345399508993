import {
  createSubscriptionTypes,
  PodcastSubscription,
  PodcastSubscriptionType,
  SubscriptionItem,
} from 'redux/middleware/api/podcast-service';
import {
  AudioSelectionType,
  DurationOption,
  PartialUpdateAutomationData,
} from 'redux/modules/edit-automation';
import { AutogramClipSelectionMethod, VideoTypes } from 'types';

import bem from 'utils/bem';
import { int } from 'utils/numbers';

import { DurationAsText, getSubscriptionItem } from '../utils';

export const editAutomationAudioSelectionLengthBlock = bem(
  'edit-automation-audio-selection-length',
);
export const editAutomationAudioSelctionTypeBlock = bem(
  'edit-automation-audio-selection-type',
);

/**
 * maps audio selection to a custom key
 * @param subscriptionItem (optional) autogram subscription data
 * @returns custom audio selection key
 */
export const getAudioSelectionType = (
  subscriptionItem?: SubscriptionItem,
): AudioSelectionType | undefined => {
  if (!subscriptionItem) {
    return undefined;
  }

  if (subscriptionItem.videoType === VideoTypes.FULL_EPISODE) {
    return 'ENTIRE_EPISODE';
  }

  if (
    subscriptionItem.clipSelectionMethod ===
    AutogramClipSelectionMethod.SOUNDBITE_TAG
  ) {
    return 'SOUNDBITE';
  }

  return 'HEADLINER_AI';
};

/**
 * maps audio selection text for audio selection card description
 * @param subscriptionItem (optional) autogram subscription data
 * @returns audio selection mapped text
 */
export const getAudioSelectionText = (
  subscriptionItem?: SubscriptionItem,
): string => {
  const audioSelectionType = getAudioSelectionType(subscriptionItem);

  if (!audioSelectionType || !subscriptionItem) {
    return '';
  }

  if (audioSelectionType === 'ENTIRE_EPISODE') {
    return 'Entire episode';
  }

  if (audioSelectionType === 'SOUNDBITE') {
    return 'Soundbite tag';
  }

  return `Headliner AI: ${
    'videoLengthSecPref' in subscriptionItem
      ? DurationAsText[subscriptionItem.videoLengthSecPref]
      : ''
  }`;
};

/**
 * Maps selection key element to the correct clipSelectionMethod attribute
 * for being sent to the backend.
 * @param selectedKey UI format selected audio key (headliner_ai, soubdbyte or entire episode)
 * @returns mapped AutogramClipSelectionMethod value or null if it does not apply
 */
const getClipSelectionMethod = (
  selectedKey: 'SOUNDBITE' | 'HEADLINER_AI',
): AutogramClipSelectionMethod => {
  switch (selectedKey) {
    case 'SOUNDBITE':
      return AutogramClipSelectionMethod.SOUNDBITE_TAG;
    case 'HEADLINER_AI':
    default:
      return AutogramClipSelectionMethod.SMART;
  }
};

/**
 * Maps audio selection data to the correct subscriptionType object for being sent to
 * the backend
 * @param selectedKey UI format selected audio key (headliner_ai, soubdbyte or entire episode)
 * @param selectedDuration UI format selected audio duration key
 * @param subscriptionItem pre-update subscription item
 * @returns mapped AutogramSubscriptionType object for being sent over the update request
 */
const mapAudioSelectionSubscriptionTypes = (
  selectedKey: AudioSelectionType,
  selectedDuration: DurationOption,
  subscriptionItem: SubscriptionItem,
): PodcastSubscriptionType[] => {
  if (selectedKey === 'ENTIRE_EPISODE') {
    return createSubscriptionTypes(subscriptionItem, {
      videoType: VideoTypes.FULL_EPISODE,
    });
  }

  return createSubscriptionTypes(subscriptionItem, {
    videoType: VideoTypes.RANDOM_CLIP,
    videoLengthSec: int(selectedDuration),
    clipSelectionMethod: getClipSelectionMethod(selectedKey),
  });
};

/**
 * Maps UI selection and merges it with the changes made by the user for
 * generating the date to be sent with the update subscription request
 * @param data pre-update subscription data and current selected duration/key for audio selection
 * @returns mapped UpdateSubscriptionData for being sent with the update request.
 */
export const mapAudioSelectionUpdateData = (data: {
  selectedAudioKey: AudioSelectionType;
  selectedDuration?: DurationOption;
  subscription: PodcastSubscription;
}): PartialUpdateAutomationData => {
  const { selectedAudioKey, selectedDuration, subscription } = data;

  const { autoCreateSubscriptionId } = subscription;
  const subscriptionItem = getSubscriptionItem(subscription);

  return {
    subscriptionId: autoCreateSubscriptionId,
    subscriptionTypes: mapAudioSelectionSubscriptionTypes(
      selectedAudioKey,
      selectedDuration,
      subscriptionItem,
    ),
  };
};
