import { LocationDescriptor } from 'history';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'underscore';
import CardCta from 'components/CardCta/CardCta';
import CutCardCta from 'components/CardCta/CutCardCta';
import { H1, H2 } from 'components/Heading';
import { WaveIcon } from 'components/icons';
import LoadingOverlay from 'components/LoadingOverlay';
import ModalContentWithMedia from 'components/Modal/ModalContentWithMedia';
import {
  EditorState,
  VideoTemplateEditorContextProvider,
} from 'components/VideoTemplateEditor';
import PreviewPane from 'components/VideoTemplateEditor/PreviewPane';
import {
  onOpenTemplateInEditor,
  onOpenTemplateWizard,
} from 'redux/modules/mixpanel/actions';
import { hideModal } from 'redux/modules/modal/actions';
import {
  openTemplateInEditorLocation,
  templateWizardLocation,
} from 'utils/routes';
import usePodcastTemplate from './usePodcastTemplate';
import { block } from './utils';

export interface OpenTemplateModalContentsProps {
  onRedirect?: (location: LocationDescriptor) => void;
  templateId?: string;
}

const createEditorState = template => {
  if (!template) return undefined;

  return EditorState.createStateFromTemplate(template);
};

const OpenTemplateModalContents: React.FC<OpenTemplateModalContentsProps> = props => {
  const { onRedirect = noop, templateId } = props;

  const dispatch = useDispatch();

  const {
    displayName,
    fetchTemplateStatus,
    podcastTemplate,
  } = usePodcastTemplate({
    templateId,
  });

  const templateName = podcastTemplate?.name;
  const templateType = podcastTemplate?.templateType;

  const isLoading =
    fetchTemplateStatus === 'idle' || fetchTemplateStatus === 'pending';

  const [editorState, setEditorState] = useState(() =>
    createEditorState(podcastTemplate),
  );

  useEffect(() => {
    if (podcastTemplate && !editorState) {
      setEditorState(createEditorState(podcastTemplate));
    }
  }, [editorState, podcastTemplate]);

  const handleChange = useCallback(stateUpdater => {
    setEditorState(prevState => stateUpdater(prevState));
  }, []);

  const handleHide = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  const handleOpenInEditor = useCallback(() => {
    handleHide();
    dispatch(onOpenTemplateInEditor());
    onRedirect(openTemplateInEditorLocation(templateId, templateType));
  }, [dispatch, handleHide, onRedirect, templateId, templateType]);

  const handleOpenInWizard = useCallback(() => {
    handleHide();
    dispatch(onOpenTemplateWizard(templateId, templateName));
    onRedirect(templateWizardLocation(templateId, templateType));
  }, [
    dispatch,
    handleHide,
    onRedirect,
    templateId,
    templateName,
    templateType,
  ]);

  return (
    <>
      {isLoading && <LoadingOverlay title="Loading" />}
      <ModalContentWithMedia onClose={handleHide}>
        <ModalContentWithMedia.ModalContentWithMediaColumn>
          <div className={block('preview-column')}>
            {editorState && (
              <VideoTemplateEditorContextProvider
                disabled
                onChange={handleChange}
                state={editorState}
              >
                <PreviewPane className={block('preview')} />
              </VideoTemplateEditorContextProvider>
            )}
          </div>
        </ModalContentWithMedia.ModalContentWithMediaColumn>

        <ModalContentWithMedia.ModalContentWithMediaColumn>
          <div className={block('cta-content')}>
            <div className={block('cta-heading')}>
              <H1 className={block('cta-title')}>{displayName}</H1>
              <H2>How would you like to use this template?</H2>
            </div>
            <div>
              <CardCta
                variant="purple"
                description={
                  <span>
                    Simply select an audio clip and <br />
                    get a shareable video in no time.
                  </span>
                }
                icon={<WaveIcon />}
                label="Quickly add audio"
                onClick={handleOpenInWizard}
              />
              <CutCardCta
                description={
                  <span>
                    Edit captions, add transitions, <br />
                    multiple tracks, watermarks, & much more.
                  </span>
                }
                label="Open advanced editor"
                onClick={handleOpenInEditor}
              />
            </div>
          </div>
        </ModalContentWithMedia.ModalContentWithMediaColumn>
      </ModalContentWithMedia>
    </>
  );
};

export default OpenTemplateModalContents;
