import { Button, Spacer } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';

import AutomationPostModalBackButton from 'components/AutomationPostModalBackButton';
import DynamicElementsPopper from 'components/DynamicElementsPopper';
import EducationalCard from 'components/EducationalCard/EducationalCard';
import ModalContentWithMedia from 'components/Modal/ModalContentWithMedia';
import { Step } from 'components/SteppedModal';
import { block, videoTitleMetadata } from '../../utils';
import YoutubeVideoTitleInput from '../../YoutubeVideoTitleInput';

interface UseVideoTitleStepProps {
  title: string;
  isActive: boolean;
  readonly?: boolean;
  hideProgress: boolean;
  onBackClick: () => void;
  onTitleChange: (title: string) => void;
  onHideModal: () => void;
}

export default function useVideoTitleStep({
  title,
  isActive,
  onTitleChange,
  readonly,
  hideProgress,
  onBackClick,
  onHideModal,
}: UseVideoTitleStepProps): Step {
  const [defaultTitle, setDefaultTitle] = useState(title);

  const handleBackClick = useCallback((): void => {
    onTitleChange(defaultTitle);
    onBackClick();
  }, [defaultTitle, onBackClick, onTitleChange]);

  if (!isActive && title !== defaultTitle) {
    setDefaultTitle(title);
  }

  return {
    component: (
      <>
        <ModalContentWithMedia
          onClose={onHideModal}
          className="composer-step-content"
        >
          <ModalContentWithMedia.ModalContentWithMediaColumn>
            <img
              src="https://static.sparemin.com/static/webapp-assets/auto-posting-detail/auto-posting-detail-image-b-202303301450.png"
              className={block('composer-image-left')}
            />
          </ModalContentWithMedia.ModalContentWithMediaColumn>

          <ModalContentWithMedia.ModalContentWithMediaColumn className="composer-content-column">
            <Spacer
              orientation="vertical"
              align="center"
              justify="center"
              space={2.5}
              className={block('step-container')}
            >
              <AutomationPostModalBackButton
                text="Video titles"
                description="Format how titles post with each video. You can add up to 100 characters."
                onClick={handleBackClick}
              />

              <YoutubeVideoTitleInput
                onChange={onTitleChange}
                readonly={readonly}
                value={title}
                placeholder="Add title"
                showLabel={false}
                hideProgress={hideProgress}
              />

              <EducationalCard>
                <DynamicElementsPopper
                  buttonContent="add content from your rss feed"
                  elementsList={videoTitleMetadata}
                  onClick={metaValue => onTitleChange(`${title} ${metaValue}`)}
                />
              </EducationalCard>

              <Button
                onClick={onBackClick}
                variant="solid"
                className={block('composer-step-submit-button')}
              >
                save changes
              </Button>
            </Spacer>
          </ModalContentWithMedia.ModalContentWithMediaColumn>
        </ModalContentWithMedia>
      </>
    ),
    id: 'video-title-step',
    hideFooter: true,
    replaceBody: true,
  };
}
