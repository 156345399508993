import * as React from 'react';

import Button from 'components/Button';
import PlanAction from '../../PlanAction';
import { block } from '../utils';

export interface ProPlanCtaProps {
  children?: React.ReactNode;
  ctaOverride?: React.ReactNode;
  onClick?: () => void;
}

const ProPlanCta: React.FC<ProPlanCtaProps> = ({
  children,
  ctaOverride,
  onClick,
}) => (
  <PlanAction className={block('cta')} onClick={onClick}>
    {ctaOverride ?? (
      <Button theme="submit">
        <span className={block('cta-text')}>{children}</span>
      </Button>
    )}
  </PlanAction>
);

export default ProPlanCta;
