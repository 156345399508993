import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import { H1, H2 } from 'components/Heading';
import SourceStep from '../SourceStep/SourceStep';
import { SourceStepType } from '../SourceStep/types';
import { episodeWizardSourceStep as block } from './utils';

interface EpisodeWizardSourceStepProps {
  onClick: (selectedSourceStepType: SourceStepType) => void;
}

const EpisodeWizardSourceStep: React.FC<EpisodeWizardSourceStepProps> = ({
  onClick,
}) => {
  return (
    <Spacer
      orientation="vertical"
      align="center"
      justify="space-between"
      space={2.5}
      className={block()}
    >
      <H1>What should we create from?</H1>
      <H2>
        Select a source that you would like to turn into full-length audiograms.
      </H2>

      <SourceStep onClick={onClick} />
    </Spacer>
  );
};

export default EpisodeWizardSourceStep;
