import * as React from 'react';

import { useDispatch } from 'react-redux';
import { PaidSubscriptionButton } from 'blocks/Pricing/components/PaidSubscription';
import Button from 'components/Button';
import { H2, H3 } from 'components/Heading';
import { Exclamation } from 'components/icons';
import { onClickUnpauseMyPlan } from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal/actions';
import BillingHistoryLink from '../BillingHistoryLink';
import { block } from './utils';

export interface PausedSubscriptionInfoProps {
  title: string;
  subtitle: string;
}

const PausedSubscriptionInfo: React.FC<PausedSubscriptionInfoProps> = props => {
  const dispatch = useDispatch();
  const { title, subtitle } = props;

  const handleUnpauseClick = () => {
    dispatch(onClickUnpauseMyPlan());
    dispatch(
      replaceModal({
        name: 'UnpauseSubscription',
      }),
    );
  };
  const handleCancelPlanClick = () => {
    dispatch(
      replaceModal({
        name: 'CancelReason',
      }),
    );
  };

  return (
    <div className={block()}>
      <div className={block('header')}>
        <Exclamation />
        <div className={block('titles')}>
          <H2>{title}</H2>
          <H3>{subtitle}</H3>
        </div>
      </div>

      <div className={block('buttons')}>
        <Button theme="rainbow" fluid onClick={handleUnpauseClick}>
          Unpause my plan
        </Button>

        <BillingHistoryLink>
          {linkProps => (
            <PaidSubscriptionButton theme="submit" {...linkProps}>
              View billing history
            </PaidSubscriptionButton>
          )}
        </BillingHistoryLink>

        <Button theme="submit-alt" fluid onClick={handleCancelPlanClick}>
          Cancel my plan
        </Button>
      </div>
    </div>
  );
};

export default PausedSubscriptionInfo;
