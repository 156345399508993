import cn from 'classnames';
import * as React from 'react';

import Button, { ButtonProps } from 'components/Button';
import bem from 'utils/bem';

type PickedButtonProps = Pick<
  ButtonProps,
  'download' | 'disabled' | 'href' | 'onClick' | 'params'
>;

const block = bem('share-button');

export interface ShareButtonProps extends PickedButtonProps {
  bodyClassName?: string;
  children: React.ReactNode;
  className?: string;
  label: string;
}

const ShareButton: React.SFC<ShareButtonProps> = ({
  bodyClassName,
  children,
  className,
  label,
  ...props
}) => (
  <Button className={cn(block(), className)} {...props}>
    <div className={cn(block('body'), bodyClassName)}>{children}</div>
    <div className={block('label')}>{label}</div>
  </Button>
);

export default ShareButton;
