import cn from 'classnames';
import * as React from 'react';

import Modal, { ModalProps } from 'components/Modal';

export interface MobileSupportModalProps
  extends Pick<ModalProps, 'onHide' | 'show'> {
  className?: string;
  disabled?: boolean;
  dismissible?: boolean;
}

const MobileSupportModal: React.SFC<MobileSupportModalProps> = ({
  className,
  disabled,
  onHide,
  show,
  dismissible,
}) => {
  const containerClassName = cn(
    'mobile-support-modal',
    'mobile-support-modal--default',
    className,
  );

  return (
    <Modal
      show={show}
      backdrop={dismissible || 'static'}
      keyboard={dismissible}
      className={containerClassName}
      closeButton={dismissible}
      onHide={onHide}
      title="Mobile Support"
    >
      <Modal.Body>
        <div className="mobile-support-modal__text">
          <p>
            Headliner works the best on a desktop or laptop instead of on a
            mobile device. Please switch to a computer in order to use
            Headliner.
          </p>
        </div>
      </Modal.Body>

      {dismissible && (
        <Modal.Footer>
          <Modal.FooterButton onClick={onHide} disabled={disabled}>
            Close
          </Modal.FooterButton>
        </Modal.Footer>
      )}
    </Modal>
  );
};

MobileSupportModal.defaultProps = {
  disabled: false,
  dismissible: true,
  show: false,
};

export default MobileSupportModal;
