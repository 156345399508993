import cn from 'classnames';
import * as React from 'react';

import { cloneOnlyChild } from 'utils/react';
import { block } from './utils';

interface IProps {
  children?: React.ReactNode;
}

const TextBoxField: React.SFC<IProps> = ({ children }) =>
  cloneOnlyChild(children, child => ({
    className: cn(block('field'), child.props.className),
    horizontal: child.props.horizontal || {
      control: { xs: 3 },
      label: { xs: 9 },
    },
  }));

export default TextBoxField;
export { IProps as TextBoxFieldProps };
