import { useEffect, useRef, useState } from 'react';

import { isFileLike } from 'utils/file';

export default function useObjectUrl(src: any) {
  const [objectUrl, setObjectUrl] = useState<string>(undefined);

  const objectUrlRef = useRef(objectUrl);

  useEffect(() => {
    objectUrlRef.current = objectUrl;
  }, [objectUrl]);

  useEffect(() => {
    if (src && isFileLike(src)) {
      setObjectUrl(URL.createObjectURL(src));

      return () => {
        URL.revokeObjectURL(objectUrlRef.current);
      };
    }

    if (typeof src === 'string') {
      setObjectUrl(src);
    } else if (!src) {
      setObjectUrl(undefined);
    }

    return undefined;
  }, [src]);

  return objectUrl;
}
