import Immutable from 'immutable';
import * as mixpanel from 'mixpanel-browser';
import { compose, createStore } from 'redux';
import { autoRehydrate, persistStore } from 'redux-persist-immutable';

import { logout } from 'redux/modules/auth/actions';
import { tokenSelector } from 'redux/modules/auth/selectors';
import { Type as MixpanelType } from 'redux/modules/mixpanel/action-types';
import { onUnsavedWorkAlert } from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal';
import {
  stateFactory as AutomationCmsRecord,
  templatesFactory as AutomationCmsTemplatesRecord,
} from '../modules/automation-cms/factories';
import {
  mySubscriptionFactory as MySubscriptionRecord,
  stateFactory as PricingStateRecord,
} from '../modules/pricing/factories';
import createReducer from '../modules/reducer';
import {
  createBlacklistFilter,
  createWhitelistFilter,
} from '../persist-transform-filter';
import createMigration from '../redux-persist-migrate';
import history, { setUserConfirmationHandler } from './history';
import createMiddleware from './middleware';
import manifest from './persist-migrate-manifest';
import { createStorage, isQuotaExceededError } from './storage';
import { createTokenEnhancer } from './token-enhancer';

const migration = createMigration(
  manifest,
  state => state.app && state.app.get('stateVersion'),
  (state, version) => ({
    ...state,
    app: (state.app || Immutable.Map()).set('stateVersion', version),
  }),
);

/**
 * redux-devtools-extension https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
 */
/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        shouldHotReload: false,
        shouldCatchErrors: false,
      })
    : compose;
/* eslint-enable */

let mpLoaded = false;
mixpanel.init(spareminConfig.mixpanel.token, {
  api_host: spareminConfig.mixpanel.apiHost,
  loaded: () => {
    mpLoaded = true;
    dispatchMixpanelInitialize();
  },
});

const middleware = createMiddleware(mixpanel);

const tokenEnhancer = createTokenEnhancer({
  getToken: state => state.getIn(['auth', 'token']),
  setToken: (state, token) => state.setIn(['auth', 'token'], token),
});

const enhancer = composeEnhancers(
  middleware,
  migration,
  autoRehydrate(),
  tokenEnhancer,
);

const store = createStore(createReducer(history), Immutable.Map(), enhancer);
dispatchMixpanelInitialize();

// this might dispatch twice but that shouldn't be an issue
function dispatchMixpanelInitialize() {
  try {
    if (mpLoaded && store) {
      store.dispatch({
        type: MixpanelType.MIXPANEL_INITIALIZE,
      });
    }
  } catch {
    /* ignore */
  }
}

setUserConfirmationHandler(async message => {
  const result = await store.dispatch(pushModal({ name: 'UnsavedWork' }));

  store.dispatch(onUnsavedWorkAlert(result, message));

  return result;
});

const authWhitelist = createWhitelistFilter('auth', ['userId']);
const embedBlacklist = createBlacklistFilter('embed', ['isLoading']);
const exportBlacklist = createBlacklistFilter('embedExport', [
  'saveInProgress',
]);

/*
 * don't keep project history since we want to refresh history every time
 * the site loads
 */
const projectBlacklist = createBlacklistFilter('project', ['history']);

/*
 * we want to clear the notifications except for the showed intro field
 */
const notificationBlacklist = createBlacklistFilter('notification', [
  'notifications',
  'notificationsById',
]);

const onboardingWhitelist = createWhitelistFilter('onboarding', [
  'wizardComplete',
  'fileRouterStepValue',
]);

const entitiesBlacklist = createBlacklistFilter('entities', [
  'creations',
  'podcastFeeds',
  'podcastFeedEpisodes',
  'socialShares',
]);

const pricingWhitelist = createWhitelistFilter('pricing', [
  'seenFreeTrialReminders',
  'mySubscription',
]);

const storage = createStorage({
  onError(error) {
    if (isQuotaExceededError(error) && tokenSelector(store.getState())) {
      store.dispatch(logout(false));
    }
  },
});

export const persistor = persistStore(store, {
  storage,
  blacklist: [
    'asyncAudioClipper',
    'audioWizard',
    'auth',
    'audiogramWizard',
    'automatedWizard',
    'automationCms',
    'displayPref',
    'download',
    'imageSearch',
    'recordingSearch',
    'routing',
    'router',
    'videoEdit',
    'videoSearch',
    'sampleAudio',
    'gifSearch',
    'embed',
    'social',
    'projectTemplates',
    'podcastSearch',
    'mixpanel',
    'modal',
    'clipSelect',
    'wizardExport',
    'textToImage',
    'imageToVideo',
  ],
  transforms: [
    authWhitelist,
    embedBlacklist,
    entitiesBlacklist,
    exportBlacklist,
    notificationBlacklist,
    onboardingWhitelist,
    projectBlacklist,
    pricingWhitelist,
  ],
  records: [
    MySubscriptionRecord,
    PricingStateRecord,
    AutomationCmsRecord,
    AutomationCmsTemplatesRecord,
  ],
});

if (process.env.NODE_ENV === 'local' && module.hot) {
  module.hot.accept('../modules/reducer', () => {
    store.replaceReducer(createReducer(history));
  });
}

export default store;
