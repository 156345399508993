import * as React from 'react';

import { IconProps } from './types';

const Enterprise: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="66"
    viewBox="0 0 80 66"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M32.764 59.8c1.063-1.464 2.771-2.424 4.72-2.424.126 0 .251.02.372.058a6.94 6.94 0 0 0 2.144.361c.75 0 1.468-.141 2.144-.361.12-.037.246-.058.372-.058 1.949 0 3.657.96 4.72 2.424a9.91 9.91 0 0 0 2.686-6.8c0-5.493-4.444-9.937-9.922-9.937-5.478 0-9.922 4.444-9.922 9.937a9.91 9.91 0 0 0 2.686 6.8zM40 40c7.181 0 13 5.819 13 13s-5.819 13-13 13-13-5.819-13-13 5.819-13 13-13zm0 7.043a4.613 4.613 0 1 1 0 9.226 4.613 4.613 0 0 1 0-9.226zM59.764 45.8c1.063-1.464 2.771-2.424 4.72-2.424.126 0 .251.02.372.058a6.94 6.94 0 0 0 2.144.361c.75 0 1.468-.141 2.144-.361.12-.037.246-.058.372-.058 1.949 0 3.657.96 4.72 2.424a9.91 9.91 0 0 0 2.686-6.8c0-5.493-4.444-9.937-9.922-9.937-5.478 0-9.922 4.444-9.922 9.937a9.91 9.91 0 0 0 2.686 6.8zM67 26c7.181 0 13 5.819 13 13s-5.819 13-13 13-13-5.819-13-13 5.819-13 13-13zm0 7.043a4.613 4.613 0 1 1 0 9.226 4.613 4.613 0 0 1 0-9.226zM5.764 45.8c1.063-1.464 2.771-2.424 4.72-2.424.126 0 .251.02.372.058a6.94 6.94 0 0 0 2.144.361c.75 0 1.468-.141 2.144-.361.12-.037.246-.058.372-.058 1.949 0 3.657.96 4.72 2.424a9.91 9.91 0 0 0 2.686-6.8c0-5.493-4.444-9.937-9.922-9.937-5.478 0-9.922 4.444-9.922 9.937a9.91 9.91 0 0 0 2.686 6.8zM13 26c7.181 0 13 5.819 13 13s-5.819 13-13 13S0 46.181 0 39s5.819-13 13-13zm0 7.043a4.613 4.613 0 1 1 0 9.226 4.613 4.613 0 0 1 0-9.226zM40 2.914c-7.78 0-14.086 6.307-14.086 14.086 0 7.78 6.307 14.086 14.086 14.086 7.78 0 14.086-6.307 14.086-14.086 0-7.78-6.307-14.086-14.086-14.086zM40 0c9.389 0 17 7.611 17 17s-7.611 17-17 17-17-7.611-17-17S30.611 0 40 0zm6.237 15.98c.752.457.75 1.581 0 2.038l-8.396 5.13c-.756.463-1.727-.094-1.727-1.019V11.866a1.138 1.138 0 0 1 1.727-1.018l8.396 5.132z"
    />
  </svg>
);

export default Enterprise;
