import { useEffect, useState } from 'react';

import { useNavigation } from './context/NavigationContext';
import { useEditorState } from './context/VideoEditorStateContext';
import { isLottie } from './state';

import { Slide, VideoClip } from './types';
import { MediaIntegration } from './types/integrations';
import useSlide from './useSlide';
import useVideo from './useVideo';

interface useMainMediaBaseConfig {
  integration: MediaIntegration;
  lastIntegration: MediaIntegration | undefined;
  isLottieAsset: boolean;
}

interface UseMainMediaImage extends useMainMediaBaseConfig {
  type: 'image';
  asset: Slide;
}

interface UseMainMediaVideoClip extends useMainMediaBaseConfig {
  type: 'videoClip';
  asset: VideoClip;
}

interface UseMainMediaNone extends useMainMediaBaseConfig {
  type: 'none';
  asset: undefined;
}

type UseMainMedia =
  | UseMainMediaImage
  | UseMainMediaVideoClip
  | UseMainMediaNone;

const useMainMedia = (): UseMainMedia => {
  const [{ context }] = useNavigation();
  const { id } = context;

  // slide is search from the current id
  const slide = useSlide(id);
  // videoclip is search from the current id
  const videoClip = useVideo(id);

  const { integrations } = useEditorState();
  const { mediaIntegrations } = integrations;
  const [lastIntegration, setLastIntegration] = useState<
    MediaIntegration | undefined
  >();

  // variables and params are initialized for "none" mode with no asset assigned
  let type: UseMainMedia['type'] = 'none';
  let asset;
  let isLottieAsset = false;

  // if slide is present, output is formatted for an image type asset
  if (slide) {
    asset = slide;
    type = 'image';
    isLottieAsset = isLottie(slide);
    // otherwise if videoclip exists, output is formated for a videoclip type asset
  } else if (videoClip) {
    asset = videoClip;
    type = 'videoClip';
  }

  // integration is selected from current asset integration data id by checking if
  // it complies with one of the compatible integration ids
  const integration =
    mediaIntegrations.find(i =>
      i.compatibleIntegrationIds.includes(asset?.integrationData?.id),
    ) ?? mediaIntegrations[0];

  useEffect(() => {
    setLastIntegration(currentIntegration => {
      if (!integration || currentIntegration === integration) {
        return currentIntegration;
      }
      return integration;
    });
  }, [integration]);

  return { asset, integration, isLottieAsset, lastIntegration, type };
};

export default useMainMedia;
