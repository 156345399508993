import React, { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { AudiogramDestination } from 'blocks/AudiogramDestinations/types';
import DialogRadioContent from 'components/DialogRadioContent';

import {
  editAutomationOutputSelector,
  editAutomationSetOutputOption,
  OutputOption,
} from 'redux/modules/edit-automation';

import { AutogramVideoType } from 'types';
import EditAutomationLoader from '../EditAutomationLoader';
import EditAutomationSelectHeader from '../EditAutomationSelectHeader';
import useEditAutomationModal from '../hooks/useEditAutomationModal';
import { OutputOptionItem } from './types';
import useEditOutput from './useEditOutput';

interface EditAutomationOutputModalContentsProps {
  subscriptionId?: number;
  videoType?: AutogramVideoType;
  platform?: AudiogramDestination;
}

const EditAutomationOutputModalContents: React.FunctionComponent<EditAutomationOutputModalContentsProps> = props => {
  const { subscriptionId, videoType, platform } = props;

  const { option } = useSelector(editAutomationOutputSelector);
  const dispatch = useDispatch();

  const options = useMemo((): OutputOptionItem[] => {
    return [
      {
        value: 'EMAIL',
        label: 'Email each video to me',
      },
      {
        value: 'YOUTUBE',
        label: 'Auto-post each video to YouTube',
      },
      ...(videoType !== 'fullEpisode' || platform === 'facebook'
        ? [
            {
              value: 'TIKTOK',
              label: 'Auto-post each video to TikTok',
            } as OutputOptionItem,
            {
              value: 'LINKEDIN',
              label: 'Auto-post each video to LinkedIn',
            } as OutputOptionItem,
            {
              value: 'INSTAGRAM',
              label: 'Auto-post each video to Instagram',
            } as OutputOptionItem,
            {
              value: 'FACEBOOK',
              label: 'Auto-post each video to Facebook',
            } as OutputOptionItem,
            {
              value: 'TWITTER',
              label: 'Auto-post each video to X',
            } as OutputOptionItem,
            {
              value: 'THREADS',
              label: 'Auto-post each video to Threads',
            } as OutputOptionItem,
          ]
        : []),
    ];
  }, [platform, videoType]);

  const { onGoBackToMain } = useEditAutomationModal(subscriptionId);
  const { onConfirmOutputSelection } = useEditOutput(subscriptionId);

  const handleSelectOption = React.useCallback(
    (nextOption: OutputOption) => {
      dispatch(editAutomationSetOutputOption(nextOption));
    },
    [dispatch],
  );

  const handleConfirmOutputSelection = React.useCallback(() => {
    onConfirmOutputSelection(option);
  }, [onConfirmOutputSelection, option]);

  return (
    <>
      <DialogRadioContent
        actions={[
          {
            children: 'update automation',
            fluid: true,
            key: 'submit',
            onClick: handleConfirmOutputSelection,
            theme: 'submit',
          },
        ]}
        label={
          <EditAutomationSelectHeader
            onGoBack={onGoBackToMain}
            title="Where should we output videos to?"
          />
        }
        onChange={handleSelectOption}
        options={options}
        value={option}
      />
      <EditAutomationLoader />
    </>
  );
};

export default EditAutomationOutputModalContents;
