import React from 'react';

import TextToolbar from 'components/TextToolbar';

import useTextOverlayEffects from '../../hooks/useTextOverlayEffects';
import {
  block,
  DEFAULT_HIGHLIGHT_VALUE,
  DEFAULT_SHADOW_VALUE,
} from '../../utils';

const TextOverlayEffectsToolbar: React.FunctionComponent = () => {
  const {
    onChangeHighlightColor,
    onChangeShadowStyle,
    selectedHighlightColor,
    selectedShadowStyle,
  } = useTextOverlayEffects();

  return (
    <div className={block('toolbar-row')}>
      <TextToolbar.TextHighlightPickerV2
        defaultValue={DEFAULT_HIGHLIGHT_VALUE}
        onChange={onChangeHighlightColor}
        value={selectedHighlightColor}
      />
      <TextToolbar.TextShadowInputV2
        defaultValue={DEFAULT_SHADOW_VALUE}
        onChange={onChangeShadowStyle}
        value={selectedShadowStyle}
      />
    </div>
  );
};

export default TextOverlayEffectsToolbar;
