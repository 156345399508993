import { createSelector } from 'reselect';
import { CreationVideoExportOptions } from 'redux/middleware/api/creation-service/types';
import {
  UserPrefVideoSize,
  VideoExportAdjustments,
  WaveformPref,
} from 'redux/middleware/api/headliner-user-service/types';
import {
  userPrefsSelector,
  videoSizesSelector,
  waveformPrefsSelector,
} from 'redux/modules/entities/selectors';
import { State } from 'redux/types';
import {
  AspectRatioName,
  FrameSize,
  RequestStatus,
  VideoExportPreferences,
  VideoFrameExportSettings,
} from 'types';
import { VIDEO_EXPORT_PREFS_DEFAULTS } from 'utils/constants';
import { getVideoResolution } from 'utils/video-export';
import { userIdSelector } from '../auth';
import { UserPrefState } from './types';

const stateSelector = (state: State): UserPrefState => state.get('userPref');

export const currentUserPrefSelector = createSelector(
  userIdSelector,
  userPrefsSelector,
  (userId, userPrefs) => userPrefs?.get(String(userId)),
);

export const waveformPrefIdsSelector = createSelector(
  currentUserPrefSelector,
  userPref => userPref?.get('waveformPrefs')?.toJS() as number[],
);

export const createWaveformPrefIdsSelector = (aspectRatio: number) =>
  createSelector(
    waveformPrefIdsSelector,
    waveformPrefsSelector,
    (prefIds, prefs) =>
      prefIds?.filter(prefId => {
        const pref = prefs?.get(String(prefId));
        return (
          aspectRatio ===
          pref?.get('dimensionWidth') / pref?.get('dimensionHeight')
        );
      }),
  );

export const createWaveformPrefsEmptySelector = (aspectRatio: number) =>
  createSelector(
    createWaveformPrefIdsSelector(aspectRatio),
    prefIds => prefIds?.length === 0,
  );

export const createWaveformPrefSelector = (waveformPrefId: number) =>
  createSelector(
    waveformPrefsSelector,
    waveformPrefs =>
      waveformPrefs?.get(String(waveformPrefId))?.toJS() as WaveformPref,
  );

export const isFetchingUserPrefSelector = createSelector(
  stateSelector,
  state => state?.get('fetchUserPrefStatus') === RequestStatus.REQUEST,
);

export const isSavingWaveformPrefSelector = createSelector(
  stateSelector,
  state => state?.get('saveWaveformPrefStatus') === RequestStatus.REQUEST,
);

export const isDeletingWaveformPrefSelector = (waveformPrefId: number) =>
  createSelector(
    stateSelector,
    state =>
      state?.deleteWaveformPrefStatuses?.get(waveformPrefId) ===
      RequestStatus.REQUEST,
  );

export const presetColorsSelector = createSelector(
  currentUserPrefSelector,
  userPref => userPref?.get('colorPrefs')?.toJS() as string[],
);

export const captionsPrefsSelector = createSelector(
  currentUserPrefSelector,
  userPref => {
    return (
      (userPref?.get('captionPrefs')?.toJS() as {
        captionEnabled: boolean;
        captionLanguage: string;
      }) || { captionEnabled: false, captionLanguage: 'en-US' }
    );
  },
);

export const userVideoPrefsSelector = createSelector(
  currentUserPrefSelector,
  (prefs): VideoExportAdjustments | undefined =>
    prefs?.get('videoExportPrefs')?.toJS(),
);

// Even though video sizes have ids, they're generally not used in the UI and are
// not even used when communicating back and forth with the API.  Since there is
// no other unique identifier in a UserPrefVideoSize, an array is returned.
export const userVideoSizesSelector = createSelector(
  videoSizesSelector,
  (videoSizes): UserPrefVideoSize[] | undefined =>
    videoSizes?.valueSeq()?.toJS(),
);

// videoExportPrefs represent the values that the user can set as their preferences,
// i.e. frame rate, frame quality, and frame size (string value, not dimensions)
export const videoExportPrefsSelector = createSelector(
  // this is what the user has actually set as their preferences, which might
  // actually be nothing (gets backfilled with default values)
  userVideoPrefsSelector,
  (userVideoPrefs): VideoExportPreferences => ({
    frameQuality:
      userVideoPrefs?.frameQuality?.default ??
      VIDEO_EXPORT_PREFS_DEFAULTS.frameQuality,
    frameRate:
      userVideoPrefs?.frameRate?.default ??
      VIDEO_EXPORT_PREFS_DEFAULTS.frameRate,
    fullEpisodeFrameRate:
      userVideoPrefs?.fullEpisodeFrameRate?.default ??
      VIDEO_EXPORT_PREFS_DEFAULTS.fullEpisodeFrameRate,
    frameSize:
      userVideoPrefs?.frameSize?.default ??
      VIDEO_EXPORT_PREFS_DEFAULTS.frameSize,
    fullEpisodeFrameSize:
      userVideoPrefs?.fullEpisodeFrameSize?.default ??
      VIDEO_EXPORT_PREFS_DEFAULTS.fullEpisodeFrameSize,
  }),
);

export const videoFrameExportSettingsSelector = (
  isFullEpisode: boolean,
  state: State,
): VideoFrameExportSettings => {
  const {
    frameQuality,
    frameRate,
    fullEpisodeFrameRate,
    frameSize,
    fullEpisodeFrameSize,
  } = videoExportPrefsSelector(state);

  if (isFullEpisode) {
    return {
      frameQuality,
      frameRate: fullEpisodeFrameRate,
      frameSize: fullEpisodeFrameSize,
    };
  }

  return {
    frameQuality,
    frameRate,
    frameSize,
  };
};

// videoExportOptions represents the values that get sent to the API when exporting
export const videoExportOptionsSelector = (
  aspectRatioName: AspectRatioName,
  isFullEpisode: boolean,
  state: State,
  frameSizeOverride?: FrameSize,
): CreationVideoExportOptions => {
  const {
    frameQuality,
    frameRate,
    frameSize,
  } = videoFrameExportSettingsSelector(isFullEpisode, state);

  const videoSizes = userVideoSizesSelector(state);

  const { height, width } = getVideoResolution(
    frameSizeOverride ?? frameSize,
    aspectRatioName,
    videoSizes,
  );

  return {
    videoFrameHeight: height,
    videoFrameQuality: frameQuality,
    videoFrameRate: frameRate,
    videoFrameWidth: width,
  };
};
