import { Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import CloseIconButton from 'components/CloseIconButton';
import ModalContentWithMediaColumn from './ModalContentWithMediaColumn';
import { block } from './utils';

export interface ModalContentWithMediaProps {
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
}

interface ModalContentWithMediaPropsComponent
  extends React.FC<ModalContentWithMediaProps> {
  ModalContentWithMediaColumn: typeof ModalContentWithMediaColumn;
}

const ModalContentWithMedia: ModalContentWithMediaPropsComponent = ({
  children,
  className,
  onClose,
}) => {
  return (
    <div className={cn(block(), className)}>
      {onClose && (
        <CloseIconButton className={block('close')} onClick={onClose} />
      )}

      <Spacer
        orientation="horizontal"
        align="normal"
        justify="center"
        className={block('body')}
      >
        {children}
      </Spacer>
    </div>
  );
};

ModalContentWithMedia.ModalContentWithMediaColumn = ModalContentWithMediaColumn;

export default ModalContentWithMedia;
