import * as React from 'react';
import { connect } from 'react-redux';

import * as mixpanelActions from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import { IProps } from './AudioUploader';

type DispatchProps = Pick<IProps, 'onLongAudioError'>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onLongAudioError: () => dispatch(mixpanelActions.onAudioUploadLongError()),
});

export default function(component: React.ComponentType<IProps>) {
  return connect(
    null,
    mapDispatchToProps,
  )(component);
}
