import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardCta, { VideoCardCta } from 'components/CardCta';
import { ProBadge, VideoCameraAlt3 } from 'components/icons';
import useBillingCycleModal from 'hooks/useBillingCycleModal';
import { Tier } from 'redux/middleware/api/plan-service';
import { onUnlock1080pExport } from 'redux/modules/mixpanel';
import { pushModal } from 'redux/modules/modal';
import { updateUserVideoExportPrefs } from 'redux/modules/user-pref/actions';
import { videoExportPrefsSelector } from 'redux/modules/user-pref/selectors';
import { Dispatch } from 'redux/types';
import { FrameSize } from 'types';

import CustomizeStepCtaOverlay from './CustomizeStepCtaOverlay';
import { block } from './utils';

type FullEpisodeCtaOverlayProps = {
  onSubmitClick?: (val?: FrameSize) => void;
  onCancelClick?: () => void;
  show: boolean;
};

const FullEpisodeCtaOverlay: React.FC<FullEpisodeCtaOverlayProps> = ({
  onSubmitClick,
  onCancelClick,
  show,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const state = useSelector(videoExportPrefsSelector);
  const { openBillingCycleModal } = useBillingCycleModal();
  const upgradeVideoSettings = () => {
    dispatch(
      updateUserVideoExportPrefs({
        ...state,
        frameSize: 'fullHd',
        fullEpisodeFrameSize: 'fullHd',
      }),
    );
  };

  const handleClick = async () => {
    dispatch(onUnlock1080pExport('FullEpisodeWizard'));
    const isUpgraded = await openBillingCycleModal(
      {
        allowTierSelection: false,
        tier: Tier.PRO,
        defaultSubscriptionPeriod: 'yearly',
        disableSuccessRedirect: true,
      },
      pushModal,
    );
    if (isUpgraded) {
      onSubmitClick('fullHd');
      upgradeVideoSettings();
    }
  };

  return (
    <CustomizeStepCtaOverlay
      overlaySource="UCSFullEpisode"
      {...{ onCancelClick, show }}
    >
      <div className={block('button-wrapper')}>
        <VideoCardCta
          label="Continue with HD"
          description={<span>Export your audiogram at 720p size.</span>}
          onClick={() => onSubmitClick()}
        />
      </div>
      <div className={block('button-wrapper')}>
        <CardCta
          icon={<VideoCameraAlt3 />}
          variant="rainbow"
          label={
            <Spacer space={1} align="center">
              <span>Upgrade to Full HD</span>
              <ProBadge />
            </Spacer>
          }
          description={<span>Export your audiogram at 1080p size.</span>}
          onClick={handleClick}
        />
      </div>
    </CustomizeStepCtaOverlay>
  );
};

export default FullEpisodeCtaOverlay;
