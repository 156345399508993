import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useCost from 'hooks/useCost';
import { PlanName } from 'redux/middleware/api/plan-service';
import { createPlanAmountSelector } from 'redux/modules/pricing';
import { getSubscriptionPeriod } from 'utils/pricing';
import { SubscriptionPeriod } from './types';

export default function usePlanCost(
  plan: PlanName,
  displayPeriod?: SubscriptionPeriod,
): string {
  // cost returned from api, which is the amount the user will be charged based
  // on the plan's billing cycle - monthly plans return the cost for 1 month whereas
  // yearly plans return the cost for 1 year.
  const billingPeriodCostCentsSelector = useMemo(
    () => createPlanAmountSelector(plan),
    [plan],
  );
  const billingPeriodCostCents = useSelector(billingPeriodCostCentsSelector);
  const billingPeriod = getSubscriptionPeriod(plan);

  const displayCostCents =
    billingPeriod === 'monthly' && displayPeriod === 'yearly'
      ? billingPeriodCostCents * 12
      : billingPeriod === 'yearly' && displayPeriod === 'monthly'
      ? billingPeriodCostCents / 12
      : billingPeriodCostCents;

  return useCost(displayCostCents);
}
