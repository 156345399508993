import { useCallback, useEffect, useRef } from 'react';

/**
 * hook for using promises in a component
 *
 * this hoook is designed to hold one promise at a time.  setting a new promise
 * will cancel the existing one.
 */
export default function usePromise<T = void>(promise: Promise<T> = null) {
  const promiseRef = useRef<Promise<T>>(promise);

  const cancelPromise = useCallback(() => {
    if (promiseRef.current && (promiseRef.current as any).cancel) {
      (promiseRef.current as any).cancel();
      promiseRef.current = null;
    }
  }, []);

  const setPromise = useCallback(
    (p: Promise<T>) => {
      cancelPromise();
      promiseRef.current = p;
      return promiseRef.current;
    },
    [cancelPromise],
  );

  /*
   * proxy function which wraps a promise callback, saves the promise, and
   * returns it.
   */
  const withPromise = useCallback(
    (fn: (...args: any[]) => Promise<T>) => (...args: any[]) => {
      cancelPromise();
      const result = fn(...args);
      promiseRef.current = result;
      return result;
    },
    [cancelPromise],
  );

  useEffect(() => () => cancelPromise(), [cancelPromise]);

  return {
    cancelPromise,
    setPromise,
    withPromise,
    promise: promiseRef.current,
  };
}
