import { Tier } from 'redux/middleware/api/plan-service';
import bem from 'utils/bem';

const WATERMARK_FREE_TIERS = [Tier.ENTERPRISE, Tier.PRO];

export const block = bem('autogram-preview-step');

export const isHeadlinerWatermarkEnabled = (tier: Tier): boolean =>
  !WATERMARK_FREE_TIERS.includes(tier);

export const shouldShowWatermarksPopover = (
  tier: Tier,
  transitionActive?: boolean,
): boolean => !transitionActive && !WATERMARK_FREE_TIERS.includes(tier);
