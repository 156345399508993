import { Map } from 'immutable';

import { Type } from './action-types';
import { ProjectTemplatesAction, State } from './types';

const defaultState = Map({ ids: undefined });

export default function reducer(
  state: State = defaultState,
  action: ProjectTemplatesAction,
) {
  switch (action.type) {
    case Type.PROJECT_TEMPLATES_GET_SUCCESS: {
      return state.withMutations(s => s.set('ids', action.payload.ids));
    }

    case Type.PROJECT_TEMPLATES_DELETE_SUCCESS: {
      const { templateId } = action.payload;

      if (!state.get('ids')) {
        return state;
      }

      return state.update('ids', d =>
        d.filter(id => id !== templateId).toList(),
      );
    }

    default:
      return state;
  }
}
