import cn from 'classnames';
import * as React from 'react';
import Spinner from 'react-spinkit';

import bem from 'utils/bem';

interface IProps {
  className?: string;
  customContent?: React.ReactNode;
  title?: string;
  description?: string;
  small?: boolean;
}

const b = bem('loading-overlay');

const LoadingOverlay: React.SFC<IProps> = ({
  className,
  customContent,
  title,
  description,
  small,
}) =>
  customContent ? (
    <div className={cn(b({ small }), className)}>{customContent}</div>
  ) : (
    <div className={cn(b({ small }), className)}>
      {title && <span className={b('title')}>{title}</span>}
      {description && <span className={b('subtitle')}>{description}</span>}
      <Spinner
        className={cn(
          b('spinner'),
          bem('spinner')({ blue: true, med: true, wave: true }),
        )}
        spinnerName="wave"
        noFadeIn
      />
    </div>
  );

LoadingOverlay.defaultProps = {
  small: false,
};

export default LoadingOverlay;
export { IProps as LoadingOverlayProps };
