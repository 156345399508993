import cn from 'classnames';
import color from 'color';
import React, { useContext, useEffect, useRef } from 'react';
import { addPlugin, block } from '../utils';
import WaveSurferContext from '../WaveSurferContext';
import MinimapPlugin from './MinimapPlugin';

interface Props {
  className?: string;
}

const waveColor = color('#dce1eb')
  .alpha(0.5)
  .string();

function Minimap(props: Props) {
  const { className } = props;
  const ref = useRef<HTMLDivElement>();
  const ws = useContext(WaveSurferContext);

  useEffect(() => {
    if (!ws) {
      return () => {};
    }

    const plugin = MinimapPlugin.create({
      container: ref.current,
      waveColor,
      progressColor: waveColor,
      height: 40,
      showOverview: true,
      showRegions: true,
      // minimap only displays the arrow
      cursorColor: 'transparent',
    });

    return addPlugin(ws, plugin as any);
  }, [ws]);

  return <div ref={ref} className={cn(className, block('minimap'))} />;
}

export default Minimap;
