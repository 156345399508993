import cn from 'classnames';
import * as React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { isNumber, isUndefined, noop } from 'underscore';

import BlurredVideoBackground from 'components/BlurredVideoBackground';
import InfoboxOverlay from 'components/InfoboxOverlay';
import { EditorVideoFramePreview } from 'containers/VideoFramePreview';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { hasAllDimensions } from 'utils/embed/video';
import fullstory from 'utils/fullstory';
import { percentageOf } from 'utils/numbers';
import DraggableVideo from '../components/DraggableVideo';
import DurationOverlay from '../components/DurationOverlay';
import { CropInfo, VideoCropperProps, VideoCropperState } from '../types';
import { block, calculateCropInfo, calculateFillZoom } from '../utils';

const fs = fullstory as any;

const defaultCropInfo: CropInfo = {
  axis: 'none',
  dimension: { height: 0, width: 0 },
  initialPosition: { top: 0, left: 0 },
};

export default class VideoCropper extends React.Component<
  VideoCropperProps,
  VideoCropperState
> {
  public static defaultProps: Partial<VideoCropperProps> = {
    blurBackground: false,
    defaultPosition: { top: undefined, left: undefined },
    onLoadedMetadata: noop,
    onPositionChange: noop,
    zoom: 1,
    id: undefined,
  };

  private video: HTMLVideoElement;

  public state: VideoCropperState = {
    cropInfo: defaultCropInfo,
    currentTimeMillis: 0,
    defaultPosition: this.props.defaultPosition,
    dragging: false,
    durationMillis: 0,
    height: undefined,
    left: undefined,
    loadedMetadata: false,
    modified: false,
    top: undefined,
    videoClientHeight: 0,
    videoClientWidth: 0,
    width: undefined,
  };

  public componentDidMount() {
    this.video.addEventListener('loadedmetadata', this.handleLoadedMetadata);
    this.video.addEventListener('timeupdate', this.handleTimeUpdate);
  }

  public UNSAFE_componentWillReceiveProps({
    zoom: nextZoom,
    resetPosition: nextResetPosition,
  }) {
    const { zoom, resetPosition } = this.props;

    if (
      (!isUndefined(zoom) && zoom !== nextZoom) ||
      (!resetPosition && nextResetPosition)
    ) {
      this.setState(
        ({ width, height, top, left }) => {
          if (nextResetPosition) {
            return this.calculateState(width, height, nextZoom);
          }

          return this.calculateState(width, height, nextZoom, top, left, zoom);
        },
        () => {
          this.handlePositionChange(false);
        },
      );
    }
  }

  public componentDidUpdate(_, prevState: Readonly<VideoCropperState>) {
    if (!hasAllDimensions(prevState) && hasAllDimensions(this.state)) {
      this.handlePositionChange(false);
    }
  }

  public componentWillUnmount() {
    this.video.removeEventListener('loadedmetadata', this.handleLoadedMetadata);
    this.video.removeEventListener('timeupdate', this.handleTimeUpdate);
  }

  private handleResize = (width: number, height: number) => {
    const { zoom } = this.props;
    const { top, left } = this.calculatePosition(height, width);
    const newState = {
      ...this.calculateState(width, height, zoom, top, left),
      height,
      width,
    };
    fs.log(`resize event.  setting state to ${JSON.stringify(newState)}`);
    this.setState(newState);
  };

  private handleLoadedMetadata = (event: Event) => {
    // TODO changing the video in an already mounted cropper does not reset top/left
    const { width, height } = this.state;
    const { zoom } = this.props;
    const { top: topInPx, left: leftInPx } = this.calculatePosition(
      height,
      width,
    );
    const newState = {
      ...this.calculateState(width, height, zoom, topInPx, leftInPx),
      loadedMetadata: true,
      videoClientHeight: height,
      videoClientWidth: width,
    };
    fs.log(
      `video metadata loaded.  setting state to ${JSON.stringify(newState)}`,
    );
    this.setState(newState, () => {
      const { onLoadedMetadata } = this.props;
      this.calculateFillZoom();
      onLoadedMetadata(event);
    });
  };

  private handleDrag = ({ top, left }) => {
    this.setState({ top, left, modified: true });
  };

  private handleDragStart = () => {
    this.setState({ dragging: true });
  };

  private handleDragStop = () => {
    this.setState(({ dragging }) => {
      if (dragging) {
        this.handlePositionChange(true);
      }
      return { dragging: false };
    });
  };

  private handlePositionChange = (isDragged: boolean) => {
    const { onPositionChange } = this.props;
    const {
      width,
      height,
      top,
      left,
      cropInfo: { dimension },
    } = this.state;

    const payload = {
      isDragged,
      height: percentageOf(dimension.height, height),
      left: percentageOf(left, width),
      top: percentageOf(top, height),
      width: percentageOf(dimension.width, width),
    };
    fs.log(
      `calculated position change percentages ${JSON.stringify(
        payload,
      )} from data: dimension.height=${dimension.height}, dimension.width=${
        dimension.width
      }, height=${height}, width=${width}, top=${top}, left=${left}`,
    );
    onPositionChange(payload);
  };

  private handleTimeUpdate = () => {
    this.setState(() => {
      const currentTimeMillis = this.video.currentTime * 1000;
      return { currentTimeMillis };
    });
  };

  private calculateState = (
    workspaceWidth: number,
    workspaceHeight: number,
    zoom: number,
    top?: number,
    left?: number,
    prevZoom?: number,
  ): Pick<
    VideoCropperState,
    'cropInfo' | 'durationMillis' | 'left' | 'top'
  > => {
    /*
     * calculateCropInfo will result in undefined / NaN's if we don't have
     * video dimensions yet
     */
    if (
      !this.video ||
      this.video.videoHeight === 0 ||
      this.video.videoWidth === 0 ||
      isUndefined(workspaceWidth) ||
      isUndefined(workspaceHeight)
    ) {
      fs.log('cannot calculate state because a required value is undefined');
      return undefined;
    }

    const cropInfo = calculateCropInfo(
      this.video,
      workspaceWidth,
      workspaceHeight,
      zoom,
      top,
      left,
      prevZoom,
    );

    fs.log(`calculated crop info ${JSON.stringify(cropInfo)}`);

    const statePayload = {
      cropInfo,
      durationMillis: this.video.duration * 1000,
      left: cropInfo.initialPosition.left,
      top: cropInfo.initialPosition.top,
    };

    fs.log(`calculated state as ${JSON.stringify(statePayload)}`);

    return statePayload;
  };

  private calculateFillZoom = () => {
    const { aspectRatio, onCalculateFillZoom } = this.props;
    const { videoWidth, videoHeight } = this.video;

    const fillZoom = calculateFillZoom(aspectRatio, videoWidth, videoHeight);
    onCalculateFillZoom(fillZoom);
  };

  private calculatePosition(height: number, width: number) {
    const {
      defaultPosition: { top, left },
    } = this.state;
    const { zoom } = this.props;

    const initialTop = zoom > 0 ? top : undefined;
    const initialLeft = zoom > 0 ? left : undefined;

    return {
      left: isNumber(initialLeft) ? (initialLeft * width) / 100 : undefined,
      top: isNumber(initialTop) ? (initialTop * height) / 100 : undefined,
    };
  }

  private setVideo = (video: HTMLVideoElement) => {
    this.video = video;
  };

  public render() {
    const {
      blurBackground,
      className,
      videoUrl,
      aspectRatio,
      playing,
      id,
      ...rest
    } = this.props;
    const {
      dragging,
      loadedMetadata,
      width,
      height,
      durationMillis,
      currentTimeMillis,
      videoClientHeight,
      videoClientWidth,
      ...restState
    } = this.state;

    const ratioName = getAspectRatioName(aspectRatio);

    return (
      <div className={cn(block({ [ratioName]: true }), className)}>
        {/* ReactResizeDetector callback has height set to 0 without this inner div */}
        <div className={block('container')}>
          <ReactResizeDetector
            handleWidth
            handleHeight
            onResize={this.handleResize}
          />
          {blurBackground && this.video && loadedMetadata && (
            <BlurredVideoBackground
              video={this.video}
              playing={playing}
              videoClientHeight={videoClientHeight}
              videoClientWidth={videoClientWidth}
            />
          )}
          {!blurBackground && (
            <EditorVideoFramePreview
              aspectRatio={aspectRatio}
              canvasDimensions={
                videoClientWidth && videoClientHeight
                  ? {
                      width: videoClientWidth,
                      height: videoClientHeight,
                    }
                  : undefined
              }
              backgroundFor={{
                type: 'videoClips',
                id,
              }}
            />
          )}
          <DraggableVideo
            {...rest}
            {...restState}
            dragging={dragging}
            onDragStart={this.handleDragStart}
            onDrag={this.handleDrag}
            onDragStop={this.handleDragStop}
            src={videoUrl}
            videoRef={this.setVideo}
            playing={playing}
          />
          <DurationOverlay
            durationMillis={durationMillis}
            currentTimeMillis={currentTimeMillis}
          />
          {!dragging && !playing && loadedMetadata && (
            <InfoboxOverlay text="Click and drag to move video" />
          )}
        </div>
      </div>
    );
  }
}

export { VideoCropper };
