import * as React from 'react';
import Button from 'components/Button';
import { mobileAppPrompt } from '../utils';

const MobileAppPromptPage: React.FC = () => {
  return (
    <div className={mobileAppPrompt()}>
      <img
        className={mobileAppPrompt('logo')}
        src="https://static.sparemin.com/static/logo/headliner/202107152136/mark/gradient/Headliner.Logo.2021.Mark.GradientWithBlackFill.svg"
        alt="Logo"
      />
      <h1 className={mobileAppPrompt('title')}>
        Easily turn audio to video
        <br />
        with the Headliner app:
      </h1>
      <nav className={mobileAppPrompt('buttons')}>
        <Button
          fluid
          theme="submit"
          href={spareminConfig.branch.downloadAppUrl}
        >
          Download now
        </Button>
      </nav>
    </div>
  );
};

export default MobileAppPromptPage;
