import * as React from 'react';
import { connect } from 'react-redux';

import { selectors as authSelectors } from 'redux/modules/auth';
import { forgotPassword, updatePassword } from 'redux/modules/auth/actions';
import { pushModal } from 'redux/modules/modal';
import { Dispatch, State } from 'redux/types';
import { IProps } from './PasswordSettings';

type StateProps = Pick<
  IProps,
  'errorMessage' | 'updateInProgress' | 'forgotInProgress' | 'emailAddress'
>;
type DispatchProps = Pick<
  IProps,
  'onSubmit' | 'onForgotPassword' | 'onDeleteUserAccount'
>;

const mapStateToProps = (state: State): StateProps => ({
  emailAddress: authSelectors.userNameSelector(state),
  errorMessage: authSelectors.errorMessageSelector(state),
  forgotInProgress: authSelectors.forgotPasswordInProgressSelector(state),
  updateInProgress: authSelectors.passwordUpdateInProgressSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onForgotPassword: () => dispatch(forgotPassword()),
  onSubmit: (currentPassword, newPassword, confirmPassword) => {
    dispatch(updatePassword(currentPassword, newPassword, confirmPassword));
  },
  onDeleteUserAccount: () => dispatch(pushModal({ name: 'DeleteAccount' })),
});

export default function(component: React.ComponentType<IProps>) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
