import {
  ButtonBase,
  FocusScope,
  TooltipContainer,
  TooltipContainerProps,
  TooltipPopover,
  useHover,
} from '@sparemin/blockhead';
import React from 'react';
import { block } from './utils';

type PickedTooltipContainerProps = Pick<
  TooltipContainerProps,
  'offset' | 'placement' | 'strategy'
>;

interface ToolbarPopoverButtonProps extends PickedTooltipContainerProps {
  children: React.ReactNode;
  isEnabled: boolean;
  onClickTrigger: () => void;
  popoverContent: React.ReactNode;
}

const ToolbarPopoverButton: React.FunctionComponent<ToolbarPopoverButtonProps> = props => {
  const {
    children,
    isEnabled,
    offset = 0,
    onClickTrigger,
    placement = 'top',
    popoverContent,
    strategy,
  } = props;

  // Button hover is necessary because the tooltip will ignore the pointer
  // entry event when the button is clicked preventing is open to be triggered
  const { hoverProps, isHovered: buttonIsHovered } = useHover({});

  return (
    <TooltipContainer
      delay={0}
      offset={offset}
      placement={placement}
      contents={({ state: { isOpen } }) => {
        const visible = isEnabled && (buttonIsHovered || isOpen);
        return (
          visible && (
            <TooltipPopover className={block('toolbar-popover')}>
              <FocusScope autoFocus>{popoverContent}</FocusScope>
            </TooltipPopover>
          )
        );
      }}
      strategy={strategy}
    >
      <div>
        <ButtonBase
          {...hoverProps}
          as="div"
          className={block('toolbar-popover-trigger')}
          onPress={onClickTrigger}
        >
          {children}
        </ButtonBase>
      </div>
    </TooltipContainer>
  );
};

export default ToolbarPopoverButton;
