import * as React from 'react';

import { IconProps } from 'components/icons/types';

const InstagramGradientCircleButton: React.FC<IconProps> = (
  props: IconProps,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="85"
    height="85"
    viewBox="0 0 85 85"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="rotate(180 42.5 42.5)">
        <use
          fill="#000"
          filter="url(#instagram-gradient-circle-button-filter)"
          xlinkHref="#instagram-gradient-circle-button-circle"
        />
        <use
          fill="url(#instagram-gradient-circle-button-gradient)"
          xlinkHref="#instagram-gradient-circle-button-circle"
        />
      </g>
      <path
        fill="#ffffff"
        fillRule="nonzero"
        d="M43.11 34.701a8.392 8.392 0 00-8.403 8.406 8.392 8.392 0 008.404 8.406 8.392 8.392 0 008.404-8.406 8.392 8.392 0 00-8.404-8.406zm0 13.871a5.474 5.474 0 01-5.463-5.465 5.47 5.47 0 015.464-5.465 5.47 5.47 0 015.463 5.465 5.474 5.474 0 01-5.463 5.465zM53.82 34.357c0 1.09-.878 1.961-1.96 1.961a1.96 1.96 0 111.96-1.96zm5.566 1.99c-.125-2.626-.724-4.952-2.648-6.87-1.916-1.916-4.242-2.516-6.868-2.647-2.706-.154-10.818-.154-13.524 0-2.618.124-4.944.724-6.868 2.64-1.923 1.917-2.516 4.244-2.648 6.87-.153 2.707-.153 10.82 0 13.527.125 2.626.725 4.953 2.648 6.87 1.924 1.916 4.242 2.516 6.868 2.648 2.706.153 10.818.153 13.524 0 2.626-.125 4.952-.724 6.868-2.649 1.916-1.916 2.516-4.243 2.648-6.87.153-2.706.153-10.812 0-13.519zm-3.496 16.424a5.532 5.532 0 01-3.116 3.117c-2.158.856-7.278.658-9.662.658-2.385 0-7.512.19-9.662-.658a5.532 5.532 0 01-3.116-3.117c-.856-2.158-.658-7.279-.658-9.664s-.19-7.513.658-9.664a5.532 5.532 0 013.116-3.117c2.157-.855 7.277-.658 9.662-.658 2.384 0 7.511-.19 9.662.658a5.532 5.532 0 013.116 3.117c.855 2.158.658 7.28.658 9.664 0 2.385.197 7.513-.658 9.664z"
      />
    </g>
  </svg>
);

export default InstagramGradientCircleButton;
