import { countBy } from 'underscore';
import bem from 'utils/bem';
import { SelectMultipleOption } from './types';

export const block = bem('select-multiple');

export type Labels = {
  noSelected: string;
  selected: string;
  selectedPlural: string;
};

export function prepareValue(labels: Labels) {
  return (options: SelectMultipleOption[]): SelectMultipleOption => {
    const { selected = 0 } = countBy(options, opt => {
      return opt.selected === true ? 'selected' : 'noSelected';
    });

    if (selected > 0) {
      const plural = selected > 1;
      return {
        id: 'selected-counter-label',
        label: `${selected} ${
          plural ? labels.selectedPlural : labels.selected
        } selected`,
        value: 'selected-counter-label',
      };
    }

    return {
      id: 'no-selected',
      label: labels.noSelected,
      value: 'no-selected',
    };
  };
}
