import { Text } from '@sparemin/blockhead';
import cn from 'classnames';
import * as React from 'react';
import { identity, noop } from 'underscore';

import FontAwesome from 'components/FontAwesome';
import { getValue } from 'utils/collections';
import SelectContext from './SelectContext';
import SelectOption from './SelectOption';
import { Option, Overrides, SelectControlProps } from './types';
import { block } from './utils';

const { useContext } = React;

function SelectControl<T extends Option>(props: SelectControlProps<T>) {
  const overrides = useContext<Overrides<T>>(SelectContext);

  const commonProps = {
    className: cn(block('control')),
  };

  const overriddenProps = getValue(
    overrides,
    ['control', 'props'],
    identity,
  )({
    ...props,
    ...commonProps,
  });

  const { className, onClick, value, placeholder, ...rest } = overriddenProps;

  return (
    <div className={className} onClick={onClick} {...rest}>
      {value ? (
        <SelectOption className={block('control-value')} option={value} />
      ) : (
        <Text variant="label" className={block('placeholder')}>
          {placeholder}
        </Text>
      )}
      <FontAwesome className={block('arrow')} icon="caret-down" />
    </div>
  );
}

SelectControl.defaultProps = {
  onClick: noop,
};

export default SelectControl;
