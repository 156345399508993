import cn from 'classnames';
import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import ToggleButton, { ToggleButtonProps } from './ToggleButton';
import { block } from './utils';

type Props = ToggleButtonProps;

const ItalicsToggle: React.SFC<Props> = ({ className, ...buttonProps }) => (
  <ToggleButton
    className={cn(block('italics-toggle'), className)}
    {...buttonProps}
  >
    <FontAwesome icon="italic" />
  </ToggleButton>
);

export default ItalicsToggle;
export { Props as ItalicsToggleProps };
