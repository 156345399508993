import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import PromoUnavailableModalContents from './PromoUnavailableModalContents';

export interface PromoUnavailableModalProps {}

const PromoUnavailableModal: React.FC<PromoUnavailableModalProps> = () => (
  <ConnectedModal
    show
    name="PromoUnavailable"
    title="This Promo is not Available"
  >
    <PromoUnavailableModalContents />
  </ConnectedModal>
);

export default PromoUnavailableModal;
