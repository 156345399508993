import * as React from 'react';

import useOnMount from 'hooks/useOnMount';

import ClipAudioStep, { ClipAudioStepProps } from '../../ClipAudioStep';
import { TranscriptionConfig, TranscriptionFormValue } from '../../types';
import { block } from '../utils';

type PickedClipStepProps = Pick<
  ClipAudioStepProps,
  | 'clipperRef'
  | 'maxDurationMillis'
  | 'onPresetChange'
  | 'onReady'
  | 'onRegionUpdate'
  | 'playing'
  | 'region'
  | 'transcriptionConfig'
>;

export interface AudioWizardClipStepProps extends PickedClipStepProps {
  audioSrc: string;
  entireAudioInstanceId: number;
  hidden?: boolean;
  // TODO is this necessary?
  isAudiogram: boolean;
  lockedTranscriptionConfig?: TranscriptionConfig;
  onLoadAudio?: (src: string) => void;
  onReady?: () => void;
  onTranscriptionChange?: (value: TranscriptionFormValue) => void;
  transcription?: TranscriptionFormValue;
}

const AudioWizardClipStep: React.SFC<AudioWizardClipStepProps> = ({
  audioSrc,
  entireAudioInstanceId,
  hidden,
  isAudiogram,
  onLoadAudio,
  onPresetChange,
  transcriptionConfig,
  ...clipStepProps
}) => {
  useOnMount(() => onLoadAudio(audioSrc));

  return (
    <ClipAudioStep
      className={block('clip-audio-step', { hidden })}
      entireAudioInstanceId={entireAudioInstanceId}
      maxDurationMillis={Infinity}
      onPresetChange={onPresetChange}
      src={audioSrc}
      transcriptionConfig={transcriptionConfig}
      {...clipStepProps}
    />
  );
};

export default AudioWizardClipStep;
