import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noop } from 'underscore';
import { PlanName } from 'redux/middleware/api/plan-service';
import { showNotification, showSuccess } from 'redux/modules/notification';
import {
  isUpdatingSubscriptionSelector,
  updateSubscription,
} from 'redux/modules/pricing';

interface UseGiveUpMyPlanConfig {
  onGiveUpComplete?: () => void;
}

export default function useGiveUpMyPlan({
  onGiveUpComplete = noop,
}: UseGiveUpMyPlanConfig) {
  const dispatch = useDispatch();
  const isGivingUp = useSelector(isUpdatingSubscriptionSelector);

  const onGiveUpMyPlan = useCallback(async (): Promise<void> => {
    try {
      await dispatch(updateSubscription(PlanName.FREE_FOREVER, false));

      dispatch(
        showSuccess(
          'You can always reactivate it if you change your mind',
          10,
          undefined,
          'You have given up your plan',
        ),
      );

      onGiveUpComplete();
    } catch (err) {
      dispatch(
        showNotification({
          message: 'Please try again or {{link}} so we can help',
          code: 'ER013',
          level: 'error',
          type: 'help',
          title: "Sorry, we couldn't cancel your plan",
          actionLabel: 'contact us',
        }),
      );
    }
  }, [dispatch, onGiveUpComplete]);

  return { isGivingUp, onGiveUpMyPlan };
}
