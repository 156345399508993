import { useSelector } from 'react-redux';

import { createSelector } from 'reselect';
import { threadsSelector, threadsUserDataSelector } from 'redux/modules/social';
import { PostStatus } from 'redux/modules/social/constants';
import { DEFAULT_CAPTION } from './constants';

const postStatusSelector = createSelector(
  threadsSelector,
  threads => threads.postStatus,
);

interface UseThreadsPostModalConfig {
  defaultDescription?: string;
}

export default function useThreadsPostModalSelector(
  config: UseThreadsPostModalConfig = {},
) {
  const { defaultDescription } = config;

  const { username, profileImageUrl } = useSelector(threadsUserDataSelector);
  const postStatus: PostStatus = useSelector(postStatusSelector);

  return {
    username,
    profileImageUrl,
    postStatus,
    defaultDescription: defaultDescription ?? DEFAULT_CAPTION,
  };
}
