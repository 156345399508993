import * as React from 'react';

import { Omit } from 'types';
import { StyleTile, StyleTileProps } from '../../components/StyleTileButton';
import VideoStyleButton, { VideoStyleButtonProps } from './VideoStyleButton';

type PickedTileProps = Omit<StyleTileProps, 'children'>;

export type VideoStyleTileButtonProps = PickedTileProps & VideoStyleButtonProps;

const VideoStyleTileButton: React.SFC<VideoStyleTileButtonProps> = ({
  className,
  ...buttonProps
}) => (
  <StyleTile className={className}>
    <VideoStyleButton {...buttonProps} />
  </StyleTile>
);

export default VideoStyleTileButton;
