import Immutable from 'immutable';
import { createSelector } from 'reselect';

import { embedWidgetIdSelector } from '../embed/selectors';
import { AudioExportStatus } from './constants';

export const renderedAudioRecordingIdSelector = state =>
  state.getIn(['embedExport', 'recordingId']);

export const renderedEmbedConfigurationSelector = state =>
  state.getIn(['embedExport', 'renderedConfiguration']);

export const videoExportSelector = state =>
  state.getIn(['embedExport', 'videoExport']);
export const videoExportUrlSelector = state =>
  state.getIn(['embedExport', 'videoUrl']);
export const audioExportSelector = state =>
  state.getIn(['embedExport', 'audioExport']);
export const embedExportWidgetIdSelector = state =>
  state.getIn(['embedExport', 'widgetId']);
export const exportSaveInProgressSelector = state =>
  state.getIn(['embedExport', 'saveInProgress']);

export const renderedEmbedConfigurationWidgetIdSelector = createSelector(
  [renderedEmbedConfigurationSelector],
  config => config && config.wid,
);

export const audioExportStatusSelector = createSelector(
  [audioExportSelector],
  audioExport => {
    if (!audioExport || !audioExport.get('status')) return undefined;
    return AudioExportStatus.enumValueOf(audioExport.get('status'));
  },
);

export const videoExportProgressSelector = createSelector(
  [videoExportSelector, embedExportWidgetIdSelector],
  (videoExport = Immutable.Map(), widgetId) =>
    widgetId ? videoExport.set('widgetId', widgetId) : videoExport,
);

const audioExportInProgressSelector = createSelector(
  audioExportStatusSelector,
  status => AudioExportStatus.inProgress(status),
);

export const exportInProgressSelector = createSelector(
  [
    embedWidgetIdSelector,
    videoExportProgressSelector,
    audioExportInProgressSelector,
    exportSaveInProgressSelector,
  ],
  (wid, exportProgress, audioExportInProgress, saveInProgress) => {
    if (saveInProgress) return true;
    if (!wid || !exportProgress) return false;
    const isExportingThisEmbed = exportProgress.get('widgetId') === wid;
    const isFinished =
      !audioExportInProgress && exportProgress.get('isResolved');
    return isExportingThisEmbed && !isFinished;
  },
);
