import { useCallback, useReducer } from 'react';

import { Actions, State } from './types';

const initialState: State = {
  projectId: undefined,
  showModalType: undefined,
  workflowId: undefined,
  episodeId: undefined,
};

function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'show-podcast-delete':
      return { ...state, showModalType: 'podcast-delete' };

    case 'show-workflow-create': {
      const { episodeId } = action.payload;
      return { ...state, episodeId, showModalType: 'workflow-create' };
    }

    case 'show-workflow-delete': {
      const { workflowId } = action.payload;
      return {
        ...state,
        workflowId,
        showModalType: 'workflow-delete',
      };
    }

    case 'show-workflow-disable': {
      const { workflowId } = action.payload;
      return {
        ...state,
        workflowId,
        showModalType: 'workflow-disable',
      };
    }

    case 'show-episode-create': {
      const { episodeId } = action.payload;
      return {
        ...state,
        episodeId,
        showModalType: 'episode-create',
      };
    }

    case 'reset':
      return initialState;

    default:
      return state;
  }
}

export default function useAutomationCMSModalVisibility() {
  const [
    { projectId, showModalType, workflowId, episodeId },
    dispatch,
  ] = useReducer(reducer, initialState);

  const hideModal = useCallback(() => {
    dispatch({ type: 'reset' });
  }, []);

  const showPodcastDeleteModal = useCallback(() => {
    dispatch({ type: 'show-podcast-delete' });
  }, []);

  const showWorkflowDeleteModal = useCallback((wid: number) => {
    dispatch({
      payload: { workflowId: wid },
      type: 'show-workflow-delete',
    });
  }, []);

  const showWorkflowDisableModal = useCallback((wid: number) => {
    dispatch({
      payload: { workflowId: wid },
      type: 'show-workflow-disable',
    });
  }, []);

  return {
    dispatch,
    hideModal,
    projectId,
    episodeId,
    showModalType,
    showPodcastDeleteModal,
    showWorkflowDeleteModal,
    showWorkflowDisableModal,
    workflowId,
  };
}
