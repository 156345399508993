import { useCallback } from 'react';

import { getProgressPositionId, getProgressSizeId } from 'utils/embed/progress';
import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import { ProgressPlacement } from './types';

export default function useProgressPlacement() {
  const { progress } = useTemplateState();
  const dispatch = useTemplateDispatch();
  const size = getProgressSizeId(progress);
  const alignment = getProgressPositionId(progress);

  const onPlacementChange = useCallback(
    (value: ProgressPlacement) => {
      if (value.size && value.size !== size) {
        dispatch({
          payload: value.size,
          type: 'PROGRESS_SIZE_CHANGE',
        });
      } else if (value.alignment && value.alignment !== alignment) {
        dispatch({
          payload: value.alignment,
          type: 'PROGRESS_ALIGNMENT_CHANGE',
        });
      }
    },
    [alignment, dispatch, size],
  );

  return {
    onPlacementChange,
    value: {
      alignment: alignment === 'custom' ? undefined : alignment,
      size: size === 'custom' ? undefined : size,
    },
  };
}
