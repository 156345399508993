import cn from 'classnames';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { noop } from 'underscore';

import FileUploaderDropzoneBody from './FileUploaderDropzoneBody';
import { MediaImportOption } from './types';
import { block } from './utils';

type DropzoneProps = Dropzone.DropzoneProps;
type DropFilesEventHandler = Dropzone.DropFilesEventHandler;

type PickedProps = Pick<
  DropzoneProps,
  'onDrop' | 'onDropAccepted' | 'onDropRejected'
>;

export type FileUploadMethod = 'chooseFile' | 'mediaImporter';

interface IProps extends PickedProps {
  accept?: DropzoneProps['accept'];
  className?: string;
  inProgress?: boolean;
  title?: React.ReactNode;
  footer?: React.ReactElement;
  image: React.ReactNode;
  supportedFileTypes: string[];
  supportedFileMaxSizeInMb?: number;
  additionalRestrictions?: string;
  templateDimensionsInfo?: React.ReactNode;
  multiple?: boolean;
  uploadMethod?: FileUploadMethod;
  onClickMediaImportOption?: (mediaImportOption: MediaImportOption) => void;
}

interface IState {
  showOverlay?: boolean;
}

export default class FileUploaderDropzone extends React.Component<
  IProps,
  IState
> {
  public static defaultProps: Partial<IProps> = {
    inProgress: false,
    onDrop: noop,
    title: 'Drag & drop',
    uploadMethod: 'chooseFile',
  };

  private dropzone: Dropzone;

  public state: IState = { showOverlay: false };

  private handleDragLeave = () => this.showOverlay(false);

  private handleDragOver = () => this.showOverlay(true);

  private handleDrop: DropFilesEventHandler = (accepted, rejected, event) => {
    const { onDrop } = this.props;
    this.showOverlay(false);
    onDrop(accepted, rejected, event);
  };

  private handleUploadButtonClick = () => this.dropzone.open();

  private showOverlay(show: boolean) {
    this.setState({ showOverlay: show });
  }

  public render() {
    const {
      accept,
      className,
      onDropAccepted,
      onDropRejected,
      inProgress,
      title,
      footer,
      image,
      supportedFileTypes,
      supportedFileMaxSizeInMb,
      additionalRestrictions,
      templateDimensionsInfo,
      multiple,
      uploadMethod = 'chooseFile',
      onClickMediaImportOption,
    } = this.props;
    const { showOverlay } = this.state;

    return (
      <Dropzone
        accept={accept}
        activeClassName={`${block('dropzone')}--active`}
        className={cn(block('dropzone'), className)}
        rejectClassName={`${block('dropzone')}--rejected`}
        disableClick
        multiple={multiple}
        onDragLeave={this.handleDragLeave}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
        ref={el => {
          this.dropzone = el;
        }}
      >
        {showOverlay && !inProgress && <div className={block('overlay')} />}

        <FileUploaderDropzoneBody
          image={image}
          title={title}
          footer={footer}
          supportedFileTypes={supportedFileTypes}
          supportedFileMaxSizeInMb={supportedFileMaxSizeInMb}
          additionalRestrictions={additionalRestrictions}
          onClickUploadButton={this.handleUploadButtonClick}
          templateDimensionsInfo={templateDimensionsInfo}
          uploadMethod={uploadMethod}
          onClickMediaImportOption={onClickMediaImportOption}
        />
      </Dropzone>
    );
  }
}

export { IProps as FileUploaderDropzoneProps };
