import * as React from 'react';

import ControlsIcon from 'components/icons/ControlsIcon';
import TrackLabelButton from '../TrackLabelButton';
import { ButtonProps } from './types';

export type GlobalStyleButtonProps = ButtonProps;

export default class GlobalStyleButton extends React.PureComponent<
  GlobalStyleButtonProps
> {
  public render() {
    return (
      <TrackLabelButton
        tooltip="Apply styles to all text boxes"
        {...this.props}
      >
        <ControlsIcon />
      </TrackLabelButton>
    );
  }
}
