import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import { block } from './utils';

export interface AvatarProps {
  src?: string;
}

const Avatar: React.SFC<AvatarProps> = ({ src }) => (
  <div className={block('avatar')}>
    {src ? (
      <img className={block('avatar-image')} src={src} />
    ) : (
      <FontAwesome icon="user-circle" />
    )}
  </div>
);

export default Avatar;
