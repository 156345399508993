import { Spacer } from '@sparemin/blockhead';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { noop } from 'underscore';

import PodcastSearch, { PodcastSearchProps } from 'blocks/PodcastSearch';
import AudioDurationUpsellBanner from 'components/AudioDurationUpsellBanner';
import Tabs from 'components/Tabs';
import useMediaPlayback from 'hooks/useMediaPlayback';
import useOnMount from 'hooks/useOnMount';
import { audioSelectorMethodBlacklist } from 'redux/modules/display-pref';
import { AddAudioMeta, AudioSourceType } from 'types';
import { ApplicationError } from 'utils/ApplicationError';

import WizardAudioUploader, {
  WizardAudioUploaderProps,
} from '../WizardAudioUploader';
import {
  AUDIO_SELECTOR_TO_TAB_KEY,
  block,
  checkPlanEpisodeMaxDurationUsage,
} from './utils';

const { useEffect, useRef } = React;

export type TabKey = 'upload' | 'search';
export type ErrorType = 'episodeTooLarge';

type PickedUploaderProps = Pick<
  WizardAudioUploaderProps,
  'isFileSelected' | 'wizardType'
>;

interface VideoRenderProps {
  playing: boolean;
  onPlay?: () => void;
  onPause?: () => void;
}

type PickedPodcastSearchProps = Pick<PodcastSearchProps, 'onDescriptImport'>;

export type AddAudioStepSources = Array<TabKey>;

export type OnAudioAdded = (
  source: File | string,
  type: AudioSourceType,
  meta?: AddAudioMeta,
) => void;

export interface AddAudioStepProps
  extends PickedUploaderProps,
    PickedPodcastSearchProps {
  activeTab: TabKey;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  isFreeOrBasicTier?: boolean;
  maxFileDurationSeconds?: number;
  maxFileSizeMb: number;
  onAudioAdded?: OnAudioAdded;
  onError?: (error: ApplicationError, file?: File, type?: ErrorType) => void;
  onIncreaseDurationLimit?: (e: React.MouseEvent) => void;
  onOfferIncreaseDurationLimit?: () => void;
  onTabSelect?: (tabKey: TabKey) => void;
  podcastSearchIntegrations?: PodcastSearchProps['integrations'];
  showTranscriptionForm?: boolean;
  video?: (props: VideoRenderProps) => JSX.Element;
  sources?: AddAudioStepSources;
}

const audioSourcesTabKeySelector = createSelector(
  audioSelectorMethodBlacklist,
  blacklist => {
    const tabKeyBlacklist = blacklist.map(
      selector => AUDIO_SELECTOR_TO_TAB_KEY[selector],
    );
    const defaultKeys: TabKey[] = ['upload', 'search'];
    return defaultKeys.filter(value => !tabKeyBlacklist.includes(value));
  },
);

const AddAudioStep: React.FC<AddAudioStepProps> = ({
  activeTab,
  header,
  footer,
  isFileSelected,
  isFreeOrBasicTier,
  maxFileDurationSeconds,
  maxFileSizeMb,
  onAudioAdded,
  onError,
  onIncreaseDurationLimit,
  onOfferIncreaseDurationLimit,
  onTabSelect,
  podcastSearchIntegrations,
  showTranscriptionForm,
  video,
  wizardType,
  sources = ['search', 'upload'],
}) => {
  const tabKeys: TabKey[] = useSelector(audioSourcesTabKeySelector);
  const searchRef = useRef();
  const {
    playing: playingUploadVideo,
    play: playUploadVideo,
    pause: pauseUploadVideo,
  } = useMediaPlayback();

  const shouldUsePlanMaxAudioDuration = checkPlanEpisodeMaxDurationUsage(
    wizardType,
  );
  const shouldShowDurationIncreaseBanner =
    shouldUsePlanMaxAudioDuration && isFreeOrBasicTier;

  useOnMount(() => onAudioAdded(undefined, undefined));

  useEffect(() => {
    pauseUploadVideo();

    if (activeTab === 'search' && searchRef.current) {
      (searchRef.current as any).focus();
    }
  }, [activeTab, pauseUploadVideo]);

  const handleDropAccepted = (file: File) => onAudioAdded(file, 'upload');

  const handleSampleAudioClick = (url: string) => onAudioAdded(url, 'sample');

  const tabConfig = {
    contentClassName: block('tab-content'),
    titleClassName: block('tab-title'),
  };

  const tabs = [
    {
      ...tabConfig,
      content: (
        <div className={block('podcast-content')}>
          <PodcastSearch
            className={block('podcast-search')}
            integrations={podcastSearchIntegrations}
            ref={searchRef}
            usePlanEpisodeMaxDuration={shouldUsePlanMaxAudioDuration}
          />
        </div>
      ),
      tabKey: 'search',
      title: 'podcasts',
    },
    {
      ...tabConfig,
      content: (
        <div className={block('upload-tab-content')}>
          {video && (
            <div className={block('video-wrapper')}>
              {video({
                onPause: pauseUploadVideo,
                onPlay: playUploadVideo,
                playing: playingUploadVideo,
              })}
            </div>
          )}
          <WizardAudioUploader
            className={block('uploader')}
            checkDurationIncreaseForTier={shouldUsePlanMaxAudioDuration}
            isFileSelected={isFileSelected}
            maxFileDurationSeconds={maxFileDurationSeconds}
            maxFileSizeMb={maxFileSizeMb}
            onDropAccepted={handleDropAccepted}
            onError={onError}
            onLongAudioForTierError={onOfferIncreaseDurationLimit}
            onSampleAudioClick={handleSampleAudioClick}
            wizardType={wizardType}
            renderTranscriptInstructions={showTranscriptionForm}
          />
        </div>
      ),
      tabKey: 'upload',
      title: 'upload',
    },
  ].filter(
    tab =>
      tabKeys.includes(tab.tabKey as TabKey) &&
      sources.includes(tab.tabKey as TabKey),
  );

  return (
    <div className={block()}>
      {header && (
        <Spacer
          orientation="vertical"
          align="center"
          justify="space-between"
          space={2.5}
          className={block('header')}
        >
          {header}
        </Spacer>
      )}

      <AudioDurationUpsellBanner
        className={block('audio-duration-upsell-banner')}
        onUpgradeClick={onIncreaseDurationLimit}
        show={shouldShowDurationIncreaseBanner}
      />
      <Tabs
        activeTabKey={activeTab}
        className={block('tabs')}
        defaultActiveTabKey="upload"
        onTabSelect={onTabSelect}
        tabs={tabs}
      />
      {footer && <div className={block('footer')}>{footer}</div>}
    </div>
  );
};

AddAudioStep.defaultProps = {
  maxFileDurationSeconds: spareminConfig.rawAudioMaxDurationSeconds,
  onAudioAdded: noop,
  showTranscriptionForm: true,
};

export default AddAudioStep;
