import { AudioClip, AudioClipOffsets, ClippingOption } from 'types';
import { isSafari } from 'utils/device';
import SpareminAudio from './Audio';
import AudioEvent from './AudioEvent';

export function getDuration(
  src: string | File | Blob,
  cb?: (duration: number, err?: Error) => void,
) {
  const result = new Promise<number>((resolve, reject) => {
    const audio = new Audio();
    audio.volume = 0;
    const loadSource = () => {
      audio.src =
        src instanceof File || src instanceof Blob
          ? URL.createObjectURL(src)
          : src;
    };

    // HACK
    // https://stackoverflow.com/questions/34255345/how-to-get-audio-file-duration-in-safari
    // https://sparemin.atlassian.net/browse/SPAR-8641
    if (isSafari) {
      loadSource();
      audio
        .play()
        .then(() => {
          audio.pause();
          resolve(audio.duration);
        })
        .catch(err => reject(err));
    } else {
      audio.addEventListener('loadedmetadata', () => resolve(audio.duration));
      audio.addEventListener('error', () => reject(audio.error));
      loadSource();
    }
  });

  // if the caller passed a cb, call it and return nothing, otherwise return the promise
  if (!cb || typeof cb !== 'function') {
    return result;
  }

  result.then(duration => cb(duration)).catch(err => cb(undefined, err));
  return undefined;
}

function isAudioClip(audioClip: any): audioClip is AudioClip {
  return audioClip.source && audioClip.originalDurationMillis === undefined;
}

export const createClippingOption = (
  audioClip: AudioClip | AudioClipOffsets,
): ClippingOption => {
  const originalDurationMillis = isAudioClip(audioClip)
    ? audioClip.source.duration * 1000
    : audioClip.originalDurationMillis;

  const clipDurationMillis = audioClip.endMillis - audioClip.startMillis;

  return {
    clipDuration: clipDurationMillis,
    clipEnd: audioClip.endMillis,
    clipped: originalDurationMillis - clipDurationMillis !== 0,
    clipStart: audioClip.startMillis,
  };
};

export default SpareminAudio;
export * from './Audio';
export * from './export';
export {
  IAudioSourceConfig as AudioSourceConfig,
  IAudioTransition as AudioTransition,
} from './types';
export { AudioEvent };
