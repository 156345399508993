import {
  isCircleWaveType,
  isCustomWaveType,
} from 'redux/modules/display-pref/utils';
import { Dimensions, Size, SoundwaveType } from 'types';
import {
  Measurement,
  Pixels,
  ViewportHeight,
  ViewportWidth,
} from 'utils/measurement';
import { getRatio } from 'utils/numbers';
import {
  hasDimensions,
  measurementToPx,
  measurementToViewport,
  replaceRect,
} from 'utils/placement';
import {
  SoundwaveState,
  VideoTemplateStateContent,
  WaveformPlacement,
} from '../types';
import {
  MEASUREMENT_ERROR_MARGIN,
  PADDED_HEIGHT,
  PADDED_OFFSET,
  PADDED_WIDTH,
  PADDED_WIDTH_CIRCLE,
  PADDED_WIDTH_DOTS,
} from './constants';

/*
 * checks equality within a margin of error.
 *
 * when dragging the waveform all the way to the bottom of the frame, the resulting
 * top offset is not always frameHeight - waveformHeight.  there is typically a
 * < 1.0 difference between the calculated position and what we would consider
 * "bottom" alignment.
 *
 * TODO - figure out why this is happening
 */
function eq(v1: Measurement<any, any>, v2: Measurement<any, any> | number) {
  return v1.eq(v2, MEASUREMENT_ERROR_MARGIN);
}

/**
 * soundwaves have specific aspect ratios they should maintain when displayed
 * in the UI.  this is needed for consistency with how the backend renders the
 * waveform.  dimensions are specified in viewport units.
 *
 * enjoy the mess :)
 *
 * @param {Number} frameRatio the aspect ratio of the video frame
 */
export function getSoundwaveSize(
  waveType: SoundwaveType,
  frameRatio: number,
): [ViewportWidth, ViewportHeight] {
  // linear dots has it's on very special aspect ratio
  if (isCustomWaveType(waveType)) {
    return [new ViewportWidth(33), new ViewportHeight(15)];
  }

  // square waveform
  if (isCircleWaveType(waveType)) {
    // square frame
    if (frameRatio === 1) {
      // return [measurement('50vw'), measurement('50vh')];
      return [new ViewportWidth(50), new ViewportHeight(50)];
    }

    // portrait frame
    if (frameRatio < 1) {
      // return [measurement('50vw'), measurement('28.17vh')];
      return [new ViewportWidth(50), new ViewportHeight(28.17)];
    }

    // landscape frame
    if (frameRatio > 1) {
      // return [measurement('28.125vw'), measurement('50vh')];
      return [new ViewportWidth(28.125), new ViewportHeight(50)];
    }
  }

  // landscape waveforms.  the originals.
  return [new ViewportWidth(100), new ViewportHeight(30)];
}

/*
 * after resing a waveform, one or more of its edges might be out of the frame.
 * this function attempts to bring those parts of the waveform back into the frame
 */
function contain(
  container: Size<Pixels>,
  dimensions: Dimensions<Pixels>,
): Dimensions<Pixels> {
  const result = { ...dimensions };

  const right = dimensions.left.plus(dimensions.width);
  const bottom = dimensions.top.plus(dimensions.height);

  const rightOverflow = right.minus(container.width);
  if (rightOverflow.value > 0) {
    result.left = result.left.minus(rightOverflow);
  }

  if (dimensions.left.value < 0) {
    result.left = new Pixels(0);
  }

  const bottomOverflow = bottom.minus(container.height);
  if (bottomOverflow.value > 0) {
    result.top = result.top.minus(bottomOverflow);
  }

  if (dimensions.top.value < 0) {
    result.top = new Pixels(0);
  }

  return result;
}

export function soundwavePlacementToDimensions(
  soundwave: SoundwaveState,
  frameRatio: number,
  { alignment, size }: WaveformPlacement,
) {
  const [width, height] = getRatio(frameRatio);
  const frameHeight = new Pixels(height);
  const frameWidth = new Pixels(width);

  const soundwaveLeft = soundwave.left.toUnit('px', frameWidth);
  const soundwaveTop = soundwave.top.toUnit('px', frameHeight);
  const soundwaveWidth = soundwave.width.toUnit('px', frameWidth);
  const soundwaveHeight = soundwave.height.toUnit('px', frameHeight);

  const result = {
    height: soundwaveHeight,
    left: soundwaveLeft,
    top: soundwaveTop,
    width: soundwaveWidth,
  };

  if (size === 'fill') {
    const defaultSize = getSoundwaveSize(soundwave.type, frameRatio);
    const defaultWidth = defaultSize[0].toUnit('px', frameWidth);
    const defaultHeight = defaultSize[1].toUnit('px', frameHeight);

    const scale = Math.min(
      frameWidth.divideBy(defaultWidth).value,
      frameHeight.divideBy(defaultHeight).value,
    );

    result.height = defaultHeight.times(scale);
    result.width = defaultWidth.times(scale);
  }

  if (size === 'padded') {
    if (isCircleWaveType(soundwave.type)) {
      result.width = PADDED_WIDTH_CIRCLE.toUnit('px', frameWidth);
      result.height = result.width;
    } else if (isCustomWaveType(soundwave.type)) {
      result.width = PADDED_WIDTH_DOTS.toUnit('px', frameWidth);
      result.height = result.width.divideBy(
        soundwaveWidth.divideBy(soundwaveHeight),
      );
    } else {
      result.width = PADDED_WIDTH.toUnit('px', frameWidth);
      result.height = PADDED_HEIGHT.toUnit('px', frameHeight);
    }
  }

  if (alignment) {
    if (alignment === 'top') {
      if (size === 'padded') {
        result.top = PADDED_OFFSET.toUnit('px', frameHeight);
      } else {
        result.top = new Pixels(0);
      }
    }

    if (alignment === 'middle') {
      result.top = frameHeight.minus(result.height).divideBy(2);
    }

    if (alignment === 'bottom') {
      if (size === 'padded') {
        const bottomPadding = PADDED_OFFSET.toUnit('px', frameHeight);
        result.top = frameHeight.minus(result.height).minus(bottomPadding);
      } else {
        result.top = frameHeight.minus(result.height);
      }
    }

    result.left = frameWidth.minus(result.width).divideBy(2);
  }

  const contained = contain(
    {
      width: frameWidth,
      height: frameHeight,
    },
    result,
  );

  return {
    height: contained.height.toUnit('vh', frameHeight),
    left: contained.left.toUnit('vw', frameWidth),
    width: contained.width.toUnit('vw', frameWidth),
    top: contained.top.toUnit('vh', frameHeight),
  };
}

export function soundwaveDimensionsToPlacement(
  soundwave: SoundwaveState,
): WaveformPlacement {
  const result: WaveformPlacement = {};

  if (!soundwave || !hasDimensions(soundwave)) return undefined;

  const vw = new ViewportWidth(100);
  const vh = new ViewportHeight(100);

  const isHorizontallyCentered = eq(
    soundwave.left,
    vw.divideBy(2).minus(soundwave.width.divideBy(2)),
  );

  if (isHorizontallyCentered) {
    if (eq(soundwave.top, 0)) {
      result.alignment = 'top';
    }

    if (eq(soundwave.top, vh.minus(soundwave.height).divideBy(2))) {
      result.alignment = 'middle';
    }

    if (eq(soundwave.top, vh.minus(soundwave.height))) {
      result.alignment = 'bottom';
    }
  }

  if (eq(soundwave.height, 100) || eq(soundwave.width, 100)) {
    result.size = 'fill';
  }

  if (
    (isCircleWaveType(soundwave.type) &&
      eq(soundwave.width, PADDED_WIDTH_CIRCLE)) ||
    (isCustomWaveType(soundwave.type) &&
      eq(soundwave.width, PADDED_WIDTH_DOTS)) ||
    eq(soundwave.width, PADDED_WIDTH)
  ) {
    result.size = 'padded';

    if (isHorizontallyCentered) {
      if (eq(soundwave.top, vh.minus(soundwave.height.plus(PADDED_OFFSET)))) {
        result.alignment = 'bottom';
      } else if (eq(soundwave.top, PADDED_OFFSET)) {
        result.alignment = 'top';
      } else if (result.alignment === 'bottom' || result.alignment === 'top') {
        result.alignment = undefined;
      }
    }
  }

  return result;
}

/**
 * calculates size of soundwave when replacing one type with another, possibly
 * with a different aspect ratio
 */
export function getReplacementSoundwaveDimensions(
  state: VideoTemplateStateContent,
  newType: SoundwaveType,
): Pick<SoundwaveState, 'top' | 'left' | 'height' | 'width'> {
  const { aspectRatio, canvas, soundwave } = state;

  const { height, left, top, type, width } = soundwave;

  if (type === newType || newType === 'none') {
    return { height, left, top, width };
  }

  const newTypeOriginalDims = getSoundwaveSize(newType, aspectRatio);

  if (!hasDimensions(soundwave)) {
    return {
      height: newTypeOriginalDims[1],
      left: new ViewportWidth(0),
      top: new ViewportHeight(0),
      width: newTypeOriginalDims[0],
    };
  }

  return measurementToViewport(
    replaceRect(
      measurementToPx(soundwave, canvas),
      measurementToPx(
        { width: newTypeOriginalDims[0], height: newTypeOriginalDims[1] },
        canvas,
      ),
      canvas,
    ),
    canvas,
  );
}
