import cn from 'classnames';
import * as React from 'react';
import { isUndefined, noop } from 'underscore';

import Draggable, {
  DragCallbackData,
  DraggableProps,
} from 'components/Draggable';
import { ArgsOf } from 'types';
import { cloneOnlyChild } from 'utils/react';
import { block } from '../../utils';
import TrackAssetBody from './TrackAssetBody';
import TrackAssetDragHandles, {
  ResizeCallback,
  TrackAssetDragHandlesProps,
} from './TrackAssetDragHandles';

interface Position {
  x: number;
  y: number;
}

type DragCallback = (
  data: DragCallbackData,
  e: React.MouseEvent<HTMLElement>,
) => void;

type PickedDragHandlesProps = Pick<
  TrackAssetDragHandlesProps,
  'handleClassName' | 'onResize' | 'onResizeStart'
>;

export type TrackAssetResizeStopCallback = (
  resized: boolean,
  ...args: ArgsOf<ResizeCallback>
) => any;

export interface TrackAssetProps extends PickedDragHandlesProps {
  children?: React.ReactElement;
  dragBounds?: {
    left: number;
    right: number;
  };
  dragParentHandle?: string;
  id: string;
  itemClassName?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onDrag?: DragCallback;
  onDragStart?: DragCallback;
  /*
   * onDragStop should probably just be DragCallback, but some work is needed
   * in Draggable to support that and historically the dragData hasn't been
   * needed in the drag stop callback.
   * */
  onDragStop?: (e: React.MouseEvent<HTMLElement>, moved: boolean) => void;
  // onDragStop?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLElement>) => void;
  onResizeStop?: TrackAssetResizeStopCallback;
  /*
   * higher number brings the asset forward
   */
  order?: number;
  position?: Position;
  pxPerSec: number;
  sizeBounds?: {
    maxWidth?: number;
    minWidth?: number;
  };
  width: number;
}

interface State {
  dragging: boolean;
  resizing: boolean;
}

export default class TrackAsset extends React.Component<
  TrackAssetProps,
  State
> {
  public static defaultProps: Partial<TrackAssetProps> = {
    dragBounds: {
      left: 0,
      right: Infinity,
    },
    onClick: noop,
    onDrag: noop,
    onDragStart: noop,
    onDragStop: noop,
    onMouseDown: noop,
    onResizeStart: noop,
    onResizeStop: noop,
    position: { x: 0, y: 0 },
    sizeBounds: {
      maxWidth: Infinity,
      minWidth: 0,
    },
  };

  private dragged: boolean = false;
  private resized: boolean = false;

  public state: Readonly<State> = {
    dragging: false,
    resizing: false,
  };

  /**
   * stop propagation on body click so that it doesn't pass through to
   * underlying timeline.
   *
   * might be better for Timeline to make this decision, but it would take some
   * work to wire up a body click from an asset all the way up to the
   * Timeline component, so putting it here for now.
   *
   * NB: onClick is called in handleDragStop.  Draggable fires onStart on
   * mouse down and onStop on mouse up, so it's the same as a click if the
   * asset hasn't moved.
   */
  private handleClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
  };

  private handleDrag: DraggableProps['onDrag'] = (e, dragData) => {
    this.props.onDrag(dragData, e);
    this.dragged = true;
  };

  private handleDragStart: DraggableProps['onStart'] = (e, dragData) => {
    const { onDragStart } = this.props;
    this.dragged = false;
    this.setDragging(true);
    onDragStart(dragData, e);
  };

  private handleDragStop: DraggableProps['onStop'] = e => {
    const { onDragStop, onClick } = this.props;
    onDragStop(e, this.dragged);
    this.setDragging(false);

    if (!this.dragged) {
      onClick(e);
    }
  };

  private handleResize: ResizeCallback = (...args) => {
    this.props.onResize(...args);
    this.resized = true;
  };

  private handleResizeStart: ResizeCallback = (...args) => {
    const { onResizeStart } = this.props;
    this.resized = false;
    this.setState({ resizing: true });
    onResizeStart(...args);
  };

  private handleResizeStop: ResizeCallback = (...args) => {
    const { onResizeStop } = this.props;
    this.setState({ resizing: false });
    onResizeStop(this.resized, ...args);
  };

  private setDragging(dragging: boolean) {
    this.setState({ dragging });
  }

  public render() {
    const {
      children,
      dragBounds,
      dragParentHandle,
      handleClassName,
      id,
      itemClassName,
      onMouseDown,
      order,
      position,
      pxPerSec,
      sizeBounds,
      width,
    } = this.props;
    const { dragging, resizing } = this.state;

    const containerId = `track-${id}-asset-container`;

    return (
      <Draggable
        bounds={dragBounds}
        handle={`#${containerId}`}
        onMouseDown={onMouseDown}
        parentHandle={dragParentHandle}
        position={position}
        onDrag={this.handleDrag}
        onStart={this.handleDragStart}
        onStop={this.handleDragStop}
      >
        <TrackAssetBody
          dragging={dragging}
          id={containerId}
          itemClassName={itemClassName}
          onClick={this.handleClick}
          resizing={resizing}
          style={{
            position: 'absolute',
            width: 'auto',
            zIndex: isUndefined(order) ? 'auto' : order,
          }}
          width={width}
        >
          {cloneOnlyChild(children, child => ({
            className: cn(block('track-asset-inner'), child.props.className),
          }))}
          <TrackAssetDragHandles
            bounds={{
              maxPos: dragBounds.right,
              maxWidth: sizeBounds.maxWidth,
              minPos: dragBounds.left,
              minWidth: sizeBounds.minWidth,
            }}
            handleClassName={handleClassName}
            id={id}
            onResizeStart={this.handleResizeStart}
            onResizeStop={this.handleResizeStop}
            onResize={this.handleResize}
            position={{
              left: position.x,
              right: position.x + width,
            }}
            pxPerSec={pxPerSec}
          />
        </TrackAssetBody>
      </Draggable>
    );
  }
}
