import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { selectors as embedSelectors } from 'redux/modules/embed';
import { updateManualTranscriptFromUpload } from 'redux/modules/embed/actions/captions';
import * as transcriptActions from 'redux/modules/embed/actions/transcript';
import { onFileUpload } from 'redux/modules/mixpanel/actions';
import * as notificationActions from 'redux/modules/notification';
import { Dispatch, State } from 'redux/types';
import { ApplicationError } from 'utils/ApplicationError';
import { DropZoneType } from 'utils/constants';
import { UploadCaptionsModalProps } from './UploadCaptionsModal';

type StateProps = Pick<UploadCaptionsModalProps, 'uploadInProgress'>;
type DispatchProps = Pick<
  UploadCaptionsModalProps,
  'onCaptionAccepted' | 'onError'
>;

const captionUploadStatusSelector = createSelector(
  embedSelectors.captionUploadStatusSelector,
  isUploading => isUploading === true,
);

const mapStateToProps = (state: State): StateProps => ({
  uploadInProgress: captionUploadStatusSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onCaptionAccepted: (file: File) => {
    dispatch(onFileUpload(DropZoneType.EDITOR_CAPTION, file));

    return dispatch(transcriptActions.importManualTranscript(file))
      .then(res => dispatch(updateManualTranscriptFromUpload(res)))
      .catch(() =>
        dispatch(
          notificationActions.showError(
            'Something went wrong, please try again later.',
            5,
          ),
        ),
      );
  },
  onError: (error: ApplicationError, file: File) => {
    dispatch(onFileUpload(DropZoneType.EDITOR_CAPTION, file, error.message));
    dispatch(
      notificationActions.showError({
        message: error.message,
        code: error.code,
        dismissAfterSec: 5,
      }),
    );
  },
});

export default function(
  component: React.ComponentType<UploadCaptionsModalProps>,
) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
