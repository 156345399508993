import cn from 'classnames';
import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import ExportCompleteModalContents from '../../components/ExportCompleteModalContents';
import { block } from '../../utils';
import VideoExportModalContents from '../VideoExportModalContents';

export interface VideoExportModalProps {
  className?: string;
  isExported?: boolean;
}

const VideoExportModal: React.SFC<VideoExportModalProps> = ({
  className,
  isExported,
}) => {
  return (
    <ConnectedModal
      backdrop="static"
      className={cn(block(), className)}
      name="VideoExportModal"
      title="Export"
    >
      {isExported ? (
        <ExportCompleteModalContents />
      ) : (
        <VideoExportModalContents />
      )}
    </ConnectedModal>
  );
};

export default VideoExportModal;
