import * as React from 'react';
import { noop } from 'underscore';

import { Square } from 'components/icons';
import { PopperRadio } from 'components/Popper';
import Popper from 'components/Popper/Popper';
import PopperVerticalAlignRadio, {
  PopperVerticalAlignRadioValue,
} from '../Popper/PopperVerticalAlignRadio';
import { ProgressPlacement, ProgressSize } from '../types';
import { progressPlacementTileBlock as block } from '../utils';

const { useCallback } = React;

export interface ProgressPlacementPopperProps {
  onChange?: (value: ProgressPlacement) => void;
  value: ProgressPlacement;
}

const ProgressPlacementPopper: React.FC<ProgressPlacementPopperProps> = ({
  onChange = noop,
  value,
}) => {
  const handleAlignmentChange = useCallback(
    (alignment: PopperVerticalAlignRadioValue) => {
      onChange({
        ...value,
        alignment,
      });
    },
    [onChange, value],
  );

  const handleSizeChange = useCallback(
    (size: ProgressSize) => {
      onChange({
        ...value,
        size,
      });
    },
    [onChange, value],
  );

  return (
    <Popper className={block('popper')}>
      <PopperVerticalAlignRadio
        value={value.alignment}
        onChange={handleAlignmentChange}
      />
      <PopperRadio
        onChange={handleSizeChange}
        options={[
          {
            icon: <Square style={{ height: 4, width: 4 }} />,
            value: 'small',
          },
          {
            icon: <Square style={{ height: 5, width: 6 }} />,
            value: 'medium',
          },
          {
            icon: <Square style={{ height: 10, width: 10 }} />,
            value: 'large',
          },
        ]}
        value={value.size}
      />
    </Popper>
  );
};

export default ProgressPlacementPopper;
