import React from 'react';
import { noop } from 'underscore';

import { CaptionsConfig } from 'blocks/TextOverlayModal/v2';
import { CAPTION_STYLE_FIT_TEXT } from 'utils/constants';

import { useCanvasSize } from '../../context/CanvasSizeContext';
import { useNavigation } from '../../context/NavigationContext';
import { useTemplateDispatch } from '../../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../../context/VideoTemplateStateContext';
import { useModalManager } from '../../ModalManager';
import { CaptionsState } from '../../types';
import { canvasBlock as block } from '../../utils';
import RndAsset, { RndAssetCallback } from '../RndAsset';
import { getCaptionStyle } from '../utils';
import useCaptionsAssetAdjust from './useCaptionsAssetAdjust';

interface CaptionsAssetProps {
  captionsOverride: CaptionsState;
}

const ASSET_ID = 'captions-asset';

const CaptionsAsset: React.FunctionComponent<CaptionsAssetProps> = props => {
  const { captionsOverride } = props;

  const [, send] = useNavigation();
  const { captions, transcription } = useTemplateState();
  const dispatch = useTemplateDispatch();
  const { toPx } = useCanvasSize();
  const modalManager = useModalManager();

  const trancribeEnabled = transcription?.transcribe;

  const handleDimensionsChange: RndAssetCallback = React.useCallback(
    value => {
      dispatch({
        type: 'CAPTIONS_DIMENSIONS_CHANGE',
        payload: {
          dimensions: value,
        },
      });
    },
    [dispatch],
  );

  const handleMouseDown = React.useCallback(() => {
    send({
      type: 'CHILD_VIEW_OPEN',
      payload: 'captions',
      meta: { source: 'preview' },
    });
  }, [send]);

  const handleDoubleClick = React.useCallback(() => {
    modalManager.showModal('edit-captions', {
      captionsOverride: captions,
      onSubmit: (captionsConfig: CaptionsConfig) => {
        dispatch({
          type: 'CAPTIONS_CONFIG_UPDATE',
          payload: {
            captions: captionsConfig,
          },
        });
      },
    });
  }, [captions, dispatch, modalManager]);

  const captionsStyle = React.useMemo(() => {
    return getCaptionStyle(captionsOverride, toPx);
  }, [captionsOverride, toPx]);

  const {
    minimumSize,
    onResize,
    onResizeStart,
    onResizeStop,
    truncatedTextValue,
  } = useCaptionsAssetAdjust({
    captionsState: captionsOverride,
    textValue: CAPTION_STYLE_FIT_TEXT,
  });

  if (!trancribeEnabled || !captionsStyle) {
    return null;
  }

  const { height, left, top, width, ...style } = captionsStyle;

  return (
    <RndAsset
      assetId="captions-asset"
      bounds="parent"
      enableResizing
      height={height}
      left={left}
      lockAspectRatio={false}
      minSizePx={minimumSize}
      onDragStop={handleDimensionsChange}
      onResize={onResize}
      onResizeStart={onResizeStart(noop)}
      onResizeStop={onResizeStop(handleDimensionsChange)}
      onMouseDown={handleMouseDown}
      params={{ id: ASSET_ID }}
      resizeWhenBelowMin
      top={top}
      width={width}
    >
      <p
        className={block('captions-asset')}
        onDoubleClick={handleDoubleClick}
        style={style}
      >
        <span style={captionsOverride.textStyle}>{truncatedTextValue}</span>
      </p>
    </RndAsset>
  );
};

export default CaptionsAsset;
