import cn from 'classnames';
import * as React from 'react';
import HistoricalColorPicker from 'containers/HistoricalColorPicker';

import { createChainedFunction } from 'utils/functions';
import { useEditorDispatch } from './context/VideoEditorDispatchContext';
import { block, colorPickerSwatchClassName } from './utils';

const { useCallback } = React;

export type VideoTemplateEditorColorPickerProps = React.ComponentProps<
  typeof HistoricalColorPicker
>;

const VideoTemplateEditorColorPicker: React.FC<VideoTemplateEditorColorPickerProps> = ({
  className,
  disabled,
  onClose,
  onOpen,
  swatchClassName,
  ...props
}) => {
  const dispatch = useEditorDispatch();

  const handleOpen = useCallback(() => {
    dispatch({ type: 'OVERLAY_OPEN' });
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch({ type: 'OVERLAY_CLOSE' });
  }, [dispatch]);

  return (
    <HistoricalColorPicker
      className={cn(block('color-picker'), className)}
      swatchClassName={cn(
        colorPickerSwatchClassName(disabled),
        swatchClassName,
      )}
      disabled={disabled}
      onClose={createChainedFunction(onClose, handleClose)}
      onOpen={createChainedFunction(onOpen, handleOpen)}
      {...props}
    />
  );
};

export default VideoTemplateEditorColorPicker;
