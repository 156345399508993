import { RecordOf } from 'immutable';
import * as React from 'react';

import FadingScrollBars from 'components/FadingScrollBars';
import { IRevision, IRevisionItemData } from '../types';
import { block } from '../utils';
import RevisionHistoryDropdownItem from './RevisionHistoryDropdownItem';

interface IProps {
  onClickRestoreToVersion: (item: RecordOf<IRevisionItemData>) => void;
  revisionHistory: IRevision;
  visible: boolean;
}

const RevisionHistoryDropdown: React.SFC<IProps> = ({
  onClickRestoreToVersion,
  revisionHistory,
  visible,
}) => (
  <div className={block('dropdown', { visible })}>
    <FadingScrollBars
      hideTracksWhenNotNeeded
      className={block('dropdown-fading-scroller')}
    >
      <ul className={block('dropdown-list')}>
        {revisionHistory
          .valueSeq()
          .sort(
            (a, b) => Number(b.get('updatedAt')) - Number(a.get('updatedAt')),
          )
          .map(item => (
            <RevisionHistoryDropdownItem
              key={String(item.get('embedConfigurationUuid'))}
              item={item}
              onClickRestoreToVersion={onClickRestoreToVersion}
            />
          ))}
      </ul>
    </FadingScrollBars>
  </div>
);

export default RevisionHistoryDropdown;
export { IProps as RevisionHistoryDropdownProps };
