import * as React from 'react';

import Button from 'components/Button';
import { useEditorState } from './context/VideoEditorStateContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import { CallToActionProps } from './types';
import { optionsBlock as block } from './utils';

const CallToAction: React.FC<CallToActionProps> = ({
  render,
  label = 'submit',
  onClick,
}) => {
  const { disabled } = useEditorState();
  const state = useTemplateState();

  const childProps = {
    disabled,
    label,
    onClick,
    state,
    className: block('cta'),
  };

  return render ? (
    render(childProps)
  ) : (
    <div className={childProps.className}>
      <Button
        disabled={childProps.disabled}
        fluid
        onClick={childProps.onClick}
        theme="submit"
      >
        {childProps.label}
      </Button>
    </div>
  );
};

export default CallToAction;
