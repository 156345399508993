import * as React from 'react';
import { useDispatch } from 'react-redux';

import DialogContent from 'components/DialogContent';
import IntroOutroGradient from 'components/icons/IntroOutroGradient';
import useContextualUpgrade from 'hooks/useContextualUpgrade';
import { onUnlockThisFeature } from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal';

import { block } from './utils';

const { useCallback } = React;

const IntroOutroUpsellModalContents = () => {
  const dispatch = useDispatch();

  const { onUpgrade } = useContextualUpgrade({ openModal: replaceModal });

  const handleUpgrade = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      dispatch(onUnlockThisFeature('AddIntroOutroInUCS'));
      onUpgrade();
    },
    [dispatch, onUpgrade],
  );

  return (
    <DialogContent
      actions={[
        {
          children: 'unlock this feature',
          key: 'unlock-cta',
          onClick: handleUpgrade,
          theme: 'rainbow',
        },
      ]}
      details="Add an intro to hook viewers before your main content begins and then drive ideal outcomes by adding a call to action in your outro."
      detailsClassName={block('details-text')}
      icon={<IntroOutroGradient />}
      title="Add intros and outros"
    />
  );
};

export default IntroOutroUpsellModalContents;
