import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { AutogramSubscriptionOptions, DeepPartial } from 'types';

export interface UseTwitterAutomationAuthenticatorResults {
  authenticate: () => Promise<DeepPartial<AutogramSubscriptionOptions>>;
}

export default function useTwitterAutomationAuthenticator(): UseTwitterAutomationAuthenticatorResults {
  const reduxDispatch = useDispatch<Dispatch>();

  const authenticate = useCallback(async (): Promise<
    DeepPartial<AutogramSubscriptionOptions>
  > => {
    const result = await reduxDispatch(
      pushModal({
        name: 'TwitterAutoPost',
      }),
    );

    if (result) {
      return {
        autopost: result,
      };
    }

    return undefined;
  }, [reduxDispatch]);

  return {
    authenticate,
  };
}
