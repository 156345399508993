import { createBrowserHistory } from 'history';

type Handler = (message: string) => Promise<boolean>;

let userConfirmationHandler: Handler | null = null;

export const setUserConfirmationHandler = (handler: Handler) => {
  userConfirmationHandler = handler;
};

export default createBrowserHistory({
  async getUserConfirmation(message, callback) {
    if (userConfirmationHandler) {
      callback(await userConfirmationHandler(message));
    } else {
      // eslint-disable-next-line no-alert
      callback(window.confirm(message));
    }
  },
});
