import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import _ from 'underscore';

import { VideoPosition } from 'blocks/VideoCropper';
import { Values as VideoFormState } from 'components/VideoFormWithPreview';
import ConnectedEditAssetModal from 'containers/ConnectedModal/ConnectedEditAssetModal';
import { VideoScaling } from 'utils/embed/video';
import { Omit } from '../../types';
import EditVideoModalContents from './EditVideoModalContents';

type VideoState = Omit<VideoFormState, 'clipStartMillis' | 'clipEndMillis'>;

export { VideoState };

interface IVideo {
  id: string;
  startMillis: number;
  endMillis: number;
  audioLevel: number;
  mainAudioLevel: number;
  audioFadeInDurationMillis: number;
  audioFadeOutDurationMillis: number;
  transitionIn: string;
  transitionOut: string;
  scaling: VideoScaling;
  src: string;
  blurredBackground: boolean;
  position?: VideoPosition;
  height?: number;
  width?: number;
  zoom: number;
}

export interface IProps {
  aspectRatio: number;
  video: RecordOf<IVideo>;
  onDelete?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onSave?: (video: VideoState, e: React.MouseEvent<HTMLButtonElement>) => void;
  onReplace?: () => void;
  onReplaceHide?: () => void;
  show?: boolean;
  showAudioSection?: boolean;
}

interface IDataState {
  isClosing: boolean;
  metadataLoaded: boolean;
  showAudioSection: boolean;
}

interface IState {
  data: RecordOf<IDataState>;
}

const dataFactory = Record<IDataState>({
  isClosing: undefined,
  metadataLoaded: false,
  showAudioSection: undefined,
});

export default class EditVideoModal extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    onDelete: _.noop,
    show: false,
    showAudioSection: true,
  };

  constructor(props: IProps) {
    super(props);

    const { showAudioSection } = props;

    this.state = {
      data: dataFactory({
        showAudioSection,
        isClosing: false,
      }),
    };
  }

  private handleExited = () => {
    const { showAudioSection } = this.props;

    this.setState(({ data }) => ({
      data: data.withMutations(d => {
        d.set('isClosing', false);

        if (showAudioSection !== d.showAudioSection) {
          d.set('showAudioSection', showAudioSection);
        }

        return d;
      }),
    }));
  };

  public render() {
    return (
      <ConnectedEditAssetModal
        backdrop="static"
        className="edit-video-modal"
        name="EditVideoModal"
        onExited={this.handleExited}
        title="Video Properties"
      >
        <EditVideoModalContents />
      </ConnectedEditAssetModal>
    );
  }
}
