import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useRedeemPromotion } from 'blocks/Pricing';
import PostDownloadJoyride from 'blocks/ProjectsPage/components/PostDownloadJoyride';
import { ProjectType } from 'blocks/ProjectsPage/types';
import Page from 'components/Page';
import SocialIconsBottomBar from 'containers/SocialIconsBottomBar';
import useOnMount from 'hooks/useOnMount';
import { getMyAnnouncements } from 'redux/modules/announcement/actions';
import { clearPodcastFeed } from 'redux/modules/automation-cms';
import { isWorkflowLimitReachedSelector } from 'redux/modules/automation-cms/selectors';
import { getMyDisplayPref } from 'redux/modules/display-pref';
import { clearEmbedExport } from 'redux/modules/embed-export/actions';
import {
  onClickAutomationTile,
  onClickTranscribe,
  onCreateManualAudiogram,
} from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal/actions';
import {
  clearProjectHistory,
  getProjectsCount,
} from 'redux/modules/project/actions';
import ProjectsPageTabs from '../../components/ProjectsPageTabs';
import { block } from '../../utils';
import ProjectsPageModalManager from '../ProjectsPageModalManager';

const { useCallback } = React;

export type ProjectsPageProps = {};

const ProjectsPage: React.FC<ProjectsPageProps> = () => {
  const dispatch = useDispatch();

  useOnMount(
    () => {
      dispatch(clearEmbedExport());
      dispatch(getMyDisplayPref());
      dispatch(getProjectsCount());
      dispatch(getMyAnnouncements());
    },
    () => {
      dispatch(clearProjectHistory());
      dispatch(clearPodcastFeed());
    },
  );

  useRedeemPromotion();

  const handleNewProjectClick = useCallback(
    (type: ProjectType, _, e: React.MouseEvent<any>) => {
      if (type === 'blank') {
        e.preventDefault();
        dispatch(
          pushModal({
            name: 'BlankProject',
          }),
        );
      }

      const isWorkflowLimitReached = dispatch((__, getState) =>
        isWorkflowLimitReachedSelector(getState()),
      );

      if (type === 'autovideo' && isWorkflowLimitReached) {
        e.preventDefault();
        dispatch(
          pushModal({
            name: 'AddAutomationUpgrade',
            params: { podcastId: null },
          }),
        );
        dispatch(onClickAutomationTile());
      }

      if (type === 'manual') {
        e.preventDefault();
        dispatch(
          pushModal({
            name: 'AudiogramDestination',
          }),
        );
        dispatch(onCreateManualAudiogram());
      }

      if (type === 'transcribe') {
        dispatch(onClickTranscribe());
      }
    },
    [dispatch],
  );

  return (
    <Page className={block()} fillViewport>
      <ProjectsPageTabs onNewProjectClick={handleNewProjectClick} />
      <SocialIconsBottomBar />
      <ProjectsPageModalManager />
      <PostDownloadJoyride />
    </Page>
  );
};

export default ProjectsPage;
