import * as React from 'react';
import uncontrollable from 'uncontrollable';

import NumericSelect, { NumericSelectProps } from './NumericSelect';

export type UncontrollableProps = NumericSelectProps & {
  defaultIsOpen?: NumericSelectProps['isOpen'];
  defaultValue?: NumericSelectProps['value'];
};

const uncontrolled: React.ComponentType<UncontrollableProps> = uncontrollable(
  NumericSelect,
  {
    isOpen: 'onToggleOpen',
    value: 'onChange',
  },
);

export default uncontrolled;
export { UncontrollableProps as NumericSelectProps };
