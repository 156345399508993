import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EpisodeListRow from 'blocks/AutomationCMS/components/EpisodeListRow';
import Carousel from 'components/Carousel';
import { H3 } from 'components/Heading';
import LinkButton from 'components/LinkButton';
import Modal from 'components/Modal';
import useSet from 'hooks/useSet';
import { createEpisodeVideo } from 'redux/modules/automation-cms/actions';
import {
  isWorkflowLimitReachedSelector,
  podcastFeedDataSelector,
} from 'redux/modules/automation-cms/selectors';
import { PodcastFeedData } from 'redux/modules/automation-cms/types';
import {
  onAutoCmsBackCatalogModalSubmitButton,
  onNewAutomation,
} from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal/actions';
import { showError } from 'redux/modules/notification/actions';
import { Dispatch, State } from 'redux/types';
import usePodcastFeedEpisode from '../../../../hooks/usePodcastFeedEpisode';
import { workflowSelectModalBlock as block } from '../../utils';
import AutomationCMSModalContext from '../AutomationCMSRich/AutomationCMSModalContext';
import AutomationWorkflowOption from './AutomationWorkflowOption';
import { Props } from './types';

const { useContext } = React;

const AutomationWorkflowSelectModal: React.FC<Props> = props => {
  const { onCancel, onCreate, show, podcastId, episodeId } = props;
  const [set, { toggle, reset }] = useSet<number>();
  const episode = usePodcastFeedEpisode(episodeId);
  const modalDispatch = useContext(AutomationCMSModalContext);
  const dispatch = useDispatch<Dispatch>();
  const feedData = useSelector<State, PodcastFeedData>(state =>
    podcastFeedDataSelector(state)?.get(podcastId),
  );

  const onSubmit = async () => {
    try {
      const workflowIds = [...set];
      onCreate(workflowIds);
      await dispatch(createEpisodeVideo(episodeId, workflowIds));
      dispatch(onAutoCmsBackCatalogModalSubmitButton(workflowIds));
      reset();
    } catch {
      dispatch(
        showError(
          `Could not create videos for ${episode?.title ?? 'episode'}.`,
          5,
          undefined,
          'Error creating videos',
        ),
      );
    }
  };

  const onCreateNew = () => {
    dispatch(onNewAutomation('Back Catalog Modal'));

    const isWorkflowLimitReached = dispatch((_, getState) =>
      isWorkflowLimitReachedSelector(getState()),
    );

    if (isWorkflowLimitReached) {
      modalDispatch({ type: 'reset' });
      dispatch(
        pushModal({
          name: 'AddAutomationUpgrade',
          params: { podcastId },
        }),
      );
    } else {
      modalDispatch({
        type: 'show-workflow-create',
        payload: {
          episodeId,
        },
      });
    }
  };

  return (
    <Modal
      className={block()}
      onHide={onCancel}
      show={show}
      title="Automate this episode"
      size="lg"
    >
      <Modal.Body className={block('body')}>
        <EpisodeListRow.Container className={block('episode')} collapsible>
          <EpisodeListRow.Artwork>
            <img src={episode?.thumbnailImageUrl} />
          </EpisodeListRow.Artwork>
          <EpisodeListRow.Description
            primary={
              <H3 className={block('episode-title')}>{episode?.title}</H3>
            }
            secondary={
              <p className={block('episode-details')}>
                To get videos for this episode select at least one automation
                below or{' '}
                <LinkButton theme="cta" onClick={onCreateNew}>
                  create a new one
                </LinkButton>
                .
              </p>
            }
          />
        </EpisodeListRow.Container>
        <Carousel
          dots={false}
          slidesToShow={1}
          slidesToScroll={1}
          variableWidth
        >
          {feedData?.workflows?.map(workflowId => (
            <AutomationWorkflowOption
              key={workflowId}
              {...{ workflowId }}
              onToggle={toggle}
              checked={set.has(workflowId)}
            />
          ))}
        </Carousel>
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButtons>
          <Modal.FooterButton
            onClick={onSubmit}
            disabled={!set.size}
            theme="submit"
          >
            {set.size > 1
              ? `Create ${set.size} videos using these automations`
              : 'Create a video using this automation'}
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

export default AutomationWorkflowSelectModal;
