import accept from 'attr-accept';
import {
  FileExtension,
  FileTypeResult,
  fromBlob,
  fromStream,
} from 'file-type/browser';
import { isSafari } from 'react-device-detect';
import { isString } from 'underscore';
import { isFileLike } from './file';

export const SUPPORTED_WATERMARK_IMAGE_TYPES = ['.png', '.jpg'];

export const SUPPORTED_IMAGE_TYPES = ['.jpg', '.png', '.gif', '.svg'];

export const SUPPORTED_VIDEO_TYPES = ['.mp4', '.mov', '.webm'];

export const SUPPORTED_AUDIO_TYPES = ['.mp3', '.wav', '.m4a'];

export const SUPPORTED_FONT_TYPES = [
  '.ttf',
  '.otf',
  '.eot',
  '.svg',
  '.woff',
  '.woff2',
];

export const SUPPORTED_VIDEO_MIME_TYPES: Readonly<string[]> = [
  'video/mp4',
  'video/quicktime',
  'video/webm',
];

export const getSupportedVideoMimeTypes = () =>
  SUPPORTED_VIDEO_MIME_TYPES.filter(
    mimeType => !(mimeType.includes('webm') && isSafari),
  );

export const getSupportedVideoTypes = () =>
  SUPPORTED_VIDEO_TYPES.filter(type => !(type.includes('webm') && isSafari));

export const getSupportedMediaMimeTypes = () => [
  'image/*',
  getSupportedVideoMimeTypes(),
];

export function getSupportedFontTypes() {
  SUPPORTED_FONT_TYPES.filter(
    fontType => !(fontType.includes('webm') && isSafari),
  );
}

export const getSupportedMediaTypes = () =>
  [].concat(SUPPORTED_IMAGE_TYPES, getSupportedVideoTypes());

export const isSupportedVideoFile = (file: File | string) => {
  if (!file) {
    return false;
  }

  if (isString(file)) {
    return accept({ name: file, type: '' }, getSupportedVideoTypes());
  }

  return accept(file, getSupportedVideoMimeTypes());
};

export const isSupportedAudioFile = (file: File | string) => {
  if (!file) {
    return false;
  }

  if (isString(file)) {
    return accept({ name: file, type: '' }, SUPPORTED_AUDIO_TYPES);
  }

  return accept(file, SUPPORTED_AUDIO_TYPES);
};

const BLACKLISTED_IMAGE_TYPES: FileExtension[] = ['jp2', 'jpm', 'jpx', 'mj2'];

export const isSupportedImageFile = async (source: File | string) => {
  let fileType: FileTypeResult;

  if (isFileLike(source)) {
    fileType = await fromBlob(source);
  }

  if (isString(source)) {
    const response = await fetch(source);
    fileType = await fromStream(response.body);
  }

  return !BLACKLISTED_IMAGE_TYPES.includes(fileType?.ext);
};
