import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FontAwesome from 'components/FontAwesome';
import Circular from 'components/icons/circular';
import Modal from 'components/Modal';
import NonIdealState from 'components/NonIdealState';
import {
  onExtractAudioFromVideo,
  onTranscribeVideoFile,
} from 'redux/modules/mixpanel/actions';
import { modalParamsSelector } from 'redux/modules/modal';
import { hideModal, replaceModal } from 'redux/modules/modal/actions';
import { getVideoTranscriptionWizardPath } from 'utils/routes';

function IncorrectFileTypeModalContent() {
  const dispatch = useDispatch();
  const params = useSelector(modalParamsSelector);

  const handleExtractClick = () => {
    dispatch(onExtractAudioFromVideo());
    dispatch(
      replaceModal({
        name: 'ExtractAudioFeatureFake',
      }),
    );
  };

  const handleTranscribeClick = () => {
    dispatch(onTranscribeVideoFile());
    dispatch(hideModal());
  };

  return (
    <>
      <NonIdealState
        title="Want to add a video file?"
        description={
          <>
            You’re actually in the wrong place, but we can easily fix that. Just
            let us know what you’re trying to do:
          </>
        }
        icon={
          <Circular variant="orange">
            <FontAwesome icon="video" size="sm" />
          </Circular>
        }
      />
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton theme="submit-alt" onClick={handleExtractClick}>
            Extract audio from video
          </Modal.FooterButton>
          <Modal.FooterButton
            theme="submit"
            componentClass={Link}
            onClick={handleTranscribeClick}
            // @ts-ignore
            to={getVideoTranscriptionWizardPath(params?.file)}
          >
            Transcribe this video
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
}

export default IncorrectFileTypeModalContent;
