import { useCallback, useRef } from 'react';
import * as ids from 'short-id';

import { useEditorDispatch } from './context/VideoEditorDispatchContext';
import { useEditorState } from './context/VideoEditorStateContext';

type UseEditorLoadingResult = [boolean, (isLoading: boolean) => void];

export default function useEditorLoading(): UseEditorLoadingResult {
  const idRef = useRef(ids.generate());
  const dispatch = useEditorDispatch();
  const { loadingIds } = useEditorState();

  const isEditorLoading = loadingIds?.includes(idRef.current);

  const setEditorLoading = useCallback(
    (loading: boolean) => {
      if (loading) {
        dispatch({
          type: 'EDITOR_LOADING_BEGIN',
          payload: { id: idRef.current },
        });
      } else {
        dispatch({
          type: 'EDITOR_LOADING_END',
          payload: { id: idRef.current },
        });
      }
    },
    [dispatch],
  );

  return [isEditorLoading, setEditorLoading];
}
