import { connect } from 'react-redux';

import { PropsOf } from 'types';
import EnterpriseSubscription, {
  EnterpriseSubscriptionProps,
} from './EnterpriseSubscription';

type StateProps = Pick<EnterpriseSubscriptionProps, 'title'>;

const mapStateToProps = (): StateProps => ({
  title: 'API',
});

const component = connect(mapStateToProps)(EnterpriseSubscription);
export type ApiSubscriptionProps = PropsOf<typeof component>;
export default component;
