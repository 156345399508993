import * as React from 'react';

import FontStyleField, { FontStyleFieldProps } from './FontStyleField';
import TextBoxColorField from './TextBoxColorField';
import TextBoxNumericField from './TextBoxNumericField';
import TextBoxSelectField from './TextBoxSelectField';

interface IOnChange {
  (value: Set<string>, field: 'fontStyle'): void;
  (value: number, field: 'fontSize'): void;
  (value: string, field: 'font' | 'fontColor'): void;
  (value: any, field: string): void;
}

interface IProps {
  color: string;
  name: string;
  fonts: string[];
  onChange?: IOnChange;
  size: number;
  style: FontStyleFieldProps['value'];
}

export default class FontOptions extends React.Component<IProps> {
  private handleFontChange = (font: string) =>
    this.props.onChange(font, 'font');

  private handleFontSizeChange = (size: number) =>
    this.props.onChange(size, 'fontSize');

  private handleFontColorChange = (color: string) =>
    this.props.onChange(color, 'fontColor');

  private handleStyleChange = (value: Set<string>) =>
    this.props.onChange(value, 'fontStyle');

  public render() {
    const { fonts, color, name, size, style } = this.props;

    return (
      <div>
        <TextBoxSelectField
          label="Font"
          onChange={this.handleFontChange}
          value={name}
          horizontal={{
            control: { xs: 7 },
            label: { xs: 5 },
          }}
        >
          {fonts.map(fname => (
            <option key={fname} value={fname}>
              {fname}
            </option>
          ))}
        </TextBoxSelectField>
        <TextBoxNumericField
          label="Font Size"
          min={1}
          max={100}
          maxLength={3}
          onChange={this.handleFontSizeChange}
          size={3}
          value={size}
        />
        <TextBoxColorField
          label="Font Color"
          onChange={this.handleFontColorChange}
          value={color}
        />
        <FontStyleField value={style} onChange={this.handleStyleChange} />
      </div>
    );
  }
}

export { IProps as FontOptionsProps };
