import React, { useCallback, useState } from 'react';

import { ModalFooterButton } from 'components/Modal';
import { Step } from 'components/SteppedModal';
import { IStep } from 'components/SteppedModal/types';

import {
  YoutubeManualPostAdditionalDetails,
  YoutubeManualPostAdditionalDetailsProps,
} from '../../YoutubeManualPostComposer';
import { AdditionalDetailsFormState } from './types';

type PickedYoutubePostAdditionalDetailsProps = Pick<
  YoutubeManualPostAdditionalDetailsProps,
  | 'categories'
  | 'categoryId'
  | 'language'
  | 'languages'
  | 'madeForKids'
  | 'onCategoryIdChange'
  | 'onLanguageChange'
  | 'onMadeForKidsChange'
  | 'onTagsChange'
  | 'onVisibilityChange'
  | 'tags'
  | 'visibility'
>;

interface UseAdditionalDetailsStepConfig
  extends PickedYoutubePostAdditionalDetailsProps,
    Pick<IStep, 'submitButtonLabel'> {
  isActive: boolean;
  onGoBack: () => void;
}

const useAdditionalDetailsStep = (
  config: UseAdditionalDetailsStepConfig,
): Step => {
  const {
    isActive,
    categories,
    categoryId,
    language,
    languages,
    madeForKids,
    onCategoryIdChange,
    onLanguageChange,
    onGoBack,
    onMadeForKidsChange,
    onTagsChange,
    onVisibilityChange,
    submitButtonLabel = 'save changes',
    tags,
    visibility,
  } = config;

  const [prevActiveState, setPrevActiveState] = useState<boolean>(isActive);

  const [draftState, setDraftState] = useState<AdditionalDetailsFormState>({
    categoryId,
    language,
    madeForKids,
    tags,
    visibility,
  });

  const resetDraftState = useCallback(() => {
    setDraftState({
      categoryId,
      language,
      madeForKids,
      tags,
      visibility,
    });
  }, [categoryId, language, madeForKids, tags, visibility]);

  const handleUpdateValue = <TDataKey extends keyof AdditionalDetailsFormState>(
    key: TDataKey,
  ) => (value: AdditionalDetailsFormState[TDataKey]): void => {
    setDraftState(currDraftState => ({
      ...currDraftState,
      [key]: value,
    }));
  };

  const handleGoBack = useCallback(() => {
    resetDraftState();
    onGoBack();
  }, [onGoBack, resetDraftState]);

  const handleSubmit = useCallback(() => {
    onCategoryIdChange(draftState.categoryId);
    onLanguageChange(draftState.language);
    onMadeForKidsChange(draftState.madeForKids);
    onTagsChange(draftState.tags);
    onVisibilityChange(draftState.visibility);
    onGoBack();
  }, [
    draftState,
    onCategoryIdChange,
    onLanguageChange,
    onGoBack,
    onMadeForKidsChange,
    onTagsChange,
    onVisibilityChange,
  ]);

  if (prevActiveState !== isActive) {
    resetDraftState();
    setPrevActiveState(isActive);
  }

  return {
    component: (
      <YoutubeManualPostAdditionalDetails
        categories={categories}
        categoryId={draftState.categoryId}
        language={draftState.language}
        isMetadataEnabled={false}
        languages={languages}
        madeForKids={draftState.madeForKids}
        onCategoryIdChange={handleUpdateValue('categoryId')}
        onClickBack={handleGoBack}
        onLanguageChange={handleUpdateValue('language')}
        onMadeForKidsChange={handleUpdateValue('madeForKids')}
        onTagsChange={handleUpdateValue('tags')}
        onVisibilityChange={handleUpdateValue('visibility')}
        tags={draftState.tags}
        visibility={draftState.visibility}
      />
    ),
    fluidFooterButtons: true,
    id: 'additional-details-step',
    onSubmit: handleSubmit,
    renderFooterButtons: ({ submit }) => [
      <ModalFooterButton {...submit} key="submit" theme="submit">
        {submitButtonLabel}
      </ModalFooterButton>,
    ],
  };
};

export default useAdditionalDetailsStep;
