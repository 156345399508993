import { fromJS, List } from 'immutable';
import * as React from 'react';

import TextBoxOptions, {
  DynamicStyleConfig,
  Padding,
  Position,
} from 'components/TextBoxOptions';
import { block } from '../../utils/block';
import { IContainerDecoratorStyle } from './types';

interface IProps {
  background?: string;
  containerDecoratorStyles?: List<IContainerDecoratorStyle>;
  lineHeight?: number;
  padding?: Padding;
  position?: Position;

  onDecoratorStylesChange?: (styles: List<IContainerDecoratorStyle>) => void;
  onBackgroundChange?: (background: string) => void;
  onLineHeightChange?: (lineHeight: number) => void;
  onPaddingChange?: (padding: Padding) => void;
  onPositionChange?: (position: Position) => void;
  onLineHeightBlur?: (lineHeight: number) => void;
  onPaddingBlur?: (padding: Padding) => void;
  onPositionBlur?: (position: Position) => void;
}

class StyleForm extends React.Component<IProps> {
  public static defaultProps = {
    containerDecoratorStyles: List(),
  };

  private handleDecoratorStyleChange = (index: number) => (
    dynamicStyle: DynamicStyleConfig[],
  ) => {
    const { containerDecoratorStyles, onDecoratorStylesChange } = this.props;
    onDecoratorStylesChange(
      containerDecoratorStyles.set(index, fromJS(dynamicStyle)),
    );
  };

  public render() {
    const {
      background,
      containerDecoratorStyles,
      lineHeight,
      onBackgroundChange,
      onLineHeightChange,
      onPaddingChange,
      onPositionChange,
      onLineHeightBlur,
      onPaddingBlur,
      onPositionBlur,
      padding,
      position,
    } = this.props;

    return (
      <TextBoxOptions className={block('font-form')}>
        {/* TODO these styles should be given an id so we don't have to use array index as key */
        /* eslint-disable */
        containerDecoratorStyles.toJS().map((style, index) => (
          <TextBoxOptions.DynamicStyle
            dynamicStyle={style}
            key={index}
            onChange={this.handleDecoratorStyleChange(index)}
          />
        ))
        /* eslint-enable */
        }
        <TextBoxOptions.Background
          value={background}
          onChange={onBackgroundChange}
        />
        <TextBoxOptions.LineSpacing
          value={lineHeight}
          onChange={onLineHeightChange}
          onBlur={onLineHeightBlur}
        />
        <TextBoxOptions.Padding
          value={padding}
          onChange={onPaddingChange}
          onBlur={onPaddingBlur}
        />
        <TextBoxOptions.Position
          value={position}
          onChange={onPositionChange}
          onBlur={onPositionBlur}
        />
      </TextBoxOptions>
    );
  }
}

export default StyleForm;
export { IProps as StyleFormProps };
