export enum Type {
  TEXT_TO_IMAGE_CREATE_REQUEST = 'app/text-to-image/TEXT_TO_IMAGE_CREATE_REQUEST',
  TEXT_TO_IMAGE_CREATE_SUCCESS = 'app/text-to-image/TEXT_TO_IMAGE_CREATE_SUCCESS',
  TEXT_TO_IMAGE_CREATE_FAILURE = 'app/text-to-image/TEXT_TO_IMAGE_CREATE_FAILURE',

  TEXT_TO_IMAGE_POLLING_BEGIN = 'app/text-to-image/TEXT_TO_IMAGE_POLLING_BEGIN',
  TEXT_TO_IMAGE_POLLING_SUCCESS = 'app/text-to-image/TEXT_TO_IMAGE_POLLING_SUCCESS',
  TEXT_TO_IMAGE_POLLING_FAILURE = 'app/text-to-image/TEXT_TO_IMAGE_POLLING_FAILURE',
  TEXT_TO_IMAGE_POLLING_END = 'app/text-to-image/TEXT_TO_IMAGE_POLLING_END',
}
