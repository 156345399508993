import * as React from 'react';
import { noop } from 'underscore';

import Button from 'components/Button';
import { ButtonShape } from './Button/Button';

export interface RefreshPageButtonProps {
  className?: string;
  children?: React.ReactNode;
  onClickRefreshButton?: () => void;
  shape?: ButtonShape;
}

export default class RefreshPageButton extends React.Component<
  RefreshPageButtonProps
> {
  public static defaultProps: Partial<RefreshPageButtonProps> = {
    children: 'Refresh Page',
    onClickRefreshButton: noop,
  };

  private handleClick = () => {
    const { onClickRefreshButton } = this.props;

    onClickRefreshButton();
    window.location.reload();
  };

  public render() {
    const { children, className, shape } = this.props;

    return (
      <Button
        className={className}
        onClick={this.handleClick}
        theme="submit"
        shape={shape}
        size="small"
      >
        {children}
      </Button>
    );
  }
}
