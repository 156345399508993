import { useContext } from 'react';
import SocialSectionContext from './SocialSectionContext';

export default function useSocialSection() {
  const context = useContext(SocialSectionContext);

  if (context === undefined) {
    throw new Error(
      'useSocialSection must be used within SocialSectionProvider',
    );
  }

  return context;
}
