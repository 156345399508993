import { compose } from 'redux';
import { isEmpty, omit } from 'underscore';

import {
  PodcastTemplateConfig,
  PodcastWorkflowTemplate,
} from 'redux/middleware/api/podcast-service';
import { KeyTextType } from 'types';
import {
  applyConfigTransforms,
  updateLayerReferences,
} from 'utils/embed/config-transforms';
import { isSpitfireConfig } from 'utils/embed/embed';
import {
  KeyTextAsset,
  KeyTextAssetIds,
  KeyTextAssetType,
  VideoTemplateEditorConfig,
  VideoTemplateState,
  VideoTemplateStateContent,
} from '../types';
import {
  getAspectRatio,
  getBackgroundColor,
  getCaptions,
  getHasCubeEffect,
  getIntroOutro,
  getProgress,
  getSlideshow,
  getSoundwave,
  getTextOverlays,
  getVideoClips,
  getWatermark,
} from './config-utils';
import {
  DEFAULT_BG_COLOR,
  DEFAULT_INTRO_OUTRO_STATE,
  DEFAULT_KEY_TEXT_ASSETS,
  DEFAULT_LAYER_STATE,
  DEFAULT_MAIN_IMAGE,
  DEFAULT_ORDERED_STATE,
  DEFAULT_PROGRESS_STATE,
  DEFAULT_SLIDESHOW_STATE,
} from './constants';
import { buildLayerState, identifyLayers } from './layer-utils';
import { createState, getStateContent } from './state-utils';

const DEFAULT_PATCH = {
  backgroundColor: undefined,
  hasCubeEffect: undefined,
  slideshow: undefined,
  soundwave: undefined,
  textOverlays: undefined,
  videoClips: undefined,
  watermark: undefined,
};

export function createEmptyState(aspectRatio: number): VideoTemplateState {
  return createState({
    aspectRatio,
    canvas: undefined,
    container: undefined,
    introOutro: DEFAULT_INTRO_OUTRO_STATE,
    layers: DEFAULT_LAYER_STATE,
    mainImage: DEFAULT_MAIN_IMAGE,
    originalLayers: DEFAULT_LAYER_STATE.order,
    patch: DEFAULT_PATCH,
    backgroundColor: DEFAULT_BG_COLOR,
    hasCubeEffect: false,
    slideEffectText: {},
    slideshow: DEFAULT_SLIDESHOW_STATE,
    soundwave: undefined,
    progress: DEFAULT_PROGRESS_STATE,
    textOverlays: DEFAULT_ORDERED_STATE,
    timer: undefined,
    videoClips: DEFAULT_ORDERED_STATE,
    watermark: undefined,
    transcription: {},
    ...DEFAULT_KEY_TEXT_ASSETS,
  });
}

function createStateFromKeyTextAssets(
  keyTextAssetIds: KeyTextAssetIds,
  type: KeyTextAssetType,
): { [k in KeyTextType]?: KeyTextAsset } {
  return Object.keys(keyTextAssetIds).reduce((acc, textAssetType) => {
    acc[textAssetType] = {
      id: keyTextAssetIds[textAssetType],
      type,
    };
    return acc;
  }, {} as any);
}

function getKeyTextAssets(
  overlayAssets: KeyTextAssetIds,
  slideTextAssets: KeyTextAssetIds,
): Pick<VideoTemplateStateContent, KeyTextType> {
  const keyOverlayAssets = createStateFromKeyTextAssets(
    overlayAssets,
    'textOverlay',
  );
  const keySlideTextAssets = createStateFromKeyTextAssets(
    slideTextAssets,
    'slideEffectText',
  );

  return { ...keyOverlayAssets, ...keySlideTextAssets };
}

// TODO: remove this function
// During implementation of hotfix/SPAR-19150, it was discovered that the backend
// is formatting the template config in a way that the front end doesn't expect,
// specifically the config.soundwave.isEnabled property should be called
// config.soundwave.enabled so that it aligns with the shape of the embed configuration.
// template configurations are a subset of the embed configuration, so objects should
// use the same keys.
//
// There is a backend ticket to fix this, however it is too high risk to do as part
// of a hotfix.
function fixTemplateConfig(
  config: PodcastTemplateConfig,
): PodcastTemplateConfig {
  if (!config.soundwave || isEmpty(config.soundwave)) {
    return config;
  }

  // @ts-ignore
  const { isEnabled, enabled, ...restSoundwave } = config.soundwave;

  return {
    ...config,
    soundwave: {
      ...restSoundwave,
      enabled: isEnabled ?? enabled,
    },
  };
}

/*
 * In the editor, you can have a waveform track with no waveform on it.  In the
 * configuration, this is modeled by having the waveform track id in config.layerOrder.
 * Since everything in the VideoTemplateEditor is added in a new layer, if the user
 * loads one of these configurations and then adds a waveform, an additional
 * waveform layer will get added, causing issues when the template/project is opened
 * (multiple waveform tracks are not supported).
 *
 * This function removes the waveform layer from the list of layers if the waveform
 * asset is disabled.  All layer references are updated to account for the waveform
 * layer that's removed
 */
function removeDisabledSoundwave(
  config: PodcastTemplateConfig,
): PodcastTemplateConfig {
  if (
    !config.soundwave ||
    isEmpty(config.soundwave) ||
    config.soundwave.enabled
  ) {
    return config;
  }

  const waveformLayerIndex = config.layerOrder.indexOf('waveform');

  if (waveformLayerIndex < 0) {
    // no waveform layer. since the waveform is disabled there's no issue loading
    // this configuration, but remove the waveform config since it's not needed and
    // follows how the VideoTemplateEditor handles not having a waveform
    const { soundwave: _, ...restConfig } = config;
    return restConfig;
  }

  // remove soundwave layer, delete soundwave, fix layers (everything below the
  // waveform layer shifts up by 1)
  const updatedLayerOrder = [...config.layerOrder];
  updatedLayerOrder.splice(waveformLayerIndex, 1);

  const { soundwave: _, ...restConfig } = config;
  return updateLayerReferences(
    {
      ...restConfig,
      layerOrder: updatedLayerOrder,
    },
    asset => {
      // the asset points to an index in the original layerOrder which contains
      // the waveform track.  if the layer index comes after the waveform layer,
      // it has to be shifted up 1
      return asset.layerId > waveformLayerIndex
        ? asset.layerId - 1
        : asset.layerId;
    },
  );
}

export const createStateFromConfig = compose(
  (config: PodcastTemplateConfig): VideoTemplateState => {
    const layers = identifyLayers(config);
    const { keyAssets: keyTextAssets, ...textState } = getTextOverlays(
      config,
      layers,
    );

    const {
      keyAssets: keySlideshowAssets,
      text: slideEffectText,
      ...slideState
    } = getSlideshow(config, layers);

    const introOutro = getIntroOutro(config);
    const videoState = getVideoClips(config, layers);

    const soundwave = getSoundwave(config, layers);

    const [watermark] = getWatermark(config);

    const layerState = buildLayerState(layers);

    const captions = getCaptions(config);

    const state = {
      aspectRatio: undefined,
      backgroundColor: getBackgroundColor(config),
      captions,
      container: undefined,
      canvas: undefined,
      hasCubeEffect: getHasCubeEffect(config),
      introOutro,
      isComplex: isSpitfireConfig(config),
      layers: layerState,
      mainImage: {
        ids: keySlideshowAssets.mainImage,
        src: undefined,
      },
      originalLayers: layerState.order,
      patch: DEFAULT_PATCH,
      progress: getProgress(config) || DEFAULT_PROGRESS_STATE,
      slideshow: slideState,
      slideEffectText,
      soundwave,
      textOverlays: textState,
      // TODO SPAR-13063 read state from config
      timer: undefined,
      videoClips: videoState,
      watermark,
      transcription: {},
      ...getKeyTextAssets(keyTextAssets, omit(keySlideshowAssets, 'mainImage')),
    };

    return createState(state);
  },
  removeDisabledSoundwave,
  applyConfigTransforms,
  fixTemplateConfig,
);

export function createModifiedState(
  baseState: VideoTemplateState,
  transformer: (
    baseContent: VideoTemplateStateContent,
  ) => VideoTemplateStateContent,
): VideoTemplateState {
  const baseContent = getStateContent(baseState);
  return createState(transformer(baseContent));
}

export function overrideStateContent(
  state: VideoTemplateState,
  overrides: Partial<VideoTemplateStateContent>,
): VideoTemplateState {
  return createModifiedState(state, content => ({
    ...content,
    ...overrides,
  }));
}

export function createStateFromTemplate(
  template: PodcastWorkflowTemplate,
): VideoTemplateState {
  const aspectRatio = getAspectRatio(template);

  return overrideStateContent(
    createStateFromConfig(template.previewConfiguration),
    { aspectRatio, template },
  );
}

export function createTemplateEditorState({
  aspectRatio,
  backgroundColor = DEFAULT_BG_COLOR,
  canvas,
  container,
  hasCubeEffect = false,
  introOutro = {},
  isComplex = false,
  layers = DEFAULT_LAYER_STATE,
  mainImage = DEFAULT_MAIN_IMAGE,
  progress = DEFAULT_PROGRESS_STATE,
  slideEffectText = {},
  slideshow = DEFAULT_SLIDESHOW_STATE,
  soundwave,
  textOverlays = DEFAULT_ORDERED_STATE,
  timer,
  videoClips = DEFAULT_ORDERED_STATE,
  watermark,
  transcription = {},
}: Partial<VideoTemplateEditorConfig>): VideoTemplateState {
  return createState({
    aspectRatio,
    backgroundColor,
    canvas,
    container,
    hasCubeEffect,
    introOutro,
    isComplex,
    layers,
    mainImage,
    originalLayers: layers.order,
    progress,
    slideEffectText,
    slideshow,
    soundwave,
    textOverlays,
    timer,
    videoClips,
    watermark,
    patch: DEFAULT_PATCH,
    transcription,
    ...DEFAULT_KEY_TEXT_ASSETS,
  });
}
