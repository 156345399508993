import React from 'react';
import { block } from './utils';

interface Props {
  children: React.ReactNode;
}

function PopoverTitle(props: Props) {
  const { children } = props;
  return <div className={block('title')}>{children}</div>;
}

export default PopoverTitle;
