import * as React from 'react';

import { block } from '../utils';

export interface NoSearchResultTipProps {
  children?: React.ReactNode;
  color: string;
  icon: React.ReactElement;
}

const NoSearchResultTip: React.SFC<NoSearchResultTipProps> = ({
  children,
  color,
  icon,
}) => (
  <div className={block('no-search-result-tip')}>
    <div
      className={block('no-search-result-icon-container')}
      style={{ backgroundColor: color }}
    >
      {icon}
    </div>
    <span className={block('no-search-result-tip-text')}>{children}</span>
  </div>
);

export default NoSearchResultTip;
