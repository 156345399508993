import classNames from 'classnames';
import * as React from 'react';
import _ from 'underscore';

import FontAwesome from 'components/FontAwesome';
import { block } from './utils';

interface IProps {
  className?: string;
  disabled?: boolean;
  onPause?: () => void;
  onPlay?: () => void;
  onBack?: () => void;
  onForward?: () => void;
  playing?: boolean;
}

const PlaybackControls: React.SFC<IProps> = ({
  className,
  disabled,
  onPause,
  onPlay,
  onBack,
  onForward,
  playing,
}) => {
  const buttonClassName = block('control-button', { disabled });

  function disabledGuard<T>(arg: T) {
    if (disabled) return undefined;
    return arg;
  }

  return (
    <div className={classNames(block('playback-controls'), className)}>
      <FontAwesome
        className={classNames(buttonClassName, block('back-button'))}
        icon="step-backward"
        onClick={disabledGuard(onBack)}
      />
      {playing ? (
        <FontAwesome
          className={classNames(buttonClassName, block('pause-button'))}
          icon="pause"
          onClick={disabledGuard(onPause)}
        />
      ) : (
        <FontAwesome
          className={classNames(buttonClassName, block('play-button'))}
          icon="play"
          onClick={disabledGuard(onPlay)}
        />
      )}
      <FontAwesome
        className={classNames(buttonClassName, block('forward-button'))}
        icon="step-forward"
        onClick={disabledGuard(onForward)}
      />
    </div>
  );
};

PlaybackControls.defaultProps = {
  disabled: false,
  onBack: _.noop,
  onForward: _.noop,
  onPause: _.noop,
  onPlay: _.noop,
  playing: false,
};

export default PlaybackControls;
