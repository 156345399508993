import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { suggestionsStatusSelector } from 'redux/modules/clip-select/selectors';
import {
  onAddClipToVideo,
  onAdjustClip,
  onCaptionsModalSubmit,
  onDislikeSuggestedClip,
  onSelectOwnClip,
  onSuggestedClipsPageLoad,
} from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import { ClipSelectPageSource, RequestStatus } from 'types';
import { ClipSelectTrackingContextType } from '../types';
import { useClipSelectNavigation } from './ClipSelectNavigationContext/ClipSelectNavigationContext';

const ClipSelectTrackingContext = React.createContext<
  ClipSelectTrackingContextType | undefined
>(undefined);

interface ClipSelectTrackingProviderProps {
  children?: React.ReactNode;
  source: ClipSelectPageSource;
}

export const ClipSelectTrackingProvider: React.FC<ClipSelectTrackingProviderProps> = ({
  children,
  source,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const status = useSelector(suggestionsStatusSelector);
  const [state] = useClipSelectNavigation();
  const { clipAdjusted } = state.context;

  useEffect(() => {
    if (status === RequestStatus.SUCCESS) {
      dispatch(onSuggestedClipsPageLoad(source));
    }
  }, [dispatch, source, status]);

  const handleDislikeClip = useCallback(
    (reason: string) => {
      dispatch(onDislikeSuggestedClip(source, reason));
    },
    [dispatch, source],
  );

  const handleAdjustClip = useCallback(() => {
    dispatch(onAdjustClip(source));
  }, [dispatch, source]);

  const handleAddClip = useCallback(() => {
    dispatch(onAddClipToVideo(source, clipAdjusted));
  }, [dispatch, source, clipAdjusted]);

  const handleSelectOwnClip = useCallback(() => {
    dispatch(onSelectOwnClip(source));
  }, [dispatch, source]);

  const handleCaptionsModalSubmit = useCallback(
    (enabled: boolean) => {
      dispatch(onCaptionsModalSubmit(enabled));
    },
    [dispatch],
  );

  const value: ClipSelectTrackingContextType = {
    onAddClip: handleAddClip,
    onAdjustClip: handleAdjustClip,
    onCaptionsModalSubmit: handleCaptionsModalSubmit,
    onDislikeClip: handleDislikeClip,
    onSelectOwnClip: handleSelectOwnClip,
  };

  return (
    <ClipSelectTrackingContext.Provider value={value}>
      {children}
    </ClipSelectTrackingContext.Provider>
  );
};

export function useClipSelectTracking() {
  const context = useContext(ClipSelectTrackingContext);

  if (context === undefined) {
    throw new Error(
      'useClipSelectTracking must be used within ClipSelectTrackingProvider',
    );
  }

  return context;
}
