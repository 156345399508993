import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'underscore';

import Lottie from 'components/Lottie';
import { useTemplateState } from 'components/VideoTemplateEditor/context/VideoTemplateStateContext';
import useObjectUrl from 'hooks/useObjectUrl';
import useOnMount from 'hooks/useOnMount';
import usePreviousRef from 'hooks/usePreviousRef';
import useStyle from 'hooks/useStyle';
import { getAnimationConfig } from 'redux/middleware/api/embed-service/actions';
import { LottieConfiguration } from 'redux/middleware/api/embed-service/types';
import { Dispatch } from 'redux/types';
import useEditorLoading from '../../useEditorLoading';
import { TextReplacements } from './types';
import { getMainImageUrl, replaceMainImageUrl, replaceText } from './utils';

type State = 'idle' | 'request' | 'success' | 'failure';

export default function useLottieData(slideId: string) {
  const [config, setConfig] = useState<LottieConfiguration>();
  const [requestState, setRequestState] = useState<State>('idle');
  const dispatch = useDispatch<Dispatch>();
  const [, setEditorLoading] = useEditorLoading();
  const [originalImageUrl, setOriginalImageUrl] = useState<string>();
  const { slideEffectText, slideshow } = useTemplateState();
  const lottie = useRef<Lottie>();

  const slide = slideshow.data[slideId];

  const animationName = slide.imageEffect.effect;
  const imageUrl = useObjectUrl(slide?.imageSrc);
  const mainImageUrl = imageUrl;

  const embeddedTextIds =
    slideshow.data[slideId]?.imageEffect.options?.embeddedTexts ?? [];

  const embeddedTexts = embeddedTextIds.reduce<TextReplacements>((acc, id) => {
    const effectText = slideEffectText[id];
    acc[effectText.textType] = effectText.text;
    return acc;
  }, {});

  const prevEmbeddedTextsRef = usePreviousRef(embeddedTexts);
  const prevEmbeddedTexts = prevEmbeddedTextsRef.current;

  const handleDomReady = useCallback(() => {
    replaceText((lottie.current as any).anim, prevEmbeddedTextsRef.current);
  }, [prevEmbeddedTextsRef]);

  const styleAdjustments =
    slideshow.data[slideId]?.imageEffect.options?.styleAdjustments;

  const css = useMemo(() => {
    if (!styleAdjustments) return undefined;
    return styleAdjustments
      .map(
        ({ selector, styleKey, styleValue }) =>
          `${selector}{${styleKey}:${styleValue};}`,
      )
      .join('');
  }, [styleAdjustments]);

  useStyle(css);

  useOnMount(async () => {
    setRequestState('request');
    setEditorLoading(true);

    try {
      const { response } = await dispatch(
        getAnimationConfig(animationName, mainImageUrl),
      );
      setConfig(response.configuration);
      setOriginalImageUrl(getMainImageUrl(response.configuration));
      setRequestState('success');
    } catch (err) {
      setRequestState('failure');
    } finally {
      setEditorLoading(false);
    }
  });

  useEffect(() => {
    const currentImageUrl = getMainImageUrl(config);
    if (mainImageUrl === currentImageUrl) return;
    setConfig(replaceMainImageUrl(config, mainImageUrl));
  }, [config, mainImageUrl, originalImageUrl]);

  useEffect(() => {
    if (!isEqual(embeddedTexts, prevEmbeddedTexts)) {
      // anim probably isn't intended to be exposed which is why it doesn't exist
      // in the typedefs
      replaceText((lottie.current as any).anim, embeddedTexts);
    }
  }, [embeddedTexts, prevEmbeddedTexts]);

  return [config, requestState, lottie, handleDomReady] as const;
}
