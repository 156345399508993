import * as React from 'react';

import SelectSampleAudioButton, {
  SelectSampleAudioButtonProps,
} from 'blocks/SampleAudio/containers/SelectSampleAudioButton';
import AudioUploader, {
  IProps as AudioUploaderProps,
} from 'containers/AudioUploader/AudioUploader';
import { WizardType } from 'types';

interface IProps extends AudioUploaderProps {
  checkDurationIncreaseForTier?: boolean;
  onSampleAudioClick?: SelectSampleAudioButtonProps['onClick'];
  wizardType: WizardType;
}

class WizardAudioUploader extends React.Component<IProps> {
  private renderFooter = () => {
    const { onSampleAudioClick } = this.props;

    return (
      <SelectSampleAudioButton
        onClick={onSampleAudioClick}
        source={this.props.wizardType}
      >
        No audio file? Try our sample.
      </SelectSampleAudioButton>
    );
  };

  public render() {
    return <AudioUploader {...this.props} footer={this.renderFooter()} />;
  }
}

export default WizardAudioUploader;
export { IProps as WizardAudioUploaderProps };
