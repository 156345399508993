import * as React from 'react';

import { TextAreaField } from 'components/Form';
import { LinkIcon, TextIcon } from 'components/icons';
import SearchInput, { SearchInputProps } from 'components/SearchInput';
import bem from 'utils/bem';
import LoadingStep from '../../LoadingStep';
import { ImportType } from '../types';
import ImportTypeSelector from './ImportTypeSelector';

interface IProps {
  onSelectType?: (importType: ImportType) => void;
  onTextChange?: (text: string) => void;
  onUrlChange?: SearchInputProps['onChange'];
  summarizing?: boolean;
  text?: string;
  type?: ImportType;
  url?: SearchInputProps['value'];
}

const block = bem('import-text-step');

export default class ImportTextStep extends React.Component<IProps> {
  private linkInput;
  private textInput;

  public static defaultProps = {
    summarizing: false,
  };

  public componentDidMount() {
    this.focusInput();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { type: oldType } = prevProps;
    const { type: newType } = this.props;

    if (oldType === newType) return;

    this.focusInput();
  }

  private focusInput = () => {
    const { type } = this.props;

    if (type === ImportType.LINK) {
      this.linkInput && this.linkInput.inner.focus();
    }
    if (type === ImportType.TEXT) {
      this.textInput && this.textInput.focus();
    }
  };

  private linkInputRef = el => (this.linkInput = el);
  private textInputRef = el => (this.textInput = el);

  public render() {
    const {
      onSelectType,
      onTextChange,
      onUrlChange,
      summarizing,
      text,
      type,
      url,
    } = this.props;
    const types = [
      {
        content: (
          <SearchInput
            className={block('input')}
            placeholder="Paste your article's URL"
            onChange={onUrlChange}
            value={url}
            hideButton
            ref={this.linkInputRef}
          />
        ),
        id: ImportType.LINK,
        title: (
          <>
            <LinkIcon className="icon" /> Link
          </>
        ),
      },
      {
        content: (
          <TextAreaField
            className={block('textarea')}
            placeholder="Paste your article’s text"
            value={text}
            onChange={onTextChange}
            ref={this.textInputRef}
          />
        ),
        id: ImportType.TEXT,
        title: (
          <>
            <TextIcon className="icon" /> Text
          </>
        ),
      },
    ];

    return (
      <div className={block()}>
        {summarizing ? (
          <LoadingStep message="Summarizing" />
        ) : (
          <ImportTypeSelector
            activeItemId={type}
            onSelectType={onSelectType}
            items={types}
          />
        )}
      </div>
    );
  }
}

export { IProps as ImportTextStepProps };
