import * as React from 'react';

import IconButton from 'components/IconButton';
import { Upload } from 'components/icons';
import Tooltip from 'components/Tooltip';

interface IProps {
  onUploadClick: () => void;
}

const CaptionsUpload: React.SFC<IProps> = ({ onUploadClick }) => (
  <Tooltip
    animation={false}
    content="Upload captions"
    id="captions-upload-tooltip"
    placement="top"
    preventHideOnHover={false}
  >
    <IconButton onButtonClick={onUploadClick}>
      <Upload style={{ height: 20 }} />
    </IconButton>
  </Tooltip>
);

export default CaptionsUpload;
export { IProps as CaptionsUploadProps };
