import {
  isEditActive,
  isEditorActive,
  isProjectActive,
  willRouteChange,
} from './routes';

export * from './routes';

export default {
  isProjectActive,
  isEditActive,
  isEditorActive,
  willRouteChange,
};
