import { Tier } from 'redux/middleware/api/plan-service';
import { Query } from 'types';
import bem from 'utils/bem';
import {
  REFERRAL_KEY_QUERY_PARAM,
  REFERRAL_TYPE_QUERY_PARAM,
} from 'utils/constants';
import { ReferralType } from './types';

type ValidTier = Tier.FREE | Tier.PRO | Tier.BASIC | Tier.UNLIMITED;

const REFERRAL_TYPE_VALUES = ['referral', 'promo'];
const TIER_VALUES: ValidTier[] = [
  Tier.FREE,
  Tier.PRO,
  Tier.BASIC,
  Tier.UNLIMITED,
];

function isValidTier(tier: string | string[]): tier is ValidTier {
  if (Array.isArray(tier)) {
    return false;
  }

  return (TIER_VALUES as string[]).indexOf(tier) >= 0;
}

function isValidReferralType(
  referralType: string | string[],
): referralType is ReferralType {
  if (Array.isArray(referralType)) {
    return false;
  }

  if (REFERRAL_TYPE_VALUES.indexOf(referralType) >= 0) {
    return true;
  }
  return false;
}

export function getTierFromQuery(query: Query = {}) {
  const { tier } = query;
  if (isValidTier(tier)) {
    return tier;
  }
  return 'free' as Tier.FREE;
}

export function getReferralTypeFromQuery(
  query: Query = {},
): ReferralType | undefined {
  const referralType = query[REFERRAL_TYPE_QUERY_PARAM];
  if (isValidReferralType(referralType)) {
    return referralType;
  }
  return undefined;
}

export function getReferralKeyFromQuery(query: Query = {}): string {
  return query && (query[REFERRAL_KEY_QUERY_PARAM] as string);
}

export const block = bem('projects-page');
export const tabsBlock = bem('projects-page-tabs');
export const createTabBlock = bem('projects-page-create-tab');
