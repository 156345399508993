import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';

import { block } from './utils';

export interface LayerOrderControlsButtonProps {
  icon: React.ReactNode;
  tooltipText: string;
  disabled?: boolean;
  onClick: () => void;
}

const LayerOrderControlsButton: React.FC<LayerOrderControlsButtonProps> = props => {
  const { icon, tooltipText, disabled, onClick } = props;

  return (
    <Tooltip
      content={tooltipText}
      placement="right"
      hideArrow
      className={block('control-tooltip-wrapper')}
    >
      <Spacer
        as={Button}
        orientation="vertical"
        align="center"
        justify="center"
        className={block('control-button')}
        disabled={disabled}
        onClick={onClick}
      >
        {icon}
      </Spacer>
    </Tooltip>
  );
};

export default LayerOrderControlsButton;
