import { Set } from 'immutable';
import { useReducer } from 'react';

import { VideoEditorState } from '../types';
import { EditorAction } from '../types/editor-actions';

function reducer(state: VideoEditorState, action: EditorAction) {
  switch (action.type) {
    case 'ASSET_DRAG_START': {
      return {
        ...state,
        selectedAsset: {
          ...state.selectedAsset,
          dragging: true,
        },
      };
    }

    case 'ASSET_DRAG_STOP': {
      return {
        ...state,
        selectedAsset: {
          ...state.selectedAsset,
          dragging: false,
        },
      };
    }

    case 'EDITOR_LOADING_BEGIN': {
      const { id } = action.payload;
      return {
        ...state,
        loadingIds: state.loadingIds.add(id),
      };
    }

    case 'EDITOR_LOADING_END': {
      const { id } = action.payload;
      return {
        ...state,
        loadingIds: state.loadingIds.remove(id),
      };
    }

    case 'ASSET_SELECT': {
      const { id } = action.payload;
      return {
        ...state,
        selectedAsset: {
          id,
          dragging: false,
        },
      };
    }

    case 'CHILD_VIEW_CLOSE': {
      return {
        ...state,
        selectedAsset: {
          id: undefined,
          dragging: false,
        },
      };
    }

    case 'OVERLAY_OPEN': {
      return {
        ...state,
        overlayOpen: true,
      };
    }

    case 'OVERLAY_CLOSE': {
      return {
        ...state,
        overlayOpen: false,
      };
    }

    default:
      return state;
  }
}

export default function useEditorStateReducer() {
  return useReducer(reducer, {
    loadingIds: Set<string>(),
    overlayOpen: false,
    selectedAsset: {
      id: undefined,
      dragging: false,
    },
  });
}
