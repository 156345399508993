import { ColorPaletteState } from '@sparemin/blockhead';
import { Map, Record, RecordOf } from 'immutable';
import * as React from 'react';
import _ from 'underscore';

import { CropperModalBody } from 'components/CropperModal';
import { FileUploadMethod } from 'components/FileUploader/FileUploaderDropzone';
import { SlideEditorValues } from 'components/SlideEditor';
import { SlideEditorStatus } from 'components/SlideEditor/types';
import SteppedModal from 'components/SteppedModal';
import { UseHook } from 'components/UseHook';
import { ImageCropperInstance } from 'containers/ImageCropper';
import { ImageSource } from 'containers/ImageCropper/types';
import { MediaImportedArgs } from 'containers/MediaUploadModal/types';
import VideoEditorPlaybackTimeContext from 'containers/VideoEditor/VideoEditorPlaybackTimeContext';
import useImageProcessor from 'hooks/useImageProcessor';
import {
  AiImageType,
  IMediaMetadata as IImageMetadata,
} from 'redux/middleware/api/image-search-service/types';
import { IVideoUpload } from 'redux/middleware/api/media-upload-service';
import { AddVideoParams } from 'redux/modules/embed/actions/video';
import {
  AspectRatioName,
  CropMetadata,
  IImmutableMap,
  Position,
  Size,
  SlideEffect,
  SlideTransition,
  SourceImageOrigin,
} from 'types';
import { videoTransitionTypes } from 'utils/embed/video';
import { createChainedFunction } from 'utils/functions';
import addMediaFooterButtons from './addMediaFooterButtons';
import AddSlideEditor from './AddSlideEditor';

import AddVideoForm, {
  dataFactory as videoFormDataFactory,
  VideoScaling,
} from './AddVideoForm';
import MediaImport, {
  MediaImportProps,
  MediaType,
  TabName,
} from './MediaImport';

interface SearchParams {
  query: string;
  mediaType?: string;
  gifEngine?: string;
  imageEngine?: string;
  videoEngine?: string;
}

// FIXME this is wrong.  this is not what addImage accepts
interface AddImageArgs {
  startMillis: number;
  endMillis: number;
  effect: SlideEffect;
  placement: Size<string> & Position<string>;
  transition: SlideTransition;
  imageMetadata: IImageMetadata;
  mediaToReplaceId: string;
  originalImage: Blob;
  cropData: CropMetadata;
  croppedImage: Blob;
  sourceImageOrigin: SourceImageOrigin;
  blurRadius?: string;
}

export interface IProps
  extends Pick<
    MediaImportProps,
    'onImageClick' | 'onVideoClick' | 'onGifClick' | 'onAiAssetClick'
  > {
  addingMediaType?: MediaType;
  aspectRatio: number;
  defaultGifSearchEngine?: string;
  defaultImageSearchEngine?: string;
  defaultVideoSearchEngine?: string;
  durationMillis?: number;
  colorPaletteState: ColorPaletteState;
  error?: string;
  aspectRatioName: AspectRatioName;
  imageType: AiImageType;
  onAspectRatioNameChange: (value: AspectRatioName) => void;
  onImageTypeChange: (value: AiImageType) => void;
  onMediaSearchSubmit?: (type: string, engine: string, query: string) => void;
  onModalShow?: () => void;
  onExited?: () => void;
  onHide?: () => void;
  onAddImage?: (args: AddImageArgs) => void;
  onAddVideo?: (
    params: AddVideoParams,
    mediaImportedArgs?: MediaImportedArgs,
  ) => void;
  show?: boolean;
  videoEntity?: IImmutableMap<IVideoUpload>;
  uploadMethod?: FileUploadMethod;
  onMediaTypeChange?: (mediaType: MediaType) => void;
}

interface IDataState {
  activeStep: string;
  croppedImageSrc: Blob;
  cropMetadata: CropMetadata;
  searchInputValue: string;
  selectedImportTab: TabName;
  selectedGifEngine: string;
  selectedImageEngine: string;
  selectedImageSrc: string | Blob;
  selectedVideoEngine: string;
  selectedMediaType: MediaType;
  slideEditorStatus: SlideEditorStatus;
  slideEditorValues: SlideEditorValues;
  videoPreviewPlaying?: boolean;
  mediaImportedArgs?: MediaImportedArgs;
}

interface IState {
  data: RecordOf<IDataState>;
}

export interface SelectedMediaElement extends Map<string, any> {
  startMillis: number;
  endMillis: number;
  imageEffect?: SlideEffect;
  entryTransition?: SlideTransition;
  transitionIn?: string;
  transitionOut?: string;
  audioLevel: number;
  mainAudioLevel: number;
  audioFadeInDurationMillis: number;
  audioFadeOutDurationMillis: number;
  id: string;
}

const dataFactory = Record<IDataState>({
  activeStep: undefined,
  croppedImageSrc: undefined,
  cropMetadata: undefined,
  searchInputValue: undefined,
  selectedGifEngine: undefined,
  selectedImageEngine: undefined,
  selectedImageSrc: undefined,
  selectedImportTab: undefined,
  selectedMediaType: undefined,
  selectedVideoEngine: undefined,
  slideEditorStatus: 'loading',
  slideEditorValues: undefined,
  videoPreviewPlaying: undefined,
  mediaImportedArgs: undefined,
});

export default class AddMediaModal extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    addingMediaType: undefined,
    defaultGifSearchEngine: 'giphy',
    onAddImage: _.noop,
    onAddVideo: _.noop,
    onHide: _.noop,
    onImageClick: _.noop,
    onMediaSearchSubmit: _.noop,
    show: false,
  };

  private steppedModal: SteppedModal;
  // TODO needs to be AddSlideForm type but that doesn't exist yet
  private slideForm: any;
  private cropper: ImageCropperInstance;
  private videoForm: any;
  private video: File | string;
  private lastSearchTerm: string;
  private searchedGifEngines: Set<string>;
  private searchedImageEngines: Set<string>;
  private searchedVideoEngines: Set<string>;
  private imageMetadata: IImageMetadata;
  private sourceImageOrigin: SourceImageOrigin;

  constructor(props: IProps) {
    super(props);

    this.searchedGifEngines = new Set();
    this.searchedImageEngines = new Set();
    this.searchedVideoEngines = new Set();

    this.state = {
      data: dataFactory({
        activeStep: undefined,
        searchInputValue: '',
        selectedGifEngine: props.defaultGifSearchEngine,
        selectedImageEngine: props.defaultImageSearchEngine,
        selectedImportTab: 'image',
        selectedMediaType: 'image',
        selectedVideoEngine: props.defaultVideoSearchEngine,
        videoPreviewPlaying: undefined,
        mediaImportedArgs: undefined,
      }),
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const {
      addingMediaType: nextAddingMediaType,
      durationMillis: nextDurationMillis,
      error: nextError,
      onHide: nextOnHide,
      show: nextShow,
    } = nextProps;
    const { addingMediaType, show, durationMillis, onModalShow } = this.props;

    if (!durationMillis && nextDurationMillis) {
      this.steppedModal.stepHistory.push('video');
    }

    if (addingMediaType && !nextAddingMediaType) {
      this.steppedModal && this.steppedModal.stepHistory.replace('import');
      nextOnHide();
    }

    if (!show && nextShow) {
      this.setState(({ data }) => ({
        data: data.delete('videoPreviewPlaying'),
      }));
      onModalShow();
    }

    if (
      nextShow &&
      nextError &&
      this.steppedModal.stepHistory.active === 'video'
    ) {
      this.steppedModal.stepHistory.pop();
    }
  }

  private steppedModalRef = el => (this.steppedModal = el);

  private cropperModalRef = el => {
    this.cropper = el;
  };

  private handleFileAccepted = (file: File, type: MediaType) => {
    /*
     * animated gifs are sent to the api where they get converted to a video. if this.video
     * is set to the original animated gif file that was accepted, then onAddVideo will pass
     * this video file along as one of the arguments when we really want the video url of the
     * gif.
     *
     * if the type of file is video but the file's mime type is 'image/gif', then don't save the
     * file to this.video
     */
    if (type === 'video' && file.type !== 'image/gif') {
      this.video = file;
    }

    if (type === 'image') {
      this.setNewImageSrc(file);
    } else {
      this.steppedModal.stepHistory.push('video');
    }

    this.setMediaType(type);
  };

  private handleImageClick = (
    imageUrl: string,
    metadata: IImageMetadata,
    engine: string,
  ) => {
    const { onImageClick } = this.props;
    this.setNewImageSrc(imageUrl, metadata);
    onImageClick(imageUrl, metadata, engine);
  };

  private handleVideoClick = (url: string, poster: string, engine: string) => {
    const { onVideoClick } = this.props;
    this.steppedModal.stepHistory.push('video');
    onVideoClick(url, poster, engine);
  };

  private handleMediaImported = (mediaImportedArgs: MediaImportedArgs) => {
    const { onVideoClick } = this.props;

    if (!mediaImportedArgs) {
      return;
    }

    this.steppedModal.stepHistory.push('video');

    onVideoClick(mediaImportedArgs?.previewUrl);

    this.setState(({ data }) => ({
      data: data.set('mediaImportedArgs', mediaImportedArgs),
    }));
  };

  private handleGifClick = (url: string, poster: string, engine: string) => {
    const { onGifClick } = this.props;
    this.steppedModal.stepHistory.push('video');
    onGifClick(url, poster, engine);
  };

  private checkShouldPreventAutoSeach = (engine: string): boolean => {
    return ['textToImage', 'textToVideo'].includes(engine);
  };

  private handleSearchEngineSelect = (engine: string) => {
    const { data } = this.state;

    const mediaType = data.selectedMediaType;
    let engineKey;

    switch (mediaType) {
      case 'image':
        engineKey = 'imageEngine';
        break;
      case 'video':
        engineKey = 'videoEngine';
        break;
      case 'gif-video':
        engineKey = 'gifEngine';
        break;
      default:
        engineKey = 'imageEngine';
    }

    const search = (query: string) => {
      if (this.checkShouldPreventAutoSeach(engine)) {
        return undefined;
      }

      return this.submitSearch({
        mediaType,
        query,
        [engineKey]: engine,
      });
    };

    if (this.lastSearchTerm !== data.searchInputValue) {
      this.setSearchInput(this.lastSearchTerm);
      search(this.lastSearchTerm);
    } else if (!this.isSearched(mediaType, engine)) {
      search(data.searchInputValue);
    }

    this.setSearchEngine(engine);
  };

  private handleUrlAccepted = (
    url: string,
    type: MediaType,
    origin: SourceImageOrigin,
  ) => {
    if (type === 'image') {
      this.setNewImageSrc(url, undefined, origin);
    } else {
      this.steppedModal.stepHistory.push('video');
    }

    this.setMediaType(type);
  };

  private handleSearchInputChange = (value: string) =>
    this.setSearchInput(value);

  private getActiveEngineByMediaType = (mediaType: MediaType) => {
    const { data } = this.state;

    switch (mediaType) {
      case 'image':
        return data.selectedImageEngine;

      case 'video':
        return data.selectedVideoEngine;

      case 'gif-video':
        return data.selectedGifEngine;

      default:
        return undefined;
    }
  };

  private handleTabSelect = (tabKey: TabName) =>
    this.setState(({ data }) => ({
      data: data.set('selectedImportTab', tabKey),
    }));

  private handleMediaTypeSelect = (mediaType: MediaType) => {
    const { data } = this.state;

    const search = (query: string) => {
      if (
        this.checkShouldPreventAutoSeach(
          this.getActiveEngineByMediaType(mediaType),
        )
      ) {
        return undefined;
      }

      return this.submitSearch({ mediaType, query });
    };

    if (this.lastSearchTerm !== data.searchInputValue) {
      this.setSearchInput(this.lastSearchTerm);
      search(this.lastSearchTerm);
    } else if (!this.isSearched(mediaType)) {
      search(data.searchInputValue);
    }

    this.setMediaType(mediaType);
  };

  private handleModalExited = () => {
    const { addingMediaType } = this.props;
    if (addingMediaType !== 'video') {
      this.steppedModal.stepHistory.replace('import');
    }
  };

  private handleModalHide = () => {
    const { addingMediaType, onHide } = this.props;

    if (
      (!addingMediaType && !this.isLoadingVideo()) ||
      addingMediaType === 'video'
    ) {
      onHide();
    }
  };

  private handleSearchSubmit = (query: string) => this.submitSearch({ query });

  private setNewImageSrc = (
    src: ImageSource,
    metadata?: IImageMetadata,
    origin?: SourceImageOrigin,
  ) => {
    this.setState(({ data }) => ({
      data: data.withMutations(d => {
        d.set('selectedImageSrc', src);
        d.delete('cropMetadata');
        d.delete('croppedImageSrc');
        return d;
      }),
    }));
    this.imageMetadata = metadata;
    this.sourceImageOrigin = origin;
    this.steppedModal.stepHistory.push('image');
  };

  private submitSearch(searchParams: SearchParams) {
    const { data } = this.state;
    const { onMediaSearchSubmit } = this.props;

    const {
      query,
      mediaType = data.selectedMediaType,
      gifEngine = data.selectedGifEngine,
      imageEngine = data.selectedImageEngine,
      videoEngine = data.selectedVideoEngine,
    } = searchParams;

    if (!query) return;

    let engine;
    switch (mediaType) {
      case 'image':
        engine = imageEngine;
        break;
      case 'video':
        engine = videoEngine;
        break;
      case 'gif-video':
        engine = gifEngine;
        break;
      default:
        engine = imageEngine;
    }
    onMediaSearchSubmit(mediaType, engine, query);

    if (this.lastSearchTerm !== query) {
      this.searchedGifEngines.clear();
      this.searchedImageEngines.clear();
      this.searchedVideoEngines.clear();
    }

    this.lastSearchTerm = query;

    if (mediaType === 'image') {
      this.searchedImageEngines.add(imageEngine);
    } else if (mediaType === 'video') {
      this.searchedVideoEngines.add(videoEngine);
    } else {
      this.searchedGifEngines.add(gifEngine);
    }
  }

  private handleKeywordClick = (keyword: string) => {
    this.setSearchInput(keyword);
    this.submitSearch({ query: keyword });
  };

  private handleAddSlide = (originalImage: Blob) => async () => {
    const { onAddImage } = this.props;
    const { data } = this.state;

    const {
      effect,
      endMillis,
      mediaToReplaceId,
      placement,
      startMillis,
      transition,
      blurRadius,
    } = this.slideForm.getValues();

    onAddImage({
      effect,
      endMillis,
      mediaToReplaceId,
      originalImage,
      startMillis,
      transition,
      croppedImage: data.croppedImageSrc || originalImage,
      cropData: data.cropMetadata,
      imageMetadata: this.imageMetadata,
      placement: Object.keys(placement).reduce((acc, key) => {
        acc[key] = placement[key].toString();
        return acc;
      }, {} as Size<string> & Position<string>),
      sourceImageOrigin: this.sourceImageOrigin,
      blurRadius: blurRadius?.toString(),
    });
  };

  private handleAddVideo = () => {
    const {
      data: { selectedMediaType },
    } = this.state;
    const { onAddVideo, durationMillis, videoEntity } = this.props;
    this.setState(({ data }) => ({
      data: data.set('videoPreviewPlaying', false),
    }));

    const { src, ...values } = this.videoForm.values;
    const videoFormValues =
      selectedMediaType !== 'video'
        ? _.omit(values, 'transcriptionEnabled', 'transcriptionLanguage')
        : values;

    onAddVideo(
      {
        durationMillis,
        file: this.video || src,
        preloadedVideoEntity: videoEntity,
        scaling: 'fill',
        ...videoFormValues,
      },
      this.state.data.mediaImportedArgs,
    );
  };

  private handleVideoPreviewPlay = () =>
    this.setState(({ data }) => ({
      data: data.set('videoPreviewPlaying', true),
    }));

  private handleVideoPreviewPause = () =>
    this.setState(({ data }) => ({
      data: data.set('videoPreviewPlaying', false),
    }));

  private handleStepChange = (toStep: string, fromStep: string) => {
    const { data } = this.state;
    if (fromStep === 'import') {
      if (data.searchInputValue !== this.lastSearchTerm) {
        this.setSearchInput(this.lastSearchTerm);
      }
    }

    if (toStep === 'import') {
      this.setState(({ data: prevData }) => ({
        data: prevData.withMutations(d => {
          d.set('cropMetadata', undefined);
          d.set('selectedImageSrc', undefined);
          d.set('slideEditorValues', undefined);
          return d;
        }),
      }));
    }

    if (fromStep === 'video') {
      delete this.video;
    }

    this.setState(({ data: dataState }) => ({
      data: dataState.set('activeStep', toStep),
    }));
  };

  private handleCropperOpen = () => {
    this.setState(({ data }) => ({
      data: data.set('slideEditorValues', this.slideForm.getValues()),
    }));
    this.steppedModal.stepHistory.push('crop-image');
  };

  private handleCrop = async () => {
    const { croppedImage, cropData } = await this.cropper.getValues();

    this.setState(({ data }) => ({
      data: data.withMutations(s => {
        s.set('croppedImageSrc', croppedImage);
        s.set('cropMetadata', cropData);

        // if slide was re-cropped, delete placement and move back to center
        return s;
      }),
    }));
    this.steppedModal.stepHistory.replace('image');
  };

  private handleSlideEditorStatusChange = (status: SlideEditorStatus) => {
    this.setState(({ data }) => ({
      data: data.set('slideEditorStatus', status),
    }));
  };

  private setSearchInput(value: string = '') {
    this.setState(({ data }) => ({
      data: data.set('searchInputValue', value),
    }));
  }

  private setSearchEngine(engine: string) {
    this.setState(({ data }) => ({
      data: data.set(
        data.selectedMediaType === 'image'
          ? 'selectedImageEngine'
          : data.selectedMediaType === 'video'
          ? 'selectedVideoEngine'
          : 'selectedGifEngine',
        engine,
      ),
    }));
  }

  private setMediaType(type: MediaType) {
    const { onMediaTypeChange } = this.props;

    onMediaTypeChange(type);

    this.setState(({ data }) => ({
      data: data.set('selectedMediaType', type),
    }));
  }

  private isSearched(mediaType: MediaType, engine?: string) {
    const { data } = this.state;

    if (mediaType === 'image') {
      const imageEngine = engine || data.selectedImageEngine;
      return this.searchedImageEngines.has(imageEngine);
    }

    if (mediaType === 'video') {
      const videoEngine = engine || data.selectedVideoEngine;
      return this.searchedVideoEngines.has(videoEngine);
    }

    if (mediaType === 'gif-video') {
      const gifEngine = engine || data.selectedGifEngine;
      return this.searchedGifEngines.has(gifEngine);
    }

    return false;
  }

  private isLoadingVideo() {
    const { durationMillis } = this.props;

    if (!this.steppedModal) return false;

    return this.steppedModal.stepHistory.active === 'video' && !durationMillis;
  }

  public render() {
    const {
      addingMediaType,
      aspectRatio,
      show,
      durationMillis,
      onExited,
      onHide,
      colorPaletteState,
      aspectRatioName,
      imageType,
      uploadMethod,
      onAspectRatioNameChange,
      onImageTypeChange,
      onAiAssetClick,
    } = this.props;
    const { data } = this.state;

    const isAdding = !_.isUndefined(addingMediaType);

    return (
      <UseHook
        hook={useImageProcessor}
        args={[data.selectedImageSrc, aspectRatio]}
      >
        {({ objectUrl, originalFile }) => (
          <VideoEditorPlaybackTimeContext.Consumer>
            {({ positionSec }) => (
              <SteppedModal
                baseClassName="add-media-modal"
                defaultStep="import"
                footerButtonsDisabled={isAdding}
                onExited={createChainedFunction(
                  this.handleModalExited,
                  onExited,
                )}
                onHide={this.handleModalHide}
                onStepChange={this.handleStepChange}
                ref={this.steppedModalRef}
                show={show}
                steps={[
                  {
                    component: (
                      <MediaImport
                        uploadMethod={uploadMethod}
                        selectedTab={data.selectedImportTab}
                        onFileAccepted={this.handleFileAccepted}
                        onGifClick={this.handleGifClick}
                        onAiAssetClick={(url, poster) => {
                          onAiAssetClick(url, poster);
                          this.setMediaType('ai-asset');
                        }}
                        onImageClick={this.handleImageClick}
                        onKeywordClick={this.handleKeywordClick}
                        onMediaTypeSelect={this.handleMediaTypeSelect}
                        onSearchEngineSelect={this.handleSearchEngineSelect}
                        onUrlAccepted={this.handleUrlAccepted}
                        onSearchInputChange={this.handleSearchInputChange}
                        onSearchSubmit={this.handleSearchSubmit}
                        onTabSelect={this.handleTabSelect}
                        onVideoClick={this.handleVideoClick}
                        onMediaImported={this.handleMediaImported}
                        searchInputValue={data.searchInputValue}
                        selectedGifEngine={data.selectedGifEngine}
                        selectedImageEngine={data.selectedImageEngine}
                        selectedVideoEngine={data.selectedVideoEngine}
                        aspectRatioName={aspectRatioName}
                        imageType={imageType}
                        colorPaletteState={colorPaletteState}
                        onAspectRatioNameChange={onAspectRatioNameChange}
                        onImageTypeChange={onImageTypeChange}
                      />
                    ),
                    id: 'import',
                    modalClassName: 'add-media-modal--import',
                    footerClassName: 'add-media-modal__footer--import',
                    renderFooterButtons: [],
                  },
                  {
                    component: (
                      <AddSlideEditor
                        className="add-media-modal__add-slide"
                        defaultState={data.slideEditorValues}
                        disabled={isAdding}
                        onCropperOpen={this.handleCropperOpen}
                        onStatusChange={this.handleSlideEditorStatusChange}
                        ref={el => (this.slideForm = el)}
                        src={data.croppedImageSrc ?? objectUrl}
                        startMillis={positionSec * 1000}
                      />
                    ),
                    id: 'image',
                    modalClassName: 'add-media-modal--placer',
                    footerClassName: 'add-media-modal__footer',
                    renderFooterButtons: ({ back, cancel, submit }) => {
                      const disabledOverride =
                        data.slideEditorStatus === 'loading';

                      return addMediaFooterButtons({
                        back: {
                          ...back,
                          disabled: back.disabled || disabledOverride,
                        },
                        cancel: { ...cancel, disabled: false },
                        submit: {
                          ...submit,
                          disabled: submit.disabled || disabledOverride,
                          onClick: this.handleAddSlide(originalFile),
                        },
                      });
                    },
                  },
                  {
                    component: (
                      <CropperModalBody
                        defaultMetadata={data.cropMetadata}
                        defaultConstrainImage={data.cropMetadata?.constrained}
                        fileType={originalFile?.type}
                        imgSrc={objectUrl}
                        ref={this.cropperModalRef}
                      />
                    ),
                    id: 'crop-image',
                    modalClassName: 'add-media-modal--cropper',
                    onSubmit: this.handleCrop,
                    submitButtonLabel: 'Crop',
                  },
                  {
                    component: (
                      <AddVideoForm
                        defaultData={videoFormDataFactory({
                          audioFadeInDurationMillis: 0,
                          audioFadeOutDurationMillis: 0,
                          audioLevel: 100,
                          mainAudioLevel: 100,
                          scaling: VideoScaling.FILL,
                          startMillis: positionSec * 1000,
                          transitionIn: videoTransitionTypes.CUT,
                          transitionOut: videoTransitionTypes.CUT,
                        })}
                        durationMillis={durationMillis}
                        onPause={this.handleVideoPreviewPause}
                        onPlay={this.handleVideoPreviewPlay}
                        onUpgradeClick={onHide}
                        playing={data.videoPreviewPlaying}
                        ref={el => (this.videoForm = el)}
                        showTranscriptionSection={
                          data.selectedMediaType === 'video'
                        }
                      />
                    ),
                    id: 'video',
                    modalClassName: 'add-media-modal--video',
                    onSubmit: this.handleAddVideo,
                    renderFooterButtons: ({ back, cancel, submit }) => {
                      const disabledOverride = this.isLoadingVideo();

                      return addMediaFooterButtons({
                        back: {
                          ...back,
                          onClick: () => {
                            back.onClick();
                          },
                          disabled: back.disabled || disabledOverride,
                        },
                        // cancel button should only be disabled when video is loading,
                        // since the modal hide function has a check that will prevent
                        // the modal from closing if the video is loading. in other
                        // scenarios, the modal will close so the user should be allowed
                        // to click the button
                        cancel: {
                          ...cancel,
                          disabled: disabledOverride || false,
                        },
                        submit: {
                          ...submit,
                          disabled: submit.disabled || disabledOverride,
                        },
                      });
                    },
                  },
                ]}
                title="Add Media"
              />
            )}
          </VideoEditorPlaybackTimeContext.Consumer>
        )}
      </UseHook>
    );
  }
}
