// simplified implementation of https://github.com/AKolodeev/redux-promising-modals

import { Middleware } from 'redux';
import { Type } from './action-types';
import { stateSelector } from './selectors';
import { ModalAction } from './types';

const HANDLED_ACTIONS = [Type.MODAL_PUSH, Type.MODAL_HIDE, Type.MODAL_REPLACE];

function canHandleAction(action: any): action is ModalAction {
  return HANDLED_ACTIONS.includes(action?.type);
}

const middleware = (): Middleware => {
  const resolvers = [];

  const resolveWithValue = action => {
    const { value } = action.meta || {};
    const resolver = resolvers.pop();

    if (resolver) {
      return resolver(value);
    }

    return undefined;
  };

  return store => next => action => {
    if (!canHandleAction(action)) {
      return next(action);
    }

    const oldModals = stateSelector(store.getState());
    const result = next(action);
    const newModals = stateSelector(store.getState());

    if (action.type === Type.MODAL_PUSH) {
      // if modal is pushed, add a new resolver to be resolved when the modal
      // is closed
      return new Promise(resolve => {
        resolvers.push(resolve);
      });
    }

    if (
      action.type === Type.MODAL_HIDE &&
      oldModals.first()?.show &&
      !newModals.first()?.show
    ) {
      // if modal is hidden, resolve the most recent promise.  the reducer might
      // not necessarily hide the modal at the top of the stack, so verify that
      // before resolving promise
      return resolveWithValue(action);
    }

    if (action.type === Type.MODAL_POP && newModals.size < oldModals.size) {
      const poppedModal = oldModals.first();

      // if the popped modal was already hidden, the promise was already resolved
      // and popped off the resolvers stack.  only resolve and pop if popped modal
      // was shown
      if (poppedModal?.show) {
        return resolveWithValue(action);
      }
    }

    if (action.type === Type.MODAL_REPLACE) {
      // replace will take the top modal, hide it, and insert a new modal below
      // it at index 1.  if the new modal list size is > 1, then there was a modal
      // on the stack and if that modal became hidden as part of the replace
      // action, then its promise needs to be resolved
      if (
        newModals.size > 1 &&
        oldModals.first().show &&
        !newModals.first().show
      ) {
        resolveWithValue(action);
      }

      // replace adds a new modal, so add its resolver
      return new Promise(resolve => {
        resolvers.push(resolve);
      });
    }

    return result;
  };
};

export default middleware;
