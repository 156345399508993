import * as React from 'react';
import { IconProps } from './types';

const CalloutPro: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228 168" {...props}>
    <defs>
      <linearGradient
        id="vx8axjum0a"
        x1="0%"
        x2="100%"
        y1="22.853%"
        y2="77.147%"
      >
        <stop offset="0%" stopColor="#5EBEFC" />
        <stop offset="100%" stopColor="#DC92FE" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g
        fill="currentColor"
        stroke="url(#vx8axjum0a)"
        strokeWidth="2"
        transform="translate(-765 -235)"
      >
        <g>
          <g>
            <path
              d="M218 152h-89.414L114 166.586 99.414 152H10c-2.485 0-4.735-1.007-6.364-2.636C2.007 147.735 1 145.485 1 143V10c0-2.485 1.007-4.735 2.636-6.364C5.265 2.007 7.515 1 10 1h208c2.485 0 4.735 1.007 6.364 2.636C225.993 5.265 227 7.515 227 10v133c0 2.485-1.007 4.735-2.636 6.364-1.629 1.629-3.879 2.636-6.364 2.636z"
              transform="translate(750 39) translate(15 196)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CalloutPro;
