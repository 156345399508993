import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import PhraseControlButton, {
  PhraseControlButtonProps,
} from './PhraseControlButton';

export type AddPhraseButtonProps = Pick<
  PhraseControlButtonProps,
  'disabled' | 'onClick'
>;

const AddPhraseButton: React.SFC<AddPhraseButtonProps> = props => (
  <PhraseControlButton {...props} id="add-phrase" tooltip="Add block">
    <FontAwesome icon="plus" />
  </PhraseControlButton>
);

export default AddPhraseButton;
