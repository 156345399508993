import { connect } from 'react-redux';

import { addAudioToTrack } from 'redux/modules/embed/actions/audio';
import { projectTypeByAssets } from 'redux/modules/embed/selectors';
import { pushModal } from 'redux/modules/modal';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import TranscriptStatusDisplay, {
  TranscriptStatusDisplayProps as Props,
} from './TranscriptStatusDisplay';

type StateProps = Pick<Props, 'projectTypeByAssets'>;
type DispatchProps = Pick<Props, 'onClickAddAudio'>;

const mapStateToProps = (state: State): StateProps => ({
  projectTypeByAssets: projectTypeByAssets(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onClickAddAudio: () => {
    dispatch(addAudioToTrack());
    dispatch(pushModal({ name: 'AddAudioModal' }));
  },
});

const component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TranscriptStatusDisplay);
export type TranscriptStatusDisplayProps = PropsOf<typeof component>;
export default component;
