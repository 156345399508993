import React from 'react';
import { Alert } from 'components/icons';
import { showLiveChatWidget } from 'utils/helpdesk';
import DownloadCaptionsDialogModalContents from './DownloadCaptionsDialogModalContents';

export interface EpisodeTranscriptionErrorModalContentsProps {}

const EpisodeTranscriptionErrorModalContents: React.FC<EpisodeTranscriptionErrorModalContentsProps> = () => (
  <DownloadCaptionsDialogModalContents
    actions={[
      {
        children: 'contact support',
        key: 'support',
        theme: 'submit',
        onClick: showLiveChatWidget,
      },
    ]}
    icon={<Alert style={{ height: 100, width: 100 }} />}
    title="Something went wrong"
    details="We aren’t able to transcribe this episode, please contact support so we can fix this."
  />
);

export default EpisodeTranscriptionErrorModalContents;
