import React from 'react';
import { useDispatch } from 'react-redux';

import AudiogramDestinationVideoTypesList from 'blocks/AudiogramDestinations/components/VideoTypes/AudiogramDestinationVideoTypesList';
import {
  AudiogramDestinationConfig,
  VideoTypeConfig,
} from 'blocks/AudiogramDestinations/types';
import Modal from 'components/Modal/v2';
import ModalFooter from 'components/Modal/v2/ModalFooter';
import {
  onClickAudiogramVideoTypesModal,
  onClickStartWizardDestination,
} from 'redux/modules/mixpanel';
import { ClickAudiogramVideoTypesModalProperties } from 'redux/modules/mixpanel/types';
import { hideModal } from 'redux/modules/modal/actions';
import { Dispatch } from 'redux/types';
import {
  DESTINATION_ID_TO_MIXPANEL_DESTINATION_MAP,
  VIDEO_TYPE_TO_MIXPANEL_VIDEO_TYPE_MAP,
} from './constants';
import { videoTypesModalContentsBlock as block } from './utils';

export interface VideoTypesModalContentsProps {
  destination: AudiogramDestinationConfig;
  onBackClick?: () => void;
}

const VideoTypesModalContents: React.FC<VideoTypesModalContentsProps> = ({
  destination,
  onBackClick,
}) => {
  const { icon, videoTypes } = destination;
  const dispatch = useDispatch<Dispatch>();

  const handleVideoTypeClick = (videoType: VideoTypeConfig) => {
    // TS cannot infer the type of this object - it doesn't know that because
    // destination.id is constrained to MultiPathAudiogramDestination that the
    // destination map will return MixpanelAudiogramVideoTypesPlatform.  TS can
    // also not infer videoType for a similar reason
    const properties = {
      platform: DESTINATION_ID_TO_MIXPANEL_DESTINATION_MAP[destination.id],
      videoType: VIDEO_TYPE_TO_MIXPANEL_VIDEO_TYPE_MAP[videoType.id],
    } as ClickAudiogramVideoTypesModalProperties;
    dispatch(onClickStartWizardDestination(videoType.destination));
    dispatch(onClickAudiogramVideoTypesModal(properties));
    dispatch(hideModal());
  };

  return (
    <Modal
      bodyClassName={block('body')}
      className={block()}
      footer={<ModalFooter className={block('footer')} />}
    >
      <AudiogramDestinationVideoTypesList
        title="Choose a video type"
        icon={icon}
        videoTypes={videoTypes}
        onClick={handleVideoTypeClick}
        onBackClick={onBackClick}
      />
    </Modal>
  );
};

export default VideoTypesModalContents;
