import { ParsedQuery, ParseOptions } from 'query-string';
import { useLocation } from 'react-router';
import { parseSearch } from 'utils/routes';

export function useQuery(
  options: { parseBooleans: true; parseNumbers: true } & ParseOptions,
): ParsedQuery<string | boolean | number>;
export function useQuery(
  options: { parseBooleans: true } & ParseOptions,
): ParsedQuery<string | boolean>;
export function useQuery(
  options: { parseNumbers: true } & ParseOptions,
): ParsedQuery<string | number>;
export function useQuery(options?: ParseOptions): ParsedQuery;
export function useQuery(opts?: ParseOptions) {
  const location = useLocation();
  return parseSearch(location.search, opts);
}

export default useQuery;
