import * as types from '../../../../types';
import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'EMBED_CONFIGURATION_SERVICE';
export const ACTION_KEY: ActionKey = 'EMBED_CONFIGURATION_SERVICE';

export enum ServiceMethod {
  CREATE_CONFIGURATION = 'CREATE_CONFIGURATION',
  GET_CONFIGURATION = 'GET_CONFIGURATION',
}

export interface IEmbedConfigurationResponse {
  configuration: types.IEmbedConfiguration;
}

export type GetConfigurationArgs = [
  string, // wid
];
export type GetConfigurationResult = IEmbedConfigurationResponse;

export type CreateConfigurationArgs = [
  number, // expectedDurationMilli
  types.ISlideshowItem[],
  types.ITextOverlayItem[],
  types.IAudioInfo[],
  types.IVideoClip[],
  types.IVersionInfo,
  number, // recording id
  types.IDimensions,
  types.ISoundwave,
  types.IWatermark[],
  types.ICaptions,
  string, // creator user id
  string[], // layers
  types.MediaContainer,
  types.Progress,
  types.Timer[],
  types.IEdgeVideos,
  boolean, // hideAvatars
  boolean, // bigPlay
  string, // theme
];

export type CreateConfigurationResult = IEmbedConfigurationResponse;

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export type GetConfigurationAction = ApiAction<
  ServiceMethod.GET_CONFIGURATION,
  GetConfigurationArgs
>;

export type CreateConfigurationAction = ApiAction<
  ServiceMethod.CREATE_CONFIGURATION,
  CreateConfigurationArgs
>;

export interface IHandle {
  (
    method: ServiceMethod.GET_CONFIGURATION,
    args: GetConfigurationArgs,
    token?: string,
  ): Promise<GetConfigurationResult>;
  (
    method: ServiceMethod.CREATE_CONFIGURATION,
    args: CreateConfigurationArgs,
    token?: string,
  ): Promise<CreateConfigurationResult>;
}

export interface EmbedConfigurationServiceDispatch {
  (action: GetConfigurationAction): Promise<
    IApiResponse<GetConfigurationResult>
  >;
  (action: CreateConfigurationAction): Promise<
    IApiResponse<CreateConfigurationResult>
  >;
}
