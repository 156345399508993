import { Map } from 'immutable';

import { AspectRatioName, createMap } from 'types';
import { DEFAULT_TEXT_SIZES, VIEWPORTS } from './constants';

/*
 * These utils use dto live in containers/ProjectWizards/AddTextField/utils
 * but were moved here so they can be used outside of that module.
 *
 * This code is a little stale - there are better ways to handle these things
 * but keeping it as is for legacy right now until we can revisit and update everything
 */
export const getSize = (aspectRatioName: AspectRatioName) => {
  const viewport = VIEWPORTS[aspectRatioName];

  if (viewport) {
    const { width, height } = DEFAULT_TEXT_SIZES[aspectRatioName];
    return Map({
      height: height.toUnit('px', viewport).value,
      width: width.toUnit('px', viewport).value,
    });
  }

  return undefined;
};

export const getPosition = (aspectRatioName: AspectRatioName) => {
  const viewport = VIEWPORTS[aspectRatioName];
  const size = getSize(aspectRatioName);

  if (viewport && size) {
    return createMap({
      left: (viewport.width - size.get('width')) / 2,
      top: (viewport.height - size.get('height')) / 2,
    });
  }

  return undefined;
};

export const getViewportMap = (aspectRatioName: AspectRatioName) => {
  const viewport = VIEWPORTS[aspectRatioName];

  return createMap(viewport);
};
