import * as React from 'react';
import Spinner from 'react-spinkit';

import useConfirmEmailDispatch from './useConfirmEmailDispatch';
import { block } from './utils';

const { useEffect } = React;

const ConfirmEmailPage: React.FC = () => {
  const { onConfirm } = useConfirmEmailDispatch();

  useEffect(() => {
    onConfirm();
  }, [onConfirm]);

  return (
    <div className={block()}>
      <div className={block('container')}>
        <Spinner
          className="spinner--blue spinner--wave spinner--large"
          spinnerName="wave"
        />
      </div>
    </div>
  );
};

export default ConfirmEmailPage;
