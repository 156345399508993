import * as React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import EmailVerificationSuccessModalContent from './EmailVerificationSuccessModalContent';
import { block } from './utils';

const EmailVerificationSuccessModal = () => {
  return (
    <ConnectedModal
      name="EmailVerificationSuccess"
      title="Email verified"
      className={block()}
    >
      <EmailVerificationSuccessModalContent />
    </ConnectedModal>
  );
};

export default EmailVerificationSuccessModal;
