import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { PurchaseableTier } from 'redux/middleware/api/plan-service';
import {
  savePaymentMethodStatusSelector,
  subtierSelector,
  tierSelector,
  updateSubscriptionStatusSelector,
} from 'redux/modules/pricing';
import { combineRequestStatuses } from 'redux/selectors';
import { RequestStatus } from 'types/common';
import { isPurchaseableSubTier, isPurchaseableTier } from 'utils/pricing';

const statusSelector = createSelector(
  [savePaymentMethodStatusSelector, updateSubscriptionStatusSelector],
  combineRequestStatuses,
);

export default function(tierForSale: PurchaseableTier) {
  const subscribedTier = useSelector(tierSelector);
  const subTier = useSelector(subtierSelector);
  const status = useSelector(statusSelector);

  // user might load welcome step when already subscribed to a pro plan (e.g.
  // sign up during onboarding and then reload the page)
  const isAlreadySubscribed = isPurchaseableSubTier(subTier);

  // after completing the subscription flow, subTier will still be unset or will
  // show free.  use the request status and the intent query param to determine
  // what the user just signed up for
  const justSubscribed =
    status === RequestStatus.SUCCESS && isPurchaseableTier(tierForSale);

  // user should only see upsell completed step when this is true
  const isUpsellCompleted = isAlreadySubscribed || justSubscribed;

  // this is the tier used for loading the upsell completed step.  if the user is
  // already subscribed, the subscribedTier is accurate, otherwise if they just
  // finished the subscription flow, use the intent from the query string
  const purchasedTier =
    isAlreadySubscribed && isPurchaseableTier(subscribedTier)
      ? subscribedTier
      : justSubscribed
      ? tierForSale
      : undefined;

  return {
    isUpsellCompleted,
    status,
    purchasedTier,
  };
}
