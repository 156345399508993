import cn from 'classnames';
import React from 'react';

import CreateProjectButton from 'blocks/TemplateTabs/components/CreateProjectButton';
import UpgradeToProButton from 'blocks/TemplateTabs/components/UpgradeToProButton';
import ProIndicator from 'containers/ProIndicator';
import { UserGeneratedEmptyCTA, UserGeneratedEmptyFrom } from '../types';
import { blockUserGenerated, userGeneratedEmptyTitleMessages } from '../utils';
import UserGeneratedEmptyDescription from './UserGeneratedEmptyDescription';

export interface UserGeneratedEmptyProps {
  className?: string;
  from: UserGeneratedEmptyFrom;
  cta: UserGeneratedEmptyCTA;
  onClick?: () => void;
}

const UserGeneratedEmpty: React.FC<UserGeneratedEmptyProps> = ({
  className,
  from,
  cta,
  onClick,
}) => {
  const handleClickCreateNewProject = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <ProIndicator>
      {({ isFree }) => (
        <div
          className={cn(
            blockUserGenerated(),
            cta === 'learnMore' && 'learn-more-container',
            className,
          )}
        >
          <span className={blockUserGenerated('title')}>
            {isFree
              ? 'Save time with saved templates'
              : userGeneratedEmptyTitleMessages[from]}
          </span>
          <span className={blockUserGenerated('description')}>
            {isFree ? (
              'Upgrade to Pro so you can save any project as a template to make videos even faster.'
            ) : (
              <UserGeneratedEmptyDescription cta={cta} />
            )}
          </span>
          {isFree && <UpgradeToProButton />}
          {!isFree && cta === 'createTemplate' && (
            <CreateProjectButton onClick={handleClickCreateNewProject} />
          )}
        </div>
      )}
    </ProIndicator>
  );
};

export default UserGeneratedEmpty;
