import * as React from 'react';

import DeleteModal, { DeleteModalProps } from 'components/DeleteModal';
import bem from 'utils/bem';

type PickedModalProps = Pick<
  DeleteModalProps,
  'bodyClassName' | 'onCancel' | 'onDelete' | 'show'
>;

export interface PodcastDeleteModalProps extends PickedModalProps {
  podcastName: string;
}

const block = bem('podcast-delete-modal');

const PodcastDeleteModal: React.FC<PodcastDeleteModalProps> = ({
  onCancel,
  onDelete,
  podcastName,
  show,
}) => (
  <DeleteModal
    bodyClassName={block('body')}
    bodyText={
      <span className={block('text')}>
        Remove <span className={block('podcast-name')}>{podcastName}</span> and
        any automations you’ve setup for it? All your videos will still be
        accessible in the All Projects section.
      </span>
    }
    {...{ onCancel, onDelete, show }}
    deleteButtonTitle="Remove"
    title="remove podcast"
  />
);

export default PodcastDeleteModal;
