import React from 'react';

import { normalizeFontFamily } from 'components/RichTextEditor/tiny-utils';
import { FontV2 } from 'components/TextToolbar';

import { useTextOverlay } from '../state/TextOverlayProvider';
import { DEFAULT_FONT } from '../utils';

interface UseTextOverlayFontStyles {
  availableFonts: FontV2[];
  onChangeFontColor: (color: string) => void;
  onChangeFontSize: (value: number) => void;
  onSelectFont: (font: FontV2) => void;
  selectedFont: FontV2;
  selectedFontSize: number;
  selectedFontColor: string;
}

const useTextOverlayFontStyles = (): UseTextOverlayFontStyles => {
  const {
    draftEditorData,
    fonts: availableFonts,
    onUpdateStyle,
  } = useTextOverlay();

  const selectedFontName = normalizeFontFamily(
    draftEditorData.getIn(['editor', 'textStyle', 'fontFamily']),
  );
  const selectedFontSize = draftEditorData.getIn([
    'editor',
    'textStyle',
    'fontSize',
  ]);
  const selectedFontColor = draftEditorData.getIn([
    'editor',
    'textStyle',
    'color',
  ]);

  const handleSelectFont = React.useCallback(
    (font: FontV2): void => {
      onUpdateStyle(textStyle => textStyle.set('fontFamily', font.familyName));
    },
    [onUpdateStyle],
  );

  const handleChangeFontColor = React.useCallback(
    (color: string): void => {
      onUpdateStyle(textStyle => textStyle.set('color', color));
    },
    [onUpdateStyle],
  );

  const handleChangeFontSize = React.useCallback(
    (fontSize: number): void => {
      onUpdateStyle(textStyle =>
        textStyle.withMutations(s => s.set('fontSize', fontSize)),
      );
    },
    [onUpdateStyle],
  );

  const selectedFont =
    availableFonts.find(font => font.familyName === selectedFontName) ??
    availableFonts.find(font => font.familyName === DEFAULT_FONT);

  return {
    availableFonts,
    onChangeFontColor: handleChangeFontColor,
    onChangeFontSize: handleChangeFontSize,
    onSelectFont: handleSelectFont,
    selectedFont,
    selectedFontColor,
    selectedFontSize,
  };
};

export default useTextOverlayFontStyles;
