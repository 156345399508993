import { useCallback } from 'react';
import * as ids from 'short-id';
import { useNavigation } from './context/NavigationContext';
import { useEditorState } from './context/VideoEditorStateContext';
import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import { useModalManager } from './ModalManager';
import { isLottie } from './state/state-utils';
import { MediaIntegrationId } from './types';
import useImageSource, { OnImageProcessed } from './useImageSource';
import useVideoSource, { OnVideoProcessed } from './useVideoSource';

const useAddMedia = () => {
  const state = useTemplateState();
  const dispatch = useTemplateDispatch();
  const { integrations } = useEditorState();
  const { hideModal, showModal } = useModalManager();
  const [, send] = useNavigation();
  const mainImageIds = state.mainImage?.ids ?? [];
  const lottieIds = mainImageIds.filter(mainImageId =>
    isLottie(state.slideshow.data[mainImageId]),
  );

  const {
    dynamicImageIntegrations,
    mediaIntegrations,
    supportsDynamicImages,
    supportsStaticImages,
    staticImageIntegrations,
  } = integrations;

  const handleError = useCallback(
    (file, reason) => {
      dispatch({
        error: true,
        meta: { file },
        payload: reason,
        type: 'MEDIA_ADD_ERROR',
      });
    },
    [dispatch],
  );

  const handleMediaProcessed = useCallback(
    (id: string) => {
      send({
        type: 'CHILD_VIEW_OPEN',
        payload: 'image',
        meta: { source: 'options', id },
      });
    },
    [send],
  );

  const handleImageProcessed: OnImageProcessed = useCallback(
    payload => {
      const imageIds = lottieIds.length ? lottieIds : [ids.generate()];

      imageIds.forEach(id => {
        dispatch({
          payload: {
            ...payload,
            id,
          },
          type: 'IMAGE_ADD',
        });

        handleMediaProcessed(id);
      });
    },
    [dispatch, handleMediaProcessed, lottieIds],
  );

  const handleVideoProcessed: OnVideoProcessed = useCallback(
    (payload, integrationId) => {
      if (integrationId === MediaIntegrationId.AI_ASSET) {
        hideModal();
      }

      const id = ids.generate();
      dispatch({
        payload: {
          ...payload,
          id,
        },
        type: 'VIDEOCLIP_ADD',
      });

      handleMediaProcessed(id);
    },
    [dispatch, handleMediaProcessed, hideModal],
  );

  const handleAddMedia = () => {
    if (supportsDynamicImages && supportsStaticImages) {
      showModal('add-image-type', {
        onSubmit(newImageType) {
          if (newImageType === 'static') {
            handleAddStaticImage();
          } else {
            handleAddDynamicImage();
          }
        },
      });
    } else if (supportsDynamicImages) {
      handleAddDynamicImage();
    } else if (supportsStaticImages) {
      handleAddStaticImage();
    }
  };

  const processImage = useImageSource({
    onError: handleError,
    onImageProcessed: handleImageProcessed,
  });

  const processVideo = useVideoSource({
    onUploadAnotherFile: handleAddMedia,
    onError: handleError,
    onVideoProcessed: handleVideoProcessed,
  });

  const handleAddIntegrationImage = useCallback(
    (integrationId: string) => {
      const integration = !integrationId
        ? mediaIntegrations[0]
        : mediaIntegrations.find(i => i.id === integrationId);

      integration?.onAddMedia(
        {
          accept: (src, integrationData) => {
            if (integrationData.id !== MediaIntegrationId.AI_ASSET) {
              hideModal();
            }

            // when integration matches a video/gif upload video processor is used
            // instead of image processor.
            if (
              integrationData.id === MediaIntegrationId.VIDEOCLIP ||
              integrationData.id === MediaIntegrationId.GIF ||
              integrationData.id === MediaIntegrationId.AI_ASSET
            ) {
              processVideo(src, integrationData);
            } else {
              processImage(src, integrationData);
            }
          },
          reject: handleError,
        },
        state,
      );
    },
    [
      handleError,
      hideModal,
      mediaIntegrations,
      processImage,
      processVideo,
      state,
    ],
  );

  const handleAddStaticImage = useCallback(() => {
    if (staticImageIntegrations.length === 1) {
      handleAddIntegrationImage(staticImageIntegrations[0].id);
    } else if (staticImageIntegrations.length >= 2) {
      showModal('add-image', { onClick: handleAddIntegrationImage });
    }
  }, [handleAddIntegrationImage, showModal, staticImageIntegrations]);

  const handleAddDynamicImage = useCallback(() => {
    handleAddIntegrationImage(dynamicImageIntegrations[0].id);
  }, [dynamicImageIntegrations, handleAddIntegrationImage]);

  return handleAddMedia;
};

export default useAddMedia;
