import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import Draggable, { DraggableProps } from 'components/Draggable';
import FontAwesome from 'components/FontAwesome';
import { block } from '../../utils';

type PickedDragProps = Pick<
  DraggableProps,
  'bounds' | 'onDrag' | 'onStart' | 'onStop'
>;

export interface PlayheadProps extends PickedDragProps {
  hide?: boolean;
  onButtonClick?: (
    trackId: string,
    e: React.MouseEvent<HTMLButtonElement>,
  ) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  position: number;
}

export default class Playhead extends React.Component<PlayheadProps> {
  public static defaultProps: Partial<PlayheadProps> = {
    hide: false,
    onButtonClick: noop,
  };

  public shouldComponentUpdate(nextProps: Readonly<PlayheadProps>) {
    const { hide } = this.props;
    const { hide: nextHide } = nextProps;

    /*
     * no point to rendering when playhead is hidden, but if the playhead is
     * going from not-hidden to hidden, we have to render once to actually hide
     * the playhead.
     */
    if (nextHide && !hide) {
      return true;
    }

    return !nextHide;
  }

  public render() {
    const {
      hide,
      onButtonClick,
      onClick,
      onStart,
      onStop,
      position,
      ...props
    } = this.props;

    return (
      <Draggable
        {...props}
        cancel=".timeline__playhead-bar"
        handle="#playhead"
        onStart={onStart}
        onStop={onStop}
        position={{ x: position, y: 0 }}
      >
        <div
          className={cn(block('playhead', { hidden: hide }))}
          onClick={onClick}
          id="playhead"
          style={{ bottom: -26, position: 'absolute' }}
        >
          <FontAwesome
            className={block('playhead-topper')}
            icon="caret-down"
            size="2x"
          />
          <span className={block('playhead-bar')} />
        </div>
      </Draggable>
    );
  }
}
