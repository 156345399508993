import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import IconButton from 'components/IconButton';
import { block } from '../utils';

interface IProps {
  disabled?: 'up' | 'down' | 'both';
  onUpClick?: () => void;
  onDownClick?: () => void;
}

export const NumericSpinners: React.FC<IProps> = ({
  disabled,
  onUpClick,
  onDownClick,
}) => {
  const iconButtonProps = {
    className: block('numeric-spin-button'),
  };

  return (
    <div className={block('numeric-spinners')}>
      <IconButton
        {...iconButtonProps}
        disabled={disabled === 'up' || disabled === 'both'}
        onButtonClick={onUpClick}
        onLongButtonClick={onUpClick}
      >
        <FontAwesome icon="caret-up" />
      </IconButton>
      <IconButton
        {...iconButtonProps}
        disabled={disabled === 'down' || disabled === 'both'}
        onButtonClick={onDownClick}
        onLongButtonClick={onDownClick}
      >
        <FontAwesome icon="caret-down" />
      </IconButton>
    </div>
  );
};

export { IProps as NumericSpinnersProps };
export default NumericSpinners;
