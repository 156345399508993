import * as React from 'react';

import useModalMonitor from 'containers/ConnectedModal/useModalMonitor';
import { ModalName } from 'redux/modules/modal/types';
import AddTrackModal from '../containers/AddTrackModal';
import DeleteTrackModal from '../containers/DeleteTrackModal';

const MODAL_NAMES: ModalName[] = ['AddTrackModal', 'DeleteTrackModal'];

export interface TimelineModalsProps {
  onHide?: () => void;
  onShow?: () => void;
  onSubmit?: (type: string) => void;
}

const TimelineModals: React.FC<TimelineModalsProps> = ({
  onHide,
  onShow,
  onSubmit,
}) => {
  const handleSubmit = type => {
    onSubmit(type);
  };

  useModalMonitor({
    names: MODAL_NAMES,
    onHide,
    onShow,
  });

  return (
    <>
      <AddTrackModal onSubmit={handleSubmit} />
      <DeleteTrackModal />
    </>
  );
};

export default TimelineModals;
