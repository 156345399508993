import * as React from 'react';
import { intersperse } from 'utils/collections';

import { LinkTarget } from './types';

const LINK_PATTERN = '{{link}}';

export interface LinkNotificationProps {
  href?: string;
  label: string;
  linkTarget?: LinkTarget;
  onClick?: () => void;
  text: string;
}

const LinkNotification: React.SFC<LinkNotificationProps> = ({
  href,
  label,
  linkTarget,
  onClick,
  text,
}) => {
  const linkProps = href
    ? { href }
    : {
        onClick: e => {
          e.preventDefault();
          onClick && onClick();
        },
      };

  return (
    <>
      {intersperse(text.split(LINK_PATTERN), index => (
        <a key={index} target={linkTarget} {...linkProps}>
          {label}
        </a>
      ))}
    </>
  );
};

LinkNotification.defaultProps = {
  linkTarget: '_blank',
};

export default LinkNotification;
