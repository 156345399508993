import * as React from 'react';

import VideoExportLink from 'containers/VideoExportLink';

export interface VideoExportSuccessNotificationProps {
  className?: string;
}

const VideoExportSuccessNotification: React.SFC<VideoExportSuccessNotificationProps> = ({
  className,
}) => (
  <div className={className}>
    Download it <VideoExportLink />
  </div>
);

export default VideoExportSuccessNotification;
