import * as React from 'react';

import Tabs, { TabsProps } from 'components/Tabs';
import useLoadTemplateList from 'containers/ProjectWizards/useLoadTemplateList';
import { AspectRatioName } from 'types';
import {
  TEMPLATE_TAB_KEY_ALL,
  TEMPLATE_TAB_KEY_REFRESH_TEMPLATE_LIST,
} from 'utils/constants';
import { GetItemKey, ITabContent } from '../types';
import { block } from '../utils';
import TemplateTabsGridContent from './TemplateTabsGridContent';
import TemplateTabsHeader from './TemplateTabsHeader';

export interface TemplateTabsProps extends Pick<TabsProps, 'overrides'> {
  centered?: boolean;
  contentItems?: ITabContent[];
  defaultSelectedKey?: string;
  getItemKey?: GetItemKey;
  aspectRatioName?: AspectRatioName;
}

const { useState } = React;

const TemplateTabs: React.SFC<TemplateTabsProps> = ({
  centered,
  contentItems,
  defaultSelectedKey,
  getItemKey,
  overrides,
  aspectRatioName,
}) => {
  const [selectedTabKey, setSelectedTabKey] = useState(defaultSelectedKey);
  const { loadTemplateList } = useLoadTemplateList();

  const handleClickTabHeader = (tabKey: string) => {
    if (tabKey === TEMPLATE_TAB_KEY_REFRESH_TEMPLATE_LIST && aspectRatioName) {
      loadTemplateList(aspectRatioName);
      return;
    }

    setSelectedTabKey(tabKey);
  };

  return (
    <Tabs
      activeTabKey={selectedTabKey}
      className={block({ centered })}
      onTabSelect={handleClickTabHeader}
      overrides={{
        content: ({ children }) => <>{children}</>,
        header: TemplateTabsHeader,
        tabsContent: ({ children }) => (
          <div className={block('content', { grid: true })}>{children}</div>
        ),
        ...overrides,
      }}
      tabs={contentItems.map(item => ({
        content: (
          <TemplateTabsGridContent
            active={item.key === selectedTabKey}
            key={item.key}
            emptyContent={item.emptyContent}
            getItemKey={getItemKey}
            items={item.items}
            tabKey={item.key}
          />
        ),
        tabKey: item.key,
        title: item.title,
        titleClassName:
          item?.titleClassName ??
          block('header-item', {
            active: item.key === selectedTabKey,
          }),
      }))}
    />
  );
};

TemplateTabs.defaultProps = {
  centered: false,
  defaultSelectedKey: TEMPLATE_TAB_KEY_ALL,
};

export default TemplateTabs;
