import {
  CaptionsMediaSourceType,
  DeepImmutableMap,
  IImmutableMap,
} from 'types';
import { TextTemplate } from 'utils/text-templates';

export interface ITextShadow {
  color: string;
  x: number;
  y: number;
  blur: number;
}

export type Viewport = IImmutableMap<TextTemplate['viewport']>;
export type Style = DeepImmutableMap<
  TextTemplate & {
    templateName: string;
    textBoxHeight: number;
  }
>;
export type CaptionsTemplate = Style;

export interface ICaptions {
  mediaSourceType: CaptionsMediaSourceType;
  mediaSourceId: number | string;
  enabled: boolean;
  shouldRechunkCaptionsOnEditorLoad?: boolean;
  style: Style;
  transcriptId?: string;
  transcriptRevisionId?: string;
}

export type Captions = IImmutableMap<ICaptions>;

export interface ITemplateScaler {
  vhToPx: (number) => number;
  vwToPx: (number) => number;
}

export enum AspectRatio {
  square,
  portrait,
  landscape,
}
