import * as React from 'react';

import { IconProps } from './types';

const ArrowDownDash: React.FC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="15"
    fill="currentColor"
    {...props}
  >
    <path
      d="M19 13a1 1 0 1 1 0 2H1a1 1 0 1 1 0-2h18zM16.475.43l1.127 1.08c.468.449.468 1.175 0 1.62l-6.757 6.497a1.223 1.223 0 0 1-1.69.005L2.398 3.134a1.111 1.111 0 0 1 0-1.62L3.52.435a1.221 1.221 0 0 1 1.685 0L10 5.037 14.79.43a1.221 1.221 0 0 1 1.685 0z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default ArrowDownDash;
