import { Record, Stack } from 'immutable';

export interface IStep<TStepId extends string = string> {
  footerClassName?: string;
  hideFooter?: boolean;
  id: TStepId;
  modalClassName?: string;
  onSubmit?: () => void;
  component: JSX.Element;
  renderFooterButtons?: FooterButtons | FooterButtonsFactory;
  replaceBody?: boolean;
  fluidFooterButtons?: boolean;
  submitButtonLabel?: string;
  title?: string;
}

export interface IStepHistory {
  active: string;
  push: (id: string) => void;
  pop: () => void;
  replace: (id: string) => void;
}

interface IButtonProps {
  disabled: boolean;
  onClick: () => void;
}

export interface IFooterButtonProps {
  back: IButtonProps;
  cancel: IButtonProps;
  submit: IButtonProps;
}

export enum FooterButtonType {
  BACK = 'BACK',
  CANCEL = 'CANCEL',
  SUBMIT = 'SUBMIT',
}
export type FooterButtons = JSX.Element[];
export type FooterButtonsFactory = (props: IFooterButtonProps) => JSX.Element[];
export type FooterButtonsDisabled = (action: FooterButtonType) => boolean;

export type StepList = IStep[];
export type StepListFactory = (history: IStepHistory) => StepList;

export interface IDataState {
  stepHistory: Stack<string>;
}

export const dataFactory = Record<IDataState>({ stepHistory: undefined });
