import * as React from 'react';

import { IconProps } from 'components/icons/types';

/* tslint:disable-next-line:variable-name */
const ControlsIcon: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="14"
    viewBox="0 0 16 14"
    {...props}
  >
    <defs>
      <path
        id="controlIconPath"
        d="M16.262 10.87H9.16c-.288-.768-1.024-1.328-1.888-1.328-.863 0-1.616.56-1.888 1.328H.937c-.336 0-.336 0-.336.672 0 .671 0 .671.336.671h4.447c.288.768 1.024 1.328 1.888 1.328.864 0 1.616-.56 1.888-1.328h7.102c.337 0 .337 0 .337-.671 0-.672 0-.672-.337-.672zm0-4.671h-1.776C14.198 5.43 13.463 4.87 12.6 4.87c-.864 0-1.616.56-1.888 1.328H.936c-.336 0-.336 0-.336.672 0 .671 0 .671.336.671h9.775c.288.768 1.023 1.328 1.888 1.328.863 0 1.616-.56 1.887-1.328h1.776c.337 0 .337 0 .337-.671 0-.672 0-.672-.337-.672zM.938 2.87h1.776C3.002 3.64 3.737 4.2 4.6 4.2c.864 0 1.616-.56 1.888-1.328h9.775c.336 0 .336 0 .336-.671 0-.672 0-.672-.336-.672H6.489C6.201.76 5.466.2 4.601.2c-.863 0-1.616.56-1.887 1.328H.938C.6 1.528.6 1.528.6 2.2c0 .671 0 .671.337.671z"
      />
    </defs>
    <use
      fill="currentColor"
      fillRule="nonzero"
      transform="translate(-.6 .4)"
      xlinkHref="#controlIconPath"
    />
  </svg>
);

export default ControlsIcon;
