import { stringifyUrl } from 'query-string';
import bem from 'utils/bem';

export const block = bem('media-upload-modal');

export function buildImportedFileUrl(
  baseUrl: string,
  params: Record<string, string>,
): string {
  return stringifyUrl({ url: baseUrl, query: params });
}

export async function fetchImportedFilePreviewUrl(
  baseUrl: string,
  params: Record<string, string>,
  headers: Record<string, string>,
): Promise<string> {
  const url = buildImportedFileUrl(baseUrl, params);

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers,
    });

    const blob = await response.blob();
    const videoUrl = URL.createObjectURL(blob);

    return videoUrl;
  } catch {
    throw new Error('Sorry, something went wrong');
  }
}
