import { ShareStatus, SocialPost } from './types';

export function isShareStatusResolved(
  status: ShareStatus | SocialPost,
  extraStatus: ShareStatus[] = [],
): boolean {
  const statusValue = typeof status === 'string' ? status : status.shareStatus;
  return !['completed', 'error', 'errorAck', ...extraStatus].includes(
    statusValue,
  );
}

export function hasShareFailed(
  status: ShareStatus | SocialPost,
  extraStatus: ShareStatus[] = [],
): boolean {
  const statusValue = typeof status === 'string' ? status : status.shareStatus;
  return ['error', 'errorAck', ...extraStatus].includes(statusValue);
}
