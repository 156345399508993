import { actions as videoExportServiceActions } from 'redux/middleware/api/video-export-service';
import { ThunkAction } from 'redux/types';
import reduxPoll, { cancelReduxPoll } from 'utils/redux-poll';

const EXPORT_POLL_ID = 'app/common/video-export-poll';

export const waitForExport = (
  widgetId: string,
  pollConfig: { intervalMillis?: number } = {},
): ThunkAction<Promise<void>> => async dispatch => {
  const {
    intervalMillis = spareminConfig.videoExportPollIntervalMillis,
  } = pollConfig;

  await reduxPoll(
    dispatch,
    () => dispatch(videoExportServiceActions.fetchVideoExportStatus(widgetId)),
    {
      id: EXPORT_POLL_ID,
      intervalMillis,
      maxAttempts: spareminConfig.videoExportPollMaxAttempts,
      retryAttempts: spareminConfig.videoExportRetryAttempts,
      shouldContinue: (err, res) => {
        if (err) return false;
        const { response } = res;
        const id = response.result;
        const embedExport = response.entities.embedExports[id];
        if (embedExport.status === 'error') {
          throw new Error('Error generating video');
        }
        return !embedExport.isResolved;
      },
    },
  );
};

export const cancelWaitForExport = (): ThunkAction<void> => dispatch => {
  cancelReduxPoll(dispatch, EXPORT_POLL_ID);
};
