import React, { FC } from 'react';

import FontAwesome from 'components/FontAwesome';

import LinkButton from 'components/LinkButton';
import { block } from './utils';

export interface AssetTileIncompatibleTemplateProps {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const AssetTileIncompatibleTemplate: FC<AssetTileIncompatibleTemplateProps> = props => {
  const { onClick } = props;

  return (
    <div className={block('incompatible-template')}>
      <div className={block('icon-container')}>
        <FontAwesome
          className={block('alert-icon')}
          icon="exclamation-circle"
          style={{ height: 36, width: 36 }}
        />
      </div>

      <span className={block('incompatible-message')}>
        This template only supports 10 min of audio:
      </span>
      <LinkButton
        theme="cta"
        type="button"
        className={block('learn-more-button')}
        onClick={onClick}
      >
        learn more
      </LinkButton>
    </div>
  );
};

export default AssetTileIncompatibleTemplate;
