import * as React from 'react';

import VideoStyleOptions, {
  VideoStyleOptionsProps,
} from 'blocks/VideoStyleOptions';
import ScrollBars from 'components/ScrollBars';
import { block } from '../utils';
import AssetsPaneContent from './AssetsPaneContent';

export type StyleTabProps = Pick<
  VideoStyleOptionsProps,
  'onModalHide' | 'onModalShow'
>;

const StyleTab: React.SFC<StyleTabProps> = props => (
  <ScrollBars>
    <AssetsPaneContent className={block('style-tab')}>
      <VideoStyleOptions {...props} />
    </AssetsPaneContent>
  </ScrollBars>
);

export default StyleTab;
