import { Checkbox, Spacer } from '@sparemin/blockhead';
import React from 'react';
import { H3 } from 'components/Heading';
import { Season } from 'redux/modules/automation-cms/types';
import { block } from '../utils';

export interface SeasonListItemProps extends Pick<Season, 'episodeCount'> {
  season: string | number;
  isSelected: boolean;
  onChange: (value: boolean) => void;
}

const SeasonListItem: React.FC<SeasonListItemProps> = ({
  season,
  episodeCount,
  isSelected,
  onChange,
}) => {
  return (
    <Checkbox
      {...{ isSelected, onChange }}
      className={block('season-checkbox')}
    >
      <Spacer
        orientation="vertical"
        align="start"
        justify="start"
        className={block('season-info-container')}
      >
        <H3>{season}</H3>
        <p
          className={block('description')}
        >{`${episodeCount?.toLocaleString()} ${
          episodeCount === 1 ? 'episode' : 'episodes'
        }`}</p>
      </Spacer>
    </Checkbox>
  );
};

export default SeasonListItem;
