import * as React from 'react';

import { getDisplayName } from 'utils/react';
import QueryStringAuthenticator, {
  QueryStringAuthenticatorProps,
} from './QueryStringAuthenticator';
import RedirectWithoutToken from './RedirectWithoutToken';
import { splitProps } from './utils';

type AuthenticatorOptions = Pick<
  QueryStringAuthenticatorProps,
  'accessTokenName'
>;

/**
 * props that get passed to "authentication state" components - e.g. components that get rendered
 * during the "authenticating" or "failed" states.
 */
type AuthStateComponentProps = Pick<
  QueryStringAuthenticatorProps,
  'accessTokenName'
>;

interface IOptions extends AuthenticatorOptions {
  AuthenticatingComponent?: React.ComponentType<AuthStateComponentProps>;
  FailureComponent?: React.ComponentType<AuthStateComponentProps>;
}

/**
 * HOC for using QueryStringAuthenticator.
 *
 * QueryStringAuthenticator can be used on its own, but logging in with credentials is handled by
 * auth HOCs in routes.jsx.  By using the withQueryStringAuth HOC route components can be wrapped
 * in a similar way to the other auth HOCs, making it clear how all authentication is handled just
 * by looking at routes.jsx.
 */

export default function({
  accessTokenName,
  AuthenticatingComponent = () => null,
  FailureComponent = () => null,
}: IOptions) {
  return <T extends {}>(Component: React.ComponentType<T>) => {
    type Props = QueryStringAuthenticatorProps & T;
    const WithQueryStringAuth: React.SFC<Props> = props => {
      const { authenticate, isAuthenticated, isAuthenticating } = props;
      const { routerProps, componentProps } = splitProps(props);
      const authStateProps = { ...routerProps, accessTokenName };
      return (
        <QueryStringAuthenticator
          {...routerProps}
          accessTokenName={accessTokenName}
          authenticate={authenticate}
          isAuthenticated={isAuthenticated}
          isAuthenticating={isAuthenticating}
          render={({ token }) => {
            /*
             * token is considered optional.  if it doesn't exist, render the wrapped component and
             * let redux-auth-wrapper handle auth. redux-auth-wrapper will need the router, so pass
             * through routerProps as well.
             */
            if (!token) {
              return <Component {...routerProps} {...componentProps} />;
            }

            /**
             * there is a token in the url and isAuthenticating is true, so render the
             * AuthenticatingComponent
             */
            if (isAuthenticating) {
              return <AuthenticatingComponent {...authStateProps} />;
            }

            /**
             * token in url and user is authenticated.  redirect to the same url without the access
             * token query param, handing off to redux-auth-wrapper
             */
            if (isAuthenticated) {
              return <RedirectWithoutToken {...authStateProps} />;
            }

            /**
             * token in url, authentication not in progress and user is not authenticated.
             * something went wrong, so render the FailureComponent
             */
            return <FailureComponent {...authStateProps} />;
          }}
        />
      );
    };

    WithQueryStringAuth.displayName = `WithQueryStringAuth(${getDisplayName(
      Component,
    )})`;

    return WithQueryStringAuth;
  };
}

export { IOptions as Options };
