import { constants as exportConstants } from 'redux/modules/embed-export';
import bem from 'utils/bem';

import { IntroOutroFormState, VideoExportStatus } from './types';

export const block = bem('export-modal');

export function isExportComplete(
  videoExportStatus: VideoExportStatus,
  widgetId: string,
) {
  const status = videoExportStatus && videoExportStatus.get('status');

  const statusObjDefined = !!videoExportStatus;
  const statusIsForThisWid =
    statusObjDefined && videoExportStatus.get('widgetId') === widgetId;
  const exportIsComplete =
    status === exportConstants.VIDEO_EXPORT_STATE_COMPLETED;

  return statusIsForThisWid && exportIsComplete;
}

/**
 * Gets the correct display value for an input field as depending on if it is a File
 * or not the value has to be replaced with "Uploaded"
 * @param {string | number} fileName - Current file name
 * @param {boolean} isFile - Wheter the selected element is a file or not
 * @returns {string | undefined} - Parsed value for the file name.
 */
export const getFileInputValue = (
  fileName: IntroOutroFormState['value'],
  type: IntroOutroFormState['type'],
): string | undefined => {
  if (type === 'file') {
    return fileName ? String(fileName) : undefined;
  }
  return fileName ? 'Uploaded' : undefined;
};
