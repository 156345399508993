import classNames from 'classnames';
import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import _ from 'underscore';

import AlignmentGuidelines from 'components/AlignmentGuidelines/AlignmentGuidelines';
import { CENTERING_LINES } from 'components/AlignmentGuidelines/constants';
import { OnSnapDrag } from 'components/AlignmentGuidelines/types';
import useSnapLines from 'components/AlignmentGuidelines/useSnapLines';
import DraggableWorkspace, {
  OnSizeChange,
} from 'components/DraggableWorkspace';
import InfoboxOverlay from 'components/InfoboxOverlay';
import Rnd from 'components/Rnd';
import { UseHook } from 'components/UseHook';
import { EditorVideoFramePreview } from 'containers/VideoFramePreview';
import { getValue } from 'utils/collections';
import { scalePixelToVhVw, scaleVhVwToPixel } from 'utils/embed/ui';
import { createChainedFunction } from 'utils/functions';
import { Pixels } from 'utils/measurement';
import { fitElement, isFill, numberOrPxToPx, scale } from 'utils/placement';
import { IWatermark } from '../../types/embed-config';
import { IWatermarkPosition, IWatermarkSize } from './types';
import { logoModalBlock } from './utils';

export interface IProps {
  aspectRatio?: number;
  className?: string;
  logoImageSrc?: string;
  onChangeWatermarkPosition?: (watermarkPosition: IWatermarkPosition) => void;
  onChangeWatermarkSize?: (watermarkSize: IWatermarkSize) => void;
  watermark?: IWatermark;
}

interface IUiState {
  dragging: boolean;
  resizing: boolean;
  workspaceHeight: number;
  workspaceWidth: number;
  watermarkPositionLeft: number;
  watermarkPositionTop: number;
  watermarkHeight: number;
  watermarkWidth: number;
}

const uiFactory = Record<IUiState>({
  dragging: false,
  resizing: false,
  watermarkHeight: undefined,
  watermarkPositionLeft: undefined,
  watermarkPositionTop: undefined,
  watermarkWidth: undefined,
  workspaceHeight: undefined,
  workspaceWidth: undefined,
});

interface IState {
  ui: RecordOf<IUiState>;
}

class LogoPreview extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    aspectRatio: 1,
    watermark: null,
  };

  private image: HTMLImageElement;

  constructor(props: IProps) {
    super(props);

    this.state = {
      ui: uiFactory({
        dragging: false,
        resizing: false,
        watermarkHeight: 0,
        watermarkPositionLeft: 0,
        watermarkPositionTop: 0,
        watermarkWidth: 0,
        workspaceHeight: 0,
        workspaceWidth: 0,
      }),
    };
  }

  private setWatermarkPosition = () => {
    const { onChangeWatermarkPosition } = this.props;
    const { ui } = this.state;
    const workspaceHeight = ui.get('workspaceHeight');
    const workspaceWidth = ui.get('workspaceWidth');
    const watermarkPositionLeft = ui.get('watermarkPositionLeft');
    const watermarkPositionTop = ui.get('watermarkPositionTop');

    onChangeWatermarkPosition({
      left: scalePixelToVhVw(watermarkPositionLeft, workspaceWidth, 'vw'),
      top: scalePixelToVhVw(watermarkPositionTop, workspaceHeight, 'vh'),
    });
  };

  private setWatermarkSize = () => {
    const { onChangeWatermarkSize } = this.props;
    const { ui } = this.state;
    const workspaceHeight = ui.get('workspaceHeight');
    const workspaceWidth = ui.get('workspaceWidth');
    const watermarkHeight = ui.get('watermarkHeight');
    const watermarkWidth = ui.get('watermarkWidth');

    onChangeWatermarkSize({
      height: scalePixelToVhVw(watermarkHeight, workspaceHeight, 'vh'),
      width: scalePixelToVhVw(watermarkWidth, workspaceWidth, 'vw'),
    });
  };

  private handleWorkspaceSizeChange: OnSizeChange = workspaceSize => {
    const { height, width } = workspaceSize;

    this.setState(({ ui }) => ({
      ui: ui.withMutations(u =>
        u
          .set('workspaceHeight', height)
          .set('workspaceWidth', width)
          .set('watermarkPositionLeft', 0)
          .set('watermarkPositionTop', 0),
      ),
    }));
  };

  private handleWorkspaceDoubleClick = () => {
    const { ui } = this.state;
    const workspace = {
      height: new Pixels(ui.workspaceHeight),
      width: new Pixels(ui.workspaceWidth),
    };
    const watermark = {
      height: new Pixels(ui.watermarkHeight),
      left: new Pixels(ui.watermarkPositionLeft),
      top: new Pixels(ui.watermarkPositionTop),
      width: new Pixels(ui.watermarkWidth),
    };

    const newDimensions = fitElement(
      watermark,
      workspace,
      isFill(watermark, workspace) ? 'fit' : 'fill',
    );

    this.setState(({ ui: currentUi }) => ({
      ui: currentUi.withMutations(u => {
        u.set('watermarkHeight', newDimensions.height.value);
        u.set('watermarkWidth', newDimensions.width.value);
        u.set('watermarkPositionLeft', newDimensions.left.value);
        u.set('watermarkPositionTop', newDimensions.top.value);
        return u;
      }),
    }));
  };

  private throttledHandleWorkspaceWheel = _.throttle(
    (e: React.WheelEvent<Element>) =>
      this.setState(
        ({ ui }) => {
          const scaled = scale(
            numberOrPxToPx({
              height: ui.watermarkHeight,
              left: ui.watermarkPositionLeft,
              top: ui.watermarkPositionTop,
              width: ui.watermarkWidth,
            }),
            e.deltaY * 0.001,
            { minArea: 500 },
          );
          return {
            ui: ui.withMutations(u => {
              u.set('watermarkHeight', scaled.height.value);
              u.set('watermarkWidth', scaled.width.value);
              u.set('watermarkPositionLeft', scaled.left.value);
              u.set('watermarkPositionTop', scaled.top.value);
              return u;
            }),
          };
        },
        () => {
          this.setWatermarkPosition();
          this.setWatermarkSize();
        },
      ),
    100,
  );

  private handleWorkspaceWheel = (e: React.WheelEvent<HTMLElement>) => {
    e.persist();
    this.throttledHandleWorkspaceWheel(e);
  };

  private handleDrag: OnSnapDrag = (_1, _2, { x, y }) => {
    this.setState(({ ui }) => ({
      ui: ui.withMutations(u =>
        u
          .set('watermarkPositionLeft', x)
          .set('watermarkPositionTop', y)
          .set('dragging', true),
      ),
    }));
  };

  private handleDragStop: OnSnapDrag = (_1, _2, { x, y }) => {
    this.setState(
      ({ ui }) => ({
        ui: ui.withMutations(u => {
          u.set('dragging', false);
          u.set('watermarkPositionLeft', x);
          u.set('watermarkPositionTop', y);
          return u;
        }),
      }),
      () => {
        this.setWatermarkPosition();
      },
    );
  };

  private handleResize = (__, ___, ref, ____, position) => {
    this.setState(({ ui }) => ({
      ui: ui.withMutations(u =>
        u
          .set('watermarkPositionLeft', position.x)
          .set('watermarkPositionTop', position.y)
          .set('watermarkHeight', ref.clientHeight)
          .set('watermarkWidth', ref.clientWidth)
          .set('resizing', true),
      ),
    }));
  };

  private handleResizeStop = () => {
    this.setWatermarkPosition();
    this.setWatermarkSize();
    this.setState(({ ui }) => ({
      ui: ui.set('resizing', false),
    }));
  };

  private handleImageLoad = () => {
    const { watermark } = this.props;
    const { ui: uiWorkspace } = this.state;

    const workspaceHeight = uiWorkspace.get('workspaceHeight');
    const workspaceWidth = uiWorkspace.get('workspaceWidth');

    const logoHeight = this.image.naturalHeight;
    const logoWidth = this.image.naturalWidth;

    const ratioHeight = logoHeight / workspaceHeight;
    const ratioWidth = logoWidth / workspaceWidth;

    const ratioAspect = Math.max(ratioWidth, ratioHeight, 1);

    const newHeight = logoHeight / ratioAspect;
    const newWidth = logoWidth / ratioAspect;

    const left = getValue(watermark, ['position', 'properties', 'left'], 0);
    const top = getValue(watermark, ['position', 'properties', 'top'], 0);
    const width = getValue(
      watermark,
      ['style', 'width'],
      scalePixelToVhVw(newWidth, workspaceWidth, 'vw'),
    );
    const height = getValue(
      watermark,
      ['style', 'height'],
      scalePixelToVhVw(newHeight, workspaceHeight, 'vh'),
    );

    // TODO remove "any" when utils is in ts
    const scaleVwToPixel: any = _.partial(scaleVhVwToPixel, _, workspaceWidth);
    const scaleVhToPixel: any = _.partial(scaleVhVwToPixel, _, workspaceHeight);

    this.setState(
      ({ ui }) => ({
        ui: ui.withMutations(u =>
          u
            .set('watermarkPositionLeft', scaleVwToPixel(left))
            .set('watermarkPositionTop', scaleVhToPixel(top))
            .set('watermarkHeight', scaleVhToPixel(height))
            .set('watermarkWidth', scaleVwToPixel(width)),
        ),
      }),
      () => {
        this.setWatermarkPosition();
        this.setWatermarkSize();
      },
    );
  };

  private setImageRef = (el: HTMLImageElement) => {
    this.image = el;
  };

  private renderResizeInfobox = () => {
    const { logoImageSrc } = this.props;
    const { ui } = this.state;

    return (
      logoImageSrc &&
      !ui.get('dragging') &&
      !ui.get('resizing') && (
        <InfoboxOverlay text="Drag corners of your watermark to resize" />
      )
    );
  };

  public render() {
    const { aspectRatio, className, logoImageSrc } = this.props;
    const { ui } = this.state;

    const workspaceHeight = ui.get('workspaceHeight');
    const workspaceWidth = ui.get('workspaceWidth');

    const previewClassName = classNames(logoModalBlock('preview'), className);
    const enableResizing = {
      bottom: false,
      bottomLeft: true,
      bottomRight: true,
      left: false,
      right: false,
      top: false,
      topLeft: true,
      topRight: true,
    };
    const workspaceSize = {
      height: ui.get('workspaceHeight'),
      width: ui.get('workspaceWidth'),
    };
    const watermarkPosition = {
      x: ui.get('watermarkPositionLeft'),
      y: ui.get('watermarkPositionTop'),
    };
    const watermarkSize = {
      height: ui.get('watermarkHeight'),
      width: ui.get('watermarkWidth'),
    };

    return (
      <div className={previewClassName}>
        <DraggableWorkspace
          aspectRatio={aspectRatio}
          className={logoModalBlock('draggable')}
          onDoubleClick={this.handleWorkspaceDoubleClick}
          onSizeChange={this.handleWorkspaceSizeChange}
          onWheel={this.handleWorkspaceWheel}
        >
          <div style={{ height: '100%', position: 'relative', width: '100%' }}>
            <div className={logoModalBlock('bg-image')}>
              <EditorVideoFramePreview
                aspectRatio={aspectRatio}
                canvasDimensions={
                  workspaceWidth && workspaceHeight
                    ? {
                        width: workspaceWidth,
                        height: workspaceHeight,
                      }
                    : undefined
                }
                backgroundFor={{
                  type: 'watermark',
                }}
              />
            </div>
            {this.renderResizeInfobox()}
            <UseHook
              hook={useSnapLines}
              args={[
                {
                  containerSize: workspaceSize,
                  lines: CENTERING_LINES,
                  onDrag: this.handleDrag,
                  onDragStop: this.handleDragStop,
                  size: watermarkSize,
                },
              ]}
            >
              {({
                activeLines,
                dragAxis,
                onDrag,
                onDragStart,
                onDragStop,
                onResizeStart,
                onResizeStop,
              }) => (
                <>
                  <AlignmentGuidelines
                    active={ui.dragging}
                    containerSize={workspaceSize}
                    lines={activeLines}
                  />
                  <Rnd
                    active
                    enableResizing={enableResizing}
                    lockAspectRatio
                    position={watermarkPosition}
                    size={watermarkSize}
                    onResize={this.handleResize}
                    onResizeStop={createChainedFunction(
                      onResizeStop,
                      this.handleResizeStop,
                    )}
                    {...{
                      dragAxis,
                      onDrag,
                      onDragStart,
                      onDragStop,
                      onResizeStart,
                    }}
                  >
                    {logoImageSrc && (
                      <img
                        className={logoModalBlock('logo-image')}
                        src={logoImageSrc}
                        onLoad={this.handleImageLoad}
                        ref={this.setImageRef}
                      />
                    )}
                  </Rnd>
                </>
              )}
            </UseHook>
          </div>
        </DraggableWorkspace>
      </div>
    );
  }
}

export default LogoPreview;
