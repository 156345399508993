import queryString from 'query-string';
import * as React from 'react';
import { RouteChildrenProps } from 'react-router';
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history3/redirect';
import _ from 'underscore';

import OnboardingAnimations from 'blocks/OnboardingAnimations';
import { OnboardingName } from 'blocks/OnboardingAnimations/types';
import LoadingScreen from 'components/LoadingScreen';
import VideoEditor from 'containers/VideoEditor';
import * as types from './types';

interface QueryParams {
  wid?: string;
  pid?: string;
  ar?: string;
  tid?: string;
  source?: string;
  fromWizard?: boolean;
}

export interface IProps
  extends RouteChildrenProps<{ pid?: string }>,
    InjectedAuthRouterProps {
  /**
   * @param {string} wid widget id from url query param
   * @param {string} pid project id from url path variable
   * @param {string} ar aspect ratio from query param
   * @param {string} tid templateId from query param
   */
  onMount?: (params: QueryParams) => void;
  onReady?: () => void;
  isLoadingData?: boolean;
  onUnmount?: () => void;
  style?: { [key: string]: any };
  redirectTo?: string;
}

export default class EditorPage extends React.Component<IProps, types.IState> {
  public static defaultProps: Partial<IProps> = {
    onMount: _.noop,
    onReady: _.noop,
    onUnmount: _.noop,
    redirectTo: undefined,
    style: {},
  };

  private setIsLoadingDataTaskId: NodeJS.Timer;

  constructor(props: IProps) {
    super(props);

    this.state = {
      ui: types.uiFactory({
        addSlideEndMillis: 0,
        isLoadingData: true,
        videoEditorRendered: false,
      }),
    };
  }

  public componentDidMount() {
    const { isLoadingData, onMount, location, match } = this.props;

    const params = match?.params ?? {};
    const query: QueryParams = queryString.parse(location.search, {
      parseBooleans: true,
    });
    const { ar, wid, tid, source, fromWizard } = query;
    const { pid } = params;

    if (!isLoadingData) {
      onMount({ wid, pid, ar, tid, source, fromWizard });
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const { isLoadingData } = this.props;
    const { isLoadingData: nextIsLoadingData } = nextProps;

    if (!isLoadingData && nextIsLoadingData) {
      this.setState(({ ui }) => ({
        ui: ui.set('isLoadingData', true),
      }));
    }
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<types.IState>,
  ) {
    const {
      isLoadingData: prevIsLoadingData,
      redirectTo: prevRedirectTo,
    } = prevProps;
    const { history, isLoadingData, onReady, redirectTo } = this.props;

    if (prevIsLoadingData === true && isLoadingData === false) {
      this.setIsLoadingDataTaskId = setTimeout(() => {
        this.setIsLoadingDataTaskId = undefined;
        this.setState(({ ui }) => ({
          ui: ui.set('isLoadingData', false),
        }));
      }, 2000);
    }

    if (redirectTo && redirectTo !== prevRedirectTo) {
      history.push({
        pathname: `/project/${redirectTo}`,
        state: { reload: true },
      });
    }

    if (!this.isReady(prevState) && this.isReady(this.state)) {
      onReady();
    }
  }

  public componentWillUnmount() {
    const { onUnmount } = this.props;
    this.setIsLoadingDataTaskId && clearInterval(this.setIsLoadingDataTaskId);
    onUnmount();
  }

  private handleVideoEditorReady = () =>
    this.setState(({ ui }) => ({
      ui: ui.set('videoEditorRendered', true),
    }));

  private isReady(state = this.state) {
    const { ui } = state;
    return !ui.isLoadingData && ui.videoEditorRendered;
  }

  public render() {
    const { style } = this.props;
    const { ui } = this.state;

    return (
      <div className="editor" style={style}>
        {ui.get('videoEditorRendered') && !ui.get('isLoadingData') && (
          <OnboardingAnimations name={OnboardingName.EDITOR_PAGE} />
        )}
        <LoadingScreen
          message="Loading Your Project"
          show={!ui.get('videoEditorRendered') || ui.get('isLoadingData')}
          fadeout
        />
        <VideoEditor
          onReady={this.handleVideoEditorReady}
          hidden={style.display === 'none'}
          isReady={ui.get('videoEditorRendered') && !ui.get('isLoadingData')}
        />
      </div>
    );
  }
}
