import cn from 'classnames';
import * as React from 'react';
import * as ids from 'short-id';

import Image from 'components/Image';
import { MixedPartial } from 'types';
import { Slide } from './types';
import { block } from './utils';

export interface VideoFramePreviewProps {
  backgroundColor?: string;
  className?: string;
  excludeAssets?: string[];
  slides?: MixedPartial<Slide, 'id'> | Slide[];
}

const defaultSlideStyle = {
  left: 0,
  height: '100%',
  top: 0,
  width: '100%',
  position: 'absolute' as const,
};

const VideoFramePreview: React.FC<VideoFramePreviewProps> = ({
  backgroundColor = 'black',
  className,
  slides,
}) => {
  const slideList = Array.isArray(slides)
    ? slides
    : [{ ...slides, id: ids.generate() }];

  return (
    <div className={cn(block(), className)} style={{ backgroundColor }}>
      {slideList.map(({ id, url, placement }) => (
        <Image
          className={block('image')}
          key={id}
          src={url}
          style={{
            ...defaultSlideStyle,
            ...placement,
          }}
        />
      ))}
    </div>
  );
};

export default VideoFramePreview;
