import React from 'react';

import { SwitchField, SwitchFieldProps } from 'components/Form';
import { popperToggleBlock as block } from './utils';

interface PopperToggleOption {
  description: string;
  value: string;
}

type PickedSwitchProps = Pick<
  SwitchFieldProps,
  'onChange' | 'switchConfig' | 'value'
>;

export interface PopperToggleProps extends PickedSwitchProps {
  options: [PopperToggleOption, PopperToggleOption];
}

const PopperToggle: React.FC<PopperToggleProps> = ({
  onChange,
  options,
  switchConfig,
  value,
}) => {
  const [left, right] = options;

  return (
    <>
      <SwitchField
        className={block('switch')}
        left={left.value}
        right={right.value}
        {...{ onChange, switchConfig, value }}
      />
      <span className={block('description')}>
        {options.find(opts => opts.value === value)?.description}
      </span>
    </>
  );
};

export default PopperToggle;
