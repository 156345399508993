import * as React from 'react';

import { block } from './utils';

interface BlockImageTooltipContentProps {
  description: string;
  footer?: string;
}

const BlockImageTooltipContent: React.SFC<BlockImageTooltipContentProps> = ({
  description,
  footer,
}) => (
  <div className={block('tooltip-content')}>
    <p className={block('tooltip-description')}>{description}</p>

    <p className={block('tooltip-footer')}>
      <span className={block('tooltip-footer-intro')}>Use Information</span>{' '}
      {footer || 'No use information available'}
    </p>
  </div>
);

export default BlockImageTooltipContent;
