import * as React from 'react';
import { isNumber, noop } from 'underscore';

import FadingScrollBars from 'components/FadingScrollBars';
import ScrollBars from 'components/ScrollBars';
import { Keyword } from '../../components/EditableKeywordPhrase';
import { block } from '../../utils';
import NewPhrase from '../NewPhrase';
import TranscriptPhrase, { TranscriptPhraseProps } from '../TranscriptPhrase';

export interface TranscriptProps {
  activePhraseId?: string;
  onCopyPhraseToTimeline?: TranscriptPhraseProps['onCopyToTimeline'];
  onKeywordClick?: (keyword: Keyword, millis: number) => void;
  onPause?: () => void;
  onPlayPhrase?: (startMillis: number, endMillis: number) => void;
  playing?: boolean;
  phrases: string[];
  scrollToPhrase: boolean;
}

interface State {
  activePhraseId: string;
  addAfterPhraseId: string;
  selectedPhraseId: string;
}

export default class Transcript extends React.Component<
  TranscriptProps,
  State
> {
  public static defaultProps: Partial<TranscriptProps> = {
    onKeywordClick: noop,
  };

  private element: ScrollBars;
  private phraseRefs: { [key: string]: HTMLDivElement } = {};

  public state: Readonly<State> = {
    activePhraseId: undefined,
    addAfterPhraseId: undefined,
    selectedPhraseId: undefined,
  };

  public componentDidUpdate(prevProps: Readonly<TranscriptProps>) {
    const { activePhraseId, scrollToPhrase } = this.props;
    const { selectedPhraseId } = this.state;

    const {
      activePhraseId: prevActivePhraseId,
      scrollToPhrase: prevScrollToPhrase,
    } = prevProps;

    if (!scrollToPhrase && prevScrollToPhrase) {
      this.setState({ activePhraseId: undefined });
    }

    if (
      activePhraseId &&
      activePhraseId !== selectedPhraseId &&
      activePhraseId !== prevActivePhraseId &&
      scrollToPhrase
    ) {
      this.scrollToPhrase(activePhraseId);
    }
  }

  private handleAddAfterPhrase = (phraseId: string) =>
    this.setState({ addAfterPhraseId: phraseId });

  private handleKeywordClick: TranscriptPhraseProps['onKeywordClick'] = (
    keyword,
    phraseId,
  ) => {
    const { onKeywordClick } = this.props;
    const millis = keyword.id;
    if (isNumber(millis)) {
      this.setSelectedPhrase(phraseId, () => onKeywordClick(keyword, millis));
    }
  };

  private handlePlayPhrase = (startMillis: number, endMillis: number) => {
    const { onPlayPhrase } = this.props;
    onPlayPhrase(startMillis, endMillis);
  };

  private handleTogglePlayPhrase = (startMillis: number, endMillis: number) => {
    const { onPause, onPlayPhrase, playing } = this.props;
    if (playing) {
      onPause();
    } else {
      onPlayPhrase(startMillis, endMillis);
    }
  };

  private handleNewPhraseCancel = () =>
    this.setState({ addAfterPhraseId: undefined });

  private handlePhraseMount = (el: HTMLDivElement, id: string) => {
    this.phraseRefs[id] = el;
  };

  private handlePhraseUnmount = (id: string) => {
    delete this.phraseRefs[id];
  };

  private scrollToPhrase(phraseId: string) {
    const element = this.phraseRefs[phraseId];
    const phraseOffset = element.offsetTop;
    const targetOffset = 50;

    this.element.scrollTop = phraseOffset - targetOffset;
    this.setState({ activePhraseId: phraseId, selectedPhraseId: undefined });
  }

  private setElement = el => (this.element = el);

  private setSelectedPhrase(phraseId: string, cb: () => void) {
    this.setState(
      {
        activePhraseId: undefined,
        selectedPhraseId: phraseId,
      },
      cb,
    );
  }

  public render() {
    const { onCopyPhraseToTimeline, phrases } = this.props;
    const { activePhraseId, addAfterPhraseId, selectedPhraseId } = this.state;

    return (
      <div className={block()}>
        <FadingScrollBars
          className={block('fading-scroller')}
          scrollBarsRef={this.setElement}
        >
          <div className={block('phrases')}>
            {phrases.length > 0 &&
              phrases.reduce((acc, id) => {
                acc.push(
                  <TranscriptPhrase
                    active={id === selectedPhraseId}
                    key={id}
                    onAdd={this.handleAddAfterPhrase}
                    onCopyToTimeline={onCopyPhraseToTimeline}
                    onMount={this.handlePhraseMount}
                    onKeywordClick={this.handleKeywordClick}
                    onPlay={this.handlePlayPhrase}
                    onTogglePlay={this.handleTogglePlayPhrase}
                    onUnmount={this.handlePhraseUnmount}
                    phraseId={id}
                    selected={id === activePhraseId}
                  />,
                );

                if (id === addAfterPhraseId) {
                  acc.push(
                    <NewPhrase
                      key="new-phrase"
                      onPhraseCancel={this.handleNewPhraseCancel}
                      previousPhraseId={addAfterPhraseId}
                    />,
                  );
                }
                return acc;
              }, [])}
          </div>
        </FadingScrollBars>
      </div>
    );
  }
}
