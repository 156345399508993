import * as React from 'react';
import { ModalName } from 'redux/modules/modal/types';
import { createChainedFunction } from 'utils/functions';
import ConnectedModalComponent, {
  ConnectedModalComponentProps,
} from './ConnectedModalComponent';
import useConnectedModal from './useConnectedModal';

export interface Props extends ConnectedModalComponentProps {
  name: ModalName;
}

const ConnectedModal: React.FC<Props> = props => {
  const {
    children,
    name,
    title,
    className,
    closeButton,
    backdrop,
    keyboard,
    size,
    onExited,
    onHide,
    v2,
  } = props;
  const {
    show,
    onHide: onConnectedHide,
    onExited: onConnectedExited,
    params,
  } = useConnectedModal(name);

  return (
    <ConnectedModalComponent
      {...{
        backdrop,
        show,
        className,
        closeButton,
        params,
        size,
        keyboard,
        v2,
      }}
      title={title ?? params?.title}
      onHide={createChainedFunction(onHide, onConnectedHide)}
      onExited={createChainedFunction(onExited, onConnectedExited)}
    >
      {children}
    </ConnectedModalComponent>
  );
};

export default ConnectedModal;
