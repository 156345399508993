import { debounce } from 'underscore';
import {
  Region as IRegion,
  RegionParams,
} from 'wavesurfer.js/src/plugin/regions';
import { Region as WSRegion } from 'wavesurfer.js/src/plugin/regions/region';
import { Observer } from 'wavesurfer.js/src/util';
import { clamp } from 'utils/numbers';

type RegionUtil = WaveSurfer['util'] & {
  getRegionSnapToGridValue(value: number): number;
};

interface ClipRegion extends IRegion {
  setLengthExceededListener: (listener: () => void) => void;
}

class ClipRegion extends WSRegion {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(params: RegionParams, regionsUtil: RegionUtil, ws: WaveSurfer) {
    super(params, regionsUtil, ws);
    this.regionsUtil = regionsUtil;
  }

  readonly regionsUtil: RegionUtil;

  isResizing: boolean = false;

  isDragging: boolean = false;

  vertical: boolean = false;

  private triggerMaxDurationExceeded = debounce(
    () => {
      this.fireEvent('max-length-exceeded');
    },
    1000,
    true,
  );

  update(params: RegionParams) {
    // https://github.com/katspaugh/wavesurfer.js/issues/2297
    if (params.end != null) {
      const start = params.start != null ? Number(params.start) : this.start;
      const minLength =
        params.minLength != null ? Number(params.minLength) : this.minLength;
      const maxLength =
        params.maxLength != null ? Number(params.maxLength) : this.maxLength;

      if (params.end > start + maxLength && this.isResizing) {
        this.triggerMaxDurationExceeded();
      }

      super.update({
        ...params,
        end: clamp(Number(params.end), start + minLength, start + maxLength),
      });
    } else {
      super.update(params);
    }
  }

  bindDragEvents() {
    const { scrollSpeed } = this;
    let startTime: number;
    let touchId;
    let drag: boolean;
    let maxScroll: number;
    let resize: 'start' | 'end' | false;
    let updated = false;
    let scrollDirection: 1 | -1;
    let wrapperRect: DOMRect;
    let regionLeftHalfTime: number;
    let regionRightHalfTime: number;
    let handleOffset = 0;

    // Scroll when the user is dragging within the threshold
    const edgeScroll = event => {
      const orientedEvent = this.util.withOrientation(event, this.vertical);
      const duration = this.wavesurfer.getDuration();
      if (!scrollDirection || (!drag && !resize)) {
        return;
      }

      const x = orientedEvent.clientX;
      let distanceBetweenCursorAndWrapperEdge = 0;
      let regionHalfTimeWidth = 0;
      let adjustment = 0;

      // Get the currently selected time according to the mouse position
      let time = this.regionsUtil.getRegionSnapToGridValue(
        this.wavesurfer.drawer.getProgress(event.clientX + handleOffset) *
          duration,
      );

      if (drag) {
        // Considering the point of contact with the region while edgescrolling
        if (scrollDirection === -1) {
          regionHalfTimeWidth =
            regionLeftHalfTime * this.wavesurfer.params.minPxPerSec;
          distanceBetweenCursorAndWrapperEdge = x - wrapperRect.left;
        } else {
          regionHalfTimeWidth =
            regionRightHalfTime * this.wavesurfer.params.minPxPerSec;
          distanceBetweenCursorAndWrapperEdge = wrapperRect.right - x;
        }
      } else {
        // Considering minLength while edgescroll
        let { minLength } = this;
        if (!minLength) {
          minLength = 0;
        }

        if (resize === 'start') {
          if (time > this.end - minLength) {
            time = this.end - minLength;
            adjustment = scrollSpeed * scrollDirection;
          }

          if (time < 0) {
            time = 0;
          }
        } else if (resize === 'end') {
          if (time < this.start + minLength) {
            time = this.start + minLength;
            adjustment = scrollSpeed * scrollDirection;
          }

          if (time > duration) {
            time = duration;
          }
        }
      }

      // Don't edgescroll if region has reached min or max limit
      const wrapperScrollLeft = this.wavesurfer.drawer.getScrollX();

      if (scrollDirection === -1) {
        if (Math.round(wrapperScrollLeft) === 0) {
          return;
        }

        if (
          Math.round(
            wrapperScrollLeft -
              regionHalfTimeWidth +
              distanceBetweenCursorAndWrapperEdge,
          ) <= 0
        ) {
          return;
        }
      } else {
        if (Math.round(wrapperScrollLeft) === maxScroll) {
          return;
        }

        if (
          Math.round(
            wrapperScrollLeft +
              regionHalfTimeWidth -
              distanceBetweenCursorAndWrapperEdge,
          ) >= maxScroll
        ) {
          return;
        }
      }

      // Update scroll position
      let scrollLeft =
        wrapperScrollLeft - adjustment + scrollSpeed * scrollDirection;

      if (scrollDirection === -1) {
        scrollLeft = Math.max(
          0 + regionHalfTimeWidth - distanceBetweenCursorAndWrapperEdge,
          scrollLeft,
        );
        this.wavesurfer.drawer.setScrollX(scrollLeft);
      } else {
        scrollLeft = Math.min(
          maxScroll - regionHalfTimeWidth + distanceBetweenCursorAndWrapperEdge,
          scrollLeft,
        );
        this.wavesurfer.drawer.setScrollX(scrollLeft);
      }

      const delta = time - startTime;
      startTime = time;

      // Continue dragging or resizing
      if (drag) {
        this.onDrag(delta);
      }

      if (resize === 'start' || resize === 'end') {
        this.onResize(delta, resize);
      }

      // Repeat
      window.requestAnimationFrame(() => {
        edgeScroll(event);
      });
    };

    const onDown = event => {
      const duration = this.wavesurfer.getDuration();
      if (event.touches && event.touches.length > 1) {
        return;
      }
      touchId = event.targetTouches ? event.targetTouches[0].identifier : null;

      // stop the event propagation, if this region is resizable or draggable
      // and the event is therefore handled here.
      if (this.drag || this.resize) {
        event.stopPropagation();
      }

      this.isDragging = false;
      this.isResizing = false;
      const isHandle = event.target.tagName.toLowerCase() === 'handle';
      if (isHandle) {
        this.isResizing = true;
        resize = event.target.classList.contains('wavesurfer-handle-start')
          ? 'start'
          : 'end';
      } else {
        this.isDragging = true;
        drag = true;
        resize = false;
      }

      const { clientX } = event;

      if (resize === 'start') {
        const bbox: DOMRect = event.target.getBoundingClientRect();
        handleOffset = bbox.width - (clientX - bbox.left);
      } else if (resize === 'end') {
        const bbox: DOMRect = event.target.getBoundingClientRect();
        handleOffset = clientX - bbox.left - bbox.width;
      } else {
        handleOffset = 0;
      }

      // Store the selected startTime we begun dragging or resizing
      startTime = this.regionsUtil.getRegionSnapToGridValue(
        this.wavesurfer.drawer.getProgress(clientX + handleOffset) * duration,
      );

      // Store the selected point of contact when we begin dragging
      regionLeftHalfTime = startTime - this.start;
      regionRightHalfTime = this.end - startTime;

      // Store for scroll calculations
      maxScroll = this.wavesurfer.drawer.getMaxScroll();
      wrapperRect = this.wavesurfer.drawer.container.getBoundingClientRect();
    };
    const onUp = event => {
      if (event.touches && event.touches.length > 1) {
        return;
      }

      if (drag || resize) {
        this.isDragging = false;
        this.isResizing = false;
        drag = false;
        scrollDirection = null;
        resize = false;
        handleOffset = 0;
      }

      if (updated) {
        updated = false;
        this.util.preventClick();
        this.fireEvent('update-end', event);
        this.wavesurfer.fireEvent('region-update-end', this, event);
      }
    };
    const onMove = event => {
      const duration = this.wavesurfer.getDuration();

      if (event.touches && event.touches.length > 1) {
        return;
      }
      if (
        event.targetTouches &&
        event.targetTouches[0].identifier !== touchId
      ) {
        return;
      }
      if (!drag && !resize) {
        return;
      }

      const { clientX } = event;
      let time = this.regionsUtil.getRegionSnapToGridValue(
        this.wavesurfer.drawer.getProgress(clientX + handleOffset) * duration,
      );

      if (drag) {
        // To maintain relative cursor start point while dragging
        const maxEnd = this.wavesurfer.getDuration();
        if (time > maxEnd - regionRightHalfTime) {
          time = maxEnd - regionRightHalfTime;
        }

        if (time - regionLeftHalfTime < 0) {
          time = regionLeftHalfTime;
        }
      }

      if (resize) {
        // To maintain relative cursor start point while resizing
        // we have to handle for minLength
        let { minLength } = this;
        if (!minLength) {
          minLength = 0;
        }

        if (resize === 'start') {
          if (time > this.end - minLength) {
            time = this.end - minLength;
          }

          if (time < 0) {
            time = 0;
          }
        } else if (resize === 'end') {
          if (time < this.start + minLength) {
            time = this.start + minLength;
          }

          if (time > duration) {
            time = duration;
          }
        }
      }

      const delta = time - startTime;
      startTime = time;

      // Drag
      if (this.drag && drag) {
        updated = updated || !!delta;
        this.onDrag(delta);
      }

      // Resize
      if (this.resize && resize) {
        updated = updated || !!delta;
        this.onResize(delta, resize);
      }

      // If scrolling is enabled
      if (this.wavesurfer.drawer.getMaxScroll()) {
        // Check threshold based on mouse
        const x = event.clientX - wrapperRect.left;
        if (x < wrapperRect.left + this.edgeScrollWidth) {
          scrollDirection = -1;
        } else if (x > wrapperRect.right - this.edgeScrollWidth) {
          scrollDirection = 1;
        } else {
          scrollDirection = null;
        }
        if (scrollDirection) {
          edgeScroll(event);
        }
      }
    };

    this.element.addEventListener('mousedown', onDown);
    this.element.addEventListener('touchstart', onDown);

    document.body.addEventListener('mousemove', onMove);
    document.body.addEventListener('touchmove', onMove, { passive: false });

    document.addEventListener('mouseup', onUp);
    document.body.addEventListener('touchend', onUp);

    this.on('remove', () => {
      document.removeEventListener('mouseup', onUp);
      document.body.removeEventListener('touchend', onUp);
      document.body.removeEventListener('mousemove', onMove);
      document.body.removeEventListener('touchmove', onMove);
    });

    this.wavesurfer.on('destroy', () => {
      document.removeEventListener('mouseup', onUp);
      document.body.removeEventListener('touchend', onUp);
    });
  }
}

const observerPrototypeKeys = Object.getOwnPropertyNames(Observer.prototype);
observerPrototypeKeys.forEach(key => {
  WSRegion.prototype[key] = Observer.prototype[key];
});

export default ClipRegion;
