import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import { episodeListBlock as block } from '../utils';

export interface EpisodeRowComingSoonProps {}

const EpisodeRowComingSoon: React.FC<EpisodeRowComingSoonProps> = () => (
  <div className={block('coming-soon')}>
    <FontAwesome icon="envelope" style={{ height: 'auto', width: 32 }} />
    <span className={block('coming-soon-text')}>
      We'll email your video soon!
    </span>
  </div>
);

export default EpisodeRowComingSoon;
