import React from 'react';
import { IconProps } from './types';

const Calendar: React.FC<IconProps> = props => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M3.429 0c.474 0 .857.391.857.875v.875h3.428V.875c0-.484.383-.875.857-.875.475 0 .858.391.858.875v.875h1.285c.71 0 1.286.588 1.286 1.313v1.312H0V3.062C0 2.338.576 1.75 1.286 1.75H2.57V.875c0-.484.383-.875.858-.875zM0 5.25h12v7.438c0 .724-.576 1.312-1.286 1.312H1.286C.576 14 0 13.412 0 12.687V5.25zm1.714 2.188v.875c0 .24.193.437.429.437H3a.434.434 0 0 0 .429-.438v-.874A.434.434 0 0 0 3 7h-.857a.434.434 0 0 0-.429.438zm3.429 0v.875c0 .24.193.437.428.437h.858a.434.434 0 0 0 .428-.438v-.874A.434.434 0 0 0 6.43 7H5.57a.434.434 0 0 0-.428.438zM9 7a.434.434 0 0 0-.429.438v.875c0 .24.193.437.429.437h.857a.434.434 0 0 0 .429-.438v-.874A.434.434 0 0 0 9.857 7H9zm-7.286 3.938v.874c0 .241.193.438.429.438H3a.434.434 0 0 0 .429-.438v-.874A.434.434 0 0 0 3 10.5h-.857a.434.434 0 0 0-.429.438zm3.857-.438a.434.434 0 0 0-.428.438v.874c0 .241.193.438.428.438h.858a.434.434 0 0 0 .428-.438v-.874a.434.434 0 0 0-.428-.438H5.57zm3 .438v.874c0 .241.193.438.429.438h.857a.434.434 0 0 0 .429-.438v-.874a.434.434 0 0 0-.429-.438H9a.434.434 0 0 0-.429.438z"
        id="bv868razja"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="kljwk5tjib" fill="#fff">
        <use xlinkHref="#bv868razja" />
      </mask>
      <use fill="currentColor" fillRule="nonzero" xlinkHref="#bv868razja" />
      <g mask="url(#kljwk5tjib)" fill="currentcolor">
        <path d="M-4.588 0h21.176v14H-4.588z" />
      </g>
    </g>
  </svg>
);

export default Calendar;
