import React from 'react';

import ClipCtaButton from 'blocks/ClipSelect/components/ClipCtaButton';
import { Scissors } from 'components/icons';
import { RequestStatus } from 'types';
import { createChainedFunction } from 'utils/functions';
import { selectorBlock as block } from '../../utils';
import AddToVideoButton from '../AddToVideoButton';
import { useClipSelect } from '../ClipSelectContext';
import { useClipSelectTracking } from '../ClipSelectTrackingContext';
import DislikeClipButton from './DislikeClipButton';

export interface ClipSelectorControlsProps {}

const ClipSelectorControls = () => {
  const { activeSuggestionId, onAdjust, submitStatus } = useClipSelect();
  const { onAdjustClip } = useClipSelectTracking();

  const disabled = submitStatus === RequestStatus.REQUEST;

  return (
    <div className={block('controls')}>
      <DislikeClipButton
        disabled={disabled}
        suggestionId={activeSuggestionId}
      />
      <ClipCtaButton
        disabled={disabled}
        icon={<Scissors style={{ height: 16, width: 'auto' }} />}
        id="adjust"
        onClick={createChainedFunction(onAdjust, onAdjustClip)}
        theme="confirm"
        tooltip="Change the start or end time of this clip"
      >
        adjust clip
      </ClipCtaButton>
      <AddToVideoButton
        disabled={disabled}
        id="confirm"
        theme="rainbow"
        tooltip="Use this clip without making changes"
      />
    </div>
  );
};

export default ClipSelectorControls;
