import { isUndefined } from 'underscore';

import { PodcastSearchIntegrationId } from 'blocks/PodcastSearch/types';
import * as headlinerUserService from 'redux/middleware/api/headliner-user-service';
import * as apiActions from 'redux/middleware/api/podcast-service/actions';
import { deleteEntities } from 'redux/modules/entities/actions';
import { ThunkAction } from 'redux/types';
import { Omit } from 'types';
import { setCollectFeedProperties } from '../mixpanel/actions';
import { showError, showSuccess } from '../notification/actions';
import { Type } from './action-types';
import {
  defaultPodcastIdSelector,
  favoritePodcastIdsSelector,
  nextEpisodePublishMillisSelector,
  podcastResultsNextOffsetSelector,
  podcastResultsOffsetSelector,
  searchTermSelector,
  selectedPodcastIdSelector,
} from './selectors';
import {
  PodcastSearchListType,
  PodcastSearchResultPayload,
  SearchPodcastFailureAction,
  SearchPodcastsRequestAction,
  SearchPodcastSuccessAction,
} from './types';

interface PodcastSearchActionCreator {
  (
    actionType: Type.PODCAST_SEARCH_REQUEST,
    payload: { query: string },
  ): SearchPodcastsRequestAction;
  (
    actionType: Type.PODCAST_SEARCH_SUCCESS,
    payload: Omit<PodcastSearchResultPayload, 'integration'>,
  ): SearchPodcastSuccessAction;
  (actionType: Type.PODCAST_SEARCH_FAILURE): SearchPodcastFailureAction;
}

const createPodcastSearchActionCreator = (
  integration: PodcastSearchIntegrationId,
): PodcastSearchActionCreator => (actionType, payload?: any) => {
  return {
    type: actionType,
    payload: {
      integration,
      ...payload,
    },
  };
};

export const clearSearchResults = (): ThunkAction<void> => dispatch => {
  dispatch({ type: Type.PODCAST_SEARCH_RESULTS_CLEAR });
  dispatch(deleteEntities(['podcasts', 'podcastDetailEpisodes']));
};

export const searchPodcastsByName = (
  searchTerm: string,
  offset: number = 0,
): ThunkAction<Promise<void>> => dispatch => {
  const createAction = createPodcastSearchActionCreator('listennotes');

  dispatch(createAction(Type.PODCAST_SEARCH_REQUEST, { query: searchTerm }));

  return dispatch(apiActions.searchPodcasts(searchTerm, offset))
    .then(({ response }) => {
      dispatch(
        createAction(Type.PODCAST_SEARCH_SUCCESS, {
          podcasts: response.result,
          query: response.queries.current,
        }),
      );
    })
    .catch(err => {
      dispatch(createAction(Type.PODCAST_SEARCH_FAILURE));
      throw err;
    });
};

export const sendPodcastSupport = (
  args: headlinerUserService.SendPodcastSupportArgs,
): ThunkAction<Promise<void>> => async dispatch => {
  try {
    await dispatch(headlinerUserService.sendPodcastSupportRequest(args));

    dispatch(
      showSuccess({
        message:
          'Your request has been sent to support, we will get back to you shortly.',
      }),
    );
  } catch (err) {
    dispatch(showError({ message: 'Error sending request to support.' }));
  }
};

export const searchPodcastsByFeed = (
  searchTerm: string,
): ThunkAction<Promise<void>> => dispatch => {
  const createAction = createPodcastSearchActionCreator('rss');

  dispatch(createAction(Type.PODCAST_SEARCH_REQUEST, { query: searchTerm }));

  return dispatch(apiActions.searchFeed(searchTerm))
    .then(({ response }) => {
      dispatch(
        createAction(Type.PODCAST_SEARCH_SUCCESS, {
          podcasts: response.result,
        }),
      );
    })
    .catch(() => {
      dispatch(createAction(Type.PODCAST_SEARCH_FAILURE));
    });
};

export const getNextPodcastsPage = (
  offset?: number,
): ThunkAction<Promise<void>> => (dispatch, getState) => {
  const nextOffset = isUndefined(offset)
    ? podcastResultsNextOffsetSelector(getState())
    : offset;
  const searchTerm = searchTermSelector(getState());
  const lastSearchTerm = searchTermSelector(getState());
  const lastOffset = podcastResultsOffsetSelector(getState());

  if (searchTerm === lastSearchTerm && nextOffset <= lastOffset) {
    return Promise.resolve();
  }

  return dispatch(searchPodcastsByName(searchTerm, nextOffset));
};

/*
 * NB: do not use an async function.  needs to support cancellation
 */
export const getPodcastEpisodes = (
  nextPublishMillis?: number,
): ThunkAction<Promise<void>> => (dispatch, getState) => {
  const podcastId = selectedPodcastIdSelector(getState());

  dispatch({
    payload: { podcastId, nextPublishMillis },
    type: Type.PODCAST_EPISODES_GET_REQUEST,
  });

  return dispatch(apiActions.getPodcastEpisodes(podcastId, nextPublishMillis))
    .then(({ response }) => {
      dispatch({
        payload: {
          nextPublishMillis: response.nextEpisodePubAtMillis,
        },
        type: Type.PODCAST_EPISODES_GET_SUCCESS,
      });
    })
    .catch(() => {
      dispatch({ type: Type.PODCAST_EPISODES_GET_FAILURE });
    });
};

export const getNextEpisodesPage = (): ThunkAction<Promise<void>> => (
  dispatch,
  getState,
) => {
  const nextPublishMillis = nextEpisodePublishMillisSelector(getState());
  return dispatch(getPodcastEpisodes(nextPublishMillis));
};

export const setSelectedPodcast = (podcastId?: string) => ({
  payload: { podcastId },
  type: Type.PODCAST_SELECTED_PODCAST_SET,
});

export const setSelectedEpisode = (episodeId?: string) => ({
  payload: { episodeId },
  type: Type.PODCAST_SELECTED_EPISODE_SET,
});

export const clearPodcastEpisodes = (podcastId?: string): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const pid = podcastId || selectedPodcastIdSelector(getState());
  dispatch({
    payload: { podcastId: pid },
    type: Type.PODCAST_EPISODES_CLEAR,
  });
};

export const setSearchScrollOffset = (
  offset: number,
  listType: PodcastSearchListType,
) => ({
  payload: { listType, offset },
  type: Type.PODCAST_SEARCH_SCROLL_OFFSET_SET,
});

export const markPodcastDefault = (
  podcastId: string,
): ThunkAction<Promise<void>> => async dispatch => {
  try {
    await dispatch(
      apiActions.markPodcastFavorite({ podcastId, isDefault: false }),
    );
    await dispatch(getFavoritePodcasts(true));
  } catch (error) {
    dispatch(showError('There was an error capturing your response'));
  }
};

export const getFavoritePodcasts = (
  force?: boolean,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const favorites = favoritePodcastIdsSelector(getState());

  if (!force && Array.isArray(favorites)) {
    return;
  }

  try {
    dispatch({
      type: Type.FAVORITE_PODCASTS_GET_REQUEST,
    });
    const { response } = await dispatch(apiActions.getFavoritePodcasts());
    dispatch({
      type: Type.FAVORITE_PODCASTS_GET_SUCCESS,
      payload: response,
    });
    dispatch(setCollectFeedProperties());
  } catch (error) {
    dispatch({
      type: Type.FAVORITE_PODCASTS_GET_FAILURE,
    });
  }
};

export const searchFavoritePodcast = (): ThunkAction<Promise<
  string | null
>> => async (dispatch, getState) => {
  const createAction = createPodcastSearchActionCreator('favorite');
  dispatch(createAction(Type.PODCAST_SEARCH_REQUEST, { query: '' }));

  await dispatch(getFavoritePodcasts());
  const defaultPodcastId = defaultPodcastIdSelector(getState());

  if (defaultPodcastId) {
    dispatch(setSelectedPodcast(defaultPodcastId));
    await dispatch(getPodcastEpisodes());
  }

  dispatch(
    createAction(Type.PODCAST_SEARCH_SUCCESS, {
      podcasts: defaultPodcastId ? [defaultPodcastId] : [],
    }),
  );

  if (!defaultPodcastId) {
    dispatch(clearSearchResults());
  }

  return defaultPodcastId;
};

export const setDefaultPodcast = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const createAction = createPodcastSearchActionCreator('favorite');
  const defaultPodcastId = defaultPodcastIdSelector(getState());

  if (defaultPodcastId) {
    dispatch(createAction(Type.PODCAST_SEARCH_REQUEST, { query: '' }));
    dispatch(
      createAction(Type.PODCAST_SEARCH_SUCCESS, {
        podcasts: [defaultPodcastId],
      }),
    );
    dispatch(setSelectedPodcast(defaultPodcastId));
  }
};

export const collectFeedback = (
  podcastId: string,
): ThunkAction<void> => async dispatch => {
  await dispatch(markPodcastDefault(podcastId));
};

export const fetchEpisodeExcerpts = (
  podcastId: string,
  episodeId: string,
): ThunkAction<void> => async dispatch => {
  try {
    await dispatch(apiActions.getPodcastRemoteEpisode(podcastId, episodeId));
  } catch (error) {
    if (error.status !== 404) {
      dispatch(showError(error.message));
    }
  }
};

export const deleteFavoritePodcast = (
  podcastId: string,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    payload: { podcastId },
    type: Type.FAVORITE_PODCAST_DELETE_REQUEST,
  });
  try {
    await dispatch(apiActions.deleteFavoritePodcast(podcastId));
    dispatch({ type: Type.FAVORITE_PODCAST_DELETE_SUCCESS });
  } catch (err) {
    dispatch({ type: Type.FAVORITE_PODCAST_DELETE_FAILURE });
    throw err;
  }
};
