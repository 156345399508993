import { useCallback } from 'react';
import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import useDeleteMedia from './useDeleteMedia';

interface useDeleteLayer {
  handleDeleteLayer: (layerId: string) => void;
}

const useDeleteLayer = (): useDeleteLayer => {
  const { layers, textOverlays } = useTemplateState();
  const dispatch = useTemplateDispatch();
  const handleDeleteMedia = useDeleteMedia();

  const handleDeleteLayer = useCallback(
    (layerId: string): void => {
      const layerType = layers.data[layerId];

      if (!layerType) return;

      switch (layerType.type) {
        case 'waveform': {
          dispatch({ type: 'SOUNDWAVE_TYPE_CHANGE', payload: 'none' });

          break;
        }

        case 'media': {
          handleDeleteMedia();

          break;
        }

        case 'text': {
          const selectedTextOverlayId = Object.keys(textOverlays.data).find(
            key => {
              const textOverlay = textOverlays.data[key];

              return textOverlay?.layerId === layerId;
            },
          );

          dispatch({
            payload: selectedTextOverlayId,
            type: 'TEXT_DELETE',
          });

          break;
        }

        default:
          break;
      }
    },
    [dispatch, handleDeleteMedia, layers.data, textOverlays.data],
  );

  return {
    handleDeleteLayer,
  };
};

export default useDeleteLayer;
