import React from 'react';

import { IconProps } from './types';

const CaptionsCircle: React.FC<IconProps> = ({ ...svgProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="75"
    height="75"
    viewBox="0 0 75 75"
    {...svgProps}
  >
    <defs>
      <linearGradient id="wvt4edy52a" x1="0%" x2="100%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#5DBEFC" />
        <stop offset="100%" stopColor="#DD92FE" />
      </linearGradient>
      <filter
        id="gl10sf506b"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle
        cx="37.5"
        cy="37.5"
        r="37.5"
        fill="url(#wvt4edy52a)"
        filter="url(#gl10sf506b)"
      />
      <path
        fill="#282E37"
        fillRule="nonzero"
        d="M49.688 26.25H25.311a2.813 2.813 0 00-2.812 2.813v16.875a2.813 2.813 0 002.813 2.812h24.375a2.813 2.813 0 002.812-2.813V29.064a2.813 2.813 0 00-2.813-2.813zM35.279 39.357a.362.362 0 01.54.053l1.142 1.623c.1.14.088.328-.03.451-3.14 3.328-10.124 1.881-10.124-3.978 0-5.701 7.13-7.002 10.107-4.108.123.118.147.188.059.334l-1.026 1.788a.365.365 0 01-.533.1c-2.39-1.876-5.543-.874-5.543 1.827.006 2.813 2.994 4.131 5.408 1.91zm11.157 0a.362.362 0 01.539.053l1.142 1.623c.1.14.088.328-.03.451-3.134 3.334-10.118 1.881-10.118-3.978 0-5.701 7.13-7.002 10.107-4.108.123.118.147.188.059.334l-1.026 1.788a.365.365 0 01-.533.1c-2.39-1.876-5.543-.874-5.543 1.827 0 2.813 2.988 4.131 5.403 1.91z"
      />
    </g>
  </svg>
);

export default CaptionsCircle;
