import cn from 'classnames';
import * as React from 'react';

import { CircularGradientEnvelope } from 'components/icons/circular-gradient';
import { newsletterBlock as block } from '../utils';
import CardWizardStep, {
  CardWizardControlButton,
  CardWizardControls,
} from './CardWizard/CardWizardStep';

const { useCallback } = React;

export interface NewsletterStepProps {
  className?: string;
  onSubmit?: (newsletter: boolean) => void;
}

const NewsletterStep: React.FC<NewsletterStepProps> = ({
  className,
  onSubmit,
}) => {
  const handleOptIn = useCallback(() => {
    onSubmit(true);
  }, [onSubmit]);

  const handleOptOut = useCallback(() => {
    onSubmit(false);
  }, [onSubmit]);

  return (
    <CardWizardStep className={cn(block(), className)}>
      <div className={block('icon')}>
        <CircularGradientEnvelope />
      </div>
      <div className={block('message')}>
        <CardWizardStep.H1>Hate missing the latest?</CardWizardStep.H1>
        <CardWizardStep.H2>
          We'll send you best practices & new features each week!
        </CardWizardStep.H2>
      </div>
      <CardWizardControls className={block('controls')}>
        <CardWizardControlButton onClick={handleOptIn}>
          yes! send me the goods
        </CardWizardControlButton>
        <CardWizardControlButton onClick={handleOptOut} theme="submit-alt">
          no thanks!
          <span className={block('extended-decline-text')}>
            {' '}
            i don't want the best practices
          </span>
        </CardWizardControlButton>
      </CardWizardControls>
    </CardWizardStep>
  );
};

export default NewsletterStep;
