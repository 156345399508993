import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import IncorrectFileTypeModalContent from './IncorrectFileTypeModalContent';

export interface Props {}

const IncorrectFileTypeModal: React.FC<Props> = () => {
  return (
    <ConnectedModal name="IncorrectFileType" title="incorrect file type">
      <IncorrectFileTypeModalContent />
    </ConnectedModal>
  );
};

export default IncorrectFileTypeModal;
