import cn from 'classnames';
import * as React from 'react';
import { block } from './utils';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

const { forwardRef } = React;

export const SearchbarInput: React.RefForwardingComponent<
  HTMLInputElement,
  Props
> = (props, ref) => {
  const { className, ...rest } = props;
  return (
    <input
      {...rest}
      className={cn(block('value'), className)}
      type="text"
      ref={ref}
    />
  );
};

SearchbarInput.displayName = 'Searchbar.Input';

export default forwardRef(SearchbarInput);
export { Props as SearchbarInputProps };
