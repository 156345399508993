import * as React from 'react';

import { IconProps } from './types';

const ChevronCircleRight: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="54"
    height="54"
    viewBox="0 0 54 54"
    {...props}
  >
    <defs>
      <filter
        id="prefix__a"
        width="287.5%"
        height="287.5%"
        x="-93.8%"
        y="-93.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="7.5"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
      </filter>
      <path
        id="prefix__b"
        d="M12 24C5.371 24 0 18.629 0 12S5.371 0 12 0s12 5.371 12 12-5.371 12-12 12zM7.281 12.64l5.407 5.095c.375.353.981.353 1.352 0l.679-.64c.375-.353.375-.925 0-1.274L10.665 12l4.054-3.82c.375-.354.375-.926 0-1.276l-.679-.639c-.375-.353-.981-.353-1.352 0L7.28 11.361c-.375.353-.375.925 0 1.278z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(15 15)">
      <circle cx="12" cy="12" r="10" fill="#23282C" />
      <g fillRule="nonzero" transform="matrix(-1 0 0 1 24 0)">
        <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
        <use fill="#DCE1EB" xlinkHref="#prefix__b" />
      </g>
    </g>
  </svg>
);

export default ChevronCircleRight;
