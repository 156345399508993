import * as React from 'react';
import { connect } from 'react-redux';

import {
  onClickAudiogramProject,
  onClickAutomaticPodcastVideos,
  onClickAutomationWizard,
  onClickBlankProject,
  onClickFullEpisode,
  onClickRecommendedProject,
  onClickTextToVideoProject,
  onClickVideoTranscriptProject,
} from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import { createChainedFunction } from 'utils/functions';
import { ProjectButtonProps as Props } from '../../components/buttons/types';
import ProjectButton from './ProjectButton';

type DispatchProps = Pick<Props, 'onClick'>;
type OwnProps = Pick<Props, 'onClick'>;

const trackingActions = {
  audiogram: onClickAudiogramProject,
  autogram: onClickAutomaticPodcastVideos,
  blank: onClickBlankProject,
  episode: onClickFullEpisode,
  recommended: onClickRecommendedProject,
  'text-to-video': onClickTextToVideoProject,
  'video-transcript': onClickVideoTranscriptProject,
  autovideo: onClickAutomationWizard,
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onClick: createChainedFunction(props.onClick, type => {
    const action = trackingActions[type];
    if (action) {
      dispatch(action());
    }
  }),
});

const component = connect(null, mapDispatchToProps)(ProjectButton);
export type ProjectButtonProps = React.ComponentPropsWithoutRef<
  typeof component
>;
export default component;
