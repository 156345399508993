import * as React from 'react';
import Joyride, { ACTIONS, EVENTS, LIFECYCLE, STATUS } from 'react-joyride';
import { noop } from 'underscore';

import Tooltip, { TooltipProps } from '../../components/Tooltip';
import { CallBackProps, OnboardingName, Step } from '../../types';
import { block } from '../../utils';

interface IProps {
  isViewed?: boolean;
  name: OnboardingName;
  onCancelWalkthrough?: (
    onboardingName: OnboardingName,
    source: string,
  ) => void;
  onClickWalkthroughBack?: (
    onboardingName: OnboardingName,
    step: string,
  ) => void;
  onClickWalkthroughNext?: (
    onboardingName: OnboardingName,
    step: string,
  ) => void;
  onCompleteWalkthrough?: (onboardingName: OnboardingName) => void;
  onExit?: () => void;
  onMount?: (name: OnboardingName) => void;
  onStartWalkthrough?: (onboardingName: OnboardingName) => void;
  onUnMount?: () => void;
  show?: boolean;
  steps: Step[];
}

interface IState {
  run: boolean;
}

export default class OnboardingAnimations extends React.Component<
  IProps,
  IState
> {
  private tooltipCloseButtonRef: HTMLButtonElement;

  public static defaultProps: Partial<IProps> = {
    isViewed: undefined,
    onCancelWalkthrough: noop,
    onClickWalkthroughBack: noop,
    onClickWalkthroughNext: noop,
    onCompleteWalkthrough: noop,
    onExit: noop,
    onMount: noop,
    onStartWalkthrough: noop,
    onUnMount: noop,
    show: true,
  };

  public state: Readonly<IState> = {
    run: false,
  };

  public componentDidMount() {
    const { name, onMount, steps } = this.props;

    if (steps.length) {
      // triggers auto start
      steps[0].disableBeacon = true;
    }

    onMount(name);

    document.addEventListener('click', this.closeOnboarding);
  }

  public static getDerivedStateFromProps(props: IProps, state: IState) {
    const { isViewed, show } = props;
    const { run } = state;

    if (isViewed === false && !run && show) return { run: true };

    return null;
  }

  public shouldComponentUpdate(nextProps: IProps) {
    const { isViewed: prevIsViewed, show: prevShow } = this.props;
    const { isViewed: nextIsViewed, show: nextShow } = nextProps;

    return prevIsViewed !== nextIsViewed || prevShow !== nextShow;
  }

  public componentWillUnmount() {
    const { onUnMount } = this.props;

    onUnMount();

    document.removeEventListener('click', this.closeOnboarding);
  }

  /**
   * Although there is some information about the close event in Joyride's callback
   * (action === ACTIONS.CLOSE && lifecycle === LIFECYCLE.READY)
   * but it runs on clicking the close X button or the overlay and there is no other info
   * where the click event comes from
   */
  private closeOnboarding = event => {
    const { name, onCancelWalkthrough, onExit } = this.props;
    const { target } = event;

    if (!target || !target.className || !target.className.includes) return;

    if (target.className.includes('react-joyride__overlay')) {
      // default click on overlay causes scrolling to the next step
      // so triggering Tooltip's close button click does not trigger this scrolling event
      this.tooltipCloseButtonRef.click();
      onCancelWalkthrough(name, 'clickBackground');
      onExit();
    }

    if (
      target.className.includes('onboarding-animations-tooltip__button--close')
    ) {
      onCancelWalkthrough(name, 'clickClose');
      onExit();
    }
  };

  private handleJoyrideCallback = ({
    action,
    index,
    lifecycle,
    status,
    type,
  }: CallBackProps) => {
    const {
      name,
      onClickWalkthroughNext,
      onClickWalkthroughBack,
      onCompleteWalkthrough,
      onExit,
      onStartWalkthrough,
      steps,
    } = this.props;

    // started
    if (type === EVENTS.TOUR_START) {
      onStartWalkthrough(name);
    }

    if (lifecycle === LIFECYCLE.COMPLETE && status === STATUS.RUNNING) {
      // clicked on Next
      if (action === ACTIONS.NEXT) {
        const nextStep: Step = steps[index + 1];
        onClickWalkthroughNext(name, nextStep.name);
      }

      // clicked on Back
      if (action === ACTIONS.PREV) {
        const actualStep: Step = steps[index];
        onClickWalkthroughBack(name, actualStep.name);
      }
    }

    // clicked on Done at the last step
    if (status === STATUS.FINISHED && type === EVENTS.TOUR_END) {
      onCompleteWalkthrough(name);
      onExit();
    }
  };

  private setTooltipCloseButtonRef = (el: HTMLButtonElement) =>
    (this.tooltipCloseButtonRef = el);

  private getTooltipComponent = (props: TooltipProps) => (
    <Tooltip ref={this.setTooltipCloseButtonRef} {...props} />
  );

  private renderOnboading = () => {
    const { isViewed, steps } = this.props;
    const { run } = this.state;

    if (steps.length && isViewed === false) {
      return (
        <div className={block()}>
          <Joyride
            callback={this.handleJoyrideCallback}
            continuous
            run={run}
            steps={steps}
            scrollToFirstStep
            beaconComponent={null}
            tooltipComponent={this.getTooltipComponent}
            styles={{ options: { zIndex: 1000 } }}
            disableOverlayClose // disabling click on overlay by default, we fire it manually in closeOnboarding
            disableCloseOnEsc // closing with ESC causes a scroll to the next step
            disableScrollParentFix
          />
        </div>
      );
    }

    return null;
  };

  public render() {
    return this.renderOnboading();
  }
}

export { IProps as OnboardingAnimationsProps };
