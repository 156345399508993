import classNames from 'classnames';
import * as React from 'react';
import _ from 'underscore';

import Image from 'components/Image';
import Modal from 'components/Modal';

interface IProps {
  className?: string;
  onHide?: () => void;
  show?: boolean;
  disabled?: boolean;
  dismissible?: boolean;
}

export const BrowserSupportModal: React.FC<IProps> = ({
  className,
  disabled,
  onHide,
  show,
  dismissible,
}) => {
  const containerClassName = classNames(
    'browser-support-modal',
    'browser-support-modal--default',
    className,
  );

  return (
    <Modal
      show={show}
      backdrop={dismissible || 'static'}
      keyboard={dismissible}
      className={containerClassName}
      closeButton={dismissible}
      onHide={onHide}
      title="Browser Support"
    >
      <Modal.Body>
        <div className="browser-support-modal__text">
          <h3>Please upgrade your browser to use Headliner.</h3>
          <p>
            We built Headliner using the latest technology, which makes it
            faster and easier to use. Unfortunately, your browser doesn&rsquo;t
            support these technologies. Upgrade to one of the latest browsers
            listed below to get the most out of your Headliner experience.
          </p>
          <p>
            <a
              className="browser-support-modal__browser-download"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/chrome/browser/desktop/index.html"
            >
              <Image
                className="browser-support-modal__browser-logo"
                src="https://cdnjs.cloudflare.com/ajax/libs/browser-logos/45.1.0/chrome/chrome.svg"
              />
              Get Chrome
            </a>
            <a
              className="browser-support-modal__browser-download"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.mozilla.org/firefox/new/"
            >
              <Image
                className="browser-support-modal__browser-logo"
                src="https://cdnjs.cloudflare.com/ajax/libs/browser-logos/45.1.0/firefox/firefox.svg"
              />
              Get Firefox
            </a>
            <a
              className="browser-support-modal__browser-download"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.apple.com/safari/"
            >
              <Image
                className="browser-support-modal__browser-logo"
                src="https://static.sparemin.com/static/webapp-assets/safari.svg"
              />
              Get Safari
            </a>
          </p>
        </div>
      </Modal.Body>

      {dismissible && (
        <Modal.Footer>
          <Modal.FooterButton onClick={onHide} disabled={disabled}>
            Close
          </Modal.FooterButton>
        </Modal.Footer>
      )}
    </Modal>
  );
};

BrowserSupportModal.defaultProps = {
  disabled: false,
  dismissible: true,
  onHide: _.noop,
  show: false,
};

export default BrowserSupportModal;
