import * as actions from './actions';
import { handle } from './service';
import { ACTION_KEY, ServiceMethod } from './types';
import { createEmbedPreviewUrl } from './utils';

export * from './types';
export { actions, createEmbedPreviewUrl };

export default {
  handle,
  ActionKey: ACTION_KEY,
  Method: ServiceMethod,
};
