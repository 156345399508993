import cn from 'classnames';
import * as React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import ProjectDeleteModalContent from './ProjectDeleteModalContent';

export interface ProjectDeleteModalProps {
  className?: string;
  onSubmit?: (projectId: string) => void;
}

const ProjectDeleteModal: React.SFC<ProjectDeleteModalProps> = ({
  className,
  onSubmit,
}) => {
  return (
    <ConnectedModal
      name="ProjectDelete"
      className={cn(
        'project-delete-modal',
        'project-delete-modal--default',
        className,
      )}
      title="Delete Project"
    >
      <ProjectDeleteModalContent onSubmit={onSubmit} />
    </ConnectedModal>
  );
};

export default ProjectDeleteModal;
