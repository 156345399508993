export const EMBED_EXPORT_REQUEST = 'app/embed-export/EMBED_EXPORT_REQUEST';
export const EMBED_EXPORT_SUCCESS = 'app/embed-export/EMBED_EXPORT_SUCCESS';
export const EMBED_EXPORT_FAILURE = 'app/embed-export/EMBED_EXPORT_FAILURE';

export const EMBED_EXPORT_SAVE_REQUEST =
  'app/embed-export/EMBED_EXPORT_SAVE_REQUEST';
export const EMBED_EXPORT_SAVE_SUCCESS =
  'app/embed-export/EMBED_EXPORT_SAVE_SUCCESS';
export const EMBED_EXPORT_SAVE_FAILURE =
  'app/embed-export/EMBED_EXPORT_SAVE_FAILURE';

export const EMBED_EXPORT_RECORDING_ID_SET =
  'app/embed-export/EMBED_EXPORT_RECORDING_ID_SET';
export const EMBED_EXPORT_RENDERED_CONFIG_SET =
  'app/embed-export/EMBED_EXPORT_RENDERED_CONFIG_SET';
export const EMBED_EXPORT_CLEAR = 'app/embed-export/EMBED_EXPORT_CLEAR';

export const EMBED_VIDEO_EXPORT_STATUS_SET =
  'app/embed-export/EMBED_VIDEO_EXPORT_STATUS_SET';
export const EMBED_VIDEO_EXPORT_STATUS_CLEAR =
  'app/embed-export/EMBED_VIDEO_EXPORT_STATUS_CLEAR';

export const EMBED_AUDIO_EXPORT_REQUEST =
  'app/embed-export/EMBED_AUDIO_EXPORT_REQUEST';
export const EMBED_AUDIO_EXPORT_SUCCESS =
  'app/embed-export/EMBED_AUDIO_EXPORT_SUCCESS';
export const EMBED_AUDIO_EXPORT_FAILURE =
  'app/embed-export/EMBED_AUDIO_EXPORT_FAILURE';

export const EMBED_AUDIO_EXPORT_STATUS_SET =
  'app/embed-export/EMBED_AUDIO_EXPORT_STATUS_SET';
