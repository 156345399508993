import * as React from 'react';

import { IconProps } from 'components/icons/types';

const EditThin: React.FC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <defs>
      <path
        d="M8.725.91c.475 0 .855.375.855.832a.84.84 0 0 1-.724.82l-.063.009-.068.002H2.081a.377.377 0 0 0-.364.3l-.006.043-.002.047v10.986c0 .19.129.346.288.381l.04.007.044.002h11.021a.377.377 0 0 0 .363-.3l.007-.043.002-.047V7.257c0-.457.38-.832.855-.832.424 0 .776.302.843.705l.008.062.003.065v6.692c0 1.066-.827 1.952-1.9 2.045l-.09.006-.09.001H2.08a2.07 2.07 0 0 1-2.073-1.875l-.006-.088L0 13.949V2.963C0 1.897.827 1.012 1.9.919l.09-.006.09-.002h6.645zm4.364.832 1.158 1.152c.16.16.161.42.002.58l-.002.002-7.26 7.21a.41.41 0 0 1-.29.119h-1.16a.41.41 0 0 1-.411-.41v-1.15a.41.41 0 0 1 .121-.292l7.263-7.211a.41.41 0 0 1 .579 0zM15.178.239l.582.578a.813.813 0 0 1 0 1.154l-.291.289a.41.41 0 0 1-.579 0l-1.159-1.152a.41.41 0 0 1 0-.582l.29-.287a.82.82 0 0 1 1.157 0z"
        id="xjj8euhg4a"
      />
    </defs>
    <g transform="translate(0 -.001)" fill="none" fillRule="evenodd">
      <mask id="j7ai3pqevb" fill="#fff">
        <use xlinkHref="#xjj8euhg4a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#xjj8euhg4a" />
      <g mask="url(#j7ai3pqevb)" fill="currentColor">
        <path d="M-4-3.999h24v24H-4z" />
      </g>
    </g>
  </svg>
);

export default EditThin;
