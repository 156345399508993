import { useDispatch } from 'react-redux';

import { clearSelectedTrackElement } from 'redux/modules/embed/actions';
import { EditAssetModalName } from 'redux/modules/modal/types';
import { createChainedFunction } from 'utils/functions';
import useConnectedModal, {
  UseConnectedModalReturnType,
} from './useConnectedModal';

export default function useEditAssetModal(
  name: EditAssetModalName,
): UseConnectedModalReturnType {
  const dispatch = useDispatch();
  const { onHide, ...rest } = useConnectedModal(name);

  return {
    ...rest,
    onHide: createChainedFunction(onHide, () =>
      dispatch(clearSelectedTrackElement()),
    ),
  };
}
