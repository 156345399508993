import * as React from 'react';

import { Omit } from 'types';
import Heading, { HeadingProps } from './Heading';

type HProps = Omit<HeadingProps, 'level'>;
function headingFactory(level: number, name: string) {
  const component = React.forwardRef<HTMLHeadingElement, HProps>(
    (props, ref) => <Heading level={level} ref={ref} {...props} />,
  );
  component.displayName = name;
  return component;
}

const h1 = headingFactory(1, 'H1');
const h2 = headingFactory(2, 'H2');
const h3 = headingFactory(3, 'H3');
const h4 = headingFactory(4, 'H4');
const h5 = headingFactory(5, 'H5');
const h6 = headingFactory(6, 'H6');

export default Heading;
export {
  HeadingProps,
  h1 as H1,
  h2 as H2,
  h3 as H3,
  h4 as H4,
  h5 as H5,
  h6 as H6,
  HProps as H1Props,
  HProps as H2Props,
  HProps as H3Props,
  HProps as H4Props,
  HProps as H5Props,
  HProps as H6Props,
};
