import cn from 'classnames';
import React from 'react';

import SteppedModal from 'components/SteppedModal';
import { TwitterPostModalProps } from './types';
import { block } from './utils';

const TwitterPostModal = React.forwardRef<SteppedModal, TwitterPostModalProps>(
  (
    {
      title,
      show,
      steps,
      defaultStep,
      className,
      onStepChange,
      onHide,
      onExited,
    },
    ref,
  ) => {
    return (
      <SteppedModal
        baseClassName={cn(block(), className)}
        {...{
          ref,
          title,
          show,
          steps,
          defaultStep,
          onStepChange,
          onHide,
          onExited,
        }}
      />
    );
  },
);

export default TwitterPostModal;
