import { AspectRatioName } from 'types';
import bem from 'utils/bem';

import { CardsPerRow } from './types';

export const block = bem('generated-media-loader');

export const LOADING_CARDS_AMOUNT_FOR_ASPECT_RATIO: Record<
  AspectRatioName,
  { cols: CardsPerRow; rows: number }
> = {
  landscape: { cols: 2, rows: 2 },
  square: { cols: 2, rows: 2 },
  portrait: { cols: 4, rows: 1 },
};

export const getLoadingCardWidth = (cardsAmount: CardsPerRow): number =>
  12 / cardsAmount;
