import * as React from 'react';

import GridLines, { GridLinesProps } from 'components/GridLines';
import { Size } from 'types';
import { Line } from './types';
import { toPxLine } from './utils';

type PickedGridLinesProps = Pick<
  GridLinesProps,
  'className' | 'color' | 'strokeWidth'
>;

export interface AlignmentGuidelinesProps extends PickedGridLinesProps {
  active: boolean;
  containerSize?: Size<number>;
  lines: Line[];
}

const AlignmentGuidelines: React.FC<AlignmentGuidelinesProps> = ({
  active,
  className,
  color,
  containerSize,
  lines,
  strokeWidth = 1,
}) => (
  <>
    {active && (
      <GridLines
        {...{ className, color, strokeWidth }}
        data={lines.map(line => toPxLine(line, containerSize))}
      />
    )}
  </>
);

export default AlignmentGuidelines;
