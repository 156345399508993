import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import UnsavedWorkModalContent from './UnsavedWorkModalContent';

const UnsavedWorkModal = () => {
  return (
    <ConnectedModal name="UnsavedWork" title="unsaved work">
      <UnsavedWorkModalContent />
    </ConnectedModal>
  );
};

export default UnsavedWorkModal;
