import { Text } from '@sparemin/blockhead';
import * as React from 'react';
import { H3 } from 'components/Heading';

import { block } from './utils';

export interface AutomationPostModalHeaderProps {
  title: React.ReactNode;
  description: React.ReactNode;
}

const AutomationPostModalHeader: React.FC<AutomationPostModalHeaderProps> = ({
  title,
  description,
}) => (
  <div className={block('header')}>
    <H3 className={block('header-title')}>{title}</H3>

    <Text variant="p" className={block('header-description')}>
      {description}
    </Text>
  </div>
);

export default AutomationPostModalHeader;
