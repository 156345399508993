import cn from 'classnames';
import * as React from 'react';
import List, { ListItemTextProps } from 'components/List';

import { episodeListBlock as block } from '../../utils';

export interface EpisodeListRowDescriptionProps
  extends Pick<ListItemTextProps, 'primary' | 'secondary'> {
  children?: React.ReactNode;
  className?: string;
}

/**
 * the text to the left of the artwork in the row
 */
const EpisodeListRowDescription: React.FC<EpisodeListRowDescriptionProps> = ({
  children,
  className,
  primary,
  secondary,
}) => (
  <List.Item.Text
    className={cn(block('description'), className)}
    {...{ primary, secondary }}
  >
    {children}
  </List.Item.Text>
);

export default EpisodeListRowDescription;
