import cn from 'classnames';
import * as React from 'react';

import ColorChangeInput, {
  ColorChangeInputProps,
} from 'components/ColorChangeInput';
import { Omit } from 'types';
import { block } from './utils';

export type VideoTemplateEditorColorChangeInputProps = Omit<
  ColorChangeInputProps,
  'arrowClassName' | 'colorClassName'
>;

const VideoTemplateEditorColorChangeInput: React.FC<VideoTemplateEditorColorChangeInputProps> = ({
  className,
  disabled,
  swatchClassName,
  ...props
}) => (
  <ColorChangeInput
    {...props}
    arrowClassName={block('color-change-arrow')}
    className={cn(block('color-change', { disabled }), className)}
    colorClassName={block('color-change-input')}
    disabled={disabled}
    swatchClassName={cn(block('color-picker-swatch'), swatchClassName)}
  />
);

export default VideoTemplateEditorColorChangeInput;
