import dayjs from 'dayjs';
import { fromJS, RecordOf } from 'immutable';
import * as React from 'react';

import IconButton from 'components/IconButton';
import { RevisionHistory as RevisionHistoryIcon } from 'components/icons';
import Tooltip from 'components/Tooltip';
import RestoreToVersionModal from './RestoreToVersionModal';
import { RevisionHistoryDropdown } from './RevisionHistoryDropdown';
import { IRevision, IRevisionItemData } from './types';
import { block } from './utils';

export interface IProps {
  revisionHistory: IRevision;
  restoreToVersion: (
    itemToRestore: RecordOf<IRevisionItemData>,
  ) => Promise<any>;
}

export interface IState {
  isRestoringInProgess: boolean;
  itemToRestore: RecordOf<IRevisionItemData>;
  showRestoreToVersionModal: boolean;
  showRevisionHistoryDropdown: boolean;
}

export default class RevisionHistory extends React.Component<IProps, IState> {
  private revisionHistoryContainer: HTMLDivElement;

  constructor(props: IProps) {
    super(props);

    this.state = {
      isRestoringInProgess: false,
      itemToRestore: fromJS({}),
      showRestoreToVersionModal: false,
      showRevisionHistoryDropdown: false,
    };
  }

  public componentDidMount() {
    document.body.addEventListener('click', this.handleDocumentClick);
  }

  public componentWillUnmount() {
    document.body.removeEventListener('click', this.handleDocumentClick);
  }

  private handleDocumentClick = e => {
    const { showRevisionHistoryDropdown } = this.state;

    if (!this.revisionHistoryContainer) return;

    if (
      !this.revisionHistoryContainer.contains(e.target) &&
      showRevisionHistoryDropdown
    ) {
      this.closeDropdown();
    }
  };

  private hasRevisionHistory = () => {
    const { revisionHistory } = this.props;

    return revisionHistory.size > 0;
  };

  private handleToggleDropdown = () =>
    this.hasRevisionHistory() &&
    this.setState(state => ({
      showRevisionHistoryDropdown: !state.showRevisionHistoryDropdown,
    }));

  private closeDropdown = () =>
    this.setState({ showRevisionHistoryDropdown: false });

  private handleClickRestoreToVersion = (
    itemToRestore: RecordOf<IRevisionItemData>,
  ) => {
    this.closeDropdown();
    this.setState({
      itemToRestore,
      showRestoreToVersionModal: true,
    });
  };

  private handleClickCancelModal = () =>
    this.setState({
      itemToRestore: fromJS({}),
      showRestoreToVersionModal: false,
    });

  private handleClickRestore = () => {
    const { restoreToVersion } = this.props;
    const { itemToRestore } = this.state;

    this.setState({ isRestoringInProgess: true });

    restoreToVersion(itemToRestore).then(() => window.location.reload());
  };

  private setRevisionHistoryContainerRef = el => {
    this.revisionHistoryContainer = el;
  };

  private getRestoreDateAndTime = () => {
    const { itemToRestore } = this.state;

    if (itemToRestore.size) {
      const updatedAt = Number(itemToRestore.get('updatedAt'));

      return `${dayjs(updatedAt).format('MMMM Do')} at ${dayjs(
        updatedAt,
      ).format('h:mm:ss a')}`;
    }

    return null;
  };

  public render() {
    const {
      isRestoringInProgess,
      showRevisionHistoryDropdown,
      showRestoreToVersionModal,
    } = this.state;
    const { revisionHistory } = this.props;
    const hasRevisionHistory = this.hasRevisionHistory();

    return (
      <div className={block()} ref={this.setRevisionHistoryContainerRef}>
        <Tooltip
          animation={false}
          content="Revert a project"
          id="revision-history-tooltip"
          placement="bottom"
          preventHideOnHover={false}
        >
          <IconButton
            onButtonClick={this.handleToggleDropdown}
            disabled={!hasRevisionHistory}
          >
            <RevisionHistoryIcon style={{ height: 28 }} />
            <span className={block('icon-text')}>Revert project</span>
          </IconButton>
        </Tooltip>

        {hasRevisionHistory && (
          <RevisionHistoryDropdown
            onClickRestoreToVersion={this.handleClickRestoreToVersion}
            revisionHistory={revisionHistory}
            visible={showRevisionHistoryDropdown}
          />
        )}

        <RestoreToVersionModal
          isLoading={isRestoringInProgess}
          onCancel={this.handleClickCancelModal}
          onRestore={this.handleClickRestore}
          restoreDateAndTime={this.getRestoreDateAndTime()}
          show={showRestoreToVersionModal}
        />
      </div>
    );
  }
}
