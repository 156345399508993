import React from 'react';

import FontAwesome from 'components/FontAwesome';
import { Omit } from 'types';
import useDeleteMedia from '../useDeleteMedia';
import DynamicChildButton, {
  DynamicChildButtonProps,
} from './DynamicChildButton';

export type DeleteButtonProps = Omit<
  DynamicChildButtonProps,
  'icon' | 'onClick'
>;

const DeleteButton: React.FC<DeleteButtonProps> = props => {
  const handleClick = useDeleteMedia();

  return (
    <DynamicChildButton
      icon={<FontAwesome icon="trash-alt" style={{ height: 16, width: 14 }} />}
      onClick={handleClick}
      {...props}
    >
      delete
    </DynamicChildButton>
  );
};

export default DeleteButton;
