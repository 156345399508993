import { isString, times } from 'underscore';

export function capitalize(str: string) {
  if (!isString(str)) return str;
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

export function truncateSentence(str: string, maxChars: number) {
  const words = str.split(' ');
  const result: string[] = [];

  function currentLength() {
    const textLen = result.reduce((sum, w) => sum + w.length, 0);
    const spaceLen = result.length === 0 ? 0 : result.length - 1;
    return textLen + spaceLen;
  }

  while (words.length > 0) {
    result.push(words.shift());
    if (currentLength() > maxChars) {
      result.pop();
      break;
    }
  }

  return result.join(' ');
}

export function replaceCharAt(str: string, index: number, char: string) {
  return [str.slice(0, index), char, str.slice(index + 1)].join('');
}

export function camelToSnake(str: string) {
  return str.replace(/([A-Z])/g, (__, p1) => `-${p1.toLowerCase()}`);
}

export function kebabToCamel(str: string) {
  return str
    .toLowerCase()
    .split('-')
    .map((part, idx) => (idx === 0 ? part : capitalize(part)))
    .join('');
}

export function camelToPascal(str: string) {
  return capitalize(str);
}

const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;

export const checkHasWhiteSpace = (stringToCheck: string): boolean => {
  return /\s/g.test(stringToCheck);
};

export const makeSocialPostLengthCounter = (urlLength: number = 0) => {
  const urlPlaceholder = times(urlLength, () => '#').join('');

  return function socialPostLengthCounter(post: string = '') {
    const text = post ?? '';
    const truncatedText = text.replace(urlRegex, urlPlaceholder);
    return truncatedText.length;
  };
};

export default { capitalize, truncateSentence };
