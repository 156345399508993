import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import ProjectDeleteModal from 'containers/ProjectDeleteModal/ProjectDeleteModal';
import { makePodcastTitleSelector } from 'redux/modules/podcast-search/selectors';
import PodcastDeleteModal from '../../components/PodcastDeleteModal';
import AutomationWorkflowCreateModal from '../AutomationWorkflowCreateModal';
import AutomationWorkflowDeleteModal from '../AutomationWorkflowDeleteModal';
import AutomationWorkflowDisableModal from '../AutomationWorkflowDisableModal';
import AutomationWorkflowSelectModal from '../AutomationWorkflowSelectModal';
import { ModalType } from './types';
import useAutomationCMSModalsActions from './useAutomationCMSModalsActions';

export interface AutomationCMSModalsProps {
  onHide?: () => void;
  podcastId?: string;
  episodeId?: string;
  showModalType: ModalType;
  workflowId?: number;
}

const AutomationCMSModals: React.FC<AutomationCMSModalsProps> = ({
  onHide,
  podcastId,
  episodeId,
  showModalType,
  workflowId,
}) => {
  const { deletePodcastFeed, deleteProject } = useAutomationCMSModalsActions({
    hideModal: onHide,
    podcastId,
  });

  const podcastTitle = useSelector(
    useMemo(() => makePodcastTitleSelector(podcastId), [podcastId]),
  );

  return (
    <>
      <PodcastDeleteModal
        onCancel={onHide}
        onDelete={deletePodcastFeed}
        podcastName={podcastTitle}
        show={showModalType === 'podcast-delete'}
      />
      <AutomationWorkflowDeleteModal
        onCancel={onHide}
        onDelete={onHide}
        show={showModalType === 'workflow-delete'}
        workflowId={workflowId}
      />
      <AutomationWorkflowDisableModal
        onCancel={onHide}
        onToggle={onHide}
        show={showModalType === 'workflow-disable'}
        workflowId={workflowId}
      />
      <AutomationWorkflowSelectModal
        onCancel={onHide}
        onCreate={onHide}
        show={showModalType === 'episode-create'}
        {...{ podcastId, episodeId }}
      />
      <ProjectDeleteModal onSubmit={deleteProject} />
      <AutomationWorkflowCreateModal
        show={showModalType === 'workflow-create'}
        {...{ podcastId, episodeId }}
      />
    </>
  );
};

export default AutomationCMSModals;
