import cn from 'classnames';
import * as React from 'react';
import Modal from 'components/Modal';
import { block } from './utils';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const ConfirmationModalBody: React.FC<Props> = props => {
  const { className, children } = props;

  return (
    <Modal.Body className={cn(block('body'), className)}>{children}</Modal.Body>
  );
};

ConfirmationModalBody.displayName = 'ConfirmationModal.Body';

export default ConfirmationModalBody;
