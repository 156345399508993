import * as React from 'react';
import { noop } from 'underscore';

import { NumericField, NumericFieldProps, NumericValue } from 'components/Form';
import { Omit } from 'types';
import TextBoxField from './TextBoxField';

interface IProps<Args = undefined>
  extends Omit<NumericFieldProps, 'onChange' | 'onBlur'> {
  args?: Args;
  onChange?: (
    val: NumericValue,
    args: Args,
    e: React.SyntheticEvent<any>,
  ) => void;
  onBlur?: (
    val: NumericValue,
    args: Args,
    e: React.SyntheticEvent<any>,
  ) => void;
}

export default class TextBoxNumericField<Args> extends React.Component<
  IProps<Args>
> {
  public static defaultProps: Partial<IProps> = {
    onBlur: noop,
    onChange: noop,
    value: 0,
  };

  private handleChange: NumericFieldProps['onChange'] = (val, e) => {
    const { args, onChange } = this.props;
    onChange(val, args, e);
  };

  private handleBlur: NumericFieldProps['onBlur'] = (val, e) => {
    const { args, onBlur } = this.props;
    onBlur(val, args, e);
  };

  public render() {
    const { args, onChange, onBlur, ...props } = this.props;

    return (
      <TextBoxField>
        <NumericField
          {...props}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
      </TextBoxField>
    );
  }
}

export { IProps as TextBoxNumericFieldProps };
