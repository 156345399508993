import * as React from 'react';
import uncontrollable from 'uncontrollable';

import { FontColorIcon } from 'components/icons';
import ToolbarColorPicker, {
  ToolbarColorPickerProps,
} from './ToolbarColorPicker';
import { block } from './utils';

type Props = Pick<
  ToolbarColorPickerProps,
  | 'args'
  | 'defaultIsOpen'
  | 'defaultValue'
  | 'disabled'
  | 'isOpen'
  | 'onChange'
  | 'onControlClick'
  | 'onToggleOpen'
  | 'value'
>;

export const FontColorPicker: React.SFC<Props> = ({ value, ...restProps }) => (
  <ToolbarColorPicker
    alignRight
    className={block('font-color')}
    icon={
      <FontColorIcon
        className={block('font-color-icon')}
        underlineColor={value}
      />
    }
    value={value}
    {...restProps}
  />
);

const uncontrolled: React.ComponentType<Props> = uncontrollable(
  FontColorPicker,
  {
    value: 'onChange',
  },
);
export default uncontrolled;
export { Props as FontColorPickerProps };
