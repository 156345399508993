import cn from 'classnames';
import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import ToggleButton, { ToggleButtonProps } from './ToggleButton';
import { block } from './utils';

type Props = ToggleButtonProps;

const AlignCenterToggle: React.SFC<Props> = ({ className, ...buttonProps }) => (
  <ToggleButton
    className={cn(block('aligncenter-toggle'), className)}
    {...buttonProps}
  >
    <FontAwesome icon="align-center" />
  </ToggleButton>
);

export default AlignCenterToggle;
export { Props as AlignCenterToggleProps };
