import React from 'react';
import { TiktokLogo } from 'components/icons';
import SocialCircle from './SocialCircle';

export interface TikTokSocialCircleProps {
  className?: string;
}

const TikTokSocialCircle: React.FC<TikTokSocialCircleProps> = ({
  className,
}) => (
  <SocialCircle background="#000" className={className}>
    <TiktokLogo
      width="31.78%"
      height="unset"
      style={{ position: 'relative', left: '1.2%' }}
    />
  </SocialCircle>
);

export default TikTokSocialCircle;
