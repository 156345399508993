import localforage from 'localforage';

// http://crocodillon.com/blog/always-catch-localstorage-security-and-quota-exceeded-errors
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
export function isQuotaExceededError(e: Error & { code?: number }) {
  if (
    // everything except Firefox
    e.code === 22 ||
    // Firefox
    e.code === 1014 ||
    // test name field too, because code might not be present
    // everything except Firefox
    e.name === 'QuotaExceededError' ||
    // Firefox
    e.name === 'NS_ERROR_DOM_QUOTA_REACHED'
  ) {
    return true;
  }

  return false;
}

interface Options {
  onError: (error?: Error) => void;
}

export function createStorage(options: Options) {
  return {
    ...localforage,
    setItem(key: string, value: string, cb: (error?: Error) => void) {
      return localforage.setItem(key, value, error => {
        if (error) {
          options?.onError?.(error);
        }
        cb?.(error);
      });
    },
  };
}
