import cn from 'classnames';
import * as React from 'react';
import ResizeDetector from 'react-resize-detector';

import Spinner from 'components/Spinner';
import { block } from '../utils';
import NoSearchResult from './NoSearchResult';
import PodcastSearchDefaultState from './PodcastSearchDefaultState';
import { useIntegrationsContext } from './PodcastSearchIntegrationsContext';

const { useCallback, useMemo, useState } = React;

export interface PodcastSearchBodyProps {
  className?: string;
  light?: boolean;
}

const PodcastSearchBody: React.FC<PodcastSearchBodyProps> = props => {
  const { className, light } = props;
  const [height, setHeight] = useState<number>();

  const { activeIntegration, isSearching } = useIntegrationsContext();

  const resultCount = activeIntegration?.resultCount;

  const handleResize = useCallback((_, h: number) => {
    setHeight(h);
  }, []);

  return (
    <div
      className={cn(
        block('body', {
          default: activeIntegration === undefined,
          empty: isSearching || resultCount === 0,
        }),
        className,
      )}
    >
      <ResizeDetector handleHeight onResize={handleResize}>
        {useMemo(() => {
          if (isSearching) {
            return <Spinner />;
          }

          if (!activeIntegration || resultCount === null) {
            return <PodcastSearchDefaultState light={light} />;
          }

          if (resultCount === 0) {
            return <NoSearchResult />;
          }

          return activeIntegration?.results(height);
        }, [activeIntegration, height, isSearching, light, resultCount])}
      </ResizeDetector>
    </div>
  );
};

export default PodcastSearchBody;
