import { Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import MediaCardContent from './MediaCardContent';
import { MediaCardProvider } from './MediaCardContext';
import MediaCardImage from './MediaCardImage';
import { block } from './utils';

interface MediaCardProps {
  children?: React.ReactNode;
  isLocked?: boolean;
  isDisabled?: boolean;
  tooltipContent?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

interface MediaCardPropsComponent extends React.FC<MediaCardProps> {
  MediaCardImage: typeof MediaCardImage;
  MediaCardContent: typeof MediaCardContent;
}

const MediaCard: MediaCardPropsComponent = ({
  children,
  isLocked,
  isDisabled,
  tooltipContent,
  className,
  onClick,
}) => {
  return (
    <MediaCardProvider isLocked={isLocked} isDisabled={isDisabled}>
      <Tooltip
        content={
          isLocked
            ? 'PRO feature: click to upgrade and unlock it'
            : tooltipContent
        }
        placement="top"
        {...(!isLocked && !tooltipContent && { show: false })}
      >
        <Spacer
          as={Button}
          space={2}
          orientation="vertical"
          align="center"
          justify="space-between"
          className={cn(block({ disabled: isDisabled }), className)}
          onClick={onClick}
        >
          {children}
        </Spacer>
      </Tooltip>
    </MediaCardProvider>
  );
};

MediaCard.MediaCardImage = MediaCardImage;
MediaCard.MediaCardContent = MediaCardContent;

export default MediaCard;
