import * as React from 'react';
import { isFunction } from 'underscore';
import { ProBadge } from 'components/icons';
import { ProIndicatorBlacklist } from './types';
import useProIndicator from './useProIndicator';

export interface ProIndicatorRenderProps {
  badge: JSX.Element;
  isFree: boolean;
}

export interface ProIndicatorProps {
  className?: string;
  children?: (props: ProIndicatorRenderProps) => JSX.Element;
  blacklist?: ProIndicatorBlacklist;
}

const ProIndicator: React.FC<ProIndicatorProps> = props => {
  const { blacklist, children, className } = props;
  const { isFree, Badge } = useProIndicator({ blacklist });
  const badge = Badge === null ? null : <Badge className={className} />;

  if (isFunction(children)) {
    return children({ badge, isFree });
  }

  return badge;
};

export default ProIndicator;
export { ProBadge };
