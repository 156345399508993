import { SuperAgentStatic } from 'superagent';
import { createRequest } from '../utils';
import { EventSourcePlatform, ServiceMethod } from './constants';
import { PingArgs, PingResult } from './types';

async function sendPing(
  request: SuperAgentStatic,
  [eventTypeId, dataId, url]: PingArgs,
): Promise<PingResult> {
  return request.get('/api/v1/event/ping').query({
    eventTypeId,
    dataId,
    url,
    platform: EventSourcePlatform.WEBAPP,
  });
}

export function handle(method: any, args: any) {
  const request = createRequest({
    baseUrl: spareminConfig.services.eventPing,
    omitBearerPrefix: true,
  });

  switch (method) {
    case ServiceMethod.PING:
      return sendPing(request, args);
  }

  return undefined;
}
