import * as React from 'react';

import { IconProps } from './types';

const TranscriptIcon: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 85 62"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor" transform="translate(9 39)">
        <rect width="67" height="3" y="6" rx="1.5" />
        <rect width="41" height="3" x="26" y="12" rx="1.5" />
        <rect width="41" height="3" rx="1.5" />
        <rect width="24" height="3" x="43" rx="1.5" />
        <rect width="24" height="3" y="12" rx="1.5" />
      </g>
      <rect
        width="82"
        height="59"
        x="1.5"
        y="1.5"
        stroke="currentColor"
        strokeWidth="3"
        rx="3"
      />
    </g>
  </svg>
);

export default TranscriptIcon;
