import * as React from 'react';

import { IconProps } from './types';

const AudioFile: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="84"
    height="96"
    viewBox="0 0 84 96"
    {...props}
  >
    <defs>
      <path
        id="audiofile-path"
        d="M3 0h31.757a3 3 0 0 1 2.122.879L47.12 11.12A3 3 0 0 1 48 13.243V57a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z"
      />
      <filter
        id="audiofile-filter"
        width="220.8%"
        height="196.7%"
        x="-60.4%"
        y="-41.7%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="1"
          result="shadowSpreadOuter1"
        />
        <feOffset dy="4" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="8"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(18 14)">
        <use
          fill="#000"
          filter="url(#audiofile-filter)"
          xlinkHref="#audiofile-path"
        />
        <use fill="#323746" xlinkHref="#audiofile-path" />
      </g>
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M42.368 46.486c1.744 0 3.158-1.524 3.158-3.405v-5.676c0-1.88-1.414-3.405-3.158-3.405s-3.157 1.525-3.157 3.405v5.676c0 1.88 1.413 3.405 3.157 3.405zm6.699-5.723h-.67c-.37 0-.67.303-.67.677v2.029c0 3.162-2.7 5.699-5.894 5.384-2.784-.274-4.823-2.801-4.823-5.625V41.44c0-.374-.3-.677-.67-.677h-.67c-.37 0-.67.303-.67.677v1.697c0 3.79 2.678 7.167 6.364 7.68v1.987h-2.345c-.37 0-.67.303-.67.677v.676c0 .374.3.676.67.676h6.699c.37 0 .67-.302.67-.676v-.676c0-.374-.3-.677-.67-.677h-2.345v-1.97c3.589-.497 6.364-3.607 6.364-7.365v-2.03c0-.373-.3-.676-.67-.676z"
      />
    </g>
  </svg>
);

export default AudioFile;
