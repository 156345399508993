import * as React from 'react';

import IconButton from 'components/IconButton';
import { TextStylesButtonIcon } from 'components/icons';
import Tooltip from 'components/Tooltip';
import CaptionsFormControl from './CaptionsFormControl';
import { block } from './utils';

interface IProps {
  disabled: boolean;
  onClick?: () => void;
}

const CaptionsStyleButton: React.SFC<IProps> = ({ disabled, onClick }) => (
  <Tooltip
    animation={false}
    content="Text styles & animation"
    id="text-styles-tooltip"
    placement="top"
    preventHideOnHover={false}
  >
    <CaptionsFormControl>
      <IconButton
        className={block('style-button')}
        onButtonClick={onClick}
        disabled={disabled}
      >
        <TextStylesButtonIcon />
      </IconButton>
    </CaptionsFormControl>
  </Tooltip>
);

export default CaptionsStyleButton;
export { IProps as CaptionsStyleButtonProps };
