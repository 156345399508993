import * as React from 'react';
import { IconProps } from './types';

function Wistia(props: IconProps) {
  return (
    <svg width={100} height={15} {...props}>
      <path
        d="M58.417.003a6.192 6.192 0 013.447 1.028v2.375a5.05 5.05 0 00-3.5-1.52 2.969 2.969 0 00-1.723.475 1.435 1.435 0 00-.678 1.213c.007.456.185.893.5 1.23.464.483 1.006.89 1.603 1.207l1.643.944c1.835 1.066 2.752 2.422 2.752 4.069a3.686 3.686 0 01-1.206 2.855 4.513 4.513 0 01-3.149 1.118 6.74 6.74 0 01-4.056-1.33V10.99c1.15 1.43 2.49 2.146 4.022 2.146a2.582 2.582 0 001.683-.559c.43-.33.68-.836.672-1.37.008-.882-.649-1.722-1.97-2.52l-1.632-.955a7.432 7.432 0 01-2.189-1.8 3.345 3.345 0 01-.632-2.045 3.554 3.554 0 011.236-2.794 4.672 4.672 0 013.177-1.09zM24.572.187l4.142 10.06L32.885.187h2.034l4.188 10.06L43.215.187h2.143L39.28 14.874h-.472L33.896 2.987l-4.97 11.887h-.459L22.407.187h2.165zm25.732.006v14.62H48.15V.193h2.154zM77.209.171v1.86h-5.24v12.782h-2.165V2.032h-5.171V.17h12.576zm5.309.022v14.62h-2.155V.193h2.155zM93.261.109L100 14.813h-2.206l-2-4.337h-6.411l-1.879 4.337h-2.212L91.75.109h1.51zM19.218 5.821s.827 6.287-7.63 8.544c-2.603.693-8.468.364-9.405.364H0l3.585-3.946c.977-1.067 1.476-1.29 2.557-1.29 1.08 0 3.59.128 4.958 0 2.527-.241 4.498-.878 5.446-1.437 2.23-1.375 2.672-2.235 2.672-2.235zm73.279-2.504L90.25 8.615h4.66l-2.413-5.298zM18 .249s1.401.66.993 3.074a4.404 4.404 0 01-1.804 2.576c-1.677 1.274-4.228 1.833-6.003 1.967-1.692.116-3.39.14-5.085.073-1.08-.079-1.591-.202-2.55-1.275L0 2.702s11.054.296 14.363-.078c3.43-.384 3.626-2.103 3.636-2.346z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default Wistia;
