import { connect } from 'react-redux';

import { discountSelector } from 'redux/modules/pricing';
import { State } from 'redux/types';
import { PropsOf } from 'types';
import AddBillingCycleModalBodyComponent, {
  AddBillingCycleModalBodyProps as Props,
} from './AddBillingCycleModalBody';

type StateProps = Pick<Props, 'discount'>;

const mapStateToProps = (state: State): StateProps => ({
  discount: discountSelector(state),
});

const component = connect(mapStateToProps)(AddBillingCycleModalBodyComponent);
export type AddBillingCycleModalBodyProps = PropsOf<typeof component>;
export default component;
