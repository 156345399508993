import WaveSurfer from 'wavesurfer.js';
import { Observer } from 'wavesurfer.js/src/util';
import dayjs from 'utils/dayjs';
import { formatDurationSeconds } from 'utils/time';
import MultiCanvas from './MultiCanvas';

export interface TimelinePluginParams {
  deferInit?: boolean;
  /** CSS selector or HTML element where the timeline should be drawn. This is the only required parameter. */
  container?: string | HTMLElement;
  /** Height of notches in percent (default: 90). */
  notchPercentHeight?: number;
  /** The colour of the notches that do not have labels (default: '#c0c0c0'). */
  notchColor?: string;
  notchWidth?: number;
  /** The colour of the main notches (default: '#000'). */
  primaryColor?: string;
  /** The colour of the secondary notches (default: '#c0c0c0'). */
  secondaryColor?: string;
  /** The colour of the labels next to the main notches (default: '#000'). */
  primaryFontColor?: string;
  /** The colour of the labels next to the secondary notches (default: '#000'). */
  secondaryFontColor?: string;
  /** The padding between the label and the notch (default: 5). */
  labelPadding?: number;
  /** A debounce timeout to increase rendering performance for large files. */
  zoomDebounce?: number | false;
  fontFamily?: string;
  /** Font size of labels in pixels (default: 10). */
  fontSize?: number;
  /** Length of the track in seconds. Overrides getDuration() for setting length of timeline. */
  duration?: number | null;
  formatTimeCallback?: (sec: number, pxPerSec: number) => string;
  timeInterval?: (pxPerSec: number) => number;
  /** Cadence between labels in primary color. */
  primaryLabelInterval?: (pxPerSec: number) => number;
  /** Cadence between labels in secondary color. */
  secondaryLabelInterval?: (pxPerSec: number) => number;
  /** Offset for the timeline start in seconds. May also be negative. */
  offset?: number;
}

const PADDINGS = {
  TOP: 22,
};

const defaultFormatTimeCallback = (seconds: number, _: number): string => {
  if (seconds === 0) {
    return '0';
  }

  return formatDurationSeconds(seconds, {
    hour: dayjs.duration(seconds * 1000).hours() ? 'numeric' : undefined,
    minute: '2-digit',
    second: '2-digit',
    trim: false,
  });
};

const defaultTimeInterval = (pxPerSec: number) => {
  if (pxPerSec >= 25) {
    return 1;
  }
  if (pxPerSec * 5 >= 25) {
    return 5;
  }
  if (pxPerSec * 15 >= 25) {
    return 15;
  }
  return Math.ceil(0.5 / pxPerSec) * 60;
};

const defaultPrimaryLabelInterval = (pxPerSec: number) => {
  if (pxPerSec >= 25) {
    return 20;
  }
  if (pxPerSec * 5 >= 25) {
    return 12;
  }
  if (pxPerSec * 15 >= 25) {
    return 8;
  }
  return 8;
};

const defaultSecondaryLabelInterval = (pxPerSec: number) => {
  if (pxPerSec >= 25) {
    return 10;
  }
  if (pxPerSec * 5 >= 25) {
    return 4;
  }
  if (pxPerSec * 15 >= 25) {
    return 4;
  }
  return 4;
};

/**
 * Wavesurfer adds observer methods at runtime.
 */
interface TimelinePlugin extends Observer {}

/**
 * Forked version of Wavesurfer's official [TimelinePlugin](https://github.com/katspaugh/wavesurfer.js/blob/19afccbe8cde790986989105d99f5935c8088f03/src/plugin/timeline/index.js)
 */
class TimelinePlugin {
  private wrapper: HTMLElement;

  private drawer: MultiCanvas;

  private wavesurfer: WaveSurfer;

  private pixelRatio: number;

  private util: typeof WaveSurfer.util;

  private params: TimelinePluginParams;

  private canvas: HTMLCanvasElement;

  private onZoom: () => void;

  static create(params: TimelinePluginParams) {
    return {
      name: 'timeline',
      deferInit: params && params.deferInit ? params.deferInit : false,
      params,
      instance: TimelinePlugin,
    };
  }

  onRedraw = () => {
    this.render();
  };

  onReady = () => {
    const ws = this.wavesurfer;
    this.drawer = ws.drawer;
    this.pixelRatio = ws.drawer.params.pixelRatio;

    // add listeners
    ws.on('redraw', this.onRedraw);
    ws.on('zoom', this.onZoom);
    ws.drawer.on('scroll', this.onScroll);

    this.render();
  };

  private updateRequestId: number;

  onScroll = () => {
    this.render();
  };

  /**
   * @param {object} e Click event
   */
  onWrapperClick = e => {
    e.preventDefault();
    const relX = 'offsetX' in e ? e.offsetX : e.layerX;
    this.fireEvent('click', relX / this.wrapper.scrollWidth || 0);
  };

  /**
   * Creates an instance of TimelinePlugin.
   *
   * You probably want to use TimelinePlugin.create()
   *
   * @param {TimelinePluginParams} params Plugin parameters
   * @param {object} ws Wavesurfer instance
   */
  constructor(params: TimelinePluginParams, ws: WaveSurfer) {
    this.wavesurfer = ws;
    this.util = ws.util;
    this.params = {
      notchPercentHeight: 90,
      labelPadding: 0,
      notchColor: '#282e38',
      notchWidth: 3,
      primaryColor: 'red',
      secondaryColor: '#282e38',
      primaryFontColor: 'red',
      secondaryFontColor: '#dce1eb',
      fontFamily: 'monospace',
      fontSize: 12,
      duration: null,
      zoomDebounce: false,
      formatTimeCallback: defaultFormatTimeCallback,
      timeInterval: defaultTimeInterval,
      primaryLabelInterval: defaultPrimaryLabelInterval,
      secondaryLabelInterval: defaultSecondaryLabelInterval,
      offset: 0,
      ...params,
    };

    this.wrapper = null;
    this.drawer = null;
    this.pixelRatio = null;
    /**
     * This event handler has to be in the constructor function because it
     * relies on the debounce function which is only available after
     * instantiation
     *
     * Use a debounced function if `params.zoomDebounce` is defined
     *
     * @returns {void}
     */
    this.onZoom = this.params.zoomDebounce
      ? this.wavesurfer.util.debounce(
          () => this.render(),
          this.params.zoomDebounce,
        )
      : () => this.render();
  }

  /**
   * Initialisation function used by the plugin API
   */
  init() {
    // Check if ws is ready
    if (this.wavesurfer.isReady) {
      this.onReady();
    } else {
      this.wavesurfer.once('ready', this.onReady);
    }
  }

  /**
   * Destroy function used by the plugin API
   */
  destroy() {
    this.unAll();
    this.wavesurfer.un('redraw', this.onRedraw);
    this.wavesurfer.un('zoom', this.onZoom);
    this.wavesurfer.un('ready', this.onReady);
    this.wavesurfer.drawer.un('scroll', this.onScroll);
    if (this.wrapper && this.wrapper.parentNode) {
      this.wrapper.removeEventListener('click', this.onWrapperClick);
      this.wrapper.parentNode.removeChild(this.wrapper);
      this.wrapper = null;
    }
  }

  /**
   * Create a timeline element to wrap the canvases drawn by this plugin
   *
   */
  createWrapper() {
    // add padding to wavesurfer container
    this.util.style(this.wavesurfer.container, {
      paddingTop: '32px',
      paddingBottom: '20px',
    });

    this.wrapper = this.wavesurfer.container.appendChild(
      document.createElement('timeline'),
    );
    this.util.style(this.wrapper, {
      display: 'block',
      userSelect: 'none',
      WebkitUserSelect: 'none',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: '100%',
      zIndex: -1,
    });

    this.canvas = this.wrapper.appendChild(document.createElement('canvas'));
    this.util.style(this.canvas, {
      position: 'absolute',
      zIndex: 4,
      left: '-50px',
    });

    this.wrapper.addEventListener('click', this.onWrapperClick);
  }

  /**
   * Render the timeline (also updates the already rendered timeline)
   */
  render() {
    if (this.updateRequestId) {
      cancelAnimationFrame(this.updateRequestId);
    }

    this.updateRequestId = requestAnimationFrame(() => {
      if (!this.wrapper) {
        this.createWrapper();
      }
      this.updateCanvasesPositioning();
      this.updateTimeline();
    });
  }

  /**
   * Update the dimensions and positioning style for all the timeline canvases
   */
  updateCanvasesPositioning() {
    const canvasWidth = this.drawer.getWidth() / this.pixelRatio + 100;
    const canvasHeight = this.getHeight();

    if (canvasWidth * this.pixelRatio !== this.canvas.width) {
      this.canvas.width = canvasWidth * this.pixelRatio;
      this.util.style(this.canvas, {
        width: `${canvasWidth}px`,
      });
    }
    if (canvasHeight * this.pixelRatio !== this.canvas.height) {
      this.canvas.height = canvasHeight * this.pixelRatio;
      this.util.style(this.canvas, {
        height: `${canvasHeight}px`,
      });
    }
  }

  /**
   * Render the timeline labels and notches
   */
  updateTimeline() {
    const { backend } = this.wavesurfer;

    if (!backend) {
      return;
    }

    const duration = this.params.duration || backend.getDuration();

    if (duration <= 0) {
      return;
    }
    const wsParams = this.wavesurfer.params;
    const fontSize = this.params.fontSize * wsParams.pixelRatio;
    const { notchWidth } = this.params;
    const width =
      wsParams.fillParent && !wsParams.scrollParent
        ? this.drawer.getWidth()
        : this.drawer.wrapper.clientWidth * wsParams.pixelRatio;
    const height1 = this.getHeight() * this.pixelRatio;
    const height2 =
      this.getHeight() *
      (this.params.notchPercentHeight / 100) *
      this.pixelRatio;
    const pixelsPerSecond = width / duration;
    const totalSeconds =
      (this.drawer.getWidth() +
        this.drawer.PADDING_OFFSET_CORRECTOR * 2 * this.pixelRatio) /
      pixelsPerSecond;
    const scrollX =
      (this.drawer.getScrollX() - this.drawer.PADDING_OFFSET_CORRECTOR) *
      this.pixelRatio;

    const formatTime = this.params.formatTimeCallback;
    // if parameter is function, call the function with
    // pixelsPerSecond, otherwise simply take the value as-is
    const intervalFnOrVal = option =>
      typeof option === 'function' ? option(pixelsPerSecond) : option;
    const timeInterval = intervalFnOrVal(this.params.timeInterval);
    const primaryLabelInterval =
      intervalFnOrVal(this.params.primaryLabelInterval) * timeInterval;
    const secondaryLabelInterval =
      intervalFnOrVal(this.params.secondaryLabelInterval) * timeInterval;

    let curPixel =
      Math.round(scrollX / (timeInterval * pixelsPerSecond)) *
        timeInterval *
        pixelsPerSecond +
      pixelsPerSecond * this.params.offset;
    let curSeconds =
      Math.round(scrollX / pixelsPerSecond / timeInterval) * timeInterval;
    let i: number;
    // build an array of position data with index, second and pixel data,
    // this is then used multiple times below
    const positioning = [];
    for (i = 0; i < totalSeconds / timeInterval; i += 1) {
      positioning.push([curPixel - scrollX, curSeconds]);
      curSeconds += timeInterval;
      curPixel += pixelsPerSecond * timeInterval;
    }

    this.clearCanvas();

    // render secondary labels
    this.setFillStyles(this.params.secondaryColor);
    this.setFonts(`${fontSize}px ${this.params.fontFamily}`);
    this.setFillStyles(this.params.secondaryFontColor);
    positioning.forEach(([offset, seconds]) => {
      if (seconds > duration) {
        return;
      }
      if (seconds % secondaryLabelInterval === 0) {
        this.fillText(
          formatTime(seconds, pixelsPerSecond),
          offset + this.params.labelPadding * this.pixelRatio,
          12 * this.pixelRatio,
        );
      }
    });

    // render the actual notches (when no labels are used)
    this.setFillStyles(this.params.notchColor);
    positioning.forEach(([offset, seconds]) => {
      if (seconds < 0 || seconds > duration) {
        return;
      }
      if (
        seconds % secondaryLabelInterval !== 0 &&
        seconds % primaryLabelInterval !== 0
      ) {
        this.fillRect(
          offset,
          (height1 - height2) / 2 + PADDINGS.TOP * this.pixelRatio,
          notchWidth,
          height2,
        );
      }
      if (seconds % secondaryLabelInterval === 0) {
        this.fillRect(
          offset,
          PADDINGS.TOP * this.pixelRatio,
          notchWidth,
          height1,
        );
      }
    });
  }

  clearCanvas() {
    const context = this.canvas.getContext('2d');
    if (context) {
      context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
  }

  setFillStyles(fillStyle: string | CanvasGradient | CanvasPattern) {
    const context = this.canvas.getContext('2d');
    if (context) {
      context.fillStyle = fillStyle;
    }
  }

  setFonts(font: string) {
    const context = this.canvas.getContext('2d');
    if (context) {
      context.font = font;
    }
  }

  /**
   * Draw a rectangle on the canvases
   *
   * (it figures out the offset for each canvas)
   */
  fillRect(x: number, y: number, width: number, height: number) {
    const intersection = {
      x1: x,
      y1: y,
      x2: Math.min(x + width, this.canvas.width),
      y2: y + height,
    };

    if (intersection.x1 < intersection.x2) {
      const context = this.canvas.getContext('2d');
      if (context) {
        context.fillRect(
          intersection.x1,
          intersection.y1,
          intersection.x2 - intersection.x1,
          intersection.y2 - intersection.y1,
        );
      }
    }
  }

  /**
   * Fill a given text on the canvases
   */
  fillText(text: string, x: number, y: number) {
    const context = this.canvas.getContext('2d');
    const textWidth = context.measureText(text).width;
    context.fillText(text, x - textWidth / 2, y);
  }

  getHeight() {
    return this.wavesurfer.getHeight() + 52;
  }
}

export default TimelinePlugin;
