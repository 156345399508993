import audioProxyService from './audio-proxy-service';
import audiogramDataService from './audiogram-data-service';
import authService from './auth-service';
import creationService from './creation-service';
import eddyService from './eddy-service';
import embedConfigurationService from './embed-configuration-service';
import embedService from './embed-service';
import eventPingService from './event-ping-service';
import headlinerUserService from './headliner-user-service';
import imageSearchService from './image-search-service';
import imageService from './image-service';
import keywordExtractorService from './keyword-extractor-service';
import mediaService from './media-service';
import mediaUploadService from './media-upload-service';
import planService from './plan-service';
import podcastService from './podcast-service';
import recordingService from './recording-service';
import recordingUploadService from './recording-upload-service';
import thirdPartyAuthService from './third-party-authentication-service';
import transcriptService from './transcript-service';
import { API_CALL } from './types';
import urlGeneratorService from './url-generator-service';
import userService from './user-service';
import videoExportService from './video-export-service';
import videoProjectManagementService from './video-project-management-service';

const services = [
  audiogramDataService,
  audioProxyService,
  recordingUploadService,
  transcriptService,
  embedConfigurationService,
  imageSearchService,
  imageService,
  videoExportService,
  authService,
  thirdPartyAuthService,
  recordingService,
  videoProjectManagementService,
  userService,
  keywordExtractorService,
  headlinerUserService,
  mediaUploadService,
  mediaService,
  urlGeneratorService,
  planService,
  podcastService,
  embedService,
  eventPingService,
  creationService,
  eddyService,
];

/* ----- Exports ----- */
// action key for api calls
export { API_CALL };

export default function createApi(
  userIdSelector,
  tokenSelector,
  userRegisteredSelector,
) {
  return store => next => action => {
    const payload = action[API_CALL];

    if (typeof payload === 'undefined') {
      return next(action);
    }

    const { service: serviceKey, method, args, types } = payload;

    if (typeof serviceKey !== 'string') {
      throw new Error(`missing service name for ${API_CALL}`);
    }

    if (typeof method !== 'string') {
      throw new Error(`missing method name for service ${serviceKey}`);
    }

    if (typeof types !== 'undefined') {
      if (!Array.isArray(types)) {
        throw new Error('"types" should be an array');
      }

      if (types.length !== 3 || !types.every(t => typeof t === 'string')) {
        throw new Error('"types" should be an array of 3 strings');
      }
    }

    if (typeof token !== 'undefined' && typeof token !== 'string') {
      throw new Error('"token" must be a string or undefined');
    }

    const actionWith = data => {
      const finalAction = { ...action, ...data };
      delete finalAction[API_CALL];
      return finalAction;
    };

    const generateTypes = methodName => [
      `${methodName}_REQUEST`,
      `${methodName}_SUCCESS`,
      `${methodName}_FAILURE`,
    ];

    const handle = handler => {
      const [requestType, successType, failureType] =
        types || generateTypes(method);

      const token = userRegisteredSelector(store.getState())
        ? tokenSelector(store.getState())
        : null;
      const userId = userRegisteredSelector(store.getState())
        ? userIdSelector(store.getState())
        : null;

      next(actionWith({ type: requestType, args }));
      return handler
        .handle(method, args, token, userId)
        .then(res =>
          next(
            actionWith({
              response: res,
              type: successType,
            }),
          ),
        )
        .catch(error => {
          next(
            actionWith({
              type: failureType,
              error: error.message || `${serviceKey} error`,
              status: error.status || 500,
            }),
          );
          return Promise.reject(error);
        });
    };

    const service = services.find(s => s.ActionKey === serviceKey);
    if (service) {
      return handle(service);
    }

    // FIXME: I don't think this error gets caught if thrown, but the ones above do. why?
    throw new Error(`cannot handle calls for service ${serviceKey}`);
  };
}
