import * as React from 'react';
import * as waveforms from 'blocks/AudioWaveImage/components/Waveforms';
import AssetTileToggle from 'components/AssetTileToggle';
import { AspectRatioName, SoundwaveType } from 'types';
import bem from 'utils/bem';
import { Measurement } from 'utils/measurement';
import { viewportToPct } from 'utils/placement';
import { camelToPascal } from 'utils/string';

const block = bem('waveform-preset-tile');

type PickedAssetTileToggleProps = Pick<
  React.ComponentProps<typeof AssetTileToggle>,
  'checked' | 'onToggle' | 'value' | 'onDeleteClick'
>;

interface Props extends PickedAssetTileToggleProps {
  type: SoundwaveType;
  color: string;
  height: Measurement<any, any>;
  left: Measurement<any, any>;
  top: Measurement<any, any>;
  width: Measurement<any, any>;
  aspectRatioName: AspectRatioName;
  checked: boolean;
  permanent?: boolean;
}

function WaveformPresetTile(props: Props) {
  const {
    aspectRatioName,
    type,
    color,
    width,
    height,
    left,
    top,
    value,
    checked,
    permanent = false,
    onToggle,
    onDeleteClick,
  } = props;
  const Waveform = waveforms[camelToPascal(type)] ?? 'div';

  return (
    <AssetTileToggle
      aspectRatioName={aspectRatioName}
      className={block()}
      permanent={permanent}
      onDeleteClick={onDeleteClick}
      {...{ value, checked, onToggle }}
    >
      <div
        style={{
          position: 'absolute',
          ...viewportToPct({ width, height, left, top }),
        }}
      >
        <Waveform
          style={{
            color: type === 'blob' ? 'transparent' : undefined,
            height: '100%',
            fill: color,
            width: '100%',
          }}
        />
      </div>
    </AssetTileToggle>
  );
}

export default WaveformPresetTile;
