import * as React from 'react';

import { Omit } from 'types';
import Track, { TrackProps } from '../../components/Track';
import { block } from '../../utils';
import GifVideoClipAsset from '../GifVideoClipAsset';
import SlideAsset from '../SlideAsset';
import VideoClipAsset from '../VideoClipAsset';

type MediaAssetType = 'image' | 'gif-video' | 'video';

interface Asset {
  id: string;
  type: MediaAssetType;
}

type PickedTrackProps = Omit<TrackProps, 'className' | 'children' | 'onMount'>;

export interface MediaTrackProps extends PickedTrackProps {
  assets: Asset[];
  onAssetClick?: (id: string, type: MediaAssetType) => void;
  projectAspectRatio: number;
  pxPerSec: number;
}

export default class MediaTrack extends React.Component<MediaTrackProps> {
  private handleAssetClick = (id: string, type: MediaAssetType) => () =>
    this.props.onAssetClick(id, type);

  public render() {
    const {
      assets,
      id,
      projectAspectRatio,
      pxPerSec,
      ...trackProps
    } = this.props;

    return (
      <Track
        id={id}
        {...trackProps}
        className={block('track', { media: true })}
      >
        {assets.map(({ id: assetId, type }) => {
          const props = {
            pxPerSec,
            id: assetId,
            onClick: this.handleAssetClick(assetId, type),
          };

          if (type === 'image') {
            return <SlideAsset key={assetId} {...props} />;
          }

          if (type === 'gif-video') {
            return (
              <GifVideoClipAsset
                aspectRatio={projectAspectRatio}
                key={assetId}
                {...props}
              />
            );
          }

          // type is video
          return (
            <VideoClipAsset
              aspectRatio={projectAspectRatio}
              key={assetId}
              {...props}
            />
          );
        })}
      </Track>
    );
  }
}
