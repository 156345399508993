import * as React from 'react';
import uncontrollable from 'uncontrollable';

import { TextHighlightIcon } from 'components/icons';
import ToolbarColorPicker, {
  ToolbarColorPickerProps,
} from './ToolbarColorPicker';
import { block } from './utils';

type Props = Pick<
  ToolbarColorPickerProps,
  | 'args'
  | 'defaultValue'
  | 'defaultIsOpen'
  | 'disabled'
  | 'isOpen'
  | 'onChange'
  | 'onControlClick'
  | 'onToggleOpen'
  | 'value'
>;

export const TextHighlightPicker: React.SFC<Props> = ({
  value,
  ...restProps
}) => (
  <ToolbarColorPicker
    {...restProps}
    alignRight
    className={block('text-highlight')}
    icon={
      <TextHighlightIcon
        className={block('text-highlight-icon')}
        underlineColor={value}
      />
    }
    value={value}
  />
);

const uncontrolled: React.ComponentType<Props> = uncontrollable(
  TextHighlightPicker,
  {
    isOpen: 'onToggleOpen',
    value: 'onChange',
  },
);

export { Props as ToolbarColorPickerProps };
export default uncontrolled;
