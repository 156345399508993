import React from 'react';
import { ButtonProps } from 'components/Button';
import Modal from 'components/Modal';
import { block } from './utils';

export interface DownloadCaptionsModalContentsProps {
  action: ButtonProps;
  children?: React.ReactNode;
}

const DownloadCaptionsModalContents: React.FC<DownloadCaptionsModalContentsProps> = ({
  action,
  children,
}) => {
  return (
    <>
      <Modal.Body className={block('body')}>{children}</Modal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid={action.fluid}>
          <Modal.FooterButton {...action} />
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default DownloadCaptionsModalContents;
