import ids from 'short-id';

import { DELAY } from './types';

const DEFAULT_ACTION = {
  type: DELAY,
};

export function extractDelayMeta(action) {
  const meta = action && action.meta;
  return meta && meta.delay;
}

export function extractTimeoutMillis(action) {
  const delay = extractDelayMeta(action);
  return delay && delay.timeoutMillis;
}

export function extractId(action) {
  const delay = extractDelayMeta(action);
  return delay && delay.id;
}

export function extractCancelId(action) {
  const delay = extractDelayMeta(action);
  return delay && delay.cancelId;
}

function isObject(value) {
  const type = typeof value;
  return (type === 'function' || type === 'object') && !!value;
}

function proxyFunction(fn) {
  if (typeof fn !== 'function') return fn;
  return (...args) => fn(...args);
}

export function delayActionBuilder(action = DEFAULT_ACTION) {
  let enrichedAction = action;
  let timeoutMillis = 0;
  let id = ids.generate();
  let cancel = false;

  return {
    action(a) {
      enrichedAction = a;
      return this;
    },

    timeoutMillis(millis) {
      timeoutMillis = millis;
      return this;
    },

    id(identifier) {
      id = identifier;
      return this;
    },

    cancel() {
      cancel = true;
      return this;
    },

    build() {
      if (!isObject(enrichedAction)) return undefined;

      const delayMeta = cancel ? { cancelId: id } : { id, timeoutMillis };

      const meta = {
        ...(enrichedAction.meta || {}),
        delay: delayMeta,
      };

      if (typeof enrichedAction === 'function') {
        const clonedFn = proxyFunction(enrichedAction);
        clonedFn.meta = meta;
        return clonedFn;
      }

      return {
        ...enrichedAction,
        meta,
      };
    },
  };
}

export default {
  extractId,
  extractTimeoutMillis,
  delayActionBuilder,
};
