import { Email, GradientIcon, Heading } from '@sparemin/blockhead';
import React from 'react';
import Modal from 'components/Modal';
import { block } from './utils';

export interface OptInSuccessProps {}

const OptInSuccess: React.FC<OptInSuccessProps> = () => {
  return (
    <Modal.Body className={block('opt-in-success-body')}>
      <div className={block('email-icon-container')}>
        <GradientIcon>
          <Email />
        </GradientIcon>
      </div>

      <Heading level={1}>
        We’ll email those assets soon! Now let’s finish your audiogram…
      </Heading>
    </Modal.Body>
  );
};

export default OptInSuccess;
