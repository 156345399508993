import { MixpanelWizardStep } from 'redux/modules/mixpanel/types';
import { TemplateWizardStep } from './types';

export const MIXPANEL_WIZARD_STEP_MAP: Record<
  TemplateWizardStep,
  MixpanelWizardStep
> = {
  [TemplateWizardStep.ADD_AUDIO]: 'Select Audio',
  [TemplateWizardStep.CLIP_AUDIO]: 'Clip Audio',
  [TemplateWizardStep.CUSTOMIZE]: 'Customize',
  [TemplateWizardStep.SUBMITTING]: 'loading',
  [TemplateWizardStep.EXPORTING]: 'loading',
};
