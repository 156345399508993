import * as React from 'react';

import { block } from './utils';

export interface IProps {
  children?: React.ReactNode;
  severity?: 'success' | 'warning' | 'error' | 'info';
}

const Alert: React.SFC<IProps> = ({ children, severity }) => (
  <div className={block('alert', { [severity]: true })}>{children}</div>
);

export default Alert;
