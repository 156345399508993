import * as React from 'react';

import VideoCameraAlt from '../VideoCameraAlt';
import CircularGradient, { CircularGradientProps } from './CircularGradient';

export interface CircularGradientVideoCameraProps
  extends Pick<CircularGradientProps, 'className'> {
  iconClassName?: string;
}

const CircularGradientVideoCamera: React.FC<CircularGradientVideoCameraProps> = ({
  className,
  iconClassName,
}) => (
  <CircularGradient className={className}>
    <VideoCameraAlt
      className={iconClassName}
      strokeWidth={1}
      style={{
        height: 'auto',
        marginLeft: '8%',
        width: '45%',
      }}
    />
  </CircularGradient>
);

export default CircularGradientVideoCamera;
