import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'EDDY-SERVICE';

export const ACTION_KEY: ActionKey = 'EDDY-SERVICE';

export enum ServiceMethod {
  CREATE_EDDY_PROJECT = 'CREATE_EDDY_PROJECT',
}

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export type EddyProjectMediaType = 'audio' | 'video';

export type EddyProjectAssociatedMedia = {
  makeVideoWidgetId: string;
  mediaType: EddyProjectMediaType;
};

export type CreateEddyProjectActionArgs = [
  string /* name */,
  EddyProjectAssociatedMedia /* associated media */,
];

export type CreateEddyProjectResult = {
  projectId: number;
  projectCuid: string;
  url: string;
};

export type CreateEddyProjectAction = ApiAction<
  ServiceMethod.CREATE_EDDY_PROJECT,
  CreateEddyProjectActionArgs
>;

export interface EddyServiceDispatch {
  (action: CreateEddyProjectAction): Promise<
    IApiResponse<CreateEddyProjectResult>
  >;
}
