import * as React from 'react';

import usePromise from 'hooks/usePromise';
import { getValue } from 'utils/collections';
import SearchResultList, {
  SearchResultListProps,
} from '../../components/SearchResultList';
import { podcastListBlock as block } from '../../utils';
import PodcastSearchResult from '../PodcastSearchResult';

const { useCallback } = React;

type PickedListProps = Pick<
  SearchResultListProps,
  'defaultScrollOffset' | 'height' | 'onScroll'
>;

export interface PodcastListProps extends PickedListProps {
  hasMore?: boolean;
  isLoading?: boolean;
  initialPage?: number;
  onLoadPage?: (nextPage: number) => Promise<void>;
  onPodcastClick?: (podcastId: string) => void;
  results: string[];
}

const PodcastList: React.SFC<PodcastListProps> = ({
  defaultScrollOffset,
  hasMore,
  height,
  isLoading,
  onLoadPage,
  onPodcastClick,
  onScroll,
  results,
}) => {
  const { withPromise } = usePromise<void>();

  const getItemKey = useCallback(index => getValue(results, [index]), [
    results,
  ]);

  return (
    <SearchResultList<string>
      className={block()}
      containerClassName={block('inner')}
      defaultScrollOffset={defaultScrollOffset}
      getItemKey={getItemKey}
      hasMore={hasMore}
      height={height}
      isLoading={isLoading}
      itemSize={60}
      onLoadPage={withPromise(onLoadPage)}
      onScroll={onScroll}
      results={results}
    >
      {props => <PodcastSearchResult {...props} onClick={onPodcastClick} />}
    </SearchResultList>
  );
};

export default PodcastList;
