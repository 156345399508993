import bem from 'utils/bem';
import {
  FreeToggleCardProps,
  PaidToggleCardProps,
  ToggleCardProps,
  ToggleCardTheme,
} from './types';

export const block = bem('toggle-card');
export const toggleBodyBlock = bem('toggle-card-toggle-body');
export const infoBodyBlock = bem('toggle-card-info-body');

export function isPaidToggleCardProps(
  props: Partial<ToggleCardProps>,
): props is PaidToggleCardProps {
  return props.isPro === true;
}

export function getPaidToggleCardProp<
  K extends keyof PaidToggleCardProps,
  D extends PaidToggleCardProps[K] = undefined
>(
  props: ToggleCardProps,
  key: K,
  defaultValue?: D,
): PaidToggleCardProps[K] | D {
  if (isPaidToggleCardProps(props)) {
    return props[key] ?? defaultValue;
  }

  return defaultValue;
}

export function getFreeToggleCardProp<
  K extends keyof FreeToggleCardProps,
  D extends FreeToggleCardProps[K] = undefined
>(props: ToggleCardProps, key: K, defaultValue: D): FreeToggleCardProps[K] | D {
  if (!isPaidToggleCardProps(props)) {
    return props[key] ?? defaultValue;
  }

  return defaultValue;
}

/**
 * When `isPro === true`, props are of type PaidToggleCardProps and theme is not
 * supported - it's forced to be "rainbow" as that's the typical paid feature
 * designation in the app
 */
export function getTheme(props: ToggleCardProps): ToggleCardTheme {
  if (isPaidToggleCardProps(props)) {
    return 'rainbow';
  }

  return props.theme;
}
