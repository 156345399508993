import * as React from 'react';

import { IconProps } from './types';

const BoxedLetterT: React.SFC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M6 0h32a6 6 0 0 1 6 6v32a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6a6 6 0 0 1 6-6zm0 3l-.176.005A3 3 0 0 0 3 6v32a3 3 0 0 0 3 3h32a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3H6z" />
      <path d="M18 35.5h8a1.5 1.5 0 0 0 0-3h-8a1.5 1.5 0 0 0 0 3z" />
      <path d="M23.5 34V10a1.5 1.5 0 0 0-3 0v24a1.5 1.5 0 0 0 3 0z" />
      <path d="M11.5 11.5h21V15a1.5 1.5 0 0 0 3 0v-5A1.5 1.5 0 0 0 34 8.5H10A1.5 1.5 0 0 0 8.5 10v5a1.5 1.5 0 0 0 3 0v-3.5z" />
    </g>
  </svg>
);

export default BoxedLetterT;
