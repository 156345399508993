import * as React from 'react';

interface IProps {
  children: JSX.Element;
  rehydrated?: boolean;
}

const RehydrationGuard: React.SFC<IProps> = ({ children, rehydrated }) =>
  rehydrated ? children : null;

export default RehydrationGuard;
export { IProps as RehydrationGuardProps };
