import * as React from 'react';

import { IconProps } from './types';

const CaptionFile: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="84"
    height="96"
    viewBox="0 0 84 96"
    {...props}
  >
    <defs>
      <path
        id="captionfile-path"
        d="M3 0h31.757a3 3 0 0 1 2.122.879L47.12 11.12A3 3 0 0 1 48 13.243V57a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z"
      />
      <filter
        id="captionfile-filter"
        width="220.8%"
        height="196.7%"
        x="-60.4%"
        y="-41.7%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="1"
          result="shadowSpreadOuter1"
        />
        <feOffset dy="4" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="8"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(18 14)">
        <use
          fill="#000"
          filter="url(#captionfile-filter)"
          xlinkHref="#captionfile-path"
        />
        <use fill="#323746" xlinkHref="#captionfile-path" />
      </g>
      <path
        fill="#646E82"
        fillRule="nonzero"
        d="M52.656 35H32.344C31.05 35 30 36.008 30 37.25v13.5c0 1.242 1.05 2.25 2.344 2.25h20.312C53.95 53 55 51.992 55 50.75v-13.5c0-1.242-1.05-2.25-2.344-2.25zM40.65 45.486a.31.31 0 0 1 .45.042l.952 1.299a.275.275 0 0 1-.025.36c-2.617 2.663-8.437 1.505-8.437-3.182 0-4.561 5.942-5.602 8.423-3.286.102.093.122.15.049.267l-.855 1.43a.312.312 0 0 1-.444.08c-1.992-1.5-4.62-.7-4.62 1.462.005 2.25 2.496 3.305 4.507 1.528zm9.297 0a.31.31 0 0 1 .45.042l.952 1.299a.275.275 0 0 1-.025.36c-2.612 2.668-8.432 1.505-8.432-3.182 0-4.561 5.942-5.602 8.422-3.286.103.093.123.15.05.267l-.855 1.43a.312.312 0 0 1-.445.08c-1.992-1.5-4.619-.7-4.619 1.462 0 2.25 2.49 3.305 4.502 1.528z"
      />
    </g>
  </svg>
);

export default CaptionFile;
