import { Record, RecordOf } from 'immutable';

// utils
import { reviveRecord } from 'utils/immutable';

// types
import {
  AudioSelectionState,
  EditAutomationState,
  OutputState,
  State,
} from './types';

const audioSelectionFactory = Record<AudioSelectionState>(
  {
    duration: undefined,
    type: undefined,
  },
  'AudioSelectionState',
);

const outputFactory = Record<OutputState>(
  {
    option: undefined,
  },
  'OutputState',
);

export const stateFactory = Record<State>({
  subscriptionId: undefined,
  status: undefined,
  audioSelection: audioSelectionFactory(),
  output: outputFactory(),
  frequency: undefined,
});

export function stateFromJs(obj: Object): RecordOf<EditAutomationState> {
  return reviveRecord(stateFactory, obj);
}
