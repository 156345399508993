import cn from 'classnames';
import * as React from 'react';

import { block } from './utils';

export interface EditorPaneProps {
  children?: React.ReactNode;
  className?: string;
  onMouseDown?: React.MouseEventHandler<HTMLElement>;
}

/*
 * Parent component of the Preview and Options panes
 */
const EditorPane: React.FC<EditorPaneProps> = ({
  children,
  className,
  onMouseDown,
}) => (
  <div className={cn(block('column'), className)} onMouseDown={onMouseDown}>
    {children}
  </div>
);

export default EditorPane;
