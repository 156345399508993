import * as React from 'react';

import { IconProps } from './types';

const Close: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle
        cx="17.5"
        cy="17.5"
        r="16.333"
        stroke="currentColor"
        strokeWidth="2.333"
      />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M22.18 20.308L19.371 17.5l2.807-2.808c.345-.344.345-.903 0-1.247l-.624-.624c-.344-.345-.903-.345-1.247 0L17.5 15.628l-2.808-2.807c-.344-.345-.903-.345-1.247 0l-.624.624c-.345.344-.345.903 0 1.247l2.807 2.808-2.807 2.808c-.345.344-.345.903 0 1.247l.624.624c.344.345.903.345 1.247 0l2.808-2.807 2.808 2.807c.344.345.903.345 1.247 0l.624-.624c.345-.344.345-.903 0-1.247z"
      />
    </g>
  </svg>
);

export default Close;
