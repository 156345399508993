import classNames from 'classnames';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

import DropdownMenu from './DropdownMenu';
import { blockNavigation } from './utils';

interface IProps {
  children?: React.ReactNode;
  icon: React.ReactNode;
  id: string;
  header?: React.ReactNode;
  className?: string;
}

const DropdownMenuContainer: React.SFC<IProps> = ({
  children,
  className,
  header,
  icon,
  id,
}) => (
  <Dropdown
    className={classNames(
      blockNavigation('dropdown'),
      'navigation-icon',
      className,
    )}
    id={id}
  >
    {icon}

    <DropdownMenu bsRole="menu" header={header}>
      {children}
    </DropdownMenu>
  </Dropdown>
);

export default DropdownMenuContainer;
export { IProps as DropdownMenuContainerProps };
