import { createSelector } from 'reselect';
import * as selectors from '../../selectors';
import * as constants from './constants';

const { UploadType } = constants;

export const recordingUploadInProgressSelector = createSelector(
  [selectors.recordingUploadStatusSelector],
  recordingUploadState =>
    recordingUploadState === constants.UPLOAD_STATE_UPLOADING ||
    recordingUploadState === constants.UPLOAD_STATE_IMPORTING,
);

export const uploadedRecordingIdSelector = state =>
  state.getIn(['recordingUpload', 'recordingId']);

export const recordingUploadStatusMessageSelector = createSelector(
  [selectors.recordingUploadStatusSelector],
  status => {
    function createResult(message) {
      return { status, message };
    }

    switch (status) {
      case constants.UPLOAD_STATE_IMPORTING:
        return createResult('Importing Audio');

      case constants.UPLOAD_STATE_UPLOADED:
      case constants.UPLOAD_STATE_FAILED:
        return createResult();

      default:
        return createResult('Uploading Audio');
    }
  },
);

const recordingUploadTypeStringSelector = state =>
  state.getIn(['recordingUpload', 'type']);
export const recordingUploadTypeSelector = createSelector(
  [recordingUploadTypeStringSelector],
  type => UploadType.enumValueOf(type),
);

export const recordingUploadTranscribeSelector = state =>
  state.getIn(['recordingUpload', 'transcribe']);

export const recordingUploadSelector = createSelector(
  [
    uploadedRecordingIdSelector,
    recordingUploadInProgressSelector,
    recordingUploadTypeSelector,
    recordingUploadTranscribeSelector,
  ],
  (recordingId, inProgress, type, transcribe) => ({
    inProgress,
    recordingId,
    type,
    transcribe,
  }),
);

export default recordingUploadInProgressSelector;
