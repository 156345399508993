import * as React from 'react';
import { connect } from 'react-redux';
import { isString, omit } from 'underscore';

import { Navigator } from 'components/Wizard';
import * as mixpanelActions from 'redux/modules/mixpanel';
import { getMixpanelBlurValue } from 'redux/modules/mixpanel/utils';
import { showError } from 'redux/modules/notification/actions';
import { clearSearchResults } from 'redux/modules/podcast-search';
import { goToCreate } from 'redux/modules/router/actions';
import { exportFromWizard } from 'redux/modules/wizard-export/actions';
import { Dispatch } from 'redux/types';
import { ImageOriginId, SoundwavePositionValue } from 'types';
import { isApplicationError } from 'utils/ApplicationError';
import { getDuration } from 'utils/audio';
import { DropZoneType } from 'utils/constants';
import { getBlurRadius, hasSlideFromOrigin } from 'utils/embed/embed';
import {
  getPositionIdFromWave,
  mapSoundwaveGeneration,
} from 'utils/embed/soundwave';
import { FatalError } from 'utils/FatalError';
import { MIXPANEL_WIZARD_STEP_MAP } from './constants';
import EpisodeWizard from './EpisodeWizard';
import { EpisodeWizardProps as Props } from './types';

type DispatchProps = Pick<
  Props,
  'onCancel' | 'onError' | 'onMount' | 'onStepChange' | 'onSubmit' | 'onUnmount'
>;

const ERROR_ID = 'episode-wizard-error';

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onCancel: (nav: Navigator) => {
    dispatch(
      mixpanelActions.onCancelWizard(
        'episode',
        MIXPANEL_WIZARD_STEP_MAP[nav.stepId],
      ),
    );
    dispatch(goToCreate());
  },
  onError: (err, file = undefined) => {
    const message = isString(err) ? err : err.message;
    const code = !isString(err) && isApplicationError(err) ? err.code : null;

    if (file) {
      dispatch(
        mixpanelActions.onFileUpload(DropZoneType.EPISODE, file, message),
      );
    }

    if (err instanceof FatalError) {
      dispatch(
        showError({
          message: 'There was an error creating your video',
          code: 'ER003',
          id: ERROR_ID,
        }),
      );
      dispatch(goToCreate());
    } else {
      dispatch(showError({ message, code, id: ERROR_ID }));
    }
  },
  onMount: () => dispatch(mixpanelActions.clearMixpanel()),
  onStepChange: (to, from, { templateId, audioSource } = {}) => {
    if (from && to.stepId !== 'loading') {
      dispatch(
        mixpanelActions.onWizardNext({
          templateId,
          step: MIXPANEL_WIZARD_STEP_MAP[from.stepId],
          type: 'episode',
          audioSource,
        }),
      );
    }
  },
  onSubmit: async config => {
    const {
      audioSource,
      originalAudioUrl,
      backgroundColor,
      slideshow,
      textOverlays,
      frameSizeOverride,
    } = config;
    const originalAudioDuration = await getDuration(audioSource);
    const waveOptions = {
      waveColor: config.soundwave?.waveColor,
      waveGeneration: mapSoundwaveGeneration(config.soundwave?.waveGeneration),
      wavePosition: getPositionIdFromWave(
        config.soundwave,
      ) as SoundwavePositionValue,
      waveStyle: config.soundwave?.waveType,
      waveformPrefId: config.soundwave?.waveformPrefId,
    };

    dispatch(
      mixpanelActions.onCompleteWizard({
        backgroundColor,
        originalAudioDuration,
        addedImage: slideshow?.length > 0,
        addedText: textOverlays?.length > 0,
        audioSource: config.audioSourceType,
        canvaImage: hasSlideFromOrigin(ImageOriginId.CANVA, config),
        templateId: config.templateId,
        transcription: false,
        type: 'episode',
        blurValue: getMixpanelBlurValue(getBlurRadius(config)),
        ...waveOptions,
      }),
    );

    await dispatch(
      exportFromWizard({
        ...omit(
          config,
          'audioSource',
          'audioSourceType',
          'originalAudioUrl',
          'frameSizeOverride',
        ),
        audio: {
          source: originalAudioUrl ?? audioSource,
        },
        fullEpisodeExport: true,
        initiateExport: true,
        projectCreatMethod: 'episode',
        soundwave: {
          waveGeneration: 'amplitudeBased',
          ...config.soundwave,
        },
        transcription: {
          transcribe: false,
          transcriptUrl: undefined,
        },
        frameSizeOverride,
      }),
    );
  },
  onUnmount: () => {
    dispatch(clearSearchResults());
  },
});

const component = connect(null, mapDispatchToProps)(EpisodeWizard);
export type EpisodeWizardProps = React.ComponentPropsWithoutRef<
  typeof component
>;
export default component;
