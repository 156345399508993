import { connect } from 'react-redux';

import { onClickReferralCopy } from 'redux/modules/mixpanel/actions';
import { myReferralUrlSelector } from 'redux/modules/pricing';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import UrlInvitationForm, {
  UrlInvitationFormProps as Props,
} from './UrlInvitationForm';

type DispatchProps = Pick<Props, 'onCopyClick'>;
type StateProps = Pick<Props, 'inviteUrl'>;

const mapStateToProps = (state: State): StateProps => ({
  inviteUrl: myReferralUrlSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onCopyClick: () => dispatch(onClickReferralCopy()),
});

const component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UrlInvitationForm);
export type UrlInvitationFormProps = PropsOf<typeof component>;
export default component;
