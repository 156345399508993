import * as React from 'react';
import { connect } from 'react-redux';

import { embedSavingSelector } from 'redux/modules/embed/selectors';
import { State } from 'redux/types';
import {
  StyleButton,
  StyleButtonProps,
} from '../../components/StyleTileButton';

type StateProps = Pick<StyleButtonProps, 'disabled'>;

const VideoStyleButton = React.forwardRef<HTMLDivElement, StyleButtonProps>(
  (props, ref) => <StyleButton {...props} ref={ref} />,
);

const mapStateToProps = (state: State): StateProps => ({
  disabled: embedSavingSelector(state),
});

const component = connect<StateProps, {}, React.RefAttributes<HTMLDivElement>>(
  mapStateToProps,
  null,
  null,
  { forwardRef: true },
)(VideoStyleButton);

export type VideoStyleButtonProps = React.ComponentPropsWithoutRef<
  typeof component
>;

export default component;
