import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { transcribeEpisode } from 'redux/modules/automation-cms';
import { EpisodeTranscriptError } from 'redux/modules/automation-cms/constants';
import { onClickTranscribeEpisode } from 'redux/modules/mixpanel';
import { hideModal, replaceModal } from 'redux/modules/modal';
import { showError } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { EPISODE_TRANSCRIPTION_ERROR_STATUSES } from 'utils/constants';
import usePodcastFeedEpisode from './usePodcastFeedEpisode';
import usePreviousRef from './usePreviousRef';

export interface UseCreateEpisodeTranscriptionConfig {
  episodeId: string;
}

export interface UseCreateEpisodeTranscriptionResult {
  createEpisodeTranscription: () => Promise<void>;
}

export default function useCreateEpisodeTranscription({
  episodeId,
}: UseCreateEpisodeTranscriptionConfig): UseCreateEpisodeTranscriptionResult {
  const { transcriptInfo } = usePodcastFeedEpisode(episodeId);
  const status = transcriptInfo?.status;
  const statusRef = usePreviousRef(status);
  const dispatch = useDispatch<Dispatch>();

  const handleCreateEpisodeTranscription = useCallback(async (): Promise<
    void
  > => {
    dispatch(onClickTranscribeEpisode);

    try {
      await dispatch(transcribeEpisode(episodeId));

      dispatch(hideModal());
    } catch (err) {
      if (
        !(err instanceof EpisodeTranscriptError) ||
        err.reason === 'transcribeTimeLimitReached'
      ) {
        return dispatch(replaceModal({ name: 'EpisodeTranscriptionError' }));
      }

      if (err.reason === 'languageNotSupported') {
        return dispatch(
          replaceModal({
            name: 'EpisodeUnsupportedLanguage',
            params: {
              language: err.language,
            },
          }),
        );
      }

      if (err.reason === 'concurrentLimitReached') {
        dispatch(
          showError({
            dismissAfterSec: 5,
            message: 'Please wait until your other transcript is generated',
            title: 'Another transcription is in progress',
          }),
        );

        return undefined;
      }

      if (err.reason === 'conflict') {
        if (EPISODE_TRANSCRIPTION_ERROR_STATUSES.includes(statusRef.current)) {
          return dispatch(replaceModal({ name: 'EpisodeTranscriptionError' }));
        }

        dispatch(hideModal());
      }
    }

    return undefined;
  }, [dispatch, episodeId, statusRef]);

  return {
    createEpisodeTranscription: handleCreateEpisodeTranscription,
  };
}
