import { useEffect, useRef, useState } from 'react';
import { noop } from 'underscore';

import { getFileSize } from 'utils/file';
import usePromise from './usePromise';

export type FileSource = File | string;

export interface UseFileSizeOptions {
  onError?: (msg: string, file: FileSource) => void;
}

const DEFAULT_OPTIONS = {
  onError: noop,
};

export default function useFileSize(options?: UseFileSizeOptions) {
  const { onError } = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  const [isGettingSize, setIsGettingSize] = useState(false);
  const [source, setSource] = useState<FileSource>();
  const [fileSize, setFileSize] = useState<number>(undefined);
  const { setPromise } = usePromise();
  const onErrorRef = useRef(onError);

  useEffect(() => {
    onErrorRef.current = onError;
  }, [onError]);

  useEffect(() => {
    if (!source) return undefined;

    setIsGettingSize(true);
    setPromise(
      (getFileSize(source) as any)
        .finally(() => setIsGettingSize(false))
        .then(setFileSize)
        .catch(() => onErrorRef.current?.('Error getting file size', source)),
    );
    return () => {
      setFileSize(undefined);
    };
  }, [setPromise, source]);

  return {
    fileSize,
    isGettingSize,
    setSource,
    source,
  };
}
