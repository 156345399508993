import * as React from 'react';

import Gift from '../Gift';
import CircularGradient, { CircularGradientProps } from './CircularGradient';

export interface CircularGradientGiftProps
  extends Pick<CircularGradientProps, 'className'> {
  iconClassName?: string;
}

const CircularGradientGift: React.FC<CircularGradientGiftProps> = ({
  className,
  iconClassName,
}) => (
  <CircularGradient className={className}>
    <Gift
      className={iconClassName}
      strokeWidth={1}
      style={{
        height: 'auto',
        width: '45%',
      }}
    />
  </CircularGradient>
);

export default CircularGradientGift;
