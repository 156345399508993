import { Type } from './action-types';
import { AutomatedWizardStatus } from './constants';
import { entireAudioEnhancer } from './enhancers';
import { stateFactory } from './factories';
import { AutomatedWizardAction, AutomatedWizardState } from './types';

const defaultState = stateFactory();

function setStatus(
  state: AutomatedWizardState,
  status?: AutomatedWizardStatus,
) {
  return state.set('status', status);
}

function reducer(
  state: AutomatedWizardState = defaultState,
  action: AutomatedWizardAction,
) {
  switch (action.type) {
    case Type.AUTOMATED_WIZARD_CREATE_REQUEST: {
      const { aspectRatioDimensions } = action.payload;
      return state.set('aspectRatioDimensions', aspectRatioDimensions);
    }

    case Type.AUTOMATED_WIZARD_CONFIGURATION_CREATE_REQUEST: {
      return setStatus(state, AutomatedWizardStatus.CREATING_CONFIGURATION);
    }

    case Type.AUTOMATED_WIZARD_CONFIGURATION_CREATE_SUCCESS: {
      const { widgetId } = action.payload;
      return state.set('widgetId', widgetId);
    }

    case Type.AUTOMATED_WIZARD_PROJECT_CREATE_REQUEST:
      return setStatus(state, AutomatedWizardStatus.CREATING_PROJECT);

    case Type.AUTOMATED_WIZARD_PROJECT_CREATE_SUCCESS: {
      const { projectId } = action.payload;
      return state
        .set('projectId', projectId)
        .set('status', AutomatedWizardStatus.FINISHED);
    }

    case Type.AUTOMATED_WIZARD_CLEAR:
      return defaultState;

    default:
      return state;
  }
}

export default entireAudioEnhancer.createReducer(reducer, stateFactory());
