import merge from 'utils/deepmerge';
import {
  Action,
  AutogramWizardSubscriptionOptions,
  AutomationWorkflowWizardState,
} from '../types';

export default function reducer(
  state: AutomationWorkflowWizardState,
  action: Action,
): AutomationWorkflowWizardState {
  switch (action.type) {
    case 'SUBSCRIPTION_OPTIONS_CHANGE': {
      const {
        payload: { options, strategy },
      } = action;

      const { subscriptionOptions } = state;
      const newOptions: AutogramWizardSubscriptionOptions =
        strategy === 'overwrite'
          ? options
          : merge(subscriptionOptions, options);

      const newState = { ...state, subscriptionOptions: newOptions };

      return newState;
    }

    case 'STEP_CHANGE': {
      const { from, to } = action.payload;

      // clear template when navigating away from template step
      const templateId =
        (from?.stepId === 'template' || from?.stepId === 'customize') &&
        to?.index < from?.index
          ? undefined
          : state.templateId;

      return {
        ...state,
        templateId,
        step: to.stepId,
      };
    }

    case 'PODCAST_SELECT': {
      const { podcastId } = action.payload;
      return { ...state, podcastId };
    }

    case 'ASPECT_RATIO_SELECT':
      return { ...state, aspectRatioName: action.payload };

    case 'DESTINATION_OPTIONS_CHANGE': {
      return {
        ...state,
        destinationOptions: {
          ...state.destinationOptions,
          ...action.payload,
        },
      };
    }

    case 'EPISODE_OPTION_CHANGE': {
      return {
        ...state,
        episodeOption: action.payload,
      };
    }

    case 'SUBMIT_REQUEST':
      return { ...state, creatingWorkflow: true };

    case 'SUBMIT_COMPLETE':
      return { ...state, creatingWorkflow: false };

    case 'TEMPLATE_SELECT': {
      const { templateId } = action.payload;
      return { ...state, templateId };
    }

    case 'LANGUAGE_CHANGE': {
      const { payload: language } = action;
      return { ...state, language };
    }

    case 'TARGETED_SEASONS_CHANGE': {
      const { payload: targetedSeasons } = action;
      return {
        ...state,
        subscriptionOptions: {
          ...state.subscriptionOptions,
          targetedSeasons,
        },
      };
    }

    case 'TRANSCRIPTION_CHANGE': {
      const {
        payload: { language, transcribe },
      } = action;

      return {
        ...state,
        language,
        subscriptionOptions: {
          ...state.subscriptionOptions,
          isCaptionEnabled: transcribe,
        },
        transcription: {
          language,
          transcribe,
        },
      };
    }

    case 'CAPTIONS_CONFIG_UPDATE': {
      const {
        payload: { captions },
      } = action;

      return {
        ...state,
        captions,
      };
    }

    case 'PREVIEW_REQUEST':
      return {
        ...state,
        creatingWorkflowPreview: true,
      };

    case 'PREVIEW_COMPLETE':
      return {
        ...state,
        creatingWorkflowPreview: false,
        subscriptionPreviewId: action.payload.subscriptionPreviewJobId,
      };

    case 'PREVIEW_FAILURE':
      return {
        ...state,
        creatingWorkflowPreview: false,
      };

    default:
      return state;
  }
}
