import React from 'react';
import { AutoPostingPlatform } from 'blocks/AutomationWorkflowWizard/types';
import { EmailOutput, HeadlinerMarkColor, RSS } from 'components/icons';
import { AutogramClipSelectionMethod } from 'types';
import { AutomationOptionsCardsOptions, AutoPostOptions } from './types';
import { block } from './utils';

export const DEFAULT_EMAIL_POSTING_OPTION: AutomationOptionsCardsOptions = {
  id: 'email',
  contents: (
    <>
      <strong>Email videos to me</strong> so I can
      <br />
      manually post them myself
    </>
  ),
  icon: <EmailOutput width="70" />,
  value: {
    automationOption: AutoPostOptions.EMAIL,
  },
};

export const DEFAULT_CLIP_SELECTION_OPTIONS: AutomationOptionsCardsOptions[] = [
  {
    id: 'headliner-ai',
    contents: (
      <>
        <strong>Let Headliner AI select clips</strong>
        <br />
        that I can easily adjust later
      </>
    ),
    icon: (
      <div className={block('icon')}>
        <HeadlinerMarkColor />
      </div>
    ),
    value: {
      clipSelectionMethod: AutogramClipSelectionMethod.SMART,
    },
  },
  {
    id: 'soundbite-tags',
    contents: (
      <>
        <strong>Use Soundbite tags</strong> that I'll
        <br />
        manually add to my RSS feed
      </>
    ),
    icon: (
      <div className={block('icon')}>
        <RSS />
      </div>
    ),
    value: {
      clipSelectionMethod: AutogramClipSelectionMethod.SOUNDBITE_TAG,
    },
  },
];

export const AUTO_POSTING_PLATFORM_NAME_MAP: Record<
  AutoPostingPlatform,
  string
> = {
  youtube: 'YouTube',
  tiktok: 'TikTok',
  instagram: 'Instagram',
  linkedin: 'LinkedIn',
  facebook: 'Facebook',
  twitter: 'X',
  threads: 'Threads',
};
