import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import FreeTrialWelcomeModalContents from './FreeTrialWelcomeModalContents';

export interface FreeTrialWelcomeModalProps {}

const FreeTrialWelcomeModal: React.FC<FreeTrialWelcomeModalProps> = () => (
  <ConnectedModal name="FreeTrialWelcome" title="Your Free Trial of Pro">
    <FreeTrialWelcomeModalContents />
  </ConnectedModal>
);

export default FreeTrialWelcomeModal;
