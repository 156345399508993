import React from 'react';

import { Option } from 'components/Select';

import { YoutubeLanguage } from 'redux/middleware/api/podcast-service/types';
import YoutubeSelect from '../YoutubeSelect';
import {
  formatLanguageOptions,
  getLanguageOption,
  getSelectedOptionValue,
} from './utils';

export interface YoutubeLanguageSelectorProps {
  languageOptions: YoutubeLanguage[];
  onChange: (value: string) => void;
  value?: string;
}

const YoutubeLanguageSelector: React.FunctionComponent<YoutubeLanguageSelectorProps> = props => {
  const { languageOptions, onChange, value } = props;

  const disabled = !languageOptions.length;
  const options = formatLanguageOptions(languageOptions);

  const handleSelectValue = React.useCallback(
    (option: Option): void => {
      const nextValue = getSelectedOptionValue(option);
      onChange(nextValue);
    },
    [onChange],
  );

  return (
    <YoutubeSelect
      disabled={disabled}
      label="Language"
      options={options}
      onChange={handleSelectValue}
      value={getLanguageOption(options, value)}
    />
  );
};

export default YoutubeLanguageSelector;
