import React, { FC } from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import ExitRetryPeriodModalContents from './ExitRetryPeriodModalContents';

const ExitRetryPeriodModal: FC = () => (
  <ConnectedModal name="ExitRetryPeriod" title="give up your plan">
    <ExitRetryPeriodModalContents />
  </ConnectedModal>
);

export default ExitRetryPeriodModal;
