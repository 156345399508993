import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { PlanName } from 'redux/middleware/api/plan-service';
import { onCompleteProCancellation } from 'redux/modules/mixpanel/actions';
import {
  showNotification,
  showSuccess,
} from 'redux/modules/notification/actions';
import {
  cancelSubscription,
  loadPricingData,
} from 'redux/modules/pricing/actions';
import { planNameSelector } from 'redux/modules/pricing/selectors';
import { ThunkAction } from 'redux/types';
import { PlanCancellationReason } from 'types';

interface UseCancellationDetailsModalConfig {
  reason: PlanCancellationReason;
}

const handleSubmit = (
  reason: PlanCancellationReason,
  details: string,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  try {
    await dispatch(cancelSubscription());
    await dispatch(loadPricingData({ force: true }));
    dispatch(
      showSuccess(
        'You can restart your subscription at any time',
        10,
        undefined,
        'Your plan has been cancelled',
      ),
    );
    const planName: PlanName = planNameSelector(getState());
    const period = planName === PlanName.PRO_MONTHLY ? 'monthly' : 'yearly';
    dispatch(onCompleteProCancellation(period, reason, details));
  } catch {
    dispatch(
      showNotification({
        message: 'Please try again or {{link}} so we can help',
        code: 'ER013',
        level: 'error',
        type: 'help',
        title: "Sorry, we couldn't cancel your plan",
        actionLabel: 'contact us',
      }),
    );
  }
};

export default function usePlanCancellation({
  reason,
}: UseCancellationDetailsModalConfig) {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (details: string) => {
      dispatch(handleSubmit(reason, details));
    },
    [dispatch, reason],
  );

  return { onSubmit };
}
