import cn from 'classnames';
import * as React from 'react';

import { authCardBlock as block } from '../utils';

type Theme = 'error';

export interface AuthCardBannerProps {
  children?: JSX.Element;
  className?: string;
  theme?: Theme;
}

const AuthCardBanner: React.SFC<AuthCardBannerProps> = ({
  children,
  className,
  theme,
}) => (
  <div className={cn(block('banner', { [theme]: !!theme }), className)}>
    {children}
  </div>
);

AuthCardBanner.defaultProps = {
  theme: 'error',
};

export default AuthCardBanner;
