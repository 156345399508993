import { createSelector } from 'reselect';

import { RequestStatus } from 'types';
import { decode } from 'utils/jwt';

export const authActionSelector = state => state.getIn(['auth', 'action']);
export const authInProgressSelector = state => {
  const inProgress = state.getIn(['auth', 'inProgress'], false);
  const socialInProgress = state.getIn(['auth', 'socialAuthInProgress'], false);

  return inProgress || socialInProgress;
};
export const passwordRequestSuccessSelector = state =>
  state.getIn(['auth', 'passwordRequestSuccess'], false);
export const tokenSelector = state => state.getIn(['auth', 'token']);
export const userIdSelector = state => state.getIn(['auth', 'userId']);

// TODO legacy selector where "email" is referred to as username.  probably
// clearer to just call it "email" since a "name" field is also captured
export const userNameSelector = state => state.getIn(['auth', 'username']);
export const nameSelector = state => state.getIn(['auth', 'name']);

export const authErrorSelector = state => state.getIn(['auth', 'error'], false);
export const handleSelector = state => state.getIn(['auth', 'handle'], null);
export const confirmEmailSelector = state =>
  state.getIn(['auth', 'confirmEmail']);

export const authProviderSelector = state =>
  state.getIn(['auth', 'authenticationProvider']);

export const isAuthModalVisibleSelector = state =>
  state.getIn(['auth', 'isModalVisible']);

export const authInitialStageSelector = state =>
  state.getIn(['auth', 'initialStage']);

export const passwordUpdateInProgressSelector = createSelector(
  [authActionSelector, authInProgressSelector],
  (action, inProgress) => action === 'updatePassword' && inProgress,
);

export const forgotPasswordInProgressSelector = createSelector(
  [authActionSelector, authInProgressSelector],
  (action, inProgress) => action === 'forgotPassword' && inProgress,
);

function isValidToken(timestamp) {
  if (!timestamp) return false;

  const tokenExpDate = new Date(timestamp * 1000);
  const todayDate = new Date();

  return todayDate.getTime() < tokenExpDate.getTime();
}

export const tokenDateSelector = createSelector([tokenSelector], token => {
  if (!token) {
    return null;
  }

  const decodedToken = decode(token);
  return decodedToken && decodedToken.exp;
});

// Not using reselect in this one since the current date changes. This
// selector is used to see if a user has a token.  The user profile may not
// have been created even if this returns true
export const userRegisteredSelector = state => {
  const tokenDate = tokenDateSelector(state);
  return isValidToken(tokenDate);
};

export const loggedInSelector = state =>
  userRegisteredSelector(state) && handleSelector(state) !== null;

export const loggedOutSelector = state => !loggedInSelector(state);

export const errorMessageSelector = state =>
  authErrorSelector(state) ? state.getIn(['auth', 'errorMessage'], null) : null;

export const passwordResetStatusSelector = createSelector(
  [authInProgressSelector, passwordRequestSuccessSelector],
  (inProgress, successful) => {
    if (inProgress === undefined) return undefined;

    if (inProgress) {
      return RequestStatus.REQUEST;
    }

    return successful ? RequestStatus.SUCCESS : RequestStatus.FAILURE;
  },
);

export const userIntegratorsSelector = state =>
  state.getIn(['auth', 'integrators'], []);
