import * as React from 'react';
import { noop } from 'underscore';

import IconButton from 'components/IconButton';
import { Download } from 'components/icons';
import Tooltip from 'components/Tooltip';

interface IProps {
  onDownloadClick: () => void;
}

const CaptionsDownload: React.SFC<IProps> = ({ onDownloadClick }) => (
  <Tooltip
    animation={false}
    content="Download captions"
    id="captions-download-tooltip"
    placement="top"
    preventHideOnHover={false}
  >
    <IconButton onButtonClick={onDownloadClick}>
      <Download style={{ height: 20 }} />
    </IconButton>
  </Tooltip>
);

CaptionsDownload.defaultProps = {
  onDownloadClick: noop,
};

export default CaptionsDownload;
export { IProps as CaptionsDownloadProps };
