import _ from 'underscore';
import ArrowDown from 'components/icons/ArrowDown';
import ArrowDownDash from 'components/icons/ArrowDownDash';
import ArrowUp from 'components/icons/ArrowUp';
import ArrowUpDash from 'components/icons/ArrowUpDash';
import { VideoTemplateStateContent } from 'components/VideoTemplateEditor/types';
import { getActiveLayers } from 'components/VideoTemplateEditor/utils';
import bem from 'utils/bem';

import { ControlsId, TooltipMessageOption } from './types';

export const block = bem('layer-order-controls');

const DEFAULT_NOT_AVAILABLE_TOOLTIP_MESAGE = 'Not available for this template';

const TOOLTIP_MESSAGES: Record<ControlsId, TooltipMessageOption> = {
  front: {
    enabled: 'Send to front',
    disabled: 'Asset already in front',
  },
  forward: {
    enabled: 'Forward',
    disabled: 'Asset already in front',
  },
  backward: {
    enabled: 'Backward',
    disabled: 'Asset already in back',
  },
  back: {
    enabled: 'Send to back',
    disabled: 'Asset already in back',
  },
};

export const LAYER_CONTROL_BUTTONS_ICONS: Record<
  ControlsId,
  React.ElementType
> = {
  front: ArrowUpDash,
  forward: ArrowUp,
  backward: ArrowDown,
  back: ArrowDownDash,
};

/**
 * Checks if the layer can move up or down in the layers order.
 *
 * @param {string} layerId - The id of the layer you want to move.
 * @param {string[]} layers - An array of layers ids in the order they appear.
 * @param {boolean} isComplex - If the template is complex, moving is not allowed.
 *
 * @returns An object with two properties, up and down, which
 * are booleans indicating whether the layer can move up or down.
 */
export function checkCanLayerMove(
  state: VideoTemplateStateContent,
  layerId: string,
): Record<ControlsId, boolean> {
  const { isComplex } = state;

  const activeLayers = getActiveLayers(state).reverse(); // Layer rendering order.
  const up = _.last(activeLayers) !== layerId && !isComplex;
  const down = _.first(activeLayers) !== layerId && !isComplex;

  return {
    front: up,
    forward: up,
    backward: down,
    back: down,
  };
}

/**
 * It returns a tooltip message for each control based on the possible
 * movements and whether the control is complex or not.
 *
 * @param possibleMovements - Allowed movements for the selected layer.
 * @param {boolean} isComplex - If the template is complex, all controls are disabled.
 *
 * @returns An object with the list of control ids as keys and the relative tooltip message as values.
 */
export function getTooltipMessages(
  possibleMovements: Record<ControlsId, boolean>,
  isComplex: boolean,
): Record<ControlsId, string> {
  if (isComplex) {
    return {
      front: DEFAULT_NOT_AVAILABLE_TOOLTIP_MESAGE,
      forward: DEFAULT_NOT_AVAILABLE_TOOLTIP_MESAGE,
      backward: DEFAULT_NOT_AVAILABLE_TOOLTIP_MESAGE,
      back: DEFAULT_NOT_AVAILABLE_TOOLTIP_MESAGE,
    };
  }

  const { front, forward, backward, back } = possibleMovements;

  return {
    front: TOOLTIP_MESSAGES.front[front ? 'enabled' : 'disabled'],
    forward: TOOLTIP_MESSAGES.forward[forward ? 'enabled' : 'disabled'],
    backward: TOOLTIP_MESSAGES.backward[backward ? 'enabled' : 'disabled'],
    back: TOOLTIP_MESSAGES.back[back ? 'enabled' : 'disabled'],
  };
}
