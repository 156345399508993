import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import TikTokAutoPostingUpsellModalContent from './TikTokAutoPostingUpsellModalContent';
import { block } from './utils';

const TikTokAutoPostingUpsellModal = () => (
  <ConnectedModal
    name="TikTokAutoPostingUpsell"
    title="tiktok auto-posting"
    className={block()}
  >
    <TikTokAutoPostingUpsellModalContent />
  </ConnectedModal>
);

export default TikTokAutoPostingUpsellModal;
