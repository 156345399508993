import { Sparkle } from '@sparemin/blockhead';
import React from 'react';

import Banner from 'components/Banner';
import LinkButton from 'components/LinkButton';

import { block } from './utils';

interface SwitchEditorModalBannerProps {
  onSwitchMode: () => void;
}

const SwitchEditorModalBanner: React.FunctionComponent<SwitchEditorModalBannerProps> = props => {
  const { onSwitchMode } = props;

  const handleSwitchMode = React.useCallback((): void => {
    onSwitchMode();
  }, [onSwitchMode]);

  return (
    <div className={block('banner-container')}>
      <Banner
        className={block('banner')}
        icon={
          <Sparkle className={block('banner-icon')} height={19} width={19} />
        }
        size="small"
        theme="rainbow"
      >
        <Banner.Content className={block('banner-content')}>
          Try our{' '}
          <LinkButton onClick={handleSwitchMode} theme="cta">
            new text editor
          </LinkButton>
          !
        </Banner.Content>
      </Banner>
    </div>
  );
};

export default SwitchEditorModalBanner;
