import classNames from 'classnames';
import * as React from 'react';
import KeyHandler, { KEYDOWN } from 'react-key-handler';
import { RootCloseWrapper } from 'react-overlays';
import _ from 'underscore';

import { dataStateFactory, IProps, IState } from './types';

/**
 * A component which toggles between input mode and display mode.
 *
 * By default, user actions in input mode are defined as follows:
 *  - pressing Enter is a submit action
 *  - pressing Esc is a cancel action
 *  - clicking outside of the input area is a submit action
 *
 * To change the sematics of the clicks and keypresses defined above, you can override the following
 * callbacks:
 *  - onEnterPressed
 *  - onEscPressed
 *  - onClickOut
 */
export default class InputToggler extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    inputMode: false,
    onCancel: _.noop,
    onChange: _.noop,
    onOpen: _.noop,
    onSubmit: _.noop,
    onTabPressed: _.noop,
  };

  constructor(props: IProps) {
    super(props);

    const { value } = props;

    this.state = {
      data: dataStateFactory({ value }),
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>): void {
    const {
      inputMode: nextInputMode,
      onChange: nextOnChange,
      value: nextValue,
    } = nextProps;
    const { inputMode, value } = this.props;

    if ((!inputMode && nextInputMode) || nextValue !== value) {
      this.setState(({ data }) => ({
        data: data.set('value', nextValue),
      }));
      nextOnChange(nextValue);
    }
  }

  private handleClickOut = (e: React.MouseEvent<HTMLDivElement>): void => {
    const { onClickOut } = this.props;
    const { data } = this.state;

    if (_.isFunction(onClickOut)) {
      onClickOut(data.value, e);
    } else {
      this.handleSubmit(e);
    }
  };

  private handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    const { onChange } = this.props;
    const { value } = e.target;

    this.setState(({ data }) => ({
      data: data.set('value', value),
    }));

    onChange(value);
  };

  private handleInputKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    const { onCancel, onEnterPressed, onEscPressed, onTabPressed } = this.props;
    const { data } = this.state;

    if (e.key === 'Escape') {
      _.isFunction(onEscPressed) ? onEscPressed(e) : onCancel(e);
    } else if (e.key === 'Enter') {
      _.isFunction(onEnterPressed)
        ? onEnterPressed(data.get('value'), e)
        : this.handleSubmit(e);
    } else if (e.key === 'Tab' && _.isFunction(onTabPressed)) {
      e.preventDefault();
      _.isFunction(onTabPressed) && onTabPressed(e);
    }
  };

  private handleSubmit = (e: React.SyntheticEvent<HTMLElement>): void => {
    const { onSubmit } = this.props;
    const { data } = this.state;
    onSubmit(data.get('value'), e);
  };

  private handleEscPressed = (e: React.KeyboardEvent<HTMLElement>): void => {
    const { onCancel, onEscPressed } = this.props;

    if (_.isFunction(onEscPressed)) {
      onEscPressed(e);
    } else {
      onCancel(e);
    }
  };

  private swallowClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  private renderInput(): React.ReactNode {
    const { formClassName, renderInput } = this.props;
    const { data } = this.state;

    const className = classNames('input-toggler__form', formClassName);

    return (
      <RootCloseWrapper onRootClose={this.handleClickOut}>
        <form className={className}>
          {renderInput(
            data.get('value'),
            this.handleInputChange,
            this.handleInputKeyDown,
          )}
          <input
            className="input-toggler__submit"
            type="submit"
            onClick={this.handleSubmit}
          />
        </form>
      </RootCloseWrapper>
    );
  }

  private renderValue(): React.ReactNode {
    const { renderValue } = this.props;
    const { data } = this.state;

    if (_.isFunction(renderValue)) {
      return renderValue(data.value);
    }

    return data.value;
  }

  private renderKeyHandler(): React.ReactNode {
    return (
      <KeyHandler
        keyEventName={KEYDOWN}
        keyValue="Escape"
        onKeyHandle={this.handleEscPressed}
      />
    );
  }

  public render() {
    const { activeClassName, className, inputMode, onOpen } = this.props;

    const containerClassName = classNames(
      'input-toggler',
      'input-toggler--default',
      className,
      {
        [activeClassName]: inputMode,
      },
    );

    return (
      <div
        className={containerClassName}
        onClick={inputMode ? this.swallowClick : onOpen}
      >
        {inputMode ? this.renderInput() : this.renderValue()}
        {inputMode && this.renderKeyHandler()}
      </div>
    );
  }
}
