import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  defaultWaveTypeOverrideSelector,
  defaultWaveTypeReadOnlySelector,
} from 'redux/modules/display-pref';
import { addingToTrack, createTrack } from 'redux/modules/embed/actions/tracks';
import {
  embedDurationMillisSelector,
  hasSoundwaveSelector,
  isEditorReadySelector,
  tracksByIdSelector,
  tracksSelector,
} from 'redux/modules/embed/selectors';
import { pushModal } from 'redux/modules/modal';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import { DEFAULT_TRACK_ORDER } from 'utils/embed/tracks';
import Timeline, { TimelineProps as Props } from './Timeline';

type StateProps = Pick<
  Props,
  'enableTrackAnimations' | 'durationMillis' | 'tracks'
>;
type DispatchProps = Pick<
  Props,
  'onAddTrack' | 'onAddTrackClick' | 'onDeleteTrackClick' | 'onTrackEntered'
>;

function canDeleteTrack(
  track,
  trackIdsByType,
  defaultWaveType,
  defaultWaveTypeLocked,
) {
  if (
    track.get('type') === 'waveform' &&
    defaultWaveType !== 'none' &&
    defaultWaveTypeLocked
  ) {
    return false;
  }

  const nTracksOfType = trackIdsByType[track.get('type')].length;
  const nMinTracksOfType = DEFAULT_TRACK_ORDER.filter(
    type => type === track.get('type'),
  ).length;
  return nTracksOfType > nMinTracksOfType;
}

const orderedTrackIdsByTypeSelector = createSelector(
  [tracksSelector, tracksByIdSelector],
  (tracks, tracksById) =>
    tracks.reduce(
      (res, id) => {
        const trackType = tracksById.getIn([id, 'type']);
        if (trackType === 'text') {
          res.text.push(id);
        } else if (trackType === 'media') {
          res.media.push(id);
        } else if (trackType === 'audio') {
          res.audio.push(id);
        } else if (trackType === 'waveform') {
          res.waveform.push(id);
        }
        return res;
      },
      { text: [], media: [], audio: [], waveform: [] },
    ),
);

const tracksConfigSelector = createSelector(
  [
    tracksSelector,
    tracksByIdSelector,
    orderedTrackIdsByTypeSelector,
    defaultWaveTypeOverrideSelector,
    defaultWaveTypeReadOnlySelector,
  ],
  (
    tracks,
    tracksById,
    orderedTrackIdsByType,
    defaultWaveType,
    waveTypeLocked,
  ) =>
    tracks
      ?.map(trackId => {
        const track = tracksById.get(trackId);
        return {
          id: trackId,
          label: track.get('name'),
          permanent: !canDeleteTrack(
            track,
            orderedTrackIdsByType,
            defaultWaveType,
            waveTypeLocked,
          ),
          styleButtonDisabled: track.get('data').size === 0,
          type: track.get('type'),
        };
      })
      .toArray(),
);

const mapStateToProps = (state: State): StateProps => ({
  enableTrackAnimations: isEditorReadySelector(state) || false,
  durationMillis: embedDurationMillisSelector(state),
  tracks: tracksConfigSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onAddTrack: type => {
    dispatch(createTrack(type, { layerOrderType: 'free' }));
  },
  onAddTrackClick: () => dispatch(pushModal({ name: 'AddTrackModal' })),
  onDeleteTrackClick: trackId =>
    dispatch(pushModal({ name: 'DeleteTrackModal', params: { trackId } })),
  onTrackEntered: track =>
    dispatch((_, getState) => {
      if (track.type === 'waveform' && !hasSoundwaveSelector(getState())) {
        dispatch(addingToTrack(track.id));
        dispatch(pushModal({ name: 'AddWaveformModal' }));
      }
    }),
});

const component = connect(mapStateToProps, mapDispatchToProps)(Timeline);

export default component;
export type TimelineProps = PropsOf<typeof component>;
