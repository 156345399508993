import dayjs from 'dayjs';
import _ from 'underscore';

import { getValue } from '../../../utils/collections';
import { PROVIDER_LIST } from '../../../utils/constants';
import { actions as thirdPartyAuthServiceActions } from '../../middleware/api/third-party-authentication-service';
import { delayActionBuilder } from '../../middleware/delay';
import * as types from './types';

function createTokenRenewalScheduleId(provider) {
  return `renewtoken_${provider}`;
}

export const getThirdPartyTokens = (
  providerList = PROVIDER_LIST,
) => dispatch => {
  dispatch({
    type: types.OAUTH_TOKEN_GET_REQUEST,
    payload: { provider: providerList },
  });

  dispatch(thirdPartyAuthServiceActions.fetchThirdPartyTokens(providerList))
    .then(res => {
      const tokenList = getValue(res, ['response', 'providers']);
      tokenList.forEach(provider => {
        const providerName = provider.provider;
        const tokenInfo = provider.accessTokenInfo;
        const expiresAt = tokenInfo && tokenInfo.expiresAt;
        const expiresAtSec = parseInt(expiresAt, 10);

        if (!_.isFinite(expiresAtSec)) {
          dispatch({ type: types.OAUTH_TOKEN_GET_SUCCESS });
          return;
        }

        const expiresAtMoment = dayjs.utc(expiresAtSec * 1000);
        const nowMoment = dayjs.utc();
        if (expiresAtMoment.isAfter(nowMoment)) {
          const diffMillis = expiresAtMoment.diff(nowMoment);
          const renewInMillis = diffMillis * 0.75;
          const action = delayActionBuilder(getThirdPartyTokens([providerName]))
            .id(createTokenRenewalScheduleId(providerName))
            .timeoutMillis(renewInMillis)
            .build();
          dispatch(action);
        }

        dispatch({
          type: types.OAUTH_TOKEN_GET_SUCCESS,
          payload: {
            provider: providerName,
            token: tokenInfo.accessToken,
            expiresAtMillis: expiresAt * 1000,
          },
        });
      });
    })
    .catch(err => {
      dispatch({
        ...err,
        type: types.OAUTH_TOKEN_GET_FAILURE,
      });
      throw err;
    });
};

export const cancelThirdPartyTokenRenewals = () => dispatch => {
  PROVIDER_LIST.forEach(provider => {
    const action = delayActionBuilder()
      .id(createTokenRenewalScheduleId(provider))
      .cancel()
      .build();
    dispatch(action);
  });
};

export const clearThirdPartyAuth = () => dispatch => {
  dispatch(cancelThirdPartyTokenRenewals());
  dispatch({ type: types.OAUTH_STATE_CLEAR });
};
