import cn from 'classnames';
import * as React from 'react';

import { block } from './utils';

interface IProps {
  children?: React.ReactNode;
  disabled?: boolean;
}

const ToolbarTool: React.SFC<IProps> = ({ children, disabled }) => {
  const child = React.Children.only(children) as React.ReactElement;
  return React.cloneElement(child, {
    className: cn(block('tool', { disabled }), child.props.className),
  });
};

ToolbarTool.defaultProps = {
  disabled: false,
};

export default ToolbarTool;
