import { Record, RecordOf } from 'immutable';
import { partial } from 'underscore';
import { omitUndefined } from 'utils/collections';
import { withValue } from 'utils/control';
import { reviveRecord } from 'utils/immutable';

import { stateFactory as entireAudioFactory } from '../../factories/entire-audio';
import { AudioClipTimes, IState } from './types';

export const stateFactory = Record<IState>({
  audioClip: undefined,
  entireAudio: undefined,
  isLoading: true,
  originalAudioDurationMillis: undefined,
  traceId: undefined,
  widgetId: undefined,
  episodeId: undefined,
  projectId: undefined,
  wizardLoadError: undefined,
});

export const audioClipFactory = Record<AudioClipTimes>({
  endMillis: undefined,
  startMillis: undefined,
});

export function stateFromJs({
  audioClip,
  entireAudio,
  ...rest
}: any = {}): RecordOf<IState> {
  const clip = withValue(audioClip, partial(reviveRecord, audioClipFactory));
  const audio = withValue(
    entireAudio,
    partial(reviveRecord, entireAudioFactory),
  );

  return reviveRecord(
    stateFactory,
    omitUndefined({
      audioClip: clip,
      entireAudio: audio,
      ...rest,
    }),
  );
}
