import * as React from 'react';
import PlanDecisionModal from '../PlanDecisionModal';
import LegacyDowngradeModalContents from './LegacyDowngradeModalContents';

export interface LegacyDowngradeModalProps {}

const LegacyDowngradeModal: React.FC<LegacyDowngradeModalProps> = () => (
  <PlanDecisionModal name="LegacyDowngradeModal" title="your plan">
    <LegacyDowngradeModalContents />
  </PlanDecisionModal>
);

export default LegacyDowngradeModal;
