import cn from 'classnames';
import React from 'react';
import { H3 } from 'components/Heading';

import { block } from './utils';

export interface CreateTabCardHeaderProps {
  children?: React.ReactNode;
  icon: React.ReactElement;
  iconClassName?: string;
  subtitle: string;
  title: string;
}

const CreateTabCardHeader: React.FC<CreateTabCardHeaderProps> = ({
  children,
  icon,
  iconClassName,
  subtitle,
  title,
}) => (
  <div className={block('header')}>
    <div className={cn(block('icon'), iconClassName)}>{icon}</div>
    <div>
      <H3 className={block('title')}>{title}</H3>
      <p className={block('subtitle')}>{subtitle}</p>
    </div>
    {children}
  </div>
);

export default CreateTabCardHeader;
