import cn from 'classnames';
import * as React from 'react';
import LinkButton from 'components/LinkButton';
import Avatar from './Avatar';
import Handle from './Handle';
import { block } from './utils';

export interface SocialAccountInfoProps {
  avatarSrc?: string;
  onSwitchAccountClick?: () => void;
  username: string;
  className?: string;
}

const SocialAccountInfo: React.FC<SocialAccountInfoProps> = ({
  avatarSrc,
  onSwitchAccountClick,
  username,
  className = '',
}) => (
  <div className={cn(block('account'), className)}>
    <Avatar src={avatarSrc} />
    <Handle className={block('username')} username={username} />(
    <LinkButton
      className={block('switch-account')}
      onClick={onSwitchAccountClick}
    >
      switch account
    </LinkButton>
    )
  </div>
);

export default SocialAccountInfo;
