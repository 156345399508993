import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DialogContent from 'components/DialogContent';
import { EmojiSad } from 'components/icons';
import { onShowFreeTrialModal } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';

export interface PromoUnavailableModalContentsProps {
  onHide?: () => void;
}

const PromoUnavailableModalContents: React.FC<PromoUnavailableModalContentsProps> = ({
  onHide,
}) => {
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch(onShowFreeTrialModal('PromoUnavailable'));
  }, [dispatch]);

  return (
    <DialogContent
      actions={[
        {
          children: 'got it',
          key: 'submit',
          onClick: onHide,
          theme: 'submit',
        },
      ]}
      details="The promotion that you tried to activate is not available for your account, but please contact support if you believe this is an error."
      icon={<EmojiSad />}
      title="Sorry, this promo is unavailable"
    />
  );
};

export default PromoUnavailableModalContents;
