import * as React from 'react';
import ToggleField, { ToggleFieldProps } from 'components/Form/ToggleField';
import { useEditorTranscriptionOptionsContext } from './EditorTranscriptionOptionsContext';

export type TranscriptionToggleProps = Pick<
  ToggleFieldProps,
  'className' | 'horizontal' | 'label' | 'labelClassName'
>;

const TranscriptionToggle: React.FC<TranscriptionToggleProps> = ({
  className,
  horizontal = {
    control: { xs: 4 },
    label: { xs: 8 },
  },
  label = 'Transcription',
  labelClassName,
}) => {
  const {
    canTranscribe,
    disabled,
    onToggleTranscription,
    transcriptionEnabled,
  } = useEditorTranscriptionOptionsContext();

  return (
    <ToggleField
      checked={transcriptionEnabled}
      disabled={disabled || !canTranscribe}
      onChange={onToggleTranscription}
      {...{ className, horizontal, label, labelClassName }}
    />
  );
};

export default TranscriptionToggle;
