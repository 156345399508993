import cn from 'classnames';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import Button, { ButtonProps } from 'components/Button';
import { block } from './utils';

const ButtonAsAny = Button as any;

type PickedLinkProps = Pick<LinkProps, 'to'>;
type PickedButtonProps = Pick<ButtonProps, 'theme' | 'children'>;

export interface ExportSuccessButtonProps
  extends PickedLinkProps,
    PickedButtonProps {
  className?: string;
}

const ExportSuccessButton: React.FC<ExportSuccessButtonProps> = ({
  children,
  className,
  theme = 'submit',
  to,
}) => (
  <ButtonAsAny
    className={cn(block('button'), className)}
    componentClass={Link}
    fluid
    {...{ theme, to }}
  >
    {children}
  </ButtonAsAny>
);

export default ExportSuccessButton;
