import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import ModalFooterButton from 'components/Modal/ModalFooterButton';
import { Step } from 'components/SteppedModal';
import usePrevious from 'hooks/usePrevious';
import { isPollingSocialPostCaptionsSelector } from 'redux/modules/download';
import { twitterUserDataSelector } from 'redux/modules/social';
import { PostStatus } from 'redux/modules/social/constants';
import TwitterPostComposer from '../TwitterPostComposer';
import useTwitterPostModalSelector from '../useTwitterPostModalSelector';

export interface UseComposerStepValue {
  twitterUserId: string;
  description: string;
}

export interface UseComposerStepConfig extends Pick<Step, 'submitButtonLabel'> {
  description: string;
  defaultDescription?: string;
  setDescription: (newDescription: string) => void;
  resetDescription: () => void;
  onPostSuccess?: () => void;
  onSubmit?: (value: UseComposerStepValue) => void;
  onSwitchAccountClick?: () => void;
}

export default function useComposerStep(config: UseComposerStepConfig): Step {
  const {
    description,
    defaultDescription,
    setDescription,
    resetDescription,
    onSwitchAccountClick,
    onPostSuccess,
    onSubmit,
    submitButtonLabel = 'tweet',
  } = config;
  const { avatarSrc, postStatus, username } = useTwitterPostModalSelector();
  const isPollingSocialPostCaptions = useSelector(
    isPollingSocialPostCaptionsSelector,
  );
  const { providerUserId: twitterUserId } = useSelector(
    twitterUserDataSelector,
  );
  const prevPostStatus = usePrevious(postStatus);
  const isTweeting = postStatus === PostStatus.IN_PROGRESS;

  const handleSubmit = useCallback(() => {
    onSubmit?.({
      twitterUserId,
      description,
    });
  }, [description, onSubmit, twitterUserId]);

  useEffect(() => {
    if (
      prevPostStatus === PostStatus.IN_PROGRESS &&
      postStatus === PostStatus.SUCCESS
    ) {
      resetDescription();

      onPostSuccess?.();
    }
  }, [
    defaultDescription,
    onPostSuccess,
    postStatus,
    prevPostStatus,
    resetDescription,
  ]);

  return {
    component: (
      <TwitterPostComposer
        onDescriptionChange={setDescription}
        {...{
          alert,
          avatarSrc,
          description,
          isTweeting,
          username,
          onSwitchAccountClick,
        }}
      />
    ),
    id: 'composer',
    onSubmit: handleSubmit,
    fluidFooterButtons: true,
    renderFooterButtons: ({ submit }) => [
      <ModalFooterButton
        {...submit}
        key="submit"
        theme="submit"
        disabled={isTweeting || isPollingSocialPostCaptions}
      >
        {submitButtonLabel}
      </ModalFooterButton>,
    ],
  };
}
