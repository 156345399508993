import React from 'react';

import { IconProps } from './types';

const YoutubeRounded: React.FunctionComponent<IconProps> = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <filter
        x="-25%"
        y="-21.9%"
        width="150%"
        height="150%"
        filterUnits="objectBoundingBox"
        id="73d1uyvx1a"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="2.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <circle id="gfabkpy9ob" cx="16" cy="16" r="16" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="rotate(180 16 16)">
        <use fill="#000" filter="url(#73d1uyvx1a)" xlinkHref="#gfabkpy9ob" />
        <circle
          stroke="#DCE1EB"
          strokeLinejoin="inherit"
          fill="#FFF"
          cx="16"
          cy="16"
          r="15.5"
        />
      </g>
      <path fill="#FFF" d="M10.4 11.714h10.4v7.714H10.4z" />
      <path
        d="M23.666 11.878c-.184-.74-.727-1.322-1.415-1.52C21.003 10 16 10 16 10s-5.003 0-6.251.359c-.688.197-1.23.78-1.415 1.519C8 13.218 8 16.012 8 16.012s0 2.795.334 4.135c.184.739.727 1.297 1.415 1.494C10.997 22 16 22 16 22s5.003 0 6.251-.359a2.049 2.049 0 0 0 1.415-1.494c.334-1.34.334-4.135.334-4.135s0-2.795-.334-4.134zm-9.302 6.672v-5.075l4.181 2.537-4.181 2.538z"
        fill="red"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default YoutubeRounded;
