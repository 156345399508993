import cn from 'classnames';
import * as React from 'react';

import IconButton, { IconButtonProps } from 'components/IconButton';
import Tooltip, { TooltipProps } from 'components/Tooltip';
import { cloneOnlyChild } from 'utils/react';
import { block } from '../../utils';

export interface TrackLabelButtonProps {
  children?: React.ReactElement;
  disabled?: boolean;
  id: string;
  onClick?: IconButtonProps['onButtonClick'];
  params?: any;
  tooltip?: TooltipProps['content'];
}

const TrackLabelButton: React.SFC<TrackLabelButtonProps> = ({
  children,
  disabled,
  id,
  onClick,
  params,
  tooltip,
}) => (
  <IconButton
    className={block('track-label-button')}
    disabled={disabled}
    onButtonClick={onClick}
    params={params}
  >
    {cloneOnlyChild(children, child => ({
      className: cn(
        block('track-label-icon', { disabled }),
        child.props.className,
      ),
    }))}
    {tooltip && (
      <Tooltip id={id} content={tooltip} placement="top">
        <div className={block('track-button-overlay')} />
      </Tooltip>
    )}
  </IconButton>
);

export default TrackLabelButton;
