import React from 'react';
import { Blur } from 'components/icons';
import RangeInput from 'components/RangeInput';
import { ViewportWidth } from 'utils/measurement';
import useImageBlur from '../useImageBlur';

function ImageBlurInput() {
  const { blurRadius, disabled, onBlurChange } = useImageBlur();

  const handleChange = (value: number) => {
    onBlurChange(new ViewportWidth(value));
  };

  return (
    <RangeInput
      disabled={disabled}
      disabledTooltipText="Only available for still images"
      min={0}
      max={10}
      step={0.1}
      icon={<Blur />}
      label="Blur"
      value={blurRadius?.value}
      onChange={handleChange}
    />
  );
}

export default ImageBlurInput;
