import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isNaN } from 'underscore';
import { VideoTemplateEditorFeatures } from 'components/VideoTemplateEditor/types';
import { Step } from 'components/Wizard';
import useOnChange from 'hooks/useOnChange';
import usePodcastData from 'hooks/usePodcastData';
import { PodcastWorkflowTemplate } from 'redux/middleware/api/podcast-service/types';
import { createDefaultProjectNameSelector } from 'redux/modules/project/selectors';
import { selectTemplate } from 'redux/modules/template-wizard/actions';
import { templateSelector } from 'redux/modules/template-wizard/selectors';
import { PodcastIdentifier, TemplateType, VideoTypes } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import CustomizeStep from '../CustomizeStep';
import { CustomizeStepView } from '../CustomizeStep/CustomizeStep';
import CustomizeStepCta, {
  CustomizeStepCtaProps,
} from '../CustomizeStep/CustomizeStepCta';
import { TemplateWizardStep } from './types';
import { block } from './utils';

export interface UseCustomizeStepConfig {
  aspectRatio: number;
  durationMillis: number;
  onSubmit?: CustomizeStepCtaProps['onClick'];
  onTemplatesClick?: () => void;
  podcastIdentifier?: PodcastIdentifier;
  source?: File | string;
  transcriptionEnabled: boolean;
}

interface UseCustomizeStepResult {
  step: Step<TemplateWizardStep.CUSTOMIZE>;
}

function getEditorFeatures(
  template: PodcastWorkflowTemplate,
): VideoTemplateEditorFeatures {
  return !template
    ? undefined
    : {
        progress:
          template?.compatibleVideoTypes.includes(VideoTypes.SHORT_CLIP) ||
          'hidden',
      };
}

export default function useCustomizeStep({
  aspectRatio,
  durationMillis,
  onSubmit,
  onTemplatesClick,
  podcastIdentifier,
  source,
  transcriptionEnabled,
}: UseCustomizeStepConfig): UseCustomizeStepResult {
  const dispatch = useDispatch();
  const template = useSelector(templateSelector);
  const features = getEditorFeatures(template);
  const [view, setView] = useState<CustomizeStepView>('edit');
  const [
    templateStepAspectRatioName,
    setTemplateStepAspectRatioName,
  ] = useState(getAspectRatioName(aspectRatio));
  const { episodeId, podcastId } = podcastIdentifier ?? {};

  useOnChange(aspectRatio, {
    isEqual(newValue, oldValue) {
      return (isNaN(newValue) && isNaN(oldValue)) || newValue === oldValue;
    },
    onChange(value) {
      const name = getAspectRatioName(value);
      if (name !== templateStepAspectRatioName) {
        setTemplateStepAspectRatioName(name);
      }
    },
  });

  const defaultProjectName = useSelector(
    useMemo(
      () =>
        createDefaultProjectNameSelector({
          source,
          episodeId,
          podcastId,
        }),
      [episodeId, podcastId, source],
    ),
  );

  const handleTemplateSelect = useCallback(
    templateId => {
      dispatch(selectTemplate(templateId));
    },
    [dispatch],
  );

  return {
    step: {
      completedName: 'Completed',
      component: (
        <CustomizeStep
          className={block('customize-step')}
          compatibilityTypes={[VideoTypes.FULL_EPISODE, VideoTypes.SHORT_CLIP]}
          defaults={{
            aspectRatio,
            keyAssets: usePodcastData(podcastIdentifier),
          }}
          podcastId={podcastId}
          episodeId={episodeId}
          features={features}
          key={template?.templateId}
          onChangeView={setView}
          onTemplatesClick={onTemplatesClick}
          onSelectAspectRatio={setTemplateStepAspectRatioName}
          onSelectTemplate={handleTemplateSelect}
          renderCta={({ className, disabled, exportEditorState, state }) => (
            <CustomizeStepCta
              onClick={onSubmit}
              {...{
                className,
                disabled,
                durationMillis,
                exportEditorState,
                state,
                transcriptionEnabled,
                defaultProjectName,
              }}
            />
          )}
          templateId={template?.templateId}
          templateStepAspectRatioName={templateStepAspectRatioName}
          templateTypes={[
            TemplateType.HEADLINER_DEFAULT,
            TemplateType.USER_GENERATED,
          ]}
          view={view}
        />
      ),
      name: 'Customize',
      renderCancelButton: () => null,
      renderNextButton: () => null,
      stepId: TemplateWizardStep.CUSTOMIZE,
      title: view === 'edit' ? undefined : 'Chooose a template',
    },
  };
}
