/**
 * It takes an array of elements, removes the element at the current index, and
 * inserts it at the destination index.
 *
 * @param elements - The array of elements that you want to change the position of.
 * @param {number} currentIndex - The index of the element you want to move.
 * @param {number} destinationIndex - The index you want to move the element to.
 *
 * @returns The updated array.
 */
export function changeElementPositionInArray<T>(
  elements: Array<T>,
  currentIndex: number,
  destinationIndex: number,
): Array<T> {
  const element = elements[currentIndex];

  elements.splice(currentIndex, 1);
  elements.splice(destinationIndex, 0, element);

  return elements;
}
