import cn from 'classnames';
import React from 'react';
import { BetaIcon } from 'components/icons';
import { tabsBlock as block } from '../../utils';

export interface ProjectsTabTitleProps {
  icon: JSX.Element;
  label: string;
  beta?: boolean;
}

const ProjectsTabTitle: React.FC<ProjectsTabTitleProps> = ({
  icon,
  label,
  beta,
}) => {
  return (
    <>
      {React.cloneElement(icon, {
        className: cn(block('title-icon'), icon.props.className),
      })}
      <span className={block('title-label')}>
        {label}
        {beta && <BetaIcon className={block('beta-icon')} />}
      </span>
    </>
  );
};
export default ProjectsTabTitle;
