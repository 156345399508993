import * as Immutable from 'immutable';
import { createSelector } from 'reselect';
import _ from 'underscore';

import { getValue, pick } from 'utils/collections';
import { ProjectTypeByAssets } from 'utils/constants';
import { createAudioClips } from 'utils/embed/audio-clips';
import { getCanvasColorFromConfig } from 'utils/embed/media-container';
import { getProgressAnimationFromConfig } from 'utils/embed/progress';
import { hasSoundwave } from 'utils/embed/soundwave';
import { getAudioTrackType } from 'utils/embed/tracks';
import measurement from 'utils/measurement';
import { getTemplates, VIEWPORTS } from 'utils/text-templates';
import { getPosition, getSize } from 'utils/viewport';
import { getAspectRatioName } from '../../../utils/aspect-ratio';
import embedUtils from '../../../utils/embed';
import { selectedRecordingSelector } from '../../selectors';
import {
  keywordsSelector as keywordEntitiesSelector,
  manualTranscriptsSelector,
  recordingsSelector,
  transcriptsSelector,
  waveformsSelector,
} from '../entities/selectors';
import {
  projectAspectRatioDimensionsSelector,
  projectAspectRatioSelector,
} from '../project/selectors';
import { UploadType } from '../recording-upload/constants';
import { recordingUploadSelector } from '../recording-upload/selectors';
import AutosaveStatus from './constants/AutosaveStatus';
import EmbedPlayerStatus from './constants/EmbedPlayerStatus';
import { entireAudioEnhancer } from './enhancers';
import {
  flattenTranscript,
  formatSlideshowInfo,
  formatTextOverlayInfo,
  formatVideoClipsInfo,
} from './utils';

const EMPTY_MAP = new Immutable.Map();

const embedPlayerRawStatusSelector = state =>
  state.getIn(['embed', 'embedPlayerStatus']);

export const autosaveStatusSelector = state =>
  state.getIn(['embed', 'autosaveStatus']);
export const embedLoadingSelector = state =>
  state.getIn(['embed', 'isLoading']);
export const embedDurationMillisSelector = state =>
  state.getIn(['embed', 'durationMillis']);
export const addSlideStatusSelector = state =>
  state.getIn(['embed', 'addSlideStatus']);
export const editSlideImageStatusSelector = state =>
  state.getIn(['embed', 'editSlideImageStatus']);
export const slidesSelector = state => state.getIn(['embed', 'slidesById']);
export const embedSlideIdsSelector = state => state.getIn(['embed', 'slides']);
export const embedConfigSelector = state =>
  state.getIn(['embed', 'configuration']);
export const tracksSelector = state =>
  state.getIn(['embed', 'tracks'], Immutable.List());
export const tracksByIdSelector = state =>
  state.getIn(['embed', 'tracksById'], Immutable.Map());
export const addTrackItemStatusSelector = state =>
  state.getIn(['embed', 'addTrackItemStatus']);
export const selectedTextOverlaySelector = state =>
  state.getIn(['embed', 'selectedTextOverlay']);
export const selectedAudioSelector = state =>
  state.getIn(['embed', 'selectedAudio']);
export const selectedTrackElementSelector = state =>
  state.getIn(['embed', 'selectedTrackElement']);
export const replaceTrackElementSelector = state =>
  state.getIn(['embed', 'replaceTrackElement'], Immutable.Map());
export const selectAddingToTrack = state =>
  state.getIn(['embed', 'addingToTrack']);
export const selectIsAddingTextOverlay = state =>
  state.getIn(['embed', 'isAddingTextOverlay']);
export const isAddingVideoSelector = state =>
  state.getIn(['embed', 'isAddingVideo']);
export const videoExportProgressSelector = state =>
  state.getIn(['embed', 'exportingVideo']);
export const videoExportUrlSelector = state =>
  state.getIn(['embed', 'videoUrl']);
export const textOverlayByIdSelector = state =>
  state.getIn(['embed', 'textOverlayById'], Immutable.Map());
export const audioByIdSelector = state =>
  state.getIn(['embed', 'audioById'], Immutable.Map());
export const soundwaveSelector = state =>
  state.getIn(['embed', 'soundwave'], Immutable.Map());
export const captionsSelector = state =>
  state.getIn(['embed', 'captions'], Immutable.Map());
export const watermarkByIdSelector = state =>
  state.getIn(['embed', 'watermarkById'], Immutable.Map());
export const watermarkStatusSelector = state =>
  state.getIn(['embed', 'watermarkStatus']);
export const videoIdsSelector = state =>
  state.getIn(['embed', 'videos'], Immutable.List());
export const videosByIdSelector = state =>
  state.getIn(['embed', 'videosById'], Immutable.Map());
export const addingAudioSelector = state =>
  state.getIn(['embed', 'addingAudio'], false);
export const globalStyleTrackIdSelector = state =>
  state.getIn(['embed', 'editingGlobalStyleForTrack']);
export const captionUploadStatusSelector = state =>
  state.getIn(['embed', 'isCaptionUploading'], false);
export const isEditorReadySelector = state =>
  state.getIn(['embed', 'isEditorReady']);
export const canvasColorSelector = state =>
  state.getIn(['embed', 'canvasColor']);
export const progressAnimationSelector = state =>
  state.getIn(['embed', 'progressAnimation']);
export const timerSelector = state => state.getIn(['embed', 'timer']);
export const edgeVideosSelector = state => state.getIn(['embed', 'edgeVideos']);

export const configCanvasColorSelector = createSelector(
  embedConfigSelector,
  config => getCanvasColorFromConfig(config),
);

export const configProgressAnimationSelector = createSelector(
  embedConfigSelector,
  config => getProgressAnimationFromConfig(config),
);

export const isAddingSlideSelector = createSelector(
  addSlideStatusSelector,
  status => status === 'adding',
);

export const embedPlayerStatusSelector = createSelector(
  [embedPlayerRawStatusSelector],
  status => EmbedPlayerStatus.enumValueOf(status),
);

export const embedSavingSelector = createSelector(
  [autosaveStatusSelector],
  status => AutosaveStatus.enumValueOf(status) === AutosaveStatus.RUNNING,
);

export const textTracksSelector = createSelector(
  [tracksByIdSelector],
  tracksById =>
    tracksById && tracksById.filter(track => track.get('type') === 'text'),
);

export const textTracksIndexSelector = createSelector(
  [tracksSelector, textTracksSelector],
  (tracksIndex, textTracks) => {
    let tracksCount = 0;

    const textTracksIndex =
      tracksIndex && textTracks
        ? tracksIndex.reduce((acc, trackId) => {
            if (textTracks.get(trackId)) {
              tracksCount += 1;
              acc[trackId] = tracksCount;
            }
            return acc;
          }, {})
        : {};

    return Immutable.Map(textTracksIndex);
  },
);

// TODO zero index these tracks...
export const textTrackSelector = (state, index) => {
  const textTrackIndexes = textTracksIndexSelector(state);
  const tracksById = tracksByIdSelector(state);
  const trackId = textTrackIndexes.findKey(i => i === index);
  return tracksById.get(trackId);
};

export const textTrack2Selector = state => textTrackSelector(state, 2);

export const textTrack2IdSelector = createSelector(
  textTrack2Selector,
  textTrack2 => textTrack2.get('id'),
);

export const audioTracksSelector = createSelector(
  [tracksByIdSelector],
  tracksById =>
    tracksById && tracksById.filter(track => track.get('type') === 'audio'),
);

export const mediaTracksSelector = createSelector(
  [tracksByIdSelector],
  tracksById =>
    tracksById && tracksById.filter(track => track.get('type') === 'media'),
);

export const firstMediaTrackIdSelector = createSelector(
  mediaTracksSelector,
  tracks => tracks && tracks.first().get('id'),
);

export const waveformTrackSelector = createSelector(
  [tracksByIdSelector],
  tracksById =>
    tracksById && tracksById.find(track => track.get('type') === 'waveform'),
);

export const lastTextOverlayTemplateSelector = state =>
  state.getIn(['embed', 'lastTextOverlayTemplate']);

export const lastGlobalTextOverlayTemplateSelector = state => {
  const template = state.getIn(['embed', 'lastGlobalTextOverlayTemplate']);
  return template && template.toJS();
};

const lastUpdatedSlideIdSelector = createSelector(
  slidesSelector,
  slidesById => {
    if (!slidesById) return undefined;

    const lastSlide = slidesById.max(
      (s1, s2) => s1.get('updatedAt', 0) > s2.get('updatedAt', 0),
    );

    if (!lastSlide || !lastSlide.get('updatedAt')) return undefined;
    return lastSlide.get('id');
  },
);

export const lastUpdatedSlideTemplateSelector = createSelector(
  [lastUpdatedSlideIdSelector, slidesSelector],
  (lastSlideId, slidesById) => {
    if (_.isUndefined(lastSlideId) || !slidesById) return undefined;

    const keysToKeep = ['entryTransition', 'imageEffect'];
    const slide = slidesById.get(lastSlideId);
    return slide.filter((v, k) => keysToKeep.indexOf(k) >= 0);
  },
);

// make sure the "selected" overlay actually exists
export const mapStateToSelectedTextOverlay = createSelector(
  [selectedTextOverlaySelector, textOverlayByIdSelector],
  (textOverlayId, textOverlaysById) =>
    textOverlaysById && textOverlaysById.has(textOverlayId)
      ? textOverlayId
      : undefined,
);

export const mapStateToSelectedAudio = createSelector(
  [selectedAudioSelector, audioByIdSelector],
  (audioId, audioById) =>
    audioById && audioById.has(audioId) ? audioId : undefined,
);

export const mapStateToAddTrackItemStatus = createSelector(
  [addTrackItemStatusSelector],
  status => status,
);

// TODO delete? same as tracksByIdSelector...
export const mapStateToTracksById = createSelector(
  [tracksByIdSelector],
  tracks => tracks,
);

const embedConfigDimensionsSelector = createSelector(
  embedConfigSelector,
  config => {
    const dimensions = getValue(config, ['embedConfig', 'dimensions']);
    return Immutable.fromJS(dimensions);
  },
);

export const aspectRatioDimensionsSelector = createSelector(
  [projectAspectRatioDimensionsSelector, embedConfigDimensionsSelector],
  (projectDims, embedConfigDims) => projectDims || embedConfigDims,
);

export const embedAudioSelector = createSelector(
  [audioByIdSelector, recordingsSelector, tracksSelector, tracksByIdSelector],
  (audioById, recordingsById, trackIds, tracksById) => {
    if (!audioById || audioById.isEmpty()) {
      return undefined;
    }

    const audioTracks = tracksById.filter(t => t.get('type') === 'audio');

    return audioById
      .valueSeq()
      .map(a => {
        const recording =
          recordingsById && recordingsById.get(a.get('id').toString());
        const trackId = audioTracks.findKey(t =>
          t.get('data').includes(a.get('id')),
        );
        return !recording || !trackId
          ? undefined
          : a.withMutations(aa => {
              aa.set('src', recording.get('recordingUrl'));
              aa.set('published', recording.get('published'));
              aa.set(
                'audioTrackType',
                getAudioTrackType(trackId, trackIds, tracksById),
              );
            });
      })
      .filter(_.negate(_.isUndefined))
      .toArray();
  },
);

const isMainAudio = audio => {
  return audio?.get('audioTrackType') === 'main';
};

export const embedMainAudioSelector = createSelector(
  embedAudioSelector,
  embedAudio => embedAudio && embedAudio.find(isMainAudio),
);

export const mainAudioIdSelector = createSelector(
  embedMainAudioSelector,
  audio => audio && audio.get('id'),
);

export const embedBgAudioSelector = createSelector(
  embedAudioSelector,
  audio => {
    if (!audio) return undefined;
    return audio.filter(a => !isMainAudio(a));
  },
);

/**
 * @returns {number} the audio track's recording id if it can be used in the exported video,
 *  otherwise will return undefined to indicate that the audio needs to be rendered/uploaded for
 *  use in the final video.
 */
export const embedExportRecordingIdSelector = createSelector(
  [
    embedConfigSelector,
    embedMainAudioSelector,
    embedDurationMillisSelector,
    recordingsSelector,
  ],
  (config, audio, embedDurationMillis, recordingsById) => {
    const configRecordingId = getValue(config, ['embedConfig', 'recordingId']);
    const configAudio = getValue(config, ['embedConfig', 'audioInfo', 0]);

    // this project contains no audio
    if (!audio) return undefined;

    const durationMillis = audio.get('endMillis') - audio.get('startMillis');

    /*
     * uploaded audio can be used as the rendered audio
     * this means that the audio track has no silence at the beginning or end and has not been
     * trimmed from the beginning or end, so doesn't need to be rendered and uploaded
     */
    const hasSilence =
      audio.get('startMillis') !== 0 ||
      embedDurationMillis !== audio.get('endMillis');
    const trimmed =
      audio.get('playFromMillis', 0) !== 0 ||
      durationMillis !==
        recordingsById.getIn([audio.get('id').toString(), 'durationMillis']);

    if (!hasSilence && !trimmed) {
      return audio.get('id');
    }

    /**
     * audio was rendered, so configRecordingId is the rendered version of configAudio.recordingId.
     * if nothing has changed, we can reuse the rendered audio and don't need to re-render
     */
    if (
      !_.isUndefined(configRecordingId) &&
      audio.get('id') === configAudio.recordingId &&
      audio.get('startMillis') === configAudio.startAtMilli &&
      audio.get('playFromMillis') === configAudio.playOffsetMilli &&
      durationMillis === configAudio.durationMilli &&
      embedDurationMillis === config.embedConfig.expectedDurationMilli
    ) {
      return configRecordingId;
    }

    // audio needs to be rendered and uploaded
    return undefined;
  },
);

export const captionsConfigSelector = createSelector(
  captionsSelector,
  captions => captions.get('config', Immutable.Map()),
);

export const captionsOffsetMillisSelector = createSelector(
  captionsConfigSelector,
  config => config.get('offsetMillis', 0),
);

export const captionsPhraseStatusSelector = createSelector(
  captionsSelector,
  captions => captions.get('phraseStatus'),
);

export const captionsDeletedPhraseIdsSelector = createSelector(
  captionsSelector,
  captions => captions.get('deletedPhrases', Immutable.Set()),
);

export const captionsAddPhraseStatusSelector = createSelector(
  captionsSelector,
  captions => captions.get('addPhraseStatus'),
);

export const captionsStyleSelector = createSelector(
  captionsConfigSelector,
  config => config.get('style'),
);

export const captionsLockSelector = createSelector(captionsSelector, captions =>
  captions.get('lock', false),
);

export const captionsMediaSourceSelector = createSelector(
  captionsConfigSelector,
  config => {
    if (config) {
      const mediaSource = pick(config, 'mediaSourceId', 'mediaSourceType');

      if (
        mediaSource.get('mediaSourceId') !== undefined &&
        mediaSource.get('mediaSourceType') !== undefined
      ) {
        return mediaSource;
      }
    }

    return EMPTY_MAP;
  },
);

export const captionsSourceSelector = createSelector(
  captionsConfigSelector,
  config => config && pick(config, 'transcriptId', 'transcriptRevisionId'),
);

export const captionsAudioSourceSelector = createSelector(
  [captionsMediaSourceSelector, audioByIdSelector],
  (source, audio) => {
    if (!source || !audio || source.get('mediaSourceType') !== 'audio')
      return undefined;
    return audio.get(source.get('mediaSourceId'));
  },
);

export const captionsVideoSourceSelector = createSelector(
  [captionsMediaSourceSelector, videosByIdSelector],
  (source, videos) => {
    if (!source || !videos || source.get('mediaSourceType') !== 'video')
      return undefined;
    return videos.find(v => v.get('serverId') === source.get('mediaSourceId'));
  },
);

const layersSelector = createSelector(
  [tracksSelector, tracksByIdSelector],
  (tracks, tracksById) =>
    tracks.reduce((acc, id) => {
      acc.push(tracksById.getIn([id, 'type']));
      return acc;
    }, []),
);

// TODO break this up.  it's insane
export const mapStateToEmbedConfig = createSelector(
  [
    embedSlideIdsSelector,
    slidesSelector,
    textOverlayByIdSelector,
    audioByIdSelector,
    videosByIdSelector,
    embedDurationMillisSelector,
    tracksSelector,
    textTracksSelector,
    mediaTracksSelector,
    audioTracksSelector,
    waveformTrackSelector,
    soundwaveSelector,
    aspectRatioDimensionsSelector,
    watermarkByIdSelector,
    captionsConfigSelector,
    embedExportRecordingIdSelector,
    layersSelector,
    canvasColorSelector,
    progressAnimationSelector,
    timerSelector,
    edgeVideosSelector,
  ],
  (
    slideIds,
    slidesById = EMPTY_MAP,
    textOverlayById = EMPTY_MAP,
    audioById = EMPTY_MAP,
    videosById = EMPTY_MAP,
    duration,
    tracks,
    textTracks,
    mediaTracks,
    audioTracks,
    waveformTrack,
    soundwaveInfo,
    aspectRatio,
    watermarkById,
    captionsInfo,
    recordingId,
    layerOrder,
    canvasColor,
    progressAnimation,
    timerOptions,
    edgeVideos,
  ) => {
    const expectedDurationMilli = duration;

    const slideshowInfo = formatSlideshowInfo({
      mediaTracks,
      tracks,
      slidesById,
    });

    const videoClips = formatVideoClipsInfo({
      mediaTracks,
      tracks,
      videosById,
      aspectRatio: aspectRatio?.toJS(),
    });

    const textOverlayInfo = formatTextOverlayInfo({
      textTracks,
      tracks,
      textOverlayById,
    });

    const audioInfo = audioTracks.reduce((acc, track) => {
      const trackIndex = tracks.indexOf(track.get('id'));
      const trackData = track.get('data') || Immutable.List();
      const audioConfigs = trackData.reduce((audios, audioId) => {
        const audio = audioById.get(audioId);
        const audioConfig = embedUtils.formatAudioForConfig(audio, trackIndex);
        audios.push(audioConfig);
        return audios;
      }, []);
      return acc.concat(audioConfigs);
    }, []);

    const versionInfo = embedUtils.createVersionInfo(spareminConfig.version);

    const soundwave = embedUtils.formatSoundwaveForConfig(
      soundwaveInfo,
      tracks.indexOf(waveformTrack && waveformTrack.get('id')),
    );

    const dimensions = {
      height: aspectRatio?.get('height'),
      width: aspectRatio?.get('width'),
    };

    const watermark = watermarkById.reduce((acc, wmark) => {
      acc.push(embedUtils.formatWatermarkForConfig(wmark, duration));
      return acc;
    }, []);

    const captions = embedUtils.formatCaptionsForConfig(
      captionsInfo,
      aspectRatio,
    );

    const mainMediaContainer = embedUtils.formatMediaContainerForConfig(
      canvasColor,
    );

    const progress = embedUtils.formatProgressForConfig(progressAnimation);
    const timer = embedUtils.formatTimerForConfig(timerOptions);

    return {
      audioInfo,
      edgeVideos,
      expectedDurationMilli,
      slideshowInfo,
      textOverlayInfo,
      versionInfo,
      soundwave,
      dimensions,
      watermark,
      captions,
      recordingId,
      videoClips,
      layerOrder,
      mainMediaContainer,
      progress,
      timer,
    };
  },
);

export const embedWidgetIdSelector = createSelector(
  [embedConfigSelector],
  config => config && config.wid,
);

export const slideEndMillisSelector = createSelector(
  [slidesSelector],
  slidesById => slidesById && slidesById.map(s => s.get('endMilli')).max(),
);

export const textOverlayEndMillisSelector = createSelector(
  [textOverlayByIdSelector],
  overlayById =>
    overlayById && overlayById.map(o => o.getIn(['time', 'endMillis'])).max(),
);

export const audioEndMillisSelector = createSelector(
  [audioByIdSelector],
  audioById => audioById && audioById.map(a => a.get('endMillis')).max(),
);

export const videoEndMillisSelector = createSelector(
  videosByIdSelector,
  videoById => videoById && videoById.map(v => v.endMillis).max(),
);

export const soundwaveEndMillisSelector = createSelector(
  soundwaveSelector,
  soundwave => soundwave && soundwave.getIn(['time', 'endMillis']),
);

export const mainAudioVersionIdSelector = createSelector(
  [mainAudioIdSelector, recordingsSelector],
  (audioId, recordings) => {
    if (!recordings || _.isUndefined(audioId)) return undefined;
    return recordings.getIn([audioId.toString(), 'versionId']);
  },
);

export const embedAutoTranscriptSelector = createSelector(
  [mainAudioVersionIdSelector, transcriptsSelector],
  (audioVersionId, transcripts) => {
    if (_.isUndefined(audioVersionId) || !transcripts) return undefined;
    return transcripts.get(audioVersionId.toString());
  },
);

export const transcriptIdSelector = createSelector(
  [captionsConfigSelector],
  config => config && config.get('transcriptId'),
);

export const embedManualTranscriptSelector = createSelector(
  [transcriptIdSelector, manualTranscriptsSelector],
  (transcriptId, manualTranscripts) => {
    if (!transcriptId || !manualTranscripts) return undefined;
    return manualTranscripts.get(transcriptId);
  },
);

export const embedTranscriptSelector = createSelector(
  embedManualTranscriptSelector,
  transcript => {
    if (!transcript) return undefined;

    const flattenedTranscript = flattenTranscript(transcript);

    return transcript.set('transcript', flattenedTranscript);
  },
);

export const phrasesSelector = createSelector(
  embedTranscriptSelector,
  transcript => transcript && transcript.get('transcript'),
);

export const newProjectRecordingIdSelector = createSelector(
  [selectedRecordingSelector, recordingUploadSelector],
  (searchRecordingId, upload) => {
    if (!_.isUndefined(searchRecordingId)) {
      return searchRecordingId;
    }

    if (
      !_.isUndefined(upload.recordingId) &&
      upload.type === UploadType.PROJECT &&
      !upload.inProgress
    ) {
      return upload.recordingId;
    }

    return undefined;
  },
);

export const autoTranscriptIdSelector = createSelector(
  [captionsMediaSourceSelector, recordingsSelector],
  (mediaSource, recordings) => {
    if (!mediaSource) return undefined;

    const mediaSourceId = mediaSource.get('mediaSourceId');
    const mediaSourceType = mediaSource.get('mediaSourceType');

    if (!mediaSourceId || !mediaSourceType) return undefined;

    return mediaSourceType !== 'audio'
      ? mediaSourceId
      : recordings.getIn([mediaSourceId.toString(), 'versionId']);
  },
);

const keywordsIdSelector = createSelector(
  [
    captionsMediaSourceSelector,
    mainAudioIdSelector,
    recordingsSelector,
    autoTranscriptIdSelector,
  ],
  (mediaSource, recordingId, recordings, transcriptId) => {
    if (!mediaSource || mediaSource.isEmpty()) return undefined;

    const mediaSourceType = mediaSource.get('mediaSourceType');
    const mediaSourceId = mediaSource.get('mediaSourceId');
    if (
      !mediaSourceType ||
      mediaSourceType === 'video' ||
      (mediaSourceType === 'audio' && mediaSourceId !== recordingId)
    ) {
      return undefined;
    }

    return mediaSourceType === 'text'
      ? mediaSourceId
      : recordings.getIn([mediaSourceId.toString(), 'versionId']).toString();
  },
);

export const transcriptKeywordsSelector = createSelector(
  [keywordsIdSelector, keywordEntitiesSelector],
  (keywordsId, keywords) => {
    if (_.isUndefined(keywordsId) || !keywords) return undefined;
    return keywords.getIn([keywordsId, 'detail']);
  },
);

export const keywordsSelector = createSelector(
  transcriptKeywordsSelector,
  keywords => keywords && keywords.map(kw => kw.get('keyword')).toArray(),
);

export const embedConfigAspectRatioSelector = createSelector(
  embedConfigDimensionsSelector,
  dimensions =>
    dimensions && dimensions.get('width') / dimensions.get('height'),
);

export const aspectRatioSelector = createSelector(
  [projectAspectRatioSelector, embedConfigAspectRatioSelector],
  (projectRatio, embedConfigRatio) => projectRatio || embedConfigRatio,
);

export const aspectRatioNameSelector = createSelector(
  aspectRatioSelector,
  aspectRatio => getAspectRatioName(aspectRatio),
);

export const viewportSelector = createSelector(
  aspectRatioNameSelector,
  aspectRatioName => VIEWPORTS[aspectRatioName],
);

export const textoverlaySizeSelector = createSelector(
  aspectRatioNameSelector,
  aspectRatioName => getSize(aspectRatioName),
);

export const textoverlayPositionSelector = createSelector(
  aspectRatioNameSelector,
  aspectRatioName => getPosition(aspectRatioName),
);

export const watermarkConfigurationSelector = createSelector(
  embedConfigSelector,
  embedConfig => getValue(embedConfig, ['embedConfig', 'watermark', 0]),
);

/*
 * returns the "first" watermark in the watermarkById map.  this is probably non-deterministic, but
 * with the current watermark implementation, there should only ever be one in the map.  will need
 * to be revisited when watermark is more fleshed out
 */
export const watermarkSelector = createSelector(
  watermarkByIdSelector,
  watermarkById => watermarkById && watermarkById.first(),
);

export const audioClipsSelector = createSelector(
  [videosByIdSelector, videoIdsSelector, embedAudioSelector],
  (videosById, videoIds, audio) =>
    createAudioClips(audio, { order: videoIds, data: videosById }),
);

export const transcriptMediaOffsetMillisSelector = createSelector(
  [captionsAudioSourceSelector, captionsVideoSourceSelector],
  (audio, video) => {
    if (audio) {
      return audio.get('startMillis', 0);
    }

    if (video) {
      return video.get('startMillis', 0);
    }

    return 0;
  },
);

export const textTemplatesSelector = createSelector(
  aspectRatioNameSelector,
  ratioName => {
    if (!ratioName) return undefined;
    const templates = getTemplates(ratioName);
    return Object.keys(templates).map(templateName => {
      const { ui, ...template } = templates[templateName];
      return {
        id: templateName,
        displayName: ui.displayName,
        imageUrl: ui.imageUrl,
        template: Immutable.fromJS(template),
      };
    });
  },
);

export const transcriptOffsetMillisSelector = createSelector(
  [transcriptMediaOffsetMillisSelector, captionsOffsetMillisSelector],
  (mediaOffsetMillis = 0, captionsOffsetMillis = 0) =>
    mediaOffsetMillis - captionsOffsetMillis,
);

export const toReplaceMediaElementSelector = createSelector(
  replaceTrackElementSelector,
  slidesSelector,
  videosByIdSelector,
  (selectedTrackElement, slides = Immutable.Map(), videos) => {
    if (!selectedTrackElement) return undefined;

    const dataId = selectedTrackElement.get('dataId');

    const maybeSlide = slides.get(dataId);
    if (maybeSlide) {
      return maybeSlide.mapKeys(k => {
        if (k === 'startMilli') return 'startMillis';
        if (k === 'endMilli') return 'endMillis';
        return k;
      });
    }

    const maybeVideo = videos.get(dataId);
    if (maybeVideo) return maybeVideo;

    return undefined;
  },
);

export const selectedSlideIdSelector = createSelector(
  [selectedTrackElementSelector, tracksByIdSelector],
  (element, tracksById) => {
    if (!element || !tracksById) return undefined;

    const track = tracksById.get(element.get('trackId'));
    if (!track || track.get('type') !== 'media') return undefined;

    return element.get('dataId');
  },
);
export const projectTypeByAssets = createSelector(
  [audioByIdSelector, tracksByIdSelector],
  (audioById, tracksById) => {
    const audios = audioById ? audioById.toArray() : [];
    const tracks = tracksById ? tracksById.toArray() : [];

    const numberOfAssets = tracks.reduce(
      (assetsCnt, trackById) => assetsCnt + trackById.get('data').size,
      0,
    );

    if (numberOfAssets === 0) {
      return ProjectTypeByAssets.PROJECT_NO_ASSETS;
    }

    if (audios.length) {
      return ProjectTypeByAssets.PROJECT_WITH_AUDIO_ASSETS;
    }

    return undefined;
  },
);

export const replaceAudioStartMillisSelector = createSelector(
  [selectAddingToTrack, tracksByIdSelector, audioByIdSelector],
  (addToTrackId, tracksById, audioById) => {
    const replaceAudioId = tracksById
      .getIn([addToTrackId, 'data'], Immutable.List())
      .get(0);

    return audioById.getIn([replaceAudioId, 'startMillis'], 0);
  },
);

export const hasSoundwaveSelector = state => {
  const soundwave = soundwaveSelector(state);
  return hasSoundwave(soundwave);
};

const selectedAudioIdSelector = createSelector(
  [selectedTrackElementSelector, tracksByIdSelector],
  (element, tracksById) => {
    if (!element || !tracksById) return undefined;

    const track = tracksById.get(element.get('trackId'));
    if (track.get('type') !== 'audio') return undefined;

    return element.get('dataId');
  },
);

export const selectedAudioAssetSelector = createSelector(
  [
    selectedAudioIdSelector,
    audioByIdSelector,
    recordingsSelector,
    waveformsSelector,
  ],
  (audioId, audioById, recordingsById, waveformsByVersion) => {
    if (_.isUndefined(audioId) || !audioById) {
      return undefined;
    }

    const audio = audioById.get(audioId);
    const recording = recordingsById.get(audioId.toString());
    const waveform =
      recording &&
      waveformsByVersion.get(recording.get('versionId').toString());
    return (
      audio &&
      recording &&
      audio.withMutations(a =>
        a
          .set('src', recording.get('recordingUrl'))
          .set('waveformStatus', waveform && waveform.get('status')),
      )
    );
  },
);

export const hasImageFromOriginSelector = (origin, state) => {
  const slides = slidesSelector(state);
  return (
    slides?.find(
      slide => slide.getIn(['sourceImageOrigin', 'origin']) === origin,
    ) !== undefined
  );
};

export const blurRadiusSelector = state => {
  const slides = slidesSelector(state);
  const slideWithBlurRadius = slides?.find(slide => !!slide.get('blurRadius'));
  return measurement(slideWithBlurRadius?.get('blurRadius')) || undefined;
};

export const {
  entireAudioInstanceIdSelector,
  uploadProgressSelector,
} = entireAudioEnhancer.selectors;

export const recordingIdSelector = createSelector(
  [tracksByIdSelector],
  tracksById => {
    const tracks = tracksById.toJS();

    if (!tracks) {
      return null;
    }

    const audioTrack = Object.values(tracks).find(
      ({ name }) => name === 'audio',
    );

    return audioTrack?.data[0] ?? null;
  },
);

export const trackDurationInMilliSelector = createSelector(
  [embedConfigSelector, recordingIdSelector],
  (editorConfig, id) => {
    if (!editorConfig || !id) {
      return 0;
    }

    const trackById = editorConfig.embedConfig.audioInfo.find(
      ({ recordingId }) => id === recordingId,
    );

    return trackById?.durationMilli ?? 0;
  },
);

export const selectedOverlayIdSelector = createSelector(
  [selectedTrackElementSelector, tracksByIdSelector],
  (element, tracksById) => {
    if (!element || !tracksById) return undefined;

    const track = tracksById.get(element.get('trackId'));
    if (track.get('type') !== 'text') return undefined;

    return element.get('dataId');
  },
);

export const selectedOverlaySelector = createSelector(
  [selectedOverlayIdSelector, textOverlayByIdSelector],
  (textOverlayId, textOverlaysById) => {
    if (!textOverlayId || !textOverlaysById) return undefined;
    return textOverlaysById.get(textOverlayId);
  },
);
