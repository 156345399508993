import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createSelector } from 'reselect';
import Banner from 'components/Banner';
import Button from 'components/Button';
import { InfinityIcon } from 'components/icons';
import useCost from 'hooks/useCost';
import { Tier } from 'redux/middleware/api/plan-service';
import { onClickUpgradePlan } from 'redux/modules/mixpanel';
import { pushModal } from 'redux/modules/modal';
import {
  proYearlyCostCentsSelector,
  unlimitedYearlyCostCentsSelector,
} from 'redux/modules/pricing';
import bem from 'utils/bem';
import { capitalize } from 'utils/string';

export interface TierUpgradeBannerProps {
  source?: Parameters<typeof onClickUpgradePlan>[0];
  modal: 'UpgradePlan' | 'AddBillingCycle';
  targetTier: Tier.PRO | Tier.UNLIMITED;
}

const costCentsSelector = createSelector(
  (_, targetTier) => targetTier,
  unlimitedYearlyCostCentsSelector,
  proYearlyCostCentsSelector,
  (tier, unlimitedYearlyCostCents, proYearlyCostCents) => {
    const costCents = {
      pro: proYearlyCostCents,
      unlimited: unlimitedYearlyCostCents,
    }[tier];

    return costCents / 12;
  },
);

const block = bem('tier-upgrade-banner');

const TierUpgradeBanner: React.FC<TierUpgradeBannerProps> = ({
  source,
  modal,
  targetTier,
}) => {
  const dispatch = useDispatch();
  const yearlyCostCents = useSelector(state =>
    costCentsSelector(state, targetTier),
  );
  const cost = useCost(yearlyCostCents);

  const handleUpgradeClick = useCallback(() => {
    dispatch(onClickUpgradePlan(source, 'MyPlanPage'));
    dispatch(
      pushModal({
        name: modal,
        params: {
          tier: targetTier,
          defaultSubscriptionPeriod: 'yearly',
          loadingMessage: 'Updating Subscription',
        },
      }),
    );
  }, [dispatch, modal, source, targetTier]);

  return (
    <button className={block()} onClick={handleUpgradeClick} type="button">
      <Banner
        theme="rainbow"
        className={block('banner')}
        icon={<InfinityIcon width="45%" height="100%" />}
      >
        <div>
          <Banner.Title>Remove all watermarks</Banner.Title>
          <Banner.Content className={block('details')}>
            Completely remove watermarks with{' '}
            <strong>Headliner {capitalize(targetTier)}</strong> for just {cost}
            /mo (billed yearly)!
          </Banner.Content>
        </div>
        <Banner.Actions className={block('actions')}>
          <Button componentClass="div" theme="rainbow">
            Upgrade
          </Button>
        </Banner.Actions>
      </Banner>
    </button>
  );
};

export default TierUpgradeBanner;
