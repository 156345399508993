import { connect } from 'react-redux';

import {
  autoRenewalSelector,
  isLoadingSelector,
  subtierSelector,
} from 'redux/modules/pricing';
import { State } from 'redux/types';
import { PropsOf } from 'types';
import SubscriptionSettings, {
  SubscriptionSettingsProps as Props,
} from './SubscriptionSettings';

type StateProps = Pick<Props, 'isLoading' | 'pricingTier' | 'autoRenewal'>;

const mapStateToProps = (state: State): StateProps => ({
  isLoading: isLoadingSelector(state),
  pricingTier: subtierSelector(state),
  autoRenewal: autoRenewalSelector(state),
});

const component = connect(mapStateToProps)(SubscriptionSettings);
export type SubscriptionSettingsProps = PropsOf<typeof component>;
export default component;
