import { createSelector } from 'reselect';
import { State } from 'redux/types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { TextToImageState } from './types';

const stateSelector = (state: State): TextToImageState =>
  state.get('textToImage');

export const textToImageJobIdsSelector = createSelector(
  stateSelector,
  state => state.textToImageJobIds,
);

export const isPollingTextToImageSelector = createSelector(
  stateSelector,
  state => state.isPollingTextToImage,
);

export const isImagePollingTextToImageSelector = createSelector(
  isPollingTextToImageSelector,
  isPolling => isPolling.image,
);

export const isVideoPollingTextToImageSelector = createSelector(
  isPollingTextToImageSelector,
  isPolling => isPolling.video,
);

export const textToImagesSelector = createSelector(
  stateSelector,
  state => state.images,
);

export const imageTextToImagesSelector = createSelector(
  textToImagesSelector,
  state => state.image,
);

export const videoTextToImagesSelector = createSelector(
  textToImagesSelector,
  state => state.video,
);

export const imageHasTextToImagesSelector = createSelector(
  imageTextToImagesSelector,
  images => !!images.length,
);

export const videoHasTextToImagesSelector = createSelector(
  videoTextToImagesSelector,
  images => !!images.length,
);

export const imageTextToImageHasErrorSelector = createSelector(
  stateSelector,
  state => state.hasError.image,
);

export const videoTextToImageHasErrorSelector = createSelector(
  stateSelector,
  state => state.hasError.video,
);

export const textToImageAspectRatioNameSelector = createSelector(
  textToImagesSelector,
  images => getAspectRatioName(images[0].height, images[0].width),
);
