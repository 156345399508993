import { AspectRatio, TextToImage } from '@sparemin/blockhead';
import React from 'react';

import { Col } from 'components/Grid';
import ProgressOverlay from 'components/ProgressOverlay';
import { AspectRatioName } from 'types';
import { getAspectRatioValue } from 'utils/aspect-ratio';

import { block } from './utils';

interface PlaceholderCardProps {
  aspectRatio: AspectRatioName;
  colSpan: number;
}

const PlaceholderCard: React.FunctionComponent<PlaceholderCardProps> = props => {
  const { aspectRatio, colSpan } = props;

  return (
    <Col className={block('col')} xs={colSpan}>
      <div className={block('card')}>
        <AspectRatio ratio={getAspectRatioValue(aspectRatio)}>
          <ProgressOverlay in />
        </AspectRatio>
        <div className={block('card-inner')}>
          <TextToImage height={58} width={64} />
        </div>
      </div>
    </Col>
  );
};

export default PlaceholderCard;
