import * as React from 'react';

import { IconProps } from 'components/icons/types';

/* tslint:disable-next-line:variable-name */
const SupportCircleIcon: React.SFC<IconProps> = (props: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="22.5" cy="22.5" r="22.5" fill="#6E6EDE" />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M20.194 20.827c0-.7-.567-1.266-1.266-1.266h-.633a2.531 2.531 0 0 0-2.53 2.531v1.899a2.531 2.531 0 0 0 2.53 2.53h.633c.699 0 1.266-.566 1.266-1.265v-4.43zm6.96 5.695a2.531 2.531 0 0 0 2.532-2.531v-1.899a2.531 2.531 0 0 0-2.531-2.531h-.633c-.699 0-1.266.567-1.266 1.266v4.43c0 .698.567 1.265 1.266 1.265h.633zM22.726 12.6c-5.649 0-9.944 4.7-10.125 10.125v.633c0 .35.283.633.633.633h.633c.35 0 .632-.284.632-.633v-.633c0-4.536 3.69-8.227 8.227-8.227 4.536 0 8.227 3.69 8.227 8.227h-.005c.003.096.005 6.554.005 6.554 0 .924-.75 1.673-1.673 1.673h-4.023c0-1.049-.85-1.899-1.898-1.899h-1.266a1.898 1.898 0 1 0 0 3.797h7.187a3.57 3.57 0 0 0 3.571-3.57v-6.555C32.669 17.3 28.374 12.6 22.725 12.6z"
      />
    </g>
  </svg>
);

export default SupportCircleIcon;
