import React from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'underscore';
import FixedAspect from 'components/FixedAspect';
import useCreateEpisodeTranscription from 'hooks/useCreateEpisodeTranscription';
import usePodcastFeedEpisode from 'hooks/usePodcastFeedEpisode';

import { isSubmittingEpisodeTranscriptionCreationRequestSelector } from 'redux/modules/automation-cms/selectors';

import DownloadCaptionsDialogModalContents from './DownloadCaptionsDialogModalContents';
import { transcribeModalBlock as block } from './utils';

export interface TranscribeEpisodeModalContentsProps {
  episodeId?: string;
}

const TranscribeEpisodeModalContents: React.FC<TranscribeEpisodeModalContentsProps> = ({
  episodeId,
}) => {
  const { title, thumbnailImageUrl } = usePodcastFeedEpisode(episodeId);
  const { createEpisodeTranscription } = useCreateEpisodeTranscription({
    episodeId,
  });
  const submitting = useSelector(
    isSubmittingEpisodeTranscriptionCreationRequestSelector,
  );

  return (
    <DownloadCaptionsDialogModalContents
      actions={[
        {
          children: 'transcribe this episode',
          disabled: submitting,
          key: 'transcribe',
          onClick: createEpisodeTranscription,
          theme: 'submit',
        },
      ]}
      details="We’ll create a full transcript that you can post on any website to increase this episode’s accessibilty & SEO."
      onClose={noop}
      icon={
        <FixedAspect aspectRatio={1}>
          <img className={block('artwork')} src={thumbnailImageUrl} />
        </FixedAspect>
      }
      title={`Transcribe "${title}"?`}
    />
  );
};

export default TranscribeEpisodeModalContents;
