import * as React from 'react';
import { noop } from 'underscore';
import useStaticCallback from 'hooks/useStaticCallback';

export interface IProps {
  className?: string;
  onClick?: () => void;
  onUploadSuccess?: () => void;
  sourceFile: string;
  fileName: string;
}

const { useRef, useEffect } = React;

function SaveToDropboxButton(props: IProps) {
  const {
    sourceFile,
    fileName,
    onClick = noop,
    onUploadSuccess = noop,
    className,
  } = props;
  const ref = useRef<HTMLDivElement>();
  const handleSuccess = useStaticCallback(onUploadSuccess);

  useEffect(() => {
    /*
     * sometimes window.Dropbox isn't available (from FS seems like the browser
     * never even attempts to download the script).  check if sdk is available
     * before using it to create the button
     */
    if (window.Dropbox) {
      const dropboxButton = window.Dropbox.createSaveButton(
        sourceFile,
        fileName,
        { success: handleSuccess },
      );
      ref.current.appendChild(dropboxButton);
    }
  }, [fileName, handleSuccess, sourceFile]);

  return <div className={className} ref={ref} onClick={onClick} />;
}

export default SaveToDropboxButton;
