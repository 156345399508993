import React from 'react';
import SelectOption from './SelectOption';
import { Option, SelectOptionsProps } from './types';

/**
 * Helper to render the options list when building menus
 */
export function SelectOptions<T extends Option>({
  onChange,
  options,
  value,
}: SelectOptionsProps<T>) {
  return (
    <>
      {options.map(option => (
        <SelectOption
          key={option.value}
          onClick={onChange}
          option={option}
          selected={option?.value === value?.value}
        />
      ))}
    </>
  );
}

export default SelectOptions;
