import cn from 'classnames';
import * as React from 'react';
import { block } from './utils';

interface Props
  extends Pick<
    React.HTMLProps<HTMLButtonElement | HTMLDivElement>,
    'onMouseOut' | 'onMouseOver'
  > {
  position?: 'start' | 'end';
  onClick?: () => void;
  className?: string;
}

const InputAdornment: React.FC<Props> = props => {
  const {
    children,
    position = 'start',
    onClick,
    className,
    onMouseOut,
    onMouseOver,
  } = props;
  const classes = cn(block('adornment', { [position]: true }), className);

  if (onClick) {
    return (
      <button
        {...{ onClick, onMouseOut, onMouseOver }}
        type="button"
        className={classes}
      >
        {children}
      </button>
    );
  }

  return (
    <div className={classes} {...{ onMouseOut, onMouseOver }}>
      {children}
    </div>
  );
};

export default InputAdornment;
