import BluebirdPromise from 'bluebird';
import { normalize, schema } from 'normalizr';
import { SuperAgentRequest, SuperAgentStatic } from 'superagent';
import { isString, noop } from 'underscore';

import { getValue } from 'utils/collections';
import { attachFields } from 'utils/request';
import { createRequest } from '../utils';
import * as types from './types';

const entireAudiosSchema = new schema.Entity('entireAudios', {});

const entireAudioInstancesSchema = new schema.Entity(
  'entireAudioInstances',
  {
    entireAudio: entireAudiosSchema,
  },
  {
    // in some cases, the backend will try to determine if the uploaded file exists,
    // and while it's doing so, the entireAudio key in the instance object will be
    // defined but will have the id set to null.  the processStrategy here omits the
    // entireAudio object if the id is null so that we don't wind up with an id
    // of null in the entireAudios redux entities
    processStrategy: instance => {
      if (!instance?.entireAudio?.id) {
        const { entireAudio, ...rest } = instance;
        return rest;
      }
      return { ...instance };
    },
  },
);

const entireAudioClipsSchema = new schema.Entity(
  'entireAudioClips',
  {
    entireAudioInstance: entireAudioInstancesSchema,
  },
  {
    idAttribute: value => value.entireAudioInstance.id,
  },
);

function attachFile(req: SuperAgentRequest, src: File | string) {
  if (isString(src)) {
    req.field('url', src);
  } else {
    req.attach('file', src);
  }
  return req;
}

const uploadRecording = (
  args: types.UploadRecordingArgs,
  request: SuperAgentStatic,
  userId?: number,
): Promise<types.UploadRecordingResult> => {
  const [
    src,
    directUploadBucket,
    directUploadKey,
    language = 'en-US',
    waveGeneration = 'amplitudeBased',
  ] = args;

  const req = request.post('/api/v1/recording-upload/');

  attachFields(req, {
    directUploadBucket,
    directUploadKey,
    language,
    userId,
    preferredWaveformType: waveGeneration,
  });

  attachFile(req, src);

  // TODO SPAR-16528
  // return req.then(res => res.body);
  return req.then(({ body }) => {
    const {
      recording_id: recordingId,
      recording_upload_id: recordingUploadId,
      ...restBody
    } = body;
    return {
      recordingId,
      recordingUploadId,
      ...restBody,
    };
  });
};

async function getEntireAudios(
  [md5]: types.GetEntireAudiosArgs,
  request: SuperAgentStatic,
): Promise<types.GetEntireAudiosResult> {
  const result = await request
    .get('/api/v1/recording-upload/entire-audio')
    .query({ md5 });
  return normalize(result.body.data, [entireAudiosSchema]);
}

function uploadAudio(
  [
    src,
    md5,
    directUploadBucket,
    directUploadKey,
    listenNotesPodcastId,
    listenNotesEpisodeId,
    onProgress = noop,
  ]: types.UploadAudioArgs,
  request: SuperAgentStatic,
): Promise<types.UploadAudioResult> {
  return new BluebirdPromise((resolve, reject, onCancel) => {
    const req = request.post('/api/v1/recording-upload/entire-audio/instance');

    attachFields(req, {
      directUploadBucket,
      directUploadKey,
      listenNotesEpisodeId,
      listenNotesPodcastId,
      md5,
    });
    attachFile(req, src);

    onCancel?.(() => {
      req.abort();
    });

    return req
      .on('progress', event => {
        onProgress(event.percent);
      })
      .then(res => resolve(normalize(res.body, entireAudioInstancesSchema)))
      .catch(reject);
  }) as any;
}

function getAudioUpload(
  [id]: types.GetAudioUploadArgs,
  request: SuperAgentStatic,
): Promise<types.GetAudioUploadResult> {
  return request
    .get(`/api/v1/recording-upload/entire-audio/instance/${id}`)
    .then(res => normalize(res.body, entireAudioInstancesSchema));
}

function clipAudio(
  [
    id,
    startMillis,
    endMillis,
    language,
    waveType,
    transcribe,
  ]: types.ClipAudioArgs,
  request: SuperAgentStatic,
  userId: number,
): Promise<types.ClipAudioResult> {
  return request
    .post(`/api/v1/recording-upload/entire-audio/instance/${id}/clip`)
    .type('form')
    .send({
      language,
      userId,
      isTranscriptEnabled: transcribe,
      preferredWaveformType: waveType,
      trimEndMillis: endMillis,
      trimStartMillis: startMillis,
    })
    .then(res => res.body);
}

/*
 * the use case here is just to get a clip from a recording id.  the API supports
 * this via a collection endpoint /api/v1/recording-upload/entire-audio/instance/clip
 * that can be filtered by recording id.  the clips returning from this endpoint
 * do not have ids, but having one for redux would be useful.
 *
 * This function will take the first result from the collection endpoint and will
 * use the recordingId as the id of that clip.  At the time of writing we don't
 * need to use this endpoint for more than 1 result.
 */
function getAudioClip(
  [recordingId]: types.GetAudioClipArgs,
  request: SuperAgentStatic,
): Promise<types.GetAudioClipResult> {
  return request
    .get('/api/v1/recording-upload/entire-audio/instance/clip')
    .query({ recordingId })
    .then(res => {
      const firstResult = getValue(res, ['body', 'data', 0]);
      return normalize(
        {
          ...firstResult,
          id: recordingId,
        },
        entireAudioClipsSchema,
      );
    });
}

export const handle: types.IHandle = (
  method: types.ServiceMethod,
  args: any,
  token?: string,
  userId?: number,
): Promise<any> => {
  const request = createRequest({
    token,
    baseUrl: spareminConfig.services.recordingUpload,
  });

  switch (method) {
    case types.ServiceMethod.UPLOAD_RECORDING:
      return uploadRecording(args, request, userId);

    case types.ServiceMethod.UPLOAD_AUDIO:
      return uploadAudio(args, request);

    case types.ServiceMethod.GET_AUDIO_UPLOAD:
      return getAudioUpload(args, request);

    case types.ServiceMethod.CLIP_AUDIO:
      return clipAudio(args, request, userId);

    case types.ServiceMethod.GET_AUDIO_CLIP:
      return getAudioClip(args, request);

    case types.ServiceMethod.GET_ENTIRE_AUDIOS:
      return getEntireAudios(args, request);

    default:
      throw new Error(`${types.ACTION_KEY} cannot handle method ${method}`);
  }
};
