import { MetadataField } from 'components/Metadata/types';

export const MAX_CHARS_CAPTIONS = 2200;
export const MAX_CHARS_CAPTIONS_PREVIEW = 2200;

export const captionsMetadata: MetadataField[] = [
  {
    label: 'Podcast Title',
    value: '<Podcast Title>',
  },
  {
    label: 'Episode Title',
    value: '<Episode Title>',
  },
  {
    label: 'Episode Number',
    value: '<Episode Number>',
  },
  {
    label: 'Link To Episode',
    value: '<Link To Episode>',
  },
];
