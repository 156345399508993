import React from 'react';
import { Emoji } from 'components/icons';
import Modal from 'components/Modal';
import NonIdealState from 'components/NonIdealState';
import { SM_BLUE } from 'utils/constants';

interface Props {
  onHide?: () => void;
}

function ExtractAudioFeatureFakeModalContent(props: Props) {
  const { onHide } = props;

  return (
    <>
      <NonIdealState
        title="This feature is coming soon"
        description={
          <>
            We’re still developing the ability to extract audio
            <br />
            from video & will notify you when it’s available.
          </>
        }
        icon={<Emoji fill={SM_BLUE} width="100" height="100" />}
      />
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton theme="submit" onClick={onHide}>
            OK
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
}

export default ExtractAudioFeatureFakeModalContent;
