import * as React from 'react';

import { block } from '../utils';

export interface BogoTextProps {
  children?: React.ReactNode;
}

const BogoText: React.SFC<BogoTextProps> = ({ children }) => (
  <span className={block('bogo-text')}>{children}</span>
);

export default BogoText;
