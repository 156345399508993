import ReactJoyride, { STATUS } from 'react-joyride';

// A simple hack to access the underlying popper.js instance.
// A better way would be the `floaterProps.getPopper` but it is broken at the moment:
// https://github.com/gilbarbara/react-joyride/issues/600
class Joyride extends ReactJoyride {
  beaconPopper: any;
  tooltipPopper: any;

  setPopper = (
    popper: { instance: { scheduleUpdate: () => void } },
    type: 'wrapper' | 'floater',
  ) => {
    if (type === 'wrapper') {
      this.beaconPopper = popper;
    } else {
      // fix tooltip misplacement on first render
      popper.instance.scheduleUpdate();
      this.tooltipPopper = popper;
    }
  };
}

export default Joyride;
export { STATUS };
