import { Record } from 'immutable';
import * as React from 'react';
import { DraggableData } from 'react-draggable';

export interface IBounds {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}

export interface IPosition {
  x: number;
  y?: number;
}

export interface ISize {
  height?: number;
  width: number;
}

export interface ICallbackData {
  width: number;
  bounds: IBounds;
  x: number;
  lastX: number;
  offset: {
    x: number;
  };
}

export type ReactDraggableEventHandler = (
  e: React.MouseEvent<HTMLElement>,
  data: DraggableData,
) => void;

export type DragEventHandler = (
  e: React.MouseEvent<HTMLElement>,
  data: ICallbackData,
) => void | false;

export interface IDataState {
  lastX: number;
  offset: number;
  width: number;
}

export const dataFactory = Record<IDataState>({
  lastX: undefined,
  offset: undefined,
  width: undefined,
});
