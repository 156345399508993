import classNames from 'classnames';
import * as React from 'react';
import _ from 'underscore';

import Modal from 'components/Modal';

export interface IProps {
  className?: string;
  onHide?: () => void;
  show?: boolean;
  bannerIcon?: string;
  bannerText?: string;
  titleText?: string;
  subtitleText?: string;
  mainHtml?: string;
}

// TODO this should be SFC
export default class AnnouncementModal extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    className: '',
    onHide: _.noop,
    show: false,
  };

  private renderBody() {
    const {
      bannerIcon,
      bannerText,
      titleText,
      subtitleText,
      mainHtml,
    } = this.props;
    const mainInnerHtml = { __html: mainHtml };

    return (
      <div className="announcement-modal__body-contents">
        <div className="announcement-modal__body-banner-container">
          <img
            className="announcement-modal__body-banner-icon"
            src={bannerIcon}
          />
          <div className="announcement-modal__body-banner-title">
            {bannerText}
          </div>
        </div>
        {titleText && (
          <div className="announcement-modal__body-title">{titleText}</div>
        )}
        {subtitleText && (
          <div className="announcement-modal__body-subtitle">
            {subtitleText}
          </div>
        )}
        <div
          className="announcement-modal__body-main"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={mainInnerHtml}
        />
      </div>
    );
  }

  public render() {
    const { className, onHide, show } = this.props;
    return (
      <Modal
        backdrop="static"
        className={classNames('announcement-modal', className)}
        keyboard={false}
        onHide={onHide}
        show={show}
        title="Product Announcement"
      >
        <Modal.Body className="announcement-modal__body">
          {this.renderBody()}
        </Modal.Body>
        <Modal.Footer>
          <Modal.FooterButton onClick={onHide}>Close</Modal.FooterButton>
        </Modal.Footer>
      </Modal>
    );
  }
}
