import cn from 'classnames';
import React from 'react';
import { block } from './utils';

export interface ModalFooterProps {
  children?: React.ReactNode;
  className?: string;
}

const ModalFooter: React.FC<ModalFooterProps> = ({ children, className }) => (
  <div className={cn(block('footer'), className)}>{children}</div>
);

export default ModalFooter;
