import * as React from 'react';

import { SelectField, SelectFieldProps } from 'components/Form';
import { ProgressType } from 'types';

export type Value = ProgressType | null;

interface IProps
  extends Omit<SelectFieldProps, 'label' | 'onChange' | 'value'> {
  onChange?: (value: Value) => void;
  value: Value;
}

interface ProgressStyleOption {
  value: Value;
  displayName: string;
}

const options: ProgressStyleOption[] = [
  {
    value: null,
    displayName: 'None',
  },
  {
    value: ProgressType.BAR,
    displayName: 'Bar',
  },
  {
    value: ProgressType.PLAYER,
    displayName: 'Player',
  },
];

const ProgressStyleField: React.FC<IProps> = props => {
  const { disabled, onChange, value } = props;

  const handleChange = (selectedValue: string) => {
    onChange(selectedValue ? (selectedValue as ProgressType) : null);
  };

  return (
    <SelectField
      disabled={disabled}
      horizontal={{
        control: { xs: 7 },
        label: { xs: 5 },
      }}
      label="Style"
      onChange={handleChange}
      value={value ?? ''}
    >
      {options.map(option => (
        <option key={option.value} value={option.value ?? ''}>
          {option.displayName}
        </option>
      ))}
    </SelectField>
  );
};

export default ProgressStyleField;
