import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { defaultPostTextSelector } from 'redux/modules/download/selectors';
import {
  linkedinIsLoading,
  linkedinOrganizationsSelector,
  linkedinSelector,
} from 'redux/modules/social';

import { PostStatus } from 'redux/modules/social/constants';
import { LinkedinOrganizationSelectorOption } from './LinkedinOrganizationSelector/types';

const usernameSelector = createSelector(
  linkedinSelector,
  linkedin => linkedin.username,
);

const avatarSrcSelector = createSelector(
  linkedinSelector,
  linkedin => linkedin.profileImageUrl,
);

const postStatusSelector = createSelector(
  linkedinSelector,
  linkedin => linkedin.postStatus,
);

const defaultOrganizationSelector = createSelector(
  linkedinSelector,
  linkedinIsLoading,
  (li, isLoading): LinkedinOrganizationSelectorOption => {
    if (isLoading) {
      return {
        id: null,
        label: 'Loading',
        value: 'Loading',
      };
    }

    return {
      id: li.providerUserId,
      imageUrl: li.profileImageUrl,
      label: 'LinkedIn Profile',
      value: li.providerUserId,
    };
  },
);

const preselectedOrganizationSelector = createSelector(
  [
    (_, linkedinAuthorId) => linkedinAuthorId,
    linkedinOrganizationsSelector,
    defaultOrganizationSelector,
  ],
  (linkedinAuthorId, organizations, defaultOrganization) => {
    const isPerson = linkedinAuthorId?.includes('person');

    if (isPerson && linkedinAuthorId?.includes(defaultOrganization?.id)) {
      return defaultOrganization;
    }

    if (!isPerson && Boolean(organizations.length)) {
      const { id, logoImageUrl, name } =
        organizations?.find(org => org.id === linkedinAuthorId) || {};

      return !id
        ? defaultOrganization
        : {
            id,
            imageUrl: logoImageUrl,
            label: name,
            value: id,
          };
    }

    return defaultOrganization;
  },
);

interface UseLinkedinPostModalConfig {
  defaultDescription?: string;
  linkedinAuthorId?: string;
}

export default function useLinkedinPostModalSelector(
  config: UseLinkedinPostModalConfig = {},
) {
  const {
    defaultDescription: defaultDescriptionProp,
    linkedinAuthorId,
  } = config;

  const avatarSrc = useSelector(avatarSrcSelector);
  const postStatus: PostStatus = useSelector(postStatusSelector);
  const username = useSelector(usernameSelector);
  const defaultDescription = useSelector(defaultPostTextSelector);
  const defaultOrganization = useSelector(defaultOrganizationSelector);
  const preselectedOrganization = useSelector(state =>
    preselectedOrganizationSelector(state, linkedinAuthorId),
  );

  return {
    avatarSrc,
    postStatus,
    username,
    defaultDescription: defaultDescriptionProp || defaultDescription,
    defaultOrganization,
    preselectedOrganization,
  };
}
