import cn from 'classnames';
import React from 'react';

import { Popper, PopperProps } from 'components/Popper';
import PopperToggle, {
  PopperToggleProps,
} from 'components/Popper/PopperToggle';
import { toggleBodyBlock as block } from './utils';

type PickedProps = Pick<PopperProps, 'title'> &
  Pick<PopperToggleProps, 'onChange' | 'options' | 'switchConfig' | 'value'>;

export interface ToggleCardToggleBodyProps extends PickedProps {
  className?: string;
}

const ToggleCardToggleBody: React.FC<ToggleCardToggleBodyProps> = ({
  className,
  onChange,
  options,
  switchConfig,
  title,
  value,
}) => (
  <Popper className={cn(block(), className)} title={title}>
    <PopperToggle {...{ onChange, options, switchConfig, value }} />
  </Popper>
);

export default ToggleCardToggleBody;
