import * as React from 'react';
import { useSelector } from 'react-redux';
import Modal from 'components/Modal';
import { isUpdatingSubscriptionSelector } from 'redux/modules/pricing/selectors';
import { AddBillingCycleModalContent } from './AddBillingCycleModal';
import BillingCycleModal from './BillingCycleModal';

const MODAL_NAME = 'UpgradePlan';

function UpgradePlanModal() {
  const disabled = useSelector(isUpdatingSubscriptionSelector);

  return (
    <BillingCycleModal title="checkout" name={MODAL_NAME} disabled={disabled}>
      <AddBillingCycleModalContent
        disabled={disabled}
        renderFooter={({ onPaymentSaved }) => (
          <Modal.Footer>
            <Modal.FooterButton
              disabled={disabled}
              onClick={onPaymentSaved}
              theme="submit"
              fluid
            >
              confirm and pay
            </Modal.FooterButton>
          </Modal.Footer>
        )}
      />
    </BillingCycleModal>
  );
}

export default UpgradePlanModal;
