import { Location, LocationDescriptor } from 'history';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { isFunction } from 'underscore';

import Redirect from 'containers/Redirect';
import { getDisplayName } from 'utils/react';
import { getHomepage } from 'utils/routes';

function withRedirectOnMobile(
  path:
    | LocationDescriptor
    | ((location: Location) => LocationDescriptor) = getHomepage(),
) {
  return (WrappedComponent: React.ComponentType) => {
    const RedirectOnMobileComponent = props => {
      const { location } = props;

      if (isMobile) {
        const redirectTo = isFunction(path) ? path(location) : path;
        return <Redirect path={redirectTo} />;
      }

      return <WrappedComponent {...props} />;
    };

    (RedirectOnMobileComponent as any).displayName = `WithRedirectOnMobile(${getDisplayName(
      WrappedComponent,
    )})`;

    return RedirectOnMobileComponent;
  };
}

export default withRedirectOnMobile;
