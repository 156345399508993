import React from 'react';

import Button from 'components/Button';
import { H1 } from 'components/Heading';
import ProgressOverlay from 'components/ProgressOverlay';

import { block } from './utils';

interface AutogramPreviewLoadingStepProps {
  onCancel: () => void;
}

const AutogramPreviewLoadingStep: React.FunctionComponent<AutogramPreviewLoadingStepProps> = props => {
  const { onCancel } = props;

  return (
    <div className={block('content')}>
      <div className={block('content-pane', { left: true })}>
        <div className={block('overlay-wrapper')}>
          <ProgressOverlay in />
        </div>
      </div>
      <div className={block('content-pane', { right: true })}>
        <div className={block('preview-left-pane')}>
          <H1 className={block('title')}>
            Generating a preview of your automation
          </H1>
          <Button
            className={block('cancel-button')}
            onClick={onCancel}
            size="small"
            shape="round"
            theme="cancel"
          >
            cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AutogramPreviewLoadingStep;
