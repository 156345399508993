import { useSelector } from 'react-redux';
import {
  threadsAvatarSrcSelector,
  threadsUserNameSelector,
} from 'redux/modules/social';
import { MAX_CHARS_CAPTIONS_PREVIEW } from './constants';

function formatDescription(val: string) {
  if (typeof val !== 'string') return undefined;

  return val.substring(0, MAX_CHARS_CAPTIONS_PREVIEW);
}

interface UseThreadsPostModalConfig {
  defaultDescription?: string;
}

export default function useThreadsPostModalSelector(
  config: UseThreadsPostModalConfig = {},
) {
  const username = useSelector(threadsUserNameSelector);
  const avatarSrc = useSelector(threadsAvatarSrcSelector);

  const { defaultDescription } = config;

  return {
    username,
    avatarSrc,
    defaultDescription: formatDescription(defaultDescription),
  };
}
