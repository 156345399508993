import * as React from 'react';

import PlanDecisionModal from '../PlanDecisionModal';
import PlanDowngradeModalContents from './PlanDowngradeModalContents';

export interface PlanDowngradeModalProps {}

const PlanDowngradeModal: React.FC<PlanDowngradeModalProps> = () => (
  <PlanDecisionModal name="PlanDowngradeModal" title="your plan">
    <PlanDowngradeModalContents />
  </PlanDecisionModal>
);

export default PlanDowngradeModal;
