import * as React from 'react';

import { Omit } from 'types';
import NumericSelect, { NumericSelectProps } from './NumericSelect';
import { block } from './utils';

type Props = Omit<
  NumericSelectProps,
  'displayValues' | 'maxValue' | 'minValue' | 'spinButtons'
>;

const FontSizeInput: React.SFC<Props> = props => (
  <NumericSelect
    {...props}
    className={block('font-size')}
    displayValues={[8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96]}
    maxValue={99}
    minValue={1}
    spinButtons
  />
);

export default FontSizeInput;
export { Props as FontSizeInputProps };
