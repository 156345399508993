import * as React from 'react';

import Rnd, { RndProps } from 'components/Rnd';
import TimerPreview from 'components/TimerPreview';
import { Dimensions, Size } from 'types';
import { createChainedFunction } from 'utils/functions';
import measurement, { Pixels } from 'utils/measurement';
import { stringToPx } from 'utils/placement';

const { useCallback } = React;

type DraggableTimerValue = Dimensions<string>;
type PickedRndProps = Pick<RndProps, 'onDragStart' | 'onDragStop'>;

export interface DraggableTimerProps extends PickedRndProps {
  canvas: Size<number>;
  color: string;
  fontSize: string;
  onChange?: (value: DraggableTimerValue) => void;
  defaultValue: DraggableTimerValue;
}

const DraggableTimer: React.FC<DraggableTimerProps> = ({
  canvas,
  color,
  fontSize,
  onChange,
  onDragStart,
  onDragStop,
  defaultValue,
}) => {
  const { height, left, top, width } = stringToPx(defaultValue, canvas);

  const handleDragStop: RndProps['onDragStop'] = useCallback(
    (_, { x, y }) => {
      onChange({
        height: defaultValue.height,
        width: defaultValue.width,
        left: new Pixels(x).toUnit('vw', canvas).toString(),
        top: new Pixels(y).toUnit('vh', canvas).toString(),
      });
    },
    [canvas, defaultValue.height, defaultValue.width, onChange],
  );

  return (
    height > 0 &&
    width > 0 && (
      <Rnd
        active
        bounds="parent"
        default={{
          height,
          width,
          x: left,
          y: top,
        }}
        enableResizing={{
          bottom: false,
          bottomLeft: false,
          bottomRight: false,
          left: false,
          right: false,
          top: false,
          topLeft: false,
          topRight: false,
        }}
        lockAspectRatio
        onDragStop={createChainedFunction(handleDragStop, onDragStop)}
        {...{ onDragStart }}
      >
        <TimerPreview
          color={color}
          fontSize={measurement(fontSize).toUnit('px', canvas)}
        />
      </Rnd>
    )
  );
};

export default DraggableTimer;
