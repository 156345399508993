import { connect } from 'react-redux';

import {
  configurationIdSelector,
  episodeIdSelector,
} from 'redux/modules/download';
import { onClickReclipFeedbackSurvey } from 'redux/modules/mixpanel/actions';
import { Dispatch, State } from 'redux/types';
import ReClipPostTaskBanner, { Props } from './ReClipPostTaskBanner';

type DispatchProps = Pick<Props, 'onClick'>;
type StateProps = Pick<Props, 'autoCreateEpisodeId' | 'widgetId'>;

const mapStateToProps = (state: State): StateProps => ({
  autoCreateEpisodeId: episodeIdSelector(state),
  widgetId: configurationIdSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onClick: () => dispatch(onClickReclipFeedbackSurvey()),
});

const component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReClipPostTaskBanner);
export type ReClipPostTaskBannerProps = React.ComponentPropsWithRef<
  typeof component
>;
export default component;
