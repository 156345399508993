import { CMSEpisode } from 'types/common';
import bem from 'utils/bem';

export const cmsBlock = bem('automation-cms-rich');
export const liteCmsBlock = bem('automation-cms-lite');
export const automationWorkflowTileBlock = bem('automation-workflow-tile');
export const podcastSidebarBlock = bem('podcast-sidebar');
export const episodeListBlock = bem('cms-episode-list');
export const workflowGridBlock = bem('automation-workflow-grid');
export const workflowDeleteModalBlock = bem('cms-workflow-delete-modal');
export const workflowSelectModalBlock = bem('cms-workflow-select-modal');

export function getVideoCount(projects: string[], episode: CMSEpisode) {
  return projects?.length ?? episode?.projectInfo?.totalActiveProjectCount ?? 0;
}

export function isVideoPending(episode: CMSEpisode) {
  return episode?.projectInfo?.status === 'processing';
}

export function isGenerating(episode: CMSEpisode) {
  return (
    episode?.projectInfo?.status === 'partialProcessing' &&
    !!episode?.projectInfo?.projectListUrl
  );
}

export function hasVideos(projects: string[], episode: CMSEpisode) {
  return getVideoCount(projects, episode) > 0;
}

export function getWorkflowCount(workflows: number[]) {
  return workflows?.length ?? 0;
}
