import { createSelector } from 'reselect';

import { State } from 'redux/types';
import { PostTaskState } from './types';

const stateSelector = (state: State): PostTaskState => state.get('postTask');

export const hiddenPostTaskSelector = createSelector(
  stateSelector,
  state => state.hidden,
);
