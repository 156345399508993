import cn from 'classnames';
import * as React from 'react';

import { Omit } from 'types';
import { PodcastData } from '../types';
import { podcastListBlock as block } from '../utils';

export interface PodcastProps extends Omit<PodcastData, 'id'> {
  className?: string;
}

const Podcast: React.SFC<PodcastProps> = ({
  artworkUrl,
  className,
  episodeCount,
  title,
  host,
}) => {
  return (
    <div className={cn(block('podcast'), className)}>
      <img className={block('podcast-thumb')} src={artworkUrl} />
      <div className={block('podcast-info')}>
        <span className={block('podcast-title')}>{title}</span>
        <span className={block('podcast-host')}>
          {host}
          {episodeCount > 0 &&
            ` • ${episodeCount} ${episodeCount === 1 ? 'episode' : 'episodes'}`}
        </span>
      </div>
    </div>
  );
};

export default Podcast;
