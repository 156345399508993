import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectTrackElement } from 'redux/modules/embed/actions';
import { hasSoundwaveSelector } from 'redux/modules/embed/selectors';
import { pushModal } from 'redux/modules/modal/actions';
import { Dispatch } from 'redux/types';
import { Omit } from 'types';
import Track, { TrackProps } from '../../components/Track';
import { block } from '../../utils';
import WaveformAsset, { WaveformAssetProps } from '../WaveformAsset';

const { useCallback } = React;

type PickedAssetProps = Pick<WaveformAssetProps, 'pxPerSec'>;
type PickedTrackProps = Omit<TrackProps, 'className' | 'children'>;

export interface WaveformTrackProps
  extends PickedTrackProps,
    PickedAssetProps {}

const WaveformTrack: React.FC<WaveformTrackProps> = ({
  pxPerSec,
  id,
  ...props
}) => {
  const dispatch = useDispatch<Dispatch>();
  const hasWaveform = useSelector(hasSoundwaveSelector);

  const handleAssetClick = useCallback(() => {
    dispatch(selectTrackElement('waveform', id));
    dispatch(pushModal({ name: 'EditWaveformModal' }));
  }, [dispatch, id]);

  return (
    <Track {...props} id={id} className={block('track', { waveform: true })}>
      {hasWaveform && (
        <WaveformAsset onClick={handleAssetClick} pxPerSec={pxPerSec} />
      )}
    </Track>
  );
};

export default WaveformTrack;
