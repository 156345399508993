import * as React from 'react';

import { block } from '../utils';

export interface TimelineWarningOverlayProps {
  position: number;
  width: number;
}

const TimelineWarningOverlay: React.SFC<TimelineWarningOverlayProps> = ({
  position,
  width,
}) => (
  <div
    className={block('warning-overlay')}
    style={{
      width,
      transform: `translate(${position}px, 0px)`,
    }}
  />
);

export default TimelineWarningOverlay;
