import { Checkbox, Spacer, Text } from '@sparemin/blockhead';
import React, { useMemo } from 'react';
import EducationalCard from 'components/EducationalCard/EducationalCard';
import { block } from '../../utils';
import { DisclosureOption } from './types';
import { UseDisclosureStepProps } from './useDisclosureStep';

export type DisclosureSectionProps = Pick<
  UseDisclosureStepProps,
  | 'brandContent'
  | 'brandOrganic'
  | 'onBrandContentChange'
  | 'onBrandOrganicChange'
>;

const DisclosureSection: React.FC<DisclosureSectionProps> = ({
  brandContent,
  brandOrganic,
  onBrandContentChange,
  onBrandOrganicChange,
}) => {
  const disclosureOptions = useMemo((): DisclosureOption[] => {
    return [
      {
        title: 'Your brand',
        description:
          'You are promoting yourself or your own business. Your videos will be classified as Brand Organic.',
        isSelected: brandOrganic,
        onChange: onBrandOrganicChange,
      },
      {
        title: 'Branded content',
        description:
          'You are promoting another brand or a third party. This video will be classified as Branded Content.',
        isSelected: brandContent,
        onChange: onBrandContentChange,
      },
    ];
  }, [brandContent, brandOrganic, onBrandContentChange, onBrandOrganicChange]);

  return (
    <Spacer orientation="vertical" align="flex-start" space="30px">
      <Spacer
        orientation="vertical"
        align="flex-start"
        space="20px"
        className={block('disclosure-section')}
      >
        {disclosureOptions.map(
          ({ title, description, isSelected, onChange }) => (
            <Checkbox {...{ isSelected, onChange }}>
              <Spacer
                orientation="vertical"
                align="flex-start"
                space="6px"
                className={block('fluid-content')}
              >
                <Text
                  variant="label"
                  className={block('disclosure-item-title')}
                >
                  {title}
                </Text>

                <Text
                  variant="label"
                  className={block('disclosure-item-subtitle')}
                >
                  {description}
                </Text>
              </Spacer>
            </Checkbox>
          ),
        )}
      </Spacer>

      {(brandContent || brandOrganic) && (
        <EducationalCard
          content={
            brandContent
              ? 'Your videos will be labeled “Paid partnership”. This cannot be changed once a video is posted.'
              : 'Your videos will be labeled “Promotional content”. This cannot be changed once a video is posted.'
          }
        />
      )}
    </Spacer>
  );
};

export default DisclosureSection;
