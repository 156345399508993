import React from 'react';

import { useDispatch } from 'react-redux';
import { SubscriptionPeriod } from 'blocks/Pricing/types';
import { PurchaseableTier, Tier } from 'redux/middleware/api/plan-service';
import { onClickUpgradePlan } from 'redux/modules/mixpanel';
import {
  UpgradePlanLocation,
  UpgradePlanSource,
} from 'redux/modules/mixpanel/types';
import { usePlanSelector } from './PlanSelectorContext';

interface UnderlyingProps {
  period: SubscriptionPeriod;
  onUpgradeClick: () => void;
}

export interface InjectedWithPlanSelectorProps {
  location: UpgradePlanLocation;
  onUpgradeClick: (tier: PurchaseableTier, period: SubscriptionPeriod) => void;
}

export default function withPlanSelector(
  tier: Tier,
  source: UpgradePlanSource,
) {
  return <P extends {}>(
    Plan: React.ComponentType<P & UnderlyingProps>,
  ): React.FC<
    Omit<P, keyof UnderlyingProps> & InjectedWithPlanSelectorProps
  > => {
    const WrappedComponent = props => {
      const { location, onUpgradeClick, ...restProps } = props;
      const dispatch = useDispatch();
      const { period } = usePlanSelector();

      return (
        <Plan
          onUpgradeClick={() => {
            dispatch(onClickUpgradePlan(source, location, period));
            onUpgradeClick(tier, period);
          }}
          period={period}
          {...restProps}
        />
      );
    };

    WrappedComponent.displayName = `WithPlanSelector(${Plan.displayName})`;

    return WrappedComponent;
  };
}
