import classNames from 'classnames';
import { List, Record, RecordOf } from 'immutable';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import _ from 'underscore';

import FadingScrollBars from 'components/FadingScrollBars';
import Image from 'components/Image';
import VideoPoster from 'components/VideoPoster';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { formatDurationMillis } from 'utils/time';

type AssetType = 'image' | 'video';

interface IAsset {
  id: string;
  src: string;
  startMillis: number;
  type: AssetType;
}

interface IProps {
  assets?: List<RecordOf<IAsset>>;
  aspectRatio: number;
  className?: string;
  onAssetClick?: (asset: RecordOf<IAsset>) => void;
}

export const assetFactory = Record<IAsset>({
  id: undefined,
  src: undefined,
  startMillis: undefined,
  type: undefined,
});

export default class MediaAssets extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    assets: List(),
    onAssetClick: _.noop,
  };

  public shouldComponentUpdate(nextProps: Readonly<IProps>) {
    const { assets } = this.props;
    const { assets: nextAssets } = nextProps;

    return !nextAssets.equals(assets);
  }

  private handleAssetClick = (id: string) => () => {
    const { assets, onAssetClick } = this.props;
    const asset = assets.find(a => a.id === id);
    onAssetClick(asset);
  };

  private renderAssets() {
    const { aspectRatio, assets } = this.props;

    const ratioName = getAspectRatioName(aspectRatio);

    return assets.reduce((acc, asset) => {
      acc.push(
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={4}
          className={classNames('assets-pane__tile')}
          key={asset.id}
          onClick={this.handleAssetClick(asset.id)}
        >
          <div className={`assets-pane__asset-wrapper--${ratioName}`}>
            {asset.type === 'image' ? (
              <Image className="assets-pane__image" src={asset.src} />
            ) : (
              <VideoPoster className="assets-pane__video" src={asset.src} />
            )}
          </div>
          <div className="assets-pane__tile-footer">
            <span>
              {formatDurationMillis(asset.startMillis, {
                minute: '2-digit',
                second: '2-digit',
                trim: false,
              })}
            </span>
          </div>
        </Col>,
      );
      return acc;
    }, [] as JSX.Element[]);
  }

  public render() {
    const { assets } = this.props;

    return (
      <div
        className={classNames('assets-pane__media', {
          'assets-pane__media--empty': assets.isEmpty(),
        })}
      >
        <FadingScrollBars className="assets-pane__fading-scroller">
          <div className="assets-pane__media-inner">
            <Row className="assets-pane__media-tiles">
              {!assets.isEmpty() && this.renderAssets()}
            </Row>
          </div>
        </FadingScrollBars>
      </div>
    );
  }
}

export { IProps as MediaAssetsProps };
