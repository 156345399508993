import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { onExitTemplateWizard } from 'redux/modules/mixpanel';
import { getCreatePath } from 'utils/routes';

const { useCallback } = React;

export interface ChooseAnotherTemplateLinkProps {
  children?: React.ReactNode;
  stepName?: string;
}

const ChooseAnotherTemplateLink: React.FC<ChooseAnotherTemplateLinkProps> = ({
  children = 'choose another template',
  stepName,
}) => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    if (stepName) {
      dispatch(onExitTemplateWizard(stepName));
    }
  }, [dispatch, stepName]);

  return (
    <Link onClick={handleClick} to={getCreatePath()}>
      {children}
    </Link>
  );
};

export default ChooseAnotherTemplateLink;
