import classNames from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { ImportType } from '../../types';
import ImportTypeSelectorHeaderItem from './ImportTypeSelectorHeaderItem';
import { block } from './utils';

export interface Item {
  content: React.ReactNode;
  id: ImportType;
  title: React.ReactNode;
}

interface IProps {
  activeItemId: string;
  className?: string;
  items: Item[];
  onSelectType?: (importType: ImportType) => void;
}

const ImportTypeSelector: React.SFC<IProps> = ({
  activeItemId,
  className,
  items,
  onSelectType,
}) => (
  <div className={classNames(block(), className)}>
    <div className={block('header-container')}>
      <ul className={block('header')}>
        {items.map((item: Item) => (
          <ImportTypeSelectorHeaderItem
            key={item.id}
            id={item.id}
            onSelectType={onSelectType}
            selected={activeItemId === item.id}
          >
            {item.title}
          </ImportTypeSelectorHeaderItem>
        ))}
      </ul>

      <div
        className={classNames(
          block('header-indicator'),
          block(`header-indicator--${activeItemId}`),
        )}
      />
    </div>

    <div
      className={classNames(
        block('content'),
        block(`content--${activeItemId}`),
      )}
    >
      {items.map((item: Item) => (
        <div
          key={item.id}
          className={block('item', { active: activeItemId === item.id })}
        >
          {item.content}
        </div>
      ))}
    </div>
  </div>
);

ImportTypeSelector.defaultProps = {
  onSelectType: noop,
};

export default ImportTypeSelector;
export { IProps as ImportTypeSelectorProps };
