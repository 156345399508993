import { Token } from '@stripe/stripe-js';
import * as React from 'react';
import { SubscriptionPeriod } from 'blocks/Pricing/types';
import FontAwesome from 'components/FontAwesome';
import { InputAdornment, TextField } from 'components/Form';
import CardField from 'components/Form/CardField';
import Modal from 'components/Modal';
import { PurchaseableTier } from 'redux/middleware/api/plan-service';
import { BillingCycleModalRenderProps } from '../BillingCycleModal/BillingCycleModalContent';
import AddBillingCycleModalBody from './AddBillingCycleModalBody';
import AddBillingCycleModalSection from './AddBillingCycleModalSection';
import useCheckoutForm from './useCheckoutForm';
import { block } from './utils';

interface Props {
  costCents: number;
  tier: PurchaseableTier;
  children: React.ReactNode;
  className?: string;
  subscriptionPeriod: SubscriptionPeriod;
  disabled?: boolean;
  onSaved: BillingCycleModalRenderProps['onSubmit'];
}

export interface ICheckoutForm {
  submit: () => Promise<Token>;
}

const { useRef } = React;

function CheckoutForm(props: Props) {
  const {
    children,
    onSaved,
    costCents,
    tier,
    className,
    subscriptionPeriod,
    disabled,
  } = props;
  const isPayNowButtonVisibleRef = useRef(false);
  const {
    email,
    card,
    hasError,
    helperText,
    onSubmit,
    allowSubmit,
  } = useCheckoutForm(() =>
    onSaved(null, {
      name: 'MainCTA',
      isPayNowButtonVisible: isPayNowButtonVisibleRef.current,
    }),
  );

  return (
    <>
      <AddBillingCycleModalBody
        costCents={costCents}
        subscriptionPeriod={subscriptionPeriod}
        className={className}
        tier={tier}
      >
        <AddBillingCycleModalSection title="Your billing cycle">
          {children}
        </AddBillingCycleModalSection>
        <AddBillingCycleModalSection title="Your payment method">
          {/*
           * NB: PaymentRequestButton used to be here but was causing the app
           * to crash.  See https://sparemin.atlassian.net/browse/SPAR-18343
           */}
          <TextField
            light
            className={block('field')}
            density="comfortable"
            onChange={email.onChange}
            error={!!email.error}
            helperText={email.error}
            placeholder="Email"
            autoComplete="off"
            startAdornment={
              <InputAdornment>
                <FontAwesome icon="envelope" />
              </InputAdornment>
            }
          />
          <CardField
            light
            density="comfortable"
            error={hasError}
            helperText={helperText}
            className={block('field')}
            onCardChange={card.onChange}
          />
        </AddBillingCycleModalSection>
      </AddBillingCycleModalBody>
      <Modal.Footer>
        <Modal.FooterButton
          disabled={disabled || !allowSubmit}
          theme="submit"
          fluid
          onClick={onSubmit}
        >
          Confirm and pay
        </Modal.FooterButton>
      </Modal.Footer>
    </>
  );
}

export default CheckoutForm;
