import React from 'react';
import { Twitter } from 'components/icons';
import SocialCircle from './SocialCircle';

export interface TwitterSocialCircleProps {
  className?: string;
}

const TwitterSocialCircle: React.FC<TwitterSocialCircleProps> = ({
  className,
}) => (
  <SocialCircle background="#38a1f3" className={className}>
    <Twitter
      height="100%"
      width="41.67%"
      style={{ position: 'relative', left: '2.5%', top: '1%' }}
    />
  </SocialCircle>
);

export default TwitterSocialCircle;
