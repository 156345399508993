import { ButtonBase, ButtonBaseProps } from '@sparemin/blockhead';
import React from 'react';

import { block as textToolbarBlock } from './utils';

interface ToolbarButtonV2Props
  extends Omit<ButtonBaseProps<'div'>, 'as' | 'className'> {
  children: React.ReactNode;
}

const ToolbarButtonV2 = React.forwardRef<HTMLDivElement, ToolbarButtonV2Props>(
  (props, ref) => {
    const { children, ...buttonProps } = props;
    return (
      <ButtonBase
        {...buttonProps}
        ref={ref}
        as="div"
        className={textToolbarBlock('toolbar-button-v2')}
      >
        {children}
      </ButtonBase>
    );
  },
);

export default ToolbarButtonV2;
