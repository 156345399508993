import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';
import { attachFields } from 'utils/request';
import { createRequest } from '../utils';
import {
  ACTION_KEY,
  CreationArgs,
  CreationResult,
  GetCreationArgs,
  ServiceMethod,
} from './types';

const BASE_PATH = '/api/v1/creation';

const creationSchema = new schema.Entity('creations');

async function create(
  request: SuperAgentStatic,
  [creationReq]: CreationArgs,
): Promise<CreationResult> {
  const req = request.post(`${BASE_PATH}/creation-request`);
  attachFields(req, creationReq);

  const { body } = await req;
  return normalize(body, creationSchema);
}

async function getCreation(
  request: SuperAgentStatic,
  [creationId]: GetCreationArgs,
): Promise<CreationResult> {
  const req = request.get(`${BASE_PATH}/creation-request/${creationId}`);
  const { body } = await req;
  return normalize(body, creationSchema);
}

export const handle = (method: any, args: any, token?: string): any => {
  const request = createRequest({
    token,
    baseUrl: spareminConfig.services.creation,
  });

  switch (method) {
    case ServiceMethod.CREATE:
      return create(request, args);

    case ServiceMethod.CREATION_GET:
      return getCreation(request, args);

    default:
      throw new Error(`${ACTION_KEY} cannot handle method ${method}`);
  }
};
