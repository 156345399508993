import classNames from 'classnames';
import * as React from 'react';
import _ from 'underscore';

import { ColorChangeField } from 'components/Form';
import CaptionsAnimationsCard, {
  RenderSettings,
} from './CaptionsAnimationsCard';

interface IProps {
  className?: string;
  disabled?: boolean;
  karaokeAnimation?: boolean;
  fromColor?: string;
  toColor?: string;
  onKaraokeAnimationChange?: (enabled: boolean) => void;
  onFromColorChange?: (color: string) => void;
  onToColorChange?: (color: string) => void;
}

export default class CaptionsAnimationsForm extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    disabled: false,
    fromColor: 'rbga(0.5, 0.5, 0.5, 1)',
    karaokeAnimation: false,
    onFromColorChange: _.noop,
    onKaraokeAnimationChange: _.noop,
    onToColorChange: _.noop,
    toColor: 'rgba(255, 0, 0, 1)',
  };

  private handleKaraokeAnimationChange = (val: boolean) => {
    const { onKaraokeAnimationChange } = this.props;
    onKaraokeAnimationChange(val);
  };

  private handleFromColorChange = (color: string) => {
    const { onFromColorChange } = this.props;
    onFromColorChange(color);
  };

  private handleToColorChange = (color: string) => {
    const { onToColorChange } = this.props;
    onToColorChange(color);
  };

  private renderKaraokeSettings: RenderSettings = (
    commonProps,
    controlClassName,
  ) => {
    const { fromColor, toColor } = this.props;

    return (
      <ColorChangeField
        {...commonProps}
        controlClassName={controlClassName('animations-card__karaoke-color')}
        label="Color Transitions"
        onFromColorChange={this.handleFromColorChange}
        onToColorChange={this.handleToColorChange}
        horizontal={{
          control: { xs: 6 },
          label: { xs: 6 },
        }}
        value={{ fromColor, toColor }}
      />
    );
  };

  public render() {
    const { className, disabled, karaokeAnimation } = this.props;

    const containerClassName = classNames({
      'animations-form': true,
      'animations-form--std': true,
      [className]: !!className,
    });

    return (
      <div className={containerClassName}>
        <CaptionsAnimationsCard
          disabled={disabled}
          animationEnabled={karaokeAnimation}
          title="Karaoke Style"
          previewImageUrl="https://static.sparemin.com/img/editor/animation-icons/karaoke.gif"
          renderSettings={this.renderKaraokeSettings}
          onAnimationEnabledChange={this.handleKaraokeAnimationChange}
        />
      </div>
    );
  }
}
