import React, { useContext } from 'react';
import { noop } from 'underscore';

import SelectContext from './SelectContext';
import SelectMenuContainer from './SelectMenuContainer';
import SelectOptions from './SelectOptions';
import { Option, Overrides, SelectMenuProps } from './types';

function SelectMenu<T extends Option>({
  onChange,
  options,
  value,
}: SelectMenuProps<T>) {
  const overrides = useContext<Overrides<T>>(SelectContext);

  const MenuOverride = overrides.menu?.component;

  if (MenuOverride) {
    return <MenuOverride {...{ onChange, options, value }} />;
  }

  return (
    <SelectMenuContainer>
      <SelectOptions {...{ onChange, options, value }} />
    </SelectMenuContainer>
  );
}

SelectMenu.defaultProps = {
  onChange: noop,
};

export default SelectMenu;
