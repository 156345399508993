import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'USER_SERVICE';
export const ACTION_KEY: ActionKey = 'USER_SERVICE';

export enum ServiceMethod {
  CREATE_PROFILE = 'CREATE_PROFILE',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  GET_PREFERENCES_FOR_USER = 'GET_PREFERENCES_FOR_USER',
  UPDATE_PREFERENCES_FOR_USER = 'UPDATE_PREFERENCES_FOR_USER',
  GET_PROFILE_BY_ID = 'GET_PROFILE_BY_ID',
  GET_MY_PROFILE = 'GET_MY_PROFILE',
  GET_INTEGRATORS_FOR_USER = 'GET_INTEGRATORS_FOR_USER',
  GET_ORGANIZATION_FOR_USER = 'GET_ORGANIZATION_FOR_USER',
  RENEW_TOKEN = 'RENEW_TOKEN',
  DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT',
}

export enum EmailVerificationStatus {
  UNVERIFIED = 'unverified',
  VERIFIED = 'verified',
  DENIED = 'denied',
}

interface EmailVerification {
  email?: string;
  emailVerificationStatus?: EmailVerificationStatus;
  userId?: number;
}

export interface IProfile {
  email?: string;
  maskedEmail?: string;
  firstName?: string;
  fullName?: string;
  imageUrl?: string;
  handle?: string;
  lastName?: string;
  userId: number;
  emailVerification?: EmailVerification;
}

interface INormalizedProfile {
  entities: {
    userProfiles: {
      [id: number]: IProfile;
    };
  };
  result: number;
}

interface IPreferences {
  videoExportEmailAlertEnabled: boolean;
  userId: number;
}

interface INormalizedPreferences {
  entities: {
    userPreferences: {
      [id: number]: IPreferences;
    };
  };
  result: number;
}

export interface Integrator {
  displayName: string;
  integratorId: number;
  integratorIdentityProviderId: number;
  integratorIdentityProviderName: string;
  name: string;
}

interface Organization {
  name: string;
  organizationCuid: string;
  organizationId: number;
}

export type CreateProfileArgs = [
  string, // email
  string, // full name
];
export type CreateProfileResult = IProfile;

export type UpdateProfileArgs = [
  string, // email
];
export type UpdateProfileResult = IProfile;

export type GetPreferencesArgs = undefined;
export type GetPreferencesResult = INormalizedPreferences;

export type UpdatePreferencesArgs = [boolean, boolean, boolean, boolean];
export type UpdatePrefencesResult = void;

export type GetProfileByIdArgs = [number];
export type GetProfileByIdResult = INormalizedProfile;

export type GetMyProfileArgs = undefined;
export type GetMyProfileResult = IProfile;

export type GetIntegratorsArgs = undefined;
export interface GetIntegratorsResult {
  integrators: Integrator[];
}

export type GetOrganizationArgs = undefined;
export type GetOrganizationResult = Organization;

export type DeleteUserAccountResult = undefined;

export type RenewTokenArgs = [string];

export type RenewTokenResult = {
  token: string;
};

export interface IHandle {
  (
    method: ServiceMethod.CREATE_PROFILE,
    args: CreateProfileArgs,
    token?: string,
    userId?: number,
  ): Promise<CreateProfileResult>;

  (
    method: ServiceMethod.UPDATE_PROFILE,
    args: UpdateProfileArgs,
    token?: string,
    userId?: number,
  ): Promise<UpdateProfileResult>;

  (
    method: ServiceMethod.GET_PREFERENCES_FOR_USER,
    args: GetPreferencesArgs,
    token?: string,
    userId?: number,
  ): Promise<GetPreferencesResult>;

  (
    method: ServiceMethod.UPDATE_PREFERENCES_FOR_USER,
    args: UpdatePreferencesArgs,
    token?: string,
    userId?: number,
  ): Promise<UpdatePrefencesResult>;

  (
    method: ServiceMethod.GET_PROFILE_BY_ID,
    args: GetProfileByIdArgs,
    token?: string,
    userId?: number,
  ): Promise<GetProfileByIdResult>;

  (
    method: ServiceMethod.GET_INTEGRATORS_FOR_USER,
    args: GetIntegratorsArgs,
    token?: string,
    userId?: number,
  ): Promise<GetIntegratorsResult>;

  (
    method: ServiceMethod.GET_ORGANIZATION_FOR_USER,
    args: GetOrganizationArgs,
    token?: string,
    userId?: number,
  ): Promise<GetOrganizationResult>;
}

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export type CreateProfileAction = ApiAction<
  ServiceMethod.CREATE_PROFILE,
  CreateProfileArgs
>;
export type UpdateProfileAction = ApiAction<
  ServiceMethod.UPDATE_PROFILE,
  UpdateProfileArgs
>;
export type GetPreferencesAction = ApiAction<
  ServiceMethod.GET_PREFERENCES_FOR_USER,
  GetPreferencesArgs
>;
export type UpdatePreferencesAction = ApiAction<
  ServiceMethod.UPDATE_PREFERENCES_FOR_USER,
  UpdatePreferencesArgs
>;
export type GetProfileByIdAction = ApiAction<
  ServiceMethod.GET_PROFILE_BY_ID,
  GetProfileByIdArgs
>;
export type GetMyProfileAction = ApiAction<
  ServiceMethod.GET_MY_PROFILE,
  GetMyProfileArgs
>;

export type GetIntegratorsAction = ApiAction<
  ServiceMethod.GET_INTEGRATORS_FOR_USER,
  GetIntegratorsArgs
>;
export type GetOrganizationAction = ApiAction<
  ServiceMethod.GET_ORGANIZATION_FOR_USER,
  GetOrganizationArgs
>;

export type DeleteUserAccountAction = ApiAction<
  ServiceMethod.DELETE_USER_ACCOUNT,
  DeleteUserAccountResult
>;

export type RenewTokenAction = ApiAction<
  ServiceMethod.RENEW_TOKEN,
  RenewTokenArgs
>;

export interface UserServiceDispatch {
  (action: CreateProfileAction): Promise<IApiResponse<CreateProfileResult>>;
  (action: UpdateProfileAction): Promise<IApiResponse<UpdateProfileResult>>;
  (action: GetPreferencesAction): Promise<IApiResponse<GetPreferencesResult>>;
  (action: UpdatePreferencesAction): Promise<
    IApiResponse<UpdatePrefencesResult>
  >;
  (action: GetProfileByIdAction): Promise<IApiResponse<GetProfileByIdResult>>;
  (action: GetMyProfileAction): Promise<IApiResponse<GetMyProfileResult>>;
  (action: GetIntegratorsAction): Promise<IApiResponse<GetIntegratorsResult>>;
  (action: GetOrganizationAction): Promise<IApiResponse<GetOrganizationResult>>;
  (action: GetPreferencesAction): Promise<IApiResponse<GetPreferencesResult>>;
  (action: DeleteUserAccountAction): Promise<
    IApiResponse<DeleteUserAccountResult>
  >;
  (action: RenewTokenAction): Promise<IApiResponse<RenewTokenResult>>;
}
