import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as auth from 'redux/modules/auth';
import { loadExperiments } from 'redux/modules/experiments/actions';
import * as mixpanelActions from 'redux/modules/mixpanel';
import { loadPricingData } from 'redux/modules/pricing';
import { loadSurveyData } from 'redux/modules/survey/actions';
import { State, ThunkAction } from 'redux/types';
import useModalOnLoad from './useModalOnLoad';

const onWebappLoad = (
  username: string,
): ThunkAction<Promise<void>> => async dispatch => {
  try {
    await Promise.all([
      dispatch(loadSurveyData()),
      // don't force ({ force: true }) because other comoponents (auth modules after
      // login) might already be querying for the data
      dispatch(loadPricingData()),
    ]);

    await dispatch(loadExperiments());

    const action = mixpanelActions.onWebappLoad(username);
    const enrichedAction = auth.setUserContext(action);
    return dispatch(enrichedAction);
  } catch (err) {
    return undefined;
  }
};

const useWebappLoad = () => {
  const dispatch = useDispatch();
  const username = useSelector<State, string>(auth.userNameSelector);
  const token = useSelector(auth.tokenSelector);
  const isTokenValid = useSelector(auth.userRegisteredSelector);
  const { onLoad } = useModalOnLoad();

  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    async function load() {
      if (token && !isTokenValid) {
        dispatch(auth.logout(false));
        return;
      }

      try {
        if (!userLoaded) {
          if (token) {
            await dispatch(auth.getMyProfile());
          }

          setUserLoaded(true);
        } else if (username) {
          await dispatch(onWebappLoad(username));
          onLoad();
        }
      } catch {
        dispatch(auth.logout());
      }
    }

    load();
  }, [dispatch, isTokenValid, onLoad, token, userLoaded, username]);

  return userLoaded;
};

export default useWebappLoad;
