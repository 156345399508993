import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import useAsyncCallback from 'hooks/useAsyncCallback';
import { getPodcastWorkflowTemplate } from 'redux/middleware/api/podcast-service/actions';
import {
  podcastWorkflowTemplatesSelector,
  projectTemplatesSelector,
} from 'redux/modules/entities';

export interface UsePodcastTemplateConfig {
  templateId: string;
}

const templateSelector = createSelector(
  projectTemplatesSelector,
  (_, templateId) => templateId,
  (templates, templateId) => templates?.get(templateId)?.toJS(),
);

export default function usePodcastTemplate({
  templateId,
}: UsePodcastTemplateConfig) {
  const dispatch = useDispatch();
  const [podcastTemplate, setPodcastTemplate] = useState<any>();
  const template = useSelector(state => templateSelector(state, templateId));

  const [fetchTemplate, { status: fetchTemplateStatus }] = useAsyncCallback(
    async () => {
      await dispatch(async (_, getState) => {
        await dispatch(
          getPodcastWorkflowTemplate(
            template.id,
            // project templates have type set to "headlinerDefault", but
            // the same podcast templates have type set to "headlinerDefaultOriginal"
            template.type === 'headlinerDefault'
              ? 'headlinerDefaultOriginal'
              : template.type,
          ),
        );

        const podcastTemplates = podcastWorkflowTemplatesSelector(getState());
        setPodcastTemplate(podcastTemplates?.get(templateId)?.toJS());
      });
    },
  );

  useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  return {
    podcastTemplate,
    fetchTemplateStatus,
    displayName: template.displayName,
  };
}
