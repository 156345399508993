import React from 'react';

import { H3 } from 'components/Heading';
import IconButton from 'components/IconButton';
import { Back } from 'components/icons';

import { editAutomationSelectHeaderBlock } from './utils';

interface EditAutomationSelectHeaderProps {
  onGoBack: () => void;
  title: string;
}

const EditAutomationSelectHeader: React.FunctionComponent<EditAutomationSelectHeaderProps> = props => {
  const { onGoBack, title } = props;

  return (
    <H3>
      <IconButton
        className={editAutomationSelectHeaderBlock('icon')}
        onButtonClick={onGoBack}
      >
        <Back height={16} width={16} />
      </IconButton>
      {title}
    </H3>
  );
};

export default EditAutomationSelectHeader;
