import React from 'react';

import Banner from 'components/Banner';
import Button from 'components/Button';
import { Microphone } from 'components/icons';
import Link from 'components/Link';

import { PLAY_APP_LINK } from 'utils/constants';
import { block } from './utils';

interface ClaimPodcastBannerProps {
  onClaimPodcast: () => void;
  onClickPlayAppLink: () => void;
}

const ClaimPodcastBanner: React.FunctionComponent<ClaimPodcastBannerProps> = props => {
  const { onClaimPodcast, onClickPlayAppLink } = props;

  return (
    <Banner
      icon={
        <Microphone
          className={block('icon')}
          height={45}
          width={30}
          viewBox="0 0 10 15"
        />
      }
      theme="primary"
    >
      <div>
        <Banner.Title>Want your show in our new listening app?</Banner.Title>
        <Banner.Content className={block('text')}>
          Become one of the first shows in{' '}
          <Link
            className={block('link')}
            onClick={onClickPlayAppLink}
            to={PLAY_APP_LINK}
            target="_blank"
          >
            our new podcast
          </Link>{' '}
          app so listeners can discover it!
        </Banner.Content>
      </div>
      <Banner.Actions>
        <Button onClick={onClaimPodcast} theme="submit">
          claim your podcast
        </Button>
      </Banner.Actions>
    </Banner>
  );
};

export default ClaimPodcastBanner;
