import { configure as configureMediaImport } from '@sparemin/media-import';
import * as React from 'react';
import { render } from 'react-dom';

// TODO test that fontawesome still works and if not, move importFontAwesome() back up here0
import importFontAwesomeIcons from 'components/FontAwesome/importFontAwesomeIcons';
import Root from 'components/Root';
import store from 'redux/store';
import polyfills from 'utils/polyfills';
import sentry from 'utils/sentry';

import 'css/main.less';

const environment = {
  development: 'dev',
  local: 'local',
  production: 'prod',
}[process.env.NODE_ENV];

importFontAwesomeIcons();

configureMediaImport({
  environment,
});

/**
 * NB: raven needs to listen to unhandled promise rejections for error logging.  this global
 *     rejection event is not available in a lot of browsers, so we polyfill promises with
 *     the bluebird library which provides this event.  make sure polyfill happens before raven is
 *     setup
 */
polyfills.install();
sentry.install();

render(<Root store={store} />, document.getElementById('root'));
