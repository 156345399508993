import * as React from 'react';
import uncontrollable from 'uncontrollable';
import { noop } from 'underscore';

import FontAwesome from 'components/FontAwesome';
import FontFamilyProIndicator from '../FontFamilyProIndicator';
import { FontSelectInput } from './FontSelectInput';
import { useFontSectionSelector } from './useFontSectionSelector';
import { block } from './utils';

export interface IFont {
  name: string;
  family: string;
}

interface IProps {
  args?: any;
  disabled?: boolean;
  fonts?: IFont[];
  isMenuOpen?: boolean;
  onChange?: (fontName: string, args) => void;
  onToggleOpen?: (isOpen: boolean, args: any) => void;
  onControlClick?: (args: any) => void;
  value?: string;
}

export function FontFamilySelector(props: IProps) {
  const {
    disabled,
    isMenuOpen,
    value,
    fonts,
    args,
    onControlClick,
    onChange,
    onToggleOpen,
  } = props;
  const {
    userFonts,
    allFonts,
    newRecentFonts: recentFonts,
  } = useFontSectionSelector(fonts);

  function handleControlClick() {
    onControlClick(args);
    handleToggleOpen(!isMenuOpen);
  }

  function handleFontClick(name: string) {
    onChange(name, args);
    handleToggleOpen(false);
  }

  function handleToggleOpen(isOpen: boolean) {
    onToggleOpen(isOpen, args);
  }

  return (
    <div className={block('fonts-container')}>
      <FontSelectInput
        className={block('font-family')}
        disabled={disabled}
        dropdownClassName={block('font-list')}
        icon={<FontAwesome icon="font" />}
        iconContainerClassName={block('font-family-icon-container')}
        isOpen={isMenuOpen}
        onChange={handleFontClick}
        onControlClick={handleControlClick}
        onToggleOpen={handleToggleOpen}
        allFonts={allFonts}
        recentFonts={recentFonts}
        userFonts={userFonts}
        value={value}
        footer={<FontFamilyProIndicator />}
      />
    </div>
  );
}

FontFamilySelector.defaultProps = {
  disabled: false,
  fonts: [],
  onChange: noop,
  onControlClick: noop,
  onToggleOpen: noop,
} as Partial<IProps>;

export type FontFamilySelectorProps = IProps & {
  defaultIsMenuOpen?: boolean;
};

const UncontrollableFontFamilySelector: React.ComponentClass<FontFamilySelectorProps> = uncontrollable(
  FontFamilySelector,
  {
    isMenuOpen: 'onToggleOpen',
    value: 'onChange',
  },
);

export { IFont as Font, UncontrollableFontFamilySelector as default };
