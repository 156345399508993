import React, { useCallback } from 'react';

import { Crop } from 'components/icons';

import OptionTile from '../assets/OptionTile';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { useModalManager } from '../ModalManager';
import useMainMedia from '../useMainMedia';

export interface CropTileProps {
  className?: string;
}

const CropTile: React.FC<CropTileProps> = props => {
  const { className } = props;
  const dispatch = useTemplateDispatch();
  const { showModal } = useModalManager();
  const mainMedia = useMainMedia();

  const disabled = mainMedia.type !== 'image';

  const handleCrop = useCallback(() => {
    // however tile will be disabled, this ensures no action is dispatched
    // if asset type is not an image.
    if (mainMedia.type !== 'image') {
      return;
    }

    const { asset } = mainMedia;

    showModal('crop', {
      defaultMetadata: asset.metadata,
      fileType:
        typeof asset.originalSrc === 'string'
          ? undefined
          : asset.originalSrc?.type,
      imgSrc: asset.originalSrc || asset.sourceImage.url || asset.imageSrc,
      onSubmit: (src, metadata) => {
        dispatch({
          payload: { id: asset?.id, src, metadata },
          type: 'IMAGE_CROP',
        });
      },
    });
  }, [dispatch, mainMedia, showModal]);

  return (
    <OptionTile
      className={className}
      disabled={disabled}
      disabledTooltipText="Only available for still images"
      onClick={handleCrop}
      icon={<Crop style={{ height: 40, width: 'auto' }} />}
      label="crop"
    />
  );
};

export default CropTile;
