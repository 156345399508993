import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import useOnMount from 'hooks/useOnMount';
import usePromise from 'hooks/usePromise';
import { clearSeasons, loadSeasons } from 'redux/modules/automation-cms';
import {
  isLoadingSeasonsSelector,
  seasonsSelector,
  totalEpisodesSelector,
} from 'redux/modules/automation-cms/selectors';
import { Season } from 'redux/modules/automation-cms/types';
import { showNotification } from 'redux/modules/notification';
import { goToCreate } from 'redux/modules/router/actions';
import { Dispatch } from 'redux/types';
import { getNormalizedSeasonsList } from './utils';

export interface SeasonsSelectorConfig {
  podcastId: string;
  onTargetedSeasonsChange: (targetedSeasons: number[]) => void;
  onSeasonsLoaded?: (seasons: Season[]) => void;
}

const SeasonsSelector = ({
  podcastId,
  onTargetedSeasonsChange,
  onSeasonsLoaded,
}: SeasonsSelectorConfig) => {
  const { setPromise } = usePromise<void>();
  const dispatch = useDispatch<Dispatch>();
  const isLoading = useSelector(isLoadingSeasonsSelector);
  const seasons = useSelector(seasonsSelector);
  const totalEpisodes = useSelector(totalEpisodesSelector);

  const handleTargetedListChange = useCallback(
    (targetedSeasons: number[], season: number, isSelected: boolean): void => {
      if (isSelected) {
        onTargetedSeasonsChange([...targetedSeasons, season]);
      } else {
        onTargetedSeasonsChange(
          targetedSeasons.filter(s => !isEqual(s, season)),
        );
      }
    },
    [onTargetedSeasonsChange],
  );

  const handleAllEpisodesClick = useCallback(
    (isSelected: boolean): void => {
      onTargetedSeasonsChange(
        isSelected ? getNormalizedSeasonsList(seasons) : [],
      );
    },
    [onTargetedSeasonsChange, seasons],
  );

  const initializeSeasons = useCallback((): void => {
    setPromise(
      dispatch(loadSeasons(podcastId))
        .then(data => {
          return onSeasonsLoaded?.(data);
        })
        .catch(() => {
          dispatch(
            showNotification({
              message: 'Please try again or {{link}} so we can help',
              code: 'ER013',
              dismissAfterSec: 5,
              level: 'error',
              type: 'help',
              title: 'Error loading seasons',
              actionLabel: 'contact us',
            }),
          );
          dispatch(goToCreate());
        }),
    );
  }, [dispatch, onSeasonsLoaded, podcastId, setPromise]);

  useOnMount(
    initializeSeasons,
    useCallback(() => {
      dispatch(clearSeasons());
    }, [dispatch]),
  );

  return {
    isLoading,
    seasons,
    totalEpisodes,
    onTargetedListChange: handleTargetedListChange,
    onAllEpisodesClick: handleAllEpisodesClick,
  };
};

export default SeasonsSelector;
