import * as React from 'react';

import TimelineContext from 'blocks/Timeline/TimelineContext';
import { TrackLabelDragItem } from '../../types';
import { block, canMoveTrack } from '../../utils';

const { useContext, useMemo } = React;

export interface TrackLabelDropIndicatorProps {
  active?: boolean;
  dragItem: TrackLabelDragItem | undefined;
  invalid?: boolean;
  // the drop indicator is placed between 2 track labels.  this is the index of
  // the "next" track.  so if this indicator is between tracks 1 and 2,
  // nextTrackIndex is 2
  nextTrackIndex: number;
  show?: boolean;
  style?: React.CSSProperties;
}

const TrackLabelDropIndicator: React.FC<TrackLabelDropIndicatorProps> = ({
  active = false,
  dragItem,
  nextTrackIndex,
  style,
}) => {
  const { tracks } = useContext(TimelineContext);

  const isValid = useMemo(() => {
    return canMoveTrack(tracks, nextTrackIndex, dragItem.index);
  }, [nextTrackIndex, tracks, dragItem.index]);

  return (
    <span
      className={block('track-label-drop-indicator', {
        active,
        invalid: !isValid,
      })}
      style={style}
    />
  );
};

export default TrackLabelDropIndicator;
