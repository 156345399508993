import { RequestStatus } from 'types';
import { PostStatus } from '../constants';
import { Type } from './action-types';
import { LinkedinAction, LinkedinState, linkedinStateFactory } from './types';

const defaultState = linkedinStateFactory();

export default function(
  state: LinkedinState = defaultState,
  action: LinkedinAction,
) {
  switch (action.type) {
    case Type.LINKEDIN_USER_DATA_GET_SUCCESS: {
      const { profileImageUrl, username } = action.payload;

      return state
        .set('username', username)
        .set('profileImageUrl', profileImageUrl);
    }

    case Type.LINKEDIN_USER_DATA_CLEAR:
      return linkedinStateFactory();

    case Type.LINKEDIN_ORGANIZATIONS_GET_REQUEST:
      return state
        .setIn(['organizations', 'data'], [])
        .setIn(['organizations', 'postStatus'], RequestStatus.REQUEST);

    case Type.LINKEDIN_ORGANIZATIONS_GET_FAILURE:
      return state
        .setIn(['organizations', 'data'], [])
        .setIn(['organizations', 'postStatus'], RequestStatus.FAILURE);

    case Type.LINKEDIN_ORGANIZATIONS_GET_SUCCESS:
      return state
        .setIn(['organizations', 'data'], action.payload.data)
        .setIn(['organizations', 'postStatus'], RequestStatus.SUCCESS);

    case Type.LINKEDIN_VIDEO_POST_REQUEST:
      return state.set('postStatus', PostStatus.IN_PROGRESS);

    case Type.LINKEDIN_VIDEO_POST_SUCCESS: {
      const { socialShareId } = action.payload;
      return state.set('socialShareId', socialShareId);
    }

    case Type.LINKEDIN_VIDEO_POST_FAILURE:
    case Type.LINKEDIN_VIDEO_POST_AWAIT_FAILURE:
      return state.set('postStatus', PostStatus.FAILURE);

    case Type.LINKEDIN_VIDEO_POST_AWAIT_SUCCESS:
      return state.set('postStatus', PostStatus.SUCCESS);

    case Type.LINKEDIN_AUTHORIZE_SUCCESS: {
      return state.merge(action.payload);
    }

    default:
      return state;
  }
}
