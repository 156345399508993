import * as React from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';

import { useNavigation } from './context/NavigationContext';
import { useOptionTiles } from './OptionTilesContext';
import {
  CHILD_VIEW_ZOOM_DELAY_MS,
  CHILD_VIEW_ZOOM_DURATION_MS,
} from './state/constants';

const { useCallback, useEffect, useState } = React;

export interface ChildViewZoomEffectProps {}

const DEFAULT_STYLE: Partial<React.CSSProperties> = {
  backgroundColor: '#323746',
  position: 'absolute',
  transition: `all ${CHILD_VIEW_ZOOM_DURATION_MS}ms ease-in-out`,
  zIndex: 1,
};

const OPEN_STYLE: Partial<React.CSSProperties> = {
  height: '100%',
  left: 0,
  top: 0,
  width: '100%',
  zIndex: 1,
};

const TRANSITION_STYLES: Record<
  TransitionStatus,
  Partial<React.CSSProperties>
> = {
  entering: OPEN_STYLE,
  entered: OPEN_STYLE,
  exiting: {
    transitionDelay: `${CHILD_VIEW_ZOOM_DELAY_MS}ms`,
  },
  exited: undefined,
  unmounted: undefined,
};

/**
 * the child view zoom effect has 2 phases
 *  1. position a hidden overlay at the inital position
 *  2. zoom in to fill the whole frame
 *
 * react-transition-group doesn't provide a state that allows you to setup
 * for a transition, so if we try to sequentially do steps 1 and 2, the renders
 * will get collapsed and the animation won't happen because the first render
 * included the animations final position.  using the state machine allows us to
 * extend the current behavior with the prep state we need
 */
const ChildViewZoomEffect: React.FC<ChildViewZoomEffectProps> = () => {
  const [navState, , navService] = useNavigation();
  const [initialized, setInitialized] = useState(false);
  const [initialPosition, setInitialPosition] = useState(null);

  useEffect(() => {
    setInitialized(current => {
      if (initialPosition && !current) {
        return true;
      }
      return current;
    });
  }, [initialPosition]);

  const { getTile } = useOptionTiles();

  useEffect(() => {
    const sub = navService.subscribe(state => {
      if (state.changed && state.matches('child')) {
        const viewName = (state.value as any)?.child;
        const element = getTile(viewName);
        setInitialPosition({
          height: element.offsetHeight,
          left: element.offsetLeft,
          top: element.offsetTop,
          width: element.offsetWidth,
        });
      }
    });

    return () => {
      sub?.unsubscribe();
    };
  }, [getTile, navService]);

  const handleExited = useCallback(() => {
    setInitialPosition(null);
    setInitialized(false);
  }, []);

  return (
    <Transition
      in={navState.matches('child') && initialized}
      onExited={handleExited}
      timeout={{
        enter: CHILD_VIEW_ZOOM_DURATION_MS,
        exit: CHILD_VIEW_ZOOM_DURATION_MS + CHILD_VIEW_ZOOM_DELAY_MS,
      }}
    >
      {transitionState => (
        <div
          style={{
            ...DEFAULT_STYLE,
            ...initialPosition,
            ...TRANSITION_STYLES[transitionState],
          }}
        />
      )}
    </Transition>
  );
};

export default ChildViewZoomEffect;
