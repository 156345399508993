import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { ColorField, FormField, FormFieldProps } from 'components/Form';
import { SoundwaveOption, SoundwaveType } from 'types';
import bem from 'utils/bem';
import { getValue } from 'utils/collections';
import WaveTypeField from './WaveTypeField';

const { useCallback } = React;

export interface WaveStyleValue {
  color: string;
  type: SoundwaveType;
}

interface Props {
  defaultValue?: WaveStyleValue;
  generatingWaveform?: boolean;
  lockColor?: boolean;
  lockType?: boolean;
  onChange?: (value: WaveStyleValue) => void;
  soundwaveOptions?: SoundwaveOption[];
  value: WaveStyleValue;
}

export type WaveStyleFieldProps = FormFieldProps<Props>;

const block = bem('wave-style-field');

const WaveStyleField: React.SFC<WaveStyleFieldProps> = ({
  className,
  defaultValue,
  disabled,
  generatingWaveform,
  lockColor,
  lockType,
  onChange,
  soundwaveOptions,
  value,
}) => {
  const handleTypeChange = useCallback(
    (type: SoundwaveType) => {
      onChange({
        type,
        color: value.color,
      });
    },
    [onChange, value],
  );

  const handleColorChange = useCallback(
    (color: string) => {
      onChange({
        color,
        type: value.type,
      });
    },
    [onChange, value],
  );

  return (
    <FormField
      className={cn(block(), className)}
      horizontal={{ control: { xs: 7 }, label: { xs: 5 } }}
      label="Wave Style"
    >
      <div className={block('fields')}>
        <WaveTypeField
          className={block('type-field', { locked: lockType })}
          defaultValue={getValue(defaultValue, 'type')}
          disabled={disabled}
          generatingWaveform={generatingWaveform}
          locked={lockType}
          lockedWrapperClassName={block('type-locked-wrapper')}
          onChange={handleTypeChange}
          options={soundwaveOptions}
          value={value.type}
        />
        <ColorField
          className={block('color-field', { locked: lockColor })}
          disabled={
            disabled || value.type === 'none' || value.type === 'equalizer'
          }
          locked={lockColor}
          lockedWrapperClassName={block('color-locked-wrapper')}
          onChange={handleColorChange}
          value={getValue(defaultValue, 'color', value.color)}
        />
      </div>
    </FormField>
  );
};

WaveStyleField.defaultProps = {
  onChange: noop,
};

export default WaveStyleField;
