import { Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { SubscriptionItem } from 'redux/middleware/api/podcast-service/types';
import { podcastSubscriptionsSelector } from 'redux/modules/entities';
import { State } from 'redux/types';
import { VideoTypes } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import AutomationWorkflowTile, {
  AutomationWorkflowTileProps as Props,
} from './AutomationWorkflowTile';

type DispatchProps = Pick<Props, 'onDeleteClick' | 'onToggleClick'>;
type StateProps = Pick<
  Props,
  | 'aspectRatioName'
  | 'platform'
  | 'frequency'
  | 'durationSec'
  | 'id'
  | 'isAuthError'
  | 'enabled'
  | 'latestAutogramUrl'
  | 'clipSelectionMethod'
>;
interface OwnProps extends Pick<Props, 'id'> {
  onClickDeleteTemplate: (id: number) => void;
  onClickToggleTemplate?: (
    id: number,
    enabled: boolean,
    hasAuthError: boolean,
  ) => void;
}

const workflowIdSelector = (_, props: OwnProps) => props.id;

const podcastSubscriptionSelector = createSelector(
  [podcastSubscriptionsSelector, workflowIdSelector],
  (subs, id) => {
    if (!subs || !id) return undefined;
    return subs.get(`${id}`);
  },
);

const descriptionSelector = createSelector(
  podcastSubscriptionSelector,
  (sub): Pick<Props, 'frequency' | 'durationSec' | 'clipSelectionMethod'> => {
    if (!sub) return undefined;

    const subscriptionItems: SubscriptionItem[] = sub
      .get('subscriptionItems')
      .toJS();

    switch (subscriptionItems.length) {
      default:
      case 0:
        // Shouldn't be possible for the user to select nothing
        return {};

      // Full legnth videos or once per episode
      case 1: {
        const item: SubscriptionItem = subscriptionItems[0];

        if (item.videoType === VideoTypes.FULL_EPISODE) {
          return { durationSec: 'full-length' };
        }

        return {
          durationSec: item.videoLengthSecPref,
          clipSelectionMethod: item.clipSelectionMethod,
        };
      }

      // Daily / Weekly [onPublish created automatically] or full episode and once per episode
      case 2: {
        const item: SubscriptionItem = subscriptionItems[0];
        const fullEpisodeItem: SubscriptionItem = subscriptionItems.find(
          (subscriptionItem: SubscriptionItem) =>
            subscriptionItem.videoType === VideoTypes.FULL_EPISODE,
        );
        const oncePerEpisodeItem: SubscriptionItem = subscriptionItems.find(
          (subscriptionItem: SubscriptionItem) =>
            subscriptionItem.videoType === VideoTypes.RANDOM_CLIP &&
            subscriptionItem.videoFrequencyPref === 'onPublish',
        );
        if (fullEpisodeItem && oncePerEpisodeItem) {
          return {
            frequency: 'Once per episode & full-length',
            durationSec: oncePerEpisodeItem.videoLengthSecPref,
            clipSelectionMethod: item.clipSelectionMethod,
          };
        }
        return {
          frequency: item.videoFrequencyPref,
          durationSec: item.videoLengthSecPref,
          clipSelectionMethod: item.clipSelectionMethod,
        };
      }

      // Daily/Weekly & Full Episodes [onPublish is created automatically]
      case 3: {
        const itemRandom: SubscriptionItem = subscriptionItems.find(
          (subscriptionItem: SubscriptionItem) =>
            subscriptionItem.videoType === VideoTypes.RANDOM_CLIP &&
            subscriptionItem.videoFrequencyPref !== 'onPublish',
        );
        return {
          frequency: `${itemRandom.videoFrequencyPref} & full-length`,
          durationSec: itemRandom.videoLengthSecPref,
          clipSelectionMethod: itemRandom.clipSelectionMethod,
        };
      }
    }
  },
);

const aspectRatioNameSelector = createSelector(
  podcastSubscriptionSelector,
  sub => {
    if (!sub) return undefined;

    return getAspectRatioName(
      sub.getIn(['dimension', 'height']),
      sub.getIn(['dimension', 'width']),
    );
  },
);

const platformSelector = createSelector(podcastSubscriptionSelector, sub => {
  if (!sub) return undefined;

  const subscriptionItems: SubscriptionItem[] = sub
    .get('subscriptionItems')
    .toJS();

  if (!subscriptionItems) return undefined;

  return subscriptionItems[0]?.autoPostVideoPreference?.platform;
});

const latestAutogramUrlSelector = createSelector(
  podcastSubscriptionSelector,
  (sub = Map()) => {
    const wid = sub.getIn(['latestSuccessfulAutoEpisode', 'video', 'widgetId']);
    if (!wid) return undefined;

    return `/download/${wid}`;
  },
);

const makeMapDispatchToProps = () => (_, props: OwnProps): DispatchProps => {
  const id = workflowIdSelector(null, props);

  return {
    onDeleteClick: () => props.onClickDeleteTemplate(id),
    onToggleClick: props.onClickToggleTemplate
      ? (enabled, hasAuthError) =>
          props.onClickToggleTemplate(id, enabled, hasAuthError)
      : null,
  };
};

const makeMapStateToProps = () => (
  state: State,
  props: OwnProps,
): StateProps => {
  const subscription = podcastSubscriptionSelector(state, props);
  return {
    ...descriptionSelector(state, props),
    aspectRatioName: aspectRatioNameSelector(state, props),
    platform: platformSelector(state, props),
    id: workflowIdSelector(state, props),
    isAuthError: subscription?.get('isAuthError'),
    latestAutogramUrl: latestAutogramUrlSelector(state, props),
    enabled: subscription?.get('isEnabled'),
  };
};

const component = connect(
  makeMapStateToProps,
  makeMapDispatchToProps,
)(AutomationWorkflowTile);
export type AutomationWorkflowTileProps = React.ComponentPropsWithoutRef<
  typeof component
>;
export default component;
