import React from 'react';
import { useDispatch } from 'react-redux';
import WarningModalContents from 'containers/WarningModal/WarningModalContents';
import { hideModal } from 'redux/modules/modal/actions';
import { block } from './utils';

function AdjustmentNeededModalContent() {
  const dispatch = useDispatch();

  return (
    <WarningModalContents
      title="Adjustment needed"
      className={block('content')}
      description={
        <>
          For full-length projects we currently only support videos and GIFs as
          the top or bottom layer. Please adjust your layout and try again.
        </>
      }
      actions={[
        {
          theme: 'submit',
          onClick: () => {
            dispatch(hideModal(false));
          },
          children: 'Return to editing',
          id: 'return',
        },
      ]}
    />
  );
}

export default AdjustmentNeededModalContent;
