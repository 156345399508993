import * as React from 'react';

import { IconProps } from 'components/icons/types';

/* tslint:disable-next-line:variable-name */
const AspectRatios: React.SFC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 112 30"
    {...props}
  >
    <defs>
      <rect id="aspect-ratio-a" width="50" height="28" rx="2" />
      <path id="aspect-ratio-c" d="M0 29.596h53.13V0H0z" />
      <rect id="aspect-ratio-e" width="28" height="28" rx="2" />
      <path id="aspect-ratio-g" d="M0 29.814h29.814V0H0z" />
      <rect id="aspect-ratio-i" width="16" height="28" rx="2" />
      <path id="aspect-ratio-k" d="M0 30h16.875V0H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)">
        <mask id="aspect-ratio-b" fill="#fff">
          <use xlinkHref="#aspect-ratio-a" />
        </mask>
        <rect
          width="51"
          height="29"
          x="-.5"
          y="-.5"
          stroke="#646E82"
          strokeOpacity=".5"
          rx="2"
        />
        <g mask="url(#aspect-ratio-b)">
          <g transform="translate(-2 -1)">
            <mask id="aspect-ratio-d" fill="#fff">
              <use xlinkHref="#aspect-ratio-c" />
            </mask>
            <use fill="#14191E" xlinkHref="#aspect-ratio-c" />
            <path
              fill="#646E82"
              d="M9.643 6.964a2.679 2.679 0 1 0-5.357 0 2.679 2.679 0 0 0 5.357 0"
              mask="url(#aspect-ratio-d)"
            />
            <path
              fill="#282E38"
              d="M53.277 29.817v-3.884L37.032 9.793 16.88 29.816z"
              mask="url(#aspect-ratio-d)"
            />
            <path
              fill="#282E38"
              d="M20.418 14.45L4.953 29.816h30.931z"
              mask="url(#aspect-ratio-d)"
            />
          </g>
        </g>
      </g>
      <g transform="translate(59 1)">
        <mask id="aspect-ratio-f" fill="#fff">
          <use xlinkHref="#aspect-ratio-e" />
        </mask>
        <rect
          width="29"
          height="29"
          x="-.5"
          y="-.5"
          stroke="#646E82"
          strokeOpacity=".5"
          rx="2"
        />
        <g mask="url(#aspect-ratio-f)">
          <g transform="translate(-1 -1)">
            <mask id="aspect-ratio-h" fill="#fff">
              <use xlinkHref="#aspect-ratio-g" />
            </mask>
            <use fill="#14191E" xlinkHref="#aspect-ratio-g" />
            <path
              fill="#646E82"
              d="M9.643 6.964a2.678 2.678 0 1 0-5.357 0 2.678 2.678 0 0 0 5.357 0"
              mask="url(#aspect-ratio-h)"
            />
            <path
              fill="#282E38"
              d="M30.15 18.12l-4.73-5.119L9.575 30.15H30.15z"
              mask="url(#aspect-ratio-h)"
            />
            <path
              fill="#282E38"
              d="M12.358 16.99L.198 30.149h24.32z"
              mask="url(#aspect-ratio-h)"
            />
          </g>
        </g>
      </g>
      <g transform="translate(95 1)">
        <mask id="aspect-ratio-j" fill="#fff">
          <use xlinkHref="#aspect-ratio-i" />
        </mask>
        <rect
          width="17"
          height="29"
          x="-.5"
          y="-.5"
          stroke="#646E82"
          strokeOpacity=".5"
          rx="2"
        />
        <g mask="url(#aspect-ratio-j)">
          <g transform="translate(0 -1)">
            <mask id="aspect-ratio-l" fill="#fff">
              <use xlinkHref="#aspect-ratio-k" />
            </mask>
            <use fill="#14191E" xlinkHref="#aspect-ratio-k" />
            <path
              fill="#282E38"
              d="M6.236 15.563L-6 30.084h24.471z"
              mask="url(#aspect-ratio-l)"
            />
            <path
              fill="#646E82"
              d="M7.5 6.964a2.678 2.678 0 1 0-5.357 0 2.678 2.678 0 0 0 5.357 0"
              mask="url(#aspect-ratio-l)"
            />
            <path
              fill="#282E38"
              d="M17.292 29.866V13.875L3.75 29.948"
              mask="url(#aspect-ratio-l)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AspectRatios;
