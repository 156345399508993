import cn from 'classnames';
import React from 'react';
import CirclePlayButton, {
  CirclePlayButtonProps,
} from 'components/CirclePlayButton';

import AudioClipCardText from './AudioClipCardText';
import ClipTag, { ClipTagProps } from './ClipTag';
import ClipTimecodes, { ClipTimecodesProps } from './ClipTimecodes';
import { block } from './utils';

type PickedPlayButtonProps = Pick<
  CirclePlayButtonProps,
  'progress' | 'playing'
>;

export interface AudioClipCardProps
  extends ClipTagProps,
    ClipTimecodesProps,
    PickedPlayButtonProps {
  className?: string;
  onClickControlButton?: () => void;
  playable?: boolean;
  text: string;
}

const AudioClipCard: React.FC<AudioClipCardProps> = ({
  className,
  clipEndMillis,
  clipIndex,
  clipStartMillis,
  onClickControlButton,
  playable,
  playing,
  progress,
  text,
  totalClips,
}) => (
  <div className={cn(block({ playable }), className)}>
    <div className={block('head')}>
      <div className={block('playback')}>
        <CirclePlayButton
          onClick={onClickControlButton}
          {...{ playing, progress }}
        />
      </div>
      <div className={block('metadata')}>
        <ClipTag {...{ clipIndex, totalClips }} />
        <ClipTimecodes {...{ clipStartMillis, clipEndMillis }} />
      </div>
    </div>
    <AudioClipCardText text={text} />
  </div>
);

export default AudioClipCard;
