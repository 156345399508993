import cn from 'classnames';
import React from 'react';
import Button, { ButtonProps } from 'components/Button';
import { block } from './utils';

export type SocialButtonSize = 'large' | 'small';

export interface SocialButtonProps
  extends Pick<ButtonProps, 'disabled' | 'onClick' | 'params'> {
  className?: string;
  icon: React.ReactNode;
  label: string;
  size?: SocialButtonSize;
}

const SocialButton: React.FC<SocialButtonProps> = ({
  className,
  disabled,
  onClick,
  icon,
  label,
  params,
  size,
}) => (
  <Button
    className={cn(block({ [size]: !!size }), className)}
    {...{ disabled, onClick, params }}
  >
    <div className={block('icon')}>{icon}</div>
    <span className={block('label')}>{label}</span>
  </Button>
);

export default SocialButton;
