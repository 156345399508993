import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noop } from 'underscore';

import SteppedModal from 'components/SteppedModal';
import { UseComposerStepValue } from 'containers/YoutubePostModal/steps/types';
import useCreatePlaylistStep from 'containers/YoutubePostModal/steps/useCreatePlaylistStep';
import useManualPostComposerStep from 'containers/YoutubePostModal/steps/useManualPostComposerStep';
import useYoutubeAccountVerification from 'containers/YoutubePostModal/useYoutubeAccountVerification';
import usePostModalDescription from 'hooks/usePostModalDescription';
import usePrevious from 'hooks/usePrevious';
import {
  defaultPostTextSelector,
  projectPodcastNameSelector,
} from 'redux/modules/download';
import { YouTubeAccountVerificationCheckSource } from 'redux/modules/mixpanel/types';
import { showError } from 'redux/modules/notification';
import {
  getYoutubeCategories,
  getYoutubeLanguages,
  getYoutubePlaylists,
} from 'redux/modules/social';
import { Dispatch } from 'redux/types';

import YoutubePostModal from '../YoutubePostModal';
import useAccessDeniedStep from '../YoutubePostModal/steps/useAccessDeniedStep';
import useSwitchAccountStep from '../YoutubePostModal/steps/useSwitchAccountStep';
import { YoutubePostModalProps } from '../YoutubePostModal/types';

export interface YoutubePostVideoModalProps
  extends Pick<YoutubePostModalProps, 'onHide' | 'show'> {
  error?: string;
  onSubmit?: (vals: UseComposerStepValue) => void;
  source: YouTubeAccountVerificationCheckSource;
  onUnlockAICaptions?: () => void;
  onCancelUnlockAICaptions?: () => void;
}

const YoutubePostVideoModal: React.FC<YoutubePostVideoModalProps> = ({
  error,
  onHide,
  onSubmit = noop,
  show,
  source,
  onUnlockAICaptions,
  onCancelUnlockAICaptions,
}) => {
  const [activeStep, setActiveStep] = useState('');

  const modalRef = useRef<SteppedModal>(null);
  const prevShow = usePrevious(show);
  const dispatch = useDispatch<Dispatch>();
  const podcastName = useSelector(projectPodcastNameSelector);

  const handlePostSuccess = useCallback(() => {
    if (show) {
      onHide();
    }
  }, [onHide, show]);

  const handleSwitchAccountClick = useCallback(
    () => modalRef.current.stepHistory.replace('switch'),
    [],
  );

  const handleError = useCallback(err => {
    if (err === 'access_denied') {
      modalRef.current.stepHistory.replace('access-denied');
    }
  }, []);

  const loadCategories = useCallback(() => {
    dispatch(getYoutubeCategories()).catch(() => {
      dispatch(
        showError({
          message: 'Unable to load categories due to network error',
        }),
      );
    });
  }, [dispatch]);

  const loadLanguages = useCallback(() => {
    dispatch(getYoutubeLanguages()).catch(() => {
      dispatch(
        showError({ message: 'Unable to load languages due to network error' }),
      );
    });
  }, [dispatch]);

  const handleAccountVerificationSuccess = useCallback(() => {
    loadCategories();
    loadLanguages();
    dispatch(getYoutubePlaylists());
    modalRef.current.stepHistory.replace('composer');
  }, [loadCategories, loadLanguages, dispatch]);

  const handleClickAdditionalDetails = useCallback(() => {
    modalRef.current.stepHistory.replace('additional-details-step');
  }, []);

  const handleClickInitialStep = useCallback(() => {
    modalRef.current.stepHistory.replace('composer');
  }, []);

  const {
    onAccountVerification,
    verificationSteps,
  } = useYoutubeAccountVerification({
    modalRef,
    // As this modal has will load an already signed in account, the user can end up in an
    // endless loop (manual) if verification fails for any reason. This can happen easily
    // if the user logs without accepting the YT rights at the sign in page. In order to avoid
    // this if the verification request fails, the user will be prompted to switch the account.
    onAccountVerificationFailure: handleSwitchAccountClick,
    onAccountVerificationSuccess: handleAccountVerificationSuccess,
    onHideModal: onHide,
    onSwitchAccount: handleSwitchAccountClick,
    source,
  });

  const handleSwitchAccountSuccess = useCallback(() => {
    onAccountVerification();
  }, [onAccountVerification]);

  useEffect(() => {
    if (show && !prevShow) {
      onAccountVerification();
    }

    if (!prevShow && show && error) handleError(error);
  }, [dispatch, error, handleError, onAccountVerification, prevShow, show]);

  const defaultDescription = useSelector(defaultPostTextSelector);

  const {
    description,
    setDescription,
    resetDescription,
  } = usePostModalDescription({
    platform: 'youtube',
    defaultDescription,
  });

  const { composerSteps } = useManualPostComposerStep({
    defaultDescription,
    description,
    setDescription,
    resetDescription,
    onUnlockAICaptions,
    onCancelUnlockAICaptions,
    activeStep,
    onAdditionalStepClick: handleClickAdditionalDetails,
    onCreatePlaylistClick: () => modalRef.current.stepHistory.push('playlist'),
    onInitialStepClick: handleClickInitialStep,
    onPostSuccess: handlePostSuccess,
    onSubmit,
    onSwitchAccountClick: handleSwitchAccountClick,
    initialValues: {
      categoryId: undefined,
      language: undefined,
      madeForKids: false,
      playlistsIds: [],
      visibility: 'public',
    },
  });

  const accessDeniedStep = useAccessDeniedStep({
    onError: handleError,
    onReviseAccess: handleSwitchAccountSuccess,
  });

  const switchAccountStep = useSwitchAccountStep({
    onError: handleError,
    onSwitchAccountSuccess: handleSwitchAccountSuccess,
  });

  const playlistStep = useCreatePlaylistStep({
    defaultPlaylistName: podcastName,
    onSuccess: () => modalRef.current?.stepHistory.pop(),
  });

  return (
    <YoutubePostModal
      defaultStep="verification-in-progress"
      onHide={onHide}
      onStepChange={setActiveStep}
      ref={modalRef}
      show={show}
      steps={[
        ...verificationSteps,
        ...composerSteps,
        switchAccountStep,
        accessDeniedStep,
        playlistStep,
      ]}
      title="upload your video"
    />
  );
};

export default YoutubePostVideoModal;
