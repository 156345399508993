import * as React from 'react';
import { noop } from 'underscore';

import { TemplateDispatch } from '../types';

const { useContext } = React;

interface VideoTemplateDispatchProviderProps {
  children?: React.ReactNode;
  value: TemplateDispatch;
}

const DispatchContext = React.createContext<TemplateDispatch>(noop);

export function useTemplateDispatch() {
  return useContext(DispatchContext);
}

export const VideoTemplateDispatchProvider: React.FC<VideoTemplateDispatchProviderProps> = ({
  children,
  value,
}) => (
  <DispatchContext.Provider value={value}>{children}</DispatchContext.Provider>
);
