import { Heading, Spacer } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import BetaBadge from 'components/BetaBadge';
import ConfirmationModal from 'components/ConfirmationModal';
import { VideoCameraSpeechBubble } from 'components/icons';
import Modal from 'components/Modal';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import useContextualUpgrade from 'hooks/useContextualUpgrade';
import { onUnlockThisFeature } from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { block } from './utils';

export interface AnimateImagesUpsellModalContentsProps {}

const AnimateImagesUpsellModalContents: React.FC<AnimateImagesUpsellModalContentsProps> = () => {
  const { onHide } = useConnectedModal('AnimateImagesUpsell');
  const { onUpgrade } = useContextualUpgrade({
    openModal: replaceModal,
  });
  const dispatch = useDispatch<Dispatch>();

  const handleUnlockThisFeature = useCallback((): void => {
    onHide(true);
    dispatch(onUnlockThisFeature('AnimateImages'));
    onUpgrade();
  }, [dispatch, onUpgrade, onHide]);

  return (
    <>
      <ConfirmationModal.Body>
        <ConfirmationModal.Icon>
          <VideoCameraSpeechBubble className={block('ai-generated-icon')} />
        </ConfirmationModal.Icon>

        <Spacer justify="center" align="center" space={1}>
          <ConfirmationModal.Title>Animate your images</ConfirmationModal.Title>
          <BetaBadge className={block('beta-icon')} />
        </Spacer>

        <Heading level={2} align="center">
          Bring your podcast to life by transforming your images into engaging
          videos that loop for the duration of your audio.
        </Heading>
      </ConfirmationModal.Body>

      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton theme="rainbow" onClick={handleUnlockThisFeature}>
            unlock this feature
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default AnimateImagesUpsellModalContents;
