import React from 'react';

import { useTextOverlay } from '../../state/TextOverlayProvider';

import { block } from '../../utils';

const AnimationsTab: React.FunctionComponent = () => {
  const { animationsTabExtension } = useTextOverlay();

  const { animationModules } = animationsTabExtension;

  return (
    <div className={block('animations-tab-content')}>
      {animationModules.map(animationModule => (
        <animationModule.control key={animationModule.key} />
      ))}
    </div>
  );
};

export default AnimationsTab;
