import * as React from 'react';

import Modal, { ModalProps } from 'components/Modal';
import useProject from 'hooks/useProject';

const { useCallback } = React;

export interface ProjectDeleteModalProps extends Pick<ModalProps, 'onHide'> {
  disabled?: boolean;
  onSubmit?: (projectId: string) => void;
  projectId?: string;
}

const ProjectDeleteModal: React.SFC<ProjectDeleteModalProps> = ({
  disabled,
  onHide,
  onSubmit,
  projectId,
}) => {
  const project = useProject(projectId);
  const projectName = project?.name;

  const handleSubmit = useCallback(() => {
    onSubmit(projectId);
    onHide();
  }, [onHide, onSubmit, projectId]);

  return (
    <>
      <Modal.Body>
        <div className="project-delete-modal__body-text">
          <p>
            Are you sure you want to delete{' '}
            {projectName ? `the project "${projectName}"` : 'this project'}?
          </p>
          <p>
            <strong>This cannot be undone.</strong>
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Modal.FooterButtons>
          <Modal.FooterButton onClick={onHide} disabled={disabled}>
            Cancel
          </Modal.FooterButton>
          <Modal.FooterButton
            onClick={handleSubmit}
            disabled={disabled}
            theme="delete"
          >
            Delete
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default ProjectDeleteModal;
