import React from 'react';

import { useSelector } from 'react-redux';
import ModalFooterButton from 'components/Modal/ModalFooterButton';
import { Step } from 'components/SteppedModal';
import { isPollingSocialPostCaptionsSelector } from 'redux/modules/download';
import {
  YoutubeManualPostComposer,
  YoutubeManualPostComposerProps,
} from '../../YoutubeManualPostComposer';
import { UsePostComposerBaseConfig } from '../types';

type PickedYoutubeManualPostComposerProps = Pick<
  YoutubeManualPostComposerProps,
  | 'avatarSrc'
  | 'description'
  | 'isMetadataEnabled'
  | 'playlists'
  | 'onDescriptionChange'
  | 'onPlaylistChange'
  | 'onTitleChange'
  | 'readonly'
  | 'selectedPlaylistsIds'
  | 'title'
  | 'username'
  | 'onUnlockAICaptions'
  | 'onCancelUnlockAICaptions'
>;

export interface UsePostComposerStepConfig
  extends UsePostComposerBaseConfig,
    PickedYoutubeManualPostComposerProps {
  onSubmit: () => void;
}

const usePostComposerStep = (config: UsePostComposerStepConfig): Step => {
  const {
    avatarSrc,
    description,
    isMetadataEnabled,
    isUploading,
    playlists,
    onAdditionalStepClick,
    onCreatePlaylistClick,
    onDescriptionChange,
    onPlaylistChange,
    onSubmit,
    onSwitchAccountClick,
    onTitleChange,
    readonly,
    selectedPlaylistsIds,
    submitButtonLabel = 'post your video',
    title,
    username,
    onUnlockAICaptions,
    onCancelUnlockAICaptions,
  } = config;

  const isPollingSocialPostCaptions = useSelector(
    isPollingSocialPostCaptionsSelector,
  );

  return {
    component: (
      <YoutubeManualPostComposer
        onUnlockAICaptions={onUnlockAICaptions}
        onCancelUnlockAICaptions={onCancelUnlockAICaptions}
        avatarSrc={avatarSrc}
        description={description}
        isMetadataEnabled={isMetadataEnabled}
        isUploading={isUploading}
        playlists={playlists}
        onAdditionalStepClick={onAdditionalStepClick}
        onCreatePlaylistClick={onCreatePlaylistClick}
        onDescriptionChange={onDescriptionChange}
        onPlaylistChange={onPlaylistChange}
        onSwitchAccountClick={onSwitchAccountClick}
        onTitleChange={onTitleChange}
        readonly={readonly}
        selectedPlaylistsIds={selectedPlaylistsIds}
        title={title}
        username={username}
      />
    ),
    id: 'composer',
    onSubmit,
    fluidFooterButtons: true,
    renderFooterButtons: ({ submit }) => [
      <ModalFooterButton
        {...submit}
        key="submit"
        theme="submit"
        disabled={isUploading || isPollingSocialPostCaptions}
      >
        {submitButtonLabel}
      </ModalFooterButton>,
    ],
  };
};

export default usePostComposerStep;
