import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { captionsConfigSelector } from 'redux/modules/embed/selectors';
import { State } from 'redux/types';
import { PropsOf } from 'types';
import TranscriptTab, {
  TranscriptTabProps as ImportedTranscriptTabProps,
} from './TranscriptTab';

type StateProps = Pick<ImportedTranscriptTabProps, 'captionsEnabled'>;

const captionsEnabledSelector = createSelector(
  captionsConfigSelector,
  config => config && config.get('enabled'),
);

const mapStateToProps = (state: State): StateProps => ({
  captionsEnabled: captionsEnabledSelector(state),
});

const component = connect(mapStateToProps, null)(TranscriptTab);
export type TranscriptTabProps = PropsOf<typeof component>;
export default component;
