import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import SkipEditorCaptionsModalContents from './SkipEditorCaptionsModalContents';

export interface SkipEditorCaptionsModalProps {}

const SkipEditorCaptionsModal: React.FC<SkipEditorCaptionsModalProps> = () => {
  return (
    <ConnectedModal name="SkipEditorCaptions" title="captions are enabled">
      <SkipEditorCaptionsModalContents />
    </ConnectedModal>
  );
};

export default SkipEditorCaptionsModal;
