import React, { useEffect } from 'react';
import * as ReactGA from 'react-ga';
import { Route, Switch, useLocation } from 'react-router';

import ExternalRedirect from 'components/ExternalRedirect';
import App from 'containers/App';
import { sendVisitorPath } from 'utils/helpdesk';

export interface RoutesProps {}

const Routes: React.FC<RoutesProps> = () => {
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname + location.search;
    ReactGA.set({ page });
    ReactGA.pageview(page);
    sendVisitorPath();
  }, [location]);

  return (
    /*
     * App renders the nav bar so to avoid seeing the nav bar flash on screen before /home redirects
     * to headliner.app, wrap everything in a Wrapper component that doesn't render anything but
     * a div containing its children
     */
    <div>
      <Switch>
        <Route
          exact
          path="/home"
          render={() => (
            <ExternalRedirect
              history={false}
              location="https://headliner.app"
            />
          )}
        />
        <Route component={App} />
      </Switch>
    </div>
  );
};

export default Routes;
