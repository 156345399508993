/**
 * executes the save operation
 */
function runSave() {
  // need to know if another save comes in while this one is in progress, so set a flag
  this.saving = true;

  /*
   * if a save was scheduled and then a pause was received, `scheduleSaveOnResume` will be set to
   * true.  If for some reason another save takes place before `resume` is received, then the
   * request to save which was scheduled (and paused) has been fulfilled and there's no reason to
   * schedule the save to happen again after `resume` is called.
   */
  this.scheduleSaveOnResume = false;
  return this.save().then(() => {
    if (this.pendingImmediateSave) {
      /*
       * marks that the pending save has been fulfilled. note that this flag could still get set
       * back to `true` while saves are happening
       */
      this.pendingImmediateSave = false;
      return runSave.apply(this);
    }

    // all saving complete.  clear saving status and return
    this.saving = false;
    return undefined;
  });
}

export default class Saver {
  constructor(save, debounceMillis) {
    this.save = save;
    this.debounceMillis = debounceMillis;

    // id returned by setTimeout when scheduling a save
    this.scheduleId = undefined;

    // gets set to true when a `save` action is recieved while another save operation is in progress
    this.pendingImmediateSave = false;

    // whether or not save is in progress
    this.saving = false;

    // whether or not autosave has been suspended
    this.paused = false;

    // gets set to true when a save is scheduled and a `pause` event is received
    this.scheduleSaveOnResume = false;
  }

  isSaveScheduled() {
    return !!this.scheduleId;
  }

  cancelSave() {
    if (!this.isSaveScheduled()) return;
    clearTimeout(this.scheduleId);
    this.scheduleId = undefined;
  }

  delaySave() {
    if (!this.isSaveScheduled()) return;
    this.scheduleSave();
  }

  scheduleSave() {
    if (this.paused) {
      this.scheduleSaveOnResume = true;
    } else {
      this.cancelSave();
      this.scheduleId = setTimeout(() => {
        this.scheduleId = undefined;
        runSave.apply(this);
      }, this.debounceMillis);
    }
  }

  saveNow() {
    this.cancelSave();
    if (this.saving) {
      this.pendingImmediateSave = true;
    } else {
      runSave.call(this);
    }
  }

  pause() {
    this.paused = true;
    if (this.isSaveScheduled()) {
      this.scheduleSaveOnResume = true;
      this.cancelSave();
    }
  }

  resume() {
    this.paused = false;
    if (this.scheduleSaveOnResume) {
      this.scheduleSaveOnResume = false;
      this.scheduleSave();
    }
  }

  flush() {
    if (this.paused || !this.isSaveScheduled()) return;
    this.saveNow();
  }
}
