import {
  fromJS,
  Iterable,
  List,
  Map,
  Record,
  RecordFactory,
  RecordOf,
} from 'immutable';
import _ from 'underscore';
import { AnyObject } from 'types';

import { omitUndefined } from './collections';
import { withValue } from './control';

const { compose, mapObject, partial } = _;

export function recordFromJs<T>(
  values: object,
  factory: RecordFactory<T>,
): RecordOf<T> {
  const nested = fromJS(values, (key, value) => {
    if (factory.prototype[key] && factory.prototype[key] instanceof Record) {
      return recordFromJs(value.toJS(), factory.prototype[key].constructor);
    }
    return value;
  });
  return factory(nested);
}

export function isImmutable<T, I extends Iterable<any, any>>(
  obj: T | I,
): obj is I {
  return Iterable.isIterable(obj);
}

/**
 * Converts a js object to a Record
 *
 * Record factories don't to any conversion, meaning if the js object has a key
 * "foo" with a value which is on object, passing the js object into the record
 * factory will result in a record with key "foo" and a value that is a js object -
 * the value doesn't get converted to an immutable Map
 *
 * This function will revive the record using the default reviver (fromJS) but
 * will replaces values in the map with overrides.
 *
 * For example:
 *  obj = { foo: ['a', 'b'], bar: { baz: 'quux' }, hello: { world: true }}
 *  reviveRecord(objFactory, obj, { hello: helloRecordFactory(obj.hello) });
 *
 *  results in: Record({ foo: List('a', 'b'), bar: Map({ baz: 'quux'}), hello: HelloRecord });
 *
 * @param reviver
 * @param obj
 * @param overrides
 */
export function reviveRecord(
  reviver: Function,
  obj: AnyObject,
  overrides?: AnyObject,
) {
  return reviver(
    omitUndefined({
      ...mapObject(obj, v => fromJS(v)),
      ...overrides,
    }),
  );
}

/**
 * Useful for converting JS to Immutable for Immutable objects of the form
 * Map<string, Record>
 *
 * @param valueReviver
 * @param obj
 */
// export function reviveMap(valueReviver: Function, obj: AnyObject) {
//   if (!obj) return undefined;
//   return Map(mapObject(obj, v => reviveRecord(valueReviver, v)));
// }

export function reviveMap<T extends AnyObject>(
  valueReviver: <K extends keyof T>(v: T[K], k: K) => any,
  obj: T,
) {
  return withValue(
    obj,
    compose(Map, partial(mapObject, _, valueReviver as any)) as any,
  );
}

export function reviveList<T>(valueReviver: (v: T) => any, obj: T[]) {
  return withValue(obj, o => List(o.map(valueReviver)));
}
