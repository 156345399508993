import * as React from 'react';
import { IconProps } from './types';

function AppStoreIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={60}
      height={60}
      viewBox="0 0 60 60"
      {...props}
    >
      <defs>
        <linearGradient id="prefix__d" x1="0%" x2="100%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#5DBEFC" />
          <stop offset="100%" stopColor="#DD92FE" />
        </linearGradient>
        <filter
          id="prefix__c"
          width="100%"
          height="100%"
          x="0%"
          y="0%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur in="SourceGraphic" />
        </filter>
        <rect id="prefix__a" width={60} height={60} x={0} y={0} rx={12.75} />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <g mask="url(#prefix__b)">
          <path
            fill="#14191E"
            d="M0 0h60.117v60.117H0z"
            filter="url(#prefix__c)"
            transform="translate(-.059 -.059)"
          />
          <path
            fill="url(#prefix__d)"
            fillRule="nonzero"
            d="M50.566 30.059c0 11.326-9.181 20.507-20.508 20.507-11.326 0-20.507-9.182-20.507-20.507 0-11.326 9.181-20.508 20.508-20.508 11.326 0 20.507 9.181 20.507 20.508zm-18.542 2.127v2.7a3.35 3.35 0 106.703.001v-12.11a3.352 3.352 0 10-6.703 0v7.115h-3.93v-7.115a3.352 3.352 0 00-6.703 0v12.11a3.351 3.351 0 006.703 0v-2.7h3.93zm6.745 8.532h-.011m.013-.124h-.011m-.034-.036c-.24 0-.483.076-.69.23h.001c-2.32 1.72-5.057 2.625-7.788 2.625a14.27 14.27 0 01-4.267-.645 13.178 13.178 0 01-3.902-1.98 1.152 1.152 0 00-.688-.228h-.002c-.353 0-.704.16-.93.467a1.157 1.157 0 00.241 1.619 15.448 15.448 0 004.587 2.33c1.616.507 3.292.751 4.962.751 3.243 0 6.46-1.074 9.167-3.08a1.16 1.16 0 00.241-1.621 1.16 1.16 0 00-.93-.468h-.007"
            transform="translate(-.059 -.059)"
          />
        </g>
      </g>
    </svg>
  );
}

export default AppStoreIcon;
