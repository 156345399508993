import { createSelector } from 'reselect';

import { State } from 'redux/types';
import { WizardLastUsedStyleState } from './types';

export const stateSelector = (state: State): WizardLastUsedStyleState =>
  state.get('wizardLastUsedStyle');

export const audiogramLastUsedStyleSelector = createSelector(
  stateSelector,
  state => state.get('audiogram'),
);

export const fullEpisodeLastUsedStyleSelector = createSelector(
  stateSelector,
  state => state.get('fullEpisode'),
);

export const autogramLastUsedStyleSelector = createSelector(
  stateSelector,
  state => state.get('autogram'),
);
