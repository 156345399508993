import cn from 'classnames';
import * as React from 'react';

import { H2, H3 } from 'components/Heading';
import bem from 'utils/bem';

export interface ReferralsInvitationFormProps {
  children?: React.ReactNode;
  className?: string;
  subtitle?: string;
  title: string;
}

const block = bem('referrals-invitation-form');

/**
 * Component used for laying out the different invitation forms.
 *
 * This component will render the title and optional subtitle and accepts a
 * children prop so that the parent can define the actual form.
 */

const ReferralsInvitationForm: React.SFC<ReferralsInvitationFormProps> = ({
  children,
  className,
  subtitle,
  title,
}) => (
  <div className={cn(block(), className)}>
    <H3 className={block('title')}>{title}</H3>
    {subtitle && <H2 className={block('subtitle')}>{subtitle}</H2>}
    {children}
  </div>
);

export default ReferralsInvitationForm;
