import { useCallback } from 'react';

import { useEditorState } from './context/VideoEditorStateContext';
import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import { useModalManager } from './ModalManager';
import { AssetCreationType, MediaIntegrationId } from './types';
import useImageSource, { OnImageProcessed } from './useImageSource';
import useMainMedia from './useMainMedia';
import useVideoSource, { OnVideoProcessed } from './useVideoSource';

const useReplaceMedia = () => {
  const state = useTemplateState();
  const dispatch = useTemplateDispatch();
  const { integrations } = useEditorState();
  const { asset, integration, isLottieAsset, type } = useMainMedia();
  const modalManager = useModalManager();

  const {
    dynamicImageIntegrations,
    mediaIntegrations,
    staticImageIntegrations,
    supportsDynamicImages,
    supportsStaticImages,
  } = integrations;

  const { hideModal, showModal } = modalManager;

  const handleError = useCallback(
    (file: File | string, reason: Error) => {
      dispatch({
        error: true,
        meta: { file },
        payload: reason,
        type: 'MEDIA_REPLACE_ERROR',
      });
    },
    [dispatch],
  );

  const handleImageProcessed: OnImageProcessed = useCallback(
    args => {
      dispatch({
        payload: {
          ...args,
          id: asset?.id,
          // sets prev type for allowing cross replacement
          prevType: type,
        },
        type: 'IMAGE_REPLACE',
      });
    },
    [asset, dispatch, type],
  );

  const handleVideoProcessed: OnVideoProcessed = useCallback(
    (payload, integrationId) => {
      if (integrationId === MediaIntegrationId.AI_ASSET) {
        hideModal();
      }

      dispatch({
        payload: {
          ...payload,
          id: asset?.id,
          // sets prev type for allowing cross replacement
          prevType: type,
        },
        type: 'VIDEOCLIP_REPLACE',
      });
    },
    [asset, dispatch, hideModal, type],
  );

  const handleReplaceMedia = () => {
    integration.onReplaceMedia(
      modalManager,
      createAddImagePrompt(integration.creationType),
    );
  };

  const processImage = useImageSource({
    onError: handleError,
    onImageProcessed: handleImageProcessed,
  });

  const processVideo = useVideoSource({
    onUploadAnotherFile: handleReplaceMedia,
    onError: handleError,
    onVideoProcessed: handleVideoProcessed,
  });

  const handleAddIntegrationImage = useCallback(
    (integrationId: string) => {
      const targetIntegration = !integrationId
        ? mediaIntegrations[0]
        : mediaIntegrations.find(i =>
            i.compatibleIntegrationIds.includes(
              integrationId as MediaIntegrationId,
            ),
          );

      targetIntegration?.onAddMedia(
        {
          accept: (payload, integrationData) => {
            if (integrationData.id !== MediaIntegrationId.AI_ASSET) {
              hideModal();
            }
            // if integration matches a video/gif, video processor is used
            if (
              integrationData.id === MediaIntegrationId.VIDEOCLIP ||
              integrationData.id === MediaIntegrationId.GIF ||
              integrationData.id === MediaIntegrationId.AI_ASSET
            ) {
              // replacing a lottie asset is not currently supported, so in this
              // case, an exception will be thrown.
              const canSelectVideoOrGif = !isLottieAsset;
              if (!canSelectVideoOrGif) {
                handleError(
                  payload,
                  new Error(
                    'This asset can not be replaced with a video/gif asset',
                  ),
                );
              } else {
                processVideo(payload, integrationData);
              }
              // if not matching video/gif, asset will be processed as an image
            } else {
              processImage(payload, integrationData);
            }
          },
          reject: handleError,
        },
        state,
      );
    },
    [
      handleError,
      hideModal,
      mediaIntegrations,
      isLottieAsset,
      processImage,
      processVideo,
      state,
    ],
  );

  const handleAddStaticImage = useCallback(() => {
    if (staticImageIntegrations.length === 1) {
      handleAddIntegrationImage(staticImageIntegrations[0].id);
    } else if (staticImageIntegrations.length >= 2) {
      showModal('add-image', { onClick: handleAddIntegrationImage });
    }
  }, [handleAddIntegrationImage, showModal, staticImageIntegrations]);

  const createAddImagePrompt = useCallback(
    (replacedImageType: AssetCreationType) => () => {
      if (replacedImageType === 'dynamic' || !supportsDynamicImages) {
        // it only makes sense to replace a dynamic image with a static one (otherwise
        // the same dynamic image would be used)
        handleAddStaticImage();
      } else if (supportsStaticImages) {
        showModal('add-image-type', {
          onSubmit: newImageType => {
            if (newImageType === 'static') {
              handleAddStaticImage();
            } else {
              handleAddIntegrationImage(dynamicImageIntegrations[0].id);
            }
          },
        });
      }
    },
    [
      dynamicImageIntegrations,
      handleAddIntegrationImage,
      handleAddStaticImage,
      showModal,
      supportsDynamicImages,
      supportsStaticImages,
    ],
  );

  return handleReplaceMedia;
};

export default useReplaceMedia;
