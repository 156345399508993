import * as React from 'react';
import BemCssTransition from 'components/BemCssTransition';
import { block } from './utils';

export interface Props {
  show: boolean;
}

const WaveformLoading = ({ show }: Props) => (
  <div className={block('loading-waveform-wrapper')}>
    <BemCssTransition
      appear
      className={block('loading-waveform')}
      delay={{ enter: 1000, exit: 0 }}
      timeout={{ enter: 500, exit: 800 }}
      in={show}
    >
      <span>
        We’re loading the waveform, but you can still select a clip in the
        meantime
      </span>
    </BemCssTransition>
  </div>
);

export default WaveformLoading;
