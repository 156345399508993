import React from 'react';
import { IconProps } from './types';

const InfoGradient: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <defs>
      <linearGradient id="0c7y17gp9a" x1="0%" x2="100%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#5DBEFC" />
        <stop offset="100%" stopColor="#DD92FE" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g
        fill="url(#0c7y17gp9a)"
        fillRule="nonzero"
        transform="translate(-1785.000000, -85.000000)"
      >
        <g>
          <g>
            <path
              d="M23 15c-4.418 0-8 3.583-8 8 0 4.42 3.582 8 8 8s8-3.58 8-8c0-4.417-3.582-8-8-8zm0 3.548c.748 0 1.355.607 1.355 1.355s-.607 1.355-1.355 1.355-1.355-.607-1.355-1.355.607-1.355 1.355-1.355zm1.806 8.194c0 .214-.173.387-.387.387h-2.838c-.214 0-.387-.173-.387-.387v-.774c0-.214.173-.387.387-.387h.387v-2.065h-.387c-.214 0-.387-.173-.387-.387v-.774c0-.214.173-.387.387-.387h2.064c.214 0 .387.173.387.387v3.226h.387c.214 0 .387.173.387.387v.774z"
              transform="translate(20.000000, 70.000000) translate(1750.000000, 0.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default InfoGradient;
