import { Tooltip } from '@sparemin/blockhead';
import React from 'react';

import { useTextOverlay } from '../../state/TextOverlayProvider';
import { block } from '../../utils';

interface BoxSizeCheckerSubmitButtonWrapperProps {
  children: (props: { disabled: boolean }) => React.ReactElement;
  isDisabled: boolean;
}

const BoxSizeCheckerSubmitButtonWrapper: React.FunctionComponent<BoxSizeCheckerSubmitButtonWrapperProps> = props => {
  const { children, isDisabled } = props;

  const { boxSizeFitCheckerExtension } = useTextOverlay();

  const {
    rechunkAlertMessage,
    showRechunkAlert,
    submitDisabled,
    submitDisabledMessage,
  } = boxSizeFitCheckerExtension;

  const showDisabledTooltip = submitDisabled;
  const showRechunkTooltip = !submitDisabled && showRechunkAlert;

  // double tooltip is preferred over a single tooltip with a dynamic
  // content because changing the content will not recalculate the tooltip's
  // position
  return (
    <div className={block('submit-btn-tooltip-wrapper')}>
      <Tooltip
        arrow
        contents={rechunkAlertMessage}
        isOpen={showRechunkTooltip}
        placement="top"
        strategy="fixed"
      >
        <div>
          <Tooltip
            arrow
            contents={submitDisabledMessage}
            isOpen={showDisabledTooltip}
            placement="top"
            strategy="fixed"
          >
            {children({ disabled: isDisabled || submitDisabled })}
          </Tooltip>
        </div>
      </Tooltip>
    </div>
  );
};

export default BoxSizeCheckerSubmitButtonWrapper;
