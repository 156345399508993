import cn from 'classnames';
import React from 'react';

import { block } from './utils';
import YoutubeAudienceSelector from './YoutubeAudienceSelector';
import YoutubeVisibilitySelector, {
  YoutubeVisibilitySelectorProps,
} from './YoutubeVisibilitySelector';

export interface YoutubeSettingsProps {
  className?: string;
  madeForKids: boolean;
  onMadeForKidsChange: (value: boolean) => void;
  onVisibilityChange: YoutubeVisibilitySelectorProps['onChange'];
  visibility: YoutubeVisibilitySelectorProps['value'];
}

const YoutubeSettings: React.FC<YoutubeSettingsProps> = ({
  className,
  madeForKids,
  onMadeForKidsChange,
  onVisibilityChange,
  visibility,
}) => (
  <div className={cn(block('settings'), className)}>
    <div className={block('multi-input-row')}>
      <YoutubeVisibilitySelector
        onChange={onVisibilityChange}
        value={visibility}
      />
      <YoutubeAudienceSelector
        onChange={onMadeForKidsChange}
        value={madeForKids}
      />
    </div>
  </div>
);

export default YoutubeSettings;
