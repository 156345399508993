import { useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { SubscriptionPeriod } from 'blocks/Pricing/types';
import { createPlanAmountSelector } from 'redux/modules/pricing';
import { BillingCyclePlanOptions } from './BillingCycleOptions';

export default function useBillingCycleState(
  defaultPeriod: SubscriptionPeriod = 'monthly',
  options: BillingCyclePlanOptions,
) {
  const monthly = options?.monthly;
  const yearly = options?.yearly;

  const monthlySelector = useMemo(() => createPlanAmountSelector(monthly), [
    monthly,
  ]);
  const yearlySelector = useMemo(() => createPlanAmountSelector(yearly), [
    yearly,
  ]);

  const monthlyCostCents = useSelector(monthlySelector);
  const yearlyCostCents = useSelector(yearlySelector);

  const [subPeriod, setSubPeriod] = useState(defaultPeriod);

  const costCents =
    subPeriod === 'monthly'
      ? monthlyCostCents
      : subPeriod === 'yearly'
      ? yearlyCostCents
      : undefined;

  return [subPeriod, costCents, setSubPeriod] as const;
}
