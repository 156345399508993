import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'underscore';

import usePrevious from 'hooks/usePrevious';
import { activeModalSelector } from 'redux/modules/modal/selectors';
import { ModalName } from 'redux/modules/modal/types';

export interface UseModalMonitorConfig {
  names: ModalName[];
  onShow?: (name: ModalName) => void;
  onHide?: (name: ModalName) => void;
}

/*
 * montiors a subset of modals and returns information about the modal currently
 * being displayed
 */
export default function useModalMonitor({
  names,
  onShow = noop,
  onHide = noop,
}: UseModalMonitorConfig) {
  const activeModal = useSelector(activeModalSelector);
  const monitoredActiveModalName = !activeModal
    ? undefined
    : names.find(n => activeModal.name === n);
  const prevActiveModalName = usePrevious(monitoredActiveModalName);

  useEffect(() => {
    if (monitoredActiveModalName !== prevActiveModalName) {
      if (prevActiveModalName) {
        onHide(prevActiveModalName);
      }

      if (monitoredActiveModalName) {
        onShow(monitoredActiveModalName);
      }
    }
  }, [monitoredActiveModalName, onHide, onShow, prevActiveModalName]);
}
