import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import _ from 'underscore';

import Button from 'components/Button';
import Divider from 'components/Divider';
import { H4 } from 'components/Heading';
import LinkButton from 'components/LinkButton';
import ResetPasswordInput, {
  ICallbacks,
  RenderCallback,
} from 'components/ResetPasswordInput';
import SettingsContent from '../SettingsContent';
import { block } from '../utils';
import PasswordField from './PasswordField';

export interface IProps {
  emailAddress: string;
  errorMessage?: string;
  forgotInProgress?: boolean;
  onForgotPassword?: () => void;
  onDeleteUserAccount?: () => void;
  onSubmit?: (
    currentPassword: string,
    newPassword: string,
    confirmPassword: string,
  ) => void;
  updateInProgress?: boolean;
}

interface IDataState {
  currentPassword: string;
  errorMessage?: string;
  canSubmitForm: boolean;
  showForgotSuccessMessage: boolean;
  showUpdateSuccessMessage: boolean;
}

const dataFactory = Record<IDataState>({
  canSubmitForm: undefined,
  currentPassword: undefined,
  errorMessage: undefined,
  showForgotSuccessMessage: undefined,
  showUpdateSuccessMessage: undefined,
});

interface IState {
  data: RecordOf<IDataState>;
}

export default class PasswordSettings extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    onForgotPassword: _.noop,
    onSubmit: _.noop,
    updateInProgress: false,
    onDeleteUserAccount: _.noop,
  };

  private resetPasswordInput: ResetPasswordInput;

  constructor(props: IProps) {
    super(props);

    this.state = {
      data: dataFactory({
        canSubmitForm: false,
        currentPassword: '',
        errorMessage: undefined,
        showForgotSuccessMessage: false,
        showUpdateSuccessMessage: false,
      }),
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const {
      errorMessage: nextErrorMessage,
      forgotInProgress: nextForgotInProgress,
      updateInProgress: nextUpdateInProgress,
    } = nextProps;
    const { errorMessage, forgotInProgress, updateInProgress } = this.props;

    // if an error just occurred
    if (!errorMessage && nextErrorMessage) {
      this.setState(({ data }) => ({
        data: data.set('errorMessage', nextErrorMessage),
      }));
    }

    // if updating password just finished without any errors
    if (updateInProgress && !nextUpdateInProgress && !nextErrorMessage) {
      this.setState(({ data }) => ({
        data: data.withMutations(d =>
          d
            .set('showUpdateSuccessMessage', true)
            .set('currentPassword', '')
            .set('canSubmitForm', false),
        ),
      }));

      this.resetPasswordInput && this.resetPasswordInput.reset();
    }

    // if forgot password just finished without any errors
    if (forgotInProgress && !nextForgotInProgress && !nextErrorMessage) {
      this.setState(({ data }) => ({
        data: data.set('showForgotSuccessMessage', true),
      }));
    }
  }

  private handleCurrentPasswordChange = (
    newPassword: string,
    confirmPassword: string,
  ) => (value: string) =>
    this.handleInputChange(newPassword, confirmPassword, value, d =>
      d.set('currentPassword', value),
    );

  private handleNewPasswordChange = (
    confirmPassword: string,
    onPasswordChange: ICallbacks['onPasswordChange'],
  ) => (value: string, e: React.ChangeEvent<any>) => {
    this.handleInputChange(value, confirmPassword);
    onPasswordChange(e);
  };

  private handleConfirmPasswordChange = (
    newPassword: string,
    onConfirmPasswordChange: ICallbacks['onConfirmPasswordChange'],
  ) => (value: string, e: React.ChangeEvent<any>) => {
    this.handleInputChange(newPassword, value);
    onConfirmPasswordChange(e);
  };

  private handleSubmit = (
    newPassword: string,
    confirmPassword: string,
    validate: () => string,
  ) => (e: React.SyntheticEvent<any>) => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    e.preventDefault();

    this.clearAlerts();

    const errorMessage = validate();
    if (errorMessage) {
      this.setState(({ data: dataState }) => ({
        data: dataState.withMutations(d => d.set('errorMessage', errorMessage)),
      }));
    } else {
      onSubmit(data.currentPassword, newPassword, confirmPassword);
    }
  };

  private handleForgotPasswordClick = () => {
    const { onForgotPassword } = this.props;
    this.clearAlerts();
    onForgotPassword();
  };

  private handleInputChange(
    newPassword: string,
    confirmPassword: string,
    currentPassword?: string,
    updateFn: (data: RecordOf<IDataState>) => RecordOf<IDataState> = data =>
      data,
  ) {
    const canSubmit = this.canSubmitForm(
      newPassword,
      confirmPassword,
      currentPassword,
    );
    this.setState(({ data }) => ({
      data: data.withMutations(d => {
        d.set('canSubmitForm', canSubmit);
        d.delete('errorMessage');
        this.clearAlerts(d);
        return updateFn(d);
      }),
    }));
  }

  private canSubmitForm(
    newPassword: string,
    confirmPassword: string,
    currentPassword?: string,
  ) {
    const { data } = this.state;

    const currentPw = _.isUndefined(currentPassword)
      ? data.currentPassword
      : currentPassword;

    return [currentPw, newPassword, confirmPassword]
      .map(str => str.length)
      .every(length => length > 0);
  }

  private clearAlerts(data?: RecordOf<IDataState>) {
    const clear = (dataState: RecordOf<IDataState>) =>
      dataState.withMutations(d =>
        d
          .set('showUpdateSuccessMessage', false)
          .set('showForgotSuccessMessage', false)
          .delete('errorMessage'),
      );

    if (data) {
      clear(data);
    } else {
      this.setState(({ data: dataState }) => ({
        data: clear(dataState),
      }));
    }
  }

  private showDeleteUserAccountModal = () => {
    const { onDeleteUserAccount } = this.props;
    onDeleteUserAccount();
  };

  private getAlert() {
    const { emailAddress } = this.props;
    const { data } = this.state;

    const createResponse = (message, severity) => ({ message, severity });

    if (data.errorMessage) {
      return createResponse(data.errorMessage, 'error');
    }

    if (data.showUpdateSuccessMessage) {
      return createResponse(
        'Your password has been updated successfully',
        'success',
      );
    }

    if (data.showForgotSuccessMessage) {
      return createResponse(
        `Please check ${emailAddress} for instructions to reset your password`,
        'success',
      );
    }

    return undefined;
  }

  private renderForm: RenderCallback = ({ callbacks, validate, values }) => {
    const { forgotInProgress, updateInProgress } = this.props;
    const { data } = this.state;

    const isBusy = forgotInProgress || updateInProgress;

    return (
      <form className={block('password-form')}>
        <PasswordField
          primaryLabel="Current password"
          secondaryLabel="You will need to enter your current password to update it"
          onChange={this.handleCurrentPasswordChange(
            values.password,
            values.confirmPassword,
          )}
          value={data.currentPassword}
        />
        <PasswordField
          primaryLabel="New password"
          secondaryLabel="Password must be 8 characters long"
          onChange={this.handleNewPasswordChange(
            values.confirmPassword,
            callbacks.onPasswordChange,
          )}
          value={values.password}
        />
        <PasswordField
          primaryLabel="Confirm password"
          onChange={this.handleConfirmPasswordChange(
            values.password,
            callbacks.onConfirmPasswordChange,
          )}
          value={values.confirmPassword}
        />
        <div className={block('password-buttons')}>
          <Button
            className={block('submit-password')}
            disabled={isBusy || !data.canSubmitForm || !!data.errorMessage}
            onClick={this.handleSubmit(
              values.password,
              values.confirmPassword,
              validate,
            )}
            theme="submit"
          >
            Update Now
          </Button>
          <span
            className={block('forgot-password', { disabled: isBusy })}
            onClick={isBusy ? undefined : this.handleForgotPasswordClick}
          >
            I forgot my password
          </span>
        </div>
      </form>
    );
  };

  public render() {
    const ref = el => {
      this.resetPasswordInput = el;
    };
    return (
      <SettingsContent
        alert={this.getAlert()}
        subtitle="Manage password to login"
        title="Password Settings"
      >
        <ResetPasswordInput render={this.renderForm} ref={ref} />
        <Divider />
        <H4>
          To delete your account{' '}
          <LinkButton theme="cta" onClick={this.showDeleteUserAccountModal}>
            click here
          </LinkButton>
        </H4>
      </SettingsContent>
    );
  }
}
