import cn from 'classnames';
import * as React from 'react';
import { RouteChildrenProps } from 'react-router';
import { isUndefined, noop } from 'underscore';
import { RejectedReason } from 'components/FileUploader';
import { renderTemplateDimensionsInfoByAspectRatio } from 'components/FileUploader/utils';
import LinkButton from 'components/LinkButton';
import Tooltip from 'components/Tooltip';
import { UseHook } from 'components/UseHook';
import Wizard, {
  IndexedStep,
  Navigator,
  Step,
  WizardProps,
} from 'components/Wizard';
import { MediaImportedArgs } from 'containers/MediaUploadModal/types';
import useTranscriptionBalance from 'hooks/useTranscriptionBalance';
import { Tier } from 'redux/middleware/api/plan-service';
import { VideoUploadArgs } from 'redux/modules/video-upload';
import { AspectRatioName } from 'types';
import { ApplicationError } from 'utils/ApplicationError';
import { getAspectRatioValue } from 'utils/aspect-ratio';
import bem from 'utils/bem';

import { createChainedFunction } from 'utils/functions';
import { round } from 'utils/numbers';
import { capitalize } from 'utils/string';
import AspectRatioStep from '../AspectRatioStep';
import ClipVideoStep from '../ClipVideoStep';
import UploadVideoStep from '../UploadVideoStep';
import useVideoWizardBlocker from './useVideoWizardBlocker';
import VideoWizardProgressStep from './VideoWizardProgressStep';

type PickedWizardProps = Pick<WizardProps, 'onStepChange' | 'onFileUpload'>;

interface IProps
  extends PickedWizardProps,
    Partial<RouteChildrenProps<{}, { file: any }>> {
  tier: Tier;
  onCancel?: (nav: Navigator) => void;
  onClickSampleFile?: () => void;
  onError?: (
    error: ApplicationError,
    file?: File,
    reason?: RejectedReason,
  ) => void;
  onMount?: () => void;
  onSubmit?: (
    args: VideoUploadArgs,
    mediaImportedArgs: MediaImportedArgs,
  ) => void;
  onComplete?: () => void;
}

interface IState {
  aspectRatioName: AspectRatioName;
  step: string;
  clipEndMillis: number;
  clipStartMillis: number;
  videoSrc: string;
  isSampleVideo: boolean;
  language: string;
  mediaImportedArgs: MediaImportedArgs;
}

enum Steps {
  ASPECT_RATIO = 0,
  UPLOAD_VIDEO = 1,
  CLIP_VIDEO = 2,
}

const clipMaxDuration = spareminConfig.videoClipMaxDurationSeconds * 1000;

const block = bem('video-wizard');

export default class VideoWizard extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    onCancel: noop,
    onClickSampleFile: noop,
    onError: noop,
    onFileUpload: noop,
    onMount: noop,
    onStepChange: noop,
    onSubmit: noop,
  };

  private wizard: Wizard;
  private videoDurationMillis: number;

  // will only be set if user uploads file, not if user clicks the sample video link
  private videoFile: File;

  public state: IState = {
    step: undefined,
    aspectRatioName: 'square',
    clipEndMillis: undefined,
    clipStartMillis: 0,
    isSampleVideo: false,
    language: 'en-US',
    videoSrc: undefined,
    mediaImportedArgs: undefined,
  };

  public componentDidMount() {
    const { onMount } = this.props;

    onMount();
  }

  private clearDefaultFile = () => {
    const { location, history } = this.props;
    if (location.state?.file) {
      const { state, ...locationWithoutState } = location;
      history.replace(locationWithoutState);
    }
  };

  private handleStepChange = (to: IndexedStep, from?: IndexedStep) => {
    /*
     * user already selected a video but navigated back to a step where a video shouldn't be
     * selected.
     */
    if (
      from &&
      from.index >= Steps.UPLOAD_VIDEO &&
      to.index <= Steps.UPLOAD_VIDEO
    ) {
      this.videoFile = undefined;
      this.setVideoSrc();
      this.setState(prev => ({
        ...prev,
        clipEndMillis: undefined,
      }));
    }

    this.setState(prev => ({ ...prev, step: to.stepId }));
  };

  private handleAspectRatioSelect = (__, name: AspectRatioName) =>
    this.setState({
      aspectRatioName: name,
    });

  private handleFileUploadAccepted = (file: File) => {
    const { onFileUpload } = this.props;

    this.clearDefaultFile();

    this.videoFile = file;
    this.setVideoSrc(URL.createObjectURL(file));

    onFileUpload(file);
  };

  private handleFileUploadRejected = (
    error: ApplicationError,
    file?: File,
    reason?: RejectedReason,
  ) => {
    const { onError } = this.props;

    this.clearDefaultFile();

    onError?.(error, file, reason);
  };

  private handleSampleVideoClick = () => {
    const { onClickSampleFile } = this.props;
    onClickSampleFile();
    this.setVideoSrc(spareminConfig.sampleVideoUrl, true);
    this.wizard.next();
  };

  private handleMediaAccepted = (
    mediaImportedArgs: MediaImportedArgs,
  ): void => {
    if (!mediaImportedArgs) {
      return;
    }

    this.setVideoSrc(mediaImportedArgs.previewUrl, false);
    this.setState({
      mediaImportedArgs,
    });
  };

  private handleLanguageSelect = (language: string) =>
    this.setState({ language });

  private handleVideoDurationChange = (millis: number) => {
    this.videoDurationMillis = millis;
    this.setState({
      clipEndMillis: Math.min(millis, clipMaxDuration),
    });
  };

  private handleClipMillisChange = (startMillis: number, endMillis: number) =>
    this.setState({
      clipEndMillis: round(endMillis),
      clipStartMillis: round(startMillis),
    });

  private handleSubmit = () => {
    const {
      aspectRatioName,
      clipEndMillis,
      clipStartMillis,
      language,
      videoSrc,
      mediaImportedArgs,
    } = this.state;

    // might not (but likely will) have an object url here but no harm in calling it either way
    URL.revokeObjectURL(videoSrc);

    if (mediaImportedArgs) {
      URL.revokeObjectURL(mediaImportedArgs.previewUrl);
    }

    const { onSubmit } = this.props;
    onSubmit(
      {
        aspectRatioName,
        src: this.videoFile || videoSrc,
        durationMillis: this.videoDurationMillis,
        trimStartMillis: clipStartMillis,
        trimEndMillis: clipEndMillis,
        language,
      },
      mediaImportedArgs,
    );
  };

  private setVideoSrc(src?: string, isSample: boolean = false) {
    this.setState({
      isSampleVideo: !src ? undefined : isSample,
      videoSrc: !src ? undefined : src,
    });
  }

  private getSteps({
    canTranscribe,
    newBalanceMillis,
    outOfTimeMessage,
    showBalance,
  }: ReturnType<typeof useTranscriptionBalance>): Step[] {
    const { onComplete, onError, tier, location } = this.props;
    const {
      aspectRatioName,
      clipEndMillis,
      clipStartMillis,
      isSampleVideo,
      language,
      videoSrc,
    } = this.state;

    return [
      {
        completedName: capitalize(aspectRatioName),
        component: (
          <AspectRatioStep
            onSelect={this.handleAspectRatioSelect}
            value={aspectRatioName}
          />
        ),
        description:
          'Create a video with a transcript. Get started by selecting an aspect ratio',
        name: 'Choose Aspect Ratio',
        stepId: 'aspectRatio',
        title: 'Video Transcription',
      },
      {
        completedName: 'Video Selected',
        component: (
          <UploadVideoStep
            templateDimensionsInfo={renderTemplateDimensionsInfoByAspectRatio(
              getAspectRatioValue(aspectRatioName),
            )}
            uploadMethod="mediaImporter"
            language={language}
            onError={this.handleFileUploadRejected}
            onFileAccepted={this.handleFileUploadAccepted}
            onLanguageSelect={this.handleLanguageSelect}
            onMediaAccepted={this.handleMediaAccepted}
            /*
             * clicking the sample video should automatically advance to the next step, so the
             * isSampleVideo check is here to prevent the uploader from flashing the "selected
             * video" state when transitioning to the next step
             */
            videoSelected={!isSampleVideo && !isUndefined(videoSrc)}
            footer={
              <LinkButton onClick={this.handleSampleVideoClick}>
                No video file? Try our sample.
              </LinkButton>
            }
            className={block('uploader')}
            tier={tier}
            defaultFile={location.state?.file}
          />
        ),
        name: 'Upload Video',
        renderNextButton: props => (
          <Wizard.Button
            {...props}
            disabled={isUndefined(videoSrc)}
            theme="next"
          >
            Next
          </Wizard.Button>
        ),
        stepId: 'uploadVideo',
      },
      {
        completedName: 'Video Clipped',
        component: (
          <ClipVideoStep
            aspectRatioName={aspectRatioName}
            clipEndMillis={clipEndMillis}
            clipStartMillis={clipStartMillis}
            onDurationChange={this.handleVideoDurationChange}
            onSelectedMillisChange={this.handleClipMillisChange}
            src={videoSrc}
            showTranscriptionBalance={showBalance}
            transcriptionBalanceMillis={newBalanceMillis}
          />
        ),

        description:
          'Clip your video by dragging the handles at either end of the blue box.',
        name: 'Clip Video',
        renderNextButton: ({ className, ...props }) => (
          <Tooltip
            content={outOfTimeMessage}
            show={!canTranscribe ? undefined : false}
            placement="top"
            id="video-clip-tooltip"
          >
            <div
              className={cn(
                block('create-button-wrapper', {
                  disabled: !canTranscribe,
                }),
                className,
              )}
            >
              <Wizard.Button
                {...props}
                className={block('create-button')}
                disabled={!canTranscribe}
                onClick={createChainedFunction(
                  this.handleSubmit,
                  props.onClick,
                )}
                theme="next"
              >
                create project
              </Wizard.Button>
            </div>
          </Tooltip>
        ),
        stepId: 'clipVideo',
      },
      {
        component: (
          <VideoWizardProgressStep onCompleted={onComplete} onError={onError} />
        ),
        name: 'submitting',
        stepId: 'submitting',
        renderCancelButton: () => null,
        renderNextButton: () => null,
        showInNav: false,
      },
    ];
  }

  public render() {
    const { onCancel, onStepChange } = this.props;
    const { step, clipEndMillis, clipStartMillis } = this.state;

    return (
      <UseHook hook={useVideoWizardBlocker} args={[step]}>
        {onStepClick => (
          <UseHook
            hook={useTranscriptionBalance}
            args={[
              {
                durationMillis: clipEndMillis - clipStartMillis,
                transcriptionEnabled: true,
              },
            ]}
          >
            {transcriptionBalance => (
              <Wizard
                canBacktrack={step !== 'submitting'}
                className={block()}
                bodyClassName={block('body', { [step]: true })}
                onCancelClick={onCancel}
                onStepChange={createChainedFunction(
                  this.handleStepChange,
                  onStepChange,
                )}
                onStepClick={onStepClick}
                steps={this.getSteps(transcriptionBalance)}
                ref={el => {
                  this.wizard = el;
                }}
              />
            )}
          </UseHook>
        )}
      </UseHook>
    );
  }
}

export { IProps as VideoWizardProps };
