import { fromJS } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isUndefined } from 'underscore';

import {
  defaultInTransitionSelector,
  defaultOutTransitionSelector,
} from 'redux/modules/display-pref/selectors';
import {
  actions as embedActions,
  selectors as embedSelectors,
} from 'redux/modules/embed';
import { aspectRatioSelector } from 'redux/modules/embed/selectors';
import { hideModal } from 'redux/modules/modal';
import { Dispatch, State } from 'redux/types';
import { createMap } from 'types';
import { DEFAULT_TEXT_OVERLAY } from 'utils/constants';
import { LegacyAddTextModalProps } from './types';

type StateProps = Pick<
  LegacyAddTextModalProps,
  | 'aspectRatio'
  | 'inputDisabled'
  | 'defaultTextOverlayTemplate'
  | 'viewport'
  | 'size'
  | 'position'
>;

type DispatchProps = Pick<LegacyAddTextModalProps, 'onSubmit'>;

const viewportSelector = createSelector(
  embedSelectors.viewportSelector,
  createMap,
);

const textOverlayPreferenceSelector = createSelector(
  [defaultInTransitionSelector, defaultOutTransitionSelector],
  (transitionIn, transitionOut) =>
    fromJS({
      transitions: {
        in: {
          value: transitionIn,
        },
        out: {
          value: transitionOut,
        },
      },
    }),
);

const defaultTextOverlayTemplateSelector = createSelector(
  [
    embedSelectors.lastTextOverlayTemplateSelector,
    textOverlayPreferenceSelector,
  ],
  (lastTemplate, preferenceTemplate) =>
    DEFAULT_TEXT_OVERLAY.mergeDeepWith(
      (oldVal, newVal) => (isUndefined(newVal) ? oldVal : newVal),
      preferenceTemplate,
      lastTemplate,
    ) as any,
);

const mapStateToProps = (
  state: State,
  ownProps: Pick<LegacyAddTextModalProps, 'initialOverlay'>,
): StateProps => ({
  aspectRatio: aspectRatioSelector(state),
  defaultTextOverlayTemplate:
    ownProps.initialOverlay ?? defaultTextOverlayTemplateSelector(state),
  inputDisabled: embedSelectors.selectIsAddingTextOverlay(state),
  size: embedSelectors.textoverlaySizeSelector(state) as any,
  position: embedSelectors.textoverlayPositionSelector(state) as any,
  viewport: viewportSelector(state) as any,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSubmit: overlay => {
    dispatch(embedActions.addTextOverlays(overlay));
    dispatch(hideModal());
  },
});

export default function(
  component: React.ComponentType<LegacyAddTextModalProps>,
) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
