import React from 'react';
import VideoTypeIcon from 'blocks/AudiogramDestinations/components/VideoTypes/VideoTypeIcon';

import { AudiogramDestinationConfig } from 'blocks/AudiogramDestinations/types';
import { DestinationSteps } from 'blocks/AutomationOptions/types';
import { InstagramReels, InstagramStories, PlusBold } from 'components/icons';
import { InstagramSocialCircle } from 'components/SocialCircle';
import { VideoTypes } from 'types';

import { getAudiogramWizardPath } from 'utils/routes';
import { millisToSec } from 'utils/time';
import { DESTINATION_PRESETS } from '../../destinationPresets/utils';

const INSTAGRAM_GRADIENT =
  'linear-gradient(171deg, #6c14c6 6%, #f1532e 60%, #f8c45c 95%)';

const instagram: AudiogramDestinationConfig = {
  id: 'instagram',
  icon: <InstagramSocialCircle />,
  videoTypes: [
    {
      description: 'Share a short clip to go viral',
      destination: getAudiogramWizardPath({
        defaultClipDurationMillis:
          DESTINATION_PRESETS.instagram.reel.durationMs,
        defaultAspectRatio: DESTINATION_PRESETS.instagram.reel.aspectRatio,
        defaultPresetKey: DESTINATION_PRESETS.instagram.reel.key,
      }),
      id: 'reel',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.instagram.reel.aspectRatio}
          background={INSTAGRAM_GRADIENT}
        >
          <InstagramReels width="15px" color="white" />
        </VideoTypeIcon>
      ),
      title: 'Instagram Reel',
      autogramWizardSettings: {
        destination: DestinationSteps.AUTO_POSTING,
        videoType: VideoTypes.RANDOM_CLIP,
        clipDuration: millisToSec(
          DESTINATION_PRESETS.instagram.reel.durationMs,
        ),
        aspectRatioName: DESTINATION_PRESETS.instagram.reel.aspectRatio,
      },
    },
    {
      description: 'Share an ephemeral clip for 24 hours',
      destination: getAudiogramWizardPath({
        defaultClipDurationMillis:
          DESTINATION_PRESETS.instagram.story.durationMs,
        defaultAspectRatio: DESTINATION_PRESETS.instagram.story.aspectRatio,
        defaultPresetKey: DESTINATION_PRESETS.instagram.story.key,
      }),
      id: 'story',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.instagram.story.aspectRatio}
          background={INSTAGRAM_GRADIENT}
        >
          <InstagramStories width="15px" color="white" />
        </VideoTypeIcon>
      ),
      title: 'Instagram Story',
      autogramWizardSettings: {
        destination: DestinationSteps.AUTO_POSTING,
        videoType: VideoTypes.RANDOM_CLIP,
        clipDuration: millisToSec(
          DESTINATION_PRESETS.instagram.story.durationMs,
        ),
        aspectRatioName: DESTINATION_PRESETS.instagram.story.aspectRatio,
      },
    },
    {
      description: 'Clip a full segment from an episode',
      destination: getAudiogramWizardPath({
        defaultClipDurationMillis:
          DESTINATION_PRESETS.instagram.post.durationMs,
        defaultAspectRatio: DESTINATION_PRESETS.instagram.post.aspectRatio,
        defaultPresetKey: DESTINATION_PRESETS.instagram.post.key,
      }),
      id: 'post',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.instagram.post.aspectRatio}
          background={INSTAGRAM_GRADIENT}
        >
          <PlusBold width="13px" color="white" />
        </VideoTypeIcon>
      ),
      title: 'Instagram Post',
      autogramWizardSettings: {
        destination: DestinationSteps.AUTO_POSTING,
        videoType: VideoTypes.RANDOM_CLIP,
        clipDuration: millisToSec(
          DESTINATION_PRESETS.instagram.post.durationMs,
        ),
        aspectRatioName: DESTINATION_PRESETS.instagram.post.aspectRatio,
      },
    },
  ],
};

export default instagram;
