import { Button } from '@sparemin/blockhead';
import React from 'react';

import AutomationPostModalAdditionalDetails from 'components/AutomationPostModalAdditionalDetails';
import AutomationPostModalHeader from 'components/AutomationPostModalHeader';
import InputFieldAsButton from 'components/Form/InputFieldAsButton';
import { EditThin } from 'components/icons';
import LoadingOverlay from 'components/LoadingOverlay';
import ModalContentWithMedia from 'components/Modal/ModalContentWithMedia';
import SocialAccountInfo from 'components/SocialAccountInfo';
import { Step } from 'components/SteppedModal';
import { YoutubePlaylist } from 'redux/middleware/api/third-party-authentication-service/types';
import { block } from '../../utils';
import YoutubePlaylistSelector, {
  YoutubePlaylistSelectorOption,
} from '../../YoutubePlaylistSelector';

import { UsePostComposerBaseConfig } from '../types';

export interface UsePostComposerStepConfig extends UsePostComposerBaseConfig {
  title: string;
  description: string;
  avatarSrc: string;
  playlists: YoutubePlaylist[];
  onHideModal: () => void;
  onPlaylistChange: (playlists: YoutubePlaylistSelectorOption) => void;
  onSubmit: () => void;
  onVideoDescriptionClick: () => void;
  onVideoTitleClick: () => void;
  selectedPlaylistsIds: Set<string>;
  username: string;
}

export default function usePostComposerStep({
  alert,
  avatarSrc,
  description,
  isUploading,
  onAdditionalStepClick,
  onCreatePlaylistClick,
  onHideModal,
  onPlaylistChange,
  onSubmit,
  onSwitchAccountClick,
  onVideoDescriptionClick,
  onVideoTitleClick,
  playlists,
  selectedPlaylistsIds,
  submitButtonLabel,
  title,
  username,
}: UsePostComposerStepConfig): Step {
  return {
    component: (
      <>
        <ModalContentWithMedia
          onClose={onHideModal}
          className="composer-step-content"
        >
          <ModalContentWithMedia.ModalContentWithMediaColumn>
            <img
              src="https://static.sparemin.com/static/webapp-assets/auto-posting-detail/auto-posting-detail-image-a-202303301450.png"
              className={block('composer-image-left')}
            />
          </ModalContentWithMedia.ModalContentWithMediaColumn>

          <ModalContentWithMedia.ModalContentWithMediaColumn className="composer-content-column">
            {isUploading && <LoadingOverlay title="Posting Video" />}
            <div className={block('composer-fields-container')}>
              <SocialAccountInfo
                {...{ avatarSrc, onSwitchAccountClick, username }}
              />

              {alert}

              <AutomationPostModalHeader
                title="Let’s set up your video details"
                description="We'll post these details with each video. You can pull information from your RSS feed, such as episode titles, to make each post unique."
              />

              <InputFieldAsButton
                label="Example of your video titles"
                content={title}
                tooltipContent="Edit format of video titles"
                icon={EditThin}
                onClick={onVideoTitleClick}
                className={block('composer-single-line')}
              />

              <InputFieldAsButton
                label="Example of your video descriptions"
                content={description}
                tooltipContent="Edit format of video descriptions"
                icon={EditThin}
                onClick={onVideoDescriptionClick}
                className={block('composer-description')}
              />

              <YoutubePlaylistSelector
                options={playlists}
                onCreatePlaylistClick={onCreatePlaylistClick}
                onChange={onPlaylistChange}
                value={selectedPlaylistsIds}
              />

              <AutomationPostModalAdditionalDetails
                onClick={onAdditionalStepClick}
              />

              <Button
                onClick={onSubmit}
                variant="solid"
                disabled={isUploading}
                className={block('composer-step-submit-button')}
              >
                {submitButtonLabel}
              </Button>
            </div>
          </ModalContentWithMedia.ModalContentWithMediaColumn>
        </ModalContentWithMedia>
      </>
    ),
    id: 'composer',
    hideFooter: true,
    replaceBody: true,
  };
}
