import * as React from 'react';

import { IconProps } from './types';

const SocialSolidCircleFacebook: React.FC<IconProps> = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <circle fill="#222831" cx="16" cy="16" r="16" />
      <path
        d="M23 16.026C23 12.146 19.866 9 16 9s-7 3.145-7 7.026a7.028 7.028 0 0 0 5.31 6.82v-4.674h-1.444v-2.146h1.444v-.925c0-2.39 1.077-3.5 3.418-3.5.443 0 1.209.089 1.523.176v1.944a9.19 9.19 0 0 0-.81-.028c-1.148 0-1.59.437-1.59 1.57v.763h2.285l-.393 2.146h-1.895V23C20.315 22.58 23 19.619 23 16.026z"
        fill="#BEC8D2"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SocialSolidCircleFacebook;
