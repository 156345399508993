import { List } from 'immutable';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { selectTrackElement } from 'redux/modules/embed/actions/tracks';
import {
  tracksByIdSelector,
  tracksSelector,
} from 'redux/modules/embed/selectors';
import { pushModal } from 'redux/modules/modal/actions';
import { Dispatch, State, ThunkAction } from 'redux/types';
import { PropsOf } from 'types';
import { getAudioTrackType } from 'utils/embed/tracks';
import AudioTrack, { AudioTrackProps as Props } from './AudioTrack';

type StateProps = Pick<Props, 'assetIds'>;
type DispatchProps = Pick<Props, 'onAssetClick'>;
type OwnProps = Pick<Props, 'id'>;

const trackIdSelector = (_, props: OwnProps) => props.id;

const handleAssetClick = (
  assetId: string,
  trackId: string,
): ThunkAction<void> => (dispatch, getState) => {
  const audioTrackType = getAudioTrackType(
    trackId,
    tracksSelector(getState()),
    tracksByIdSelector(getState()),
  );

  dispatch(selectTrackElement(assetId, trackId));
  dispatch(
    pushModal({
      name:
        audioTrackType === 'main'
          ? 'EditAudioModal'
          : 'EditBackgroundAudioModal',
    }),
  );
};

const makeMapStateToProps = () => {
  const assetIdsSelector = createSelector(
    [trackIdSelector, tracksByIdSelector],
    (trackId, tracksById) => tracksById.getIn([trackId, 'data'], List()).toJS(),
  );

  return (state: State, props: OwnProps): StateProps => ({
    assetIds: assetIdsSelector(state, props),
  });
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onAssetClick: assetId => dispatch(handleAssetClick(assetId, props.id)),
});

const component = connect(makeMapStateToProps, mapDispatchToProps)(AudioTrack);

export type AudioTrackProps = PropsOf<typeof component>;
export default component;
