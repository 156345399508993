import cn from 'classnames';
import * as React from 'react';
import { isString, noop } from 'underscore';

import { Option } from './types';
import { block } from './utils';

export interface PageOptionProps {
  className?: string;
  option: Option;
  onClick?: (value: any) => void;
  selected?: boolean;
}

export default class PageOption extends React.Component<PageOptionProps> {
  public static defaultProps: Partial<PageOptionProps> = {
    onClick: noop,
    selected: false,
  };

  private handleClick = () => this.props.onClick(this.props.option.value);

  public render() {
    const { className, option, selected } = this.props;
    const { label, logo } = option;
    return (
      <div
        className={cn(block('option', { selected }), className)}
        onClick={this.handleClick}
      >
        {isString(logo) ? (
          <img className={block('option-logo')} src={logo} />
        ) : (
          logo
        )}
        <span className={block('option-label')}>{label}</span>
      </div>
    );
  }
}
