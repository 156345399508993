import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
  VideoTemplateEditorContextProvider,
  VideoTemplateState,
} from 'components/VideoTemplateEditor';
import PreviewPane from 'components/VideoTemplateEditor/PreviewPane';
import {
  createEmptyState,
  createStateFromConfig,
  overrideStateContent,
} from 'components/VideoTemplateEditor/state';
import { getTimer } from 'components/VideoTemplateEditor/state/config-utils';

import { PodcastTemplateConfig } from 'redux/middleware/api/podcast-service';
import {
  mapStateToEmbedConfig,
  mediaTracksSelector,
  slidesSelector,
  soundwaveSelector,
  textOverlayByIdSelector,
  textTracksSelector,
  tracksSelector,
  videosByIdSelector,
} from 'redux/modules/embed/selectors';
import {
  formatSlideshowInfo,
  formatTextOverlayInfo,
  formatVideoClipsInfo,
} from 'redux/modules/embed/utils';
import { Timing } from 'types';
import {
  AssetInfo,
  BackgroundFor,
  EditorVideoFramePreviewProps,
} from './types';
import {
  block,
  getAssetInfoWithinArray,
  getAssetInfoWithinObject,
  getAssetMiddlePoint,
  getEmbedConfigurationWithoutHiddenAssets,
} from './utils';

const currentAssetInfoSelector = createSelector(
  slidesSelector,
  videosByIdSelector,
  textOverlayByIdSelector,
  soundwaveSelector,
  (_, backgroundFor: BackgroundFor) => backgroundFor,
  (slidesById, videosById, textById, soundwave, backgroundFor) => {
    if (!backgroundFor) {
      return undefined;
    }

    const { id, type } = backgroundFor;

    if (type === 'slideshowInfo') {
      const slide = slidesById?.get(id);

      return !slide
        ? undefined
        : {
            id: slide.get('id'),
            startMillis: slide.get('startMilli'),
            endMillis: slide.get('endMilli'),
          };
    }

    if (type === 'textOverlayInfo') {
      const overlay = textById?.get(id);

      return !overlay
        ? undefined
        : {
            id: overlay.get('id'),
            startMillis: overlay.getIn(['time', 'startMillis']),
            endMillis: overlay.getIn(['time', 'endMillis']),
          };
    }

    if (type === 'videoClips') {
      const video = videosById?.get(id);

      return !video
        ? undefined
        : {
            id: video.get('id'),
            startMillis: video.get('startMillis'),
            endMillis: video.get('endMillis'),
          };
    }

    if (type === 'soundwave') {
      return !soundwave
        ? undefined
        : {
            startMillis: soundwave.getIn(['time', 'startMillis']),
            endMillis: soundwave.getIn(['time', 'endMillis']),
          };
    }

    return undefined;
  },
);

const allAssetsInfoSelector = createSelector(
  (args: { config: PodcastTemplateConfig }) => args,
  ({ config }) => {
    const assetsInfoList = [
      ...getAssetInfoWithinArray(config, 'textOverlayInfo'),
      ...getAssetInfoWithinArray(config, 'slideshowInfo'),
      ...getAssetInfoWithinArray(config, 'videoClips'),
      getAssetInfoWithinObject(config?.soundwave, 'soundwave'),
    ];

    return assetsInfoList;
  },
);

const slidesOutOfFrameSelector = createSelector(
  (args: { allAssetsInfo: AssetInfo[]; currentAssetInfo: Timing }) => args,
  ({ allAssetsInfo, currentAssetInfo }) => {
    const assetMiddlepoint = getAssetMiddlePoint(currentAssetInfo);

    if (!allAssetsInfo) {
      return [];
    }

    const outOfFrameAssets = allAssetsInfo.filter(asset => {
      if (
        asset.startMillis > assetMiddlepoint ||
        asset.endMillis < assetMiddlepoint
      ) {
        return asset;
      }

      return undefined;
    });

    return outOfFrameAssets;
  },
);

const slidesWithIdSelector = createSelector(
  mediaTracksSelector,
  tracksSelector,
  slidesSelector,
  (mediaTracks, tracks, slidesById) =>
    formatSlideshowInfo({
      mediaTracks,
      tracks,
      slidesById,
      includeId: true,
    }),
);

const textOverlaysWithIdSelector = createSelector(
  textTracksSelector,
  tracksSelector,
  textOverlayByIdSelector,
  (textTracks, tracks, textOverlayById) =>
    formatTextOverlayInfo({
      textTracks,
      tracks,
      textOverlayById,
      includeId: true,
    }),
);

const videosWithIdSelector = createSelector(
  mediaTracksSelector,
  tracksSelector,
  videosByIdSelector,
  (mediaTracks, tracks, videosById) =>
    formatVideoClipsInfo({
      mediaTracks,
      tracks,
      videosById,
      aspectRatio: undefined,
      includeId: true,
    }),
);

const currentAssetsWithIdSelector = createSelector(
  slidesWithIdSelector,
  textOverlaysWithIdSelector,
  videosWithIdSelector,
  (slideshowInfo, textOverlayInfo, videoClips) => ({
    slideshowInfo,
    textOverlayInfo,
    videoClips,
  }),
);

const EditorVideoFramePreview: React.FC<EditorVideoFramePreviewProps> = ({
  canvasDimensions,
  backgroundFor,
  aspectRatio,
  className,
}) => {
  const [state, setState] = React.useState<VideoTemplateState>();

  // Prevents reselect selectors from running too often.
  const memoizedBackgroundFor = React.useMemo(
    () => ({ id: backgroundFor?.id, type: backgroundFor?.type }),
    [backgroundFor],
  );

  const initialConfiguration = useSelector(currentState =>
    mapStateToEmbedConfig(currentState),
  );

  const currentAssetsWithId = useSelector(currentState =>
    currentAssetsWithIdSelector(currentState),
  );

  const embedConfiguration = React.useMemo(
    () => ({
      ...initialConfiguration,
      ...currentAssetsWithId,
    }),
    [currentAssetsWithId, initialConfiguration],
  );

  const currentAssetInfo = useSelector(currentState =>
    currentAssetInfoSelector(currentState, memoizedBackgroundFor),
  );

  const allAssetsInfo = useSelector(() =>
    allAssetsInfoSelector({ config: embedConfiguration }),
  );

  const slidesOutOfFrame = useSelector(() =>
    slidesOutOfFrameSelector({
      allAssetsInfo,
      currentAssetInfo,
    }),
  );

  const getState = React.useCallback((): VideoTemplateState => {
    return overrideStateContent(
      embedConfiguration
        ? createStateFromConfig(
            getEmbedConfigurationWithoutHiddenAssets(embedConfiguration, [
              ...slidesOutOfFrame,
              // Adding current asset into the list as we don't want to
              // show it in the background preview.
              allAssetsInfo.find(({ id }) => id === memoizedBackgroundFor?.id),
            ]),
          )
        : createEmptyState(aspectRatio),
      {
        // Timer shares the same layer as progress. If opening the
        // progress layer, timer layer should also be hidden.
        timer:
          memoizedBackgroundFor.type === 'progress'
            ? undefined
            : getTimer(embedConfiguration),
        aspectRatio,
        canvas: canvasDimensions,
      },
    );
  }, [
    allAssetsInfo,
    aspectRatio,
    canvasDimensions,
    embedConfiguration,
    memoizedBackgroundFor,
    slidesOutOfFrame,
  ]);

  // Only setting the state if it wasn't set before and if the
  // canvasDimensions are not undefined to avoid unnecessary re-renders.
  if (!state && !!canvasDimensions) {
    setState(getState());
  }

  if (!state) return null;

  return (
    <VideoTemplateEditorContextProvider state={state} disabled>
      <PreviewPane
        className={cn(block('preview-container'), className)}
        autoplayVideo={false}
        useDefaultVideoStyle={false}
      />
    </VideoTemplateEditorContextProvider>
  );
};

export default EditorVideoFramePreview;
