import * as React from 'react';
import { Form, FormProps } from 'react-bootstrap';
import { isFunction, noop } from 'underscore';

import { Omit } from 'types';

const { useCallback, useEffect } = React;

export interface AuthFormProps extends Omit<FormProps, 'onError'> {
  children?: React.ReactElement | React.FC<AuthFormRenderProps>;
  className?: string;
  error?: boolean;
  errorMessage?: string;
  inProgress?: boolean;
  onChange?: () => void;
  onError?: (message: string) => void;
  onSubmit?: () => void;
  onUnmount?: () => void;
}

export type AuthFormRenderProps = Required<
  Pick<AuthFormProps, 'error' | 'errorMessage' | 'inProgress' | 'onChange'>
>;

const AuthForm: React.SFC<AuthFormProps> = ({
  children,
  className,
  error,
  errorMessage,
  inProgress,
  onUnmount,
  onChange,
  onError,
  onSubmit,
}) => {
  useEffect(() => onUnmount, [onUnmount]);

  useEffect(() => {
    if (errorMessage) {
      onError(errorMessage);
    }
  }, [errorMessage, onError]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );

  return (
    <Form className={className} onSubmit={handleSubmit}>
      {isFunction(children)
        ? children({ onChange, inProgress, error, errorMessage })
        : children}
    </Form>
  );
};

AuthForm.defaultProps = {
  error: false,
  inProgress: false,
  onError: noop,
  onUnmount: noop,
};

export default AuthForm;
