import cn from 'classnames';
import * as React from 'react';
import ReactInfiniteScroll from 'react-infinite-scroller';
import { noop } from 'underscore';

import bem from 'utils/bem';
import InfiniteScrollLoader from './InfiniteScrollLoader';

export interface IProps {
  children?: React.ReactNode;
  className?: string;
  getScrollParent?: () => HTMLElement;
  hasMore?: boolean;
  isLoading?: boolean;
  onLoadMore?: (pageNo: number) => void;
  pageStart?: number;
  spinnerClassName?: string;
  loadingText?: string;
  style?: React.CSSProperties;
  window?: boolean;
}

const b = bem('infinite-scroll');

const InfiniteScroll: React.SFC<IProps> = ({
  children,
  className,
  getScrollParent,
  hasMore,
  isLoading,
  onLoadMore,
  pageStart,
  spinnerClassName,
  style,
  window,
  loadingText,
}) => (
  <ReactInfiniteScroll
    className={cn(b(), className)}
    getScrollParent={getScrollParent}
    hasMore={hasMore && !isLoading}
    pageStart={pageStart}
    loadMore={onLoadMore}
    style={style}
    useWindow={window}
    initialLoad
  >
    {children}
    {isLoading && (
      <InfiniteScrollLoader {...{ spinnerClassName, loadingText }} />
    )}
  </ReactInfiniteScroll>
);

InfiniteScroll.defaultProps = {
  hasMore: false,
  isLoading: false,
  onLoadMore: noop,
  pageStart: 1,
};

export default InfiniteScroll;
