import cn from 'classnames';
import * as React from 'react';

import RadioOptions, { OptionOverrideProps } from 'components/RadioOptions';
import { popperRadioBlock as block } from './utils';

const { useMemo } = React;

export interface PopperRadioOption {
  icon?: React.ReactElement;
  value: string;
  label?: React.ReactChild;
}

type OptionProps = OptionOverrideProps<PopperRadioOption>;

export interface PopperRadioProps {
  onChange?: (value: string) => void;
  options: PopperRadioOption[];
  optionClassName?: string;
  value: string;
}

const PopperRadio: React.FC<PopperRadioProps> = ({
  onChange,
  options,
  value,
  optionClassName,
}) => {
  return (
    <RadioOptions
      {...{ options }}
      className={block()}
      listClassName={block('list')}
      overrides={{
        option: useMemo(
          () => ({ onClick, option, selected }: OptionProps) => {
            return (
              <button
                className={cn(block('option', { selected }), optionClassName)}
                type="button"
                {...{ onClick }}
              >
                {option.icon && (
                  <div className={block('icon-container')}>
                    {React.cloneElement(option.icon, {
                      className: cn(block('icon'), option.icon.props.className),
                    })}
                  </div>
                )}
                <span className={block('label')}>
                  {option.label ?? option.value}
                </span>
              </button>
            );
          },
          [optionClassName],
        ),
      }}
      {...{ onChange, value }}
    />
  );
};

export default PopperRadio;
