import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import useRegistrationQuery from 'blocks/Authentication/hooks/useRegistrationQuery';
import { removeReferralQueryParams } from 'blocks/Authentication/utils';
import { Tier } from 'redux/middleware/api/plan-service';
import { acceptPromotion } from 'redux/middleware/api/plan-service/actions';
import { pushModal } from 'redux/modules/modal';
import { loadMyPricingData, tierSelector } from 'redux/modules/pricing';
import { Dispatch } from 'redux/types';

type AlertStatus = 'idle' | 'in-progress' | 'resolved';

export default function useRedeemPromotion() {
  const dispatch = useDispatch<Dispatch>();
  const location = useLocation();
  const history = useHistory();
  const tier = useSelector(tierSelector);
  const { promoKey } = useRegistrationQuery();
  const alertStatus = useRef<AlertStatus>('idle');

  useEffect(() => {
    const redeemPromotion = async () => {
      alertStatus.current = 'in-progress';
      await dispatch(acceptPromotion(promoKey));
      await dispatch(loadMyPricingData());

      // remove the query params - if the user refereshes the page and the query
      // params are still there, they will see modal that says they are not
      // eligible for the promotion
      history.replace(removeReferralQueryParams(location));

      await dispatch(pushModal({ name: 'FreeTrialWelcome' }));

      alertStatus.current = 'resolved';
    };

    const denyPromotion = async () => {
      alertStatus.current = 'in-progress';
      history.replace(removeReferralQueryParams(location));
      await dispatch(pushModal({ name: 'PromoUnavailable' }));
      alertStatus.current = 'resolved';
    };

    // if tier is undefined, we cannot yet determine if the user is on a plan that
    // is eligible to redeem the promotion.  if redeeming is in progress, we should
    // bail in order to prevent showing the wrong modal/state
    if (tier === undefined || alertStatus.current !== 'idle') {
      return;
    }

    // only free tier users can redeem promo codes
    if (promoKey && tier === Tier.FREE) {
      redeemPromotion();
    } else if (promoKey) {
      denyPromotion();
    }
  }, [dispatch, history, location, promoKey, tier]);
}
