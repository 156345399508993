import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import DeleteModalContents from 'components/DeleteModal/DeleteModalContents';
import { deleteProjectTemplateAction } from 'redux/modules/project-templates';

interface DeleteTemplateModalContentsProps {
  templateId?: string;
  onHide?: (templateHasBeenDeleted: boolean) => void;
}

const DeleteTemplateModalContents: React.FC<DeleteTemplateModalContentsProps> = props => {
  const { templateId, onHide } = props;

  const dispatch = useDispatch();

  const handleDeleteTemplate = useCallback((): void => {
    dispatch(deleteProjectTemplateAction(templateId));
    onHide(true);
  }, [dispatch, onHide, templateId]);

  const handleCloseModal = useCallback((): void => {
    onHide(false);
  }, [onHide]);

  return (
    <>
      <DeleteModalContents
        bodyText="Are you sure you want to delete this template?"
        onCancel={handleCloseModal}
        onDelete={handleDeleteTemplate}
      />
    </>
  );
};

export default DeleteTemplateModalContents;
