import * as React from 'react';

import Image from 'components/Image';
import Tooltip from 'components/Tooltip';
import { block } from './utils';

export interface BlockImageProps {
  height?: number;
  width?: number;
  src?: string;
  noDimension?: boolean;
  translucent?: boolean;
  tooltip?: React.ReactNode;
}

const { useEffect, useRef, useState } = React;

const BlockImage: React.SFC<BlockImageProps> = ({
  height,
  width,
  src,
  noDimension,
  translucent,
  tooltip,
}) => {
  const [imageHeight, setImageHeight] = useState<number>(height || 0);
  const [imageWidth, setImageWidth] = useState<number>(width || 0);
  const [isLoaded, setIsLoaded] = useState<boolean>();

  const imageRef = useRef(null);

  useEffect(() => {
    const isCached =
      imageRef.current.complete ||
      imageRef.current.naturalWidth + imageRef.current.naturalHeight > 0;

    setIsLoaded(isCached);
  }, []);

  const handleImageLoad = event => {
    const imageEl = event.target;

    setIsLoaded(true);
    setImageHeight(imageEl.naturalHeight);
    setImageWidth(imageEl.naturalWidth);
  };

  const renderContent = () => {
    const aspectPct = (imageHeight / imageWidth) * 100;

    const innerStyle = {
      paddingTop: `${aspectPct}%`,
      position: 'relative' as 'relative',
    };

    const dimensionsString = `${imageWidth} x ${imageHeight}`;

    return (
      <div className={block({ translucent })}>
        <div className={block('container')} style={innerStyle}>
          <Image
            className={block('img', {
              loaded: isLoaded,
              loading: !isLoaded,
            })}
            ref={imageRef}
            src={src}
            onLoad={handleImageLoad}
          />
        </div>
        {!noDimension && (
          <div className={block('footer')}>
            <span className="image-info">{dimensionsString}</span>
          </div>
        )}
      </div>
    );
  };

  const renderTooltip = () => (
    <Tooltip
      animation={false}
      content={tooltip}
      id="block-image-tooltip"
      className={block('tooltip')}
      placement="left"
    >
      <div>{renderContent()}</div>
    </Tooltip>
  );

  return tooltip ? renderTooltip() : renderContent();
};

BlockImage.defaultProps = { noDimension: false };

export default BlockImage;
