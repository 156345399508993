import React, { useCallback, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';
import { entireAudioInstanceIdSelector } from 'redux/modules/clip-select/selectors';
import {
  entireAudioInstancesSelector,
  entireAudiosSelector,
} from 'redux/modules/entities/selectors';
import { useClipPlayer } from './ClipSelectContext';

export interface SuggestionPlayerProps {}

/*
 * Responsible for playing audio associated with suggestion cards.
 *
 * This component is used instead of the clipper because
 *  - the clipper would need to be loaded in the backround.  it's heavy and interferes
 *    with animations on the page
 *  - seeking and then playing in the clipper sometimes results in no playback
 */
const SuggestionPlayer: React.FC<SuggestionPlayerProps> = () => {
  const { playerRef, onAudioProcess } = useClipPlayer();
  const audioRef = useRef<HTMLAudioElement>();

  const entireAudioInstanceId = useSelector(entireAudioInstanceIdSelector);

  const entireAudio = useSelector(state => {
    const entireAudioInstance = entireAudioInstancesSelector(state)?.get(
      String(entireAudioInstanceId),
    );
    return entireAudiosSelector(state)?.get(
      String(entireAudioInstance?.get('entireAudio')),
    );
  });

  useImperativeHandle(playerRef, () => ({
    pause() {
      audioRef.current?.pause();
    },
    play() {
      audioRef.current?.play();
    },
    seek(millis: number) {
      if (audioRef.current) {
        audioRef.current.currentTime = millis / 1000;
      }
    },
  }));

  const handleTimeupdate = useCallback(() => {
    onAudioProcess(audioRef.current.currentTime * 1000);
  }, [onAudioProcess]);

  return (
    <audio
      ref={audioRef}
      onTimeUpdate={handleTimeupdate}
      src={entireAudio?.get('url')}
    />
  );
};

export default SuggestionPlayer;
