import React from 'react';

import FontAwesome from 'components/FontAwesome';
import OptionTile from '../assets/OptionTile';
import useDeleteMedia from '../useDeleteMedia';

export interface DeleteTileProps {
  className?: string;
}

const DeleteTile: React.FC<DeleteTileProps> = props => {
  const { className } = props;
  const handleClick = useDeleteMedia();

  return (
    <OptionTile
      className={className}
      icon={
        <FontAwesome
          icon="trash-alt"
          style={{ color: '#c82828', height: 36, width: 'auto' }}
        />
      }
      label="delete"
      onClick={handleClick}
    />
  );
};

export default DeleteTile;
