import * as Sentry from '@sentry/browser';
import { Dispatch, Store } from 'redux';
import { constant, isFunction, isObject, noop } from 'underscore';

import { isErrorReported, markErrorReported } from 'utils/sentry';
import { ReportedError } from 'utils/sentry/types';
import { CreateSentryOptions, SentryAction } from './types';
import { isPromise } from './utils';

export default function createSentry<S, A extends SentryAction>({
  actionTransformer = x => x,
  extractBreadcrumbData = constant(undefined),
  filterBreadcrumbActions = constant(true),
  stateTransformer = constant(undefined),
  filterErrors = constant(true),
  onErrorReported = noop,
}: CreateSentryOptions = {}) {
  return ({ getState }: Store<S, A>) => {
    let lastAction: A;

    const recordError = <E extends ReportedError>(err: E) => {
      if (!isErrorReported(err) && filterErrors(err)) {
        Sentry.withScope(scope => {
          scope.setExtras({
            lastAction: actionTransformer(lastAction),
            state: stateTransformer(getState()),
          });
          Sentry.captureException(err);
        });

        markErrorReported(err);
        onErrorReported(err, Sentry.lastEventId());
      }
      return err;
    };

    return (next: Dispatch<A>) => (action: A) => {
      const userContext = action?.meta?.sentry?.userContext;
      const tags = action?.meta?.sentry?.tags;

      /*
       * undefined: don't alter user context
       * null: clear user context
       * else: set user context
       */
      if (userContext || userContext === null) {
        Sentry.configureScope(scope => scope.setUser(userContext ?? undefined));
      }

      if (tags) {
        Sentry.configureScope(scope => scope.setTags(tags));
      }

      /*
       * dispatch the action and return the result. if an exception is thrown,
       * it is reported to sentry and `undefined` is returned
       */
      const dispatchAction = () => {
        try {
          return next(action);
        } catch (e) {
          recordError(e);
          throw e;
        }
      };

      if (isFunction(action)) {
        /*
         * if the action is a function, run it through the remaining middleware
         * assuming one of the remaining middlewares can handle the function
         */
        const res = dispatchAction();

        // if the response is a promise, instasll an error handler that reports
        // to sentry
        if (isPromise(res)) {
          /*
           * note that we modify the error here by adding a field indicating that
           * the error has been reported to sentry. make sure the modified error
           * propagates through so that if any other dispatched action results
           * in a failed promise with the same error, we only log it once
           */
          return res.catch(err => Promise.reject(recordError(err)));
        }

        return res;
      }

      // if the action is an object, assume it's just a plain FSA or the like
      if (isObject(action)) {
        lastAction = action;

        if (filterBreadcrumbActions(action)) {
          Sentry.addBreadcrumb({
            category: 'redux-action',
            data: extractBreadcrumbData(action),
            message: action.type,
          });
        }

        return dispatchAction();
      }

      return dispatchAction();
    };
  };
}
