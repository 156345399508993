import * as React from 'react';
import { noop } from 'underscore';

import { Edit } from 'components/icons';
import TextChildTile from './TextChildTile';
import { textViewBlock as block } from './utils';

export interface TextAssetTileProps {
  children?: React.ReactNode;
  onClick?: (params: any, e: React.MouseEvent<HTMLButtonElement>) => void;
  params?: any;
}

const TextAssetTile: React.FC<TextAssetTileProps> = ({
  children,
  onClick = noop,
  params,
}) => (
  <TextChildTile className={block('text-asset')} {...{ onClick, params }}>
    <span className={block('text-asset-text')}>{children}</span>
    <div className={block('text-asset-overlay')}>
      <Edit style={{ height: 'auto', marginRight: 12, width: 27 }} /> edit text
    </div>
  </TextChildTile>
);

export default TextAssetTile;
