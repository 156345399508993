import cn from 'classnames';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  CSSTransitionClassNames,
  CSSTransitionProps,
} from 'react-transition-group/CSSTransition';
import { isNumber } from 'underscore';

import { camelToSnake } from 'utils/string';

type IProps = CSSTransitionProps & {
  delay?: number | { enter?: number; exit?: number };
  transitionClassName?: string;
};

const states: Array<keyof CSSTransitionClassNames> = [
  'appear',
  'appearActive',
  'appearDone',
  'enter',
  'enterActive',
  'enterDone',
  'exit',
  'exitActive',
  'exitDone',
];

interface State {
  in: boolean;
}

export default class BemCSSTransition extends React.Component<IProps, State> {
  private taskId: number = undefined;

  public static defaultProps: Partial<IProps> = {
    enter: true,
    exit: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      in: false,
    };
  }

  public componentDidMount() {
    const { appear, in: tIn } = this.props;

    // FIXME: if appear is set to false (default in react-transition-group), then
    // the component shouldn't transition in (default state is enter-done).  this
    // only works if in=true when the CSSTransition mounts.  Our implementaiton
    // always mounts CSSTransition with in=false (see constructor), meaning there
    // is no easy way to prevent the transition on appear
    if (tIn) {
      if (appear) {
        this.transition('enter');
      } else {
        this.setState({ in: true });
      }
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { enter, exit, in: tIn } = this.props;
    const { in: prevTIn } = prevProps;

    if (tIn && !prevTIn) {
      if (enter) {
        this.transition('enter');
      } else {
        this.setState({ in: true });
      }
    } else if (!tIn && prevTIn) {
      if (exit) {
        this.transition('exit');
      } else {
        this.setState({ in: false });
      }
    }
  }

  public componentWillUnmount() {
    if (this.taskId) {
      window.clearTimeout(this.taskId);
      this.taskId = undefined;
    }
  }

  private transition(direction: 'enter' | 'exit') {
    if (this.taskId) {
      window.clearTimeout(this.taskId);
      this.taskId = undefined;
    }

    this.taskId = window.setTimeout(
      () => this.setState({ in: direction === 'enter' }),
      this.getDelay(direction),
    );
  }

  private getDelay(direction: 'enter' | 'exit') {
    const { delay = 0 } = this.props;

    if (isNumber(delay)) {
      return delay;
    }

    return delay[direction] || 0;
  }

  private static suffixClassName(className: string): CSSTransitionClassNames {
    return states.reduce((acc, state) => {
      acc[state] = `${className}--${camelToSnake(state)}`;
      return acc;
    }, {});
  }

  private getChildClassName() {
    const { children } = this.props;

    try {
      const onlyChild = React.Children.only(children);
      if (React.isValidElement(onlyChild)) {
        return onlyChild.props.className;
      }

      return undefined;
    } catch {
      return undefined;
    }
  }

  public render() {
    const {
      delay,
      className,
      in: tInProp,
      out,
      transitionClassName,
      ...props
    } = this.props;
    const { in: tIn } = this.state;

    return (
      <CSSTransition
        className={cn(className, transitionClassName, this.getChildClassName())}
        classNames={BemCSSTransition.suffixClassName(
          transitionClassName || className,
        )}
        in={tIn}
        {...props}
      />
    );
  }
}

export { IProps as BemCssTransitionProps };
