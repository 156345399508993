import cn from 'classnames';
import * as React from 'react';

import { block } from './utils';

export interface HandleProps {
  className?: string;
  username: string;
}

const Handle: React.SFC<HandleProps> = ({ className, username }) => (
  <span className={cn(block('handle'), className)}>{username}</span>
);

export default Handle;
