import React from 'react';

import FontAwesome from 'components/FontAwesome';
import Circular from 'components/icons/circular';
import {
  ModalFooter,
  ModalFooterButton,
  ModalFooterButtonProps,
  ModalFooterButtons,
} from 'components/Modal';
import NonIdealState, { NonIdealStateProps } from 'components/NonIdealState';

type PickedNonIdealStateProps = Pick<
  NonIdealStateProps,
  'description' | 'title'
>;

interface Action extends ModalFooterButtonProps {
  id: string;
}

export interface WarningModalContentsProps extends PickedNonIdealStateProps {
  actions?: Action[];
  className?: string;
}

const WarningModalContents: React.FC<WarningModalContentsProps> = ({
  actions,
  className,
  description,
  title,
}) => (
  <>
    <NonIdealState
      {...{ className, description, title }}
      icon={
        <Circular variant="danger">
          <FontAwesome icon="exclamation" size="lg" />
        </Circular>
      }
    />
    <ModalFooter>
      <ModalFooterButtons fluid>
        {actions.map(({ id, ...buttonProps }) => (
          <ModalFooterButton key={id} {...buttonProps} />
        ))}
      </ModalFooterButtons>
    </ModalFooter>
  </>
);

export default WarningModalContents;
