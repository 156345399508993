import * as React from 'react';

import { useDispatch } from 'react-redux';
import ConnectedModal from 'containers/ConnectedModal';
import { hideModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import PodcastIdModalContent from './PodcastIdModalContents';
import { block } from './utils';

export interface PodcastIdModalProps {}

const PodcastIdModal: React.FC<PodcastIdModalProps> = () => {
  const dispatch = useDispatch<Dispatch>();

  const handleCloseModal = React.useCallback((): void => {
    dispatch(hideModal());
  }, [dispatch]);

  return (
    <ConnectedModal
      name="PodcastId"
      title="podcast personalization"
      className={block()}
      onHide={handleCloseModal}
    >
      <PodcastIdModalContent />
    </ConnectedModal>
  );
};

export default PodcastIdModal;
