import React from 'react';

import Modal from 'components/Modal';
import PostTask from '../../components/PostTask';
import { blockOverlay } from '../../utils';
import PostTaskDiscoSection from './PostTaskDiscoSection';

interface PostTaskDiscoOverlayProps {
  subTitle: string;
  title: string;
  visible: boolean;
}

const PostTaskDiscoOverlay: React.FunctionComponent<PostTaskDiscoOverlayProps> = props => {
  const { subTitle, title, visible } = props;

  return (
    <Modal
      backdrop="static"
      show={visible}
      backdropClassName={blockOverlay('backdrop')}
      className={blockOverlay({ disco: true })}
    >
      <PostTask
        banner={<PostTaskDiscoSection />}
        title={title}
        subTitle={subTitle}
      />
    </Modal>
  );
};

export default PostTaskDiscoOverlay;
