import * as React from 'react';

import { IconProps } from './types';

const Check: React.FC<IconProps> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="fill" fillRule="evenodd">
      <circle fill="fill" cx="15" cy="15" r="15" />
      <path
        d="M19.002 11.338a1.164 1.164 0 0 1 1.666.027c.425.447.44 1.142.056 1.607l-.083.09-5.679 5.6a1.163 1.163 0 0 1-1.549.081l-.088-.079-2.964-2.906a1.215 1.215 0 0 1-.031-1.696 1.163 1.163 0 0 1 1.575-.113l.091.08 2.144 2.102 4.862-4.793z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default Check;
