import React from 'react';
import { useSelector } from 'react-redux';

import { subscriptionPurchasePlatformSelector } from 'redux/modules/pricing';

import PaymentDetailsGate from './PaymentDetailsGate';

const PaymentDetailsGateContainer: React.FunctionComponent = props => {
  const { children } = props;

  const subscriptionPurchasePlaform = useSelector(
    subscriptionPurchasePlatformSelector,
  );

  return (
    <PaymentDetailsGate
      subscriptionPurchasePlaform={subscriptionPurchasePlaform}
    >
      {children}
    </PaymentDetailsGate>
  );
};

export default PaymentDetailsGateContainer;
