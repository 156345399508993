import cn from 'classnames';
import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { IImmutableMap } from 'types';
import { getAspectRatioDimensions } from 'utils/aspect-ratio';
import { prefix } from 'utils/ui';

export interface FillAspectRatioProps {
  aspectRatio: number;
  children?: React.ReactNode;
  className?: string;
  element?: React.ReactNode;
  innerClassName?: string;
  wrapperClassName?: string;
}

interface IUiState {
  height: number;
  width: number;
}

interface IState {
  ui: RecordOf<IUiState>;
}

const uiFactory = Record<IUiState>({
  height: undefined,
  width: undefined,
});

/**
 * This component renders its children with a fixed aspect ratio, filling available height.
 *
 * NB: In _most_ cases this component is not needed.  If a specific aspect ratio is needed, setting
 *     padding-top to (height / width) should be sufficient.  This component is only needed when
 *     the fixed AR element's height should determine its size rather than its width.
 */
/*
 * explanation of the DOM structure and how this works.
 *
 * .fill-ar: root node for the component
 *
 * .fill-ar__inner: container for all of the component's content
 *
 * .fill-ar__aspect: container for `element` to force it to the desired AR. this is done by
 *   giving it inline-block positioning so that it grows with its children.  The block has 2
 *   children, an image and the `element`.  The image is scaled to the correct AR and the `element`
 *   is positioned absolutely and stretched over the image.  Because the `element` is positioned
 *   absolutely, the image determines thhe size of .fill-ar__aspect and stretching `element` over
 *   its parent (.fill-ar__aspect) gives `element` the correct dimensions
 *
 * .fill-ar__img: the image used to enforce AR
 *
 * .fill-ar__element-wrapper: wraps `element` in order to stretch it over the image to the correct
 *   AR.  `element` should have height and width set to 100%
 */
export default class FillAspectRatio extends React.Component<
  FillAspectRatioProps,
  IState
> {
  private aspectRatioDimensions: IImmutableMap<{
    height: number;
    width: number;
  }>;

  constructor(props) {
    super(props);

    const { aspectRatio } = props;
    this.aspectRatioDimensions = getAspectRatioDimensions(aspectRatio);

    this.state = {
      ui: uiFactory(),
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { aspectRatio: nextAspectRatio } = nextProps;
    const { aspectRatio } = this.props;

    if (nextAspectRatio !== aspectRatio) {
      this.aspectRatioDimensions = getAspectRatioDimensions(nextAspectRatio);
    }
  }

  private handleResize = (width: number, height: number) =>
    this.setState(({ ui }) => ({
      ui: ui.withMutations(u => u.set('width', width).set('height', height)),
    }));

  private getImgStyle() {
    const { aspectRatio } = this.props;
    const { ui } = this.state;

    const availableHeight = ui.get('height');
    const availableWidth = ui.get('width');

    // try to make the img full width.  if the height is still within bounds, use it...
    const fullWidthHeight = availableWidth / aspectRatio;
    if (fullWidthHeight <= availableHeight) {
      return prefix({
        width: availableWidth,
      });
    }

    // ...otherwise make the image full height
    return prefix({
      height: availableHeight,
    });
  }

  private imgSvgSrc() {
    const width = this.aspectRatioDimensions.get('width');
    const height = this.aspectRatioDimensions.get('height');
    const rect = `<rect height='${height}' width='${width}' />`;
    const svg = `<svg xmlns='http://www.w3.org/2000/svg' width='${width}' height='${height}'>${rect}</svg>`;
    return `data:image/svg+xml;utf8,${svg}`;
  }

  public render() {
    const {
      children,
      className,
      element,
      innerClassName,
      wrapperClassName,
    } = this.props;

    const containerClassName = cn('fill-ar', className);

    const imgStyle = this.getImgStyle();

    return (
      <div className={containerClassName}>
        <ReactResizeDetector
          onResize={this.handleResize}
          handleWidth
          handleHeight
        />
        <div className={cn('fill-ar__inner', innerClassName)}>
          <div className="fill-ar__aspect">
            <img
              className="fill-ar__img"
              src={this.imgSvgSrc()}
              style={imgStyle}
            />
            <div className={cn('fill-ar__element-wrapper', wrapperClassName)}>
              {element}
            </div>
          </div>
          {children}
        </div>
      </div>
    );
  }
}
