import cn from 'classnames';
import * as React from 'react';

import { podcastSidebarBlock as block } from '../utils';

export interface PodcastSidebarItemProps
  extends Pick<React.HTMLProps<HTMLDivElement>, 'onClick'> {
  children?: React.ReactNode;
  className?: string;
  flippedProps?: any;
}

const PodcastSidebarItem: React.FC<PodcastSidebarItemProps> = ({
  children,
  className,
  flippedProps,
  onClick,
}) => (
  <div
    className={cn(block('item'), className)}
    onClick={onClick}
    {...flippedProps}
  >
    {children}
  </div>
);

export default PodcastSidebarItem;
