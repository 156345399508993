import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';

import DurationLimitErrorModalContentsContainer from './DurationLimitErrorModalContentsContainer';

const DurationLimitErrorModal: React.FunctionComponent = () => (
  <ConnectedModal name="DurationLimitErrorModal" title="duration limit">
    <DurationLimitErrorModalContentsContainer />
  </ConnectedModal>
);

export default DurationLimitErrorModal;
