import * as React from 'react';

import { IconProps } from './types';

interface IProps extends IconProps {
  gradient?: boolean;
}

const SaveAsTemplate: React.SFC<IProps> = ({
  fill = 'currentColor',
  gradient,
  ...restProps
}) => {
  const svgPathProps: any = {
    fill,
  };

  if (gradient) {
    svgPathProps.fill = 'url(#template-gradient)';
    svgPathProps.filter = 'url(#template-filter)';
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      viewBox="0 0 22 21"
      {...restProps}
    >
      <path
        {...svgPathProps}
        fillRule="nonzero"
        d="M12.425 2.652H2.462a.818.818 0 0 0-.81.826v14.867c0 .457.364.826.81.826h14.709c.446 0 .81-.369.81-.826v-7.442a.826.826 0 1 1 1.652 0v7.442a2.47 2.47 0 0 1-2.462 2.477H2.462A2.47 2.47 0 0 1 0 18.345V3.478A2.47 2.47 0 0 1 2.462 1h9.963a.826.826 0 1 1 0 1.652zm.19 7.701c.333.19.332.66 0 .85l-3.73 2.14c-.335.194-.766-.038-.766-.424V8.636c0-.43.464-.599.767-.425l3.729 2.142zM20.55 0c.793 0 1.437.643 1.437 1.437V7.84a.479.479 0 0 1-.847.306l-1.846-2.217a.479.479 0 0 0-.734-.004l-1.898 2.242a.479.479 0 0 1-.845-.31V1.437c0-.794.644-1.437 1.437-1.437h3.296z"
      />
    </svg>
  );
};

export default SaveAsTemplate;
