import cn from 'classnames';
import React from 'react';

import FontAwesome, { IconProp } from 'components/FontAwesome';

import ToolbarButtonV2 from '../ToolbarButtonV2';
import { block as textToolbarBlock } from '../utils';
import { TEXT_ALIGN_LIST, TextAlign } from './types';

const ALIGN_ICON_MAP: Record<TextAlign, IconProp> = {
  center: 'align-center',
  left: 'align-left',
  right: 'align-right',
};

interface TextAlignSelectorProps {
  onChange: (nextAlign: TextAlign) => void;
  value: TextAlign;
}

const TextAlignSelector: React.FunctionComponent<TextAlignSelectorProps> = props => {
  const { onChange, value } = props;

  const handleSwitchAlign = React.useCallback(() => {
    const currIndex = TEXT_ALIGN_LIST.indexOf(value);
    const nextIndex =
      currIndex >= TEXT_ALIGN_LIST.length - 1 ? 0 : currIndex + 1;
    onChange(TEXT_ALIGN_LIST[nextIndex]);
  }, [onChange, value]);

  return (
    <ToolbarButtonV2 onPress={handleSwitchAlign}>
      <FontAwesome
        className={cn(textToolbarBlock('text-align'), textToolbarBlock('icon'))}
        icon={ALIGN_ICON_MAP[value]}
      />
    </ToolbarButtonV2>
  );
};

export default TextAlignSelector;
