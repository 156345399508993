import cn from 'classnames';
import React from 'react';
import { H1, H2 } from 'components/Heading';
import { block } from './utils';

export interface ModalHeadingProps {
  className?: string;
  icon?: React.ReactNode;
  title?: string;
  subtitle?: React.ReactNode;
}

const ModalHeading: React.FC<ModalHeadingProps> = ({
  className,
  icon,
  subtitle,
  title,
}) => (
  <div className={cn(block('heading'), className)}>
    {icon && <div className={block('icon')}>{icon}</div>}
    {title && <H1 className={block('title')}>{title}</H1>}
    {typeof subtitle === 'string' ? (
      <H2 className={block('subtitle')}>{subtitle}</H2>
    ) : (
      React.isValidElement(subtitle) &&
      React.cloneElement(subtitle, {
        className: cn(block('subtitle'), subtitle.props.className),
      })
    )}
  </div>
);

export default ModalHeading;
