import { OnboardingName } from 'blocks/OnboardingAnimations/types';
import { updateUserPreferences } from 'redux/middleware/api/user-service/actions';
import { ThunkAction } from 'redux/types';
import {
  getOnboarding,
  getOnboardingViewConfirmation,
} from '../../middleware/api/headliner-user-service';
import { setUserContext } from '../auth/actions';
import { loadExperiments } from '../experiments/actions';
import { saveCohortSurveyResponse } from '../survey/actions';
import { Type } from './action-types';
import { GetOnboardingSuccessAction, OnboardingWizardResponse } from './types';

export const getOnboardingAction = (
  name: OnboardingName,
): ThunkAction<Promise<GetOnboardingSuccessAction>> => dispatch =>
  dispatch(getOnboarding(name))
    .then(({ response: payload }) =>
      dispatch({
        payload,
        type: Type.ONBOADING_GET_SUCCESS,
      } as GetOnboardingSuccessAction),
    )
    .catch(err => {
      dispatch({
        ...err,
        type: Type.ONBOADING_GET_FAILURE,
      });
      throw err;
    });

export const getOnboardingViewConfirmationAction = (
  name: OnboardingName,
): ThunkAction<void> => dispatch => {
  dispatch({
    type: Type.ONBOADING_GET_VIEW_CONFIRMATION_REQUEST,
  });

  dispatch(getOnboardingViewConfirmation(name))
    .then(() =>
      dispatch({
        type: Type.ONBOADING_GET_VIEW_CONFIRMATION_SUCCESS,
        payload: { name },
      }),
    )
    .catch(err => {
      dispatch({
        ...err,
        type: Type.ONBOADING_GET_VIEW_CONFIRMATION_FAILURE,
      });
      throw err;
    });
};

export const saveOnboardingWizardResponse = (
  response: OnboardingWizardResponse,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    type: Type.ONBOARDING_WIZARD_COMPLETE_REQUEST,
  });

  const { podcastYesNo, subscribeToNewsletter, usage, referrer } = response;

  try {
    await Promise.all([
      dispatch(saveCohortSurveyResponse(podcastYesNo, usage, referrer)),
      dispatch(updateUserPreferences(null, subscribeToNewsletter, null, null)),
    ]);
    dispatch(setUserContext());
    await dispatch(loadExperiments());
    dispatch({ type: Type.ONBOARDING_WIZARD_COMPLETE_SUCCESS });
  } catch (err) {
    dispatch({ type: Type.ONBOARDING_WIZARD_COMPLETE_FAILURE });
    throw err;
  }
};

export const clearOnboarding = () => dispatch =>
  dispatch({ type: Type.ONBOADING_CLEAR });
