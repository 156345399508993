import dayjs from 'dayjs';

import { EPISODE_MAX_DURATION_UPGRADE_MS_VALUE } from 'utils/constants';

export const DEFAULT_MAX_DURATION_MILLIS = dayjs
  .duration(2, 'hours')
  .asMilliseconds();

export const getFullEpisodeMaxDurationMillis = (
  maxDurationHours: number,
  usePlanEpisodeMaxDuration: boolean,
): number => {
  return usePlanEpisodeMaxDuration
    ? dayjs.duration(maxDurationHours, 'hours').asMilliseconds()
    : DEFAULT_MAX_DURATION_MILLIS;
};

export const getMaxDurationString = (maxDurationMillis: number): string => {
  return dayjs.duration(maxDurationMillis, 'milliseconds').format('H [hours]');
};

export const isEpisodeTooLong = (
  episodeDurationMillis: number,
  maxDurationMillis: number,
): boolean => episodeDurationMillis > maxDurationMillis;

export const isEpisodeSelectableWithUpgrade = (
  episodeDurationMillis: number,
): boolean => episodeDurationMillis <= EPISODE_MAX_DURATION_UPGRADE_MS_VALUE;

export const getSearchResultConfig = (opts: {
  episodeDurationMillis: number;
  isFreeOrBasicUser: boolean;
  planMaxDurationHours: number;
  usePlanEpisodeMaxDuration: boolean;
}) => {
  const {
    episodeDurationMillis,
    isFreeOrBasicUser,
    planMaxDurationHours,
    usePlanEpisodeMaxDuration,
  } = opts;

  const maxDurationMillis = getFullEpisodeMaxDurationMillis(
    planMaxDurationHours,
    usePlanEpisodeMaxDuration,
  );
  const isTooLong = isEpisodeTooLong(episodeDurationMillis, maxDurationMillis);
  const canSelectWithUpgrade = isEpisodeSelectableWithUpgrade(
    episodeDurationMillis,
  );

  // if episode duration is within the acceptable values, disabled is set to false and no tooltip is
  // returned.
  if (!isTooLong) {
    return { disabled: false, tooltipText: undefined };
  }

  // episodes are targetted to ones that can be selected by upgrading when user is a free one,
  // plan max duration usage is enabled and episode duration is higher than plan's max but lower
  // than paid plan target max
  const canUpgradePlanForSelect =
    isFreeOrBasicUser && usePlanEpisodeMaxDuration && canSelectWithUpgrade;

  // if upgrade condition is met, disabled is set to true and tooltip text set to show that the
  // episode can be selected if the user upgrades his plan
  if (canUpgradePlanForSelect) {
    return {
      disabled: true,
      tooltipText: `Upgrade to use audio files longer than ${getMaxDurationString(
        maxDurationMillis,
      )}`,
    };
  }

  // if plan max duration is enabled but episode can no be selected even with a plan upgrade, the
  // default paid max episode duration value will be used for message. Otherwise plan duration will
  // be used.
  const useDefaultMaxDurationTarget =
    usePlanEpisodeMaxDuration && !canSelectWithUpgrade;
  const maxDurationText = useDefaultMaxDurationTarget
    ? getMaxDurationString(EPISODE_MAX_DURATION_UPGRADE_MS_VALUE)
    : getMaxDurationString(maxDurationMillis);

  // under this condition, disabled will be set to true, and tooltip message will be populated with
  // the correct max duration value
  return {
    disabled: true,
    tooltipText: `Sorry, we currently can't add episodes over ${maxDurationText}`,
  };
};
