import * as React from 'react';
import { noop } from 'underscore';

import FontAwesome from 'components/FontAwesome';
import { ProjectTileControl } from '../types';
import { block } from '../utils';
import ProjectTileButton from './ProjectTileButton';

interface IProps {
  disabledCopyButton: boolean;
  lastSuccessfulVideoWid?: string;
  onClick?: (type: ProjectTileControl) => void;
}

export default class ProjectTileControls extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onClick: noop,
  };

  private handleDeleteClick = () => this.props.onClick('delete');

  private handleCopyClick = () => this.props.onClick('copy');

  private handleDownloadClick = () => this.props.onClick('download');

  public render() {
    const { disabledCopyButton, lastSuccessfulVideoWid } = this.props;

    const hasExport = !!lastSuccessfulVideoWid;

    return (
      <div className={block('controls')}>
        <ProjectTileButton onClick={this.handleDeleteClick}>
          <FontAwesome icon="trash-alt" size="lg" />
          <span>delete</span>
        </ProjectTileButton>
        <ProjectTileButton
          disabled={disabledCopyButton}
          onClick={disabledCopyButton ? undefined : this.handleCopyClick}
        >
          <FontAwesome
            icon="copy"
            regular
            size="lg"
            data-joyride-tooltip="copy"
          />
          <span>copy</span>
        </ProjectTileButton>
        <ProjectTileButton
          disabled={!hasExport}
          href={hasExport ? `/download/${lastSuccessfulVideoWid}` : undefined}
          target="_blank"
          onClick={hasExport ? this.handleDownloadClick : undefined}
        >
          <FontAwesome icon="share" size="lg" />
          <span>share</span>
        </ProjectTileButton>
      </div>
    );
  }
}

export { IProps as ProjectTileControlsProps };
