import { useToggle } from '@sparemin/blockhead';
import React from 'react';

import { ICaptions } from 'utils/embed/captions/types';

import CaptionsStyleEditor from './CaptionsStyleEditor';
import LegacyCaptionsStyleEditor from './LegacyCaptionsStyleEditor';

interface CaptionsStyleEditorContainerProps {
  onHide: () => void;
  onOpen: () => void;
  onSubmit: (
    style: ICaptions['style'],
    maxChars: number,
    templateId: string,
    rechunk: boolean,
  ) => void;
  show: boolean;
}

const CaptionsStyleEditorContainer: React.FunctionComponent<CaptionsStyleEditorContainerProps> = props => {
  const { onHide, onOpen, onSubmit, show } = props;

  const { toggle: onToggleLegacyMode, value: showLegacy } = useToggle(false);

  // In order to have a smooth experience with the modals switch, it is required
  // to gracefully close the current modal (either legacy or new), and then re open
  // the new one. For this reason the switch flow instead of just toggling the flag
  // it will hide the current modal and the open the new one.
  const handleSwitchLegacyMode = React.useCallback(() => {
    onHide();
    onToggleLegacyMode();
    onOpen();
  }, [onHide, onOpen, onToggleLegacyMode]);

  return (
    <>
      <LegacyCaptionsStyleEditor
        onHide={onHide}
        onSubmit={onSubmit}
        onSwitchLegacyMode={handleSwitchLegacyMode}
        show={showLegacy && show}
      />
      <CaptionsStyleEditor
        onHide={onHide}
        onSubmit={onSubmit}
        onSwitchLegacyMode={handleSwitchLegacyMode}
        show={!showLegacy && show}
      />
    </>
  );
};

export default CaptionsStyleEditorContainer;
