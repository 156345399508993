import React from 'react';

import { IconProps } from './types';

const Stack: React.FC<IconProps> = ({ ...svgProps }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="m.242 2.891 4.55 2.064a.5.5 0 0 0 .416 0l4.55-2.064c.323-.147.323-.635 0-.782L5.207.045a.5.5 0 0 0-.416 0L.242 2.11c-.323.147-.323.635 0 .782zm9.516 1.724-1.135-.514-3.157 1.431a1.12 1.12 0 0 1-.932 0l-3.157-1.43-1.135.513c-.323.147-.323.635 0 .782l4.55 2.062c.133.06.283.06.416 0l4.55-2.062c.323-.147.323-.635 0-.782zm0 2.496L8.628 6.6 5.465 8.032a1.12 1.12 0 0 1-.932 0L1.373 6.6l-1.13.512c-.324.147-.324.635 0 .782l4.549 2.062c.133.06.283.06.416 0l4.55-2.062c.323-.147.323-.635 0-.782z"
      fill="#DCE1EB"
      fillRule="nonzero"
    />
  </svg>
);

export default Stack;
