import * as React from 'react';

type Hook<T, U> = (...args: T[]) => U;

interface UseHookProps<T, U> {
  args: T[];
  hook: Hook<T, U>;
  children?: (props: U) => React.ReactElement;
}

export function UseHook<T, U>({
  args,
  children,
  hook,
}: UseHookProps<T, U>): React.ReactElement {
  return <>{children(hook(...args))}</>;
}
