import * as React from 'react';

import Tooltip, { TooltipProps } from 'components/Tooltip';
import { block } from './utils';

type PickedTooltipProps = Pick<TooltipProps, 'content' | 'id' | 'show'>;

export interface CustomizeStepTooltipProps extends PickedTooltipProps {
  children?: React.ReactNode;
}

const CustomizeStepTooltip: React.FC<CustomizeStepTooltipProps> = ({
  children,
  content,
  id,
  show,
}) => (
  <Tooltip
    {...{ content, id, show }}
    className={block('cta-tooltip')}
    placement="top"
  >
    {children}
  </Tooltip>
);

export default CustomizeStepTooltip;
