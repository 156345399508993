import { Map } from 'immutable';
import { RefAttributes } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import SlideEditor, { SlideEditorProps } from 'components/SlideEditor';
import {
  aspectRatioSelector,
  isAddingSlideSelector,
  lastUpdatedSlideTemplateSelector,
  toReplaceMediaElementSelector,
} from 'redux/modules/embed/selectors';
import { State } from 'redux/types';
import { PropsOf } from 'types';
import {
  ADD_SLIDE_DURATION_MILLIS,
  getSlidePlacement,
} from 'utils/embed/slideshow';

type StateProps = Pick<
  SlideEditorProps,
  | 'aspectRatio'
  | 'defaultState'
  | 'isSaving'
  | 'minDurationMillis'
  | 'params'
  | 'slideId'
>;

interface OwnProps
  extends RefAttributes<SlideEditor>,
    Pick<SlideEditorProps, 'defaultState'> {
  startMillis: number;
}

const defaultEffectSelector = createSelector(
  lastUpdatedSlideTemplateSelector,
  template => template && template.get('imageEffect'),
);

const defaultTransitionSelector = createSelector(
  lastUpdatedSlideTemplateSelector,
  template => template && template.get('entryTransition'),
);

const defaultReplacementStateSelector = createSelector(
  [
    toReplaceMediaElementSelector,
    defaultEffectSelector,
    defaultTransitionSelector,
  ],
  (mediaElement, defaultEffect, defaultTransition) => {
    if (!mediaElement) return undefined;
    return {
      constrain: true,
      effect: mediaElement.get('imageEffect') || defaultEffect,
      endMillis: mediaElement.get('endMillis'),
      placement: getSlidePlacement(mediaElement),
      startMillis: mediaElement.get('startMillis'),
      transition: mediaElement.get('entryTransition') || defaultTransition,
    };
  },
);

const startMillisSelector = (_: any, { startMillis }: OwnProps) => startMillis;

const defaultStateSelector = createSelector(
  [defaultReplacementStateSelector, startMillisSelector],
  (defaultReplacementState, startMillis) => {
    if (defaultReplacementState) return defaultReplacementState;

    return {
      startMillis,
      endMillis: startMillis + ADD_SLIDE_DURATION_MILLIS,
      placement: undefined,
    };
  },
);

const paramsSelector = createSelector(
  toReplaceMediaElementSelector,
  (mediaElement = Map()) => ({
    mediaToReplaceId: mediaElement.get('id'),
  }),
);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  aspectRatio: aspectRatioSelector(state),
  defaultState: ownProps.defaultState || defaultStateSelector(state, ownProps),
  isSaving: isAddingSlideSelector(state),
  minDurationMillis: spareminConfig.minSlideDurationMillis,
  params: paramsSelector(state),
  slideId: paramsSelector(state)?.mediaToReplaceId,
});

const component = connect(mapStateToProps, null, null, { forwardRef: true })(
  SlideEditor,
);

export default component;
export type AddSlideEditorProps = PropsOf<typeof component>;
