import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import AssetTile from 'components/AssetTile';
import AssetTileIncompatibleTemplate from 'components/AssetTile/AssetTileIncompatibleTemplate';
import { TemplateSelectorTileProps } from './types';
import { block, getDisplayAspectRatio } from './utils';

const { useCallback } = React;

const TemplateSelectorTile: React.FC<TemplateSelectorTileProps> = ({
  aspectRatioName,
  background,
  children,
  className,
  displayName,
  isCompatible,
  permanent,
  id,
  onClick = noop,
  onIncompatibleTemplateClick = noop,
  onDeleteClick = noop,
  onMouseOut,
  onMouseOver,
}) => {
  const handleClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  const handleDeleteClick = useCallback(
    (_, event: MouseEvent) => {
      event.stopPropagation();

      onDeleteClick(id);
    },
    [id, onDeleteClick],
  );

  const handleIncompatibleTemplateClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      onIncompatibleTemplateClick(id);
    },
    [id, onIncompatibleTemplateClick],
  );

  return (
    <AssetTile
      aspectRatio={getDisplayAspectRatio(aspectRatioName)}
      className={cn(
        block('tile', {
          [aspectRatioName]: !!aspectRatioName,
          'incompatible-template': !isCompatible,
        }),
        className,
      )}
      onClick={handleClick}
      onDeleteClick={handleDeleteClick}
      permanent={permanent}
      {...{ background, displayName, onMouseOver, onMouseOut, isCompatible }}
    >
      {!isCompatible && (
        <AssetTileIncompatibleTemplate
          onClick={handleIncompatibleTemplateClick}
        />
      )}
      {children}
    </AssetTile>
  );
};

export default TemplateSelectorTile;
