import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useOnChange from 'hooks/useOnChange';
import usePrevious from 'hooks/usePrevious';
import useSet from 'hooks/useSet';
import { episodeUrlSelector } from 'redux/modules/download';
import {
  lastCreatedPlaylistIdSelector,
  youtubeCategoriesSelector,
  youtubeLanguagesListSelector,
  youtubePlaylistsSelector,
  youtubeUserDataSelector,
} from 'redux/modules/social';
import { PostStatus } from 'redux/modules/social/constants';
import { YoutubeVideoVisibility } from 'types';
import { addSocialUTMSource } from 'utils/social/add-social-utm-source';

import useYoutubePostModalSelector from '../../useYoutubePostModalSelector';
import {
  getSelectedPlaylistsIds,
  YoutubePlaylistSelectorOption,
} from '../../YoutubePlaylistSelector';
import {
  UseAutopostingComposerStep,
  UseAutopostingComposerStepConfig,
} from './types';
import useAdditionalDetailsStep from './useAdditionalDetailsStep';
import usePostComposerStep from './usePostComposerStep';
import useVideoDescriptionStep from './useVideoDescriptionStep';
import useVideoTagsStep from './useVideoTagsStep';
import useVideoTitleStep from './useVideoTitleStep';

const useAutopostingComposerStep = (
  config: UseAutopostingComposerStepConfig,
): UseAutopostingComposerStep => {
  const {
    alert,
    activeStep,
    hideProgress,
    initialValues,
    onAdditionalStepClick,
    onCreatePlaylistClick,
    onHideModal,
    onInitialStepClick,
    onPostSuccess,
    onSubmit,
    onSwitchAccountClick,
    onVideoDescriptionClick,
    onVideoTagsClick,
    onVideoTitleClick,
    submitButtonLabel = 'post your video',
  } = config;

  // Playlist creation step should be considered as an "active step" for the composer.
  // As active step is used for resetting the composer state to the default one, it is
  // required to avoid default state reset when switching modals for creating a playlist.
  const isActive = [
    'composer',
    'playlist',
    'video-title-step',
    'video-description-step',
    'additional-details-step',
    'video-tags-step',
  ].includes(activeStep);

  const {
    defaultTitle,
    defaultTags,
    avatarSrc,
    postStatus,
    username,
  } = useYoutubePostModalSelector({
    defaultTitle: initialValues.title,
    defaultTags: !initialValues.tags?.length
      ? ['<categories>']
      : initialValues.tags,
  });

  const defaultDescription = initialValues.description;
  const [title, setTitle] = useState<string>(defaultTitle);
  const [description, setDescription] = useState<string>(defaultDescription);
  const [language, setLanguage] = useState<string>(initialValues.language);
  const [tags, setTags] = useState<string[]>(defaultTags);
  const [madeForKids, setMadeForKids] = useState<boolean>(
    initialValues.madeForKids,
  );
  const [categoryId, setCategoryId] = useState<number>(
    initialValues.categoryId,
  );
  const [visibility, setVisibility] = useState<YoutubeVideoVisibility>(
    initialValues.visibility,
  );
  const [prevActiveState, setPrevActiveState] = useState<boolean>(isActive);
  const [selectedPlaylistsIds, { initialize, toggle, reset }] = useSet<string>(
    new Set(initialValues.playlistsIds),
  );

  const isUploading = postStatus === PostStatus.IN_PROGRESS;

  const { playlists: userPlaylists } = useSelector(youtubePlaylistsSelector);
  const categories = useSelector(youtubeCategoriesSelector);
  const episodeUrl = useSelector(episodeUrlSelector);
  const languages = useSelector(youtubeLanguagesListSelector);
  const ytAccessData = useSelector(youtubeUserDataSelector);
  const prevPostStatus = usePrevious(postStatus);

  const handlePlaylistChange = (option: YoutubePlaylistSelectorOption) => {
    toggle(option.id);
  };

  const handleSubmit = useCallback(() => {
    onSubmit?.({
      defaultLanguage: language,
      title,
      description: addSocialUTMSource('youtube', episodeUrl, description),
      tags,
      madeForKids,
      categoryId,
      visibility,
      playlists: getSelectedPlaylistsIds(userPlaylists, selectedPlaylistsIds),
      // as some use cases where token can not be obtained from store, it can be tackeld
      // by returning access data from the modal
      ytAccessData,
    });
  }, [
    language,
    onSubmit,
    title,
    episodeUrl,
    description,
    tags,
    madeForKids,
    categoryId,
    selectedPlaylistsIds,
    userPlaylists,
    visibility,
    ytAccessData,
  ]);

  const handleSetDefaultProps = useCallback(() => {
    setTitle(defaultTitle);
    setDescription(defaultDescription);
    setTags(defaultTags);
    setMadeForKids(initialValues.madeForKids);
    setCategoryId(initialValues.categoryId);
    setVisibility(initialValues.visibility);
    setLanguage(initialValues.language);
    initialize(initialValues.playlistsIds);
  }, [
    defaultDescription,
    defaultTags,
    defaultTitle,
    initialValues.language,
    initialValues.madeForKids,
    initialValues.categoryId,
    initialValues.playlistsIds,
    initialValues.visibility,
    initialize,
  ]);

  const postComposer = usePostComposerStep({
    title,
    description,
    alert,
    avatarSrc,
    isUploading,
    onCreatePlaylistClick,
    onVideoTitleClick,
    onVideoDescriptionClick,
    onAdditionalStepClick,
    onSubmit: handleSubmit,
    onHideModal,
    onSwitchAccountClick,
    submitButtonLabel,
    playlists: userPlaylists,
    onPlaylistChange: handlePlaylistChange,
    selectedPlaylistsIds,
    username,
  });

  const videoTitle = useVideoTitleStep({
    title,
    isActive: activeStep === 'video-title-step',
    readonly: false,
    onBackClick: onInitialStepClick,
    hideProgress,
    onTitleChange: setTitle,
    onHideModal,
  });

  const videoDescription = useVideoDescriptionStep({
    description,
    isActive: activeStep === 'video-description-step',
    readonly: false,
    onBackClick: onInitialStepClick,
    hideProgress,
    onDescriptionChange: setDescription,
    onHideModal,
  });

  const additionalDetails = useAdditionalDetailsStep({
    isActive:
      activeStep === 'additional-details-step' ||
      activeStep === 'video-tags-step',
    tags,
    categories,
    languages,
    visibility,
    madeForKids,
    categoryId,
    language,
    onBackClick: onInitialStepClick,
    onVideoTagsClick,
    onVisibilityChange: setVisibility,
    onMadeForKidsChange: setMadeForKids,
    onCategoryIdChange: setCategoryId,
    onHideModal,
    onLanguageChange: setLanguage,
  });

  const videoTags = useVideoTagsStep({
    tags,
    isActive: activeStep === 'video-tags-step',
    onBackClick: onAdditionalStepClick,
    hideProgress,
    onTagsChange: setTags,
    onHideModal,
  });

  useOnChange(useSelector(lastCreatedPlaylistIdSelector), {
    onChange: playlistId => {
      if (playlistId) {
        toggle(playlistId);
      }
    },
  });

  useEffect(() => {
    if (
      prevPostStatus === PostStatus.IN_PROGRESS &&
      postStatus === PostStatus.SUCCESS
    ) {
      handleSetDefaultProps();
      reset();

      onPostSuccess?.();
    }
  }, [handleSetDefaultProps, onPostSuccess, postStatus, prevPostStatus, reset]);

  if (prevActiveState !== isActive) {
    handleSetDefaultProps();
    setPrevActiveState(isActive);
  }

  return {
    composerSteps: [
      postComposer,
      videoTitle,
      videoDescription,
      additionalDetails,
      videoTags,
    ],
  };
};

export default useAutopostingComposerStep;
