import * as Sentry from '@sentry/browser';
import { uniq } from 'underscore';
import { EXPERIMENTS_NAMES } from 'blocks/ABTest';
import * as headlinerUserService from 'redux/middleware/api/headliner-user-service';
import { ThunkAction } from 'redux/types';
import fs from 'utils/fullstory';
import { setExperimentVariants } from '../mixpanel/actions';

export const loadExperiments = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  try {
    const experiments = uniq(Object.values(EXPERIMENTS_NAMES));

    fs.log(`experiments array: ${JSON.stringify(experiments)}`);

    if (experiments.length) {
      fs.log('experiments is not empty.  getting experiment variants');
      await dispatch(headlinerUserService.getExperimentVariant(experiments));
      fs.log('got experiment variants');
      dispatch(setExperimentVariants(experiments));
    }
  } catch (error) {
    fs.log('error', 'error getting experiments');
    Sentry.captureException(error);
  }
};
