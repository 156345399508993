import cn from 'classnames';
import * as React from 'react';

import { canvasBlock as block } from '../utils';

export interface LayerProps
  extends Pick<React.HTMLProps<HTMLDivElement>, 'onDoubleClick' | 'onWheel'> {
  active?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

/*
 * asset layer used in the EditorCanvas.  determines the z-index of assets
 * in the video
 */
const Layer: React.FC<LayerProps> = ({
  active,
  children,
  onDoubleClick,
  onWheel,
  className,
  style,
}) => (
  <div
    className={cn(block('layer', { active }), className)}
    {...{ onDoubleClick, onWheel, style }}
  >
    {children}
  </div>
);

export default Layer;
