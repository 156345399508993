import { Action } from 'redux';
import { identity } from 'underscore';

import { WithSentryMeta } from 'redux/middleware/sentry/types';
import { Type } from './action-types';
import {
  HeadlinerSentryMetadata,
  HeadlinerSentryTags,
  SentryThunkProxy,
} from './types';

const DEFAULT_ACTION = {
  type: Type.SENTRY,
};

export function sentryAction<A extends any[] = undefined, R = any>(
  action: (...args: A) => R,
  options: HeadlinerSentryMetadata,
): SentryThunkProxy<A, R>;
export function sentryAction<A extends Action>(
  action,
  options?: HeadlinerSentryMetadata,
): WithSentryMeta<A, HeadlinerSentryTags>;
export function sentryAction(action: any, options?: HeadlinerSentryMetadata) {
  const baseAction = action || DEFAULT_ACTION;

  const metadata = { sentry: options };

  // try to do something sensible if a function is passed as the action because
  // thunk middleware is being used.  wrap the function and add a custom property
  // to it
  if (typeof baseAction === 'function') {
    const proxyFn: SentryThunkProxy = (...args: any[]) => baseAction(...args);
    proxyFn.meta = metadata;
    return proxyFn;
  }

  return {
    ...baseAction,
    meta: metadata,
  } as any;
}

// following redux ducks pattern, we must export a reducer by default
export default identity;
