import { ActionButton, Tooltip, Undo } from '@sparemin/blockhead';
import * as React from 'react';

import { useDispatch } from 'react-redux';
import { waitForPodcastFeedRefresh } from 'redux/modules/podcast';
import { episodeExplorerBlock as block } from '../../utils';

export interface RefreshFeedButtonProps {
  podcastId: string;
  isVisible: boolean;
}

const RefreshFeedButton: React.FC<RefreshFeedButtonProps> = ({
  podcastId,
  isVisible,
}) => {
  const dispatch = useDispatch();

  const handleRefreshPodcastFeed = React.useCallback(async (): Promise<
    void
  > => {
    if (!podcastId) {
      return;
    }

    dispatch(waitForPodcastFeedRefresh(podcastId));
  }, [dispatch, podcastId]);

  return (
    <Tooltip
      className={block('refresh-container')}
      contents="Refresh feed"
      placement="top"
      offset={-16}
      arrow
    >
      <ActionButton
        variant="frosted"
        className={block('refresh-button', {
          hovering: isVisible,
        })}
        onPress={handleRefreshPodcastFeed}
      >
        <Undo className={block('refresh-icon')} />
      </ActionButton>
    </Tooltip>
  );
};

export default RefreshFeedButton;
