import cn from 'classnames';
import React from 'react';
import Select, { Overrides } from 'components/Select';
import { ShareFormField } from 'components/ShareForm';
import {
  YoutubeVisibilitySelectorOption,
  YoutubeVisibilitySelectorProps,
} from './types';
import { block, getOptions, getVisibilityOption } from './utils';
import VisibilityOption from './VisibilityOption';

const selectComponentOverrides: Overrides<YoutubeVisibilitySelectorOption> = {
  control: {
    props: p => ({
      ...p,
      className: cn(block('control'), p.className),
    }),
  },
  option: {
    component: VisibilityOption,
  },
};

const YoutubeVisibilitySelector: React.FC<YoutubeVisibilitySelectorProps> = ({
  onChange,
  value,
}) => {
  const handleChange = (option: YoutubeVisibilitySelectorOption) => {
    onChange?.(option.value);
  };

  return (
    <ShareFormField label="Visibility">
      <Select
        className={block()}
        onChange={handleChange}
        options={getOptions()}
        overrides={selectComponentOverrides}
        value={getVisibilityOption(value)}
      />
    </ShareFormField>
  );
};

export default YoutubeVisibilitySelector;
