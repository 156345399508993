import {
  PlanName,
  PurchaseableTier,
  Tier,
} from 'redux/middleware/api/plan-service/types';
import bem from 'utils/bem';
import { BillingCyclePlanOptions } from '../BillingCycleModal/BillingCycleOptions';

export const block = bem('add-billing-cycle-modal');

export const OPTIONS_BY_TIER: Record<
  PurchaseableTier,
  BillingCyclePlanOptions
> = {
  [Tier.PRO]: {
    monthly: PlanName.PRO_20211112_MONTHLY,
    yearly: PlanName.PRO_20211112_YEARLY,
  },
  [Tier.BASIC]: {
    monthly: PlanName.BASIC_20211112_MONTHLY,
    yearly: PlanName.BASIC_20211112_YEARLY,
  },
  [Tier.UNLIMITED]: {
    monthly: PlanName.UNLIMITED_20211112_MONTHLY,
    yearly: PlanName.UNLIMITED_20211112_YEARLY,
  },
};
