import React from 'react';

import { useTemplateState } from '../../context/VideoTemplateStateContext';
import TextAssetLegacyModal, {
  TextAssetLegacyModalProps,
} from './TextAssetLegacyModal';
import TextAssetModal, { TextAssetModalProps } from './TextAssetModal';

export type TextAssetModalType = 'add' | 'edit';

export type PickedTextAssetLegacyModalProps = Pick<
  TextAssetLegacyModalProps,
  | 'banner'
  | 'defaultText'
  | 'editable'
  | 'onCancel'
  | 'onDelete'
  | 'onExited'
  | 'onSubmit'
  | 'show'
  | 'showEmoji'
  | 'textOverlay'
  | 'title'
>;

export interface TextAssetModalWrapperLegacyModeProps
  extends PickedTextAssetLegacyModalProps {
  assetId: string;
  isLegacy: true;
  type: TextAssetModalType;
}

export type PickedTextAssetModalProps = Pick<
  TextAssetModalProps,
  | 'defaultText'
  | 'editable'
  | 'onCancel'
  | 'onDelete'
  | 'onExited'
  | 'onSubmit'
  | 'show'
  | 'showEmoji'
  | 'subTitle'
  | 'textOverlay'
  | 'title'
>;

export interface TextAssetModalWrapperNonLegacyModeProps
  extends PickedTextAssetModalProps {
  assetId: string;
  isLegacy: false;
  isV2CompatibilityMode: boolean;
  onOpenLegacyModal?: () => void;
  type: TextAssetModalType;
}

export type TextAsseModalWrapperProps =
  | TextAssetModalWrapperLegacyModeProps
  | TextAssetModalWrapperNonLegacyModeProps;

const TextAssetModalWrapper: React.FunctionComponent<TextAsseModalWrapperProps> = props => {
  const {
    defaultText,
    editable = true,
    assetId,
    isLegacy,
    onCancel,
    onDelete,
    onExited,
    onSubmit,
    show,
    showEmoji = true,
    textOverlay,
    title,
    type,
  } = props;

  const state = useTemplateState();
  const { aspectRatio, backgroundColor } = state;

  const isEdit = type === 'edit';

  // explicit check with true allow a proper type inference when the if
  // condition is not met.
  if (isLegacy === true) {
    const { banner } = props;

    return (
      <TextAssetLegacyModal
        {...{
          assetId,
          aspectRatio,
          backgroundColor,
          banner,
          defaultText,
          editable,
          isEdit,
          onCancel,
          onDelete,
          onExited,
          onSubmit,
          show,
          showEmoji,
          textOverlay,
          title,
        }}
      />
    );
  }

  const { isV2CompatibilityMode, onOpenLegacyModal, subTitle } = props;

  return (
    <TextAssetModal
      {...{
        aspectRatio,
        assetId,
        backgroundColor,
        defaultText,
        editable,
        isEdit,
        isV2CompatibilityMode,
        onCancel,
        onDelete,
        onExited,
        onOpenLegacyModal,
        onSubmit,
        show,
        showEmoji,
        subTitle,
        textOverlay,
        title,
      }}
    />
  );
};

export default TextAssetModalWrapper;
