import { Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import { AiAsset } from 'redux/middleware/api/image-search-service';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { block } from './utils';

export interface AIGenerationResultsProps {
  aiAssets: AiAsset[];
  overlayActions?: (result: AiAsset) => React.ReactNode;
}

const AIGenerationResults: React.FC<AIGenerationResultsProps> = ({
  aiAssets,
  overlayActions,
}) => {
  const aspectRatio = getAspectRatioName(
    aiAssets[0]?.height,
    aiAssets[0]?.width,
  );

  return (
    <Spacer
      orientation="horizontal"
      align="center"
      justify="center"
      className={block('ai-generated-container')}
    >
      <div
        className={cn(
          block('ai-generated-results', { [aspectRatio]: !!aspectRatio }),
        )}
      >
        {aiAssets.map(asset => (
          <div
            className={cn(
              block('ai-result-container', { [aspectRatio]: !!aspectRatio }),
            )}
            key={asset.url}
          >
            <img src={asset.url} className={block('ai-result')} />

            {overlayActions && (
              <Spacer
                orientation="vertical"
                space="20px"
                align="center"
                justify="center"
                className={block('ai-generated-overlay-actions')}
              >
                {overlayActions(asset)}
              </Spacer>
            )}
          </div>
        ))}
      </div>
    </Spacer>
  );
};

export default AIGenerationResults;
