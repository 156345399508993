import dayjs from 'dayjs';

import bem from 'utils/bem';

export const block = bem('free-transcription-balance');

type Duration = ReturnType<typeof dayjs.duration>;

export function hasTimeRemaining(duration: Duration): boolean {
  return Math.floor(duration.asSeconds()) > 0;
}

export function timeRemainingFormat(duration: Duration) {
  const hours = duration.asHours();
  if (hours >= 1) {
    const roundedHours = Math.round(hours);
    return `H [${roundedHours === 1 ? 'hour' : 'hours'}]`;
  }

  const mins = Math.floor(duration.asMinutes());
  if (mins >= 1) {
    return 'm [min]';
  }

  return 's [sec]';
}
