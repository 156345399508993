import * as React from 'react';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import _ from 'underscore';

import { SaveAsTemplateButton } from 'blocks/SaveAsTemplate';
import RevisionHistory from 'containers/RevisionHistory';
import { DEFAULT_PROJECT_NAME } from 'utils/constants';
import * as routeUtils from 'utils/routes';
import HelpDropdown from '../../components/DropdownMenu/HelpDropdown';
import UserDropdown from '../../components/DropdownMenu/UserDropdown';
import NavInput from '../../components/NavInput';
import * as types from '../../types';
import { block } from '../../utils';
import AuthButtons from '../AuthButtons';
import HeadlinerNavLogo from '../HeadlinerNavLogo';
import PricingTierButton from '../PricingTierButton';

export interface IProps {
  isSavingProjectName?: boolean;
  loggedIn?: boolean;
  loggedInUserEmail: string;
  onSubmitProjectName?: (name: string) => void;
  projectName?: string;
  reducedNavbar: boolean;
}

export default class AuthNavBar extends React.Component<IProps, types.IState> {
  public static defaultProps: Partial<IProps> = {
    isSavingProjectName: false,
    onSubmitProjectName: _.noop,
  };

  private static isProjectActive() {
    return routeUtils.isProjectActive();
  }

  private static isAuthPage() {
    return routeUtils.isAuthActive();
  }

  private static isWizard() {
    return routeUtils.isWizardActive();
  }

  constructor(props) {
    super(props);

    this.state = {
      data: types.dataFactory({
        errorMessage: undefined,
      }),
    };
  }

  private handleSubmitProjectName = (value: string) => {
    const { onSubmitProjectName } = this.props;

    const name = value || DEFAULT_PROJECT_NAME;

    if (name.length < 3) {
      this.setErrorMessage('Project name must be at least 3 characters');
      return;
    }

    if (name.length > 256) {
      this.setErrorMessage('Project name must be at most 256 characters');
      return;
    }

    onSubmitProjectName(name);
  };

  private handleProjectNameChange = () =>
    this.setState(({ data }) => ({
      data: data.delete('errorMessage'),
    }));

  private setErrorMessage(message: string) {
    this.setState(({ data }) => ({
      data: data.set('errorMessage', message),
    }));
  }

  private hasReducedNavbar() {
    const { reducedNavbar } = this.props;

    return reducedNavbar;
  }

  private renderProjectName() {
    const { isSavingProjectName, projectName } = this.props;
    const { data } = this.state;

    return (
      <NavInput
        activeClassName={block('project-name', { active: true })}
        className={block('project-name')}
        errorMessage={data.get('errorMessage')}
        inputClassName={block('project-input')}
        isSaving={isSavingProjectName}
        onChange={this.handleProjectNameChange}
        onSubmit={this.handleSubmitProjectName}
        value={projectName}
      />
    );
  }

  private renderNavLeft() {
    return (
      <Nav className={block('left')}>
        {!this.hasReducedNavbar() && <HeadlinerNavLogo />}

        {AuthNavBar.isProjectActive() && (
          <>
            <li role="presentation" className={block('project')}>
              <a href={routeUtils.getProjectsPath()}>
                {this.renderProjectName()}
              </a>
            </li>

            {!this.hasReducedNavbar() && (
              <li role="presentation" className={block('save-as-template')}>
                <SaveAsTemplateButton from="AdvancedEditor" />
              </li>
            )}

            <NavItem>
              <RevisionHistory />
            </NavItem>
          </>
        )}
      </Nav>
    );
  }

  private renderNavRight() {
    const { loggedIn, loggedInUserEmail } = this.props;

    return (
      <Nav pullRight className={block('right')}>
        {!this.hasReducedNavbar() && !AuthNavBar.isWizard() && (
          <PricingTierButton />
        )}

        {!this.hasReducedNavbar() && (
          <HelpDropdown loggedInUserEmail={loggedInUserEmail} />
        )}

        {loggedIn && !AuthNavBar.isWizard() && (
          <UserDropdown
            reducedNavbar={this.hasReducedNavbar()}
            shouldLogoutRedirect={!routeUtils.isDownloadActive()}
          />
        )}

        <AuthButtons />
      </Nav>
    );
  }

  public render() {
    if (AuthNavBar.isAuthPage()) {
      return null;
    }

    return (
      <Navbar
        className={block({
          wizard: AuthNavBar.isWizard(),
        })}
        inverse
        fluid
      >
        {this.renderNavLeft()}
        {this.renderNavRight()}
      </Navbar>
    );
  }
}
