import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WaveformPresetTile } from 'components/WaveformPresetList';
import { defaultWaveformPrefOnlyEnabledSelector } from 'redux/modules/display-pref/selectors';
import { deleteWaveformPref } from 'redux/modules/user-pref/actions';
import {
  createWaveformPrefSelector,
  isDeletingWaveformPrefSelector,
} from 'redux/modules/user-pref/selectors';
import { Soundwave } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { formatSoundwaveFromConfig } from 'utils/embed/soundwave';
import { stringToViewport } from 'utils/placement';

interface Props {
  id: number;
  checked?: boolean;
  onSelect?: (soundwave: Soundwave) => void;
  onDeleted?: (waveformPrefId: number) => void;
}

const { useMemo } = React;

function WaveformPreset(props: Props) {
  const { id, checked, onSelect, onDeleted } = props;
  const dispatch = useDispatch();
  const isDeleting = useSelector(isDeletingWaveformPrefSelector(id));
  const locked = useSelector(defaultWaveformPrefOnlyEnabledSelector);
  const waveformPref = useSelector(
    useMemo(() => createWaveformPrefSelector(id), [id]),
  );
  const { waveformConfig, dimensionWidth, dimensionHeight } = waveformPref;
  const soundwave = useMemo<Soundwave>(
    () => formatSoundwaveFromConfig(waveformConfig)?.toJS(),
    [waveformConfig],
  );
  const handleDeleteClick = async () => {
    await dispatch(deleteWaveformPref(id));
    onDeleted?.(id);
  };
  const handleToggle = () => {
    if (!checked) {
      onSelect?.({
        ...soundwave,
        waveformPrefId: id,
      });
    }
  };

  return (
    <WaveformPresetTile
      color={soundwave?.waveColor}
      type={soundwave?.waveType}
      permanent={locked}
      aspectRatioName={getAspectRatioName(dimensionHeight, dimensionWidth)}
      {...stringToViewport({
        ...soundwave?.waveSize,
        ...soundwave?.wavePosition,
      })}
      checked={checked}
      value={id}
      onToggle={handleToggle}
      onDeleteClick={!isDeleting && handleDeleteClick}
    />
  );
}

export default WaveformPreset;
