import * as React from 'react';
import { Clock } from 'components/icons';
import PopperTile from '../Popper/PopperTile';
import TimerPopper, { TimerPopperProps } from './TimerPopper';

export type TimerTileProps = TimerPopperProps;

const TimerTile: React.FC<TimerTileProps> = ({ onChange, value }) => (
  <PopperTile icon={<Clock style={{ height: 24, width: 24 }} />} id="timer">
    <TimerPopper {...{ onChange, value }} />
  </PopperTile>
);

export default TimerTile;
