import ExtendoError from 'extendo-error';
import { RecordOf } from 'immutable';

import { PodcastSubscriptionType } from 'redux/middleware/api/podcast-service/types';
import { IEmbedExport } from 'redux/middleware/api/video-export-service/types';
import { Action } from 'redux/types';
import { DeepImmutableMap, IEmbedConfiguration, RequestStatus } from 'types';
import { Type } from './action-types';

export interface State {
  configuration: DeepImmutableMap<IEmbedConfiguration>;
  podcastInfo: DeepImmutableMap<IEmbedExport['podcastInfo']>;
  dpaPerformed: boolean;
  loadingState: RequestStatus;
  projectId: string;
  podcastEpisodeId: number;
  videoDurationMillis: number;
  videoFrequency: PodcastSubscriptionType['videoFrequency'];
  isPollingSocialPostCaptions: boolean;
  jobId: number;
  hasError: boolean;
}

export type DownloadState = RecordOf<State>;

export type DownloadSocialPlatforms =
  | 'facebook'
  | 'twitter'
  | 'instagram'
  | 'linkedin'
  | 'youtube'
  | 'tiktok';

type LoadDataRequestAction = Action<Type.LOAD_DATA_REQUEST>;
type LoadDataSuccessAction = Action<
  Type.LOAD_DATA_SUCCESS,
  {
    configuration: IEmbedConfiguration;
    podcastInfo: IEmbedExport['podcastInfo'];
    projectId: string;
    videoDurationMillis: number;
    videoFrequency: PodcastSubscriptionType['videoFrequency'];
    podcastEpisodeId?: number;
  }
>;
type LoadDataFailureAction = Action<Type.LOAD_DATA_FAILURE, string>;

type ClearAction = Action<Type.DOWNLOAD_CLEAR>;

type DownloadPageAction = Action<Type.DOWNLOAD_PAGE_ACTION>;

type CreateSocialPostCaptionsRequest = Action<
  Type.SOCIAL_POST_CAPTIONS_CREATE_REQUEST,
  {
    podcastEpisodeId: number;
  }
>;

type CreateSocialPostCaptionsSuccess = Action<
  Type.SOCIAL_POST_CAPTIONS_CREATE_SUCCESS,
  {
    jobId: number;
  }
>;

type CreateSocialPostCaptionsFailure = Action<
  Type.SOCIAL_POST_CAPTIONS_CREATE_FAILURE
>;

type GetSocialPostCaptionsRequest = Action<
  Type.SOCIAL_POST_CAPTIONS_POLLING_REQUEST
>;

type GetSocialPostCaptionsSuccess = Action<
  Type.SOCIAL_POST_CAPTIONS_POLLING_SUCCESS
>;

type GetSocialPostCaptionsFailure = Action<
  Type.SOCIAL_POST_CAPTIONS_POLLING_FAILURE
>;

type GetSocialPostCaptionsEnd = Action<Type.SOCIAL_POST_CAPTIONS_POLLING_END>;

export type DownloadAction =
  | LoadDataRequestAction
  | LoadDataSuccessAction
  | LoadDataFailureAction
  | ClearAction
  | DownloadPageAction
  | CreateSocialPostCaptionsRequest
  | CreateSocialPostCaptionsSuccess
  | CreateSocialPostCaptionsFailure
  | GetSocialPostCaptionsRequest
  | GetSocialPostCaptionsSuccess
  | GetSocialPostCaptionsFailure
  | GetSocialPostCaptionsEnd;

export class GetVideoExportStatusError extends ExtendoError {}
