import classNames from 'classnames';
import * as React from 'react';
import _ from 'underscore';

interface IProps<T = undefined> {
  className?: string;
  displayName?: string;
  disabled?: boolean;
  hoverElement?: React.ReactElement<any>;
  imageClassName?: string;
  onClick?: (params: T) => void;
  src?: string;
  params?: T;
}

export default class ImageButton<T = undefined> extends React.Component<
  IProps<T>
> {
  public static defaultProps: Partial<IProps> = {
    disabled: false,
    onClick: _.noop,
  };

  private handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const { disabled, onClick, params } = this.props;
    if (disabled) {
      e.preventDefault();
      return;
    }
    onClick(params);
  };

  public render() {
    const {
      className,
      disabled,
      displayName,
      hoverElement,
      imageClassName,
      src,
    } = this.props;

    const containerClassName = classNames('image-button', className);
    const imgClassName = classNames('image-button__img', imageClassName);

    return (
      <div className={containerClassName}>
        <div onClick={this.handleClick}>
          <img
            alt={displayName}
            className={imgClassName}
            // @ts-ignore: disable attr not in typescript def file for img}
            disabled={disabled}
            src={src}
          />
        </div>
        {hoverElement && (
          <div className="image-button__hover-overlay">{hoverElement}</div>
        )}
      </div>
    );
  }
}
