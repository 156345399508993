import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Flipped } from 'react-flip-toolkit';
import { ControlsIcon, Wave } from 'components/icons';
import ProgressList from 'components/ProgressList';
import { WizardSubmitProgress } from './types';

interface Props {
  progressId: WizardSubmitProgress;
  loading: boolean;
  offsetInDegrees: number;
  shouldFlip?: () => boolean;
}

type PickedItemProps = Pick<
  React.ComponentProps<typeof ProgressList.Item>,
  'color' | 'icon' | 'children'
>;

const progressToProps: Record<WizardSubmitProgress, PickedItemProps> = {
  [WizardSubmitProgress.AUDIO_UPLOADING]: {
    color: 'pink',
    icon: <FontAwesomeIcon icon="microphone" />,
    children: 'uploading your audio',
  },
  [WizardSubmitProgress.IMAGE_UPLOADING]: {
    color: 'green',
    icon: <FontAwesomeIcon icon="image" />,
    children: 'uploading your imagery',
  },
  [WizardSubmitProgress.WAVEFORM_GENERATING]: {
    color: 'blue',
    icon: <Wave width={20} />,
    children: 'generating waveform',
  },
  [WizardSubmitProgress.PROJECT_CREATING]: {
    color: 'purple',
    icon: <ControlsIcon width={20} />,
    children: 'setting up your project',
  },
  [WizardSubmitProgress.VIDEO_UPLOADING]: {
    color: 'orange',
    icon: <FontAwesomeIcon icon="video" />,
    children: 'uploading your video',
  },
};

export const WizardProgressItem: React.FC<Props> = props => {
  const { progressId, loading, offsetInDegrees, shouldFlip } = props;
  return (
    <Flipped
      flipId={`list-item-${progressId}`}
      stagger
      translate={false}
      scale={false}
      opacity
      shouldFlip={shouldFlip}
    >
      <ProgressList.Item
        {...progressToProps[progressId]}
        {...{ loading, offsetInDegrees }}
      />
    </Flipped>
  );
};

export default WizardProgressItem;
