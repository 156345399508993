import cn from 'classnames';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import Button, { ButtonProps } from 'components/Button';
import { H2, H3 } from 'components/Heading';
import bem from 'utils/bem';

type PickedButtonProps = Pick<ButtonProps, 'disabled' | 'onClick'> &
  Partial<Pick<LinkProps, 'to'>>;

export type Variant = 'default' | 'purple' | 'tapestry' | 'rainbow';

export interface CardCtaProps extends PickedButtonProps {
  className?: string;
  description?: React.ReactNode;
  icon: JSX.Element;
  label: React.ReactNode;
  params?: any;
  variant?: Variant;
}

const block = bem('card-cta');

const CardCta: React.FC<CardCtaProps> = ({
  className,
  variant = 'default',
  description,
  disabled,
  icon,
  label,
  onClick,
  params,
  to,
}) => (
  <Button
    className={cn(block({ [variant]: true }), className)}
    componentClass={to ? Link : undefined}
    to={to}
    {...{ disabled, onClick, params, to }}
  >
    <div className={block('icon-wrapper')}>
      {React.cloneElement(icon, {
        className: block('icon'),
        style: {
          ...icon.props.style,
          width: icon.props.width ?? 50,
        },
      })}
    </div>
    <div className={block('text')}>
      <H3 className={block('label')}>{label}</H3>
      {description && <H2 className={block('description')}>{description}</H2>}
    </div>
  </Button>
);

export default CardCta;
