import * as React from 'react';

import FileUploader, { FileUploaderProps } from 'components/FileUploader';

export type ImageUploaderProps = FileUploaderProps;

export const ImageUploader: React.SFC<ImageUploaderProps> = props => (
  <FileUploader {...props} />
);

ImageUploader.defaultProps = {
  accept: 'image/*',
};

export default ImageUploader;
