import cn from 'classnames';
import React from 'react';

import Button, { ButtonProps } from 'components/Button';
import { Omit } from 'types';
import { imageViewBlock as block } from '../utils';

export interface DynamicChildButtonProps
  extends Omit<ButtonProps, 'fluid' | 'shape'> {
  icon: React.ReactNode;
}

const DynamicChildButton: React.FC<DynamicChildButtonProps> = ({
  children,
  className,
  icon,
  ...props
}) => (
  <Button
    className={cn(block('dynamic-button'), className)}
    fluid
    shape="chip"
    {...props}
  >
    {React.isValidElement(icon) &&
      React.cloneElement(icon, {
        className: cn(block('dynamic-button-icon'), icon.props.className),
      })}
    {children}
  </Button>
);

export default DynamicChildButton;
