import { Copy, PaletteCircle, TabItem, Tabs } from '@sparemin/blockhead';
import React from 'react';
import AnimationsTab from '../../extensions/AnimationsTab/AnimationsTab';

import { useTextOverlay } from '../../state/TextOverlayProvider';
import { block } from '../../utils';
import TextOverlayToolbar from '../TextOverlayToolbar';

interface TextOverlayControlPaneTabsProps {
  showEmoji: boolean;
}

const TextOverlayControlPaneTabs: React.FunctionComponent<TextOverlayControlPaneTabsProps> = props => {
  const { showEmoji } = props;

  const { animationsTabExtension } = useTextOverlay();

  const tabs = React.useMemo(() => {
    const baseTabs = [
      {
        key: 'customizations',
        component: <TextOverlayToolbar showEmoji={showEmoji} />,
        icon: <PaletteCircle />,
        title: 'customizations',
      },
    ];

    if (animationsTabExtension.enabled) {
      baseTabs.push({
        key: 'animations',
        component: <AnimationsTab />,
        icon: <Copy />,
        title: 'animations',
      });
    }

    return baseTabs;
  }, [animationsTabExtension.enabled, showEmoji]);

  if (tabs.length <= 1) {
    return <TextOverlayToolbar showEmoji={showEmoji} />;
  }

  return (
    <Tabs className={block('tabs-wrapper')}>
      {tabs.map(tab => (
        <TabItem key={tab.key} icon={tab.icon} title={tab.title}>
          {tab.component}
        </TabItem>
      ))}
    </Tabs>
  );
};

export default TextOverlayControlPaneTabs;
