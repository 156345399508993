import * as React from 'react';

import FontAwesome from 'components/FontAwesome';

const EmptyPreview: React.SFC = () => (
  <div className="preview-pane__preview">
    <div className="preview-pane__empty-title">
      Start by adding an asset using the <FontAwesome icon="plus" /> signs below
    </div>
    <div className="preview-pane__empty-help">
      Your video preview will appear here
    </div>
  </div>
);

export default EmptyPreview;
