import React from 'react';

import { Alert } from 'components/icons';
import { PurchasePlatform } from 'types';

import { block, isMobileManagedSubscription } from './utils';

interface PaymentDetailsGateProps {
  subscriptionPurchasePlaform: PurchasePlatform;
}

const PaymentDetailsGate: React.FunctionComponent<PaymentDetailsGateProps> = props => {
  const { children, subscriptionPurchasePlaform } = props;

  if (isMobileManagedSubscription(subscriptionPurchasePlaform)) {
    return (
      <div className={block('container')}>
        <Alert background="transparent" fill="#8ca0c3" width={77} />
        <p className={block('text')}>
          You purchased your plan from our mobile app, please go there to make
          any changes.
        </p>
      </div>
    );
  }

  return <>{children}</>;
};

export default PaymentDetailsGate;
