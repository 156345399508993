import { TemplateEditorStateExport } from 'components/VideoTemplateEditor';
import { AspectRatioDimensions, AudioClipOffsets } from 'types';
import { TranscriptionFormValue } from '../types';

export enum TemplateWizardStep {
  ADD_AUDIO = 'add-audio',
  CLIP_AUDIO = 'clip-audio',
  CUSTOMIZE = 'customize',
  EXPORTING = 'exporting',
  SUBMITTING = 'submitting',
}

export interface SubmitConfig extends TemplateEditorStateExport {
  aspectRatio: AspectRatioDimensions;
  audioClip: AudioClipOffsets;
  projectName: string;
  templateId: string | undefined;
  transcription: TranscriptionFormValue;
}
