import { Type } from './action-types';
import { stateFactory } from './factories';
import { VideoEditAction, VideoEditState } from './types';

const defaultState = stateFactory();

export default function reducer(
  state: VideoEditState = defaultState,
  action: VideoEditAction,
) {
  switch (action.type) {
    case Type.VIDEO_EDIT_PREPARE_REQUEST: {
      const { assetType, url, revokable, poster } = action.payload;
      return state.withMutations(s =>
        s
          .set('assetType', assetType)
          .set('videoUrl', url)
          .set('revokable', revokable)
          .set('poster', poster)
          // This value is cleared in case the next request to prepare
          // does not contain a videoEntity
          .set('videoEntity', undefined),
      );
    }

    case Type.VIDEO_EDIT_PREPARE_SUCCESS: {
      const { duration } = action.payload;
      return state.withMutations(s =>
        s
          .set('durationSeconds', duration)
          .set('videoEntity', action.payload.videoEntity),
      );
    }

    case Type.VIDEO_EDIT_PREPARE_FAILURE: {
      const { error } = action;
      return state.set('error', error);
    }

    case Type.VIDEO_EDIT_CLEAR:
      return defaultState;

    default:
      return state;
  }
}
