import * as React from 'react';
import FontAwesome from 'components/FontAwesome';
import { InputAdornment } from 'components/Form';
import { block } from './utils';

interface Props {
  onClick: () => void;
}

const EmailAddressRemoveButton: React.FC<Props> = props => {
  const { onClick } = props;
  return (
    <InputAdornment
      position="end"
      onClick={onClick}
      className={block('remove-button')}
    >
      <FontAwesome icon="times" />
    </InputAdornment>
  );
};

export default EmailAddressRemoveButton;
