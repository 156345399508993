import React from 'react';
import { Youtube } from 'components/icons';
import SocialCircle from './SocialCircle';

export interface YouTubeSocialCircleProps {
  className?: string;
}

const YouTubeSocialCircle: React.FC<YouTubeSocialCircleProps> = ({
  className,
}) => (
  <SocialCircle background="white" className={className}>
    <Youtube height="100%" width="100%" />
  </SocialCircle>
);

export default YouTubeSocialCircle;
