import React from 'react';
import { TikTokSocialCircle } from 'components/SocialCircle';
import SocialButton, { SocialButtonProps } from './SocialButton';

export interface TikTokButtonProps
  extends Pick<SocialButtonProps, 'disabled' | 'onClick' | 'params' | 'size'> {}

const TikTokButton: React.FC<TikTokButtonProps> = ({
  disabled,
  onClick,
  size,
}) => (
  <SocialButton
    icon={<TikTokSocialCircle />}
    label="TikTok"
    {...{ disabled, onClick, size }}
  />
);

export default TikTokButton;
