import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { YoutubeVisibilitySelectorOption } from './types';
import { block } from './utils';

export interface VisibilityOptionProps {
  className?: string;
  option: YoutubeVisibilitySelectorOption;
  onClick?: (value: any) => void;
  selected?: boolean;
}

const { useCallback } = React;

const VisibilityOption: React.FC<VisibilityOptionProps> = ({
  className,
  onClick,
  option,
  selected,
}) => {
  const { label, value } = option;
  const handleClick = useCallback(() => onClick(value), [onClick, value]);

  return (
    <div
      className={cn(block('option', { selected }), className)}
      onClick={handleClick}
    >
      <span className={block('option-label')}>{label}</span>
    </div>
  );
};

VisibilityOption.defaultProps = {
  onClick: noop,
  selected: false,
};

export default VisibilityOption;
