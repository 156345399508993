import { Spacer } from '@sparemin/blockhead';
import React from 'react';

import DialogContent from 'components/DialogContent';
import { H2 } from 'components/Heading';
import ModalFooterButton from 'components/Modal/ModalFooterButton';
import SocialAccountInfo from 'components/SocialAccountInfo';
import { Step } from 'components/SteppedModal';

import useYoutubePostModalSelector from '../useYoutubePostModalSelector';
import { block } from '../utils';

export interface UseChannelNeededStepConfig {
  onClickEducationLink: () => void;
  onSwitchAccount: () => void;
}

const EDUCATION_LINK =
  'https://support.google.com/youtube/answer/1646861?hl=en';

const useChannelNeededStep = (config: UseChannelNeededStepConfig): Step => {
  const { onClickEducationLink, onSwitchAccount } = config;

  const { avatarSrc, username } = useYoutubePostModalSelector();

  return {
    title: 'channel needed',
    modalClassName: block('dialog-content'),
    component: (
      <DialogContent
        className={block('dialog')}
        details={
          <H2>
            Your account doesn’t have a channel yet, you’ll need to create one
            so that we have somewhere to post your videos to.
          </H2>
        }
        detailsClassName={block('dialog-details')}
        headerRow={
          <Spacer>
            <SocialAccountInfo
              avatarSrc={avatarSrc}
              onSwitchAccountClick={onSwitchAccount}
              username={username}
            />
          </Spacer>
        }
        onClose={() => null}
        title="You don’t have a YouTube channel"
        titleClassName={block('dialog-title')}
      />
    ),
    fluidFooterButtons: true,
    id: 'no-channel',
    renderFooterButtons: ({ submit }) => [
      <ModalFooterButton
        {...submit}
        href={EDUCATION_LINK}
        key="submit"
        onClick={onClickEducationLink}
        rel="noreferrer noopener"
        size="large"
        target="_blank"
        theme="submit"
      >
        learn how to add one
      </ModalFooterButton>,
    ],
  };
};

export default useChannelNeededStep;
