import cn from 'classnames';
import * as React from 'react';
import { blockNavigation } from './DropdownMenu/utils';

type DivProps = React.HTMLProps<HTMLDivElement>;

export interface NavIconProps extends DivProps {
  children?: React.ReactNode;
}

const NavIcon: React.SFC<NavIconProps> = ({ className, ...props }) => (
  <div className={cn(blockNavigation('icon'), className)} {...props} />
);

export default NavIcon;
