import cn from 'classnames';
import React, { useCallback } from 'react';

import SteppedModal from 'components/SteppedModal';
import { TikTokPostModalProps } from './types';
import { block } from './utils';

const TikTokPostModal = React.forwardRef<SteppedModal, TikTokPostModalProps>(
  (
    {
      title,
      show,
      defaultStep,
      steps,
      className,
      onStepChange,
      onHide,
      onExited,
    },
    ref,
  ) => {
    const handleHide = useCallback(() => {
      onHide();
    }, [onHide]);

    return (
      <SteppedModal
        baseClassName={cn(block(), className)}
        onHide={handleHide}
        {...{
          ref,
          title,
          show,
          defaultStep,
          steps,
          onStepChange,
          onExited,
        }}
      />
    );
  },
);

export default TikTokPostModal;
