import * as React from 'react';

import {
  RndFormatStyleRules,
  RndTextEditorData,
  RndTextHandlers,
} from 'components/RndTextEditor';
import TextWorkspace, {
  IProps as TextWorkspaceProps,
} from 'components/TextWorkspace';
import { EditorVideoFramePreview } from 'containers/VideoFramePreview';
import { Size } from 'types';
import { prefix } from 'utils/ui';

import { block } from '../../utils/block';

interface IProps extends Pick<TextWorkspaceProps, 'resizableEdges'> {
  data: RndTextEditorData;
  handlers: RndTextHandlers;
  formatStyleRules: RndFormatStyleRules;
  text?: string;
  aspectRatio?: number;
  resizing?: boolean;
  draggingEnabled?: boolean;

  onTextBoxDrag?: (handlers: RndTextHandlers, x: number, y: number) => void;
  onTextBoxDragStop?: (handlers: RndTextHandlers, x: number, y: number) => void;
  onTextBoxResizeStart?: (
    handlers: RndTextHandlers,
    width: number,
    height: number,
  ) => void;
  onTextBoxResizeStop?: (
    handlers: RndTextHandlers,
    width: number,
    height: number,
    x: number,
    y: number,
  ) => void;
  onWorkspaceSizeChange?: (
    handlers: RndTextHandlers,
    width: number,
    height: number,
  ) => void;
}

interface IState {
  workspaceSize: Size<number>;
}

class Workspace extends React.Component<IProps> {
  public static defaultProps = {
    draggingEnabled: true,
  };

  public state: Readonly<IState> = {
    workspaceSize: undefined,
  };

  private handleTextBoxDrag = ({ x, y }: { x: number; y: number }) => {
    const { handlers, onTextBoxDrag } = this.props;
    onTextBoxDrag(handlers, x, y);
  };

  private handleTextBoxDragStop = ({ x, y }: { x: number; y: number }) => {
    const { handlers, onTextBoxDragStop } = this.props;
    onTextBoxDragStop(handlers, x, y);
  };

  private handleTextBoxResizeStart = ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => {
    const { handlers, onTextBoxResizeStart } = this.props;
    onTextBoxResizeStart(handlers, width, height);
  };

  private handleTextBoxResizeStop = ({
    x,
    y,
    width,
    height,
  }: {
    x: number;
    y: number;
    width: number;
    height: number;
  }) => {
    const { handlers, onTextBoxResizeStop } = this.props;
    onTextBoxResizeStop(handlers, width, height, x, y);
  };

  private handleWorkspaceSizeChange = ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => {
    const { handlers, onWorkspaceSizeChange } = this.props;
    this.setState({ workspaceSize: { width, height } });
    onWorkspaceSizeChange(handlers, width, height);
  };

  public render() {
    const {
      data,
      formatStyleRules,
      text,
      aspectRatio,
      resizing,
      draggingEnabled,
      resizableEdges,
    } = this.props;
    const { workspaceSize } = this.state;

    const containerRules = { ...formatStyleRules(data.get('containerStyle')) };
    const textRules = formatStyleRules(data.get('textStyle'));

    const position = resizing
      ? undefined
      : {
          x: data.getIn(['position', 'left']),
          y: data.getIn(['position', 'top']),
        };

    const size = {
      height: data.getIn(['size', 'height']),
      width: data.getIn(['size', 'width']),
    };

    return (
      <TextWorkspace
        aspectRatio={aspectRatio}
        background={
          <EditorVideoFramePreview
            aspectRatio={aspectRatio}
            canvasDimensions={workspaceSize}
          />
        }
        className={block('workspace')}
        disabled={false}
        onTextBoxDrag={this.handleTextBoxDrag}
        onTextBoxDragStop={this.handleTextBoxDragStop}
        onTextBoxResizeStart={this.handleTextBoxResizeStart}
        onTextBoxResizeStop={this.handleTextBoxResizeStop}
        onWorkspaceSizeChange={this.handleWorkspaceSizeChange}
        textBoxPosition={position}
        textBoxSize={size}
        draggingEnabled={draggingEnabled}
        resizableEdges={resizableEdges}
      >
        <div className="text-workspace__input" style={prefix(containerRules)}>
          <span className="text-workspace__text" style={prefix(textRules)}>
            {text}
          </span>
        </div>
      </TextWorkspace>
    );
  }
}

export default Workspace;
export { IProps as WorkspaceProps };
