import cn from 'classnames';
import * as React from 'react';

import { TextAreaField, TextAreaFieldProps } from 'components/Form';
import CharacterCount from './CharacterCount';
import SummaryPanelTextContainer from './SummaryPanelTextContainer';
import SummaryStepPanel from './SummaryStepPanel';
import { block } from './utils';

interface ITextSummary {
  charCount?: number;
  text?: string;
}

interface IProps extends Pick<TextAreaFieldProps, 'onChange'> {
  hideTitle?: boolean;
  maxLength: number;
  value?: ITextSummary;
  solo?: boolean;
}

const SummaryPanel: React.SFC<IProps> = ({
  hideTitle,
  maxLength,
  solo,
  value,
  ...props
}) => {
  const { charCount = 0, text = '' } = value;

  return (
    <SummaryStepPanel
      className={cn(block('summary-panel', { solo }))}
      title={hideTitle ? undefined : 'Your script'}
    >
      <SummaryPanelTextContainer>
        <TextAreaField
          className={block('field')}
          controlClassName={block('input')}
          placeholder="Enter your text here and click Next to continue..."
          value={text}
          {...props}
        />
      </SummaryPanelTextContainer>
      <CharacterCount limit={maxLength} count={charCount} />
    </SummaryStepPanel>
  );
};

SummaryPanel.defaultProps = {
  value: {
    charCount: 0,
    text: '',
  },
};

export default SummaryPanel;
export { IProps as SummaryPanelProps };
