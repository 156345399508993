import React from 'react';

import FontAwesome from 'components/FontAwesome';
import CardCta, { CardCtaProps } from './CardCta';

export type VideoCardCtaProps = Omit<CardCtaProps, 'color' | 'icon'> & {
  iconStyle?: React.CSSProperties;
};

const VideoCardCta: React.FC<VideoCardCtaProps> = props => (
  <CardCta icon={<FontAwesome icon="video" />} {...props} />
);

export default VideoCardCta;
