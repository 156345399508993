import * as React from 'react';
import { noop } from 'underscore';

import Cropper, { CropperData, CropperProps } from 'components/Cropper';
import useObjectUrl from 'hooks/useObjectUrl';
import { Omit } from 'types';
import { downscaleImage, toBlob } from 'utils/image';
import LoadingImageOverlay from './LoadingImageOverlay';
import { DownloadFile, ImageSource, OnError } from './types';

const { useImperativeHandle, useRef } = React;

export interface ImageCropperProps extends Omit<CropperProps, 'src'> {
  fileType: string;
  onDownloadImage: DownloadFile;
  onError?: OnError;
  params?: any;
  src: ImageSource;
}

export interface ImageCropperValues {
  croppedImage: Blob;
  cropData: Omit<CropperData, 'croppedCanvas' | 'originalImageSrc'>;
  params: any;
}

export interface ImageCropperInstance {
  getValues: () => Promise<ImageCropperValues>;
}

const ImageCropperComponent = React.forwardRef<
  ImageCropperInstance,
  ImageCropperProps
>(
  (
    {
      aspectRatio,
      constrainImage,
      fileType,
      onDownloadImage,
      onError,
      params,
      src,
      ...props
    },
    ref,
  ) => {
    const objectUrl = useObjectUrl(src);

    const cropper = useRef<Cropper>(undefined);

    // TODO need to resize result for png's with transparent space
    useImperativeHandle(
      ref,
      () =>
        ({
          getValues: async () => {
            const {
              croppedCanvas,
              originalImageSrc,
              ...cropData
            } = cropper.current.getData();

            /*
             * When constrain image is not checked, the user can select outside of the
             * bounds of the image, so we need to get the image as a png to preserve
             * transparency
             */
            const imageFormat = constrainImage ? fileType : 'image/png';

            /*
             * downscale the cropped image again.  with no constrain image,
             * zooming out and creating a lot of transparency can lead to big
             * images
             */
            const rawCroppedImage = await toBlob(croppedCanvas, imageFormat);
            const croppedImage = await downscaleImage(
              rawCroppedImage,
              aspectRatio,
            );

            return {
              cropData,
              croppedImage,
              params,
            };
          },
        } as ImageCropperInstance),
    );

    return src && !objectUrl ? (
      <LoadingImageOverlay />
    ) : (
      <Cropper
        aspectRatio={aspectRatio}
        constrainImage={constrainImage}
        ref={cropper}
        src={src && objectUrl}
        {...props}
      />
    );
  },
);

ImageCropperComponent.defaultProps = {
  onError: noop,
};

export default ImageCropperComponent;
