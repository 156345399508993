import * as React from 'react';

function Chat(props) {
  return (
    <svg width={90} height={70} viewBox="0 0 90 70" {...props}>
      <path
        fill="#68D3CF"
        d="M65 25C65 11.187 50.453 0 32.5 0S0 11.188 0 25c0 5.36 2.203 10.297 5.938 14.375C3.844 44.094.39 47.844.343 47.891c-.344.359-.438.89-.235 1.359.204.469.641.75 1.141.75 5.719 0 10.453-1.922 13.86-3.906C20.14 48.547 26.093 50 32.5 50 50.453 50 65 38.812 65 25zm19.063 34.375C87.796 55.312 90 50.359 90 45c0-10.453-8.36-19.406-20.203-23.14.14 1.03.203 2.078.203 3.14 0 16.547-16.828 30-37.5 30-1.688 0-3.328-.125-4.953-.297C32.469 63.687 44.03 70 57.5 70c6.406 0 12.36-1.438 17.39-3.906C78.298 68.078 83.032 70 88.75 70c.5 0 .953-.297 1.14-.75.204-.453.11-.984-.234-1.36-.047-.046-3.5-3.78-5.594-8.515z"
      />
    </svg>
  );
}

export default Chat;
