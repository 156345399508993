import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { noop } from 'underscore';
import WaveSurferJs from 'wavesurfer.js';

import useMeasure from 'hooks/useMeasure';
import usePreviousRef from 'hooks/usePreviousRef';
import { createChainedFunction } from 'utils/functions';
import { WaveSurferFunctions, WaveSurferProps } from './types';
import useOptions from './useOptions';
import usePeaks from './usePeaks';
import usePlayback from './usePlayback';
import useResponsive from './useResponsive';
import useSeek from './useSeek';
import useVolume from './useVolume';
import useWaveSurferAudio from './useWaveSurferAudio';
import useWaveSurferEventHandlers from './useWaveSurferEventHandlers';
import useZoom from './useZoom';
import { withCurrent } from './utils';
import WaveSurferContext, {
  WaveSurferContainerContext,
} from './WaveSurferContext';

const WaveSurfer = React.forwardRef<WaveSurferFunctions, WaveSurferProps>(
  (props, ref) => {
    const { children, volume, width, zoom } = props;
    const options = useOptions(props);
    const [wavesurfer, setWs] = useState<WaveSurferJs>(null);
    const optionsRef = usePreviousRef(options);
    const [containerRef, { height }] = useMeasure();

    useEffect(() => {
      if (wavesurfer?.drawer.wrapper) {
        wavesurfer?.setHeight(height);
      }
    }, [height, wavesurfer]);

    const wavesurferRef = useRef<WaveSurferJs>();
    const getWaveSurfer = useCallback(() => wavesurferRef.current, []);
    const withWaveSurfer = withCurrent(wavesurferRef);
    const [isDisabled, setIsDisabled] = useState(options.interact);
    useResponsive({ ...props, options }, width, wavesurfer);

    const audioRef = useWaveSurferAudio(props);

    const setPlayback = usePlayback(props, getWaveSurfer);
    const seek = useSeek(props, getWaveSurfer);
    usePeaks(props, getWaveSurfer);

    const volumeRef = useVolume(volume);

    const zoomRef = useZoom(zoom);

    const eventHandlerRef = useWaveSurferEventHandlers({
      ...props,
      onReady: createChainedFunction(props.onReady, () =>
        withWaveSurfer(ws => {
          seek();
          setPlayback();
          ws.zoom(zoom);
        }),
      ),
    });

    const setWaveSurfer = useCallback(
      createChainedFunction(
        (ws: WaveSurferJs) => {
          wavesurferRef.current = ws;
        },
        eventHandlerRef,
        volumeRef,
        zoomRef,
        audioRef,
        setWs,
      ),
      [eventHandlerRef, audioRef, volumeRef],
    );

    const setContainerEl = useCallback(
      (el: HTMLDivElement) => {
        containerRef(el);
        if (el) {
          setWaveSurfer(
            WaveSurferJs.create({
              container: el,
              ...optionsRef.current,
            }),
          );
        }
      },
      [containerRef, optionsRef, setWaveSurfer],
    );

    useEffect(() => {
      return () => {
        if (wavesurferRef.current) {
          wavesurferRef.current.destroy();
        }
      };
    }, []);

    useEffect(() => {
      if (options.interact !== isDisabled) {
        setIsDisabled(options.interact);
        wavesurferRef.current?.toggleInteraction();
      }
    }, [isDisabled, options.interact]);

    useImperativeHandle(ref, () => ({ getWaveSurfer, withWaveSurfer }));

    return (
      <WaveSurferContainerContext.Provider value={setContainerEl}>
        <WaveSurferContext.Provider value={wavesurfer}>
          {children}
        </WaveSurferContext.Provider>
      </WaveSurferContainerContext.Provider>
    );
  },
);

WaveSurfer.defaultProps = {
  onAudioprocess: noop,
  onDestroy: noop,
  onError: noop,
  onFinish: noop,
  onInteraction: noop,
  onLoading: noop,
  onMute: noop,
  onPause: noop,
  onPlay: noop,
  onReady: noop,
  onScroll: noop,
  onSeek: noop,
  onVolume: noop,
  onWaveformReady: noop,
  onZoom: noop,
  options: {},
  playing: false,
  volume: 1,
  zoom: 0,
};

export default WaveSurfer;
