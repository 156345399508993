import { MediaActionButton } from '@sparemin/blockhead';
import React from 'react';

import Button from 'components/Button';
import FillAspectRatio from 'components/FillAspectRatio';
import { H1, H2 } from 'components/Heading';
import HiddenAudioPlayer from 'components/HiddenAudio';
import { HeadlinerMarkColor } from 'components/icons';
import ProgressOverlay from 'components/ProgressOverlay';
import ProjectVideoPreview from 'components/ProjectVideoPreview';
import { AspectRatioName } from 'types';
import { getAspectRatio, getAspectRatioValue } from 'utils/aspect-ratio';

import useAutogramPreview from './useAutogramPreview';
import { block } from './utils';

interface AutogramPreviewReadyStepProps {
  aspectRatioName: AspectRatioName;
  embedUrl: string;
  isSubmitting: boolean;
  onGoBack: () => void;
  onSubmit: () => void;
  recordingUrl: string;
}

const HEADY_ICON_SIZE = 75;

const AutogramPreviewReadyStep: React.FunctionComponent<AutogramPreviewReadyStepProps> = props => {
  const {
    aspectRatioName,
    embedUrl,
    isSubmitting,
    onGoBack,
    onSubmit,
    recordingUrl,
  } = props;

  const {
    audioPlayerRef,
    isLoaded,
    isMuted,
    isPlaying,
    onAudioReady,
    onEndReached,
    onPreviewReady,
    onToggleMuted,
    previewPlayerRef,
  } = useAutogramPreview({ embedUrl, recordingUrl });

  const mediaActionState = isMuted ? 'muted' : 'unmuted';

  return (
    <div className={block('content')}>
      <div className={block('content-pane', { left: true })}>
        <FillAspectRatio
          aspectRatio={getAspectRatioValue(aspectRatioName)}
          className={block('preview-box-wrapper')}
          element={
            <div>
              <div className={block('preview-loader')}>
                <ProgressOverlay in={!isLoaded} />
              </div>
              <div
                className={block('preview-controls-layer', {
                  muted: isMuted,
                  hidden: !isLoaded,
                })}
                onClick={onToggleMuted}
              >
                <MediaActionButton
                  containerAs="div"
                  onPress={onToggleMuted}
                  state={mediaActionState}
                  variant="frosted"
                />
              </div>

              <ProjectVideoPreview
                ref={previewPlayerRef}
                aspectRatio={getAspectRatio(aspectRatioName)}
                className={block('preview-box')}
                src={embedUrl}
                onEnded={onEndReached}
                onReady={onPreviewReady}
                playing={isPlaying}
                volume={0}
              />
            </div>
          }
        />
      </div>
      <div className={block('content-pane', { right: true })}>
        <div className={block('preview-left-pane')}>
          <HeadlinerMarkColor
            className={block('icon')}
            height={HEADY_ICON_SIZE}
            width={HEADY_ICON_SIZE}
          />
          <H1 className={block('title')}>Here is a preview!</H1>
          <H2 className={block('subtitle')}>
            This example shows how your audiograms will look.
          </H2>
          <div className={block('footer')}>
            <Button
              className={block('submit-button')}
              disabled={isSubmitting}
              fluid
              onClick={onSubmit}
              shape="chip"
              theme="submit"
            >
              looks good, set it up!
            </Button>
            <Button
              className={block('goback-button')}
              disabled={isSubmitting}
              fluid
              onClick={onGoBack}
              shape="chip"
              theme="submit-alt"
            >
              go back to editing
            </Button>
          </div>
        </div>
      </div>
      <HiddenAudioPlayer
        ref={audioPlayerRef}
        src={recordingUrl}
        isPlaying={isPlaying}
        loadOnMount
        loop={false}
        muted={isMuted}
        onLoad={onAudioReady}
        onEnded={onEndReached}
      />
    </div>
  );
};

export default AutogramPreviewReadyStep;
