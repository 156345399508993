import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import Tooltip from 'components/Tooltip';
import { block } from '../utils';

const { useCallback } = React;

export interface SearchResultListItemProps {
  children?: React.ReactElement<{ className?: string }>;
  className?: string;
  container?: React.ReactNode;
  disabled?: boolean;
  id?: string;
  onClick?: (params: any) => void;
  params?: any;
  style?: React.CSSProperties;
  tooltip?: React.ReactNode;
}

const SearchResultListItem: React.SFC<SearchResultListItemProps> = ({
  children,
  className,
  container,
  id,
  disabled,
  onClick,
  params,
  style,
  tooltip,
}) => {
  const handleClick = useCallback(() => {
    onClick(params);
  }, [onClick, params]);

  const result = (
    <div
      className={cn(block('result', { disabled }), className)}
      onClick={handleClick}
      style={style}
    >
      {children &&
        React.cloneElement(children, {
          className: cn(block('result-inner'), children.props.className),
        })}
    </div>
  );

  return !tooltip ? (
    result
  ) : (
    <Tooltip
      className={block('result-tooltip')}
      container={container}
      content={tooltip}
      id={`${id}-tooltip`}
      placement="top"
      preventHideOnHover={false}
    >
      {result}
    </Tooltip>
  );
};

SearchResultListItem.defaultProps = {
  onClick: noop,
};

export default SearchResultListItem;
