import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfirmationModal from 'components/ConfirmationModal';
import FontAwesome from 'components/FontAwesome';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import Modal from 'components/Modal';
import ConnectedModal from 'containers/ConnectedModal';
import { Tier } from 'redux/middleware/api/plan-service/types';
import { onUpgradeToAddMoreAutomations } from 'redux/modules/mixpanel/actions';
import { replaceModal } from 'redux/modules/modal/actions';
import { modalParamsSelector } from 'redux/modules/modal/selectors';
import { isFreeSelector } from 'redux/modules/pricing/selectors';
import { Dispatch } from 'redux/types';
import { autogramWizardLocation } from 'utils/routes';

const AddAutomationUpgradeModal = () => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const params = useSelector(modalParamsSelector);
  const handleUpgrade = async () => {
    dispatch(onUpgradeToAddMoreAutomations());
    await dispatch(
      replaceModal({
        name: 'AddBillingCycle',
        params: {
          tier: Tier.PRO,
          defaultSubscriptionPeriod: 'yearly',
          disableSuccessRedirect: true,
        },
      }),
    );

    const isFree = dispatch((_, getState) => isFreeSelector(getState()));

    if (!isFree) {
      history.push(autogramWizardLocation({ podcastId: params?.podcastId }));
    }
  };
  return (
    <ConnectedModal name="AddAutomationUpgrade" title="new automation">
      <ConfirmationModal.Body>
        <ConfirmationModal.Icon>
          <CircularGradient>
            <FontAwesome icon="magic" size="3x" />
          </CircularGradient>
        </ConfirmationModal.Icon>
        <ConfirmationModal.Title>
          Our free plan allows one
          <br />
          active automation at a time
        </ConfirmationModal.Title>
        <ConfirmationModal.Content>
          You can upgrade to unlock unlimited automations!
        </ConfirmationModal.Content>
      </ConfirmationModal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton onClick={handleUpgrade} theme="rainbow">
            upgrade your plan
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </ConnectedModal>
  );
};

export default AddAutomationUpgradeModal;
