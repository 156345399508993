import * as React from 'react';
import _ from 'underscore';

import { createMap, HeadlinerCSSProperties, IImmutableMap } from 'types';
import { percentageOf, scale } from 'utils/numbers';
import {
  formatHeadlinerStyleRules,
  getFontStyle,
  getFontWeight,
  getTextDecoration,
} from 'utils/ui';

type Viewport = IImmutableMap<{
  height?: number;
  width?: number;
}>;

type SetAlignment = (align: string) => void;
type SetBackground = (color: string) => void;
type SetData = (data: EditorData) => void;
type SetFont = (font: { family: string }) => void;
type SetFontColor = (color: string) => void;
type SetFontSize = (size: number, viewport?: Viewport) => void;
type SetLineHeight = (height: number) => void;
type SetPadding = (padding: number) => void;
type SetPosition = (
  pos: { x: number; y: number },
  viewport?: Viewport,
  constrain?: boolean,
) => void;
type SetSize = (
  x: number,
  y: number,
  height: number,
  width: number,
  viewport?: Viewport,
  constrain?: boolean,
) => void;
type SetText = (text: string) => void;
type SetTextHighlight = (color: string) => void;
type SetTextShadowBlur = (blur: number) => void;
type SetTextShadowColor = (color: string) => void;
type SetTextShadowX = (x: number) => void;
type SetTextShadowY = (y: number) => void;
type SetTime = (startMillis: number, endMillis: number) => void;
type SetBold = (bold: boolean) => void;
type SetItalic = (italic: boolean) => void;
type SetUnderline = (underline: boolean) => void;
type SetWorkspaceSize = (height: number, width: number) => void;
type FormatStyleRules = (rules: HeadlinerCSSProperties) => React.CSSProperties;

interface IHandlers {
  setAlignment: SetAlignment;
  setBackground: SetBackground;
  setData: SetData;
  setFont: SetFont;
  setFontColor: SetFontColor;
  setFontSize: SetFontSize;
  setLineHeight: SetLineHeight;
  setPaddingTop: SetPadding;
  setPaddingRight: SetPadding;
  setPaddingBottom: SetPadding;
  setPaddingLeft: SetPadding;
  setPosition: SetPosition;
  setSize: SetSize;
  setText: SetText;
  setTextHighlight: SetTextHighlight;
  setTextShadowBlur: SetTextShadowBlur;
  setTextShadowColor: SetTextShadowColor;
  setTextShadowX: SetTextShadowX;
  setTextShadowY: SetTextShadowY;
  setTime: SetTime;
  setBold: SetBold;
  setItalic: SetItalic;
  setUnderline: SetUnderline;
  setWorkspaceSize: SetWorkspaceSize;
}

export type EditorData = IImmutableMap<{
  containerStyle?: HeadlinerCSSProperties;
  position?: IImmutableMap<{
    bottom?: number;
    left?: number;
    top?: number;
  }>;
  size?: IImmutableMap<{
    height?: number;
    width?: number;
  }>;
  textStyle?: IImmutableMap<{
    background?: string;
  }>;
  time?: IImmutableMap<{
    startMillis?: number;
    endMillis?: number;
  }>;
  viewport?: IImmutableMap<{
    height?: number;
    width?: number;
  }>;
}>;

interface IRenderProps {
  data: EditorData;
  formatStyleRules: FormatStyleRules;
  text: string;
  handlers: IHandlers;
}

interface IUpdateProps extends IRenderProps {
  prevData: EditorData;
  prevText: string;
}

interface IProps {
  data?: EditorData;
  /**
   * called whenever RndTextEditor updates its internal state.
   * NOTE: be careful of infinite loops.  you should not call a handler from within the onUpdate
   *  callback.  calling a handler will cause RndTextEditor to update its state, triggering
   *  onUpdate.  If a handler is called from within the onUpdate callback, the cycle starts all
   *  over again
   */
  onUpdate?: (props: IUpdateProps) => void;

  /**
   * in cases where `data` contains `bottom` instead of `height` or is missing the value for
   * 'size', the component will need to read the text element's height from the dom. this
   * function should return the text element's height, or undefined if it is unknown
   */
  getTextElementHeight?: () => number;

  /**
   * Renders the text editor.
   *
   * @param data immutable map. same structure as the data prop.  like the data prop, values
   *  are scaled to data.workspaceSize
   * @param {String} text current text value
   * @param {Object} handlers functions used to set various values in state.  These are
   *  the event handlers that should get wired into the text editor so that changes are reflected
   *  in the data object
   * @param {Function} formatStyleRules accepts a style from `data` and formats it to valid css.
   *  this function only knows how to properly handle the style rules it declares in the prop
   *  data.textStyle and data.containerStyle. If you pass rules that this component doesn't
   *  understand, they might not get formatted correctly
   */
  render?: (props: IRenderProps) => JSX.Element;
  text?: string;
}

type Size = IImmutableMap<{
  height?: number;
  width?: number;
}>;

interface IState {
  text: string;
  data: EditorData;
}

const DEFAULT_DATA = (createMap({
  containerStyle: {
    background: 'transparent',
    color: 'white',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: getFontWeight(false),
    lineHeight: 1,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    textAlign: 'left',
    textDecoration: getTextDecoration(false),
    textShadow: { x: 0, y: 0, blur: 0, color: 'transparent' },
  },
  textStyle: {
    background: 'transparent',
  },
}) as unknown) as EditorData;

function createData(data: EditorData) {
  const result = DEFAULT_DATA.mergeDeepWith(
    (oldVal, newVal) => (typeof newVal === 'undefined' ? oldVal : newVal),
    data,
  );

  return result as EditorData;
}

function scaleLayout(data: EditorData, fromSize: Size, toSize: Size) {
  const fromWidth = fromSize.get('width');
  const fromHeight = fromSize.get('height');

  const toWidth = toSize.get('width');
  const toHeight = toSize.get('height');

  const scaleByWidth = val => scale(val, fromWidth, toWidth);
  const scaleByHeight = val => scale(val, fromHeight, toHeight);

  return data.withMutations(d =>
    d
      .updateIn(['containerStyle', 'fontSize'], scaleByWidth)
      .updateIn(['position', 'left'], scaleByWidth)
      .updateIn(['position', 'top'], scaleByHeight)
      .updateIn(['position', 'bottom'], scaleByHeight)
      .updateIn(['size', 'width'], scaleByWidth)
      .updateIn(['size', 'height'], scaleByHeight)
      .set('viewport', toSize),
  ) as EditorData;
}

function scalePaddings(data: EditorData, toSize: Size) {
  const toWidth = toSize.get('width');

  const percentageByWidth = val => (val / 100) * toWidth;

  return data.withMutations(d =>
    d
      .updateIn(['containerStyle', 'paddingTop'], percentageByWidth)
      .updateIn(['containerStyle', 'paddingRight'], percentageByWidth)
      .updateIn(['containerStyle', 'paddingBottom'], percentageByWidth)
      .updateIn(['containerStyle', 'paddingLeft'], percentageByWidth),
  ) as EditorData;
}

function unscalePaddings(data: EditorData, toSize: Size) {
  const toWidth = toSize.get('width');

  const percentageByWidth = val => percentageOf(val, toWidth);

  return data.withMutations(d =>
    d
      .updateIn(['containerStyle', 'paddingTop'], percentageByWidth)
      .updateIn(['containerStyle', 'paddingRight'], percentageByWidth)
      .updateIn(['containerStyle', 'paddingBottom'], percentageByWidth)
      .updateIn(['containerStyle', 'paddingLeft'], percentageByWidth),
  ) as EditorData;
}

function scaleData(data: EditorData, toSize: Size) {
  if (!data || !data.get('viewport') || !toSize) return data;
  return scalePaddings(scaleLayout(data, data.get('viewport'), toSize), toSize);
}

/**
 * A container for wiring up a text editor with a resizable and draggable text box
 */
class RndTextEditor extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    getTextElementHeight: _.constant(undefined),
    onUpdate: _.noop,
    render: () => null,
  };

  private handlers: IHandlers;
  private workspaceSize: Size;

  constructor(props: IProps) {
    super(props);

    const { data, text } = props;
    this.state = {
      text,
      // TODO rename data to style
      data: createData(data),
    };

    this.workspaceSize = undefined;

    this.handlers = {
      setAlignment: this.setAlignment,
      setBackground: this.setBackground,
      setBold: this.setBold,
      setData: this.setData,
      setFont: this.setFont,
      setFontColor: this.setFontColor,
      setFontSize: this.setFontSize,
      setItalic: this.setItalic,
      setLineHeight: this.setLineHeight,
      setPaddingBottom: this.setPaddingBottom,
      setPaddingLeft: this.setPaddingLeft,
      setPaddingRight: this.setPaddingRight,
      setPaddingTop: this.setPaddingTop,
      setPosition: this.setPosition,
      setSize: this.setSize,
      setText: this.setText,
      setTextHighlight: this.setTextHighlight,
      setTextShadowBlur: this.setTextShadowBlur,
      setTextShadowColor: this.setTextShadowColor,
      setTextShadowX: this.setTextShadowX,
      setTextShadowY: this.setTextShadowY,
      setTime: this.setTime,
      setUnderline: this.setUnderline,
      setWorkspaceSize: this.setWorkspaceSize,
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const { data: nextData, text: nextText } = nextProps;
    const { data, text } = this.props;

    if (!nextData.equals(data)) {
      const scaledData = scaleData(nextData, this.workspaceSize);
      // this.setState({ data: DEFAULT_DATA.mergeDeep(scaledData) });
      this.setState({ data: createData(scaledData) });
    }

    if (nextText !== text) {
      this.setState({ text: nextText });
    }
  }

  public componentDidUpdate(__, prevState: Readonly<IState>) {
    const { getTextElementHeight, onUpdate } = this.props;
    const { data: prevData, text: prevText } = prevState;
    const { data, text } = this.state;

    const hasWorkspaceSize = !!this.workspaceSize;
    const isScaled =
      hasWorkspaceSize && data.get('viewport').equals(this.workspaceSize);
    const textElementHeight = getTextElementHeight();
    const hasTextElementHeight = !_.isUndefined(textElementHeight);

    if (isScaled && hasTextElementHeight) {
      /*
       * pass textElementHeight through rather than having setDimensions call getTextElementHeight
       * in case getTextElementHeight is expensive
       */
      this.setDimensions(textElementHeight);
    }

    if (prevText !== text || !prevData.equals(data)) {
      onUpdate({
        data,
        prevData,
        prevText,
        text,
        formatStyleRules: this.formatStyleRules,
        handlers: this.handlers,
      });
    }
  }

  private setAlignment = (textAlign: string) =>
    this.updateData([
      { path: ['containerStyle', 'textAlign'], value: textAlign },
    ]);

  private setBackground = (color: string) =>
    this.updateData([{ path: ['containerStyle', 'background'], value: color }]);

  /**
   * @param data function or immutable map. if it's a function, it calls the function with the
   *  current  value of data from state and expects the function to return a new data value.  If
   *  it's not a function, then the value itself is used as the new value for data state
   */
  private setData = (
    data: EditorData,
    cb: (data: EditorData) => void = _.noop,
  ) =>
    this.setState(
      ({ data: currentData }) => {
        const newData = _.isFunction(data)
          ? data(currentData)
          : createData(data);
        return {
          data: scaleData(newData, currentData.get('viewport')),
        };
      },
      () => cb(this.state.data),
    );

  private setFont = ({ family }) =>
    this.updateData([
      { path: ['containerStyle', 'fontFamily'], value: family },
    ]);

  private setFontColor = (color: string) =>
    this.updateData([{ path: ['containerStyle', 'color'], value: color }]);

  private setFontSize = (
    fontSize,
    viewport = this.state.data.get('viewport'),
  ) => {
    const { data } = this.state;
    const size = scale(
      fontSize,
      viewport.get('width'),
      data.getIn(['viewport', 'width']),
    );
    this.updateData([{ path: ['containerStyle', 'fontSize'], value: size }]);
  };

  private setLineHeight = (lineHeight: number) =>
    this.updateData([
      { path: ['containerStyle', 'lineHeight'], value: lineHeight },
    ]);

  private setPaddingTop = (padding: number) => {
    const { data } = this.state;
    const scaled = (padding / 100) * data.getIn(['viewport', 'width']);
    this.updateData([
      {
        path: ['containerStyle', 'paddingTop'],
        value: String(padding) === '' ? '' : scaled,
      },
    ]);
  };

  private setPaddingRight = (padding: number) => {
    const { data } = this.state;
    const scaled = (padding / 100) * data.getIn(['viewport', 'width']);
    this.updateData([
      {
        path: ['containerStyle', 'paddingRight'],
        value: String(padding) === '' ? '' : scaled,
      },
    ]);
  };

  private setPaddingBottom = (padding: number) => {
    const { data } = this.state;
    const scaled = (padding / 100) * data.getIn(['viewport', 'width']);
    this.updateData([
      {
        path: ['containerStyle', 'paddingBottom'],
        value: String(padding) === '' ? '' : scaled,
      },
    ]);
  };

  private setPaddingLeft = (padding: number) => {
    const { data } = this.state;
    const scaled = (padding / 100) * data.getIn(['viewport', 'width']);
    this.updateData([
      {
        path: ['containerStyle', 'paddingLeft'],
        value: String(padding) === '' ? '' : scaled,
      },
    ]);
  };

  private setPosition = (
    { x, y },
    viewport = this.state.data.get('viewport'),
    constrain = false,
  ) => {
    const updatePaths = [];

    if (!_.isUndefined(x)) {
      const newX = this.calculatePosition(x, viewport, 'width', constrain);
      updatePaths.push({
        path: ['position', 'left'],
        value: x === '' ? '' : newX,
      });
    }

    if (!_.isUndefined(y)) {
      const newY = this.calculatePosition(y, viewport, 'height', constrain);
      updatePaths.push({
        path: ['position', 'top'],
        value: y === '' ? '' : newY,
      });
    }

    this.updateData(updatePaths);
  };

  private setSize = (
    x: number,
    y: number,
    height: number,
    width: number,
    viewport = this.state.data.get('viewport'),
    constrain = false,
  ) => {
    const { data } = this.state;
    this.updateData([
      {
        path: ['position', 'left'],
        value: this.calculatePosition(x, viewport, 'width', constrain),
      },
      {
        path: ['position', 'top'],
        value: this.calculatePosition(y, viewport, 'height', constrain),
      },
      {
        path: ['size', 'width'],
        value: scale(
          width,
          viewport.get('width'),
          data.getIn(['viewport', 'width']),
        ),
      },
      {
        path: ['size', 'height'],
        value: scale(
          height,
          viewport.get('height'),
          data.getIn(['viewport', 'height']),
        ),
      },
    ]);
  };

  private setText = (text: string) => this.setState({ text });

  private setTextHighlight = (highlight: string) =>
    this.updateData([
      { path: ['textStyle', 'background'], value: highlight },
      { path: ['textStyle', 'paddingLeft'], value: '0.25em' },
      { path: ['textStyle', 'paddingRight'], value: '0.25em' },
      { path: ['textStyle', 'boxDecorationBreak'], value: 'clone' },
      { path: ['textStyle', 'WebkitBoxDecorationBreak'], value: 'clone' },
    ]);

  private setTextShadowBlur = (blur: number) =>
    this.updateData([
      { path: ['containerStyle', 'textShadow', 'blur'], value: blur },
    ]);

  private setTextShadowColor = (color: string) =>
    this.updateData([
      { path: ['containerStyle', 'textShadow', 'color'], value: color },
    ]);

  private setTextShadowX = (x: number) =>
    this.updateData([
      { path: ['containerStyle', 'textShadow', 'x'], value: x },
    ]);

  private setTextShadowY = (y: number) =>
    this.updateData([
      { path: ['containerStyle', 'textShadow', 'y'], value: y },
    ]);

  private setTime = (startMillis: number, endMillis: number) =>
    this.updateData([
      { path: ['time', 'startMillis'], value: startMillis },
      { path: ['time', 'endMillis'], value: endMillis },
    ]);

  private setBold = (enabled: boolean) =>
    this.updateData([
      { path: ['containerStyle', 'fontWeight'], value: getFontWeight(enabled) },
    ]);

  private setItalic = (enabled: boolean) =>
    this.updateData([
      { path: ['containerStyle', 'fontStyle'], value: getFontStyle(enabled) },
    ]);

  private setUnderline = (enabled: boolean) =>
    this.updateData([
      {
        path: ['containerStyle', 'textDecoration'],
        value: getTextDecoration(enabled),
      },
    ]);

  private setWorkspaceSize = (height: number, width: number) => {
    this.workspaceSize = (createMap({ height, width }) as unknown) as Size;
    this.setState(({ data }) => ({
      data: scaleData(data, this.workspaceSize),
    }));
  };

  private setDimensions(textElementHeight: number) {
    const { data } = this.state;

    const isTopSet = !_.isUndefined(data.getIn(['position', 'top']));
    const isBottomSet = !_.isUndefined(data.getIn(['position', 'bottom']));
    const isHeightSet = !_.isUndefined(data.getIn(['size', 'height']));

    if (isTopSet && isHeightSet) {
      return;
    }

    this.setState(({ data: dataState }) => {
      const offsetBottom = data.getIn(['position', 'bottom']);
      const viewportHeight = data.getIn(['viewport', 'height']);

      return {
        data: dataState.withMutations(d => {
          if (!isTopSet && isBottomSet) {
            d.setIn(
              ['position', 'top'],
              viewportHeight - offsetBottom - textElementHeight,
            );
            d.deleteIn(['position', 'bottom']);
          }

          if (!isHeightSet) {
            d.setIn(['size', 'height'], textElementHeight);
          }
        }) as EditorData,
      };
    });
  }

  public getData(viewport?: Viewport) {
    const { data } = this.state;
    const viewportData = viewport || data.get('viewport');

    if (viewportData.equals(data.get('viewport'))) {
      return unscalePaddings(data, data.get('viewport'));
    }

    return unscalePaddings(
      scaleLayout(data, data.get('viewport'), viewportData),
      data.get('viewport'),
    );
  }

  public getText() {
    const { text } = this.state;
    return text;
  }

  private updateData(updates) {
    this.setState(({ data }) => ({
      data: data.withMutations(d => {
        updates.forEach(({ path, value }) => {
          const keyPath = Array.isArray(path) ? path : [path];
          d.setIn(keyPath, value);
        });
      }) as EditorData,
    }));
  }

  private calculatePosition(val, viewport, dimension, constrain) {
    // const { data } = this.props;
    const { data } = this.state;
    const scaledVal = scale(
      val,
      viewport.get(dimension),
      data.getIn(['viewport', dimension]),
    );

    if (!constrain) {
      return scaledVal;
    }

    const maxVal =
      data.getIn(['viewport', dimension]) - data.getIn(['size', dimension]);
    return Math.min(scaledVal, maxVal);
  }

  public formatStyleRules = formatHeadlinerStyleRules;

  public render() {
    const { render } = this.props;
    const { data, text } = this.state;

    return render({
      data,
      text,
      formatStyleRules: this.formatStyleRules,
      handlers: this.handlers,
    });
  }
}

export {
  RndTextEditor as default,
  EditorData as RndTextEditorData,
  IHandlers as RndTextHandlers,
  FormatStyleRules as RndFormatStyleRules,
};
