import cn from 'classnames';
import * as React from 'react';

import { block } from '../../utils';

export interface StyleTileProps {
  children?: React.ReactNode;
  className?: string;
}

const StyleTile: React.SFC<StyleTileProps> = ({ children, className }) => (
  <div className={cn(block('tile'), className)}>{children}</div>
);

export default StyleTile;
