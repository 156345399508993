import cn from 'classnames';
import * as React from 'react';

import IconButton, { IconButtonProps } from 'components/IconButton';
import Tooltip from 'components/Tooltip';
import { block } from '../../utils';

export interface PhraseControlButtonProps extends IconButtonProps {
  id: string;
  large?: boolean;
  tooltip: React.ReactNode;
}

const PhraseControlButton: React.SFC<PhraseControlButtonProps> = ({
  children,
  className,
  id,
  large,
  onClick,
  tooltip,
  ...buttonProps
}) => (
  <Tooltip
    animation={false}
    content={tooltip}
    id={`${id}-tooltip`}
    placement="top"
    preventHideOnHover={false}
  >
    <IconButton
      className={cn(block('phrase-control', { large }))}
      onButtonClick={onClick}
      {...buttonProps}
    >
      {children}
    </IconButton>
  </Tooltip>
);

export default PhraseControlButton;
