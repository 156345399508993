import * as React from 'react';

import TextBoxNumericField, {
  TextBoxNumericFieldProps,
} from '../TextBoxNumericField';

type Edge = 'top' | 'left' | 'bottom' | 'right';

type Props = TextBoxNumericFieldProps<Edge>;

const PaddingOptionsField: React.SFC<Props> = props => (
  <TextBoxNumericField
    size={3}
    maxLength={3}
    min={0}
    max={100}
    step={0.01}
    {...props}
  />
);

export default PaddingOptionsField;
export { Props as PaddingOptionsFieldProps };
