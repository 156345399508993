import queryString from 'query-string';
import { DownloadSocialPlatforms } from 'redux/modules/download/types';

export function addSocialUTMSource(
  sharingPlatform: DownloadSocialPlatforms,
  sharingLink: string,
  description: string,
) {
  const stringifiedLink = queryString.stringifyUrl({
    url: sharingLink || '',
    query: { utm_source: sharingPlatform },
  });
  return description.replace(sharingLink, stringifiedLink);
}
