import { ColorPaletteState } from '@sparemin/blockhead';
import React, { useMemo } from 'react';
import { AiImageType } from 'redux/middleware/api/image-search-service';
import { AspectRatioName } from 'types';
import { AIGenerationTopSlot } from '../AIGeneration';
import { EngineRegistry } from '../types';

export interface UseImageSearchResultsTopSlotConfig {
  hasQuery: boolean;
  selectedEngine?: string;
  engines: EngineRegistry;
  aspectRatioName?: AspectRatioName;
  imageType?: AiImageType;
  colorPaletteState: ColorPaletteState;
  onImageTypeChange: (value: AiImageType) => void;
  onAspectRatioNameChange: (value: AspectRatioName) => void;
  onGenerate?: () => void;
}

export type UseImageSearchResultsTopSlotResult = React.ReactNode;

export default function useImageSearchResultsTopSlot({
  hasQuery,
  selectedEngine,
  engines,
  aspectRatioName,
  onAspectRatioNameChange,
  imageType,
  colorPaletteState,
  onImageTypeChange,
  onGenerate,
}: UseImageSearchResultsTopSlotConfig): UseImageSearchResultsTopSlotResult {
  return useMemo((): React.ReactNode => {
    switch (selectedEngine) {
      case 'textToImage': {
        const { isLoading, isSearching } = engines?.textToImage || {};

        return (
          <AIGenerationTopSlot
            {...{
              hasQuery,
              onGenerate,
              aspectRatioName,
              imageType,
              colorPaletteState,
              onImageTypeChange,
              onAspectRatioNameChange,
            }}
            isLoading={isLoading || isSearching}
          />
        );
      }

      default: {
        return null;
      }
    }
  }, [
    aspectRatioName,
    colorPaletteState,
    engines,
    hasQuery,
    imageType,
    onAspectRatioNameChange,
    onGenerate,
    onImageTypeChange,
    selectedEngine,
  ]);
}
