import React from 'react';

import DialogContent from 'components/DialogContent';
import { H2 } from 'components/Heading';
import { Youtube } from 'components/icons';
import LoadingOverlay from 'components/LoadingOverlay';
import Modal from 'components/Modal';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import GoogleButton from 'containers/YoutubePostModal/GoogleButton/GoogleButton';
import useYouTubeAuthenticator from 'hooks/useYouTubeAuthenticator';

import useYoutubePermissionsRestore from './hooks/useYoutubePermissionsRestore';

interface YoutubePermissionRevokedModalContentProps {
  reAuthAutoCreateSubId?: number;
}

const YoutubePermissionsRevokedModalContent: React.FunctionComponent<YoutubePermissionRevokedModalContentProps> = props => {
  const { reAuthAutoCreateSubId } = props;

  const { onHide } = useConnectedModal('YoutubePermissionsRevoked');

  const {
    isLoading,
    onYoutubeReauthenticationFailure,
    onYoutubeReauthenticationSuccess,
  } = useYoutubePermissionsRestore({
    onHideModal: onHide,
    reAuthAutoCreateSubId,
  });

  const { authenticate, authenticating } = useYouTubeAuthenticator({
    onAuthSuccess: onYoutubeReauthenticationSuccess,
    onError: onYoutubeReauthenticationFailure,
    force: true,
  });

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <DialogContent
        details={
          <H2>
            We'll quickly send you to Google so you can give us permission to
            add videos to your YouTube account.
          </H2>
        }
        icon={<Youtube height={100} width={100} />}
        onClose={() => null}
        title="Let’s get your automation back on"
      />
      <Modal.Footer>
        <GoogleButton
          disabled={authenticating || isLoading}
          theme="submit"
          onClick={authenticate}
        >
          Sign in with Google
        </GoogleButton>
      </Modal.Footer>
    </>
  );
};

export default YoutubePermissionsRevokedModalContent;
