import * as React from 'react';
import { connect } from 'react-redux';

import {
  copyAllPhrasesToTimeline,
  toggleCaptions,
} from 'redux/modules/embed/actions';
import { onCopyAllCaptions } from 'redux/modules/mixpanel/actions';
import { showError } from 'redux/modules/notification/actions';
import { Dispatch } from 'redux/types';
import { CaptionsFormProps } from './CaptionsForm';

export type DispatchProps = Pick<
  CaptionsFormProps,
  'onToggleCaptions' | 'onCopyAllToTimeline'
>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onCopyAllToTimeline: () => {
    dispatch(onCopyAllCaptions());
    dispatch(copyAllPhrasesToTimeline());
  },
  onToggleCaptions: enabled =>
    dispatch(toggleCaptions(enabled)).catch(() => {
      const message = `Error ${enabled ? 'enabling' : 'disabling'} captions`;
      dispatch(showError(message));
    }),
});

export default function(component: React.ComponentType<CaptionsFormProps>) {
  return connect(null, mapDispatchToProps)(component);
}
