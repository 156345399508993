import * as React from 'react';

import ModalFooterButton from 'components/Modal/ModalFooterButton';
import { FooterButtonProps } from 'components/SteppedModal';

interface AddMediaFooterButtonsArgs extends FooterButtonProps {}

export default function addMediaFooterButtons({
  back,
  cancel,
  submit,
}: AddMediaFooterButtonsArgs) {
  return [
    <ModalFooterButton {...back} key="back">
      Back
    </ModalFooterButton>,
    <ModalFooterButton {...cancel} key="cancel">
      Cancel
    </ModalFooterButton>,
    <ModalFooterButton {...submit} key="submit" theme="submit">
      Add To Video
    </ModalFooterButton>,
  ];
}
