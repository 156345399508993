import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A>(method: M, args: A) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export function searchForImages(
  query: string,
  engine: string,
  page: number,
  pageSize: number,
): types.SearchForImagesAction {
  return apiCallAction(types.ServiceMethod.SEARCH_FOR_IMAGES, [
    query,
    engine,
    page,
    pageSize,
  ] as [string, string, number, number]);
}

export function searchForVideos(
  query: string,
  engine: string,
  page: number,
  pageSize: number,
): types.SearchForVideosAction {
  return apiCallAction(types.ServiceMethod.SEARCH_FOR_VIDEOS, [
    query,
    engine,
    page,
    pageSize,
  ] as [string, string, number, number]);
}

export function searchForGifs(
  query: string,
  engine: string,
  page: number,
  pageSize: number,
): types.SearchForGifsAction {
  return apiCallAction(types.ServiceMethod.SEARCH_FOR_GIFS, [
    query,
    engine,
    page,
    pageSize,
  ] as [string, string, number, number]);
}

export function createTextToImage(
  prompt: string,
  provider: types.TextToImageProvider,
  width: number,
  height: number,
  imageType?: types.AiImageType,
  colors?: types.TextToImageColor[],
): types.CreateTextToImageAction {
  return apiCallAction(types.ServiceMethod.CREATE_TEXT_TO_IMAGE, [
    prompt,
    provider,
    width,
    height,
    imageType,
    colors,
  ] as [
    string,
    types.TextToImageProvider,
    number,
    number,
    types.AiImageType,
    types.TextToImageColor[],
  ]);
}

export function getTextToImage(
  textToImageJobId: number,
): types.GetTextToImageAction {
  return apiCallAction(types.ServiceMethod.GET_TEXT_TO_IMAGE, [
    textToImageJobId,
  ] as [number]);
}

export function createImageToVideo(
  sourceImageUrl: string,
  provider: types.ImageToVideoProvider,
): types.CreateImageToVideoAction {
  return apiCallAction(types.ServiceMethod.CREATE_IMAGE_TO_VIDEO, [
    sourceImageUrl,
    provider,
  ] as [string, types.ImageToVideoProvider]);
}

export function getImageToVideo(
  imageToVideoJobId: number,
): types.GetImageToVideoAction {
  return apiCallAction(types.ServiceMethod.GET_IMAGE_TO_VIDEO, [
    imageToVideoJobId,
  ] as [number]);
}
