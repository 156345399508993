import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'components/LoadingOverlay';
import { Tier } from 'redux/middleware/api/plan-service';
import { hideModal, replaceModal } from 'redux/modules/modal';
import { tierSelector } from 'redux/modules/pricing';
import usePlanDowngrade from '../../usePlanDowngrade';
import { Callouts, PlanDecisionModalBody } from '../PlanDecisionModal';
import DowngradeCtaCallout from './DowngradeCtaCallout';
import { CalloutAction } from './types';
import { block, getModalConfig } from './utils';

const { useCallback, useEffect } = React;

export interface PlanDowngradeModalContentsProps {
  toTier?: Tier;
}

const PlanDowngradeModalContents: React.FC<PlanDowngradeModalContentsProps> = ({
  toTier,
}) => {
  const fromTier = useSelector(tierSelector);
  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  const handleCancelPlan = useCallback(() => {
    dispatch(replaceModal({ name: 'CancelReason' }));
  }, [dispatch]);

  const { onDowngrade, disabled } = usePlanDowngrade({
    onDowngradeComplete: closeModal,
  });

  const config = getModalConfig({ fromTier, toTier });

  const actionsMap: Record<CalloutAction, () => void> = React.useMemo(
    () => ({
      cancelPlan: handleCancelPlan,
      closeModal,
      downgrade: onDowngrade,
    }),
    [closeModal, handleCancelPlan, onDowngrade],
  );

  useEffect(() => {
    if (!config) {
      closeModal();
    }
  }, [closeModal, config]);

  if (!config) return null;

  const { callouts, heading, subheading } = config;

  return (
    <PlanDecisionModalBody
      headerClassName={block('header')}
      heading={heading}
      subheading={subheading}
    >
      {disabled && <LoadingOverlay title="Processing Payment" />}
      <Callouts>
        {callouts.map(callout => (
          <DowngradeCtaCallout
            key={callout.key}
            ctaLabel={callout.ctaLabel}
            exportLimit={callout.toVideos}
            onClick={actionsMap[callout.action]}
            pro={callout.isPro}
          />
        ))}
      </Callouts>
    </PlanDecisionModalBody>
  );
};

export default PlanDowngradeModalContents;
