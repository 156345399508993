import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  defaultWatermarkSelector,
  watermarkOverrideSelector,
} from 'redux/modules/display-pref/selectors';
import {
  projectTypeByAssets,
  slidesSelector,
  watermarkSelector,
  watermarkStatusSelector,
} from 'redux/modules/embed/selectors';
import { State } from 'redux/types';
import { PropsOf } from 'types';
import { getValue } from 'utils/collections';
import { findSlideContainingMillis } from 'utils/embed/slideshow';
import WatermarkTile, { WatermarkTileProps as Props } from './WatermarkTile';

type StateProps = Pick<
  Props,
  | 'defaultWatermark'
  | 'logoImage'
  | 'logoStatus'
  | 'imageAtPosition'
  | 'projectType'
  | 'hasOverrides'
>;

const logoSelector = createSelector(
  watermarkSelector,
  (watermark: Map<string, any>): string => watermark && watermark.get('src'),
);

interface OwnProps {
  positionSec?: number;
}

const positionMillisSelector = (__, { positionSec }: OwnProps) =>
  positionSec * 1000;

const backgroundImageSelector = createSelector(
  [positionMillisSelector, slidesSelector],
  (positionMillis, slides) =>
    getValue(findSlideContainingMillis(positionMillis, slides), 'imageUrl'),
);

const mapStateToProps = (state: State, props: OwnProps): StateProps => ({
  defaultWatermark: defaultWatermarkSelector(state),
  imageAtPosition: backgroundImageSelector(state, props),
  logoImage: logoSelector(state),
  logoStatus: watermarkStatusSelector(state),
  projectType: projectTypeByAssets(state),
  hasOverrides: !!watermarkOverrideSelector(state)?.length,
});

const component = connect(mapStateToProps)(WatermarkTile);
export type WatermarkTileProps = PropsOf<typeof component>;
export default component;
