import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import Tile, { TileProps } from './Tile';
import { selectTileBlock as block } from './utils';

type PickedTileProps<P = any> = Pick<TileProps<P>, 'as' | 'onClick' | 'params'>;

export interface SelectTileProps<P = any> extends PickedTileProps<P> {
  children?: React.ReactNode;
  className?: string;
  selected?: boolean;
}

/**
 * VideoTemplateEditor tile with a selected state
 */
const SelectTile: React.FC<SelectTileProps> = ({
  as,
  children,
  className,
  onClick,
  params,
  selected = false,
}) => (
  <Tile
    className={cn(block({ selected }), className)}
    onClick={selected ? noop : onClick}
    {...{ as, params }}
  >
    {children}
  </Tile>
);

export default SelectTile;
