import React from 'react';
import { useDispatch } from 'react-redux';
import { ToggleField as FormToggleField } from 'components/Form';
import { updateMyPreferences } from 'redux/modules/common/actions';
import { PropsOf } from 'types';
import asSettingsFormField from '../asSettingsFormField';

const ToggleField = asSettingsFormField()(FormToggleField);
type ToggleFieldProps = PropsOf<typeof ToggleField>;

export type EmailPreferences = {
  videoExportEmailEnabled: boolean;
  automationEmailAlertEnabled: boolean;
  reminderEmailEnabled: boolean;
};

interface Props extends ToggleFieldProps {
  preferences: EmailPreferences;
  name: string;
}

function EmailSettingToggleField(props: Props) {
  const { primaryLabel, secondaryLabel, name, preferences } = props;
  const dispatch = useDispatch();
  return (
    <ToggleField
      {...{ primaryLabel, secondaryLabel }}
      checked={preferences[name]}
      disabled={preferences[name] === undefined}
      onChange={newChecked => {
        dispatch(
          updateMyPreferences({
            [name]: newChecked,
          }),
        );
      }}
    />
  );
}

export default EmailSettingToggleField;
export { Props as ToggleFieldProps };
