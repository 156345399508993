import classNames from 'classnames';
import * as React from 'react';
import _ from 'underscore';

import FontAwesome from 'components/FontAwesome';
import { prefix } from 'utils/ui';

type PlayheadSize = 'small' | 'medium' | 'large';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  barClassName?: string;
  children?: React.ReactNode;
  className?: string;
  id?: string;
  playheadClassName?: string;
  position?: number;
  style?: object;
  width?: number;
  headSize?: PlayheadSize;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

function getPlayheadSize(headSize: PlayheadSize) {
  switch (headSize) {
    case 'small':
      return 'lg';

    case 'medium':
      return '2x';

    case 'large':
      return '3x';

    default:
      return '2x';
  }
}

const Needle: React.SFC<IProps> = ({
  barClassName,
  children,
  className,
  id,
  playheadClassName,
  style,
  headSize,
  onClick,
  ...callbacks
}) => {
  const needleStyle = prefix({
    ...style,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
  });

  const {
    onMouseDown,
    onMouseUp,
    onTouchStart,
    onTouchEnd,
    onTransitionEnd,
  } = callbacks;

  const handlers = {
    onMouseDown,
    onMouseUp,
    onTouchEnd,
    onTouchStart,
    onTransitionEnd,
  };

  const containerClassName = classNames({
    needle: true,
    [className]: !!className,
  });

  const playheadClass = classNames(
    'needle__head',
    `needle__head--${headSize}`,
    playheadClassName,
  );
  const barClass = classNames('needle__bar', barClassName);

  return (
    <div
      id={id}
      style={needleStyle}
      className={containerClassName}
      onClick={onClick}
      {...handlers}
    >
      <FontAwesome
        className={playheadClass}
        icon="caret-down"
        size={getPlayheadSize(headSize)}
      />

      <span id="needle-bar" className={barClass} />
      {children}
      <div className="needle__drag-handle" />
    </div>
  );
};

Needle.defaultProps = {
  headSize: 'medium',
  onClick: _.noop,
  position: 0,
  style: {},
  width: 2,
};

export default Needle;
