import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import { InputAdornment, PasswordField, TextField } from 'components/Form';
import Modal from 'components/Modal';
import { Props } from './types';
import useUpdateEmailModalState from './useUpdateEmailModalState';
import { block } from './utils';

const UpdateEmailModalContent: React.FC<Props> = () => {
  const { onSubmit, email, password, submitting } = useUpdateEmailModalState();
  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <Modal.Body className={block('body')}>
        <TextField
          light
          density="comfortable"
          onChange={email.onChange}
          error={!!email.error}
          helperText={email.error}
          placeholder="New email address"
          autoComplete="off"
          startAdornment={
            <InputAdornment>
              <FontAwesome icon="envelope" />
            </InputAdornment>
          }
        />
        <PasswordField
          light
          density="comfortable"
          onChange={password.onChange}
          error={!!password.error}
          helperText={password.error}
          placeholder="Password"
          // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#The_autocomplete_attribute_and_login_fields
          autoComplete="new-password"
          startAdornment={
            <InputAdornment>
              <FontAwesome icon="lock" />
            </InputAdornment>
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButton
          fluid
          theme="submit"
          type="submit"
          disabled={submitting || !email.value || !password.value}
        >
          Update Email Address
        </Modal.FooterButton>
      </Modal.Footer>
    </form>
  );
};

export default UpdateEmailModalContent;
