import React from 'react';
import { ThreadsSocialCircle } from 'components/SocialCircle';
import SocialButton, { SocialButtonProps } from './SocialButton';

export interface ThreadsButtonProps
  extends Pick<SocialButtonProps, 'disabled' | 'onClick' | 'params' | 'size'> {}

const ThreadsButton: React.FC<ThreadsButtonProps> = ({
  disabled,
  onClick,
  size,
}) => (
  <SocialButton
    icon={<ThreadsSocialCircle />}
    label="Threads"
    {...{ disabled, onClick, size }}
  />
);

export default ThreadsButton;
