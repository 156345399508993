import { AiImageType } from 'redux/middleware/api/image-search-service';
import { AspectRatioName } from 'types';
import { RegenerationOptionsListItem } from './types';

export const aspectRatioOptions: RegenerationOptionsListItem<
  AspectRatioName
>[] = [
  {
    label: '🖼️ Square (1:1)',
    value: 'square',
  },
  {
    label: '🏞️ Landscape (16:9)',
    value: 'landscape',
  },
  {
    label: '📱 Portrait (9:16)',
    value: 'portrait',
  },
];

export const imageTypeOptions: RegenerationOptionsListItem<
  AiImageType | ''
>[] = [
  {
    label: '🎨 None specified',
    value: '',
  },
  {
    label: '🎥 Cinematic',
    value: 'stabilityCinematic',
  },
  {
    label: '👾 Digital art',
    value: 'stabilityDigitalArt',
  },
  {
    label: '🧱 Isometric',
    value: 'stabilityIsometric',
  },
  {
    label: '📷 Photographic',
    value: 'stabilityPhotographic',
  },
];
