export enum Type {
  AUDIO_WIZARD_TRACE_ID_SET = 'app/audio-wizard/AUDIO_WIZARD_TRACE_ID_SET',
  AUDIO_WIZARD_EPISODE_ID_SET = 'app/audio-wizard/AUDIO_WIZARD_EPISODE_ID_SET',
  AUDIO_WIZARD_ORIGINAL_AUDIO_DURATION_SET = 'app/audio-wizard/AUDIO_WIZARD_ORIGINAL_AUDIO_DURATION_SET',

  AUDIO_WIZARD_DATA_LOAD_REQUEST = 'app/audio-wizard/AUDIO_WIZARD_DATA_LOAD_REQUEST',
  AUDIO_WIZARD_DATA_LOAD_SUCCESS = 'app/audio-wizard/AUDIO_WIZARD_DATA_LOAD_SUCCESS',
  AUDIO_WIZARD_DATA_LOAD_FAILURE = 'app/audio-wizard/AUDIO_WIZARD_DATA_LOAD_FAILURE',

  AUDIO_WIZARD_CLEAR = 'app/audio-wizard/AUDIO_WIZARD_CLEAR',

  AUDIO_WIZARD_AUDIO_CLIP_SET = 'app/audio-wizard/AUDIO_WIZARD_AUDIO_CLIP_SET',
}
