import bem from 'utils/bem';

import { Dimension } from '../types';

export const block = bem('edit-automation-visuals');

/**
 * Maps autogram selected dimensions to a WIDTH:HEIGHT aspect ratio text
 * @param dimension object containing autogram height and width
 * @returns text with the mapped aspect ratio option using WIDTH:HEIGHT
 * format
 */
export const getDimensionsText = (dimension?: Dimension): string =>
  dimension ? `${dimension.width}:${dimension.height}` : '-';
