import * as React from 'react';
import { noop } from 'underscore';

import { SlideEffect, SlideTransition } from 'types';
import { ViewportWidth } from 'utils/measurement';
import SlideOptions from './SlideOptions';
import { block } from './utils';

export interface SlideFormData {
  startMillis: number;
  endMillis: number;
  effect: SlideEffect;
  transition: SlideTransition;
  blurRadius?: ViewportWidth;
}

export interface SlideFormProps {
  disabled?: boolean;
  minDurationMillis: number;
  onChange?: (data: SlideFormData) => void;
  onCropperOpen?: () => void;
  value: SlideFormData;
}

export default class SlideForm extends React.Component<SlideFormProps> {
  public static defaultProps: Partial<SlideFormProps> = {
    disabled: false,
    onChange: noop,
    onCropperOpen: noop,
  };

  private handleStartMillisChange = (startMillis: number) => {
    const { value } = this.props;
    const currentDuration = value.endMillis - value.startMillis;
    this.fireOnChange({
      startMillis,
      ...(value.endMillis > startMillis
        ? undefined
        : {
            endMillis: startMillis + currentDuration,
          }),
    });
  };

  private handleEffectChange = (effect: SlideEffect) =>
    this.fireOnChange({ effect });

  private handleEndMillisChange = (endMillis: number) =>
    this.fireOnChange({ endMillis });

  private handleTransitionChange = (transition: SlideTransition) =>
    this.fireOnChange({ transition });

  private handleDurationChange = (durationMillis: number) => {
    const { value } = this.props;
    this.fireOnChange({
      endMillis: value.startMillis + durationMillis,
    });
  };

  private handleBlurChange = (blurRadius: number) => {
    this.fireOnChange({ blurRadius: new ViewportWidth(blurRadius) });
  };

  private fireOnChange(data: Partial<SlideFormData>) {
    const { value, onChange } = this.props;
    onChange({
      ...value,
      ...data,
    });
  }

  public render() {
    const { disabled, minDurationMillis, onCropperOpen, value } = this.props;

    const { startMillis, endMillis, effect, transition, blurRadius } = value;

    return (
      <SlideOptions className={block('form')} disabled={disabled}>
        <SlideOptions.StartTime
          value={startMillis}
          onChange={this.handleStartMillisChange}
        />
        <SlideOptions.Effect
          value={effect}
          onChange={this.handleEffectChange}
        />
        <SlideOptions.EndTime
          minMillis={startMillis + minDurationMillis}
          onChange={this.handleEndMillisChange}
          value={endMillis}
        />
        <SlideOptions.Transition
          onChange={this.handleTransitionChange}
          value={transition}
        />
        <SlideOptions.Duration
          minMillis={minDurationMillis}
          value={endMillis - startMillis}
          onChange={this.handleDurationChange}
        />
        <SlideOptions.AdjustCrop onClick={onCropperOpen} />
        <SlideOptions.Blur
          value={blurRadius?.value}
          onChange={this.handleBlurChange}
        />
      </SlideOptions>
    );
  }
}
