import { List, Record } from 'immutable';

import { Position, Size } from 'types';
import { formatSlideForConfig } from 'utils/embed/slideshow';
import { formatOverlayForConfig } from 'utils/embed/text-overlay';
import { formatVideoForConfig } from 'utils/embed/video';
import { IEmbedVideoState, TimerState } from './types';

export const videoFactory = Record<IEmbedVideoState>({
  assetType: undefined,
  audioFadeInDurationMillis: undefined,
  audioFadeOutDurationMillis: undefined,
  audioLevel: undefined,
  audioSrc: undefined,
  blurredBackground: undefined,
  endMillis: undefined,
  height: undefined,
  id: undefined,
  mainAudioLevel: undefined,
  playFromMillis: undefined,
  position: undefined,
  previewThumbnailUrl: undefined,
  scaling: undefined,
  serverId: undefined,
  sourceDurationMillis: undefined,
  src: undefined,
  startMillis: undefined,
  transitionIn: undefined,
  transitionOut: undefined,
  width: undefined,
  zoom: undefined,
});

export const timerFactory: Record.IFactory<TimerState> = Record({
  color: undefined,
  enabled: undefined,
  fontSize: undefined,
  position: undefined,
  timerSize: undefined,
});

export const positionFactory = Record<Position<string | number>>({
  left: undefined,
  top: undefined,
});

export const sizeFactory = Record<Size<string>>({
  height: undefined,
  width: undefined,
});

export const flattenTranscript = transcript =>
  transcript
    .get('transcript')
    .flatMap(chunk =>
      chunk
        .get('subchunks')
        .map(subchunk => subchunk.set('chunkId', chunk.get('chunkId'))),
    );

export const formatSlideshowInfo = ({
  mediaTracks,
  tracks,
  slidesById,
  includeId = false,
}) => {
  return mediaTracks.reduce((acc, track) => {
    const trackIndex = tracks.indexOf(track?.get('id'));
    const trackData = track?.get('data') || List();

    const trackSlides = trackData.reduce((slides, id) => {
      const slide = slidesById?.get(id);

      if (slide) {
        const slideInfo = formatSlideForConfig(slide, trackIndex);

        slides.push(includeId ? { ...slideInfo, id } : { ...slideInfo });
      }

      return slides;
    }, []);

    return acc.concat(trackSlides);
  }, []);
};

export const formatVideoClipsInfo = ({
  mediaTracks,
  tracks,
  videosById,
  aspectRatio,
  includeId = false,
}) => {
  return mediaTracks.reduce((acc, track) => {
    const trackIndex = tracks.indexOf(track?.get('id'));
    const trackData = track?.get('data') || List();

    const trackVideos = trackData.reduce((videos, id) => {
      const video = videosById?.get(id);

      if (video) {
        const videoInfo = formatVideoForConfig(video, aspectRatio, trackIndex);

        videos.push(includeId ? { ...videoInfo, id } : { ...videoInfo });
      }

      return videos;
    }, []);

    return acc.concat(trackVideos);
  }, []);
};

export const formatTextOverlayInfo = ({
  textTracks,
  tracks,
  textOverlayById,
  includeId = false,
}) => {
  /*
   * iterate through text overlays by iterating through tracks, that way we have the track index
   * without doing a lookup for track index while processing each overlay
   */
  return textTracks.reduce((acc, track) => {
    const trackIndex = tracks.indexOf(track.get('id'));
    const trackData = track.get('data') || List();

    const trackOverlays = trackData.reduce((overlays, overlayId) => {
      const overlay = textOverlayById.get(overlayId);
      const overlayInfo = formatOverlayForConfig(overlay, trackIndex);

      overlays.push(
        includeId ? { ...overlayInfo, id: overlayId } : { ...overlayInfo },
      );

      return overlays;
    }, []);

    return acc.concat(trackOverlays);
  }, []);
};
