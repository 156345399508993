import cn from 'classnames';
import React from 'react';

import { block } from './utils';

export interface ExportVideoStepContainerProps {
  className?: string;
  children?: React.ReactNode;
}

const ExportVideoStepContainer: React.FC<ExportVideoStepContainerProps> = ({
  children,
  className,
}) => <div className={cn(block(), className)}>{children}</div>;

export default ExportVideoStepContainer;
