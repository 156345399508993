/**
 * Historically, the embed-config-migrate middleware was used to migrate old
 * configurations to a new format when breaking changes were made in the editor.
 *
 * Headliner saves the current version of the app to the configuration and when
 * a config is loaded in the editor, the version saved in the config is compared
 * to the running version of the app and a migration is run if necessary.
 *
 * Since configurations are created on the backend (Autogram) and mobile, we can
 * no longer rely on the Headliner webapp version saved to the config
 */
import { compose } from 'redux';

import { isEmpty, isEqual } from 'underscore';
import {
  AudioInfoTransition,
  IAudioInfo,
  IEmbedConfig,
  ISlideshowItem,
  Layerable,
  TrackType,
} from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { AUDIO_FADE_DURATION_MAX_MILLIS, VIEWPORTS } from 'utils/constants';
import { clamp } from 'utils/numbers';
import { getSlideBlurRadiusFromStyle } from './slideshow';

function countLayers(config: IEmbedConfig, type: TrackType) {
  const { layerOrder } = config;
  return layerOrder?.filter(layer => layer === type).length;
}

export function updateLayerReferences(
  config: IEmbedConfig,
  updater: (asset: Layerable) => number,
): IEmbedConfig {
  const {
    audioInfo,
    soundwave,
    slideshowInfo,
    textOverlayInfo,
    videoClips,
    ...restConfig
  } = config;

  const updateAssetLayerReferences = <A extends Layerable>(assets: A[]) =>
    assets?.map(asset => ({
      ...asset,
      layerId: updater(asset),
    }));

  return {
    ...restConfig,
    audioInfo: updateAssetLayerReferences(audioInfo),
    slideshowInfo: updateAssetLayerReferences(slideshowInfo),
    soundwave:
      !soundwave || isEmpty(soundwave)
        ? soundwave
        : { ...soundwave, layerId: updater(soundwave) },
    textOverlayInfo: updateAssetLayerReferences(textOverlayInfo),
    videoClips: updateAssetLayerReferences(videoClips),
  };
}

function audioTrackLayeringTransform(config: IEmbedConfig): IEmbedConfig {
  // recursive function that proceeds as follows -
  // given array of size n, we can ensure that the tracks are in the correct order
  // by first organizing tracks 1 through n-1 and then seeing where track 0 belongs.
  const moveAudioTrack = (
    configuration: IEmbedConfig,
    startIndex: number = config.layerOrder.length - 1,
    nextAudioTrackSlotIndex: number = config.layerOrder.length - 1,
  ) => {
    const { layerOrder: tracks } = configuration;

    // look for an audio track.  when the loop ends, one of 3 conditions will be
    // true:
    //  1. the array will be exhausted and no audio track will have been found
    //  2. the audio track is where it belongs
    //  3. the audio track is not where it belongs
    let i = startIndex;
    while (i >= 0 && tracks[i] !== 'audio') {
      i -= 1;
    }

    // case #1
    if (i < 0) {
      return configuration;
    }

    // case #2
    if (i === nextAudioTrackSlotIndex) {
      return moveAudioTrack(configuration, i - 1, nextAudioTrackSlotIndex - 1);
    }

    // case #3
    // copy tracks to layerOrder.  splicing happens in-place and we don't want to
    // modify the layerOrder object in the configuration
    const layerOrder = [...tracks];

    // reorganize the layers
    layerOrder.splice(i, 1);
    layerOrder.splice(nextAudioTrackSlotIndex, 0, 'audio');

    // assets now need to be adjusted to point to the correct layers
    const newConfiguration = updateLayerReferences(configuration, asset => {
      // assets outside of the range [i, nextAudioTrackSlotIndex] are not
      // affected by the track reordering
      if (asset.layerId < i || asset.layerId > nextAudioTrackSlotIndex) {
        return asset.layerId;
      }

      // layer i, the audio layer, has been moved to nextAudioTrackSlotIndex
      if (asset.layerId === i) {
        return nextAudioTrackSlotIndex;
      }

      // the layer at index i was removed and inserted closer towards the end
      // of the array, causing items above nextAudioTrackSlotIndex to "slide up"
      // and fill the spot left vacant by removing i
      return asset.layerId - 1;
    });

    return moveAudioTrack(
      { ...newConfiguration, layerOrder },
      i - 1,
      nextAudioTrackSlotIndex - 1,
    );
  };

  return moveAudioTrack(config);
}

/**
 * tries to determine if the embed config was created prior to waveform track
 * support.  If this is the case, a waveform track is added if necessary.  A waveform
 * track is only added if none exists and waveform is enabled.
 *
 * prior to waveform track support, tracks were grouped with all text at the
 * top of the stack, then all media, then audio.
 *
 * after waveform support, audio must be at the bottom but the tracks can otherwise
 * be in any order
 */
export function waveformTrackTransform(config: IEmbedConfig): IEmbedConfig {
  const { audioInfo, layerOrder, soundwave } = config;

  if (layerOrder.indexOf('waveform') >= 0) {
    // if track list contains a waveform track then there's nothing to do
    return config;
  }

  if (!soundwave?.enabled) {
    const copy = { ...config };
    delete copy.soundwave;
    return copy;
  }

  // waveform enabled but no track. insert above the highest media track
  const waveformTrackIndex = layerOrder.indexOf('media');
  const updatedLayerOrder = [...layerOrder];
  updatedLayerOrder.splice(waveformTrackIndex, 0, 'waveform');

  const audioAsset = audioInfo?.[0];

  return {
    ...updateLayerReferences(config, asset =>
      asset.layerId < waveformTrackIndex ? asset.layerId : asset.layerId + 1,
    ),
    layerOrder: updatedLayerOrder,
    soundwave: {
      ...soundwave,
      endMilli: audioAsset?.startAtMilli + audioAsset?.durationMilli,
      layerId: waveformTrackIndex,
      startMilli: audioAsset?.startAtMilli,
    },
  };
}

export function bgAudioTrackTransform(config: IEmbedConfig): IEmbedConfig {
  const { layerOrder } = config;

  // there should be at least one audio layer - if not, something is probably wrong
  // with the project, so just return the config as-is.  similarly if there is more
  // than 1 audio layer, at least one should be the bg audio
  if (countLayers(config, 'audio') !== 1) {
    return config;
  }

  return {
    ...config,
    layerOrder: [...layerOrder, 'audio'],
  };
}

/**
 * This transformation fixes a bug with the EditAudioModal.
 *
 * When the user sets a fade in/out duration in that modal, the value from the
 * slider is in the range [0, 2].  When the modal is saved, that value gets
 * multiplied by 1000 to translate it to the range [0, 2000].
 *
 * The EditAudioModal was reading the millisecond values from the configuration and
 * using them as if they were second values.  So, if the value read from the config
 * was 2000, then when the modal was saved, the number would get converted to
 * 2000 * 1000 = 2,000,000.  This pattern would repeat - next time, the modal would
 * read the value 2,000,000 and upon saving, would convert it to 2,000,000,000.
 *
 * We could work backwards and divide by 1000 to bring the number to the target range,
 * however there are more edge cases to handle (e.g. what if the duration is greater
 * than our current allowed max, but for a different reason/bug other than this
 * seconds/milliseconds issue?).  It's easier and safer just to clamp it, so that's
 * what's happening.
 */
export function audioTrackFadingTransform(config: IEmbedConfig): IEmbedConfig {
  const { audioInfo } = config;

  // if there's no audio configuration, return the config as-is
  if (!audioInfo || audioInfo.length === 0) {
    return config;
  }

  const updatedAudioInfo: IAudioInfo[] = audioInfo.map(audio => {
    if (!audio.transition) {
      return audio;
    }

    const transitionKeys = Object.keys(audio.transition) as Array<
      keyof AudioInfoTransition
    >;
    const newTransitions = transitionKeys.reduce((acc, transitionKey) => {
      const transition = audio.transition[transitionKey];

      if (!transition) {
        return acc;
      }

      acc[transitionKey] = {
        ...transition,
        durationMilli: clamp(
          transition.durationMilli,
          0,
          AUDIO_FADE_DURATION_MAX_MILLIS,
        ),
      };

      return acc;
    }, {} as AudioInfoTransition);

    return {
      ...audio,
      transition: newTransitions,
    };
  });

  return {
    ...config,
    audioInfo: updatedAudioInfo,
  };
}

export function slideshowBlurRadiusTransform(
  config: IEmbedConfig,
): IEmbedConfig {
  const { slideshowInfo } = config;

  // If there's no slideshowInfo configuration, return the config as-is.
  if (!slideshowInfo || slideshowInfo.length === 0) {
    return config;
  }

  const updatedSlideshowInfo: ISlideshowItem[] = slideshowInfo.map(slide => {
    if (!slide.style) {
      return slide;
    }

    return {
      ...slide,
      blurRadius: getSlideBlurRadiusFromStyle(slide.style),
    };
  });

  return {
    ...config,
    slideshowInfo: updatedSlideshowInfo,
  };
}

/**
 * This config transform is meant for preventing the bug detected at:
 * https://sparemin.atlassian.net/browse/SPAR-22790
 * When a project was resized at the advanced editor the captions viewport was
 * not being adjusted. That leads to unconsistencies in the way in which both
 * the captions are scaled and also in which the rechunk calculation is done.
 * This transformation checks the current viewport for the captions config object
 * at the embed and compares it to the one that would match the embed config
 * dimensions. If they are different, the expected viewport is set and the
 * shouldRechunkCaptionsOnEditorLoad is set to the editor config for the captions
 * to be recalculated when the editor is loaded.
 */
export function captionsConfigViewportTransfrom(
  config: IEmbedConfig,
): IEmbedConfig {
  const { dimensions } = config;

  try {
    const aspectRatio = getAspectRatioName(dimensions.height, dimensions.width);
    const targetViewport = VIEWPORTS[aspectRatio];
    const { captions } = config;
    const captionsViewport = captions?.editor.styleContext?.viewport;

    // only if a target viewport is found, it also exists for the captions and
    // they are different, the captions config is updated for using the correct
    // viewport.
    if (
      captionsViewport &&
      targetViewport &&
      !isEqual(captionsViewport, targetViewport)
    ) {
      const updatedCaptions: IEmbedConfig['captions'] = {
        ...captions,
        editor: {
          ...captions.editor,
          styleContext: {
            ...captions.editor.styleContext,
            viewport: targetViewport,
          },
          shouldRechunkCaptionsOnEditorLoad: true,
        },
      };

      return {
        ...config,
        captions: updatedCaptions,
      };
    }

    return config;
  } catch {
    return config;
  }
}

export const applyConfigTransforms = compose(
  audioTrackLayeringTransform,
  bgAudioTrackTransform,
  captionsConfigViewportTransfrom,
  waveformTrackTransform,
  slideshowBlurRadiusTransform,
);
