import cn from 'classnames';
import * as React from 'react';
import { TextTransitionOptionProps } from './types';

const createUrl = (direction, transition) =>
  `${spareminConfig.textTransitionUrlBases[direction]}${transition}.gif`;

const toLabel = value =>
  value.replace(/^cut$/g, 'noTransition').replace(/([A-Z])/g, ' $1');

class TextTransitionOption extends React.Component<TextTransitionOptionProps> {
  public static defaultProps: Partial<TextTransitionOptionProps> = {
    disabled: false,
  };

  public state = { showImage: true };

  private handleClick = () => {
    const { onSelect, value } = this.props;
    onSelect(value);
  };

  private hideImage = () => {
    this.setState({ showImage: false });
  };

  public render() {
    const { direction, value, selected, disabled } = this.props;
    const { showImage } = this.state;

    const url = createUrl(direction, value);

    const classNames = cn('text-transition-select__option', {
      'text-transition-select__option--selected': selected,
    });

    return (
      <button
        className={classNames}
        onClick={this.handleClick}
        disabled={disabled}
        type="button"
      >
        {showImage ? (
          <img
            className="text-transition-select__image"
            src={url}
            onError={this.hideImage}
          />
        ) : (
          <span>{toLabel(value)}</span>
        )}
      </button>
    );
  }
}

export default TextTransitionOption;
