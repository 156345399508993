import { compose } from 'redux';

import WithWindowResize from 'components/hoc/WithWindowResize';
import { PropsOf } from 'types';
import connect from './connect';
import Minimap from './Minimap';

const enhance = compose(
  connect,
  WithWindowResize(),
);

export default enhance(Minimap);

export type MinimapProps = PropsOf<ReturnType<typeof enhance>>;
