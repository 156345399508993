import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userIdSelector } from 'redux/modules/auth';

import { loadMyWorkflows } from 'redux/modules/automation-cms';
import { getOwnedPodcastsRequest } from 'redux/modules/claim-podcast';
import {
  defaultPodcastIdSelector,
  favoritePodcastIdsSelector,
  getFavoritePodcasts,
} from 'redux/modules/podcast-search';
import { isInvoicePastDueSelector } from 'redux/modules/pricing';
import { isPodcasterSelector } from 'redux/modules/survey';

import { BannerSlotTarget } from '../../types';
import { hasLoadedAllDataSelector } from './selectors';

interface IUseBannerSlotData {
  targetBanner: BannerSlotTarget;
}

const useBannerSlot = (): IUseBannerSlotData => {
  const dispatch = useDispatch();

  // loads initial data required for taking the decission on which modal should be shown
  // any new data fetch action required should be placed here
  React.useEffect(() => {
    dispatch(getFavoritePodcasts());
    dispatch(loadMyWorkflows());
    dispatch(getOwnedPodcastsRequest());
  }, [dispatch]);

  let targetBanner: BannerSlotTarget = 'none';

  const userId = useSelector(userIdSelector);
  const hasLoadedAllData = useSelector(hasLoadedAllDataSelector);

  // the next statements summarize the flow diagram for defining which banner should be shown:
  // - First all the conditions are calculated.
  // - If data is still loading, 'none' key will be returned for hiding any banner momentarily.
  // - Once data is loaded, the sequence of if statements mimics the flow diagram defined at the
  // spec.

  // is user in retry period?
  const isInRetryPeriod = useSelector(isInvoicePastDueSelector);

  // has property 'use for podcast'
  const hasPropertyUseForPodcast = useSelector(isPodcasterSelector);

  // has connected at least one podcast
  const defaultPodcastId = useSelector(defaultPodcastIdSelector);
  const favoritePodcastIds = useSelector(favoritePodcastIdsSelector);

  const hasConnectedAtLeastOnePodcast = !(
    !defaultPodcastId && Array.isArray(favoritePodcastIds)
  );

  // if data has not been loaded yet or userId is not present, no banner is shown
  if (!userId || !hasLoadedAllData) {
    targetBanner = 'none';
  } else if (isInRetryPeriod) {
    targetBanner = 'retry-banner';
  } else if (!hasPropertyUseForPodcast) {
    targetBanner = 'eddy-ai-banner';
  } else if (!hasConnectedAtLeastOnePodcast) {
    targetBanner = 'feed-collect-banner';
  } else {
    targetBanner = 'self-serve-disco-banner';
  }

  return { targetBanner };
};

export default useBannerSlot;
