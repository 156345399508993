import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { textViewBlock as block } from './utils';

const { useCallback } = React;

export interface TextChildTileProps<P = any> {
  children?: React.ReactNode;
  className?: string;
  onClick?: (params: P, e: React.MouseEvent<HTMLButtonElement>) => void;
  params?: P;
}

function TextChildTile<P = any>({
  children,
  className,
  onClick = noop,
  params,
}: TextChildTileProps<P>) {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onClick(params, e);
    },
    [onClick, params],
  );

  return (
    <button
      className={cn(block('tile'), className)}
      onClick={handleClick}
      type="button"
    >
      {children}
    </button>
  );
}

export default TextChildTile;
