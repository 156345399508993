import cn from 'classnames';
import * as React from 'react';

import { useSelector } from 'react-redux';
import { ThinWand } from 'components/icons';
import { autogramHiddenSelector } from 'redux/modules/display-pref/selectors';
import bem from 'utils/bem';
import ProjectButton from '../../containers/ProjectButton';
import { INewProjectButtonProps } from './types';

const block = bem('autogram-button');

const AutogramButton: React.SFC<INewProjectButtonProps> = ({
  className,
  ...props
}) => {
  const disabled = useSelector(autogramHiddenSelector);

  return (
    <ProjectButton
      className={cn(block(), className)}
      description="Sit back and relax while we transform each episode of any podcast into shareable videos!"
      disabled={disabled}
      icon={<ThinWand className={block('icon')} />}
      slideClassName={block('slide')}
      title="Podcast AutoVideos"
      titleClassName={block('title')}
      to={{
        pathname: '/wizard',
        query: {
          type: 'autovideo',
        },
      }}
      projectType="autogram"
      beta
      {...props}
    />
  );
};

export default AutogramButton;
