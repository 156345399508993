import * as React from 'react';

import { ToggleField } from 'components/Form';
import { AutogramClipSelectionMethod } from 'types/common';
import { capitalize } from 'utils/string';
import { automationWorkflowTileBlock as block } from '../../utils';

export interface AutogramInfoProps {
  frequency?: string;
  /**
   * If a number, it's assumed to be in seconds and will render as "{value} sec".
   * If a string, it's assumed that it's already formatted properly and will just
   * render as-is.
   *
   * This can be used to override the duration field to display any string content
   */
  durationSec?: number | string;
  enabled?: boolean;
  clipSelectionMethod?: AutogramClipSelectionMethod;
  onToggleClick?: (enabled: boolean) => void;
}

const AutogramInfo: React.FC<AutogramInfoProps> = ({
  frequency,
  durationSec,
  enabled,
  clipSelectionMethod,
  onToggleClick,
}) => {
  const getDuration = React.useCallback((): string | undefined => {
    if (!durationSec) {
      return undefined;
    }

    if (typeof durationSec === 'string') {
      return durationSec;
    }

    return `${durationSec} sec`;
  }, [durationSec]);

  return (
    <div className={block('text-box')}>
      {onToggleClick && (
        <ToggleField
          className={block('toggle')}
          onChange={onToggleClick}
          checked={enabled}
        />
      )}
      <div className={block('description')}>
        {clipSelectionMethod === AutogramClipSelectionMethod.SMART && (
          <>
            {!!frequency && capitalize(frequency)}
            {!!frequency && <br />}
            {getDuration()}
          </>
        )}
        {clipSelectionMethod === AutogramClipSelectionMethod.MANUAL && (
          <>{!!frequency && capitalize(frequency)}</>
        )}
        {clipSelectionMethod === AutogramClipSelectionMethod.SOUNDBITE_TAG && (
          <>Based on Soundbite tags in this feed</>
        )}
        {!clipSelectionMethod && <>{capitalize(frequency)}</>}
      </div>
    </div>
  );
};

export default AutogramInfo;
