import cn from 'classnames';
import * as React from 'react';
import { H1 } from '../Heading';
import NonIdealStateButton from './NonIdealStateButton';
import NonIdealStateLinkButton from './NonIdealStateLinkButton';
import NonIdealStateOverlay from './NonIdealStateOverlay';
import { block } from './utils';

export interface NonIdealStateProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
}

interface INonIdealState extends React.FC<NonIdealStateProps> {
  Button: typeof NonIdealStateButton;
  LinkButton: typeof NonIdealStateLinkButton;
  Overlay: typeof NonIdealStateOverlay;
}

const NonIdealState: INonIdealState = props => {
  const { className, icon, title, description, children } = props;
  return (
    <div className={cn(block(), className)}>
      {icon && <div className={block('visual')}>{icon}</div>}
      {title && <H1 className={block('title')}>{title}</H1>}
      {description && <div className={block('description')}>{description}</div>}
      {children}
    </div>
  );
};

NonIdealState.Button = NonIdealStateButton;
NonIdealState.LinkButton = NonIdealStateLinkButton;
NonIdealState.Overlay = NonIdealStateOverlay;

export default NonIdealState;
