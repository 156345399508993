import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isUndefined, pick } from 'underscore';

import {
  clearAnnouncements,
  sendViewConfirmation,
} from 'redux/modules/announcement/actions';
import { announcementToShowSelector } from 'redux/modules/announcement/selectors';
import { pricingDataLoadedSelector } from 'redux/modules/pricing';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import { createChainedFunction } from 'utils/functions';
import ProjectsPageModalManager, {
  ModalType,
  ProjectsPageModalManagerProps as Props,
} from './ProjectsPageModalManager';

type StateProps = Pick<Props, 'announcement' | 'announcementActive'>;
type DispatchProps = Pick<Props, 'onHide'>;
type OwnProps = Pick<Props, 'onHide'>;

/*
 * this selector seems trivial but is here so we can wait for additional
 * async data if necessary.
 */
const hasEnoughDataSelector = createSelector(
  pricingDataLoadedSelector,
  loaded => loaded,
);

const announcementSelector = createSelector(
  announcementToShowSelector,
  announcement => announcement && pick(announcement, 'id', 'announcementParts'),
);

const announcementActiveSelector = createSelector(
  [hasEnoughDataSelector, announcementSelector],
  (hasData, announcement = {}) =>
    hasData && !isUndefined(announcement.announcementParts),
);

const mapStateToProps = (state: State): StateProps => ({
  announcement: announcementSelector(state),
  announcementActive: announcementActiveSelector(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onHide: createChainedFunction((modalType, announcement) => {
    if (modalType === 'announcement') {
      dispatch(clearAnnouncements());
      dispatch(sendViewConfirmation(announcement.id));
    }
  }, props.onHide),
});

const component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectsPageModalManager);
export type ProjectsPageModalManagerProps = PropsOf<typeof component>;
export default component;

export { ModalType };
