import { List } from 'immutable';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  aspectRatioSelector,
  slidesSelector,
  videosByIdSelector,
} from 'redux/modules/embed/selectors';
import { State } from 'redux/types';
import { PropsOf } from 'types';
import MediaAssets, {
  assetFactory,
  MediaAssetsProps as Props,
} from './MediaAssets';

type StateProps = Pick<Props, 'assets' | 'aspectRatio'>;

const orderedAssetIdsSelector = createSelector(
  [slidesSelector, videosByIdSelector],
  (slides, videos) => {
    const slideIds = !slides
      ? []
      : slides.reduce((acc, slide) => {
          acc.push({
            id: slide.get('id'),
            startMillis: slide.get('startMilli'),
            type: 'image',
          });
          return acc;
        }, []);

    const videoIds = !videos
      ? []
      : videos.reduce((acc, video) => {
          acc.push({
            id: video.id,
            startMillis: video.startMillis,
            type: 'video',
          });
          return acc;
        }, []);

    return [...slideIds, ...videoIds].sort(
      (v1, v2) => v1.startMillis - v2.startMillis,
    );
  },
);

const assetsSelector = createSelector(
  [slidesSelector, videosByIdSelector, orderedAssetIdsSelector],
  (slides, videos, order) => {
    const assets = order.map(({ id, startMillis, type }) => {
      if (type === 'image') {
        return assetFactory({
          id,
          startMillis,
          type,
          src: slides.getIn([id, 'imageUrl']),
        });
      }
      return assetFactory({
        id,
        startMillis,
        type,
        src: videos.getIn([id, 'previewThumbnailUrl']),
      });
    });
    return List(assets);
  },
);

const mapStateToProps = (state: State): StateProps => ({
  aspectRatio: aspectRatioSelector(state),
  assets: assetsSelector(state),
});

const component = connect(mapStateToProps)(MediaAssets);
export type MediaAssetsProps = PropsOf<typeof component>;
export default component;
