import cn from 'classnames';
import * as React from 'react';

import { H1, H2 } from 'components/Heading';
import Modal from 'components/Modal';
import { block } from './utils';

export interface PlanDecisionModalBodyProps {
  children?: React.ReactNode;
  className?: string;
  heading: React.ReactNode;
  headerClassName?: string;
  subheading: React.ReactNode;
}

const PlanDecisionModalBody: React.FC<PlanDecisionModalBodyProps> = ({
  children,
  className,
  heading,
  headerClassName,
  subheading,
}) => (
  <Modal.Body className={cn(block('body'), className)}>
    <div className={cn(block('header'), headerClassName)}>
      <H1 className={block('heading')}>{heading}</H1>
      <H2 className={block('subhead')}>{subheading}</H2>
    </div>
    {children}
  </Modal.Body>
);

export default PlanDecisionModalBody;
