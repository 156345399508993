import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { downloadTranscripts } from 'redux/modules/embed/actions';
import { hideModal } from 'redux/modules/modal';
import { CaptionFormat } from 'types';
import CaptionsFormatSelector from './CaptionsFormatSelector';
import DownloadCaptionsModalContents from './DownloadCaptionsModalContents';

const CAPTIONS_FORMAT_OPTIONS = [CaptionFormat.VTT, CaptionFormat.SRT];

export interface DownloadProjectCaptionsModalContentsProps {}

const DownloadProjectCaptionsModalContents: React.FC<DownloadProjectCaptionsModalContentsProps> = () => {
  const dispatch = useDispatch();
  const [format, setFormat] = useState(CAPTIONS_FORMAT_OPTIONS[0]);

  const handleSubmit = useCallback(() => {
    dispatch(downloadTranscripts(format));
    dispatch(hideModal());
  }, [dispatch, format]);

  return (
    <DownloadCaptionsModalContents
      action={{
        children: 'download',
        fluid: true,
        onClick: handleSubmit,
        theme: 'submit',
      }}
    >
      <CaptionsFormatSelector
        options={CAPTIONS_FORMAT_OPTIONS}
        onChange={setFormat}
        value={format}
      />
    </DownloadCaptionsModalContents>
  );
};

export default DownloadProjectCaptionsModalContents;
