import * as React from 'react';
import { getDisplayName } from 'utils/react';

interface InjectedProps<P = any> {
  wrapper?: React.ComponentType<P>;
  wrapperProps?: P;
}

/**
 * Ehances the provided component by adding `wrapper` and `wrapperProps` props.
 * The wrapper defaults to a react fragment.
 */
export default function withWrapper() {
  return <CP extends {}>(Component: React.ComponentType<CP>) => {
    function EnhancedComponent<WP = any>({
      wrapper: Wrapper = React.Fragment,
      wrapperProps,
      ...props
    }: CP & InjectedProps<WP>) {
      return (
        <Wrapper {...wrapperProps}>
          <Component {...(props as CP)} />
        </Wrapper>
      );
    }

    EnhancedComponent.displayName = `withWrapper(${getDisplayName(Component)})`;

    return EnhancedComponent;
  };
}

export type CreateWithWrapperProps<
  C extends React.ComponentType,
  WP
> = React.ComponentPropsWithoutRef<C> & InjectedProps<WP>;
