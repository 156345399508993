import * as React from 'react';
import { useSelector } from 'react-redux';
import { isFunction, noop } from 'underscore';

import UploadInput, {
  UploadInputProps,
} from 'components/UploadButton/UploadInput';
import { videoUploadLimitMbSelector } from 'redux/modules/pricing';
import { CropMetadata } from 'types';
import { getSupportedMediaMimeTypes } from 'utils/formats';

const { useCallback, useImperativeHandle, useRef } = React;

export interface MediaFileSelectorRenderProps {
  onClick?: () => void;
}

export interface MediaFileSelectorInstance {
  open: () => void;
}
export interface BackgroundImagePayload {
  src: Blob;
  original: Blob;
  metadata: CropMetadata;
  fileName: string;
}

type PickedInputProps = Pick<
  UploadInputProps,
  'onFileAccepted' | 'onFileRejected'
>;

export interface MediaFileSelectorProps extends PickedInputProps {
  accept?: (string | string[])[];
  children?: React.FC<MediaFileSelectorRenderProps>;
  testId?: string;
}

const MediaFileSelector = React.forwardRef<
  MediaFileSelectorInstance,
  MediaFileSelectorProps
>(
  (
    {
      accept = getSupportedMediaMimeTypes(),
      children,
      onFileAccepted,
      onFileRejected = noop,
      testId,
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>();

    const maxVideoSizeMb = useSelector(videoUploadLimitMbSelector);

    const handleClick = useCallback(() => {
      inputRef.current.click();
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        open: handleClick,
      }),
      [handleClick],
    );

    return (
      <>
        {isFunction(children) && children({ onClick: handleClick })}
        <UploadInput
          accept={accept.join(',')}
          maxSizeMb={maxVideoSizeMb}
          ref={inputRef}
          style={{ display: 'none' }}
          testId={testId}
          {...{ onFileAccepted, onFileRejected }}
        />
      </>
    );
  },
);

export default MediaFileSelector;
