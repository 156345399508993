import React, { useCallback } from 'react';

import { VideoCamera } from 'components/icons';
import { RequestStatus } from 'types';
import { createChainedFunction } from 'utils/functions';
import ClipCtaButton, { ClipCtaButtonProps } from '../components/ClipCtaButton';
import { useClipSelect } from './ClipSelectContext';
import { useClipSelectTracking } from './ClipSelectTrackingContext';

export type AddToVideoButtonProps = Pick<
  ClipCtaButtonProps,
  'disabled' | 'id' | 'theme' | 'tooltip'
>;

const AddToVideoButton: React.FC<AddToVideoButtonProps> = ({
  disabled,
  id,
  theme,
  tooltip,
}) => {
  const { onSubmit, submitStatus } = useClipSelect();
  const { onAddClip } = useClipSelectTracking();

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <ClipCtaButton
      disabled={disabled || submitStatus === RequestStatus.REQUEST}
      icon={<VideoCamera style={{ height: 15, width: 'auto' }} />}
      onClick={createChainedFunction(handleSubmit, onAddClip)}
      {...{ disabled, id, theme, tooltip }}
    >
      add to video
    </ClipCtaButton>
  );
};

export default AddToVideoButton;
