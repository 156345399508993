import * as React from 'react';

import OriginalArticlePanel, {
  OriginalArticlePanelProps,
} from './OriginalArticlePanel';
import SummaryPanel, { SummaryPanelProps } from './SummaryPanel';
import { block } from './utils';

interface IProps extends SummaryPanelProps {
  originalArticle?: OriginalArticlePanelProps['text'];
}

const EditSummaryStep: React.SFC<IProps> = ({
  value,
  maxLength,
  onChange,
  originalArticle,
}) => (
  <div className={block()}>
    {originalArticle && <OriginalArticlePanel text={originalArticle} />}
    <SummaryPanel
      value={value}
      maxLength={maxLength}
      onChange={onChange}
      hideTitle={!originalArticle}
      solo={!originalArticle}
    />
  </div>
);

EditSummaryStep.defaultProps = {
  value: {
    charCount: 0,
    text: '',
  },
};

export default EditSummaryStep;
export { IProps as EditSummaryStepProps };
