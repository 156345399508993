import * as React from 'react';

import { PaintCan } from 'components/icons';
import { Omit } from 'types';
import ColorPickerTile, {
  ColorPickerTileProps,
} from '../../components/ColorPickerTile';

type PickedTileProps = Omit<
  ColorPickerTileProps,
  'disableAlpha' | 'icon' | 'label'
>;

export type BackgroundColorTileProps = PickedTileProps;

const BackgroundColorTile: React.SFC<BackgroundColorTileProps> = ({
  color,
  ...props
}) => (
  <ColorPickerTile
    color={color}
    disableAlpha
    icon={<PaintCan style={{ width: '46%' }} color={color} />}
    label="background color"
    {...props}
  />
);

export default BackgroundColorTile;
