import React from 'react';

import useTemplateThumbnail from 'hooks/useTemplateThumbnail';

import { PodcastSubscription } from 'redux/middleware/api/podcast-service';
import EditAutomationVisuals from './EditAutomationVisuals';
import useEditVisuals from './useEditVisuals';

interface EditAutomationVisualsContainerProps {
  subscription?: PodcastSubscription;
}

const EditAutomationVisualsContainer: React.FunctionComponent<EditAutomationVisualsContainerProps> = props => {
  const { subscription } = props;

  const { imageUrl, isGenerating, progressOverlay } = useTemplateThumbnail({
    podcastSubscriptionId: subscription?.autoCreateSubscriptionId,
  });

  const {
    editAspectRatioLink,
    editTemplateLink,
    onEditAspectRatio,
    onEditTemplate,
  } = useEditVisuals(subscription);

  return (
    <EditAutomationVisuals
      dimension={subscription?.dimension}
      editAspectRatioLink={editAspectRatioLink}
      editTemplateLink={editTemplateLink}
      imageUrl={imageUrl}
      isGenerating={isGenerating}
      onEditAspectRatio={onEditAspectRatio}
      onEditTemplate={onEditTemplate}
      progressOverlay={progressOverlay}
    />
  );
};

export default EditAutomationVisualsContainer;
