import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { InstagramMediaType } from 'redux/middleware/api/podcast-service';
import { pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { AutogramSubscriptionOptions, DeepPartial } from 'types';

export interface UseInstagramAutomationAuthenticatorResults {
  authenticate: (
    mediaType: InstagramMediaType,
  ) => Promise<DeepPartial<AutogramSubscriptionOptions>>;
}

export default function useInstagramAutomationAuthenticator(): UseInstagramAutomationAuthenticatorResults {
  const reduxDispatch = useDispatch<Dispatch>();

  const authenticate = useCallback(
    async (
      mediaType: InstagramMediaType,
    ): Promise<DeepPartial<AutogramSubscriptionOptions>> => {
      const result = await reduxDispatch(
        pushModal({
          name: 'InstagramAutoPost',
          params: {
            mediaType,
          },
        }),
      );

      if (result) {
        return {
          autopost: result,
        };
      }

      return undefined;
    },
    [reduxDispatch],
  );

  return {
    authenticate,
  };
}
