import React from 'react';
import { noop } from 'underscore';

import ModalFooterButton from 'components/Modal/ModalFooterButton';
import { Handle } from 'components/SocialAccountInfo';
import { Step } from 'components/SteppedModal';
import SwitchAccountMessage from 'components/SwitchAccountMessage';
import useThreadsAuthenticator from 'hooks/useThreadsAuthenticator';
import useThreadsPostModalSelector from '../useThreadsPostModalSelector';

export interface UseSwitchAccountStepConfig extends Pick<Step, 'title'> {
  onError?: (message: string) => void;
  onSwitchAccountSuccess?: () => void;
}

export default function useSwitchAccountStep(
  config: UseSwitchAccountStepConfig = {},
): Step {
  const {
    onError = noop,
    onSwitchAccountSuccess = noop,
    title = 'switch account',
  } = config;

  const { username } = useThreadsPostModalSelector();

  const { authenticating, withAuthentication } = useThreadsAuthenticator({
    onError: error => onError?.(error.message),
    force: true,
  });

  return {
    id: 'switch',
    title,
    component: (
      <SwitchAccountMessage username={<Handle username={username} />} />
    ),
    renderFooterButtons: ({ cancel, submit }) => [
      <ModalFooterButton {...cancel} key="cancel">
        cancel
      </ModalFooterButton>,

      <ModalFooterButton
        {...submit}
        key="submit"
        theme="submit"
        disabled={authenticating}
        onClick={withAuthentication(onSwitchAccountSuccess)}
      >
        switch accounts
      </ModalFooterButton>,
    ],
  };
}
