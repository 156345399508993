import React, { useCallback, useMemo } from 'react';
import { Flipped } from 'react-flip-toolkit';
import { useSelector } from 'react-redux';
import { noop } from 'underscore';
import Tooltip from 'components/Tooltip';
import {
  makePodcastArtworkSelector,
  makePodcastTitleSelector,
} from 'redux/modules/podcast-search/selectors';

import PodcastDeleteButton from '../../components/PodcastDeleteButton';
import PodcastSidebarItem from '../../components/PodcastSidebarItem';
import { podcastSidebarBlock as block } from '../../utils';
import { PodcastSidebarPodcastProps } from './types';

const PodcastSidebarPodcast: React.FC<PodcastSidebarPodcastProps> = ({
  active,
  onClick,
  onDeleteClick,
  podcastFeedId: podcastId,
}) => {
  const artworkUrl = useSelector(
    useMemo(() => makePodcastArtworkSelector(podcastId), [podcastId]),
  );
  const title = useSelector(
    useMemo(() => makePodcastTitleSelector(podcastId), [podcastId]),
  );
  const handleClick = useCallback(() => {
    onClick(podcastId);
  }, [podcastId, onClick]);

  const handleDeleteClick = useCallback(() => {
    onDeleteClick(podcastId);
  }, [podcastId, onDeleteClick]);

  return (
    <Flipped flipId={podcastId}>
      {flippedProps => (
        <PodcastSidebarItem
          className={block('podcast-item', { active })}
          flippedProps={flippedProps}
          onClick={handleClick}
        >
          <Tooltip
            content={title}
            id={`${podcastId}-tooltip`}
            placement="right"
            hideArrow
          >
            <div className={block('artwork-container')}>
              <img className={block('artwork')} src={artworkUrl} alt={title} />
              <div className={block('artwork-overlay')}>
                <PodcastDeleteButton onClick={handleDeleteClick} />
              </div>
            </div>
          </Tooltip>
        </PodcastSidebarItem>
      )}
    </Flipped>
  );
};

PodcastSidebarPodcast.defaultProps = {
  active: false,
  onClick: noop,
  onDeleteClick: noop,
};

export default PodcastSidebarPodcast;
