import * as mediaUploadService from 'redux/middleware/api/media-upload-service/actions';
import { ThunkAction } from 'redux/types';
import { getDuration } from 'utils/video';
import { waitForVideoUpload } from '../common/actions/video';
import { Type } from './action-types';
import { revokableSelector, videoUrlSelector } from './selectors';
import {
  PrepareVideoSuccessAction,
  ResolvedVideoSource,
  VideoAssetType,
  VideoSource,
} from './types';

const calculateDuration = (video: File | string) => () => getDuration(video);

const resolveVideoSource = (
  video: VideoSource,
  type: VideoAssetType,
): ThunkAction<Promise<ResolvedVideoSource>> => async dispatch => {
  if (type === 'video') {
    return { src: video };
  }

  const { response } = await dispatch(
    mediaUploadService.uploadVideo(video, { transcribe: false }),
  );
  const uploadId = response.result;

  const videoEntity = await dispatch(waitForVideoUpload(uploadId));
  const variations = videoEntity.get('variations');
  const transcodedVideoUrl = videoEntity.get('transcodedVideoUrl');

  const transparentVariation = variations?.find(v => v.isTransparent);

  if (transparentVariation) {
    return { src: transparentVariation.url, videoEntity };
  }

  return { src: transcodedVideoUrl, videoEntity };
};

export const prepareVideoForEditing = (
  source: VideoSource,
  poster?: string,
  assetType: VideoAssetType = 'video',
): ThunkAction<Promise<PrepareVideoSuccessAction>> => dispatch =>
  dispatch(resolveVideoSource(source, assetType)).then(
    ({ src, videoEntity }) => {
      const videoIsFile = src instanceof File;
      const url = videoIsFile ? URL.createObjectURL(src) : src;
      dispatch({
        payload: {
          assetType,
          poster,
          url,
          revokable: videoIsFile,
        },
        type: Type.VIDEO_EDIT_PREPARE_REQUEST,
      });

      return dispatch(calculateDuration(src))
        .then(duration =>
          // as video upload result is not saved as an entity it is saved when
          // the edit prepare success action is dispatched.
          dispatch({
            payload: { duration, videoEntity },
            type: Type.VIDEO_EDIT_PREPARE_SUCCESS,
          } as PrepareVideoSuccessAction),
        )
        .catch(error => {
          dispatch({
            error:
              error.message ||
              (error.code && `Error with media: ${error.code}`),
            type: Type.VIDEO_EDIT_PREPARE_FAILURE,
          });
          throw error;
        });
    },
  );

export const clearVideoEdit = (): ThunkAction<void> => (dispatch, getState) => {
  const videoUrl = videoUrlSelector(getState());
  const revokable = revokableSelector(getState());

  if (revokable) {
    URL.revokeObjectURL(videoUrl);
  }

  dispatch({ type: Type.VIDEO_EDIT_CLEAR });
};
