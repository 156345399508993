import * as React from 'react';

import PaidFeature, { PaidFeatureProps } from './PaidFeature';
import { block } from './utils';

export interface PaidFeaturesProps {
  features: PaidFeatureProps[];
}

const PaidFeatures: React.SFC<PaidFeaturesProps> = ({ features }) => (
  <div className={block('features')}>
    {features.map(fprops => (
      <PaidFeature key={fprops.label} {...fprops} />
    ))}
  </div>
);

export default PaidFeatures;
