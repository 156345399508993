import cn from 'classnames';
import * as React from 'react';

import { IconButtonProps } from 'components/IconButton';
import AssetTileBackground from './AssetTileBackground';
import AssetTileDeleteButton from './AssetTileDeleteButton';
import AssetTileDisplayName from './AssetTileDisplayName';
import { Background } from './types';
import { block, isImageBackground } from './utils';

const { useRef, useEffect } = React;

export interface AssetTileProps {
  aspectRatio?: number;
  background?: Background;
  children?: React.ReactNode;
  className?: string;
  deleteButtonClassName?: string;
  displayName?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onDeleteClick?: IconButtonProps['onButtonClick'];
  onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseOut?: (e: React.MouseEvent<HTMLDivElement>) => void;
  permanent?: boolean;
  style?: React.CSSProperties;
}

const AssetTile = React.forwardRef<HTMLDivElement, AssetTileProps>(
  (
    {
      aspectRatio,
      background,
      children,
      className,
      deleteButtonClassName,
      displayName,
      onClick,
      onDeleteClick,
      onMouseOut,
      onMouseOver,
      permanent,
      style,
    },
    ref,
  ) => {
    const video = useRef<HTMLVideoElement>();
    const hasVideoBackground = background && !isImageBackground(background);

    const play = () => {
      if (video.current) {
        video.current.play().catch(() => {
          /*
           * catches "play was interrupted by pause" which is legal here. the
           * user might mouse out before playback has started, in which case
           * we want to make sure playback doesn't start
           */
        });
      }
    };

    const pause = React.useCallback(() => {
      if (video.current) {
        video.current.pause();
        video.current.currentTime = 0;
      }
    }, [video]);

    const handleMouseEnter = play;

    const handleMouseLeave = pause;

    useEffect(() => pause, [pause]);

    return (
      <div
        className={cn(block(), className)}
        onClick={onClick}
        onMouseEnter={hasVideoBackground ? handleMouseEnter : undefined}
        onMouseLeave={hasVideoBackground ? handleMouseLeave : undefined}
        {...{ onMouseOut, onMouseOver, ref, style, onClick }}
      >
        <AssetTileBackground
          aspectRatio={aspectRatio}
          background={background}
          videoRef={video}
        />
        {!permanent && (
          <AssetTileDeleteButton
            className={deleteButtonClassName}
            onClick={onDeleteClick}
          />
        )}
        {displayName && (
          <AssetTileDisplayName>{displayName}</AssetTileDisplayName>
        )}
        {children}
      </div>
    );
  },
);

AssetTile.defaultProps = {
  permanent: false,
};

AssetTile.displayName = 'ForwardRef(AssetTile)';

export default AssetTile;
