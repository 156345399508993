import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  performDpa,
  sendDownloadPageActionEvent,
} from 'redux/modules/download/actions';
import { onClickPostThreadsVideo } from 'redux/modules/mixpanel/actions';
import { Dispatch } from 'redux/types';

export default function useThreadsPostButton() {
  const dispatch = useDispatch<Dispatch>();

  const onClick = useCallback(() => {
    dispatch(performDpa);
    dispatch(onClickPostThreadsVideo());
    dispatch(sendDownloadPageActionEvent());
  }, [dispatch]);

  return { onClick };
}
