import * as React from 'react';

interface IProps {
  // if true, will add an entry to the browser's history. essentially push vs. replace
  history?: boolean;
  location: string;
}

export default class ExternalRedirect extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    history: true,
  };

  public UNSAFE_componentWillMount() {
    const { history, location } = this.props;
    history
      ? (window.location.href = location)
      : window.location.replace(location);
  }

  public render() {
    return null;
  }
}

export { IProps as ExternalRedirectProps };
