import * as React from 'react';
import { connect } from 'react-redux';

import { projectEmbedConfigurationIdSelector } from 'redux/modules/project/selectors';
import { State } from 'redux/types';
import { TemplateLengthSelectorModalProps } from '.';

type StateProps = Pick<TemplateLengthSelectorModalProps, 'configurationId'>;

const mapStateToProps = (state: State): StateProps => ({
  configurationId: projectEmbedConfigurationIdSelector(state),
});

export default function(
  component: React.ComponentType<TemplateLengthSelectorModalProps>,
) {
  return connect(mapStateToProps)(component);
}
