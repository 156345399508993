import { MetadataField } from 'components/Metadata/types';

export const MAX_CHARS_CAPTIONS = 2500;
export const MAX_CHARS_CAPTIONS_PREVIEW = 2500;

export const captionsMetadata: MetadataField[] = [
  {
    label: 'Podcast Title',
    value: '<Podcast Title>',
  },
  {
    label: 'Episode Title',
    value: '<Episode Title>',
  },
  {
    label: 'Episode Number',
    value: '<Episode Number>',
  },
  {
    label: 'Podcast Description',
    value: '<Podcast Description>',
  },
  {
    label: 'Episode Description',
    value: '<Episode Description>',
  },
  {
    label: 'Link To Episode',
    value: '<Link To Episode>',
  },
];
