import React from 'react';
import FontAwesome from './FontAwesome';

export interface GradientIconProps {
  children?: React.ReactNode;
  gradient?: string;
  gradientUrl?: string;
}

const GradientIcon: React.FC<GradientIconProps> = ({
  children,
  gradient = 'linear-gradient(130deg, #5dbefc, #dd92fe)',
  gradientUrl = 'url(#pro-badge-gradient)',
}) => {
  if (!children) {
    return null;
  }

  const child = React.Children.only(children);

  if (!React.isValidElement(child)) {
    return <>{child}</>;
  }

  if (child.type === FontAwesome) {
    return React.cloneElement(child, {
      mask: 'square-full',
      style: {
        ...child.props.style,
        backgroundImage: gradient,
      },
    });
  }

  // it's assumed that the icon is one of our svgs, to which gradient is applied
  // by assigning the "fill" prop to the appropriate element in the svg tree
  return React.cloneElement(child, {
    fill: gradientUrl,
  });
};

export default GradientIcon;
