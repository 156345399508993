import * as React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  underlineColor?: string;
}

const TextHighlightIcon: React.SFC<IProps> = ({
  underlineColor,
  ...svgProps
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 60 78"
    {...svgProps}
  >
    {/* tslint:disable max-line-length */}
    <path
      fill="currentColor"
      d="m1.14,62.084c-1.163,-0.36 -1.523,-1.722 -0.672,-2.515l3.486,-3.19c0.311,-0.284 0.491,-0.673 0.491,-1.077l0,-9.491c0,-0.405 0.18,-0.794 0.491,-1.078l4.224,-3.863a1.77,1.77 0 0 1 2.357,0l15.303,13.968c0.655,0.599 0.655,1.557 0,2.156l-4.222,3.862c-0.311,0.285 -0.737,0.45 -1.179,0.45l-11.065,0l-2.946,2.694l-6.269,-1.916l0.001,0zm32.155,-13.13l-15.412,-13.929c-0.701,-0.635 -0.668,-1.662 0.084,-2.25l40.611,-31.828c1.687,-1.33 4.208,-1.254 5.794,0.181l6.38,5.77c1.586,1.436 1.67,3.717 0.2,5.242l-35.168,36.738c-0.651,0.664 -1.786,0.71 -2.488,0.075l-0.001,0.001z"
    />
    <path
      fill={underlineColor}
      d="m68.39192,70l-64.79235,0c-1.98276,0 -3.59958,1.795 -3.59958,4s1.6158,4 3.59958,4l64.79235,0c1.98276,0 3.59958,-1.795 3.59958,-4s-1.60181,-4 -3.59958,-4z"
    />
    {/* tslint:enable */}
  </svg>
);

TextHighlightIcon.defaultProps = {
  underlineColor: 'currentColor',
};

export default TextHighlightIcon;
export { IProps as TextHighlightIconProps };
