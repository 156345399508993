import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import MediaCard from 'components/MediaCard';
import { block } from './utils';

interface EpisodesCardOptionsProps {
  isFreeTier: boolean;
  isDisabled: boolean;
  onTargetedSeasonsClick: () => void;
  onPublishClick: () => void;
}

const EpisodesCardOptions: React.FC<EpisodesCardOptionsProps> = ({
  isFreeTier,
  isDisabled,
  onTargetedSeasonsClick,
  onPublishClick,
}) => {
  return (
    <Spacer
      orientation="horizontal"
      align="center"
      justify="space-between"
      className={block()}
    >
      <MediaCard
        isLocked={isFreeTier && !isDisabled}
        isDisabled={isDisabled}
        tooltipContent={isDisabled && 'Only available for full episodes'}
        onClick={onTargetedSeasonsClick}
      >
        <MediaCard.MediaCardImage imgSrc="https://static.sparemin.com/static/webapp-assets/backgrounds/automation-back-catalog-card-202307101445.jpg" />

        <MediaCard.MediaCardContent
          title="Back catalog"
          description="We’ll automatically turn your existing episodes into audiograms based on the template you select in the next step. We’ll create audiograms for up to three episodes a day."
          buttonContent={
            isFreeTier && !isDisabled
              ? 'upgrade to unlock →'
              : 'Continue with existing episodes →'
          }
        />
      </MediaCard>

      <MediaCard onClick={onPublishClick}>
        <MediaCard.MediaCardImage imgSrc="https://static.sparemin.com/static/webapp-assets/backgrounds/automation-future-episodes-card-202307101445.jpg" />

        <MediaCard.MediaCardContent
          title="Future episodes"
          description="Each time you release an episode we’ll automatically turn it into an audiogram based on the template you select in the next step. We’ll create audiograms for up to three episodes a day."
          buttonContent="Continue with future episodes →"
        />
      </MediaCard>
    </Spacer>
  );
};

export default EpisodesCardOptions;
