import { useEffect, useRef } from 'react';

type MountFn = () => void;

export default function useOnMount(onMount: MountFn, onUnmount?: MountFn) {
  const onMountRef = useRef(onMount);
  const onUnmountRef = useRef(onUnmount);

  useEffect(() => {
    onMountRef.current = onMount;
    onUnmountRef.current = onUnmount;
  }, [onMount, onUnmount]);

  useEffect(() => {
    onMountRef.current?.();
    return onUnmountRef.current;
  }, []);
}
