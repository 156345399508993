import * as React from 'react';

import { PaintCan } from 'components/icons';
import { useEditorState } from '../context/VideoEditorStateContext';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import ColorOptionTile from './ColorOptionTile';

const { useCallback } = React;

export interface BackgroundTileProps {
  className?: string;
  onClick?: (view: 'background_color') => void;
}

const BackgroundTile: React.FC<BackgroundTileProps> = ({
  className,
  onClick,
}) => {
  const dispatch = useTemplateDispatch();
  const { backgroundColor } = useTemplateState();
  const { disabled, isLoading } = useEditorState();

  const isDisabled = disabled || isLoading;

  const handleChange = useCallback(
    (color: string) => {
      dispatch({
        payload: color,
        type: 'BACKGROUND_COLOR_CHANGE',
      });

      onClick('background_color');
    },
    [dispatch, onClick],
  );

  return (
    <ColorOptionTile
      disableAlpha
      className={className}
      disabled={isDisabled}
      color={backgroundColor}
      icon={<PaintCan height={50} />}
      id="template-bg-tile"
      label="background"
      onChangeComplete={handleChange}
    />
  );
};

export default BackgroundTile;
