import React, { useContext, useMemo } from 'react';
import { ToggleCardContextType } from './types';

const ToggleCardContext = React.createContext<
  ToggleCardContextType | undefined
>(undefined);

interface ToggleCardProviderProps extends ToggleCardContextType {
  children?: React.ReactNode;
}

export const ToggleCardProvider: React.FC<ToggleCardProviderProps> = ({
  checked,
  children,
  isProGated,
  theme,
}) => (
  <ToggleCardContext.Provider
    value={useMemo(() => ({ checked, isProGated, theme }), [
      checked,
      isProGated,
      theme,
    ])}
  >
    {children}
  </ToggleCardContext.Provider>
);

export function useToggleCard() {
  const context = useContext(ToggleCardContext);

  if (context === undefined) {
    throw new Error('useToggleCard must be used within ToggleCardProvider');
  }

  return context;
}
