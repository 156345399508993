import cn from 'classnames';
import * as React from 'react';
import { ApplicationError } from 'utils/ApplicationError';

import { hasValidType } from 'utils/file';
import { block } from './utils';

const { useCallback } = React;

type PickedInputProps = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  'accept' | 'style'
>;

export class FileRejectedError extends ApplicationError {
  file?: File;

  constructor(message: string, code: string, file: File) {
    super(message, code);
    this.file = file;
  }
}

export interface UploadInputProps extends PickedInputProps {
  className?: string;
  maxSizeMb?: number;
  onFileAccepted?: (file: File) => void;
  onFileRejected?: (error: FileRejectedError) => void;
  testId?: string;
}

const UploadInput = React.forwardRef<HTMLInputElement, UploadInputProps>(
  (
    {
      accept = '*',
      className,
      maxSizeMb = Infinity,
      onFileAccepted,
      onFileRejected,
      style,
      testId = 'upload-input',
    },
    ref,
  ) => {
    const handleFileRejected = useCallback(
      (error: FileRejectedError, element: HTMLInputElement) => {
        onFileRejected(error);
        element.value = null;
      },
      [onFileRejected],
    );

    const handleInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const element = e.currentTarget;
        const file = element?.files?.[0];

        if (!hasValidType(file, accept)) {
          handleFileRejected(
            new FileRejectedError('File type not supported', 'IN001', file),
            element,
          );
        } else if (file.size / 1024 ** 2 > maxSizeMb) {
          handleFileRejected(
            new FileRejectedError(
              `File exceeds max size of ${maxSizeMb} MB`,
              'IN006',
              file,
            ),
            element,
          );
        } else {
          onFileAccepted(file);
        }
      },
      [accept, maxSizeMb, handleFileRejected, onFileAccepted],
    );

    return (
      <input
        accept={accept}
        className={cn(block('input'), className)}
        onChange={handleInputChange}
        ref={ref}
        style={style}
        type="file"
        value=""
        data-testid={testId}
      />
    );
  },
);

export default UploadInput;
