import React from 'react';

// types
import { IconProps } from './types';

const YoutubeSquare: React.FC<IconProps> = ({ ...svgProps }) => (
  <svg
    width="95"
    height="95"
    viewBox="0 0 95 95"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <g fill="none" fillRule="evenodd">
      <rect fill="#FFF" width="95" height="95" rx="20" />
      <path
        d="M70.976 36.32a6.116 6.116 0 0 0-4.332-4.303C62.823 31 47.5 31 47.5 31s-15.323 0-19.144 1.017a6.116 6.116 0 0 0-4.332 4.303C23 40.115 23 48.034 23 48.034s0 7.92 1.024 11.715c.563 2.094 2.224 3.674 4.332 4.234C32.177 65 47.5 65 47.5 65s15.323 0 19.144-1.017c2.108-.56 3.769-2.14 4.332-4.234C72 55.953 72 48.034 72 48.034s0-7.919-1.024-11.714zM42.49 55.224v-14.38l12.806 7.19-12.806 7.19z"
        fill="red"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default YoutubeSquare;
