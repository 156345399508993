import { createSelector } from 'reselect';
import { State } from 'redux/types';
import { PodcastState } from './types';

const stateSelector = (state: State): PodcastState => state.get('podcast');

export const isRefreshingPodcastFeedSelector = createSelector(
  stateSelector,
  state => state.isRefreshingPodcastFeed,
);
