import React from 'react';
import { ModalFooterButton } from 'components/Modal';
import PermissionNotGrantedMessage from 'components/PermissionNotGrantedMessage';
import { Step } from 'components/SteppedModal';
import useThreadsAuthenticator from 'hooks/useThreadsAuthenticator';

export interface UseAccessDeniedStepConfig extends Pick<Step, 'title'> {
  onError?: (message: string) => void;
  onReviseAccess?: () => void;
}

export default function useAccessDeniedStep({
  title = 'we need permission to post',
  onReviseAccess,
  onError,
}: UseAccessDeniedStepConfig): Step {
  const { authenticating, withAuthentication } = useThreadsAuthenticator({
    onError: error => onError?.(error.message),
    force: true,
  });

  return {
    id: 'access-denied',
    title,
    component: <PermissionNotGrantedMessage />,
    renderFooterButtons: ({ cancel, submit }) => [
      <ModalFooterButton {...cancel} key="cancel">
        cancel
      </ModalFooterButton>,

      <ModalFooterButton
        {...submit}
        key="submit"
        theme="submit"
        disabled={authenticating}
        onClick={withAuthentication(onReviseAccess)}
      >
        revise options
      </ModalFooterButton>,
    ],
  };
}
