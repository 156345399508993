import * as React from 'react';
import _ from 'underscore';

import DropdownMenuContainer from '../DropdownMenuContainer';
import DropdownMenuHeader from '../DropdownMenuHeader';
import DropdownMenuItem from '../DropdownMenuItem';
import DropdownSection from '../DropdownSection';
import { blockNavigation } from '../utils';
import UserIcon from './UserIcon';

export interface IProps {
  className?: string;
  onLabsClick?: (e: React.MouseEvent<any>) => void;
  onLogoutClick?: (e: React.MouseEvent<any>) => void;
  onSettingsClick?: (e: React.MouseEvent<any>) => void;
  reducedNavbar: boolean;
  username: string;
  shouldLogoutRedirect: boolean;
}

const UserDropdown: React.FC<IProps> = ({
  onLogoutClick,
  onSettingsClick,
  reducedNavbar,
  username,
}) => {
  const logoutMenuItem = (
    <DropdownSection>
      <DropdownMenuItem
        label="Log out"
        iconName="sign-out-alt"
        onClick={onLogoutClick}
      />
    </DropdownSection>
  );

  return (
    <DropdownMenuContainer
      header={
        !reducedNavbar && (
          <DropdownMenuHeader iconName="user-circle" title={username} />
        )
      }
      icon={<UserIcon bsRole="toggle" />}
      id="user-nav-dropdown"
      className={blockNavigation('dropdown', { user: true })}
    >
      {reducedNavbar ? (
        logoutMenuItem
      ) : (
        <>
          <DropdownSection
            className={blockNavigation('settings-section')}
            hideOnMobile
          >
            <DropdownMenuItem
              className={blockNavigation('account-settings')}
              label="Account Settings"
              iconName="cogs"
              onClick={onSettingsClick}
            />
          </DropdownSection>
          {logoutMenuItem}
        </>
      )}
    </DropdownMenuContainer>
  );
};

UserDropdown.defaultProps = {
  onLogoutClick: _.noop,
  onSettingsClick: _.noop,
  reducedNavbar: false,
};

export default UserDropdown;
