import * as React from 'react';

import { ModalBody, ModalFooter, ModalFooterButton } from 'components/Modal';
import { ConnectedModalInjectedProps } from 'containers/ConnectedModal/ConnectedModalComponent';
import TrackSelector from './TrackSelector';
import { AddableTrackType } from './types';
import { block } from './utils';

const { useCallback, useState } = React;

export interface AddTrackModalContentsProps {
  onSubmit?: (trackType: AddableTrackType) => void;
}

const AddTrackModalContents: React.FC<AddTrackModalContentsProps &
  ConnectedModalInjectedProps> = ({ onHide, onSubmit }) => {
  const [trackType, setTrackType] = useState<AddableTrackType>('text');

  const handleSubmit = useCallback(() => {
    onSubmit(trackType);
    onHide();
  }, [onHide, onSubmit, trackType]);

  return (
    <>
      <ModalBody className={block('body')}>
        <TrackSelector
          trackType={trackType}
          onChange={setTrackType}
          onClick={handleSubmit}
        />
      </ModalBody>
      <ModalFooter>
        <ModalFooterButton onClick={onHide}>Cancel</ModalFooterButton>
        <ModalFooterButton onClick={handleSubmit} theme="submit">
          Add
        </ModalFooterButton>
      </ModalFooter>
    </>
  );
};

export default AddTrackModalContents;
