import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { H1 } from 'components/Heading';
import { SaveAsTemplateCentered } from 'components/icons';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import Modal from 'components/Modal';
import ShareFormTextarea from 'components/ShareForm/ShareFormTextarea';
import { ClickSaveTemplateFrom } from 'redux/modules/mixpanel/types';
import { hideModal } from 'redux/modules/modal';
import { block } from './utils';

const TEMPLATE_NAME_MAX_CHARS = 70;

interface SaveAsTemplateModalContentsProps {
  onSave: (
    displayName: string,
    from: ClickSaveTemplateFrom,
    useMockAudioForPreview: boolean,
  ) => void;
  disabled?: boolean;
  from?: ClickSaveTemplateFrom;
  useMockAudioForPreview?: boolean;
}

const SaveAsTemplateModalContents: React.FC<SaveAsTemplateModalContentsProps> = props => {
  const { onSave, disabled, from, useMockAudioForPreview } = props;

  const [displayName, setDisplayName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();

  const handleSave = useCallback(() => {
    setIsSaving(true);
    onSave(displayName, from, useMockAudioForPreview);
    dispatch(hideModal(true));
  }, [onSave, displayName, from, useMockAudioForPreview, dispatch]);

  return (
    <>
      <Modal.Body className={block('body')}>
        <CircularGradient className={block('template-icon-container')}>
          <SaveAsTemplateCentered
            width="38%"
            height="unset"
            style={{ position: 'relative' }}
          />
        </CircularGradient>

        <H1 className={block('title')}>Let's name your template</H1>

        <ShareFormTextarea
          onChange={setDisplayName}
          className={block('template-name-input')}
          placeholder="Template name"
          type="input"
          maxChars={TEMPLATE_NAME_MAX_CHARS}
          value={displayName}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton
            onClick={handleSave}
            theme="submit"
            disabled={!displayName || isSaving || disabled}
          >
            Save template
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default SaveAsTemplateModalContents;
export { SaveAsTemplateModalContentsProps };
