import {
  analyzeRecordingVersion,
  analyzeTextBlob,
} from 'redux/middleware/api/keyword-extractor-service/actions';
import {
  AnalyzeRecordingVersionResult,
  AnalyzeTextBlobResult,
} from 'redux/middleware/api/keyword-extractor-service/types';
import { getVideo } from 'redux/middleware/api/media-upload-service/actions';
import { IApiResponse } from 'redux/middleware/api/types';
import { ThunkAction } from 'redux/types';
import { AspectRatioDimensions } from 'types';
import { getValue } from 'utils/collections';
import { isVideo } from 'utils/embed/video';
import reduxPoll from 'utils/redux-poll';
import { KEYWORD_POLL_ID } from '../constants';

/**
 * process recommendation, filling in video assets with additional information from
 * media-upload-service
 */
const enrichRecommendation = ({
  response,
}: IApiResponse<
  AnalyzeRecordingVersionResult | AnalyzeTextBlobResult
>): ThunkAction<Promise<any>> => dispatch => {
  const { typedRecommendation, ...restResponse } = response;

  if (!typedRecommendation) return Promise.resolve({ response });

  const futRichRecommendation = typedRecommendation.reduce(
    (acc, recommendedAsset) => {
      if (!isVideo(recommendedAsset)) {
        acc.push(Promise.resolve(recommendedAsset));
      } else {
        const videoId = `${recommendedAsset.assetDetail.videoId}`;
        const richVideo = dispatch(getVideo(videoId)).then(videoRes => ({
          ...recommendedAsset,
          ...getValue(videoRes, ['response', 'entities', 'videos', videoId]),
        }));
        acc.push(richVideo);
      }
      return acc;
    },
    [],
  );
  return Promise.all(futRichRecommendation).then(richRecommendation => ({
    response: {
      ...restResponse,
      typedRecommendation: richRecommendation,
    },
  }));
};

export const getKeywordsForRecordingVersion = (
  versionId: number,
  includeSlideshow: boolean = false,
  aspectRatio?: AspectRatioDimensions,
  pollId: string = KEYWORD_POLL_ID,
): ThunkAction<Promise<any>> => dispatch =>
  reduxPoll(
    dispatch,
    () =>
      dispatch(
        analyzeRecordingVersion(versionId, includeSlideshow, aspectRatio),
      ),
    {
      id: pollId,
      intervalMillis: spareminConfig.keywordPollIntervalMillis,
      maxAttempts: spareminConfig.keywordPollMaxAttempts,
      shouldContinue: (err, res) => !err && !res.response.isResolved,
    },
  ).then(res => dispatch(enrichRecommendation(res)));

export const analyzeText = (
  textBlobId: string,
  aspectRatio: AspectRatioDimensions,
  includeRecommendation: boolean = false,
  pollId: string = KEYWORD_POLL_ID,
): ThunkAction<Promise<any>> => dispatch =>
  reduxPoll(
    dispatch,
    () =>
      dispatch(analyzeTextBlob(textBlobId, includeRecommendation, aspectRatio)),
    {
      id: pollId,
      intervalMillis: spareminConfig.keywordPollIntervalMillis,
      maxAttempts: spareminConfig.keywordPollMaxAttempts,
      shouldContinue: (err, res) =>
        err ? false : !getValue(res, ['response', 'isResolved']),
    },
  ).then(res => dispatch(enrichRecommendation(res)));
