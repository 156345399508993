import { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { TikTokUserAllowance } from 'containers/TikTokAutoPostModal/types';
import { TikTokPrivacyLevelOptionName } from 'redux/middleware/api/third-party-authentication-service/types';
import {
  tikTokCreatorInfoSelector,
  tiktokUserDataSelector,
} from 'redux/modules/social';
import useTikTokPostModalSelector from '../../useTikTokPostModalSelector';
import {
  UseAutopostingComposerStep,
  UseAutopostingComposerStepConfig,
} from './types';
import useCaptionsStep from './useCaptionsStep';
import useDisclosureStep from './useDisclosureStep';
import usePostComposerStep from './usePostComposerStep';

const useAutopostingComposerStep = (
  config: UseAutopostingComposerStepConfig,
): UseAutopostingComposerStep => {
  const {
    activeStep,
    initialValues,
    onSwitchAccountClick,
    onCaptionsClick,
    onInitialStepClick,
    onAdditionalDetailsClick,
    onHideModal,
    onSubmit,
  } = config;

  const isActive = ['composer', 'captions', 'disclosure'].includes(activeStep);

  const {
    username,
    avatarSrc,
    defaultCaptions,
    defaultPrivacyLevel,
    defaultUserAllowance,
    defaultBrandContent,
    defaultBrandOrganic,
  } = useTikTokPostModalSelector({
    defaultCaptions: initialValues?.captions,
    defaultPrivacyLevel: initialValues?.privacyLevel,
    defaultUserAllowance: initialValues?.userAllowance,
    defaultBrandContent: initialValues?.brandContent,
    defaultBrandOrganic: initialValues?.brandOrganic,
  });

  const [prevActiveState, setPrevActiveState] = useState<boolean>(isActive);
  const [prevInitialValues, setPrevInitialValues] = useState(initialValues);
  const [captions, setCaptions] = useState<string>(defaultCaptions);
  const [privacyLevel, setPrivacyLevel] = useState<
    TikTokPrivacyLevelOptionName
  >(defaultPrivacyLevel);
  const [userAllowance, setUserAllowance] = useState<TikTokUserAllowance[]>(
    defaultUserAllowance,
  );
  const [brandContent, setBrandContent] = useState<boolean>(
    defaultBrandContent,
  );
  const [brandOrganic, setBrandOrganic] = useState<boolean>(
    defaultBrandOrganic,
  );
  const [isDisclosureVisible, setIsDisclosureVisible] = useState(
    defaultBrandContent || defaultBrandOrganic,
  );
  const { providerUserId: tiktokUserId } = useSelector(tiktokUserDataSelector);
  const creatorInfo = useSelector(tikTokCreatorInfoSelector);

  const handleSetDefaultProps = useCallback(() => {
    setCaptions(defaultCaptions);
    setPrivacyLevel(defaultPrivacyLevel);
    setUserAllowance(defaultUserAllowance);
    setBrandContent(defaultBrandContent);
    setBrandOrganic(defaultBrandOrganic);
    setIsDisclosureVisible(defaultBrandContent || defaultBrandOrganic);
  }, [
    defaultBrandContent,
    defaultBrandOrganic,
    defaultCaptions,
    defaultPrivacyLevel,
    defaultUserAllowance,
  ]);

  const handleBrandContentChange = useCallback(
    (isBrandedContent: boolean): void => {
      setBrandContent(isBrandedContent);

      if (!isBrandedContent || privacyLevel !== 'selfOnly') {
        return;
      }

      setPrivacyLevel(
        creatorInfo?.isAccountPublic ? 'publicToEveryone' : 'followerOfCreator',
      );
    },
    [creatorInfo, privacyLevel],
  );

  const handleSubmit = useCallback((): void => {
    onSubmit?.({
      tiktokUserId,
      tiktokPrivacyLevel: privacyLevel,
      commentEnabled: userAllowance.includes('comment'),
      duetEnabled: userAllowance.includes('duet'),
      stitchEnabled: userAllowance.includes('stitch'),
      title: captions,
      brandContentToggle: brandContent,
      brandOrganicToggle: brandOrganic,
    });

    handleSetDefaultProps();
  }, [
    brandContent,
    brandOrganic,
    captions,
    handleSetDefaultProps,
    onSubmit,
    privacyLevel,
    tiktokUserId,
    userAllowance,
  ]);

  const postComposer = usePostComposerStep({
    username,
    avatarSrc,
    captions,
    privacyLevel,
    userAllowance,
    brandedContentSelected: brandContent,
    allowSubmit: Boolean(privacyLevel),
    onSwitchAccountClick,
    onCaptionsClick,
    onPrivacyLevelChange: setPrivacyLevel,
    onUserAllowanceChange: setUserAllowance,
    onAdditionalDetailsClick,
    onHideModal,
    onSubmit: handleSubmit,
  });

  const captionsStep = useCaptionsStep({
    captions,
    isActive: activeStep === 'captions',
    readonly: false,
    onBackClick: onInitialStepClick,
    onCaptionsChange: setCaptions,
    onHideModal,
  });

  const disclosureStep = useDisclosureStep({
    isDisclosureVisible,
    brandContent,
    brandOrganic,
    onBrandContentChange: handleBrandContentChange,
    onBrandOrganicChange: setBrandOrganic,
    onBackClick: onInitialStepClick,
    onHideModal,
    onVisibilityChange: setIsDisclosureVisible,
  });

  if (prevActiveState !== isActive) {
    handleSetDefaultProps();
    setPrevActiveState(isActive);
  }

  if (!isEqual(prevInitialValues, initialValues)) {
    handleSetDefaultProps();
    setPrevInitialValues(initialValues);
  }

  return {
    composerSteps: [postComposer, captionsStep, disclosureStep],
  };
};

export default useAutopostingComposerStep;
