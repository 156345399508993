import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Tier } from 'redux/middleware/api/plan-service';
import { discountSelector, subscriptionSelector } from 'redux/modules/pricing';
import { State } from 'redux/types';
import { PropsOf } from 'types';
import { Discount } from '../../../../constants';
import { SubscriptionPeriod } from '../../../../types';
import { planIsCancelled } from '../../utils';
import ProPlanCta, { ProPlanCtaProps as Props } from './ProPlanCta';

type StateProps = Pick<Props, 'children'>;

interface OwnProps {
  subscriptionPeriod: SubscriptionPeriod;
}
const subscriptionPeriodSelector = (_, props: OwnProps) =>
  props.subscriptionPeriod;

const ctaLabelSelector = createSelector(
  [discountSelector, subscriptionPeriodSelector, subscriptionSelector],
  (discount, subscriptionPeriod, subscription) => {
    if (discount === Discount.BOGO) {
      return subscriptionPeriod === 'monthly'
        ? 'get a free month'
        : 'get a free year';
    }
    if (
      subscription.subtier === 'proTrial' ||
      planIsCancelled(Tier.PRO, subscription)
    )
      return 'keep this plan';
    return 'get started';
  },
);

const mapStateToProps = (state: State, props: OwnProps): StateProps => ({
  children: ctaLabelSelector(state, props),
});

const component = connect(mapStateToProps)(ProPlanCta);
export type ProPlanCtaProps = PropsOf<typeof component>;
export default component;
