import cn from 'classnames';
import * as React from 'react';

import { block } from './utils';

interface IProps {
  icon?: JSX.Element;
  /**
   * react-font-awesome icon name
   */
  label: string;
}

const NavLabel: React.SFC<IProps> = ({ icon, label }) => {
  return (
    <div className={block('label')}>
      {React.cloneElement(icon, {
        className: cn(block('label-icon'), icon.props.className),
      })}
      <span className={block('label-text')}>{label}</span>
    </div>
  );
};

export default NavLabel;
