import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import { episodeTranscriptionModalBlock as block } from '../utils';
import EpisodeTranscriptionUpgradeModalContents from './EpisodeTranscriptionUpgradeModalContents';

export interface EpisodeTranscriptionUpgradeModalProps {}

const EpisodeTranscriptionUpgradeModal: React.FC<EpisodeTranscriptionUpgradeModalProps> = () => {
  return (
    <ConnectedModal
      name="EpisodeTranscriptionUpgrade"
      title="transcript"
      className={block()}
    >
      <EpisodeTranscriptionUpgradeModalContents />
    </ConnectedModal>
  );
};

export default EpisodeTranscriptionUpgradeModal;
