import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import AudiogramDestinationModalContents from './AudiogramDestinationModalContents';
import { block } from './utils';

export interface AudiogramDestinationModalProps {}

const AudiogramDestinationModal: React.FC<AudiogramDestinationModalProps> = () => (
  <ConnectedModal className={block()} name="AudiogramDestination" v2>
    <AudiogramDestinationModalContents />
  </ConnectedModal>
);

export default AudiogramDestinationModal;
