import React from 'react';

import { IconProps } from './types';

const X: React.FunctionComponent<IconProps> = ({
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="12 7 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <filter
        x="-20.5%"
        y="-15.5%"
        width="141%"
        height="141%"
        filterUnits="objectBoundingBox"
        id="obd8w8xh2a"
      >
        <feOffset dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="6"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      filter="url(#obd8w8xh2a)"
      transform="translate(12 7)"
      fill="none"
      fillRule="evenodd"
    >
      <circle fill="#000" cx="50" cy="50" r="50" />
      <path
        d="M60.002 34.783h5.334L53.683 47.674l13.708 17.543H56.658l-8.407-10.64-9.619 10.64h-5.336l12.463-13.789-13.15-16.645h11.005l7.6 9.725 8.788-9.725zM58.13 62.127h2.956L42.009 37.711h-3.172L58.13 62.127z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default X;
