import * as React from 'react';

interface IProps {
  src?: string;
}

const Video: React.FC<IProps> = props => {
  const { src } = props;
  return <video key={src} muted loop autoPlay preload="auto" src={src} />;
};

export { IProps as VideoProps };
export default Video;
