// list of all search engines in the order they should appear in the ui
export const IMAGE_SEARCH_ENGINES: Readonly<string[]> = [
  'ap',
  'getty',
  'gettyCreative',
  'google',
  'microsoft',
  'shutterstock',
  'thinkstock',
  'pixabay',
  'usaToday',
  'tonl',
  'textToImage',
];

export const PRO_IMAGE_SEARCH_ENGINES: Readonly<string[]> = ['getty', 'tonl'];

export const IMAGE_SEARCH_ENGINES_BLACKLIST: Readonly<string[]> = [
  'shutterstock',
  'tonl',
];

export const ENABLED_IMAGE_SEARCH_ENGINES: Readonly<string[]> = IMAGE_SEARCH_ENGINES.filter(
  engine => IMAGE_SEARCH_ENGINES_BLACKLIST.indexOf(engine) < 0,
);

export const DEFAULT_IMAGE_SEARCH_ENGINES: Readonly<string[]> = ENABLED_IMAGE_SEARCH_ENGINES.filter(
  engine =>
    engine !== 'usaToday' &&
    engine !== 'ap' &&
    engine !== 'getty' &&
    engine !== 'gettyCreative',
);
