import React from 'react';
import { IconProps } from './types';

const Hourglass: React.FC<IconProps> = props => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M13.125 0c.485 0 .875.378.875.844 0 .466-.39.844-.875.844h-.292v.667c0 1.417-.616 2.746-1.622 3.78L8.236 9l2.975 2.865c1.006 1.002 1.622 2.363 1.622 3.78v.668h.292c.485 0 .875.376.875.843 0 .468-.39.844-.875.844H.875C.392 18 0 17.624 0 17.156c0-.467.392-.843.875-.843h.292v-.668a5.25 5.25 0 0 1 1.623-3.78L5.764 9 2.79 6.135C1.75 5.1 1.167 3.772 1.167 2.355v-.667H.875C.392 1.688 0 1.31 0 .844 0 .378.392 0 .875 0h12.25zM7 10.206l-2.971 2.869a3.586 3.586 0 0 0-1.112 2.584v.7h8.166v-.7c0-.97-.4-1.899-1.112-2.584L7 10.205zm4.083-8.55H2.917v.699c0 .97.4 1.899 1.112 2.584L7 7.81l2.971-2.87a3.592 3.592 0 0 0 1.112-2.584v-.7z"
        id="u36f44su5a"
      />
    </defs>
    <g transform="matrix(1 0 0 -1 0 18)" fill="none" fillRule="evenodd">
      <mask id="jsru2unemb" fill="#fff">
        <use xlinkHref="#u36f44su5a" />
      </mask>
      <use fill="#19B5FE" fillRule="nonzero" xlinkHref="#u36f44su5a" />
      <g mask="url(#jsru2unemb)" fill="currentColor">
        <path d="M-7 0h28v18H-7z" />
      </g>
    </g>
  </svg>
);

export default Hourglass;
