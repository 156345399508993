import cn from 'classnames';
import * as React from 'react';

import FontAwesome from 'components/FontAwesome';

interface IProps extends React.HTMLProps<HTMLSelectElement> {
  children: React.ReactNode;
  caretClassName?: string;
  className?: string;
  disabled?: boolean;
  inputClassName?: string;
}

const WrappedSelect: React.SFC<IProps> = ({
  children,
  caretClassName,
  className,
  inputClassName,
  ...restProps
}) => (
  <div className={className}>
    <select className={cn('wrapped-select', inputClassName)} {...restProps}>
      {children}
    </select>
    <FontAwesome
      className={cn('wrapped-select__caret', caretClassName)}
      icon="caret-down"
    />
  </div>
);

WrappedSelect.defaultProps = {
  disabled: false,
};

export default WrappedSelect;
export { IProps as WrappedSelectProps };
