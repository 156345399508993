import React, { FunctionComponent, useCallback } from 'react';
import { ActionStepCardEditButton } from 'components/ActionStepCard';
import Button from 'components/Button';
import { Popper, PopperTrigger } from 'components/Popper';
import useToggle from 'hooks/useToggle';
import { SubscriptionItem } from 'redux/middleware/api/podcast-service';
import { OutputOption } from 'redux/modules/edit-automation';
import useEditOutput from './useEditOutput';
import { block } from './utils';

interface AutomationActionProps {
  output: OutputOption;
  subscriptionItem: SubscriptionItem;
  onClickChangeDestination: () => void;
}

const AutomationAction: FunctionComponent<AutomationActionProps> = props => {
  const { output, subscriptionItem, onClickChangeDestination } = props;

  const [menuOpen, toggleMenu] = useToggle(false);

  const showChangeDetailsButton =
    subscriptionItem.autoPostVideoPreference.options.postType !==
    'facebookStory';

  const { onConfirmOutputSelection } = useEditOutput(
    subscriptionItem.autoCreateSubId,
  );

  const handleAutoPostSubmit = useCallback((): void => {
    onConfirmOutputSelection(output, false);
  }, [onConfirmOutputSelection, output]);

  return (
    <div style={{ position: 'relative' }}>
      <PopperTrigger open={menuOpen} onClose={() => toggleMenu(false)}>
        <Popper className={block('menu', { single: !showChangeDetailsButton })}>
          <Button
            size="small"
            theme="ghost"
            onClick={onClickChangeDestination}
            className={block('menu-button')}
            fluid
          >
            <span className={block('menu-button-text')}>
              Change destination
            </span>
          </Button>
          {showChangeDetailsButton && (
            <Button
              size="small"
              theme="ghost"
              onClick={handleAutoPostSubmit}
              className={block('menu-button')}
              fluid
            >
              <span className={block('menu-button-text')}>
                Change auto-posting details
              </span>
            </Button>
          )}
        </Popper>
      </PopperTrigger>
      <ActionStepCardEditButton onClickEdit={() => toggleMenu(true)} />
    </div>
  );
};

export default AutomationAction;
