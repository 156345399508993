import * as React from 'react';

import UpsellCompletedStep from 'blocks/OnboardingWizard/components/UpsellCompletedStep';
import UpsellErrorStep from 'blocks/OnboardingWizard/components/UpsellErrorStep';
import { HeadyOutline } from 'components/icons';
import { Tier } from 'redux/middleware/api/plan-service';
import { RequestStatus } from 'types';
import CardWizardStep, {
  CardWizardControlButton,
  CardWizardControls,
} from '../../components/CardWizard/CardWizardStep';
import { welcomeBlock as block } from '../../utils';
import { WelcomeStepProps } from './types';
import usePaidWelcomeStep from './usePaidWelcomeStep';

export type PaidWelcomeStepProps = WelcomeStepProps;

const PaidWelcomeStep: React.FC<PaidWelcomeStepProps> = ({
  onNextClick,
  title,
}) => {
  const {
    isUpsellCompleted,
    openCheckoutModal,
    purchasedTier,
    status,
  } = usePaidWelcomeStep();

  if (status === RequestStatus.FAILURE) {
    return <UpsellErrorStep onSubmit={onNextClick} />;
  }

  if (isUpsellCompleted && purchasedTier) {
    return <UpsellCompletedStep onSubmit={() => onNextClick(purchasedTier)} />;
  }

  return (
    <CardWizardStep className={block()}>
      <div className={block('icon', { sm: true })}>
        <HeadyOutline />
      </div>
      <div className={block('message')}>
        <CardWizardStep.H1>{title}</CardWizardStep.H1>
        <CardWizardStep.H2>
          Finish upgrading your plan or enjoy our free tier.
        </CardWizardStep.H2>
      </div>
      <CardWizardControls>
        <CardWizardControlButton
          theme="rainbow"
          disabled={status === RequestStatus.REQUEST}
          onClick={openCheckoutModal}
        >
          upgrade your plan
        </CardWizardControlButton>
        <CardWizardControlButton
          theme="cancel"
          onClick={() => onNextClick(Tier.FREE)}
          disabled={status === RequestStatus.REQUEST}
        >
          Use forever free
        </CardWizardControlButton>
      </CardWizardControls>
    </CardWizardStep>
  );
};

export default PaidWelcomeStep;
