import { LocationDescriptorObject } from 'history';
import queryString from 'query-string';
import React from 'react';
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom';
import { isObject, isString } from 'underscore';

import { Omit } from 'types';

type Query = { [key: string]: any };

interface LocationDescriptorWithQuery
  extends Omit<LocationDescriptorObject, 'search'> {
  query?: Query;
}

function isLocationWithQuery(loc: any): loc is LocationDescriptorWithQuery {
  return isObject(loc?.query);
}

function toLocationWithQuery(
  loc: LocationDescriptorObject | LocationDescriptorWithQuery | string,
): LocationDescriptorWithQuery {
  if (isString(loc)) {
    return { pathname: loc };
  }

  if (isLocationWithQuery(loc)) {
    return loc;
  }

  const { search, ...restLoc } = loc;
  return {
    ...restLoc,
    query: queryString.parse(loc.search),
  };
}

function toLocation(
  loc: LocationDescriptorWithQuery,
): LocationDescriptorObject {
  const { query, ...restLoc } = loc;
  return {
    ...restLoc,
    search: queryString.stringify(query),
  };
}

export interface LinkProps extends Omit<ReactRouterLinkProps, 'to'> {
  forwardSearch?: boolean;
  to: LocationDescriptorObject | LocationDescriptorWithQuery | string;
}

const Link: React.FC<LinkProps> = ({ forwardSearch, to, ...props }) => {
  const baseLocation = toLocationWithQuery(to);
  const targetLocation = !forwardSearch
    ? baseLocation
    : {
        ...baseLocation,
        query: {
          ...baseLocation.query,
          ...queryString.parse(window.location.search),
        },
      };

  return <ReactRouterLink to={toLocation(targetLocation)} {...props} />;
};

export default Link;
