import cn from 'classnames';
import React from 'react';
import ListItemArtwork from './ListItemArtwork';
import ListItemContents from './ListItemContents';
import ListItemText from './ListItemText';
import { block } from './utils';

export interface ListItemProps
  extends Pick<React.HTMLProps<HTMLLIElement>, 'onClick' | 'style'> {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  modifiers?: 'no-padding'[];
}

interface ListItemComponent extends React.FC<ListItemProps> {
  Artwork: typeof ListItemArtwork;
  Contents: typeof ListItemContents;
  Text: typeof ListItemText;
}

const ListItem: ListItemComponent = ({
  children,
  disabled,
  className,
  modifiers = [],
  onClick,
  style,
}) => (
  <li
    className={cn(
      block('item', {
        disabled,
        'no-padding': modifiers.includes('no-padding'),
      }),
      className,
    )}
    {...{ onClick, style }}
  >
    {children}
  </li>
);

ListItem.Artwork = ListItemArtwork;
ListItem.Contents = ListItemContents;
ListItem.Text = ListItemText;

export default ListItem;
