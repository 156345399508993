import { List } from 'immutable';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  copyPhrasesToTimeline,
  deletePhrase,
  updateCaptionEndTime,
  updateCaptionsPhrase,
  updateCaptionStartTime,
} from 'redux/modules/embed/actions/captions';
import {
  captionsDeletedPhraseIdsSelector,
  captionsPhraseStatusSelector,
  phrasesSelector as embedPhrasesSelector,
  transcriptKeywordsSelector,
  transcriptOffsetMillisSelector,
} from 'redux/modules/embed/selectors';
import { onCopyCaption, onDeleteCaption } from 'redux/modules/mixpanel/actions';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import { Keyword } from '../components/EditableKeywordPhrase';
import Phrase, { PhraseProps } from '../components/Phrase';

type StateProps = Pick<
  PhraseProps,
  | 'disabledControls'
  | 'endBoundsMillis'
  | 'endMillis'
  | 'keywords'
  | 'params'
  | 'phrase'
  | 'isSaving'
  | 'startBoundsMillis'
  | 'startMillis'
>;

type DispatchProps = Pick<
  PhraseProps,
  | 'onAdd'
  | 'onCopyToTimeline'
  | 'onMount'
  | 'onPlay'
  | 'onRemove'
  | 'onEndTimeSubmit'
  | 'onKeywordClick'
  | 'onPhraseSubmit'
  | 'onStartTimeSubmit'
  | 'onTogglePlay'
  | 'onUnmount'
>;
interface OwnProps {
  onAdd: (phraseId: string) => void;
  onCopyToTimeline: (startMillis: number, phraseId: string) => void;
  onKeywordClick: (keyword: Keyword, phraseId: string) => void;
  onMount: (element: HTMLDivElement, phraseId: string) => void;
  onPlay: (startMillis: number, endMillis: number) => void;
  onTogglePlay: (startMillis: number, endMillis: number) => void;
  onUnmount: (phraseId: string) => void;
  phraseId: string;
}

const phrasesSelector = createSelector(
  embedPhrasesSelector,
  phrases => phrases || List(),
);

const phraseIdSelector = (_, props: OwnProps) => props.phraseId;

const isDeletingPhraseSelector = createSelector(
  captionsDeletedPhraseIdsSelector,
  deletedIds => !deletedIds.isEmpty(),
);

const makeMapStateToProps = () => {
  const phraseIndexSelector = createSelector(
    [phrasesSelector, phraseIdSelector],
    (phrases, phraseId) =>
      !phrases || !phraseId
        ? -1
        : phrases.findIndex(p => p.get('id') === phraseId),
  );

  const phraseSelector = createSelector(
    [phrasesSelector, phraseIndexSelector],
    (phrases, index) => (index < 0 ? undefined : phrases.get(index)),
  );

  const phraseTimeSelector = createSelector(
    [phraseSelector, transcriptOffsetMillisSelector],
    (phrase, offsetMillis) =>
      !phrase
        ? undefined
        : {
            endMillis: phrase.get('endMillis') + offsetMillis,
            startMillis: phrase.get('startMillis') + offsetMillis,
          },
  );

  const startMillisSelector = createSelector(
    phraseTimeSelector,
    time => time && time.startMillis,
  );

  const endMillisSelector = createSelector(
    phraseTimeSelector,
    time => time && time.endMillis,
  );

  const keywordsSelector = createSelector(
    [
      transcriptKeywordsSelector,
      phraseTimeSelector,
      transcriptOffsetMillisSelector,
    ],
    (keywords, phraseTime, offsetMillis) => {
      if (!keywords || !phraseTime) return undefined;
      return keywords.reduce((acc, detail) => {
        const keyword = detail.get('keyword');
        const { endMillis: phraseEnd, startMillis: phraseStart } = phraseTime;
        const appearances = detail
          .get('estAppearancesTranscript')
          .reduce((acc2, occursAt) => {
            if (occursAt >= phraseStart && occursAt <= phraseEnd) {
              acc2.push({
                id: occursAt + offsetMillis,
                value: keyword,
              });
            }
            return acc2;
          }, []);
        return acc.concat(appearances);
      }, []);
    },
  );

  const phraseTextSelector = createSelector(
    phraseSelector,
    phrase => phrase && phrase.get('text'),
  );

  const isSavingSelector = createSelector(
    [phraseIdSelector, captionsPhraseStatusSelector],
    (phraseId, phraseStatus) => {
      if (!phraseId || !phraseStatus) return false;
      const status = phraseStatus.get(phraseId);
      return status === 'saving';
    },
  );

  const isLastPhraseSelector = createSelector(
    [phrasesSelector, phraseIndexSelector],
    (phrases, index) => index === phrases.size - 1,
  );

  const disabledControlsSelector = createSelector(
    [
      isDeletingPhraseSelector,
      phrasesSelector,
      phraseSelector,
      isLastPhraseSelector,
    ],
    (isDeleting, phrases) => {
      if (isDeleting) {
        return ['add', 'remove'] as StateProps['disabledControls'];
      }

      const nPhrases = phrases.size;
      const disabledControls = new Set<string>();

      if (nPhrases === 1) {
        disabledControls.add('remove');
      }

      return Array.from(disabledControls) as StateProps['disabledControls'];
    },
  );

  const minMillisSelector = createSelector(
    [transcriptOffsetMillisSelector],
    offsetMillis => 0 + offsetMillis,
  );

  const endBoundsMillisSelector = createSelector(
    [minMillisSelector],
    minMillis => ({
      lower: minMillis,
      upper: Infinity,
    }),
  );

  const startBoundsMillisSelector = createSelector(
    [minMillisSelector],
    minMillis => ({
      lower: minMillis,
      upper: Infinity,
    }),
  );

  return (state: State, props: OwnProps): StateProps => ({
    disabledControls: disabledControlsSelector(state, props),
    endBoundsMillis: endBoundsMillisSelector(state),
    endMillis: endMillisSelector(state, props),
    isSaving: isSavingSelector(state, props),
    keywords: keywordsSelector(state, props),
    params: phraseTimeSelector(state, props),
    phrase: phraseTextSelector(state, props),
    startBoundsMillis: startBoundsMillisSelector(state),
    startMillis: startMillisSelector(state, props),
  });
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  {
    onAdd,
    onCopyToTimeline,
    onMount,
    onKeywordClick,
    onPlay,
    onTogglePlay,
    onUnmount,
    phraseId,
  }: OwnProps,
): DispatchProps => ({
  onAdd: () => onAdd(phraseId),
  onCopyToTimeline: ({ startMillis }) => {
    dispatch(copyPhrasesToTimeline(phraseId));
    dispatch(onCopyCaption(phraseId));
    onCopyToTimeline(startMillis, phraseId);
  },
  onEndTimeSubmit: endMillis =>
    dispatch(updateCaptionEndTime(phraseId, endMillis)),
  onKeywordClick: keyword => onKeywordClick(keyword, phraseId),
  onMount: element => onMount(element, phraseId),
  onPhraseSubmit: text => dispatch(updateCaptionsPhrase(phraseId, text)),
  onPlay: ({ endMillis, startMillis }) => onPlay(startMillis, endMillis),
  onRemove: () => {
    dispatch(onDeleteCaption());
    dispatch(deletePhrase(phraseId));
  },
  onStartTimeSubmit: startMillis =>
    dispatch(updateCaptionStartTime(phraseId, startMillis)),
  onTogglePlay: ({ startMillis, endMillis }) =>
    onTogglePlay(startMillis, endMillis),
  onUnmount: () => onUnmount(phraseId),
});

const component = connect(makeMapStateToProps, mapDispatchToProps)(Phrase);
export type TranscriptPhraseProps = PropsOf<typeof component>;
export default component;
