import { PostStatus } from '../constants';
import { Type } from './action-types';
import { FacebookAction, FacebookState, facebookStateFactory } from './types';

const defaultState = facebookStateFactory();

export default function(
  state: FacebookState = defaultState,
  action: FacebookAction,
) {
  switch (action.type) {
    case Type.FACEBOOK_AUTHORIZE_SUCCESS: {
      return state.merge(action.payload);
    }

    case Type.FACEBOOK_USER_DATA_GET_SUCCESS: {
      const { profileImageUrl, username } = action.payload;

      return state
        .set('username', username)
        .set('profileImageUrl', profileImageUrl);
    }

    case Type.FACEBOOK_PAGES_GET_REQUEST: {
      return state.set('pagesLoading', true);
    }

    case Type.FACEBOOK_PAGES_GET_SUCCESS: {
      return state.set('pages', action.payload).set('pagesLoading', false);
    }

    case Type.FACEBOOK_PAGES_GET_FAILURE: {
      return state.set('pagesLoading', false);
    }

    case Type.FACEBOOK_PAGES_CLEAR: {
      return facebookStateFactory();
    }

    case Type.FACEBOOK_USER_ID_SELECT: {
      return state.set('facebookUserId', action.payload);
    }

    case Type.FACEBOOK_VIDEO_POST_REQUEST:
      return state.set('postStatus', PostStatus.IN_PROGRESS);

    case Type.FACEBOOK_VIDEO_POST_SUCCESS: {
      const { socialShareId } = action.payload;

      return state.set('socialShareId', socialShareId);
    }

    case Type.FACEBOOK_VIDEO_POST_AWAIT_SUCCESS:
      return state.set('postStatus', PostStatus.SUCCESS);

    case Type.FACEBOOK_VIDEO_POST_FAILURE:
    case Type.FACEBOOK_VIDEO_POST_AWAIT_FAILURE:
      return state.set('postStatus', PostStatus.FAILURE);

    default:
      return state;
  }
}
