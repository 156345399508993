import * as React from 'react';
import { noop } from 'underscore';

import TextBoxDurationField from './TextBoxDurationField';

interface Time {
  startMillis: number;
  endMillis: number;
}

interface IProps {
  disabled?: boolean;
  endMillis?: number;
  minDurationMillis?: number;
  onChange?: (args: Time) => void;
  startMillis?: number;
}

export default class TimeOptions extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    disabled: false,
    minDurationMillis: 0,
    onChange: noop,
  };

  private handleStartTimeChange = (startMillis: number) =>
    this.fireOnChange({ startMillis });

  private handleEndTimeChange = (endMillis: number) =>
    this.fireOnChange({ endMillis });

  private handleStartTimeBlur = (startMillis: number) => {
    const { endMillis, minDurationMillis } = this.props;
    const end = Math.max(startMillis + minDurationMillis, endMillis);
    this.fireOnChange({ startMillis, endMillis: end });
  };

  private handleEndTimeBlur = (endMillis: number) => {
    const { minDurationMillis, startMillis } = this.props;
    const start = Math.min(endMillis - minDurationMillis, startMillis);
    this.fireOnChange({ endMillis, startMillis: start });
  };

  private fireOnChange({
    startMillis: newStartMillis,
    endMillis: newEndMillis,
  }: Partial<Time>) {
    const { onChange, startMillis, endMillis } = this.props;
    onChange({
      endMillis: newEndMillis ?? endMillis,
      startMillis: newStartMillis ?? startMillis,
    });
  }

  public render() {
    const { disabled, endMillis, minDurationMillis, startMillis } = this.props;

    return (
      <div>
        <TextBoxDurationField
          label="Start Time"
          disabled={disabled}
          horizontal={{
            control: { xs: 8 },
            label: { xs: 4 },
          }}
          minMillis={0}
          onBlur={this.handleStartTimeBlur}
          onChange={this.handleStartTimeChange}
          value={startMillis}
        />
        <TextBoxDurationField
          label="End Time"
          disabled={disabled}
          horizontal={{
            control: { xs: 8 },
            label: { xs: 4 },
          }}
          minMillis={minDurationMillis}
          onBlur={this.handleEndTimeBlur}
          onChange={this.handleEndTimeChange}
          value={endMillis}
        />
      </div>
    );
  }
}

export { IProps as TimeOptionsProps };
