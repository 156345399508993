import cn from 'classnames';
import * as React from 'react';
import KeyHandler, { KEYDOWN } from 'react-key-handler';

import VideoPlayer from 'components/VideoPlayer';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { createChainedFunction } from 'utils/functions';
import { block } from './utils';
import VideoClipperControls, {
  VideoClipperControlsProps,
} from './VideoClipperControls';
import VideoClipperPlayer, {
  VideoClipperPlayerProps,
} from './VideoClipperPlayer';

type PlayerProps = Pick<
  VideoClipperPlayerProps,
  | 'aspectRatio'
  | 'playerRef'
  | 'onDurationChange'
  | 'onLoadedMetadata'
  | 'onPause'
  | 'onPlay'
  | 'poster'
  | 'scaling'
  | 'src'
>;

type ControlsProps = Pick<
  VideoClipperControlsProps,
  | 'endMillis'
  | 'lengthMillis'
  | 'onSelectedMillisChange'
  | 'startMillis'
  | 'maxDurationMillis'
>;

interface IProps extends PlayerProps, ControlsProps {
  className?: string;
  onPause?: () => void;
  onPlay?: () => void;
  playerClassName?: string;
  playing?: boolean;
}

interface IState {
  positionMillis: number;
  stopPlaybackAtMillis: number | null;
}

export default class VideoClipper extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    playing: false,
  };

  private player: VideoPlayer;
  private lastPlayPosMillis: number = 0;

  public state: IState = { positionMillis: 0, stopPlaybackAtMillis: null };

  private handlePlay = () => {
    const { onPlay } = this.props;
    const { positionMillis } = this.state;
    this.lastPlayPosMillis = positionMillis;
    onPlay();
  };

  private handleControlBarPlay = () => {
    const { onPlay, playing } = this.props;
    if (!playing) {
      onPlay();
    }
  };

  private handleControlBarPause = () => {
    this.player.pause();
    this.resetStopPlaybackAtMillis();
  };

  private resetStopPlaybackAtMillis = () => {
    const { stopPlaybackAtMillis } = this.state;
    if (stopPlaybackAtMillis) {
      this.setState({
        stopPlaybackAtMillis: null,
      });
    }
  };

  private handleSpaceKeyDown = (e: React.SyntheticEvent<KeyboardEvent>) => {
    const { onPause, onPlay, playing } = this.props;

    e.preventDefault();
    if (playing) {
      onPause();
    } else {
      onPlay();
    }
  };

  private handleTimeUpdate = (millis: number) => {
    this.setState({ positionMillis: millis }, () => {
      const { endMillis, onPause, playing } = this.props;
      const { positionMillis, stopPlaybackAtMillis } = this.state;

      if (
        playing &&
        this.lastPlayPosMillis < stopPlaybackAtMillis &&
        positionMillis >= stopPlaybackAtMillis
      ) {
        onPause();
      }

      if (
        playing &&
        this.lastPlayPosMillis < endMillis &&
        positionMillis >= endMillis
      ) {
        onPause();
      }
    });
  };

  private handleControlBarPlayPositionChange = (millis: number) => {
    this.player.seek(millis);
    this.resetStopPlaybackAtMillis();
  };

  private setPlayer = (el: VideoPlayer) => (this.player = el);

  public playSection = (startMillis: number, endMillis: number) => {
    this.player.seek(startMillis);
    this.player.play();
    this.setState({
      stopPlaybackAtMillis: endMillis,
    });
  };

  public render() {
    const {
      aspectRatio,
      className,
      lengthMillis,
      maxDurationMillis,
      onDurationChange,
      onLoadedMetadata,
      onPause,
      playerClassName,
      playerRef,
      playing,
      poster,
      scaling,
      src,
      ...props
    } = this.props;
    const { positionMillis } = this.state;

    const [width, height] = aspectRatio
      ? aspectRatio.split(':').map(parseFloat)
      : [0, 0];
    const ratioName = getAspectRatioName(height, width);

    return (
      <div className={cn(block({ [ratioName]: true }), className)}>
        <KeyHandler
          keyEventName={KEYDOWN}
          keyValue=" "
          onKeyHandle={this.handleSpaceKeyDown}
        />
        <div className={playerClassName}>
          <VideoClipperPlayer
            aspectRatio={aspectRatio}
            onDurationChange={onDurationChange}
            onLoadedMetadata={onLoadedMetadata}
            onPlay={this.handlePlay}
            onPause={onPause}
            onTimeUpdate={this.handleTimeUpdate}
            playerRef={createChainedFunction(playerRef, this.setPlayer)}
            playing={playing}
            positionMillis={positionMillis}
            poster={poster}
            scaling={scaling}
            src={src}
          />
        </div>
        <VideoClipperControls
          {...props}
          currentMillis={positionMillis}
          lengthMillis={lengthMillis}
          maxDurationMillis={maxDurationMillis}
          onPlay={this.handleControlBarPlay}
          onPlayPositionChange={this.handleControlBarPlayPositionChange}
          onPause={this.handleControlBarPause}
          playing={playing}
        />
      </div>
    );
  }
}

export { IProps as VideoClipperProps };
