import cn from 'classnames';
import * as React from 'react';

import Modal, { ModalProps } from 'components/Modal';
import { getAspectRatioName } from 'utils/aspect-ratio';

import { block } from '../../utils';
import TextOverlayModalContents, {
  TextOverlayModalContentsProps,
} from '../TextOverlayModalContents';

type PickedModalProps = Pick<
  ModalProps,
  'onExited' | 'onHide' | 'show' | 'title'
>;

export type TextOverlayModalProps = TextOverlayModalContentsProps &
  PickedModalProps & {
    aspectRatio?: number;
    backgroundColor?: string;
  };

const TextOverlayModal: React.FunctionComponent<TextOverlayModalProps> = props => {
  const {
    aspectRatio,
    onExited,
    onHide,
    show = false,
    showStyleTabs = true,
    showPresetSelector = false,
    title,
    workspaceAspectRatio,
    ...rest
  } = props;

  const getWorkspaceAspectRatio = React.useCallback(() => {
    return aspectRatio || workspaceAspectRatio;
  }, [aspectRatio, workspaceAspectRatio]);

  const ratioName = getAspectRatioName(getWorkspaceAspectRatio());
  const ratioClass = ratioName && `text-modal--${ratioName}`;

  return (
    <Modal
      backdrop="static"
      className={cn(
        block({
          dark: true,
          'hidden-style-tabs': !showStyleTabs,
          'with-presets-panel': showPresetSelector,
        }),
        ratioClass,
      )}
      onExited={onExited}
      onHide={onHide}
      show={show}
      title={title}
    >
      <TextOverlayModalContents
        {...rest}
        showStyleTabs={showStyleTabs}
        showPresetSelector={showPresetSelector}
        workspaceAspectRatio={getWorkspaceAspectRatio()}
      />
    </Modal>
  );
};

export default TextOverlayModal;
