import { fromJS } from 'immutable';

import {
  createProjectTemplate,
  deleteProjectTemplate,
  getProjectTemplates,
  overwriteProjectTemplate,
} from 'redux/middleware/api/headliner-user-service';
import { deleteEntities } from 'redux/modules/entities';
import {
  onClickSaveTemplate,
  onSaveTemplate,
} from 'redux/modules/mixpanel/actions';
import { projectEmbedConfigurationIdSelector } from 'redux/modules/project/selectors';
import { ThunkAction } from 'redux/types';
import { getCreatePath } from 'utils/routes';
import { ClickSaveTemplateFrom } from '../mixpanel/types';
import { showNotification } from '../notification';
import { Type } from './action-types';

export const getProjectTemplatesAction = (): ThunkAction<void> => dispatch => {
  dispatch({
    type: Type.PROJECT_TEMPLATES_GET_REQUEST,
  });

  dispatch(getProjectTemplates())
    .then(({ response: payload }) =>
      dispatch({
        payload: {
          ids: fromJS(payload.result),
        },
        type: Type.PROJECT_TEMPLATES_GET_SUCCESS,
      }),
    )
    .catch(err => {
      dispatch({
        ...err,
        type: Type.PROJECT_TEMPLATES_GET_FAILURE,
      });
      throw err;
    });
};

export const clearProjectTemplates = () => deleteEntities(['projectTemplates']);

export const handleSaveTemplateSuccess = (): ThunkAction<void> => dispatch => {
  dispatch(
    showNotification(
      'Your changes were saved successfully',
      'success',
      0,
      'link',
      'Template saved',
      undefined,
      getCreatePath(),
      'Templates Page',
      '_self',
    ),
  );

  dispatch({
    type: Type.PROJECT_TEMPLATES_CREATE_SUCCESS,
  });

  dispatch(onSaveTemplate());
};

export const handleSaveTemplateError = (err): ThunkAction<void> => dispatch => {
  dispatch(
    showNotification({
      message: 'Please try again or {{link}} so we can help',
      code: 'ER013',
      level: 'error',
      type: 'help',
      title: "Sorry, we couldn't save your template",
      actionLabel: 'contact us',
    }),
  );

  dispatch({
    ...err,
    type: Type.PROJECT_TEMPLATES_CREATE_FAILURE,
  });

  throw err;
};

export const createProjectTemplateAction = (
  displayName: string,
  from?: ClickSaveTemplateFrom,
  useMockAudioForPreview: boolean = false,
): ThunkAction<void> => (dispatch, getState) => {
  dispatch({
    type: Type.PROJECT_TEMPLATES_CREATE_REQUEST,
  });

  dispatch(onClickSaveTemplate({ from }));

  const configurationId = projectEmbedConfigurationIdSelector(getState());

  dispatch(
    createProjectTemplate(configurationId, displayName, useMockAudioForPreview),
  )
    .then(() => {
      dispatch(handleSaveTemplateSuccess());
    })
    .catch(err => {
      dispatch(handleSaveTemplateError(err));
    });
};

export const overwriteProjectTemplateAction = (
  templateId?: string,
  useMockAudioForPreview: boolean = false,
): ThunkAction<void> => (dispatch, getState) => {
  dispatch({
    type: Type.PROJECT_TEMPLATES_CREATE_REQUEST,
  });

  const configurationId = projectEmbedConfigurationIdSelector(getState());

  dispatch(
    overwriteProjectTemplate(
      configurationId,
      templateId,
      useMockAudioForPreview,
    ),
  )
    .then(() => {
      dispatch(handleSaveTemplateSuccess());
    })
    .catch(err => {
      dispatch(handleSaveTemplateError(err));
    });
};

export const deleteProjectTemplateAction = (
  templateId: string,
): ThunkAction<void> => dispatch => {
  dispatch({
    type: Type.PROJECT_TEMPLATES_DELETE_REQUEST,
  });

  dispatch(deleteProjectTemplate(templateId))
    .then(() => {
      dispatch({
        payload: { templateId },
        type: Type.PROJECT_TEMPLATES_DELETE_SUCCESS,
      });
    })
    .catch(err => {
      dispatch({
        ...err,
        type: Type.PROJECT_TEMPLATES_DELETE_FAILURE,
      });
      throw err;
    });
};
