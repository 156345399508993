import { YoutubeVideoVisibility } from 'types';
import bem from 'utils/bem';
import { omitUndefined } from 'utils/collections';
import { HEADLINER_SHOUTOUT } from 'utils/constants';
import merge from 'utils/deepmerge';

export interface YouTubeSheetDefaultValues {
  language?: string;
  description?: string;
  madeForKids: boolean;
  categoryId?: number;
  playlistsIds: string[];
  tags?: string[];
  title?: string;
  visibility: YoutubeVideoVisibility;
}

interface YouTubeSheetDefaultsOptions {
  isFree: boolean;
  loggedOut: boolean;
}

const DEFAULT_TAGS_LIST = [];
const DEFAULT_PLAYLIST_IDS_LIST = [];
const DEFAULT_PRIVACY_STATUS = 'public';
const DEFAULT_TITLE = '<Episode Title> | <Podcast Title>';

export const block = bem('youtube-autopost-modal');

export const getDefaultDescription = (
  isFree: boolean,
  loggedOut: boolean,
): string =>
  `<Episode Description> \nEpisode link: <Link To Episode> ${
    isFree || loggedOut ? `\n${HEADLINER_SHOUTOUT}` : ''
  }`;

export const getComposerSheetDefaults = (
  initialData: Partial<YouTubeSheetDefaultValues>,
  options: YouTubeSheetDefaultsOptions,
): YouTubeSheetDefaultValues => {
  const { isFree, loggedOut } = options;

  const defaults: YouTubeSheetDefaultValues = {
    language: undefined,
    description: getDefaultDescription(isFree, loggedOut),
    madeForKids: false,
    categoryId: undefined,
    playlistsIds: DEFAULT_PLAYLIST_IDS_LIST,
    tags: DEFAULT_TAGS_LIST,
    title: DEFAULT_TITLE,
    visibility: DEFAULT_PRIVACY_STATUS,
  };

  return merge(defaults, omitUndefined(initialData));
};
