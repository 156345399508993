export enum ImportType {
  LINK = 'link',
  TEXT = 'text',
}

export type Step =
  | 'importText'
  | 'editScript'
  | 'aspectRatio'
  | 'uploadAudio'
  | 'create';
