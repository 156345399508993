import React from 'react';
import { noop } from 'underscore';

import { LegacyEditCaptionsModalProps } from 'containers/LegacyEditCaptionsModal';
import UploadCaptionsModal from 'containers/UploadCaptionsModal';
import { TranscriptStatus } from 'utils/constants';

import CaptionsStyleEditor from '../CaptionsStyleEditor';

export type CaptionsModal = 'add' | 'edit';

export interface CaptionsModalsProps {
  onEditCaptionsSubmit: LegacyEditCaptionsModalProps['onSubmit'];
  onHide?: () => void;
  onOpenEditModal?: () => void;
  transcriptStatus: TranscriptStatus;
  show: CaptionsModal | undefined;
}

const CaptionsModals: React.SFC<CaptionsModalsProps> = ({
  onEditCaptionsSubmit,
  onHide,
  onOpenEditModal = noop,
  show,
  transcriptStatus,
}) => {
  return (
    <>
      {transcriptStatus === TranscriptStatus.TRANSCRIPT_STATUS_AVAILABLE && (
        <CaptionsStyleEditor
          onHide={onHide}
          onOpen={onOpenEditModal}
          onSubmit={onEditCaptionsSubmit}
          show={show === 'edit'}
        />
      )}
      <UploadCaptionsModal onCancel={onHide} show={show === 'add'} />
    </>
  );
};

export default CaptionsModals;
