import * as React from 'react';

import usePlanCost from 'blocks/Pricing/usePlanCost';
import useYearlyDiscount from 'blocks/Pricing/useYearlyDiscount';
import RadioOptions from 'components/RadioOptions';
import {
  MonthlyPlanName,
  YearlyPlanName,
} from 'redux/middleware/api/plan-service';
import BillingCycleOptionLabel from './BillingCycleOptionLabel';
import { block } from './utils';

export interface BillingCyclePlanOptions {
  monthly: MonthlyPlanName;
  yearly: YearlyPlanName;
}

export type SubscriptionDuration = keyof BillingCyclePlanOptions;

type OnChange = (value: SubscriptionDuration) => void;

export interface BillingCycleOptionsProps {
  disabled?: boolean;
  onChange: OnChange;
  options: BillingCyclePlanOptions;
  value: SubscriptionDuration;
}

const BillingCycleOptions: React.SFC<BillingCycleOptionsProps> = ({
  disabled,
  onChange,
  options,
  value,
}) => {
  const monthlyCost = usePlanCost(options.monthly);
  const yearlyCost = usePlanCost(options.yearly);
  const yearlyDiscountPercent = useYearlyDiscount(
    options.monthly,
    options.yearly,
  );

  return (
    <RadioOptions
      className={block('options')}
      disabled={disabled}
      listClassName={block('options-list')}
      onChange={onChange}
      optionClassName={block('option')}
      options={[
        {
          label: (
            <BillingCycleOptionLabel cost={`${yearlyCost} / year`}>
              Billed yearly
              <span className={block('discount')}>
                SAVE {yearlyDiscountPercent}%
              </span>
            </BillingCycleOptionLabel>
          ),
          value: 'yearly',
        },
        {
          label: (
            <BillingCycleOptionLabel cost={`${monthlyCost} / mo`}>
              Billed monthly
            </BillingCycleOptionLabel>
          ),
          value: 'monthly',
        },
      ]}
      value={value}
    />
  );
};

export default BillingCycleOptions;
