import { Button } from '@sparemin/blockhead';
import React from 'react';

import useTextOverlaySubmit from 'blocks/TextOverlayModal/v2/hooks/useTextOverlaySubmit';
import { TextOverlayV2 } from 'blocks/TextOverlayModal/v2/types';

interface DuplicateOverlayButtonProps {
  onHide: () => void;
  onSubmit: (overlay: TextOverlayV2) => void;
}

const DuplicateOverlayButton: React.FunctionComponent<DuplicateOverlayButtonProps> = props => {
  const { onHide, onSubmit } = props;

  const handleSubmit = useTextOverlaySubmit({ onHide, onSubmit });

  return (
    <Button onPress={handleSubmit} variant="ghost">
      Duplicate
    </Button>
  );
};

export default DuplicateOverlayButton;
