import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { every, isEmpty } from 'underscore';
import SteppedModal from 'components/SteppedModal';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import InstagramPostModal from 'containers/InstagramPostModal';
import { InstagramPostModalStep } from 'containers/InstagramPostModal/steps/types';
import useAccessDeniedStep from 'containers/InstagramPostModal/steps/useAccessDeniedStep';
import useAuthenticationProgressStep from 'containers/InstagramPostModal/steps/useAuthenticationProgressStep';
import useAuthorizationStep from 'containers/InstagramPostModal/steps/useAuthorizationStep';
import useAutopostingComposerStep from 'containers/InstagramPostModal/steps/useAutopostingComposerStep';
import { UseComposerStepValue } from 'containers/InstagramPostModal/steps/useAutopostingComposerStep/types';
import { instagramUserDataSelector } from 'redux/modules/social';
import { InstagramSheetDefaultValues } from './types';

export interface InstagramAutoPostModalProps {}

const InstagramAutoPostModal: React.FC<InstagramAutoPostModalProps> = () => {
  const [activeStep, setActiveStep] = useState('');

  const modalRef = useRef<SteppedModal>(null);

  const { params, show, onExited, onHide } = useConnectedModal(
    'InstagramAutoPost',
  );

  const instagramUserData = useSelector(instagramUserDataSelector);

  const { mediaType, subscriptionItem } = params ?? {};

  const { description, instagramUserId } =
    subscriptionItem?.autoPostVideoPreference.options || {};

  const initialValues = React.useMemo((): InstagramSheetDefaultValues => {
    return {
      description:
        description ??
        'Here’s a new clip from <Episode Title>! Listen to the full episode here: <Link To Episode>',
      instagramMediaType: mediaType,
      instagramUserId,
    };
  }, [description, mediaType, instagramUserId]);

  const replaceModalStep = useCallback((id: InstagramPostModalStep): void => {
    modalRef.current?.stepHistory.replace(id);
  }, []);

  const handleGoToProgressStep = useCallback(() => {
    replaceModalStep('authentication-in-progress');
  }, [replaceModalStep]);

  const handleAuthorizationError = useCallback((): void => {
    replaceModalStep('access-denied');
  }, [replaceModalStep]);

  const handleGoToComposerStep = useCallback(() => {
    if (every(instagramUserData, isEmpty)) {
      return undefined;
    }

    return replaceModalStep('composer');
  }, [instagramUserData, replaceModalStep]);

  const handleAuthenticationSuccess = useCallback(async () => {
    replaceModalStep('composer');
  }, [replaceModalStep]);

  const handleHide = useCallback(
    (values?: UseComposerStepValue) => {
      handleGoToComposerStep();
      onHide(values);
    },
    [handleGoToComposerStep, onHide],
  );

  const handleExited = useCallback(() => {
    handleGoToComposerStep();
    onExited();
  }, [handleGoToComposerStep, onExited]);

  const handleSubmit = useCallback(
    (values: UseComposerStepValue): void => {
      handleHide(values);
    },
    [handleHide],
  );

  const authorization = useAuthorizationStep({
    onAuth: handleGoToProgressStep,
    onAuthSuccess: handleAuthenticationSuccess,
    onError: handleAuthorizationError,
  });

  const authenticationProgress = useAuthenticationProgressStep();

  const accessDenied = useAccessDeniedStep({
    onError: () => null,
    onReviseAccess: () => null,
  });

  const composer = useAutopostingComposerStep({
    initialValues,
    onHideModal: handleHide,
    onSubmit: handleSubmit,
  });

  const isComposerStep = activeStep === 'composer';

  return (
    <InstagramPostModal
      {...{ show }}
      ref={modalRef}
      title={!isComposerStep && 'auto-post videos'}
      defaultStep={authorization.id}
      steps={[authorization, authenticationProgress, accessDenied, composer]}
      className={isComposerStep && 'composer-step-container'}
      onStepChange={setActiveStep}
      onHide={() => handleHide()}
      onExited={handleExited}
    />
  );
};

export default InstagramAutoPostModal;
