import { unix } from 'dayjs';
import { useSelector } from 'react-redux';
import { subscriptionSelector } from 'redux/modules/pricing';
import { DATE_FORMAT } from './utils';

export default function usePausedSubscriptionInfo() {
  const { pauseSchedule } = useSelector(subscriptionSelector) ?? {};
  const status = pauseSchedule?.pauseStatus;
  const isQueued = status === 'queued';
  const isPaused = status === 'active';

  const getTitle = () => {
    if (isQueued) {
      return 'Your plan pauses soon';
    }

    if (isPaused) {
      return 'Your plan is paused';
    }

    return '';
  };

  const getSubtitle = () => {
    if (!pauseSchedule) {
      return '';
    }

    const { pauseStart, pauseEnd } = pauseSchedule;
    const startDate = unix(pauseStart).format(DATE_FORMAT);
    const endDate = unix(pauseEnd).format(DATE_FORMAT);

    if (isQueued) {
      return `You’ll be on our free plan from ${startDate} to ${endDate}`;
    }

    if (isPaused) {
      return `Your are on a forever free plan until ${endDate}`;
    }

    return '';
  };

  return {
    title: getTitle(),
    subtitle: getSubtitle(),
    showPausedInfo: pauseSchedule?.pauseStatus !== null,
  };
}
