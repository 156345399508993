export const ACTION_KEY = 'EVENT_PING_SERVICE' as const;

export enum EventType {
  VIDEO_VIEW = 3,
  VIDEO_ACTION = 4,
}

export enum ServiceMethod {
  PING = 'PING',
}

export enum EventSourcePlatform {
  WEBAPP = 'website',
}
