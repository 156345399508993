import cn from 'classnames';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { noop } from 'underscore';

import bem from 'utils/bem';
import GradientBorder from './GradientBorder';
import { H2 } from './Heading';
import LinkButton from './LinkButton';

export interface UpsellBannerProps {
  className?: string;
  icon?: JSX.Element;
  heading?: React.ReactNode;
  onUpgradeClick?: (e: React.MouseEvent<any>) => void;
  actionLabel?: React.ReactNode;
}

const block = bem('upsell-banner');

const UpsellBanner: React.FC<UpsellBannerProps> = ({
  className,
  heading,
  icon,
  onUpgradeClick = noop,
  actionLabel = 'get unlimited',
}) => (
  <GradientBorder className={cn(block(), className)}>
    <div className={block('icon')}>{icon}</div>
    <H2>{heading}</H2>
    <LinkButton<LinkProps>
      componentClass={Link}
      className={block('upgrade')}
      onClick={onUpgradeClick}
      to="/settings/my-plan"
    >
      {actionLabel}
    </LinkButton>
  </GradientBorder>
);

export default UpsellBanner;
