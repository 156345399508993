import * as React from 'react';

import FileUploader from 'components/FileUploader';
import { CaptionFile } from 'components/icons';
import { ApplicationError } from 'utils/ApplicationError';
import { block, SUPPORTED_CAPTION_TYPE_EXTENSIONS } from './utils';

interface IProps {
  onFileAccepted: (file: File) => void;
  onFileRejected: (error: ApplicationError, file: File) => void;
}

export const CaptionUploader: React.SFC<IProps> = ({
  onFileAccepted,
  onFileRejected,
}) => (
  <div className={block('uploader')}>
    <FileUploader
      accept={SUPPORTED_CAPTION_TYPE_EXTENSIONS.join(',')}
      className={block('dropzone')}
      image={<CaptionFile />}
      supportedFileTypes={SUPPORTED_CAPTION_TYPE_EXTENSIONS}
      onFileAccepted={onFileAccepted}
      onFileRejected={onFileRejected}
    />
  </div>
);

export default CaptionUploader;
export { IProps as CaptionUploaderProps };
