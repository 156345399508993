import toWav from 'audiobuffer-to-wav';
import { Mp3Encoder } from 'lamejs';
import _ from 'underscore';

import { OneOrMore } from '../../types';
import { getOfflineContext } from './audio-context';
import {
  calculateBufferSize,
  createAudioSource,
  rampToGainAtTime,
  removeAudioSource,
  setGainAtTime,
  startRendering,
} from './common';
import {
  scheduleGainChanges,
  shouldUseImmediateTransition,
} from './gain-mixer';
import { ExportAudioSource, ExportSourceConfig } from './types';

function render(ctx: OfflineAudioContext, sources: ExportAudioSource[]) {
  const startedAt = ctx.currentTime;

  sources.forEach(source => {
    const sourceNode = ctx.createBufferSource();
    const gainNode = ctx.createGain();

    sourceNode.buffer = source.buffer;
    sourceNode.connect(gainNode);
    gainNode.connect(ctx.destination);

    if (source.audioLevels) {
      scheduleGainChanges(source, transition => {
        const startSec =
          transition.atSec + startedAt + source.startOffsetSeconds;
        switch (transition.effect) {
          case 'cut':
            setGainAtTime(
              gainNode,
              transition.toGain,
              startSec,
              shouldUseImmediateTransition(transition),
            );
            break;

          case 'fade': {
            const { durationSec, fromGain, toGain } = transition;
            rampToGainAtTime(gainNode, fromGain, toGain, startSec, durationSec);
            break;
          }
        }
      });
    }

    const startAudioAt = startedAt + source.startOffsetSeconds;
    sourceNode.start(
      startAudioAt,
      source.fromSeconds,
      source.toSeconds - source.fromSeconds,
    );
  });

  return startRendering(ctx);
}

// hurrah smart people https://stackoverflow.com/questions/33738873/float32-to-int16-javascript-web-audio-api
function float32ToInt16(buffer: Float32Array) {
  return buffer.reduce((res, data, index) => {
    const s = Math.max(-1, Math.min(1, data));
    res[index] = s < 0 ? s * 0x8000 : s * 0x7fff;
    return res;
  }, new Int16Array(buffer.length));
}

function mp3Encode(buffer: AudioBuffer): Promise<Int8Array[]> {
  return new Promise(resolve => {
    const data: Int16Array[] = [];
    for (let channel = 0; channel < buffer.numberOfChannels; channel += 1) {
      data[channel] = float32ToInt16(buffer.getChannelData(channel));
    }

    const encoder = new Mp3Encoder(
      buffer.numberOfChannels,
      buffer.sampleRate,
      128,
    );
    const sampleBlockSize = 1152;

    const mp3Data: Int8Array[] = [];
    for (let i = 0; i < data[0].length; i += sampleBlockSize) {
      const dataChunks: Int16Array[] = [];
      for (let channel = 0; channel < buffer.numberOfChannels; channel += 1) {
        dataChunks[channel] = data[channel].subarray(i, i + sampleBlockSize);
      }

      const encoded: Int8Array = encoder.encodeBuffer.apply(null, dataChunks);
      if (encoded.length > 0) {
        mp3Data.push(encoded);
      }
    }

    const mp3Buf = encoder.flush();

    if (mp3Buf.length > 0) {
      mp3Data.push(new Int8Array(mp3Buf));
    }

    resolve(mp3Data);
  });
}

function wavEncode(buffer: AudioBuffer): Promise<ArrayBuffer> {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    try {
      const wav = toWav(buffer);
      resolve(wav);
    } catch (e) {
      reject(e);
    }
  });
}

export function exportAudio(
  sourceConfigs: OneOrMore<ExportSourceConfig>,
  durationMillis?: number,
  encoding?: 'wav',
): Promise<ArrayBuffer>;

export function exportAudio(
  sourceConfigs: OneOrMore<ExportSourceConfig>,
  durationMillis?: number,
  encoding?: 'mp3',
): Promise<Int8Array[]>;

export function exportAudio(
  sourceConfigs: OneOrMore<ExportSourceConfig>,
  durationMillis?: number,
  encoding?: 'mp3' | 'wav',
) {
  const configs = Array.isArray(sourceConfigs)
    ? sourceConfigs
    : [sourceConfigs];
  const futAudioSources = configs.map(sourceConfig =>
    createAudioSource(sourceConfig).then(source => {
      const { fromSeconds, toSeconds, buffer, startOffsetSeconds } = source;
      return {
        ...source,
        fromSeconds:
          !_.isUndefined(fromSeconds) && fromSeconds < toSeconds
            ? fromSeconds
            : 0,
        startOffsetSeconds: !_.isUndefined(startOffsetSeconds)
          ? startOffsetSeconds
          : 0,
        toSeconds:
          !_.isUndefined(toSeconds) && toSeconds > fromSeconds
            ? toSeconds
            : buffer.duration,
      };
    }),
  );

  const futRendered = Promise.all(futAudioSources)
    .then(sources => {
      const channels = Math.max(...sources.map(s => s.buffer.numberOfChannels));
      const sampleRate = Math.max(...sources.map(s => s.buffer.sampleRate));

      const duration = (() => {
        if (!_.isUndefined(durationMillis)) {
          return durationMillis;
        }

        const sourceDurations = sources.map(source => {
          const { fromSeconds, toSeconds, startOffsetSeconds } = source;
          return (toSeconds - fromSeconds + startOffsetSeconds) * 1000;
        });

        return Math.max(...sourceDurations);
      })();

      const ctx = getOfflineContext(
        channels,
        calculateBufferSize(duration, sampleRate),
        sampleRate,
      );

      return render(ctx, sources);
    })
    .then<any>(renderedBuffer =>
      encoding === 'mp3'
        ? mp3Encode(renderedBuffer)
        : wavEncode(renderedBuffer),
    );

  futRendered.then(() =>
    configs.forEach(config => {
      if (typeof config.source === 'string') {
        removeAudioSource(config.source);
      }
    }),
  );

  return futRendered;
}

export default exportAudio;
