import { fromJS } from 'immutable';
import { omit } from 'underscore';
import { SlideExport } from 'components/VideoTemplateEditor/types';
import {
  create,
  getCreation,
} from 'redux/middleware/api/creation-service/actions';
import { CreationStatus } from 'redux/middleware/api/creation-service/types';
import { PodcastWorkflowTemplate } from 'redux/middleware/api/podcast-service';
import { createBaseConfiguration } from 'redux/middleware/api/podcast-service/actions';
import { ThunkAction } from 'redux/types';
import {
  CaptionsMediaSourceType,
  DeepImmutableMap,
  IEmbedConfig,
  ISlideshowItem,
  ISoundwave,
  ITextOverlayItem,
  IWatermark,
  Layerable,
  Soundwave,
} from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { omitUndefined } from 'utils/collections';
import merge from 'utils/deepmerge';
import { addDefaultAudioLayers } from 'utils/embed/audio';
import {
  applyWizardCaptionsOverrideToStyle,
  createWizardCaptions,
  formatCaptionsForConfig,
  hasCaptions,
} from 'utils/embed/captions';
import { createVersionInfo } from 'utils/embed/embed';
import { formatMediaContainerForConfig } from 'utils/embed/media-container';
import { formatProgressForConfig } from 'utils/embed/progress';
import { createSlide, formatSlideForConfig } from 'utils/embed/slideshow';
import { formatSoundwaveForConfig } from 'utils/embed/soundwave';
import { formatOverlayForConfig } from 'utils/embed/text-overlay';
import { formatTimerForConfig } from 'utils/embed/timer';
import { createVideoClipFromVideo } from 'utils/embed/video';
import { formatWatermarkForConfig } from 'utils/embed/watermark';
import reduxPoll, { cancelReduxPoll } from 'utils/redux-poll';
import { isBlankCanvas } from 'utils/templates';
import { createVideoElement } from 'utils/video';
import { uploadImage } from '../common/actions/image';
import { podcastWorkflowTemplatesSelector } from '../entities';
import { videoExportOptionsSelector } from '../user-pref/selectors';
import { Type } from './action-types';
import { CREATION_POLL_ID } from './constants';
import {
  creationIdSelector,
  creationSelector,
  creationStatusSelector,
} from './selectors';
import { ExportConfiguration, isClippedAudio } from './types';

const uploadImageSource = (
  src: string | Blob,
): ThunkAction<Promise<string>> => dispatch => {
  return !src
    ? Promise.resolve(undefined)
    : dispatch(uploadImage(src)).then(({ url }) => url);
};

const uploadSlide = (
  slide: SlideExport,
): ThunkAction<Promise<[string, string]>> => async dispatch => {
  // NB: the server sometimes throws a 500 error when uploading the same image
  // multiple times in parallel.  Although there's a higher chance of this happening
  // with parallel requests, the server also seems to occassionally throw the
  // error for serial requests.  To work around this:
  //  - check if the blobs are the same and if so, only upload one
  //  - when multiple images are uploaded, submit the requests serially
  const originalUrl = await dispatch(uploadImageSource(slide.originalSrc));
  const croppedUrl =
    slide.originalSrc === slide.imageSrc
      ? originalUrl
      : await dispatch(uploadImageSource(slide.imageSrc));

  return [originalUrl, croppedUrl];
};

// NB: this assumes that any lottie key text assets exist within slideshow items
// that are tagged as the main image
const createSlideshowFromBaseConfig = (
  config: ExportConfiguration,
  { slideshowInfo }: IEmbedConfig,
): ThunkAction<Promise<ISlideshowItem[]>> => async dispatch => {
  const { aspectRatio } = config;

  return Promise.all(
    config.slideshow.map(async slide => {
      const originalSlide = slideshowInfo[slide.originalSlideshowIndex];
      const originalSrc = originalSlide?.imageUrl;
      const isSameImage =
        typeof slide.imageSrc === 'string' && slide.imageSrc === originalSrc;

      const [originalUrl, croppedUrl] = isSameImage
        ? []
        : await dispatch(uploadSlide(slide));

      return formatSlideForConfig(
        fromJS(
          createSlide(
            isSameImage ? slide.imageSrc : croppedUrl,
            originalSlide?.entryTransition || 'cut',
            0,
            0,
            slide.imageEffect || originalSlide.imageEffect || 'none',
            originalSlide?.imageMetadata,
            fromJS(aspectRatio),
            isSameImage ? originalSlide.sourceImage?.url : originalUrl,
            slide.metadata,
            slide.placement,
            slide.sourceImageOrigin,
            slide.blurRadius,
          ),
        ),
        slide.layerId,
      );
    }),
  ) as any;
};

const createWatermarkFromBaseConfig = (
  config: ExportConfiguration,
  watermarks: IWatermark[],
): ThunkAction<Promise<IWatermark[]>> => async dispatch => {
  if (!config.watermark) {
    return [];
  }

  const watermarkUrl = await dispatch(
    uploadImageSource(config?.watermark?.url),
  );

  if (watermarks.length) {
    return watermarks.map(watermark => {
      if (watermark.imageType === 'mainImage') {
        return {
          ...watermark,
          ...formatWatermarkForConfig(
            {
              ...config.watermark,
              src: watermarkUrl,
            },
            0,
          ),
        };
      }
      return watermark;
    });
  }

  return [
    formatWatermarkForConfig(
      {
        ...config.watermark,
        src: watermarkUrl,
      },
      0,
    ),
  ];
};

const getMediaSourceType = (
  config: ExportConfiguration,
): CaptionsMediaSourceType => {
  const { audio, transcription, video } = config;

  const hasTranscript = transcription.transcribe || transcription.transcriptUrl;

  if (hasTranscript) {
    if (audio) {
      return 'audio';
    }

    if (video) {
      return 'video';
    }
  }

  return undefined;
};

const createCaptions = (
  config: ExportConfiguration,
  baseConfig?: IEmbedConfig,
): any => {
  const { aspectRatio } = config;

  const enabled =
    config.transcription.transcribe || !!config.transcription.transcriptUrl;

  // when the captions at config has the hasBeenEdited flag, it is safe to assume that
  // they should be threated as config that has been edited at the wizard
  if (config.captions?.hasBeenEdited) {
    return formatCaptionsForConfig(
      applyWizardCaptionsOverrideToStyle(
        createWizardCaptions(
          getAspectRatioName(aspectRatio),
          undefined,
          getMediaSourceType(config),
          undefined,
          undefined,
          enabled,
          undefined,
        ),
        config.captions,
      ),
    );
  }

  if (hasCaptions(baseConfig)) {
    return merge(baseConfig.captions, { enabled });
  }

  return formatCaptionsForConfig(
    createWizardCaptions(
      getAspectRatioName(aspectRatio),
      undefined,
      getMediaSourceType(config),
      undefined,
      undefined,
      enabled,
      undefined,
    ),
  );
};

const createVideoClips = async (config: ExportConfiguration) => {
  const { audio, video, videoClips, aspectRatio } = config;

  const audioLength = isClippedAudio(audio)
    ? audio.endMillis - audio.startMillis
    : undefined;

  // As this function can be used by transcriptions wizard, videoClips
  // can be undefined
  const parsedVideoClips =
    videoClips?.map(videoClip => ({
      ...videoClip,
      endMilli: audioLength,
    })) ?? [];

  if (!video) {
    return parsedVideoClips;
  }

  const videoElement = await createVideoElement(video.source);

  if (!videoElement && !video?.uploadedVideoId) {
    return parsedVideoClips;
  }

  return [
    ...parsedVideoClips,
    createVideoClipFromVideo(
      fromJS({
        trimStartMillis: video.startMillis,
        trimEndMillis: video.endMillis,
        videoWidth: videoElement?.videoWidth,
        videoHeight: videoElement?.videoHeight,
      }),
      video.originalDurationMillis,
      aspectRatio,
    ),
  ];
};

export const createConfigurationFromScratch = (
  config: ExportConfiguration,
  textOverlayInfo: ITextOverlayItem[],
  soundwave: ISoundwave,
): ThunkAction<Promise<IEmbedConfig>> => async dispatch => {
  const {
    aspectRatio,
    backgroundColor,
    edgeVideos,
    layers,
    progress,
    slideshow,
    timer,
    watermark,
  } = config;

  const watermarkUrl = await dispatch(uploadImageSource(watermark?.url));

  // "start from scratch" projects always have the main image set to slideshow,
  // but orgs like DW have a default watermark that doesn't need to be uploaded
  // but does need to exist in the config
  const watermarks = watermark && [
    formatWatermarkForConfig(
      {
        ...watermark,
        src: watermarkUrl,
      },
      0,
    ),
  ];

  // when starting from scratch, assume all images added by the user
  const slideshowInfo = await Promise.all(
    slideshow.map(async slide => {
      const [originalUrl, croppedUrl] = await dispatch(uploadSlide(slide));
      return formatSlideForConfig(
        fromJS(
          createSlide(
            croppedUrl,
            'cut',
            0,
            0,
            'none',
            undefined,
            fromJS(aspectRatio),
            originalUrl,
            slide.metadata,
            slide.placement,
            slide.sourceImageOrigin,
            slide.blurRadius,
          ),
        ),
        slide.layerId,
      );
    }),
  );

  const videoClips = await createVideoClips(config);

  const captions = createCaptions(config);

  return omitUndefined({
    captions,
    edgeVideos,
    slideshowInfo,
    soundwave,
    textOverlayInfo,
    dimensions: aspectRatio,
    layerOrder: addDefaultAudioLayers(layers),
    mainMediaContainer: formatMediaContainerForConfig(backgroundColor),
    progress: formatProgressForConfig(progress),
    timer: formatTimerForConfig(timer),
    versionInfo: createVersionInfo(spareminConfig.version),
    watermark: watermarks,
    videoClips,
  });
};

export const createConfigurationFromTemplate = (
  config: ExportConfiguration,
  textOverlayInfo: ITextOverlayItem[],
  soundwave: ISoundwave,
  template: DeepImmutableMap<PodcastWorkflowTemplate>,
): ThunkAction<Promise<IEmbedConfig>> => async dispatch => {
  const templateId = template.get('templateId');
  const templateType = template.get('templateType');

  const { response: baseConfig } = await dispatch(
    createBaseConfiguration(
      templateId,
      templateType,
      // TODO recordingId and durationMillis are required by the endpoint.  relax this constraint?
      null,
      null,
    ),
  );

  const versionInfo = createVersionInfo(spareminConfig.version);
  const captions = createCaptions(config, baseConfig);
  const { edgeVideos } = config;

  const slideshowInfo = await dispatch(
    createSlideshowFromBaseConfig(config, baseConfig),
  );

  const watermark = await dispatch(
    createWatermarkFromBaseConfig(config, baseConfig.watermark),
  );

  const videoClips = await createVideoClips(config);

  return merge(
    {
      ...baseConfig,
      edgeVideos,
      videoClips,
      // captions can't be deep merged - we have to check the captions object in
      // the base config in order to decide what goes in the final configuration
      captions,
    },
    {
      slideshowInfo,
      soundwave,
      textOverlayInfo,
      versionInfo,
      watermark,
      layerOrder: addDefaultAudioLayers(config.layers),
      mainMediaContainer: formatMediaContainerForConfig(config.backgroundColor),
      progress: formatProgressForConfig(config.progress),
      timer: formatTimerForConfig(config.timer),
    },
    {
      arrayMerge: (__: any[], src: any[]): any => src,
    },
  );
};

export const waitForCreation = (
  shouldTerminate: (creation: any) => boolean,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({
    type: Type.CREATION_STATUS_POLL_REQUEST,
  });

  try {
    await reduxPoll(
      dispatch,
      async () => {
        const creationId = creationIdSelector(getState());
        await dispatch(getCreation(creationId));
        return undefined;
      },
      {
        id: CREATION_POLL_ID,
        intervalMillis: 5000,
        maxAttempts: 1500,
        retryAttempts: 3,
        shouldContinue: err => {
          if (err) {
            return false;
          }

          if (creationStatusSelector(getState()) === CreationStatus.FAILED) {
            throw new Error('Error creation project');
          }

          const creation = creationSelector(getState());
          return !shouldTerminate(creation);
        },
      },
    );
    dispatch({
      type: Type.CREATION_STATUS_POLL_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: Type.CREATION_STATUS_POLL_FAILURE,
    });
    throw error;
  }
};

export const formatSoundwave = (
  soundwaveConfig: Soundwave & Layerable,
): ISoundwave => {
  return (
    soundwaveConfig &&
    formatSoundwaveForConfig(
      fromJS({
        ...omit(soundwaveConfig, 'layerId'),
      }),
      soundwaveConfig.layerId,
    )
  );
};

export const formatTextOverlayInfo = (textOverlays): ITextOverlayItem[] => {
  // Cast to any and explicitly typed because formatOverlayForConfig is
  // a js file and doesn't contain type info.
  return textOverlays?.map(overlay =>
    formatOverlayForConfig(fromJS(overlay), overlay.layerId),
  ) as any;
};

export const exportFromWizard = (
  config: ExportConfiguration,
): ThunkAction<Promise<any>> => async (dispatch, getState) => {
  try {
    dispatch({ type: Type.CREATION_REQUEST });

    const {
      aspectRatio,
      podcastIdentifier,
      soundwave: soundwaveConfig,
      templateId,
      textOverlays,
      audio,
      transcription,
      traceId,
      customTraceId,
      video,
      frameSizeOverride,
    } = config;

    const aspectRatioName = getAspectRatioName(aspectRatio);
    const soundwave = soundwaveConfig && formatSoundwave(soundwaveConfig);
    const textOverlayInfo = formatTextOverlayInfo(textOverlays);
    const templates = podcastWorkflowTemplatesSelector(getState());
    const template = templates?.get(templateId);

    const embedConfig = !template
      ? await dispatch(
          createConfigurationFromScratch(config, textOverlayInfo, soundwave),
        )
      : await dispatch(
          createConfigurationFromTemplate(
            config,
            textOverlayInfo,
            soundwave,
            template,
          ),
        );

    const { response } = await dispatch(
      create({
        audioPodcastId: podcastIdentifier?.podcastId,
        audioRemoteEpisodeId: podcastIdentifier?.episodeId,
        templateId: isBlankCanvas(templateId) ? undefined : templateId,
        isEddy: config.shouldGenerateAssets,
        traceId,
        customTraceId,
        aspectRatioHeight: config.aspectRatio.height,
        aspectRatioWidth: config.aspectRatio.width,
        baseConfigJson: embedConfig,
        initiateExportVideo: config.initiateExport,
        preferredAudioWavetype:
          soundwave?.preferredWaveformType ?? 'amplitudeBased',
        projectCreateMethod: config.projectCreatMethod,
        projectName: config.projectName,
        templateType: template?.get('templateType'),
        ...(audio && {
          isAudioTranscriptEnabled:
            !transcription?.transcriptUrl && transcription?.transcribe,
          audioLanguage: config.shouldGenerateAssets
            ? config.audioLanguage
            : transcription?.language,
        }),
        ...(audio &&
          isClippedAudio(audio) && {
            entireAudioInstanceId: audio.entireAudioInstanceId,
            entireAudioTrimStartMillis: audio.startMillis,
            entireAudioTrimEndMillis: audio.endMillis,
            entireAudioTranscriptUrl: transcription?.transcriptUrl,
          }),
        ...(audio &&
          !isClippedAudio(audio) && {
            audioSource: audio.source,
            clippedAudioTranscriptUrl: transcription?.transcriptUrl,
          }),
        ...(config.initiateExport
          ? videoExportOptionsSelector(
              aspectRatioName,
              config.fullEpisodeExport,
              getState(),
              frameSizeOverride,
            )
          : undefined),
        ...(video && {
          videoSource: video.source,
          uploadedVideoId: video?.uploadedVideoId,
          videoTrimStartMillis: video.startMillis,
          videoTrimEndMillis: video.endMillis,
          videoLanguage: transcription?.language,
          isVideoTranscriptEnabled: transcription?.transcribe,
          clippedAudioTranscriptUrl: transcription?.transcriptUrl,
        }),
      }),
    );

    dispatch({
      type: Type.CREATION_SUCCESS,
      payload: { creationId: response.result },
    });
  } catch (error) {
    dispatch({ type: Type.CREATION_FAILURE });

    throw error;
  }
};

export const cancelWaitForCreation = (): ThunkAction<void> => dispatch =>
  cancelReduxPoll(dispatch, CREATION_POLL_ID);

export const clearWizardExport = () => {
  return { type: Type.CREATION_CLEAR };
};
