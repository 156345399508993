import { Checkbox, Spacer } from '@sparemin/blockhead';
import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { ShareFormField } from 'components/ShareForm';
import { TikTokUserAllowance } from 'containers/TikTokAutoPostModal/types';
import { tikTokCreatorInfoSelector } from 'redux/modules/social';
import { block } from '../../utils';
import { UsePostComposerStepConfig } from './usePostComposerStep';

export type UserAllowanceProps = Pick<
  UsePostComposerStepConfig,
  'userAllowance' | 'onUserAllowanceChange'
>;

const UserAllowance: React.FunctionComponent<UserAllowanceProps> = ({
  userAllowance = [],
  onUserAllowanceChange,
}) => {
  const { isCommentDisabled, isDuetDisabled, isStitchDisabled } = useSelector(
    tikTokCreatorInfoSelector,
  );

  const createHandleChange = useCallback(
    (allowance: TikTokUserAllowance) => () => {
      onUserAllowanceChange(
        userAllowance.includes(allowance)
          ? userAllowance.filter(i => i !== allowance)
          : [...userAllowance, allowance],
      );
    },
    [onUserAllowanceChange, userAllowance],
  );

  return (
    <ShareFormField label="Allow users to" className={block('form-field')}>
      <Spacer
        orientation="horizontal"
        align="center"
        space="16px"
        className={block('checkbox-group-container')}
      >
        <Checkbox
          isSelected={userAllowance.includes('comment')}
          onChange={createHandleChange('comment')}
          isDisabled={isCommentDisabled}
        >
          Comment
        </Checkbox>
        <Checkbox
          isSelected={userAllowance.includes('duet')}
          onChange={createHandleChange('duet')}
          isDisabled={isDuetDisabled}
        >
          Duet
        </Checkbox>
        <Checkbox
          isSelected={userAllowance.includes('stitch')}
          onChange={createHandleChange('stitch')}
          isDisabled={isStitchDisabled}
        >
          Stitch
        </Checkbox>
      </Spacer>
    </ShareFormField>
  );
};

export default UserAllowance;
