import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';

import FileSizeUpsellBanner from 'components/FileSizeUpsellBanner';
import FileUploader, { RejectedReason } from 'components/FileUploader';
import { FileUploadMethod } from 'components/FileUploader/FileUploaderDropzone';
import { MediaImportOption } from 'components/FileUploader/types';
import { renderTemplateDimensionsInfoByAspectRatio } from 'components/FileUploader/utils';
import { MediaFile } from 'components/icons';
import TosLink from 'components/TosLink';
import { Variant } from 'containers/FileSizeUpsellModal';
import { Tier } from 'redux/middleware/api/plan-service';
import {
  onFileUpload,
  onIncreaseUploadLimit,
  onUnlockThisFeature,
} from 'redux/modules/mixpanel';
import { unshiftModal } from 'redux/modules/modal';
import { modalParamsSelector } from 'redux/modules/modal/selectors';
import { tierSelector } from 'redux/modules/pricing';
import { ApplicationError } from 'utils/ApplicationError';
import { DropZoneType } from 'utils/constants';
import {
  getSupportedMediaMimeTypes,
  getSupportedMediaTypes,
  isSupportedImageFile,
} from 'utils/formats';
import FileSizeUpsellContents from './FileSizeUpsellContents';
import UrlImportForm from './UrlImportForm';
import { block } from './utils';

interface IProps {
  aspectRatio: number;
  className?: string;
  maxFileSizeMb: number;
  uploadMethod?: FileUploadMethod;
  isUploadInProgress?: boolean;
  onClickMediaImportOption?: (mediaImportOption: MediaImportOption) => void;
  onFileAccepted?: (file: File) => void;
  onFileRejected: (error: ApplicationError, file?: File) => void;
  onUrlAccepted?: (url: string) => void;
}

export const MediaUploader: React.SFC<IProps> = ({
  aspectRatio,
  className,
  maxFileSizeMb,
  uploadMethod = 'chooseFile',
  onClickMediaImportOption,
  onFileAccepted,
  onFileRejected,
  onUrlAccepted,
}) => {
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();
  const [showUpsell, setShowUpsell] = useState<Variant | null>(null);
  const tier = useSelector(tierSelector);
  const modalParams = useSelector(modalParamsSelector);

  const handleFileRejected = useCallback(
    (error, file, reason: RejectedReason) => {
      dispatch(onFileUpload(DropZoneType.EDITOR_MEDIA, file, error.message));

      if (tier === Tier.FREE && reason === RejectedReason.SIZE_LIMIT_EXCEEDED) {
        setShowUpsell('file');
      } else {
        onFileRejected(error, file);
      }
    },
    [dispatch, onFileRejected, tier],
  );

  const handleFileAccepted = async (file: File) => {
    if (await isSupportedImageFile(file)) {
      onFileAccepted(file);
    } else {
      handleFileRejected(
        new ApplicationError('File type not supported', 'IN001'),
        file,
        RejectedReason.UNSUPPORTED_FILE_TYPE,
      );
    }
  };

  const handleUpgradeClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (showUpsell === 'default') {
      dispatch(onUnlockThisFeature('500MB', 'editorMedia'));
    } else {
      dispatch(onIncreaseUploadLimit('editorMedia'));
    }
    dispatch(
      unshiftModal({
        name: 'AddBillingCycle',
        params: {
          tier: Tier.PRO,
          defaultSubscriptionPeriod: 'yearly',
          disableSuccessRedirect: true,
        },
      }),
    );
  };

  const handleInputSubmit = useCallback(async () => {
    if (await isSupportedImageFile(inputValue)) {
      onUrlAccepted(inputValue);
    } else {
      handleFileRejected(
        new ApplicationError('File type not supported', 'IN001'),
        inputValue,
        RejectedReason.UNSUPPORTED_FILE_TYPE,
      );
    }
  }, [handleFileRejected, inputValue, onUrlAccepted]);

  return (
    <div
      className={classNames(
        block('uploader', { upsell: !!showUpsell }),
        className,
      )}
    >
      {showUpsell ? (
        <FileSizeUpsellContents
          onUpgradeClick={handleUpgradeClick}
          variant={showUpsell}
          onCancelClick={() => setShowUpsell(null)}
        />
      ) : (
        <>
          {tier === Tier.FREE && (
            <FileSizeUpsellBanner
              className={block('upsell-banner')}
              onUpgradeClick={event => {
                event.preventDefault();
                dispatch(onIncreaseUploadLimit('editorMedia'));
                setShowUpsell('default');
              }}
            />
          )}
          <FileUploader
            accept={getSupportedMediaMimeTypes().join(',')}
            className={block('dropzone')}
            image={<MediaFile />}
            supportedFileTypes={getSupportedMediaTypes()}
            supportedFileMaxSizeInMb={maxFileSizeMb}
            additionalRestrictions={`${dayjs
              .duration(3600, 'second')
              .format('H [hour]')} max`}
            maxFileSizeMb={maxFileSizeMb}
            onFileAccepted={handleFileAccepted}
            onFileRejected={handleFileRejected}
            templateDimensionsInfo={renderTemplateDimensionsInfoByAspectRatio(
              aspectRatio,
            )}
            defaultFile={modalParams?.file}
            uploadMethod={uploadMethod}
            onClickMediaImportOption={onClickMediaImportOption}
          />
          <UrlImportForm
            onChange={e => setInputValue(e.target.value)}
            onSubmit={handleInputSubmit}
            value={inputValue}
          />
          <div className={block('upload-disclaimer')}>
            By uploading any files and/or images through our service, you
            represent and warrant that you own or otherwise have the valid right
            to use such files and images in connection with the SpareMin
            software and this site, and acknowledge that you have read and
            accepted our&nbsp;
            <TosLink />
          </div>
        </>
      )}
    </div>
  );
};

MediaUploader.defaultProps = {
  onFileAccepted: _.noop,
  onFileRejected: _.noop,
  onUrlAccepted: _.noop,
};

export default MediaUploader;

export { IProps as MediaUploaderProps };
