import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import EpisodeCreationMethodSelectModalContent from './EpisodeCreationMethodSelectModalContent';
import { block } from './utils';

function EpisodeCreationMethodSelectModal() {
  return (
    <ConnectedModal
      name="EpisodeCreationMethodSelect"
      title="create a video for this episode"
      className={block()}
    >
      <EpisodeCreationMethodSelectModalContent />
    </ConnectedModal>
  );
}

export default EpisodeCreationMethodSelectModal;
