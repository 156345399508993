import { fromJS } from 'immutable';
import { RequestStatus } from 'types';

import { Type } from './action-types';
import { stateFactory } from './factories';
import { DownloadAction, DownloadState } from './types';

const defaultState = stateFactory();

export default function reducer(
  state: DownloadState = defaultState,
  action: DownloadAction,
) {
  switch (action.type) {
    case Type.LOAD_DATA_REQUEST:
      return state.set('loadingState', RequestStatus.REQUEST);

    case Type.LOAD_DATA_SUCCESS: {
      const {
        configuration,
        podcastInfo,
        projectId,
        videoDurationMillis,
        videoFrequency,
        podcastEpisodeId,
      } = action.payload;
      return state.withMutations(s => {
        s.set('configuration', fromJS(configuration));
        s.set('podcastInfo', fromJS(podcastInfo));
        s.set('projectId', projectId);
        s.set('videoDurationMillis', videoDurationMillis);
        s.set('videoFrequency', videoFrequency);
        s.set('loadingState', RequestStatus.SUCCESS);
        s.set('podcastEpisodeId', podcastEpisodeId);
        return s;
      });
    }

    case Type.LOAD_DATA_FAILURE:
      return state.set('loadingState', RequestStatus.FAILURE);

    case Type.DOWNLOAD_PAGE_ACTION: {
      return state.set('dpaPerformed', true);
    }

    case Type.DOWNLOAD_CLEAR:
      return defaultState;

    case Type.SOCIAL_POST_CAPTIONS_CREATE_REQUEST: {
      const { podcastEpisodeId } = action.payload;

      return state
        .set('podcastEpisodeId', podcastEpisodeId)
        .set('hasError', false);
    }

    case Type.SOCIAL_POST_CAPTIONS_CREATE_SUCCESS: {
      const { jobId } = action.payload;

      return state.set('jobId', jobId).set('hasError', false);
    }

    case Type.SOCIAL_POST_CAPTIONS_CREATE_FAILURE: {
      return state
        .set('podcastEpisodeId', undefined)
        .set('jobId', undefined)
        .set('isPollingSocialPostCaptions', false)
        .set('hasError', true);
    }

    case Type.SOCIAL_POST_CAPTIONS_POLLING_END: {
      return state
        .set('podcastEpisodeId', undefined)
        .set('jobId', undefined)
        .set('isPollingSocialPostCaptions', false);
    }

    case Type.SOCIAL_POST_CAPTIONS_POLLING_REQUEST: {
      return state
        .set('hasError', false)
        .set('isPollingSocialPostCaptions', true);
    }

    case Type.SOCIAL_POST_CAPTIONS_POLLING_SUCCESS: {
      return state
        .set('hasError', false)
        .set('isPollingSocialPostCaptions', false);
    }

    case Type.SOCIAL_POST_CAPTIONS_POLLING_FAILURE: {
      return state
        .set('hasError', true)
        .set('isPollingSocialPostCaptions', false);
    }

    default:
      return state;
  }
}
