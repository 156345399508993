import { RequestStatus } from 'types';
import { Type } from './action-types';
import { OnboardingAction, OnboardingState, stateFactory } from './types';

const defaultState = stateFactory();

export default function reducer(
  state: OnboardingState = defaultState,
  action: OnboardingAction,
) {
  switch (action.type) {
    case Type.ONBOADING_GET_SUCCESS: {
      const { name, isViewed } = action.payload;
      return state.mergeIn(['onboardingItems'], { [name]: isViewed });
    }

    case Type.ONBOADING_GET_VIEW_CONFIRMATION_SUCCESS: {
      const { name } = action.payload;
      return state.mergeIn(['onboardingItems'], { [name]: true });
    }

    case Type.ONBOADING_CLEAR:
      return defaultState;

    case Type.ONBOARDING_WIZARD_COMPLETE_REQUEST:
      return state.set('status', RequestStatus.REQUEST);

    case Type.ONBOARDING_WIZARD_COMPLETE_SUCCESS:
      return state.set('status', RequestStatus.SUCCESS);

    case Type.ONBOARDING_WIZARD_COMPLETE_FAILURE:
      return state.set('status', RequestStatus.FAILURE);

    default:
      return state;
  }
}
