import { PostStatus } from '../constants';
import { Type } from './action-types';
import { ThreadsAction, ThreadsState, threadsStateFactory } from './types';

const defaultState = threadsStateFactory();

export default function(
  state: ThreadsState = defaultState,
  action: ThreadsAction,
) {
  switch (action.type) {
    case Type.THREADS_AUTHORIZE_SUCCESS: {
      return state.merge(action.payload);
    }

    case Type.THREADS_USER_DATA_GET_SUCCESS: {
      const { id, profileImageUrl, username } = action.payload;

      return state
        .set('threadsUserId', id)
        .set('username', username)
        .set('profileImageUrl', profileImageUrl);
    }

    case Type.THREADS_USER_CLEAR:
      return threadsStateFactory();

    case Type.THREADS_USER_ID_SELECT: {
      return state.set('threadsUserId', action.payload);
    }

    case Type.THREADS_VIDEO_POST_REQUEST:
      return state.set('postStatus', PostStatus.IN_PROGRESS);

    case Type.THREADS_VIDEO_POST_SUCCESS: {
      const { socialShareId } = action.payload;

      return state.set('socialShareId', socialShareId);
    }

    case Type.THREADS_VIDEO_POST_AWAIT_SUCCESS:
      return state.set('postStatus', PostStatus.SUCCESS);

    case Type.THREADS_VIDEO_POST_FAILURE:
    case Type.THREADS_VIDEO_POST_AWAIT_FAILURE:
      return state.set('postStatus', PostStatus.FAILURE);

    default:
      return state;
  }
}
