import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import { Edit } from 'components/icons';
import { automationWorkflowTileBlock as block } from '../../utils';
import useEditAutogramButtonDispatch from './useEditAutogramButtonDispatch';

const ButtonAsAny = Button as any;

export interface EditAutogramButtonProps {
  workflowId: number;
}

const EditAutogramButton: React.FC<EditAutogramButtonProps> = ({
  workflowId,
}) => {
  const { onEdit } = useEditAutogramButtonDispatch();

  const handleEdit = React.useCallback(() => {
    onEdit(workflowId);
  }, [onEdit, workflowId]);

  return (
    <ButtonAsAny
      className={block('edit-button')}
      componentClass={Link}
      onClick={handleEdit}
      theme="submit"
      rel="noopener noreferrer"
      fluid
    >
      <Edit className={block('edit-button-icon')} />
      edit
    </ButtonAsAny>
  );
};

export default EditAutogramButton;
