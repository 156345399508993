import React from 'react';

import LoadingOverlay from 'components/LoadingOverlay';
import { Step } from 'components/SteppedModal';

import { block } from '../utils';

const useVerificationProgressStep = (): Step => {
  return {
    title: 'Verifying YouTube account',
    modalClassName: block('dialog-content'),
    component: <LoadingOverlay small />,
    fluidFooterButtons: true,
    hideFooter: true,
    id: 'verification-in-progress',
  };
};

export default useVerificationProgressStep;
