import { connect } from 'react-redux';

import { userNameSelector } from 'redux/modules/auth/selectors';
import { onClickPostTaskPrompt } from 'redux/modules/mixpanel/actions';
import { Dispatch, State } from 'redux/types';
import PostTaskBanner, { PostTaskBannerProps } from './PostTaskBanner';

type DispatchProps = Pick<PostTaskBannerProps, 'onClick'>;
type StateProps = Pick<PostTaskBannerProps, 'userEmail'>;

const mapStateToProps = (state: State): StateProps => ({
  userEmail: userNameSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onClick: () => {
    dispatch(onClickPostTaskPrompt());
  },
});

const component = connect(mapStateToProps, mapDispatchToProps)(PostTaskBanner);
export type PostTaskProps = React.ComponentPropsWithRef<typeof component>;
export default component;
