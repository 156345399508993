import * as React from 'react';

import useCost from 'hooks/useCost';
import { SubscriptionPeriod } from '../../../types';
import { block } from '../utils';
import BogoText from './BogoText';

export interface BogoDetailsProps {
  subscriptionPeriod: SubscriptionPeriod;
  costCents: number;
}

const BogoDetails: React.SFC<BogoDetailsProps> = ({
  costCents,
  subscriptionPeriod,
}) => {
  const cost = useCost(costCents);

  return (
    <div className={block('bogo')}>
      <div className={block('bogo-primary')}>
        Your total is {cost}{' '}
        <BogoText>
          (FREE {subscriptionPeriod === 'yearly' ? 'YEAR' : 'MONTH'} 🎉)
        </BogoText>
      </div>
      <div className={block('bogo-secondary')}>
        {cost} per {subscriptionPeriod === 'yearly' ? 'year' : 'month'} x 2{' '}
        {subscriptionPeriod === 'yearly' ? 'years' : 'months'}{' '}
        <BogoText>- {cost}</BogoText>
      </div>
    </div>
  );
};

export default BogoDetails;
