import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noop } from 'underscore';

import UseAuthModal from 'blocks/Authentication/containers/UseAuthModal';
import useTemplateThumbnail from 'hooks/useTemplateThumbnail';
import { getUserTemplateCompatibility } from 'redux/middleware/api/headliner-user-service';
import { Tier } from 'redux/middleware/api/plan-service/types';
import { podcastWorkflowTemplateSelector } from 'redux/modules/entities/selectors';
import {
  onClickIncompatibleTemplateLearnMore,
  onClickProTemplate,
} from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal/actions';
import { isCompatibleSelector } from 'redux/modules/pricing/selectors';
import { Dispatch } from 'redux/types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import ProGatedTemplateTile from './ProGatedTemplateTile';
import TemplateSelectorTile from './TemplateSelectorTile';
import { TemplateSelectorTileProps } from './types';

const { useMemo } = React;

export type TemplateSelectorTemplateTileProps = Pick<
  TemplateSelectorTileProps,
  'onClick' | 'id' | 'isCompatible' | 'onDeleteClick'
>;

const TemplateSelectorTemplateTile: React.FC<TemplateSelectorTemplateTileProps> = ({
  id,
  isCompatible,
  onClick = noop,
  onDeleteClick = noop,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const template = useSelector(
    useMemo(() => podcastWorkflowTemplateSelector(id), [id]),
  );
  const {
    isGenerating: isThumbnailGenerating,
    imageUrl: thumbnailUrl,
    progressOverlay,
  } = useTemplateThumbnail({ template });
  const available = useSelector(state =>
    isCompatibleSelector(state, template.compatibleTiers),
  );
  const displayName = template?.displayName;
  const Tile = available ? TemplateSelectorTile : ProGatedTemplateTile;

  const handleClick = () => {
    // availability might change during user authentication
    const isAvailable = dispatch((_, getState) =>
      isCompatibleSelector(getState(), template.compatibleTiers),
    );

    if (isAvailable) {
      onClick(id);
    } else {
      dispatch(onClickProTemplate(id));
      dispatch(
        pushModal({
          name: 'AddBillingCycle',
          params: {
            tier: Tier.PRO,
            defaultSubscriptionPeriod: 'yearly',
            disableSuccessRedirect: true,
          },
        }),
      );
    }
  };

  const handleDeleteClick = async (templateId: string) => {
    const hasDeleted = await dispatch(
      pushModal({ name: 'DeleteTemplate', params: { templateId } }),
    );

    if (hasDeleted) {
      onDeleteClick(templateId);
    }
  };

  const aspectRatioName = useMemo(
    () =>
      getAspectRatioName(
        template?.dimension?.height,
        template?.dimension?.width,
      ),
    [template],
  );

  const onIncompatibleTemplateClick = async (
    templateId: string,
  ): Promise<void> => {
    const {
      response: {
        fullEpisode: { compatibilityDetails },
      },
    } = await dispatch(getUserTemplateCompatibility(templateId));

    dispatch(
      pushModal({
        name: 'FullLengthTemplateCompatibility',
        params: {
          compatibilityDetails,
          templateId,
          aspectRatioName,
          allowRefreshTemplateListFlow: true,
        },
      }),
    );
    dispatch(onClickIncompatibleTemplateLearnMore());
  };

  const background = useMemo(() => {
    const previewVideos = template?.previewVideos;

    if (isThumbnailGenerating) {
      return null;
    }

    if (previewVideos?.length > 0) {
      return {
        thumbnail: thumbnailUrl,
        src: previewVideos.map(video => ({
          type: video.mediaType,
          url: video.videoUrl,
        })),
        type: 'video' as const,
      };
    }

    return { src: thumbnailUrl, type: 'image' as const };
  }, [isThumbnailGenerating, template, thumbnailUrl]);

  return (
    <UseAuthModal origin="audioWizard">
      {({ withAuthentication }) => (
        <Tile
          {...{ aspectRatioName, background, displayName, isCompatible, id }}
          onClick={() => {
            if (!isCompatible) return;

            if (available) {
              handleClick();
            } else {
              withAuthentication(handleClick)();
            }
          }}
          onDeleteClick={handleDeleteClick}
          onIncompatibleTemplateClick={onIncompatibleTemplateClick}
          permanent={template.templateType !== 'userGenerated'}
        >
          {progressOverlay}
        </Tile>
      )}
    </UseAuthModal>
  );
};

export default TemplateSelectorTemplateTile;
