import * as React from 'react';

import { IconProps } from './types';

const ContinuousRightArrow: React.SFC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 44" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M3.577 14.08V6.108C3.53 4.768 5.605 3 7.591 3h29.818A4.09 4.09 0 0 1 41.5 7.09v29.82A4.09 4.09 0 0 1 37.41 41H7.59a4.09 4.09 0 0 1-4.09-4.09V25.955c0-1.505.83-2.405 2.352-2.515l.79-.021 2.082-.016c3.33-.014 9.386-.005 18.142.028a1.5 1.5 0 0 0 .011-3l-15.08-.034-5.37.025c-.184.003-.339.006-.464.01C2.705 20.522.5 22.752.5 25.955v10.953A7.09 7.09 0 0 0 7.59 44h29.82a7.09 7.09 0 0 0 7.09-7.09V7.09A7.09 7.09 0 0 0 37.41 0H7.59C4.025 0 .467 3.032.579 6.162v7.918a1.5 1.5 0 1 0 3 0z" />
      <path d="M26.879 21.993l-6.94 6.947a1.5 1.5 0 0 0 2.122 2.12l8-8.008a1.5 1.5 0 0 0 0-2.121l-8-7.992a1.5 1.5 0 0 0-2.121 2.122l6.939 6.932z" />
    </g>
  </svg>
);

export default ContinuousRightArrow;
