import cn from 'classnames';
import * as React from 'react';

import bem from 'utils/bem';

export interface PermissionNotGrantedMessageProps {
  className?: string;
}

const block = bem('permission-not-granted-message');

const PermissionNotGrantedMessage: React.FC<PermissionNotGrantedMessageProps> = ({
  className,
}) => (
  <div className={cn(block(), className)}>
    We can’t post your video unless you allow us to manage and publish to your
    pages.
  </div>
);

export default PermissionNotGrantedMessage;
