import React from 'react';

import AuthCard from '../components/AuthCard';
import AuthCardBanner from '../components/AuthCardBanner';
import { CreateProfileFormProps } from '../types';
import CreateProfileForm from './CreateProfileForm';

export interface CreateProfileCardProps
  extends Pick<CreateProfileFormProps, 'origin'> {}

const CreateProfileCard: React.FC<CreateProfileCardProps> = ({ origin }) => (
  <AuthCard title="Create profile">
    <CreateProfileForm errorComponent={AuthCardBanner} origin={origin} />
  </AuthCard>
);

export default CreateProfileCard;
