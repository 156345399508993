import cn from 'classnames';
import * as React from 'react';
import ResizeDetector from 'react-resize-detector';

import { useNavigation } from './context/NavigationContext';
import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import EditorCanvas from './EditorCanvas';
import EditorPane from './EditorPane';
import { previewBlock as block } from './utils';

const { useCallback } = React;

export interface PreviewPaneProps {
  className?: string;
  autoplayVideo?: boolean;
  useDefaultVideoStyle?: boolean;
}

/*
 * Column that shows the interactable template preview
 */
const PreviewPane: React.FC<PreviewPaneProps> = ({
  className,
  autoplayVideo,
  useDefaultVideoStyle,
}) => {
  const [, send] = useNavigation();
  const dispatch = useTemplateDispatch();

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const el = e.target as Element;
      const closestAsset =
        el.closest('.rnd-asset') || el.closest('.layer-order-controls');

      // Close the child view if the user clicks anything in the PreviewPane that
      // is not a descendent of a draggable asset or a layer order control.
      if (!closestAsset) {
        send({ type: 'CHILD_VIEW_CLOSE' });
      }
    },
    [send],
  );

  const handleResize = useCallback(
    (width: number, height: number) => {
      dispatch({
        payload: { height, width },
        type: 'CONTAINER_RESIZE',
      });
    },
    [dispatch],
  );

  return (
    <EditorPane
      className={cn(block(), className)}
      onMouseDown={handleMouseDown}
    >
      <ResizeDetector handleHeight handleWidth onResize={handleResize}>
        <EditorCanvas
          autoplayVideo={autoplayVideo}
          useDefaultVideoStyle={useDefaultVideoStyle}
        />
      </ResizeDetector>
    </EditorPane>
  );
};

export default PreviewPane;
