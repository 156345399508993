import React from 'react';

// types
import { IconProps } from './types';

const FooterYoutube: React.FC<IconProps> = ({ ...svgProps }) => (
  <svg
    width="28"
    height="20"
    viewBox="0 0 28 20"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M5.6 2.857h16.8v12.857H5.6z" />
      <path
        d="M27.415 3.13A3.547 3.547 0 0 0 24.939.597C22.756 0 14 0 14 0S5.244 0 3.06.598A3.547 3.547 0 0 0 .586 3.13C0 5.362 0 10.02 0 10.02s0 4.658.585 6.891a3.493 3.493 0 0 0 2.476 2.49C5.244 20 14 20 14 20s8.756 0 10.94-.598a3.493 3.493 0 0 0 2.475-2.49C28 14.677 28 10.02 28 10.02s0-4.658-.585-6.89zM11.136 14.25V5.79l7.318 4.23-7.318 4.23z"
        fill="red"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default FooterYoutube;
