import { createSelector } from 'reselect';
import { socialSharesSelector } from 'redux/modules/entities';
import { stateSelector } from '../selectors';

export const instagramSelector = createSelector(stateSelector, state =>
  state.get('instagram'),
);

export const instagramUserIdSelector = createSelector(
  instagramSelector,
  instagram => instagram.instagramUserId,
);

export const instagramProviderUserIdSelector = createSelector(
  instagramSelector,
  instagram => instagram.providerUserId,
);

export const instagramUserDataSelector = createSelector(
  instagramSelector,
  instagram => ({
    accessToken: instagram.accessToken,
    idToken: instagram.idToken,
    providerUserId: instagram.providerUserId,
    username: instagram.username,
    profileImageUrl: instagram.profileImageUrl,
  }),
);

export const instagramSocialShareIdSelector = createSelector(
  instagramSelector,
  instagram => instagram.socialShareId,
);

export const instagramSocialSharesSelector = createSelector(
  instagramSocialShareIdSelector,
  socialSharesSelector,
  (id, shares) =>
    !id || !shares || shares.size === 0
      ? undefined
      : shares.get(String(id))?.toJS(),
);

export const instagramAccountsSelector = createSelector(
  instagramSelector,
  instagram => instagram.accounts,
);

export const hasInstagramAccountsSelector = createSelector(
  instagramAccountsSelector,
  accounts => !!accounts.length,
);

export const instagramAccountsLoadingSelector = createSelector(
  instagramSelector,
  instagram => instagram.accountsLoading,
);

export const instagramPostStatusSelector = createSelector(
  instagramSelector,
  instagram => instagram.postStatus,
);

export const isInstagramAccountInfoLoadingSelector = createSelector(
  instagramSelector,
  instagram => instagram.accountInfoLoading,
);

export const instagramAccountInfoSelector = createSelector(
  instagramSelector,
  instagram => instagram.accountInfo,
);

export const isInstagramBusinessAccountSelector = createSelector(
  instagramAccountInfoSelector,
  instagramAccountInfo => instagramAccountInfo?.isBusinessAccount,
);
