import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AutogramWizardStepIndex } from 'blocks/AutomationWorkflowWizard';
import ConfirmationModal from 'components/ConfirmationModal';
import FontAwesome from 'components/FontAwesome';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import Modal from 'components/Modal';
import { onPodcastConnectedModal } from 'redux/modules/mixpanel/actions';
import { selectedPodcastIdSelector } from 'redux/modules/podcast-search';

interface Props {
  onHide?: () => void;
}

function PodcastConnectedModalContent(props: Props) {
  const { onHide } = props;
  const dispatch = useDispatch();
  const selectedPodcastId = useSelector(selectedPodcastIdSelector);

  const handleAutomaticCreateClick = () => {
    dispatch(onPodcastConnectedModal('SetupAutomation'));
    onHide?.();
  };

  const handleCreateClick = () => {
    dispatch(onPodcastConnectedModal('CreateANewVideo'));
    onHide?.();
  };

  return (
    <>
      <ConfirmationModal.Body>
        <ConfirmationModal.Icon>
          <CircularGradient>
            <FontAwesome icon="microphone" size="3x" />
          </CircularGradient>
        </ConfirmationModal.Icon>
        <ConfirmationModal.Title>
          Podcast added, let’s promote it!
        </ConfirmationModal.Title>
        <ConfirmationModal.Content>
          <strong>
            Increase listens by turning some audio clips into video trailers,
          </strong>
          <br />
          <strong>commonly known as ‘audiograms’ or ‘Headliners’.</strong>
        </ConfirmationModal.Content>
      </ConfirmationModal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton
            theme="submit-alt"
            onClick={handleCreateClick}
            componentClass={Link}
            // @ts-ignore
            to="/wizard?type=audiogram"
          >
            <strong>Create a video manually</strong>
          </Modal.FooterButton>
          <Modal.FooterButton
            theme="submit"
            onClick={handleAutomaticCreateClick}
            componentClass={Link}
            // @ts-ignore
            to={`/wizard?type=autovideo&initialStep=${AutogramWizardStepIndex.LANGUAGE}&podcastId=${selectedPodcastId}`}
          >
            <strong>Let us create your videos</strong>
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
}

export default PodcastConnectedModalContent;
