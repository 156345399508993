import React from 'react';

import { ActionStepCard } from 'components/ActionStepCard';
import { PodcastSubscription } from 'redux/middleware/api/podcast-service';

interface EditAutomationInputProps {
  subscription?: PodcastSubscription;
}

const EditAutomationInput: React.FunctionComponent<EditAutomationInputProps> = props => {
  const { subscription } = props;

  return (
    <ActionStepCard
      description={subscription?.title}
      image={subscription?.thumbnailUrl}
      title="Input"
    />
  );
};

export default EditAutomationInput;
