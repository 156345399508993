import * as React from 'react';

import { block } from './utils';

export interface HeadlinerSocialButtonProps {
  icon: JSX.Element;
  name: string;
  url: string;
}

const HeadlinerSocialButton: React.SFC<HeadlinerSocialButtonProps> = ({
  icon,
  name,
  url,
}) => (
  <a
    className={block('social-button')}
    href={url}
    rel="noopener noreferrer"
    target="_blank"
  >
    {icon}
    <span className={block('social-button-label')}>{name}</span>
  </a>
);

export default HeadlinerSocialButton;
