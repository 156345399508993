import * as React from 'react';

import AudioLibrary, { AudioLibraryProps } from 'components/AudioLibrary';

export interface IContainerProps extends AudioLibraryProps {
  onLoad: () => void;
}

export default class SampleAudioLibrary extends React.Component<
  IContainerProps
> {
  public componentDidMount() {
    const { libraryIsFetching, audios, onLoad } = this.props;
    if (!libraryIsFetching && !audios) onLoad();
  }

  public render() {
    const { onLoad, ...rest } = this.props;
    return <AudioLibrary {...rest} />;
  }
}
