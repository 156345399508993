import React from 'react';

import { IntroOutro } from 'components/icons';

import { useNavigation } from '../context/NavigationContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import OptionChildView from '../OptionChildView';
import TileGrid from '../TileGrid';
import { IntroOutroData, IntroOutroType } from '../types';
import { introOutroChildViewBlock as block } from '../utils';
import IntroOutroDelete from './IntroOutroDelete';
import IntroOutroReplace from './IntroOutroReplace';

interface IntroOutroEditChildViewProps {
  active: boolean;
  onClose: () => void;
}

const BACKGROUND_GRADIENT =
  'linear-gradient(to bottom, rgba(50, 55, 50, 0.75), #323746)';

const IntroOutroEditChildView: React.FunctionComponent<IntroOutroEditChildViewProps> = props => {
  const { active, onClose } = props;

  const [{ context }] = useNavigation();
  const { introOutro } = useTemplateState();

  const type = context?.id as IntroOutroType;
  const target = introOutro?.[type] as IntroOutroData | undefined;

  // As there are some cases where the file was pre-uploaded (for example at enterprise)
  // setups a fallback for filename is used (by setting it to the current type: intro/outro)
  const title = target?.fileName ?? type;
  const thumbnailUrl = target?.thumbnailUrl;
  const thumbnailStyle = thumbnailUrl
    ? `url(${thumbnailUrl}) center center / cover no-repeat`
    : '';

  return (
    <OptionChildView
      active={active}
      bodyClassName={block('body')}
      className={block()}
      icon={<IntroOutro height={23} width={30} />}
      onClose={onClose}
      title={title}
    >
      <div
        className={block('background-image')}
        style={{
          background: [BACKGROUND_GRADIENT, thumbnailStyle]
            .filter(Boolean)
            .join(','),
        }}
      />
      <TileGrid>
        <IntroOutroDelete type={type} />
        <IntroOutroReplace type={type} />
      </TileGrid>
    </OptionChildView>
  );
};

export default IntroOutroEditChildView;
