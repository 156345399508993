import * as React from 'react';

import { IconProps } from './types';

const InfinityIcon: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="9"
    viewBox="0 0 15 9"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M11.041.75C9.492.75 8.281 1.783 7.5 2.715 6.72 1.783 5.508.75 3.959.75 1.777.75 0 2.545 0 4.75s1.777 4 3.959 4c1.549 0 2.76-1.033 3.541-1.965.78.932 1.992 1.965 3.541 1.965 2.182 0 3.959-1.795 3.959-4s-1.777-4-3.959-4zM3.96 6.35c-.943 0-1.709-.717-1.709-1.6 0-.883.766-1.6 1.709-1.6.895 0 1.72.903 2.203 1.6-.478.69-1.31 1.6-2.203 1.6zm7.082 0c-.895 0-1.72-.902-2.203-1.6.478-.69 1.31-1.6 2.203-1.6.943 0 1.709.717 1.709 1.6 0 .883-.766 1.6-1.709 1.6z"
    />
  </svg>
);

export default InfinityIcon;
