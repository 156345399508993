import * as React from 'react';

import { Omit } from 'types';
import TextBoxColorField, { TextBoxColorFieldProps } from './TextBoxColorField';

type Props = Omit<TextBoxColorFieldProps, 'label'>;

const BackgroundField: React.SFC<Props> = props => (
  <TextBoxColorField label="Background" {...props} />
);

export default BackgroundField;
export { Props as BackgroundFieldProps };
