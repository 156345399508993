import React from 'react';
import BemCssTransition, {
  BemCssTransitionProps,
} from 'components/BemCssTransition';
import bem from 'utils/bem';

const block = bem('progress-overlay');

type Props = Pick<BemCssTransitionProps, 'in'>;

function ProgressOverlay(props: Props) {
  return (
    <BemCssTransition
      {...props}
      className={block()}
      mountOnEnter
      unmountOnExit
      timeout={400}
    >
      <div />
    </BemCssTransition>
  );
}

export default ProgressOverlay;
