import * as React from 'react';
import { noop } from 'underscore';

import { cardWizardBlock } from '../../utils';
import StepperIndicator from './StepperIndicator';

export interface CardWizardStepperProps {
  onStepClick?: (step: number) => void;
  step: number;
  totalSteps: number;
}

const CardWizardStepper: React.FC<CardWizardStepperProps> = ({
  onStepClick,
  step,
  totalSteps,
}) => (
  <div className={cardWizardBlock('stepper')}>
    {Array(totalSteps)
      .fill(0)
      .map((_, index) => (
        <StepperIndicator
          active={step === index}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          completed={step > index}
          onClick={onStepClick}
          params={index}
        />
      ))}
  </div>
);

CardWizardStepper.defaultProps = {
  onStepClick: noop,
};

export default CardWizardStepper;
