import * as React from 'react';

import PodcastSearch from 'blocks/PodcastSearch';
import { PodcastEpisodeData } from 'blocks/PodcastSearch/types';
import useDefaultIntegration, {
  UseDefaultIntegrationConfig,
} from 'blocks/PodcastSearch/useDefaultIntegration';
import useRssIntegration from 'blocks/PodcastSearch/useRssIntegration';
import { addPodcastStepBlock as block } from './utils';

const { useEffect, useRef } = React;

type PickedSearchProps = Pick<UseDefaultIntegrationConfig, 'onPodcastClick'>;

export interface AddPodcastStepProps
  extends Pick<PickedSearchProps, 'onPodcastClick'> {
  onEpisodeClick?: (episode: PodcastEpisodeData) => void;
}

const AddPodcastStep: React.FC<AddPodcastStepProps> = ({
  onPodcastClick,
  onEpisodeClick,
}) => {
  const search = useRef(null);

  useEffect(() => {
    search.current?.focus();
  }, []);

  return (
    <div className={block()}>
      <PodcastSearch
        className={block('add-podcast-search')}
        integrations={[
          useDefaultIntegration({
            episodeExplorerEnabled: false,
            onPodcastClick,
            onEpisodeClick,
            priority: 1,
          }),
          useRssIntegration({
            episodeExplorerEnabled: false,
            onPodcastClick,
            onEpisodeClick,
            priority: 0,
          }),
        ]}
        ref={search}
      />
    </div>
  );
};

export default AddPodcastStep;
