import cn from 'classnames';
import * as React from 'react';

import bem from 'utils/bem';
import { STATIC_TIMER_STYLE } from 'utils/embed/timer';
import { Timer } from './icons';

export interface TimerPreviewProps {
  className?: string;
  color: string;
  fontSize: string | number;
  style?: React.CSSProperties;
}

const block = bem('timer-preview');

const TimerPreview: React.FC<TimerPreviewProps> = ({
  className,
  color,
  fontSize,
}) => {
  return (
    <div className={cn(block(), className)} style={STATIC_TIMER_STYLE}>
      <Timer style={{ color, height: fontSize, width: 'auto' }} />
    </div>
  );
};

export default TimerPreview;
