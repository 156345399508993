import * as React from 'react';
import ToggleField from 'components/Form/ToggleField';
import { ClosedCaptioning } from 'components/icons';
import TranscriptionFormContext from './TranscriptionFormContext';
import { block } from './utils';

type Props = Pick<React.ComponentProps<typeof ToggleField>, 'label'> & {
  icon?: React.ReactNode;
};

const { useContext, useCallback } = React;

const TranscriptionToggle: React.FC<Props> = props => {
  const {
    icon = <ClosedCaptioning width={29} />,
    label = 'Add captions to video',
  } = props;
  const { value, onChange, disabled } = useContext(TranscriptionFormContext);

  const handleTranscribeChange = useCallback(
    (transcribe: boolean) => {
      onChange({ ...value, transcribe });
    },
    [onChange, value],
  );

  return (
    <ToggleField
      checked={value.transcribe}
      className={block('toggle-field')}
      disabled={disabled}
      horizontal={{
        control: { xs: 4 },
        label: { xs: 8 },
      }}
      label={
        <>
          {icon}
          <span className={block('label-text')}>{label}</span>
        </>
      }
      labelClassName={block('label')}
      onChange={handleTranscribeChange}
    />
  );
};

TranscriptionToggle.displayName = 'Transcription.Toggle';

export default TranscriptionToggle;
