import * as React from 'react';
import EpisodeRowCameraIcon from 'blocks/AutomationCMS/components/EpisodeRowCameraIcon';
import { episodeListBlock as block } from '../../utils';
import EpisodeRowActionButton, {
  EpisodeRowActionButtonProps,
} from './EpisodeRowActionButton';

export interface EpisodeNewVideoButtonProps
  extends Pick<EpisodeRowActionButtonProps, 'onClick'> {}

const EpisodeNewVideoButton: React.FC<EpisodeNewVideoButtonProps> = ({
  onClick,
}) => (
  <EpisodeRowActionButton
    className={block('new-video-button')}
    onClick={onClick}
  >
    <EpisodeRowActionButton.Icon>
      <EpisodeRowCameraIcon className={block('new-video-button-icon')} />
    </EpisodeRowActionButton.Icon>
    <EpisodeRowActionButton.Label className={block('new-video-button-label')}>
      new video
    </EpisodeRowActionButton.Label>
  </EpisodeRowActionButton>
);

export default EpisodeNewVideoButton;
