import * as React from 'react';
import { connect } from 'react-redux';

import { onClickReferralSend } from 'redux/modules/mixpanel/actions';
import { showNotification, showSuccess } from 'redux/modules/notification';
import { isSendingInvitesSelector } from 'redux/modules/pricing';
import { sendReferralEmailInvites } from 'redux/modules/pricing/actions';
import { Dispatch, State } from 'redux/types';
import EmailInvitationForm, {
  EmailInvitationFormProps as Props,
} from './EmailInvitationForm';

type DispatchProps = Pick<Props, 'onSubmit'>;
type StateProps = Pick<Props, 'disabled'>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSubmit: async emails => {
    dispatch(onClickReferralSend());

    try {
      await dispatch(sendReferralEmailInvites(emails));
      dispatch(
        showSuccess(
          'Your invites were sent',
          10,
          undefined,
          "You'll receive free time when they’re accepted",
        ),
      );
    } catch {
      dispatch(
        showNotification({
          message: 'Please try again or {{link}} so we can help',
          code: 'ER013',
          dismissAfterSec: 10,
          level: 'error',
          type: 'help',
          title: "Sorry, we couldn't send your invites",
          actionLabel: 'contact us',
        }),
      );
    }
  },
});

const mapStateToProps = (state: State): StateProps => ({
  disabled: isSendingInvitesSelector(state),
});

const component = connect<
  StateProps,
  DispatchProps,
  React.RefAttributes<HTMLTextAreaElement>
>(mapStateToProps, mapDispatchToProps, undefined, { forwardRef: true })(
  EmailInvitationForm,
);

export type EmailInvitatinoFormProps = React.ComponentPropsWithoutRef<
  typeof component
>;

export default component;
