import * as React from 'react';

import { ReClipPostTaskBanner as ReClipPostTaskBannerIcon } from 'components/icons';
import PostTaskBannerLink, {
  PostTaskBannerLinkProps,
} from '../../components/PostTaskBannerLink';

export interface Props extends Pick<PostTaskBannerLinkProps, 'onClick'> {
  autoCreateEpisodeId: string;
  widgetId: string;
}

const ReClipPostTaskBanner: React.SFC<Props> = ({
  autoCreateEpisodeId,
  onClick,
  widgetId,
}) => (
  <PostTaskBannerLink
    href={`https://www.headliner.app/clip-feedback/?widgetId=${widgetId}&autoCreateEpisodeId=${autoCreateEpisodeId}`}
    image={<ReClipPostTaskBannerIcon />}
    onClick={onClick}
  />
);

export default ReClipPostTaskBanner;
