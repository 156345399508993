import React from 'react';
import FontAwesome from 'components/FontAwesome';
import CircularGradient from './CircularGradient';

export interface CircularGradientLeftQuoteProps {
  className?: string;
}

const CircularGradientLeftQuote: React.FC<CircularGradientLeftQuoteProps> = ({
  className,
}) => (
  <CircularGradient className={className}>
    <FontAwesome icon="quote-left" style={{ height: 'auto', width: '35%' }} />
  </CircularGradient>
);

export default CircularGradientLeftQuote;
