import * as React from 'react';

import { IconProps } from 'components/icons/types';

/* tslint:disable-next-line:variable-name */
const LinkCircleIcon: React.SFC<IconProps> = (props: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="22.5" cy="22.5" r="22.5" fill="#C82828" />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M25.432 20.468a5.34 5.34 0 0 1 0 7.557l-2.362 2.362a5.35 5.35 0 0 1-7.557 0 5.35 5.35 0 0 1 0-7.557l1.304-1.304a.562.562 0 0 1 .96.373c.023.623.134 1.249.34 1.853.07.205.02.431-.132.584l-.46.46c-.986.985-1.017 2.59-.041 3.585a2.534 2.534 0 0 0 3.597.018l2.363-2.363a2.53 2.53 0 0 0-.364-3.88.564.564 0 0 1-.244-.444 1.4 1.4 0 0 1 .411-1.048l.74-.74a.565.565 0 0 1 .724-.06c.258.18.5.381.721.604zm4.955-4.955a5.35 5.35 0 0 0-7.557 0l-2.362 2.362a5.341 5.341 0 0 0 .721 8.162c.225.157.53.133.724-.061l.74-.74a1.4 1.4 0 0 0 .411-1.048.564.564 0 0 0-.244-.443 2.53 2.53 0 0 1-.364-3.881L24.82 17.5a2.534 2.534 0 0 1 3.597.018c.976.995.945 2.6-.04 3.585l-.46.46a.566.566 0 0 0-.134.584c.207.604.318 1.23.341 1.853a.562.562 0 0 0 .96.373l1.304-1.304a5.35 5.35 0 0 0 0-7.557z"
      />
    </g>
  </svg>
);

export default LinkCircleIcon;
