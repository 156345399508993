import cn from 'classnames';
import * as React from 'react';
import Actions from './BannerActions';
import Content from './BannerContent';
import { BannerProvider } from './BannerContext';
import Title from './BannerTitle';
import { BannerSize } from './types';
import { block } from './utils';

interface Props {
  icon: React.ReactNode;
  className?: string;
  size?: BannerSize;
  theme?: 'rainbow' | 'primary' | 'danger' | 'info' | 'blank';
}

interface IBanner extends React.FC<Props> {
  Actions: typeof Actions;
  Content: typeof Content;
  Title: typeof Title;
}

const Banner: IBanner = props => {
  const { icon, children, className, size = 'large', theme } = props;
  return (
    <BannerProvider size={size}>
      <section
        className={cn(block({ [theme]: !!theme, [size]: true }), className)}
      >
        <div className={block('icon')}>{icon}</div>
        {children}
      </section>
    </BannerProvider>
  );
};

Banner.Actions = Actions;
Banner.Content = Content;
Banner.Title = Title;

export default Banner;
