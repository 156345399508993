import * as React from 'react';

import PaidSubscription, {
  educationFeatures,
  PaidSubscriptionHeader,
} from 'blocks/Pricing/components/PaidSubscription';
import { H2, H3 } from 'components/Heading';
import EducationIcon from 'components/icons/EducationIcon';
import { Tier } from 'redux/middleware/api/plan-service/types';
import HeadlinerSocialButtons from '../components/ProEducationSubscription/HeadlinerSocialButtons';
import TierUpgradeBanner from './TierUpgradeBanner';

export interface Props {}

const EducationSubscription: React.FC<Props> = () => (
  <PaidSubscription>
    <PaidSubscriptionHeader icon={<EducationIcon />} title="EDU / NPO">
      <PaidSubscriptionHeader.Features features={educationFeatures} />
      <TierUpgradeBanner
        source="EDUMyPlanPage"
        modal="AddBillingCycle"
        targetTier={Tier.PRO}
      />
    </PaidSubscriptionHeader>
    <PaidSubscription.Body>
      <H3>You’re on our education / nonprofit tier!</H3>
      <H2>
        Enjoy this free tier and please share your awesome creations with us:
      </H2>
      <HeadlinerSocialButtons />
    </PaidSubscription.Body>
  </PaidSubscription>
);

export default EducationSubscription;
