import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import _ from 'underscore';

import { Omit } from 'types';
import { getDisplayName } from 'utils/react';

export interface WithWindowResizeProps {
  windowSize?: {
    height?: number;
    width?: number;
  };
}

interface IUiState {
  height: number;
  width: number;
}

interface IState {
  ui: RecordOf<IUiState>;
}

const uiFactory = Record<IUiState>({
  height: undefined,
  width: undefined,
});

export type WithWindowResizeWrapper<
  Props extends WithWindowResizeProps
> = React.ComponentClass<Omit<Props, keyof WithWindowResizeProps>>;

export default (timeout: number = 500) => <Props extends WithWindowResizeProps>(
  WrappedComponent: React.ComponentClass<Props>,
) => {
  type WrapperProps = Omit<Props, keyof WithWindowResizeProps>;

  return class WithWindowResize extends React.Component<WrapperProps, IState> {
    public static displayName = `WithWindowResize(${getDisplayName(
      WrappedComponent,
    )})`;

    private instance: React.Component<Props>;

    constructor(props: Props) {
      super(props);

      this.state = {
        ui: uiFactory({
          height: undefined,
          width: undefined,
        }),
      };
    }

    public componentDidMount() {
      window.addEventListener('resize', this.debouncedHandleResize);
      this.setState(({ ui }) => ({
        ui: ui.withMutations(u =>
          u.set('height', window.innerHeight).set('width', window.innerWidth),
        ),
      }));
    }

    public componentWillUnmount() {
      window.removeEventListener('resize', this.debouncedHandleResize);
    }

    /**
     * useful if you have have to call the API of the WrappedComponent via a ref. When using a
     * component that is wrapped in WithWindowResize, the WrappedComponent API will be hidden
     * behind that of WithWindowResize.  In order to access the WrappedComponent instance, use
     * something like `this.wrappedComponentRef.getInstance().wrappedComponentAPIFn()`
     */
    public getInstance() {
      return this.instance;
    }

    private debouncedHandleResize = _.debounce(
      e => this.handleResize(e),
      timeout,
    );

    private handleResize = (event: UIEvent) => {
      const height = (event.target as Window).innerHeight;
      const width = (event.target as Window).innerWidth;

      this.setState(({ ui }) => ({
        ui: ui.withMutations(u => u.set('height', height).set('width', width)),
      }));
    };

    private createProps(): WithWindowResizeProps {
      const { ui } = this.state;

      if (typeof ui.width === 'undefined' || typeof ui.height === 'undefined') {
        return undefined;
      }

      return {
        windowSize: {
          height: ui.height,
          width: ui.width,
        },
      };
    }

    public render() {
      /*
       * create a ref to the underlying component so that its API / properties can be accessed via
       * getInstance() call on a ref to this HOC
       */
      const ref = (element: React.Component<Props>) => {
        this.instance = element;
      };

      return (
        <WrappedComponent
          {..._.extend({}, this.props)}
          {...this.createProps()}
          ref={ref}
        />
      );
    }
  };
};
