import * as React from 'react';

import { WaveformProps } from '../../types';

const Burst = React.forwardRef<SVGSVGElement, WaveformProps>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    ref={ref}
    {...props}
  >
    <path d="M23.992 16.116h7.845v-0.231h-7.845z" />
    <path d="M24.143 15.984c0 0.090-0.070 0.163-0.157 0.163s-0.157-0.073-0.157-0.163c-0-0.090 0.070-0.163 0.157-0.164s0.157 0.073 0.157 0.163zM31.843 15.984h0.157c0 0.090-0.070 0.163-0.157 0.163s-0.157-0.073-0.157-0.163c-0-0.090 0.070-0.163 0.157-0.164s0.157 0.073 0.157 0.163z" />
    <path d="M23.95 16.753l6.635 0.523 0.018-0.23-6.635-0.523z" />
    <path d="M24.11 16.634c0 0.088-0.070 0.16-0.157 0.16s-0.157-0.071-0.157-0.16c-0-0.088 0.070-0.16 0.157-0.16s0.157 0.071 0.157 0.159zM30.601 17.164h0.157c0 0.088-0.070 0.16-0.157 0.16s-0.157-0.071-0.157-0.16c-0-0.088 0.070-0.16 0.157-0.16s0.157 0.071 0.157 0.159z" />
    <path d="M23.876 17.373l5.622 0.883 0.036-0.228-5.622-0.883z" />
    <path d="M24.045 17.253c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.157zM29.522 18.147h0.157c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.157z" />
    <path d="M23.769 17.992l6.243 1.504 0.054-0.225-6.243-1.504z" />
    <path d="M23.946 17.874c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.071 0.157 0.158zM30.045 19.388h0.157c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.071 0.157 0.158z" />
    <path d="M23.564 18.578l6.472 2.092 0.071-0.22-6.472-2.092z" />
    <path d="M23.782 18.462c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM30.078 20.566h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M23.359 19.163l6.21 2.582 0.089-0.213-6.21-2.582z" />
    <path d="M23.554 19.082c0 0.086-0.070 0.156-0.157 0.156s-0.157-0.070-0.157-0.156c-0-0.086 0.070-0.156 0.157-0.156s0.157 0.070 0.157 0.156zM29.62 21.646h0.157c0 0.086-0.070 0.156-0.157 0.156s-0.157-0.070-0.157-0.156c-0-0.086 0.070-0.156 0.157-0.156s0.157 0.070 0.157 0.156z" />
    <path d="M23.090 19.747l5.459 2.778 0.105-0.206-5.459-2.778z" />
    <path d="M23.293 19.639c0 0.087-0.070 0.157-0.157 0.158s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.157zM28.607 22.429h0.157c0 0.087-0.070 0.157-0.157 0.158s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.157z" />
    <path d="M22.755 20.299l5.132 3.138 0.12-0.197-5.132-3.138z" />
    <path d="M22.966 20.194c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM27.953 23.344h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M22.388 20.817l4.74 3.432 0.135-0.187-4.74-3.432z" />
    <path d="M22.607 20.717c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.158-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.158 0.070 0.158 0.157zM27.201 24.162h0.158c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.158-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.158 0.070 0.158 0.157z" />
    <path d="M22.021 21.268l5.034 4.315 0.15-0.175-5.034-4.315z" />
    <path d="M22.247 21.207c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM27.136 25.502h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M21.557 21.72l4.772 4.772 0.163-0.163-4.772-4.772z" />
    <path d="M21.821 21.664c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM26.417 26.417h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M21.093 22.171l4.282 5.001 0.175-0.15-4.282-5.001z" />
    <path d="M21.332 22.090c0 0.087-0.070 0.157-0.157 0.157s-0.158-0.070-0.158-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.158 0.070 0.158 0.157zM25.469 27.103h0.158c0 0.087-0.070 0.157-0.157 0.157s-0.158-0.070-0.158-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.158 0.070 0.158 0.157z" />
    <path d="M20.597 22.556l3.792 5.197 0.187-0.136-3.792-5.197z" />
    <path d="M20.873 22.482c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM24.489 27.692h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M20.069 22.875l3.563 5.818 0.197-0.121-3.563-5.818z" />
    <path d="M20.35 22.809c0 0.087-0.070 0.157-0.156 0.157s-0.156-0.070-0.156-0.157c-0-0.087 0.070-0.157 0.156-0.157s0.156 0.070 0.157 0.157zM23.737 28.607h0.156c0 0.087-0.070 0.157-0.156 0.157s-0.156-0.070-0.156-0.157c-0-0.087 0.070-0.157 0.156-0.157s0.156 0.070 0.157 0.157z" />
    <path d="M19.542 23.194l2.615 5.132 0.206-0.105-2.615-5.132z" />
    <path d="M19.796 23.136c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.158 0.157zM22.265 28.28h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.158 0.157z" />
    <path d="M18.949 23.447l2.288 5.557 0.214-0.088-2.288-5.557z" />
    <path d="M19.207 23.397c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM21.351 28.934h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M18.358 23.635l1.732 5.328 0.22-0.071-1.732-5.328z" />
    <path d="M18.62 23.593c0 0.087-0.071 0.157-0.158 0.157s-0.158-0.070-0.158-0.157c-0-0.087 0.071-0.157 0.158-0.157s0.158 0.070 0.158 0.157zM20.206 28.934h0.158c0 0.087-0.071 0.157-0.158 0.157s-0.158-0.070-0.158-0.157c-0-0.087 0.071-0.157 0.158-0.157s0.158 0.070 0.158 0.157z" />
    <path d="M17.767 23.823l1.504 6.243 0.225-0.054-1.504-6.243z" />
    <path d="M18.033 23.79c0 0.087-0.071 0.157-0.158 0.157s-0.158-0.070-0.159-0.157c-0-0.087 0.071-0.157 0.158-0.157s0.159 0.070 0.159 0.157zM19.388 30.045h0.158c0 0.087-0.071 0.157-0.158 0.157s-0.158-0.070-0.159-0.157c-0-0.087 0.071-0.157 0.158-0.157s0.159 0.070 0.159 0.157z" />
    <path d="M17.144 23.912l0.883 5.589 0.228-0.036-0.883-5.589z" />
    <path d="M17.412 23.92c0 0.087-0.071 0.157-0.159 0.157s-0.159-0.070-0.159-0.157c-0-0.087 0.071-0.157 0.158-0.157s0.159 0.070 0.159 0.157zM18.146 29.49h0.159c0 0.087-0.071 0.157-0.159 0.157s-0.159-0.070-0.159-0.157c-0-0.087 0.071-0.157 0.158-0.157s0.159 0.070 0.159 0.157z" />
    <path d="M16.522 24.001l0.49 6.178 0.23-0.018-0.49-6.178z" />
    <path d="M16.782 23.986c0 0.087-0.069 0.157-0.154 0.157s-0.154-0.070-0.154-0.157c-0-0.087 0.069-0.157 0.154-0.157s0.154 0.070 0.154 0.157zM17.105 30.176h0.154c0 0.087-0.069 0.157-0.154 0.157s-0.154-0.070-0.154-0.157c-0-0.087 0.069-0.157 0.154-0.157s0.154 0.070 0.154 0.157z" />
    <path d="M15.884 23.992v7.845h0.231v-7.845z" />
    <path d="M16.147 23.986c0 0.087-0.073 0.157-0.163 0.157s-0.163-0.070-0.163-0.157c-0-0.087 0.073-0.157 0.163-0.157s0.163 0.070 0.164 0.157zM15.984 31.843h0.163c0 0.087-0.073 0.157-0.163 0.157s-0.163-0.070-0.163-0.157c-0-0.087 0.073-0.157 0.163-0.157s0.163 0.070 0.164 0.157z" />
    <path d="M15.247 23.95l-0.556 7.126 0.23 0.018 0.556-7.126z" />
    <path d="M15.526 23.985c0 0.087-0.071 0.157-0.158 0.157s-0.159-0.070-0.159-0.157c-0-0.087 0.071-0.157 0.158-0.157s0.159 0.070 0.159 0.157zM14.802 31.091h0.159c0 0.087-0.071 0.157-0.158 0.157s-0.159-0.070-0.159-0.157c-0-0.087 0.071-0.157 0.158-0.157s0.159 0.070 0.159 0.157z" />
    <path d="M14.628 23.875l-1.079 6.733 0.228 0.037 1.079-6.733z" />
    <path d="M14.905 23.888c0 0.087-0.070 0.157-0.156 0.157s-0.156-0.070-0.156-0.157c-0-0.087 0.070-0.157 0.156-0.157s0.156 0.070 0.156 0.157zM13.688 30.633h0.156c0 0.087-0.070 0.157-0.156 0.157s-0.156-0.070-0.156-0.157c-0-0.087 0.070-0.157 0.156-0.157s0.156 0.070 0.156 0.157z" />
    <path d="M14.008 23.736l-1.406 5.884 0.225 0.054 1.406-5.884z" />
    <path d="M14.284 23.789c0 0.087-0.071 0.157-0.158 0.157s-0.158-0.070-0.158-0.157c-0-0.087 0.071-0.157 0.158-0.157s0.158 0.070 0.158 0.157zM12.709 29.653h0.158c0 0.087-0.071 0.157-0.158 0.157s-0.158-0.070-0.158-0.157c-0-0.087 0.071-0.157 0.158-0.157s0.158 0.070 0.158 0.157z" />
    <path d="M13.422 23.564l-1.961 5.982 0.22 0.072 1.961-5.982z" />
    <path d="M13.696 23.593c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM11.597 29.587h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M12.837 23.359l-2.517 6.047 0.213 0.089 2.517-6.047z" />
    <path d="M13.107 23.397c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM10.453 29.456h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M12.253 23.089l-3.171 6.21 0.206 0.105 3.171-6.21z" />
    <path d="M12.519 23.136c0 0.087-0.070 0.157-0.156 0.157s-0.156-0.070-0.156-0.157c-0-0.087 0.070-0.157 0.156-0.157s0.156 0.070 0.157 0.157zM9.211 29.358h0.156c0 0.087-0.070 0.157-0.156 0.157s-0.156-0.070-0.156-0.157c-0-0.087 0.070-0.157 0.156-0.157s0.156 0.070 0.157 0.157z" />
    <path d="M11.734 22.755l-3.922 6.407 0.197 0.121 3.922-6.407z" />
    <path d="M11.996 22.841c0 0.087-0.070 0.157-0.158 0.157s-0.158-0.070-0.158-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.158 0.070 0.158 0.157zM7.904 29.228h0.158c0 0.087-0.071 0.157-0.157 0.157s-0.158-0.070-0.158-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.158 0.070 0.158 0.157z" />
    <path d="M11.216 22.388l-3.922 5.393 0.187 0.136 3.922-5.393z" />
    <path d="M11.44 22.482c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM7.38 27.856h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M10.698 21.989l-3.661 4.315 0.176 0.149 3.661-4.315z" />
    <path d="M10.95 22.089c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM7.119 26.385h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M10.247 21.589l-3.955 3.955 0.163 0.163 3.955-3.955z" />
    <path d="M10.492 21.665c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM6.368 25.632h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M9.829 21.093l-4.772 4.086 0.15 0.175 4.772-4.086z" />
    <path d="M10.067 21.207c0 0.087-0.070 0.157-0.157 0.157s-0.158-0.070-0.158-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.158 0.070 0.158 0.157zM5.126 25.273h0.157c0 0.087-0.071 0.157-0.157 0.157s-0.157-0.070-0.158-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.158 0.070 0.158 0.157z" />
    <path d="M9.444 20.597l-5.034 3.661 0.136 0.187 5.034-3.661z" />
    <path d="M9.675 20.685c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.070-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.157zM4.472 24.357h0.157c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.070-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.157z" />
    <path d="M9.125 20.069l-5.557 3.399 0.121 0.197 5.557-3.399z" />
    <path d="M9.316 20.162c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.070-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.157zM3.622 23.573h0.157c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.070-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.157z" />
    <path d="M8.838 19.542l-5.459 2.778 0.105 0.206 5.459-2.778z" />
    <path d="M9.021 19.639c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.158zM3.425 22.428h0.157c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.158z" />
    <path d="M8.552 18.95l-6.276 2.615 0.089 0.213 6.276-2.615z" />
    <path d="M8.76 19.049c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM2.315 21.645h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M8.365 18.358l-7.224 2.353 0.072 0.22 7.224-2.353z" />
    <path d="M8.564 18.462c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.071 0.157 0.158zM1.17 20.827h0.157c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.071 0.157 0.158z" />
    <path d="M8.177 17.767l-7.747 1.863 0.054 0.225 7.747-1.863z" />
    <path d="M8.368 17.874c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.071 0.157 0.158zM0.451 19.748h0.157c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.071 0.157 0.158z" />
    <path d="M8.088 17.144l-7.42 1.177 0.036 0.228 7.42-1.177z" />
    <path d="M8.27 17.253c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.071 0.157 0.158zM0.68 18.44h0.157c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.071 0.157 0.158z" />
    <path d="M8.032 16.49l-6.472 0.523 0.019 0.23 6.472-0.523z" />
    <path d="M8.204 16.63c0 0.086-0.070 0.156-0.157 0.156s-0.157-0.070-0.157-0.156c-0-0.086 0.070-0.156 0.157-0.156s0.157 0.070 0.157 0.156zM1.563 17.135h0.157c0 0.086-0.070 0.156-0.157 0.156s-0.157-0.070-0.157-0.156c-0-0.086 0.070-0.156 0.157-0.156s0.157 0.070 0.157 0.156z" />
    <path d="M8.008 15.884h-7.845v0.231h7.845z" />
    <path d="M8.172 15.984c0 0.090-0.070 0.163-0.157 0.163s-0.157-0.073-0.157-0.163c-0-0.090 0.070-0.163 0.157-0.164s0.157 0.073 0.157 0.163zM0.157 15.984h0.157c0 0.090-0.070 0.163-0.157 0.163s-0.157-0.073-0.157-0.163c-0-0.090 0.070-0.163 0.157-0.164s0.157 0.073 0.157 0.163z" />
    <path d="M8.017 15.247l-6.995-0.556-0.018 0.23 6.995 0.556z" />
    <path d="M8.172 15.372c0 0.085-0.070 0.154-0.157 0.154s-0.157-0.069-0.157-0.154c-0-0.085 0.070-0.154 0.157-0.154s0.157 0.069 0.157 0.154zM1.007 14.831h0.157c0 0.085-0.070 0.154-0.157 0.154s-0.157-0.069-0.157-0.154c-0-0.085 0.070-0.154 0.157-0.154s0.157 0.069 0.157 0.154z" />
    <path d="M8.124 14.627l-6.276-0.981-0.036 0.228 6.276 0.981z" />
    <path d="M8.27 14.748c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM1.824 13.754h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M8.231 14.008l-5.72-1.373-0.054 0.225 5.72 1.373z" />
    <path d="M8.368 14.126c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.071 0.157 0.158zM2.478 12.742h0.157c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.071 0.157 0.158z" />
    <path d="M8.436 13.422l-5.851-1.896-0.071 0.22 5.851 1.896z" />
    <path d="M8.564 13.538c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM2.543 11.63h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M8.641 12.837l-6.341-2.615-0.088 0.214 6.341 2.615z" />
    <path d="M8.76 12.95c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM2.249 10.323h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M8.91 12.253l-5.328-2.713-0.105 0.206 5.328 2.713z" />
    <path d="M9.021 12.362c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM3.524 9.636h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M9.245 11.734l-5.687-3.497-0.121 0.197 5.687 3.497z" />
    <path d="M9.348 11.807c0 0.086-0.070 0.156-0.157 0.156s-0.157-0.070-0.157-0.156c-0-0.086 0.070-0.156 0.157-0.156s0.157 0.070 0.157 0.156zM3.491 8.328h0.157c0 0.086-0.070 0.156-0.157 0.156s-0.157-0.070-0.157-0.156c-0-0.086 0.070-0.156 0.157-0.156s0.157 0.070 0.157 0.156z" />
    <path d="M9.612 11.216l-5.001-3.628-0.136 0.187 5.001 3.628z" />
    <path d="M9.675 11.315c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.157zM4.537 7.675h0.157c0 0.087-0.070 0.157-0.157 0.158s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.157z" />
    <path d="M10.012 10.699l-5.263-4.478-0.15 0.176 5.263 4.478z" />
    <path d="M10.067 10.793c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM4.668 6.302h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M10.411 10.247l-4.805-4.805-0.163 0.163 4.805 4.805z" />
    <path d="M10.492 10.335c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM5.518 5.518h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M10.874 9.829l-4.87-5.72-0.176 0.15 4.87 5.72z" />
    <path d="M10.95 9.91c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.158s0.157 0.070 0.157 0.157zM5.91 4.178h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.158s0.157 0.070 0.157 0.157z" />
    <path d="M11.403 9.444l-3.792-5.197-0.187 0.136 3.792 5.197z" />
    <path d="M11.44 9.518c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM7.511 4.309h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M11.931 9.124l-3.007-4.903-0.197 0.121 3.007 4.903z" />
    <path d="M11.996 9.191c0 0.087-0.071 0.157-0.158 0.157s-0.158-0.070-0.158-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.158 0.070 0.158 0.157zM8.819 4.276h0.158c0 0.087-0.071 0.157-0.158 0.157s-0.158-0.070-0.158-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.158 0.070 0.158 0.157z" />
    <path d="M12.491 8.805l-2.157-4.217-0.206 0.105 2.157 4.217z" />
    <path d="M12.519 8.865c0 0.086-0.070 0.156-0.156 0.156s-0.156-0.070-0.156-0.156c-0-0.086 0.070-0.156 0.156-0.157s0.156 0.070 0.157 0.156zM10.224 4.667h0.156c0 0.086-0.070 0.156-0.156 0.156s-0.156-0.070-0.156-0.156c-0-0.086 0.070-0.157 0.156-0.157s0.156 0.070 0.157 0.156z" />
    <path d="M13.050 8.552l-1.798-4.315-0.213 0.089 1.798 4.315z" />
    <path d="M13.107 8.603c0 0.086-0.070 0.156-0.156 0.157s-0.156-0.070-0.157-0.156c-0-0.086 0.070-0.157 0.156-0.157s0.157 0.070 0.157 0.156zM11.172 4.308h0.156c0 0.086-0.070 0.157-0.156 0.157s-0.156-0.070-0.157-0.156c-0-0.086 0.070-0.157 0.156-0.157s0.157 0.070 0.157 0.156z" />
    <path d="M13.642 8.365l-1.406-4.315-0.22 0.072 1.406 4.315z" />
    <path d="M13.663 8.374c0 0.087-0.070 0.157-0.156 0.157s-0.156-0.070-0.156-0.157c-0-0.087 0.070-0.157 0.156-0.157s0.156 0.070 0.156 0.157zM12.119 4.079h0.156c0 0.087-0.070 0.157-0.156 0.157s-0.156-0.070-0.156-0.157c-0-0.087 0.070-0.157 0.156-0.157s0.156 0.070 0.156 0.157z" />
    <path d="M14.233 8.21l-1.177-4.87-0.225 0.054 1.177 4.87z" />
    <path d="M14.284 8.243c0 0.087-0.070 0.157-0.156 0.157s-0.156-0.070-0.156-0.157c-0-0.087 0.070-0.157 0.156-0.158s0.156 0.070 0.156 0.157zM12.969 3.361h0.156c0 0.087-0.070 0.157-0.156 0.157s-0.156-0.070-0.156-0.157c-0-0.087 0.070-0.157 0.156-0.158s0.156 0.070 0.156 0.157z" />
    <path d="M14.856 8.088l-0.915-5.785-0.228 0.036 0.915 5.785z" />
    <path d="M14.905 8.112c0 0.087-0.071 0.157-0.159 0.157s-0.159-0.070-0.159-0.157c-0-0.087 0.071-0.157 0.158-0.157s0.159 0.070 0.159 0.157zM13.822 2.315h0.159c0 0.087-0.071 0.157-0.159 0.157s-0.159-0.070-0.159-0.157c-0-0.087 0.071-0.157 0.158-0.158s0.159 0.070 0.159 0.157z" />
    <path d="M15.478 7.999l-0.425-5.263-0.23 0.019 0.425 5.262z" />
    <path d="M15.526 8.015c0 0.087-0.069 0.157-0.155 0.157s-0.155-0.070-0.155-0.157c-0-0.087 0.069-0.157 0.155-0.157s0.155 0.070 0.155 0.157zM14.962 2.739h0.155c0 0.087-0.069 0.157-0.155 0.157s-0.155-0.070-0.155-0.157c-0-0.087 0.069-0.157 0.155-0.157s0.155 0.070 0.155 0.157z" />
    <path d="M16.116 8.008v-7.845h-0.231v7.845z" />
    <path d="M16.147 8.014c0 0.087-0.073 0.157-0.163 0.157s-0.163-0.070-0.163-0.157c-0-0.087 0.073-0.157 0.163-0.157s0.163 0.070 0.164 0.157zM15.984 0.157h0.163c0 0.087-0.073 0.157-0.163 0.157s-0.163-0.070-0.163-0.157c-0-0.087 0.073-0.157 0.163-0.157s0.163 0.070 0.164 0.157z" />
    <path d="M16.72 8.050l0.425-5.23-0.23-0.019-0.425 5.23z" />
    <path d="M16.786 8.015c0 0.087-0.070 0.157-0.156 0.157s-0.156-0.070-0.156-0.157c-0-0.087 0.070-0.157 0.156-0.157s0.156 0.070 0.156 0.157zM17.037 2.804h0.156c0 0.087-0.070 0.157-0.156 0.157s-0.156-0.070-0.156-0.157c-0-0.087 0.070-0.157 0.156-0.157s0.156 0.070 0.156 0.157z" />
    <path d="M17.372 8.124l0.915-5.753-0.228-0.036-0.915 5.753z" />
    <path d="M17.413 8.113c0 0.087-0.071 0.157-0.159 0.157s-0.159-0.070-0.159-0.157c-0-0.087 0.071-0.157 0.159-0.157s0.159 0.070 0.159 0.157zM18.178 2.347h0.159c0 0.087-0.071 0.157-0.159 0.157s-0.159-0.070-0.159-0.157c-0-0.087 0.071-0.157 0.159-0.157s0.159 0.070 0.159 0.157z" />
    <path d="M17.992 8.231l1.177-4.87-0.225-0.054-1.177 4.87z" />
    <path d="M18.027 8.211c0 0.087-0.070 0.157-0.155 0.157s-0.155-0.070-0.156-0.157c-0-0.087 0.070-0.157 0.155-0.157s0.155 0.070 0.156 0.157zM19.031 3.328h0.155c0 0.087-0.070 0.157-0.155 0.157s-0.155-0.070-0.156-0.157c-0-0.087 0.070-0.157 0.155-0.157s0.155 0.070 0.156 0.157z" />
    <path d="M18.578 8.436l1.732-5.328-0.22-0.071-1.732 5.328z" />
    <path d="M18.62 8.407c0 0.087-0.071 0.157-0.158 0.157s-0.158-0.070-0.158-0.157c-0-0.087 0.071-0.157 0.158-0.157s0.158 0.070 0.158 0.157zM20.206 3.066h0.158c0 0.087-0.071 0.157-0.158 0.157s-0.158-0.070-0.158-0.157c-0-0.087 0.071-0.157 0.158-0.157s0.158 0.070 0.158 0.157z" />
    <path d="M19.163 8.641l2.386-5.72-0.213-0.089-2.386 5.72z" />
    <path d="M19.206 8.603c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.156-0.157s0.157 0.070 0.157 0.157zM21.416 2.87h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.156-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M19.748 8.91l3.236-6.374-0.206-0.105-3.236 6.374z" />
    <path d="M19.795 8.864c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM22.887 2.478h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M20.266 9.245l3.661-5.949-0.197-0.121-3.661 5.949z" />
    <path d="M20.35 9.191c0 0.087-0.070 0.157-0.156 0.157s-0.156-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.156-0.157s0.157 0.070 0.157 0.157zM23.835 3.229h0.157c0 0.087-0.070 0.157-0.156 0.157s-0.156-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.156-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M20.784 9.58l3.759-5.164-0.187-0.136-3.759 5.164z" />
    <path d="M20.842 9.518c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM24.456 4.341h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M21.301 10.011l3.53-4.151-0.176-0.15-3.53 4.151z" />
    <path d="M21.365 9.911c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM24.75 5.779h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M21.753 10.411l3.334-3.334-0.163-0.163-3.334 3.334z" />
    <path d="M21.822 10.335c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM25.012 6.988h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M22.171 10.907l4.184-3.595-0.151-0.175-4.184 3.595z" />
    <path d="M22.247 10.793c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM26.286 7.217h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M22.556 11.403l4.903-3.563-0.136-0.187-4.903 3.563z" />
    <path d="M22.64 11.315c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.070-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.158 0.157zM27.397 7.741h0.157c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.157c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.158 0.157z" />
    <path d="M22.875 11.898l5.687-3.465-0.12-0.197-5.687 3.465z" />
    <path d="M22.965 11.806c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157zM28.476 8.329h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.157 0.157z" />
    <path d="M23.194 12.458l5.066-2.582-0.105-0.206-5.066 2.582z" />
    <path d="M23.293 12.361c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.158zM28.215 9.767h0.157c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.158z" />
    <path d="M23.448 13.051l5.295-2.19-0.088-0.213-5.295 2.19z" />
    <path d="M23.553 12.949c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.158zM28.673 10.748h0.157c0 0.087-0.070 0.158-0.157 0.158s-0.157-0.071-0.157-0.158c-0-0.087 0.070-0.158 0.157-0.158s0.157 0.070 0.157 0.158z" />
    <path d="M23.635 13.642l5.785-1.863-0.071-0.22-5.785 1.863z" />
    <path d="M23.751 13.539c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.158 0.157zM29.391 11.662h0.157c0 0.087-0.070 0.157-0.157 0.157s-0.157-0.070-0.157-0.157c-0-0.087 0.070-0.157 0.157-0.157s0.157 0.070 0.158 0.157z" />
    <path d="M23.79 14.233l6.243-1.504-0.054-0.225-6.243 1.504z" />
    <path d="M23.946 14.128c0 0.086-0.070 0.156-0.157 0.156s-0.157-0.070-0.157-0.156c-0-0.086 0.070-0.156 0.157-0.156s0.157 0.070 0.157 0.156zM30.013 12.642h0.157c0 0.086-0.070 0.156-0.157 0.156s-0.157-0.070-0.157-0.156c-0-0.086 0.070-0.156 0.157-0.156s0.157 0.070 0.157 0.156z" />
    <path d="M23.912 14.856l6.374-1.013-0.036-0.228-6.374 1.013z" />
    <path d="M24.045 14.746c0 0.088-0.070 0.159-0.157 0.159s-0.157-0.071-0.157-0.159c-0-0.088 0.070-0.159 0.157-0.159s0.157 0.071 0.157 0.159zM30.274 13.724h0.157c0 0.088-0.070 0.159-0.157 0.159s-0.157-0.071-0.157-0.159c-0-0.088 0.070-0.159 0.157-0.159s0.157 0.071 0.157 0.159z" />
    <path d="M24.001 15.478l5.328-0.425-0.018-0.23-5.328 0.425z" />
    <path d="M24.142 15.372c0 0.085-0.070 0.154-0.157 0.154s-0.157-0.069-0.157-0.154c-0-0.085 0.070-0.154 0.157-0.154s0.157 0.069 0.157 0.154zM29.326 14.961h0.157c0 0.085-0.070 0.154-0.157 0.154s-0.157-0.069-0.157-0.154c-0-0.085 0.070-0.154 0.157-0.154s0.157 0.069 0.157 0.154z" />
  </svg>
));

export default Burst;
