import { combineReducers } from 'redux-immutable';

import facebookReducer from './facebook';
import instagramReducer from './instagram';
import linkedinReducer from './linkedin';
import threadsReducer from './threads';
import tiktokReducer from './tiktok';
import twitterReducer from './twitter';
import youtubeReducer from './youtube';

export default combineReducers({
  facebook: facebookReducer,
  twitter: twitterReducer,
  youtube: youtubeReducer,
  linkedin: linkedinReducer,
  threads: threadsReducer,
  tiktok: tiktokReducer,
  instagram: instagramReducer,
});
