import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import ClipSelectEducationModalContents from './ClipSelectEducationModalContents';

export interface ClipSelecteEducationModalProps {}

const ClipSelecteEducationModal: React.FC<ClipSelecteEducationModalProps> = () => (
  <ConnectedModal name="ClipSelectEducation" title="smart clip selection">
    <ClipSelectEducationModalContents />
  </ConnectedModal>
);

export default ClipSelecteEducationModal;
