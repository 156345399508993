import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import usePostModalDescription from 'hooks/usePostModalDescription';
import {
  defaultPostTextSelector,
  episodeUrlSelector,
  isPollingSocialPostCaptionsSelector,
} from 'redux/modules/download/selectors';
import {
  getFacebookPages,
  postFacebookVideo,
} from 'redux/modules/social/facebook/actions';
import {
  facebookPagesLoadingSelector,
  facebookPagesSelector,
  facebookPostStatusSelector,
} from 'redux/modules/social/facebook/selectors';
import { Dispatch, State, ThunkAction } from 'redux/types';
import { PropsOf } from 'types';
import FacebookPostModal, {
  FacebookPostModalProps as Props,
} from './FacebookPostModal';

type DispatchProps = Pick<Props, 'onAuthSuccess' | 'onOpen' | 'onSubmit'>;
type StateProps = Pick<
  Props,
  'pages' | 'postStatus' | 'episodeUrl' | 'isFetchingPages' | 'description'
>;

const getPages = (): ThunkAction<void> => async dispatch => {
  await dispatch(getFacebookPages());
};

export const pagesSelector = createSelector(facebookPagesSelector, pages => {
  return pages.reduce((acc, page) => {
    acc.push({
      label: page.name,
      logo: page.imageUrl,
      value: page.pageId,
      pageAccessToken: page.pageAccessToken,
    });
    return acc;
  }, []);
});

const mapStateToProps = (state: State): StateProps => ({
  pages: pagesSelector(state),
  postStatus: facebookPostStatusSelector(state),
  episodeUrl: episodeUrlSelector(state),
  isFetchingPages: facebookPagesLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onAuthSuccess: () => dispatch(getPages()),
  onOpen: () => dispatch(getPages()),
  onSubmit: (facebookPageId, pageAccessToken, description) =>
    dispatch(
      postFacebookVideo(pageAccessToken, {
        facebookPageId,
        description,
      }),
    ),
});

const FacebookPostModalContainer = props => {
  const defaultDescription = useSelector(defaultPostTextSelector);
  const isLoading = useSelector(isPollingSocialPostCaptionsSelector);

  const {
    description,
    setDescription,
    resetDescription,
  } = usePostModalDescription({
    platform: 'facebook',
    defaultDescription,
  });

  return (
    <FacebookPostModal
      {...props}
      {...{ description, isLoading, resetDescription }}
      onDescriptionChange={setDescription}
    />
  );
};

const component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FacebookPostModalContainer);
export type FacebookPostModalProps = PropsOf<typeof component>;
export default component;
