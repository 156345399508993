import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { TikTokUserAllowance } from 'containers/TikTokAutoPostModal/types';
import { TikTokPrivacyLevelOptionName } from 'redux/middleware/api/third-party-authentication-service/types';
import { tiktokSelector } from 'redux/modules/social';
import { MAX_CHARS_CAPTIONS_PREVIEW } from './constants';

const usernameSelector = createSelector(
  tiktokSelector,
  tikTok => tikTok.username,
);

const avatarSrcSelector = createSelector(
  tiktokSelector,
  tikTok => tikTok.profileImageUrl,
);

function formatCaptions(val: string) {
  if (typeof val !== 'string') return undefined;

  return val.substring(0, MAX_CHARS_CAPTIONS_PREVIEW);
}

interface UseTikTokPostModalConfig {
  defaultCaptions?: string;
  defaultPrivacyLevel?: TikTokPrivacyLevelOptionName;
  defaultUserAllowance?: TikTokUserAllowance[];
  defaultBrandContent?: boolean;
  defaultBrandOrganic?: boolean;
}

export default function useTikTokPostModalSelector(
  config: UseTikTokPostModalConfig = {},
) {
  const username = useSelector(usernameSelector);
  const avatarSrc = useSelector(avatarSrcSelector);

  const {
    defaultCaptions,
    defaultPrivacyLevel,
    defaultUserAllowance,
    defaultBrandContent,
    defaultBrandOrganic,
  } = config;

  return {
    username,
    avatarSrc,
    defaultCaptions: formatCaptions(defaultCaptions),
    defaultPrivacyLevel,
    defaultUserAllowance,
    defaultBrandContent,
    defaultBrandOrganic,
  };
}
