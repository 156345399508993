import React from 'react';

import { SpacerArrow } from 'components/icons';

import { actionStepCardSpacerBlock } from './utils';

const ActionStepCardSpacer: React.FunctionComponent = () => {
  return (
    <div className={actionStepCardSpacerBlock('container')}>
      <SpacerArrow height={20} width={16} />
    </div>
  );
};

export default ActionStepCardSpacer;
