import React from 'react';
import { TabHeaderProps } from './types';

const TabsHeader: React.FC<TabHeaderProps> = ({
  children,
  className,
  nTabs,
}) => {
  if (nTabs < 2) {
    return null;
  }

  return <ul {...{ className, children }} />;
};

export default TabsHeader;
