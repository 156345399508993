import FontFaceObserver from 'fontfaceobserver';
import React from 'react';
import WebFont from 'webfontloader';

import { useFontSettings } from 'containers/AccountSettings/FontSettings/useFontSettings';
import { RequestStatus } from 'types';

interface UseCustomFontsLoader {
  status: RequestStatus;
}

const DEFAULT_PERSONAL_FONTS = [];

/*
  This is a hook version of the FontLoad component, it gets all the personals fonts
  for a user and loads them all at once.
*/
const useCustomFontsLoader = (): UseCustomFontsLoader => {
  const [status, setStatus] = React.useState<RequestStatus>(
    RequestStatus.REQUEST,
  );

  const {
    fontsLoading,
    personalFonts = DEFAULT_PERSONAL_FONTS,
  } = useFontSettings();

  React.useEffect(() => {
    if (fontsLoading || !personalFonts.length) {
      return;
    }

    const fontsToLoad = personalFonts.reduce(
      (acc, font) => {
        if (font.family && font.url) {
          acc.families.push(font.family);
          acc.urls.push(font.url);
        }
        return acc;
      },
      { families: [], urls: [] },
    );

    if (fontsToLoad.families.length) {
      WebFont.load({
        classes: false,
        custom: {
          families: fontsToLoad.families,
          urls: fontsToLoad.urls,
        },
      });
    }

    personalFonts
      .filter(font => font.family)
      .forEach(({ family, style, weight }) => {
        const opts = { style, weight };

        new FontFaceObserver(family, opts)
          .load()
          .then(() => setStatus(RequestStatus.SUCCESS))
          .catch(() => setStatus(RequestStatus.FAILURE));
      });
  }, [fontsLoading, personalFonts, setStatus]);

  return { status };
};

export default useCustomFontsLoader;
