import cn from 'classnames';
import * as React from 'react';

import bem from 'utils/bem';

export interface PageProps {
  children?: React.ReactNode;
  className?: string;

  /**
   * when true, will create a page that fills the viewport height and hides any
   * overflow.  when false, will use the default semantics where the page is
   * at least as tall as the viewport but possibly larger.
   */
  fillViewport?: boolean;
}

const block = bem('page');

const Page: React.FC<PageProps> = ({ className, fillViewport, ...props }) => (
  <div className={cn(block({ fill: fillViewport }), className)} {...props} />
);

export default Page;
