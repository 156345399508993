import React from 'react';
import ResizeDetector from 'react-resize-detector';

import BackgroundLayer from '../../layers/BackgroundLayer';
import { editorModalPreviewBlock as block } from '../../utils';
import EditorModalCaptionsLayer from './EditorModalCaptionsLayer';
import EditorModalFadeLayer from './EditorModalFadeLayer';
import EditorModalSlideshowLayer from './EditorModalSlideshowLayer';
import EditorModalSoundwaveLayer from './EditorModalSoundwaveLayer';
import EditorModalTextLayer from './EditorModalTextLayer';
import useEditorModalBackground from './useEditorModalBackground';

interface EditorModalStaticBackgroundProps {
  assetId: string;
  isCaptionsEditor?: boolean;
  showFadeLayer?: boolean;
}

const EditorModalStaticBackground: React.FunctionComponent<EditorModalStaticBackgroundProps> = props => {
  const { assetId, isCaptionsEditor, showFadeLayer } = props;

  const [canvasSize, setCanvasSize] = React.useState({ height: 0, width: 0 });

  const handleResize = React.useCallback((width, height) => {
    setCanvasSize({ height, width });
  }, []);

  const orderedLayers = useEditorModalBackground({
    isCaptionsEditor,
    selectedAssetId: assetId,
  });

  return (
    <div className={block('wrapper')}>
      <ResizeDetector handleWidth handleHeight onResize={handleResize}>
        <EditorModalFadeLayer visible={showFadeLayer} />
        <BackgroundLayer />
        {orderedLayers.map(orderedLayer => {
          switch (orderedLayer.type) {
            case 'soundwave':
              return (
                <EditorModalSoundwaveLayer
                  canvasSize={canvasSize}
                  soundwave={orderedLayer.soundwave}
                />
              );
            case 'slideshow':
              return (
                <EditorModalSlideshowLayer slideshow={orderedLayer.slideshow} />
              );
            case 'text-overlay':
              return (
                <EditorModalTextLayer
                  canvasSize={canvasSize}
                  textOverlay={orderedLayer.textOverlay}
                />
              );
            case 'captions':
              return (
                <EditorModalCaptionsLayer
                  canvasSize={canvasSize}
                  captionsOverride={orderedLayer.captionsOverride}
                />
              );
            default:
              return null;
          }
        })}
      </ResizeDetector>
    </div>
  );
};

export default EditorModalStaticBackground;
