import cn from 'classnames';
import * as React from 'react';

import Draggable, {
  DragCallbackData,
  DraggableProps,
} from 'components/Draggable';
import { block } from '../../../utils';
import DragHandleTooltip from './DragHandleTooltip';

type PickedDraggableProps = Pick<DraggableProps, 'bounds' | 'parentHandle'>;

export interface DragHandleProps extends PickedDraggableProps {
  className?: string;
  /**
   * x coordinate used for displaying the tooltip.
   *
   * this might be different than the x prop.  for example, drag handles are
   * nested within a track asset, so the left handle typically always has
   * position 0 (relative to the asset's body) but the handle's display position
   * can be anywhere on the timeline
   */
  displayX: number;
  id: string;
  onDrag?: (data: DragCallbackData, e: React.MouseEvent<HTMLElement>) => void;
  onStart?: (data: DragCallbackData, e: React.MouseEvent<HTMLElement>) => void;
  onStop?: (e: React.MouseEvent<HTMLElement>) => void;
  pxPerSec: number;
  showTooltip?: boolean;
  x?: number;
  width: number;
}

export default class DragHandle extends React.Component<DragHandleProps> {
  public static defaultProps: Partial<DragHandleProps> = {
    x: 0,
  };

  private handleDrag: DraggableProps['onDrag'] = (e, data) =>
    this.props.onDrag(data, e);

  private handleStart: DraggableProps['onStart'] = (e, data) =>
    this.props.onStart(data, e);

  private handleStop: DraggableProps['onStop'] = e => this.props.onStop(e);

  public render() {
    const {
      bounds,
      className,
      displayX,
      id,
      parentHandle,
      pxPerSec,
      showTooltip,
      width,
      x,
    } = this.props;
    return (
      <DragHandleTooltip
        id={`${id}-tooltip`}
        pxPerSec={pxPerSec}
        show={showTooltip}
        x={displayX}
      >
        <Draggable
          bounds={bounds}
          cancel=".timeline__track-asset"
          handle={`#${id}`}
          parentHandle={parentHandle}
          position={{ x, y: 0 }}
          onDrag={this.handleDrag}
          onStart={this.handleStart}
          onStop={this.handleStop}
        >
          <div
            id={id}
            className={cn(block('track-asset-handle'), className)}
            style={{ width }}
          />
        </Draggable>
      </DragHandleTooltip>
    );
  }
}
