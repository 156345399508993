import cn from 'classnames';
import * as React from 'react';

import BemCssTransition, {
  BemCssTransitionProps,
} from 'components/BemCssTransition';
import TemplateNuxOverlay from './TemplateNuxOverlay';
import { block } from './utils';

const { useCallback, useState } = React;

type PickedTransitionProps = Pick<BemCssTransitionProps, 'delay' | 'onExited'>;

export interface TemplateNuxOverlayContainerProps
  extends PickedTransitionProps {
  className?: string;
}

const TemplateNuxOverlayContainter: React.SFC<TemplateNuxOverlayContainerProps> = ({
  className,
  delay,
  onExited,
}) => {
  const [show, setShow] = useState(true);

  const handleEntered = useCallback(() => setShow(false), [setShow]);

  return (
    <BemCssTransition
      appear
      in={show}
      className={cn(block(), className)}
      delay={delay}
      onEntered={handleEntered}
      onExited={onExited}
      timeout={{
        enter: 2775,
        exit: 475,
      }}
      transitionClassName={block()}
      unmountOnExit
    >
      <TemplateNuxOverlay />
    </BemCssTransition>
  );
};

export default TemplateNuxOverlayContainter;
