import * as React from 'react';
import { connect } from 'react-redux';
import { isNaN, isUndefined } from 'underscore';

import { dataFactory } from 'components/VideoFormWithPreview';
import { SelectedMediaElement } from 'containers/AddMediaModal/AddMediaModal';
import { selectors as embedSelectors } from 'redux/modules/embed';
import {
  clearVideoEdit,
  videoPosterSelector,
  videoUrlSelector,
} from 'redux/modules/video-edit';
import { Dispatch, State } from 'redux/types';
import AddVideoForm, { IProps } from './AddVideoForm';

type StateProps = Pick<
  IProps,
  'aspectRatio' | 'videoSrc' | 'isSubmitting' | 'poster' | 'defaultData'
>;
type DispatchProps = Pick<IProps, 'onUnmount'>;
type OwnProps = Pick<IProps, 'defaultData'> & React.RefAttributes<AddVideoForm>;

const createDefaultData = (
  mediaElement: SelectedMediaElement,
  { defaultData }: OwnProps,
) => {
  if (!mediaElement) return defaultData;

  const mediaElementData = dataFactory({
    audioFadeInDurationMillis: mediaElement.get('audioFadeInDurationMillis'),
    audioFadeOutDurationMillis: mediaElement.get('audioFadeOutDurationMillis'),
    audioLevel: mediaElement.get('audioLevel') * 100,
    clipEndMillis:
      mediaElement.get('endMillis') - mediaElement.get('startMillis'),
    mainAudioLevel: mediaElement.get('mainAudioLevel') * 100,
    mediaToReplaceId: mediaElement.get('id'),
    startMillis: mediaElement.get('startMillis'),
    transitionIn: mediaElement.get('transitionIn'),
    transitionOut: mediaElement.get('transitionOut'),
  });

  return defaultData.mergeWith(
    (oldVal, newVal) =>
      isUndefined(newVal) || isNaN(newVal) ? oldVal : newVal,
    mediaElementData,
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  aspectRatio: embedSelectors.aspectRatioSelector(state),
  defaultData: createDefaultData(
    embedSelectors.toReplaceMediaElementSelector(state),
    ownProps,
  ),
  isSubmitting: embedSelectors.isAddingVideoSelector(state),
  poster: videoPosterSelector(state),
  videoSrc: videoUrlSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onUnmount: () => dispatch(clearVideoEdit()),
});

export default function(component: React.ComponentType<IProps>) {
  return connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
  })(component);
}
