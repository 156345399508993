import _ from 'underscore';

import { round } from 'utils/numbers';
import {
  clearRequestInterval,
  requestInterval,
  RequestIntervalId,
} from 'utils/scheduling';
import { getContext } from './audio-context';
import AudioEvent from './AudioEvent';
import BaseAudio from './BaseAudio';
import { createAudioSource, removeAudioSource } from './common';
import { IAudioSource, IAudioSourceConfig } from './types';

interface IEventTimePayload {
  seconds: number;
  duration: number;
}

type Callback = (...args: any[]) => void;

type Listeners = { [Key in AudioEvent]: Callback[] };

export type OnPlay = () => void;
export type OnTimeupdate = (arg: IEventTimePayload) => void;
export type OnEnd = () => void;
export type OnPause = () => void;
export type OnSeek = (arg: IEventTimePayload) => void;

export type Listener<T extends AudioEvent> = T extends AudioEvent.PLAY
  ? OnPlay
  : T extends AudioEvent.TIMEUPDATE
  ? OnTimeupdate
  : T extends AudioEvent.END
  ? OnEnd
  : T extends AudioEvent.PAUSE
  ? OnPause
  : T extends AudioEvent.SEEK
  ? OnSeek
  : never;

interface ISourceUrlById {
  [key: string]: string;
}

export default class Audio extends BaseAudio {
  public static create(
    sourceConfigs: IAudioSourceConfig | IAudioSourceConfig[],
    durationSeconds: number = 0,
    cb: (audio: Audio) => void = _.noop,
  ) {
    const audioCtx = getContext();
    const configs = Array.isArray(sourceConfigs)
      ? sourceConfigs
      : [sourceConfigs];
    const futSources = configs.map(config =>
      createAudioSource(config, audioCtx),
    );

    return Promise.all(futSources).then(sources => {
      const audio = new Audio(audioCtx, sources, durationSeconds);
      typeof cb === 'function' && cb(audio);
      return audio;
    });
  }

  private eventListeners: Listeners;
  private timeupdateTaskId: RequestIntervalId;
  private sourceUrlById: ISourceUrlById;

  constructor(
    ctx: AudioContext,
    sources: IAudioSource[],
    durationSeconds: number,
  ) {
    super(ctx, durationSeconds, sources);
    this.durationSeconds = durationSeconds;
    this.eventListeners = Audio.initializeEventListeners();
    this.sourceUrlById = sources.reduce((acc, source) => {
      if (typeof source.source === 'string') {
        acc[source.id] = source.source;
      }
      return acc;
    }, {} as ISourceUrlById);
  }

  private static initializeEventListeners() {
    return Object.keys(AudioEvent).reduce(
      (acc, key) => ({
        ...acc,
        [AudioEvent[key]]: [],
      }),
      {} as Listeners,
    );
  }

  private createEventTimePayload(playbackSec: number): IEventTimePayload {
    return {
      duration: this.durationSeconds,
      seconds: playbackSec,
    };
  }

  private fireEvent(event: AudioEvent.PLAY | AudioEvent.END | AudioEvent.PAUSE);
  private fireEvent(
    event: AudioEvent.TIMEUPDATE | AudioEvent.SEEK,
    args: IEventTimePayload,
  );
  private fireEvent(event: string, ...args: any[]) {
    this.eventListeners[event].forEach(listener => listener(...args));
  }

  protected scheduleTimeupdate() {
    this.timeupdateTaskId = requestInterval(() => {
      if (this.currentTime >= this.duration) {
        this.fireEvent(
          AudioEvent.TIMEUPDATE,
          this.createEventTimePayload(this.duration),
        );
        this.stop();
        return;
      }
      const seconds = round(this.currentTime, -3);
      this.fireEvent(
        AudioEvent.TIMEUPDATE,
        this.createEventTimePayload(seconds),
      );
    }, 20);
  }

  protected unscheduleTimeupdate() {
    if (this.timeupdateTaskId) {
      clearRequestInterval(this.timeupdateTaskId);
      delete this.timeupdateTaskId;
    }
  }

  protected startPlayback(fromSec?: number) {
    super.startPlayback(fromSec);
    this.scheduleTimeupdate();
  }

  protected stopPlayback() {
    super.stopPlayback();
    this.unscheduleTimeupdate();
  }

  public play(offsetSec?: number) {
    if (this.playing) return;
    this.startPlayback(offsetSec);
    this.fireEvent(AudioEvent.PLAY);
  }

  public pause() {
    if (!this.playing) return;
    this.pausePlayback();
    this.fireEvent(AudioEvent.PAUSE);
  }

  public stop() {
    this.stopPlayback();
    this.fireEvent(AudioEvent.END);
  }

  public seek(sec: number) {
    if (_.isUndefined(sec)) return;

    const seekToSec = Math.max(0, Math.min(this.durationSeconds, sec));
    this.seekTo(seekToSec);

    const eventPayload = this.createEventTimePayload(seekToSec);
    this.fireEvent(AudioEvent.SEEK, eventPayload);

    if (seekToSec === this.durationSeconds) {
      this.stop();
    }
  }

  public setMasterVolume(value: number) {
    this.setMasterGain(value / 100);
  }

  public addSource(config: IAudioSourceConfig | IAudioSourceConfig[]) {
    const configs = Array.isArray(config) ? config : [config];
    configs.forEach(sourceConfig =>
      createAudioSource(sourceConfig, this.audioCtx).then(source => {
        super.addSources([source]);
        if (typeof source.source === 'string') {
          this.sourceUrlById[source.id] = source.source;
        }
      }),
    );
  }

  public deleteSource(id: string | string[]) {
    (Array.isArray(id) ? id : [id]).forEach(sourceId => {
      super.deleteSource(sourceId);

      const url = this.sourceUrlById[sourceId];
      if (url) {
        removeAudioSource(url);
        delete this.sourceUrlById[sourceId];
      }
    });
  }

  public on<T extends AudioEvent>(event: T, listener: Listener<T>) {
    // listener already registered
    const registeredListeners = this.eventListeners[event];
    if (registeredListeners.indexOf(listener) > -1) return;

    this.eventListeners = {
      ...this.eventListeners,
      [event]: [...registeredListeners, listener],
    };
  }

  public off<T extends AudioEvent>(event: T, listener: Listener<T>) {
    const registeredListeners = this.eventListeners[event];
    const indexOfListener = registeredListeners.indexOf(listener);

    // listener not registered
    if (indexOfListener === -1) return;

    this.eventListeners = {
      ...this.eventListeners,
      [event]: [
        ...registeredListeners.slice(0, indexOfListener),
        ...registeredListeners.slice(
          indexOfListener + 1,
          registeredListeners.length,
        ),
      ],
    };
  }

  public getCurrentTime() {
    return this.currentTime;
  }
}
