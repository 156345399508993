import BluebirdPromise from 'bluebird';
import { Reducer, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'underscore';

import { IVideoUpload } from 'redux/middleware/api/media-upload-service';
import { uploadVideo } from 'redux/middleware/api/media-upload-service/actions';
import { waitForVideoUpload } from 'redux/modules/common/actions';
import { Dispatch } from 'redux/types';

import { isAnimatedGif } from 'utils/image';
import { verifyVideo } from 'utils/video';
import useObjectUrl from './useObjectUrl';
import useStaticCallback from './useStaticCallback';

export type VideoSource = File | string;

export type OnError = (error: Error) => void;

export type VideoProcessorStatus = 'idle' | 'processing' | 'ready' | 'error';

type OnStatusChange = (state: State) => void;

export interface UseVideoProcessorResult {
  objectUrl?: string;
  originalFile?: Blob;
  status: VideoProcessorStatus;
}

type ActionType =
  | 'processing-request'
  | 'processing-success'
  | 'processing-failure'
  | 'processing-abort';

interface BaseAction<T extends ActionType, P = any> {
  payload?: P;
  type: T;
}

type Action =
  | BaseAction<'processing-request', VideoSource>
  | BaseAction<
      'processing-success',
      { src: VideoSource; videoUpload: IVideoUpload }
    >
  | BaseAction<'processing-failure'>
  | BaseAction<'processing-abort'>;

interface State {
  originalFile: Blob;
  source: VideoSource;
  status: VideoProcessorStatus;
  videoUpload: IVideoUpload;
}

const INITIAL_STATE: State = {
  originalFile: undefined,
  source: undefined,
  status: 'idle',
  videoUpload: undefined,
};

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'processing-request':
      return {
        ...INITIAL_STATE,
        source: action.payload,
        status: 'processing',
      };

    case 'processing-success':
      return {
        ...state,
        originalFile:
          typeof action.payload.src === 'string'
            ? undefined
            : action.payload.src,
        status: 'ready',
        videoUpload: action.payload.videoUpload,
      };

    case 'processing-failure':
      return { ...state, status: 'error' };

    case 'processing-abort':
      return INITIAL_STATE;

    default:
      return state;
  }
};

const useVideoProcessor = (
  src: VideoSource,
  targetAspectRatio: number,
  onError: OnError = noop,
  onStatusChange: OnStatusChange = noop,
): UseVideoProcessorResult => {
  const reduxDispatch = useDispatch<Dispatch>();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const objectUrl = useObjectUrl(state.originalFile);

  const handleError: OnError = useStaticCallback(error => {
    onError(error);
  });

  const handleStatusChange: OnStatusChange = useStaticCallback(nextState => {
    onStatusChange(nextState);
  });

  useEffect(() => {
    handleStatusChange(state);
  }, [handleStatusChange, state]);

  // effect should run only when src or targetAspectRatio changes. downloadFile
  // should never change and onErrorRef should never change.
  useEffect(() => {
    const validateFile = async (videoSrc: VideoSource) => {
      try {
        if (typeof videoSrc === 'string') {
          return;
        }

        // validates if file is a valid video
        await verifyVideo(videoSrc);
      } catch (err) {
        // in the case file validation fails for video, file is checked to be a valid
        // gif. In the case both validations fail, the error is propagated
        const isGif = await isAnimatedGif(videoSrc);
        if (!isGif) {
          throw err;
        }
      }
    };

    const processVideo = async (): BluebirdPromise<void> => {
      if (!src) {
        return;
      }

      try {
        dispatch({ type: 'processing-request', payload: src });

        // first file is validated, if src is not a string and the file does not pass video
        // validation an error is thrown.
        await validateFile(src);

        // if src is valid, it is uploaded regardless on if it is a gif or a video.
        // after uploaded, a polling starts to await for the video to be processed, when
        // processing finishes, action is dispatched with both the file and the obtained
        // upload data.
        const videoInitialUpload = await reduxDispatch(uploadVideo(src, {}));
        const uploadedVideo = await reduxDispatch(
          waitForVideoUpload(videoInitialUpload.response.result),
        );
        const videoUpload = uploadedVideo.toJS();
        dispatch({
          type: 'processing-success',
          payload: { src, videoUpload },
        });
      } catch (err) {
        handleError(err || new Error('Error loading video'));
        dispatch({ type: 'processing-failure' });
      }
    };

    const fetchPromise = processVideo();

    return () => {
      if (fetchPromise && fetchPromise.cancel) {
        fetchPromise.cancel();
        dispatch({ type: 'processing-abort' });
      }
    };
  }, [handleError, reduxDispatch, src, targetAspectRatio]);

  return {
    status: state.status,
    objectUrl,
    originalFile: state.originalFile,
  };
};

export default useVideoProcessor;
