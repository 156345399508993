import * as React from 'react';

import { IconProps } from 'components/icons/types';

const InstagramGradientSquareButton: React.FC<IconProps> = (
  props: IconProps,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="85"
    height="85"
    viewBox="0 0 85 85"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="rotate(180 42.857 42.857)">
        <use
          fill="#000"
          filter="url(#instagram-gradient-square-button-filter)"
          xlinkHref="#instagram-gradient-square-button-rect"
        />
        <use
          fill="url(#instagram-gradient-square-button-gradient)"
          xlinkHref="#instagram-gradient-square-button-rect"
        />
      </g>
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M42.655 26.986a16.053 16.053 0 00-16.076 16.08c0 8.9 7.177 16.08 16.076 16.08 8.899 0 16.077-7.18 16.077-16.08 0-8.9-7.178-16.08-16.077-16.08zm0 26.534c-5.75 0-10.452-4.688-10.452-10.454 0-5.766 4.688-10.454 10.452-10.454 5.765 0 10.452 4.688 10.452 10.454 0 5.766-4.701 10.454-10.452 10.454zM63.14 26.328a3.741 3.741 0 01-3.75 3.751 3.741 3.741 0 01-3.75-3.75 3.75 3.75 0 117.5 0zm10.648 3.807c-.238-5.024-1.386-9.474-5.065-13.141-3.666-3.667-8.116-4.814-13.139-5.066-5.177-.294-20.693-.294-25.87 0-5.01.238-9.459 1.385-13.138 5.052-3.68 3.667-4.814 8.117-5.065 13.141-.294 5.178-.294 20.698 0 25.876.237 5.025 1.385 9.475 5.065 13.142 3.68 3.666 8.115 4.814 13.138 5.066 5.177.294 20.693.294 25.87 0 5.023-.238 9.473-1.386 13.139-5.066 3.665-3.667 4.813-8.117 5.065-13.142.293-5.178.293-20.684 0-25.862zm-6.688 31.418c-1.092 2.743-3.205 4.857-5.96 5.962-4.128 1.638-13.923 1.26-18.484 1.26-4.561 0-14.37.364-18.483-1.26-2.742-1.091-4.855-3.205-5.96-5.962-1.637-4.128-1.26-13.924-1.26-18.487 0-4.562-.363-14.372 1.26-18.487 1.09-2.743 3.204-4.856 5.96-5.962 4.128-1.637 13.922-1.26 18.483-1.26s14.37-.363 18.483 1.26c2.742 1.092 4.855 3.205 5.96 5.962 1.638 4.129 1.26 13.925 1.26 18.487 0 4.563.378 14.373-1.26 18.487z"
      />
    </g>
  </svg>
);

export default InstagramGradientSquareButton;
