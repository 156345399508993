import { actions } from 'redux/middleware/api/third-party-authentication-service';
import { ThunkAction } from 'redux/types';
import { RequestStatus } from 'types';
import { AppsConnectedActionTypes } from './action-types';
import {
  AppsProvider,
  AppsProviderStatus,
  DisconnectAppsConnectedAction,
  DisconnectAppsConnectedFailureAction,
  DisconnectAppsConnectedSuccessAction,
  RequestAppsConnectedAction,
  RequestAppsConnectedFailureAction,
  RequestAppsConnectedSuccessAction,
} from './types';

export const disconnectAppsConnectedAction = (
  provider: AppsProvider,
): DisconnectAppsConnectedAction => ({
  type: AppsConnectedActionTypes.APPS_CONNECTED_DISCONECT,
  payload: { provider },
});

export const disconnectAppsConnectedFailureAction = (
  provider: AppsProvider,
): DisconnectAppsConnectedFailureAction => ({
  type: AppsConnectedActionTypes.APPS_CONNECTED_DISCONECT_FAILURE,
  payload: { provider },
});

export const disconnectAppsConnectedSuccessAction = (
  provider: AppsProvider,
): DisconnectAppsConnectedSuccessAction => ({
  type: AppsConnectedActionTypes.APPS_CONNECTED_DISCONECT_SUCCESS,
  payload: { provider },
});

export const requestAppsConnectedAction = (): RequestAppsConnectedAction => ({
  type: AppsConnectedActionTypes.APPS_CONNECTED_REQUEST,
});

export const requestAppsConnectedFailure = (): RequestAppsConnectedFailureAction => ({
  type: AppsConnectedActionTypes.APPS_CONNECTED_REQUEST_FAILURE,
});

export const requestAppsConnectedSuccess = (
  appsConnected: AppsProviderStatus[],
): RequestAppsConnectedSuccessAction => ({
  type: AppsConnectedActionTypes.APPS_CONNECTED_REQUEST_SUCCESS,
  payload: { appsConnected },
});

export const requestAppsConnected = (
  provider?: AppsProvider,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch(requestAppsConnectedAction());

  try {
    const appsConnectedResponse = await dispatch(
      actions.getAppsConnected(provider),
    );
    const appsConnectedData = appsConnectedResponse?.response.accesses ?? [];
    dispatch(requestAppsConnectedSuccess(appsConnectedData));
  } catch (err) {
    dispatch(requestAppsConnectedFailure());
    throw err;
  }
};

export const disconnectAppsConnected = (
  provider: AppsProvider,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const alreadyLoading =
    getState().getIn(['appsConnected', 'deleteStatus'])?.[provider]?.status ===
    RequestStatus.REQUEST;

  if (alreadyLoading) {
    return;
  }

  dispatch(disconnectAppsConnectedAction(provider));

  try {
    await dispatch(actions.deleteAppsConnected(provider));
    dispatch(disconnectAppsConnectedSuccessAction(provider));
  } catch (err) {
    dispatch(disconnectAppsConnectedFailureAction(provider));
    throw err;
  }
};
