import cn from 'classnames';
import * as React from 'react';
import { H4 } from 'components/Heading';
import { useBanner } from './BannerContext';
import { block } from './utils';

interface Props {
  className?: string;
}

export const BannerTitle: React.FC<Props> = props => {
  const { children, className } = props;
  const { size } = useBanner();

  const Component = size === 'small' ? H4 : 'h1';

  return (
    <Component className={cn(block('title'), className)}>{children}</Component>
  );
};

export default BannerTitle;
