import { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { twitterUserDataSelector } from 'redux/modules/social';
import useTwitterPostModalSelector from '../../useTwitterPostModalSelector';
import {
  UseAutopostingComposerStep,
  UseAutopostingComposerStepConfig,
} from './types';
import usePostComposerStep from './usePostComposerStep';

const useAutopostingComposerStep = (
  config: UseAutopostingComposerStepConfig,
): UseAutopostingComposerStep => {
  const { initialValues, onSwitchAccountClick, onHideModal, onSubmit } = config;

  const {
    username,
    avatarSrc,
    defaultDescription,
  } = useTwitterPostModalSelector({
    defaultDescription: initialValues?.description,
  });

  const [prevInitialValues, setPrevInitialValues] = useState(initialValues);
  const [description, setDescription] = useState<string>(defaultDescription);

  const { providerUserId: twitterUserId } = useSelector(
    twitterUserDataSelector,
  );

  const handleSetDefaultProps = useCallback(() => {
    setDescription(defaultDescription);
  }, [defaultDescription]);

  const handleSubmit = useCallback((): void => {
    onSubmit?.({
      twitterUserId,
      description,
    });

    handleSetDefaultProps();
  }, [description, handleSetDefaultProps, twitterUserId, onSubmit]);

  const postComposer = usePostComposerStep({
    username,
    avatarSrc,
    allowSubmit: Boolean(description),
    description,
    onSwitchAccountClick,
    onDescriptionChange: setDescription,
    onHideModal,
    onSubmit: handleSubmit,
  });

  if (!isEqual(prevInitialValues, initialValues)) {
    handleSetDefaultProps();
    setPrevInitialValues(initialValues);
  }

  return postComposer;
};

export default useAutopostingComposerStep;
