import cn from 'classnames';
import * as React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import { block } from './utils';

type Props = Pick<
  React.ComponentProps<typeof ConnectedModal>,
  'name' | 'title' | 'children' | 'className'
> & { disabled?: boolean };

function BillingCycleModal(props: Props) {
  const { title, name, children, className, disabled } = props;
  return (
    <ConnectedModal
      title={title}
      name={name}
      className={cn(className, block())}
      backdrop={disabled ? 'static' : true}
      keyboard={!disabled}
    >
      {children}
    </ConnectedModal>
  );
}

export default BillingCycleModal;
