import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { ModalBody, ModalFooter, ModalFooterButton } from 'components/Modal';
import { ConnectedModalInjectedProps } from 'containers/ConnectedModal/ConnectedModalComponent';
import { deleteTrack } from 'redux/modules/embed/actions/tracks';
import { tracksByIdSelector } from 'redux/modules/embed/selectors';
import { Dispatch } from 'redux/types';
import { createChainedFunction } from 'utils/functions';
import { block } from './utils';

export interface DeleteTrackModalContentsProps {
  trackId?: string;
}

const DeleteTrackModalContents: React.FC<DeleteTrackModalContentsProps &
  ConnectedModalInjectedProps> = ({ onHide, trackId }) => {
  const dispatch = useDispatch<Dispatch>();
  const tracks = useSelector(tracksByIdSelector);
  const trackName = tracks?.getIn([trackId, 'name']);

  return (
    <>
      <ModalBody className={block('body')}>
        <div>Are you sure you want to delete {trackName}?</div>
        <div>All content will be removed.</div>
      </ModalBody>
      <ModalFooter>
        <ModalFooterButton onClick={onHide}>Cancel</ModalFooterButton>
        <ModalFooterButton
          onClick={createChainedFunction(
            () => dispatch(deleteTrack(trackId)),
            onHide,
          )}
          theme="delete"
        >
          Delete
        </ModalFooterButton>
      </ModalFooter>
    </>
  );
};

export default DeleteTrackModalContents;
