import { ThunkAction } from 'redux/types';
import { Type } from './action-types';
import { WizardLastUsedStyle, WizardName } from './types';

export const setLastUsedStyleForWizard = (
  wizard: WizardName,
  style: WizardLastUsedStyle,
): ThunkAction<void> => dispatch =>
  dispatch({
    payload: { wizard, style },
    type: Type.WIZARD_LAST_USED_STYLE_SET,
  });
