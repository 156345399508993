import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  MonthlyPlanName,
  YearlyPlanName,
} from 'redux/middleware/api/plan-service';
import { createPlanAmountSelector } from 'redux/modules/pricing';

export default function useYearlyDiscount(
  monthly: MonthlyPlanName,
  yearly: YearlyPlanName,
): number {
  const monthylAmount = useSelector(
    useMemo(() => createPlanAmountSelector(monthly), [monthly]),
  );
  const yearlyAmount = useSelector(
    useMemo(() => createPlanAmountSelector(yearly), [yearly]),
  );
  const percent = 100 - (yearlyAmount / (12 * monthylAmount)) * 100;

  return Math.round(percent);
}
