import cn from 'classnames';
import * as React from 'react';

import { block } from '../utils';

interface PodcastSearchDefaultStateItemProps {
  children: React.ReactNode;
  className?: string;
  icon: React.ReactElement;
}

const PodcastSearchDefaultStateItem: React.FC<PodcastSearchDefaultStateItemProps> = ({
  children,
  className,
  icon,
}) => (
  <div className={cn(block('default-state-item'), className)}>
    {React.cloneElement(icon, {
      className: cn(block('default-state-icon'), icon.props.className),
    })}
    <span className={block('default-state-text')}>{children}</span>
  </div>
);

export default PodcastSearchDefaultStateItem;
