import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A>(method: M, args: A) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export const putImageData = (imageData: string): types.UploadImageAction =>
  apiCallAction(types.ServiceMethod.UPLOAD_IMAGE, [
    imageData,
  ] as types.UploadImageArgs);

export const putImageBlob = (imageBlob: Blob): types.UploadImageBlobAction =>
  apiCallAction(types.ServiceMethod.UPLOAD_IMAGE_BLOB, [
    imageBlob,
  ] as types.UploadImageBlobArgs);

export const getImageByUrl = (
  url: string,
  maxWidth?: number,
  maxHeight?: number,
): types.GetDataUrlAction =>
  apiCallAction(types.ServiceMethod.GET_DATA_URL, [
    url,
    maxWidth,
    maxHeight,
  ] as types.GetDataUrlArgs);
