import * as React from 'react';
import { Step } from 'react-joyride';
import StepItem from 'blocks/OnboardingAnimations/components/StepItem';

export const COPY_KEY = 'copy';

const steps: Step[] = [
  {
    target: `[data-joyride-spotlight=${COPY_KEY}]`,
    spotlightPadding: 24,
    content: (
      <StepItem content="Here’s your first project, simply click here to copy it into a different aspect ratio." />
    ),
    disableBeacon: true,
    offset: 0,
    floaterProps: {
      target: `[data-joyride-tooltip=${COPY_KEY}]`,
    } as any,
  },
];

export default steps;
