import React, { useCallback } from 'react';

import FadingScrollBars from 'components/FadingScrollBars';
import { H3 } from 'components/Heading';
import { block } from './utils';

interface AudioClipCardTextProps {
  text: string;
}

const AudioClipCardText: React.FC<AudioClipCardTextProps> = ({ text }) => {
  const renderTrackVertical = useCallback(
    ({ style, ...props }) => (
      <div
        {...props}
        style={{
          ...style,
          borderRadius: 3,
          bottom: 2,
          padding: '15px 0px',
          right: 2,
          top: 2,
        }}
      />
    ),
    [],
  );

  const renderThumbVertical = useCallback(
    ({ style, ...props }) => (
      <div
        {...props}
        style={{
          ...style,
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          borderRadius: 'inherit',
          cursor: 'pointer',
          display: 'block',
          zIndex: 1,
        }}
      />
    ),
    [],
  );

  return (
    <FadingScrollBars
      className={block('text-container')}
      renderThumbVertical={renderThumbVertical}
      renderTrackVertical={renderTrackVertical}
    >
      <H3 className={block('text')}>{text}</H3>
    </FadingScrollBars>
  );
};

export default AudioClipCardText;
