import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogRadioContent from 'components/DialogRadioContent';

import {
  DurationOption,
  editAutomatioAudioSelectionSelector,
  editAutomationSetAudioSelectionDuration,
} from 'redux/modules/edit-automation';

import EditAutomationLoader from '../EditAutomationLoader';
import EditAutomationSelectHeader from '../EditAutomationSelectHeader';
import useEditAudioSelection from './useEditAudioSelection';

interface EditAutomationAudioSelectionLengthModalContentsProps {
  subscriptionId?: number;
}

const OPTIONS: {
  value: DurationOption;
  label: string;
  disabled?: boolean;
}[] = [
  {
    value: '15',
    label: '15 seconds',
  },
  {
    value: '30',
    label: '30 seconds',
  },
  {
    value: '45',
    label: '45 seconds',
  },
  {
    value: '60',
    label: '60 seconds',
  },
  {
    value: '600',
    label: '10  minutes',
  },
];

const EditAutomationAudioSelectionLengthModalContents: React.FunctionComponent<EditAutomationAudioSelectionLengthModalContentsProps> = props => {
  const { subscriptionId } = props;

  const {
    duration: currSelectedDuration,
    type: currSelectedType,
  } = useSelector(editAutomatioAudioSelectionSelector);
  const {
    onConfirmDurationSelection,
    onGoBackToAudioSelection,
  } = useEditAudioSelection(subscriptionId);

  const dispatch = useDispatch();

  const handleSelectDuration = React.useCallback(
    (nextDuration: DurationOption) => {
      dispatch(editAutomationSetAudioSelectionDuration(nextDuration));
    },
    [dispatch],
  );

  const handleGoBack = React.useCallback(() => {
    dispatch(editAutomationSetAudioSelectionDuration(undefined));
    onGoBackToAudioSelection();
  }, [dispatch, onGoBackToAudioSelection]);

  const handleSubmit = React.useCallback(() => {
    onConfirmDurationSelection(currSelectedDuration, currSelectedType);
  }, [currSelectedDuration, currSelectedType, onConfirmDurationSelection]);

  return (
    <>
      <DialogRadioContent
        actions={[
          {
            children: 'select this duration',
            disabled: !currSelectedDuration,
            fluid: true,
            key: 'submit',
            onClick: handleSubmit,
            theme: 'submit',
          },
        ]}
        label={
          <EditAutomationSelectHeader
            onGoBack={handleGoBack}
            title="How long should each clip be?"
          />
        }
        onChange={handleSelectDuration}
        options={OPTIONS}
        value={currSelectedDuration}
      />
      <EditAutomationLoader />
    </>
  );
};

export default EditAutomationAudioSelectionLengthModalContents;
