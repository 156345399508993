import React from 'react';

import AutomationPostModalBackButton from 'components/AutomationPostModalBackButton';
import TagsInput from '../TagsInput';
import { TagsProps } from '../TagsInput/types';
import { block } from '../utils';
import YoutubeCategorySelector, {
  YoutubeCategorySelectorProps,
} from '../YoutubeCategorySelector';
import YoutubeLanguageSelector, {
  YoutubeLanguageSelectorProps,
} from '../YoutubeLanguageSelector/YoutubeLanguageSelector';
import YoutubeSettings, { YoutubeSettingsProps } from '../YoutubeSettings';

type PickedSettingsProps = Pick<
  YoutubeSettingsProps,
  'madeForKids' | 'onMadeForKidsChange' | 'onVisibilityChange' | 'visibility'
>;

export interface YoutubeManualPostAdditionalDetailsProps
  extends PickedSettingsProps {
  categories: YoutubeCategorySelectorProps['options'];
  categoryId: YoutubeCategorySelectorProps['value'];
  language: YoutubeLanguageSelectorProps['value'];
  isMetadataEnabled?: TagsProps['isMetadataEnabled'];
  languages: YoutubeLanguageSelectorProps['languageOptions'];
  onCategoryIdChange: YoutubeCategorySelectorProps['onChange'];
  onClickBack: () => void;
  onLanguageChange: YoutubeLanguageSelectorProps['onChange'];
  onTagsChange: TagsProps['onChange'];
  tags: TagsProps['defaultValue'];
}

const YoutubeManualPostAdditionalDetails: React.FC<YoutubeManualPostAdditionalDetailsProps> = props => {
  const {
    categories,
    categoryId,
    language,
    isMetadataEnabled = false,
    languages,
    madeForKids,
    onCategoryIdChange,
    onClickBack,
    onLanguageChange,
    onMadeForKidsChange,
    onTagsChange,
    onVisibilityChange,
    tags,
    visibility,
  } = props;

  return (
    <div className={block('share-form')}>
      <AutomationPostModalBackButton
        text="Additional details"
        onClick={onClickBack}
      />
      <YoutubeSettings
        madeForKids={madeForKids}
        onMadeForKidsChange={onMadeForKidsChange}
        onVisibilityChange={onVisibilityChange}
        visibility={visibility}
      />
      <YoutubeCategorySelector
        options={categories}
        onChange={onCategoryIdChange}
        value={categoryId}
      />
      <YoutubeLanguageSelector
        languageOptions={languages}
        onChange={onLanguageChange}
        value={language}
      />
      <TagsInput
        defaultValue={tags}
        onChange={onTagsChange}
        isMetadataEnabled={isMetadataEnabled}
      />
    </div>
  );
};

export default YoutubeManualPostAdditionalDetails;
