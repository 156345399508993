import cn from 'classnames';
import * as React from 'react';

import Button, { ButtonProps } from 'components/Button';

type IProps = ButtonProps;

const ModalFooterButton: React.SFC<IProps> = ({ className, ...restProps }) => (
  <Button className={cn('hl-modal__footer-button', className)} {...restProps} />
);

export default ModalFooterButton;
export { IProps as ModalFooterButtonProps };
