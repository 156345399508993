import cn from 'classnames';
import * as React from 'react';
import uncontrollable from 'uncontrollable';

import ToolbarButton from './ToolbarButton';
import ToolbarIcon from './ToolbarIcon';
import ToolbarTool from './ToolbarTool';
import { block } from './utils';

interface IProps {
  active?: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onToggle?: (active: boolean) => void;
}

export class ToggleButton extends React.Component<IProps> {
  private handleClick = () => {
    const { active, onToggle } = this.props;
    onToggle(!active);
  };

  public render() {
    const { active, children, className, disabled } = this.props;

    return (
      <ToolbarTool disabled={disabled}>
        <ToolbarButton
          className={cn(block('toggle-button', { enabled: active }), className)}
          disabled={disabled}
          onClick={this.handleClick}
        >
          <ToolbarIcon>{children}</ToolbarIcon>
        </ToolbarButton>
      </ToolbarTool>
    );
  }
}

export type ToggleButtonProps = IProps & {
  defaultActive?: boolean;
};

const UncontrolledToggleButton: React.ComponentClass<ToggleButtonProps> = uncontrollable(
  ToggleButton,
  {
    active: 'onToggle',
  },
);

export default UncontrolledToggleButton;
