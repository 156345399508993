import * as React from 'react';

import DeleteModal, { DeleteModalProps } from 'components/DeleteModal';

type PickedModalProps = Pick<
  DeleteModalProps,
  'onCancel' | 'onDelete' | 'onExited' | 'show' | 'title'
>;

export interface DeleteAssetModalProps extends PickedModalProps {
  assetName: string;
}

const DeleteAssetModal: React.FC<DeleteAssetModalProps> = ({
  assetName,
  onCancel,
  onDelete,
  onExited,
  show,
  title,
}) => (
  <DeleteModal
    {...{ onCancel, onDelete, onExited, show, title }}
    bodyText={<span>Are you sure you want to delete {assetName}?</span>}
  />
);

export default DeleteAssetModal;
