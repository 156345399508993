import * as React from 'react';

import { WaveformProps } from '../../types';

const Bars = React.forwardRef<SVGSVGElement, WaveformProps>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85 32"
    ref={ref}
    {...props}
  >
    <path d="M0.007 13.607h0.333v4.826h-0.333v-4.826z" />
    <path d="M0.672 11.434h0.332v9.173h-0.333v-9.173z" />
    <path d="M1.337 3.22h0.333v25.6h-0.333v-25.6z" />
    <path d="M2.002 7.151h0.333v17.738h-0.333v-17.738z" />
    <path d="M2.667 12.408h0.333v7.223h-0.333v-7.223z" />
    <path d="M3.332 10.746h0.333v10.547h-0.333v-10.547z" />
    <path d="M3.997 8.142h0.333v15.756h-0.333v-15.756z" />
    <path d="M4.662 10.651h0.333v10.739h-0.332v-10.739z" />
    <path d="M5.327 14.438h0.333v3.164h-0.332v-3.164z" />
    <path d="M5.992 14.486h0.332v3.068h-0.332v-3.068z" />
    <path d="M6.657 14.23h0.332v3.58h-0.332v-3.58z" />
    <path d="M7.322 11.993h0.332v8.054h-0.332v-8.054z" />
    <path d="M7.987 11.529h0.333v8.981h-0.332v-8.981z" />
    <path d="M8.652 13.543h0.332v4.954h-0.332v-4.954z" />
    <path d="M9.317 12.344h0.332v7.351h-0.332v-7.351z" />
    <path d="M9.982 5.857h0.332v20.327h-0.332v-20.327z" />
    <path d="M10.647 10.011h0.332v12.017h-0.332v-12.017z" />
    <path d="M11.312 14.31h0.332v3.42h-0.332v-3.42z" />
    <path d="M11.977 10.075h0.332v11.889h-0.332v-11.889z" />
    <path d="M12.642 8.749h0.332v14.542h-0.332v-14.542z" />
    <path d="M13.307 12.968h0.332v6.104h-0.332v-6.104z" />
    <path d="M13.972 12.408h0.332v7.223h-0.332v-7.223z" />
    <path d="M14.637 14.406h0.332v3.228h-0.332v-3.228z" />
    <path d="M15.302 14.39h0.332v3.26h-0.332v-3.26z" />
    <path d="M15.967 10.299h0.332v11.442h-0.332v-11.442z" />
    <path d="M16.632 11.929h0.332v8.182h-0.332v-8.182z" />
    <path d="M17.297 9.069h0.332v13.903h-0.332v-13.903z" />
    <path d="M17.962 13.383h0.332v5.273h-0.332v-5.273z" />
    <path d="M18.627 8.062h0.332v15.916h-0.332v-15.916z" />
    <path d="M19.292 11.482h0.332v9.077h-0.332v-9.077z" />
    <path d="M19.957 14.086h0.332v3.867h-0.332v-3.867z" />
    <path d="M20.622 11.29h0.332v9.46h-0.332v-9.46z" />
    <path d="M21.287 10.794h0.332v10.451h-0.332v-10.451z" />
    <path d="M21.952 10.698h0.332v10.643h-0.332v-10.643z" />
    <path d="M22.617 13.911h0.332v4.219h-0.332v-4.219z" />
    <path d="M23.282 14.486h0.332v3.068h-0.332v-3.068z" />
    <path d="M23.947 13.671h0.332v4.698h-0.332v-4.698z" />
    <path d="M24.612 12.76h0.332v6.52h-0.332v-6.52z" />
    <path d="M25.277 14.278h0.332v3.484h-0.332v-3.484z" />
    <path d="M25.942 14.47h0.332v3.1h-0.332v-3.1z" />
    <path d="M26.607 14.869h0.332v2.301h-0.332v-2.301z" />
    <path d="M27.272 14.598h0.332v2.844h-0.332v-2.844z" />
    <path d="M27.937 11.242h0.332v9.556h-0.332v-9.556z" />
    <path d="M28.602 12.456h0.332v7.127h-0.332v-7.127z" />
    <path d="M29.268 14.374h0.332v3.292h-0.332v-3.292z" />
    <path d="M29.933 11.833h0.332v8.374h-0.332v-8.374z" />
    <path d="M30.598 10.794h0.332v10.451h-0.332v-10.451z" />
    <path d="M31.263 12.696h0.332v6.648h-0.332v-6.648z" />
    <path d="M31.928 10.219h0.333v11.602h-0.332v-11.602z" />
    <path d="M32.593 2.948h0.333v26.143h-0.333v-26.143z" />
    <path d="M33.258 13.687h0.333v4.666h-0.333v-4.666z" />
    <path d="M33.923 13.287h0.333v5.465h-0.333v-5.465z" />
    <path d="M34.588 8.19h0.333v15.661h-0.333v-15.661z" />
    <path d="M35.253 11.45h0.333v9.141h-0.333v-9.141z" />
    <path d="M35.918 14.917h0.333v2.205h-0.333v-2.205z" />
    <path d="M36.583 11.018h0.333v10.004h-0.333v-10.004z" />
    <path d="M37.248 10.187h0.333v11.665h-0.333v-11.665z" />
    <path d="M37.913 13.223h0.333v5.593h-0.333v-5.593z" />
    <path d="M38.578 8.749h0.333v14.542h-0.333v-14.542z" />
    <path d="M39.243 12.169h0.333v7.702h-0.333v-7.702z" />
    <path d="M39.908 12.105h0.333v7.83h-0.333v-7.83z" />
    <path d="M40.573 9.644h0.333v12.752h-0.333v-12.752z" />
    <path d="M41.238 12.265h0.333v7.511h-0.333v-7.511z" />
    <path d="M41.903 13h0.333v6.040h-0.333v-6.040z" />
    <path d="M42.568 11.865h0.333v8.31h-0.333v-8.31z" />
    <path d="M43.233 11.418h0.333v9.205h-0.333v-9.205z" />
    <path d="M43.898 14.534h0.333v2.972h-0.333v-2.972z" />
    <path d="M44.563 15.269h0.333v1.502h-0.333v-1.502z" />
    <path d="M45.228 12.44h0.333v7.159h-0.333v-7.159z" />
    <path d="M45.893 12.968h0.333v6.104h-0.333v-6.104z" />
    <path d="M46.558 14.262h0.333v3.516h-0.333v-3.516z" />
    <path d="M47.223 12.712h0.333v6.616h-0.333v-6.616z" />
    <path d="M47.888 5.984h0.333v20.071h-0.333v-20.071z" />
    <path d="M48.553 12.344h0.333v7.351h-0.333v-7.351z" />
    <path d="M49.218 11.833h0.333v8.374h-0.333v-8.374z" />
    <path d="M49.883 10.187h0.333v11.665h-0.333v-11.665z" />
    <path d="M50.548 13h0.333v6.040h-0.333v-6.040z" />
    <path d="M51.213 13.655h0.333v4.73h-0.333v-4.73z" />
    <path d="M51.878 14.71h0.333v2.621h-0.333v-2.621z" />
    <path d="M52.543 13.479h0.333v5.082h-0.333v-5.082z" />
    <path d="M53.208 11.274h0.333v9.492h-0.333v-9.492z" />
    <path d="M53.873 12.392h0.333v7.255h-0.333v-7.255z" />
    <path d="M54.538 10.746h0.333v10.547h-0.333v-10.547z" />
    <path d="M55.203 9.868h0.333v12.305h-0.333v-12.305z" />
    <path d="M55.868 11.178h0.333v9.684h-0.333v-9.684z" />
    <path d="M56.533 10.555h0.333v10.93h-0.333v-10.93z" />
    <path d="M57.198 9.628h0.333v12.784h-0.333v-12.784z" />
    <path d="M57.863 12.073h0.333v7.894h-0.333v-7.894z" />
    <path d="M58.528 13.239h0.333v5.561h-0.333v-5.561z" />
    <path d="M59.193 14.71h0.333v2.621h-0.333v-2.621z" />
    <path d="M59.858 13.447h0.333v5.146h-0.333v-5.146z" />
    <path d="M60.523 11.37h0.333v9.3h-0.333v-9.3z" />
    <path d="M61.188 13.159h0.333v5.721h-0.333v-5.721z" />
    <path d="M61.853 14.598h0.333v2.844h-0.333v-2.844z" />
    <path d="M62.519 13.831h0.333v4.379h-0.333v-4.379z" />
    <path d="M63.184 0.040h0.333v31.96h-0.333v-31.96z" />
    <path d="M63.849 6.192h0.333v19.656h-0.333v-19.656z" />
    <path d="M64.514 11.865h0.332v8.31h-0.332v-8.31z" />
    <path d="M65.179 7.758h0.332v16.523h-0.332v-16.523z" />
    <path d="M65.844 10.107h0.332v11.825h-0.332v-11.825z" />
    <path d="M66.509 14.662h0.332v2.717h-0.332v-2.717z" />
    <path d="M67.174 14.55h0.332v2.94h-0.332v-2.94z" />
    <path d="M67.839 14.406h0.332v3.228h-0.332v-3.228z" />
    <path d="M68.504 9.42h0.332v13.2h-0.332v-13.2z" />
    <path d="M69.169 13.495h0.332v5.050h-0.332v-5.050z" />
    <path d="M69.834 12.68h0.332v6.68h-0.332v-6.68z" />
    <path d="M70.499 12.824h0.332v6.392h-0.332v-6.392z" />
    <path d="M71.164 14.118h0.332v3.803h-0.332v-3.803z" />
    <path d="M71.829 11.274h0.332v9.492h-0.332v-9.492z" />
    <path d="M72.494 12.568h0.332v6.903h-0.332v-6.903z" />
    <path d="M73.159 13.431h0.332v5.178h-0.332v-5.178z" />
    <path d="M73.824 11.226h0.332v9.588h-0.332v-9.588z" />
    <path d="M74.489 9.468h0.332v13.104h-0.332v-13.104z" />
    <path d="M75.154 14.278h0.332v3.484h-0.332v-3.484z" />
    <path d="M75.819 14.342h0.332v3.356h-0.332v-3.356z" />
    <path d="M76.484 14.23h0.332v3.58h-0.332v-3.58z" />
    <path d="M77.149 13.847h0.332v4.347h-0.332v-4.347z" />
    <path d="M77.814 12.249h0.332v7.543h-0.332v-7.543z" />
    <path d="M78.479 9.532h0.332v12.976h-0.332v-12.976z" />
    <path d="M79.144 4.45h0.332v23.139h-0.332v-23.139z" />
    <path d="M79.809 13.191h0.332v5.657h-0.332v-5.657z" />
    <path d="M80.474 9.244h0.332v13.551h-0.332v-13.551z" />
    <path d="M81.139 12.728h0.332v6.584h-0.332v-6.584z" />
    <path d="M81.804 11.178h0.332v9.684h-0.332v-9.684z" />
    <path d="M82.469 12.648h0.332v6.744h-0.332v-6.744z" />
    <path d="M83.134 13.639h0.332v4.762h-0.332v-4.762z" />
    <path d="M83.799 8.525h0.332v14.989h-0.332v-14.989z" />
    <path d="M84.464 12.057h0.332v7.926h-0.332v-7.926z" />
  </svg>
));

export default Bars;
