import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import BemCssTransition from 'components/BemCssTransition';
import CloseIconButton from 'components/CloseIconButton';
import { IconButtonProps } from 'components/IconButton';
import { useNavigation } from './context/NavigationContext';
import { optionsBlock as block } from './utils';

export interface OptionChildViewProps {
  active?: boolean;
  bodyClassName?: string;
  children?: React.ReactNode;
  className?: string;
  icon: React.ReactElement;
  onClose?: IconButtonProps['onClick'];
  title: string;
  footer?: React.ReactNode;
}

/*
 * child view in the OptionsPane
 */
const OptionChildView: React.FC<OptionChildViewProps> = ({
  bodyClassName,
  active,
  children,
  className,
  icon,
  onClose = noop,
  title,
  footer,
}) => {
  const [state] = useNavigation();

  const switching = !!(state.context as any).pendingChildView;

  return (
    <BemCssTransition
      className={cn(block('child-view', { switch: switching }), className)}
      in={active}
      mountOnEnter
      transitionClassName={block('child-view-transition')}
      timeout={{
        enter: switching ? 150 : 450,
        exit: switching ? 150 : 300,
      }}
      unmountOnExit
    >
      <div>
        <BemCssTransition
          className={block('child-view-header')}
          in={active}
          timeout={{
            enter: switching ? 0 : 450,
            exit: switching ? 0 : 300,
          }}
        >
          <div>
            <div className={block('child-view-header-elements')}>
              {React.cloneElement(icon, {
                className: cn(block('child-view-icon'), icon.props.className),
              })}
              <span className={block('child-view-title')}>{title}</span>
              <CloseIconButton
                className={block('child-view-close')}
                onClick={onClose}
                showLabel
              />
            </div>
            <hr className={block('child-view-divider')} />
          </div>
        </BemCssTransition>
        <div className={cn(block('child-view-body'), bodyClassName)}>
          <div className={block('child-view-contents')}>{children}</div>
          {footer && <div className={block('child-view-footer')}>{footer}</div>}
        </div>
      </div>
    </BemCssTransition>
  );
};

export default OptionChildView;
