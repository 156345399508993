import * as React from 'react';
import { isUndefined } from 'underscore';

export const REDRAW_DELAY_MILLIS = 50;

interface IProps {
  playing?: boolean;
  video: HTMLVideoElement;
  videoClientHeight: number;
  videoClientWidth: number;
}

export default class BlurredVideoBackground extends React.Component<IProps> {
  private canvas: HTMLCanvasElement;
  private canvasContext: CanvasRenderingContext2D;
  private drawTaskId: number;

  public componentDidMount() {
    const { video } = this.props;

    this.canvasContext = this.canvas.getContext('2d');
    this.forceDraw();

    // when modal opened, black background was displayed instead of the first frame of the video
    video.addEventListener('canplaythrough', this.forceDraw);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const { playing: nextPlaying } = nextProps;
    const { playing } = this.props;

    // if playback has just started
    if (!playing && nextPlaying) {
      this.draw({ props: nextProps, force: true });
    }
  }

  public componentWillUnmount() {
    const { video } = this.props;

    if (!isUndefined(this.drawTaskId)) {
      window.clearTimeout(this.drawTaskId);
      this.drawTaskId = undefined;
    }

    video.removeEventListener('canplaythrough', this.forceDraw);
  }

  private forceDraw = () => {
    this.draw({ force: true });
  };

  private draw = ({ props = this.props, force = false } = {}) => {
    const { playing, video, videoClientHeight, videoClientWidth } = props;

    if (!isUndefined(this.drawTaskId)) {
      this.drawTaskId = undefined;
    }

    if (!playing && !force) return;

    this.canvasContext.drawImage(
      video,
      0,
      0,
      videoClientWidth,
      videoClientHeight,
    );
    this.drawTaskId = window.setTimeout(this.draw, REDRAW_DELAY_MILLIS);
  };

  private setCanvasRef = (el: HTMLCanvasElement) => (this.canvas = el);

  public render() {
    const { videoClientHeight, videoClientWidth } = this.props;

    return (
      <canvas
        className="blurred-video-bg"
        height={videoClientHeight}
        ref={this.setCanvasRef}
        width={videoClientWidth}
      />
    );
  }
}

export { IProps as BlurredVideoBackgroundProps };
