import { List } from 'immutable';

import { AudioTrackType, LayerOrderType, TrackType } from 'types';
import { getValue, isImmutable } from 'utils/collections';

export const DEFAULT_TRACK_ORDER: Readonly<TrackType[]> = [
  'text',
  'text',
  'media',
  'audio',
  'audio',
];

export const TRACK_TYPE_ORDER: Readonly<TrackType[]> = [
  'text',
  'waveform',
  'media',
  'audio',
];

export function defaultTrackIndex(type: TrackType, index: number = 0) {
  const tracksOfType = DEFAULT_TRACK_ORDER.map((trackType, idx) => ({
    trackType,
    index: idx,
  })).filter(track => track.trackType === type);

  if (tracksOfType.length === 0) return -1;

  return getValue(tracksOfType, [index, 'index'], -1);
}

function getIdsOfType(tracksById, type) {
  return isImmutable(tracksById)
    ? tracksById
        .filter(t => t.get('type') === type)
        .keySeq()
        .toArray()
    : Object.keys(tracksById).filter(id => tracksById[id].type === type);
}

export function getPreviousGroupType(type: TrackType): TrackType {
  return TRACK_TYPE_ORDER[TRACK_TYPE_ORDER.indexOf(type) - 1];
}

function getLastIndexOfPreviousGroup(trackTypes: TrackType[], type: TrackType) {
  const previousGroupType =
    DEFAULT_TRACK_ORDER[DEFAULT_TRACK_ORDER.indexOf(type) - 1];

  if (!previousGroupType) {
    return -1;
  }

  const lastIndexOfPreviousGroup = trackTypes.lastIndexOf(previousGroupType);

  return lastIndexOfPreviousGroup >= 0
    ? lastIndexOfPreviousGroup
    : getLastIndexOfPreviousGroup(trackTypes, previousGroupType);
}

function getTrackGroupIndexes(trackTypes: TrackType[], type: TrackType) {
  const firstIndexOfType = trackTypes.findIndex(t => t === type);

  const startIndex =
    firstIndexOfType >= 0
      ? firstIndexOfType
      : getLastIndexOfPreviousGroup(trackTypes, type);

  const endIndex = Math.max(trackTypes.lastIndexOf(type), startIndex);

  return [startIndex, endIndex];
}

export function getNewTrackIndexFromTrackTypes(
  trackTypes: TrackType[],
  newTrackType: TrackType,
  layerOrderType: LayerOrderType = 'grouped',
): number {
  if (layerOrderType === 'free') {
    return 0;
  }

  const [startIndex, endIndex] = getTrackGroupIndexes(trackTypes, newTrackType);

  const nTracksOfType = endIndex - startIndex;

  if (nTracksOfType === 0 && newTrackType === 'waveform') {
    // TODO temporary to insert the waveform track into its legacy default location.
    return getLastIndexOfPreviousGroup(trackTypes, 'media') + 1;
  }

  return endIndex + 1;
}

export function getNewTrackIndex(
  trackIds: List<string> | string[],
  tracksById,
  newTrackType: TrackType,
  layerOrderType: LayerOrderType = 'grouped',
) {
  return getNewTrackIndexFromTrackTypes(
    (trackIds as any).map(id => getValue(tracksById, [id, 'type'])),
    newTrackType,
    layerOrderType,
  );
}

// TODO there is another instance of this function in Timeline/containers/Timeline/index.ts
export function canDeleteTrack(tracksById, trackId: string) {
  const track = tracksById[trackId];
  const nDefaultTracks = DEFAULT_TRACK_ORDER.filter(type => type === track.type)
    .length;
  const nTracksOfType = getIdsOfType(tracksById, track.type).length;

  return nTracksOfType - 1 >= nDefaultTracks;
}

export function getDefaultTrackOrder(soundwave?: boolean): TrackType[] {
  const order = [...DEFAULT_TRACK_ORDER];
  if (!soundwave) return order;

  const waveformIndex = getNewTrackIndexFromTrackTypes(order, 'waveform');

  order.splice(waveformIndex, 0, 'waveform');

  return order;
}

export function isBgAudioTrack(
  trackId: string,
  trackIds: List<string> | string[],
  tracksById,
) {
  const audioTrackIds = (trackIds as any).filter(
    id => getValue(tracksById, [id, 'type']) === 'audio',
  );
  return audioTrackIds.indexOf(trackId) > 0;
}

export function getAudioTrackType(
  trackId: string,
  trackIds: List<string> | string[],
  tracksById,
): AudioTrackType {
  return isBgAudioTrack(trackId, trackIds, tracksById) ? 'background' : 'main';
}
