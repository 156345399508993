import { MetadataField } from 'components/Metadata/types';
import { PodcastRemoteEpisode } from 'redux/middleware/api/podcast-service';
import bem from 'utils/bem';
import { HEADLINER_SHOUTOUT } from 'utils/constants';
import { EpisodeMetadataInfo, MetadataToEpisodeInfo } from './types';

export const block = bem('youtube-post-modal');

export const MAX_CHARS_TITLE = 100;
export const MAX_CHARS_DESCRIPTION = 5000;

export const MAX_CHARS_TITLE_EXTRA = 1000000;
export const MAX_CHARS_DESCRIPTION_EXTRA = 1000000;

export const videoTitleMetadata: MetadataField[] = [
  {
    label: 'Podcast Title',
    value: '<Podcast Title>',
  },
  {
    label: 'Episode Title',
    value: '<Episode Title>',
  },
  {
    label: 'Episode Number',
    value: '<Episode Number>',
  },
];

export const videoDescriptionMetadata: MetadataField[] = [
  {
    label: 'Podcast Title',
    value: '<Podcast Title>',
  },
  {
    label: 'Podcast Description',
    value: '<Podcast Description>',
  },
  {
    label: 'Episode Title',
    value: '<Episode Title>',
  },
  {
    label: 'Episode Number',
    value: '<Episode Number>',
  },
  {
    label: 'Episode Description',
    value: '<Episode Description>',
  },
  {
    label: 'Link To Episode',
    value: '<Link To Episode>',
  },
  {
    label: 'Headliner Shoutout',
    value: HEADLINER_SHOUTOUT,
  },
];

const metadataToEpisodeInfoMap: MetadataToEpisodeInfo = {
  '<Podcast Title>': 'podcastTitle',
  '<Episode Title>': 'episodeTitle',
  '<Episode Number>': 'episodeNumber',
  '<Podcast Description>': 'podcastDescription',
  '<Episode Description>': 'episodeDescription',
  '<Link To Episode>': 'linkToEpisode',
  '<categories>': 'categories',
};

const getFormattedEpisodeInfo = (
  episodeInfo: PodcastRemoteEpisode,
): EpisodeMetadataInfo => {
  if (!episodeInfo) {
    return undefined;
  }

  const {
    podcast,
    title,
    episodeNumber,
    description,
    url,
    sanitizedTags,
  } = episodeInfo;

  return {
    podcastTitle: podcast.title,
    episodeTitle: title,
    episodeNumber,
    podcastDescription: podcast.description,
    episodeDescription: description,
    linkToEpisode: url,
    categories: sanitizedTags,
  };
};

/**
 * This function replaces metadata in a given text with corresponding episode information.
 *
 * @param {string} text - A string that contains metadata placeholders that needs to be replaced with
 * actual episode information.
 * @param {EpisodeMetadataInfo} episodeInfo - An object containing information about an episode, such as its title
 * description, link etc. This information is used to replace placeholders in a given `text` string that correspond to
 * specific metadata keys.
 *
 * @returns A string that is the result of replacing metadata placeholders in the input `text` with
 * corresponding values from the `episodeInfo` object.
 */
export const replaceMetadataWithEpisodeInfo = (
  text: string,
  episodeInfo: PodcastRemoteEpisode,
): string => {
  if (!episodeInfo) {
    return text;
  }

  const formattedEpisodeInfo = getFormattedEpisodeInfo(episodeInfo);

  const result = Object.keys(metadataToEpisodeInfoMap).reduce(
    (prev, current) => {
      const currentEpisodeInfo =
        formattedEpisodeInfo[metadataToEpisodeInfoMap[current]];

      if (!currentEpisodeInfo) {
        return prev;
      }

      return prev.split(current).join(currentEpisodeInfo);
    },
    text,
  );

  return result;
};
