import * as React from 'react';

import LockIcon from 'components/IconWithTooltip';
import { block } from './utils';

export interface IState {
  showTooltip: boolean;
}

export default class LockOverlay extends React.Component<{}, IState> {
  public state: Readonly<IState> = {
    showTooltip: false,
  };

  private handleMouseEnter = () => this.setState({ showTooltip: true });

  private handleMouseLeave = () => this.setState({ showTooltip: false });

  public render() {
    const { showTooltip } = this.state;

    return (
      <div
        className={block()}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className={block('inner')}>
          <LockIcon
            tooltipId="lock-overlay"
            className={block('lock')}
            showTooltip={showTooltip}
          />
        </div>
      </div>
    );
  }
}
