import { Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import { Alert } from 'components/icons';

import { DEFAULT_EDUCATIONAL_CARD_CONTENT } from './constants';
import { block } from './utils';

interface EducationalCardProps {
  content?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}

const EducationalCard: React.FC<EducationalCardProps> = ({
  content = DEFAULT_EDUCATIONAL_CARD_CONTENT,
  className,
  children,
}) => {
  return (
    <Spacer
      orientation="horizontal"
      align="center"
      justify="center"
      className={cn(block(), className)}
    >
      <Spacer
        orientation="vertical"
        align="center"
        justify="center"
        className={block('alert')}
      >
        <Alert fill="#fff" background="transparent" width={18} height={18} />
      </Spacer>

      <Spacer
        orientation="vertical"
        align="center"
        justify="center"
        className={block('content-container')}
      >
        <span className={block('content')}>{content}</span>

        {children}
      </Spacer>
    </Spacer>
  );
};

export default EducationalCard;
