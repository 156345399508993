import cn from 'classnames';
import * as React from 'react';

import Modal from 'components/Modal';
import { block } from './utils';

export interface DeleteModalContentsProps {
  bodyClassName?: string;
  bodyText: React.ReactNode;
  cancelButtonTitle?: string;
  deleteButtonTitle?: string;
  footer?: React.ReactElement;
  onCancel?: () => void;
  onDelete?: () => void;
}

const DeleteModalContents: React.SFC<DeleteModalContentsProps> = ({
  bodyClassName,
  bodyText,
  cancelButtonTitle = 'cancel',
  deleteButtonTitle = 'delete',
  footer,
  onCancel,
  onDelete,
}) => (
  <>
    <Modal.Body className={cn(bodyClassName, block('body'))}>
      {bodyText}
    </Modal.Body>
    {footer || (
      <Modal.Footer>
        <Modal.FooterButtons>
          <Modal.FooterButton onClick={onCancel}>
            {cancelButtonTitle}
          </Modal.FooterButton>
          <Modal.FooterButton theme="delete" onClick={onDelete}>
            {deleteButtonTitle}
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    )}
  </>
);

DeleteModalContents.defaultProps = {
  cancelButtonTitle: 'Cancel',
  deleteButtonTitle: 'Delete',
};

export default DeleteModalContents;
