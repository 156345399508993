import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'components/Tooltip';
import {
  makePodcastArtworkSelector,
  makePodcastTitleSelector,
} from 'redux/modules/podcast-search/selectors';

interface Props {
  podcastId: string;
  className?: string;
  onClick?: (podcastId: string) => void;
}

function FavoritePodcastBarImage(props: Props) {
  const { podcastId, className, onClick } = props;
  const source = useSelector(
    useMemo(() => makePodcastArtworkSelector(podcastId), [podcastId]),
  );
  const title = useSelector(
    useMemo(() => makePodcastTitleSelector(podcastId), [podcastId]),
  );
  return (
    <button
      className={className}
      type="button"
      onClick={() => onClick?.(podcastId)}
    >
      <Tooltip
        animation={false}
        content={title}
        id={`FavoritePodcastBarImage[${podcastId}]`}
        placement="top"
        preventHideOnHover={false}
      >
        <img src={source} alt={title} width={45} height={45} />
      </Tooltip>
    </button>
  );
}

export default FavoritePodcastBarImage;
