import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { videoExportEmailEnabledSelector } from 'redux/selectors';
import { State } from 'redux/types';
import { PropsOf } from 'types';
import { FormState } from '../../types';
import useFormState from './useFormState';
import VideoExportForm, {
  VideoExportFormProps as Props,
} from './VideoExportForm';

type StateProps = Pick<Props, 'hideEmailInput'>;

const hideEmailInputSelector = createSelector(
  videoExportEmailEnabledSelector,
  emailEnabled => !emailEnabled,
);

const mapStateToProps = (state: State): StateProps => ({
  hideEmailInput: hideEmailInputSelector(state),
});

const component = connect(mapStateToProps)(VideoExportForm);
export type VideoExportFormProps = PropsOf<typeof component>;
export default component;

export { useFormState, FormState };
export { LockableField } from './VideoExportForm';
