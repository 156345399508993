import Immutable from 'immutable';

import * as types from './types';

const defaultState = Immutable.Map();

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.OAUTH_TOKEN_GET_SUCCESS: {
      if (!action.payload) return state;
      const { token, provider, expiresAtMillis } = action.payload;
      return state.set(provider, Immutable.Map({ token, expiresAtMillis }));
    }

    case types.OAUTH_STATE_CLEAR:
      return defaultState;

    default:
      return state;
  }
}
