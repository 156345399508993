import * as React from 'react';

import { IconProps } from './types';

const Gift: React.SFC<IconProps> = props => {
  const { fill = 'currentColor' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 54" {...props}>
      <path
        fill={fill}
        fillRule="nonzero"
        d="M24 8.5a4.5 4.5 0 1 0-4.5 4.5H24V8.5zm4 0V13h4.5A4.5 4.5 0 1 0 28 8.5zM28 50h16V27H28v23zm-4 0V27H8v23h16zm0-27v-6H4v6h20zm4 0h20v-6H28v6zM12.288 13A8.5 8.5 0 0 1 26 3.022 8.5 8.5 0 0 1 39.712 13H50a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-2v25a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V27H2a2 2 0 0 1-2-2V15a2 2 0 0 1 2-2h10.288z"
      />
    </svg>
  );
};

export default Gift;
