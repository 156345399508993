import { replace } from 'connected-react-router/immutable';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import {
  connectedReduxRedirect,
  connectedRouterRedirect,
  ConnectedRouterRedirectConfig,
} from 'redux-auth-wrapper/history4/redirect';
import { compose } from 'underscore';

import { canReclipSelector } from 'redux/modules/download/selectors';
import { isNotEnterpriseApiSelector } from 'redux/modules/pricing';
import { appHomeSelector } from 'redux/modules/router';
import { hasAnsweredSurveySelector } from 'redux/modules/survey';
import { State } from 'redux/types';
import * as authActions from '../../redux/modules/auth';
import withLogout from './withClearState';

const locationHelper = locationHelperBuilder({});

function redirectOrGoHome(_, props) {
  return locationHelper.getRedirectQueryParam(props) || appHomeSelector();
}

/*
 * aside from just protecting routes, this HOC handles logging a user out when
 * their token expires.  special handling is needed in that case since we want to
 * clear state on logout, but redux-auth-wrapper by default only handles redirecting
 * the user somewhere.
 *
 * NB: this is legacy behavior which was updated in SPAR-10773 to clear state on
 * logout.  logging a user out when their token expires should probably be handled
 * in a different way as not every action will trigger the auth wrapper to check
 * the validity of the token and the user can still perform certain actions without
 * triggering a redirect.  the user might get unexpected results from performing
 * these actions since they are technically exectued in an unauthenticated context
 */
export const userIsAuthenticated = connectedReduxRedirect({
  authenticatedSelector: authActions.loggedInSelector,
  // @ts-ignore: we're using thunk middleware but the auth wrapper doesn't know
  // that and will throw an error given the thunk signature doesn't match what
  // the wrapper is expecting
  redirectAction: location => (dispatch, getState) => {
    const token = authActions.tokenSelector(getState());

    // if a token is in state and the user is being redirected, that means token
    // is invalid or some other condition caused logout.  clear state to prepare
    // for a login or registration action.  if token doesn't exist, redirect as
    // usual
    if (token) {
      dispatch(authActions.logout(false));
    }

    dispatch(replace(location));
  },
  redirectPath: '/login',
  wrapperDisplayName: 'UserIsAuthenticated',
});

function createUserIsNotAuthenticatedHoc<P = {}, S = {}>(
  config: Partial<ConnectedRouterRedirectConfig<P, S>>,
) {
  // when a user logs out (either manually or via expired token), the redirect
  // handler of the userIsAuthenticated hoc takes care of clearing login info
  // from state, however if the user's token expires and the first page they
  // visit after that is a "userIsNotAuthenticated" route, the userIsAuthenticated
  // hoc redirect handler isn't called and logout state isn't cleared, leading to issues.
  // this hoc will wrap such componoents so that state is cleared as if the
  // authenticated hoc redirect handler were called
  const logoutHoc = withLogout();
  const unauthenticatedHoc = connectedRouterRedirect({
    allowRedirectBack: false,
    authenticatedSelector: authActions.loggedOutSelector,
    redirectAction: replace,
    redirectPath: redirectOrGoHome,
    ...config,
  });

  return compose(unauthenticatedHoc, logoutHoc);
}

export const userIsNotAuthenticated = createUserIsNotAuthenticatedHoc({
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

export const userIsNotSignedUp = createUserIsNotAuthenticatedHoc({
  // keep sign-up query params
  redirectPath: () => ['/onboarding', window.location.search].join(''),
  wrapperDisplayName: 'UserIsNotSignedUp',
});

export const userIsNotOnboarded = compose(
  userIsAuthenticated,
  connectedRouterRedirect<{}, State>({
    allowRedirectBack: false,
    // if answeredSurvey is undefined, that means we're still waiting for some
    // data to determine if the user has answered it.  give the user the benefit
    // of the doubt and don't redirect until we know whether or not we need to
    authenticatedSelector: state => {
      const answeredSurvey = hasAnsweredSurveySelector(state);
      return answeredSurvey === undefined ? true : !answeredSurvey;
    },
    redirectAction: replace,
    redirectPath: redirectOrGoHome,
    wrapperDisplayName: 'UserIsNotOnboarded',
  }),
);

export const userIsOnboarded = connectedRouterRedirect<{}, State>({
  allowRedirectBack: false,
  // if answeredSurvey is undefined, that means we're still waiting for some
  // data to determine if the user has answered it.  give the user the benefit
  // of the doubt and don't redirect until we know whether or not we need to
  authenticatedSelector: state => {
    const answeredSurvey = hasAnsweredSurveySelector(state);
    return answeredSurvey === undefined ? true : answeredSurvey;
  },
  redirectAction: replace,
  redirectPath: '/onboarding',
  wrapperDisplayName: 'UserIsOnboarded',
});

export const userIsAuthenticatedAndOnboarded = compose(
  userIsAuthenticated,
  userIsOnboarded,
);

export const hideUnlessAuthenticated = connectedAuthWrapper({
  authenticatedSelector: authActions.loggedInSelector,
  wrapperDisplayName: 'HideUnlessAuthenticted',
});

export const userIsNotEnterpriseOrApi = connectedRouterRedirect<{}, State>({
  allowRedirectBack: false,
  authenticatedSelector: state => {
    const isNotEnterpriseApi = isNotEnterpriseApiSelector(state);
    return isNotEnterpriseApi === undefined ? true : isNotEnterpriseApi;
  },
  redirectAction: replace,
  redirectPath: () => appHomeSelector(),
  wrapperDisplayName: 'UserIsNotEnterpriseOrApi',
});

export const canReclip = connectedRouterRedirect<{}, State>({
  allowRedirectBack: false,
  authenticatedSelector: state => canReclipSelector(state),
  redirectAction: replace,
  redirectPath: () => appHomeSelector(),
  wrapperDisplayName: 'UserIsNotFree',
});
