import * as React from 'react';

import { IconProps } from './types';

const Timer: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor" fillRule="nonzero">
        <g>
          <g>
            <path
              d="M22.978 27.83v-2.05H17v2.05h5.978zM30.584 33c4.885 0 5.93-5.293 5.93-8.927 0-3.78-1.045-8.927-5.93-8.927-4.884 0-5.93 5.147-5.93 8.927 0 3.634 1.046 8.927 5.93 8.927zm0-2.049c-3.256 0-3.596-4.634-3.596-6.878 0-2.39.34-6.878 3.596-6.878 3.186 0 3.58 4.295 3.596 6.72v.158c0 2.244-.34 6.878-3.596 6.878zm10.134-6.731c.874 0 1.603-.732 1.603-1.61 0-.878-.729-1.61-1.603-1.61-.875 0-1.604.732-1.604 1.61 0 .878.729 1.61 1.604 1.61zm0 8.634c.874 0 1.603-.732 1.603-1.61 0-.878-.729-1.61-1.603-1.61-.875 0-1.604.732-1.604 1.61 0 .878.729 1.61 1.604 1.61zM50.85 33c4.884 0 5.93-5.293 5.93-8.927 0-3.78-1.046-8.927-5.93-8.927-4.884 0-5.93 5.147-5.93 8.927 0 3.634 1.046 8.927 5.93 8.927zm0-2.049c-3.186 0-3.58-4.435-3.596-6.728v-.15c0-2.39.34-6.878 3.596-6.878 3.256 0 3.597 4.488 3.597 6.878 0 2.244-.34 6.878-3.597 6.878zM64.265 33C67.303 33 70 31.317 70 28c0-2.122-1.215-3.976-3.378-4.341v-.05c1.75-.511 2.94-2.097 2.94-3.902 0-3.195-2.454-4.707-5.273-4.707-2.284 0-4.3 1.049-5.2 3.244l2.381.805c.51-1.195 1.604-2 2.868-2 1.652 0 2.892.975 2.892 2.805 0 1.707-.85 2.707-4.399 2.707v2.049c2.187 0 4.836.341 4.836 3.22 0 1.853-1.53 3.121-3.33 3.121-1.822 0-2.6-.805-3.158-2.463l-2.455.756c.778 2.61 2.917 3.756 5.541 3.756z"
              transform="translate(-474 -660) translate(0 138) translate(63 93) translate(394 414)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Timer;
