import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useYoutubePostModalSelector from 'containers/YoutubePostModal/useYoutubePostModalSelector';
import {
  getSelectedPlaylistsIds,
  YoutubePlaylistSelectorOption,
} from 'containers/YoutubePostModal/YoutubePlaylistSelector';
import useOnChange from 'hooks/useOnChange';
import usePrevious from 'hooks/usePrevious';
import useSet from 'hooks/useSet';
import { episodeUrlSelector } from 'redux/modules/download';
import {
  lastCreatedPlaylistIdSelector,
  youtubeCategoriesSelector,
  youtubeLanguagesListSelector,
  youtubePlaylistsSelector,
  youtubeUserDataSelector,
} from 'redux/modules/social';
import { PostStatus } from 'redux/modules/social/constants';
import { YoutubeVideoVisibility } from 'types';
import { addSocialUTMSource } from 'utils/social/add-social-utm-source';

import {
  UseManualPostComposerStep,
  UseManualPostComposerStepConfig,
} from './types';
import useAdditionalDetailsStep from './useAdditionalDetailsStep';
import usePostComposerStep from './usePostComposerStep';

const useManualPostComposerStep = (
  config: UseManualPostComposerStepConfig,
): UseManualPostComposerStep => {
  const {
    initialValues,
    activeStep,
    description,
    setDescription,
    resetDescription,
    submitButtonLabel = 'post your video',
    onAdditionalStepClick,
    onCreatePlaylistClick,
    onInitialStepClick,
    onSwitchAccountClick,
    onPostSuccess,
    onSubmit,
    onUnlockAICaptions,
    onCancelUnlockAICaptions,
  } = config;

  // Playlist creation step should be considered as an "active step" for the composer.
  // As active step is used for resetting the composer state to the default one, it is
  // required to avoid default state reset when switching modals for creating a playlist.
  const isPlaylistCreatorStepActive = activeStep === 'playlist';
  const isComposerStepActive = activeStep === 'composer';
  const isAdditionalDetailsStepActive =
    activeStep === 'additional-details-step';
  const isActive =
    isComposerStepActive ||
    isAdditionalDetailsStepActive ||
    isPlaylistCreatorStepActive;

  const {
    defaultTitle,
    defaultTags,
    avatarSrc,
    postStatus,
    username,
  } = useYoutubePostModalSelector({
    defaultTitle: initialValues.title,
    defaultTags: initialValues.tags ?? [],
  });

  const [categoryId, setCategoryId] = useState<number>(
    initialValues.categoryId,
  );
  const [language, setLanguage] = useState<string>(initialValues.language);
  const [madeForKids, setMadeForKids] = useState<boolean>(
    initialValues.madeForKids,
  );
  const [selectedPlaylistsIds, { initialize, toggle, reset }] = useSet<string>(
    new Set(initialValues.playlistsIds),
  );
  const [tags, setTags] = useState<string[]>(defaultTags);
  const [title, setTitle] = useState<string>(defaultTitle);
  const [visibility, setVisibility] = useState<YoutubeVideoVisibility>(
    initialValues.visibility,
  );
  const [prevActiveState, setPrevActiveState] = useState<boolean>(isActive);

  const { playlists: userPlaylists } = useSelector(youtubePlaylistsSelector);
  const categories = useSelector(youtubeCategoriesSelector);
  const episodeUrl = useSelector(episodeUrlSelector);
  const languages = useSelector(youtubeLanguagesListSelector);
  const ytAccessData = useSelector(youtubeUserDataSelector);
  const prevPostStatus = usePrevious(postStatus);
  const isUploading = postStatus === PostStatus.IN_PROGRESS;

  const handlePlaylistChange = (option: YoutubePlaylistSelectorOption) => {
    toggle(option.id);
  };

  const handleSubmit = useCallback(() => {
    onSubmit?.({
      defaultLanguage: language,
      title,
      description: addSocialUTMSource('youtube', episodeUrl, description),
      tags,
      madeForKids,
      categoryId,
      visibility,
      playlists: getSelectedPlaylistsIds(userPlaylists, selectedPlaylistsIds),
      // as some use cases where token can not be obtained from store, it can be tackeld
      // by returning access data from the modal
      ytAccessData,
    });
  }, [
    onSubmit,
    language,
    title,
    episodeUrl,
    description,
    tags,
    madeForKids,
    categoryId,
    visibility,
    userPlaylists,
    selectedPlaylistsIds,
    ytAccessData,
  ]);

  const handleSetDefaultProps = useCallback(() => {
    setTitle(defaultTitle);
    setTags(defaultTags);
    setMadeForKids(initialValues.madeForKids);
    setCategoryId(initialValues.categoryId);
    setVisibility(initialValues.visibility);
    setLanguage(initialValues.language);
    initialize(initialValues.playlistsIds);

    if (initialValues.description) {
      setDescription(initialValues.description);
    } else {
      resetDescription();
    }
  }, [
    defaultTitle,
    defaultTags,
    initialValues,
    initialize,
    setDescription,
    resetDescription,
  ]);

  const postComposer = usePostComposerStep({
    onUnlockAICaptions,
    onCancelUnlockAICaptions,
    avatarSrc,
    description,
    isUploading,
    playlists: userPlaylists,
    onAdditionalStepClick,
    onCreatePlaylistClick,
    onDescriptionChange: setDescription,
    onPlaylistChange: handlePlaylistChange,
    onSubmit: handleSubmit,
    onSwitchAccountClick,
    onTitleChange: setTitle,
    selectedPlaylistsIds,
    submitButtonLabel,
    title,
    username,
  });

  const additionalDetails = useAdditionalDetailsStep({
    isActive: isAdditionalDetailsStepActive,
    categories,
    categoryId,
    language,
    languages,
    madeForKids,
    onCategoryIdChange: setCategoryId,
    onGoBack: onInitialStepClick,
    onLanguageChange: setLanguage,
    onMadeForKidsChange: setMadeForKids,
    onTagsChange: setTags,
    onVisibilityChange: setVisibility,
    tags,
    visibility,
  });

  useOnChange(useSelector(lastCreatedPlaylistIdSelector), {
    onChange: playlistId => {
      if (playlistId) {
        toggle(playlistId);
      }
    },
  });

  useEffect(() => {
    if (
      prevPostStatus === PostStatus.IN_PROGRESS &&
      postStatus === PostStatus.SUCCESS
    ) {
      // Resets common form values
      handleSetDefaultProps();
      // Resets playlists form value
      reset();

      onPostSuccess?.();
    }
  }, [handleSetDefaultProps, onPostSuccess, postStatus, prevPostStatus, reset]);

  if (prevActiveState !== isActive) {
    handleSetDefaultProps();
    setPrevActiveState(isActive);
  }

  return {
    composerSteps: [postComposer, additionalDetails],
  };
};

export default useManualPostComposerStep;
