import { ThunkAction } from 'redux/types';
import { AspectRatioName, IEdgeVideos } from 'types';
import { getAspectRatio } from 'utils/aspect-ratio';

import { DEFAULT_TRACK_ORDER } from 'utils/embed/tracks';
import { round } from 'utils/numbers';
import { DEFAULT_PROJECT_NAME } from '../../../utils/constants';
import { defaultVideoExportSettingsSelectorByRatio } from '../display-pref';
import { mediaUploader } from '../embed/actions/edge-videos';
import { exportFromWizard } from '../wizard-export/actions';
import { defaultWatermarkSelector } from './selectors';

/**
 * As captions videos do not allow selecting the intro/outro, this option will obtain the default
 * preferences and, if necessary, upload the videos and set the edgeVideos object.
 * @param {string} aspectRatioName - Aspect ratio name, it is used for getting the default prefs
 * @returns {object} - edge videos object containing the video ids obtained (if required) for intro/outro
 */
export const processEdgeVideos = (
  aspectRatioName: AspectRatioName,
): ThunkAction<Promise<IEdgeVideos>> => (dispatch, getState) => {
  const {
    intro: introPrefs,
    outro: outroPrefs,
  } = defaultVideoExportSettingsSelectorByRatio(aspectRatioName)(getState());

  const targets = [introPrefs, outroPrefs].filter(target => !!target?.value);

  return Promise.all(
    targets.map(target => dispatch(mediaUploader(target.value, 'url'))),
  ).then(([introSrc, outroSrc]) => {
    const intro = introSrc ? { videoId: introSrc.id } : undefined;
    const outro = outroSrc ? { videoId: outroSrc.id } : undefined;
    return { intro, outro };
  });
};

export const createVideoTranscriptionProject = (
  aspectRatioName: AspectRatioName,
  videoSrc: string | File,
  durationMillis: number,
  trimStartMillis: number,
  trimEndMillis: number,
  language: string,
  uploadedVideoId?: number,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const ratio = getAspectRatio(aspectRatioName);
  const isFullClip =
    round(trimEndMillis) - round(trimStartMillis) === round(durationMillis);
  const edgeVideos = await dispatch(processEdgeVideos(aspectRatioName));

  return dispatch(
    exportFromWizard({
      aspectRatio: {
        width: ratio.get('width'),
        height: ratio.get('height'),
      },
      edgeVideos,
      projectName: DEFAULT_PROJECT_NAME,
      initiateExport: false,
      projectCreatMethod: 'videoTranscript',
      transcription: {
        transcribe: true,
        language,
      },
      video: {
        source: videoSrc,
        endMillis: isFullClip ? undefined : trimEndMillis,
        startMillis: isFullClip ? undefined : trimStartMillis,
        originalDurationMillis: durationMillis,
        uploadedVideoId,
      },
      textOverlays: [],
      slideshow: [],
      layers: [...DEFAULT_TRACK_ORDER],
      watermark: defaultWatermarkSelector(getState()),
      backgroundColor: undefined,
      templateId: undefined,
      mainImage: undefined,
      progress: undefined,
      soundwave: undefined,
      timer: undefined,
    }),
  );
};
