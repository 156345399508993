import * as React from 'react';

import { IconProps } from 'components/icons/types';

/* tslint:disable-next-line:variable-name */
const InstagramTemplate: React.SFC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    {...props}
  >
    <path
      fill="#23282C"
      fillRule="nonzero"
      d="M5.001 2.427a2.555 2.555 0 1 0 0 5.11 2.555 2.555 0 1 0 0-5.11zm0 4.216a1.667 1.667 0 0 1-1.666-1.66c0-.917.747-1.662 1.666-1.662.92 0 1.667.745 1.667 1.661S5.918 6.643 5 6.643zm3.266-4.32a.595.595 0 0 1-.598.596.595.595 0 1 1 0-1.192c.33 0 .598.267.598.596zm1.698.605c-.038-.799-.221-1.506-.808-2.088C8.573.257 7.863.075 7.062.035c-.825-.047-3.299-.047-4.124 0-.8.038-1.509.22-2.095.803-.587.582-.768 1.29-.808 2.087-.047.823-.047 3.289 0 4.112.038.798.221 1.505.808 2.087.586.583 1.294.765 2.095.805.825.047 3.299.047 4.124 0 .801-.037 1.51-.22 2.095-.805.585-.582.768-1.29.808-2.087.047-.823.047-3.287 0-4.11zM8.898 7.919c-.174.436-.51.772-.95.948-.658.26-2.22.2-2.947.2-.727 0-2.291.057-2.947-.2a1.684 1.684 0 0 1-.95-.948C.843 7.263.903 5.707.903 4.982c0-.725-.058-2.283.2-2.937.175-.436.512-.772.951-.947.658-.26 2.22-.2 2.947-.2.727 0 2.291-.058 2.947.2.437.173.774.509.95.947.262.656.201 2.212.201 2.937 0 .725.06 2.284-.2 2.937z"
    />
  </svg>
);

export default InstagramTemplate;
