import { Query } from 'types';
import bem from 'utils/bem';
import { VideoExportStatus } from './types';

export const block = bem('audio-wizard');

export function isSafeToCloseWizard(exportStatus: VideoExportStatus) {
  return exportStatus === 'rendering-video';
}

export function getTraceIdsFromQuery(query: Query = {}) {
  const { traceId, customTraceId, episodeId } = query;

  return {
    customTraceId: customTraceId as string,
    traceId: traceId as string,
    episodeId: parseInt(episodeId as string, 10) || null,
  };
}
