import {
  OverlayContainer,
  PopoverContainer,
  PopoverContainerProps,
  PopoverMenu,
  UnstyledPopoverOverlay,
} from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';

import { block } from './utils';

type PickedPopoverContainerProps = Pick<
  PopoverContainerProps,
  'isOpen' | 'offset' | 'placement' | 'triggerElement'
>;

interface ToolbarPopoverProps extends PickedPopoverContainerProps {
  children: React.ReactNode;
  className?: string;
  isDismissable?: boolean;
  onClose?: () => void;
}

const ToolbarPopover: React.FunctionComponent<ToolbarPopoverProps> = props => {
  const {
    children,
    className,
    isDismissable = true,
    isOpen,
    offset,
    onClose,
    placement,
    triggerElement,
  } = props;

  return (
    <OverlayContainer>
      <PopoverContainer
        isOpen={isOpen}
        offset={offset}
        placement={placement}
        preventOverflow={{ altAxis: true, mainAxis: false }}
        triggerElement={triggerElement}
      >
        <UnstyledPopoverOverlay
          isDismissable={isDismissable}
          isOpen={isOpen}
          onClose={onClose}
        >
          <PopoverMenu className={cn(block('popover-menu'), className)}>
            {children}
          </PopoverMenu>
        </UnstyledPopoverOverlay>
      </PopoverContainer>
    </OverlayContainer>
  );
};

export default ToolbarPopover;
