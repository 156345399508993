import * as React from 'react';

import { IntroOutro } from 'components/icons';
import Intro from 'components/icons/Intro';
import Outro from 'components/icons/Outro';

import { useEditorState } from '../context/VideoEditorStateContext';
import OptionChildView, { OptionChildViewProps } from '../OptionChildView';
import TileGrid from '../TileGrid';
import { introOutroChildViewBlock as block } from '../utils';
import IntroOutroSelectionTile from './IntroOutroSelectionTile';
import useIntroOutro from './useIntroOutro';

type PickedChildViewProps = Pick<OptionChildViewProps, 'active' | 'onClose'>;

export interface IntroOutroChildViewProps extends PickedChildViewProps {}

const ICON_SIZE = 60;

const IntroOutroChildView: React.FC<IntroOutroChildViewProps> = ({
  active,
  onClose,
}) => {
  const { isLoading } = useEditorState();
  const {
    introLocked,
    introOutro,
    onAddIntroOutro,
    onExpandIntroOutro,
    onLoadIntroOutro,
    outroLocked,
  } = useIntroOutro();

  const handleClickAddIntro = React.useCallback(() => {
    onAddIntroOutro('intro');
  }, [onAddIntroOutro]);

  const handleClickAddOutro = React.useCallback(() => {
    onAddIntroOutro('outro');
  }, [onAddIntroOutro]);

  const handleExpandIntro = React.useCallback(() => {
    onExpandIntroOutro('intro');
  }, [onExpandIntroOutro]);

  const handleExpandOutro = React.useCallback(() => {
    onExpandIntroOutro('outro');
  }, [onExpandIntroOutro]);

  const { id: introId, loaded: introLoaded } = introOutro?.intro ?? {};
  const { id: outroId, loaded: outroLoaded } = introOutro?.outro ?? {};

  React.useEffect(() => {
    if (introId && !introLoaded) {
      onLoadIntroOutro('intro', introId);
    }
    if (outroId && !outroLoaded) {
      onLoadIntroOutro('outro', outroId);
    }
  }, [introId, introLoaded, outroId, outroLoaded, onLoadIntroOutro]);

  return (
    <OptionChildView
      active={active}
      bodyClassName={block('body')}
      className={block()}
      icon={<IntroOutro height={23} width={30} />}
      onClose={onClose}
      title="INTRO / OUTRO"
    >
      <TileGrid className={block('tiles-container')}>
        <IntroOutroSelectionTile
          assetData={introOutro?.intro}
          description="Hook viewers before your main content begins."
          disabled={isLoading}
          icon={<Intro height={ICON_SIZE} width={ICON_SIZE} />}
          id="intro"
          locked={introLocked}
          onClickAdd={handleClickAddIntro}
          onClickThumbnail={handleExpandIntro}
          title="Intro clip"
          tooltipText="Add an intro clip"
        />
        <IntroOutroSelectionTile
          assetData={introOutro?.outro}
          description="Drive ideal outcomes by adding a call to action after your main content."
          disabled={isLoading}
          icon={<Outro height={ICON_SIZE} width={ICON_SIZE} />}
          id="outro"
          locked={outroLocked}
          onClickAdd={handleClickAddOutro}
          onClickThumbnail={handleExpandOutro}
          title="Outro clip"
          tooltipText="Add an outro clip"
        />
      </TileGrid>
    </OptionChildView>
  );
};

export default IntroOutroChildView;
