import memoize from 'memoizee';
import * as request from 'superagent';
import _ from 'underscore';

import { getContext } from './audio-context';

export function calculateBufferSize(millis: number, sampleRate: number) {
  return Math.ceil((millis * sampleRate) / 1000);
}

function blobToArrayBuffer(blob: Blob) {
  const reader = new FileReader();
  return new Promise<ArrayBuffer>(resolve => {
    reader.onloadend = () => resolve(reader.result as ArrayBuffer);
    reader.readAsArrayBuffer(blob);
  });
}

function fetchAudioData(url: string) {
  return request
    .get(url)
    .responseType('blob')
    .then(res => blobToArrayBuffer(res.body));
}

function decodeAudioDataPromise(ctx: AudioContext, data: ArrayBuffer) {
  return new Promise<AudioBuffer>((resolve, reject) => {
    ctx.decodeAudioData(
      data,
      audioBuffer => resolve(audioBuffer),
      err => reject(err),
    );
  });
}

export function startRendering(ctx: OfflineAudioContext) {
  return new Promise<AudioBuffer>(resolve => {
    ctx.oncomplete = (e: OfflineAudioCompletionEvent) =>
      resolve(e.renderedBuffer);
    ctx.startRendering();
  });
}

function loadAudio(ctx: AudioContext, url: string): Promise<AudioBuffer> {
  return url
    ? fetchAudioData(url).then(data => decodeAudioDataPromise(ctx, data))
    : Promise.resolve(undefined);
}

const memoizedLoadAudio = memoize(loadAudio, {
  normalizer(args) {
    return args[1];
  },
  primitive: true,
  promise: true,
  refCounter: true,
} as any);

export function removeAudioSource(url: string) {
  (memoizedLoadAudio as any).deleteRef(null, url);
}

export function createAudioSource<T extends { source: string | AudioBuffer }>(
  config: T,
  ctx: AudioContext = getContext(),
): Promise<T & { buffer: AudioBuffer }> {
  const { source } = config;

  const futBuffer =
    source instanceof AudioBuffer
      ? Promise.resolve(source)
      : memoizedLoadAudio(ctx, source);

  // NB: using _.extend because spread doesn't work with generics in Typescript
  return futBuffer.then(buffer => _.extend({}, config, { buffer }));
}

export function setGainAtTime(
  node: GainNode,
  value: number,
  time: number,
  immediate?: boolean,
) {
  if (time < 0) return;
  if (immediate) {
    // There are cases where some video assets have a click sound at the beginning when
    // using the strategy suggested at https://alemangui.github.io/ramp-to-value.
    // For avoiding that, if the gain change is set to immediate, this will avoid that
    // click sound
    node.gain.setValueAtTime(value, time);
  } else {
    // https://alemangui.github.io/ramp-to-value
    node.gain.setTargetAtTime(value, time, 0.015);
  }
}

export function rampToGainAtTime(
  node: GainNode,
  startValue: number,
  endValue: number,
  startSec: number,
  durationSec: number,
) {
  if (startSec < 0) return;
  node.gain.setValueAtTime(startValue, startSec);
  node.gain.linearRampToValueAtTime(endValue, startSec + durationSec);
}
