import * as React from 'react';

import { ProgressBar } from 'components/icons';
import { RefType } from 'types';
import { useEditorState } from '../context/VideoEditorStateContext';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { OPTION_TILE_FEATURE_PATHS } from '../state/constants';
import AccessControlOptionTile, {
  AccessControlOptionTileProps,
} from './AccessControlOptionTile';

type PickedTileProps<P = any> = Pick<
  AccessControlOptionTileProps<P>,
  'className' | 'onClick' | 'params'
>;

export type ProgressTileProps<P extends any> = PickedTileProps<P>;

const ProgressTile = React.forwardRef(
  <P extends {} = any>(
    { className, onClick, params }: ProgressTileProps<P>,
    ref: RefType<typeof AccessControlOptionTile>,
  ) => {
    const { disabled } = useEditorState();
    const dispatch = useTemplateDispatch();

    const handleChange = React.useCallback(
      (p, e) => {
        dispatch({
          type: 'PROGRESS_BAR_ADD',
        });

        onClick(p, e);
      },
      [dispatch, onClick],
    );

    return (
      <AccessControlOptionTile
        {...{ className, disabled, params }}
        featurePath={OPTION_TILE_FEATURE_PATHS.progress}
        icon={<ProgressBar width={70} />}
        id="template-progress-tile"
        label="progress"
        onClick={handleChange}
        ref={ref}
      />
    );
  },
);

export default ProgressTile;
