import { List, Record, RecordOf } from 'immutable';

// utils
import { reviveRecord } from 'utils/immutable';

// types
import {
  AppsConnectedState,
  AppsProviderStatus,
  AppsStatus,
  State,
} from './types';

export const appsProviderStatusFactory = Record<AppsProviderStatus>({
  provider: undefined,
  hasAccess: undefined,
});

const appStatusFactory = Record<AppsStatus>(
  {
    providers: List([]),
    status: undefined,
  },
  'AppsStatus',
);

export const stateFactory = Record<State>({
  appsStatus: appStatusFactory(),
  deleteStatus: undefined,
});

export function appsProviderStatusFromJs(
  status: AppsProviderStatus[],
): List<RecordOf<AppsProviderStatus>> {
  return List(status.map(val => appsProviderStatusFactory(val)));
}

export function stateFromJs(obj: Object): RecordOf<AppsConnectedState> {
  return reviveRecord(stateFactory, obj);
}
