import cn from 'classnames';
import * as React from 'react';

import bem from 'utils/bem';

export interface GradientBorderProps {
  children?: React.ReactNode;
  className?: string;
  as?: React.ReactType;
}

const block = bem('gradient-border');

const GradientBorder: React.FC<GradientBorderProps> = ({
  children,
  className,
  as: Component = 'div',
}) => {
  return <Component className={cn(block(), className)}>{children}</Component>;
};

export default GradientBorder;
