import React from 'react';

import LoadingOverlay from 'components/LoadingOverlay';
import { Step } from 'components/SteppedModal';

import { block } from '../utils';

const useAuthenticationProgressStep = (): Step => {
  return {
    id: 'authentication-in-progress',
    title: 'Authenticating with TikTok',
    fluidFooterButtons: true,
    hideFooter: true,
    modalClassName: block('dialog-content'),
    component: <LoadingOverlay small />,
  };
};

export default useAuthenticationProgressStep;
