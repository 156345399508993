import { useCallback } from 'react';
import { pick } from 'underscore';
import { useEditorState } from './context/VideoEditorStateContext';
import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import { useModalManager } from './ModalManager/ModalManagerContext';
import { getTextModalOverlay } from './state';

export default function useEditText() {
  const dispatch = useTemplateDispatch();
  const state = useTemplateState();
  const modalManager = useModalManager();
  const { integrations } = useEditorState();

  const { textIntegrations } = integrations;

  return useCallback(
    (id: string) => {
      const overlay = getTextModalOverlay(state, id);

      const integration =
        textIntegrations.find(
          i => i.id === overlay.getIn(['integrationData', 'id']),
        ) ?? textIntegrations[0];

      integration.onEditText(state, modalManager, id, {
        onDelete: () => {
          dispatch({
            payload: id,
            type: 'TEXT_DELETE',
          });
        },
        onSubmit: textOverlay => {
          dispatch({
            payload: {
              id,
              textOverlay,
              integrationData: pick(integration, ['id', 'type']),
            },
            type: 'TEXT_UPDATE',
          });
        },
      });
    },
    [dispatch, modalManager, state, textIntegrations],
  );
}
