import { AuthLinkProps } from '@sparemin/auth';
import React from 'react';
import Link from 'components/Link';
import { isResetPasswordActive } from 'utils/routes';

export type AuthProviderLinkProps = AuthLinkProps;

/**
 * A Link component for @sparemin/auth's AuthProvider to use to render links between
 * the various authentication pages.
 *
 * When switching between login and registration pages, we want to pass the query
 * parametes along with the redirect in case there are promo codes or other information
 * we use in the auth flow.  For reset password, the email sends a link with a lot
 * of query params (token, etc.).  There's no reason to pass those query params
 * across all of the auth pagse as the user navigates between them.
 */
const AuthProviderLink: React.FC<AuthProviderLinkProps> = ({
  children,
  className,
  href,
  onPress,
}) => (
  <Link
    className={className}
    onClick={onPress}
    to={href}
    forwardSearch={!isResetPasswordActive()}
  >
    {children}
  </Link>
);

export default AuthProviderLink;
