import { isNumber, mapObject } from 'underscore';
import { Size } from 'types';
import { Measurement } from 'utils/measurement';
import { Coords, DragAxis, Line } from './types';

function toPxValue(
  val: Measurement | number,
  containerSize: Size<number>,
): number {
  return isNumber(val) ? val : val?.toUnit('px', containerSize)?.value;
}

function toPxCoords(
  coords: Coords<Measurement | number>,
  containerSize: Size<number>,
): Coords<number> {
  return mapObject<Measurement | number>(coords, val =>
    toPxValue(val, containerSize),
  ) as any;
}

function slope(line: Line<number>) {
  const {
    from: { x: x1, y: y1 },
    to: { x: x2, y: y2 },
  } = line;

  return (y2 - y1) / (x2 - x1);
}

export function toPxLine(
  line: Line,
  containerSize: Size<number>,
): Line<number> {
  return {
    ...line,
    from: toPxCoords(line.from, containerSize),
    to: toPxCoords(line.to, containerSize),
  };
}

export function isVertical(line: Line<number>) {
  return Math.abs(slope(line)) === Infinity;
}

export function isHorizontal(line: Line<number>) {
  return slope(line) === 0;
}

export function getDragAxis(
  snappedX: boolean,
  snappedY: boolean,
  defaultAxis: DragAxis = 'both',
): DragAxis {
  if (snappedX && snappedY) {
    return 'none';
  }

  if (snappedX && (defaultAxis === 'y' || defaultAxis === 'both')) {
    return 'y';
  }

  if (snappedY && (defaultAxis === 'x' || defaultAxis === 'both')) {
    return 'x';
  }

  return defaultAxis;
}
