import classNames from 'classnames';
import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import bem from 'utils/bem';

interface IProps {
  className?: string;
  infoText?: string;
}

export const block = bem('info-tip');

const InfoTip: React.SFC<IProps> = ({ className, infoText }) => (
  <div className={classNames(block(), className)}>
    <FontAwesome icon="info-circle" className={block('icon')} />
    {infoText}
  </div>
);

export default InfoTip;
export { IProps as InfoTipProps };
