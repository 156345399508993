import _ from 'underscore';

import { divideEvenly, round } from 'utils/numbers';
import { DurationMask } from './types';

export function durationToValue(
  durationMillis: number,
  mask: DurationMask = '00:00.000',
) {
  if (_.isUndefined(durationMillis)) return undefined;

  const hasMillis = mask.split('.').length > 1;
  const hasHours = mask.split(':').length > 2;

  const { value: sec, remainder: millis } = divideEvenly(durationMillis, 1000);
  const { value: min, remainder: seconds } = divideEvenly(sec, 60);
  const { value: hours, remainder: minutes } = divideEvenly(min, 60);

  const parts = [];

  if (hasHours) {
    parts.push(hours, minutes, seconds);
  } else {
    parts.push(hours * 60 + minutes, seconds);
  }

  if (hasMillis) {
    parts.push(round(millis));
  }

  return applyMask(parts, mask);
}

export function valueToDuration(value: string) {
  if (!value) return undefined;

  const hasMillis = value.split('.').length > 1;
  const parts = value.split(/[:.]/);

  const initialValue = hasMillis ? parseInt(parts.pop(), 10) : 0;
  return parts.reverse().reduce((millis, part, index) => {
    const num = parseInt(part, 10);
    return millis + num * 60 ** index * 1000;
  }, initialValue);
}

const POSITION_MULTIPLIERS: Record<DurationMask, number[]> = {
  '000:00.000': [6000000, 600000, 60000, 0, 10000, 1000, 0, 100, 10, 1],
  '00:00.000': [600000, 60000, 0, 10000, 1000, 0, 100, 10, 1],
  '00:00': [600000, 60000, 0, 10000, 1000],
  '0:00:00': [3600000, 0, 600000, 60000, 0, 10000, 1000],
};

export function getPositionMillisMultiplier(mask: DurationMask, index: number) {
  const multipliers = POSITION_MULTIPLIERS[mask];
  return multipliers?.[index];
}

/**
 * masks an input.
 *
 * number should be an array of [min, sec, millis]
 */
function applyMask(parts: number[], mask: DurationMask = '00:00.000') {
  // TODO this regex exists in 3 places (see MaskedDurationInput.tsx)
  const hasMillis = mask.split('.').length > 1;
  const maskParts = mask.split(/[:.]/);
  const maskedParts = parts.reduce((acc, part, index) => {
    const strPart = part.toString();
    const maskPart = maskParts[index];
    const lenDiff = maskPart.length - strPart.toString().length;
    const maskedVal = maskPart
      .split('')
      .reduceRight((maskedValChars, maskChar, maskCharIndex) => {
        const charIndex = maskCharIndex - lenDiff;
        const char =
          charIndex >= 0 && charIndex < strPart.length
            ? strPart.charAt(charIndex)
            : maskChar;
        maskedValChars.unshift(char);
        return maskedValChars;
      }, [])
      .join('');
    acc.push(maskedVal);
    return acc;
  }, [] as string[]);

  if (hasMillis) {
    const maskedMillis = maskedParts.pop();
    return [maskedParts.join(':'), maskedMillis].join('.');
  }

  return maskedParts.join(':');
}
