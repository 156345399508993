import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import { block } from '../../utils';
import PhraseControlButton, {
  PhraseControlButtonProps,
} from './PhraseControlButton';

export type PlayPhraseButtonProps = Pick<
  PhraseControlButtonProps,
  'disabled' | 'onClick'
>;

const PlayPhraseButton: React.SFC<PlayPhraseButtonProps> = props => (
  <PhraseControlButton
    {...props}
    id="play-selection"
    large
    tooltip={
      <div>
        Play selection
        <FontAwesome className={block('tooltip-icon')} icon="keyboard" />
        <span className={block('tooltip-desc')}>[TAB]</span>
      </div>
    }
  >
    <FontAwesome icon="play" />
  </PhraseControlButton>
);

export default PlayPhraseButton;
