import CanvasEntry from 'wavesurfer.js/src/drawer.canvasentry';

export default class CustomCanvasEntry extends CanvasEntry {
  private halfPixel: number;

  /**
   * Render the actual waveform line on a `canvas` element
   * IMPORTANT: This is a reimplementation of CanvasEntry.drawLineToContext method.
   * It eliminates sync canvas ctx filling keeping only the method that draws the line
   *
   * @param {CanvasRenderingContext2D} ctx Rendering context of target canvas
   * @param {number[]} peaks Array with peaks data
   * @param {number} absmax Maximum peak value (absolute)
   * @param {number} halfH Half the height of the waveform
   * @param {number} offsetY Offset to the top
   * @param {number} start The x-offset of the beginning of the area that
   * should be rendered
   * @param {number} end The x-offset of the end of the area that
   * should be rendered
   */
  drawLineToContext(ctx, peaks, absmax, halfH, offsetY) {
    if (!ctx) {
      return;
    }

    const length = peaks.length / 2;
    const first = Math.round(length * this.start);

    // use one more peak value to make sure we join peaks at ends -- unless,
    // of course, this is the last canvas
    const last = Math.round(length * this.end) + 1;

    const canvasStart = first;
    const canvasEnd = last;
    const scale = this.wave.width / (canvasEnd - canvasStart - 1);

    // optimization
    const halfOffset = halfH + offsetY;
    const absmaxHalf = absmax / halfH;

    ctx.beginPath();
    ctx.moveTo((canvasStart - first) * scale, halfOffset);

    ctx.lineTo(
      (canvasStart - first) * scale,
      halfOffset - Math.round((peaks[2 * canvasStart] || 0) / absmaxHalf),
    );

    let i;
    let peak;
    let h;
    for (i = canvasStart; i < canvasEnd; i += 1) {
      peak = peaks[2 * i] || 0;
      h = Math.round(peak / absmaxHalf);
      ctx.lineTo((i - first) * scale + this.halfPixel, halfOffset - h);
    }

    // draw the bottom edge going backwards, to make a single
    // closed hull to fill
    let j = canvasEnd - 1;
    for (j; j >= canvasStart; j -= 1) {
      peak = peaks[2 * j + 1] || 0;
      h = Math.round(peak / absmaxHalf);
      ctx.lineTo((j - first) * scale + this.halfPixel, halfOffset - h);
    }

    ctx.lineTo(
      (canvasStart - first) * scale,
      halfOffset - Math.round((peaks[2 * canvasStart + 1] || 0) / absmaxHalf),
    );

    ctx.closePath();
  }
}
