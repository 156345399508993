import { AutoPostingPlatform } from 'blocks/AutomationWorkflowWizard/types';
import { ToggleCardProps } from 'components/ToggleCard';
import { AutogramClipSelectionMethod } from 'types';

export enum DestinationSteps {
  CHOOSE_DESTINATION = 'choose-destination',
  VIDEO_TYPES = 'video-types',
  CLIP_SELECTION = 'clip-selection',
  AUTO_POSTING = 'auto-posting',
}

export enum AutomationSubSteps {
  CLIP_SELECTION = 'clip-selection',
  AUTO_POSTING = 'auto-posting',
}

export enum AutoPostOptions {
  AUTO_POST = 'auto-posting',
  EMAIL = 'email',
}

export enum ClipSelectionOptions {
  HEADLINER = 'headliner',
  SOUNDBITE = 'soundbite',
}

export type DestinationStepView =
  | DestinationSteps.CHOOSE_DESTINATION
  | DestinationSteps.VIDEO_TYPES
  | DestinationSteps.CLIP_SELECTION
  | DestinationSteps.AUTO_POSTING;

export type AutomationStepOptions =
  | AutomationSubSteps.CLIP_SELECTION
  | AutomationSubSteps.AUTO_POSTING;

export type AutomationCardsOptions = AutoPostOptions | ClipSelectionOptions;

export type AutomationOptionCardValue = AutogramClipSelectionMethod | boolean;

export interface AutomationOptionsCardsOptions
  extends Pick<ToggleCardProps, 'isPro'> {
  id?: string;
  contents: JSX.Element;
  icon: JSX.Element;
  value: Record<string, AutomationCardsOptions | AutomationOptionCardValue>;
  onUpgrade?: () => void;
}

export type AutomationStepConfigOptions = AutomationOptionsCardsOptions[];

export type StepOption = AutoPostingPlatform | 'clipSelection';

export interface GetAutoPostOptionArgs {
  id: string;
  platform: AutoPostingPlatform;
  icon: JSX.Element;
}
