import React from 'react';

// types
import { IconProps } from './types';

const YoutubeOutput: React.FunctionComponent<IconProps> = ({ ...svgProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="71"
    viewBox="0 0 70 71"
    {...svgProps}
  >
    <defs>
      <filter
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        filterUnits="objectBoundingBox"
        id="urttgca3ga"
      >
        <feGaussianBlur in="SourceGraphic" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="red" d="M11 16h51v42H11z" />
      <path
        d="M10.5 0h49C65.299 0 70 4.701 70 10.5v49C70 65.299 65.299 70 59.5 70h-49C4.701 70 0 65.299 0 59.5v-49C0 4.701 4.701 0 10.5 0zm39.831 27.442a3.976 3.976 0 0 0-2.829-2.784C45.007 24 35 24 35 24s-10.007 0-12.502.658a3.977 3.977 0 0 0-2.83 2.784C19 29.898 19 35.022 19 35.022s0 5.124.669 7.58c.368 1.355 1.452 2.378 2.829 2.74C24.993 46 35 46 35 46s10.007 0 12.502-.658c1.377-.362 2.461-1.385 2.83-2.74.668-2.456.668-7.58.668-7.58s0-5.124-.669-7.58zM31.727 39.674V30.37l8.364 4.652-8.364 4.652z"
        fill="#FFF"
        filter="url(#urttgca3ga)"
        transform="translate(0 .708)"
      />
    </g>
  </svg>
);

export default YoutubeOutput;
