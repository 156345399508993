import { CustomizeStepBrandingOptions } from 'components/VideoTemplateEditor';

const INTEGRATORS_TEMPLATE_BRAND_MAP = {
  acast: 'acast',
};

export const getTemplateBranding = (
  integratorName?: string,
): 'acast' | undefined => {
  return INTEGRATORS_TEMPLATE_BRAND_MAP[integratorName];
};

export const getBrandingOptions = (opts: {
  integratorName?: string;
}): CustomizeStepBrandingOptions => {
  return {
    template: getTemplateBranding(opts.integratorName),
  };
};
