import * as React from 'react';

import SummaryPanelTextContainer from './SummaryPanelTextContainer';
import SummaryStepPanel from './SummaryStepPanel';
import { block, splitArticleParagraphs } from './utils';

interface IProps {
  text?: string;
}

const OriginalArticlePanel: React.SFC<IProps> = ({ text }) => (
  <SummaryStepPanel title="original">
    <SummaryPanelTextContainer>
      <div className={block('original-article')}>
        {splitArticleParagraphs(text).map((paragraph, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <p className={block('paragraph')} key={`p-${index}`}>
            {paragraph}
          </p>
        ))}
      </div>
    </SummaryPanelTextContainer>
  </SummaryStepPanel>
);

export default OriginalArticlePanel;
export { IProps as OriginalArticlePanelProps };
