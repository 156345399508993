import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { SubscriptionPeriod } from 'blocks/Pricing/types';
import Modal from 'components/Modal';
import useBillingCycleModal from 'hooks/useBillingCycleModal';
import { PurchaseableTier, Tier } from 'redux/middleware/api/plan-service';
import { replaceModal } from 'redux/modules/modal';
import { tierSelector } from 'redux/modules/pricing';
import PlanSelector, { InjectedWithPlanSelectorProps } from '../PlanSelector';
import TierSelectionAltCta from './TierSelectionAltCta';
import { block } from './utils';

export interface TierSelectionModalContentsProps {}

const TierSelectionModalContents: React.FC<TierSelectionModalContentsProps> = () => {
  const currentTier = useSelector(tierSelector);
  const { openBillingCycleModal } = useBillingCycleModal();

  const handleUpgradeClick = useCallback(
    (tier: PurchaseableTier, period: SubscriptionPeriod) => {
      openBillingCycleModal(
        {
          tier,
          // if the user is currently viewing the tier selection modal, allow them to
          // return to it if they change their mind at the checkout modal
          allowTierSelection: true,
          defaultSubscriptionPeriod: period,
          disableSuccessRedirect: true,
        },
        replaceModal,
      );
    },
    [openBillingCycleModal],
  );

  const planProps: InjectedWithPlanSelectorProps = {
    location: 'TierSelectModal',
    onUpgradeClick: handleUpgradeClick,
  };

  return (
    <>
      <Modal.Body className={block('contents')}>
        <PlanSelector
          className={block('plan-selector')}
          message="Select a plan to upgrade"
          subMessage="Seems like you’ve outgrown your current plan and you’re ready to level up!"
        >
          <PlanSelector.PlanList className={block('plan-list')}>
            <PlanSelector.BasicPlan
              className={block('plan')}
              cta={
                currentTier === Tier.BASIC || currentTier === Tier.PRO ? (
                  <TierSelectionAltCta theme="confirm-alt">
                    {currentTier === Tier.BASIC
                      ? 'your current plan'
                      : 'our basic plan'}
                  </TierSelectionAltCta>
                ) : (
                  undefined
                )
              }
              {...planProps}
            />
            <PlanSelector.ProPlan
              className={block('plan')}
              cta={
                currentTier === Tier.PRO ? (
                  <TierSelectionAltCta theme="submit-alt">
                    your current plan
                  </TierSelectionAltCta>
                ) : (
                  undefined
                )
              }
              {...planProps}
            />
            <PlanSelector.UnlimitedPlan
              className={block('plan')}
              {...planProps}
            />
          </PlanSelector.PlanList>
        </PlanSelector>
      </Modal.Body>
    </>
  );
};

export default TierSelectionModalContents;
