import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { WizardNavigationAction } from 'components/Wizard';
import {
  clearAudiogramWizard,
  clearEntireAudio,
  createAudiogram,
  entireAudioInstanceIdSelector,
  uploadProgressSelector,
} from 'redux/modules/audiogram-wizard';
import { defaultTranscriptionEnabledSelector } from 'redux/modules/display-pref';
import {
  clearMixpanel,
  onCancelWizard,
  onCompleteWizard,
  onFileUpload,
  onReturnToClipAudioStep,
  onWizardNext,
} from 'redux/modules/mixpanel';
import { getMixpanelBlurValue } from 'redux/modules/mixpanel/utils';
import { clearNotification, showError } from 'redux/modules/notification';
import { clearSearchResults } from 'redux/modules/podcast-search';
import { goToCreate, goToProject } from 'redux/modules/router';
import { clearSelectedAudio } from 'redux/modules/sample-audio';
import { projectIdSelector } from 'redux/modules/wizard-export/selectors';
import { Dispatch, State, ThunkAction } from 'redux/types';
import { ImageOriginId, SoundwavePositionValue } from 'types';
import { isApplicationError } from 'utils/ApplicationError';
import { createClippingOption } from 'utils/audio';
import { DropZoneType } from 'utils/constants';
import { getBlurRadius, hasSlideFromOrigin } from 'utils/embed/embed';
import {
  getPositionIdFromWave,
  mapSoundwaveGeneration,
} from 'utils/embed/soundwave';
import { FatalError } from 'utils/FatalError';
import AudiogramWizard from './AudiogramWizard';
import { MIXPANEL_WIZARD_STEP_MAP } from './constants';
import { AudiogramWizardProps as Props } from './types';

type StateProps = Pick<
  Props,
  'defaultTranscription' | 'entireAudioInstanceId' | 'uploadProgress'
>;

type DispatchProps = Pick<
  Props,
  | 'onBackgroundImageChange'
  | 'onCancel'
  | 'onError'
  | 'onMount'
  | 'onStepChange'
  | 'onSubmit'
  | 'onUnmount'
  | 'onComplete'
>;

const ERROR_ID = 'audiogram-wizard-error';

const defaultTranscriptionSelector = createSelector(
  defaultTranscriptionEnabledSelector,
  enabled => ({
    language: 'en-US',
    transcribe: !!enabled,
    transcriptUrl: undefined,
  }),
);

const onComplete = (): ThunkAction<void> => (dispatch, getState) => {
  const projectId = projectIdSelector(getState());
  dispatch(goToProject({ projectId, history: false, fromWizard: true }));
};

const mapStateToProps = (state: State): StateProps => ({
  defaultTranscription: defaultTranscriptionSelector(state),
  entireAudioInstanceId: entireAudioInstanceIdSelector(state),
  uploadProgress: uploadProgressSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onBackgroundImageChange: (file, error) => {
    dispatch(onFileUpload(DropZoneType.AUDIOGRAM_IMAGE, file, error));
  },
  onCancel: nav => {
    dispatch(onCancelWizard('audiogram', MIXPANEL_WIZARD_STEP_MAP[nav.stepId]));
    dispatch(goToCreate());
  },
  onError: (error, file) => {
    if (file) {
      dispatch(onFileUpload(DropZoneType.AUDIOGRAM_AUDIO, file, error.message));
    }

    if (error instanceof FatalError) {
      dispatch(
        showError({
          message: 'There was an error creating your project',
          code: 'ER007',
          dismissAfterSec: 5,
          id: ERROR_ID,
        }),
      );
      dispatch(goToCreate());
    } else {
      dispatch(
        showError({
          message: error.message,
          code: isApplicationError(error) ? error.code : undefined,
          dismissAfterSec: 5,
        }),
      );
    }
  },
  onMount: () => {
    dispatch(clearNotification(ERROR_ID));
    dispatch(clearMixpanel());
  },
  onStepChange: (
    to,
    from,
    action,
    {
      templateId,
      audioSource,
      clipSelectionMethod,
      destination,
      maxDuration,
    } = {},
  ) => {
    if (from && to.stepId !== 'export') {
      dispatch(
        onWizardNext({
          templateId,
          step: MIXPANEL_WIZARD_STEP_MAP[from.stepId],
          type: 'audiogram',
          audioSource,
          ...(from.stepId === 'template' && { name: 'Choose Template' }),
          ...(from.stepId === 'clip' && {
            clipSelectionMethod,
            destination,
            maxDuration,
          }),
        }),
      );
    }

    if (action === WizardNavigationAction.STEP_CLICK && to.stepId === 'clip') {
      dispatch(onReturnToClipAudioStep(from.stepId));
    }

    if (to.stepId === 'add-audio') {
      dispatch(clearEntireAudio());
      dispatch(clearSelectedAudio());
    }
  },
  onSubmit: async (type, config) => {
    const {
      audioClip,
      audioSourceType,
      backgroundColor,
      progress,
      slideshow,
      soundwave,
      templateId,
      textOverlays,
      transcription,
    } = config;

    try {
      dispatch(
        onCompleteWizard({
          backgroundColor,
          templateId,
          addedImage: slideshow?.length > 0,
          addedText: textOverlays?.length > 0,
          audioSource: audioSourceType,
          canvaImage: hasSlideFromOrigin(ImageOriginId.CANVA, config),
          enabledProgressBar: progress.enabled,
          originalAudioDuration: audioClip.originalDurationMillis,
          submissionType: type,
          transcription: !!transcription.transcribe,
          type: 'audiogram',
          waveColor: soundwave?.waveColor,
          waveGeneration: mapSoundwaveGeneration(soundwave?.waveGeneration),
          wavePosition: getPositionIdFromWave(
            soundwave,
          ) as SoundwavePositionValue,
          waveStyle: soundwave?.waveType,
          waveformPrefId: soundwave?.waveformPrefId,
          blurValue: getMixpanelBlurValue(getBlurRadius(config)),
          ...createClippingOption(audioClip),
        }),
      );
      await dispatch(createAudiogram(type, config));
    } catch (err) {
      if (isApplicationError(err)) {
        dispatch(
          showError({
            message: err.message,
            code: err.code,
            dismissAfterSec: 5,
            id: ERROR_ID,
          }),
        );
      } else {
        dispatch(
          showError({
            message: 'There was an error creating your project',
            code: 'ER007',
            dismissAfterSec: 5,
            id: ERROR_ID,
          }),
        );
      }
      dispatch(goToCreate());
    }
  },
  onUnmount: () => {
    dispatch(clearSelectedAudio());
    dispatch(clearSearchResults());
    dispatch(clearAudiogramWizard());
  },
  onComplete: () => {
    dispatch(onComplete());
  },
});

const component = connect(mapStateToProps, mapDispatchToProps)(AudiogramWizard);
export type AudiogramWizardProps = React.ComponentPropsWithoutRef<
  typeof component
>;
export default component;
