import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import UnpauseSubscriptionModalContent from './UnpauseSubscriptionModalContent';

function UnpauseSubscriptionModal() {
  return (
    <ConnectedModal name="UnpauseSubscription" title="Unpause my plan">
      <UnpauseSubscriptionModalContent />
    </ConnectedModal>
  );
}

export default UnpauseSubscriptionModal;
