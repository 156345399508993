import { useMemo } from 'react';
import { throttle } from 'underscore';

export function useWheel(
  handler: (e: React.WheelEvent<Element>) => void,
  delay: number = 100,
) {
  return useMemo(() => {
    const throttledHandler = throttle(handler, delay);
    return (e: React.WheelEvent<Element>) => {
      e.persist();
      throttledHandler(e);
    };
  }, [delay, handler]);
}
