import * as React from 'react';

import { WaveformProps } from '../../types';

const Blob = React.forwardRef<SVGSVGElement, WaveformProps>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M22.034 3.975c1.743 1.494 2.428 3.304 4.035 5.018s5.131 3.22 5.611 5.266c0.48 2.046-1.583 4.955-2.73 7.008s-2.577 3.531-4.155 5.307c-1.578 1.776-3.227 4.831-5.311 5.348s-4.759-1.65-7.193-2.249c-2.435-0.6-5.446-0.063-7.415-1.35s-3.68-4.051-4.401-6.374c-0.721-2.323-0.56-5.307 0.074-7.566s2.243-4.347 3.73-5.991c1.487-1.644 3.304-2.479 5.193-3.873s4.045-4.4 6.139-4.49c2.094-0.090 4.68 2.454 6.423 3.948zM8.95 11.613c-0.712 1.031-1.223 2.377-1.374 3.621s0.022 2.672 0.467 3.844c0.445 1.172 1.262 2.356 2.201 3.187s2.213 1.5 3.43 1.8c1.217 0.3 2.657 0.3 3.874 0s2.492-0.969 3.43-1.8c0.938-0.831 1.756-2.015 2.201-3.187s0.618-2.6 0.467-3.844c-0.151-1.244-0.662-2.589-1.374-3.621s-1.79-1.986-2.9-2.568c-1.11-0.582-2.508-0.927-3.761-0.927s-2.651 0.344-3.761 0.927c-1.11 0.582-2.187 1.537-2.9 2.568z"
    />
    <path d="M27.065 8.29c1.601 1.727 4.185 3.744 4.621 5.95s-0.791 5.306-2.004 7.284c-1.213 1.978-3.578 2.856-5.277 4.586s-2.937 5.367-4.916 5.795c-1.978 0.429-4.802-2.304-6.954-3.224s-3.945-1.17-5.955-2.296c-2.010-1.125-5.613-2.514-6.103-4.456s2.351-5.056 3.164-7.192c0.814-2.136 0.864-3.69 1.717-5.624s1.693-4.46 3.402-5.978c1.709-1.518 4.634-3.253 6.854-3.13s4.557 2.489 6.465 3.869c1.908 1.381 3.383 2.687 4.984 4.414zM7.58 15.215c-0.151 1.244 0.022 2.673 0.467 3.845s1.262 2.356 2.201 3.188c0.939 0.831 2.213 1.5 3.431 1.8s2.657 0.3 3.874 0c1.217-0.3 2.492-0.969 3.431-1.8s1.756-2.015 2.201-3.188c0.445-1.172 0.618-2.601 0.467-3.845s-0.662-2.59-1.374-3.622c-0.712-1.032-1.79-1.986-2.9-2.568s-2.508-0.927-3.762-0.927c-1.254 0-2.651 0.344-3.762 0.927s-2.188 1.537-2.9 2.568c-0.712 1.032-1.223 2.377-1.374 3.622z" />
  </svg>
));

export default Blob;
