import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ButtonTheme } from 'components/Button/Button';
import DialogContent from 'components/DialogContent';
import { Gift } from 'components/icons';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import {
  onFreeTrialExtendTrialCta,
  onFreeTrialLearnMoreCta,
  onShowFreeTrialModal,
} from 'redux/modules/mixpanel';
import { FreeTrialReminder } from 'redux/modules/pricing/types';
import { getMyPlanPath, getReferralsPath } from 'utils/routes';

interface Props {
  type?: FreeTrialReminder;
}

const { useEffect } = React;

const getTitle = (type: FreeTrialReminder) => {
  switch (type) {
    case FreeTrialReminder.OVER:
      return 'Welcome to our Forever Free tier!';
    case FreeTrialReminder.LAST_WEEK:
      return 'This is your last week of Pro!';
    default:
      throw new Error(`Invalid reminder type: ${type}`);
  }
};

const getDetails = (type: FreeTrialReminder) => {
  switch (type) {
    case FreeTrialReminder.OVER:
      return (
        <span>
          Your Pro trial is over but you can still enjoy{' '}
          <b>Headliner for free!</b>
          <br />
          You can also upgrade at any point to <b>regain your Pro features</b>.
        </span>
      );
    case FreeTrialReminder.LAST_WEEK:
      return (
        <span>
          After your trial you can use our <b>Forever Free</b> tier or
          <br />
          upgrade to keep your <b>Pro</b> features forever.
        </span>
      );
    default:
      throw new Error(`Invalid reminder type: ${type}`);
  }
};

const FreeTrialReminderModalContent: React.FC<Props> = props => {
  const { type } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onShowFreeTrialModal(type));
  }, [dispatch, type]);

  return (
    <DialogContent
      title={getTitle(type)}
      details={getDetails(type)}
      icon={
        <CircularGradient>
          <Gift height="36px" />
        </CircularGradient>
      }
      actions={[
        {
          theme: 'submit-alt' as ButtonTheme,
          children: 'Extend your trial',
          componentClass: Link,
          // @ts-ignore
          to: getReferralsPath(),
          onClick: () => {
            dispatch(
              onFreeTrialExtendTrialCta({
                source: 'Modal',
                modalName: type,
              }),
            );
          },
        },
        {
          theme: 'rainbow' as ButtonTheme,
          children: 'Learn more about pro',
          componentClass: Link,
          // @ts-ignore
          to: getMyPlanPath(),
          onClick: () => {
            dispatch(
              onFreeTrialLearnMoreCta({
                source: 'Modal',
                modalName: type,
              }),
            );
          },
        },
      ]}
    />
  );
};

export default FreeTrialReminderModalContent;
