import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';

import MediaAssetsForm from 'containers/MediaAssetsForm';
import AssetsPaneContent from '../../components/AssetsPaneContent';
import { block } from '../../utils';
import MediaAssets, { MediaAssetsProps } from './MediaAssets';

export interface MediaTabProps extends Pick<MediaAssetsProps, 'onAssetClick'> {
  onAddMedia?: () => void;
}

const MediaTab: React.SFC<MediaTabProps> = ({ onAddMedia, onAssetClick }) => (
  <AssetsPaneContent className={block('media-tab')}>
    <MediaAssetsForm onClickAddMedia={onAddMedia} />
    <Scrollbars hideTracksWhenNotNeeded>
      <MediaAssets onAssetClick={onAssetClick} />
    </Scrollbars>
  </AssetsPaneContent>
);

export default MediaTab;
