import classNames from 'classnames';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import _ from 'underscore';

import FormInput from 'components/FormInput';
import WrappedSelect from 'components/WrappedSelect';

interface IRenderSettingsProps {
  disabled: boolean;
  className: string;
  labelClassName: string;
}

type CreateControlClassName = (cname: string) => string;

export type RenderSettings = (
  props: IRenderSettingsProps,
  createControlClassName: CreateControlClassName,
) => JSX.Element;

interface IProps {
  className?: string;
  disabled?: boolean;
  animationEnabled?: boolean;
  title?: string;
  previewImageUrl?: string;
  /*
   * FIXME: this prop is optional.  a default prop is provided, yet the the render function checks
   * if the prop is defined.  it always will be.
   */
  renderSettings?: RenderSettings;
  onAnimationEnabledChange?: (enabled: boolean) => void;
}

export default class CaptionsAnimationsCard extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    animationEnabled: false,
    disabled: false,
    onAnimationEnabledChange: _.noop,
    renderSettings: _.constant(null),
  };

  private handleAnimationEnabledChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { onAnimationEnabledChange } = this.props;
    onAnimationEnabledChange(this.onOffToEnabled(e.target.value));
  };

  private onOffToEnabled = (val: string) => val === 'on';

  private enabledToOnOff = (enabled: boolean) => (enabled ? 'on' : 'off');

  public render() {
    const {
      className,
      disabled,
      animationEnabled,
      title,
      previewImageUrl,
      renderSettings,
    } = this.props;

    const containerClassName = classNames({
      'animations-card': true,
      'animations-card--std': true,
      [className]: !!className,
    });

    const commonProps = {
      disabled,
      className: 'animations-card__group',
      labelClassName: 'animations-card__label',
    };

    const controlClassName = (cname: string) =>
      classNames({
        'animations-card__control': true,
        [cname]: !!cname,
      });

    const FormInputAsAny = FormInput as any;

    return (
      <Form className={containerClassName} horizontal>
        <FormInputAsAny
          {...commonProps}
          label={title}
          labelSizes={{ xs: 8 }}
          caretClassName="animations-card__select-caret"
          controlSizes={{ xs: 4 }}
          componentClass={WrappedSelect}
          controlClassName={controlClassName('animations-card__enabled-select')}
          value={this.enabledToOnOff(animationEnabled)}
          onChange={this.handleAnimationEnabledChange}
        >
          <option key="on" value="on">
            On
          </option>
          <option key="off" value="off">
            Off
          </option>
        </FormInputAsAny>
        <img className="animations-card__img-preview" src={previewImageUrl} />
        {animationEnabled && renderSettings && (
          <div className="animations-card__settings">
            <div className="animations-card__settings-title">Settings</div>
            {renderSettings(commonProps, controlClassName)}
          </div>
        )}
      </Form>
    );
  }
}
