import { Button, Heading } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Image from 'components/Image';
import LinkButton from 'components/LinkButton';
import ModalContentWithMedia from 'components/Modal/ModalContentWithMedia';

import { onClickTryEddyModal } from 'redux/modules/mixpanel';
import { hideModal, pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { EDDY_URL } from 'utils/constants';
import EddyToolsList from './EddyToolsList';
import { block } from './utils';

export interface TryEddyAtCancellationModalContentsProps {}

const TryEddyAtCancellationModalContents: React.FC<TryEddyAtCancellationModalContentsProps> = () => {
  const dispatch = useDispatch<Dispatch>();

  const handleHide = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  const handleTryEddyClick = useCallback(() => {
    handleHide();

    dispatch(onClickTryEddyModal('Eddy'));
  }, [dispatch, handleHide]);

  const handleCancelClick = useCallback(() => {
    handleHide();

    dispatch(onClickTryEddyModal('Cancel'));
    dispatch(pushModal({ name: 'CancelReason' }));
  }, [dispatch, handleHide]);

  return (
    <ModalContentWithMedia onClose={handleHide} className={block('container')}>
      <ModalContentWithMedia.ModalContentWithMediaColumn
        className={block('left-side')}
      >
        <Image
          className={block('main-image')}
          src="https://static.sparemin.com/static/webapp-assets/backgrounds/eddy-marketing-202309261844.jpg"
        />
      </ModalContentWithMedia.ModalContentWithMediaColumn>

      <ModalContentWithMedia.ModalContentWithMediaColumn
        className={block('right-side')}
      >
        <div className={block('content')}>
          <Heading level={1}>
            Before you cancel, did you know you also have access to Eddy?
          </Heading>

          <Heading level={2}>
            Your Headliner plan extends to our new transcription app:
          </Heading>

          <EddyToolsList />

          <Button
            variant="special"
            fluid
            className={block('try-button')}
            as={LinkButton}
            rel="noopener noreferrer"
            target="_blank"
            href={`${EDDY_URL}/dashboard`}
            onPress={handleTryEddyClick}
          >
            try eddy at no extra cost
          </Button>

          <Button variant="ghost" fluid onPress={handleCancelClick}>
            I still want to cancel
          </Button>
        </div>
      </ModalContentWithMedia.ModalContentWithMediaColumn>
    </ModalContentWithMedia>
  );
};

export default TryEddyAtCancellationModalContents;
