import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import FakeFeatureModalContents from './FakeFeatureModalContents';

const FakeFeatureModal: React.FunctionComponent = () => {
  return (
    <ConnectedModal name="FakeFeatureModal">
      <FakeFeatureModalContents />
    </ConnectedModal>
  );
};

export default FakeFeatureModal;
