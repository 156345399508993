import * as React from 'react';
import { noop } from 'underscore';

import Button from 'components/Button';
import bem from 'utils/bem';
import ReferralsInvitationForm from '../ReferralsInvitationForm';

const { useCallback, useState } = React;

export interface EmailInvitationFormProps {
  disabled?: boolean;
  onSubmit?: (emails: string[]) => void;
}

const block = bem('email-invitation-form');

/**
 * A form a user can use to enter a comma-separated list of email addresses that
 * will be split and passed to the onSubmit callback as an array when the button
 * is clicked.
 */
const EmailInvitationForm = React.forwardRef<
  HTMLTextAreaElement,
  EmailInvitationFormProps
>(({ disabled, onSubmit }, ref) => {
  const [emailText, setEmailText] = useState('');

  const handleChange = useCallback(
    e => {
      const { value } = e.target;
      setEmailText(value);
    },
    [setEmailText],
  );

  const handleSubmit = useCallback(() => {
    const emails = emailText
      .replace(/\s/g, '')
      .split(',')
      .filter(Boolean);

    onSubmit(emails);
  }, [emailText, onSubmit]);

  return (
    <ReferralsInvitationForm
      className={block()}
      title="Invite friends by email"
    >
      <form className={block('form')}>
        <div className={block('email-input')}>
          <textarea
            className={block('email-textarea')}
            onChange={handleChange}
            placeholder="Enter your friends' email addresses separated by commas"
            ref={ref}
          />
        </div>
        <Button
          className={block('button')}
          disabled={disabled || !emailText}
          fluid
          onClick={handleSubmit}
          theme="submit"
        >
          send invites
        </Button>
      </form>
    </ReferralsInvitationForm>
  );
});

EmailInvitationForm.defaultProps = {
  onSubmit: noop,
};

export default EmailInvitationForm;
