import {
  ShadowBlur,
  ShadowHorizontalOffset,
  ShadowSolid,
  ShadowVerticalOffset,
  Spacer,
  TooltipContainerProps,
} from '@sparemin/blockhead';
import React from 'react';

import useStyleToggle from '../hooks/useStyleToggle';
import ToolbarPopoverButton from '../ToolbarPopoverButton';

import TextShadowColorPicker from './TextShadowColorPicker';
import TextShadowSlider from './TextShadowSlider';
import { TextShadow } from './types';
import { block } from './utils';

type PickedTooltipContainerProps = Pick<
  TooltipContainerProps,
  'offset' | 'placement' | 'strategy'
>;

interface TextShadowInputProps extends PickedTooltipContainerProps {
  defaultValue: TextShadow;
  onChange: (value: TextShadow) => void;
  value?: TextShadow;
}

const TextShadowInput: React.FunctionComponent<TextShadowInputProps> = props => {
  const {
    defaultValue,
    offset = 8,
    onChange,
    placement = 'top-end',
    strategy = 'fixed',
    value,
  } = props;

  const { onToggle, isEnabled } = useStyleToggle({
    defaultValue,
    onChange,
    value,
  });

  const handleChangeColorStyle = (color: string) => {
    onChange({
      ...value,
      color,
    });
  };

  const handleChangeNumericStyle = <T extends keyof Omit<TextShadow, 'color'>>(
    controlKey: T,
  ) => (nextValue: TextShadow[T]) => {
    onChange({
      ...value,
      [controlKey]: nextValue,
    });
  };

  const handleClickShadowButton = React.useCallback((): void => {
    onToggle();
  }, [onToggle]);

  const Icon = (
    <ShadowSolid
      className={block('icon', { 'style-off': isEnabled })}
      color={isEnabled ? value?.color : 'transparent'}
    />
  );

  return (
    <ToolbarPopoverButton
      isEnabled={isEnabled}
      offset={offset}
      onClickTrigger={handleClickShadowButton}
      placement={placement}
      popoverContent={
        <Spacer
          className={block('controls-popover-content')}
          orientation="vertical"
          space={1}
        >
          <TextShadowColorPicker
            onChange={handleChangeColorStyle}
            value={value?.color}
          />
          <TextShadowSlider
            icon={<ShadowHorizontalOffset />}
            label="Horizontal offset"
            maxValue={5}
            minValue={-5}
            onChange={handleChangeNumericStyle('x')}
            value={value?.x}
          />
          <TextShadowSlider
            icon={<ShadowVerticalOffset />}
            label="Vertical offset"
            maxValue={5}
            minValue={-5}
            onChange={handleChangeNumericStyle('y')}
            value={value?.y}
          />
          <TextShadowSlider
            icon={<ShadowBlur />}
            label="Blur"
            maxValue={10}
            minValue={0}
            onChange={handleChangeNumericStyle('blur')}
            value={value?.blur}
          />
        </Spacer>
      }
      strategy={strategy}
    >
      {Icon} Shadow
    </ToolbarPopoverButton>
  );
};

export default TextShadowInput;
