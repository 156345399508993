import * as React from 'react';
import { connect } from 'react-redux';

import { IErrorPageProps } from 'containers/ErrorPage';
import { logout } from 'redux/modules/auth';
import {
  onAppError,
  onClickErrorRefresh,
} from 'redux/modules/mixpanel/actions';
import { Dispatch } from 'redux/types';

export type DispatchProps = Pick<
  IErrorPageProps,
  'onClickErrorRefresh' | 'onLoad' | 'onLogoutClick'
>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onLogoutClick: () => dispatch(logout()),
  onClickErrorRefresh: () => dispatch(onClickErrorRefresh()),
  onLoad: () => dispatch(onAppError()),
});

export default function(component: React.ComponentType<IErrorPageProps>) {
  return connect(null, mapDispatchToProps)(component);
}
