import * as React from 'react';
import { FormGroup } from 'react-bootstrap';

import CopyToClipboard from 'components/CopyToClipboard';
import { block } from '../../utils';

export interface CopyVideoLinkFormProps {
  onCopyClick?: () => void;
  videoUrl: string;
}

const CopyVideoLinkForm: React.SFC<CopyVideoLinkFormProps> = ({
  onCopyClick,
  videoUrl,
}) => (
  <FormGroup className={block('copy-form')}>
    <p>Copy this URL to view or share:</p>
    <CopyToClipboard
      buttonLabel="copy"
      id="download-url"
      onCopyClick={onCopyClick}
      value={videoUrl}
    />
  </FormGroup>
);
export default CopyVideoLinkForm;
