import * as React from 'react';
import { isUndefined, noop } from 'underscore';

import LoadingOverlay from 'components/LoadingOverlay';
import Modal from 'components/Modal';
import SteppedModal, { SteppedModalProps } from 'components/SteppedModal';
import { UseHook } from 'components/UseHook';
import FacebookPostComposer, {
  FacebookPostComposerProps,
} from 'containers/FacebookPostModal/FacebookPostComposer';
import useFacebookAuthenticator from 'hooks/useFacebookAuthenticator';
import { PostStatus } from 'redux/modules/social/constants';
import { addSocialUTMSource } from 'utils/social/add-social-utm-source';
import SwitchFacebookAccountMessage from './SwitchFacebookAccountMessage';
import { block } from './utils';

type Page = FacebookPostComposerProps['selectedPage'];
type PickedModalProps = Pick<SteppedModalProps, 'onHide' | 'show'>;
type PickedComposerProps = Pick<FacebookPostComposerProps, 'pages'>;

export interface FacebookPostModalProps
  extends PickedModalProps,
    PickedComposerProps {
  defaultPage?: Page;
  description?: string;
  onDescriptionChange?: (newDescription: string) => void;
  resetDescription?: () => void;
  onAuthSuccess?: () => void;
  onSubmit?: (pageId: string, pageAccessToken: string, text: string) => void;
  onOpen?: () => void;
  isFetchingPages?: boolean;
  postStatus: PostStatus;
  episodeUrl: string;
  isFree?: boolean;
  isLoading?: boolean;
}

type Step = 'loading' | 'permission' | 'post' | 'switch';

interface State {
  page: Page;
  step: Step;
  episodeUrl: string;
}

interface PendingStepTransition {
  taskId?: number;
  step?: Step;
}

export default class FacebookPostModal extends React.Component<
  FacebookPostModalProps,
  State
> {
  public static defaultProps: Partial<FacebookPostModalProps> = {
    isFetchingPages: false,
    onOpen: noop,
  };

  private modal: SteppedModal;
  private pendingTransition: PendingStepTransition = {};

  public state: Readonly<State> = {
    page: this.props.defaultPage,
    step: 'loading',
    episodeUrl: this.props.episodeUrl,
  };

  public componentDidUpdate(prevProps: Readonly<FacebookPostModalProps>) {
    const {
      onHide,
      onOpen,
      pages,
      postStatus,
      show,
      isFetchingPages,
      resetDescription,
    } = this.props;

    const { show: prevShow, postStatus: prevPostStatus } = prevProps;
    const { step } = this.state;

    const modalOpened = !prevShow && show;

    if (modalOpened) {
      onOpen();
    }

    /*
     * NB: if pages is undefined, optimistically assume user has pages
     * when isFetchingPages === false and pages === undefined, we haven't yet
     * made a request for pages so we don't actually know if the user has any
     */
    const hadPages = this.hasPages(prevProps);
    const hasPages = this.hasPages();

    if (isFetchingPages) {
      return;
    }

    if (isUndefined(hasPages) && step !== 'loading') {
      this.goToStep('loading');
    }

    if (modalOpened && hasPages === true) {
      this.goToStep('post');
    }

    if (hasPages === false && step !== 'permission') {
      this.goToStep('permission');
    }

    if (hasPages === true && step === 'loading') {
      this.goToStep('post');
    }

    if (!hadPages && hasPages) {
      this.setState({
        page: pages[0],
      });
    }

    if (hadPages && !hasPages) {
      this.setState({ page: undefined });
    }

    if (
      prevPostStatus === PostStatus.IN_PROGRESS &&
      postStatus === PostStatus.SUCCESS
    ) {
      this.setState({
        step: 'post',
      });

      resetDescription();

      if (show) {
        onHide(this.modal.stepHistory.active);
      }
    }
  }

  public componentWillUnmount() {
    this.cancelTask();
  }

  private handlePageChange = (page: Page) => this.setState({ page });

  private handleSwitchAccountClick = () => this.goToStep('switch');

  private handleSwitchAccountSuccess = () => {
    const { resetDescription } = this.props;

    resetDescription();

    this.goToStep('post');
  };

  private handleSubmit = () => {
    const { onSubmit, description } = this.props;
    const { page, episodeUrl } = this.state;

    onSubmit(
      page.value,
      page.pageAccessToken,
      addSocialUTMSource('facebook', episodeUrl, description),
    );
  };

  private handleStepChange = (step: Step) => this.setState({ step });

  private setModal = (modal: SteppedModal) => {
    this.modal = modal;
  };

  /*
   * returns undefined if answer is unknown (e.g. request hasn't completed yet)
   * returns true/false if answer is known
   */
  private hasPages(props = this.props) {
    const { isFetchingPages, pages } = props;

    if (!isFetchingPages && !pages) {
      return undefined;
    }

    return !isFetchingPages && pages.length > 0;
  }

  private getTitle() {
    const { step } = this.state;

    switch (step) {
      case 'post':
      case 'loading':
        return 'post your video on facebook';

      case 'switch':
        return 'switch facebook accounts';

      case 'permission':
        return 'we need permission to post';

      default:
        return '';
    }
  }

  private goToStep(step: Step) {
    const { step: activeStep } = this.state;

    if (step === activeStep || this.pendingTransition.step === step) {
      return;
    }

    const replaceStep = () => this.modal.stepHistory.replace(step);

    // cancel any pending transition if we get a new one
    this.cancelTask();

    // loading step can be very quick depending on internet connection.  slow
    // down the transition to prevent ugly flashing
    this.pendingTransition.step = step;
    if (activeStep === 'loading') {
      this.pendingTransition.taskId = window.setTimeout(() => {
        replaceStep();
        this.pendingTransition = {};
      }, 1000);
    } else {
      replaceStep();
    }
  }

  private cancelTask() {
    if (this.pendingTransition.taskId) {
      window.clearTimeout(this.pendingTransition.taskId);
      this.pendingTransition = {};
    }
  }

  private fireHide = () => {
    const { step } = this.state;
    const { onHide } = this.props;
    onHide(step);
  };

  public render() {
    const {
      isFetchingPages,
      onAuthSuccess,
      onHide,
      pages,
      postStatus,
      show,
      isLoading,
      description,
      onDescriptionChange,
    } = this.props;
    const { page } = this.state;

    const isPosting = postStatus === PostStatus.IN_PROGRESS;

    return (
      <UseHook
        args={[{ force: true, onAuthSuccess }]}
        hook={useFacebookAuthenticator}
      >
        {({ authenticating, withAuthentication }) => (
          <SteppedModal
            baseClassName={block()}
            defaultStep="loading"
            onHide={onHide}
            onStepChange={this.handleStepChange}
            ref={this.setModal}
            show={show}
            steps={[
              {
                component: (
                  <LoadingOverlay
                    className={block('loading-pages-overlay')}
                    title="Loading Pages"
                  />
                ),
                id: 'loading',
                renderFooterButtons: ({ cancel }) => [
                  <Modal.FooterButton {...cancel} key="cancel">
                    cancel
                  </Modal.FooterButton>,
                ],
              },
              {
                component: (
                  <span className={block('permission')}>
                    We can't post your video unless you allow us to manage and
                    publish to your pages.
                  </span>
                ),
                id: 'permission',
                renderFooterButtons: ({ cancel, submit }) => [
                  <Modal.FooterButton
                    {...cancel}
                    key="cancel"
                    onClick={this.fireHide}
                  >
                    cancel
                  </Modal.FooterButton>,
                  <Modal.FooterButton
                    {...submit}
                    key="submit"
                    theme="submit"
                    disabled={authenticating}
                    onClick={withAuthentication(() => null)}
                  >
                    revise options
                  </Modal.FooterButton>,
                ],
              },
              {
                component: (
                  <FacebookPostComposer
                    isPosting={isPosting}
                    onPageChange={this.handlePageChange}
                    onTextChange={onDescriptionChange}
                    onSwitchAccountClick={this.handleSwitchAccountClick}
                    pages={pages}
                    isFetchingPages={isFetchingPages}
                    selectedPage={page}
                    text={description}
                  />
                ),
                id: 'post',
                onSubmit: this.handleSubmit,
                fluidFooterButtons: true,
                renderFooterButtons: ({ submit }) => [
                  <Modal.FooterButton
                    {...submit}
                    key="submit"
                    theme="submit"
                    disabled={isPosting || isLoading}
                  >
                    post to facebook
                  </Modal.FooterButton>,
                ],
              },
              {
                component: <SwitchFacebookAccountMessage />,
                id: 'switch',
                renderFooterButtons: ({ cancel, submit }) => [
                  <Modal.FooterButton {...cancel} key="cancel">
                    cancel
                  </Modal.FooterButton>,
                  <Modal.FooterButton
                    {...submit}
                    disabled={authenticating}
                    theme="submit"
                    key="submit"
                    onClick={withAuthentication(
                      this.handleSwitchAccountSuccess,
                    )}
                  >
                    switch accounts
                  </Modal.FooterButton>,
                ],
              },
            ]}
            title={this.getTitle()}
          />
        )}
      </UseHook>
    );
  }
}
