import React from 'react';

import { block } from '../../utils';

interface TextOverlayControlsGroupProps {
  children: React.ReactNode;
}

const TextOverlayControlsGroup: React.FunctionComponent<TextOverlayControlsGroupProps> = props => {
  const { children } = props;

  return <div className={block('controls-group')}>{children}</div>;
};

export default TextOverlayControlsGroup;
