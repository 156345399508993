import React, { useEffect } from 'react';
import FontAwesome from 'components/FontAwesome';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import { podcastAddedBlock as block } from '../utils';
import CardWizardStep from './CardWizard/CardWizardStep';

interface Props {
  onComplete: () => void;
}

function PodcastAddedStep(props: Props) {
  const { onComplete } = props;

  useEffect(() => {
    const handle = setTimeout(onComplete, 1000);
    return () => clearTimeout(handle);
  }, [onComplete]);

  return (
    <CardWizardStep className={block()}>
      <div className={block('icon')}>
        <CircularGradient>
          <FontAwesome icon="microphone" size="4x" />
        </CircularGradient>
      </div>
      <CardWizardStep.H1>Great choice!</CardWizardStep.H1>
    </CardWizardStep>
  );
}

export default PodcastAddedStep;
