import * as React from 'react';
import { noop } from 'underscore';

import { ImportType } from '../../types';
import { block } from './utils';

interface IProps {
  children?: React.ReactNode;
  id: ImportType;
  onSelectType?: (importType: ImportType) => void;
  selected?: boolean;
}

const ImportTypeSelectorHeaderItem: React.SFC<IProps> = ({
  children,
  id,
  onSelectType,
  selected,
}) => {
  const handleOnSelectType = () => onSelectType(id);

  return (
    <li
      className={block('header-item', { selected })}
      onClick={handleOnSelectType}
      onMouseEnter={handleOnSelectType}
    >
      {children}
    </li>
  );
};

ImportTypeSelectorHeaderItem.defaultProps = {
  onSelectType: noop,
  selected: false,
};

export default ImportTypeSelectorHeaderItem;
export { IProps as ImportTypeSelectorHeaderItemProps };
