import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import LottieProgressCheckmark from 'components/LottieProgressCheckmark';
import useCreateEpisodeTranscription from 'hooks/useCreateEpisodeTranscription';
import useEpisodeTranscriptionProIndicator from 'hooks/useEpisodeTranscriptionProIndicator';
import { onClickEpisodeTranscriptButton } from 'redux/modules/mixpanel';
import { pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { episodeListBlock as block } from '../../utils';
import EpisodeRowActionButton from './EpisodeRowActionButton';
import useEpisodeListEpisodeRowContext from './useEpisodeListEpisodeRowContext';
import useValidateEpisodeTranscription from './useValidateEpisodeTranscription';

export interface EpisodeTranscriptionButtonProps {}

const EpisodeTranscriptionButton: React.FC<EpisodeTranscriptionButtonProps> = () => {
  const { episode } = useEpisodeListEpisodeRowContext();
  const { isFree } = useEpisodeTranscriptionProIndicator();
  const dispatch = useDispatch<Dispatch>();

  const {
    isValidating,
    validateEpisodeTranscription,
  } = useValidateEpisodeTranscription({
    onFreeOrBasicUserLimitsExceeded: () => {
      dispatch(
        pushModal({
          name: 'EpisodeTranscriptionUpgrade',
        }),
      );
    },
    onProUserLimitsExceeded: (fileSize: number, episodeDuration: number) => {
      dispatch(
        pushModal({
          name: 'EpisodeExceedsLimits',
          params: {
            fileSize,
            episodeDuration,
          },
        }),
      );
    },
  });

  const episodeId = String(episode.id);
  const status = episode?.eddyProjectInfo?.status;
  const transcribable = status === null;
  const hasTranscript = status === 'completed';

  const inProgress = useMemo((): boolean => {
    return (
      ['processing', 'queued'].includes(status) ||
      episode.eddyProjectInfo.isResolved === false ||
      isValidating
    );
  }, [episode.eddyProjectInfo.isResolved, isValidating, status]);

  const { createEpisodeTranscription } = useCreateEpisodeTranscription({
    episodeId,
  });

  const handleOnTranscriptionClick = useCallback((): void => {
    if (isFree) {
      return dispatch(onClickEpisodeTranscriptButton('free'));
    }

    if (hasTranscript) {
      return dispatch(onClickEpisodeTranscriptButton('download', 'EpisodeRow'));
    }

    if (transcribable) {
      return dispatch(onClickEpisodeTranscriptButton('transcribable'));
    }

    return undefined;
  }, [dispatch, hasTranscript, isFree, transcribable]);

  const handleTranscription = useCallback(async (): Promise<void> => {
    if (status === 'completed') {
      window.open(
        `${episode.eddyProjectInfo.url}?source=MakeFullTranscriptButton`,
        '_blank',
      );

      return;
    }

    const isValid = await validateEpisodeTranscription(episode);

    if (!isValid) {
      return;
    }

    createEpisodeTranscription();
    handleOnTranscriptionClick();
  }, [
    createEpisodeTranscription,
    episode,
    handleOnTranscriptionClick,
    status,
    validateEpisodeTranscription,
  ]);

  const label = useMemo((): string => {
    if (inProgress) {
      return 'transcribing';
    }

    if (hasTranscript) {
      return 'transcript';
    }

    return 'transcribe';
  }, [hasTranscript, inProgress]);

  return (
    <EpisodeRowActionButton
      className={block('transcription-button')}
      disabled={inProgress}
      onClick={handleTranscription}
    >
      <EpisodeRowActionButton.Icon>
        {inProgress ? (
          <LottieProgressCheckmark loading size={14} />
        ) : (
          <FontAwesomeIcon
            className={block('transcription-button-icon')}
            icon="quote-left"
          />
        )}
      </EpisodeRowActionButton.Icon>

      <EpisodeRowActionButton.Label>{label}</EpisodeRowActionButton.Label>
    </EpisodeRowActionButton>
  );
};

export default EpisodeTranscriptionButton;
