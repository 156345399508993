import {
  FacebookSquare,
  InstagramSquare,
  LinkedInSquare,
  ThreadsSquare,
  TikTokSquare,
  XSquare,
} from '@sparemin/blockhead';

import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { YoutubeOutput } from 'components/icons';
import { AUTOMATION_BLACKLIST, useProIndicator } from 'containers/ProIndicator';
import { pushModal } from 'redux/modules/modal';
import {
  DEFAULT_CLIP_SELECTION_OPTIONS,
  DEFAULT_EMAIL_POSTING_OPTION,
} from './constants';
import { AutomationStepConfigOptions, StepOption } from './types';
import { getAutoPostOptions } from '.';

export default function useAutomationStepOptions() {
  const { isFree } = useProIndicator({ blacklist: AUTOMATION_BLACKLIST });
  const dispatch = useDispatch();

  const handleUpgradeTikTok = useCallback((): void => {
    dispatch(
      pushModal({
        name: 'TikTokAutoPostingUpsell',
      }),
    );
  }, [dispatch]);

  const autoPostingOptions = useMemo((): Record<
    StepOption,
    AutomationStepConfigOptions
  > => {
    return {
      clipSelection: DEFAULT_CLIP_SELECTION_OPTIONS,
      youtube: [
        getAutoPostOptions({
          id: 'youtube',
          platform: 'youtube',
          icon: <YoutubeOutput width="70" />,
        }),
        DEFAULT_EMAIL_POSTING_OPTION,
      ],
      tiktok: [
        {
          ...getAutoPostOptions({
            id: 'tiktok',
            platform: 'tiktok',
            icon: <TikTokSquare width="70" />,
          }),
          isPro: isFree,
          onUpgrade: handleUpgradeTikTok,
        },
        DEFAULT_EMAIL_POSTING_OPTION,
      ],
      instagram: [
        {
          ...getAutoPostOptions({
            id: 'instagram',
            platform: 'instagram',
            icon: <InstagramSquare width="70" />,
          }),
        },
        DEFAULT_EMAIL_POSTING_OPTION,
      ],
      linkedin: [
        {
          ...getAutoPostOptions({
            id: 'linkedin',
            platform: 'linkedin',
            icon: <LinkedInSquare width="70" />,
          }),
        },
        DEFAULT_EMAIL_POSTING_OPTION,
      ],
      facebook: [
        {
          ...getAutoPostOptions({
            id: 'facebook',
            platform: 'facebook',
            icon: <FacebookSquare width="70" />,
          }),
        },
        DEFAULT_EMAIL_POSTING_OPTION,
      ],
      twitter: [
        {
          ...getAutoPostOptions({
            id: 'twitter',
            platform: 'twitter',
            icon: <XSquare width="70" />,
          }),
        },
        DEFAULT_EMAIL_POSTING_OPTION,
      ],
      threads: [
        {
          ...getAutoPostOptions({
            id: 'threads',
            platform: 'threads',
            icon: <ThreadsSquare width="70" />,
          }),
        },
        DEFAULT_EMAIL_POSTING_OPTION,
      ],
    };
  }, [handleUpgradeTikTok, isFree]);

  return { autoPostingOptions };
}
