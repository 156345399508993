import cn from 'classnames';
import * as React from 'react';

import LockIcon from 'components/IconWithTooltip';
import UploadButton, { UploadButtonProps } from 'components/UploadButton';
import FormField, { FormFieldProps } from './FormField';
import { block } from './utils';

type InputProps = Pick<React.InputHTMLAttributes<HTMLInputElement>, 'accept'>;

interface IProps extends InputProps, UploadButtonProps {
  className?: string;
  locked?: boolean;
  pro?: boolean;
}

type Props = FormFieldProps<IProps>;

interface IState {
  showLockTooltip: boolean;
}

class UploadField extends React.Component<Props, IState> {
  public static defaultProps: Partial<Props> = {
    locked: false,
    pro: false,
  };

  public state: Readonly<IState> = {
    showLockTooltip: false,
  };

  private handleMouseEnter = () => this.setState({ showLockTooltip: true });

  private handleMouseLeave = () => this.setState({ showLockTooltip: false });

  private renderUploadButton = () => {
    const {
      children,
      className,
      onUpgradePlan,
      tooltipId,
      locked,
      pro,
      ...props
    } = this.props;

    return (
      <FormField<InputProps>
        {...props}
        className={cn(block('upload-field'), className)}
      >
        <UploadButton
          className={block('upload-input')}
          onUpgradePlan={onUpgradePlan}
          tooltipId={tooltipId}
          pro={pro}
        >
          {children}
        </UploadButton>
      </FormField>
    );
  };

  private renderLockedUploadButton = () => {
    const {
      children,
      className,
      tooltipId,
      locked,
      value,
      accept,
      maxSizeMb,
      onClear,
      onFileAccepted,
      onFileRejected,
      pro,
      ...props
    } = this.props;
    const { showLockTooltip } = this.state;

    return (
      <div
        className={block('locked-wrapper')}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <FormField<InputProps>
          {...props}
          className={cn(block('upload-field'), className)}
          disabled
        >
          <div className={block('locked-container')}>
            <div className={block('locked-input')}>{value}</div>
          </div>
        </FormField>

        <LockIcon
          tooltipId="input-lock"
          className={block('input-lock', {
            vertical: !!(!props.horizontal && props.label),
          })}
          showTooltip={showLockTooltip}
        />
      </div>
    );
  };

  public render() {
    const { locked } = this.props;

    return locked ? this.renderLockedUploadButton() : this.renderUploadButton();
  }
}

export default UploadField;
export { Props as UploadFieldProps };
