import { useCallback } from 'react';
import undoable, { excludeAction } from 'redux-undo';
import { noop, pick } from 'underscore';

import usePreviousRef from 'hooks/usePreviousRef';
import { Dimensions, FitType, ProgressType } from 'types';
import {
  fitElement,
  isFill,
  measurementToPx,
  measurementToViewport,
  numberOrPxToPx,
} from 'utils/placement';
import { Pixels } from '../../../utils/measurement';
import {
  Action,
  Dispatch,
  OnStateChange,
  TemplateAction,
  VideoTemplateStateContent,
} from '../types';
import { getCanvasDimensions } from '../utils';
import {
  addImage,
  addIntroOutro,
  addTextOverlay,
  addVideo,
  addWatermark,
  deleteImage,
  deleteIntroOutro,
  deleteTextOverlay,
  deleteVideo,
  loadIntroOutroData,
  recropImage,
  recropWatermark,
  replaceImage,
  replaceIntroOutro,
  replaceVideo,
  resetSoundwave,
  scaleImage,
  scaleVideoClip,
  scaleWatermark,
  setBackgroundColor,
  setCaptionsDimensions,
  setInitialSlidePlacement,
  setInitialVideoClipPlacement,
  setInitialWatermarkPlacement,
  setNewLayerPosition,
  setProgress,
  setProgressAlignment,
  setProgressSize,
  setProgressType,
  setSoundwave,
  setSoundwaveColor,
  setSoundwaveDimensions,
  setSoundwavePlacement,
  setSoundwaveType,
  setTemplateUCSCompatibility,
  setTemplateUCSCompatibilityLoading,
  setTextDimensions,
  setTimer,
  setTranscription,
  setWaveformFidelity,
  updateCaptionsConfig,
  updateImmutableTextOverlay,
  updateSlideBlurRadius,
  updateSlideEffectText,
  updateSlidePlacement,
  updateVideoClipPlacement,
  updateWatermark,
} from './state-utils';

interface UseEditorStateOptions {
  onChange?: OnStateChange;
}

function reducer(
  state: VideoTemplateStateContent,
  action: Action,
): VideoTemplateStateContent {
  switch (action.type) {
    case 'BACKGROUND_COLOR_CHANGE':
      return setBackgroundColor(state, action.payload);

    case 'INTRO_OUTRO_ADD':
      return addIntroOutro(
        state,
        action.payload.type,
        action.payload.src,
        action.payload.fileName,
      );

    case 'INTRO_OUTRO_DELETE':
      return deleteIntroOutro(state, action.payload.type);

    case 'INTRO_OUTRO_LOAD':
      return loadIntroOutroData(
        state,
        action.payload.type,
        action.payload.src,
        action.payload.fileName,
      );

    case 'INTRO_OUTRO_REPLACE':
      return replaceIntroOutro(
        state,
        action.payload.type,
        action.payload.src,
        action.payload.fileName,
      );

    case 'WATERMARK_LAYER_DOUBLE_CLICK': {
      const { canvas, watermark } = state;
      const canvasPx = {
        height: new Pixels(canvas.height),
        width: new Pixels(canvas.width),
      };
      const placement: Dimensions<Pixels> = measurementToPx(
        {
          ...watermark.position,
          ...watermark.size,
        },
        canvas,
      );

      const newPlacement = fitElement(
        placement,
        canvasPx,
        isFill(placement, canvasPx) ? 'fit' : 'fill',
      );

      const newPlacementVp = measurementToViewport(newPlacement, canvasPx);

      return updateWatermark(state, {
        position: pick(newPlacementVp, 'left', 'top'),
        size: pick(newPlacementVp, 'height', 'width'),
      });
    }

    case 'WATERMARK_ADD':
      return addWatermark(state, action.payload);

    case 'WATERMARK_CROP': {
      const { src, metadata } = action.payload;
      return recropWatermark(state, src, metadata);
    }

    case 'WATERMARK_DELETE': {
      return {
        ...state,
        watermark: undefined,
      };
    }

    case 'WATERMARK_LOAD': {
      const { naturalSize } = action.payload;
      return setInitialWatermarkPlacement(state, naturalSize);
    }

    case 'WATERMARK_PLACEMENT_CHANGE': {
      const { height, left, top, width } = action.payload;
      const { canvas } = state;

      return updateWatermark(state, {
        position: measurementToViewport({ left, top }, canvas),
        size: measurementToViewport({ height, width }, canvas),
      });
    }

    case 'WATERMARK_UPDATE': {
      return updateWatermark(state, action.payload);
    }

    case 'SOUNDWAVE_COLOR_CHANGE':
      return setSoundwaveColor(state, action.payload);

    case 'SOUNDWAVE_TYPE_CHANGE':
      return setSoundwaveType(state, action.payload);

    case 'SOUNDWAVE_DIMENSIONS_CHANGE': {
      const {
        payload: { height, width, left, top },
      } = action;
      const { canvas } = state;
      return setSoundwaveDimensions(
        state,
        measurementToViewport(
          {
            height,
            left,
            top,
            width,
          },
          canvas,
        ),
      );
    }

    case 'SOUNDWAVE_FIDELITY_CHANGE': {
      return setWaveformFidelity(state, action.payload);
    }

    case 'SOUNDWAVE_PLACEMENT_SELECT': {
      return setSoundwavePlacement(state, action.payload);
    }

    case 'SOUNDWAVE_RESET': {
      return resetSoundwave(state);
    }

    case 'SOUNDWAVE_PRESET_SELECT': {
      return setSoundwave(state, action.payload);
    }

    case 'CONTAINER_RESIZE': {
      const container = action.payload;
      const [width, height] = getCanvasDimensions(container, state.aspectRatio);
      return {
        ...state,
        canvas: { height, width },
        container,
      };
    }

    case 'TEMPLATE_UCS_COMPATIBILITY': {
      return setTemplateUCSCompatibility(state, action.payload);
    }

    case 'TEMPLATE_UCS_COMPATIBILITY_LOADING': {
      return setTemplateUCSCompatibilityLoading(state, action.payload);
    }

    case 'VIDEOCLIP_ADD': {
      const { src, original, fileName, integrationData, id } = action.payload;
      return addVideo(state, src, { original, fileName, integrationData, id });
    }

    case 'VIDEOCLIP_DELETE': {
      const { id } = action.payload;
      return deleteVideo(state, id);
    }

    case 'VIDEOCLIP_LAYER_DOUBLE_CLICK': {
      const { id } = action.payload;
      const { canvas, videoClips } = state;
      const videoClip = videoClips.data[id];

      const canvasPx = {
        height: new Pixels(canvas.height),
        width: new Pixels(canvas.width),
      };

      const placement: Dimensions<Pixels> = measurementToPx(
        videoClip.placement,
        canvasPx,
      );

      const newPlacement = fitElement(
        placement,
        canvasPx,
        isFill(placement, canvasPx) ? 'fit' : 'fill',
      );

      const newPlacementVp = measurementToViewport(newPlacement, canvasPx);

      return updateVideoClipPlacement(state, id, newPlacementVp as any);
    }

    case 'IMAGE_ADD': {
      const { src, original, fileName, integrationData, id } = action.payload;
      return addImage(state, src, { original, fileName, integrationData, id });
    }

    case 'IMAGE_DELETE': {
      const { id } = action.payload;
      return deleteImage(state, id);
    }

    case 'IMAGE_EFFECT_TEXT_UPDATE': {
      const { id, text } = action.payload;
      return updateSlideEffectText(state, id, text);
    }

    case 'IMAGE_LAYER_DOUBLE_CLICK': {
      const { id } = action.payload;
      const { canvas, slideshow } = state;
      const slide = slideshow.data[id];

      const canvasPx = {
        height: new Pixels(canvas.height),
        width: new Pixels(canvas.width),
      };

      const placement: Dimensions<Pixels> = measurementToPx(
        slide.placement,
        canvasPx,
      );

      const newPlacement = fitElement(
        placement,
        canvasPx,
        isFill(placement, canvasPx) ? 'fit' : 'fill',
      );

      const newPlacementVp = measurementToViewport(newPlacement, canvasPx);

      return updateSlidePlacement(state, id, newPlacementVp as any);
    }

    case 'IMAGE_PLACEMENT_CHANGE': {
      const { id, placement } = action.payload;
      const { canvas, slideshow } = state;
      const slide = slideshow?.data[id];

      const canvasPx = numberOrPxToPx({
        height: canvas.height,
        width: canvas.width,
      });

      if (typeof placement === 'string') {
        return updateSlidePlacement(
          state,
          id,
          fitElement(
            measurementToPx(slide.placement, canvasPx),
            canvasPx,
            placement as FitType,
          ),
        );
      }

      return updateSlidePlacement(state, id, placement as Dimensions<Pixels>);
    }

    case 'VIDEOCLIP_PLACEMENT_CHANGE': {
      const { id, placement } = action.payload;
      const { canvas, videoClips } = state;
      const slide = videoClips?.data[id];

      const canvasPx = numberOrPxToPx({
        height: canvas.height,
        width: canvas.width,
      });

      if (typeof placement === 'string') {
        return updateVideoClipPlacement(
          state,
          id,
          fitElement(
            measurementToPx(slide.placement, canvasPx),
            canvasPx,
            placement as FitType,
          ),
        );
      }

      return updateVideoClipPlacement(
        state,
        id,
        placement as Dimensions<Pixels>,
      );
    }

    case 'IMAGE_BLUR_RADIUS_CHANGE': {
      const { id, blurRadius } = action.payload;
      return updateSlideBlurRadius(state, id, blurRadius as number);
    }

    case 'IMAGE_REPLACE': {
      const {
        src,
        original,
        fileName,
        integrationData,
        id,
        prevType,
      } = action.payload;
      return replaceImage(state, id, src, prevType, {
        original,
        fileName,
        integrationData,
      });
    }

    case 'VIDEOCLIP_REPLACE': {
      const {
        src,
        original,
        fileName,
        integrationData,
        id,
        prevType,
      } = action.payload;
      return replaceVideo(state, id, src, prevType, {
        original,
        fileName,
        integrationData,
      });
    }

    case 'IMAGE_CROP': {
      const { src, id, metadata } = action.payload;
      return recropImage(state, id, src, metadata);
    }

    case 'IMAGE_LOAD': {
      const { id, naturalSize } = action.payload;
      return setInitialSlidePlacement(state, id, naturalSize);
    }

    case 'VIDEOCLIP_LOAD': {
      const { id, position, size } = action.payload;
      return setInitialVideoClipPlacement(state, id, position, size);
    }

    case 'IMAGE_LAYER_WHEEL': {
      const { event, id } = action.payload;
      return scaleImage(state, id, event.deltaY * 0.001);
    }

    case 'VIDEOCLIP_LAYER_WHEEL': {
      const { event, id } = action.payload;
      return scaleVideoClip(state, id, event.deltaY * 0.001);
    }

    case 'PROGRESS_CHANGE':
      return setProgress(state, action.payload);

    case 'PROGRESS_TYPE_CHANGE':
      return setProgressType(state, action.payload);

    case 'PROGRESS_ALIGNMENT_CHANGE':
      return setProgressAlignment(state, action.payload);

    case 'PROGRESS_SIZE_CHANGE':
      return setProgressSize(state, action.payload);

    case 'TEXT_ADD': {
      const { textOverlay, integrationData } = action.payload;
      return addTextOverlay(state, textOverlay, { integrationData });
    }

    case 'TEXT_DIMENSIONS_CHANGE': {
      const { dimensions, id } = action.payload;
      return setTextDimensions(state, id, dimensions);
    }

    case 'CAPTIONS_DIMENSIONS_CHANGE': {
      const { dimensions } = action.payload;
      return setCaptionsDimensions(state, dimensions);
    }

    case 'TEXT_DELETE': {
      const { payload: id } = action;
      return deleteTextOverlay(state, id);
    }

    case 'TEXT_UPDATE': {
      const {
        payload: { id, textOverlay },
      } = action;

      return updateImmutableTextOverlay(state, id, textOverlay);
    }

    case 'TIMER_CHANGE': {
      return setTimer(state, action.payload);
    }

    case 'WATERMARK_LAYER_WHEEL': {
      const event = action.payload;
      return scaleWatermark(state, event.deltaY * 0.001);
    }

    case 'CHILD_VIEW_OPEN': {
      if (action.payload === 'progress' && !state.progress.enabled) {
        return setProgressType(state, ProgressType.BAR);
      }
      return state;
    }

    case 'LAYER_POSITION_CHANGE': {
      const { layerId, moveTo } = action.payload;

      return setNewLayerPosition(state, layerId, moveTo);
    }

    case 'CAPTIONS_CONFIG_UPDATE': {
      const { captions } = action.payload;

      return updateCaptionsConfig(state, captions);
    }

    case 'TRANSCRIPTION_CHANGE': {
      const { transcription } = action.payload;

      return setTranscription(state, transcription);
    }

    default:
      return state;
  }
}

const undoableReducer = undoable(reducer, {
  filter: excludeAction([
    'xstate.init',
    'CONTAINER_RESIZE',
    'IMAGE_LOAD',
    'CHILD_VIEW_CLOSE',
    'CHILD_VIEW_OPEN',
  ]),
  limit: 50,
  syncFilter: true,
});

/*
 * returns a dispatch function that sends actions to modify the editor's state.
 * the dispatch function reference will change when state changes - it's not
 * static.
 */
export default function useTemplateDispatch({
  onChange = noop,
}: UseEditorStateOptions): Dispatch {
  const onChangeRef = usePreviousRef(onChange);

  const dispatch = useCallback(
    (action: TemplateAction) => {
      onChangeRef.current(
        prevState => undoableReducer(prevState, action),
        action,
      );
    },
    [onChangeRef],
  );

  return dispatch;
}
