import { Heading, Quote } from '@sparemin/blockhead';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from 'components/ConfirmationModal';
import Circular from 'components/icons/circular/Circular';
import Modal from 'components/Modal';
import { onDisplayTranscribeUnsupportedLanguageModal } from 'redux/modules/mixpanel';
import { hideModal, modalParamsSelector } from 'redux/modules/modal';
import { episodeTranscriptionModalBlock as block } from '../utils';

function EpisodeUnsupportedLanguageModalContents() {
  const { language } = useSelector(modalParamsSelector) || {};

  const dispatch = useDispatch();

  const handleHideModal = useCallback((): void => {
    dispatch(hideModal());
  }, [dispatch]);

  useEffect(() => {
    dispatch(onDisplayTranscribeUnsupportedLanguageModal(language));
  }, [dispatch, language]);

  return (
    <>
      <ConfirmationModal.Body className={block('body')}>
        <ConfirmationModal.Icon>
          <Circular>
            <Quote className={block('icon')} />
          </Circular>
        </ConfirmationModal.Icon>

        <ConfirmationModal.Title>
          This language isn’t supported yet
        </ConfirmationModal.Title>

        <ConfirmationModal.Content>
          <Heading level={2}>
            Sorry for the inconvenience, but the language you selected is not
            supported for transcripts yet. Your request is noted and we’ll do
            our best to add support this language soon.
          </Heading>
        </ConfirmationModal.Content>
      </ConfirmationModal.Body>

      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton theme="submit" onClick={handleHideModal}>
            Close
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
}

export default EpisodeUnsupportedLanguageModalContents;
