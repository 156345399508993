import { fromJS } from 'immutable';
import { MediaImportedArgs } from 'containers/MediaUploadModal/types';
import {
  actions,
  Source,
  UploadVideoOptions,
  UploadVideoToStorageOptions,
} from 'redux/middleware/api/media-upload-service';
import { uploadVideo as uploadVideoFile } from 'redux/middleware/api/media-upload-service/actions';
import { fetchTranscriptByVideoIdRequest } from 'redux/middleware/api/transcript-service/actions';
import { transcriptsSelector } from 'redux/modules/entities';
import { ThunkAction } from 'redux/types';
import { round } from 'utils/numbers';
import reduxPoll from 'utils/redux-poll';
import { waitForVideoUpload } from '../common/actions/video';
import { Type } from './action-types';
import { handleUploadVideo, searchForVideo } from './utils';

const TRANSCRIBE_POLL_ID = 'app/video-upload/transcribe-poll';

export const uploadVideo = (
  video: File | string,
  durationMillis: number,
  opts: UploadVideoOptions = {},
): ThunkAction<Promise<any>> => dispatch => {
  dispatch({ type: Type.VIDEO_UPLOAD_REQUEST });

  const { language, transcribe, trimStart, trimEnd } = opts;
  const isFullClip =
    round(trimEnd) - round(trimStart) === round(durationMillis);

  return dispatch(
    actions.uploadVideo(video, {
      language,
      transcribe,
      trimEnd: isFullClip ? undefined : trimEnd,
      trimStart: isFullClip ? undefined : trimStart,
    }),
  )
    .then(res => {
      const uploadId = res.response.result;
      return dispatch(waitForVideoUpload(uploadId));
    })
    .then(videoResult => {
      return dispatch({
        payload: { video: videoResult },
        type: Type.VIDEO_UPLOAD_SUCCESS,
      });
    })
    .catch(e => {
      dispatch({ type: Type.VIDEO_UPLOAD_FAILURE });
      throw e;
    });
};

const handleUploadVideoFile = (
  src: Source,
  args: UploadVideoOptions,
): ThunkAction<void> => async dispatch => {
  const uploadResult = await dispatch(uploadVideoFile(src, args))
    .then(res => dispatch(waitForVideoUpload(res.response.result)))
    .then(videoResult =>
      dispatch({
        payload: { video: videoResult },
        type: Type.VIDEO_UPLOAD_SUCCESS,
      }),
    );

  return uploadResult;
};

const uploadVideoWithImportedFile = (
  opts: UploadVideoToStorageOptions,
  mediaImportedArgs?: MediaImportedArgs,
): ThunkAction<void> => async dispatch => {
  const { trimStartMillis, trimEndMillis, language } = opts;
  const { provider } = mediaImportedArgs;

  const uploadResult = await dispatch(
    handleUploadVideoFile(undefined, {
      trimStart: trimStartMillis,
      trimEnd: trimEndMillis,
      transcribe: true,
      language,
      initiateProcessing: true,
      ...(provider === 'googleDrive'
        ? {
            providerUserId: mediaImportedArgs.providerUserId,
            googleDriveFileId: mediaImportedArgs.googleDriveFileId,
          }
        : {}),
      ...(provider === 'zoom'
        ? {
            providerUserId: mediaImportedArgs.providerUserId,
            zoomMeetingId: mediaImportedArgs.zoomMeetingId,
            zoomRecordingFileId: mediaImportedArgs.zoomRecordingFileId,
          }
        : {}),
      ...(provider === 'youtube'
        ? {
            youtubeUrl: mediaImportedArgs.youtubeUrl,
          }
        : {}),
    }),
  );

  return uploadResult;
};

export const uploadVideoToStorage = (
  opts: UploadVideoToStorageOptions,
  mediaImportedArgs?: MediaImportedArgs,
): ThunkAction<Promise<any>> => async dispatch => {
  const {
    src,
    trimStartMillis,
    trimEndMillis,
    language,
    initiateProcessing = true,
  } = opts;

  dispatch({ type: Type.VIDEO_UPLOAD_REQUEST });

  if (mediaImportedArgs) {
    const uploadResult = await dispatch(
      uploadVideoWithImportedFile(opts, mediaImportedArgs),
    );

    return uploadResult;
  }

  const existingVideo = await dispatch(
    searchForVideo({
      src,
      trimStartMillis,
      trimEndMillis,
      language,
    }),
  );

  if (existingVideo) {
    return dispatch({
      payload: { video: fromJS(existingVideo) },
      type: Type.VIDEO_UPLOAD_SUCCESS,
    });
  }

  if (typeof src === 'string') {
    const uploadResult = await dispatch(
      handleUploadVideoFile(src, {
        trimStart: trimStartMillis,
        trimEnd: trimEndMillis,
        transcribe: true,
        language,
        initiateProcessing: true,
      }),
    );

    return uploadResult;
  }

  const uploadedVideo = await dispatch(
    handleUploadVideo(src, {
      trimStartMillis,
      trimEndMillis,
      language,
      initiateProcessing,
    }),
  )
    .then(({ result }) => dispatch(waitForVideoUpload(result)))
    .then(videoResult =>
      dispatch({
        payload: { video: videoResult },
        type: Type.VIDEO_UPLOAD_SUCCESS,
      }),
    );

  return uploadedVideo;
};

export const awaitVideoTranscription = (
  id: number,
): ThunkAction<Promise<any>> => (dispatch, getState) =>
  reduxPoll(dispatch, () => dispatch(actions.getVideo(id)), {
    id: TRANSCRIBE_POLL_ID,
    intervalMillis: spareminConfig.videoTranscriptionPollIntervalMillis,
    maxAttempts: spareminConfig.videoTranscriptionPollMaxAttempts,
    shouldContinue: err => {
      if (err) return false;
      const isResolved = transcriptsSelector(getState()).getIn([
        String(id),
        'isResolved',
      ]);
      return !isResolved;
    },
  }).then(() => dispatch(fetchTranscriptByVideoIdRequest(id)));
