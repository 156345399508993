import * as React from 'react';
import { IconProps } from './types';

function InfinitySolid({ fill = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={25} height={12} viewBox="0 0 25 12" {...props}>
      <path
        fill={fill}
        stroke="#323746"
        strokeWidth={0.5}
        d="M24.75 6c0 1.58-.678 3.018-1.772 4.06a6.195 6.195 0 01-4.28 1.69c-2.066 0-3.926-1.13-5.546-2.965l-.652-.739-.652.739c-1.62 1.835-3.48 2.965-5.545 2.965a6.19 6.19 0 01-4.28-1.69C.927 9.018.25 7.58.25 6c0-1.58.678-3.018 1.772-4.06A6.195 6.195 0 016.302.25c2.066 0 3.926 1.13 5.546 2.965l.652.739.652-.739C14.772 1.38 16.632.25 18.697.25a6.19 6.19 0 014.28 1.69C24.073 2.982 24.75 4.42 24.75 6zm-10.611.529c1.324 1.813 2.906 3.014 4.558 3.014 1.06 0 2.027-.393 2.728-1.032.702-.64 1.137-1.525 1.137-2.511 0-.986-.435-1.871-1.137-2.511a4.048 4.048 0 00-2.728-1.032c-.858 0-1.7.322-2.496.993-.741.626-1.445 1.56-2.062 3.079zM6.303 9.543c.858 0 1.7-.322 2.496-.993.741-.626 1.445-1.56 2.062-3.079-1.324-1.813-2.906-3.014-4.558-3.014-1.06 0-2.027.393-2.728 1.032-.702.64-1.138 1.525-1.138 2.511 0 .986.436 1.871 1.138 2.511a4.048 4.048 0 002.728 1.032z"
      />
    </svg>
  );
}

export default InfinitySolid;
