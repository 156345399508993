import { List, Record, RecordOf } from 'immutable';

import { reviveRecord } from 'utils/immutable';

import { State } from './types';

export const stateFactory = Record<State>({
  presets: List([]),
  status: undefined,
});

export const presetsFromJs = (presetIds: string[]): List<string> => {
  return List(presetIds);
};

export const stateFromJs = (obj: Object): RecordOf<State> => {
  return reviveRecord(stateFactory, obj);
};
