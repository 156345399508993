import * as React from 'react';

import LoadingOverlay from 'components/LoadingOverlay';
import Modal from 'components/Modal';
import { block } from '../utils';

interface IProps {
  isLoading: boolean;
  onCancel: () => void;
  onRestore: () => void;
  restoreDateAndTime?: string;
  show: boolean;
}

const RestoreToVersionModal: React.SFC<IProps> = ({
  isLoading,
  onCancel,
  onRestore,
  restoreDateAndTime,
  show,
}) => (
  <Modal
    className={block('restore-modal')}
    show={show}
    onHide={onCancel}
    backdrop="static"
    title="Restore version"
  >
    <Modal.Body className={block('restore-modal-body')}>
      {isLoading && (
        <LoadingOverlay
          title="Restoring in progress"
          description="This may take a while, so please keep your browser open and check back later"
        />
      )}

      <div>
        Your current video will revert to version from {restoreDateAndTime}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Modal.FooterButtons>
        <Modal.FooterButton
          className={block('restore-modal-cancel')}
          onClick={onCancel}
        >
          Cancel
        </Modal.FooterButton>
        <Modal.FooterButton
          className={block('restore-modal-restore')}
          theme="submit"
          onClick={onRestore}
          disabled={isLoading}
        >
          Restore
        </Modal.FooterButton>
      </Modal.FooterButtons>
    </Modal.Footer>
  </Modal>
);

export default RestoreToVersionModal;
export { IProps as RestoreToVersionModalProps };
