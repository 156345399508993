import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import FixedAspect from 'components/FixedAspect';
import { H1 } from 'components/Heading';
import { HeadphonesHeady } from 'components/icons';

import {
  podcastArtworkSelector,
  suggestionIdsSelector,
} from 'redux/modules/clip-select';
import { introBlock as block } from '../utils';

export interface ClipSelectIntroProps {
  className?: string;
}

const messageSelector = createSelector(suggestionIdsSelector, suggestionIds => {
  if (!suggestionIds) {
    return null;
  }

  if (suggestionIds.length === 0) {
    return (
      <>We can't find any clips for this episode, please select your own...</>
    );
  }

  return (
    <>
      We <em>listened</em> to this episode
      <br />
      and found{' '}
      <strong>
        {suggestionIds.length} awesome clip
        {suggestionIds.length > 1 ? 's' : ''}!
      </strong>
    </>
  );
});

const ClipSelectIntro: React.FC<ClipSelectIntroProps> = ({ className }) => {
  const message = useSelector(messageSelector);
  const artwork = useSelector(podcastArtworkSelector);

  return (
    <div className={cn(block(), className)}>
      <div className={block('callout')}>
        <div className={block('body')}>
          <div className={block('image-container')}>
            <FixedAspect aspectRatio={1}>
              {artwork && <img className={block('image')} src={artwork} />}
            </FixedAspect>
          </div>
          <H1 className={block('message')}>{message}</H1>
        </div>
        <div className={block('arrow')} />
      </div>
      <HeadphonesHeady className={block('heady')} />
    </div>
  );
};

export default ClipSelectIntro;
