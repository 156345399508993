import { RequestStatus } from 'types';
import { Type } from './action-types';
import { stateFactory } from './factories';
import { UserPrefAction, UserPrefState } from './types';

const defaultState = stateFactory();

export default function(
  state: UserPrefState = defaultState,
  action: UserPrefAction,
) {
  switch (action.type) {
    case Type.USER_PREF_GET_REQUEST: {
      return state.set('fetchUserPrefStatus', RequestStatus.REQUEST);
    }
    case Type.USER_PREF_GET_SUCCESS: {
      return state.set('fetchUserPrefStatus', RequestStatus.SUCCESS);
    }
    case Type.USER_PREF_GET_FAILURE: {
      return state.set('fetchUserPrefStatus', RequestStatus.FAILURE);
    }
    case Type.WAVEFORM_PREF_CREATE_REQUEST: {
      return state.set('saveWaveformPrefStatus', RequestStatus.REQUEST);
    }
    case Type.WAVEFORM_PREF_CREATE_SUCCESS: {
      return state.set('saveWaveformPrefStatus', RequestStatus.SUCCESS);
    }
    case Type.WAVEFORM_PREF_CREATE_FAILURE: {
      return state.set('saveWaveformPrefStatus', RequestStatus.FAILURE);
    }
    case Type.WAVEFORM_PREF_DELETE_REQUEST: {
      const { id } = action.payload;
      return state.update('deleteWaveformPrefStatuses', statuses =>
        statuses.set(id, RequestStatus.REQUEST),
      );
    }
    case Type.WAVEFORM_PREF_DELETE_SUCCESS: {
      const { id } = action.payload;
      return state.update('deleteWaveformPrefStatuses', statuses =>
        statuses.set(id, RequestStatus.SUCCESS),
      );
    }
    case Type.WAVEFORM_PREF_DELETE_FAILURE: {
      const { id } = action.payload;
      return state.update('deleteWaveformPrefStatuses', statuses =>
        statuses.set(id, RequestStatus.FAILURE),
      );
    }

    case Type.CAPTIONS_PREF_PUT_REQUEST:
      return state.set('fetchCaptionPrefStatus', RequestStatus.REQUEST);

    case Type.CAPTIONS_PREF_PUT_SUCCESS: {
      return state.set('fetchCaptionPrefStatus', RequestStatus.SUCCESS);
    }

    case Type.CAPTIONS_PREF_PUT_FAILURE:
      return state.set('fetchCaptionPrefStatus', RequestStatus.FAILURE);

    default:
      return state;
  }
}
