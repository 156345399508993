import { ColorPaletteState } from '@sparemin/blockhead';
import React, { useCallback, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { pick } from 'underscore';
import BlockImage from 'components/BlockImage';
import BlockImageTooltipContent from 'components/BlockImage/BlockImageTooltipContent';
import {
  AiImageType,
  IMediaMetadata,
} from 'redux/middleware/api/image-search-service';

import { hasTextToImageAccessSelector } from 'redux/modules/display-pref';
import { isPollingImageToVideoSelector } from 'redux/modules/image-to-video/selectors';
import { AspectRatioName } from 'types';
import { getValue } from 'utils/collections';

import AIMediaLoader from '../AIMediaLoader';
import ProvidersList from '../ProvidersList';
import SearchFooter from '../SearchFooter';
import SearchResults, {
  IFooterProps,
  OnLoadMore,
  SearchResultsProps,
} from '../SearchResults';
import { EngineRegistry } from '../types';
import { searchResultsBlock } from '../utils';
import {
  ImageData,
  ImageSearchResults as ImageSearchResultsType,
} from './types';
import useImageSearchResultsTopSlot from './useImageSearchResultsTopSlot';
import useTextToImage from './useTextToImage';
import { block } from './utils';

export interface ImageSearchResultsProps
  extends Pick<SearchResultsProps, 'onEngineSelect'> {
  hasQuery: boolean;
  selectedEngine?: string;
  results: ImageSearchResultsType;
  aspectRatioName?: AspectRatioName;
  imageType?: AiImageType;
  colorPaletteState: ColorPaletteState;
  onImageTypeChange: (value: AiImageType) => void;
  onAspectRatioNameChange: (value: AspectRatioName) => void;
  onGenerate?: () => void;
  onLoadMoreResults?: OnLoadMore;
  onRegenerate?: () => void;
  onImageClick?: (
    imageUrl: string,
    metadata: IMediaMetadata,
    engine: string,
  ) => void;
  onAnimateClick?: (sourceImageUrl: string) => void;
  onAnimateGatedClick?: () => void;
  onImageToVideoSuccess?: (videoUrl: string, posterUrl: string) => void;
  onTextToImageSuccess?: (url: string, poster: string) => void;
}

const ImageSearchResults: React.FC<ImageSearchResultsProps> = ({
  hasQuery,
  results,
  onLoadMoreResults,
  selectedEngine = 'textToImage',
  aspectRatioName,
  imageType,
  colorPaletteState,
  onImageTypeChange,
  onAspectRatioNameChange,
  onGenerate,
  onImageClick,
  onEngineSelect,
  onTextToImageSuccess,
  onAnimateGatedClick,
}) => {
  const isPollingImageToVideo = useSelector(isPollingImageToVideoSelector);
  const hasTextToImageAccess = useSelector(hasTextToImageAccessSelector);

  const handleImageClick = useCallback(
    (result: ImageData, engine: string) => () => {
      onImageClick(result.image.url, result.metadata, engine);
    },
    [onImageClick],
  );

  const {
    engine: textToImageEngine,
    renderInitialContent: textToImageInitialContent,
    renderErrorMessage: textToImageErrorMessage,
    renderResults: textToImageResults,
    selectedImage: textToImageSelectedImage,
    hasError: textToImageHasError,
  } = useTextToImage({
    onImageClick,
    onTextToImageSuccess,
    onAnimateGatedClick,
  });

  const initialContent =
    selectedEngine === 'textToImage' && textToImageInitialContent;

  const engines = useMemo((): EngineRegistry => {
    return Object.keys(results).reduce(
      (acc, engine) => {
        const engineData = results[engine];

        acc[engine] = {
          displayName: engineData.displayName,
          errorMessage: engineData.error,
          hasMoreResults:
            engineData.data && engineData.data.length < engineData.totalResults,
          hasResults: !!engineData.data,
          isSearching: engineData.isSearching,
          isLoading: engineData.isLoading,
          pro: !!engineData.pro,
        };

        return acc;
      },
      {
        ...(hasTextToImageAccess && { textToImage: textToImageEngine }),
      },
    );
  }, [hasTextToImageAccess, results, textToImageEngine]);

  const topSlot = useImageSearchResultsTopSlot({
    hasQuery,
    selectedEngine,
    engines: pick(engines, [selectedEngine]),
    aspectRatioName,
    imageType,
    colorPaletteState,
    onImageTypeChange,
    onAspectRatioNameChange,
    onGenerate,
  });

  const handleRenderProvidersList = useCallback(() => {
    return (
      <div className={searchResultsBlock('multiple-providers-list')}>
        <ProvidersList
          title="AI generated"
          engines={pick(engines, ['textToImage'])}
          isDisabled={isPollingImageToVideo}
        />

        <ProvidersList
          title="Web search"
          engines={pick(engines, ['google', 'microsoft'])}
          noProvidersMessage="Sorry, but there are no image providers for this account."
          isDisabled={isPollingImageToVideo}
        />
        <ProvidersList
          title="Libraries"
          engines={pick(engines, ['pixabay'])}
          noProvidersMessage="Sorry, but there are no image providers for this account."
          isDisabled={isPollingImageToVideo}
        />
      </div>
    );
  }, [engines, isPollingImageToVideo]);

  const handleRenderFooter = useCallback(
    (footerProps: IFooterProps) => {
      if (selectedEngine !== 'textToImage') {
        return (
          <SearchFooter
            className={footerProps.className}
            engineList={Object.keys(results)}
            selectedEngine={selectedEngine}
            searchType="image"
          />
        );
      }

      return undefined;
    },
    [results, selectedEngine],
  );

  const handleRenderResults = useCallback(
    (engine: string) => {
      if (engine === 'textToImage') {
        if (textToImageHasError) {
          return [textToImageErrorMessage];
        }

        return [textToImageResults];
      }

      return results[engine].data.map(result => {
        const { image } = result;

        return (
          <Col
            className={block('image-result')}
            xs={6}
            sm={4}
            lg={3}
            onClick={handleImageClick(result, engine)}
            key={image.url}
          >
            <BlockImage
              height={image.height}
              width={image.width}
              src={getValue(result, ['thumbnail', 'url'], image.url)}
              tooltip={
                engine === 'ap' && (
                  <BlockImageTooltipContent
                    description={getValue(result, ['metadata', 'description'])}
                    footer={getValue(result, ['metadata', 'usageTerm'])}
                  />
                )
              }
            />
          </Col>
        );
      });
    },
    [
      handleImageClick,
      results,
      textToImageErrorMessage,
      textToImageHasError,
      textToImageResults,
    ],
  );

  return (
    <SearchResults
      id="media-search__image-results"
      active={selectedEngine}
      className={block('image-results')}
      customLoader={
        selectedEngine === 'textToImage' ? (
          <AIMediaLoader
            aspectRatio={aspectRatioName}
            sourceImageUrl={textToImageSelectedImage}
            targerMediaType="image"
          />
        ) : (
          undefined
        )
      }
      engines={engines}
      itemSelector=".media-search__image-result"
      onEngineSelect={onEngineSelect}
      onLoadMore={onLoadMoreResults}
      initialContent={initialContent}
      providersList={handleRenderProvidersList()}
      renderFooter={handleRenderFooter}
      renderResults={handleRenderResults}
      topSlot={topSlot}
    />
  );
};

export default ImageSearchResults;
