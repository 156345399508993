import React from 'react';

import { IconProps } from './types';

const FacebookRounded: React.FunctionComponent<IconProps> = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <filter
        x="-25%"
        y="-21.9%"
        width="150%"
        height="150%"
        filterUnits="objectBoundingBox"
        id="y2hymgwfwa"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="2.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <circle id="5u7huvap6b" cx="16" cy="16" r="16" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="rotate(180 16 16)">
        <use fill="#000" filter="url(#y2hymgwfwa)" xlinkHref="#5u7huvap6b" />
        <use fill="#3B5998" xlinkHref="#5u7huvap6b" />
      </g>
      <path
        d="M23 10.5v11a1.5 1.5 0 0 1-1.5 1.5h-2.666v-5.538h1.894L21 15.35h-2.166V14c0-.613.17-1.028 1.047-1.028H21v-1.888A15.041 15.041 0 0 0 19.369 11c-1.613 0-2.719.984-2.719 2.794v1.56h-1.9v2.112h1.903V23H10.5A1.5 1.5 0 0 1 9 21.5v-11A1.5 1.5 0 0 1 10.5 9h11a1.5 1.5 0 0 1 1.5 1.5z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default FacebookRounded;
