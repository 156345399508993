import * as React from 'react';

import { IconProps } from 'components/icons/types';

/* tslint:disable-next-line:variable-name */
const RevisionHistory: React.SFC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.485 7.882h1.28v3.586l3.323 2.289-.724 1.056-3.88-2.673V7.882zM13.365 3a8.647 8.647 0 0 1 7.039 3.639 8.647 8.647 0 0 1-4.627 13.303 8.644 8.644 0 0 1-7.778-1.514l.983-1.28a7.041 7.041 0 0 0 10.643-2.28A7.044 7.044 0 1 0 6.35 11.03h2.692L5.522 14.87 2 11.03h2.747A8.644 8.644 0 0 1 13.365 3z"
    />
  </svg>
);

export default RevisionHistory;
