import * as React from 'react';

import { useDispatch } from 'react-redux';
import ConnectedModal from 'containers/ConnectedModal';
import { markFreeTrialReminderSeen } from 'redux/modules/pricing/actions';
import FreeTrialReminderModalContent from './FreeTrialReminderModalContent';

const FreeTrialReminderModal: React.FC = () => {
  const dispatch = useDispatch();
  const onHide = () => dispatch(markFreeTrialReminderSeen());

  return (
    <ConnectedModal
      title="Your Free Trial of Pro"
      name="FreeTrialReminder"
      {...{ onHide }}
    >
      <FreeTrialReminderModalContent />
    </ConnectedModal>
  );
};

export default FreeTrialReminderModal;
