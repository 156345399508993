import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVideo } from 'redux/middleware/api/media-upload-service/actions';
import { lockedFieldsSelectorByRatio } from 'redux/modules/display-pref';

import { showNotification } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { getAspectRatioName } from 'utils/aspect-ratio';

import { useNavigation } from '../context/NavigationContext';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { useModalManager } from '../ModalManager';
import { IntroOutroState, IntroOutroType } from '../types';
import useAddIntroOutro from '../useAddIntroOutro';
import useEditorLoading from '../useEditorLoading';

interface UseIntroOutro {
  aspectRatioName: string;
  introLocked: boolean;
  introOutro: IntroOutroState;
  onAddIntroOutro: (type: IntroOutroType) => void;
  onDeleteIntroOutro: (type: IntroOutroType) => void;
  onExpandIntroOutro: (type: IntroOutroType) => void;
  onLoadIntroOutro: (type: IntroOutroType, id: number) => void;
  onReplaceIntroOutro: (type: IntroOutroType) => void;
  outroLocked: boolean;
}

const useIntroOutro = (): UseIntroOutro => {
  const reduxDispatch = useDispatch<Dispatch>();
  const [, send] = useNavigation();
  const dispatch = useTemplateDispatch();
  const modalManager = useModalManager();
  const [, isEditorLoading] = useEditorLoading();
  const { aspectRatio: aspectRatioCoef, introOutro } = useTemplateState();
  const onAddMedia = useAddIntroOutro();
  const aspectRatioName = getAspectRatioName(aspectRatioCoef);
  const lockedFields = useSelector(
    lockedFieldsSelectorByRatio(aspectRatioName),
  );

  // Checks locked fields for finding out if intro/outro selection inputs are locked
  const introLocked = lockedFields.has('intro');
  const outroLocked = lockedFields.has('outro');

  const onAddIntroOutro = React.useCallback(
    (type: IntroOutroType) => {
      onAddMedia(type, 'add')();
    },
    [onAddMedia],
  );

  const onDeletionConfirmed = React.useCallback(
    (type: IntroOutroType) => () => {
      dispatch({
        type: 'INTRO_OUTRO_DELETE',
        payload: {
          type,
        },
      });
      // after removing intro/outro clip it is required to close the child view and return to intro/outro child view
      send({
        type: 'CHILD_VIEW_OPEN',
        payload: 'intro_outro',
        meta: { source: 'options' },
      });
    },
    [dispatch, send],
  );

  const onDeleteIntroOutro = React.useCallback(
    (type: IntroOutroType) => {
      modalManager.showModal('delete', {
        assetName: `this ${type}`,
        onDelete: onDeletionConfirmed(type),
        title: `delete ${type}`,
      });
    },
    [modalManager, onDeletionConfirmed],
  );

  const onReplaceIntroOutro = React.useCallback(
    (type: IntroOutroType) => {
      onAddMedia(type, 'replace')();
    },
    [onAddMedia],
  );

  const onExpandIntroOutro = React.useCallback(
    (type: IntroOutroType) => {
      send({
        type: 'CHILD_VIEW_OPEN',
        payload: 'intro_outro_edit',
        meta: { source: 'options', id: type },
      });
    },
    [send],
  );

  const onLoadIntroOutro = React.useCallback(
    async (type: IntroOutroType, id: number) => {
      isEditorLoading(true);
      try {
        const mediaData = await reduxDispatch(getVideo(id));

        if (!mediaData.response.entities.videos?.[id]) {
          throw new Error('Video not found');
        }

        dispatch({
          type: 'INTRO_OUTRO_LOAD',
          payload: {
            type,
            src: mediaData.response.entities.videos[id],
            // TODO add file name is added to media upload file.
            // This will be done in a separated ticket
            fileName: '',
          },
        });
      } catch {
        reduxDispatch(
          showNotification({
            message: `The ${type} clip could not be loaded. Thumbnail will not be visible`,
            level: 'error',
            title: `Sorry, we could not load the ${type} clip`,
          }),
        );
      } finally {
        isEditorLoading(false);
      }
    },
    [dispatch, reduxDispatch, isEditorLoading],
  );

  return {
    aspectRatioName,
    introLocked,
    introOutro,
    onAddIntroOutro,
    onDeleteIntroOutro,
    onExpandIntroOutro,
    onLoadIntroOutro,
    onReplaceIntroOutro,
    outroLocked,
  };
};

export default useIntroOutro;
