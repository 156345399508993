import cn from 'classnames';
import * as React from 'react';
import { isUndefined } from 'underscore';

import Draggable, { DraggableProps } from 'components/Draggable';

type PickedDraggableProps = Pick<
  DraggableProps,
  'onStart' | 'onStop' | 'onDrag'
>;
type PickedDivProps = Pick<React.HTMLProps<HTMLDivElement>, 'onClick'>;

export interface MinimapWindowProps
  extends PickedDraggableProps,
    PickedDivProps {
  className?: string;
  dragging?: boolean;
  minimapWidth?: number;
  position?: number;
  width?: number;
}

export default class MinimapWindow extends React.PureComponent<
  MinimapWindowProps
> {
  public render() {
    const {
      className,
      dragging,
      width,
      minimapWidth,
      onStart,
      onStop,
      onDrag,
      position,
      onClick,
    } = this.props;

    if (isUndefined(width)) {
      return null;
    }

    const containerClassName = cn({
      'tl-minimap__viewport': true,
      'tl-minimap__viewport--std': true,
      'tl-minimap__viewport--grabbing': dragging,
      'tl-minimap__viewport--grabbable': !dragging,
      [className]: !!className,
    });

    return (
      <Draggable
        bounds={{ left: 0, right: minimapWidth }}
        handle="#viewport"
        onStart={onStart}
        onStop={onStop}
        onDrag={onDrag}
        position={{ x: position, y: 0 }}
      >
        <div
          id="viewport"
          className={containerClassName}
          style={{ width }}
          onClick={onClick}
        />
      </Draggable>
    );
  }
}
