import React, { useCallback } from 'react';

import AutomationPostModalHeader from 'components/AutomationPostModalHeader';
import EducationalCard from 'components/EducationalCard/EducationalCard';
import LinkButton from 'components/LinkButton';
import Select, { Overrides } from 'components/Select';
import { InstagramAccountOption } from 'containers/InstagramPostVideoModal/types';
import { InstagramMediaType } from 'redux/middleware/api/podcast-service';
import { block } from '../../utils';

export interface ComposerBlockedViewProps {
  accounts: InstagramAccountOption[];
  instagramUserId: string | number;
  selectComponentOverrides: Overrides<InstagramAccountOption>;
  isBusinessAccount?: boolean;
  onMediaTypeChange: (newMediaType: InstagramMediaType) => void;
  onUserChange?: (newInstagramUserId: string | number) => void;
}

const ComposerBlockedView: React.FC<ComposerBlockedViewProps> = ({
  accounts,
  instagramUserId,
  selectComponentOverrides,
  isBusinessAccount,
  onMediaTypeChange,
  onUserChange,
}) => {
  const handleMediaTypeChange = useCallback((): void => {
    onMediaTypeChange('reels');
  }, [onMediaTypeChange]);

  return (
    <>
      <AutomationPostModalHeader
        title="Select an account"
        description="Which account would you like to auto-post your stories to?"
      />

      {!!accounts.length && (
        <Select
          options={accounts}
          overrides={selectComponentOverrides}
          // The accounts list contains the same values as those returned by
          // `instagramAccountsSelector`, but with different property names
          // assigned to accommodate the Select component. The `value`
          // property corresponds to the selected instagram user id.
          value={accounts.find(({ value }) => value === instagramUserId)}
          className={block('accounts-list')}
          onChange={({ value }) => onUserChange(value)}
        />
      )}

      {!isBusinessAccount && (
        <EducationalCard
          className={block('non-business-account-warning')}
          content={
            <>
              Instagram only allows business accounts to auto-post stories.
              Please select a business account from the dropdown above or switch
              this automation to auto-post as a{' '}
              <LinkButton theme="cta" onClick={handleMediaTypeChange}>
                reel
              </LinkButton>{' '}
              or a{' '}
              <LinkButton theme="cta" onClick={handleMediaTypeChange}>
                post
              </LinkButton>
              .
            </>
          }
        />
      )}
    </>
  );
};

export default ComposerBlockedView;
