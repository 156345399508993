import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isUndefined } from 'underscore';

import {
  debouncedSaveConfiguration,
  updateSlide,
} from 'redux/modules/embed/actions';
import {
  embedSlideIdsSelector,
  slidesSelector,
} from 'redux/modules/embed/selectors';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import ImageAsset, { ImageAssetProps } from '../components/ImageAsset';
import { millisToPosition, positionToMillis } from '../utils';

interface OwnProps extends Pick<ImageAssetProps, 'id'> {
  pxPerSec: number;
}

type StateProps = Pick<
  ImageAssetProps,
  'minWidth' | 'order' | 'position' | 'src' | 'width'
>;
type DispatchProps = Pick<
  ImageAssetProps,
  'onDrag' | 'onDragStop' | 'onResize' | 'onResizeStop'
>;

const idSelector = (_, props: OwnProps) => props.id;
const pxPerSecSelector = (_, props: OwnProps) => props.pxPerSec;

const makeMapStateToProps = () => {
  const slideOrderSelector = createSelector(
    [embedSlideIdsSelector, idSelector],
    (slideIds, id) => (!slideIds || !id ? undefined : slideIds.indexOf(id) + 1),
  );

  const slideSelector = createSelector(
    [slidesSelector, idSelector],
    (slidesById, id) => (!slidesById || !id ? undefined : slidesById.get(id)),
  );

  const slideTimesSelector = createSelector(
    slideSelector,
    slide =>
      slide && {
        endMillis: slide.get('endMilli'),
        startMillis: slide.get('startMilli'),
      },
  );

  const positionSelector = createSelector(
    [slideTimesSelector, pxPerSecSelector],
    (times, pxPerSec) =>
      isUndefined(times) ? 0 : millisToPosition(times.startMillis, pxPerSec),
  );

  const srcSelector = createSelector(
    slideSelector,
    slide => slide && slide.get('imageUrl'),
  );

  const widthSelector = createSelector(
    [slideTimesSelector, pxPerSecSelector],
    (time, pxPerSec) =>
      isUndefined(time)
        ? 0
        : millisToPosition(time.endMillis - time.startMillis, pxPerSec),
  );

  return (state: State, props: OwnProps): StateProps => ({
    minWidth: millisToPosition(
      spareminConfig.minTextDurationMillis,
      props.pxPerSec,
    ),
    order: slideOrderSelector(state, props),
    position: positionSelector(state, props),
    src: srcSelector(state, props),
    width: widthSelector(state, props),
  });
};

const makeMapDispatchToProps = () => (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onDrag: data =>
    dispatch(
      (updateSlide as any)(props.id, {
        endMillis: positionToMillis(data.x + data.width, props.pxPerSec),
        startMillis: positionToMillis(data.x, props.pxPerSec),
      }),
    ),
  onDragStop: (_, moved) => {
    if (moved) {
      dispatch(debouncedSaveConfiguration());
    }
  },
  onResize: (width, _, pos) =>
    dispatch(
      (updateSlide as any)(props.id, {
        endMillis: positionToMillis(pos + width, props.pxPerSec),
        startMillis: positionToMillis(pos, props.pxPerSec),
      }),
    ),
  onResizeStop: () => dispatch(debouncedSaveConfiguration()),
});

const component = connect(
  makeMapStateToProps,
  makeMapDispatchToProps,
)(ImageAsset);

export type SlideAssetProps = PropsOf<typeof component>;
export default component;
