import cn from 'classnames';
import * as React from 'react';
import Spinner from 'react-spinkit';

import { block } from './utils';

const LoadingImageOverlay: React.SFC = () => (
  <div className={block('overlay')}>
    <span className={block('overlay-text')}>Opening Image</span>
    <Spinner
      className={cn(
        block('spinner'),
        'spinner--blue',
        'spinner--wave',
        'spinner--large',
      )}
      spinnerName="wave"
    />
  </div>
);

export default LoadingImageOverlay;
