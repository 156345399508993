import cn from 'classnames';
import React from 'react';
import Image from 'components/Image';
import { block } from './utils';

interface MediaCardImageProps {
  imgSrc: string;
  className?: string;
}

const MediaCardImage: React.FC<MediaCardImageProps> = ({
  imgSrc,
  className,
}) => {
  return (
    <Image src={imgSrc} className={cn(block('media-card-image'), className)} />
  );
};

export default MediaCardImage;
