import { useDispatch, useSelector } from 'react-redux';
import { Tier } from 'redux/middleware/api/plan-service';
import {
  googleFonts as googleFontsSelector,
  isFontsLoading,
  personalFonts as personalFontsSelector,
} from 'redux/modules/fonts/selectors';
import { onClickFontUpgrade } from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal';
import { isLoadingSelector } from 'redux/modules/pricing';
import { Dispatch } from 'redux/types';

export function useFontSettings() {
  const dispatch = useDispatch<Dispatch>();
  const priceLoading = useSelector(isLoadingSelector);
  const fontsLoading = useSelector(isFontsLoading);
  const personalFonts = useSelector(personalFontsSelector);
  const googleFonts = useSelector(googleFontsSelector);

  function fontUpgrade() {
    dispatch(onClickFontUpgrade());
    dispatch(
      pushModal({
        name: 'AddBillingCycle',
        params: {
          tier: Tier.PRO,
          defaultSubscriptionPeriod: 'yearly',
          disableSuccessRedirect: true,
        },
      }),
    );
  }

  return {
    fontsLoading,
    fontUpgrade,
    personalFonts,
    googleFonts,
    priceLoading,
  };
}
