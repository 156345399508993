import bem from 'utils/bem';
import { Steps } from './types';

export const block = bem('episode-wizard');
export const episodeWizardDeliveryMethodStep = bem(
  'episode-wizard-delivery-method',
);
export const episodeWizardBackCatalogFinalMessage = bem(
  'episode-wizard-back-catalog-final-message',
);
export const episodeWizardSourceStep = bem('episode-wizard-source-step');

export const customizeStepBlock = bem('customize-step');

export function getStepIndex(
  step: Steps,
  skipTemplateStep: boolean = false,
): number {
  if (!skipTemplateStep || step > Steps.TEMPLATE_STEP) {
    return step;
  }

  return step - 1;
}
