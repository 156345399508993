import * as React from 'react';

import Heading from 'components/Heading';
import { block } from './utils';

const ConfirmationTitle: React.FC = props => {
  const { children } = props;
  return <Heading className={block('title')}>{children}</Heading>;
};

export default ConfirmationTitle;
