import * as React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import PauseSubscriptionDetailsModalContents from './PauseSubscriptionDetailsModalContents';

export interface PauseSubscriptionDetailsModalProps {}

const PauseSubscriptionDetailsModal: React.FC<PauseSubscriptionDetailsModalProps> = () => (
  <ConnectedModal name="PauseSubscriptionDetails" title="pause my plan">
    <PauseSubscriptionDetailsModalContents />
  </ConnectedModal>
);

export default PauseSubscriptionDetailsModal;
