import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { getMyPodcastFeed } from 'redux/modules/automation-cms/actions';
import { podcastFeedDataSelector } from 'redux/modules/automation-cms/selectors';
import { Dispatch } from 'redux/types';
import { RequestStatus } from 'types';

const makeFeedDataSelector = () =>
  createSelector(
    podcastFeedDataSelector,
    (_, podcastFeedId) => podcastFeedId,
    (feedData, feedId) => feedData?.get(feedId),
  );

export default function usePodcastFeedEpisodes(podcastFeedId: string) {
  const dispatch = useDispatch<Dispatch>();
  const feedDataSelector = useMemo(() => makeFeedDataSelector(), []);
  const feedData = useSelector(state => feedDataSelector(state, podcastFeedId));

  const isFetching = feedData?.status === RequestStatus.REQUEST;
  const hasMore =
    feedData?.number === undefined || feedData?.number < feedData?.totalPages;

  const episodes = useMemo(() => feedData?.episodes?.toArray(), [feedData]);

  const workflows = useMemo(() => feedData?.workflows?.toArray(), [feedData]);

  const canRefreshFeed = useMemo(() => feedData?.canRefreshFeed, [feedData]);

  const loadMoreEpisodes = useCallback(() => {
    if (!isFetching && hasMore && podcastFeedId !== undefined) {
      dispatch(getMyPodcastFeed(podcastFeedId));
    }
  }, [dispatch, hasMore, isFetching, podcastFeedId]);

  return {
    episodes,
    hasMore: hasMore && feedData?.status !== RequestStatus.FAILURE,
    isFetching,
    loadMoreEpisodes,
    workflows,
    canRefreshFeed,
  };
}
