import * as React from 'react';

import VideoCameraAlt2 from '../VideoCameraAlt2';
import CircularGradient, { CircularGradientProps } from './CircularGradient';

export type CircularGradientVideoCameraProps = Pick<
  CircularGradientProps,
  'className'
>;

const CircularGradientVideoCameraAlt2: React.FC<CircularGradientVideoCameraProps> = ({
  className,
}) => (
  <CircularGradient className={className}>
    <VideoCameraAlt2
      strokeWidth={1}
      style={{
        height: 'auto',
        marginLeft: '8%',
        width: '45%',
      }}
    />
  </CircularGradient>
);

export default CircularGradientVideoCameraAlt2;
