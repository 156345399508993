import { Recording } from 'redux/middleware/api/recording-service/types';
import { defaultLogoUrlSelector } from 'redux/modules/display-pref/selectors';
import { GetState } from 'redux/types';
import { ICaptions } from 'types';
import { omitUndefined } from 'utils/collections';
import { createAudioInfoFromRecording } from 'utils/embed/audio';
import { createVersionInfo } from 'utils/embed/embed';
import { DEFAULT_TRACK_ORDER } from 'utils/embed/tracks';
import { formatWatermarkSrcForConfig } from 'utils/embed/watermark';
import { aspectRatioDimensionsSelector } from './selectors';

export const createConfigForEmbed = (
  assets: any,
  captions: ICaptions,
  recording: Recording,
) => (_, getState: GetState) => {
  const aspectRatioDimensions = aspectRatioDimensionsSelector(getState());
  const { slideshowInfo = [], videoClips = [] } = assets;
  const audioInfo = [createAudioInfoFromRecording(recording as any)];
  const versionInfo = createVersionInfo(spareminConfig.version);
  const dimensions = aspectRatioDimensions;
  const watermark = formatWatermarkSrcForConfig(
    defaultLogoUrlSelector(getState()),
    recording.get('durationMillis'),
  );

  return omitUndefined({
    audioInfo,
    captions,
    dimensions,
    slideshowInfo,
    versionInfo,
    videoClips,
    expectedDurationMilli: recording.get('durationMillis'),
    layerOrder: DEFAULT_TRACK_ORDER,
    watermark: watermark && [watermark],
  });
};
