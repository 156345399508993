import * as Immutable from 'immutable';
import _ from 'underscore';

import { TemplateEditorStateExport } from 'components/VideoTemplateEditor';
import {
  DeepImmutableMap,
  DeepPartial,
  IEmbedConfig,
  IEmbedConfiguration,
  ImageOriginId,
  KeyTextType,
} from 'types';
import { getValue } from 'utils/collections';
import { AUDIOGRAM_CUTOFF_MILLIS, KEY_TEXT_TYPE } from 'utils/constants';
import { parseCSSFilter } from 'utils/dom';
import measurement, { Measurement } from 'utils/measurement';
import { formatAudioFromConfig, getTrackDataFromAudioConfig } from './audio';
import { formatCaptionsFromConfig } from './captions/captions';
import { formatMediaContainerFromConfig } from './media-container';
import { formatProgressFromConfig } from './progress';
import { createSlideStateFromConfig, isSpitfireSlide } from './slideshow';
import { formatSoundwaveFromConfig } from './soundwave';
import { formatOverlayFromConfig } from './text-overlay';
import { formatTimerFromConfig } from './timer';
import { formatVideoFromConfig } from './video';
import { formatWatermarkFromConfig } from './watermark';

export function createEmbedCode(wid, recordingId) {
  const dataR = !_.isUndefined(recordingId) ? `data-r='${recordingId}'` : '';
  return (
    `<div class='sparemin-embed' ${dataR} data-templateid='6' data-widgetid='${wid}'></div>` +
    "<script async type='text/javascript' src='https://embed.sparemin.com/public/js/widget.bundle.min.js'></script>"
  );
}

export function isConfigEmpty(config) {
  if (!config) return true;

  const { embedConfig } = config;
  if (!embedConfig) return true;

  /*
   * NB: specifically checking !expectedDurationMilli instead of undefeind becuase embed service
   *    doesn't allow missing expected duration or 0 if recording is also missing
   */
  const { expectedDurationMilli, recordingId } = embedConfig;
  return !expectedDurationMilli && _.isUndefined(recordingId);
}

export function formatStateFromEmbedConfig(config) {
  const {
    audioInfo,
    dimensions,
    edgeVideos,
    mainMediaContainer,
    progress,
    textOverlayInfo,
    slideshowInfo,
    soundwave: soundwaveConfig,
    timer,
    watermark,
    videoClips,
  } = config;

  const aspectRatio = Immutable.Map({ ...dimensions });

  const { textOverlayById, textOverlayTrackData } = !textOverlayInfo
    ? { textOverlayById: undefined, textOverlayTrackData: Immutable.List() }
    : textOverlayInfo.reduce(
        (res, overlay) => {
          const overlayState = formatOverlayFromConfig(overlay);
          const overlayId = overlayState.get('id');
          const trackIndex = overlay.layerId;
          return {
            textOverlayById: res.textOverlayById.set(overlayId, overlayState),
            textOverlayTrackData: res.textOverlayTrackData.update(
              trackIndex,
              (l = Immutable.List()) => l.push(overlayId),
            ),
          };
        },
        {
          textOverlayById: Immutable.Map(),
          textOverlayTrackData: Immutable.List(),
        },
      );

  const { slidesById, slideMediaTrackData } = !slideshowInfo
    ? { slidesById: undefined, slideMediaTrackData: Immutable.List() }
    : slideshowInfo.reduce(
        (res, slide) => {
          const slideState = createSlideStateFromConfig(slide, aspectRatio);
          const slideId = slideState.id;
          const trackIndex = slide.layerId;
          return {
            slidesById: {
              ...res.slidesById,
              [slideId]: slideState,
            },
            slideMediaTrackData: res.slideMediaTrackData.update(
              trackIndex,
              (l = Immutable.List()) => l.push(slideId),
            ),
          };
        },
        { slidesById: {}, slideMediaTrackData: Immutable.List() },
      );

  const { videosById, videoMediaTrackData } = !videoClips
    ? { videosById: undefined, videoMediaTrackData: Immutable.List() }
    : videoClips.reduce(
        (res, video) => {
          const videoState = formatVideoFromConfig(video);
          const videoId = videoState.id;
          const trackIndex = video.layerId;
          return {
            videosById: res.videosById.set(videoId, videoState),
            videoMediaTrackData: res.videoMediaTrackData.update(
              trackIndex,
              (l = Immutable.List()) => l.push(videoId),
            ),
          };
        },
        { videosById: Immutable.Map(), videoMediaTrackData: Immutable.List() },
      );

  const mediaTrackData = videoMediaTrackData.mergeWith(
    (vidData = Immutable.List(), slideData = Immutable.List()) => {
      const allData = vidData.concat(slideData);
      return allData.isEmpty() ? undefined : allData;
    },
    slideMediaTrackData,
  );

  const { audioById, audioTrackData } = !audioInfo
    ? { audioById: Immutable.Map(), audioTrackData: Immutable.List() }
    : audioInfo.reduce(
        (res, audio) => {
          const audioState = formatAudioFromConfig(audio);
          const audioId = audioState.get('id');
          const { trackIndex } = getTrackDataFromAudioConfig(audio);
          return {
            audioById: res.audioById.set(audioId, audioState),
            audioTrackData: res.audioTrackData.update(
              trackIndex,
              (l = Immutable.List()) => l.push(audioId),
            ),
          };
        },
        { audioById: Immutable.Map(), audioTrackData: Immutable.List() },
      );

  const soundwave = formatSoundwaveFromConfig(soundwaveConfig);
  const waveformTrackData =
    soundwave &&
    Immutable.List().set(soundwaveConfig.layerId, Immutable.List(['waveform']));

  const watermarkById = !watermark
    ? Immutable.Map()
    : watermark.reduce((acc, wmark) => {
        const wmarkState = formatWatermarkFromConfig(wmark);
        return acc.set(wmarkState.get('id'), wmarkState);
      }, Immutable.Map());

  const captionsInfo = formatCaptionsFromConfig(config);

  const tracks = textOverlayTrackData.mergeWith(
    (d1, d2) => d1 || d2,
    mediaTrackData,
    audioTrackData,
    waveformTrackData,
  );

  const { canvasColor } = formatMediaContainerFromConfig(mainMediaContainer);
  const progressAnimation = formatProgressFromConfig(progress);
  const timerState = formatTimerFromConfig(timer);

  return {
    audioById,
    canvasColor,
    edgeVideos,
    progressAnimation,
    slidesById,
    textOverlayById,
    tracks,
    videosById,
    watermarkById,
    soundwave,
    captions: captionsInfo,
    trackOrder: Immutable.List(config.layerOrder),
    timer: timerState,
  };
}

export function createVersionInfo(version) {
  return {
    embedApp: version,
  };
}

export function readVersionFromConfig(config) {
  const embedConfig = config && config.embedConfig;
  const versionInfo = embedConfig && embedConfig.versionInfo;
  return versionInfo && versionInfo.embedApp;
}

export function writeVersionToConfig(config, version) {
  if (!config) return undefined;

  const versionInfo = createVersionInfo(version);
  return {
    ...config,
    embedConfig: {
      ...config.embedConfig,
      versionInfo: {
        ...config.embedConfig.versionInfo,
        ...versionInfo,
      },
    },
  };
}

/*
 * complex templates are all those that have lottie assets on the media track,
 * except rotating cube becuase that was implemented as an effect.  the cube
 * template allows for other assets (waveform, bg color) but the lottie templates
 * do not
 */
export function isSpitfireConfig<
  Config extends Pick<IEmbedConfig, 'slideshowInfo'>
>(config: Config): boolean {
  const slideshow = config?.slideshowInfo ?? [];
  return slideshow.some(isSpitfireSlide);
}

export function isKeyTextAssetType(assetType: any): assetType is KeyTextType {
  return KEY_TEXT_TYPE.includes(assetType);
}

type Config =
  | DeepPartial<TemplateEditorStateExport>
  | IEmbedConfiguration
  | DeepImmutableMap<IEmbedConfiguration>;

export function hasSlideFromOrigin(
  origin: ImageOriginId,
  config: Config,
): boolean {
  const slideshowPaths = [['embedConfig', 'slideshowInfo'], ['slideshow']];

  const slideOriginPaths = [
    ['sourceImageOrigin', 'origin'],
    ['sourceImage', 'origin'],
  ];

  const slideshow = slideshowPaths
    .map(path => getValue(config, path))
    .find(value => value !== undefined);
  const slideFromOrigin = slideshow?.find(slide =>
    slideOriginPaths.find(path => getValue(slide, path) === origin),
  );
  return slideFromOrigin !== undefined;
}

interface AudioClip {
  startMillis: number;
  endMillis: number;
}

function isDuration<T extends AudioClip>(
  durationOrClip: number | T,
): durationOrClip is number {
  return typeof durationOrClip === 'number';
}

export function isAudiogram<T extends AudioClip>(
  durationOrClip: number | T,
): boolean {
  if (_.isUndefined(durationOrClip) || _.isNull(durationOrClip))
    return undefined;

  const durationMillis = isDuration(durationOrClip)
    ? durationOrClip
    : durationOrClip.endMillis - durationOrClip.startMillis;
  return durationMillis <= AUDIOGRAM_CUTOFF_MILLIS;
}

export function getBlurRadius(
  config: Partial<TemplateEditorStateExport>,
): Measurement {
  const { slideshow } = config;
  const slideWithBlurRadius = slideshow?.find(slide => slide?.blurRadius);
  if (slideWithBlurRadius) {
    return measurement(slideWithBlurRadius.blurRadius);
  }

  return undefined;
}

export function getBlurRadiusFromConfig(config: Partial<IEmbedConfig>) {
  const { slideshowInfo } = config;
  const slideWithBlurRadius = slideshowInfo?.find(
    slide => parseCSSFilter(slide?.style?.filter)?.blur?.radius,
  );
  const parsedFilter = parseCSSFilter(slideWithBlurRadius?.style?.filter);
  if (parsedFilter?.blur?.radius) {
    return measurement(parsedFilter.blur.radius);
  }
  return undefined;
}

export default {
  createEmbedCode,
  formatStateFromEmbedConfig,
};
