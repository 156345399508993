import * as React from 'react';

import { constant } from 'underscore';
import LoadingOverlay from 'components/LoadingOverlay';
import ShareFormTextarea from 'components/ShareForm/ShareFormTextarea';
import SocialAccountInfo from 'components/SocialAccountInfo';
import { getUsernameHandle } from 'containers/TwitterPostVideoModal/utils';
import useThreadsPostModalSelector from './useThreadsPostModalSelector';
import { block, MAX_CHARS_DESCRIPTION } from './utils';

export interface ThreadsPostComposerProps {
  description: string;
  isUploading?: boolean;
  readonly?: boolean;
  onDescriptionChange: (description: string) => void;
  onSwitchAccountClick: () => void;
}

const ThreadsPostComposer: React.FC<ThreadsPostComposerProps> = ({
  description,
  isUploading = false,
  readonly,
  onDescriptionChange,
  onSwitchAccountClick,
}) => {
  const { username, profileImageUrl } = useThreadsPostModalSelector();

  return (
    <>
      {isUploading && <LoadingOverlay title="Posting Video" />}

      <div className={block('share-form')}>
        {username && (
          <SocialAccountInfo
            avatarSrc={profileImageUrl}
            username={getUsernameHandle(username)}
            onSwitchAccountClick={onSwitchAccountClick}
          />
        )}

        <ShareFormTextarea
          value={description}
          maxChars={MAX_CHARS_DESCRIPTION}
          readonly={readonly}
          renderProgress={readonly ? constant(null) : undefined}
          controlClassName={block('description-control')}
          onChange={onDescriptionChange}
        />
      </div>
    </>
  );
};

export default ThreadsPostComposer;
