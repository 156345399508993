import * as React from 'react';
import _ from 'underscore';

interface IProps extends React.HTMLProps<HTMLIFrameElement> {
  allow?: string;
  iframeRef?: (el: HTMLIFrameElement) => void;
}

export default class FeaturePolicyIFrame extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    iframeRef: _.noop,
    onLoad: _.noop,
  };

  private iframe: HTMLIFrameElement;

  /*
   * add the `allow` feature policy attribute since React doesn't know about it and won't allow us
   * to easily add it via jsx.
   *
   * also seems it doesn't work to add this attribute on mount, so using the load handler
   */
  private handleLoad = (e: React.SyntheticEvent<HTMLIFrameElement>) => {
    const { allow, onLoad } = this.props;

    if (!_.isUndefined(allow)) {
      (this.iframe as any).allow = allow;
    }

    onLoad(e);
  };

  public render() {
    const { allow, iframeRef, ...iframeProps } = this.props;

    const ref = el => {
      iframeRef(el);
      this.iframe = el;
    };

    return <iframe {...iframeProps} onLoad={this.handleLoad} ref={ref} />;
  }
}
