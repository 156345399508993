import * as React from 'react';
import { noop } from 'underscore';

import Button from 'components/Button';
import Disclaimer from './Disclaimer';
import { block } from './utils';

export interface ContentFreeProps {
  onClickFontUpgrade?: () => void;
}

function ContentFree({ onClickFontUpgrade }: ContentFreeProps) {
  return (
    <>
      <div className={block('content')}>
        Upgrade to Headliner Pro to unlock custom fonts!
        <div className={block('button-action-container', { upgrade: true })}>
          <Button
            theme="rainbow"
            className={block('button-action')}
            onClick={onClickFontUpgrade}
          >
            UPGRADE TO PRO
          </Button>
        </div>
      </div>

      <Disclaimer />
    </>
  );
}

ContentFree.defaultProps = {
  onClickFontUpgrade: noop,
};

export default ContentFree;
