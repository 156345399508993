import * as React from 'react';
import _ from 'underscore';

import SplitPane from 'components/SplitPane';
import { Split } from '../../utils/constants';
import { Position, Size } from './types';
import { getSplitByPosition, renderSingleAndDualPane } from './utils';

interface IProps {
  /**
   * for a horizontal main split, this is the size of the top pane.  for a vertical main split,
   * this is the size of the left pane.
   *
   * accepts any css size value
   */
  defaultMainSplitFirstPaneSize?: Size;

  /**
   * for a horizontal main split (and thereby a vertical seconary split), this is the size of
   * the left subpane.
   *
   * accepts any css size value
   */
  defaultSecondarySplitFirstPaneSize?: Size;

  /**
   * A wrapper component for the dual pane
   */
  dualPaneWrapper: any;

  /**
   * Props for the dual pane
   */
  dualPaneWrapperProps?: any;

  /**
   * class name for the handle that allows for resizing the main split
   */
  mainResizerClassName?: string;
  mainSplitComponent?: string;
  mainSplitProps?: any;

  /**
   * callbacks for the main resizer, separating the single pane from the dual pane
   */
  onMainResizerDrag?: () => void;
  onMainResizerDragStart?: () => void;
  onMainResizerDragStop?: () => void;

  /**
   * callbacks for the secondary resizer, splitting the dual pane
   */
  onSecondaryResizerDrag?: () => void;
  onSecondaryResizerDragStart?: () => void;
  onSecondaryResizerDragStop?: () => void;

  /**
   * for a horizontal main split (and thereby a vertical dual split), this is the callback to
   * render the left pane within the dual pane.
   *
   * for a vertical main split (and thereby a horizontal dual split), this is the callback to
   * render the top pane within the dual pane.
   */
  renderFirstSubpane?: (height: Size, width: Size) => void;

  /**
   * render callback for the single pane
   */
  renderMainPane?: (height: Size, width: Size) => void;

  /**
   * for a horizontal main split (and thereby a vertical dual split), this is the callback to
   * render the right pane within the dual pane.
   *
   * for a vertical main split (and thereby a horizontal dual split), this is the callback to
   * render the bottom pane within the dual pane.
   */
  renderSecondSubpane?: (height: Size, width: Size) => void;

  /**
   * class name of the resizer that splits the dual pane
   */
  secondaryResizerClassName?: string;

  /**
   * the position of the single pane.
   *
   * if the position is 'top' or 'bottom', the main split will be horizontal and the secondary
   * split (for the dual pane component) will be vertical.
   *
   * if the position is 'left' or 'right', the main split will be vertical and the secondary
   * split (for the dual pane component) will be horizontal.
   */
  singlePanePosition?: Position;

  /**
   * A wrapper component for the single pane
   */
  singlePaneWrapper: any;

  /**
   * Props for the `singlePaneWrapper`
   */
  singlePaneWrapperProps?: any;
}

/**
 * A component which divides the screen into 3 sections.  This supports one of 4 configurations:
 *  1. single pane on top, vertically-split dual pane on bottom
 *  2. single pane on bottom, vertically-split dual pane on top
 *  3. single pane on left, horizontally-split dual pane on right
 *  4. single pane on right, horizontally-split dual pane on left
 *
 * Some nomenclature:
 *  - "main split": this is the split separating the single pane from the dual pane
 *
 *  - "secondary split": this is the split separating the dual pane
 *
 *  - "resizer": the handle the user drags to resize the panes
 *
 *  - "first" (e.g. "firstPane"): for vertical splits, this is always the left pane.  For horizontal
 *    splits, this is always the top pane (see SplitPane.jsx)
 *
 *  - "single": the single pane.  note that this is not the same as "first". the primary pane could
 *    be the pane on the right in a vertical split, however the pane on the left is always the
 *    "first" one
 *
 *  - "dual": the dual pane
 *
 */
export default class TriPane extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    defaultMainSplitFirstPaneSize: '50%',
    defaultSecondarySplitFirstPaneSize: '50%',
    mainResizerClassName: '',
    mainSplitComponent: 'div',
    mainSplitProps: {},
    onMainResizerDrag: _.noop,
    onMainResizerDragStart: _.noop,
    onMainResizerDragStop: _.noop,
    onSecondaryResizerDrag: _.noop,
    onSecondaryResizerDragStart: _.noop,
    onSecondaryResizerDragStop: _.noop,
    renderFirstSubpane: _.noop,
    renderMainPane: _.noop,
    renderSecondSubpane: _.noop,
    secondaryResizerClassName: '',
    singlePanePosition: Position.BOTTOM,
  };

  private renderFirstSubpane = (size1: Size) => (size2: Size) => {
    const { renderFirstSubpane } = this.props;

    return renderFirstSubpane(size1, size2);
  };

  private renderSecondSubpane = (size1: Size) => (size2: Size) => {
    const { renderSecondSubpane } = this.props;

    return renderSecondSubpane(size1, size2);
  };

  private renderSinglePane = (size: Size) => {
    const {
      singlePaneWrapper: MainPaneWrapper,
      singlePaneWrapperProps: mainPaneWrapperProps,
      renderMainPane,
    } = this.props;

    return (
      <MainPaneWrapper {...mainPaneWrapperProps}>
        {renderMainPane(size, size)}
      </MainPaneWrapper>
    );
  };

  private renderDualPane = (size: Size) => {
    const {
      defaultSecondarySplitFirstPaneSize,

      dualPaneWrapper: SecondaryPaneWrapper,
      dualPaneWrapperProps: secondaryPaneWrapperProps,
      onSecondaryResizerDrag,
      onSecondaryResizerDragStart,
      onSecondaryResizerDragStop,
      secondaryResizerClassName,
      singlePanePosition,
    } = this.props;
    const split =
      getSplitByPosition(singlePanePosition) === Split.HORIZONTAL
        ? Split.VERTICAL
        : Split.HORIZONTAL;

    return (
      <SecondaryPaneWrapper {...secondaryPaneWrapperProps}>
        <SplitPane
          className={secondaryResizerClassName}
          defaultSize={defaultSecondarySplitFirstPaneSize}
          onResizerDrag={onSecondaryResizerDrag}
          onResizerDragStart={onSecondaryResizerDragStart}
          onResizerDragStop={onSecondaryResizerDragStop}
          split={split}
          renderFirstPane={this.renderFirstSubpane(size)}
          renderSecondPane={this.renderSecondSubpane(size)}
        />
      </SecondaryPaneWrapper>
    );
  };

  public render() {
    const {
      defaultMainSplitFirstPaneSize,
      onMainResizerDrag,
      onMainResizerDragStart,
      onMainResizerDragStop,
      mainResizerClassName,
      singlePanePosition,
    } = this.props;

    const { renderFirstPane, renderSecondPane } = renderSingleAndDualPane(
      singlePanePosition,
      this.renderSinglePane,
      this.renderDualPane,
    );

    return (
      <SplitPane
        className={mainResizerClassName}
        defaultSize={defaultMainSplitFirstPaneSize}
        onResizerDrag={onMainResizerDrag}
        onResizerDragStart={onMainResizerDragStart}
        onResizerDragStop={onMainResizerDragStop}
        renderFirstPane={renderFirstPane}
        renderSecondPane={renderSecondPane}
        split={getSplitByPosition(singlePanePosition)}
      />
    );
  }
}

export { IProps as TriPaneProps };
