import * as Immutable from 'immutable';
import ids from 'short-id';

import { getValue, omitUndefined } from 'utils/collections';

export function formatWatermarkForConfig(watermark, embedDurationMillis) {
  return {
    endMilli: embedDurationMillis,
    position: {
      properties: {
        top: getValue(watermark, ['position', 'top'], 0),
        left: getValue(watermark, ['position', 'left'], 0),
      },
      type: 'absolute',
    },
    startMilli: 0,
    style: {
      height: getValue(watermark, ['size', 'height'], '100vh'),
      width: getValue(watermark, ['size', 'width'], '100vw'),
    },
    url: getValue(watermark, 'src'),
  };
}

export function formatWatermarkSrcForConfig(src, embedDurationMillis) {
  const position = Immutable.fromJS({
    left: '0vw',
    top: '0vh',
  });
  const size = Immutable.fromJS({
    height: '100vh',
    width: '100vw',
  });

  return !src
    ? undefined
    : formatWatermarkForConfig(
        Immutable.Map({ src, position, size }),
        embedDurationMillis,
      );
}

export function formatWatermarkFromConfig(watermark) {
  return Immutable.fromJS(
    omitUndefined({
      id: ids.generate(),
      position: getValue(watermark, ['position', 'properties']),
      size: watermark.style,
      src: watermark.url,
    }),
  );
}

export function formatWatermarkState(src, watermarkConfig) {
  if (!src) return undefined;

  const watermark = formatWatermarkFromConfig({ ...watermarkConfig, url: src });

  return Immutable.Map({
    [watermark.get('id')]: watermark,
  });
}

export default { formatWatermarkForConfig };
