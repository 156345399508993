import * as React from 'react';

import { H2, H3 } from 'components/Heading';
import ProSubscriptionHeader from '../../containers/ProSubscription/ProSubscriptionHeader';
import PaidSubscription from '../PaidSubscription';
import KnowledgeBaseButton from '../PaidSubscription/KnowledgeBaseButton';
import HeadlinerSocialButtons from './HeadlinerSocialButtons';
import { block } from './utils';

const ProEducationSubscription: React.SFC = () => (
  <PaidSubscription>
    <ProSubscriptionHeader />
    <PaidSubscription.Body className={block('body')}>
      <H3>You've got Headliner Pro for free!</H3>
      <H2>Enjoy and please share your awesome creations with us:</H2>
      <HeadlinerSocialButtons />
      <KnowledgeBaseButton className={block('knowledge')} />
    </PaidSubscription.Body>
  </PaidSubscription>
);

export default ProEducationSubscription;
