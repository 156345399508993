import { Button, EditorDialog } from '@sparemin/blockhead';
import React from 'react';

import { BoxSizeCheckerSubmitButtonWrapper } from '../../extensions/BoxSizeFitChecker';

import { block } from '../../utils';

export interface TextOverlayFooterProps {
  isDisabled: boolean;
  leftActions?: React.ReactNode | React.ReactNode[];
  onSubmit: () => void;
  submitButtonText: string;
}

const TextOverlayFooter: React.FunctionComponent<TextOverlayFooterProps> = props => {
  const { isDisabled, leftActions, onSubmit, submitButtonText } = props;

  const submitButtonRef = React.useRef<HTMLButtonElement>(null);

  const handleSubmit = React.useCallback((): void => {
    onSubmit();
  }, [onSubmit]);

  return (
    <EditorDialog.ControlPaneFooter className={block('footer-row')}>
      {leftActions}
      <BoxSizeCheckerSubmitButtonWrapper isDisabled={isDisabled}>
        {({ disabled }) => (
          <Button
            ref={submitButtonRef}
            fluid
            isDisabled={disabled}
            onPress={handleSubmit}
          >
            {submitButtonText}
          </Button>
        )}
      </BoxSizeCheckerSubmitButtonWrapper>
    </EditorDialog.ControlPaneFooter>
  );
};

export default TextOverlayFooter;
