import { connect } from 'react-redux';

import * as mixpanelActions from 'redux/modules/mixpanel/actions';
import {
  clearOnboarding,
  getOnboardingAction,
  getOnboardingViewConfirmationAction,
} from 'redux/modules/onboarding/actions';
import { createIsOnboardingViewedSelector } from 'redux/modules/onboarding/selectors';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import getSteps from '../../components/Steps';
import { OnboardingName } from '../../types';
import OnboardingAnimations, {
  OnboardingAnimationsProps,
} from './OnboardingAnimations';

type StateProps = Pick<OnboardingAnimationsProps, 'isViewed' | 'steps'>;

type OwnProps = Pick<OnboardingAnimationsProps, 'name'>;

type DispatchProps = Pick<
  OnboardingAnimationsProps,
  | 'onCancelWalkthrough'
  | 'onClickWalkthroughNext'
  | 'onClickWalkthroughBack'
  | 'onCompleteWalkthrough'
  | 'onMount'
  | 'onStartWalkthrough'
  | 'onUnMount'
>;

const isOnboardingViewedSelector = createIsOnboardingViewedSelector(
  OnboardingName.EDITOR_PAGE,
);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  isViewed: isOnboardingViewedSelector(state),
  steps: getSteps(ownProps.name),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onCancelWalkthrough: (name: OnboardingName, source: string) => {
    dispatch(mixpanelActions.onCancelWalkthrough(name, source));
    dispatch(getOnboardingViewConfirmationAction(name));
  },
  onClickWalkthroughBack: (name: OnboardingName, step: string) =>
    dispatch(mixpanelActions.onClickWalkthroughBack(name, step)),
  onClickWalkthroughNext: (name: OnboardingName, step: string) =>
    dispatch(mixpanelActions.onClickWalkthroughNext(name, step)),
  onCompleteWalkthrough: (name: OnboardingName) => {
    dispatch(mixpanelActions.onCompleteWalkthrough(name));
    dispatch(getOnboardingViewConfirmationAction(name));
  },
  onMount: (name: OnboardingName) => {
    dispatch(clearOnboarding());
    dispatch(getOnboardingAction(name));
  },
  onStartWalkthrough: (name: OnboardingName) =>
    dispatch(mixpanelActions.onStartWalkthrough(name)),
  onUnMount: () => dispatch(clearOnboarding()),
});

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OnboardingAnimations);
type Props = PropsOf<typeof connectedComponent>;

export default connectedComponent;
export { Props as OnboardingAnimationsProps };
