import bem from 'utils/bem';
import { VIEWPORTS } from 'utils/text-templates';

export const block = bem('text-overlay-modal-v2');

/**
 * A default px ratio for the font sizes (width % / 1px font). This values
 * were calculted using the default widths defined for VIEWPORTS object.
 */
export const DEFAULT_FONT_PX_RATIO_MAP = {
  landscape: 1 / VIEWPORTS.landscape.width,
  portrait: 1 / VIEWPORTS.portrait.width,
  square: 1 / VIEWPORTS.square.width,
};

export const DEFAULT_FONT = 'Inter';
export const DEFAULT_HIGHLIGHT_VALUE = 'rgba(0,0,0,0.5)';
export const DEFAULT_SHADOW_VALUE = {
  blur: 5,
  color: 'rgba(0, 0, 0, 1)',
  x: 0,
  y: 0,
};
export const DEFAULT_TEXT = 'Double click to add your text';
export const DEFAULT_SUBMIT_BTN_TEXT = 'add to video';
export const DEFAULT_TITLE = 'text';
export const TEXT_OVERLAY_MODAL_VERSION = 2;
