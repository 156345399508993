import { createMap } from 'types';

import { Type } from './action-types';
import { FetchStatus } from './constants';
import { DisplayPrefAction, DisplayPrefState, IState } from './types';

const defaultState = createMap<IState>({});

export default function(
  state: DisplayPrefState = defaultState,
  action: DisplayPrefAction,
) {
  switch (action.type) {
    case Type.DISPLAY_PREF_GET_REQUEST:
      return state.set('fetchStatus', FetchStatus.FETCHING);

    case Type.DISPLAY_PREF_GET_SUCCESS: {
      const { payload = {} } = action;
      return state.merge(payload).set('fetchStatus', FetchStatus.SUCCESS);
    }

    case Type.DISPLAY_PREF_GET_FAILURE:
      return state.set('fetchStatus', FetchStatus.FAILURE);

    default:
      return state;
  }
}
