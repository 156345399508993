import React, { useCallback } from 'react';
import { contains } from 'underscore';
import FadingScrollBars from 'components/FadingScrollBars';
import List from 'components/List';
import { Season } from 'redux/modules/automation-cms/types';
import { block } from '../utils';
import SeasonListItem from './SeasonListItem';
import { getNormalizedSeasonsList } from './utils';

export interface SeasonsSelectorProps {
  seasons: Season[];
  targetedSeasons: number[];
  totalEpisodes: number;
  onChange: (targetedSeasons: number, isEnabled: boolean) => void;
  onAllEpisodesClick: (isSelected: boolean) => void;
}

const SeasonsSelector: React.FC<SeasonsSelectorProps> = ({
  seasons = [],
  targetedSeasons = [],
  totalEpisodes,
  onChange,
  onAllEpisodesClick,
}) => {
  const renderTrackVertical = useCallback(
    ({ style, ...props }) => (
      <div
        {...props}
        style={{
          ...style,
          top: 18,
          right: 16,
          bottom: 18,
          padding: 0,
          borderRadius: 4,
        }}
      />
    ),
    [],
  );

  const renderThumbVertical = useCallback(
    ({ style, ...props }) => (
      <div
        {...props}
        style={{
          ...style,
          width: 7,
          borderRadius: 'inherit',
          backgroundColor: '#464e5e',
        }}
      />
    ),
    [],
  );

  const createHandleChange = (season: number) => (isEnabled: boolean) => {
    onChange(season, isEnabled);
  };

  return (
    <List className={block('list')}>
      <FadingScrollBars
        className={block('list-scroll-container')}
        hideTracksWhenNotNeeded
        autoHeight
        autoHeightMax={400}
        renderThumbVertical={renderThumbVertical}
        renderTrackVertical={renderTrackVertical}
      >
        <List.Item className={block('list-item')}>
          <SeasonListItem
            isSelected={
              getNormalizedSeasonsList(seasons).length ===
              targetedSeasons?.length
            }
            onChange={onAllEpisodesClick}
            season="All episodes"
            episodeCount={totalEpisodes}
          />
        </List.Item>

        {seasons?.map(
          ({ season, episodeCount }) =>
            season && (
              <List.Item key={season} className={block('list-item')}>
                <SeasonListItem
                  isSelected={contains(targetedSeasons, season)}
                  onChange={createHandleChange(season)}
                  season={`Season ${season}`}
                  episodeCount={episodeCount}
                />
              </List.Item>
            ),
        )}
      </FadingScrollBars>
    </List>
  );
};

export default SeasonsSelector;
