import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import RefreshTemplateListModalContents from './RefreshTemplateListModalContents';
import { block } from './utils';

const RefreshTemplateListModal: React.FC = () => {
  return (
    <ConnectedModal
      name="RefreshTemplateList"
      title="Refresh template list"
      className={block()}
    >
      <RefreshTemplateListModalContents />
    </ConnectedModal>
  );
};

export default RefreshTemplateListModal;
