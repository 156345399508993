import { isEqual } from 'underscore';
import { CreateManualTranscriptResult } from 'redux/middleware/api/transcript-service/types';
import { ThunkAction } from 'redux/types';
import { IEmbedConfig, Size } from 'types';
import { getValue } from 'utils/collections';
import {
  formatCaptionsForConfig,
  formatCaptionsFromConfig,
  switchCaptionsVieport,
} from 'utils/embed/captions';

import {
  copyTranscriptByRevisionId,
  getEmbedConfiguration,
} from '../embed/actions';

interface TranscriptInfo {
  revisionId?: string;
  transcriptId?: string;
}

const getCreateTranscriptResponseInfo = (
  response: CreateManualTranscriptResult,
): TranscriptInfo => {
  const transcriptId = getValue(response, ['response', 'result']);
  const revisionId = getValue(response, [
    'response',
    'entities',
    'manualTranscripts',
    transcriptId,
    'revisionId',
  ]);

  return {
    revisionId,
    transcriptId,
  };
};

/**
 * Copies the transcript for the embed that is going to be copied. It returns the
 * the revision & transcript ID for the copied entity.
 * If the embed has not captions, copy operation is skipped and null is returned.
 */
const copyTranscript = (
  embedConfig: IEmbedConfig,
): ThunkAction<Promise<TranscriptInfo | null>> => async dispatch => {
  const captionsSource =
    getValue(embedConfig, ['captions', 'captionSource']) ?? {};
  const { revisionId, transcriptId } = captionsSource;

  if (!(transcriptId && revisionId)) {
    return null;
  }

  const copiedTranscriptResponse = await dispatch(
    copyTranscriptByRevisionId(transcriptId, revisionId),
  );

  return getCreateTranscriptResponseInfo(copiedTranscriptResponse);
};

/**
 * Preprocesses the transcript config upon a embed copy operation.
 */
export const preprocessEmbedCopyTranscript = (
  embedConfig: IEmbedConfig,
): ThunkAction<Promise<TranscriptInfo>> => async dispatch => {
  // First the transcript is copied for the embed config.
  const copiedTranscript = await dispatch(copyTranscript(embedConfig));

  // If for any reason copy response is empty, the trancript info
  // is returned empty.
  if (!copiedTranscript) {
    return { revisionId: undefined, transcriptId: undefined };
  }

  // Otherwise the copied transcript data is returned.
  return copiedTranscript;
};

/**
 * Preprocesses the embed config upon a embed copy operation.
 */
export const preprocessEmbedCopyEmbedConfig = (
  embedConfigurationId: string,
  targetViewport: Size<number>,
): ThunkAction<Promise<IEmbedConfig>> => async dispatch => {
  // We need to get the embed configuration, copy the manual transcript, and use that updated
  // transcript in order to copy everything properly
  const res = await dispatch(getEmbedConfiguration(embedConfigurationId));

  const { embedConfig } = res.response.configuration;

  // If the embed config does not have captions config, it is returned as it is.
  // Otherwise, the viewport is adjusted and the captions style reformated.
  // Also if the captions don't have a captions config object, it can be assumed that
  // the captions have not been set up, in that case it should not be needed
  // to rescale the captions config.
  if (!embedConfig.captions?.editor?.styleContext) {
    return embedConfig;
  }

  const currentViewport = embedConfig.captions.editor.styleContext.viewport;

  // If the viewport has not changed, there is no need for making any transformation
  if (isEqual(currentViewport, targetViewport)) {
    return embedConfig;
  }

  // Performs the scaling required for the fontsize and adjusts viewport of the
  // captions config.
  embedConfig.captions = switchCaptionsVieport(
    embedConfig.captions,
    targetViewport,
  );

  // Finishes the adjustments by simultaring the embed captions config is loaded
  // and transformed using the same logic used by the editor.
  embedConfig.captions = formatCaptionsForConfig(
    formatCaptionsFromConfig(embedConfig),
  );

  return embedConfig;
};
