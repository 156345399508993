import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  Props as FontAwesomeProps,
} from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { mapIcon } from './utils';

type IProps = FontAwesomeProps & {
  className?: string;
  regular?: boolean;
  onClick?: () => void;
};

const FontAwesome: React.SFC<IProps> = ({ icon, regular, ...props }) => (
  <FontAwesomeIcon icon={mapIcon(icon as string, regular)} {...props} />
);

FontAwesome.defaultProps = {
  regular: false,
};

export default FontAwesome;
export { IconProp };
