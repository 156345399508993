import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A>(method: M, args?: A) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export function getDataByRecordingVersion(
  versionId: number,
): types.GetByVersionIdAction {
  return apiCallAction(
    types.ServiceMethod.GET_AUDIOGRAM_DATA_BY_RECORDING_VERSION,
    [versionId] as types.GetByVersionIdArgs,
  );
}

export function isWaveformResolved(
  versionId: number,
): types.WaveformResolvedAction {
  return apiCallAction(types.ServiceMethod.WAVEFORM_RESOLVED, [
    versionId,
  ] as types.GetByVersionIdArgs);
}
