import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  debouncedSaveConfiguration,
  updateAudio,
} from 'redux/modules/embed/actions';
import { audioByIdSelector } from 'redux/modules/embed/selectors';
import { recordingsSelector } from 'redux/modules/entities';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import AudioAsset, { AudioAssetProps } from '../components/AudioAsset';
import { millisToPosition, positionToMillis } from '../utils';

interface OwnProps extends Pick<AudioAssetProps, 'id'> {
  pxPerSec: number;
}

type StateProps = Pick<
  AudioAssetProps,
  | 'fullDurationWidth'
  | 'minWidth'
  | 'playFromOffset'
  | 'position'
  | 'src'
  | 'width'
>;
type DispatchProps = Pick<
  AudioAssetProps,
  'onDrag' | 'onDragStop' | 'onResize' | 'onResizeStop'
>;

const idSelector = (_, props: OwnProps) => props.id;

const pxPerSecSelector = (_, props: OwnProps) => props.pxPerSec;

const makeMapStateToProps = () => {
  const audioSelector = createSelector(
    [audioByIdSelector, idSelector],
    (audioById, id) => {
      return !audioById || !id ? undefined : audioById.get(id);
    },
  );

  const recordingSelector = createSelector(
    [recordingsSelector, idSelector],
    (recordingsById, id) =>
      !recordingsById || !id ? undefined : recordingsById.get(id.toString()),
  );

  const fullDurationWidthSelector = createSelector(
    [recordingSelector, pxPerSecSelector],
    (recording, pxPerSec) =>
      recording && millisToPosition(recording.get('durationMillis'), pxPerSec),
  );

  const playFromOffsetSelector = createSelector(
    [audioSelector, pxPerSecSelector],
    (audio, pxPerSec) =>
      audio && millisToPosition(audio.get('playFromMillis', 0), pxPerSec),
  );

  const audioTimesSelector = createSelector(
    audioSelector,
    audio =>
      audio && {
        endMillis: audio.get('endMillis'),
        startMillis: audio.get('startMillis'),
      },
  );

  const positionSelector = createSelector(
    [audioTimesSelector, pxPerSecSelector],
    (times, pxPerSec) =>
      !times ? 0 : millisToPosition(times.startMillis, pxPerSec),
  );

  const srcSelector = createSelector(
    recordingSelector,
    recording => recording && recording.get('recordingUrl'),
  );

  const widthSelector = createSelector(
    [audioTimesSelector, pxPerSecSelector],
    (times, pxPerSec) =>
      !times
        ? 0
        : millisToPosition(times.endMillis - times.startMillis, pxPerSec),
  );

  return (state: State, props: OwnProps): StateProps => ({
    fullDurationWidth: fullDurationWidthSelector(state, props),
    minWidth: millisToPosition(
      spareminConfig.minAudioDurationMillis,
      props.pxPerSec,
    ),
    playFromOffset: playFromOffsetSelector(state, props),
    position: positionSelector(state, props),
    src: srcSelector(state, props),
    width: widthSelector(state, props),
  });
};

const makeMapDispatchToProps = () => (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onDrag: data =>
    dispatch(
      updateAudio({
        endMillis: positionToMillis(data.x + data.width, props.pxPerSec),
        id: props.id,
        startMillis: positionToMillis(data.x, props.pxPerSec),
      }),
    ),
  onDragStop: (_, moved) => {
    if (moved) {
      dispatch(debouncedSaveConfiguration());
    }
  },
  onResize: (playFromOffset, width, _, pos) =>
    dispatch(
      updateAudio({
        endMillis: positionToMillis(pos + width, props.pxPerSec),
        id: props.id,
        playFromMillis: positionToMillis(playFromOffset, props.pxPerSec),
        startMillis: positionToMillis(pos, props.pxPerSec),
      }),
    ),
  onResizeStop: () => dispatch(debouncedSaveConfiguration()),
});

const component = connect(
  makeMapStateToProps,
  makeMapDispatchToProps,
)(AudioAsset);

export type AudioClipAssetProps = PropsOf<typeof component>;
export default component;
