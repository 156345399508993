import * as React from 'react';
import { useSelector } from 'react-redux';
import LoadingMessage from 'components/LoadingMessage';
import {
  isLoadingPodcastsSelector,
  searchHandlerIntegrationSelector,
} from 'redux/modules/podcast-search';
import {
  PodcastSearchIntegration,
  PodcastSearchIntegrationsContext,
} from '../types';

const { createContext, useContext, useState } = React;

interface ProviderProps {
  children?: React.ReactNode;
  integrations: PodcastSearchIntegration[];
}

const Context = createContext<PodcastSearchIntegrationsContext>({
  activeIntegration: undefined,
  integrations: [],
  isSearching: false,
  setIsSearching: () => null,
  searchTerm: '',
  setSearchTerm: () => null,
});

export const PodcastSearchIntegrationsContextProvider: React.FC<ProviderProps> = ({
  children,
  integrations = [],
}) => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const isLoading = useSelector(isLoadingPodcastsSelector);
  const activeIntegrationId = useSelector(searchHandlerIntegrationSelector);

  if (isLoading && activeIntegrationId === 'favorite') {
    return <LoadingMessage fill />;
  }

  return (
    <Context.Provider
      value={{
        activeIntegration: integrations.find(i => i.id === activeIntegrationId),
        integrations,
        isSearching,
        setIsSearching,
        searchTerm,
        setSearchTerm,
      }}
    >
      {children}
    </Context.Provider>
  );
};

PodcastSearchIntegrationsContextProvider.displayName =
  'PodcastSearchIntegrationsContextProvider';

export function useIntegrationsContext() {
  return useContext(Context);
}
