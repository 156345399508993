import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SocialPostCaptionInfoPlatform } from 'redux/middleware/api/podcast-service';
import { loggedInSelector } from 'redux/modules/auth';
import {
  canGenerateSocialPostCaptionsSelector,
  socialPostCaptionInfoByPlatformSelector,
} from 'redux/modules/download';
import { isFreeSelector } from 'redux/modules/pricing';
import usePrevious from './usePrevious';

export interface UsePostModalDescriptionConfig {
  platform: SocialPostCaptionInfoPlatform;
  defaultDescription: string;
}

export interface UsePostModalDescriptionResult {
  description: string;
  setDescription: (newDescription: string) => void;
  resetDescription: () => void;
}

export default function usePostModalDescription({
  platform,
  defaultDescription,
}: UsePostModalDescriptionConfig): UsePostModalDescriptionResult {
  const socialPostCaption = useSelector(
    socialPostCaptionInfoByPlatformSelector,
  )?.[platform]?.postText;
  const isFree = useSelector(isFreeSelector);
  const isAuthenticated = useSelector(loggedInSelector);
  const canGenerateSocialPostCaptions = useSelector(
    canGenerateSocialPostCaptionsSelector,
  );
  const prevSocialPostCaption = usePrevious(socialPostCaption);
  const prevIsFree = usePrevious(isFree);
  const shouldUseDefaultDescription =
    isFree ||
    !isAuthenticated ||
    !socialPostCaption ||
    !canGenerateSocialPostCaptions;

  const [description, setDescription] = useState(
    shouldUseDefaultDescription ? defaultDescription : socialPostCaption,
  );

  const resetDescription = useCallback((): void => {
    setDescription(
      shouldUseDefaultDescription ? defaultDescription : socialPostCaption,
    );
  }, [defaultDescription, shouldUseDefaultDescription, socialPostCaption]);

  useEffect(() => {
    if (
      (prevIsFree && !isFree && socialPostCaption) ||
      (!prevSocialPostCaption && socialPostCaption)
    ) {
      setDescription(socialPostCaption);
    }
  }, [
    defaultDescription,
    description,
    isFree,
    prevIsFree,
    prevSocialPostCaption,
    socialPostCaption,
  ]);

  return {
    description,
    setDescription,
    resetDescription,
  };
}
