import * as React from 'react';

export interface WizardPageProps {
  children?: React.ReactNode;
}

const WizardPage: React.SFC<WizardPageProps> = ({ children }) => (
  <div className="wizard-page">{children}</div>
);
export default WizardPage;
