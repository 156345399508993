import * as React from 'react';

import { Step } from '../../types';
import StepItem from '../StepItem';

const ProjectPageSteps: Step[] = [
  {
    content: (
      <StepItem
        content="Welcome to Headliner! Choose a button here to start creating a video."
        videoSrc="https://static.sparemin.com/static/webapp-assets/onboarding/videos/20190201/select-wizard.mp4"
      />
    ),
    name: 'createVideo',
    placement: 'right-start',
    target: '.projects-page__title span',
  },
  {
    content: (
      <StepItem
        content="Your projects will show up here. Click on the project name to open it up."
        videoSrc="https://static.sparemin.com/static/webapp-assets/onboarding/videos/20190201/open-project.mp4"
      />
    ),
    name: 'projectTitle',
    placement: 'top-start',
    target: '.project-history__tile:first-child span',
  },
  {
    content: (
      <StepItem
        content="You can delete or copy a project here. You can also download an exported video."
        videoSrc="https://static.sparemin.com/static/webapp-assets/onboarding/videos/20190201/project-options.mp4"
      />
    ),
    name: 'projectActions',
    target: '.project-history__tile:first-child .project-history__controls',
  },
];

export default ProjectPageSteps;
