import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import PlanCancelledModalContents from './PlanCancelledModalContents';

export interface PlanCancelledModalProps {}

const PlanCancelledModal: React.FC<PlanCancelledModalProps> = () => (
  <ConnectedModal name="PlanCancelled" title="your plan was cancelled">
    <PlanCancelledModalContents />
  </ConnectedModal>
);

export default PlanCancelledModal;
