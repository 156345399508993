import cn from 'classnames';
import * as React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { AssetTileProps } from 'components/AssetTile';
import AssetTileToggle from 'components/AssetTileToggle';
import useTemplateThumbnail from 'hooks/useTemplateThumbnail';
import { podcastSubscriptionsSelector } from 'redux/modules/entities/selectors';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { automationWorkflowTileBlock as block } from '../../utils';
import AutogramInfo from '../AutomationWorkflowTile/AutogramInfo';

type PickedTileProps = Pick<AssetTileProps, 'onClick'>;

const { useMemo } = React;

export interface AutomationWorkflowOptionProps extends PickedTileProps {
  workflowId: number;
  checked: boolean;
  className?: string;
  onToggle: (workflowId: number) => void;
}

const makePodcastSubscriptionSelector = (id: number) =>
  createSelector([podcastSubscriptionsSelector], subs => {
    if (!subs || !id) return undefined;
    return subs.get(`${id}`)?.toJS();
  });

const AutomationWorkflowOption: React.SFC<AutomationWorkflowOptionProps> = props => {
  const { workflowId, checked, onToggle, className } = props;
  const { imageUrl } = useTemplateThumbnail({
    podcastSubscriptionId: workflowId,
  });
  const podcastSubscriptionSelector = useMemo(
    () => makePodcastSubscriptionSelector(workflowId),
    [workflowId],
  );
  const workflow = useSelector(podcastSubscriptionSelector);
  const [item] = workflow?.subscriptionItems ?? [];

  const aspectRatioName = getAspectRatioName(
    workflow?.dimension?.height,
    workflow?.dimension?.width,
  );

  return (
    <AssetTileToggle
      {...{ checked, aspectRatioName }}
      value={workflowId}
      background={{
        src: imageUrl,
        type: 'image',
      }}
      className={cn(className, block())}
      onToggle={() => onToggle(workflowId)}
    >
      <AutogramInfo
        durationSec={item?.videoLengthSecPref}
        clipSelectionMethod={item?.clipSelectionMethod}
      />
    </AssetTileToggle>
  );
};

export default React.memo(AutomationWorkflowOption);
