import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import useRedirectOnClose from 'hooks/useRedirectOnClose';
import { ClickSaveTemplateFrom } from 'redux/modules/mixpanel/types';
import TemplateLengthSelectorModalContents from './TemplateLengthSelectorModalContents';

interface TemplateLengthSelectorModalProps {
  configurationId: string;
  from?: ClickSaveTemplateFrom;
}

const TemplateLengthSelectorModal: React.FC<TemplateLengthSelectorModalProps> = props => {
  const { configurationId, from } = props;
  const { onExited } = useRedirectOnClose();

  return (
    <ConnectedModal name="TemplateLengthSelector" onExited={onExited} v2>
      <TemplateLengthSelectorModalContents
        configurationId={configurationId}
        from={from}
      />
    </ConnectedModal>
  );
};

export default TemplateLengthSelectorModal;
export { TemplateLengthSelectorModalProps };
