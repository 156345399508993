import React from 'react';
import FontAwesome from 'components/FontAwesome';

import Tooltip from 'components/Tooltip';

import { introOutroChildViewBlock as block } from '../utils';

interface IntroOutroLockedOverlayProps {
  children: React.ReactElement;
  id: string;
  locked: boolean;
}

const IntroOutroLockedOverlay: React.FunctionComponent<IntroOutroLockedOverlayProps> = props => {
  const { children, id, locked } = props;

  if (!locked) {
    return children;
  }

  return (
    <div className={block('locked-wrapper')}>
      <div className={block('locked-overlay')} />
      <Tooltip
        content="Your administrator has locked this option"
        id={`${id}-locked-tooltip`}
        placement="top"
      >
        <div className={block('lock-icon-wrapper')}>
          <FontAwesome icon="lock" className={block('lock-icon')} />
        </div>
      </Tooltip>
      {children}
    </div>
  );
};

export default IntroOutroLockedOverlay;
