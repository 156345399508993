import { connect } from 'react-redux';

import { onReviewStepLoaded, onReviewStepShare } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import AutogramReviewDetails, {
  AutogramReviewDetailsProps as Props,
} from './AutogramReviewDetails';

type DispatchProps = Pick<Props, 'onShareClick' | 'onShow'>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onShareClick: () => dispatch(onReviewStepShare()),
  onShow: () => dispatch(onReviewStepLoaded()),
});

const component = connect(null, mapDispatchToProps)(AutogramReviewDetails);

export type AutogramReviewDetailsProps = React.ComponentPropsWithoutRef<
  typeof component
>;

export default component;
