import { createMap } from 'types';
import { Type } from './action-types';
import { TextWizardStatus } from './constants';
import { stateFactory } from './factories';
import { TextWizardAction, TextWizardState } from './types';

const defaultState = stateFactory();

function setStatus(state: TextWizardState, status?: TextWizardStatus) {
  return state.set('status', status);
}

export default function(
  state: TextWizardState = defaultState,
  action: TextWizardAction,
) {
  switch (action.type) {
    case Type.TEXT_WIZARD_SUMMARIZE_REQUEST:
      return setStatus(state, TextWizardStatus.SUMMARIZING);

    case Type.TEXT_WIZARD_SUMMARIZE_SUCCESS: {
      const { urlSummaryId } = action.payload;
      return setStatus(state).set('urlSummaryId', urlSummaryId);
    }

    case Type.TEXT_WIZARD_SUMMARIZE_FAILURE:
      return setStatus(state);

    case Type.TEXT_WIZARD_CREATE_REQUEST: {
      const { aspectRatioName, aspectRatio } = action.payload;
      return defaultState.withMutations(s =>
        s
          .set('urlSummaryId', state.urlSummaryId)
          .set('aspectRatioName', aspectRatioName)
          .set('aspectRatioDimensions', createMap(aspectRatio)),
      );
    }

    case Type.TEXT_WIZARD_TEXT_UPLOAD_REQUEST:
      return setStatus(state, TextWizardStatus.UPLOADING_TEXT);

    case Type.TEXT_WIZARD_TEXT_UPLOAD_SUCCESS: {
      const { textBlobId } = action.payload;
      return state.set('textBlobId', textBlobId);
    }

    case Type.TEXT_WIZARD_TRANSCRIPT_PROCESS_REQUEST:
      return setStatus(state, TextWizardStatus.CREATING_TRANSCRIPT);

    case Type.TEXT_WIZARD_TRANSCRIPT_PROCESS_SUCCESS: {
      const { transcriptId, revisionId } = action.payload;
      return state.withMutations(s =>
        s
          .set('transcriptId', transcriptId)
          .set('transcriptRevisionId', revisionId),
      );
    }

    case Type.TEXT_WIZARD_ANALYZE_REQUEST:
      return setStatus(state, TextWizardStatus.ANALYZING_TEXT);

    case Type.TEXT_WIZARD_CONFIGURATION_CREATE_REQUEST:
      return setStatus(state, TextWizardStatus.CREATING_CONFIGURATION);

    case Type.TEXT_WIZARD_CONFIGURATION_CREATE_SUCCESS: {
      const { widgetId } = action.payload;
      return state.set('widgetId', widgetId);
    }

    case Type.TEXT_WIZARD_PROJECT_CREATE_REQUEST:
      return setStatus(state, TextWizardStatus.CREATING_PROJECT);

    case Type.TEXT_WIZARD_PROJECT_CREATE_SUCCESS: {
      const { projectId } = action.payload;
      return state
        .set('projectId', projectId)
        .set('status', TextWizardStatus.FINISHED);
    }

    case Type.TEXT_WIZARD_CLEAR:
      return defaultState;

    default:
      return state;
  }
}
