import * as React from 'react';
import { CMSEpisode } from 'types/common';

export interface EpisodeListEpisodeRowContextType {
  episode: CMSEpisode;
  projects: string[];
  workflows: number[];
}

export default React.createContext<EpisodeListEpisodeRowContextType>({
  episode: null,
  projects: null,
  workflows: null,
});
