import * as React from 'react';

import Heading from 'components/Heading';
import { EmojiSad } from 'components/icons';
import MailToSupport from 'components/MailToSupport';
import Modal from 'components/Modal';
import useEmailVerificationFailureModalState from './useEmailVerificationFailureModalState';
import { block } from './utils';

const EmailVerificationFailureModalContent: React.FC = () => {
  const {
    loading,
    onChangeEmail,
    onResendEmail,
  } = useEmailVerificationFailureModalState();

  return (
    <>
      <Modal.Body className={block('body')}>
        <div className={block('icon')}>
          <EmojiSad />
        </div>
        <Heading>Something went wrong</Heading>
        <p>
          Sorry! There was an error verifying your email, please try the link
          again. If it still doesn't work, please{' '}
          <MailToSupport subject="Headliner Email Verification Error">
            contact support
          </MailToSupport>
          .
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButton
          fluid
          theme="submit-alt"
          disabled={loading}
          onClick={onChangeEmail}
        >
          Change email address
        </Modal.FooterButton>
        <Modal.FooterButton
          fluid
          theme="submit"
          disabled={loading}
          onClick={onResendEmail}
        >
          Resend verification
        </Modal.FooterButton>
      </Modal.Footer>
    </>
  );
};

export default EmailVerificationFailureModalContent;
