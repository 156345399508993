import { Record, RecordOf } from 'immutable';

import { GetFacebookPagesResult } from 'redux/middleware/api/third-party-authentication-service/types';
import { Action } from 'redux/types';
import { PostStatus } from '../constants';
import { Type } from './action-types';

export interface FacebookPostMessageData {
  tokenInfo: {
    accessToken: string;
    idToken: string;
    provider: 'facebook';
    providerUserId: string;
  };
}

export type FacebookAuthData = Pick<
  FacebookPostMessageData['tokenInfo'],
  'accessToken' | 'idToken' | 'providerUserId'
>;

interface State extends FacebookAuthData {
  pages: GetFacebookPagesResult[];
  facebookUserId: string;
  username: string;
  profileImageUrl: string;
  pagesLoading: boolean;
  accessToken: string;
  postStatus: PostStatus;
  socialShareId: number;
}

export type FacebookState = RecordOf<State>;

export const facebookStateFactory = Record<State>({
  pages: [],
  facebookUserId: undefined,
  username: undefined,
  profileImageUrl: undefined,
  pagesLoading: true,
  accessToken: undefined,
  postStatus: undefined,
  socialShareId: undefined,
  idToken: undefined,
  providerUserId: undefined,
});

export type FacebookAuthorizeSuccessAction = Action<
  Type.FACEBOOK_AUTHORIZE_SUCCESS,
  FacebookAuthData
>;

type GetFacebookUserRequestAction = Action<Type.FACEBOOK_USER_DATA_GET_REQUEST>;

type GetFacebookUserSuccessAction = Action<
  Type.FACEBOOK_USER_DATA_GET_SUCCESS,
  {
    username: string;
    profileImageUrl: string;
  }
>;

type GetFacebookUserFailureAction = Action<Type.FACEBOOK_USER_DATA_GET_FAILURE>;

type SelectFacebookUserIdAction = Action<Type.FACEBOOK_USER_ID_SELECT>;

type GetFacebookPagesRequestAction = Action<Type.FACEBOOK_PAGES_GET_REQUEST>;

type GetFacebookPagesSuccessAction = Action<
  Type.FACEBOOK_PAGES_GET_SUCCESS,
  GetFacebookPagesResult[]
>;

type GetFacebookPagesFailureAction = Action<Type.FACEBOOK_PAGES_GET_FAILURE>;

type ClearFacebookUserAction = Action<Type.FACEBOOK_PAGES_CLEAR>;

type PostVideoRequestAction = Action<Type.FACEBOOK_VIDEO_POST_REQUEST>;

type PostVideoFailureAction = Action<Type.FACEBOOK_VIDEO_POST_FAILURE>;

type PostVideoSuccessAction = Action<
  Type.FACEBOOK_VIDEO_POST_SUCCESS,
  {
    socialShareId: number;
  }
>;

type AwaitPostRequestAction = Action<Type.FACEBOOK_VIDEO_POST_AWAIT_REQUEST>;

type AwaitPostSuccessAction = Action<Type.FACEBOOK_VIDEO_POST_AWAIT_SUCCESS>;

type AwaitPostFailureAction = Action<Type.FACEBOOK_VIDEO_POST_AWAIT_FAILURE>;

export type FacebookAction =
  | GetFacebookUserRequestAction
  | GetFacebookUserSuccessAction
  | GetFacebookUserFailureAction
  | GetFacebookPagesRequestAction
  | GetFacebookPagesSuccessAction
  | GetFacebookPagesFailureAction
  | ClearFacebookUserAction
  | SelectFacebookUserIdAction
  | PostVideoRequestAction
  | PostVideoSuccessAction
  | PostVideoFailureAction
  | FacebookAuthorizeSuccessAction
  | AwaitPostRequestAction
  | AwaitPostSuccessAction
  | AwaitPostFailureAction;
