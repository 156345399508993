import React, { useContext } from 'react';
import { AddBillingCycleModalContextType } from './types';

const AddBillingCycleModalContext = React.createContext<
  AddBillingCycleModalContextType | undefined
>(undefined);

interface AddBillingCycleModalProviderProps
  extends AddBillingCycleModalContextType {
  children?: React.ReactNode;
}

export const AddBillingCycleModalProvider: React.FC<AddBillingCycleModalProviderProps> = ({
  allowTierSelection,
  children,
}) => (
  <AddBillingCycleModalContext.Provider value={{ allowTierSelection }}>
    {children}
  </AddBillingCycleModalContext.Provider>
);

export function useAddBillingCycleModal() {
  const context = useContext(AddBillingCycleModalContext);

  if (context === undefined) {
    throw new Error(
      'useAddBillingCycle must be used within AddBillingCycleModalProivder',
    );
  }

  return context;
}
