import * as React from 'react';

import { noop } from 'underscore';

import { HeadlinerUsage } from 'types';
import { cohortQuestions } from 'utils/user-survey';
import AuthFormSelect from './AuthFormSelect';

export interface UserRoleFieldProps {
  disabled?: boolean;
  onChange?: (value: HeadlinerUsage) => void;
  value: string;
}

const UserRoleField: React.SFC<UserRoleFieldProps> = ({
  disabled,
  onChange,
  value,
}) => (
  <AuthFormSelect
    label="Tell us what you do"
    onChange={onChange}
    value={value}
    disabled={disabled}
  >
    <option value="" />
    {cohortQuestions.map(({ id, displayName }) => (
      <option value={id} key={id}>
        {displayName}
      </option>
    ))}
  </AuthFormSelect>
);

UserRoleField.defaultProps = {
  disabled: false,
  onChange: noop,
  value: '',
};

export default UserRoleField;
