import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import Tooltip from 'components/Tooltip';
import { Omit, PropsOf } from 'types';
import { block } from './utils';

export interface TileComponentProps {
  className?: string;
  disabled?: boolean;
  forwardedRef?: React.Ref<HTMLDivElement>;
  icon: JSX.Element;
  id?: string | number;
  onClick?: (e: React.MouseEvent<HTMLElement>, params: any) => void;
  onHover?: (e: React.MouseEvent<HTMLElement>, params: any) => void;
  label: string;
  params?: any;
  selected?: boolean;
  tooltip?: string;
}

export class TileComponent extends React.Component<TileComponentProps> {
  public static defaultProps = {
    onClick: noop,
    onHover: noop,
    selected: false,
  };

  private handleMouseEnter = (e: React.MouseEvent<any>) => {
    const { params, onHover } = this.props;
    onHover(e, params);
  };

  private handleClick = (e: React.MouseEvent<any>) => {
    const { params, onClick } = this.props;
    onClick(e, params);
  };

  public render() {
    const {
      className,
      disabled,
      forwardedRef,
      icon,
      id,
      label,
      selected,
      tooltip,
    } = this.props;

    return (
      <div
        className={cn(block('tile', { disabled, off: !selected }), className)}
        onClick={disabled ? undefined : this.handleClick}
        onMouseEnter={disabled ? undefined : this.handleMouseEnter}
        ref={forwardedRef}
      >
        <div className={block('icon-container')}>
          {React.cloneElement(icon, {
            className: cn(icon.props.className, block('icon')),
          })}
        </div>
        <div className={block('label')}>{label}</div>
        {tooltip && (
          <Tooltip
            className={block('tooltip')}
            content={tooltip}
            id={`${id}-tooltip`}
            placement="top"
          >
            <div className={block('tile-overlay')} />
          </Tooltip>
        )}
      </div>
    );
  }
}

const component = React.forwardRef<HTMLDivElement, TileComponentProps>(
  (props, ref) => <TileComponent {...props} forwardedRef={ref} />,
);
component.displayName = `ForwardRef(${TileComponent.name})`;

export type TileProps = Omit<PropsOf<typeof component>, 'forwardedRef'>;
export type Tile = React.ExoticComponent<TileProps>;
export const Tile = component as Tile;
export default Tile;
