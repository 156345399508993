import * as React from 'react';

import { IconProps } from './types';

const HappyFace: React.SFC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
    <path
      fill="#19B5FE"
      d="M50 0C22.379 0 0 22.379 0 50s22.379 50 50 50 50-22.379 50-50S77.621 0 50 0zm0 90.323C27.762 90.323 9.677 72.238 9.677 50S27.762 9.677 50 9.677 90.323 27.762 90.323 50 72.238 90.323 50 90.323zM33.871 46.774a6.445 6.445 0 006.452-6.451 6.445 6.445 0 00-6.452-6.452 6.445 6.445 0 00-6.452 6.452 6.445 6.445 0 006.452 6.451zm32.258 0a6.445 6.445 0 006.452-6.451 6.445 6.445 0 00-6.452-6.452 6.445 6.445 0 00-6.452 6.452 6.445 6.445 0 006.452 6.451zm.806 14.637A22.002 22.002 0 0150 69.355a21.937 21.937 0 01-16.935-7.944 4.853 4.853 0 00-6.815-.625c-2.056 1.714-2.319 4.758-.625 6.815A31.648 31.648 0 0050 79.012a31.648 31.648 0 0024.375-11.411 4.833 4.833 0 00-.625-6.815 4.853 4.853 0 00-6.815.625z"
    />
  </svg>
);

export default HappyFace;
