import classNames from 'classnames';
import * as React from 'react';
import { DropdownProps } from 'react-bootstrap';
import { RootCloseWrapper } from 'react-overlays';
import _ from 'underscore';

import { blockNavigation } from './utils';

// props passed via Dropdown to handle root close. separate interface for clarity
interface IRootCloseProps {
  event?: 'click' | 'mousedown';
  onClose?: (
    event: React.MouseEvent<any>,
    opts: { source: 'rootClose' },
  ) => void;
}

interface IProps
  extends IRootCloseProps,
    Pick<DropdownProps, 'open' | 'onSelect'> {
  /*
   * NOTE: bsRole isn't used explicitly by this component, but the component needs to accept a
   *  bsRole prop so that the Dropdown component knows how to handle it
   */
  bsRole?: string;
  children?: React.ReactNode;
  header?: React.ReactNode;
}

export default class DropdownMenu extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    header: null,
    onClose: _.noop,
  };

  public focusNext = _.noop;

  private handleRootClose = (event: React.MouseEvent<any>) => {
    const { onClose } = this.props;
    onClose(event, { source: 'rootClose' });
  };

  public render() {
    const { children, event, header, onSelect, open } = this.props;

    return (
      <RootCloseWrapper
        disabled={!open}
        onRootClose={this.handleRootClose}
        event={event}
      >
        <div className={classNames('dropdown-menu', blockNavigation('menu'))}>
          {header}

          <ul className={blockNavigation('menu-links')}>
            {React.Children.map(children, (child: React.ReactElement<any>) =>
              React.cloneElement(child, { onSelect }),
            )}
          </ul>
        </div>
      </RootCloseWrapper>
    );
  }
}

export { IProps as DropdownMenuProps };
