import { useSelector } from 'react-redux';
import {
  authInProgressSelector,
  userNameSelector,
} from 'redux/modules/auth/selectors';
import useEmailVerificationFailureModalDispatch from './useEmailVerificationFailureModalDispatch';

export default () => {
  const email = useSelector(userNameSelector);
  const loading = useSelector(authInProgressSelector);
  const {
    onChangeEmail,
    onResendEmail,
  } = useEmailVerificationFailureModalDispatch();

  return {
    email,
    loading,
    onChangeEmail,
    onResendEmail: () => onResendEmail(email),
  };
};
