import * as actions from './actions';
import { handle } from './service';
import { ACTION_KEY, ServiceMethod } from './types';

export * from './types';
export { actions };

export default {
  handle,
  ActionKey: ACTION_KEY,
  Method: ServiceMethod,
};
