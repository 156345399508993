import * as React from 'react';

import { IconProps } from './types';

const ProgressBar: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 110 40"
    {...props}
  >
    <defs>
      <path
        id="progress-bar-a"
        d="M3.52 0h102.96c1.944 0 3.52 1.433 3.52 3.2v33.6c0 1.767-1.576 3.2-3.52 3.2H3.52C1.576 40 0 38.567 0 36.8V3.2C0 1.433 1.576 0 3.52 0z"
      />
      <path
        id="progress-bar-c"
        d="M4 2.25h28.84a4 4 0 0 1 2.832 1.176l64.528 64.75A4 4 0 0 1 97.367 75H4a4 4 0 0 1-4-4V6.25a4 4 0 0 1 4-4z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="progress-bar-b" fill="#ffffff">
        <use xlinkHref="#progress-bar-a" />
      </mask>
      <g stroke="currentColor" fill="currentColor" mask="url(#progress-bar-b)">
        <path
          fill="currentColor"
          strokeLinecap="square"
          strokeWidth="4"
          d="M32.5-13l75 75M12.5-13l75 75M52.5-13l75 75M72.5-13l75 75M92.5-13l75 75"
        />
        <path
          fill="currentColor"
          d="M-33-10.25a3.5 3.5 0 0 0-3.5 3.5V58a3.5 3.5 0 0 0 3.5 3.5h93.367a3.5 3.5 0 0 0 2.479-5.97L-1.682-9.22a3.5 3.5 0 0 0-2.479-1.03H-33z"
        />
      </g>
    </g>
  </svg>
);

export default ProgressBar;
