import { Link, Metrics } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { onClickHomepageAnalyticsTab } from 'redux/modules/mixpanel';
import { MAKE_PAGE_DASHBOARD_URL } from 'utils/constants';
import { tabsBlock as block } from '../../utils';
import ProjectsTabTitle from './ProjectsTabTitle';

type AnalyticsTabTitleProps = {};

const AnalyticsTabTitle: React.FC<AnalyticsTabTitleProps> = () => {
  const dispatch = useDispatch();

  const handleAnalyticsTabClick = useCallback(() => {
    dispatch(onClickHomepageAnalyticsTab());
  }, [dispatch]);

  return (
    <Link
      href={MAKE_PAGE_DASHBOARD_URL}
      target="__blank"
      rel="noopener noreferrer"
      className={block('title-contents')}
      onClick={handleAnalyticsTabClick}
    >
      <ProjectsTabTitle
        icon={<Metrics width={15} height={15} style={{ marginTop: 2 }} />}
        label="ANALYTICS"
      />
    </Link>
  );
};
export default AnalyticsTabTitle;
