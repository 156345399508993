import React, { forwardRef, useCallback } from 'react';

import { ProFeatureTooltipContent } from 'blocks/Pricing';
import { Watermark } from 'components/icons';
import Tooltip from 'components/Tooltip';
import { useProIndicator } from 'containers/ProIndicator';
import { RefType } from 'types';

import { useEditorState } from '../context/VideoEditorStateContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { hasWatermark } from '../state';
import { OPTION_TILE_FEATURE_PATHS } from '../state/constants';
import useAddWatermark from '../useAddWatermark';

import useFeature from '../useFeature';
import AccessControlOptionTile, {
  AccessControlOptionTileProps,
} from './AccessControlOptionTile';

type PickedTileProps<P = any> = Pick<
  AccessControlOptionTileProps<P>,
  'onClick' | 'params'
>;

export interface WatermarkTileProps<P = any> extends PickedTileProps<P> {
  className?: string;
}

const WatermarkTile = forwardRef(
  <P extends {} = any>(
    { className, onClick, params }: WatermarkTileProps<P>,
    ref: RefType<typeof AccessControlOptionTile>,
  ) => {
    const { disabled, isLoading } = useEditorState();
    const { isFree } = useProIndicator();
    const state = useTemplateState();
    const onAddWatermark = useAddWatermark();
    const canOpenChildView = hasWatermark(state);
    const featureState = useFeature(['watermark']);

    const isDisabled = disabled || isLoading;

    const handleClick: AccessControlOptionTileProps['onClick'] = useCallback(
      (p, e): void => {
        if (canOpenChildView) onClick(p, e);
        else onAddWatermark();
      },
      [canOpenChildView, onClick, onAddWatermark],
    );

    return (
      <Tooltip
        animation
        content={ProFeatureTooltipContent}
        id="watermark-tile-tooltip"
        placement="top"
        show={isFree ? undefined : false}
        preventHideOnHover
      >
        <div>
          <AccessControlOptionTile
            className={className}
            disabled={isDisabled}
            featurePath={OPTION_TILE_FEATURE_PATHS.watermark}
            icon={<Watermark width={54} />}
            id="template-watermark-tile"
            label="watermark"
            onClick={handleClick}
            showProLabel={isFree}
            params={params}
            type={featureState === 'locked' ? featureState : 'default'}
            ref={ref}
          />
        </div>
      </Tooltip>
    );
  },
);

export default WatermarkTile;
