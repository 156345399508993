import { List } from 'immutable';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { pushEditTextModal } from 'redux/modules/embed/actions/text-overlay-ts';
import { selectTrackElement } from 'redux/modules/embed/actions/tracks';
import { tracksByIdSelector } from 'redux/modules/embed/selectors';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';

import TextTrack, { TextTrackProps as Props } from './TextTrack';

type StateProps = Pick<Props, 'assetIds'>;
type DispatchProps = Pick<Props, 'onAssetClick'>;
type OwnProps = Pick<Props, 'id'>;

const trackIdSelector = (_, props: OwnProps) => props.id;

const makeMapStateToProps = () => {
  const assetIdsSelector = createSelector(
    [trackIdSelector, tracksByIdSelector],
    (trackId, tracks) => tracks.getIn([trackId, 'data'], List()).toJS(),
  );

  return (state: State, props: OwnProps): StateProps => ({
    assetIds: assetIdsSelector(state, props),
  });
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onAssetClick: assetId => {
    dispatch(selectTrackElement(assetId, props.id));
    dispatch(pushEditTextModal());
  },
});

const component = connect(makeMapStateToProps, mapDispatchToProps)(TextTrack);

export default component;
export type TextTrackProps = PropsOf<typeof component>;
