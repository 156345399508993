import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import ProIndicatorButton, {
  ProIndicatorButtonProps,
} from 'components/ProIndicatorButton';
import { createChainedFunction } from 'utils/functions';
import SelectedFile from './SelectedFile';
import UploadInput, { FileRejectedError } from './UploadInput';
import { block } from './utils';

const { useCallback, useRef } = React;

const ProIndicatorButtonAsAny = ProIndicatorButton as any;
type PickedButtonProps = Pick<
  ProIndicatorButtonProps,
  'disabled' | 'onClick' | 'pro'
>;

export interface UploadButtonProps extends PickedButtonProps {
  accept?: string;
  children?: React.ReactNode;
  className?: string;
  maxSizeMb?: number;

  // TODO can these callbacks maybe be combined into an "onChange" or something?
  onClear?: () => void;
  onFileAccepted?: (file: File) => void;
  onFileRejected?: (error: FileRejectedError) => void;
  onUpgradePlan: () => void;
  tooltipId?: string;
  value?: string;
}

const UploadButton: React.SFC<UploadButtonProps> = ({
  accept,
  children,
  className,
  disabled,
  maxSizeMb,
  onClear,
  onClick,
  onFileAccepted,
  onFileRejected,
  onUpgradePlan,
  pro,
  tooltipId,
  value,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const resetInput = () => {
    if (inputRef.current) {
      inputRef.current.value = null;
    }
  };

  const handleClick = useCallback(() => {
    inputRef.current.click();
  }, []);

  const hasValue = !!value;

  /*
   * these will get passed through from ProIndicatorButton to the underlying
   * component, however if the user has not selected a file, the underlying
   * component is a Button, which doesn't accept these props
   */
  const selectedFileProps = !hasValue
    ? {}
    : {
        tooltipId,
        fileName: value,
        onCancelClick: createChainedFunction(onClear, resetInput),
      };

  return (
    <>
      <ProIndicatorButtonAsAny
        className={cn(block(), className)}
        componentClass={!hasValue ? undefined : SelectedFile}
        disabled={disabled}
        onClick={
          hasValue ? undefined : createChainedFunction(onClick, handleClick)
        }
        onUpgrade={onUpgradePlan}
        pro={pro}
        size="small"
        theme="input"
        {...selectedFileProps}
      >
        {children}
      </ProIndicatorButtonAsAny>
      <UploadInput
        accept={accept}
        onFileAccepted={onFileAccepted}
        onFileRejected={onFileRejected}
        ref={inputRef}
        maxSizeMb={maxSizeMb}
      />
    </>
  );
};

UploadButton.defaultProps = {
  accept: '*',
  disabled: false,
  maxSizeMb: Infinity,
  onClear: noop,
  onClick: noop,
  onFileAccepted: noop,
  onFileRejected: noop,
  pro: false,
};

export default UploadButton;
