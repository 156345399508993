import cn from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';

import Banner from 'components/Banner';
import Button from 'components/Button';
import FontAwesome from 'components/FontAwesome';
import { onClickFeedCollectBanner } from 'redux/modules/mixpanel/actions';
import { pushModal, replaceModal } from 'redux/modules/modal/actions';
import { Dispatch } from 'redux/types';
import bem from 'utils/bem';

interface Props {
  className?: string;
}

const block = bem('feed-collect-banner');

function FeedCollectBanner(props: Props) {
  const { className } = props;
  const dispatch = useDispatch<Dispatch>();

  const handleConnectClick = async () => {
    dispatch(onClickFeedCollectBanner());

    const podcast = await dispatch(
      pushModal({
        name: 'PodcastConnect',
      }),
    );

    dispatch(
      replaceModal({
        name: 'PodcastConnected',
        params: {
          podcastId: podcast.podcastId,
          title: podcast.title,
        },
      }),
    );
  };

  return (
    <Banner
      theme="primary"
      icon={
        <FontAwesome className={block('icon')} icon="microphone" size="3x" />
      }
      className={cn(className, block())}
    >
      <div>
        <Banner.Title>Don’t waste time uploading audio!</Banner.Title>
        <Banner.Content>
          We’ll automatically add each episode of a podcast into Headliner.
        </Banner.Content>
      </div>
      <Banner.Actions>
        <Button theme="submit" onClick={handleConnectClick}>
          connect a podcast
        </Button>
      </Banner.Actions>
    </Banner>
  );
}

export default FeedCollectBanner;
