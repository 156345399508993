import cn from 'classnames';
import * as React from 'react';
import MaxLengthTextarea, {
  MaxLengthTextareaProps,
} from 'components/MaxLengthTextarea';
import FormField, { FormFieldProps } from './FormField';
import { block } from './utils';

export type MaxLengthTextareaFieldProps = FormFieldProps<
  MaxLengthTextareaProps
>;

const MaxLengthTextareaField: React.FC<MaxLengthTextareaFieldProps> = ({
  className,
  ...props
}) => (
  <FormField<MaxLengthTextareaProps>
    {...props}
    light
    className={cn(block('max-length-textarea-field'), className)}
  >
    <MaxLengthTextarea />
  </FormField>
);

export default MaxLengthTextareaField;
