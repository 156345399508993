import * as Immutable from 'immutable';
import { createSelector } from 'reselect';
import { QuestionId } from 'redux/middleware/api/headliner-user-service';

import { PodcastSubscription } from 'redux/middleware/api/podcast-service/types';
import { EmailVerificationStatus } from 'redux/middleware/api/user-service/types';
import { userIdSelector } from 'redux/modules/auth';
import { workflowIdsSelector } from 'redux/modules/automation-cms/selectors';
import {
  podcastSubscriptionsSelector,
  userProfilesSelector,
} from 'redux/modules/entities';
import { favoritePodcastIdsSelector } from 'redux/modules/podcast-search';
import { pricingDataLoadedSelector } from 'redux/modules/pricing';
import { surveyDataSelector } from 'redux/modules/survey';

// favorites loading state is complex as it is affected by podcast search actions. the best way
// to check if data has been loaded at least once is to check if the ids state has been already
// set with an array despite of its length
export const hasLoadedFavoritesSelector = createSelector(
  favoritePodcastIdsSelector,
  ids => {
    return Array.isArray(ids);
  },
);

// in the same way, for checking if workflows have been loaded, application relies on the data
// for workflows to have been populated. It was tested to add a loading status, but it ended up
// adding a blink when switching tabs as it was getting into request state which made the banner
// dissapear
export const hasLoadedWorkflowsSelector = createSelector(
  workflowIdsSelector,
  ids => {
    return Array.isArray(ids);
  },
);

export const hasLoadedUseAsPodcastSelector = createSelector(
  surveyDataSelector,
  surveyData => surveyData && surveyData[QuestionId.PODCAST] !== undefined,
);

// a general check for determining if all the required data has been already loaded. any new data
// load check that is required should be added to this selector
export const hasLoadedAllDataSelector = createSelector(
  [
    pricingDataLoadedSelector,
    hasLoadedFavoritesSelector,
    hasLoadedWorkflowsSelector,
    hasLoadedUseAsPodcastSelector,
  ],
  (
    hasLoadedPricingData,
    hasLoadedFavorites,
    hasLoadedWorkflows,
    hasLoadedUseAsPodcast,
  ) =>
    hasLoadedPricingData &&
    hasLoadedFavorites &&
    hasLoadedWorkflows &&
    hasLoadedUseAsPodcast,
);

// check if the user has at leas one subscription with autoposting enabled
export const hasOneAutoPostingSelector = createSelector(
  podcastSubscriptionsSelector,
  (
    subscriptionsMap: Immutable.Map<
      string,
      PodcastSubscription
    > = Immutable.Map({}),
  ) => {
    if (subscriptionsMap.size === 0) {
      return false;
    }

    const subscriptions: PodcastSubscription[] = Object.values(
      subscriptionsMap.toJS(),
    );

    return subscriptions.some(({ subscriptionItems = [] }) =>
      subscriptionItems.some(
        ({ autoPostVideoPreference }) => !!autoPostVideoPreference?.platform,
      ),
    );
  },
);

// checks if the user has at least one active workflow
export const hasActiveWorkflowsSelector = createSelector(
  workflowIdsSelector,
  ids => {
    return !!ids?.length;
  },
);

// checks if the user has already verified his email address
export const hasVerifiedEmailSelector = createSelector(
  [userIdSelector, userProfilesSelector],
  (userId, profiles) => {
    return (
      profiles?.getIn([
        String(userId),
        'emailVerification',
        'emailVerificationStatus',
      ]) === EmailVerificationStatus.VERIFIED
    );
  },
);
