import qs from 'query-string';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import useOnMount from 'hooks/useOnMount';
import { checkWorkflowAuthError } from 'redux/modules/automation-cms';
import { pushModal } from 'redux/modules/modal';
import { showError } from 'redux/modules/notification';
import { int } from 'utils/numbers';
import { removeQueryParamFromURL } from 'utils/routes';

interface UseYoutubePermissionRevokedConfig {
  onSetActivePodcast: (podcastId: string) => void;
  podcastIds: string[];
}

// This hook listens the url query params for the projects page and determines
// if it is necessary to load the YT permissions revoked modal
const useYoutubePermissionRevokedListener = (
  config: UseYoutubePermissionRevokedConfig,
): void => {
  const { onSetActivePodcast, podcastIds } = config;

  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  const handleValidationError = React.useCallback(() => {
    // If the params are set but the validations fail an error message is shown
    dispatch(
      showError({
        message:
          'Podcast subscription does not exist or has no revoked permissions issues',
        code: 'IN020',
        dismissAfterSec: 5,
      }),
    );
  }, [dispatch]);

  useOnMount(async () => {
    const { reAuthAutoCreateSubId, reAuthPodcastId } = qs.parse(
      location.search,
    );

    // If any of the params is not defined, it can be assumed it is just a regular app nav url
    // and do not perform any action
    if (!(reAuthAutoCreateSubId || reAuthPodcastId)) {
      return;
    }

    // Checks the query params integraty and that they are used for a valid podcast
    if (
      !(
        typeof reAuthPodcastId === 'string' &&
        typeof reAuthAutoCreateSubId === 'string' &&
        podcastIds?.includes(reAuthPodcastId)
      )
    ) {
      handleValidationError();
      return;
    }

    try {
      // Validates the subscription is valid and that it is in a "auth error" state
      await dispatch(checkWorkflowAuthError(reAuthAutoCreateSubId));

      // Set the podcast tab and activates the modal
      onSetActivePodcast(reAuthPodcastId);
      dispatch(
        pushModal({
          name: 'YoutubePermissionsRevoked',
          params: { reAuthAutoCreateSubId: int(reAuthAutoCreateSubId) },
        }),
      );

      // Remove the involved query params and clean the url
      const updatedSearch = removeQueryParamFromURL(
        location.search,
        'reAuthAutoCreateSubId',
        'reAuthPodcastId',
      );
      history.replace({ search: updatedSearch });
    } catch {
      handleValidationError();
    }
  });
};

export default useYoutubePermissionRevokedListener;
