import cn from 'classnames';
import * as React from 'react';

import { DurationField, DurationFieldProps } from 'components/Form';
import { block } from './utils';

const ClippingOptionsField: React.SFC<DurationFieldProps> = ({
  className,
  ...props
}) => <DurationField className={cn(block('field'), className)} {...props} />;

export default ClippingOptionsField;
export { DurationFieldProps as ClippingOptionsFieldProps };
