import * as React from 'react';

import { IconProps } from './types';

interface Props extends IconProps {
  size?: number;
}

const Plus: React.FC<Props> = props => {
  const { size = 2, ...rest } = props;
  const height = 50;
  const x = height / 2 - size / 2;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      {...rest}
    >
      <g fill="currentColor" fillRule="evenodd">
        <rect width={size} height={height} x={x} rx="1.88" />
        <rect
          width={size}
          height={height}
          x={x}
          rx="1.88"
          transform="rotate(90 25 25)"
        />
      </g>
    </svg>
  );
};

export default Plus;
