import { deleteEntities } from 'redux/modules/entities';
import {
  getAnnouncements,
  getViewConfirmation,
} from '../../middleware/api/headliner-user-service';
import * as types from './types';

export const getMyAnnouncements = () => dispatch => {
  dispatch({
    type: types.ANNOUNCEMENTS_GET_REQUEST,
  });

  dispatch(getAnnouncements())
    .then(() =>
      dispatch({
        type: types.ANNOUNCEMENTS_GET_SUCCESS,
      }),
    )
    .catch(err => {
      dispatch({
        ...err,
        type: types.ANNOUNCEMENTS_GET_FAILURE,
      });
      throw err;
    });
};

export const sendViewConfirmation = announcementId => dispatch => {
  dispatch({
    type: types.VIEW_CONFIRMATION_GET_REQUEST,
  });
  dispatch(getViewConfirmation(announcementId))
    .then(() =>
      dispatch({
        type: types.VIEW_CONFIRMATION_GET_SUCCESS,
      }),
    )
    .catch(err => {
      dispatch({
        ...err,
        type: types.VIEW_CONFIRMATION_GET_FAILURE,
      });
      throw err;
    });
};

export const clearAnnouncements = () => dispatch =>
  dispatch(deleteEntities(['announcements']));

export default { clearAnnouncements, getMyAnnouncements, sendViewConfirmation };
