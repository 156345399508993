import { connect } from 'react-redux';

import { embedExportWidgetIdSelector } from 'redux/modules/embed-export/selectors';
import { State } from 'redux/types';
import { VideoExportLinkProps } from './VideoExportLink';

type StateProps = Pick<VideoExportLinkProps, 'widgetId'>;

const mapStateToProps = (state: State): StateProps => ({
  widgetId: embedExportWidgetIdSelector(state),
});

export default function(component: React.ComponentType<VideoExportLinkProps>) {
  return connect(mapStateToProps)(component);
}
