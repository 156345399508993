import React from 'react';
import { X } from 'components/icons';
import SocialCircle from './SocialCircle';

export interface XSocialCircleProps {
  className?: string;
}

const XSocialCircle: React.FC<XSocialCircleProps> = ({ className }) => (
  <SocialCircle className={className}>
    <X />
  </SocialCircle>
);

export default XSocialCircle;
