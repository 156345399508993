import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PodcastSubscription } from 'redux/middleware/api/podcast-service';

import { userNameSelector } from 'redux/modules/auth';
import { editAutomationInitOutput } from 'redux/modules/edit-automation';
import { replaceModal } from 'redux/modules/modal';
import { getSubscriptionItem } from '../utils';

import EditAutomationOutput from './EditAutomationOutput';

import useGetUserInfo from './useGetUserInfo';
import { getOutputOption } from './utils';

interface EditAutomationOutputContainerProps {
  subscription: PodcastSubscription;
}

const EditAutomationOutputContainer: FunctionComponent<EditAutomationOutputContainerProps> = props => {
  const { subscription } = props;

  const dispatch = useDispatch();

  const subscriptionItem = getSubscriptionItem(subscription);
  const email = useSelector(userNameSelector);
  const outputOption = getOutputOption(getSubscriptionItem(subscription));
  const { displayName } = useGetUserInfo(subscriptionItem);

  const handleEdit = useCallback(() => {
    dispatch(editAutomationInitOutput(outputOption));
    dispatch(
      replaceModal({
        name: 'EditAutomationOutputModal',
        params: {
          subscriptionId: subscription?.autoCreateSubscriptionId,
          videoType: subscriptionItem?.videoType,
          platform: subscriptionItem?.autoPostVideoPreference?.platform,
        },
      }),
    );
  }, [dispatch, outputOption, subscription, subscriptionItem]);

  return (
    <EditAutomationOutput
      editable
      email={email}
      subscriptionItem={subscriptionItem}
      onEdit={handleEdit}
      outputOption={outputOption}
      description={displayName}
    />
  );
};

export default EditAutomationOutputContainer;
