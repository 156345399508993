import queryString from 'query-string';
import { connect } from 'react-redux';
import { RouteChildrenProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { createSelector } from 'reselect';

import {
  embedLoadingSelector,
  isEditorReadySelector,
} from 'redux/modules/embed/selectors';
import { updateEditorUrl } from 'redux/modules/router';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import TemplateInstructionOverlay, {
  TemplateInstructionOverlayProps as Props,
} from './TemplateInstructionOverlay';

type StateProps = Pick<Props, 'show'>;
type DispatchProps = Pick<Props, 'onExited'>;
type OwnProps = RouteChildrenProps;

const isTemplateSelector = (_, props: OwnProps) => {
  const query = queryString.parse(props.location.search);
  return query.projectType === 'template';
};

const showSelector = createSelector(
  [isTemplateSelector, embedLoadingSelector, isEditorReadySelector],
  (isTemplate, embedLoading, editorReady) =>
    isTemplate && !embedLoading && editorReady,
);

const mapStateToProps = (state: State, props: OwnProps): StateProps => ({
  show: showSelector(state, props),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onExited: () => dispatch(updateEditorUrl(undefined, true)),
});

const component = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(TemplateInstructionOverlay);
export type TemplateInstructionOverlayProps = PropsOf<typeof component>;
export default component;
