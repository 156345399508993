import cn from 'classnames';
import * as React from 'react';
import ReactBootstrapSlider from 'react-bootstrap-slider';
import { isEqual, noop } from 'underscore';

import FontAwesome from 'components/FontAwesome';

type SliderCallback = (value: number) => void;

export interface TimelineZoomSliderProps {
  className?: string;
  minusClassName?: string;
  onChange?: SliderCallback;
  onSlideStop?: SliderCallback;
  onZoomInClick?: () => void;
  onZoomOutClick?: () => void;
  plusClassName?: string;
  sliderClassName?: string;
  value?: number;
}

/**
 * A wrapper around react-bootstrap-slider with plus and minus buttons.
 *
 * This component exits mostly to control the render cycle of ReactBootstrapSlider.  Any time the
 * slider gets new props, a layout recalculation is triggered.  This causes reflow and can lead to
 * performance issues.
 *
 * This component uses shouldComponentUpdate to control when ReactBootstrapSlider gets new props and
 * thereby when it does its expensive layout calculations.
 */
export default class TimelineZoomSlider extends React.Component<
  TimelineZoomSliderProps
> {
  public static defaultProps: Partial<TimelineZoomSliderProps> = {
    onChange: noop,
    onSlideStop: noop,
    onZoomInClick: noop,
    onZoomOutClick: noop,
  };

  public shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  private handleZoomOutClick = () => {
    const { onZoomOutClick } = this.props;
    onZoomOutClick();
  };

  private handleZoomInClick = () => {
    const { onZoomInClick } = this.props;
    onZoomInClick();
  };

  private handleSlideStop = e => {
    const { onSlideStop } = this.props;
    onSlideStop(e.target.value);
  };

  private handleChange = e => this.props.onChange(e.target.value);

  public render() {
    const {
      className,
      minusClassName,
      plusClassName,
      sliderClassName,
      value,
    } = this.props;

    const containerClassName = cn(
      'zoom-slider',
      'zoom-slider--default',
      className,
    );

    const minusButtonClassName = cn('zoom-slider__button', minusClassName);

    const plusButtonClassName = cn('zoom-slider__button', plusClassName);

    return (
      <div className={containerClassName}>
        <FontAwesome
          icon="search-minus"
          className={minusButtonClassName}
          onClick={this.handleZoomOutClick}
        />
        <ReactBootstrapSlider
          className={sliderClassName}
          value={value}
          step={1}
          max={100}
          min={1}
          slideStop={this.handleSlideStop}
          change={this.handleChange}
        />
        <FontAwesome
          icon="search-plus"
          className={plusButtonClassName}
          onClick={this.handleZoomInClick}
        />
      </div>
    );
  }
}
