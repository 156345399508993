import cn from 'classnames';
import * as React from 'react';

import { block } from '../utils';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  contentsClassName?: string;
  cta?: React.ReactNode;
  title?: string;
}

const ProjectsPageSection: React.SFC<IProps> = ({
  children,
  className,
  contentsClassName,
  cta,
  title,
}) => (
  <div className={cn(block('section'), className)}>
    <div className={cn(block('contents'), contentsClassName)}>
      {title && (
        <div className={block('title')}>
          <span>{title}</span>
          {cta}
        </div>
      )}
      <div className={block('body')}>{children}</div>
    </div>
  </div>
);

export default ProjectsPageSection;
export { IProps as ProjectsPageSectionProps };
