import * as React from 'react';
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history3/redirect';
import _ from 'underscore';

import FontAwesome from 'components/FontAwesome';
import { Gift, Stack } from 'components/icons';
import SidebarNav from 'components/SidebarNav';
import { SubTier } from 'redux/middleware/api/plan-service';
import { isEnterpriseSubTier } from 'utils/pricing';
import NavLabel from './NavLabel';
import Routes from './Routes';
import UpdateEmailModal from './UpdateEmailModal';
import { block } from './utils';
import VerifyEmailModal from './VerifyEmailModal';

export interface IProps extends InjectedAuthRouterProps {
  onWillMount?: () => void;
  subtier: SubTier | undefined;
}

export default class AccountSettings extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onWillMount: _.noop,
  };

  public UNSAFE_componentWillMount() {
    const { onWillMount } = this.props;
    onWillMount();
  }

  public render() {
    const { subtier } = this.props;

    const canAccessReferrals = subtier && !isEnterpriseSubTier(subtier);

    return (
      <SidebarNav
        className={block()}
        contentColClassName={block('content-col')}
        id="account-settings-nav"
        navItems={[
          {
            href: '/settings/my-plan',
            key: 'plan',
            label: (
              <NavLabel
                icon={<FontAwesome icon="credit-card" fixedWidth />}
                label="My Plan"
              />
            ),
          },
          canAccessReferrals && {
            href: '/settings/referrals',
            key: 'referrals',
            label: (
              <NavLabel
                icon={<Gift className={block('none-fa-icon')} />}
                label="Free Pro"
              />
            ),
          },
          {
            href: '/settings/password',
            key: 'password',
            label: (
              <NavLabel
                icon={<FontAwesome icon="lock" fixedWidth />}
                label="Account"
              />
            ),
          },
          {
            href: '/settings/email',
            key: 'email',
            label: (
              <NavLabel
                icon={<FontAwesome icon="envelope" fixedWidth />}
                label="Email"
              />
            ),
          },
          {
            href: '/settings/fonts',
            key: 'fonts',
            label: (
              <NavLabel
                icon={<FontAwesome icon="font" fixedWidth />}
                label="Fonts"
              />
            ),
          },
          {
            href: '/settings/video',
            key: 'video',
            label: (
              <NavLabel
                icon={<FontAwesome icon="video" fixedWidth />}
                label="Video"
              />
            ),
          },
          {
            href: '/settings/apps',
            key: 'apps',
            label: (
              <NavLabel
                icon={<Stack className={block('none-fa-icon')} />}
                label="Apps"
              />
            ),
          },
        ].filter(Boolean)}
        paneClassName={block('content-pane')}
        title="Settings"
      >
        <Routes />
        <UpdateEmailModal />
        <VerifyEmailModal />
      </SidebarNav>
    );
  }
}
