import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { every, isEmpty } from 'underscore';
import SteppedModal from 'components/SteppedModal';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import TikTokPostModal from 'containers/TikTokPostModal';
import useAccessDeniedStep from 'containers/TikTokPostModal/steps/useAccessDeniedStep';
import useAuthenticationProgressStep from 'containers/TikTokPostModal/steps/useAuthenticationProgressStep';
import useAuthorizationStep from 'containers/TikTokPostModal/steps/useAuthorizationStep';
import useAutopostingComposerStep from 'containers/TikTokPostModal/steps/useAutopostingComposerStep';
import { UseComposerStepValue } from 'containers/TikTokPostModal/steps/useAutopostingComposerStep/types';
import useSwitchAccountStep from 'containers/TikTokPostModal/steps/useSwitchAccountStep';
import {
  getTikTokCreatorInfo,
  tiktokUserDataSelector,
} from 'redux/modules/social';
import { Dispatch } from 'redux/types';
import { TikTokSheetDefaultValues, TikTokUserAllowance } from './types';

export interface TikTokAutoPostModalProps {}

const TikTokAutoPostModal: React.FC<TikTokAutoPostModalProps> = () => {
  const [activeStep, setActiveStep] = useState('');

  const modalRef = useRef<SteppedModal>(null);

  const { onExited, onHide, show, params } = useConnectedModal(
    'TikTokAutoPost',
  );

  const tikTokUserData = useSelector(tiktokUserDataSelector);

  const { subscriptionItem } = params ?? {};

  const {
    title,
    tiktokPrivacyLevel,
    commentEnabled,
    duetEnabled,
    stitchEnabled,
    brandContentToggle,
    brandOrganicToggle,
  } = subscriptionItem?.autoPostVideoPreference.options || {};

  const initialValues = React.useMemo(():
    | TikTokSheetDefaultValues
    | undefined => {
    return {
      captions:
        title ??
        'Here’s a new clip from <Episode Title>! Listen to the full episode here: <Link To Episode>',
      privacyLevel: tiktokPrivacyLevel,
      userAllowance:
        ([
          commentEnabled && 'comment',
          duetEnabled && 'duet',
          stitchEnabled && 'stitch',
        ].filter(Boolean) as TikTokUserAllowance[]) ?? [],
      brandContent: brandContentToggle ?? false,
      brandOrganic: brandOrganicToggle ?? false,
    };
  }, [
    brandContentToggle,
    brandOrganicToggle,
    commentEnabled,
    duetEnabled,
    stitchEnabled,
    tiktokPrivacyLevel,
    title,
  ]);

  const dispatch = useDispatch<Dispatch>();

  const replaceModalStep = useCallback((id: string): void => {
    modalRef.current?.stepHistory.replace(id);
  }, []);

  const handleGoToProgressStep = useCallback(() => {
    replaceModalStep('authentication-in-progress');
  }, [replaceModalStep]);

  const handleAuthorizationError = useCallback((): void => {
    replaceModalStep('access-denied');
  }, [replaceModalStep]);

  const handleSwitchAccount = useCallback(() => {
    replaceModalStep('switch');
  }, [replaceModalStep]);

  const handleGoToComposerStep = useCallback(() => {
    if (every(tikTokUserData, isEmpty)) {
      return undefined;
    }

    return replaceModalStep('composer');
  }, [replaceModalStep, tikTokUserData]);

  const handleGoToDisclosureStep = useCallback(() => {
    replaceModalStep('disclosure');
  }, [replaceModalStep]);

  const handleAuthenticationSuccess = useCallback(async () => {
    await dispatch(getTikTokCreatorInfo());

    replaceModalStep('composer');
  }, [dispatch, replaceModalStep]);

  const handleCaptionsClick = useCallback(() => {
    replaceModalStep('captions');
  }, [replaceModalStep]);

  const handleHide = useCallback(
    (values?: UseComposerStepValue) => {
      handleGoToComposerStep();
      onHide(values);
    },
    [handleGoToComposerStep, onHide],
  );

  const handleExited = useCallback(() => {
    handleGoToComposerStep();
    onExited();
  }, [handleGoToComposerStep, onExited]);

  const handleSubmit = useCallback(
    (values: UseComposerStepValue): void => {
      handleHide(values);
    },
    [handleHide],
  );

  const authorization = useAuthorizationStep({
    onAuth: handleGoToProgressStep,
    onAuthSuccess: handleAuthenticationSuccess,
    onError: handleAuthorizationError,
  });

  const authenticationProgress = useAuthenticationProgressStep();

  const accessDenied = useAccessDeniedStep({
    onError: () => null,
    onReviseAccess: () => null,
  });

  const switchAccount = useSwitchAccountStep({
    onSwitchAccount: handleGoToProgressStep,
    onSwitchAccountSuccess: handleAuthenticationSuccess,
    onError: handleAuthorizationError,
  });

  const { composerSteps } = useAutopostingComposerStep({
    initialValues,
    onSwitchAccountClick: handleSwitchAccount,
    onCaptionsClick: handleCaptionsClick,
    onInitialStepClick: handleGoToComposerStep,
    onAdditionalDetailsClick: handleGoToDisclosureStep,
    onHideModal: handleHide,
    onSubmit: handleSubmit,
  });

  const composerStepsId = composerSteps.map(step => step.id);
  const isComposerStep = composerStepsId.includes(activeStep);

  return (
    <TikTokPostModal
      {...{ show }}
      ref={modalRef}
      title={!isComposerStep && 'auto-post videos'}
      defaultStep={authorization.id}
      steps={[
        authorization,
        authenticationProgress,
        accessDenied,
        switchAccount,
        ...composerSteps,
      ]}
      className={isComposerStep && 'composer-step-container'}
      onStepChange={setActiveStep}
      onHide={handleHide}
      onExited={handleExited}
    />
  );
};

export default TikTokAutoPostModal;
