import React from 'react';
import { InstagramSocialCircle } from 'components/SocialCircle';
import SocialButton, { SocialButtonProps } from './SocialButton';

export interface InstagramButtonProps
  extends Pick<SocialButtonProps, 'disabled' | 'onClick' | 'size'> {}

const InstagramButton: React.FC<InstagramButtonProps> = ({
  disabled,
  onClick,
  size,
}) => (
  <SocialButton
    icon={<InstagramSocialCircle />}
    label="Instagram"
    {...{ disabled, onClick, size }}
  />
);

export default InstagramButton;
