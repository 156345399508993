import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { tabsBlock as block } from '../../utils';
import ProjectsTabTitle, { ProjectsTabTitleProps } from './ProjectsTabTitle';

export interface ProjectsNavTabTitleProps extends ProjectsTabTitleProps {
  to: string;
}

/**
 * Overrides the title element in Tabs to support tab with custom active state,
 * in this case filling svg and text with a gradient.
 */
const ProjectsNavTabTitle: React.FC<ProjectsNavTabTitleProps> = ({
  to,
  ...titleProps
}) => (
  <NavLink
    to={to}
    activeClassName={block('title-contents', { active: true })}
    className={block('title-contents')}
  >
    <ProjectsTabTitle {...titleProps} />
  </NavLink>
);

export default ProjectsNavTabTitle;
