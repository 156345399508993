import cn from 'classnames';
import * as React from 'react';

import { ModalBodyCol, ModalBodyColProps } from 'components/Modal';
import { block } from '../utils';

type PickedColProps = Pick<ModalBodyColProps, 'size'>;

export interface ProgressAnimationModalColProps extends PickedColProps {
  className?: string;
}

const ProgressAnimationModalCol: React.SFC<ProgressAnimationModalColProps> = ({
  className,
  ...props
}) => (
  <ModalBodyCol
    className={cn(block('column'), className)}
    size={6}
    {...props}
  />
);

export default ProgressAnimationModalCol;
