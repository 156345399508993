import cn from 'classnames';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import List from 'components/List';

import { episodeListBlock as block } from '../../utils';
import EpisodeListRowArtwork from './EpisodeListRowArtwork';
import EpisodeListRowContainer from './EpisodeListRowContainer';
import EpisodeListRowContents from './EpisodeListRowContents';
import EpisodeListRowContext from './EpisodeListRowContext';
import EpisodeListRowDescription from './EpisodeListRowDescription';
import EpisodeListRowStatus from './EpisodeListRowStatus';

const { useState } = React;

export interface EpisodeListRowProps {
  active: boolean;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  empty?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  style?: React.CSSProperties;
}

interface EpisodeListRow extends React.FC<EpisodeListRowProps> {
  Artwork: typeof EpisodeListRowArtwork;
  Container: typeof EpisodeListRowContainer;
  Contents: typeof EpisodeListRowContents;
  Description: typeof EpisodeListRowDescription;
  Status: typeof EpisodeListRowStatus;
}

/**
 * the general structure of the row should be:
 *
 * EpisodeListRow
 * |- EpisodeListRowContainer
 * | |- EpisodeListRowArtwork
 * | |- EpisodeListRowDescription
 * | |- EpisodeListRowStatus
 * |- EpisodeListRowContents
 *
 * @see EpisodeListAutomationsRow
 * @see EpisodeListEpisodeRow
 */
const EpisodeListRow: EpisodeListRow = ({
  active,
  children,
  className,
  disabled,
  empty,
  onClick,
  style,
}) => {
  const [contentsHeight, setContentsHeight] = useState(0);

  return (
    <EpisodeListRowContext.Provider
      value={{
        active,
        setContentsHeight,
      }}
    >
      <CSSTransition
        in={active}
        className={cn(block('row', { active, disabled, empty }), className)}
        classNames={block('row')}
        timeout={300}
        appear
      >
        <List.Item
          className={cn(block('row', { active, disabled, empty }), className)}
          onClick={onClick}
          style={{
            height: active && contentsHeight ? contentsHeight + 85 : 85,
            ...style,
          }}
        >
          {children}
        </List.Item>
      </CSSTransition>
    </EpisodeListRowContext.Provider>
  );
};

EpisodeListRow.Artwork = EpisodeListRowArtwork;
EpisodeListRow.Container = EpisodeListRowContainer;
EpisodeListRow.Contents = EpisodeListRowContents;
EpisodeListRow.Description = EpisodeListRowDescription;
EpisodeListRow.Status = EpisodeListRowStatus;

export default EpisodeListRow;
