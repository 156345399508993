import cn from 'classnames';
import React from 'react';

import ConnectedModal, { ConnectedModalProps } from 'containers/ConnectedModal';

import { dialogModalBlock as block } from './utils';

export interface DownloadCaptionsDialogModalProps
  extends Pick<ConnectedModalProps, 'className' | 'name' | 'title'> {
  children?: React.ReactNode;
}

const DownloadCaptionsDialogModal: React.FC<DownloadCaptionsDialogModalProps> = ({
  children,
  className,
  name,
  title,
}) => (
  <ConnectedModal className={cn(block(), className)} {...{ name, title }}>
    {children}
  </ConnectedModal>
);

export default DownloadCaptionsDialogModal;
