import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'underscore';
import SteppedModal from 'components/SteppedModal';
import usePostModalDescription from 'hooks/usePostModalDescription';
import usePrevious from 'hooks/usePrevious';
import { defaultPostTextSelector } from 'redux/modules/download';
import useComposerStep, { UseComposerStepValue } from './steps/useComposerStep';
import useSwitchAccountStep from './steps/useSwitchAccountStep';
import { TwitterPostModalProps } from './types';
import { block } from './utils';

export interface TwitterPostVideoModalProps
  extends Pick<TwitterPostModalProps, 'onHide' | 'show'> {
  error?: string;
  onSubmit?: (vals: UseComposerStepValue) => void;
}

const TwitterPostVideoModal: React.FC<TwitterPostVideoModalProps> = ({
  error,
  onHide = noop,
  onSubmit = noop,
  show = false,
}) => {
  const modalRef = useRef<SteppedModal>(null);
  const prevShow = usePrevious(show);
  const defaultDescription = useSelector(defaultPostTextSelector);

  const {
    description,
    setDescription,
    resetDescription,
  } = usePostModalDescription({
    platform: 'twitter',
    defaultDescription,
  });

  const handlePostSuccess = useCallback(() => {
    if (show) {
      onHide();
    }
  }, [onHide, show]);

  const handleSwitchAccountClick = useCallback(
    () => modalRef.current.stepHistory.replace('switch'),
    [],
  );

  const handleError = useCallback(err => {
    if (err === 'access_denied') {
      modalRef.current.stepHistory.replace('access-denied');
    }
  }, []);

  const handleSwitchAccountSuccess = useCallback(() => {
    resetDescription();

    modalRef.current.stepHistory.replace('composer');
  }, [resetDescription]);

  useEffect(() => {
    if (show && !prevShow) {
      modalRef.current.stepHistory.replace('composer');
    }

    if (!prevShow && show && error) handleError(error);
  }, [error, handleError, prevShow, show]);

  const composerStep = useComposerStep({
    description,
    defaultDescription,
    setDescription,
    resetDescription,
    onPostSuccess: handlePostSuccess,
    onSubmit,
    onSwitchAccountClick: handleSwitchAccountClick,
    submitButtonLabel: 'Tweet',
  });

  const switchAccountStep = useSwitchAccountStep({
    onError: handleError,
    onSwitchAccountSuccess: handleSwitchAccountSuccess,
  });

  return (
    <SteppedModal
      defaultStep="composer"
      baseClassName={block()}
      onHide={onHide}
      ref={modalRef}
      show={show}
      steps={[composerStep, switchAccountStep]}
      title="Tweet your video"
      footerButtonsDisabled={false}
      onExited={noop}
    />
  );
};

export default TwitterPostVideoModal;
