import { Switch } from '@sparemin/blockhead';
import React from 'react';

import { block } from '../../../utils';

interface CaptionsTimesLockProps {
  locked: boolean;
  onChange: (enabled: boolean) => void;
}

const CaptionsTimesLock: React.FunctionComponent<CaptionsTimesLockProps> = props => {
  const { locked, onChange } = props;

  return (
    <div className={block('captions-time-lock')}>
      <p className={block('captions-time-lock-message')}>Lock caption times</p>
      <Switch
        className={block('captions-time-lock-switch')}
        isSelected={locked}
        onChange={onChange}
        value={locked ? 'on' : 'off'}
      />
    </div>
  );
};

export default CaptionsTimesLock;
