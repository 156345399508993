import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TextOverlayModal, { TextOverlayV2 } from 'blocks/TextOverlayModal/v2';
import useVideoEditorPlaybackTimeContext from 'containers/VideoEditor/useVideoEditorPlaybackTimeContext';
import { EditorVideoFramePreview } from 'containers/VideoFramePreview';
import { addTextOverlays } from 'redux/modules/embed/actions';
import { aspectRatioSelector } from 'redux/modules/embed/selectors';
import { DEFAULT_TEXT_OVERLAY_DURATION_MILLIS } from 'utils/constants';

import { block } from './utils';

interface AddTextModalProps {
  initialOverlay: TextOverlayV2;
  onExited: () => void;
  onHide: () => void;
  onSwitchToLegacy: () => void;
  show: boolean;
}

const AddTextModal: React.FunctionComponent<AddTextModalProps> = props => {
  const { initialOverlay, onExited, onHide, onSwitchToLegacy, show } = props;

  const dispatch = useDispatch();

  const videoPlayback = useVideoEditorPlaybackTimeContext();
  const startMillis = videoPlayback.positionSec * 1000;
  const endMillis = startMillis + DEFAULT_TEXT_OVERLAY_DURATION_MILLIS;

  const handleSubmit = React.useCallback(
    (textOverlay: TextOverlayV2): void => {
      dispatch(addTextOverlays(textOverlay));
      onHide();
    },
    [dispatch, onHide],
  );

  const aspectRatio = useSelector(aspectRatioSelector);

  return (
    <TextOverlayModal
      aspectRatio={aspectRatio}
      background={({ viewport }) => (
        <EditorVideoFramePreview
          // This preview component does not re render when the viewport
          // size is changed by a browser viewport size change.
          // This is not an event likely to happen in practise, but it is
          // good to support it.
          key={`${viewport?.height}-${viewport?.width}`}
          aspectRatio={aspectRatio}
          canvasDimensions={viewport}
          className={block('video-frame-preview')}
          backgroundFor={{
            type: 'textOverlayInfo',
          }}
        />
      )}
      editable
      extensions={{
        overlayTiming: {
          enabled: true,
          startMillis,
          endMillis,
        },
      }}
      isCompatibilityMode
      onExited={onExited}
      onHide={onHide}
      onSwitchToLegacy={onSwitchToLegacy}
      onSubmit={handleSubmit}
      showPresetSelector={false}
      submitButtonText="Add to video"
      shouldDisableSubmitIfEmpty
      showEmoji
      show={show}
      textOverlay={initialOverlay}
      title="Add text"
    />
  );
};

export default AddTextModal;
