import cn from 'classnames';
import * as React from 'react';

import useVideoEditorPlaybackTimeContext from 'containers/VideoEditor/useVideoEditorPlaybackTimeContext';
import BackgroundColorTile from '../containers/BackgroundColorTile';
import ProgressBarTile from '../containers/ProgressBarTile';
import WatermarkTile from '../containers/WatermarkTile';

import { block } from '../utils';

export interface VideoStyleOptionsProps {
  className?: string;
  onModalHide?: () => void;
  onModalShow?: () => void;
}

const VideoStyleOptions: React.SFC<VideoStyleOptionsProps> = ({
  className,
  onModalHide,
  onModalShow,
}) => {
  const videoEditorPlaybackTimeContext = useVideoEditorPlaybackTimeContext();
  const { positionSec } = videoEditorPlaybackTimeContext;

  return (
    <div className={cn(block(), className)}>
      <BackgroundColorTile onOpen={onModalShow} onClose={onModalHide} />
      <ProgressBarTile onShow={onModalShow} onHide={onModalHide} />
      <WatermarkTile
        onModalHide={onModalHide}
        onModalShow={onModalShow}
        positionSec={positionSec}
      />
    </div>
  );
};

export default VideoStyleOptions;
