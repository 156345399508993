import { useCallback, useState } from 'react';

export default function useMediaPlayback(defaultPlaying: boolean = false) {
  const [playing, setPlaying] = useState(defaultPlaying);

  const play = useCallback(() => setPlaying(true), []);
  const pause = useCallback(() => setPlaying(false), []);

  return { pause, play, playing };
}
