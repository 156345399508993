import { connect } from 'react-redux';
import { once } from 'underscore';

import {
  clearInvalidEditorSizeNotification,
  showInvalidEditorSizeNotification,
} from 'redux/modules/embed/actions';
import {
  invalidBrowserSize,
  validBrowserSize,
} from 'redux/modules/mixpanel/actions';
import { Dispatch } from 'redux/types';
import { WithBrowserSizeMonitorProps } from './withBrowserSizeMonitor';

type DispatchProps = Pick<
  WithBrowserSizeMonitorProps,
  'onResizeAboveMin' | 'onResizeBelowMin' | 'onUnmount'
>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onResizeAboveMin: once((size, min) => {
    dispatch(clearInvalidEditorSizeNotification());
    dispatch(validBrowserSize(size, min));
  }),
  onResizeBelowMin: once((size, min) => {
    dispatch(showInvalidEditorSizeNotification());
    dispatch(invalidBrowserSize(size, min));
  }),
  onUnmount: () => dispatch(clearInvalidEditorSizeNotification()),
});

export default function(
  component: React.ComponentType<WithBrowserSizeMonitorProps>,
) {
  return connect(null, mapDispatchToProps)(component);
}
