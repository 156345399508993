import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Plus } from 'components/icons';
import tiles from 'components/VideoTemplateEditor/waveform-tiles';
import { WaveTypeTile } from 'components/VideoTemplateEditor/waveform-tiles/createWaveTypeTile';
import ModalLink from 'containers/ModalLink';
import { addingToTrack, createTrack } from 'redux/modules/embed/actions/tracks';
import {
  hasSoundwaveSelector,
  soundwaveSelector,
  waveformTrackSelector,
} from 'redux/modules/embed/selectors';
import { pushModal } from 'redux/modules/modal/actions';
import { Dispatch } from 'redux/types';
import { block } from './utils';

const { useCallback } = React;

export interface WaveformModalLinkProps {
  onHide?: () => void;
}

const WaveformModalLink: React.FC<WaveformModalLinkProps> = ({ onHide }) => {
  const dispatch = useDispatch<Dispatch>();
  const hasWaveform = useSelector(hasSoundwaveSelector);
  const waveformTrack = useSelector(waveformTrackSelector);
  const soundwave = useSelector(soundwaveSelector);

  const SoundwaveTile: WaveTypeTile = tiles[soundwave?.get('waveType')];

  const handleAddWaveformClick = useCallback(() => {
    onHide();

    if (!waveformTrack) {
      dispatch(createTrack('waveform', { layerOrderType: 'free' }));
    } else {
      dispatch(addingToTrack(waveformTrack.get('id')));
      dispatch(pushModal({ name: 'AddWaveformModal' }));
    }
  }, [dispatch, onHide, waveformTrack]);

  return !hasWaveform ? (
    <button
      className={block('waveform-link', { add: true })}
      onClick={handleAddWaveformClick}
      type="button"
    >
      <div className={block('waveform-cta')}>
        <Plus style={{ height: 50, width: 50 }} />
      </div>
      <span className={block('waveform-label')}>Add Waveform</span>
    </button>
  ) : (
    <ModalLink
      className={block('waveform-link', { edit: true })}
      to={{ name: 'EditWaveformModal' }}
    >
      <SoundwaveTile as="div" className={block('waveform-cta')} />
      <span className={block('waveform-label')}>Edit Waveform</span>
    </ModalLink>
  );
};

export default WaveformModalLink;
