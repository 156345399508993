import cn from 'classnames';
import * as React from 'react';
import Slider, { Settings } from 'react-slick';

import Arrow from './Arrow';
import { block } from './utils';

type Breakpoint = 'xsmall' | 'small' | 'medium' | 'large';

interface IBreakpointConfig {
  slidesToShow: number;
  slidesToScroll: number;
}

export interface ICarouselSettings
  extends Pick<
    Settings,
    | 'arrows'
    | 'dots'
    | 'slidesToShow'
    | 'slidesToScroll'
    | 'swipeToSlide'
    | 'variableWidth'
  > {
  responsive?: { [k in Breakpoint]?: IBreakpointConfig };
}

interface IProps extends ICarouselSettings {
  children: React.ReactNode;
  className?: string;
}

/**
 * use bootstrap's breakpoints for consistency with other responsive elements in the app
 */

const BREAKPOINTS: { [k in Breakpoint]: number } = {
  xsmall: 480,
  small: 720,
  medium: 940,
  large: 1140,
};

const Carousel: React.FC<IProps> = ({
  children,
  className,
  responsive,
  ...sliderProps
}) => (
  <Slider
    draggable={false}
    className={cn(block(), className)}
    infinite={false}
    speed={500}
    nextArrow={<Arrow direction="next" />}
    prevArrow={<Arrow direction="prev" />}
    responsive={
      responsive &&
      Object.keys(responsive).reduce((acc, key) => {
        acc.push({
          breakpoint: BREAKPOINTS[key],
          settings: responsive[key],
        });
        return acc;
      }, [])
    }
    {...sliderProps}
  >
    {React.Children.map(children, (child: React.ReactElement<any>) => (
      // fragment is required here to prevent slick carouse to set display inline-block
      // https://github.com/akiran/react-slick/issues/1378
      <React.Fragment key={child?.key}>{child}</React.Fragment>
    ))}
  </Slider>
);

Carousel.defaultProps = {
  dots: true,
  slidesToScroll: 4,
  slidesToShow: 4,
  variableWidth: false,
};

export default Carousel;
export { IProps as CarouselProps };
