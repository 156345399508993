import { Record } from 'immutable';
import { State } from './types';

export const stateFactory = Record<State>({
  isLoading: undefined,
  isError: undefined,
  loadingText: undefined,
  errorMessage: undefined,
  recentFonts: undefined,
});
