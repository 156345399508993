import { useMemo } from 'react';
import useLoadElementFonts, {
  UseLoadElementFontsConfig,
} from 'hooks/useLoadElementFonts';

interface UseLoadTextOverlayFontsConfig
  extends Pick<UseLoadElementFontsConfig, 'onComplete'> {
  template: any;
}

export default function useLoadTextOverlayFonts({
  onComplete,
  template,
}: UseLoadTextOverlayFontsConfig) {
  const textHtmls = useMemo(() => {
    if (!template) {
      return [];
    }

    // In general 'textOverlayInfo' can be found on the root of the template object,
    // but for some template types like 'userGenerated' the 'textOverlayInfo' might come from 'previewConfiguration' property.
    const textSource =
      template.textOverlayInfo || template.previewConfiguration.textOverlayInfo;

    return textSource?.map(overlay => overlay.textHtml) ?? [];
  }, [template]);

  return useLoadElementFonts({ html: textHtmls, onComplete });
}
