import * as React from 'react';
import { IconProps } from './types';

const Escape: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 10" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor" fillRule="nonzero">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M12.741 58v-1.412h-3.96v-2.285h3.374v-1.377H8.78v-2.033h3.95v-1.424H7.021V58h5.72zm5.125.117c.946 0 1.697-.21 2.256-.63.559-.42.838-.999.838-1.737 0-.664-.229-1.219-.686-1.664-.457-.445-1.185-.818-2.185-1.12-.543-.163-.954-.339-1.234-.527-.279-.187-.418-.42-.418-.697 0-.297.119-.536.357-.718.238-.181.572-.272 1.002-.272.445 0 .79.108 1.037.325.246.217.37.52.37.911h1.757c0-.511-.132-.968-.396-1.37-.263-.403-.633-.715-1.11-.935-.476-.221-1.02-.331-1.629-.331-.605 0-1.151.101-1.637.304-.487.203-.86.486-1.12.847-.26.361-.39.772-.39 1.233 0 .887.485 1.592 1.454 2.116.355.191.838.385 1.447.583.61.197 1.031.384 1.266.562.234.178.351.433.351.765 0 .304-.117.542-.351.712-.235.17-.56.255-.979.255-1.125 0-1.687-.471-1.687-1.413h-1.764c0 .551.142 1.036.425 1.454.283.417.694.747 1.233.987.54.24 1.137.36 1.793.36zm8.047 0c1 0 1.806-.263 2.417-.79.611-.528.95-1.25 1.017-2.169h-1.758c-.04.52-.193.904-.46 1.152-.268.248-.673.372-1.216.372-.601 0-1.042-.213-1.321-.639-.28-.426-.42-1.1-.42-2.022v-.644c.009-.883.158-1.534.45-1.954.29-.42.729-.63 1.315-.63.539 0 .94.124 1.204.372.263.248.417.648.46 1.198h1.757c-.093-.953-.441-1.693-1.043-2.22-.601-.528-1.394-.791-2.378-.791-.711 0-1.335.167-1.873.5-.537.335-.95.814-1.239 1.439-.289.625-.433 1.348-.433 2.168v.521c0 1.286.313 2.296.94 3.033.627.736 1.487 1.104 2.581 1.104z"
                  transform="translate(-1952 -207) translate(0 135) translate(1000 3) translate(20 20) translate(925)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Escape;
