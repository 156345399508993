import cn from 'classnames';
import * as React from 'react';
import { MenuItem, MenuItemProps } from 'react-bootstrap';

import FontAwesome, { IconProp } from 'components/FontAwesome';

interface IProps extends MenuItemProps {
  label: string;
  iconClassName?: string;
  iconName?: IconProp;
}

const DropdownMenuItem: React.SFC<IProps> = ({
  className,
  label,
  iconClassName,
  iconName,
  ...menuItemProps
}) => {
  return (
    <MenuItem
      {...menuItemProps}
      className={cn('navigation__menu-item', className)}
    >
      <div className="navigation__menu-item-content">
        <FontAwesome
          className={cn('navigation__menu-item-icon', iconClassName)}
          icon={iconName}
          fixedWidth
        />
        <span className="navigation__menu-item-text">{label}</span>
      </div>
    </MenuItem>
  );
};

export default DropdownMenuItem;
export { IProps as DropdownMenuItemProps };
