import Slider, { Handle } from 'rc-slider';
import * as React from 'react';
import _ from 'underscore';

import Tooltip from 'components/Tooltip';
import { block } from './utils';

interface IProps {
  defaultValue?: number;
  disabled?: boolean;
  marks: any;
  maxValue: number;
  minValue: number;
  onChange?: (value: number) => void;
  step?: number;
  tooltipId: string;
  value: number;
}

interface IState {
  defaultValue: number;
  sliderHandlerHovered: boolean;
}

export default class DurationSlider extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    defaultValue: 0,
    disabled: false,
    onChange: _.noop,
    step: 0.01,
  };

  public constructor(props: IProps) {
    super(props);

    this.state = {
      defaultValue: props.defaultValue || props.value,
      sliderHandlerHovered: false,
    };
  }

  private formatTooltip = (v: number) => `${v.toFixed(2)}s`;

  private handleMouseEnter = () =>
    this.setState({ sliderHandlerHovered: true });

  private handleMouseLeave = () =>
    this.setState({ sliderHandlerHovered: false });

  private renderHandle = props => {
    const { value, dragging, index, ...restProps } = props;
    const { tooltipId } = this.props;
    const { sliderHandlerHovered } = this.state;

    return (
      <Tooltip
        animation={false}
        content={this.formatTooltip(value)}
        id={tooltipId}
        placement="top"
        preventHideOnHover={false}
        show={dragging || sliderHandlerHovered}
      >
        <Handle
          value={value}
          {...restProps}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        />
      </Tooltip>
    );
  };

  public render() {
    const {
      disabled,
      marks,
      minValue,
      maxValue,
      onChange,
      step,
      value,
    } = this.props;
    const { defaultValue } = this.state;

    return (
      <div className={block({ disabled })}>
        <Slider
          disabled={disabled}
          included={false}
          min={minValue}
          max={maxValue}
          step={step}
          defaultValue={defaultValue}
          value={value}
          marks={marks}
          trackStyle={{ visibility: 'visible' }}
          handle={this.renderHandle}
          onChange={onChange}
        />
      </div>
    );
  }
}

export { IProps as DurationSliderProps };
