import { ThunkAction } from 'redux/types';
import { getCaptionsStateStyle } from 'utils/embed/captions';

import * as embedSelectors from '../selectors';
import { setCaptionsConfig, updateCaptionsRevision } from './captions';
import { rechunkTranscript } from './transcript';

/**
 * Applies a captions rechunk correction meant to be done when the editor
 * is loaded.
 * If the captions are enabled, the transcriptId can be found and the
 * shouldRechunkCaptionsOnEditorLoad flag was set for the captions config.
 * The captions styles are recalculated and the captions rechunked. After
 * that, the captions revision is updated and the update flag is cleared
 * from the captions config object.
 */
export const applyCaptionsRechunkCorrection = (): ThunkAction<Promise<
  void
>> => async (dispatch, getState) => {
  const captionsConfig = embedSelectors.captionsSelector(getState());
  const enabled = captionsConfig.getIn(['config', 'enabled']);
  const transcriptId = captionsConfig.getIn(['config', 'transcriptId']);
  const shouldRechunkCaptionsOnEditorLoad = captionsConfig.getIn([
    'config',
    'shouldRechunkCaptionsOnEditorLoad',
  ]);

  if (enabled && transcriptId && shouldRechunkCaptionsOnEditorLoad) {
    const style = getCaptionsStateStyle(captionsConfig.get('config'));
    await dispatch(rechunkTranscript(undefined, undefined, undefined, style));

    await dispatch(
      setCaptionsConfig(
        captionsConfig
          .get('config')
          .delete('shouldRechunkCaptionsOnEditorLoad'),
      ),
    );

    await dispatch(updateCaptionsRevision());
  }
};
