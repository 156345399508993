import * as React from 'react';
import { noop } from 'underscore';

import { AspectRatioName } from '../../types';
import { AspectRatio, Size } from './types';
import { block } from './utils';

interface IProps {
  description?: string;
  name: AspectRatioName;
  onSelect?: (ratio: AspectRatio, name: AspectRatioName) => void;
  selected?: boolean;
  size?: Size;
  value: AspectRatio;
}

export default class AspectRatioOption extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onSelect: noop,
    selected: false,
  };

  private handleSelect = () => {
    const { name, onSelect, value } = this.props;
    onSelect(value, name);
  };

  public render() {
    const { description, name, selected, size } = this.props;

    return (
      <button
        className={block('option', { selected, [name]: true })}
        onClick={this.handleSelect}
        type="button"
      >
        <div className={block('icon-container')}>
          <div className={block('icon')} />
        </div>
        {size === 'large' && (
          <div className={block('footer')}>
            <div className={block('title')}>{name}</div>
            <div className={block('description')}>{description}</div>
          </div>
        )}
      </button>
    );
  }
}

export { IProps as AspectRatioOptionProps };
