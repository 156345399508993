import { useTwitterLogin, UseTwitterLoginConfig } from '@sparemin/auth';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'underscore';
import { tokenSelector } from 'redux/modules/auth';
import { showError } from 'redux/modules/notification';
import {
  clearTwitterUser,
  twitterAuthorizeSuccess,
  waitForTwitterProfile,
} from 'redux/modules/social';
import { FatalError } from 'utils/FatalError';

export interface UseTwitterAuthenticatorConfig
  extends Pick<UseTwitterLoginConfig, 'force' | 'onError'> {
  onAuthSuccess?: () => void;
}

export default function useTwitterAuthenticator({
  onAuthSuccess,
  onError,
  force,
}: UseTwitterAuthenticatorConfig) {
  const dispatch = useDispatch();
  const spareminToken = useSelector(tokenSelector);

  const showGenericErrorMessage = useCallback(() => {
    dispatch(showError('Error authenticating with Twitter'));
  }, [dispatch]);

  return useTwitterLogin({
    force,
    spareminToken,
    onError: error => {
      if (error.message === 'access_denied') {
        onError?.(error);
      } else {
        showGenericErrorMessage();
      }
    },
    onSuccess: async data => {
      dispatch(clearTwitterUser());

      if (data) {
        dispatch(
          twitterAuthorizeSuccess(
            pick(
              data.tokenInfo,
              'accessToken',
              'accessTokenSecret',
              'idToken',
              'providerUserId',
            ),
          ),
        );
      }

      try {
        await dispatch(waitForTwitterProfile());
        onAuthSuccess?.();
      } catch (error) {
        onError?.(new FatalError(error.message));
        showGenericErrorMessage();
      }
    },
  });
}
