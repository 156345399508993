import cn from 'classnames';
import React from 'react';
import { H3 } from 'components/Heading';
import { block } from './utils';

export interface ListItemTextProps {
  className?: string;
  primary: React.ReactNode;
  secondary?: React.ReactNode;
}

const ListItemText: React.FC<ListItemTextProps> = ({
  className,
  primary,
  secondary,
}) => {
  const hasSecondary = !!secondary;

  const createPrimaryClassName = (modifiers?: { [k: string]: boolean }) =>
    block('item-text-primary', {
      noSecondary: !hasSecondary,
      ...modifiers,
    });

  const secondaryClassName = block('item-text-secondary');

  return (
    <div className={cn(block('item-text'), className)}>
      {typeof primary === 'string' ? (
        <H3 className={createPrimaryClassName({ heading: true })}>{primary}</H3>
      ) : (
        React.isValidElement(primary) &&
        React.cloneElement(primary, {
          className: cn(createPrimaryClassName(), primary.props.className),
        })
      )}
      {typeof secondary === 'string' ? (
        <span className={secondaryClassName}>{secondary}</span>
      ) : (
        React.isValidElement(secondary) &&
        React.cloneElement(secondary, {
          className: cn(secondaryClassName, secondary.props.className),
        })
      )}
    </div>
  );
};

export default ListItemText;
