import React, { useCallback, useState } from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';
import { useEditorState } from 'components/VideoTemplateEditor/context/VideoEditorStateContext';
import { AssetCreationType } from '../../types';
import { addImageTypeModalBlock as block } from '../../utils';
import VideoTemplateEditorToggleCard from '../../VideoTemplateEditorToggleCard';

export interface AddImageTypeSelectionModalContentsProps {
  onSubmit?: (value: AssetCreationType) => void;
}

const AddImageTypeSelectionModalContents: React.FC<AddImageTypeSelectionModalContentsProps> = ({
  onSubmit,
}) => {
  const [value, setValue] = useState<AssetCreationType | undefined>(undefined);
  const { integrations } = useEditorState();
  const { staticImageIntegrations, dynamicImageIntegrations } = integrations;

  const staticProps = staticImageIntegrations.find(
    i => i.mediaTypeCardProps !== undefined,
  )?.mediaTypeCardProps;

  const dynamicProps = dynamicImageIntegrations.find(
    i => i.mediaTypeCardProps !== undefined,
  )?.mediaTypeCardProps;

  const handleStaticChange = useCallback((checked: boolean) => {
    if (checked) {
      setValue('static');
    } else {
      setValue(undefined);
    }
  }, []);

  const handleDynamicChange = useCallback((checked: boolean) => {
    if (checked) {
      setValue('dynamic');
    } else {
      setValue(undefined);
    }
  }, []);

  const handleNextClick = useCallback(() => {
    onSubmit(value);
  }, [onSubmit, value]);

  return (
    <>
      <Modal.Body className={block('body')}>
        <VideoTemplateEditorToggleCard
          checked={value === 'static'}
          icon={staticProps.icon}
          info={staticProps.info}
          onChange={handleStaticChange}
          theme="blue"
        />
        <VideoTemplateEditorToggleCard
          checked={value === 'dynamic'}
          icon={dynamicProps.icon}
          info={dynamicProps.info}
          onChange={handleDynamicChange}
          theme="rainbow"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={value === undefined}
          onClick={handleNextClick}
          fluid
          theme="submit"
        >
          next
        </Button>
      </Modal.Footer>
    </>
  );
};

export default AddImageTypeSelectionModalContents;
