import * as React from 'react';

import { episodeListBlock as block } from '../utils';
import EpisodeRowCameraIcon from './EpisodeRowCameraIcon';

export interface EpisodeRowVideoCountProps {
  count: number;
}

const EpisodeRowVideoCount: React.FC<EpisodeRowVideoCountProps> = ({
  count,
}) => (
  <div className={block('video-count')}>
    <EpisodeRowCameraIcon />
    <span className={block('video-count-num')}>{count}</span>
  </div>
);

export default EpisodeRowVideoCount;
