import React from 'react';

import ChildViewTipTip from '../assets/ChildViewTip';
import DeleteButton from '../image-cta/DeleteButton';
import ReplaceButton from '../image-cta/ReplaceButton';
import TileGrid from '../TileGrid';
import useEditorLoading from '../useEditorLoading';
import { imageViewBlock as block } from '../utils';
import DynamicImageCard from './DynamicImageCard';

export interface DynamicImageChildContentsProps {}

const DynamicImageChildContents: React.FC<DynamicImageChildContentsProps> = () => {
  const [isEditorLoading] = useEditorLoading();

  // tiles are disabled while media editor is loading
  const disabled = isEditorLoading;

  return (
    <>
      <DynamicImageCard />
      <ChildViewTipTip />
      <TileGrid className={block('grid', { dynamic: true })}>
        <DeleteButton disabled={disabled} />
        <ReplaceButton disabled={disabled} />
      </TileGrid>
    </>
  );
};

export default DynamicImageChildContents;
