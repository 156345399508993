import React from 'react';

import TextPresetSelector from 'components/TextPresetSelector';
import { ImmutableTextPreset } from 'redux/modules/text-presets';

import { block } from '../../utils';

interface TextOverlayPresetsProps {
  onPickPreset: (presetStyles: ImmutableTextPreset) => void;
  selectedPreset?: ImmutableTextPreset;
}

const TextOverlayPresets: React.FunctionComponent<TextOverlayPresetsProps> = props => {
  const { onPickPreset, selectedPreset } = props;

  return (
    <div className={block('presets-toolbar-container')}>
      <TextPresetSelector
        mode="horizontal"
        onPickPreset={onPickPreset}
        selectedPreset={selectedPreset}
      />
    </div>
  );
};

export default TextOverlayPresets;
