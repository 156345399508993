import { isNull, isUndefined } from 'underscore';
import { actions as podcastService } from 'redux/middleware/api/podcast-service';
import { actions as urlGenApiActions } from 'redux/middleware/api/url-generator-service';
import { Dispatch, ThunkAction } from 'redux/types';
import { ApplicationError } from 'utils/ApplicationError';
import { getDuration } from 'utils/audio';
import { pollForThirdPartyAsset } from '../common/actions/third-party';
import { Type } from './action-types';
import { entireAudioEnhancer } from './enhancers';
import {
  audioUrlSelector,
  episodeSelector,
  shouldPollForAssetsSelector,
  traceAudioDurationMillisSelector,
  transcriptUrlSelector,
} from './selectors';
import { SetAudioClipAction } from './types';

export const setAudioClip = (
  startMillis: number,
  endMillis: number,
): ThunkAction<SetAudioClipAction> => dispatch =>
  dispatch({
    payload: { endMillis, startMillis },
    type: Type.AUDIO_WIZARD_AUDIO_CLIP_SET,
  });

const loadAudioDuration = (): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  const audioUrl = audioUrlSelector(getState());

  try {
    const durationSeconds = await getDuration(audioUrl);
    dispatch({
      payload: {
        durationMillis: Math.round(durationSeconds * 1000),
      },
      type: Type.AUDIO_WIZARD_ORIGINAL_AUDIO_DURATION_SET,
    });
  } catch (err) {
    throw new Error('Error accessing audio, please try again shortly');
  }
};

const pollForAssets = (): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  const shouldPoll = shouldPollForAssetsSelector(getState());

  if (!shouldPoll) {
    return;
  }

  try {
    await dispatch(pollForThirdPartyAsset(audioUrlSelector));
  } catch {
    throw new Error('Error accessing audio, please try again shortly');
  }

  try {
    await dispatch(pollForThirdPartyAsset(transcriptUrlSelector));
  } catch {
    throw new Error('Error accessing transcript, please try again shortly');
  }
};

const loadTrace = (
  customTraceId: string | undefined,
  traceId: string | undefined,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const { response } = await (traceId
    ? dispatch(urlGenApiActions.getTraceById(traceId))
    : dispatch(urlGenApiActions.getTraceByCustomId(customTraceId)));

  dispatch({
    payload: {
      traceId: response.result,
    },
    type: Type.AUDIO_WIZARD_TRACE_ID_SET,
  });

  await dispatch(pollForAssets());

  const traceDuration = traceAudioDurationMillisSelector(getState());
  if (!traceDuration) {
    await dispatch(loadAudioDuration());
  }
};

const loadEpisode = (
  episodeId: number,
): ThunkAction<Promise<void>> => async dispatch => {
  const { response } = await dispatch(podcastService.getEpisodeById(episodeId));

  dispatch({
    payload: {
      episodeId: response.result,
    },
    type: Type.AUDIO_WIZARD_EPISODE_ID_SET,
  });
};

export const loadWizardData = (
  customTraceId: string | undefined,
  traceId: string | undefined,
  episodeId?: number | undefined,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  if (!customTraceId && !traceId && !episodeId) {
    throw new Error('Insufficient data for loading the wizard.');
  }

  dispatch({
    type: Type.AUDIO_WIZARD_DATA_LOAD_REQUEST,
  });

  try {
    if (customTraceId || traceId) {
      await dispatch(loadTrace(customTraceId, traceId));
    } else {
      await dispatch(loadEpisode(episodeId));
    }

    // Episode audioDurationMillis is used for defining if it is an autogram or not.
    // PM has defined that as isAutogram condition can not be assumed when audioDurationMillis
    // is null. Since that condtion, when episode is loaded audioDurationMillis is checked
    // and if it is null or undefined an error will be thrown for avoid loading the wizard
    // with insuficient data.
    if (episodeId && !customTraceId && !traceId) {
      const episode = episodeSelector(getState());
      const audioDurationMillis = episode?.get('audioDurationMillis');
      if (isUndefined(audioDurationMillis) || isNull(audioDurationMillis)) {
        throw new ApplicationError(
          'Duration of audio is not available in the podcast feed',
          'ER039',
        );
      }
    }

    dispatch({ type: Type.AUDIO_WIZARD_DATA_LOAD_SUCCESS });
  } catch (err) {
    dispatch({ type: Type.AUDIO_WIZARD_DATA_LOAD_FAILURE });
    throw err;
  }
};

export const clearAudioWizard = () => (dispatch: Dispatch) =>
  dispatch({ type: Type.AUDIO_WIZARD_CLEAR });

export const uploadEntireAudio = (
  src: string | Blob,
): ThunkAction<Promise<void>> => (dispatch, getState) => {
  const episode = episodeSelector(getState());
  const metadata = episode
    ? {
        podcastId: episode.getIn(['podcast', 'remotePodcastId']),
        episodeId: String(episode.get('remoteEpisodeId')),
        originalAudioUrl: episode.get('originalAudioUrl'),
      }
    : {};

  return dispatch(entireAudioEnhancer.actions.uploadEntireAudio(src, metadata));
};

export const { clearEntireAudio } = entireAudioEnhancer.actions;
