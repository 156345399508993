import * as React from 'react';

import { ButtonField, ButtonFieldProps } from 'components/Form';
import SlideOptionsField from './SlideOptionsField';

type SlideCropFieldProps = Pick<ButtonFieldProps, 'onClick' | 'disabled'>;

const SlideCropField: React.FC<SlideCropFieldProps> = ({
  disabled,
  onClick,
}) => (
  <SlideOptionsField>
    <ButtonField
      disabled={disabled}
      label="Image Crop"
      onClick={onClick}
      theme="submit"
    >
      adjust crop
    </ButtonField>
  </SlideOptionsField>
);

export default SlideCropField;
