import * as React from 'react';
import Lottie from 'components/Lottie';
import ImageAsset from '../ImageAsset';
import useSlideSrc from '../useSlideSrc';
import { LottieAssetProps } from './types';
import useLottieData from './useLottieData';

const LottieAsset: React.FC<LottieAssetProps> = ({ slideId }) => {
  const [config, requestState, lottieRef, onReady] = useLottieData(slideId);
  const fallbackImageUrl = useSlideSrc(slideId);

  if (!config) return null;

  if (requestState === 'failure') {
    return <ImageAsset imageUrl={fallbackImageUrl} />;
  }

  return (
    <Lottie
      options={{
        animationData: config.animationData,
        autoplay: true,
        loop: true,
      }}
      eventListeners={[
        {
          eventName: 'DOMLoaded',
          callback: onReady,
        },
      ]}
      ref={lottieRef}
    />
  );
};

export default LottieAsset;
