import * as React from 'react';
import CutCardCta from 'components/CardCta/CutCardCta';
import ReplyCardCta from 'components/CardCta/ReplyCardCta';
import { AudioSourceType } from 'types';
import CustomizeStepCtaButtonTooltip from './CustomizeStepCtaButtonTooltip';
import CustomizeStepCtaOverlay from './CustomizeStepCtaOverlay';
import { CustomizeStepSubmitType } from './types';

const { useCallback } = React;

export interface AudiogramCtaOverlayProps {
  disabled: boolean;
  show: boolean;
  isComplex: boolean;
  isLongFormProject: boolean;
  isTranscriptionEnabled?: boolean;
  audioSourceType: AudioSourceType;
  language: string;
  transcriptWithNoClipping?: boolean;
  onCancelClick?: () => void;
  onSubmitClick?: (
    type: CustomizeStepSubmitType,
    shouldGenerateAssets?: boolean,
  ) => void;
}

const AudiogramCtaOverlay: React.FC<AudiogramCtaOverlayProps> = ({
  disabled,
  isComplex,
  isLongFormProject,
  isTranscriptionEnabled,
  audioSourceType,
  language,
  onCancelClick,
  onSubmitClick,
  show,
  transcriptWithNoClipping,
}) => {
  const handleSubmitClick = useCallback(
    async (_, { type }) => {
      return onSubmitClick(type);
    },
    [onSubmitClick],
  );
  const disableEditorButton = disabled || isComplex || isLongFormProject;
  const disableExportButton =
    disabled || (!transcriptWithNoClipping && isTranscriptionEnabled);

  const getTooltip = () => {
    if (isComplex)
      return 'This template cannot be opened in the advanced editor';
    if (isLongFormProject)
      return 'This project is too long to open in the advaned editor';
    return undefined;
  };

  return (
    <CustomizeStepCtaOverlay
      overlaySource="UCS"
      {...{ onCancelClick, show, audioSourceType, language }}
    >
      <CustomizeStepCtaButtonTooltip
        id="edit-video"
        disabled={disableEditorButton}
        tooltip={getTooltip()}
      >
        <CutCardCta
          label="Open advanced editor"
          description={
            <span>
              Edit captions, add transitions, <br />
              multiple tracks, watermarks, & much more.
            </span>
          }
          disabled={disableEditorButton}
          onClick={handleSubmitClick}
          params={{ type: 'edit' }}
        />
      </CustomizeStepCtaButtonTooltip>
      <CustomizeStepCtaButtonTooltip
        id="export-video"
        disabled={disableExportButton}
        tooltip={
          !transcriptWithNoClipping && isTranscriptionEnabled
            ? 'You enabled captions, please review them in the advanced editor'
            : undefined
        }
      >
        <ReplyCardCta
          label="Export video now"
          description={
            <span>
              Skip advanced editing so you <br />
              can share your video sooner.
            </span>
          }
          disabled={disableExportButton}
          onClick={handleSubmitClick}
          params={{ type: 'export' }}
        />
      </CustomizeStepCtaButtonTooltip>
    </CustomizeStepCtaOverlay>
  );
};

export default AudiogramCtaOverlay;
