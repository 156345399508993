/* tslint:disable variable-name max-classes-per-file */
import * as Immutable from 'immutable';

import { WebAudioPlayerProps } from 'components/WebAudioPlayer';

interface IDataState {
  playbackStatus: 'paused' | 'playing' | 'playPending';
  positionSeconds: number;
}

const DataRecordClass = Immutable.Record<IDataState>({
  playbackStatus: 'paused',
  positionSeconds: 0,
});

export class DataState extends DataRecordClass {}

export interface IState {
  data: DataState;
}

export interface IPlaybackPos {
  seconds: number;
  duration: number;
}

export interface IProps extends Pick<WebAudioPlayerProps, 'clips'> {
  canPlay?: boolean;
  className?: string;
  controlButtonClassName?: string;
  controlsClassName?: string;
  durationSeconds?: number;
  exportButtonEnabled?: boolean;
  copyButtonEnabled?: boolean;
  onExportClick?: () => void;
  onPause?: () => void;
  onPlay?: () => void;
  onTimeupdate?: (args: IPlaybackPos) => void;
  onSeek?: (args: IPlaybackPos) => void;
  playing?: boolean;
  playbackControlGroupClassName?: string;
  statusClassName?: string;
  volumeControlGroupClassName?: string;
}
