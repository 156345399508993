import * as React from 'react';

import { Emoji, InfinityGradient, Pro, Users } from 'components/icons';
import ReferralsFeaturesBoxRow from './ReferralsFeaturesBoxRow';
import { blockFeaturesBox as block } from './utils';

const ReferralsFeaturesBox: React.SFC = () => (
  <div className={block()}>
    <ReferralsFeaturesBoxRow
      icon={<Users width="45" />}
      title="Give 2 weeks free"
      description="Your friends get 2 free weeks of Pro to try it out."
    />
    <ReferralsFeaturesBoxRow
      icon={<Emoji width="40" />}
      title="Get 1 week per friend"
      description="You get a free week of Pro for each friend who joins."
    />
    <ReferralsFeaturesBoxRow
      icon={<Pro width="45" />}
      title="Already Pro? Go Pro-er!"
      description="Each free week gets added to your current subscription."
    />
    <ReferralsFeaturesBoxRow
      icon={<InfinityGradient width="45" />}
      title="Send infinite invites"
      description="Got a lot of friends? You just might get free Pro for life."
    />
  </div>
);

export default ReferralsFeaturesBox;
