import FacebookButton, { FacebookButtonProps } from './FacebookButton';
import InstagramButton, { InstagramButtonProps } from './InstagramButton';
import LinkedInButton, { LinkedInButtonProps } from './LinkedInButton';
import SocialButton, { SocialButtonProps } from './SocialButton';
import ThreadsButton, { ThreadsButtonProps } from './ThreadsButton';
import TikTokButton, { TikTokButtonProps } from './TikTokButton';
import TwitterButton, { TwitterButtonProps } from './TwitterButton';
import YouTubeButton, { YouTubeButtonProps } from './YouTubeButton';

export default SocialButton;
export {
  FacebookButton,
  FacebookButtonProps,
  InstagramButton,
  InstagramButtonProps,
  LinkedInButton,
  LinkedInButtonProps,
  TikTokButton,
  TikTokButtonProps,
  TwitterButton,
  TwitterButtonProps,
  YouTubeButton,
  YouTubeButtonProps,
  SocialButtonProps,
  ThreadsButton,
  ThreadsButtonProps,
};
