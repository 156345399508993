import * as React from 'react';
import Context from './ToggleButtonGroupContext';
import { block } from './utils';

interface Props {
  name: string;
  value: string;
  className?: string;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}

const { useContext } = React;

const ToggleButton: React.FC<Props> = props => {
  const { id: groupId, value: groupValue, onChange } = useContext(Context);
  const { name, className, value, children, onMouseOver, onMouseOut } = props;
  const id = `${groupId}-${value}`;
  return (
    <span className={block('button')} {...{ onMouseOver, onMouseOut }}>
      <input
        {...{ id, name, className, value }}
        type="radio"
        checked={value === groupValue}
        onChange={event => {
          if (event.target.checked) {
            onChange(value);
          }
        }}
      />
      <label htmlFor={id}>{children}</label>
    </span>
  );
};

export default ToggleButton;
