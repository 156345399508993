import cn from 'classnames';
import React from 'react';
import { actionStepCardIconBlock as block } from './utils';

export interface ActionStepCardCompositeIconProps {
  children?: React.ReactElement<{ className: string }>;
}

/**
 * Some ActionStepCard's are a small icon contained within a colored container.
 * This component builds that "composite" icon
 */
function ActionStepCardCompositeIcon({
  children,
}: ActionStepCardCompositeIconProps) {
  return (
    <div className={block()}>
      {React.isValidElement(children) &&
        React.cloneElement(children, {
          className: cn(children.props.className, block('icon')),
        })}
    </div>
  );
}

export default ActionStepCardCompositeIcon;
