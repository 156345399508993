import {
  AiImageType,
  actions as imageSearch,
  TextToImageProvider,
} from 'redux/middleware/api/image-search-service';
import { ThunkAction } from 'redux/types';
import reduxPoll from 'utils/redux-poll';
import { Type } from './action-types';
import { TEXT_TO_IMAGE_POLL_ID } from './constants';
import { MediaType } from './types';

export const pollForTextToImage = (
  textToImageJobId: number,
  mediaType: MediaType,
): ThunkAction<void> => async dispatch => {
  dispatch({
    type: Type.TEXT_TO_IMAGE_POLLING_BEGIN,
    payload: { mediaType },
  });

  try {
    await reduxPoll(
      dispatch,
      async () => {
        const { response } = await dispatch(
          imageSearch.getTextToImage(textToImageJobId),
        );

        if (response.status === 'completed') {
          dispatch({
            type: Type.TEXT_TO_IMAGE_POLLING_SUCCESS,
            payload: { images: response.images, mediaType },
          });
        }

        return response;
      },
      {
        id: TEXT_TO_IMAGE_POLL_ID,
        shouldContinue: (_, value) => {
          if (value.status === 'error') {
            dispatch({
              type: Type.TEXT_TO_IMAGE_POLLING_FAILURE,
              mediaType,
            });

            return false;
          }

          if (value.status === 'completed') {
            return false;
          }

          return true;
        },
        intervalMillis: 2500,
        maxAttempts: Infinity,
      },
    );
  } finally {
    dispatch({
      type: Type.TEXT_TO_IMAGE_POLLING_END,
      payload: { mediaType },
    });
  }
};

export const createTextToImage = (
  mediaType: MediaType,
  prompt: string,
  provider: TextToImageProvider,
  width: number,
  height: number,
  imageType?: AiImageType,
  colors?: string[],
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    type: Type.TEXT_TO_IMAGE_CREATE_REQUEST,
    payload: { mediaType, prompt, provider, width, height },
  });

  try {
    const {
      response: { textToImageJobId },
    } = await dispatch(
      imageSearch.createTextToImage(
        prompt,
        provider,
        width,
        height,
        imageType,
        colors.map(hexColorCode => ({
          hexColorCode,
        })),
      ),
    );

    dispatch({
      type: Type.TEXT_TO_IMAGE_CREATE_SUCCESS,
      payload: { textToImageJobId, mediaType },
    });

    dispatch(pollForTextToImage(textToImageJobId, mediaType));
  } catch (err) {
    dispatch({
      type: Type.TEXT_TO_IMAGE_CREATE_FAILURE,
    });

    throw err;
  }
};
