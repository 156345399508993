import * as React from 'react';
import { noop } from 'underscore';

import TextOverlayModal, {
  ITextOverlay,
  TextOverlay as ModalTextOverlay,
} from 'blocks/TextOverlayModal';
import Modal from 'components/Modal';
import SwitchEditorModalBanner from 'components/SwitchEditorModalBanner';
import VideoEditorPlaybackTimeContext from 'containers/VideoEditor/VideoEditorPlaybackTimeContext';
import { EditorVideoFramePreview } from 'containers/VideoFramePreview';
import { DeepImmutableMap, Size } from 'types';
import { block } from './utils';

export type TextOverlay = DeepImmutableMap<ITextOverlay & { id: string }>;

export interface LegacyEditTextModalProps {
  background?: React.ReactNode;
  onDelete?: (id: string) => void;
  onDuplicate?: (overlay: TextOverlay) => TextOverlay;
  onExited?: () => void;
  onHide?: () => void;
  onSave?: (overlay: TextOverlay) => void;
  onSwitchLegacyMode: (partialOverlay?: ModalTextOverlay) => void;
  textOverlay?: TextOverlay;
  aspectRatio?: number;
  show?: boolean;
  inputDisabled?: boolean;
}

interface IState {
  textOverlay: TextOverlay;
  workspaceSize: Size<number>;
}

export default class LegacyEditTextModal extends React.Component<
  LegacyEditTextModalProps,
  IState
> {
  public static defaultProps: Partial<LegacyEditTextModalProps> = {
    inputDisabled: false,
    onDuplicate: () => undefined,
    onDelete: noop,
    onHide: noop,
    onSave: noop,
    show: false,
  };

  private closing: boolean = false;

  public state: Readonly<IState> = {
    textOverlay: this.props.textOverlay,
    workspaceSize: undefined,
  };

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { textOverlay: nextOverlay } = nextProps;
    const { show, textOverlay } = this.props;

    /**
     * `textOverlay` is needed to render the modal contents.  when the modal is dismissed, redux
     * state is cleared and `textOverlay` will be undefined.  when the modal is dismissed, there
     * is a transition where the modal fades up towards the top of the screen.
     *
     * if we just don't render `TextOverlayModal` when `textOverlay` is undefined, then we unmount
     * the modal component before it has time to fully transition out - and instead of a nice
     * transition the modal just vanishes.
     *
     * to prevent this, check if we had a `textOverlay` that is now gone.  If that's the case
     * don't clear the modal from state so that we don't unmount the modal component and everything
     * transitions out smoothly.
     */

    if (!show && !this.closing) {
      const nextOverlayId = nextOverlay && nextOverlay.get('id');
      const textOverlayId = textOverlay && textOverlay.get('id');
      if (nextOverlayId !== textOverlayId) {
        this.setState(() => ({ textOverlay: nextOverlay }));
      }
    }
  }

  private handleHide = () => {
    const { onHide } = this.props;
    this.closing = true;
    onHide();
  };

  private handleExited = () => {
    const { onExited } = this.props;
    this.setState({ textOverlay: undefined });
    this.closing = false;
    onExited();
  };

  private handleSetWorkspaceSize = (newSize: Size<number>) => {
    this.setState({ workspaceSize: newSize });
  };

  private handleSave = (textOverlay: TextOverlay) => {
    const { onSave } = this.props;
    onSave(textOverlay);
  };

  private handleDelete = () => {
    const { onDelete, textOverlay } = this.props;
    onDelete(textOverlay.get('id'));
  };

  private handleDuplicate = () => {
    const { onDuplicate, textOverlay } = this.props;
    const { setPositionSec } = this.context;

    const newTextOverlay = onDuplicate(textOverlay);

    if (newTextOverlay.size > 0) {
      setPositionSec(newTextOverlay.getIn(['time', 'startMillis']) / 1000);
    }
    this.handleHide();
  };

  private renderCancelButton() {
    return (
      <Modal.FooterButton onClick={this.handleHide}>Cancel</Modal.FooterButton>
    );
  }

  private renderDeleteButton() {
    return (
      <Modal.FooterButton onClick={this.handleDelete} theme="delete">
        Delete
      </Modal.FooterButton>
    );
  }

  private renderSaveButton() {
    return (
      <Modal.FooterButton onClick={this.handleSave as any} theme="submit">
        Save
      </Modal.FooterButton>
    );
  }

  private renderDuplicateButton = () => {
    return (
      <Modal.FooterButton
        onClick={this.handleDuplicate as any}
        theme="submit-alt"
      >
        Duplicate
      </Modal.FooterButton>
    );
  };

  public render() {
    const { inputDisabled, show, aspectRatio, onSwitchLegacyMode } = this.props;
    const { textOverlay, workspaceSize } = this.state;

    return (
      <TextOverlayModal
        background={
          <EditorVideoFramePreview
            aspectRatio={aspectRatio}
            canvasDimensions={workspaceSize}
            backgroundFor={{
              id: textOverlay?.get('id'),
              type: 'textOverlayInfo',
            }}
          />
        }
        onWorkspaceSizeChange={this.handleSetWorkspaceSize}
        cancelButton={this.renderCancelButton()}
        deleteButton={this.renderDeleteButton()}
        footerLeftSlot={({ partialSubmitHandlerBuilder }) => (
          <div className={block('new-text-modal-banner')}>
            <SwitchEditorModalBanner
              onSwitchMode={partialSubmitHandlerBuilder(onSwitchLegacyMode)}
            />
          </div>
        )}
        inputDisabled={inputDisabled}
        onExited={this.handleExited}
        onHide={this.handleHide}
        shouldDisableSubmitIfEmpty
        show={show}
        submitButton={this.renderSaveButton()}
        textOverlay={textOverlay as any}
        title="Text Properties"
        duplicateButton={this.renderDuplicateButton()}
      />
    );
  }
}

LegacyEditTextModal.contextType = VideoEditorPlaybackTimeContext;
