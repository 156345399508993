import * as React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Font, VideoCamera, Wave } from 'components/icons';
import TileSelector from 'components/TileSelector';
import { tracksByIdSelector } from 'redux/modules/embed/selectors';
import { AddableTrackType } from './types';

const hasWaveformTrackSelector = createSelector(
  tracksByIdSelector,
  tracksById =>
    tracksById.find(track => track.get('type') === 'waveform') !== undefined,
);

export interface TrackSelectorProps {
  onChange?: (trackType: AddableTrackType) => void;
  onClick?: (trackType: AddableTrackType) => void;
  trackType: AddableTrackType;
}

const TrackSelector: React.SFC<TrackSelectorProps> = ({
  onChange,
  onClick,
  trackType,
}) => {
  const hasWaveformTrack = useSelector(hasWaveformTrackSelector);

  return (
    <TileSelector
      onChange={onChange}
      onClick={onClick}
      options={[
        { id: 'text', icon: <Font /> },
        { id: 'media', icon: <VideoCamera /> },
        {
          disabled: hasWaveformTrack,
          icon: <Wave />,
          id: 'waveform',
          tooltip: !hasWaveformTrack
            ? undefined
            : 'Only one waveform track can be added',
        },
      ]}
      value={trackType}
    />
  );
};

export default TrackSelector;
