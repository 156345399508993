import React from 'react';
import { useDispatch } from 'react-redux';
import { AutogramWizardStepIndex } from 'blocks/AutomationWorkflowWizard';
import { PodcastSubscription } from 'redux/middleware/api/podcast-service';

import { hideModal } from 'redux/modules/modal';
import { autogramWizardLocation } from 'utils/routes';

const useEditVisuals = (subscription: PodcastSubscription) => {
  const dispatch = useDispatch();

  const editAspectRatioLink = autogramWizardLocation({
    initialStep: AutogramWizardStepIndex.TEMPLATE,
    subStep: 'aspect-ratio',
    podcastId: subscription?.podcastId,
    workflowId: subscription?.autoCreateSubscriptionId,
  });
  const editTemplateLink = autogramWizardLocation({
    initialStep: AutogramWizardStepIndex.TEMPLATE,
    subStep: 'template-selection',
    podcastId: subscription?.podcastId,
    workflowId: subscription?.autoCreateSubscriptionId,
  });

  const onEditAspectRatio = React.useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  const onEditTemplate = React.useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  return {
    editAspectRatioLink,
    editTemplateLink,
    onEditAspectRatio,
    onEditTemplate,
  };
};

export default useEditVisuals;
