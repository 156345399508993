import { Heading, Link, Metrics } from '@sparemin/blockhead';
import * as React from 'react';

import { useDispatch } from 'react-redux';
import { Alert } from 'components/icons';
import Infobox from 'components/Infobox';
import { onDownloadPageDashboardLinkClick } from 'redux/modules/mixpanel';
import { DASHBOARD_URL } from 'utils/constants';
import { block } from './utils';

export interface LinkedinPostInfoBoxProps {
  isOrganizationProfile: boolean;
}

const LinkedinPostInfoBox: React.FC<LinkedinPostInfoBoxProps> = ({
  isOrganizationProfile,
}) => {
  const dispatch = useDispatch();

  const handleDashboardLinkClick = React.useCallback((): void => {
    dispatch(onDownloadPageDashboardLinkClick('LinkedInModal'));
  }, [dispatch]);

  if (isOrganizationProfile) {
    return (
      <Infobox
        icon={<Metrics width={40} height={40} fill="#fff" />}
        theme="purple"
      >
        <Heading level={4}>
          You’ll be able to see how this post performs in your{' '}
          <Link
            href={DASHBOARD_URL}
            target="__blank"
            onClick={handleDashboardLinkClick}
          >
            Headliner dashboard
          </Link>{' '}
          :)
        </Heading>
      </Infobox>
    );
  }

  return (
    <Infobox
      icon={<Alert fill="#fff" background="transparent" />}
      theme="purple"
      className={block('info-box-personal')}
    >
      <Heading level={4}>
        LinkedIn doesn’t share data for posts to personal feeds, so you will not
        be <br /> able to see how this post performs in your{' '}
        <Link
          href={DASHBOARD_URL}
          target="__blank"
          onClick={handleDashboardLinkClick}
        >
          Headliner dashboard.
        </Link>
      </Heading>
    </Infobox>
  );
};

export default LinkedinPostInfoBox;
