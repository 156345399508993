import classNames from 'classnames';
import * as React from 'react';

import Button from 'components/Button';
import FontAwesome from 'components/FontAwesome';
import { block } from './utils';

interface IProps {
  className?: string;
  onClickAddMedia: () => void;
}

export const MediaAssetsForm: React.FC<IProps> = ({
  className,
  onClickAddMedia,
}) => {
  const containerClassName = classNames(block(), className);

  return (
    <>
      <div className={containerClassName}>
        <span className={block('label')}>Media</span>

        <div className={block('buttons')}>
          <Button size="small" theme="submit" onClick={onClickAddMedia}>
            <FontAwesome
              icon="plus"
              className={block('button-add-media-icon')}
            />
            Add media
          </Button>
        </div>
      </div>
    </>
  );
};

export default MediaAssetsForm;
export { IProps as MediaAssetsFormProps };
