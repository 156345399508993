import * as React from 'react';

import WaveSurfer, { WaveSurferContainer } from 'components/WaveSurfer';
import { Omit } from 'types';
import { block } from '../utils';
import ClippableTrackAsset, {
  ClippableTrackAssetProps,
} from './ClippableTrackAsset';

type InheritedProps = Omit<
  ClippableTrackAssetProps,
  'itemClassName' | 'position'
>;

export interface AudioAssetProps extends InheritedProps {
  height: number;
  onReady?: () => void;
  position: ClippableTrackAssetProps['position']['x'];
  src: string;
}

const AudioAsset: React.SFC<AudioAssetProps> = ({
  fullDurationWidth,
  height,
  id,
  onReady,
  playFromOffset,
  position,
  src,
  ...props
}) => (
  <ClippableTrackAsset
    {...props}
    fullDurationWidth={fullDurationWidth}
    id={id}
    itemClassName={block('audio-asset')}
    playFromOffset={playFromOffset}
    position={{ x: position, y: 0 }}
  >
    <div className={block('audio-asset-inner')}>
      <div
        className={block('audio-ws-container')}
        style={{
          left: -playFromOffset,
          position: 'relative',
          width: fullDurationWidth,
        }}
      >
        <WaveSurfer
          src={src}
          onReady={onReady}
          options={{
            barHeight: 4,
            barWidth: 1,
            cursorWidth: 0,
            height: Math.floor(height),
            hideScrollbar: true,
            interact: false,
            normalize: true,
            waveColor: '#dce1eb',
            plugins: [],
          }}
          width={fullDurationWidth}
        >
          <WaveSurferContainer />
        </WaveSurfer>
      </div>
    </div>
  </ClippableTrackAsset>
);

export default AudioAsset;
