import * as React from 'react';

import { IconProps } from 'components/icons/types';

const Template: React.FC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="29"
    viewBox="0 0 30 29"
    {...props}
  >
    <path
      d="M16.953 1.78c.623 0 1.127.498 1.127 1.111 0 .614-.504 1.11-1.127 1.11H3.36c-.609 0-1.106.496-1.106 1.112v19.991c0 .616.497 1.11 1.106 1.11h20.069c.608 0 1.105-.494 1.105-1.11V15.097c0-.613.505-1.11 1.127-1.11.623 0 1.127.497 1.127 1.11v10.007c0 1.838-1.502 3.332-3.36 3.332H3.36c-1.858 0-3.36-1.494-3.36-3.332V5.113C0 3.275 1.502 1.78 3.36 1.78zm-5.875 10.27c0-.58.633-.806 1.046-.572l5.088 2.88a.65.65 0 0 1 0 1.143l-5.088 2.878c-.458.26-1.046-.052-1.046-.571zM28.088.435C29.143.436 30 1.292 30 2.349v8.666a.638.638 0 0 1-1.125.411L26.31 8.39a.638.638 0 0 0-.97-.004l-2.637 3.068a.638.638 0 0 1-1.121-.416V2.35c0-1.057.856-1.913 1.913-1.913z"
      fill="#8CA0C3"
      fillRule="nonzero"
    />
  </svg>
);

export default Template;
