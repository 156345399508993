import * as React from 'react';
import PixabayLink from './PixabayLink';

interface IProps {
  className?: string;
  mediaType: string;
}

const PixabayDisclaimer: React.SFC<IProps> = ({ className, mediaType }) => (
  <span className={className}>
    {' '}
    These {mediaType}s come from <PixabayLink />.{' '}
  </span>
);

export default PixabayDisclaimer;
