import React from 'react';

import { scaleInlineStyles } from 'utils/embed/text-overlay';
import { ViewportWidth } from 'utils/measurement';

import Layer from '../../layers/Layer';
import { TextOverlay, ToPx } from '../../types';
import { editorModalPreviewBlock as block } from '../../utils';
import { getTextBoundingBoxStyle } from './utils';

interface EditorModalTextLayerProps {
  canvasSize: { height: number; width: number };
  textOverlay: TextOverlay;
}

const EditorModalTextLayer: React.FunctionComponent<EditorModalTextLayerProps> = props => {
  const { canvasSize, textOverlay } = props;

  const toPx: ToPx = React.useCallback(
    value => {
      return value?.toUnit('px', canvasSize);
    },
    [canvasSize],
  );

  const textboxStyle = React.useMemo(
    () => getTextBoundingBoxStyle(textOverlay, toPx),
    [textOverlay, toPx],
  );

  return (
    <Layer className={block('text-layer')}>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: scaleInlineStyles(
            textOverlay.textHtml,
            ['fontSize'],
            val => `${toPx(new ViewportWidth(val))?.value}px`,
          ),
        }}
        key={textOverlay.id}
        style={textboxStyle}
      />
    </Layer>
  );
};

export default EditorModalTextLayer;
