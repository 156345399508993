import * as React from 'react';
import _ from 'underscore';

import LoadingOverlay from 'components/LoadingOverlay';
import VideoFormWithPreview, {
  dataFactory,
  VideoFormWithPreviewProps,
  VideoScaling,
} from 'components/VideoFormWithPreview';

export interface IProps extends VideoFormWithPreviewProps {
  isSubmitting?: boolean;
  formRef?: (el: VideoFormWithPreview) => void;
  onUnmount?: () => void;
  onUpgradeClick?: () => void;
}

export default class AddVideoForm extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    isSubmitting: false,
    onUnmount: _.noop,
    onUpgradeClick: _.noop,
  };

  private form: VideoFormWithPreview;

  public componentWillUnmount() {
    const { onUnmount } = this.props;
    onUnmount();
  }

  public get values() {
    return this.form.values;
  }

  public render() {
    const {
      durationMillis,
      isSubmitting,
      showTranscriptionSection,
      ...formProps
    } = this.props;

    return (
      <div className="add-media-modal__container">
        {isSubmitting && (
          <LoadingOverlay
            title="Adding video to project"
            description="This may take a while, so please keep your browser open and check back later"
          />
        )}
        {!isSubmitting && !durationMillis && (
          <LoadingOverlay title="Loading Video" />
        )}
        <VideoFormWithPreview
          {...formProps}
          durationMillis={durationMillis}
          previewColClassName="add-media-modal__preview-col"
          showTranscriptionSection={showTranscriptionSection}
          showTransformOptions={false}
          showClipper
          ref={el => (this.form = el)}
        />
      </div>
    );
  }
}

export { VideoScaling, dataFactory };
