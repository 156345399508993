import cn from 'classnames';
import * as React from 'react';
import { isString } from 'underscore';

import Button, { ButtonProps } from 'components/Button';
import ProIndicator, { ProIndicatorOverlay } from 'containers/ProIndicator';
import bem from 'utils/bem';

const block = bem('pro-indicator-button');

interface IProps extends ButtonProps {
  children?: React.ReactNode;
  onUpgrade: () => void;
  pro?: boolean;
}

const ProIndicatorButton: React.SFC<IProps> = ({
  children,
  className,
  disabled,
  onUpgrade,
  pro,
  theme,
  ...buttonProps
}) => {
  const handleUpgrade = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onUpgrade();
    },
    [onUpgrade],
  );

  const renderButton = (isFree = false, badge = null) => (
    <Button
      {...buttonProps}
      className={cn(block({ free: isFree }), className)}
      disabled={disabled || isFree}
      theme={isFree ? undefined : theme}
    >
      <span className={block('text')}>{children}</span>
      {badge}
    </Button>
  );

  return pro ? (
    <ProIndicator className={block('badge')}>
      {({ isFree, badge }) => (
        <div className={block('container')}>
          {renderButton(isFree, badge)}
          {isFree && (
            <ProIndicatorOverlay
              from={isString(children) ? children : ''}
              onClick={handleUpgrade}
            />
          )}
        </div>
      )}
    </ProIndicator>
  ) : (
    renderButton()
  );
};

ProIndicatorButton.defaultProps = {
  pro: true,
};

export default ProIndicatorButton;
export { IProps as ProIndicatorButtonProps };
