import * as React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import PodcastConnectedModalContent from './PodcastConnectedModalContent';

const PodcastConnectedModal = () => {
  return (
    <ConnectedModal name="PodcastConnected">
      <PodcastConnectedModalContent />
    </ConnectedModal>
  );
};

export default PodcastConnectedModal;
