import React from 'react';

import { SAVE_TEMPLATE_KNOWLEDGE_BASE_URL } from 'utils/constants';
import { UserGeneratedEmptyCTA } from '../types';
import { blockUserGenerated } from '../utils';

export interface UserGeneratedEmptyDescriptionProps {
  cta: UserGeneratedEmptyCTA;
}

const UserGeneratedEmptyDescription: React.FC<UserGeneratedEmptyDescriptionProps> = ({
  cta,
}) => {
  return (
    <span>
      Any designs you save will appear here as starting points for future videos
      {cta === 'learnMore' ? (
        <>
          :{' '}
          <a
            className={blockUserGenerated('learn-more')}
            href={SAVE_TEMPLATE_KNOWLEDGE_BASE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            learn more
          </a>
        </>
      ) : (
        '.'
      )}
    </span>
  );
};

export default UserGeneratedEmptyDescription;
