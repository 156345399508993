import * as React from 'react';

import { IconProps } from './types';

const InifintyGradient: React.SFC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 21" {...props}>
    <path
      fill="url(#infinity-gradient)"
      fillRule="evenodd"
      d="M33.655 8c-3.836 0-7.305 2.017-10.32 5.337l-.835.921-.836-.92C18.65 10.016 15.181 8 11.344 8 5.078 8 0 12.73 0 18.5S5.077 29 11.345 29c3.836 0 7.305-2.017 10.32-5.337l.835-.921.836.92C26.35 26.984 29.819 29 33.656 29 39.922 29 45 24.27 45 18.5S39.923 8 33.655 8zm-22.31 16.263c-3.558 0-6.508-2.546-6.508-5.763s2.95-5.763 6.508-5.763c2.857 0 5.565 2.063 7.842 5.096l.5.667-.5.667c-2.268 3.017-4.995 5.096-7.842 5.096zm22.31 0c-2.857 0-5.565-2.063-7.842-5.096l-.5-.667.5-.667c2.268-3.017 4.995-5.096 7.842-5.096 3.558 0 6.508 2.546 6.508 5.763s-2.95 5.763-6.508 5.763z"
      transform="translate(0 -8)"
    />
  </svg>
);

export default InifintyGradient;
