import { Circle } from 'rc-progress';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ids from 'short-id';
import usePrevious from 'hooks/usePrevious';

import { block } from './utils';

interface UseProgressCirclesConfig {
  playing: boolean;
  percentage: number;
}

interface CircleKeys {
  active: string;
  inactive: string | undefined;
}

const baseCircleProps = {
  prefixCls: block('progress'),
  strokeWidth: 4,
  strokeColor: 'white',
  trailColor: 'transparent',
};

export default function useProgressCircles({
  percentage,
  playing,
}: UseProgressCirclesConfig) {
  const [{ active, inactive }, setKeys] = useState<CircleKeys>({
    active: ids.generate(),
    inactive: undefined,
  });
  const timeoutId = useRef<number>();
  const prevPlaying = usePrevious(playing);

  useLayoutEffect(() => {
    if (
      ((!prevPlaying && playing) || (playing && percentage >= 99)) &&
      !inactive
    ) {
      setKeys({
        active: ids.generate(),
        inactive: active,
      });
      timeoutId.current = window.setTimeout(() => {
        setKeys(ks => ({
          ...ks,
          inactive: undefined,
        }));
        timeoutId.current = undefined;
      }, 600);
    }
  }, [active, inactive, percentage, playing, prevPlaying]);

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        window.clearTimeout(timeoutId.current);
        timeoutId.current = undefined;
      }
    };
  }, []);

  return [
    active && (
      <Circle
        {...baseCircleProps}
        className={block('progress')}
        percent={percentage}
        key={active}
      />
    ),
    inactive && (
      <Circle
        {...baseCircleProps}
        className={block('progress', { vanishing: true })}
        percent={100}
        key={inactive}
      />
    ),
  ].filter(Boolean);
}
