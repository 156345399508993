import { Map } from 'immutable';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import _ from 'underscore';

import AudioWaveImage from 'blocks/AudioWaveImage';
import AudioWaveOptions from 'blocks/AudioWaveOptions';
import Modal from 'components/Modal';
import ScrollBars from 'components/ScrollBars';
import { EditorVideoFramePreview } from 'containers/VideoFramePreview';
import WaveformPresets from 'containers/WaveformPresets';
import WaveformPresetsHeader from 'containers/WaveformPresetsHeader';
import usePrevious from 'hooks/usePrevious';
import { defaultWaveformPrefOnlyEnabledSelector } from 'redux/modules/display-pref/selectors';
import {
  isCircleWaveType,
  isCustomWaveType,
} from 'redux/modules/display-pref/utils';
import { aspectRatioSelector } from 'redux/modules/embed/selectors';
import {
  onDeleteWaveformPreset,
  onSaveWaveformPreset,
} from 'redux/modules/mixpanel';
import { Size, Soundwave, SoundwaveType } from 'types';
import { soundwavePositionToDimensions } from 'utils/embed/soundwave';
import AudioTrackInfobox from './AudioTrackInfobox';
import { WaveformModalValue } from './types';
import { block } from './utils';

const { useCallback, useEffect, useMemo } = React;

export interface WaveformModalContentsProps {
  footer?: React.ReactNode;
  onChange?: (value: WaveformModalValue) => void;
  value: WaveformModalValue;
}

const WaveformModalContents: React.FC<WaveformModalContentsProps> = ({
  footer,
  onChange = _.noop,
  value,
}) => {
  const dispatch = useDispatch();
  const aspectRatio = useSelector(aspectRatioSelector);
  const locked = useSelector(defaultWaveformPrefOnlyEnabledSelector);
  const [workspaceSize, setWorkspaceSize] = React.useState<Size<number>>();

  const prevWaveType = usePrevious(value.waveType);

  const handlePositionChange = useCallback(
    ({ top, left }) => {
      onChange({
        ...value,
        wavePosition: { left, top },
        waveformPrefId: null,
      });
    },
    [onChange, value],
  );

  const handleDimensionsChange = useCallback(
    ({ height, left, top, width }) => {
      onChange({
        ...value,
        wavePosition: { left, top },
        waveSize: { height, width },
        waveformPrefId: null,
      });
    },
    [onChange, value],
  );

  const handleDimensionsChangeByKeyword = useMemo(
    () =>
      compose(
        handleDimensionsChange,
        _.partial(
          soundwavePositionToDimensions,
          _,
          aspectRatio,
          value.waveType,
        ),
      ),
    [aspectRatio, handleDimensionsChange, value.waveType],
  );

  const handleColorChange = useCallback(
    (waveColor: string) => {
      onChange({
        ...value,
        waveColor,
        waveformPrefId: null,
      });
    },
    [onChange, value],
  );

  const handleWaveTypeChange = useCallback(
    (waveType: SoundwaveType) => {
      onChange({
        ...value,
        waveType,
        waveformPrefId: null,
      });
    },
    [onChange, value],
  );

  useEffect(() => {
    if (
      prevWaveType === undefined ||
      value.waveType === undefined ||
      prevWaveType === value.waveType ||
      !!value.waveformPrefId
    ) {
      return;
    }

    const isCircle = isCircleWaveType(value.waveType);
    const isPrevCircle = isCircleWaveType(prevWaveType);
    const isCustom = isCustomWaveType(value.waveType);
    const isPrevCustom = isCustomWaveType(prevWaveType);

    if (!isPrevCircle && isCircle) {
      handleDimensionsChangeByKeyword('middle');
      return;
    }

    // reset wavePosition to top when changing between different types of waveforms
    if (isCircle !== isPrevCircle || isCustom !== isPrevCustom) {
      handleDimensionsChangeByKeyword('middle');
    }
  }, [
    handleDimensionsChangeByKeyword,
    prevWaveType,
    value.waveType,
    value.waveformPrefId,
  ]);

  const handlePresetSaved = (preset: Soundwave) => {
    dispatch(onSaveWaveformPreset('Editor', aspectRatio));
    onChange(preset);
  };

  const handlePresetDeleted = () => {
    dispatch(onDeleteWaveformPreset('Editor', aspectRatio));
  };

  return (
    <>
      <Modal.Body>
        <Modal.Row>
          <Modal.Col className={block('preview-col')} size={6}>
            <AudioWaveImage
              aspectRatio={aspectRatio}
              background={
                <EditorVideoFramePreview
                  aspectRatio={aspectRatio}
                  canvasDimensions={workspaceSize}
                  backgroundFor={{
                    type: 'soundwave',
                  }}
                />
              }
              onWavePositionChange={handlePositionChange}
              onWaveSizeChange={handleDimensionsChange}
              onWorkspaceSizeChange={setWorkspaceSize}
              waveColor={value.waveColor}
              wavePosition={Map(value.wavePosition) as any}
              waveSize={Map(value.waveSize) as any}
              waveType={value.waveType}
            />
          </Modal.Col>
          <Modal.Col className={block('controls-col')} size={6}>
            {locked ? (
              <section className={block('presets')}>
                <AudioTrackInfobox />
                <WaveformPresets
                  onSelect={onChange}
                  selectedWaveformPrefId={value?.waveformPrefId}
                  aspectRatio={aspectRatio}
                />
              </section>
            ) : (
              <ScrollBars className={block('scrollbar')}>
                <div className={block('controls')}>
                  <AudioTrackInfobox />
                  <AudioWaveOptions
                    className={block('form')}
                    color={value.waveColor}
                    onColorChange={handleColorChange}
                    onPositionChange={handleDimensionsChangeByKeyword}
                    onTypeChange={handleWaveTypeChange}
                    position={{ ...value.wavePosition, ...value.waveSize }}
                    showAudioFadeControls={false}
                    showWaveGenerationField={false}
                    type={value.waveType}
                  />
                </div>
                <section className={block('presets')}>
                  <hr className={block('separator')} />
                  <WaveformPresetsHeader
                    getSoundwaveToSave={() => value}
                    onSaved={handlePresetSaved}
                    aspectRatio={aspectRatio}
                    disabled={
                      !value.waveType ||
                      value.waveType === 'none' ||
                      !!value.waveformPrefId
                    }
                  />
                  <WaveformPresets
                    onSelect={onChange}
                    onDeleted={handlePresetDeleted}
                    selectedWaveformPrefId={value?.waveformPrefId}
                    aspectRatio={aspectRatio}
                  />
                </section>
              </ScrollBars>
            )}
          </Modal.Col>
        </Modal.Row>
      </Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </>
  );
};

export default WaveformModalContents;
