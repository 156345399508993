import { connect } from 'react-redux';

import { planEndSecSelector } from 'redux/modules/pricing';
import { State } from 'redux/types';
import { PropsOf } from 'types';
import ProTrialSubscription, {
  ProTrialSubscriptionProps as Props,
} from './ProTrialSubscription';

type StateProps = Pick<Props, 'expirationSeconds'>;

const mapStateToProps = (state: State): StateProps => ({
  expirationSeconds: planEndSecSelector(state),
});

const component = connect(mapStateToProps)(ProTrialSubscription);
export type ProTrialSubscriptionProps = PropsOf<typeof component>;
export default component;
