import cn from 'classnames';
import React from 'react';

import { block } from './utils';

export interface PlanSelectorPlanListProps {
  children?: React.ReactNode;
  className?: string;
}

const PlanSelectorPlanList: React.FC<PlanSelectorPlanListProps> = ({
  children,
  className,
}) => <div className={cn(block('plan-list'), className)}>{children}</div>;

export default PlanSelectorPlanList;
