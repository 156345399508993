import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import useRedirectOnClose from 'hooks/useRedirectOnClose';
import ManualAudiogramModalContents from './ManualAudiogramModalContents';

export type ManualAudiogramModalProps = {};

const ManualAudiogramModal: React.FC<ManualAudiogramModalProps> = () => {
  const { onExited } = useRedirectOnClose();

  return (
    <ConnectedModal name="ManualAudiogram" onExited={onExited} v2>
      <ManualAudiogramModalContents />
    </ConnectedModal>
  );
};

export default ManualAudiogramModal;
