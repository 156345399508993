import React from 'react';
import { noop } from 'underscore';

import ModalFooterButton from 'components/Modal/ModalFooterButton';
import { Handle } from 'components/SocialAccountInfo';
import { Step } from 'components/SteppedModal';
import SwitchAccountMessage from 'components/SwitchAccountMessage';
import useYouTubeAuthenticator, {
  UseYouTubeAuthenticatorConfig,
} from 'hooks/useYouTubeAuthenticator';
import useYoutubePostModalSelector from '../useYoutubePostModalSelector';

export interface UseSwitchAccountStepConfig
  extends Partial<Pick<UseYouTubeAuthenticatorConfig, 'onError'>>,
    Pick<Step, 'title'> {
  onSwitchAccountSuccess?: () => void;
}

export default function useSwitchAccountStep(
  config: UseSwitchAccountStepConfig = {},
): Step {
  const {
    onError = noop,
    onSwitchAccountSuccess = noop,
    title = 'switch youtube accounts',
  } = config;

  const { username } = useYoutubePostModalSelector();

  const { authenticating, authenticate } = useYouTubeAuthenticator({
    onError,
    onAuthSuccess: onSwitchAccountSuccess,
    force: true,
  });

  return {
    title,
    component: (
      <SwitchAccountMessage username={<Handle username={username} />} />
    ),
    id: 'switch',
    renderFooterButtons: ({ cancel, submit }) => [
      <ModalFooterButton {...cancel} key="cancel">
        cancel
      </ModalFooterButton>,
      <ModalFooterButton
        {...submit}
        disabled={authenticating}
        key="submit"
        onClick={authenticate}
        theme="submit"
      >
        switch accounts
      </ModalFooterButton>,
    ],
  };
}
