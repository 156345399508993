import cn from 'classnames';
import * as React from 'react';

import { block } from '../utils';

export interface AssetsPaneContentProps {
  children?: React.ReactNode;
  className?: string;
}

const AssetsPaneContent: React.SFC<AssetsPaneContentProps> = ({
  children,
  className,
}) => (
  <div className={cn(block('content-container'), className)}>{children}</div>
);

export default AssetsPaneContent;
