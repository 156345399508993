import React, { useLayoutEffect, useState } from 'react';
import { matchPath, RouteChildrenProps, RouteProps } from 'react-router';

type DisplayType = 'none' | 'block';

interface TogglerComponentProps extends RouteChildrenProps {
  style: {
    display: DisplayType;
  };
}

export interface RouteTogglerProps
  extends RouteChildrenProps<{}, { reload?: boolean }> {
  children: React.ReactElement<TogglerComponentProps>;
  keepMountedRoute: string | string[] | RouteProps;
}

const RouteToggler: React.FC<RouteTogglerProps> = ({
  children,
  keepMountedRoute,
  ...routeProps
}) => {
  const { location, match } = routeProps;
  const [mounted, setMounted] = useState(false);

  const keepMountedMatch = matchPath(location.pathname, keepMountedRoute);

  useLayoutEffect(() => {
    if (match) {
      setMounted(true);
    } else if (!keepMountedMatch) {
      setMounted(false);
    }
  }, [keepMountedMatch, match]);

  const hidden = mounted && !!keepMountedMatch;

  return (
    (mounted || match) &&
    React.cloneElement(children, {
      ...routeProps,
      key:
        location.state && location.state.reload ? location.pathname : undefined,
      style: {
        display: hidden ? 'none' : 'block',
      },
    })
  );
};

export default RouteToggler;
