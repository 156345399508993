import * as React from 'react';
import { noop } from 'underscore';

import Modal, { ModalProps } from 'components/Modal';
import { block } from './utils';

type PickedModalProps = Pick<ModalProps, 'onExited' | 'onHide' | 'show'>;

export interface WaveformModalProps extends PickedModalProps {
  children?: React.ReactNode;
}

const WaveformModal: React.FC<WaveformModalProps> = ({
  children,
  onExited = noop,
  onHide = noop,
  show = false,
}) => (
  <Modal className={block()} title="Waveform" {...{ show, onExited, onHide }}>
    {children}
  </Modal>
);

export default WaveformModal;
