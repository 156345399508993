import classNames from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { TemplateType } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { TemplateItem } from '../../types';
import { blockProjectTemplateItems as block } from '../../utils';
import HeadlinerDefault from './HeadlinerDefault';
import UserGenerated from './UserGenerated';

interface IProps {
  domRef?: React.Ref<HTMLDivElement>;
  id: string;
  onClickDeleteTemplate?: (templateId: string) => void;
  onClickTemplate?: (template: TemplateItem) => void;
  template: TemplateItem;
}

export default class ProjectTemplateItem extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onClickDeleteTemplate: noop,
    onClickTemplate: noop,
  };

  private getRatioName = () => {
    const { template } = this.props;
    const { height, width } = template.dimension;

    return getAspectRatioName(height, width);
  };

  private handleClickTemplate = () => {
    const { onClickTemplate, template } = this.props;

    onClickTemplate(template);
  };

  private handleClickDeleteTemplate = (__, event: MouseEvent) => {
    event.stopPropagation();
    const { onClickDeleteTemplate, template } = this.props;

    onClickDeleteTemplate(template.id);
  };

  private getClass() {
    const ratioName = this.getRatioName();
    const commonClass = block('item', {
      [ratioName]: true,
    });

    return classNames(commonClass, block('item'));
  }

  public render() {
    const { domRef, template } = this.props;
    const { type } = template;

    if (type === TemplateType.HEADLINER_DEFAULT) {
      return (
        <HeadlinerDefault
          className={this.getClass()}
          domRef={domRef}
          template={template}
          onClickTemplate={this.handleClickTemplate}
        />
      );
    }

    if (type === TemplateType.USER_GENERATED) {
      return (
        <UserGenerated
          className={this.getClass()}
          template={template}
          onClickDeleteButton={this.handleClickDeleteTemplate}
          onClickTemplate={this.handleClickTemplate}
          ref={domRef}
        />
      );
    }

    return null;
  }
}

export { IProps as ProjectTemplateItemProps };
