import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { has } from 'underscore';
import { AudiogramDestination } from 'blocks/AudiogramDestinations/types';
import { SubscriptionItem } from 'redux/middleware/api/podcast-service';
import { showNotification } from 'redux/modules/notification';
import {
  facebookPagesSelector,
  getFacebookPages,
  getInstagramAccounts as getInstagramUserAccounts,
  getOrganizations,
  getTikTokCreatorInfo,
  getYoutubeChannels,
  instagramAccountsSelector,
  linkedinOrganizationsSelector,
  threadsUserNameSelector,
  tiktokUserNameSelector,
  twitterUsernameSelector,
  waitForThreadsUser,
  waitForTiktokProfile,
  waitForTwitterProfile,
  youtubeChannelSelector,
} from 'redux/modules/social';
import { HandleGetInfoErrorArgs } from './types';
import { mapYoutubeChannelName } from './utils';

export interface UseGetUserInfo {
  displayName: string;
}

export default function useGetUserInfo(
  subscriptionItem: SubscriptionItem,
): UseGetUserInfo {
  const dispatch = useDispatch();
  const youtubeChannelData = useSelector(youtubeChannelSelector);
  const youtubeChannelName = mapYoutubeChannelName(youtubeChannelData);
  const tikTokAccountName = useSelector(tiktokUserNameSelector);
  const instagramAccounts = useSelector(instagramAccountsSelector);
  const organizations = useSelector(linkedinOrganizationsSelector);
  const facebookPages = useSelector(facebookPagesSelector);
  const twitterUserName = useSelector(twitterUsernameSelector);
  const theadsUserName = useSelector(threadsUserNameSelector);

  const platform = useMemo(
    (): AudiogramDestination =>
      subscriptionItem?.autoPostVideoPreference?.platform,
    [subscriptionItem],
  );

  const handleGetInfoError = useCallback(
    ({ title, message }: HandleGetInfoErrorArgs): void => {
      dispatch(
        showNotification({
          message,
          level: 'error',
          dismissAfterSec: 5,
          code: 'ER013',
          title,
          type: 'help',
          actionLabel: 'contact us',
        }),
      );
    },
    [dispatch],
  );

  const getYoutubeChannelName = useCallback(async (): Promise<void> => {
    try {
      await dispatch(
        getYoutubeChannels(
          subscriptionItem?.autoPostVideoPreference.options.googleId,
        ),
      );
    } catch {
      handleGetInfoError({
        title: "You won't be able to see your output YouTube channel right now",
        message: "Sorry, we couldn't load the YouTube channel name",
      });
    }
  }, [dispatch, handleGetInfoError, subscriptionItem]);

  const getTikTokAccountName = useCallback(async (): Promise<void> => {
    try {
      const tikTokUserId =
        subscriptionItem?.autoPostVideoPreference.options.tiktokUserId;

      await dispatch(waitForTiktokProfile(tikTokUserId));
      await dispatch(getTikTokCreatorInfo(tikTokUserId));
    } catch {
      handleGetInfoError({
        title: "You won't be able to see your output TitTok right now",
        message: "Sorry, we couldn't load the TikTok account name",
      });
    }
  }, [dispatch, handleGetInfoError, subscriptionItem]);

  const getInstagramAccounts = useCallback(async (): Promise<void> => {
    const instagramUserId =
      subscriptionItem?.autoPostVideoPreference.options.facebookId;

    try {
      await dispatch(getInstagramUserAccounts(instagramUserId));
    } catch {
      handleGetInfoError({
        title: "You won't be able to see your output Instagram right now",
        message: "Sorry, we couldn't load the Instagram account name",
      });
    }
  }, [dispatch, handleGetInfoError, subscriptionItem]);

  const getLinkedInOrganizations = useCallback(async (): Promise<void> => {
    try {
      const linkedinUserId =
        subscriptionItem?.autoPostVideoPreference.options.linkedinUserId;

      await dispatch(getOrganizations(linkedinUserId));
    } catch {
      handleGetInfoError({
        title: "You won't be able to see your output LinkedIn right now",
        message: "Sorry, we couldn't load the LinkedIn account name",
      });
    }
  }, [dispatch, handleGetInfoError, subscriptionItem]);

  const getFacebookAccounts = useCallback(async (): Promise<void> => {
    const facebookId =
      subscriptionItem?.autoPostVideoPreference.options.facebookId;

    try {
      await dispatch(getFacebookPages(facebookId));
    } catch {
      handleGetInfoError({
        title: "You won't be able to see your output Facebook right now",
        message: "Sorry, we couldn't load the Facebook account name",
      });
    }
  }, [dispatch, handleGetInfoError, subscriptionItem]);

  const getTwitterUserName = useCallback(async (): Promise<void> => {
    try {
      const twitterUserId =
        subscriptionItem?.autoPostVideoPreference.options.twitterUserId;

      await dispatch(waitForTwitterProfile(twitterUserId));
    } catch {
      handleGetInfoError({
        title: "You won't be able to see your output X right now",
        message: "Sorry, we couldn't load the X user name",
      });
    }
  }, [dispatch, handleGetInfoError, subscriptionItem]);

  const getThreadsUserName = useCallback(async (): Promise<void> => {
    try {
      const threadsUserId =
        subscriptionItem?.autoPostVideoPreference.options.threadsUserId;

      await dispatch(waitForThreadsUser(threadsUserId));
    } catch {
      handleGetInfoError({
        title: "You won't be able to see your output Threads right now",
        message: "Sorry, we couldn't load the Threads user name",
      });
    }
  }, [dispatch, handleGetInfoError, subscriptionItem]);

  const getInfoByPlatorm = useMemo((): Partial<
    Record<AudiogramDestination, () => void>
  > => {
    return {
      youtube: getYoutubeChannelName,
      tiktok: getTikTokAccountName,
      instagram: getInstagramAccounts,
      linkedin: getLinkedInOrganizations,
      facebook: getFacebookAccounts,
      twitter: getTwitterUserName,
      threads: getThreadsUserName,
    };
  }, [
    getFacebookAccounts,
    getInstagramAccounts,
    getLinkedInOrganizations,
    getThreadsUserName,
    getTikTokAccountName,
    getTwitterUserName,
    getYoutubeChannelName,
  ]);

  const displayNameByPlatform = useMemo((): Partial<
    Record<AudiogramDestination, string>
  > => {
    return {
      youtube: youtubeChannelName,
      tiktok: tikTokAccountName,
      instagram: instagramAccounts.find(
        account =>
          account.instagramId ===
          subscriptionItem?.autoPostVideoPreference.options.instagramUserId,
      )?.username,
      linkedin: organizations[0]?.name,
      facebook: facebookPages.find(
        page =>
          page.pageId ===
          subscriptionItem?.autoPostVideoPreference.options.facebookPageId,
      )?.name,
      twitter: twitterUserName,
      threads: theadsUserName,
    };
  }, [
    facebookPages,
    instagramAccounts,
    organizations,
    subscriptionItem,
    theadsUserName,
    tikTokAccountName,
    twitterUserName,
    youtubeChannelName,
  ]);

  useEffect(() => {
    if (has(getInfoByPlatorm, platform)) {
      getInfoByPlatorm[platform]();
    }
  }, [getInfoByPlatorm, platform]);

  return {
    displayName: displayNameByPlatform?.[platform] || '',
  };
}
