import { Season } from 'redux/modules/automation-cms/types';

// The seasons list have the first element as a 'null' season which only contains
// the sum of all episodes without seasons. This function removes this element from the list
// so we can have the real seasons list without any unwanted values.
export function getNormalizedSeasonsList(seasons: Season[]): number[] {
  if (!seasons) {
    return [];
  }

  return seasons.map(({ season }) => season).filter(Boolean);
}
