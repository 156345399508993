import { useState } from 'react';

import { PlanCancellationReason } from 'types';
import { Props } from './types';
import useCancellationDetailsModalDispatch from './useCancellationReasonModalDispatch';

export default (props: Props) => {
  const { reason: initialReason } = props;
  const [reason, onReasonChange] = useState<PlanCancellationReason>(
    initialReason,
  );

  const { onDeleteAccount, onSubmit } = useCancellationDetailsModalDispatch();

  const handleSubmit = () => {
    onSubmit(reason);
  };

  return { reason, onReasonChange, onDeleteAccount, onSubmit: handleSubmit };
};
