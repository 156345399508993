import * as React from 'react';

import { ColorField, FormField, ToggleField } from 'components/Form';
import { block } from '../../utils';

const { useCallback } = React;

export interface TimerFieldValue {
  enabled: boolean;
  color: string;
}

export interface TimerFieldProps {
  value: TimerFieldValue;
  onChange: (value: TimerFieldValue) => void;
}

const TimerField: React.FC<TimerFieldProps> = ({ onChange, value }) => {
  const handleEnabledChange = useCallback(
    (enabled: boolean) => {
      onChange({
        ...value,
        enabled,
      });
    },
    [onChange, value],
  );

  const handleColorChange = useCallback(
    (color: string) => {
      onChange({
        ...value,
        color,
      });
    },
    [onChange, value],
  );

  return (
    <FormField
      className={block('timer-field')}
      controlClassName={block('timer-field-control')}
      label="Timer"
      horizontal={{ label: { xs: 6 }, control: { xs: 6 } }}
    >
      <div className={block('timer-sub-fields')}>
        <ToggleField
          checked={value.enabled}
          className={block('timer-enabled-field')}
          horizontal={{ control: { xs: 12 }, label: { xs: 0 } }}
          onChange={handleEnabledChange}
        />
        <ColorField
          className={block('timer-color-field')}
          disabled={!value.enabled}
          onChange={handleColorChange}
          value={value.color}
        />
      </div>
    </FormField>
  );
};

export default TimerField;
