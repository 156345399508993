import React from 'react';

import { PodcastSearchConfigContext } from '../types';

interface PodcastSearchConfigContextProviderProps {
  usePlanEpisodeMaxDuration?: boolean;
}

const Context = React.createContext<PodcastSearchConfigContext>({
  usePlanEpisodeMaxDuration: false,
});

export const PodcastSearchConfigContextProvider: React.FunctionComponent<PodcastSearchConfigContextProviderProps> = props => {
  const { children, usePlanEpisodeMaxDuration = false } = props;

  return (
    <Context.Provider
      value={React.useMemo(
        () => ({
          usePlanEpisodeMaxDuration,
        }),
        [usePlanEpisodeMaxDuration],
      )}
    >
      {children}
    </Context.Provider>
  );
};

export const usePodcastSearchConfig = () => {
  const podcastSearchConfigContext = React.useContext(Context);

  if (podcastSearchConfigContext === undefined) {
    throw new Error(
      'usePodcastSearchConfig must be used within a PodcastSearchConfigContextProvider',
    );
  }

  return podcastSearchConfigContext;
};
