import { PostStatus } from '../constants';
import { Type } from './action-types';
import {
  InstagramAction,
  InstagramState,
  instagramStateFactory,
} from './types';

const defaultState = instagramStateFactory();

export default function(
  state: InstagramState = defaultState,
  action: InstagramAction,
) {
  switch (action.type) {
    case Type.INSTAGRAM_AUTHORIZE_SUCCESS: {
      return state.merge(action.payload);
    }

    case Type.INSTAGRAM_USER_DATA_GET_SUCCESS: {
      const { profileImageUrl, username } = action.payload;

      return state
        .set('username', username)
        .set('profileImageUrl', profileImageUrl);
    }

    case Type.INSTAGRAM_ACCOUNTS_GET_REQUEST: {
      return state.set('accountsLoading', true);
    }

    case Type.INSTAGRAM_ACCOUNTS_GET_SUCCESS: {
      return state
        .set('accounts', action.payload)
        .set('accountsLoading', false);
    }

    case Type.INSTAGRAM_ACCOUNTS_GET_FAILURE: {
      return state.set('accountsLoading', false);
    }

    case Type.INSTAGRAM_ACCOUNTS_CLEAR:
      return instagramStateFactory();

    case Type.INSTAGRAM_USER_ID_SELECT: {
      return state.set('instagramUserId', action.payload);
    }

    case Type.INSTAGRAM_VIDEO_POST_REQUEST:
      return state.set('postStatus', PostStatus.IN_PROGRESS);

    case Type.INSTAGRAM_VIDEO_POST_SUCCESS: {
      const { socialShareId } = action.payload;

      return state.set('socialShareId', socialShareId);
    }

    case Type.INSTAGRAM_VIDEO_POST_AWAIT_SUCCESS:
      return state.set('postStatus', PostStatus.SUCCESS);

    case Type.INSTAGRAM_VIDEO_POST_FAILURE:
    case Type.INSTAGRAM_VIDEO_POST_AWAIT_FAILURE:
      return state.set('postStatus', PostStatus.FAILURE);

    case Type.INSTAGRAM_ACCOUNT_INFO_REQUEST:
      return state
        .set('accountInfoLoading', true)
        .set('accountInfo', undefined);

    case Type.INSTAGRAM_ACCOUNT_INFO_SUCCESS: {
      const accountInfo = action.payload;

      return state
        .set('accountInfoLoading', false)
        .set('accountInfo', accountInfo);
    }

    case Type.INSTAGRAM_ACCOUNT_INFO_FAILURE:
      return state.set('accountInfoLoading', false);

    default:
      return state;
  }
}
