import { Action, State as ReduxState } from '../../types';
import createActions, { createActionTypes } from './actions';
import { UploadStatus } from './constants';
import createReducer, { stateFactory } from './reducer';
import createSelectors from './selectors';
import { EntireAudioState, WrappedState } from './types';

export default function<
  S extends EntireAudioState,
  A extends Action<string, any>
>(name: string, stateSelector?: (state: ReduxState) => WrappedState<S>) {
  const actionTypes = createActionTypes(name);
  const selectors = createSelectors(stateSelector || name);
  const actions = createActions(actionTypes, selectors);

  return {
    actions,
    selectors,
    createReducer(
      originalReducer: (state: WrappedState<S>, action: A) => WrappedState<S>,
      defaultState: WrappedState<S>,
    ) {
      return createReducer(originalReducer, defaultState, actionTypes);
    },
  };
}

export { stateFactory, UploadStatus };
export { default as createActions } from './actions';
export { default as createReducer } from './reducer';
export { default as createSelectors } from './selectors';
export { EntireAudioState } from './types';
export { isStatusResolved } from './utils';
