import * as React from 'react';
import { noop } from 'underscore';

import { SelectField, SelectFieldProps } from 'components/Form';
import { Omit, SlideEffect } from 'types';
import SlideOptionsField from './SlideOptionsField';

interface IProps extends Omit<SelectFieldProps, 'label' | 'onChange'> {
  onChange?: (
    value: SlideEffect,
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
}

const SlideEffectField: React.SFC<IProps> = ({ className, ...props }) => (
  <SlideOptionsField className={className}>
    <SelectField {...props} label="Effect">
      <option value="none">None</option>
      <option value="kenburns">Zoom</option>
      <option value="pan">Pan</option>
      <option value="panLeft">Pan Left</option>
      <option value="panRight">Pan Right</option>
      <option value="panUp">Pan Up</option>
      <option value="panDown">Pan Down</option>
      <option value="rotatingCube">Rotating Cube</option>
    </SelectField>
  </SlideOptionsField>
);

SlideEffectField.defaultProps = {
  onChange: noop,
};

export default SlideEffectField;
export { SlideEffect, IProps as SlideEffectFieldProps };
