import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import ClipCtaButton, {
  ClipCtaButtonProps,
} from 'blocks/ClipSelect/components/ClipCtaButton';
import { Dislike } from 'components/icons';
import { Popper, PopperRadio, PopperTrigger } from 'components/Popper';

import { dislikeSuggestedClip } from 'redux/modules/clip-select/actions';
import { showError, showSuccess } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { ClipSuggestionDislikeReason } from 'types/common';
import { selectorBlock as block } from '../../utils';
import { useClipSelect } from '../ClipSelectContext';
import { useClipSelectNavigation } from '../ClipSelectNavigationContext/ClipSelectNavigationContext';
import { useClipSelectTracking } from '../ClipSelectTrackingContext';

export interface DislikeClipButtonProps
  extends Pick<ClipCtaButtonProps, 'disabled'> {
  suggestionId?: number;
}

const DislikeClipButton = (props: DislikeClipButtonProps) => {
  const { disabled, suggestionId } = props;
  const dispatch = useDispatch<Dispatch>();
  const [open, setOpen] = useState(false);
  const { onDislikeClip } = useClipSelectTracking();
  const [, send] = useClipSelectNavigation();
  const { visibleSuggestionIds } = useClipSelect();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleButtonClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleOptionSelect = useCallback(
    async value => {
      handleClose();
      onDislikeClip(value);
      send({
        type: 'DISLIKE_CLIP',
        payload: {
          suggestionCount: visibleSuggestionIds.length - 1,
        },
      });

      try {
        await dispatch(
          dislikeSuggestedClip(
            suggestionId,
            value as ClipSuggestionDislikeReason,
          ),
        );
        dispatch(
          showSuccess({
            dismissAfterSec: 5,
            message: "We'll suggest less clips like that in the future",
            title: 'Thanks for your feedback',
          }),
        );
      } catch (error) {
        dispatch(
          showError({
            message: 'There was an error saving your feedback',
            dismissAfterSec: 5,
          }),
        );
      }
    },
    [
      dispatch,
      handleClose,
      onDislikeClip,
      send,
      suggestionId,
      visibleSuggestionIds.length,
    ],
  );

  return (
    <div className={block('dislike-container')}>
      <ClipCtaButton
        disabled={disabled || !suggestionId}
        icon={<Dislike style={{ height: 16, width: 'auto' }} />}
        onClick={handleButtonClick}
        theme="cancel"
      >
        dislike
      </ClipCtaButton>
      <PopperTrigger open={open} onClose={handleClose}>
        <Popper className={block('dislike-popper')}>
          <PopperRadio
            onChange={handleOptionSelect}
            options={[
              {
                label: "it's an advertisement",
                value: ClipSuggestionDislikeReason.Advertisement,
              },
              {
                label: "it's not interesting",
                value: ClipSuggestionDislikeReason.NotInteresting,
              },
              {
                label: 'it cuts off mid sentence',
                value: ClipSuggestionDislikeReason.CutsOffMidSentence,
              },
              {
                label: 'other reason',
                value: ClipSuggestionDislikeReason.Other,
              },
            ]}
            value={undefined}
          />
        </Popper>
      </PopperTrigger>
    </div>
  );
};

export default DislikeClipButton;
