import React from 'react';
import { useDispatch } from 'react-redux';

import { TextOverlay } from 'blocks/TextOverlayModal';
import { TextOverlayV2 } from 'blocks/TextOverlayModal/v2';
import { useTextOverlaySwitch } from 'blocks/TextOverlaySwitchModal';
import { useAddAssetModal } from 'containers/ConnectedModal/useAddAssetModal';
import { pushModal } from 'redux/modules/modal';

type ActiveModal = 'legacy' | 'current' | 'none';

interface UseAddTextModalSwitcher {
  activeModal: ActiveModal;
  onExited: () => void;
  onHide: () => void;
  onToggleLegacyMode: (partialOverlay?: TextOverlay | TextOverlayV2) => void;
  transitionOverlay: TextOverlay | TextOverlayV2;
}

const useAddTextModalSwitcher = (): UseAddTextModalSwitcher => {
  const dispatch = useDispatch();

  const { onHide, onExited, show } = useAddAssetModal('AddTextModal');

  const handleOpen = React.useCallback(() => {
    dispatch(pushModal({ name: 'AddTextModal' }));
  }, [dispatch]);

  const {
    activeModal,
    onToggleLegacyMode,
    transitionOverlay,
  } = useTextOverlaySwitch({
    onHide,
    onOpen: handleOpen,
    show,
  });

  return {
    activeModal,
    onExited,
    onHide,
    onToggleLegacyMode,
    transitionOverlay,
  };
};

export default useAddTextModalSwitcher;
