import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createEmbedPreviewUrl } from 'redux/middleware/api/embed-service';

import { GetSubscriptionPreviewResult } from 'redux/middleware/api/podcast-service';
import { fetchRecordingById } from 'redux/middleware/api/recording-service/actions';
import { tokenSelector } from 'redux/modules/auth';
import { recordingsSelector } from 'redux/modules/entities';
import { onGoBackToEditing } from 'redux/modules/mixpanel';
import { showNotification } from 'redux/modules/notification';

import AutogramPreviewStep, {
  AutogramPreviewStepProps,
} from './AutogramPreviewStep';

interface AutogramPreviewStepContainerProps
  extends Omit<
    AutogramPreviewStepProps,
    'embedUrl' | 'onRemoveWatermarks' | 'showWatermarkPopover'
  > {
  subscriptionPreviewId?: number;
  transitionActive?: boolean;
  subscriptionPreviewConfig?: GetSubscriptionPreviewResult;
  onCancel: () => void;
}

const AutogramPreviewStepContainer: React.FunctionComponent<AutogramPreviewStepContainerProps> = props => {
  const {
    onGoBack,
    subscriptionPreviewId,
    transitionActive,
    subscriptionPreviewConfig,
    onCancel,
    ...restProps
  } = props;

  const dispatch = useDispatch();
  const recordings = useSelector(recordingsSelector);
  const token = useSelector(tokenSelector);

  const recordingId = subscriptionPreviewConfig?.recordingId;

  React.useEffect(() => {
    // Each time the recording Id is updated, it is required to reload fetch the recording
    // using that ID for being able to perform the audio playback.
    if (recordingId) {
      dispatch(fetchRecordingById(recordingId));
    }
  }, [dispatch, recordingId]);

  const embedUrl = subscriptionPreviewConfig
    ? createEmbedPreviewUrl({
        recordingId: subscriptionPreviewConfig.recordingId,
        token,
        widgetId: subscriptionPreviewConfig?.episodeConfigs[0]?.widgetId,
      })
    : undefined;

  const recordingUrl = recordings?.get(String(recordingId))?.toJS()
    ?.recordingUrl;

  const handleGoBack = React.useCallback((): void => {
    dispatch(onGoBackToEditing());
    onGoBack();
  }, [dispatch, onGoBack]);

  if (subscriptionPreviewConfig?.episodeConfigs?.length === 0) {
    dispatch(
      showNotification({
        message: 'No episodes found. Please contact support',
        level: 'error',
      }),
    );

    onCancel();
  }

  return (
    <AutogramPreviewStep
      {...restProps}
      embedUrl={embedUrl}
      onGoBack={handleGoBack}
      recordingUrl={recordingUrl}
    />
  );
};

export default AutogramPreviewStepContainer;
