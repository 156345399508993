import cn from 'classnames';
import React from 'react';

import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import FontAwesome from 'components/FontAwesome';
import { H1 } from 'components/Heading';
import Link from 'components/Link';
import { onClickGoToVideo } from 'redux/modules/mixpanel';
import { getDownloadPath } from 'utils/routes';
import { block } from './utils';

const ButtonAsAny = Button as any;

export interface VideoReadyBlockProps {
  className?: string;
  widgetId?: string;
}

const VideoReadyBlock: React.FC<VideoReadyBlockProps> = ({
  className,
  widgetId,
}) => {
  const dispatch = useDispatch();

  return (
    <div className={cn(block('ready-block'), className)}>
      <H1 className={block('ready-heading')}>Your video is ready!</H1>
      <ButtonAsAny
        className={block('share-button')}
        componentClass={Link}
        fluid
        onClick={() => dispatch(onClickGoToVideo)}
        shape="chip"
        theme="submit"
        to={getDownloadPath(widgetId)}
      >
        <FontAwesome
          icon="share"
          style={{ marginRight: 8, width: 18, height: 'auto' }}
        />
        go to video
      </ButtonAsAny>
    </div>
  );
};

export default VideoReadyBlock;
