import cn from 'classnames';
import * as React from 'react';

import ImageButton from 'components/ImageButton';
import InfoTip from 'components/InfoTip';
import { IImmutableMap } from 'types';
import bem from 'utils/bem';

interface BaseTemplate {
  containerDecorator?: any;
}

export type Template<T extends BaseTemplate = any> = IImmutableMap<T>;

interface ITemplateData<T extends BaseTemplate = any> {
  id: string;
  displayName?: string;
  imageUrl: string;
  template?: Template<T>;
  templateId?: string;
}

interface IProps<T extends BaseTemplate = any> {
  className?: string;
  onSelect?: (data: ITemplateData<T>) => void;
  templates: Array<ITemplateData<T>>;
}

const block = bem('text-template-selector');

function TextTemplateSelector<T extends BaseTemplate = any>({
  className,
  onSelect,
  templates,
}: IProps<T>) {
  return (
    <div className={cn(block(), className)}>
      {templates.map(data => {
        const { id, displayName, imageUrl, template } = data;
        return (
          <ImageButton<ITemplateData<T>>
            className={block('option')}
            displayName={displayName}
            hoverElement={
              template.get('containerDecorator') && (
                <InfoTip infoText="Only the text will show in the left preview window." />
              )
            }
            imageClassName={block('img')}
            key={id}
            onClick={onSelect}
            params={data}
            src={imageUrl}
          />
        );
      })}
    </div>
  );
}

export default TextTemplateSelector;
export { IProps as TextTemplateSelectorProps };
