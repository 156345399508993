import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { deleteFavoritePodcast } from 'redux/modules/podcast-search';
import { deleteProjectById } from 'redux/modules/project/actions';

export interface UseAutomationCMSModalsActionsConfig {
  hideModal: () => void;
  podcastId?: string;
}

export default function useAutomationCMSModalsActions({
  hideModal,
  podcastId,
}: UseAutomationCMSModalsActionsConfig) {
  const dispatch = useDispatch();

  const deletePodcastFeed = useCallback(() => {
    dispatch(deleteFavoritePodcast(podcastId));
    hideModal();
  }, [dispatch, hideModal, podcastId]);

  const deleteProject = useCallback(
    (id: string) => {
      dispatch(deleteProjectById(id));
      hideModal();
    },
    [dispatch, hideModal],
  );

  return { deletePodcastFeed, deleteProject };
}
