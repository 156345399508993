import * as React from 'react';

interface IWaveProps {
  duration?: number;
}

const Wave: React.SFC<IWaveProps> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 3200 198"
    className="wave"
  >
    <defs>
      <linearGradient
        id="a"
        x1="281.425"
        x2="281.425"
        y1="-61.684"
        y2="562.849"
        gradientTransform="matrix(-2.84267 0 0 .35178 1600 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23282d" stopOpacity=".49" offset="0%" />
        <stop stopColor="#646e82" offset="100%" />
      </linearGradient>
    </defs>
    <path
      d="M789 0C389 0 289 121 0 121v77H3199.998s-.01-48-.01-77c-310.99-.002-409.89-121.25-810.99-121-400 0-500 121-789 121C1288.999 121 1190.102-.25 789 0z"
      fill="url(#a)"
    />
  </svg>
);

export default Wave;
