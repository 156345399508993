import * as React from 'react';
import { useSelector } from 'react-redux';

import { createSelector } from 'reselect';
import { podcastResultsSelector } from 'redux/modules/podcast-search/selectors';

import PodcastExplorerBody, {
  PodcastExplorerBodyProps,
} from './containers/PodcastExplorerBody';
import { PodcastSearchIntegration } from './types';

type PickedIntegrationProps = Pick<PodcastSearchIntegration, 'priority'>;
type PickedBodyProps = Pick<
  PodcastExplorerBodyProps,
  'isProcessingEpisode' | 'onAudioAdded' | 'onEpisodeClick'
>;

export interface UseFavoriteIntegrationConfig
  extends PickedIntegrationProps,
    PickedBodyProps {
  maxFileSizeMb?: number;
}

const resultCountSelector = createSelector(
  podcastResultsSelector,
  results => results && results.size,
);

/**
 * Technical podcast search integration hook, responsible for displaying the favorite podcast.
 * Can only be activated programmatically (see the `searchFavoritePodcast` action creator).
 */
export default function useFavoriteIntegration({
  isProcessingEpisode,
  maxFileSizeMb = spareminConfig.episodeMaxAudioFileSizeMb,
  onAudioAdded,
  onEpisodeClick,
  priority,
}: UseFavoriteIntegrationConfig): PodcastSearchIntegration<'favorite'> {
  const resultCount = useSelector(resultCountSelector);
  const episodeExplorerEnabled = true;

  return {
    hidden: true,
    priority,
    resultCount,
    canHandle: () => false,
    color: null,
    description: null,
    icon: null,
    id: 'favorite',
    onSubmit: async () => null,
    placeholder: null,
    results: height => (
      <PodcastExplorerBody
        {...{
          height,
          isProcessingEpisode,
          maxFileSizeMb,
          onAudioAdded,
          episodeExplorerEnabled,
          onEpisodeClick,
        }}
      />
    ),
    tip: null,
    episodeExplorerEnabled,
  };
}
