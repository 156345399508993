import * as React from 'react';

import { block } from './utils';

interface IProps {
  limit: number;
  count?: number;
}

const CharacterCount: React.SFC<IProps> = ({ count, limit }) => (
  <span className={block('counter', { danger: count > limit })}>
    {count}/{limit} characters
  </span>
);

CharacterCount.defaultProps = {
  count: 0,
};

export default CharacterCount;
export { IProps as CharacterCountProps };
