import * as React from 'react';
import { OuterProps } from 'react-window';

import ScrollBars from 'components/ScrollBars';

export interface SearchResultListScrollBarsProps extends OuterProps {
  forwardedRef?: React.Ref<ScrollBars>;
}

const SearchResultListScrollBars = React.forwardRef<
  ScrollBars,
  SearchResultListScrollBarsProps
>(({ style, ...props }, ref) => (
  <ScrollBars {...props} ref={ref} style={{ ...style, overflow: 'hidden' }} />
));

export default SearchResultListScrollBars;
