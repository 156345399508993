import cn from 'classnames';
import * as React from 'react';
import Button, { ButtonProps } from 'components/Button';
import { block } from './utils';

interface Props extends ButtonProps {
  icon?: React.ReactNode;
}

export const SearchbarButton: React.FC<Props> = props => {
  const { className, children, icon, ...rest } = props;
  return (
    <Button
      type="submit"
      {...rest}
      className={cn(block('button'), className)}
      theme="submit"
      shape="square"
      size="small"
    >
      {icon}
      {children && <span>{children}</span>}
    </Button>
  );
};

SearchbarButton.displayName = 'Searchbar.Button';

export default SearchbarButton;
