import * as React from 'react';

import { IImmutableMap } from 'types';
import VideoExportNotificationSpinner from './VideoExportNotificationSpinner';

export interface VideoExportNotificationProps {
  exportStatusMessage?: string;
  videoExportStatus: IImmutableMap<{
    widgetId?: string;
    status?: string;
    progress?: number;
    maxProgress?: number;
  }>;
  videoUrl?: string;
}

// TODO a lot of overlap with form
export default class VideoExportNotification extends React.Component<
  VideoExportNotificationProps
> {
  private notification: HTMLDivElement;

  public componentDidMount() {
    this.setParentHeight();
  }

  private setParentHeight() {
    if (!this.notification) return;

    const rect = this.notification.getBoundingClientRect();
    const parent = this.notification.parentElement;

    parent.style.height = `${rect.height}px`;
  }

  private renderProgressBar() {
    const { videoExportStatus } = this.props;

    return (
      <progress
        className="export-alert__progress-bar"
        value={videoExportStatus.get('progress')}
        max={videoExportStatus.get('maxProgress')}
      />
    );
  }

  private renderContents() {
    const { exportStatusMessage, videoExportStatus } = this.props;

    return (
      <div className="export-alert__progress">
        <span className="export-alert__title">{exportStatusMessage}</span>
        {videoExportStatus.get('progress') !== undefined ? (
          this.renderProgressBar()
        ) : (
          <VideoExportNotificationSpinner />
        )}
        <span className="export-alert__description">
          Please keep this tab open, exporting usually takes a few minutes.
        </span>
      </div>
    );
  }

  public render() {
    const className = 'export-alert export-alert--default';

    const ref = el => {
      this.notification = el;
    };

    return (
      <div className={className} ref={ref}>
        {this.renderContents()}
      </div>
    );
  }
}
