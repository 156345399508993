import React from 'react';
import { useSelector } from 'react-redux';
import { Link, LinkProps } from 'react-router-dom';
import { noop } from 'underscore';
import UseAuthModal from 'blocks/Authentication/containers/UseAuthModal';
import { Headliner, HeadlinerMark } from 'components/icons';
import useMedia from 'hooks/useMedia';
import { loggedInSelector } from 'redux/modules/auth/selectors';
import { block } from '../utils';

type Props = Pick<LinkProps, 'onClick' | 'to' | 'className'> & {
  disabled?: boolean;
  mark?: boolean;
};

function HeadlinerLogoLink(props: Props) {
  const { onClick, to, disabled, mark = false } = props;
  const isLoggedIn = useSelector(loggedInSelector);
  const isWide = useMedia('(min-width: 1046px)');
  const logo = isWide && !mark ? <Headliner /> : <HeadlinerMark />;
  const className = block('logo', { disabled });

  if (isLoggedIn) {
    return (
      <Link onClick={onClick} className={className} to={to}>
        {logo}
      </Link>
    );
  }

  return (
    <UseAuthModal>
      {({ withAuthentication }) => (
        <button
          className={className}
          disabled={disabled}
          onClick={withAuthentication(noop)}
          type="button"
        >
          {logo}
        </button>
      )}
    </UseAuthModal>
  );
}

export default HeadlinerLogoLink;
