import * as React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

function List(props: IProps) {
  const { color = 'currentColor' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14" {...props}>
      <path
        fill={color}
        d="M.571 2.571H15.43A.571.571 0 0016 2V.571A.571.571 0 0015.429 0H.57A.571.571 0 000 .571V2a.57.57 0 00.571.571zm0 5.715H15.43a.571.571 0 00.57-.572V6.286a.571.571 0 00-.571-.572H.57a.571.571 0 00-.57.572v1.428c0 .316.256.572.571.572zm0 5.714H15.43a.571.571 0 00.571-.571V12a.571.571 0 00-.571-.571H.57A.571.571 0 000 12v1.429c0 .315.256.571.571.571z"
      />
    </svg>
  );
}

export default List;
