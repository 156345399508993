import * as React from 'react';

import { IconProps } from './types';

const VideoCameraAlt: React.SFC<IconProps> = ({
  fill = 'currentColor',
  strokeWidth,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93 102" {...props}>
    <path
      fill={fill}
      fillRule="nonzero"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M61.746 41.504c5.163 0 9.277 4.435 9.277 9.82v3.569l12.115-6.303c3.726-1.925 8.815.053 8.815 3.403V85.88c0 3.363-5.107 5.328-8.815 3.403l-12.116-6.294.001 7.782c0 5.386-4.114 9.82-9.277 9.82H11.23c-5.163 0-9.278-4.434-9.278-9.82V51.324c0-5.385 4.115-9.82 9.278-9.82h50.515zm0 6.565H11.23c-1.536 0-2.852 1.42-2.852 3.255V90.77c0 1.836 1.316 3.256 2.852 3.256h50.515c1.536 0 2.852-1.42 2.852-3.256V51.324c0-1.836-1.316-3.255-2.852-3.255zm23.988 6.425l-14.712 7.652.003 13.59 14.71 7.642V54.494zM53.101 1.41c8.745 0 15.83 7.129 15.83 15.827S61.845 33.063 53.1 33.063c-5.353 0-10.193-3.04-14.4-8.045l-1.166-1.388-1.166 1.388c-4.207 5.005-9.047 8.045-14.4 8.045-8.745 0-15.83-7.129-15.83-15.827S13.225 1.409 21.97 1.409c5.353 0 10.193 3.04 14.4 8.045l1.166 1.388L38.7 9.454c4.207-5.005 9.047-8.045 14.4-8.045zM21.969 8.55c-4.963 0-9.08 3.839-9.08 8.687 0 4.848 4.117 8.687 9.08 8.687 3.974 0 7.778-3.134 10.942-7.68l.7-1.006-.7-1.006c-3.177-4.572-6.955-7.682-10.942-7.682zm31.132 0c-3.974 0-7.778 3.134-10.942 7.68l-.7 1.006.7 1.006c3.177 4.572 6.955 7.682 10.942 7.682 4.963 0 9.08-3.839 9.08-8.687 0-4.848-4.117-8.687-9.08-8.687z"
    />
  </svg>
);

VideoCameraAlt.defaultProps = {
  strokeWidth: 1,
};

export default VideoCameraAlt;
