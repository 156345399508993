import { diffArrays } from 'diff';
import { fromJS, List, Set } from 'immutable';
import { isUndefined, pick, property } from 'underscore';

import { getValue } from 'utils/collections';
import { interpolate, parseChunk, splitString } from './core';
import simpleRechunk from './simple-rechunker';
import textFitRechunk from './text-fit-rechunker';
import twoLimitRechunk from './two-limit-rechunker';

/**
 *
 * @param {import('redux/middleware/api/transcript-service').RecordingTranscript} automated
 * @returns {import('redux/middleware/api/transcript-service').ManualTranscript}
 */
export function convertAutoTranscriptToManual(automated) {
  const { transcript, ...restTranscript } = automated;

  return {
    ...restTranscript,
    transcript: transcript.map(({ text, words, ...restChunk }) => ({
      ...restChunk,
      subchunks: [
        {
          ...pick(restChunk, 'startMillis', 'endMillis'),
          text,
          words,
        },
      ],
    })),
  };
}

/**
 * @param {Immutable.List} chunks immutable array of immutable chunks
 * @param {string|Immutable.Set} phraseIds ids of the subchunks to delete
 */
export function deleteSubchunk(chunks, phraseIds) {
  if (!chunks) return undefined;
  if (!phraseIds) return chunks;

  const deletePhraseIds = Set.isSet(phraseIds) ? phraseIds : Set([phraseIds]);

  return chunks.reduce((acc, chunk) => {
    const updatedSubchunks = chunk
      .get('subchunks')
      .filter(sc => !deletePhraseIds.includes(sc.get('id')));
    if (updatedSubchunks.size === 0) return acc;
    const updatedChunk = chunk.withMutations(c =>
      c
        .set('subchunks', updatedSubchunks)
        .set(
          'startMillis',
          updatedSubchunks.map(s => s.get('startMillis')).min(),
        )
        .set('endMillis', updatedSubchunks.map(s => s.get('endMillis')).max()),
    );
    return acc.push(updatedChunk);
  }, List());
}

/**
 * @param {Immutable.List} chunks immutable array of immutable chunks
 * @param {number} startMillis time new subchunk starts
 * @param {number} endMillis time new subchunk ends
 * @param {string} text text for new subchunk
 */
export function addSubchunk(chunks, startMillis, endMillis, text) {
  if (!chunks) return undefined;
  if ([startMillis, endMillis, text].some(isUndefined)) return chunks;

  const subchunk = fromJS({
    startMillis,
    endMillis,
    text,
    words: interpolate(splitString(text), startMillis, endMillis),
  });

  function insertAsSubchunk(chunk) {
    return chunk.update('subchunks', subchunks => {
      // find the index after which to insert the new subchunk
      const insertAfterIdx = subchunks.findIndex((sc, idx) => {
        const nextSubchunk = subchunks.get(idx + 1);

        if (!nextSubchunk) {
          return true;
        }

        return (
          startMillis >= sc.get('endMillis') &&
          endMillis < nextSubchunk.get('endMillis')
        );
      });
      return subchunks.insert(insertAfterIdx + 1, subchunk);
    });
  }

  chunks.forEach((chunk, index) => {
    if (
      startMillis >= chunk.get('startMillis') &&
      endMillis <= chunk.get('endMillis')
    ) {
      chunks = chunks.update(index, insertAsSubchunk);
      return false;
    }

    if (
      startMillis >= chunk.get('endMillis') &&
      (index === chunks.size - 1 ||
        startMillis <= chunks.getIn([index + 1, 'startMillis']))
    ) {
      chunks = chunks.insert(
        index + 1,
        fromJS({
          startMillis,
          endMillis,
          subchunks: [subchunk],
        }),
      );
      return false;
    }

    return true;
  });

  return chunks;
}

function hasTimecodes(chunkLike) {
  return (
    chunkLike.startMillis !== undefined && chunkLike.endMillis !== undefined
  );
}

/**
 * @param {import('redux/middleware/api/transcript-service/types').ISubChunk} subchunk
 * @param {string} newText
 * @returns {import('redux/middleware/api/transcript-service/types').ISubChunk}
 */
export function updateSubchunkText(subchunk, newText) {
  const { words: currentWords } = subchunk;
  const currentWordStrings = subchunk.words.map(property('text'));
  const diff = diffArrays(currentWordStrings, splitString(newText));

  const newWordsArray = diff.reduce(
    (acc, { added, count, removed, value }) => {
      // word remains the same
      if (!added && !removed) {
        acc.data.push(...currentWords.slice(acc.index, acc.index + count));
        acc.index += count;
        return acc;
      }

      //  word added.  add a partial object with no timestamps
      if (added) {
        acc.data.push(...value.map(v => ({ text: v })));
        return acc;
      }

      // word deleted. advance index that tracks the source array by count,
      // effectively skipping the removed words
      acc.index += count;
      return acc;
    },
    { data: [], index: 0 },
  ).data;

  // interpolate all unknown word timestamps
  let index = 0;
  while (index < newWordsArray.length) {
    const word = newWordsArray[index];

    if (hasTimecodes(word)) {
      // if timecodes exist, continue
      index += 1;
    } else {
      // find all adjacent words with unknown timestamps
      const wordStringsWithoutTimes = [];
      for (
        let i = index;
        i < newWordsArray.length && !hasTimecodes(newWordsArray[i]);
        i += 1
      ) {
        wordStringsWithoutTimes.push(newWordsArray[i].text);
      }

      const interpolatedWords = interpolate(
        wordStringsWithoutTimes,
        getValue(newWordsArray[index - 1], ['endMillis'], subchunk.startMillis),
        getValue(
          newWordsArray[index + wordStringsWithoutTimes.length],
          ['startMillis'],
          subchunk.endMillis,
        ),
      );

      newWordsArray.splice(
        index,
        interpolatedWords.length,
        ...interpolatedWords,
      );

      index += wordStringsWithoutTimes.length;
    }
  }

  return {
    ...subchunk,
    text: newText,
    words: newWordsArray,
  };
}

export function shiftChunks(chunks, offsetMillis) {
  if (isUndefined(chunks)) return undefined;
  if (isUndefined(offsetMillis)) return chunks;

  const shiftChunkLike = chunkLike =>
    chunkLike
      .update('startMillis', startMillis =>
        Math.max(startMillis + offsetMillis, 0),
      )
      .update('endMillis', endMillis => Math.max(endMillis + offsetMillis, 0));

  return chunks.map(chunk =>
    chunk.withMutations(c => {
      shiftChunkLike(c);
      c.update('subchunks', subchunks =>
        subchunks.map(subchunk =>
          subchunk.withMutations(sc => {
            shiftChunkLike(sc);
            sc.update('words', words =>
              words.map(word => word.withMutations(shiftChunkLike)),
            );
          }),
        ),
      );
    }),
  );
}

export async function rechunk(transcript, maxLength, splitAt, styles, fonts) {
  if (styles) {
    return textFitRechunk(transcript, styles, fonts);
  }

  if (isUndefined(splitAt)) {
    return simpleRechunk(transcript, maxLength);
  }

  return twoLimitRechunk(transcript, maxLength, splitAt);
}

/**
 * cilp the transcript, discarding anything before startMillis or after
 * endMillis.  the resulting transcript clip is then shifted to begin at
 * time 0
 *
 * @param {*} transcript manual transcript (plain JSON object)
 * @param {*} startMillis
 * @param {*} endMillis
 */
export function clipTranscript(transcript, startMillis, endMillis) {
  // the whole transcript has to be shifted by -startMillis, but at the time of
  // writing shiftChunks only works on an immutable transcript and it seems somewhat
  // heavy to convert this to immutable, shift, and convert back to json.
  // having this small function here also allows shifting during the reduce rather
  // than iterating the transcript again to shift it after it has been clipped
  const shift = chunkLike => {
    if (!chunkLike) return undefined;

    const result = {
      ...chunkLike,
      startMillis: chunkLike.startMillis - startMillis,
      endMillis: chunkLike.endMillis - startMillis,
    };

    // chunkLike is a chunk
    if (chunkLike.subchunks) {
      return {
        ...result,
        subchunks: chunkLike.subchunks.map(shift),
      };
    }

    // chunkLike is a subchunk
    if (chunkLike.words) {
      return {
        ...result,
        words: chunkLike.words.map(shift),
      };
    }

    return result;
  };

  return transcript.reduce((clip, chunk) => {
    // entire chunk lies before the start time or after the end time.  these can
    // be discarded
    if (chunk.endMillis <= startMillis || chunk.startMillis >= endMillis) {
      return clip;
    }

    // entire chunk lies within range.  these can be accepted without modification
    if (chunk.startMillis >= startMillis && chunk.endMillis <= endMillis) {
      clip.push(shift(chunk));
      return clip;
    }

    // startMillis or endMillis intersects chunk.  interpolate chunk times and take
    // all the words that fall within the range
    const words = parseChunk(chunk);
    const wordsInRange = words.filter(
      word => word.startMillis >= startMillis && word.endMillis <= endMillis,
    );

    // there might be a really short overlap and no words are in range
    if (wordsInRange.length === 0) {
      return clip;
    }

    const newChunkStartMillis = wordsInRange[0].startMillis;
    const newChunkEndMillis = wordsInRange[wordsInRange.length - 1].endMillis;
    clip.push(
      shift({
        endMillis: newChunkEndMillis,
        startMillis: newChunkStartMillis,
        subchunks: [
          {
            endMillis: newChunkEndMillis,
            startMillis: newChunkStartMillis,
            text: wordsInRange.map(w => w.text).join(' '),
            words: wordsInRange,
          },
        ],
      }),
    );

    return clip;
  }, []);
}

export default { convertAutoTranscriptToManual, rechunk };
