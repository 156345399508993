import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { ModalFooterButton, ModalFooterButtons } from 'components/Modal';
import {
  defaultWaveformPrefOnlyEnabledSelector,
  defaultWaveTypeReadOnlySelector,
} from 'redux/modules/display-pref/selectors';
import {
  removeSoundwave,
  saveSoundwave,
} from 'redux/modules/embed/actions/soundwave';
import { soundwaveSelector as embedSoundwaveSelector } from 'redux/modules/embed/selectors';
import { Dispatch } from 'redux/types';
import { defaultSoundwaveState } from 'utils/embed/soundwave';
import { WaveformModalValue } from './types';
import WaveformModalContents from './WaveformModalContents';

const { useCallback, useState } = React;

const defaultSoundwaveSelector = createSelector(embedSoundwaveSelector, swave =>
  !swave || swave.isEmpty() ? defaultSoundwaveState.toJS() : swave.toJS(),
);

export interface EditWaveformModalContents {
  onHide?: () => void;
}

// TODO put this somewhere others can use
interface Time {
  startMillis: number;
  endMillis: number;
}

const EditWaveformModalContents: React.FC<EditWaveformModalContents> = ({
  onHide,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const [soundwave, setSoundwave] = useState<
    WaveformModalValue & { time: Time }
  >(useSelector(defaultSoundwaveSelector));

  // if the edit waveform modal is open, there must be a waveform.  if the
  // default wave type is locked, the user cannot select the "none" type or
  // delete the asset
  const isWaveformPermanent = useSelector(defaultWaveTypeReadOnlySelector);
  const isWaveformPrefOnly = useSelector(
    defaultWaveformPrefOnlyEnabledSelector,
  );

  const handleSetSoundwave = useCallback((value: WaveformModalValue) => {
    setSoundwave(s => ({
      ...value,
      time: s.time,
    }));
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(removeSoundwave());
    onHide();
  }, [dispatch, onHide]);

  const handleSave = useCallback(() => {
    dispatch(saveSoundwave(soundwave));
    onHide();
  }, [dispatch, onHide, soundwave]);

  return (
    <WaveformModalContents
      footer={
        <ModalFooterButtons>
          <ModalFooterButton
            disabled={isWaveformPermanent || isWaveformPrefOnly}
            onClick={handleDelete}
            theme="delete"
          >
            delete
          </ModalFooterButton>
          <ModalFooterButton onClick={handleSave} theme="submit">
            save
          </ModalFooterButton>
        </ModalFooterButtons>
      }
      onChange={handleSetSoundwave}
      value={soundwave}
    />
  );
};

export default EditWaveformModalContents;
