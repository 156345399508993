import { fromJS } from 'immutable';

import { ImmutableTextPreset } from 'redux/modules/text-presets';
import { scale } from 'utils/numbers';
import { splitStyle } from 'utils/rte';
import { createTextShadowString } from 'utils/ui';

import { formatPresetStyles } from '../utils';
import { EditorStyleMap } from './state/types';
import {
  DisplayTextStyles,
  EditorDataV2,
  ITextOverlayV2,
  Position,
  Size,
  StateTextStyles,
} from './types';

/**
 * Coeficient for adjusting the font shadow to the font size. This value
 * will be use to convert both shadow vertical and horizontal offset px
 * values into em.
 */
export const TEXT_SHADOW_OFFSET_VALUE_TO_EM_COEF = 0.04;

/**
 * The idea of all the transformations in this file is a single way
 * transformation either from:
 *
 * Overlay state styles (unscaled) =====> Static text preview styles (scaled)
 *
 * or:
 *
 * Static text preview styles (scaled) =====> Overlay state styles (unscaled)
 */

/**
 * Custom scaler for scaling the font. As the variations in the base overlay
 * canvas affect the font scaling from the px, a scaler coef is calculated
 * when the overlay is initially procesed and then used for scaling the preview
 * font size.
 */
const fontSizeScaler = (
  fontSizePx: number,
  editorData: EditorDataV2,
  canvasSize: Size,
): number => {
  return (
    fontSizePx *
    editorData.getIn(['editor', 'scaler', 'fontWidthPercPerPx']) *
    canvasSize.width
  );
};

/*
  Font size is standardized to the the base viewports defined at
  utils/text-templates. Based on that the font size is saved to the
  current editor selection (i.e. 36). Later the font will be scaled
  based on the original overlay dimensions and not on the current
  scale between the base overlay status and the text overlay modal
  canvas size. For that purpose, fontSizeScaler custom scaler is used.
*/
const FONT_STYLE_SCALABLE_STYLES = [
  { path: ['fontSize'], customScaler: fontSizeScaler, scaleKey: 'width' },
  { path: ['paddingBottom'], scaleKey: 'height' },
  { path: ['paddingLeft'], scaleKey: 'width' },
  { path: ['paddingRight'], scaleKey: 'width' },
  { path: ['paddingTop'], scaleKey: 'height' },
];

/**
 * ------------------------------------------------------------------------------
 * | Overlay state styles (unscaled) =====> Static text preview styles (scaled) |
 * ------------------------------------------------------------------------------
 *
 * Scales the font styles from the current editor styles to the scaled values
 * that should be shown at the overlay preview.
 * The transformations are applied based on the FONT_STYLE_SCALABLE_STYLES dict.
 * For the case of static transformations it will use the static scaler that is
 * based on the original overlay creation dimensions.
 * For the case of the non static transformations it will use the current canvas
 * size oposed to the text overlay viewport dimensions that were provided.
 */
export const scaleFontStyles = (
  editorData: EditorDataV2,
  canvasSize: Size,
): StateTextStyles => {
  const data = splitStyle(
    editorData.getIn([
      'editor',
      'textStyle',
    ]) as ITextOverlayV2['editor']['textStyle'],
  );

  const { innerStyle } = data;
  const innerStyleMap = fromJS(innerStyle);

  return innerStyleMap
    .withMutations(s => {
      FONT_STYLE_SCALABLE_STYLES.forEach(({ path, customScaler, scaleKey }) => {
        s.setIn(
          path,
          customScaler
            ? customScaler(s.getIn(path), editorData, canvasSize)
            : scale(
                innerStyleMap.getIn(path),
                editorData.getIn(['viewport', scaleKey]),
                canvasSize[scaleKey],
              ),
        );
      });
    })
    .toJS();
};

/**
 * ------------------------------------------------------------------------------
 * | Overlay state styles (unscaled) =====> Static text preview styles (scaled) |
 * ------------------------------------------------------------------------------
 *
 * Transforms the editor state styles text shadow object into an the correct
 * display values for the overlay preview.
 */
export const transformShadowStyle = (
  textStyles: StateTextStyles,
): DisplayTextStyles => {
  const textShadow = textStyles.textShadow
    ? createTextShadowString({
        ...textStyles.textShadow,
        x: (textStyles.textShadow.x ?? 0) * TEXT_SHADOW_OFFSET_VALUE_TO_EM_COEF,
        y: (textStyles.textShadow.y ?? 0) * TEXT_SHADOW_OFFSET_VALUE_TO_EM_COEF,
      })
    : undefined;

  return {
    ...textStyles,
    textShadow,
  };
};

/**
 * ------------------------------------------------------------------------------
 * | Static text preview styles (scaled) =====> Overlay state styles (unscaled) |
 * ------------------------------------------------------------------------------
 *
 * Maps a given preset styles to the editor text styles. It applies the original
 * formatPresetStyles utility meant for the v1 of the text modal and applies a
 * further mutation over the text shadow styles.
 */
export const transformPresetToStateStyles = (
  presetData: ImmutableTextPreset,
  draftEditorData: EditorDataV2,
): EditorStyleMap => {
  const formattedPresetStyles = formatPresetStyles(presetData);

  return draftEditorData
    .mergeDeepIn(
      ['editor', 'textStyle'],
      formattedPresetStyles.withMutations(s => {
        s.set(
          'textShadow',
          fromJS({
            ...s.get('textShadow'),
            x: s.get('textShadow').x / TEXT_SHADOW_OFFSET_VALUE_TO_EM_COEF,
            y: s.get('textShadow').y / TEXT_SHADOW_OFFSET_VALUE_TO_EM_COEF,
          }),
        );
        s.set('textHighlight', s.get('background'));
        return s;
      }),
    )
    .getIn(['editor', 'textStyle']);
};

/**
 * ------------------------------------------------------------------------------
 * | Overlay state styles (unscaled) =====> Static text preview styles (scaled) |
 * ------------------------------------------------------------------------------
 *
 * Scales the current canvas position from the overlay viewport dimensions to the
 * editor modal canvas dimensions.
 */
export const scaleEditorDataPosition = (
  unscaledPosition: Position,
  draftEditorData: EditorDataV2,
  canvasSize: Size,
): Position => {
  const position = {
    x: scale(
      unscaledPosition.x,
      draftEditorData.getIn(['viewport', 'width']),
      canvasSize.width,
    ),
    y: scale(
      unscaledPosition.y,
      draftEditorData.getIn(['viewport', 'height']),
      canvasSize.height,
    ),
  };

  return position;
};

/**
 * ------------------------------------------------------------------------------
 * | Static text preview styles (scaled) =====> Overlay state styles (unscaled) |
 * ------------------------------------------------------------------------------
 *
 * Unscales the current canvas position from the editor modal canvas dimensions to the
 * overlay viewport dimensions scale.
 */
export const unscaleCanvasPosition = (
  scaledPosition: Position,
  draftEditorData: EditorDataV2,
  canvasSize: Size,
): Position => {
  const position = {
    x: scale(
      scaledPosition.x,
      canvasSize.width,
      draftEditorData.getIn(['viewport', 'width']),
    ),
    y: scale(
      scaledPosition.y,
      canvasSize.height,
      draftEditorData.getIn(['viewport', 'height']),
    ),
  };

  return position;
};

/**
 * ------------------------------------------------------------------------------
 * | Overlay state styles (unscaled) =====> Static text preview styles (scaled) |
 * ------------------------------------------------------------------------------
 *
 * Scales the current canvas size from the overlay viewport dimensions to the
 * editor modal canvas dimensions.
 */
export const scaleEditorDataSize = (
  unscaledSize: Size,
  draftEditorData: EditorDataV2,
  canvasSize: Size,
): Size => {
  const size = {
    height: scale(
      unscaledSize.height,
      draftEditorData.getIn(['viewport', 'height']),
      canvasSize.height,
    ),
    width: scale(
      unscaledSize.width,
      draftEditorData.getIn(['viewport', 'width']),
      canvasSize.width,
    ),
  };

  return size;
};

/**
 * ------------------------------------------------------------------------------
 * | Static text preview styles (scaled) =====> Overlay state styles (unscaled) |
 * ------------------------------------------------------------------------------
 *
 * Unscales the current canvas size from the editor modal canvas dimensions to the
 * overlay viewport dimensions scale.
 */
export const unscaleCanvasSize = (
  scaledSize: Size,
  draftEditorData: EditorDataV2,
  canvasSize,
): Size => {
  const size = {
    height: scale(
      scaledSize.height,
      canvasSize.height,
      draftEditorData.getIn(['viewport', 'height']),
    ),
    width: scale(
      scaledSize.width,
      canvasSize.width,
      draftEditorData.getIn(['viewport', 'width']),
    ),
  };

  return size;
};
