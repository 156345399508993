import * as React from 'react';

import { H1 } from 'components/Heading';
import MailToSupport from 'components/MailToSupport';
import PaidSubscription, {
  KnowledgeBaseButton,
  PaidSubscriptionButton,
} from '../../components/PaidSubscription';
import EnterpriseSubscriptionHeader from './EnterpriseSubscriptionHeader';
import { block } from './utils';

export interface EnterpriseSubscriptionProps {
  enterpriseName: string;
  title?: string;
}

const EnterpriseSubscription: React.SFC<EnterpriseSubscriptionProps> = ({
  enterpriseName,
  title,
}) => (
  <PaidSubscription className={block()}>
    <EnterpriseSubscriptionHeader title={title} />
    <PaidSubscription.Body className={block('body')}>
      <H1>{enterpriseName}</H1>
      <KnowledgeBaseButton className={block('knowledge')} />
      <MailToSupport
        className={block('adjust-plan')}
        subject="Adjust my Headliner subscription"
        render={({ href }) => (
          <PaidSubscriptionButton
            className={block('adjust-plan')}
            theme="submit-alt"
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            email us to adjust your plan
          </PaidSubscriptionButton>
        )}
      />
    </PaidSubscription.Body>
  </PaidSubscription>
);

EnterpriseSubscription.defaultProps = {
  title: 'Enterprise',
};

export default EnterpriseSubscription;
