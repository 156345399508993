import * as React from 'react';
import { connect } from 'react-redux';

import { rehydratedSelector } from 'redux/modules/app/selectors';
import { State } from 'redux/types';
import { RehydrationGuardProps } from './RehydrationGuard';

export type StateProps = Pick<RehydrationGuardProps, 'rehydrated'>;

const mapStateToProps = (state: State): StateProps => ({
  rehydrated: rehydratedSelector(state),
});

export default function(component: React.ComponentType<RehydrationGuardProps>) {
  return connect(mapStateToProps)(component);
}
