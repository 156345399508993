import React from 'react';

import { Replace } from 'components/icons';

import OptionTile from '../assets/OptionTile';
import { IntroOutroType } from '../types';
import { introOutroChildViewBlock as block } from '../utils';
import useIntroOutro from './useIntroOutro';

export interface IntroOutroReplaceProps {
  className?: string;
  type: IntroOutroType;
}

const IntroOutroReplace: React.FC<IntroOutroReplaceProps> = props => {
  const { className, type } = props;
  const { onReplaceIntroOutro } = useIntroOutro();

  const handleClick = React.useCallback(() => {
    onReplaceIntroOutro(type);
  }, [onReplaceIntroOutro, type]);

  return (
    <OptionTile
      className={className}
      icon={<Replace className={block('replace-icon')} />}
      label="replace"
      onClick={handleClick}
    />
  );
};

export default IntroOutroReplace;
