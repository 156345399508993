import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { defaults } from 'underscore';

import { saveProgressAnimation } from 'redux/modules/embed/actions/progress';
import {
  aspectRatioSelector,
  progressAnimationSelector,
  timerSelector,
} from 'redux/modules/embed/selectors';
import { Dispatch, State } from 'redux/types';
import { ProgressAnimationOptions, PropsOf, TimerOptions } from 'types';
import { DEFAULT_PROGRESS_OPTIONS } from 'utils/embed/progress';
import { getDefaultTimer } from 'utils/embed/timer';
import { Measurement } from 'utils/measurement';
import { measurementToString, stringToViewport } from 'utils/placement';
import ProgressAnimationModal, {
  ProgressAnimationModalProps,
} from '../components/ProgressAnimationModal';

type StateProps = Pick<
  ProgressAnimationModalProps,
  'aspectRatio' | 'defaultValue'
>;

type DispatchProps = Pick<ProgressAnimationModalProps, 'onSubmit'>;

const defaultTimerSelector = createSelector(
  aspectRatioSelector,
  timerSelector,
  (aspectRatio, timer): TimerOptions<string> => {
    const defaultTimer = getDefaultTimer(aspectRatio, 'string');

    return (
      defaultTimer && {
        ...defaultTimer,
        ...timer?.toJS(),
      }
    );
  },
);

const defaultValueSelector = createSelector(
  progressAnimationSelector,
  defaultTimerSelector,
  (progress, timer) => {
    return {
      timer,
      progress: stringToViewport<ProgressAnimationOptions<Measurement>>(
        defaults(progress?.toJS(), DEFAULT_PROGRESS_OPTIONS),
      ),
    };
  },
);

const mapStateToProps = (state: State): StateProps => ({
  aspectRatio: aspectRatioSelector(state),
  defaultValue: defaultValueSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSubmit: progressAndTimer => {
    const { progress, timer } = progressAndTimer;
    dispatch(
      saveProgressAnimation({
        timer,
        progress: measurementToString(progress),
      }),
    );
  },
});

const component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgressAnimationModal);
export type EditorProgressAnimationModalProps = PropsOf<typeof component>;
export default component;
