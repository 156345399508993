import { Iterable, Set } from 'immutable';
import * as React from 'react';
import NotificationSystem from 'react-notification-system';
import { noop, omit } from 'underscore';

import VideoExportSuccessNotification from 'components/VideoExportSuccessNotification';
import VideoExportNotification from 'containers/VideoExportNotification';
import { Action } from 'redux/types';
import { createMap } from 'types';
import { showLiveChatWidget } from 'utils/helpdesk';
import LinkNotification from './LinkNotification';
import { Notification, Notifications as NotificationsType } from './types';
import {
  getNotificationIdsToClear,
  getNotificationsToShow,
  getTitle,
} from './utils';

export interface NotificationsProps {
  notifications: NotificationsType;
  dismissNotification?: (id: string) => void;
  saveConfiguration?: () => void;
  onClickAction?: (action: Action<any>) => void;
}

export default class Notifications extends React.Component<NotificationsProps> {
  public static defaultProps: Partial<NotificationsProps> = {
    dismissNotification: noop,
    notifications: createMap({}),
    saveConfiguration: noop,
  };

  private system: NotificationSystem.System;

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { notifications: nextNotifications } = nextProps;
    const { notifications } = this.props;

    // if nothing has changed, bail
    if (nextNotifications.equals(notifications)) return;

    const alertsToShow = getNotificationsToShow(
      notifications,
      nextNotifications,
    );
    const alertsToClear = getNotificationIdsToClear(
      notifications,
      nextNotifications,
    );

    this.showNotifications(alertsToShow);
    this.clearNotifications(alertsToClear);
  }

  public shouldComponentUpdate() {
    return false;
  }

  /*
   * there are 2 paths to clearing a notification:
   *  1. user clicks cancel
   *  2. notification is removed from state by other means (e.g. auto-dismiss after certain
   *     duration, notification cleared by dispatch of an action from another component, etc.)
   *
   * (1) If the remove handler is called and the notification is in props, then that means it's
   *     still in state and we want to call `dismissNotification` to remove it from state.
   *
   * (2) if the remove handler is called and the notification is not in state, don't call
   *     `dismissNotification`
   */
  private handleRemoveNotification = (id: string) => () => {
    const { dismissNotification, notifications } = this.props;

    if (notifications.hasIn(['ids', id])) {
      dismissNotification(id);
    }
  };

  private getNotificationConfig(notification: Notification) {
    const { onClickAction, saveConfiguration } = this.props;

    const defaultConfig = {
      autoDismiss: notification.get('dismissAfterSec', 0),
      level: notification.get('level'),
      message: notification.get('message'),
      onRemove: this.handleRemoveNotification(notification.get('id')),
      position: 'tr' as 'tr',
      title: notification.get('title') || getTitle(notification.get('level')),
      uid: notification.get('id'),
    };

    switch (notification.get('type')) {
      case 'failedSave': {
        return {
          ...defaultConfig,
          action: {
            callback: saveConfiguration,
            label: 'Save',
          },
        };
      }

      case 'export': {
        const { message, ...rest } = defaultConfig;
        return {
          ...rest,
          children: <VideoExportNotification />,
          dismissible: false,
        };
      }

      case 'exportSuccess': {
        return {
          ...defaultConfig,
          children: <VideoExportSuccessNotification />,
        };
      }

      case 'help':
        return {
          ...omit(defaultConfig, 'message'),
          children: (
            <LinkNotification
              text={notification.get('message')}
              label="contact us"
              onClick={showLiveChatWidget}
            />
          ),
        };

      case 'link':
        return {
          ...omit(defaultConfig, 'message'),
          children: (
            <LinkNotification
              text={notification.get('message')}
              href={notification.get('href')}
              label={notification.get('actionLabel')}
              linkTarget={notification.get('linkTarget')}
            />
          ),
        };

      // NB: does _not_ handle thunks.  this is for simple actions only
      case 'action': {
        return {
          ...omit(defaultConfig, 'message'),
          children: (
            <LinkNotification
              text={notification.get('message')}
              label={notification.get('actionLabel')}
              onClick={() => onClickAction(notification.get('action')?.toJS())}
            />
          ),
        };
      }

      default:
        return { ...defaultConfig };
    }
  }

  private showNotifications(notifications: Iterable<string, any>) {
    notifications.forEach(notification => {
      const config = this.getNotificationConfig(notification);
      this.system.addNotification(config);
    });
  }

  private clearNotifications(notificationIds: Set<string>) {
    notificationIds.forEach(id => this.system.removeNotification(id));
  }

  public render() {
    const ref = el => {
      this.system = el;
    };
    return <NotificationSystem ref={ref} />;
  }
}
