import * as React from 'react';

import { TiktokIconRounded } from 'components/icons';
import Modal from 'components/Modal';
import { block } from './utils';

interface IProps {
  className?: string;
  onHide?: () => void;
}

const AspectRatioModalContent: React.FC<IProps> = props => {
  const { onHide } = props;

  return (
    <>
      <Modal.Body>
        <div className={block('body-container')}>
          <div className={block('icons')}>
            <TiktokIconRounded />
          </div>

          <p className={block('body-title')}>One last step…</p>

          <p className={block('body-description')}>
            We uploaded your video to TikTok, now just open their mobile app
            <br />
            and look for a message in your inbox that says “Your video from
            Headliner is ready”.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButtons className={block('footer')}>
          <Modal.FooterButton onClick={onHide} theme="submit" fluid>
            Sounds good
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default AspectRatioModalContent;
