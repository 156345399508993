import { LinkedinOrganization } from 'redux/modules/social/linkedin';
import bem from 'utils/bem';
import { LinkedinOrganizationSelectorOption } from './types';

export const block = bem('linkedin-organization-selector');

export function organizationToOption(
  organization: LinkedinOrganization,
): LinkedinOrganizationSelectorOption {
  return {
    ...organization,
    imageUrl: organization.logoImageUrl,
    label: organization.name,
    value: organization.id,
  };
}
