import { useFacebookLogin, UseFacebookLoginConfig } from '@sparemin/auth';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'underscore';
import { getSocialProfile } from 'redux/middleware/api/third-party-authentication-service/actions';
import { tokenSelector } from 'redux/modules/auth';
import { showError } from 'redux/modules/notification';

import {
  clearFacebookUser,
  facebookAuthorizeSuccess,
  getFacebookPages,
  getFacebookUser,
} from 'redux/modules/social/facebook/actions';
import { Dispatch } from 'redux/types';
import { SocialSharePlatform } from 'types';
import { FatalError } from 'utils/FatalError';

export interface UseFacebookAuthenticatorConfig
  extends Pick<UseFacebookLoginConfig, 'force'> {
  onError?: (error: Error) => void;
  onAuthSuccess?: () => void;
}

export default function useFacebookAuthenticator({
  force,
  onAuthSuccess,
  onError,
}: UseFacebookAuthenticatorConfig) {
  const dispatch = useDispatch<Dispatch>();
  const spareminToken = useSelector(tokenSelector);

  const showGenericErrorMessage = useCallback(() => {
    dispatch(showError('Error authenticating with Facebook'));
  }, [dispatch]);

  return useFacebookLogin({
    force,
    spareminToken,
    onSuccess: async data => {
      dispatch(clearFacebookUser());

      if (data) {
        dispatch(
          facebookAuthorizeSuccess(
            pick(data.tokenInfo, 'accessToken', 'idToken', 'providerUserId'),
          ),
        );
      }

      try {
        const { accessToken, providerUserId, idToken } = data.tokenInfo;

        const { response } = await dispatch(
          getSocialProfile(
            SocialSharePlatform.FACEBOOK,
            providerUserId,
            idToken,
            accessToken,
          ),
        );

        await dispatch(getFacebookPages());
        await dispatch(getFacebookUser(response));

        onAuthSuccess?.();
      } catch (error) {
        showGenericErrorMessage();

        throw new FatalError(error.message);
      }
    },
    onError: error => {
      if (error.message === 'access_denied') {
        onError?.(error);
      } else {
        showGenericErrorMessage();
      }
    },
  });
}
