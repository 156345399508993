import cn from 'classnames';
import * as React from 'react';

import { TabContentProps } from 'components/Tabs/types';
import { tabsBlock as block } from '../../utils';
import ProjectsPageTabContext from './ProjectsPageTabContext';

const { useCallback, useMemo, useState } = React;

export interface ProjectsPageTabProps
  extends Pick<TabContentProps, 'active' | 'children' | 'className'> {
  expand?: boolean;
}

/**
 * Used to replace the tabContainer element in the Tabs component.  This component
 * adds a scroll container for longer content and pads the scrollable element so
 * that all content is accessible (and isn't hidden behind the footer, for example).
 *
 * This component also exposes the scroll parent in context, which is particularly
 * useful for tabs that use InfiniteScroll.  Context is used because of the way
 * the Tabs component is structured - tab content gets passed as part of the
 * `tabs` prop and ProjectsPageTab is rendered using the `overrides` prop.  Using
 * context is easier than trying to pass the necessary props around.
 *
 * The tab content (children) is lazily mounted, meaning it gets mounted the first
 * time the user opens the tab and then stays mounted thereafter.
 */
const ProjectsPageTab: React.FC<ProjectsPageTabProps> = ({
  active,
  children,
  className,
  expand,
}) => {
  const [scrollParent, setScrollParent] = useState<HTMLElement>(null);

  const handleScrollParentRef = useCallback(el => {
    setScrollParent(el);
  }, []);

  const contextValue = useMemo(
    () => ({
      scrollParent,
    }),
    [scrollParent],
  );

  return (
    <div
      className={cn(block('tab', { active, expand }), className)}
      ref={handleScrollParentRef}
    >
      <div className={block('tab-inner')}>
        <ProjectsPageTabContext.Provider value={contextValue}>
          {children}
        </ProjectsPageTabContext.Provider>
      </div>
    </div>
  );
};

export default ProjectsPageTab;
