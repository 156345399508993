import { useSelector } from 'react-redux';
import useDateString from 'hooks/useDateString';
import { subscriptionBillingPeriodEndSelector } from 'redux/modules/pricing/selectors';

const useBillingCycle = () => {
  const billingPeriodEnd = useSelector(subscriptionBillingPeriodEndSelector);
  const billingPeriodEndDate = useDateString(billingPeriodEnd);

  return {
    billingPeriodEndDate,
  };
};

export default useBillingCycle;
