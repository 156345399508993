import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { customWavePositions } from 'redux/modules/display-pref/constants';
import {
  defaultWaveColorReadonlySelector,
  defaultWaveformSelector,
  defaultWaveGenerationReadonlySelector,
  defaultWaveGenerationsSelector,
  defaultWavePositionReadonlySelector,
  defaultWavePositionsSelector,
  defaultWaveTypeReadOnlySelector,
  defaultWaveTypesSelector,
} from 'redux/modules/display-pref/selectors';
import {
  isCircleWaveType,
  isCustomWaveType,
} from 'redux/modules/display-pref/utils';
import { State } from 'redux/types';
import { AudioWaveOptionsProps } from './AudioWaveOptions';

type StateProps = Pick<
  AudioWaveOptionsProps,
  | 'defaultColorReadonly'
  | 'defaultGenerationReadonly'
  | 'defaultPositionReadonly'
  | 'defaultTypeReadonly'
  | 'defaultSoundwave'
  | 'generationOptions'
  | 'positionOptions'
  | 'typeOptions'
>;

type OwnProps = Pick<AudioWaveOptionsProps, 'type'>;

const defaultSoundwaveSelector = createSelector(
  defaultWaveformSelector,
  waveform => waveform.toJS(),
);

const positionsSelector = (state: State, props: OwnProps) => {
  if (isCircleWaveType(props.type) || isCustomWaveType(props.type)) {
    return customWavePositions;
  }

  return defaultWavePositionsSelector(state);
};

const mapStateToProps = (state: State, props: OwnProps): StateProps => ({
  defaultColorReadonly: defaultWaveColorReadonlySelector(state),
  defaultGenerationReadonly: defaultWaveGenerationReadonlySelector(state),
  defaultPositionReadonly: defaultWavePositionReadonlySelector(state),
  defaultSoundwave: defaultSoundwaveSelector(state),
  defaultTypeReadonly: defaultWaveTypeReadOnlySelector(state),
  generationOptions: defaultWaveGenerationsSelector(state),
  positionOptions: positionsSelector(state, props),
  typeOptions: defaultWaveTypesSelector(state),
});

export default function(component: React.ComponentType<AudioWaveOptionsProps>) {
  return connect(mapStateToProps)(component);
}
