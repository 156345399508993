import classNames from 'classnames';
import * as React from 'react';
import _ from 'underscore';

import Draggable, { DragCallbackData } from 'components/Draggable';
import { block } from './utils';

interface IProps {
  className?: string;
  currentMillis?: number;
  totalMillis?: number;
  parentWidth?: number;
  dragWidth?: number;
  width?: number;
  onPlayPositionChange?: (posMillis: number) => void;
}

export default class CurrentPlayMarker extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    currentMillis: 0,
    dragWidth: 2,
    onPlayPositionChange: _.noop,
    totalMillis: 0,
    width: 2,
  };

  private handleDrag = (__, dragData: DragCallbackData) => {
    const { parentWidth, totalMillis, onPlayPositionChange } = this.props;
    const oldPosition = this.getPosition();
    const newX = Math.min(dragData.x, parentWidth);

    if (newX !== oldPosition.x) {
      const newPosMillis = (newX / parentWidth) * totalMillis;
      onPlayPositionChange(newPosMillis);
    }
  };

  private getPosition = () => {
    const { currentMillis, totalMillis, parentWidth, dragWidth } = this.props;
    if (!totalMillis) return { x: 0, y: 0 };

    return {
      x: (currentMillis / totalMillis) * parentWidth - 0.5 * dragWidth,
      y: 0,
    };
  };

  public render() {
    const { className, parentWidth, dragWidth, width } = this.props;

    const bounds = {
      left: -0.5 * dragWidth,
      right: parentWidth + dragWidth,
    };

    const dragContainerStyle = { width: dragWidth };
    const playheadStyle = { width };

    return (
      <Draggable
        handle="#clipper-marker"
        position={this.getPosition()}
        bounds={bounds}
        onDrag={this.handleDrag}
      >
        <div
          id="clipper-marker"
          className={block('playhead-container')}
          style={dragContainerStyle}
        >
          <div
            className={classNames(block('playhead'), className)}
            style={playheadStyle}
          />
        </div>
      </Draggable>
    );
  }
}
