import React from 'react';

import DialogContent from 'components/DialogContent';
import { H2 } from 'components/Heading';
import { Alert } from 'components/icons';
import ModalFooterButton from 'components/Modal/ModalFooterButton';
import { Step } from 'components/SteppedModal';

import { block } from '../utils';

export interface UseDisallowedAccountStepConfig {
  onSwitchAccount: () => void;
}

const useDisallowedAccountStep = (
  config: UseDisallowedAccountStepConfig,
): Step => {
  const { onSwitchAccount } = config;

  return {
    title: 'Verification Disallowed',
    modalClassName: block('dialog-content'),
    component: (
      <DialogContent
        details={
          <H2>
            YouTube flagged your account because it violated their community
            guidelines. Please switch to another account, otherwise they may
            block your videos.
          </H2>
        }
        detailsClassName={block('dialog-details')}
        icon={<Alert height={100} width={100} />}
        onClose={() => null}
        title="We can’t verify this YouTube account"
        titleClassName={block('dialog-title')}
      />
    ),
    fluidFooterButtons: true,
    id: 'disallowed-account',
    renderFooterButtons: ({ submit }) => [
      <ModalFooterButton
        {...submit}
        key="submit"
        onClick={onSwitchAccount}
        size="large"
        theme="submit"
      >
        select another account
      </ModalFooterButton>,
    ],
  };
};

export default useDisallowedAccountStep;
