import cn from 'classnames';
import * as React from 'react';
import uncontrollable from 'uncontrollable';
import { noop } from 'underscore';

import { Swatch } from 'components/ColorPicker';
import HistoricalColorPicker from 'containers/HistoricalColorPicker';
import MenuButton, { MenuButtonProps } from './MenuButton';
import ToolbarButton from './ToolbarButton';
import ToolbarTool from './ToolbarTool';
import { block } from './utils';

interface IProps extends Pick<MenuButtonProps, 'disabled'> {
  alignRight?: boolean;
  args?: any;
  className?: string;
  controlClassName?: string;
  icon?: JSX.Element;
  isOpen?: boolean;
  onChange?: (color: string, args: any) => void;
  onControlClick?: (args: any) => void;
  onToggleOpen?: (isOpen: boolean, args) => void;
  value?: string;
}

export class ToolbarColorPicker extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    alignRight: false,
    onChange: noop,
    onControlClick: noop,
    onToggleOpen: noop,
  };

  private handleChangeComplete = (color: string) => {
    const { args, onChange } = this.props;
    onChange(color, args);
  };

  private handleControlClick = () => {
    const { args, isOpen, onControlClick, onToggleOpen } = this.props;
    onControlClick(args);
    onToggleOpen(!isOpen, args);
  };

  private handleMenuClose = () =>
    this.props.onToggleOpen(false, this.props.args);

  public render() {
    const {
      alignRight,
      className,
      controlClassName,
      disabled,
      icon,
      isOpen,
      value,
    } = this.props;

    return (
      <HistoricalColorPicker
        className={cn(block('color-picker'), className)}
        color={value}
        componentClass="div"
        disabled={disabled}
        displayColorPicker={isOpen}
        onChangeComplete={this.handleChangeComplete}
        onClose={this.handleMenuClose}
        onSwatchClick={this.handleControlClick}
        swatchClassName={block('color-swatch')}
        rootCloseEvent="click"
        renderSwatch={({ onClick, domRef, ...props }) =>
          icon ? (
            <MenuButton
              className={controlClassName}
              disabled={disabled}
              domRef={domRef}
              icon={React.cloneElement(icon, {
                className: cn(block('color-picker-icon'), icon.props.className),
              })}
              onIconClick={onClick}
            />
          ) : (
            <ToolbarTool disabled={disabled}>
              <ToolbarButton
                className={controlClassName}
                onClick={onClick}
                domRef={domRef}
              >
                <Swatch {...props} />
              </ToolbarButton>
            </ToolbarTool>
          )
        }
        popoverStyle={{
          [alignRight ? 'right' : 'left']: 3,
          marginTop: 0,
          position: 'absolute',
          top: 35,
        }}
      />
    );
  }
}

export type ToolbarColorPickerProps = IProps & {
  defaultIsOpen?: boolean;
  defaultValue?: string;
};

const UncontrollableToolbarColorPicker: React.ComponentClass<ToolbarColorPickerProps> = uncontrollable(
  ToolbarColorPicker,
  {
    isOpen: 'onToggleOpen',
    value: 'onChange',
  },
);

export default UncontrollableToolbarColorPicker;
