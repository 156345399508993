import * as React from 'react';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import { Tier } from 'redux/middleware/api/plan-service';
import { subscriptionSelector } from 'redux/modules/pricing';
import PlanAction from '../PlanAction';
import { basicPlanBlock as block, planIsCancelled } from '../utils';

export interface BasicPlanCtaProps {
  ctaOverride?: React.ReactNode;
  onClick?: () => void;
}

const BasicPlanCta: React.FC<BasicPlanCtaProps> = ({
  ctaOverride,
  onClick,
}) => {
  const plan = useSelector(subscriptionSelector);
  const label = planIsCancelled(Tier.BASIC, plan)
    ? 'keep this plan'
    : 'get started';

  return (
    <PlanAction className={block('cta')} onClick={onClick}>
      {ctaOverride ?? (
        <Button>
          <span className={block('cta-text')}>{label}</span>
        </Button>
      )}
    </PlanAction>
  );
};

export default BasicPlanCta;
