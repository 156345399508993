import { SuperAgentStatic } from 'superagent';
import { createRequest } from '../utils';

import * as types from './types';

const BASE_PATH = '/api/v1/embed';
const ANIMATION_CONFIG_PATH = `${BASE_PATH}/animation-config`;

async function getAnimationConfig(
  request: SuperAgentStatic,
  [animationName, mainImageUrl]: types.GetAnimationConfigArgs,
): Promise<types.GetAnimationConfigResult> {
  const { body } = await request
    .get(ANIMATION_CONFIG_PATH)
    .query({ animationName, mainImageUrl });

  return body;
}

export function handle(method: any, args: any, token: string) {
  const request = createRequest({
    token,
    baseUrl: spareminConfig.services.embed,
  });

  switch (method) {
    case types.ServiceMethod.GET_ANIMATION_CONFIG:
      return getAnimationConfig(request, args);

    default:
      return undefined;
  }
}
