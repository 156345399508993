import * as React from 'react';
import { DestinationOptions } from 'blocks/AutomationWorkflowWizard/containers/AutomationWorkflowWizard/types';
import ToggleCard from 'components/ToggleCard';
import { AutomationOptionsCardsOptions, DestinationStepView } from './types';
import { block } from './utils';

interface AutomationOptionsCardsProps<
  TOption extends AutomationOptionsCardsOptions
> {
  options: TOption[];
  value: TOption['value'][keyof TOption['value']];
  currentStep: DestinationStepView;
  onChange: (props: DestinationOptions) => void;
}

const AutomationOptionsCards = <TOptions extends AutomationOptionsCardsOptions>(
  props: AutomationOptionsCardsProps<TOptions>,
) => {
  const { options, value, currentStep, onChange } = props;

  const handleSetNewCheckedValue = (
    option: AutomationOptionsCardsOptions,
  ): void => {
    const { value: optionValue, isPro, onUpgrade } = option;

    if (isPro) {
      return onUpgrade?.();
    }

    return onChange({
      ...optionValue,
      stepView: currentStep,
    });
  };

  return (
    <div className={block()}>
      {options.map(option => {
        const { id, contents, icon, isPro, value: optionValue } = option;

        return (
          <ToggleCard
            {...{ icon, isPro }}
            key={id}
            hasAccess={isPro}
            info={contents}
            theme="blue"
            checked={Object.values(optionValue).includes(value)}
            onChange={() => handleSetNewCheckedValue(option)}
          />
        );
      })}
    </div>
  );
};

export default AutomationOptionsCards;
