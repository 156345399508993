import * as React from 'react';

import { IconProps } from './types';

const Key: React.FC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M16 5.5a5.5 5.5 0 0 1-6.526 5.404l-.75.844a.75.75 0 0 1-.56.252H7v1.25a.75.75 0 0 1-.75.75H5v1.25a.75.75 0 0 1-.75.75H.75a.75.75 0 0 1-.75-.75v-2.44a.75.75 0 0 1 .22-.53l5.056-5.056A5.5 5.5 0 0 1 10.5 0C13.546 0 16 2.453 16 5.5zM10.5 4a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0z"
    />
  </svg>
);

export default Key;
