import { RecordOf } from 'immutable';
import { createSelector } from 'reselect';
import { isString } from 'underscore';

import { recordingsSelector } from 'redux/modules/entities/selectors';
import { State as ReduxState } from 'redux/types';
import { EntireAudioState, Selectors, State, WrappedState } from './types';

export default function createSelectors<S extends EntireAudioState>(
  stateSelectorOrName: string | ((state: ReduxState) => WrappedState<S>),
): Selectors {
  const stateSelector = isString(stateSelectorOrName)
    ? (state: ReduxState) =>
        state.getIn([stateSelectorOrName, 'entireAudio']) as RecordOf<State>
    : (state: ReduxState) =>
        stateSelectorOrName(state).get('entireAudio') as RecordOf<State>;

  const recordingIdSelector = createSelector(
    stateSelector,
    state => state && state.get('recordingId'),
  );

  const recordingSelector = createSelector(
    [recordingsSelector, recordingIdSelector],
    (recordings, recordingId) => {
      if (!recordings || !recordingId) return false;
      return recordings.get(`${recordingId}`);
    },
  );

  const versionIdSelector = createSelector(
    recordingSelector,
    recording => recording && recording.get('versionId'),
  );

  return {
    recordingIdSelector,
    recordingSelector,
    versionIdSelector,
    entireAudioInstanceIdSelector: createSelector(
      stateSelector,
      state => state && state.get('entireAudioInstanceId'),
    ),
    isImportedSelector: createSelector(versionIdSelector, versionId =>
      isFinite(versionId),
    ),
    recordingUploadIdSelector: createSelector(
      stateSelector,
      state => state && state.get('recordingUploadId'),
    ),
    uploadStatusSelector: createSelector(
      stateSelector,
      state => state && state.get('uploadStatus'),
    ),
    uploadProgressSelector: createSelector(stateSelector, state =>
      state?.get('uploadProgress'),
    ),
  };
}
