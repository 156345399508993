import {
  Button,
  Globe,
  Heading,
  Select,
  Spacer,
  Text,
  ThemeProvider,
} from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_LANGUAGE, languages } from 'constants/languages';
import { eddySupportedLanguagesSelector } from 'redux/modules/entities';
import { SHOULD_GENERATE_ASSETS_KEY } from '../constants';
import EddyProjectCreationSelector from './EddyProjectCreationSelector';
import { block } from './utils';

export interface LanguageStepProps {
  language: string;
  defaultShouldGenerateAssets?: boolean;
  showEddyProjectCreationSelector?: boolean;
  onLanguageChange: (language: string) => void;
  onShouldGenerateAssetsChange: (shouldGenerateAssets?: boolean) => void;
  onContinue: (isLanguageSupported: boolean, generateAssets: boolean) => void;
}

const LanguageStep: React.FC<LanguageStepProps> = ({
  language,
  defaultShouldGenerateAssets,
  showEddyProjectCreationSelector = true,
  onLanguageChange,
  onShouldGenerateAssetsChange,
  onContinue,
}) => {
  const eddySupportedProjectLanguages = useSelector(
    eddySupportedLanguagesSelector,
  );

  const [isLanguageSupported, setIsLanguageSupported] = useState(
    eddySupportedProjectLanguages.some(
      ({ languageCode }) => languageCode === language,
    ),
  );

  const shouldGenerateAssetsFromStorage = localStorage.getItem(
    SHOULD_GENERATE_ASSETS_KEY,
  );

  const [shouldGenerateAssets, setShouldGenerateAssets] = useState(
    shouldGenerateAssetsFromStorage
      ? shouldGenerateAssetsFromStorage === 'true'
      : true,
  );

  const handleShouldGenerateAssetsChange = useCallback(
    (generate: boolean): void => {
      localStorage.setItem(SHOULD_GENERATE_ASSETS_KEY, generate.toString());

      setShouldGenerateAssets(generate);
      onShouldGenerateAssetsChange(generate);
    },
    [onShouldGenerateAssetsChange],
  );

  const handleLanguageChange = useCallback(
    (newLanguage: string) => {
      onLanguageChange(newLanguage);

      if (!showEddyProjectCreationSelector) {
        return;
      }

      const isSupported = eddySupportedProjectLanguages.some(
        ({ languageCode }) => languageCode === newLanguage,
      );

      setIsLanguageSupported(isSupported);
      setShouldGenerateAssets(isSupported);
      onShouldGenerateAssetsChange(isSupported);
    },
    [
      eddySupportedProjectLanguages,
      onLanguageChange,
      onShouldGenerateAssetsChange,
      setShouldGenerateAssets,
      showEddyProjectCreationSelector,
    ],
  );

  const handleContinue = useCallback((): void => {
    // If defaultShouldGenerateAssets is undefined, that means that the user made no
    // selection so assigning the default value from the local storage.
    if (
      showEddyProjectCreationSelector &&
      defaultShouldGenerateAssets === undefined
    ) {
      handleShouldGenerateAssetsChange(shouldGenerateAssets);
    }

    onContinue(isLanguageSupported, shouldGenerateAssets);
  }, [
    defaultShouldGenerateAssets,
    handleShouldGenerateAssetsChange,
    isLanguageSupported,
    onContinue,
    shouldGenerateAssets,
    showEddyProjectCreationSelector,
  ]);

  return (
    <Spacer orientation="vertical" align="center" space={4} className={block()}>
      <Spacer
        orientation="vertical"
        align="center"
        className={block('heading-container')}
      >
        <Heading level={1}>What language is this podcast?</Heading>

        <Text variant="label" className={block('description')}>
          This ensures our captions are as accurate as possible.
        </Text>
      </Spacer>

      <ThemeProvider theme="dark">
        <Select
          fluid
          defaultSelectedKey={DEFAULT_LANGUAGE}
          onSelectionChange={handleLanguageChange}
          startAdornment={
            <Globe
              className={block('globe-adornment')}
              width={14}
              height={15.6}
            />
          }
        >
          {languages.map(({ value, label }) => (
            <Select.Option key={value}>{label}</Select.Option>
          ))}
        </Select>
      </ThemeProvider>

      {showEddyProjectCreationSelector && (
        <EddyProjectCreationSelector
          {...{ isLanguageSupported, shouldGenerateAssets }}
          onShouldGenerateAssetsChange={handleShouldGenerateAssetsChange}
        />
      )}

      <Button
        fluid
        className={block('continue-button')}
        onPress={handleContinue}
      >
        continue with this language
      </Button>
    </Spacer>
  );
};

export default LanguageStep;
