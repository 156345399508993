import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import RemoveWatermarksModalContents from './RemoveWatermarksModalContents';

export interface RemoveWatermarksModalProps {}

const RemoveWatermarksModal: React.FC<RemoveWatermarksModalProps> = () => {
  return (
    <ConnectedModal name="RemoveWatermarks" title="upgrade your plan">
      <RemoveWatermarksModalContents />
    </ConnectedModal>
  );
};

export default RemoveWatermarksModal;
