import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  appsConnectedDeleteStatusSelector,
  appsConnectedStatusSelector,
  AppsProvider,
  disconnectAppsConnected,
  requestAppsConnected,
} from 'redux/modules/apps-connected';
import { showError } from 'redux/modules/notification';
import AppsSettings from './AppsSettingsView';

const AppsSettingsContainer: React.FunctionComponent = () => {
  const [selectedApp, setSelectedApp] = React.useState<AppsProvider>();

  const dispatch = useDispatch();
  const appsConnectedStatus = useSelector(appsConnectedStatusSelector);
  const appsConnectedDeleteStatus = useSelector(
    appsConnectedDeleteStatusSelector,
  );

  const fetchAppsConnected = React.useCallback(async () => {
    try {
      // for now only Youtube is available
      // TODO: Change logic if more apps become available
      await dispatch(requestAppsConnected('youtube'));
    } catch (err) {
      dispatch(showError('There was an error getting your connected apps.'));
    }
  }, [dispatch]);

  React.useEffect(() => {
    fetchAppsConnected();
  }, [fetchAppsConnected]);

  const handleDisconnectApp = async (provider: AppsProvider) => {
    try {
      await dispatch(disconnectAppsConnected(provider));
      setSelectedApp(undefined);
    } catch {
      dispatch(showError('There was an error disconnecting your app.'));
    }
  };

  return (
    <AppsSettings
      appsConnected={appsConnectedStatus}
      disconnectStatus={appsConnectedDeleteStatus}
      onDisconnect={handleDisconnectApp}
      selectedApp={selectedApp}
      setSelectedApp={setSelectedApp}
    />
  );
};

export default AppsSettingsContainer;
