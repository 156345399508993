import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useOnboardingWizard } from 'blocks/OnboardingWizard/OnboardingWizardContext';
import useOnboardingSale from 'blocks/OnboardingWizard/useOnboardingSale';
import { pushModal } from 'redux/modules/modal';

export default () => {
  const dispatch = useDispatch();
  const { tierIntent } = useOnboardingWizard();

  const { isUpsellCompleted, status, purchasedTier } = useOnboardingSale(
    tierIntent,
  );

  const openCheckoutModal = useCallback(() => {
    dispatch(
      pushModal({
        name: 'AddBillingCycle',
        params: {
          tier: tierIntent,
          defaultSubscriptionPeriod: 'yearly',
          disableSuccessRedirect: true,
          onboardingCheckoutMode: true,
        },
      }),
    );
  }, [dispatch, tierIntent]);

  return {
    isUpsellCompleted,
    openCheckoutModal,
    purchasedTier,
    status,
  };
};
