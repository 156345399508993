import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import CardCta, { CardCtaProps } from './CardCta';

export type CutCardCtaProps = Omit<CardCtaProps, 'color' | 'icon'>;

const CutCardCta: React.FC<CutCardCtaProps> = props => (
  <CardCta icon={<FontAwesome icon="cut" />} {...props} />
);

export default CutCardCta;
