/* eslint-disable max-classes-per-file */
import ExtendoError from 'extendo-error';
import { CaptionFormat } from 'types';

import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'TRANSCRIPT_SERVICE';
export const ACTION_KEY: ActionKey = 'TRANSCRIPT_SERVICE';

export enum ServiceMethod {
  ADD_RECORDING_TO_WHITELIST = 'ADD_RECORDING_TO_WHITELIST',
  GET_TRANSCRIPT_BY_VERSION_ID = 'GET_TRANSCRIPT_BY_VERSION_ID',
  CREATE_MANUAL_TRANSCRIPT = 'CREATE_MANUAL_TRANSCRIPT',
  GET_TRANSCRIPT_BY_ID = 'GET_TRANSCRIPT_BY_ID',
  GET_TRANSCRIPT_BY_REVISION_ID = 'GET_TRANSCRIPT_BY_REVISION_ID',
  GET_VIDEO_TRANSCRIPT_BY_VIDEO_ID = 'GET_VIDEO_TRANSCRIPT_BY_VIDEO_ID',
  DOWNLOAD_TRANSCRIPT_BY_REVISION_ID = 'DOWNLOAD_TRANSCRIPT_BY_REVISION_ID',
  DOWNLOAD_EPISODE_TRANSCRIPT = 'DOWNLOAD_EPISODE_TRANSCRIPT',
  UPDATE_TRANSCRIPT_SUBCHUNK = 'UPDATE_TRANSCRIPT_SUBCHUNK',
  UPDATE_TRANSCRIPT = 'UPDATE_TRANSCRIPT',
  IMPORT_MANUAL_TRANSCRIPT = 'IMPORT_MANUAL_TRANSCRIPT',
}

export class TranscriptNotFoundError extends ExtendoError {}
export class TranscriptNoContentError extends ExtendoError {}

interface ITimeable {
  startMillis: number;
  endMillis: number;
}

export interface Word extends ITimeable {
  text: string;
}

export interface IChunk extends ITimeable {
  text: string;
  userId?: number;
  words: Word[];
}

export interface IParticipant {
  id: number;
}

export interface ITranscript {
  isResolved: boolean;
  participants: IParticipant[];
  status: 'inProgress' | 'completed' | 'failed';
  transcript: IChunk[];
}

export interface INormalizedTranscript {
  result: number;
  entities: {
    transcripts: {
      [id: number]: ITranscript & { id: number };
    };
  };
}

export interface ISubChunk extends ITimeable {
  id: string;
  text: string;
  userId?: number;
  words: Word[];
}

export interface IManualTranscriptChunk extends ITimeable {
  chunkId: string;
  subchunks: ISubChunk[];
}

export interface IManualTranscript {
  id: string;
  participants: IParticipant[];
  revisionId: string;
  schemaVersion: number;
  transcript: IManualTranscriptChunk[];
}

export interface INormalizedManualTranscript {
  result: string;
  entities: {
    manualTranscripts: {
      [id: string]: IManualTranscript;
    };
  };
}

export interface IUpdateTranscriptRequest {
  participants: IParticipant[];
  transcript: IManualTranscriptChunk[];
}

export type GetTranscriptByVersionIdArgs = [number];
export type GetTranscriptByVersionIdResult = INormalizedTranscript;

export type AddRecordingToWhitelistArgs = [
  number, // recordingId
  string, // language
];

export type CreateManualTranscriptArgs = [IChunk[], IParticipant[]];
export type CreateManualTranscriptResult = INormalizedManualTranscript;

export type GetTranscriptByIdArgs = [string];
export type GetTranscriptByIdResult = INormalizedTranscript;

export type GetTranscriptByRevisionIdArgs = [string, string];
export type GetTranscriptByRevisionIdResult = INormalizedManualTranscript;

export type GetVideoTranscriptByVideoIdArgs = [number];
export type GetVideoTranscriptByVideoIdResult = INormalizedTranscript;

export type DownloadTranscriptByRevisionIdArgs = [string, string, string];
export type DownloadTranscriptByRevisionIdResult = [string, string, string];

export type UpdateTranscriptSubchunkArgs = [string, string, string, ISubChunk];
export type UpdateTranscriptSubchunkResult = INormalizedManualTranscript;

export type UpdateTranscriptArgs = [string, IUpdateTranscriptRequest];
export type UpdateTranscriptResult = INormalizedManualTranscript;

export type ImportManualTranscriptArgs = [File | string];
export type ImportManualTranscriptResult = INormalizedManualTranscript;

export type DownloadEpisodeTranscriptArgs = [
  string /* episode id */,
  CaptionFormat,
];
export type DownloadEpisodeTranscriptResult = void;

export interface IHandler {
  (
    method: ServiceMethod.CREATE_MANUAL_TRANSCRIPT,
    args: CreateManualTranscriptArgs,
    token?: string,
  ): Promise<CreateManualTranscriptResult>;
  (
    method: ServiceMethod.GET_TRANSCRIPT_BY_ID,
    args: GetTranscriptByIdArgs,
    token?: string,
  ): Promise<GetTranscriptByIdResult>;
  (
    method: ServiceMethod.GET_TRANSCRIPT_BY_REVISION_ID,
    args: GetTranscriptByRevisionIdArgs,
    token?: string,
  ): Promise<GetTranscriptByRevisionIdResult>;
  (
    method: ServiceMethod.GET_VIDEO_TRANSCRIPT_BY_VIDEO_ID,
    args: GetVideoTranscriptByVideoIdArgs,
    token?: string,
  ): Promise<GetVideoTranscriptByVideoIdResult>;
  (
    method: ServiceMethod.UPDATE_TRANSCRIPT_SUBCHUNK,
    args: UpdateTranscriptSubchunkArgs,
    token?: string,
  ): Promise<UpdateTranscriptSubchunkResult>;
  (
    method: ServiceMethod.UPDATE_TRANSCRIPT,
    args: UpdateTranscriptArgs,
    token?: string,
  ): Promise<UpdateTranscriptResult>;
  (
    method: ServiceMethod.IMPORT_MANUAL_TRANSCRIPT,
    args: ImportManualTranscriptArgs,
    token?: string,
  ): Promise<ImportManualTranscriptResult>;
}

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;
export type CreateManualTranscriptAction = ApiAction<
  ServiceMethod.CREATE_MANUAL_TRANSCRIPT,
  CreateManualTranscriptArgs
>;
export type GetTranscriptByIdAction = ApiAction<
  ServiceMethod.GET_TRANSCRIPT_BY_ID,
  GetTranscriptByIdArgs
>;
export type GetTranscriptByRevisionIdAction = ApiAction<
  ServiceMethod.GET_TRANSCRIPT_BY_REVISION_ID,
  GetTranscriptByRevisionIdArgs
>;
export type AddRecordingToWhitelistAction = ApiAction<
  ServiceMethod.ADD_RECORDING_TO_WHITELIST,
  AddRecordingToWhitelistArgs
>;
export type GetTranscriptByVersionIdAction = ApiAction<
  ServiceMethod.GET_TRANSCRIPT_BY_VERSION_ID,
  GetTranscriptByVersionIdArgs
>;
export type DownloadTranscriptByRevisionIdAction = ApiAction<
  ServiceMethod.DOWNLOAD_TRANSCRIPT_BY_REVISION_ID,
  DownloadTranscriptByRevisionIdArgs
>;
export type GetVideoTranscriptByVideoIdAction = ApiAction<
  ServiceMethod.GET_VIDEO_TRANSCRIPT_BY_VIDEO_ID,
  GetVideoTranscriptByVideoIdArgs
>;
export type UpdateTranscriptSubchunkAction = ApiAction<
  ServiceMethod.UPDATE_TRANSCRIPT_SUBCHUNK,
  UpdateTranscriptSubchunkArgs
>;
export type UpdateTranscriptAction = ApiAction<
  ServiceMethod.UPDATE_TRANSCRIPT,
  UpdateTranscriptArgs
>;

export type ImportManualTranscriptionAction = ApiAction<
  ServiceMethod.IMPORT_MANUAL_TRANSCRIPT,
  ImportManualTranscriptArgs
>;

export type DownloadEpisodeTranscriptAction = ApiAction<
  ServiceMethod.DOWNLOAD_EPISODE_TRANSCRIPT,
  DownloadEpisodeTranscriptArgs
>;

export interface TranscriptServiceDispatch {
  (action: CreateManualTranscriptAction): Promise<
    IApiResponse<CreateManualTranscriptResult>
  >;
  (action: GetTranscriptByIdAction): Promise<
    IApiResponse<GetTranscriptByIdResult>
  >;
  (action: GetTranscriptByRevisionIdAction): Promise<
    IApiResponse<GetTranscriptByRevisionIdResult>
  >;
  (action: AddRecordingToWhitelistAction): Promise<IApiResponse<any>>;
  (action: GetTranscriptByVersionIdAction): Promise<
    IApiResponse<GetTranscriptByVersionIdResult>
  >;
  (action: GetVideoTranscriptByVideoIdAction): Promise<
    IApiResponse<GetVideoTranscriptByVideoIdResult>
  >;
  (action: UpdateTranscriptSubchunkAction): Promise<
    IApiResponse<UpdateTranscriptSubchunkResult>
  >;
  (action: UpdateTranscriptAction): Promise<
    IApiResponse<UpdateTranscriptResult>
  >;
  (action: ImportManualTranscriptionAction): Promise<
    IApiResponse<ImportManualTranscriptResult>
  >;
  (action: DownloadEpisodeTranscriptAction): Promise<
    IApiResponse<DownloadEpisodeTranscriptResult>
  >;
}
