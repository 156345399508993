import * as React from 'react';

import { useDispatch } from 'react-redux';
import SaveAsTemplate from 'blocks/SaveAsTemplate';
import ProIndicator, { ProIndicatorOverlay } from 'containers/ProIndicator';
import { ClickSaveTemplateFrom } from 'redux/modules/mixpanel/types';
import { pushModal } from 'redux/modules/modal/actions';
import { block } from '../../utils';

export interface SaveAsTemplateButton {
  from?: ClickSaveTemplateFrom;
}

const SaveAsTemplateButton: React.FC<SaveAsTemplateButton> = props => {
  const { from } = props;

  const dispatch = useDispatch();
  const handleUpgradeClick = (event: React.MouseEvent) => {
    event.preventDefault();
    dispatch(
      pushModal({
        name: 'SaveAsTemplateIntro',
      }),
    );
  };

  return (
    <ProIndicator className={block('badge')}>
      {({ isFree, badge }) => (
        <div className={block('button', { free: isFree })}>
          <SaveAsTemplate disabled={isFree} from={from} />
          {badge}
          {isFree && (
            <ProIndicatorOverlay
              from="Save as Template"
              tooltipPlacement="bottom"
              onClick={handleUpgradeClick}
            />
          )}
        </div>
      )}
    </ProIndicator>
  );
};

export default SaveAsTemplateButton;
