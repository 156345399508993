import bem from 'utils/bem';
import { checkHasWhiteSpace } from 'utils/string';

export const block = bem('add-categories-from-feed');

export const MAX_CHARS_TAGS = 500;
export const MAX_CHARS_TAGS_EXTRA = 1000000;

export const getTagsList = (tags: string): string[] => {
  return (
    tags
      // Removes the leading and trailing white space of the string.
      .trim()
      // Removes the leading and trailing white space for each tag.
      .replace(/\s*,\s*/g, ',')
      // Separates all the tags based on commas.
      .split(',')
  );
};

// Based on YouTube's documentation on tags:
// If a tag contains a space, the API server handles the tag
// value as though it were wrapped in quotation marks, and the
// quotation marks count toward the character limit.
// So, for the purposes of character limits, the tag
// Foo-Baz contains seven characters, but the tag Foo Baz contains nine characters.
export const calculateTagsTextLength = (tags: string[]): number => {
  if (!tags) return 0;

  const tagsWithWhiteSpace = tags.reduce((accum, current) => {
    if (checkHasWhiteSpace(current)) return accum + 1;

    return accum;
  }, 0);

  // The total amount of tags with whitespace +
  // 2 which would be the extra characters for each tag with white spaces +
  // The total lenght of the tags text without counting the space between the tags.
  return tagsWithWhiteSpace * 2 + tags.toString().length;
};

export const convertTagsListToString = (tags: string[] = []): string => {
  if (!tags.length) return '';

  return tags?.join(', ');
};
