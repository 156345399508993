import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';
import bem from 'utils/bem';

interface IProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'ref' | 'as'> {
  disabled?: boolean;
  // FIXME: a little confusing that this has both onClick and onButtonClick (see index.ts)
  onButtonClick?: (params: any, event: MouseEvent) => void;
  onLongButtonClick?: (params: any, event: MouseEvent) => void;
  params?: any;
  className?: string;
  theme?: 'default' | 'primary';
  as?: React.ElementType;
}

const block = bem('icon-button');

/**
 * This component also supports all of the standard button props
 */
class IconButton extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onButtonClick: noop,
    onLongButtonClick: undefined,
    params: undefined,
  };

  private longPressDelay: number;
  private longPressTimer: number;

  private handleClick = event => {
    const { onButtonClick, params } = this.props;
    onButtonClick(params, event);
  };

  private handleLongButtonClick = event => {
    const { onLongButtonClick, params } = this.props;
    onLongButtonClick(params, event);
  };

  private handleMouseDown = event => {
    const { onLongButtonClick } = this.props;
    if (onLongButtonClick) {
      this.longPressDelay = window.setTimeout(() => {
        this.longPressTimer = window.setInterval(() => {
          this.handleLongButtonClick(event);
        }, 5);
      }, 200);
    }
  };

  private handleMouseUp = () => {
    window.clearInterval(this.longPressTimer);
    window.clearTimeout(this.longPressDelay);
  };

  public render() {
    const {
      onButtonClick,
      onLongButtonClick,
      params,
      className,
      children,
      theme,
      as: As = 'button',
      ...rest
    } = this.props;
    return (
      <As
        {...rest}
        className={cn(block({ [theme]: !!theme }), className)}
        onClick={this.handleClick}
        type="button"
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onMouseLeave={this.handleMouseUp}
      >
        {children}
      </As>
    );
  }
}

export default IconButton;
export { IProps as IconButtonProps };
