import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingName } from 'blocks/OnboardingAnimations/types';
import { getUserIntegrators, loggedInSelector } from 'redux/modules/auth';
import {
  clearDownload,
  loadDownloadData,
} from 'redux/modules/download/actions';
import { GetVideoExportStatusError } from 'redux/modules/download/types';
import { showError } from 'redux/modules/notification/actions';
import { getOnboardingAction } from 'redux/modules/onboarding';
import { goToAppHome } from 'redux/modules/router/actions';

export default function useDownloadPageDispatch(wid: string) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(loggedInSelector);

  useEffect(
    () => () => {
      dispatch(clearDownload());
    },
    [dispatch],
  );

  useEffect(() => {
    Promise.resolve()
      .then(() => {
        if (isLoggedIn) {
          return Promise.all([
            dispatch(getUserIntegrators()),
            dispatch(getOnboardingAction(OnboardingName.NPS_SURVEY)),
          ]);
        }
        return null;
      })
      .then(() => dispatch(loadDownloadData(wid)))
      .catch(err => {
        if (err instanceof GetVideoExportStatusError) {
          dispatch(
            showError({
              message: 'Invalid Download URL',
              code: 'IN017',
              dismissAfterSec: 5,
            }),
          );
        } else {
          dispatch(
            showError({
              message: 'Error loading video data',
              code: 'ER014',
              dismissAfterSec: 5,
            }),
          );
        }

        dispatch(goToAppHome());
      });
  }, [dispatch, isLoggedIn, wid]);
}
