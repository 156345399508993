import { Record, RecordOf } from 'immutable';

import { Action } from 'redux/types';
import { PostStatus } from '../constants';
import { Type } from './action-types';

export interface TwitterPostMessageData {
  tokenInfo: {
    accessToken: string;
    accessTokenSecret: string;
    idToken: string;
    provider: 'twitter';
    providerUserId: string;
  };
}

export type TwitterAuthData = Pick<
  TwitterPostMessageData['tokenInfo'],
  'accessToken' | 'accessTokenSecret' | 'idToken' | 'providerUserId'
>;

interface State extends TwitterAuthData {
  accessToken: string;
  accessTokenSecret: string;
  postStatus: PostStatus;
  profileImageUrl: string;
  socialShareId: number;
  username: string;
  tweetUrl: string;
}

export type TwitterState = RecordOf<State>;

export const twitterStateFactory = Record<State>({
  accessToken: undefined,
  accessTokenSecret: undefined,
  idToken: undefined,
  postStatus: undefined,
  profileImageUrl: undefined,
  providerUserId: undefined,
  socialShareId: undefined,
  username: undefined,
  tweetUrl: undefined,
});

type GetTwitterUserRequestAction = Action<Type.TWITTER_USER_DATA_GET_REQUEST>;
type GetTwitterUserSuccessAction = Action<
  Type.TWITTER_USER_DATA_GET_SUCCESS,
  {
    username: string;
    profileImageUrl: string;
  }
>;
type GetTwitterUserFailureAction = Action<Type.TWITTER_USER_DATA_GET_FAILURE>;

type ClearTwitterUserAction = Action<Type.TWITTER_USER_DATA_CLEAR>;

type PostVideoRequestAction = Action<Type.TWITTER_VIDEO_POST_REQUEST>;
type PostVideoFailureAction = Action<Type.TWITTER_VIDEO_POST_FAILURE>;
type PostVideoSuccessAction = Action<
  Type.TWITTER_VIDEO_POST_SUCCESS,
  {
    socialShareId: number;
  }
>;

type AwaitGetUserRequestAction = Action<
  Type.TWITTER_USER_DATA_GET_AWAIT_REQUEST
>;
type AwaitGetUserSuccessAction = Action<
  Type.TWITTER_USER_DATA_GET_AWAIT_SUCCESS
>;
type AwaitGetUserFailureAction = Action<
  Type.TWITTER_USER_DATA_GET_AWAIT_FAILURE
>;

type AwaitPostRequestAction = Action<Type.TWITTER_VIDEO_POST_AWAIT_REQUEST>;
type AwaitPostSuccessAction = Action<Type.TWITTER_VIDEO_POST_AWAIT_SUCCESS>;
type AwaitPostFailureAction = Action<Type.TWITTER_VIDEO_POST_AWAIT_FAILURE>;

export type TwitterAuthorizeSuccessAction = Action<
  Type.TWITTER_AUTHORIZE_SUCCESS,
  TwitterAuthData
>;

export type TwitterAction =
  | GetTwitterUserRequestAction
  | GetTwitterUserSuccessAction
  | GetTwitterUserFailureAction
  | ClearTwitterUserAction
  | PostVideoRequestAction
  | PostVideoSuccessAction
  | PostVideoFailureAction
  | TwitterAuthorizeSuccessAction
  | AwaitPostRequestAction
  | AwaitPostSuccessAction
  | AwaitPostFailureAction
  | AwaitGetUserRequestAction
  | AwaitGetUserSuccessAction
  | AwaitGetUserFailureAction;
