import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { searchFavoritePodcast } from 'redux/modules/podcast-search/actions';
import { Dispatch } from 'redux/types';

type TabKey = number;

const useActiveTabKey = (): [TabKey, (tab: TabKey) => void, TabKey] => {
  const dispatch = useDispatch<Dispatch>();
  const initialValue = useRef<TabKey>();
  const [activeTab, setActiveTab] = useState<TabKey>(2);

  useEffect(() => {
    dispatch(searchFavoritePodcast()).then(defaultPodcastId => {
      initialValue.current = defaultPodcastId ? 2 : 1;
      setActiveTab(initialValue.current);
    });
  }, [dispatch]);

  return [activeTab, setActiveTab, initialValue.current];
};

export default useActiveTabKey;
