import * as React from 'react';
import { Link } from 'react-router-dom';

export interface VideoExportLinkProps {
  children?: React.ReactNode;
  className?: string;
  widgetId?: string;
}

const VideoExportLink: React.SFC<VideoExportLinkProps> = ({
  children,
  className,
  widgetId,
}) => {
  const linkTo = {
    pathname: `/download/${widgetId}`,
  };

  return (
    <Link
      to={linkTo}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </Link>
  );
};

VideoExportLink.defaultProps = {
  children: 'here',
};

export default VideoExportLink;
