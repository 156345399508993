import React from 'react';
import ReactToggle, { ToggleProps } from 'react-toggle';

import FormField, { FormFieldProps } from './FormField';
import { block } from './utils';

const { useCallback } = React;

type InputProps = Pick<ToggleProps, 'checked'>;

type SwitchConfigOption = 'on' | 'off';

interface Props extends InputProps {
  inputClassName?: string;
  left: string;
  onChange?: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  right: string;
  /**
   * array index 0 is the option on the left and index 1 is the option on the right.
   * the SwitchConfigOption controls whether or not the switch appears to be on
   * or off when toggled to the respective direction.  For example,
   * ['on', 'on'] means that the switch will always appear "on" (track colored blue)
   * when switched to either direction, whereas ['off', 'on'] will make the switch
   * appear to be off (gray track) when toggled left and on when tggled right
   */
  switchConfig?: [SwitchConfigOption, SwitchConfigOption];
  value?: string;
}

export type SwitchFieldProps = FormFieldProps<Props>;

const SwitchField: React.SFC<SwitchFieldProps> = ({
  checked,
  disabled,
  inputClassName,
  left,
  onChange,
  right,
  switchConfig = ['on', 'on'],
  value,
  ...props
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.checked ? right : left;
      onChange(val, e);
    },
    [onChange, right, left],
  );

  return (
    <FormField<InputProps> {...props} disabled={disabled}>
      <div className={block('switch-container', { disabled })}>
        <span className={block('switch-value', { selected: value === left })}>
          {left}
        </span>
        <ReactToggle
          className={block('switch-input', {
            'left-on': switchConfig[0] === 'on',
            'right-on': switchConfig[1] === 'on',
            'left-active': value === left,
            'right-active': value === right,
          })}
          // react-toggle field is "checked" when the switch is on the right
          checked={value === right}
          disabled={disabled}
          icons={false}
          onChange={handleChange}
          role="switch"
        />
        <span className={block('switch-value', { selected: value === right })}>
          {right}
        </span>
      </div>
    </FormField>
  );
};

export default SwitchField;
