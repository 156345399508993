import * as React from 'react';
import { IconProps } from './types';

const MultiVideoCamera: React.FC<IconProps> = props => (
  <svg viewBox="0 0 42 30" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.675 9.436 2.669 26.6c0 .628.561 1.122 1.1 1.122l20.916.003C24.231 29.046 23.03 30 21.6 30H3.737c-1.826 0-3.28-1.557-3.28-3.448V12.7c0-1.506.92-2.8 2.218-3.265zM7.129 4.81l-.006 17.163c0 .628.561 1.122 1.1 1.122l20.916.003c-.454 1.321-1.656 2.276-3.086 2.276H8.191c-1.826 0-3.28-1.557-3.28-3.449V8.075c0-1.506.921-2.8 2.218-3.265zM30.507 0c1.826 0 3.281 1.557 3.281 3.448l-.002 1.769 4.45-2.25c1.36-.683 3.22.02 3.22 1.209v12.03c0 1.194-1.866 1.892-3.22 1.208l-4.45-2.246.002 2.131c0 1.891-1.455 3.449-3.28 3.449H12.644c-1.826 0-3.28-1.558-3.28-3.449V3.45C9.364 1.556 10.818 0 12.644 0h17.862zm0 2.305H12.645c-.543 0-1.009.499-1.009 1.143V17.3c0 .645.466 1.143 1.009 1.143h17.862c.544 0 1.01-.498 1.01-1.143V3.45c0-.645-.466-1.144-1.01-1.144zm8.677 2.759L33.787 7.79v4.803l.074.038 1.531.773.256.13.233.117 3.303 1.667V5.064z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default MultiVideoCamera;
