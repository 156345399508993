import cn from 'classnames';
import * as React from 'react';

import Button, { ButtonProps } from 'components/Button';
import { block } from './utils';

export type PaidSubscriptionButtonProps = ButtonProps;

const PaidSubscriptionButton: React.SFC<PaidSubscriptionButtonProps> = ({
  className,
  ...props
}) => <Button className={cn(block('button'), className)} {...props} />;

export default PaidSubscriptionButton;
