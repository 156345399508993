import * as React from 'react';

import { IconProps } from './types';

const Scissors: React.FC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="45"
    viewBox="0 0 45 45"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M27.93 22.5L44.647 5.783a1.205 1.205 0 0 0 0-1.705 8.438 8.438 0 0 0-11.932 0L21.112 15.682l-2.497-2.497a9.624 9.624 0 0 0 .67-3.542A9.643 9.643 0 0 0 9.644 0 9.643 9.643 0 0 0 0 9.643a9.643 9.643 0 0 0 10.985 9.55l3.308 3.307-3.308 3.308A9.643 9.643 0 0 0 0 35.358 9.643 9.643 0 0 0 9.643 45a9.643 9.643 0 0 0 9.643-9.643c0-1.25-.238-2.445-.671-3.542l2.497-2.497 11.603 11.604a8.438 8.438 0 0 0 11.932 0 1.205 1.205 0 0 0 0-1.705L27.93 22.5zM9.643 12.857a3.219 3.219 0 0 1-3.214-3.214 3.219 3.219 0 0 1 3.214-3.214 3.219 3.219 0 0 1 3.214 3.214 3.219 3.219 0 0 1-3.214 3.214zm0 25.714a3.219 3.219 0 0 1-3.214-3.214 3.219 3.219 0 0 1 3.214-3.214 3.219 3.219 0 0 1 3.214 3.214 3.219 3.219 0 0 1-3.214 3.214z"
    />
  </svg>
);

export default Scissors;
