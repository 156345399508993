import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import HeadlinerSocialButton from './HeadlinerSocialButton';
import { block } from './utils';

const HeadlinerSocialButtons: React.SFC = () => (
  <div className={block('social')}>
    <HeadlinerSocialButton
      icon={<FontAwesome className={block('twitter-icon')} icon="twitter" />}
      name="Twitter"
      url="https://twitter.com/headlinerapp_"
    />
    <HeadlinerSocialButton
      icon={<FontAwesome className={block('youtube-icon')} icon="youtube" />}
      name="YouTube"
      url="https://www.youtube.com/@headlinerapp"
    />
    <HeadlinerSocialButton
      icon={
        <FontAwesome
          className={block('facebook-icon')}
          icon="facebook-square"
        />
      }
      name="Facebook"
      url="https://www.facebook.com/headlinerapp/"
    />
    <HeadlinerSocialButton
      icon={
        <FontAwesome className={block('instagram-icon')} icon="instagram" />
      }
      name="Instagram"
      url="https://www.instagram.com/headliner.app/"
    />
  </div>
);

export default HeadlinerSocialButtons;
