import cn from 'classnames';
import * as React from 'react';

import { DurationField, DurationFieldProps } from 'components/Form';
import TextBoxField from './TextBoxField';
import { block } from './utils';

const TextBoxDurationField: React.SFC<DurationFieldProps> = ({
  controlClassName,
  ...props
}) => (
  <TextBoxField>
    <DurationField
      {...props}
      controlClassName={cn(block('duration-input'), controlClassName)}
    />
  </TextBoxField>
);

export default TextBoxDurationField;
export { DurationFieldProps as TextBoxDurationFieldProps };
