import * as React from 'react';

import { block } from './utils';

export interface PaidFeatureProps {
  icon: React.ReactNode;
  label: string;
}

const PaidFeature: React.SFC<PaidFeatureProps> = ({ icon, label }) => (
  <div className={block('feature')}>
    <div className={block('feature-icon-container')}>{icon}</div>
    <div className={block('feature-label')}>{label}</div>
  </div>
);

export default PaidFeature;
