import * as React from 'react';

import { useTemplateState } from '../context/VideoTemplateStateContext';
import Layer from './Layer';

const BackgroundLayer: React.FC = () => {
  const { backgroundColor } = useTemplateState();
  return <Layer style={{ backgroundColor }} />;
};

export default BackgroundLayer;
