import * as React from 'react';

import { PlanInstanceProps } from 'blocks/Pricing/types';
import { Gift } from 'components/icons';
import { freeFeatureList } from '../features';
import Plan from '../Plan';
import { freePlanBlock as block } from '../utils';
import FreePlanCta from './FreePlanCta';

export type FreePlanProps = Pick<PlanInstanceProps, 'cta'>;

const FreePlan: React.FC<FreePlanProps> = ({ cta }) => {
  return (
    <Plan className={block()}>
      <Plan.Header
        description="Perfect if you’re just getting started with your podcast promotion."
        icon={<Gift />}
        title="Forever Free"
        cost="$0"
        theme="free"
      />
      <Plan.Description
        callToAction={cta ?? <FreePlanCta />}
        features={freeFeatureList}
      />
    </Plan>
  );
};

export default FreePlan;
