import * as React from 'react';

import { constant } from 'underscore';
import { useSocialSection } from 'blocks/DownloadPage/containers/DownloadPageSocialSection/SocialSectionContext';
import LoadingOverlay from 'components/LoadingOverlay';
import { ShareAICaptionsField } from 'components/ShareForm';
import { LinkedinOrganizationSelectorOption } from './LinkedinOrganizationSelector/types';
import LinkedinPostInfoBox from './LinkedinPostInfoBox';
import LinkedinSettings from './LinkedinSettings';
import { block, MAX_CHARS_DESCRIPTION } from './utils';

export interface LinkedinPostComposerProps {
  isUploading?: boolean;
  organization: LinkedinOrganizationSelectorOption;
  organizations: LinkedinOrganizationSelectorOption[];
  description: string;
  onDescriptionChange: (description: string) => void;
  onOrganizationChange: (
    organization: LinkedinOrganizationSelectorOption,
  ) => void;
  readonly?: boolean;
  isOrganizationProfile: boolean;
}

const LinkedinPostComposer: React.FC<LinkedinPostComposerProps> = ({
  description,
  isUploading = false,
  organization,
  organizations,
  onDescriptionChange,
  onOrganizationChange,
  readonly,
  isOrganizationProfile,
}) => {
  const {
    allowAiDisclaimer,
    onUnlockAICaptions,
    toggleModalVisibility,
  } = useSocialSection();

  return (
    <>
      {isUploading && <LoadingOverlay title="Posting Video" />}
      <div className={block('share-form')}>
        <LinkedinSettings
          organization={organization}
          organizations={organizations}
          onOrganizationChange={onOrganizationChange}
        />

        <LinkedinPostInfoBox isOrganizationProfile={isOrganizationProfile} />

        <ShareAICaptionsField
          {...{ allowAiDisclaimer, onUnlockAICaptions }}
          inputProps={{
            controlClassName: block('description-control'),
            onChange: onDescriptionChange,
            value: description,
            maxChars: MAX_CHARS_DESCRIPTION,
            readonly,
            renderProgress: readonly ? constant(null) : undefined,
          }}
          onCancelUnlockAICaptions={() => toggleModalVisibility('linkedin')}
        />
      </div>
    </>
  );
};

export default LinkedinPostComposer;
