import React from 'react';
import { useSelector } from 'react-redux';

import ConnectedModal from 'containers/ConnectedModal';

import { selectedPodcastNameSelector } from 'redux/modules/claim-podcast/selectors';

import ClaimPodcastResultModalContents from './ClaimPodcastResultModalContentsContainer';
import { block } from './utils';

const ClaimPodcastModal: React.FunctionComponent = () => {
  const podcastTitle = useSelector(selectedPodcastNameSelector);

  return (
    <>
      <ConnectedModal
        backdrop="static"
        className={block('results-modal')}
        name="ClaimPodcastResultModal"
        title={podcastTitle}
      >
        <ClaimPodcastResultModalContents />
      </ConnectedModal>
    </>
  );
};

export default ClaimPodcastModal;
