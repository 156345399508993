import * as React from 'react';

import { IconProps } from './types';

const FileAudio: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="112"
    height="135"
    viewBox="0 0 112 135"
    {...props}
  >
    <defs>
      <path
        id="b"
        d="M6 0h60.618a5.75 5.75 0 0 1 4.066 1.684l19.632 19.632A5.75 5.75 0 0 1 92 25.382V109a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6a6 6 0 0 1 6-6z"
      />
      <filter
        id="a"
        width="137%"
        height="129.6%"
        x="-18.5%"
        y="-11.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuterFileAudio" />
        <feGaussianBlur
          in="shadowOffsetOuterFileAudio"
          result="shadowBlurOuterFileAudio"
          stdDeviation="5"
        />
        <feColorMatrix
          in="shadowBlurOuterFileAudio"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(10 6)">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="#19B5FE" xlinkHref="#b" />
      </g>
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M56 67.772c3.55 0 6.429-3.147 6.429-7.029V49.029C62.429 45.147 59.55 42 56 42c-3.55 0-6.429 3.147-6.429 7.029v11.714c0 3.882 2.879 7.029 6.429 7.029zM69.636 55.96h-1.363c-.754 0-1.364.624-1.364 1.396v4.188c0 6.526-5.496 11.762-12 11.113-5.667-.566-9.818-5.782-9.818-11.61v-3.691c0-.772-.61-1.396-1.364-1.396h-1.363c-.754 0-1.364.624-1.364 1.396v3.504c0 7.82 5.452 14.792 12.955 15.852v4.1h-4.773c-.754 0-1.364.625-1.364 1.396v1.396c0 .771.61 1.396 1.364 1.396h13.636c.754 0 1.364-.625 1.364-1.396v-1.396c0-.771-.61-1.396-1.364-1.396h-4.773v-4.067C65.35 75.719 71 69.3 71 61.544v-4.188c0-.772-.61-1.396-1.364-1.396z"
      />
    </g>
  </svg>
);

export default FileAudio;
