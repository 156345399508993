import React, { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import { EpisodeOption } from 'blocks/AutomationWorkflowWizard/components/EpisodesStep/types';
import DialogRadioContent from 'components/DialogRadioContent';

import EditAutomationLoader from 'containers/EditAutomationModal/EditAutomationLoader';
import EditAutomationSelectHeader from 'containers/EditAutomationModal/EditAutomationSelectHeader';
import useEditAutomationModal from 'containers/EditAutomationModal/hooks/useEditAutomationModal';
import { getSubscriptionItem } from 'containers/EditAutomationModal/utils';
import useContextualUpgrade from 'hooks/useContextualUpgrade';
import { hasBackCatalogFeatureSelector } from 'redux/modules/pricing';
import useEditAutomationEpisode from '../useEditAutomationEpisode';

interface EditAutomationEpisodeSelectionModalContentsProps {
  subscriptionId?: number;
}

const EditAutomationEpisodeSelectionModalContents: React.FunctionComponent<EditAutomationEpisodeSelectionModalContentsProps> = props => {
  const { subscriptionId } = props;

  const { subscription, onGoBackToMain } = useEditAutomationModal(
    subscriptionId,
  );
  const { onUpgrade } = useContextualUpgrade();
  const { onConfirmEpisodeSelection } = useEditAutomationEpisode({
    subscriptionId,
  });
  const subscriptionItem = getSubscriptionItem(subscription);

  const [currentFrequency, setCurrentyFrequency] = useState(
    subscriptionItem.videoFrequencyPref,
  );

  const hasBackCatalogFeature = useSelector(hasBackCatalogFeatureSelector);

  const handleSetEpisodeSelection = React.useCallback(
    (selection: EpisodeOption) => {
      if (selection === 'targetedSeason' && !hasBackCatalogFeature) {
        onUpgrade();
        return;
      }

      setCurrentyFrequency(selection);
    },
    [hasBackCatalogFeature, onUpgrade],
  );

  const handleSubmit = useCallback((): void => {
    onConfirmEpisodeSelection(currentFrequency);
  }, [currentFrequency, onConfirmEpisodeSelection]);

  return (
    <>
      <DialogRadioContent
        label={
          <EditAutomationSelectHeader
            onGoBack={onGoBackToMain}
            title="Which episodes should be included"
          />
        }
        actions={[
          {
            children: 'update automation',
            fluid: true,
            key: 'submit',
            onClick: handleSubmit,
            theme: 'submit',
          },
        ]}
        onChange={handleSetEpisodeSelection}
        options={[
          {
            value: 'targetedSeason',
            label: 'Back catalog',
            isProGated: !hasBackCatalogFeature,
          },
          {
            value: 'onPublish',
            label: 'Future episodes',
          },
        ]}
        value={
          currentFrequency !== 'onPublish' ? 'targetedSeason' : 'onPublish'
        }
      />
      <EditAutomationLoader />
    </>
  );
};

export default EditAutomationEpisodeSelectionModalContents;
