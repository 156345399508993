import cn from 'classnames';
import * as React from 'react';
import { omit, pick } from 'underscore';

import { cloneOnlyChild } from 'utils/react';
import { block } from './utils';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

type Props<T = {}> = IProps & T;

/**
 * wraps the form input. control is just another name for input since input is also the name of an
 * html tag and this component can handle any arbitrary react element as its child
 */

function InputControl<T>(props: Props<T>) {
  const { children, className } = pick(props, 'children', 'className');
  const restProps = omit(props, 'children', 'className');

  return cloneOnlyChild(children, child => ({
    ...restProps,
    className: cn(block('input-control'), className, child.props.className),
  }));
}

export default InputControl;
export { Props as InputControlProps };
