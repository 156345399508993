import { connect } from 'react-redux';

import { createSelector } from 'reselect';
import { addingToTrack } from 'redux/modules/embed/actions/tracks';
import { tracksByIdSelector } from 'redux/modules/embed/selectors';
import { pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { PropsOf } from 'types';
import AddAssetButton, { AddAssetButtonProps as Props } from './AddAssetButton';

type DispatchProps = Pick<Props, 'onClick'>;
type StateProps = Pick<Props, 'params'>;
interface OwnProps {
  trackId: string;
}

interface Params {
  trackId: string;
  trackType: 'string';
}

const paramsSelector = createSelector(
  (_, trackId: string) => trackId,
  tracksByIdSelector,
  (trackId, tracks): Params => ({
    trackId,
    trackType: tracks?.getIn([trackId, 'type']),
  }),
);

const mapStateToProps = (state, { trackId }: OwnProps): StateProps => ({
  params: paramsSelector(state, trackId),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onClick: (_1, { trackId, trackType }) => {
    dispatch(addingToTrack(trackId));

    if (trackType === 'text') {
      dispatch(pushModal({ name: 'AddTextModal' }));
    }

    if (trackType === 'media') {
      dispatch(pushModal({ name: 'AddMediaModal' }));
    }

    if (trackType === 'audio') {
      dispatch(pushModal({ name: 'AddAudioModal' }));
    }

    if (trackType === 'waveform') {
      dispatch(pushModal({ name: 'AddWaveformModal' }));
    }
  },
});

const component = connect(mapStateToProps, mapDispatchToProps)(AddAssetButton);

export type AddAssetButtonProps = PropsOf<typeof component>;
export default component;
