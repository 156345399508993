export function wrapInPromise<T>(fn: () => T | Promise<T>): Promise<T> {
  try {
    return Promise.resolve(fn());
  } catch (err) {
    return Promise.reject(err);
  }
}

export const delay = <T>(millis: number, value?: T | Error): Promise<T> =>
  new Promise((resolve, reject) => {
    window.setTimeout(() => {
      if (value instanceof Error) {
        reject(value);
      } else {
        resolve(value);
      }
    }, millis);
  });
