import * as React from 'react';

import { H2, H4 } from 'components/Heading';

interface IProps {
  primaryLabel: string;
  secondaryLabel?: string;
}

const FormLabel: React.SFC<IProps> = ({ primaryLabel, secondaryLabel }) => (
  <div>
    <H2>{primaryLabel}</H2>
    {secondaryLabel && <H4 as="div">{secondaryLabel}</H4>}
  </div>
);

export default FormLabel;
