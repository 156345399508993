import cn from 'classnames';
import React from 'react';

import { useSelector } from 'react-redux';
import { isFinite, isUndefined } from 'underscore';
import { VideoCamera } from 'components/icons';
import UpsellBanner, { UpsellBannerProps } from 'components/UpsellBanner';
import useContextualUpgrade from 'hooks/useContextualUpgrade';
import { Tier } from 'redux/middleware/api/plan-service/types';
import { exportBalanceSelector } from 'redux/modules/pricing/selectors';
import bem from 'utils/bem';
import { createChainedFunction } from 'utils/functions';

export interface Props {
  className?: string;
  onUpgradeClick?: UpsellBannerProps['onUpgradeClick'];
}

const block = bem('export-balance-banner');

const ExportBalanceBanner: React.FC<Props> = props => {
  const { className, onUpgradeClick } = props;
  const videoBalance = useSelector(exportBalanceSelector);
  const { onUpgrade } = useContextualUpgrade({
    allowTierSelection: false,
    purchaseTier: Tier.PRO,
  });

  if (isUndefined(videoBalance) || !isFinite(videoBalance)) {
    return null;
  }

  return (
    <UpsellBanner
      className={cn(className, block())}
      heading={
        <span>
          <strong>
            {videoBalance} unwatermarked{' '}
            {videoBalance === 1 ? 'video' : 'videos'} left this month
          </strong>
        </span>
      }
      icon={<VideoCamera />}
      onUpgradeClick={createChainedFunction(onUpgradeClick, onUpgrade)}
    />
  );
};

export default ExportBalanceBanner;
