import { useCallback, useMemo, useRef, useState } from 'react';
import { DraggableEvent, DraggableEventHandler } from 'react-draggable';
import _ from 'underscore';

import useKeyBind from 'hooks/useKeyBind';
import useStaticCallback from 'hooks/useStaticCallback';
import { withValue } from 'utils/control';
import {
  DragAxis,
  OnSnapDrag,
  UseSnapLinesConfig,
  UseSnapLinesResult,
} from './types';
import { getDragAxis, isHorizontal, isVertical, toPxLine } from './utils';

const { noop, partial } = _;

type FireDragEvent = (
  ...args: [...Parameters<DraggableEventHandler>, OnSnapDrag]
) => void;

const DRAG_LOCK_COUNTER_MIN = 10;

export default function useSnapLines(
  config: UseSnapLinesConfig,
): UseSnapLinesResult {
  const {
    anchors = ['center'],
    containerSize,
    defaultDragAxis = 'both',
    lines = [],
    onDrag: onDragProp = noop,
    onDragStart: onDragStartProp = noop,
    onDragStop: onDragStopProp = noop,
    size,
    stickiness = 10,
  } = config;

  const dragThresholdCounter = useRef(0);

  const [defaultAxis] = useState(defaultDragAxis);
  const [resizing, setResizing] = useState(false);
  const alignEdge = anchors.includes('edge');
  const alignCenter = anchors.includes('center');

  const onDragStart = useStaticCallback(onDragStartProp);
  const onDrag = useStaticCallback(onDragProp);
  const onDragStop = useStaticCallback(onDragStopProp);

  const pxLines = useMemo(() => {
    return lines.map(line => toPxLine(line, containerSize));
  }, [containerSize, lines]);

  const [dragAxis, setDragAxis] = useState<DragAxis>(defaultDragAxis);
  const [activeLines, setActiveLines] = useState([]);
  const modifierPressed = useKeyBind({
    bindings: {
      Control: false,
      Meta: false,
    },
  });

  // Snap will be blocked under the following conditions:
  // - if event is mousedown: prevents non desired snap to position when clicking
  // - if event is mouseup and threshold has not been meet: prevents a non desired snap to position
  // when asset is clicked.
  // - if event is mousemove and threshold has not been meet: prevents a small blink that might
  // sometimes happen when clicking an asset. The blink would move asset quickly to snap and back
  // again to its original position.
  const isSnapBlocked = useCallback((e: DraggableEvent): boolean => {
    return (
      e?.type === 'mousedown' ||
      (dragThresholdCounter.current < DRAG_LOCK_COUNTER_MIN &&
        (e?.type === 'mouseup' || e?.type === 'mousemove'))
    );
  }, []);

  // While dragging an asset the drag lock counter is increased. Snap controls will be triggered
  // only if the treshold is passed. For avoiding unnecessary re renders, counter will not be updated
  // after max has been passed.
  const controlDragThreshold = useCallback((e: DraggableEvent) => {
    if (e.type === 'mousemove') {
      const currCount = dragThresholdCounter.current;
      dragThresholdCounter.current =
        DRAG_LOCK_COUNTER_MIN + 1 ? currCount + 1 : currCount;
    } else {
      dragThresholdCounter.current = 0;
    }
  }, []);

  const isWithinSnapRange = useCallback(
    (value: number, target: number) => {
      return value > target - stickiness && value < target + stickiness;
    },
    [stickiness],
  );

  const getSnappedCoord = useCallback(
    (value: number, target: number, dimension: number) => {
      if (alignEdge) {
        if (isWithinSnapRange(value, target)) {
          return target - value;
        }
        if (isWithinSnapRange(value + dimension, target)) {
          return target - dimension;
        }
      }

      if (alignCenter) {
        if (isWithinSnapRange(value + dimension / 2, target)) {
          return target - dimension / 2;
        }
      }

      return undefined;
    },
    [alignCenter, alignEdge, isWithinSnapRange],
  );

  const fireDragEvent: FireDragEvent = useCallback(
    (e, dragData, cb) => {
      if (modifierPressed) {
        onDrag(e, dragData, {
          x: dragData.x,
          y: dragData.y,
          lines: [],
          dragAxis: defaultDragAxis,
        });
        setActiveLines(current => (!_.isEqual(current, []) ? [] : current));
        setDragAxis(defaultDragAxis);
        return;
      }

      const { x, y } = dragData;
      const { height, width } = size;

      const snappedX = pxLines.filter(isVertical).map(line => {
        // line is vertical, so from.x and to.x should be the same
        const coord = getSnappedCoord(x, line.from.x, width);
        return withValue(coord, value => ({ value, line }));
      })[0];

      const snappedY = pxLines.filter(isHorizontal).map(line => {
        // line is horizontal, so from.y and to.y should be the same
        const coord = getSnappedCoord(y, line.from.y, height);
        return withValue(coord, value => ({ value, line }));
      })[0];

      const snapBlocked = isSnapBlocked(e);
      controlDragThreshold(e);

      const snapData = snapBlocked
        ? {
            x: dragData.x,
            y: dragData.y,
            lines: [],
            dragAxis: defaultAxis,
          }
        : {
            x: snappedX?.value ?? dragData.x,
            y: snappedY?.value ?? dragData.y,
            lines: [snappedX?.line, snappedY?.line].filter(Boolean),
            dragAxis: getDragAxis(
              snappedX?.value !== undefined,
              snappedY?.value !== undefined,
              defaultAxis,
            ),
          };

      setActiveLines(current =>
        !_.isEqual(snapData.lines, current) ? snapData.lines : current,
      );
      setDragAxis(snapData.dragAxis);

      cb(e, dragData, snapData);
    },
    [
      controlDragThreshold,
      defaultAxis,
      defaultDragAxis,
      getSnappedCoord,
      isSnapBlocked,
      modifierPressed,
      onDrag,
      pxLines,
      size,
    ],
  );

  const handleDragStart = useCallback(
    partial(fireDragEvent, _, _, onDragStart),
    [fireDragEvent, onDragStart],
  );

  const handleDragStop = useCallback(partial(fireDragEvent, _, _, onDragStop), [
    fireDragEvent,
    onDragStop,
  ]);

  const handleDrag = useCallback(partial(fireDragEvent, _, _, onDrag), [
    fireDragEvent,
    onDrag,
  ]);

  const handleResizeStart = useCallback(() => {
    setResizing(true);
  }, []);

  const handleResizeStop = useCallback(() => {
    setResizing(false);
  }, []);

  return useMemo(
    () => ({
      activeLines,
      dragAxis: resizing ? defaultDragAxis : dragAxis,
      onDrag: handleDrag,
      onDragStart: handleDragStart,
      onDragStop: handleDragStop,
      onResizeStart: handleResizeStart,
      onResizeStop: handleResizeStop,
    }),
    [
      activeLines,
      defaultDragAxis,
      dragAxis,
      handleDrag,
      handleDragStart,
      handleDragStop,
      handleResizeStart,
      handleResizeStop,
      resizing,
    ],
  );
}
