import cn from 'classnames';
import * as React from 'react';
import { block } from './utils';

interface Props {
  className?: string;
}

export const BannerActions: React.FC<Props> = props => {
  const { children, className } = props;
  return <div className={cn(block('actions'), className)}>{children}</div>;
};

export default BannerActions;
