import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WaveformPresetList from 'components/WaveformPresetList';
import { getMyUserPref } from 'redux/modules/user-pref/actions';
import { createWaveformPrefIdsSelector } from 'redux/modules/user-pref/selectors';
import { Dispatch } from 'redux/types';
import { Soundwave } from 'types';
import WaveformPreset from './WaveformPreset';

interface Props {
  aspectRatio: number;
  onDeleted?: () => void;
  onSelect?: (soundwave: Soundwave) => void;
  selectedWaveformPrefId?: number;
}

const { useEffect, useMemo } = React;

function WaveformPresets(props: Props) {
  const dispatch = useDispatch<Dispatch>();
  const { aspectRatio, selectedWaveformPrefId, onDeleted, onSelect } = props;
  const presetIds = useSelector(
    useMemo(() => createWaveformPrefIdsSelector(aspectRatio), [aspectRatio]),
  );

  useEffect(() => {
    dispatch(getMyUserPref());
  }, [dispatch]);

  return (
    <WaveformPresetList>
      {presetIds
        ?.slice()
        ?.reverse()
        ?.map(waveformPrefId => (
          <WaveformPreset
            key={String(waveformPrefId)}
            id={waveformPrefId}
            checked={selectedWaveformPrefId === waveformPrefId}
            onSelect={onSelect}
            onDeleted={onDeleted}
          />
        ))}
    </WaveformPresetList>
  );
}

export default WaveformPresets;
