import { useCallback } from 'react';
import { noop } from 'underscore';
import usePreviousRef from 'hooks/usePreviousRef';
import { useEditorState } from './context/VideoEditorStateContext';

import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import { useModalManager } from './ModalManager';
import { AddTextProps } from './ModalManager/types';

type UseAddTextModalConfig = Pick<AddTextProps, 'onSubmit'>;

export default function useAddTextModal(config: UseAddTextModalConfig = {}) {
  const { onSubmit = noop } = config;
  const state = useTemplateState();
  const modalManager = useModalManager();
  const dispatch = useTemplateDispatch();
  const { integrations } = useEditorState();
  const {
    dynamicTextIntegrations,
    supportsDynamicText,
    supportsStaticText,
    staticTextIntegrations,
    textIntegrations,
  } = integrations;
  const textIntegrationsRef = usePreviousRef(textIntegrations);

  const { hideModal, showModal } = modalManager;

  const handleAddIntegrationText = useCallback(
    (integrationId: string) => {
      const integration = textIntegrationsRef.current.find(
        i => i.id === integrationId,
      );
      integration.onAddText(state, modalManager, (overlay, integrationData) => {
        dispatch({
          payload: {
            textOverlay: overlay,
            integrationData,
          },
          type: 'TEXT_ADD',
        });
        onSubmit(overlay);
      });
    },
    [dispatch, modalManager, onSubmit, state, textIntegrationsRef],
  );

  const handleAddStaticText = useCallback(() => {
    handleAddIntegrationText(staticTextIntegrations[0].id);
  }, [handleAddIntegrationText, staticTextIntegrations]);

  const handleAddDynamicText = useCallback(() => {
    handleAddIntegrationText(dynamicTextIntegrations[0].id);
  }, [dynamicTextIntegrations, handleAddIntegrationText]);

  return useCallback(() => {
    if (supportsDynamicText && supportsStaticText) {
      showModal('add-text-type', {
        onSubmit(newTextType) {
          hideModal();
          if (newTextType === 'static') {
            handleAddStaticText();
          } else {
            handleAddDynamicText();
          }
        },
      });
    } else if (supportsDynamicText) {
      handleAddDynamicText();
    } else if (supportsStaticText) {
      handleAddStaticText();
    } else {
      handleAddIntegrationText(textIntegrations[0].id);
    }
  }, [
    handleAddDynamicText,
    handleAddIntegrationText,
    handleAddStaticText,
    hideModal,
    showModal,
    supportsDynamicText,
    supportsStaticText,
    textIntegrations,
  ]);
}
