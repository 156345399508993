import { createSelector } from 'reselect';

import { QuestionId } from 'redux/middleware/api/headliner-user-service';
import { State } from 'redux/types';
import { isNotEnterpriseApiSelector } from '../pricing';
import { SurveyState } from './types';

const stateSelector = (state: State): SurveyState => state.get('survey');

export const surveyDataSelector = createSelector(
  stateSelector,
  state => state.data,
);

export const surveyStatusSelector = createSelector(
  stateSelector,
  state => state.status,
);

export const isPodcasterSelector = createSelector(
  surveyDataSelector,
  surveyData => surveyData && surveyData[QuestionId.PODCAST] === 'yes',
);

export const hasAnsweredSurveySelector = createSelector(
  [surveyStatusSelector, isNotEnterpriseApiSelector],
  (surveyStatus, isNotEnterprise) =>
    surveyStatus === undefined || isNotEnterprise === undefined
      ? undefined
      : !isNotEnterprise || surveyStatus === 'completed',
);
