export enum FetchStatus {
  FETCHING = 'fetching',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export const defaultWaveTypes = [
  { value: 'none', displayName: 'None' },
  { value: 'linearDots', displayName: 'Dots' },
  { value: 'blob', displayName: 'Halo' },
  { value: 'pulse', displayName: 'Pulse' },
  { value: 'burst', displayName: 'Burst' },
  { value: 'waveBars', displayName: 'Wave Bars' },
  { value: 'wave', displayName: 'Wave' },
  { value: 'roundBars', displayName: 'Round Bars' },
  { value: 'line', displayName: 'Line' },
  { value: 'curve', displayName: 'Curve' },
  { value: 'bricks', displayName: 'Bricks' },
  { value: 'pixel', displayName: 'Pixelated' },
  { value: 'equalizer', displayName: 'Equalizer' },
  { value: 'wideRoundBars', displayName: 'Wide Round Bars' },
];

// these positions are hidden from normal users
// 'prisa-default' gets added to Prisa users from overrideOptions
// 'lower-third' gets added to Entercom users from overrideOptions
export const hiddenWavePositions = ['prisa-default', 'lower-third'];

export const defaultWavePositions = [
  { value: 'prisa-default', displayName: 'Prisa Default' },
  { value: 'lower-third', displayName: 'Lower Third' },
  { value: 'custom', displayName: 'Custom' },
  { value: 'top', displayName: 'Top' },
  { value: 'middle', displayName: 'Middle' },
  { value: 'padded-bottom', displayName: 'Padded Bottom' },
  { value: 'bottom', displayName: 'Bottom' },
];

export const customWavePositions = [
  { value: 'custom', displayName: 'Custom' },
  { value: 'top', displayName: 'Top' },
  { value: 'middle', displayName: 'Middle' },
  { value: 'bottom', displayName: 'Bottom' },
];

export const defaultWaveGenerations = [
  { value: 'amplitudeBased', displayName: 'Fast (low fidelity)' },
  { value: 'accurate', displayName: 'Slow (high fidelity)' },
];

export const circleWaveTypes = ['burst', 'blob', 'pulse'];

export const customWaveTypes = ['linearDots'];
