import cn from 'classnames';
import * as React from 'react';

import PlacementPopper, {
  PlacementPopperProps,
} from '../Popper/PlacementPopper';
import { progressPlacementTileBlock as block } from '../utils';
import ProgressPlacementPopper, {
  ProgressPlacementPopperProps,
} from './ProgressPlacementPopper';

type PickedPopperProps = Pick<
  ProgressPlacementPopperProps,
  'onChange' | 'value'
> &
  Pick<PlacementPopperProps, 'disabled'>;

export interface ProgressPlacementTileProps extends PickedPopperProps {
  className?: string;
}

const ProgressPlacementTile: React.FC<ProgressPlacementTileProps> = ({
  className,
  disabled,
  onChange,
  value,
}) => (
  <PlacementPopper className={cn(block(), className)} disabled={disabled}>
    <ProgressPlacementPopper {...{ onChange, value }} />
  </PlacementPopper>
);

export default ProgressPlacementTile;
