import cn from 'classnames';
import * as React from 'react';
import { ProgressType } from 'types';

import bem from 'utils/bem';

export interface VideoProgressPreviewProps {
  className?: string;
  type?: ProgressType;
  color: string;
  fillColor: string;
}

const block = bem('video-progress-preview');

const VideoProgressPreview: React.FC<VideoProgressPreviewProps> = props => {
  const { className, color, fillColor, type = ProgressType.BAR } = props;
  return (
    <div
      className={cn(block({ [type]: true }), className)}
      style={{ color: fillColor }}
    >
      <div className={block('background')} style={{ backgroundColor: color }}>
        <div className={block('fill')} style={{ backgroundColor: fillColor }} />
      </div>
    </div>
  );
};

export default VideoProgressPreview;
