import React from 'react';
import { RootCloseWrapper } from 'react-overlays';

import BemCssTransition from 'components/BemCssTransition';
import FontAwesome from 'components/FontAwesome';
import { InputAdornment } from 'components/Form';
import FacebookPreset from 'components/icons/FacebookPreset';
import InstagramPreset from 'components/icons/InstagramPreset';
import TiktokPreset from 'components/icons/TiktokPreset';
import YoutubePreset from 'components/icons/YoutubePreset';
import Popper from 'components/Popper/Popper';

import AudioClipperSocialPresetOptionGroup from './SocialPresetOptionGroup';
import { SocialPresetOption } from './types';
import {
  block,
  FACEBOOK_OPTIONS,
  INSTAGRAM_OPTIONS,
  MORE_OPTIONS,
  TIKTOK_OPTIONS,
  YOUTUBE_OPTIONS,
} from './utils';

interface Props {
  disabled?: boolean;
  maxDurationMillis?: number;
  maxMillis: number;
  onChange?: (durationMillis: number) => void;
  onPickPreset?: (preset: SocialPresetOption) => void;
  presetBlocked?: boolean;
}

const { useState, useCallback } = React;

function ClipDurationInputAdornment(props: Props) {
  const {
    disabled,
    maxDurationMillis,
    maxMillis,
    onChange,
    onPickPreset,
    presetBlocked,
  } = props;

  const [open, setOpen] = useState(false);

  const presetSelectionOpen = open && !presetBlocked;

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    if (!disabled) {
      setOpen(true);
    }
  }, [disabled]);

  const handleChange = useCallback(
    (preset: SocialPresetOption) => {
      onPickPreset?.(preset);
      onChange(preset.durationMs);
      setOpen(false);
    },
    [onChange, onPickPreset],
  );

  const moreOptions = React.useMemo(() => {
    return MORE_OPTIONS.map(option =>
      // when maxDurationMillis is infinite or not defined, the audio/video max duration is set as durationMs for any
      // option that has useMaxAvailableDuration set (for example full clip option)
      option.useMaxAvailableDuration &&
      (maxDurationMillis === Infinity || maxDurationMillis === undefined)
        ? { ...option, durationMs: maxMillis }
        : option,
    );
  }, [maxDurationMillis, maxMillis]);

  return (
    <InputAdornment onMouseOver={presetSelectionOpen ? null : handleOpen}>
      <FontAwesome icon="clock" color="#19b5fe" />
      <RootCloseWrapper
        disabled={!presetSelectionOpen}
        onRootClose={handleClose}
      >
        <BemCssTransition
          transitionClassName={block('popper')}
          in={presetSelectionOpen}
          timeout={300}
          unmountOnExit
        >
          <Popper onMouseOver={e => e.stopPropagation()}>
            <div>
              <AudioClipperSocialPresetOptionGroup
                icon={<InstagramPreset />}
                label="instagram"
                onPickOption={handleChange}
                options={INSTAGRAM_OPTIONS}
              />
              <AudioClipperSocialPresetOptionGroup
                icon={<YoutubePreset />}
                label="youtube"
                onPickOption={handleChange}
                options={YOUTUBE_OPTIONS}
              />
            </div>
            <div>
              <AudioClipperSocialPresetOptionGroup
                icon={<FacebookPreset />}
                label="facebook"
                onPickOption={handleChange}
                options={FACEBOOK_OPTIONS}
              />
              <AudioClipperSocialPresetOptionGroup
                icon={<TiktokPreset />}
                label="tiktok"
                onPickOption={handleChange}
                options={TIKTOK_OPTIONS}
              />
            </div>
            <div>
              <AudioClipperSocialPresetOptionGroup
                label="other options"
                onPickOption={handleChange}
                options={moreOptions}
              />
            </div>
          </Popper>
        </BemCssTransition>
      </RootCloseWrapper>
    </InputAdornment>
  );
}

export default ClipDurationInputAdornment;
