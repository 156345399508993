import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import AddClipCaptionsModalContents from './AddClipCaptionsModalContents';

export interface AddClipCaptionsModalProps {}

const AddClipCaptionsModal: React.FC<AddClipCaptionsModalProps> = () => (
  <ConnectedModal name="AddClipCaptions" title="captions">
    <AddClipCaptionsModalContents />
  </ConnectedModal>
);

export default AddClipCaptionsModal;
