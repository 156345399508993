import * as React from 'react';

import Button from 'components/Button';
import { Download } from 'components/icons';
import bem from 'utils/bem';
import { downloadLinkFromS3 } from 'utils/download';
import ShareButton from '../../components/ShareButton';

export interface DownloadButtonProps {
  fileName?: string;
  onClick?: () => void;
  url: string;
  single: boolean;
}

const block = bem('download-button');

const DownloadButton: React.FC<DownloadButtonProps> = ({
  fileName,
  onClick,
  url,
  single,
}) => {
  const handleDownloadClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();

      downloadLinkFromS3(url, fileName);

      onClick?.();
    },
    [fileName, onClick, url],
  );

  if (single) {
    return (
      <Button
        fluid
        shape="chip"
        theme="submit"
        href={url}
        onClick={handleDownloadClick}
      >
        <Download width={16} height="100%" />
        <span>Download video</span>
      </Button>
    );
  }

  return (
    <ShareButton
      className={block()}
      download={fileName}
      href={url}
      label="Download"
      onClick={onClick}
    >
      <Download width={35} />
    </ShareButton>
  );
};

export default DownloadButton;
