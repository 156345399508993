import { useCallback, useEffect, useState } from 'react';
import { noop } from 'underscore';

type AnimationStatus = 'stopped' | 'playing' | 'stop-pending';

// the transition duration for the status opacity.  should match css rules
const statusAnimationDuration = 300;

// the progress animation is a gradient that slides from left to right.  before
// this gradient reaches the end, the status should start fading back in.  this
// number is the duration in milliseconds from the beginning of the animation
// after which the status can begin fading back in.
const progressAnimationInDuration = 2800;

// the entire progress animation duration. should match css rules.
const progressAnimationDuration = 4000;

export interface UseProgressAnimationConfig {
  inProgress: boolean;
  onAnimationBegin?: () => void;
  onAnimationEnd?: () => void;
}

/**
 * the progress animation timing is hacked to support a smoother group of
 * transitions.
 *
 * the progress animation loops until inProgress becomes false.  each loop of
 * the progress animation completes in entirety, so if a loop begins and
 * inProgress becomes false right at the start, the animation loop will still
 * complete before transitioning to the "resolved" state.
 *
 * before the end of the last animation loop, the status component fades back in.
 * in order to know if the status component can fade back in, we need to check
 * the value of inProgress before the end of the current animation loop - that
 * way we know if we should begin another loop or fade the status back in.
 *
 * the progress animation timing is hacked as follows:
 *    - assume that the whole transition of the progress bar is 4000ms. there
 *      should be a css transition rule somewhere that sets the progress bar's
 *      animation to 4000ms
 *    - the progress bar "enter" animation is less than that, e.g. 2800ms. this
 *      means that onEntered will be called after 2800ms and we can use that
 *      callback to check the satus of inProgress
 *    - the progress bar "exit" animation is delayed by the difference,
 *      4000 - 2800 = 1200ms.  this delay means that the "exit" classes won't
 *      be applied immediately after enter completes, leaving the enter classes
 *      in place and thereby giving the progress animation time to finish. after
 *      the 1200ms, onExited will be called and we can check if the entire
 *      animation is complete
 *    - when "onExited" is called, 2800 + 1200 = 4000ms has elapsed and the
 *      animation loop is finished.  if at the 2800 mark we saw that inProgress
 *      had become false, then the entire animation has finished.
 */
export default function useProgressAnimation({
  inProgress,
  onAnimationBegin = noop,
  onAnimationEnd = noop,
}: UseProgressAnimationConfig) {
  const [animationStatus, setAnimationStatus] = useState<AnimationStatus>(
    'stopped',
  );

  // corresponds to the "in" prop for a BemCssTransition.  in order to effectively
  // loop the animation, we must flip between true and false
  const [progressTransitionIn, setProgressTransitionIn] = useState(false);

  const progressDelay = {
    // don't begin the progress animation until the status animation has
    // completely transitioned out
    enter: statusAnimationDuration,
    exit: progressAnimationDuration - progressAnimationInDuration,
  };

  const progressTimeout = {
    enter: progressAnimationInDuration,
    exit: 0,
  };

  // showing the status component when the animation status is stop-pending
  // allows the status to fade back in before the animation is completely stopped
  const showStatus =
    animationStatus === 'stopped' || animationStatus === 'stop-pending';

  useEffect(() => {
    if (inProgress) {
      setAnimationStatus('playing');
      setProgressTransitionIn(true);
      onAnimationBegin();
    }
  }, [inProgress, onAnimationBegin]);

  // called when the progress overlay has finished animating from left to right
  // and is now completely finished with the transition
  const onProgressAnimationEntered = useCallback(() => {
    setProgressTransitionIn(false);

    if (!inProgress) {
      setAnimationStatus('stop-pending');
    }
  }, [inProgress]);

  // called right after the progress overlay transition finishes.  the exit
  // transition is instant is only used to reset the transition
  const onProgressAnimationExited = useCallback(() => {
    if (animationStatus === 'stop-pending') {
      setAnimationStatus('stopped');
      onAnimationEnd();
    } else {
      setProgressTransitionIn(true);
    }
  }, [animationStatus, onAnimationEnd]);

  return {
    isAnimating:
      animationStatus === 'playing' || animationStatus === 'stop-pending',
    onProgressAnimationEntered,
    onProgressAnimationExited,
    progressDelay,
    progressTimeout,
    progressTransitionIn,
    showStatus,
  };
}
