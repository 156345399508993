import * as React from 'react';

import LoadingStep from '../../LoadingStep';
import { block } from '../utils';

const LoadingAssetsSpinner: React.SFC = () => (
  <div className={block('loading-assets')}>
    <div className={block('loading-assets-message')}>
      Hang on! We're retrieving settings and assets.
    </div>
    <LoadingStep message="Retrieving Assets" />
  </div>
);

export default LoadingAssetsSpinner;
