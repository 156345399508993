import React from 'react';

import { CaptionsAnimationsCard } from 'components/CaptionsAnimationsForm';
import { ColorPickerProps } from 'components/ColorPicker';
import { ColorChangeField } from 'components/Form';

import { KARAOKE_PREVIEW_URL } from 'utils/constants';
import useTextOverlayFontStyles from '../../../hooks/useTextOverlayFontStyles';
import { useTextOverlay } from '../../../state/TextOverlayProvider';
import { block } from '../../../utils';
import { AnimationModule } from '../types';

const DEFAULT_TO_COLOR = 'rgb(255,255,255,1)';
const COLOR_PICKER_VERTICAL_CORRECTION_PX = 150;
const COLOR_PICKER_HORIZONTAL_CORRECTION_PX = 220;

const KaraokeAnimationControl: React.FunctionComponent = () => {
  const { draftEditorData, onUpdateOverlay } = useTextOverlay();
  const { selectedFontColor, onChangeFontColor } = useTextOverlayFontStyles();

  const handleUpdateToColor = React.useCallback(
    (color: string): void => {
      onUpdateOverlay(overlay =>
        overlay.withMutations(s =>
          s.setIn(['animation', 'follow', 'textStyle', 'color'], color),
        ),
      );
    },
    [onUpdateOverlay],
  );

  const handleEnableAnimation = React.useCallback(
    (nextEnabled: boolean): void => {
      onUpdateOverlay(overlay =>
        overlay.withMutations(s =>
          s.setIn(['animation', 'follow', 'enabled'], nextEnabled),
        ),
      );
    },
    [onUpdateOverlay],
  );

  const colorPickerPositioner: ColorPickerProps['popoverPositioner'] = React.useCallback(
    (_, rect, __, exceedsViewportHeight) => ({
      left: rect.x - COLOR_PICKER_HORIZONTAL_CORRECTION_PX,
      top: exceedsViewportHeight
        ? rect.y - COLOR_PICKER_VERTICAL_CORRECTION_PX
        : undefined,
    }),
    [],
  );

  const enabled =
    draftEditorData.getIn(['animation', 'follow', 'enabled']) ?? false;
  const fromColor = selectedFontColor;
  const toColor =
    draftEditorData.getIn(['animation', 'follow', 'textStyle', 'color']) ??
    DEFAULT_TO_COLOR;

  return (
    <CaptionsAnimationsCard
      animationEnabled={enabled}
      className={block('animations-config-card')}
      title="Karaoke Style"
      previewImageUrl={KARAOKE_PREVIEW_URL}
      renderSettings={() => (
        <ColorChangeField
          label="Color Transitions"
          className={block('animations-config-change-field-control')}
          fromPopoverPositioner={colorPickerPositioner}
          horizontal={{
            control: { xs: 6 },
            label: { xs: 6 },
          }}
          onFromColorChange={onChangeFontColor}
          onToColorChange={handleUpdateToColor}
          toPopoverPositioner={colorPickerPositioner}
          value={{ fromColor, toColor }}
        />
      )}
      onAnimationEnabledChange={handleEnableAnimation}
    />
  );
};

const karaokeAnimationModule: AnimationModule = {
  key: 'karaoke',
  name: 'Karaoke',
  control: KaraokeAnimationControl,
};

export default karaokeAnimationModule;
