import * as React from 'react';
import { noop } from 'underscore';

import FontAwesome from 'components/FontAwesome';
import Select, { Overrides, SelectProps } from 'components/Select';
import PageOption from './PageOption';
import { Option } from './types';
import { block } from './utils';

export const SWITCH_ACCOUNT_VALUE = 'switch-account';

type PickedSelectProps = Pick<SelectProps<Option>, 'onChange' | 'value'>;

export interface FacebookPageSelectorProps extends PickedSelectProps {
  onSwitchAccountClick?: () => void;
  options: Option[];
}

const selectComponentOverrides: Overrides<Option> = {
  option: {
    component: props => <PageOption {...props} />,
  },
};

const FacebookPageSelector: React.SFC<FacebookPageSelectorProps> = ({
  onChange,
  onSwitchAccountClick,
  options,
  value,
}) => {
  const handleChange = (val: Option) => {
    if (val.value === SWITCH_ACCOUNT_VALUE) {
      onSwitchAccountClick();
    } else {
      onChange(val);
    }
  };

  return (
    <Select
      onChange={handleChange}
      options={[
        ...options,
        {
          label: 'Switch Account',
          logo: (
            <FontAwesome
              className={block('switch-account-icon')}
              icon="sign-out-alt"
            />
          ),
          value: SWITCH_ACCOUNT_VALUE,
        } as Option,
      ]}
      overrides={selectComponentOverrides}
      value={value}
    />
  );
};

FacebookPageSelector.defaultProps = {
  onChange: noop,
  onSwitchAccountClick: noop,
};

// TODO maybe put in types file
export { Option };

export default FacebookPageSelector;
