import * as React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import UpdateEmailModalContent from './UpdateEmailModalContent';
import { block } from './utils';

const UpdateEmailModal = () => {
  return (
    <ConnectedModal
      name="UpdateEmail"
      title="Update Your Email Address"
      className={block()}
    >
      <UpdateEmailModalContent />
    </ConnectedModal>
  );
};

export default UpdateEmailModal;
