import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import FakeFeatureChosenModalContents from './FakeFeatureChosenModalContents';

const FakeFeatureChosenModal: React.FunctionComponent = () => {
  return (
    <ConnectedModal name="FakeFeatureChosenModal">
      <FakeFeatureChosenModalContents />
    </ConnectedModal>
  );
};

export default FakeFeatureChosenModal;
