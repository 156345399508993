import { RecordOf } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { IRevisionHistoryProps } from 'containers/RevisionHistory';
import { revisionHistorySelector } from 'redux/modules/entities/selectors';
import { onCickRevertProject } from 'redux/modules/mixpanel/actions';
import { updateCurrentProject } from 'redux/modules/project/actions';
import { Dispatch, State } from 'redux/types';
import { IRevisionItemData } from './types';

type StateProps = Pick<IRevisionHistoryProps, 'revisionHistory'>;
export type DispatchProps = Pick<IRevisionHistoryProps, 'restoreToVersion'>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  restoreToVersion: (itemToRestore: RecordOf<IRevisionItemData>) => {
    const embedConfigurationUuid = itemToRestore.get('embedConfigurationUuid');
    const projectName = itemToRestore.get('projectName');
    const updatedAt = Number(itemToRestore.get('updatedAt'));

    dispatch(onCickRevertProject(updatedAt));

    return dispatch(updateCurrentProject(embedConfigurationUuid, projectName));
  },
});

const mapStateToProps = (state: State): StateProps => ({
  revisionHistory: revisionHistorySelector(state),
});

export default function(component: React.ComponentType<IRevisionHistoryProps>) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
