import * as React from 'react';
import FontAwesome, { IconProp } from 'components/FontAwesome';
import Circular from './circular';

type Props = Omit<IconProp, 'icon'>;

const EducationIcon: React.FC<Props> = props => {
  return (
    <Circular variant="orange" elevated>
      <FontAwesome icon="hand-holding-heart" {...props} />
    </Circular>
  );
};

EducationIcon.displayName = 'EducationIcon';

export default EducationIcon;
