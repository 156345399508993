import classNames from 'classnames';
import * as React from 'react';

import { formatDurationMillis } from 'utils/time';

interface IProps {
  className?: string;
  currentMillis?: number;
  totalMillis?: number;
}

const PlayTimer: React.SFC<IProps> = ({
  className,
  currentMillis,
  totalMillis,
}) => {
  const containerClassName = classNames(className, 'play-timer');
  const millisToTime = millis =>
    formatDurationMillis(millis, {
      minute: '2-digit',
      second: '2-digit',
    });

  return (
    <div className={containerClassName}>
      <div className="play-timer__time">
        {`${millisToTime(currentMillis)} / ${millisToTime(totalMillis)}`}
      </div>
    </div>
  );
};

PlayTimer.defaultProps = {
  currentMillis: 0,
  totalMillis: 0,
};

export default PlayTimer;
