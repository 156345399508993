import cn from 'classnames';
import memoize from 'memoizee';
import * as React from 'react';
import { isUndefined, noop } from 'underscore';

import RadioOptions from 'components/RadioOptions';
import Slider from './Slider';
import Tile from './Tile';
import { block } from './utils';

interface Option {
  disabled?: boolean;
  id: string;
  label?: string;
  icon: JSX.Element;
  tooltip?: string;
}

export interface TileSelectorProps {
  className?: string;
  onChange?: (id: string) => void;
  onClick?: (id: string) => void;
  options: Option[];
  value: string;
}

export default class TileSelector extends React.Component<TileSelectorProps> {
  private tiles: { [id: string]: HTMLDivElement } = {};

  public static defaultProps: Partial<TileSelectorProps> = {
    onChange: noop,
    onClick: noop,
  };

  private handleHover = (_, id: string) => {
    const { onChange } = this.props;

    onChange(id);
  };

  private getOffset = memoize((tile: HTMLDivElement) => {
    if (!tile) return undefined;
    return tile.offsetLeft;
  });

  public render() {
    const { className, onClick, options, value } = this.props;
    const sliderOffset = this.getOffset(this.tiles[value]);

    return (
      <RadioOptions<Omit<Option, 'id'> & { value: Option['id'] }>
        className={cn(block(), className)}
        onChange={onClick}
        options={options.map(({ disabled, id, icon, tooltip }) => ({
          disabled,
          icon,
          tooltip,
          label: id,
          value: id,
        }))}
        overrides={{
          option: ({ onClick: optionOnClick, option, selected }) => {
            return (
              <Tile
                disabled={option.disabled}
                selected={selected}
                icon={option.icon}
                id={option.value}
                label={option.label}
                params={option.value}
                onHover={this.handleHover}
                onClick={optionOnClick}
                tooltip={option.tooltip}
                ref={tile => (this.tiles[option.value] = tile)}
              />
            );
          },
          optionList: ({ children }) => (
            <>
              {!isUndefined(sliderOffset) && <Slider offset={sliderOffset} />}
              {children}
            </>
          ),
        }}
        value={value}
      />
    );
  }
}
