import * as React from 'react';

function VideoCameraAlt2(props) {
  const { strokeWidth, ...restProps } = props;

  return (
    <svg width={57} height={36} viewBox="0 0 57 36" {...restProps}>
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        d="M37.224 0C40.44 0 43 2.702 43 5.983V9.05l7.83-3.901c2.396-1.186 5.67.032 5.67 2.096V28.12c0 2.072-3.285 3.283-5.67 2.096l-7.831-3.896.001 3.697C43 33.298 40.439 36 37.224 36H5.776C2.56 36 0 33.298 0 30.017V5.983C0 2.702 2.561 0 5.776 0h31.448zm0 4H5.776C4.82 4 4 4.865 4 5.983v24.034C4 31.135 4.82 32 5.776 32h31.448C38.18 32 39 31.135 39 30.017V5.983C39 4.865 38.18 4 37.224 4zM52.5 8.786L43 13.517v8.336l.02.01 9.48 4.717V8.786z"
      />
    </svg>
  );
}

export default VideoCameraAlt2;
