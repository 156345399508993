import { RecordOf } from 'immutable';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import _ from 'underscore';

import {
  ActionUtils,
  EditorState,
  VideoTemplateEditorProps,
} from 'components/VideoTemplateEditor';
import { useDynamicPodcastTextIntegration } from 'components/VideoTemplateEditor/integrations';
import useMediaUploadIntegration from 'components/VideoTemplateEditor/integrations/useMediaUploadIntegration';
import useStaticTextIntegration from 'components/VideoTemplateEditor/integrations/useStaticTextIntegration';
import useTemplateCompatibilityCheck from 'components/VideoTemplateEditor/state/useTemplateCompatibilityCheck';
import {
  ErrorActionType,
  KeyTextAssetType,
  TemplateAction,
  TemplateEditorStateExport,
  VideoTemplateEditorFeatures,
  VideoTemplateState,
  VideoTemplateStateContent,
} from 'components/VideoTemplateEditor/types';
import { VideoTemplateEditorIntegrations } from 'components/VideoTemplateEditor/types/integrations';
import useOnStyleChange, {
  OnStyleChange,
} from 'components/VideoTemplateEditor/useOnStyleChange';
import { TranscriptionFormValue } from 'containers/TranscriptionForm';
import useImageProcessor, {
  ImageProcessorStatus,
} from 'hooks/useImageProcessor';
import usePodcastData from 'hooks/usePodcastData';
import usePrevious from 'hooks/usePrevious';
import { PodcastWorkflowTemplate } from 'redux/middleware/api/podcast-service/types';
import {
  defaultLogoUrlSelector,
  defaultVideoExportSettingsSelectorByRatio,
  defaultWaveformSelector,
  defaultWavePositionReadonlySelector,
  watermarkReadOnlySelector,
} from 'redux/modules/display-pref/selectors';
import { podcastWorkflowTemplatesSelector } from 'redux/modules/entities/selectors';
import {
  onBackToTemplateStyles,
  onClickAsset,
} from 'redux/modules/mixpanel/actions';
import { WizardLastUsedStyleData } from 'redux/modules/wizard-last-used-style/types';
import { KeyTextType, Soundwave } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import {
  DEFAULT_EPISODE_TITLE,
  TEMPLATE_EDITOR_PLACEHOLDER_IMAGE,
  UCS_EDITOR_DEFAULTS,
} from 'utils/constants';
import { withValue } from 'utils/control';
import merge from 'utils/deepmerge';
import { isKeyTextAssetType } from 'utils/embed/embed';
import { hasMainImage } from 'utils/embed/slideshow';
import { getFontSize } from 'utils/embed/text-overlay';
import { getDefaultTimer } from 'utils/embed/timer';
import { formatWatermarkSrcForConfig } from 'utils/embed/watermark';
import measurement from 'utils/measurement';
import { createDefaultSoundwave } from '../utils';
import { KeyAssetMap, OnSubmit } from './types';
import useCustomizeStepIntroOutroInit from './useCustomizeStepIntroOutroInit';
import useLoadTextOverlayFonts from './useLoadTextOverlayFonts';

const { identity } = _;

export interface UseCustomizeStepStateConfig
  extends Pick<VideoTemplateEditorProps, 'features'> {
  defaults: {
    aspectRatio: number;
    backgroundColor?: string;
    keyAssets?: Partial<KeyAssetMap>;
    soundwave?: Soundwave;
  };
  lastUsedStyle?: RecordOf<WizardLastUsedStyleData>;
  onError?: (error: Error, type: ErrorActionType, meta: any) => void;
  /**
   * this callback is used mainly for setting the lastUsedStyle and was designed
   * to match the setLastUsedStyleForWizard call signature.  only style changes
   * that are tracked for lastUsedStyle will trigger this callback.  If a more
   * generic callback is needed, this should be updated to support those use cases
   */
  onStyleChange?: OnStyleChange;
  onSubmit?: OnSubmit;
  onTemplatesClick?: () => void;
  templateId?: string;
  podcastId?: string;
  episodeId?: string;
  transcription?: TranscriptionFormValue;
}

interface UseCustomizeStepStateResult
  extends Required<
    Pick<VideoTemplateEditorProps, 'features' | 'onChange' | 'state'>
  > {
  exportEditorState: () => TemplateEditorStateExport;
  imageProcessorStatus: ImageProcessorStatus;
  integrations: VideoTemplateEditorIntegrations;
  onSubmit: () => void;
}

const templateSelector = createSelector(
  podcastWorkflowTemplatesSelector,
  (__, templateId) => templateId,
  (templates, templateId) => templates?.get(templateId)?.toJS(),
);

const defaultSoundwaveSelector = createSelector(
  defaultWaveformSelector,
  (_1, defaultSoundwaveProp) => defaultSoundwaveProp,
  (waveform, defaultSoundwaveProp) => {
    return merge(createDefaultSoundwave(waveform), defaultSoundwaveProp);
  },
);

/*
 * historically we locked individual fields separately - waveform color, gen,
 * position, and type.
 *
 * the only use-case for locked settings is DW who has also locked waveform position.
 * the only way to lock the waveform position is to prevent the user from entering
 * the child view
 */
const editorFeaturesSelector = createSelector(
  defaultWavePositionReadonlySelector,
  (__, features) => features,
  watermarkReadOnlySelector,
  (_1, _2, hideTemplateSelection) => hideTemplateSelection,
  (posReadonly, features, isWatermarkLocked): VideoTemplateEditorFeatures => {
    return {
      ...features,
      ...(posReadonly && { waveform: 'locked' }),
      ...(isWatermarkLocked && { watermark: 'locked' }),
    };
  },
);

const defaultWatermarkSelector = createSelector(defaultLogoUrlSelector, url => {
  if (!url) return undefined;
  const watermark = formatWatermarkSrcForConfig(url);
  return {
    original: url,
    src: url,
    position: {
      left: measurement(watermark.position.properties.left),
      top: measurement(watermark.position.properties.top),
    },
    size: {
      height: measurement(watermark.style.height),
      width: measurement(watermark.style.width),
    },
  };
});

function resolveMainImageUrl(
  artworkUrl?: string,
  template?: PodcastWorkflowTemplate,
): string {
  if (template) {
    // if the template has no main image defined, don't attempt to replace
    if (!hasMainImage(template.previewConfiguration)) {
      return undefined;
    }
    // if the template has a main image defined, attempt to use the artwork
    // url but fallback to the default placeholder
    return artworkUrl || TEMPLATE_EDITOR_PLACEHOLDER_IMAGE;
  }

  // if no template defined, use the artwork url but don't fallback to the
  // default placeholder
  return artworkUrl;
}

const getTemplateAssets = (
  template: PodcastWorkflowTemplate,
): Partial<KeyAssetMap> => {
  return (
    template?.previewConfiguration?.textOverlayInfo?.reduce(
      (acc, { textType, text }) => ({
        ...acc,
        [textType]: text,
      }),
      {},
    ) ?? {}
  );
};

const rankTexts = (
  state: VideoTemplateStateContent,
  textAssetTypes: KeyTextType[],
) => {
  const textOverlayTexts =
    state?.textOverlays?.order
      ?.map(id => {
        const textOverlay = state.textOverlays.data[id];

        if (!textOverlay?.textHtml) {
          return undefined;
        }

        const fontSize = getFontSize(textOverlay.textHtml);

        return {
          id,
          type: 'textOverlay' as KeyTextAssetType,
          fontSize,
          text: textOverlay.text,
          textType: textAssetTypes.find(
            textAssetType => state[textAssetType]?.id === id,
          ),
        };
      })
      // rank by fontSize
      .sort((a, b) => b.fontSize.minus(a.fontSize).value) ?? [];

  // slide effect text uses hard coded ranks
  const slideEffectTextRank: KeyTextType[] = [
    'mainText',
    'episodeTitle',
    'podcastTitle',
  ];
  const slideEffectTexts = slideEffectTextRank
    .map(textType => {
      const keyText = state?.[textType];
      if (keyText?.type === 'slideEffectText') {
        const { text, id } = state.slideEffectText[keyText.id];
        return {
          id,
          type: 'slideEffectText' as KeyTextAssetType,
          text,
          textType,
        };
      }
      return null;
    })
    .filter(Boolean);
  return [...slideEffectTexts, ...textOverlayTexts];
};

// TODO this function should probably call a replacement function defined on
// the return value of useStaticTextIntegration to do the replacements
const createTextAssetReplacementFunctions = (
  prevContent: VideoTemplateStateContent,
  prevTemplateAssets: Partial<KeyAssetMap>,
  keyAssets: Partial<KeyAssetMap>,
) => (content: VideoTemplateStateContent): VideoTemplateStateContent => {
  const textAssetTypes = Object.keys(keyAssets).filter(isKeyTextAssetType);

  const prevTexts = rankTexts(prevContent, textAssetTypes).filter(
    textOverlay =>
      textOverlay.text !== keyAssets[textOverlay.textType] &&
      textOverlay.text !== prevTemplateAssets[textOverlay.textType],
  );
  const nextTexts = rankTexts(content, textAssetTypes);

  const keyAssetReplacers = textAssetTypes.map(assetType =>
    _.partial(EditorState.replaceKeyAsset, _, assetType, keyAssets[assetType]),
  );

  const textOverlayReplacers = prevTexts.map(({ text }, index) =>
    nextTexts[index]
      ? _.partial(
          nextTexts[index].type === 'textOverlay'
            ? EditorState.updateTextOverlayText
            : EditorState.updateSlideEffectText,
          _,
          nextTexts[index].id,
          text,
        )
      : identity,
  );

  // identity will pass content through if there are no functions in
  // assetReplacementFunctions
  return compose<VideoTemplateStateContent>(
    identity,
    ...textOverlayReplacers,
    ...keyAssetReplacers,
  )(content);
};

export default function useCustomizeStepState({
  podcastId,
  episodeId,
  defaults,
  features: baseFeatures,
  lastUsedStyle,
  onError,
  onStyleChange,
  onSubmit,
  onTemplatesClick,
  templateId,
  transcription,
}: UseCustomizeStepStateConfig): UseCustomizeStepStateResult {
  const staticTextIntegration = useStaticTextIntegration({
    priority: 0,
  });
  const mediaUploadIntegration = useMediaUploadIntegration({
    priority: 1,
  });
  const prevTemplateId = usePrevious(templateId);
  const mainImageSrcRef = useRef<string | Blob>(null);
  const { intro: introDefault, outro: outroDefault } = useSelector(
    defaultVideoExportSettingsSelectorByRatio(
      getAspectRatioName(defaults.aspectRatio),
    ),
  );
  const { episodeTitle, podcastTitle } = usePodcastData({
    defaultEpisodeTitle: DEFAULT_EPISODE_TITLE,
    podcastId,
    episodeId,
  });

  const dynamicTextIntegration = useDynamicPodcastTextIntegration({
    episodeNamePlaceholder: episodeTitle,
    podcastNamePlaceholder: podcastTitle,
    priority: 1,
  });
  // Gets default enterprise values for intro and outro
  const introDefaultVideoUrl = introDefault?.value;
  const outroDefaultVideoUrl = outroDefault?.value;

  const {
    aspectRatio,
    backgroundColor,
    keyAssets = {},
    soundwave = {},
  } = defaults;
  const dispatch = useDispatch();
  const template = useSelector(state => templateSelector(state, templateId));
  const prevTemplate = usePrevious<PodcastWorkflowTemplate>(template);
  const defaultWatermark = useSelector(defaultWatermarkSelector);
  const artworkUrl = keyAssets.mainImage;
  const textAssetReplacementFunctions = useRef<
    (content: VideoTemplateStateContent) => VideoTemplateStateContent
  >(identity);

  const handleStyleChange = useOnStyleChange({ onStyleChange });

  const initialSoundwave = useSelector(state =>
    defaultSoundwaveSelector(state, soundwave),
  );

  const initialBackgroundColor =
    lastUsedStyle.get('backgroundColor') || backgroundColor;

  const features = useSelector(state =>
    editorFeaturesSelector(state, baseFeatures),
  );

  const handleChange = useCallback(
    (
      stateUpdater: (prevState: VideoTemplateState) => VideoTemplateState,
      action: TemplateAction,
    ) => {
      // TODO: Tech Debt https://sparemin.atlassian.net/browse/SPAR-14289
      // Although not mentioned in the React docs, the state updater below should
      // probably be a pure function.  Side effects such as onError create some
      // issues.  Move this `if` block into `setEditorState` and upload an animated
      // gif.  notice that you'll get 2 error messages.
      if (ActionUtils.isErrorAction(action)) {
        onError(action.payload, action.type, action?.meta);
      }

      setEditorState((prevState: VideoTemplateState) => {
        const state = stateUpdater(prevState);
        const content = EditorState.getContent(state);
        handleStyleChange(content, action);

        if (action.type === 'TEMPLATES_BUTTON_CLICK') {
          dispatch(onBackToTemplateStyles(templateId));
          onTemplatesClick();
        } else if (action.type === 'CHILD_VIEW_OPEN') {
          if (action.meta.source === 'preview') {
            dispatch(onClickAsset(action.payload));
          }
        }

        return state;
      });
    },
    [dispatch, handleStyleChange, onError, onTemplatesClick, templateId],
  );

  const initializeState = useCallback(
    (prevState?: VideoTemplateState) => {
      if (template) {
        textAssetReplacementFunctions.current = createTextAssetReplacementFunctions(
          EditorState.getContent(prevState),
          getTemplateAssets(prevTemplate),
          keyAssets,
        );
        return EditorState.createModifiedState(
          EditorState.createStateFromTemplate(template),
          compose(
            _.partial(EditorState.setTranscription, _, transcription),
            _.partial(EditorState.setInitialCaptionsConfig, _, transcription),
            dynamicTextIntegration.replaceKeyAssets,
            EditorState.createSoundwaveRestorePoint,
            template.templateType !== 'userGenerated' && mainImageSrcRef.current
              ? _.partial(
                  EditorState.replaceKeyAsset,
                  _,
                  'mainImage',
                  mainImageSrcRef.current,
                )
              : identity,
            _.partial(
              EditorState.setIntroOutroDefaultPrefs,
              _,
              {
                introDefaultVideoUrl,
                outroDefaultVideoUrl,
              },
              prevState?.present,
            ),
          ),
        );
      }

      return EditorState.createModifiedState(
        EditorState.createEmptyState(aspectRatio),
        content =>
          compose(
            mainImageSrcRef.current
              ? _.partial(
                  EditorState.replaceKeyAsset,
                  _,
                  'mainImage',
                  mainImageSrcRef.current,
                )
              : identity,
            !defaultWatermark
              ? _.identity
              : _.partial(EditorState.addWatermark, _, defaultWatermark),
            withValue(
              lastUsedStyle.get('timer'),
              lastUsedTimer =>
                _.partial(EditorState.setTimerOptions, _, {
                  ...getDefaultTimer(aspectRatio, 'string'),
                  ...lastUsedTimer,
                }),
              _.identity,
            ),
            _.partial(EditorState.setProgressAnimationOptions, _, {
              ...UCS_EDITOR_DEFAULTS.progress,
              ...lastUsedStyle.get('progress'),
            }),
            _.partial(
              staticTextIntegration.addText,
              _,
              lastUsedStyle.get('textOverlay'),
            ),
            _.partial(
              EditorState.setIntroOutroDefaultPrefs,
              _,
              {
                introDefaultVideoUrl,
                outroDefaultVideoUrl,
              },
              prevState?.present,
            ),
            // set the default soundwave, create a restore point, then set the last
            // used soundwave.  this will render the last used style but the reset button
            // will revert back to the default (org presets)
            _.partial(
              EditorState.setSoundwave,
              _,
              lastUsedStyle.get('soundwave'),
            ),
            EditorState.createSoundwaveRestorePoint,
            _.partial(EditorState.setSoundwave, _, initialSoundwave),
            _.partial(EditorState.setTranscription, _, transcription),
            _.partial(EditorState.setInitialCaptionsConfig, _, transcription),
            _.partial(
              EditorState.setBackgroundColor,
              _,
              initialBackgroundColor,
            ),
          )(content),
      );
    },
    [
      aspectRatio,
      defaultWatermark,
      dynamicTextIntegration.replaceKeyAssets,
      initialBackgroundColor,
      initialSoundwave,
      introDefaultVideoUrl,
      keyAssets,
      lastUsedStyle,
      outroDefaultVideoUrl,
      prevTemplate,
      staticTextIntegration.addText,
      template,
      transcription,
    ],
  );

  const [editorState, setEditorState] = useState(() => initializeState());

  const { checkTemplateCompatibility } = useTemplateCompatibilityCheck({
    onChange: handleChange,
  });

  useEffect(() => {
    if (templateId !== prevTemplateId) {
      const newState = initializeState(editorState);
      setEditorState(newState);
    }
  }, [editorState, initializeState, prevTemplateId, templateId]);

  useEffect(() => {
    checkTemplateCompatibility(templateId, template?.templateType);
  }, [checkTemplateCompatibility, template, templateId]);

  // replaces text placeholders
  useLoadTextOverlayFonts({
    template,
    onComplete: () =>
      setEditorState(s =>
        EditorState.modifyStateContent(
          s,
          textAssetReplacementFunctions.current,
        ),
      ),
  });

  // replaces image placeholders
  const { status: imageProcessorStatus } = useImageProcessor(
    resolveMainImageUrl(artworkUrl, template),
    EditorState.getContent(editorState).aspectRatio,
    undefined,
    useCallback(
      ({ originalFile, status }) => {
        if (status === 'ready') {
          setEditorState(s =>
            EditorState.modifyStateContent(s, content => {
              const newContent = mediaUploadIntegration.replaceMainMedia(
                content,
                originalFile,
              );

              // the useImageProcessor hook only executes once when the UCS
              // is initialized.  When the user switches templates, we want to
              // reset all images to the same state the user would see when they
              // first load the UCS.  this line saves the processed image to a ref
              // so that it can be used when re-initializing the UCS (see
              // initializeState)
              mainImageSrcRef.current = originalFile;
              return newContent;
            }),
          );
        }
      },
      [mediaUploadIntegration],
    ),
  );

  // Pre-uploads the current default state for intro/outro clips.
  useCustomizeStepIntroOutroInit({
    editorState,
    setEditorState,
  });

  const exportEditorState = useCallback(() => {
    return EditorState.exportState(editorState, [
      dynamicTextIntegration.postProcessor,
    ]);
  }, [dynamicTextIntegration.postProcessor, editorState]);

  const handleSubmit = useCallback(() => {
    onSubmit(exportEditorState());
  }, [exportEditorState, onSubmit]);

  return {
    exportEditorState,
    features,
    imageProcessorStatus,
    integrations: [mediaUploadIntegration, staticTextIntegration],
    onChange: handleChange,
    onSubmit: handleSubmit,
    state: editorState,
  };
}
