import cn from 'classnames';
import * as React from 'react';
import { Modal as BsModal } from 'react-bootstrap';
import { noop } from 'underscore';

import ModalBody from './ModalBody';
import ModalBodyCol from './ModalBodyCol';
import ModalBodyRow from './ModalBodyRow';
import ModalFooter from './ModalFooter';
import ModalFooterButton from './ModalFooterButton';
import ModalFooterButtons from './ModalFooterButtons';
import ModalHeader, { ModalHeaderProps } from './ModalHeader';

export type Backdrop = 'static' | boolean;

interface IProps extends Pick<ModalHeaderProps, 'closeButton'> {
  /**
   * when true, modal will animate on show/hide
   */
  animation?: boolean;
  /**
   * backdrop for modal.
   *
   * true - render backdrop.  if backdrop is clicked, onHide is fired
   * false - don't render backdrop.  user will not be able to click anything "behind" the modal
   * 'static' - render a backdrop.  if backdrop is clicked, onHide is not fired
   */
  backdrop?: Backdrop;
  backdropClassName?: string;
  children?: React.ReactNode;
  className?: string;
  /**
   * class name of the modal header
   */
  headerClassName?: string;
  /**
   * closes the modal when the [ESC] key is pressed
   */
  keyboard?: boolean;
  /**
   * called after the modal finishes transitioning out
   */
  onExited?: () => void;
  /**
   * called when the modal is dismissed either by clicking the "x" in the header or clicking on
   * the backdrop when backdrop is set to true
   */
  onHide?: () => void;
  /**
   * if true, will only re-render the modal when it is visible or transitioning
   * from hidden to visible.
   *
   * defaults to true
   */
  optimizeRender?: boolean;
  responsive?: boolean;
  /**
   * controls whether or not the modal is open
   */
  show?: boolean;
  title?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  /**
   * When true the Modal will prevent focus from leaving the Modal while open.
   *
   * @default false
   */
  enforceFocus?: boolean;
  v2?: boolean;
}

/**
 * Headliner modal component.  Wraps react-bootstrap's Modal.
 */

export default class Modal extends React.Component<IProps> {
  public static Body = ModalBody;
  public static Col = ModalBodyCol;
  public static Footer = ModalFooter;
  public static FooterButton = ModalFooterButton;
  public static FooterButtons = ModalFooterButtons;
  public static Header = ModalHeader;
  public static Row = ModalBodyRow;

  public static defaultProps: Partial<IProps> = {
    animation: true,
    backdrop: true,
    keyboard: true,
    onHide: noop,
    optimizeRender: true,
    responsive: true,
    show: false,
  };

  public shouldComponentUpdate(nextProps: IProps) {
    const { show } = this.props;
    const { optimizeRender, show: nextShow } = nextProps;

    if (optimizeRender) {
      return show || nextShow;
    }

    // true is the default implementation provided by react
    return true;
  }

  /*
   * standardize the onHide callback.  when clicking the "x" in the header, react-bootstrap passes
   * event args, but when clicking the backdrop it passes no args
   */
  private handleHide = () => this.props.onHide();

  public render() {
    const {
      backdropClassName,
      children,
      className,
      closeButton,
      headerClassName,
      onHide,
      optimizeRender,
      responsive,
      title,
      size,
      enforceFocus = false,
      v2,
      ...bsModalProps
    } = this.props;

    return (
      <BsModal
        {...bsModalProps}
        enforceFocus={enforceFocus}
        backdropClassName={cn('hl-modal__backdrop', backdropClassName)}
        dialogClassName={cn(
          'hl-modal',
          { 'hl-modal--v2': v2 },
          { 'hl-modal--responsive': responsive },
          { [`hl-modal--${size}`]: !!size },
          className,
        )}
        onHide={this.handleHide}
      >
        {title && (
          <ModalHeader className={headerClassName} closeButton={closeButton}>
            {title}
          </ModalHeader>
        )}
        {children}
      </BsModal>
    );
  }
}

export { IProps as ModalProps, Modal };
