import * as React from 'react';

import { IconProps } from './types';

const Stamp: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="30"
    width="30"
    viewBox="0 0 30 30"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#464E5D" d="M0 0h30v30H0z" />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M13.071 19.286h-.643l.712-2.164h3.703l.712 2.164h-.626a.429.429 0 0 0-.429.428v.857c0 .237.192.429.429.429h3.642a.429.429 0 0 0 .429-.429v-.857a.429.429 0 0 0-.429-.428h-.716L16.38 9.288A.429.429 0 0 0 15.975 9h-1.95a.429.429 0 0 0-.404.288l-3.476 9.998h-.716a.429.429 0 0 0-.429.428v.857c0 .237.192.429.429.429h3.642a.429.429 0 0 0 .429-.429v-.857a.429.429 0 0 0-.429-.428zm1.724-7.28c.117-.408.177-.754.203-.928.023.175.08.522.207.93l1.108 3.321H13.67l1.124-3.323z"
      />
    </g>
  </svg>
);

export default Stamp;
