let context: AudioContext;
const windowAsAny = window as any;

const OfflineContext =
  windowAsAny.OfflineAudioContext || windowAsAny.webkitOfflineAudioContext;
const Context = windowAsAny.AudioContext || windowAsAny.webkitAudioContext;

/*
 * unlocks the audio context for playback
 *
 * BaseAudio has a workaround for browser autoplay policies where it checks if
 * the context is suspended on play and resumes it if that's not the case. This
 * workaround doesn't seem to handle playback on Safari when the user clicks the
 * play button before any other interaction with the page.
 *
 * This function will listen for valid interactions on the document and attempt
 * to resume the audio context when one of these events is received.
 *
 * see https://www.mattmontag.com/web/unlock-web-audio-in-safari-for-ios-and-macos
 */
function unlockAudioContext(ctx: AudioContext) {
  if (ctx.state !== 'suspended') return;
  const b = document.body;
  const events = ['touchstart', 'touchend', 'mousedown', 'keydown'];
  const cleanup = () =>
    events.forEach(e => b.removeEventListener(e, unlock, false));
  const unlock = () => ctx.resume().then(cleanup);
  events.forEach(e => b.addEventListener(e, unlock, false));
}

export function getContext() {
  if (!context) {
    context = new Context();
    unlockAudioContext(context);
  }
  return context;
}

export function getOfflineContext(
  nChannels: number,
  length: number,
  sampleRate: number = 44100,
): OfflineAudioContext {
  return new OfflineContext(nChannels, length, sampleRate);
}
