import React from 'react';

import { block } from './utils';

export interface ActionStepCardProps {
  description: string;
  modifiers?: 'ellipsized-description'[];
  image: React.ReactNode;
  renderAction?: () => React.ReactElement;
  title: string;
}

const ActionStepCard: React.FunctionComponent<ActionStepCardProps> = (
  props: ActionStepCardProps,
) => {
  const { description, image, modifiers = [], renderAction, title } = props;

  const ActionComponent = React.useMemo(
    () =>
      renderAction ? (
        <div className={block('action-container')}>{renderAction()}</div>
      ) : null,
    [renderAction],
  );

  const ImageComponent = React.useMemo(
    () =>
      typeof image === 'string' ? (
        <img className={block('image')} src={image} alt="edit_action_image" />
      ) : (
        image
      ),
    [image],
  );

  const descriptionClassName = block('description-text', {
    ellipsized: modifiers.includes('ellipsized-description'),
  });

  return (
    <div className={block('card-container')}>
      <div className={block('image-container')}>{ImageComponent}</div>
      <div className={block('data-container')}>
        <h6 className={block('title-text')}>{title}</h6>
        <p className={descriptionClassName}>{description}</p>
      </div>
      {ActionComponent}
    </div>
  );
};

export default ActionStepCard;
