import * as React from 'react';
import _ from 'underscore';

import * as types from './types';

interface IProps {
  render?: types.RenderCallback;
}

export default class ResetPasswordInput extends React.Component<
  IProps,
  types.IState
> {
  public static defaultProps: Partial<IProps> = {
    render: () => null,
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      data: types.dataStateFactory({
        confirmPassword: '',
        password: '',
      }),
    };
  }

  private handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.updateDataState('password', e.target.value);

  private handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => this.updateDataState('confirmPassword', e.target.value);

  private updateDataState<K extends keyof types.IDataState>(
    key: K,
    value: types.IDataState[K],
  ) {
    this.setState(({ data }) => ({
      data: data.set(key, value),
    }));
  }

  private validatePasswordReset = () => {
    const { data } = this.state;

    const { confirmPassword, password } = data;

    if (password !== confirmPassword) {
      return 'Passwords must match';
    }

    if (_.isEmpty(password) || password.length < 8) {
      return 'Password must be at least 8 characters';
    }

    if (password.length > 255) {
      return 'Password must be at most 255 characters';
    }

    return undefined;
  };

  private getRenderArgs(): types.IRenderArg {
    const { data } = this.state;

    const { confirmPassword, password } = data;

    return {
      callbacks: {
        onConfirmPasswordChange: this.handleConfirmPasswordChange,
        onPasswordChange: this.handlePasswordChange,
      },
      validate: this.validatePasswordReset,
      values: { confirmPassword, password },
    };
  }

  public reset() {
    this.setState({
      data: types.dataStateFactory({
        confirmPassword: '',
        password: '',
      }),
    });
  }

  public render() {
    const { render } = this.props;
    return render(this.getRenderArgs());
  }
}
