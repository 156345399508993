import * as React from 'react';

import { IconProps } from 'components/icons/types';

/* tslint:disable-next-line:variable-name */
const PostTaskBanner: React.SFC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="500"
    height="300"
    viewBox="0 0 500 300"
    {...props}
  >
    <g fill="#14191e" fillRule="evenodd">
      <path
        stroke="url(#post-task-path-gradient)"
        strokeWidth="2"
        d="M10 1a9 9 0 0 0-9 9v280a9 9 0 0 0 9 9h480a9 9 0 0 0 9-9V10a9 9 0 0 0-9-9H10z"
      />
      <text
        fill="#8CA0C3"
        fontFamily="Montserrat-Medium, Montserrat"
        fontSize="18"
        fontWeight="regular"
        letterSpacing=".5"
      >
        <tspan x="66.562" y="173.5">
          Next time, save time by making videos{' '}
        </tspan>
        <tspan
          x="96.241"
          y="199.5"
          fontFamily="Montserrat-Medium, Montserrat"
          fontWeight="400"
        >
          {' '}
          directly from your podcast host!
        </tspan>
      </text>
      <g transform="translate(200 30)">
        <circle
          cx="50"
          cy="50"
          r="50"
          fill="url(#post-task-circle-gradient)"
          filter="url(#post-task-circle-filter)"
        />
        <path
          fill="#282E37"
          fillRule="nonzero"
          d="M49.75 57.281c3.84 0 6.955-3.148 6.955-7.031V38.531c0-3.883-3.114-7.031-6.955-7.031-3.84 0-6.955 3.148-6.955 7.031V50.25c0 3.883 3.114 7.031 6.955 7.031zm11.59-11.718h-1.158c-.64 0-1.16.524-1.16 1.171v3.516c0 5.479-4.671 9.875-10.199 9.33-4.817-.476-8.346-4.854-8.346-9.747v-3.099c0-.647-.518-1.172-1.159-1.172H38.16c-.64 0-1.159.525-1.159 1.172v2.942c0 6.565 4.634 12.418 11.011 13.307v2.501h-4.056c-.64 0-1.16.525-1.16 1.172v1.172c0 .648.52 1.172 1.16 1.172h11.59c.64 0 1.16-.524 1.16-1.172v-1.172c0-.647-.52-1.172-1.16-1.172H51.49v-2.473C57.698 62.15 62.5 56.761 62.5 50.25v-3.516c0-.647-.519-1.172-1.16-1.172z"
        />
      </g>
      <g transform="translate(20 235)">
        <rect width="460" height="45" fill="#19B5FE" rx="3" />
        <text
          fill="#FFF"
          fontFamily="Roboto-Bold, Roboto"
          fontSize="12"
          fontWeight="bold"
          letterSpacing="2"
        >
          <tspan x="115" y="28">
            REQUEST A HEADLINER INTEGRATION
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

export default PostTaskBanner;
