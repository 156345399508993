import * as React from 'react';

import { Replace } from 'components/icons';
import OptionTile from '../assets/OptionTile';
import useAddWatermark from '../useAddWatermark';

export interface ReplaceWatermarkTileProps {
  className?: string;
}

const ReplaceWatermarkTile: React.FC<ReplaceWatermarkTileProps> = props => {
  const { className } = props;
  const onAddWatermark = useAddWatermark();

  return (
    <OptionTile
      className={className}
      icon={<Replace style={{ height: 36, width: 'auto' }} />}
      label="replace"
      onClick={onAddWatermark}
    />
  );
};

export default ReplaceWatermarkTile;
