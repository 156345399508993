import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import AspectRatioSelector from 'components/AspectRatioSelector';
import Modal from 'components/Modal';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import { onClickBlankProject } from 'redux/modules/mixpanel/actions';
import { ModalName } from 'redux/modules/modal/types';
import { AspectRatioName } from 'types';
import { stringifyQuery } from 'utils/routes';

const { useState } = React;

interface IProps {
  modalName: ModalName;
  className?: string;
  target?: string;
  selectedAspectRatio?: AspectRatioName;
  shouldRedirectOnSelect?: boolean;
  onSubmit?: (ratio: AspectRatioName) => void;
}

const AspectRatioModalContents: React.FC<IProps> = props => {
  const {
    modalName,
    target,
    selectedAspectRatio = 'square',
    shouldRedirectOnSelect,
  } = props;
  const { onHide } = useConnectedModal(modalName);
  const dispatch = useDispatch();
  const [ratioName, setRatioName] = useState<AspectRatioName>(
    selectedAspectRatio,
  );

  const handleOptionClick = (__, name: AspectRatioName) => {
    setRatioName(name);
  };

  const handleSubmit = () => {
    onHide({ meta: { ratioName } });
    dispatch(onClickBlankProject());
  };

  return (
    <>
      <Modal.Body>
        <AspectRatioSelector
          className="aspect-modal__options"
          onSelect={handleOptionClick}
          value={ratioName}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButtons>
          <Modal.FooterButton onClick={onHide}>cancel</Modal.FooterButton>
          <Modal.FooterButton
            theme="submit"
            onClick={handleSubmit}
            target={target}
            {...(shouldRedirectOnSelect && {
              componentClass: Link,
              to: {
                pathname: '/project',
                search: stringifyQuery({ ar: ratioName }),
              },
            })}
          >
            confirm
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default AspectRatioModalContents;
