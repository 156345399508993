import * as React from 'react';

import { block } from '../utils';
import FontUpload from './FontUpload';

function ContentProEmpty() {
  return (
    <div className={block('font-upload')}>
      <FontUpload />
    </div>
  );
}

export default ContentProEmpty;
