import * as React from 'react';

import { block } from './utils';

export interface AssetTileDisplayNameProps {
  children: React.ReactNode;
}

const AssetTileDisplayName: React.SFC<AssetTileDisplayNameProps> = ({
  children,
}) => <div className={block('displayname')}>{children}</div>;

export default AssetTileDisplayName;
