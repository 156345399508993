import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import EditAutomationAudioSelectionLengthModalContents from './EditAutomationAudioSelectionLengthModalContents';

const EditAutomationAudioSelectionLengthModal: React.FunctionComponent = () => {
  return (
    <ConnectedModal
      backdrop="static"
      name="EditAutomationAudioSelectionLengthModal"
      title="Let Headliner AI select for me"
    >
      <EditAutomationAudioSelectionLengthModalContents />
    </ConnectedModal>
  );
};

export default EditAutomationAudioSelectionLengthModal;
