import { CSSProperties } from 'react';
import { TimerState } from 'redux/modules/embed/types';
import {
  positionFactory,
  sizeFactory,
  timerFactory,
} from 'redux/modules/embed/utils';
import { AspectRatioName, Timer, TimerOptions } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { isImmutable } from 'utils/collections';
import { withValue } from 'utils/control';
import measurement, { ViewportHeight, ViewportWidth } from 'utils/measurement';
import { measurementToString, stringToViewport } from 'utils/placement';

interface TimerMeasurements {
  fontSize: ViewportHeight;
  height: ViewportHeight;
  width: ViewportWidth;
}

export const STATIC_TIMER_STYLE: CSSProperties = {
  fontFamily: 'Avenir LT Pro',
  fontWeight: 'bold',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
};

const squareSizes = {
  fontSize: measurement(2.5, 'vh'),
  height: measurement(6.67, 'vh'),
  width: measurement(12.22, 'vw'),
} as TimerMeasurements;

const landscapeSizes = {
  fontSize: squareSizes.fontSize.times(16 / 9),
  height: measurement(11.56, 'vh'),
  width: measurement(12.22, 'vw'),
} as TimerMeasurements;

const portraitSizes = {
  fontSize: measurement(1.85, 'vh'),
  height: measurement(4.93, 'vh'),
  width: measurement(16.08, 'vw'),
} as TimerMeasurements;

export const DEFAULT_TIMER_SIZES: Record<AspectRatioName, TimerMeasurements> = {
  square: squareSizes,
  landscape: landscapeSizes,
  portrait: portraitSizes,
};

export function timerMeasurementToString(
  timer: TimerOptions<ViewportHeight, ViewportWidth>,
): TimerOptions<string> {
  if (!timer) return undefined;

  return {
    ...timer,
    fontSize: timer.fontSize.toString(),
    position: measurementToString(timer.position),
    timerSize: measurementToString(timer.timerSize),
  };
}

export function timerStringToViewport(
  timer: TimerOptions<string>,
): TimerOptions<ViewportHeight, ViewportWidth> {
  return {
    ...timer,
    fontSize: measurement(timer.fontSize, 'vh') as ViewportHeight,
    position: stringToViewport(timer.position),
    timerSize: stringToViewport(timer.timerSize),
  };
}

export function getDefaultTimer(
  frameRatio: number = 0,
  as: 'viewport' | 'string' = 'viewport',
): TimerOptions {
  const ratioName = getAspectRatioName(frameRatio);
  const properties = DEFAULT_TIMER_SIZES[ratioName];

  if (!properties) return undefined;

  const { fontSize, height, width } = properties;

  const position = {
    top: measurement(100, 'vh')
      .minus(height)
      .divideBy(2) as ViewportHeight,
    left: measurement(100, 'vw')
      .minus(width)
      .divideBy(2) as ViewportWidth,
  };

  const viewportTimer: TimerOptions<ViewportHeight, ViewportWidth> = {
    fontSize,
    position,
    timerSize: { height, width },
    color: 'white',
    enabled: false,
  };

  return as === 'viewport'
    ? viewportTimer
    : timerMeasurementToString(viewportTimer);
}

export function formatTimerForConfig(
  timer: TimerOptions<string> | TimerState,
): Timer[] {
  const value = isImmutable(timer) ? (timer as any).toJS() : timer;

  return withValue(
    value,
    ({ enabled, timerSize, color, fontSize, position }) => [
      {
        enabled,
        countDirection: 'countDown',
        containerStyle: {
          ...STATIC_TIMER_STYLE,
          ...timerSize,
          color,
          fontSize,
        },
        position: {
          properties: position,
          type: 'absolute',
        },
      },
    ],
  );
}

export function formatTimerFromConfig(timers: Timer[]): TimerState {
  return withValue(timers?.[0], timer =>
    timerFactory({
      color: timer.containerStyle.color,
      enabled: timer.enabled,
      fontSize: timer.containerStyle.fontSize as string,
      position: positionFactory({
        left: timer.position.properties.left as string,
        top: timer.position.properties.top as string,
      }) as any,
      timerSize: sizeFactory({
        height: timer.containerStyle.height as string,
        width: timer.containerStyle.width as string,
      }) as any,
    }),
  );
}
