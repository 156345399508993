import * as React from 'react';

import { block } from './utils';

const ConfirmationIcon: React.FC = props => {
  const { children } = props;
  return <div className={block('icon')}>{children}</div>;
};

export default ConfirmationIcon;
