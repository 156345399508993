import { Record, RecordOf } from 'immutable';

export interface ICallbacks {
  // TODO figure out the right type param here
  onPasswordChange: (e: React.ChangeEvent<any>) => void;
  onConfirmPasswordChange: (e: React.ChangeEvent<any>) => void;
}

export interface IValues {
  password?: string;
  confirmPassword?: string;
}

export interface IDataState {
  password: string;
  confirmPassword: string;
}

export interface IState {
  data: RecordOf<IDataState>;
}

export const dataStateFactory = Record<IDataState>({
  confirmPassword: undefined,
  password: undefined,
});

export type RenderCallback = (arg: IRenderArg) => JSX.Element;
export type Validate = () => string;

export interface IRenderArg {
  callbacks: ICallbacks;
  /**
   * @return {string} error message if invalid, otherwise undefined
   */
  validate: Validate;
  values: IValues;
}
