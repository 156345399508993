import cn from 'classnames';
import * as React from 'react';

import { H1, H2 } from 'components/Heading';
import { block } from '../utils';

export interface DownloadPageDetailsProps {
  children?: React.ReactNode;
  className?: string;
  subtitle?: React.ReactNode;
  title: string;
  icon?: React.ReactNode;
}

const DownloadPageDetails: React.FC<DownloadPageDetailsProps> = ({
  children,
  className,
  subtitle,
  title,
  icon,
}) => (
  <div className={cn(block('details'), className)}>
    <div className={block('heading', { centered: !icon })}>
      {icon && <div className={block('heading-icon')}>{icon}</div>}
      <H1 className={block('title')}>{title}</H1>
      <H2 className={block('subtitle')}>{subtitle}</H2>
    </div>
    {children}
  </div>
);

export default DownloadPageDetails;
