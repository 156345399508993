import React from 'react';
import DownloadCaptionsDialogModal from './DownloadCaptionsDialogModal';
import UnlockTranscriptsModalContents from './UnlockTranscriptsModalContents';

export interface UnlockTranscriptsModalProps {}

const UnlockTranscriptsModal: React.FC<UnlockTranscriptsModalProps> = () => (
  <DownloadCaptionsDialogModal
    name="UnlockTranscripts"
    title="Unlock Transcripts"
  >
    <UnlockTranscriptsModalContents />
  </DownloadCaptionsDialogModal>
);

export default UnlockTranscriptsModal;
