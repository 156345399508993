import React from 'react';
import SelectOption from '../SelectInput/SelectOption';
import { FontSelectOption } from './types';
import { block as fontSelectorBlock } from './utils';

export interface FontListSectionProps {
  onOptionClick?: (value: string) => void;
  optionClassName?: string;
  options: FontSelectOption[];
  selectedValue?: string;
  title?: React.ReactNode;
}

const FontListSection: React.FC<FontListSectionProps> = ({
  onOptionClick,
  optionClassName,
  options = [],
  selectedValue,
  title,
}) =>
  options.length === 0 ? null : (
    <>
      {title && (
        <span className={fontSelectorBlock('select-title')}>{title}</span>
      )}
      {options.map(opt => (
        <SelectOption
          {...opt}
          className={optionClassName}
          key={opt.label}
          onClick={onOptionClick}
          selected={opt.value === selectedValue}
        />
      ))}
    </>
  );

export default FontListSection;
