export enum Type {
  WAIT_FOR_WAVEFORM_REQUEST = 'app/async-audio-clipper/WAIT_FOR_WAVEFORM_REQUEST',
  WAIT_FOR_WAVEFORM_SUCCESS = 'app/async-audio-clipper/WAIT_FOR_WAVEFORM_SUCCESS',
  WAIT_FOR_WAVEFORM_FAILURE = 'app/async-audio-clipper/WAIT_FOR_WAVEFORM_FAILURE',

  WAVEFORM_CLEAR = 'app/async-audio-clipper/WAVEFORM_CLEAR',

  CANCEL_STANDARDIZATION = 'app/asycn-audio-clipper/CANCEL_STANDARDIZATION',

  LOAD_MEDIA_FAILURE = 'app/async-audio-clipper/LOAD_MEDIA_FAILURE',
}
