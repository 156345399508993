import React from 'react';

import FadingScrollBars from 'components/FadingScrollBars';
import { Row } from 'components/Grid';
import { AspectRatioName } from 'types';

import PlaceholderCard from './PlaceholderCard';
import {
  block,
  getLoadingCardWidth,
  LOADING_CARDS_AMOUNT_FOR_ASPECT_RATIO,
} from './utils';

interface PlaceholderCardsProps {
  aspectRatio: AspectRatioName;
}

const PlaceholderCards: React.FunctionComponent<PlaceholderCardsProps> = props => {
  const { aspectRatio } = props;

  const cards = React.useMemo(() => {
    const cardsConfig = LOADING_CARDS_AMOUNT_FOR_ASPECT_RATIO[aspectRatio];
    const colSpan = getLoadingCardWidth(cardsConfig.cols);

    return [...Array(cardsConfig.rows).keys()].map(rowKey => (
      <Row key={rowKey} className={block('row')}>
        {[...Array(cardsConfig.cols).keys()].map(colKey => (
          <PlaceholderCard
            key={colKey}
            aspectRatio={aspectRatio}
            colSpan={colSpan}
          />
        ))}
      </Row>
    ));
  }, [aspectRatio]);

  return (
    <FadingScrollBars className={block('container')}>{cards}</FadingScrollBars>
  );
};

export default PlaceholderCards;
