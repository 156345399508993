import * as React from 'react';
import Wave from './Wave';

const Waves: React.SFC = () => (
  <div className="waves">
    <div className="waves__container">
      <Wave />
      <Wave />
    </div>
  </div>
);

export default Waves;
