import { Record, RecordOf } from 'immutable';
import { partial } from 'underscore';

import { omitUndefined } from 'utils/collections';
import { withValue } from 'utils/control';
import { reviveRecord } from 'utils/immutable';
import { stateFactory as entireAudioFactory } from '../../factories/entire-audio';
import { IState } from './types';

export const stateFactory = Record<IState>({
  aspectRatioDimensions: undefined,
  entireAudio: undefined,
  projectId: undefined,
  status: undefined,
  widgetId: undefined,
});

export function stateFromJs({ entireAudio, ...rest }: any = {}): RecordOf<
  IState
> {
  return reviveRecord(
    stateFactory,
    omitUndefined({
      entireAudio: withValue(
        entireAudio,
        partial(reviveRecord, entireAudioFactory),
      ),
      ...rest,
    }),
  );
}
