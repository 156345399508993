import React from 'react';

import WatermarkIcon from '../WatermarkIcon';
import CircularGradient, { CircularGradientProps } from './CircularGradient';

export type CircularGradientVideoCameraProps = Pick<
  CircularGradientProps,
  'className'
>;

const CircularGradientWatermark: React.FC<CircularGradientVideoCameraProps> = ({
  className,
}) => (
  <CircularGradient className={className}>
    <WatermarkIcon
      strokeWidth={1}
      style={{
        height: 'auto',
        width: '45%',
      }}
    />
  </CircularGradient>
);

export default CircularGradientWatermark;
