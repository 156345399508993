export enum Type {
  YOUTUBE_USER_DATA_GET_REQUEST = 'app/social/youtube/USER_DATA_GET_REQUEST',
  YOUTUBE_USER_DATA_GET_SUCCESS = 'app/social/youtube/USER_DATA_GET_SUCCESS',
  YOUTUBE_USER_DATA_GET_FAILURE = 'app/social/youtube/USER_DATA_GET_FAILURE',

  YOUTUBE_LANGUAGES_REQUEST = 'app/social/youtube/YOUTUBE_LANGUAGES_REQUEST',
  YOUTUBE_LANGUAGES_SUCCESS = 'app/social/youtube/YOUTUBE_LANGUAGES_SUCCESS',
  YOUTUBE_LANGUAGES_FAILURE = 'app/social/youtube/YOUTUBE_LANGUAGES_FAILURE',

  YOUTUBE_USER_DATA_CLEAR = 'app/social/youtube/USER_DATA_CLEAR',

  YOUTUBE_VIDEO_POST_REQUEST = 'app/social/youtube/VIDEO_POST_REQUEST',
  YOUTUBE_VIDEO_POST_SUCCESS = 'app/social/youtube/VIDEO_POST_SUCCESS',
  YOUTUBE_VIDEO_POST_FAILURE = 'app/social/youtube/VIDEO_POST_FAILURE',

  YOUTUBE_VIDEO_POST_AWAIT_REQUEST = 'app/social/youtube/VIDEO_POST_AWAIT_REQUEST',
  YOUTUBE_VIDEO_POST_AWAIT_SUCCESS = 'app/social/youtube/VIDEO_POST_AWAIT_SUCCESS',
  YOUTUBE_VIDEO_POST_AWAIT_FAILURE = 'app/social/youtube/VIDEO_POST_AWAIT_FAILURE',

  YOUTUBE_CHANNEL_GET_REQUEST = 'app/social/youtube/YOUTUBE_CHANNEL_GET_REQUEST',
  YOUTUBE_CHANNEL_GET_FAILURE = 'app/social/youtube/YOUTUBE_CHANNEL_GET_FAILURE',
  YOUTUBE_CHANNEL_GET_SUCCESS = 'app/social/youtube/YOUTUBE_CHANNEL_GET_SUCCESS',

  YOUTUBE_AUTHORIZE_SUCCESS = 'app/social/youtube/AUTHORIZE_SUCCESS',

  PLAYLISTS_GET_REQUEST = 'app/social/youtube/PLAYLISTS_GET_REQUEST',
  PLAYLISTS_GET_SUCCESS = 'app/social/youtube/PLAYLISTS_GET_SUCCESS',
  PLAYLISTS_GET_FAILURE = 'app/social/youtube/PLAYLISTS_GET_FAILURE',

  CATEGORIES_GET_REQUEST = 'app/social/youtube/CATEGORIES_GET_REQUEST',
  CATEGORIES_GET_SUCCESS = 'app/social/youtube/CATEGORIES_GET_SUCCESS',
  CATEGORIES_GET_FAILURE = 'app/social/youtube/CATEGORIES_GET_FAILURE',

  PLAYLIST_CREATE_REQUEST = 'app/social/youtube/PLAYLISTS_CREATE_REQUEST',
  PLAYLIST_CREATE_SUCCESS = 'app/social/youtube/PLAYLISTS_CREATE_SUCCESS',
  PLAYLIST_CREATE_FAILURE = 'app/social/youtube/PLAYLISTS_CREATE_FAILURE',
}
