import * as React from 'react';

import TosLink from 'components/TosLink';
import { block as blockAccountSettings } from '../utils';

function Disclaimer() {
  return (
    <div className={blockAccountSettings('disclaimer')}>
      By uploading or sending us any fonts/font files, you represent and warrant
      that you own or otherwise have the valid right to use such files and
      images in connection with the SpareMin software and this site, and
      acknowledge that you have read and accepted our&nbsp;
      <TosLink />.
    </div>
  );
}

export default Disclaimer;
