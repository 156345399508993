import React from 'react';

import CreditCardRecoveryBanner, {
  useCreditCardRecoveryBanner,
} from 'blocks/Pricing/containers/CreditCardRecoveryBanner';
import VerificationReminderBanner from '../VerificationReminderBanner';
import useVerificationBanner from '../VerificationReminderBanner/useVerificationBanner';

export interface DownloadPageBannerSlotProps {}

const DownloadPageBannerSlot: React.FC<DownloadPageBannerSlotProps> = () => {
  const { show: showVerification } = useVerificationBanner();
  const { show: showCreditCardRecovery } = useCreditCardRecoveryBanner();

  return showCreditCardRecovery ? (
    <CreditCardRecoveryBanner />
  ) : showVerification ? (
    <VerificationReminderBanner />
  ) : null;
};

export default DownloadPageBannerSlot;
