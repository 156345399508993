import classNames from 'classnames';
import React from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { isEmpty } from 'underscore';
import { ProBadge } from 'containers/ProIndicator';
import { EngineRegistry } from './types';
import { searchResultsBlock as block } from './utils';

export interface ProvidersListProps {
  title?: string;
  engines?: EngineRegistry;
  navContainerClassName?: string;
  noProvidersMessage?: string;
  isDisabled?: boolean;
}

const ProvidersList: React.FC<ProvidersListProps> = ({
  title = 'Provider',
  engines,
  navContainerClassName,
  noProvidersMessage = 'Sorry, but there are no providers for this account.',
  isDisabled,
}) => {
  return (
    <div className={classNames(navContainerClassName, block('providers'))}>
      <div className={block('nav-title')}>{title}</div>

      {isEmpty(engines) ? (
        <span className={block('nav', { empty: true })}>
          {noProvidersMessage}
        </span>
      ) : (
        <Nav bsStyle="pills" className={block('nav')}>
          {Object.keys(engines).map(key => (
            <NavItem
              eventKey={key}
              key={key}
              disabled={engines[key].pro || isDisabled}
              className={block('nav-item')}
            >
              {engines[key].displayName}

              {engines[key].pro && (
                <span className={block('nav-pro-badge')}>
                  <ProBadge />
                </span>
              )}
            </NavItem>
          ))}
        </Nav>
      )}
    </div>
  );
};

export default ProvidersList;
