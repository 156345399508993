import cn from 'classnames';
import React from 'react';

import FontAwesome from 'components/FontAwesome';
import useProgressCircles from './useProgressCircles';
import { block } from './utils';

export interface CirclePlayButtonProps
  extends Pick<React.HTMLProps<HTMLButtonElement>, 'onClick'> {
  className?: string;
  playing: boolean;
  progress: number;
}

const CirclePlayButton: React.FC<CirclePlayButtonProps> = ({
  className,
  onClick,
  playing,
  progress,
}) => {
  const visualPercent = !playing ? 100 : progress * 100;
  const circles = useProgressCircles({ playing, percentage: visualPercent });

  return (
    <button
      type="button"
      className={cn(block({ playing }), className)}
      onClick={onClick}
    >
      {circles}
      <FontAwesome
        className={block('icon', { play: !playing, pause: playing })}
        icon={playing ? 'pause' : 'play'}
        style={{ height: 'auto', width: 20 }}
      />
    </button>
  );
};

export default CirclePlayButton;
