import * as React from 'react';

import { ProgressBar } from 'components/icons';
import { ProgressType } from 'types';
import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import NoSelectionTile from './NoSelectionTile';
import OptionChildView, { OptionChildViewProps } from './OptionChildView';
import { ProgressPlacementTile } from './progress-settings';
import { TimerPopperValue } from './progress-settings/TimerPopper';
import TimerTile from './progress-settings/TimerTile';
import { ProgressEnabledTile } from './progress-tiles';
import ProgressPlayerTile from './ProgressPlayerTile';
import TileGrid from './TileGrid';
import { ProgressState } from './types';
import useProgressPlacement from './useProgressPlacement';
import { progressViewBlock as block } from './utils';
import VideoTemplateEditorColorChangeInput from './VideoTemplateEditorColorChangeInput';

const { useCallback } = React;

type PickedChildViewProps = Pick<OptionChildViewProps, 'active' | 'onClose'>;

export interface ProgressChildViewProps extends PickedChildViewProps {}

const ProgressChildView: React.FC<ProgressChildViewProps> = ({
  active,
  onClose,
}) => {
  const { progress, timer } = useTemplateState();
  const dispatch = useTemplateDispatch();
  const {
    value: progressPlacement,
    onPlacementChange,
  } = useProgressPlacement();

  const handleProgressChange = useCallback(
    (value: Partial<ProgressState>) => {
      dispatch({
        payload: {
          ...progress,
          ...value,
        },
        type: 'PROGRESS_CHANGE',
      });
    },
    [dispatch, progress],
  );

  const handleTimerChange = useCallback(
    (value: TimerPopperValue) => {
      dispatch({
        payload: {
          ...value,
        },
        type: 'TIMER_CHANGE',
      });
    },
    [dispatch],
  );

  const handleToColorChange = useCallback(
    (color: string) => {
      handleProgressChange({ color });
    },
    [handleProgressChange],
  );

  const handleFromColorChange = useCallback(
    (color: string) => {
      handleProgressChange({ fillColor: color });
    },
    [handleProgressChange],
  );

  const handlePlayerTileClick = useCallback(() => {
    dispatch({
      payload: ProgressType.PLAYER,
      type: 'PROGRESS_TYPE_CHANGE',
    });
  }, [dispatch]);

  const handleBarTileClick = useCallback(() => {
    dispatch({
      payload: ProgressType.BAR,
      type: 'PROGRESS_TYPE_CHANGE',
    });
  }, [dispatch]);

  const handleNoneTileClick = useCallback(() => {
    dispatch({
      payload: null,
      type: 'PROGRESS_TYPE_CHANGE',
    });
  }, [dispatch]);

  return (
    <OptionChildView
      active={active}
      bodyClassName={block('body')}
      className={block()}
      icon={<ProgressBar style={{ width: 30, height: 'auto' }} />}
      onClose={onClose}
      title="progress"
    >
      <div className={block('tile-group')} role="group">
        <ProgressPlacementTile
          className={block('placement-tile')}
          disabled={!progress?.enabled}
          onChange={onPlacementChange}
          value={progressPlacement}
        />
        <TimerTile onChange={handleTimerChange} value={timer} />
      </div>
      <VideoTemplateEditorColorChangeInput
        className={block('color-change')}
        disabled={!progress?.enabled}
        fromColor={progress?.fillColor}
        onFromColorChange={handleFromColorChange}
        onToColorChange={handleToColorChange}
        toColor={progress?.color}
      />
      <TileGrid>
        <NoSelectionTile
          selected={!progress?.enabled}
          onClick={handleNoneTileClick}
        />
        <ProgressEnabledTile
          selected={progress?.enabled && progress?.type === ProgressType.BAR}
          onClick={handleBarTileClick}
        />
        <ProgressPlayerTile
          selected={progress?.enabled && progress?.type === ProgressType.PLAYER}
          onClick={handlePlayerTileClick}
        />
      </TileGrid>
    </OptionChildView>
  );
};

export default ProgressChildView;
