import { delayActionBuilder } from 'redux/middleware/delay';
import { ThunkAction } from 'redux/types';

export const cancelPolling = (
  pollId: string,
): ThunkAction<void> => dispatch => {
  const cancelAction = delayActionBuilder()
    .id(pollId)
    .cancel()
    .build();
  dispatch(cancelAction);
};
