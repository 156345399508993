import * as React from 'react';

import { Replace } from 'components/icons';
import OptionTile from '../assets/OptionTile';
import useReplaceMedia from '../useReplaceMedia';

export interface ReplaceTileProps {
  className?: string;
}

const ReplaceTile: React.FC<ReplaceTileProps> = props => {
  const { className } = props;
  const onReplaceMedia = useReplaceMedia();

  return (
    <OptionTile
      className={className}
      icon={<Replace style={{ height: 36, width: 'auto' }} />}
      label="replace"
      onClick={onReplaceMedia}
    />
  );
};

export default ReplaceTile;
