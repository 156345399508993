import { useToggle } from '@sparemin/blockhead';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createEddyProject } from 'redux/middleware/api/eddy-service';
import { projectNameSelector } from 'redux/modules/download';
import { onEddyPopperClicked, onEddyPopperShown } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import useDownloadPageState from '../useDownloadPageState';

export interface UsePromoPopperConfig {
  hasEddyProject: boolean;
}

export interface UsePromoPopperResult {
  showPopper: boolean;
  isLoading: boolean;
  toggleShowPopper: () => void;
  onOpenProject: () => void;
  onCreateAssets: () => void;
  onDismissPromoPopper: () => void;
}

export default function usePromoPopper({
  hasEddyProject,
}: UsePromoPopperConfig): UsePromoPopperResult {
  const [isLoading, setIsLoading] = useState(false);

  const { value: showPopper, toggle: toggleShowPopper } = useToggle(false);

  const { wid } = useDownloadPageState();
  const projectName = useSelector(projectNameSelector);
  const dispatch = useDispatch<Dispatch>();

  const handleToggleShowPopper = useCallback((): void => {
    toggleShowPopper();
  }, [toggleShowPopper]);

  const handleOpenProject = useCallback((): void => {
    dispatch(onEddyPopperClicked('Visit'));
    handleToggleShowPopper();
  }, [dispatch, handleToggleShowPopper]);

  const handleCreateAssets = useCallback(async (): Promise<void> => {
    setIsLoading(true);

    dispatch(onEddyPopperClicked('CreateAssets'));

    const {
      response: { url },
    } = await dispatch(
      createEddyProject(projectName, {
        makeVideoWidgetId: wid,
        mediaType: 'video',
      }),
    );

    setIsLoading(false);

    window.open(url, '_blank');
  }, [dispatch, projectName, wid]);

  const handleDismissPromoPopper = useCallback((): void => {
    dispatch(onEddyPopperClicked('Dismiss'));
    handleToggleShowPopper();
  }, [dispatch, handleToggleShowPopper]);

  useEffect(() => {
    if (showPopper) {
      dispatch(
        onEddyPopperShown(
          hasEddyProject ? 'EddyProjectReady' : 'CreateEddyProject',
        ),
      );
    }
  }, [dispatch, hasEddyProject, showPopper]);

  return {
    showPopper,
    isLoading,
    toggleShowPopper: handleToggleShowPopper,
    onOpenProject: handleOpenProject,
    onCreateAssets: handleCreateAssets,
    onDismissPromoPopper: handleDismissPromoPopper,
  };
}
