import { getErrorMessage, SignUpCard, SignUpCardProps } from '@sparemin/auth';
import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import useRegistrationQuery from 'blocks/Authentication/hooks/useRegistrationQuery';
import { onSignup, onSignupError } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import useAuthenticationSuccessHandler from '../hooks/useAuthenticationSuccessHandler';

const RegistrationCard: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const { onAuthenticationSuccess } = useAuthenticationSuccessHandler();

  // the auth library handles sending metadata like referralKey and promo code to
  // the API, however Headliner needs to send some data to MP so we read it separately here
  useRegistrationQuery({
    onLoad({ referralKey, tier }) {
      dispatch(onSignup(tier, referralKey));
    },
  });

  const handleError: SignUpCardProps['onError'] = useCallback(
    error => {
      dispatch(
        onSignupError(getErrorMessage(error) ?? 'Unknown registration error'),
      );
    },
    [dispatch],
  );

  return (
    <SignUpCard
      onAuthenticateSuccess={onAuthenticationSuccess}
      onError={handleError}
    />
  );
};

export default RegistrationCard;
