import * as React from 'react';

import { block } from '../utils';

const EmptyProjectList: React.SFC = () => (
  <div className={block('empty')}>
    <div className={block('empty-message')}>No projects yet</div>
    <div className={block('empty-detail')}>
      Projects you start will automatically appear here
    </div>
  </div>
);

export default EmptyProjectList;
