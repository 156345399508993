import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import ExtractAudioFeatureFakeModalContent from './ExtractAudioFeatureFakeModalContent';

export interface Props {}

const ExtractAudioFeatureFakeModal: React.FC<Props> = () => {
  return (
    <ConnectedModal
      name="ExtractAudioFeatureFake"
      title="extract audio from video"
    >
      <ExtractAudioFeatureFakeModalContent />
    </ConnectedModal>
  );
};

export default ExtractAudioFeatureFakeModal;
