import React from 'react';

import ToggleCard, { FreeToggleCardProps } from 'components/ToggleCard';
import { toggleCardBlock as block } from './utils';

export type VideoTemplateEditorToggleCardProps = FreeToggleCardProps;

const VideoTemplateEditorToggleCard: React.FC<VideoTemplateEditorToggleCardProps> = ({
  icon,
  theme,
  ...props
}) => {
  return (
    <ToggleCard
      icon={
        <div className={block('icon-container', { [theme]: !!theme })}>
          {icon}
        </div>
      }
      theme={theme}
      {...props}
    />
  );
};

export default VideoTemplateEditorToggleCard;
