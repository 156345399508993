import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { LongWaveIcon, WaveIcon } from 'components/icons';
import Modal from 'components/Modal/v2';

import CTAButtonsModalContents, {
  CTAButton,
} from 'containers/CTAButtonsModalContents/CTAButtonsModalContents';
import {
  CompatibilityDetails,
  getProjectTemplateCompatibilityFromConfigurationId,
} from 'redux/middleware/api/headliner-user-service';
import { onClickTemplateLengthSelectorModalCTA } from 'redux/modules/mixpanel';
import { ClickSaveTemplateFrom } from 'redux/modules/mixpanel/types';
import { hideModal, replaceModal } from 'redux/modules/modal';
import { Modal as ModalType } from 'redux/modules/modal/types';
import { showError } from 'redux/modules/notification';

interface TemplateLengthSelectorProps {
  configurationId: string;
  from?: ClickSaveTemplateFrom;
}

const TemplateLengthSelector: React.FC<TemplateLengthSelectorProps> = props => {
  const { configurationId, from } = props;

  const dispatch = useDispatch();

  const handleHide = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  const openModal = useCallback(
    (modal: ModalType): void => {
      dispatch(replaceModal(modal));
    },
    [dispatch],
  );

  const openSaveAsTemplateModal = useCallback((): void => {
    openModal({
      name: 'SaveAsTemplate',
      params: { from },
    });
  }, [from, openModal]);

  const openCompatibilityModal = useCallback(
    (compatibilityDetails: CompatibilityDetails): void => {
      openModal({
        name: 'FullLengthTemplateCompatibility',
        params: { compatibilityDetails },
      });
    },
    [openModal],
  );

  const checkProjectTemplateCompatibility = async () => {
    const {
      response: { fullEpisode },
    } = await dispatch(
      getProjectTemplateCompatibilityFromConfigurationId(configurationId),
    );

    const { compatibilityDetails, isCompatible } = fullEpisode;

    try {
      if (isCompatible) {
        openSaveAsTemplateModal();
      } else {
        openCompatibilityModal(compatibilityDetails);
      }
    } catch {
      dispatch(
        showError({
          message: 'Error checking project template compatibility',
          code: 'ER012',
        }),
      );
    }
  };

  const CTAButtons: CTAButton[] = [
    {
      id: 'upTo10Min',
      icon: <WaveIcon width={65} />,
      label: 'Up to 10 min',
      description:
        'Perfect for creating clips to post onto social media or YouTube.',
      action: () => {
        dispatch(onClickTemplateLengthSelectorModalCTA('short'));
        openSaveAsTemplateModal();
      },
    },
    {
      id: 'fullLength',
      icon: <LongWaveIcon width={75} />,
      label: 'Full-length',
      description:
        'Perfect for turning entire podcast episodes into audiograms.',
      action: () => {
        dispatch(onClickTemplateLengthSelectorModalCTA('fullLength'));
        checkProjectTemplateCompatibility();
      },
      variant: 'tapestry',
    },
  ];

  return (
    <Modal
      header={
        <Modal.Header
          onHide={handleHide}
          title="How will you use this template?"
          subtitle="Please select the video length you plan on using this template for."
        />
      }
    >
      <CTAButtonsModalContents buttons={CTAButtons} />
    </Modal>
  );
};

export default TemplateLengthSelector;
