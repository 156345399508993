import { uploadVideo } from 'redux/middleware/api/media-upload-service/actions';
import { waitForVideoUpload } from 'redux/modules/common/actions';
import { ThunkAction } from 'redux/types';
import {
  ExportConfigOverride,
  IntroOutroConfigOverride,
  IntroOutroUploadData,
  ProcessIntroOutroOutput,
} from './types';

/**
 * Upload media using media upload service
 * @param {string | number | File} src - File, url or id from the media source
 * @param {string} type - Type of the media source
 * @returns {object | undefined} - An object containing the id of the just uploaded clip or
 * undefined if conditions are not met.
 */
export const mediaUploader = (
  src: IntroOutroConfigOverride['value'],
  type: IntroOutroConfigOverride['type'],
): ThunkAction<Promise<IntroOutroUploadData | undefined>> => async dispatch => {
  if (!src) {
    return undefined;
  }

  if (type === 'id') {
    return { id: src };
  }

  if (typeof src === 'number') {
    return undefined;
  }

  const videoInitialUpload = await dispatch(uploadVideo(src, {}));
  const uploadedVideo = await dispatch(
    waitForVideoUpload(videoInitialUpload.response.result),
  );

  return uploadedVideo.toJS();
};

/**
 * Checks export override config and if either intro or outro clips are modified it
 * uploads them and obtains their video ids for setting the embed configuration.
 * @param {object} exportConfigOverride - the edge videos config overriden by the export modal form
 * @returns {object} - An object containing the videoId for the overriden intro or outro clips
 */
export const processIntroOutroClipUploads = (
  exportConfigOverride: ExportConfigOverride,
): ThunkAction<Promise<ProcessIntroOutroOutput>> => dispatch => {
  if (!exportConfigOverride.edgeVideos) {
    return Promise.resolve({});
  }

  const {
    intro: introExportConfig,
    outro: outroExportConfig,
  } = exportConfigOverride.edgeVideos;

  const introUploader = dispatch(
    mediaUploader(introExportConfig.value, introExportConfig.type),
  );
  const outroUploader = dispatch(
    mediaUploader(outroExportConfig.value, outroExportConfig.type),
  );
  return Promise.all([introUploader, outroUploader]).then(
    ([introSrc, outroSrc]) => {
      const intro = introSrc ? { videoId: introSrc.id } : undefined;
      const outro = outroSrc ? { videoId: outroSrc.id } : undefined;
      return { edgeVideos: { intro, outro } };
    },
  );
};
