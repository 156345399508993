import React from 'react';
import DownloadCaptionsDialogModal from './DownloadCaptionsDialogModal';
import TranscribeEpisodeModalContents from './TranscribeEpisodeModalContents';

export interface TranscribeEpisodeModalProps {}

const TranscribeEpisodeModal: React.FC<TranscribeEpisodeModalProps> = () => (
  <DownloadCaptionsDialogModal
    name="TranscribeEpisode"
    title="Episode Transcript"
  >
    <TranscribeEpisodeModalContents />
  </DownloadCaptionsDialogModal>
);

export default TranscribeEpisodeModal;
