import React from 'react';
import { IconProps } from './types';

function Help(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 18 18"
      {...props}
    >
      <defs>
        <linearGradient id="prefix__a" x1="0%" x2="100%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#5DBEFC" />
          <stop offset="100%" stopColor="#DD92FE" />
        </linearGradient>
      </defs>
      <path
        d="M1305 43c0 4.972-4.03 9-9 9s-9-4.028-9-9c0-4.969 4.03-9 9-9s9 4.031 9 9zm-8.758-6.024c-1.978 0-3.24.833-4.23 2.314a.437.437 0 00.098.59l1.26.954c.189.144.458.11.604-.077.649-.822 1.093-1.299 2.08-1.299.741 0 1.658.477 1.658 1.196 0 .544-.448.823-1.18 1.233-.854.479-1.984 1.075-1.984 2.565v.145c0 .24.195.435.436.435h2.032c.24 0 .436-.195.436-.435v-.049c0-1.032 3.018-1.076 3.018-3.87 0-2.105-2.183-3.702-4.228-3.702zm-.242 9c-.92 0-1.67.749-1.67 1.67 0 .92.75 1.669 1.67 1.669.92 0 1.67-.75 1.67-1.67 0-.92-.75-1.67-1.67-1.67z"
        transform="translate(-1287 -34)"
        fill="url(#prefix__a)"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default Help;
