import { useCallback, useState } from 'react';
import _ from 'underscore';
import { TemplateAction, VideoTemplateStateContent } from './types';

export interface UseTemplateDirtyConfig {
  initialState?: boolean;
  state: VideoTemplateStateContent;
}

/**
 * It checks if the template was dirt by the user.
 * When a child view is opened, that is registered inside this hook state. That is
 * fundamental for this hook to work correctly because after childViewHasBeenOpened
 * is set to true we know for sure that all changes after that were triggered by the user.
 * After that, all this does is a comparation between the initial state of the template and
 * the new state, if they're not equal that means that the user changed something in the template.
 */
export default function useTemplateDirty(config: UseTemplateDirtyConfig) {
  const { initialState, state } = config;

  const [prevState, setPrevState] = useState(state);
  const [isTemplateDirty, setIsTemplateDirty] = useState(initialState);
  const [childViewHasBeenOpened, setChildViewHasBeenOpened] = useState(false);

  const checkChildViewWasOpened = useCallback(
    (action: TemplateAction): void => {
      if (action.type === 'CHILD_VIEW_OPEN') {
        setChildViewHasBeenOpened(true);
        setPrevState(state);
      }
    },
    [state],
  );

  const resetTemplateDirtyState = useCallback((): void => {
    setIsTemplateDirty(false);
  }, []);

  if (!_.isEqual(state, prevState) && childViewHasBeenOpened) {
    setPrevState(state);
    setIsTemplateDirty(true);
    setChildViewHasBeenOpened(false);
  }

  return {
    isTemplateDirty,
    checkChildViewWasOpened,
    resetTemplateDirtyState,
  };
}
