import React, { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import ModalFooterButton from 'components/Modal/ModalFooterButton';
import { Step } from 'components/SteppedModal';
import usePrevious from 'hooks/usePrevious';
import { threadsUserIdSelector } from 'redux/modules/social';
import { PostStatus } from 'redux/modules/social/constants';
import ThreadsPostComposer, {
  ThreadsPostComposerProps,
} from '../ThreadsPostComposer';
import useThreadsPostModalSelector from '../useThreadsPostModalSelector';

export interface UseComposerStepValue {
  description: string;
  threadsUserId: string;
}

export interface UseComposerStepConfig
  extends Pick<ThreadsPostComposerProps, 'readonly'>,
    Pick<Step, 'submitButtonLabel'> {
  defaultDescription?: string;
  onSwitchAccountClick?: () => void;
  onPostSuccess?: () => void;
  onSubmit?: (value: UseComposerStepValue) => void;
}

export default function useComposerStep(
  config: UseComposerStepConfig = {},
): Step {
  const {
    defaultDescription: defaultDescriptionProp,
    readonly,
    submitButtonLabel = 'post to threads',
    onSwitchAccountClick,
    onPostSuccess,
    onSubmit,
  } = config;

  const { defaultDescription, postStatus } = useThreadsPostModalSelector({
    defaultDescription: defaultDescriptionProp,
  });
  const threadsUserId = useSelector(threadsUserIdSelector);

  const [description, setDescription] = useState<string>(defaultDescription);

  const prevPostStatus = usePrevious(postStatus);

  const isUploading = postStatus === PostStatus.IN_PROGRESS;

  const handleSubmit = useCallback(() => {
    onSubmit?.({
      description,
      threadsUserId,
    });
  }, [onSubmit, description, threadsUserId]);

  useEffect(() => {
    if (
      prevPostStatus === PostStatus.IN_PROGRESS &&
      postStatus === PostStatus.SUCCESS
    ) {
      setDescription(defaultDescription);
      onPostSuccess?.();
    }
  }, [defaultDescription, onPostSuccess, postStatus, prevPostStatus]);

  return {
    id: 'composer',
    fluidFooterButtons: true,
    component: (
      <ThreadsPostComposer
        onDescriptionChange={setDescription}
        {...{
          description,
          isUploading,
          readonly,
          onSwitchAccountClick,
        }}
      />
    ),
    renderFooterButtons: ({ submit }) => [
      <ModalFooterButton
        {...submit}
        key="submit"
        theme="submit"
        disabled={isUploading}
      >
        {submitButtonLabel}
      </ModalFooterButton>,
    ],
    onSubmit: handleSubmit,
  };
}
