import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createImageToVideo,
  resetImageToVideo,
} from 'redux/modules/image-to-video';
import {
  imageToVideoHasErrorSelector,
  isPollingImageToVideoSelector,
} from 'redux/modules/image-to-video/selectors';
import { videoDurationSelector } from 'redux/modules/video-edit';
import { Dispatch } from 'redux/types';

export interface UseImageToVideoConfig {
  image?: string;
  onImageToVideoSuccess: (url: string, poster: string) => void;
}

export interface UseImageToVideoResults {
  isLoading: boolean;
  hasError: boolean;
  onAnimate: (sourceImageUrl: string) => void;
}

export default function useImageToVideo({
  image,
  onImageToVideoSuccess,
}: UseImageToVideoConfig): UseImageToVideoResults {
  const [generationFinished, setGenerationFinished] = useState(false);

  const isLoading = useSelector(isPollingImageToVideoSelector);
  const videoUrl = useSelector(videoDurationSelector);
  const hasError = useSelector(imageToVideoHasErrorSelector);

  const dispatch = useDispatch<Dispatch>();

  const handleAnimate = useCallback(
    async (sourceImageUrl: string): Promise<void> => {
      try {
        const result = await dispatch(
          createImageToVideo(sourceImageUrl, 'stability'),
        );

        if (!result || result.status === 'cancelled') {
          return;
        }

        if (result.status === 'completed') {
          onImageToVideoSuccess(result.videos?.[0]?.url, image);
          dispatch(resetImageToVideo());
          setGenerationFinished(true);
        }
      } catch {
        // error is already handled by the action state
      }
    },
    [dispatch, image, onImageToVideoSuccess],
  );

  return {
    isLoading: isLoading || (generationFinished && !videoUrl),
    hasError,
    onAnimate: handleAnimate,
  };
}
