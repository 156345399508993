import * as jwt from 'jsonwebtoken';

import { Replace } from 'types';

interface IRawPayload {
  exp: number;
  iat: number;
  iss: string;
  role: string;
  sub: string;
}

export type Payload = Replace<
  IRawPayload,
  {
    sub: number;
  }
>;

export function decode(token: string): Payload {
  if (!token) return undefined;

  const payload = jwt.decode(token) as IRawPayload;
  if (!payload) return undefined;

  const { sub, ...restPayload } = payload;
  return {
    ...restPayload,
    sub: Number(sub),
  };
}

export { IRawPayload as RawPayload };
