import React from 'react';

// types
import { IconProps } from './types';

const Refresh: React.FC<IconProps> = ({
  fill = 'currentColor',
  stroke = '#8ca0c3',
  ...svgProps
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    {...svgProps}
  >
    <defs>
      <filter
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        filterUnits="objectBoundingBox"
        id="fau2spjvka"
      >
        <feGaussianBlur in="SourceGraphic" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill={fill} filter="url(#fau2spjvka)" cx="50" cy="50" r="50" />
      <path
        d="M63.759 47.858h.586c.917 0 1.655-.717 1.655-1.607v-8.57c0-.65-.4-1.239-1.02-1.487a1.694 1.694 0 0 0-1.807.349l-2.87 2.785c-6.04-5.792-15.77-5.772-21.777.067-6.035 5.858-6.035 15.353 0 21.211 6.034 5.859 15.813 5.859 21.847 0a2.102 2.102 0 0 0 0-3.033 2.257 2.257 0 0 0-3.124 0c-4.31 4.185-11.296 4.185-15.606 0-4.31-4.185-4.31-10.967 0-15.152 4.29-4.164 11.22-4.185 15.537-.067l-2.835 2.759a1.575 1.575 0 0 0-.358 1.754c.255.603.862.99 1.53.99h8.242z"
        fill={stroke}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default Refresh;
