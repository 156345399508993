import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import CircularGradient, { CircularGradientProps } from './CircularGradient';

export type CircularGradientCommentsProps = Pick<
  CircularGradientProps,
  'className'
>;

const CircularGradientComments: React.FC<CircularGradientCommentsProps> = ({
  className,
}) => (
  <CircularGradient className={className}>
    <FontAwesome icon="comments" style={{ height: '44%', width: '44%' }} />
  </CircularGradient>
);

export default CircularGradientComments;
