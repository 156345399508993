import * as React from 'react';

import { IconProps } from './types';

const Visible: React.FC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="13"
    viewBox="0 0 20 13"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M19.77 5.64a11.282 11.282 0 0 0-19.54 0 1.721 1.721 0 0 0 0 1.72 11.282 11.282 0 0 0 19.54 0 1.721 1.721 0 0 0 0-1.72zM10 11.303c-2.61 0-4.722-2.148-4.722-4.804 0-2.655 2.112-4.804 4.722-4.804 2.61 0 4.722 2.148 4.722 4.804 0 2.655-2.112 4.804-4.722 4.804zM13.611 6.5c0 2.03-1.617 3.674-3.611 3.674-1.994 0-3.611-1.645-3.611-3.674 0-.626.154-1.214.425-1.73v.001c0 .836.665 1.513 1.487 1.513.82 0 1.487-.677 1.487-1.513 0-.835-.666-1.513-1.487-1.513H8.3a3.546 3.546 0 0 1 1.7-.432c1.994 0 3.611 1.645 3.611 3.674z"
    />
  </svg>
);

export default Visible;
