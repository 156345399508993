import React from 'react';

// types
import { IconProps } from './types';

const Youtube: React.FC<IconProps> = ({ ...svgProps }) => (
  <svg
    width="85"
    height="85"
    viewBox="0 0 85 85"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...svgProps}
  >
    <defs>
      <filter
        x="-20%"
        y="-15.3%"
        width="100%"
        height="100%"
        filterUnits="objectBoundingBox"
        id="yt-a"
      >
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <circle id="yt-b" cx="42.5" cy="42.5" r="42.5" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <use fill="#000" filter="url(#yt-a)" xlinkHref="#yt-b" />
        <use fill="#FFF" xlinkHref="#yt-b" />
      </g>
      <path fill="#FFF" d="M39 39h26v18H39z" />
      <path
        transform="translate(-10 -6)"
        d="M72.164 39.381a5.015 5.015 0 0 0-3.536-3.543C65.508 35 53 35 53 35s-12.508 0-15.628.838a5.015 5.015 0 0 0-3.536 3.543C33 42.507 33 49.028 33 49.028s0 6.522.836 9.648c.46 1.724 1.815 3.026 3.536 3.486C40.492 63 53 63 53 63s12.508 0 15.628-.838c1.72-.46 3.076-1.762 3.536-3.486C73 55.55 73 49.028 73 49.028s0-6.521-.836-9.647zM48.91 54.95V43.107l10.455 5.921-10.455 5.921z"
        fill="red"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default Youtube;
