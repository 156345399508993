import * as emailValidator from 'email-validator';

import { useSelector } from 'react-redux';
import useField from 'components/Form/useField';
import { authInProgressSelector } from 'redux/modules/auth/selectors';
import useUpdateEmailModalDispatch from './useUpdateEmailModalDispatch';

export default () => {
  const email = useField();
  const password = useField();
  const submitting = useSelector(authInProgressSelector);
  const { onSubmit } = useUpdateEmailModalDispatch();

  const validate = () => {
    let valid = true;

    if (!email.value || !emailValidator.validate(email.value)) {
      valid = false;
      email.setError("This doesn't seem to be a valid email address");
    }

    if (!password.value) {
      valid = false;
      password.setError('Required!');
    }

    return valid;
  };

  const handleSubmit = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();

    if (validate()) {
      onSubmit(email.value, password.value).catch(error => {
        password.setError(error.password);
        email.setError(error.email);
      });
    }
  };

  return {
    email,
    password,
    submitting,
    onSubmit: handleSubmit,
  };
};
