import * as React from 'react';
import _ from 'underscore';

interface IProps extends Omit<React.HTMLProps<HTMLAudioElement>, 'ref'> {
  isPlaying: boolean;
  loadOnMount?: boolean;
  onLoad?: () => void;
  onPause?: () => void;
  onPlay?: () => void;
}

class HiddenAudioPlayer extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    loop: true,
    onLoad: _.noop,
    onPause: _.noop,
    onPlay: _.noop,
    preload: 'none',
  };

  private audio: HTMLAudioElement;
  private audioPromise: Promise<void>;

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { isPlaying, onLoad } = this.props;
    if (isPlaying && !nextProps.isPlaying) {
      this.pause();
    }

    if (!isPlaying && nextProps.isPlaying) {
      if (this.audio.readyState !== 4) {
        this.audio.load();
        onLoad();
      }

      this.play();
    }
  }

  public componentDidMount(): void {
    const { loadOnMount, onLoad } = this.props;

    if (loadOnMount) {
      this.audio.load();
      onLoad();
    }
  }

  private play() {
    const { onPlay } = this.props;

    this.audioPromise = this.audio.play();

    // In browsers that don't yet support this functionality, this.audioPromise won't be defined.
    // See more on this: https://developers.google.com/web/updates/2016/03/play-returns-promise
    if (this.audioPromise) {
      this.audioPromise.then(onPlay).catch(err => {
        if (!(err instanceof DOMException) || err.name !== 'AbortError') {
          throw err;
        }
      });
    } else {
      onPlay();
    }
  }

  private pause() {
    const { onPause } = this.props;

    this.audio.pause();
    this.audioPromise = undefined;
    onPause();
  }

  private setAudioRef = el => {
    this.audio = el;
  };

  public setCurrentTime = (posSecs: number): void => {
    if (posSecs >= 0 && posSecs <= this.audio.duration) {
      this.audio.currentTime = posSecs;
    }
  };

  public render() {
    const { isPlaying, onPause, onLoad, onPlay, ...rest } = this.props;
    return (
      <audio {...rest} ref={this.setAudioRef} style={{ display: 'none' }} />
    );
  }
}

export default HiddenAudioPlayer;
