import React from 'react';
import { IconProps } from './types';

const HeadyOutline: React.FC<IconProps> = props => (
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="a">
        <stop stopColor="#5DBEFC" offset="0%" />
        <stop stopColor="#DD92FE" offset="100%" />
      </linearGradient>
    </defs>
    <path
      d="M308 87.906c6.224 0 12.26 1.218 17.94 3.62a46.063 46.063 0 0 1 7.829 4.25 46.432 46.432 0 0 1 12.454 12.454 46.063 46.063 0 0 1 4.25 7.829c2.403 5.68 3.62 11.717 3.62 17.941 0 6.224-1.217 12.26-3.62 17.94a46.063 46.063 0 0 1-4.249 7.829 46.432 46.432 0 0 1-12.454 12.454 46.063 46.063 0 0 1-7.829 4.25c-5.68 2.403-11.717 3.62-17.941 3.62-6.224 0-12.26-1.217-17.94-3.62a46.063 46.063 0 0 1-7.829-4.249 46.432 46.432 0 0 1-12.454-12.454 46.063 46.063 0 0 1-4.25-7.829c-2.403-5.68-3.62-11.717-3.62-17.941 0-6.224 1.217-12.26 3.62-17.94a46.063 46.063 0 0 1 4.249-7.829 46.432 46.432 0 0 1 12.454-12.454 46.063 46.063 0 0 1 7.829-4.25c5.68-2.403 11.717-3.62 17.941-3.62M308 84c-27.614 0-50 22.386-50 50s22.386 50 50 50 50-22.386 50-50-22.386-50-50-50zm19.971 76.312c-5.802 4.3-12.656 6.572-19.506 6.572a35.6 35.6 0 0 1-10.665-1.613 32.957 32.957 0 0 1-9.771-4.96 1.953 1.953 0 0 0-2.326 3.139 36.857 36.857 0 0 0 10.927 5.548 39.502 39.502 0 0 0 11.836 1.792c7.714 0 15.376-2.556 21.832-7.34a1.953 1.953 0 1 0-2.327-3.138zm-15.18-21.516v6.976a8.171 8.171 0 0 0 16.342 0v-29.526a8.171 8.171 0 0 0-16.342 0v17.74h-9.582v-17.74a8.171 8.171 0 0 0-16.343 0v29.526a8.171 8.171 0 0 0 16.342 0v-6.976h9.583z"
      transform="translate(-258 -84)"
      fill="url(#a)"
      fillRule="nonzero"
    />
  </svg>
);

export default HeadyOutline;
