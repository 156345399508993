import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A>(method: M, args: A) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export const updateRecording = (
  recordingId: number,
  publishedStatus: boolean,
): types.UpdateRecordingAction =>
  apiCallAction(types.ServiceMethod.UPDATE_RECORDING, [
    recordingId,
    publishedStatus,
  ] as types.UpdateRecordingArgs);

export function fetchRecordingById(
  recordingId: number,
): types.GetRecordingByIdAction {
  return apiCallAction(types.ServiceMethod.RECORDING_BY_ID_GET, [
    recordingId,
  ] as types.GetRecordingByIdArgs);
}
