import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import AdjustmentNeededModalContent from './AdjustmentNeededModalContent';

const AdjustmentNeededModal = () => {
  return (
    <ConnectedModal name="AdjustmentNeeded" title="Alert">
      <AdjustmentNeededModalContent />
    </ConnectedModal>
  );
};

export default AdjustmentNeededModal;
