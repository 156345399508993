import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  PurchaseableTier,
  Tier,
} from 'redux/middleware/api/plan-service/types';
import {
  onIncreaseUploadLimit,
  onUnlockThisFeature,
} from 'redux/modules/mixpanel/actions';
import { hideModal, replaceModal } from 'redux/modules/modal';

import FileSizeUpsellDialog from './FileSizeUpsellDialog';
import { UploadLimit, Variant } from './types';

const { useCallback } = React;

const DEFAULT_UPGRADE_UPLOAD_LIMIT = '500MB';

export interface Props {
  fake?: boolean;
  uploadLimit?: UploadLimit;
  variant?: Variant;
}

const FileSizeUpsellModalContents = (props: Props) => {
  const {
    uploadLimit = DEFAULT_UPGRADE_UPLOAD_LIMIT,
    variant = 'default',
  } = props;

  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  const handleShowCheckoutModal = useCallback(
    (tier: PurchaseableTier) => {
      dispatch(
        replaceModal({
          name: 'AddBillingCycle',
          params: {
            tier,
            defaultSubscriptionPeriod: 'yearly',
            disableSuccessRedirect: true,
          },
        }),
      );
    },
    [dispatch],
  );

  const handleUpgrade = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (variant === 'default') {
        dispatch(onUnlockThisFeature(uploadLimit, 'videoTranscription'));
      } else {
        dispatch(onIncreaseUploadLimit('videoTranscription'));
      }
      handleShowCheckoutModal(Tier.PRO);
    },
    [dispatch, handleShowCheckoutModal, uploadLimit, variant],
  );

  return (
    <FileSizeUpsellDialog
      uploadLimit={uploadLimit}
      variant={variant}
      actions={[
        variant === 'file' && {
          children: 'no thanks',
          key: 'negative-cta',
          onClick: closeModal,
          theme: 'cancel' as const,
        },
        {
          children: 'Upgrade and Claim Credits',

          componentClass: Link,
          key: 'positive-cta',
          to: '/settings/my-plan',
          theme: 'rainbow' as const,
          onClick: handleUpgrade,
        },
      ].filter(Boolean)}
    />
  );
};

export default FileSizeUpsellModalContents;
