import Immutable from 'immutable';

import {
  defaultWaveformEnabledSelector,
  defaultWaveformSelector,
} from 'redux/modules/display-pref/selectors';
import {
  deleteCaptionsSource,
  setCaptionsMediaSource,
  setupCaptions,
} from 'redux/modules/embed/actions/captions';
import { createClippingOption } from 'utils/audio';

import {
  defaultSoundwaveState,
  hasSoundwave,
  mapSoundwaveGeneration,
} from 'utils/embed/soundwave';
import { isBgAudioTrack } from 'utils/embed/tracks';
import { getValue } from '../../../../utils/collections';
import * as commonActions from '../../common/actions';
import * as types from '../action-types';
import { entireAudioEnhancer } from '../enhancers';
import * as embedSelectors from '../selectors';
import {
  createEmbedConfigurationFromState,
  saveConfiguration,
  setEmbedDuration,
} from './embed';
import * as soundwaveActions from './soundwave';
import { addingToTrack, addToTrack, createTrack } from './tracks';

function embedAudioCollectionUpdateAction(audioById) {
  return {
    type: types.EMBED_AUDIO_COLLECTION_UPDATE,
    payload: { audioById },
  };
}

export const updateAudioCollection = updateFn => (dispatch, getState) => {
  const audioById =
    embedSelectors.audioByIdSelector(getState()) || Immutable.Map();
  const updatedAudioById = updateFn(audioById);

  dispatch(embedAudioCollectionUpdateAction(updatedAudioById));
  dispatch(setEmbedDuration(true));
};

export const addToAudioCollection = newAudio => dispatch => {
  dispatch(
    updateAudioCollection(audios => audios.set(newAudio.get('id'), newAudio)),
  );
};

export const updateAudio = audio => dispatch => {
  const id = getValue(audio, 'id');
  dispatch({
    type: types.EMBED_AUDIO_UPDATE,
    payload: { id, audio },
  });

  dispatch(setEmbedDuration(true));
};

function createAudioObject(recording, startOffsetMillis, language) {
  return Immutable.Map({
    endMillis: startOffsetMillis + recording.get('durationMillis'),
    id: recording.get('recordingId'),
    startMillis: startOffsetMillis,
    language,
  });
}

const deleteFromAudioTrack = (audioId, transcribe = true) => dispatch => {
  if (!audioId) return;

  dispatch({
    type: types.EMBED_AUDIO_DELETE,
    payload: { audioId },
  });
  transcribe && dispatch(deleteCaptionsSource(audioId, 'audio'));
  dispatch(setEmbedDuration(true));
};

const processAudioUploadResponse = (
  recording,
  trackId,
  startOffsetMillis = 0,
  language,
  transcribe = true,
  waveGeneration,
  onAddAudioProps = {},
) => (dispatch, getState) => {
  const recordingId = recording.get('recordingId');
  const audio = createAudioObject(recording, startOffsetMillis, language);
  const currentAudioId = embedSelectors
    .audioTracksSelector(getState())
    .getIn([trackId, 'data', 0]);
  const currentSoundwave = embedSelectors.soundwaveSelector(getState());
  const trackIds = embedSelectors.tracksSelector(getState());
  const tracksById = embedSelectors.tracksByIdSelector(getState());

  const updateSoundwave = s =>
    dispatch(
      soundwaveActions.updateSoundwave({
        ...s.toJS(),
        waveGeneration,
        time: {
          startMillis: startOffsetMillis,
          endMillis: startOffsetMillis + recording.get('durationMillis'),
        },
      }),
    );

  dispatch(deleteFromAudioTrack(currentAudioId, transcribe));
  dispatch(addToAudioCollection(audio));
  dispatch(
    addToTrack(trackId, audio.get('id'), 'audio', {
      onAddAudioProps,
    }),
  );

  if (
    hasSoundwave(currentSoundwave) &&
    !isBgAudioTrack(trackId, trackIds, tracksById)
  ) {
    // if a soundwave exists, realign it with the new audio asset
    updateSoundwave(currentSoundwave);
  } else if (defaultWaveformEnabledSelector(getState())) {
    // if no soundwave exists and the account has a default soundwave style
    // associated with it, create the new soundwave asset
    const soundwave = defaultSoundwaveState.merge(
      defaultWaveformSelector(getState()),
    );

    const waveformTrack = embedSelectors.waveformTrackSelector(getState());
    if (soundwave.get('waveType') !== 'none' && !waveformTrack) {
      dispatch(createTrack('waveform', { layerOrderType: 'free' }));
    }
    updateSoundwave(soundwave);
  }

  // kickoff polling for the waveform
  dispatch(commonActions.getRecordingWaveform(audio.get('id'))).then(() => {
    dispatch(createEmbedConfigurationFromState());
  });

  if (transcribe) {
    /*
     * transcribe is true, so captions will be enabled.  record the captions media source so we
     * know who the transcript belongs to
     */
    dispatch(setCaptionsMediaSource('audio', recordingId));

    // poll for transcript response
    const futTranscript = dispatch(
      entireAudioEnhancer.actions.waitForTranscript(),
    );

    // once we have transcript, process it (create manual transcript and save to config) and get
    // keyword analysis
    futTranscript.then(() => {
      dispatch(setupCaptions(recordingId, 'audio', true));
    });
  }

  // this action saves the audio to the configuration.  once transcript is created, another
  // save is dispatched (see above)
  return dispatch(createEmbedConfigurationFromState());
};

const addAudio = (uploadAudio, processUpload) => dispatch => {
  dispatch({ type: types.EMBED_AUDIO_ADD_REQUEST });
  return (uploadAudio() || Promise.resolve)
    .then(res => {
      if (!res) return undefined;
      return processUpload(res);
    })
    .then(() => dispatch({ type: types.EMBED_AUDIO_ADD_SUCCESS }))
    .catch(err =>
      dispatch({
        type: types.EMBED_AUDIO_ADD_FAILURE,
        error: err.message,
      }),
    );
};

export const addToAudioTrack = (
  audioClip,
  startOffsetMillis,
  language,
  transcribe,
  waveGeneration,
) => (dispatch, getState) => {
  const trackId = embedSelectors.selectAddingToTrack(getState());

  if (transcribe) {
    dispatch(deleteCaptionsSource());
  }

  const uploadAudio = () =>
    dispatch(
      entireAudioEnhancer.actions.clipEntireAudio(
        audioClip.startMillis,
        audioClip.endMillis,
        language,
        waveGeneration,
        transcribe,
      ),
    );

  const onAddAudioProps = {
    originalAudioDuration: audioClip.originalAudioDuration,
    waveGeneration: mapSoundwaveGeneration(waveGeneration),
    ...createClippingOption(audioClip),
  };

  const processUpload = res =>
    dispatch(
      processAudioUploadResponse(
        res,
        trackId,
        startOffsetMillis,
        language,
        transcribe,
        waveGeneration,
        onAddAudioProps,
      ),
    );

  return dispatch(addAudio(uploadAudio, processUpload));
};

export const removeFromAudioTrack = audioId => (dispatch, getState) => {
  dispatch({ type: types.EMBED_AUDIO_REMOVE_REQUEST });
  dispatch(deleteFromAudioTrack(audioId));
  dispatch(commonActions.cancelTranscriptionPolling());
  dispatch(commonActions.cancelWaveformPolling());

  return dispatch(createEmbedConfigurationFromState())
    .then(() =>
      dispatch({
        type: types.EMBED_AUDIO_REMOVE_SUCCESS,
      }),
    )
    .catch(error =>
      dispatch({
        ...error,
        type: types.EMBED_AUDIO_REMOVE_FAILURE,
      }),
    );
};

export const saveAudioProperties = audio => dispatch => {
  if (audio) {
    dispatch(updateAudio(audio));
    return dispatch(saveConfiguration());
  }
  return undefined;
};

export default {
  updateAudioCollection,
  addToAudioCollection,
  addToAudioTrack,
  removeFromAudioTrack,
  saveAudioProperties,
  updateAudio,
};

export const addAudioToTrack = () => (dispatch, getState) => {
  const trackIds = embedSelectors.tracksSelector(getState());
  const audioTracks = embedSelectors.audioTracksSelector(getState());
  const trackId = trackIds.find(id => audioTracks.has(id));

  dispatch(addingToTrack(trackId));
};

export const replaceAudioTrack = () => (dispatch, getState) => {
  const trackId = embedSelectors
    .replaceTrackElementSelector(getState())
    .get('trackId');

  dispatch(addingToTrack(trackId));
};

export const {
  clearEntireAudio,
  uploadEntireAudio,
} = entireAudioEnhancer.actions;
