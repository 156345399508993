import * as React from 'react';

import IconButton from 'components/IconButton';
import { Timeline } from 'components/icons';
import Tooltip from 'components/Tooltip';
import CaptionsFormControl from './CaptionsFormControl';
import { block } from './utils';

interface IProps {
  onClick?: () => void;
}

const CopyAllCaptionsButton: React.SFC<IProps> = ({ onClick }) => (
  <Tooltip
    animation={false}
    content="Copy all captions to new Text track"
    id="copy-all-captions-tooltip"
    placement="top"
    preventHideOnHover={false}
  >
    <CaptionsFormControl>
      <IconButton className={block('copy-all-button')} onButtonClick={onClick}>
        <Timeline />
      </IconButton>
    </CaptionsFormControl>
  </Tooltip>
);

export default CopyAllCaptionsButton;
export { IProps as CopyAllCaptionsButtonProps };
