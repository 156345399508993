import React from 'react';
import { SwitchTransition } from 'react-transition-group';
import BemCSSTransition from 'components/BemCssTransition';
import Button from 'components/Button';
import FontAwesome from 'components/FontAwesome';
import FontLoader from 'components/hoc/FontLoader';
import LinkButton from 'components/LinkButton';
import { GoogleFont } from 'redux/middleware/api/headliner-user-service';
import bem from 'utils/bem';

const block = bem('display-google-fonts');

export interface DisplayGoogleFontsProps {
  googleFonts: GoogleFont[];
  addGoogleFont: (googleFamily: string) => void;
  clearGoogleSearchTerm: () => void;
}

function DisplayGoogleFonts({
  googleFonts,
  addGoogleFont,
  clearGoogleSearchTerm,
}) {
  return (
    <SwitchTransition>
      <BemCSSTransition
        key={googleFonts.length ? 'show-list' : 'show-empty'}
        timeout={300}
        className={block('font-transitions')}
      >
        {googleFonts.length ? (
          <div className={block('font-suggestions')}>
            {googleFonts.map(({ family, previewCssUrl }: GoogleFont) => (
              <React.Fragment key={family}>
                <FontLoader family={family} url={previewCssUrl} weight={400} />
                <p
                  style={{ fontFamily: family }}
                  onClick={() => addGoogleFont(family)}
                  className={block('suggestions-text')}
                >
                  {family}
                </p>
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div className={block('font-empty')}>
            <p className={block('empty-p')}>
              Sorry, we couldn't find any matching fonts...
            </p>
            <div>
              <Button
                target="_blank"
                rel="noopener noreferrer"
                href="https://fonts.google.com/"
                className={block('empty-button')}
                theme="submit"
              >
                BROWSE GOOGLE FONTS
              </Button>
              <Button theme="submit-alt" onClick={clearGoogleSearchTerm}>
                UPLOAD YOUR OWN FILES
              </Button>
            </div>
            <span className={block('empty-span')}>
              <FontAwesome icon="headset" />
              Need some help? Email{' '}
              <LinkButton href="mailto: support@headliner.app" theme="cta">
                support@headliner.app
              </LinkButton>
            </span>
          </div>
        )}
      </BemCSSTransition>
    </SwitchTransition>
  );
}

export default DisplayGoogleFonts;
