import { TextOverlay } from 'blocks/TextOverlayModal';
import {
  isLegacyOverlayConvertAble,
  TextOverlayV2,
  updateOverlayToV2,
  validateOverlayV2Integrity,
} from 'blocks/TextOverlayModal/v2';
import bem from 'utils/bem';
import { textContentFromHtmlString } from 'utils/dom';

export const block = bem('text-overlay-switch');

export const transformOverlayToLegacy = (
  overlay: TextOverlayV2,
): TextOverlay | undefined => {
  // in order to avoid carrying unconsitencies when switching, version is removed
  // as well as the editor text styles.
  return overlay.withMutations(s =>
    s
      .deleteIn(['editor', 'textStyle'])
      .deleteIn(['editor', 'scaler'])
      .delete('version')
      .delete('textBuilderStyles'),
  );
};

export const transformOverlayToV2 = (
  overlay: TextOverlay,
): TextOverlayV2 | undefined => {
  const isV2 = validateOverlayV2Integrity(overlay);

  if (isV2) {
    return overlay;
  }

  const canBeConverted = isLegacyOverlayConvertAble(overlay);
  const textValue = textContentFromHtmlString(overlay.get('textHtml'));
  const overlayWithUpdatedText = overlay.set('text', textValue);

  if (canBeConverted) {
    return updateOverlayToV2(overlayWithUpdatedText);
  }

  try {
    return updateOverlayToV2(overlayWithUpdatedText);
  } catch {
    return undefined;
  }
};
