import * as React from 'react';

import { IconProps } from './types';

const Trash: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="14"
    viewBox="0 0 11 14"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M.355 2.62v-.68c0-.324.253-.584.567-.584h2.649l.222-.454A.56.56 0 0 1 4.3.58h2.703c.216 0 .412.124.509.323l.222.454h2.649c.314 0 .567.26.567.584v.68c0 .16-.128.291-.284.291H.64a.289.289 0 0 1-.284-.291zm9.837 1.36v7.873c0 .644-.508 1.166-1.135 1.166h-6.81c-.627 0-1.135-.522-1.135-1.166V3.98c0-.16.127-.292.283-.292H9.91c.156 0 .283.131.283.292zM3.76 5.634a.385.385 0 0 0-.378-.389.385.385 0 0 0-.379.389v5.442c0 .214.17.39.379.39a.385.385 0 0 0 .378-.39V5.633zm2.27 0a.385.385 0 0 0-.378-.389.385.385 0 0 0-.378.389v5.442c0 .214.17.39.378.39a.385.385 0 0 0 .378-.39V5.633zm2.27 0a.385.385 0 0 0-.378-.389.385.385 0 0 0-.378.389v5.442c0 .214.17.39.378.39a.385.385 0 0 0 .379-.39V5.633z"
    />
  </svg>
);

export default Trash;
