import qs from 'query-string';
import request from 'superagent';

function downloadBlob(blob: Blob, filename: string) {
  const href = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename);

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}

/**
 * Downloads a file given its URL
 *
 * Something similar to this can be done in the DOM by adding an anchor with the
 * `download` property, however in that case the file to be downloaded has to be
 * hosted on the same domain as the application.  This helper allows downloading
 * cross-origin files.
 */
export async function downloadLink(url: string, filename: string) {
  const response = await request.get(url).responseType('blob');

  return downloadBlob(response.body, filename);
}

/**
 *
 * cloudfront/s3 offers a query parameter value you can send which will dynamically add the
 * content-disposition header allowing for downloading cross-origin files. In order to account for alphabets
 * with characters that don't meet the requirements for  ISO-8859-1, use the filename* field and encode the filename.
 */
export async function downloadLinkFromS3(url: string, filename: string) {
  const link = document.createElement('a');

  const search = {
    'response-content-disposition': `attachment; filename*=UTF-8''${encodeURIComponent(
      filename,
    )}`,
  };

  const formattedLink = qs.stringifyUrl(
    { url, query: search },
    { encode: false },
  );
  const encodedLink = encodeURI(formattedLink);
  link.href = encodedLink;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}
