export enum Type {
  PRICING_DATA_LOAD_REQUEST = 'app/pricing/PRICING_DATA_LOAD_REQUEST',
  PRICING_DATA_LOAD_SUCCESS = 'app/pricing/PRICING_DATA_LOAD_SUCCESS',
  PRICING_DATA_LOAD_FAILURE = 'app/pricing/PRICING_DATA_LOAD_FAILURE',

  PRICING_DATA_SUBSCRIPTION_LOAD_SUCCESS = 'app/pricing/PRICING_DATA_SUBSCRIPTION_LOAD_SUCCESS',

  PRICING_DATA_COUPONS_LOAD_SUCCESS = 'app/pricing/PRICING_DATA_COUPONS_LOAD_SUCCESS',

  PRICING_CREATE_PAYMENT_METHOD_REQUEST = 'app/pricing/PRICING_CREATE_PAYMENT_METHOD_REQUEST',
  PRICING_CREATE_PAYMENT_METHOD_SUCCESS = 'app/pricing/PRICING_CREATE_PAYMENT_METHOD_SUCCESS',
  PRICING_CREATE_PAYMENT_METHOD_FAILURE = 'app/pricing/PRICING_CREATE_PAYMENT_METHOD_FAILURE',

  PRICING_UPDATE_SUBSCRIPTION_REQUEST = 'app/pricing/PRICING_UPDATE_SUBSCRIPTION_REQUEST',
  PRICING_UPDATE_SUBSCRIPTION_SUCCESS = 'app/pricing/PRICING_UPDATE_SUBSCRIPTION_SUCCESS',
  PRICING_UPDATE_SUBSCRIPTION_FAILURE = 'app/pricing/PRICING_UPDATE_SUBSCRIPTION_FAILURE',

  PRICING_CUSTOMER_CARD_GET_REQUEST = 'app/pricing/PRICING_CUSTOMER_CARD_GET_REQUEST',
  PRICING_CUSTOMER_CARD_GET_SUCCESS = 'app/pricing/PRICING_CUSTOMER_CARD_GET_SUCCESS',
  PRICING_CUSTOMER_CARD_GET_FAILURE = 'app/pricing/PRICING_CUSTOMER_CARD_GET_FAILURE',

  PRICING_REFERRAL_DATA_LOAD_SUCCESS = 'app/pricing/PRICING_REFERRAL_DATA_LOAD_SUCCESS',

  PRICING_REFERRAL_INVITATIONS_SEND_REQUEST = 'app/pricing/PRICING_REFERRAL_INVITATIONS_SEND_REQUEST',
  PRICING_REFERRAL_INVITATIONS_SEND_SUCCESS = 'app/pricing/PRICING_REFERRAL_INVITATIONS_SEND_SUCCESS',
  PRICING_REFERRAL_INVITATIONS_SEND_FAILURE = 'app/pricing/PRICING_REFERRAL_INVITATIONS_SEND_FAILURE',

  MARK_FREE_TRIAL_REMINDER_SEEN = 'app/pricing/MARK_FREE_TRIAL_REMINDER_SEEN',

  LOAD_EXTERNAL_PORTAL_LINK_REQUEST = 'app/pricing/LOAD_EXTERNAL_PORTAL_LINK_REQUEST',
  LOAD_EXTERNAL_PORTAL_LINK_SUCCESS = 'app/pricing/LOAD_EXTERNAL_PORTAL_LINK_SUCCESS',
  LOAD_EXTERNAL_PORTAL_LINK_FAILURE = 'app/pricing/LOAD_EXTERNAL_PORTAL_LINK_FAILURE',
}
