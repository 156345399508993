import React from 'react';

import { Size } from 'types';

interface UseRndAssetAutoAdjustConfig {
  minSizePx?: Size<number>;
  onResize: (size: Size<number>) => void;
  size: Size<number>;
  shouldAdjust?: boolean;
}

/**
 * Checks and triggers an auto resize if the rnd asset dimensions are
 * below the minimum allowed size.
 */
const useRndAssetAutoAdjust = (config: UseRndAssetAutoAdjustConfig) => {
  const { minSizePx, onResize, size, shouldAdjust = false } = config;

  React.useEffect(() => {
    // Only activates the feature if both minSizePx is provided and the auto
    // adjust is enabled.
    if (!minSizePx || !shouldAdjust) {
      return;
    }

    const correctedHeight =
      minSizePx.height > size.height ? minSizePx.height : size.height;
    const correctedWidth =
      minSizePx.width > size.width ? minSizePx.width : size.width;
    const shouldResize =
      correctedHeight !== size.height || correctedWidth !== size.width;
    if (shouldResize) {
      onResize({ height: correctedHeight, width: correctedWidth });
    }
  }, [minSizePx, onResize, shouldAdjust, size.height, size.width]);
};

export default useRndAssetAutoAdjust;
