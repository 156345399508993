import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import ModalFooterButton from 'components/Modal/ModalFooterButton';
import { Step } from 'components/SteppedModal';
import usePrevious from 'hooks/usePrevious';
import { tiktokUserDataSelector } from 'redux/modules/social';
import { PostStatus } from 'redux/modules/social/constants';
import TiktokPostComposer, {
  TiktokPostComposerProps,
} from '../TiktokPostComposer';
import useTiktokPostModalSelector from '../useTiktokPostModalSelector';

export interface UseComposerStepValue {
  tiktokUserId: string;
}

export interface UseComposerStepConfig
  extends Pick<TiktokPostComposerProps, 'readonly'>,
    Pick<Step, 'submitButtonLabel'> {
  onPostSuccess?: () => void;
  onSubmit?: (value: UseComposerStepValue) => void;
  onSwitchAccountClick?: () => void;
}

export default function useComposerStep(
  config: UseComposerStepConfig = {},
): Step {
  const {
    onSwitchAccountClick,
    onPostSuccess,
    onSubmit,
    readonly,
    submitButtonLabel = 'post your video',
  } = config;
  const { avatarSrc, postStatus, username } = useTiktokPostModalSelector();

  const { providerUserId: tiktokUserId } = useSelector(tiktokUserDataSelector);

  const prevPostStatus = usePrevious(postStatus);

  const isUploading = postStatus === PostStatus.IN_PROGRESS;

  const handleSubmit = useCallback(() => {
    onSubmit?.({
      tiktokUserId,
    });
  }, [onSubmit, tiktokUserId]);

  useEffect(() => {
    if (
      prevPostStatus === PostStatus.IN_PROGRESS &&
      postStatus === PostStatus.SUCCESS
    ) {
      onPostSuccess?.();
    }
  }, [onPostSuccess, postStatus, prevPostStatus]);

  return {
    component: (
      <TiktokPostComposer
        {...{
          alert,
          avatarSrc,
          isUploading,
          readonly,
          username,
          onSwitchAccountClick,
        }}
      />
    ),
    id: 'composer',
    onSubmit: handleSubmit,
    fluidFooterButtons: true,
    renderFooterButtons: ({ submit }) => [
      <ModalFooterButton
        {...submit}
        key="submit"
        theme="submit"
        disabled={isUploading}
      >
        {submitButtonLabel}
      </ModalFooterButton>,
    ],
  };
}
