import * as React from 'react';
import _ from 'underscore';

import FontAwesome from 'components/FontAwesome';
import NavIcon from '../../NavIcon';
import { blockNavigation } from '../utils';

interface IProps {
  bsRole?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export default class UserIcon extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onClick: _.noop,
  };

  private handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const { onClick } = this.props;
    e.preventDefault();
    onClick(e);
  };

  public render() {
    return (
      <NavIcon
        className={blockNavigation('icon-toggle')}
        onClick={this.handleClick}
      >
        <FontAwesome
          icon="user-circle"
          size="2x"
          className={blockNavigation('icon-user')}
        />
      </NavIcon>
    );
  }
}
