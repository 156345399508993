import * as React from 'react';
import { useDispatch } from 'react-redux';
import PodcastSearch from 'blocks/PodcastSearch';
import useDefaultIntegration from 'blocks/PodcastSearch/useDefaultIntegration';
import useRssIntegration from 'blocks/PodcastSearch/useRssIntegration';
import LoadingOverlay from 'components/LoadingOverlay';
import Modal from 'components/Modal';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import useAsyncCallback from 'hooks/useAsyncCallback';
import { Podcast } from 'redux/middleware/api/podcast-service';
import { podcastsSelector } from 'redux/modules/entities/selectors';
import { showError } from 'redux/modules/notification/actions';
import { markPodcastDefault } from 'redux/modules/podcast-search/actions';
import { Dispatch, ThunkAction } from 'redux/types';
import { block } from './utils';

const { useRef, useEffect } = React;

const openRoleCollectModal = (
  podcastId: string,
): ThunkAction<Promise<Podcast>> => async (dispatch, getState) => {
  try {
    const podcast = podcastsSelector(getState())?.get(podcastId);

    await dispatch(markPodcastDefault(podcastId));

    return podcast.toJS();
  } catch (error) {
    return dispatch(
      showError({
        message: 'There was an error saving your preferences',
        dismissAfterSec: 5,
      }),
    );
  }
};

function PodcastConnectModalContent() {
  const { onHide } = useConnectedModal('PodcastConnect');
  const dispatch = useDispatch<Dispatch>();
  const search = useRef(null);

  useEffect(() => {
    search.current?.focus();
  }, []);

  const [onPodcastClick, { status }] = useAsyncCallback<
    (podcastId: string) => Promise<void>
  >(async podcastId => {
    const podcast = await dispatch(openRoleCollectModal(podcastId));

    onHide(podcast);
  });

  return (
    <Modal.Body className={block('body')}>
      <PodcastSearch
        integrations={[
          useDefaultIntegration({
            episodeExplorerEnabled: false,
            feedCollectionEnabled: false,
            onPodcastClick,
            priority: 1,
          }),
          useRssIntegration({
            episodeExplorerEnabled: false,
            feedCollectionEnabled: false,
            onPodcastClick,
            priority: 0,
          }),
        ]}
        ref={search}
      />
      {status === 'pending' && <LoadingOverlay title="Saving..." />}
    </Modal.Body>
  );
}

export default PodcastConnectModalContent;
