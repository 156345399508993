import * as React from 'react';

import { IconProps } from './types';

const ProBadge: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="15"
    viewBox="0 0 28 15"
    aria-label="Pro"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <rect
        width="28"
        height="15"
        fill="url(#pro-badge-gradient)"
        filter="url(#pro-badge-filter)"
        rx="2"
      />
      <path
        fill="#000"
        fillRule="nonzero"
        d="M5.424 8.506v2.4H4V4.094h2.696c.519 0 .975.093 1.369.28.394.187.697.453.909.798a2.2 2.2 0 0 1 .318 1.177c0 .667-.232 1.194-.696 1.58-.463.384-1.105.577-1.924.577H5.424zm0-1.137h1.272c.376 0 .663-.087.861-.262.198-.175.297-.424.297-.749 0-.333-.1-.603-.3-.81-.199-.205-.474-.311-.825-.317H5.424v2.138zm8.192 1.043H12.48v2.494h-1.423V4.094h2.567c.816 0 1.446.179 1.889.538.443.358.664.865.664 1.52 0 .465-.102.853-.306 1.163-.204.31-.513.558-.928.742l1.495 2.784v.065h-1.528l-1.295-2.494zM12.48 7.275h1.149c.357 0 .634-.09.83-.269.197-.179.295-.426.295-.741 0-.322-.093-.574-.278-.758-.185-.184-.47-.276-.852-.276h-1.144v2.044zM24 7.655c0 .67-.12 1.258-.36 1.763a2.69 2.69 0 0 1-1.033 1.17c-.448.275-.96.412-1.54.412a2.906 2.906 0 0 1-1.533-.407A2.717 2.717 0 0 1 18.49 9.43c-.247-.504-.372-1.083-.375-1.738v-.337c0-.67.123-1.261.368-1.771s.592-.902 1.04-1.175A2.89 2.89 0 0 1 21.057 4a2.89 2.89 0 0 1 1.535.41c.448.272.794.664 1.04 1.174.244.51.367 1.099.367 1.766v.304zm-1.443-.31c0-.714-.13-1.257-.389-1.628-.26-.371-.63-.557-1.11-.557-.478 0-.847.184-1.106.55-.26.367-.39.904-.394 1.612v.332c0 .696.13 1.236.39 1.62.258.383.632.575 1.12.575.477 0 .844-.185 1.1-.555.256-.37.386-.908.39-1.616v-.332z"
      />
    </g>
  </svg>
);

export default ProBadge;
