import React from 'react';
import { InstagramGradientCircleButton } from 'components/icons';
import SocialCircle from './SocialCircle';

export interface InstagramSocialCircleProps {
  className?: string;
}

const InstagramSocialCircle: React.FC<InstagramSocialCircleProps> = ({
  className,
}) => (
  <SocialCircle className={className}>
    <InstagramGradientCircleButton height="100%" width="100%" />
  </SocialCircle>
);

export default InstagramSocialCircle;
