import { Record, RecordOf } from 'immutable';
import * as React from 'react';

import BrowserSupportModal from 'components/BrowserSupportModal';
import MobileSupportModal from 'components/MobileSupportModal';
import { isBrowserSupported, isMobile } from 'utils/device';

type Callback = (event: React.SyntheticEvent<any>) => void;

export interface BrowserCheckProps {
  preventMobile?: boolean;

  /**
   * This prop takes something of the type (guardfunc) => React.ReactNode. The node is
   * rendered using the guard function found in this component
   *
   * Here is an example use of this render prop:
   *  <BrowserCheck
   *    render={(guardFn) => {
   *      return (
   *        <Button
   *          onClick={guardFn(functionToBeGuardedByBrowserCheck)}
   *        />
   *      );
   *    }}
   *  />
   */
  render?: (guardFn: (cb: Callback) => void) => JSX.Element;

  dismissible?: boolean;
}

interface IUiState {
  showBrowserSupportModal: boolean;
  showMobileSupportModal: boolean;
}

interface IState {
  ui: RecordOf<IUiState>;
}

const uiFactory = Record<IUiState>({
  showBrowserSupportModal: false,
  showMobileSupportModal: false,
});

export default class BrowserCheck extends React.Component<
  BrowserCheckProps,
  IState
> {
  public static defaultProps: Partial<BrowserCheckProps> = {
    dismissible: true,
    preventMobile: false,
    render: () => null,
  };

  public state: Readonly<IState> = {
    ui: uiFactory(),
  };

  public componentDidMount() {
    this.checkBrowserSupport(this.props);
  }

  private handleModalHide = () => {
    this.setState(({ ui: oldUi }) => ({
      ui: oldUi
        .set('showBrowserSupportModal', false)
        .set('showMobileSupportModal', false),
    }));
  };

  private checkBrowserSupport = (props: BrowserCheckProps) => {
    const { preventMobile } = props;

    if (isMobile && preventMobile) {
      this.setState(({ ui: oldUi }) => ({
        ui: oldUi.set('showMobileSupportModal', true),
      }));
      return false;
    }

    if (!isBrowserSupported()) {
      this.setState(({ ui: oldUi }) => ({
        ui: oldUi.set('showBrowserSupportModal', true),
      }));
      return false;
    }

    return true;
  };

  private guardFromUnsupportedBrowsers = (
    fn: (event: React.SyntheticEvent<any>) => void,
  ) => event => {
    event.preventDefault();
    if (this.checkBrowserSupport(this.props)) {
      fn(event);
    }
  };

  public render() {
    const { ui } = this.state;
    const { preventMobile, render, dismissible } = this.props;

    const guardedObject = render(this.guardFromUnsupportedBrowsers);

    return (
      <div>
        {guardedObject}
        <BrowserSupportModal
          show={ui.get('showBrowserSupportModal')}
          dismissible={dismissible}
          onHide={this.handleModalHide}
        />

        <MobileSupportModal
          show={preventMobile && ui.get('showMobileSupportModal')}
          dismissible={dismissible}
          onHide={this.handleModalHide}
        />
      </div>
    );
  }
}
