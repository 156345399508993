import React from 'react';
import { IconProps } from './types';

export interface HourglassProps extends IconProps {
  gradient?: boolean;
}

const HourglassSand: React.FC<HourglassProps> = ({ gradient, ...props }) => {
  return (
    <svg
      width="53"
      height="70"
      viewBox="0 0 53 70"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <linearGradient
          x1="0%"
          y1="28.195%"
          x2="100%"
          y2="71.805%"
          id="rrxognmkrc"
        >
          <stop stopColor="#5DBEFC" offset="0%" />
          <stop stopColor="#DD92FE" offset="100%" />
        </linearGradient>
        <filter
          x="0%"
          y="0%"
          width="100%"
          height="100%"
          filterUnits="objectBoundingBox"
          id="259qa9a6rd"
        >
          <feGaussianBlur in="SourceGraphic" />
        </filter>
        <path
          d="M49.688 0C51.523 0 53 1.47 53 3.281c0 1.812-1.477 3.281-3.313 3.281h-1.104v2.595c0 5.513-2.332 10.68-6.142 14.7L31.18 35 42.44 46.143c3.81 3.896 6.142 9.187 6.142 14.697v2.597h1.105C51.523 63.438 53 64.9 53 66.72 53 68.537 51.523 70 49.687 70H3.313C1.482 70 0 68.537 0 66.719c0-1.819 1.484-3.281 3.313-3.281h1.104V60.84c0-5.51 2.21-10.8 6.144-14.697L21.821 35l-11.26-11.143c-3.935-4.02-6.144-9.187-6.144-14.7V6.562H3.313C1.482 6.563 0 5.093 0 3.283 0 1.47 1.484 0 3.313 0h46.374zM26.5 30.365 37.749 19.21a14.152 14.152 0 0 0 4.21-10.052V6.44H11.041v2.718a14.13 14.13 0 0 0 4.21 10.052L26.5 30.365z"
          id="il3p2934da"
        />
      </defs>
      <g transform="matrix(1 0 0 -1 0 70)" fill="none" fillRule="evenodd">
        <mask id="ebizz9b2xb" fill="#fff">
          <use xlinkHref="#il3p2934da" />
        </mask>
        <use fill="#19B5FE" fillRule="nonzero" xlinkHref="#il3p2934da" />
        <g mask="url(#ebizz9b2xb)">
          <path fill="#000" d="M-26.5 0h106v70h-106z" />
          <path
            fill={gradient ? 'url(#rrxognmkrc)' : 'currentColor'}
            filter="url(#259qa9a6rd)"
            d="M0 0h106v70H0z"
            transform="translate(-26.5)"
          />
        </g>
      </g>
    </svg>
  );
};

export default HourglassSand;
