import React from 'react';

import DownloadCaptionsDialogModal from './DownloadCaptionsDialogModal';
import EmptyEpisodeTranscriptModalContents from './EmptyEpisodeTranscriptModalContents';

export interface EmptyEpisodeTranscriptModalProps {}

const EmptyEpisodeTranscriptModal: React.FC<EmptyEpisodeTranscriptModalProps> = () => (
  <DownloadCaptionsDialogModal
    name="EmptyEpisodeTranscript"
    title="Download Transcript"
  >
    <EmptyEpisodeTranscriptModalContents />
  </DownloadCaptionsDialogModal>
);

export default EmptyEpisodeTranscriptModal;
