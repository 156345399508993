import * as React from 'react';
import AspectRatioSelector, {
  AspectRatio,
} from 'components/AspectRatioSelector';
import { InputAdornment } from 'components/Form';
import MaxLengthTextareaField from 'components/Form/MaxLengthTextareaField';
import { VideoCamera } from 'components/icons';
import Modal from 'components/Modal';
import { ConnectedModalInjectedProps } from 'containers/ConnectedModal/ConnectedModalComponent';
import useProject from 'hooks/useProject';
import { getAspectRatioDimensions } from 'utils/aspect-ratio';
import { block } from './utils';

const { useState } = React;

export interface ProjectCopyModalProps extends ConnectedModalInjectedProps {
  disabled?: boolean;
  projectId?: string;
  showCopyMessage?: boolean;
}

const ProjectCopyModalContent: React.FC<ProjectCopyModalProps> = ({
  disabled,
  onHide,
  projectId,
  showCopyMessage,
}) => {
  const project = useProject(projectId);
  const [newProjectName, setNewProjectName] = useState(
    `Copy of ${project?.name ?? 'project'}`,
  );
  const [aspectRatio, setAspectRatio] = useState<AspectRatio>(() =>
    getAspectRatioDimensions(
      project?.aspectRatio?.width / project?.aspectRatio?.height,
    ),
  );

  const handleSubmit = async () => {
    onHide({
      aspectRatio,
      projectId,
      projectName: newProjectName,
    });
  };

  return (
    <>
      <Modal.Body className={block('body')}>
        {showCopyMessage && (
          <div className={block('desc')}>
            You can change the aspect ratio of your project by copying it.
            We&#39;ll take the contents of this project and use your assets to
            create a new project in the aspect ratio you select.
          </div>
        )}
        <MaxLengthTextareaField
          maxChars={255}
          onChange={setNewProjectName}
          placeholder="Title your project (optional)"
          density="comfortable"
          light
          startAdornment={
            <InputAdornment>
              <VideoCamera width={16} />
            </InputAdornment>
          }
          value={newProjectName}
          type="input"
        />
        <AspectRatioSelector value={aspectRatio} onSelect={setAspectRatio} />
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton
            onClick={handleSubmit}
            disabled={disabled}
            theme="submit"
          >
            Copy
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default ProjectCopyModalContent;
