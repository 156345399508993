import * as React from 'react';

import { IconProps } from 'components/icons/types';

const Edit: React.FC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="14"
    viewBox="0 0 16 14"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.183 2.273L13.69 4.74c.105.104.105.273 0 .377L7.622 11.09l-2.578.282c-.344.038-.636-.25-.597-.588l.286-2.538L10.8 2.273c.106-.104.278-.104.383 0zm4.5-.627L14.328.312c-.422-.416-1.109-.416-1.534 0l-.983.968c-.105.104-.105.273 0 .377l2.506 2.467c.105.104.277.104.383 0l.983-.968c.423-.418.423-1.094 0-1.51zm-5.016 7.82v2.784h-8.89V3.498h6.384c.089 0 .172-.036.236-.096l1.111-1.094c.211-.208.061-.56-.236-.56H1.333C.597 1.748 0 2.336 0 3.06v9.627C0 13.412.597 14 1.333 14h9.778c.736 0 1.333-.588 1.333-1.313V8.372c0-.293-.358-.438-.569-.233l-1.111 1.094c-.061.063-.097.145-.097.233z"
    />
  </svg>
);

export default Edit;
