import * as React from 'react';

export interface IProps {
  className?: string;
  fileName?: string;
  sourceFile: string;
}

const SaveToDriveButton: React.FC<IProps> = props => {
  const { className, fileName = 'file', sourceFile } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    /**
     * https://sparemin.atlassian.net/browse/SPAR-8367
     *
     * the Ghostery browser plugin returns a partial version of the gapi library
     * when the user opts to block the Google+ Platform.
     *
     * The gapi object still exists, still has some properties, but doesn't
     * have savetodrive for whatever reason.  Because of ghostery, we need to
     * check that the actual function we want to call exists before calling it
     */
    if (ref.current && window.gapi?.savetodrive?.render) {
      window.gapi.savetodrive.render(ref.current, {
        src: sourceFile,
        filename: fileName,
        sitename: 'Headliner',
      });
    }
  }, [fileName, sourceFile]);

  return <div {...{ className, ref }} />;
};

export default SaveToDriveButton;
