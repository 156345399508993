import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  constants as embedExportConstants,
  selectors as embedExportSelectors,
} from 'redux/modules/embed-export';
import {
  recordingUploadSelector,
  recordingUploadStatusMessageSelector,
  UPLOAD_STATE_UPLOADED,
  UploadType,
} from 'redux/modules/recording-upload';
import { State } from 'redux/types';
import { VideoExportNotificationProps } from './VideoExportNotification';

const {
  audioExportStatusSelector,
  videoExportProgressSelector,
  videoExportUrlSelector,
} = embedExportSelectors;

const { AudioExportStatus } = embedExportConstants;

type StateProps = Pick<
  VideoExportNotificationProps,
  'exportStatusMessage' | 'videoExportStatus' | 'videoUrl'
>;

const audioUploadStatusMessageSelector = createSelector(
  [recordingUploadSelector, recordingUploadStatusMessageSelector],
  (upload, statusMessage) =>
    !upload || upload.type !== (UploadType as any).RENDERED
      ? undefined
      : statusMessage,
);

const statusMessageSelector = createSelector(
  [audioExportStatusSelector, audioUploadStatusMessageSelector],
  (audioExportStatus, audioUploadMessage) => {
    if (audioExportStatus === (AudioExportStatus as any).RENDERING) {
      return 'Rendering Audio';
    }

    if (
      audioExportStatus === (AudioExportStatus as any).UPLOADING &&
      audioUploadMessage &&
      audioUploadMessage.status !== UPLOAD_STATE_UPLOADED
    ) {
      return audioUploadMessage.message;
    }

    return 'Generating Video';
  },
);

const mapStateToProps = (state: State): StateProps => ({
  exportStatusMessage: statusMessageSelector(state),
  videoExportStatus: videoExportProgressSelector(state),
  videoUrl: videoExportUrlSelector(state),
});

export default function(
  component: React.ComponentType<VideoExportNotificationProps>,
) {
  return connect(mapStateToProps)(component);
}
