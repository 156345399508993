import { fromJS } from 'immutable';

import { RequestStatus } from 'types';

import { Type } from './action-types';
import { stateFactory } from './factories';
import { ClaimPodcastAction, ClaimPodcastState } from './types';

const defaultState = stateFactory();

const reducer = (
  state: ClaimPodcastState = defaultState,
  action: ClaimPodcastAction,
) => {
  switch (action.type) {
    case Type.CLAIM_PODCAST_INIT:
      return state.withMutations(s => {
        s.set('claimRequestStatus', undefined);
        s.set('claimResponse', undefined);
        s.set('podcastId', undefined);
        return s;
      });

    case Type.CLAIM_PODCAST_REQUEST:
      return state.withMutations(s => {
        s.set('claimRequestStatus', RequestStatus.REQUEST);
        s.set('podcastId', action.payload.podcastId);
        return s;
      });

    case Type.CLAIM_PODCAST_FAILURE:
      return state.set('claimRequestStatus', RequestStatus.FAILURE);

    case Type.CLAIM_PODCAST_SUCCESS:
      return state.withMutations(s => {
        s.set('claimRequestStatus', RequestStatus.SUCCESS);
        s.set('claimResponse', fromJS(action.payload.claimResponse));
        return s;
      });

    case Type.GET_OWNED_PODCASTS_REQUEST:
      return state.withMutations(s => {
        s.set('ownedPodcastsRequestStatus', RequestStatus.REQUEST);
        s.set('ownedPodcasts', fromJS([]));
        return s;
      });

    case Type.GET_OWNED_PODCASTS_FAILURE:
      return state.set('ownedPodcastsRequestStatus', RequestStatus.FAILURE);

    case Type.GET_OWNED_PODCASTS_SUCCESS:
      return state.withMutations(s => {
        s.set('ownedPodcastsRequestStatus', RequestStatus.SUCCESS);
        s.set('ownedPodcasts', fromJS(action.payload.result));
        return s;
      });

    default:
      return state;
  }
};

export default reducer;
