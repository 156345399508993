import classNames from 'classnames';
import * as React from 'react';
import _ from 'underscore';

import DurationSlider from 'components/DurationSlider';
import Tabs from 'components/Tabs/Tabs';
import TextTransitionSelect, {
  InTransitions,
  OutTransitions,
  TransitionDirections,
} from 'components/TextTransitionSelect';
import { DEFAULT_TRANSITION_DURATION_IN_SEC } from '../../utils/constants';
import { InTransition, IProps, OutTransition } from './types';
import { block } from './utils';

export default class TextOverlayTransitionsForm extends React.Component<
  IProps
> {
  public static defaultProps: Partial<IProps> = {
    defaultInTransitionName: undefined,
    defaultOutTransitionName: undefined,
    disabled: false,
    onInTransitionChange: _.noop,
    onOutTransitionChange: _.noop,
    onTabSelect: _.noop,
  };

  private handleTransitionInChange = (value: InTransition): void => {
    const { transitionIn, onInTransitionChange } = this.props;
    onInTransitionChange(transitionIn.set('value', value));
  };

  private handleTransitionOutChange = (value: OutTransition): void => {
    const { transitionOut, onOutTransitionChange } = this.props;
    onOutTransitionChange(transitionOut.set('value', value));
  };

  private handleChangeTransitionInDuration = (duration: number): void => {
    const { transitionIn, onInTransitionChange } = this.props;
    onInTransitionChange(transitionIn.set('duration', duration));
  };

  private handleChangeTransitionOutDuration = (duration: number): void => {
    const { transitionOut, onOutTransitionChange } = this.props;
    onOutTransitionChange(transitionOut.set('duration', duration));
  };

  private getMarksForDurationSlider = () => ({
    0.2: {
      label: 'Short',
      style: {
        left: -4,
        marginLeft: 0,
        textAlign: 'left',
        width: 'auto',
      },
    },
    0.5: { label: ' ', style: { display: 'none' } },
    0.8: 'Normal',
    1.1: { label: ' ', style: { display: 'none' } },
    1.4: {
      label: 'Long',
      style: {
        left: 'auto',
        marginLeft: 0,
        right: -4,
        textAlign: 'right',
        width: 'auto',
      },
    },
  });

  public render() {
    const {
      className,
      defaultInTransitionName,
      defaultOutTransitionName,
      disabled,
      onTabSelect,
      transitionIn,
      transitionOut,
    } = this.props;

    const containerClassName = classNames(block(), className);

    return (
      <div className={containerClassName}>
        <Tabs
          onTabSelect={onTabSelect}
          defaultActiveTabKey="in"
          condensed
          id="text-transition-select-tabs"
          tabs={[
            {
              content: (
                <div className={block('transition-in')}>
                  <DurationSlider
                    defaultValue={DEFAULT_TRANSITION_DURATION_IN_SEC}
                    disabled={disabled || transitionIn.get('value') === 'cut'}
                    minValue={0.2}
                    maxValue={1.4}
                    marks={this.getMarksForDurationSlider()}
                    onChange={this.handleChangeTransitionInDuration}
                    step={0.01}
                    tooltipId="tooltip-transition-in"
                    value={transitionIn.get('duration')}
                  />

                  <TextTransitionSelect
                    onChange={this.handleTransitionInChange}
                    value={transitionIn.get('value')}
                    options={InTransitions}
                    disabled={disabled}
                    direction={TransitionDirections.IN}
                    defaultValue={defaultInTransitionName}
                  />
                </div>
              ),
              tabKey: 'in',
              title: 'Transition in',
            },
            {
              content: (
                <div className={block('transition-out')}>
                  <DurationSlider
                    defaultValue={DEFAULT_TRANSITION_DURATION_IN_SEC}
                    disabled={disabled || transitionOut.get('value') === 'cut'}
                    minValue={0.2}
                    maxValue={1.4}
                    marks={this.getMarksForDurationSlider()}
                    onChange={this.handleChangeTransitionOutDuration}
                    step={0.01}
                    tooltipId="tooltip-transition-out"
                    value={transitionOut.get('duration')}
                  />

                  <TextTransitionSelect
                    onChange={this.handleTransitionOutChange}
                    value={transitionOut.get('value')}
                    options={OutTransitions}
                    disabled={disabled}
                    direction={TransitionDirections.OUT}
                    defaultValue={defaultOutTransitionName}
                  />
                </div>
              ),
              tabKey: 'out',
              title: 'Transition out',
            },
          ]}
        />
      </div>
    );
  }
}
