import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import _ from 'underscore';

import { selectors as embedSelectors } from 'redux/modules/embed';
import {
  addWatermark,
  editWatermark,
  removeWatermark,
} from 'redux/modules/embed/actions';
import {
  watermarkConfigurationSelector,
  watermarkStatusSelector,
} from 'redux/modules/embed/selectors';
import { onFileUpload } from 'redux/modules/mixpanel/actions';
import { hideModal } from 'redux/modules/modal';
import * as notificationActions from 'redux/modules/notification';
import { Dispatch, State } from 'redux/types';
import { ApplicationError } from 'utils/ApplicationError';
import { DropZoneType } from 'utils/constants';

import { IProps as AddLogoModalProps } from './AddLogoModal';
import { IProps as EditLogoModalProps } from './EditLogoModal';
import { IWatermarkPosition, IWatermarkSize } from './types';

const isAddingSelector = createSelector(
  watermarkStatusSelector,
  status => status === 'adding',
);

const isDeletingSelector = createSelector(
  watermarkStatusSelector,
  status => status === 'deleting',
);

export function connectAddLogoModal(
  component: React.ComponentType<AddLogoModalProps>,
) {
  type StateProps = Pick<
    AddLogoModalProps,
    'aspectRatio' | 'isAdding' | 'watermark'
  >;
  type DispatchProps = Pick<
    AddLogoModalProps,
    'onSubmit' | 'onFileRejected' | 'onFileUpload' | 'onCancel'
  >;
  type OwnProps = Pick<AddLogoModalProps, 'onSubmit'>;

  const mapStateToProps = (state: State): StateProps => ({
    aspectRatio: embedSelectors.aspectRatioSelector(state),
    isAdding: isAddingSelector(state),
    watermark: watermarkConfigurationSelector(state),
  });

  const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: OwnProps,
  ): DispatchProps => ({
    onFileRejected: (error: ApplicationError, file: File) => {
      dispatch(
        onFileUpload(DropZoneType.EDITOR_WATERMARK, file, error.message),
      );
      dispatch(
        notificationActions.showError({
          message: error.message,
          code: error.code,
          dismissAfterSec: 5,
        }),
      );
    },
    onFileUpload: (file: File) =>
      dispatch(onFileUpload(DropZoneType.EDITOR_WATERMARK, file)),
    onSubmit: (
      file: File,
      watermarkPosition: IWatermarkPosition,
      watermarkSize: IWatermarkSize,
    ) => {
      const { onSubmit: propsOnSubmit = _.noop } = ownProps;
      dispatch(addWatermark(file, watermarkPosition, watermarkSize));
      propsOnSubmit(file, watermarkPosition, watermarkSize);
      dispatch(hideModal());
    },
    onCancel: () => dispatch(hideModal()),
  });

  return connect(mapStateToProps, mapDispatchToProps)(component);
}

export function connectEditLogoModal(
  component: React.ComponentType<EditLogoModalProps>,
) {
  type StateProps = Pick<
    EditLogoModalProps,
    'aspectRatio' | 'isDeleting' | 'watermark'
  >;
  type DispatchProps = Pick<
    EditLogoModalProps,
    'onDelete' | 'onSubmit' | 'onCancel'
  >;
  type OwnProps = Pick<EditLogoModalProps, 'onDelete' | 'onSubmit'>;

  const mapStateToProps = (state: State): StateProps => ({
    aspectRatio: embedSelectors.aspectRatioSelector(state),
    isDeleting: isDeletingSelector(state),
    watermark: watermarkConfigurationSelector(state),
  });

  const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: OwnProps,
  ): DispatchProps => ({
    onDelete: () => {
      const { onDelete: propsOnDelete = _.noop } = ownProps;
      dispatch(removeWatermark());
      propsOnDelete();
      dispatch(hideModal());
    },
    onSubmit: (
      watermarkPosition: IWatermarkPosition,
      watermarkSize: IWatermarkSize,
    ) => {
      const { onSubmit: propsOnSubmit = _.noop } = ownProps;
      dispatch(editWatermark(watermarkPosition, watermarkSize));
      propsOnSubmit(watermarkPosition, watermarkSize);
      dispatch(hideModal());
    },
    onCancel: () => dispatch(hideModal()),
  });

  return connect(mapStateToProps, mapDispatchToProps)(component);
}
