import { Spacer } from '@sparemin/blockhead';
import * as React from 'react';
import { ArrowLeft } from 'components/icons';

import LinkButton from 'components/LinkButton';
import { block } from './utils';

export interface AutomationPostModalBackButtonProps {
  text: React.ReactNode;
  description?: React.ReactNode;
  onClick: () => void;
}

const AutomationPostModalBackButton: React.FC<AutomationPostModalBackButtonProps> = ({
  text,
  description,
  onClick,
}) => (
  <Spacer
    orientation="vertical"
    align="flex-start"
    justify="center"
    className={block('back-button')}
  >
    <Spacer
      as={LinkButton}
      orientation="horizontal"
      align="center"
      justify="center"
      space={2}
      onClick={onClick}
    >
      <ArrowLeft />

      <span className={block('back-button-text')}>{text}</span>
    </Spacer>
    {description && (
      <p className={block('back-button-description')}>{description}</p>
    )}
  </Spacer>
);

export default AutomationPostModalBackButton;
