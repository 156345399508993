import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { omit } from 'underscore';
import { setLastUsedStyleForWizard } from 'redux/modules/wizard-last-used-style/actions';
import {
  WizardLastUsedStyle,
  WizardName,
} from 'redux/modules/wizard-last-used-style/types';

export default function useLastUsedStyleDispatch(wizardName: WizardName) {
  const dispatch = useDispatch();

  const setLastUsedStyle = useCallback(
    (style: WizardLastUsedStyle) => {
      // timer size and fontSize are fixed relative to the viewport.  utils/embed/timer
      // contains the default sizes for each aspect ratio.  we drop these sizes
      // when saving last used style so that we don't deform the aspect ratio of
      // the timer when using lastUsedStyle from one aspect ratio in another
      const modifiedStyle = !style.timer
        ? style
        : {
            ...style,
            timer: omit(style.timer, 'timerSize', 'fontSize'),
          };
      dispatch(setLastUsedStyleForWizard(wizardName, modifiedStyle));
    },
    [dispatch, wizardName],
  );

  return {
    setLastUsedStyle,
  };
}
