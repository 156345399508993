import * as React from 'react';

function PlayCircle(props) {
  return (
    <svg width={47} height={47} viewBox="0 0 47 47" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <rect
          width={45}
          height={45}
          stroke="#DCE1EB"
          strokeLinejoin="round"
          strokeWidth={1.688}
          rx={22.5}
        />
        <path
          fill="#DCE1EB"
          fillRule="nonzero"
          d="M31.594 21.047L19.573 13.73c-.977-.594-2.473-.018-2.473 1.452V29.81c0 1.318 1.39 2.113 2.473 1.452l12.021-7.313c1.073-.65 1.076-2.253 0-2.903z"
        />
      </g>
    </svg>
  );
}

export default PlayCircle;
