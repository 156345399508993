import cn from 'classnames';
import React from 'react';

import { PlanInstanceProps } from 'blocks/Pricing/types';
import Button from 'components/Button';
import { VideoCameraAlt } from 'components/icons';
import { PlanName, Tier } from 'redux/middleware/api/plan-service';
import bem from 'utils/bem';
import { unlimitedFeatureList } from './features';
import Plan from './Plan';

export type UnlimitedPlanProps = Omit<PlanInstanceProps, 'cta'>;

const block = bem('unlimited-plan');

// TODO SPAR-16225 - UnlimitedPlan hiden on the FreeSub page.  Flagging this component for removal
// if Unlimited doesn't return
const UnlimitedPlan: React.FC<UnlimitedPlanProps> = ({
  className,
  onUpgradeClick,
  period,
}) => {
  return (
    <Plan className={cn(block(), className)}>
      <Plan.SubPeriodHeader
        cost={{
          monthly: PlanName.UNLIMITED_20211112_MONTHLY,
          yearly: PlanName.UNLIMITED_20211112_YEARLY,
        }}
        description="Multiple podcasts? Promote them all with unlimited everything."
        icon={<VideoCameraAlt fill="url(#pro-gradient)" />}
        period={period}
        theme="unlimited"
        title={<span className={block('title')}>Unlimited</span>}
      />
      <Plan.Description
        callToAction={
          <Plan.Action onClick={onUpgradeClick}>
            <Button theme="rainbow">get started</Button>
          </Plan.Action>
        }
        features={unlimitedFeatureList}
        theme={Tier.UNLIMITED}
      />
    </Plan>
  );
};

export default UnlimitedPlan;
