import classNames from 'classnames';
import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import { noop } from 'underscore';

import DraggableWorkspace, {
  DraggableWorkspaceProps,
  OnSizeChange,
} from 'components/DraggableWorkspace';
import Rnd, { RndProps } from 'components/Rnd';
import WorkspacePositionOverlay from 'components/WorkspacePositionOverlay';
import embedUtils from 'utils/embed';
import { createChainedFunction } from 'utils/functions';
import { setStyle } from 'utils/ui';
import AlignmentGuidelines from './AlignmentGuidelines/AlignmentGuidelines';
import { CENTERING_LINES } from './AlignmentGuidelines/constants';
import { SnapData } from './AlignmentGuidelines/types';
import useSnapLines from './AlignmentGuidelines/useSnapLines';
import { UseHook } from './UseHook';

interface IPosition {
  x: number;
  y: number;
}

interface ISize {
  height: number;
  width: number;
}

export interface IProps extends Pick<RndProps, 'resizeHandleStyles'> {
  aspectRatio?: number;
  background: React.ReactNode;
  boxBorderClassName?: string;
  children?: React.ReactElement;
  className?: string;
  containerSize?: {
    height: string | number;
    width: string | number;
  };
  defaultSize?: DraggableWorkspaceProps['defaultSize'];
  disabled?: boolean;
  draggingEnabled?: boolean;
  hidePositionOverlay?: boolean;
  textAreaClassName?: string;
  onTextBoxDrag?: (pos: IPosition) => void;
  onTextBoxDragStart?: (data: IPosition, e: MouseEvent) => void;
  onTextBoxDragStop?: (data: IPosition, e: MouseEvent) => void;
  onTextBoxResize?: (pos: IPosition, delta: ISize) => void;
  onTextBoxResizeStart?: (size: ISize) => void;
  onTextBoxResizeStop?: (data: IPosition & ISize) => void;
  onWorkspaceSizeChange?: OnSizeChange;
  positionOverlayClassName?: string;
  resizeHandleClassName?: string;
  resizingEnabled?: boolean;
  textBoxClassName?: string;
  textBoxPosition?: IPosition;
  textBoxSize: ISize;
  resizableEdges?: {
    bottom: boolean;
    bottomLeft: boolean;
    bottomRight: boolean;
    left: boolean;
    right: boolean;
    top: boolean;
    topLeft: boolean;
    topRight: boolean;
  };
}

interface IUi {
  textBoxDragging: boolean;
  showScaledPosition: boolean;
  scaledLeft: number;
  scaledTop: number;
}

interface IState {
  ui: RecordOf<IUi>;
}

const uiFactory = Record<IUi>({
  scaledLeft: 0,
  scaledTop: 0,
  showScaledPosition: false,
  textBoxDragging: false,
});

export default class TextWorkspace extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    aspectRatio: 16 / 9,
    disabled: true,
    draggingEnabled: true,
    onTextBoxDrag: noop,
    onTextBoxDragStart: noop,
    onTextBoxDragStop: noop,
    onTextBoxResize: noop,
    onTextBoxResizeStart: noop,
    onTextBoxResizeStop: noop,
    onWorkspaceSizeChange: noop,
    resizableEdges: undefined,
    resizingEnabled: true,
  };

  // TODO figure out types for hoc's
  private workspace: any;

  constructor(props: IProps) {
    super(props);

    this.state = { ui: uiFactory() };
  }

  private handleTextBoxDrag = (e, _, snapData: SnapData) => {
    const { onTextBoxDrag, onTextBoxDragStart } = this.props;
    const { x, y } = snapData;
    this.updateScaledPosition(x, y);
    const { textBoxDragging } = this.state.ui;

    if (!textBoxDragging) {
      this.setState(({ ui }) => ({
        ui: ui.set('textBoxDragging', true).set('showScaledPosition', true),
      }));
      /*
       * sets the move cursor style on the entire document so that even if user moves the mouse
       * outside of the dragged element, the cursor will remain consistent
       */
      setStyle(document.body, {
        cursor: 'move',
      });
      onTextBoxDragStart(snapData, e);
    } else {
      onTextBoxDrag(snapData);
    }
  };

  private handleTextBoxDragStop = (e: MouseEvent, _, data: SnapData) => {
    const { onTextBoxDragStop } = this.props;
    const { textBoxDragging } = this.state.ui;

    if (textBoxDragging === false) {
      return;
    }

    this.setState(({ ui }) => ({
      ui: ui.withMutations(u =>
        u.set('textBoxDragging', false).set('showScaledPosition', false),
      ),
    }));

    setStyle(document.body, {
      cursor: 'default',
    });
    onTextBoxDragStop(data, e);
  };

  private handleTextBoxResizeStart = (_1, _2, ref: HTMLDivElement) => {
    const { onTextBoxResizeStart } = this.props;

    const { height, width } = ref.getBoundingClientRect();
    onTextBoxResizeStart({ height, width });
  };

  private handleTextBoxResizeStop = (
    _1,
    _2,
    ref: HTMLDivElement,
    _3,
    position: IPosition,
  ) => {
    const { onTextBoxResizeStop } = this.props;
    const rect = ref.getBoundingClientRect();

    this.setState(({ ui }) => ({
      ui: ui.set('showScaledPosition', false),
    }));
    onTextBoxResizeStop({
      height: rect.height,
      width: rect.width,
      x: position.x,
      y: position.y,
    });
  };

  private handleTextBoxResize = (_1, _2, _3, delta, position) => {
    const { onTextBoxResize } = this.props;

    this.setState(({ ui }) => ({
      ui: ui.set('showScaledPosition', true),
    }));
    this.updateScaledPosition(position.x, position.y);

    onTextBoxResize(
      {
        x: position.x,
        y: position.y,
      },
      delta,
    );
  };

  get element() {
    return this.workspace.getInstance().getElement();
  }

  private updateScaledPosition = (x: number, y: number) => {
    const {
      height: workspaceHeight,
      width: workspaceWidth,
    } = this.workspace.getInstance().size;
    const scaledPosition = embedUtils.scaleToGrid(
      x,
      y,
      workspaceWidth,
      workspaceHeight,
    );

    this.setState(({ ui }) => ({
      ui: ui.withMutations(u =>
        u
          .set('scaledLeft', scaledPosition.x)
          .set('scaledTop', scaledPosition.y),
      ),
    }));
  };

  private getResizigEnabled = () => {
    const { disabled, resizableEdges, resizingEnabled } = this.props;

    return !disabled && resizingEnabled
      ? resizableEdges
      : {
          bottom: false,
          bottomLeft: false,
          bottomRight: false,
          left: false,
          right: false,
          top: false,
          topLeft: false,
          topRight: false,
        };
  };

  public render() {
    const {
      aspectRatio,
      background,
      boxBorderClassName,
      children,
      className,
      containerSize,
      defaultSize,
      disabled,
      draggingEnabled,
      hidePositionOverlay,
      onWorkspaceSizeChange,
      positionOverlayClassName,
      resizeHandleClassName,
      resizeHandleStyles,
      textBoxClassName,
      textBoxPosition,
      textBoxSize,
    } = this.props;

    const { ui } = this.state;

    const containerClassName = classNames({
      'text-workspace': true,
      'text-workspace--std': true,
      [className]: !!className,
    });

    const resizeHandleClass = classNames(
      'text-workspace__text-box-resize-handle',
      resizeHandleClassName,
    );

    const resizeHandleClasses = {
      bottom: resizeHandleClass,
      bottomLeft: resizeHandleClass,
      bottomRight: resizeHandleClass,
      left: resizeHandleClass,
      right: resizeHandleClass,
      top: resizeHandleClass,
      topLeft: resizeHandleClass,
      topRight: resizeHandleClass,
    };

    const workspaceRef = el => {
      this.workspace = el;
    };

    const child = React.Children.only(children);

    return (
      <DraggableWorkspace
        aspectRatio={aspectRatio}
        className={containerClassName}
        containerSize={containerSize}
        defaultSize={defaultSize}
        onSizeChange={onWorkspaceSizeChange}
        ref={workspaceRef}
      >
        {background}
        <div className="text-workspace__text-area-sizer">
          <UseHook
            hook={useSnapLines}
            args={[
              {
                containerSize: this.workspace?.getInstance().size,
                lines: CENTERING_LINES,
                onDrag: this.handleTextBoxDrag,
                onDragStop: this.handleTextBoxDragStop,
                size: textBoxSize,
              },
            ]}
          >
            {({
              activeLines,
              dragAxis,
              onDrag,
              onDragStart,
              onDragStop,
              onResizeStart,
              onResizeStop,
            }) => (
              <>
                <AlignmentGuidelines
                  active={ui.textBoxDragging}
                  containerSize={this.workspace?.getInstance().size}
                  lines={activeLines}
                />
                <Rnd
                  activatable={false}
                  className="text-workspace__text-box-container"
                  resizeHandleClasses={resizeHandleClasses}
                  resizeHandleStyles={resizeHandleStyles}
                  bounds="parent"
                  disableDragging={disabled || !draggingEnabled}
                  enableResizing={this.getResizigEnabled()}
                  onResize={this.handleTextBoxResize}
                  onResizeStart={createChainedFunction(
                    this.handleTextBoxResizeStart,
                    onResizeStart,
                  )}
                  onResizeStop={createChainedFunction(
                    this.handleTextBoxResizeStop,
                    onResizeStop,
                  )}
                  position={textBoxPosition}
                  size={textBoxSize}
                  {...{
                    dragAxis,
                    onDrag,
                    onDragStart,
                    onDragStop,
                  }}
                >
                  <div
                    className={classNames(
                      'text-workspace__text-box',
                      textBoxClassName,
                    )}
                  >
                    <div
                      className={classNames(
                        'text-workspace__border',
                        boxBorderClassName,
                      )}
                    />
                    {React.cloneElement(child, {
                      className: classNames(
                        'text-workspace__input',
                        child.props.className,
                      ),
                    })}
                  </div>
                </Rnd>
              </>
            )}
          </UseHook>
        </div>

        {!hidePositionOverlay && (
          <WorkspacePositionOverlay
            className={classNames(
              'text-workspace__position',
              positionOverlayClassName,
            )}
            display={ui.get('showScaledPosition')}
            position={{ x: ui.get('scaledLeft'), y: ui.get('scaledTop') }}
          />
        )}
      </DraggableWorkspace>
    );
  }
}
