import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SteppedModal from 'components/SteppedModal';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import YoutubePostModal from 'containers/YoutubePostModal';
import { UseComposerStepValue } from 'containers/YoutubePostModal/steps/types';
import useAccessDeniedStep from 'containers/YoutubePostModal/steps/useAccessDeniedStep';
import useAuthorizationStep from 'containers/YoutubePostModal/steps/useAuthorizationStep';

import useAutopostingComposerStep from 'containers/YoutubePostModal/steps/useAutopostingComposerStep';
import useCreatePlaylistStep from 'containers/YoutubePostModal/steps/useCreatePlaylistStep';
import useSwitchAccountStep from 'containers/YoutubePostModal/steps/useSwitchAccountStep';
import useYoutubeAccountVerification from 'containers/YoutubePostModal/useYoutubeAccountVerification';
import useLatestEpisodeInfo from 'hooks/useLatestEpisodeInfo';
import { loggedOutSelector } from 'redux/modules/auth';
import { showError } from 'redux/modules/notification';
import { isFreeSelector } from 'redux/modules/pricing';
import {
  clearYoutubeUser,
  getYoutubeCategories,
  getYoutubeLanguages,
  getYoutubePlaylists,
  youtubePlaylistsSelector,
} from 'redux/modules/social';
import { Dispatch } from 'redux/types';
import { createChainedFunction } from 'utils/functions';

import { getComposerSheetDefaults } from './utils';

export interface YoutubeAutoPostModalProps {}

const YoutubeAutoPostModal: React.FC<YoutubeAutoPostModalProps> = () => {
  const [activeStep, setActiveStep] = useState('');
  const { onExited, onHide, params, show } = useConnectedModal(
    'YoutubeAutoPost',
  );
  const dispatch = useDispatch<Dispatch>();
  const modalRef = useRef<SteppedModal>(null);
  const isFree = useSelector(isFreeSelector);
  const loggedOut = useSelector(loggedOutSelector);

  const { defaultPlaylistName, defaultPodcastId, source, subscriptionItem } =
    params ?? {};

  const {
    title,
    defaultLanguage: language,
    description,
    tags,
    madeForKids,
    categoryId,
    privacyStatus,
    playlists,
  } = subscriptionItem?.autoPostVideoPreference.options || {};

  const { getLatestEpisodeInfo } = useLatestEpisodeInfo();

  // it is important to keep this object references stable as it would end up producing
  // an endless loop if any of the values was to mutate on each re render
  const initialValues = React.useMemo(
    () =>
      getComposerSheetDefaults(
        {
          language,
          description,
          madeForKids,
          categoryId,
          playlistsIds: playlists,
          tags,
          title,
          visibility: privacyStatus,
        },
        { isFree, loggedOut },
      ),
    [
      categoryId,
      description,
      isFree,
      language,
      loggedOut,
      madeForKids,
      playlists,
      privacyStatus,
      tags,
      title,
    ],
  );

  const { isLoading: isPlaylistLoading, isIdle: isPlaylistIdle } = useSelector(
    youtubePlaylistsSelector,
  );

  const replaceModalStep = useCallback((id: string): void => {
    modalRef.current?.stepHistory.replace(id);
  }, []);

  const pushModalStep = useCallback((id: string): void => {
    modalRef.current?.stepHistory.push(id);
  }, []);

  const handleError = useCallback(
    (err: string) => {
      if (err === 'access_denied') {
        replaceModalStep('access-denied');
      }
    },
    [replaceModalStep],
  );

  const handleSwitchAccount = useCallback(() => {
    replaceModalStep('switch');
  }, [replaceModalStep]);

  const loadLanguages = useCallback(() => {
    dispatch(getYoutubeLanguages()).catch(() => {
      dispatch(
        showError({ message: 'Unable to load languages due to network error' }),
      );
    });
  }, [dispatch]);

  const resolveAllowedAccount = useCallback(async () => {
    loadLanguages();
    dispatch(getYoutubeCategories());

    if (isPlaylistIdle || !isPlaylistLoading) {
      await getLatestEpisodeInfo(defaultPodcastId);
      dispatch(getYoutubePlaylists());
      pushModalStep('composer');
    }
  }, [
    defaultPodcastId,
    dispatch,
    getLatestEpisodeInfo,
    isPlaylistIdle,
    isPlaylistLoading,
    loadLanguages,
    pushModalStep,
  ]);

  const {
    onAccountVerification,
    verificationSteps,
  } = useYoutubeAccountVerification({
    modalRef,
    onAccountVerificationFailure: onHide,
    onAccountVerificationSuccess: resolveAllowedAccount,
    onHideModal: onHide,
    onSwitchAccount: handleSwitchAccount,
    source,
  });

  const handleAuthenticated = useCallback(() => {
    onAccountVerification();
  }, [onAccountVerification]);

  const handleExited = useCallback(() => {
    replaceModalStep('authorization');
  }, [replaceModalStep]);

  const handleSwitchAccountSuccess = useCallback(() => {
    onAccountVerification();
  }, [onAccountVerification]);

  const handleCreatePlaylistClick = useCallback(() => {
    pushModalStep('playlist');
  }, [pushModalStep]);

  const onInitialStepClick = useCallback(() => {
    pushModalStep('composer');
  }, [pushModalStep]);

  const handleVideoTitleClick = useCallback(() => {
    pushModalStep('video-title-step');
  }, [pushModalStep]);

  const handleVideoDescriptionClick = useCallback(() => {
    pushModalStep('video-description-step');
  }, [pushModalStep]);

  const handleVideoTagsClick = useCallback(() => {
    pushModalStep('video-tags-step');
  }, [pushModalStep]);

  const handleAdditionalStepClick = useCallback(() => {
    pushModalStep('additional-details-step');
  }, [pushModalStep]);

  const handleSubmit = useCallback(
    (value: UseComposerStepValue): void => {
      dispatch(clearYoutubeUser());
      onHide(value);
    },
    [dispatch, onHide],
  );

  const authorization = useAuthorizationStep({
    onAuthSuccess: handleAuthenticated,
    onError: handleError,
  });

  const accessDenied = useAccessDeniedStep({
    onError: () => null,
    onReviseAccess: () => null,
  });

  const { composerSteps } = useAutopostingComposerStep({
    initialValues,
    hideProgress: true,
    activeStep,
    onHideModal: onHide,
    onInitialStepClick,
    onVideoTitleClick: handleVideoTitleClick,
    onVideoDescriptionClick: handleVideoDescriptionClick,
    onVideoTagsClick: handleVideoTagsClick,
    onAdditionalStepClick: handleAdditionalStepClick,
    onCreatePlaylistClick: handleCreatePlaylistClick,
    onSubmit: handleSubmit,
    onSwitchAccountClick: handleSwitchAccount,
    submitButtonLabel: 'confirm auto-posting details',
  });

  const composerStepsId = composerSteps.map(step => step.id);
  const isComposerStep = composerStepsId.includes(activeStep);

  const switchAccount = useSwitchAccountStep({
    onError: handleError,
    onSwitchAccountSuccess: handleSwitchAccountSuccess,
  });

  const playlist = useCreatePlaylistStep({
    defaultPlaylistName,
    podcastId: defaultPodcastId,
    onSuccess: () => modalRef.current?.stepHistory.pop(),
  });

  return (
    <YoutubePostModal
      {...{ onHide, show }}
      defaultStep={authorization.id}
      onExited={createChainedFunction(handleExited, onExited)}
      onStepChange={setActiveStep}
      ref={modalRef}
      steps={[
        ...verificationSteps,
        ...composerSteps,
        authorization,
        accessDenied,
        switchAccount,
        playlist,
      ]}
      className={isComposerStep && 'composer-step-container'}
      title={!isComposerStep && 'Auto-posting details'}
    />
  );
};

export default YoutubeAutoPostModal;
