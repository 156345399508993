import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ids from 'short-id';
import { standardizationStatusSelector } from 'redux/modules/async-audio-clipper';
import {
  onAnalyzeAudio,
  onAudioLoaded,
  onBeginAudioAnalysis,
  onFinalizingAudio,
  onReencodingAudio,
  onTransferAudioToStorage,
  onUploadingAudio,
} from 'redux/modules/mixpanel';
import * as constants from 'redux/modules/mixpanel/constants';
import { ProcessAudioEvent } from 'redux/modules/mixpanel/types';
import { ThunkAction } from 'redux/types';
import { isFileLike } from 'utils/file';

interface UseProcessAudioEventTrackingConfig {
  progressPercentage: number;
  src: string | Blob;
}

interface EventDescription {
  progressStart: number;
  progressEnd: number;
  action: (
    id: string,
    src: string | Blob,
    progress: number,
  ) => ThunkAction<void>;
  event: ProcessAudioEvent;
}

const AUDIO_FILE_EVENTS: EventDescription[] = [
  {
    progressStart: 1,
    progressEnd: 48,
    action: onUploadingAudio,
    event: constants.UPLOADING_AUDIO,
  },
  {
    progressStart: 49,
    progressEnd: 61,
    action: onTransferAudioToStorage,
    event: constants.TRANSFER_AUDIO_TO_STORAGE,
  },
  {
    progressStart: 62,
    progressEnd: 66,
    action: onBeginAudioAnalysis,
    event: constants.BEGIN_AUDIO_ANALYSIS,
  },
  {
    progressStart: 67,
    progressEnd: 72,
    action: onAnalyzeAudio,
    event: constants.ANALYZE_AUDIO,
  },
  {
    progressStart: 73,
    progressEnd: 95,
    action: onReencodingAudio,
    event: constants.REENCODING_AUDIO,
  },
  {
    progressStart: 96,
    progressEnd: 99,
    action: onFinalizingAudio,
    event: constants.FINALIZING_AUDIO,
  },
  {
    progressStart: 100,
    progressEnd: 100,
    action: onAudioLoaded,
    event: constants.AUDIO_LOADED,
  },
];

const AUDIO_URL_EVENTS: EventDescription[] = [
  {
    progressStart: 12,
    progressEnd: 29,
    action: onTransferAudioToStorage,
    event: constants.TRANSFER_AUDIO_TO_STORAGE,
  },
  {
    progressStart: 30,
    progressEnd: 46,
    action: onBeginAudioAnalysis,
    event: constants.BEGIN_AUDIO_ANALYSIS,
  },
  {
    progressStart: 47,
    progressEnd: 72,
    action: onAnalyzeAudio,
    event: constants.ANALYZE_AUDIO,
  },
  {
    progressStart: 73,
    progressEnd: 95,
    action: onReencodingAudio,
    event: constants.REENCODING_AUDIO,
  },
  {
    progressStart: 96,
    progressEnd: 99,
    action: onFinalizingAudio,
    event: constants.FINALIZING_AUDIO,
  },
  {
    progressStart: 100,
    progressEnd: 100,
    action: onAudioLoaded,
    event: constants.AUDIO_LOADED,
  },
];

function removeEvents(
  eventList: EventDescription[],
  ...eventsToRemove: ProcessAudioEvent[]
): EventDescription[] {
  eventsToRemove.forEach(event => {
    const index = eventList.findIndex(desc => desc.event === event);

    if (index >= 0) {
      eventsToRemove.splice(index, 1);
    }
  });

  return eventList;
}

export default function useProcessAudioEventTracking({
  progressPercentage,
  src,
}: UseProcessAudioEventTrackingConfig) {
  const sessionId = useRef<string>();
  const events = useRef<EventDescription[]>();
  const dispatch = useDispatch();
  const standardizationStatus = useSelector(standardizationStatusSelector);

  // this is an initialization effect that sets `events` to the full set of events
  // that should be sent to mixpanel for this audio source type.
  useEffect(() => {
    sessionId.current = ids.generate();

    if (isFileLike(src)) {
      events.current = [...AUDIO_FILE_EVENTS];
    } else {
      events.current = [...AUDIO_URL_EVENTS];
    }
  }, [src]);

  // this hook removes the "reencoding audio" event if standardization is to be
  // skipped.  this is done because each time we send an event, we send all
  // previous events that might have been skipped (e.g. if the progressPercentage
  // jumps).
  //
  // if the status is 'skipStandardization' that means one of two things:
  //  - the file was never seen by the API, so was analyzed and determined to not need reencoding
  //  - the file was seen by the API in the past and it has already determined that no reencoding is needed
  //
  // In the former case, standardization status will progress as "queued" => "processing" => "skipStandardization".
  // In the latter case, the first status returned by the API will be "skipStandardization"
  useEffect(() => {
    if (standardizationStatus === 'skipStandardization') {
      removeEvents(
        events.current,
        constants.REENCODING_AUDIO,
        constants.BEGIN_AUDIO_ANALYSIS,
        constants.ANALYZE_AUDIO,
      );
    }
  }, [standardizationStatus]);

  useEffect(() => {
    const progressEventIndex = events.current.findIndex(
      desc =>
        progressPercentage >= desc.progressStart &&
        progressPercentage <= desc.progressEnd,
    );

    if (progressEventIndex >= 0) {
      const eventsToDispatch = events.current.splice(0, progressEventIndex + 1);
      eventsToDispatch.forEach(desc =>
        dispatch(desc.action(sessionId.current, src, progressPercentage)),
      );
    }
  }, [dispatch, progressPercentage, src]);
}
