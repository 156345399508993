import * as React from 'react';

import {
  getVideoCount,
  getWorkflowCount,
  isVideoPending,
} from 'blocks/AutomationCMS/utils';
import EpisodeListRowStatus, {
  EpisodeListRowStatusProps,
} from '../../components/EpisodeListRow/EpisodeListRowStatus';
import EpisodeRowComingSoon from '../../components/EpisodeRowComingSoon';
import EpisodeRowVideoCount from '../../components/EpisodeRowVideoCount';
import EpisodeActionButtons from './EpisodeActionButtons';
import GeneratingBadge from './GeneratingBadge';
import useEpisodeListEpisodeRowContext from './useEpisodeListEpisodeRowContext';

const { useCallback, useState } = React;

type PickedStatusProps = Pick<EpisodeListRowStatusProps, 'hide'>;

export interface EpisodeRowStatusProps extends PickedStatusProps {
  active: boolean;
  generating: boolean;
  hide?: boolean;
  onCreateClick: () => void;
}

interface Values {
  active: boolean;
  hide: boolean;
  videoCount: number;
  videoPending: boolean;
  workflowCount: number;
}

/*
 * props are cached while the fade-out animation is in progress so that the
 * text doesn't change from "create a video" to "we'll email you" prematurely.
 * the text change will only happen after the element is completely faded out
 * so that the change is invisible to the user
 */
const EpisodeRowStatus: React.FC<EpisodeRowStatusProps> = props => {
  const {
    hide: hideProp,
    generating,
    active: activeProp,
    onCreateClick,
  } = props;
  const { projects, episode, workflows } = useEpisodeListEpisodeRowContext();
  const [animatingOut, setAnimatingOut] = useState(false);
  const [cachedValues, setCachedValues] = useState<Values>(null);

  const values: Values = {
    active: activeProp,
    hide: hideProp,
    videoCount: getVideoCount(projects, episode),
    videoPending: isVideoPending(episode),
    workflowCount: getWorkflowCount(workflows),
  };

  const { hide, active, videoCount, videoPending } = animatingOut
    ? cachedValues
    : values;

  const handleExit = useCallback(() => {
    setAnimatingOut(true);
    setCachedValues(values);
  }, [values]);

  const handleExited = useCallback(() => {
    setAnimatingOut(false);
    setCachedValues(null);
  }, []);

  return (
    <EpisodeListRowStatus
      onExit={handleExit}
      onExited={handleExited}
      hide={hide}
    >
      {generating && <GeneratingBadge active={active} />}
      <div style={{ position: 'relative' }}>
        <EpisodeActionButtons
          enabled={(videoCount === 0 || active) && !videoPending}
          onClick={onCreateClick}
        />
        {videoPending ? (
          <EpisodeRowComingSoon />
        ) : (
          <EpisodeRowVideoCount count={videoCount} />
        )}
      </div>
    </EpisodeListRowStatus>
  );
};

export default EpisodeRowStatus;
