import * as React from 'react';
import { connect } from 'react-redux';
import { pushModal } from 'redux/modules/modal';

import { projectEmbedConfigurationIdSelector } from 'redux/modules/project/selectors';
import { Dispatch, State } from 'redux/types';
import { SaveAsTemplateProps } from '.';

type StateProps = Pick<SaveAsTemplateProps, 'disabled'>;
type DispatchProps = Pick<SaveAsTemplateProps, 'onSave'>;
type OwnProps = Pick<SaveAsTemplateProps, 'disabled'>;

const mapStateToProps = (state: State, props: OwnProps): StateProps => ({
  disabled: props.disabled || !projectEmbedConfigurationIdSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSave: (from?: string) =>
    dispatch(
      pushModal({
        name: 'TemplateLengthSelector',
        params: {
          from,
        },
      }),
    ),
});

export default function(component: React.ComponentType<SaveAsTemplateProps>) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
