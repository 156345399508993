import * as React from 'react';
import { numberOrPxToPx } from 'utils/placement';
import { useNavigation } from '../context/NavigationContext';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import useEditorLoading from '../useEditorLoading';
import RndAsset, { RndAssetProps } from './RndAsset';
import SlideshowAsset from './SlideshowAsset';

const { useCallback, useEffect } = React;

type PickedAssetProps = Pick<
  RndAssetProps,
  'onDragStop' | 'onResizeStop' | 'params'
>;

export interface DraggableSlideshowAssetProps extends PickedAssetProps {
  id: string;
}

const DraggableSlideshowAsset: React.FC<DraggableSlideshowAssetProps> = ({
  id,
  onDragStop,
  onResizeStop,
  params,
}) => {
  const [, send] = useNavigation();
  const dispatch = useTemplateDispatch();
  const { slideshow } = useTemplateState();
  const [isEditorLoading, setEditorLoading] = useEditorLoading();

  useEffect(() => {
    return () => {
      setEditorLoading(false);
    };
  }, [setEditorLoading]);

  const handleSlideLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const img = e.currentTarget;

      setEditorLoading(false);

      dispatch({
        type: 'IMAGE_LOAD',
        payload: {
          id,
          naturalSize: numberOrPxToPx({
            height: img.naturalHeight,
            width: img.naturalWidth,
          }),
        },
      });
    },
    [dispatch, id, setEditorLoading],
  );

  const handleSlideError = useCallback(() => {
    setEditorLoading(false);
  }, [setEditorLoading]);

  const handleSlideLoadStart = useCallback(() => {
    setEditorLoading(true);
  }, [setEditorLoading]);

  const handleMouseDown = useCallback(() => {
    send({
      type: 'CHILD_VIEW_OPEN',
      payload: 'image',
      meta: { source: 'preview', id },
    });
  }, [id, send]);

  return !slideshow.data[id].placement ? (
    <SlideshowAsset
      hidden
      id={id}
      onLoad={handleSlideLoad}
      onError={handleSlideError}
      onLoadStart={handleSlideLoadStart}
    />
  ) : (
    <RndAsset
      {...{ onDragStop, onResizeStop, params }}
      {...slideshow.data[id].placement}
      assetId={id}
      onMouseDown={handleMouseDown}
    >
      <SlideshowAsset
        id={id}
        hidden={isEditorLoading}
        onLoad={handleSlideLoad}
        onError={handleSlideError}
        onLoadStart={handleSlideLoadStart}
      />
    </RndAsset>
  );
};

export default DraggableSlideshowAsset;
