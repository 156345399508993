import { fromJS } from 'immutable';
import { defaults } from 'underscore';

import {
  IEmbedConfig,
  Progress,
  ProgressAnimationOptions,
  ProgressAnimationOptionsState,
  ProgressPositionId,
  ProgressSizeId,
  ProgressType,
  Size,
} from 'types';
import { getValue } from 'utils/collections';
import { isImmutable } from 'utils/immutable';
import measurement, { Measurement } from 'utils/measurement';
import { clamp } from 'utils/numbers';

type UpdateSizeAction = {
  type: 'UPDATE_SIZE';
  payload: ProgressSizeId;
  meta: Size<number, number>;
};

type UpdatePositionAction = {
  type: 'UPDATE_POSITION';
  payload: ProgressPositionId;
  meta: Size<number, number>;
};

type UpdateTypeAction = {
  type: 'UPDATE_TYPE';
  payload: ProgressType;
  meta: Size<number, number>;
};

type Action = UpdateSizeAction | UpdatePositionAction | UpdateTypeAction;

export const BAR_HEIGHTS: Partial<Record<ProgressSizeId, Measurement>> = {
  small: measurement((3 / 285) * 100, 'vh'),
  medium: measurement((10 / 285) * 100, 'vh'),
  large: measurement((20 / 285) * 100, 'vh'),
};

const applySizeToBar = (
  state: ProgressAnimationOptions<Measurement>,
  action: UpdateSizeAction,
): ProgressAnimationOptions<Measurement> => {
  const { height, top } = state;
  const newHeight = BAR_HEIGHTS[action.payload];

  if (!newHeight) {
    return state;
  }

  const position = getProgressPositionId(state);
  const diff = height.minus(newHeight);

  if (position === 'bottom') {
    return {
      ...state,
      height: newHeight,
      top: measurement(100, 'vh').minus(newHeight),
    };
  }

  if (position === 'top') {
    return {
      ...state,
      height: newHeight,
      top: measurement(0, 'vh'),
    };
  }

  return {
    ...state,
    height: newHeight,
    top: measurement(
      clamp(top.value + diff.value / 2, 0, 100 - newHeight.value),
      'vh',
    ),
  };
};

const applyPosition = (
  state: ProgressAnimationOptions<Measurement>,
  action: UpdatePositionAction,
): ProgressAnimationOptions<Measurement> => {
  const { height } = state;
  if (action.payload === 'top') {
    return {
      ...state,
      top: measurement(0, 'vh'),
    };
  }

  if (action.payload === 'bottom') {
    return {
      ...state,
      top: measurement(100 - height.value, 'vh'),
    };
  }

  if (action.payload === 'middle') {
    return {
      ...state,
      top: measurement(50 - height.value / 2, 'vh'),
    };
  }

  return state;
};

const PLAYER_RATIO = 12;

const PLAYER_WIDTHS: Partial<Record<ProgressSizeId, Measurement>> = {
  large: measurement(100, 'vw'),
  medium: measurement(73.52, 'vw'),
  small: measurement(37.33, 'vw'),
};

const applySizeToPlayer = (
  state: ProgressAnimationOptions<Measurement>,
  action: UpdateSizeAction,
): ProgressAnimationOptions<Measurement> => {
  const width = PLAYER_WIDTHS[action.payload]?.toUnit('px', action.meta);

  if (!width) {
    return state;
  }

  const position = getProgressPositionId(state);
  const height = width.divideBy(PLAYER_RATIO);
  const newWidth = width.toUnit('vw', action.meta);
  const newHeight = height.toUnit('vh', action.meta);
  const halfHeightDiff = state.height.minus(newHeight).divideBy(2);
  const halfWidthDiff = state.width.minus(newWidth).divideBy(2);
  const left = measurement(
    clamp(state.left.value + halfWidthDiff.value, 0, 100 - newWidth.value),
    'vw',
  );

  if (position === 'bottom') {
    return {
      ...state,
      width: newWidth,
      height: newHeight,
      left,
      top: measurement(100, 'vh').minus(newHeight),
    };
  }

  if (position === 'top') {
    return {
      ...state,
      width: newWidth,
      height: newHeight,
      left,
      top: measurement(0, 'vh'),
    };
  }

  return {
    ...state,
    width: newWidth,
    height: newHeight,
    left,
    top: measurement(
      clamp(state.top.value + halfHeightDiff.value, 0, 100 - newHeight.value),
      'vh',
    ),
  };
};

export const progressReducer = (
  state: ProgressAnimationOptions<Measurement>,
  action: Action,
): ProgressAnimationOptions<Measurement> => {
  switch (action.type) {
    case 'UPDATE_SIZE': {
      switch (state.type) {
        case ProgressType.BAR: {
          return applySizeToBar(state, action);
        }
        case ProgressType.PLAYER: {
          return applySizeToPlayer(state, action);
        }
        default: {
          return state;
        }
      }
    }
    case 'UPDATE_POSITION': {
      return applyPosition(state, action);
    }
    case 'UPDATE_TYPE': {
      let newState: ProgressAnimationOptions<Measurement> = {
        ...state,
        type: action.payload,
        enabled: !!action.payload,
      };

      // reset size
      newState = progressReducer(newState, {
        type: 'UPDATE_SIZE',
        payload: 'large',
        meta: action.meta,
      });

      // reset position
      newState = progressReducer(newState, {
        type: 'UPDATE_POSITION',
        payload: 'bottom',
        meta: action.meta,
      });

      return newState;
    }
    default: {
      return state;
    }
  }
};

export const getProgressSizeId = (
  state: ProgressAnimationOptions<Measurement>,
): ProgressSizeId => {
  if (state.type === ProgressType.PLAYER) {
    const keys = Object.keys(PLAYER_WIDTHS) as ProgressSizeId[];
    return keys.find(k => state.width.eq(PLAYER_WIDTHS[k])) ?? 'custom';
  }

  const keys = Object.keys(BAR_HEIGHTS) as ProgressSizeId[];
  return keys.find(k => state.height.eq(BAR_HEIGHTS[k])) ?? 'custom';
};

export const getProgressPositionId = (
  state: ProgressAnimationOptions<Measurement>,
): ProgressPositionId => {
  const height = state.height.value;

  if (state.top.eq(0)) {
    return 'top';
  }

  if (state.top.eq(50 - height / 2)) {
    return 'middle';
  }

  if (state.top.eq(100 - height)) {
    return 'bottom';
  }

  return 'custom';
};

export const DEFAULT_PROGRESS_OPTIONS: Readonly<ProgressAnimationOptions> = {
  color: 'rgba(216, 216, 216, 0.25)',
  enabled: false,
  fillColor: '#19b5fe',
  width: '100vw',
  height: BAR_HEIGHTS.medium.toString(),
  left: '0vw',
  top: measurement(100, 'vh')
    .minus(BAR_HEIGHTS.medium)
    .toString(),
};

export function formatProgressForConfig(
  progressOptions: ProgressAnimationOptions | ProgressAnimationOptionsState,
): Partial<Progress> {
  if (!progressOptions) return { enabled: false };

  const opts: ProgressAnimationOptions = {
    ...DEFAULT_PROGRESS_OPTIONS,
    ...(isImmutable(progressOptions)
      ? progressOptions.toJS()
      : progressOptions),
  };

  return {
    elapsedColor: opts.fillColor,
    enabled: opts.enabled,
    position: {
      properties: {
        top: opts.top,
        left: opts.left,
      },
      type: 'absolute' as const,
    },
    style: {
      width: opts.width,
      height: opts.height,
    },
    type: opts.type ?? ProgressType.BAR,
    unelapsedColor: opts.color,
  };
}

export function formatProgressFromConfig(progress: Progress) {
  if (!progress) return undefined;

  return fromJS(
    defaults(
      {
        enabled: progress?.enabled,
        color: progress?.unelapsedColor,
        fillColor: progress?.elapsedColor,
        width: progress?.style?.width,
        height: progress?.style?.height,
        top: progress?.position?.properties?.top,
        left: progress?.position?.properties?.left,
        type: progress?.type,
      },
      DEFAULT_PROGRESS_OPTIONS,
    ),
  );
}

export function getProgressAnimationFromConfig(config: IEmbedConfig) {
  return getValue(config, ['embedConfig', 'progress']);
}
