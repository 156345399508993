import * as React from 'react';
import { Upload } from 'components/icons';
import UpsellBanner, { UpsellBannerProps } from 'components/UpsellBanner';

export interface FileSizeUpsellProps
  extends Pick<UpsellBannerProps, 'onUpgradeClick'> {
  className?: string;
  upgradeLimit?: string;
}

const DEFAULT_UPGRADE_UPLOAD_LIMIT = '500MB';

const FileSizeUpsellBanner: React.FC<FileSizeUpsellProps> = ({
  className,
  onUpgradeClick,
  upgradeLimit = DEFAULT_UPGRADE_UPLOAD_LIMIT,
}) => (
  <UpsellBanner
    {...{ className, onUpgradeClick }}
    heading={`Increase your upload limit to ${upgradeLimit}`}
    actionLabel="Upgrade now"
    icon={<Upload />}
  />
);

export default FileSizeUpsellBanner;
