import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import DeleteAccountModalContents from './DeleteAccountModalContents';

const EditAudioModal: React.FC = () => {
  return (
    <ConnectedModal name="DeleteAccount" title="Delete Your Account">
      <DeleteAccountModalContents />
    </ConnectedModal>
  );
};

export default EditAudioModal;
