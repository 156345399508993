import * as Immutable from 'immutable';
import { Type } from './action-types';
import { IModal, ModalAction } from './types';

const defaultState = Immutable.List<IModal>();

export default (state = defaultState, action: ModalAction) => {
  switch (action.type) {
    case Type.MODAL_PUSH: {
      const { payload } = action;
      return state.push({ ...payload, show: true });
    }

    case Type.MODAL_HIDE: {
      const modalName = action.payload;

      if (!state.first()) {
        return state;
      }

      return state.withMutations(s => {
        if (!modalName || s.first().name === modalName) {
          s.set(0, { ...s.first(), show: false });
        }
      });
    }

    case Type.MODAL_POP: {
      return state.shift();
    }

    case Type.MODAL_REPLACE: {
      const { payload } = action;
      const modalToAdd = { ...payload, show: true };

      if (state.first()) {
        return state
          .set(0, { ...state.first(), show: false })
          .insert(1, modalToAdd);
      }

      return state.insert(0, modalToAdd);
    }

    case Type.MODAL_UPDATE: {
      const { payload } = action;
      if (state.first().name === payload.name) {
        return state.set(0, { ...state.first(), ...payload });
      }
      return state;
    }

    default:
      return state;
  }
};
