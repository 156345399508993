import * as React from 'react';
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router';
import ConfirmationModal from 'components/ConfirmationModal';
import FontAwesome from 'components/FontAwesome';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import { podcastFeedDataSelector } from 'redux/modules/automation-cms/selectors';
import { PodcastFeedData } from 'redux/modules/automation-cms/types';
import { State } from 'redux/types';
import { autogramWizardLocation } from 'utils/routes';
import AutomationCMSModalContext from './AutomationCMSRich/AutomationCMSModalContext';

const { useContext } = React;

export interface AutomationWorkflowCreateModalProps {
  show: boolean;
  episodeId: string;
  podcastId: string;
}

const AutomationWorkflowCreateModal: React.FC<AutomationWorkflowCreateModalProps> = ({
  show,
  episodeId,
  podcastId,
}) => {
  const history = useHistory();
  const modalDispatch = useContext(AutomationCMSModalContext);
  const feed = useSelector<State, PodcastFeedData>(state =>
    podcastFeedDataSelector(state)?.get(podcastId),
  );

  const onCancel = () => {
    if (feed?.workflows?.size) {
      modalDispatch({
        type: 'show-episode-create',
        payload: {
          episodeId,
        },
      });
    } else {
      modalDispatch({
        type: 'reset',
      });
    }
  };

  const onConfirm = () => {
    history.push(autogramWizardLocation({ podcastId }));
  };

  return (
    <ConfirmationModal
      {...{ show, onCancel, onConfirm }}
      confirmButtonText="Create new"
      cancelButtonText="Back"
      title="New automation"
    >
      <ConfirmationModal.Icon>
        <CircularGradient>
          <FontAwesome icon="magic" size="3x" />
        </CircularGradient>
      </ConfirmationModal.Icon>
      <ConfirmationModal.Title>Create new automation</ConfirmationModal.Title>
      <ConfirmationModal.Content>
        Do you want to create a new automation for this podcast?
      </ConfirmationModal.Content>
    </ConfirmationModal>
  );
};

export default AutomationWorkflowCreateModal;
