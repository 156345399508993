import cn from 'classnames';
import React, { useCallback } from 'react';

import BemCSSTransition from 'components/BemCssTransition';
import Button from 'components/Button';
import { H2 } from 'components/Heading';
import { RequestStatus } from 'types';
import { footerBlock as block } from '../utils';
import { useClipSelectAnimation } from './ClipSelectAnimationContext';
import { useClipSelect } from './ClipSelectContext';
import { useClipSelectNavigation } from './ClipSelectNavigationContext/ClipSelectNavigationContext';
import { useClipSelectTracking } from './ClipSelectTrackingContext';

export interface ClipSelectFooterProps {
  className?: string;
}

const ClipSelectFooter: React.FC<ClipSelectFooterProps> = ({ className }) => {
  const animations = useClipSelectAnimation();
  const { onRegionChange, submitStatus } = useClipSelect();
  const { onSelectOwnClip } = useClipSelectTracking();
  const [, send] = useClipSelectNavigation();

  const handleButtonClick = useCallback(() => {
    onRegionChange({ startMillis: 0, endMillis: 1000 });
    send({ type: 'CUSTOM_CLICK' });
    onSelectOwnClip();
  }, [onRegionChange, onSelectOwnClip, send]);

  return (
    <div className={cn(block(), className)}>
      <div className={block('contents')}>
        <H2 className={block('message')}>Don't like these suggestions?</H2>
        <BemCSSTransition {...animations.footerCta}>
          <Button
            disabled={submitStatus === RequestStatus.REQUEST}
            fluid
            onClick={handleButtonClick}
            theme="submit-alt"
          >
            select your own clip
          </Button>
        </BemCSSTransition>
      </div>
    </div>
  );
};

export default ClipSelectFooter;
