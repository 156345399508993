import { getDefaultActiveModal } from 'containers/EditTextModal';
import { pushModal } from 'redux/modules/modal';
import { ThunkAction } from 'redux/types';

import { selectedOverlaySelector } from '../selectors';

export const pushEditTextModal = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const textOverlay = selectedOverlaySelector(getState());
  dispatch(
    pushModal({
      name: 'EditTextModal',
      params: { startingModalType: getDefaultActiveModal(textOverlay) },
    }),
  );
};
