import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import { H3 } from 'components/Heading';
import { ProBadge } from 'components/icons';
import { useMediaCard } from './MediaCardContext';
import { block } from './utils';

interface MediaCardContentTitleProps {
  title: React.ReactNode;
}

const MediaCardContentTitle: React.FC<MediaCardContentTitleProps> = ({
  title,
}) => {
  const { isLocked } = useMediaCard();

  return (
    <Spacer
      as={H3}
      orientation="horizontal"
      align="center"
      justify="space-between"
      className={block('content-title')}
    >
      {title}
      {isLocked && <ProBadge />}
    </Spacer>
  );
};

export default MediaCardContentTitle;
