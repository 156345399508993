import cn from 'classnames';
import * as React from 'react';

import bem from 'utils/bem';
import { cloneOnlyChild } from 'utils/react';
import AuthDialogWrapper from './AuthDialogWrapper';

export interface OnboardingDialogWrapperProps {
  children?: React.ReactNode;
}

const block = bem('onboarding-dialog-wrapper');

/**
 * wraps onboarding dialogs (cards) on the AuthPage to provide the correct
 * spacing on desktoip and mobile.  The onboarding wizard dialogs have a slightly
 * different UI than the auth dialogs
 */
const OnboardingDialogWrapper: React.FC<OnboardingDialogWrapperProps> = ({
  children,
}) => (
  <AuthDialogWrapper className={block()}>
    {cloneOnlyChild(children, child => ({
      className: cn(child.props.className, block('dialog')),
    }))}
  </AuthDialogWrapper>
);

export default OnboardingDialogWrapper;
