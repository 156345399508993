import React from 'react';

import { Edit } from 'components/icons';
import LinkButton from 'components/LinkButton';

import { actionStepCardEditBlock } from './utils';

interface ActionStepCardEditButtonProps {
  onClickEdit: () => void;
}

const ActionStepCardEditButton: React.FunctionComponent<ActionStepCardEditButtonProps> = props => {
  const { onClickEdit } = props;

  return (
    <LinkButton
      className={actionStepCardEditBlock('button')}
      onClick={onClickEdit}
    >
      <span className={actionStepCardEditBlock('text')}>
        <Edit
          className={actionStepCardEditBlock('icon')}
          height={14}
          width={14}
        />{' '}
        Edit
      </span>
    </LinkButton>
  );
};

export default ActionStepCardEditButton;
