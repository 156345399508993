import * as React from 'react';

import ConnectedEditAssetModal from 'containers/ConnectedModal/ConnectedEditAssetModal';
import EditAudioModalContents from './EditAudioModalContents';
import { block } from './utils';

export interface EditAudioModalProps {}

const EditAudioModal: React.FC<EditAudioModalProps> = () => {
  return (
    <ConnectedEditAssetModal
      className={block({ default: true })}
      backdrop="static"
      name="EditAudioModal"
      title="Audio Properties"
    >
      <EditAudioModalContents />
    </ConnectedEditAssetModal>
  );
};

export default EditAudioModal;
