import * as React from 'react';
import _ from 'underscore';

import { SelectField, SelectFieldProps } from 'components/Form';
import { getWaveOptionDisplayNameByValue } from 'redux/modules/display-pref/utils';
import { SoundwaveOption } from 'types';

interface IProps extends SelectFieldProps {
  labelClassName?: string;
  value: string;
  onChange?: (val: string, e: React.ChangeEvent<HTMLSelectElement>) => void;
  defaultValue?: string;
  locked?: boolean;
  options?: SoundwaveOption[];
}

export const WaveGenerationInput: React.FC<IProps> = ({
  disabled,
  labelClassName,
  onChange,
  value,
  defaultValue,
  locked,
  options,
  ...props
}) => {
  const commonProps = {
    disabled: disabled || locked,
    horizontal: {
      control: { xs: 7 },
      label: { xs: 5 },
    },
    label: 'Wave Generation',
  };

  return (
    <SelectField
      {...props}
      {...commonProps}
      onChange={onChange}
      value={`${value}`}
      variant={locked ? 'locked' : 'default'}
      defaultValueText={getWaveOptionDisplayNameByValue(options, defaultValue)}
    >
      {options.map((option: SoundwaveOption) => (
        <option key={option.value} value={option.value}>
          {option.displayName}
        </option>
      ))}
    </SelectField>
  );
};

WaveGenerationInput.defaultProps = {
  defaultValue: undefined,
  locked: false,
  onChange: _.noop,
  options: [],
};

export { IProps as WaveGenerationInputProps };
export default WaveGenerationInput;
