import React from 'react';
import { FacebookSocialCircle } from 'components/SocialCircle';
import SocialButton, { SocialButtonProps } from './SocialButton';

export interface FacebookButtonProps
  extends Pick<SocialButtonProps, 'disabled' | 'onClick' | 'params' | 'size'> {}

const FacebookButton: React.FC<FacebookButtonProps> = ({
  disabled,
  onClick,
  size,
}) => (
  <SocialButton
    icon={<FacebookSocialCircle />}
    label="Facebook"
    {...{ disabled, onClick, size }}
  />
);

export default FacebookButton;
