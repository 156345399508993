import * as React from 'react';

import Modal from 'components/Modal';
import { ConnectedModalInjectedProps } from 'containers/ConnectedModal/ConnectedModalComponent';
import VideoExportLink from 'containers/VideoExportLink';
import { block } from '../utils';

export type ExportCompleteModalContentsProps = ConnectedModalInjectedProps;

const ExportCompleteModalContents: React.SFC<ExportCompleteModalContentsProps> = ({
  onHide,
}) => (
  <>
    <Modal.Body>
      <div className={block('result')}>
        <span className={block('primary-text')}>
          Your video has been generated.
        </span>
        <span className={block('secondary-text')}>
          Download it <VideoExportLink className={block('link')} />. To generate
          another video, make a change to the project, save it, and try again.
        </span>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Modal.FooterButtons>
        <Modal.FooterButton onClick={onHide}>cancel</Modal.FooterButton>
      </Modal.FooterButtons>
    </Modal.Footer>
  </>
);

export default ExportCompleteModalContents;
