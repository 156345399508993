import * as React from 'react';
import { noop } from 'underscore';

import Popper from 'components/Popper/Popper';
import PopperToggle from 'components/Popper/PopperToggle';
import bem from 'utils/bem';
import { WaveformFidelity } from '../types';

export interface WaveformFidelityPopperProps {
  onChange?: (value: WaveformFidelity) => void;
  value: WaveformFidelity;
}

const block = bem('waveform-fidelity-popper');

const WaveformFidelityPopper: React.FC<WaveformFidelityPopperProps> = ({
  onChange = noop,
  value,
}) => (
  <Popper className={block()} title="waveform generation">
    <PopperToggle
      options={[
        { description: 'Less accurate but exports quicker', value: 'lo-fi' },
        { description: 'More accurate but slower to export', value: 'hi-fi' },
      ]}
      onChange={onChange}
      value={value}
    />
  </Popper>
);

export default WaveformFidelityPopper;
