import React from 'react';

import { useDispatch } from 'react-redux';
import { IntroOutro } from 'components/icons';
import { onClickProFeature } from 'redux/modules/mixpanel';
import { pushModal } from 'redux/modules/modal';
import { RefType } from 'types';

import { useEditorState } from '../context/VideoEditorStateContext';
import { OPTION_TILE_FEATURE_PATHS } from '../state/constants';
import AccessControlOptionTile, {
  AccessControlOptionTileProps,
} from './AccessControlOptionTile';
import ProGatedTile from './ProGatedTile';

export type IntroOutroTileProps<P = unknown> = Pick<
  AccessControlOptionTileProps<P>,
  'className' | 'onClick' | 'params'
>;

const IntroOutroTile = React.forwardRef(
  <P extends {} = unknown>(
    props: IntroOutroTileProps<P>,
    ref: RefType<typeof AccessControlOptionTile>,
  ) => {
    const { className, onClick, params } = props;

    const dispatch = useDispatch();
    const { disabled } = useEditorState();

    const handleUpgrade = React.useCallback(() => {
      dispatch(onClickProFeature({ from: 'AddIntroOutroInUCS' }));
      dispatch(pushModal({ name: 'IntroOutroUpsell' }));
    }, [dispatch]);

    return (
      <ProGatedTile
        ref={ref}
        disabled={disabled}
        onClickFeature={onClick}
        onUpgrade={handleUpgrade}
        tileBaseClassName={className}
      >
        {({ proDisabled, proClickHandler, tileClassName }) => {
          return (
            <AccessControlOptionTile
              className={tileClassName}
              disabled={disabled}
              featurePath={OPTION_TILE_FEATURE_PATHS.introOutro}
              icon={<IntroOutro height={40} width="auto" />}
              id="templates-intro-outro-tile"
              label="intro / outro"
              onClick={proClickHandler}
              params={params}
              showProLabel={proDisabled}
            />
          );
        }}
      </ProGatedTile>
    );
  },
);

export default IntroOutroTile;
