import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import { useDispatch } from 'react-redux';

import DialogContent from 'components/DialogContent';
import { H2 } from 'components/Heading';
import LoadingOverlay from 'components/LoadingOverlay';
import ModalFooterButton from 'components/Modal/ModalFooterButton';
import SocialAccountInfo from 'components/SocialAccountInfo';
import { Step } from 'components/SteppedModal';
import { showError } from 'redux/modules/notification';
import { validateYoutubeAccountElegibility } from 'redux/modules/social';
import { Dispatch } from 'redux/types';

import useYoutubePostModalSelector from '../useYoutubePostModalSelector';
import { block } from '../utils';

export interface UseVerificationStepConfig {
  onAccountDisallowed: () => void;
  onAccountVerified: () => void;
  onSwitchAccount: () => void;
}

const useVerificationStep = (config: UseVerificationStepConfig): Step => {
  const { onAccountDisallowed, onAccountVerified, onSwitchAccount } = config;

  const [verifying, setVerifying] = React.useState(false);

  const dispatch = useDispatch<Dispatch>();

  const handleVerifyAccount = React.useCallback(async () => {
    setVerifying(true);
    try {
      const longUploadsStatus = await dispatch(
        validateYoutubeAccountElegibility(),
      );

      // In order to avoid running the same validation flow which might replace the
      // current modal, the verification flow is resolved at this step.
      // The only possible transitions from elegible are allowed or disallowed, any
      // other transition is ignored
      switch (longUploadsStatus) {
        case 'allowed':
          onAccountVerified();
          break;
        case 'disallowed':
          onAccountDisallowed();
          break;
        default:
          break;
      }
    } catch {
      dispatch(
        showError({
          message: 'YouTube account verification failed',
          code: 'IN019',
        }),
      );
    } finally {
      setVerifying(false);
    }
  }, [dispatch, onAccountDisallowed, onAccountVerified]);

  const { avatarSrc, username } = useYoutubePostModalSelector();

  return {
    title: 'awaiting verification',
    modalClassName: block('dialog-content'),
    component: (
      <>
        {verifying && <LoadingOverlay small title="Verifying..." />}
        <DialogContent
          className={block('dialog')}
          details={
            <H2>
              After verification we can continue getting ready for YouTube.
            </H2>
          }
          detailsClassName={block('dialog-details')}
          headerRow={
            <Spacer>
              <SocialAccountInfo
                avatarSrc={avatarSrc}
                onSwitchAccountClick={onSwitchAccount}
                username={username}
              />
            </Spacer>
          }
          onClose={() => null}
          title="Tell us when you’re verified"
          titleClassName={block('dialog-title')}
        />
      </>
    ),
    fluidFooterButtons: true,
    id: 'verify-account',
    renderFooterButtons: ({ submit }) => [
      <ModalFooterButton
        {...submit}
        disabled={verifying}
        key="submit"
        onClick={handleVerifyAccount}
        size="large"
        theme="submit"
      >
        my account is now verified
      </ModalFooterButton>,
    ],
  };
};

export default useVerificationStep;
