import { useState } from 'react';
import { isUndefined } from 'underscore';

export default function useAudioSource() {
  const [file, setFile] = useState<File>();
  const [remoteFileUrl, setRemoteFileUrl] = useState<string>();

  const setAudioSource = (src?: File | string) => {
    if (isUndefined(src)) {
      setFile(undefined);
      setRemoteFileUrl(undefined);
    } else if (src instanceof File) {
      setRemoteFileUrl(undefined);
      setFile(src);
    } else {
      setFile(undefined);
      setRemoteFileUrl(src);
    }
  };

  return {
    setAudioSource,
    audioSource: file || remoteFileUrl,
  };
}
