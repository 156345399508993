import * as React from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'underscore';
import UseAuthModal from 'blocks/Authentication/containers/UseAuthModal';
import Button from 'components/Button';
import { loggedInSelector } from 'redux/modules/auth/selectors';
import * as routeUtils from 'utils/routes';
import { block } from '../utils';

const AuthButtons: React.FC = () => {
  const loggedIn = useSelector(loggedInSelector);

  // auth buttons only available on download pages
  if (!routeUtils.isDownloadActive()) {
    return null;
  }

  return (
    <UseAuthModal origin="downloadPage">
      {({ withAuthentication }) =>
        loggedIn ? null : (
          <>
            <Button
              theme="submit-alt"
              size="medium"
              className={block('button')}
              onClick={withAuthentication(noop)}
            >
              Log in
            </Button>

            <Button
              theme="submit"
              size="medium"
              className={block('button')}
              onClick={withAuthentication(noop, 'sign-up')}
            >
              Sign up
            </Button>
          </>
        )
      }
    </UseAuthModal>
  );
};

export default AuthButtons;
