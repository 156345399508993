import React from 'react';
import { noop } from 'underscore';

import { FontFamilyProIndicatorStore } from './FontFamilyProIndicatorTypes';

export const FontFamilyProIndicatorContext = React.createContext<
  FontFamilyProIndicatorStore
>({
  closeListeners: React.createRef(),
  registerCloseFn: noop,
  unregisterCloseFn: noop,
});

export const useFontFamilyProIndicator = (
  id: string,
  onCloseParentModal: () => void,
) => {
  const { registerCloseFn, unregisterCloseFn } = React.useContext(
    FontFamilyProIndicatorContext,
  );

  React.useEffect(() => {
    registerCloseFn(id, onCloseParentModal);
    return () => unregisterCloseFn(id);
  }, [id, onCloseParentModal, registerCloseFn, unregisterCloseFn]);
};

export const useFontFamilyProIndicatorStore = () => {
  const { closeListeners } = React.useContext(FontFamilyProIndicatorContext);

  const hideParentModal = React.useCallback(() => {
    Object.values(closeListeners.current).forEach(listener => {
      if (listener) {
        listener();
      }
    });
  }, [closeListeners]);

  return {
    hideParentModal,
  };
};

const FontFamilyProIndicatorProvider: React.FunctionComponent = props => {
  const { children } = props;

  const closeListeners = React.useRef({});

  const registerModalCloseFn = React.useCallback(
    (id: string, closeCb: () => void) => {
      closeListeners.current[id] = closeCb;
    },
    [],
  );

  const unregisterModalCloseFn = React.useCallback((id: string) => {
    if (closeListeners.current[id]) {
      closeListeners.current[id] = undefined;
    }
  }, []);

  return (
    <FontFamilyProIndicatorContext.Provider
      value={{
        closeListeners,
        registerCloseFn: registerModalCloseFn,
        unregisterCloseFn: unregisterModalCloseFn,
      }}
    >
      {children}
    </FontFamilyProIndicatorContext.Provider>
  );
};

export default FontFamilyProIndicatorProvider;
