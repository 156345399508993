import * as React from 'react';
import { connect } from 'react-redux';

import { uploadEntireAudio } from 'redux/modules/audiogram-wizard';
import { Dispatch } from 'redux/types';
import UseAudioUpload, {
  UseAudioUploadProps as Props,
} from '../UseAudioUpload';

type DispatchProps = Pick<Props, 'onAudioAdded'>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  // TODO AddAudioStep for some reason calls onAudioAdded(undefined, undefined)
  // figure out if this can be changed to something more intuitive that doesn't
  // require checking if src is defined when the name of the fn implies that it
  // should be
  onAudioAdded: (src, metadata) => {
    if (src) {
      return dispatch(uploadEntireAudio(src, metadata));
    }
    return Promise.resolve();
  },
});

const component = connect(null, mapDispatchToProps)(UseAudioUpload);
export type UseAudioUploadProps = React.ComponentPropsWithoutRef<
  typeof component
>;
export default component;
