import * as React from 'react';

import Button, { ButtonProps } from 'components/Button';
import Tooltip from 'components/Tooltip';
import { Omit } from 'types';

type PickedButtonProps = Omit<ButtonProps, 'className' | 'theme' | 'shape'>;

export interface ExportButtonProps extends PickedButtonProps {
  renderMaxDurationExceededTooltip?: boolean;
}

export default class ExportButton extends React.PureComponent<
  ExportButtonProps
> {
  public static defaultProps: Partial<ExportButtonProps> = {
    renderMaxDurationExceededTooltip: false,
  };

  public render() {
    const { renderMaxDurationExceededTooltip, ...props } = this.props;
    return (
      <div className="playbar__export">
        {renderMaxDurationExceededTooltip && (
          <Tooltip
            id="export-max-duration-exceeded-tooltip"
            content="We currently only support videos under 10 minutes. Please shorten your video to export"
            placement="top"
          >
            {/*
             * a disabled button does not fire any events, meaning it will not
             * respond to hover and show the tooltip.  this "target" element is
             * placed on top of the button to capture the mouse hover and show
             * the tooltip
             */}
            <div className="playbar__export-tooltip-target" />
          </Tooltip>
        )}
        <Button
          className="playbar__export-button"
          shape="square"
          theme="submit"
          {...props}
        >
          Export
        </Button>
      </div>
    );
  }
}
