import cn from 'classnames';
import React from 'react';
import InputLabel from 'components/Form/InputLabel';
import Tooltip from 'components/Tooltip';
import { block } from './utils';

export interface ShareFormFieldProps {
  children?: React.ReactNode;
  className?: string;
  label?: React.ReactNode;
  labelHint?: React.ReactNode;
  helperText?: React.ReactNode;
  showTooltip?: boolean;
  tooltip?: React.ReactNode;
}

const ShareFormField: React.FC<ShareFormFieldProps> = ({
  children,
  className,
  label,
  labelHint,
  helperText,
  showTooltip,
  tooltip,
}) => {
  const hasLabelOrHelper = !!(label || helperText);

  return (
    <div className={cn(block('field'), className)}>
      {hasLabelOrHelper && (
        <div className={block('text-container')}>
          {label && (
            <InputLabel className={block('label')}>
              {label}
              {labelHint && (
                <span className={block('label-hint')}> {labelHint}</span>
              )}
            </InputLabel>
          )}
          {helperText && <span className={block('helper')}>{helperText}</span>}
        </div>
      )}
      {!tooltip ? (
        children
      ) : (
        <Tooltip
          content={tooltip}
          placement="top"
          show={showTooltip ? undefined : false}
        >
          {children}
        </Tooltip>
      )}
    </div>
  );
};

export default ShareFormField;
