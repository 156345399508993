import * as React from 'react';

import CircularGradientGift from 'components/icons/circular-gradient/CircularGradientGift';
import BannerPage, { BannerPageHeader } from '../../components/BannerPage';
import EmailInvitationForm from './EmailInvitationForm';
import ReferralsFeaturesBox from './ReferralsFeaturesBox';
import UrlInvitationForm from './UrlInvitationForm';
import { block } from './utils';

const { useEffect, useRef } = React;

const ReferralsPage: React.SFC = () => {
  const emailInput = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    emailInput.current.focus();
  }, []);

  return (
    <BannerPage
      className={block()}
      backgroundClassName={block('background')}
      backgroundImageSrc="https://static.sparemin.com/static/webapp-assets/referrals_bg_20190703.jpg"
    >
      <BannerPageHeader
        icon={<CircularGradientGift className={block('icon')} />}
        subtitle="Invite friends & when they join everyone gets Pro for free!"
        title="Bring your friends"
      />
      <div className={block('body')}>
        <div className={block('body-left')}>
          <ReferralsFeaturesBox />
        </div>

        <div className={block('body-right')}>
          <EmailInvitationForm ref={emailInput} />
          <UrlInvitationForm />
        </div>
      </div>
    </BannerPage>
  );
};

export default ReferralsPage;
