import * as React from 'react';

import { useDispatch } from 'react-redux';
import { onClickGettingStartedGuide } from 'redux/modules/mixpanel/actions';
import { setLiveChatEmail, showLiveChatWidget } from 'utils/helpdesk';
import DropdownMenuContainer from '../DropdownMenuContainer';
import DropdownMenuItem from '../DropdownMenuItem';
import { blockNavigation } from '../utils';
import HelpIcon from './HelpIcon';

interface IProps {
  loggedInUserEmail: string;
}

const HelpDropdown: React.SFC<IProps> = ({ loggedInUserEmail }) => {
  const dispatch = useDispatch();

  const handleClickLiveChat = () => {
    setLiveChatEmail(loggedInUserEmail);
    showLiveChatWidget();
  };

  const handleClickStartGuide = () => {
    dispatch(onClickGettingStartedGuide());
  };

  return (
    <DropdownMenuContainer
      icon={<HelpIcon bsRole="toggle" />}
      id="help-nav-dropdown"
      className={blockNavigation('dropdown', { help: true })}
    >
      <DropdownMenuItem
        label="Getting Started Guide"
        iconName="map"
        href="https://learn.headliner.app/hc/en-us/articles/360010534274-Getting-Started-Guide"
        target="_blank"
        onClick={handleClickStartGuide}
      />

      <DropdownMenuItem
        label="Contact Support"
        iconName="comment-dots"
        onClick={handleClickLiveChat}
      />

      <DropdownMenuItem
        label="Knowledge Base"
        iconName="graduation-cap"
        href="https://learn.headliner.app/hc/en-us"
        target="_blank"
      />
    </DropdownMenuContainer>
  );
};

export default HelpDropdown;
