import cn from 'classnames';
import * as React from 'react';

import { Row } from 'components/Grid';

interface IProps {
  children?: React.ReactNode;
  className?: string;
}

const ModalBodyRow: React.SFC<IProps> = ({ children, className }) => (
  <Row className={cn('hl-modal__row', className)} divider>
    {children}
  </Row>
);

export default ModalBodyRow;
export { IProps as ModalBodyRowProps };
