import cn from 'classnames';
import React from 'react';
import { episodeListBlock as block } from '../../../utils';

export interface EpisodeRowActionButtonIconProps {
  children?: React.ReactNode;
}

const EpisodeRowActionButtonIcon: React.FC<EpisodeRowActionButtonIconProps> = ({
  children,
}) =>
  React.isValidElement(children) &&
  React.cloneElement(children, {
    className: cn(
      block('episode-action-button-icon'),
      children.props.className,
    ),
  });

export default EpisodeRowActionButtonIcon;
