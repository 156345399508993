import * as React from 'react';
import Context from './ToggleButtonGroupContext';
import { block } from './utils';

interface Props<T extends string> {
  onChange: (value: T) => void;
  value: T;
  id: string;
}

function ToggleButtonGroup<T extends string>(
  props: React.PropsWithChildren<Props<T>>,
) {
  const { children, value, onChange, id } = props;

  return (
    <Context.Provider value={{ id, value, onChange }}>
      <div role="group" className={block()}>
        {children}
      </div>
    </Context.Provider>
  );
}

export default ToggleButtonGroup;
