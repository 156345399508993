import * as React from 'react';
import { isString } from 'underscore';

import { H3 } from 'components/Heading';
import { block } from './utils';

export interface RadioOptionLabelProps {
  children?: React.ReactChild;
}

const RadioOptionLabel: React.SFC<RadioOptionLabelProps> = ({ children }) => {
  if (!children) {
    return null;
  }

  return isString(children) ? (
    <H3 className={block('label')}>{children}</H3>
  ) : (
    <>{children}</>
  );
};

export default RadioOptionLabel;
