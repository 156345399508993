import { Record, RecordOf } from 'immutable';
import { partial } from 'underscore';
import { omitUndefined } from 'utils/collections';

import { withValue } from 'utils/control';
import { reviveRecord } from 'utils/immutable';
import { stateFactory as entireAudioFactory } from '../../factories/entire-audio';
import { IState } from './types';

export const stateFactory = Record<IState>({
  entireAudio: undefined,
});

export function stateFromJs({ entireAudio, ...rest }: any = {}): RecordOf<
  IState
> {
  const audio = withValue(
    entireAudio,
    partial(reviveRecord, entireAudioFactory),
  );
  return reviveRecord(
    stateFactory,
    omitUndefined({
      entireAudio: audio,
      ...rest,
    }),
  );
}
