import { useCallback, useState } from 'react';

import { canMoveTrack } from 'blocks/Timeline/utils';
import {
  TimelineContext,
  TrackLabelDragItem,
  TrackLabelProps,
  TrackLabelsProps,
} from '../../types';

interface MoveTrackState {
  insertBeforeIndex: number;
  isValidMove: boolean;
}

type Config = Pick<TrackLabelsProps, 'onTrackMove'> &
  Pick<TimelineContext, 'tracks'>;

export default function useMoveTrackLabel({ tracks, onTrackMove }: Config) {
  const [dragItem, setDragItem] = useState<TrackLabelDragItem>(null);

  const [moveTrackState, setMoveTrackState] = useState<MoveTrackState>({
    insertBeforeIndex: undefined,
    isValidMove: undefined,
  });

  const onDragStart = useCallback((item: TrackLabelDragItem) => {
    setDragItem(item);
  }, []);

  const onDragStop = useCallback(() => {
    setMoveTrackState({
      insertBeforeIndex: undefined,
      isValidMove: undefined,
    });
    setDragItem(null);
  }, []);

  const onTrackMoveIntent: TrackLabelProps['onTrackMoveIntent'] = useCallback(
    (item, hoverIndex, insertionPoint) => {
      // user intends to move track to a location between hoverIndex and this index
      const adjacentIndex =
        insertionPoint === 'before' ? hoverIndex - 1 : hoverIndex + 1;

      // user intends to move track between these two configs
      const adjacentConfig = tracks[adjacentIndex];
      const hoverConfig = tracks[hoverIndex];

      // config of the track label the user is moving
      const movingConfig = tracks[item.index];

      // if the user is hovering over the same track being moved or the adjacent
      // track is the one being moved, the user shouldn't see any drop indicator
      if (
        hoverConfig.id === movingConfig.id ||
        adjacentConfig?.id === movingConfig.id
      ) {
        setMoveTrackState({
          insertBeforeIndex: undefined,
          isValidMove: undefined,
        });
        return;
      }

      const insertBeforeIndex =
        hoverIndex + (insertionPoint === 'before' ? 0 : 1);

      const state: MoveTrackState = {
        insertBeforeIndex,
        isValidMove: false,
      };

      state.isValidMove = canMoveTrack(tracks, insertBeforeIndex, item.index);

      setMoveTrackState(state);
    },
    [tracks],
  );

  const onTrackDrop = useCallback(
    (item: TrackLabelDragItem) => {
      if (moveTrackState.isValidMove) {
        onTrackMove(item.id, moveTrackState.insertBeforeIndex - 1);
      }
    },
    [moveTrackState.insertBeforeIndex, moveTrackState.isValidMove, onTrackMove],
  );

  return {
    dragItem,
    moveTrackState,
    onDragStart,
    onDragStop,
    onTrackDrop,
    onTrackMoveIntent,
  };
}
