import React from 'react';

import { useCaptionsAssetAdjust } from 'components/VideoTemplateEditor/assets/CaptionsAsset';
import { CAPTION_STYLE_FIT_TEXT } from 'utils/constants';

import Layer from '../../layers/Layer';
import { CaptionsState, ToPx } from '../../types';
import { editorModalPreviewBlock as block } from '../../utils';
import { getCaptionsLayerStyle } from './utils';

export interface CaptionsLayerProps {
  canvasSize: { height: number; width: number };
  captionsOverride: CaptionsState;
}

// This component is similar to the captions layer used at the UCS preview,
// it was created as a separated component because the preview one is going
// to be converted into a draggable/resizeable asset in future wheres this
// one need to be kept static.
const CaptionsLayer: React.FC<CaptionsLayerProps> = props => {
  const { canvasSize, captionsOverride } = props;

  const { truncatedTextValue } = useCaptionsAssetAdjust({
    captionsState: captionsOverride,
    textValue: CAPTION_STYLE_FIT_TEXT,
  });

  const toPx: ToPx = React.useCallback(
    value => {
      return value?.toUnit('px', canvasSize);
    },
    [canvasSize],
  );

  return (
    <Layer className={block('text-layer')}>
      <span style={getCaptionsLayerStyle(captionsOverride, toPx)}>
        <span style={captionsOverride.textStyle}>{truncatedTextValue}</span>
      </span>
    </Layer>
  );
};

export default CaptionsLayer;
