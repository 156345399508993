import { Button, Undo as UndoIcon } from '@sparemin/blockhead';
import React from 'react';

import { useDispatch } from 'react-redux';
import { waitForPodcastFeedRefresh } from 'redux/modules/podcast';
import { cmsBlock as block } from '../utils';

export interface RefreshFeedButtonProps {
  podcastId: string;
  isDisabled?: boolean;
}

const RefreshFeedButton: React.FC<RefreshFeedButtonProps> = ({
  podcastId,
  isDisabled,
}) => {
  const dispatch = useDispatch();

  const handleRefreshPodcastFeed = React.useCallback(async (): Promise<
    void
  > => {
    if (!podcastId) {
      return;
    }

    dispatch(waitForPodcastFeedRefresh(podcastId));
  }, [dispatch, podcastId]);

  return (
    <Button
      variant="solid"
      className={block('refresh-button')}
      onPress={handleRefreshPodcastFeed}
      isDisabled={isDisabled}
    >
      <UndoIcon className={block('refresh-icon')} />

      <span>Refresh feed</span>
    </Button>
  );
};

export default RefreshFeedButton;
