import cn from 'classnames';
import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import ToggleButton, { ToggleButtonProps } from './ToggleButton';
import { block } from './utils';

type Props = ToggleButtonProps;

const AlignLeftToggle: React.SFC<Props> = ({ className, ...buttonProps }) => (
  <ToggleButton
    className={cn(block('alignleft-toggle'), className)}
    {...buttonProps}
  >
    <FontAwesome icon="align-left" />
  </ToggleButton>
);

export default AlignLeftToggle;
export { Props as AlignLeftToggleProps };
