import * as React from 'react';
import usePlanEndDateString from '../usePlanEndDateString';
import BillingHistoryLink from './BillingHistoryLink';
import { BaseFreeSubscription } from './FreeSubscription';

export interface Props {}

const CancelledSubscription: React.FC<Props> = () => {
  const planEnd = usePlanEndDateString();

  return (
    <BaseFreeSubscription
      message={`Your plan will end on ${planEnd}`}
      subMessage={
        <span>
          You cancelled your plan but can easily reactivate below! Also{' '}
          <BillingHistoryLink>here is your billing history</BillingHistoryLink>.
        </span>
      }
    />
  );
};

export default CancelledSubscription;
