import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import EditAutomationOutputModalContents from './EditAutomationOutputModalContents';

const EditAutomationOutputModal: React.FunctionComponent = () => {
  return (
    <ConnectedModal
      backdrop="static"
      name="EditAutomationOutputModal"
      title="Edit output"
    >
      <EditAutomationOutputModalContents />
    </ConnectedModal>
  );
};

export default EditAutomationOutputModal;
