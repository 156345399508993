import * as React from 'react';

import { IconProps } from './types';

const Callout: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228 168" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <g>
          <g>
            <path
              d="M10 0h208c5.523 0 10 4.477 10 10v133c0 5.523-4.477 10-10 10h-89l-15 15-15-15H10c-5.523 0-10-4.477-10-10V10C0 4.477 4.477 0 10 0z"
              transform="translate(-1007 -235) translate(750 39) translate(257 196)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Callout;
