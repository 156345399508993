import * as React from 'react';

import Button, { ButtonProps } from 'components/Button';

export type UserGeneratedEmptyButtonProps = ButtonProps;

const UserGeneratedEmptyButton: React.SFC<UserGeneratedEmptyButtonProps> = ({
  className,
  ...props
}) => <Button className={className} size="large" {...props} />;

export default UserGeneratedEmptyButton;
