import {
  Container,
  GradientIcon,
  Heading,
  Spacer,
  Text,
} from '@sparemin/blockhead';
import React from 'react';
import { Alert, ClosedCaptioning } from 'components/icons';
import { captionsViewBlock as block } from '../utils';

export interface AutoVideoChildViewTopSlotProps {}

const AutoVideoChildViewTopSlot: React.FC<AutoVideoChildViewTopSlotProps> = () => {
  return (
    <Spacer orientation="vertical" space="30px" align="center" justify="center">
      <div className={block('captions-icon-container')}>
        <GradientIcon>
          <ClosedCaptioning width="40px" height="30px" color="#282e38" />
        </GradientIcon>
      </div>

      <Heading level={1} className={block('captions-heading')}>
        Captions
      </Heading>

      <Container className={block('captions-disclaimer-container')}>
        <Alert
          fill="#8ca0c3"
          background="transparent"
          className={block('captions-disclaimer-icon')}
        />

        <Text variant="label" className={block('captions-disclaimer-text')}>
          Editing captions in automations is not supported yet, but it will be
          soon!
        </Text>
      </Container>
    </Spacer>
  );
};

export default AutoVideoChildViewTopSlot;
