import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import DialogContent from 'components/DialogContent';
import { H2 } from 'components/Heading';
import { Refresh } from 'components/icons';
import Modal from 'components/Modal';
import useLoadTemplateList from 'containers/ProjectWizards/useLoadTemplateList';
import { hideModal } from 'redux/modules/modal';
import { AspectRatioName } from 'types';
import { block } from './utils';

interface RefreshTemplateListModalContentsProps {
  aspectRatioName?: AspectRatioName;
}

const RefreshTemplateListModalContents: React.FC<RefreshTemplateListModalContentsProps> = props => {
  const { aspectRatioName } = props;
  const { loadTemplateList } = useLoadTemplateList();
  const dispatch = useDispatch();

  const handleRefreshTemplateList = useCallback((): void => {
    loadTemplateList(aspectRatioName);
    dispatch(hideModal());
  }, [aspectRatioName, dispatch, loadTemplateList]);

  return (
    <>
      <DialogContent
        className={block()}
        detailsClassName={block('details')}
        title="Let us know when you’re done"
        details={
          <H2>
            Once you’ve saved the changes to your template, just click the
            button below to refresh your template list. This will allow your new
            template to display here so you can select it!
          </H2>
        }
        icon={
          <Refresh height={100} width={100} fill="#646E82" stroke="#ffffff" />
        }
        onClose={() => null}
      />

      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton
            onClick={handleRefreshTemplateList}
            theme="submit"
          >
            Refresh template list
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default RefreshTemplateListModalContents;
