import * as React from 'react';

import { Omit } from 'types';
import TextBoxNumericField, {
  TextBoxNumericFieldProps,
} from './TextBoxNumericField';

type Props = Omit<TextBoxNumericFieldProps, 'label'>;

const LineSpacingField: React.SFC<Props> = props => (
  <TextBoxNumericField
    {...props}
    label="Line Spacing"
    size={3}
    maxLength={3}
    min={-9.99}
    max={9.99}
    step={0.01}
  />
);

export default LineSpacingField;
export { Props as LineSpacingFieldProps };
