import { useDelay, useToggle } from '@sparemin/blockhead';
import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import ConnectedModal from 'containers/ConnectedModal';
import { hideModal } from 'redux/modules/modal';
import EddyPromoModalContents from './EddyPromoModalContents';
import { block } from './utils';

interface EddyPromoModalProps {}

const EddyPromoModal: React.FC<EddyPromoModalProps> = () => {
  const { value: isSubmitting, toggleOn, toggleOff } = useToggle(false);

  const dispatch = useDispatch();

  const [delayedModalClose] = useDelay(() => dispatch(hideModal(true)), 3000);

  const handleOnSubmit = useCallback((): void => {
    toggleOn();

    // After submission, the modal should automatically
    // close after 3 seconds.
    delayedModalClose();
  }, [delayedModalClose, toggleOn]);

  return (
    <ConnectedModal
      name="EddyPromo"
      title="Headliner AI"
      className={block()}
      closeButton={!isSubmitting}
      onHide={toggleOff}
      onExited={toggleOff}
    >
      <EddyPromoModalContents
        isSubmitting={isSubmitting}
        onSubmit={handleOnSubmit}
      />
    </ConnectedModal>
  );
};

export default EddyPromoModal;
