import * as React from 'react';

import { block } from '../utils';

interface IProps {
  children?: React.ReactChild;
  label: string;
}

const TextShadowField: React.SFC<IProps> = ({ label, children }) => (
  <div className={block('text-shadow-field')}>
    <div className={block('input-label')}>{label}</div>
    {children}
  </div>
);

export default TextShadowField;
export { IProps as TextShadowFieldProps };
