import * as React from 'react';

import { IconProps } from './types';

const VideoCameraAlt3: React.FC<IconProps> = props => (
  <svg
    width="50"
    height="33"
    viewBox="0 0 50 33"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.184 0H4.149C1.858 0 0 1.84 0 4.108v24.784C0 31.161 1.858 33 4.15 33h25.034c2.292 0 4.15-1.84 4.15-4.108V4.108c0-2.269-1.858-4.108-4.15-4.108zm16.33 3.492c1.849-1.254 4.375.035 4.375 2.218v22.077c0 2.191-2.535 3.472-4.375 2.217L36 23.516V9.989zm-26.09 2.832a1.206 1.206 0 0 1 1.56-.073c.472.36.644 1.017.413 1.579l-3.004 7.362h4.356c.523 0 .988.34 1.171.849a1.358 1.358 0 0 1-.347 1.447l-10 9.188a1.206 1.206 0 0 1-1.558.073 1.352 1.352 0 0 1-.414-1.579l3.004-7.358H10.25a1.25 1.25 0 0 1-1.172-.849 1.37 1.37 0 0 1 .348-1.451z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default VideoCameraAlt3;
