import { Replace } from 'types';
import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'RECORDING_UPLOAD_SERVICE';
export const ACTION_KEY: ActionKey = 'RECORDING_UPLOAD_SERVICE';

export enum ServiceMethod {
  UPLOAD_RECORDING = 'UPLOAD_RECORDING',
  UPLOAD_AUDIO = 'UPLOAD_AUDIO',
  GET_AUDIO_UPLOAD = 'GET_AUDIO_UPLOAD',
  CLIP_AUDIO = 'CLIP_AUDIO',
  GET_AUDIO_CLIP = 'GET_AUDIO_CLIP',
  GET_ENTIRE_AUDIOS = 'GET_ENTIRE_AUDIOS',
}

export type UploadRecordingArgs = [
  File | string, // src
  string, // direct upload bucket
  string, // direct upload key
  string, // language
  string, // wave generation
];
export interface UploadRecordingResult {
  recordingId: number;
  recordingUploadId: number;
}

export interface EntireAudio {
  id: number;
  status: 'uploading' | 'complete' | 'failed';
  url?: string;
  waveform: {
    status: 'queued' | 'processing' | 'completed' | 'error' | 'errorAck';
    url?: string;
  };
  standardized: {
    status:
      | 'queued'
      | 'processing'
      | 'completed'
      | 'skipStandardization'
      | 'error'
      | 'errorAck';
    url?: string;
  };
}

export interface EntireAudioInstance {
  id: number;
  ownerId: number;
  entireAudio: EntireAudio;
}

interface NormalizedEntireAudio {
  entities: {
    entireAudioInstances: {
      [id: number]: Replace<EntireAudioInstance, { entireAudio: number }>;
    };
    entireAudios: {
      [id: number]: EntireAudio;
    };
  };
  result: number;
}

type NormalizedEntireAudios = Omit<NormalizedEntireAudio, 'result'> & {
  result: number[];
};

interface EntireAudioClip {
  entireAudioInstance: EntireAudioInstance;
  trimStartMillis: number;
  trimEndMillis: number;
}

interface NormalizedEntireAudioClip {
  entities: {
    entireAudios: {
      [id: number]: EntireAudio;
    };
    entireAudioInstances: {
      [id: number]: Replace<EntireAudioInstance, { entireAudio: number }>;
    };
    entireAudioClips: {
      [id: number]: Replace<EntireAudioClip, { entireAudioInstance: number }>;
    };
  };
  result: number;
}

export type UploadAudioArgs = [
  File | string, // source
  string, // md5
  string, // direct upload bucket
  string, // direct upload key
  string, // listen notes pocast id
  string, // listen notes episode id
  (progress: number) => void,
];
export type UploadAudioResult = NormalizedEntireAudio;

export type GetAudioUploadArgs = [number /* id */];
export type GetAudioUploadResult = NormalizedEntireAudio;

export type ClipAudioArgs = [
  number, // entire audio instance id
  number, // start millis
  number, // end millis
  string, // language
  string, // wave type
  boolean, // transcribe
];
export type ClipAudioResult = UploadRecordingResult;

export type GetAudioClipArgs = [number /* recordingId */];
export type GetAudioClipResult = NormalizedEntireAudioClip;

export type GetEntireAudiosArgs = [string /* md5 of file */];
export type GetEntireAudiosResult = NormalizedEntireAudios;

export type IHandle = (
  method: ServiceMethod,
  args: any,
  token?: string,
  userId?: number,
) => Promise<any>;

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export type UploadRecordingAction = ApiAction<
  ServiceMethod.UPLOAD_RECORDING,
  UploadRecordingArgs
>;

export type UploadAudioAction = ApiAction<
  ServiceMethod.UPLOAD_AUDIO,
  UploadAudioArgs
>;

export type GetAudioUploadAction = ApiAction<
  ServiceMethod.GET_AUDIO_UPLOAD,
  GetAudioUploadArgs
>;

export type GetEntireAudioClipsAction = ApiAction<
  ServiceMethod.GET_AUDIO_CLIP,
  GetAudioClipArgs
>;
export type GetEntireAudioClipsResult = Promise<
  IApiResponse<GetAudioClipResult>
>;

export type ClipAudioAction = ApiAction<
  ServiceMethod.CLIP_AUDIO,
  ClipAudioArgs
>;

export type GetEntireAudiosAction = ApiAction<
  ServiceMethod.GET_ENTIRE_AUDIOS,
  GetEntireAudiosArgs
>;

export interface RecordingUploadServiceDispatch {
  (action: UploadRecordingAction): Promise<IApiResponse<UploadRecordingResult>>;
  (action: UploadAudioAction): Promise<IApiResponse<UploadAudioResult>>;
  (action: GetAudioUploadAction): Promise<IApiResponse<GetAudioUploadResult>>;
  (action: ClipAudioAction): Promise<IApiResponse<ClipAudioResult>>;
  (action: GetEntireAudioClipsAction): Promise<
    IApiResponse<GetAudioClipResult>
  >;
  (action: GetEntireAudiosAction): Promise<IApiResponse<GetEntireAudiosResult>>;
}
