import Promise from 'bluebird';
import { Set } from 'immutable';
import * as React from 'react';
import { isUndefined, noop } from 'underscore';

import { VideoEditorPlaybackTimeContext as VideoEditorPlaybackTimeContextType } from 'containers/VideoEditor/types';
import VideoEditorPlaybackTimeContext from 'containers/VideoEditor/VideoEditorPlaybackTimeContext';
import Audio, { AudioEvent, OnSeek, OnTimeupdate } from 'utils/audio';
import MediaControls from './MediaControls';
import { AudioClips } from './types';
import { clipsChanged, PLAYER_STEP_IN_SEC, updateClips } from './utils';

interface IProps {
  controls?: boolean;
  controlButtonClassName?: string;
  controlsClassName?: string;
  clips?: AudioClips;
  durationSeconds?: number;
  onPause?: () => void;
  onPlay?: () => void;
  onSeek?: OnSeek;
  onTimeupdate?: OnTimeupdate;
  playbackGroupClassName?: string;
  playing?: boolean;
  volumeGroupClassName?: string;
}

export default class WebAudioPlayer extends React.Component<IProps> {
  private previousContext: VideoEditorPlaybackTimeContextType;

  public static defaultProps: Partial<IProps> = {
    clips: {
      data: {},
      ids: Set([]),
    },
    controls: false,
    durationSeconds: 0,
    onPause: noop,
    onPlay: noop,
    onSeek: noop,
    onTimeupdate: noop,
    playing: false,
  };

  private audio: Audio;
  private createAudioPromise: Promise<Audio>;
  private playbackPositionSeconds: number = 0;

  constructor(props: IProps) {
    super(props);

    this.createAudioFromProps();
  }

  public componentDidMount() {
    this.previousContext = this.context;
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<IProps>,
    nextContext: VideoEditorPlaybackTimeContextType,
  ) {
    this.updateAudioFromProps(nextProps, nextContext);
  }

  public shouldComponentUpdate(nextProps: Readonly<IProps>) {
    const { controls: nextControls, playing: nextPlaying } = nextProps;
    const { controls, playing } = this.props;

    const controlsChanged = nextControls !== controls;
    const controlsEnabled = nextControls;

    /*
     * if controls were enabled (or weren't) and now are (or aren't), we need to re-render to make
     * them (dis)appear.  if controls didn't change and they're just disabled, no need to cause a
     * render
     */
    if (!controlsChanged && !controlsEnabled) {
      return false;
    }

    /*
     * if controls are enabled and "playing" state has changed, we need to re-render (at the very
     * least, the play/pause button will have to flip)
     */
    if (controlsEnabled && nextPlaying !== playing) {
      return true;
    }

    return false;
  }

  public componentDidUpdate() {
    this.previousContext = this.context;
  }

  public componentWillUnmount() {
    if (this.createAudioPromise) {
      this.createAudioPromise.cancel();
    }

    this.withAudio(audio => {
      if (audio.isPlaying) {
        this.pauseAudio();
      }
    });
  }

  private handleEnd = () => this.props.onPause();

  private handleSeek: OnSeek = data => {
    this.playbackPositionSeconds = data.seconds;
    this.props.onSeek(data);
  };

  private handleTimeupdate: OnTimeupdate = payload => {
    const { onTimeupdate } = this.props;
    this.playbackPositionSeconds = payload.seconds;
    onTimeupdate(payload);
  };

  private handleBackClick = () => this.withAudio(audio => audio.seek(0));

  private handleRewindClick = () =>
    this.withAudio(audio => {
      const rewindTime = this.playbackPositionSeconds - PLAYER_STEP_IN_SEC;
      this.props.onSeek({
        duration: audio.duration,
        seconds: Math.max(rewindTime, 0),
      });
    });

  private handleForwardClick = () =>
    this.withAudio(audio => {
      const { duration } = audio;
      const forwardTime = this.playbackPositionSeconds + PLAYER_STEP_IN_SEC;
      this.props.onSeek({
        duration,
        seconds: Math.min(forwardTime, duration),
      });
    });

  private handleEndClick = () =>
    this.withAudio(audio => {
      audio.seek(audio.duration);
    });

  private handlePauseClick = () => this.props.onPause();

  private handlePlayClick = () => this.props.onPlay();

  private handleVolumeChange = (val: number) =>
    this.withAudio(audio => {
      audio.setMasterVolume(val);
    });

  private updateAudioFromProps(
    props = this.props,
    context: VideoEditorPlaybackTimeContextType = this.context,
  ) {
    const { clips, durationSeconds, playing } = props;
    const { seekToSec: prevPosition } = this.previousContext;
    const { seekToSec: position } = context;

    /*
     * when this.audio is not set there are either no clips or the Audio.create promise hasn't
     * yet returned
     */
    this.withAudio(audio => {
      /*
       * if the clips in props does not match the clips used to initialize the audio object, destroy
       * the current audio object and create a new one with the updated clips
       */
      if (clipsChanged(audio, clips)) {
        this.createAudioFromProps(props);
      } else {
        if (!isUndefined(playing)) {
          if (playing && !audio.isPlaying) {
            this.playAudio();
          }

          if (!playing && audio.isPlaying) {
            this.pauseAudio();
          }
        }

        if (durationSeconds !== audio.duration) {
          audio.duration = durationSeconds;
        }

        /*
         * updateAudioFromProps is called from UNSAFE_componentWillReceiveProps as well as
         * creteAudioFromProps.   when called from componentWillRecieveProps, it could be have
         * been called for _any_ prop update.  just becuase position doesn't match
         * this.audio.getCurrentTime doesn't mean we should seek (this could lead to a lot of
         * unexpected results).  safest to compare the current prop position vs. the old prop
         * position and seek only if it changed.
         *
         * when this function is called from createAudioFromProps, the position will first be set
         * to the old audio object's position.  after that, this function is called and if the
         * prop positions don't match, we'll seek below.  this gives us the following semantics for
         * updating audio position after a new audio object is created:
         *
         *    if position prop from before creating audio matches position prop after creating
         *    audio, use the old audio object's position, otherwise use the position prop after
         *    creating the audio object.
         */
        if (position !== prevPosition) {
          audio.seek(position);
        }

        updateClips(audio, clips);
      }
    });
  }

  private createAudioFromProps(props: IProps = this.props) {
    const { clips, durationSeconds } = props;

    const currentTime = this.withAudio(audio => audio.getCurrentTime()) || 0;
    this.audio = undefined;

    this.createAudioPromise = (Audio.create(
      Object.values(clips.data),
      durationSeconds,
    ) as any) as Promise<Audio>;

    this.createAudioPromise.then(audio => {
      this.audio = audio;

      this.audio.seek(currentTime);
      this.registerCallbacks();
      this.updateAudioFromProps();
    });
  }

  private playAudio() {
    const { durationSeconds } = this.props;
    this.withAudio(audio => {
      if (this.playbackPositionSeconds >= durationSeconds) {
        audio.seek(0);
      }
      audio.play();
    });
  }

  private pauseAudio() {
    this.withAudio(audio => audio.pause());
  }

  private registerCallbacks() {
    this.withAudio(audio => {
      audio.on(AudioEvent.END, this.handleEnd);
      audio.on(AudioEvent.SEEK, this.handleSeek);
      audio.on(AudioEvent.TIMEUPDATE, this.handleTimeupdate);
    });
  }

  private withAudio<T>(fn: (audio: Audio) => T): T {
    if (this.audio) {
      return fn(this.audio);
    }
    return undefined;
  }

  public render() {
    const {
      controls,
      controlButtonClassName,
      controlsClassName,
      playbackGroupClassName,
      playing,
      volumeGroupClassName,
    } = this.props;

    if (!controls) return null;

    return (
      <MediaControls
        className={controlsClassName}
        buttonClassName={controlButtonClassName}
        onBackClick={this.handleBackClick}
        onRewindClick={this.handleRewindClick}
        onForwardClick={this.handleForwardClick}
        onEndClick={this.handleEndClick}
        onPlayClick={this.handlePlayClick}
        onPauseClick={this.handlePauseClick}
        onVolumeChange={this.handleVolumeChange}
        playing={playing}
        playbackGroupClassName={playbackGroupClassName}
        volumeGroupClassName={volumeGroupClassName}
      />
    );
  }
}

WebAudioPlayer.contextType = VideoEditorPlaybackTimeContext;

export { IProps as WebAudioPlayerProps };
