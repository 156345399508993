import * as React from 'react';
import { Col } from 'react-bootstrap';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const SlideOptionsField: React.SFC<IProps> = ({ children, className }) => (
  <Col sm={6} xs={12} className={className}>
    {children}
  </Col>
);

export default SlideOptionsField;
export { IProps as SlideOptionsFieldProps };
