import { memoize } from 'underscore';
import { ApplicationError } from './ApplicationError';
import { isSafari } from './device';

function getSource(src: File | string) {
  return src instanceof File ? URL.createObjectURL(src) : src;
}

export function createVideoElement(src: File | string) {
  if (src === undefined) {
    return undefined;
  }

  const video = document.createElement('video');
  video.volume = 0;

  return new Promise<HTMLVideoElement>((resolve, reject) => {
    const removeListeners = () => {
      video.removeEventListener('durationchange', handleDurationChange);
      video.removeEventListener('error', handleError);
    };

    const handleDurationChange = () => {
      resolve(video);
      removeListeners();
    };

    const handleError = () => {
      reject(video.error);
      removeListeners();
    };

    const loadSource = () => {
      video.src = getSource(src);
      video.load();
    };

    video.addEventListener('error', handleError);

    // HACK
    // https://stackoverflow.com/questions/34255345/how-to-get-audio-file-duration-in-safari
    // https://sparemin.atlassian.net/browse/SPAR-8641
    if (isSafari) {
      loadSource();
      video
        .play()
        .then(() => {
          video.pause();
          resolve(video);
        })
        .catch(err => reject(err));
    } else {
      video.addEventListener('durationchange', handleDurationChange);
      loadSource();
    }
  });
}

export function getDuration(
  src: File | string,
  cb?: (duration: number, err?: Error) => void,
) {
  const result = createVideoElement(src).then(video => video.duration);

  // if the caller passed a cb, call it and return nothing, otherwise return the promise
  if (!cb || typeof cb !== 'function') {
    return result;
  }

  result.then(duration => cb(duration)).catch(err => cb(undefined, err));
  return undefined;
}

export async function verifyVideo(
  file: File,
  maxDurationSeconds = spareminConfig.uploadVideoMaxDurationSeconds,
) {
  const video = await createVideoElement(file);

  if (video.duration > maxDurationSeconds) {
    throw new ApplicationError(
      'Uploaded videos have to be a maximum of 1 hour in length',
      'IN005',
    );
  }

  /*
   * HACK: not all browsers support all video encodings.  if a video with an unsupported encoding is
   * loaded into the browser, the browser typically just plays the audio without showing any video.
   * codec information isn't exposed in the browser, so assume that if the video is done loading
   * and both videoHeight and videoWidth are 0 the encoding is unsupported
   */
  if (video.videoHeight === 0 || video.videoWidth === 0) {
    throw new ApplicationError(
      "Your browser doesn't support the video's encoding.  Try uploading this video in another browser.",
      'ER008',
    );
  }

  return true;
}

type VideoType = 'ogg' | 'h264' | 'webm' | 'vp9' | 'hls' | string;

/**
 * https://davidwalsh.name/detect-supported-video-formats-javascript
 * https://github.com/Modernizr/Modernizr/blob/5eea7e2a213edc9e83a47b6414d0250468d83471/feature-detects/video.js
 */
export const supportsVideoType = memoize((type: VideoType) => {
  let video: HTMLVideoElement;

  // Allow user to create shortcuts, i.e. just "webm"
  const formats: Record<VideoType, string> = {
    ogg: 'video/ogg; codecs="theora"',
    h264: 'video/mp4; codecs="avc1.42E01E"',
    webm: 'video/webm; codecs="vp8, vorbis"',
    vp9: 'video/webm; codecs="vp9"',
    hls: 'application/x-mpegURL; codecs="avc1.42E01E"',
  };

  if (!video) {
    video = document.createElement('video');
  }

  try {
    return !!video.canPlayType(formats[type] || type).replace(/^no$/, '');
  } catch {
    return false;
  }
});

export function supportsWebM() {
  return supportsVideoType('webm') && supportsVideoType('vp9');
}
