import cn from 'classnames';
import * as React from 'react';

import { WaveIcon } from 'components/icons';
import ProjectButton from '../../containers/ProjectButton';
import { INewProjectButtonProps } from './types';

const AudiogramButton: React.SFC<INewProjectButtonProps> = ({
  className,
  description,
  icon,
  title,
  projectType = 'audiogram',
  ...rest
}) => (
  <ProjectButton
    {...rest}
    className={cn('audiogram-button', className)}
    icon={icon}
    projectType={projectType}
    title={title}
    description={description}
    to={{
      pathname: '/wizard',
      query: {
        type: 'audiogram',
      },
    }}
  />
);

AudiogramButton.defaultProps = {
  description:
    'Turn audio clips into short videos with a waveform to share on social',
  icon: <WaveIcon />,
  title: 'Audiogram maker',
};

export default AudiogramButton;
