import { externalMediaExists } from 'redux/middleware/api/audio-proxy-service/actions';
import { State, ThunkAction } from 'redux/types';
import reduxPoll, { PollingTimeoutError } from 'utils/redux-poll';

type UrlProvider = string | ((state: State) => string);

// superagent considers 4xx to be errors, so have to check the error object
// to find the response status
function isNotFound(err: any) {
  const status = err?.status;
  return status === 403 || status === 404;
}

function resolveUrl(provider: UrlProvider, state: State): string | undefined {
  if (typeof provider === 'function') {
    return provider(state);
  }

  return provider;
}

const pollUntilAssetReady = (
  url: UrlProvider,
): ThunkAction<Promise<boolean>> => async (dispatch, getState) => {
  const getUrl = () => resolveUrl(url, getState());

  try {
    let urlToPoll: string;
    const result = await reduxPoll(
      dispatch,
      () => {
        urlToPoll = getUrl();

        return dispatch(externalMediaExists(urlToPoll));
      },
      {
        intervalMillis: 5000,
        maxAttempts: 12,
        shouldContinue: (err, _, cancel) => {
          const currentUrl = getUrl();

          if (urlToPoll !== currentUrl) {
            cancel();
            return false;
          }

          if (err && isNotFound(err)) {
            return true;
          }

          if (!err) {
            return false;
          }

          throw err;
        },
      },
    );

    return result.response;
  } catch (err) {
    if (err instanceof PollingTimeoutError) {
      throw new Error('Try again shortly');
    }
    throw err;
  }
};

export const pollForThirdPartyAsset = (
  provider: UrlProvider,
): ThunkAction<Promise<boolean>> => async (dispatch, getState) => {
  const url = resolveUrl(provider, getState());

  if (url) {
    return dispatch(pollUntilAssetReady(provider));
  }

  return true;
};
