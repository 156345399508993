import cn from 'classnames';
import * as React from 'react';

import bem from 'utils/bem';

export interface NewBadgeProps {
  className?: string;
}

const block = bem('new-badge');

const NewBadge: React.FC<NewBadgeProps> = ({ className, children = 'New' }) => (
  <div className={cn(block(), className)}>{children}</div>
);

export default NewBadge;
