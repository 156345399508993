import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A>(method: M, args: A) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export const postVideoExport = (
  config: types.IQueueVideoCreate,
): types.QueueVideoCreateAction =>
  apiCallAction(
    types.ServiceMethod.QUEUE_VIDEO_CREATE,
    config as types.QueueVideoCreateArgs,
  );

export const fetchVideoExportStatus = (
  widgetId: string,
): types.GetCreateStatusAction =>
  apiCallAction(types.ServiceMethod.GET_VIDEO_CREATE_STATUS, [
    widgetId,
  ] as types.GetCreateStatusArgs);

export const postSocialShare = (post: types.CreateSocialShareArgs) => {
  return apiCallAction(types.ServiceMethod.SOCIAL_SHARE_CREATE, post);
};

export const fetchEmbedVideos = (
  videoId: number,
): types.GetEmbedVideosAction => {
  return apiCallAction(types.ServiceMethod.GET_EMBED_VIDEOS, [
    videoId,
  ] as types.GetEmbedVideosArgs);
};

export function getSocialShare(
  socialShareId: number,
): types.GetSocialShareAction {
  return apiCallAction(types.ServiceMethod.GET_SOCIAL_SHARE, [
    socialShareId,
  ] as types.GetSocialShareArgs);
}
