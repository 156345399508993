import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import Tooltip, { TooltipProps } from 'components/Tooltip';
import { tileBlock as block } from './utils';

const { useCallback, useMemo } = React;

interface ComponentProps {
  children: React.ReactNode;
  className: string;
  disabled: boolean;
  onClick: (e: React.MouseEvent<Element>) => void;
}

export interface TileProps<P = any> {
  active?: boolean;
  as?: keyof JSX.IntrinsicElements;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  hoverExpandLocked?: boolean;
  id?: string;
  onClick?: (params: P, e: React.MouseEvent<Element>) => void;
  params?: P;
  tooltip?: string;
  tooltipPlacement?: TooltipProps['placement'];
}

const Tile = React.forwardRef(
  <P extends {} = any>(
    {
      active,
      as,
      children,
      className,
      disabled,
      hoverExpandLocked,
      id,
      onClick = noop,
      params,
      tooltip,
      tooltipPlacement = 'bottom',
    }: TileProps<P>,
    ref: React.Ref<Element>,
  ) => {
    const handleClick = useCallback(
      (e: React.MouseEvent<Element>) => {
        onClick(params, e);
      },
      [onClick, params],
    );

    const Component = useMemo(() => {
      const componentRef = ref as any;
      if (!as) {
        return (props: ComponentProps) => (
          <button {...props} type="button" ref={componentRef} />
        );
      }
      const As: any = as;
      return (props: ComponentProps) => <As {...props} ref={componentRef} />;
    }, [as, ref]);

    return (
      <Component
        className={cn(
          block({
            active,
            'hover-expand-locked': hoverExpandLocked,
            disabled,
          }),
          className,
        )}
        disabled={disabled}
        onClick={handleClick}
      >
        {children}
        {tooltip && (
          <Tooltip
            className={block('tooltip')}
            content={tooltip}
            id={id}
            placement={tooltipPlacement}
          >
            <div className={block('tooltip-overlay')} />
          </Tooltip>
        )}
      </Component>
    );
  },
);

export default Tile;
