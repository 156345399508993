import Immutable from 'immutable';

import recordingUploadService from '../../middleware/api/recording-upload-service';
import { types as embedTypes } from '../embed';
import * as constants from './constants';
import * as types from './types';

const defaultState = Immutable.Map();

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case `${recordingUploadService.Method.UPLOAD_RECORDING}_SUCCESS`: {
      const { recordingId } = action.response;
      return state.set('recordingId', recordingId);
    }

    case types.UPLOAD_AUDIO_REQUEST: {
      const { type } = action.payload;
      return state.withMutations(s =>
        s
          .set('status', constants.UPLOAD_STATE_UPLOADING)
          .set('type', type.name),
      );
    }

    case types.UPLOAD_AUDIO_SUCCESS: {
      return state.withMutations(s =>
        s.set('status', constants.UPLOAD_STATE_UPLOADED),
      );
    }

    case types.UPLOAD_AUDIO_FAILURE:
      return state.set('status', constants.UPLOAD_STATE_FAILED);

    case types.UPLOAD_AWAIT_IMPORT_REQUEST:
      return state.set('status', constants.UPLOAD_STATE_IMPORTING);

    case embedTypes.EMBED_EDITOR_LOAD_REQUEST:
      return defaultState;

    default:
      return state;
  }
}
