import cn from 'classnames';
import * as React from 'react';

import Button, { ButtonProps } from 'components/Button';
import { cardWizardBlock as block } from '../../../utils';

type PickedButtonProps = Pick<ButtonProps, 'onClick' | 'theme' | 'disabled'>;

export interface CardWizardControlButtonProps extends PickedButtonProps {
  children?: React.ReactNode;
  className?: string;
}

const CardWizardControlButton: React.FC<CardWizardControlButtonProps> = ({
  children,
  className,
  onClick,
  theme,
  disabled,
}) => (
  <Button
    className={cn(block('control-button'), className)}
    {...{ onClick, theme, disabled }}
    fluid
  >
    {children}
  </Button>
);

CardWizardControlButton.defaultProps = {
  theme: 'submit',
};

export default CardWizardControlButton;
