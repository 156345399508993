import {
  FacebookSquare,
  InstagramSquare,
  LinkedInSquare,
  ThreadsSquare,
  TikTokSquare,
  XSquare,
} from '@sparemin/blockhead';

import React from 'react';
import { YoutubeSquare } from 'components/icons';
import { AutogramClipSelectionMethod } from 'types/common';
import { AutoPostingPlatform } from './types';

export const AUTOGRAM_DURATION_OPTIONS = [
  { label: 'Instagram Stories: 15 sec', value: 15 },
  { label: 'Instagram / LinkedIn: 30 sec', value: 30 },
  { label: 'Twitter: 45 sec', value: 45 },
  { label: 'Facebook / TikTok: 60 sec', value: 60 },
];

export const AUTOGRAM_SELECTION_METHOD_OPTIONS = [
  {
    label: 'Let Headliner AI select for me',
    value: AutogramClipSelectionMethod.SMART,
  },
  {
    label: 'Use Soundbite tags in this feed',
    value: AutogramClipSelectionMethod.SOUNDBITE_TAG,
  },
];

export const SUCCESS_STEP_SUBTITLE_BY_PLATFORM: Record<
  AutoPostingPlatform,
  string
> = {
  youtube: 'Each audiogram will be auto-posted to YouTube.',
  tiktok: 'Each clip will be auto-posted to TikTok.',
  instagram: 'Each episode will be auto-posted to Instagram.',
  linkedin: 'Each episode will be auto-posted to LinkedIn.',
  facebook: 'Each episode will be auto-posted to Facebook.',
  twitter: 'Each episode will be auto-posted to X.',
  threads: 'Each episode will be auto-posted to Threads.',
};

export const SUCCESS_STEP_ICON_BY_PLATFORM: Record<
  AutoPostingPlatform,
  JSX.Element
> = {
  youtube: <YoutubeSquare />,
  tiktok: <TikTokSquare width="95px" height="95px" />,
  instagram: <InstagramSquare width="95px" height="95px" />,
  linkedin: <LinkedInSquare width="95px" height="95px" />,
  facebook: <FacebookSquare width="95px" height="95px" />,
  twitter: <XSquare width="95px" height="95px" />,
  threads: <ThreadsSquare width="95px" height="95px" />,
};
