import { createSelector } from 'reselect';
import { AUTOMATION_BLACKLIST } from 'containers/ProIndicator';
import { SubTier } from 'redux/middleware/api/plan-service';
import { AutoCreateEpisode } from 'redux/middleware/api/podcast-service/types';
import { State } from 'redux/types';
import {
  autoCreateEpisodesSelector,
  entireAudioClipsSelector,
  podcastEpisodesSelector,
  podcastSubscriptionsSelector,
} from '../entities/selectors';
import { subtierSelector } from '../pricing/selectors';
import { ClipSelectState } from './types';

const stateSelector = (state: State): ClipSelectState =>
  state.get('clipSelect');

export const isFreeSelector = createSelector(
  subtierSelector,
  (subtier: SubTier) => AUTOMATION_BLACKLIST.includes(subtier),
);

export const dislikedSuggestionIdsSelector = createSelector(
  stateSelector,
  state => state.dislikedSuggestionIds.toArray(),
);

export const statusSelector = createSelector(stateSelector, state =>
  state.get('status'),
);

export const suggestionsStatusSelector = createSelector(stateSelector, state =>
  state.get('suggestionsStatus'),
);

export const suggestionIdsSelector = createSelector(stateSelector, state =>
  state.suggestionIds?.toArray(),
);

export const submitStatusSelector = createSelector(stateSelector, state =>
  state.get('submitStatus'),
);

export const episodeIdSelector = createSelector(stateSelector, state =>
  state.get('episodeId'),
);

export const entireAudioInstanceIdSelector = createSelector(
  stateSelector,
  state => state.get('entireAudioInstanceId'),
);

const subscriptionIdSelector = createSelector(stateSelector, state =>
  state.get('subscriptionId'),
);

export const subscriptionItemIdSelector = createSelector(stateSelector, state =>
  state.get('subscriptionItemId'),
);

export const defaultRegionSelector = createSelector(
  [entireAudioClipsSelector, entireAudioInstanceIdSelector],
  (entireAudioClips, entireAudioInstanceId) => {
    if (!entireAudioClips || !entireAudioInstanceId) {
      return undefined;
    }

    const clip = entireAudioClips.get(`${entireAudioInstanceId}`);

    if (!clip) {
      return undefined;
    }

    return {
      endMillis: clip.get('trimEndMillis'),
      startMillis: clip.get('trimStartMillis'),
    };
  },
);

export const autoCreateEpisodeIdSelector = createSelector(
  stateSelector,
  state => state.get('autoCreateEpisodeId'),
);

export const autoCreateEpisodeSelector = createSelector(
  autoCreateEpisodeIdSelector,
  autoCreateEpisodesSelector,
  (autoCreateEpisodeId, episodes): AutoCreateEpisode | undefined =>
    episodes?.get(String(autoCreateEpisodeId))?.toJS(),
);

export const widgetIdSelector = createSelector(stateSelector, state =>
  state.get('widgetId'),
);

export const podcastArtworkSelector = createSelector(
  episodeIdSelector,
  subscriptionIdSelector,
  podcastEpisodesSelector,
  podcastSubscriptionsSelector,
  (episodeId, subscriptionId, episodes, subscriptions) => {
    if (!episodeId && !episodes && !subscriptions) return undefined;

    const episodeArtwork = episodes?.getIn([
      `${episodeId}`,
      'thumbnailImageUrl',
    ]);
    const podcastArtwork = subscriptions?.getIn([
      `${subscriptionId}`,
      'thumbnailUrl',
    ]);

    return episodeArtwork || podcastArtwork;
  },
);

export const podcastInfoSelector = createSelector(
  episodeIdSelector,
  podcastEpisodesSelector,
  (episodeId, episodes) => {
    if (!episodeId || !episodes) return undefined;
    const episode = episodes.get(String(episodeId));

    if (!episode) return undefined;

    return {
      episodeName: episode.get('title'),
      podcastName: episode.getIn(['podcast', 'title']),
      durationMillis: episode.get('audioDurationMillis'),
      publishedAtMillis: episode.get('publishedAtMillis'),
    };
  },
);
