import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A>(method: M, args: A) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export const postLogin = (email: string, password: string): types.LoginAction =>
  apiCallAction(types.ServiceMethod.LOGIN, [
    email,
    password,
  ] as types.LoginArgs);

export const postForgotPassword = (email: string): types.ForgotPasswordAction =>
  apiCallAction(types.ServiceMethod.FORGOT_PASSWORD, [email]);

export const postUpdatePassword = (
  userId: number,
  currentPassword: string,
  newPassword: string,
  confirmPassword: string,
): types.UpdatePasswordAction =>
  apiCallAction(types.ServiceMethod.UPDATE_PASSWORD, [
    userId,
    currentPassword,
    newPassword,
    confirmPassword,
  ] as types.UpdatePasswordArgs);

export const putConfirmEmail = (uuid: string): types.ConfirmEmailAction =>
  apiCallAction(types.ServiceMethod.CONFIRM_EMAIL, [
    uuid,
  ] as types.ConfirmEmailArgs);

export const postResendVerificationEmail = (
  userId: number,
): types.ResendVerificationEmailAction => {
  return apiCallAction(types.ServiceMethod.RESEND_VERIFICATION_EMAIL, [userId]);
};
