import { Map, Record, RecordOf } from 'immutable';

import { OnboardingName } from 'blocks/OnboardingAnimations/types';
import { IGetOnboarding } from 'redux/middleware/api/headliner-user-service';
import { Action } from 'redux/types';
import { HeadlinerUsage, RequestStatus } from 'types';
import { Type } from './action-types';

export interface OnboardingWizardResponse {
  podcastYesNo: string;
  usage: HeadlinerUsage;
  subscribeToNewsletter: boolean;
  referrer?: string;
}

export type GetOnboardingRequestAction = Action<
  Type.ONBOADING_GET_REQUEST,
  { name: OnboardingName }
>;
export type GetOnboardingSuccessAction = Action<
  Type.ONBOADING_GET_SUCCESS,
  IGetOnboarding
>;
export type GetOnboardingFailureAction = Action<Type.ONBOADING_GET_FAILURE>;
export type GetOnboardingViewConfirmationRequestAction = Action<
  Type.ONBOADING_GET_VIEW_CONFIRMATION_REQUEST
>;
export type GetOnboardingViewConfirmationSuccessAction = Action<
  Type.ONBOADING_GET_VIEW_CONFIRMATION_SUCCESS,
  { name: OnboardingName }
>;
export type GetOnboardingViewConfirmationFailureAction = Action<
  Type.ONBOADING_GET_VIEW_CONFIRMATION_FAILURE
>;
export type OnboardingClear = Action<Type.ONBOADING_CLEAR>;

export type OnboardingWizardCompleteRequest = Action<
  Type.ONBOARDING_WIZARD_COMPLETE_REQUEST
>;
export type OnboardingWizardCompleteSuccess = Action<
  Type.ONBOARDING_WIZARD_COMPLETE_SUCCESS
>;
export type OnboardingWizardCompleteFailure = Action<
  Type.ONBOARDING_WIZARD_COMPLETE_FAILURE
>;

export type OnboardingAction =
  | GetOnboardingRequestAction
  | GetOnboardingSuccessAction
  | GetOnboardingFailureAction
  | GetOnboardingViewConfirmationRequestAction
  | GetOnboardingViewConfirmationSuccessAction
  | GetOnboardingViewConfirmationFailureAction
  | OnboardingWizardCompleteRequest
  | OnboardingWizardCompleteSuccess
  | OnboardingWizardCompleteFailure
  | OnboardingClear;

interface IState {
  onboardingItems: Map<OnboardingName, boolean>;
  status: RequestStatus;
}

export type OnboardingState = RecordOf<IState>;

export const stateFactory = Record<IState>({
  onboardingItems: Map(),
  status: undefined,
});
