import classNames from 'classnames';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import _ from 'underscore';

import AssetsPane, { AssetsPaneProps } from 'blocks/AssetsPane';
import { PostTaskDiscoOverlay } from 'blocks/PostTask';
import TriPane from 'components/TriPane';
import { Position as TripanePosition } from 'components/TriPane/types';
import PreviewPane from 'containers/PreviewPane';
import TimelinePane from 'containers/TimelinePane';
import VideoEditorModals from 'containers/VideoEditorModals';
import { getAspectRatioName } from 'utils/aspect-ratio';
import TemplateInstructionOverlay from './TemplateInstructionOverlay';
import {
  State,
  VideoEditorPlaybackTimeContext as VideoEditorPlaybackTimeContextType,
  VideoEditorProps,
} from './types';
import { block, uiStateFromJs } from './utils';
import VideoEditorPlaybackTimeContext from './VideoEditorPlaybackTimeContext';

export default class VideoEditor extends React.Component<
  VideoEditorProps,
  State
> {
  public static defaultProps: Partial<VideoEditorProps> = {
    hidden: false,
    onPause: _.noop,
    onPlay: _.noop,
    onReady: _.noop,
  };

  constructor(props: VideoEditorProps) {
    super(props);

    this.state = {
      ui: uiStateFromJs({
        editor: {
          data: undefined,
          type: undefined,
        },
        embedPlayer: {
          ready: false,
        },
        isModalOpen: false,
        isPlaying: false,
        panes: {
          draggingResizer: false,
        },
        /*
         * this position is the source of truth and gets set by webaudio timeupdate callback
         */
        positionSec: 0,
        /*
         * this is the position used to seek through the audio.  its easier to avoid potential
         * infinite loops in the timeline by having a separate parameter to track seek position
         * (as opposed to just using positionSec)
         */
        seekToSec: undefined,
        stopSec: null,
        syncTranscriptWithAudio: true,
      }),
    };
  }

  public componentDidMount(): void {
    const { onReady } = this.props;
    onReady();
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<VideoEditorProps>,
  ): void {
    const {
      ui: { isPlaying },
    } = this.state;

    if (nextProps.hidden && isPlaying) {
      this.handlePause();
    }
  }

  public componentDidUpdate(__, prevState: Readonly<State>) {
    const { ui: prevUi } = prevState;
    const { ui } = this.state;
    const { onPlay, onPause } = this.props;

    if (ui.isPlaying !== prevUi.isPlaying) {
      ui.isPlaying ? onPlay() : onPause();
    }
  }

  private handleSetDraggingResizer = (val: boolean) => () =>
    this.setState(({ ui }) => ({
      ui: ui.setIn(['panes', 'draggingResizer'], val),
    }));

  private handleAssetClick: AssetsPaneProps['onAssetClick'] = ({
    startMillis,
  }) =>
    this.setState(({ ui }) => ({
      ui: ui.set('seekToSec', startMillis / 1000),
    }));

  private handleCopyPhraseToTimeline = (startMillis: number) =>
    this.setState(({ ui }) => ({
      ui: ui.set('seekToSec', startMillis / 1000),
    }));

  private handlePlayPhrase: AssetsPaneProps['onPlayPhrase'] = (
    startMillis,
    endMillis,
  ) => {
    this.setState(({ ui }) => ({
      ui: ui
        .withMutations(u =>
          u
            .set('seekToSec', startMillis / 1000)
            .set('stopSec', endMillis / 1000)
            .set('isPlaying', true),
        )
        .set('syncTranscriptWithAudio', false),
    }));
  };

  private handlePlay = (): void =>
    this.setState(({ ui }) => ({
      ui:
        ui.isModalOpen || this.props.hidden
          ? ui
          : ui.withMutations(u =>
              u
                .set('isPlaying', true)
                .delete('stopSec')
                .set('syncTranscriptWithAudio', true),
            ),
    }));

  private handlePause = (): void => {
    this.setState(({ ui }) => ({
      ui: ui.withMutations(u =>
        u
          .set('isPlaying', false)
          .delete('stopSec')
          .set('syncTranscriptWithAudio', true),
      ),
    }));
    this.updatePreviewPosition(this.state.ui.get('positionSec'));
  };

  private handleTranscriptKeywordClick: AssetsPaneProps['onKeywordClick'] = (
    keyword,
    navigateToMillis,
  ) => {
    const seekToMillis = navigateToMillis || (keyword.id as number);
    const seekToSec = seekToMillis / 1000;
    this.setState(({ ui }) => ({
      ui: ui.set('seekToSec', seekToSec),
    }));
  };

  private handleTimelineSeek = (sec: number) => {
    this.setState(({ ui }) => ({
      ui: ui.withMutations(u =>
        u.set('seekToSec', sec).set('positionSec', sec),
      ),
    }));
  };

  private handleTimeUpdate = ({ seconds }: { seconds: number }) => {
    const {
      ui: { stopSec },
    } = this.state;

    this.setState(({ ui }) => ({
      ui: ui.withMutations(u => {
        u.delete('seekToSec').set('positionSec', seconds);

        if (stopSec && seconds >= stopSec) {
          u.set('isPlaying', false)
            .set('syncTranscriptWithAudio', true)
            .set('stopSec', null);
        }

        return u;
      }),
    }));

    this.syncPreviewPosition(seconds);
  };

  private handleEmbedPlayerDestroy = () => {
    const { onPlayerDestroy } = this.props;
    this.setState(({ ui }) => ({
      ui: ui.setIn(['embedPlayer', 'ready'], false),
    }));

    onPlayerDestroy();
  };

  private handleEmbedPlayerPlay = () =>
    this.setState(({ ui }) => ({
      ui: ui.set('isPlaying', true),
    }));

  private handleEmbedPlayerPause = () => {
    this.setState(({ ui }) => ({
      ui: ui.set('isPlaying', false),
    }));
    this.updatePreviewPosition(this.state.ui.get('positionSec'));
  };

  private handleEmbedPlayerReady = () => {
    const { onPlayerReady } = this.props;
    this.setState(({ ui }) => ({
      ui: ui.setIn(['embedPlayer', 'ready'], true),
    }));
    onPlayerReady();
  };

  private handleModalShow = () => {
    this.handlePause();
    this.setState(({ ui }) => ({
      ui: ui.set('isModalOpen', true),
    }));
  };

  private handleModalHide = () =>
    this.setState(({ ui }) => ({
      ui: ui.set('isModalOpen', false),
    }));

  private updatePreviewPosition = (sec: number) => {
    this.setState(({ ui }) => ({
      ui: ui.set('seekToSec', sec),
    }));
  };

  // disable trailing edge so that we don't get invariants on unmount
  private syncPreviewPosition = _.throttle(this.updatePreviewPosition, 15000, {
    trailing: false,
  });

  private renderAssetsPane(): React.ReactNode {
    const { isReady } = this.props;
    const { ui } = this.state;
    return (
      <AssetsPane
        className={block('assets-pane')}
        onAssetClick={this.handleAssetClick}
        onCopyPhraseToTimeline={this.handleCopyPhraseToTimeline}
        onPlayPhrase={this.handlePlayPhrase}
        onPause={this.handlePause}
        onKeywordClick={this.handleTranscriptKeywordClick}
        playing={ui.get('isPlaying')}
        syncTranscriptWithPlayback={ui.get('syncTranscriptWithAudio')}
        onModalShow={this.handleModalShow}
        onModalHide={this.handleModalHide}
        isVideoEditorReady={isReady}
      />
    );
  }

  private renderTimelinePane() {
    const { onReady } = this.props;
    const { ui } = this.state;

    return (
      <TimelinePane
        className={block('tl-pane-components')}
        onPlay={this.handlePlay}
        onPause={this.handlePause}
        onReady={onReady}
        onSeek={this.handleTimelineSeek}
        onTimelineModalHide={this.handleModalHide}
        onTimelineModalShow={this.handleModalShow}
        onTimeupdate={this.handleTimeUpdate}
        playing={ui.get('isPlaying')}
      />
    );
  }

  private renderPreviewPane(): React.ReactNode {
    const { isSaving, active } = this.props;
    const { ui } = this.state;

    const showOverlay = isSaving || !ui.getIn(['embedPlayer', 'ready']);

    // tslint:disable-next-line variable-name
    const PreviewPaneAsAny = PreviewPane as any;

    return (
      <PreviewPaneAsAny
        className={block('preview-pane')}
        classNamePrefix={block()}
        id="embed-tabs"
        onPlayerDestroy={this.handleEmbedPlayerDestroy}
        onPlayerPlay={this.handleEmbedPlayerPlay}
        onPlayerPause={this.handleEmbedPlayerPause}
        onPlayerReady={this.handleEmbedPlayerReady}
        playing={ui.isPlaying}
        position={ui.seekToSec}
        renderDragFixOverlay={
          ui.getIn(['panes', 'draggingResizer']) || ui.isModalOpen
        }
        savingOverlayClassName={block('saving-overlay')}
        showSavingOverlay={showOverlay}
        tabClassName="video-editor__pane-tab"
        active={active}
        volume={0}
      />
    );
  }

  private renderTopLeftPane = (): React.ReactNode => (
    <>
      <Col className={classNames(block('col'), block('assets-col'))} xs={4}>
        {this.renderAssetsPane()}
      </Col>
      <TemplateInstructionOverlay />
    </>
  );

  private renderBottomPane = (_1, _2): React.ReactNode => (
    <Col className={classNames(block('col'), block('tl-col'))} xs={8}>
      {this.renderTimelinePane()}
    </Col>
  );

  private renderTopRightPane = (): React.ReactNode => (
    <Col className={classNames(block('col'), block('prvw-col'))} xs={5}>
      {this.renderPreviewPane()}
    </Col>
  );

  private showPostTaskDiscoWidget() {
    const { shouldShowDiscoOverlay, videoExportStatus } = this.props;
    const progress = videoExportStatus.get('progress');
    const status = videoExportStatus.get('status');
    const inProgressStatuses = ['queued', 'processing'];

    return (
      shouldShowDiscoOverlay &&
      inProgressStatuses.includes(status) &&
      progress !== undefined
    );
  }

  public render() {
    const { aspectRatio } = this.props;
    const { ui } = this.state;

    const aspectRatioName = getAspectRatioName(aspectRatio);

    const providerValue: VideoEditorPlaybackTimeContextType = {
      positionSec: ui.get('positionSec', 0),
      seekToSec: ui.get('seekToSec', 0),
      setPositionSec: this.handleTimelineSeek,
    };

    return (
      <VideoEditorPlaybackTimeContext.Provider value={providerValue}>
        <div
          className={classNames(
            block({ split: true, [aspectRatioName]: true }),
          )}
        >
          <TriPane
            defaultMainSplitFirstPaneSize="calc(100% - 351px)"
            defaultSecondarySplitFirstPaneSize="40%"
            singlePanePosition={TripanePosition.BOTTOM}
            mainResizerClassName="video-editor__split-pane"
            singlePaneWrapper={Row}
            singlePaneWrapperProps={{
              className: 'video-editor__row video-editor__row--split',
            }}
            dualPaneWrapper={Row}
            dualPaneWrapperProps={{
              className: 'video-editor__row video-editor__row--split',
            }}
            onMainResizerDragStart={this.handleSetDraggingResizer(true)}
            onMainResizerDragStop={this.handleSetDraggingResizer(false)}
            onSecondaryResizerDragStart={this.handleSetDraggingResizer(true)}
            onSecondaryResizerDragStop={this.handleSetDraggingResizer(false)}
            renderFirstSubpane={this.renderTopLeftPane}
            renderMainPane={this.renderBottomPane}
            renderSecondSubpane={this.renderTopRightPane}
          />
          <VideoEditorModals
            onModalShow={this.handleModalShow}
            onModalHide={this.handleModalHide}
          />
          <PostTaskDiscoOverlay
            subTitle="In the meantime, enjoy these clips while you wait…"
            title="Your video will be ready soon!"
            visible={this.showPostTaskDiscoWidget()}
          />
        </div>
      </VideoEditorPlaybackTimeContext.Provider>
    );
  }
}
