import React from 'react';
import { useSelector } from 'react-redux';
import { Discount } from 'blocks/Pricing/constants';
import {
  customerIdSelector,
  discountSelector,
  isInvoicePastDueSelector,
} from 'redux/modules/pricing';
import BillingHistoryLink from '../BillingHistoryLink';
import CreditCardRecoveryBanner, {
  useCreditCardRecoveryBanner,
} from '../CreditCardRecoveryBanner';
import BaseFreeSubscription from './BaseFreeSubscription';
import { block } from './utils';

export interface FreeSubscriptionProps {}

const FreeSubscription: React.FC<FreeSubscriptionProps> = () => {
  const discount = useSelector(discountSelector);
  const customerId = useSelector(customerIdSelector);
  const invoicePastDue = useSelector(isInvoicePastDueSelector);
  const { show: showCreditCardRecovery } = useCreditCardRecoveryBanner();

  return (
    <>
      {showCreditCardRecovery && (
        <CreditCardRecoveryBanner className={block('banner')} />
      )}

      <BaseFreeSubscription
        message={
          discount === Discount.BOGO
            ? '🎉 Special Deal: Buy One, Get One Free 🎉 '
            : invoicePastDue || customerId
            ? 'Your plan was cancelled :('
            : "You've got Headliner for free!"
        }
        subMessage={
          discount === Discount.BOGO ? (
            "As a thanks for being an early user, we're offering you a free year or month of Headliner Pro!"
          ) : invoicePastDue || customerId ? (
            <span>
              However, you can easily reactivate below! Also{' '}
              <BillingHistoryLink>
                here is your billing history
              </BillingHistoryLink>
              .
            </span>
          ) : (
            'Interested in taking your video making to the next level? Check out our upgrade options:'
          )
        }
      />
    </>
  );
};

export default FreeSubscription;
