import * as React from 'react';
import _ from 'underscore';

import { block } from '../../utils';

export interface IKeyword {
  value: string;
  id: string | number;
}

export interface KeywordPhraseProps {
  className?: string;
  keywords?: IKeyword[];

  /**
   * a click anywhere in the phrase.  this callback will not be called if a keyword is clicked
   */
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;

  /**
   * function called when a keyword is clicked.  callback function has 1 parameter which is the
   * corresponding keyword object passed in via props
   */
  onKeywordClick?: (
    keyword: IKeyword,
    e: React.MouseEvent<HTMLElement>,
  ) => void;

  /**
   * the block of text in which keywords should be highlighted
   */
  phrase: string;
}

export default class KeywordPhrase extends React.Component<KeywordPhraseProps> {
  public static defaultProps: Partial<KeywordPhraseProps> = {
    keywords: [],
    onClick: _.noop,
    onKeywordClick: _.noop,
  };

  private handleKeywordClick = (keyword: IKeyword) => (
    e: React.MouseEvent<HTMLElement>,
  ): void => {
    const { onKeywordClick } = this.props;
    onKeywordClick(keyword, e);
  };

  /*
   * searched text for first instance of keyword.  return an array of mixed content where each item
   * is either a string or a jsx object.
   *
   * for example, given the text "hello world, welcome to the hello world app" with keyword "hello",
   * the return value would be:
   *
   *  [<span>hello</span>, "world, welcome to the hello world app"]
   *
   * React can render the response array directly with something like <div>{responseArray}</div>
   */
  private highlightKeywordInText(
    text: string,
    keyword: IKeyword,
  ): { found: boolean; result: React.ReactNode[] } {
    const { id: keywordId, value: word } = keyword;

    // NB: keep the "g" flag otherwise wordEndIndex below will always be 0
    const regex = new RegExp(`\\b${word}\\b`, 'ig');

    const matchResult = regex.exec(text);
    if (matchResult) {
      const { index: wordStartIndex } = matchResult;
      const [match] = matchResult;
      const wordEndIndex = regex.lastIndex;

      const prefix = text.substr(0, wordStartIndex);
      const suffix = text.substr(wordEndIndex);
      const tag = (
        <span
          className={block('keyword', { highlight: true })}
          key={`${keywordId}-${word}`}
          onClick={this.handleKeywordClick(keyword)}
        >
          {match}
        </span>
      );

      return {
        found: true,
        result: [prefix, tag, suffix],
      };
    }

    return {
      found: false,
      result: [text],
    };
  }

  /*
   * takes a block of text (phrase) and searches for all instances of all keywords in that block
   * and highlights them.
   */
  private highlightKeywordsInPhrase(): React.ReactNode[] {
    const { phrase, keywords } = this.props;

    /*
     * using a reduce here to calculate the final array.  the initial value of the accumulator is
     * the entire phrase as an array [phrase].  for each keyword, we do the following:
     *    1. iterate through the accumulator
     *    2. for all strings (as we iterate, the accumulator will start to contain jsx), call
     *       highlightKeywordInText
     *    3. return the flattened response from 2
     */
    return keywords.reduce(
      (acc, keyword) => {
        // 1.
        const matchedKeywords = new Set();
        const arys = acc.map(item => {
          if (typeof item !== 'string' || matchedKeywords.has(keyword)) {
            return [item];
          }
          const { result, found } = this.highlightKeywordInText(item, keyword); // 2.
          if (found) matchedKeywords.add(keyword);
          return result;
        });
        return arys.reduce((flattened, a) => flattened.concat(a), []); // 3.
      },
      [phrase],
    );
  }

  public render() {
    const { className, onClick } = this.props;
    return (
      <div className={className} onClick={onClick}>
        {this.highlightKeywordsInPhrase()}
      </div>
    );
  }
}
