import cn from 'classnames';
import * as React from 'react';

import bem from 'utils/bem';

export interface Props {
  variant?:
    | 'default'
    | 'pink'
    | 'green'
    | 'purple'
    | 'orange'
    | 'yellow'
    | 'danger';
  children?: React.ReactNode;
  className?: string;
  elevated?: boolean;
}

const block = bem('circular');

const Circular: React.FC<Props> = ({
  children,
  className,
  variant = 'default',
  elevated = false,
}) => (
  <div className={cn(block({ [variant]: true, elevated }), className)}>
    {children}
  </div>
);

export default Circular;
