import { Set } from 'immutable';
import { SubTier } from 'redux/middleware/api/plan-service';
import { RequestStatus } from 'types';
import { Type } from './action-types';
import {
  myCardFactory,
  myCouponsFactory,
  mySubscriptionFactory,
  stateFactory,
} from './factories';
import { PricingAction, PricingState } from './types';
import { getDaysUntilNow } from './utils';

const defaultState = stateFactory();

export default function(
  state: PricingState = defaultState,
  action: PricingAction,
) {
  switch (action.type) {
    case Type.PRICING_DATA_SUBSCRIPTION_LOAD_SUCCESS: {
      const { mySubscription } = action.payload;
      return state.withMutations(s => {
        s.set('mySubscription', mySubscriptionFactory(mySubscription));

        // reset trial reminders when the trial has been extended or expired
        const planEndInDays = getDaysUntilNow(mySubscription.planEndSec);
        if (mySubscription.subtier !== SubTier.PRO_TRIAL || planEndInDays > 7) {
          s.set('seenFreeTrialReminders', Set([]));
        }

        return s;
      });
    }

    case Type.PRICING_DATA_COUPONS_LOAD_SUCCESS: {
      const { myCoupons } = action.payload;
      return state.set('myCoupons', myCouponsFactory(myCoupons));
    }

    case Type.PRICING_DATA_LOAD_REQUEST:
      return state.set('isLoading', true);

    case Type.PRICING_DATA_LOAD_SUCCESS:
    case Type.PRICING_DATA_LOAD_FAILURE:
      return state.set('isLoading', false);

    case Type.PRICING_REFERRAL_DATA_LOAD_SUCCESS: {
      const { myReferralUrl } = action.payload;
      return state.set('myReferralUrl', myReferralUrl);
    }

    case Type.PRICING_CREATE_PAYMENT_METHOD_REQUEST:
      return state.withMutations(s => {
        s.set('savePaymentMethodStatus', RequestStatus.REQUEST);
        s.set('updateSubscriptionStatus', undefined);
        return s;
      });

    case Type.PRICING_CREATE_PAYMENT_METHOD_SUCCESS:
      return state.set('savePaymentMethodStatus', RequestStatus.SUCCESS);

    case Type.PRICING_CREATE_PAYMENT_METHOD_FAILURE:
      return state.set('savePaymentMethodStatus', RequestStatus.FAILURE);

    case Type.PRICING_UPDATE_SUBSCRIPTION_REQUEST:
      return state.set('updateSubscriptionStatus', RequestStatus.REQUEST);

    case Type.PRICING_UPDATE_SUBSCRIPTION_SUCCESS:
      return state.set('updateSubscriptionStatus', RequestStatus.SUCCESS);

    case Type.PRICING_UPDATE_SUBSCRIPTION_FAILURE:
      return state.set('updateSubscriptionStatus', RequestStatus.FAILURE);

    case Type.PRICING_CUSTOMER_CARD_GET_SUCCESS: {
      const { myCard, customerId } = action.payload;
      return state
        .set('myCard', myCardFactory(myCard))
        .set('customerId', customerId);
    }

    case Type.PRICING_CUSTOMER_CARD_GET_FAILURE:
      return state.delete('myCard');

    case Type.PRICING_REFERRAL_INVITATIONS_SEND_REQUEST:
      return state.set('isSendingInvites', true);

    case Type.PRICING_REFERRAL_INVITATIONS_SEND_SUCCESS:
    case Type.PRICING_REFERRAL_INVITATIONS_SEND_FAILURE:
      return state.set('isSendingInvites', false);

    case Type.MARK_FREE_TRIAL_REMINDER_SEEN: {
      const { payload } = action;

      if (state.mySubscription.subtier === SubTier.PRO_TRIAL) {
        return state.update('seenFreeTrialReminders', s => s.add(payload));
      }

      return state;
    }

    case Type.LOAD_EXTERNAL_PORTAL_LINK_SUCCESS: {
      const { payload } = action;
      return state.set('externalPortalLink', payload);
    }

    default:
      return state;
  }
}
