import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { block } from '../utils';

interface IProps {
  className?: string;
  label: number | string;
  onClick?: (value: string | number) => void;
  selected?: boolean;
  style?: React.CSSProperties;
  value: any;
  v2?: boolean;
}

export default class SelectOption extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onClick: noop,
  };

  private handleClick = () => this.props.onClick(this.props.value);

  public render() {
    const { className, label, selected, style, v2 } = this.props;

    return (
      <li
        className={cn(block('select-option', { selected, v2 }), className)}
        onClick={this.handleClick}
        role="menuitem"
        style={style}
      >
        {label}
      </li>
    );
  }
}

export { IProps as SelectOptionProps };
