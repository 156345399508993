import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'underscore';
import useVideoProcessor from 'hooks/useVideoProcessor';

import { IVideoUpload } from 'redux/middleware/api/media-upload-service';
import { pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { useEditorState } from './context/VideoEditorStateContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import {
  MediaIntegrationId,
  VideoIntegrationData,
  VideoSource,
} from './types/core';
import useEditorLoading from './useEditorLoading';

export interface ProcessedVideoConfig {
  fileName: string;
  integrationData?: VideoIntegrationData;
  original: Blob;
  src: IVideoUpload;
}

export type OnVideoProcessed = (
  config: ProcessedVideoConfig,
  integrationId?: string,
) => void;

export interface UseImageSourceConfig {
  onUploadAnotherFile: () => void;
  onError?: (source: VideoSource, err: Error) => void;
  onVideoProcessed?: OnVideoProcessed;
}

const useVideoSource = ({
  onUploadAnotherFile,
  onError = noop,
  onVideoProcessed = noop,
}: UseImageSourceConfig) => {
  const { aspectRatio } = useTemplateState();
  const { audioClipDurationSec } = useEditorState();
  const dispatch = useDispatch<Dispatch>();

  const [result, setResult] = useState<ProcessedVideoConfig | undefined>();
  const [acceptedFile, setAcceptedFile] = useState<VideoSource>();
  const [, setEditorLoading] = useEditorLoading();
  const [integrationData, setIntegrationData] = useState<
    VideoIntegrationData
  >();
  const fileName =
    (typeof acceptedFile === 'string' ? acceptedFile : acceptedFile?.name) ??
    'file';

  const setAcceptedSource = useCallback(
    (src: VideoSource, data?: VideoIntegrationData) => {
      if (data?.id !== MediaIntegrationId.AI_ASSET) {
        setEditorLoading(true);
      }

      if (src === acceptedFile && result) {
        onVideoProcessed({ ...result }, data?.id);
      } else {
        setAcceptedFile(src);
        setIntegrationData(data);
      }
    },
    [acceptedFile, onVideoProcessed, result, setEditorLoading],
  );

  useVideoProcessor(
    acceptedFile,
    aspectRatio,
    useCallback(
      err => {
        setEditorLoading(false);
        onError(
          acceptedFile,
          new Error(`Could not open ${fileName}: ${err.message}`),
        );
      },
      [acceptedFile, fileName, onError, setEditorLoading],
    ),
    useCallback(
      async ({ originalFile, status, videoUpload }) => {
        if (status === 'ready') {
          const config = {
            fileName,
            integrationData,
            original: originalFile,
            src: videoUpload,
          };

          setEditorLoading(false);

          if (audioClipDurationSec > videoUpload.acceptableFinalDurationSecs) {
            const shouldContinueWithVideo = await dispatch(
              pushModal({
                name: 'QualityReductionAlert',
              }),
            );

            if (!shouldContinueWithVideo) {
              onUploadAnotherFile?.();

              return;
            }
          }

          setResult(config);
          onVideoProcessed(config, integrationData?.id);
        }
      },
      [
        audioClipDurationSec,
        dispatch,
        fileName,
        integrationData,
        onUploadAnotherFile,
        onVideoProcessed,
        setEditorLoading,
      ],
    ),
  );

  return setAcceptedSource;
};

export default useVideoSource;
