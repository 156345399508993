import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';

import YoutubePermissionsRevokedModalContent from './YoutubePermissionsRevokedModalContent';

const YoutubePermissionsRevokedModal = () => {
  return (
    <ConnectedModal name="YoutubePermissionsRevoked" title="auto-post videos">
      <YoutubePermissionsRevokedModalContent />
    </ConnectedModal>
  );
};

export default YoutubePermissionsRevokedModal;
