import * as React from 'react';
import destinations from 'blocks/AudiogramDestinations/components/Destinations';
import AudiogramDestinationVideoTypesList from 'blocks/AudiogramDestinations/components/VideoTypes/AudiogramDestinationVideoTypesList';
import {
  AudiogramDestination,
  VideoTypeConfig,
} from 'blocks/AudiogramDestinations/types';
import AutomationOptionsCards from 'blocks/AutomationOptions/AutomationOptionsCards';
import {
  AutoPostOptions,
  DestinationSteps,
  DestinationStepView,
} from 'blocks/AutomationOptions/types';
import useAutomationStepOptions from 'blocks/AutomationOptions/useAutomationStepOptions';
import { VideoTypes } from 'types';
import { DestinationOptions } from '../containers/AutomationWorkflowWizard/types';
import {
  checkViewIsAutoPosting,
  checkViewIsClipSelection,
} from '../containers/AutomationWorkflowWizard/utils';
import { destinationStepBlock as block } from '../utils';
import Steps from './DestinationStep/Steps';

export interface AutomationDestinationStepProps {
  destinationOptions: DestinationOptions;
  onDestinationOptionsChange?: (props: DestinationOptions) => void;
}

const AutomationDestinationStep: React.FC<AutomationDestinationStepProps> = props => {
  const { destinationOptions, onDestinationOptionsChange } = props;
  const { stepView, automationOption, platform, clipSelectionMethod } =
    destinationOptions || {};

  const { autoPostingOptions } = useAutomationStepOptions();

  const handleAutoPostingOptionsChange = React.useCallback(
    (options: DestinationOptions): void => {
      onDestinationOptionsChange({
        ...options,
        autopostVideoToYouTube:
          options.automationOption === AutoPostOptions.AUTO_POST,
      });
    },
    [onDestinationOptionsChange],
  );

  const handleVideoTypeClick = (selectedVideoType: VideoTypeConfig) => {
    const {
      destination,
      videoType,
      aspectRatioName,
      clipDuration,
    } = selectedVideoType.autogramWizardSettings;

    onDestinationOptionsChange({
      stepView: destination,
      isDestinationOptionsComplete: !destination,
      videoType,
      platformVideoType: selectedVideoType.id,
      aspectRatioName,
      clipDuration,
    });
  };

  const createSetDestinationHandler = (
    newPlatform: AudiogramDestination | null,
    shouldSelectVideoTypes: boolean,
    nextStepView?: DestinationStepView,
  ) => () => {
    onDestinationOptionsChange({
      stepView:
        nextStepView ||
        (shouldSelectVideoTypes
          ? DestinationSteps.VIDEO_TYPES
          : DestinationSteps.CLIP_SELECTION),
      platform: newPlatform,
      videoType: shouldSelectVideoTypes ? null : VideoTypes.RANDOM_CLIP,
      platformVideoType: shouldSelectVideoTypes ? null : 'post',
      aspectRatioName: !shouldSelectVideoTypes ? 'square' : null,
    });
  };

  if (stepView === DestinationSteps.VIDEO_TYPES) {
    return (
      <div className={block('video-types-list-container')}>
        <AudiogramDestinationVideoTypesList
          title="Choose a video type"
          icon={destinations[platform]?.icon}
          videoTypes={destinations[platform]?.videoTypes}
          shouldRedirectToDestination={false}
          onClick={handleVideoTypeClick}
          onBackClick={() =>
            onDestinationOptionsChange({
              stepView: DestinationSteps.CHOOSE_DESTINATION,
            })
          }
        />
      </div>
    );
  }

  if (checkViewIsClipSelection(stepView)) {
    return (
      <AutomationOptionsCards
        options={autoPostingOptions.clipSelection}
        value={clipSelectionMethod}
        currentStep={stepView}
        onChange={onDestinationOptionsChange}
      />
    );
  }

  if (checkViewIsAutoPosting(stepView)) {
    return (
      <AutomationOptionsCards
        options={autoPostingOptions[platform]}
        value={automationOption}
        currentStep={stepView}
        onChange={handleAutoPostingOptionsChange}
      />
    );
  }

  return <Steps createChangeHandler={createSetDestinationHandler} />;
};

export default AutomationDestinationStep;
