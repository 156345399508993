import { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { threadsUserDataSelector } from 'redux/modules/social';
import useThreadsPostModalSelector from '../../useThreadsPostModalSelector';
import {
  UseAutopostingComposerStep,
  UseAutopostingComposerStepConfig,
} from './types';
import usePostComposerStep from './usePostComposerStep';

const useAutopostingComposerStep = (
  config: UseAutopostingComposerStepConfig,
): UseAutopostingComposerStep => {
  const { initialValues, onSwitchAccountClick, onHideModal, onSubmit } = config;

  const {
    username,
    avatarSrc,
    defaultDescription,
  } = useThreadsPostModalSelector({
    defaultDescription: initialValues?.description,
  });

  const [prevInitialValues, setPrevInitialValues] = useState(initialValues);
  const [description, setDescription] = useState<string>(defaultDescription);

  const { providerUserId: threadsUserId } = useSelector(
    threadsUserDataSelector,
  );

  const handleSetDefaultProps = useCallback(() => {
    setDescription(defaultDescription);
  }, [defaultDescription]);

  const handleSubmit = useCallback((): void => {
    onSubmit?.({
      threadsUserId,
      description,
    });

    handleSetDefaultProps();
  }, [description, handleSetDefaultProps, threadsUserId, onSubmit]);

  const postComposer = usePostComposerStep({
    username,
    avatarSrc,
    allowSubmit: Boolean(description),
    description,
    onSwitchAccountClick,
    onDescriptionChange: setDescription,
    onHideModal,
    onSubmit: handleSubmit,
  });

  if (!isEqual(prevInitialValues, initialValues)) {
    handleSetDefaultProps();
    setPrevInitialValues(initialValues);
  }

  return postComposer;
};

export default useAutopostingComposerStep;
