import qs from 'query-string';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEpisodeById } from 'redux/middleware/api/podcast-service/actions';
import {
  isBasicOrFreeSelector,
  subscriptionSelector,
} from 'redux/modules/pricing';
import { Dispatch } from 'redux/types';
import { CMSEpisode } from 'types';
import { getFileSize } from 'utils/file';
import { millisToHours } from 'utils/time';

export interface UseValidateEpisodeTranscriptionConfig {
  onFreeOrBasicUserLimitsExceeded: () => void;
  onProUserLimitsExceeded: (fileSize: number, episodeDuration: number) => void;
}

export interface UseValidateEpisodeTranscriptionResults {
  isValidating: boolean;
  validateEpisodeTranscription: (episode: CMSEpisode) => Promise<boolean>;
}

export default function useValidateEpisodeTranscription({
  onFreeOrBasicUserLimitsExceeded,
  onProUserLimitsExceeded,
}: UseValidateEpisodeTranscriptionConfig): UseValidateEpisodeTranscriptionResults {
  const [isValidating, setIsValidating] = useState(false);

  const {
    eddyDurationLimitHours,
    eddyUploadMB,
    fullEpisodeDurationLimitHours,
    videoUploadLimitMb,
  } = useSelector(subscriptionSelector);
  const isFreeOrBasic = useSelector(isBasicOrFreeSelector);

  const dispatch = useDispatch<Dispatch>();

  const handleGetFileSize = useCallback(
    async (episode: CMSEpisode): Promise<number> => {
      const { response } = await dispatch(getEpisodeById(episode.id));

      const proxyUrl = qs.stringifyUrl({
        url: '/audio-proxy',
        query: {
          url: qs.stringifyUrl({
            url: response.entities.podcastEpisodes[episode.id]?.audioUrl,
            query: { 'x-request': 'xhr' },
          }),
        },
      });

      const size = await getFileSize(
        `${spareminConfig.services.audioProxy}${proxyUrl}`,
        'cors',
      );

      return size;
    },
    [dispatch],
  );

  const handleEpisodeTranscriptionValidation = useCallback(
    async (episode: CMSEpisode): Promise<boolean> => {
      setIsValidating(true);

      const { audioDurationMillis } = episode;
      const audioDurationHours = millisToHours(audioDurationMillis);
      const fileSize = await handleGetFileSize(episode);
      const fileSizeMB = fileSize / 1024 ** 2;

      if (
        isFreeOrBasic &&
        (audioDurationHours > eddyDurationLimitHours ||
          fileSizeMB > eddyUploadMB)
      ) {
        onFreeOrBasicUserLimitsExceeded();
        setIsValidating(false);

        return false;
      }

      if (
        !isFreeOrBasic &&
        (audioDurationHours > fullEpisodeDurationLimitHours ||
          fileSizeMB > videoUploadLimitMb)
      ) {
        onProUserLimitsExceeded(fileSize, audioDurationMillis);
        setIsValidating(false);

        return false;
      }

      setIsValidating(false);

      return true;
    },
    [
      eddyDurationLimitHours,
      eddyUploadMB,
      fullEpisodeDurationLimitHours,
      handleGetFileSize,
      isFreeOrBasic,
      onFreeOrBasicUserLimitsExceeded,
      onProUserLimitsExceeded,
      videoUploadLimitMb,
    ],
  );

  return {
    isValidating,
    validateEpisodeTranscription: handleEpisodeTranscriptionValidation,
  };
}
