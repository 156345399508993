import React from 'react';
import { RootCloseWrapper } from 'react-overlays';

import BemCssTransition from 'components/BemCssTransition';
import { popperTriggerBlock as block } from './utils';

export interface PopperTriggerProps {
  children?: React.ReactNode;
  open: boolean;
  onClose?: (e: React.SyntheticEvent) => void;
}

const PopperTrigger: React.FC<PopperTriggerProps> = ({
  children,
  open,
  onClose,
}) => (
  <RootCloseWrapper disabled={!open} onRootClose={onClose}>
    <BemCssTransition
      transitionClassName={block('transition')}
      in={open}
      timeout={300}
      mountOnEnter
      unmountOnExit
    >
      {children}
    </BemCssTransition>
  </RootCloseWrapper>
);

export default PopperTrigger;
