import _ from 'underscore';

import { actions as searchActions } from 'redux/middleware/api/image-search-service';
import { onVideoSearch } from 'redux/modules/mixpanel';
import { ThunkAction } from 'redux/types';
import { Type } from './action-types';
import { videoSearchSelector } from './selectors';
import { VideoSearchFailureAction, VideoSearchSuccessAction } from './types';

export const searchForVideos = (
  engine: string,
  query: string,
  page?: number,
): ThunkAction<
  VideoSearchFailureAction | Promise<VideoSearchSuccessAction>
> => (dispatch, getState) => {
  dispatch({
    payload: { engine },
    type: Type.VIDEOS_GET_REQUEST,
  });

  const engineState = videoSearchSelector(getState()).get(engine);

  const pageNumber = !_.isUndefined(page) ? page : engineState.page;
  const pageSize = !_.isUndefined(engineState.pageSize)
    ? engineState.pageSize
    : 10;
  const q = query || engineState.q;

  if (!q) {
    return dispatch({
      error: 'no query string',
      payload: { engine },
      type: Type.VIDEOS_GET_FAILURE,
    } as VideoSearchFailureAction);
  }

  if (!pageNumber) {
    dispatch(onVideoSearch(engine, q));
  }

  return dispatch(
    searchActions.searchForVideos(q, engine, pageNumber, pageSize),
  )
    .then(({ response }) =>
      dispatch({
        payload: response,
        type: Type.VIDEOS_GET_SUCCESS,
      } as VideoSearchSuccessAction),
    )
    .catch(err => {
      dispatch({
        error: err.message,
        payload: { engine },
        type: Type.VIDEOS_GET_FAILURE,
      });
      throw err;
    });
};

export const clearSearchResults = (engine: string) => ({
  payload: { engine },
  type: Type.VIDEO_SEARCH_RESULTS_CLEAR,
});

export const clearAllSearchResults = () => ({
  type: Type.VIDEO_SEARCH_RESULTS_CLEAR_ALL,
});
