import Pixels from './Pixels';
import { Unit } from './types';
import ViewportHeight from './ViewportHeight';
import ViewportWidth from './ViewportWidth';

export default function(value: number | string, unit?: Unit) {
  if (value === undefined || value === null) {
    return undefined;
  }

  if (typeof value === 'number') {
    if (!unit || unit === 'px') {
      return new Pixels(value);
    }

    if (unit === 'vw') {
      return new ViewportWidth(value);
    }

    return new ViewportHeight(value);
  }

  const units = value.slice(-2).toLowerCase();

  if (units === 'vw') {
    return new ViewportWidth(parseFloat(value));
  }

  if (units === 'vh') {
    return new ViewportHeight(parseFloat(value));
  }

  if (units === 'px') {
    return new Pixels(parseFloat(value));
  }

  throw new Error(`could not convert ${value} to a Measurement`);
}

export { default as Pixels } from './Pixels';
export { default as ViewportHeight } from './ViewportHeight';
export { default as ViewportWidth } from './ViewportWidth';
export { default as Measurement } from './Measurement';
