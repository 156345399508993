import * as React from 'react';

import FadingScrollBars from 'components/FadingScrollBars';
import { RndTextEditorData, RndTextHandlers } from 'components/RndTextEditor';
import Tabs from 'components/Tabs';
import { block } from '../../utils/block';

interface IProps {
  data?: RndTextEditorData;
  handlers?: RndTextHandlers;
  renderTextTemplateForm?: (
    data: RndTextEditorData,
    handlers: RndTextHandlers,
  ) => React.ReactNode;
  renderStyleForm?: (
    data: RndTextEditorData,
    handlers: RndTextHandlers,
  ) => React.ReactNode;
  renderTransitionForm?: (
    data: RndTextEditorData,
    handlers: RndTextHandlers,
  ) => React.ReactNode;
}

const StyleTabs: React.SFC<IProps> = ({
  data,
  handlers,
  renderStyleForm,
  renderTextTemplateForm,
  renderTransitionForm,
}) => (
  <Tabs
    defaultActiveTabKey="globalTemplateTab"
    id="global-style-tabs"
    tabs={[
      {
        content: (
          <FadingScrollBars
            className={block('fade-scroller')}
            hideTracksWhenNotNeeded
          >
            <div className={block('template-tab-content')}>
              {renderTextTemplateForm(data, handlers)}
            </div>
          </FadingScrollBars>
        ),
        tabKey: 'globalTemplateTab',
        title: 'styles',
      },
      {
        content: (
          <FadingScrollBars
            className={block('fade-scroller')}
            hideTracksWhenNotNeeded
          >
            <div className={block('custom-tab-content')}>
              {renderStyleForm(data, handlers)}
            </div>
          </FadingScrollBars>
        ),
        tabKey: 'globalCustomTab',
        title: 'custom',
      },
      {
        content: renderTransitionForm(data, handlers),
        tabKey: 'globalTransitionsTab',
        title: 'transitions',
      },
    ]}
  />
);

export default StyleTabs;
export { IProps as StyleTabProps };
