import { createSelector } from 'reselect';

import { State } from 'redux/types';
import { VideoEditState } from './types';

const videoEditSelector = (state: State): VideoEditState =>
  state.get('videoEdit');

export const videoUrlSelector = createSelector(
  videoEditSelector,
  videoEdit => videoEdit.videoUrl,
);
export const revokableSelector = createSelector(
  videoEditSelector,
  videoEdit => videoEdit.revokable,
);

export const videoAssetTypeSelector = createSelector(
  videoEditSelector,
  videoEdit => videoEdit.assetType,
);

/**
 * @return {number} duration in seconds
 */
export const videoDurationSelector = createSelector(
  videoEditSelector,
  videoEdit => videoEdit.durationSeconds,
);

export const videoPosterSelector = createSelector(
  videoEditSelector,
  videoEdit => videoEdit.poster,
);

export const videoEditErrorSelector = createSelector(
  videoEditSelector,
  videoEdit => videoEdit.error,
);

export const videoEntitySelector = createSelector(
  videoEditSelector,
  videoEdit => videoEdit.videoEntity,
);
