import cn from 'classnames';
import { RecordOf } from 'immutable';
import * as React from 'react';
import {
  Rnd as ReactRnd,
  Props as ReactRndProps,
  RndDragCallback,
  RndResizeCallback,
  RndResizeStartCallback,
} from 'react-rnd';
import _ from 'underscore';

import BemCssTransition from 'components/BemCssTransition';
import { Size } from 'types';
import bem from 'utils/bem';
import { omitUndefined } from 'utils/collections';

import * as types from './types';

const block = bem('rnd');

export interface IProps extends ReactRndProps {
  active?: boolean;
  activatable?: boolean;
  cornerHandleSize?: 'large' | 'small';
  minSize?: Size<number>;
  rndRef?: React.Ref<ReactRnd>;
  slackPx?: number;
  snapPoints?: types.ISnapPoint[];
}

interface IState {
  resize: RecordOf<types.IResizeState>;
  snap: RecordOf<types.ISnapState>;
  lockAspectRatio: boolean | number;
}
/**
 * Wraps react-rnd with "point snapping" and also corrects some prop type definitions
 * for react-rnd.
 */
class Rnd extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    activatable: true,
    cornerHandleSize: 'small',
    onDrag: _.noop,
    onDragStop: _.noop,
    onResizeStart: _.noop,
    onResizeStop: _.noop,
    slackPx: 5,
    snapPoints: [],
  };

  constructor(props: IProps) {
    super(props);

    const { lockAspectRatio } = props;

    this.state = {
      lockAspectRatio,
      resize: types.resizeFactory({
        resizing: false,
      }),
      snap: undefined,
    };
  }

  private handleResizeStart: RndResizeStartCallback = (e, dir, ref) => {
    const { lockAspectRatio, onResizeStart } = this.props;
    onResizeStart(e, dir, ref);

    this.setState(({ resize }) => ({
      lockAspectRatio: [
        'topRight',
        'topLeft',
        'bottomRight',
        'bottomLeft',
      ].includes(dir)
        ? true
        : lockAspectRatio,
      resize: resize.set('resizing', true),
    }));
  };

  private handleResizeStop: RndResizeCallback = (e, dir, ref, delta, pos) => {
    const { lockAspectRatio, onResizeStop } = this.props;
    onResizeStop(e, dir, ref, delta, pos);
    this.setState(({ resize }) => ({
      lockAspectRatio,
      resize: resize.set('resizing', false),
    }));
  };

  private wrapDragCallback = (callback: RndDragCallback) => (e, data) => {
    const { x, y } = data;
    const { x: snappedX, y: snappedY } = this.snap(x, y);
    const dragData = {
      ...data,
      x: snappedX,
      y: snappedY,
    };
    callback(e, dragData);
  };

  private snap(x: number, y: number) {
    const { snap } = this.state;

    const snappedX = this.snapCoord(x, 'x');
    const snappedY = this.snapCoord(y, 'y');

    if (snappedX.point || snappedY.point) {
      const axis = (() => {
        if (snappedX.point && snappedY.point) return 'both';
        if (snappedX.point) return 'x';
        return 'y';
      })();
      this.setSnap({ x: snappedX.value, y: snappedY.value }, axis);
    } else if (snap) {
      this.clearSnap();
    }
    return {
      x: snappedX.value,
      y: snappedY.value,
    };
  }

  private snapCoord(pos: number, axis: 'x' | 'y') {
    const { slackPx, snapPoints } = this.props;

    const { point: snapPoint } = snapPoints.reduce(
      (res, point) => {
        const { dist: minDist } = res;
        const dist = Math.abs(pos - point[axis]);
        if (dist <= slackPx && dist < minDist) {
          return { dist, point };
        }
        return res;
      },
      { dist: Infinity } as { point?: types.ISnapPoint; dist: number },
    );

    // note that `point` only exists in the reduce output when we have an actual point
    if (snapPoint) {
      return {
        axis,
        point: snapPoint,
        value: snapPoint[axis],
      };
    }

    return {
      axis,
      value: pos,
    };
  }

  private setSnap(pos: types.ICoords, axis: types.Axis) {
    this.setState({
      snap: types.snapFactory({ ...pos, axis }),
    });
  }

  private clearSnap() {
    this.setState({ snap: undefined });
  }

  private reactRndProps() {
    const {
      active,
      activatable,
      children,
      className,
      cornerHandleSize,
      slackPx,
      snapPoints,
      rndRef,
      ...restProps
    } = this.props;
    return restProps;
  }

  /*
   * specifying react-rnd drag axis provides exactly the functionaly needed for "stickiness". if an
   * axis is disabled and the user drags, we still get all the drag callbacks, but the draggable
   * doesn't move until we enable the axis.
   */
  private getRndDragAxis(snapState?: types.ISnapState) {
    const { snap: defaultSnapState } = this.state;
    const snap = snapState ?? defaultSnapState;
    if (!snap) return undefined;

    // if snapped to x, allow the user to only drag along the y axis
    if (snap.axis === 'x') return 'y';

    // if snaped to y, allow user to only drag along the x axis
    if (snap.axis === 'y') return 'x';

    // if both x and y are snapped, prevent the user from dragging along either axis
    if (snap.axis === 'both') return 'none';

    return undefined;
  }

  public render() {
    const {
      active,
      activatable,
      children,
      className,
      cornerHandleSize,
      dragAxis: axis,
      lockAspectRatio: lockAspectRatioProp,
      minSize,
      onDrag,
      onDragStop,
      resizeHandleClasses,
      resizeHandleStyles = {},
      rndRef,
    } = this.props;
    const { lockAspectRatio, resize } = this.state;

    const dragAxis = axis || (resize.resizing ? 'both' : this.getRndDragAxis());

    const cornerHandleClassName = block('corner-handle', {
      [cornerHandleSize]: !!cornerHandleSize,
    });

    const edgeHandleClassName = (edge: string) =>
      block('edge-handle', {
        [edge]: true,
        [cornerHandleSize]: !!cornerHandleSize,
        hidden: !!lockAspectRatioProp,
      });

    return (
      <ReactRnd
        {...this.reactRndProps()}
        className={cn(block({ active, activatable }), className)}
        dragAxis={dragAxis}
        lockAspectRatio={lockAspectRatio}
        onDrag={this.wrapDragCallback(onDrag)}
        onDragStop={this.wrapDragCallback(onDragStop)}
        onResizeStart={this.handleResizeStart}
        onResizeStop={this.handleResizeStop}
        minHeight={minSize?.height}
        minWidth={minSize?.width}
        ref={rndRef}
        resizeHandleStyles={{
          bottom: { bottom: -4 },
          left: { left: -4 },
          right: { right: -4 },
          top: { top: -4 },
          ...resizeHandleStyles,
        }}
        resizeHandleClasses={{
          bottom: edgeHandleClassName('bottom'),
          bottomLeft: cornerHandleClassName,
          bottomRight: cornerHandleClassName,
          left: edgeHandleClassName('left'),
          right: edgeHandleClassName('right'),
          top: edgeHandleClassName('top'),
          topLeft: cornerHandleClassName,
          topRight: cornerHandleClassName,
          ...resizeHandleClasses,
        }}
        style={omitUndefined({
          cursor: !active && activatable ? 'pointer' : undefined,
        })}
      >
        {children}
        <BemCssTransition
          in={active}
          transitionClassName={block('outline')}
          timeout={300}
        >
          <div />
        </BemCssTransition>
      </ReactRnd>
    );
  }
}

export default React.forwardRef<ReactRnd, IProps>((props, ref) => (
  <Rnd {...props} rndRef={ref} />
));
