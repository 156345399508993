import * as React from 'react';

import useObjectUrl from 'hooks/useObjectUrl';
import { useWheel } from 'hooks/useWheel';
import { numberOrPxToPx } from 'utils/placement';
import RndAsset, { RndAssetCallback } from '../assets/RndAsset';
import { useChildViewState, useNavigation } from '../context/NavigationContext';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { canvasBlock as block } from '../utils';
import Layer from './Layer';

const { useCallback } = React;

export interface WatermarkLayerProps {}

const WatermarkLayer: React.FC<WatermarkLayerProps> = () => {
  const [state, send] = useNavigation();
  const { service: childService } = useChildViewState();
  const templateState = useTemplateState();
  const dispatch = useTemplateDispatch();

  const { watermark } = templateState;
  const watermarkUrl = useObjectUrl(watermark?.url);

  const handlePlacementChange: RndAssetCallback = useCallback(
    value => {
      dispatch({
        type: 'WATERMARK_PLACEMENT_CHANGE',
        payload: value,
      });
    },
    [dispatch],
  );

  const handleImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const image = e.currentTarget;
      dispatch({
        payload: {
          naturalSize: numberOrPxToPx({
            height: image.naturalHeight,
            width: image.naturalWidth,
          }),
        },
        type: 'WATERMARK_LOAD',
      });
    },
    [dispatch],
  );

  const handleLayerDoubleClick = useCallback(() => {
    dispatch({ type: 'WATERMARK_LAYER_DOUBLE_CLICK' });
  }, [dispatch]);

  const handleLayerWheel = useWheel(
    useCallback(
      (e: React.WheelEvent<Element>) => {
        dispatch({
          type: 'WATERMARK_LAYER_WHEEL',
          payload: e,
        });
      },
      [dispatch],
    ),
  );

  const handleMouseDown = useCallback(() => {
    send({
      type: 'CHILD_VIEW_OPEN',
      payload: 'watermark',
      meta: { source: 'preview' },
    });
  }, [send]);

  const isLayerActive =
    state.matches({ child: 'image' }) && childService?.state.matches('open');

  return !watermark ? null : (
    <Layer
      active={isLayerActive}
      className={block('watermark-layer')}
      onDoubleClick={handleLayerDoubleClick}
      onWheel={handleLayerWheel}
    >
      <RndAsset
        assetId="watermark"
        onDragStop={handlePlacementChange}
        onResizeStop={handlePlacementChange}
        onMouseDown={handleMouseDown}
        {...watermark?.size}
        {...watermark?.position}
      >
        {watermarkUrl && (
          <img
            src={watermarkUrl}
            onLoad={handleImageLoad}
            style={{ height: '100%', width: '100%', pointerEvents: 'none' }}
          />
        )}
      </RndAsset>
    </Layer>
  );
};

export default WatermarkLayer;
