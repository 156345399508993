import { Button, Spacer } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';

import AutomationPostModalBackButton from 'components/AutomationPostModalBackButton';
import EducationalCard from 'components/EducationalCard/EducationalCard';
import LinkButton from 'components/LinkButton';
import ModalContentWithMedia from 'components/Modal/ModalContentWithMedia';
import { Step } from 'components/SteppedModal';
import TagsInput from '../../TagsInput';
import { block } from '../../utils';

interface UseVideoTagsStepProps {
  tags: string[];
  isActive: boolean;
  hideProgress: boolean;
  onTagsChange: (tags: string[]) => void;
  onBackClick: () => void;
  onHideModal: () => void;
}

export default function useVideoTagsStep({
  tags,
  isActive,
  hideProgress,
  onTagsChange,
  onBackClick,
  onHideModal,
}: UseVideoTagsStepProps): Step {
  const [defaultTags, setDefaultTags] = useState(tags);

  const handleBackClick = useCallback((): void => {
    onTagsChange(defaultTags);
    onBackClick();
  }, [defaultTags, onBackClick, onTagsChange]);

  const handleAddCategories = useCallback((): void => {
    if (!tags.includes('<categories>')) {
      onTagsChange([...tags, '<categories>']);
    }
  }, [onTagsChange, tags]);

  if (!isActive && tags !== defaultTags) {
    setDefaultTags(tags);
  }

  return {
    component: (
      <>
        <ModalContentWithMedia
          onClose={onHideModal}
          className="composer-step-content"
        >
          <ModalContentWithMedia.ModalContentWithMediaColumn>
            <img
              src="https://static.sparemin.com/static/webapp-assets/auto-posting-detail/auto-posting-detail-image-d-202303301450.png"
              className={block('composer-image-right')}
            />
          </ModalContentWithMedia.ModalContentWithMediaColumn>

          <ModalContentWithMedia.ModalContentWithMediaColumn className="composer-content-column">
            <Spacer
              orientation="vertical"
              align="center"
              justify="center"
              space={2.5}
              className={block('step-container')}
            >
              <AutomationPostModalBackButton
                text="Tags"
                description="Enter a comma after each tag, YouTube allows up to 500 characters worth."
                onClick={handleBackClick}
              />

              <TagsInput
                defaultValue={tags}
                onChange={onTagsChange}
                isMetadataEnabled={false}
                showUseCategoriesButton={false}
                showLabels={false}
                hideProgress={hideProgress}
              />

              <EducationalCard
                content={
                  <>
                    To pull in categories from your RSS feed as tags,{' '}
                    <LinkButton theme="cta" onClick={handleAddCategories}>
                      add “{'<categories>'}” above
                    </LinkButton>
                    . This uses any categories added to an episode to tag each
                    YouTube video. Nothing displays if categories aren't found
                    in the feed. All other tags stay the same for each video.
                  </>
                }
              />

              <Button
                onClick={onBackClick}
                variant="solid"
                className={block('composer-step-submit-button')}
              >
                save changes
              </Button>
            </Spacer>
          </ModalContentWithMedia.ModalContentWithMediaColumn>
        </ModalContentWithMedia>
      </>
    ),
    id: 'video-tags-step',
    hideFooter: true,
    replaceBody: true,
  };
}
