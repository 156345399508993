import cn from 'classnames';
import * as React from 'react';

import BemCssTransition, {
  BemCssTransitionProps,
} from 'components/BemCssTransition';
import { episodeListBlock as block } from '../../utils';

type PickedTransitionProps = Pick<
  BemCssTransitionProps,
  'onEnter' | 'onEntered' | 'onExit' | 'onExited'
>;

export interface EpisodeListRowStatusProps extends PickedTransitionProps {
  children?: React.ReactNode;
  className?: string;
  hide?: boolean;
  innerClassName?: string;
}

/**
 * the text on the right side of the row that slides in/out on hover
 */
const EpisodeListRowStatus: React.FC<EpisodeListRowStatusProps> = ({
  children,
  className,
  hide,
  innerClassName,
  onEnter,
  onEntered,
  onExit,
  onExited,
}) => (
  <BemCssTransition
    className={cn(block('status'), className)}
    in={!hide}
    onEnter={onEnter}
    onEntered={onEntered}
    onExit={onExit}
    onExited={onExited}
    transitionClassName={block('status-transition')}
    timeout={1000}
  >
    <div>
      <div className={cn(block('status-inner'), innerClassName)}>
        {children}
      </div>
    </div>
  </BemCssTransition>
);

export default EpisodeListRowStatus;
