import * as React from 'react';

import { IconProps } from './types';

const SocialSolidCircleInstagram: React.FC<IconProps> = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M15.668 12.077a3.583 3.583 0 0 0-3.588 3.59 3.583 3.583 0 0 0 3.588 3.59 3.583 3.583 0 0 0 3.589-3.59 3.583 3.583 0 0 0-3.589-3.59zm0 5.923a2.338 2.338 0 0 1-2.333-2.333 2.336 2.336 0 0 1 2.333-2.334 2.336 2.336 0 0 1 2.333 2.334A2.338 2.338 0 0 1 15.668 18zm4.573-6.07a.835.835 0 0 1-.837.838.837.837 0 1 1 .837-.837zm2.376.85c-.053-1.121-.309-2.115-1.13-2.933-.818-.819-1.812-1.075-2.933-1.131-1.155-.066-4.62-.066-5.775 0-1.118.053-2.111.31-2.933 1.128-.82.818-1.074 1.812-1.13 2.933-.066 1.156-.066 4.62 0 5.776.053 1.122.31 2.115 1.13 2.934.822.818 1.812 1.074 2.933 1.13 1.156.066 4.62.066 5.775 0 1.121-.053 2.115-.309 2.933-1.13.818-.819 1.074-1.812 1.13-2.934.066-1.156.066-4.617 0-5.773zm-1.492 7.013a2.362 2.362 0 0 1-1.33 1.331c-.922.366-3.109.281-4.127.281-1.018 0-3.207.082-4.126-.28a2.362 2.362 0 0 1-1.33-1.332c-.366-.921-.281-3.108-.281-4.126 0-1.019-.081-3.209.28-4.127a2.362 2.362 0 0 1 1.331-1.33c.922-.366 3.108-.282 4.126-.282 1.018 0 3.208-.081 4.126.281a2.362 2.362 0 0 1 1.33 1.33c.366.922.282 3.11.282 4.128 0 1.018.084 3.208-.281 4.126z"
        id="vkr6vrqija"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="#222831" cx="16" cy="16" r="16" />
      <use fill="#BEC8D2" fillRule="nonzero" xlinkHref="#vkr6vrqija" />
    </g>
  </svg>
);

export default SocialSolidCircleInstagram;
