import * as React from 'react';
import ReactPlayer from 'react-player';

import { Omit } from 'types';
import { createChainedFunction } from 'utils/functions';
import AddAudioStep, { AddAudioStepProps } from '../../AddAudioStep';
import { block } from '../../AudiogramWizard/utils';

type PickedAudioStepProps = Omit<
  AddAudioStepProps,
  'maxFileSizeMb' | 'video' | 'wizardType'
>;

export interface AutomatedAddAudioStepProps extends PickedAudioStepProps {
  onSampleVideoPlay?: () => void;
}

const AutomatedAddAudioStep: React.FC<AutomatedAddAudioStepProps> = ({
  onSampleVideoPlay,
  ...props
}) => {
  return (
    <AddAudioStep
      {...props}
      maxFileSizeMb={500}
      video={({ onPause, onPlay, playing }) => (
        <div style={{ paddingTop: '56.25%', position: 'relative' }}>
          <ReactPlayer
            className={block('upload-video')}
            height="100%"
            onPause={onPause}
            onPlay={createChainedFunction(onPlay, onSampleVideoPlay)}
            playing={playing}
            width="100%"
            url="https://player.vimeo.com/video/352593549"
          />
        </div>
      )}
      wizardType="automated"
    />
  );
};

export default AutomatedAddAudioStep;
