import cn from 'classnames';
import * as React from 'react';
import { isUndefined, noop } from 'underscore';

import IconButton from 'components/IconButton';
import { block } from '../utils';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  href?: string;
  onClick?: () => void;
  target?: string;
}

const ProjectTileButton: React.SFC<IProps> = ({
  children,
  className,
  disabled,
  href,
  onClick,
  target,
}) => {
  const cname = cn(block('button', { disabled }), className);

  if (isUndefined(href)) {
    return (
      <IconButton className={cname} disabled={disabled} onButtonClick={onClick}>
        {children}
      </IconButton>
    );
  }

  return (
    <IconButton
      as="a"
      href={href}
      target={target}
      className={cname}
      onButtonClick={onClick}
    >
      {children}
    </IconButton>
  );
};

ProjectTileButton.defaultProps = {
  disabled: false,
  onClick: noop,
};

export default ProjectTileButton;
export { IProps as ProjectTileButtonProps };
