import React, { useCallback } from 'react';

import DialogContent from 'components/DialogContent';
import { H2 } from 'components/Heading';
import { ModalFooterButton } from 'components/Modal';
import { LinkedinSocialCircle } from 'components/SocialCircle';
import { Step } from 'components/SteppedModal';
import useLinkedinAuthenticator, {
  UseLinkedInAuthenticatorConfig,
} from 'hooks/useLinkedinAuthenticator';
import { block } from '../utils';
import { LinkedinPostModalSteps } from './types';

interface UseAuthorizationStepConfig
  extends Pick<UseLinkedInAuthenticatorConfig, 'onAuthSuccess' | 'onError'> {
  onAuth: () => void;
}

export default function useAuthorizationStep({
  onAuth,
  onAuthSuccess,
  onError,
}: UseAuthorizationStepConfig): Step<LinkedinPostModalSteps> {
  const { authenticate, authenticating } = useLinkedinAuthenticator({
    force: true,
    onAuthSuccess,
    onError,
  });

  const handleAuthenticate = useCallback((): void => {
    onAuth();
    authenticate();
  }, [authenticate, onAuth]);

  return {
    id: 'authorization',
    fluidFooterButtons: true,
    component: (
      <DialogContent
        title="Let's add your account"
        details={
          <H2>
            We'll quickly send you to LinkedIn so you can give us permission to
            add videos to your account.
          </H2>
        }
        icon={<LinkedinSocialCircle className={block('icon')} />}
        detailsClassName={block('details')}
      />
    ),
    renderFooterButtons: ({ submit }) => [
      <ModalFooterButton
        {...submit}
        theme="submit"
        disabled={authenticating}
        onClick={handleAuthenticate}
      >
        <span>Sign in with LinkedIn</span>
      </ModalFooterButton>,
    ],
  };
}
