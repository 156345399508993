import { Type } from './action-types';
import { stateFactory } from './factories';
import { ImageToVideoAction, ImageToVideoState } from './types';

const defaultState = stateFactory();

export default function reducer(
  state: ImageToVideoState = defaultState,
  action: ImageToVideoAction,
) {
  switch (action.type) {
    case Type.IMAGE_TO_VIDEO_CREATE_REQUEST: {
      const { sourceImageUrl, provider } = action.payload;

      return state
        .set('sourceImageUrl', sourceImageUrl)
        .set('provider', provider)
        .set('imageToVideoGenerationSucceeded', false);
    }

    case Type.IMAGE_TO_VIDEO_CREATE_SUCCESS: {
      const { imageToVideoJobId } = action.payload;

      return state.set('imageToVideoJobId', imageToVideoJobId);
    }

    case Type.IMAGE_TO_VIDEO_CREATE_FAILURE: {
      return state.set('sourceImageUrl', undefined).set('provider', undefined);
    }

    case Type.IMAGE_TO_VIDEO_POLLING_BEGIN: {
      return state
        .set('hasError', false)
        .set('isPollingImageToVideo', true)
        .set('videos', []);
    }

    case Type.IMAGE_TO_VIDEO_POLLING_SUCCESS: {
      const { videos } = action.payload;

      return state.set('videos', videos);
    }

    case Type.IMAGE_TO_VIDEO_POLLING_FAILURE: {
      return state
        .set('isPollingImageToVideo', false)
        .set('hasError', true)
        .set('imageToVideoGenerationSucceeded', false);
    }

    case Type.IMAGE_TO_VIDEO_POLLING_END: {
      return state
        .set('isPollingImageToVideo', false)
        .set('imageToVideoGenerationSucceeded', true);
    }

    case Type.IMAGE_TO_VIDEO_RESET: {
      return stateFactory();
    }

    case Type.IMAGE_TO_VIDEO_CREATE_CANCEL:
      return state.set('shouldCancelPolling', true);

    case Type.IMAGE_TO_VIDEO_CREATE_ABORTED:
      return state
        .set('isPollingImageToVideo', false)
        .set('shouldCancelPolling', false);

    default:
      return state;
  }
}
