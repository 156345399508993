import 'emoji-mart/css/emoji-mart.css';

import cn from 'classnames';
import { EmojiData } from 'emoji-mart';
// @ts-ignore
import data from 'emoji-mart/data/twitter.json';
import NimblePicker from 'emoji-mart/dist-es/components/picker/nimble-picker';
import * as React from 'react';
import uncontrollable from 'uncontrollable';
import { noop } from 'underscore';

import FontAwesome from 'components/FontAwesome';
import MenuButton, { MenuButtonProps } from './MenuButton';
import { block } from './utils';

interface IProps extends Pick<MenuButtonProps, 'disabled'> {
  args?: any;
  className?: string;
  defaultIsMenuOpen?: boolean;
  isOpen?: boolean;
  onControlClick?: (args: any) => void;
  onSelect?: (native: string, args: any) => void;
  onToggleOpen?: (isOpen: boolean, args: any) => void;
}

export class EmojiPicker extends React.Component<IProps> {
  public static defaultProps = {
    onControlClick: noop,
    onSelect: noop,
    onToggleOpen: noop,
  };

  private handleIconClick = () => {
    const { args, isOpen, onControlClick, onToggleOpen } = this.props;
    onControlClick(args);
    onToggleOpen(!isOpen, args);
  };

  private handleMenuClose = () => {
    const { args, onToggleOpen } = this.props;
    onToggleOpen(false, args);
  };

  private handleEmojiSelect = (emoji: EmojiData) => {
    const { args, onSelect, onToggleOpen } = this.props;
    onSelect(emoji.native, args);
    onToggleOpen(false, args);
  };

  public render() {
    const { className, disabled, isOpen } = this.props;

    return (
      <MenuButton
        className={cn(block('emoji-picker'), className)}
        disabled={disabled}
        icon={<FontAwesome icon="smile" regular />}
        onIconClick={this.handleIconClick}
        onClose={this.handleMenuClose}
      >
        {isOpen && (
          <NimblePicker
            autoFocus
            custom={[]}
            data={data}
            native
            onSelect={this.handleEmojiSelect}
            emoji="point_up"
            title="Pick your emoji..."
          />
        )}
      </MenuButton>
    );
  }
}

export type EmojiPickerProps = IProps & {
  defaultIsOpen?: boolean;
};

const UncontrollableEmojiPicker: React.ComponentClass<EmojiPickerProps> = uncontrollable(
  EmojiPicker,
  {
    isOpen: 'onToggleOpen',
  },
);

export { UncontrollableEmojiPicker as default };
