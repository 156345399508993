import { List, Record } from 'immutable';

import { State } from './types';

export const stateFactory = Record<State>({
  claimResponse: undefined,
  claimRequestStatus: undefined,
  ownedPodcasts: List([]),
  ownedPodcastsRequestStatus: undefined,
  podcastId: undefined,
});
