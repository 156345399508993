import * as React from 'react';
import Textarea from 'react-textarea-autosize';
import _ from 'underscore';

import InputToggler from 'components/InputToggler';
import { Omit } from 'types';
import { prefix } from 'utils/ui';
import { block } from '../../utils';
import KeywordPhrase, { IKeyword, KeywordPhraseProps } from './KeywordPhrase';

export interface EditableKeywordPhraseProps
  extends Omit<KeywordPhraseProps, 'phrase'> {
  defaultInputMode?: boolean;
  inputStyle?: object;
  isSaving?: boolean;
  onCancelInput?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit?: (value: string) => void;
  onTogglePlay?: () => void;
  phrase: string;
}

interface State {
  inputMode: boolean;
}

export default class EditableKeywordPhrase extends React.Component<
  EditableKeywordPhraseProps,
  State
> {
  public static defaultProps: Partial<EditableKeywordPhraseProps> = {
    defaultInputMode: false,
    inputStyle: prefix({ minHeight: '100%' }),
    isSaving: false,
    onCancelInput: _.noop,
    onChange: _.noop,
    onSubmit: _.noop,
  };

  private input: HTMLTextAreaElement;

  public state: Readonly<State> = {
    inputMode: this.props.defaultInputMode,
  };

  public componentDidMount() {
    const { inputMode } = this.state;

    if (inputMode) {
      this.focusInput();
    }
  }

  public componentDidUpdate(__, prevState: Readonly<State>): void {
    const { inputMode: prevInputMode } = prevState;
    const { inputMode } = this.state;

    if (!prevInputMode && inputMode && this.input) {
      this.focusInput();
    }
  }

  private handleInputSubmit = (
    value: string,
    e: React.MouseEvent<HTMLInputElement>,
  ): void => {
    const { onSubmit } = this.props;

    e.preventDefault();
    e.stopPropagation();

    this.closeInput();

    onSubmit(value);
  };

  private handleOpenInput = () => this.setState({ inputMode: true });

  private handleCancelInput = () => {
    const { onCancelInput } = this.props;
    this.closeInput();
    onCancelInput();
  };

  private handleKeywordClick = (
    keyword: IKeyword,
    e: React.MouseEvent<HTMLElement>,
  ): void => {
    const { onKeywordClick } = this.props;
    e.preventDefault();
    e.stopPropagation();
    onKeywordClick(keyword, e);
  };

  private getKeywordPhraseProps(): KeywordPhraseProps {
    const { onChange, onSubmit, ...keywordPhraseProps } = this.props;

    return keywordPhraseProps;
  }

  private closeInput() {
    this.setState({ inputMode: false });
  }

  private focusInput() {
    this.input.focus();
    this.input.setSelectionRange(0, 0);
  }

  public render() {
    const { inputStyle, isSaving, phrase, onTogglePlay } = this.props;
    const { inputMode } = this.state;

    // don't let the user enter input mode if a save is in in progress
    const onOpen = isSaving ? undefined : this.handleOpenInput;

    const inputRef = el => {
      this.input = el;
    };

    const blockClassName = block();

    return (
      <InputToggler
        activeClassName={`${blockClassName}__text--edit`}
        className={block('text')}
        formClassName={block('form')}
        inputMode={inputMode}
        onCancel={this.handleCancelInput}
        onOpen={onOpen}
        onSubmit={this.handleInputSubmit}
        onTabPressed={onTogglePlay}
        renderInput={(value, onChange, onKeyDown) => (
          <Textarea
            className={block('text-input')}
            onChange={onChange}
            onKeyDown={onKeyDown}
            style={inputStyle}
            inputRef={inputRef}
            value={value}
          />
        )}
        renderValue={value => {
          const props = this.getKeywordPhraseProps();
          return (
            <KeywordPhrase
              {...this.getKeywordPhraseProps()}
              phrase={isSaving ? value : props.phrase}
              onKeywordClick={this.handleKeywordClick}
            />
          );
        }}
        value={phrase}
      />
    );
  }
}
