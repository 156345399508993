import cn from 'classnames';
import React from 'react';
import Select, { Overrides } from 'components/Select';
import { ShareFormField } from 'components/ShareForm';
import CategoryOption from './CategoryOption';
import {
  YoutubeCategorySelectorOption,
  YoutubeCategorySelectorProps,
} from './types';
import { block, formatYoutubeCategories, getCategoryOption } from './utils';

const selectComponentOverrides: Overrides<YoutubeCategorySelectorOption> = {
  control: {
    props: p => ({
      ...p,
      className: cn(block('control'), p.className),
    }),
  },
  option: {
    component: CategoryOption,
  },
};

const YoutubeCategorySelector: React.FC<YoutubeCategorySelectorProps> = ({
  options,
  onChange,
  value,
}) => {
  const formattedOptions = formatYoutubeCategories(options);

  const handleChange = (option: YoutubeCategorySelectorOption) => {
    onChange?.(option.value);
  };

  return (
    <ShareFormField label="Category">
      <Select
        className={block()}
        onChange={handleChange}
        options={formattedOptions}
        overrides={selectComponentOverrides}
        value={getCategoryOption(formattedOptions, value)}
      />
    </ShareFormField>
  );
};

export default YoutubeCategorySelector;
