import * as React from 'react';
import WaveSurferJs from 'wavesurfer.js';

const WaveSurferContext = React.createContext<WaveSurferJs>(null);

export const WaveSurferContainerContext = React.createContext<
  (el: HTMLDivElement) => void
>(null);

export default WaveSurferContext;
