import * as Immutable from 'immutable';
import { ServiceMethod as PodcastServiceMethod } from 'redux/middleware/api/podcast-service/types';
import { Type as AutomationCMSTypes } from 'redux/modules/automation-cms/action-types';
import { Type as PodcastSearchTypes } from 'redux/modules/podcast-search/action-types';
import { getValue } from '../../../utils/collections';
import * as types from './types';

export const entitiesDefaultState = Immutable.Map({
  callEntries: Immutable.Map(),
  entireAudios: Immutable.Map(),
  recordings: Immutable.Map(),
  users: Immutable.Map(),
  userPreferences: Immutable.Map(),
  transcripts: Immutable.Map(),
  videos: Immutable.Map(),
  waveforms: Immutable.Map(),
});

export default function reducer(state = entitiesDefaultState, action) {
  const entities = getValue(action, ['response', 'entities']);

  switch (action.type) {
    case types.ENTITIES_DELETE: {
      const { keys } = action.payload;
      return keys.reduce((s, key) => s.set(key, Immutable.Map()), state);
    }

    case PodcastSearchTypes.PODCAST_EPISODES_CLEAR: {
      const { podcastId } = action.payload;
      return state.withMutations(s => {
        // episodes need to be deleted from the podcast entitiy as well as the
        // podcastDetailEpisodes entity
        const episodes = s.getIn(
          ['podcasts', podcastId, 'episodes'],
          Immutable.List(),
        );
        s.deleteIn(['podcasts', podcastId, 'episodes']);
        s.update('podcastDetailEpisodes', podcastEpisodes =>
          podcastEpisodes.withMutations(pes => {
            episodes.forEach(episodeId => pes.delete(episodeId));
            return pes;
          }),
        );
        return s;
      });
    }

    case `${PodcastServiceMethod.GET_PODCAST_EPISODES}_SUCCESS`: {
      if (!entities) return state;
      const podcastId = action.response.result;
      const { episodes: newEpisodes, ...newPodcast } = entities.podcasts[
        podcastId
      ];
      return state.withMutations(s => {
        s.updateIn(
          ['podcasts', podcastId, 'episodes'],
          (episodes = Immutable.List()) => episodes.concat(newEpisodes),
        );
        s.mergeIn(['podcasts', podcastId], newPodcast);
        s.mergeIn(['podcastDetailEpisodes'], entities.podcastDetailEpisodes);
      });
    }

    case `${PodcastServiceMethod.DELETE_PODCAST_SUBSCRIPTION}_SUCCESS`: {
      const { id } = action.response;
      return state.deleteIn(['podcastSubscriptions', `${id}`]);
    }

    case AutomationCMSTypes.AUTOMATION_CMS_TEMPLATES_CLEAR:
      return state.delete('podcastWorkflowTemplates');

    case `${PodcastServiceMethod.GET_ENTIRE_EPISODE_TRANSCRIPT_INFO}_SUCCESS`:
    case `${PodcastServiceMethod.GET_ENTIRE_EPISODE_TRANSCRIPT_INFOS}_SUCCESS`: {
      const { podcastFeedEpisodes, ...restEntities } = action.response.entities;
      return state.withMutations(s => {
        s.mergeDeepIn(['podcastFeedEpisodes'], podcastFeedEpisodes);

        Object.keys(restEntities).forEach(entityName => {
          s.mergeIn([entityName], entities[entityName]);
        });

        return s;
      });
    }

    default:
    // fall out of switch into catch-all below
  }

  if (entities) {
    return state.withMutations(s => {
      Object.keys(entities).forEach(entityName =>
        s.mergeIn([entityName], entities[entityName]),
      );
    });
  }

  return state;
}
