import * as React from 'react';
import Spinner from 'react-spinkit';

import bem from 'utils/bem';

export interface IProps {
  spinnerClassName?: string;
  loadingText?: string;
}

const b = bem('infinite-scroll');

const InfiniteScrollLoader: React.FC<IProps> = ({
  spinnerClassName,
  loadingText,
}) => (
  <div className={spinnerClassName}>
    <Spinner
      key="infinite-spinner"
      className={b('spinner')}
      spinnerName="three-bounce"
    />
    {loadingText && <p className={b('loading-text')}>{loadingText}</p>}
  </div>
);

export default InfiniteScrollLoader;
