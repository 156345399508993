import { useCallback } from 'react';

import { useEditorState } from './context/VideoEditorStateContext';
import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';

export default function useTemplatesButton() {
  const dispatch = useTemplateDispatch();
  const { disabled } = useEditorState();

  return {
    disabled,
    onClick: useCallback(() => {
      dispatch({ type: 'TEMPLATES_BUTTON_CLICK' });
    }, [dispatch]),
  };
}
