import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { block } from '../../utils';

export interface AddAssetButtonProps {
  onClick?: (e: React.MouseEvent<HTMLElement>, params: any) => void;
  params?: any;
  style?: React.CSSProperties;
}

export default class AddAssetButton extends React.Component<
  AddAssetButtonProps
> {
  public static defaultProps: Partial<AddAssetButtonProps> = {
    onClick: noop,
  };

  private handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { onClick, params } = this.props;
    e.stopPropagation();
    onClick(e, params);
  };

  public render() {
    const { style } = this.props;

    return (
      <div className={block('add-asset-button')} style={style}>
        <button
          className={block('add-asset-button-element')}
          onClick={this.handleClick}
          type="submit"
        >
          <span
            className={cn(
              block('add-asset-button-icon'),
              'glyphicon',
              'glyphicon-plus',
            )}
          />
        </button>
      </div>
    );
  }
}
