import React from 'react';
import SelectMultiple from 'components/SelectMultiple';
import { SelectMultipleProps } from 'components/SelectMultiple/types';
import ShareFormField, { ShareFormFieldProps } from './ShareFormField';

type PickedMultiSelectProps = Pick<
  SelectMultipleProps,
  | 'disabled'
  | 'keepOpenOnClick'
  | 'onChange'
  | 'options'
  | 'overrides'
  | 'value'
>;

export type ShareFormMultiSelectProps = ShareFormFieldProps &
  PickedMultiSelectProps;

const ShareFormMultiSelect: React.FC<ShareFormMultiSelectProps> = ({
  disabled,
  keepOpenOnClick = true,
  onChange,
  options,
  overrides,
  value,
  ...fieldProps
}) => (
  <ShareFormField {...fieldProps}>
    <SelectMultiple
      {...{ disabled, keepOpenOnClick, onChange, options, overrides, value }}
    />
  </ShareFormField>
);

export default ShareFormMultiSelect;
