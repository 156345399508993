import cn from 'classnames';
import * as React from 'react';

import Button, { ButtonProps } from 'components/Button';
import { GoogleLogo } from 'components/icons';
import { block } from '../utils';

type IProps = ButtonProps;

const GoogleButton: React.SFC<IProps> = ({ className, ...restProps }) => (
  <Button className={cn(block('google-button'), className)} {...restProps}>
    <GoogleLogo width={34} height={34} />
    <span>{restProps.children}</span>
  </Button>
);

export default GoogleButton;
export { IProps as ModalFooterButtonProps };
