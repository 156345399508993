import * as React from 'react';

import UserGeneratedEmptyButton, {
  UserGeneratedEmptyButtonProps,
} from './UserGeneratedEmptyButton';

export type CreateProjectButtonProps = Pick<
  UserGeneratedEmptyButtonProps,
  'onClick'
>;

const CreateProjectButton: React.FC<CreateProjectButtonProps> = props => (
  <UserGeneratedEmptyButton theme="submit" {...props}>
    create a template
  </UserGeneratedEmptyButton>
);

export default CreateProjectButton;
