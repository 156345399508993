import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AddBillingCycleModal,
  AddBillingCycleModalParams,
  ModalDispatch,
  PushModalAction,
  ReplaceModalAction,
  UnshiftModalAction,
  UpgradePlanModal,
  UpgradePlanModalParams,
} from 'redux/modules/modal/types';
import { tierSelector } from 'redux/modules/pricing';
import { Dispatch, ThunkAction } from 'redux/types';
import { isPurchaseableTier } from 'utils/pricing';

type UseBillingCycleModal = AddBillingCycleModal | UpgradePlanModal;

type OpenModalAction =
  | PushModalAction
  | ReplaceModalAction
  | UnshiftModalAction;

type OpenModal<T extends OpenModalAction> = (modal: UseBillingCycleModal) => T;
type OpenBillingCycleModal = <T extends OpenModalAction>(
  params: AddBillingCycleModalParams,
  open?: OpenModal<T>,
) => OpenBillingCycleModalResult<T>;
type OpenBillingCycleModalResult<
  T extends OpenModalAction
> = T extends Parameters<ModalDispatch>[0]
  ? ReturnType<ModalDispatch>
  : T extends ThunkAction<infer R>
  ? R
  : never;
/**
 * The "billing cycle" modal is more accurately though of as a checkout modal.
 * There are two cases where we open this checkout modal - when purchasing a plan
 * and when upgrading a plan.  The difference between these two cases is that in
 * the latter case, we already have payment info associated with your account.
 *
 * This hoook will open the appropriate modal based on whether the user is upgrading
 * from free plan to paid plan or from paid plan to paid plan
 */
export default function useBillingCycleModal() {
  const dispatch = useDispatch<Dispatch>();
  const tier = useSelector(tierSelector);
  return {
    openBillingCycleModal: useCallback(
      (params, open) => {
        const openModal: OpenBillingCycleModal = <
          T extends OpenModalAction
        >() => {
          if (isPurchaseableTier(tier)) {
            return dispatch<OpenBillingCycleModalResult<T>>(
              open({
                name: 'UpgradePlan',
                params: params as UpgradePlanModalParams,
              }),
            );
          }
          return dispatch<OpenBillingCycleModalResult<T>>(
            open({
              name: 'AddBillingCycle',
              params,
            }),
          );
        };
        return openModal(params, open);
      },
      [dispatch, tier],
    ),
  };
}
