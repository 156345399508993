import * as React from 'react';

import { IconProps } from './types';

const Stamp: React.SFC<IconProps> = ({ fill = 'currentColor', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="14"
    viewBox="0 0 13 14"
    {...props}
  >
    <path
      fill={fill}
      fillRule="nonzero"
      d="M.813 13.893h11.374v-1.625H.813v1.625zm9.75-6.5h-1.69a.747.747 0 0 1-.748-.748v-.24c0-.695.225-1.356.545-1.973.231-.447.327-.975.23-1.534A2.452 2.452 0 0 0 6.938.93a2.44 2.44 0 0 0-2.875 2.4c0 .359.079.699.22 1.004.339.733.592 1.501.592 2.308v.002a.747.747 0 0 1-.747.748h-1.69A2.437 2.437 0 0 0 0 9.83v.813c0 .448.364.812.813.812h11.374a.813.813 0 0 0 .813-.812V9.83a2.437 2.437 0 0 0-2.438-2.437z"
    />
  </svg>
);

export default Stamp;
