import React from 'react';

import DialogContent from 'components/DialogContent';
import { CircularGradientMicrophone } from 'components/icons/circular-gradient';

import { block } from './utils';

interface DurationLimitModalContentsProps {
  onIncreaseDurationLimit: () => void;
}

const DurationLimitModalContents: React.FunctionComponent<DurationLimitModalContentsProps> = props => {
  const { onIncreaseDurationLimit } = props;

  return (
    <DialogContent
      actions={[
        {
          children: 'increase your limit',
          key: 'submit',
          onClick: onIncreaseDurationLimit,
          theme: 'rainbow',
        },
      ]}
      className={block()}
      details="Your current plan only supports audio files up to 2 hours long, but you can upgrade to increase your duration limit to 4 hours."
      icon={<CircularGradientMicrophone />}
      title="Use longer audio files"
    />
  );
};

export default DurationLimitModalContents;
