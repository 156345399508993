import * as React from 'react';

import { blockFeaturesBox as block } from './utils';

export interface ReferralsFeaturesBoxRowProps {
  description: React.ReactNode;
  icon: any;
  title: React.ReactNode;
}

const ReferralsFeaturesBoxRow: React.SFC<ReferralsFeaturesBoxRowProps> = ({
  description,
  icon,
  title,
}) => (
  <div className={block('row')}>
    <div className={block('row-icon')}>{icon}</div>

    <div className={block('row-text')}>
      <div className={block('row-title')}>{title}</div>
      <div className={block('row-description')}>{description}</div>
    </div>
  </div>
);

export default ReferralsFeaturesBoxRow;
