import React from 'react';

import { AspectRatioName } from 'types';
import AnimationCard from './AnimationCard';

import PlaceholderCards from './PlaceholderCards';

interface AIMediaLoaderProps {
  aspectRatio: AspectRatioName;
  isCancelling: boolean;
  isGeneratingImageToVideo: boolean;
  isGeneratingTextToImage: boolean;
  onCancelGeneration: () => void;
  sourceImageUrl: string;
}

const AIMediaLoader: React.FunctionComponent<AIMediaLoaderProps> = props => {
  const {
    aspectRatio,
    isCancelling,
    isGeneratingImageToVideo,
    isGeneratingTextToImage,
    onCancelGeneration,
    sourceImageUrl,
  } = props;

  if (isGeneratingTextToImage) {
    return <PlaceholderCards aspectRatio={aspectRatio} />;
  }

  return (
    <AnimationCard
      canCancel={isGeneratingImageToVideo}
      isCancelling={isCancelling}
      onCancelGeneration={onCancelGeneration}
      sourceImageUrl={sourceImageUrl}
    />
  );
};

export default AIMediaLoader;
