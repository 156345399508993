import * as React from 'react';

import { PaidSubscriptionButton } from 'blocks/Pricing/components/PaidSubscription';
import { H2, H3 } from 'components/Heading';
import { BasicCard } from 'components/icons';
import LinkButton from 'components/LinkButton';
import { createChainedFunction } from 'utils/functions';
import BillingHistoryLink from '../BillingHistoryLink';
import CardDetails from './CardDetails';
import usePaymentInfo from './usePaymentInfo';
import { block } from './utils';

export interface PaymentInfoProps {
  onUpdatePlanClick?: () => void;
  cardElement?: React.ReactNode;
}

const PaymentInfo: React.FC<PaymentInfoProps> = props => {
  const { onUpdatePlanClick, cardElement = <BasicCard /> } = props;
  const { card, hasCardInfo, onUpdateCard, onUpdatePlan } = usePaymentInfo();

  return (
    <div className={block()}>
      {cardElement}
      <div className={block('details')}>
        <H3>Payment details</H3>
        <H2>
          {hasCardInfo && (
            <CardDetails brand={card.brand} last4Digits={card.last4Digits} />
          )}
          <LinkButton onClick={onUpdateCard} theme="cta">
            {hasCardInfo ? 'UPDATE' : 'UPDATE CARD'}
          </LinkButton>
        </H2>
        <BillingHistoryLink>
          {linkProps => (
            <PaidSubscriptionButton theme="submit" {...linkProps}>
              View billing history
            </PaidSubscriptionButton>
          )}
        </BillingHistoryLink>

        <PaidSubscriptionButton
          theme="submit-alt"
          onClick={createChainedFunction(onUpdatePlanClick, onUpdatePlan)}
        >
          adjust my plan
        </PaidSubscriptionButton>
      </div>
    </div>
  );
};

export default PaymentInfo;
