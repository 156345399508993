import { ColorPaletteState } from '@sparemin/blockhead';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, noop } from 'underscore';
import SearchInput, { SearchInputProps } from 'components/SearchInput';
import { AiImageType } from 'redux/middleware/api/image-search-service';
import { keywordsSelector } from 'redux/modules/embed/selectors';
import { searchForImages } from 'redux/modules/image-search';
import { searchResultsSelector } from 'redux/modules/image-search/selectors';
import { isPollingImageToVideoSelector } from 'redux/modules/image-to-video/selectors';
import { isImagePollingTextToImageSelector } from 'redux/modules/text-to-image/selectors';
import { State } from 'redux/types';
import { AspectRatioName } from 'types';
import { getValue } from 'utils/collections';
import SearchKeywords from '../SearchKeywords';
import ImageSearchResults, {
  ImageSearchResultsProps,
} from './ImageSearchResults';

type PickedImageResultsProps = Pick<
  ImageSearchResultsProps,
  'onImageClick' | 'onTextToImageSuccess' | 'onImageToVideoSuccess'
>;
type PickedSearchProps = Pick<SearchInputProps, 'onSubmit'>;

export interface ImageSearchProps
  extends PickedSearchProps,
    PickedImageResultsProps {
  id: string;
  query?: string;
  selectedImageEngine?: string;
  className?: string;
  aspectRatioName?: AspectRatioName;
  imageType?: AiImageType;
  colorPaletteState: ColorPaletteState;
  onImageTypeChange: (value: AiImageType) => void;
  onAspectRatioNameChange: (value: AspectRatioName) => void;
  onInputChange?: (value: string) => void;
  onKeywordClick?: (keyword: string) => void;
  onEngineSelect?: (engine: string) => void;
  onAnimateGatedClick?: () => void;
}

const ImageSearch: React.FC<ImageSearchProps> = ({
  id,
  query,
  selectedImageEngine,
  className,
  aspectRatioName,
  onAspectRatioNameChange,
  imageType,
  colorPaletteState,
  onImageTypeChange,
  onInputChange,
  onKeywordClick,
  onEngineSelect,
  onImageClick,
  onTextToImageSuccess,
  onImageToVideoSuccess,
  onAnimateGatedClick,
  onSubmit,
}) => {
  const isTextToImage = selectedImageEngine === 'textToImage';

  const isGeneratingTextToImage = useSelector(
    isImagePollingTextToImageSelector,
  );
  const isGeneratingImageToVideo = useSelector(isPollingImageToVideoSelector);
  const keywords = useSelector(keywordsSelector);
  const imageSearchResults = useSelector((state: State) =>
    searchResultsSelector(state),
  );

  const dispatch = useDispatch();

  const handleLoadMoreImageResults = useCallback(
    (engine: string, page: number): void => {
      dispatch(searchForImages(engine, query, page));
    },
    [dispatch, query],
  );

  const handleOnGenerate = useCallback((): void => {
    onSubmit(query);
  }, [onSubmit, query]);

  const isSearching = useMemo(
    () =>
      (!isEmpty(imageSearchResults) &&
        getValue(imageSearchResults, [selectedImageEngine, 'isSearching'])) ||
      isGeneratingTextToImage ||
      isGeneratingImageToVideo,
    [
      imageSearchResults,
      isGeneratingImageToVideo,
      isGeneratingTextToImage,
      selectedImageEngine,
    ],
  );

  return (
    <Tab.Container
      id="images"
      activeKey={id}
      className={classNames('media-search', 'media-search--default', className)}
      onSelect={noop}
    >
      <div>
        <div className="media-search__right">
          <SearchInput
            placeholder={
              isTextToImage
                ? 'Describe the image you would like to generate as specifically as possible'
                : 'Enter Keyword'
            }
            buttonText="Search"
            value={query}
            theme={isTextToImage ? 'light' : 'dark'}
            hideButton={isTextToImage}
            disabled={isSearching}
            onChange={onInputChange}
            onSubmit={value => (isTextToImage ? null : onSubmit(value))}
          />

          <SearchKeywords
            keywords={keywords}
            disabled={isSearching}
            onKeywordClick={onKeywordClick}
          />

          <Tab.Content
            unmountOnExit
            className="media-search__results-content"
            animation={false}
          >
            <Tab.Pane eventKey={id}>
              <ImageSearchResults
                {...{
                  aspectRatioName,
                  imageType,
                  colorPaletteState,
                  onImageTypeChange,
                  onAspectRatioNameChange,
                  onTextToImageSuccess,
                  onAnimateGatedClick,
                }}
                hasQuery={Boolean(query?.length)}
                selectedEngine={selectedImageEngine}
                results={imageSearchResults}
                onGenerate={handleOnGenerate}
                onEngineSelect={onEngineSelect}
                onImageClick={onImageClick}
                onImageToVideoSuccess={onImageToVideoSuccess}
                onLoadMoreResults={handleLoadMoreImageResults}
              />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
};

export default ImageSearch;
