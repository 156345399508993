import * as React from 'react';
import { ProBadge } from 'components/icons';
import bem from 'utils/bem';

interface Props {
  avatarSrc: string;
  name: string;
  podcastName: string;
}

const block = bem('testimonial');

function Testimonial(props: React.PropsWithChildren<Props>) {
  const { avatarSrc, podcastName, name, children } = props;
  return (
    <div className={block()}>
      <div className={block('header')}>
        <img className={block('avatar')} src={avatarSrc} alt={name} />
        <div>
          {name}
          <ProBadge />
          <br />
          <span className={block('podcast')}>{podcastName}</span>
        </div>
      </div>
      <p className={block('body')}>{children}</p>
    </div>
  );
}

export default Testimonial;
