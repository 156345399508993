import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import _ from 'underscore';
import FadingScrollBars from 'components/FadingScrollBars';
import { DurationField } from 'components/Form';
import LoadingOverlay from 'components/LoadingOverlay';
import WaveGenerationInput from 'components/WaveGenerationInput';
import { AudioTrackType, Soundwave, SoundwaveOption } from 'types';
import { getValue } from 'utils/collections';
import { block } from './utils';

interface IProps {
  audioTrackType: AudioTrackType;
  className?: string;
  disabled?: boolean;
  endMillis?: number;
  onStartTimeChange?: (millis: number) => void;
  startMillis?: number;
  renderClipper: () => React.ReactElement<any>;
  uploadInProgress: boolean;
  fileName: string;
  waveGeneration?: string;
  onWaveGenerationChange?: (value: string) => void;
  defaultSoundwave: Soundwave;
  lockedWaveGeneration?: boolean;
  waveGenerationOptions?: SoundwaveOption[];
}

const AudioOptionsForm: React.SFC<IProps> = ({
  audioTrackType,
  disabled,
  onStartTimeChange,
  onWaveGenerationChange,
  startMillis,
  renderClipper,
  uploadInProgress,
  fileName,
  waveGeneration,
  defaultSoundwave,
  lockedWaveGeneration,
  waveGenerationOptions,
}) => {
  return (
    <div>
      {uploadInProgress && (
        <LoadingOverlay
          title="Adding audio to project"
          description="This may take a while, so please keep your browser open and check back later"
        />
      )}
      <Row className={block('top')}>
        <Col xs={7} className={block('uploader-col')}>
          <div className={block('filename')}>“{fileName}” selected</div>
          <div className={block('message')}>
            The selected audio will replace any existing audio on the track.
          </div>
        </Col>
        <Col xs={5} className={block('form-col')}>
          <FadingScrollBars
            className={block('scrollbars')}
            faderClassName={block('scrollbars-fader')}
            hideTracksWhenNotNeeded
          >
            <form>
              <DurationField
                disabled={disabled}
                horizontal={{
                  control: { xs: 5 },
                  label: { xs: 7 },
                }}
                label="Timeline Placement"
                onChange={onStartTimeChange}
                value={startMillis}
              />
              {audioTrackType === 'main' && (
                <WaveGenerationInput
                  onChange={onWaveGenerationChange}
                  value={waveGeneration}
                  defaultValue={getValue(defaultSoundwave, 'waveGeneration')}
                  variant={lockedWaveGeneration ? 'locked' : 'default'}
                  options={waveGenerationOptions}
                />
              )}
            </form>
          </FadingScrollBars>
        </Col>
      </Row>
      {renderClipper()}
    </div>
  );
};

AudioOptionsForm.defaultProps = {
  disabled: false,
  lockedWaveGeneration: false,
  onStartTimeChange: _.noop,
  onWaveGenerationChange: _.noop,
  waveGeneration: 'amplitudeBased',
};

export default AudioOptionsForm;
