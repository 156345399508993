import React from 'react';
import { Threads } from 'components/icons';
import SocialCircle from './SocialCircle';

export interface ThreadsSocialCircleProps {
  className?: string;
}

const ThreadsSocialCircle: React.FC<ThreadsSocialCircleProps> = ({
  className,
}) => (
  <SocialCircle className={className}>
    <Threads />
  </SocialCircle>
);

export default ThreadsSocialCircle;
