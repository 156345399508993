import { Type } from './action-types';
import { stateFactory } from './factories';
import { PodcastAction, PodcastState } from './types';

const defaultState = stateFactory();

export default function(
  state: PodcastState = defaultState,
  action: PodcastAction,
) {
  switch (action.type) {
    case Type.PODCAST_REFRESH_FEED_REQUEST: {
      return state.set('isRefreshingPodcastFeed', true);
    }

    case Type.PODCAST_REFRESH_FEED_FINISHED: {
      return state.set('isRefreshingPodcastFeed', false);
    }

    default:
      return state;
  }
}
