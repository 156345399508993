import * as React from 'react';
import { noop } from 'underscore';

import { SliderField } from 'components/Form';
import AudioFadeField from './AudioFadeField';
import VideoOptionsField from './VideoOptionsField';
import VideoOptionsSection from './VideoOptionsSection';

type OnChange = (
  val: number,
  field:
    | 'videoAudioLevel'
    | 'mainAudioLevel'
    | 'fadeInDurationMillis'
    | 'fadeOutDurationMillis',
) => void;

interface IProps {
  fadeInDurationMillis: number;
  fadeOutDurationMillis: number;
  onChange?: OnChange;
  mainAudioLevel: number;
  videoAudioLevel: number;
}

export default class AudioSection extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onChange: noop,
  };

  private handleVideoAudioLevelChange = (val: number) =>
    this.props.onChange(val, 'videoAudioLevel');

  private handleMainAudioLevelChange = (val: number) =>
    this.props.onChange(val, 'mainAudioLevel');

  private handleAudioFadeInChange = (millis: number) =>
    this.props.onChange(millis, 'fadeInDurationMillis');

  private handleAudioFadeOutChange = (millis: number) =>
    this.props.onChange(millis, 'fadeOutDurationMillis');

  public render() {
    const {
      fadeInDurationMillis,
      fadeOutDurationMillis,
      mainAudioLevel,
      videoAudioLevel,
    } = this.props;

    return (
      <VideoOptionsSection title="Audio">
        <VideoOptionsField>
          <SliderField
            label="Video Audio Level"
            onChange={this.handleVideoAudioLevelChange}
            value={videoAudioLevel}
          />
        </VideoOptionsField>
        <VideoOptionsField>
          <SliderField
            label="Main Audio Level"
            onChange={this.handleMainAudioLevelChange}
            value={mainAudioLevel}
          />
        </VideoOptionsField>
        <AudioFadeField
          label="Audio Fade-in"
          value={fadeInDurationMillis}
          onChange={this.handleAudioFadeInChange}
        />
        <AudioFadeField
          label="Audio Fade-out"
          value={fadeOutDurationMillis}
          onChange={this.handleAudioFadeOutChange}
        />
      </VideoOptionsSection>
    );
  }
}

export { OnChange as OnAudioOptionChange };
export { IProps as AudioSectionProps };
