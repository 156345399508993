import * as queryString from 'query-string';
import { isNumber } from 'underscore';
import { AutogramFrequency, AutoPostPlatform, WizardType } from 'types';
import { Measurement } from 'utils/measurement';
import { range } from 'utils/numbers';
import * as routes from 'utils/routes';
import { COMPATIBILITY_MODAL_SOURCES } from './constants';
import { AutogramStartingPoint } from './types';

const AUTOMGRAM_FREQ_MAP: Record<AutogramFrequency, AutogramStartingPoint> = {
  daily: 'LatestEpisode',
  fromFirstEpisode: 'BackCatalog',
  onPublish: 'LatestEpisode',
  weekly: 'LatestEpisode',
  targetedSeason: 'BackCatalog',
};

export const getWizardTypeFromLocation = (): WizardType => {
  const { type } = queryString.parse(window.location.search);
  switch (type) {
    case 'audio':
      return 'audio';
    case 'audiogram':
      return 'audiogram';
    case 'automated':
      return 'automated';
    case 'episode':
      return 'episode';
    case 'text':
      return 'textToVideo';
    case 'video-transcription':
      return 'videoTranscription';
    case 'template':
      return 'templateWizard';
    case 'autovideo':
      return 'autoVideosMVP';
    default:
      return null;
  }
};

export const getSourceFromLocation = () => {
  if (routes.isDownloadActive()) {
    return 'DLP';
  }
  if (routes.isEditorActive()) {
    return 'Editor';
  }
  if (routes.isCreateActive()) {
    return 'CreateTab';
  }
  if (routes.isAudiogramActive()) {
    return 'Audiogram';
  }
  if (routes.isFullEpisodeWizardActive()) {
    return 'FullEpisode';
  }
  if (routes.isAutogramActive()) {
    return 'Autogram';
  }
  if (routes.isTemplateWizardActive()) {
    return 'TemplateWizard';
  }
  if (routes.isSettingsActive()) {
    return 'Settings';
  }
  if (routes.isAudioWizardActive()) {
    return 'Audio';
  }
  if (routes.isOnboardingActive()) {
    return 'Onboarding';
  }

  return null;
};

export function getLanguageProperty() {
  return { translated: document.documentElement.lang };
}

export function getRetryPeriodBannerSource() {
  return routes.isCreateActive()
    ? 'CreateTab'
    : routes.isDownloadActive()
    ? 'DownloadPage'
    : routes.isMyPlanActive()
    ? 'MyPlan'
    : undefined;
}

export const getMixpanelBlurValue = (blurRadius: Measurement): number => {
  if (blurRadius && isNumber(blurRadius.value)) {
    return range(0, 10, 0, 100, blurRadius.value);
  }

  return undefined;
};

export function getAutoPost(platform: AutoPostPlatform | undefined) {
  switch (platform) {
    case 'youtube':
      return 'YouTube';

    case 'tiktok':
      return 'TikTok';

    default:
      return 'None';
  }
}

export const getAutogramStartingPoint = (
  freq: AutogramFrequency,
): AutogramStartingPoint => {
  return AUTOMGRAM_FREQ_MAP[freq];
};

export function getCompatibilityModalSource() {
  let key: keyof typeof COMPATIBILITY_MODAL_SOURCES;
  // eslint-disable-next-line guard-for-in
  for (key in COMPATIBILITY_MODAL_SOURCES) {
    const isRouteActive = COMPATIBILITY_MODAL_SOURCES[key];
    if (isRouteActive()) {
      return key;
    }
  }
  return undefined;
}
