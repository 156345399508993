import { MixpanelWizardStep } from 'redux/modules/mixpanel/types';

export enum WizardStep {
  ASPECT_RATIO = 'aspectRatio',
  CUSTOMIZE = 'customize',
  CLIP_AUDIO = 'clipAudio',
  LANGUAGE = 'language',
  EXPORTING = 'exporting',
  SUBMITTING = 'submitting',
}

export const MIXPANEL_WIZARD_STEP_MAP: Record<
  WizardStep,
  MixpanelWizardStep
> = {
  [WizardStep.ASPECT_RATIO]: 'Choose Aspect Ratio',
  [WizardStep.CUSTOMIZE]: 'Customize',
  [WizardStep.CLIP_AUDIO]: 'Clip Audio',
  [WizardStep.LANGUAGE]: 'Language',
  [WizardStep.SUBMITTING]: 'loading',
  [WizardStep.EXPORTING]: 'loading',
};
