import { isPlainObject } from 'is-plain-object';
import { newHistory } from 'redux-undo';
import * as ids from 'short-id';
import { isEmpty, omit, pick } from 'underscore';

import {
  CaptionsConfig,
  ITextOverlayV2,
  validateOverlayV2Integrity,
} from 'blocks/TextOverlayModal/v2';
import { TranscriptionFormValue } from 'containers/TranscriptionForm';
import { IVideoUpload } from 'redux/middleware/api/media-upload-service';
import {
  CaptionsOverride,
  CropMetadata,
  DeepImmutableMap,
  DeepPartial,
  Dimensions,
  IVideoClip,
  KeyAssetType,
  KeyImageType,
  KeyTextType,
  Omit,
  Position,
  ProgressAnimationOptions,
  ProgressType,
  Size,
  Soundwave,
  SoundwaveType,
  TimerOptions,
  TrackType,
} from 'types';
import { DYNAMIC_ELEMENTS } from 'utils/constants';
import merge from 'utils/deepmerge';
import {
  domTreeToHtmlString,
  htmlStringToTreeWalker,
  textContentFromHtmlString,
} from 'utils/dom';
import { getBaseCaptionsOverride } from 'utils/embed/captions/utils';
import { progressReducer } from 'utils/embed/progress';
import { defaultSoundwaveState } from 'utils/embed/soundwave';
import {
  getTextFitFontSize,
  scaleInlineStyles,
} from 'utils/embed/text-overlay';
import { getDefaultTimer, timerStringToViewport } from 'utils/embed/timer';
import { getNewTrackIndex } from 'utils/embed/tracks';
import measurement, {
  Measurement,
  Pixels,
  ViewportHeight,
  ViewportWidth,
} from 'utils/measurement';
import {
  fitElement,
  hasPosition,
  isFill,
  measurementToPx,
  measurementToViewport,
  replaceRect,
  scale,
  stringToViewport,
  viewportToPct,
} from 'utils/placement';

import {
  DynamicElementType,
  DynamicTitleType,
  ImageIntegrationData,
  IntroOutroType,
  LayerMoveOption,
  LayerState,
  LayerType,
  MediaIntegrationId,
  ProgressAlignment,
  ProgressSize,
  ProgressState,
  Slide,
  SoundwaveState,
  TextIntegrationData,
  TextIntegrationId,
  TextOverlay,
  TimerState,
  VideoClip,
  VideoClipState,
  VideoIntegrationData,
  VideoTemplateState,
  VideoTemplateStateContent,
  WatermarkState,
  WaveformFidelity,
  WaveformPlacement,
} from '../types';
import { getCaptionsFromConfig } from './captions';
import {
  DEFAULT_IMAGE_EFFECT,
  DEFAULT_WATERMARK_MARGIN_DISTANCE_PX,
  DEFAULT_WATERMARK_SIZE_PX,
} from './constants';
import { canDeleteLayer, moveLayer } from './layer-utils';
import {
  getReplacementSoundwaveDimensions,
  soundwavePlacementToDimensions,
} from './soundwave-placement';
import { formatPadding } from './text-overlay';

interface AddMediaMetaData {
  placement?: Dimensions<
    ViewportHeight,
    ViewportWidth,
    ViewportHeight,
    ViewportWidth
  >;
  targetLayerId?: string;
}

interface AddImageOptions {
  id?: string;
  original?: Blob | string;
  metadata?: CropMetadata;
  fileName?: string;
  integrationData?: ImageIntegrationData;
}

interface AddVideoOptions {
  id?: string;
  original?: Blob;
  metadata?: CropMetadata;
  fileName?: string;
  integrationData?: VideoIntegrationData;
}

interface AddVideoClipOptions {
  id?: string;
  original?: Blob;
  metadata?: CropMetadata;
  fileName?: string;
  integrationData?: VideoIntegrationData;
}

const VIDEO_MEDIA_INTEGRATION_TO_ASSET_TYPE_MAP = {
  [MediaIntegrationId.GIF]: 'gif',
  [MediaIntegrationId.VIDEOCLIP]: 'video',
} as const;

const overwriteMerge = (_, src) => src;

export function getStateContent(
  state: VideoTemplateState,
): VideoTemplateStateContent {
  return state?.present;
}

export function createState(
  content: VideoTemplateStateContent,
): VideoTemplateState {
  return newHistory([], content, []);
}

/**
 * create new state by deep merging mods into state.  changes are recorded
 * in the `patch` key
 */
function updateState(
  state: VideoTemplateStateContent,
  mods: DeepPartial<VideoTemplateStateContent>,
): VideoTemplateStateContent {
  return merge(
    state,
    {
      patch: mods,
      ...mods,
    },
    {
      arrayMerge: overwriteMerge,
      isMergeableObject: isPlainObject,
    },
  );
}

function getUnusedLayer(
  state: VideoTemplateStateContent,
  type: TrackType,
): string {
  const { layers } = state;
  const reversedLayers = [...layers?.order].reverse();

  if (type === 'text') {
    const { textOverlays } = state;
    return reversedLayers.find(layerId => {
      const layer = layers.data[layerId];

      // skip non-text layers since those can't be used for text assets
      if (layer.type !== 'text') return false;

      // check if there are any text overlays using the id.  if not, this id is
      // considered unused
      return textOverlays.order.every(overlayId => {
        const overlay = textOverlays.data[overlayId];
        return overlay.layerId !== layerId;
      });
    });
  }

  if (type === 'media') {
    const { slideshow, videoClips } = state;
    return reversedLayers.find(layerId => {
      const layer = layers.data[layerId];

      // skip non-media layers since those can't be used for media assets
      if (layer.type !== 'media') return false;

      // check if any slides are using this layer
      const unusedForSlideshow = slideshow.order.every(slideId => {
        const slide = slideshow.data[slideId];
        return slide.layerId !== layerId;
      });

      const unusedForVideo = videoClips.order.every(videoId => {
        const videoClip = videoClips.data[videoId];
        return videoClip.layerId !== layerId;
      });

      return unusedForSlideshow && unusedForVideo;
    });
  }

  if (type === 'waveform') {
    const { soundwave } = state;

    return reversedLayers.find(layerId => {
      const layer = layers.data[layerId];

      if (layer.type !== 'waveform') return false;

      return layerId !== soundwave?.layerId;
    });
  }
  return undefined;
}

/**
 * creates a new layer maintaining the track ordering logic
 */
function createNewLayer(
  state: VideoTemplateStateContent,
  type: LayerType,
  targetLayerId?: string,
): [string, LayerState] {
  // if a target layer id is specified, no new layers are created and curr layers + target id
  // are returned.
  if (targetLayerId) {
    return [targetLayerId, state.layers];
  }

  const unusedLayerId = getUnusedLayer(state, type);

  if (unusedLayerId) {
    return [unusedLayerId, state.layers];
  }

  const {
    layers: { order, data },
  } = state;
  const newIndex = getNewTrackIndex(order, data, type, 'free');
  const id = ids.generate();

  const orderCopy = [...order];

  // no track exists where this one should go
  if (orderCopy[newIndex] === undefined) {
    orderCopy[newIndex] = id;
  } else {
    // a track exists where this one should go, so splice it in
    orderCopy.splice(newIndex, 0, id);
  }

  return [
    id,
    {
      data: {
        ...data,
        [id]: { id, type },
      },
      order: orderCopy,
    },
  ];
}

/**
 * Obtains the count of remaining assets that will remain in a particular layer after deleting
 * an asset
 * @param {object} state - Current template editor's state
 * @param {string} layerId - Target layer id.
 * @returns {number} - The amount of remaining assets for the target layer
 */
const getRemainingAssetCountInLayer = (
  state: VideoTemplateStateContent,
  layerId: string,
): number => {
  const { slideshow, videoClips } = state;

  const slideLayerCount = slideshow.order
    .map(slideId => slideshow.data[slideId].layerId)
    .filter(slideLayerId => slideLayerId === layerId).length;
  const videoLayerCount = videoClips.order
    .map(videoId => videoClips.data[videoId].layerId)
    .filter(videoLayerId => videoLayerId === layerId).length;

  const nAssetsLeftInLayer = slideLayerCount + videoLayerCount - 1;

  return nAssetsLeftInLayer;
};

const getVideoUrlFromUpload = (src: IVideoUpload): string => {
  const webmVariation = src.variations?.find(
    variation => variation.type === 'transparent' && variation.isTransparent,
  );
  return webmVariation ? webmVariation.url : src.transcodedVideoUrl;
};

/**
 * Generates the base config for an UCS videoclip from its source, integration data (videoclip of gif) and the layer index.
 * @param {object} src - video upload response output
 * @param {object} integrationData - object containing the type of upload (videoclip or gif)
 * @param {number} layerIndex - layer index for the layer to which this clip is goin to be assigned
 * @returns {object} - a video clip object generated from the uploaded gif/video data.
 */
const createVideoClipFromVideoUpload = (
  src: IVideoUpload,
  integrationData: VideoIntegrationData,
  initialPlacement: Dimensions<
    ViewportHeight,
    ViewportWidth,
    ViewportHeight,
    ViewportWidth
  >,
): IVideoClip => {
  const placement = viewportToPct(initialPlacement);
  return {
    assetType: VIDEO_MEDIA_INTEGRATION_TO_ASSET_TYPE_MAP[integrationData.id],
    // this layer id should be set when exporting as doing it while adding implies updating
    // it each time a layer is added/removed
    layerId: -1,
    // as there are no current controls for the track positioning initial offset and start
    // milli are set to 0.
    playOffsetMilli: 0,
    position: {
      top: placement.top,
      left: placement.left,
    },
    sourceDurationMilli: src.durationMillis,
    startMilli: 0,
    style: {
      height: placement.height,
      width: placement.width,
    },
    videoUrl: getVideoUrlFromUpload(src),
    videoId: src.id,
    editor: {
      videoHeight: src.videoHeight,
      videoWidth: src.videoWidth,
      viewport: undefined,
      zoom: undefined,
    },
    previewThumbnail: {
      url: src.previewThumbnail.thumbnails?.[0]?.url,
    },
  };
};

/**
 * Allows resizing an asset to fit its layers dimensions when replacing it.
 * It fits the new asset's dimensions at the current layer and re-adjusts the
 * new asset dimensions in order not to lose the aspect ratio.
 * @param {object} state - current editor state
 * @param {object} currPlacement - previous asset placement
 * @param {object} [assetSize] - new asset size.
 * @returns {object} - a new dimensions object with the placement and size for the newly added asset.
 */
const getUpdatedPlacement = (
  state: VideoTemplateStateContent,
  currPlacement: Dimensions<
    ViewportHeight,
    ViewportWidth,
    ViewportHeight,
    ViewportWidth
  >,
  assetSize?: { height: number; width: number },
) => {
  // if asset size is not available (corner case), new placement ignores height and width
  if (!assetSize) {
    return {
      height: undefined,
      width: undefined,
      left: currPlacement.left,
      top: currPlacement.top,
    };
  }

  const { canvas } = state;

  // canvas dimensions are transformed to px, then the same is done with new asset
  // dimensions. Finally new dimensions are fit to canvas container and those dimensions
  // are measured with viewport units.
  const canvasPx = {
    height: new Pixels(canvas.height),
    width: new Pixels(canvas.width),
  };

  const containerPx = measurementToPx(currPlacement, canvasPx);

  const assetDimensions: Size<Pixels> = {
    height: new Pixels(assetSize.height),
    width: new Pixels(assetSize.width),
  };

  const fittedSize = fitElement(assetDimensions, containerPx, 'fit');

  return measurementToViewport(
    {
      height: fittedSize.height,
      left: currPlacement.left,
      top: currPlacement.top,
      width: fittedSize.width,
    },
    canvas,
  );
};

function deleteLayer(
  state: VideoTemplateStateContent,
  id: string,
): Pick<VideoTemplateStateContent, 'layers'> {
  const { layers } = state;

  const canDelete = canDeleteLayer(state, id);

  return {
    layers: !canDelete
      ? layers
      : {
          data: omit(layers.data, id),
          order: layers.order.filter(layerId => layerId !== id),
        },
  };
}

export function identify() {
  return ids.generate();
}

// TODO create an image utils file
export function isLottie(slide: Slide): boolean {
  return slide?.imageEffect?.effectType === 'lottie';
}

export function setAspectRatio(
  state: VideoTemplateStateContent,
  dimensions: Size<number> | number,
) {
  if (!dimensions) return state;

  const aspectRatio =
    typeof dimensions === 'number'
      ? dimensions
      : dimensions.width / dimensions.height;

  return {
    ...state,
    aspectRatio,
  };
}

export function setBackgroundColor(
  state: VideoTemplateStateContent,
  color: string,
) {
  return updateState(state, {
    backgroundColor: color,
  });
}

export function createSoundwaveRestorePoint(state: VideoTemplateStateContent) {
  const { soundwave } = state;
  return {
    ...state,
    soundwave: {
      ...soundwave,
      original: pick(
        soundwave,
        'type',
        'color',
        'height',
        'left',
        'top',
        'width',
      ),
    },
  };
}

export function setSoundwaveColor(
  state: VideoTemplateStateContent,
  color: string,
) {
  return updateState(state, {
    soundwave: {
      color,
      waveformPrefId: null,
    },
  });
}

function updateSoundwaveLayer(
  oldState: VideoTemplateStateContent,
  newState: VideoTemplateStateContent,
): VideoTemplateStateContent {
  const oldType = oldState.soundwave?.type ?? 'none';
  const newType = newState.soundwave?.type ?? 'none';

  if (oldType !== 'none' && newType === 'none') {
    const { layers } = newState;
    const layerId = layers.order.find(
      id => layers.data[id].type === 'waveform',
    );
    return {
      ...updateState(newState, {
        soundwave: {
          ...newState.soundwave,
          layerId: undefined,
        },
      }),
      ...deleteLayer(newState, layerId),
    };
  }

  if (oldType === 'none' && newType !== 'none') {
    const [layerId, layerState] = createNewLayer(newState, 'waveform');
    return updateState(newState, {
      soundwave: {
        ...newState.soundwave,
        layerId,
      },
      layers: layerState,
    });
  }

  return newState;
}

export function setSoundwaveType(
  state: VideoTemplateStateContent,
  newType: SoundwaveType,
) {
  const newState = updateState(state, {
    soundwave: {
      ...getReplacementSoundwaveDimensions(state, newType),
      type: newType,
      color: state?.soundwave.color ?? defaultSoundwaveState.get('waveColor'),
      waveformPrefId: null,
    },
  });

  return updateSoundwaveLayer(state, newState);
}

export function setSoundwaveDimensions(
  state: VideoTemplateStateContent,
  dims: Pick<SoundwaveState, 'height' | 'left' | 'top' | 'width'>,
) {
  return updateState(state, {
    soundwave: { ...dims, waveformPrefId: null },
  });
}

export function setSoundwavePlacement(
  state: VideoTemplateStateContent,
  placement: WaveformPlacement,
) {
  return updateState(state, {
    soundwave: {
      waveformPrefId: null,
      ...soundwavePlacementToDimensions(
        state.soundwave,
        state.aspectRatio,
        placement,
      ),
    },
  });
}

// helper function to set the soundwave state from the shape used elsewhere
// in the app
// TODO this probably belongs somewhere else.  might want to make the distinction
// between which of the state utils are used internally and which are intended
// to be used externally.  as implementation progresses, the functions which
// need to be available externally should become clear
//
export function setSoundwave(
  state: VideoTemplateStateContent,
  soundwave: Soundwave,
) {
  if (!soundwave) return state;
  const newState = updateState(state, {
    soundwave: {
      color: soundwave.waveColor,
      fidelity: soundwave.waveGeneration === 'accurate' ? 'hi-fi' : 'lo-fi',
      height: measurement(soundwave.waveSize.height),
      left: measurement(soundwave.wavePosition.left, 'vw'),
      top: measurement(soundwave.wavePosition.top, 'vh'),
      type: soundwave.waveType,
      width: measurement(soundwave.waveSize.width),
      waveformPrefId: soundwave.waveformPrefId,
    },
  });

  return updateSoundwaveLayer(state, newState);
}

export function setTemplateUCSCompatibility(
  state: VideoTemplateStateContent,
  isCompatible: boolean,
) {
  return updateState(state, {
    template: {
      ...state.template,
      isUCSEditorCompatible: isCompatible,
    },
  });
}

export function setTemplateUCSCompatibilityLoading(
  state: VideoTemplateStateContent,
  isLoading: boolean,
) {
  return updateState(state, {
    template: {
      ...state.template,
      isUCSEditorCompatibleLoading: isLoading,
    },
  });
}

// helper function to set the progress state from the shape used elsewhere
// in the app
// TODO this probably belongs somewhere else.  might want to make the distinction
// between which of the state utils are used internally and which are intended
// to be used externally.  as implementation progresses, the functions which
// need to be available externally should become clear
export function setProgressAnimationOptions(
  state: VideoTemplateStateContent,
  progress: ProgressAnimationOptions,
) {
  if (!progress) return state;
  return updateState(state, {
    progress: stringToViewport(progress),
  });
}

export function setProgressAlignment(
  state: VideoTemplateStateContent,
  alignment: ProgressAlignment,
): VideoTemplateStateContent {
  return updateState(state, {
    progress: progressReducer(state.progress, {
      type: 'UPDATE_POSITION',
      payload: alignment,
      meta: state.canvas,
    }),
  });
}

export function setProgressSize(
  state: VideoTemplateStateContent,
  size: ProgressSize,
): VideoTemplateStateContent {
  return updateState(state, {
    progress: progressReducer(state.progress, {
      type: 'UPDATE_SIZE',
      payload: size,
      meta: state.canvas,
    }),
  });
}

export function setProgressType(
  state: VideoTemplateStateContent,
  style: ProgressType | null,
): VideoTemplateStateContent {
  return updateState(state, {
    progress: progressReducer(state.progress, {
      type: 'UPDATE_TYPE',
      payload: style,
      meta: state.canvas,
    }),
  });
}

export function setTimerOptions(
  state: VideoTemplateStateContent,
  timer: TimerOptions<string>,
) {
  if (!timer) return state;

  return updateState(state, {
    timer: timerStringToViewport(timer),
  });
}

export function resetSoundwave(state: VideoTemplateStateContent) {
  const newState = updateState(state, {
    soundwave: {
      ...state.soundwave.original,
    },
  });
  return updateSoundwaveLayer(state, newState);
}

/**
 * Adds a new videoclip element to videoclips collection. It generates a new videoclip
 * based on an uploaded video element and attaches it to a layer.
 * @param {object} state - current template editor state
 * @param {object} src - new videoclip element to add upload data obtained from be from the upload operation
 * @param {object} options - filename, id and integration data of the new videoclip element
 * @param {object} metadata - placement and targetLayerId. Both are optional. placement will be used as an initial placemente whereas targetLayerId will be used to place the new asset at an existing free layer instead of using a new one.
 * @returns {object} - updated template editor state.
 */
export function addVideo(
  state: VideoTemplateStateContent,
  src: IVideoUpload,
  options: AddVideoClipOptions = {},
  metadata: AddMediaMetaData = {},
): VideoTemplateStateContent {
  const { canvas } = state;
  const { fileName, id = ids.generate(), integrationData } = options;
  const { placement, targetLayerId } = metadata;

  const [layerId, layers] = createNewLayer(state, 'media', targetLayerId);

  const canvasPx = {
    height: new Pixels(canvas.height),
    width: new Pixels(canvas.width),
  };

  const videoSize: Size<Pixels> = {
    height: new Pixels(src.videoHeight),
    width: new Pixels(src.videoWidth),
  };

  // If an initial placement is provided it will be used. Otherwise, element will be fitted
  // in canvas in order to calculate its initial placement.
  const initialPlacement =
    placement ||
    measurementToViewport(fitElement(videoSize, canvasPx, 'fit'), canvas);

  // Videoclip base data is created. This object contains the information that will be sent as
  // config to the BE
  const originalVideoClip = createVideoClipFromVideoUpload(
    src,
    integrationData,
    initialPlacement,
  );

  const newVideoClip: Omit<VideoClip, 'position' | 'style'> = {
    fileName,
    integrationData,
    id,
    layerId,
    original: originalVideoClip,
    placement: initialPlacement,
    videoUrl: getVideoUrlFromUpload(src),
  };

  return updateState(state, {
    layers,
    videoClips: {
      order: [...state.videoClips.order, id],
      data: {
        ...state.videoClips.data,
        [id]: newVideoClip,
      },
    },
  });
}

export function addImage(
  state: VideoTemplateStateContent,
  src: string | Blob | File,
  {
    fileName,
    id = ids.generate(),
    original,
    metadata,
    integrationData,
  }: AddImageOptions = {},
  { placement, targetLayerId }: AddMediaMetaData = {},
): VideoTemplateStateContent {
  const {
    mainImage,
    slideshow: { order, newImages, data },
  } = state;

  const isMainImage = mainImage?.ids.includes(id);

  const name =
    typeof src === 'string' ? fileName : (src as any)?.name ?? fileName;

  // on multi-layer lottie templates, it is required to check if targetLayerId exists.
  // this ensures that when replacing videoclip with an image it wont replace the main
  // lottie image.
  const mainLottieImageIds = mainImage?.ids.filter(currId =>
    isLottie(data[currId]),
  );
  if (mainLottieImageIds?.length && !targetLayerId) {
    return mainLottieImageIds.reduce((draftState, lottieImageId) => {
      return replaceImage(draftState, lottieImageId, src, 'image', {
        fileName,
        integrationData,
        metadata,
        original,
      });
    }, state);
  }

  const [layerId, layers] = createNewLayer(state, 'media', targetLayerId);

  const newSlide: Slide = {
    metadata,
    id,
    fileName: name,
    layerId,
    originalSrc: original,
    imageEffect: DEFAULT_IMAGE_EFFECT,
    imageSrc: src,
    imageType: isMainImage ? ('mainImage' as const) : undefined,
    integrationData,
    placement,
  };

  const slideshow = {
    data: {
      ...data,
      [newSlide.id]: newSlide,
    },
    newImages: [...newImages, newSlide.id],
    order: [...order, newSlide.id],
  };

  return updateState(state, {
    mainImage: !isMainImage
      ? mainImage
      : {
          ...mainImage,
          ids: [...mainImage.ids, id],
        },
    layers,
    slideshow,
  });
}

export function getFirstSlide(state: VideoTemplateStateContent) {
  const { slideshow } = state;
  return slideshow.data[slideshow.order[0]];
}

export function getOnlyTextOverlay(state: VideoTemplateStateContent) {
  const { textOverlays } = state;
  return textOverlays.data[textOverlays.order[0]];
}

/**
 * Deletes a videoclip asset from the template editor's state.
 * @param {object} state - Current template editor's state.
 * @param {string} id - Target videoclip to delete id.
 * @param {boolean} [keepLayer=false] - Wether to keep layer or not. This is useful when replacing an element because it will keep the layer and will allow to place a new asset on it.
 * @returns {object} - Updated template editor's state.
 */
export const deleteVideo = (
  state: VideoTemplateStateContent,
  id: string,
  keepLayer = false,
): VideoTemplateStateContent => {
  const { videoClips } = state;
  const videoClipAsset = videoClips.data[id];
  const { layerId } = videoClipAsset;

  // Counts slideshow and videoclips remaining layers, updates the videoclips order
  // and finally it removes the videoclip from data
  const nAssetsLeftInLayer = getRemainingAssetCountInLayer(state, layerId);
  const updatedOrder = state.videoClips.order?.filter(currId => currId !== id);
  const updatedClipsData = updatedOrder.reduce((acc, currKey) => {
    return { ...acc, [currKey]: state.videoClips.data[currKey] };
  }, {} as VideoClipState['data']);

  return {
    ...state,
    ...(nAssetsLeftInLayer === 0 && !keepLayer
      ? deleteLayer(state, layerId)
      : undefined),
    videoClips: {
      order: updatedOrder,
      data: updatedClipsData,
    },
  };
};

export function deleteImage(
  state: VideoTemplateStateContent,
  id: string,
  keepLayer = false,
) {
  const { mainImage, slideshow } = state;
  const slide = slideshow.data[id];
  const isLottieSlide = isLottie(slide);

  const { layerId } = slide;

  const nAssetsLeftInLayer = getRemainingAssetCountInLayer(state, layerId);

  const data = isLottieSlide
    ? {
        ...slideshow.data,
        [id]: {
          ...slideshow.data[id],
          imageSrc: '',
          metadata: undefined,
          originalSrc: '',
        },
      }
    : omit(slideshow.data, id);

  const order = isLottieSlide
    ? slideshow.order
    : slideshow.order.filter(otherId => otherId !== id);

  return {
    ...state,
    // if keep layer is enabled, layer deletion is omitted
    ...(nAssetsLeftInLayer === 0 && !keepLayer
      ? deleteLayer(state, layerId)
      : undefined),
    // if the slide being deleted was the main image, clear out the main image
    // id but leave the type unchanged. when the user adds another image, the
    // mainImage type will indicate that the new image should become the new
    // main image
    mainImage: mainImage && {
      ...mainImage,
      ids: mainImage.ids.filter(currId => isLottieSlide || currId !== id),
    },
    slideshow: {
      newImages: slideshow.newImages.filter(otherId => otherId !== id),
      data,
      order,
    },
  };
}

export function replaceImage(
  state: VideoTemplateStateContent,
  id: string,
  src: Blob | string,
  prevType: 'image' | 'none' | 'videoClip',
  {
    original,
    metadata,
    fileName,
    integrationData,
  }: Omit<AddImageOptions, 'id'>,
): VideoTemplateStateContent {
  if (prevType === 'none') {
    return state;
  }

  const { slideshow, videoClips } = state;
  const { newImages } = slideshow;

  // This new condition allows the possibility of replacing a video/gif with an image
  // asset. When this case is matched, target layer id, then video asset is removed
  // but its layer kept and new image is added using the target layer in order to avoid
  // adding it in a new layer.
  if (prevType === 'videoClip') {
    const videoClip = videoClips.data[id];
    const targetLayerId = videoClip?.layerId;
    return addImage(
      deleteVideo(state, id, true),
      src,
      { fileName, id, original, metadata, integrationData },
      { placement: videoClip.placement, targetLayerId },
    );
  }

  return updateState(state, {
    slideshow: {
      newImages: newImages.indexOf(id) >= 0 ? newImages : [...newImages, id],
      data: {
        [id]: {
          fileName,
          metadata,
          integrationData,
          imageSrc: src,
          originalSrc: original,
        },
      },
    },
  });
}

/**
 * Replaces a video/gif asset with a different video/gif or image one.
 * @param {object} state - Current template editor's state.
 * @param {string} id - Target asset to replace id.
 * @param {object} src - New videoclip element to add upload data obtained from be from the upload operation
 * @param {string} prevType - The type of the new asset that is going to be replace (image, videoClip or none)
 * @param {object} param4 - New asset options: fileName, integrationData, metadata and original element
 * @returns {object} - Updated template editor's state.
 */
export function replaceVideo(
  state: VideoTemplateStateContent,
  id: string,
  src: IVideoUpload,
  prevType: 'image' | 'none' | 'videoClip',
  {
    fileName,
    integrationData,
    metadata,
    original,
  }: Omit<AddVideoOptions, 'id'>,
): VideoTemplateStateContent {
  if (prevType === 'none') {
    return state;
  }

  const { slideshow, videoClips } = state;

  // This new condition allows the possibility of replacing an image with a video/gif
  // asset. When this case is matched, target layer id, then image asset is removed
  // but its layer kept and new video/gif is added using the target layer in order to
  // avoid adding it in a new layer.
  if (prevType === 'image') {
    const slide = slideshow.data[id];
    const targetLayerId = slide?.layerId;
    const newAssetDimensions = {
      height: src.videoHeight,
      width: src.videoWidth,
    };
    const placement = getUpdatedPlacement(
      state,
      slide.placement,
      newAssetDimensions,
    );
    return addVideo(
      deleteImage(state, id, true),
      src,
      { fileName, id, original, metadata, integrationData },
      { placement, targetLayerId },
    );
  }

  const currVideoClip = videoClips.data[id];

  const newAssetDimensions = { height: src.videoHeight, width: src.videoWidth };
  const fittedPlacement = getUpdatedPlacement(
    state,
    currVideoClip.placement,
    newAssetDimensions,
  );
  const originalVideoClip = createVideoClipFromVideoUpload(
    src,
    integrationData,
    fittedPlacement,
  );

  const newVideoClip: Omit<VideoClip, 'position' | 'style'> = {
    fileName,
    integrationData,
    id,
    layerId: currVideoClip?.layerId,
    original: originalVideoClip,
    placement: fittedPlacement,
    videoUrl: getVideoUrlFromUpload(src),
  };

  return updateState(state, {
    videoClips: {
      data: {
        ...state.videoClips.data,
        [id]: newVideoClip,
      },
    },
  });
}

export function recropImage(
  state: VideoTemplateStateContent,
  id: string,
  src: Blob,
  metadata: CropMetadata,
): VideoTemplateStateContent {
  const { slideshow } = state;
  const { newImages } = slideshow;

  return {
    ...state,
    slideshow: {
      ...slideshow,
      newImages: newImages.indexOf(id) >= 0 ? newImages : [...newImages, id],
      data: {
        ...slideshow.data,
        [id]: {
          ...slideshow.data[id],
          metadata,
          imageSrc: src,
        },
      },
    },
  };
}

export function scaleImage(
  state: VideoTemplateStateContent,
  id: string,
  multiplier: number,
): VideoTemplateStateContent {
  const { canvas, slideshow } = state;
  const slide = slideshow.data[id];

  return updateState(state, {
    slideshow: {
      data: {
        [id]: {
          placement: measurementToViewport(
            scale(measurementToPx(slide.placement, canvas), multiplier, {
              minArea: 500,
            }),
            canvas,
          ),
        },
      },
    },
  });
}

/**
 * Scales a video/gif asset to respond to the will zoom event
 * @param {object} state - Current template editor's state.
 * @param {string} id - Target asset to replace id.
 * @param {number} multiplier - Scaling multiplier factor.
 * @returns {object} - Updated template editor's state.
 */
export function scaleVideoClip(
  state: VideoTemplateStateContent,
  id: string,
  multiplier: number,
): VideoTemplateStateContent {
  const { canvas, videoClips } = state;
  const videoClip = videoClips.data[id];

  return updateState(state, {
    videoClips: {
      data: {
        [id]: {
          placement: measurementToViewport(
            scale(measurementToPx(videoClip.placement, canvas), multiplier, {
              minArea: 500,
            }),
            canvas,
          ),
        },
      },
    },
  });
}

export function scaleWatermark(
  state: VideoTemplateStateContent,
  multiplier: number,
): VideoTemplateStateContent {
  const { canvas, watermark } = state;
  const scaledPlacement = measurementToViewport(
    scale(
      measurementToPx(
        {
          ...watermark.position,
          ...watermark.size,
        },
        canvas,
      ),
      multiplier,
      { minArea: 500 },
    ),
    canvas,
  );

  return updateState(state, {
    watermark: {
      position: pick(scaledPlacement, 'left', 'top'),
      size: pick(scaledPlacement, 'height', 'width'),
    },
  });
}

export function recropWatermark(
  state: VideoTemplateStateContent,
  src: Blob,
  metadata: CropMetadata,
): VideoTemplateStateContent {
  const { watermark } = state;

  return {
    ...state,
    watermark: {
      ...watermark,
      metadata,
      url: src,
    },
  };
}

export function recropMainImage(
  state: VideoTemplateStateContent,
  src: Blob,
  metadata: CropMetadata,
): VideoTemplateStateContent {
  const { mainImage } = state;

  return mainImage.ids.reduce(
    (draftState, currId) => recropImage(draftState, currId, src, metadata),
    state,
  );
}

export function deleteOnlyImage(state: VideoTemplateStateContent) {
  const slide = getFirstSlide(state);
  return !slide ? state : deleteImage(state, slide.id);
}

export function replaceMainImage(
  state: VideoTemplateStateContent,
  url: string | Blob,
  metadata?: Omit<AddImageOptions, 'id'>,
): VideoTemplateStateContent {
  const { mainImage } = state;

  // main image a slideshow image.  if there's an id, just replace the image source
  if (mainImage?.ids.length) {
    return mainImage.ids.reduce(
      (draftState, currId) =>
        replaceImage(draftState, currId, url, 'image', {
          ...metadata,
          original: metadata?.original ?? url,
        }),
      state,
    );
  }

  // main image is slideshow but no image id.  add a new slide
  return addImage(state, url, {
    ...metadata,
    original: url,
  });
}

function replaceOverlayText(
  state: VideoTemplateStateContent,
  overlayId: string,
  replacementText: string,
) {
  const { aspectRatio, textOverlays } = state;
  const overlay = textOverlays.data[overlayId];
  const { size, textHtml } = overlay;

  const walker = htmlStringToTreeWalker(textHtml, NodeFilter.SHOW_TEXT);
  const root = walker.currentNode;

  let textNode = walker.nextNode();

  // this condition is still kept, if no text node at all is available it will throw an error
  if (!textNode) {
    throw new Error('Could not find text to replace');
  }

  // the walker is iterated until finding the node that matches the text value
  // this will replace text with the target value only if it matches the current text:
  // - if text is %%Episode%% or %%Podcast it will only fit episode on the node that has
  // the %%Episode%% as content.
  // - a corner case would be that the target text was a custom one and the content is the
  // same. In that case the replacement should not be harmful as it would replace one text
  // with the same text. This also covers the case of some templates that have "Episode Title"
  // or "Podcast Title" instead of the placeholders mentioned above.
  while (textNode && textNode.textContent !== overlay.text) {
    textNode = walker.nextNode();
  }

  // Replacement is only perfomed if the text node is actually found, otherwise no
  // replacement will be done.
  if (textNode) {
    // replace text
    textNode.textContent = replacementText;

    // this returns the font size, but also leaves the last "good" font size set
    // on the element
    getTextFitFontSize(textNode, size as any, overlay.style, aspectRatio);
  }

  return domTreeToHtmlString(root);
}

export function updateTextOverlayText(
  state: VideoTemplateStateContent,
  id: string,
  value: string,
  opts?: AddTextOverlayOptions,
): VideoTemplateStateContent {
  const { textOverlays } = state;
  const textOverlay = textOverlays.data[id];

  if (!textOverlay) {
    return state;
  }

  const textHtml = replaceOverlayText(state, id, value);
  return updateTextOverlay(state, id, {
    textHtml,
    text: value,
    ...opts,
  });
}

export function replaceKeyTextAsset(
  state: VideoTemplateStateContent,
  type: KeyTextType,
  value: string,
  opts?: AddTextOverlayOptions,
): VideoTemplateStateContent {
  const { [type]: textAsset } = state;

  if (!textAsset?.id) return state;

  if (textAsset.type === 'textOverlay') {
    return updateTextOverlayText(state, textAsset.id, value, opts);
  }

  if (textAsset.type === 'slideEffectText') {
    return updateSlideEffectText(state, textAsset.id, value, opts);
  }

  return state;
}

export function replaceKeyAsset(
  state: VideoTemplateStateContent,
  type: KeyImageType,
  src: string | Blob,
): VideoTemplateStateContent;
export function replaceKeyAsset(
  state: VideoTemplateStateContent,
  type: KeyTextType,
  text: string,
): VideoTemplateStateContent;
export function replaceKeyAsset(
  state: VideoTemplateStateContent,
  type: KeyAssetType,
  value: any,
) {
  if (type === 'mainImage') {
    return updateState(replaceMainImage(state, value), {
      mainImage: {
        src: value,
      },
    });
  }
  return replaceKeyTextAsset(state, type, value);
}

export function hasMedia(state: VideoTemplateStateContent) {
  const { mainImage, slideshow, videoClips } = state;

  return (
    !!mainImage?.ids.find(currId => slideshow?.data[currId]?.imageSrc) ||
    !!slideshow?.order?.length ||
    !!videoClips?.order?.length
  );
}

export function hasWatermark(state: VideoTemplateStateContent) {
  const { watermark } = state;

  return !!watermark;
}

export function hasText(state: VideoTemplateStateContent) {
  const { slideEffectText = {}, textOverlays } = state;
  const hasEffectText = Object.keys(slideEffectText).length > 0;
  const hasOverlayText = textOverlays?.order.length > 0;

  return hasEffectText || hasOverlayText;
}

export function setWaveformFidelity(
  state: VideoTemplateStateContent,
  fidelity: WaveformFidelity,
) {
  return {
    ...state,
    soundwave: {
      ...state.soundwave,
      waveformPrefId: null,
      fidelity,
    },
  };
}

/*
 * take an overlay output from the TextOverlay modal and transform it to the
 * shape needed for VideoTemplateEditorState.  Shape is the same but values are
 * slightly different in order to remove assumptions about untis (px, vw, etc.)
 */
function overlayToState(
  overlay: DeepImmutableMap<ITextOverlayV2>,
): Omit<TextOverlay, 'id' | 'layerId' | 'integrationData'> {
  const measurementContext = overlay.get('viewport').toJS();
  const style = overlay.get('style').toJS();
  const editor = overlay.get('editor')?.toJS?.();
  const textBuilderStyles = overlay.get('textBuilderStyles')?.toJS?.();

  const toVw = (val: number) =>
    new Pixels(val).toUnit('vw', measurementContext);
  const toVh = (val: number) =>
    new Pixels(val).toUnit('vh', measurementContext);

  return {
    editor,
    position: {
      left: toVw(overlay.getIn(['position', 'left'])),
      top: toVh(overlay.getIn(['position', 'top'])),
    },
    size: {
      height: toVh(overlay.getIn(['size', 'height'])),
      width: toVw(overlay.getIn(['size', 'width'])),
    },
    style: {
      ...style,
      fontSize: toVw(style.fontSize),
      ...formatPadding(style, (vw: number) => new ViewportWidth(vw)),
    },
    text: validateOverlayV2Integrity(overlay)
      ? overlay.get('text')
      : textContentFromHtmlString(overlay.get('textHtml') || ''),
    textBuilderStyles,
    textHtml: scaleInlineStyles(overlay.get('textHtml'), ['fontSize'], val =>
      toVw(val).toString(),
    ),
    version: overlay.get('version'),
  };
}

interface AddTextOverlayOptions {
  integrationData: TextIntegrationData;
}

export function addTextOverlay(
  state: VideoTemplateStateContent,
  textOverlay: DeepImmutableMap<ITextOverlayV2>,
  opts?: AddTextOverlayOptions,
): VideoTemplateStateContent {
  if (!textOverlay) return state;

  const id = ids.generate();
  const integrationData = opts?.integrationData;

  const [layerId, layers] = createNewLayer(state, 'text');

  const newOverlay: TextOverlay = {
    id,
    layerId,
    integrationData,
    ...overlayToState(textOverlay),
  };

  return updateState(state, {
    layers,
    textOverlays: {
      data: {
        [id]: newOverlay,
      },
      order: [...state.textOverlays?.order, id],
    },
  });
}

export function updateTextOverlay(
  state: VideoTemplateStateContent,
  id: string,
  textOverlay: DeepPartial<TextOverlay>,
): VideoTemplateStateContent {
  return updateState(state, {
    textOverlays: {
      data: {
        [id]: textOverlay,
      },
    },
  });
}

export function checkIsDynamicElement(
  element: string,
): element is DynamicElementType {
  return DYNAMIC_ELEMENTS.includes(element);
}

export function updateDynamicTextElements(
  state: VideoTemplateStateContent,
  id: string,
  textOverlay: TextOverlay,
  value: string,
  type: DynamicTitleType,
): VideoTemplateStateContent {
  const textHtml = replaceOverlayText(state, id, value);

  return updateState(state, {
    textOverlays: {
      data: {
        [id]: {
          ...textOverlay,
          textHtml,
          text: value,
          integrationData: {
            id: TextIntegrationId.DYNAMIC,
            type,
          },
        },
      },
    },
  });
}

export function updateImmutableTextOverlay(
  state: VideoTemplateStateContent,
  id: string,
  textOverlay: DeepImmutableMap<ITextOverlayV2>,
): VideoTemplateStateContent {
  return updateTextOverlay(state, id, overlayToState(textOverlay));
}

export function setTextDimensions(
  state: VideoTemplateStateContent,
  id: string,
  placement: Dimensions<Pixels>,
): VideoTemplateStateContent {
  const { canvas } = state;
  const { height, width, top, left } = measurementToViewport(placement, canvas);

  return updateState(state, {
    textOverlays: {
      data: {
        [id]: {
          size: { height, width },
          position: { top, left },
        },
      },
    },
  });
}

export function setCaptionsDimensions(
  state: VideoTemplateStateContent,
  placement: Dimensions<Pixels>,
): VideoTemplateStateContent {
  const { canvas } = state;
  const { height, width, top, left } = measurementToViewport(placement, canvas);

  return updateState(state, {
    captions: {
      hasBeenEdited: true,
      position: {
        left,
        top,
      },
      size: {
        height,
        width,
      },
    },
  });
}

export function deleteTextOverlay(
  state: VideoTemplateStateContent,
  textOverlayId: string,
): VideoTemplateStateContent {
  const { textOverlays } = state;

  const { layerId } = textOverlays.data[textOverlayId];
  const nAssetsInLayer =
    textOverlays.order
      .map(id => textOverlays.data[id].layerId)
      .filter(overlayLayerId => overlayLayerId === layerId).length - 1;

  return {
    ...updateState(state, {
      textOverlays: {
        data: {
          ...textOverlays.data,
          [textOverlayId]: undefined,
        },
        order: textOverlays.order.filter(id => id !== textOverlayId),
      },
    }),
    ...(nAssetsInLayer === 0 ? deleteLayer(state, layerId) : undefined),
  };
}

export function setProgress(
  state: VideoTemplateStateContent,
  opts: Partial<ProgressState>,
): VideoTemplateStateContent {
  if (!opts) return state;

  return updateState(state, {
    progress: opts,
  });
}

export function setTimer(
  state: VideoTemplateStateContent,
  opts: Partial<TimerState>,
): VideoTemplateStateContent {
  if (!opts) return state;

  const { aspectRatio, timer: currentTimer } = state;

  return updateState(state, {
    timer: {
      ...(!currentTimer ? getDefaultTimer(aspectRatio) : undefined),
      ...opts,
    },
  });
}

export function updateSlideEffectText(
  state: VideoTemplateStateContent,
  id: string,
  text: string,
  opts?: AddTextOverlayOptions,
): VideoTemplateStateContent {
  return updateState(state, {
    slideEffectText: {
      [id]: { text, ...opts },
    },
  });
}

export function updateSlidePlacement(
  state: VideoTemplateStateContent,
  id: string,
  placement: Dimensions<Pixels>,
): VideoTemplateStateContent {
  const { canvas } = state;

  return updateState(state, {
    slideshow: {
      data: {
        [id]: {
          placement: measurementToViewport(
            {
              height: placement.height,
              left: placement.left,
              top: placement.top,
              width: placement.width,
            },
            canvas,
          ),
        },
      },
    },
  });
}

/**
 * Updates a video/gif asset placement
 * @param {object} state - Current template editor's state.
 * @param {string} id - Target asset to replace id.
 * @param {object} placement - New placement object.
 * @returns {object} - Updated template editor's state.
 */
export function updateVideoClipPlacement(
  state: VideoTemplateStateContent,
  id: string,
  placement: Dimensions<Pixels>,
): VideoTemplateStateContent {
  const { canvas } = state;

  const updatedPlacement = measurementToViewport(
    {
      height: placement.height,
      left: placement.left,
      top: placement.top,
      width: placement.width,
    },
    canvas,
  );

  return updateState(state, {
    videoClips: {
      data: {
        [id]: {
          placement: updatedPlacement,
        },
      },
    },
  });
}

export function updateSlideBlurRadius(
  state: VideoTemplateStateContent,
  id: string,
  blurRadius: number,
) {
  return updateState(state, {
    slideshow: {
      data: {
        [id]: {
          blurRadius,
        },
      },
    },
  });
}

export function setInitialSlidePlacement(
  state: VideoTemplateStateContent,
  id: string,
  naturalSize: Size<Pixels>,
): VideoTemplateStateContent {
  const { canvas, slideshow } = state;
  const canvasPx = {
    height: new Pixels(canvas.height),
    width: new Pixels(canvas.width),
  };

  const slide = slideshow.data[id];

  const placement = (() => {
    if (!hasPosition(slide.placement)) {
      return fitElement(naturalSize, canvasPx, 'fit');
    }

    const slidePlacement = measurementToPx(slide.placement, canvasPx);

    if (isFill(slidePlacement, canvasPx)) {
      return fitElement(naturalSize, canvasPx, 'fill');
    }

    return replaceRect(slidePlacement, naturalSize, canvasPx);
  })();

  return updateState(state, {
    slideshow: {
      data: {
        [id]: {
          placement: measurementToViewport(
            {
              height: placement.height,
              left: placement.left,
              top: placement.top,
              width: placement.width,
            },
            canvas,
          ),
        },
      },
    },
  });
}

/**
 * Sets the initial placement for a particular video/gif element.
 * Updates a video/gif asset placement
 * @param {object} state - Current template editor's state.
 * @param {string} id - Target asset to replace id.
 * @param {object} position - Initial top and left position.
 * @param {object} size - Initial height and width.
 * @returns {object} - Updated template editor's state.
 */
export const setInitialVideoClipPlacement = (
  state: VideoTemplateStateContent,
  id: string,
  position: { top: number; left: number },
  size: { height: number; width: number },
) => {
  const { canvas, videoClips } = state;

  if (!videoClips.data[id]) {
    return state;
  }

  const placement = measurementToViewport(
    {
      ...{ top: new Pixels(position.top), left: new Pixels(position.left) },
      ...{ height: new Pixels(size.height), width: new Pixels(size.width) },
    },
    canvas,
  );

  return updateState(state, {
    videoClips: {
      data: {
        [id]: {
          placement,
        },
      },
    },
  });
};

export function setInitialWatermarkPlacement(
  state: VideoTemplateStateContent,
  naturalSize: Size<Pixels>,
): VideoTemplateStateContent {
  const { canvas, watermark } = state;
  const currentPlacement = { ...watermark.position, ...watermark.size };

  const canvasPx = {
    height: new Pixels(canvas.height),
    width: new Pixels(canvas.width),
  };
  const placement = !hasPosition(currentPlacement)
    ? fitElement(naturalSize, canvasPx, 'fit')
    : replaceRect(
        measurementToPx(currentPlacement, canvasPx),
        naturalSize,
        canvasPx,
      );

  return updateState(state, {
    watermark: {
      position: measurementToViewport(
        {
          left: placement.left,
          top: placement.top,
        },
        canvas,
      ),
      size: measurementToViewport(
        {
          height: placement.height,
          width: placement.width,
        },
        canvas,
      ),
    },
  });
}

export function addWatermark(
  state: VideoTemplateStateContent,
  watermark: {
    original: string | Blob;
    src: string | Blob;
    position?: Position<Measurement>;
    size?: Size<Measurement>;
    integrationData?: ImageIntegrationData;
  },
): VideoTemplateStateContent {
  const { canvas } = state;

  // Initial position at the top right of the screen.
  const position = {
    left: new Pixels(
      canvas?.width -
        DEFAULT_WATERMARK_SIZE_PX -
        DEFAULT_WATERMARK_MARGIN_DISTANCE_PX,
    ).toUnit('vw', canvas),
    top: new Pixels(DEFAULT_WATERMARK_MARGIN_DISTANCE_PX).toUnit('vh', canvas),
  };

  const size = {
    height: new Pixels(DEFAULT_WATERMARK_SIZE_PX).toUnit('vh', canvas),
    width: new Pixels(DEFAULT_WATERMARK_SIZE_PX).toUnit('vw', canvas),
  };

  return updateState(state, {
    watermark: {
      integrationData: watermark.integrationData,
      position: isEmpty(position) ? undefined : position,
      originalUrl: watermark.original,
      size: isEmpty(size) ? undefined : size,
      url: watermark.src,
    },
  });
}

export function updateWatermark(
  state: VideoTemplateStateContent,
  patch: Partial<WatermarkState>,
): VideoTemplateStateContent {
  return updateState(state, {
    watermark: patch,
  });
}

export function setTranscription(
  state: VideoTemplateStateContent,
  transcription: TranscriptionFormValue,
): VideoTemplateStateContent {
  return updateState(state, { transcription });
}

export function setCaptions(
  state: VideoTemplateStateContent,
  captions: CaptionsConfig,
): VideoTemplateStateContent {
  return updateState(state, { captions });
}

export function updateCaptionsConfig(
  state: VideoTemplateStateContent,
  captions?: CaptionsOverride,
): VideoTemplateStateContent {
  return updateState(state, { captions });
}

export function setInitialCaptionsConfig(
  state: VideoTemplateStateContent,
  transcription: TranscriptionFormValue,
): VideoTemplateStateContent {
  if (!transcription?.transcribe) {
    return state;
  }

  if (transcription.transcribe && state.captions) {
    return state;
  }

  const baseCaptionsOverride = getCaptionsFromConfig(
    getBaseCaptionsOverride(state.aspectRatio),
  );

  return updateState(state, { captions: baseCaptionsOverride });
}

export function modifyStateContent(
  state: VideoTemplateState,
  modify: (content: VideoTemplateStateContent) => VideoTemplateStateContent,
): VideoTemplateState {
  return {
    ...state,
    present: modify(getStateContent(state)),
  };
}

/**
 * Adds an intro/outro clip depending on the type provided. It requires a fully loaded
 * clip with its source object
 * @param {object} state - current template editor state
 * @param {string} type - clip to add type: intro | outro
 * @param {object} src - selected video clip upload object
 * @param {string} fileName - Uploaded clip's file name
 * @returns {object} - Updated state
 */
export const addIntroOutro = (
  state: VideoTemplateStateContent,
  type: IntroOutroType,
  src: IVideoUpload,
  fileName: string,
): VideoTemplateStateContent => {
  return updateState(state, {
    introOutro: {
      ...state.introOutro,
      [type]: {
        id: src.id,
        fileName,
        thumbnailUrl: src.previewThumbnail?.thumbnails?.[0]?.url,
        loaded: true,
      },
    },
  });
};

/**
 * Replaces a fully loaded intro/outro clip entry.
 * @param {object} state - current template editor state
 * @param {string} type - clip to replace type: intro | outro
 * @param {object} src - selected video clip upload object
 * @param {string} fileName - Uploaded clip's file name
 * @returns {object} - Updated state
 */
export const replaceIntroOutro = (
  state: VideoTemplateStateContent,
  type: IntroOutroType,
  src: IVideoUpload,
  fileName: string,
): VideoTemplateStateContent => {
  return updateState(state, {
    introOutro: {
      [type]: {
        id: src.id,
        fileName,
        thumbnailUrl: src.previewThumbnail?.thumbnails?.[0]?.url,
        loaded: true,
      },
    },
  });
};

/**
 * Removes a intro/outro clip element from the template editor state
 * @param {object} state - current template editor state
 * @param {string} type - clip to replace type: intro | outro
 * @returns {object} - Updated state
 */
export const deleteIntroOutro = (
  state: VideoTemplateStateContent,
  type: IntroOutroType,
): VideoTemplateStateContent => {
  return updateState(state, {
    introOutro: {
      [type]: undefined,
    },
  });
};

/**
 * Sets a not loaded intro/outro clip as loaded and completes its data
 * @param {object} state - current template editor state
 * @param {string} type - clip to set as loaded type: intro | outro
 * @param {object} src - loaded video clip upload object
 * @param {string} fileName - loaded clip's file name
 * @returns {object} - Updated state
 */
export const loadIntroOutroData = (
  state: VideoTemplateStateContent,
  type: IntroOutroType,
  src: IVideoUpload,
  fileName: string,
): VideoTemplateStateContent => {
  return updateState(state, {
    introOutro: {
      ...state.introOutro,
      [type]: {
        fileName,
        thumbnailUrl: src.previewThumbnail?.thumbnails?.[0]?.url,
        loaded: true,
      },
    },
  });
};

/**
 * Side utility for parsing the initial intro/outro state. If it is an url that
 * that was previously loaded, it will copy current state. Otherwise it will generate
 * a brand new entry
 * @param {object} currState - Current intro/outro editor state
 * @param {string} [currUrl] - Current default intro/outro clip url.
 * @returns {object | undefined} - The parsed intro/outro state for editor
 */
const getIntroOutroInitialState = (
  currState:
    | VideoTemplateStateContent['introOutro']['intro']
    | VideoTemplateStateContent['introOutro']['outro'],
  currUrl?: string,
):
  | VideoTemplateStateContent['introOutro']['intro']
  | VideoTemplateStateContent['introOutro']['outro'] => {
  if (currState?.url === currUrl) {
    return currState;
  }

  return currUrl ? { loaded: false, url: currUrl } : undefined;
};

/**
 * Sets intro/outro clips default state from prefs. The pref elements wont be set
 * if there is no pref set for the particular type. Also, selected clips will only
 * contain its url, but its loaded state will be set to false.
 * @param {object} state - current template editor state
 * @param {object} introOutro - Object containing introDefaultVideoUrl and outroDefaultVideoUrl (both optionals)
 * @param {object} currVideoTemplateState - Current editor state. It is used for carrying the previous intro/outro url when switching templates
 * @returns {object} - Updated state
 */
export const setIntroOutroDefaultPrefs = (
  state: VideoTemplateStateContent,
  introOutro: {
    introDefaultVideoUrl?: string;
    outroDefaultVideoUrl?: string;
  },
  currVideoTemplateState?: VideoTemplateStateContent,
): VideoTemplateStateContent => {
  const { introDefaultVideoUrl, outroDefaultVideoUrl } = introOutro;

  const intro = getIntroOutroInitialState(
    currVideoTemplateState?.introOutro?.intro,
    introDefaultVideoUrl,
  );
  const outro = getIntroOutroInitialState(
    currVideoTemplateState?.introOutro?.outro,
    outroDefaultVideoUrl,
  );

  return updateState(state, {
    introOutro: { intro, outro },
  });
};

export const setNewLayerPosition = (
  state: VideoTemplateStateContent,
  layerId: string,
  moveTo: LayerMoveOption,
): VideoTemplateStateContent => {
  const newOrder = moveLayer(state, layerId, moveTo);

  return updateState(state, {
    layers: {
      ...state.layers,
      order: newOrder,
    },
  });
};
