import cn from 'classnames';
import * as React from 'react';
import { ModalFooter as BsModalFooter } from 'react-bootstrap';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  noSeparator?: boolean;
}

const ModalFooter: React.SFC<IProps> = ({
  children,
  className,
  noSeparator,
}) => (
  <BsModalFooter
    className={cn(
      'hl-modal__footer',
      {
        'hl-modal__footer--no-separator': noSeparator,
      },
      className,
    )}
  >
    {children}
  </BsModalFooter>
);

export default ModalFooter;
export { IProps as ModalFooterProps };
