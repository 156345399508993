import * as React from 'react';

import { Omit } from 'types';
import { block } from '../utils';
import VideoAsset, { VideoAssetProps } from './VideoAsset';

export type GifVideoAssetProps = Omit<
  VideoAssetProps,
  'loopable' | 'posterClassName'
>;

const GifVideoAsset: React.SFC<GifVideoAssetProps> = props => (
  <VideoAsset
    {...props}
    posterClassName={block('gif-video-asset-thumb')}
    loopable
  />
);

export default GifVideoAsset;
