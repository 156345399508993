import { Type } from './action-types';
import { stateFactory } from './factories';
import { MixpanelAction, MixpanelState } from './types';

const defaultState = stateFactory();

export default function reducer(
  state: MixpanelState = defaultState,
  action: MixpanelAction,
) {
  switch (action.type) {
    case Type.MIXPANEL_SET_COMPLETE_WIZARD_PROPS:
      return state.set('completeWizardProps', action.payload.props);

    case Type.MIXPANEL_CLEAR:
      return defaultState;

    case Type.MIXPANEL_INITIALIZE:
      return state.set('initialized', true);

    default:
      return state;
  }
}
