import * as React from 'react';

import PaidSubscriptionButton, {
  PaidSubscriptionButtonProps,
} from './PaidSubscriptionButton';

export type KnowledgeBaseButtonProps = PaidSubscriptionButtonProps;

const KnowledgeBaseButton: React.SFC<KnowledgeBaseButtonProps> = props => (
  <PaidSubscriptionButton
    href="https://learn.headliner.app/hc"
    theme="submit"
    target="_blank"
    {...props}
  />
);

KnowledgeBaseButton.defaultProps = {
  children: 'visit our knowledge base',
};

export default KnowledgeBaseButton;
