import classNames from 'classnames';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Masonry from 'react-masonry-component';
import _ from 'underscore';

import FadingScrollBars from 'components/FadingScrollBars';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  gridClassName?: string;
  hasMore?: boolean;
  isActive?: boolean;
  itemSelector: string;
  onLoadMore?: (page: number) => void;
  scrollClassName?: string;
}

const InfiniteGrid: React.SFC<IProps> = ({
  children,
  className,
  gridClassName,
  hasMore,
  isActive,
  itemSelector,
  onLoadMore,
  scrollClassName,
}) => (
  <FadingScrollBars className={classNames('infinite-grid', className)}>
    <InfiniteScroll
      className={scrollClassName}
      hasMore={isActive && hasMore}
      loadMore={onLoadMore}
      useWindow={false}
    >
      <Masonry
        className={classNames('infinite-grid__grid', gridClassName)}
        elementType="div"
        options={{ itemSelector }}
      >
        {children}
      </Masonry>
    </InfiniteScroll>
  </FadingScrollBars>
);

InfiniteGrid.defaultProps = {
  hasMore: false,
  isActive: false,
  onLoadMore: _.noop,
};

export default InfiniteGrid;
export { IProps as InfiniteGridProps };
