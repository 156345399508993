import * as React from 'react';

import ErrorPage from 'containers/ErrorPage';

export interface ErrorBoundaryProps {
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<{}, State> {
  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public state: Readonly<State> = {
    hasError: false,
  };

  public render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return hasError ? <ErrorPage /> : <>{children}</>;
  }
}
