import React from 'react';
import LinkButton from 'components/LinkButton';
import { block } from './utils';

type Props = React.ComponentPropsWithoutRef<typeof LinkButton>;

function NonIdealStateLinkButton(props: Props) {
  return (
    <LinkButton
      theme="cta"
      uppercase
      size="md"
      {...props}
      className={block('link-button')}
    />
  );
}

export default NonIdealStateLinkButton;
