import * as React from 'react';
import PlanCost, { PlanCostProps } from 'blocks/Pricing/components/PlanCost';

import { CalloutCta, CalloutCtaProps } from '../PlanDecisionModal';
import { block } from './utils';

type PickedCtaProps = Pick<CalloutCtaProps, 'ctaLabel' | 'onClick' | 'pro'>;
type PickedCostProps = Pick<
  PlanCostProps,
  'cost' | 'period' | 'theme' | 'title'
>;

export type LegacyDowngradeModalCtaProps = PickedCtaProps & PickedCostProps;

const LegacyDowngradeModalCta: React.FC<LegacyDowngradeModalCtaProps> = ({
  ctaLabel,
  onClick,
  period,
  cost,
  pro,
  theme,
  title,
}) => (
  <CalloutCta {...{ ctaLabel, onClick, pro }}>
    <PlanCost
      className={block('plan-cost')}
      {...{ cost, period, theme, title }}
    />
  </CalloutCta>
);

export default LegacyDowngradeModalCta;
