import cn from 'classnames';
import * as React from 'react';

import { ThinWand } from 'components/icons';
import ProjectButton from '../../containers/ProjectButton';
import { INewProjectButtonProps } from './types';

const AutomationButton: React.SFC<INewProjectButtonProps> = ({
  className,
  description,
  icon,
  title,
  onClick,
  ...rest
}) => (
  <ProjectButton
    {...rest}
    className={cn('automation-button', className)}
    icon={icon}
    onClick={onClick}
    projectType="autovideo"
    title={title}
    description={description}
    to={{
      pathname: '/wizard',
      query: {
        type: 'autovideo',
      },
    }}
  />
);

AutomationButton.defaultProps = {
  description:
    'We’ll create and post videos for you each time a new podcast episode is released',
  icon: <ThinWand />,
  title: 'Automatic audiograms',
};

export default AutomationButton;
