import * as React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

const BetaIcon: React.SFC<IProps> = props => {
  return (
    <svg width={30} height={14} viewBox="0 0 30 14" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M155 0a2 2 0 012 2v10a2 2 0 01-2 2h-26a2 2 0 01-2-2V2a2 2 0 012-2h26zm-21.346 4H131v6h2.81c1.428 0 2.173-.6 2.173-1.637 0-.754-.388-1.269-.994-1.483.443-.257.722-.72.722-1.32 0-.934-.698-1.56-2.057-1.56zm7.874 0h-4.105v6h4.206V8.886h-2.957V7.497h2.523v-1.08h-2.523V5.114h2.856V4zm5.612 0h-4.734v1.131h1.738V10h1.257V5.131h1.74V4zm3.43 0h-1.241l-2.422 6h1.289l.48-1.286h2.523l.482 1.286H153l-2.43-6zm-16.853 3.471c.651 0 1 .24 1 .746 0 .514-.349.737-1 .737h-1.467V7.471h1.467zm16.225-2.108l.861 2.297h-1.723l.862-2.297zm-16.443-.317c.613 0 .947.231.947.703 0 .471-.334.711-.947.711h-1.25V5.046h1.25z"
        filter="url(#beta-icon-filter)"
        transform="translate(-127)"
      />
    </svg>
  );
};

export default BetaIcon;
export { IProps as BetaIconProps };
