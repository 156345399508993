import * as React from 'react';
import { IconProps } from './types';

function Fox(props: IconProps) {
  return (
    <svg width={50} height={20} {...props}>
      <path
        d="M37.694 0l2.512 4.313L42.75 0h6.646l-5.883 9.601L50 20h-6.837l-3.053-5.052L37.153 20h-6.678l6.36-10.34L30.825 0h6.869zM12.91 0l.413 5.465h-7.25v2.6h5.914V13.5H6.073v6.44H0V0h12.91zm10.46.199c2.847 0 5.278.955 7.293 2.866 2.015 1.911 3.023 4.218 3.023 6.92 0 2.72-1.008 5.037-3.023 6.948-2.015 1.91-4.446 2.866-7.292 2.866-2.867 0-5.309-.955-7.324-2.866-2.015-1.911-3.022-4.227-3.022-6.949 0-2.702 1.007-5.008 3.022-6.919 2.015-1.91 4.457-2.866 7.324-2.866zm.063 4.024c-.498 0-.924.165-1.278.492-.353.329-.53.724-.53 1.187v8.078c0 .444.177.83.53 1.158.353.328.78.492 1.278.492.499 0 .92-.164 1.262-.492.343-.328.515-.714.515-1.158V5.902c0-.463-.172-.858-.515-1.187a1.758 1.758 0 00-1.262-.492z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default Fox;
