import * as React from 'react';

import { block, getFormattedDate } from '../../utils';

export interface ProjectTileContentsProps {
  projectName: string;
  updatedAtMillis: number;
}

const ProjectTileContents: React.FC<ProjectTileContentsProps> = ({
  projectName,
  updatedAtMillis,
}) => (
  <>
    <div className={block('title')}>{projectName}</div>
    <div className={block('date-modified')}>
      {getFormattedDate(updatedAtMillis)}
    </div>
  </>
);

export default ProjectTileContents;
