import React from 'react';

// types
import { IconProps } from './types';

const SpacerArrow: React.FunctionComponent<IconProps> = ({ ...svgProps }) => (
  <svg viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
    <path
      d="m15.452 13.27-6.137 6.181a1.85 1.85 0 0 1-2.63 0l-6.137-6.18c-1.171-1.18-.341-3.198 1.315-3.198h4.965V0h2.339v10.073h4.97c1.656 0 2.486 2.017 1.315 3.197z"
      fill="#19B5FE"
      fillRule="nonzero"
    />
  </svg>
);

export default SpacerArrow;
