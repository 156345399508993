import React, { useContext } from 'react';
import { BannerContextType } from './types';

const BannerContext = React.createContext<BannerContextType | undefined>(
  undefined,
);

interface BannerProviderProps extends BannerContextType {
  children?: React.ReactNode;
}

export const BannerProvider: React.FC<BannerProviderProps> = ({
  children,
  size,
}) => (
  <BannerContext.Provider value={{ size }}>{children}</BannerContext.Provider>
);

export function useBanner() {
  const context = useContext(BannerContext);

  if (context === undefined) {
    throw new Error('useBanner must be used within BannerProvider');
  }

  return context;
}
