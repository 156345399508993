import cn from 'classnames';
import * as React from 'react';

import { TextToVideoIcon } from 'components/icons';
import ProjectButton from '../../containers/ProjectButton';
import { INewProjectButtonProps } from './types';

const TextToVideoButton: React.SFC<INewProjectButtonProps> = ({
  className,
  description,
  icon,
  title,
}) => (
  <ProjectButton
    beta
    className={cn('text-button', className)}
    icon={icon}
    projectType="text-to-video"
    title={title}
    description={description}
    to={{
      pathname: '/wizard',
      query: {
        type: 'text',
      },
    }}
  />
);

TextToVideoButton.defaultProps = {
  description:
    'Automatically summarize your article and prefill relevant media',
  icon: <TextToVideoIcon />,
  title: 'Article To Video',
};

export default TextToVideoButton;
