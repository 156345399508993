import { useSelector } from 'react-redux';

import { FontV2 } from 'components/TextToolbar';
import useCustomFontsLoader from 'hooks/useCustomFontsLoader';
import { fontsSelector } from 'redux/selectors';

interface UseTextOverlayFonts {
  fonts: FontV2[];
}

const useTextOverlayFonts = (): UseTextOverlayFonts => {
  useCustomFontsLoader();

  const fonts = useSelector(fontsSelector) ?? [];

  return {
    fonts,
  };
};

export default useTextOverlayFonts;
