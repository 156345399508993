import * as React from 'react';
import { Col } from 'react-bootstrap';

interface IProps {
  children: React.ReactNode;
}

const VideoOptionsField: React.SFC<IProps> = ({ children }) => (
  <Col sm={6} xs={12}>
    {children}
  </Col>
);

export default VideoOptionsField;
export { IProps as VideoOptionsFieldProps };
