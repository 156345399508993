import * as React from 'react';
import { noop } from 'underscore';

import AnnouncementModal from 'components/AnnouncementModal';
import { getValue } from 'utils/collections';
import { IAnnouncement } from '../../types';

const { useCallback, useEffect, useState } = React;

type Announcement = Pick<IAnnouncement, 'id' | 'announcementParts'>;
export type ModalType = 'announcement';

export interface ProjectsPageModalManagerProps {
  announcement?: Announcement;
  announcementActive?: boolean;
  onHide?: (type: ModalType, announcement?: Announcement) => void;
}

/**
 * A component to manage which modal is shown on the projects page.
 *
 * Takes various inputs for which modals are "active" and determines which modal
 * to show.  An active modal is one that can be shown, but it is up to the modal
 * manager to determine if it is actually shown.
 */

const ProjectsPageModalManager: React.SFC<ProjectsPageModalManagerProps> = ({
  announcement,
  announcementActive,
  onHide,
}) => {
  const [showModal, setShowModal] = useState<ModalType>(undefined);
  const announcementParts = getValue(announcement, 'announcementParts');

  const handleHide = useCallback(() => {
    onHide(showModal, announcement);
    setShowModal(undefined);
  }, [onHide, showModal, announcement]);

  useEffect(() => {
    if (announcementActive) {
      setShowModal('announcement');
    }
  }, [setShowModal, announcementActive]);

  return (
    <>
      {announcementParts && (
        <AnnouncementModal
          show={showModal === 'announcement'}
          onHide={handleHide}
          {...announcementParts}
        />
      )}
    </>
  );
};

ProjectsPageModalManager.defaultProps = {
  onHide: noop,
};

export default ProjectsPageModalManager;
