import * as React from 'react';

import { IconProps } from './types';

const SocialSolidCircleTikTok: React.FC<IconProps> = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <circle fill="#222831" cx="16" cy="16" r="16" />
      <path
        d="M22.25 14.74a5.745 5.745 0 0 1-3.358-1.075v4.89a4.446 4.446 0 1 1-3.833-4.406v2.459a2.04 2.04 0 1 0 1.427 1.947V9h2.406c-.001.204.016.407.052.607.17.903.702 1.696 1.474 2.193a3.32 3.32 0 0 0 1.832.55v2.39z"
        fill="#BEC8D2"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SocialSolidCircleTikTok;
