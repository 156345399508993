import cn from 'classnames';
import React from 'react';
import { useToggleCard } from './ToggleCardContext';

import { block } from './utils';

export interface ToggleCardIconProps {
  children?: React.ReactNode;
  className?: string;
  hidden?: boolean;
  isDisabled?: boolean;
}

const ToggleCardIcon: React.FC<ToggleCardIconProps> = ({
  children,
  className,
  hidden,
  isDisabled,
}) => {
  const { checked, theme } = useToggleCard();

  return (
    <div
      className={cn(
        block('icon', {
          checked,
          hidden,
          [theme]: !!theme,
          disabled: isDisabled,
        }),
        className,
      )}
    >
      {children}
    </div>
  );
};

export default ToggleCardIcon;
