import * as React from 'react';
import { isEmpty } from 'underscore';

import { Font } from 'components/icons';
import { RefType } from 'types';
import { useNavigation } from '../context/NavigationContext';
import { useEditorState } from '../context/VideoEditorStateContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { hasText } from '../state';
import { OPTION_TILE_FEATURE_PATHS } from '../state/constants';
import useAddTextModal from '../useAddTextModal';
import AccessControlOptionTile, {
  AccessControlOptionTileProps,
} from './AccessControlOptionTile';

type PickedTileProps<P = any> = Pick<
  AccessControlOptionTileProps<P>,
  'onClick' | 'params'
>;

export interface TextTileProps<P = any> extends PickedTileProps<P> {
  className?: string;
}

const TextTile = React.forwardRef(
  <P extends {} = any>(
    { className, onClick, params }: TextTileProps<P>,
    ref: RefType<typeof AccessControlOptionTile>,
  ) => {
    const state = useTemplateState();
    const { disabled, integrations, isLoading } = useEditorState();
    const { isComplex, slideEffectText = {} } = state;
    const [, send] = useNavigation();
    const showModal = useAddTextModal({
      onSubmit: () =>
        send({
          type: 'CHILD_VIEW_OPEN',
          payload: 'text',
          meta: { source: 'options' },
        }),
    });
    const { supportsDynamicText } = integrations;

    const canOpenChildView = hasText(state);

    const isDisabled = disabled || isLoading;

    const isNotAvailable =
      // cannot edit complex template text if dynamic assets is true
      (isComplex && supportsDynamicText) ||
      // cannot add dynamic text, so if none exists then the tile shows n/a
      (isComplex && isEmpty(slideEffectText));

    return (
      <AccessControlOptionTile
        className={className}
        disabled={isDisabled}
        featurePath={OPTION_TILE_FEATURE_PATHS.text}
        icon={<Font width={40} />}
        id="template-font-tile"
        label="text"
        onClick={canOpenChildView ? onClick : showModal}
        params={params}
        ref={ref}
        type={isNotAvailable ? 'not-available' : 'default'}
      />
    );
  },
);

export default TextTile;
