import cn from 'classnames';
import * as React from 'react';
import { Grid as BSGrid, GridProps as BSGridProps } from 'react-bootstrap';

import { Omit } from 'types';
import Col from './Col';
import Row from './Row';
import { block } from './utils';

export type GridProps = Omit<BSGridProps, 'ref'>;

export default class Grid extends React.Component<GridProps> {
  public static Row = Row;
  public static Col = Col;
  public render() {
    const { className, ...gridProps } = this.props;
    return <BSGrid {...gridProps} className={cn(block(), className)} />;
  }
}
