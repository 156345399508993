export enum Type {
  AUTOMATION_CMS_WORKFLOW_LOAD_REQUEST = 'app/automation-cms/AUTOMATION_CMS_WORKFLOW_LOAD_REQUEST',
  AUTOMATION_CMS_WORKFLOW_LOAD_SUCCESS = 'app/automation-cms/AUTOMATION_CMS_WORKFLOW_LOAD_SUCCESS',
  AUTOMATION_CMS_WORKFLOW_LOAD_FAILURE = 'app/automation-cms/AUTOMATION_CMS_WORKFLOW_LOAD_FAILURE',

  AUTOMATION_CMS_WORKFLOW_DELETE = 'app/automation-cms/AUTOMATION_CMS_WORKFLOW_DELETE',

  AUTOMATION_CMS_TEMPLATES_LOAD_REQUEST = 'app/automation-cms/AUTOMATION_CMS_TEMPLATES_LOAD_REQUEST',
  AUTOMATION_CMS_TEMPLATES_LOAD_SUCCESS = 'app/automation-cms/AUTOMATION_CMS_TEMPLATES_LOAD_SUCCESS',
  AUTOMATION_CMS_TEMPLATES_LOAD_FAILURE = 'app/automation-cms/AUTOMATION_CMS_TEMPLATES_LOAD_FAILURE',

  AUTOMATION_CMS_TEMPLATES_CLEAR = 'app/automation-cms/AUTOMATION_CMS_TEMPLATES_CLEAR',

  AUTOMATION_CMS_SEASONS_LOAD_REQUEST = 'app/automation-cms/AUTOMATION_CMS_SEASONS_LOAD_REQUEST',
  AUTOMATION_CMS_SEASONS_LOAD_SUCCESS = 'app/automation-cms/AUTOMATION_CMS_SEASONS_LOAD_SUCCESS',
  AUTOMATION_CMS_SEASONS_LOAD_FAILURE = 'app/automation-cms/AUTOMATION_CMS_SEASONS_LOAD_FAILURE',
  AUTOMATION_CMS_SEASONS_CLEAR = 'app/automation-cms/AUTOMATION_CMS_SEASONS_CLEAR',

  AUTOMATION_CMS_PODCAST_FEEDS_GET_REQUEST = 'app/automation-cms/AUTOMATION_CMS_PODCAST_FEEDS_GET_REQUEST',
  AUTOMATION_CMS_PODCAST_FEEDS_GET_SUCCESS = 'app/automation-cms/AUTOMATION_CMS_PODCAST_FEEDS_GET_SUCCESS',
  AUTOMATION_CMS_PODCAST_FEEDS_GET_FAILURE = 'app/automation-cms/AUTOMATION_CMS_PODCAST_FEEDS_GET_FAILURE',

  AUTOMATION_CMS_PODCAST_FEED_GET_REQUEST = 'app/automation-cms/AUTOMATION_CMS_PODCAST_FEED_GET_REQUEST',
  AUTOMATION_CMS_PODCAST_FEED_GET_SUCCESS = 'app/automation-cms/AUTOMATION_CMS_PODCAST_FEED_GET_SUCCESS',
  AUTOMATION_CMS_PODCAST_FEED_GET_FAILURE = 'app/automation-cms/AUTOMATION_CMS_PODCAST_FEED_GET_FAILURE',

  AUTOMATION_CMS_EPISODE_PROJECTS_GET_REQUEST = 'app/automation-cms/AUTOMATION_CMS_EPISODE_PROJECTS_GET_REQUEST',
  AUTOMATION_CMS_EPISODE_PROJECTS_GET_SUCCESS = 'app/automation-cms/AUTOMATION_CMS_EPISODE_PROJECTS_GET_SUCCESS',
  AUTOMATION_CMS_EPISODE_PROJECTS_GET_FAILURE = 'app/automation-cms/AUTOMATION_CMS_EPISODE_PROJECTS_GET_FAILURE',

  AUTOMATION_CMS_EPISODE_VIDEO_CREATE_REQUEST = 'app/automation-cms/AUTOMATION_CMS_EPISODE_VIDEO_CREATE_REQUEST',
  AUTOMATION_CMS_EPISODE_VIDEO_CREATE_SUCCESS = 'app/automation-cms/AUTOMATION_CMS_EPISODE_VIDEO_CREATE_SUCCESS',
  AUTOMATION_CMS_EPISODE_VIDEO_CREATE_FAILURE = 'app/automation-cms/AUTOMATION_CMS_EPISODE_VIDEO_CREATE_FAILURE',

  AUTOMATION_CMS_EPISODE_TRANSCRIPTION_CREATE_REQUEST = 'app/automation-cms/AUTOMATION_CMS_EPISODE_TRANSCRIPTION_CREATE_REQUEST',
  AUTOMATION_CMS_EPISODE_TRANSCRIPTION_CREATE_SUCCESS = 'app/automation-cms/AUTOMATION_CMS_EPISODE_TRANSCRIPTION_CREATE_SUCCESS',
  AUTOMATION_CMS_EPISODE_TRANSCRIPTION_CREATE_FAILURE = 'app/automation-cms/AUTOMATION_CMS_EPISODE_TRANSCRIPTION_CREATE_FAILURE',

  AUTOMATION_CMS_EPISODE_TRANSCRIPTION_REQUEST = 'app/automation-cms/AUTOMATION_CMS_EPISODE_TRANSCRIPTION_REQUEST',
  AUTOMATION_CMS_EPISODE_TRANSCRIPTION_SUCCESS = 'app/automation-cms/AUTOMATION_CMS_EPISODE_TRANSCRIPTION_SUCCESS',
  AUTOMATION_CMS_EPISODE_TRANSCRIPTION_ABORT = 'app/automation-cms/AUTOMATION_CMS_EPISODE_TRANSCRIPTION_ABORT',

  AUTOMATION_CMS_EPISODE_TRANSCRIPTION_POLLING_BEGIN = 'app/automation-cms/AUTOMATION_CMS_EPISODE_TRANSCRIPTION_POLLILNG_BEGIN',
  AUTOMATION_CMS_EPISODE_TRANSCRIPTION_POLLING_END = 'app/automation-cms/AUTOMATION_CMS_EPISODE_TRANSCRIPTION_POLLILNG_END',

  AUTOMATION_CMS_RESTORE_REVOKED_PERMISSION_REQUEST = 'app/automation-cms/AUTOMATION_CMS_RESTORE_REVOKED_PERMISSION_REQUEST',
  AUTOMATION_CMS_RESTORE_REVOKED_PERMISSION_FAILURE = 'app/automation-cms/AUTOMATION_CMS_RESTORE_REVOKED_PERMISSION_FAILURE',
  AUTOMATION_CMS_RESTORE_REVOKED_PERMISSION_SUCCESS = 'app/automation-cms/AUTOMATION_CMS_RESTORE_REVOKED_PERMISSION_SUCCESS',

  AUTOMATION_CMS_WORKFLOW_TOGGLE_REQUEST = 'app/automation-cms/AUTOMATION_CMS_WORKFLOW_TOGGLE_REQUEST',
  AUTOMATION_CMS_WORKFLOW_TOGGLE_SUCCESS = 'app/automation-cms/AUTOMATION_CMS_WORKFLOW_TOGGLE_SUCCESS',
  AUTOMATION_CMS_WORKFLOW_TOGGLE_FAILURE = 'app/automation-cms/AUTOMATION_CMS_WORKFLOW_TOGGLE_FAILURE',

  AUTOMATION_CMS_WORKFLOW_PREVIEW_REQUEST = 'app/automation-cms/AUTOMATION_CMS_WORKFLOW_PREVIEW_REQUEST',
  AUTOMATION_CMS_WORKFLOW_PREVIEW_FAILURE = 'app/automation-cms/AUTOMATION_CMS_WORKFLOW_PREVIEW_FAILURE',
  AUTOMATION_CMS_WORKFLOW_PREVIEW_SUCCESS = 'app/automation-cms/AUTOMATION_CMS_WORKFLOW_PREVIEW_SUCCESS',

  PODCAST_SELECT = 'app/automation-cms/PODCAST_SELECT',

  RUX_OVERLAY_HIDE = 'app/automation-cms/RUX_OVERLAY_HIDE',

  CLEAR_PODCAST_FEED = 'app/automation-cms/PODCAST_FEED_CLEAR',
}
