import * as React from 'react';

import { SelectField, SelectFieldProps } from 'components/Form';

interface Props extends SelectFieldProps {
  label?: string;
}

const options = [
  { label: 'English (United States)', value: 'en-US' },
  { label: 'Spanish (Spain)', value: 'es-ES' },
  { label: 'German (Germany)', value: 'de-DE' },
  { label: 'French (France)', value: 'fr-FR' },
  { label: 'Italian (Italy)', value: 'it-IT' },
  { label: 'Portuguese (Portugal)', value: 'pt-PT' },
  { label: 'Abkhaz (Georgia)', value: 'ab-GE' },
  { label: 'Afrikaans (South Africa)', value: 'af-ZA' },
  { label: 'Arabic (Saudi Arabia)', value: 'ar-SA' },
  { label: 'Arabic (United Arab Emirates)', value: 'ar-AE' },
  { label: 'Armenian (Armenia)', value: 'hy-AM' },
  { label: 'Asturian (Spain)', value: 'ast-ES' },
  { label: 'Azerbaijani (Azerbaijan)', value: 'az-AZ' },
  { label: 'Bashkir (Russia)', value: 'ba-RU' },
  { label: 'Basque (Spain)', value: 'eu-ES' },
  { label: 'Belarusian (Belarus)', value: 'be-BY' },
  { label: 'Bengali (India)', value: 'bn-IN' },
  { label: 'Bosnian (Bosnia and Herzegovina)', value: 'bs-BA' },
  { label: 'Bulgarian (Bulgaria)', value: 'bg-BG' },
  { label: 'Catalan (Spain)', value: 'ca-ES' },
  { label: 'Central Kurdish (Iran)', value: 'ckb-IR' },
  { label: 'Central Kurdish (Iraq)', value: 'ckb-IQ' },
  { label: 'Chinese (PRC)', value: 'zh-CN' },
  { label: 'Chinese (Taiwan)', value: 'zh-TW' },
  { label: 'Croatian (Croatia)', value: 'hr-HR' },
  { label: 'Czech (Czech Republic)', value: 'cs-CZ' },
  { label: 'Danish (Denmark)', value: 'da-DK' },
  { label: 'Dutch (Netherlands)', value: 'nl-NL' },
  { label: 'English (Australia)', value: 'en-AU' },
  { label: 'English (India)', value: 'en-IN' },
  { label: 'English (Ireland)', value: 'en-IE' },
  { label: 'English (New Zealand)', value: 'en-NZ' },
  { label: 'English (Scotland)', value: 'en-AB' },
  { label: 'English (South Africa)', value: 'en-ZA' },
  { label: 'English (United Kingdom)', value: 'en-GB' },
  { label: 'English (Wales)', value: 'en-WL' },
  { label: 'Estonian (Ethiopia)', value: 'et-ET' },
  { label: 'Finnish (Finland)', value: 'fi-FI' },
  { label: 'French (Canada)', value: 'fr-CA' },
  { label: 'Galician (Spain)', value: 'gl-ES' },
  { label: 'Georgian (Georgia)', value: 'ka-GE' },
  { label: 'German (Switzerland)', value: 'de-CH' },
  { label: 'Greek (Greece)', value: 'el-GR' },
  { label: 'Gujarati (India)', value: 'gu-IN' },
  { label: 'Hausa (Nigeria)', value: 'ha-NG' },
  { label: 'Hebrew (Israel)', value: 'he-IL' },
  { label: 'Hindi (India)', value: 'hi-IN' },
  { label: 'Hungarian (Hungary)', value: 'hu-HU' },
  { label: 'Icelandic (Iceland)', value: 'is-IS' },
  { label: 'Indonesian (Indonesia)', value: 'id-ID' },
  { label: 'Japanese (Japan)', value: 'ja-JP' },
  { label: 'Kabyle (Algeria)', value: 'kab-DZ' },
  { label: 'Kannada (India)', value: 'kn-IN' },
  { label: 'Kazakh (Kazakhstan)', value: 'kk-KZ' },
  { label: 'Kinyarwanda (Rwanda)', value: 'rw-RW' },
  { label: 'Korean (South Korea)', value: 'ko-KR' },
  { label: 'Kyrgyz (Kyrgyzstan)', value: 'ky-KG' },
  { label: 'Latvian (Latvia)', value: 'lv-LV' },
  { label: 'Lithuanian (Lithuania)', value: 'lt-LT' },
  { label: 'Luganda (India)', value: 'lg-IN' },
  { label: 'Macedonian (North Macedonia)', value: 'mk-MK' },
  { label: 'Malay (Malaysia)', value: 'ms-MY' },
  { label: 'Malayalam (India)', value: 'ml-IN' },
  { label: 'Maltese (Malta)', value: 'mt-MT' },
  { label: 'Marathi (India)', value: 'mr-IN' },
  { label: 'Meadow Mari (Russia)', value: 'mhr-RU' },
  { label: 'Mongolian (Mongolia)', value: 'mn-MN' },
  { label: 'Norwegian Bokmål (Norway)', value: 'no-NO' },
  { label: 'Odia/Oriya (India)', value: 'or-IN' },
  { label: 'Pashto (Afghanistan)', value: 'ps-AF' },
  { label: 'Persian (Iran)', value: 'fa-IR' },
  { label: 'Polish (Poland)', value: 'pl-PL' },
  { label: 'Portuguese (Brazil)', value: 'pt-BR' },
  { label: 'Punjabi (India)', value: 'pa-IN' },
  { label: 'Romanian (Romania)', value: 'ro-RO' },
  { label: 'Russian (Russia)', value: 'ru-RU' },
  { label: 'Serbian (Serbia)', value: 'sr-RS' },
  { label: 'Sinhala (Sri Lanka)', value: 'si-LK' },
  { label: 'Slovak (Slovakia)', value: 'sk-SK' },
  { label: 'Slovenian (Slovenia)', value: 'sl-SI' },
  { label: 'Somali (Somalia)', value: 'so-SO' },
  { label: 'Spanish (United States)', value: 'es-US' },
  { label: 'Sundanese (Indonesia)', value: 'su-ID' },
  { label: 'Swahili (Burundi)', value: 'sw-BI' },
  { label: 'Swahili (Kenya)', value: 'sw-KE' },
  { label: 'Swahili (Rwanda)', value: 'sw-RW' },
  { label: 'Swahili (Tanzania)', value: 'sw-TZ' },
  { label: 'Swahili (Uganda)', value: 'sw-UG' },
  { label: 'Swedish (Sweden)', value: 'sv-SE' },
  { label: 'Tagalog/Filipino (Philippines)', value: 'tl-PH' },
  { label: 'Tamil (India)', value: 'ta-IN' },
  { label: 'Tatar (Russia)', value: 'tt-RU' },
  { label: 'Telugu (India)', value: 'te-IN' },
  { label: 'Thai (Thailand)', value: 'th-TH' },
  { label: 'Turkish (Turkey)', value: 'tr-TR' },
  { label: 'Ukrainian (Ukraine)', value: 'uk-UA' },
  { label: 'Uyghur (China)', value: 'ug-CN' },
  { label: 'Uzbek (Uzbekistan)', value: 'uz-UZ' },
  { label: 'Vietnamese (Vietnam)', value: 'vi-VN' },
  { label: 'Welsh (Saint Lucia)', value: 'cy-WL' },
  { label: 'Wolof (Senegal)', value: 'wo-SN' },
  { label: 'Zulu (South Africa)', value: 'zu-ZA' },
];

const AutomationLanguageSelector: React.SFC<Props> = ({ label, ...props }) => (
  <SelectField label={label} {...props}>
    {options.map(option => (
      <option value={option.value} key={option.value}>
        {option.label}
      </option>
    ))}
  </SelectField>
);

AutomationLanguageSelector.defaultProps = {
  label: 'Transcription Language',
  value: 'en-US',
};

export default AutomationLanguageSelector;
