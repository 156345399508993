import * as ReactGA from 'react-ga';

import { PlanName } from 'redux/middleware/api/plan-service';

const DIMENSION__KEY_PODCAST = 'dimension2'; // Use For Podcast
const DIMENSION__KEY_SECTOR = 'dimension3'; // What You Do
const DIMENSION__KEY_PRICING_PLAN = 'dimension4'; // Pricing plan

export const setSurveyGa = (podcast, sector) => {
  ReactGA.set({ [DIMENSION__KEY_PODCAST]: podcast });
  ReactGA.set({ [DIMENSION__KEY_SECTOR]: sector });
};

export const setPricingPlanGa = (plan: PlanName) =>
  plan && ReactGA.set({ [DIMENSION__KEY_PRICING_PLAN]: plan });
