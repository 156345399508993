import * as React from 'react';
import { WelcomeType } from './containers/WelcomeStep';
import { OnboardingWizardContextType } from './types';
import { WELCOME_TYPE_TO_TIER } from './utils';

const { createContext, useContext } = React;

export interface OnboardingWizardProviderProps {
  welcomeType: WelcomeType;
}

const Context = createContext<OnboardingWizardContextType>({
  welcomeType: undefined,
  tierIntent: undefined,
});

export function useOnboardingWizard() {
  return useContext(Context);
}

export const OnboardingWizardProvider: React.FC<OnboardingWizardProviderProps> = ({
  children,
  welcomeType,
}) => (
  <Context.Provider
    value={{ welcomeType, tierIntent: WELCOME_TYPE_TO_TIER[welcomeType] }}
  >
    {children}
  </Context.Provider>
);
