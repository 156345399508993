import * as React from 'react';
import { omit, pick } from 'underscore';

import HistoricalColorPicker from 'containers/HistoricalColorPicker';
import { Omit } from 'types';
import {
  VideoStyleButton,
  VideoStyleButtonProps,
} from '../containers/VideoStyleTileButton';
import { StyleTile } from './StyleTileButton';

interface PickedColorPickerProps
  extends Pick<
    React.ComponentProps<typeof HistoricalColorPicker>,
    | 'color'
    | 'disableAlpha'
    | 'onChange'
    | 'onChangeComplete'
    | 'onClose'
    | 'onOpen'
    | 'popoverClassName'
  > {
  containerClassName?: string;
}
type PickedButtonProps = Omit<VideoStyleButtonProps, 'onClick'>;

export type ColorPickerTileProps = PickedButtonProps & PickedColorPickerProps;

const ColorPickerTile: React.SFC<ColorPickerTileProps> = props => {
  const { containerClassName } = props;
  const pickerPropKeys = [
    'color',
    'disableAlpha',
    'onChange',
    'onChangeComplete',
    'onClose',
    'onOpen',
    'popoverClassName',
  ];
  const pickerProps = pick(props, ...pickerPropKeys);
  const tileProps = omit(props, ...pickerPropKeys, 'containerClassName');

  return (
    <StyleTile className={containerClassName}>
      <HistoricalColorPicker
        popoverStyle={{ zIndex: 1001 }}
        renderSwatch={({ onClick, domRef }) => (
          <VideoStyleButton ref={domRef} onClick={onClick} {...tileProps} />
        )}
        rootCloseEvent="click"
        {...pickerProps}
      />
    </StyleTile>
  );
};

export default ColorPickerTile;
