import * as React from 'react';

import { useSelector } from 'react-redux';
import Divider from 'components/Divider';
import FontAwesome from 'components/FontAwesome';
import { FormField } from 'components/Form';
import { H2, H4 } from 'components/Heading';
import LinkButton from 'components/LinkButton';
import { userNameSelector } from 'redux/modules/auth/selectors';
import {
  automationEmailAlertEnabledSelector,
  reminderEmailEnabledSelector,
  videoExportEmailEnabledSelector,
} from 'redux/selectors';
import SettingsContent from '../SettingsContent';
import { block } from '../utils';
import ToggleField, { EmailPreferences } from './ToggleField';
import useEmailSettings from './useEmailSettings';

export default function EmailSettings() {
  const { onEmailChange, unsubscribeFromAllEmails } = useEmailSettings();
  const videoExportEmailEnabled: boolean = useSelector(
    videoExportEmailEnabledSelector,
  );
  const automationEmailAlertEnabled: boolean = useSelector(
    automationEmailAlertEnabledSelector,
  );
  const reminderEmailEnabled: boolean = useSelector(
    reminderEmailEnabledSelector,
  );
  const email = useSelector(userNameSelector);

  const preferences: EmailPreferences = {
    videoExportEmailEnabled,
    automationEmailAlertEnabled,
    reminderEmailEnabled,
  };

  return (
    <SettingsContent
      subtitle="Manage email settings for Headliner"
      title="Email Settings"
    >
      <form className={block('email-form')}>
        <FormField
          label={<H2>{email}</H2>}
          horizontal={{
            control: { xs: 4 },
            label: { xs: 8 },
          }}
        >
          <LinkButton
            theme="cta"
            className={block('link')}
            onClick={onEmailChange}
          >
            <FontAwesome icon="edit" size="sm" /> Change email
          </LinkButton>
        </FormField>
        <Divider />
        <ToggleField
          primaryLabel="Video exports"
          secondaryLabel="When a video export is complete and available to be shared."
          name="videoExportEmailEnabled"
          preferences={preferences}
        />
        <Divider />
        <ToggleField
          primaryLabel="Automatic videos"
          secondaryLabel="When an automated video is complete and available to be shared."
          name="automationEmailAlertEnabled"
          preferences={preferences}
        />
        <Divider />
        <ToggleField
          primaryLabel="Education, reminders, and reports"
          secondaryLabel="Helpful promotion tips and performance updates."
          name="reminderEmailEnabled"
          preferences={preferences}
        />
        <Divider />
        <H4>
          To unsubscribe from all emails{' '}
          <LinkButton theme="cta" onClick={unsubscribeFromAllEmails}>
            click here
          </LinkButton>
        </H4>
      </form>
    </SettingsContent>
  );
}
