import * as React from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'underscore';

import LanguageSelector from 'components/AutomationLanguageSelector';
import { selectedPodcastThumbnailUrlSelector } from 'redux/modules/podcast-search';
import bem from 'utils/bem';

export interface AutomationLanguageStepProps {
  language: string;
  onChange?: (language: string) => void;
}

const block = bem('automation-language-step');

const AutomationLanguageStep: React.FC<AutomationLanguageStepProps> = ({
  language,
  onChange,
}) => {
  const artworkUrl = useSelector(selectedPodcastThumbnailUrlSelector);

  return (
    <div className={block()}>
      <div className={block('image-asset-container')}>
        <img className={block('image-asset')} src={artworkUrl} />
      </div>

      <div className={block('language-container')}>
        <LanguageSelector
          className={block('language-selector')}
          onChange={onChange}
          value={language}
        />
      </div>
    </div>
  );
};

AutomationLanguageStep.defaultProps = {
  onChange: noop,
};

export default AutomationLanguageStep;
