import dayjs from 'dayjs';
import * as ids from 'short-id';
import { isObject } from 'underscore';
import { Action, ThunkAction } from 'redux/types';

import {
  favoritePodcastsSelector,
  podcastFeedEpisodesSelector,
} from '../entities';
import * as mixpanelActions from '../mixpanel';
import { pushModal } from '../modal';
import * as types from './types';

type Level = 'success' | 'error' | 'warning' | 'info';

interface NotificationOptions {
  action?: Action<any, any>;
  message?: string;
  level: Level;
  dismissAfterSec?: number;
  type?: 'failedSave' | 'link' | 'help' | string;
  title?: string;
  id?: string;
  href?: string;
  actionLabel?: string;
  linkTarget?: string;
  code?: string;
}

type ShorthandNotificationOptions = Omit<NotificationOptions, 'level'>;

function normalizeOptions(...args: any[]): NotificationOptions {
  if (isObject(args[0])) {
    return args[0];
  }
  const [
    message,
    level,
    dismissAfterSec,
    type,
    title,
    id,
    href,
    actionLabel,
    linkTarget,
    action,
  ] = args;
  return {
    message,
    level,
    dismissAfterSec,
    type,
    title,
    id,
    href,
    actionLabel,
    linkTarget,
    action,
  };
}

function normalizeShorthandOptions(
  ...args: any[]
): ShorthandNotificationOptions {
  if (isObject(args[0])) {
    return args[0];
  }
  const [message, dismissAfterSec, type, title, id] = args;
  return {
    message,
    dismissAfterSec,
    type,
    title,
    id,
  };
}

export function showNotification(
  options: NotificationOptions,
): ThunkAction<string>;
export function showNotification(
  message: NotificationOptions['message'],
  level: NotificationOptions['level'],
  dismissAfterSec?: NotificationOptions['dismissAfterSec'],
  type?: NotificationOptions['type'],
  title?: NotificationOptions['title'],
  id?: NotificationOptions['id'],
  href?: NotificationOptions['href'],
  actionLabel?: NotificationOptions['actionLabel'],
  linkTarget?: NotificationOptions['linkTarget'],
  actoin?: NotificationOptions['action'],
): ThunkAction<string>;
export function showNotification(...args: any[]): ThunkAction<string> {
  const {
    level,
    message,
    id = ids.generate(),
    linkTarget = '_blank',
    dismissAfterSec = 0,
    code,
    ...rest
  } = normalizeOptions(...args);
  return dispatch => {
    dispatch({
      type: types.NOTIFICATION_ADD,
      payload: {
        ...rest,
        ts: dayjs().unix(),
        level,
        message,
        linkTarget,
        dismissAfterSec,
        id,
      },
    });

    if (level === 'error') {
      dispatch(mixpanelActions.onErrorNotification(message, code));
    }

    return id;
  };
}

const createShorthand = (level: Level) => {
  function showLevel(
    options: ShorthandNotificationOptions,
  ): ThunkAction<string>;
  function showLevel(
    message: NotificationOptions['message'],
    dismissAfterSec?: NotificationOptions['dismissAfterSec'],
    type?: NotificationOptions['type'],
    title?: NotificationOptions['title'],
    id?: NotificationOptions['id'],
  ): ThunkAction<string>;
  function showLevel(...args: any[]): ThunkAction<string> {
    const options = normalizeShorthandOptions(...args);
    return showNotification({
      ...options,
      level,
    });
  }
  return showLevel;
};

export const showError = createShorthand('error');

export const showWarning = createShorthand('warning');

export const showSuccess = createShorthand('success');

export const clearNotification = (id: string): ThunkAction<void> => dispatch =>
  dispatch({
    type: types.NOTIFICATION_CLEAR,
    payload: { id },
  });

export const clearAllNotifications = (): ThunkAction<void> => dispatch =>
  dispatch({
    type: types.NOTIFICATION_CLEAR_ALL,
  });

export const setShowedIntroMessage = (): ThunkAction<void> => dispatch =>
  dispatch({
    type: types.NOTIFICATION_SET_INTRO,
  });

export const showEpisodeTranscriptReadyNotification = (
  episodeId: string,
): ThunkAction<void> => (dispatch, getState) => {
  const episodes = podcastFeedEpisodesSelector(getState());
  const podcasts = favoritePodcastsSelector(getState());

  const episode = episodes?.get(episodeId);

  if (!episode) {
    return;
  }

  const podcast = podcasts.get(episode.get('podcastFeedId'));

  if (!podcast) {
    return;
  }

  dispatch(
    showNotification({
      action: pushModal({
        name: 'DownloadEpisodeCaptions',
        params: { episodeId },
      }),
      actionLabel: 'download it here',
      level: 'success',
      message: `We transcribed this entire episode of ${podcast.get(
        'title',
      )}, you can {{link}}`,
      title: `Transcript ready for ${episode.get('title')}`,
      type: 'action',
    }),
  );
};
