export enum Type {
  THREADS_USER_CLEAR = 'app/social/threads/THREADS_USER_CLEAR',

  THREADS_USER_ID_SELECT = 'app/social/threads/THREADS_USER_ID_SELECT',

  THREADS_USER_DATA_GET_AWAIT_REQUEST = 'app/social/threads/THREADS_USER_DATA_GET_AWAIT_REQUEST',
  THREADS_USER_DATA_GET_AWAIT_SUCCESS = 'app/social/threads/THREADS_USER_DATA_GET_AWAIT_SUCCESS',
  THREADS_USER_DATA_GET_AWAIT_FAILURE = 'app/social/threads/THREADS_USER_DATA_GET_AWAIT_FAILURE',

  THREADS_USER_DATA_GET_REQUEST = 'app/social/threads/THREADS_USER_DATA_GET_REQUEST',
  THREADS_USER_DATA_GET_SUCCESS = 'app/social/threads/THREADS_USER_DATA_GET_SUCCESS',
  THREADS_USER_DATA_GET_FAILURE = 'app/social/threads/THREADS_USER_DATA_GET_FAILURE',

  THREADS_VIDEO_POST_REQUEST = 'app/social/threads/THREADS_VIDEO_POST_REQUEST',
  THREADS_VIDEO_POST_SUCCESS = 'app/social/threads/THREADS_VIDEO_POST_SUCCESS',
  THREADS_VIDEO_POST_FAILURE = 'app/social/threads/THREADS_VIDEO_POST_FAILURE',

  THREADS_VIDEO_POST_AWAIT_REQUEST = 'app/social/threads/THREADS_VIDEO_POST_AWAIT_REQUEST',
  THREADS_VIDEO_POST_AWAIT_SUCCESS = 'app/social/threads/THREADS_VIDEO_POST_AWAIT_SUCCESS',
  THREADS_VIDEO_POST_AWAIT_FAILURE = 'app/social/threads/THREADS_VIDEO_POST_AWAIT_FAILURE',

  THREADS_AUTHORIZE_SUCCESS = 'app/social/threads/THREADS_AUTHORIZE_SUCCESS',
}
