import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { tiktokSelector } from 'redux/modules/social';
import { PostStatus } from 'redux/modules/social/constants';

const usernameSelector = createSelector(
  tiktokSelector,
  tiktok => tiktok.username,
);

const avatarSrcSelector = createSelector(
  tiktokSelector,
  tiktok => tiktok.profileImageUrl,
);

const postStatusSelector = createSelector(
  tiktokSelector,
  tiktok => tiktok.postStatus,
);

export default function useTikTokModalSelector() {
  const avatarSrc = useSelector(avatarSrcSelector);
  const postStatus: PostStatus = useSelector(postStatusSelector);
  const username = useSelector(usernameSelector);

  return {
    avatarSrc,
    postStatus,
    username,
  };
}
