import React from 'react';

import { noop } from 'underscore';
import { Step } from 'components/Wizard';
import TemplateWizardProgressStep, {
  TemplateWizardProgressStepProps,
} from './TemplateWizardProgressStep';
import { TemplateWizardStep } from './types';

type UseProgressStepConfig = Pick<
  TemplateWizardProgressStepProps,
  'onCompleted' | 'onError'
>;
interface UseProgressStepResult {
  step: Step<TemplateWizardStep.SUBMITTING>;
}

export default function useProgressStep({
  onCompleted = noop,
  onError = noop,
}: UseProgressStepConfig): UseProgressStepResult {
  return {
    step: {
      component: (
        <TemplateWizardProgressStep
          onCompleted={onCompleted}
          onError={onError}
        />
      ),
      name: 'Creating Project',
      renderCancelButton: () => null,
      renderNextButton: () => null,
      showInNav: false,
      stepId: TemplateWizardStep.SUBMITTING,
    },
  };
}
