import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDateString from 'hooks/useDateString';
import {
  externalPortalLinkSelector,
  loadExternalPortalLink,
} from 'redux/modules/pricing';
import { Dispatch } from 'redux/types';
import bem from 'utils/bem';
import BillingHistoryLinkView from '../BillingHistoryLinkView';
import { BaseFreeSubscription } from '../FreeSubscription';

export interface ProTrialSubscriptionProps {
  expirationSeconds: number;
}

export const block = bem('pro-trial-subscription');

const ProTrialSubscription: React.SFC<ProTrialSubscriptionProps> = ({
  expirationSeconds,
}) => {
  const dispatch = useDispatch<Dispatch>();

  const longDate = useDateString(expirationSeconds);

  const externalPortalLink = useSelector(externalPortalLinkSelector);

  useEffect(() => {
    if (!externalPortalLink) dispatch(loadExternalPortalLink());
  }, [dispatch, externalPortalLink]);

  const expirationWarning = `Your free trial will expire on ${longDate}, to keep it longer just
  upgrade your plan below.`;

  return (
    <BaseFreeSubscription
      className={block()}
      message="🎉 You’ve got Headliner Pro for FREE 🎉"
      subMessage={
        <>
          {expirationWarning}
          {externalPortalLink && (
            <>
              <span> Also </span>
              <BillingHistoryLinkView href={externalPortalLink}>
                here is your billing history
              </BillingHistoryLinkView>
              .
            </>
          )}
        </>
      }
    />
  );
};

export default ProTrialSubscription;
