import { useColorPaletteState } from '@sparemin/blockhead';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { isUndefined } from 'underscore';

import { FileUploadMethod } from 'components/FileUploader/FileUploaderDropzone';
import { useAddAssetModal } from 'containers/ConnectedModal/useAddAssetModal';
import usePromise from 'hooks/usePromise';
import useRedirectOnClose from 'hooks/useRedirectOnClose';
import { AiImageType } from 'redux/middleware/api/image-search-service';
import { types } from 'redux/modules/embed';
import {
  addSlide,
  addVideoToTrack,
  removeReplacedTrackElement,
} from 'redux/modules/embed/actions';
import {
  addSlideStatusSelector,
  aspectRatioSelector,
  isAddingVideoSelector,
} from 'redux/modules/embed/selectors';
import {
  AddVideoFailureAction,
  AddVideoSuccessAction,
} from 'redux/modules/embed/types';
import {
  clearAllSearchResults as clearAllGifSearchResults,
  clearSearchResults as clearGifSearchResults,
  searchForGifs,
} from 'redux/modules/gif-search/actions';
import { gifSearchQueriesSelector } from 'redux/modules/gif-search/selectors';
import {
  clearAllSearchResults as clearAllImageSearchResults,
  clearSearchResults as clearImageSearchResults,
  searchForImages,
} from 'redux/modules/image-search/actions';
import {
  defaultImageSearchEngineSelector,
  expiredEngineSelector,
  imageSearchQueriesSelector,
} from 'redux/modules/image-search/selectors';
import {
  onClickSearchGif,
  onClickSearchImage,
  onClickSearchVideo,
  onImageSearch,
} from 'redux/modules/mixpanel';
import { showError } from 'redux/modules/notification/actions';
import { getThirdPartyTokens } from 'redux/modules/oauth/actions';
import {
  createTextToImage,
  pollForTextToImage,
} from 'redux/modules/text-to-image';
import {
  isPollingTextToImageSelector,
  textToImageJobIdsSelector,
} from 'redux/modules/text-to-image/selectors';
import { MediaType } from 'redux/modules/text-to-image/types';
import {
  prepareVideoForEditing,
  videoDurationSelector,
  videoEditErrorSelector,
  videoEntitySelector,
} from 'redux/modules/video-edit';
import {
  clearAllSearchResults as clearAllVideoSearchResults,
  clearSearchResults as clearVideoSearchResults,
  searchForVideos,
} from 'redux/modules/video-search/actions';
import {
  defaultVideoSearchEngineSelector,
  videoSearchQueriesSelector,
} from 'redux/modules/video-search/selectors';
import { Dispatch, ThunkAction } from 'redux/types';
import { AspectRatioName } from 'types';
import { getAspectRatio, getAspectRatioName } from 'utils/aspect-ratio';
import { createChainedFunction } from 'utils/functions';
import AddMediaModal, { IProps as AddMediaModalProps } from './AddMediaModal';
import { imageTypeOptions } from './MediaImport/AIGeneration/constants';

const { useCallback } = React;

export type ConnectedAddMediaModalProps = {
  uploadMethod?: FileUploadMethod;
};

const isAddingImageSelector = createSelector(
  addSlideStatusSelector,
  status => status === 'adding',
);

const durationMillisSelector = createSelector(
  videoDurationSelector,
  durationSeconds =>
    isUndefined(durationSeconds) ? undefined : durationSeconds * 1000,
);

const addingMediaTypeSelector = createSelector(
  [isAddingImageSelector, isAddingVideoSelector],
  (isAddingImage, isAddingVideo) => {
    if (isAddingImage) {
      return 'image';
    }

    if (isAddingVideo) {
      return 'video';
    }

    return undefined;
  },
);

const clearImageSearch = (engine: string, query: string): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const queries = imageSearchQueriesSelector(getState());
  const matchingQuery = queries.indexOf(query) >= 0;
  if (matchingQuery) {
    dispatch(clearImageSearchResults(engine));
  } else {
    dispatch(clearAllImageSearchResults());
  }
};

const clearVideoSearch = (engine: string, query: string): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const queries = videoSearchQueriesSelector(getState());
  const matchingQuery = queries.indexOf(query) >= 0;
  if (matchingQuery) {
    dispatch(clearVideoSearchResults(engine));
  } else {
    dispatch(clearAllVideoSearchResults());
  }
};

const clearGifSearch = (engine: string, query: string): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const queries = gifSearchQueriesSelector(getState());
  const matchingQuery = queries.indexOf(query) >= 0;
  if (matchingQuery) {
    dispatch(clearGifSearchResults(engine));
  } else {
    dispatch(clearAllGifSearchResults());
  }
};

const ConnectedAddMediaModal: React.FC<ConnectedAddMediaModalProps> = ({
  uploadMethod = 'chooseFile',
}) => {
  const dispatch = useDispatch<Dispatch>();
  const colorPaletteState = useColorPaletteState();

  const addVideoPromise = usePromise<
    AddVideoSuccessAction | AddVideoFailureAction
  >();
  const aspectRatio = useSelector(aspectRatioSelector);
  const videoEntity = useSelector(videoEntitySelector);
  const textToImageJobIds = useSelector(textToImageJobIdsSelector);
  const isPollingTextToImage = useSelector(isPollingTextToImageSelector);
  const { show, onExited, onHide: onAddAssetHide } = useAddAssetModal(
    'AddMediaModal',
  );
  const addingMediaType = useSelector(addingMediaTypeSelector);
  const defaultImageSearchEngine = useSelector(
    defaultImageSearchEngineSelector,
  );
  const defaultVideoSearchEngine = useSelector(
    defaultVideoSearchEngineSelector,
  );
  const durationMillis = useSelector(durationMillisSelector);
  const error = useSelector(videoEditErrorSelector);
  const { onExited: onExitedRedirect } = useRedirectOnClose();

  const [aspectRatioName, setAspectRatioName] = React.useState<AspectRatioName>(
    getAspectRatioName(aspectRatio),
  );
  const [imageType, setImageType] = React.useState<AiImageType>(
    imageTypeOptions[0].value,
  );

  const handleAddImage: AddMediaModalProps['onAddImage'] = useCallback(
    async ({ mediaToReplaceId, ...args }) => {
      await dispatch(addSlide(args));
      dispatch(removeReplacedTrackElement(mediaToReplaceId));
      onAddAssetHide();
    },
    [dispatch, onAddAssetHide],
  );

  const handleAddVideo: AddMediaModalProps['onAddVideo'] = useCallback(
    (params, mediaImportedArgs) => {
      addVideoPromise
        .setPromise(dispatch(addVideoToTrack(params, mediaImportedArgs)))
        .then(() => {
          onAddAssetHide();
        });
    },
    [addVideoPromise, dispatch, onAddAssetHide],
  );

  const handleCancelVideoUpload: AddMediaModalProps['onHide'] = useCallback(() => {
    addVideoPromise.cancelPromise();
    dispatch({ type: types.EMBED_VIDEO_ADD_FAILURE });
  }, [addVideoPromise, dispatch]);

  const handleAiAssetClick: AddMediaModalProps['onAiAssetClick'] = useCallback(
    async (url, poster) => {
      try {
        await dispatch(prepareVideoForEditing(url, poster, 'video'));
      } catch {
        dispatch(showError('Error loading video'));
      }
    },
    [dispatch],
  );

  const handleGifClick: AddMediaModalProps['onGifClick'] = useCallback(
    async (url, poster, engine) => {
      dispatch(onClickSearchGif(engine, url));

      try {
        await dispatch(prepareVideoForEditing(url, poster, 'gif-video'));
      } catch {
        dispatch(showError('Error loading video'));
      }
    },
    [dispatch],
  );

  const handleImageClick: AddMediaModalProps['onImageClick'] = useCallback(
    (imageUrl, _, engine) => {
      dispatch(onClickSearchImage(engine, imageUrl));
    },
    [dispatch],
  );

  const handleCreateTextToImage = useCallback(
    (query: string, mediaType: MediaType): void => {
      const newAspectRatio = getAspectRatio(aspectRatioName);

      dispatch(
        createTextToImage(
          mediaType,
          query,
          'stability',
          newAspectRatio.get('width'),
          newAspectRatio.get('height'),
          imageType,
          colorPaletteState?.colors('hex'),
        ),
      );
    },
    [aspectRatioName, colorPaletteState, dispatch, imageType],
  );

  const handleMediaSearchSubmit: AddMediaModalProps['onMediaSearchSubmit'] = useCallback(
    (type, engine, query) => {
      switch (type) {
        case 'image': {
          if (engine === 'textToImage') {
            handleCreateTextToImage(query, 'image');
            dispatch(onImageSearch('AI', query));

            break;
          }

          dispatch(clearImageSearch(engine, query));
          dispatch(searchForImages(engine, query));

          break;
        }

        case 'video':
          if (engine === 'textToVideo') {
            handleCreateTextToImage(query, 'video');

            break;
          }

          dispatch(clearVideoSearch(engine, query));
          dispatch(searchForVideos(engine, query));

          break;

        case 'gif-video':
          dispatch(clearGifSearch(engine, query));
          dispatch(searchForGifs(engine, query));
          break;
      }
    },
    [dispatch, handleCreateTextToImage],
  );

  const handleModalShow: AddMediaModalProps['onModalShow'] = useCallback(() => {
    dispatch((_, getState) => {
      const expiredSearchEngines: any = expiredEngineSelector(getState());
      expiredSearchEngines &&
        expiredSearchEngines.length > 0 &&
        dispatch(getThirdPartyTokens(expiredSearchEngines));
    });
  }, [dispatch]);

  const handleVideoClick: AddMediaModalProps['onVideoClick'] = useCallback(
    async (url, poster, engine) => {
      dispatch(onClickSearchVideo(engine, url));
      try {
        await dispatch(prepareVideoForEditing(url, poster));
      } catch {
        dispatch(showError('Error loading video'));
      }
    },
    [dispatch],
  );

  const handleMediaTypeChange: AddMediaModalProps['onMediaTypeChange'] = useCallback(
    mediaType => {
      if (isPollingTextToImage?.image && mediaType === 'image') {
        return dispatch(
          pollForTextToImage(textToImageJobIds?.image, mediaType),
        );
      }

      if (isPollingTextToImage?.video && mediaType === 'video') {
        return dispatch(
          pollForTextToImage(textToImageJobIds?.video, mediaType),
        );
      }

      return undefined;
    },
    [dispatch, isPollingTextToImage, textToImageJobIds],
  );

  const handleExited = createChainedFunction(onExited, onExitedRedirect);
  const handleHide = createChainedFunction(
    handleCancelVideoUpload,
    onAddAssetHide,
  );

  if (!aspectRatioName && aspectRatio) {
    setAspectRatioName(getAspectRatioName(aspectRatio));
  }

  return (
    <AddMediaModal
      {...{
        addingMediaType,
        aspectRatio,
        defaultImageSearchEngine,
        defaultVideoSearchEngine,
        durationMillis,
        error,
        show,
        aspectRatioName,
        imageType,
        colorPaletteState,
        uploadMethod,
      }}
      onMediaTypeChange={handleMediaTypeChange}
      onAddImage={handleAddImage}
      onAddVideo={handleAddVideo}
      onExited={handleExited}
      onGifClick={handleGifClick}
      onAiAssetClick={handleAiAssetClick}
      onHide={handleHide}
      onImageClick={handleImageClick}
      onMediaSearchSubmit={handleMediaSearchSubmit}
      onModalShow={handleModalShow}
      onVideoClick={handleVideoClick}
      onAspectRatioNameChange={setAspectRatioName}
      onImageTypeChange={setImageType}
      videoEntity={videoEntity}
    />
  );
};

export default ConnectedAddMediaModal;
