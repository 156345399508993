import { SocialButton, SocialButtonProps } from '@sparemin/blockhead';
import React from 'react';
import { block } from './utils';

export interface UploadButtonProps
  extends Pick<SocialButtonProps, 'aria-label' | 'logo' | 'onPress'> {
  label: string;
}

const UploadButton: React.FC<UploadButtonProps> = ({
  label,
  logo,
  ...props
}) => (
  <SocialButton
    {...props}
    className={block('upload-button')}
    logo={<div className={block('logo-wrapper')}>{logo}</div>}
  >
    <span>{label}</span>
  </SocialButton>
);

export default UploadButton;
