import cn from 'classnames';
import * as React from 'react';
import { RootCloseWrapper } from 'react-overlays';
import _ from 'underscore';

import FontAwesome from 'components/FontAwesome';
import ToolbarButton, { ToolbarButtonProps } from './ToolbarButton';
import ToolbarIcon from './ToolbarIcon';
import ToolbarTool from './ToolbarTool';
import { block } from './utils';

interface IControlProps {
  buttonProps: ToolbarButtonProps;
  caret: JSX.Element;
}

interface IProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  domRef?: (el: HTMLElement) => void;
  icon?: React.ReactNode;
  iconContainerClassName?: string;
  onIconClick?: () => void;
  renderControlButton?: (props: IControlProps) => JSX.Element;
  rootClose?: boolean;
  rootCloseEvent?: 'click' | 'mousedown';
  onClose?: () => void;
}

const MenuButton: React.SFC<IProps> = ({
  children,
  className,
  disabled,
  domRef,
  icon,
  iconContainerClassName,
  onIconClick,
  onClose,
  renderControlButton,
  rootClose,
  rootCloseEvent,
}) => {
  const isOpen = React.Children.toArray(children).length > 0;

  const wrapWithRootClose = (element: JSX.Element) =>
    !rootClose || !isOpen ? (
      element
    ) : (
      <RootCloseWrapper onRootClose={onClose} event={rootCloseEvent}>
        {element}
      </RootCloseWrapper>
    );

  const caret = (
    <div className={block('menu-icon-arrow')}>
      <FontAwesome icon="caret-down" />
    </div>
  );

  const buttonProps = {
    disabled,
    className: cn(block('menu-icon-container'), iconContainerClassName),
    onClick: onIconClick,
  };

  return wrapWithRootClose(
    <ToolbarTool disabled={disabled}>
      <div className={cn(block('menu-icon'), className)} ref={domRef}>
        {renderControlButton ? (
          renderControlButton({ caret, buttonProps })
        ) : (
          <ToolbarButton {...buttonProps}>
            <ToolbarIcon>{icon}</ToolbarIcon>
            {caret}
          </ToolbarButton>
        )}
        {isOpen && <div className={block('menu-icon-popover')}>{children}</div>}
      </div>
    </ToolbarTool>,
  );
};

MenuButton.defaultProps = {
  domRef: _.noop,
  onClose: _.noop,
  onIconClick: _.noop,
  rootClose: true,
  rootCloseEvent: 'click',
};

export default MenuButton;
export { IProps as MenuButtonProps };
