import { ShareVideoOptions } from 'redux/middleware/api/podcast-service';
import { shareVideo } from 'redux/middleware/api/podcast-service/actions';
import {
  getInstagramAccounts as getAccounts,
  getInstagramAccountInfoAction,
} from 'redux/middleware/api/third-party-authentication-service/actions';
import { GetSocialProfileAction } from 'redux/middleware/api/third-party-authentication-service/types';
import { isShareStatusResolved } from 'redux/middleware/api/video-export-service';
import { getSocialShare } from 'redux/middleware/api/video-export-service/actions';
import { onInstagramVideoUploadedSuccessfully } from 'redux/modules/mixpanel/actions';
import { showNotification } from 'redux/modules/notification/actions';
import { ThunkAction } from 'redux/types';
import { SocialSharePlatform } from 'types';
import reduxPoll from 'utils/redux-poll';
import { Type } from './action-types';
import {
  instagramSocialShareIdSelector,
  instagramSocialSharesSelector,
  instagramUserDataSelector,
} from './selectors';
import {
  InstagramAuthorizeSuccessAction,
  InstagramPostMessageData,
} from './types';

const INSTAGRAM_POST_ID = 'app/instagram/post-poll';

const showSuccessNotification = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const { sharePostUrl } = instagramSocialSharesSelector(getState());

  dispatch(
    showNotification(
      'You can see your post {{link}}',
      'success',
      0,
      'link',
      'Your video was posted',
      undefined,
      sharePostUrl,
      'here',
    ),
  );
};

const showErrorNotification = (): ThunkAction<void> => (dispatch, getState) => {
  const { errorDetail } = instagramSocialSharesSelector(getState());

  if (errorDetail.errorReason === 'invalidAccountType') {
    dispatch(
      showNotification(
        'To post a video as a story from Headliner you’ll need to {{link}}',
        'error',
        0,
        'link',
        'Your Instagram account does not support posting stories from our app',
        undefined,
        'https://help.instagram.com/502981923235522',
        'switch to a business account',
      ),
    );

    return;
  }

  dispatch(
    showNotification({
      message: 'Please try again or {{link}} so we can help',
      code: 'ER013',
      level: 'error',
      type: 'help',
      title: "Sorry, we couldn't post your video",
      actionLabel: 'contact us',
    }),
  );
};

export const instagramAuthorizeSuccess = (
  data: Pick<
    InstagramPostMessageData['tokenInfo'],
    'accessToken' | 'idToken' | 'providerUserId'
  >,
): InstagramAuthorizeSuccessAction => ({
  type: Type.INSTAGRAM_AUTHORIZE_SUCCESS,
  payload: data,
});

export const getInstagramUser = (
  response: GetSocialProfileAction,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.INSTAGRAM_USER_DATA_GET_REQUEST });

  try {
    dispatch({
      payload: response,
      type: Type.INSTAGRAM_USER_DATA_GET_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: new Error('Error getting user data'),
      type: Type.INSTAGRAM_USER_DATA_GET_FAILURE,
    });

    throw error;
  }
};

export const clearInstagramUser = () => ({
  type: Type.INSTAGRAM_ACCOUNTS_CLEAR,
});

export const instagramSelectUser = (newAccount: string | number) => ({
  type: Type.INSTAGRAM_USER_ID_SELECT,
  payload: newAccount,
});

export const getInstagramAccounts = (
  instagramUserId?: string,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.INSTAGRAM_ACCOUNTS_GET_REQUEST });

  try {
    const {
      providerUserId = instagramUserId,
      accessToken,
    } = instagramUserDataSelector(getState());

    const { response } = await dispatch(
      getAccounts(providerUserId, accessToken),
    );

    dispatch({
      type: Type.INSTAGRAM_ACCOUNTS_GET_SUCCESS,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: Type.INSTAGRAM_ACCOUNTS_GET_FAILURE,
      error: new Error('Error getting Instagram accounts'),
    });

    throw err;
  }
};

const waitForInstagramPost = (): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  dispatch({ type: Type.INSTAGRAM_VIDEO_POST_AWAIT_REQUEST });

  const socialShareId = instagramSocialShareIdSelector(getState());

  try {
    await reduxPoll(dispatch, () => dispatch(getSocialShare(socialShareId)), {
      id: INSTAGRAM_POST_ID,
      intervalMillis: 3000,
      maxAttempts: spareminConfig.videoExportPollMaxAttempts,
      shouldContinue: err => {
        if (err) {
          return false;
        }

        const share = instagramSocialSharesSelector(getState());

        if (share.shareStatus === 'error') {
          throw err;
        }

        return isShareStatusResolved(share, ['uploaded']);
      },
    });

    dispatch({
      type: Type.INSTAGRAM_VIDEO_POST_AWAIT_SUCCESS,
    });
    dispatch(showSuccessNotification());
    dispatch(onInstagramVideoUploadedSuccessfully());
  } catch (err) {
    // NB: Error isn't send in the action because it is thrown and handled by
    // postInstagramVideo, which will send it to the reducer.
    dispatch({ type: Type.INSTAGRAM_VIDEO_POST_AWAIT_FAILURE });

    throw err;
  }
};

export const postInstagramVideo = (
  embedVideoId: string,
  opts: ShareVideoOptions,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.INSTAGRAM_VIDEO_POST_REQUEST });

  const { accessToken } = instagramUserDataSelector(getState());

  try {
    const {
      response: { socialShareId },
    } = await dispatch(
      shareVideo(
        embedVideoId,
        SocialSharePlatform.INSTAGRAM,
        accessToken,
        opts,
      ),
    );

    dispatch({
      payload: { socialShareId },
      type: Type.INSTAGRAM_VIDEO_POST_SUCCESS,
    });

    await dispatch(waitForInstagramPost());
  } catch (err) {
    dispatch({
      error: err,
      type: Type.INSTAGRAM_VIDEO_POST_FAILURE,
    });

    dispatch(showErrorNotification());
  }
};

export const getInstagramAccountInfo = (
  instagramId?: string | number,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.INSTAGRAM_ACCOUNT_INFO_REQUEST });

  try {
    const {
      providerUserId = instagramId,
      accessToken,
    } = instagramUserDataSelector(getState());

    const { response } = await dispatch(
      getInstagramAccountInfoAction(providerUserId, accessToken, instagramId),
    );

    dispatch({
      payload: response,
      type: Type.INSTAGRAM_ACCOUNT_INFO_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: new Error('Error getting Instagram account info'),
      type: Type.INSTAGRAM_ACCOUNT_INFO_FAILURE,
    });

    throw error;
  }
};
