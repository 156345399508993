import { directUpload } from 'redux/middleware/api/media-upload-service/actions';
import { fetchRecordingById } from 'redux/middleware/api/recording-service/actions';
import { postRecording } from 'redux/middleware/api/recording-upload-service/actions';
import reduxPoll from 'utils/redux-poll';
import { preSignedUpload } from 'utils/request';
import { recordingsSelector } from '../entities/selectors';
import { UploadType } from './constants';
import * as uploadSelectors from './selectors';
import * as types from './types';

const IMPORT_POLL_ID = 'app/recording-upload/import-poll';

const pollForAudio = (dispatch, task, opts) =>
  reduxPoll(dispatch, task, {
    intervalMillis: spareminConfig.recordingImportPollIntervalMillis,
    maxAttempts: spareminConfig.recordingImportPollMaxAttempts,
    retryAttempts: spareminConfig.recordingImportRetryAttempts,
    ...opts,
  });

/**
 * action that waits for audio import to complete.
 *
 * note that audio is imported automatically as part of upload, so this action won't initiate an
 * import.
 */
const waitForAudioImport = recordingId => (dispatch, getState) => {
  dispatch({ type: types.UPLOAD_AWAIT_IMPORT_REQUEST });

  /*
   * the call entry id won't change, but the call entry object in the `entities` key will change.
   * this function is used to get the call entry object at different points in the upload process
   */
  const getCallRecording = () => {
    const recordings = recordingsSelector(getState());
    return recordings.get(String(recordingId));
  };

  const futUploadComplete = pollForAudio(
    dispatch,
    () => dispatch(fetchRecordingById(recordingId)),
    {
      id: IMPORT_POLL_ID,
      shouldContinue: err =>
        !err && getCallRecording().get('versionId') === undefined,
    },
  );

  return futUploadComplete
    .then(() => {
      dispatch({ type: types.UPLOAD_AWAIT_IMPORT_SUCCESS });
    })
    .catch(err => {
      dispatch({
        type: types.UPLOAD_AWAIT_IMPORT_FAILURE,
        error: err.message,
      });
      throw err;
    });
};

function getUploadingRecordingId(type, getState) {
  const upload = uploadSelectors.recordingUploadSelector(getState());
  if (!upload.inProgress || upload.type !== type) return undefined;
  return upload.recordingId;
}

const continueAudioUpload = type => (dispatch, getState) => {
  const recordingId = getUploadingRecordingId(type, getState);
  if (!recordingId) return Promise.resolve();

  return dispatch(waitForAudioImport(recordingId))
    .then(() => {
      dispatch({ type: types.UPLOAD_AUDIO_SUCCESS });
      return {
        recordingId: uploadSelectors.uploadedRecordingIdSelector(getState()),
      };
    })
    .catch(err => {
      const message = 'Upload failed';
      dispatch({
        type: types.UPLOAD_AUDIO_FAILURE,
        error: message,
      });
      throw new Error(message);
    });
};

const uploadAudio = (file, type, language, waveGeneration, transcription) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: types.UPLOAD_AUDIO_REQUEST,
    payload: { type },
  });

  const uploadResult =
    typeof file === 'string'
      ? dispatch(postRecording({ file, language, waveGeneration }))
      : dispatch(directUpload(file)).then(({ response }) => {
          const { bucket, key, upload } = response;
          return preSignedUpload(upload.url, file, upload.fields).then(() => {
            return dispatch(
              postRecording({
                language,
                directUploadBucket: bucket,
                directUploadKey: key,
              }),
            );
          });
        });

  return (
    uploadResult
      // TODO - SPAR-16528 YK updating backend to send snake
      .then(({ response: { recordingId } }) =>
        dispatch(fetchRecordingById(recordingId)),
      )
      .then(() => dispatch(continueAudioUpload(type)))
  );
};

// ----- Rendered Audio Upload
export const resumeRenderedUpload = () => dispatch =>
  dispatch(continueAudioUpload(UploadType.RENDERED));

export const uploadRenderedAudio = (
  file,
  language,
  waveGeneration,
) => dispatch => {
  return dispatch(
    uploadAudio(file, UploadType.RENDERED, language, waveGeneration),
  );
};

// ----- Wizard Audio Upload
export const uploadWizardAudio = (
  file,
  language,
  waveGeneration,
  transcription,
) => dispatch =>
  dispatch(
    uploadAudio(
      file,
      UploadType.WIZARD,
      language,
      waveGeneration,
      transcription,
    ),
  );
