import cn from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'underscore';

import LinkButton from 'components/LinkButton';
import { hideModal, pushModal } from 'redux/modules/modal/actions';
import { Modal } from 'redux/modules/modal/types';
import { Dispatch } from 'redux/types';
import bem from 'utils/bem';
import { createChainedFunction } from 'utils/functions';

const { useCallback } = React;

const block = bem('modal-link');

export interface ModalLinkProps {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  to: Modal;
}

const ModalLink: React.FC<ModalLinkProps> = ({
  children,
  className,
  onClick = noop,
  to,
}) => {
  const dispatch = useDispatch<Dispatch>();

  const handleClick = useCallback(() => {
    dispatch(hideModal());
    dispatch(pushModal(to));
  }, [dispatch, to]);

  return (
    <LinkButton
      className={cn(block(), className)}
      onClick={createChainedFunction(onClick, handleClick)}
    >
      {children}
    </LinkButton>
  );
};

export default ModalLink;
