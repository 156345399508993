import { useEffect } from 'react';

/**
 * injects css string into style tag in head of document
 */
export default function useStyle(css: string) {
  useEffect(() => {
    if (!css) return undefined;

    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    styleElement.innerHTML = css;
    document.getElementsByTagName('head')[0].appendChild(styleElement);

    return () => {
      styleElement.parentNode.removeChild(styleElement);
    };
  }, [css]);
}
