import * as React from 'react';

import Enterprise from '../Enterprise';
import CircularGradient, { CircularGradientProps } from './CircularGradient';

export type CircularGradientEnterpriseProps = Pick<
  CircularGradientProps,
  'className'
>;

const CircularGradientEnterprise: React.FC<CircularGradientEnterpriseProps> = ({
  className,
}) => (
  <CircularGradient className={className}>
    <Enterprise style={{ height: 'auto', width: 65 }} />
  </CircularGradient>
);

export default CircularGradientEnterprise;
