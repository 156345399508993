import * as React from 'react';

import { WaveformProps } from '../../types';

const WaveBars = React.forwardRef<SVGSVGElement, WaveformProps>(
  (props, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 32"
      preserveAspectRatio="none"
      ref={ref}
      {...props}
    >
      <path d="M0.004 16.791h0.197v15.209h-0.197v-15.209z" />
      <path d="M0.399 4.464h0.197v27.536h-0.197v-27.536z" />
      <path d="M0.793 2.966h0.197v29.034h-0.197v-29.034z" />
      <path d="M1.188 8.456h0.197v23.544h-0.197v-23.544z" />
      <path d="M1.582 9.229h0.197v22.771h-0.197v-22.771z" />
      <path d="M1.977 6.524h0.197v25.476h-0.197v-25.476z" />
      <path d="M2.372 8.149h0.197v23.851h-0.197v-23.851z" />
      <path d="M2.766 13.456h0.197v18.544h-0.197v-18.544z" />
      <path d="M3.161 16.166h0.197v15.834h-0.197v-15.834z" />
      <path d="M3.555 17.218h0.197v14.782h-0.197v-14.782z" />
      <path d="M3.95 15.089h0.197v16.911h-0.197v-16.911z" />
      <path d="M4.345 13.475h0.197v18.525h-0.197v-18.525z" />
      <path d="M4.739 15.057h0.197v16.943h-0.197v-16.943z" />
      <path d="M5.134 14.426h0.197v17.574h-0.197v-17.574z" />
      <path d="M5.528 6.411h0.197v25.589h-0.197v-25.589z" />
      <path d="M5.923 7.334h0.197v24.666h-0.197v-24.666z" />
      <path d="M6.318 12.897h0.197v19.103h-0.197v-19.103z" />
      <path d="M6.712 10.653h0.197v21.347h-0.197v-21.347z" />
      <path d="M7.107 7.957h0.197v24.043h-0.197v-24.043z" />
      <path d="M7.501 11.615h0.197v20.385h-0.197v-20.385z" />
      <path d="M7.896 12.781h0.197v19.219h-0.197v-19.219z" />
      <path d="M8.291 15.734h0.197v16.266h-0.197v-16.266z" />
      <path d="M8.685 17.192h0.197v14.808h-0.197v-14.808z" />
      <path d="M9.080 13.066h0.197v18.934h-0.197v-18.934z" />
      <path d="M9.474 12.937h0.197v19.063h-0.197v-19.063z" />
      <path d="M9.869 9.478h0.197v22.522h-0.197v-22.522z" />
      <path d="M10.264 12.869h0.197v19.131h-0.197v-19.131z" />
      <path d="M10.658 8.249h0.197v23.751h-0.197v-23.751z" />
      <path d="M11.053 9.998h0.197v22.002h-0.197v-22.002z" />
      <path d="M11.447 13.963h0.197v18.037h-0.197v-18.037z" />
      <path d="M11.842 12.627h0.197v19.373h-0.197v-19.373z" />
      <path d="M12.237 11.371h0.197v20.629h-0.197v-20.629z" />
      <path d="M12.631 10.63h0.197v21.37h-0.197v-21.37z" />
      <path d="M13.026 14.070h0.197v17.93h-0.197v-17.93z" />
      <path d="M13.42 16.474h0.197v15.526h-0.197v-15.526z" />
      <path d="M13.815 16.708h0.197v15.292h-0.197v-15.292z" />
      <path d="M14.21 15.744h0.197v16.256h-0.197v-16.256z" />
      <path d="M14.604 17.064h0.197v14.936h-0.197v-14.936z" />
      <path d="M14.999 17.951h0.197v14.049h-0.197v-14.049z" />
      <path d="M15.393 18.869h0.197v13.131h-0.197v-13.131z" />
      <path d="M15.788 19.006h0.197v12.994h-0.197v-12.994z" />
      <path d="M16.183 15.092h0.197v16.908h-0.197v-16.908z" />
      <path d="M16.577 14.576h0.197v17.424h-0.197v-17.424z" />
      <path d="M16.972 16.594h0.197v15.406h-0.197v-15.406z" />
      <path d="M17.366 14.587h0.197v17.413h-0.197v-17.413z" />
      <path d="M17.761 12.351h0.197v19.649h-0.197v-19.649z" />
      <path d="M18.156 13.49h0.197v18.51h-0.197v-18.51z" />
      <path d="M18.55 11.12h0.197v20.88h-0.197v-20.88z" />
      <path d="M18.945 1.306h0.197v30.694h-0.197v-30.694z" />
      <path d="M19.339 9.143h0.197v22.857h-0.197v-22.857z" />
      <path d="M19.734 12.588h0.197v19.412h-0.197v-19.412z" />
      <path d="M20.129 8.26h0.197v23.74h-0.197v-23.74z" />
      <path d="M20.523 9.965h0.197v22.035h-0.197v-22.035z" />
      <path d="M20.918 14.933h0.197v17.067h-0.197v-17.067z" />
      <path d="M21.312 12.79h0.197v19.21h-0.197v-19.21z" />
      <path d="M21.707 10.732h0.197v21.268h-0.197v-21.268z" />
      <path d="M22.102 13.306h0.197v18.694h-0.197v-18.694z" />
      <path d="M22.496 9.283h0.197v22.717h-0.197v-22.717z" />
      <path d="M22.891 11.33h0.197v20.67h-0.197v-20.67z" />
      <path d="M23.285 12.278h0.197v19.722h-0.197v-19.722z" />
      <path d="M23.68 9.831h0.197v22.169h-0.197v-22.169z" />
      <path d="M24.075 11.718h0.197v20.282h-0.197v-20.282z" />
      <path d="M24.469 13.534h0.197v18.466h-0.197v-18.466z" />
      <path d="M24.864 13.095h0.197v18.905h-0.197v-18.905z" />
      <path d="M25.258 12.345h0.197v19.655h-0.197v-19.655z" />
      <path d="M25.653 15.668h0.197v16.332h-0.197v-16.332z" />
      <path d="M26.048 18.202h0.197v13.798h-0.197v-13.798z" />
      <path d="M26.442 16.112h0.197v15.888h-0.197v-15.888z" />
      <path d="M26.837 15.693h0.197v16.307h-0.197v-16.307z" />
      <path d="M27.231 17.019h0.197v14.981h-0.197v-14.981z" />
      <path d="M27.626 15.843h0.197v16.157h-0.197v-16.157z" />
      <path d="M28.021 7.271h0.197v24.729h-0.197v-24.729z" />
      <path d="M28.415 10.533h0.197v21.467h-0.197v-21.467z" />
      <path d="M28.81 11.557h0.197v20.443h-0.197v-20.443z" />
      <path d="M29.204 10.115h0.197v21.885h-0.197v-21.885z" />
      <path d="M29.599 12.732h0.197v19.268h-0.197v-19.268z" />
      <path d="M29.994 14.819h0.197v17.181h-0.197v-17.181z" />
      <path d="M30.388 17.113h0.197v14.887h-0.197v-14.887z" />
      <path d="M30.783 16.8h0.197v15.2h-0.197v-15.2z" />
      <path d="M31.177 14.027h0.197v17.973h-0.197v-17.973z" />
      <path d="M31.572 13.967h0.197v18.032h-0.197v-18.032z" />
      <path d="M31.967 11.985h0.197v20.015h-0.197v-20.015z" />
      <path d="M32.361 9.95h0.197v22.050h-0.197v-22.050z" />
      <path d="M32.756 10.488h0.197v21.512h-0.197v-21.512z" />
      <path d="M33.15 10.017h0.197v21.983h-0.197v-21.983z" />
      <path d="M33.545 8.682h0.197v23.318h-0.197v-23.318z" />
      <path d="M33.94 10.916h0.197v21.084h-0.197v-21.084z" />
      <path d="M34.334 13.417h0.197v18.583h-0.197v-18.583z" />
      <path d="M34.729 16.412h0.197v15.588h-0.197v-15.588z" />
      <path d="M35.123 16.412h0.197v15.588h-0.197v-15.588z" />
      <path d="M35.518 13.946h0.197v18.054h-0.197v-18.054z" />
      <path d="M35.913 14.838h0.197v17.162h-0.197v-17.162z" />
      <path d="M36.307 16.99h0.197v15.010h-0.197v-15.010z" />
      <path d="M36.702 17.156h0.197v14.844h-0.197v-14.844z" />
      <path d="M37.096 0.873h0.197v31.127h-0.197v-31.127z" />
      <path d="M37.491 0.032h0.197v31.968h-0.197v-31.968z" />
      <path d="M37.886 6.345h0.197v25.655h-0.197v-25.655z" />
      <path d="M38.28 4.627h0.197v27.373h-0.197v-27.373z" />
      <path d="M38.675 6.556h0.197v25.444h-0.197v-25.444z" />
      <path d="M39.069 12.925h0.197v19.075h-0.197v-19.075z" />
      <path d="M39.464 15.977h0.197v16.023h-0.197v-16.023z" />
      <path d="M39.859 17.332h0.197v14.668h-0.197v-14.668z" />
      <path d="M40.253 12.093h0.197v19.907h-0.197v-19.907z" />
      <path d="M40.648 14.309h0.197v17.691h-0.197v-17.691z" />
      <path d="M41.042 14.45h0.197v17.55h-0.197v-17.55z" />
      <path d="M41.437 14.691h0.197v17.309h-0.197v-17.309z" />
      <path d="M41.832 16.348h0.197v15.652h-0.197v-15.652z" />
      <path d="M42.226 13.801h0.197v18.199h-0.197v-18.199z" />
      <path d="M42.621 14.063h0.197v17.937h-0.197v-17.937z" />
      <path d="M43.015 15.218h0.197v16.782h-0.197v-16.782z" />
      <path d="M43.41 13.179h0.197v18.821h-0.197v-18.821z" />
      <path d="M43.805 10.073h0.197v21.927h-0.197v-21.927z" />
      <path d="M44.199 14.228h0.197v17.772h-0.197v-17.772z" />
      <path d="M44.594 16.382h0.197v15.618h-0.197v-15.618z" />
      <path d="M44.988 17.326h0.197v14.674h-0.197v-14.674z" />
      <path d="M45.383 17.343h0.197v14.657h-0.197v-14.657z" />
      <path d="M45.778 15.455h0.197v16.545h-0.197v-16.545z" />
      <path d="M46.172 11.287h0.197v20.713h-0.197v-20.713z" />
      <path d="M46.567 3.172h0.197v28.828h-0.197v-28.828z" />
      <path d="M46.961 9.489h0.197v22.511h-0.197v-22.511z" />
      <path d="M47.356 7.962h0.197v24.038h-0.197v-24.038z" />
      <path d="M47.751 11.334h0.197v20.666h-0.197v-20.666z" />
      <path d="M48.145 11.18h0.197v20.82h-0.197v-20.82z" />
      <path d="M48.54 12.847h0.197v19.153h-0.197v-19.153z" />
      <path d="M48.934 14.857h0.197v17.143h-0.197v-17.143z" />
      <path d="M49.329 9.793h0.197v22.207h-0.197v-22.207z" />
      <path d="M49.724 11.453h0.197v20.547h-0.197v-20.547z" />
      <path d="M50.118 13.112h0.197v18.888h-0.197v-18.888z" />
    </svg>
  ),
);

export default WaveBars;
