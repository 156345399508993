import * as React from 'react';

import { block } from './utils';

export interface CardDetailsProps {
  brand: string;
  last4Digits: string;
}

const CardDetails: React.SFC<CardDetailsProps> = ({ brand, last4Digits }) => (
  <>
    <span className={block('card-brand')}>{brand}</span> ending in {last4Digits}{' '}
  </>
);

export default CardDetails;
