import { LocationDescriptor, LocationDescriptorObject } from 'history';
import * as queryString from 'query-string';
import { RouteChildrenProps } from 'react-router';
import * as ids from 'short-id';
import { omit } from 'underscore';
import Url from 'url-parse';

import UrlPattern from 'url-pattern';
import { parseQueryParams } from 'blocks/Authentication/utils';
// import from deep in the AutomationWorkflowWizard module to avoid circular import
import { AutogramWizardStepIndex } from 'blocks/AutomationWorkflowWizard/containers/AutomationWorkflowWizard/constants';
import { TemplateType, WizardType } from 'types';
import { omitUndefined, pick } from '../collections';
import {
  AudiogramWizardLocationOptions,
  AutogramWizardLocationOptions,
  FullEpisodeWizardLocationOptions,
} from './types';

export const AUTH_PAGE_ROUTES = {
  login: '/login',
  onboarding: '/onboarding',
  resetPassword: '/reset-password',
  signup: '/sign-up',
} as const;

export const AUTH_ROUTES = Object.values(AUTH_PAGE_ROUTES);

const AUTH_PATTERNS = AUTH_ROUTES.map(r => new UrlPattern(r));

export const EDITOR_ROUTES = ['/project', '/project/:pid', '/edit'] as const;

const EDIT_PATTERN = new UrlPattern('/edit');
const PROJECT_PATTERN = new UrlPattern('/project(/:pid)');
const PROJECTS_PATTERN = new UrlPattern('/projects');
const SETTINGS_PATTERN = new UrlPattern('/settings*');
const DOWNLOAD_PATTERN = new UrlPattern('/download*');
const WIZARD_PATTERN = new UrlPattern('/wizard*');
const CREATE_PATTERN = new UrlPattern('/create');
const AUTOMATION_PATTERN = new UrlPattern(getAutomationPath());
const ONBOARDING_PATTERN = new UrlPattern('/onboarding');
const MY_PLAN_PATTERN = new UrlPattern('/my-plan');
const RESET_PASSWORD_PATTERN = new UrlPattern(AUTH_PAGE_ROUTES.resetPassword);

export const ACCESS_TOKEN_NAME = 'accessToken';

export function parseSearch(
  search: string | LocationDescriptorObject,
  opts?: queryString.ParseOptions,
) {
  if (!search) return {};

  const searchString = typeof search === 'string' ? search : search.search;

  return queryString.parse(searchString, {
    parseBooleans: true,
    parseNumbers: true,
    ...opts,
  });
}

export function stringifyQuery(query: { [key: string]: any }): string {
  return queryString.stringify(omitUndefined(query));
}

function isActive(pattern: UrlPattern) {
  const { pathname } = window.location;
  return pattern.match(pathname) !== null;
}

function hasParam(name: string, value: string) {
  const params = parseSearch(window.location.search);
  return params[name] === value;
}

export function isProjectActive() {
  return isActive(PROJECT_PATTERN);
}

export function isProjectsActive() {
  return isActive(PROJECTS_PATTERN);
}

export function isEditActive() {
  return isActive(EDIT_PATTERN);
}

export function isSettingsActive() {
  return isActive(SETTINGS_PATTERN);
}

export function isEditorActive() {
  const editActive = isEditActive();
  const projectActive = isProjectActive();
  const settingsActive = isSettingsActive();

  return !settingsActive && (editActive || projectActive);
}

export function isDownloadActive() {
  return isActive(DOWNLOAD_PATTERN);
}

export function isWizardActive() {
  return isActive(WIZARD_PATTERN);
}

export function isCreateActive() {
  return isActive(CREATE_PATTERN);
}

export function isAutomationActive() {
  return isActive(AUTOMATION_PATTERN);
}
export function isMyPlanActive() {
  return isActive(MY_PLAN_PATTERN);
}

export function isAudiogramActive() {
  return isWizardActive() && hasParam('type', 'audiogram');
}

export function isFullEpisodeWizardActive() {
  return isWizardActive() && hasParam('type', 'episode');
}

export function isAutogramActive() {
  return isWizardActive() && hasParam('type', 'autovideo');
}

export function isTemplateWizardActive() {
  return isWizardActive() && hasParam('type', 'template');
}

export function isAudioWizardActive() {
  return isWizardActive() && hasParam('type', 'audio');
}

export function isOnboardingActive() {
  return isActive(ONBOARDING_PATTERN);
}

// checks if any authentication route is active
export function isAuthActive() {
  return AUTH_PATTERNS.some(isActive);
}

export function isResetPasswordActive() {
  return isActive(RESET_PASSWORD_PATTERN);
}

export function willRouteChange({ newRoute }) {
  return !new UrlPattern(newRoute).match(window.location.pathname);
}

export function getAccessToken(
  routerProps: RouteChildrenProps,
  accessTokenName: string,
) {
  const { location } = routerProps;
  if (!accessTokenName || !location) return undefined;

  const query = parseSearch(location.search);
  return query[accessTokenName] as string;
}

export function removeQueryParam(routeProps, ...paramName: string[]) {
  const { location } = routeProps;
  const query = queryString.parse(location.search);
  const modifiedQuery = omit(query, paramName);
  return {
    ...omit(location, 'action'),
    search: queryString.stringify(modifiedQuery),
  };
}

export function removeQueryParamFromURL(
  url: string,
  ...paramName: string[]
): string {
  const { url: baseUrl, query } = queryString.parseUrl(url);
  const sanitizedQuery = omit(query, paramName);
  return queryString.stringifyUrl({ url: baseUrl, query: sanitizedQuery });
}

export function addQueryParams(
  location: LocationDescriptorObject,
  query: { [k: string]: any },
): LocationDescriptorObject {
  const locationQuery = parseSearch(location.search);

  return {
    ...pick(location, 'pathname', 'state'),
    search: stringifyQuery({
      ...locationQuery,
      ...query,
    }),
  };
}

export const createRedirector = (pathname, redirect) => (
  forwardSearch: boolean,
) => {
  const locationDescriptor = {
    pathname,
    search: forwardSearch ? window.location.search : '',
  };
  return redirect(locationDescriptor);
};

export function hasHeadlinerReferrer() {
  if (!document.referrer) {
    return false;
  }

  const { hostname: referrerHostname } = new Url(document.referrer);
  return referrerHostname === window.location.hostname;
}

export function getProjectsPath() {
  return '/projects';
}

export function getCreatePath() {
  return '/create';
}

export function getAutomationPath() {
  return '/automation';
}

export function getMyPlanPath() {
  return '/settings/my-plan';
}

export function getReferralsPath() {
  return '/settings/referrals';
}

export function getEmailSettingsPath() {
  return '/settings/email';
}

export function getHomepage() {
  return getCreatePath();
}

export function getDownloadPath(widgetId: string) {
  return `/download/${widgetId}`;
}

export function getWizardTypeFromSearch(
  search: LocationDescriptor,
): WizardType | null {
  const params = parseSearch(search);

  if (params.type) {
    return params.type as WizardType;
  }

  return null;
}

export function getAudiogramWizardPath(
  options?: AudiogramWizardLocationOptions,
): LocationDescriptorObject {
  return {
    pathname: '/wizard',
    search: stringifyQuery({
      ...options,
      type: 'audiogram',
    }),
  };
}

export function getAudiogramWizardQueryParams(
  search: string,
): AudiogramWizardLocationOptions {
  return parseSearch(search) as AudiogramWizardLocationOptions;
}

export function getFullEpisodeWizardPath(
  options?: FullEpisodeWizardLocationOptions,
) {
  return {
    pathname: '/wizard',
    search: stringifyQuery({
      ...options,
      type: 'episode',
    }),
  };
}

export function getFullEpisodeWizardQueryParams(
  search: string,
): FullEpisodeWizardLocationOptions {
  return parseSearch(search) as FullEpisodeWizardLocationOptions;
}

export function getVideoTranscriptionWizardPath(
  file?: File,
): LocationDescriptorObject {
  return {
    pathname: '/wizard',
    search: '?type=video-transcription',
    state: {
      file,
    },
  };
}

export function getAudioWizardPath(
  episodeId: number | string,
): LocationDescriptorObject {
  return {
    pathname: '/wizard',
    search: stringifyQuery({
      episodeId,
      type: 'audio',
    }),
  };
}

export function autogramWizardLocation(
  options: AutogramWizardLocationOptions = {},
  forceReload = true,
): LocationDescriptor {
  const { initialStep, podcastId, workflowId } = options;

  const inferredInitialStep = workflowId
    ? AutogramWizardStepIndex.DURATION
    : podcastId
    ? AutogramWizardStepIndex.LANGUAGE
    : 1;

  return {
    pathname: '/wizard',
    search: stringifyQuery({
      ...options,
      initialStep: initialStep ?? inferredInitialStep,
      type: 'autovideo',
    }),
    state: forceReload ? ids.generate() : undefined,
  };
}

export function templateWizardLocation(
  templateId: string,
  templateType: TemplateType,
): LocationDescriptorObject {
  return {
    pathname: '/wizard',
    search: stringifyQuery({
      templateId,
      templateType,
      type: 'template',
    }),
  };
}

export function setAuthWrapperRedirect(location, redirectBaseUrl) {
  const redirect = queryString.stringifyUrl({
    url: redirectBaseUrl,
    query: parseQueryParams(location), // keep redirect query parameters!
  });
  const path = addQueryParams(location, { redirect });
  return path;
}

export function openTemplateInEditorLocation(templateId, templateType) {
  return {
    pathname: '/project',
    search: stringifyQuery({
      projectType: templateType === 'headlinerDefault' ? 'template' : undefined,
      tid: templateId,
    }),
  };
}

export default {
  isProjectActive,
  isEditActive,
  isEditorActive,
  willRouteChange,
};
