import * as React from 'react';

import { IconProps } from './types';

const Arrow: React.SFC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      // fill="#DCE1EB"
      fill="currentColor"
      fillRule="nonzero"
      stroke="#000"
      strokeWidth="1.75"
      d="M3.464 15.924l.003-.003a1.034 1.034 0 0 1 1.492.02l10.71 11.27V1.928c0-.586.466-1.053 1.049-1.053h2.564c.583 0 1.049.467 1.049 1.053v25.283l1.51-1.588 9.198-9.68a1.047 1.047 0 0 1 1.497-.019l1.783 1.787a1.049 1.049 0 0 1-.004 1.485L18.732 34.819a1.037 1.037 0 0 1-1.475-.003L1.681 19.193a1.049 1.049 0 0 1 .004-1.485l1.779-1.784zm-1.24-1.234l-.002.002.003-.004-.002.002z"
    />
  </svg>
);

export default Arrow;
