import { useState } from 'react';
import { useLocation } from 'react-router';
import { noop } from 'underscore';

import useOnMount from 'hooks/useOnMount';
import { Tier } from 'redux/middleware/api/plan-service';
import { parseQueryParams } from '../utils';

export interface RegistrationQueryStringData {
  promoKey?: string | undefined;
  referralKey?: string | undefined;
  tier?: Tier.FREE | Tier.PRO | Tier.BASIC | Tier.UNLIMITED;
  traceId?: string;
}

interface Props {
  onLoad?: (data: RegistrationQueryStringData) => void;
}

export default function useRegistrationQuery(props?: Props) {
  const { onLoad = noop } = props ?? {};
  const location = useLocation();
  const [queryStringData, setQueryStringData] = useState<
    RegistrationQueryStringData
  >({});

  useOnMount(() => {
    const rawData = parseQueryParams(location);

    const data = {
      promoKey:
        rawData?.referralType === 'promo' ? rawData.referralKey : undefined,
      referralKey:
        rawData?.referralType === 'referral' ? rawData.referralKey : undefined,
      tier: rawData?.tier,
      traceId: rawData.traceId,
    };

    setQueryStringData(data);
    onLoad(data);
  });

  return queryStringData;
}
