import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  externalPortalLinkSelector,
  loadExternalPortalLink,
} from 'redux/modules/pricing';
import { Dispatch } from 'redux/types';
import BillingHistoryLinkView, {
  LinkProps as Props,
} from './BillingHistoryLinkView';

type LinkProps = Pick<Props, 'href' | 'disabled'>;

type ChildrenCallback = (props: LinkProps) => React.ReactNode;

interface BillingHistoryLinkProps {
  children: React.ReactNode | ChildrenCallback;
}

const BillingHistoryLink: React.FC<BillingHistoryLinkProps> = props => {
  const { children } = props;
  const dispatch = useDispatch<Dispatch>();
  const externalPortalLink = useSelector(externalPortalLinkSelector);

  const linkProps: LinkProps = {
    href: externalPortalLink,
    disabled: !externalPortalLink,
  };

  useEffect(() => {
    if (!externalPortalLink) {
      dispatch(loadExternalPortalLink());
    }
  }, [dispatch, externalPortalLink]);

  return (
    <BillingHistoryLinkView {...linkProps}>{children}</BillingHistoryLinkView>
  );
};

BillingHistoryLink.displayName = 'BillingHistoryLink';

export default BillingHistoryLink;
