import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { Omit } from 'types';
import FormField, { FormFieldProps } from './FormField';
import InputControl from './InputControl';
import { block } from './utils';

type InputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'value' | 'onChange'
>;

interface IProps extends InputProps {
  children?: React.ReactNode;
  className?: string;
  horizontal?: FormFieldProps['horizontal']['label'];
  inputClassName?: string;
  onChange?: (checked: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
}

type Props = Omit<FormFieldProps<IProps>, 'label'>;

export default class CheckboxField extends React.Component<Props> {
  public static defaultProps: Partial<IProps> = {
    onChange: noop,
  };

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    onChange(e.target.checked, e);
  };

  public render() {
    const {
      children,
      checked,
      className,
      controlClassName,
      disabled,
      inputClassName,
      labelClassName,
      onChange,
      horizontal,
      ...props
    } = this.props;

    return (
      <FormField
        {...props}
        className={cn(block('checkbox-field'), className)}
        disabled={disabled}
        horizontal={horizontal && { label: horizontal }}
        label={
          <div className={cn(block('checkbox-control'), controlClassName)}>
            <InputControl>
              <input
                checked={checked}
                className={cn(block('checkbox-input'), inputClassName)}
                disabled={disabled}
                onChange={this.handleChange}
                type="checkbox"
              />
            </InputControl>
            {children}
          </div>
        }
        labelClassName={cn(block('checkbox-label'), labelClassName)}
      />
    );
  }
}

export { IProps as CheckboxFieldProps };
