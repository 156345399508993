import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'AUTH_SERVICE';
export const ACTION_KEY: ActionKey = 'AUTH_SERVICE';

export enum ServiceMethod {
  LOGIN = 'LOGIN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  CONFIRM_EMAIL = 'CONFIRM_EMAIL',
  RESEND_VERIFICATION_EMAIL = 'RESEND_VERIFICATION_EMAIL',
}

export type LoginArgs = [string, string];
export interface LoginResult {
  handle: string;
  token: string;
  userId: number;
}

export type ForgotPasswordArgs = [string /* email */];
export type ForgotPasswordResult = any;

export type UpdatePasswordArgs = [number, string, string, string];
export type UpdatePasswordResult = any;

export type ConfirmEmailArgs = [string];
export type ConfirmEmailResult = any;

export type ResendVerificationEmailArgs = [number];
export type ResendVerificationEmailResult = any;

export type ServiceResults =
  | LoginResult
  | ForgotPasswordResult
  | UpdatePasswordResult
  | ConfirmEmailResult
  | ResendVerificationEmailResult;

export type IHandle = (
  method: ServiceMethod,
  args: any,
  token?: string,
) => Promise<ServiceResults>;

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export type LoginAction = ApiAction<ServiceMethod.LOGIN, LoginArgs>;
export type ForgotPasswordAction = ApiAction<
  ServiceMethod.FORGOT_PASSWORD,
  ForgotPasswordArgs
>;
export type UpdatePasswordAction = ApiAction<
  ServiceMethod.UPDATE_PASSWORD,
  UpdatePasswordArgs
>;
export type ConfirmEmailAction = ApiAction<
  ServiceMethod.CONFIRM_EMAIL,
  ConfirmEmailArgs
>;
export type ResendVerificationEmailAction = ApiAction<
  ServiceMethod.RESEND_VERIFICATION_EMAIL,
  ResendVerificationEmailArgs
>;

export interface AuthServiceDispatch {
  (action: LoginAction): Promise<IApiResponse<LoginResult>>;
  (action: ForgotPasswordAction): Promise<IApiResponse<ForgotPasswordResult>>;
  (action: UpdatePasswordAction): Promise<IApiResponse<UpdatePasswordResult>>;
  (action: ConfirmEmailAction): Promise<IApiResponse<ConfirmEmailResult>>;
  (action: ResendVerificationEmailAction): Promise<
    IApiResponse<ResendVerificationEmailResult>
  >;
}
