import React from 'react';
import DownloadCaptionsModal from './DownloadCaptionsModal';
import DownloadEpisodeCaptionsModalContents from './DownloadEpisodeCaptionsModalContents';

export interface DownloadEpisodeCaptionsModalProps {}

const DownloadEpisodeCaptionsModal: React.FC<DownloadEpisodeCaptionsModalProps> = () => (
  <DownloadCaptionsModal
    name="DownloadEpisodeCaptions"
    title="Episode Transcript"
  >
    <DownloadEpisodeCaptionsModalContents />
  </DownloadCaptionsModal>
);

export default DownloadEpisodeCaptionsModal;
