import * as React from 'react';
import { noop } from 'underscore';

import SaveToDriveButton from 'components/SaveToDriveButton';
import SaveToDropboxButton from 'components/SaveToDropboxButton';
import { block } from '../../utils';

export interface CloudUploadSectionProps {
  isMobile: boolean;
  onCloudSave?: (provider: string) => void;
  onUploadSuccess?: (provider: string) => void;
  videoFileName?: string;
  videoUrl: string;
}

const CloudUploadSection: React.SFC<CloudUploadSectionProps> = ({
  isMobile,
  onCloudSave,
  onUploadSuccess,
  videoFileName,
  videoUrl,
}) => {
  return (
    <div className={block('cloud-section')}>
      <div className={block('cloud-row')}>
        <div className={block('cloud-label')}>
          Upload to cloud storage accounts:
        </div>
        <div className={block('cloud-buttons')}>
          {!isMobile && videoUrl && videoFileName && (
            <SaveToDriveButton sourceFile={videoUrl} fileName={videoFileName} />
          )}
          {videoUrl && videoFileName && (
            <SaveToDropboxButton
              className={block('dropbox-button')}
              onClick={() => onCloudSave?.('dropbox')}
              onUploadSuccess={() => onUploadSuccess?.('dropbox')}
              sourceFile={videoUrl}
              fileName={videoFileName}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CloudUploadSection.defaultProps = {
  onCloudSave: noop,
  onUploadSuccess: noop,
};

export default CloudUploadSection;
