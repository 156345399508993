import cn from 'classnames';
import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import { noop } from 'underscore';

import { ToggleField } from 'components/Form';
import Modal from 'components/Modal';
import PopoverAlert from 'components/PopoverAlert';
import SwitchEditorModalBanner from 'components/SwitchEditorModalBanner';
import { getAspectRatioName } from 'utils/aspect-ratio';

import LegacyEditCaptionsModalBody, {
  LegacyEditCaptionsModalBodyProps,
} from './LegacyEditCaptionsModalBody';
import { block } from './utils';

type BodyProps = Pick<
  LegacyEditCaptionsModalBodyProps,
  'caption' | 'fonts' | 'styleTemplates' | 'workspaceAspectRatio'
>;

export interface LegacyEditCaptionsModalProps extends BodyProps {
  className?: string;
  lockCaptions?: boolean;
  onCaptionsLockChange?: (isLocked: boolean) => void;
  onHide?: () => void;
  onSubmit?: (style, maxChars, templateId, rechunk) => void;
  onSwitchLegacyMode: () => void;
  onTemplateSelect?: (displayName: string) => void;
  show?: boolean;
}

interface IUiState {
  rechunkRequired: boolean;
}

interface IState {
  ui: RecordOf<IUiState>;
  visibleTextOffsets: [number, number];
}

const uiFactory = Record<IUiState>({
  rechunkRequired: false,
});

export default class EditCaptionsModal extends React.Component<
  LegacyEditCaptionsModalProps,
  IState
> {
  public static defaultProps: Partial<LegacyEditCaptionsModalProps> = {
    onHide: noop,
    onSubmit: noop,
    show: false,
    styleTemplates: [],
  };

  private modalBody: LegacyEditCaptionsModalBody;
  private saveButton: HTMLElement;

  public state: Readonly<IState> = {
    ui: uiFactory(),
    visibleTextOffsets: [0, 0],
  };

  private handleRechunkRequired = () => {
    this.setState(({ ui }) => ({
      ui: ui.set('rechunkRequired', true),
    }));
  };

  private handleHide = () => {
    const { onHide } = this.props;
    this.setState(({ ui }) => ({
      ui: ui.set('rechunkRequired', false),
    }));
    onHide();
  };

  private handleSubmit = () => {
    const { onSubmit, lockCaptions } = this.props;
    const { ui: currentUi } = this.state;

    const { maxChars, style, templateId } = this.modalBody.caption;
    onSubmit(
      style,
      maxChars,
      templateId,
      !lockCaptions && currentUi.rechunkRequired,
    );
    this.setState(({ ui }) => ({
      ui: ui.set('rechunkRequired', false),
    }));
  };

  private handleToggleCaptionsLock = checked => {
    const { onCaptionsLockChange } = this.props;
    onCaptionsLockChange(checked);
  };

  private handleVisibleTextChange = (offsets: [number, number]) => {
    this.setState({
      visibleTextOffsets: offsets,
    });
  };

  private setSaveButtonRef = el => (this.saveButton = el);

  private renderBody() {
    const {
      caption,
      fonts,
      onTemplateSelect,
      styleTemplates,
      workspaceAspectRatio,
    } = this.props;
    const { visibleTextOffsets } = this.state;

    const bodyRef = el => {
      this.modalBody = el;
    };

    return (
      <LegacyEditCaptionsModalBody
        caption={caption}
        fonts={fonts}
        onRechunkRequired={this.handleRechunkRequired}
        onTemplateSelect={onTemplateSelect}
        onVisibleTextChange={this.handleVisibleTextChange}
        ref={bodyRef}
        styleTemplates={styleTemplates}
        visibleTextOffsets={visibleTextOffsets}
        workspaceAspectRatio={workspaceAspectRatio}
      />
    );
  }

  private renderCaptionsLock() {
    const { lockCaptions } = this.props;

    return (
      <div className="captions-modal__captions-lock">
        <form className="captions-modal__form">
          <ToggleField
            checked={lockCaptions}
            className="captions-modal__lock-field"
            horizontal={{
              control: { xs: 3 },
              label: { xs: 9 },
            }}
            label="Lock Caption Times"
            labelClassName="captions-modal__lock-label"
            onChange={this.handleToggleCaptionsLock}
          />
        </form>
      </div>
    );
  }

  private renderNewEditorBanner = () => {
    const { onSwitchLegacyMode } = this.props;

    return (
      <div className={block('new-text-modal-banner')}>
        <SwitchEditorModalBanner onSwitchMode={onSwitchLegacyMode} />
      </div>
    );
  };

  private renderFooter() {
    const { lockCaptions } = this.props;
    const { ui, visibleTextOffsets } = this.state;

    const overlayTarget = () => this.saveButton;

    const canFitText = visibleTextOffsets[1] - visibleTextOffsets[0] > 0;

    return (
      <Modal.Footer>
        {this.renderCaptionsLock()}
        <Modal.FooterButtons>
          <PopoverAlert
            className="captions-modal__popover"
            id="captions-popover-id"
            message={
              !canFitText
                ? 'Please enlarge the text box or decrease the font size to continue'
                : 'Saving may change your caption timings'
            }
            overlayProps={{
              containerPadding: 15,
              placement: 'top',
              show: (!lockCaptions && ui.rechunkRequired) || !canFitText,
              target: overlayTarget,
            }}
          />
          <Modal.FooterButton onClick={this.handleHide}>
            Cancel
          </Modal.FooterButton>
          <Modal.FooterButton
            onClick={this.handleSubmit}
            disabled={!canFitText}
            domRef={this.setSaveButtonRef}
            theme="submit"
          >
            Save
          </Modal.FooterButton>
        </Modal.FooterButtons>
        {this.renderNewEditorBanner()}
      </Modal.Footer>
    );
  }

  public render() {
    const { className, onHide, show, workspaceAspectRatio } = this.props;

    const ratioName = getAspectRatioName(workspaceAspectRatio);
    const ratioClass = ratioName && `captions-modal--${ratioName}`;

    const modalClassName = cn(
      'captions-modal',
      'captions-modal--default',
      ratioClass,
      className,
    );

    return (
      <Modal
        show={show}
        className={modalClassName}
        onHide={onHide}
        backdrop="static"
        title="Captions Properties"
      >
        <Modal.Body>{this.renderBody()}</Modal.Body>
        {this.renderFooter()}
      </Modal>
    );
  }
}
