import cn from 'classnames';
import * as React from 'react';
import _ from 'underscore';

import { DestinationPreset } from 'blocks/AudiogramDestinations/destinationPresets/types';
import { round } from 'utils/numbers';

import ClipDurationInputAdornment from './ClipDurationInputAdornment';
import ClipEndTimeInputAdornment from './ClipEndTimeInputAdornment';
import ClipperDurationField from './ClipperDurationField';
import ClipStartTimeInputAdornment from './ClipStartTimeInputAdornment';
import SocialPresetPopover from './SocialPresetPopover';
import { ClipperContainerMode, IRegion, SocialPresetOption } from './types';
import { block, isPresetBlocked } from './utils';

const PREVIEW_LENGTH_MS = 5000;

type InputType = 'start' | 'end' | 'duration';

type DurationInputHandler = (millis: number) => void;

interface IProps {
  className?: string;
  containerMode: ClipperContainerMode;
  disabled?: boolean;
  durationDisabled?: boolean;
  endMillis: number;
  endDisabled?: boolean;
  onDurationSubmit?: DurationInputHandler;
  onEndTimeSubmit?: DurationInputHandler;
  onPickPreset?: (preset: SocialPresetOption) => void;
  onStartTimeSubmit?: DurationInputHandler;
  startDisabled?: boolean;
  startMillis: number;
  minMillis?: number;
  maxMillis?: number;
  minDurationMillis?: number;
  maxDurationMillis?: number;
  onClearSelectedPreset: () => void;
  onDurationChange?: (durationMillis: number) => void;
  onStartPreview: (region: IRegion) => void;
  onEndPreview: (region: IRegion) => void;
  selectedPreset?: DestinationPreset;
}

export default class ClippingForm extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    disabled: false,
    durationDisabled: false,
    endDisabled: false,
    maxMillis: Infinity,
    minDurationMillis: spareminConfig.minAudioDurationMillis,
    minMillis: 0,
    onDurationSubmit: _.noop,
    onEndTimeSubmit: _.noop,
    onStartTimeSubmit: _.noop,
    startDisabled: false,
    startMillis: 0,
  };

  public shouldComponentUpdate(nextProps: Readonly<IProps>) {
    return !_.isEqual(nextProps, this.props);
  }

  private handleBlur = (type: InputType) => (millis: number) => {
    const { onStartTimeSubmit, onEndTimeSubmit, onDurationSubmit } = this.props;

    switch (type) {
      case 'start':
        onStartTimeSubmit(millis);
        break;

      case 'end':
        onEndTimeSubmit(millis);
        break;

      case 'duration':
        onDurationSubmit(millis);
        break;
    }
  };

  private handleStartPreview = () => {
    const { onStartPreview, startMillis, endMillis } = this.props;
    onStartPreview({
      startMillis,
      endMillis: Math.min(startMillis + PREVIEW_LENGTH_MS, endMillis),
    });
  };

  private handleEndPreview = () => {
    const { onEndPreview, startMillis, endMillis } = this.props;
    onEndPreview({
      startMillis: Math.max(endMillis - PREVIEW_LENGTH_MS, startMillis),
      endMillis,
    });
  };

  public render() {
    const {
      className,
      containerMode,
      disabled,
      maxMillis,
      startDisabled,
      endDisabled,
      durationDisabled,
      startMillis,
      endMillis,
      minDurationMillis,
      maxDurationMillis,
      onClearSelectedPreset,
      onDurationChange,
      onPickPreset,
      selectedPreset,
    } = this.props;

    const hasInputError =
      !!durationDisabled || !!startDisabled || !!endDisabled;

    const presetBlocked = isPresetBlocked(selectedPreset);

    return (
      <form className={cn(block('form'), className)}>
        <div className={block('time-range')}>
          <ClipperDurationField
            label="Clip start"
            name="startTime"
            disabled={disabled || startDisabled}
            value={round(startMillis)}
            onBlur={this.handleBlur('start')}
            mask="0:00:00"
            startAdornment={
              <ClipStartTimeInputAdornment onClick={this.handleStartPreview} />
            }
          />
          <ClipperDurationField
            disabled={disabled || endDisabled}
            label="Clip end"
            name="endTime"
            value={round(endMillis)}
            onBlur={this.handleBlur('end')}
            mask="0:00:00"
            startAdornment={
              <ClipEndTimeInputAdornment onClick={this.handleEndPreview} />
            }
          />
          <SocialPresetPopover
            containerId="audio-clipper-inner-wrapper"
            containerMode={containerMode}
            onClose={onClearSelectedPreset}
            preset={selectedPreset}
          >
            <ClipperDurationField
              disabled={disabled || durationDisabled}
              label="Duration"
              name="duration"
              maxMillis={maxDurationMillis}
              value={round(endMillis - startMillis)}
              minMillis={minDurationMillis}
              onBlur={this.handleBlur('duration')}
              mask="0:00:00"
              startAdornment={
                <ClipDurationInputAdornment
                  disabled={disabled || hasInputError}
                  maxDurationMillis={maxDurationMillis}
                  maxMillis={maxMillis}
                  onChange={onDurationChange}
                  onPickPreset={onPickPreset}
                  presetBlocked={presetBlocked}
                />
              }
            />
          </SocialPresetPopover>
        </div>
      </form>
    );
  }
}
