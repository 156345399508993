import { useCallback, useEffect, useRef } from 'react';
import WaveSurferJs from 'wavesurfer.js';

import { range } from 'utils/numbers';
import { WaveSurferProps } from './types';

const MAX_PX_PER_SEC = 50;

export default function useZoom(zoom: WaveSurferProps['zoom']) {
  const zoomRef = useRef(zoom);
  const wavesurfer = useRef<WaveSurferJs>();

  const setZoom = useCallback((ws: WaveSurferJs = wavesurfer.current) => {
    if (ws && ws.getDuration() > 0) {
      const width = ws.drawer.getWidth();
      const initialZoomLevel = width / ws.getDuration() / ws.params.pixelRatio;
      const pxPerSec = range(
        1,
        100,
        initialZoomLevel,
        MAX_PX_PER_SEC,
        zoomRef.current,
      );
      if (isFinite(pxPerSec)) {
        ws.zoom(pxPerSec);
      }
    }
  }, []);

  useEffect(() => {
    zoomRef.current = zoom;
    const frame = requestAnimationFrame(() => {
      setZoom();
    });
    return () => {
      cancelAnimationFrame(frame);
    };
  }, [setZoom, zoom]);

  return useCallback(
    (ws: WaveSurfer) => {
      wavesurfer.current = ws;
      if (ws) {
        if (ws.isReady) {
          setZoom(ws);
        } else {
          const fn = () => setZoom(ws);
          ws.on('ready', fn);
          return () => ws.un('ready', fn);
        }
      }
      return undefined;
    },
    [setZoom],
  );
}
