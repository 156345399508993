import {
  GoogleDriveLogo,
  ShareIcon,
  Spacer,
  ThemeProvider,
  YouTube,
  ZoomLogo,
} from '@sparemin/blockhead';
import React from 'react';
import { MediaImportOption } from './types';
import UploadButton from './UploadButton';
import { block } from './utils';

export interface MediaImporterOptionsProps {
  onClickUploadButton: () => void;
  onClickMediaImportOption?: (mediaImportOption: MediaImportOption) => void;
}

const MediaImporterOptions: React.FC<MediaImporterOptionsProps> = ({
  onClickUploadButton,
  onClickMediaImportOption,
}) => {
  const createHandleMediaImportOptionClick = (
    mediaImportOption: MediaImportOption,
  ) => () => {
    onClickMediaImportOption?.(mediaImportOption);
  };

  return (
    <ThemeProvider theme="dark">
      <Spacer
        orientation="horizontal"
        space="16px"
        align="center"
        justify="center"
        className={block('options-container')}
      >
        <UploadButton
          aria-label="Upload files from your computer"
          label="Your computer"
          logo={
            <div className={block('icon-container')}>
              <ShareIcon className={block('share-icon')} />
            </div>
          }
          onPress={onClickUploadButton}
        />

        <UploadButton
          aria-label="Upload files from Google Drive"
          label="Google Drive"
          logo={<GoogleDriveLogo className={block('integrator-icon')} />}
          onPress={createHandleMediaImportOptionClick('googleDrive')}
        />

        <UploadButton
          aria-label="Upload files from Zoom"
          label="Zoom"
          logo={<ZoomLogo className={block('integrator-icon')} />}
          onPress={createHandleMediaImportOptionClick('zoom')}
        />

        <UploadButton
          aria-label="Upload files from YouTube"
          label="Youtube"
          logo={
            <div className={block('icon-container')}>
              <YouTube height="22px" />
            </div>
          }
          onPress={createHandleMediaImportOptionClick('youtube')}
        />
      </Spacer>
    </ThemeProvider>
  );
};

export default MediaImporterOptions;
