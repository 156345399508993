import * as React from 'react';

import { block } from './utils';

interface IProps {
  primaryMessage: string;
  secondaryMessage?: string;
}

const ConfirmationModalMessage: React.SFC<IProps> = ({
  primaryMessage,
  secondaryMessage,
}) => (
  <div className={block('message')}>
    <div className={block('primary-text')}>{primaryMessage}</div>
    {secondaryMessage && (
      <div className={block('secondary-text')}>{secondaryMessage}</div>
    )}
  </div>
);

export default ConfirmationModalMessage;
export { IProps as ConfirmationModalMessageProps };
