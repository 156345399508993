import { useCallback, useEffect, useState } from 'react';
import useQuery from 'hooks/useQuery';

import { HeadlinerUsage } from 'types';
import { CreateProfileFormValue } from '../types';
import useCreateProfileFormDispatch, {
  UseCreateProfileFormDispatchConfig,
} from './useCreateProfileFormDispatch';

interface UseProfileFormConfig extends UseCreateProfileFormDispatchConfig {}

type ProfileConsumer<R = any> = (value: CreateProfileFormValue) => R;

export default function useProfileForm(config?: UseProfileFormConfig) {
  const {
    error: dispatchError,
    clearError: clearDispatchError,
    isSubmitting,
    onError,
    onSubmit,
  } = useCreateProfileFormDispatch(config);
  const [podcast, setPodcast] = useState('');
  const [userRole, setUserRole] = useState<HeadlinerUsage>('' as any);
  const [emailsEnabled, setEmailsEnabled] = useState(false);
  const [error, setError] = useState<string>(undefined);
  const { utm_medium: utmMedium, utm_source: utmSource } = useQuery();
  const referrer = utmMedium ? `${utmMedium}(${utmSource})` : undefined;

  const handleError = useCallback(
    message => {
      setError(message);
      onError(message);
    },
    [onError],
  );

  const validate = useCallback(() => {
    if (!podcast) {
      handleError('Please tell us if you plan to use Headliner for a Podcast');
      return false;
    }

    if (!userRole) {
      handleError('Please tell us what you do');
      return false;
    }

    return true;
  }, [podcast, userRole, handleError]);

  useEffect(() => {
    if (dispatchError) {
      handleError(dispatchError);
    }
  }, [handleError, dispatchError]);

  const withValidation: <R>(
    consumer: ProfileConsumer<R>,
  ) => R | undefined = useCallback(
    consumer => {
      if (validate()) {
        return consumer({
          emailsEnabled,
          podcast,
          userRole,
          referrer,
        });
      }
      return undefined;
    },
    [validate, emailsEnabled, podcast, userRole, referrer],
  );

  const handleSubmit = useCallback(() => withValidation(onSubmit), [
    withValidation,
    onSubmit,
  ]);

  const clearError = useCallback(() => {
    setError(undefined);
    clearDispatchError();
  }, [clearDispatchError]);

  return {
    clearError,
    error,
    isSubmitting,
    emailsEnabled: {
      onChange: setEmailsEnabled,
      value: emailsEnabled,
    },
    onSubmit: handleSubmit,
    podcast: {
      onChange: setPodcast,
      value: podcast,
    },
    userRole: {
      onChange: setUserRole,
      value: userRole,
    },
  };
}
