import {
  Response,
  SuperAgent,
  SuperAgentRequest,
  SuperAgentStatic,
} from 'superagent';
import { createRequest } from '../utils';

import * as types from './types';

function createConfiguration(
  args: types.CreateConfigurationArgs,
  request: SuperAgentStatic,
): Promise<types.CreateConfigurationResult> {
  const [
    expectedDurationMilli,
    slideshowInfo,
    textOverlayInfo,
    audioInfo,
    videoClips,
    versionInfo,
    recordingId,
    dimensions,
    soundwave,
    watermark,
    captions,
    creatorUserId,
    layerOrder,
    mainMediaContainer,
    progress,
    timer,
    edgeVideos,
    hideAvatars = true,
    bigPlay = false,
    theme = 'light',
  ] = args;

  return request
    .post('/api/v1/embed-configuration')
    .send({
      audioInfo,
      bigPlay,
      captions,
      creatorUserId,
      dimensions,
      edgeVideos,
      expectedDurationMilli,
      hideAvatars,
      layerOrder,
      mainMediaContainer,
      progress,
      recordingId,
      slideshowInfo,
      soundwave,
      textOverlayInfo,
      theme,
      timer,
      versionInfo,
      videoClips,
      watermark,
      templateId: 6,
    })
    .then((res: Response) => ({
      configuration: res.body,
    }));
}

function getConfiguration(
  args: types.GetConfigurationArgs,
  request: SuperAgent<SuperAgentRequest>,
): Promise<types.GetConfigurationResult> {
  const wid = args;

  return request.get(`/api/v1/embed-configuration/${wid}`).then(res => ({
    configuration: res.body,
  }));
}

export const handle: types.IHandle = (
  method: any,
  args: any,
  token?: string,
): any => {
  const request = createRequest({
    token,
    baseUrl: spareminConfig.services.embedConfiguration,
    omitBearerPrefix: true,
  });

  switch (method) {
    case types.ServiceMethod.CREATE_CONFIGURATION:
      return createConfiguration(
        args as types.CreateConfigurationArgs,
        request,
      );

    case types.ServiceMethod.GET_CONFIGURATION:
      return getConfiguration(args as types.GetConfigurationArgs, request);

    default:
      throw new Error(`${types.ACTION_KEY} cannot handle method ${method}`);
  }
};
