import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined } from 'underscore';

import useOnMount from 'hooks/useOnMount';
import { onClickAdjustPlan } from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal';
import { loadExternalPortalLink } from 'redux/modules/pricing/actions';
import { cardSelector } from 'redux/modules/pricing/selectors';
import { Dispatch } from 'redux/types';

export default function() {
  const dispatch = useDispatch<Dispatch>();
  const card = useSelector(cardSelector);

  const cardVendor = card?.brand;
  const cardDigits = card?.last4Digits;

  const hasCardInfo = !isUndefined(cardVendor) && !isUndefined(cardDigits);

  useOnMount(async () => {
    if (!card) {
      try {
        await dispatch(loadExternalPortalLink());
      } catch {
        /*
         * swallow error.  don't actually need card info.  everything still works
         * without it
         */
      }
    }
  });

  const onUpdateCard = useCallback(() => {
    dispatch(
      pushModal({
        name: 'UpdateCreditCard',
        params: {
          successNotificationTexts: {
            dismissAfterSec: 10,
            message: 'Your payment details were updated',
            title: "You're all set",
          },
        },
      }),
    );
  }, [dispatch]);

  const onUpdatePlan = useCallback(() => {
    dispatch(onClickAdjustPlan());
  }, [dispatch]);

  return {
    card,
    hasCardInfo,
    onUpdateCard,
    onUpdatePlan,
  };
}
