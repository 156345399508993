import classNames from 'classnames';
import { Record, RecordOf, Stack } from 'immutable';
import * as React from 'react';
import _ from 'underscore';

import Modal from 'components/Modal';
import * as types from './types';
import { isFooterButtons, isStepList } from './utils';

export interface IProps {
  baseClassName?: string;
  defaultStep: string;
  footerButtonsDisabled?: boolean | types.FooterButtonsDisabled;
  onHide?: (activeStep: string) => void;
  onExited?: (activeStep: string, history: types.IStepHistory) => void;
  onStepChange?: (toStep: string, fromStep: string) => void;
  show?: boolean;
  steps: types.StepList | types.StepListFactory;
  title: string;
}

interface IState {
  data: RecordOf<types.IDataState>;
}

const dataFactory = Record<types.IDataState>({ stepHistory: undefined });

export default class SteppedModal extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    footerButtonsDisabled: false,
    onExited: _.noop,
    onHide: _.noop,
    onStepChange: _.noop,
    show: false,
    steps: [],
  };

  constructor(props: IProps) {
    super(props);

    const { defaultStep } = props;

    this.state = {
      data: dataFactory({
        stepHistory: Stack([defaultStep]),
      }),
    };
  }

  public componentDidUpdate(__, prevState: Readonly<IState>) {
    const { onStepChange } = this.props;
    const { data: prevData } = prevState;
    const { data } = this.state;

    const prevStep = prevData.stepHistory.peek();
    const step = data.stepHistory.peek();

    if (step !== prevStep) {
      onStepChange(step, prevStep);
    }
  }

  private handleModalExited = () => {
    const { onExited } = this.props;
    const { data } = this.state;

    onExited(data.stepHistory.peek(), this.stepHistory);
  };

  private handleModalHide = () => {
    const { onHide } = this.props;
    const { data } = this.state;

    onHide(data.stepHistory.peek());
  };

  private pushStep = (id: string) =>
    this.setState(({ data }) => ({
      data: data.update('stepHistory', history =>
        history.peek() === id ? history : history.push(id),
      ),
    }));

  private popStep = () =>
    this.setState(({ data }) => ({
      data: data.update('stepHistory', history => history.pop()),
    }));

  private replaceStep = (id: string) =>
    this.setState(({ data }) => ({
      data: data.update('stepHistory', history =>
        history.withMutations(h => {
          h.pop();

          if (h.peek() !== id) {
            h.push(id);
          }

          return h;
        }),
      ),
    }));

  private handleBackClick = () => this.popStep();

  private getSteps() {
    const { steps } = this.props;

    if (isStepList(steps)) {
      return steps;
    }

    return steps(this.stepHistory);
  }

  private getFooterButtonProps(activeStep: types.IStep) {
    const { footerButtonsDisabled } = this.props;

    return {
      back: {
        disabled: _.isFunction(footerButtonsDisabled)
          ? footerButtonsDisabled(types.FooterButtonType.BACK)
          : footerButtonsDisabled,
        onClick: this.handleBackClick,
      },
      cancel: {
        disabled: _.isFunction(footerButtonsDisabled)
          ? footerButtonsDisabled(types.FooterButtonType.CANCEL)
          : footerButtonsDisabled,
        onClick: this.handleModalHide,
      },
      submit: {
        disabled: _.isFunction(footerButtonsDisabled)
          ? footerButtonsDisabled(types.FooterButtonType.SUBMIT)
          : footerButtonsDisabled,
        onClick: activeStep.onSubmit,
      },
    };
  }

  public get stepHistory() {
    const { data } = this.state;

    return {
      active: data.stepHistory.peek(),
      pop: this.popStep,
      push: this.pushStep,
      replace: this.replaceStep,
    };
  }

  private renderFooter(activeStep: types.IStep) {
    const { data } = this.state;
    const { renderFooterButtons, fluidFooterButtons } = activeStep;

    const wrap = (bs: JSX.Element[]) => (
      <Modal.FooterButtons fluid={fluidFooterButtons}>{bs}</Modal.FooterButtons>
    );

    const buttonProps = this.getFooterButtonProps(activeStep);

    /*
     * if renderFooterButtons is undefined, render the standard set of buttons
     * cancel button always exists
     * submit button exists if onSubmit is defined
     * back button exists only if there's a step in history to go back to
     */
    if (typeof renderFooterButtons === 'undefined') {
      const buttons = [];

      if (data.stepHistory.size > 1) {
        buttons.push(
          <Modal.FooterButton {...buttonProps.back} key="back">
            Back
          </Modal.FooterButton>,
        );
      }

      buttons.push(
        <Modal.FooterButton {...buttonProps.cancel} key="cancel">
          Cancel
        </Modal.FooterButton>,
      );

      if (typeof activeStep.onSubmit !== 'undefined') {
        buttons.push(
          <Modal.FooterButton
            {...buttonProps.submit}
            key="submit"
            theme="submit"
          >
            {activeStep.submitButtonLabel || 'Submit'}
          </Modal.FooterButton>,
        );
      }

      return wrap(buttons);
    }

    if (isFooterButtons(renderFooterButtons)) {
      return wrap(renderFooterButtons);
    }

    return wrap(renderFooterButtons(buttonProps));
  }

  public render() {
    const { baseClassName, show, title } = this.props;
    const { data } = this.state;

    const steps = this.getSteps();
    const activeStepId = data.stepHistory.peek();
    const activeStep = steps.find(step => step.id === activeStepId);

    const modalClassName = classNames(
      baseClassName,
      `${baseClassName}--default`,
      activeStep.modalClassName,
    );

    return (
      <Modal
        backdrop="static"
        className={modalClassName}
        onExited={this.handleModalExited}
        onHide={this.handleModalHide}
        show={show}
        title={activeStep.title || title}
      >
        {activeStep.replaceBody ? (
          activeStep.component
        ) : (
          <Modal.Body className={`${baseClassName}__body`}>
            {activeStep.component}
          </Modal.Body>
        )}
        {!activeStep?.hideFooter && (
          <Modal.Footer className={activeStep?.footerClassName}>
            {this.renderFooter(activeStep)}
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

export { IProps as SteppedModalProps };
