import { Record } from 'immutable';

import { Type } from './action-types';
import { PostTaskAction, PostTaskState, State } from './types';

export const stateFactory = Record<State>({
  hidden: undefined,
});

const defaultState = stateFactory();

export default function(
  state: PostTaskState = defaultState,
  action: PostTaskAction,
) {
  switch (action.type) {
    case Type.POST_TASK_HIDE: {
      return state.set('hidden', true);
    }

    case Type.POST_TASK_CLEAR:
      return defaultState;

    default:
      return state;
  }
}
