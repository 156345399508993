import cn from 'classnames';
import * as React from 'react';

import { PodcastSearchIntegrationsContextProvider } from 'blocks/PodcastSearch/components/PodcastSearchIntegrationsContext';

import PodcastSearchBody from '../../components/PodcastSearchBody';
import { PodcastSearchConfigContextProvider } from '../../components/PodcastSearchConfigContext';
import { PodcastSearchIntegration } from '../../types';
import { block } from '../../utils';
import PodcastSearchBar, { PodcastSearchBarProps } from '../PodcastSearchBar';

type PickedSearchBarProps = Pick<
  PodcastSearchBarProps,
  'defaultValue' | 'onDescriptImport'
>;

export interface PodcastSearchProps extends PickedSearchBarProps {
  children?: React.ReactNode;
  className?: string;
  hasResults?: boolean;
  integrations?: PodcastSearchIntegration[];
  isLoading?: boolean;
  light?: boolean;
  usePlanEpisodeMaxDuration?: boolean;
}

const PodcastSearch = React.forwardRef<any, PodcastSearchProps>(
  (
    {
      className,
      defaultValue,
      hasResults,
      integrations,
      isLoading,
      light,
      onDescriptImport,
      usePlanEpisodeMaxDuration,
    },
    ref,
  ) => {
    return (
      <div className={cn(block({ light }), className)}>
        <PodcastSearchConfigContextProvider
          usePlanEpisodeMaxDuration={usePlanEpisodeMaxDuration}
        >
          <PodcastSearchIntegrationsContextProvider integrations={integrations}>
            <PodcastSearchBar
              light={light}
              defaultValue={defaultValue}
              disabled={isLoading && !hasResults}
              onDescriptImport={onDescriptImport}
              ref={ref}
            />
            <PodcastSearchBody light={light} />
          </PodcastSearchIntegrationsContextProvider>
        </PodcastSearchConfigContextProvider>
      </div>
    );
  },
);

export default PodcastSearch;
