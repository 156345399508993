import { useSelector } from 'react-redux';
import { authInProgressSelector } from 'redux/modules/auth';
import { queuerUserProfileSelector } from 'redux/modules/download';
import useVerificationDispatch from './useVerificationDispatch';

export default () => {
  const { onResendEmail, onChangeEmail } = useVerificationDispatch();
  const profile = useSelector(queuerUserProfileSelector);
  const email = profile?.email ?? profile?.maskedEmail;
  const loading = useSelector(authInProgressSelector);

  return {
    email,
    loading,
    onChangeEmail,
    onResendEmail: () => {
      onResendEmail(email);
    },
  };
};
