import Promise from 'bluebird';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

function promise() {
  Promise.config({
    cancellation: true,
    warnings: {
      /*
       * without this option, bluebird prints a lot of warnings for promises which don't return
       * anything.  this is a little annoying since a lot of times we call .then() on a promise
       * to execute some function but we don't necessarily need the return value, and so we let
       * the promise resolved to undefined, which Bluebird picks up as a "runaway" promise.
       * Bluebird forces you to return null to suppress this warning, but since we do it a lot
       * (and the same warning doesn't exist for native promises), I'm suppressing it
       */
      wForgottenReturn: false,
    },
  });
  window.Promise = Promise;
}

function values() {
  if (!Object.values) {
    Object.values = obj => Object.keys(obj).map(key => obj[key]);
  }
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/includes
/* eslint-disable no-bitwise, no-extend-native */
function includes() {
  if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
      value(searchElement, fromIndex) {
        // 1. Let O be ? ToObject(this value).
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }

        const o = Object(this);

        // 2. Let len be ? ToLength(? Get(O, "length")).
        const len = o.length >>> 0;

        // 3. If len is 0, return false.
        if (len === 0) {
          return false;
        }

        // 4. Let n be ? ToInteger(fromIndex).
        //    (If fromIndex is undefined, this step produces the value 0.)
        const n = fromIndex | 0;

        // 5. If n ≥ 0, then
        //  a. Let k be n.
        // 6. Else n < 0,
        //  a. Let k be len + n.
        //  b. If k < 0, let k be 0.
        let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

        function sameValueZero(x, y) {
          return (
            x === y ||
            (typeof x === 'number' &&
              typeof y === 'number' &&
              isNaN(x) &&
              isNaN(y))
          );
        }

        // 7. Repeat, while k < len
        while (k < len) {
          // a. Let elementK be the result of ? Get(O, ! ToString(k)).
          // b. If SameValueZero(searchElement, elementK) is true, return true.
          // c. Increase k by 1.
          if (sameValueZero(o[k], searchElement)) {
            return true;
          }
          k += 1;
        }

        // 8. Return false
        return false;
      },
    });
  }
}
/* eslint-enable no-bitwise, no-extend-native */

function install() {
  promise();
  values();
  includes();
}

export default { install };
