import React from 'react';
import { VideoTypeConfig } from 'blocks/AudiogramDestinations/types';
import { videoTypesContentsBlock as block } from 'blocks/AudiogramDestinations/utils';
import Button from 'components/Button';
import { H1 } from 'components/Heading';
import Link from 'components/Link';
import LinkButton from 'components/LinkButton';
import List from 'components/List';
import ScrollBars from 'components/ScrollBars';

export interface AudiogramDestinationVideoTypesListProps {
  title: string;
  icon: React.ReactNode;
  videoTypes: VideoTypeConfig[];
  shouldRedirectToDestination?: boolean;
  onClick: (videoType: VideoTypeConfig) => void;
  onBackClick?: () => void;
}

const AudiogramDestinationVideoTypesList: React.FC<AudiogramDestinationVideoTypesListProps> = ({
  title,
  icon,
  videoTypes,
  shouldRedirectToDestination = true,
  onClick,
  onBackClick,
}) => {
  const VideoTypeComponent = shouldRedirectToDestination ? Link : Button;

  const createClickHandler = config => () => {
    onClick(config);
  };

  return (
    <div className={block()}>
      <div className={block('main-icon')}>{icon}</div>

      <H1 className={block('title')}>{title}</H1>

      <LinkButton
        onClick={onBackClick}
        className={block('choose-another-button')}
        size="md"
        theme="cta"
        uppercase
      >
        ← choose another destination
      </LinkButton>

      <List>
        <ScrollBars autoHeight autoHeightMax={340} hideTracksWhenNotNeeded>
          {videoTypes.map(config => (
            <List.Item
              key={config.id}
              modifiers={['no-padding']}
              className={block('list-item-container')}
            >
              <VideoTypeComponent
                {...(shouldRedirectToDestination && { to: config.destination })}
                className={block('video-type-component')}
                onClick={createClickHandler(config)}
              >
                <List.Item.Contents>
                  <List.Item.Artwork>
                    <div className={block('icon-container')}>{config.icon}</div>
                  </List.Item.Artwork>

                  <List.Item.Text
                    className={block('text')}
                    primary={config.title}
                    secondary={
                      <span className={block('desc')}>
                        {config.description}
                      </span>
                    }
                  />
                </List.Item.Contents>
              </VideoTypeComponent>
            </List.Item>
          ))}
        </ScrollBars>
      </List>
    </div>
  );
};

export default AudiogramDestinationVideoTypesList;
