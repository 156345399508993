import * as React from 'react';
import { useSelector } from 'react-redux';
import { isUpdatingSubscriptionSelector } from 'redux/modules/pricing/selectors';

import { Omit } from 'types';
import { SubscriptionPeriod } from '../../types';
import BillingCycleModal, {
  BillingCycleModalContent,
} from '../BillingCycleModal';
import ChangeBillingCycleModalContent from './ChangeBillingCycleModalContent';
import { block } from './utils';

type PickedBillingCycleModalProps = Omit<
  React.ComponentProps<typeof BillingCycleModalContent>,
  | 'children'
  | 'options'
  | 'subscriptionPeriod'
  | 'onSubscriptionPeriodChange'
  | 'onSubmit'
>;

export interface ChangeBillingCycleModalProps
  extends PickedBillingCycleModalProps {
  onSubmit?: (period: SubscriptionPeriod) => void;
}

const ChangeBillingCycleModal: React.SFC<ChangeBillingCycleModalProps> = ({
  onSubmit,
  ...props
}) => {
  const disabled = useSelector(isUpdatingSubscriptionSelector);
  return (
    <BillingCycleModal
      name="ChangeBillingCycle"
      className={block()}
      title="adjust my billing cycle"
      disabled={disabled}
      {...props}
    >
      <ChangeBillingCycleModalContent disabled={disabled} />
    </BillingCycleModal>
  );
};

export default ChangeBillingCycleModal;
