import React from 'react';

import FontAwesome from 'components/FontAwesome';

import OptionTile from '../assets/OptionTile';
import { IntroOutroType } from '../types';
import { introOutroChildViewBlock as block } from '../utils';
import useIntroOutro from './useIntroOutro';

export interface IntroOutroDeleteProps {
  className?: string;
  type: IntroOutroType;
}

const IntroOutroDelete: React.FC<IntroOutroDeleteProps> = props => {
  const { className, type } = props;
  const { onDeleteIntroOutro } = useIntroOutro();

  const handleClick = React.useCallback(() => {
    onDeleteIntroOutro(type);
  }, [onDeleteIntroOutro, type]);

  return (
    <OptionTile
      className={className}
      icon={<FontAwesome className={block('delete-icon')} icon="trash-alt" />}
      label="delete"
      onClick={handleClick}
    />
  );
};

export default IntroOutroDelete;
