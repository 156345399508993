import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextOverlay } from 'blocks/TextOverlayModal';
import { TextOverlayV2 } from 'blocks/TextOverlayModal/v2';
import {
  ActiveModal,
  useTextOverlaySwitch,
} from 'blocks/TextOverlaySwitchModal';
import useEditAssetModal from 'containers/ConnectedModal/useEditAssetModal';
import { selectTrackElement } from 'redux/modules/embed/actions';
import {
  selectedOverlaySelector,
  selectedTrackElementSelector,
} from 'redux/modules/embed/selectors';
import { pushModal } from 'redux/modules/modal';

interface UseEditTextModalSwitcher {
  activeModal: ActiveModal;
  onExited: () => void;
  onHide: () => void;
  onToggleLegacyMode: () => void;
  selectedTrackId?: string;
  transitionOverlay: TextOverlay | TextOverlayV2;
}

const useEditTextModalSwitcher = (): UseEditTextModalSwitcher => {
  const dispatch = useDispatch();

  const selectedOverlay = useSelector(selectedOverlaySelector);
  const selectedTrackElement = useSelector(selectedTrackElementSelector);

  const selectedTrackId = selectedTrackElement?.get('trackId');
  const selectedOverlayId = selectedOverlay?.get('id');

  const { params, onHide, onExited, show } = useEditAssetModal('EditTextModal');

  const startingModalType = params?.startingModalType;

  const handleOpen = React.useCallback(() => {
    dispatch(selectTrackElement(selectedOverlayId, selectedTrackId));
    dispatch(pushModal({ name: 'EditTextModal' }));
  }, [dispatch, selectedOverlayId, selectedTrackId]);

  const {
    activeModal,
    onToggleLegacyMode,
    transitionOverlay,
  } = useTextOverlaySwitch({
    onHide,
    onOpen: handleOpen,
    show,
    startingModalType,
  });

  return {
    activeModal,
    onExited,
    onHide,
    onToggleLegacyMode,
    selectedTrackId,
    transitionOverlay,
  };
};

export default useEditTextModalSwitcher;
