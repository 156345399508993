import cn from 'classnames';
import * as React from 'react';
import { Col } from 'react-bootstrap';
import { omit, pick } from 'underscore';

import InputControl, { InputControlProps } from './InputControl';
import InputLabel from './InputLabel';
import { IColSizes } from './types';
import { block } from './utils';

interface IHorizontal {
  control?: IColSizes;
  label?: IColSizes;
}

interface IProps extends Pick<InputControlProps, 'disabled'> {
  children?: React.ReactNode;
  className?: string;
  /**
   * class to apply to the input element
   */
  controlClassName?: string;
  /**
   * an object describing how to layout the form field horizontally.  there are two keys, "control"
   * and "label", each describing the numbers of columns (out of 12 - uses bootstrap) that each of
   * the two elements should take up at various breakpoints.
   */
  horizontal?: IHorizontal;
  label?: React.ReactNode;
  labelClassName?: string;
  error?: boolean;
  helperText?: string;
  density?: 'comfortable';
  light?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

type Props<T = {}> = IProps & T;

const componentPropKeys = [
  'disabled',
  'children',
  'className',
  'horizontal',
  'label',
];

/**
 * A component that contains both the input and the input's label.  The label is rendered right
 * above the form field, unless the `horizontal` prop is passed, in which case the label is
 * rendered to the left of the input
 */

function FormField<T = {}>(props: Props<T>) {
  const {
    controlClassName,
    labelClassName,
    error,
    helperText,
    density,
    light,
    startAdornment,
    endAdornment,
    ...rest
  } = props;
  const { children, className, horizontal, label, disabled } = pick(
    props,
    ...componentPropKeys,
  );

  const controlProps = omit(rest, ...componentPropKeys);

  const wrapLabelWithCol = (element: JSX.Element) => {
    if (!horizontal) return element;
    return <Col {...horizontal.label}>{element}</Col>;
  };

  const wrapControlWithCol = (element: JSX.Element) => {
    const wrapperClassName = block('input-wrapper');
    if (!horizontal) return <div className={wrapperClassName}>{element}</div>;
    return (
      <Col className={wrapperClassName} {...horizontal.control}>
        {element}
      </Col>
    );
  };

  return (
    <div
      className={cn(
        block('field', {
          disabled,
          horizontal: !!horizontal,
          [density]: !!density,
          light,
          invalid: error,
          'adornment-start': !!startAdornment,
          'adornment-end': !!endAdornment,
        }),
        className,
      )}
    >
      {label &&
        wrapLabelWithCol(
          <InputLabel className={labelClassName} disabled={disabled}>
            {label}
          </InputLabel>,
        )}
      {React.Children.count(children) > 0 &&
        wrapControlWithCol(
          <>
            <InputControl<T>
              {...controlProps}
              className={controlClassName}
              disabled={disabled}
            >
              {children}
            </InputControl>
            {helperText && <p className={block('helper-text')}>{helperText}</p>}
            {startAdornment}
            {endAdornment}
          </>,
        )}
    </div>
  );
}

export default FormField;
export { Props as FormFieldProps };
