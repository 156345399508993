import { Reducer, useCallback, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { isString, noop } from 'underscore';

import { getExternalMedia } from 'redux/middleware/api/audio-proxy-service/actions';
import { Dispatch } from 'redux/types';
import { downscaleImage, svgToPng } from 'utils/image';
import useObjectUrl from './useObjectUrl';
import usePrevious from './usePrevious';
import usePreviousRef from './usePreviousRef';

export type ImageSource = Blob | string;

export type OnError = (error: Error) => void;

export type ImageProcessorStatus = 'idle' | 'processing' | 'ready' | 'error';

export interface OnStatusChange {
  (state: State): void;
}

export interface UseImageProcessorResult {
  objectUrl?: string;
  originalFile?: Blob;
  status: ImageProcessorStatus;
}

type ActionType =
  | 'processing-request'
  | 'processing-success'
  | 'processing-failure'
  | 'processing-abort';

interface BaseAction<T extends ActionType, P = any> {
  payload?: P;
  type: T;
}

type Action =
  | BaseAction<'processing-request', ImageSource>
  | BaseAction<'processing-success', Blob>
  | BaseAction<'processing-failure'>
  | BaseAction<'processing-abort'>;

interface State {
  originalFile: Blob;
  source: ImageSource;
  status: ImageProcessorStatus;
}

const INITIAL_STATE: State = {
  originalFile: undefined,
  source: undefined,
  status: 'idle',
};

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'processing-request':
      return {
        ...INITIAL_STATE,
        source: action.payload,
        status: 'processing',
      };

    case 'processing-success':
      return {
        ...state,
        originalFile: action.payload,
        status: 'ready',
      };

    case 'processing-failure':
      return { ...state, status: 'error' };

    case 'processing-abort':
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default function useImageProcessor(
  src: ImageSource,
  targetAspectRatio: number,
  onError: OnError = noop,
  onStatusChange: OnStatusChange = noop,
): UseImageProcessorResult {
  const reduxDispatch = useDispatch<Dispatch>();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const prevState = usePrevious(state);
  const objectUrl = useObjectUrl(state.originalFile);

  const downloadFile = useCallback(
    async (url: string) => {
      const result = await reduxDispatch(getExternalMedia(url));
      return result.response;
    },
    [reduxDispatch],
  );

  // refs for the useEffect hook that processes the image.  we don't want to
  // reprocess the image if these function references change
  const onErrorRef = usePreviousRef(onError);
  const onStatusChangeRef = usePreviousRef(onStatusChange);

  useEffect(() => {
    if (state.status !== prevState.status) {
      onStatusChangeRef.current?.(state);
    }
  }, [onStatusChangeRef, prevState.status, state]);

  // effect should run only when src or targetAspectRatio changes.  downloadFile
  // should never change and onErrorRef should never change.
  useEffect(() => {
    async function processImage() {
      if (!src) {
        return;
      }

      try {
        dispatch({ type: 'processing-request', payload: src });
        const file = isString(src) ? await downloadFile(src) : src;
        const resizedImage = await downscaleImage(file, targetAspectRatio);
        const convertedFile = await svgToPng(resizedImage, targetAspectRatio);
        dispatch({ type: 'processing-success', payload: convertedFile });
      } catch {
        onErrorRef.current(new Error('Error loading image'));
        dispatch({ type: 'processing-failure' });
      }
    }

    const fetchPromise: any = processImage();

    return () => {
      if (fetchPromise && fetchPromise.cancel) {
        fetchPromise.cancel();
        dispatch({ type: 'processing-abort' });
      }
    };
  }, [downloadFile, onErrorRef, src, targetAspectRatio]);

  return {
    status: state.status,
    objectUrl,
    originalFile: state.originalFile,
  };
}
