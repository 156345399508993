import { BlockheadProvider, StyleSheetManager } from '@sparemin/blockhead';
import { ConnectedRouter } from 'connected-react-router/immutable';
import * as React from 'react';
import * as ReactGA from 'react-ga';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider, ProviderProps } from 'react-redux';

import ErrorBoundary from 'containers/App/ErrorBoundary';
import RehydrationGuard from 'containers/RehydrationGuard';
import history from 'redux/store/history';
import 'utils/react-dom-hack';
import Routes from './Routes';
import StripeProvider from './StripeProvider';

ReactGA.initialize(spareminConfig.googleAnalytics.trackingId, {
  gaOptions: {
    allowLinker: true,
  },
});
(ReactGA.ga as any)('require', 'linker');
(ReactGA.ga as any)('linker:autoLink', ['headliner.app']);

const queryClient = new QueryClient();

type RootProps = Pick<ProviderProps, 'store'>;

const Root: React.SFC<RootProps> = ({ store }) => (
  <Provider store={store}>
    <ErrorBoundary>
      <StripeProvider>
        <RehydrationGuard>
          <ConnectedRouter history={history}>
            <BlockheadProvider theme="light">
              <StyleSheetManager target={document.getElementById('sc-inject')}>
                <QueryClientProvider client={queryClient}>
                  <Routes />
                </QueryClientProvider>
              </StyleSheetManager>
            </BlockheadProvider>
          </ConnectedRouter>
        </RehydrationGuard>
      </StripeProvider>
    </ErrorBoundary>
  </Provider>
);

export default Root;
