import classNames from 'classnames';
import React from 'react';

import { ProFeatureTooltipContent } from 'blocks/Pricing';
import Tooltip from 'components/Tooltip';
import ProIndicator from 'containers/ProIndicator';

import { RefType } from 'types';
import { optionsBlock as block } from '../utils';
import { AccessControlOptionTileProps } from './AccessControlOptionTile';

interface ProGatedTileProps {
  children: (props: {
    proClickHandler: AccessControlOptionTileProps['onClick'];
    proDisabled: boolean;
    tileClassName: string;
  }) => React.ReactElement;
  disabled?: boolean;
  onClickFeature: AccessControlOptionTileProps['onClick'];
  onUpgrade: () => void;
  tileBaseClassName?: string;
}

const ProGatedTile = React.forwardRef(
  (props: ProGatedTileProps, ref: RefType<React.ElementType>) => {
    const {
      children,
      disabled,
      onClickFeature,
      onUpgrade,
      tileBaseClassName,
    } = props;

    return (
      <ProIndicator className={block('tile-pro-badge')}>
        {({ isFree }) => {
          const proDisabled = isFree;
          const tileClassName = classNames(tileBaseClassName, {
            // pro disabled style override should only apply when no disabled for other reasons
            [block('pro-disabled-tile')]: proDisabled && !disabled,
          });
          const proClickHandler = proDisabled ? onUpgrade : onClickFeature;
          return (
            <div ref={ref} className={block('tile-wrapper')}>
              <Tooltip
                animation
                content={ProFeatureTooltipContent}
                id="intro-outro-tile-tooltip"
                placement="top"
                show={proDisabled ? undefined : false}
                preventHideOnHover
              >
                {children({ proDisabled, proClickHandler, tileClassName })}
              </Tooltip>
            </div>
          );
        }}
      </ProIndicator>
    );
  },
);

export default ProGatedTile;
