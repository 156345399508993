import classNames from 'classnames';
import React from 'react';

import { H3 } from 'components/Heading';

import AddMediaButton from '../AddMediaButton';
import { IntroOutroData } from '../types';
import { introOutroChildViewBlock as block, gridBlock } from '../utils';
import IntroOutroChildViewPreview from './IntroOutroChildViewPreview';
import IntroOutroLockedOverlay from './IntroOutroLockedOverlay';
import useIntroOutro from './useIntroOutro';

interface IntroOutroSelectionTileProps {
  assetData?: IntroOutroData;
  description: string;
  disabled: boolean;
  icon: React.ReactNode;
  id: string;
  locked: boolean;
  onClickAdd: () => void;
  onClickThumbnail: () => void;
  title: string;
  tooltipText: string;
}

const IntroOutroSelectionTile: React.FunctionComponent<IntroOutroSelectionTileProps> = props => {
  const {
    assetData,
    description,
    disabled,
    icon,
    id,
    locked,
    onClickAdd,
    onClickThumbnail,
    title,
    tooltipText,
  } = props;

  const { aspectRatioName } = useIntroOutro();

  const PickerComponent = assetData ? (
    <IntroOutroChildViewPreview
      disabled={disabled}
      id={id}
      loaded={assetData.loaded}
      onClick={onClickThumbnail}
      thumbnailUrl={assetData.thumbnailUrl}
    />
  ) : (
    <AddMediaButton
      className={classNames(
        block('add-tile-button', { [aspectRatioName]: true }),
      )}
      disabled={disabled}
      hoverExpandLocked
      locked={locked}
      onClick={onClickAdd}
      tooltipText={tooltipText}
    />
  );

  return (
    <div className={classNames(block('tile-card'), gridBlock('tile'))}>
      <div className={block('left-container')}>
        {icon}
        <div>
          <H3 className={block('title')}>{title}</H3>
          <p className={block('description')}>{description}</p>
        </div>
      </div>
      <div className={block('right-container', { [aspectRatioName]: true })}>
        <IntroOutroLockedOverlay locked={locked} id={id}>
          {PickerComponent}
        </IntroOutroLockedOverlay>
      </div>
    </div>
  );
};

export default IntroOutroSelectionTile;
