import React from 'react';
import { useDispatch } from 'react-redux';
import { addRecentFont } from 'redux/modules/fonts/actions';

import FontFamilySelector, {
  FontFamilySelectorProps,
} from './FontFamilySelector';
import { Font } from './types';

interface FontFamilySelectorContainerProps extends FontFamilySelectorProps {
  shouldUpdateRecentFonts?: boolean;
}

const FontFamilySelectorContainer: React.FunctionComponent<FontFamilySelectorContainerProps> = props => {
  const { onChange, shouldUpdateRecentFonts } = props;

  const dispatch = useDispatch();

  const handleChangeFont = React.useCallback(
    (nextFont: Font): void => {
      if (shouldUpdateRecentFonts) {
        dispatch(addRecentFont(nextFont.name));
      }

      onChange(nextFont);
    },
    [dispatch, onChange, shouldUpdateRecentFonts],
  );

  return <FontFamilySelector {...props} onChange={handleChangeFont} />;
};

export default FontFamilySelectorContainer;
