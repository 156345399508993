import * as React from 'react';

type OnSelect = (tabKey: string | number) => void;

interface Tab {
  tabKey: string | number;
  title: React.ReactNode;
  content: React.ReactNode;
  titleClassName?: string;
  contentClassName?: string;
  isDisabled?: boolean;
}

export interface TabsProps {
  tabs: Tab[];
  activeTabKey: string | number;
  onTabSelect: OnSelect;
  overrides?: ComponentOverrides;
  className?: string;
  condensed?: boolean;
  padding?: TabsPaddings;
  theme?: TabsThemes;
}

export type TabTitleProps = Pick<Tab, 'tabKey' | 'title' | 'isDisabled'> & {
  className?: string;
  onSelect: OnSelect;
};

export interface TabContentProps {
  active: boolean;
  children: Tab['content'];
  className: string;
  padding: TabsPaddings;
  tabKey: string | number;
  theme: TabsThemes;
}

export enum TabsPaddings {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum TabsThemes {
  DARK = 'dark',
}

export interface TabHeaderProps {
  className: string;
  children: React.ReactNode;
  nTabs: number;
}

export interface TabsContentProps {
  className: string;
  children?: React.ReactNode;
}

interface ComponentOverrides {
  content?: React.ElementType<TabContentProps>;
  header?: React.ElementType<TabHeaderProps>;
  tabsContent?: React.ElementType<TabsContentProps>;
}
