import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import { block } from '../../utils/block';

const WarningLabel: React.SFC = () => (
  <div className={block('warning')}>
    <FontAwesome
      icon="exclamation-triangle"
      className={block('warning-icon')}
    />
    <div className={block('warning-text')}>
      Saving will overwrite the styles of all text boxes on this track.
    </div>
  </div>
);

export default WarningLabel;
