import * as React from 'react';
import { VideoCameraAlt2 } from 'components/icons';
import PaidSubscriptionSuccessMessage from './PaidSubscriptionSuccessMessage';

export interface BasicSuccessMessageProps {}

const BasicSuccessMessage: React.FC<BasicSuccessMessageProps> = () => (
  <PaidSubscriptionSuccessMessage
    icon={<VideoCameraAlt2 height={52} width="100%" />}
    message="Welcome to Headliner Basic!"
  />
);

export default BasicSuccessMessage;
