import * as React from 'react';

import { WaveformProps } from '../../types';

const Rings = React.forwardRef<SVGSVGElement, WaveformProps>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    preserveAspectRatio="none"
    ref={ref}
    {...props}
  >
    <path d="M18.368 16c0 1.308-1.060 2.368-2.368 2.368s-2.368-1.059-2.369-2.367c-0.001-1.308 1.059-2.369 2.367-2.37s2.369 1.058 2.37 2.366l0.079-0c-0.001-1.352-1.098-2.446-2.45-2.445s-2.447 1.097-2.446 2.449c0.001 1.352 1.097 2.447 2.448 2.446s2.447-1.096 2.447-2.448h-0.079z" />
    <path d="M20.537 16c0 2.505-2.031 4.537-4.536 4.537s-4.537-2.030-4.538-4.535c-0.001-2.505 2.028-4.538 4.534-4.539s4.538 2.027 4.541 4.533l0.079-0c-0.003-2.549-2.071-4.614-4.62-4.612s-4.614 2.070-4.613 4.619c0.001 2.549 2.068 4.615 4.618 4.614s4.615-2.067 4.615-4.617h-0.079z" />
    <path d="M28.734 16c0 7.032-5.699 12.732-12.731 12.734s-12.734-5.696-12.737-12.728c-0.004-7.032 5.693-12.735 12.724-12.74s12.736 5.69 12.743 12.721l0.079-0c-0.007-7.075-5.748-12.806-12.823-12.8s-12.807 5.744-12.804 12.82c0.004 7.075 5.741 12.809 12.817 12.807s12.81-5.738 12.81-12.813h-0.079z" />
    <path d="M24.811 16c0 4.865-3.943 8.81-8.809 8.811s-8.811-3.941-8.813-8.807c-0.002-4.865 3.939-8.812 8.804-8.815s8.813 3.937 8.818 8.802l0.079-0c-0.005-4.909-3.988-8.885-8.897-8.881s-8.886 3.986-8.884 8.895c0.002 4.909 3.983 8.887 8.893 8.886s8.888-3.981 8.888-8.89h-0.079z" />
    <path d="M23.822 16c0 4.319-3.501 7.821-7.82 7.822s-7.822-3.499-7.824-7.818c-0.002-4.319 3.497-7.823 7.816-7.826s7.824 3.495 7.828 7.814l0.079-0c-0.004-4.363-3.544-7.897-7.908-7.894s-7.898 3.542-7.896 7.906c0.002 4.363 3.54 7.899 7.904 7.898s7.9-3.538 7.9-7.902h-0.079z" />
    <path d="M21.319 16c0 2.937-2.38 5.318-5.317 5.319s-5.318-2.379-5.32-5.316c-0.001-2.937 2.378-5.319 5.315-5.321s5.32 2.376 5.323 5.313l0.079-0c-0.003-2.981-2.421-5.395-5.402-5.393s-5.395 2.42-5.394 5.401c0.002 2.981 2.419 5.396 5.399 5.395s5.397-2.417 5.397-5.398h-0.079z" />
    <path d="M17.539 16c0 0.85-0.689 1.539-1.539 1.539s-1.539-0.688-1.539-1.538c-0-0.85 0.688-1.539 1.538-1.54s1.539 0.688 1.54 1.538l0.079-0c-0.001-0.894-0.726-1.617-1.62-1.617s-1.618 0.726-1.617 1.619c0 0.894 0.725 1.618 1.619 1.618s1.618-0.725 1.618-1.618h-0.079z" />
    <path d="M19.979 16c0 2.197-1.781 3.978-3.978 3.979s-3.979-1.78-3.98-3.977c-0.001-2.197 1.779-3.979 3.976-3.981s3.98 1.778 3.982 3.975l0.079-0c-0.002-2.241-1.82-4.056-4.061-4.054s-4.056 1.819-4.055 4.060c0.001 2.241 1.818 4.057 4.059 4.056s4.057-1.817 4.057-4.058h-0.079z" />
    <path d="M20.441 16c0 2.453-1.988 4.441-4.44 4.441s-4.441-1.987-4.443-4.439c-0.001-2.453 1.986-4.442 4.438-4.444s4.442 1.985 4.445 4.437l0.079-0c-0.003-2.496-2.028-4.518-4.524-4.516s-4.519 2.027-4.517 4.523c0.001 2.496 2.026 4.519 4.522 4.519s4.52-2.024 4.52-4.521h-0.079z" />
    <path d="M18.432 16c0 1.343-1.089 2.432-2.432 2.432s-2.432-1.088-2.433-2.431c-0.001-1.343 1.087-2.432 2.43-2.433s2.433 1.087 2.434 2.43l0.079-0c-0.001-1.387-1.127-2.51-2.513-2.509s-2.51 1.126-2.51 2.513c0.001 1.387 1.125 2.511 2.512 2.51s2.511-1.125 2.511-2.511h-0.079z" />
    <path d="M26.103 16c0 5.579-4.522 10.101-10.1 10.103s-10.102-4.519-10.105-10.098c-0.003-5.579 4.517-10.104 10.095-10.108s10.105 4.514 10.11 10.093l0.079-0c-0.006-5.622-4.567-10.176-10.19-10.172s-10.177 4.565-10.174 10.187c0.003 5.622 4.562 10.178 10.185 10.177s10.179-4.56 10.179-10.182h-0.079z" />
    <path d="M21.956 16c0 3.289-2.666 5.956-5.955 5.956s-5.956-2.664-5.958-5.953c-0.002-3.289 2.663-5.957 5.952-5.959s5.958 2.661 5.961 5.95l0.079-0c-0.003-3.333-2.707-6.032-6.040-6.030s-6.033 2.706-6.031 6.039c0.002 3.333 2.704 6.034 6.037 6.033s6.034-2.703 6.034-6.036h-0.079z" />
    <path d="M17.667 16c0 0.92-0.746 1.666-1.666 1.667s-1.667-0.746-1.667-1.666c-0-0.92 0.745-1.667 1.665-1.668s1.667 0.745 1.668 1.665l0.079-0c-0.001-0.964-0.783-1.745-1.747-1.744s-1.745 0.783-1.745 1.747c0 0.964 0.782 1.745 1.746 1.745s1.746-0.782 1.746-1.746h-0.079z" />
    <path d="M21.893 16c0 3.254-2.637 5.892-5.891 5.893s-5.893-2.636-5.894-5.89c-0.002-3.254 2.634-5.893 5.888-5.896s5.894 2.633 5.897 5.887l0.079-0c-0.003-3.298-2.679-5.969-5.976-5.966s-5.969 2.677-5.967 5.975c0.002 3.298 2.676 5.97 5.973 5.969s5.971-2.674 5.971-5.972h-0.079z" />
    <path d="M19.564 16c0 1.968-1.595 3.564-3.564 3.564s-3.564-1.594-3.565-3.563c-0.001-1.968 1.594-3.565 3.562-3.566s3.565 1.593 3.567 3.561l0.079-0c-0.002-2.012-1.634-3.642-3.646-3.64s-3.642 1.634-3.641 3.646c0.001 2.012 1.633 3.642 3.645 3.642s3.643-1.632 3.643-3.644h-0.079z" />
    <path d="M17.587 16c0 0.876-0.71 1.587-1.587 1.587s-1.587-0.71-1.587-1.586c-0-0.876 0.709-1.587 1.586-1.588s1.587 0.709 1.588 1.585l0.079-0c-0.001-0.92-0.747-1.665-1.668-1.665s-1.665 0.747-1.665 1.667c0 0.92 0.747 1.666 1.667 1.665s1.666-0.746 1.666-1.666h-0.079z" />
    <path d="M21.669 16c0 3.131-2.537 5.669-5.668 5.669s-5.669-2.536-5.671-5.667c-0.002-3.131 2.535-5.67 5.665-5.672s5.671 2.533 5.674 5.664l0.079-0c-0.003-3.174-2.579-5.745-5.753-5.743s-5.746 2.577-5.744 5.752c0.002 3.174 2.576 5.747 5.75 5.746s5.747-2.574 5.747-5.749h-0.079z" />
    <path d="M20.043 16c0 2.232-1.809 4.042-4.042 4.043s-4.043-1.808-4.044-4.041c-0.001-2.232 1.807-4.043 4.040-4.045s4.044 1.806 4.046 4.039l0.079-0c-0.002-2.276-1.849-4.12-4.125-4.118s-4.12 1.848-4.119 4.124c0.001 2.276 1.847 4.121 4.123 4.12s4.121-1.846 4.121-4.122h-0.079z" />
    <path d="M22.897 16c0 3.809-3.087 6.896-6.896 6.897s-6.897-3.085-6.899-6.894c-0.002-3.809 3.084-6.898 6.892-6.901s6.899 3.082 6.902 6.89l0.079-0c-0.004-3.852-3.129-6.973-6.982-6.97s-6.973 3.128-6.971 6.98c0.002 3.852 3.126 6.974 6.978 6.973s6.975-3.124 6.975-6.977h-0.079z" />
    <path d="M18.592 16c0 1.431-1.16 2.591-2.591 2.592s-2.592-1.159-2.592-2.59c-0.001-1.431 1.159-2.592 2.59-2.593s2.592 1.158 2.594 2.589l0.079-0c-0.001-1.475-1.198-2.669-2.673-2.668s-2.67 1.197-2.669 2.672c0.001 1.475 1.197 2.67 2.672 2.67s2.67-1.196 2.67-2.671h-0.079z" />
    <path d="M23.902 16c0 4.363-3.537 7.901-7.9 7.902s-7.902-3.535-7.904-7.898c-0.002-4.363 3.533-7.903 7.896-7.906s7.904 3.531 7.908 7.894l0.079-0c-0.004-4.407-3.58-7.977-7.987-7.973s-7.978 3.578-7.975 7.985c0.002 4.407 3.576 7.978 7.983 7.977s7.979-3.574 7.979-7.981h-0.079z" />
    <path d="M20.489 16c0 2.479-2.009 4.489-4.488 4.489s-4.489-2.008-4.49-4.487c-0.001-2.479 2.007-4.49 4.486-4.492s4.49 2.006 4.493 4.485l0.079-0c-0.003-2.523-2.049-4.566-4.572-4.564s-4.566 2.048-4.565 4.571c0.001 2.523 2.047 4.567 4.57 4.566s4.567-2.046 4.567-4.569h-0.079z" />
    <path d="M17.89 16c0 1.044-0.846 1.89-1.889 1.89s-1.89-0.845-1.89-1.889c-0.001-1.044 0.845-1.89 1.889-1.891s1.89 0.844 1.891 1.888l0.079-0c-0.001-1.087-0.883-1.968-1.971-1.967s-1.968 0.883-1.968 1.97c0.001 1.087 0.882 1.969 1.97 1.968s1.969-0.882 1.969-1.969h-0.079z" />
    <path d="M20.681 16c0 2.585-2.095 4.68-4.68 4.681s-4.681-2.094-4.682-4.678c-0.001-2.585 2.093-4.681 4.677-4.683s4.682 2.091 4.684 4.676l0.079-0c-0.003-2.628-2.135-4.757-4.764-4.755s-4.758 2.134-4.756 4.762c0.001 2.628 2.133 4.758 4.761 4.758s4.759-2.132 4.759-4.76h-0.079z" />
    <path d="M21.271 16c0 2.91-2.359 5.27-5.269 5.271s-5.271-2.358-5.272-5.268c-0.001-2.91 2.356-5.271 5.267-5.273s5.272 2.355 5.275 5.265l0.079-0c-0.003-2.954-2.4-5.347-5.354-5.345s-5.348 2.399-5.346 5.353c0.001 2.954 2.397 5.348 5.351 5.347s5.349-2.396 5.349-5.35h-0.079z" />
    <path d="M18.065 16c0 1.14-0.924 2.065-2.065 2.065s-2.065-0.924-2.066-2.064c-0.001-1.14 0.923-2.066 2.064-2.066s2.066 0.923 2.067 2.063l0.079-0c-0.001-1.184-0.962-2.143-2.146-2.143s-2.144 0.962-2.143 2.146c0.001 1.184 0.961 2.144 2.145 2.144s2.144-0.96 2.144-2.145h-0.079z" />
    <path d="M17.491 16c0 0.823-0.667 1.491-1.491 1.491s-1.491-0.667-1.492-1.491c-0-0.823 0.667-1.491 1.49-1.492s1.492 0.666 1.492 1.49l0.079-0c-0.001-0.867-0.704-1.57-1.572-1.569s-1.57 0.704-1.569 1.571c0 0.867 0.704 1.57 1.571 1.57s1.57-0.703 1.57-1.571h-0.079z" />
    <path d="M18.305 16c0 1.273-1.031 2.304-2.304 2.305s-2.305-1.031-2.305-2.303c-0.001-1.273 1.030-2.305 2.303-2.306s2.305 1.030 2.306 2.302l0.079-0c-0.001-1.316-1.069-2.383-2.386-2.382s-2.383 1.069-2.382 2.385c0.001 1.316 1.068 2.383 2.384 2.383s2.383-1.068 2.383-2.384h-0.079z" />
    <path d="M19.214 16c0 1.774-1.438 3.213-3.213 3.214s-3.213-1.437-3.214-3.212c-0.001-1.774 1.437-3.214 3.211-3.215s3.214 1.436 3.216 3.21l0.079-0c-0.002-1.818-1.477-3.291-3.295-3.29s-3.291 1.476-3.29 3.295c0.001 1.818 1.475 3.292 3.294 3.291s3.292-1.475 3.292-3.293h-0.079z" />
    <path d="M17.507 16c0 0.832-0.675 1.507-1.507 1.507s-1.507-0.674-1.508-1.506c-0-0.832 0.674-1.507 1.506-1.508s1.508 0.673 1.508 1.506l0.079-0c-0.001-0.876-0.712-1.586-1.588-1.585s-1.586 0.711-1.585 1.587c0 0.876 0.711 1.586 1.587 1.586s1.586-0.71 1.586-1.587h-0.079z" />
    <path d="M17.109 16c0 0.612-0.496 1.108-1.108 1.109s-1.108-0.496-1.109-1.108c-0-0.612 0.496-1.109 1.108-1.109s1.109 0.495 1.109 1.107l0.079-0c-0.001-0.656-0.533-1.187-1.189-1.187s-1.187 0.533-1.187 1.188c0 0.656 0.532 1.187 1.188 1.187s1.188-0.532 1.188-1.188h-0.079z" />
    <path d="M20.729 16c0 2.611-2.116 4.728-4.727 4.729s-4.728-2.115-4.73-4.726c-0.001-2.611 2.114-4.729 4.725-4.731s4.729 2.113 4.732 4.724l0.079-0c-0.003-2.655-2.157-4.805-4.811-4.803s-4.806 2.155-4.804 4.81c0.001 2.655 2.154 4.806 4.809 4.805s4.807-2.153 4.807-4.808h-0.079z" />
    <path d="M19.517 16c0 1.942-1.574 3.516-3.516 3.517s-3.516-1.573-3.517-3.515c-0.001-1.942 1.572-3.517 3.514-3.518s3.517 1.571 3.519 3.513l0.079-0c-0.002-1.986-1.613-3.594-3.599-3.592s-3.594 1.612-3.593 3.598c0.001 1.986 1.611 3.595 3.597 3.594s3.595-1.61 3.595-3.596h-0.079z" />
    <path d="M17.603 16c0 0.885-0.717 1.603-1.602 1.603s-1.603-0.717-1.603-1.602c-0-0.885 0.717-1.603 1.602-1.604s1.603 0.716 1.604 1.601l0.079-0c-0.001-0.929-0.755-1.681-1.683-1.681s-1.681 0.754-1.681 1.683c0 0.929 0.754 1.682 1.683 1.681s1.682-0.753 1.682-1.682h-0.079z" />
    <path d="M21.175 16c0 2.858-2.316 5.174-5.174 5.175s-5.175-2.315-5.176-5.172c-0.001-2.858 2.314-5.175 5.171-5.178s5.176 2.312 5.179 5.17l0.079-0c-0.003-2.901-2.357-5.251-5.258-5.249s-5.252 2.356-5.25 5.257c0.001 2.901 2.354 5.252 5.256 5.252s5.253-2.353 5.253-5.254h-0.079z" />
    <path d="M19.277 16c0 1.81-1.467 3.277-3.276 3.277s-3.277-1.466-3.278-3.276c-0.001-1.81 1.465-3.278 3.275-3.279s3.278 1.464 3.28 3.274l0.079-0c-0.002-1.854-1.506-3.355-3.359-3.353s-3.355 1.505-3.354 3.358c0.001 1.854 1.504 3.355 3.358 3.355s3.356-1.503 3.356-3.357h-0.079z" />
    <path d="M21.749 16c0 3.175-2.573 5.748-5.748 5.749s-5.749-2.572-5.751-5.746c-0.002-3.175 2.57-5.75 5.745-5.752s5.75 2.569 5.753 5.743l0.079-0c-0.003-3.218-2.614-5.825-5.833-5.823s-5.826 2.613-5.824 5.831c0.002 3.218 2.612 5.826 5.83 5.826s5.827-2.61 5.827-5.828h-0.079z" />
    <path d="M29.005 16c0 7.181-5.821 13.003-13.002 13.005s-13.005-5.817-13.008-12.999c-0.004-7.181 5.814-13.006 12.995-13.012s13.008 5.811 13.015 12.992l0.079-0c-0.007-7.225-5.869-13.077-13.094-13.071s-13.078 5.866-13.075 13.091c0.004 7.225 5.863 13.080 13.088 13.078s13.081-5.859 13.081-13.084h-0.079z" />
    <path d="M18.289 16c0 1.264-1.024 2.288-2.288 2.289s-2.289-1.024-2.289-2.287c-0.001-1.264 1.023-2.289 2.287-2.29s2.289 1.023 2.29 2.286l0.079-0c-0.001-1.308-1.062-2.367-2.37-2.366s-2.367 1.062-2.366 2.369c0.001 1.308 1.061 2.367 2.369 2.367s2.367-1.060 2.367-2.368h-0.079z" />
    <path d="M18.687 16c0 1.484-1.203 2.687-2.687 2.687s-2.687-1.202-2.688-2.686c-0.001-1.484 1.201-2.688 2.685-2.689s2.688 1.201 2.689 2.685l0.079-0c-0.002-1.528-1.241-2.765-2.769-2.764s-2.765 1.24-2.765 2.768c0.001 1.528 1.24 2.766 2.767 2.765s2.766-1.239 2.766-2.767h-0.079z" />
    <path d="M23.774 16c0 4.293-3.48 7.773-7.772 7.774s-7.774-3.478-7.776-7.771c-0.002-4.293 3.476-7.775 7.769-7.778s7.776 3.474 7.78 7.767l0.079-0c-0.004-4.337-3.523-7.849-7.86-7.846s-7.85 3.521-7.848 7.858c0.002 4.337 3.519 7.851 7.856 7.85s7.852-3.517 7.852-7.854h-0.079z" />
    <path d="M20.521 16c0 2.497-2.024 4.521-4.52 4.521s-4.521-2.022-4.522-4.519c-0.001-2.497 2.021-4.522 4.518-4.523s4.522 2.020 4.525 4.517l0.079-0c-0.003-2.54-2.064-4.598-4.604-4.596s-4.598 2.062-4.597 4.603c0.001 2.54 2.061 4.599 4.602 4.598s4.599-2.060 4.599-4.601h-0.079z" />
    <path d="M17.061 16c0 0.586-0.475 1.061-1.060 1.061s-1.061-0.474-1.061-1.060c-0-0.586 0.474-1.061 1.060-1.061s1.061 0.474 1.061 1.060l0.079-0c-0.001-0.63-0.511-1.139-1.141-1.139s-1.139 0.511-1.139 1.141c0 0.63 0.511 1.14 1.14 1.139s1.14-0.511 1.14-1.14h-0.079z" />
    <path d="M20.952 16c0 2.734-2.216 4.951-4.951 4.952s-4.952-2.215-4.953-4.949c-0.001-2.734 2.214-4.952 4.948-4.954s4.953 2.213 4.956 4.947l0.079-0c-0.003-2.778-2.257-5.028-5.035-5.026s-5.029 2.255-5.027 5.034c0.001 2.778 2.254 5.029 5.032 5.029s5.030-2.253 5.030-5.031h-0.079z" />
    <path d="M18.751 16c0 1.519-1.231 2.751-2.75 2.751s-2.751-1.231-2.752-2.75c-0.001-1.519 1.23-2.751 2.749-2.752s2.752 1.229 2.753 2.748l0.079-0c-0.002-1.563-1.27-2.829-2.833-2.828s-2.829 1.269-2.828 2.832c0.001 1.563 1.268 2.829 2.831 2.829s2.83-1.267 2.83-2.83h-0.079z" />
    <path d="M23.216 16c0 3.985-3.23 7.215-7.214 7.216s-7.216-3.228-7.218-7.213c-0.002-3.985 3.226-7.217 7.211-7.22s7.218 3.224 7.222 7.209l0.079-0c-0.004-4.029-3.273-7.291-7.301-7.288s-7.292 3.271-7.29 7.299c0.002 4.029 3.269 7.293 7.297 7.292s7.294-3.267 7.294-7.296h-0.079z" />
    <path d="M19.804 16c0 2.1-1.702 3.803-3.803 3.804s-3.803-1.701-3.805-3.802c-0.001-2.1 1.7-3.804 3.801-3.805s3.804 1.7 3.806 3.8l0.079-0c-0.002-2.144-1.742-3.881-3.886-3.879s-3.881 1.741-3.88 3.885c0.001 2.144 1.74 3.882 3.884 3.881s3.882-1.739 3.882-3.883h-0.079z" />
    <path d="M19.867 16c0 2.136-1.731 3.867-3.866 3.867s-3.867-1.73-3.868-3.865c-0.001-2.136 1.729-3.868 3.864-3.869s3.868 1.728 3.87 3.864l0.079-0c-0.002-2.179-1.77-3.944-3.95-3.943s-3.945 1.769-3.944 3.949c0.001 2.179 1.768 3.945 3.948 3.945s3.946-1.767 3.946-3.947h-0.079z" />
    <path d="M22.323 16c0 3.492-2.83 6.322-6.322 6.323s-6.323-2.828-6.325-6.32c-0.002-3.492 2.827-6.324 6.318-6.326s6.324 2.825 6.328 6.317l0.079-0c-0.004-3.535-2.872-6.399-6.407-6.396s-6.4 2.87-6.398 6.406c0.002 3.535 2.869 6.4 6.404 6.399s6.401-2.867 6.401-6.403h-0.079z" />
    <path d="M19.708 16c0 2.047-1.66 3.707-3.707 3.708s-3.708-1.659-3.709-3.706c-0.001-2.047 1.658-3.708 3.705-3.71s3.709 1.657 3.711 3.704l0.079-0c-0.002-2.091-1.699-3.785-3.79-3.783s-3.785 1.698-3.784 3.789c0.001 2.091 1.697 3.786 3.788 3.785s3.786-1.696 3.786-3.787h-0.079z" />
    <path d="M20.553 16c0 2.514-2.038 4.552-4.552 4.553s-4.553-2.037-4.554-4.551c-0.001-2.514 2.036-4.553 4.55-4.555s4.554 2.034 4.556 4.549l0.079-0c-0.003-2.558-2.078-4.63-4.636-4.628s-4.63 2.077-4.629 4.635c0.001 2.558 2.076 4.631 4.634 4.63s4.631-2.074 4.631-4.632h-0.079z" />
    <path d="M17.443 16c0 0.797-0.646 1.443-1.443 1.443s-1.443-0.646-1.444-1.443c-0-0.797 0.645-1.444 1.442-1.444s1.444 0.645 1.445 1.442l0.079-0c-0.001-0.841-0.683-1.522-1.524-1.521s-1.522 0.683-1.522 1.523c0 0.841 0.682 1.522 1.523 1.522s1.522-0.682 1.522-1.523h-0.079z" />
    <path d="M16.71 16c0 0.392-0.318 0.71-0.71 0.71s-0.71-0.318-0.71-0.709c-0-0.392 0.317-0.71 0.709-0.71s0.71 0.317 0.71 0.709l0.079-0c-0-0.436-0.354-0.789-0.79-0.788s-0.789 0.354-0.789 0.79c0 0.436 0.354 0.789 0.789 0.789s0.789-0.353 0.789-0.789h-0.079z" />
    <path d="M19.532 16c0 1.951-1.581 3.532-3.532 3.532s-3.532-1.58-3.533-3.531c-0.001-1.951 1.579-3.533 3.53-3.534s3.533 1.578 3.535 3.529l0.079-0c-0.002-1.994-1.62-3.61-3.615-3.608s-3.61 1.619-3.609 3.614c0.001 1.994 1.618 3.611 3.613 3.61s3.611-1.617 3.611-3.612h-0.079z" />
    <path d="M19.006 16c0 1.66-1.345 3.006-3.005 3.006s-3.006-1.345-3.007-3.005c-0.001-1.66 1.344-3.006 3.004-3.008s3.007 1.343 3.008 3.003l0.079-0c-0.002-1.704-1.384-3.084-3.088-3.082s-3.084 1.383-3.083 3.087c0.001 1.704 1.383 3.084 3.086 3.084s3.085-1.382 3.085-3.086h-0.079z" />
    <path d="M17.715 16c0 0.947-0.767 1.714-1.714 1.715s-1.714-0.767-1.715-1.714c-0-0.947 0.767-1.715 1.713-1.715s1.715 0.766 1.716 1.713l0.079-0c-0.001-0.991-0.805-1.793-1.795-1.792s-1.793 0.804-1.793 1.795c0.001 0.991 0.804 1.793 1.794 1.793s1.793-0.803 1.793-1.794h-0.079z" />
    <path d="M19.261 16c0 1.801-1.46 3.261-3.261 3.261s-3.261-1.459-3.262-3.26c-0.001-1.801 1.458-3.262 3.259-3.263s3.262 1.457 3.264 3.258l0.079-0c-0.002-1.845-1.499-3.339-3.343-3.337s-3.339 1.498-3.338 3.342c0.001 1.845 1.497 3.34 3.342 3.339s3.34-1.496 3.34-3.341h-0.079z" />
    <path d="M25.975 16c0 5.508-4.465 9.974-9.973 9.975s-9.975-4.462-9.978-9.97c-0.003-5.508 4.46-9.976 9.968-9.98s9.977 4.457 9.983 9.965l0.079-0c-0.006-5.552-4.51-10.049-10.062-10.044s-10.050 4.508-10.047 10.059c0.003 5.552 4.505 10.051 10.057 10.049s10.052-4.503 10.052-10.054h-0.079z" />
    <path d="M19.628 16c0 2.003-1.624 3.628-3.627 3.628s-3.628-1.623-3.629-3.626c-0.001-2.003 1.622-3.628 3.625-3.63s3.629 1.621 3.631 3.625l0.079-0c-0.002-2.047-1.663-3.705-3.71-3.704s-3.706 1.662-3.705 3.709c0.001 2.047 1.661 3.706 3.708 3.706s3.707-1.66 3.707-3.707h-0.079z" />
    <path d="M20.138 16c0 2.285-1.852 4.138-4.137 4.138s-4.138-1.851-4.139-4.136c-0.001-2.285 1.85-4.139 4.135-4.141s4.139 1.849 4.142 4.134l0.079-0c-0.002-2.329-1.892-4.215-4.221-4.214s-4.216 1.891-4.215 4.22c0.001 2.329 1.89 4.216 4.219 4.216s4.217-1.889 4.217-4.218h-0.079z" />
    <path d="M21.781 16c0 3.192-2.587 5.78-5.78 5.781s-5.781-2.586-5.782-5.778c-0.002-3.192 2.584-5.782 5.777-5.784s5.782 2.583 5.785 5.775l0.079-0c-0.003-3.236-2.629-5.857-5.865-5.854s-5.858 2.627-5.856 5.863c0.002 3.236 2.626 5.858 5.862 5.857s5.859-2.624 5.859-5.86h-0.079z" />
    <path d="M18.974 16c0 1.642-1.331 2.974-2.974 2.974s-2.974-1.33-2.975-2.973c-0.001-1.642 1.33-2.975 2.972-2.976s2.975 1.329 2.977 2.971l0.079-0c-0.002-1.686-1.37-3.052-3.056-3.051s-3.052 1.369-3.051 3.055c0.001 1.686 1.368 3.053 3.054 3.052s3.053-1.367 3.053-3.054h-0.079z" />
    <path d="M18.32 16c0 1.281-1.039 2.32-2.32 2.32s-2.32-1.038-2.321-2.319c-0.001-1.281 1.037-2.321 2.319-2.322s2.321 1.037 2.322 2.318l0.079-0c-0.001-1.325-1.076-2.398-2.402-2.397s-2.399 1.076-2.398 2.401c0.001 1.325 1.075 2.399 2.4 2.399s2.399-1.075 2.399-2.4h-0.079z" />
    <path d="M18.496 16c0 1.378-1.117 2.496-2.495 2.496s-2.496-1.116-2.497-2.495c-0.001-1.378 1.116-2.496 2.494-2.497s2.496 1.115 2.498 2.493l0.079-0c-0.001-1.422-1.155-2.574-2.577-2.573s-2.574 1.155-2.573 2.577c0.001 1.422 1.154 2.574 2.576 2.574s2.575-1.153 2.575-2.575h-0.079z" />
    <path d="M19.58 16c0 1.977-1.602 3.58-3.579 3.58s-3.58-1.602-3.581-3.579c-0.001-1.977 1.601-3.581 3.578-3.582s3.581 1.6 3.583 3.577l0.079-0c-0.002-2.021-1.642-3.658-3.662-3.656s-3.658 1.641-3.657 3.662c0.001 2.021 1.64 3.658 3.661 3.658s3.659-1.639 3.659-3.66h-0.079z" />
    <path d="M21.223 16c0 2.884-2.338 5.222-5.222 5.223s-5.223-2.336-5.224-5.22c-0.001-2.884 2.335-5.223 5.219-5.226s5.224 2.334 5.227 5.218l0.079-0c-0.003-2.928-2.378-5.299-5.306-5.297s-5.3 2.377-5.298 5.305c0.001 2.928 2.376 5.3 5.304 5.3s5.301-2.374 5.301-5.302h-0.079z" />
    <path d="M22.1 16c0 3.368-2.73 6.099-6.098 6.1s-6.1-2.729-6.101-6.097c-0.002-3.368 2.727-6.101 6.095-6.103s6.101 2.726 6.105 6.094l0.079-0c-0.003-3.412-2.772-6.176-6.184-6.173s-6.176 2.77-6.175 6.182c0.002 3.412 2.769 6.177 6.181 6.176s6.178-2.767 6.178-6.179h-0.079z" />
    <path d="M21.414 16c0 2.99-2.423 5.413-5.413 5.414s-5.414-2.422-5.416-5.412c-0.002-2.99 2.421-5.415 5.41-5.417s5.415 2.419 5.418 5.409l0.079-0c-0.003-3.033-2.464-5.49-5.498-5.488s-5.491 2.463-5.489 5.496c0.002 3.033 2.461 5.492 5.495 5.491s5.492-2.46 5.492-5.494h-0.079z" />
    <path d="M22.339 16c0 3.5-2.837 6.338-6.338 6.339s-6.339-2.836-6.341-6.336c-0.002-3.5 2.834-6.34 6.334-6.342s6.34 2.832 6.344 6.333l0.079-0c-0.004-3.544-2.879-6.415-6.423-6.412s-6.415 2.877-6.414 6.422c0.002 3.544 2.876 6.416 6.42 6.415s6.417-2.874 6.417-6.419h-0.079z" />
    <path d="M19.899 16c0 2.153-1.745 3.899-3.898 3.899s-3.899-1.744-3.9-3.897c-0.001-2.153 1.743-3.9 3.896-3.901s3.9 1.742 3.902 3.895l0.079-0c-0.002-2.197-1.785-3.976-3.982-3.975s-3.977 1.784-3.976 3.981c0.001 2.197 1.783 3.977 3.98 3.977s3.978-1.782 3.978-3.979h-0.079z" />
    <path d="M18.735 16c0 1.51-1.224 2.735-2.734 2.735s-2.735-1.223-2.736-2.734c-0.001-1.51 1.223-2.735 2.733-2.736s2.736 1.222 2.737 2.732l0.079-0c-0.002-1.554-1.262-2.813-2.817-2.812s-2.813 1.262-2.812 2.816c0.001 1.554 1.261 2.813 2.815 2.813s2.814-1.26 2.814-2.814h-0.079z" />
    <path d="M17.268 16c0 0.7-0.567 1.268-1.268 1.268s-1.268-0.567-1.268-1.267c-0-0.7 0.567-1.268 1.267-1.269s1.268 0.567 1.269 1.267l0.079-0c-0.001-0.744-0.604-1.347-1.348-1.346s-1.347 0.604-1.346 1.348c0 0.744 0.604 1.347 1.348 1.347s1.347-0.603 1.347-1.347h-0.079z" />
    <path d="M18.528 16c0 1.396-1.131 2.527-2.527 2.528s-2.528-1.131-2.528-2.527c-0.001-1.396 1.13-2.528 2.526-2.529s2.528 1.129 2.53 2.525l0.079-0c-0.001-1.44-1.169-2.606-2.609-2.605s-2.606 1.169-2.605 2.608c0.001 1.44 1.168 2.606 2.608 2.606s2.607-1.168 2.607-2.607h-0.079z" />
    <path d="M20.601 16c0 2.541-2.059 4.6-4.6 4.601s-4.601-2.058-4.602-4.599c-0.001-2.541 2.057-4.601 4.598-4.603s4.602 2.056 4.604 4.596l0.079-0c-0.003-2.584-2.099-4.678-4.684-4.676s-4.678 2.098-4.677 4.683c0.001 2.584 2.097 4.679 4.681 4.678s4.679-2.096 4.679-4.68h-0.079z" />
    <path d="M18.815 16c0 1.554-1.26 2.814-2.814 2.815s-2.815-1.259-2.816-2.813c-0.001-1.554 1.258-2.815 2.813-2.816s2.815 1.258 2.817 2.812l0.079-0c-0.002-1.598-1.298-2.893-2.896-2.891s-2.893 1.297-2.892 2.896c0.001 1.598 1.297 2.893 2.895 2.893s2.893-1.296 2.893-2.894h-0.079z" />
    <path d="M17.38 16c0 0.762-0.617 1.379-1.379 1.38s-1.38-0.617-1.38-1.379c-0-0.762 0.617-1.38 1.379-1.38s1.38 0.616 1.381 1.378l0.079-0c-0.001-0.806-0.654-1.458-1.46-1.457s-1.458 0.654-1.458 1.46c0 0.806 0.654 1.458 1.459 1.458s1.459-0.653 1.459-1.459h-0.079z" />
    <path d="M18.145 16c0 1.184-0.96 2.145-2.145 2.145s-2.145-0.96-2.146-2.144c-0.001-1.184 0.959-2.145 2.143-2.146s2.145 0.958 2.147 2.143l0.079-0c-0.001-1.228-0.998-2.223-2.226-2.222s-2.223 0.997-2.223 2.226c0.001 1.228 0.997 2.224 2.225 2.223s2.224-0.996 2.224-2.224h-0.079z" />
    <path d="M16.004 31.907c-8.784 0.002-15.907-7.116-15.911-15.899s7.112-15.909 15.896-15.915c8.784-0.007 15.911 7.108 15.919 15.892 0.070-8.828-7.092-15.977-15.919-15.971s-15.979 7.167-15.975 15.995c0.004 8.828 7.163 15.981 15.991 15.979s15.983-7.159 15.983-15.987c-0.079 8.784-7.199 15.905-15.983 15.907z" />
    <path d="M25.768 16c0 5.394-4.372 9.766-9.765 9.768s-9.768-4.369-9.77-9.763c-0.003-5.394 4.367-9.769 9.76-9.773s9.77 4.364 9.775 9.758l0.079-0c-0.005-5.437-4.417-9.841-9.855-9.837s-9.842 4.415-9.84 9.852c0.003 5.437 4.412 9.844 9.85 9.842s9.845-4.41 9.845-9.847h-0.079z" />
    <path d="M20.107 16c0 2.268-1.838 4.106-4.106 4.107s-4.106-1.837-4.108-4.105c-0.001-2.268 1.836-4.107 4.104-4.109s4.107 1.835 4.11 4.102l0.079-0c-0.002-2.311-1.878-4.183-4.189-4.182s-4.184 1.877-4.183 4.188c0.001 2.311 1.876 4.184 4.187 4.184s4.185-1.875 4.185-4.186h-0.079z" />
    <path d="M24.205 16c0 4.531-3.672 8.204-8.203 8.205s-8.205-3.67-8.207-8.201c-0.002-4.531 3.668-8.206 8.199-8.209s8.207 3.666 8.211 8.197l0.079-0c-0.005-4.575-3.716-8.279-8.291-8.276s-8.28 3.714-8.278 8.288c0.002 4.575 3.712 8.281 8.286 8.28s8.282-3.71 8.282-8.284h-0.079z" />
    <path d="M21.861 16c0 3.236-2.623 5.86-5.859 5.861s-5.861-2.622-5.862-5.858c-0.002-3.236 2.62-5.861 5.856-5.864s5.862 2.619 5.865 5.855l0.079-0c-0.003-3.28-2.665-5.937-5.945-5.934s-5.937 2.663-5.936 5.943c0.002 3.28 2.662 5.938 5.942 5.937s5.939-2.66 5.939-5.94h-0.079z" />
    <path d="M17.316 16c0 0.727-0.589 1.316-1.316 1.316s-1.316-0.589-1.316-1.315c-0-0.727 0.588-1.316 1.315-1.316s1.316 0.588 1.317 1.315l0.079-0c-0.001-0.77-0.626-1.394-1.396-1.394s-1.395 0.625-1.394 1.396c0 0.77 0.625 1.395 1.396 1.394s1.395-0.625 1.395-1.395h-0.079z" />
    <path d="M17.427 16c0 0.788-0.639 1.427-1.427 1.427s-1.427-0.639-1.428-1.427c-0-0.788 0.638-1.428 1.426-1.428s1.428 0.638 1.429 1.426l0.079-0c-0.001-0.832-0.676-1.506-1.508-1.505s-1.506 0.676-1.506 1.508c0 0.832 0.675 1.506 1.507 1.506s1.506-0.675 1.506-1.507h-0.079z" />
    <path d="M17.571 16c0 0.867-0.703 1.571-1.571 1.571s-1.571-0.703-1.571-1.57c-0-0.867 0.702-1.571 1.57-1.572s1.571 0.702 1.572 1.569l0.079-0c-0.001-0.911-0.74-1.649-1.652-1.649s-1.65 0.74-1.649 1.651c0 0.911 0.739 1.65 1.651 1.649s1.65-0.739 1.65-1.65h-0.079z" />
    <path d="M22.547 16c0 3.615-2.93 6.546-6.545 6.547s-6.546-2.928-6.548-6.543c-0.002-3.615 2.927-6.547 6.542-6.55s6.548 2.925 6.551 6.54l0.079-0c-0.004-3.659-2.972-6.622-6.631-6.619s-6.623 2.97-6.621 6.629c0.002 3.659 2.969 6.623 6.627 6.622s6.624-2.967 6.624-6.626h-0.079z" />
    <path d="M18.48 16c0 1.369-1.11 2.48-2.479 2.48s-2.48-1.109-2.481-2.479c-0.001-1.369 1.109-2.48 2.478-2.481s2.48 1.108 2.482 2.477l0.079-0c-0.001-1.413-1.148-2.558-2.561-2.557s-2.558 1.147-2.557 2.561c0.001 1.413 1.147 2.558 2.56 2.558s2.559-1.146 2.559-2.559h-0.079z" />
    <path d="M19.293 16c0 1.819-1.474 3.293-3.292 3.293s-3.293-1.473-3.294-3.292c-0.001-1.819 1.472-3.294 3.291-3.295s3.294 1.471 3.296 3.29l0.079-0c-0.002-1.862-1.513-3.371-3.375-3.369s-3.371 1.512-3.37 3.374c0.001 1.862 1.511 3.371 3.373 3.371s3.372-1.51 3.372-3.373h-0.079z" />
    <path d="M19.15 16c0 1.739-1.41 3.149-3.149 3.15s-3.15-1.409-3.151-3.148c-0.001-1.739 1.408-3.15 3.147-3.151s3.15 1.407 3.152 3.147l0.079-0c-0.002-1.783-1.448-3.227-3.232-3.226s-3.228 1.448-3.227 3.231c0.001 1.783 1.447 3.228 3.23 3.227s3.228-1.446 3.228-3.229h-0.079z" />
    <path d="M17.858 16c0 1.026-0.832 1.858-1.858 1.858s-1.858-0.831-1.858-1.857c-0.001-1.026 0.831-1.858 1.857-1.859s1.858 0.83 1.859 1.856l0.079-0c-0.001-1.070-0.869-1.936-1.939-1.935s-1.936 0.869-1.936 1.938c0.001 1.070 0.868 1.937 1.938 1.936s1.937-0.868 1.937-1.937h-0.079z" />
    <path d="M20.697 16c0 2.593-2.102 4.696-4.695 4.697s-4.697-2.101-4.698-4.694c-0.001-2.593 2.1-4.697 4.693-4.699s4.698 2.098 4.7 4.692l0.079-0c-0.003-2.637-2.142-4.773-4.78-4.771s-4.774 2.141-4.772 4.778c0.001 2.637 2.14 4.774 4.777 4.774s4.775-2.139 4.775-4.776h-0.079z" />
    <path d="M19.405 16c0 1.88-1.524 3.404-3.404 3.405s-3.405-1.523-3.406-3.403c-0.001-1.88 1.522-3.405 3.402-3.407s3.406 1.521 3.407 3.402l0.079-0c-0.002-1.924-1.563-3.482-3.487-3.481s-3.483 1.562-3.482 3.486c0.001 1.924 1.561 3.483 3.485 3.483s3.483-1.56 3.483-3.484h-0.079z" />
    <path d="M18.544 16c0 1.405-1.139 2.543-2.543 2.544s-2.544-1.138-2.544-2.543c-0.001-1.405 1.137-2.544 2.542-2.545s2.544 1.137 2.546 2.541l0.079-0c-0.001-1.448-1.177-2.622-2.625-2.62s-2.622 1.176-2.621 2.624c0.001 1.448 1.175 2.622 2.624 2.622s2.622-1.175 2.622-2.623h-0.079z" />
    <path d="M20.744 16c0 2.62-2.123 4.744-4.743 4.744s-4.744-2.122-4.746-4.742c-0.001-2.62 2.121-4.745 4.741-4.747s4.745 2.12 4.748 4.74l0.079-0c-0.003-2.664-2.164-4.821-4.827-4.819s-4.822 2.163-4.82 4.826c0.001 2.664 2.161 4.822 4.825 4.821s4.823-2.16 4.823-4.824h-0.079z" />
    <path d="M22.499 16c0 3.588-2.909 6.498-6.497 6.499s-6.498-2.907-6.5-6.495c-0.002-3.588 2.905-6.499 6.494-6.502s6.5 2.904 6.504 6.492l0.079-0c-0.004-3.632-2.951-6.574-6.583-6.571s-6.575 2.949-6.573 6.581c0.002 3.632 2.947 6.576 6.58 6.575s6.576-2.946 6.576-6.578h-0.079z" />
    <path d="M17.699 16c0 0.938-0.76 1.698-1.698 1.699s-1.699-0.76-1.699-1.698c-0-0.938 0.759-1.699 1.697-1.699s1.699 0.759 1.7 1.697l0.079-0c-0.001-0.982-0.797-1.777-1.779-1.776s-1.777 0.797-1.777 1.779c0.001 0.982 0.797 1.777 1.778 1.777s1.777-0.796 1.777-1.778h-0.079z" />
    <path d="M17.635 16c0 0.903-0.732 1.635-1.634 1.635s-1.635-0.731-1.635-1.634c-0-0.903 0.731-1.635 1.634-1.636s1.635 0.73 1.636 1.633l0.079-0c-0.001-0.947-0.769-1.713-1.715-1.712s-1.713 0.768-1.713 1.715c0 0.947 0.768 1.713 1.715 1.713s1.714-0.768 1.714-1.714h-0.079z" />
    <path d="M17.746 16c0 0.964-0.782 1.746-1.746 1.746s-1.746-0.781-1.747-1.746c-0-0.964 0.781-1.747 1.745-1.747s1.747 0.78 1.748 1.745l0.079-0c-0.001-1.008-0.819-1.825-1.827-1.824s-1.825 0.819-1.824 1.827c0.001 1.008 0.818 1.825 1.826 1.825s1.825-0.818 1.825-1.826h-0.079z" />
    <path d="M18.129 16c0 1.176-0.953 2.129-2.129 2.129s-2.129-0.952-2.13-2.128c-0.001-1.176 0.952-2.129 2.128-2.13s2.13 0.951 2.131 2.127l0.079-0c-0.001-1.22-0.991-2.207-2.21-2.206s-2.207 0.99-2.207 2.21c0.001 1.22 0.99 2.208 2.209 2.207s2.208-0.989 2.208-2.208h-0.079z" />
    <path d="M19.724 16c0 2.056-1.667 3.723-3.723 3.724s-3.724-1.666-3.725-3.722c-0.001-2.056 1.665-3.724 3.721-3.726s3.725 1.664 3.727 3.72l0.079-0c-0.002-2.1-1.706-3.801-3.806-3.799s-3.801 1.705-3.8 3.805c0.001 2.1 1.704 3.802 3.804 3.801s3.802-1.703 3.802-3.803h-0.079z" />
    <path d="M22.435 16c0 3.553-2.88 6.434-6.433 6.435s-6.435-2.878-6.436-6.432c-0.002-3.553 2.877-6.435 6.43-6.438s6.436 2.875 6.44 6.428l0.079-0c-0.004-3.597-2.922-6.51-6.519-6.508s-6.511 2.92-6.509 6.517c0.002 3.597 2.919 6.512 6.516 6.511s6.513-2.917 6.513-6.514h-0.079z" />
    <path d="M27.506 16c0 6.354-5.15 11.504-11.503 11.506s-11.506-5.147-11.509-11.5c-0.003-6.353 5.144-11.507 11.497-11.512s11.508 5.141 11.515 11.495l0.079-0c-0.006-6.397-5.197-11.579-11.594-11.574s-11.58 5.194-11.577 11.591c0.003 6.397 5.191 11.581 11.588 11.58s11.582-5.188 11.582-11.585h-0.079z" />
    <path d="M18.783 16c0 1.537-1.246 2.783-2.782 2.783s-2.783-1.245-2.784-2.782c-0.001-1.537 1.244-2.783 2.781-2.784s2.784 1.243 2.785 2.78l0.079-0c-0.002-1.581-1.284-2.861-2.864-2.859s-2.861 1.283-2.86 2.864c0.001 1.581 1.283 2.861 2.863 2.861s2.862-1.282 2.862-2.862h-0.079z" />
    <path d="M22.722 16c0 3.712-3.008 6.721-6.72 6.722s-6.722-3.007-6.724-6.719c-0.002-3.712 3.005-6.722 6.717-6.725s6.723 3.003 6.727 6.715l0.079-0c-0.004-3.756-3.051-6.797-6.806-6.794s-6.798 3.049-6.796 6.805c0.002 3.756 3.047 6.799 6.803 6.798s6.8-3.046 6.8-6.801h-0.079z" />
    <path d="M19.245 16c0 1.792-1.453 3.245-3.245 3.245s-3.245-1.452-3.246-3.244c-0.001-1.792 1.451-3.246 3.243-3.247s3.246 1.45 3.248 3.242l0.079-0c-0.002-1.836-1.491-3.323-3.327-3.321s-3.323 1.491-3.322 3.326c0.001 1.836 1.49 3.324 3.326 3.323s3.324-1.489 3.324-3.325h-0.079z" />
    <path d="M20.792 16c0 2.646-2.145 4.792-4.791 4.792s-4.792-2.144-4.793-4.79c-0.001-2.646 2.142-4.793 4.789-4.795s4.793 2.141 4.796 4.787l0.079-0c-0.003-2.69-2.185-4.869-4.875-4.867s-4.869 2.184-4.868 4.874c0.001 2.69 2.183 4.87 4.873 4.869s4.87-2.182 4.87-4.872h-0.079z" />
    <path d="M19.325 16c0 1.836-1.488 3.325-3.324 3.325s-3.325-1.487-3.326-3.323c-0.001-1.836 1.487-3.325 3.323-3.327s3.326 1.486 3.328 3.322l0.079-0c-0.002-1.88-1.527-3.402-3.407-3.401s-3.403 1.526-3.402 3.406c0.001 1.88 1.525 3.403 3.405 3.403s3.404-1.525 3.404-3.404h-0.079z" />
    <path d="M18.336 16c0 1.29-1.046 2.336-2.336 2.336s-2.336-1.045-2.337-2.335c-0.001-1.29 1.045-2.337 2.335-2.338s2.337 1.044 2.338 2.334l0.079-0c-0.001-1.334-1.084-2.414-2.418-2.413s-2.415 1.083-2.414 2.417c0.001 1.334 1.082 2.415 2.416 2.415s2.415-1.082 2.415-2.416h-0.079z" />
    <path d="M23.44 16c0 4.108-3.33 7.439-7.438 7.44s-7.439-3.328-7.441-7.436c-0.002-4.108 3.326-7.44 7.434-7.443s7.441 3.324 7.445 7.432l0.079-0c-0.004-4.152-3.373-7.514-7.524-7.511s-7.515 3.371-7.513 7.523c0.002 4.152 3.369 7.516 7.521 7.515s7.517-3.367 7.517-7.519h-0.079z" />
    <path d="M19.915 16c0 2.162-1.752 3.915-3.914 3.915s-3.915-1.751-3.916-3.913c-0.001-2.162 1.75-3.916 3.912-3.917s3.916 1.749 3.918 3.911l0.079-0c-0.002-2.206-1.792-3.992-3.998-3.991s-3.993 1.791-3.992 3.997c0.001 2.206 1.79 3.993 3.996 3.993s3.994-1.789 3.994-3.995h-0.079z" />
  </svg>
));

export default Rings;
