import * as React from 'react';
import { useEditorState } from '../context/VideoEditorStateContext';

export interface IntegrationsUiProps {}

const IntegrationsUi: React.FC<IntegrationsUiProps> = () => {
  const { integrations } = useEditorState();

  return (
    <>
      {integrations.allIntegrations
        .filter(({ ui }) => React.isValidElement(ui))
        .map(({ id, ui }) => React.cloneElement(ui, { key: id }))}
    </>
  );
};

export default IntegrationsUi;
