import React from 'react';
import { isEqual } from 'underscore';

interface UseStyleToggleConfig<TStyle> {
  defaultValue: TStyle;
  onToggleOff?: () => void;
  onToggleOn?: () => void;
  onChange: (value?: TStyle) => void;
  value: TStyle;
}

interface UseStyleToggle {
  isEnabled: boolean;
  onToggle: () => void;
}

const useStyleToggle = <TStyle extends unknown>(
  config: UseStyleToggleConfig<TStyle>,
): UseStyleToggle => {
  const { defaultValue, onChange, onToggleOff, onToggleOn, value } = config;

  const isInitialEnabled = !!value;

  const [prevValue, setPrevValue] = React.useState(value);
  const [isEnabled, setIsEnabled] = React.useState(isInitialEnabled);
  const [lastUsedStyle, setLastUsedStyle] = React.useState<TStyle>(
    isEnabled ? value : defaultValue,
  );

  if (!isEqual(prevValue, value)) {
    setPrevValue(value);
    if (value) {
      setLastUsedStyle(value);
    }
  }

  const handleToggle = React.useCallback((): void => {
    if (isEnabled) {
      onToggleOff?.();
      setIsEnabled(false);
      onChange(undefined);
    } else {
      onToggleOn?.();
      setIsEnabled(true);
      onChange(lastUsedStyle);
    }
  }, [isEnabled, lastUsedStyle, onChange, onToggleOff, onToggleOn]);

  return {
    isEnabled,
    onToggle: handleToggle,
  };
};

export default useStyleToggle;
