import { useService } from '@xstate/react';
import * as React from 'react';
import { noop } from 'underscore';

import { NavigationContextType } from '../types';

const { useContext, useEffect, useRef, useState } = React;

interface NavigationProviderProps {
  children?: React.ReactNode;
  value: NavigationContextType;
}

const NavContext = React.createContext<NavigationContextType>(undefined);

export function useNavigation() {
  const context = useContext(NavContext);

  if (context === undefined) {
    throw new Error('useNavigation must be used within NavigationProvider');
  }

  return context;
}

export function useChildView() {
  const [, , service] = useNavigation();
  return useService(service.children.get('childView') as any);
}

interface UseChildViewStateConfig {
  onChange?: (state: any) => void;
}

/**
 * similar to useChildView, but the child view machine is instantiated only
 * when in a child view.  when the child machine isn't running,
 * service.children.get('childView') returns undefined and the xstate
 * useService hook throws an error for an undefined argument.
 *
 * TODO pass messages from child machine to parent so parent has all the data it needs
 */
export function useChildViewState(config: UseChildViewStateConfig = {}) {
  const { onChange = noop } = config;
  const onChangeRef = useRef(onChange);

  const [, , service] = useNavigation();
  const [, setState] = useState<any>();
  const childService = service.children.get('childView');

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    const sub = childService?.subscribe(s => {
      if (s.changed || s.matches('initializing')) {
        setState(s);
        onChangeRef.current(s);
      }
    });

    return sub?.unsubscribe;
  });

  return {
    service: childService,
  };
}

export const NavigationProvider: React.FC<NavigationProviderProps> = ({
  children,
  value,
}) => <NavContext.Provider value={value}>{children}</NavContext.Provider>;
