import * as types from './types';

const deleteEntities = keys => dispatch =>
  dispatch({
    type: types.ENTITIES_DELETE,
    payload: {
      keys,
    },
  });

export default deleteEntities;
export { deleteEntities };
