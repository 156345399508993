import {
  AuthenticationProvider,
  UserAuthenticationEventType,
  UserAuthResult,
} from '@sparemin/auth';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  loginUserCredentials,
  registerUserCredentials,
  socialAuth,
} from 'redux/modules/auth';
import { showError } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { removeReferralQueryParams } from '../utils';

export interface UserAuthenticationSuccessHandlerConfig {
  /**
   * This hook by default will handle errors thrown by the login/registration logic.
   * The error gets displayed as a toast and gets suppressed so that it will not get
   * thrown back to the caller.
   *
   * If `rethrowErrors` is true, his hook will not handle the error and instead will
   * throw it back up to the caller.  If the calling function needs to build on the
   * default error handling behavior, it can first pass the error to `defaultErrorHandler(error)`
   * before handling the error itself.
   */
  rethrowErrors?: boolean;
}

/**
 * Consolidates the logic that has to be run after login (on the login page),
 * after registration (on the registration page), or after either login or registration
 * in the Auth modal
 */
export default function useAuthenticationSuccessHandler(
  config?: UserAuthenticationSuccessHandlerConfig,
) {
  const { rethrowErrors = false } = config ?? {};
  const dispatch = useDispatch<Dispatch>();
  const location = useLocation();
  const history = useHistory();

  const defaultErrorHandler = useCallback(
    (error: Error, eventType: UserAuthenticationEventType) => {
      dispatch(
        showError({
          dismissAfterSec: 5,
          message: error.message,
          title:
            eventType === 'registration' ? 'Registration Error' : 'Login Error',
        }),
      );
    },
    [dispatch],
  );

  const onAuthenticationSuccess = useCallback(
    async (provider: AuthenticationProvider, data: UserAuthResult) => {
      const { spareminToken, eventType, userId } = data;

      if (eventType === 'registration') {
        window.fbq && window.fbq('track', 'CompleteRegistration');

        // referral/promo key can be removed from the URL after this point.  If the
        // user just registered, then the query params are no longer needed (and in fact
        // might cause issues when redirecting to the Create tab)
        history.replace(removeReferralQueryParams(location));
      }

      try {
        if (eventType === 'registration' && provider === 'headliner') {
          return dispatch(registerUserCredentials(userId, spareminToken));
        }

        if (eventType === 'login' && provider === 'headliner') {
          return dispatch(loginUserCredentials(userId, spareminToken));
        }

        return dispatch(
          socialAuth(
            userId,
            spareminToken,
            eventType === 'registration',
            provider,
          ),
        );
      } catch (err) {
        if (!rethrowErrors) {
          defaultErrorHandler(err, eventType);
        } else {
          throw err;
        }
      }

      return null;
    },
    [defaultErrorHandler, dispatch, history, location, rethrowErrors],
  );

  return { defaultErrorHandler, onAuthenticationSuccess };
}
