import * as React from 'react';

import TextBoxNumericField, {
  TextBoxNumericFieldProps,
} from '../TextBoxNumericField';

type Props = TextBoxNumericFieldProps<string>;

const TextShadowNumericField: React.SFC<Props> = props => (
  <TextBoxNumericField<string>
    {...props}
    size={2}
    maxLength={2}
    min={-99}
    max={99}
    step={1}
  />
);

TextShadowNumericField.defaultProps = {
  value: 0,
};

export { Props as TextShadowNumericFieldProps };
export default TextShadowNumericField;
