import _ from 'underscore';

import { updateCaptionsOffset } from 'redux/modules/embed/actions/captions';
import embedUtils from '../../../../utils/embed';
import { max } from '../../../../utils/numbers';
import { API_CALL } from '../../../middleware/api';
import embedConfigurationService from '../../../middleware/api/embed-configuration-service';
import { fetchEmbedConfiguration } from '../../../middleware/api/embed-configuration-service/actions';
import { autosaveActionBuilder } from '../../../middleware/autosave';
import * as authSelectors from '../../auth/selectors';
import * as notificationActions from '../../notification';
import { actions as projectActions } from '../../project';
import * as routingActions from '../../router/actions';
import * as types from '../action-types';
import * as embedSelectors from '../selectors';
import { processIntroOutroClipUploads } from './edge-videos';

// TODO make these arguments an object.  this is insane
const postEmbedConfiguration = (
  durationMillis,
  slides,
  textOverlayInfo,
  audioInfo,
  videoClips,
  versionInfo,
  recordingId,
  dimensions,
  soundwave,
  watermark,
  captions,
  creatorUserId,
  layers,
  mainMediaContainer,
  progress,
  timer,
  edgeVideos,
) => ({
  [API_CALL]: {
    service: embedConfigurationService.ActionKey,
    method: embedConfigurationService.Method.CREATE_CONFIGURATION,
    args: [
      durationMillis,
      slides,
      textOverlayInfo,
      audioInfo,
      videoClips,
      versionInfo,
      recordingId,
      dimensions,
      soundwave,
      watermark,
      captions,
      creatorUserId,
      layers,
      mainMediaContainer,
      progress,
      timer,
      edgeVideos,
    ],
  },
});

const setEmbedDurationAction = durationMillis => ({
  type: types.EMBED_DURATION_SET,
  payload: { durationMillis },
});

export const getEmbedConfiguration = wid => dispatch =>
  dispatch(fetchEmbedConfiguration(wid));

export const clearEmbedState = () => dispatch =>
  dispatch({
    type: types.EMBED_STATE_CLEAR,
  });

export const selectEntryForEmbed = entry => dispatch => {
  dispatch(clearEmbedState());
  const recordingId = entry.get('recording');

  dispatch({
    type: types.SPAREMIN_RECORDING_SELECT,
    payload: { recordingId },
  });

  /*
   * load the editor.
   * this will navigate to the `EditorPage` component which, on mount, will first clear out
   * existing editor data before loading data for the requested project
   */
  dispatch(routingActions.goToProject({ recordingId }));
};

export const createEmbedCode = (wid, recordingId) => dispatch => {
  const code = embedUtils.createEmbedCode(wid, recordingId);

  return dispatch({
    type: types.EMBED_CODE_CREATE,
    payload: { code },
  });
};

export const setEmbedConfiguration = configuration => dispatch => {
  const { wid } = configuration;
  dispatch({
    type: types.EMBED_CONFIGURATION_SET,
    payload: { configuration },
  });
  dispatch(routingActions.updateEditorUrl(wid));
};

export const createEmbedConfiguration = config => (dispatch, getState) => {
  const configuration =
    config || embedSelectors.mapStateToEmbedConfig(getState());

  const userId = authSelectors.userIdSelector(getState());
  const {
    edgeVideos,
    expectedDurationMilli,
    slideshowInfo,
    textOverlayInfo,
    audioInfo,
    versionInfo,
    dimensions,
    soundwave,
    watermark,
    captions,
    recordingId,
    videoClips,
    layerOrder,
    mainMediaContainer,
    progress,
    timer,
  } = configuration;

  return dispatch(
    postEmbedConfiguration(
      expectedDurationMilli,
      slideshowInfo,
      textOverlayInfo,
      audioInfo,
      videoClips,
      versionInfo,
      recordingId,
      dimensions,
      soundwave,
      watermark,
      captions,
      userId,
      layerOrder,
      mainMediaContainer,
      progress,
      timer,
      edgeVideos,
    ),
  );
};

export const prepareEmbedConfig = exportConfigOverride => (
  dispatch,
  getState,
) => {
  const mediaOffsetMillis = embedSelectors.transcriptMediaOffsetMillisSelector(
    getState(),
  );
  const updatedCaptions = dispatch(updateCaptionsOffset(mediaOffsetMillis));

  const edgeVideosConfig = dispatch(
    processIntroOutroClipUploads(exportConfigOverride),
  );

  return Promise.all([edgeVideosConfig, updatedCaptions]).then(
    ([processIntroOutroOutput]) => {
      const { edgeVideos } = processIntroOutroOutput;
      // This is a workaround for avoiding overriding config for non export operations,
      // it should be reviewed when the feature is adjusted for advanced editor
      if (edgeVideos) {
        dispatch({ type: types.EMBED_EDGE_VIDEOS_CHANGE, payload: edgeVideos });
      }
    },
  );
};

export const createEmbedConfigurationFromState = (
  exportConfigOverride = {},
) => (dispatch, getState) => {
  dispatch({ type: types.EMBED_CONFIGURATION_CREATE_REQUEST });

  const prepareEmbed = dispatch(prepareEmbedConfig(exportConfigOverride));

  const embedConfig = prepareEmbed.then(() => {
    return dispatch(createEmbedConfiguration());
  });

  const updatedProject = embedConfig.then(res => {
    dispatch(setEmbedConfiguration(res.response.configuration));
    dispatch(projectActions.updateCurrentProject());
  });

  return Promise.all([embedConfig, updatedProject, prepareEmbed])
    .then(([embedRes]) => {
      const { wid } = embedRes.response.configuration;
      dispatch(createEmbedCode(wid));
      dispatch({ type: types.EMBED_CONFIGURATION_CREATE_SUCCESS });
      return embedConfig;
    })
    .catch(errorAction => {
      dispatch({
        ...errorAction,
        type: types.EMBED_CONFIGURATION_CREATE_FAILURE,
      });
      return Promise.reject(errorAction);
    });
};

/**
 * NB: exported so that it can be used by the autosave middleware, but this function should
 *     _only_ be dispatched by the middleware.  to trigger a save, dispatch `saveConfiguration` or
 *     `debouncedSaveConfiguration`
 */
export const doSaveConfiguration = dispatch => {
  dispatch({ type: types.EMBED_CONFIGURATION_SAVE_REQUEST });

  return dispatch(createEmbedConfigurationFromState())
    .then(() => dispatch({ type: types.EMBED_CONFIGURATION_SAVE_SUCCESS }))
    .catch(err => {
      dispatch(
        notificationActions.showError({
          type: 'failedSave',
          code: 'ER001',
          message:
            'Embed configuration could not be saved. Press the button below to try again.',
        }),
      );
      dispatch({
        ...err,
        type: types.EMBED_CONFIGURATION_SAVE_FAILURE,
      });
    });
};

const immediateSaveAction = autosaveActionBuilder()
  .immediateSave()
  .build();
const delayedSaveAction = autosaveActionBuilder({
  type: types.EMBED_AUTOSAVE_SCHEDULE,
})
  .delayedSave()
  .build();

const cancelDelayedSaveAction = autosaveActionBuilder({
  type: types.EMBED_AUTOSAVE_SCHEDULE_CLEAR,
})
  .clear()
  .build();

export const saveConfiguration = () => dispatch =>
  dispatch(immediateSaveAction);

export const debouncedSaveConfiguration = () => dispatch =>
  dispatch(delayedSaveAction);

export const clearDebouncedSaveConfiguration = () => dispatch =>
  dispatch(cancelDelayedSaveAction);

export const setEmbedRecording = recordingId => dispatch =>
  dispatch({
    type: types.EMBED_RECORDING_SET,
    payload: { recordingId },
  });

function handleSetEmbedDuration(dispatch, getState) {
  const currentDurationMillis = embedSelectors.embedDurationMillisSelector(
    getState(),
  );
  const slideEndMillis = embedSelectors.slideEndMillisSelector(getState());
  const textOverlayEndMillis = embedSelectors.textOverlayEndMillisSelector(
    getState(),
  );
  const audioEndMillis = embedSelectors.audioEndMillisSelector(getState());
  const videoEndMillis = embedSelectors.videoEndMillisSelector(getState());
  const waveformEndMillis = embedSelectors.soundwaveEndMillisSelector(
    getState(),
  );

  const durationMillis = max(
    slideEndMillis,
    textOverlayEndMillis,
    audioEndMillis,
    videoEndMillis,
    waveformEndMillis,
  );

  if (durationMillis !== currentDurationMillis) {
    dispatch(setEmbedDurationAction(durationMillis));
  }
}

/*
 * this is a naiive approach to finding the duration of the timeline.  debouncing in case of
 * performance issues with dense timelines.
 */
const throttledSetEmbedDuration = _.throttle(handleSetEmbedDuration, 200);

export const setEmbedDuration = (immediate = false) => (dispatch, getState) => {
  const fn = immediate ? handleSetEmbedDuration : throttledSetEmbedDuration;
  fn(dispatch, getState);
};

export default {
  clearEmbedState,
  createEmbedCode,
  getEmbedConfiguration,
  selectEntryForEmbed,
  createEmbedConfigurationFromState,
  saveConfiguration,
  debouncedSaveConfiguration,
  setEmbedRecording,
  setEmbedDuration,
};
