import { useMemo, useRef, useState } from 'react';

export interface StableActions<K> {
  add: (key: K) => void;
  initialize: (keys: K[]) => void;
  remove: (key: K) => void;
  toggle: (key: K) => void;
  reset: () => void;
}

const useSet = <K>(initialSet = new Set<K>()): [Set<K>, StableActions<K>] => {
  const initial = useRef(initialSet);
  const [set, setSet] = useState(initialSet);

  const stableActions = useMemo<StableActions<K>>(() => {
    const add = (item: K) =>
      setSet(prevSet => new Set([...Array.from(prevSet), item]));
    const initialize = (items: K[]): void => {
      setSet(new Set(items));
    };
    const remove = (item: K) =>
      setSet(prevSet => new Set(Array.from(prevSet).filter(i => i !== item)));
    const toggle = (item: K) =>
      setSet(prevSet =>
        prevSet.has(item)
          ? new Set(Array.from(prevSet).filter(i => i !== item))
          : new Set([...Array.from(prevSet), item]),
      );

    return {
      add,
      initialize,
      remove,
      toggle,
      reset: () => setSet(initial.current),
    };
  }, [setSet]);

  return [set, stableActions];
};

export default useSet;
