import * as React from 'react';
import { getStateContent } from '../state';

import { VideoTemplateState, VideoTemplateStateContent } from '../types';

const { useContext } = React;

interface VideoTemplateStateProviderProps {
  chidlren?: React.ReactNode;
  value: VideoTemplateState;
}

const TemplateContext = React.createContext<VideoTemplateStateContent>(
  undefined,
);

export function useTemplateState() {
  return useContext(TemplateContext);
}

export const VideoTemplateStateProvider: React.FC<VideoTemplateStateProviderProps> = ({
  children,
  value,
}) => (
  <TemplateContext.Provider value={getStateContent(value)}>
    {children}
  </TemplateContext.Provider>
);
