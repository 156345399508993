import * as React from 'react';

import ImageBackground from './ImageBackground';
import { Background } from './types';
import { isImageBackground } from './utils';
import VideoBackground from './VideoBackground';

export interface AssetTileBackgroundProps {
  aspectRatio?: number;
  background?: Background;
  videoRef?: React.Ref<HTMLVideoElement>;
}

const AssetTileBackground: React.SFC<AssetTileBackgroundProps> = ({
  aspectRatio,
  background,
  videoRef,
}) => {
  if (!background) {
    return null;
  }

  if (isImageBackground(background)) {
    return (
      <ImageBackground aspectRatio={aspectRatio} background={background} />
    );
  }

  return <VideoBackground background={background} ref={videoRef} />;
};

export default AssetTileBackground;
