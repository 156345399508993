import * as React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import PaidSubscriptionSuccessModalContent from './PaidSubscriptionSuccessModalContent';
import { block } from './utils';

interface Props {}

const PaidSubscriptionSuccessModal: React.FC<Props> = () => {
  return (
    <ConnectedModal name="PaidSubscriptionSuccess" className={block()}>
      <PaidSubscriptionSuccessModalContent />
    </ConnectedModal>
  );
};

export default PaidSubscriptionSuccessModal;
