import * as React from 'react';

import { IconProps } from './types';

const Unlisted: React.FC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="17"
    viewBox="0 0 20 17"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M10 13c-2.37 0-4.29-1.835-4.466-4.16L2.256 6.307c-.43.54-.827 1.112-1.147 1.737a1.01 1.01 0 0 0 0 .912c1.694 3.307 5.05 5.544 8.89 5.544.842 0 1.653-.125 2.435-.327l-1.622-1.255c-.267.052-.54.08-.812.082zm9.807 1.816l-3.455-2.67a10.351 10.351 0 0 0 2.54-3.19 1.01 1.01 0 0 0 0-.912C17.195 4.737 13.841 2.5 10 2.5a9.63 9.63 0 0 0-4.604 1.178L1.421.605a.5.5 0 0 0-.702.088l-.614.79a.5.5 0 0 0 .088.701l18.386 14.21a.5.5 0 0 0 .702-.087l.614-.79a.5.5 0 0 0-.088-.701zm-5.741-4.438l-1.229-.95a2.96 2.96 0 0 0-3.628-3.81c.188.256.29.565.29.882-.004.106-.02.21-.047.313l-2.3-1.778A4.447 4.447 0 0 1 10 4a4.497 4.497 0 0 1 4.5 4.5c0 .676-.165 1.306-.434 1.878z"
    />
  </svg>
);

export default Unlisted;
