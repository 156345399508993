import * as React from 'react';

interface IRenderProps {
  href: string;
}

interface IProps {
  children?: React.ReactNode;
  className?: string;
  to: string;
  body?: string;
  render?: (props: IRenderProps) => JSX.Element;
  subject?: string;
}

const MailTo: React.SFC<IProps> = ({
  className,
  children,
  to,
  subject,
  body,
  render,
}) => {
  const opts = {
    body: body && encodeURIComponent(body),
    subject: subject && encodeURIComponent(subject),
  };

  const queryString = Object.keys(opts)
    .reduce((res, key) => {
      const value = opts[key];
      if (!value) return res;
      return [...res, `${key}=${value}`];
    }, [])
    .join('&');

  const href = `mailto:${to}${queryString ? `?${queryString}` : ''}`;
  const renderProps = { href };

  return render ? (
    render(renderProps)
  ) : (
    <a className={className} href={href}>
      {children}
    </a>
  );
};

export default MailTo;
export { IProps as MailToProps };
