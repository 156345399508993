import cn from 'classnames';
import * as React from 'react';

import ConnectedModal, { ConnectedModalProps } from 'containers/ConnectedModal';
import { block } from './utils';

type PickedModalProps = Pick<ConnectedModalProps, 'name' | 'title'>;

export interface PlanDecisionModalProps extends PickedModalProps {
  className?: string;
}

const PlanDecisionModal: React.FC<PlanDecisionModalProps> = ({
  children,
  className,
  name,
  title,
}) => (
  <ConnectedModal className={cn(block(), className)} {...{ name, title }}>
    {children}
  </ConnectedModal>
);

export default PlanDecisionModal;
