import { Button, Heading, HeadyGradient, Spacer } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinkButton from 'components/LinkButton';
import LoadingOverlay from 'components/LoadingOverlay';
import { loggedInSelector } from 'redux/modules/auth';
import { isPollingSocialPostCaptionsSelector } from 'redux/modules/download';
import {
  onAiGenSocialCaptionsClearText,
  onAiGenSocialCaptionsUpgradePoint,
} from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal/actions';
import { isFreeSelector } from 'redux/modules/pricing';
import { Dispatch } from 'redux/types';
import RainbowFieldContainer from './RainbowFieldContainer';
import ShareFormGroup from './ShareFormGroup';
import ShareFormTextarea, { ShareFormTextareaProps } from './ShareFormTextarea';
import { block } from './utils';

export interface ShareAICaptionsFieldProps {
  allowAiDisclaimer?: boolean;
  inputProps: ShareFormTextareaProps;
  onUnlockAICaptions: () => void;
  onCancelUnlockAICaptions: () => void;
}

const ShareAICaptionsField: React.FC<ShareAICaptionsFieldProps> = ({
  allowAiDisclaimer,
  inputProps,
  onUnlockAICaptions,
  onCancelUnlockAICaptions,
}) => {
  const [showAiDisclaimer, setShowAiDisclaimer] = useState(
    allowAiDisclaimer && !!inputProps.value,
  );

  const isAuthenticated = useSelector(loggedInSelector);
  const isPollingSocialPostCaptions = useSelector(
    isPollingSocialPostCaptionsSelector,
  );
  const isFree = useSelector(isFreeSelector);
  const dispatch = useDispatch<Dispatch>();

  const showLockedContents = isFree || !isAuthenticated;

  const handleUpgrade = useCallback(async () => {
    dispatch(onAiGenSocialCaptionsUpgradePoint());

    onUnlockAICaptions();

    const shouldCancel = await dispatch(
      replaceModal({ name: 'AICaptionsUpsell' }),
    );

    if (shouldCancel) {
      onCancelUnlockAICaptions();
    }
  }, [dispatch, onCancelUnlockAICaptions, onUnlockAICaptions]);

  const handleClearText = useCallback(() => {
    dispatch(onAiGenSocialCaptionsClearText());
    inputProps.onChange('');
    setShowAiDisclaimer(false);
  }, [dispatch, inputProps]);

  return (
    <ShareFormGroup>
      {isPollingSocialPostCaptions && <LoadingOverlay />}

      {showAiDisclaimer ? (
        <RainbowFieldContainer>
          <Spacer
            orientation="horizontal"
            align="center"
            justify="space-between"
          >
            <Spacer
              orientation="horizontal"
              space="6px"
              align="center"
              justify="space-between"
            >
              <HeadyGradient width="20px" height="20px" color="transparent" />

              <Heading level={4}>
                {showLockedContents
                  ? 'Headliner can now write captions for you!'
                  : 'We wrote a caption for you, hope you like it!'}
              </Heading>
            </Spacer>

            {showLockedContents ? (
              <LinkButton
                theme="rainbow"
                className={block('unlock-button')}
                onClick={handleUpgrade}
              >
                Unlock this feature
              </LinkButton>
            ) : (
              <Button
                size="small"
                className={block('clear-button')}
                onPress={handleClearText}
              >
                Clear text
              </Button>
            )}
          </Spacer>

          <ShareFormTextarea
            {...inputProps}
            className={block('ai-captions-field-input')}
          />
        </RainbowFieldContainer>
      ) : (
        <ShareFormTextarea
          {...inputProps}
          className={block('ai-captions-field-input')}
        />
      )}
    </ShareFormGroup>
  );
};

export default ShareAICaptionsField;
