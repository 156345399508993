import * as emailValidator from 'email-validator';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { VideoExportAdjustments } from 'redux/middleware/api/headliner-user-service';
import { userVideoPrefsSelector } from 'redux/modules/user-pref/selectors';
import { FrameQuality, FrameRate, FrameSize } from 'types';
import { VIDEO_EXPORT_PREFS_DEFAULTS } from 'utils/constants';

import { FormErrorState, FormState, IntroOutroFormState } from '../../types';
import { HANDLED_MESSAGE_FILE_ERR_CODES } from './utils';

type FileField = 'intro' | 'outro';

const DEFAULT_FORM_STATE: Readonly<Partial<FormState>> = {
  email: [''],
  intro: undefined,
  outro: undefined,
};

function useFile(
  defaultFileName: IntroOutroFormState['value'],
  defaultType: IntroOutroFormState['type'],
) {
  // This state should be a temporal workaround for allowing File, uploaded intro/outro
  // video ids and initialUrl preferences to exist together. By reading this style it can
  // be defined if the value has a real file or if it has a pre loaded value from the
  // embed config.
  // TODO: Remove when implementing the final UX
  const [type, setType] = useState(defaultType);
  const [fileName, setFileName] = useState(defaultFileName);
  const [file, setFile] = useState<File | undefined>();

  const handleFileAccepted = useCallback((acceptedFile: File) => {
    // Sets type to "file" as default has been overriden now with a file
    setType('file');
    setFileName(acceptedFile.name);
    setFile(acceptedFile);
  }, []);

  const handleFileClear = useCallback(() => {
    // Sets type to "file" as by clearing any selection, it should rollback selection type
    // to file
    setType('file');
    setFile(undefined);
    setFileName(undefined);
  }, []);

  return {
    file,
    fileName,
    handleFileAccepted,
    handleFileClear,
    setFileName,
    type,
  };
}

function useIntroOutro(
  defaultIntro?: IntroOutroFormState,
  defaultOutro?: IntroOutroFormState,
  onError?: (reason: string) => void,
) {
  const {
    file: introFile,
    fileName: introFileName,
    handleFileAccepted: handleIntroFileAccepted,
    handleFileClear: handleIntroFileClear,
    type: introType,
  } = useFile(defaultIntro.value, defaultIntro.type);

  const {
    file: outroFile,
    fileName: outroFileName,
    handleFileAccepted: handleOutroFileAccepted,
    handleFileClear: handleOutroFileClear,
    type: outroType,
  } = useFile(defaultOutro.value, defaultOutro.type);

  const handleFileAccepted = useCallback(
    (field: FileField, file: File) =>
      field === 'intro'
        ? handleIntroFileAccepted(file)
        : handleOutroFileAccepted(file),
    [handleIntroFileAccepted, handleOutroFileAccepted],
  );

  const handleFileClear = useCallback(
    (field: FileField) => {
      field === 'intro' ? handleIntroFileClear() : handleOutroFileClear();
    },
    [handleIntroFileClear, handleOutroFileClear],
  );

  const handleFileRejected = useCallback(
    (field: FileField, file: File, reason: string, code: string) => {
      const fileName = file ? file.name : '';
      let message = `${field} file ${fileName} rejected: ${reason}`;
      // For the handled messages, the message is formatted for matching the ones specified
      // for intro/outro at UCS. This should be revisitted when reviewing the way in which
      // intro/outro are uploaded at advanced editor.
      if (HANDLED_MESSAGE_FILE_ERR_CODES.includes(code)) {
        message = reason?.replace('{type}', field);
      }
      onError(message);
    },
    [onError],
  );

  const handleFileChange = useCallback(
    (
      field: FileField,
      file: File,
      reason: string,
      isRejected: boolean,
      code: string,
    ) => {
      if (!isRejected) {
        file ? handleFileAccepted(field, file) : handleFileClear(field);
      } else {
        handleFileRejected(field, file, reason, code);
      }
    },
    [handleFileAccepted, handleFileClear, handleFileRejected],
  );

  return {
    handleFileChange,
    introFile,
    introFileName,
    introType,
    outroFile,
    outroFileName,
    outroType,
  };
}

export default function useFormState(
  defaultFormState?: Partial<FormState>,
  onError?: (string) => void,
) {
  const videoPrefs: VideoExportAdjustments = useSelector(
    userVideoPrefsSelector,
  );

  const initialFrameRate: FrameRate =
    videoPrefs?.frameRate?.default || VIDEO_EXPORT_PREFS_DEFAULTS.frameRate;
  const initialVideoQuality: FrameQuality =
    videoPrefs?.frameQuality?.default ||
    VIDEO_EXPORT_PREFS_DEFAULTS.frameQuality;
  const initialVideoSize: FrameSize =
    videoPrefs?.frameSize?.default || VIDEO_EXPORT_PREFS_DEFAULTS.frameSize;

  const initialState = {
    ...DEFAULT_FORM_STATE,
    ...defaultFormState,
  };

  const [errors, setErrors] = useState<FormErrorState>({ email: [] });
  const [email, setEmail] = useState(initialState.email);
  const [frameRate, setFrameRate] = useState(initialFrameRate);
  const [quality, setQuality] = useState(initialVideoQuality);
  const [size, setSize] = useState(initialVideoSize);

  const validate = () => {
    let isFormValid = true;

    if (Array.isArray(email)) {
      setErrors({
        email: email.map(v => {
          const isValid = !v || emailValidator.validate(v);
          isFormValid = isFormValid && isValid;
          return isValid ? null : 'Invalid email address';
        }),
      });
    }

    return isFormValid;
  };

  const {
    introFile,
    introFileName,
    introType,
    outroFile,
    outroFileName,
    outroType,
    handleFileChange,
  } = useIntroOutro(defaultFormState.intro, defaultFormState.outro, onError);

  const handleFormChange = useCallback(
    (val, field) => {
      switch (field) {
        case 'email':
          setEmail(val);
          setErrors({ email: [] });
          break;

        case 'quality':
          setQuality(val);
          break;

        case 'size':
          setSize(val);
          break;

        case 'frameRate':
          setFrameRate(val);
          break;

        case 'intro':
        case 'outro': {
          const { file, code, reason, isRejected } = val;
          handleFileChange(field, file, reason, isRejected, code);
          break;
        }

        default:
      }
    },
    [handleFileChange],
  );

  const value = {
    email,
    frameRate,
    quality,
    size,
    intro: { type: introType, value: introFileName },
    outro: { type: outroType, value: outroFileName },
  };

  const files = {
    introFile,
    outroFile,
  };

  return { value, files, errors, validate, handleFormChange };
}
