import { ButtonBase, TikTokFilled } from '@sparemin/blockhead';
import React, { useCallback } from 'react';

import DialogContent from 'components/DialogContent';
import { H2 } from 'components/Heading';
import { TiktokIconRounded } from 'components/icons';
import { Step } from 'components/SteppedModal';
import useTikTokAuthenticator, {
  UseTikTokAuthenticatorConfig,
} from 'hooks/useTikTokAuthenticator';
import { block } from '../utils';

interface UseAuthorizationStepConfig
  extends Pick<UseTikTokAuthenticatorConfig, 'onAuthSuccess' | 'onError'> {
  onAuth: () => void;
}

export default function useAuthorizationStep({
  onAuth,
  onAuthSuccess,
  onError,
}: UseAuthorizationStepConfig): Step {
  const { authenticate, authenticating } = useTikTokAuthenticator({
    force: true,
    onAuthSuccess,
    onError,
  });

  const handleAuthenticate = useCallback((): void => {
    onAuth();
    authenticate();
  }, [authenticate, onAuth]);

  return {
    id: 'authorization',
    fluidFooterButtons: true,
    component: (
      <DialogContent
        title="Let's add your account"
        details={
          <H2>
            We'll quickly send you to TikTok so you can give us permission to
            add videos to your account.
          </H2>
        }
        icon={<TiktokIconRounded width="100px" height="100px" />}
        detailsClassName={block('details')}
      />
    ),
    renderFooterButtons: () => [
      <ButtonBase
        className={block('auth-button')}
        onPress={handleAuthenticate}
        isDisabled={authenticating}
      >
        <TikTokFilled width="16px" height="18px" />
        <span>Continue with TikTok</span>
      </ButtonBase>,
    ],
  };
}
