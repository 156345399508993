import { AutoPostPlatform } from 'types';

export type AutoPostPlatformOverlay = 'default' | 'youtube';

const comingSoonOverlayMessages: Record<AutoPostPlatformOverlay, string> = {
  default: 'We’ll email your first video soon',
  youtube: "We'll post your first video to YouTube soon!",
};

export function getComingSoonOverlayMessage(
  platform: AutoPostPlatform,
): string {
  if (!platform || !(platform in comingSoonOverlayMessages))
    return comingSoonOverlayMessages.default;

  return comingSoonOverlayMessages[platform];
}
