import React from 'react';

// types
import { IconProps } from './types';

const FooterInstagram: React.FC<IconProps> = ({ ...svgProps }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...svgProps}
  >
    <defs>
      <radialGradient
        cx="42.983%"
        cy="0%"
        fx="42.983%"
        fy="0%"
        r="103.088%"
        gradientTransform="matrix(.24291 .97005 -.97863 .24506 .325 -.417)"
        id="e"
      >
        <stop stopColor="#F9D262" offset="0%" />
        <stop stopColor="#F6A650" offset="22.135%" />
        <stop stopColor="#F14E2C" offset="50.167%" />
        <stop stopColor="#98069F" offset="82.431%" />
        <stop stopColor="#5E18D1" offset="100%" />
      </radialGradient>
      <filter
        x="-94.7%"
        y="-82.9%"
        width="289.5%"
        height="289.5%"
        filterUnits="objectBoundingBox"
        id="c"
      >
        <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="7.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <circle id="d" cx="10" cy="10" r="12.667" />
      <path
        d="M10.002 4.872A5.12 5.12 0 0 0 4.876 10a5.12 5.12 0 0 0 5.126 5.128A5.12 5.12 0 0 0 15.13 10a5.12 5.12 0 0 0-5.127-5.128zm0 8.462A3.34 3.34 0 0 1 6.67 10a3.337 3.337 0 0 1 3.333-3.334A3.337 3.337 0 0 1 13.335 10a3.34 3.34 0 0 1-3.333 3.334zm6.532-8.671c0 .664-.535 1.196-1.195 1.196a1.196 1.196 0 1 1 1.196-1.196zm3.396 1.213c-.076-1.602-.442-3.02-1.615-4.19C17.145.516 15.727.15 14.125.07c-1.65-.093-6.6-.093-8.25 0-1.597.076-3.016.442-4.19 1.611C.512 2.851.151 4.27.07 5.871c-.093 1.652-.093 6.601 0 8.253.076 1.602.442 3.02 1.615 4.19 1.174 1.17 2.588 1.535 4.19 1.616 1.65.093 6.6.093 8.25 0 1.602-.076 3.02-.442 4.19-1.616 1.169-1.17 1.534-2.588 1.615-4.19.093-1.652.093-6.596 0-8.248zm-2.133 10.02a3.375 3.375 0 0 1-1.9 1.9c-1.317.523-4.44.402-5.895.402-1.454 0-4.582.116-5.894-.402a3.375 3.375 0 0 1-1.9-1.9c-.523-1.317-.402-4.441-.402-5.896s-.116-4.583.401-5.895a3.375 3.375 0 0 1 1.901-1.901c1.316-.523 4.44-.402 5.894-.402 1.455 0 4.582-.116 5.894.402a3.375 3.375 0 0 1 1.901 1.9c.522 1.317.402 4.441.402 5.896s.12 4.583-.402 5.895z"
        id="a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#F47F40" fillRule="nonzero" xlinkHref="#a" />
      <g mask="url(#b)" transform="rotate(180 10 10)">
        <use fill="#000" filter="url(#c)" xlinkHref="#d" />
        <use fill="url(#e)" xlinkHref="#d" />
      </g>
    </g>
  </svg>
);

export default FooterInstagram;
