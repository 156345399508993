import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';

import { selectors as authSelectors } from 'redux/modules/auth';
import {
  navigationBarHiddenSelector,
  navigationSelector,
} from 'redux/modules/display-pref/selectors';
import {
  actions as projectActions,
  selectors as projectSelectors,
} from 'redux/modules/project';
import { Dispatch, State } from 'redux/types';
import AuthNavBar, { IProps } from './AuthNavBar';

type StateProps = Pick<
  IProps,
  | 'isSavingProjectName'
  | 'loggedIn'
  | 'loggedInUserEmail'
  | 'projectName'
  | 'reducedNavbar'
>;
type DispatchProps = Pick<IProps, 'onSubmitProjectName'>;

const reducedNavbarSelector = createSelector(
  [navigationSelector, navigationBarHiddenSelector],
  (navigation, navigationBarHidden) => {
    if (navigation === undefined) return true;

    if (navigationBarHidden !== undefined) return navigationBarHidden;

    if (navigationBarHidden === undefined) return false;

    return false;
  },
);

const mapStateToProps = (state: State): StateProps => {
  const loggedIn = authSelectors.loggedInSelector(state);

  return {
    isSavingProjectName: projectSelectors.projectNameUpdateInProgressSelector(
      state,
    ),
    loggedIn,
    loggedInUserEmail: authSelectors.userNameSelector(state),
    projectName: projectSelectors.projectNameSelector(state),
    reducedNavbar: loggedIn && reducedNavbarSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSubmitProjectName: name =>
    dispatch(projectActions.updateCurrentProject(undefined, name, undefined)),
});

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthNavBar);

const component = withRouter(connectedComponent);

export type AuthNavBarProps = React.ComponentPropsWithoutRef<typeof component>;

export default component;
