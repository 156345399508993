import * as React from 'react';

import { useLocation } from 'react-router';
import BemCSSTransition from 'components/BemCssTransition';
import CutCardCta from 'components/CardCta/CutCardCta';
import ReplyCardCta from 'components/CardCta/ReplyCardCta';
import DownloadPageDetails from '../../components/DownloadPageDetails';
import { block, exitAutogramReviewLink } from '../../utils';

const { useEffect } = React;

interface Props {
  onReclipClick?: () => void;
  onShareClick?: () => void;
  onShow?: () => void;
  show?: boolean;
}

const AutogramReviewDetails: React.FC<Props> = ({
  onReclipClick,
  onShareClick,
  onShow,
  show,
}) => {
  const location = useLocation();
  useEffect(() => {
    if (show) {
      onShow();
    }
  }, [onShow, show]);

  return (
    <BemCSSTransition
      className={block('autogram-review')}
      in={show}
      delay={600}
      timeout={1000}
      enter={false}
      unmountOnExit
    >
      <DownloadPageDetails
        className={block('autogram-review')}
        subtitle="Hope you like it, but if not you can quickly tweak it."
        title="Voila, your new video!"
      >
        <div className={block('review-buttons')}>
          <BemCSSTransition
            className={block('summary-share-button')}
            in={show}
            timeout={450}
            enter={false}
          >
            <div>
              <ReplyCardCta
                label="Share it with your fans"
                onClick={onShareClick}
                to={exitAutogramReviewLink({ location })}
              />
            </div>
          </BemCSSTransition>
          <div className={block('summary-reclip-button-container')}>
            <BemCSSTransition
              className={block('summary-reclip-button')}
              in={show}
              timeout={800}
              enter={false}
            >
              <CutCardCta
                label="Choose another audio clip"
                to={loc => `${loc.pathname}/reclip`}
                onClick={onReclipClick}
              />
            </BemCSSTransition>
          </div>
        </div>
      </DownloadPageDetails>
    </BemCSSTransition>
  );
};

AutogramReviewDetails.defaultProps = {
  show: false,
};

export type AutogramReviewDetailsProps = Props;

export default AutogramReviewDetails;
