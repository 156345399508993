import classNames from 'classnames';
import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import _ from 'underscore';

import WithWindowResize, {
  WithWindowResizeWrapper,
} from 'components/hoc/WithWindowResize';
import { innerHeight, innerWidth } from 'utils/dom';
import { getRatio } from 'utils/numbers';
import { prefix } from 'utils/ui';

interface ISize {
  height?: number;
  width?: number;
}

export type OnSizeChange = (size: Required<ISize>) => void;

export interface DraggableWorkspaceProps
  extends Pick<React.HTMLProps<HTMLDivElement>, 'onDoubleClick' | 'onWheel'> {
  aspectRatio?: number;
  className?: string;
  children?: React.ReactNode;
  containerSize?: {
    height?: string | number;
    width?: string | number;
  };
  defaultSize?: ISize;
  onSizeChange?: OnSizeChange;
  style?: React.CSSProperties;
  windowSize?: {
    height?: number;
    width?: number;
  };
}

interface IState {
  data: RecordOf<ISize>;
}

const dataFactory = Record<ISize>({
  height: undefined,
  width: undefined,
});

/**
 * A container to help with some of the quirks of react-draggable.
 *
 * react-draggable calculates the dimensions of the draggable container by using HTMLElement
 * "offset" properties (e.g. offsetHeight, offsetWidth, etc.).  These properties return values that
 * are rounded to the nearest integer.
 *
 * If you don't specify the height and width of your container in integer pixels and instead use
 * fractional pixels or a relative measurement like a percentage, then there could be issues with
 * react-draggable.
 *
 * The issue occurs when the container height or width contains a fraction of a pixel less than
 * 0.5px.  This causes the measurements like offsetHeight to round down.  While you might have
 * 300.49px width, react-draggable is going to see that as 300px and is going to prevent you from
 * dragging to the boundary which is 0.49px further.
 *
 * Depending on how the browser handles rendering fractions of a pixel, this gap could be quite
 * noticeable.
 *
 * This component is driven by width and will base the height of the workspace off of the width.
 * The component will render as if it had width set to 100%, but it will round down to the nearest
 * integer and will calculate the hight (also rounding down to the nearest integer) using the
 * provided aspect ratio.  This results in integer height and width.
 */
class DraggableWorkspace extends React.Component<
  DraggableWorkspaceProps,
  IState
> {
  public static defaultProps: Partial<DraggableWorkspaceProps> = {
    containerSize: {},
    defaultSize: {
      height: 0,
      width: 0,
    },
    onSizeChange: _.noop,
    windowSize: {},
  };

  private element: HTMLDivElement;

  constructor(props) {
    super(props);

    const { defaultSize } = props;

    this.state = {
      data: dataFactory({
        height: defaultSize.height,
        width: defaultSize.width,
      }),
    };
  }

  public componentDidMount() {
    this.setWorkspaceSize();
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<DraggableWorkspaceProps>,
  ) {
    const {
      containerSize: nextContainerSize,
      windowSize: nextWindowSize,
    } = nextProps;
    const { containerSize, windowSize } = this.props;

    const windowSizeChanged = nextWindowSize.width !== windowSize.width;
    const containerSizeChanged =
      nextContainerSize.width !== containerSize.width;
    if (windowSizeChanged || containerSizeChanged) {
      this.setWorkspaceSize(nextProps);
    }
  }

  public componentDidUpdate(__, prevState: Readonly<IState>) {
    const { onSizeChange } = this.props;
    const { data: prevData } = prevState;
    const { data } = this.state;

    if (!data.equals(prevData)) {
      onSizeChange(this.size);
    }
  }

  private setWorkspaceSize(
    props: Readonly<DraggableWorkspaceProps> = this.props,
  ) {
    const { aspectRatio } = props;

    const parent = this.element.parentElement;
    const parentWidth = innerWidth(parent);
    const parentHeight = innerHeight(parent);

    if (!aspectRatio) {
      this.setState(({ data }) => ({
        data: data.withMutations(d => {
          d.set('height', parentHeight);
          d.set('width', parentWidth);
          return d;
        }),
      }));
      return;
    }

    const [width, height] = getRatio(aspectRatio);

    const widthRatio = parentWidth / width;
    const heightRatio = parentHeight / height;

    // min excluding 0
    const scale = Math.min(...[widthRatio, heightRatio].filter(Boolean));

    this.setState(({ data }) => ({
      data: data.withMutations(d => {
        d.set('height', height * scale);
        d.set('width', width * scale);
        return d;
      }),
    }));
  }

  public getElement() {
    return this.element;
  }

  public get size() {
    const { data } = this.state;
    return {
      height: data.get('height'),
      width: data.get('width'),
    };
  }

  public render() {
    const {
      children,
      className,
      onDoubleClick,
      onWheel,
      style: styleProp,
    } = this.props;
    const { data } = this.state;

    const containerClassName = classNames({
      'draggable-workspace': true,
      [className]: !!className,
    });

    const style = prefix({
      height: data.get('height'),
      width: data.get('width'),
      ...styleProp,
    });

    const ref = element => {
      this.element = element;
    };

    return (
      <div
        className={containerClassName}
        {...{ onDoubleClick, onWheel, style, ref }}
      >
        {children}
      </div>
    );
  }
}

export default WithWindowResize(50)(
  DraggableWorkspace,
) as WithWindowResizeWrapper<DraggableWorkspaceProps>;
