import cn from 'classnames';
import * as React from 'react';

import SelectTile, { SelectTileProps } from '../SelectTile';
import { progressViewBlock as block } from '../utils';

type PickedTileProps = Pick<SelectTileProps, 'onClick' | 'selected'>;

export interface ProgressEnabledTileProps extends PickedTileProps {
  className?: string;
}

const ProgressEnabledTile: React.FC<ProgressEnabledTileProps> = ({
  className,
  onClick,
  selected,
}) => (
  <SelectTile
    className={cn(block('enabled-tile'), className)}
    {...{ onClick, selected }}
  >
    <div className={block('tile-contents')}>
      <div className={block('tile-color-indicator')}>
        <div className={block('tile-from-color')} />
      </div>
    </div>
  </SelectTile>
);

export default ProgressEnabledTile;
