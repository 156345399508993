import { useCallback, useEffect, useRef } from 'react';
import WaveSurferJs from 'wavesurfer.js';

import { WaveSurferProps } from './types';

export default function useVolume(volume: WaveSurferProps['volume']) {
  const volumeRef = useRef(volume);
  const wavesurfer = useRef<WaveSurferJs>();

  const setVolume = useCallback((ws: WaveSurferJs = wavesurfer.current) => {
    if (ws) {
      ws.setVolume(volumeRef.current);
    }
  }, []);

  useEffect(
    () => {
      volumeRef.current = volume;
      setVolume();
    },
    // NB: it's important that setVolume is stable.  this hook should only run when
    // volume changes
    [volume, setVolume],
  );

  // handles setting volume when wavesurfer loads
  return useCallback(
    (ws: WaveSurfer) => {
      wavesurfer.current = ws;
      if (ws) {
        if (ws.isReady) {
          setVolume(ws);
        } else {
          const fn = () => setVolume(ws);
          ws.on('ready', fn);
          return () => ws.un('ready', fn);
        }
      }
      return undefined;
    },
    [setVolume],
  );
}
