import * as React from 'react';
import _ from 'underscore';

import Tooltip, { TooltipProps } from 'components/Tooltip';
import { formatTimelineMillis } from 'utils/time';
import { positionToMillis } from '../../../utils';

type PickedToolipProps = Pick<TooltipProps, 'children' | 'id' | 'show'>;

export interface DragHandleTooltipProps extends PickedToolipProps {
  x: number;
  pxPerSec: number;
}

const DragHandleTooltip: React.SFC<DragHandleTooltipProps> = ({
  x,
  pxPerSec,
  ...props
}) => {
  const posToFormattedTime = _.compose(
    formatTimelineMillis,
    _.partial(positionToMillis, _, pxPerSec),
  );

  return (
    <Tooltip
      animation={false}
      content={posToFormattedTime(x)}
      placement="top"
      preventHideOnHover={false}
      {...props}
    />
  );
};

export default DragHandleTooltip;
