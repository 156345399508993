import * as React from 'react';

import { IconProps } from './types';

const GoogleLogo: React.FC<IconProps> = props => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M18.614 9.205c0-.639-.058-1.252-.164-1.841H9.974v3.481h4.843a4.14 4.14 0 0 1-1.796 2.716v2.259h2.91c1.7-1.567 2.683-3.875 2.683-6.615z"
        fill="#4285F4"
      />
      <path
        d="M9.974 18c2.43 0 4.467-.806 5.956-2.18l-2.909-2.259c-.805.54-1.836.86-3.047.86-2.344 0-4.328-1.584-5.036-3.711H1.93v2.332A8.997 8.997 0 0 0 9.974 18z"
        fill="#34A853"
      />
      <path
        d="M4.938 10.71A5.41 5.41 0 0 1 4.656 9c0-.593.102-1.17.282-1.71V4.958H1.93A8.996 8.996 0 0 0 .974 9c0 1.452.347 2.827.957 4.042l3.007-2.332z"
        fill="#FBBC05"
      />
      <path
        d="M9.974 3.58c1.321 0 2.507.454 3.44 1.345l2.582-2.58C14.436.891 12.4 0 9.974 0A8.997 8.997 0 0 0 1.93 4.958L4.938 7.29c.708-2.127 2.692-3.71 5.036-3.71z"
        fill="#EA4335"
      />
      <path d="M.974 0h18v18h-18z" />
    </g>
  </svg>
);

export default GoogleLogo;
