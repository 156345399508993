import { Tier } from 'redux/middleware/api/plan-service/types';
import bem from 'utils/bem';
import { WelcomeType } from './containers/WelcomeStep/types';
import { StepId } from './types';

export const cardWizardBlock = bem('card-wizard');
export const onboardingWizardBlock = bem('onboarding-wizard');
export const iconicSelectorBlock = bem('iconic-selector-step');
export const newsletterBlock = bem('newsletter-step');
export const referrerBlock = bem('referrer-step');
export const welcomeBlock = bem('welcome-step');
export const upsellBlock = bem('upsell-step');
export const featuresCalloutBlock = bem('features-callout');
export const feedCollectBlock = bem('feed-collect-step');
export const podcastAddedBlock = bem('podcast-added-step');
export const onboardingDestinationStepBlock = bem(
  'onboarding-destination-step',
);
export const onboardingVideoTypeStepBlock = bem('onboarding-video-type-step');

export const WELCOME_TYPE_TO_TIER = {
  [WelcomeType.PRO]: Tier.PRO,
  [WelcomeType.BASIC]: Tier.BASIC,
  [WelcomeType.UNLIMITED]: Tier.UNLIMITED,
} as const;

export const STEPS_TO_IGNORE_STEPPER: StepId[] = [
  'podcast-added',
  'destination-selection',
  'video-type-selection',
];
