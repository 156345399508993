import * as React from 'react';
import AutomationCMS from 'blocks/AutomationCMS/containers/AutomationCMS';
import ProjectsPageTabContext from './ProjectsPageTabs/ProjectsPageTabContext';

const { useCallback, useContext } = React;

export interface AutomatinCMSTabProps {}

const AutomatinCMSTab: React.FC<AutomatinCMSTabProps> = () => {
  const { scrollParent } = useContext(ProjectsPageTabContext);

  const handleGetScrollParent = useCallback(() => scrollParent, [scrollParent]);

  return <AutomationCMS getScrollParent={handleGetScrollParent} />;
};

export default AutomatinCMSTab;
