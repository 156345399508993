import cn from 'classnames';
import * as React from 'react';
import { isNumber } from 'underscore';
import { AspectRatioName } from 'types';

import { getAspectRatioValue } from 'utils/aspect-ratio';
import bem from 'utils/bem';
import { cloneOnlyChild } from 'utils/react';

export interface FixedAspectProps {
  aspectRatio: number | AspectRatioName;
  children?: React.ReactNode;
}

const block = bem('fixed-aspect');

const FixedAspect: React.SFC<FixedAspectProps> = ({
  aspectRatio,
  children,
}) => {
  const ratio = isNumber(aspectRatio)
    ? aspectRatio
    : getAspectRatioValue(aspectRatio);

  return (
    <div
      className={block()}
      style={{
        paddingTop: `${(1 / ratio) * 100}%`,
      }}
    >
      {cloneOnlyChild(children, child => ({
        className: cn(block('element'), child.props.className),
      }))}
    </div>
  );
};

export default FixedAspect;
