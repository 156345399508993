import * as React from 'react';

import LoadingScreen from 'components/LoadingScreen';
import Page from 'components/Page';
import { RequestStatus } from 'types';
import useDownloadPageDispatch from '../useDownloadPageDispatch';
import useDownloadPageState from '../useDownloadPageState';

export interface DownloadPageContainerProps {
  children?: React.ReactElement;
}

const DownloadPageContainer: React.FC<DownloadPageContainerProps> = ({
  children,
}) => {
  const { isLoading, loadingState, wid } = useDownloadPageState();
  useDownloadPageDispatch(wid);

  return (
    <>
      <LoadingScreen message="Preparing Download" show={isLoading} fadeout />
      {
        // don't want to mount children until loading is complete, but the
        // LoadingScreen component needs something to fill the page underneath
        // it so an empty <Page /> is used
      }
      {loadingState === RequestStatus.SUCCESS ? children : <Page />}
    </>
  );
};

export default DownloadPageContainer;
