import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { projectsSelector } from 'redux/modules/entities';
import { onClickDownloadLink } from 'redux/modules/mixpanel/actions';
import { showError } from 'redux/modules/notification/actions';
import {
  copyProject,
  deleteProjectById,
  getProjectHistory,
  getProjectSubscriptionIfExists,
} from 'redux/modules/project/actions';
import { projectHistoryIdsSelector } from 'redux/modules/project/selectors';
import { getTemplateId } from 'redux/modules/project/utils';
import { Dispatch, State, ThunkAction } from 'redux/types';
import { getValue } from 'utils/collections';
import ProjectHistory, { ProjectHistoryProps } from './ProjectHistory';

type DispatchProps = Pick<
  ProjectHistoryProps,
  'onDeleteProject' | 'onDownloadProject' | 'onCopyProject'
>;
type StateProps = Pick<ProjectHistoryProps, 'projects'>;

const projectIdsSelector = createSelector(projectHistoryIdsSelector, ids =>
  ids?.toArray(),
);

const onDownloadProject = (id: string): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  const projects = projectsSelector(getState());
  const templateId = getTemplateId(getValue(projects, id));
  const subscription = await dispatch(getProjectSubscriptionIfExists(id));

  dispatch(
    onClickDownloadLink({
      id,
      templateId,
      templateName: subscription?.templateName,
      templateType: subscription?.templateType,
    }),
  );
};

const mapStateToProps = (state: State): StateProps => ({
  projects: projectIdsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onCopyProject: (id, name, ratio) =>
    dispatch(copyProject(id, name, ratio)).then(() =>
      dispatch(getProjectHistory(false)).catch(() =>
        dispatch(showError('There was an error copying your project', 5)),
      ),
    ),
  onDeleteProject: id => dispatch(deleteProjectById(id)),
  onDownloadProject: id => dispatch(onDownloadProject(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectHistory);
