import uncontrollable from 'uncontrollable';

import SearchInput, { SearchInputProps } from './SearchInput';

type UncontrollableProps = SearchInputProps & {
  defaultValue?: SearchInputProps['value'];
};

const uncontrollableComponent: React.ComponentClass<UncontrollableProps> = uncontrollable(
  SearchInput,
  {
    value: 'onChange',
  },
  ['focus'],
);

export { UncontrollableProps as SearchInputProps };
export default uncontrollableComponent;
