import { SocialSharePlatform } from 'types';
import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A>(method: M, args: A) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export const fetchThirdPartyTokens = (
  provider: types.GettyAuthProvider[],
): types.GetGettyProviderTokenAction =>
  apiCallAction(types.ServiceMethod.GET_GETTY_PROVIDER_TOKEN, [
    provider,
  ] as types.GetGettyProviderTokenArgs);

export function authorizeThirdPartyProvider(
  provider: types.SocialAuthProvider,
): types.AuthorizeAction {
  return apiCallAction(types.ServiceMethod.AUTHORIZE, [
    provider,
  ] as types.AuthorizeArgs);
}

export function getSocialAuthStatus(
  platform: SocialSharePlatform,
  scopeContext?: types.SocialShareScopeContext,
  forceLogin?: boolean,
): types.GetSocialAuthStatusAction {
  return apiCallAction(types.ServiceMethod.GET_SOCIAL_AUTH_STATUS, [
    platform,
    scopeContext,
    forceLogin,
  ]);
}

export function getSocialProfile(
  provider: SocialSharePlatform,
  providerUserId?: string,
  idToken?: string,
  accessToken?: string,
  accessTokenSecret?: string,
): types.GetSocialProfileAction {
  return apiCallAction(types.ServiceMethod.GET_SOCIAL_PROFILE, [
    provider,
    providerUserId,
    idToken,
    accessToken,
    accessTokenSecret,
  ]);
}

export function getOrganizations(
  provider: SocialSharePlatform,
  providerUserId?: string,
  idToken?: string,
  accessToken?: string,
): types.GetLinkedinOrganizationsAction {
  return apiCallAction(types.ServiceMethod.GET_LINKEDIN_ORGANIZATIONS, [
    provider,
    providerUserId,
    idToken,
    accessToken,
  ]);
}

export function getInstagramAccounts(
  providerUserId?: string,
  accessToken?: string,
): types.GetInstagramAccountsAction {
  return apiCallAction(types.ServiceMethod.GET_INSTAGRAM_ACCOUNTS, [
    providerUserId,
    accessToken,
  ]);
}

export function getFacebookPages(
  providerUserId?: string,
  accessToken?: string,
): types.GetFacebookPagesAction {
  return apiCallAction(types.ServiceMethod.GET_FACEBOOK_PAGES, [
    providerUserId,
    accessToken,
  ]);
}

export const getYoutubeChannel = (
  providerUserId: string,
  accessToken: string,
): types.GetSocialYoutubeChannelAction => {
  return apiCallAction(types.ServiceMethod.GET_SOCIAL_YOUTUBE_CHANNEL, [
    providerUserId,
    accessToken,
  ]);
};

export const getAppsConnected = (
  provider?: types.SocialAuthProvider,
): types.GetAppsConnectedAction => {
  return apiCallAction(types.ServiceMethod.GET_APPS_CONNECTED, {
    provider,
  } as types.GetAppsConnectedArgs);
};

export const deleteAppsConnected = (
  provider: types.SocialAuthProvider,
): types.DeleteAppsConnectedAction => {
  return apiCallAction(types.ServiceMethod.DELETE_APPS_CONNECTED, {
    provider,
  } as types.DeleteAppsConnectedArgs);
};

export const getYoutubePlaylists = (
  providerUserId: string,
  accessToken: string,
): types.GetYoutubePlaylistsAction => {
  return apiCallAction(types.ServiceMethod.GET_YOUTUBE_PLAYLISTS, {
    providerUserId,
    accessToken,
  } as types.GetYoutubePlaylistsArgs);
};

export function createYoutubePlaylist(
  args: types.CreateYoutubePlaylistArgs,
): types.CreateYoutubePlaylistAction {
  return apiCallAction(types.ServiceMethod.CREATE_YOUTUBE_PLAYLIST, args);
}

export const getTikTokCreatorInfoAction = (
  providerUserId: string,
  accessToken: string,
): types.GetTikTokCreatorInfoAction => {
  return apiCallAction(types.ServiceMethod.GET_TIKTOK_CREATOR_INFO, {
    providerUserId,
    accessToken,
  } as types.GetTikTokCreatorInfoArgs);
};

export const getInstagramAccountInfoAction = (
  providerUserId: string | number,
  accessToken: string,
  instagramId: string | number,
): types.GetInstagramAccountInfoAction => {
  return apiCallAction(types.ServiceMethod.GET_INSTAGRAM_ACCOUNT_INFO, {
    providerUserId,
    accessToken,
    instagramId,
  } as types.GetInstagramAccountInfoArgs);
};
