import * as React from 'react';

import AddTrackButton from '../containers/AddTrackButton';
import { block } from '../utils';

export interface TimelineHeaderProps {
  children?: React.ReactNode;
  onAddTrackClick?: () => void;
}

const TimelineHeader: React.SFC<TimelineHeaderProps> = ({
  children,
  onAddTrackClick,
}) => (
  <div className={block('header')}>
    <div className={block('header-spacer')}>
      <AddTrackButton onClick={onAddTrackClick} />
    </div>
    {children}
  </div>
);

export default TimelineHeader;
