import bem from 'utils/bem';

export const block = bem('font-size-input-v2');

export const SIZE_OPTIONS = [
  8,
  9,
  10,
  11,
  12,
  14,
  18,
  24,
  30,
  36,
  48,
  60,
  72,
  96,
];

export const MIN_SIZE = 1;
export const MAX_SIZE = 96;
