import * as React from 'react';
import _ from 'underscore';

import HiddenAudioPlayer from 'components/HiddenAudio';
import LoadingOverlay from 'components/LoadingOverlay';
import Waves from 'components/Waves';
import dayjs from 'utils/dayjs';
import { formatDurationMillis } from 'utils/time';
import AudioLibraryTile from './AudioLibraryTile';
import { IAudioTileProps, IAudioTileState } from './types';

class AudioTile extends React.Component<IAudioTileProps, IAudioTileState> {
  public static defaultProps: Partial<IAudioTileProps> = {
    onPreview: _.noop,
  };

  public state: IAudioTileState = {
    isLoading: false,
    isPlaying: false,
  };

  private playTimeoutId: number;
  private willUnmount: boolean = false;

  public componentDidUpdate(
    prevProps: Readonly<IAudioTileProps>,
    prevState: Readonly<IAudioTileState>,
  ) {
    const { isParentScrolling } = this.props;
    const { isParentScrolling: prevIsParentScrolling } = prevProps;
    const { isPlaying } = prevState;

    if (
      isParentScrolling &&
      !prevIsParentScrolling &&
      isPlaying &&
      !this.willUnmount
    ) {
      this.handleMouseLeave();
    }
  }

  public componentWillUnmount() {
    this.willUnmount = true;
    this.clearTimeout();
  }

  private clearTimeout = () => {
    if (!_.isUndefined(this.playTimeoutId)) {
      window.clearTimeout(this.playTimeoutId);
      this.playTimeoutId = undefined;
    }
  };

  private handleMouseEnter = () => {
    this.playTimeoutId = window.setTimeout(
      () => this.setState({ isPlaying: true }),
      300,
    );
  };

  private handleMouseLeave = () => {
    this.clearTimeout();
    this.setState({ isPlaying: false, isLoading: false });
  };

  private handleAudioLoad = () => {
    this.setState({ isLoading: true });
  };

  private handleAudioPlay = () => {
    const { audio, onPreview } = this.props;
    this.setState({ isLoading: false });
    onPreview(audio);
  };

  private handleClick = () => {
    const { audio, onSelect, disabled } = this.props;
    !disabled && onSelect(audio);
  };

  public render() {
    const { audio, hideDuration, selected } = this.props;
    const { isLoading, isPlaying } = this.state;
    return (
      <AudioLibraryTile
        className="audio-tile"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleClick}
        selected={selected}
      >
        <div className="audio-tile__container">
          {isLoading && (
            <LoadingOverlay className="audio-tile__loading-overlay" />
          )}
          {!isLoading && isPlaying && <Waves />}
          <HiddenAudioPlayer
            src={audio.get('audioUrl')}
            isPlaying={isPlaying}
            onLoad={this.handleAudioLoad}
            onPlay={this.handleAudioPlay}
          />
          <div className="audio-tile__details">
            <span className="audio-tile__title">{audio.get('title')}</span>
            {audio.getIn(['genre', 'name']) && (
              <span className="audio-tile__genre">
                {audio.getIn(['genre', 'name'])}
              </span>
            )}
            {!hideDuration && (
              <div className="audio-tile__duration">
                <span>
                  {formatDurationMillis(audio.get('durationMillis'), {
                    hour: dayjs.duration(audio.get('durationMillis')).hours()
                      ? 'numeric'
                      : undefined,
                    minute: '2-digit',
                    second: '2-digit',
                    trim: false,
                  })}
                </span>
              </div>
            )}
          </div>
        </div>
      </AudioLibraryTile>
    );
  }
}

export default AudioTile;
