// TODO SPAR-13155 move to containers?
import cn from 'classnames';
import * as React from 'react';

import { H3 } from 'components/Heading';
import { PlanName } from 'redux/middleware/api/plan-service';
import bem from 'utils/bem';
import { SubscriptionPeriod } from '../types';
import usePlanCost from '../usePlanCost';

type PlanOptions = Record<SubscriptionPeriod, PlanName>;

export interface PlanCostProps {
  className?: string;
  density?: 'comfortable' | 'sparse';
  description?: string;
  period?: SubscriptionPeriod;
  cost: PlanOptions | string;
  title: React.ReactNode;
  theme?:
    | 'free' // all elements pink
    | 'basic' // all elements are the "basic" color
    | 'pro' // all elements are the "pro" color
    | 'enterprise' // all elements are green
    | 'pro-upsell' // gradient title, white price, gray footnote
    | 'basic-upsell' // "basic" title, white price, "basic" footnote
    | 'muted' // all elements are gray
    | 'unlimited';
}

const block = bem('plan-cost');

const PlanCost: React.FC<PlanCostProps> = ({
  className,
  period,
  cost,
  density = 'sparse',
  description,
  theme,
  title,
}) => {
  const customCost = typeof cost === 'string' ? cost : undefined;
  const monthlyPlan = typeof cost === 'string' ? undefined : cost.monthly;
  const yearlyPlan = typeof cost === 'string' ? undefined : cost.yearly;

  const monthlyCost = usePlanCost(monthlyPlan, 'monthly');
  const yearlyCost = usePlanCost(yearlyPlan, 'monthly');

  return (
    <div className={cn(block({ [theme]: !!theme }), className)}>
      <H3 className={block('title')}>{title}</H3>
      {description && (
        <p className={block('description', { [density]: true })}>
          {description}
        </p>
      )}
      <div className={block('cost')}>
        <span className={block('amount')}>
          {customCost ||
            (period === 'monthly' ? `${monthlyCost}/mo` : `${yearlyCost}/mo`)}
        </span>
        {!customCost && period === 'yearly' && (
          <span className={block('footnote')}>BILLED YEARLY</span>
        )}
      </div>
    </div>
  );
};

export default PlanCost;
