import cn from 'classnames';
import * as React from 'react';
import { isString } from 'underscore';

import bem from 'utils/bem';

export interface SwitchAccountMessageProps {
  className?: string;
  username: string | JSX.Element;
}

const block = bem('switch-account-message');

const SwitchAccountMessage: React.SFC<SwitchAccountMessageProps> = ({
  className,
  username,
}) => (
  <span className={cn(block(), className)}>
    {!username ? null : (
      <>
        Log out of{' '}
        {isString(username) ? (
          <span className={block('username')}>{username}</span>
        ) : (
          React.cloneElement(username, {
            className: cn(block('username'), username.props.className),
          })
        )}{' '}
        and into another account?
      </>
    )}
  </span>
);

export default SwitchAccountMessage;
