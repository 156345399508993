import cn from 'classnames';
import * as React from 'react';
import uncontrollable from 'uncontrollable';

import RadioOptions, { Option } from 'components/RadioOptions';
import { AspectRatioName } from 'types';
import { ASPECT_RATIOS } from 'utils/aspect-ratio';
import AspectRatioOption, { AspectRatioOptionProps } from './AspectRatioOption';
import { AspectRatio, Size } from './types';
import { block } from './utils';

type Value = AspectRatio | AspectRatioName;

export interface IProps extends Pick<AspectRatioOptionProps, 'onSelect'> {
  className?: string;
  /**
   * supports both aspect ratio values as an Immutable map of height and width OR an aspect ratio
   * name, e.g. 'landscape', 'square', 'portrait'. supporting string values is mostly for legacy
   * and support might be removed in the future.
   */
  value?: Value;
  /**
   * size of the aspect ratio selector.  when "small", will not render the footer (i.e. title,
   * description)
   */
  size?: Size;
}

function isValueName(value: Value): value is AspectRatioName {
  return typeof value === 'string';
}

interface AspectRatioOptionType extends Option {
  description: string;
  label: AspectRatioName;
  value: Value;
}

const OPTIONS = [
  {
    description: 'Best for Youtube and websites',
    id: 'landscape',
    label: 'landscape' as const,
    value: ASPECT_RATIOS.get('landscape'),
  },
  {
    description: 'Best for Twitter, Instagram, and Facebook',
    id: 'square',
    label: 'square' as const,
    value: ASPECT_RATIOS.get('square'),
  },
  {
    description: 'Best for Instagram Stories, Snapchat, and TikTok',
    id: 'portrait',
    label: 'portrait' as const,
    value: ASPECT_RATIOS.get('portrait'),
  },
];

function getValueDimensions(value: Value) {
  return isValueName(value) ? ASPECT_RATIOS.get(value) : value;
}

const AspectRatioSelector: React.SFC<IProps> = ({
  className,
  onSelect,
  value,
  size,
}) => (
  <RadioOptions<AspectRatioOptionType>
    className={cn(block({ small: size === 'small' }), className)}
    options={OPTIONS}
    overrides={{
      option: ({ option, selected }) => (
        <AspectRatioOption
          description={option.description}
          name={option.label}
          onSelect={onSelect}
          key={option.label}
          selected={selected}
          size={size}
          value={getValueDimensions(option.value)}
        />
      ),
      optionList: ({ className: listClassName, ...props }) => (
        <div className={cn(listClassName, block('list'))} {...props} />
      ),
    }}
    value={getValueDimensions(value)}
  />
);

AspectRatioSelector.defaultProps = {
  size: 'large',
};

export type AspectRatioSelectorProps = IProps & {
  defaultValue?: IProps['value'];
};

const UncontrolledAspectRatioSelector: React.SFC<AspectRatioSelectorProps> = uncontrollable(
  AspectRatioSelector,
  { value: 'onSelect' },
);

export { AspectRatioSelector };
export default UncontrolledAspectRatioSelector;
