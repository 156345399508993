import * as React from 'react';

function PauseCircle(props) {
  return (
    <svg width={47} height={47} viewBox="0 0 50 50" {...props}>
      <g fill="none" fillRule="evenodd">
        <rect
          width={48}
          height={48}
          x={1}
          y={1}
          stroke="#DCE1EB"
          strokeWidth={2}
          rx={24}
        />
        <path
          fill="#DCE1EB"
          fillRule="nonzero"
          d="M22.221 34h-3.934c-1.086 0-1.967-.864-1.967-1.929V17.93c0-1.065.881-1.929 1.967-1.929h3.934c1.086 0 1.968.864 1.968 1.929v14.14c0 1.065-.882 1.929-1.968 1.929zm12.459-1.929V17.93c0-1.065-.881-1.929-1.967-1.929h-3.934c-1.086 0-1.968.864-1.968 1.929v14.14c0 1.065.882 1.929 1.968 1.929h3.934c1.086 0 1.967-.864 1.967-1.929z"
        />
      </g>
    </svg>
  );
}

export default PauseCircle;
