import {
  AuthPageLayout,
  AuthProvider,
  ResetPasswordCard,
} from '@sparemin/auth';
import * as React from 'react';
import { Route, Switch, useLocation } from 'react-router';

import OnboardingWizard from 'blocks/OnboardingWizard';
import {
  userIsNotAuthenticated,
  userIsNotOnboarded,
  userIsNotSignedUp,
} from 'components/hoc/auth';
import Page from 'components/Page';
import { AUTH_PAGE_ROUTES } from 'utils/routes';
import AuthProviderLink from './AuthProviderLink';
import LoginCard from './components/LoginCard';
import OnboardingDialogWrapper from './components/OnboardingDialogWrapper';
import RegistrationCard from './containers/RegistrationCard';
import useRegistrationQuery from './hooks/useRegistrationQuery';

const LoginComponent = userIsNotAuthenticated(LoginCard);
const RegistrationComponent = userIsNotSignedUp(RegistrationCard);
const ResetPasswordComponent = userIsNotAuthenticated(ResetPasswordCard);
const OnboardingComponent = userIsNotOnboarded(OnboardingWizard);

const AuthRoutes: React.FC = () => {
  const { promoKey, referralKey, traceId } = useRegistrationQuery();
  const location = useLocation();

  return (
    <AuthProvider
      navigation={{
        linkComponent: AuthProviderLink,
        routes: {
          login: AUTH_PAGE_ROUTES.login,
          'sign-up': AUTH_PAGE_ROUTES.signup,
        },
      }}
      promotionKey={promoKey}
      {...{ referralKey, traceId }}
    >
      <Page>
        <AuthPageLayout
          hideHeadlinerLogo={location.pathname === AUTH_PAGE_ROUTES.onboarding}
        >
          <Switch>
            <Route
              exact
              path={AUTH_PAGE_ROUTES.login}
              component={LoginComponent}
            />
            <Route
              exact
              path={AUTH_PAGE_ROUTES.signup}
              component={RegistrationComponent}
            />
            <Route
              exact
              path={AUTH_PAGE_ROUTES.resetPassword}
              component={ResetPasswordComponent}
            />
            <Route
              path={AUTH_PAGE_ROUTES.onboarding}
              render={props => (
                <OnboardingDialogWrapper>
                  <OnboardingComponent {...props} />
                </OnboardingDialogWrapper>
              )}
            />
          </Switch>
        </AuthPageLayout>
      </Page>
    </AuthProvider>
  );
};

export default AuthRoutes;
