import cn from 'classnames';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import Link, { LinkProps } from 'components/Link';
import { block } from '../utils';

interface IProps extends Pick<LinkProps, 'onClick'> {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  projectId: string;
}

const ProjectTileLink = (props: IProps) => {
  const { className, disabled, projectId, children, onClick, ...rest } = props;
  const classes = cn(block('link', { disabled }), className);
  const location = useLocation();

  if (disabled) {
    return (
      <div className={classes} {...rest}>
        {children}
      </div>
    );
  }

  return (
    <Link
      className={classes}
      to={{
        pathname: `/project/${projectId}`,
        query: {
          source: location.pathname,
        },
      }}
      {...{ children, onClick }}
      {...rest}
    />
  );
};

export default ProjectTileLink;
