import { createSelector } from 'reselect';
import { State } from 'redux/types';

import { textPresetsSelector } from '../entities';
import { ImmutableTextPreset, TextPresetsState } from './types';

const stateSelector = (state: State): TextPresetsState =>
  state.get('textPresets');

export const textPresetIdsSelector = createSelector(
  stateSelector,
  state => state.get('presets')?.toJS() ?? [],
);

export const textPresetSelector = createSelector(
  [textPresetsSelector],
  presets => presets,
);

export const makePresetSelector = (presetId: string) =>
  createSelector(
    textPresetSelector,
    (presets): ImmutableTextPreset => presets?.get(presetId),
  );
