import * as React from 'react';

import { WaveformProps } from '../../types';

const LinearDots = React.forwardRef<SVGSVGElement, WaveformProps>(
  (props, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 173 29"
      ref={ref}
      {...props}
    >
      <path d=" M 13.503058512396695 14.4 A 5.64851305785124 5.64851305785124 0 1 1 13.503055688140401 14.394351487883565 L 28.956805289256202 14.4 A 5.393168925619834 5.393168925619834 0 1 1 28.956802592671963 14.394606831973238 L 44.24717752066117 14.4 A 4.974450247933884 4.974450247933884 0 1 1 44.24717503343625 14.395025550581138 L 60.57349289256199 14.4 A 5.591674710743802 5.591674710743802 0 1 1 60.57349009672486 14.3944083262212 L 75.84053950413224 14.4 A 5.149630413223141 5.149630413223141 0 1 1 75.84053692931725 14.394850370445045 L 90.98115173553721 14.4 A 4.5811517355371905 4.5811517355371905 0 1 1 90.98114944496153 14.395418849027985 L 107.44037553719009 14.4 A 5.331284628099174 5.331284628099174 0 1 1 107.440372871548 14.394668716260446 L 122.9059279338843 14.4 A 5.08774611570248 5.08774611570248 0 1 1 122.90592539001146 14.394912254732253 L 139.7193203305785 14.4 A 6.192047603305786 6.192047603305786 0 1 1 139.71931723455498 14.393807953428698 L 154.3079246280992 14.4 A 5.071560991735538 5.071560991735538 0 1 1 154.3079220923189 14.394928439853523 L 169.84345388429753 14.4 A 4.8979993388429754 4.8979993388429754 0 1 1 169.84345143529808 14.395102001477488" />
    </svg>
  ),
);

export default LinearDots;
