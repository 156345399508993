import { Color } from '@sparemin/blockhead';
import React from 'react';
import ColorPicker from 'components/ColorPicker';

import TextShadowControl from './TextShadowControl';

import { block } from './utils';

interface TextShadowColorPickerProps {
  onChange: (color: string) => void;
  value: string;
}

const TextShadowColorPicker: React.FunctionComponent<TextShadowColorPickerProps> = props => {
  const { onChange, value } = props;

  return (
    <TextShadowControl icon={<Color />} label="Color" layout="horizontal">
      <ColorPicker
        color={value}
        onChange={onChange}
        popoverStyle={{
          top: undefined,
          right: 90,
        }}
        swatchClassName={block('color-picker')}
      />
    </TextShadowControl>
  );
};

export default TextShadowColorPicker;
