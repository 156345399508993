import * as React from 'react';

import { IconProps } from './types';

const Crop: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M33.067 5.333c.883 0 1.6.717 1.6 1.6l-.001 22.4H38.4c.884 0 1.6.717 1.6 1.6v2.134c0 .883-.716 1.6-1.6 1.6l-3.734-.001V38.4c0 .884-.716 1.6-1.6 1.6h-2.133c-.883 0-1.6-.716-1.6-1.6V10.666H14.667V5.334h18.4zM6.933 34.667c-.883 0-1.6-.717-1.6-1.6V10.666H1.6c-.884 0-1.6-.716-1.6-1.6V6.934c0-.883.716-1.6 1.6-1.6h3.733V1.6c0-.884.717-1.6 1.6-1.6h2.134c.883 0 1.6.716 1.6 1.6l-.001 27.733h14.667v5.334h-18.4z"
    />
  </svg>
);

export default Crop;
