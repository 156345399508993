import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import useLayerOrder from 'components/VideoTemplateEditor/useLayerOrder';
import LayerOrderControlsButton from './LayerOrderControlsButton';
import { block } from './utils';

const LayerOrderControls: React.FC = () => {
  const { getLayerControlButtonsSettings } = useLayerOrder();

  return (
    <Spacer
      as="div"
      orientation="vertical"
      align="center"
      justify="center"
      className={block()}
    >
      <LayerOrderControlsButton {...getLayerControlButtonsSettings('front')} />
      <LayerOrderControlsButton
        {...getLayerControlButtonsSettings('forward')}
      />
      <LayerOrderControlsButton
        {...getLayerControlButtonsSettings('backward')}
      />
      <LayerOrderControlsButton {...getLayerControlButtonsSettings('back')} />
    </Spacer>
  );
};

export default LayerOrderControls;
