import * as React from 'react';

function Robot(props) {
  return (
    <svg width={88} height={70} viewBox="0 0 88 70" {...props}>
      <path
        fill="#282e37"
        d="M4.4 30.625h4.4v26.25H4.4a4.415 4.415 0 01-3.113-1.28A4.363 4.363 0 010 52.5V35a4.362 4.362 0 011.287-3.095 4.415 4.415 0 013.113-1.28zm70.4-6.563V61.25c-.005 4.83-3.942 8.745-8.8 8.75H22c-4.858-.005-8.795-3.92-8.8-8.75V24.062c0-2.9 1.158-5.683 3.22-7.735a11.036 11.036 0 017.78-3.202h15.4v-8.75C39.6 1.959 41.57 0 44 0c2.43 0 4.4 1.959 4.4 4.375v8.75h15.4c2.918 0 5.716 1.151 7.78 3.202a10.912 10.912 0 013.22 7.735zM36.3 35c0-3.02-2.462-5.469-5.5-5.469S25.3 31.98 25.3 35c0 3.02 2.462 5.469 5.5 5.469a5.517 5.517 0 003.89-1.602A5.46 5.46 0 0036.3 35zm-1.1 17.5h-8.8v4.375h8.8V52.5zm13.2 0h-8.8v4.375h8.8V52.5zM62.7 35c0-3.02-2.462-5.469-5.5-5.469S51.7 31.98 51.7 35c0 3.02 2.462 5.469 5.5 5.469a5.517 5.517 0 003.89-1.602A5.46 5.46 0 0062.7 35zm-1.1 17.5h-8.8v4.375h8.8V52.5zM88 35v17.5a4.362 4.362 0 01-1.287 3.095 4.415 4.415 0 01-3.113 1.28h-4.4v-26.25h4.4a4.415 4.415 0 013.113 1.28A4.363 4.363 0 0188 35z"
      />
    </svg>
  );
}

export default Robot;
