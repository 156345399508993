import cn from 'classnames';
import * as React from 'react';

import PlanCost, { PlanCostProps } from 'blocks/Pricing/components/PlanCost';
import { block } from './utils';

type PickedCostProps = Pick<
  PlanCostProps,
  'cost' | 'density' | 'description' | 'period' | 'title' | 'theme'
>;

export interface PlanHeaderProps extends PickedCostProps {
  className?: string;
  icon: React.ReactNode;
}

const PlanHeader: React.SFC<PlanHeaderProps> = ({
  className,
  cost,
  density,
  description,
  icon,
  period,
  theme,
  title,
}) => (
  <div className={cn(block('header'), className)}>
    <div className={block('header-icon-container')}>
      {React.isValidElement(icon) &&
        React.cloneElement(icon, {
          className: cn(icon.props.className, block('header-icon')),
        })}
    </div>
    <PlanCost {...{ cost, density, description, period, theme, title }} />
  </div>
);

export default PlanHeader;
