import React from 'react';
import { throttle } from 'underscore';

import { unscaleCanvasSize } from '../../style-transform-utils';
import { EditorDataV2, Position, Rect, Size } from '../../types';
import { BoxSizeFitChecker, BoxSizeFitCheckerConfig } from './types';
import {
  getBoxMaxLines,
  shouldDisableSubmitButton,
  shouldShowRechunkAlert,
} from './utils';

interface UseBoxSizeFitCheckerConfig {
  baseTextValue: string;
  canvasSize: Size;
  draftEditorData: EditorDataV2;
  extensionConfig: BoxSizeFitCheckerConfig;
  initialEditorData: EditorDataV2;
}

type UseBoxSizeFitChecker = BoxSizeFitChecker;

const BASE_RESIZE_DELTA: Size = { height: 0, width: 0 };
const RESIZE_THROTLE_MS = 50;

const useBoxSizeFitChecker = (
  config: UseBoxSizeFitCheckerConfig,
): UseBoxSizeFitChecker => {
  const {
    baseTextValue,
    canvasSize,
    draftEditorData,
    extensionConfig = {},
    initialEditorData,
  } = config;

  const {
    enabled = false,
    onTextRangeChange,
    onToggleRechunkRequired,
    rechunkAlert,
    submitDisabledOnNoFit,
  } = extensionConfig;

  const [resizeDelta, setResizeDelta] = React.useState(BASE_RESIZE_DELTA);

  // As resize actions are emitted repeadly and fast, the ref is necessary for
  // making immediate checks and avoiding race conditions when the resize
  // actions start and end.
  const isResizingRef = React.useRef(false);

  // This action is throttled for increasing performance of the modal.
  // Calculating the resize delta allows a "live" feedback of the text fit calculation
  // while dragging. If the extension is disabled, the calculation is not done
  // at all.
  const handleTextBoxResize = React.useMemo(
    () =>
      throttle((_: Position, delta: Size): void => {
        if (enabled && isResizingRef.current && draftEditorData) {
          setResizeDelta(unscaleCanvasSize(delta, draftEditorData, canvasSize));
        }
      }, RESIZE_THROTLE_MS),
    [canvasSize, enabled, draftEditorData],
  );

  // Composes the resize end function for being able to restore the resize
  // delta to the initial value and clearing the isResizing flag
  const handleTextBoxResizeEnd = React.useCallback(
    (onResizeEnd: (rect: Rect) => void) => (rect: Rect): void => {
      onResizeEnd(rect);
      setResizeDelta(BASE_RESIZE_DELTA);
      isResizingRef.current = false;
    },
    [],
  );

  // Composes the resize start function for being able to enable the isResizing
  // flag when resizing starts.
  const handleTextBoxResizeStart = React.useCallback(
    (onResizeStart: () => void) => (): void => {
      onResizeStart();
      isResizingRef.current = true;
    },
    [],
  );

  const textLinesRange: [number, number] = React.useMemo(() => {
    if (!enabled) {
      return [0, 0];
    }

    if (!baseTextValue) {
      return [0, 0];
    }

    return getBoxMaxLines(baseTextValue, draftEditorData, resizeDelta);
  }, [baseTextValue, draftEditorData, enabled, resizeDelta]);

  // When the extension is disabled the base text value is not modified.
  const textValue = React.useMemo(
    () =>
      // it is required to trim the value as the util that parses the chars will
      // keep the trailing spaces making it to be considered as a space by the static
      // text preview.
      enabled
        ? baseTextValue.substring(...textLinesRange).trim()
        : baseTextValue,
    [baseTextValue, enabled, textLinesRange],
  );

  const { submitDisabled, submitDisabledMessage } = React.useMemo(() => {
    const disabled = shouldDisableSubmitButton(
      textLinesRange,
      submitDisabledOnNoFit?.enabled,
    );
    const message = disabled ? submitDisabledOnNoFit?.message : undefined;

    return {
      submitDisabled: disabled,
      submitDisabledMessage: message,
    };
  }, [submitDisabledOnNoFit, textLinesRange]);

  const { showRechunkAlert, rechunkAlertMessage } = React.useMemo(() => {
    const show = shouldShowRechunkAlert(
      initialEditorData,
      draftEditorData,
      rechunkAlert?.enabled,
    );
    const message = show ? rechunkAlert?.message : undefined;

    return {
      showRechunkAlert: show,
      rechunkAlertMessage: message,
    };
  }, [draftEditorData, initialEditorData, rechunkAlert]);

  React.useEffect(() => {
    onTextRangeChange?.(textLinesRange);
  }, [onTextRangeChange, textLinesRange]);

  React.useEffect(() => {
    onToggleRechunkRequired?.(showRechunkAlert);
  }, [onToggleRechunkRequired, showRechunkAlert]);

  return {
    onTextBoxResize: enabled ? handleTextBoxResize : undefined,
    onTextBoxResizeEnd: handleTextBoxResizeEnd,
    onTextBoxResizeStart: handleTextBoxResizeStart,
    rechunkAlertMessage,
    showRechunkAlert,
    submitDisabled,
    submitDisabledMessage,
    textBoxClassModifier: { 'size-checker-enabled': enabled },
    textValue,
  };
};

export default useBoxSizeFitChecker;
