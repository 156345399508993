import { actions as userServiceActions } from 'redux/middleware/api/user-service';
import { myPreferencesSelector } from 'redux/selectors';
import { ThunkAction } from 'redux/types';
import { UpdateEmailPreferences } from './types';

export const getMyPreferences = (
  force: boolean = false,
): ThunkAction<Promise<any>> => (dispatch, getState) => {
  const userPreferences = myPreferencesSelector(getState());
  if (!userPreferences || force) {
    return dispatch(userServiceActions.getMyPreferences())
      .then(() => myPreferencesSelector(getState()))
      .catch(() => Promise.resolve());
  }

  return Promise.resolve(userPreferences);
};

export const updateMyPreferences = ({
  videoExportEmailEnabled,
  marketingEmailEnabled,
  automationEmailAlertEnabled,
  reminderEmailEnabled,
}: UpdateEmailPreferences): ThunkAction<Promise<any>> => dispatch =>
  dispatch(
    userServiceActions.updateUserPreferences(
      videoExportEmailEnabled,
      marketingEmailEnabled,
      automationEmailAlertEnabled,
      reminderEmailEnabled,
    ),
  ).then(() => dispatch(getMyPreferences(true)));
