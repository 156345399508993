import * as compareVersions from 'compare-versions';
import {
  browserName,
  browserVersion,
  isMobile,
  isSafari,
} from 'react-device-detect';

const SUPPORTED_MOBILE_BROWSERS = [
  'Chrome',
  'Firefox',
  'Mobile Safari',
  'Safari',
];

function isMobileBrowserSupported() {
  return SUPPORTED_MOBILE_BROWSERS.indexOf(browserName) >= 0;
}

export function isBrowserSupported() {
  if (isMobile) {
    return isMobileBrowserSupported();
  }

  switch (browserName) {
    case 'Chrome':
      return compareVersions(browserVersion, '50.0.0') >= 0;

    case 'Firefox':
      return compareVersions(browserVersion, '55.0.0') >= 0;

    case 'Safari':
      return compareVersions(browserVersion, '11.0.0') >= 0;

    default:
      return false;
  }
}

export { isMobile, isSafari };
