import * as React from 'react';

import { TEXT_SHADOW_EM_MULTIPLIER } from 'utils/embed/ui';
import { round } from 'utils/numbers';
import TextBoxColorField from '../TextBoxColorField';
import TextShadowNumericField, {
  TextShadowNumericFieldProps,
} from './TextShadowNumericField';

export interface ITextShadow {
  x?: number;
  y?: number;
  blur?: number;
  color?: string;
}

interface IProps {
  onChange?: (shadow: ITextShadow) => void;
  value?: ITextShadow;
}

export default class TextBoxTextShadowField extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    value: {} as ITextShadow,
  };

  private handleTextShadowInputChange: TextShadowNumericFieldProps['onChange'] = (
    val,
    type,
  ) => {
    switch (type) {
      case 'x':
      case 'y':
        this.fireOnChange({
          [type]: val === '' ? '' : val * TEXT_SHADOW_EM_MULTIPLIER,
        });
        break;

      default:
        this.fireOnChange({ [type]: val });
    }
  };

  private handleColorChange = (color: string) => this.fireOnChange({ color });

  private fireOnChange(overrides: Partial<ITextShadow>) {
    const { onChange, value } = this.props;
    onChange({ ...value, ...overrides });
  }

  public render() {
    const { value } = this.props;

    return (
      <div>
        <TextShadowNumericField
          label="Text Shadow X"
          value={round(value.x / TEXT_SHADOW_EM_MULTIPLIER)}
          onChange={this.handleTextShadowInputChange}
          args="x"
        />
        <TextShadowNumericField
          label="Text Shadow Y"
          value={round(value.y / TEXT_SHADOW_EM_MULTIPLIER)}
          onChange={this.handleTextShadowInputChange}
          args="y"
        />
        <TextShadowNumericField
          label="Text Shadow Blur"
          value={value.blur}
          onChange={this.handleTextShadowInputChange}
          min={0}
          max={99}
          args="blur"
        />
        <TextBoxColorField
          label="Text Shadow Color"
          value={value.color}
          onChange={this.handleColorChange}
        />
      </div>
    );
  }
}

export { IProps as TextShadowOptionsProps };
