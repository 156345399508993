import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import CircularGradient, { CircularGradientProps } from './CircularGradient';

export type CircularGradientExclamationProps = Pick<
  CircularGradientProps,
  'className'
>;

const CircularGradientExclamation: React.FC<CircularGradientExclamationProps> = ({
  className,
}) => (
  <CircularGradient className={className}>
    <FontAwesome icon="exclamation" style={{ height: '44%', width: '44%' }} />
  </CircularGradient>
);

export default CircularGradientExclamation;
