import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import SaveAsTemplateIntroModalContent from './SaveAsTemplateIntroModalContent';

function SaveAsTemplateIntroModal() {
  return (
    <ConnectedModal name="SaveAsTemplateIntro" title="save as a template">
      <SaveAsTemplateIntroModalContent />
    </ConnectedModal>
  );
}

export default SaveAsTemplateIntroModal;
