import * as Immutable from 'immutable';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  ModalBody,
  ModalFooter,
  ModalFooterButton,
  ModalFooterButtons,
} from 'components/Modal';
import { ConnectedModalInjectedProps } from 'containers/ConnectedModal/ConnectedModalComponent';
import {
  defaultInTransitionSelector,
  defaultOutTransitionSelector,
  textTemplateHideOptionSelector,
  textTemplateOverrideIdsSelector,
} from 'redux/modules/display-pref/selectors';
import { applyTemplateToTextTrack } from 'redux/modules/embed/actions';
import {
  aspectRatioSelector,
  globalStyleTrackIdSelector,
  lastGlobalTextOverlayTemplateSelector,
  textTemplatesSelector,
} from 'redux/modules/embed/selectors';
import { fontsSelector } from 'redux/selectors';
import { Dispatch } from 'redux/types';
import { lastUsedStyle } from 'utils/text-templates';
import { getFilteredTemplates } from 'utils/text-templates/utils';
import {
  GlobalStyleFont,
  GlobalStyleModalBody,
  GlobalTextStyle,
  GlobalTextTemplate,
} from '../components/GlobalStyleModalBody';
import WarningLabel from './GlobalStyleModal/WarningLabel';

const { useCallback, useRef } = React;

export interface GlobalStyleModalContentsProps {
  aspectRatio?: number;
  backgroundColor?: string;
  backgroundImage?: string;
  defaultInTransitionName?: string;
  defaultOutTransitionName?: string;
  defaultTemplate?: GlobalTextStyle;
  fonts?: GlobalStyleFont[];
  lastUsedTextStyle?: GlobalTextStyle;
  templates?: GlobalTextTemplate[];
  trackId?: string;
  className?: string;
  onSave?: (template: any, trackId: string) => void;
  show?: boolean;
}

const lastUsedTextStyleSelector = createSelector(
  lastGlobalTextOverlayTemplateSelector,
  lastUsedTemplate => Immutable.fromJS(lastUsedTemplate),
);

const lastUsedTemplateSelector = createSelector(
  lastGlobalTextOverlayTemplateSelector,
  lastUsedTemplate =>
    lastUsedTemplate && {
      displayName: lastUsedStyle.ui.displayName,
      id: 'lastUsedStyle',
      imageUrl: lastUsedStyle.ui.imageUrl,
      template: Immutable.fromJS(lastUsedTemplate),
      templateId: lastUsedTemplate.templateId,
    },
);

const templatesSelector = createSelector(
  [textTemplatesSelector, lastUsedTemplateSelector],
  (textTemplates, lastUsedTemplate) => {
    if (!textTemplates) return undefined;

    const formattedTemplates = textTemplates.reduce((acc, template) => {
      const styleTemplate = template.template;

      acc.push({
        ...template,
        template: styleTemplate.withMutations(st => {
          st.set('style', st.get('containerStyle'));

          if (st.getIn(['textStyle', 'background'])) {
            st.setIn(
              ['style', 'textHighlight'],
              st.getIn(['textStyle', 'background']),
            );
          }

          return st;
        }),
        templateId: template.id,
      });

      return acc;
    }, []);

    if (lastUsedTemplate) {
      lastUsedTemplate.templateId = lastUsedTemplate.template.get('templateId');
    }

    // lastUsedTemplate will be undefined if no overlays added yet, so filter out if necessary
    return [lastUsedTemplate, ...formattedTemplates].filter(Boolean);
  },
);

const filteredTemplatesSelector = createSelector(
  [
    templatesSelector,
    textTemplateOverrideIdsSelector,
    textTemplateHideOptionSelector,
  ],
  (templates, overrideIds, hideOptionIds) =>
    getFilteredTemplates(templates, overrideIds, hideOptionIds),
);

const textOverlayPreferenceSelector = createSelector(
  [defaultInTransitionSelector, defaultOutTransitionSelector],
  (transitionIn = 'cut', transitionOut = 'cut') =>
    Immutable.fromJS({
      transitions: {
        in: { value: transitionIn },
        out: { value: transitionOut },
      },
    }),
);

const defaultTemplateSelector = createSelector(
  [templatesSelector, textOverlayPreferenceSelector],
  (templates, preferenceTemplate) => {
    if (!templates) return preferenceTemplate;
    const defaultTemplate = templates.find(({ id }) => id === 'bbc');
    return !defaultTemplate
      ? preferenceTemplate
      : defaultTemplate.template.mergeDeep(preferenceTemplate);
  },
);

const GlobalStyleModalContents: React.FC<GlobalStyleModalContentsProps &
  ConnectedModalInjectedProps> = ({ onHide }) => {
  const dispatch = useDispatch<Dispatch>();

  const aspectRatio = useSelector(aspectRatioSelector);
  const defaultInTransitionName = useSelector(defaultInTransitionSelector);
  const defaultOutTransitionName = useSelector(defaultOutTransitionSelector);
  const defaultTemplate = useSelector(defaultTemplateSelector);
  const fonts = useSelector(fontsSelector);
  const lastUsedTextStyle = useSelector(lastUsedTextStyleSelector);
  const templates = useSelector(filteredTemplatesSelector);
  const trackId = useSelector(globalStyleTrackIdSelector);

  const bodyRef = useRef<GlobalStyleModalBody>();
  const textStyle = lastUsedTextStyle || defaultTemplate;

  const handleSave = useCallback(() => {
    if (!bodyRef.current) return;

    dispatch(
      applyTemplateToTextTrack(trackId, bodyRef.current.textStyle.toJS()),
    );
    onHide();
  }, [dispatch, onHide, trackId]);

  return (
    <ModalBody>
      <GlobalStyleModalBody
        ref={bodyRef}
        aspectRatio={aspectRatio}
        fonts={fonts}
        textTemplates={templates}
        textStyle={textStyle}
        text="Overwrite your text box styles with this style."
        defaultInTransitionName={defaultInTransitionName}
        defaultOutTransitionName={defaultOutTransitionName}
      />
      <ModalFooter>
        <WarningLabel />
        <ModalFooterButtons>
          <ModalFooterButton onClick={onHide}>Cancel</ModalFooterButton>

          <ModalFooterButton theme="submit" onClick={handleSave}>
            Save
          </ModalFooterButton>
        </ModalFooterButtons>
      </ModalFooter>
    </ModalBody>
  );
};

export default GlobalStyleModalContents;
