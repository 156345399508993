import React from 'react';

import { IconProps } from './types';

const Outro: React.FunctionComponent<IconProps> = ({ ...props }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <filter
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        filterUnits="objectBoundingBox"
        id="ntx98vmaqa"
      >
        <feGaussianBlur in="SourceGraphic" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect
        fill="#323746"
        filter="url(#ntx98vmaqa)"
        width="40"
        height="40"
        rx="6"
      />
      <path
        d="M13.933 10a.933.933 0 0 0-.933.933v18.134a.933.933 0 0 0 1.867 0V10.933a.933.933 0 0 0-.934-.933zM27 14.177c0-1.047-1.158-1.57-1.833-.831l-5.319 5.827a1.249 1.249 0 0 0 0 1.659l5.32 5.822c.674.74 1.832.216 1.832-.831z"
        fill="#DCE1EB"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default Outro;
