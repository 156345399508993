import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import UnlimitedTranscriptionModalContent from './UnlimitedTranscriptionModalContent';

function UnlimitedTranscriptionModal() {
  return (
    <ConnectedModal
      name="UnlimitedTranscription"
      title="unlimited transcription"
    >
      <UnlimitedTranscriptionModalContent />
    </ConnectedModal>
  );
}

export default UnlimitedTranscriptionModal;
