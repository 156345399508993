import { connect } from 'react-redux';

import {
  onClickPodcastTab,
  onIncorrectFileType,
} from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal/actions';
import { showError } from 'redux/modules/notification';
import { isBasicOrFreeSelector } from 'redux/modules/pricing';
import { Dispatch, State } from 'redux/types';
import { ApplicationError } from 'utils/ApplicationError';
import { isSupportedVideoFile } from 'utils/formats';
import { createChainedFunction } from 'utils/functions';

import AddAudioStep, { AddAudioStepProps as Props } from './AddAudioStep';

type StateProps = Pick<Props, 'isFreeOrBasicTier'>;
type DispatchProps = Pick<
  Props,
  | 'onError'
  | 'onIncreaseDurationLimit'
  | 'onOfferIncreaseDurationLimit'
  | 'onTabSelect'
>;
interface OwnProps extends Pick<Props, 'maxFileSizeMb' | 'onTabSelect'> {
  onError: (message: ApplicationError, file?: File) => void;
}

const mapStateToProps = (state: State): StateProps => ({
  isFreeOrBasicTier: isBasicOrFreeSelector(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onError: (error, file, type) => {
    if (type === 'episodeTooLarge') {
      dispatch(
        showError(
          'Please try a different episode instead',
          5,
          undefined,
          `Sorry, that episode was over ${props.maxFileSizeMb}MB`,
        ),
      );
    } else if (isSupportedVideoFile(file)) {
      dispatch(onIncorrectFileType('Video'));
      dispatch(
        pushModal({
          name: 'IncorrectFileType',
          params: { file },
        }),
      );
    } else {
      props.onError(error, file);
    }
  },
  onIncreaseDurationLimit: (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(pushModal({ name: 'DurationLimitModal' }));
  },
  onOfferIncreaseDurationLimit: () => {
    dispatch(pushModal({ name: 'DurationLimitErrorModal' }));
  },
  onTabSelect: createChainedFunction(props.onTabSelect, tabKey => {
    if (tabKey === 'search') {
      dispatch(onClickPodcastTab());
    }
  }),
});

const component = connect(mapStateToProps, mapDispatchToProps)(AddAudioStep);

export type AddAudioStepProps = React.ComponentPropsWithoutRef<
  typeof component
>;
export default component;

export { TabKey } from './AddAudioStep';
export { default as useAudioSource } from './useAudioSource';
