interface IData {
  id?: number;
}

export function requestInterval(fn: () => void, delay = 0) {
  let start = Date.now();
  const data: IData = {};

  function loop() {
    data.id = requestAnimationFrame(loop);

    if (Date.now() - start >= delay) {
      fn();
      start = Date.now();
    }
  }

  data.id = requestAnimationFrame(loop);
  return data;
}

export function clearRequestInterval(data: IData) {
  cancelAnimationFrame(data.id);
}

export { IData as RequestIntervalId };
