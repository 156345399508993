import cn from 'classnames';
import memoize from 'memoizee';
import * as React from 'react';

import { formatTimelineMillis } from 'utils/time';

export interface TimelineStatusProps {
  className?: string;

  /**
   * status time to display, in seconds
   */
  position?: number;
  durationSeconds?: number;

  imagePosition?: {
    startMillis: number;
    endMillis: number;
  };

  timeFormat?: string;
}

const formatDuration = memoize(durationSec =>
  formatTimelineMillis(durationSec * 1000),
);

const TimelineStatus: React.SFC<TimelineStatusProps> = ({
  className,
  position,
  durationSeconds,
}) => {
  const formattedPosition = formatTimelineMillis(position * 1000);
  const formattedDuration = formatDuration(durationSeconds);

  const containerClassName = cn({
    'timeline-status': true,
    'timeline-status--std': true,
    [className]: !!className,
  });

  return (
    <div id="timeline-status" className={containerClassName}>
      <div className="timeline-status__position">
        <span id="timeline-position">
          <span className="timeline-status__current">{formattedPosition}</span>{' '}
          /{' '}
          <span
            className={cn('timeline-status__duration', {
              'timeline-status__duration--warning':
                durationSeconds > spareminConfig.maxVideoExportDurationSeconds,
            })}
          >
            {formattedDuration}
          </span>
        </span>
      </div>
    </div>
  );
};

TimelineStatus.defaultProps = {
  durationSeconds: 0,
  position: 0,
};

export default TimelineStatus;
