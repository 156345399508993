import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { addPhrase } from 'redux/modules/embed/actions/captions';
import {
  captionsAddPhraseStatusSelector,
  embedDurationMillisSelector,
  phrasesSelector,
  transcriptOffsetMillisSelector,
} from 'redux/modules/embed/selectors';
import { onAddCaption } from 'redux/modules/mixpanel/actions';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import { clamp } from 'utils/numbers';
import Phrase, { PhraseProps } from '../components/Phrase';

type StateProps = Pick<
  PhraseProps,
  | 'endBoundsMillis'
  | 'endMillis'
  | 'isSaving'
  | 'onPhraseSubmit'
  | 'params'
  | 'phrase'
  | 'phraseDefaultInputMode'
  | 'startBoundsMillis'
  | 'startMillis'
>;
type DispatchProps = Pick<PhraseProps, 'onPhraseSubmit'>;
interface OwnProps {
  previousPhraseId: string;
}

const prevPhraseIdSelector = (_, props: OwnProps) => props.previousPhraseId;

const isAddingPhraseSelector = createSelector(
  captionsAddPhraseStatusSelector,
  status => status === 'adding',
);

const makeMapStateToProps = () => {
  const prevPhraseIndexSelector = createSelector(
    [phrasesSelector, prevPhraseIdSelector],
    (phrases, phraseId) =>
      !phrases || !phraseId
        ? -1
        : phrases.findIndex(p => p.get('id') === phraseId),
  );
  const prevPhraseSelector = createSelector(
    [phrasesSelector, prevPhraseIndexSelector],
    (phrases, index) => (index < 0 ? undefined : phrases.get(index)),
  );

  const phraseTimesSelector = createSelector(
    [
      transcriptOffsetMillisSelector,
      embedDurationMillisSelector,
      phrasesSelector,
      prevPhraseSelector,
      prevPhraseIndexSelector,
    ],
    (offsetMillis, durationMillis, phrases, phrase, index) => {
      if (!phrases || !phrase || index < 0) return 0;

      const prevPhraseEndMillis = phrase.get('endMillis');
      const startMillis = clamp(
        prevPhraseEndMillis + offsetMillis,
        offsetMillis,
        durationMillis,
      );
      const endMillis = clamp(startMillis + 2000, offsetMillis, durationMillis);

      return {
        startMillis,
        endMillis,
      };
    },
  );

  const startMillisSelector = createSelector(phraseTimesSelector, times =>
    !times ? 0 : times.startMillis,
  );

  const endMillisSelector = createSelector(phraseTimesSelector, times =>
    !times ? 0 : times.endMillis,
  );

  const boundsMillisSelector = createSelector(phraseTimesSelector, times =>
    !times
      ? undefined
      : {
          lower: times.startMillis,
          upper: times.endMillis,
        },
  );

  return (state: State, props: OwnProps): StateProps => ({
    endBoundsMillis: boundsMillisSelector(state, props),
    endMillis: endMillisSelector(state, props),
    isSaving: isAddingPhraseSelector(state),
    params: phraseTimesSelector(state, props),
    phrase: '',
    phraseDefaultInputMode: true,
    startBoundsMillis: boundsMillisSelector(state, props),
    startMillis: startMillisSelector(state, props),
  });
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onPhraseSubmit: (text, { startMillis, endMillis }) => {
    dispatch(onAddCaption(startMillis, endMillis));
    dispatch(addPhrase(startMillis, endMillis, text));
  },
});

const component = connect(makeMapStateToProps, mapDispatchToProps)(Phrase);
export type NewPhraseProps = PropsOf<typeof component>;
export default component;
