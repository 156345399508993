import React from 'react';

import Modal from 'components/Modal';

import { block } from './utils';
import VideoTemplateAddMediaModalContent, {
  VideoTemplateAddMediaModalContentProps,
} from './VideoTemplateAddMediaModalContent';

export interface VideoTemplateAddMediaModalProps
  extends VideoTemplateAddMediaModalContentProps {
  onCancel: () => void;
  onExited: () => void;
  onAnimateGatedClick: () => void;
  show: boolean;
}

const VideoTemplateAddMediaModal: React.FunctionComponent<VideoTemplateAddMediaModalProps> = props => {
  const {
    aspectRatio,
    onAnimateGatedClick,
    onCancel,
    onExited,
    onSubmit,
    show,
  } = props;

  return (
    <Modal
      className={block()}
      onExited={onExited}
      onHide={onCancel}
      show={show}
      title="add media"
    >
      <VideoTemplateAddMediaModalContent
        aspectRatio={aspectRatio}
        onSubmit={onSubmit}
        onAnimateGatedClick={onAnimateGatedClick}
      />
    </Modal>
  );
};

export default VideoTemplateAddMediaModal;
