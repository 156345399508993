export enum EditAutomationActionTypes {
  EDIT_AUTOMATION_UPDATE_REQUEST = 'app/edit-automation/EDIT_AUTOMATION_UPDATE_REQUEST',
  EDIT_AUTOMATION_UPDATE_FAILURE = 'app/edit-automation/EDIT_AUTOMATION_UPDATE_FAILURE',
  EDIT_AUTOMATION_UPDATE_SUCCESS = 'app/edit-automation/EDIT_AUTOMATION_UPDATE_SUCCESS',

  EDIT_AUTOMATION_INIT_AUDIO_SELECTION = 'app/edit-automation/EDIT_AUTOMATION_INIT_AUDIO_SELECTION',
  EDIT_AUTOMATION_SET_AUDIO_SELECTION_TYPE = 'app/edit-automation/EDIT_AUTOMATION_SET_AUDIO_SELECTION_TYPE',
  EDIT_AUTOMATION_SET_AUDIO_SELECTION_DURATION = 'app/edit-automation/EDIT_AUTOMATION_SET_AUDIO_SELECTION_DURATION',

  EDIT_AUTOMATION_INIT_OUTPUT = 'app/edit-automation/EDIT_AUTOMATION_INIT_OUTPUT',
  EDIT_AUTOMATION_SET_OUTPUT_OPTION = 'app/edit-automation/EDIT_AUTOMATION_SET_OUTPUT_OPTION',

  EDIT_AUTOMATION_STARTING_POINT_CHANGE = 'app/edit-automation/EDIT_AUTOMATION_STARTING_POINT_CHANGE',
}
