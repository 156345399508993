import * as React from 'react';
import { Checkbox, FormGroup } from 'react-bootstrap';
import { noop } from 'underscore';

const { useCallback } = React;

export interface EmailCheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
}

// TODO consolidate with TOS checkbox

const EmailCheckbox: React.SFC<EmailCheckboxProps> = ({
  checked,
  disabled,
  onChange,
}) => {
  const handleChange = useCallback(e => onChange(e.target.checked), [onChange]);

  return (
    <FormGroup>
      <Checkbox
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
        inline
      >
        Stay up to date with Headliner's new features through our weekly email.
      </Checkbox>
    </FormGroup>
  );
};

EmailCheckbox.defaultProps = {
  onChange: noop,
};

export default EmailCheckbox;
