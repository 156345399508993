export function createChainedFunction<T extends any[]>(
  ...fns: Array<(...args: T) => any>
) {
  return fns
    .filter(f => f != null)
    .reduce((acc, f) => {
      if (acc === null) {
        return f;
      }

      return function wrapper(...args) {
        acc.apply(this, args);
        f.apply(this, args);
      };
    }, null);
}
