import { useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { canLoadInitialStepIndex } from 'blocks/AutomationWorkflowWizard/utils';
import { isTemplateStepSubStep } from 'containers/ProjectWizards/TemplateStep';
import useOnMount from 'hooks/useOnMount';
import useQuery from 'hooks/useQuery';
import {
  getPodcastById,
  getSubscriptionById,
  searchFeed,
} from 'redux/middleware/api/podcast-service/actions';
import {
  getTraceByCustomId,
  getTraceById,
} from 'redux/middleware/api/url-generator-service/actions';
import {
  podcastsSelector,
  podcastSubscriptionsSelector,
  tracesSelector,
} from 'redux/modules/entities';
import { markPodcastDefault } from 'redux/modules/podcast-search';
import { Dispatch, State } from 'redux/types';
import { ReadyState } from 'types';
import { AutomationWorkflowWizardProps } from '../AutomationWorkflowWizard';
import usePreloadedPodcast from './usePreloadedPodcast';

type AutogramWizardQueryParams = {
  customTraceId?: string;
  initialStep?: number;
  podcastId?: string;
  subStep?: string;
  traceId?: string;
  workflowId?: number;
};

type AutogramWizardDefaultProps = Pick<
  AutomationWorkflowWizardProps,
  | 'defaultPodcastId'
  | 'defaultStepIndex'
  | 'defaultSubStep'
  | 'defaultWorkflowId'
>;

type UseDefaultDataResult = {
  props: AutogramWizardDefaultProps;
  status: ReadyState;
  workflowId: number | string;
};

function resolveStatus(
  fetchStatus: ReadyState,
  preloadPodcastStatus: ReadyState,
  canLoadInitialStep: boolean,
) {
  if (fetchStatus === 'idle' && preloadPodcastStatus === 'idle') {
    return 'idle';
  }

  if (fetchStatus === 'loading' || preloadPodcastStatus === 'loading') {
    return 'loading';
  }

  if (
    fetchStatus === 'error' ||
    preloadPodcastStatus === 'error' ||
    !canLoadInitialStep
  ) {
    return 'error';
  }

  return 'ready';
}

/**
 * loads the initial data for the Autogram Wizard
 */
export default function useDefaultData(): UseDefaultDataResult {
  const dispatch = useDispatch<Dispatch>();
  const store = useStore<State>();

  const [status, setStatus] = useState<ReadyState>('idle');
  const [podcastId, setPodcastId] = useState<string>();
  const [initialStepIndex, setInitialStepIndex] = useState<number>();

  const queryParams = useQuery({
    parseNumbers: true,
  }) as AutogramWizardQueryParams;

  const { status: preloadPodcastStatus } = usePreloadedPodcast(podcastId);

  const canLoadInitialStep = canLoadInitialStepIndex(initialStepIndex, {
    podcastId,
    workflowId: queryParams.workflowId,
  });

  useOnMount(async () => {
    setStatus('loading');

    try {
      if (queryParams.traceId || queryParams.customTraceId) {
        // load the trace id and use the rss URL to fetch the podcast id
        const {
          response: { result: traceId },
        } = await (queryParams.traceId
          ? dispatch(getTraceById(queryParams.traceId))
          : dispatch(getTraceByCustomId(queryParams.customTraceId)));

        const traces = tracesSelector(store.getState());
        const rssUrl = traces.getIn([traceId, 'properties', 'rssUrl']);

        const {
          response: {
            result: [tracePodcastId],
          },
        } = await dispatch(searchFeed(rssUrl));

        setPodcastId(tracePodcastId);

        // if podcast id is passed in from the request trace, the podcast
        // should be marked as a favorite so that feed processing is
        // triggered correctly...
        await dispatch(markPodcastDefault(tracePodcastId));
        // ...and then the wizard should start on step 2 (confirm podcast language).
        setInitialStepIndex(1);
      } else {
        // no trace, but if podcast id was passed via query params, load it
        const podcasts = podcastsSelector(store.getState());

        if (!podcasts?.get(queryParams.podcastId)) {
          await dispatch(getPodcastById(queryParams.podcastId));
        }

        setPodcastId(queryParams.podcastId);
      }

      if (queryParams.workflowId) {
        // automation "edit" mode.  load the workflow indicated by the id
        const subscriptions = podcastSubscriptionsSelector(store.getState());

        if (!subscriptions?.get(queryParams.workflowId)) {
          await dispatch(getSubscriptionById(queryParams.workflowId));
        }
      }

      // initial step might have already been set if a trace id was included
      // in the query params.  only set the initial step if it hasn't already
      // been set
      setInitialStepIndex(currentIndex => {
        if (currentIndex !== undefined) {
          return currentIndex;
        }

        const initialStep = queryParams.initialStep ?? 1;
        return initialStep - 1;
      });

      setStatus('ready');
    } catch {
      setStatus('error');
    }
  });

  const resolvedStatus = resolveStatus(
    status,
    preloadPodcastStatus,
    canLoadInitialStep,
  );

  return {
    status: resolvedStatus,
    workflowId: queryParams.workflowId,
    props: {
      defaultPodcastId: status === 'error' ? undefined : podcastId,
      defaultStepIndex: status === 'error' ? 0 : initialStepIndex,
      defaultSubStep: isTemplateStepSubStep(queryParams.subStep)
        ? queryParams.subStep
        : undefined,
      defaultWorkflowId:
        status === 'error' ? undefined : queryParams.workflowId,
    },
  };
}
