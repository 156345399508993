import * as React from 'react';

import { IconProps } from 'components/icons/types';

/* tslint:disable-next-line:variable-name */
const ReClipPostTaskBanner: React.SFC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="500"
    height="300"
    viewBox="0 0 500 300"
    {...props}
  >
    <defs>
      <path
        id="a"
        d="M10 0h480c5.523 0 10 4.477 10 10v280c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10V10C0 4.477 4.477 0 10 0z"
      />
    </defs>
    <g fill="#14191e" fillRule="evenodd">
      <path
        stroke="url(#post-task-path-gradient)"
        strokeWidth="2"
        d="M10 1a9 9 0 0 0-9 9v280a9 9 0 0 0 9 9h480a9 9 0 0 0 9-9V10a9 9 0 0 0-9-9H10z"
      />
      <text
        fill="#8CA0C3"
        fontFamily="Montserrat-Bold, Montserrat"
        fontSize="18"
        fontWeight="bold"
        letterSpacing=".5"
      >
        <tspan x="95.031" y="173.5">
          Help us improve smart clipping
        </tspan>{' '}
        <tspan
          x="405.969"
          y="173.5"
          fontFamily="Montserrat-Medium, Montserrat"
          fontWeight="400"
        >
          {' '}
        </tspan>{' '}
        <tspan
          x="82.248"
          y="199.5"
          fontFamily="Montserrat-Medium, Montserrat"
          fontWeight="400"
        >
          by providing some quick feedback!
        </tspan>
      </text>
      <g transform="translate(200 30)">
        <circle
          cx="50"
          cy="50"
          r="50"
          fill="url(#post-task-circle-gradient)"
          filter="url(#post-task-circle-filter)"
        />
        <path
          fill="#282E37"
          fillRule="nonzero"
          d="M50.5 31C38.072 31 28 39.105 28 49.107c0 4.318 1.88 8.27 5.01 11.378-1.099 4.388-4.773 8.296-4.817 8.34a.687.687 0 0 0-.131.757c.114.261.36.418.641.418 5.827 0 10.195-2.768 12.358-4.475a27.034 27.034 0 0 0 9.439 1.69c12.428 0 22.5-8.105 22.5-18.108C73 39.105 62.928 31 50.5 31z"
        />
      </g>
      <g transform="translate(20 235)">
        <rect width="460" height="45" fill="#19B5FE" rx="3" />
        <text
          fill="#FFF"
          fontFamily="Roboto-Bold, Roboto"
          fontSize="12"
          fontWeight="bold"
          letterSpacing="2"
        >
          <tspan x="149" y="28">
            RATE YOUR LAST CLIP
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

export default ReClipPostTaskBanner;
