import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noop } from 'underscore';

import { onCompleteDowngrade } from 'redux/modules/mixpanel';
import { showSuccess } from 'redux/modules/notification';
import {
  loadMyPricingData,
  updateSubscription,
} from 'redux/modules/pricing/actions';
import {
  isUpdatingSubscriptionSelector,
  planNameSelector,
  tierSelector,
} from 'redux/modules/pricing/selectors';
import { getDowngradePlan, getTierFromPlan } from 'utils/pricing';
import useBillingCycle from './useBillingCycle';

interface UsePlanDowngradeConfig {
  onDowngradeComplete?: () => void;
}

export default function usePlanDowngrade({
  onDowngradeComplete = noop,
}: UsePlanDowngradeConfig) {
  const currentPlan = useSelector(planNameSelector);
  const currentTier = useSelector(tierSelector);
  const { billingPeriodEndDate } = useBillingCycle();
  const dispatch = useDispatch();
  const disabled = useSelector(isUpdatingSubscriptionSelector);

  const onDowngrade = useCallback(async () => {
    const downgradePlan = getDowngradePlan(currentPlan);
    const downgradeTier = getTierFromPlan(downgradePlan);

    if (downgradePlan) {
      await dispatch(updateSubscription(downgradePlan));
      dispatch(onCompleteDowngrade(currentTier, downgradeTier));
      onDowngradeComplete();
      dispatch(
        showSuccess({
          dismissAfterSec: 10,
          message: `Your plan will be downgraded on ${billingPeriodEndDate}`,
          title: 'Your plan will downgrade soon',
        }),
      );
      await dispatch(loadMyPricingData());
    }
  }, [
    billingPeriodEndDate,
    currentPlan,
    currentTier,
    dispatch,
    onDowngradeComplete,
  ]);

  return { disabled, onDowngrade };
}
