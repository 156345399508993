import dayjs from 'dayjs';
import bem from 'utils/bem';

export const block = bem('project-history');

export const getFormattedDate = (millis: number) => {
  const dateFormat = 'HH:mm, MMM D, YYYY';

  return dayjs(millis).format(dateFormat);
};
