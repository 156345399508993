import * as React from 'react';
import Draggable from 'react-draggable';
import { isUndefined } from 'underscore';
import { DragHandleProps } from '../types';
import { block } from '../utils';

export default class DragHandle extends React.Component<DragHandleProps> {
  private handleDrag = (_, { deltaX, deltaY }) => {
    const { dragging } = this.props;
    if (!dragging) return;

    const {
      onDrag,
      left: prevLeft,
      top: prevTop,
      cropInfo: { axis },
    } = this.props;
    let top;
    let left;
    if (axis === 'y' || axis === 'both') {
      top = deltaY + prevTop;
    }
    if (axis === 'x' || axis === 'both') {
      left = deltaX + prevLeft;
    }
    onDrag({
      left: isUndefined(left) ? prevLeft : left,
      top: isUndefined(top) ? prevTop : top,
    });
  };

  public render() {
    const {
      onDragStart,
      onDragStop,
      dragging,
      cropInfo: { axis, dimension },
      top,
      left,
    } = this.props;

    return (
      <Draggable
        handle="#video-cropper-draghandle"
        axis={axis}
        onStart={onDragStart}
        onStop={onDragStop}
        onDrag={this.handleDrag}
        position={{ x: left, y: top }}
      >
        <div
          className={block('draghandle', { dragging })}
          id="video-cropper-draghandle"
          style={{
            ...dimension,
          }}
        />
      </Draggable>
    );
  }
}
