import { List } from 'immutable';
import { createSelector } from 'reselect';

import { State } from 'redux/types';

import { projectTemplatesSelector as projectTemplatesEntitiesSelector } from '../entities';

const projectTemplateIdsSelector = (state: State) =>
  state.getIn(['projectTemplates', 'ids'], List());

export const projectTemplatesSelector = createSelector(
  [projectTemplatesEntitiesSelector, projectTemplateIdsSelector],
  (projectTemplatesEntities, projectTemplateIds) => {
    if (!projectTemplateIds) {
      return [];
    }

    return projectTemplateIds
      .map(templateId => projectTemplatesEntities.get(templateId))
      .toJS();
  },
);
