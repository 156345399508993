export const PROJECT_CREATE_REQUEST = 'app/project/PROJECT_CREATE_REQUEST';
export const PROJECT_CREATE_SUCCESS = 'app/project/PROJECT_CREATE_SUCCESS';
export const PROJECT_CREATE_FAILURE = 'app/project/PROJECT_CREATE_FAILURE';

export const PROJECT_WIZARD_AUDIO_CLIP_REQUEST =
  'app/project/PROJECT_WIZARD_AUDIO_CLIP_REQUEST';
export const PROJECT_WIZARD_AUDIO_CLIP_SUCCESS =
  'app/project/PROJECT_WIZARD_AUDIO_CLIP_SUCCESS';
export const PROJECT_WIZARD_AUDIO_CLIP_FAILURE =
  'app/project/PROJECT_WIZARD_AUDIO_CLIP_FAILURE';

export const PROJECT_HISTORY_GET_REQUEST =
  'app/project/PROJECT_HISTORY_GET_REQUEST';
export const PROJECT_HISTORY_GET_SUCCESS =
  'app/project/PROJECT_HISTORY_GET_SUCCESS';
export const PROJECT_HISTORY_GET_FAILURE =
  'app/project/PROJECT_HISTORY_GET_FAILURE';

export const PROJECT_UPDATE_REQUEST = 'app/project/PROJECT_UPDATE_REQUEST';
export const PROJECT_UPDATE_SUCCESS = 'app/project/PROJECT_UPDATE_SUCCESS';
export const PROJECT_UPDATE_FAILURE = 'app/project/PROJECT_UPDATE_FAILURE';

export const PROJECT_DELETE = 'app/project/PROJECT_DELETE';

export const PROJECT_GET_BY_ID_REQUEST =
  'app/project/PROJECT_GET_BY_ID_REQUEST';
export const PROJECT_GET_BY_ID_SUCCESS =
  'app/project/PROJECT_GET_BY_ID_SUCCESS';
export const PROJECT_GET_BY_ID_FAILURE =
  'app/project/PROJECT_GET_BY_ID_FAILURE';

export const NEW_PROJECT_SETTINGS_SET = 'app/project/NEW_PROJECT_SETTINGS_SET';
export const NEW_PROJECT_SETTINGS_CLEAR =
  'app/project/NEW_PROJECT_SETTINGS_CLEAR';

export const PROJECT_SET = 'app/project/PROJECT_SET';
export const PROJECT_CLEAR = 'app/project/PROJECT_CLEAR';

export const PROJECT_COPY_REQUEST = 'app/project/PROJECT_COPY_REQUEST';
export const PROJECT_COPY_SUCCESS = 'app/project/PROJECT_COPY_SUCCESS';
export const PROJECT_COPY_FAILURE = 'app/project/PROJECT_COPY_FAILURE';

export const PROJECTS_COUNT_GET_REQUEST =
  'app/project/PROJECTS_COUNT_GET_REQUEST';
export const PROJECTS_COUNT_GET_SUCCESS =
  'app/project/PROJECTS_COUNT_GET_SUCCESS';
export const PROJECTS_COUNT_GET_FAILURE =
  'app/project/PROJECTS_COUNT_GET_FAILURE';

export const PROJECT_TEMPLATE_CREATE_REQUEST =
  'app/project/PROJECT_TEMPLATE_CREATE_REQUEST';
export const PROJECT_TEMPLATE_CREATE_SUCCESS =
  'app/project/PROJECT_TEMPLATE_CREATE_SUCCESS';
export const PROJECT_TEMPLATE_CREATE_FAILURE =
  'app/project/PROJECT_TEMPLATE_CREATE_FAILURE';

export const PROJECT_HISTORY_CLEAR = 'app/project/PROJECT_HISTORY_CLEAR';

export const PROJECTS_TAB_LOAD = 'app/project/PROJECTS_TAB_LOAD';
