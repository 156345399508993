import { useDispatch } from 'react-redux';
import { updateEmail } from 'redux/modules/auth/actions';
import { replaceModal } from 'redux/modules/modal';
import { showNotification } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';

export default () => {
  const dispatch = useDispatch<Dispatch>();

  const onSubmit = (email: string, password: string) => {
    return dispatch(updateEmail(email, password))
      .then(() => {
        dispatch(replaceModal({ name: 'VerifyEmail' }));
      })
      .catch(error => {
        if (!error.email && !error.password) {
          dispatch(
            showNotification({
              message: 'Please try again or {{link}} so we can help',
              code: 'ER013',
              dismissAfterSec: 10,
              level: 'error',
              type: 'help',
              title: "Sorry, we couldn't update your account",
              actionLabel: 'contact us',
            }),
          );
        }
        throw error;
      });
  };

  return { onSubmit };
};
