import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { isAfterNow } from '../../../utils/time';

const gettyExpiresAtMillisSelector = state =>
  state.getIn(['oauth', 'getty', 'expiresAtMillis']);
const thinkstockExpiresAtMillisSelector = state =>
  state.getIn(['oauth', 'thinkstock', 'expiresAtMillis']);
const gettyCreativeExpiresAtMillisSelector = state =>
  state.getIn(['oauth', 'gettyCreative', 'expiresAtMillis']);

export const gettyExpiresAtMomentSelector = createSelector(
  [gettyExpiresAtMillisSelector],
  millis => dayjs.utc(millis),
);

export const thinkstockExpiresAtMomentSelector = createSelector(
  [thinkstockExpiresAtMillisSelector],
  millis => dayjs.utc(millis),
);

export const gettyCreativeExpiresAtMomentSelector = createSelector(
  [gettyCreativeExpiresAtMillisSelector],
  millis => dayjs.utc(millis),
);

export const hasGettyAccessSelector = createSelector(
  [gettyExpiresAtMomentSelector],
  gettyExpiration =>
    gettyExpiration === undefined ? false : isAfterNow(gettyExpiration),
);

export const hasThinkstockAccessSelector = createSelector(
  [thinkstockExpiresAtMomentSelector],
  thinkstockExpiration =>
    thinkstockExpiration === undefined
      ? false
      : isAfterNow(thinkstockExpiration),
);

export const hasGettyCreativeAccessSelector = createSelector(
  [gettyCreativeExpiresAtMomentSelector],
  gettyCreativeExpiration =>
    gettyCreativeExpiration === undefined
      ? false
      : isAfterNow(gettyCreativeExpiration),
);

export default {
  hasGettyAccessSelector,
  hasThinkstockAccessSelector,
  hasGettyCreativeAccessSelector,
  gettyExpiresAtMomentSelector,
  thinkstockExpiresAtMomentSelector,
  gettyCreativeExpiresAtMomentSelector,
};
