import cn from 'classnames';
import * as React from 'react';
import { isString, noop } from 'underscore';

import { ProFeatureTooltipContent } from 'blocks/Pricing';
import { H2 } from 'components/Heading';
import { ProBadge } from 'components/icons';
import Tooltip from 'components/Tooltip';
import RadioIndicator from './RadioIndicator';
import { Option, RadioOptionProps } from './types';
import { block } from './utils';

const { useCallback } = React;

export default function RadioOption<T extends Option<V> = Option, V = any>(
  props: RadioOptionProps<T, V>,
) {
  const {
    className,
    component,
    disabled,
    indicatorClassName,
    onClick,
    option,
    selected,
    appearance = 'option',
  } = props;
  const handleClick = useCallback(() => onClick(option.value), [
    onClick,
    option.value,
  ]);
  const propOverrides = {
    className: cn(block(appearance, { disabled, selected }), className),
    onClick: !disabled ? handleClick : noop,
  };

  const tooltipVisibilityControl =
    option.isProGated || (option.disabledTooltip && option.disabled)
      ? undefined
      : false;

  return (
    <Tooltip
      content={
        option.isProGated ? ProFeatureTooltipContent : option.disabledTooltip
      }
      id={`${option.id ?? option.label ?? option.value}-tooltip`}
      placement="top"
      show={tooltipVisibilityControl}
    >
      {component ? (
        component({ ...props, ...propOverrides })
      ) : (
        <div {...propOverrides}>
          <RadioIndicator selected={selected} className={indicatorClassName} />
          {isString(option.label) ? <H2>{option.label}</H2> : option.label}
          {option.isProGated && <ProBadge style={{ marginLeft: 8 }} />}
        </div>
      )}
    </Tooltip>
  );
}

RadioOption.defaultProps = {
  disabled: false,
  onClick: noop,
  selected: false,
};
