import * as React from 'react';

interface IProps {
  src?: string;
}

const Image: React.SFC<IProps> = ({ src }) => <img src={src} alt={src} />;

export default Image;
export { IProps as ImageProps };
