import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import { Omit } from 'types';
import CardCta, { CardCtaProps } from './CardCta';

export type ReplyCardCtaProps = Omit<CardCtaProps, 'variant' | 'icon'>;

const ReplyCardCta: React.FC<ReplyCardCtaProps> = props => (
  <CardCta
    variant="purple"
    icon={<FontAwesome icon="reply" flip="horizontal" />}
    {...props}
  />
);

export default ReplyCardCta;
