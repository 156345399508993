import { ColorPaletteState } from '@sparemin/blockhead';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AiImageType } from 'redux/middleware/api/image-search-service';
import { isFreeSelector } from 'redux/modules/pricing';
import { AspectRatioName } from 'types';
import { AIGenerationTopSlot } from '../AIGeneration';
import { EngineRegistry } from '../types';

export interface UseVideoSearchResultsTopSlotConfig {
  hasQuery: boolean;
  selectedEngine?: string;
  engines: EngineRegistry;
  aspectRatioName?: AspectRatioName;
  imageType?: AiImageType;
  colorPaletteState: ColorPaletteState;
  onImageTypeChange: (value: AiImageType) => void;
  onAspectRatioNameChange: (value: AspectRatioName) => void;
  onGenerate?: () => void;
}

export type UseVideoSearchResultsTopSlotResult = React.ReactNode;

export default function useVideoSearchResultsTopSlot({
  hasQuery,
  selectedEngine,
  engines,
  aspectRatioName,
  onAspectRatioNameChange,
  imageType,
  colorPaletteState,
  onImageTypeChange,
  onGenerate,
}: UseVideoSearchResultsTopSlotConfig): UseVideoSearchResultsTopSlotResult {
  const isFree = useSelector(isFreeSelector);

  return useMemo((): React.ReactNode => {
    if (isFree) {
      return null;
    }

    switch (selectedEngine) {
      case 'textToVideo': {
        return (
          <AIGenerationTopSlot
            {...{
              hasQuery,
              onGenerate,
              aspectRatioName,
              imageType,
              colorPaletteState,
              onImageTypeChange,
              onAspectRatioNameChange,
            }}
            isLoading={engines?.textToVideo?.isLoading}
          />
        );
      }

      default: {
        return null;
      }
    }
  }, [
    aspectRatioName,
    colorPaletteState,
    engines,
    hasQuery,
    imageType,
    isFree,
    onAspectRatioNameChange,
    onGenerate,
    onImageTypeChange,
    selectedEngine,
  ]);
}
