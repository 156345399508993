import {
  IEmbedConfig,
  ProjectCreationMethod,
  Replace,
  SoundWaveGeneration,
  TemplateType,
} from 'types';
import { IApiAction, IApiResponse } from '../types';

export const ACTION_KEY = 'CREATION_SERVICE';

export enum ServiceMethod {
  CREATE = 'CREATE',
  CREATION_GET = 'CREATION_GET',
}

export enum CreationStatus {
  SUBMITTED = 'submitted',
  PROCESSING_ASSETS = 'processingAssets',
  CREATING_CONFIG = 'creatingConfig',
  EXPORTING_VIDEO = 'exportingVideo',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum AssetStatus {
  COMPLETED = 'completed',
  FAILED = 'failed',
  NOT_FOUND = 'notFound',
  PROCESSING = 'processing',
}

export interface CreationRequest {
  audioFile?: Blob;
  audioPodcastId?: string;
  audioRemoteEpisodeId?: string;
  audioUrl?: string;
  videoFile?: Blob;
  videoUrl?: string;
  isVideoTranscriptEnabled?: boolean;
  videoLanguage?: string;
  videoTrimStartMillis?: number;
  videoTrimEndMillis?: number;
  entireAudioInstanceId?: number;
  entireAudioTrimStartMillis?: number;
  entireAudioTrimEndMillis?: number;
  preferredAudioWavetype?: SoundWaveGeneration;
  isAudioTranscriptEnabled?: boolean;
  templateId?: string;
  templateType?: TemplateType;
  projectCreateMethod?: ProjectCreationMethod;
  projectName?: string;
  baseConfigJson?: string;
  entireAudioTranscriptUrl?: string;
  clippedAudioTranscriptUrl?: string;
  initiateExportVideo?: boolean;
  videoFrameHeight?: number;
  videoFrameWidth?: number;
  videoFrameQuality?: number;
  videoFrameRate?: number;
  aspectRatioWidth?: number;
  aspectRatioHeight?: number;
  customTraceId?: string;
  traceId?: string;
  isEddy?: boolean;
  audioLanguage?: string;
  uploadedVideoId?: number;
}

export type CreationActionOptions = Replace<
  Omit<CreationRequest, 'audioUrl' | 'audioFile' | 'videoUrl' | 'videoFile'>,
  {
    audioSource?: string | File;
    videoSource?: string | File;
    baseConfigJson?: IEmbedConfig;
    projectUuid?: string;
  }
>;

export type CreationVideoExportOptions = Pick<
  CreationActionOptions,
  | 'videoFrameWidth'
  | 'videoFrameQuality'
  | 'videoFrameHeight'
  | 'videoFrameRate'
>;

export interface AssetStatuses {
  audioStatus: AssetStatus;
  waveformStatus: AssetStatus;
}

interface CreationResponse {
  assetStatuses: AssetStatus;
  id: number;
  initiateVideoExport: boolean;
  requestObj: CreationRequest;
  status: CreationStatus;
}

export type CreationArgs = [CreationRequest];
export type GetCreationArgs = [number];

export interface CreationResult {
  entities: {
    creations: {
      [key: number]: CreationResponse;
    };
  };
  result: number;
}

type ApiAction<M extends ServiceMethod, A> = IApiAction<
  typeof ACTION_KEY,
  M,
  A
>;

export type CreationAction = ApiAction<ServiceMethod.CREATE, CreationArgs>;
export type GetCreationAction = ApiAction<
  ServiceMethod.CREATION_GET,
  GetCreationArgs
>;

export interface CreationServiceDispatch {
  (action: CreationAction): Promise<IApiResponse<CreationResult>>;
  (action: GetCreationAction): Promise<IApiResponse<CreationResult>>;
}
