import React from 'react';

import BemCSSTransition from 'components/BemCssTransition';
import LoadingMessage from 'components/LoadingMessage';
import { pageBlock as block } from '../utils';
import { useClipSelectAnimation } from './ClipSelectAnimationContext';
import ClipSelectAudioClipper from './ClipSelectAudioClipper';
import ClipSelectFooter from './ClipSelectFooter';
import ClipSelectHeader from './ClipSelectHeader';
import ClipSelectIntro from './ClipSelectIntro';
import { useClipSelectNavigation } from './ClipSelectNavigationContext/ClipSelectNavigationContext';
import ClipSelector from './ClipSelector';
import ClipSelectVideoExport from './ClipSelectVideoExport';
import SuggestionPlayer from './SuggestionPlayer';

export interface ClipSelectPageContentsProps {}

const ClipSelectPageContents: React.FC<ClipSelectPageContentsProps> = () => {
  const animations = useClipSelectAnimation();
  useClipSelectNavigation();

  return (
    <>
      <BemCSSTransition className={block('loader')} {...animations.loader}>
        <LoadingMessage message="Loading Clip Data" variation="bars" />
      </BemCSSTransition>
      <BemCSSTransition className={block('header')} {...animations.header}>
        <ClipSelectHeader />
      </BemCSSTransition>
      <div className={block('body')}>
        <BemCSSTransition className={block('intro')} {...animations.intro}>
          <ClipSelectIntro />
        </BemCSSTransition>
        <BemCSSTransition
          className={block('selector')}
          {...animations.clipSelector}
        >
          <ClipSelector />
        </BemCSSTransition>
        <BemCSSTransition className={block('footer')} {...animations.footer}>
          <ClipSelectFooter />
        </BemCSSTransition>
        <BemCSSTransition className={block('clipper')} {...animations.clipper}>
          <ClipSelectAudioClipper />
        </BemCSSTransition>
      </div>
      <BemCSSTransition className={block('export')} {...animations.export}>
        <ClipSelectVideoExport />
      </BemCSSTransition>
      <SuggestionPlayer />
    </>
  );
};

export default ClipSelectPageContents;
