import * as React from 'react';

import { block } from '../utils';

const ProjectListHeading: React.SFC = () => (
  <div className={block('heading')}>
    <div className={block('name-heading')}>Project Name</div>
    <div className={block('date-heading')}>Date Modified</div>
  </div>
);

export default ProjectListHeading;
