import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DESTINATION_PRESETS } from 'blocks/AudiogramDestinations';
import {
  AudiogramDestination,
  MultiPathAudiogramDestination,
  SinglePathAudiogramDestination,
} from 'blocks/AudiogramDestinations/types';
import Link from 'components/Link';
import LinkButton from 'components/LinkButton';
import Modal, { ModalHeader } from 'components/Modal/v2';
import ModalFooter from 'components/Modal/v2/ModalFooter';
import {
  FacebookButton,
  InstagramButton,
  LinkedInButton,
  TikTokButton,
  TwitterButton,
  YouTubeButton,
} from 'components/SocialButton';
import {
  onClickAudiogramDestinationModal,
  onClickStartWizardDestination,
} from 'redux/modules/mixpanel';
import { hideModal, replaceModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { getAudiogramWizardPath } from 'utils/routes';
import { DESTINATION_ID_TO_MIXPANEL_DESTINATION_MAP } from './constants';
import { chooseDestinationContentsBlock as block } from './utils';

export interface ChooseDestinationModalContentsProps {
  onDestinationClick?: (destination: MultiPathAudiogramDestination) => void;
}

const ChooseDestinationModalContents: React.FC<ChooseDestinationModalContentsProps> = ({
  onDestinationClick,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const handleHide = useCallback((): void => {
    dispatch(hideModal());
  }, [dispatch]);

  const handleOnClickDestination = useCallback(
    (dest: AudiogramDestination): void => {
      dispatch(
        onClickAudiogramDestinationModal(
          DESTINATION_ID_TO_MIXPANEL_DESTINATION_MAP[dest],
        ),
      );
    },
    [dispatch],
  );

  // This method handles multi path audiograms, with two steps:
  // Choose a destination and Choose a video type.
  const createMultiPathClickHandler = (
    dest: MultiPathAudiogramDestination,
  ) => () => {
    handleOnClickDestination(dest);

    onDestinationClick?.(dest);
  };

  // This method handles single path audiograms:
  // With a redirect after selecting the destination.
  const createSinglePathClickHandler = (
    dest: SinglePathAudiogramDestination,
  ) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    handleOnClickDestination(dest);

    dispatch(onClickStartWizardDestination(e.currentTarget.href));

    handleHide();
  };

  const handleOldOptionsClick = () => {
    dispatch(onClickAudiogramDestinationModal('OldOptions'));
    dispatch(replaceModal({ name: 'ManualAudiogram' }));
  };

  return (
    <Modal
      header={
        <ModalHeader
          className={block('header')}
          onHide={handleHide}
          title="Choose a destination"
          subtitle="Where would you like to promote your podcast?"
        />
      }
      footer={
        <ModalFooter className={block('footer')}>
          <LinkButton
            onClick={handleOldOptionsClick}
            theme="cta"
            uppercase
            size="md"
          >
            I’m not sure, show me the old options
          </LinkButton>
        </ModalFooter>
      }
    >
      <div className={block('grid')}>
        <YouTubeButton onClick={createMultiPathClickHandler('youtube')} />
        <InstagramButton onClick={createMultiPathClickHandler('instagram')} />
        <FacebookButton onClick={createMultiPathClickHandler('facebook')} />
        <TikTokButton onClick={createMultiPathClickHandler('tiktok')} />

        <Link
          to={getAudiogramWizardPath({
            defaultAspectRatio: DESTINATION_PRESETS.more.linkedin.aspectRatio,
            defaultClipDurationMillis:
              DESTINATION_PRESETS.more.linkedin.durationMs,
            defaultPresetKey: DESTINATION_PRESETS.more.linkedin.key,
          })}
          onClick={createSinglePathClickHandler('linkedin')}
        >
          <LinkedInButton />
        </Link>

        <Link
          to={getAudiogramWizardPath({
            defaultAspectRatio: DESTINATION_PRESETS.more.twitter.aspectRatio,
            defaultClipDurationMillis:
              DESTINATION_PRESETS.more.twitter.durationMs,
            defaultPresetKey: DESTINATION_PRESETS.more.twitter.key,
          })}
          onClick={createSinglePathClickHandler('twitter')}
        >
          <TwitterButton />
        </Link>
      </div>
    </Modal>
  );
};

export default ChooseDestinationModalContents;
