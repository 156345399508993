import { WaveformStatus } from './types';

export function isWaveformResolved(status: WaveformStatus) {
  switch (status) {
    case 'completed':
    case 'failed':
    case 'failureAcked':
      return true;

    case 'notFound':
    case 'inProgress':
    case 'queued':
      return false;

    default:
      throw new Error('Failed to resolve waveform');
  }
}
