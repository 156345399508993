import { Type } from './action-types';
import { stateFactory } from './factories';
import { TextToImageAction, TextToImageState } from './types';

const defaultState = stateFactory();

export default function reducer(
  state: TextToImageState = defaultState,
  action: TextToImageAction,
) {
  switch (action.type) {
    case Type.TEXT_TO_IMAGE_CREATE_REQUEST: {
      const { mediaType, prompt, provider, width, height } = action.payload;

      return state
        .set('mediaType', mediaType)
        .set('prompt', prompt)
        .set('providers', {
          ...state.providers,
          [mediaType]: provider,
        })
        .set('width', width)
        .set('height', height);
    }

    case Type.TEXT_TO_IMAGE_CREATE_SUCCESS: {
      const { textToImageJobId, mediaType } = action.payload;

      return state.set('textToImageJobIds', {
        ...state.textToImageJobIds,
        [mediaType]: textToImageJobId,
      });
    }

    case Type.TEXT_TO_IMAGE_CREATE_FAILURE: {
      return state
        .set('prompt', undefined)
        .set('providers', undefined)
        .set('width', undefined)
        .set('height', undefined);
    }

    case Type.TEXT_TO_IMAGE_POLLING_BEGIN: {
      const { mediaType } = action.payload;

      return state
        .set('hasError', {
          ...state.hasError,
          [mediaType]: false,
        })
        .set('isPollingTextToImage', {
          ...state.isPollingTextToImage,
          [mediaType]: true,
        })
        .set('images', {
          ...state.images,
          [mediaType]: [],
        });
    }

    case Type.TEXT_TO_IMAGE_POLLING_SUCCESS: {
      const { images, mediaType } = action.payload;

      return state.set('images', {
        ...state.images,
        [mediaType]: images,
      });
    }

    case Type.TEXT_TO_IMAGE_POLLING_FAILURE: {
      const { mediaType } = action.payload;

      return state
        .set('isPollingTextToImage', {
          ...state.isPollingTextToImage,
          [mediaType]: false,
        })
        .set('hasError', {
          ...state.hasError,
          [mediaType]: true,
        });
    }

    case Type.TEXT_TO_IMAGE_POLLING_END: {
      const { mediaType } = action.payload;

      return state.set('isPollingTextToImage', {
        ...state.isPollingTextToImage,
        [mediaType]: false,
      });
    }

    default:
      return state;
  }
}
