import * as React from 'react';

import NumericSelect, { NumericSelectProps } from '../NumericSelect';
import TextShadowField from './TextShadowField';

type Props = NumericSelectProps;

const TextShadowBlurField: React.SFC<Props> = ({ ...props }) => (
  <TextShadowField label="Blur">
    <NumericSelect
      {...props}
      displayValues={Array(11)
        .fill(0)
        .map((__, index) => index)}
    />
  </TextShadowField>
);

export default TextShadowBlurField;
export { Props as TextShadowBlurFieldProps };
