import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import PhraseControlButton, {
  PhraseControlButtonProps,
} from './PhraseControlButton';

export type DeletePhraseButtonProps = Pick<
  PhraseControlButtonProps,
  'disabled' | 'onClick'
>;

const DeletePhraseButton: React.SFC<DeletePhraseButtonProps> = props => (
  <PhraseControlButton {...props} id="delete-phrase" tooltip="Delete block">
    <FontAwesome icon="trash-alt" />
  </PhraseControlButton>
);

export default DeletePhraseButton;
