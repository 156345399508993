import { createSelector } from 'reselect';
import { socialSharesSelector } from 'redux/modules/entities';
import { stateSelector } from '../selectors';

export const facebookSelector = createSelector(stateSelector, state =>
  state.get('facebook'),
);

export const facebookProviderUserIdSelector = createSelector(
  facebookSelector,
  facebook => facebook.providerUserId,
);

export const facebookUserDataSelector = createSelector(
  facebookSelector,
  facebook => ({
    accessToken: facebook.accessToken,
    idToken: facebook.idToken,
    providerUserId: facebook.providerUserId,
    username: facebook.username,
    profileImageUrl: facebook.profileImageUrl,
  }),
);

export const facebookUserNameSelector = createSelector(
  facebookUserDataSelector,
  userData => userData.username,
);

export const facebookSocialShareIdSelector = createSelector(
  facebookSelector,
  facebook => facebook.socialShareId,
);

export const facebookSocialSharesSelector = createSelector(
  facebookSocialShareIdSelector,
  socialSharesSelector,
  (id, shares) =>
    !id || !shares || shares.size === 0
      ? undefined
      : shares.get(String(id))?.toJS(),
);

export const facebookPagesSelector = createSelector(
  facebookSelector,
  facebook => facebook.pages,
);

export const facebookPagesLoadingSelector = createSelector(
  facebookSelector,
  facebook => facebook.get('pagesLoading'),
);

export const facebookPostStatusSelector = createSelector(
  facebookSelector,
  facebook => facebook.postStatus,
);
