import cn from 'classnames';
import React from 'react';

import DialogContent, {
  Props as DialogContentProps,
} from 'components/DialogContent';
import { dialogModalBlock as block } from './utils';

export interface DownloadCaptionsDialogModalContentsProps
  extends DialogContentProps {}

const DownloadCaptionsDialogModalContents: React.FC<DownloadCaptionsDialogModalContentsProps> = ({
  className,
  detailsClassName,
  titleClassName,
  ...props
}) => (
  <DialogContent
    className={cn(block('dialog'), className)}
    detailsClassName={cn(block('details'), detailsClassName)}
    titleClassName={cn(block('title'), titleClassName)}
    {...props}
  />
);

export default DownloadCaptionsDialogModalContents;
