import { Enum } from 'enumify';

/* eslint-disable no-use-before-define */
class AudioExportStatus extends Enum {
  static inProgress(status) {
    return IN_PROGRESS_STATUSES.includes(status);
  }

  static isFinished(status) {
    return FINISHED_STATUSES.includes(status);
  }
}
/* eslint-enable no-use-before-define */

AudioExportStatus.initEnum([
  'STARTED',
  'RENDERING',
  'UPLOADING',
  'SAVING_TO_CONFIG',
  'FAILED',
  'SUCCEEDED',
]);

const IN_PROGRESS_STATUSES = [
  AudioExportStatus.STARTED,
  AudioExportStatus.RENDERING,
  AudioExportStatus.UPLOADING,
  AudioExportStatus.SAVING_TO_CONFIG,
];

const FINISHED_STATUSES = [
  AudioExportStatus.FAILED,
  AudioExportStatus.SUCCEEDED,
];

export default AudioExportStatus;
