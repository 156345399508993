import * as React from 'react';
import { noop } from 'underscore';

import TextBoxPositionField, {
  TextBoxPositionFieldProps,
} from './TextBoxPositionField';

type Value = TextBoxPositionFieldProps['value'];

export interface IPosition {
  x?: Value;
  y?: Value;
}

interface IProps {
  disabled?: boolean;
  value?: IPosition;
  onBlur?: (value: IPosition) => void;
  onChange?: (value: IPosition) => void;
}

export default class PositionOptions extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    disabled: false,
    onBlur: noop,
    onChange: noop,
  };

  private handlePosChange: TextBoxPositionFieldProps['onChange'] = (
    val,
    coordinate,
  ) => {
    const { onChange, value } = this.props;
    onChange({
      ...value,
      ...{ [coordinate]: val },
    });
  };

  private handlePosBlur: TextBoxPositionFieldProps['onBlur'] = (
    val,
    coordinate,
  ) => {
    const { onBlur, value } = this.props;
    onBlur({
      ...value,
      ...{ [coordinate]: val },
    });
  };

  public render() {
    const { disabled, value } = this.props;

    return (
      <div>
        <TextBoxPositionField
          disabled={disabled}
          coordinate="x"
          value={value.x}
          onChange={this.handlePosChange}
          onBlur={this.handlePosBlur}
        />
        <TextBoxPositionField
          disabled={disabled}
          coordinate="y"
          value={value.y}
          onChange={this.handlePosChange}
          onBlur={this.handlePosBlur}
        />
      </div>
    );
  }
}

export { IProps as PositionOptionsProps };
