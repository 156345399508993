import * as React from 'react';

import TextChildTile, { TextChildTileProps } from './TextChildTile';
import { textViewBlock as block } from './utils';

type PickedTileProps = Pick<TextChildTileProps, 'onClick'>;

export interface AddTextButtonProps extends PickedTileProps {}

const AddTextButton: React.FC<AddTextButtonProps> = ({ onClick }) => (
  <TextChildTile className={block('add-text')} onClick={onClick}>
    + Add Text
  </TextChildTile>
);

export default AddTextButton;
