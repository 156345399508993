import { Container, Text, ThemeProvider } from '@sparemin/blockhead';
import React from 'react';
import { useSelector } from 'react-redux';
import { isExperiment0045VariantAActiveSelector } from 'redux/modules/experiments/selectors';
import { block } from '../utils';
import EddyProjectCreationContents from './EddyProjectCreationContents';
import EddyProjectCreationUnsupportedBanner from './EddyProjectCreationUnsupportedBanner';

export interface EddyProjectCreationSelectorProps {
  isLanguageSupported: boolean;
  shouldGenerateAssets: boolean;
  onShouldGenerateAssetsChange: (shouldGenerateAssets: boolean) => void;
}

const EddyProjectCreationSelector: React.FC<EddyProjectCreationSelectorProps> = ({
  isLanguageSupported,
  shouldGenerateAssets = true,
  onShouldGenerateAssetsChange,
}) => {
  const isVariantA = useSelector(isExperiment0045VariantAActiveSelector());

  return (
    <ThemeProvider theme="dark">
      {isLanguageSupported ? (
        <Container className={block('creation-selector')}>
          <EddyProjectCreationContents
            {...{
              shouldGenerateAssets,
              onShouldGenerateAssetsChange,
            }}
            title={
              isVariantA ? 'Add FREE promo assets!' : 'Add a FREE transcript!'
            }
            description={
              isVariantA ? (
                <Text
                  variant="p"
                  align="center"
                  className={block('creation-description')}
                >
                  At no additional charge, we can create a <b>transcript</b>,{' '}
                  <b>episode art</b>, <b>keywords</b>, <b>show notes</b>,{' '}
                  <b>social post captions</b>, <b>quotes</b> & much more!
                </Text>
              ) : (
                ''
              )
            }
            imageSrc={
              isVariantA
                ? 'https://static.sparemin.com/static/eddy/promopack-popper-header-image-202312121904.jpg'
                : 'https://static.sparemin.com/static/eddy/promopack-popper-header-transcript-202405311611.png'
            }
          />
        </Container>
      ) : (
        <EddyProjectCreationUnsupportedBanner />
      )}
    </ThemeProvider>
  );
};

export default EddyProjectCreationSelector;
