import cn from 'classnames';
import * as React from 'react';

import { popperBlock as block } from './utils';

export interface PopperProps
  extends Pick<React.HTMLAttributes<HTMLDivElement>, 'onMouseOver'> {
  children?: React.ReactNode;
  className?: string;
  role?: string;
  title?: string;
}

const Popper: React.FC<PopperProps> = ({
  children,
  className,
  role = 'tooltip',
  title,
  onMouseOver,
}) => (
  <div className={cn(block(), className)} {...{ onMouseOver, role }}>
    {title && <span className={block('title')}>{title}</span>}
    {children}
  </div>
);

export default Popper;
