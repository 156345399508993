import { PodcastSubscriptionType, SubscriptionItem } from './types';

/**
 * Converts a `SubscriptionItem` into `PodcastSubscriptionType[]`
 *
 * The backend defines slightly different objects for subscription types depending
 * on whether the POST/PUT endpoint is called or the GET endpoint is called. This
 * function takes a `SubscriptionItem` (GET endpoint), converts it to a
 * `PodcastSubscriptionType` (POST/PUT endpoint), and optionally applies any updates
 * of the shape `Partial<PodcastSubscriptionType>`.
 *
 * The backend also defines an array of subscriptions, but the front end only exposes
 * a single subscription to the user - so this function accepts a single subscription
 * (what is often dealt with in the UI) and returns an array of a single `PodcastSubscriptionType`
 * (what is needed by the API)
 *
 * @param subscriptionItem the original subscription item that's being modified in the
 *  API request
 * @param updates a partial object describing updates to the subscriptionItem
 */
export function createSubscriptionTypes(
  subscriptionItem: SubscriptionItem,
  updates?: Partial<PodcastSubscriptionType>,
): [PodcastSubscriptionType] {
  // no harm in sending fields we don't need to send, but cleaner and less confusing
  // to just omit them
  const {
    autoCreateSubId,
    id,
    videoFrequencyPref,
    videoLengthSecPref,
    ...podcastSubscriptionType
  } = subscriptionItem;

  return [
    {
      ...podcastSubscriptionType,
      // omitting these fields will effectively null them out, which is odd because
      // the pattern the API typically follows is to shallow-merge for PUT requests -
      // meaning videoFrequency and videoLengthSec should only become null if the front-end
      // specifically sends null as the value
      videoFrequency: videoFrequencyPref,
      videoLengthSec: videoLengthSecPref,
      ...updates,
    },
  ];
}
