import React from 'react';
import { IconProps } from './types';

const CreditCard: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 35" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor" fillRule="nonzero">
        <g>
          <path
            d="M69.242 33H31.758C29.68 33 28 34.68 28 36.75v27.5c0 2.07 1.68 3.75 3.758 3.75h37.484C71.32 68 73 66.32 73 64.25v-27.5c0-2.07-1.68-3.75-3.758-3.75zm-37.015 3.75h36.546c.258 0 .47.21.47.469V40.5H31.757v-3.281c0-.258.21-.469.469-.469zm36.546 27.5H32.227c-.258 0-.47-.21-.47-.469V50.5h37.485v13.281c0 .258-.21.469-.469.469zM43 56.437v3.126c0 .515-.422.937-.938.937h-5.624c-.516 0-.938-.422-.938-.938v-3.124c0-.516.422-.938.938-.938h5.624c.516 0 .938.422.938.938zm15 0v3.126c0 .515-.422.937-.938.937H46.438c-.516 0-.938-.422-.938-.938v-3.124c0-.516.422-.938.938-.938h10.624c.516 0 .938.422.938.938z"
            transform="translate(-505.000000, -193.000000) translate(477.000000, 160.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default CreditCard;
