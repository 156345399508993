import { flatten, partial } from 'underscore';

import {
  IManualTranscriptChunk,
  Word,
} from 'redux/middleware/api/transcript-service/types';
import { range } from 'utils/numbers';

export function parseChunk({ subchunks }: IManualTranscriptChunk): Word[] {
  return flatten(
    subchunks.map(subchunk => {
      return subchunk.words.map(word => {
        return interpolate(
          splitString(word.text),
          word.startMillis,
          word.endMillis,
        );
      });
    }),
  );
}

export function splitString(str: string): string[] {
  return str.split(/\s/g);
}

export function interpolate(
  words: string[],
  startMillis: number,
  endMillis: number,
): Word[] {
  const mapIndexToMillis = partial(
    range,
    0,
    words.length,
    startMillis,
    endMillis,
  );

  return words.map((word, i) => ({
    endMillis: Math.floor(mapIndexToMillis(i + 1)),
    startMillis: Math.floor(mapIndexToMillis(i)),
    text: word,
  }));
}
