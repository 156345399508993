import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { VolumeMute, VolumeUp } from 'components/icons';
import Infobox from 'components/Infobox/Infobox';
import ModalLink from 'containers/ModalLink';
import { addingToTrack } from 'redux/modules/embed/actions';
import {
  audioByIdSelector,
  audioTracksSelector,
} from 'redux/modules/embed/selectors';
import { Dispatch } from 'redux/types';

const { useCallback } = React;

export interface AudioTrackInfoboxProps {}

const hasAudioSelector = createSelector(
  audioByIdSelector,
  audioById => !audioById?.isEmpty(),
);

const AudioTrackInfobox: React.FC<AudioTrackInfoboxProps> = () => {
  const dispatch = useDispatch<Dispatch>();
  const hasAudio = useSelector(hasAudioSelector);

  const handleModalLinkClick = useCallback(() => {
    dispatch((_, getState) => {
      const audioTracks = audioTracksSelector(getState());
      dispatch(addingToTrack(audioTracks.first().get('id')));
    });
  }, [dispatch]);

  return (
    <Infobox icon={hasAudio ? <VolumeUp /> : <VolumeMute />} theme="pink">
      {hasAudio ? (
        <span>
          This waveform visualizes your{' '}
          <ModalLink
            onClick={handleModalLinkClick}
            to={{ name: 'EditAudioModal' }}
          >
            audio track
          </ModalLink>
        </span>
      ) : (
        <span>
          No audio track to visualize:{' '}
          <ModalLink
            onClick={handleModalLinkClick}
            to={{ name: 'AddAudioModal' }}
          >
            add one here
          </ModalLink>
        </span>
      )}
    </Infobox>
  );
};

export default AudioTrackInfobox;
