import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import Button from 'components/Button';
import FontAwesome from 'components/FontAwesome';
import { TextField, TextFieldProps } from 'components/Form';
import bem from 'utils/bem';

interface IProps
  extends Pick<TextFieldProps, 'disabled' | 'placeholder' | 'onChange'> {
  buttonText?: string;
  className?: string;
  formLabelText?: string;
  hideButton?: boolean;
  onSubmit?: (value: string) => void;
  theme?: 'dark' | 'light';
  value?: string;
}

export default class SearchInput extends React.Component<IProps> {
  private input;

  public static defaultProps: Partial<IProps> = {
    buttonText: 'search',
    disabled: false,
    formLabelText: '',
    hideButton: false,
    onSubmit: noop,
    theme: 'dark',
  };

  private handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const { onSubmit, value } = this.props;

    // prevents the form submit from reloading the page
    e.preventDefault();

    onSubmit(value);
  };

  private inputRef = el => (this.input = el);

  public focus = () => this.input && this.input.focus();

  private renderForm = () => {
    const {
      buttonText,
      className,
      disabled,
      formLabelText,
      hideButton,
      onChange,
      placeholder,
      theme,
      value,
    } = this.props;

    const block = bem('search-input');

    return (
      <form
        className={cn(
          block({ disabled, 'with-label': formLabelText.length > 0 }),
          className,
        )}
        onSubmit={this.handleSubmit}
      >
        <TextField
          className={block('field')}
          controlClassName={block('control', { light: theme === 'light' })}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          label={formLabelText}
          ref={this.inputRef}
        />
        {!hideButton && (
          <Button
            className={block('button')}
            theme="submit"
            disabled={disabled || !value}
            onClick={this.handleSubmit}
          >
            <span className={block('text')}>{buttonText}</span>
            <FontAwesome className={block('icon')} icon="search" />
          </Button>
        )}
      </form>
    );
  };

  public render() {
    return this.renderForm();
  }
}

export { IProps as SearchInputProps };
