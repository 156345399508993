import * as React from 'react';
import { noop } from 'underscore';

import { SelectField, SelectFieldProps } from 'components/Form';
import { Omit, SlideTransition } from 'types';
import SlideOptionsField from './SlideOptionsField';

interface IProps extends Omit<SelectFieldProps, 'label' | 'onChange'> {
  className?: string;
  onChange?: (
    value: SlideTransition,
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
}

const SlideTransitionField: React.SFC<IProps> = ({ className, ...props }) => (
  <SlideOptionsField className={className}>
    <SelectField {...props} label="Entry Transition">
      <option value="fade">Dissolve</option>
      <option value="wipe">Wipe</option>
      <option value="fadeIn">Fade in</option>
      <option value="slideRight">Slide Right</option>
      <option value="slideLeft">Slide Left</option>
      <option value="slideUp">Slide Up</option>
      <option value="slideDown">Slide Down</option>
      <option value="cut">None</option>
    </SelectField>
  </SlideOptionsField>
);

SlideTransitionField.defaultProps = {
  onChange: noop,
};

export default SlideTransitionField;
export { IProps as SlideTransitionFieldProps };
