import { GradientIcon, Heading } from '@sparemin/blockhead';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import DialogContent from 'components/DialogContent';
import { ClosedCaptioning } from 'components/icons';
import useContextualUpgrade from 'hooks/useContextualUpgrade';
import { onUnlockThisFeature } from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal';

import { block } from './utils';

const { useCallback } = React;

const CaptionsUpsellModalContents = () => {
  const { onUpgrade } = useContextualUpgrade({ openModal: replaceModal });
  const dispatch = useDispatch();

  const handleUpgrade = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      dispatch(onUnlockThisFeature('AddCaptions'));
      onUpgrade();
    },
    [dispatch, onUpgrade],
  );

  return (
    <DialogContent
      title="Add captions to your videos"
      icon={
        <GradientIcon>
          <ClosedCaptioning color="#282e38" />
        </GradientIcon>
      }
      details={
        <Heading level={2}>
          Increase the accessibility of your videos by adding captions.
        </Heading>
      }
      actions={[
        {
          children: 'unlock this feature',
          key: 'unlock-cta',
          onClick: handleUpgrade,
          theme: 'rainbow',
        },
      ]}
      className={block('contents')}
      detailsClassName={block('details')}
    />
  );
};

export default CaptionsUpsellModalContents;
