import * as podcastService from 'redux/middleware/api/podcast-service/actions';
import { IApiResponse } from 'redux/middleware/api/types';
import { ThunkAction } from 'redux/types';

import { SelectableAutogramFrequency } from 'types';
import { EditAutomationActionTypes } from './action-types';
import {
  AudioSelectionType,
  EditAutomationInitAudioSelection,
  EditAutomationInitOutput,
  EditAutomationSetAudioSelectionDuration,
  EditAutomationSetAudioSelectionType,
  EditAutomationSetOutputOption,
  EditAutomationStartingPointChangeAction,
  PartialUpdateAutomationData,
} from './types';
import { DurationOption, OutputOption } from '.';

export const editAutomation = (
  { subscriptionId, subscriptionTypes }: PartialUpdateAutomationData,
  automationDataUpdater?: () => Promise<void>,
): ThunkAction<Promise<IApiResponse<void>>> => async dispatch => {
  dispatch({ type: EditAutomationActionTypes.EDIT_AUTOMATION_UPDATE_REQUEST });

  try {
    const result = await dispatch(
      podcastService.updatePodcastSubscription({
        subscriptionId,
        subscriptionTypes,
      }),
    );
    if (automationDataUpdater) {
      await automationDataUpdater();
    }
    dispatch({
      type: EditAutomationActionTypes.EDIT_AUTOMATION_UPDATE_SUCCESS,
    });
    return result;
  } catch (err) {
    dispatch({
      type: EditAutomationActionTypes.EDIT_AUTOMATION_UPDATE_FAILURE,
    });
    throw err;
  }
};

export const editAutomationInitAudioSelection = (
  type: AudioSelectionType,
  duration?: DurationOption,
): EditAutomationInitAudioSelection => ({
  type: EditAutomationActionTypes.EDIT_AUTOMATION_INIT_AUDIO_SELECTION,
  payload: { duration, type },
});

export const editAutomationSetAudioSelectionType = (
  type: AudioSelectionType,
): EditAutomationSetAudioSelectionType => ({
  type: EditAutomationActionTypes.EDIT_AUTOMATION_SET_AUDIO_SELECTION_TYPE,
  payload: { type },
});

export const editAutomationSetAudioSelectionDuration = (
  duration?: DurationOption,
): EditAutomationSetAudioSelectionDuration => ({
  type: EditAutomationActionTypes.EDIT_AUTOMATION_SET_AUDIO_SELECTION_DURATION,
  payload: { duration },
});

export const editAutomationInitOutput = (
  option: OutputOption,
): EditAutomationInitOutput => ({
  type: EditAutomationActionTypes.EDIT_AUTOMATION_INIT_OUTPUT,
  payload: { option },
});

export const editAutomationSetOutputOption = (
  option: OutputOption,
): EditAutomationSetOutputOption => ({
  type: EditAutomationActionTypes.EDIT_AUTOMATION_SET_OUTPUT_OPTION,
  payload: { option },
});

export const editAutomationChangeStartingPoint = (
  frequency: SelectableAutogramFrequency,
): EditAutomationStartingPointChangeAction => ({
  type: EditAutomationActionTypes.EDIT_AUTOMATION_STARTING_POINT_CHANGE,
  payload: { frequency },
});
