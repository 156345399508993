import * as React from 'react';

import { IconProps } from './types';

const Twitter: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="13"
    viewBox="0 0 15 13"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M13.458 3.24c.01.142.01.284.01.426C13.468 8.003 10.374 13 4.72 13A8.296 8.296 0 0 1 0 11.527c.247.03.485.041.742.041 1.438 0 2.76-.518 3.817-1.402-1.352-.03-2.484-.975-2.874-2.275a3.06 3.06 0 0 0 1.39-.06C1.664 7.525.608 6.204.608 4.61v-.04c.41.243.885.395 1.39.416-.828-.59-1.37-1.595-1.37-2.732 0-.61.151-1.168.418-1.656C2.56 2.59 4.835 3.89 7.386 4.032a3.942 3.942 0 0 1-.076-.752C7.31 1.473 8.68 0 10.384 0a2.98 2.98 0 0 1 2.246 1.036 5.827 5.827 0 0 0 1.951-.792 3.242 3.242 0 0 1-1.351 1.808A5.86 5.86 0 0 0 15 1.544a6.868 6.868 0 0 1-1.542 1.696z"
    />
  </svg>
);

export default Twitter;
