import cn from 'classnames';
import React, { useCallback } from 'react';
import { ProFeatureTooltipContent } from 'blocks/Pricing';
import Tooltip from 'components/Tooltip';
import { ToggleCardProvider } from './ToggleCardContext';
import ToggleCardInfo from './ToggleCardInfo';
import ToggleCardToggler from './ToggleCardToggler';
import { ToggleCardProps } from './types';
import { block, getPaidToggleCardProp, getTheme } from './utils';

const ToggleCard: React.FC<ToggleCardProps> = props => {
  const {
    checked,
    children,
    className,
    icon,
    info,
    isPro: isProCard = false,
    onChange,
    isDisabled = false,
    customTooltipContent,
  } = props;

  const theme = getTheme(props);
  const hasAccess = getPaidToggleCardProp(props, 'hasAccess');
  const isProGated = isProCard && !hasAccess;

  const handleChange = useCallback(() => {
    !isDisabled && onChange(!checked);
  }, [checked, isDisabled, onChange]);

  const card = (
    <div
      className={cn(
        block({ checked, [theme]: !!theme, disabled: isDisabled }),
        className,
      )}
      onClick={!checked ? handleChange : undefined}
    >
      <div className={block('body')}>
        <ToggleCardToggler
          onClick={checked ? handleChange : undefined}
          isDisabled={isDisabled}
        />
        <ToggleCardInfo icon={icon} hidden={!!children && checked}>
          {info}
        </ToggleCardInfo>
        {children && (
          <div className={block('options', { checked })}>{children}</div>
        )}
      </div>
    </div>
  );

  return (
    <ToggleCardProvider {...{ checked, isProGated, theme }}>
      {!isProGated && !customTooltipContent ? (
        card
      ) : (
        <Tooltip
          content={isProGated ? ProFeatureTooltipContent : customTooltipContent}
          placement="top"
        >
          {card}
        </Tooltip>
      )}
    </ToggleCardProvider>
  );
};

export default ToggleCard;
