import { List, Map, Record, RecordOf, Set } from 'immutable';
import { partial } from 'underscore';
import { AnyObject } from 'types';
import { omitUndefined } from 'utils/collections';
import { withValue } from 'utils/control';
import { reviveMap, reviveRecord } from 'utils/immutable';

import { PodcastEpisodeData, PodcastFeedData, State, Templates } from './types';

export const templatesFactory = Record<Templates>(
  {
    landscape: undefined,
    portrait: undefined,
    square: undefined,
  },
  'AutomationCmsTemplatesRecord',
);

export const podcastFeedDataFactory = Record<PodcastFeedData>({
  episodes: List(),
  status: undefined,
  number: undefined,
  pageSize: undefined,
  totalElements: undefined,
  totalPages: undefined,
  workflows: undefined,
  canRefreshFeed: undefined,
});

export const podcastEpisodeDataFactory = Record<PodcastEpisodeData>({
  isFetching: undefined,
  projects: undefined,
});

export const stateFactory = Record<State>(
  {
    createTranscriptionEpisodeId: undefined,
    isFetchingPodcastFeeds: undefined,
    isLoadingTemplates: undefined,
    isLoadingSeasons: undefined,
    isPollingEpisodeTranscriptions: false,
    isPollingSubscriptionPreview: false,
    isUpdatingRevokedPermissions: false,
    manualEpisodeTriggerStatuses: Map(),
    podcastEpisodeData: Map(),
    podcastFeedData: Map(),
    podcastFeedIds: undefined,
    pollTranscriptionEpisodeIds: Set(),
    subscriptionPreviewData: undefined,
    subscriptionPreviewPollingId: undefined,
    templates: templatesFactory(),
    seasons: undefined,
    totalEpisodes: undefined,
    workflows: undefined,
  },
  'AutomationCmsRecord',
);

export function stateFromJs(value: AnyObject = {}): RecordOf<State> {
  const {
    podcastEpisodeData,
    podcastFeedData,
    templates,
    ...restValue
  } = value;

  return reviveRecord(
    stateFactory,
    omitUndefined({
      ...restValue,
      podcastEpisodeData: withValue(
        podcastEpisodeData,
        partial(reviveMap, partial(reviveRecord, podcastFeedDataFactory)),
      ),
      podcastFeedData: withValue(
        podcastFeedData,
        partial(reviveMap, partial(reviveRecord, podcastFeedDataFactory)),
      ),
      templates: withValue(templates, partial(reviveRecord, templatesFactory)),
    }),
  );
}
