import React, { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import Banner from 'components/Banner';
import Button from 'components/Button';
import { CreditCard } from 'components/icons';
import {
  onClickRetryPeriodBanner,
  onShowRetryPeriodBanner,
} from 'redux/modules/mixpanel';
import { pushModal } from 'redux/modules/modal';
import bem from 'utils/bem';

export interface CreditCardRecoveryBannerProps {
  className?: string;
}

const block = bem('credit-card-recovery-banner');

const CreditCardRecoveryBanner: React.FC<CreditCardRecoveryBannerProps> = ({
  className,
}) => {
  const dispatch = useDispatch();

  const handleUpdateCtaClick = useCallback(() => {
    dispatch(onClickRetryPeriodBanner());
    dispatch(pushModal({ name: 'UpdateCreditCard' }));
  }, [dispatch]);

  const handleGiveUpPlanCtaClick = useCallback(() => {
    dispatch(pushModal({ name: 'ExitRetryPeriod' }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(onShowRetryPeriodBanner());
  }, [dispatch]);

  return (
    <Banner
      className={className}
      icon={<CreditCard className={block('icon')} />}
      theme="danger"
    >
      <div>
        <Banner.Title>Oh no, your payment failed…</Banner.Title>
        <Banner.Content>
          To regain your paid features, just update your payment information.
        </Banner.Content>
      </div>
      <Banner.Actions>
        <Button onClick={handleUpdateCtaClick} theme="submit">
          update your credit card
        </Button>
        <Button
          onClick={handleGiveUpPlanCtaClick}
          theme="ghost"
          className={block('give-up-plan-button')}
        >
          give up your plan
        </Button>
      </Banner.Actions>
    </Banner>
  );
};

export default CreditCardRecoveryBanner;
