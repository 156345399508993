import { LocationDescriptor } from 'history';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';

function isEvent(
  e: LocationDescriptor | React.SyntheticEvent,
): e is React.SyntheticEvent {
  return (e as any)?.nativeEvent instanceof Event;
}

export default function useRedirectOnClose() {
  const history = useHistory();
  const [redirectTo, setRedirectTo] = useState<LocationDescriptor>(undefined);

  const onClickLink = useCallback(
    (e: LocationDescriptor | React.SyntheticEvent) => {
      const to = isEvent(e) ? (e?.target as any)?.href : e;
      setRedirectTo(to);

      if (isEvent(e)) {
        e.preventDefault();
        setRedirectTo((e?.target as any)?.href);
      } else {
        setRedirectTo(e);
      }
    },
    [],
  );

  const onExited = useCallback(() => {
    if (redirectTo) {
      history.push(redirectTo);
      setRedirectTo(undefined);
    }
  }, [history, redirectTo]);

  return {
    onClickLink,
    onExited,
  };
}
