import * as React from 'react';

import { useDispatch } from 'react-redux';
import ProjectDeleteModal from 'containers/ProjectDeleteModal';
import { pushModal } from 'redux/modules/modal/actions';
import { showError } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import EmptyProjectList from '../../components/EmptyProjectList';
import ProjectList from '../../components/ProjectList';
import { ProjectTileControl } from '../../types';
import { block } from '../../utils';

interface IProps {
  isLoadingHistory: boolean;
  onDownloadProject?: (id: string) => void;
  onCopyProject?: (id: string, name: string, aspectRatio) => void;
  onDeleteProject?: (id: string) => void;
  onItemsRendered?: () => void;
  projects: string[];
}

const ProjectHistory: React.FC<IProps> = props => {
  const {
    isLoadingHistory,
    onItemsRendered,
    projects,
    onCopyProject,
    onDeleteProject,
    onDownloadProject,
  } = props;
  const dispatch = useDispatch<Dispatch>();
  const handleControlClick = async (
    type: ProjectTileControl,
    projectId: string,
  ) => {
    switch (type) {
      case 'delete':
        dispatch(
          pushModal({
            name: 'ProjectDelete',
            params: { projectId },
          }),
        );
        break;

      case 'copy': {
        try {
          const copyResult = await dispatch(
            pushModal({
              name: 'ProjectCopy',
              params: { projectId },
            }),
          );

          if (copyResult) {
            const { aspectRatio, projectName } = copyResult;
            onCopyProject(projectId, projectName, aspectRatio);
          }
        } catch {
          dispatch(
            showError({
              title: 'Something went wrong',
              message: 'Please try again',
              dismissAfterSec: 5,
            }),
          );
        }
        break;
      }

      case 'download':
        onDownloadProject(projectId);
        break;

      default:
    }
  };

  return (
    <div className={block()}>
      {(() => {
        if (isLoadingHistory === undefined || (isLoadingHistory && !projects)) {
          return null;
        }

        if (!projects || projects.length === 0) {
          return <EmptyProjectList />;
        }

        return (
          <ProjectList
            onControlClick={handleControlClick}
            onItemsRendered={onItemsRendered}
            projects={projects}
          />
        );
      })()}
      <ProjectDeleteModal onSubmit={onDeleteProject} />
    </div>
  );
};

export { IProps as ProjectHistoryProps };
export default ProjectHistory;
