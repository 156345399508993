import cn from 'classnames';
import * as React from 'react';

import { cloneOnlyChild } from 'utils/react';
import { block } from './utils';

export interface PlanActionProps {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const PlanAction: React.SFC<PlanActionProps> = ({
  children,
  className,
  onClick,
}) =>
  cloneOnlyChild(children, child => ({
    className: cn(block('action'), className, child.props.className),
    ...(onClick ? { onClick } : undefined),
  }));

export default PlanAction;
