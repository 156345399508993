import * as React from 'react';
import { noop } from 'underscore';

import { UploadField, UploadFieldProps } from 'components/Form';
import { FileRejectedError } from 'components/UploadButton/UploadInput';
import { Omit } from 'types';
import { INTRO_OUTRO_MAX_DURATION_SECS } from 'utils/constants';
import { getDuration } from 'utils/video';

import { HANDLED_MESSAGE_FILE_ERR_CODES } from './utils';
import VideoExportFormField from './VideoExportFormField';

interface IUploadArgs {
  file: File;
  isRejected: boolean;
  code?: string;
  reason?: string;
}

type UploadProps = Omit<
  UploadFieldProps,
  'accept' | 'onClear' | 'onFileAccepted' | 'onFileRejected' | 'pro'
>;

interface IProps extends UploadProps {
  onChange?: (val: IUploadArgs) => void;
  onUpgradePlan: () => void;
  value?: string;
}

export default class UploadVideoField extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onChange: noop,
  };

  private handleFileRejected = (error: FileRejectedError) => {
    const reason = HANDLED_MESSAGE_FILE_ERR_CODES.includes(error.code)
      ? `Make sure your {type} is not greater than ${
          spareminConfig.introOutroClipMaxMb
        }MB or ${spareminConfig.introOutroClipMaxMins} minute${
          spareminConfig.introOutroClipMaxMins > 1 ? 's' : ''
        }`
      : error.message;
    this.props.onChange({
      file: error.file,
      code: error.code,
      reason,
      isRejected: true,
    });
  };

  private handleFileChange = (file?: File) => {
    this.props.onChange({
      file,
      isRejected: false,
    });
  };

  private handleFileAccepted = (file?: File) => {
    if (file) {
      // once file is accepted duration is checked, if it is not within the accepted limits
      // it will be rejected
      getDuration(file, duration => {
        if (duration > INTRO_OUTRO_MAX_DURATION_SECS) {
          this.handleFileRejected(
            new FileRejectedError(
              'Duration exceeds max duration',
              'IN008',
              file,
            ),
          );
        } else {
          this.handleFileChange(file);
        }
      });
    } else {
      this.handleFileChange(file);
    }
  };

  public render() {
    const { onChange, onUpgradePlan, value, ...props } = this.props;

    return (
      <VideoExportFormField xs={12} sm={6}>
        <UploadField
          {...props}
          onClear={this.handleFileAccepted}
          onFileAccepted={this.handleFileAccepted}
          onFileRejected={this.handleFileRejected}
          onUpgradePlan={onUpgradePlan}
          accept=".mov,.mp4,.wmv"
          maxSizeMb={spareminConfig.introOutroClipMaxMb}
          value={value}
          pro
        />
      </VideoExportFormField>
    );
  }
}

export { IProps as UploadVideoFieldProps, IUploadArgs as UploadArgs };
