export enum Type {
  PROJECT_TEMPLATES_GET_REQUEST = 'app/project-templates/PROJECT_TEMPLATES_GET_REQUEST',
  PROJECT_TEMPLATES_GET_SUCCESS = 'app/project-templates/PROJECT_TEMPLATES_GET_SUCCESS',
  PROJECT_TEMPLATES_GET_FAILURE = 'app/project-templates/PROJECT_TEMPLATES_GET_FAILURE',

  PROJECT_TEMPLATES_CLEAR = 'app/project-templates/PROJECT_TEMPLATES_CLEAR',

  PROJECT_TEMPLATES_CREATE_REQUEST = 'app/project-templates/PROJECT_TEMPLATES_CREATE_REQUEST',
  PROJECT_TEMPLATES_CREATE_SUCCESS = 'app/project-templates/PROJECT_TEMPLATES_CREATE_SUCCESS',
  PROJECT_TEMPLATES_CREATE_FAILURE = 'app/project-templates/PROJECT_TEMPLATES_CREATE_FAILURE',

  PROJECT_TEMPLATES_DELETE_REQUEST = 'app/project-templates/PROJECT_TEMPLATES_DELETE_REQUEST',
  PROJECT_TEMPLATES_DELETE_SUCCESS = 'app/project-templates/PROJECT_TEMPLATES_DELETE_SUCCESS',
  PROJECT_TEMPLATES_DELETE_FAILURE = 'app/project-templates/PROJECT_TEMPLATES_DELETE_FAILURE',
}
