import React from 'react';
import { H1, H4 } from 'components/Heading';
import { block } from '../utils';

interface VSOptionsHeadingProps {
  title: string;
  subtitle: string;
}

function VSOptionsHeading({ title, subtitle }: VSOptionsHeadingProps) {
  return (
    <div className={block('heading')}>
      <H1 className={block('heading-title')}>{title}</H1>
      <H4 className={block('heading-subtitle')}>{subtitle}</H4>
    </div>
  );
}

export default VSOptionsHeading;
