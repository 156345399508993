import cn from 'classnames';
import React from 'react';

import bem from 'utils/bem';
import IconButton, { IconButtonProps } from './IconButton';
import { Close, Escape } from './icons';

export interface CloseIconButtonProps {
  className?: string;
  onClick?: IconButtonProps['onButtonClick'];
  showLabel?: boolean;
}

const block = bem('close-icon-button');

const CloseIconButton: React.FC<CloseIconButtonProps> = ({
  className,
  onClick,
  showLabel,
}) => (
  <IconButton className={cn(block(), className)} onButtonClick={onClick}>
    <Close style={{ height: 35, width: 35 }} />
    {showLabel && (
      <div className={block('label-container')}>
        <Escape style={{ width: 22 }} />
      </div>
    )}
  </IconButton>
);

export default CloseIconButton;
