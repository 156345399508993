import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  AudioClipperComponent,
  RegionUpdateAction,
} from 'components/AudioClipper';
import {
  dislikedSuggestionIdsSelector,
  submitStatusSelector,
  suggestionIdsSelector,
  suggestionsStatusSelector,
} from 'redux/modules/clip-select/selectors';
import { RequestStatus } from 'types/common';
import {
  AudioPlayer,
  ClipSelectContextType,
  ClipSelectionType,
  FormValues,
  Region,
} from '../types';
import { useClipSelectNavigation } from './ClipSelectNavigationContext/ClipSelectNavigationContext';

const ClipSelectContext = React.createContext<ClipSelectContextType>(null);

interface Props {
  onSubmit: (values: FormValues) => void;
}

const { useRef, useState } = React;

export const ClipSelectProvider: React.FC<Props> = props => {
  const { children, onSubmit } = props;

  const suggestionIds = useSelector(suggestionIdsSelector);
  const dislikedSuggestionIds = useSelector(dislikedSuggestionIdsSelector);
  const status = useSelector(suggestionsStatusSelector);
  const submitStatus = useSelector(submitStatusSelector);
  const [removedSuggestionIds, setRemovedSuggestionIds] = useState<number[]>(
    [],
  );
  const visibleSuggestionIds = useMemo(
    () => suggestionIds?.filter(id => !removedSuggestionIds.includes(id)),
    [removedSuggestionIds, suggestionIds],
  );
  const [region, setRegion] = useState<Region>(null);
  const [selectedRegion, setSelectedRegion] = useState<Region>(null);
  const [playing, setPlaying] = useState(false);
  const [activeSuggestionId, setActiveSuggestionId] = useState<number>(
    undefined,
  );
  const playerRef = useRef<AudioPlayer>();
  const clipperRef = useRef<AudioClipperComponent>();
  const lastSelectionTypeRef = useRef<ClipSelectionType>(
    ClipSelectionType.DEFAULT,
  );
  const [playbackMillis, setPlaybackMillis] = useState(0);
  const [navState, send] = useClipSelectNavigation();

  useEffect(() => {
    if (suggestionIds && !activeSuggestionId) {
      setActiveSuggestionId(suggestionIds[0]);
    }
  }, [activeSuggestionId, suggestionIds]);

  useEffect(() => {
    if (status === RequestStatus.SUCCESS && suggestionIds) {
      send({
        type: 'DATA_LOAD_SUCCESS',
        payload: { suggestionCount: suggestionIds.length },
      });

      if (suggestionIds.length === 0) {
        setRegion({ startMillis: 0, endMillis: 1000 });
      }
    }
  }, [send, status, suggestionIds]);

  useEffect(() => {
    if (visibleSuggestionIds?.length === 0) {
      setRegion({ startMillis: 0, endMillis: 1000 });
    }
  }, [visibleSuggestionIds]);

  useEffect(() => {
    if (submitStatus === RequestStatus.SUCCESS) {
      send({ type: 'SUBMIT_SUCCESS' });
    }
  });

  useEffect(() => {
    if (!navState.matches('select')) {
      playerRef.current?.pause();
      setPlaying(false);
    }
    if (!navState.matches('clip')) {
      clipperRef.current?.pause();
    }
  }, [navState]);

  const onSuggestionSelect = (suggestionRegion: Region) => {
    lastSelectionTypeRef.current = ClipSelectionType.SUGGESTED_CLIP;
    playerRef.current?.seek(suggestionRegion.startMillis);
    setRegion(suggestionRegion);
    setSelectedRegion(suggestionRegion);
  };

  const onRegionChange = (value: Region, action?: RegionUpdateAction) => {
    if (action !== 'init') {
      lastSelectionTypeRef.current = ClipSelectionType.CUSTOM_CLIP;
      setRegion(value);
    }
  };

  const handleAdjust = useCallback(() => {
    onRegionChange(selectedRegion);
    send({ type: 'ADJUST_CLICK' });
  }, [selectedRegion, send]);

  const handleSubmit = async () => {
    playerRef.current?.pause();
    clipperRef.current?.pause();

    onSubmit({
      region,
      lastClipSelectionType: lastSelectionTypeRef.current,
      isCaptionEnabled: true,
    });
  };

  const onAudioProcess = useCallback(
    (millis: number) => {
      setPlaybackMillis(millis);

      if (millis >= selectedRegion?.endMillis) {
        playerRef.current.seek(selectedRegion.startMillis);
      }
    },
    [selectedRegion],
  );

  const togglePlayback = useCallback(() => {
    if (playing) {
      playerRef.current.pause();
      setPlaying(false);
    } else {
      // business requirement that suggestions should always start playing from the beginning
      playerRef.current.seek(region.startMillis);
      playerRef.current.play();
      setPlaying(true);
    }
  }, [playing, region]);

  const removeSuggestion = useCallback((id: number) => {
    setRemovedSuggestionIds(ids => ids.concat(id));
  }, []);

  return (
    <ClipSelectContext.Provider
      value={{
        activeSuggestionId,
        dislikedSuggestionIds,
        playerRef,
        onAudioProcess,
        clipperRef,
        onRegionChange,
        onSuggestionSelect,
        region,
        selectedRegion,
        submitStatus,
        suggestionIds,
        status,
        playbackMillis,
        playing,
        removeSuggestion,
        removedSuggestionIds,
        setActiveSuggestionId,
        togglePlayback,
        visibleSuggestionIds,
        onAdjust: handleAdjust,
        onSubmit: handleSubmit,
      }}
    >
      {children}
    </ClipSelectContext.Provider>
  );
};

export function useClipPlayer() {
  const context = useContext(ClipSelectContext);

  if (context === null) {
    throw new Error('useClipPlayer must be used within a ClipSelectProvider');
  }

  const { playerRef, onAudioProcess } = context;

  return {
    playerRef,
    onAudioProcess,
  };
}

export function useClipSelect() {
  const context = useContext(ClipSelectContext);

  if (context === null) {
    throw new Error('useClipSelect must be used within a ClipSelectProvider');
  }

  const { playerRef, onAudioProcess, ...rest } = context;

  return rest;
}

export default ClipSelectContext;
