import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Carousel from 'components/Carousel';
import Modal from 'components/Modal';
import { watermarkOverrideSelector } from 'redux/modules/display-pref/selectors';
import { replaceWatermark } from 'redux/modules/embed/actions';
import {
  aspectRatioNameSelector,
  watermarkStatusSelector,
} from 'redux/modules/embed/selectors';
import { hideModal } from 'redux/modules/modal/actions';
import { Dispatch } from 'redux/types';
import { WatermarkOption } from 'types';
import LockedWatermarkOption from './LockedWatermarkOption';

const { useState } = React;

interface Props {}

const LockedWatermarkSelectModalContent: React.FC<Props> = () => {
  const dispatch = useDispatch<Dispatch>();
  const aspectRatioName = useSelector(aspectRatioNameSelector);
  const overrides = useSelector(watermarkOverrideSelector);
  const status = useSelector(watermarkStatusSelector);
  const [selected, setSelected] = useState<WatermarkOption>(null);

  const onSubmit = async () => {
    await dispatch(
      replaceWatermark(selected.url, selected.position, selected.size),
    );
    dispatch(hideModal());
  };

  return (
    <>
      <Modal.Body>
        <Carousel
          dots={false}
          slidesToShow={1}
          slidesToScroll={1}
          variableWidth
        >
          {overrides?.map(option => (
            <LockedWatermarkOption
              key={option.displayName}
              value={option}
              onToggle={setSelected}
              checked={option === selected}
              aspectRatioName={aspectRatioName}
            />
          ))}
        </Carousel>
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton
            onClick={onSubmit}
            disabled={!selected || !!status}
            theme="submit"
          >
            Add to video
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default LockedWatermarkSelectModalContent;
