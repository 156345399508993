import React from 'react';

// types
import { IconProps } from './types';

const Back: React.FunctionComponent<IconProps> = ({ ...svgProps }) => (
  <svg viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
    <path
      d="m9.772 14.928-.842.814a.93.93 0 0 1-1.287 0L.267 8.622a.855.855 0 0 1 0-1.241L7.643.258a.93.93 0 0 1 1.287 0l.842.814a.86.86 0 0 1-.015 1.256L5.185 6.534h10.904c.505 0 .911.392.911.88v1.172c0 .488-.406.88-.91.88H5.184l4.572 4.206c.372.34.38.908.015 1.256z"
      fill="#DCE1EB"
      fillRule="nonzero"
    />
  </svg>
);

export default Back;
