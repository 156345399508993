import React, { FC, useCallback, useState } from 'react';
import { last } from 'underscore';
import ShareFormTextarea from 'components/ShareForm/ShareFormTextarea';

import { block } from '../utils';
import { AddCategoriesFromFeed } from './AddCategoriesFromFeed';
import { Tags, TagsProps } from './types';
import {
  calculateTagsTextLength,
  convertTagsListToString,
  getTagsList,
  MAX_CHARS_TAGS,
  MAX_CHARS_TAGS_EXTRA,
} from './utils';

const TagsInput: FC<TagsProps> = ({
  defaultValue,
  readonly,
  onChange,
  isMetadataEnabled,
  hideProgress,
  showLabels = true,
  showUseCategoriesButton = true,
}) => {
  const MAX_CHARS_TAGS_FINAL = isMetadataEnabled
    ? MAX_CHARS_TAGS_EXTRA
    : MAX_CHARS_TAGS;

  const getDefaultState = useCallback((): Tags => {
    return {
      text: convertTagsListToString(defaultValue),
      list: defaultValue || [],
      tagsLength: calculateTagsTextLength(defaultValue),
    };
  }, [defaultValue]);

  const [tags, setTags] = useState<Tags>(getDefaultState());
  const [prevTags, setPrevTags] = useState<Tags>(tags);

  const handleTagsFormatting = useCallback(
    (newTags: string): Tags | void => {
      const newTagsList = getTagsList(newTags);
      const newTagsLength = calculateTagsTextLength(newTagsList);

      if (newTagsLength <= MAX_CHARS_TAGS_FINAL) {
        const newTagsObject = {
          text: newTags,
          // Filtering the tags list and only returning non-empty tags.
          // The filter also helps to control scenarios in which the user adds
          // the optional comma at the end of all tags.
          // Doing this here to not affected the characters count because
          // the filter will ignore all commas (which are included in the count).
          list: newTagsList.filter((tag: string) => tag),
          tagsLength: newTagsLength,
        };

        setTags(newTagsObject);

        return newTagsObject;
      }

      return null;
    },
    [MAX_CHARS_TAGS_FINAL],
  );

  const handleTagsChange = useCallback(
    (newTags: string): void => {
      const formattedTags = handleTagsFormatting(newTags);

      if (formattedTags) onChange(formattedTags.list);
    },
    [handleTagsFormatting, onChange],
  );

  const handleCategoriesFromFeed = useCallback(
    (tagsFromFeed: string): void => {
      let newTags: string;

      // If there is no previous tags added, then the new tags will be tagsFromFeed.
      if (!tags.tagsLength) newTags = tagsFromFeed;
      // If there is tags added, concatenating current tags with tagsFromFeed and
      // checking if there's any comma after the last tag in order to avoid duplicated commas.
      else
        newTags = `${tags.text}${
          last(tags.text) === ',' ? ' ' : ', '
        }${tagsFromFeed}`;

      handleTagsChange(newTags);
    },
    [handleTagsChange, tags.tagsLength, tags.text],
  );

  // Converting 'defaultValue' to a string similar to 'prevTags.text' in order
  // to compare the texts for both values. It is not possible to compare both arrays
  // (defaultValue and prevTags.list) because of the commas count. That would prevent the
  // user from adding commas to separate the tags.
  if (convertTagsListToString(defaultValue) !== prevTags.text) {
    setPrevTags(tags);
    setTags(getDefaultState());
  }

  return (
    <>
      <ShareFormTextarea
        label={showLabels && 'Tags'}
        labelHint={
          showLabels &&
          (isMetadataEnabled
            ? '(up to 500 characters, enter a comma after each tag)'
            : '(enter a comma after each tag)')
        }
        onChange={handleTagsChange}
        value={tags.text}
        type="input"
        className={block('tags')}
        placeholder="Add tags"
        readonly={readonly}
        hideProgress={isMetadataEnabled || readonly || hideProgress}
        maxChars={MAX_CHARS_TAGS_FINAL}
        countRemaining={() => tags?.tagsLength}
      />

      {showUseCategoriesButton && (
        <AddCategoriesFromFeed
          isMetadataEnabled={isMetadataEnabled}
          onClick={handleCategoriesFromFeed}
        />
      )}
    </>
  );
};

export default TagsInput;
