import * as React from 'react';

import { block } from '../utils';

export interface DownloadDetailsSectionProps {
  children?: React.ReactNode;
}

const DownloadDetailsSection: React.SFC<DownloadDetailsSectionProps> = ({
  children,
}) => <div className={block('details-section')}>{children}</div>;

export default DownloadDetailsSection;
