import { YoutubePlaylist } from 'redux/middleware/api/third-party-authentication-service/types';
import bem from 'utils/bem';
import { YoutubePlaylistSelectorOption } from './types';

export const block = bem('youtube-playlist-selector');

export function playlistToOption(selectedIds: Set<string>) {
  return (playlist: YoutubePlaylist): YoutubePlaylistSelectorOption => {
    return {
      ...playlist,
      label: playlist.title,
      value: playlist.id,
      selected: selectedIds.has(playlist.id),
    };
  };
}

export function getSelectedPlaylistsIds(
  playlists: YoutubePlaylist[],
  selectedIds: Set<string>,
): string[] {
  return playlists.filter(({ id }) => selectedIds.has(id)).map(({ id }) => id);
}

export const formatPlaylistOptions = (
  options: YoutubePlaylist[] = [],
  selectedIds: Set<string>,
): YoutubePlaylistSelectorOption[] =>
  options.map(playlistToOption(selectedIds));
