import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import BackCatalogDisclaimerModalContents from './BackCatalogDisclaimerModalContents';
import { block } from './utils';

export interface BackCatalogDisclaimerModalProps {}

/**
 * When a user attempts to cancel their plan with an automation that starts from
 * the first episode (back catalog), this modal warns them that their automation
 * will be paused if they downgrade/cancel.
 */
const BackCatalogDisclaimerModal: React.FC<BackCatalogDisclaimerModalProps> = () => (
  <ConnectedModal
    name="BackCatalogDisclaimer"
    title="cancel my plan"
    className={block()}
  >
    <BackCatalogDisclaimerModalContents />
  </ConnectedModal>
);

export default BackCatalogDisclaimerModal;
