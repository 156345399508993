import { PropsOf } from 'types';
import connect from './connect';
import RevisionHistory, { IProps, IState } from './RevisionHistory';

export default connect(RevisionHistory);

export type RevisionHistoryProps = PropsOf<ReturnType<typeof connect>>;

export { IProps as IRevisionHistoryProps };
export { IState as IRevisionHistoryState };
