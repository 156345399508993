import { createContext } from 'react';

export interface TranscriptionFormValue {
  transcribe?: boolean;
  language?: string;
}

interface ITranscriptionFormContext {
  disabled: boolean;
  value: TranscriptionFormValue;
  onChange: (value: TranscriptionFormValue) => void;
}

const TranscriptionFormContext = createContext<ITranscriptionFormContext>(null);

export default TranscriptionFormContext;
