import * as React from 'react';
import { TabTitleProps } from './types';

class TabTitle extends React.Component<TabTitleProps> {
  public static defaultProps: Partial<TabTitleProps> = {
    className: undefined,
  };

  private handleClick = () => {
    const { onSelect, tabKey } = this.props;
    onSelect(tabKey);
  };

  public render() {
    const { className, title } = this.props;
    return (
      <li className={className} onClick={this.handleClick}>
        {title}
      </li>
    );
  }
}

export default TabTitle;
