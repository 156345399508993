import * as React from 'react';

import { IconProps } from './types';

const VolumeMute: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 15" {...props}>
    <path
      fill="currentColor"
      d="M7.98.261L4.678 3.562H.891c-.492 0-.891.399-.891.891v5.344c0 .492.399.89.89.89h3.788l3.302 3.301c.557.558 1.52.166 1.52-.63V.892c0-.796-.963-1.187-1.52-.63zm9.151 6.864l1.694-1.694c.233-.234.233-.613 0-.847l-.847-.846c-.234-.234-.613-.234-.847 0L15.437 5.43l-1.693-1.693c-.234-.234-.613-.234-.847 0l-.847.846c-.234.234-.234.613 0 .847l1.694 1.694-1.694 1.693c-.233.234-.233.613 0 .847l.847.847c.234.234.613.234.847 0l1.693-1.693 1.694 1.693c.234.234.613.234.847 0l.847-.847c.233-.233.233-.613 0-.846L17.13 7.125z"
    />
  </svg>
);

export default VolumeMute;
