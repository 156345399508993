import React from 'react';
import ConfirmationModal from 'components/ConfirmationModal';
import { Captions } from 'components/icons';
import LoadingOverlay from 'components/LoadingOverlay';
import Modal from 'components/Modal';

interface AppsSettingsModalProps {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  visible: boolean;
}

const AppsSettingsModal: React.FunctionComponent<AppsSettingsModalProps> = props => {
  const { loading, onCancel, onConfirm, visible } = props;

  const LoadingComponent = React.useMemo(
    () => (loading ? <LoadingOverlay /> : null),
    [loading],
  );

  return (
    <Modal onHide={onCancel} show={visible} title="disconnect app">
      <ConfirmationModal.Body>
        <ConfirmationModal.Icon>
          <Captions />
        </ConfirmationModal.Icon>
        <ConfirmationModal.Title>
          Should we disconnect this app?
        </ConfirmationModal.Title>
        <ConfirmationModal.Content>
          This will revoke access, but you can always connect it again.
        </ConfirmationModal.Content>
      </ConfirmationModal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton onClick={onCancel} theme="cancel">
            cancel
          </Modal.FooterButton>
          <Modal.FooterButton onClick={onConfirm} theme="delete">
            disconnect
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
      {LoadingComponent}
    </Modal>
  );
};

export default AppsSettingsModal;
