import Measurement from './Measurement';
import { MeasurementContext, Unit } from './types';
import ViewportHeight from './ViewportHeight';
import ViewportWidth from './ViewportWidth';

export default class Pixels extends Measurement<'px', Pixels> {
  constructor(value: number) {
    super(value, 'px');
  }

  public toUnit(unit: Unit, context: MeasurementContext): any {
    if (unit === 'px') {
      return this;
    }

    if (unit === 'vw') {
      const contextValue = Pixels.getContextValue(context, 'width');
      return new ViewportWidth((this.value / contextValue) * 100 || 0);
    }

    if (unit === 'vh') {
      const contextValue = Pixels.getContextValue(context, 'height');
      return new ViewportHeight((this.value / contextValue) * 100 || 0);
    }

    throw new Error(`cannot convert from px to ${unit}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public update(val: number) {
    return new Pixels(val);
  }
}
