import { createSelector } from 'reselect';

import { TranscriptionStatus } from 'redux/middleware/api/media-upload-service/types';
import {
  manualTranscriptsSelector,
  summariesSelector,
  textBlobsSelector,
} from 'redux/modules/entities/selectors';
import { State } from 'redux/types';
import { getValue } from 'utils/collections';
import { TextWizardStatus } from './constants';
import { TextWizardState } from './types';

interface ITextBlobTranscriptStatus {
  isResolved: boolean;
  status: TranscriptionStatus;
}

const stateSelector = (state: State): TextWizardState =>
  state.get('textWizard');

export const urlSummaryIdSelector = createSelector(
  stateSelector,
  state => state.urlSummaryId,
);

const summaryEntitySelector = createSelector(
  [urlSummaryIdSelector, summariesSelector],
  (summaryId, summaries) => {
    if (!summaryId || !summaries) return undefined;
    return summaries.get(summaryId);
  },
);

export const summarySelector = createSelector(
  summaryEntitySelector,
  summary => {
    if (!summary) return undefined;
    return summary
      .get('summaryDetails')
      .map(detail => detail.get('text'))
      .join('\n\n');
  },
);

export const parsedTextSelector = createSelector(
  summaryEntitySelector,
  summary => {
    if (!summary) return undefined;
    return summary.get('parsedText');
  },
);

export const textBlobIdSelector = createSelector(
  stateSelector,
  state => state.textBlobId,
);

export const textBlobSelector = createSelector(
  [textBlobIdSelector, textBlobsSelector],
  (id, texts) => getValue(texts, id),
);

export const textBlobTranscriptStatusSelector = createSelector(
  textBlobSelector,
  (blob): ITextBlobTranscriptStatus => ({
    isResolved: getValue(blob, ['transcript', 'isResolved'], false),
    status: getValue(blob, ['transcript', 'status']),
  }),
);

export const textBlobTranscriptUrlSelector = createSelector(
  textBlobSelector,
  (blob): string => getValue(blob, ['transcript', 'url']),
);

export const transcriptIdsSelector = createSelector(stateSelector, state => ({
  revisionId: getValue(state, 'transcriptRevisionId'),
  transcriptId: getValue(state, 'transcriptId'),
}));

export const widgetIdSelector = createSelector(stateSelector, state =>
  getValue(state, 'widgetId'),
);

export const aspectRatioNameSelector = createSelector(stateSelector, state =>
  getValue(state, 'aspectRatioName'),
);

export const aspectRatioSelector = createSelector(stateSelector, state => {
  const ratio = getValue(state, 'aspectRatioDimensions');
  return ratio && ratio.toJS();
});

export const statusSelector = createSelector(
  stateSelector,
  state => state && state.status,
);

export const projectIdSelector = createSelector(
  stateSelector,
  state => state && state.projectId,
);

export const isSummarizingSelector = createSelector(
  statusSelector,
  status => status === TextWizardStatus.SUMMARIZING,
);

export const videoDurationMillisSelector = createSelector(
  [transcriptIdsSelector, manualTranscriptsSelector],
  ({ transcriptId }, transcripts) => {
    if (!transcriptId || !transcripts) return undefined;

    const transcript = transcripts.get(transcriptId);
    if (!transcript) return undefined;

    return transcript
      .get('transcript')
      .map(chunk => chunk.get('endMillis'))
      .sort()
      .last();
  },
);
