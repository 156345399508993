import { isEqual } from 'underscore';
import WaveSurfer from 'wavesurfer.js';
import { Observer } from 'wavesurfer.js/src/util';
import { getTranslateValues } from 'utils/dom';
import { clamp, range } from 'utils/numbers';
import ClipRegionPlugin from '../ClipRegion/ClipRegionPlugin';
import { WaveSurferOptions } from '../types';

interface MinimapPluginParams extends WaveSurferOptions {
  regionsPluginName?: string;
  container?: string | Element;
  waveColor?: string;
  progressColor?: string;
  height: number;
  showOverview?: boolean;
  showRegions?: boolean;
  deferInit?: boolean;
  overviewOpacity?: number;
  interact?: boolean;
  pixelRatio?: number;
}

/**
 * Wavesurfer adds observer methods at runtime.
 */
interface MinimapPlugin extends Observer {}

/**
 * Forked version of Wavesurfer's official [MinimapPlugin](https://github.com/katspaugh/wavesurfer.js/blob/19afccbe8cde790986989105d99f5935c8088f03/src/plugin/minimap/index.js)
 */
class MinimapPlugin {
  /**
   * Minimap plugin definition factory
   *
   * This function must be used to create a plugin definition which can be
   * used by wavesurfer to correctly instantiate the plugin.
   */
  static create(params: MinimapPluginParams) {
    return {
      name: 'minimap',
      deferInit: params && params.deferInit ? params.deferInit : false,
      params,
      staticProps: {},
      instance: MinimapPlugin,
    };
  }

  params: Omit<MinimapPluginParams, 'container'> & {
    container: Node;
  };

  peaks: number[] | number[][];

  wavesurfer: WaveSurfer;

  util: typeof WaveSurfer.util;

  drawer: any;

  renderEvent: 'ready' | 'waveform-ready';

  ratio: number;

  draggingOverview: boolean = false;

  overviewRegion: HTMLElement;

  clipRegion: HTMLElement;

  isInitialised = false;

  private overviewWidth: number;

  private onShouldRender: () => void;

  private onAudioprocess: () => void;

  private onSeek: () => void;

  private onScroll: (e: any) => void;

  private onResize: (e: any) => void;

  private onZoom: (e: any) => void;

  private onRedraw: (e: any) => void;

  private onRegionChange: () => void;

  constructor(params: MinimapPluginParams, ws: WaveSurfer) {
    this.params = {
      ...ws.params,
      showRegions: false,
      regionsPluginName: params.regionsPluginName || 'regions',
      showOverview: false,
      overviewBorderColor: 'green',
      overviewBorderSize: 2,
      // the container should be different
      container: false,
      height: Math.max(Math.round(ws.params.height / 4), 20),
      ...params,
      scrollParent: false,
      fillParent: true,
    };
    // if container is a selector, get the element
    if (typeof params.container === 'string') {
      const el = document.querySelector(params.container);
      if (!el) {
        // eslint-disable-next-line
        console.warn(
          `Wavesurfer minimap container ${params.container} was not found! The minimap will be automatically appended below the waveform.`,
        );
      }
      this.params.container = el;
    }
    // if no container is specified add a new element and insert it
    if (!params.container) {
      this.params.container = ws.util.style(document.createElement('minimap'), {
        display: 'block',
      });
    }

    this.drawer = new ws.Drawer(this.params.container, {
      ...this.params,
    });
    this.wavesurfer = ws;
    this.util = ws.util;
    /**
     * Minimap needs to listen for the `ready` and `waveform-ready` events
     * to work with the `MediaElement` backend. The moment the `ready` event
     * is called is different (and peaks would not load).
     *
     * @type {string}
     * @see https://github.com/katspaugh/wavesurfer.js/issues/736
     */
    this.renderEvent =
      ws.params.backend === 'MediaElement' ? 'waveform-ready' : 'ready';
    this.overviewRegion = null;

    this.drawer.createWrapper();
    this.createElements();

    // ws ready event listener
    this.onShouldRender = () => {
      // only bind the events in the first run
      if (!this.isInitialised) {
        this.bindWavesurferEvents();
        this.bindMinimapEvents();
        this.isInitialised = true;
      }
      // if there is no such element, append it to the container (below
      // the waveform)
      if (!document.body.contains(this.params.container)) {
        this.wavesurfer.container.insertBefore(this.params.container, null);
      }

      this.render();
    };

    this.onAudioprocess = () => {
      this.drawer.progress(this.wavesurfer.backend.getPlayedPercents());
    };

    // ws seek event listener
    this.onSeek = () => {
      this.drawer.progress(this.wavesurfer.backend.getPlayedPercents());
    };

    // event listeners for the overview region
    this.onScroll = scrollX => {
      const overviewScrollX = this.getOverviewScrollX(scrollX);
      this.util.style(this.overviewRegion, {
        transform: `translateX(${overviewScrollX}px)`,
      });
    };

    let prevWidth = 0;
    this.onResize = ws.util.debounce(() => {
      if (prevWidth !== this.drawer.getWidth()) {
        prevWidth = this.drawer.getWidth();
        this.render();
        this.drawer.progress(this.wavesurfer.backend.getPlayedPercents());
      }
    });

    this.onZoom = () => {
      this.render(true);
    };

    this.onRedraw = peaks => {
      if (!isEqual(this.peaks, peaks)) {
        this.peaks = peaks;
        this.render();
      }
    };

    this.onRegionChange = () => {
      this.renderRegions();
    };

    this.wavesurfer.on('zoom', this.onZoom);
  }

  init() {
    if (this.wavesurfer.isReady) {
      this.onShouldRender();
    }
    this.wavesurfer.on(this.renderEvent, this.onShouldRender);
  }

  destroy() {
    window.removeEventListener('resize', this.onResize, true);
    window.removeEventListener('orientationchange', this.onResize, true);
    this.wavesurfer.drawer.un('scroll', this.onScroll);
    this.wavesurfer.un(this.renderEvent, this.onShouldRender);
    this.wavesurfer.un('seek', this.onSeek);
    this.wavesurfer.un('audioprocess', this.onAudioprocess);
    this.wavesurfer.un('zoom', this.onZoom);
    this.wavesurfer.un('region-created', this.onRegionChange);
    this.wavesurfer.un('region-updated', this.onRegionChange);
    this.wavesurfer.un('region-removed', this.onRegionChange);
    this.drawer.destroy();
    this.overviewRegion = null;
    this.unAll();
  }

  renderRegions() {
    const regionsPlugin: ClipRegionPlugin = this.wavesurfer[
      this.params.regionsPluginName
    ];

    if (!regionsPlugin) {
      return;
    }

    const { region } = regionsPlugin;

    if (region) {
      if (!this.clipRegion) {
        this.clipRegion = this.util.style(document.createElement('region'), {
          height: 'inherit',
          backgroundColor: region.color,
          borderRadius: '8px',
          top: '0',
          display: 'block',
          position: 'absolute',
        });
        this.drawer.wrapper.appendChild(this.clipRegion);
      }

      const duration = this.wavesurfer.getDuration();
      const width = this.getWidth() * ((region.end - region.start) / duration);
      const translateX = this.getWidth() * (region.start / duration);

      this.util.style(this.clipRegion, {
        width: `${width}px`,
        transform: `translateX(${translateX}px)`,
      });
    } else if (this.clipRegion) {
      this.drawer.wrapper.removeChild(this.clipRegion);
      this.clipRegion = null;
    }
  }

  createElements() {
    this.drawer.createElements();
    if (this.params.showOverview) {
      this.overviewRegion = this.util.style(
        document.createElement('overview'),
        {
          top: '0',
          bottom: '0',
          width: '0px',
          display: 'block',
          position: 'absolute',
          cursor: 'move',
          borderRadius: '10px',
          border: '2px solid #dce1eb',
          zIndex: '2',
          opacity: String(this.params.overviewOpacity),
        },
      );
      this.drawer.wrapper.appendChild(this.overviewRegion);
    }
  }

  bindWavesurferEvents() {
    window.addEventListener('resize', this.onResize, true);
    window.addEventListener('orientationchange', this.onResize, true);
    this.wavesurfer.on('audioprocess', this.onAudioprocess);
    this.wavesurfer.on('seek', this.onSeek);
    this.wavesurfer.on('redraw', this.onRedraw);
    if (this.params.showOverview) {
      this.wavesurfer.drawer.on('scroll', this.onScroll);
    }

    this.wavesurfer.on('region-created', this.onRegionChange);
    this.wavesurfer.on('region-updated', this.onRegionChange);
    this.wavesurfer.on('region-removed', this.onRegionChange);
  }

  bindMinimapEvents() {
    let mouseClientX = 0;
    let translateX = 0;
    let seek = true;

    // the following event listeners will be destroyed by using
    // this.unAll() and nullifying the DOM node references after
    // removing them
    if (this.params.interact) {
      this.drawer.wrapper.addEventListener('click', event => {
        this.fireEvent('click', event, this.drawer.handleEvent(event));
      });

      this.on('click', (_, position) => {
        if (seek) {
          this.drawer.progress(position);
          this.wavesurfer.seekAndCenter(position);
        } else {
          seek = true;
        }
      });
    }

    if (this.params.showOverview) {
      const handleMouseMove = e => {
        const diff = e.clientX - mouseClientX;
        this.moveOverviewRegion(translateX + diff);
      };

      const handleMouseUp = event => {
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('mousemove', handleMouseMove);
        if (mouseClientX - event.clientX === 0) {
          seek = true;
          this.draggingOverview = false;
        } else if (this.draggingOverview) {
          seek = false;
          this.draggingOverview = false;
        }
      };

      this.overviewRegion.addEventListener('mousedown', event => {
        this.draggingOverview = true;
        const translate = getTranslateValues(this.overviewRegion);
        translateX = translate.x;
        mouseClientX = event.clientX;
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousemove', handleMouseMove);
      });
    }
  }

  render(skipPeaks?: boolean) {
    const len = this.drawer.getWidth();
    const peaks = this.wavesurfer.backend.getPeaks(len, 0, len);
    // Allows skipping the waveform redraw at the minimap. This improves performance when
    // zooming in as minimap is not redrawn.
    if (!skipPeaks) {
      this.drawer.drawPeaks(peaks, len, 0, len);
    }
    this.drawer.progress(this.wavesurfer.backend.getPlayedPercents());

    if (this.params.showOverview && this.overviewRegion) {
      // get proportional width of overview region considering the respective
      // width of the drawers
      const containerRatio =
        this.wavesurfer.container.offsetWidth /
        this.drawer.container.offsetWidth;
      this.ratio = this.wavesurfer.drawer.width / this.drawer.width;
      this.overviewWidth =
        (this.drawer.container.offsetWidth / this.ratio) * containerRatio;

      const scrollX = this.wavesurfer.drawer.getScrollX();
      const overviewScrollX = this.getOverviewScrollX(scrollX);
      this.util.style(this.overviewRegion, {
        transform: `translateX(${overviewScrollX}px)`,
        width: `${this.overviewWidth}px`,
      });
    }

    if (this.params.showRegions) {
      this.renderRegions();
    }
  }

  moveOverviewRegion(pixels: number) {
    const overviewScrollX = clamp(
      pixels,
      0,
      this.drawer.container.offsetWidth - this.overviewWidth,
    );

    const scrollX = this.getDrawerScrollX(overviewScrollX);
    this.wavesurfer.drawer.setScrollX(scrollX);
  }

  getWidth() {
    return this.drawer.width / this.params.pixelRatio;
  }

  getDrawerScrollX = (overviewScrollX: number) => {
    const maxScroll = this.wavesurfer.drawer.getMaxScroll();
    const scrollX = range(
      0,
      this.drawer.container.offsetWidth - this.overviewWidth,
      0,
      maxScroll,
      overviewScrollX,
    );
    return scrollX || 0;
  };

  getOverviewScrollX = (scrollX: number) => {
    const maxScroll = this.wavesurfer.drawer.getMaxScroll();
    const overviewScrollX = range(
      0,
      maxScroll,
      0,
      this.drawer.container.offsetWidth - this.overviewWidth,
      scrollX,
    );
    return overviewScrollX || 0;
  };
}

export default MinimapPlugin;
