import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';

import EditAutomationAudioSelectionTypeModalContents from './EditAutomationAudioSelectionTypeModalContents';

const EditAutomationAudioSelectionTypeModal: React.FunctionComponent = () => {
  return (
    <ConnectedModal
      backdrop="static"
      name="EditAutomationAudioSelectionTypeModal"
      title="edit audio selection"
    >
      <EditAutomationAudioSelectionTypeModalContents />
    </ConnectedModal>
  );
};

export default EditAutomationAudioSelectionTypeModal;
