import cn from 'classnames';
import * as React from 'react';

import { block } from './utils';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

const InputLabel: React.SFC<IProps> = ({ children, className, disabled }) => (
  <label className={cn(block('input-label', { disabled }), className)}>
    {children}
  </label>
);

export default InputLabel;
export { IProps as InputLabelProps };
