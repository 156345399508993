import React from 'react';

import { useSelector } from 'react-redux';
import Select, { Option, Overrides } from 'components/Select';

import { ShareFormField } from 'components/ShareForm';
import { tikTokPrivacyLevelOptionsSelector } from 'redux/modules/social';
import { block } from '../utils';
import TikTokVisibilityOption from './TikTokVisibilityOption';
import {
  formatVisibilityOptions,
  getSelectedOptionValue,
  getVisibilityOption,
} from './utils';

export interface TikTokVisibilitySelectorProps {
  brandedContentSelected?: boolean;
  value?: string;
  onChange: (value: string) => void;
}

const selectComponentOverrides: Overrides<Option> = {
  control: {
    props: p => ({
      ...p,
      className: p.className,
    }),
  },
  option: {
    component: TikTokVisibilityOption,
  },
};

const TikTokVisibilitySelector: React.FunctionComponent<TikTokVisibilitySelectorProps> = props => {
  const { brandedContentSelected, value, onChange } = props;
  const privacyLevelOptions = useSelector(tikTokPrivacyLevelOptionsSelector);
  const options = formatVisibilityOptions(
    privacyLevelOptions,
    brandedContentSelected ? ['selfOnly'] : [],
  );

  const handleSelectValue = React.useCallback(
    (option: Option): void => {
      const nextValue = getSelectedOptionValue(option);

      onChange(nextValue);
    },
    [onChange],
  );

  return (
    <ShareFormField
      label="Who can view your videos"
      className={block('form-field')}
    >
      <Select
        onChange={handleSelectValue}
        options={options}
        placeholder="Select an option"
        overrides={selectComponentOverrides}
        value={getVisibilityOption(options, value)}
      />
    </ShareFormField>
  );
};

export default TikTokVisibilitySelector;
