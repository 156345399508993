import * as Sentry from '@sentry/browser';
import { RecordOf } from 'immutable';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import _ from 'underscore';

import { VideoPosition } from 'blocks/VideoCropper';
import LoadingOverlay from 'components/LoadingOverlay';
import {
  ModalBody,
  ModalFooter,
  ModalFooterButton,
  ModalFooterButtons,
} from 'components/Modal';
import VideoFormWithPreview, {
  dataFactory as videoFormDataFactory,
  VideoScaling,
} from 'components/VideoFormWithPreview';
import { ConnectedModalInjectedProps } from 'containers/ConnectedModal/ConnectedModalComponent';
import {
  addingToMediaTrack,
  addingToTrack,
  replaceSelectedTrackElement,
} from 'redux/modules/embed/actions';
import {
  removeFromVideoTrack,
  saveVideo,
} from 'redux/modules/embed/actions/video';
import {
  aspectRatioSelector,
  selectedTrackElementSelector,
  videosByIdSelector,
} from 'redux/modules/embed/selectors';
import { onReplaceAsset } from 'redux/modules/mixpanel';
import { pushModal } from 'redux/modules/modal/actions';
import { showError } from 'redux/modules/notification/actions';
import { Dispatch } from 'redux/types';
import { hasAllDimensions } from 'utils/embed/video';

const { useCallback, useRef, useState } = React;

interface IVideo {
  id: string;
  startMillis: number;
  endMillis: number;
  audioLevel: number;
  mainAudioLevel: number;
  audioFadeInDurationMillis: number;
  audioFadeOutDurationMillis: number;
  transitionIn: string;
  transitionOut: string;
  scaling: VideoScaling;
  src: string;
  blurredBackground: boolean;
  position?: VideoPosition;
  height?: number;
  width?: number;
  zoom: number;
}

export interface EditVideoModalContentsProps {
  onReplace?: () => void;
  onReplaceHide?: () => void;
}

const videoSelector = createSelector(
  [selectedTrackElementSelector, videosByIdSelector],
  (element, videosById) => {
    if (!element || !videosById) return undefined;

    const video = videosById.get(element.get('dataId'));
    if (!video) return undefined;

    return video
      .withMutations(v =>
        v
          .update('audioLevel', level =>
            _.isUndefined(level) ? undefined : Math.trunc(level * 100),
          )
          .update('mainAudioLevel', level =>
            _.isUndefined(level) ? undefined : Math.trunc(level * 100),
          ),
      )
      .update('scaling', scaling => scaling && scaling.toUpperCase());
  },
);

const EditVideoModalContents: React.FC<EditVideoModalContentsProps &
  ConnectedModalInjectedProps> = ({ onHide }) => {
  const dispatch = useDispatch<Dispatch>();
  const trackId = useSelector(selectedTrackElementSelector)?.get('trackId');
  const aspectRatio = useSelector(aspectRatioSelector);
  const video = useSelector(videoSelector);
  const [metadataLoaded, setMetadataLoaded] = useState(false);
  const formRef = useRef<VideoFormWithPreview>();

  const {
    startMillis,
    audioLevel,
    mainAudioLevel,
    audioFadeInDurationMillis,
    audioFadeOutDurationMillis,
    blurredBackground,
    position,
    scaling,
    src,
    transitionIn,
    transitionOut,
    width,
    height,
    zoom,
    id,
  } = video || ({} as RecordOf<IVideo>);

  const durationMillis = !video
    ? undefined
    : video.endMillis - video.startMillis;

  const handleLoadedMetadata = useCallback(() => {
    setMetadataLoaded(true);
  }, []);

  const handleSave = useCallback(() => {
    const {
      clipStartMillis,
      clipEndMillis,
      mediaToReplaceId,
      transcriptionEnabled,
      transcriptionLanguage,
      ...rest
    } = formRef.current.values;
    dispatch((__, getState) => {
      const v = videoSelector(getState());

      if (!hasAllDimensions(rest)) {
        const message = 'Error updating asset. Please try again';
        dispatch(showError(message));
        Sentry.captureException(new Error(message));
        onHide();
      } else {
        dispatch(saveVideo(v.id, rest));
      }
    });
    onHide();
  }, [dispatch, onHide]);

  const handleDelete = useCallback(() => {
    dispatch((__, getState) => {
      const v = videoSelector(getState());
      dispatch(removeFromVideoTrack(v.id));
      onHide();
    });
  }, [dispatch, onHide]);

  const handleReplace = useCallback(() => {
    dispatch(onReplaceAsset('video'));
    dispatch(addingToMediaTrack());
    dispatch(replaceSelectedTrackElement());
    onHide();
    dispatch(pushModal({ name: 'AddMediaModal' }));
    dispatch(addingToTrack(trackId));
  }, [dispatch, onHide, trackId]);

  return (
    <>
      <ModalBody>
        {!metadataLoaded && <LoadingOverlay title="Loading Video" />}
        <VideoFormWithPreview
          aspectRatio={aspectRatio}
          defaultData={videoFormDataFactory({
            audioFadeInDurationMillis,
            audioFadeOutDurationMillis,
            audioLevel,
            blurredBackground,
            height,
            mainAudioLevel,
            position,
            scaling,
            startMillis,
            transitionIn,
            transitionOut,
            width,
            zoom,
          })}
          id={id}
          durationMillis={durationMillis}
          onLoadedMetadata={handleLoadedMetadata}
          previewColClassName="edit-video-modal__preview-col"
          videoSrc={src}
          ref={formRef}
          showTransformOptions
          showTranscriptionSection={false}
        />
      </ModalBody>

      <ModalFooter className="edit-video-modal__footer">
        <ModalFooterButtons>
          <ModalFooterButton onClick={onHide}>Cancel</ModalFooterButton>
          <ModalFooterButton onClick={handleReplace}>Replace</ModalFooterButton>
          <ModalFooterButton onClick={handleDelete} theme="delete">
            Delete
          </ModalFooterButton>
          <ModalFooterButton
            disabled={!metadataLoaded}
            onClick={handleSave}
            theme="submit"
          >
            Update Video
          </ModalFooterButton>
        </ModalFooterButtons>
      </ModalFooter>
    </>
  );
};

export default EditVideoModalContents;
