import React from 'react';

import { useTextOverlay } from '../state/TextOverlayProvider';
import { mapDraftEditorDataToTextOverlay } from '../style-export-utils';
import { TextOverlayV2 } from '../types';
import useTextOverlayStyle from './useTextOverlayStyle';

interface UseTextOverlaySubmitConfig {
  onHide: () => void;
  onSubmit: (overlay: TextOverlayV2) => void;
}

type UseTextOverlaySubmit = () => void;

const useTextOverlaySubmit = (
  config: UseTextOverlaySubmitConfig,
): UseTextOverlaySubmit => {
  const { onHide, onSubmit } = config;

  const {
    canvasSize,
    draftEditorData,
    editorHtmlRef,
    overlayTimingExtension,
    textValue,
  } = useTextOverlay();

  const { textBuilderStyles } = useTextOverlayStyle();

  // the timing extension will append the time entry to the output overlay
  // if necessary.
  const { appendTimingToOverlay } = overlayTimingExtension;

  return React.useCallback(() => {
    const htmlString = String(editorHtmlRef.current?.innerHTML);

    const customizedTextOverlay = mapDraftEditorDataToTextOverlay(
      draftEditorData,
      htmlString,
      textValue,
      canvasSize,
      textBuilderStyles,
    );

    onSubmit(appendTimingToOverlay(customizedTextOverlay));
    onHide();
  }, [
    appendTimingToOverlay,
    canvasSize,
    draftEditorData,
    editorHtmlRef,
    onHide,
    onSubmit,
    textBuilderStyles,
    textValue,
  ]);
};

export default useTextOverlaySubmit;
