export const ANNOUNCEMENTS_GET_REQUEST =
  'app/display-pref/ANNOUNCEMENTS_GET_REQUEST';
export const ANNOUNCEMENTS_GET_SUCCESS =
  'app/display-pref/ANNOUNCEMENTS_GET_SUCCESS';
export const ANNOUNCEMENTS_GET_FAILURE =
  'app/display-pref/ANNOUNCEMENTS_GET_FAILURE';

export const VIEW_CONFIRMATION_GET_REQUEST =
  'app/display-pref/VIEW_CONFIRMATION_GET_REQUEST';
export const VIEW_CONFIRMATION_GET_SUCCESS =
  'app/display-pref/VIEW_CONFIRMATION_GET_SUCCESS';
export const VIEW_CONFIRMATION_GET_FAILURE =
  'app/display-pref/VIEW_CONFIRMATION_GET_FAILURE';

export const CLEAR_ANNOUNCEMENT = 'app/display-pref/CLEAR_ANNOUNCEMENT';
