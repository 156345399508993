import cn from 'classnames';
import * as React from 'react';

import { childViewCardBlock as block } from './utils';

type ChildViewCardLayout = 'horizontal' | 'vertical';

export interface ChildViewCardProps {
  children?: React.ReactNode;
  className?: string;
  icon: React.ReactElement;
  layout?: ChildViewCardLayout;
}

const ChildViewCard: React.FC<ChildViewCardProps> = ({
  children,
  className,
  icon,
  layout = 'horizontal',
}) => (
  <div className={cn(block({ [layout]: !!layout }), className)}>
    {React.cloneElement(icon, {
      className: cn(block('icon'), icon.props.className),
    })}
    {children}
  </div>
);

export default ChildViewCard;
