import dayjs from 'dayjs';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import { SubTier } from 'redux/middleware/api/plan-service/types';
import bem from 'utils/bem';
import { getMyPlanPath, getReferralsPath } from 'utils/routes';
import { Gift } from './icons';

interface Props {
  pricingTier: SubTier;
  endSec: number;
  onLearnMoreClick?: () => void;
  onExtendClick?: () => void;
}

const block = bem('free-trial-popover');

const { useRef } = React;

const FreeTrialPopover: React.FC<Props> = props => {
  const tooltipRef = useRef<Tooltip>(null);
  const {
    children,
    pricingTier,
    endSec,
    onLearnMoreClick,
    onExtendClick,
  } = props;
  const end = dayjs.unix(endSec);
  const handleClick = (cb: () => void) => () => {
    tooltipRef.current.hide();
    cb();
  };

  if (pricingTier !== SubTier.PRO_TRIAL) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      className={block()}
      ref={tooltipRef}
      content={
        <>
          <header className={block('header')}>
            <Gift height="15px" fill="url(#pro-gradient)" />
            &nbsp;&nbsp;{end.fromNow(true)} left
          </header>
          <p className={block('body')}>
            After your trial you can use our <b>Forever Free</b> tier or upgrade
            to keep your <b>PRO features</b> forever.
          </p>
          <footer className={block('footer')}>
            <Button
              theme="rainbow"
              fluid
              componentClass={Link}
              // @ts-ignore
              to={getMyPlanPath()}
              onClick={handleClick(onLearnMoreClick)}
            >
              Learn more about pro
            </Button>
            <Button
              theme="submit-alt"
              fluid
              componentClass={Link}
              // @ts-ignore
              to={getReferralsPath()}
              onClick={handleClick(onExtendClick)}
            >
              Extend your trial
            </Button>
          </footer>
        </>
      }
      id="free-trial-tooltip"
      placement="bottom"
    >
      {children}
    </Tooltip>
  );
};

export default FreeTrialPopover;
