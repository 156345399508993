import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import EpisodeListRow from 'blocks/AutomationCMS/components/EpisodeListRow';
import { H3 } from 'components/Heading';
import usePodcastFeedEpisode from 'hooks/usePodcastFeedEpisode';
import { downloadEpisodeTranscript } from 'redux/middleware/api/transcript-service/actions';
import { TranscriptNoContentError } from 'redux/middleware/api/transcript-service/types';
import { onDownloadEpisodeTranscript } from 'redux/modules/mixpanel';
import { hideModal, replaceModal } from 'redux/modules/modal';
import { showError } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { CaptionFormat } from 'types';
import CaptionsFormatSelector from './CaptionsFormatSelector';
import DownloadCaptionsModalContents from './DownloadCaptionsModalContents';
import { episodeModalBlock as block } from './utils';

const CAPTIONS_FORMAT_OPTIONS = [
  CaptionFormat.VTT,
  CaptionFormat.SRT,
  CaptionFormat.PDF,
  CaptionFormat.TXT,
  CaptionFormat.DOC,
];

export interface DownloadEpisodeCaptionsModalContentsProps {
  episodeId?: string;
}

const DownloadEpisodeCaptionsModalContents: React.FC<DownloadEpisodeCaptionsModalContentsProps> = ({
  episodeId,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { title, thumbnailImageUrl } = usePodcastFeedEpisode(episodeId);
  const [format, setFormat] = useState(CAPTIONS_FORMAT_OPTIONS[0]);
  const [downloading, setDownloading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setDownloading(true);
    dispatch(onDownloadEpisodeTranscript(format));

    try {
      await dispatch(downloadEpisodeTranscript(episodeId, format));
      dispatch(hideModal());
    } catch (err) {
      if (err instanceof TranscriptNoContentError) {
        dispatch(
          replaceModal({
            name: 'EmptyEpisodeTranscript',
          }),
        );
      } else {
        dispatch(
          showError({
            dismissAfterSec: 5,
            title: 'Error downloading transcript',
          }),
        );
      }
    } finally {
      setDownloading(false);
    }
  }, [dispatch, episodeId, format]);

  return (
    <DownloadCaptionsModalContents
      action={{
        children: 'download transcript',
        disabled: downloading,
        fluid: true,
        onClick: handleSubmit,
        theme: 'submit',
      }}
    >
      <EpisodeListRow.Container className={block('episode-info')}>
        <EpisodeListRow.Artwork>
          <img src={thumbnailImageUrl} />
        </EpisodeListRow.Artwork>
        <EpisodeListRow.Description primary={<H3>{title}</H3>} />
      </EpisodeListRow.Container>
      <CaptionsFormatSelector
        options={CAPTIONS_FORMAT_OPTIONS}
        onChange={setFormat}
        value={format}
      />
    </DownloadCaptionsModalContents>
  );
};

export default DownloadEpisodeCaptionsModalContents;
