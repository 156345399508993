import ExtendableError from './ExtendableError';

export class ApplicationError extends ExtendableError {
  code: string;

  constructor(message: string, code: string) {
    super(message);
    this.code = code;
  }
}

export const isApplicationError = (error: Error): error is ApplicationError => {
  return (error as ApplicationError).code !== undefined;
};
