import { fromJS } from 'immutable';

import { ITextOverlayV2 } from 'blocks/TextOverlayModal/v2';
import { DeepImmutableMap } from 'types';
import { scaleInlineStyles } from 'utils/embed/text-overlay';
import { Measurement, ViewportWidth } from 'utils/measurement';
import { VideoTemplateStateContent } from '../types';

interface PaddingValues<T = any> {
  paddingBottom?: T;
  paddingLeft?: T;
  paddingRight?: T;
  paddingTop?: T;
}

type PaddingFormatter<T, U> = (val: T, key: keyof PaddingValues) => U;

const PADDING_KEYS: Array<keyof PaddingValues> = [
  'paddingTop',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
];

export function formatPadding<T extends PaddingValues<U>, U, V>(
  t: T,
  formatter: PaddingFormatter<U, V>,
): PaddingValues<V> {
  return PADDING_KEYS.reduce((acc, key) => {
    acc[key] = formatter(t[key], key);
    return acc;
  }, {} as any);
}

/*
 * take a text overlay from state and transform it to the shape expected by
 * the TextOverlayModal
 */
export function getTextModalOverlay(
  state: VideoTemplateStateContent,
  id: string,
): DeepImmutableMap<ITextOverlayV2> {
  const overlay = state.textOverlays?.data?.[id];

  if (!overlay) return undefined;

  const toPx = (m: Measurement) => m.toUnit('px', state.canvas).value;

  return fromJS({
    editor: overlay.editor,
    integrationData: overlay.integrationData,
    position: {
      left: toPx(overlay.position.left),
      top: toPx(overlay.position.top),
    },
    size: {
      height: toPx(overlay.size.height),
      width: toPx(overlay.size.width),
    },
    style: {
      ...overlay.style,
      fontSize: toPx(overlay.style.fontSize),
      position: 'absolute',
      // the TextOverlay modal confusingly takes numeric padding values which are
      // assumed to be in vw units.  these values are saved in VW in state, so
      // we just take the value and pass it to the TextOverlay template as is
      ...formatPadding(overlay.style, (val: Measurement) => val.value),
    },
    text: overlay.text,
    textBuilderStyles: overlay.textBuilderStyles,
    textHtml: scaleInlineStyles(
      overlay.textHtml,
      ['fontSize'],
      val => `${toPx(new ViewportWidth(val))}px`,
    ),
    viewport: { ...state.canvas },
    version: overlay.version,
  });
}
