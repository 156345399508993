import * as React from 'react';
import { Link } from 'react-router-dom';
import { shuffle } from 'underscore';

import DialogRadioContent from 'components/DialogRadioContent';
import { H3 } from 'components/Heading';
import LinkButton from 'components/LinkButton';
import { PlanCancellationReason } from 'types';

import { CancelOption, Props } from './types';
import useCancellationReasonModalState from './useCancellationReasonModalState.tsx';
import { block } from './utils';

const cancelOptions: CancelOption[] = [
  ...(shuffle([
    {
      value: PlanCancellationReason.DONT_RUN_OUT_OF_VIDEOS,
      label: "I don't usually run out of unwatermarked videos",
    },
    {
      value: PlanCancellationReason.ANOTHER_VIDEO_EDITOR,
      label: 'I use another video editor',
    },
    {
      value: PlanCancellationReason.NOT_ENOUGH_FEATURES,
      label: 'There aren’t enough features',
    },
    {
      value: PlanCancellationReason.CANT_AFFORD,
      label: 'I can’t afford it right now',
    },
    {
      value: PlanCancellationReason.FILE_NOT_SUPPORTED,
      label: 'My file size, length, or type is not supported',
    },
    {
      value: PlanCancellationReason.TECHNICAL_PROBLEMS,
      label: 'I encountered too many technical issues',
    },
  ]) as CancelOption[]),
  {
    value: PlanCancellationReason.OTHER,
    label: 'Other',
  },
];

const CancellationReasonModal: React.FC<Props> = props => {
  const {
    reason,
    onDeleteAccount,
    onReasonChange,
    onSubmit,
  } = useCancellationReasonModalState(props);

  const handleDeleteAccountClick = React.useCallback(() => {
    onDeleteAccount();
  }, [onDeleteAccount]);

  return (
    <DialogRadioContent
      actions={[
        {
          children: 'Next',
          disabled: !reason,
          fluid: true,
          key: 'submit',
          onClick: onSubmit,
          theme: 'submit',
        },
      ]}
      label={
        <div>
          <H3>Sorry to see you go, please let us know why:</H3>
          <p className={block('reason-subtitle')}>
            This action <b>won’t delete your account</b>, if you would like to
            do that instead{' '}
            <LinkButton
              className={block('reason-subtitle', { link: true })}
              componentClass={Link}
              onClick={handleDeleteAccountClick}
              to="/settings/password"
            >
              click here
            </LinkButton>
            .
          </p>
        </div>
      }
      onChange={onReasonChange}
      options={cancelOptions}
      value={reason}
    />
  );
};

export default CancellationReasonModal;
