import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogRadioContent from 'components/DialogRadioContent';

import {
  AudioSelectionType,
  editAutomatioAudioSelectionSelector,
  editAutomationSetAudioSelectionType,
} from 'redux/modules/edit-automation';

import EditAutomationLoader from '../EditAutomationLoader';
import EditAutomationSelectHeader from '../EditAutomationSelectHeader';
import useEditAutomationModal from '../hooks/useEditAutomationModal';
import useEditAutomationTemplate from '../hooks/useEditAutomationTemplate';
import useEditAudioSelection from './useEditAudioSelection';

interface EditAutomationAudioSelectionTypeModalContentsProps {
  subscriptionId?: number;
}

const buildOptions = (
  isEntireEpisodeAllowed: boolean,
): {
  value: AudioSelectionType;
  label: string;
  disabled?: boolean;
  disabledTooltip?: string;
}[] => [
  {
    value: 'HEADLINER_AI',
    label: 'Let Headliner AI select for me',
  },
  {
    value: 'SOUNDBITE',
    label: 'Use Soundbite tags in this feed',
  },
  {
    value: 'ENTIRE_EPISODE',
    label: 'Use the entire episode',
    disabled: !isEntireEpisodeAllowed,
    disabledTooltip: 'Not available for selected video template',
  },
];

const EditAutomationAudioSelectionTypeModalContents: React.FunctionComponent<EditAutomationAudioSelectionTypeModalContentsProps> = props => {
  const { subscriptionId } = props;

  const { type: currSelectedType } = useSelector(
    editAutomatioAudioSelectionSelector,
  );

  const { onGoBackToMain, subscription } = useEditAutomationModal(
    subscriptionId,
  );
  const { onConfirmAudioSelection } = useEditAudioSelection(subscriptionId);
  const { isEntireEpisodeAllowed } = useEditAutomationTemplate(
    subscription.templateId,
    subscription.templateType,
  );

  const dispatch = useDispatch();

  const handleSetAudioSelectionType = React.useCallback(
    (nextKey: AudioSelectionType) => {
      dispatch(editAutomationSetAudioSelectionType(nextKey));
    },
    [dispatch],
  );

  const handleSubmit = React.useCallback(() => {
    onConfirmAudioSelection(currSelectedType);
  }, [currSelectedType, onConfirmAudioSelection]);

  return (
    <>
      <DialogRadioContent
        actions={[
          {
            children: 'update automation',
            disabled: !currSelectedType,
            fluid: true,
            key: 'submit',
            onClick: handleSubmit,
            theme: 'submit',
          },
        ]}
        label={
          <EditAutomationSelectHeader
            onGoBack={onGoBackToMain}
            title="How should we select audio for each video?"
          />
        }
        onChange={handleSetAudioSelectionType}
        options={buildOptions(isEntireEpisodeAllowed)}
        value={currSelectedType}
      />
      <EditAutomationLoader />
    </>
  );
};

export default EditAutomationAudioSelectionTypeModalContents;
