import * as React from 'react';

import { usePodcastSearchConfig } from 'blocks/PodcastSearch/components/PodcastSearchConfigContext';
import { PodcastEpisodeData } from 'blocks/PodcastSearch/types';
import PodcastEpisode from '../../components/PodcastEpisode';
import { SearchResultRenderProps } from '../../components/SearchResultList';
import SearchResultListItem from '../../components/SearchResultListItem';

import { episodeExplorerBlock as block } from '../../utils';
import { getSearchResultConfig } from './utils';

export interface EpisodeSearchResultProps
  extends Pick<SearchResultRenderProps, 'style'> {
  className?: string;
  episode: PodcastEpisodeData;
  fullEpisodeDurationLimitHours: number;
  isFreeOrBasic?: boolean;
  onClick?: (episode: PodcastEpisodeData) => void;
}

const EpisodeSearchResult: React.SFC<EpisodeSearchResultProps> = ({
  episode,
  fullEpisodeDurationLimitHours,
  isFreeOrBasic,
  onClick,
  style,
}) => {
  const { usePlanEpisodeMaxDuration } = usePodcastSearchConfig();

  const { disabled, tooltipText } = getSearchResultConfig({
    episodeDurationMillis: episode.durationMillis,
    isFreeOrBasicUser: isFreeOrBasic,
    planMaxDurationHours: fullEpisodeDurationLimitHours,
    usePlanEpisodeMaxDuration,
  });

  return (
    <SearchResultListItem
      className={block('episode-list-item')}
      disabled={disabled}
      id={episode.id}
      onClick={disabled ? undefined : onClick}
      params={episode}
      style={style}
      tooltip={tooltipText}
    >
      <PodcastEpisode
        durationMillis={episode.durationMillis}
        publishedAtMillis={episode.publishedAtMillis}
        title={episode.title}
      />
    </SearchResultListItem>
  );
};

export default EpisodeSearchResult;
