import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';

import EditAutomationEpisodeSelectionModalContents from './EditAutomationEpisodeSelectionModalContents';

const EditAutomationEpisodeSelectionModal: React.FunctionComponent = () => {
  return (
    <ConnectedModal
      backdrop="static"
      name="EditAutomationEpisodeSelection"
      title="edit episode selection"
    >
      <EditAutomationEpisodeSelectionModalContents />
    </ConnectedModal>
  );
};

export default EditAutomationEpisodeSelectionModal;
