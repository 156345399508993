import cn from 'classnames';
import * as React from 'react';
import Button, { ButtonProps } from 'components/Button';
import bem from 'utils/bem';

interface Props extends ButtonProps {}

const block = bem('floating-action-button');

function FloatingActionButton(props: Props) {
  const { className, size, children, ...rest } = props;
  return (
    <Button {...rest} className={cn(block({ [size]: true }), className)}>
      <span className={block('inner')}>{children}</span>
    </Button>
  );
}

export default FloatingActionButton;
