import React from 'react';
import EpisodesStep from 'blocks/AutomationWorkflowWizard/components/EpisodesStep/EpisodesStep';
import { EpisodeOption } from 'blocks/AutomationWorkflowWizard/components/EpisodesStep/types';
import { block } from './utils';

export interface AutogramEpisodesStepProps {
  isFullEpisode: boolean;
  onEpisodeOptionChange: (value: EpisodeOption) => void;
}

const AutogramEpisodesStep: React.FC<AutogramEpisodesStepProps> = ({
  isFullEpisode,
  onEpisodeOptionChange,
}: AutogramEpisodesStepProps) => {
  return (
    <div className={block()}>
      <EpisodesStep
        isFullEpisode={isFullEpisode}
        onClick={onEpisodeOptionChange}
      />
    </div>
  );
};

export default AutogramEpisodesStep;
