import * as React from 'react';

import { Timeline } from 'components/icons';
import PhraseControlButton, {
  PhraseControlButtonProps,
} from './PhraseControlButton';

export type CopyToTimelineButtonProps = Pick<
  PhraseControlButtonProps,
  'disabled' | 'onClick'
>;

const CopyToTimelineButton: React.SFC<CopyToTimelineButtonProps> = props => (
  <PhraseControlButton
    {...props}
    id="copy-to-timeline"
    tooltip="Copy to timeline"
  >
    <Timeline />
  </PhraseControlButton>
);

export default CopyToTimelineButton;
