export enum Type {
  IMAGE_TO_VIDEO_CREATE_ABORTED = 'app/image-to-video/IMAGE_TO_VIDEO_CREATE_ABORTED',
  IMAGE_TO_VIDEO_CREATE_CANCEL = 'app/image-to-video/IMAGE_TO_VIDEO_CREATE_CANCEL',
  IMAGE_TO_VIDEO_CREATE_REQUEST = 'app/image-to-video/IMAGE_TO_VIDEO_CREATE_REQUEST',
  IMAGE_TO_VIDEO_CREATE_SUCCESS = 'app/image-to-video/IMAGE_TO_VIDEO_CREATE_SUCCESS',
  IMAGE_TO_VIDEO_CREATE_FAILURE = 'app/image-to-video/IMAGE_TO_VIDEO_CREATE_FAILURE',

  IMAGE_TO_VIDEO_POLLING_BEGIN = 'app/image-to-video/IMAGE_TO_VIDEO_POLLING_BEGIN',
  IMAGE_TO_VIDEO_POLLING_SUCCESS = 'app/image-to-video/IMAGE_TO_VIDEO_POLLING_SUCCESS',
  IMAGE_TO_VIDEO_POLLING_FAILURE = 'app/image-to-video/IMAGE_TO_VIDEO_POLLING_FAILURE',
  IMAGE_TO_VIDEO_POLLING_END = 'app/image-to-video/IMAGE_TO_VIDEO_POLLING_END',

  IMAGE_TO_VIDEO_RESET = 'app/image-to-video/IMAGE_TO_VIDEO_RESET',
}
