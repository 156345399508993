import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';
import { List } from 'components/icons';
import RadioOption from 'components/RadioOptions/RadioOption';

import { SelectMultipleOption } from './types';
import { block } from './utils';

export interface SelectMultipleOptionProps {
  className?: string;
  option: SelectMultipleOption;
  onClick?: (value: any) => void;
  selected?: boolean;
}

const { useCallback } = React;

const SelectOption: React.FC<SelectMultipleOptionProps> = ({
  className,
  onClick = noop,
  option,
  selected = false,
}) => {
  const { label, value } = option;

  const handleClick = useCallback(() => onClick(value), [onClick, value]);
  const showEmptyOrCounterLabel =
    value === 'no-selected' || value === 'selected-counter-label';

  return (
    <div
      className={cn(block('option', { selected }), className)}
      onClick={handleClick}
    >
      {showEmptyOrCounterLabel ? (
        <>
          <List />
          {label}
        </>
      ) : (
        <RadioOption
          selected={option.selected}
          option={option}
          indicatorClassName={block('option-indicator')}
          appearance="checkmark"
        />
      )}
    </div>
  );
};

export default SelectOption;
