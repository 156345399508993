import cn from 'classnames';
import React from 'react';
import { H3 } from 'components/Heading';

import { useToggleCard } from './ToggleCardContext';
import { block } from './utils';

export interface ToggleCardInfoProps {
  children?: React.ReactNode;
  hidden?: boolean;
  icon?: React.ReactNode;
}

const ToggleCardInfo: React.FC<ToggleCardInfoProps> = ({
  children,
  hidden,
  icon,
}) => {
  const { theme } = useToggleCard();

  return (
    <div className={block('info', { hidden })}>
      {React.isValidElement(icon) &&
        React.cloneElement(icon, {
          className: cn(
            icon.props.className,
            block('info-icon', { [theme]: !!theme }),
          ),
        })}
      <H3 className={block('text')}>{children}</H3>
    </div>
  );
};

export default ToggleCardInfo;
