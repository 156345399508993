import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A>(method: M, args: A) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export function uploadVideo(
  video: types.Source | undefined,
  opts: types.UploadVideoOptions,
): types.UploadVideoAction {
  const {
    trimStart,
    trimEnd,
    transcribe,
    language,
    directUploadBucket,
    directUploadKey,
    initiateProcessing,
    providerUserId,
    googleDriveFileId,
    zoomMeetingId,
    zoomRecordingFileId,
    youtubeUrl,
  } = opts;
  return apiCallAction(types.ServiceMethod.UPLOAD_VIDEO, [
    video,
    transcribe,
    trimStart,
    trimEnd,
    language,
    directUploadBucket,
    directUploadKey,
    initiateProcessing,
    providerUserId,
    googleDriveFileId,
    zoomMeetingId,
    zoomRecordingFileId,
    youtubeUrl,
  ] as types.UploadVideoArgs);
}

export function getVideo(id: number | string): types.GetVideoAction {
  return apiCallAction(types.ServiceMethod.GET_VIDEO, [id]);
}

export function searchVideo(
  md5: string,
  trimStartMillis: number,
  trimEndMillis: number,
  enableTranscription: boolean,
  language: string,
): types.SearchVideoAction {
  return apiCallAction(types.ServiceMethod.SEARCH_VIDEO, [
    md5,
    trimStartMillis,
    trimEndMillis,
    enableTranscription,
    language,
  ]);
}

export function uploadText(
  text: string,
  urlSummaryId?: string,
): types.UploadTextAction {
  return apiCallAction(types.ServiceMethod.UPLOAD_TEXT, [
    text,
    urlSummaryId,
  ] as types.UploadTextArgs);
}

export function getText(id: string): types.GetTextAction {
  return apiCallAction(types.ServiceMethod.GET_TEXT, [id] as [string]);
}

export function convertGifToVideo(
  source: types.Source,
): types.ConvertGifToVideoAction {
  return apiCallAction(types.ServiceMethod.CONVERT_GIF_TO_VIDEO, [source] as [
    types.Source,
  ]);
}

export function parseExternalContent(
  url: string,
): types.ParseExternalContentAction {
  return apiCallAction(types.ServiceMethod.PARSE_EXTERNAL_CONTENT, [url]);
}

export function directUpload(
  file: Blob,
  type: types.DirectUploadFileType = 'audio',
): types.DirectUploadAction {
  return apiCallAction(types.ServiceMethod.DIRECT_UPLOAD, [
    type,
    file.type,
    file instanceof File ? file.name : undefined,
  ]);
}

export function getMediaContentInfo(
  url: string,
): types.GetMediaContentInfoAction {
  return apiCallAction(types.ServiceMethod.GET_MEDIA_CONTENT_INFO, [url]);
}
