import { MixpanelWizardStep } from 'redux/modules/mixpanel/types';
import { Step } from './types';

export const MIXPANEL_WIZARD_STEP_MAP: Record<Step, MixpanelWizardStep> = {
  source: 'Source',
  searchPodcast: 'Select Audio',
  aspectRatio: 'Choose Aspect Ratio',
  deliveryMethod: 'Choose delivery method',
  customize: 'Customize',
  backCatalogCustomize: 'Customize',
  export: 'loading',
  submitting: 'loading',
  template: 'Choose Template',
  uploadAudio: 'Select Audio',
  language: 'Language',
  backCatalogFinalMessage: 'Completed',
};
