import React from 'react';

import { IconProps } from './types';

const TiktokPreset: React.FunctionComponent<IconProps> = props => (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fillRule="nonzero" fill="none">
      <path
        d="M8.152 4.332c.803.553 1.786.88 2.848.88V3.238c-.201 0-.401-.02-.598-.06V4.73a5.003 5.003 0 0 1-2.848-.88v4.024c0 2.013-1.69 3.645-3.777 3.645a3.856 3.856 0 0 1-2.102-.616A3.834 3.834 0 0 0 4.375 12c2.086 0 3.777-1.632 3.777-3.645V4.332zm.738-1.99A2.704 2.704 0 0 1 8.152.735V.48h-.566A2.76 2.76 0 0 0 8.89 2.343zM2.994 9.36a1.623 1.623 0 0 1-.353-1.01c0-.92.774-1.666 1.728-1.666.178 0 .355.026.524.078V4.745a3.946 3.946 0 0 0-.597-.033V6.28a1.787 1.787 0 0 0-.525-.078c-.954 0-1.728.746-1.728 1.667 0 .65.387 1.214.95 1.489z"
        fill="#FF004F"
      />
      <path
        d="M7.554 3.852c.803.553 1.786.879 2.848.879V3.179a2.884 2.884 0 0 1-1.512-.836A2.76 2.76 0 0 1 7.586.48h-1.49v7.875c-.002.918-.775 1.662-1.727 1.662-.561 0-1.06-.258-1.375-.658a1.662 1.662 0 0 1-.95-1.49c0-.92.773-1.666 1.727-1.666.183 0 .36.027.524.078V4.71C2.246 4.754.598 6.369.598 8.356c0 .992.41 1.892 1.077 2.549.6.389 1.324.616 2.102.616 2.086 0 3.777-1.632 3.777-3.645V3.852z"
        fill="#FFF"
      />
      <path
        d="M10.402 3.179v-.42a2.924 2.924 0 0 1-1.512-.417c.401.425.93.717 1.512.837zM7.586.48a2.72 2.72 0 0 1-.032-.226V0H5.5v7.875c-.003.918-.776 1.662-1.728 1.662-.28 0-.543-.064-.777-.178.315.4.814.658 1.375.658.952 0 1.725-.744 1.728-1.662V.48h1.489zm-3.29 4.232v-.447a3.95 3.95 0 0 0-.519-.034C1.691 4.231 0 5.863 0 7.875c0 1.262.665 2.374 1.675 3.028A3.568 3.568 0 0 1 .598 8.355c0-1.987 1.648-3.602 3.698-3.643z"
        fill="#00F2EA"
      />
    </g>
  </svg>
);

export default TiktokPreset;
