import React from 'react';
import { useDispatch } from 'react-redux';
import { Alert } from 'components/icons';
import { hideModal } from 'redux/modules/modal';
import { showLiveChatWidget } from 'utils/helpdesk';
import DownloadCaptionsDialogModalContents from './DownloadCaptionsDialogModalContents';

export interface EmptyEpisodeTranscriptModalContentsProps {}

const EmptyEpisodeTranscriptModalContents: React.FC<EmptyEpisodeTranscriptModalContentsProps> = () => {
  const dispatch = useDispatch();

  return (
    <DownloadCaptionsDialogModalContents
      actions={[
        {
          children: 'contact support',
          fluid: true,
          key: 'support',
          onClick: showLiveChatWidget,
          theme: 'submit-alt',
        },
        {
          // doesn't need onClick - the modal will close by default when clicking this button
          children: 'got it',
          fluid: true,
          key: 'close',
          theme: 'submit',
        },
      ]}
      details="This episode doesn’t appear to contain any spoken words, so naturally there is nothing to transcribe. If you believe this is an error, please contact support so we can fix it."
      icon={<Alert style={{ height: 100, width: 100 }} />}
      onClose={() => dispatch(hideModal())}
      title="We have no words… literally!"
    />
  );
};

export default EmptyEpisodeTranscriptModalContents;
