import React from 'react';

// types
import { IconProps } from './types';

const FooterFacebook: React.FC<IconProps> = ({ ...svgProps }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M20 2.143v15.714C20 19.04 19.04 20 17.857 20H14.05v-7.91h2.705l.389-3.019h-3.094V7.143c0-.875.241-1.469 1.496-1.469h1.598V2.978a21.487 21.487 0 0 0-2.33-.12c-2.304 0-3.884 1.405-3.884 3.99v2.228H8.214v3.018h2.719V20h-8.79A2.143 2.143 0 0 1 0 17.857V2.143C0 .96.96 0 2.143 0h15.714C19.04 0 20 .96 20 2.143z"
      fill="#3B5998"
      fillRule="nonzero"
    />
  </svg>
);

export default FooterFacebook;
