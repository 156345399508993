import * as React from 'react';

import useDefaultIntegration from 'blocks/PodcastSearch/useDefaultIntegration';
import useFavoriteIntegration from 'blocks/PodcastSearch/useFavoriteIntegration';
import useRssIntegration from 'blocks/PodcastSearch/useRssIntegration';
import { Omit } from 'types';
import AddAudioStep, { AddAudioStepProps } from '../../AddAudioStep';

type PickedAudioStepProps = Omit<
  AddAudioStepProps,
  | 'footer'
  | 'maxFileSizeMb'
  | 'podcastSearchIntegrations'
  | 'video'
  | 'wizardType'
>;

export interface AudiogramAddAudioStepProps extends PickedAudioStepProps {
  onSampleVideoPlay?: () => void;
}

const AudiogramAddAudioStep: React.SFC<AudiogramAddAudioStepProps> = ({
  onAudioAdded,
  onSampleVideoPlay,
  ...props
}) => {
  return (
    <AddAudioStep
      {...props}
      onAudioAdded={onAudioAdded}
      podcastSearchIntegrations={[
        useDefaultIntegration({
          onAudioAdded,
          priority: 3,
        }),
        useRssIntegration({
          onAudioAdded,
          episodeExplorerEnabled: true,
          priority: 2,
        }),
        useFavoriteIntegration({
          onAudioAdded,
          priority: 0,
        }),
      ]}
      maxFileSizeMb={500}
      wizardType="audiogram"
    />
  );
};

export default AudiogramAddAudioStep;
