import { RequestStatus } from 'types';
import { Type } from './action-types';
import { presetsFromJs, stateFactory } from './factories';
import { TextPresetsAction, TextPresetsState } from './types';

const initialState = stateFactory();

const textPresetsReducer = (
  state = initialState,
  action: TextPresetsAction,
): TextPresetsState => {
  switch (action.type) {
    case Type.TEXT_PRESETS_REQUEST:
      return state.set('status', RequestStatus.REQUEST);

    case Type.TEXT_PRESETS_REQUEST_FAILURE:
      return state.set('status', RequestStatus.FAILURE);

    case Type.TEXT_PRESETS_REQUEST_SUCCESS:
      return state.withMutations(s => {
        s.set('status', RequestStatus.SUCCESS);
        s.set('presets', presetsFromJs(action.payload.presets));
        return s;
      });

    default:
      return state;
  }
};

export default textPresetsReducer;
