export enum Type {
  FACEBOOK_PAGES_GET_REQUEST = 'app/social/facebook/PAGES_GET_REQUEST',
  FACEBOOK_PAGES_GET_SUCCESS = 'app/social/facebook/PAGES_GET_SUCCESS',
  FACEBOOK_PAGES_GET_FAILURE = 'app/social/facebook/PAGES_GET_FAILURE',
  FACEBOOK_PAGES_CLEAR = 'app/social/facebook/PAGES_CLEAR',

  FACEBOOK_USER_DATA_GET_REQUEST = 'app/social/linkedin/FACEBOOK_USER_DATA_GET_REQUEST',
  FACEBOOK_USER_DATA_GET_SUCCESS = 'app/social/linkedin/FACEBOOK_USER_DATA_GET_SUCCESS',
  FACEBOOK_USER_DATA_GET_FAILURE = 'app/social/linkedin/FACEBOOK_USER_DATA_GET_FAILURE',
  FACEBOOK_USER_ID_SELECT = 'app/social/facebook/FACEBOOK_USER_ID_SELECT',

  FACEBOOK_VIDEO_POST_REQUEST = 'app/social/facebook/VIDEO_POST_REQUEST',
  FACEBOOK_VIDEO_POST_SUCCESS = 'app/social/facebook/VIDEO_POST_SUCCESS',
  FACEBOOK_VIDEO_POST_FAILURE = 'app/social/facebook/VIDEO_POST_FAILURE',

  FACEBOOK_VIDEO_POST_AWAIT_REQUEST = 'app/social/facebook/VIDEO_POST_AWAIT_REQUEST',
  FACEBOOK_VIDEO_POST_AWAIT_SUCCESS = 'app/social/facebook/VIDEO_POST_AWAIT_SUCCESS',
  FACEBOOK_VIDEO_POST_AWAIT_FAILURE = 'app/social/facebook/VIDEO_POST_AWAIT_FAILURE',

  FACEBOOK_AUTHORIZE_SUCCESS = 'app/social/facebook/AUTHORIZE_SUCCESS',
}
