import { Query } from 'types';
import bem from 'utils/bem';

export const block = bem('video-clip-wizard');

export function getTraceIdsFromQuery(query: Query = {}) {
  const { traceId, customTraceId } = query;

  return {
    customTraceId: customTraceId as string,
    traceId: traceId as string,
  };
}
