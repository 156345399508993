import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Wave } from 'components/icons';
import WaveformPresets from 'containers/WaveformPresets';
import WaveformPresetsHeader from 'containers/WaveformPresetsHeader';
import { defaultWaveformPrefOnlyEnabledSelector } from 'redux/modules/display-pref/selectors';
import {
  onDeleteWaveformPreset,
  onSaveWaveformPreset,
} from 'redux/modules/mixpanel/actions';
import { Soundwave, SoundwaveType } from 'types';

import { defaultSoundwaveState } from 'utils/embed/soundwave';
import { useEditorState } from './context/VideoEditorStateContext';
import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import OptionChildView, { OptionChildViewProps } from './OptionChildView';
import { getSoundwave } from './state';
import { isFeatureHidden } from './state/features-utils';
import { soundwaveDimensionsToPlacement } from './state/soundwave-placement';
import TileGrid from './TileGrid';
import { WaveformFidelity, WaveformPlacement } from './types';
import { waveformViewBlock as block } from './utils';
import VideoTemplateEditorColorPicker from './VideoTemplateEditorColorPicker';
import {
  WaveformFidelityTile,
  WaveformPlacementTile,
} from './waveform-settings';
import waveformTiles from './waveform-tiles';

const { useCallback } = React;

const WAVEFORM_ORDER: SoundwaveType[] = [
  'none',
  'wave',
  'roundBars',
  'wideRoundBars',
  'pixel',
  'curve',
  'line',
  'blob',
  'pulse',
  'burst',
  'linearDots',
  'waveBars',
  'bricks',
  'equalizer',
];

export type WaveformChildViewProps = Pick<
  OptionChildViewProps,
  'active' | 'onClose'
>;

const WaveformChildView: React.FC<WaveformChildViewProps> = ({
  active,
  onClose,
}) => {
  const reduxDispatch = useDispatch();
  const dispatch = useTemplateDispatch();
  const state = useTemplateState();
  const { soundwave, aspectRatio } = state;
  const { features } = useEditorState();
  const locked = useSelector(defaultWaveformPrefOnlyEnabledSelector);

  const handleColorChange = useCallback(
    (color: string) => {
      dispatch({ type: 'SOUNDWAVE_COLOR_CHANGE', payload: color });
    },
    [dispatch],
  );

  const handleWaveformClick = useCallback(
    (type: SoundwaveType) => {
      dispatch({ type: 'SOUNDWAVE_TYPE_CHANGE', payload: type });
    },
    [dispatch],
  );

  const handleFidelityChange = useCallback(
    (fidelity: WaveformFidelity) => {
      dispatch({ type: 'SOUNDWAVE_FIDELITY_CHANGE', payload: fidelity });
    },
    [dispatch],
  );

  const handlePlacementChange = useCallback(
    (placement: WaveformPlacement) => {
      if (placement === undefined) {
        dispatch({
          type: 'SOUNDWAVE_RESET',
        });
      } else {
        dispatch({
          payload: placement,
          type: 'SOUNDWAVE_PLACEMENT_SELECT',
        });
      }
    },
    [dispatch],
  );

  const handleSaved = (preset: Soundwave) => {
    reduxDispatch(onSaveWaveformPreset('UCS', aspectRatio));
    handleSelect(preset);
  };

  const handleDeleted = () => {
    reduxDispatch(onDeleteWaveformPreset('UCS', aspectRatio));
  };

  const handleSelect = (preset: Soundwave) => {
    dispatch({
      type: 'SOUNDWAVE_PRESET_SELECT',
      payload: preset,
    });
  };

  return (
    <OptionChildView
      active={active}
      className={block()}
      bodyClassName={block('body')}
      icon={<Wave />}
      onClose={onClose}
      title="waveform"
      footer={
        !locked && (
          <section className={block('presets')}>
            <WaveformPresetsHeader
              getSoundwaveToSave={() => getSoundwave(state)}
              onSaved={handleSaved}
              aspectRatio={aspectRatio}
              disabled={
                !soundwave?.type ||
                soundwave?.type === 'none' ||
                !!soundwave?.waveformPrefId
              }
            />
            <WaveformPresets
              onSelect={handleSelect}
              onDeleted={handleDeleted}
              selectedWaveformPrefId={soundwave?.waveformPrefId}
              aspectRatio={aspectRatio}
            />
          </section>
        )
      }
    >
      {locked ? (
        <section className={block('presets')}>
          <WaveformPresets
            onSelect={handleSelect}
            selectedWaveformPrefId={soundwave?.waveformPrefId}
            aspectRatio={aspectRatio}
          />
        </section>
      ) : (
        <>
          <div className={block('settings')}>
            {!isFeatureHidden(features, ['waveform', 'placement']) && (
              <WaveformPlacementTile
                onChange={handlePlacementChange}
                value={soundwaveDimensionsToPlacement(soundwave)}
              />
            )}
            <VideoTemplateEditorColorPicker
              swatchClassName={block('swatch')}
              color={soundwave?.color ?? defaultSoundwaveState.get('waveColor')}
              onChangeComplete={handleColorChange}
            />
            {!isFeatureHidden(features, ['waveform', 'fidelity']) && (
              <WaveformFidelityTile
                onChange={handleFidelityChange}
                value={soundwave?.fidelity}
              />
            )}
          </div>
          <TileGrid className={block('wave-type-grid')}>
            {WAVEFORM_ORDER.map(type => {
              const Component = waveformTiles[type];
              return (
                <Component
                  key={type}
                  onClick={handleWaveformClick}
                  selected={type === soundwave?.type}
                />
              );
            })}
          </TileGrid>
        </>
      )}
    </OptionChildView>
  );
};

export default WaveformChildView;
