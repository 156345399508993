import cn from 'classnames';
import * as React from 'react';

import bem from 'utils/bem';
import PlanHeader, { PlanHeaderProps } from './PlanHeader';

type PickedHeaderProps = Pick<
  PlanHeaderProps,
  | 'className'
  | 'cost'
  | 'density'
  | 'description'
  | 'icon'
  | 'period'
  | 'theme'
  | 'title'
>;

export type SubPeriodPlanHeaderProps = PickedHeaderProps;

const block = bem('sub-period-plan-header');

const SubPeriodPlanHeader: React.FC<SubPeriodPlanHeaderProps> = ({
  className,
  cost,
  density,
  description,
  icon,
  period,
  theme,
  title,
}) => (
  <PlanHeader
    className={cn(block(), className)}
    {...{ cost, density, description, icon, period, theme, title }}
  />
);

export default SubPeriodPlanHeader;
