import * as React from 'react';
import { connect } from 'react-redux';

import { selectors as embedSelectors } from 'redux/modules/embed';
import { State } from 'redux/types';
import { TextOverlayModalProps } from './TextOverlayModal';

type StateProps = Pick<TextOverlayModalProps, 'workspaceAspectRatio'>;

const mapStateToProps = (state: State): StateProps => ({
  workspaceAspectRatio: embedSelectors.aspectRatioSelector(state),
});

export default function(component: React.ComponentType<TextOverlayModalProps>) {
  return connect(mapStateToProps)(component);
}
