import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';
import { ProBadge } from 'components/icons';
import IconWithTooltip from 'components/IconWithTooltip';
import WrappedSelect from 'components/WrappedSelect';
import FormField, { FormFieldProps } from './FormField';
import { block } from './utils';

interface IProps
  extends Pick<React.InputHTMLAttributes<HTMLSelectElement>, 'value'> {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  inputClassName?: string;
  lockedWrapperClassName?: string;
  defaultValueText?: string | number;
  selectClassName?: string;
  lockedTooltipContent?: string;
  onLockedClick?: () => void;
  onChange?: (value: string, e: React.ChangeEvent<HTMLSelectElement>) => void;
  variant?: 'locked' | 'pro' | 'default';
}

type Props = FormFieldProps<IProps>;

interface IState {
  showLockTooltip: boolean;
}

export default class SelectField extends React.Component<Props, IState> {
  public static defaultProps: Partial<Props> = {
    defaultValueText: '',
    variant: 'default',
    onChange: noop,
    lockedTooltipContent: '',
  };

  public state: Readonly<IState> = {
    showLockTooltip: false,
  };

  private handleChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    this.props.onChange(e.target.value, e);

  private handleMouseEnter = () => this.setState({ showLockTooltip: true });

  private handleMouseLeave = () => this.setState({ showLockTooltip: false });

  private renderSelect = () => {
    const { children, inputClassName, variant } = this.props;

    const isLocked = variant === 'locked';

    return (
      <WrappedSelect
        className={block('select-control', { isLocked })}
        inputClassName={cn(block('select-input'), inputClassName)}
        onChange={this.handleChange}
      >
        {children}
      </WrappedSelect>
    );
  };

  public renderNonLockedSelect = () => {
    const {
      children,
      onChange,
      inputClassName,
      variant,
      lockedWrapperClassName,
      defaultValueText,
      ...props
    } = this.props;

    return <FormField<IProps> {...props}>{this.renderSelect()}</FormField>;
  };

  public renderLockedSelect = () => {
    const {
      children,
      onChange,
      disabled,
      variant,
      lockedWrapperClassName,
      defaultValueText,
      value,
      lockedTooltipContent,
      onLockedClick,
      ...props
    } = this.props;
    const { showLockTooltip } = this.state;

    return (
      <div
        className={cn(
          block('locked-wrapper', { badge: variant === 'pro' }),
          lockedWrapperClassName,
        )}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={onLockedClick}
      >
        <FormField<IProps> {...props} disabled={disabled}>
          <div className={block('locked-container')}>
            <div className={block('locked-input')}>{defaultValueText}</div>
          </div>
        </FormField>

        <IconWithTooltip
          tooltipId="input-lock"
          className={block('input-lock', {
            vertical: !!(!props.horizontal && props.label),
          })}
          showTooltip={showLockTooltip}
          {...(lockedTooltipContent && {
            tooltipContent: lockedTooltipContent,
          })}
          icon={variant === 'pro' ? <ProBadge /> : undefined}
        />
      </div>
    );
  };

  public render() {
    const { variant } = this.props;

    return variant === 'pro' || variant === 'locked'
      ? this.renderLockedSelect()
      : this.renderNonLockedSelect();
  }
}

export { Props as SelectFieldProps };
