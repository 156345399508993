import * as Immutable from 'immutable';
import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import _ from 'underscore';

import { createEmbedPreviewUrl } from 'redux/middleware/api/embed-service';
import { pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import embedUtils from 'utils/embed';
import { tokenSelector } from '../../redux/modules/auth/selectors';
import {
  constants as embedConstants,
  selectors as embedSelectors,
} from '../../redux/modules/embed';
import { exportInProgressSelector } from '../../redux/modules/embed-export/selectors';
import { IEmbedConfiguration } from '../../types';
import { IProps } from './types';

type StateProps = Pick<
  IProps,
  | 'clips'
  | 'canPlay'
  | 'durationSeconds'
  | 'exportButtonEnabled'
  | 'copyButtonEnabled'
>;
type DispatchProps = Pick<IProps, 'onExportClick'>;

const { AutosaveStatus, EmbedPlayerStatus } = embedConstants;

const embedPlayerReadySelector = createSelector(
  [embedSelectors.embedPlayerStatusSelector],
  (status: string): boolean => status === (EmbedPlayerStatus as any).READY,
);

const canPlaySelector = createSelector(
  [embedSelectors.autosaveStatusSelector, embedPlayerReadySelector],
  (autosaveStatus: string, playerReady: boolean): boolean => {
    const notSet = _.isUndefined(autosaveStatus);
    const done =
      (AutosaveStatus as any).enumValueOf(autosaveStatus) ===
      (AutosaveStatus as any).DONE;
    return (notSet || done) && playerReady;
  },
);

const embedDurationSelector = createSelector(
  [embedSelectors.embedDurationMillisSelector],
  (millis: number): number => millis / 1000,
);

const embedUrlSelector = createSelector(
  [
    embedSelectors.embedConfigSelector,
    tokenSelector,
    embedSelectors.embedLoadingSelector,
  ],
  (config: IEmbedConfiguration, token: string, isLoading: boolean): string =>
    embedUtils.isConfigEmpty(config) || isLoading
      ? undefined
      : createEmbedPreviewUrl({
          recordingId: config.embedConfig.recordingId,
          token,
          widgetId: config.wid,
        }),
);

const exportButtonEnabledSelector = createSelector(
  [exportInProgressSelector, embedUrlSelector, embedDurationSelector],
  (inProgress: boolean, url: string, durationSeconds: number): boolean => {
    const hasUrl = !!url;
    const videoTooLong =
      durationSeconds > spareminConfig.maxVideoExportDurationSeconds;
    return !inProgress && hasUrl && !videoTooLong;
  },
);

const audioClipsSelector = createSelector(
  embedSelectors.audioClipsSelector,
  clips => {
    const ids = Immutable.Set(clips.map(clip => clip.id));
    const data = clips.reduce((acc, clip) => {
      acc[clip.id] = clip;
      return acc;
    }, {});
    return { ids, data };
  },
);

const mapStateToProps = (state: Immutable.Map<string, any>): StateProps => ({
  canPlay: canPlaySelector(state),
  clips: audioClipsSelector(state),
  durationSeconds: embedDurationSelector(state),
  exportButtonEnabled: exportButtonEnabledSelector(state),
  copyButtonEnabled: !!embedUrlSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onExportClick: () => dispatch(pushModal({ name: 'VideoExportModal' })),
});

export default function(component: ComponentType<IProps>) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
