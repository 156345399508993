import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFooterButton } from 'components/Modal';
import { Step } from 'components/SteppedModal';
import { onClickInstagramPostModalCTA } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import NoAccountFoundStep from '../NoAccountFoundStep';
import { StepProps } from '../types';

export default function useNoAccountFoundStep({ goToStep }: StepProps): Step {
  const dispatch = useDispatch<Dispatch>();

  const handleAndroidClick = useCallback((): void => {
    dispatch(onClickInstagramPostModalCTA('Android'));
  }, [dispatch]);

  const handleAppleClick = useCallback((): void => {
    dispatch(onClickInstagramPostModalCTA('Apple'));
  }, [dispatch]);

  return {
    component: <NoAccountFoundStep goToStep={goToStep} />,
    id: 'no-account-found',
    fluidFooterButtons: true,
    renderFooterButtons: () => [
      <ModalFooterButton
        theme="submit"
        href="https://play.google.com/store/apps/details?id=app.headliner.headliner"
        target="_blank"
        onClick={handleAndroidClick}
      >
        Android
      </ModalFooterButton>,
      <ModalFooterButton
        theme="submit"
        href="https://apps.apple.com/us/app/headliner-video/id1485577013"
        target="_blank"
        onClick={handleAppleClick}
      >
        Apple
      </ModalFooterButton>,
    ],
  };
}
