import * as React from 'react';

import SampleAudioLibrary, {
  SampleAudioLibraryProps,
} from 'containers/SampleAudioLibrary';

import bem from 'utils/bem';

type Props = Pick<SampleAudioLibraryProps, 'onSelect' | 'selectedId'>;

const block = bem('select-audio-step');

const SelectAudioStep: React.SFC<Props> = props => (
  <div className={block()}>
    <SampleAudioLibrary
      {...props}
      className={block('library')}
      disclaimerClassName={block('disclaimer')}
      hideAudioTileDuration
      noAudioOption
    />
  </div>
);

export default SelectAudioStep;
