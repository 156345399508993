import * as React from 'react';

import { Size } from 'types';
import { Measurement } from 'utils/measurement';
import { CanvasSizeContextType } from '../types';

const { useCallback, useContext, useMemo } = React;

export interface CanvasSizeProviderProps {
  children?: React.ReactNode;
  container: Size<number>;
  canvas: Size<number>;
}

const CanvasSizeContext = React.createContext<CanvasSizeContextType>(undefined);

export function useCanvasSize() {
  return useContext(CanvasSizeContext);
}

export const CanvasSizeProvider: React.FC<CanvasSizeProviderProps> = ({
  canvas: canvasProp = {},
  children,
  container,
}) => {
  const canvas = useMemo(
    () => ({
      height: canvasProp.height,
      width: canvasProp.width,
    }),
    [canvasProp.height, canvasProp.width],
  );

  const convertToPx = useCallback(
    (value: Measurement<any, any>) => {
      return value?.toUnit('px', canvas);
    },
    [canvas],
  );

  const contextValue = useMemo(
    () => ({
      canvas,
      container,
      toPx: convertToPx,
    }),
    [canvas, container, convertToPx],
  );

  return (
    <CanvasSizeContext.Provider value={contextValue}>
      {children}
    </CanvasSizeContext.Provider>
  );
};
