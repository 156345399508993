import * as React from 'react';

import { Omit } from 'types';
import { block } from '../utils';
import TrackAsset, { TrackAssetProps } from './TrackAsset';

type InheritedProps = Omit<
  TrackAssetProps,
  'position' | 'sizeBounds' | 'itemClassName'
>;

export interface TextAssetProps extends InheritedProps {
  minWidth?: number;
  position: TrackAssetProps['position']['x'];
  text?: string;
}

const TextAsset: React.SFC<TextAssetProps> = ({
  minWidth,
  position,
  text,
  ...props
}) => (
  <TrackAsset
    {...props}
    itemClassName={block('text-asset')}
    position={{ x: position, y: 0 }}
    sizeBounds={{ minWidth, maxWidth: Infinity }}
  >
    <div className={block('text-asset-inner')}>
      <span className={block('text')}>{text}</span>
    </div>
  </TrackAsset>
);

export default TextAsset;
