import { Record } from 'immutable';
import { State } from './types';

export const stateFactory = Record<State>(
  {
    sourceImageUrl: undefined,
    provider: undefined,
    imageToVideoJobId: undefined,
    isPollingImageToVideo: false,
    videos: [],
    hasError: false,
    imageToVideoGenerationSucceeded: false,
    shouldCancelPolling: false,
  },
  'ImageToVideoRecord',
);
