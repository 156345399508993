import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A>(method: M, args: A) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export function fetchEmbedConfiguration(
  wid: string,
): types.GetConfigurationAction {
  return apiCallAction(types.ServiceMethod.GET_CONFIGURATION, [
    wid,
  ] as types.GetConfigurationArgs);
}
