import { useCallback, useEffect, useRef } from 'react';
import WaveSurferJs from 'wavesurfer.js';

import usePreviousRef from 'hooks/usePreviousRef';
import { WaveSurferProps } from './types';
import useAudioUrl from './useAudioUrl';

/**
 * hook to handle loading audio into wavesurfer.
 *
 * since loading audio is closely related to rendering peaks, this hook has
 * some overlap in responsibility with usePeaks
 */
export default function useWaveSurferAudio({ peaks, src }: WaveSurferProps) {
  const url = useAudioUrl(src);
  const wavesurfer = useRef<WaveSurferJs>();

  // url that is currently loaded into wavesurfer (last time wavesurfer.load was
  // called)
  const loadedUrl = useRef<string>();
  const latestPeaks = usePreviousRef(peaks);
  const prevUrl = usePreviousRef(url);

  // this callback shouldn't change.  see dependency array
  const loadAudio = useCallback(
    (
      ws: WaveSurferJs,
      audioUrl = prevUrl.current,
      audioPeaks = latestPeaks.current,
    ) => {
      // there might be a race between the effect and callback ref, so check
      // to make sure the url isn't already loaded
      if (ws && audioUrl && audioUrl !== loadedUrl.current) {
        ws.load(audioUrl, audioPeaks);
        loadedUrl.current = audioUrl;
      }
    },
    // note that latestPeaks and prevUrl are refs, but the exhaustive-deps lint
    // rule doesn't know that and requires them to be listed
    [latestPeaks, prevUrl],
  );

  useEffect(() => {
    loadAudio(wavesurfer.current, url);
  }, [url, loadAudio]);

  return useCallback(
    (ws: WaveSurferJs) => {
      wavesurfer.current = ws;
      loadAudio(ws);
    },
    [loadAudio],
  );
}
