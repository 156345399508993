import React from 'react';

import { Replace } from 'components/icons';
import { Omit } from 'types';
import useReplaceMedia from '../useReplaceMedia';
import DynamicChildButton, {
  DynamicChildButtonProps,
} from './DynamicChildButton';

export type ReplaceButtonProps = Omit<
  DynamicChildButtonProps,
  'icon' | 'onClick'
>;

const ReplaceButton: React.FC<ReplaceButtonProps> = props => {
  const onReplaceMedia = useReplaceMedia();

  return (
    <DynamicChildButton
      icon={<Replace style={{ height: 16, width: 21 }} />}
      onClick={onReplaceMedia}
      {...props}
    >
      replace
    </DynamicChildButton>
  );
};

export default ReplaceButton;
