import * as React from 'react';

import { IconProps } from './types';

const Child: React.FC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="18"
    viewBox="0 0 14 18"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M4.375 2.531C4.375 1.133 5.55 0 7 0s2.625 1.133 2.625 2.531C9.625 3.93 8.45 5.062 7 5.062S4.375 3.93 4.375 2.533zm9.283.049a1.197 1.197 0 0 0-1.65 0L8.85 5.625h-3.7L1.992 2.58a1.197 1.197 0 0 0-1.65 0 1.097 1.097 0 0 0 0 1.59l3.45 3.327v9.378c0 .621.522 1.125 1.166 1.125h.584c.644 0 1.166-.504 1.166-1.125v-3.938h.584v3.938c0 .621.522 1.125 1.166 1.125h.584c.644 0 1.166-.504 1.166-1.125V7.497l3.45-3.327a1.097 1.097 0 0 0 0-1.59z"
    />
  </svg>
);

export default Child;
