import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStaticCallback from 'hooks/useStaticCallback';
import {
  cancelWaitForExport,
  waitForExport,
} from 'redux/modules/common/actions/export';
import { Dispatch } from 'redux/types';
import { ExportVideoStepProps } from './types';
import useExportVideoStepTransitions from './useExportVideoStepTransitions';
import { createEmbedExportSelector } from './utils';

type UseExportVideoStepConfig = Pick<
  ExportVideoStepProps,
  'onError' | 'pollIntervalMillis' | 'widgetId'
>;

export default function useExportVideoStep({
  onError,
  pollIntervalMillis,
  widgetId,
}: UseExportVideoStepConfig) {
  const dispatch = useDispatch<Dispatch>();
  const { status, progressInfo } = useSelector(
    useMemo(() => createEmbedExportSelector(widgetId), [widgetId]),
  );
  const handleError = useStaticCallback(onError);

  const progress = Math.round((progressInfo?.progress ?? 0) * 100);

  const animationConfig = useExportVideoStepTransitions({
    exportStatus: status,
  });

  useEffect(() => {
    if (widgetId) {
      dispatch(
        waitForExport(widgetId, { intervalMillis: pollIntervalMillis }),
      ).catch(handleError);
    }
    return () => dispatch(cancelWaitForExport());
  }, [dispatch, handleError, pollIntervalMillis, widgetId]);

  return {
    animationConfig,
    progress,
    status,
  };
}
