import * as ids from 'short-id';
import { DEFAULT_PROGRESS_OPTIONS } from 'utils/embed/progress';
import { DEFAULT_TRACK_ORDER } from 'utils/embed/tracks';
import { ViewportHeight, ViewportWidth } from 'utils/measurement';
import { stringToViewport } from 'utils/placement';
import {
  IntroOutroState,
  LayerState,
  MainImage,
  ProgressState,
  SlideEffect,
  VideoTemplateEditorFeatures,
} from '../types';

export const DEFAULT_BG_COLOR = 'black';

export const DEFAULT_IMAGE_EFFECT: SlideEffect = {
  effect: 'none',
};

export const DEFAULT_INTRO_OUTRO_STATE: IntroOutroState = {};

export const DEFAULT_ORDERED_STATE = {
  data: {},
  order: [],
};

export const DEFAULT_SLIDESHOW_STATE = {
  ...DEFAULT_ORDERED_STATE,
  newImages: [],
};

export const DEFAULT_FEATURES: VideoTemplateEditorFeatures = {
  templates: true,
  waveform: true,
  captions: 'hidden',
};

export const DEFAULT_WATERMARK_SIZE_PX = 126;
export const DEFAULT_WATERMARK_MARGIN_DISTANCE_PX = 22;

export const PADDED_WIDTH_CIRCLE = new ViewportWidth(30);
export const PADDED_WIDTH_DOTS = new ViewportWidth(25);
export const PADDED_WIDTH = new ViewportWidth(88.88);
export const PADDED_HEIGHT = new ViewportHeight(13.88);
export const PADDED_OFFSET = new ViewportHeight(18.07);
export const MEASUREMENT_ERROR_MARGIN = 0.09;

export const CHILD_VIEW_ZOOM_DURATION_MS = 300;
export const CHILD_VIEW_ZOOM_DELAY_MS = 300;

export const DEFAULT_MAIN_IMAGE: MainImage = {
  ids: [],
  src: undefined,
};

export const DEFAULT_KEY_TEXT_ASSETS = {
  episodeTitle: undefined,
  mainText: undefined,
  podcastTitle: undefined,
};

export const DEFAULT_LAYER_STATE: LayerState = DEFAULT_TRACK_ORDER.reduce(
  (acc, trackType) => {
    // editor only supports media and text tracks
    if (trackType !== 'media' && trackType !== 'text') return acc;
    const id = ids.generate();
    const layer = { id, type: trackType };
    acc.data[id] = layer;
    acc.order.push(id);
    return acc;
  },
  { data: {}, order: [] },
);

export const DEFAULT_PROGRESS_STATE: ProgressState = stringToViewport(
  DEFAULT_PROGRESS_OPTIONS,
);

export const OPTION_TILE_FEATURE_PATHS = {
  waveform: ['waveform'],
  image: ['image'],
  introOutro: ['introOutro'],
  watermark: ['watermark'],
  text: ['text'],
  progress: ['progress'],
  background: ['background'],
  templates: ['templates'],
  captions: ['captions'],
};
