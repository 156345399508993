import * as React from 'react';

import Image from 'components/Image';

interface IProps {
  className?: string;
  src?: string;
  style?: React.CSSProperties;
}

const VideoPoster: React.SFC<IProps> = ({ className, src, style }) => {
  return (
    <Image className={className} src={src} style={style} draggable={false} />
  );
};

export default VideoPoster;
