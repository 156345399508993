import * as React from 'react';

import CustomizeStepTooltip from './CustomizeStepTooltip';
import { block } from './utils';

export interface CustomizeStepCtaButtonTooltipProps {
  children?: React.ReactNode;
  disabled?: boolean;
  id: string;
  tooltip?: React.ReactNode;
}

const CustomizeStepCtaButtonTooltip: React.FC<CustomizeStepCtaButtonTooltipProps> = ({
  children,
  disabled,
  id,
  tooltip,
}) => (
  <CustomizeStepTooltip
    id={`${id}-tooltip`}
    content={tooltip}
    show={tooltip ? undefined : false}
  >
    <div className={block('button-wrapper', { disabled })}>{children}</div>
  </CustomizeStepTooltip>
);

export default CustomizeStepCtaButtonTooltip;
