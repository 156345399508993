import { LocationDescriptor } from 'history';
import * as React from 'react';
import { isString } from 'underscore';

import LoadingScreen from 'components/LoadingScreen';

const { useEffect, useRef, useState } = React;

interface IProps {
  redirect: (path: LocationDescriptor) => void;
  path: LocationDescriptor;
}

const Redirect: React.FC<IProps> = ({ path, redirect }) => {
  const [isPathExternal] = useState(isString(path) && !path.startsWith('/'));
  const isPathExternalRef = useRef(isPathExternal);
  const pathRef = useRef(path);
  const redirectRef = useRef(redirect);

  useEffect(() => {
    if (isPathExternalRef.current) {
      window.location.replace(pathRef.current as string);
    } else {
      redirectRef.current(pathRef.current);
    }
  }, []);

  return isPathExternal ? <LoadingScreen message="Redirecting..." /> : null;
};

export default Redirect;

export { IProps as RedirectProps };
