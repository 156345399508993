import { fromJS } from 'immutable';
import * as headlinerUserService from 'redux/middleware/api/headliner-user-service';
import { loggedInSelector } from 'redux/modules/auth/selectors';
import { ThunkAction } from 'redux/types';
import { Soundwave } from 'types';
import { ApplicationError } from 'utils/ApplicationError';
import { getAspectRatioDimensions } from 'utils/aspect-ratio';
import { formatSoundwaveForConfig } from 'utils/embed/soundwave';
import { setMixpanelWaveformPresetsValue } from '../mixpanel/actions';
import { showError } from '../notification/actions';
import { Type } from './action-types';
import { COLOR_HISTORY_SIZE } from './constants';
import {
  isFetchingUserPrefSelector,
  presetColorsSelector,
  waveformPrefIdsSelector,
} from './selectors';

export const getMyUserPref = (): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  if (isFetchingUserPrefSelector(getState())) {
    return;
  }

  dispatch({ type: Type.USER_PREF_GET_REQUEST });

  // function gets preferences for logged in user.  if user isn't logged in,
  // request will fail so abort
  if (!loggedInSelector(getState())) {
    // dispatch success action so that components know that getting display
    // preferences is complete
    dispatch({ type: Type.USER_PREF_GET_SUCCESS });
    return;
  }

  try {
    await dispatch(headlinerUserService.getMyUserPref());
    dispatch({ type: Type.USER_PREF_GET_SUCCESS });
    setMixpanelWaveformPresetsValue(
      waveformPrefIdsSelector(getState())?.length,
    );
  } catch (err) {
    dispatch({ type: Type.USER_PREF_GET_FAILURE });
    throw new ApplicationError('Error loading user preferences', 'ER017');
  }
};

export const createWaveformPref = (
  aspectRatio: number,
  config: Soundwave,
): ThunkAction<Promise<number>> => async dispatch => {
  try {
    dispatch({ type: Type.WAVEFORM_PREF_CREATE_REQUEST });
    const dimension = getAspectRatioDimensions(aspectRatio);

    if (!dimension) {
      throw Error('Invalid aspect ratio');
    }

    const waveformConfig = formatSoundwaveForConfig(fromJS(config), null);
    const { response } = await dispatch(
      headlinerUserService.createWaveformPref(
        dimension.get('width'),
        dimension.get('height'),
        waveformConfig,
      ),
    );
    const { waveformPrefId } = response;
    await dispatch(getMyUserPref());
    dispatch({ type: Type.WAVEFORM_PREF_CREATE_SUCCESS });
    return waveformPrefId;
  } catch (error) {
    dispatch({ type: Type.WAVEFORM_PREF_CREATE_FAILURE });
    dispatch(
      showError({
        message: 'Error saving waveform preset',
        dismissAfterSec: 5,
      }),
    );
    return null;
  }
};

export const deleteWaveformPref = (
  waveformPrefId: number,
): ThunkAction<Promise<void>> => async dispatch => {
  try {
    dispatch({
      type: Type.WAVEFORM_PREF_DELETE_REQUEST,
      payload: { id: waveformPrefId },
    });
    await dispatch(headlinerUserService.deleteWaveformPref(waveformPrefId));
    await dispatch(getMyUserPref());
    dispatch({
      type: Type.WAVEFORM_PREF_DELETE_SUCCESS,
      payload: { id: waveformPrefId },
    });
  } catch (error) {
    dispatch({
      type: Type.WAVEFORM_PREF_DELETE_FAILURE,
      payload: { id: waveformPrefId },
    });
    dispatch(
      showError({
        message: 'Error deleting waveform preset',
        dismissAfterSec: 5,
      }),
    );
  }
};

export const savePresetColors = (
  color: string,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const presetColors = presetColorsSelector(getState()) ?? [];
  const updatedPresetColors = [
    color,
    ...presetColors.filter(c => c !== color),
  ].slice(0, COLOR_HISTORY_SIZE);

  try {
    dispatch({
      type: Type.COLOR_PREF_UPDATE_REQUEST,
    });

    await dispatch(headlinerUserService.updateColorPref(updatedPresetColors));
    await dispatch(getMyUserPref());

    dispatch({
      type: Type.COLOR_PREF_UPDATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: Type.COLOR_PREF_UPDATE_FAILURE,
    });
  }
};

export type UpdateMyCaptionsPrefActionArgs = {
  transcribe: boolean;
  language: string;
  forcePrefsLoading?: boolean;
};

export const updateMyCaptionsPref = (
  args: UpdateMyCaptionsPrefActionArgs,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const {
    transcribe = false,
    language = 'en-US',
    forcePrefsLoading = false,
  } = args;
  // function gets preferences for logged in user.  if user isn't logged in,
  // request will fail so abort
  if (!loggedInSelector(getState())) {
    // dispatch success action so that components know that getting display
    // preferences is complete
    dispatch({ type: Type.CAPTIONS_PREF_PUT_SUCCESS });
    return;
  }

  dispatch({ type: Type.CAPTIONS_PREF_PUT_REQUEST });
  try {
    await dispatch(
      headlinerUserService.updateMyCaptionsPrefs(transcribe, language),
    );
    dispatch({
      type: Type.CAPTIONS_PREF_PUT_SUCCESS,
    });

    if (forcePrefsLoading) {
      await dispatch(getMyUserPref());
    }
  } catch (err) {
    dispatch({ type: Type.CAPTIONS_PREF_PUT_FAILURE });
    throw new ApplicationError('Error saving user settings', 'ER010');
  }
};

export const updateUserVideoExportPrefs = (
  args: headlinerUserService.VideoExportPrefsArgs,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.VIDEO_EXPORT_PREF_UPDATE_REQUEST });

  try {
    await dispatch(headlinerUserService.updateUserVideoExportPrefsArgs(args));
    dispatch({ type: Type.VIDEO_EXPORT_PREF_UPDATE_SUCCESS });
    dispatch(getMyUserPref());
  } catch (err) {
    dispatch({ type: Type.VIDEO_EXPORT_PREF_UPDATE_FAILURE });
    dispatch(
      showError({ message: 'Error updating your video export preferences' }),
    );
  }
};
