import Immutable from 'immutable';

import * as embedTypes from '../embed/action-types';
import * as types from './types';

const defaultState = Immutable.Map();

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.NOTIFICATION_ADD: {
      const notification = action.payload;
      return state.withMutations(s =>
        s
          .setIn(
            ['notificationsById', notification.id.toString()],
            Immutable.fromJS(notification),
          )
          .update('notifications', Immutable.List(), ns =>
            ns.push(notification.id.toString()),
          ),
      );
    }

    case types.NOTIFICATION_CLEAR: {
      const { id } = action.payload;
      return state.withMutations(s =>
        s
          .update('notificationsById', ns => ns && ns.delete(id.toString()))
          .update('notifications', ns => {
            if (!ns) return ns;

            const idx = ns.indexOf(id.toString());
            if (idx < 0) return ns;

            return ns.delete(idx);
          }),
      );
    }

    case embedTypes.EMBED_EDITOR_LOAD_REQUEST:
    case types.NOTIFICATION_CLEAR_ALL: {
      return state.withMutations(s =>
        s.delete('notifications').delete('notificationsById'),
      );
    }

    case types.NOTIFICATION_SET_INTRO: {
      return state.set('showedIntro', true);
    }

    default:
      return state;
  }
}
