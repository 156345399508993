import * as React from 'react';

import { useSocialSection } from 'blocks/DownloadPage/containers/DownloadPageSocialSection/SocialSectionContext';
import LoadingOverlay from 'components/LoadingOverlay';
import { ShareAICaptionsField } from 'components/ShareForm';
import ShareFormGroup from 'components/ShareForm/ShareFormGroup';
import SocialAccountInfo, {
  SocialAccountInfoProps,
} from 'components/SocialAccountInfo';
import { makeSocialPostLengthCounter } from 'utils/string';
import { getUsernameHandle } from './utils';

type PickedSocialAccountProps = Pick<
  SocialAccountInfoProps,
  'avatarSrc' | 'onSwitchAccountClick' | 'username'
>;

export interface TwitterPostComposerProps extends PickedSocialAccountProps {
  isTweeting?: boolean;
  onDescriptionChange: (description: string) => void;
  description: string;
}

const MAX_CHARS = 280;
const TWITTER_LINK_LENGTH = 23;
const twitterPostLengthCounter = makeSocialPostLengthCounter(
  TWITTER_LINK_LENGTH,
);

const TwitterPostComposer: React.FC<TwitterPostComposerProps> = ({
  isTweeting = false,
  avatarSrc,
  username,
  description,
  onSwitchAccountClick,
  onDescriptionChange,
}) => {
  const {
    allowAiDisclaimer,
    onUnlockAICaptions,
    toggleModalVisibility,
  } = useSocialSection();

  return (
    <>
      {isTweeting && (
        <LoadingOverlay title={isTweeting ? 'Tweeting Video' : 'Loading'} />
      )}
      <ShareFormGroup>
        <SocialAccountInfo
          avatarSrc={avatarSrc}
          username={getUsernameHandle(username)}
          onSwitchAccountClick={onSwitchAccountClick}
        />
      </ShareFormGroup>

      <ShareAICaptionsField
        {...{ allowAiDisclaimer, onUnlockAICaptions }}
        inputProps={{
          onChange: onDescriptionChange,
          value: description,
          maxChars: MAX_CHARS,
          countRemaining: twitterPostLengthCounter,
        }}
        onCancelUnlockAICaptions={() => toggleModalVisibility('twitter')}
      />
    </>
  );
};

export default TwitterPostComposer;
