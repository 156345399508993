export enum Type {
  LOAD_DATA_REQUEST = 'app/clip-select/DATA_LOAD_REQUEST',
  LOAD_DATA_SUCCESS = 'app/clip-select/DATA_LOAD_SUCCESS',
  LOAD_DATA_FAILURE = 'app/clip-select/DATA_LOAD_FAILURE',

  LOAD_CLIP_SUGGESTIONS_REQUEST = 'app/clip-select/LOAD_CLIP_SUGESTIONS_REQUEST',
  LOAD_CLIP_SUGGESTIONS_SUCCESS = 'app/clip-select/LOAD_CLIP_SUGESTIONS_SUCCESS',
  LOAD_CLIP_SUGGESTIONS_FAILURE = 'app/clip-select/LOAD_CLIP_SUGESTIONS_FAILURE',

  CLIP_AUDIO_REQUEST = 'app/clip-select/CLIP_AUDIO_REQUEST',
  CLIP_AUDIO_SUCCESS = 'app/clip-select/CLIP_AUDIO_SUCCESS',
  CLIP_AUDIO_FAILURE = 'app/clip-select/CLIP_AUDIO_FAILURE',

  CLIP_SUGGESTION_DISLIKE = 'app/clip-select/CLIP_SUGGESTION_DISLIKE',

  EMBED_EXPORT_READY = 'app/clip-select/EMBED_EXPORT_READY',

  RESET = 'app/clip-select/RESET',
}
