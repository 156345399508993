import * as React from 'react';

import { Omit } from 'types';
import Podcast from '../../components/Podcast';
import { SearchResultRenderProps } from '../../components/SearchResultList';
import SearchResultListItem from '../../components/SearchResultListItem';
import { PodcastData } from '../../types';

export interface PodcastSearchResultProps
  extends Omit<PodcastData, 'id'>,
    SearchResultRenderProps<string> {
  onClick?: (id: string) => void;
}

const PodcastSearchResult: React.SFC<PodcastSearchResultProps> = ({
  artworkUrl,
  episodeCount,
  host,
  onClick,
  result,
  style,
  title,
}) => (
  <SearchResultListItem onClick={onClick} params={result} style={style}>
    <Podcast
      artworkUrl={artworkUrl}
      episodeCount={episodeCount}
      title={title}
      host={host}
    />
  </SearchResultListItem>
);

export default PodcastSearchResult;
