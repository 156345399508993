import {
  CallBackProps as JoyrideCallBackProps,
  Step as JoyrideStep,
} from 'react-joyride';

/**
 * You can find the type at https://headliner-user-service-dev1.sparemin.com/
 * GET /api/v1/headliner-user/onboarding/type Retrieves all onboarding types
 */
export enum OnboardingName {
  EDITOR_PAGE = 'editor',
  PROJECT_PAGE = 'project',
  DOWNLOAD_PAGE = 'postDownloadPageAction',
  NPS_SURVEY = 'postDownloadPageActionNPS',
}

export interface Step extends JoyrideStep {
  name?: string;
}

export interface CallBackProps extends JoyrideCallBackProps {
  step: Step;
}
