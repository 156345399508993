import React from 'react';

// types
import { IconProps } from './types';

const YoutubePreset: React.FC<IconProps> = ({ ...props }) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M1.8 1.143h7.8v5.143H1.8z" />
      <path
        d="M11.75 1.252A1.47 1.47 0 0 0 10.687.239C9.753 0 6 0 6 0S2.248 0 1.312.24A1.47 1.47 0 0 0 .25 1.251C0 2.145 0 4.008 0 4.008s0 1.863.25 2.756c.139.493.545.865 1.062.997C2.248 8 6 8 6 8s3.752 0 4.688-.24c.517-.131.923-.503 1.061-.996C12 5.871 12 4.008 12 4.008s0-1.863-.25-2.756zM4.772 5.7V2.316l3.136 1.692L4.773 5.7z"
        fill="red"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default YoutubePreset;
