import { Map } from 'immutable';

import { percentageOf, scale } from '../../numbers';
import { createTextShadowString, parseTextShadowString } from '../../ui';
import { ITextShadow } from './types';

enum ScaleByVhKeys {
  size,
  top,
  bottom,
}

enum ScaleByVwKeys {
  fontSize,
  width,
  left,
}

enum VwKeys {
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
}

enum PxKeys {
  borderRadius,
}

enum PercentageKeys {}

export default class StyleScaler {
  public static scaleVhKeys: string[] = Object.keys(ScaleByVhKeys);
  public static scaleVwKeys: string[] = Object.keys(ScaleByVwKeys);
  public static scalePxKeys: string[] = Object.keys(PxKeys);
  public static vwKeys: string[] = Object.keys(VwKeys);
  public static scalePercentageKeys: string[] = Object.keys(PercentageKeys);

  private vh: number;
  private vw: number;

  constructor(vh: number, vw: number) {
    this.vh = vh;
    this.vw = vw;
  }

  public forConfig(
    key:
      | keyof typeof ScaleByVhKeys
      | keyof typeof ScaleByVwKeys
      | keyof typeof PxKeys
      | keyof typeof VwKeys,
    val: number,
  ): { [key: string]: string };

  public forConfig(
    key: 'textShadow',
    val: ITextShadow,
  ): { [key: string]: string };

  public forConfig<T>(key: string, val: T): { [key: string]: string };

  public forConfig(key: string, val: any): { [key: string]: string } {
    if (StyleScaler.scaleVhKeys.indexOf(key) >= 0) {
      return { [key]: `${percentageOf(val, this.vh)}vh` };
    }

    if (StyleScaler.scaleVwKeys.indexOf(key) >= 0) {
      return { [key]: `${percentageOf(val, this.vw)}vw` };
    }

    if (StyleScaler.scalePxKeys.indexOf(key) >= 0) {
      return { [key]: `${val}px` };
    }

    if (StyleScaler.vwKeys.indexOf(key) >= 0 && !String(val).endsWith('em')) {
      return { [key]: `${val}vw` };
    }

    if (StyleScaler.scalePercentageKeys.indexOf(key) >= 0) {
      return { [key]: `${val}%` };
    }

    if (key === 'textShadow') {
      return { [key]: createTextShadowString(val) };
    }

    return { [key]: val };
  }

  public fromConfig(
    key:
      | keyof typeof ScaleByVhKeys
      | keyof typeof ScaleByVwKeys
      | keyof typeof PxKeys
      | keyof typeof VwKeys,
    val: string,
  ): { [key: string]: number };

  public fromConfig(
    key: 'textShadow',
    val: string,
  ): { [key: string]: ITextShadow };

  public fromConfig<T>(key: string, val: string): { [key: string]: T };

  public fromConfig(key: string, val: string): { [key: string]: any } {
    if (StyleScaler.scaleVhKeys.indexOf(key) >= 0) {
      return { [key]: scale(parseFloat(val), 100, this.vh) };
    }

    if (StyleScaler.scaleVwKeys.indexOf(key) >= 0) {
      return { [key]: scale(parseFloat(val), 100, this.vw) };
    }

    if (
      StyleScaler.scalePxKeys.indexOf(key) >= 0 ||
      StyleScaler.scalePercentageKeys.indexOf(key) >= 0 ||
      (StyleScaler.vwKeys.indexOf(key) >= 0 && !String(val).endsWith('em'))
    ) {
      return { [key]: parseFloat(val) };
    }

    if (key === 'textShadow') {
      return {
        [key]: Map(parseTextShadowString(val)),
      };
    }

    return { [key]: val };
  }
}
