import React from 'react';

import { AspectRatioName } from 'types';

import AutogramPreviewLoadingStep from './AutogramPreviewLoadingStep';
import AutogramPreviewReadyStep from './AutogramPreviewReadyStep';

export interface AutogramPreviewStepProps {
  aspectRatioName: AspectRatioName;
  embedUrl?: string;
  isSubmitting: boolean;
  onGoBack: () => void;
  onSubmit: () => void;
  previewLoading: boolean;
  recordingUrl?: string;
}

const AutogramPreviewStep: React.FunctionComponent<AutogramPreviewStepProps> = props => {
  const {
    aspectRatioName,
    embedUrl,
    isSubmitting,
    onGoBack,
    onSubmit,
    previewLoading,
    recordingUrl,
  } = props;

  if (previewLoading || !embedUrl || !recordingUrl) {
    return <AutogramPreviewLoadingStep onCancel={onGoBack} />;
  }

  return (
    <AutogramPreviewReadyStep
      aspectRatioName={aspectRatioName}
      embedUrl={embedUrl}
      isSubmitting={isSubmitting}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
      recordingUrl={recordingUrl}
    />
  );
};

export default AutogramPreviewStep;
