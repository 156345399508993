import * as React from 'react';
import ProgressListContext from './ProgressListContext';
import ProgressListItem from './ProgressListItem';
import { block } from './utils';

interface Props {
  onCompleted?: () => void;
}

interface IProgressList extends React.FC<Props> {
  Item: typeof ProgressListItem;
}

export const ProgressList: IProgressList = props => {
  const { children, onCompleted } = props;
  const completedCountRef = React.useRef(0);
  const itemCount = React.Children.count(children);

  const onItemComplete = () => {
    completedCountRef.current += 1;
    if (completedCountRef.current === itemCount) {
      onCompleted?.();
    }
  };

  return (
    <ProgressListContext.Provider value={onItemComplete}>
      <ul className={block()}>{children}</ul>
    </ProgressListContext.Provider>
  );
};

ProgressList.Item = ProgressListItem;

export default ProgressList;
