import * as React from 'react';
import { useSelector } from 'react-redux';
import { TextWizardStatus } from 'redux/modules/text-wizard/constants';
import { statusSelector } from 'redux/modules/text-wizard/selectors';
import WizardProgressStep, {
  WizardSubmitProgress,
} from '../WizardProgressStep';

interface Props {
  onCompleted: () => void;
}

const { useMemo } = React;

export const TextWizardProgressStep: React.FC<Props> = props => {
  const { onCompleted } = props;
  const status = useSelector(statusSelector);
  const completedSteps: WizardSubmitProgress[] = useMemo(() => {
    switch (status) {
      case TextWizardStatus.FINISHED: {
        return [WizardSubmitProgress.PROJECT_CREATING];
      }
      default: {
        return [];
      }
    }
  }, [status]);
  return (
    <WizardProgressStep
      steps={[WizardSubmitProgress.PROJECT_CREATING]}
      completedSteps={completedSteps}
      onCompleted={onCompleted}
    />
  );
};

export default TextWizardProgressStep;
