import React from 'react';

import { IconProps } from './types';

const IntroOutroGradient: React.FunctionComponent<IconProps> = ({
  fill = 'currentColor',
  ...props
}) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="3wj863hcma">
        <stop stopColor="#5DBEFC" offset="0%" />
        <stop stopColor="#DD92FE" offset="100%" />
      </linearGradient>
      <filter
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        filterUnits="objectBoundingBox"
        id="b5w4l07qyb"
      >
        <feGaussianBlur in="SourceGraphic" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle
        fill="url(#3wj863hcma)"
        filter="url(#b5w4l07qyb)"
        cx="50"
        cy="50"
        r="50"
      />
      <path
        d="M50 35c.789 0 1.429.64 1.429 1.429V63.57a1.429 1.429 0 1 1-2.858 0V36.43c0-.79.64-1.429 1.429-1.429zm-20 6.266c0-1.57 1.772-2.356 2.805-1.247l8.141 8.74c.643.683.643 1.799 0 2.489l-8.141 8.733C31.772 61.09 30 60.304 30 58.734zm40 0v17.468c0 1.57-1.772 2.356-2.805 1.247l-8.141-8.733c-.643-.69-.643-1.806 0-2.488l8.141-8.741C68.228 38.91 70 39.696 70 41.266z"
        fill="#282E38"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default IntroOutroGradient;
