import queryString from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import withBrowserSizeMonitor from 'containers/withBrowserSizeMonitor';
import {
  cancelTranscriptionPolling,
  cancelWaveformPolling,
} from 'redux/modules/common/actions';
import { cancelEmbedExportPolling } from 'redux/modules/embed-export/actions';
import {
  clearDebouncedSaveConfiguration,
  clearEmbedState,
  loadEditor,
  setEditorReady,
} from 'redux/modules/embed/actions';
import {
  autosaveStatusSelector,
  embedLoadingSelector,
} from 'redux/modules/embed/selectors';
import * as mixpanelActions from 'redux/modules/mixpanel/actions';
import { cancelThirdPartyTokenRenewals } from 'redux/modules/oauth/actions';
import { clearProject } from 'redux/modules/project/actions';
import { redirectIdSelector } from 'redux/modules/project/selectors';
import { clearTextWizardState } from 'redux/modules/text-wizard';
import { Dispatch, State } from 'redux/types';
import { removeQueryParam } from 'utils/routes';
import { IProps } from './EditorPage';

type StateProps = Pick<IProps, 'isLoadingData' | 'redirectTo'>;
type DispatchProps = Pick<IProps, 'onMount' | 'onReady' | 'onUnmount'>;
type OwnProps = Pick<IProps, 'location' | 'history' | 'match'>;

const redirectToSelector = createSelector(
  autosaveStatusSelector,
  redirectIdSelector,
  (autosaveStatus, redirectId) =>
    (!autosaveStatus || autosaveStatus === 'DONE') && redirectId,
);

const mapStateToProps = (state: State): StateProps => ({
  isLoadingData: embedLoadingSelector(state),
  redirectTo: redirectToSelector(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onMount: ({ wid, pid, ar, tid, fromWizard }) => {
    if (fromWizard) {
      props.history.replace(removeQueryParam(props, 'fromWizard'));
    }

    dispatch(loadEditor(wid, pid, ar, tid, fromWizard));
  },
  onReady: () => {
    const {
      location,
      match: { params },
    } = props;
    const { tid, source } = queryString.parse(location.search);
    const { pid } = params;
    const projectId = !tid && pid;

    if (source) {
      props.history.replace(removeQueryParam(props, 'source'));
      dispatch(mixpanelActions.onOpenProject(projectId, source as string));
    }

    dispatch(setEditorReady());
  },
  onUnmount: () => {
    dispatch(cancelThirdPartyTokenRenewals());
    dispatch(cancelWaveformPolling());
    dispatch(cancelTranscriptionPolling());
    dispatch(cancelEmbedExportPolling());
    dispatch(clearEmbedState());
    dispatch(clearProject());
    dispatch(clearTextWizardState());
    dispatch(clearDebouncedSaveConfiguration());
    dispatch(mixpanelActions.clearMixpanel());
  },
});

export default function(component: React.ComponentType<IProps>) {
  const connectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(component);
  return withBrowserSizeMonitor(spareminConfig.editorMinDimensions)(
    connectedComponent,
  );
}
