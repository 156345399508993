import { YoutubeVideoVisibility } from 'types';
import bem from 'utils/bem';
import { YoutubeVisibilitySelectorOption } from './types';

export const block = bem('youtube-visibility-selector');

export const getOptions = (): YoutubeVisibilitySelectorOption[] => [
  {
    label: 'Public',
    value: 'public',
  },
  {
    label: 'Unlisted',
    value: 'unlisted',
  },
  {
    label: 'Private',
    value: 'private',
  },
];

export function getVisibilityOption(
  value: YoutubeVideoVisibility,
): YoutubeVisibilitySelectorOption {
  return getOptions().find(opt => opt.value === value);
}
