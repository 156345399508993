import cn from 'classnames';
import * as React from 'react';
import { identity, isUndefined, pick } from 'underscore';

import { getValue } from 'utils/collections';
import SelectContext from './SelectContext';
import { Option, Overrides, SelectOptionProps } from './types';
import { block } from './utils';

const { useContext } = React;

function SelectOption<T extends Option>(props: SelectOptionProps<T>) {
  const { className, onClick, option, selected } = props;
  const { isDisabled } = option;
  const overrides = useContext<Overrides<T>>(SelectContext);

  const handleClick = () => !isDisabled && onClick(option);

  const commonProps = {
    className: cn(
      block('option', {
        selected,
        disabled: isDisabled,
      }),
      className,
    ),
    onClick: handleClick,
  };

  const overriddenProps = getValue(
    overrides,
    ['option', 'props'],
    identity,
  )({
    ...props,
    ...commonProps,
  });

  if (getValue(overrides, ['option', 'component'])) {
    return overrides.option.component(overriddenProps);
  }

  return (
    <div {...pick(overriddenProps, 'className', 'onClick')}>
      {!isUndefined(overriddenProps.option.value) &&
        overriddenProps.option.value !== '' &&
        overriddenProps.option.label}
    </div>
  );
}

export default SelectOption;
