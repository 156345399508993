import { PopoverOptions } from '@typeform/embed';
import { Popover } from '@typeform/embed-react';
import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import BemCssTransition from 'components/BemCssTransition';
import { userIdSelector, userNameSelector } from 'redux/modules/auth';
import { mixpanelDistinctIdSelector } from 'redux/modules/mixpanel/selectors';
import bem from 'utils/bem';

export interface SurveyPopoverProps extends Omit<PopoverOptions, 'autoClose'> {
  autoClose?: number;
  id: string;
}

const block = bem('survey-popover');

const SurveyPopover: React.FC<SurveyPopoverProps> = ({
  autoClose = 1000,
  ...props
}) => {
  const [submitted, setSubmitted] = useState(false);
  const userId = useSelector(userIdSelector);
  const email = useSelector(userNameSelector);
  const mpDistinctId = useSelector(mixpanelDistinctIdSelector);

  // there's no way to style the Popover or add a className, so a container is
  // used to handle this.  container is used to fade out since autoClose doesn't
  // seem to work
  const [container, setContainer] = useState<HTMLDivElement>();

  const propsRef = useRef(props);

  // the survey embed does weird things if the component updates due to a state
  // change.  e.g. when submitting the form, it'll close, reopen, and start
  // from the beginning
  const survey = useMemo(
    () =>
      !container ? null : (
        <Popover
          container={container}
          hidden={{
            headliner_user_id: userId,
            mp_distinct_id: mpDistinctId,
            user_email_address: email,
          }}
          onSubmit={() => setSubmitted(true)}
          {...propsRef.current}
        />
      ),
    [container, email, mpDistinctId, userId],
  );

  return (
    <BemCssTransition
      enter={false}
      in={!submitted}
      timeout={autoClose + 250}
      transitionClassName={block('animation')}
      unmountOnExit
    >
      <div
        className={block('container')}
        ref={setContainer}
        style={{
          transition: `${250}ms opacity ease-out ${autoClose}ms`,
        }}
      >
        {container ? survey : null}
      </div>
    </BemCssTransition>
  );
};

export default SurveyPopover;
