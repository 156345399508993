import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePlanDowngrade from 'blocks/Pricing/usePlanDowngrade';
import { Tier } from 'redux/middleware/api/plan-service';
import { hasBackCatalogSubscriptionSelector } from 'redux/modules/automation-cms/selectors';
import { onClickCancelPlan, onPauseSubscription } from 'redux/modules/mixpanel';
import {
  hideModal,
  pushModal,
  replaceModal,
} from 'redux/modules/modal/actions';
import { legacyDowngradeGuard, planNameSelector } from 'redux/modules/pricing';
import { getSubscriptionPeriod, getTierFromPlan } from 'utils/pricing';
import { capitalize } from 'utils/string';
import { AdjustPlanModalOptions, TierOptionConfig } from './types';

interface OnClickAdjustPeriodParams {
  id: 'period';
}

interface OnClickAdjustTierParams {
  id: 'tier';
  targetTier: Tier;
}

interface OnClickCancelParams {
  id: 'cancel';
}

interface OnClickPauseParams {
  id: 'pause';
}

type OnOptionClickParams =
  | OnClickAdjustPeriodParams
  | OnClickAdjustTierParams
  | OnClickCancelParams
  | OnClickPauseParams;

type AdjustPlanOptionOnClick = (params: OnOptionClickParams) => void;

export interface UseAdjustPlanModalOptionsProps {
  onClick?: AdjustPlanOptionOnClick;
  onHide?: () => void;
}

export interface UseAdjustPlanModalOptionsResult {
  disabled: boolean;
  options: AdjustPlanModalOptions;
}

function getTierOptionConfig(currentTier: Tier): TierOptionConfig | undefined {
  if (currentTier === Tier.PRO) {
    return {
      ctaLabel: 'downgrade',
      targetTier: Tier.BASIC,
    };
  }

  if (currentTier === Tier.BASIC) {
    return {
      ctaLabel: 'upgrade to pro',
      targetTier: Tier.PRO,
    };
  }

  if (currentTier === Tier.UNLIMITED) {
    return {
      ctaLabel: 'downgrade to pro',
      targetTier: Tier.PRO,
    };
  }

  return undefined;
}

export default function useAdjustPlanModalOptions(
  props: UseAdjustPlanModalOptionsProps = {},
): UseAdjustPlanModalOptionsResult {
  const { onClick } = props;

  const dispatch = useDispatch();
  const planName = useSelector(planNameSelector);
  const subPeriod = getSubscriptionPeriod(planName);
  const tier = getTierFromPlan(planName);
  const hasBackCatalogSubscription = useSelector(
    hasBackCatalogSubscriptionSelector,
  );

  const { onDowngrade, disabled } = usePlanDowngrade({
    onDowngradeComplete: () => {
      dispatch(hideModal());
    },
  });

  const handleClick = useCallback(
    (params: OnOptionClickParams) => {
      onClick?.(params);

      if (params.id === 'tier') {
        // SPAR-16225: PlanDowngradeModal is a modal that warns the user about decresing
        // their unwatermarked video quota.  As part of 16225, PRO users will again be getting
        // unlimited videos, so downgrading from Unlimted to Pro shouldn't warn about losing
        // unlimited videos
        if (tier === Tier.UNLIMITED && params.targetTier === Tier.PRO) {
          // legacyDowngradeGuard will open the legacy downgrade modal if applicable or
          // will call the provided function if not.  If the legacy downgrade modal opens,
          // we want to first close the adjust plan modal, but if the legacy downgrade
          // modal doesn't open, we want to keep the adjust plan modal open to show the
          // busy state of the downgrade operation.  using "replace" as the modal
          // operation allows us to close the adjust plan modal and replace it with the
          // legacy downgrade modal if it opens, otherwise it leaves the adjust plan
          // modal open which will then be closed by the onDowngradeCallback of the
          // usePlanDowngrade hook
          dispatch(
            legacyDowngradeGuard('downgrade', onDowngrade, {
              modalOperation: 'replace',
            }),
          );
        } else if (tier === Tier.PRO && params.targetTier === Tier.BASIC) {
          dispatch(hideModal());
          dispatch(
            legacyDowngradeGuard('downgrade', () => {
              dispatch(
                pushModal({
                  name: 'PlanDowngradeModal',
                  params: { toTier: params.targetTier },
                }),
              );
            }),
          );
        } else if (tier === Tier.BASIC && params.targetTier === Tier.PRO) {
          dispatch(hideModal());
          dispatch(
            pushModal({
              name: 'UpgradePlan',
              params: {
                tier: Tier.PRO,
                defaultSubscriptionPeriod: 'yearly',
                loadingMessage: 'Updating Subscription',
              },
            }),
          );
        }
      } else if (params.id === 'cancel') {
        dispatch(onClickCancelPlan());
        dispatch(hideModal());

        if (hasBackCatalogSubscription) {
          dispatch(pushModal({ name: 'BackCatalogDisclaimer' }));
        } else {
          dispatch(pushModal({ name: 'TryEddyAtCancellation' }));
        }
      } else if (params.id === 'pause') {
        dispatch(onPauseSubscription());
        dispatch(replaceModal({ name: 'PauseSubscriptionDetails' }));
      } else if (params.id === 'period') {
        dispatch(
          replaceModal({
            name: 'ChangeBillingCycle',
          }),
        );
      } else {
        dispatch(hideModal());
      }
    },
    [dispatch, hasBackCatalogSubscription, onClick, onDowngrade, tier],
  );

  const tierOptionConfig = getTierOptionConfig(tier);

  return {
    disabled,
    options: [
      {
        cta: {
          label: 'change my cycle',
          onClick: () => handleClick({ id: 'period' }),
        },
        id: 'period',
        label: `Billing cycle: ${subPeriod}`,
      },
      tierOptionConfig && {
        cta: {
          label: tierOptionConfig.ctaLabel,
          onClick: () =>
            handleClick({
              id: 'tier',
              targetTier: tierOptionConfig.targetTier,
            }),
        },
        id: 'tier',
        label: `Your tier: Headliner ${capitalize(tier)}`,
      },
      {
        cta: {
          label: 'pause my plan',
          onClick: () => handleClick({ id: 'pause' }),
        },
        id: 'pause',
        label: 'Need to take a break?',
      },
      {
        cta: {
          label: 'cancel plan',
          onClick: () => handleClick({ id: 'cancel' }),
        },
        id: 'cancel',
        label: 'Need to call it quits?',
      },
    ],
  };
}
