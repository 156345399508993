import React from 'react';
import { useDispatch } from 'react-redux';

import * as mixpanelActions from 'redux/modules/mixpanel';
import { pushModal } from 'redux/modules/modal';

import ClaimPodcastBanner from './ClaimPodcastBanner';

const ClaimPodcastBannerContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const handleClaimPodcast = React.useCallback(() => {
    dispatch(mixpanelActions.onClaimPodcastBanner('CTA'));
    dispatch(pushModal({ name: 'ClaimPodcastModal' }));
  }, [dispatch]);

  const handleClickPlayAppLink = React.useCallback(() => {
    dispatch(mixpanelActions.onClaimPodcastBanner('HyperLink'));
  }, [dispatch]);

  return (
    <ClaimPodcastBanner
      onClaimPodcast={handleClaimPodcast}
      onClickPlayAppLink={handleClickPlayAppLink}
    />
  );
};

export default ClaimPodcastBannerContainer;
