import Immutable from 'immutable';
import { REHYDRATE } from 'redux-persist-immutable/constants';

const processKey = key => {
  const intKey = parseInt(key, 10);
  if (isNaN(intKey)) {
    throw new Error(
      `redux-persist-migrate: migrations must be keyed with integer values but got ${intKey}`,
    );
  }
  return intKey;
};

function getVersionSetterAndSelector(versionSelector, versionSetter) {
  if (typeof versionSelector === 'string') {
    const reducerKey = versionSelector;
    const versionSelectorFn = state =>
      state && state[reducerKey] && state[reducerKey].get('version');

    const versionSetterFn = (state, version) => {
      const reducerState = state[reducerKey];
      if (
        typeof reducerState !== 'undefined' &&
        !Immutable.Map.isMap(reducerState)
      ) {
        return state;
      }
      return {
        ...state,
        [reducerKey]: (state[reducerKey] || Immutable.Map()).set(
          'version',
          version,
        ),
      };
    };

    return {
      selector: versionSelectorFn,
      setter: versionSetterFn,
    };
  }

  return {
    selector: versionSelector,
    setter: versionSetter,
  };
}

/**
 * An adaptation of redux-persist-migrate that works with Immutable js
 *
 * original source: https://github.com/wildlifela/redux-persist-migrate/blob/v4.1.0/src/index.js
 */
export default function createMigration(
  manifest,
  versionSelector,
  versionSetter,
) {
  const {
    selector: getVersion,
    setter: setVersion,
  } = getVersionSetterAndSelector(versionSelector, versionSetter);

  const versionKeys = Object.keys(manifest)
    .map(processKey)
    .sort((a, b) => a - b);
  const latestVersion = versionKeys[versionKeys.length - 1];
  const currentVersion =
    typeof latestVersion === 'undefined' ? -1 : latestVersion;

  const migrate = (state, version) => {
    const newState = versionKeys
      .filter(v => v > version || version === null)
      .reduce((s, v) => manifest[v](s), state);
    return setVersion(newState, currentVersion);
  };

  const migrationDispatch = next => action => {
    if (action.type === REHYDRATE) {
      const incomingState = action.payload;
      const rawIncomingVersion = getVersion(incomingState);
      const incomingVersion = isNaN(parseInt(rawIncomingVersion, 10))
        ? null
        : parseInt(rawIncomingVersion, 10);

      if (incomingVersion !== currentVersion) {
        try {
          const migratedState = migrate(incomingState, incomingVersion);
          return next({
            ...action,
            payload: migratedState,
          });
        } catch (error) {
          // failed to migrate stored state
          return next({
            ...action,
            payload: {}, // ignore stored state
          });
        }
      }
    }
    return next(action);
  };

  return next => (reducer, initialState, enhancer) => {
    const store = next(reducer, initialState, enhancer);
    return {
      ...store,
      dispatch: migrationDispatch(store.dispatch),
    };
  };
}
