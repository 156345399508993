import cn from 'classnames';
import * as React from 'react';

import bem from 'utils/bem';

interface ICoords {
  x: number | string;
  y: number | string;
}

interface IProps {
  className?: string;
  color?: string;
  data?: Array<{
    id: string;
    from: ICoords;
    to: ICoords;
  }>;
  strokeWidth?: number;
}

const block = bem('grid-lines');

const GridLines: React.SFC<IProps> = ({
  className,
  color,
  data,
  strokeWidth,
}) => {
  if (data.length === 0) return null;

  return (
    <svg
      className={cn(block(), className)}
      width="100%"
      height="100%"
      xmlns="http://w3.org/2000/svg"
    >
      {data.map(({ id, from, to }) => (
        <line
          key={id}
          x1={from.x}
          y1={from.y}
          x2={to.x}
          y2={to.y}
          strokeWidth={strokeWidth}
          stroke={color}
        />
      ))}
    </svg>
  );
};

GridLines.defaultProps = {
  color: '#c82828', // sm-error-red
  data: [],
  strokeWidth: 1,
};

export default GridLines;
export { IProps as GridLinesProps };
