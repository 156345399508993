import React from 'react';

import { IconProps } from './types';

const Intro: React.FunctionComponent<IconProps> = ({ ...props }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <filter
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        filterUnits="objectBoundingBox"
        id="uh84eyth9a"
      >
        <feGaussianBlur in="SourceGraphic" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect
        fill="#323746"
        filter="url(#uh84eyth9a)"
        width="40"
        height="40"
        rx="6"
      />
      <path
        d="M26.067 10c.515 0 .933.418.933.933v18.134a.933.933 0 0 1-1.867 0V10.933c0-.515.418-.933.934-.933zM13 14.177c0-1.047 1.158-1.57 1.833-.831l5.319 5.827c.42.455.42 1.199 0 1.659l-5.32 5.822c-.674.74-1.832.216-1.832-.831z"
        fill="#DCE1EB"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default Intro;
