import * as React from 'react';

import { H1 } from 'components/Heading';
import { CircularGradientEnvelope } from 'components/icons/circular-gradient';
import Modal from 'components/Modal';
import { Props } from './types';
import useVerifyEmailModalState from './useVerifyEmailModalState';
import { block } from './utils';

const UpdateEmailModalContent: React.FC<Props> = () => {
  const {
    email,
    loading,
    onChangeEmail,
    onResendEmail,
  } = useVerifyEmailModalState();

  return (
    <>
      <Modal.Body className={block('body')}>
        <div className={block('icon')}>
          <CircularGradientEnvelope />
        </div>
        <H1 className={block('title')}>Please verify your new email</H1>
        <p>
          We sent a verification to <b>{email}</b>.
          <br />
          Can’t find it? Check your spam or choose an option below.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButton
          fluid
          theme="submit-alt"
          onClick={onChangeEmail}
          disabled={loading}
        >
          Change email address
        </Modal.FooterButton>
        <Modal.FooterButton
          fluid
          theme="submit"
          onClick={onResendEmail}
          disabled={loading}
        >
          Resend verification
        </Modal.FooterButton>
      </Modal.Footer>
    </>
  );
};

export default UpdateEmailModalContent;
