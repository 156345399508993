import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';
import { createRequest } from '../utils';

import * as types from './types';

const BASE_API_PATH = '/api/v1/url-generator';

const traceSchema = new schema.Entity(
  'traces',
  {},
  {
    idAttribute: 'traceId',
  },
);

function getCustomTraceById(
  [customTraceId]: types.GetTraceByCustomIdArgs,
  request: SuperAgentStatic,
): Promise<types.GetTraceByCustomIdResult> {
  return request
    .get(`${BASE_API_PATH}/custom-trace/${customTraceId}`)
    .then(res => normalize(res.body, traceSchema));
}

function getTraceById(
  [id]: types.GetTraceByIdArgs,
  request: SuperAgentStatic,
): Promise<types.GetTraceByIdResult> {
  return request
    .get(`${BASE_API_PATH}/trace/${id}`)
    .then(res => normalize(res.body, traceSchema));
}

export const handle: types.Handle = (method, args, token): any => {
  const request = createRequest({
    token,
    baseUrl: spareminConfig.services.urlGenerator,
  });

  switch (method) {
    case types.ServiceMethod.GET_TRACE_BY_CUSTOM_ID:
      return getCustomTraceById(args, request);

    case types.ServiceMethod.GET_TRACE_BY_ID:
      return getTraceById(args, request);

    default:
      throw new Error(`${types.ACTION_KEY} cannot handle method ${method}`);
  }
};
