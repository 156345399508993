import qs from 'query-string';
import { SuperAgentStatic } from 'superagent';
import { createRequest } from '../utils';
import {
  ExternalMediaExistsResult,
  ProxyMediaArgs,
  ProxyMediaResult,
  ServiceMethod,
} from './types';

async function proxyMedia(
  request: SuperAgentStatic,
  [url]: ProxyMediaArgs,
): Promise<ProxyMediaResult> {
  const response = await request
    .get('/audio-proxy')
    .query({ url })
    .responseType('blob');

  return response.body as Blob;
}

async function externalMediaExists(
  request: SuperAgentStatic,
  [url]: ProxyMediaArgs,
): Promise<ExternalMediaExistsResult> {
  const res = await request.head('/audio-proxy').query({
    url: qs.stringifyUrl({
      url,
      query: {
        // https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req/856948#856948
        'x-request': 'xhr',
      },
    }),
  });

  return res.ok;
}

export function handle(method: any, args: any) {
  const request = createRequest({
    baseUrl: spareminConfig.services.audioProxy,
  });

  switch (method) {
    case ServiceMethod.EXTERNAL_MEDIA_EXISTS:
      return externalMediaExists(request, args);

    case ServiceMethod.PROXY_MEDIA:
      return proxyMedia(request, args);
  }

  return undefined;
}
