import { Type } from './action-types';
import { entireAudioEnhancer } from './enhancers';
import { stateFactory } from './factories';
import { AudiogramWizardAction, AudiogramWizardState } from './types';

function reducer(state: AudiogramWizardState, action: AudiogramWizardAction) {
  switch (action.type) {
    case Type.AUDIOGRAM_WIZARD_CLEAR:
      return stateFactory();

    default:
      return state;
  }
}

export default entireAudioEnhancer.createReducer(reducer, stateFactory());
