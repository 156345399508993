import { List, Map } from 'immutable';
import * as ids from 'short-id';
import { ResponseError } from 'superagent';
import _ from 'underscore';

import { fetchEmbedConfiguration } from 'redux/middleware/api/embed-configuration-service/actions';
import { fetchMyFonts } from 'redux/middleware/api/headliner-user-service';
import { fetchRecordingById } from 'redux/middleware/api/recording-service/actions';
import { Recording } from 'redux/middleware/api/recording-service/types';
import { TranscriptNotFoundError } from 'redux/middleware/api/transcript-service';
import { autosaveActionBuilder } from 'redux/middleware/autosave';
import {
  actions as authActions,
  selectors as authSelectors,
} from 'redux/modules/auth';
import * as commonActions from 'redux/modules/common/actions';
import {
  actions as displayPrefActions,
  selectors as displayPrefSelectors,
} from 'redux/modules/display-pref';
import {
  deleteEntities,
  selectors as entitySelectors,
} from 'redux/modules/entities';
import * as mixpanelActions from 'redux/modules/mixpanel';
import * as notificationActions from 'redux/modules/notification';
import { actions as oauthActions } from 'redux/modules/oauth';
import { actions as projectActions } from 'redux/modules/project';
import * as routingActions from 'redux/modules/router';
import { awaitVideoTranscription } from 'redux/modules/video-upload/actions';
import { Dispatch, ThunkAction } from 'redux/types';
import { IEmbedConfiguration, IImmutableMap } from 'types';
import { getAspectRatio } from 'utils/aspect-ratio';
import { getValue, omitUndefined } from 'utils/collections';
import embedUtils from 'utils/embed';
import { applyConfigTransforms } from 'utils/embed/config-transforms';
import { DEFAULT_TRACK_ORDER } from 'utils/embed/tracks';
import * as types from '../action-types';
import * as embedSelectors from '../selectors';
import { setupCaptions } from './captions';
import { applyCaptionsRechunkCorrection } from './captions-ts';
import { setEmbedDuration } from './embed';
import { removeSlide } from './slideshow';
import { createTrack, updateTrack } from './tracks';
import { getTranscriptByRevisionId } from './transcript';
import { removeFromVideoTrack } from './video';

const INVALID_EDITOR_SIZE_NOTIFICATION_ID = ids.generate();

const loadRecording = (
  recordingId: number,
): ThunkAction<Promise<IImmutableMap<Recording>>> => (dispatch, getState) => {
  function getRecording() {
    if (_.isUndefined(recordingId)) {
      return undefined;
    }
    const recordings = entitySelectors.recordingsSelector(getState());
    return recordings.get(recordingId.toString());
  }

  if (_.isUndefined(recordingId)) {
    return Promise.resolve();
  }

  const recording = getRecording();
  return recording
    ? Promise.resolve(recording)
    : dispatch(fetchRecordingById(recordingId)).then(getRecording);
};

const loadRecordings = (
  config: IEmbedConfiguration,
): ThunkAction<Array<Promise<IImmutableMap<Recording>>>> => dispatch => {
  const audioInfo = config?.embedConfig?.audioInfo ?? [];
  return audioInfo.map(audio => {
    const { recordingId } = audio;
    return dispatch(loadRecording(recordingId));
  });
};

const loadEditorDataByWidgetId = (
  wid: string,
): ThunkAction<Promise<any>> => dispatch => {
  // get the config for the widget id
  const futConfig = dispatch(fetchEmbedConfiguration(wid))
    .then(res => res.response.configuration)
    .catch(err => {
      if (err.status === 404) {
        throw new Error('Editor data not found');
      }
      throw err;
    });

  const futRecording = futConfig.then(config =>
    Promise.all(dispatch(loadRecordings(config))),
  );

  const futKeywords = Promise.all([futConfig, futRecording]).then(
    ([config, recordings]) => {
      const captionsMediaSource = embedUtils.getCaptionsMediaSource(config);
      if (!captionsMediaSource) return undefined;

      const { id, type } = captionsMediaSource;
      if (type === 'video') return undefined;

      const catchHandler = err => {
        if (err.status !== 404) throw err;
      };

      if (type === 'text') {
        return dispatch(commonActions.analyzeText(id, undefined, false)).catch(
          catchHandler,
        );
      }

      const recording = recordings.find(r => r.get('recordingId') === id);
      if (type === 'audio' && recording) {
        const versionId = recording.get('versionId');
        return dispatch(
          commonActions.getKeywordsForRecordingVersion(versionId, false),
        ).catch(catchHandler);
      }

      return undefined;
    },
  );

  const futEmbedData = Promise.all([
    futConfig,
    futRecording,
  ]).then(([{ embedConfig }]) =>
    embedUtils.formatStateFromEmbedConfig(applyConfigTransforms(embedConfig)),
  );

  return Promise.all([futEmbedData, futKeywords]).then(([data]) => data);
};

const loadEditorData = (wid: string): ThunkAction<Promise<any>> => (
  dispatch,
  getState,
) => {
  /*
   * no wid is blank project.  the empty object helps with handling this promise just like the ones
   * returned from the recording id and widget id function
   */
  const result = wid
    ? dispatch(loadEditorDataByWidgetId(wid))
    : Promise.resolve(
        omitUndefined({
          watermarkById: embedUtils.formatWatermarkState(
            displayPrefSelectors.defaultLogoUrlSelector(getState()),
            embedSelectors.watermarkConfigurationSelector(getState()),
          ),
        }),
      );
  result.then(res =>
    dispatch({
      payload: res,
      type: types.EMBED_EDITOR_DATA_LOAD,
    }),
  );

  return result;
};

const loadProjectByIdAndGetWid = (
  pid: string,
): ThunkAction<Promise<string>> => (dispatch, getState) =>
  dispatch(projectActions.getProjectById(pid)).then(({ payload }) => {
    const { project } = payload;
    const userId = authSelectors.userIdSelector(getState());
    const widgetId = project.projectConfig.embedConfigurationUuid;

    if (project.projectConfig.ownerUserId === userId) {
      dispatch(projectActions.setProject(project.projectUuid));
    }

    return widgetId;
  });

/**
 * @returns {Promise} Promise which can resolve to the following values:
 *    - widget id needed to load the editor for this project
 *    - undefined if a new project is created and we don't yet have a widget id
 *    - false if a redirect occurred
 */
const loadProject = (
  wid: string,
  pid: string,
  aspectRatioName: string,
  templateId?: string,
): ThunkAction<Promise<undefined | string>> => dispatch => {
  /*
   * if no `wid` and no `pid`, then assume user is creating a new project.  create a blank project
   * and resolve the promise to undefined since we don't yet have a widget id
   */
  if (!wid && !pid && aspectRatioName) {
    const aspectRatio = getAspectRatio(aspectRatioName);
    const ratio = aspectRatio.toJS();
    return dispatch(
      projectActions.createProject(undefined, ratio, undefined, 'blankProject'),
    ).then(() => undefined);
  }

  /*
   * if we have a `pid`, then load the project.
   * - if the project is owned by the authenticated user, set the active project id in redux state
   *   and resolve the promise to the widget id needed to load the editor data
   * - if the project is not owned by the authenticated user, redirect to the "/edit?wid=..." url.
   *   resolve the promise to `false`
   */
  if (pid) {
    return dispatch(loadProjectByIdAndGetWid(pid));
  }

  if (templateId) {
    return dispatch(
      projectActions.createProjectFromTemplate(templateId),
    ).then(projectId => dispatch(loadProjectByIdAndGetWid(projectId)));
  }

  /*
   * no project, but we have a `wid` (when being loaded from the "/edit?wid=..." url). resolve the
   * promise to the wid passed in
   */
  return Promise.resolve(wid);
};

function loadEditorErrorMessage(err: ResponseError & { code?: string }) {
  const { code } = err;
  if (!err)
    return {
      message: '',
      code,
    };

  if (err.status === 403) {
    return {
      message: 'You do not have permission to open to this project',
      code,
    };
  }

  if (err.status === 404) {
    return { message: 'Project not found', code: 'IN011' };
  }

  return { message: err.message || 'Error loading video editor', code };
}

const getAndProcessTranscript = (): ThunkAction<Promise<any>> => (
  dispatch,
  getState,
) => {
  const errorHandler = err => {
    if (!(err instanceof TranscriptNotFoundError)) {
      throw err;
    }
  };

  // first check to see if caption source has a transcript id.  if so, fetch it and we're done
  const source = embedSelectors.captionsSourceSelector(getState());
  const transcriptId = source.get('transcriptId');
  const revisionId = source.get('transcriptRevisionId');
  if (transcriptId && revisionId) {
    return dispatch(getTranscriptByRevisionId(transcriptId, revisionId)).catch(
      errorHandler,
    );
  }

  // no captions source.  if we're waiting on a transcript we should already have a media source
  const mediaSource = embedSelectors.captionsMediaSourceSelector(getState());
  const mediaSourceId = getValue(mediaSource, 'mediaSourceId');
  const mediaSourceType = getValue(mediaSource, 'mediaSourceType');
  if (!mediaSourceId || !mediaSourceType) {
    // no media source, no captions.  move on
    return Promise.resolve();
  }

  // transcript that is not yet resolved
  if (mediaSourceType === 'audio') {
    const recordings = entitySelectors.recordingsSelector(getState());
    const versionId = recordings.getIn([mediaSourceId.toString(), 'versionId']);
    return dispatch(commonActions.pollForTranscript(versionId))
      .then(() => dispatch(setupCaptions(mediaSourceId, 'audio')))
      .catch(errorHandler);
  }
  return dispatch(awaitVideoTranscription(mediaSourceId))
    .then(() => dispatch(setupCaptions(mediaSourceId, 'video')))
    .catch(errorHandler);
};

const pollForAsyncEditorData = (): ThunkAction<Promise<[any, any]>> => (
  dispatch,
  getState,
) => {
  const audioId = embedSelectors.embedMainAudioSelector(getState())?.get('id');

  const futWaveform = !audioId
    ? Promise.resolve()
    : dispatch(commonActions.getRecordingWaveform(audioId));

  const futTranscript = dispatch(getAndProcessTranscript());

  return Promise.all([futWaveform, futTranscript]);
};

export const loadEditor = (
  wid?: string,
  pid?: string,
  ar?: string,
  tid?: string,
  fromWizard?: boolean,
): ThunkAction<void | Promise<any>> => dispatch => {
  // check and renew the auth token whenever the editor is loaded
  dispatch(authActions.checkAndRenewToken());

  if (!wid && !pid && !ar && !tid) {
    dispatch(routingActions.goToCreate());
    return;
  }

  // clears out all existing embed state
  dispatch({ type: types.EMBED_EDITOR_LOAD_REQUEST });
  dispatch(deleteEntities(['revisionHistory']));

  dispatch(loadProject(wid, pid, ar, tid))
    .then(projectWid =>
      dispatch(displayPrefActions.getMyDisplayPref()).then(() =>
        dispatch(loadEditorData(projectWid)),
      ),
    )
    .then(({ tracks, trackOrder = DEFAULT_TRACK_ORDER }) => {
      dispatch(
        routingActions.updateEditorUrl(undefined, false, params =>
          _.omit(params, 'wid', 'pid', 'ar', 'tid'),
        ),
      );
      dispatch(oauthActions.getThirdPartyTokens());
      dispatch(fetchMyFonts());
      dispatch(commonActions.getMyPreferences());

      [...trackOrder].reverse().forEach((trackType, index) => {
        const {
          payload: { id },
        } = dispatch(
          createTrack(trackType, {
            layerOrderType: 'free',
            name:
              trackType !== 'audio'
                ? undefined
                : index === 0
                ? 'background audio'
                : 'audio',
          }),
        );
        // trackOrder is reversed but tracks isn't.  use the mirror index to
        // get the right data for the track
        const data = getValue(tracks, [trackOrder.size - index - 1], List());
        dispatch(updateTrack(id, () => data));
      });

      dispatch(setEmbedDuration());
      dispatch(pollForAsyncEditorData());
      dispatch(projectActions.getRevisionHistoryByProjectId());
    })
    .then(() => dispatch(applyCaptionsRechunkCorrection()))
    .then(() => {
      dispatch({ type: types.EMBED_EDITOR_LOAD_SUCCESS });

      if (fromWizard) {
        dispatch(mixpanelActions.onEditorLoaded());
      }
    })
    .catch(error => {
      dispatch(notificationActions.showError(loadEditorErrorMessage(error)));
      dispatch({
        ...error,
        type: types.EMBED_EDITOR_LOAD_FAILURE,
      });
      dispatch(routingActions.goToCreate());
    });
};

const autosavePauseAction = autosaveActionBuilder()
  .flushSave()
  .pause()
  .build();
const autosaveResumeAction = autosaveActionBuilder()
  .resume()
  .build();

export const pauseAutosave = () => dispatch => dispatch(autosavePauseAction);

export const resumeAutosave = () => dispatch => dispatch(autosaveResumeAction);

export const setEmbedPlayerStatus = status => dispatch =>
  dispatch({
    payload: {
      status: status.name,
    },
    type: types.EMBED_PLAYER_STATUS_SET,
  });

export const showInvalidEditorSizeNotification = () => dispatch => {
  const warningNotificationConfig = {
    id: INVALID_EDITOR_SIZE_NOTIFICATION_ID,
    message:
      'Your browser is too small to use Headliner. Please maximize your browser.',
    title: 'Browser too small',
  };

  // no idea how typescript is inferring arguments for showWarning.  it's written in js and has no
  // type declaration anywhere...
  dispatch(notificationActions.showWarning(warningNotificationConfig as any));
};

export const clearInvalidEditorSizeNotification = () => dispatch =>
  dispatch(
    notificationActions.clearNotification(INVALID_EDITOR_SIZE_NOTIFICATION_ID),
  );

export const removeReplacedTrackElement = (
  id?: string,
): ThunkAction<void | Promise<any>> => (dispatch, getState) => {
  if (!id) return undefined;

  const slides = embedSelectors.slidesSelector(getState()) || Map();
  const maybeSlide = slides.get(id);
  if (maybeSlide) return dispatch(removeSlide(id));

  const maybeVideo = embedSelectors.videosByIdSelector(getState()).get(id);
  if (maybeVideo) return dispatch(removeFromVideoTrack(id));

  return undefined;
};

export const setEditorReady = () => (dispatch: Dispatch) =>
  dispatch({
    payload: { isReady: true },
    type: types.EMBED_EDITOR_READY_SET,
  });

export default {
  loadEditor,
  pauseAutosave,
  resumeAutosave,
};
