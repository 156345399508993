import { useMemo } from 'react';

export default function useCost(cents: number) {
  const costStringUsd = useMemo(
    () =>
      cents === undefined
        ? undefined
        : `$${(Math.floor(cents) / 100).toFixed(2)}`,
    [cents],
  );

  return costStringUsd;
}
