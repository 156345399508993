import * as React from 'react';
import { IconProps } from './types';

export const Square: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 4" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 0h2c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1z"
      opacity=".5"
    />
  </svg>
);

export default Square;
