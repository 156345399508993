import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A = undefined>(
  method: M,
  args?: A,
) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export function createEddyProject(
  name: string,
  associatedMedia: types.EddyProjectAssociatedMedia,
): types.CreateEddyProjectAction {
  return apiCallAction(types.ServiceMethod.CREATE_EDDY_PROJECT, [
    name,
    associatedMedia,
  ]);
}
