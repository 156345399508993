import * as React from 'react';
import { connect } from 'react-redux';

import { AudioLibraryProps } from 'components/AudioLibrary';
import { IContainerProps } from 'containers/SampleAudioLibrary/SampleAudioLibrary';
import { fetchSampleAudios } from 'redux/middleware/api/headliner-user-service';
import { sampleAudiosSelector } from 'redux/modules/entities';
import { showError } from 'redux/modules/notification';
import {
  libraryIsFetchingSelector,
  selectedAudioSelector,
} from 'redux/modules/sample-audio';
import { downloadSampleAudio } from 'redux/modules/sample-audio/actions';
import { Dispatch, State } from 'redux/types';
import { getValue } from 'utils/collections';

type StateProps = Pick<
  AudioLibraryProps,
  'audios' | 'selectedIsFetching' | 'libraryIsFetching'
>;
type DispatchProps = Pick<IContainerProps, 'onSelect' | 'onLoad'>;
interface IOwnProps {
  onSelect?: IContainerProps['onSelect'];
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onLoad: () =>
    dispatch(fetchSampleAudios()).catch(() =>
      dispatch(
        showError(
          'Error occurred when downloading audio library. ' +
            'Please try again later.',
          15,
        ),
      ),
    ),
  onSelect: audio => {
    if (audio !== 'none') {
      dispatch(
        downloadSampleAudio(
          audio.get('audioUrl'),
          audio.get('id'),
          audio.get('title'),
        ),
      );
    }
  },
});

const mapStateToProps = (state: State): StateProps => {
  const audios = sampleAudiosSelector(state);
  return {
    /*
     * FIXME: type defines audio as a record but it is actually a map.  accessing properties with
     * dot notation will result in undefined
     */
    audios: audios && audios.toArray(),
    libraryIsFetching: libraryIsFetchingSelector(state),
    selectedIsFetching: selectedAudioSelector(state).isFetching,
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: IOwnProps,
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSelect: getValue(ownProps, 'onSelect', dispatchProps.onSelect),
});

export default function(component: React.ComponentType<IContainerProps>) {
  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(component);
}
