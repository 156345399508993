import { Record, RecordOf } from 'immutable';

import { Action } from 'redux/types';
import { PostStatus } from '../constants';
import { Type } from './action-types';

export interface ThreadsPostMessageData {
  tokenInfo: {
    accessToken: string;
    idToken: string;
    provider: 'threads';
    providerUserId: string;
  };
}

export type ThreadsAuthData = Pick<
  ThreadsPostMessageData['tokenInfo'],
  'accessToken' | 'idToken' | 'providerUserId'
>;

interface State extends ThreadsAuthData {
  threadsUserId: string;
  username: string;
  profileImageUrl: string;
  accessToken: string;
  postStatus: PostStatus;
  socialShareId: number;
}

export type ThreadsState = RecordOf<State>;

export const threadsStateFactory = Record<State>({
  threadsUserId: undefined,
  username: undefined,
  profileImageUrl: undefined,
  accessToken: undefined,
  postStatus: undefined,
  socialShareId: undefined,
  idToken: undefined,
  providerUserId: undefined,
});

export type ThreadsAuthorizeSuccessAction = Action<
  Type.THREADS_AUTHORIZE_SUCCESS,
  ThreadsAuthData
>;

type GetThreadsUserRequestAction = Action<Type.THREADS_USER_DATA_GET_REQUEST>;

type GetThreadsUserSuccessAction = Action<
  Type.THREADS_USER_DATA_GET_SUCCESS,
  {
    id: string;
    username: string;
    profileImageUrl: string;
  }
>;

type GetThreadsUserFailureAction = Action<Type.THREADS_USER_DATA_GET_FAILURE>;

type SelectThreadsUserIdAction = Action<Type.THREADS_USER_ID_SELECT>;

type ClearThreadsUserAction = Action<Type.THREADS_USER_CLEAR>;

type PostVideoRequestAction = Action<Type.THREADS_VIDEO_POST_REQUEST>;

type PostVideoFailureAction = Action<Type.THREADS_VIDEO_POST_FAILURE>;

type PostVideoSuccessAction = Action<
  Type.THREADS_VIDEO_POST_SUCCESS,
  {
    socialShareId: number;
  }
>;

type AwaitPostRequestAction = Action<Type.THREADS_VIDEO_POST_AWAIT_REQUEST>;

type AwaitPostSuccessAction = Action<Type.THREADS_VIDEO_POST_AWAIT_SUCCESS>;

type AwaitPostFailureAction = Action<Type.THREADS_VIDEO_POST_AWAIT_FAILURE>;

export type ThreadsAction =
  | GetThreadsUserRequestAction
  | GetThreadsUserSuccessAction
  | GetThreadsUserFailureAction
  | ClearThreadsUserAction
  | SelectThreadsUserIdAction
  | PostVideoRequestAction
  | PostVideoSuccessAction
  | PostVideoFailureAction
  | ThreadsAuthorizeSuccessAction
  | AwaitPostRequestAction
  | AwaitPostSuccessAction
  | AwaitPostFailureAction;
