import React from 'react';
import { useSelector } from 'react-redux';
import { Step } from 'components/Wizard';
import { creationConfigurationIdSelector } from 'redux/modules/wizard-export/selectors';
import { WizardExportVideoStep } from '../ExportVideoStep';
import { TemplateWizardStep } from './types';

interface UseExportingStepConfig {
  durationMillis: number;
  onError: (error: Error) => void;
}

interface UseExportingStepResult {
  step: Step<TemplateWizardStep.EXPORTING>;
}

export default function useExportingStep({
  durationMillis,
  onError,
}: UseExportingStepConfig): UseExportingStepResult {
  const widgetId = useSelector(creationConfigurationIdSelector);
  return {
    step: {
      component: (
        <WizardExportVideoStep
          estimatedDurationSec={Math.max(
            Math.floor(durationMillis / 1000) * 2,
            60,
          )}
          widgetId={widgetId}
          onError={onError}
        />
      ),
      name: 'Creating Project',
      renderCancelButton: () => null,
      renderNextButton: () => null,
      showInNav: false,
      stepId: TemplateWizardStep.EXPORTING,
    },
  };
}
