import * as React from 'react';
import _ from 'underscore';

import { ArgsOf, ComponentOrFunction } from 'types';

type CloneableElement = ArgsOf<typeof React.cloneElement>[0];

export function isComponent<RenderProp extends {}>(
  obj: ComponentOrFunction<RenderProp>,
): obj is React.ComponentClass<RenderProp> {
  // eslint-disable-next-line react/forbid-foreign-prop-types
  return !_.isUndefined(obj.propTypes) || !_.isUndefined(obj.prototype.render);
}

function isFunctionComponent(
  component: React.ElementType,
): component is React.FunctionComponent {
  return typeof component === 'function';
}

export function callRender<P = any>(
  Component: React.ElementType<any>,
  props: P,
): JSX.Element {
  if (isFunctionComponent(Component)) {
    return Component(props);
  }

  return <Component {...props} />;
}

export function getDisplayName(component: React.ComponentType) {
  return component.displayName || component.name || 'Component';
}

export function withOnlyChild(
  children: React.ReactNode,
  fn: (child: JSX.Element) => JSX.Element,
) {
  if (!children) return null;
  const child = React.Children.only(children) as CloneableElement;
  return fn(child);
}

export function cloneOnlyChild(
  children: React.ReactNode,
  createProps: (child: JSX.Element) => { [k: string]: any },
) {
  return withOnlyChild(children, child =>
    React.cloneElement(child, createProps(child)),
  );
}
