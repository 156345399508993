import * as React from 'react';
import { useDispatch } from 'react-redux';

import Heading from 'components/Heading';
import { CircularGradientEnvelope } from 'components/icons/circular-gradient';
import Modal from 'components/Modal';
import { hideModal } from 'redux/modules/modal';
import { block } from './utils';

const EmailVerificationSuccessModalContent: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <>
      <Modal.Body className={block('body')}>
        <div className={block('icon')}>
          <CircularGradientEnvelope />
        </div>
        <Heading>You’re all set!</Heading>
        <p>Thanks for verifying your email.</p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButton
          fluid
          theme="submit"
          onClick={() => {
            dispatch(hideModal());
          }}
        >
          Let's make some videos
        </Modal.FooterButton>
      </Modal.Footer>
    </>
  );
};

export default EmailVerificationSuccessModalContent;
