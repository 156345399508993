import * as audio from './audio';
import * as autogram from './autogram';
import * as captions from './captions';
import * as embed from './embed';
import * as mediaContainer from './media-container';
import * as progress from './progress';
import * as slideshow from './slideshow';
import * as soundwave from './soundwave';
import * as textOverlay from './text-overlay';
import * as timer from './timer';
import * as ui from './ui';
import * as video from './video';
import * as watermark from './watermark';

export default {
  ...audio,
  ...embed,
  ...slideshow,
  ...textOverlay,
  ...ui,
  ...soundwave,
  ...watermark,
  ...captions,
  ...video,
  ...mediaContainer,
  ...progress,
  ...timer,
  ...autogram,
};
