import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { pushModal } from 'redux/modules/modal';
import { AutogramVideoType } from 'types';
import { VideoTemplateStateContent } from './types';
import {
  checkIsValidFullEpisodeTemplate,
  checkShouldValidateTemplate,
} from './utils';

interface UseTemplateValidationCheck {
  handleCheckIsValidTemplate: (
    state: VideoTemplateStateContent,
    videoType?: AutogramVideoType,
  ) => boolean;
}

export default function useTemplateValidationCheck(): UseTemplateValidationCheck {
  const dispatch = useDispatch();
  const handleCheckIsValidTemplate = useCallback(
    (
      state: VideoTemplateStateContent,
      videoType?: AutogramVideoType,
    ): boolean => {
      const shouldValidate = checkShouldValidateTemplate(videoType);

      if (shouldValidate && !checkIsValidFullEpisodeTemplate(state)) {
        dispatch(pushModal({ name: 'AdjustmentNeeded' }));

        return false;
      }

      return true;
    },
    [dispatch],
  );

  return { handleCheckIsValidTemplate };
}
