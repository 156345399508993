import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { has } from 'underscore';
import {
  FacebookPostType,
  PodcastSubscription,
  SubscriptionItem,
} from 'redux/middleware/api/podcast-service';
import { OutputOption } from 'redux/modules/edit-automation';
import { pushModal, replaceModal } from 'redux/modules/modal';
import { AutopostPreference, AutopostPreferenceModalOutput } from '../types';
import { mapAutopostPreference } from './utils';

export interface UseHandleOutputSelectionResults {
  handleOutputSelection: (
    option: OutputOption,
    shouldOpenEditAutomationOutputModal: boolean,
  ) => Promise<AutopostPreference>;
}

export default function useHandleOutputSelection(
  subscriptionId: number,
  subscriptionItem: SubscriptionItem,
  subscription: PodcastSubscription,
): UseHandleOutputSelectionResults {
  const dispatch = useDispatch();

  const handleOpenEditAutomationOutputModal = useCallback((): void => {
    dispatch(
      pushModal({
        name: 'EditAutomationOutputModal',
        params: {
          subscriptionId,
          videoType: subscriptionItem?.videoType,
          platform: subscriptionItem?.autoPostVideoPreference?.platform,
        },
      }),
    );
  }, [dispatch, subscriptionId, subscriptionItem]);

  const handleYouTubeOutputSelection = useCallback(
    async (
      shouldOpenEditAutomationOutputModal: boolean,
    ): Promise<AutopostPreference> => {
      const autopostData = ((await dispatch(
        replaceModal({
          name: 'YoutubeAutoPost',
          params: {
            ...(subscriptionItem && { subscriptionItem }),
            defaultPlaylistName: subscription?.title,
            defaultPodcastId: subscription?.podcastId,
            source: 'Autogram',
          },
        }),
      )) as unknown) as AutopostPreferenceModalOutput | undefined;

      if (shouldOpenEditAutomationOutputModal) {
        handleOpenEditAutomationOutputModal();
      }

      if (!autopostData) {
        return undefined;
      }

      return mapAutopostPreference(autopostData, 'youtube');
    },
    [
      dispatch,
      handleOpenEditAutomationOutputModal,
      subscription,
      subscriptionItem,
    ],
  );

  const handleTikTokOutputSelection = useCallback(
    async (
      shouldOpenEditAutomationOutputModal: boolean,
    ): Promise<AutopostPreference> => {
      const autopostData = ((await dispatch(
        replaceModal({
          name: 'TikTokAutoPost',
          params: {
            ...(subscriptionItem && { subscriptionItem }),
          },
        }),
      )) as unknown) as AutopostPreferenceModalOutput | undefined;

      if (shouldOpenEditAutomationOutputModal) {
        handleOpenEditAutomationOutputModal();
      }

      if (!autopostData) {
        return undefined;
      }

      return mapAutopostPreference(autopostData, 'tiktok');
    },
    [dispatch, handleOpenEditAutomationOutputModal, subscriptionItem],
  );

  const handleLinkedInOutputSelection = useCallback(
    async (
      shouldOpenEditAutomationOutputModal: boolean,
    ): Promise<AutopostPreference> => {
      const autopostData = ((await dispatch(
        replaceModal({
          name: 'LinkedInAutoPost',
          params: {
            ...(subscriptionItem && { subscriptionItem }),
          },
        }),
      )) as unknown) as AutopostPreferenceModalOutput | undefined;

      if (shouldOpenEditAutomationOutputModal) {
        handleOpenEditAutomationOutputModal();
      }

      if (!autopostData) {
        return undefined;
      }

      return mapAutopostPreference(autopostData, 'linkedin');
    },
    [dispatch, handleOpenEditAutomationOutputModal, subscriptionItem],
  );

  const handleFacebookOutputSelection = useCallback(
    async (
      shouldOpenEditAutomationOutputModal: boolean,
    ): Promise<AutopostPreference> => {
      const {
        postType,
        facebookPageId,
        facebookId,
      } = subscriptionItem?.autoPostVideoPreference.options;

      const autopostData = ((await dispatch(
        replaceModal({
          name: 'FacebookAutoPost',
          params: {
            ...(subscriptionItem && {
              subscriptionItem,
              postType: postType as FacebookPostType,
            }),
          },
        }),
      )) as unknown) as AutopostPreferenceModalOutput | undefined;

      if (shouldOpenEditAutomationOutputModal) {
        handleOpenEditAutomationOutputModal();
      }

      if (!autopostData) {
        return undefined;
      }

      return mapAutopostPreference(
        {
          ...autopostData,
          facebookPageId,
          facebookId,
        },
        'facebook',
      );
    },
    [dispatch, handleOpenEditAutomationOutputModal, subscriptionItem],
  );

  const handleInstagramOutputSelection = useCallback(
    async (
      shouldOpenEditAutomationOutputModal: boolean,
    ): Promise<AutopostPreference> => {
      const autopostData = ((await dispatch(
        replaceModal({
          name: 'InstagramAutoPost',
          params: {
            ...(subscriptionItem && {
              subscriptionItem,
              mediaType:
                subscriptionItem?.autoPostVideoPreference.options
                  .instagramMediaType,
            }),
          },
        }),
      )) as unknown) as AutopostPreferenceModalOutput | undefined;

      if (shouldOpenEditAutomationOutputModal) {
        handleOpenEditAutomationOutputModal();
      }

      if (!autopostData) {
        return undefined;
      }

      return mapAutopostPreference(autopostData, 'instagram');
    },
    [dispatch, handleOpenEditAutomationOutputModal, subscriptionItem],
  );

  const handleTwitterOutputSelection = useCallback(
    async (
      shouldOpenEditAutomationOutputModal: boolean,
    ): Promise<AutopostPreference> => {
      const autopostData = ((await dispatch(
        replaceModal({
          name: 'TwitterAutoPost',
          params: {
            ...(subscriptionItem && { subscriptionItem }),
          },
        }),
      )) as unknown) as AutopostPreferenceModalOutput | undefined;

      if (shouldOpenEditAutomationOutputModal) {
        handleOpenEditAutomationOutputModal();
      }

      if (!autopostData) {
        return undefined;
      }

      return mapAutopostPreference(autopostData, 'twitter');
    },
    [dispatch, handleOpenEditAutomationOutputModal, subscriptionItem],
  );

  const handleThreadsOutputSelection = useCallback(
    async (
      shouldOpenEditAutomationOutputModal: boolean,
    ): Promise<AutopostPreference> => {
      const autopostData = ((await dispatch(
        replaceModal({
          name: 'ThreadsAutoPost',
          params: {
            ...(subscriptionItem && { subscriptionItem }),
          },
        }),
      )) as unknown) as AutopostPreferenceModalOutput | undefined;

      if (shouldOpenEditAutomationOutputModal) {
        handleOpenEditAutomationOutputModal();
      }

      if (!autopostData) {
        return undefined;
      }

      return mapAutopostPreference(autopostData, 'threads');
    },
    [dispatch, handleOpenEditAutomationOutputModal, subscriptionItem],
  );

  const outputByOption = useMemo(() => {
    return {
      YOUTUBE: handleYouTubeOutputSelection,
      TIKTOK: handleTikTokOutputSelection,
      LINKEDIN: handleLinkedInOutputSelection,
      FACEBOOK: handleFacebookOutputSelection,
      INSTAGRAM: handleInstagramOutputSelection,
      TWITTER: handleTwitterOutputSelection,
      THREADS: handleThreadsOutputSelection,
    };
  }, [
    handleFacebookOutputSelection,
    handleInstagramOutputSelection,
    handleLinkedInOutputSelection,
    handleThreadsOutputSelection,
    handleTikTokOutputSelection,
    handleTwitterOutputSelection,
    handleYouTubeOutputSelection,
  ]);

  const handleOutputSelection = useCallback(
    async (
      option: OutputOption,
      shouldOpenEditAutomationOutputModal: boolean,
    ): Promise<AutopostPreference | undefined> => {
      if (!has(outputByOption, option)) {
        return undefined;
      }

      const autopostPreference = await outputByOption?.[option]?.(
        shouldOpenEditAutomationOutputModal,
      );

      return autopostPreference;
    },
    [outputByOption],
  );

  return {
    handleOutputSelection,
  };
}
