import cn from 'classnames';
import * as React from 'react';

import { getWaveFormByType } from 'blocks/AudioWaveImage/utils';
import { Omit, SoundwaveType } from 'types';
import { camelToPascal } from 'utils/string';
import NoSelectionTile from '../NoSelectionTile';
import SelectTile, { SelectTileProps } from '../SelectTile';
import { waveformViewBlock as block } from '../utils';

export interface CreateWaveTypeTileArgs {
  type: SoundwaveType | 'none';
  style?: React.CSSProperties;
}

type ComponentProps = Omit<SelectTileProps, 'children'>;
export type WaveTypeTile = React.FC<ComponentProps>;

/*
 * Rather than add a whole set of new svgs for the waveform tiles, use the existing
 * waveform svgs we have.  the tile is created by setting { overflow: hidden }
 * and then adjusting scaling, positioning, etc. of the waveform svg.
 *
 * Each waveform svg will have to be adjusted in slightly different ways.  this
 * function is just a convenience for creating the waveeform tiles
 */
export default function createWaveTypeTile({
  type,
  style,
}: CreateWaveTypeTileArgs): WaveTypeTile {
  const tileClassName = block('wave-type-tile');

  const Component: React.FC<ComponentProps> =
    type === 'none'
      ? ({ className, onClick, selected }) => (
          <NoSelectionTile
            className={cn(tileClassName, className)}
            onClick={onClick}
            params={type}
            selected={selected}
          />
        )
      : ({ as, className, onClick, selected }) => {
          const Waveform = getWaveFormByType(type);
          return (
            <SelectTile
              className={cn(tileClassName, className)}
              params={type}
              {...{ as, onClick, selected }}
            >
              <Waveform
                style={{
                  ...style,
                  fill: '#19b5fe',
                  height: 'auto',
                  width: '100%',
                }}
              />
            </SelectTile>
          );
        };

  Component.displayName = `${camelToPascal(type)}WaveformTypeTile`;

  return Component;
}
