import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import usePromise from 'hooks/usePromise';
import { loadWorkflowTemplates } from 'redux/modules/automation-cms';
import { showNotification } from 'redux/modules/notification';
import { goToCreate } from 'redux/modules/router';
import { Dispatch } from 'redux/types';
import { AspectRatioName } from 'types';

interface UseLoadTemplateList {
  loadTemplateList: (aspectRatioName: AspectRatioName) => void;
}

const useLoadTemplateList = (): UseLoadTemplateList => {
  const dispatch = useDispatch<Dispatch>();

  const { setPromise } = usePromise<void>();

  const loadTemplateList = useCallback(
    (aspectRatioName: AspectRatioName) => {
      setPromise(
        dispatch(loadWorkflowTemplates(aspectRatioName)).catch(() => {
          dispatch(
            showNotification({
              message: 'Please try again or {{link}} so we can help',
              code: 'ER013',
              dismissAfterSec: 5,
              level: 'error',
              type: 'help',
              title: 'Error loading templates',
              actionLabel: 'contact us',
            }),
          );

          dispatch(goToCreate());
        }),
      );
    },
    [dispatch, setPromise],
  );

  return {
    loadTemplateList,
  };
};

export default useLoadTemplateList;
