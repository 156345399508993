import { RecordOf } from 'immutable';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { podcastEpisodeDataSelector } from 'redux/modules/automation-cms/selectors';
import { PodcastEpisodeData } from 'redux/modules/automation-cms/types';

const makeEpisodeDataSelector = () =>
  createSelector(
    podcastEpisodeDataSelector,
    (_, episodeId) => episodeId,
    (episodeData, episodeId) =>
      (episodeData?.get(episodeId) as RecordOf<PodcastEpisodeData>)?.toJS(),
  );

export default function useEpisodeProjects(episodeId: string) {
  const episodeDataSelector = useMemo(makeEpisodeDataSelector, []);
  const episodeData = useSelector(state =>
    episodeDataSelector(state, episodeId),
  );

  return {
    isLoading: episodeData?.isFetching,
    projects: episodeData?.projects,
  };
}
