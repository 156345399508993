import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useProIndicator } from 'containers/ProIndicator';
import { onClickProFeature } from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal';
import { showError } from 'redux/modules/notification/actions';
import { ApplicationError } from 'utils/ApplicationError';
import { useNavigation } from './context/NavigationContext';
import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import { useModalManager } from './ModalManager';
import { hasWatermark } from './state';
import { MediaIntegrationId } from './types';
import useImageSource, { OnImageProcessed } from './useImageSource';

// Since it is possible to have only one watermark at the time, this
// hook can be used for both adding and replacing watermarks.
const useAddWatermark = () => {
  const { hideModal, showModal } = useModalManager();
  const { isFree } = useProIndicator();
  const dispatch = useDispatch();
  const templateDispatch = useTemplateDispatch();
  const state = useTemplateState();
  const hasWatermarkAdded = hasWatermark(state);

  const [, send] = useNavigation();

  const handleError = useCallback(
    (file, reason) => {
      templateDispatch({
        error: true,
        meta: { file },
        payload: reason,
        type: 'MEDIA_ADD_ERROR',
      });
    },
    [templateDispatch],
  );

  const handleFinalWatermarkProcessed = useCallback(() => {
    send({
      type: 'CHILD_VIEW_OPEN',
      payload: 'watermark',
      meta: { source: 'options' },
    });
  }, [send]);

  const handleWatermarkProcessed: OnImageProcessed = useCallback(
    payload => {
      // Since this hook is used for both adding and replacing,
      // this is used to 'reset' the crop state once the
      // watermark is replaced.
      if (hasWatermarkAdded)
        templateDispatch({
          payload: { src: payload.original, metadata: undefined },
          type: 'WATERMARK_CROP',
        });

      templateDispatch({
        payload: {
          ...payload,
        },
        type: 'WATERMARK_ADD',
      });

      handleFinalWatermarkProcessed();
    },
    [handleFinalWatermarkProcessed, hasWatermarkAdded, templateDispatch],
  );

  const processWatermark = useImageSource({
    onError: handleError,
    onImageProcessed: handleWatermarkProcessed,
  });

  const handleUpsell = useCallback((): void => {
    dispatch(
      onClickProFeature({
        from: 'AddWatermarkInUCS',
      }),
    );

    dispatch(
      pushModal({
        name: 'WatermarkUpsell',
        params: {
          unlockThisFeature: 'AddWatermarkInUCS',
        },
      }),
    );
  }, [dispatch]);

  const handleAddIntegrationWatermark = useCallback(
    (file: File): void => {
      hideModal();

      processWatermark(file, { id: MediaIntegrationId.UPLOAD });
    },
    [hideModal, processWatermark],
  );

  const handleFileRejected = useCallback(
    (error: ApplicationError): void => {
      dispatch(
        showError({
          message: error.message,
          code: error.code,
          dismissAfterSec: 5,
        }),
      );
    },
    [dispatch],
  );

  const handleAddStaticWatermark = useCallback((): void => {
    showModal('add-watermark', {
      onFileAccepted: handleAddIntegrationWatermark,
      onFileRejected: handleFileRejected,
    });
  }, [handleAddIntegrationWatermark, handleFileRejected, showModal]);

  return useCallback((): void => {
    if (isFree) return handleUpsell();

    return handleAddStaticWatermark();
  }, [handleAddStaticWatermark, handleUpsell, isFree]);
};

export default useAddWatermark;
