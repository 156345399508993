import { createSelector } from 'reselect';
import { State } from 'redux/types';
import { AppsConnectedState } from './types';

const stateSelector = (state: State): AppsConnectedState =>
  state.get('appsConnected');

export const appsConnectedStatusSelector = createSelector(
  stateSelector,
  state => state.appsStatus.toJS(),
);

export const appsConnectedDeleteStatusSelector = createSelector(
  stateSelector,
  state => state.deleteStatus,
);
