import * as React from 'react';
import { noop } from 'underscore';

import { TRACK_IN_ANIMATION_DURATION_MILLIS } from '../../constants';
import TimelineContext from '../../TimelineContext';
import { TrackLabelsProps } from '../../types';
import { block } from '../../utils';
import TrackControls from './TrackControls';
import TrackLabel from './TrackLabel';
import TrackLabelDragLayer from './TrackLabelDragLayer';
import TrackLabelDropIndicator from './TrackLabelDropIndicator';
import useMoveTrackLabel from './useMoveTrackLabel';

const { useContext } = React;

const TrackLabels: React.SFC<TrackLabelsProps> = ({
  onDeleteButtonClick,
  onLabelEntered,
  onStyleButtonClick,
  onTrackMove,
}) => {
  const { tracks } = useContext(TimelineContext);
  const {
    dragItem,
    moveTrackState,
    onDragStart,
    onDragStop,
    onTrackDrop,
    onTrackMoveIntent,
  } = useMoveTrackLabel({
    tracks,
    onTrackMove,
  });

  return (
    <>
      <TrackLabelDragLayer />
      <div className={block('track-labels')}>
        {[...tracks, undefined].map((track, index) => {
          const id = track?.id || 'last';
          return (
            <React.Fragment key={id}>
              {dragItem && (
                <TrackLabelDropIndicator
                  active={index === moveTrackState.insertBeforeIndex}
                  dragItem={dragItem}
                  key={`${id}-drop-indicator`}
                  nextTrackIndex={index}
                  style={{ transform: `translateY(${index * 60}px)` }}
                />
              )}
              {track && (
                <TrackLabel
                  animationDelay={TRACK_IN_ANIMATION_DURATION_MILLIS}
                  draggable
                  index={index}
                  label={track.label}
                  id={track.id}
                  key={id}
                  onEntered={onLabelEntered}
                  onTrackDragStart={onDragStart}
                  onTrackDragStop={onDragStop}
                  onTrackDrop={onTrackDrop}
                  onTrackMoveIntent={onTrackMoveIntent}
                  trackType={track.type}
                >
                  <TrackControls>
                    {track.type === 'text' && (
                      <TrackControls.Style
                        disabled={track.styleButtonDisabled}
                        id={`${track.id}-style-tooltip`}
                        onClick={onStyleButtonClick}
                        params={track}
                      />
                    )}
                    <TrackControls.Delete
                      id={`${track.id}-delete-tooltip`}
                      disabled={track.permanent}
                      onClick={onDeleteButtonClick}
                      params={track}
                    />
                  </TrackControls>
                </TrackLabel>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

TrackLabels.defaultProps = {
  onDeleteButtonClick: noop,
  onStyleButtonClick: noop,
};

export default TrackLabels;
