import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createSelector } from 'reselect';
import FontAwesome from 'components/FontAwesome';
import {
  clearSearchResults,
  collectFeedback,
  searchPodcastsByName,
} from 'redux/modules/podcast-search/actions';
import {
  podcastResultsSelector,
  querySelector,
} from 'redux/modules/podcast-search/selectors';
import { Dispatch } from 'redux/types';
import { EpisodeExplorerProps } from './containers/EpisodeExplorer';

import PodcastExplorerBody, {
  PodcastExplorerBodyProps,
} from './containers/PodcastExplorerBody';
import { PodcastSearchIntegration } from './types';

type PickedIntegrationProps = Pick<
  PodcastSearchIntegration,
  'priority' | 'feedCollectionEnabled'
>;
type PickedEpisodeProps = Pick<EpisodeExplorerProps, 'isProcessingEpisode'>;
type PickedBodyProps = Pick<
  PodcastExplorerBodyProps,
  | 'episodeExplorerEnabled'
  | 'isProcessingEpisode'
  | 'onAudioAdded'
  | 'onPodcastClick'
  | 'onEpisodeClick'
>;

export interface UseDefaultIntegrationConfig
  extends PickedIntegrationProps,
    PickedEpisodeProps,
    PickedBodyProps {
  maxFileSizeMb?: number;
}

const resultCountSelector = createSelector(
  podcastResultsSelector,
  results => results && results.size,
);

export default function useDefaultIntegration({
  episodeExplorerEnabled = true,
  feedCollectionEnabled = true,
  isProcessingEpisode,
  maxFileSizeMb = spareminConfig.episodeMaxAudioFileSizeMb,
  onAudioAdded,
  onPodcastClick,
  onEpisodeClick,
  priority,
}: UseDefaultIntegrationConfig): PodcastSearchIntegration<'listennotes'> {
  const dispatch = useDispatch<Dispatch>();
  const resultCount = useSelector(resultCountSelector);
  const query = useSelector(querySelector);
  const handlePodcastClick = (podcastId: string) => {
    if (feedCollectionEnabled) {
      dispatch(collectFeedback(podcastId));
    }
    onPodcastClick?.(podcastId);
  };

  return {
    priority,
    resultCount,
    canHandle: () => true,
    color: '#6e6ede',
    description: {
      part1: 'Search for a',
      part2: 'podcast by name',
    },
    icon: <FontAwesome icon="microphone" />,
    id: 'listennotes',
    onSubmit: async (q: string) => {
      if (q === query) return undefined;
      dispatch(clearSearchResults());
      return dispatch(searchPodcastsByName(q));
    },
    placeholder: 'podcast name',
    results: height => (
      <PodcastExplorerBody
        {...{
          episodeExplorerEnabled,
          height,
          isProcessingEpisode,
          maxFileSizeMb,
          onAudioAdded,
          onEpisodeClick,
        }}
        onPodcastClick={handlePodcastClick}
      />
    ),
    tip: 'Podcast name',
    episodeExplorerEnabled,
  };
}
