import { Size } from 'types';
import { Measurement, ViewportWidth } from 'utils/measurement';
import { calculateVisibleText } from 'utils/ui';

import { CaptionsState, ToPx } from '../../types';

export const APROXIMATION_STEP = 0.1;
export const MINIMUM_WIDTH = 5;
export const MINIMAL_WORD = 'A';

export const getBoxCalculatorStyles = (
  captionsState: CaptionsState,
  toPx: ToPx,
  resizeDelta: Size<number> = { height: 0, width: 0 },
): {
  containerStyle: React.CSSProperties;
  textStyle: React.CSSProperties;
} => {
  const toPxValue = (m: Measurement, additionalValue = 0) =>
    toPx(m).value + additionalValue;

  // Increasing up the height in 1px is necessary as fractions are not well
  // calculated among the different canvas size that coexist (text modal and preview)
  const height = toPxValue(captionsState.size?.height, resizeDelta.height) + 1;

  const containerStyle: React.CSSProperties = {
    ...captionsState.containerStyle,
    width: toPxValue(captionsState.size?.width, resizeDelta.width),
    height,
    lineHeight: captionsState.containerStyle.lineHeight,
    fontSize: toPxValue(
      new ViewportWidth(parseFloat(captionsState.containerStyle?.fontSize)),
    ),
    paddingLeft: toPx(
      new ViewportWidth(
        parseFloat(
          captionsState.containerStyle?.paddingLeft ??
            captionsState.textStyle?.paddingLeft ??
            0,
        ),
      ),
    ).toString(),
    paddingTop: toPx(
      new ViewportWidth(
        parseFloat(
          captionsState.containerStyle?.paddingTop ??
            captionsState.textStyle?.paddingTop ??
            0,
        ),
      ),
    ).toString(),
    paddingRight: toPx(
      new ViewportWidth(
        parseFloat(
          captionsState.containerStyle?.paddingRight ??
            captionsState.textStyle?.paddingRight ??
            0,
        ),
      ),
    ).toString(),
    paddingBottom: toPx(
      new ViewportWidth(
        parseFloat(
          captionsState.containerStyle?.paddingBottom ??
            captionsState.textStyle?.paddingBottom ??
            0,
        ),
      ),
    ).toString(),
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  };

  const textStyle: React.CSSProperties = {
    ...captionsState.textStyle,
    boxDecorationBreak: 'clone',
    lineHeight: captionsState.containerStyle.lineHeight,
    fontSize: toPxValue(
      new ViewportWidth(parseFloat(captionsState.containerStyle?.fontSize)),
    ),
    WebkitBoxDecorationBreak: 'clone',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  };

  return {
    containerStyle,
    textStyle,
  };
};

/**
 * This util starts from the current height of the captions asset and starts
 * iterating over it by either increasing or decresing it until the minimum height
 * for which at least one line of text is visible is got.
 */
export const getBoxMinimumHeight = (
  minimalTextValue: string,
  captionsState: CaptionsState,
  toPx: ToPx,
): number => {
  if (!captionsState || !captionsState.editor?.styleContext?.viewport) {
    return 0;
  }

  const { containerStyle, textStyle } = getBoxCalculatorStyles(
    captionsState,
    toPx,
  );

  let [, lastCharIndex] = calculateVisibleText(
    minimalTextValue,
    containerStyle,
    textStyle,
  );
  let minimumHeight: number = parseInt(String(containerStyle.height), 10) ?? 0;
  let prevHeight = minimumHeight;

  if (lastCharIndex) {
    while (lastCharIndex) {
      prevHeight = minimumHeight;
      minimumHeight -= APROXIMATION_STEP;
      [, lastCharIndex] = calculateVisibleText(
        minimalTextValue,
        { ...containerStyle, height: minimumHeight },
        textStyle,
      );
    }
  } else {
    while (!lastCharIndex) {
      prevHeight = minimumHeight;
      minimumHeight += APROXIMATION_STEP;
      [, lastCharIndex] = calculateVisibleText(
        minimalTextValue,
        { ...containerStyle, height: minimumHeight },
        textStyle,
      );
    }
  }

  return Math.ceil(prevHeight);
};

/**
 * Truncates the current asset visible text based on the containing box
 * dimensions.
 */
export const getBoxTruncatedText = (
  textValue: string,
  captionsState: CaptionsState,
  resizeDelta: Size<number>,
  toPx: ToPx,
): string => {
  if (!captionsState || !captionsState.editor?.styleContext?.viewport) {
    return '';
  }

  const { containerStyle, textStyle } = getBoxCalculatorStyles(
    captionsState,
    toPx,
    resizeDelta,
  );

  const range = calculateVisibleText(textValue, containerStyle, textStyle);
  return textValue.substring(...range).trim();
};

export const isTextRangeEmpty = (range: number[] = []): boolean =>
  range.every(entry => entry === 0);
