import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularGradientLeftQuote } from 'components/icons/circular-gradient';
import { Tier } from 'redux/middleware/api/plan-service/types';
import { makePodcastFeedEpisodeSelector } from 'redux/modules/entities';
import { onClickUnlockEpisodeTranscripts } from 'redux/modules/mixpanel';
import { pushModal, replaceModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { EPISODE_TRANSCRIPTION_TRANSCRIBABLE_STATUSES } from 'utils/constants';
import DownloadCaptionsDialogModalContents from './DownloadCaptionsDialogModalContents';

export interface UnlockTranscriptsModalContentsProps {
  episodeId?: string;
}

const UnlockTranscriptsModalContents: React.FC<UnlockTranscriptsModalContentsProps> = ({
  episodeId,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const episodeSelector = useMemo(
    () => makePodcastFeedEpisodeSelector(episodeId),
    [episodeId],
  );
  const episode = useSelector(episodeSelector);

  const handleClick = useCallback(async () => {
    dispatch(onClickUnlockEpisodeTranscripts);
    const didUpgrade = await dispatch(
      replaceModal({
        name: 'AddBillingCycle',
        params: {
          tier: Tier.PRO,
          defaultSubscriptionPeriod: 'yearly',
          disableSuccessRedirect: true,
        },
      }),
    );

    if (didUpgrade && episode) {
      const status = episode?.transcriptInfo?.status;

      if (status === 'completed') {
        dispatch(
          pushModal({
            name: 'DownloadEpisodeCaptions',
            params: { episodeId },
          }),
        );
      } else if (
        EPISODE_TRANSCRIPTION_TRANSCRIBABLE_STATUSES.includes(status)
      ) {
        dispatch(
          pushModal({
            name: 'TranscribeEpisode',
            params: { episodeId },
          }),
        );
      }
    }
  }, [dispatch, episode, episodeId]);

  return (
    <DownloadCaptionsDialogModalContents
      actions={[
        {
          children: 'upgrade to pro',
          key: 'upgrade',
          onClick: handleClick,
          theme: 'rainbow',
        },
      ]}
      icon={<CircularGradientLeftQuote />}
      title="Upgrade to unlock transcripts"
      details="Upgrade to get access to full transcripts of each episode that you can post on any website to increase this podcast’s accessibilty & SEO."
    />
  );
};

export default UnlockTranscriptsModalContents;
