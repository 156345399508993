import * as React from 'react';
import { Redirect, RouteChildrenProps } from 'react-router';
import { compose, noop } from 'underscore';

import AutomationWorkflowWizard from 'blocks/AutomationWorkflowWizard';
import { userIsAuthenticatedAndOnboarded } from 'components/hoc/auth';
import withRouter from 'components/hoc/withRouter';
import {
  AudiogramWizard,
  AudioWizard,
  AutomatedWizard,
  EpisodeWizard,
  TemplateWizard,
  TextWizard,
  VideoWizard,
} from 'containers/ProjectWizards';

import VideoClipWizard from 'containers/ProjectWizards/VideoClipWizard';
import withRedirectOnMobile from 'containers/Redirect/withRedirectOnMobile';
import { parseSearch } from 'utils/routes';
import WizardPage from '../../components/WizardPage';

interface IProps extends RouteChildrenProps {
  fallbackUrl: string;
  isLoading?: boolean;
  hasError?: boolean;
  onMount?: () => void;
}

const withAuth = compose(withRouter, userIsAuthenticatedAndOnboarded);
const withAuthOnDesktop = compose(withAuth, withRedirectOnMobile());

const AuthAudiogramWizard = withAuthOnDesktop(AudiogramWizard);
const AuthAutomatedWizard = withAuthOnDesktop(AutomatedWizard);
const AuthEpisodeWizard = withAuthOnDesktop(EpisodeWizard);
const AuthTextWizard = withAuthOnDesktop(TextWizard);
const AuthVideoWizard = withAuthOnDesktop(VideoWizard);
const AuthTemplateWizard = withAuthOnDesktop(TemplateWizard);
const AuthAutogramWizard = withAuth(AutomationWorkflowWizard);

interface State {
  loaded: boolean;
}

export default class WizardPageLoader extends React.Component<IProps, State> {
  public static defaultProps: Partial<IProps> = {
    onMount: noop,
  };

  public state: State = {
    // legacy logic defines the loading state based on the progress of the
    // "getMyDisplayPref" request.  some wizards (e.g. AudioWizard) allow the user
    // to use them anonymously and then authenticate right at the end.  If, at that point,
    // we issue a request to get the user's display preferences, the isLoading prop
    // injected into this component from redux will change, affecting what this component
    // renders on screen.
    //
    // this state value makes sure that doesn't happen
    loaded: false,
  };

  public componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  public componentDidUpdate(prevProps) {
    const { isLoading: prevIsLoading } = prevProps;
    const { isLoading } = this.props;

    if (prevIsLoading && !isLoading) {
      this.setState({ loaded: true });
    }
  }

  private getWizardPage() {
    const { fallbackUrl, location } = this.props;
    const query = parseSearch(location.search);

    switch (query.type) {
      case 'audio': {
        return <AudioWizard />;
      }

      case 'video-clip': {
        return <VideoClipWizard />;
      }

      case 'audiogram': {
        return <AuthAudiogramWizard />;
      }

      case 'automated':
        return <AuthAutomatedWizard />;

      case 'episode':
        return <AuthEpisodeWizard />;

      case 'text':
        return <AuthTextWizard />;

      case 'video-transcription':
        return <AuthVideoWizard />;

      case 'template':
        return <AuthTemplateWizard />;

      case 'autovideo':
        return <AuthAutogramWizard key={location.state} />;

      default:
        return <Redirect to={fallbackUrl} />;
    }
  }

  public render() {
    const { fallbackUrl, hasError } = this.props;
    const { loaded } = this.state;

    return hasError ? (
      <Redirect to={fallbackUrl} />
    ) : (
      <WizardPage>{!loaded ? null : this.getWizardPage()}</WizardPage>
    );
  }
}

export { IProps as WizardPageLoaderProps };
