import cn from 'classnames';
import * as React from 'react';

import Tile, { TileProps } from '../Tile';
import { imageViewBlock as block } from '../utils';

type PickedTileProps<P extends any> = Pick<TileProps<P>, 'onClick' | 'params'>;

export interface OptionTileIconProps {
  className: string;
}

export interface OptionTileProps<P extends any = any>
  extends PickedTileProps<P> {
  className?: string;
  disabled?: boolean;
  disabledTooltipText?: string;
  icon: React.ReactElement<OptionTileIconProps>;
  label: string;
}

const OptionTile = <P extends any = any>({
  className,
  disabled,
  disabledTooltipText,
  icon,
  label,
  onClick,
  params,
}: OptionTileProps<P>) => {
  const tooltipText = disabled ? disabledTooltipText : undefined;

  return (
    <Tile
      className={cn(block('tile'), className)}
      disabled={disabled}
      tooltip={tooltipText}
      tooltipPlacement="top"
      {...{ onClick, params }}
    >
      {React.cloneElement(icon, {
        className: cn(block('tile-icon'), icon?.props?.className),
      })}
      <span className={block('tile-label')}>{label}</span>
    </Tile>
  );
};

export default OptionTile;
