import { getMediaContentInfo } from 'redux/middleware/api/media-upload-service/actions';
import * as types from './types';

export const getContentType = url => async dispatch => {
  dispatch({
    type: types.CONTENT_TYPE_GET_REQUEST,
    payload: {
      url,
    },
  });

  try {
    const { response } = await dispatch(getMediaContentInfo(url));
    const { contentType, isAnimated, isError } = response;

    if (isError) {
      throw new Error('Error getting media content info');
    }

    dispatch({
      type: types.CONTENT_TYPE_GET_SUCCESS,
      payload: { animated: isAnimated, contentType, url },
    });
  } catch (err) {
    dispatch({
      type: types.CONTENT_TYPE_GET_FAILURE,
      payload: { url },
    });
    throw err;
  }
};

export default { getContentType };
