import React from 'react';
import { useDispatch } from 'react-redux';

import DiscoWidgetInjector from 'blocks/DiscoWidgetInjector';
import { Microphone } from 'components/icons';
import LinkButton from 'components/LinkButton';
import { onExportDiscoWidgetInitialized } from 'redux/modules/mixpanel';

import { DISCO_ADS_PROMO_LINK, DISCO_LISTENS_STATUS } from 'utils/constants';
import { discoBlock } from '../../utils';

interface PostTaskDiscoSectionProps {}

const PostTaskDiscoSection: React.FunctionComponent<PostTaskDiscoSectionProps> = () => {
  const dispatch = useDispatch();

  const { displayVariant } = DISCO_LISTENS_STATUS;

  const onInitialize = React.useCallback(() => {
    dispatch(onExportDiscoWidgetInitialized(displayVariant));
  }, [dispatch, displayVariant]);

  return (
    <section className={discoBlock('modal-body')}>
      <DiscoWidgetInjector
        className={discoBlock('disco-container')}
        onInitialize={onInitialize}
        displayVariant={displayVariant}
      />
      <div className={discoBlock('bottom-disclaimer')}>
        <div className={discoBlock('disclaimer-inner')}>
          <Microphone className={discoBlock('icon')} height={15} width={11} />
          <span className={discoBlock('disclaimer-text')}>
            Want to promote your podcast here?{' '}
            <LinkButton
              href={DISCO_ADS_PROMO_LINK}
              theme="cta"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </LinkButton>
            .
          </span>
        </div>
      </div>
    </section>
  );
};

export default PostTaskDiscoSection;
