import { Map } from 'immutable';

import { EditorData } from 'components/RndTextEditor';
import { createMap, DeepImmutableMap } from 'types';
import bem from 'utils/bem';
import { getValue } from 'utils/collections';
import { getUpperLeft } from 'utils/ui';
import { ICaptions } from './types';

export const block = bem('captions-modal');

type Captions = DeepImmutableMap<
  ICaptions & {
    textBoxHeight?: number;
    templateName?: string;
  }
>;

export function formatCaptionAsProp(caption: Captions): EditorData {
  const containerStyle = caption.get('containerStyle');
  const size = Map({
    height: caption.get('textBoxHeight'),
    width: caption.getIn(['size', 'width']),
  });

  const position = getUpperLeft(
    caption.get('position'),
    size,
    caption.get('viewport'),
  );

  const animation = getValue(caption, ['animation']);

  return createMap({
    animation,
    containerStyle,
    position,
    size,
    templateId: caption.get('templateName'),
    textStyle: caption.get('textStyle'),
    viewport: caption.get('viewport'),
  }) as any;
}
