import { useSelector } from 'react-redux';
import {
  isYouTubeEnabledSelector,
  projectOwnerShareMethodOverridesSelector,
} from 'redux/modules/download';

export enum SocialIntegration {
  YOUTUBE = 'youtube',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  THREADS = 'threads',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  TIKTOK = 'tiktok',
  DIRECT_DOWNLOAD = 'directDownload',
}

export default function useSocialIntegration() {
  const isYouTubeEnabled = useSelector(isYouTubeEnabledSelector);
  const shareMethodOverride = useSelector(
    projectOwnerShareMethodOverridesSelector,
  );

  if (Array.isArray(shareMethodOverride)) {
    return shareMethodOverride;
  }

  return [
    SocialIntegration.TWITTER,
    SocialIntegration.FACEBOOK,
    isYouTubeEnabled && SocialIntegration.YOUTUBE,
    SocialIntegration.INSTAGRAM,
    SocialIntegration.THREADS,
    SocialIntegration.DIRECT_DOWNLOAD,
    SocialIntegration.LINKEDIN,
    SocialIntegration.TIKTOK,
  ].filter(Boolean);
}
