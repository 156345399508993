import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from 'components/ConfirmationModal';
import { TiktokIconRounded } from 'components/icons';
import Modal from 'components/Modal';
import { Tier } from 'redux/middleware/api/plan-service/types';
import { onUnlockThisFeature } from 'redux/modules/mixpanel/actions';
import { modalParamsSelector } from 'redux/modules/modal';
import { replaceModal } from 'redux/modules/modal/actions';

function TikTokAutoPostingUpsellModalContent() {
  const dispatch = useDispatch();
  const modalParams = useSelector(modalParamsSelector);

  const handleUpgradeClick = useCallback(() => {
    dispatch(
      onUnlockThisFeature(
        modalParams?.unlockThisFeature || 'TikTokAutoPosting',
      ),
    );

    dispatch(
      replaceModal({
        name: 'AddBillingCycle',
        params: {
          tier: Tier.PRO,
          defaultSubscriptionPeriod: 'yearly',
          disableSuccessRedirect: true,
        },
      }),
    );
  }, [dispatch, modalParams]);

  return (
    <>
      <ConfirmationModal.Body>
        <ConfirmationModal.Icon>
          <TiktokIconRounded />
        </ConfirmationModal.Icon>
        <ConfirmationModal.Title>
          Unlock TikTok auto-posting
        </ConfirmationModal.Title>
        <ConfirmationModal.Content>
          This is only available to Pro users because TikTok doesn’t allow
          <br />
          watermarked videos like the ones included in our Forever Free plan.
        </ConfirmationModal.Content>
      </ConfirmationModal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton theme="rainbow" onClick={handleUpgradeClick}>
            Unlock this feature
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
}

export default TikTokAutoPostingUpsellModalContent;
