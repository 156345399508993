import { UseAudioUploadConfig, UseAudioUploadValue } from './types';
import useAudioUpload from './useAudioUpload';

export interface UseAudioUploadProps extends UseAudioUploadConfig {
  children?: React.FC<UseAudioUploadValue>;
}

const UseAudioUpload: React.SFC<UseAudioUploadProps> = ({
  children,
  ...props
}) => {
  return children(useAudioUpload(props));
};

export default UseAudioUpload;
export { UseAudioUploadValue, useAudioUpload };
