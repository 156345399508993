import React from 'react';

import { block } from '../../utils';

interface TextOverlayControlWrapperProps {
  children: React.ReactNode;
  label: string;
}

const TextOverlayControlWrapper: React.FunctionComponent<TextOverlayControlWrapperProps> = props => {
  const { children, label } = props;

  return (
    <div className={block('control-wrapper')}>
      <h5 className={block('control-label')}>{label}</h5>
      {children}
    </div>
  );
};

export default TextOverlayControlWrapper;
