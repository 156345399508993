import * as React from 'react';

import AssetTileToggle from 'components/AssetTileToggle';
import { AspectRatioName, WatermarkOption } from 'types';
import bem from 'utils/bem';
import { stringToPct } from 'utils/placement';

const { useMemo } = React;

export interface Props {
  value: WatermarkOption;
  checked: boolean;
  onToggle: (value: WatermarkOption | null) => void;
  aspectRatioName: AspectRatioName;
}

const block = bem('locked-watermark-option');

const LockedWatermarkOption: React.FC<Props> = props => {
  const { value, checked, onToggle, aspectRatioName } = props;
  const style = useMemo(
    () =>
      stringToPct({
        position: 'absolute' as const,
        ...value?.position,
        ...value?.size,
      }),
    [value],
  );

  return (
    <AssetTileToggle
      {...{ checked, aspectRatioName }}
      value={value}
      className={block()}
      onToggle={newChecked => onToggle(newChecked ? value : null)}
      displayName={value.displayName}
    >
      <img src={value.url} style={style} />
    </AssetTileToggle>
  );
};

export default React.memo(LockedWatermarkOption);
