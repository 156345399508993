import memoize from 'memoizee';
import { useCallback } from 'react';

type Callback<A, R> = (...args: A[]) => R;

export interface UseMemoizedCallbackOptions {
  // @see meomizee { promise: boolean } option
  promise?: boolean;
}

/*
 * like useCallback but memoizes the return value so that the function is
 * not even invoked again if the dependencies don't change
 */
export default function useMemoizedCallback<A, R>(
  cb: Callback<A, R>,
  { promise = false }: UseMemoizedCallbackOptions,
  deps: A[],
) {
  return useCallback(memoize(cb, { promise, max: 1 }), deps);
}
