import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteModal from 'components/DeleteModal';
import Modal from 'components/Modal';
import {
  addingToTrack,
  removeFromAudioTrack,
  replaceAudioTrack,
  replaceSelectedTrackElement,
} from 'redux/modules/embed/actions';
import {
  selectedAudioAssetSelector,
  selectedTrackElementSelector,
} from 'redux/modules/embed/selectors';
import { pushModal } from 'redux/modules/modal';
import useEditAssetModal from '../ConnectedModal/useEditAssetModal';

const { useCallback } = React;

export interface EditBackgroundAudioModalProps {}

const EditBackgroundAudioModal: React.FC<EditBackgroundAudioModalProps> = () => {
  const dispatch = useDispatch();
  const { onExited, onHide, show } = useEditAssetModal(
    'EditBackgroundAudioModal',
  );
  const audio = useSelector(selectedAudioAssetSelector);
  const trackId = useSelector(state =>
    selectedTrackElementSelector(state)?.get('trackId'),
  );

  const handleDelete = useCallback(() => {
    dispatch(removeFromAudioTrack(audio.get('id')));
    onHide();
  }, [audio, dispatch, onHide]);

  const handleReplace = useCallback(() => {
    dispatch(replaceAudioTrack());
    dispatch(replaceSelectedTrackElement());
    onHide();
    dispatch(pushModal({ name: 'AddAudioModal' }));
    dispatch(addingToTrack(trackId));
  }, [dispatch, onHide, trackId]);

  return (
    <DeleteModal
      bodyText="Would you like to delete or replace background audio?"
      onCancel={onHide}
      onExited={onExited}
      footer={
        <Modal.Footer>
          <Modal.FooterButtons>
            <Modal.FooterButton onClick={handleDelete} theme="delete">
              delete
            </Modal.FooterButton>
            <Modal.FooterButton onClick={handleReplace} theme="submit">
              replace
            </Modal.FooterButton>
          </Modal.FooterButtons>
        </Modal.Footer>
      }
      show={show}
      title="Background Audio"
    />
  );
};

export default EditBackgroundAudioModal;
