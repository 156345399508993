import * as React from 'react';

import { VideoBackgroundType } from './types';
import { block } from './utils';

export interface VideoBackgroundProps {
  background: VideoBackgroundType;
}

const VideoBackground = React.forwardRef<
  HTMLVideoElement,
  VideoBackgroundProps
>(({ background }, ref) => (
  <video
    className={block('image')}
    muted
    loop
    ref={ref}
    preload="none"
    poster={background.thumbnail}
  >
    {typeof background.src === 'string' ? (
      <source src={background.src} />
    ) : (
      background.src.map(({ url, type }) => (
        <source key={url} src={url} type={type} />
      ))
    )}
  </video>
));

VideoBackground.displayName = 'ForwardRef(VideoBackground)';

export default VideoBackground;
