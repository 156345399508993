import { AudioProxyServiceDispatch } from './audio-proxy-service/types';
import { AudiogramDataServiceDispatch } from './audiogram-data-service/types';
import { AuthServiceDispatch } from './auth-service/types';
import { CreationServiceDispatch } from './creation-service/types';
import { EddyServiceDispatch } from './eddy-service';
import { EmbedConfigurationServiceDispatch } from './embed-configuration-service/types';
import { EmbedServiceDispatch } from './embed-service/types';
import { HeadlinerUserServiceDispatch } from './headliner-user-service/types';
import { ImageSearchServiceDispatch } from './image-search-service/types';
import { ImageServiceDispatch } from './image-service/types';
import { KeywordExtractorServiceDispatch } from './keyword-extractor-service/types';
import { MediaServiceDispatch } from './media-service/types';
import { MediaUploadServiceDispatch } from './media-upload-service/types';
import { PlanServiceDispatch } from './plan-service/types';
import { PodcastServiceDispatch } from './podcast-service/types';
import { RecordingServiceDispatch } from './recording-service/types';
import { RecordingUploadServiceDispatch } from './recording-upload-service/types';
import { ThirdPartyAuthenticationServiceDispatch } from './third-party-authentication-service/types';
import { TranscriptServiceDispatch } from './transcript-service/types';
import { UrlGeneratorServiceDispatch } from './url-generator-service/types';
import { UserServiceDispatch } from './user-service/types';
import { VideoExportServiceDispatch } from './video-export-service/types';
import { VideoProjectManagementServiceDispatch } from './video-project-management-service/types';

type ApiActionType = 'API_CALL';
export const API_CALL: ApiActionType = 'API_CALL';

export interface IApiAction<S extends string, M, A> {
  type: string;
  [API_CALL]: {
    service: S;
    method: M;
    args?: A;
  };
}

export interface IApiResponse<T> {
  response: T;
}

export type ApiDispatch = AudiogramDataServiceDispatch &
  AudioProxyServiceDispatch &
  AuthServiceDispatch &
  CreationServiceDispatch &
  EmbedConfigurationServiceDispatch &
  EmbedServiceDispatch &
  EddyServiceDispatch &
  HeadlinerUserServiceDispatch &
  ImageSearchServiceDispatch &
  ImageServiceDispatch &
  KeywordExtractorServiceDispatch &
  MediaUploadServiceDispatch &
  MediaServiceDispatch &
  PlanServiceDispatch &
  PodcastServiceDispatch &
  RecordingServiceDispatch &
  RecordingUploadServiceDispatch &
  ThirdPartyAuthenticationServiceDispatch &
  TranscriptServiceDispatch &
  VideoExportServiceDispatch &
  VideoProjectManagementServiceDispatch &
  UrlGeneratorServiceDispatch &
  UserServiceDispatch;
