import { useDispatch } from 'react-redux';
import { resendVerificationEmail } from 'redux/modules/auth/actions';
import { replaceModal } from 'redux/modules/modal';
import { showNotification, showSuccess } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';

export default () => {
  const dispatch = useDispatch<Dispatch>();
  const onChangeEmail = () => {
    dispatch(replaceModal({ name: 'UpdateEmail' }));
  };
  const onResendEmail = async (email: string) => {
    try {
      await dispatch(resendVerificationEmail());
      dispatch(
        showSuccess(
          `We’ve emailed ${email}`,
          10,
          undefined,
          'Verification email sent',
        ),
      );
    } catch (error) {
      dispatch(
        showNotification({
          message: 'Please try again or {{link}} so we can help',
          code: 'ER013',
          dismissAfterSec: 10,
          level: 'error',
          type: 'help',
          title: "Sorry, we couldn't send your invites",
          actionLabel: 'contact us',
        }),
      );
    }
  };

  return { onChangeEmail, onResendEmail };
};
