import * as Immutable from 'immutable';
import * as React from 'react';
import _ from 'underscore';

import FadingScrollBars from 'components/FadingScrollBars';
import FontLoader from 'components/hoc/FontLoader';
import Modal from 'components/Modal';
import RndTextEditor, {
  RndTextEditorData,
  RndTextHandlers,
} from 'components/RndTextEditor';
import { Padding, Position, TextShadow } from 'components/TextBoxOptions';
import TextOverlayTransitionsForm, {
  TransitionIn,
  TransitionOut,
} from 'components/TextOverlayTransitionsForm';
import TextTemplateSelector from 'components/TextTemplateSelector';
import { DefaultTextToolbar } from 'components/TextToolbar';
import { IImmutableMap } from 'types';
import {
  getDraggingEnabledByTemplateId,
  getResizableEdgesByTemplateId,
} from 'utils/embed/text-overlay';
import { GRID_SIZE, scaleToGrid } from 'utils/embed/ui';
import { getFontName } from 'utils/fonts';
import { percentageOf, round, scale } from 'utils/numbers';
import { getUpperLeft, isBold, isItalic, isUnderline } from 'utils/ui';
import { block } from '../../utils/block';
import StyleForm from './StyleForm';
import StyleTabs from './StyleTabs';
import {
  GlobalTextStyle,
  GlobalTextTemplate,
  IContainerDecorator,
  IContainerDecoratorStyle,
  ITransitions,
} from './types';
import Workspace from './Workspace';

interface IUi {
  resizing: boolean;
  selectedTemplateId: string;
  selectedTransitionTab: string | number;
}

interface ITextOverlayState {
  transitions?: IImmutableMap<ITransitions>;
  containerDecorator?: IImmutableMap<IContainerDecorator>;
}

interface IState {
  ui: IImmutableMap<IUi>;
  textOverlay: IImmutableMap<ITextOverlayState>;
}

interface IFont {
  name: string;
  familyName: string;
  family: string;
  url: string;
}

interface IProps {
  textStyle: GlobalTextStyle;
  text: string;
  fonts: IFont[];
  textTemplates: GlobalTextTemplate[];
  aspectRatio?: number;
  defaultInTransitionName?: string;
  defaultOutTransitionName?: string;
}

export default class GlobalStyleModalBody extends React.Component<
  IProps,
  IState
> {
  public static defaultProps = {
    onTemplateSelect: _.noop,
  };

  public static convertTemplateToEditorData(
    overlay: GlobalTextStyle,
  ): RndTextEditorData {
    return overlay.withMutations(to => {
      to.delete('containerDecorator');
      to.delete('transitions');
      to.set(
        'position',
        getUpperLeft(to.get('position'), to.get('size'), to.get('viewport')),
      );
    });
  }

  private textEditor: RndTextEditor;
  private textEditorRef = (el: RndTextEditor) => {
    this.textEditor = el;
  };

  constructor(props) {
    super(props);

    const { textStyle } = this.props;

    const transitions = textStyle.get('transitions');
    const textOverlay = Immutable.fromJS({
      transitions,
      containerDecorator: textStyle.get('containerDecorator'),
    });

    const ui = Immutable.fromJS({
      resizing: false,
      selectedTemplateId: textStyle.get('templateId'),
      selectedTransitionTab: 'in',
    });

    this.state = { textOverlay, ui };
  }

  private handleFontChange = handlers => (fontname: string) => {
    const { fonts } = this.props;
    const font = fonts.find(f => f.name === fontname);
    handlers.setFont(font);
  };

  private handleFontColorChange = handlers => (color: string) => {
    handlers.setFontColor(color);
  };

  private handleFontSizeChange = handlers => (size: number) => {
    const { textStyle } = this.props;
    handlers.setFontSize(size, textStyle.get('viewport'));
  };

  private handleHighlightColorChange = handlers => (color: string) => {
    handlers.setTextHighlight(color);
  };

  private handleJustifyChange = handlers => (justify: string) => {
    handlers.setAlignment(justify);
  };

  private handleTextShadowChange = handlers => (textshadow: TextShadow) => {
    handlers.setTextShadowX(textshadow.x);
    handlers.setTextShadowY(textshadow.y);
    handlers.setTextShadowBlur(textshadow.blur);
    handlers.setTextShadowColor(textshadow.color);
  };

  private handleToggleBold = (handlers, bold: boolean) => () => {
    handlers.setBold(bold);
  };

  private handleToggleItalic = (handlers, italic: boolean) => () => {
    handlers.setItalic(italic);
  };

  private handleToggleUnderline = (handlers, underline: boolean) => () => {
    handlers.setUnderline(underline);
  };

  private handleLineSpacingBlur = handlers => value => {
    const newValue = value === '' ? 1 : value;

    handlers.setLineHeight(newValue);
  };

  private handlePaddingChange = handlers => (value: Padding) => {
    handlers.setPaddingTop(value.top);
    handlers.setPaddingBottom(value.bottom);
    handlers.setPaddingLeft(value.left);
    handlers.setPaddingRight(value.right);
  };

  private handlePaddingBlur = handlers => value => {
    const newValue = {
      bottom: value.bottom === '' ? 0 : value.bottom,
      left: value.left === '' ? 0 : value.left,
      right: value.right === '' ? 0 : value.right,
      top: value.top === '' ? 0 : value.top,
    };

    handlers.setPaddingTop(newValue.top);
    handlers.setPaddingBottom(newValue.bottom);
    handlers.setPaddingLeft(newValue.left);
    handlers.setPaddingRight(newValue.right);
  };

  private handleTransitionInChange = (tIn: TransitionIn) =>
    this.setState(({ textOverlay }) => ({
      textOverlay: textOverlay.setIn(
        ['transitions', 'in'],
        tIn,
      ) as IImmutableMap<ITextOverlayState>,
    }));

  private handleTransitionOutChange = (tOut: TransitionOut) =>
    this.setState(({ textOverlay }) => ({
      textOverlay: textOverlay.setIn(
        ['transitions', 'out'],
        tOut,
      ) as IImmutableMap<ITextOverlayState>,
    }));

  private handlePositionChange = handlers => (value: Position) =>
    handlers.setPosition(value, GRID_SIZE, true);

  private handlePositionBlur = handlers => value => {
    const newValue = {
      x: value.x === '' ? 0 : value.x,
      y: value.y === '' ? 0 : value.y,
    };

    handlers.setPosition(newValue, GRID_SIZE, true);
  };

  private handleTextBoxDrag = (
    handlers: RndTextHandlers,
    x: number,
    y: number,
  ) => handlers.setPosition({ x, y });

  private handleTextBoxDragStop = (
    handlers: RndTextHandlers,
    x: number,
    y: number,
  ) => handlers.setPosition({ x, y });

  private handleTextBoxResizeStart = () =>
    this.setState(({ ui }) => ({
      ui: ui.set('resizing', true),
    }));

  private handleTextBoxResizeStop = (
    handlers: RndTextHandlers,
    width: number,
    height: number,
    x: number,
    y: number,
  ) => {
    handlers.setSize(x, y, height, width);
    this.setState(({ ui }) => ({
      ui: ui.set('resizing', false),
    }));
  };

  private handleWorkspaceSizeChange = (
    handlers: RndTextHandlers,
    width: number,
    height: number,
  ) => {
    handlers.setWorkspaceSize(height, width);
  };

  private handleDecoratorStylesChange = (
    styles: Immutable.List<IContainerDecoratorStyle>,
  ) => {
    this.setState(({ textOverlay }) => ({
      textOverlay: textOverlay.setIn(
        ['containerDecorator', 'styles'],
        styles,
      ) as IImmutableMap<ITextOverlayState>,
    }));
  };

  private handleTemplateSelect = (handlers: RndTextHandlers) => (
    template: GlobalTextTemplate,
  ) => {
    const textTemplate = template.template;
    const formattedTemplate = GlobalStyleModalBody.convertTemplateToEditorData(
      textTemplate,
    );

    this.setState(({ textOverlay, ui }) => ({
      textOverlay: textOverlay.set(
        'containerDecorator',
        textTemplate.get('containerDecorator'),
      ),
      ui: ui.set('selectedTemplateId', template.templateId),
    }));

    handlers.setData(formattedTemplate);
  };

  private static getTextPosition(data: RndTextEditorData) {
    const { x, y } = scaleToGrid(
      data.getIn(['position', 'left']),
      data.getIn(['position', 'top']),
      data.getIn(['viewport', 'width']),
      data.getIn(['viewport', 'height']),
    );

    return { x, y };
  }

  private static getTextPadding(data: RndTextEditorData) {
    const viewportWidth = data.getIn(['viewport', 'width']);
    const paddings: {
      bottom: number | '';
      left: number | '';
      right: number | '';
      top: number | '';
    } = {
      bottom:
        data.getIn(['containerStyle', 'paddingBottom']) === ''
          ? ''
          : percentageOf(
              data.getIn(['containerStyle', 'paddingBottom']),
              viewportWidth,
            ),
      left:
        data.getIn(['containerStyle', 'paddingLeft']) === ''
          ? ''
          : percentageOf(
              data.getIn(['containerStyle', 'paddingLeft']),
              viewportWidth,
            ),
      right:
        data.getIn(['containerStyle', 'paddingRight']) === ''
          ? ''
          : percentageOf(
              data.getIn(['containerStyle', 'paddingRight']),
              viewportWidth,
            ),
      top:
        data.getIn(['containerStyle', 'paddingTop']) === ''
          ? ''
          : percentageOf(
              data.getIn(['containerStyle', 'paddingTop']),
              viewportWidth,
            ),
    };
    return paddings;
  }

  private convertEditorDataToTemplate(
    data: RndTextEditorData,
  ): GlobalTextStyle {
    const { textOverlay, ui } = this.state;
    return data.withMutations(d => {
      d.set('containerDecorator', textOverlay.get('containerDecorator'));
      d.set('transitions', textOverlay.get('transitions'));
      d.set('templateId', ui.get('selectedTemplateId'));
    });
  }

  public get textStyle(): GlobalTextStyle {
    const { textStyle } = this.props;

    if (!this.textEditor) return undefined;
    const data = this.textEditor.getData(textStyle.get('viewport'));
    return this.convertEditorDataToTemplate(data);
  }

  private handleTransitionTabSelect = (tabKey: string | number) =>
    this.setState(({ ui }) => ({
      ui: ui.set('selectedTransitionTab', tabKey),
    }));

  private renderTextTemplateForm = (__, handlers) => {
    const { textTemplates } = this.props;
    return (
      <TextTemplateSelector
        templates={textTemplates}
        onSelect={this.handleTemplateSelect(handlers)}
      />
    );
  };

  private renderStyleForm = (data, handlers) => {
    const { textOverlay } = this.state;
    return (
      <StyleForm
        background={data.getIn(['containerStyle', 'background'])}
        containerDecoratorStyles={textOverlay.getIn([
          'containerDecorator',
          'styles',
        ])}
        lineHeight={data.getIn(['containerStyle', 'lineHeight'])}
        padding={GlobalStyleModalBody.getTextPadding(data)}
        position={GlobalStyleModalBody.getTextPosition(data)}
        onDecoratorStylesChange={this.handleDecoratorStylesChange}
        onBackgroundChange={handlers.setBackground}
        onLineHeightChange={handlers.setLineHeight}
        onPaddingChange={this.handlePaddingChange(handlers)}
        onPositionChange={this.handlePositionChange(handlers)}
        onLineHeightBlur={this.handleLineSpacingBlur(handlers)}
        onPaddingBlur={this.handlePaddingBlur(handlers)}
        onPositionBlur={this.handlePositionBlur(handlers)}
      />
    );
  };

  private renderTransitionOptionsFormTab = () => {
    const { defaultInTransitionName, defaultOutTransitionName } = this.props;
    const { ui } = this.state;
    const selectedTransitionTab = ui.get('selectedTransitionTab');
    const isScrollDisabled = () =>
      (selectedTransitionTab === 'in' && !!defaultInTransitionName) ||
      (selectedTransitionTab === 'out' && !!defaultOutTransitionName);

    return (
      <FadingScrollBars
        className={block('fade-scroller')}
        hideTracksWhenNotNeeded
        disabled={isScrollDisabled()}
      >
        {this.renderTransitionOptionsForm()}
      </FadingScrollBars>
    );
  };

  private renderTransitionOptionsForm = () => {
    const { defaultInTransitionName, defaultOutTransitionName } = this.props;
    const { textOverlay } = this.state;

    return (
      <div className={block('trnstn-tab-content')}>
        <TextOverlayTransitionsForm
          className={block('trnstn-form')}
          transitionIn={textOverlay.getIn(['transitions', 'in'])}
          transitionOut={textOverlay.getIn(['transitions', 'out'])}
          onInTransitionChange={this.handleTransitionInChange}
          onOutTransitionChange={this.handleTransitionOutChange}
          defaultInTransitionName={defaultInTransitionName}
          defaultOutTransitionName={defaultOutTransitionName}
          onTabSelect={this.handleTransitionTabSelect}
        />
      </div>
    );
  };

  private renderTextToolbar(data, handlers) {
    const { fonts, textStyle } = this.props;

    const textShadow = {
      blur: data.getIn(['containerStyle', 'textShadow', 'blur']),
      color: data.getIn(['containerStyle', 'textShadow', 'color']),
      x: data.getIn(['containerStyle', 'textShadow', 'x']),
      y: data.getIn(['containerStyle', 'textShadow', 'y']),
    };

    const fontSize = round(
      scale(
        data.getIn(['containerStyle', 'fontSize']),
        data.getIn(['viewport', 'width']),
        textStyle.getIn(['viewport', 'width']),
      ),
    );

    const fontName = getFontName(
      data.getIn(['containerStyle', 'fontFamily']),
      fonts,
    );
    const fontColor = data.getIn(['containerStyle', 'color']);
    const highlightColor = data.getIn(['textStyle', 'background']);
    const justify = data.getIn(['containerStyle', 'textAlign']);

    const bold = isBold(data.getIn(['containerStyle', 'fontWeight']));
    const italic = isItalic(data.getIn(['containerStyle', 'fontStyle']));
    const underline = isUnderline(
      data.getIn(['containerStyle', 'textDecoration']),
    );

    return (
      <DefaultTextToolbar
        className={block('toolbar')}
        bold={bold}
        fonts={fonts}
        fontname={fontName}
        fontsize={fontSize}
        fontcolor={fontColor}
        highlightColor={highlightColor}
        italic={italic}
        justify={justify}
        textShadow={textShadow}
        underline={underline}
        showEmoji={false}
        onFontChange={this.handleFontChange(handlers)}
        onFontColorChange={this.handleFontColorChange(handlers)}
        onFontSizeChange={this.handleFontSizeChange(handlers)}
        onHighlightColorChange={this.handleHighlightColorChange(handlers)}
        onJustifyChange={this.handleJustifyChange(handlers)}
        onSubmenuOpen={_.noop}
        onTextShadowChange={this.handleTextShadowChange(handlers)}
        onToggleBold={this.handleToggleBold(handlers, !bold)}
        onToggleItalic={this.handleToggleItalic(handlers, !italic)}
        onToggleUnderline={this.handleToggleUnderline(handlers, !underline)}
      />
    );
  }

  public render() {
    const { aspectRatio, fonts, text, textStyle } = this.props;
    const { ui } = this.state;
    const templateId = ui.get('selectedTemplateId');

    return (
      <RndTextEditor
        ref={this.textEditorRef}
        data={GlobalStyleModalBody.convertTemplateToEditorData(textStyle)}
        text={text}
        render={({ data, handlers, formatStyleRules }) => {
          const font = fonts.find(
            f => f.familyName === data.getIn(['containerStyle', 'familyName']),
          );
          return (
            <Modal.Row className={block('row')}>
              {font && (
                <FontLoader
                  family={data.getIn(['containerStyle', 'familyName'])}
                  style={data.getIn(['containerStyle', 'fontStyle'])}
                  url={font.url}
                  weight={data.getIn(['containerStyle', 'fontWeight'])}
                />
              )}
              <Modal.Col size={8} className={block('workspace-col')}>
                {this.renderTextToolbar(data, handlers)}
                <div className={block('workspace-container')}>
                  <Workspace
                    data={data}
                    handlers={handlers}
                    formatStyleRules={formatStyleRules}
                    text={text}
                    aspectRatio={aspectRatio}
                    draggingEnabled={getDraggingEnabledByTemplateId(templateId)}
                    resizing={ui.get('resizing')}
                    onTextBoxDrag={this.handleTextBoxDrag}
                    onTextBoxDragStop={this.handleTextBoxDragStop}
                    onTextBoxResizeStart={this.handleTextBoxResizeStart}
                    onTextBoxResizeStop={this.handleTextBoxResizeStop}
                    onWorkspaceSizeChange={this.handleWorkspaceSizeChange}
                    resizableEdges={getResizableEdgesByTemplateId(templateId)}
                  />
                </div>
              </Modal.Col>
              <Modal.Col size={4} className={block('style-col')}>
                <StyleTabs
                  data={data}
                  handlers={handlers}
                  renderTextTemplateForm={this.renderTextTemplateForm}
                  renderStyleForm={this.renderStyleForm}
                  renderTransitionForm={this.renderTransitionOptionsFormTab}
                />
              </Modal.Col>
            </Modal.Row>
          );
        }}
      />
    );
  }
}

export {
  GlobalStyleModalBody,
  IProps as GlobalStyleModalBodyProps,
  IFont as GlobalStyleFont,
};
