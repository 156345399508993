import * as React from 'react';

import { SelectField, SelectFieldProps } from 'components/Form';
import { getWaveOptionDisplayNameByValue } from 'redux/modules/display-pref/utils';
import { Omit, SoundwaveOption } from 'types';
import { soundwaveDimensionsToPosition } from 'utils/embed/soundwave';

type PositionValue = string | number;

interface IDimensions {
  left: PositionValue;
  height: PositionValue;
  width: PositionValue;
  top: PositionValue;
}

interface IProps
  extends Omit<
    SelectFieldProps,
    'label' | 'onChange' | 'value' | 'horizontal' | 'variant'
  > {
  onChange?: (value: string) => void;
  value: IDimensions;
  defaultValue?: Pick<IDimensions, 'left' | 'top'>;
  locked?: boolean;
  options?: SoundwaveOption[];
}

export default class WavePositionField extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    defaultValue: undefined,
    locked: false,
    options: [],
    value: {} as IDimensions,
  };

  private getSelectValue() {
    const { value } = this.props;
    return soundwaveDimensionsToPosition(value);
  }

  public render() {
    const { disabled, locked, options, defaultValue, onChange } = this.props;
    const commonProps = {
      disabled: disabled || locked,
      horizontal: {
        control: { xs: 7 },
        label: { xs: 5 },
      },
      label: 'Wave Position',
    };

    return (
      <SelectField
        {...commonProps}
        onChange={onChange}
        value={this.getSelectValue()}
        variant={locked ? 'locked' : 'default'}
        defaultValueText={
          defaultValue &&
          getWaveOptionDisplayNameByValue(
            options,
            soundwaveDimensionsToPosition(defaultValue),
          )
        }
      >
        {options.map((option: SoundwaveOption) => {
          if (option.value === 'custom') {
            return (
              <option key={option.value} value={option.value} hidden disabled>
                {option.displayName}
              </option>
            );
          }

          return (
            <option key={option.value} value={option.value}>
              {option.displayName}
            </option>
          );
        })}
      </SelectField>
    );
  }
}

export { IProps as WavePositionFieldProps, IDimensions as WaveDimensions };
