import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import Infobox from 'components/Infobox';
import { KeyTextType } from 'types';
import { textAssetModalBlock as block } from '../utils';

export interface DynamicTextInfoboxProps {
  assetType?: Exclude<KeyTextType, 'mainText'>;
}

const DynamicTextInfobox: React.FC<DynamicTextInfoboxProps> = ({
  assetType,
}) => (
  <Infobox
    className={block('infobox')}
    icon={
      <FontAwesome icon="microphone" style={{ height: 18, width: 'auto' }} />
    }
    theme="gradient"
  >
    This textbox will be automatically filled with the selected{' '}
    {assetType === 'podcastTitle'
      ? "podcast's title."
      : "podcast episode's title."}
  </Infobox>
);

export default DynamicTextInfobox;
