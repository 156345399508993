import * as React from 'react';

import { IconProps } from './types';

const WatermarkIcon: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="71"
    height="70"
    viewBox="0 0 71 70"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M4.99 70h61.25v-8.75H4.99V70zm52.5-35h-9.1a4.025 4.025 0 0 1-4.025-4.025V29.68c0-3.742 1.214-7.302 2.934-10.626 1.246-2.407 1.764-5.248 1.238-8.26-.926-5.302-5.26-9.666-10.563-10.59-8.29-1.442-15.484 4.898-15.484 12.92 0 1.935.427 3.765 1.188 5.41 1.823 3.945 3.187 8.084 3.187 12.43v.01A4.025 4.025 0 0 1 22.84 35h-9.1C6.491 35 .615 40.876.615 48.125V52.5a4.376 4.376 0 0 0 4.375 4.375h61.25a4.376 4.376 0 0 0 4.375-4.375v-4.375C70.615 40.875 64.74 35 57.49 35z"
    />
  </svg>
);

export default WatermarkIcon;
