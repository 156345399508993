import cn from 'classnames';
import * as React from 'react';

import FadingScrollBars from 'components/FadingScrollBars';
import AudioSection from './AudioSection';
import ClippingSection from './ClippingSection';
import TranscriptionSection from './TranscriptionSection';
import { block } from './utils';
import VideoSection from './VideoSection';

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

export default class VideoOptions extends React.Component<IProps> {
  public static AudioSection = AudioSection;
  public static ClippingSection = ClippingSection;
  public static TranscriptionSection = TranscriptionSection;
  public static VideoSection = VideoSection;

  public render() {
    const { children, className } = this.props;

    // TODO FadingScrollBars doesn't belong here.  parent should wrap it if it wants them
    return (
      <FadingScrollBars
        className={cn(block(), className)}
        hideTracksWhenNotNeeded
      >
        <form className={block('form')}>{children}</form>
      </FadingScrollBars>
    );
  }
}

export { IProps as VideoOptionsProps };
