import Immutable, { List } from 'immutable';
import { Type } from './action-types';
import { stateFactory } from './factories';
import { FontAction, FontState } from './types';

const defaultState = stateFactory();

export default function(state: FontState = defaultState, action: FontAction) {
  function failureState(errorMessage: string) {
    return state.merge({
      isLoading: false,
      isError: true,
      errorMessage,
      loadingText: undefined,
    });
  }

  function loadingState(loadingText: string) {
    return state.merge({
      isLoading: true,
      loadingText,
    });
  }

  switch (action.type) {
    case Type.PERSONAL_FONT_GET_REQUEST:
      return loadingState('Loading Fonts');

    case Type.PERSONAL_FONT_GET_SUCCESS: {
      return state.withMutations(s =>
        s.set('isLoading', false).set('loadingText', undefined),
      );
    }

    case Type.PERSONAL_FONT_GET_FAILURE: {
      const errorMessage = action.payload;
      return failureState(errorMessage);
    }

    case Type.FONT_UPLOAD_REQUEST: {
      return loadingState('Adding Fonts');
    }

    case Type.FONT_UPLOAD_SUCCESS: {
      return state.set('loadingText', undefined);
    }

    case Type.FONT_UPLOAD_FAILURE: {
      const errorMessage = action.payload;
      return failureState(errorMessage);
    }

    case Type.FONT_DELETE_REQUEST: {
      return loadingState('Deleting Font');
    }

    case Type.FONT_DELETE_FAILURE: {
      const errorMessage = action.payload;
      return failureState(errorMessage);
    }

    case Type.FONT_NAME_UPDATE_REQUEST: {
      return loadingState('Updating Font Name');
    }

    case Type.FONT_NAME_UPDATE_SUCCESS: {
      return state.set('loadingText', undefined);
    }

    case Type.FONT_NAME_UPDATE_FAILURE: {
      const errorMessage = action.payload;
      return failureState(errorMessage);
    }

    case Type.GOOGLE_FONT_GET_SUCCESS: {
      return state.withMutations(s =>
        s.set('isLoading', false).set('loadingText', undefined),
      );
    }

    case Type.GOOGLE_FONT_GET_FAILURE: {
      const errorMessage = action.payload;
      return failureState(errorMessage);
    }

    case Type.RECENT_FONT_ADD: {
      const font = action.payload;
      const recentFonts = state.get('recentFonts') ?? Immutable.List();
      let newRecentFonts: List<string>;

      if (recentFonts.includes(font)) {
        return state;
      }

      newRecentFonts = recentFonts.unshift(font);
      newRecentFonts = newRecentFonts.setSize(5);
      return state.set('recentFonts', newRecentFonts);
    }

    default:
      return state;
  }
}
