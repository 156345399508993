import * as React from 'react';

import {
  FacebookTemplate,
  InstagramTemplate,
  TwitterTemplate,
} from 'components/icons';
import { ExportPlatforms } from '../../types';
import { blockProjectTemplateIcons } from '../../utils';

interface IProps {
  icons: ExportPlatforms[];
}

const iconsMap = {
  [ExportPlatforms.INSTAGRAM]: <InstagramTemplate />,
  /* currently there's no instagramStory icon, but we might add one in the future */
  [ExportPlatforms.INSTAGRAM_STORY]: <InstagramTemplate />,
  [ExportPlatforms.FACEBOOK]: <FacebookTemplate />,
  [ExportPlatforms.TWITTER]: <TwitterTemplate />,
};

const ProjectTemplateIcons: React.SFC<IProps> = ({ icons }) =>
  icons.length > 0 && (
    <ul className={blockProjectTemplateIcons()}>
      {icons.map((icon: ExportPlatforms) => (
        <li key={icon} className={blockProjectTemplateIcons('icon')}>
          {iconsMap[icon]}
        </li>
      ))}
    </ul>
  );

export default ProjectTemplateIcons;
export { IProps as ProjectTemplateIconsProps };
