import * as emailValidator from 'email-validator';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'underscore';
import useCardField from 'components/Form/useCardField';
import useField from 'components/Form/useField';
import useStaticCallback from 'hooks/useStaticCallback';
import { showNotification } from 'redux/modules/notification/actions';
import { savePaymentMethod } from 'redux/modules/pricing/actions';

export default (onSaved: () => void) => {
  const [isSaving, setIsSaving] = useState(false);
  const email = useField();
  const { card, createToken, hasError, helperText } = useCardField();
  const dispatch = useDispatch();

  // form can only be submitted if a submission is not currently in progress
  // and the form values are valid
  const allowSubmit = !isSaving && email.value && card.value;

  const validate = () => {
    let valid = true;

    if (!email.value || !emailValidator.validate(email.value)) {
      valid = false;
      email.setError("This doesn't seem to be a valid email address");
    }

    return valid;
  };

  const handleSubmit = useStaticCallback(async () => {
    if (!validate()) {
      return;
    }

    try {
      setIsSaving(true);
      const token = await createToken();

      if (token) {
        await dispatch(savePaymentMethod({ stripeToken: token.id }));
        onSaved();
      }
    } catch (error) {
      dispatch(
        showNotification({
          message: 'Please try again or {{link}} so we can help',
          code: 'ER013',
          level: 'error',
          type: 'help',
          title: "Sorry, we couldn't update your account",
          actionLabel: 'contact us',
        }),
      );
    } finally {
      setIsSaving(false);
    }
  });

  const debouncedHandleSubmit = useMemo(
    () => debounce(handleSubmit, 500, true),
    [handleSubmit],
  );

  return {
    email,
    card,
    allowSubmit,
    hasError,
    helperText,
    onSubmit: debouncedHandleSubmit,
  };
};
