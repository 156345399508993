import * as React from 'react';
import ResizeDetector from 'react-resize-detector';
import { isUndefined, noop } from 'underscore';

import { Omit } from 'types';
import Track, { TrackProps } from '../../components/Track';
import { block } from '../../utils';
import AudioClipAsset from '../AudioClipAsset';

type PickedTrackProps = Omit<TrackProps, 'children' | 'className' | 'onMount'>;

export interface AudioTrackProps extends PickedTrackProps {
  assetIds: string[];
  onAssetClick?: (id: string) => void;
  pxPerSec: number;
}

interface State {
  height: number;
}

export default class AudioTrack extends React.Component<
  AudioTrackProps,
  State
> {
  public static defaultProps: Partial<AudioTrackProps> = {
    onAssetClick: noop,
  };

  public state: Readonly<State> = {
    height: undefined,
  };

  private handleResize = (_, height: number) => this.setState({ height });

  private handleAssetClick = (id: string) => () => this.props.onAssetClick(id);

  public render() {
    const { assetIds, id, pxPerSec, ...trackProps } = this.props;
    const { height } = this.state;

    return (
      <Track
        id={id}
        {...trackProps}
        className={block('track', { audio: true })}
      >
        <ResizeDetector handleHeight onResize={this.handleResize} />
        {!isUndefined(height) &&
          assetIds.map(assetId => (
            <AudioClipAsset
              height={height}
              id={assetId}
              onClick={this.handleAssetClick(assetId)}
              pxPerSec={pxPerSec}
              key={assetId}
            />
          ))}
      </Track>
    );
  }
}
