import classNames from 'classnames';
import * as React from 'react';

import { block } from './utils';

const ToolbarIcon: React.SFC = ({ children }) => {
  const child = React.Children.only(children) as React.ReactElement;
  return React.cloneElement(child, {
    className: classNames(block('icon'), child.props.className),
  });
};

export default ToolbarIcon;
