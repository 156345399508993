import cn from 'classnames';
import React from 'react';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import { block } from './utils';

export interface ModalProps {
  bodyClassName?: string;
  className?: string;
  children?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

interface ModalComponent extends React.FC<ModalProps> {
  Header: typeof ModalHeader;
  Footer: typeof ModalFooter;
}

const Modal: ModalComponent = ({
  bodyClassName,
  children,
  className,
  header,
  footer,
}) => (
  <div className={cn(block(), className)}>
    {header ?? <ModalHeader />}
    <ModalBody className={bodyClassName}>{children}</ModalBody>
    {footer ?? <ModalFooter />}
  </div>
);

Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;

export default Modal;
