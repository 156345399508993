import cn from 'classnames';
import * as React from 'react';

import { ClosedCaptioning } from 'components/icons';
import bem from 'utils/bem';
import ProjectButton from '../../containers/ProjectButton';
import { INewProjectButtonProps } from './types';

const block = bem('video-transcription-button');

const VideoTranscriptionButton: React.SFC<INewProjectButtonProps> = ({
  className,
  description,
  icon,
  title,
  ...rest
}) => (
  <ProjectButton
    className={cn(block(), className)}
    icon={icon}
    projectType="video-transcript"
    title={title}
    description={description}
    to={{
      pathname: '/wizard',
      query: {
        type: 'video-transcription',
      },
    }}
    {...rest}
  />
);

VideoTranscriptionButton.defaultProps = {
  description: 'Automatically transcribe your video’s audio into captions',
  icon: <ClosedCaptioning />,
  title: (
    <>
      Caption
      <br />a video
    </>
  ),
};

export default VideoTranscriptionButton;
