import bem from 'utils/bem';

export const selectorBlock = bem('clip-selector');
export const introBlock = bem('clip-select-intro');
export const pageBlock = bem('clip-select-page');
export const headerBlock = bem('clip-select-header');
export const ctaBlock = bem('clip-cta-button');
export const footerBlock = bem('clip-select-footer');
export const captionsModalBlock = bem('add-clip-captions-modal');
export const educationModalBlock = bem('clip-education-modal');
