import * as React from 'react';

import LoadingStep from 'containers/ProjectWizards/LoadingStep';
import { wizardBlock as block } from '../../utils';
import AutomationWorkflowWizard from '../AutomationWorkflowWizard';
import useDefaultData from './useDefaultData';
import WorkflowLimitGuard from './WorkflowLimitGuard';

export interface AutomationWorkflowWizardLoaderProps {}

const AutomationWorkflowWizardLoader: React.FC<AutomationWorkflowWizardLoaderProps> = () => {
  const { status, workflowId, props } = useDefaultData();

  return (
    <WorkflowLimitGuard intent={workflowId === undefined ? 'create' : 'edit'}>
      {status === 'loading' || status === 'idle' ? (
        <LoadingStep className={block('loader')} message="Loading" />
      ) : (
        <AutomationWorkflowWizard {...props} />
      )}
    </WorkflowLimitGuard>
  );
};

export default AutomationWorkflowWizardLoader;
