import * as React from 'react';

import useEpisodeAutogram from 'blocks/AutomationCMS/hooks/useEpisodeAutogram';
import useEpisodeProjects from 'blocks/AutomationCMS/hooks/useEpisodeProjects';
import usePodcastFeedEpisode from '../../../../hooks/usePodcastFeedEpisode';
import EpisodeListRow, {
  EpisodeListRowProps,
} from '../../components/EpisodeListRow';
import {
  episodeListBlock as block,
  getVideoCount,
  getWorkflowCount,
  hasVideos,
  isVideoPending,
} from '../../utils';
import EpisodeListEpisodeRowContext from './EpisodeListEpisodeRowContext';
import EpisodeListRowHeadline from './EpisodeListRowHeadline';
import EpisodeRowContents from './EpisodeRowContents';
import useProjectControlClick from './useProjectControlClick';

const { useCallback, useMemo, useState } = React;

type PickedRowProps = Pick<EpisodeListRowProps, 'active'>;

export interface EpisodeListEpisodeRowProps extends PickedRowProps {
  episodeId: string;
  onClick?: (episodeId: string) => void;
  workflows: number[];
}

const EpisodeListEpisodeRow: React.FC<EpisodeListEpisodeRowProps> = ({
  active,
  episodeId,
  onClick,
  workflows,
}) => {
  const [animating, setAnimating] = useState(false);
  const onProjectControlClick = useProjectControlClick();
  const episode = usePodcastFeedEpisode(episodeId);
  const { projects } = useEpisodeProjects(episodeId);
  const { createAutogramForEpisode } = useEpisodeAutogram();

  const workflowCount = getWorkflowCount(workflows);
  const videoPending = isVideoPending(episode);
  const videoCount = getVideoCount(projects, episode);

  const isEmpty = !hasVideos(projects, episode);
  const isDisabled = videoPending;

  const handleAnimationBegin = useCallback(() => setAnimating(true), []);
  const handleAnimationEnd = useCallback(() => setAnimating(false), []);

  const handleClick = () => {
    onClick(videoPending ? undefined : episodeId);
  };

  const handleCreateClick = () => {
    createAutogramForEpisode(episodeId, isEmpty, workflowCount);
  };

  const contextValue = useMemo(
    () => ({
      episode,
      projects,
      workflows,
    }),
    [episode, projects, workflows],
  );

  return (
    <EpisodeListEpisodeRowContext.Provider value={contextValue}>
      <EpisodeListRow
        active={active && videoCount > 0 && !videoPending}
        className={block('episode-row', { animating })}
        disabled={isDisabled}
        empty={isEmpty}
        onClick={handleClick}
      >
        <EpisodeListRowHeadline
          onAnimationBegin={handleAnimationBegin}
          onAnimationEnd={handleAnimationEnd}
          onCreateClick={handleCreateClick}
          active={active}
        />
        <EpisodeListRow.Contents>
          {({ entered }) => (
            <EpisodeRowContents
              entered={entered}
              episodeId={episodeId}
              onProjectControlClick={onProjectControlClick}
              projectsUrl={episode?.projectInfo?.projectListUrl}
            />
          )}
        </EpisodeListRow.Contents>
      </EpisodeListRow>
    </EpisodeListEpisodeRowContext.Provider>
  );
};

export default EpisodeListEpisodeRow;
