import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { LongWaveIcon, WaveIcon } from 'components/icons';
import Modal from 'components/Modal/v2';
import CTAButtonsModalContents, {
  CTAButton,
} from 'containers/CTAButtonsModalContents/CTAButtonsModalContents';
import {
  onClickAudiogramProject,
  onClickFullEpisode,
} from 'redux/modules/mixpanel';
import { hideModal } from 'redux/modules/modal/actions';

export interface ManualAudiogramProps {}

const ManualAudiogram: React.FC<ManualAudiogramProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleHide = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  const handleRedirect = (to: 'audiogram' | 'episode') => {
    handleHide();

    if (to === 'audiogram') {
      dispatch(onClickAudiogramProject());
    }

    if (to === 'episode') {
      dispatch(onClickFullEpisode());
    }

    history.push(`/wizard?type=${to}`);
  };

  const CTAButtons: CTAButton[] = [
    {
      id: 'upTo10Min',
      icon: <WaveIcon width={65} />,
      label: 'Up to 10 min',
      description:
        'Create eye-catching social videos featuring waveforms, captions, progress bars & much more!',
      action: () => handleRedirect('audiogram'),
    },
    {
      id: 'fullLength',
      icon: <LongWaveIcon width={75} />,
      label: 'Full-length',
      description:
        'Turn entire podcast episodes into waveform videos to post on YouTube, Vimeo, etc.',
      action: () => handleRedirect('episode'),
      variant: 'tapestry',
    },
  ];

  return (
    <Modal
      header={
        <Modal.Header
          onHide={handleHide}
          title="Select audiogram length"
          subtitle="How long would you like to make your video?"
        />
      }
    >
      <CTAButtonsModalContents buttons={CTAButtons} />
    </Modal>
  );
};

export default ManualAudiogram;
