import * as React from 'react';

import { Omit } from 'types';
import TextBoxSelectField, {
  TextBoxSelectFieldProps,
} from './TextBoxSelectField';

type Props = Omit<TextBoxSelectFieldProps, 'label' | 'onChange'> & {
  onChange?: (value: 'left' | 'center' | 'right') => void;
};

const TextAlignmentField: React.SFC<Props> = props => (
  <TextBoxSelectField label="Text Alignment" {...props}>
    <option key="left" value="left">
      Left
    </option>
    <option key="center" value="center">
      Center
    </option>
    <option key="right" value="right">
      Right
    </option>
  </TextBoxSelectField>
);

export default TextAlignmentField;
export { Props as TextAlignmentFieldProps };
