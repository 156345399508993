import { SoundwaveType } from 'types';
import createWaveTypeTile, { WaveTypeTile } from './createWaveTypeTile';

export const WaveTile = createWaveTypeTile({
  type: 'wave',
  style: {
    transform: 'scale(3, 2)',
  },
});

export const RoundBarsTile = createWaveTypeTile({
  type: 'roundBars',
  style: {
    marginLeft: 10,
    transform: 'scale(4, 2)',
  },
});

export const WideRoundBarsTile = createWaveTypeTile({
  type: 'wideRoundBars',
  style: {
    transform: 'scale(4, 2)',
  },
});

export const CurveTile = createWaveTypeTile({
  type: 'curve',
  style: {
    marginLeft: 10,
    transform: 'scale(4, 3)',
  },
});

export const PixelTile = createWaveTypeTile({
  type: 'pixel',
  style: {
    marginLeft: 5,
    transform: 'scale(4, 2)',
  },
});

export const LineTile = createWaveTypeTile({
  type: 'line',
  style: {
    transform: 'scale(4, 3)',
  },
});

export const BlobTile = createWaveTypeTile({
  type: 'blob',
  style: {
    color: 'black',
    transform: 'scale(0.9)',
  },
});

export const PulseTile = createWaveTypeTile({
  type: 'pulse',
  style: {
    transform: 'scale(0.9)',
  },
});

export const BurstTile = createWaveTypeTile({
  type: 'burst',
  style: {
    transform: 'scale(0.9)',
  },
});

export const DotsTile = createWaveTypeTile({
  type: 'linearDots',
  style: {
    transform: 'scale(2)',
  },
});

export const WaveBarsTile = createWaveTypeTile({
  type: 'waveBars',
  style: {
    transform: 'scale(5, 1.2)',
    marginLeft: 50,
    marginTop: 'auto',
  },
});

export const BricksTile = createWaveTypeTile({
  type: 'bricks',
  style: {
    marginBottom: 31,
    marginLeft: -36,
    transform: 'scale(4)',
  },
});

export const EqualizerTile = createWaveTypeTile({
  type: 'equalizer',
  style: {
    transform: 'scale(3)',
  },
});

export const NoneTile = createWaveTypeTile({
  type: 'none',
  style: {
    color: '#464e5e',
    transform: 'scale(0.6)',
  },
});

const tiles: Record<SoundwaveType, WaveTypeTile> = {
  blob: BlobTile,
  bricks: BricksTile,
  burst: BurstTile,
  curve: CurveTile,
  linearDots: DotsTile,
  equalizer: EqualizerTile,
  line: LineTile,
  none: NoneTile,
  pixel: PixelTile,
  pulse: PulseTile,
  roundBars: RoundBarsTile,
  wave: WaveTile,
  waveBars: WaveBarsTile,
  wideRoundBars: WideRoundBarsTile,
};

export default tiles;
