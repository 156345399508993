import React from 'react';

// types
import { IconProps } from './types';

const EmailOutput: React.FunctionComponent<IconProps> = ({ ...svgProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="70"
    viewBox="0 0 70 70"
    {...svgProps}
  >
    <defs>
      <filter
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        filterUnits="objectBoundingBox"
        id="1ce7l66rba"
      >
        <feGaussianBlur in="SourceGraphic" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M13 15h44v42H13z" />
      <path
        d="M59.5 0C65.299 0 70 4.701 70 10.5v49C70 65.299 65.299 70 59.5 70h-49C4.701 70 0 65.299 0 59.5v-49C0 4.701 4.701 0 10.5 0h49zM50 31.876c0-.293-.34-.467-.568-.281-1.313 1.042-3.047 2.372-9.024 6.81-1.19.886-3.316 2.869-5.408 2.851-2.08.012-4.166-1.929-5.402-2.851-5.977-4.438-7.717-5.762-9.03-6.804a.35.35 0 0 0-.568.281v12.243C20 45.712 21.26 47 22.813 47h24.375C48.74 47 50 45.712 50 44.125zM47.187 24H22.814C21.26 24 20 25.288 20 26.875v1.138c0 .443.2.857.54 1.132l.395.317.152.121.15.12.3.24.154.122c1.297 1.027 3.027 2.352 9.008 6.789.985.73 2.942 2.503 4.301 2.48 1.36.023 3.316-1.75 4.3-2.48 6.7-4.97 8.066-6.031 9.463-7.15l.15-.12.386-.309.162-.13c.34-.27.539-.689.539-1.132v-1.138C50 25.288 48.74 24 47.187 24z"
        fill="#323746"
        filter="url(#1ce7l66rba)"
      />
    </g>
  </svg>
);

export default EmailOutput;
