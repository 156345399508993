import Slider, { createSliderWithTooltip } from 'rc-slider';
import React from 'react';

import Tooltip from 'components/Tooltip';
import bem from 'utils/bem';

interface Props {
  icon?: React.ReactNode;
  label?: string;
  value?: number;
  disabled?: boolean;
  disabledTooltipText?: string;
  min?: number;
  max?: number;
  step?: number;
  onChange?(value: number): void;
}

const block = bem('range-input');

const SliderWithTooltip = createSliderWithTooltip(Slider);

function RangeInput(props: Props) {
  const {
    icon,
    label,
    value,
    disabled,
    disabledTooltipText,
    onChange,
    min,
    max,
    step,
  } = props;

  const Content = (
    <div className={block({ disabled })}>
      {icon}
      <div className={block('wrapper')}>
        {label && <label className={block('label')}>{label}</label>}
        <SliderWithTooltip
          className={block('slider')}
          onChange={onChange}
          value={value}
          disabled={disabled}
          min={min}
          max={max}
          step={step}
          tipFormatter={v => v * 10}
        />
      </div>
    </div>
  );

  if (disabled && disabledTooltipText) {
    return (
      <Tooltip
        animation={false}
        content={disabledTooltipText}
        id="range-input-tooltip"
        placement="top"
        preventHideOnHover={false}
      >
        {Content}
      </Tooltip>
    );
  }

  return Content;
}

export default RangeInput;
