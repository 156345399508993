import * as React from 'react';

import { Pixels } from 'utils/measurement';
import { stringToPx } from 'utils/placement';

import { useNavigation } from '../context/NavigationContext';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import useEditorLoading from '../useEditorLoading';
import useVideo from '../useVideo';
import { canvasBlock as block } from '../utils';
import RndAsset, { RndAssetProps } from './RndAsset';

const { useCallback, useEffect } = React;

type PickedAssetProps = Pick<
  RndAssetProps,
  'onDragStop' | 'onResizeStop' | 'params'
>;

export interface DraggableVideoAssetProps extends PickedAssetProps {
  id: string;
  autoplayVideo?: boolean;
  useDefaultVideoStyle?: boolean;
}

const VIDEO_COMPONENT_STYLE = {
  height: '100%',
  left: 0,
  top: 0,
  width: '100%',
};

const DraggableVideoAsset: React.FC<DraggableVideoAssetProps> = ({
  id,
  onDragStop,
  onResizeStop,
  params,
  autoplayVideo = true,
  useDefaultVideoStyle = true,
}) => {
  const dispatch = useTemplateDispatch();
  const [, setEditorLoading] = useEditorLoading();
  const [, send] = useNavigation();
  const { canvas } = useTemplateState();
  const videoClip = useVideo(id);
  const [currentVideoStyle, setCurrentVideoStyle] = React.useState({});

  const videoClipRef = React.useRef(videoClip);

  // when mounted, when canvas size changes or the asset id changes, canvas and
  // original video are meassured.
  // if video placement was not already set, once meassured, VIDEOCLIP_LOAD action
  // is dispatched for setting initial placement
  useEffect(() => {
    const currentVideoClip = videoClipRef.current;

    // if current videoclip was not found, no futher actions are required
    if (!currentVideoClip) {
      return;
    }

    // meassure and video load action should only be dispatched placement was not
    // previously set.
    if (!currentVideoClip.placement) {
      // turn loader on for avoiding video come from nowhere
      setEditorLoading(true);

      // meassure canvas and original video size/position
      const canvasPx = {
        height: new Pixels(canvas.height),
        width: new Pixels(canvas.width),
      };

      const sizePx = stringToPx(currentVideoClip.original.style, canvasPx);

      const position = {
        top: currentVideoClip.original.position.top,
        left: currentVideoClip.original.position.left,
      };
      const positionPx = stringToPx(position, canvasPx);

      setCurrentVideoStyle({
        ...sizePx,
        ...positionPx,
      });

      dispatch({
        type: 'VIDEOCLIP_LOAD',
        payload: {
          id: currentVideoClip.id,
          size: sizePx,
          position: positionPx,
        },
      });
    }
  }, [canvas.height, canvas.width, dispatch, id, setEditorLoading]);

  const handleVideoAssetLoaded = React.useCallback(() => {
    setEditorLoading(false);
  }, [setEditorLoading]);

  const handleMouseDown = useCallback(() => {
    send({
      type: 'CHILD_VIEW_OPEN',
      payload: 'image',
      meta: { source: 'preview', id },
    });
  }, [id, send]);

  const VideoComponent = (
    <video
      autoPlay={autoplayVideo}
      className={block('video-asset')}
      loop
      muted
      onCanPlay={handleVideoAssetLoaded}
      src={videoClip.videoUrl}
      style={useDefaultVideoStyle ? VIDEO_COMPONENT_STYLE : currentVideoStyle}
    />
  );

  return !videoClip.placement ? (
    VideoComponent
  ) : (
    <RndAsset
      {...{ onDragStop, onResizeStop, params }}
      {...videoClip.placement}
      assetId={id}
      onMouseDown={handleMouseDown}
    >
      {VideoComponent}
    </RndAsset>
  );
};

export default DraggableVideoAsset;
