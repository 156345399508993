import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  standardizedUrlSelector,
  waitingForStandardizationSelector,
} from 'redux/modules/async-audio-clipper/selectors';
import { getDuration } from 'utils/audio';
import { isFileLike } from 'utils/file';

const DURATION_THRESHOLD_SEC = 5;

export interface UseAudioUrlConfig {
  estimatedDurationSec?: number;
  proxySrc?: string;
  src?: string | Blob;
}

const useAudioUrl = (options: UseAudioUrlConfig) => {
  const { estimatedDurationSec, proxySrc, src } = options;
  const [audioSrc, setAudioSrc] = useState(undefined);
  const standardizedUrl = useSelector(standardizedUrlSelector);
  const waitingForStandardization = useSelector(
    waitingForStandardizationSelector,
  );

  useEffect(() => {
    let cancelled = false;

    if (!proxySrc || !src || isFileLike(src)) {
      setAudioSrc(src);
    } else {
      setAudioSrc(undefined);
      getDuration(src, durationSec => {
        if (!cancelled) {
          const effectiveEstimatedDurationSec = estimatedDurationSec || 0;
          const diff = Math.abs(effectiveEstimatedDurationSec - durationSec);
          setAudioSrc(diff > DURATION_THRESHOLD_SEC ? proxySrc : src);
        }
      });
    }

    return () => {
      cancelled = true;
    };
  }, [estimatedDurationSec, proxySrc, src]);

  return waitingForStandardization ? undefined : standardizedUrl ?? audioSrc;
};

export default useAudioUrl;
