import { Record } from 'immutable';
import { AnyObject } from 'types';
import { getValue } from 'utils/collections';
import { reviveMap, reviveRecord } from 'utils/immutable';
import { State } from './types';

export const stateFactory = Record<State>({
  isRefreshingPodcastFeed: false,
});

export function stateFromJs(obj: AnyObject) {
  return reviveMap((value, key) => {
    const data = getValue(obj, [key, 'data']);

    return reviveRecord(stateFactory, value, { data });
  }, obj);
}
