import { Dialog, ThemeProvider } from '@sparemin/blockhead';

import {
  GOOGLE_DRIVE_PLUGIN_ID,
  GOOGLE_DRIVE_TAB_KEY,
  MediaImportedEvent,
  MediaImporter,
  MediaRejectionReason,
  useGoogleDrivePlugin,
  useYoutubeUrlPlugin,
  useZoomPlugin,
  YOUTUBE_URL_PLUGIN_ID,
  YOUTUBE_URL_TAB_KEY,
  ZOOM_PLUGIN_ID,
  ZOOM_TAB_KEY,
} from '@sparemin/media-import';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import { showError } from 'redux/modules/notification';
import { videoUploadLimitMbSelector } from 'redux/modules/pricing';
import { Dispatch } from 'redux/types';
import { megabytesToBytes } from 'utils/file';
import { getSupportedVideoMimeTypes } from 'utils/formats';
import {
  block,
  buildImportedFileUrl,
  fetchImportedFilePreviewUrl,
} from './utils';

export interface MediaUploadModalContentsProps {}

const MediaUploadModalContents: React.FC<MediaUploadModalContentsProps> = () => {
  const [isLoadingFile, setIsLoadingFile] = useState(false);

  const uploadLimitMb = useSelector(videoUploadLimitMbSelector);

  const { params, onHide } = useConnectedModal('MediaUpload');

  const dispatch = useDispatch<Dispatch>();

  const { mediaImportOption } = params || {};

  const handleMediaImported = useCallback((): void => {
    setIsLoadingFile(true);
  }, []);

  const handleMediaAccepted = useCallback(
    async (args: MediaImportedEvent): Promise<void> => {
      const { payload, pluginId } = args;

      switch (pluginId) {
        case GOOGLE_DRIVE_PLUGIN_ID: {
          const {
            url,
            params: downloadInfoParams,
            headers,
          } = payload.file.downloadInfo;

          const previewUrl = await fetchImportedFilePreviewUrl(
            url,
            downloadInfoParams,
            headers,
          );

          return onHide({
            provider: 'googleDrive',
            providerUserId: payload.user.providerUserId,
            previewUrl,
            googleDriveFileId: payload.file.id,
          });
        }

        case ZOOM_PLUGIN_ID: {
          const { downloadInfo, id, meetingId } = payload.recording;
          const { url, params: downloadInfoParams } = downloadInfo;

          const previewUrl = buildImportedFileUrl(url, downloadInfoParams);

          return onHide({
            provider: 'zoom',
            providerUserId: payload.user.providerUserId,
            previewUrl,
            zoomMeetingId: meetingId,
            zoomRecordingFileId: id,
          });
        }

        case YOUTUBE_URL_PLUGIN_ID: {
          const { url, previewVideoUrl } = payload;

          return onHide({
            provider: 'youtube',
            previewUrl: previewVideoUrl,
            youtubeUrl: url,
          });
        }

        default: {
          return undefined;
        }
      }
    },
    [onHide],
  );

  const handleMediaRejected = useCallback(
    (reason: MediaRejectionReason) => {
      setIsLoadingFile(false);

      let message = '';

      switch (reason) {
        case 'WRONG_TYPE': {
          message =
            'Unfortunately, the uploaded file is not a supported file type';
          break;
        }

        case 'TOO_LARGE': {
          message = `Upload size exceeds ${uploadLimitMb / 1000}GB`;
          break;
        }

        case 'TOO_LONG': {
          message = 'Upload length exceeds 1 hour max';
          break;
        }

        default: {
          message = 'Something went wrong! Please try again.';
          break;
        }
      }

      dispatch(showError(message));
    },
    [dispatch, uploadLimitMb],
  );

  return (
    <ThemeProvider theme="dark">
      <div className={block('contents')}>
        <Dialog.CloseButton
          onPress={() => onHide()}
          className={block('close-button')}
        />

        <MediaImporter
          isDisabled={isLoadingFile}
          defaultSelectedTabKey={
            {
              googleDrive: GOOGLE_DRIVE_TAB_KEY,
              zoom: ZOOM_TAB_KEY,
              youtube: YOUTUBE_URL_TAB_KEY,
            }[mediaImportOption]
          }
          plugins={[
            useGoogleDrivePlugin(),
            useZoomPlugin(),
            useYoutubeUrlPlugin(),
          ]}
          accept={getSupportedVideoMimeTypes()}
          maxDurationMillis={60 * 60 * 1000} // One hour
          maxSizeBytes={megabytesToBytes(uploadLimitMb)}
          onMediaImported={handleMediaImported}
          onMediaAccepted={handleMediaAccepted}
          onMediaRejected={rejection =>
            handleMediaRejected(rejection.payload.reason)
          }
        />
      </div>
    </ThemeProvider>
  );
};

export default MediaUploadModalContents;
