import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'IMAGE_SERVICE';
export const ACTION_KEY: ActionKey = 'IMAGE_SERVICE';

export enum ServiceMethod {
  UPLOAD_IMAGE = 'UPLOAD_IMAGE',
  UPLOAD_IMAGE_BLOB = 'UPLOAD_IMAGE_BLOB',
  GET_DATA_URL = 'GET_DATA_URL',
}

export type UploadImageArgs = [string];

export type UploadImageBlobArgs = [Blob];
export interface UploadImageResult {
  entities: {
    images: {
      [id: number]: {
        id: number;
        url: string;
      };
    };
  };
  result: number;
}

export type GetDataUrlArgs = [string, number, number];
export interface GetDataUrlResult {
  dataUrl: string;
}

export type GetExternalImageArgs = [string];
export type GetExternalImageResult = Blob;

export type IHandle = (
  method: ServiceMethod,
  args: any,
  token?: string,
) => Promise<any>;

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export type UploadImageAction = ApiAction<
  ServiceMethod.UPLOAD_IMAGE,
  UploadImageArgs
>;
export type UploadImageBlobAction = ApiAction<
  ServiceMethod.UPLOAD_IMAGE_BLOB,
  UploadImageBlobArgs
>;
export type GetDataUrlAction = ApiAction<
  ServiceMethod.GET_DATA_URL,
  GetDataUrlArgs
>;

export interface ImageServiceDispatch {
  (action: UploadImageAction | UploadImageBlobAction): Promise<
    IApiResponse<UploadImageResult>
  >;
  (action: GetDataUrlAction): Promise<IApiResponse<GetDataUrlResult>>;
}
