import { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { FacebookPostType } from 'redux/middleware/api/podcast-service';
import { facebookPagesLoadingSelector } from 'redux/modules/social';
import useFacebookPostModalSelector from '../../useFacebookPostModalSelector';
import {
  UseAutopostingComposerStep,
  UseAutopostingComposerStepConfig,
} from './types';
import usePostComposerStep from './usePostComposerStep';

const useAutopostingComposerStep = (
  config: UseAutopostingComposerStepConfig,
): UseAutopostingComposerStep => {
  const { activeStep, initialValues, onHideModal, onSubmit } = config;

  const isActive = activeStep === 'composer';

  const {
    defaultDescription,
    defaultPostType,
    defaultUserId,
    pages,
  } = useFacebookPostModalSelector({
    defaultDescription: initialValues?.description,
    defaultPostType: initialValues?.postType,
    defaultFacebookPageId: initialValues?.facebookPageId,
  });

  const isLoading = useSelector(facebookPagesLoadingSelector);

  const [prevActiveState, setPrevActiveState] = useState<boolean>(isActive);
  const [prevInitialValues, setPrevInitialValues] = useState(initialValues);
  const [prevIsLoading, setPrevIsLoading] = useState(isLoading);
  const [description, setDescription] = useState<string>(defaultDescription);
  const [facebookPageId, setFacebookPageId] = useState<string>(defaultUserId);
  const [postType, setPostType] = useState<FacebookPostType>(defaultPostType);

  const handleSetDefaultProps = useCallback(() => {
    setDescription(defaultDescription);
    setPostType(defaultPostType);
    setFacebookPageId(defaultUserId);
  }, [defaultDescription, defaultPostType, defaultUserId]);

  const handleSubmit = useCallback((): void => {
    onSubmit?.({
      description,
      postType,
    });

    handleSetDefaultProps();
  }, [onSubmit, description, postType, handleSetDefaultProps]);

  const postComposer = usePostComposerStep({
    allowSubmit: Boolean(description),
    description,
    facebookPageId: facebookPageId ?? defaultUserId,
    pages: isLoading ? [] : pages,
    onDescriptionChange: setDescription,
    onPageChange: setFacebookPageId,
    onHideModal,
    onSubmit: handleSubmit,
  });

  if (prevIsLoading && !isLoading && !facebookPageId && Boolean(pages.length)) {
    setFacebookPageId(defaultUserId);
    setPrevIsLoading(isLoading);
  }

  if (prevActiveState !== isActive) {
    handleSetDefaultProps();
    setPrevActiveState(isActive);
  }

  if (!isEqual(prevInitialValues, initialValues)) {
    handleSetDefaultProps();
    setPrevInitialValues(initialValues);
  }

  return postComposer;
};

export default useAutopostingComposerStep;
