import cn from 'classnames';
import * as React from 'react';
import {
  renderThumbHorizontalDefault,
  renderThumbVerticalDefault,
  renderTrackHorizontalDefault,
  renderTrackVerticalDefault,
} from 'react-custom-scrollbars/lib/Scrollbars/defaultRenderElements';
import { isUndefined, noop } from 'underscore';

import ScrollBars, {
  positionValues,
  ScrollBarsProps,
} from 'components/ScrollBars';
import bem from 'utils/bem';

const block = bem('fading-scrollbars');

interface IProps extends ScrollBarsProps {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  faderClassName?: string;
  horizontal?: boolean;
  scrollBarsRef?: (el: ScrollBars) => void;
  sideFaderClassName?: string;
}

const { useCallback, useState } = React;

const FadingScrollBars: React.FC<IProps> = ({
  children,
  className,
  disabled,
  horizontal,
  renderThumbHorizontal,
  renderThumbVertical,
  renderTrackHorizontal,
  renderTrackVertical,
  scrollBarsRef,
  faderClassName: faderClassNameProp,
  sideFaderClassName,
  ...rest
}) => {
  const { onScrollFrame, onUpdate } = rest;

  const [animating, setAnimating] = useState({
    bottom: false,
    left: false,
    right: false,
    top: false,
  });
  const [showScrollFader, setShowScrollFader] = useState({
    bottom: undefined,
    left: undefined,
    right: undefined,
    top: undefined,
  });

  const renderDisabledTrackHorizontal = ({ style, ...props }) =>
    renderTrackHorizontalDefault({
      ...props,
      style: {
        ...style,
        display: 'none',
      },
    });

  const renderDisabledTrackVertical = ({ style, ...props }) =>
    renderTrackVerticalDefault({
      ...props,
      style: {
        ...style,
        display: 'none',
      },
    });

  const renderDisabledThumbHorizontal = ({ style, ...props }) =>
    renderThumbHorizontalDefault({
      ...props,
      style: {
        ...style,
        display: 'none',
      },
    });

  const renderDisabledThumbVertical = ({ style, ...props }) =>
    renderThumbVerticalDefault({
      ...props,
      style: {
        ...style,
        display: 'none',
      },
    });

  const handleScrollFrame = useCallback(
    (args: positionValues) => {
      const { left, top } = args;
      const newShowScrollFader = { ...showScrollFader };
      const newAnimating = { ...animating };

      if (!horizontal) {
        const showBottom = showScrollFader.bottom;
        const showTop = showScrollFader.top;

        // if (top === 1 && showBottom) {
        //   newShowScrollFader.bottom = false;
        //   newShowScrollFader.top = true;
        //   newAnimating.bottom = true;
        // } else if (top < 1 && !showBottom) {
        //   newShowScrollFader.bottom = true;
        // }
        if (top === 0 && showTop) {
          newShowScrollFader.top = false;
          newShowScrollFader.bottom = true;
          newAnimating.top = true;
        } else if (top >= 1 && showBottom) {
          newShowScrollFader.top = true;
          newShowScrollFader.bottom = false;
          newAnimating.bottom = true;
        } else if (top < 1 && top > 0 && !showTop) {
          newShowScrollFader.top = true;
        } else if (top < 1 && top > 0 && !showBottom) {
          newShowScrollFader.bottom = true;
        }
      } else {
        const showLeft = showScrollFader.left;
        const showRight = showScrollFader.right;

        if (left === 0 && showLeft) {
          newShowScrollFader.left = false;
          newShowScrollFader.right = true;
          newAnimating.left = true;
        } else if (left >= 1 && showRight) {
          newShowScrollFader.left = true;
          newShowScrollFader.right = false;
          newAnimating.right = true;
        } else if (left < 1 && left > 0 && !showLeft) {
          newShowScrollFader.left = true;
        } else if (left < 1 && left > 0 && !showRight) {
          newShowScrollFader.right = true;
        }
      }

      setShowScrollFader(newShowScrollFader);
      setAnimating(newAnimating);

      onScrollFrame(args);
    },
    [
      animating,
      horizontal,
      onScrollFrame,
      setAnimating,
      setShowScrollFader,
      showScrollFader,
    ],
  );

  const handleUpdate = useCallback(
    (args: positionValues) => {
      if (!horizontal) {
        const { clientHeight, scrollHeight } = args;

        if (clientHeight === 0 && scrollHeight === 0) return;
        setShowScrollFader({
          ...showScrollFader,
          bottom: scrollHeight > clientHeight,
        });
      } else {
        const { clientWidth, scrollWidth } = args;

        if (clientWidth === 0 && scrollWidth === 0) return;

        setShowScrollFader({
          ...showScrollFader,
          right: scrollWidth > clientWidth,
        });
      }

      onUpdate(args);
    },
    [horizontal, onUpdate, setShowScrollFader, showScrollFader],
  );

  const handleAnimationEnd = useCallback(
    (position: 'bottom' | 'left' | 'right' | 'top') => {
      const top = position === 'top' ? false : showScrollFader.top;
      const bottom = position === 'bottom' ? false : showScrollFader.bottom;
      const left = position === 'left' ? false : showScrollFader.left;
      const right = position === 'right' ? false : showScrollFader.right;

      setShowScrollFader({ bottom, left, right, top });
    },
    [setShowScrollFader, showScrollFader],
  );

  const handleAnimationEndBottom = useCallback(() => {
    handleAnimationEnd('bottom');
  }, [handleAnimationEnd]);
  const handleAnimationEndLeft = useCallback(() => {
    handleAnimationEnd('left');
  }, [handleAnimationEnd]);
  const handleAnimationEndRight = useCallback(() => {
    handleAnimationEnd('right');
  }, [handleAnimationEnd]);
  const handleAnimationEndTop = useCallback(() => {
    handleAnimationEnd('top');
  }, [handleAnimationEnd]);

  const updateFn = isUndefined(showScrollFader.bottom)
    ? handleUpdate
    : undefined;
  const updateFnHorizontal = isUndefined(showScrollFader.right)
    ? handleUpdate
    : undefined;

  return (
    <div className={cn(block(), className)}>
      <ScrollBars
        {...rest}
        onScrollFrame={handleScrollFrame}
        ref={scrollBarsRef}
        onUpdate={horizontal ? updateFnHorizontal : updateFn}
        renderTrackHorizontal={
          disabled ? renderDisabledTrackHorizontal : renderTrackHorizontal
        }
        renderTrackVertical={
          disabled ? renderDisabledTrackVertical : renderTrackVertical
        }
        renderThumbHorizontal={
          disabled ? renderDisabledThumbHorizontal : renderThumbHorizontal
        }
        renderThumbVertical={
          disabled ? renderDisabledThumbVertical : renderThumbVertical
        }
      >
        {children}
      </ScrollBars>

      {!disabled && !horizontal && (
        <>
          <div
            className={cn(
              block('fader-vertical', {
                hidden: !showScrollFader.top && !animating.top,
                hide: !showScrollFader.top && animating.top,
                top: true,
              }),
              faderClassNameProp,
            )}
            onAnimationEnd={handleAnimationEndTop}
          />

          <div
            className={cn(
              block('fader-vertical', {
                hidden: !showScrollFader.bottom && !animating.bottom,
                hide: !showScrollFader.bottom && animating.bottom,
                bottom: true,
              }),
              faderClassNameProp,
            )}
            onAnimationEnd={handleAnimationEndBottom}
          />
        </>
      )}

      {!disabled && horizontal && (
        <>
          <div
            className={cn(
              block('fader-side', {
                hidden: !showScrollFader.left && !animating.left,
                hide: !showScrollFader.left && animating.left,
                left: true,
              }),
              sideFaderClassName,
            )}
            onAnimationEnd={handleAnimationEndLeft}
          />
          <div
            className={cn(
              block('fader-side', {
                hidden: !showScrollFader.right && !animating.right,
                hide: !showScrollFader.right && animating.right,
                right: true,
              }),
              sideFaderClassName,
            )}
            onAnimationEnd={handleAnimationEndRight}
          />
        </>
      )}
    </div>
  );
};

FadingScrollBars.defaultProps = {
  disabled: false,
  horizontal: false,
  onScrollFrame: noop,
  onUpdate: noop,
  scrollBarsRef: noop,
};

export default FadingScrollBars;
