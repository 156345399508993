import * as actions from './actions';
import { ACTION_KEY, ServiceMethod } from './constants';
import { handle } from './service';

export * from './types';
export { actions };

export default {
  handle,
  ActionKey: ACTION_KEY,
  Method: ServiceMethod,
};
