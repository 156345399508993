import * as React from 'react';

import WrappedSlider, { WrappedSliderProps } from 'components/WrappedSlider';
import FormField, { FormFieldProps } from './FormField';
import { block } from './utils';

type Props = FormFieldProps<WrappedSliderProps>;

const SliderField: React.SFC<Props> = ({ ...props }) => (
  <FormField<WrappedSliderProps> {...props}>
    <WrappedSlider className={block('slider-input')} />
  </FormField>
);

export default SliderField;
export { Props as SliderFieldProps };
