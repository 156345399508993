import Immutable from 'immutable';
import { getValue } from 'utils/collections';
import * as types from './action-types';

const defaultState = Immutable.Map();

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.AUTEHNTICATE_SOCIAL_REQUEST:
      return state.set('socialAuthInProgress', true);

    case types.AUTHENTICATE_SOCIAL_FAILURE:
    case types.AUTHENTICATE_SOCIAL_SUCCESS:
      return state.set('socialAuthInProgress', false);

    case types.AUTHENTICATE_SUCCESS: {
      const { token, userId } = action.payload;
      return state.withMutations(s =>
        s.set('userId', userId).set('token', token),
      );
    }

    case types.LOGIN_REQUEST:
      return state.withMutations(s =>
        s
          .set('action', 'login')
          .set('inProgress', true)
          .set('error', false)
          .set('errorMessage', ''),
      );

    case types.LOGIN_SUCCESS: {
      const {
        authenticationProvider,
        fullName,
        handle,
        token,
        userId,
        username,
      } = action.payload;
      return state.withMutations(s =>
        s
          .set('authenticationProvider', authenticationProvider)
          .set('inProgress', false)
          .set('name', fullName)
          .set('username', username)
          .set('token', token)
          .set('userId', userId)
          .set('handle', handle)
          .set('errorMessage', ''),
      );
    }

    case types.LOGIN_FAILURE: {
      return state.withMutations(s =>
        s
          .set('inProgress', false)
          .set('error', true)
          .set(
            'errorMessage',
            getValue(action, ['payload', 'errorMessage'], ''),
          ),
      );
    }

    case types.MY_PROFILE_GET_SUCCESS: {
      const { fullName, handle, username, userId } = action.payload;
      return state.withMutations(s => {
        s.set('handle', handle);
        s.set('name', fullName);
        s.set('userId', userId);
        s.set('username', username);
      });
    }

    case types.RENEW_SUCCESS:
      return state.set('token', action.payload.token);

    case types.RENEW_FAILURE:
      // In a case where we can't renew the token, just treat this as a logout
      return defaultState;

    case types.USER_LOGOUT:
      return defaultState;

    case types.REGISTER_REQUEST:
      return state.withMutations(s =>
        s
          .set('action', 'register')
          .set('inProgress', true)
          .set('error', false),
      );

    case types.REGISTER_SUCCESS: {
      const { authenticationProvider, handle, name } = action.payload;
      return state.withMutations(s =>
        s
          .set('authenticationProvider', authenticationProvider)
          .set('handle', handle)
          .set('name', name)
          .set('inProgress', false),
      );
    }

    case types.REGISTER_FAILURE:
      return state.withMutations(s =>
        s
          .set('inProgress', false)
          .set('error', true)
          .set('errorMessage', action.payload.errorMessage),
      );

    case types.ACCOUNT_CREATE_SUCCESS: {
      const { token, userId, username } = action.payload;
      return state.withMutations(s =>
        s
          .set('token', token)
          .set('userId', userId)
          .set('username', username),
      );
    }

    case types.FORGOT_PASSWORD_REQUEST:
      return state.withMutations(s =>
        s
          .set('action', 'forgotPassword')
          .set('inProgress', true)
          .set('errorMessage', '')
          .set('error', false)
          .set('passwordRequestSuccess', false),
      );

    case types.FORGOT_PASSWORD_SUCCESS:
      return state.withMutations(s =>
        s
          .set('inProgress', false)
          .set('errorMessage', '')
          .set('error', false)
          .set('passwordRequestSuccess', true),
      );

    case types.FORGOT_PASSWORD_FAILURE:
      return state.withMutations(s =>
        s
          .set('inProgress', false)
          .set('errorMessage', action.payload.errorMessage)
          .set('error', true)
          .set('passwordRequestSuccess', false),
      );

    case types.PASSWORD_UPDATE_REQUEST:
      return state.withMutations(s =>
        s
          .set('action', 'updatePassword')
          .delete('error')
          .delete('errorMessage')
          .set('inProgress', true),
      );

    case types.PASSWORD_UPDATE_SUCCESS:
      return state.set('inProgress', false);

    case types.PASSWORD_UPDATE_FAILURE: {
      const { errorMessage } = action.payload;
      return state.withMutations(s =>
        s
          .set('inProgress', false)
          .set('error', true)
          .set('errorMessage', errorMessage),
      );
    }

    case types.PASSWORD_STATUS_CLEAR:
      return state.withMutations(s => s.delete('error').delete('errorMessage'));

    case types.CONFIRM_EMAIL_REQUEST:
      return state.set('confirmEmail', 'inProgress');

    case types.CONFIRM_EMAIL_SUCCESS:
      return state.withMutations(s =>
        s.set('confirmEmail', 'success').set('token', action.payload.token),
      );

    case types.CONFIRM_EMAIL_FAILURE:
      return state.set('confirmEmail', 'failure');

    case types.AUTH_GET_INTEGRATORS_SUCCESS: {
      const { integrators } = action.payload;
      return state.set('integrators', integrators);
    }

    case types.EMAIL_UPDATE_REQUEST: {
      return state.withMutations(s =>
        s
          .set('action', 'updateEmail')
          .delete('error')
          .delete('errorMessage')
          .set('inProgress', true),
      );
    }

    case types.EMAIL_UPDATE_SUCCESS: {
      const { email } = action.payload;
      return state.withMutations(s =>
        s.set('username', email).set('inProgress', false),
      );
    }

    case types.EMAIL_UPDATE_FAILURE: {
      return state.withMutations(s =>
        s.set('inProgress', false).set('error', true),
      );
    }

    case types.RESEND_VERIFICATION_EMAIL_REQUEST: {
      return state.withMutations(s =>
        s
          .set('action', 'resendEmail')
          .delete('error')
          .delete('errorMessage')
          .set('inProgress', true),
      );
    }

    case types.RESEND_VERIFICATION_EMAIL_SUCCESS: {
      return state.withMutations(s => s.set('inProgress', false));
    }

    case types.RESEND_VERIFICATION_EMAIL_FAILURE: {
      return state.withMutations(s =>
        s.set('inProgress', false).set('error', true),
      );
    }

    default:
      return state;
  }
}
