import { Tier } from 'redux/middleware/api/plan-service';

export interface WelcomeStepProps {
  title?: string;
  onNextClick?: (tier?: Tier) => void;
}

export enum WelcomeType {
  BASIC = 'BASIC',
  DEFAULT = 'DEFAULT',
  PRO = 'PRO',
  TRIAL = 'TRIAL',
  UNLIMITED = 'UNLIMITED',
}
