import * as ids from 'short-id';

import { PodcastTemplateConfig } from 'redux/middleware/api/podcast-service';
import { canDeleteTrack } from 'utils/embed/tracks';
import { changeElementPositionInArray } from 'utils/list';
import {
  Layer,
  LayerMoveOption,
  LayerState,
  VideoTemplateStateContent,
} from '../types';
import { getActiveLayers } from '../utils';

// template gets passed in with an array describing the layer order, e.g.
// ['text', 'text', 'media', 'waveform'], etc.  Assets in the template reference
// these indexes.  This function replaces each layer string with an id.
//
// the configuration will also contain audio layers, which are not handled by the
// VideoTemplateEditor and so are filtered out
export function identifyLayers(config: PodcastTemplateConfig): Layer[] {
  const { layerOrder } = config;

  return layerOrder.reduce((acc, layer, i, ary) => {
    // TODO waveform doesn't need a special id
    if (layer === 'waveform') {
      const existingWaveformLayer = acc.find(l => l.type === 'waveform');

      if (existingWaveformLayer) {
        throw new Error(
          'Invalid layer order - found multiple "waveform" layers',
        );
      } else {
        acc.push({ id: 'waveform', type: layer });
      }
    } else if (layer !== 'audio') {
      acc.push({ id: ids.generate(), type: layer });
    } else {
      const nextLayer = ary[i + 1];

      if (nextLayer !== 'audio' && i + 1 < ary.length) {
        throw new Error(
          'Invalid layer order - found "audio" layer that is not at the end of the layer list',
        );
      }
    }

    return acc;
  }, []);
}

export function buildLayerState(layers: Layer[]): LayerState {
  const data = layers.reduce((acc, layer) => {
    acc[layer.id] = layer;
    return acc;
  }, {});

  const order = layers.map(l => l.id);

  return { data, order };
}

export function canDeleteLayer(
  { layers, originalLayers, template }: VideoTemplateStateContent,
  layerId: string,
): boolean {
  return (
    canDeleteTrack(layers.data, layerId) &&
    (!template || !originalLayers.includes(layerId))
  );
}

function findNextIndexForward(
  originalLayers: string[],
  activeLayers: string[],
  startIndex: number,
): number {
  const reversedLayers = originalLayers.slice(0, startIndex).reverse();
  const nextIndex = reversedLayers.findIndex(layer =>
    activeLayers.includes(layer),
  );

  return nextIndex !== -1 ? startIndex - nextIndex - 1 : 0;
}

function findNextIndexBackward(
  originalLayers: string[],
  activeLayers: string[],
  startIndex: number,
): number {
  const nextIndex = originalLayers
    .slice(startIndex + 1)
    .findIndex(layer => activeLayers.includes(layer));

  return nextIndex !== -1
    ? nextIndex + startIndex + 1
    : originalLayers.length - 1;
}

function getNewLayerIndex(
  state: VideoTemplateStateContent,
  layerIndex: number,
  moveTo: LayerMoveOption,
): number {
  const originalLayers = state.layers.order;
  const activeLayers = getActiveLayers(state);

  switch (moveTo) {
    case 'front': {
      return 0;
    }

    case 'forward': {
      return findNextIndexForward(originalLayers, activeLayers, layerIndex);
    }

    case 'backward': {
      return findNextIndexBackward(originalLayers, activeLayers, layerIndex);
    }

    case 'back': {
      return originalLayers.length - 1;
    }

    default: {
      return layerIndex;
    }
  }
}

export function moveLayer(
  state: VideoTemplateStateContent,
  layerId: string,
  moveTo: LayerMoveOption,
): string[] {
  const originalLayers = state?.layers?.order;
  const currentLayerIndex = originalLayers.indexOf(layerId);
  const destinationIndex = getNewLayerIndex(state, currentLayerIndex, moveTo);

  return changeElementPositionInArray(
    originalLayers,
    currentLayerIndex,
    destinationIndex,
  );
}
