import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { pushModal } from 'redux/modules/modal/actions';
import { showSuccess } from 'redux/modules/notification/actions';
import { copyProject, getProjectHistory } from 'redux/modules/project/actions';
import { Dispatch } from 'redux/types';

import { EpisodeRowContentsProps } from '../../types';

export default function useProjectControlClick() {
  const dispatch = useDispatch<Dispatch>();

  return useCallback(
    async (type, id) => {
      switch (type) {
        case 'copy': {
          const copyResult = await dispatch(
            pushModal({
              name: 'ProjectCopy',
              params: { projectId: id },
            }),
          );

          if (copyResult) {
            const { aspectRatio, projectName } = copyResult;
            await dispatch(copyProject(id, projectName, aspectRatio, false));
            dispatch(getProjectHistory(false));

            dispatch(
              showSuccess({
                message: null,
                title: 'Your project was copied successfully!',
                dismissAfterSec: 5,
              }),
            );
          }

          break;
        }

        case 'delete': {
          dispatch(
            pushModal({
              name: 'ProjectDelete',
              params: { projectId: id },
            }),
          );
          break;
        }

        default:
      }
    },
    [dispatch],
  ) as EpisodeRowContentsProps['onProjectControlClick'];
}
