import { Record, RecordOf } from 'immutable';
import { reviveRecord } from 'utils/immutable';

import { IState } from './types';

export const stateFactory = Record<IState>({
  assetType: undefined,
  durationSeconds: undefined,
  error: undefined,
  poster: undefined,
  revokable: undefined,
  videoEntity: undefined,
  videoUrl: undefined,
});

export function stateFromJs(obj: any): RecordOf<IState> {
  return reviveRecord(stateFactory, obj);
}
