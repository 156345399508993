import { SliderProps } from 'rc-slider';
import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import IconButton from 'components/IconButton/IconButton';
import WrappedSlider from 'components/WrappedSlider';

const ZOOM_OPTIONS: Pick<SliderProps, 'step' | 'min' | 'max'> = {
  max: 5,
  min: 0,
  step: 0.01,
};

interface IProps {
  onZoomChange: (params: { zoom: number }) => void;
  zoom: number;
}

class VideoZoomSlider extends React.Component<IProps> {
  private handleZoomChange = (zoom: number) => {
    this.props.onZoomChange({ zoom });
  };

  public render() {
    const { zoom, onZoomChange } = this.props;

    return (
      <div className="video-zoom-slider">
        <IconButton
          onButtonClick={onZoomChange}
          onLongButtonClick={onZoomChange}
          params={{ zoom: zoom + ZOOM_OPTIONS.step }}
        >
          <FontAwesome icon="search-plus" className="video-zoom-slider__icon" />
        </IconButton>
        <WrappedSlider
          vertical
          {...ZOOM_OPTIONS}
          value={zoom}
          onChange={this.handleZoomChange}
        />
        <IconButton
          onButtonClick={onZoomChange}
          onLongButtonClick={onZoomChange}
          params={{ zoom: zoom - ZOOM_OPTIONS.step }}
        >
          <FontAwesome
            icon="search-minus"
            className="video-zoom-slider__icon"
          />
        </IconButton>
      </div>
    );
  }
}

export default VideoZoomSlider;
