import { RefAttributes } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import SlideEditor, { SlideEditorProps } from 'components/SlideEditor';
import {
  aspectRatioSelector,
  editSlideImageStatusSelector,
  selectedSlideIdSelector,
} from 'redux/modules/embed/selectors';
import { State } from 'redux/types';
import { PropsOf } from 'types';

type StateProps = Pick<
  SlideEditorProps,
  | 'aspectRatio'
  | 'defaultFitType'
  | 'isSaving'
  | 'src'
  | 'minDurationMillis'
  | 'slideId'
>;

type OwnProps = RefAttributes<SlideEditor>;

const isSavingSelector = createSelector(
  editSlideImageStatusSelector,
  slideImageStatus => slideImageStatus === 'uploading',
);

const mapStateToProps = (state: State): StateProps => ({
  aspectRatio: aspectRatioSelector(state),
  defaultFitType: 'fill',
  isSaving: isSavingSelector(state),
  minDurationMillis: spareminConfig.minSlideDurationMillis,
  slideId: selectedSlideIdSelector(state),
});

const component = connect<StateProps, {}, OwnProps>(
  mapStateToProps,
  null,
  null,
  { forwardRef: true },
)(SlideEditor);

export default component;
export type EditSlideEditorProps = PropsOf<typeof component>;
