import * as React from 'react';
import { formatDurationMillis } from 'utils/time';
import { block } from '../utils';

interface DurationOverlayProps {
  durationMillis: number;
  currentTimeMillis: number;
}

const DurationOverlay: React.SFC<DurationOverlayProps> = ({
  durationMillis,
  currentTimeMillis,
}) => {
  const formattedDuration = formatDurationMillis(durationMillis, {
    minute: '2-digit',
    second: '2-digit',
  });
  const formattedCurrentTime = formatDurationMillis(currentTimeMillis, {
    minute: '2-digit',
    second: '2-digit',
  });

  return (
    <span className={block('duration')}>
      {formattedCurrentTime} / {formattedDuration}
    </span>
  );
};

export default DurationOverlay;
