import { createSelector } from 'reselect';
import { embedExportsSelector } from 'redux/modules/entities';
import bem from 'utils/bem';

export const block = bem('export-video-step');

export const createEmbedExportSelector = (widgetId: string) =>
  createSelector(
    embedExportsSelector,
    embedExports => embedExports?.get(widgetId)?.toJS() ?? {},
  );
