import * as React from 'react';

import { PodcastEpisodeData } from 'blocks/PodcastSearch/types';
import useDefaultIntegration from 'blocks/PodcastSearch/useDefaultIntegration';
import useFavoriteIntegration from 'blocks/PodcastSearch/useFavoriteIntegration';
import useRssIntegration from 'blocks/PodcastSearch/useRssIntegration';
import { Omit } from 'types';
import AddAudioStep, { AddAudioStepProps } from '../../AddAudioStep';

type PickedAudioStepProps = Omit<
  AddAudioStepProps,
  'maxFileSizeMb' | 'video' | 'wizardType'
>;

export interface EpisodeAddAudioStepProps extends PickedAudioStepProps {
  onSampleVideoPlay?: () => void;
  onEpisodeClick?: (episode: PodcastEpisodeData) => void;
}

const EpisodeAddAudioStep: React.FC<EpisodeAddAudioStepProps> = ({
  onSampleVideoPlay,
  onAudioAdded,
  onEpisodeClick,
  ...props
}) => {
  return (
    <AddAudioStep
      {...props}
      maxFileSizeMb={500}
      onAudioAdded={onAudioAdded}
      podcastSearchIntegrations={[
        useDefaultIntegration({
          onAudioAdded,
          onEpisodeClick,
          priority: 2,
        }),
        useRssIntegration({
          onAudioAdded,
          onEpisodeClick,
          episodeExplorerEnabled: true,
          priority: 1,
        }),
        useFavoriteIntegration({
          onAudioAdded,
          onEpisodeClick,
          priority: 0,
        }),
      ]}
      showTranscriptionForm={false}
      wizardType="episode"
    />
  );
};

export default EpisodeAddAudioStep;
