import GlobalStyleModalBody, {
  GlobalStyleFont,
  GlobalStyleModalBodyProps,
} from './GlobalStyleModalBody';

import { GlobalTextStyle, GlobalTextTemplate } from './types';

export {
  GlobalStyleModalBodyProps,
  GlobalStyleModalBody,
  GlobalStyleFont,
  GlobalTextStyle,
  GlobalTextTemplate,
};
