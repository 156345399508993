import * as React from 'react';
import { IconProps } from './types';

function ImDb(props: IconProps) {
  return (
    <svg width={50} height={20} {...props}>
      <path
        d="M5.177.3V20H0V.3h5.177zM43.26 0v6.408l.18-.195a4 4 0 011.2-.861c.509-.232 1.271-.35 1.867-.35l.288.006c.565.026 1.063.13 1.495.31.504.211.889.506 1.153.886s.423.752.476 1.116c.054.365.081 1.14.081 2.33v5.526c0 1.182-.08 2.062-.242 2.64-.16.578-.54 1.08-1.134 1.504-.596.425-1.302.637-2.12.637-.587 0-1.346-.125-1.853-.378-.51-.25-.974-.63-1.396-1.135l-.007.028c-.033.129-.137.538-.315 1.227h-4.66V0h4.987zM13.598.172c.197 1.193.404 2.591.62 4.199l.741 5.004 1.2-9.203h6.762V19.87h-4.52l-.017-13.297-1.81 13.297h-3.23L11.436 6.863 11.42 19.87H6.886V.17zm15.014 0c2.507 0 3.948.114 4.831.34.883.227 1.555.6 2.015 1.118.46.518.747 1.095.863 1.731.114.635.188 1.884.188 3.748v6.918c0 1.766-.1 2.947-.269 3.542-.168.595-.462 1.06-.88 1.397-.42.335-.937.57-1.554.704-.41.09-1.338.156-2.784.201H24.75V.171zm15.384 7.686l-.11.006c-.247.024-.41.134-.49.328-.091.223-.136.782-.136 1.68v5.206c0 .865.05 1.418.155 1.66.104.244.31.365.618.365l.109-.005c.296-.025.635-.15.72-.372.063-.167.11-.77.144-1.806V9.872c0-.834-.055-1.378-.163-1.632-.108-.255-.532-.382-.847-.382zM29.923 3.541v12.93l.195-.003c.629-.024 1.022-.17 1.179-.44.171-.295.257-1.098.257-2.41V5.981c0-.89-.03-1.46-.086-1.711a.81.81 0 00-.392-.553c-.205-.117-.59-.176-1.153-.176z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default ImDb;
