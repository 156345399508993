import * as React from 'react';
import { isUndefined, noop } from 'underscore';

import TextOverlayModal, {
  ITextOverlay,
  TextOverlay,
} from 'blocks/TextOverlayModal';
import Modal from 'components/Modal';
import SwitchEditorModalBanner from 'components/SwitchEditorModalBanner';
import useVideoEditorPlaybackTimeContext from 'containers/VideoEditor/useVideoEditorPlaybackTimeContext';
import { EditorVideoFramePreview } from 'containers/VideoFramePreview';
import usePrevious from 'hooks/usePrevious';
import { createMap, DeepImmutableMap, IImmutableMap, Size } from 'types';
import { DEFAULT_TEXT_OVERLAY_DURATION_MILLIS } from 'utils/constants';

import { textContentFromHtmlString } from 'utils/dom';
import { LegacyAddTextModalProps } from './types';
import { block } from './utils';

interface ITime {
  startMillis: number;
  endMillis: number;
}

type IState = DeepImmutableMap<
  ITextOverlay & {
    time: ITime;
  }
>;

const { useCallback, useEffect, useState } = React;

const LegacyAddTextModal: React.FC<LegacyAddTextModalProps> = ({
  defaultTextOverlayTemplate,
  endMillis,
  inputDisabled,
  onAddAssetHide,
  onExited,
  onSubmit,
  onSwitchLegacyMode,
  size,
  viewport,
  position,
  aspectRatio,
  show,
}) => {
  const [workspaceSize, setWorkspaceSize] = useState<Size<number>>();
  const { positionSec } = useVideoEditorPlaybackTimeContext();
  const startMillis = positionSec * 1000;

  const defaultOverlayStyleWithTimeAndViewport = useCallback(
    (startInMillis: number, endInMillis: number) => {
      const time = createMap<ITime>({
        endMillis: !isUndefined(endInMillis)
          ? endInMillis
          : startInMillis + DEFAULT_TEXT_OVERLAY_DURATION_MILLIS,
        startMillis: startInMillis,
      });

      /*
       * if deafultTextOverlayTemplate is passed, it likely has viewport dimensions and size which
       * need to be used so that dimensions work out after scaling to workspace.
       */
      return (defaultTextOverlayTemplate.mergeWith(
        (oldVal, newVal, key) => {
          if (key === 'time') return newVal;

          // key is either viewport or size here
          if (isUndefined(oldVal)) {
            return newVal;
          }

          return oldVal;
        },
        { time, viewport, size, position } as {
          time: IImmutableMap<ITime>;
          viewport: LegacyAddTextModalProps['viewport'];
          size: LegacyAddTextModalProps['size'];
          position: LegacyAddTextModalProps['position'];
        },
      ) as unknown) as IState;
    },
    [defaultTextOverlayTemplate, position, size, viewport],
  );

  const prevShow = usePrevious(show);

  useEffect(() => {
    // if modal is being opened, reset state
    if (show && !prevShow) {
      setTextOverlay(
        defaultOverlayStyleWithTimeAndViewport(startMillis, endMillis),
      );
    }
  }, [
    endMillis,
    startMillis,
    prevShow,
    show,
    defaultOverlayStyleWithTimeAndViewport,
  ]);

  const [textOverlay, setTextOverlay] = useState<IState>(
    defaultOverlayStyleWithTimeAndViewport(startMillis, endMillis),
  );

  const renderAddToVideoButton = () => (
    <Modal.FooterButton onClick={onSubmit as any} theme="submit">
      Add To Video
    </Modal.FooterButton>
  );

  const renderCancelButton = () => (
    <Modal.FooterButton onClick={onAddAssetHide}>Cancel</Modal.FooterButton>
  );

  return (
    <TextOverlayModal
      background={
        <EditorVideoFramePreview
          aspectRatio={aspectRatio}
          canvasDimensions={workspaceSize}
          backgroundFor={{
            type: 'textOverlayInfo',
          }}
        />
      }
      cancelButton={renderCancelButton()}
      footerLeftSlot={({ partialSubmitHandlerBuilder }) => (
        <div className={block('new-text-modal-banner')}>
          <SwitchEditorModalBanner
            onSwitchMode={partialSubmitHandlerBuilder(
              (partialOverlay: TextOverlay): void => {
                const partialOverlayWithText = partialOverlay?.set(
                  'text',
                  textContentFromHtmlString(partialOverlay.get('textHtml'), ''),
                );
                onSwitchLegacyMode(partialOverlayWithText);
              },
            )}
          />
        </div>
      )}
      onWorkspaceSizeChange={setWorkspaceSize}
      onHide={onAddAssetHide}
      shouldDisableSubmitIfEmpty
      submitButton={renderAddToVideoButton()}
      title="Add Text"
      {...{
        inputDisabled,
        onExited,
        show,
        textOverlay,
      }}
    />
  );
};

LegacyAddTextModal.defaultProps = {
  defaultTextOverlayTemplate: (createMap(
    {},
  ) as unknown) as LegacyAddTextModalProps['defaultTextOverlayTemplate'],
  inputDisabled: false,
  onSubmit: noop,
  viewport: undefined,
};

export default LegacyAddTextModal;
