import { useMemo } from 'react';
import dayjs from 'utils/dayjs';

const DEFAULT_DATE_FORMAT = 'MM.DD.YYYY';

export default function useDateString(
  seconds: number | undefined,
  format = DEFAULT_DATE_FORMAT,
): string {
  return useMemo(
    () =>
      seconds === undefined ? undefined : dayjs.unix(seconds).format(format),
    [format, seconds],
  );
}
