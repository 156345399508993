import * as React from 'react';
import { FormGroup } from 'react-bootstrap';

import Button from 'components/Button';
import bem from 'utils/bem';
import { createChainedFunction } from 'utils/functions';
import EmailCheckbox from '../components/EmailCheckbox';
import PodcastField from '../components/PodcastField';
import UserRoleField from '../components/UserRoleField';
import useProfileForm from '../hooks/useProfileForm';
import { CreateProfileFormProps } from '../types';
import AuthForm from './AuthForm';

const block = bem('create-profile-form');

const CreateProfileForm: React.FC<CreateProfileFormProps> = ({
  errorComponent: ErrorComponent,
  origin,
}) => {
  const {
    clearError,
    error,
    isSubmitting,
    emailsEnabled,
    onSubmit,
    podcast,
    userRole,
  } = useProfileForm({ origin });

  return (
    <AuthForm className={block()} onSubmit={onSubmit}>
      {({ onChange }) => {
        const withClearErrors = fn =>
          createChainedFunction(fn, onChange, clearError);

        return (
          <>
            <PodcastField
              disabled={isSubmitting}
              onChange={withClearErrors(podcast.onChange)}
              value={podcast.value}
            />
            <UserRoleField
              disabled={isSubmitting}
              onChange={withClearErrors(userRole.onChange)}
              value={userRole.value}
            />
            <EmailCheckbox
              checked={emailsEnabled.value}
              disabled={isSubmitting}
              onChange={withClearErrors(emailsEnabled.onChange)}
            />
            <FormGroup>
              {error && <ErrorComponent>{error}</ErrorComponent>}
            </FormGroup>
            <Button disabled={isSubmitting} theme="submit" type="submit" fluid>
              Create Profile
            </Button>
          </>
        );
      }}
    </AuthForm>
  );
};

CreateProfileForm.defaultProps = {
  errorComponent: 'div',
};

export default CreateProfileForm;
