import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import LoadingMessage, { LoadingMessageProps } from 'components/LoadingMessage';
import useOnMount from 'hooks/useOnMount';
import usePageUnloadGuard from 'hooks/usePageUnloadGuard';
import bem from 'utils/bem';

const block = bem('loading-step');

type PickedLoadingMessageProps = Pick<
  LoadingMessageProps,
  'className' | 'message' | 'messageClassName' | 'spinner'
>;

interface IProps extends PickedLoadingMessageProps {
  disableUnloadGuard?: boolean;
  onMount?: () => void;
  onUnmount?: () => void;
}

const LoadingStep: React.FC<IProps> = ({
  className,
  disableUnloadGuard,
  message,
  messageClassName,
  onMount,
  onUnmount,
  spinner,
}) => {
  useOnMount(onMount, onUnmount);

  usePageUnloadGuard(!disableUnloadGuard);

  return (
    <LoadingMessage
      {...{ message, spinner }}
      className={cn(block(), className)}
      messageClassName={cn(block('message'), messageClassName)}
      noFadeIn
      variation="bars"
    />
  );
};

LoadingStep.defaultProps = {
  onMount: noop,
  onUnmount: noop,
};

export default LoadingStep;
export { IProps as LoadingStepProps };
