import { ITextTemplate, ITextTemplateConfig } from './types';

export const lastUsedStyle: ITextTemplate = {
  ui: {
    displayName: 'Last Used Style',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201711171624/last-used-style-16-9.png',
  },
};

export const defaultTemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: 'center',
  },
  overrides: {
    landscape: {
      containerStyle: {
        fontSize: '2.34375vw',
      },
      position: {
        bottom: '11.5071vh',
        left: `${(100 - 55) / 2}vw`,
      },
      size: {
        height: '6.6668vh',
        width: '55vw',
      },
    },
    portrait: {
      containerStyle: {
        fontSize: '6.6116vw',
      },
      position: {
        bottom: '10.8664vh',
        left: `${(100 - 80) / 2}vw`,
      },
      size: {
        height: '11.1713vh',
        width: '80vw',
      },
    },
    square: {
      containerStyle: {
        fontSize: '4.76vw',
      },
      position: {
        bottom: '9.33vh',
        left: `${(100 - 80) / 2}vw`,
      },
      size: {
        height: '14.28vh',
        width: '80vw',
      },
    },
  },
  position: {
    left: 0,
  },
  textStyle: {
    background: 'rgba(0, 0, 0, 0.7)',
    paddingLeft: '0.25em',
    paddingRight: '0.25em',
    boxDecorationBreak: 'clone',
  },
  ui: {
    displayName: 'Headliner',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201801311406/caption-template-default-16-9.png',
  },
};

export const facebookTemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'rgba(0, 0, 0, 0)',
    color: 'white',
    fontFamily: '"Nunito", sans-serif',
    fontWeight: 700,
    lineHeight: 1.1,
    textAlign: 'center',
  },
  overrides: {
    landscape: {
      containerStyle: {
        fontSize: '5.73vw',
      },
      position: {
        bottom: '7.93vh',
        left: `${(100 - 90.36) / 2}vw`,
      },
      size: {
        height: '22vh',
        width: '90.36vw',
      },
    },
    portrait: {
      containerStyle: {
        fontSize: '8.68vw',
      },
      position: {
        bottom: '6.23vh',
        left: `${(100 - 90.5) / 2}vw`,
      },
      size: {
        height: '17.27vh',
        width: '90.5vw',
      },
    },
    square: {
      containerStyle: {
        fontSize: '6.05vw',
      },
      position: {
        bottom: '5.09vh',
        left: `${(100 - 92.51) / 2}vw`,
      },
      size: {
        height: '12.95vh',
        width: '92.51vw',
      },
    },
  },
  ui: {
    displayName: 'Bold Description',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201801311406/caption-template-bold-description-16-9.png',
  },
};

export const micTemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'rgba(0, 0, 0, 0)',
    color: 'white',
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'italic',
    textAlign: 'center',
  },
  overrides: {
    landscape: {
      containerStyle: {
        fontSize: '3.78vw',
        lineHeight: 1.18,
      },
      position: {
        bottom: '7.98vh',
        left: `${(100 - 84.59) / 2}vw`,
      },
      size: {
        height: '16.16vh',
        width: '84.59vw',
      },
    },
    portrait: {
      containerStyle: {
        fontSize: '7.85vw',
        lineHeight: 1.2,
      },
      position: {
        bottom: '4.8vh',
        left: `${(100 - 85.54) / 2}vw`,
      },
      size: {
        height: '21.1vh',
        width: '85.54vw',
      },
    },
    square: {
      containerStyle: {
        fontSize: '6.05vw',
        lineHeight: 1.1,
      },
      position: {
        bottom: '6.89vh',
        left: `${(100 - 66.17) / 2}vw`,
      },
      size: {
        height: '13.77vh',
        width: '66.17vw',
      },
    },
  },
  ui: {
    displayName: 'Italics Speech',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201801311406/caption-template-italics-speech-16-9.png',
  },
};

export const shortcutTemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'rgba(0, 0, 0, 0)',
    color: 'white',
    fontFamily: '"Lato", sans-serif',
    lineHeight: 1.1,
    textAlign: 'left',
  },
  overrides: {
    landscape: {
      containerStyle: {
        fontSize: '7.81vw',
      },
      position: {
        left: '4.97vw',
        top: '7vh',
      },
      size: {
        height: '60vh',
        width: '90.23vw',
      },
    },
    portrait: {
      containerStyle: {
        fontSize: '14.88vw',
      },
      position: {
        left: `${(100 - 83.33) / 2}vw`,
        top: '4.32vh',
      },
      size: {
        height: '70.26vh',
        width: '83.33vw',
      },
    },
    square: {
      containerStyle: {
        fontSize: 38,
      },
      position: {
        left: '3.59vw',
        top: '5.09vh',
      },
      size: {
        height: '43.11vh',
        width: '86.84vw',
      },
    },
  },
  ui: {
    displayName: 'Large Heading',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201801311406/caption-template-large-heading-16-9.png',
  },
};

export const bbcTemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    fontFamily: '"PT Sans", sans-serif',
    lineHeight: 1.1,
    paddingLeft: 1,
    paddingRight: 1,
    paddingTop: 1,
    textAlign: 'center',
  },
  overrides: {
    landscape: {
      containerStyle: {
        fontSize: '4.04vw',
      },
      position: {
        bottom: '5.95vh',
      },
      size: {
        height: '18.29vh',
      },
    },
    portrait: {
      containerStyle: {
        fontSize: '7.85vw',
        paddingBottom: 3,
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 3,
      },
      position: {
        bottom: '3.47vh',
      },
      size: {
        height: '22.3vh',
      },
    },
    square: {
      containerStyle: {
        fontSize: '4.21vw',
      },
      position: {
        bottom: '10vh',
      },
      size: {
        height: '11.38vh',
      },
    },
  },
  position: {
    left: 0,
  },
  size: {
    width: '100vw',
  },
  ui: {
    displayName: 'Dark Background',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201801311406/caption-template-dark-background-16-9.png',
  },
};

export const nowthisTemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'transparent',
    color: 'white',
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: 700,
    lineHeight: 1.1,
    textAlign: 'center',
    textShadow: {
      blur: 10,
      color: 'black',
      x: 0,
      y: 0,
    },
  },
  overrides: {
    landscape: {
      containerStyle: {
        fontSize: '5.6vw',
      },
      position: {
        bottom: '4.27vh',
        left: `${(100 - 88.89) / 2}vw`,
      },
      size: {
        height: '31.1vh',
        width: '88.89vw',
      },
    },
    portrait: {
      containerStyle: {
        fontSize: '10.74vw',
      },
      position: {
        bottom: '4.4vh',
        left: `${(100 - 91) / 2}vw`,
      },
      size: {
        height: '23.83vh',
        width: '91vw',
      },
    },
    square: {
      containerStyle: {
        fontSize: '6.58vw',
      },
      position: {
        bottom: '7.19vh',
        left: `${(100 - 80.24) / 2}vw`,
      },
      size: {
        height: '20.66vh',
        width: '80.24vw',
      },
    },
  },
  textStyle: {
    textTransform: 'uppercase',
  },
  ui: {
    displayName: 'Blocky Description',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201801311406/caption-template-blocky-description-16-9.png',
  },
};

export const buTemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'transparent',
    color: 'white',
    fontFamily: '"Lato", sans-serif',
    fontWeight: 400,
    lineHeight: 1.1,
    textAlign: 'center',
    textShadow: {
      blur: 10,
      color: 'black',
      x: 0,
      y: 0,
    },
  },
  overrides: {
    landscape: {
      containerStyle: {
        fontSize: '7.81vw',
      },
      position: {
        bottom: `${(100 - 43) / 2}vh`,
        left: `${(100 - 76.71) / 2}vw`,
      },
      size: {
        height: '43vh',
        width: '76.71vw',
      },
    },
    portrait: {
      containerStyle: {
        fontSize: 30,
      },
      position: {
        bottom: `${(100 - 43.65) / 2}vh`,
        left: `${(100 - 84.19) / 2}vw`,
      },
      size: {
        height: '43.65vh',
        width: '84.19vw',
      },
    },
    square: {
      containerStyle: {
        fontSize: '11.05vw',
      },
      position: {
        bottom: `${(100 - 36.75) / 2}vh`,
        left: 0,
      },
      size: {
        height: '36.75vh',
        width: '100vw',
      },
    },
  },
  ui: {
    displayName: 'Centered Heading',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201801311406/caption-template-centered-heading-16-9.png',
  },
};

// originally designed for 16:9 and never ported to other ratios
export const title1TemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'rgba(0, 0, 0, .6)',
    color: 'white',
    fontFamily: '"Lato", sans-serif',
    fontSize: '4.72vw',
    fontStyle: 'normal',
    lineHeight: 1.1,
    paddingBottom: 1.8362,
    paddingLeft: 1.8362,
    paddingRight: 1.8362,
    paddingTop: 1.8362,
    textAlign: 'left',
  },
  position: {
    left: '4.09vw',
    top: '4.48vh',
  },
  size: {
    height: '28.01vh',
    width: '91.81vw',
  },
  ui: {
    displayName: 'Title',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201711171624/title-template-16-9.png',
  },
};

// originally designed for 16:9 and never ported to other ratios
export const quote1TemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'rgba(0, 0, 0, .6)',
    color: 'white',
    fontFamily: '"Lato", sans-serif',
    fontSize: '4.4vw',
    fontStyle: 'italic',
    lineHeight: 1.1,
    paddingBottom: 2.244,
    paddingLeft: 2.244,
    paddingRight: 2.244,
    paddingTop: 2.244,
    textAlign: 'center',
  },
  position: {
    left: '12.6vw',
    top: '39.22vh',
  },
  size: {
    height: '28vh',
    width: '74.8vw',
  },
  ui: {
    displayName: 'Quote',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201711171624/quote-template-16-9.png',
  },
};

// originally designed for 16:9 and never ported to other ratios
export const caption1TemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'rgba(0, 0, 0, .6)',
    color: 'white',
    fontFamily: '"Lato", sans-serif',
    fontSize: '3.78vw',
    fontStyle: 'normal',
    lineHeight: 1.1,
    paddingBottom: 1.9182,
    paddingLeft: 1.9182,
    paddingRight: 1.9182,
    paddingTop: 1.9182,
    textAlign: 'left',
  },
  position: {
    left: '2.05vw',
    top: '74.23vh',
  },
  size: {
    height: '20.17vh',
    width: '95.91vw',
  },
  ui: {
    displayName: 'Caption',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201711171624/caption-template-16-9.png',
  },
};

// originally designed for 1:1 and never ported to other ratios
export const gradient1TemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.70))',
    color: 'white',
    fontFamily: 'smf-cjj8p4791000007h1w6im0m3a',
    fontSize: '5vw',
    fontStyle: 'normal',
    lineHeight: 1.4,
    paddingBottom: 6,
    paddingLeft: 8.9442,
    paddingRight: 8.5,
    paddingTop: 16.4,
    textAlign: 'center',
    textShadow: {
      blur: 0,
      color: 'black',
      x: 0,
      y: 0,
    },
  },
  overrides: {
    landscape: {
      containerStyle: {
        lineHeight: 1.2,
        paddingBottom: 2,
        paddingTop: 4,
      },
    },
    portrait: {
      containerStyle: {
        fontSize: '6vw',
        paddingTop: 8,
      },
      position: {
        left: '0vw',
        top: '80vh',
      },
      size: {
        height: '20vh',
        width: '100vw',
      },
    },
  },
  position: {
    left: '0vw',
    top: '65.833421vh',
  },
  size: {
    height: '34.16658vh',
    width: '100vw',
  },
  ui: {
    displayName: 'Gradient Subtitle',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201808082205/gradient-subtitle-template-16-9.png',
  },
};

export const gradientSubtitleAmharicTemplateConfig: ITextTemplateConfig = {
  ...gradient1TemplateConfig,
  containerStyle: {
    ...gradient1TemplateConfig.containerStyle,
    fontFamily: 'smf-cjvydey2o000908pi58t4e39k',
    fontSize: '4.8vw',
    paddingLeft: 15,
    paddingRight: 15,
  },
  ui: {
    ...gradient1TemplateConfig.ui,
    displayName: 'Gradient Subtitle Ahmharic',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201905301506/gradient-subtitle-amharic-template-16-9.png',
  },
};

// Prisa Radio
export const gradientSubtitlePrisaTemplateConfig: ITextTemplateConfig = {
  ...gradient1TemplateConfig,
  containerStyle: {
    ...gradient1TemplateConfig.containerStyle,
    background: 'linear-gradient(transparent, #22273D)',
    fontFamily: 'smf-cjpbhcndw000208pm7tmplmvo',
    lineHeight: 1,
  },
  overrides: {
    ...gradient1TemplateConfig.overrides,
    landscape: {
      containerStyle: {
        ...gradient1TemplateConfig.overrides.landscape.containerStyle,
        lineHeight: 1,
      },
    },
  },
};

export const gradientSubtitleRtlTemplateConfig: ITextTemplateConfig = {
  ...gradient1TemplateConfig,
  containerStyle: {
    ...gradient1TemplateConfig.containerStyle,
    fontFamily: 'smf-ckcmffjg000000zlmu45oq701',
  },
  ui: {
    ...gradient1TemplateConfig.ui,
    displayName: 'RTL: Gradient Subtitle',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/202004271953/gradient-subtitle-amharic-template-16-9.png',
  },
};

export const gradientSubtitleBengaliTemplateConfig: ITextTemplateConfig = {
  ...gradient1TemplateConfig,
  containerStyle: {
    ...gradient1TemplateConfig.containerStyle,
    fontFamily: 'smf-ckokywwsy000010qochgnou9d',
  },
  ui: {
    ...gradient1TemplateConfig.ui,
    displayName: 'Bengali: Gradient Subtitle',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/202105211851/gradient-subtitle-bengali-template-16-9.png',
  },
};

// originally designed for 1:1 and never ported to other ratios
export const leftInsert1TemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: '#00A5FF',
    color: 'white',
    fontFamily: 'smf-cjj8pcj6200001qh1cavyjk7k',
    fontSize: '5.263158vw',
    fontStyle: 'normal',
    lineHeight: 1,
    paddingBottom: 0,
    paddingLeft: 2.63389,
    paddingRight: 2.59398,
    paddingTop: 1.6362,
    textAlign: 'left',
    textShadow: {
      blur: 0,
      color: 'black',
      x: 0,
      y: 0,
    },
  },
  position: {
    left: '5.5208vw',
    top: '68.0556vh',
  },
  size: {
    height: '13.7963vh',
    width: '39.9074vw',
  },
  ui: {
    displayName: 'Left Insert',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201808092108/left-insert-template-16-9.png',
  },
};

export const leftInsertAmharicTemplateConfig: ITextTemplateConfig = {
  ...leftInsert1TemplateConfig,
  containerStyle: {
    ...leftInsert1TemplateConfig.containerStyle,
    fontFamily: 'smf-cjvydey2o000908pi58t4e39k',
    fontSize: '5.15vw',
    paddingTop: 1.6,
  },
  ui: {
    ...leftInsert1TemplateConfig.ui,
    displayName: 'Left Insert Amharic',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201905301506/left-insert-amharic-template-16-9.png',
  },
};

export const leftInsertRtlTemplateConfig: ITextTemplateConfig = {
  ...leftInsert1TemplateConfig,
  containerStyle: {
    ...leftInsert1TemplateConfig.containerStyle,
    fontFamily: 'smf-ckcmffjg000000zlmu45oq701',
    textAlign: 'right',
  },
  ui: {
    ...leftInsert1TemplateConfig.ui,
    displayName: 'RTL: Left Insert',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/202004271953/left-insert-rtl-template-16-9.png',
  },
};

export const leftInsertBengaliTemplateConfig: ITextTemplateConfig = {
  ...leftInsert1TemplateConfig,
  containerStyle: {
    ...leftInsert1TemplateConfig.containerStyle,
    fontFamily: 'smf-ckokywwsy000010qochgnou9d',
  },
  ui: {
    ...leftInsert1TemplateConfig.ui,
    displayName: 'Bengali: Left Insert',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/202105211851/left-insert-bengali-template-16-9.png',
  },
};

// originally designed for 1:1 and never ported to other ratios
export const rightInsert1TemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: '#00A5FF',
    color: 'white',
    fontFamily: 'smf-cjj8pcj6200001qh1cavyjk7k',
    fontSize: '5.263158vw',
    fontStyle: 'normal',
    lineHeight: 1,
    paddingBottom: 0,
    paddingLeft: 2.69,
    paddingRight: 2.49074,
    paddingTop: 1.643889,
    textAlign: 'left',
    textShadow: {
      blur: 0,
      color: 'black',
      x: 0,
      y: 0,
    },
  },
  position: {
    left: '44.537037vw',
    top: '68.0556vh',
  },
  size: {
    height: '13.7963vh',
    width: '50vw',
  },
  ui: {
    displayName: 'Right Insert',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201808092108/right-insert-template-16-9.png',
  },
};

export const rightInsertAmharicTemplateConfig: ITextTemplateConfig = {
  ...rightInsert1TemplateConfig,
  containerStyle: {
    ...rightInsert1TemplateConfig.containerStyle,
    fontFamily: 'smf-cjvydey2o000908pi58t4e39k',
    fontSize: '5.15vw',
    paddingLeft: 3,
  },
  ui: {
    ...rightInsert1TemplateConfig.ui,
    displayName: 'Right Insert Amharic',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201905301506/right-insert-amharic-template-16-9.png',
  },
};

export const rightInsertRtlTemplateConfig: ITextTemplateConfig = {
  ...rightInsert1TemplateConfig,
  containerStyle: {
    ...rightInsert1TemplateConfig.containerStyle,
    fontFamily: 'smf-ckcmffjg000000zlmu45oq701',
    textAlign: 'right',
  },
  ui: {
    ...rightInsert1TemplateConfig.ui,
    displayName: 'RTL: Right Insert',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/202004271953/right-insert-rtl-template-16-9.png',
  },
};

export const rightInsertBengaliTemplateConfig: ITextTemplateConfig = {
  ...rightInsert1TemplateConfig,
  containerStyle: {
    ...rightInsert1TemplateConfig.containerStyle,
    fontFamily: 'smf-ckokywwsy000010qochgnou9d',
  },
  ui: {
    ...rightInsert1TemplateConfig.ui,
    displayName: 'Bengali: Right Insert',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/202105211851/right-insert-bengali-template-16-9.png',
  },
};

// originally designed for 1:1 and never ported to other ratios
export const copyright1TemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'rgba(0, 0, 0, 0)',
    color: '#323c45',
    fontFamily: 'smf-cjj8p4791000007h1w6im0m3a',
    fontSize: 10,
    fontStyle: 'normal',
    lineHeight: 1,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 2.6852,
    paddingTop: 2.45238,
    textAlign: 'right',
    textShadow: {
      blur: 0,
      color: 'black',
      x: 0,
      y: 0,
    },
  },
  position: {
    left: '68vw',
    top: '92.61903vh',
  },
  size: {
    height: '7.14vh',
    width: '32vw',
  },
  ui: {
    displayName: 'Copyright Text',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201808092108/copyright-text-template-16-9.png',
  },
};

export const copyrightTextAmharicTemplateConfig: ITextTemplateConfig = {
  ...copyright1TemplateConfig,
  containerStyle: {
    ...copyright1TemplateConfig.containerStyle,
    color: 'white',
    fontFamily: 'smf-cjvydey2o000908pi58t4e39k',
    fontSize: '2.4vw',
    paddingRight: 1.9,
    paddingTop: 2.6,
  },
  ui: {
    ...copyright1TemplateConfig.ui,
    displayName: 'Copyright Text Amharic',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201905301506/copyright-text-amharic-template-16-9.png',
  },
};

export const copyrightTextRtlTemplateConfig: ITextTemplateConfig = {
  ...copyright1TemplateConfig,
  containerStyle: {
    ...copyright1TemplateConfig.containerStyle,
    textAlign: 'right',
    fontFamily: 'smf-ckcmffjg000000zlmu45oq701',
    fontSize: '2.63vw',
  },
  ui: {
    ...copyright1TemplateConfig.ui,
    displayName: 'RTL: Copyright Text',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/202004271953/copyright-text-amharic-template-16-9.png',
  },
};

export const copyrightTextBengaliTemplateConfig: ITextTemplateConfig = {
  ...copyright1TemplateConfig,
  containerStyle: {
    ...copyright1TemplateConfig.containerStyle,
    fontFamily: 'smf-ckokywwsy000010qochgnou9d',
  },
  ui: {
    ...copyright1TemplateConfig.ui,
    displayName: 'Bengali: Copyright Text',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/202105211851/copyright-text-bengali-template-16-9.png',
  },
};

export const titleAnimated1Config: ITextTemplateConfig = {
  containerDecorator: {
    name: 'topAndBottomBar',
    styles: [
      [
        {
          displayName: 'Border Color',
          propName: 'background-color',
          propValue: 'yellow',
        },
      ],
    ],
  },
  containerStyle: {
    background: 'transparent',
    color: 'white',
    fontFamily: '"Lato", sans-serif',
    fontWeight: 400,
    lineHeight: 1.1,
    textAlign: 'center',
    textShadow: {
      blur: 10,
      color: 'black',
      x: 0,
      y: 0,
    },
  },
  overrides: {
    landscape: {
      containerStyle: {
        fontSize: '7.81vw',
      },
      position: {
        bottom: `${(100 - 15.81) / 2}vh`,
        left: `${(100 - 40) / 2}vw`,
      },
      size: {
        height: '15.81vh',
        width: '40vw',
      },
    },
    portrait: {
      containerStyle: {
        fontSize: 30,
      },
      position: {
        bottom: `${(100 - 8.313) / 2}vh`,
        left: `${(100 - 64) / 2}vw`,
      },
      size: {
        height: '8.313vh',
        width: '64vw',
      },
    },
    square: {
      containerStyle: {
        fontSize: '9vw',
      },
      position: {
        bottom: `${(100 - 11.3) / 2}vh`,
        left: `${(100 - 60.2) / 2}vw`,
      },
      size: {
        height: '11.3vh',
        width: '60.2vw',
      },
    },
  },
  ui: {
    displayName: 'Animated Title',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201808222154/animated-lines-title-16-9.png',
  },
};

export const detailAnimated1Config: ITextTemplateConfig = {
  containerDecorator: {
    name: 'bottomBar',
    styles: [
      [
        {
          displayName: 'Border Color',
          propName: 'background-color',
          propValue: 'yellow',
        },
      ],
    ],
  },
  containerStyle: {
    background: 'transparent',
    color: 'white',
    fontFamily: '"Lato", sans-serif',
    fontWeight: 400,
    lineHeight: 1.1,
    textAlign: 'left',
    textShadow: {
      blur: 0,
      color: 'black',
      x: 0,
      y: 0,
    },
  },
  overrides: {
    landscape: {
      containerStyle: {
        fontSize: 36,
      },
      position: {
        bottom: '18vh',
        left: '6vw',
      },
      size: {
        height: '16.42vh',
        width: '60vw',
      },
    },
    portrait: {
      containerStyle: {
        fontSize: 28,
      },
      position: {
        bottom: '12vh',
        left: '6vw',
      },
      size: {
        height: '14.13vh',
        width: '75vw',
      },
    },
    square: {
      containerStyle: {
        fontSize: 28,
      },
      position: {
        bottom: '12vh',
        left: '6vw',
      },
      size: {
        height: '24.4vh',
        width: '75vw',
      },
    },
  },
  ui: {
    displayName: 'Animated Detail',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201808222154/animated-line-detail-16-9.png',
  },
};

export const fullScreen1Config: ITextTemplateConfig = {
  containerStyle: {
    background: 'rgba(0, 0, 0, 0.6)',
    color: 'white',
    fontFamily: '"Lato", sans-serif',
    fontSize: 32,
    fontWeight: 400,
    lineHeight: 1.1,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    textAlign: 'center',
    textShadow: {
      blur: 0,
      color: 'black',
      x: 0,
      y: 0,
    },
  },
  position: {
    left: '0vw',
    top: '0vh',
  },
  size: {
    height: '100vh',
    width: '100vw',
  },
  ui: {
    displayName: 'Full Screen Overlay',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201808250308/full-screen-overlay-16-9.png',
  },
};

// quote template config for entercom
export const quote2TemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'rgba(6, 2, 122, 1.0)',
    color: 'white',
    fontFamily: '"Roboto", sans-serif',
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 1,
    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 3,
    textAlign: 'left',
    textShadow: {
      blur: 0,
      color: 'black',
      x: 0,
      y: 0,
    },
  },
  position: {
    left: '15vw',
    top: '40vh',
  },
  size: {
    height: '30vh',
    width: '70vw',
  },
  ui: {
    displayName: 'Quote 2',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201810031417/quote2-template-16-9.png',
  },
};

export const socialMediaTextStyleConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'transparent',
    color: 'white',
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 1.7,
    textAlign: 'center',
  },
  position: {
    left: '8vw',
    top: '35vh',
  },
  size: {
    height: '30vh',
    width: '84vw',
  },
  textStyle: {
    background: 'rgba(226, 5, 124, 0.93)',
  },
  ui: {
    displayName: 'Instagram',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201901291048/caption-template-social-media-text-style-16-9.png',
  },
};

// entercom
export const sportscastTitleTemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'rgba(255, 255, 255, 1.0)',
    color: '#1f055e',
    fontFamily: 'smf-ckngxh23x000012oiso0dowo9',
    fontSize: 60,
    fontWeight: 700,
    lineHeight: 1.1,
    paddingLeft: 1,
    paddingRight: 1,
    paddingTop: 1,
    textAlign: 'center',
  },
  position: {
    left: '27.303754266211605vw',
    top: '31.76291793313069vh',
  },
  size: {
    height: '18.541033434650455vh',
    width: '72.6962457337884vw',
  },
  ui: {
    displayName: 'Sportscast Title',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201905031407/sportscast-title.png',
  },
};

export const sportsQuoteLeftTemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'rgba(255, 255, 255, 1.0)',
    color: '#1f055e',
    fontFamily: 'smf-ckngxh23x000012oiso0dowo9',
    fontSize: 30,
    fontWeight: 700,
    lineHeight: 1.5,
    paddingLeft: 1,
    paddingRight: 1,
    paddingTop: 1,
    textAlign: 'center',
  },
  position: {
    left: '0vw',
    top: '31.63036136440392vh',
  },
  size: {
    height: '24.012158054711247vh',
    width: '61.262798634812285vw',
  },
  ui: {
    displayName: 'Sportscast Quote Left',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201905031407/sports-quote-left.png',
  },
};

export const sportsQuoteRightTemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'rgba(255, 255, 255, 1.0)',
    color: '#1f055e',
    fontFamily: 'smf-ckngxh23x000012oiso0dowo9',
    fontSize: 30,
    fontWeight: 700,
    lineHeight: 1.5,
    textAlign: 'center',
  },
  position: {
    left: '37.54266211604095vw',
    top: '30.989285714285714vh',
  },
  size: {
    height: '12.158054711246201vh',
    width: '62.45733788395904vw',
  },
  ui: {
    displayName: 'Sportscast Quote Right',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/201905031407/sports-quote-right.png',
  },
};

export const srseNameTemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'rgba(255, 255, 255, 1)',
    color: 'rgb(0, 0, 0)',
    fontWeight: 700,
    fontFamily: 'smf-ckfshs42g00002xqdwr78fdxp',
  },
  overrides: {
    landscape: {
      containerStyle: {
        fontSize: '4.47368vw',
        lineHeight: 1.1,
        paddingBottom: 0,
        paddingLeft: 1,
        paddingRight: 0,
        paddingTop: 1,
      },
      position: {
        left: '4.166666666666666vw',
        top: '58.7037037037037vh',
      },
      size: {
        width: '29.703911411054612vw',
        height: '6.534860510432014vh',
      },
    },
    square: {
      containerStyle: {
        fontSize: '4.47368vw',
        lineHeight: 1.1,
        paddingBottom: 0,
        paddingLeft: 1,
        paddingRight: 0,
        paddingTop: 1,
      },
      position: {
        left: '4.166666666666666vw',
        top: '58.7037037037037vh',
      },
      size: {
        width: '29.703911411054612vw',
        height: '6.534860510432014vh',
      },
    },
    portrait: {
      containerStyle: {
        fontSize: '5.78512vw',
        lineHeight: 1.25,
        paddingBottom: 0,
        paddingLeft: 1,
        paddingRight: 0,
        paddingTop: 0.5,
      },
      position: {
        top: '63.333337423305636vh',
        left: '3.4375042405603593vw',
      },
      size: {
        width: '37.99542081162167vw',
        height: '4.16345926101374vh',
      },
    },
  },
  ui: {
    displayName: 'SR-SE-Name',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/202102181410/srse-name-template-16-9.png',
  },
};

export const srseOccupationTemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    color: 'rgb(255, 255, 255)',
    fontWeight: 400,
    fontFamily: 'smf-ckfshs42g00002xqdwr78fdxp',
    background: 'rgba(0, 0, 0, 1)',
  },
  overrides: {
    landscape: {
      containerStyle: {
        fontSize: '4.21053vw',
        lineHeight: 1.1,
        paddingBottom: 0,
        paddingLeft: 1,
        paddingRight: 0,
        paddingTop: 1,
      },
      position: {
        top: '65.37037037037037vh',
        left: '4.166666666666666vw',
      },
      size: {
        width: '43.664758839164655vw',
        height: '5.9407822822109235vh',
      },
    },
    square: {
      containerStyle: {
        fontSize: '4.21053vw',
        lineHeight: 1.1,
        paddingBottom: 0,
        paddingLeft: 1,
        paddingRight: 0,
        paddingTop: 1,
      },
      position: {
        top: '65.37037037037037vh',
        left: '4.166666666666666vw',
      },
      size: {
        width: '43.664758839164655vw',
        height: '5.9407822822109235vh',
      },
    },
    portrait: {
      containerStyle: {
        fontSize: '5.78512vw',
        lineHeight: 1.5,
        paddingBottom: 0,
        paddingLeft: 1,
        paddingRight: 0,
        paddingTop: 0.5,
      },
      size: {
        width: '57.239854729196296vw',
        height: '4.441023211747988vh',
      },
      position: {
        top: '67.59259893904363vh',
        left: '3.4375042405603593vw',
      },
    },
  },
  ui: {
    displayName: 'SR-SE-Occupation',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/202102181410/srse-occupation-template-16-9.png',
  },
};

export const srseCaptionsTemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'rgba(0, 0, 0, 0)',
    lineHeight: 1.1,
    color: 'white',
    fontFamily: 'smf-ckfshs42g00002xqdwr78fdxp',
    fontWeight: 400,
    fontStyle: 'normal',
    textAlign: 'center',
    fontSize: '4.736842105263158vw',
    paddingBottom: 0,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 0,
  },
  overrides: {
    square: {
      position: {
        bottom: '6.71400270322121vh',
        left: '0vw',
      },
      size: {
        width: '100vw',
        height: '10.69340810797966vh',
      },
    },
    landscape: {
      position: {
        bottom: '6.71400270322121vh',
        left: '0vw',
      },
      size: {
        width: '100vw',
        height: '10.69340810797966vh',
      },
    },
    portrait: {
      position: {
        bottom: '11.140289031540288vh',
        left: '0vw',
      },
      size: {
        width: '100vw',
        height: '10.69340810797966vh',
      },
    },
  },
  ui: {
    displayName: 'SR-SE-Captions',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/202102181410/srse-captions-template-16-9.png',
  },
};

export const srseRubrikTemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'transparent',
    color: 'white',
    fontFamily: 'smf-ckfshs42g00002xqdwr78fdxp',
    lineHeight: 1.1,
    fontSize: '6.31579vw',
    textTransform: 'uppercase',
    fontWeight: 700,
    textAlign: 'left',
  },
  overrides: {
    square: {
      containerStyle: {
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
      },
      position: {
        left: '3.6618936662976194vw',
        top: '81.65525799659473vh',
      },
      size: {
        width: '92.67621266740477vw',
        height: '10.396368993869114vh',
      },
    },
    landscape: {
      containerStyle: {
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
      },
      position: {
        left: '3.6618936662976194vw',
        top: '81.65525799659473vh',
      },
      size: {
        width: '92.67621266740477vw',
        height: '10.396368993869114vh',
      },
    },
    portrait: {
      containerStyle: {
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 2,
      },
      position: {
        left: '6.476912237278473vw',
        top: '82.24961477642464vh',
      },
      size: {
        width: '93.26148744670775vw',
        height: '8.604482472761726vh',
      },
    },
  },
  ui: {
    displayName: 'SR-SE-Rubrik',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/202102181410/srse-rubrik-template-16-9.png',
  },
};

export const srseP4TemplateConfig: ITextTemplateConfig = {
  containerStyle: {
    background: 'transparent',
    fontFamily: 'Inter, sans-serif',
    fontSize: '5.78947vw',
    fontWeight: 700,
    lineHeight: 1.2,
    textAlign: 'center',
  },
  position: {
    left: '3.6458333333333335vw',
    top: '4.814814814814815vh',
  },
  size: {
    height: '6.237821396321469vh',
    width: '30.00095052516516vw',
  },
  ui: {
    displayName: 'SR-SE-P4',
    imageUrl:
      'https://static.sparemin.com/img/editor/template-icons/bw/202112171723/srse-programnamn-template-16-9.png',
  },
};
