import { Text } from '@sparemin/blockhead';
import React from 'react';
import { block } from './utils';

interface MediaCardContentDescriptionProps {
  children: React.ReactNode;
}

const MediaCardContentDescription: React.FC<MediaCardContentDescriptionProps> = ({
  children,
}) => {
  return (
    <Text variant="p" className={block('content-description')}>
      {children}
    </Text>
  );
};

export default MediaCardContentDescription;
