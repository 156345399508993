import { REHYDRATE } from 'redux-persist-immutable/constants';
import { pick } from 'utils/collections';
import { Type } from './action-types';
import { entireAudioEnhancer } from './enhancers';
import { stateFactory } from './factories';
import { TemplateWizardAction, TemplateWizardState } from './types';

// TODO add this to stateFromJS
const defaultState = stateFactory();

function reducer(
  state: TemplateWizardState = defaultState,
  action: TemplateWizardAction,
): TemplateWizardState {
  switch (action.type) {
    // https://stash.sparemin.com/projects/SPAR/repos/embed-generator-app/pull-requests/3810/diff#src/redux/modules/template-wizard/types.ts
    // the linked PR removed fields from the state record.  if a user had the templateWizard
    // state persisted, upon rehydration the app would crash because the rehydrater would
    // attempt to set fields which no longer exist on the record, which causes immutablejs
    // to throw an error.
    //
    // fields in question are removed in this case statement.  after enough time passes,
    // users should not longer have the outdated version of the templateWizard state persisted
    // and we can remove this opt-in rehydration logic
    case REHYDRATE: {
      // react-persist-immutable passes the full persisted redux state in action.payload.
      // the state is a plain object where the keys are the redux slices we've
      // defined in the app and their values are immutable objects
      const fullState: any = action.payload;
      const slice = fullState.templateWizard;

      if (!slice) {
        // this is the default behavior if REHYDRATE weren't being handled - the
        // action would fall through to the default case and `state` would be returned
        return state;
      }

      // because records must contain all fields, use defaultState to filter
      // the persisted state
      return pick(slice, ...defaultState.keySeq().toArray());
    }

    case Type.LOAD: {
      const { templateId, templateType } = action.payload;
      return defaultState.withMutations(s => {
        s.set('templateId', templateId);
        s.set('templateType', templateType);
        return s;
      });
    }

    case Type.TEMPLATE_SELECT: {
      const { templateId } = action.payload;
      return state.set('templateId', templateId);
    }

    case Type.UNLOAD:
      return defaultState;

    default:
      return state;
  }
}

export default entireAudioEnhancer.createReducer(reducer, defaultState);
