import { PreviewVideo, TemplateThumbnail, TemplateType } from 'types';

export enum ExportPlatforms {
  INSTAGRAM = 'instagram',
  INSTAGRAM_STORY = 'instagramStory',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
}

export interface ITemplate {
  id: string;
  configurationId: string;
  dimension: {
    width: number;
    height: number;
  };
  name?: string;
  displayName?: string;
  description?: string;
  thumbnailImageUrl?: string;
  exportPlatforms?: ExportPlatforms[];
  previewVideos: PreviewVideo[];
  type: TemplateType;
  canDelete: boolean;
  templateThumbnail: TemplateThumbnail;
}

export type TemplateItem = ITemplate;

export type Templates = Record<string, TemplateItem>;
