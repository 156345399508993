import { Record } from 'immutable';
import { AnyObject } from 'types';
import { getValue } from 'utils/collections';
import { reviveMap, reviveRecord } from 'utils/immutable';

import { IEngineResults } from './types';

export const engineFactory = Record<IEngineResults>({
  data: undefined,
  error: undefined,
  isFetching: undefined,
  page: undefined,
  pageSize: undefined,
  q: undefined,
  totalResults: undefined,
});

export function stateFromJs(obj: AnyObject) {
  return reviveMap((value, key) => {
    const data = getValue(obj, [key, 'data']);
    return reviveRecord(engineFactory, value, { data });
  }, obj);
}
