import cn from 'classnames';
import React from 'react';

import { PlanInstanceProps } from 'blocks/Pricing/types';
import { basicFeatureList } from '../features';
import Plan from '../Plan';
import { basicPlanBlock as block } from '../utils';
import BasicPlanCta from './BasicPlanCta';
import BasicPlanHeader from './BasicPlanHeader';

const BasicPlan: React.FC<PlanInstanceProps> = ({
  className,
  cta: ctaOverride,
  onUpgradeClick,
  period,
}) => (
  <Plan className={cn(block(), className)}>
    <BasicPlanHeader period={period} />
    <Plan.Description
      callToAction={
        <BasicPlanCta ctaOverride={ctaOverride} onClick={onUpgradeClick} />
      }
      features={basicFeatureList}
    />
  </Plan>
);

export default BasicPlan;
