import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';
import { useEditorState } from 'components/VideoTemplateEditor/context/VideoEditorStateContext';
import { AssetCreationType } from 'components/VideoTemplateEditor/types';
import VideoTemplateEditorToggleCard from 'components/VideoTemplateEditor/VideoTemplateEditorToggleCard';
import { addTextTypeModalBlock as block } from '../../utils';

export interface AddTextTypeSelectionModalContentsProps {
  onSubmit?: (type: AssetCreationType) => void;
}

const AddTextTypeSelectionModalContents: React.FC<AddTextTypeSelectionModalContentsProps> = ({
  onSubmit,
}) => {
  const [value, setValue] = useState<AssetCreationType | undefined>(undefined);
  const { integrations } = useEditorState();
  const { dynamicTextIntegrations, staticTextIntegrations } = integrations;

  const dynamicProps = dynamicTextIntegrations.find(
    i => i.textTypeCardProps !== undefined,
  )?.textTypeCardProps;

  const staticProps = staticTextIntegrations.find(
    i => i.textTypeCardProps !== undefined,
  )?.textTypeCardProps;

  const handleStaticChange = useCallback((checked: boolean) => {
    if (checked) {
      setValue('static');
    } else {
      setValue(undefined);
    }
  }, []);

  const handleDynamicChange = useCallback((checked: boolean) => {
    if (checked) {
      setValue('dynamic');
    } else {
      setValue(undefined);
    }
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit(value);
  }, [onSubmit, value]);

  return (
    <>
      <Modal.Body className={block('body')}>
        <VideoTemplateEditorToggleCard
          checked={value === 'static'}
          icon={staticProps.icon}
          info={staticProps.info}
          onChange={handleStaticChange}
          theme="blue"
        >
          {staticProps.children}
        </VideoTemplateEditorToggleCard>
        <VideoTemplateEditorToggleCard
          checked={value === 'dynamic'}
          icon={dynamicProps.icon}
          info={dynamicProps.info}
          onChange={handleDynamicChange}
          theme="rainbow"
        >
          {/* TODO className */
          React.isValidElement(dynamicProps.children) &&
            React.cloneElement(dynamicProps.children, {
              className: cn(
                dynamicProps.children.props.className,
                block('dynamic-contents'),
              ),
            })}
        </VideoTemplateEditorToggleCard>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={value === undefined}
          fluid
          onClick={handleSubmit}
          theme="submit"
        >
          next
        </Button>
      </Modal.Footer>
    </>
  );
};

export default AddTextTypeSelectionModalContents;
