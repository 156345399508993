import * as React from 'react';

import { PlanFeatureList, proFeatures } from 'blocks/Pricing';
import { HeadyOutline } from 'components/icons';
import CardWizardStep, {
  CardWizardControlButton,
  CardWizardControls,
} from '../../components/CardWizard/CardWizardStep';
import { welcomeBlock as block } from '../../utils';
import { WelcomeStepProps } from './types';
import useTrialWelcomeStep from './useTrialWelcomeStep';

const featuresFirst = [
  proFeatures.unwatermarkedVideos,
  proFeatures.watermarkedVideos,
  proFeatures.captions,
  { ...proFeatures.fullTranscript, label: 'Transcribe full podcast episodes' },
  { ...proFeatures.automation, label: 'Unlimited automations' },
];

const featuresSecond = [
  proFeatures.introOutro,
  proFeatures.templates,
  proFeatures.fonts,
  proFeatures.exportSpeed,
  proFeatures.support,
];

const TrialWelcomeStep: React.FC<WelcomeStepProps> = props => {
  const { onNextClick, title } = props;
  const { planEndInDays } = useTrialWelcomeStep();

  return (
    <CardWizardStep className={block()}>
      <div className={block('icon', { xs: true })}>
        <HeadyOutline />
      </div>
      <div className={block('message')}>
        <CardWizardStep.H1 className={block('trial-title')}>
          {title}
        </CardWizardStep.H1>
        <CardWizardStep.H2>
          You’ve got Headliner Pro free for {planEndInDays} days! Enjoy all
          these features:
        </CardWizardStep.H2>
      </div>
      <div className={block('features')}>
        <PlanFeatureList
          className={block('feature-list', { left: true })}
          featureClassName={block('feature')}
          features={featuresFirst}
        />
        <PlanFeatureList
          className={block('feature-list', { right: true })}
          featureClassName={block('feature')}
          features={featuresSecond}
        />
      </div>
      <CardWizardControls className={block('controls')}>
        <CardWizardControlButton onClick={() => onNextClick()}>
          next
        </CardWizardControlButton>
      </CardWizardControls>
    </CardWizardStep>
  );
};

export default TrialWelcomeStep;
