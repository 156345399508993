import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import { block } from '../../../utils';
import TrackLabelButton from '../TrackLabelButton';
import { ButtonProps } from './types';

export type DeleteTrackButtonProps = ButtonProps;

export default class DeleteTrackButton extends React.PureComponent<
  DeleteTrackButtonProps
> {
  public render() {
    const { disabled } = this.props;

    return (
      <TrackLabelButton
        disabled={disabled}
        tooltip={disabled && "This track can't be deleted"}
        {...this.props}
      >
        <FontAwesome
          className={block('trash-icon')}
          icon="trash-alt"
          fixedWidth
        />
      </TrackLabelButton>
    );
  }
}
