import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';

import { decode } from 'utils/jwt';
import { createRequest } from '../utils';
import * as types from './types';

const preferencesSchema = new schema.Entity(
  'userPreferences',
  {},
  {
    idAttribute: 'userId',
  },
);

const userProfilesSchema = new schema.Entity(
  'userProfiles',
  {},
  {
    idAttribute: 'userId',
  },
);

function createProfile(
  args: types.CreateProfileArgs,
  request: SuperAgentStatic,
  userId: number,
): Promise<types.CreateProfileResult> {
  const [email, fullName] = args;
  return request
    .post(`/api/v1/user/${userId}/profile`)
    .send({
      email,
      fullName,
      imageUrl: spareminConfig.defaultProfileImageUrl,
    })
    .then(res => res.body);
}

function updateProfile(
  args: types.UpdateProfileArgs,
  request: SuperAgentStatic,
  userId: number,
): Promise<types.UpdateProfileResult> {
  const [email] = args;
  return request
    .put(`/api/v1/user/${userId}/profile`)
    .send({
      email,
    })
    .then(res => res.body);
}

function getProfileById(
  [userId]: types.GetProfileByIdArgs,
  request: SuperAgentStatic,
): Promise<types.GetProfileByIdResult> {
  return request
    .get(`/api/v1/user/${userId}/profile`)
    .then(res => normalize(res.body, userProfilesSchema));
}

function getPreferencesByUserId(
  __: types.GetPreferencesArgs,
  request: SuperAgentStatic,
  userId: number,
): Promise<types.GetPreferencesResult> {
  return request
    .get(`/api/v1/user/${userId}/preference`)
    .then(res => normalize(res.body, preferencesSchema));
}

function updatePreferencesByUserId(
  [
    videoExportEmailAlertEnabled,
    marketingEmailEnabled,
    automationEmailAlertEnabled,
    reminderEmailEnabled,
  ]: types.UpdatePreferencesArgs,
  request: SuperAgentStatic,
  userId: number,
): Promise<types.UpdatePrefencesResult> {
  return request
    .put(`/api/v1/user/${userId}/preference`)
    .send({
      videoExportEmailAlertEnabled,
      marketingEmailEnabled,
      automationEmailAlertEnabled,
      reminderEmailEnabled,
    })
    .then(res => res.body);
}

async function getIntegratorsByUserId(
  _: types.GetIntegratorsArgs,
  request: SuperAgentStatic,
  userId: number,
): Promise<types.GetIntegratorsResult> {
  const result = await request.get(`/api/v1/user/${userId}/integrators`);
  return result.body;
}

async function getOrganizationByUserId(
  _: types.GetOrganizationArgs,
  request: SuperAgentStatic,
  userId: number,
): Promise<types.GetOrganizationResult> {
  const result = await request.get(`/api/v1/user/${userId}/organization`);
  return result.body;
}

async function deleteUserAccount(
  request: SuperAgentStatic,
  userId: number,
): Promise<types.DeleteUserAccountResult> {
  const result = await request.delete(`/api/v1/user/${userId}`);
  return result.body;
}

async function renewToken(
  [tokenOverride]: types.RenewTokenArgs,
  request: SuperAgentStatic,
  token: string,
): Promise<types.RenewTokenResult> {
  const result = await request
    .post('/api/v1/auth/token')
    .set('Authorization', tokenOverride || token);

  return {
    token: result.body.spareminToken,
  };
}

export const handle: types.IHandle = (
  method: any,
  args: any,
  token?: string,
  userId?: number,
): any => {
  const request = createRequest({
    token,
    baseUrl: spareminConfig.services.user,
    omitBearerPrefix: true,
  });

  switch (method) {
    case types.ServiceMethod.CREATE_PROFILE:
      return createProfile(args, request, userId);

    case types.ServiceMethod.UPDATE_PROFILE:
      return updateProfile(args, request, userId);

    case types.ServiceMethod.GET_PREFERENCES_FOR_USER:
      return getPreferencesByUserId(args, request, userId);

    case types.ServiceMethod.UPDATE_PREFERENCES_FOR_USER:
      return updatePreferencesByUserId(args, request, userId);

    case types.ServiceMethod.GET_PROFILE_BY_ID:
      return getProfileById(args, request);

    case types.ServiceMethod.GET_INTEGRATORS_FOR_USER:
      return getIntegratorsByUserId(args, request, userId);

    case types.ServiceMethod.GET_ORGANIZATION_FOR_USER:
      return getOrganizationByUserId(args, request, userId);

    case types.ServiceMethod.GET_MY_PROFILE: {
      const id = userId ?? decode(token).sub;
      return getProfileById([id], request);
    }

    case types.ServiceMethod.DELETE_USER_ACCOUNT:
      return deleteUserAccount(request, userId);

    case types.ServiceMethod.RENEW_TOKEN:
      return renewToken(args, request, token);

    default:
      throw new Error(`${types.ACTION_KEY} cannot handle method ${method}`);
  }
};
