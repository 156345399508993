import cn from 'classnames';
import * as React from 'react';
import Spinner from 'react-spinkit';

import { block } from './utils';

interface IProps {
  className?: string;
  message: string;
}

const FileUploaderOverlay: React.SFC<IProps> = ({ className, message }) => (
  <div className={cn(block('loading-overlay'), className)}>
    <div className={block('status-message')}>{message}</div>
    <Spinner
      className="spinner--blue spinner--wave spinner--large"
      spinnerName="wave"
    />
    <div className={block('status-details')}>
      This may take a while, so please keep your browser open and check back
      later.
    </div>
  </div>
);

export default FileUploaderOverlay;
export { IProps as FileUploaderOverlayProps };
