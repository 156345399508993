import * as React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import PodcastConnectModalContent from './PodcastConnectModalContent';

const PodcastConnectModal = () => {
  return (
    <ConnectedModal name="PodcastConnect" title="Podcast search">
      <PodcastConnectModalContent />
    </ConnectedModal>
  );
};

export default PodcastConnectModal;
