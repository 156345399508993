import {
  IManualTranscriptChunk,
  ISubChunk,
  Word,
} from 'redux/middleware/api/transcript-service/types';

const split = (subchunk: ISubChunk, maxLength: number, splitAt: number) => {
  const { endMillis, text, words } = subchunk;
  if (!text) return [subchunk];

  const newSubchunks: ISubChunk[] = words.reduce(
    (acc: ISubChunk[], word: Word) => {
      if (acc.length === 0) {
        return [
          {
            id: undefined,
            startMillis: word.startMillis,
            endMillis: word.endMillis,
            text: word.text,
            words: [word],
          },
        ];
      }

      const last = acc[acc.length - 1];
      const nextSentence = `${last.text} ${word.text}`;
      if (nextSentence.length > splitAt) {
        return [
          ...acc,
          {
            id: undefined,
            endMillis: word.endMillis,
            startMillis: word.startMillis,
            text: word.text,
            words: [word],
          },
        ];
      }

      return [
        ...acc.slice(0, acc.length - 1),
        {
          id: undefined,
          endMillis: word.endMillis,
          startMillis: last.startMillis,
          text: nextSentence,
          words: [...last.words, word],
        },
      ];
    },
    [],
  );

  const newLength = newSubchunks.length;
  const newLast = newSubchunks[newLength - 1];
  newLast.endMillis = endMillis;

  if (newLength > 1) {
    const beforeLast = newSubchunks[newLength - 2];
    if (beforeLast.text.length + newLast.text.length < maxLength) {
      newSubchunks.splice(newLength - 2, 2, {
        id: undefined,
        endMillis: newLast.endMillis,
        startMillis: beforeLast.startMillis,
        text: `${beforeLast.text} ${newLast.text}`,
        words: [...beforeLast.words, ...newLast.words],
      });
    }
  }

  return newSubchunks;
};

const rechunk = (
  chunks: IManualTranscriptChunk[],
  maxLength: number,
  splitAt: number = maxLength,
): IManualTranscriptChunk[] => {
  return chunks.map(chunk => ({
    ...chunk,
    subchunks: chunk.subchunks.reduce((acc, subchunk) => {
      if (subchunk.text.length <= maxLength) {
        return [...acc, { ...subchunk }];
      }

      return [...acc, ...split(subchunk, maxLength, splitAt)];
    }, []),
  }));
};

export default rechunk;
