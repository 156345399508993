import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Step } from 'components/SteppedModal';
import usePrevious from 'hooks/usePrevious';
import {
  hasInstagramAccountsSelector,
  instagramAccountsLoadingSelector,
  instagramAccountsSelector,
  instagramPostStatusSelector,
  instagramUserIdSelector,
} from 'redux/modules/social';
import { PostStatus } from 'redux/modules/social/constants';
import { Type } from 'redux/modules/social/instagram/action-types';
import { Dispatch } from 'redux/types';
import LoaderStep from '../LoaderStep';

export interface UseLoaderStepConfig {
  goToStep: (nextStepId: string) => void;
  onHide: () => void;
}

export default function useLoaderStep({
  goToStep,
  onHide,
}: UseLoaderStepConfig): Step {
  const dispatch = useDispatch<Dispatch>();

  const isInstagramAccountsLoading = useSelector(
    instagramAccountsLoadingSelector,
  );
  const hasInstagramAccounts = useSelector(hasInstagramAccountsSelector);
  const instagramAccounts = useSelector(instagramAccountsSelector);
  const selectedUserId = useSelector(instagramUserIdSelector);
  const postStatus = useSelector(instagramPostStatusSelector);

  const handleGoToInitialStep = useCallback((): void => {
    goToStep(hasInstagramAccounts ? 'select-account' : 'no-account-found');
  }, [goToStep, hasInstagramAccounts]);

  useEffect(() => {
    if (isInstagramAccountsLoading) {
      return;
    }

    if (hasInstagramAccounts && !selectedUserId) {
      dispatch({
        type: Type.INSTAGRAM_USER_ID_SELECT,
        payload: instagramAccounts[0].instagramId,
      });
    }

    handleGoToInitialStep();
  }, [
    dispatch,
    handleGoToInitialStep,
    hasInstagramAccounts,
    instagramAccounts,
    isInstagramAccountsLoading,
    selectedUserId,
  ]);

  const prevPostStatus = usePrevious(postStatus);

  if (
    prevPostStatus === PostStatus.IN_PROGRESS &&
    (postStatus === PostStatus.SUCCESS || postStatus === PostStatus.FAILURE)
  ) {
    onHide();
    handleGoToInitialStep();
  }

  return {
    id: 'loader',
    component: <LoaderStep />,
    hideFooter: true,
  };
}
