import React from 'react';
import { block } from './utils';

interface MediaCardContentStampProps {
  children?: React.ReactNode;
}

const MediaCardContentStamp: React.FC<MediaCardContentStampProps> = ({
  children,
}) => {
  return <div className={block('stamp')}>{children}</div>;
};

export default MediaCardContentStamp;
