import * as React from 'react';

import { blockTooltip } from '../../utils';
import Image from './Image';
import Video from './Video';

interface IProps {
  content?: React.ReactNode;
  imageSrc?: string;
  videoSrc?: string;
}

const StepItem: React.SFC<IProps> = ({ content, imageSrc, videoSrc }) => {
  const renderAnimationAsset = () => {
    if (imageSrc) {
      return <Image src={imageSrc} />;
    }

    if (videoSrc) {
      return <Video src={videoSrc} />;
    }

    return '';
  };

  return (
    <div>
      <div className={blockTooltip('animation')}>{renderAnimationAsset()}</div>

      <div className={blockTooltip('content')}>{content}</div>
    </div>
  );
};

export default StepItem;
export { IProps as StepItemProps };
