export enum Type {
  TIKTOK_USER_DATA_GET_AWAIT_REQUEST = 'app/social/tiktok/USER_DATA_GET_AWAIT_REQUEST',
  TIKTOK_USER_DATA_GET_AWAIT_SUCCESS = 'app/social/tiktok/USER_DATA_GET_AWAIT_SUCCESS',
  TIKTOK_USER_DATA_GET_AWAIT_FAILURE = 'app/social/tiktok/USER_DATA_GET_AWAIT_FAILURE',

  TIKTOK_USER_DATA_GET_REQUEST = 'app/social/tiktok/USER_DATA_GET_REQUEST',
  TIKTOK_USER_DATA_GET_SUCCESS = 'app/social/tiktok/USER_DATA_GET_SUCCESS',
  TIKTOK_USER_DATA_GET_FAILURE = 'app/social/tiktok/USER_DATA_GET_FAILURE',

  TIKTOK_USER_DATA_CLEAR = 'app/social/tiktok/USER_DATA_CLEAR',

  TIKTOK_VIDEO_POST_REQUEST = 'app/social/tiktok/VIDEO_POST_REQUEST',
  TIKTOK_VIDEO_POST_SUCCESS = 'app/social/tiktok/VIDEO_POST_SUCCESS',
  TIKTOK_VIDEO_POST_FAILURE = 'app/social/tiktok/VIDEO_POST_FAILURE',

  TIKTOK_VIDEO_POST_AWAIT_REQUEST = 'app/social/tiktok/VIDEO_POST_AWAIT_REQUEST',
  TIKTOK_VIDEO_POST_AWAIT_SUCCESS = 'app/social/tiktok/VIDEO_POST_AWAIT_SUCCESS',
  TIKTOK_VIDEO_POST_AWAIT_FAILURE = 'app/social/tiktok/VIDEO_POST_AWAIT_FAILURE',

  TIKTOK_CREATOR_INFO_REQUEST = 'app/social/tiktok/TIKTOK_CREATOR_INFO_REQUEST',
  TIKTOK_CREATOR_INFO_SUCCESS = 'app/social/tiktok/TIKTOK_CREATOR_INFO_SUCCESS',
  TIKTOK_CREATOR_INFO_FAILURE = 'app/social/tiktok/TIKTOK_CREATOR_INFO_FAILURE',

  TIKTOK_AUTHORIZE_SUCCESS = 'app/social/tiktok/AUTHORIZE_SUCCESS',
}
