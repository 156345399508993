import { Flipped, Flipper } from 'react-flip-toolkit';
import Slider from 'react-slick';
import {
  AudioClipperComponent,
  RegionUpdateAction,
} from 'components/AudioClipper';
import { BemCssTransitionProps } from 'components/BemCssTransition';
import { AsyncAudioClipperProps } from 'containers/AsyncAudioClipper';
import { Omit, RequestStatus } from 'types';
import { useNavigation } from './containers/ClipSelectNavigationContext/navigation-machine';

export type ClipSelectNavigationContextType = ReturnType<typeof useNavigation>;

export interface AudioPlayer {
  seek: (millis: number) => void;
  play: () => void;
  pause: () => void;
}

export type Region = AsyncAudioClipperProps['region'];

export type ClipSelectPageView = 'select' | 'clip';

export enum ClipSelectionType {
  DEFAULT = 'Default',
  SUGGESTED_CLIP = 'SuggestedClip',
  CUSTOM_CLIP = 'CustomClip',
}

export interface FormValues {
  region: Region;
  lastClipSelectionType: ClipSelectionType;
  isCaptionEnabled: boolean;
}

export type CreateVideo = (values: FormValues) => void;

export interface ClipSelectContextType {
  activeSuggestionId: number;
  dislikedSuggestionIds: number[];
  playerRef: React.RefObject<AudioPlayer>;
  clipperRef: React.RefObject<AudioClipperComponent>;
  playbackMillis: number;
  playing: boolean;
  region?: Region;
  removedSuggestionIds: number[];
  removeSuggestion: (id: number) => void;
  selectedRegion?: Region;
  setActiveSuggestionId: (id: number) => void;
  status: RequestStatus;
  submitStatus: RequestStatus;
  suggestionIds: number[] | undefined;
  onAudioProcess: (millis: number) => void;
  onSuggestionSelect: (region: Region) => void;
  onRegionChange: (region: Region, action?: RegionUpdateAction) => void;
  onAdjust: () => void;
  onSubmit: (isCaptionsEnabled?: boolean) => void;
  togglePlayback: () => void;
  visibleSuggestionIds: number[];
}

type AnimateableComponents =
  | 'clipper'
  | 'clipSelector'
  | 'closeClipperButton'
  | 'export'
  | 'footer'
  | 'footerCta'
  | 'header'
  | 'intro'
  | 'learnButton'
  | 'loader';

type FlipperProps = Omit<
  React.ComponentPropsWithoutRef<typeof Flipper>,
  'children'
>;
type FlippedProps = Omit<
  React.ComponentPropsWithoutRef<typeof Flipped>,
  'children'
>;

type SuggestionCardAnimationConfig = Record<
  'sizer' | 'sizerInverter' | 'vanisher',
  FlippedProps & { className: string }
>;

export interface ClipSelectAnimationContextType
  extends Record<AnimateableComponents, BemCssTransitionProps> {
  clipSelectorFlipper: FlipperProps;
  clipSelectorSliderRef: React.MutableRefObject<Slider>;
  createSuggestionCardAnimationConfig: (
    id: number,
  ) => SuggestionCardAnimationConfig;
}

export interface ClipSelectTrackingContextType {
  onAddClip: () => void;
  onAdjustClip: () => void;
  onCaptionsModalSubmit: (enabled: boolean) => void;
  onDislikeClip: (reason: string) => void;
  onSelectOwnClip: () => void;
}
