import cn from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import uncontrollable from 'uncontrollable';
import { noop } from 'underscore';

import FadingScrollBars from 'components/FadingScrollBars';
import { addRecentFont } from 'redux/modules/fonts/actions';
import { Dispatch } from 'redux/types';
import { Omit } from 'types';
import MenuButton, { MenuButtonProps } from '../MenuButton';
import FontListSection from './FontListSection';
import { FontSelectOption } from './types';
import { block } from './utils';

type PickedMenuButtonProps = Omit<MenuButtonProps, 'onIconClick' | 'onClose'>;

interface IProps extends PickedMenuButtonProps {
  className?: string;
  dropdownClassName?: string;
  isOpen?: boolean;
  onChange?: (val: any) => void;
  onControlClick?: () => void;
  onToggleOpen?: (isOpen: boolean) => void;
  allFonts?: FontSelectOption[];
  userFonts?: FontSelectOption[];
  recentFonts?: FontSelectOption[];
  optionClassName?: string;
  value?: any;
  footer?: React.ReactNode;
}

function FontSelectInput(props: IProps) {
  const dispatch = useDispatch<Dispatch>();
  const {
    onControlClick,
    className,
    dropdownClassName,
    isOpen,
    onChange,
    onToggleOpen,
    optionClassName,
    allFonts,
    userFonts,
    recentFonts,
    value,
    footer,
    ...menuButtonProps
  } = props;

  function handleOptionClick(val: string) {
    onChange(val);
    dispatch(addRecentFont(val));
    onToggleOpen(false);
  }

  function handleMenuIconClick() {
    onControlClick();
    onToggleOpen(!isOpen);
  }

  function handleClose() {
    onToggleOpen(false);
  }

  const fontListSectionProps = {
    optionClassName,
    onOptionClick: handleOptionClick,
    selectedValue: value,
  };

  return (
    <MenuButton
      {...menuButtonProps}
      className={cn(block('select'), className)}
      onClose={handleClose}
      onIconClick={handleMenuIconClick}
    >
      {isOpen && (
        <div className={cn(block('select-dropdown'), dropdownClassName)}>
          <FadingScrollBars className={block('fonts-scrollbars')}>
            <FontListSection
              {...fontListSectionProps}
              options={recentFonts}
              title="Recent Fonts"
            />
            <FontListSection
              {...fontListSectionProps}
              options={userFonts}
              title="My Fonts"
            />
            <FontListSection
              {...fontListSectionProps}
              options={allFonts}
              // this section will always exist as it's all of the fonts we support out
              // of the box.  the only case where this section needs a title is if there
              // are fonts in at last one of the other two sections.  If there are no fonts
              // in the other sections, this section has no title and the list renders
              // "flat" with no groups
              title={
                (recentFonts.length > 0 || userFonts.length > 0) && 'All Fonts'
              }
            />
          </FadingScrollBars>
          {footer}
        </div>
      )}
    </MenuButton>
  );
}

FontSelectInput.defaultProps = {
  onChange: noop,
  onControlClick: noop,
  onToggleOpen: noop,
} as Partial<IProps>;

export type FontSelectInputProps = IProps & {
  defaultIsOpen?: IProps['isOpen'];
  defaultValue?: IProps['value'];
};

const UncontrollableFontSelectInput: React.ComponentClass<FontSelectInputProps> = uncontrollable(
  FontSelectInput,
  {
    isOpen: 'onToggleOpen',
    value: 'onChange',
  },
);

export { FontSelectInput };
export default UncontrollableFontSelectInput;
