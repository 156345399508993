import * as React from 'react';
import { noop } from 'underscore';

import FormField, { FormFieldProps } from './FormField';

type InputProps = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  'value' | 'placeholder' | 'autoComplete' | 'name'
>;
type Props = FormFieldProps<InputProps> & {
  onChange?: (val: string, e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default class TextField extends React.Component<Props> {
  private input;

  public static defaultProps: Partial<Props> = {
    onChange: noop,
  };

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.props.onChange(e.target.value, e);

  private inputRef = el => (this.input = el);

  public focus = () => this.input && this.input.focus();

  public render() {
    const { onChange, autoComplete, name, ...props } = this.props;

    return (
      <FormField<InputProps> {...props}>
        <input
          type="text"
          onChange={this.handleChange}
          ref={this.inputRef}
          {...{ autoComplete, name }}
        />
      </FormField>
    );
  }
}

export { Props as TextFieldProps };
