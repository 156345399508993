import { SocialSharePlatform, YoutubeVideoVisibility } from 'types';
import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'THIRD_PARTY_AUTHENTICATION_SERVICE';
export const ACTION_KEY: ActionKey = 'THIRD_PARTY_AUTHENTICATION_SERVICE';

export type SocialAuthProvider = 'youtube';

export type GettyAuthProvider = 'getty' | 'thinkstock' | 'gettyCreative';

export enum ServiceMethod {
  AUTHORIZE = 'AUTHORIZE',
  CREATE_YOUTUBE_PLAYLIST = 'CREATE_YOUTUBE_PLAYLIST',
  GET_SOCIAL_AUTH_STATUS = 'GET_SOCIAL_AUTH_STATUS',
  GET_SOCIAL_AUTH_TOKENS = 'GET_SOCIAL_AUTH_TOKENS',
  GET_SOCIAL_PROFILE = 'GET_SOCIAL_PROFILE',
  GET_LINKEDIN_ORGANIZATIONS = 'GET_LINKEDIN_ORGANIZATIONS',
  GET_INSTAGRAM_ACCOUNTS = 'GET_INSTAGRAM_ACCOUNTS',
  GET_FACEBOOK_PAGES = 'GET_FACEBOOK_PAGES',
  GET_SOCIAL_YOUTUBE_CHANNEL = 'GET_SOCIAL_YOUTUBE_CHANNEL',
  DELETE_APPS_CONNECTED = 'DELETE_APPS_CONNECTED',
  GET_APPS_CONNECTED = 'GET_APPS_CONNECTED',
  GET_GETTY_PROVIDER_TOKEN = 'GET_GETTY_PROVIDER_TOKEN',
  GET_YOUTUBE_PLAYLISTS = 'GET_YOUTUBE_PLAYLISTS',
  GET_TIKTOK_CREATOR_INFO = 'GET_TIKTOK_CREATOR_INFO',
  GET_INSTAGRAM_ACCOUNT_INFO = 'GET_INSTAGRAM_ACCOUNT_INFO',
}

export type YouTubeChannelLongStatus = 'eligible' | 'disallowed' | 'allowed';

export type SocialShareScopeContext =
  | 'youtubePostVideo'
  | 'youtubePlaylist'
  | 'tiktokManage'
  | 'youtubeManage'
  | 'linkedinManage';

interface IGettyProviderResult {
  accessTokenInfo: {
    accessToken?: string;
    accessTokenSecret?: string;
    expiresAt?: string;
  };
  provider: string;
  providerDetail: {
    isMarketplace: boolean;
    resourceOwnerId?: string;
  };
}

export type GetGettyProviderTokenArgs = [GettyAuthProvider[]];
export interface GetGettyProviderTokenResult {
  providers: IGettyProviderResult[];
  userId: number;
}

export type AuthorizeArgs = [SocialAuthProvider];
export type AuthorizeResult = void;

export type GetSocialAuthStatusArgs = [
  SocialSharePlatform, // provider
  SocialShareScopeContext, // scopeContext
  boolean, // forceLogin
];
export interface GetSocialAuthStatusResult {
  provider: SocialSharePlatform;
  providerUserId: string;
  accessTokenInfo: {
    accessToken: string;
    accessTokenSecret: string;
    expiresAt: number;
  };
  idTokenInfo: {
    idToken: string;
    expiresAt: number;
  };
  requestUrl: string;
}

export type GetSocialYoutubeChannelArgs = [
  string, // providerUserId
  string, // accessToken
];

export type GetSocialProfileArgs = [
  SocialSharePlatform,
  string, // providerUserId
  string, // idToken
  string, // accessToken
  string, // accessTokenSecret
];

export type GetLinkedinOrganizationsArgs = [
  SocialSharePlatform,
  string, // providerUserId
  string, // idToken
  string, // accessToken
];

export type GetInstagramAccountsArgs = [
  string, // providerUserId
  string, // accessToken
];

export interface GetInstagramAccountsResult {
  instagramId: string;
  profilePictureUrl: string;
  username: string;
}

export type GetFacebookPagesArgs = [
  string, // providerUserId
  string, // accessToken
];

export interface GetFacebookPagesResult {
  imageUrl: string;
  name: string;
  pageAccessToken: string;
  pageId: string;
}

export interface GetSocialYoutubeChannelResult {
  id: string | null;
  title: string | null;
  longUploadsStatus: YouTubeChannelLongStatus | null;
}

export interface GetSocialProfileResult {
  id: string;
  username: string;
  profileImageUrl: string;
}

export type DeleteAppsConnectedArgs = { provider: SocialAuthProvider };

export type GetAppsConnectedArgs = { provider?: SocialAuthProvider };

export type GetYoutubePlaylistsArgs = {
  providerUserId: string;
  accessToken: string;
};

export type GetYoutubeCategoriesArgs = {};

export type GetYoutubeLanguagesArgs = {};

export type YoutubePlaylist = {
  id: string;
  title: string;
};

export type GetPlaylistsResult = YoutubePlaylist[];

export type GetTikTokCreatorInfoArgs = {
  providerUserId: string;
  accessToken: string;
};

export type GetInstagramAccountInfoArgs = {
  providerUserId: string;
  accessToken: string;
  instagramId: string | number;
};

export type TikTokPrivacyLevelOptionName =
  | 'publicToEveryone'
  | 'mutualFollowFriends'
  | 'selfOnly'
  | 'followerOfCreator';

export interface TikTokPrivacyLevelOption {
  name: TikTokPrivacyLevelOptionName;
  label: string;
}

export interface TikTokCreatorInfo {
  isAccountPublic: boolean;
  isCommentDisabled: boolean;
  isDuetDisabled: boolean;
  isStitchDisabled: boolean;
  privacyLevelOptions: TikTokPrivacyLevelOption[];
}

export type GetTikTokCreatorInfoResult = TikTokCreatorInfo;

export interface InstagramAccountInfo {
  isBusinessAccount: boolean;
}

export type GetInstagramAccountInfoResult = InstagramAccountInfo;

export interface CreateYoutubePlaylistArgs {
  /**
   * Required for anonymous calls (no sparemin jwt)
   */
  providerUserId?: string;
  /**
   * Required for anonymous calls (no sparemin jwt)
   */
  accessToken?: string;
  playlistTitle: string;
  privacyStatus: YoutubeVideoVisibility;
  imageUrl?: string;
}

export interface CreateYoutubePlaylistResult {
  result: string;
  entities: {
    youtubePlaylists: {
      [id: string]: YoutubePlaylist;
    };
  };
}

export type ProviderAccess = {
  provider: SocialAuthProvider;
  hasAccess: boolean;
};

export type GetAppsConnectedResult = {
  accesses: ProviderAccess[];
};

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export type GetGettyProviderTokenAction = ApiAction<
  ServiceMethod.GET_GETTY_PROVIDER_TOKEN,
  GetGettyProviderTokenArgs
>;

export type AuthorizeAction = ApiAction<ServiceMethod.AUTHORIZE, AuthorizeArgs>;

export type GetSocialAuthStatusAction = ApiAction<
  ServiceMethod.GET_SOCIAL_AUTH_STATUS,
  GetSocialAuthStatusArgs
>;

export type GetSocialProfileAction = ApiAction<
  ServiceMethod.GET_SOCIAL_PROFILE,
  GetSocialProfileArgs
>;

export type GetLinkedinOrganizationsAction = ApiAction<
  ServiceMethod.GET_LINKEDIN_ORGANIZATIONS,
  GetLinkedinOrganizationsArgs
>;

export type GetInstagramAccountsAction = ApiAction<
  ServiceMethod.GET_INSTAGRAM_ACCOUNTS,
  GetInstagramAccountsArgs
>;

export type GetFacebookPagesAction = ApiAction<
  ServiceMethod.GET_FACEBOOK_PAGES,
  GetFacebookPagesArgs
>;

export type GetSocialYoutubeChannelAction = ApiAction<
  ServiceMethod.GET_SOCIAL_YOUTUBE_CHANNEL,
  GetSocialYoutubeChannelArgs
>;

export type GetAppsConnectedAction = ApiAction<
  ServiceMethod.GET_APPS_CONNECTED,
  GetAppsConnectedArgs
>;

export type DeleteAppsConnectedAction = ApiAction<
  ServiceMethod.DELETE_APPS_CONNECTED,
  DeleteAppsConnectedArgs
>;

export type GetYoutubePlaylistsAction = ApiAction<
  ServiceMethod.GET_YOUTUBE_PLAYLISTS,
  GetYoutubePlaylistsArgs
>;

export type GetTikTokCreatorInfoAction = ApiAction<
  ServiceMethod.GET_TIKTOK_CREATOR_INFO,
  GetTikTokCreatorInfoArgs
>;

export type GetInstagramAccountInfoAction = ApiAction<
  ServiceMethod.GET_INSTAGRAM_ACCOUNT_INFO,
  GetInstagramAccountInfoArgs
>;

export type CreateYoutubePlaylistAction = ApiAction<
  ServiceMethod.CREATE_YOUTUBE_PLAYLIST,
  CreateYoutubePlaylistArgs
>;

export interface ThirdPartyAuthenticationServiceDispatch {
  (action: GetGettyProviderTokenAction): Promise<
    IApiResponse<GetGettyProviderTokenResult>
  >;
  (action: AuthorizeAction): Promise<IApiResponse<AuthorizeResult>>;
  (action: GetSocialAuthStatusAction): Promise<
    IApiResponse<GetSocialAuthStatusResult>
  >;
  (action: GetSocialYoutubeChannelAction): Promise<
    IApiResponse<GetSocialYoutubeChannelResult>
  >;
  (action: GetSocialProfileAction): Promise<
    IApiResponse<GetSocialProfileAction>
  >;
  (action: GetLinkedinOrganizationsAction): Promise<
    IApiResponse<GetLinkedinOrganizationsAction>
  >;
  (action: GetInstagramAccountsAction): Promise<
    IApiResponse<GetInstagramAccountsResult>
  >;
  (action: GetFacebookPagesAction): Promise<
    IApiResponse<GetFacebookPagesResult>
  >;
  (action: GetAppsConnectedAction): Promise<
    IApiResponse<GetAppsConnectedResult>
  >;
  (action: DeleteAppsConnectedAction): Promise<IApiResponse<void>>;
  (action: GetYoutubePlaylistsAction): Promise<
    IApiResponse<GetPlaylistsResult>
  >;
  (action: GetTikTokCreatorInfoAction): Promise<
    IApiResponse<GetTikTokCreatorInfoResult>
  >;
  (action: GetInstagramAccountInfoAction): Promise<
    IApiResponse<GetInstagramAccountInfoResult>
  >;
  (action: CreateYoutubePlaylistAction): Promise<
    IApiResponse<CreateYoutubePlaylistResult>
  >;
}
