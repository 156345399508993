import * as React from 'react';

import { DurationField, DurationFieldProps } from 'components/Form';
import { Omit } from 'types';
import SlideOptionsField from './SlideOptionsField';

type Props = Omit<DurationFieldProps, 'label'>;

const SlideEndTimeField: React.SFC<Props> = ({ className, ...props }) => (
  <SlideOptionsField className={className}>
    <DurationField {...props} label="End Time" />
  </SlideOptionsField>
);

export default SlideEndTimeField;
export { Props as SlideEndTimeFieldProps };
