import React from 'react';

import { OutputOption } from 'redux/modules/edit-automation';

import useEditAutomationModal from '../hooks/useEditAutomationModal';
import { getSubscriptionItem } from '../utils';
import useHandleOutputSelection from './useHandleOutputSelection';
import { mapOutputUpdateData } from './utils';

const useEditOutput = (subscriptionId: number) => {
  const { onUpdateAutomation, subscription } = useEditAutomationModal(
    subscriptionId,
  );
  const subscriptionItem = getSubscriptionItem(subscription);
  const { handleOutputSelection } = useHandleOutputSelection(
    subscriptionId,
    subscriptionItem,
    subscription,
  );

  const onConfirmOutputSelection = React.useCallback(
    async (
      option: OutputOption,
      shouldOpenEditAutomationOutputModal: boolean = true,
    ) => {
      const autopostPreference = await handleOutputSelection(
        option,
        shouldOpenEditAutomationOutputModal,
      );

      const updateAutomationData = mapOutputUpdateData({
        autopostPreference,
        selectedOption: option,
        subscription,
        subscriptionItem,
      });

      if (autopostPreference || option === 'EMAIL') {
        onUpdateAutomation(updateAutomationData, 'output');
      }
    },
    [handleOutputSelection, onUpdateAutomation, subscription, subscriptionItem],
  );

  return {
    onConfirmOutputSelection,
  };
};

export default useEditOutput;
