import * as React from 'react';
import uncontrollable from 'uncontrollable';
import { noop } from 'underscore';

import FormField, { FormFieldProps } from '../FormField';
import { block } from '../utils';
import MultiToggleFieldOption, {
  MultiToggleFieldOptionProps,
} from './MultiToggleFieldOption';

interface IOption extends Pick<MultiToggleFieldOptionProps, 'id'> {
  icon: React.ReactNode;
}

interface IProps {
  onChange?: (activeIds: Set<string>, e: React.SyntheticEvent<any>) => void;
  options: IOption[];
  value?: Set<string>;
}

type Props = FormFieldProps<IProps>;

interface IState {
  [key: string]: boolean;
}

class MultiToggleField extends React.Component<Props, IState> {
  public static defaultProps: Partial<IProps> = {
    onChange: noop,
    value: new Set(),
  };

  private handleClick: MultiToggleFieldOptionProps['onClick'] = (id, e) => {
    const { onChange, value } = this.props;

    const result = new Set(value);

    if (!result.delete(id)) {
      result.add(id);
    }

    onChange(result, e);
  };

  public render() {
    const { disabled, onChange, options, value, ...fieldProps } = this.props;

    return (
      <FormField
        {...fieldProps}
        disabled={disabled}
        controlClassName={block('multi-control')}
      >
        <div>
          {options.map(({ id, icon }) => (
            <MultiToggleFieldOption
              id={id}
              key={id}
              active={value.has(id)}
              disabled={disabled}
              onClick={this.handleClick}
            >
              {icon}
            </MultiToggleFieldOption>
          ))}
        </div>
      </FormField>
    );
  }
}

export type MultiToggleFieldProps = Props & {
  defaultValue?: IProps['value'];
};

const UncontrolledMultiToggleField: React.SFC<MultiToggleFieldProps> = uncontrollable(
  MultiToggleField,
  { value: 'onChange' },
);

export { MultiToggleField };
export default UncontrolledMultiToggleField;
