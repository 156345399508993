import {
  AuthenticationProvider,
  AuthStep,
  UserAuthenticationModal as BaseUserAuthenticationModal,
  UserAuthResult,
} from '@sparemin/auth';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useAuthenticationSuccessHandler from 'blocks/Authentication/hooks/useAuthenticationSuccessHandler';
import useRegistrationQuery from 'blocks/Authentication/hooks/useRegistrationQuery';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import { onForgotPassword } from 'redux/modules/mixpanel';
import { AuthenticationModal } from 'redux/modules/modal/types';
import { Dispatch } from 'redux/types';

export interface UserAuthenticationModalProps {}

const UserAuthenticationModal: React.FC<UserAuthenticationModalProps> = () => {
  const dispatch = useDispatch<Dispatch>();
  const { promoKey, referralKey, traceId } = useRegistrationQuery();
  const {
    defaultErrorHandler,
    onAuthenticationSuccess,
  } = useAuthenticationSuccessHandler({
    rethrowErrors: true,
  });

  const { onExited, onHide, params, show } = useConnectedModal<
    AuthenticationModal
  >('Authentication');

  const initialStep = params?.initialStep ?? 'login';

  const handleHide = useCallback(
    (isAuthenticated: boolean = false) => {
      onHide({ isAuthenticated });
    },
    [onHide],
  );

  const handleSubmit = useCallback(
    (step: AuthStep) => {
      if (step === 'forgot-password') {
        dispatch(onForgotPassword());
      }
    },
    [dispatch],
  );

  const handleSuccess = useCallback(
    async (
      step: AuthStep,
      provider: AuthenticationProvider,
      data: UserAuthResult,
    ) => {
      try {
        if (step === 'login' || step === 'sign-up') {
          await onAuthenticationSuccess(provider, data);
          handleHide(true);
        } else if (step !== 'forgot-password') {
          handleHide();
        }
      } catch (err) {
        defaultErrorHandler(err, data.eventType);
      }
    },
    [defaultErrorHandler, handleHide, onAuthenticationSuccess],
  );

  return (
    <BaseUserAuthenticationModal
      initialStep={initialStep}
      isOpen={show}
      onAfterClose={onExited}
      onClose={handleHide}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      reopenStrategy="reset"
      promotionKey={promoKey}
      {...{ referralKey, traceId }}
    />
  );
};

export default UserAuthenticationModal;
