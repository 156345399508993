import { useCallback, useState } from 'react';
import { noop } from 'underscore';

import useImageProcessor from 'hooks/useImageProcessor';
import { useTemplateState } from './context/VideoTemplateStateContext';
import { ImageIntegrationData, ImageSource } from './types/core';

interface ProcessedImageConfig {
  fileName: string;
  integrationData: ImageIntegrationData;
  original: Blob;
  src: Blob | string;
}

export type OnImageProcessed = (config: ProcessedImageConfig) => void;

export interface UseImageSourceConfig {
  onError?: (source: ImageSource, err: Error) => void;
  onImageProcessed?: OnImageProcessed;
}

export default function useImageSource({
  onError = noop,
  onImageProcessed = noop,
}: UseImageSourceConfig) {
  const [result, setResult] = useState<ProcessedImageConfig | undefined>();
  const [acceptedFile, setAcceptedFile] = useState<ImageSource>();
  const [integrationData, setIntegrationData] = useState<
    ImageIntegrationData
  >();
  const { aspectRatio } = useTemplateState();
  const fileName =
    (typeof acceptedFile === 'string' ? acceptedFile : acceptedFile?.name) ??
    'file';

  const setAcceptedSource = useCallback(
    (src: ImageSource, data?: ImageIntegrationData) => {
      if (src === acceptedFile && result) {
        onImageProcessed({
          ...result,
          integrationData: data,
        });
      } else {
        setAcceptedFile(src);
        setIntegrationData(data);
      }
    },
    [acceptedFile, onImageProcessed, result],
  );

  useImageProcessor(
    acceptedFile,
    aspectRatio,
    useCallback(() => {
      onError(acceptedFile, new Error(`Could not open ${fileName}`));
    }, [acceptedFile, fileName, onError]),
    useCallback(
      ({ originalFile, status }) => {
        if (status === 'ready') {
          const config = {
            fileName,
            integrationData,
            original: originalFile,
            src: originalFile,
          };
          setResult(config);
          onImageProcessed(config);
        }
      },
      [fileName, integrationData, onImageProcessed],
    ),
  );

  return setAcceptedSource;
}
