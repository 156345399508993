import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import { isFunction, noop } from 'underscore';

import AlignCenterToggle from './AlignCenterToggle';
import AlignLeftToggle from './AlignLeftToggle';
import AlignRightToggle from './AlignRightToggle';
import BoldToggle from './BoldToggle';
import EmojiPicker from './EmojiPicker';
import FontColorPicker from './FontColorPicker';
import FontSizeInput from './FontSizeInput';
import FontFamilySelector, { Font } from './FontsSelector/FontFamilySelector';
import ItalicsToggle from './ItalicsToggle';
import TextAlignSelector, { Alignment } from './TextAlignSelector';
import TextHighlightPicker from './TextHighlightPicker';
import TextShadowInput, { TextShadow } from './TextShadowInput';
import TextToolbar from './TextToolbar';
import ToolGroup from './ToolGroup';
import UnderlineToggle from './UnderlineToggle';

type StyleWithSubmenu =
  | 'font'
  | 'fontsize'
  | 'forecolor'
  | 'hilitecolor'
  | 'textshadow-color'
  | 'textshadow-x'
  | 'textshadow-y'
  | 'textshadow-blur'
  | 'emoji';

interface IData {
  openMenu?: StyleWithSubmenu;
}

interface IState {
  data: RecordOf<IData>;
}

const dataFactory = Record<IData>({
  openMenu: undefined,
});

interface IProps {
  bold?: boolean;
  className?: string;
  fonts?: Font[];
  fontname?: string;
  fontsize?: number;
  fontcolor?: string;
  highlightColor?: string;
  italic?: boolean;
  justify?: Alignment;
  onEmojiSelect?: (emoji: string) => void;
  onFontChange?: (name: string) => void;
  onFontColorChange?: (color: string) => void;
  onFontSizeChange?: (size: number) => void;
  onHighlightColorChange?: (color: string) => void;
  onJustifyChange?: (alignment: Alignment) => void;
  onSubmenuOpen?: (open: boolean) => void;
  onTextShadowChange?: (shadow: TextShadow) => void;
  onToggleBold?: () => void;
  onToggleItalic?: () => void;
  onToggleUnderline?: () => void;
  showEmoji?: boolean;
  textShadow?: TextShadow;
  underline?: boolean;
}

export default class DefaultTextToolbar extends React.Component<
  IProps,
  IState
> {
  public static AlignCenterToggle = AlignCenterToggle;
  public static AlignLeftToggle = AlignLeftToggle;
  public static AlignRightToggle = AlignRightToggle;
  public static BoldToggle = BoldToggle;
  public static EmojiPicker = EmojiPicker;
  public static FontColorPicker = FontColorPicker;
  public static FontFamilySelector = FontFamilySelector;
  public static FontSizeInput = FontSizeInput;
  public static ItalicsToggle = ItalicsToggle;
  public static TextAlignSelector = TextAlignSelector;
  public static TextHighlightPicker = TextHighlightPicker;
  public static TextShadowInput = TextShadowInput;
  public static ToolGroup = ToolGroup;
  public static UnderlineToggle = UnderlineToggle;

  private ele: HTMLDivElement;
  private localRef = (el: HTMLDivElement) => {
    this.ele = el;
  };

  public static defaultProps: Partial<IProps> = {
    onEmojiSelect: noop,
    onFontChange: noop,
    onFontColorChange: noop,
    onFontSizeChange: noop,
    onHighlightColorChange: noop,
    onJustifyChange: noop,
    onSubmenuOpen: noop,
    onTextShadowChange: noop,
    onToggleBold: noop,
    onToggleItalic: noop,
    onToggleUnderline: noop,
    showEmoji: true,
  };

  constructor(props: IProps) {
    super(props);
    this.state = { data: dataFactory() };
  }

  /**
   * FIXME: there seems to be a race or some issue with RootCloseWrapper.  if you open one color
   * picker and then click on another color picker without closing the first one, the
   * onToggleOpen event does not fire for the first menu. everything works correctly in storybook,
   * but not here.  we only get isOpen === false here when the user clicks off of the input, but
   * not onto another input that has a submenu with RootCloseWrapper
   */
  private handleToggleOpen = (isOpen: boolean, key: string) =>
    this.setOpenMenu(isOpen ? (key as any) : undefined);

  private handleFontChange = (name: string) => {
    const { onFontChange } = this.props;
    onFontChange(name);
    this.setOpenMenu();
  };

  private handleFontSizeChange = (size: number) => {
    const { onFontSizeChange } = this.props;
    onFontSizeChange(size);
    this.setOpenMenu();
  };

  private handleOpenShadowMenuChange = (key?: 'x' | 'y' | 'blur' | 'color') => {
    /*
     * if key is undefined, menu is closing. and we only want to listen for "open" events here.
     * menu will be closed using tinymce click handler and document click handler
     */
    if (!key) {
      this.setOpenMenu();
      return;
    }

    this.setOpenMenu(`textshadow-${key}` as any);
  };

  private setOpenMenu(
    type?: StyleWithSubmenu | ((current: StyleWithSubmenu) => StyleWithSubmenu),
  ) {
    const { onSubmenuOpen } = this.props;
    this.setState(
      ({ data }) => ({
        data: data.update('openMenu', current =>
          isFunction(type) ? type(current) : type,
        ),
      }),
      () => {
        const { data } = this.state;
        if (data.openMenu) {
          onSubmenuOpen(true);
        } else {
          onSubmenuOpen(false);
        }
      },
    );
  }

  // Used to tell this component to close any submenus in cases where the parent gets some event
  public closeSubmenu() {
    this.setOpenMenu();
  }

  public containsEventTarget(e): boolean {
    return this.ele && (this.ele === e.target || this.ele.contains(e.target));
  }

  public render() {
    const {
      bold,
      className,
      fonts,
      fontcolor,
      fontname,
      fontsize,
      highlightColor,
      italic,
      justify,
      onEmojiSelect,
      onFontColorChange,
      onHighlightColorChange,
      onJustifyChange,
      onTextShadowChange,
      onToggleBold,
      onToggleItalic,
      onToggleUnderline,
      showEmoji,
      textShadow,
      underline,
    } = this.props;
    const { data } = this.state;

    return (
      <TextToolbar className={className} domRef={this.localRef}>
        <TextToolbar.ToolGroup>
          <TextToolbar.FontFamilySelector
            args="font"
            fonts={fonts}
            isMenuOpen={data.openMenu === 'font'}
            onChange={this.handleFontChange}
            onToggleOpen={this.handleToggleOpen}
            value={fontname}
          />
          <TextToolbar.FontSizeInput
            args="fontsize"
            isOpen={data.openMenu === 'fontsize'}
            onChange={this.handleFontSizeChange}
            onToggleOpen={this.handleToggleOpen}
            value={fontsize}
          />
        </TextToolbar.ToolGroup>
        <TextToolbar.ToolGroup>
          <TextToolbar.BoldToggle onToggle={onToggleBold} active={bold} />
          <TextToolbar.ItalicsToggle
            onToggle={onToggleItalic}
            active={italic}
          />
          <TextToolbar.UnderlineToggle
            onToggle={onToggleUnderline}
            active={underline}
          />
          <TextToolbar.FontColorPicker
            args="forecolor"
            isOpen={data.openMenu === 'forecolor'}
            onChange={onFontColorChange}
            onToggleOpen={this.handleToggleOpen}
            value={fontcolor}
          />
          <TextToolbar.TextHighlightPicker
            args="hilitecolor"
            isOpen={data.openMenu === 'hilitecolor'}
            onChange={onHighlightColorChange}
            onToggleOpen={this.handleToggleOpen}
            value={highlightColor}
          />
        </TextToolbar.ToolGroup>
        <TextToolbar.ToolGroup>
          <TextToolbar.TextAlignSelector
            onChange={onJustifyChange}
            value={justify}
          />
        </TextToolbar.ToolGroup>
        <TextToolbar.ToolGroup>
          <TextToolbar.TextShadowInput
            onChange={onTextShadowChange}
            openMenu={
              (data.openMenu && data.openMenu.startsWith('textshadow')
                ? data.openMenu.split('-')[1]
                : '') as any
            }
            onOpenMenuChange={this.handleOpenShadowMenuChange}
            value={textShadow}
          />
        </TextToolbar.ToolGroup>
        {showEmoji && (
          <TextToolbar.ToolGroup>
            <TextToolbar.EmojiPicker
              args="emoji"
              isOpen={data.openMenu === 'emoji'}
              onSelect={onEmojiSelect}
              onToggleOpen={this.handleToggleOpen}
            />
          </TextToolbar.ToolGroup>
        )}
      </TextToolbar>
    );
  }
}

export { IProps as DefaultTextToolbarProps };
