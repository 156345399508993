import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getWaveFormByType } from 'blocks/AudioWaveImage/utils';
import {
  debouncedSaveConfiguration,
  updateSoundwave,
} from 'redux/modules/embed/actions';
import { soundwaveSelector } from 'redux/modules/embed/selectors';
import { Dispatch } from 'redux/types';
import { Omit } from 'types';
import TrackAsset, { TrackAssetProps } from '../../components/TrackAsset';
import { block, millisToPosition, positionToMillis } from '../../utils';

const { useCallback } = React;

const { useMemo } = React;

type PickedAssetProps = Omit<
  TrackAssetProps,
  | 'handleClassName'
  | 'id'
  | 'itemClassName'
  | 'onDragStop'
  | 'onResize'
  | 'onResizeStop'
  | 'position'
  | 'sizeBounds'
  | 'width'
>;

export type WaveformAssetProps = PickedAssetProps;

const waveformSelector = createSelector(soundwaveSelector, sw => sw?.toJS());

const WaveformAsset: React.FC<WaveformAssetProps> = ({
  pxPerSec,
  ...props
}) => {
  const waveform = useSelector(waveformSelector);
  const dispatch = useDispatch<Dispatch>();

  const dataUri = useMemo(() => {
    const WaveformImage = getWaveFormByType(waveform.waveType);
    const svgString = !WaveformImage
      ? undefined
      : encodeURIComponent(
          renderToStaticMarkup(
            <WaveformImage preserveAspectRatio style={{ fill: '#19b5fe' }} />,
          ),
        );

    return svgString && `url("data:image/svg+xml,${svgString}")`;
  }, [waveform.waveType]);

  const updateSoundwaveTimeFromPosition = useCallback(
    (leftPx: number, rightPx: number) => {
      dispatch(
        updateSoundwave({
          ...waveform,
          time: {
            endMillis: positionToMillis(rightPx, pxPerSec),
            startMillis: positionToMillis(leftPx, pxPerSec),
          },
        }),
      );
    },
    [dispatch, pxPerSec, waveform],
  );

  const handleDrag: TrackAssetProps['onDrag'] = useCallback(
    ({ width, x }) => {
      updateSoundwaveTimeFromPosition(x, x + width);
    },
    [updateSoundwaveTimeFromPosition],
  );

  const handleDragStop: TrackAssetProps['onDragStop'] = useCallback(
    (_, moved) => {
      if (moved) {
        dispatch(debouncedSaveConfiguration());
      }
    },
    [dispatch],
  );

  const handleResize: TrackAssetProps['onResize'] = useCallback(
    (width, _, pos) => {
      updateSoundwaveTimeFromPosition(pos, pos + width);
    },
    [updateSoundwaveTimeFromPosition],
  );

  const handleResizeStop: TrackAssetProps['onResizeStop'] = useCallback(() => {
    dispatch(debouncedSaveConfiguration());
  }, [dispatch]);

  return (
    <TrackAsset
      {...props}
      handleClassName={block('waveform-asset-handle')}
      id="waveform-asset"
      itemClassName={block('waveform-asset')}
      onDrag={handleDrag}
      onDragStop={handleDragStop}
      onResize={handleResize}
      onResizeStop={handleResizeStop}
      position={{
        x:
          waveform?.time?.startMillis === undefined
            ? 0
            : millisToPosition(waveform.time.startMillis, pxPerSec),
        y: 0,
      }}
      pxPerSec={pxPerSec}
      width={
        waveform?.time?.endMillis === undefined ||
        waveform?.time?.startMillis === undefined
          ? 0
          : millisToPosition(
              waveform.time.endMillis - waveform.time.startMillis,
              pxPerSec,
            )
      }
    >
      {dataUri && (
        <div
          className={block('waveform-asset-inner')}
          style={{ backgroundImage: dataUri }}
        />
      )}
    </TrackAsset>
  );
};

export default WaveformAsset;
