import { createSelector } from 'reselect';
import { State } from 'redux/types';
import { getFormattedPersonalFonts } from 'utils/fonts';
import { googleFontsSelector, personalFontsSelector } from '../entities';
import { FontState } from './types';

const stateSelector = (state: State): FontState => state.get('fonts');

export const personalFonts = createSelector(personalFontsSelector, fonts => {
  if (!fonts) {
    return undefined;
  }
  fonts = fonts?.toJS();
  fonts = Object.values(fonts ?? {});
  return getFormattedPersonalFonts(fonts);
});

export const googleFonts = createSelector(googleFontsSelector, gFonts => {
  if (!gFonts) {
    return undefined;
  }
  gFonts = gFonts?.toJS();
  return Object.values(gFonts ?? {});
});

export const isFontsLoading = createSelector(stateSelector, state =>
  state.get('isLoading'),
);

export const fontLoadingText = createSelector(stateSelector, state =>
  state.get('loadingText'),
);

export const recentFontsSelector = createSelector(
  stateSelector,
  state => state.get('recentFonts')?.toJS() ?? [],
);
