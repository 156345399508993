import cn from 'classnames';
import * as React from 'react';
import { isUndefined } from 'underscore';

import { Omit } from 'types';
import bem from 'utils/bem';

export type LinkButtonTheme = 'default' | 'cta' | 'rainbow';

type HtmlProps = Omit<React.HTMLProps<any>, 'onClick' | 'size' | 'ref' | 'key'>;

interface Props extends HtmlProps {
  componentClass?: React.ReactType;
  href?: string;
  onClick?: (e: React.MouseEvent<any>) => void;
  size?: 'md' | 'lg';
  uppercase?: boolean;
  theme?: LinkButtonTheme;
}

export type LinkButtonProps<P = {}> = Props & P;

const block = bem('link-button');

function LinkButton<P = {}>({
  className,

  componentClass: Component,
  href,
  size,
  theme,
  uppercase,
  ...rest
}: LinkButtonProps<P>) {
  const isAnchor = !isUndefined(href);

  const props = {
    className: cn(
      block({
        [`size-${size}`]: !!size,
        [theme]: true,
        uppercase,
      }),
      className,
    ),
    ...rest,
  };

  if (Component) {
    return <Component {...props} />;
  }

  return isAnchor ? (
    <a href={href} {...props} />
  ) : (
    <button {...props} type="button" />
  );
}

LinkButton.defaultProps = {
  theme: 'default',
} as Partial<Props>;

export default LinkButton;
