import * as React from 'react';

import { Ban } from 'components/icons';
import SelectTile, { SelectTileProps } from './SelectTile';

type PickedSelectTileProps = Pick<
  SelectTileProps,
  'className' | 'onClick' | 'params' | 'selected'
>;

export type NoSelectionTileProps = PickedSelectTileProps;

const NoSelectionTile: React.FC<NoSelectionTileProps> = ({
  className,
  onClick,
  params,
  selected,
}) => (
  <SelectTile {...{ className, onClick, params, selected }}>
    <Ban style={{ color: '#464e5e', height: 'auto', width: 50 }} />
  </SelectTile>
);

export default NoSelectionTile;
