import React from 'react';
import { useDispatch } from 'react-redux';

import { onClickSelfServeDiscoBanner } from 'redux/modules/mixpanel';

import { ClickSelfServeDiscoBannerSource } from 'redux/modules/mixpanel/types';
import SelfServeDiscoBanner from './SelfServeDiscoBanner';

export interface SelfServeDiscoBannerContainerProps {
  source: ClickSelfServeDiscoBannerSource;
}

const SelfServeDiscoBannerContainer: React.FC<SelfServeDiscoBannerContainerProps> = ({
  source,
}) => {
  const dispatch = useDispatch();

  const handleClickGetListens = React.useCallback(() => {
    dispatch(onClickSelfServeDiscoBanner(source));
  }, [dispatch, source]);

  return <SelfServeDiscoBanner onClickGetListens={handleClickGetListens} />;
};

export default SelfServeDiscoBannerContainer;
