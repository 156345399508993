import { FeatureState, VideoTemplateEditorFeatures } from '../types';

function extractValue(val: any, path: Array<string | number>, prevVal?: any) {
  if (path.length === 0) return val;

  if (!val) return prevVal;

  const [seg, ...restPath] = path;
  const nextVal = val?.[seg];

  return extractValue(nextVal, restPath, val);
}

export function getFeatureState(
  features: VideoTemplateEditorFeatures,
  path: Array<string | number>,
): FeatureState {
  if (!path) return true;
  return extractValue(features, path) ?? true;
}

export function isFeatureHidden(
  features: VideoTemplateEditorFeatures,
  path: Array<string | number>,
) {
  const state = getFeatureState(features, path);
  return state === 'hidden';
}
