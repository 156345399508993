import { Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import MediaCardContentButton from './MediaCardContentButton';
import MediaCardContentDescription from './MediaCardContentDescription';
import MediaCardContentStamp from './MediaCardContentStamp';
import MediaCardContentTitle from './MediaCardContentTitle';
import { block } from './utils';

interface MediaCardContentProps {
  stamp?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  buttonContent?: React.ReactNode;
  className?: string;
}

const MediaCardContent: React.FC<MediaCardContentProps> = ({
  stamp,
  title,
  description,
  buttonContent,
  className,
}) => {
  return (
    <Spacer
      orientation="vertical"
      align="flex-start"
      justify="space-between"
      className={cn(block('media-card-content'), className)}
    >
      {stamp && <MediaCardContentStamp>{stamp}</MediaCardContentStamp>}
      {title && <MediaCardContentTitle {...{ title }} />}
      {description && (
        <MediaCardContentDescription>{description}</MediaCardContentDescription>
      )}
      {buttonContent && <MediaCardContentButton {...{ buttonContent }} />}
    </Spacer>
  );
};

export default MediaCardContent;
