import React from 'react';
import { IconProps } from './types';

const Dislike: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor" fillRule="nonzero">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 1.75v7.5c0 .414.336.75.75.75h2.5c.414 0 .75-.336.75-.75v-7.5C4 1.336 3.664 1 3.25 1H.75C.336 1 0 1.336 0 1.75zM1.25 8c0-.414.336-.75.75-.75s.75.336.75.75-.336.75-.75.75-.75-.336-.75-.75zm8.5 8c-.63 0-.921-1.228-1.06-1.806-.163-.677-.331-1.377-.794-1.84-1.015-1.017-1.547-2.312-2.785-3.535C5.04 8.748 5 8.652 5 8.552v-6.68c0-.205.164-.371.368-.375.495-.01 1.147-.284 1.646-.506C8.006.55 9.24.001 10.75 0h.089c1.336 0 2.917.013 3.555.93.262.376.327.844.192 1.394.51.533.783 1.527.512 2.336.548.732.598 1.754.291 2.484l.003.003c.372.373.61.977.608 1.537-.005.949-.817 1.816-1.861 1.816H10.96c.228.886 1.04 1.63 1.04 2.955C12 15.75 10.5 16 9.75 16z"
                  transform="translate(-715.000000, -765.000000) translate(-122.000000, 428.000000) translate(765.000000, 0.000000) translate(0.000000, 320.000000) translate(72.000000, 17.000000)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Dislike;
