import * as React from 'react';

import { Plus } from 'components/icons';
import Tooltip from 'components/Tooltip';
import TemplateSelectorTile from './TemplateSelectorTile';
import { TemplateSelectorTileProps } from './types';
import { block } from './utils';

type BlankProjectTileProps = Pick<
  TemplateSelectorTileProps,
  'aspectRatioName' | 'id' | 'onClick'
>;

const BlankProjectTile: React.FC<BlankProjectTileProps> = ({
  aspectRatioName,
  onClick,
  id,
}) => {
  return (
    <TemplateSelectorTile
      className={block('blank-tile')}
      isCompatible
      permanent
      {...{ aspectRatioName, id, onClick }}
    >
      <Tooltip
        className={block('blank-tooltip')}
        content="Start with a blank canvas"
        id="blank-template"
        placement="top"
      >
        <div
          className={block('blank-tile-contents')}
          data-testid="blank-canvas"
        >
          <Plus className={block('blank-icon')} />
        </div>
      </Tooltip>
    </TemplateSelectorTile>
  );
};

export default BlankProjectTile;
