import cn from 'classnames';
import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import ToggleButton, { ToggleButtonProps } from './ToggleButton';
import { block } from './utils';

type Props = ToggleButtonProps;

const BoldToggle: React.SFC<Props> = ({ className, ...buttonProps }) => (
  <ToggleButton
    className={cn(block('bold-toggle'), className)}
    {...buttonProps}
  >
    <FontAwesome icon="bold" />
  </ToggleButton>
);

export default BoldToggle;
export { Props as UnderlineToggleProps };
