import { ThunkAction } from 'redux/types';
import { Type } from './action-types';
import { activeModalSelector, isActiveSelector } from './selectors';
import {
  Modal,
  ModalName,
  PushModalAction,
  ReplaceModalAction,
  UnshiftModalAction,
} from './types';

export const pushModal = (modal: Modal): PushModalAction => ({
  type: Type.MODAL_PUSH,
  payload: modal,
});

export const popModal = () => ({ type: Type.MODAL_POP });

export const popModalIfActive = (name: ModalName): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  if (isActiveSelector(name)(getState())) {
    dispatch(popModal());
  }
};

export const hideModal = (value?: any, name?: ModalName) => ({
  type: Type.MODAL_HIDE,
  payload: name,
  meta: { value },
});

export const replaceModal = (
  modal: Modal,
  value?: any,
): ReplaceModalAction => ({
  type: Type.MODAL_REPLACE,
  payload: modal,
  meta: { value },
});

export const unshiftModal = (modal: Modal, value?: any): UnshiftModalAction => (
  dispatch,
  getState,
) => {
  const activeModal = activeModalSelector(getState());
  if (activeModal) {
    dispatch(hideModal(value));
    dispatch(pushModal(modal));
    dispatch(pushModal(activeModal as Modal));
  } else {
    dispatch(pushModal(modal));
  }
};

export const updateModal = (modal: Modal) => ({
  type: Type.MODAL_UPDATE,
  payload: modal,
});
