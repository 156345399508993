import * as React from 'react';

import NumericSelect, { NumericSelectProps } from '../NumericSelect';
import TextShadowField, { TextShadowFieldProps } from './TextShadowField';

type Props = NumericSelectProps & TextShadowFieldProps;

const TextShadowOffsetField: React.SFC<Props> = ({ label, ...props }) => (
  <TextShadowField label={label}>
    <NumericSelect
      {...props}
      displayValues={Array(11)
        .fill(0)
        .map((__, index) => index - 5)}
    />
  </TextShadowField>
);

export default TextShadowOffsetField;
export { Props as TextShadowOffsetFieldProps };
