import * as React from 'react';

import { IconProps } from './types';

const Facebook: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M13 1.393v10.214c0 .77-.624 1.393-1.393 1.393H9.132V7.858h1.758l.253-1.962H9.132V4.643c0-.569.157-.955.972-.955h1.039V1.935c-.18-.023-.795-.078-1.515-.078-1.497 0-2.524.914-2.524 2.594V5.9H5.339v1.962h1.767V13H1.393A1.393 1.393 0 0 1 0 11.607V1.393C0 .623.624 0 1.393 0h10.214C12.377 0 13 .624 13 1.393z"
    />
  </svg>
);

export default Facebook;
