import classNames from 'classnames';
import * as React from 'react';
import { Tab } from 'react-bootstrap';
import Spinner from 'react-spinkit';
import { SwitchTransition } from 'react-transition-group';
import _ from 'underscore';

import InfiniteGrid, { InfiniteGridProps } from 'components/InfiniteGrid';
import { Engine } from './types';
import { searchResultsBlock as block } from './utils';

export type OnLoadMore = (engine: string, pageNumber: number) => void;

export interface IFooterProps {
  className: string;
}

export type RenderFooter = (props: IFooterProps) => React.ReactNode;

interface IProps extends Pick<InfiniteGridProps, 'itemSelector'> {
  additionalDisclaimer?: React.ReactNode;
  active?: string;
  className?: string;
  contentsClassName?: string;
  customLoader?: React.ReactNode;
  gridClassName?: string;
  engines?: {
    [engine: string]: Engine;
  };
  id?: string;
  providersList: React.ReactNode;
  onEngineSelect?: (engine: string) => void;
  onLoadMore?: OnLoadMore;
  renderFooter?: RenderFooter;
  renderResults?: (engine: string) => React.ReactNode[];
  initialContent?: React.ReactNode;
  topSlot: React.ReactNode;
}

export default class SearchResults extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    engines: {},
    onEngineSelect: _.noop,
    onLoadMore: _.noop,
    renderFooter: _.constant(null),
    renderResults: _.constant([]),
  };

  private handleLoadMore = (engine: string) => (pageNumber: number) => {
    const { onLoadMore } = this.props;
    onLoadMore(engine, pageNumber);
  };

  private renderTabContents(engine: string) {
    const {
      active,
      customLoader,
      engines,
      gridClassName,
      itemSelector,
      renderResults,
      initialContent,
    } = this.props;

    const {
      hasResults,
      hasMoreResults,
      isSearching,
      isLoading,
      errorMessage,
      notSupportedMessage,
    } = engines[engine];

    if (notSupportedMessage) {
      return notSupportedMessage;
    }

    if (!hasResults && !isSearching && !isLoading && initialContent) {
      return <div className={block('result-contents')}>{initialContent}</div>;
    }

    if (isLoading || (!hasResults && isSearching)) {
      if (customLoader) {
        return customLoader;
      }

      return (
        <div className={block('spinner')}>
          <Spinner className="spinner--blue" spinnerName="three-bounce" />
        </div>
      );
    }

    if (!hasResults && errorMessage) {
      return <div className={block('error')}>{errorMessage}</div>;
    }

    if (hasResults) {
      const results = renderResults(engine);
      return (
        <div className={block('results', { empty: results.length === 0 })}>
          {results.length === 0 ? (
            'No results found'
          ) : (
            <div className={block('result-contents')}>
              <InfiniteGrid
                className={block('grid')}
                gridClassName={classNames(block('tiles'), gridClassName)}
                hasMore={!isSearching && !errorMessage && hasMoreResults}
                isActive={active === engine}
                itemSelector={itemSelector}
                onLoadMore={this.handleLoadMore(engine)}
                scrollClassName="search-results__infinite"
              >
                {results}
              </InfiniteGrid>
            </div>
          )}
        </div>
      );
    }

    return null;
  }

  public render() {
    const {
      active,
      className,
      contentsClassName,
      engines,
      providersList,
      id,
      onEngineSelect,
      renderFooter,
      topSlot,
    } = this.props;

    return (
      <Tab.Container
        activeKey={active}
        className={classNames(block(), className)}
        id={id}
        onSelect={onEngineSelect as any}
      >
        <div>
          {providersList}

          <Tab.Content animation={false} unmountOnExit>
            {topSlot && (
              <div className={block('top-slot-container')}>{topSlot}</div>
            )}

            {_.isEmpty(engines) ? (
              <div className={block('contents')} />
            ) : (
              Object.keys(engines).map(engine => (
                <Tab.Pane eventKey={engine} key={engine}>
                  <div
                    className={classNames(block('contents'), contentsClassName)}
                  >
                    {this.renderTabContents(engine)}
                  </div>
                </Tab.Pane>
              ))
            )}
            {renderFooter({
              className: classNames(block('info'), block('footer')),
            })}
          </Tab.Content>
          <div className={block('powered-by')}>
            <SwitchTransition>{engines[active]?.poweredBy}</SwitchTransition>
          </div>
        </div>
      </Tab.Container>
    );
  }
}

export { IProps as SearchResultsProps };
