import { useMemo } from 'react';
import { useEditorState } from './context/VideoEditorStateContext';
import { getFeatureState } from './state/features-utils';

export default function useFeature(path: Array<string | number>) {
  const { features } = useEditorState();
  return useMemo(() => {
    return getFeatureState(features, path);
  }, [features, path]);
}
