import * as React from 'react';

import { block } from '../utils';
import EditableTime, { EditableTimeProps } from './EditableTime';

export interface EditablePhraseTimingProps {
  endMillis: number;
  endBoundsMillis: EditableTimeProps['boundsMillis'];
  onEndTimeSubmit?: EditableTimeProps['onSubmit'];
  onStartTimeSubmit?: EditableTimeProps['onSubmit'];
  startBoundsMillis: EditableTimeProps['boundsMillis'];
  startMillis: number;
}

const blockClassName = block();

export const EditablePhraseTiming: React.FC<EditablePhraseTimingProps> = ({
  endMillis,
  endBoundsMillis,
  onEndTimeSubmit,
  onStartTimeSubmit,
  startBoundsMillis,
  startMillis,
}) => (
  <div className={block('phrase-timing')}>
    <EditableTime
      activeClassName={`${blockClassName}__start-time--edit`}
      boundsMillis={startBoundsMillis}
      className={block('start-time')}
      defaultMillis={startMillis}
      displayClassName={block('phrase-start')}
      inputClassName={block('start-time-input')}
      key={`start-${startMillis}`}
      onSubmit={onStartTimeSubmit}
    />
    <EditableTime
      activeClassName={`${blockClassName}__end-time--edit`}
      boundsMillis={endBoundsMillis}
      className={block('end-time')}
      defaultMillis={endMillis}
      displayClassName={block('phrase-end')}
      inputClassName={block('end-time-input')}
      key={`end-${endMillis}`}
      onSubmit={onEndTimeSubmit}
    />
  </div>
);

export default EditablePhraseTiming;
