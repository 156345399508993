import cn from 'classnames';
import * as React from 'react';
import FontAwesome from 'components/FontAwesome';
import Tooltip from 'components/Tooltip';

interface IProps {
  className?: string;
  showTooltip: boolean;
  style?: any;
  tooltipContent?: React.ReactNode;
  tooltipId: string;
  icon?: React.ReactElement;
}

const LockIcon: React.SFC<IProps> = ({
  className,
  showTooltip,
  tooltipContent,
  tooltipId,
  icon,
}) => (
  <Tooltip
    animation={false}
    content={tooltipContent}
    id={tooltipId}
    placement="top"
    preventHideOnHover={false}
    show={showTooltip}
  >
    {React.isValidElement<{ className?: string }>(icon) ? (
      React.cloneElement(icon, {
        className: cn(icon.props.className, className),
      })
    ) : (
      <></>
    )}
  </Tooltip>
);

LockIcon.defaultProps = {
  className: undefined,
  style: {},
  tooltipContent: 'Your administrator has locked this option',
  icon: <FontAwesome icon="lock" />,
};

export default LockIcon;
export { IProps as LockIconProps };
