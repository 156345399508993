import { ICaptions } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';

import { BASE_CAPTION_OVERRIDES_MAP } from './constants';

export const getBaseCaptionsOverride = (aspectRatio: number): ICaptions => {
  try {
    const aspectRatioName = getAspectRatioName(aspectRatio);
    return BASE_CAPTION_OVERRIDES_MAP[aspectRatioName];
  } catch {
    return undefined;
  }
};
