import cn from 'classnames';
import * as React from 'react';

import { RadioOptionListProps } from './types';
import { block } from './utils';

const RadioOptionList: React.SFC<RadioOptionListProps> = props => {
  const { children, className, component } = props;
  const listClassName = cn(block('option-list'), className);

  return component ? (
    component({ children, className: listClassName })
  ) : (
    <div className={listClassName}>{children}</div>
  );
};

export default RadioOptionList;
