import Measurement from './Measurement';
import Pixels from './Pixels';
import { MeasurementContext, Unit } from './types';

export default class ViewportHeight extends Measurement<'vh', ViewportHeight> {
  constructor(value: number) {
    super(value, 'vh');
  }

  public toUnit(unit: Unit, context: MeasurementContext): any {
    if (unit === 'vh') {
      return this;
    }

    const contextValue = ViewportHeight.getContextValue(context, 'height');

    if (unit === 'px') {
      return new Pixels((this.value / 100) * contextValue);
    }

    throw new Error(`cannot convert from vh to ${unit}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public update(val: number) {
    return new ViewportHeight(val);
  }
}
