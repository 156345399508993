import { fromJS } from 'immutable';
import { onChangeProgressBar } from 'redux/modules/mixpanel';
import { ThunkAction } from 'redux/types';
import {
  ProgressAndTimerOptions,
  ProgressAnimationOptionsState,
  TimerOptionsState,
} from 'types';
import {
  EMBED_PROGRESS_ANIMATION_SET,
  EMBED_TIMER_CHANGE,
} from '../action-types';
import { progressAnimationSelector, timerSelector } from '../selectors';
import { saveConfiguration } from './embed';

const setProgressAnimation = (progress: ProgressAnimationOptionsState) => ({
  payload: { progress },
  type: EMBED_PROGRESS_ANIMATION_SET,
});

const updateTimer = (timer: TimerOptionsState) => ({
  payload: timer,
  type: EMBED_TIMER_CHANGE,
});

export const saveProgressAnimation = (
  opts: ProgressAndTimerOptions,
): ThunkAction<void> => (dispatch, getState) => {
  const { progress, timer } = opts;

  const newProgress = fromJS(progress);
  const currentProgress = progressAnimationSelector(getState());
  const progressChanged = !newProgress.equals(currentProgress);

  const newTimer = fromJS(timer);
  const currentTimer = timerSelector(getState());
  const timerChanged = !newTimer.equals(currentTimer);

  if (progressChanged) {
    dispatch(onChangeProgressBar(newProgress.get('enabled')));
    dispatch(setProgressAnimation(newProgress));
  }

  if (timerChanged) {
    dispatch(updateTimer(newTimer));
  }

  if (progressChanged || timerChanged) {
    dispatch(saveConfiguration());
  }
};
