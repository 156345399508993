import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { debounce, isFinite } from 'underscore';

import {
  episodeListScrollOffsetSelector,
  getNextEpisodesPage,
  isLoadingEpisodesSelector,
  nextEpisodePublishMillisSelector,
  selectedPodcastEpisodeIdsSelector,
  setSearchScrollOffset,
  setSelectedEpisode,
} from 'redux/modules/podcast-search';
import { Dispatch, State } from 'redux/types';
import { createChainedFunction } from 'utils/functions';
import EpisodeList, { EpisodeListProps as Props } from './EpisodeList';

type StateProps = Pick<
  Props,
  'defaultScrollOffset' | 'hasMore' | 'isLoading' | 'results'
>;
type DispatchProps = Pick<Props, 'onEpisodeClick' | 'onLoadPage' | 'onScroll'>;
type OwnProps = Pick<Props, 'onEpisodeClick'>;

const hasMoreSelector = createSelector(
  nextEpisodePublishMillisSelector,
  millis => isFinite(millis),
);

const mapStateToProps = (state: State): StateProps => ({
  defaultScrollOffset: episodeListScrollOffsetSelector(state),
  hasMore: hasMoreSelector(state),
  isLoading: isLoadingEpisodesSelector(state),
  results: selectedPodcastEpisodeIdsSelector(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onEpisodeClick: createChainedFunction(props.onEpisodeClick, ({ id }) =>
    dispatch(setSelectedEpisode(id)),
  ),
  onLoadPage: () => dispatch(getNextEpisodesPage()),
  onScroll: debounce(
    ({ scrollOffset }) =>
      dispatch(setSearchScrollOffset(scrollOffset, 'episode')),
    150,
  ),
});

const component = connect(mapStateToProps, mapDispatchToProps)(EpisodeList);
export type EpisodeListProps = React.ComponentPropsWithoutRef<typeof component>;
export default component;
