import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isFinite } from 'underscore';

import ExportBalanceBanner from 'components/ExportBalanceBanner';
import Modal from 'components/Modal';
import { ConnectedModalInjectedProps } from 'containers/ConnectedModal/ConnectedModalComponent';
import useContextualUpgrade from 'hooks/useContextualUpgrade';
import useOnMount from 'hooks/useOnMount';
import { Tier } from 'redux/middleware/api/plan-service';
import { aspectRatioNameSelector } from 'redux/modules/embed/selectors';
import {
  onClickProFeature,
  onClickUnpauseMyPlan,
} from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal';
import { subscriptionSelector, tierSelector } from 'redux/modules/pricing';
import { userVideoSizesSelector } from 'redux/modules/user-pref/selectors';
import {
  ExportUpgradeFrom,
  FRAME_QUALITY_OPTION,
  PropUpgradeFrom,
} from 'types';
import { getVideoResolution } from 'utils/video-export';
import { block } from '../../utils';
import VideoExportForm, {
  FormState,
  useFormState,
  VideoExportFormProps,
} from '../VideoExportForm';
import VideoExportModalContentsFooter from './VideoExportModalContentsFooter';

const { useCallback } = React;

export interface ExportConfig {
  emailsToNotify: string[];
  frameHeight: number;
  frameQuality: number;
  frameRate: number;
  frameWidth: number;
  intro: FormState['intro'];
  outro: FormState['outro'];
}

type PickedFormProps = Pick<VideoExportFormProps, 'lockedFields'>;

export interface VideoExportModalContentsProps
  extends PickedFormProps,
    ConnectedModalInjectedProps {
  defaultFormState?: FormState;
  onError?: (message: string) => void;
  onExport?: (config: ExportConfig) => void;
  onMount?: (isFree?: boolean) => void;
  onUpgrade?: (from: ExportUpgradeFrom) => void;
  onUpgradePlan?: (from?: PropUpgradeFrom) => void;
  videoBalance?: number;
}

const normalizeEmails = (emails: string[]) => {
  return Array.from(
    new Set(emails.filter(Boolean).map(email => email.toLowerCase())),
  );
};

const VideoExportModalContents: React.SFC<VideoExportModalContentsProps> = ({
  defaultFormState,
  lockedFields,
  onHide,
  onError,
  onExport,
  onMount,
  onUpgrade,
  onUpgradePlan,
  videoBalance,
}) => {
  const dispatch = useDispatch();
  const tier = useSelector(tierSelector);
  const aspectRatio = useSelector(aspectRatioNameSelector);
  const videoSizes = useSelector(userVideoSizesSelector);
  const { pauseSchedule } = useSelector(subscriptionSelector) || {};
  const pauseStatus = pauseSchedule?.pauseStatus ?? null;

  const hasLimitedExports = isFinite(videoBalance);

  const { onUpgrade: onContextualUpgrade } = useContextualUpgrade({
    allowTierSelection: false,
    purchaseTier: Tier.PRO,
  });

  useOnMount(() => onMount(hasLimitedExports));

  const { value, files, validate, errors, handleFormChange } = useFormState(
    {
      ...defaultFormState,
    },
    onError,
  );

  const handleUpgrade = useCallback(
    (from?: PropUpgradeFrom) => {
      const isPaused = pauseStatus === 'active';

      if (isPaused) {
        dispatch(onClickProFeature({ from }));
        dispatch(onClickUnpauseMyPlan());

        dispatch(
          replaceModal({
            name: 'UnpauseSubscription',
          }),
        );
      } else {
        onUpgradePlan(from);
      }
    },
    [dispatch, onUpgradePlan, pauseStatus],
  );

  const handleSubmit = useCallback(() => {
    const resolution = getVideoResolution(value.size, aspectRatio, videoSizes);
    if (validate()) {
      onExport({
        emailsToNotify: normalizeEmails(value.email ?? []),
        frameHeight: resolution.height,
        frameQuality: FRAME_QUALITY_OPTION.HIGH,
        frameRate: value.frameRate,
        frameWidth: resolution.width,
        intro: {
          value: files.introFile || value.intro.value,
          type: value.intro.type,
        },
        outro: {
          value: files.outroFile || value.outro.value,
          type: value.outro.type,
        },
      });
      onHide();
    }
  }, [
    aspectRatio,
    files.introFile,
    files.outroFile,
    onExport,
    onHide,
    validate,
    value.email,
    value.frameRate,
    value.intro,
    value.outro,
    value.size,
    videoSizes,
  ]);

  const handleUpgradeTopBannerClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      onUpgrade(
        tier === Tier.BASIC
          ? 'topBannerBasicUser'
          : tier === Tier.PRO
          ? 'topBannerProUser'
          : 'topBanner',
      );
    },
    [onUpgrade, tier],
  );

  const handleUpgradeButtonClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      onUpgrade(
        tier === Tier.BASIC
          ? 'buttonBasicUser'
          : tier === Tier.PRO
          ? 'buttonProUser'
          : 'button',
      );
      onContextualUpgrade();
    },
    [onContextualUpgrade, onUpgrade, tier],
  );

  const handlePriorityQueueClick = useCallback(() => {
    handleUpgrade('Priority Queue Upgrade');
  }, [handleUpgrade]);

  return (
    <>
      <Modal.Body className={block('body', { form: true })}>
        <ExportBalanceBanner
          className={block('video-balance')}
          onUpgradeClick={handleUpgradeTopBannerClick}
        />
        <VideoExportForm
          className={block('form')}
          errors={errors}
          lockedFields={lockedFields}
          onChange={handleFormChange}
          onPriorityQueueClick={handlePriorityQueueClick}
          onUpgradePlan={handleUpgrade}
          value={value}
        />
      </Modal.Body>
      <VideoExportModalContentsFooter
        onSubmit={handleSubmit}
        onUpgrade={handleUpgradeButtonClick}
        videoBalance={videoBalance}
      />
    </>
  );
};

export { VideoExportModalContents };

export default VideoExportModalContents;
