import { connect } from 'react-redux';

import {
  performDpa,
  sendDownloadPageActionEvent,
} from 'redux/modules/download';
import { onClickCopyVideoUrl } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import { PropsOf } from 'types';
import CopyVideoLinkForm, {
  CopyVideoLinkFormProps as Props,
} from './CopyVideoLinkForm';

type DispatchProps = Pick<Props, 'onCopyClick'>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onCopyClick: () => {
    dispatch(performDpa);
    dispatch(onClickCopyVideoUrl());
    dispatch(sendDownloadPageActionEvent());
  },
});

const component = connect(null, mapDispatchToProps)(CopyVideoLinkForm);
export type CopyVideoLinkFormProps = PropsOf<typeof component>;
export default component;
