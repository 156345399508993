import cn from 'classnames';
import React from 'react';
import { VideoCameraAlt2 } from 'components/icons';
import { PlanName } from 'redux/middleware/api/plan-service/types';
import SubPeriodPlanHeader, {
  SubPeriodPlanHeaderProps,
} from '../SubPeriodPlanHeader';
import { basicPlanBlock as block } from '../utils';

export interface BasicPlanHeaderProps
  extends Pick<SubPeriodPlanHeaderProps, 'period'> {
  className?: string;
  density?: 'sparse' | 'comfortable';
}

const BasicPlanHeader: React.FC<BasicPlanHeaderProps> = ({
  className,
  density = 'sparse',
  period,
}) => (
  <SubPeriodPlanHeader
    className={cn(block('header', { [density]: true }), className)}
    density={density}
    cost={{
      monthly: PlanName.BASIC_20211112_MONTHLY,
      yearly: PlanName.BASIC_20211112_YEARLY,
    }}
    description="Take your podcast promotion to the next level with more features."
    icon={<VideoCameraAlt2 icon="video" className={block('header-icon')} />}
    period={period}
    theme="basic"
    title="Basic"
  />
);

export default BasicPlanHeader;
