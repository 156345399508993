import * as React from 'react';
import Modal, { ModalProps } from 'components/Modal';
import AddLogoModalBody from 'containers/LogoModal/AddLogoModalBody';
import { ApplicationError } from 'utils/ApplicationError';

export interface AddWatermarkModalProps extends Pick<ModalProps, 'onExited'> {
  onCancel?: () => void;
  onFileAccepted?: (file: File) => void;
  onFileRejected?: (error: ApplicationError) => void;
  show?: boolean;
}

const AddWatermarkModal: React.FC<AddWatermarkModalProps> = ({
  onCancel,
  onFileAccepted,
  onFileRejected,
  onExited,
  show,
}) => {
  return (
    <Modal
      className="logo-modal"
      title="add watermark"
      show={show}
      onHide={onCancel}
      onExited={onExited}
    >
      <Modal.Body className="logo-modal__body">
        <AddLogoModalBody
          onFileAccepted={onFileAccepted}
          onFileRejected={onFileRejected}
        />
      </Modal.Body>
      <Modal.Footer className="logo-modal__footer">
        <Modal.FooterButtons>
          <Modal.FooterButton onClick={onCancel}>Cancel</Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

export default AddWatermarkModal;
