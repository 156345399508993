/*
 * fullstory is conditionally added to the app's `head` tag based on node environment.  rather than
 * use fullstory directly, use via this module which wraps sdk calls with a check to see if
 * fullstory is defined
 */
let fsReady = false;

// eslint-disable-next-line no-underscore-dangle
window._fs_ready = () => {
  fsReady = true;
};

/**
 * @typedef {object} FullStory - FullStory singleton object
 * @property {(now?: boolean) => string} getCurrentSessionURL
 * @property {(uid: string, userVars?: object) => void} identify
 * @property {(messageOrLevel?: string, message?: string) => void} log
 */

/**
 * @type {FullStory}
 */
const wrappedSdk = ['getCurrentSessionURL', 'identify', 'log'].reduce(
  (acc, fName) => ({
    ...acc,
    [fName]: (...args) => fsReady && window.FS[fName](...args),
  }),
  {},
);

export default wrappedSdk;
