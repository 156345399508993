const windowAny: any = window;

export const showLiveChatWidget = () =>
  windowAny.zE &&
  windowAny.zE(() => windowAny.zE.activate({ hideOnClose: true }));

export const addSurveyResponseToLiveChatTags = (
  podcast: string,
  sector: string,
) => addLiveChatTags([`podcast_${podcast}`, `role_${sector}`]);

export const addLiveChatTags = tags => {
  const chatTags = Array.isArray(tags) ? tags : [tags];

  windowAny.$zopim &&
    windowAny.$zopim(() =>
      windowAny.$zopim.livechat.addTags(chatTags.join(', ')),
    );
};

export const setLiveChatEmail = email =>
  // name cannot be empty/undefined
  windowAny.zE &&
  windowAny.zE(() => windowAny.zE.identify({ email, name: email }));

export const sendVisitorPath = () =>
  windowAny.$zopim &&
  windowAny.$zopim(() => windowAny.$zopim.livechat.sendVisitorPath());
