import cn from 'classnames';
import * as React from 'react';

import { block } from '../utils';
import PodcastSearchDefaultStateItem from './PodcastSearchDefaultStateItem';
import PodcastSearchDefaultStatePanel from './PodcastSearchDefaultStatePanel';
import { useIntegrationsContext } from './PodcastSearchIntegrationsContext';

interface Props {
  light?: boolean;
}

const PodcastSearchDefaultState: React.FC<Props> = props => {
  const { light } = props;
  const { integrations } = useIntegrationsContext();

  const items = integrations
    .filter(({ hidden }) => !hidden)
    .map(({ description, icon, id }) => (
      <PodcastSearchDefaultStateItem
        icon={React.cloneElement(icon, {
          className: cn(
            icon.props.className,
            block('default-state-icon', { [id]: true }),
          ),
        })}
        key={id}
      >
        {description.part1}
        <br />
        <strong>{description.part2}</strong>
      </PodcastSearchDefaultStateItem>
    ));

  return (
    <div className={block('default-state')}>
      {items.length === 2 ? (
        <>
          {items.map((item, index) => (
            <PodcastSearchDefaultStatePanel key={integrations[index].id}>
              {item}
            </PodcastSearchDefaultStatePanel>
          ))}
          <div className={block('default-state-circle')}>or</div>
        </>
      ) : (
        <PodcastSearchDefaultStatePanel>{items}</PodcastSearchDefaultStatePanel>
      )}
      {light ? (
        <picture className={block('powered-by')}>
          <source
            srcSet={[
              'https://static.sparemin.com/static/webapp-assets/logos/202103021414/listen-notes.png',
              'https://static.sparemin.com/static/webapp-assets/logos/202103021414/listen-notes@2x.png 2x',
              'https://static.sparemin.com/static/webapp-assets/logos/202103021414/listen-notes@3x.png 3x',
            ].join(', ')}
          />
          <img
            src="https://static.sparemin.com/static/webapp-assets/logos/202103021414/listen-notes.png"
            alt="Listen Notes Logo"
          />
        </picture>
      ) : (
        <img
          className={block('powered-by')}
          src="https://static.sparemin.com/static/webapp-assets/listen-notes.png"
        />
      )}
    </div>
  );
};

export default PodcastSearchDefaultState;
