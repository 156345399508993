import * as React from 'react';

import { canvasBlock as block } from '../utils';

export interface ImageAssetProps
  extends Pick<
    React.HTMLProps<HTMLImageElement>,
    'onLoad' | 'onError' | 'onLoadStart' | 'style'
  > {
  imageUrl: string;
}

const ImageAsset: React.FC<ImageAssetProps> = ({
  imageUrl,
  onLoad,
  onLoadStart,
  onError,
  style,
}) => {
  return (
    <img
      className={block('image-asset')}
      src={imageUrl}
      {...{ onLoad, onLoadStart, onError, style }}
    />
  );
};

export default ImageAsset;
