import React from 'react';
import { H3 } from 'components/Heading';
import { block } from './utils';

export interface AddBillingCycleModalSectionProps {
  children: React.ReactNode;
  title: string;
}

const AddBillingCycleModalSection: React.FC<AddBillingCycleModalSectionProps> = ({
  children,
  title,
}) => (
  <section className={block('section')}>
    <H3 className={block('title')}>{title}</H3>
    {children}
  </section>
);

export default AddBillingCycleModalSection;
