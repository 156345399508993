import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import { H2 } from 'components/Heading';
import bem from 'utils/bem';

const block = bem('reclip-mobile-message');

const ReclipMobileMessage: React.SFC = () => (
  <div className={block()}>
    <div className={block('icon-container')}>
      <FontAwesome
        className={block('icon')}
        icon="cut"
        style={{ height: 17, width: 17 }}
      />
    </div>
    <H2 className={block('text')}>
      <b>To pick another clip</b> from this episode just open this page on a
      desktop.
    </H2>
  </div>
);

export default ReclipMobileMessage;
