import React from 'react';
import List from '../List';
import CircularGradient, { CircularGradientProps } from './CircularGradient';

export interface CircularGradientListProps
  extends Pick<CircularGradientProps, 'className' | 'contentsClassName'> {}

const CircularGradientList: React.FC<CircularGradientListProps> = ({
  className,
  contentsClassName,
}) => (
  <CircularGradient {...{ className, contentsClassName }}>
    <List height="35%" width="40%" />
  </CircularGradient>
);

export default CircularGradientList;
