import cn from 'classnames';
import React from 'react';

import { Option } from 'components/Select';

import { block } from './utils';

export interface YoutubeLanguageOptionProps {
  className?: string;
  onClick?: (value: Option) => void;
  option: Option;
  selected?: boolean;
}

const YoutubeLanguageOption: React.FunctionComponent<YoutubeLanguageOptionProps> = props => {
  const { className, option, onClick, selected } = props;
  const { label } = option || {};

  const handleClick = React.useCallback(() => onClick(option), [
    onClick,
    option,
  ]);

  return (
    <div
      className={cn(block('option', { selected }), className)}
      onClick={handleClick}
    >
      <span className={block('option-label')}>{label}</span>
    </div>
  );
};

export default YoutubeLanguageOption;
