import React from 'react';
import VideoTypeIcon from 'blocks/AudiogramDestinations/components/VideoTypes/VideoTypeIcon';

import { AudiogramDestinationConfig } from 'blocks/AudiogramDestinations/types';
import { DestinationSteps } from 'blocks/AutomationOptions/types';
import { SocialYoutube } from 'components/icons';
import { YouTubeSocialCircle } from 'components/SocialCircle';
import { VideoTypes } from 'types';

import { getAudiogramWizardPath, getFullEpisodeWizardPath } from 'utils/routes';
import { millisToSec } from 'utils/time';
import { DESTINATION_PRESETS } from '../../destinationPresets/utils';

const youtube: AudiogramDestinationConfig = {
  id: 'youtube',
  icon: <YouTubeSocialCircle className="white-background" />,
  videoTypes: [
    {
      description: 'Add an entire podcast episode',
      destination: getFullEpisodeWizardPath({
        defaultAspectRatio: DESTINATION_PRESETS.youtube.episode.aspectRatio,
      }),
      id: 'episode',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.youtube.episode.aspectRatio}
          background="white"
        >
          <SocialYoutube width="17px" />
        </VideoTypeIcon>
      ),
      title: 'Full episode',
      autogramWizardSettings: {
        destination: DestinationSteps.AUTO_POSTING,
        videoType: VideoTypes.FULL_EPISODE,
        clipDuration: null,
        aspectRatioName: DESTINATION_PRESETS.youtube.episode.aspectRatio,
      },
    },
    {
      description: 'Clip a full segment from an episode',
      destination: getAudiogramWizardPath({
        defaultAspectRatio: DESTINATION_PRESETS.youtube.clip.aspectRatio,
        defaultClipDurationMillis: DESTINATION_PRESETS.youtube.clip.durationMs,
        defaultPresetKey: DESTINATION_PRESETS.youtube.clip.key,
      }),
      id: 'clip',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.youtube.clip.aspectRatio}
          background="white"
        >
          <SocialYoutube width="17px" />
        </VideoTypeIcon>
      ),
      title: 'Episode clip',
      autogramWizardSettings: {
        destination: DestinationSteps.AUTO_POSTING,
        videoType: VideoTypes.RANDOM_CLIP,
        clipDuration: millisToSec(DESTINATION_PRESETS.youtube.clip.durationMs),
        aspectRatioName: DESTINATION_PRESETS.youtube.clip.aspectRatio,
      },
    },
    {
      description: 'Share a short clip to go viral',
      destination: getAudiogramWizardPath({
        defaultAspectRatio: DESTINATION_PRESETS.youtube.short.aspectRatio,
        defaultClipDurationMillis: DESTINATION_PRESETS.youtube.short.durationMs,
        defaultPresetKey: DESTINATION_PRESETS.youtube.short.key,
      }),
      id: 'short',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.youtube.short.aspectRatio}
          background="white"
        >
          <SocialYoutube width="13px" />
        </VideoTypeIcon>
      ),
      title: 'YouTube Short',
      autogramWizardSettings: {
        destination: DestinationSteps.AUTO_POSTING,
        videoType: VideoTypes.RANDOM_CLIP,
        clipDuration: millisToSec(DESTINATION_PRESETS.youtube.short.durationMs),
        aspectRatioName: DESTINATION_PRESETS.youtube.short.aspectRatio,
      },
    },
  ],
};

export default youtube;
