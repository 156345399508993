import React from 'react';

import Tip from 'components/Tip/Tip';
import { imageViewBlock as block } from '../utils';

export interface ChildViewTipTipProps {}

const ChildViewTipTip: React.FC<ChildViewTipTipProps> = () => (
  <Tip className={block('tip')}>
    Double click media to toggle between fitting or filling canvas
  </Tip>
);

export default ChildViewTipTip;
