import React, { FC } from 'react';
import Check from 'components/icons/Check';
import Error from 'components/icons/Error';
import { IconProps } from 'components/icons/types';
import ProgressList from 'components/ProgressList';

import { SM_BLUE, SM_ERROR_RED } from 'utils/constants';

interface CompatibilityItemProps {
  isValid: boolean;
  icon: FC<IconProps>;
  children: React.ReactNode;
}

const CompatibilityListItem: FC<CompatibilityItemProps> = props => {
  const { isValid, icon: Icon, children } = props;

  return (
    <ProgressList.Item
      color={isValid ? 'blue' : 'red'}
      icon={
        <Icon
          width={20}
          fill={isValid ? 'currentColor' : 'white'}
          stroke={isValid ? 'currentColor' : 'white'}
        />
      }
      secondaryIcon={
        isValid ? <Check fill={SM_BLUE} /> : <Error fill={SM_ERROR_RED} />
      }
      loading={false}
    >
      {children}
    </ProgressList.Item>
  );
};

export default CompatibilityListItem;
