import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useSocialSection } from 'blocks/DownloadPage/containers/DownloadPageSocialSection/SocialSectionContext';
import LinkButton from 'components/LinkButton';
import Modal from 'components/Modal';
import { ShareAICaptionsField } from 'components/ShareForm';
import { onInstagramPostModalLoad } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import { block } from './utils';

export interface ComposerStepProps {
  description: string;
  onDescriptionChange: (value: string) => void;
  goBack: () => void;
}

const ComposerStep: React.FC<ComposerStepProps> = ({
  description,
  onDescriptionChange,
  goBack,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const {
    allowAiDisclaimer,
    onUnlockAICaptions,
    toggleModalVisibility,
  } = useSocialSection();

  useEffect(() => {
    dispatch(onInstagramPostModalLoad('ReelsSelected'));
  }, [dispatch]);

  return (
    <Modal.Body className={block('composer-step')}>
      <p className={block('title')}>Post to your feed</p>

      <LinkButton
        theme="cta"
        className={block('composer-step--back-button')}
        uppercase
        onClick={goBack}
      >
        ← choose another option
      </LinkButton>

      <ShareAICaptionsField
        {...{ allowAiDisclaimer, onUnlockAICaptions }}
        inputProps={{
          controlClassName: block('composer-step--caption'),
          maxChars: 2200,
          value: description,
          onChange: onDescriptionChange,
        }}
        onCancelUnlockAICaptions={() => toggleModalVisibility('instagram')}
      />
    </Modal.Body>
  );
};

export default ComposerStep;
