import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import LinkButton from 'components/LinkButton';
import { block } from '../utils';

export interface AddTrackButtonProps {
  onClick?: () => void;
}

export default class AddTrackButton extends React.PureComponent<
  AddTrackButtonProps
> {
  public render() {
    const { onClick } = this.props;
    return (
      <LinkButton className={block('add-track-button')} onClick={onClick}>
        <FontAwesome className={block('add-track-icon')} icon="plus" />
        Add Track
      </LinkButton>
    );
  }
}
