import * as React from 'react';

import Divider from 'components/Divider';
import { H2 } from 'components/Heading';
import LinkButton from 'components/LinkButton';
import LoadingOverlay from 'components/LoadingOverlay';
import { ModalBody } from 'components/Modal';
import useAdjustPlanModalOptions, {
  UseAdjustPlanModalOptionsProps,
} from './useAdjustPlanModalOptions';
import { block } from './utils';

export type AdjustPlanModalContentsProps = UseAdjustPlanModalOptionsProps;

const AdjustPlanModalContents: React.FC<AdjustPlanModalContentsProps> = ({
  onClick,
}) => {
  const { disabled, options } = useAdjustPlanModalOptions({ onClick });

  return (
    <ModalBody className={block('body')}>
      {disabled && <LoadingOverlay title="Processing Payment" />}
      {options.reduce((acc, opt, index, ary) => {
        const {
          id,
          label,
          cta: { label: ctaLabel, onClick: onClickCta },
        } = opt;

        acc.push(
          <div key={id} className={block('option')}>
            <H2 className={block('label')}>{label}</H2>
            <LinkButton
              className={block('link')}
              onClick={onClickCta}
              theme="cta"
            >
              {ctaLabel}
            </LinkButton>
          </div>,
        );

        if (index < ary.length - 1) {
          acc.push(<Divider key={`${id}-separator`} />);
        }

        return acc;
      }, [])}
    </ModalBody>
  );
};

export default AdjustPlanModalContents;
