import * as Immutable from 'immutable';
import * as ids from 'short-id';
import _ from 'underscore';

import {
  Dimensions,
  IEmbedConfig,
  ISlideshowItem,
  SourceImageOrigin,
} from 'types';
import { getValue, isImmutable, omit, omitUndefined } from 'utils/collections';
import { formatCSSFilter, parseCSSFilter } from 'utils/dom';
import measurement, { ViewportHeight, ViewportWidth } from 'utils/measurement';
import { hasDimensions, stringToViewport } from 'utils/placement';
import { getAspectRatioName } from '../aspect-ratio';
import { percentageOf } from '../numbers';
import { defaultTrackIndex } from './tracks';

export const DEFAULT_SLIDE_TRANSITION = 'cut';
const DEFAULT_MEDIA_TRACK_INDEX = defaultTrackIndex('media');
const DEFAULT_PLACEMENT: Dimensions<string> = {
  height: '100vh',
  left: '0vw',
  top: '0vh',
  width: '100vw',
};

export const ADD_SLIDE_DURATION_MILLIS = 5000;
export const ATTRIBUTION_FONT_SIZE_WIDTH_PERCENTAGE = 1.5;

const defaultAttributionBaseValues = Immutable.fromJS({
  color: 'white',
  fontSize: '11px',
  opacity: '0.5',
  right: '0vw',
});

function getImageEffect(imageEffect) {
  let effect;
  let options = {};

  if (_.isObject(imageEffect)) {
    return isImmutable(imageEffect) ? imageEffect.toJS() : imageEffect;
  }

  switch (imageEffect) {
    case 'kenburns':
    case 'pan':
    case 'rotatingCube':
    case 'none':
      effect = imageEffect;
      break;
    case 'panLeft':
      effect = 'pan';
      options = { to: 'left' };
      break;
    case 'panRight':
      effect = 'pan';
      options = { to: 'right' };
      break;
    case 'panUp':
      effect = 'pan';
      options = { to: 'top' };
      break;
    case 'panDown':
      effect = 'pan';
      options = { to: 'bottom' };
      break;
    default:
      effect = 'kenburns';
  }

  return { effect, options };
}

function getUiImageEffect(imageEffect) {
  let imageEffectState;
  const effect = imageEffect.effect || imageEffect;
  const options = imageEffect.options || {};

  switch (effect) {
    case 'pan':
      switch (options.to) {
        case 'left':
          imageEffectState = 'panLeft';
          break;
        case 'right':
          imageEffectState = 'panRight';
          break;
        case 'top':
          imageEffectState = 'panUp';
          break;
        case 'bottom':
          imageEffectState = 'panDown';
          break;
        default:
          imageEffectState = effect;
      }
      break;
    case 'kenburns':
    case 'none':
    default:
      imageEffectState = effect;
  }

  return imageEffectState;
}

function getEntryTransition(entryTransition) {
  let effect;
  let options = {};

  switch (entryTransition) {
    case 'slide':
    case 'slideRight':
      effect = 'slide';
      options = { to: 'right' };
      break;
    case 'slideLeft':
      effect = 'slide';
      options = { to: 'left' };
      break;
    case 'slideUp':
      effect = 'slide';
      options = { to: 'top' };
      break;
    case 'slideDown':
      effect = 'slide';
      options = { to: 'bottom' };
      break;
    default:
      effect = entryTransition;
  }

  return { effect, options };
}

function getUiEntryTransition(entryTransition) {
  const effect = entryTransition.effect || entryTransition;
  const options = entryTransition.options || {};

  switch (effect) {
    case 'slide':
      switch (options.to) {
        case 'left':
          return 'slideLeft';
        case 'right':
          return 'slideRight';
        case 'top':
          return 'slideUp';
        case 'bottom':
          return 'slideDown';
        default:
          return 'slideRight';
      }
    default:
      return effect;
  }
}

export const DEFAULT_ATTRIBUTION_VALUES = Immutable.Map({
  landscape: defaultAttributionBaseValues.withMutations(v =>
    v
      .set('top', '54vw')
      .setIn(['viewport', 'width'], 1000)
      .setIn(['viewport', 'height'], (1000 * 9) / 16),
  ),

  portrait: defaultAttributionBaseValues.withMutations(v =>
    v
      .set('top', '175.5vw')
      .setIn(['viewport', 'width'], 1000)
      .setIn(['viewport', 'height'], (1000 * 16) / 9),
  ),

  square: defaultAttributionBaseValues.withMutations(v =>
    v
      .set('top', '97.5vw')
      .setIn(['viewport', 'width'], 1000)
      .setIn(['viewport', 'height'], 1000),
  ),
});

export function sortSlides(slidesById) {
  return slidesById
    .sortBy(s => s.get('startMilli'))
    .map(s => s.get('id'))
    .toArray();
}

export function findSlideContainingMillis(millis, slides) {
  if (!_.isFinite(millis) || !slides) return undefined;

  // We use findLast instead of find since later slides in the map overlap the earlier ones
  return slides.findLast(
    slide =>
      millis >= slide.get('startMilli') && millis <= slide.get('endMilli'),
  );
}

/**
 * @param {Immutable.Map} newSlide this is the format received from the server
 * @param {Array} slideIds ids of existing slides in order that they appears
 * @param {Immutable.Map} slides by id where each slide is also an Immutable.Map.  server format
 */
export function addToSlideshow(
  newSlide,
  slideIds = [],
  slides = Immutable.Map<string, any>(),
) {
  const nextSlide = slides
    .filter(s => s.get('startMilli') > newSlide.get('startMilli'))
    .minBy(s => s.get('startMilli'));

  const newSlideIndex = nextSlide
    ? slideIds.indexOf(nextSlide.get('id'))
    : slideIds.length;

  const updatedSlides = slides.set(newSlide.get('id'), newSlide);
  const updatedSlideIds = [...slideIds];
  updatedSlideIds.splice(newSlideIndex, 0, newSlide.get('id'));

  return {
    slidesById: updatedSlides,
    slides: updatedSlideIds,
  };
}

function getAttributionTextFromImageMetadata(metadata) {
  return (
    metadata && metadata.attributionHtml && metadata.attributionHtml.default
  );
}

function getPurchaseUrlFromImageMetadata(metadata) {
  return metadata && metadata.purchaseUrl;
}

export function getSlideshowFromRecommendation(recommendation, aspectRatio) {
  // filter only slides from the recommendation
  const slideshowRec = recommendation.filter(asset => asset.type === 'image');

  const aspectRatioName = _.isString(aspectRatio)
    ? aspectRatio
    : getAspectRatioName(aspectRatio.get('height'), aspectRatio.get('width'));

  const attributionDefaultRight = DEFAULT_ATTRIBUTION_VALUES.getIn([
    aspectRatioName,
    'right',
  ]);
  const attributionDefaultTop = DEFAULT_ATTRIBUTION_VALUES.getIn([
    aspectRatioName,
    'top',
  ]);
  const attributionDefaultColor = DEFAULT_ATTRIBUTION_VALUES.getIn([
    aspectRatioName,
    'color',
  ]);
  const attributionDefaultFontSize = DEFAULT_ATTRIBUTION_VALUES.getIn([
    aspectRatioName,
    'fontSize',
  ]);
  const attributionDefaultOpacity = DEFAULT_ATTRIBUTION_VALUES.getIn([
    aspectRatioName,
    'opacity',
  ]);

  // this is the format the embed configuration service wants for the slideshow
  const sortedRec = slideshowRec.sort(
    (s1, s2) => s1.startMilli - s2.startMilli,
  );
  return sortedRec.map(({ startMilli, endMilli, assetDetail }) => {
    const {
      entryTransition,
      imageEffect,
      imageMetadata,
      isBackfill,
      origImageUrl,
      recommendedImage,
    } = assetDetail;

    const { operations, url: imageUrl } = recommendedImage;

    const attributionText = getAttributionTextFromImageMetadata(imageMetadata);
    const attributionInfo = !attributionText
      ? {}
      : {
          attributions: [
            {
              text: attributionText,
              style: {
                color: attributionDefaultColor,
                fontSize: attributionDefaultFontSize,
                opacity: attributionDefaultOpacity,
                right: attributionDefaultRight,
                top: attributionDefaultTop,
              },
            },
          ],
        };

    const purchaseUrl = getPurchaseUrlFromImageMetadata(imageMetadata);
    const purchaseInfo = !purchaseUrl ? {} : { purchaseUrl };

    const sourceImage = {
      operations,
      url: origImageUrl,
    };

    return {
      entryTransition,
      imageEffect,
      startMilli,
      endMilli,
      isBackfill,
      imageUrl,
      sourceImage,
      layerId: DEFAULT_MEDIA_TRACK_INDEX,
      ...attributionInfo,
      ...purchaseInfo,
    };
  });
}

function getAttributionFromSlideConfig(slideConfig, ratioName) {
  const { attributions, imageMetadata } = slideConfig;

  const vw = DEFAULT_ATTRIBUTION_VALUES.getIn([ratioName, 'viewport', 'width']);
  if (attributions && attributions.length > 0) {
    const attribution = attributions[0];

    return {
      attribution: {
        color: attribution.style.color,
        fontSize: attribution.style.fontSize,
        opacity: attribution.style.opacity,
        position: {
          top: (parseFloat(attribution.style.top) / 100) * vw,
          right: (parseFloat(attribution.style.right) / 100) * vw,
        },
        text: attribution.text,
      },
    };
  }

  if (imageMetadata) {
    return {
      attribution: {
        color: DEFAULT_ATTRIBUTION_VALUES.getIn([ratioName, 'color']),
        fontSize: DEFAULT_ATTRIBUTION_VALUES.getIn([ratioName, 'fontSize']),
        opacity: DEFAULT_ATTRIBUTION_VALUES.getIn([ratioName, 'opacity']),
        position: {
          top:
            (parseFloat(DEFAULT_ATTRIBUTION_VALUES.getIn([ratioName, 'top'])) /
              100) *
            vw,
          right:
            (parseFloat(
              DEFAULT_ATTRIBUTION_VALUES.getIn([ratioName, 'right']),
            ) /
              100) *
            vw,
        },
        text: getAttributionTextFromImageMetadata(imageMetadata),
      },
    };
  }

  return {};
}

function extractOriginalAndCropFromSlideConfig(slideConfig) {
  const { editor, sourceImage } = slideConfig;
  const cropperMetadata = getValue(editor, ['cropper']);
  const crop = getValue(sourceImage, ['operations', 0, 'properties']);
  const originalImageUrl = getValue(sourceImage, 'url');
  const hasCropData = crop || cropperMetadata;

  return omitUndefined({
    sourceImageOrigin: _.pick(sourceImage, 'origin', 'canvaDesignId'),
    originalImageUrl,
    ...(!hasCropData
      ? {}
      : {
          cropData: {
            ...(cropperMetadata || {}),
            ...(!crop
              ? {}
              : {
                  crop: {
                    height: crop.bottom - crop.top,
                    width: crop.right - crop.left,
                    x: crop.left,
                    y: crop.top,
                  },
                }),
          },
        }),
  });
}

export function createSlideStateFromConfig(slideConfig, aspectRatio) {
  const {
    attributions,
    imageEffect,
    entryTransition,
    sourceImage,
    editor,
    position,
    style,
    ...rest
  } = slideConfig;

  const ratioName = getAspectRatioName(
    aspectRatio.get('height'),
    aspectRatio.get('width'),
  );
  const attributionState = getAttributionFromSlideConfig(
    slideConfig,
    ratioName,
  );

  const viewportState = !attributionState
    ? {}
    : {
        viewport: {
          height: DEFAULT_ATTRIBUTION_VALUES.getIn([
            ratioName,
            'viewport',
            'height',
          ]),
          width: DEFAULT_ATTRIBUTION_VALUES.getIn([
            ratioName,
            'viewport',
            'width',
          ]),
        },
      };

  const imageEffectState = getUiImageEffect(imageEffect);
  const entryTransitionState = getUiEntryTransition(entryTransition);

  const placement = {
    ..._.pick(style, 'height', 'width'),
    ...position,
  };

  const cssFilter = parseCSSFilter(style?.filter);

  return {
    ...rest,
    ...attributionState,
    ...viewportState,
    ...extractOriginalAndCropFromSlideConfig(slideConfig),
    imageEffect: imageEffectState,
    entryTransition: entryTransitionState,
    id: ids.generate(),
    placement: _.isEmpty(placement) ? undefined : placement,
    blurRadius: cssFilter?.blur?.radius,
  };
}

function formatSourceAndEditor(
  originalUrl,
  metadata,
  sourceImageOrigin?: SourceImageOrigin,
) {
  const cropperMetadata = metadata && omit(metadata, 'crop');
  const editor =
    cropperMetadata && !_.isEmpty(cropperMetadata)
      ? {
          editor: { cropper: cropperMetadata },
        }
      : undefined;

  const { crop } = metadata || {};

  const sourceImage = (originalUrl || crop) && {
    sourceImage: {
      ...sourceImageOrigin,
      ...(originalUrl && { url: originalUrl }),
      ...(crop && {
        operations: [
          {
            properties: {
              bottom: crop.y + crop.height,
              left: crop.x,
              right: crop.x + crop.width,
              top: crop.y,
            },
            operationType: 'crop',
          },
        ],
      }),
    },
  };

  return { ...editor, ...sourceImage };
}

function extractSourceAndEditorFromReduxSlide(slide) {
  const originalImageUrl = slide.get('originalImageUrl');
  const cropData = slide.get('cropData');
  const metadata = cropData && cropData.toJS();
  return formatSourceAndEditor(
    originalImageUrl,
    metadata,
    slide.get('sourceImageOrigin')?.toJS(),
  );
}

export function formatSlideForConfig(
  slide,
  trackIndex = DEFAULT_MEDIA_TRACK_INDEX,
) {
  const entryTransition = slide.get('entryTransition');
  const effect = Immutable.Map.isMap(entryTransition)
    ? entryTransition.get('effect')
    : entryTransition;

  const slideConfig = {
    entryTransition: getEntryTransition(effect),
    startMilli: Math.ceil(slide.get('startMilli')),
    endMilli: Math.ceil(slide.get('endMilli')),
    imageEffect: getImageEffect(slide.get('imageEffect')),
    imageUrl: slide.get('imageUrl'),
  };

  const attribution = slide.get('attribution');
  const viewport = slide.get('viewport');
  const blurRadius = slide.get('blurRadius');

  const attributionConfig =
    !attribution || !viewport
      ? {}
      : {
          attributions: [
            {
              text: attribution.get('text'),
              style: {
                color: attribution.get('color'),
                fontSize: attribution.get('fontSize'),
                opacity: attribution.get('opacity'),
                top: `${percentageOf(
                  attribution.getIn(['position', 'top']),
                  viewport.get('width'),
                )}vw`,
                right: `${percentageOf(
                  attribution.getIn(['position', 'right']),
                  viewport.get('width'),
                )}vw`,
              },
            },
          ],
        };

  const purchaseUrl = slide.get('purchaseUrl');
  const purchaseConfig = !purchaseUrl ? {} : { purchaseUrl };
  const placement = slide.get('placement')?.toJS();
  const position = _.pick(placement, 'top', 'left');

  return {
    ...slideConfig,
    ...attributionConfig,
    ...purchaseConfig,
    ...extractSourceAndEditorFromReduxSlide(slide),
    backfill: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },
    layerId: trackIndex,
    position: _.isEmpty(position) ? undefined : position,
    style: omitUndefined({
      width: placement?.width,
      height: placement?.height,
      filter: formatCSSFilter({
        blur: { radius: blurRadius },
      }),
    }),
  };
}

export function formatSlidesForConfig(slides, trackIndex) {
  if (!slides) return [];

  return slides.reduce((acc, slide) => {
    acc.push(formatSlideForConfig(slide, trackIndex));
    return acc;
  }, []);
}

export function createSlide(
  imageUrl,
  transition,
  startMillis,
  endMillis,
  effect,
  imageMetadata,
  aspectRatio,
  originalImageUrl,
  cropData,
  placement = DEFAULT_PLACEMENT,
  sourceImageOrigin?: SourceImageOrigin,
  blurRadius?: string,
) {
  const ratioName = _.isString(aspectRatio)
    ? aspectRatio
    : getAspectRatioName(aspectRatio.get('height'), aspectRatio.get('width'));
  const height = DEFAULT_ATTRIBUTION_VALUES.getIn([
    ratioName,
    'viewport',
    'height',
  ]);
  const width = DEFAULT_ATTRIBUTION_VALUES.getIn([
    ratioName,
    'viewport',
    'width',
  ]);
  const entryTransition = transition || DEFAULT_SLIDE_TRANSITION;
  const attributionText = getAttributionTextFromImageMetadata(imageMetadata);
  const attributionInfo = !attributionText
    ? {}
    : {
        attribution: {
          color: DEFAULT_ATTRIBUTION_VALUES.getIn([ratioName, 'color']),
          fontSize: DEFAULT_ATTRIBUTION_VALUES.getIn([ratioName, 'fontSize']),
          opacity: DEFAULT_ATTRIBUTION_VALUES.getIn([ratioName, 'opacity']),
          position: {
            top:
              (parseFloat(
                DEFAULT_ATTRIBUTION_VALUES.getIn([ratioName, 'top']),
              ) /
                100) *
              width,
            right:
              (parseFloat(
                DEFAULT_ATTRIBUTION_VALUES.getIn([ratioName, 'right']),
              ) /
                100) *
              width,
          },
          text: attributionText,
        },
      };
  const purchaseUrl = getPurchaseUrlFromImageMetadata(imageMetadata);
  const purchaseInfo = !purchaseUrl ? {} : { purchaseUrl };
  const viewportInfo = !attributionInfo ? {} : { viewport: { height, width } };

  return {
    ...attributionInfo,
    ...purchaseInfo,
    ...viewportInfo,
    cropData,
    entryTransition,
    imageUrl,
    originalImageUrl,
    sourceImageOrigin,
    placement,
    id: ids.generate(),
    imageEffect: effect,
    isBackfill: false,
    startMilli: Math.ceil(startMillis),
    endMilli: Math.ceil(endMillis),
    blurRadius,
  };
}

export function replaceSlideImage(
  slide,
  imageUrl,
  originalUrl,
  metadata,
  sourceImageOrigin?: SourceImageOrigin,
) {
  return {
    ...slide,
    imageUrl,
    ...formatSourceAndEditor(originalUrl, metadata, sourceImageOrigin),
  };
}

export function isSpitfireSlide(slide: ISlideshowItem): boolean {
  return (
    slide?.imageEffect?.effectType === 'lottie' &&
    slide?.imageEffect?.effect !== 'rotatingCube'
  );
}

/**
 * Gets placement values for a slide in Viewport units.
 *
 * New slides should have placement - e.g. a value for top, left, width, and
 * height.  Some legacy images don't have any of those values, while others might
 * have height and width but no position (all images used to be positioned at
 * (0, 0) so it was safe to leave this value out).
 *
 * This function will return the placement value, inserting zero's for left and
 * top if no value exists
 *
 * @param slide an immutable slide from redux state
 */
export function getSlidePlacement(
  slide: any,
): Dimensions<ViewportHeight, ViewportWidth> {
  const placement = slide?.get('placement')?.toJS();

  if (!placement) {
    return undefined;
  }

  const placementWithDefaults = {
    left: new ViewportWidth(0),
    top: new ViewportHeight(0),
    ...stringToViewport(placement),
  };

  return !hasDimensions(placementWithDefaults)
    ? undefined
    : (placementWithDefaults as Dimensions<ViewportHeight, ViewportWidth>);
}

export function getSlideBlurRadius(slide: any) {
  const blurRadius = slide?.get('blurRadius');
  return measurement(blurRadius, 'vw') as ViewportWidth;
}

export function getSlideBlurRadiusFromStyle(
  style: React.CSSProperties,
): ViewportWidth | undefined {
  if (!style?.filter) {
    return undefined;
  }

  const parsedBlur = parseCSSFilter(style?.filter);

  return measurement(parsedBlur.blur.radius) as ViewportWidth;
}

export function hasEmbeddedText(slide?: ISlideshowItem): boolean {
  const embeddedTexts = slide?.imageEffect?.options?.embeddedTexts ?? [];

  return embeddedTexts.length > 0;
}

export function hasMainImage(
  config: Pick<IEmbedConfig, 'slideshowInfo' | 'watermark'>,
): boolean {
  const { slideshowInfo = [], watermark = [] } = config || {};

  const hasMainSlide = slideshowInfo.some(
    slide => slide.imageType === 'mainImage',
  );
  const hasMainWatermark = watermark.some(wm => wm.imageType === 'mainImage');

  return hasMainSlide || hasMainWatermark;
}

export default {
  ATTRIBUTION_FONT_SIZE_WIDTH_PERCENTAGE,
  addToSlideshow,
  findSlideContainingMillis,
  getSlideshowFromRecommendation,
  createSlideStateFromConfig,
  formatSlideForConfig,
  formatSlidesForConfig,
};
