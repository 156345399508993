import { Heading, Link, Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import { block } from './utils';

export interface PodcastPromoCouponProps {
  className?: string;
}

const SparklesEmoji = () => (
  <span role="img" aria-label="sparkles emoji">
    ✨
  </span>
);

const PodcastPromoCoupon: React.FC<PodcastPromoCouponProps> = ({
  className,
}) => {
  return (
    <div className={cn(block(), className)}>
      <img
        src="https://static.sparemin.com/static/webapp-assets/disco-promo-coupon-background-202403262108.png"
        className={block('promo-coupon-background')}
      />

      <Spacer
        orientation="vertical"
        align="flex-start"
        justify="space-between"
        className={block('contents')}
      >
        <Spacer align="center" space="0">
          <SparklesEmoji />
          &nbsp;
          <Heading level={4} className={block('special-message')}>
            special bonus offer
          </Heading>
          &nbsp;
          <SparklesEmoji />
        </Spacer>

        <Heading level={5} className={block('title')}>
          Get 100 extra listens each month!
        </Heading>

        <Heading level={4} className={block('description')}>
          Upgrade today and your plan will include a $10/month Podcast Promo
          credit to promote your show across our ad network at no additional
          charge (
          <Link
            href="https://www.headliner.app/features/promoteyourpodcast/"
            target="_blank"
          >
            learn more
          </Link>
          ).
        </Heading>
      </Spacer>
    </div>
  );
};

export default PodcastPromoCoupon;
