import * as React from 'react';

import TextInputModal, { TextInputModalProps } from 'components/TextInputModal';
import { useTemplateState } from '../context/VideoTemplateStateContext';

type PickedModalProps = Pick<
  TextInputModalProps,
  'onExited' | 'onHide' | 'onSubmit' | 'show'
>;

export interface EditEffectTextModalProps extends PickedModalProps {
  id: string;
}

const EditEffectTextModal: React.FC<EditEffectTextModalProps> = ({
  id,
  onExited,
  onHide,
  onSubmit,
  show,
}) => {
  const { slideEffectText } = useTemplateState();

  return (
    <TextInputModal
      {...{ onExited, onHide, onSubmit, show }}
      maxChars={255}
      defaultValue={slideEffectText?.[id]?.text}
      title="edit text"
    />
  );
};

export default EditEffectTextModal;
