import * as React from 'react';

import { IconProps } from 'components/icons/types';

/* tslint:disable-next-line:variable-name */
const TwitterTemplate: React.SFC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="9"
    viewBox="0 0 10 9"
    {...props}
  >
    <path
      fill="#23282C"
      fillRule="nonzero"
      d="M8.972 2.243c.006.098.006.197.006.295C8.978 5.541 6.916 9 3.148 9A5.39 5.39 0 0 1 0 7.98a3.858 3.858 0 0 0 3.04-.942c-.902-.02-1.657-.675-1.917-1.575a1.969 1.969 0 0 0 .926-.042C1.11 5.211.407 4.296.407 3.192v-.028c.273.169.59.274.926.288C.78 3.045.42 2.348.42 1.561c0-.422.101-.809.279-1.146C1.707 1.793 3.223 2.693 4.924 2.79a2.83 2.83 0 0 1-.05-.52C4.873 1.02 5.786 0 6.922 0c.59 0 1.123.274 1.497.717A3.805 3.805 0 0 0 9.72.17c-.151.527-.475.97-.9 1.251.412-.049.812-.175 1.18-.351-.28.45-.628.85-1.028 1.174z"
    />
  </svg>
);

export default TwitterTemplate;
