import { RequestStatus } from 'types/common';
import { Type } from './action-types';
import { stateFactory } from './factories';
import { WizardExportAction, WizardExportState } from './types';

const defaultState = stateFactory();

function reducer(
  state: WizardExportState = defaultState,
  action: WizardExportAction,
): WizardExportState {
  switch (action.type) {
    case Type.CREATION_REQUEST: {
      return defaultState.set('status', RequestStatus.REQUEST);
    }
    case Type.CREATION_SUCCESS: {
      const { creationId } = action.payload;
      return state
        .set('creationId', creationId)
        .set('status', RequestStatus.SUCCESS);
    }

    case Type.CREATION_FAILURE: {
      return state.set('status', RequestStatus.FAILURE);
    }

    case Type.CREATION_CLEAR:
      return defaultState;

    case Type.CREATION_STATUS_POLL_REQUEST:
      return state.set('pollStatus', RequestStatus.REQUEST);

    case Type.CREATION_STATUS_POLL_SUCCESS:
      return state.set('pollStatus', RequestStatus.SUCCESS);

    case Type.CREATION_STATUS_POLL_FAILURE:
      return state.set('pollStatus', RequestStatus.FAILURE);

    default:
      return state;
  }
}

export default reducer;
