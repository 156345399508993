import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { getMyDisplayPref } from 'redux/modules/display-pref/actions';
import { FetchStatus } from 'redux/modules/display-pref/constants';
import { fetchStatusSelector } from 'redux/modules/display-pref/selectors';
import { getUserPersonalFonts } from 'redux/modules/fonts/actions';
import { showError } from 'redux/modules/notification/actions';
import { loadPricingData } from 'redux/modules/pricing';
import { appHomeSelector } from 'redux/modules/router/selectors';
import { getMyUserPref } from 'redux/modules/user-pref/actions';
import { clearWizardExport } from 'redux/modules/wizard-export/actions';
import { Dispatch, State } from 'redux/types';

import { WizardPageLoaderProps } from './WizardPageLoader';

export type StateProps = Pick<
  WizardPageLoaderProps,
  'fallbackUrl' | 'isLoading' | 'hasError'
>;
export type DispatchProps = Pick<WizardPageLoaderProps, 'onMount'>;

const ERROR_ID = 'wizard-page-loader-error';

const loadingSelector = createSelector(
  fetchStatusSelector,
  status => !status || status === FetchStatus.FETCHING,
);

const errorSelector = createSelector(
  fetchStatusSelector,
  status => status === FetchStatus.FAILURE,
);

const mapStateToProps = (state: State): StateProps => ({
  fallbackUrl: appHomeSelector(),
  hasError: errorSelector(state),
  isLoading: loadingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onMount: async () => {
    try {
      dispatch(clearWizardExport());
      await dispatch(getMyDisplayPref());
      await dispatch(getMyUserPref());
      await dispatch(getUserPersonalFonts());
      await dispatch(loadPricingData({ force: true }));
    } catch (err) {
      dispatch(
        showError({
          message: err.message,
          code: err.code,
          id: ERROR_ID,
        }),
      );
    }
  },
});

export default function(component: React.ComponentType<WizardPageLoaderProps>) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
