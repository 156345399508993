import * as React from 'react';
import KeyHandler, { KEYDOWN } from 'react-key-handler';
import PlaybackControlBar from '../components/PlaybackControlBar';

export interface RenderPlayerProps {
  playing: boolean;
  seekToSec?: number;
  mediaRef: (el: HTMLMediaElement) => void;
}

interface IProps {
  renderPlayer:
    | React.ComponentClass<RenderPlayerProps>
    | React.StatelessComponent<RenderPlayerProps>;
}

interface IState {
  playing: boolean;
  bufferMillis: number;
  currentTimeMillis: number;
  disabled: boolean;
  durationMillis: number;
  seekToSec?: number;
}

class PlaybackControlBarContainer extends React.Component<IProps, IState> {
  public state = {
    bufferMillis: 0,
    currentTimeMillis: 0,
    disabled: true,
    durationMillis: 0,
    playing: false,
    seekToSec: undefined,
    timeIndicatorWidth: undefined,
  };

  private media: HTMLMediaElement;

  public componentDidMount() {
    this.media.addEventListener('loadedmetadata', this.onLoadedMetadata);
    this.media.addEventListener('timeupdate', this.onTimeUpdate);
    this.media.addEventListener('ended', this.onEnded);
    this.media.addEventListener('canplay', this.onCanPlay);
    this.media.addEventListener('canplaythrough', this.onCanPlay);
  }

  public componentWillUnmount() {
    this.media.removeEventListener('loadedmetadata', this.onLoadedMetadata);
    this.media.removeEventListener('timeupdate', this.onTimeUpdate);
    this.media.removeEventListener('ended', this.onEnded);
    this.media.removeEventListener('canplay', this.onCanPlay);
    this.media.removeEventListener('canplaythrough', this.onCanPlay);
  }

  private onLoadedMetadata = () =>
    this.setState({ durationMillis: this.media.duration * 1000 });

  private onTimeUpdate = () => {
    this.setState(() => {
      const { currentTime, buffered } = this.media;
      const currentTimeMillis = currentTime * 1000;
      const bufferMillis = buffered.length
        ? buffered.end(buffered.length - 1) * 1000
        : undefined;
      return { currentTimeMillis, bufferMillis, disabled: !bufferMillis };
    });
  };

  private onEnded = () => this.setState({ playing: false });

  private onCanPlay = () => {
    const { buffered } = this.media;
    if (buffered.length) {
      const bufferMillis = buffered.end(buffered.length - 1) * 1000;
      this.setState({
        bufferMillis,
        disabled: !bufferMillis,
      });
    }
  };

  private togglePlaying = () =>
    this.setState(({ playing }) => ({ playing: !playing }));

  private handleSeek = (seekToSec: number) => this.setState({ seekToSec });

  private mediaRef = (el: HTMLMediaElement) => (this.media = el);

  public render() {
    const { renderPlayer: Player } = this.props;
    const { playing, seekToSec } = this.state;

    return (
      <div>
        <Player
          playing={playing}
          seekToSec={seekToSec}
          mediaRef={this.mediaRef}
        />
        <KeyHandler
          keyEventName={KEYDOWN}
          keyValue=" "
          onKeyHandle={this.togglePlaying}
        />
        <PlaybackControlBar
          {...this.state}
          onTogglePlaying={this.togglePlaying}
          onSeek={this.handleSeek}
        />
      </div>
    );
  }
}

export default PlaybackControlBarContainer;
