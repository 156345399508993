import * as React from 'react';

import { IconProps } from './types';

const Timeline: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    height="100%"
    width="100%"
    {...props}
  >
    <path
      fill="currentColor"
      d="m13,10.40602l0,3.594l9,0l0,3l-9,0l0,2l5,0l0,3l-16,0l0,-3l9,0l0,-2l-9,0l0,-3l9,0l0,-3.594l-6.703,-6.703a0.961,0.961 0 0 1 -0.297,-0.703c0,-0.27 0.099,-0.505 0.297,-0.703a0.961,0.961 0 0 1 0.703,-0.297l14,0c0.27,0 0.505,0.099 0.703,0.297c0.099,0.23433 0.198,0.46866 0.297,0.703c0,0.27 -0.099,0.505 -0.297,0.703l-6.703,6.703z"
    />
  </svg>
);

export default Timeline;
