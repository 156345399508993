import cn from 'classnames';
import Cropperjs from 'cropperjs';
import * as React from 'react';
import _ from 'underscore';

import bem from 'utils/bem';
import { CropperData, DefaultMetadata, DragMode } from './types';
import { getData, scaleData } from './utils';

interface IProps {
  /**
   * aspect ratio of the crop area.  defaults to 16 x 9
   */
  aspectRatio?: number;

  className?: string;

  /**
   * Used to intialize the cropper with existing data.
   *
   * `constrained` is omitted in favor of the constrainImage prop
   */
  defaultMetadata?: DefaultMetadata;

  /**
   * src of image to crop
   */
  src?: string;

  /**
   * called any time the crop area changes (first load, user changes something, etc.)
   *  arguments:
   *    - the cropped canvas.  calling toBlob() will get the cropped image
   */
  onChange?: (data: CropperData) => void;

  /**
   * called when the cropperjs ready event fires
   */
  onReady?: () => void;

  /**
   * selector for dom element that will show preview
   */
  previewSelector?: string;

  /**
   * if true, will not let the user modify the image
   */
  disabled?: boolean;

  /**
   * if true, the user will be allowed to select outside of the bounds of the image
   */
  constrainImage?: boolean;
}

interface State {
  ready: boolean;
}

export default class Cropper extends React.Component<IProps, State> {
  public static defaultProps: Partial<IProps> = {
    constrainImage: true,
    disabled: false,
    onChange: _.noop,
    onReady: _.noop,
  };

  private cropper: Cropperjs;
  private image: HTMLImageElement;

  public state: Readonly<State> = {
    ready: false,
  };

  public componentDidMount() {
    this.createCropper();
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      constrainImage: prevConstrainImage,
      disabled: prevDisabled,
      src: prevSrc,
    } = prevProps;
    const { constrainImage, disabled, src } = this.props;

    if (constrainImage !== prevConstrainImage || src !== prevSrc) {
      this.destroyCropper();
      this.createCropper();
    }

    if (disabled !== prevDisabled) {
      if (disabled) {
        this.cropper.disable();
      } else {
        this.cropper.enable();
      }
    }
  }

  public componentWillUnmount() {
    this.destroyCropper();
  }

  /*
   * this component should only update when the underlying image src changes or allow zoom
   * changes, otherwise cropper takes care of all the rendering outside of react
   */
  public shouldComponentUpdate(
    nextProps: Readonly<IProps>,
    nextState: Readonly<State>,
  ) {
    const {
      src: nextSrc,
      constrainImage: nextConstrainImage,
      disabled: nextDisabled,
    } = nextProps;
    const { ready: nextReady } = nextState;
    const { src, constrainImage, disabled } = this.props;
    const { ready } = this.state;

    return (
      nextSrc !== src ||
      nextConstrainImage !== constrainImage ||
      nextDisabled !== disabled ||
      nextReady !== ready
    );
  }

  public getData(): CropperData {
    const { constrainImage, src } = this.props;
    return this.withCropper(cropper => ({
      ...getData(cropper),
      constrained: constrainImage,
      originalImageSrc: src,
    }));
  }

  private createCropper() {
    const { aspectRatio, previewSelector, constrainImage } = this.props;

    this.setState({ ready: false });

    const cropper = new Cropperjs(this.image, {
      aspectRatio,
      autoCropArea: 1,

      // crop box is within the image if constrainImage is false, otherwise the user can select
      // outside
      viewMode: constrainImage ? 1 : 0,

      // turn off the grid lines within the crop box
      guides: false,

      cropBoxResizable: true,

      dragMode: DragMode.Move,

      // when dragging, don't move the crop box, move the underlying image
      cropBoxMovable: false,

      // prevents the user from being able to drag the crop box if he double clicks
      toggleDragModeOnDblclick: false,

      // turn off the checkered container background
      background: false,

      // automatically dispaly the crop area after initialization
      autoCrop: true,

      preview: previewSelector,

      cropend: this.fireOnChange,
      ready: this.handleReady,
      zoom: this.fireOnChange,
    });

    this.cropper = cropper;
  }

  private destroyCropper() {
    this.withCropper(cropper => cropper.destroy());
  }

  private handleReady = () => {
    const { defaultMetadata, onReady } = this.props;

    if (defaultMetadata) {
      const scaledData = scaleData(
        defaultMetadata,
        this.cropper.getContainerData(),
      );
      this.cropper.setCanvasData(scaledData.canvas);

      /*
       * NB: cropper can be initialized with a data option which is the
       * equivalent of calling setData(), however in order to display the
       * cropped region correctly the canvas size needs to be set before the
       * data is set so the data configuration option is not being used here.
       */
      this.cropper.setData(scaledData.crop);
    }

    this.setState({ ready: true });
    onReady();
  };

  private fireOnChange = () => {
    const { onChange } = this.props;
    onChange(this.getData());
  };

  private withCropper<T>(fn: (cropper: Cropperjs) => T): T {
    if (!this.cropper) return undefined;
    return fn(this.cropper);
  }

  public render() {
    const { className, src } = this.props;
    const { ready } = this.state;

    const imageRef = (el: HTMLImageElement) => {
      this.image = el;
    };

    const block = bem('cropper');

    return (
      <div className={cn(block({ ready }), className)}>
        <img
          className={block('image')}
          ref={imageRef}
          src={src}
          style={{ display: 'none' }}
        />
      </div>
    );
  }
}

export { IProps as CropperProps };
