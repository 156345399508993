import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { createEmbedPreviewUrl } from 'redux/middleware/api/embed-service';
import { tokenSelector } from 'redux/modules/auth';
import {
  aspectRatioSelector,
  embedConfigSelector,
  embedLoadingSelector,
} from 'redux/modules/embed/selectors';
import { State } from 'redux/types';
import embedUtils from 'utils/embed';
import { PreviewPaneProps } from './PreviewPane';

type StateProps = Pick<PreviewPaneProps, 'aspectRatio' | 'embedUrl'>;

const embedUrlSelector = createSelector(
  [embedConfigSelector, tokenSelector, embedLoadingSelector],
  (config, token, isLoading) =>
    embedUtils.isConfigEmpty(config) || isLoading
      ? undefined
      : createEmbedPreviewUrl({
          widgetId: config.wid,
          recordingId: config.embedConfig.recordingId,
          token,
        }),
);

const mapStateToProps = (state: State): StateProps => ({
  aspectRatio: aspectRatioSelector(state),
  embedUrl: embedUrlSelector(state),
});

export default function(component: React.ComponentType<PreviewPaneProps>) {
  return connect(mapStateToProps)(component);
}
