import cn from 'classnames';
import React from 'react';
import bem from 'utils/bem';

type Props = React.HTMLAttributes<HTMLDivElement> & { disabled: boolean };

const block = bem('hl-tooltip-target');

// convenient inline wrapper to allow disabled element
// see https://github.com/react-bootstrap/react-bootstrap/issues/2428
function TooltipTarget(props: Props) {
  const { children, className, disabled, ...rest } = props;

  return (
    <div {...rest} className={cn(block({ disabled }), className)}>
      {children}
    </div>
  );
}

export default TooltipTarget;
