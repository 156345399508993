import React from 'react';
import DownloadCaptionsModal from './DownloadCaptionsModal';
import DownloadProjectCaptionsModalContents from './DownloadProjectCaptionsModalContents';

export interface DownloadProjectCaptionsModalProps {}

const DownloadProjectCaptionsModal: React.FC<DownloadProjectCaptionsModalProps> = () => (
  <DownloadCaptionsModal
    name="DownloadProjectCaptions"
    title="Download Captions"
  >
    <DownloadProjectCaptionsModalContents />
  </DownloadCaptionsModal>
);

export default DownloadProjectCaptionsModal;
