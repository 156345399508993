import * as React from 'react';

import { ColorField, ColorFieldProps } from 'components/Form';
import TextBoxField from './TextBoxField';

const TextBoxColorField: React.SFC<ColorFieldProps> = props => (
  <TextBoxField>
    <ColorField {...props} />
  </TextBoxField>
);

export default TextBoxColorField;
export { ColorFieldProps as TextBoxColorFieldProps };
