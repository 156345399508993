import cn from 'classnames';
import * as React from 'react';
import { isNumber, isString } from 'underscore';

import RadioOption from './RadioOption';
import RadioOptionLabel from './RadioOptionLabel';
import RadioOptionList from './RadioOptionList';
import { Option, Overrides, RadioOptionProps } from './types';
import { block } from './utils';

export interface RadioOptionsProps<T extends Option<V> = Option, V = any> {
  className?: string;
  disabled?: boolean;
  indicatorClassName?: string;
  label?: React.ReactChild;
  listClassName?: string;
  onChange?: (value: T['value']) => void;
  optionClassName?: string;
  options: T[];
  overrides?: Overrides<V, T>;
  value: V;
  appearance?: RadioOptionProps['appearance'];
}

export default function RadioOptions<T extends Option<V> = Option, V = any>({
  className,
  disabled,
  indicatorClassName,
  label,
  listClassName,
  optionClassName,
  options,
  onChange,
  overrides,
  value,
  appearance = 'option',
}: RadioOptionsProps<T, V>) {
  return (
    <div className={cn(block(), className)}>
      <RadioOptionLabel>{label}</RadioOptionLabel>
      <RadioOptionList
        component={overrides.optionList}
        className={listClassName}
      >
        {options.map(opt => (
          <RadioOption
            className={optionClassName}
            component={overrides.option}
            disabled={disabled || opt.disabled}
            key={
              !isString(opt.value) && !isNumber(opt.value) ? opt.id : opt.value
            }
            option={opt}
            onClick={onChange}
            selected={opt.value === value}
            indicatorClassName={indicatorClassName}
            appearance={appearance}
          />
        ))}
      </RadioOptionList>
    </div>
  );
}

RadioOptions.defaultProps = {
  options: [],
  overrides: {},
};
