import { Button, Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';

import ModalContentWithMedia from 'components/Modal/ModalContentWithMedia';
import { Overrides } from 'components/Select';
import { Step } from 'components/SteppedModal';

import AccountOption from 'containers/InstagramPostVideoModal/AccountOption';
import { InstagramAccountOption } from 'containers/InstagramPostVideoModal/types';
import { InstagramMediaType } from 'redux/middleware/api/podcast-service';
import { block } from '../../utils';
import { InstagramPostModalStep } from '../types';
import ComposerBlockedView from './ComposerBlockedView';
import ComposerDefaultView from './ComposerDefaultView';
import { UsePostComposerBaseConfig } from './types';

export interface UsePostComposerStepConfig extends UsePostComposerBaseConfig {
  description: string;
  mediaType: InstagramMediaType;
  allowSubmit: boolean;
  instagramUserId: string | number;
  accounts: InstagramAccountOption[];
  onDescriptionChange: (newDescription: string) => void;
  onMediaTypeChange: (newMediaType: InstagramMediaType) => void;
  onHideModal?: () => void;
  onUserChange?: (newUserId: string | number) => void;
  onSubmit?: () => void;
}

const selectComponentOverrides: Overrides<InstagramAccountOption> = {
  control: {
    props: p => ({
      ...p,
      className: cn(p.className, block('control')),
    }),
  },
  option: {
    component: props => <AccountOption {...props} />,
  },
};

export default function usePostComposerStep({
  description,
  mediaType,
  allowSubmit,
  instagramUserId,
  accounts,
  onDescriptionChange,
  onMediaTypeChange,
  onHideModal,
  onUserChange,
  onSubmit,
}: UsePostComposerStepConfig): Step<InstagramPostModalStep> {
  return {
    id: 'composer',
    hideFooter: true,
    replaceBody: true,
    component: (
      <>
        <ModalContentWithMedia
          className="composer-step-content"
          onClose={onHideModal}
        >
          <ModalContentWithMedia.ModalContentWithMediaColumn>
            <img
              src="https://static.sparemin.com/static/webapp-assets/auto-posting-detail/auto-posting-detail-instagram-image-a-202407311738.png"
              className={block('composer-image')}
            />
          </ModalContentWithMedia.ModalContentWithMediaColumn>

          <ModalContentWithMedia.ModalContentWithMediaColumn className="composer-content-column">
            <Spacer
              space="20px"
              orientation="vertical"
              align="flex-start"
              className={block('composer-view')}
            >
              {mediaType === 'stories' ? (
                <ComposerBlockedView
                  {...{
                    accounts,
                    instagramUserId,
                    selectComponentOverrides,
                    onMediaTypeChange,
                    onUserChange,
                  }}
                />
              ) : (
                <ComposerDefaultView
                  {...{
                    description,
                    accounts,
                    instagramUserId,
                    onDescriptionChange,
                    selectComponentOverrides,
                    onUserChange,
                  }}
                />
              )}
            </Spacer>

            <Button
              fluid
              variant="solid"
              onPress={onSubmit}
              isDisabled={!allowSubmit}
            >
              confirm auto-posting details
            </Button>
          </ModalContentWithMedia.ModalContentWithMediaColumn>
        </ModalContentWithMedia>
      </>
    ),
  };
}
