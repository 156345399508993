import { Edit, Quote, User, Video } from '@sparemin/blockhead';
import React from 'react';
import EddyTool from './EddyTool';
import { block } from './utils';

export interface EddyToolsListProps {}

const EddyToolsList: React.FC<EddyToolsListProps> = () => {
  return (
    <div className={block('eddy-tools-list-container')}>
      <EddyTool
        icon={<Quote width={29} height={24} color="white" />}
        description="Transcribe any audio or video podcast"
      />
      <EddyTool
        icon={<User width={24} height={27} color="white" />}
        description="Automatically label your show’s speakers"
      />
      <EddyTool
        icon={<Video width={30} height={19} color="white" />}
        description="Get AI-powered clip suggestions"
      />
      <EddyTool
        icon={<Edit width={24} height={24} color="white" />}
        description="Editing made easy with filler word removal"
      />
    </div>
  );
};

export default EddyToolsList;
