import Immutable from 'immutable';
import ids from 'short-id';
import _ from 'underscore';

import { stateFactory as surveyStateFactory } from 'redux/modules/survey/factories';
import { getValue } from '../../utils/collections';

const manifest = {
  /*
   * version 0 was blank project
   */
  0: state => {
    if (Object.keys(state).length === 0) return state;

    const { embed, entities, recordingUpload } = state;

    const embedRecordingId = getValue(embed, [
      'configuration',
      'embedConfig',
      'recordingId',
    ]);

    /*
     * prior to blank project, `embed.audioById` state did not exist
     */
    function createAudioById(recordingDurationMillis) {
      if (_.isUndefined(embedRecordingId)) return Immutable.Map();
      /*
       * make sure to use `.set` on Immutable map to get this right.  seems like Immutable allows
       * numeric keys whereas native js objects do not.  avoid using a vanilla js object here to
       * prevent the numeric key from automatically getting converted to a string
       */
      return Immutable.Map().set(
        embedRecordingId,
        Immutable.Map({
          endAtMillis: recordingDurationMillis,
          id: embedRecordingId,
          startAtMillis: 0,
        }),
      );
    }

    /*
     * prior to blank project, there were technically only two tracks in the redux state - text1
     * and text2.  this will create the missing audio and image tracks
     */
    function createMissingTracks() {
      const slides = embed.get('slides');
      const tracks = embed.get('tracks');
      const tracksById = embed.get('tracksById');
      const imageTrack = Immutable.Map({
        id: ids.generate(),
        name: 'Images',
        type: 'image',
        data: Immutable.List(slides || []),
      });

      const audioTrack = Immutable.Map({
        id: ids.generate(),
        name: 'Audio',
        type: 'audio',
        data: _.isUndefined(embedRecordingId)
          ? Immutable.List()
          : Immutable.List([embedRecordingId]),
      });

      const updatedTracksById = tracksById.withMutations(ts =>
        ts
          .set(imageTrack.get('id'), imageTrack)
          .set(audioTrack.get('id'), audioTrack),
      );

      const updatedTracks = tracks.withMutations(ts =>
        ts.push(imageTrack.get('id')).push(audioTrack.get('id')),
      );

      return {
        tracks: updatedTracks,
        tracksById: updatedTracksById,
      };
    }

    /*
     * prior to blank project, slides only had start times and we did not store the editor color
     * with the slide in state (was just in redux state).
     *
     * sets each slided's end time to the start time of the next slide (or recording duration for
     * the last slide) and also adds editor color
     */
    function migrateSlides(recordingDurationMillis) {
      const slideIds = embed.get('slides');
      const slides = embed.get('slidesById');

      if (
        !slideIds ||
        slideIds.length === 0 ||
        _.isUndefined(recordingDurationMillis)
      ) {
        return {
          slides: slideIds,
          slidesById: slides,
        };
      }

      const migratedSlides = slideIds.reduce((res, slideId, idx, ary) => {
        const slide = slides.get(slideId);
        const endMillis =
          idx + 1 < ary.length
            ? slides.getIn([ary[idx + 1], 'startMilli'])
            : recordingDurationMillis;

        /*
         * NOTE: changed in 0.73.1 - removed the randomColor library, so hard-coding the slide
         *  editor color to the standard color used for slides in this version
         */
        const editorColor = 'rgb(113, 182, 180)';

        return res.set(
          slideId,
          slide.withMutations(s =>
            s.set('endMilli', endMillis).set('editorColor', editorColor),
          ),
        );
      }, Immutable.Map());

      return {
        slides: slideIds,
        slidesById: migratedSlides,
      };
    }

    function migrateEmbed() {
      // no embed state to migrate
      if (!embed || embed.isEmpty()) return Immutable.Map();

      // technically nothing to migrate.  just the default state with durationMillis === 0
      if (embed.size === 1 && embed.has('durationMillis')) return embed;

      const recordingDurationSec =
        embedRecordingId &&
        entities.getIn(['recordings', embedRecordingId.toString(), 'duration']);
      const recordingDurationMillis =
        recordingDurationSec && recordingDurationSec * 1000;

      const {
        tracks: updatedTracks,
        tracksById: updatedTracksById,
      } = createMissingTracks();

      const { slides, slidesById } = migrateSlides(recordingDurationMillis);
      return embed.withMutations(e =>
        e
          .set('audioById', createAudioById(recordingDurationMillis))
          .set('durationMillis', recordingDurationMillis)
          .set('slides', slides)
          .set('slidesById', slidesById)
          .set('tracks', updatedTracks)
          .set('tracksById', updatedTracksById)
          .delete('exportingVideo'),
      );
    }

    /*
     * prior to blank project, recording upload had a different shape since it was used only to
     * upload project audio.  now, it's used for project audio, track audio, and export audio.
     */
    function migrateRecordingUpload() {
      if (!recordingUpload || recordingUpload.isEmpty()) return Immutable.Map();

      const callEntry = entities.getIn([
        'callEntries',
        recordingUpload.get('callEntry'),
      ]);
      const recordingId = callEntry && callEntry.get('recording');

      return recordingUpload.withMutations(r => {
        r.set('type', 'PROJECT');
        r.set('transcribe', true);

        if (!_.isUndefined(recordingId)) {
          r.set('recordingId', recordingId);
        }
      });
    }

    /*
     * prior to blank project, embed export lived in `embed.exportingVideo`.  it has been moved to
     * its own top level state key and the shape has changed
     */
    function createEmbedExport() {
      if (!embed) return Immutable.Map();

      const exportingVideo = embed.get('exportingVideo');
      if (!exportingVideo || exportingVideo.isEmpty()) return Immutable.Map();

      return Immutable.Map({
        audioExport: Immutable.Map({
          status: 'SUCCEEDED',
        }),
        recordingId: embedRecordingId,
        renderedConfiguration: { ...embed.get('configuration') },
        videoExport: exportingVideo.delete('widgetId'),
        widgetId: embed.get('configuration').wid,
      });
    }

    // transform and return state
    return {
      ...state,
      embedExport: createEmbedExport(),
      embed: migrateEmbed(),
      recordingUpload: migrateRecordingUpload(),
    };
  },

  1: state => {
    if (Object.keys(state).length === 0) return state;

    const { embed } = state;

    if (typeof embed.get('isSaving') === 'undefined') {
      return state;
    }

    const migratedEmbed = embed.update('isSaving', val =>
      val ? 'RUNNING' : 'DONE',
    );

    return {
      ...state,
      embed: migratedEmbed,
    };
  },

  /**
   * new recording field (durationMillis) exposed, flush all recording cache
   * also flush embed cache because there are references to the recording by
   * recording id
   */
  2: state => {
    if (Object.keys(state).length === 0) return state;

    const { entities } = state;
    const migratedEntities = entities.remove('recordings');

    return {
      ...state,
      entities: migratedEntities,
      embed: Immutable.Map(),
    };
  },

  /**
   * version 3 changes the redux state such that the "$.embed.isLoading" flag is no longer
   * persisted.  This is to prevent the editor from loading multiple times since `EditorPage` is
   * attached to two routes.  When the editor attempts to load, it checks the `isLoading` flag
   * to see if it should call the load action.  If the flag is persisted across refreshes, then
   * refreshing the page while the editor is loading would cause the flag to get stuck at `true`
   * and the load process would hang.
   */
  3: state => {
    if (Object.keys(state).length === 0) return state;

    const { embed } = state;
    const migratedEmbed = embed.delete('isLoading');

    return {
      ...state,
      embed: migratedEmbed,
    };
  },

  /**
   * until version 4, there was a typo in persisted state keys - "audio-wizard"
   * was used rather than "audioWizard".  because of this unexpected data was
   * being persisted.
   *
   * of particular importance is the "isLoading" flag.  after successfully
   * loading the wizard once, isLoading=true gets persisted causing an
   * unnecessary re-mounting of the audio-wizard when you enter it a second time.
   *
   * this re-mounting isn't really visible to the user, but version 4 also
   * cleared redux state when the audio-wizard unmounts which would then cause
   * this persitance bug to generate an error that is visible to the user.
   */
  4: state => {
    if (Object.keys(state).length === 0) return state;

    const { audioWizard } = state;

    if (!audioWizard) return state;

    return {
      ...state,
      audioWizard: audioWizard.set('isLoading', true),
    };
  },

  /**
   * SPAR-8682
   *
   * After version 4 there was a business requirement to send the "cohort
   * survey data" to mixpanel to help identify users.
   *
   * Once we know that the user has answered the cohort survey, we no longer
   * query the server for the survey responses.  In order to trigger the API
   * request to backfill data in mixpanel for users who already answered the
   * survey, clear out the survey state key so that redux "forgets" that the
   * user has already answered the survey.
   *
   * This change causes the app to ask the API for the survey responses so we
   * can send them to mixpanel when they come back.
   */
  5: state => {
    if (Object.keys(state).length === 0) return state;

    return {
      ...state,
      survey: surveyStateFactory(),
    };
  },

  /**
   * SPAR-9007
   *
   * We need to send survey answers to GA from those users who already answered
   */
  6: state => {
    if (Object.keys(state).length === 0) return state;

    return {
      ...state,
      survey: surveyStateFactory(),
    };
  },

  /**
   * SPAR-9360
   *
   * update tags being sent to Zendesk
   */
  7: state => {
    if (Object.keys(state).length === 0) return state;

    return {
      ...state,
      survey: surveyStateFactory(),
    };
  },

  /**
   * SPAR-10457
   *
   * removed the cohort modal.  loading the cohort modal would set a key in
   * state called isLoadingCohort.  that key is persisted, so we need to remove
   * it from the rehydrate payload since the immutable Record that defines the
   * survey redux state no longer knows about that key
   */
  8: state => {
    if (Object.keys(state).length === 0) return state;

    return {
      ...state,
      survey: surveyStateFactory(),
    };
  },

  /**
   * SPAR-10751, SPAR-10807
   *
   * project history state was persisted prior to version 9 to prevent an empty
   * state flicker on the projects page every time it loads.
   *
   * in version 9, the projects list was put behind a tab navigator, eliminating
   * most of the flicker.
   *
   * when changing to the tabbed nav, the project list
   * infinite scroll became a little more difficult.  not persisting projects
   * makes this a little easier to deal with since we can alway start with a
   * clean state and not have to worry about complex and buggy logic such as
   * "all projects are fetched but the user is at the top so query for page 1
   * to see if anything has changed"
   */
  9: state => {
    if (Object.keys(state).length === 0) return state;

    const { entities, project } = state;
    if (!project) return state;

    return {
      ...state,
      entities: !entities ? undefined : entities.delete('projects'),
      project: project.delete('history'),
    };
  },

  // SPAR-17943, SPAR-18169, SPAR-14707
  // A modal overlay was introduced with (SPAR-14707) to refer users to a change in
  // location for automations.

  // Enough time has passed and the overlay is no longer needed, cleaning up the
  // overlay (SPAR-17943) introduced a White Screen of Death (SPAR-18169) due to
  // migration of the seenRuxOverlay state from localStorage back into memory.

  // We need to remove the specific state before that happens.
  10: state => {
    if (Object.keys(state).length === 0) return state;

    const { automationCms } = state;
    const postOverlayAutomationCms = automationCms.delete('seenRuxOverlay');

    return {
      ...state,
      automationCms: postOverlayAutomationCms,
    };
  },
  // https://sparemin.atlassian.net/browse/SPAR-18198
  //
  // Remove automationCms from persistence.
  //
  // Prior to the change (10) above, the only key of automationCms that was
  // persisted was seenRuxOverlay.  This key was persisted using a whitelist and
  // once the whitelist was removed from the persist config, the entire
  // automationCms state started getting persisted.
  //
  // The automationCms state is a Record, which gets persisted as a Map and then
  // rehydrated as a Map.  The code however expects a Record and tries to access
  // properties of state using dot notation rather than map getters, which fail
  // because state is a Map...
  //
  // Now that seekRuxOverlay no longer needs to be persisted, we can remove
  // automationCms from state.  This change removes the key from the store so that
  // it doesn't get rehydrated as Map and cause errors.
  11: state => {
    if (Object.keys(state).length === 0) return state;

    const { automationCms, ...restState } = state;

    return restState;
  },
};

export default manifest;
