import * as React from 'react';
import FontAwesome from 'components/FontAwesome';
import PopperTile, { PopperTileProps } from './PopperTile';

type PickedPopperTileProps = Pick<
  PopperTileProps,
  'children' | 'disabled' | 'id'
>;

export interface PlacementPopperProps extends PickedPopperTileProps {
  className?: string;
}

const PlacementPopper: React.FC<PlacementPopperProps> = ({
  children,
  className,
  disabled,
  id,
}) => (
  <PopperTile
    icon={
      <FontAwesome icon="arrows-alt" style={{ height: 25, width: 'auto' }} />
    }
    {...{ className, disabled, id }}
  >
    {children}
  </PopperTile>
);

export default PlacementPopper;
