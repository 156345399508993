import * as React from 'react';
import { noop } from 'underscore';

import ColorChangeInput, {
  ColorChangeInputProps,
} from 'components/ColorChangeInput';
import { Omit } from 'types';
import { createChainedFunction } from 'utils/functions';
import FormField, { FormFieldProps } from './FormField';
import { block } from './utils';

const { useCallback } = React;

interface Value {
  fromColor?: string;
  toColor?: string;
}

type InputProps = Omit<ColorChangeInputProps, 'fromColor' | 'toColor'>;

interface Props extends InputProps {
  onChange?: (value: Value) => void;
  value?: Value;

  /**
   * @deprecated
   * legacy handler.  prefer onChange.
   * TODO removing these handlers requires some refacotring of EditCaptionsModalBody
   */
  onFromColorChange?: (color: string) => void;

  /**
   * @deprecated
   */
  onToColorChange?: (color: string) => void;
}

export type ColorChangeFieldProps = FormFieldProps<Props>;

const ColorChangeField: React.SFC<ColorChangeFieldProps> = ({
  onChange,
  onFromColorChange,
  onToColorChange,
  value,
  ...props
}) => {
  const handleFromColorChange = useCallback(
    color => {
      onChange({
        fromColor: color,
        toColor: value.toColor,
      });
    },
    [onChange, value.toColor],
  );

  const handleToColorChange = useCallback(
    color => {
      onChange({
        fromColor: value.fromColor,
        toColor: color,
      });
    },
    [onChange, value.fromColor],
  );

  return (
    <FormField<InputProps> {...props}>
      <ColorChangeInput
        className={block('color-change-input')}
        onFromColorChange={createChainedFunction(
          handleFromColorChange,
          onFromColorChange,
        )}
        onToColorChange={createChainedFunction(
          handleToColorChange,
          onToColorChange,
        )}
        fromColor={value.fromColor}
        toColor={value.toColor}
      />
    </FormField>
  );
};

ColorChangeField.defaultProps = {
  onChange: noop,
  value: {},
};

export default ColorChangeField;
