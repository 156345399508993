import React, { FunctionComponent, useCallback } from 'react';

import IconButton from 'components/IconButton';
import { SaveAsTemplate as SaveAsTemplateIcon } from 'components/icons';
import Tooltip from 'components/Tooltip';
import { onClickSaveTemplate } from 'redux/modules/mixpanel';
import { ClickSaveTemplateFrom } from 'redux/modules/mixpanel/types';
import { block } from '../../utils';

interface SaveAsTemplateProps {
  disabled?: boolean;
  from?: ClickSaveTemplateFrom;
  onSave: (from?: string) => void;
}

const SaveAsTemplate: FunctionComponent<SaveAsTemplateProps> = props => {
  const { disabled, from, onSave } = props;

  const handleButtonClick = useCallback(() => {
    onClickSaveTemplate({ from });

    onSave(from);
  }, [from, onSave]);

  return (
    <div className={block()}>
      <Tooltip
        animation={false}
        content="Save as a template"
        id="save-as-template-tooltip"
        placement="bottom"
        preventHideOnHover={false}
      >
        <IconButton onButtonClick={handleButtonClick} disabled={disabled}>
          <SaveAsTemplateIcon />
          <span className={block('icon-text')}>Save as a template</span>
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default SaveAsTemplate;
export { SaveAsTemplateProps };
