import { fromJS } from 'immutable';
import { isUndefined } from 'underscore';

import { getSubscriptionById } from 'redux/middleware/api/podcast-service/actions';
import { actions as videoProjectManagementServiceActions } from 'redux/middleware/api/video-project-management-service';
import * as authSelectors from 'redux/modules/auth/selectors';
import {
  createEmbedConfiguration,
  getEmbedConfiguration,
} from 'redux/modules/embed/actions/embed';
import { copyTranscriptByRevisionId } from 'redux/modules/embed/actions/transcript';
import * as embedSelectors from 'redux/modules/embed/selectors';
import {
  podcastSubscriptionsSelector,
  projectsSelector,
  projectTemplatesSelector,
} from 'redux/modules/entities/selectors';
import * as mixpanelActions from 'redux/modules/mixpanel';
import { actions as projectActions } from 'redux/modules/project';
import { getAspectRatio, getAspectRatioName } from 'utils/aspect-ratio';
import { exportAudio } from 'utils/audio';
import { getValue } from 'utils/collections';
import {
  DEFAULT_PROJECT_NAME,
  DEFAULT_PROJECT_PAGE_SIZE,
  DEFAULT_REVISION_HISTORY_PAGE,
  DEFAULT_REVISION_HISTORY_SIZE,
  VIEWPORTS,
} from 'utils/constants';
import { rescaleOverlayToViewport } from 'utils/embed/text-overlay-ts';
import { scaleImageToNewViewport } from 'utils/image';
import {
  preprocessEmbedCopyEmbedConfig,
  preprocessEmbedCopyTranscript,
} from './actions-ts';
import * as projectSelectors from './selectors';
import * as types from './types';
import { getSubscriptionId } from './utils';

const PROJECT_HISTORY_CREATE_METHODS = [
  'unknown',
  'blankProject',
  'recommended',
  'audiogram',
  'copy',
  'sample',
  'videoTranscript',
  'textToVideo',
  'episode',
  'audio',
  'audioLong',
  'template',
  'podcastFeedAutoCreate',
  'podcastFeedAutoCreateEpisode',
];

export const loadProjectsTab = () => dispatch => {
  dispatch(mixpanelActions.onProjectsTabLoad());
  dispatch({ type: types.PROJECTS_TAB_LOAD });
};

export const postNewProject = (
  id,
  name,
  aspectRatio,
  createMethod,
  options,
) => dispatch =>
  dispatch(
    videoProjectManagementServiceActions.createProject(
      id,
      name,
      aspectRatio,
      createMethod,
      options,
    ),
  );

const getAllProjects = (ownerId, createMethod, page, size) => dispatch =>
  dispatch(
    videoProjectManagementServiceActions.fetchProjects(
      ownerId,
      createMethod,
      page,
      size,
    ),
  );

const updateProject = (id, wid, name, thumbnail, aspectRatio) => dispatch =>
  dispatch(
    videoProjectManagementServiceActions.putProject(
      id,
      wid,
      name,
      aspectRatio,
      thumbnail,
    ),
  );

const deleteProject = id => dispatch =>
  dispatch(videoProjectManagementServiceActions.deleteProject(id));

const fetchProjectById = id => dispatch =>
  dispatch(videoProjectManagementServiceActions.fetchProjectById(id));

export const createProject = (
  wid,
  aspectRatio,
  name = DEFAULT_PROJECT_NAME,
  method,
  thumbnailUrl,
) => (dispatch, getState) => {
  dispatch({ type: types.PROJECT_CREATE_REQUEST });

  const widgetId = wid || embedSelectors.embedWidgetIdSelector(getState());
  const ratio =
    aspectRatio || projectSelectors.newProjectAspectRatioSelector(getState());

  if (!ratio) {
    dispatch({ type: types.PROJECT_CREATE_FAILURE });
    return Promise.reject(
      Error('Aspect ratio required for creating a new project'),
    );
  }

  return dispatch(
    postNewProject(widgetId, name, ratio, method, { thumbnailUrl }),
  )
    .then(res => {
      const id = res.response.result;
      return dispatch({
        type: types.PROJECT_CREATE_SUCCESS,
        payload: { id },
      });
    })
    .catch(err =>
      dispatch({
        ...err,
        type: types.PROJECT_CREATE_FAILURE,
      }),
    );
};

const getProjectsByUserSuccess = response => ({
  type: types.PROJECT_HISTORY_GET_SUCCESS,
  payload: { ...(response || {}) },
});

/**
 * @param {boolean=true} loadNextPage when set to true, will fetch the next page of project history
 *  results, otherwise will query for page 1
 */
export const getProjectHistory = (loadNextPage = true) => (
  dispatch,
  getState,
) => {
  const userId = authSelectors.userIdSelector(getState());
  const projectsTabActive = projectSelectors.isProjectsTabActiveSelector(
    getState(),
  );
  const projectsPageFetchCount = projectSelectors.projectsPageFetchCountSelector(
    getState(),
  );

  dispatch({
    type: types.PROJECT_HISTORY_GET_REQUEST,
    payload: { userId },
  });

  const page = projectSelectors.projectHistoryPageSelector(getState());
  const nextPage =
    !loadNextPage || isUndefined(page) ? 1 : page.get('number') + 1;
  const pageSize = isUndefined(page)
    ? DEFAULT_PROJECT_PAGE_SIZE
    : page.get('size');
  const totalPages = page && page.get('totalPages');

  /*
   * NB: total pages can be undefined, but nextPage will always be a number and
   *     number > undefined === false
   *
   * if we're not loading the next page (i.e. we're starting from the beginning with page 1) then
   * we don't care how many pages there are, so skip the check in that case
   */
  if (loadNextPage && nextPage > totalPages) {
    return dispatch(getProjectsByUserSuccess());
  }

  if (projectsTabActive) {
    dispatch(mixpanelActions.onNextProjectsPage(projectsPageFetchCount + 1));
  }

  return dispatch(
    getAllProjects(userId, PROJECT_HISTORY_CREATE_METHODS, nextPage, pageSize),
  )
    .then(res => {
      const { result: content, page: pageNumber } = res.response;
      const payload = { content, page: pageNumber };
      dispatch(getProjectsByUserSuccess(payload));
    })
    .catch(err =>
      dispatch({
        ...err,
        type: types.PROJECT_HISTORY_GET_FAILURE,
      }),
    );
};

/**
 * gets the count of the number of projects
 */
export const getProjectsCount = () => (dispatch, getState) => {
  const userId = authSelectors.userIdSelector(getState());

  dispatch({
    type: types.PROJECTS_COUNT_GET_REQUEST,
    payload: { userId },
  });

  return dispatch(
    getAllProjects(
      userId,
      PROJECT_HISTORY_CREATE_METHODS,
      1,
      DEFAULT_PROJECT_PAGE_SIZE,
    ),
  )
    .then(res => {
      const { page: pageNumber } = res.response;
      const payload = { page: pageNumber };
      dispatch({
        type: types.PROJECTS_COUNT_GET_SUCCESS,
        payload,
      });
    })
    .catch(err =>
      dispatch({
        ...err,
        type: types.PROJECTS_COUNT_GET_FAILURE,
      }),
    );
};

export const getProjectById = id => dispatch => {
  dispatch({ type: types.PROJECT_GET_BY_ID_REQUEST });

  return dispatch(fetchProjectById(id))
    .then(res => {
      const { result: projectId } = res.response;
      const { [projectId]: project } = res.response.entities.projects;
      return dispatch({
        type: types.PROJECT_GET_BY_ID_SUCCESS,
        payload: { project },
      });
    })
    .catch(err => {
      dispatch({
        ...err,
        type: types.PROJECT_GET_BY_ID_FAILURE,
      });
      return Promise.reject(err);
    });
};

export const updateCurrentProject = (wid, name, thumbnailUrl, aspectRatio) => (
  dispatch,
  getState,
) => {
  const project = projectSelectors.projectSelector(getState());
  if (!project) return Promise.resolve();

  const currentProjectName = project.getIn(['projectConfig', 'projectName']);
  const widgetId = wid || embedSelectors.embedWidgetIdSelector(getState());
  const projectName = name || currentProjectName;
  const thumbUrl =
    thumbnailUrl || project.getIn(['projectConfig', 'thumbnailUrl']);
  const ratio = aspectRatio || project.getIn(['projectConfig', 'aspectRatio']);

  if (name && name !== currentProjectName) {
    dispatch(mixpanelActions.onNameProject(name));
  }

  dispatch({
    type: types.PROJECT_UPDATE_REQUEST,
    payload: {
      id: project.get('projectUuid'),
      name: projectName,
      thumbnailUrl: thumbUrl,
      wid: widgetId,
      aspectRatio: ratio,
    },
  });

  return dispatch(
    updateProject(
      project.get('projectUuid'),
      widgetId,
      projectName,
      thumbUrl,
      ratio,
    ),
  )
    .then(() => dispatch(getProjectById(project.get('projectUuid'))))
    .then(() => dispatch(projectActions.getRevisionHistoryByProjectId()))
    .then(() => dispatch({ type: types.PROJECT_UPDATE_SUCCESS }))
    .catch(err => ({
      ...err,
      type: types.PROJECT_UPDATE_FAILURE,
    }));
};

export const deleteProjectById = id => dispatch => {
  if (!id) return Promise.resolve();

  // When deleting a project, we'll remove it from the list first and assume
  // that it succeeds.
  dispatch({
    type: types.PROJECT_DELETE,
    payload: {
      id,
    },
  });

  return dispatch(deleteProject(id));
};

export const setProject = id => dispatch =>
  dispatch({
    type: types.PROJECT_SET,
    payload: { id },
  });

export const clearProject = () => dispatch =>
  dispatch({ type: types.PROJECT_CLEAR });

export const clearProjectHistory = () => ({
  type: types.PROJECT_HISTORY_CLEAR,
});

export const setNewProjectSettings = aspectRatioName => dispatch => {
  const dimensions = getAspectRatio(aspectRatioName);
  const aspectRatio = dimensions.set('name', aspectRatioName);

  dispatch({
    type: types.NEW_PROJECT_SETTINGS_SET,
    payload: { aspectRatio },
  });
};

export const clipWizardAudio = audioClip => dispatch => {
  const { file, source, startMillis, endMillis } = audioClip;

  if (startMillis === 0 && endMillis === source.duration * 1000) {
    return Promise.resolve(file);
  }

  dispatch({ type: types.PROJECT_WIZARD_AUDIO_CLIP_REQUEST });
  const futClippedAudio = exportAudio({
    source,
    fromSeconds: startMillis / 1000,
    toSeconds: endMillis / 1000,
  }).then(clipped => new Blob([clipped], { type: 'audio/wav' }));

  futClippedAudio
    .then(() => dispatch({ type: types.PROJECT_WIZARD_AUDIO_CLIP_SUCCESS }))
    .catch(err =>
      dispatch({
        type: types.PROJECT_WIZARD_AUDIO_CLIP_FAILURE,
        error: err.message,
      }),
    );

  return futClippedAudio;
};

const findProjectAmongProjectsByWid = (projects, wid) =>
  projects.find(project => {
    const embedConfigurationUuid = project.getIn([
      'projectConfig',
      'embedConfigurationUuid',
    ]);

    return wid === embedConfigurationUuid ? project : undefined;
  });

const doCopy = (
  dispatch,
  projectId,
  embedConfigurationId,
  sourceAspectRatio,
  fromAspectRatioName,
  targetAspectRatio,
  targetName,
  fromEditor,
  thumbnailUrl = '',
) => {
  const sourceViewport = getValue(VIEWPORTS, fromAspectRatioName);
  const targetViewport = getValue(
    VIEWPORTS,
    getAspectRatioName(targetAspectRatio),
  );

  const embedPromise = dispatch(
    preprocessEmbedCopyEmbedConfig(embedConfigurationId, targetViewport),
  );

  const transcriptPromise = embedPromise.then(embedConfig =>
    dispatch(preprocessEmbedCopyTranscript(embedConfig)),
  );

  return Promise.all([embedPromise, transcriptPromise])
    .then(([embedConfig, { transcriptId, revisionId }]) => {
      const config = embedConfig;
      if (getValue(config, ['captions', 'captionSource', 'transcriptId'])) {
        config.captions.captionSource.transcriptId = transcriptId;
        config.captions.captionSource.revisionId = revisionId;
      }

      if (sourceAspectRatio !== targetAspectRatio) {
        config.dimensions = targetAspectRatio;
      }

      const textOverlayInfo = fromJS(getValue(config, 'textOverlayInfo', []));
      if (textOverlayInfo.size > 0) {
        config.textOverlayInfo = textOverlayInfo
          .map(overlay => {
            return rescaleOverlayToViewport(overlay, targetViewport).setIn(
              ['editor', 'styleContext', 'viewport'],
              targetViewport,
            );
          })
          .toJS();
      }

      config.slideshowInfo = getValue(config, 'slideshowInfo', []).map(
        slide => {
          // if height or width are undefined, scaleImageToNewViewport will return
          // undefined and the resulting image asset will have no size.  when
          // height/width is undefined and a user tries to edit the image, the
          // editor's default placement will decide where the image goes
          const { height, width } = slide.style || {};
          return {
            ...slide,
            style: {
              ...slide.style,
              ...scaleImageToNewViewport(sourceViewport, targetViewport, {
                height,
                width,
              }),
            },
          };
        },
      );

      config.watermark = getValue(config, 'watermark', []).map(watermark => {
        const { height, width } = watermark.style;
        return {
          ...watermark,
          style: {
            ...watermark.style,
            ...scaleImageToNewViewport(sourceViewport, targetViewport, {
              height,
              width,
            }),
          },
        };
      });

      return dispatch(createEmbedConfiguration(config));
    })
    .then(res => {
      const method = 'copy';
      const { wid } = res.response.configuration;
      const options = {
        thumbnailUrl,
      };

      if (projectId) {
        options.copiedFromProjectId = projectId;
      }

      return dispatch(
        postNewProject(wid, targetName, targetAspectRatio, method, options),
      );
    })
    .then(res => {
      const id = res.response.result;
      return dispatch({
        type: types.PROJECT_COPY_SUCCESS,
        payload: { id, isRedirect: fromEditor },
      });
    })
    .catch(err => {
      dispatch({
        type: types.PROJECT_COPY_FAILURE,
        ...err,
      });
      throw err;
    });
};

export const copyProject = (
  copyProjectId,
  targetName,
  targetAspectRatio,
  fromEditor,
) => (dispatch, getState) => {
  const widgetId = embedSelectors.embedWidgetIdSelector(getState());
  const projects = projectsSelector(getState());
  const projectByWidgetId = findProjectAmongProjectsByWid(projects, widgetId);
  const projectId =
    copyProjectId ||
    (projectByWidgetId && projectByWidgetId.get('projectUuid'));

  if (!projectId) {
    const fromAspectRatioName =
      embedSelectors.aspectRatioNameSelector(getState()) ??
      getAspectRatioName(targetAspectRatio);
    return doCopy(
      dispatch,
      undefined,
      widgetId,
      targetAspectRatio,
      fromAspectRatioName,
      targetAspectRatio,
      targetName,
      fromEditor,
    );
  }

  dispatch({
    type: types.PROJECT_COPY_REQUEST,
    payload: {
      id: projectId,
    },
  });

  const project = projects && projects.get(projectId);

  if (!project) {
    return dispatch({ type: types.PROJECT_COPY_FAILURE });
  }

  const sourceAspectRatio = project.getIn(['projectConfig', 'aspectRatio']);
  const fromAspectRatio = getAspectRatioName(
    project.getIn(['projectConfig', 'aspectRatio']),
  );
  const toAspectRatio = getAspectRatioName(targetAspectRatio);
  dispatch(
    mixpanelActions.onCopyProject(projectId, fromAspectRatio, toAspectRatio),
  );

  const embedConfigurationId = project.getIn([
    'projectConfig',
    'embedConfigurationUuid',
  ]);
  const thumbnailUrl = project.getIn(['projectConfig', 'thumbnailUrl']);

  return doCopy(
    dispatch,
    projectId,
    embedConfigurationId,
    sourceAspectRatio,
    fromAspectRatio,
    targetAspectRatio,
    targetName,
    fromEditor,
    thumbnailUrl,
  );
};

export const copyCurrentProject = (targetName, targetAspectRatio) => (
  dispatch,
  getState,
) => {
  const projectId = projectSelectors.projectIdSelector(getState());

  return dispatch(copyProject(projectId, targetName, targetAspectRatio, true));
};

export const getRevisionHistoryByProjectId = (
  page = DEFAULT_REVISION_HISTORY_PAGE,
  size = DEFAULT_REVISION_HISTORY_SIZE,
) => (dispatch, getState) => {
  const projectId = projectSelectors.projectIdSelector(getState());

  projectId &&
    dispatch(
      videoProjectManagementServiceActions.getRevisionHistoryByProjectId(
        projectId,
        page,
        size,
      ),
    );
};

export const createProjectFromTemplate = templateId => async (
  dispatch,
  getState,
) => {
  dispatch({ type: types.PROJECT_TEMPLATE_CREATE_REQUEST });

  const projectTemplates = projectTemplatesSelector(getState());
  const template = projectTemplates.get(templateId);

  if (!template) {
    const error = `Cannot find template with id: ${templateId}`;

    throw new Error(error);
  }

  const embedPromise = dispatch(
    getEmbedConfiguration(template.get('configurationId')),
  ).then(res => res.response.configuration.embedConfig);

  const transcriptPromise = embedPromise
    .then(embedConfig => {
      const captionsSource =
        getValue(embedConfig, ['captions', 'captionSource']) || {};
      const { revisionId, transcriptId } = captionsSource;
      if (transcriptId && revisionId) {
        return dispatch(copyTranscriptByRevisionId(transcriptId, revisionId));
      }
      return null;
    })
    .then(res => {
      const transcriptId = getValue(res, ['response', 'result']);
      const revisionId = getValue(res, [
        'response',
        'entities',
        'manualTranscripts',
        transcriptId,
        'revisionId',
      ]);

      return { transcriptId, revisionId };
    });

  return Promise.all([embedPromise, transcriptPromise])
    .then(([embedConfig, { transcriptId, revisionId }]) => {
      const config = embedConfig;
      if (getValue(config, ['captions', 'captionSource', 'transcriptId'])) {
        config.captions.captionSource.transcriptId = transcriptId;
        config.captions.captionSource.revisionId = revisionId;
      }
      return dispatch(createEmbedConfiguration(config));
    })
    .then(res => {
      const { wid } = res.response.configuration;
      return dispatch(
        postNewProject(
          wid,
          DEFAULT_PROJECT_NAME,
          template.get('dimension'),
          'template',
          {
            createdFromTemplateId: template.get('id'),
          },
        ),
      );
    })
    .then(res => {
      const id = res.response.result;

      dispatch({
        type: types.PROJECT_TEMPLATE_CREATE_SUCCESS,
        payload: { id },
      });

      return id;
    })
    .catch(err => {
      const error = err.message || 'Error creating the project from template';
      dispatch({
        type: types.PROJECT_TEMPLATE_CREATE_FAILURE,
        error,
      });
    });
};

export const getProjectSubscriptionIfExists = projectId => async (
  dispatch,
  getState,
) => {
  const project = getValue(projectsSelector(getState()), projectId);
  const subscriptionId = getSubscriptionId(project);

  if (!subscriptionId) {
    return null;
  }

  await dispatch(getSubscriptionById(subscriptionId));

  const subscriptions = podcastSubscriptionsSelector(getState());
  const subscription = getValue(subscriptions, String(subscriptionId));

  if (!subscription) {
    return null;
  }

  return subscription.toJS();
};
