import React from 'react';

import { useSelector } from 'react-redux';
import LoadingOverlay from 'components/LoadingOverlay';
import Modal from 'components/Modal';
import { instagramPostStatusSelector } from 'redux/modules/social';
import { PostStatus } from 'redux/modules/social/constants';
import { block } from './utils';

export interface LoaderStepProps {}

const LoaderStep: React.FC<LoaderStepProps> = () => {
  const postStatus = useSelector(instagramPostStatusSelector);

  const isUploading = postStatus === PostStatus.IN_PROGRESS;

  return (
    <Modal.Body className={block('loader-step')}>
      <LoadingOverlay
        className={block('loader-step--loading-overlay')}
        title={isUploading ? 'Posting video' : 'Loading Instagram accounts'}
      />
    </Modal.Body>
  );
};

export default LoaderStep;
