import { Spacer } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';

import SeasonsSelector, {
  getNormalizedSeasonsList,
  useSeasonsSelector,
} from 'blocks/AutomationWorkflowWizard/containers/AutogramSeasonsStep/SeasonsSelector';
import LoadingMessage from 'components/LoadingMessage';
import { ModalBody, ModalFooter, ModalFooterButton } from 'components/Modal';
import EditAutomationLoader from 'containers/EditAutomationModal/EditAutomationLoader';
import EditAutomationSelectHeader from 'containers/EditAutomationModal/EditAutomationSelectHeader';
import useEditAutomationModal from 'containers/EditAutomationModal/hooks/useEditAutomationModal';
import { getSubscriptionItem } from 'containers/EditAutomationModal/utils';
import { Season } from 'redux/modules/automation-cms/types';
import useEditAutomationEpisode from '../useEditAutomationEpisode';
import { block } from './utils';

interface EditAutomationEpisodeSelectSeasonModalContentsProps {
  subscriptionId?: number;
}

const EditAutomationEpisodeSelectSeasonModalContents: React.FunctionComponent<EditAutomationEpisodeSelectSeasonModalContentsProps> = props => {
  const { subscriptionId } = props;

  const { subscription, onGoBackToMain } = useEditAutomationModal(
    subscriptionId,
  );
  const { onOpenEpisodeSelection, onUpdateSeasons } = useEditAutomationEpisode({
    subscriptionId,
  });

  const {
    videoFrequencyPref,
    targetedSeasons: targetedSeasonsPref,
  } = getSubscriptionItem(subscription);

  const [targetedSeasons, setTargetedSeasons] = useState<number[] | undefined>(
    targetedSeasonsPref,
  );

  const handleInitializeTargetedSeasons = useCallback(
    async (seasons: Season[]): Promise<void> => {
      const normalizedSeasons = getNormalizedSeasonsList(seasons);

      if (!normalizedSeasons.length) {
        await onUpdateSeasons('fromFirstEpisode');
        return onGoBackToMain();
      }

      return setTargetedSeasons(
        videoFrequencyPref === 'fromFirstEpisode' || !targetedSeasonsPref
          ? normalizedSeasons
          : targetedSeasonsPref,
      );
    },
    [onGoBackToMain, onUpdateSeasons, targetedSeasonsPref, videoFrequencyPref],
  );

  const {
    isLoading,
    seasons,
    totalEpisodes,
    onTargetedListChange,
    onAllEpisodesClick,
  } = useSeasonsSelector({
    podcastId: subscription.podcastId,
    onTargetedSeasonsChange: setTargetedSeasons,
    onSeasonsLoaded: handleInitializeTargetedSeasons,
  });

  const handleChange = useCallback(
    (season: number, isSelected: boolean): void => {
      onTargetedListChange(targetedSeasons, season, isSelected);
    },
    [onTargetedListChange, targetedSeasons],
  );

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (targetedSeasons?.length === getNormalizedSeasonsList(seasons)?.length) {
      await onUpdateSeasons('fromFirstEpisode');
    } else {
      await onUpdateSeasons('targetedSeason', targetedSeasons);
    }

    onGoBackToMain();
  }, [onGoBackToMain, onUpdateSeasons, seasons, targetedSeasons]);

  return (
    <>
      <ModalBody>
        <Spacer
          orientation="vertical"
          space={3.1}
          className={block('content-container')}
        >
          <EditAutomationSelectHeader
            onGoBack={onOpenEpisodeSelection}
            title="Select seasons to include"
          />

          {isLoading || !getNormalizedSeasonsList(seasons)?.length ? (
            <Spacer justify="center" className={block('loader-container')}>
              <LoadingMessage message="Analyzing feed" noFadeIn />
            </Spacer>
          ) : (
            <SeasonsSelector
              {...{ seasons, totalEpisodes }}
              onChange={handleChange}
              onAllEpisodesClick={onAllEpisodesClick}
              targetedSeasons={targetedSeasons}
            />
          )}
        </Spacer>
      </ModalBody>
      <ModalFooter>
        <ModalFooterButton
          fluid
          onClick={handleSubmit}
          theme="submit"
          disabled={isLoading || !targetedSeasons?.length}
        >
          continue with these seasons
        </ModalFooterButton>
      </ModalFooter>
      <EditAutomationLoader />
    </>
  );
};

export default EditAutomationEpisodeSelectSeasonModalContents;
