import { Type } from './action-types';
import { stateFactory } from './factories';
import { VideoClipWizardAction, VideoClipWizardState } from './types';

const defaultState = stateFactory();

function reducer(
  state: VideoClipWizardState = defaultState,
  action: VideoClipWizardAction,
) {
  switch (action.type) {
    case Type.VIDEO_CLIP_WIZARD_DATA_LOAD_REQUEST:
      return defaultState.withMutations(s => {
        s.set('isLoading', true);
        s.set('wizardLoadError', false);

        return s;
      });

    case Type.VIDEO_CLIP_WIZARD_DATA_LOAD_SUCCESS:
      return state.set('isLoading', false);

    case Type.VIDEO_CLIP_WIZARD_DATA_LOAD_FAILURE:
      return defaultState.withMutations(s => {
        s.set('isLoading', false);
        s.set('wizardLoadError', true);

        return s;
      });

    case Type.VIDEO_CLIP_WIZARD_TRACE_ID_SET: {
      const { traceId } = action.payload;

      return state.set('traceId', traceId);
    }

    default:
      return state;
  }
}

export default reducer;
