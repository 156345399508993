import React from 'react';
import ChipElement from './ChipElement';
import { ChipListElement } from './types';
import { block } from './utils';

interface ChipListProps {
  elementsList: ChipListElement[];
  onClick: (value: string) => void;
}

const ChipsList: React.FC<ChipListProps> = ({ elementsList, onClick }) => {
  return (
    <div className={block()}>
      {elementsList.map(element => (
        <ChipElement {...{ element, onClick }} />
      ))}
    </div>
  );
};

export default ChipsList;
