import cn from 'classnames';
import * as React from 'react';
import { Row as BSRow, RowProps as BSRowProps } from 'react-bootstrap';

import { block } from './utils';

export interface RowProps extends BSRowProps {
  divider?: boolean;
}

const Row: React.SFC<RowProps> = ({ className, divider, ...rowProps }) => (
  <BSRow {...rowProps} className={cn(block('row', { divider }), className)} />
);

Row.defaultProps = {
  divider: false,
};

export default Row;
