import { fromJS } from 'immutable';
import React from 'react';

import { TextOverlayV2 } from '../../types';
import { OverlayTiming, OverlayTimingConfig } from './types';

interface UseOverlayTimingConfig {
  extensionConfig: OverlayTimingConfig;
}

type UseOverlayTiming = OverlayTiming;

const useTiming = (config: UseOverlayTimingConfig): UseOverlayTiming => {
  const { extensionConfig = {} } = config;

  const { enabled = false, startMillis = 0, endMillis = 0 } = extensionConfig;

  const appendTimingToOverlay = React.useCallback(
    (overlay: TextOverlayV2): TextOverlayV2 => {
      if (!enabled) {
        return overlay;
      }

      return overlay.set(
        'time',
        fromJS({
          startMillis,
          endMillis,
        }),
      );
    },
    [enabled, endMillis, startMillis],
  );

  return {
    appendTimingToOverlay,
    enabled,
    endMillis,
    startMillis,
  };
};

export default useTiming;
