import cn from 'classnames';
import * as React from 'react';

import bem from 'utils/bem';

export interface AuthDialogWrapperProps {
  children?: React.ReactNode;
  className?: string;
}

const block = bem('auth-dialog-wrapper');

/**
 * Used to wrap dialogs (cards) on the AuthPage to provide the correct spacing
 * on desktop and mobile
 */
const AuthDialogWrapper: React.FC<AuthDialogWrapperProps> = ({
  children,
  className,
}) => <div className={cn(block(), className)}>{children}</div>;

export default AuthDialogWrapper;
