import { createSelector } from 'reselect';

import { State } from 'redux/types';
import {
  podcastFeedEpisodesSelector,
  podcastSubscriptionsSelector,
} from '../entities';
import { isFreeSelector } from '../pricing';
import { AutomationCMSState } from './types';

const stateSelector = (state: State): AutomationCMSState =>
  state.get('automationCms');

export const workflowIdsSelector = createSelector(
  stateSelector,
  state => state.workflows && state.workflows.toArray(),
);
export const activeWorkflowIdsSelector = createSelector(
  podcastSubscriptionsSelector,
  subscriptions =>
    subscriptions
      ?.map(subscription => {
        if (subscription.get('isEnabled')) {
          return subscription.get('autoCreateSubscriptionId');
        }

        return undefined;
      })
      .filter(Boolean)
      .toArray(),
);

export const workflowTemplateIdsSelector = createSelector(
  stateSelector,
  state => state.templates,
);

export const isLoadingWorkflowTemplatesSelector = createSelector(
  stateSelector,
  state => state.isLoadingTemplates,
);

export const isLoadingSeasonsSelector = createSelector(
  stateSelector,
  state => state.isLoadingSeasons,
);

export const seasonsSelector = createSelector(
  stateSelector,
  state => state.seasons && state.seasons.toArray(),
);
export const totalEpisodesSelector = createSelector(
  stateSelector,
  state => state.totalEpisodes,
);

export const isFetchingPodcastFeedsSelector = createSelector(
  stateSelector,
  state => state.isFetchingPodcastFeeds,
);

export const isUpdatingRevokedPermissionsSelector = createSelector(
  stateSelector,
  state => state.isUpdatingRevokedPermissions,
);

export const podcastFeedIdsSelector = createSelector(
  stateSelector,
  state => state.podcastFeedIds,
);

export const podcastFeedDataSelector = createSelector(
  stateSelector,
  state => state.podcastFeedData,
);

export const podcastEpisodeDataSelector = createSelector(
  stateSelector,
  state => state.podcastEpisodeData,
);

export const manualEpisodeTriggerStatusesSelector = createSelector(
  stateSelector,
  state => state.manualEpisodeTriggerStatuses,
);

export const isWorkflowLimitReachedSelector = createSelector(
  activeWorkflowIdsSelector,
  isFreeSelector,
  (activeWorkflowIds, isFree) => isFree && activeWorkflowIds?.length > 0,
);

export const createTranscriptionEpisodeIdSelector = createSelector(
  stateSelector,
  state => state.createTranscriptionEpisodeId,
);

export const isSubmittingEpisodeTranscriptionCreationRequestSelector = createSelector(
  createTranscriptionEpisodeIdSelector,
  episodeId => episodeId !== undefined,
);

export const pollTranscriptEpisodeIdsSelector = createSelector(
  stateSelector,
  state => state.pollTranscriptionEpisodeIds,
);

export const pollTranscriptEpisodesSelector = createSelector(
  podcastFeedEpisodesSelector,
  pollTranscriptEpisodeIdsSelector,
  (episodes, episodeIds) =>
    episodes.filter((_, key) => episodeIds.includes(key)),
);

export const isPollingEpisodeTranscriptionsSelector = createSelector(
  stateSelector,
  state => state.isPollingEpisodeTranscriptions,
);

export const isPollingSubscriptionPreviewSelector = createSelector(
  stateSelector,
  state => state.isPollingSubscriptionPreview,
);

export const subscriptionPreviewPollingIdSelector = createSelector(
  stateSelector,
  state => state.subscriptionPreviewPollingId,
);

export const subscriptionPreviewDataSelector = createSelector(
  stateSelector,
  state => state.subscriptionPreviewData,
);

export const hasBackCatalogSubscriptionSelector = createSelector(
  podcastSubscriptionsSelector,
  workflowIdsSelector,
  (subscriptions, ids) => {
    if (!subscriptions || !ids) return undefined;

    const frequencies = ids.reduce((acc, id) => {
      const subscription = subscriptions.get(String(id));
      subscription.get('subscriptionItems').forEach(item => {
        acc.push(item.get('videoFrequencyPref'));
      });

      return acc;
    }, []);

    return frequencies.indexOf('fromFirstEpisode') >= 0;
  },
);
