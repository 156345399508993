import TextOverlayFooterExternalControls from './components/TextOverlayFooterExternalControls';
import {
  createCaptionsTextOverlay,
  mapTextOverlayToCaptionsOverride,
  validateCaptionsConfigIntegrity,
} from './style-captions-utils';
import { TEXT_OVERLAY_MODAL_VERSION } from './style-export-utils';
import {
  createDynamicBaseOverlay,
  updateOverlayToV2,
  validateOverlayV2Integrity,
} from './style-import-utils';
import {
  convertLegacyOverlay,
  isLegacyOverlayConvertAble,
} from './style-legacy-parser';
import TextOverlayModal, {
  TextOverlayModalProps,
} from './TextOverlayModalContainer';
import {
  CaptionsConfig,
  ITextOverlayV2,
  OverlayScaler,
  TextBuilderStyles,
  TextEditorConfig,
  TextOverlayV2,
} from './types';

export {
  TEXT_OVERLAY_MODAL_VERSION,
  CaptionsConfig,
  OverlayScaler,
  ITextOverlayV2,
  TextBuilderStyles,
  TextEditorConfig,
  TextOverlayFooterExternalControls,
  TextOverlayModalProps,
  TextOverlayV2,
  convertLegacyOverlay,
  createDynamicBaseOverlay,
  createCaptionsTextOverlay,
  isLegacyOverlayConvertAble,
  mapTextOverlayToCaptionsOverride,
  updateOverlayToV2,
  validateCaptionsConfigIntegrity,
  validateOverlayV2Integrity,
};

export default TextOverlayModal;
