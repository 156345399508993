import cn from 'classnames';
import * as React from 'react';

import Modal from 'components/Modal';
import DeleteModalContents, {
  DeleteModalContentsProps,
} from './DeleteModalContents';
import { block } from './utils';

export interface DeleteModalProps extends DeleteModalContentsProps {
  className?: string;
  onExited?: () => void;
  show: boolean;
  title: string;
}

const DeleteModal: React.SFC<DeleteModalProps> = ({
  bodyClassName,
  bodyText,
  cancelButtonTitle,
  className,
  deleteButtonTitle,
  footer,
  onCancel,
  onDelete,
  onExited,
  show,
  title,
}) => (
  <Modal
    show={show}
    onExited={onExited}
    onHide={onCancel}
    backdrop="static"
    title={title}
    className={cn(className, block())}
  >
    <DeleteModalContents
      {...{
        bodyClassName,
        bodyText,
        cancelButtonTitle,
        deleteButtonTitle,
        footer,
        onCancel,
        onDelete,
      }}
    />
  </Modal>
);

export default DeleteModal;
