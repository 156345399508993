import * as React from 'react';

import BemCssTransition from 'components/BemCssTransition';
import useTemplateThumbnail from 'hooks/useTemplateThumbnail';
import { TrackType } from 'types';
import LayerOrderControls from './assets/LayerOrderControls/LayerOrderControls';
import { useCanvasSize } from './context/CanvasSizeContext';
import { useEditorState } from './context/VideoEditorStateContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import { DragAlignmentProvider } from './DragAlignmentContext';
import {
  BackgroundLayer,
  MediaLayer,
  MediaLayerProps,
  SoundwaveLayer,
  SoundwaveLayerProps,
  TextLayer,
  TextLayerProps,
  WatermarkLayer,
} from './layers';
import CaptionsLayer from './layers/CaptionsLayer';
import ProgressLayer from './layers/ProgressLayer';
import { canvasBlock as block, getLayerIdByAssetId } from './utils';

const LAYER_COMPONENTS: Record<
  Exclude<TrackType, 'audio'>,
  React.ComponentType<{ layerId: string }>
> = {
  media: MediaLayer,
  text: TextLayer,
  waveform: SoundwaveLayer,
};

interface EditorCanvasProps
  extends MediaLayerProps,
    TextLayerProps,
    SoundwaveLayerProps {}

/*
 * The interactable preview canvas
 */
const EditorCanvas: React.FC<EditorCanvasProps> = props => {
  const { canvas, container } = useCanvasSize();
  const state = useTemplateState();
  const { selectedAsset, features } = useEditorState();
  const { layers, template, isComplex, transcription } = state;
  const { isUCSEditorCompatible, isUCSEditorCompatibleLoading, templateType } =
    template || {};
  const {
    isGenerating: isGeneratingThumbnail,
    imageUrl: thumbnailUrl,
    progressOverlay,
  } = useTemplateThumbnail({
    template,
  });

  const layerRenderOrder = [...layers?.order].reverse();
  const selectedLayerId = getLayerIdByAssetId(state, selectedAsset.id);

  return !canvas?.height || !canvas?.width ? null : (
    <div
      className={block({ 'no-thumbnail': isGeneratingThumbnail })}
      style={{
        height: canvas?.height === container?.height ? '100%' : canvas?.height,
        width: canvas?.width === container?.width ? '100%' : canvas?.width,
      }}
    >
      {templateType === 'userGenerated' &&
      (!isUCSEditorCompatible || isUCSEditorCompatibleLoading) ? (
        (() => (
          <>
            {thumbnailUrl && (
              <img className={block('fallback-img')} src={thumbnailUrl} />
            )}
            {progressOverlay}
          </>
        ))()
      ) : (
        <DragAlignmentProvider>
          <BackgroundLayer />
          {layerRenderOrder.map(layerId => {
            const layer = layers.data[layerId];

            if (layer) {
              const { type } = layer;
              const Component = LAYER_COMPONENTS[type];

              return <Component layerId={layerId} key={layerId} {...props} />;
            }
            return null;
          })}
          <ProgressLayer />
          <WatermarkLayer />
          {features.captions !== 'hidden' && transcription.transcribe && (
            <CaptionsLayer />
          )}
        </DragAlignmentProvider>
      )}
      <BemCssTransition
        in={isComplex || (!!selectedAsset.id && !!selectedLayerId)}
        transitionClassName={block('layer-order-controls-container')}
        timeout={300}
        mountOnEnter
        unmountOnExit
      >
        <LayerOrderControls />
      </BemCssTransition>
    </div>
  );
};

export default EditorCanvas;
