import cn from 'classnames';
import * as React from 'react';

import BannerPage from '../BannerPage';
import PaidSubscriptionBody from './PaidSubscriptionBody';
import PaidSubscriptionHeader from './PaidSubscriptionHeader';
import { block } from './utils';

export interface PaidSubscriptionProps {
  children?: React.ReactNode;
  className?: string;
}

export default class PaidSubscription extends React.Component<
  PaidSubscriptionProps
> {
  public static Body = PaidSubscriptionBody;
  public static Header = PaidSubscriptionHeader;

  public render() {
    const { children, className } = this.props;

    return (
      <BannerPage
        backgroundClassName={block('background')}
        backgroundImageSrc="https://static.sparemin.com/static/webapp-assets/pro_subscription_bg_20190415.jpg"
        className={cn(block(), className)}
      >
        {children}
      </BannerPage>
    );
  }
}
