export enum FRAME_QUALITY_OPTION {
  LOW = 50,
  MEDIUM = 75,
  HIGH = 100,
}

export type FrameRate = 15 | 24 | 25 | 30;
export type FullEpisodeFrameRate = 24 | 25;
export type FrameQuality =
  | FRAME_QUALITY_OPTION.LOW
  | FRAME_QUALITY_OPTION.MEDIUM
  | FRAME_QUALITY_OPTION.HIGH;
export type FrameSize = 'hd' | 'fullHd';

export interface VideoPreferenceOption<TValue = unknown> {
  displayName: string;
  value: TValue;
}

export interface VideoExportPreferences {
  frameQuality: FrameQuality;
  frameRate: FrameRate;
  fullEpisodeFrameRate: FrameRate;
  frameSize: FrameSize;
  fullEpisodeFrameSize: FrameSize;
}

export type VideoFrameExportSettings = Omit<
  VideoExportPreferences,
  'fullEpisodeFrameRate' | 'fullEpisodeFrameSize'
>;
