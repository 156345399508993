import ExtendoError from 'extendo-error';
import * as ids from 'short-id';

import { delayActionBuilder } from 'redux/middleware/delay';
import { Dispatch } from 'redux/types';
import { wrapInPromise } from 'utils/promise';

export class PollingTimeoutError extends ExtendoError {}

type PollId = string | number;
type Cancel = () => void;

export type Task<T> = () => T | Promise<T>;
export type Continue<T> = (err: any, val: T, cancel: Cancel) => boolean;

interface IPollingOptions<T> {
  /**
   * the id used to schedule the task in redux.  this gets sent to the redux delay middleware and
   * can be passed to cancelReduxPoll to cancel the scheduled action
   */
  id?: PollId;
  /**
   * polling happens on a fixed interval determined by this option
   */
  intervalMillis?: number;
  /**
   * max number of times the task should be executed before the polling operation fails with
   * a PollingTimeoutError
   */
  maxAttempts?: number;
  /**
   * executed after each time the task is run.
   *
   * if the return value is `true`, the task will be scheduled for another execution
   *
   * if the return value is `false`, the task will not be scheduled and the polling operation will
   * be resolved or rejected according to the tasks outcome.
   *
   * if cancel() is called, the task will not be scheduled for any more executions and the
   * polling operation will neither be resolved or rejected
   */
  shouldContinue?: Continue<T>;

  retryAttempts?: number;
}

const DEFAULT_POLLING_INTERVAL_MILLIS = 1000;
const DEFAULT_POLLING_MAX_ATTEMPTS = 50;

export function reduxPoll<T>(
  dispatch: Dispatch,
  task: Task<T>,
  opts: IPollingOptions<T> = {},
) {
  const {
    id = ids.generate(),
    intervalMillis = DEFAULT_POLLING_INTERVAL_MILLIS,
    maxAttempts = DEFAULT_POLLING_MAX_ATTEMPTS,
    shouldContinue = err => !!err,
    retryAttempts = 0,
  } = opts;

  let nAttempts = 0;
  let nRetry = 0;
  let cancelled = false;

  return new Promise<T>(((resolve, reject, onCancel) => {
    const cancel = () => {
      cancelled = true;
      cancelReduxPoll(dispatch, id);
    };

    // onCancel only provided when bluebird is the global Promise implementation
    // and it has been configured to enable cancellation
    if (onCancel) {
      onCancel(cancel);
    }

    const processResult = (err: any, val?: T) => {
      nAttempts += 1;

      let cont: boolean;
      if (err && nRetry < retryAttempts) {
        nRetry += 1;
        cont = true;
      } else {
        nRetry = 0;
        try {
          cont = shouldContinue(err, val, cancel);
        } catch (e) {
          reject(e);
          return;
        }
      }

      if (cancelled) {
        return;
      }

      if (!cont) {
        !err ? resolve(val) : reject(err);
      } else if (nAttempts < maxAttempts) {
        dispatch(
          delayActionBuilder(poll as any)
            .timeoutMillis(intervalMillis)
            .id(id)
            .build(),
        );
      } else {
        reject(
          new PollingTimeoutError(`${maxAttempts} polling attempts exceeded`),
        );
      }
    };

    const poll = () => {
      wrapInPromise(task)
        .then(val => processResult(undefined, val))
        .catch(err => processResult(err));
    };

    poll();
  }) as any);
}

export function cancelReduxPoll(dispatch: Dispatch, id: PollId) {
  dispatch(
    delayActionBuilder()
      .id(id)
      .cancel()
      .build(),
  );
}

export default reduxPoll;
export { IPollingOptions as ReduxPollingOptions };
