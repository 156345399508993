import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import DeleteTemplateModalContents from './DeleteTemplateModalContents';

const DeleteTemplateModal: React.FC = () => {
  return (
    <ConnectedModal name="DeleteTemplate" title="Delete template">
      <DeleteTemplateModalContents />
    </ConnectedModal>
  );
};

export default DeleteTemplateModal;
