import * as Immutable from 'immutable';

import bem from 'utils/bem';
import { recordFromJs } from 'utils/immutable';
import {
  IEditorState,
  IEmbedPlayerState,
  IPanesState,
  ISlideEditorData,
  IUiState,
  MutableUiState,
} from './types';

export const block = bem('video-editor');

const slideEditorDataFactory = Immutable.Record<ISlideEditorData>({
  imageId: undefined,
});

const editorStateFactory = Immutable.Record<IEditorState>({
  data: slideEditorDataFactory(),
  type: undefined,
});

const embedPlayerFactory = Immutable.Record<IEmbedPlayerState>({
  ready: undefined,
});

const panesStateFactory = Immutable.Record<IPanesState>({
  draggingResizer: undefined,
});

const uiStateFactory = Immutable.Record<IUiState>({
  editor: editorStateFactory(),
  embedPlayer: embedPlayerFactory(),
  isModalOpen: undefined,
  isPlaying: undefined,
  panes: panesStateFactory(),
  positionSec: undefined,
  seekToSec: undefined,
  stopSec: null,
  syncTranscriptWithAudio: undefined,
});

export function uiStateFromJs(
  obj: MutableUiState,
): Immutable.RecordOf<IUiState> {
  return recordFromJs(obj, uiStateFactory);
}
