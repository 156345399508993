import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import BackCatalogUpsellModalContents from './BackCatalogUpsellModalContents';

export interface BackCatalogUpsellModalProps {}

const BackCatalogUpsellModal: React.FC<BackCatalogUpsellModalProps> = () => (
  <ConnectedModal name="BackCatalogUpsell" title="Back catalog">
    <BackCatalogUpsellModalContents />
  </ConnectedModal>
);

export default BackCatalogUpsellModal;
