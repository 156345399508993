import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import FileSizeUpsellModalContents from './FileSizeUpsellModalContents';

import { block } from './utils';

export interface FileSizeUpsellModalProps {}

const FileSizeUpsellModal: React.FC<FileSizeUpsellModalProps> = () => {
  return (
    <ConnectedModal
      name="FileSizeUpsell"
      title="upload limit"
      className={block()}
    >
      <FileSizeUpsellModalContents />
    </ConnectedModal>
  );
};

export default FileSizeUpsellModal;
