import Immutable from 'immutable';
import { REHYDRATE } from 'redux-persist-immutable/constants';

const defaultState = Immutable.Map();

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case REHYDRATE: {
      const rehydratedState = action.payload.app || Immutable.Map();
      return rehydratedState.set('rehydrated', true);
    }

    default:
      return state;
  }
}
