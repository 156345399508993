import cn from 'classnames';
import * as React from 'react';

import { block } from '../../../utils';
import DeleteTrackButton from './DeleteTrackButton';
import GlobalStyleButton from './GlobalStyleButton';

export interface TrackControlsProps {
  children?: React.ReactNode;
  className?: string;
}

export default class TrackControls extends React.Component<TrackControlsProps> {
  public static Delete = DeleteTrackButton;
  public static Style = GlobalStyleButton;

  public render() {
    const { children, className } = this.props;

    return (
      <div className={cn(block('track-controls'), className)}>{children}</div>
    );
  }
}
