import classNames from 'classnames';
import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import _ from 'underscore';

import { ArrowRight } from 'components/icons';
import HistoricalColorPicker from 'containers/HistoricalColorPicker';
import { ColorPickerProps } from './ColorPicker';

interface IProps {
  arrowClassName?: string;
  className?: string;
  colorClassName?: string;
  swatchClassName?: string;
  disabled?: boolean;
  fromColor?: string;
  fromPopoverPositioner?: ColorPickerProps['popoverPositioner'];
  fromPopoverStyle?: React.CSSProperties;
  toColor?: string;
  toPopoverPositioner?: ColorPickerProps['popoverPositioner'];
  toPopoverStyle?: React.CSSProperties;
  onFromColorChange?: (color: string) => void;
  onToColorChange?: (color: string) => void;
}

interface IUiState {
  fromColor: string;
  toColor: string;
}

interface IState {
  ui: RecordOf<IUiState>;
}

const uiFactory = Record<IUiState>({
  fromColor: undefined,
  toColor: undefined,
});

export default class ColorChangeInput extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    disabled: false,
    fromColor: 'gray',
    onFromColorChange: _.noop,
    onToColorChange: _.noop,
    toColor: 'white',
  };

  constructor(props: IProps) {
    super(props);

    const { fromColor, toColor } = props;

    this.state = {
      ui: uiFactory({
        fromColor,
        toColor,
      }),
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const { fromColor, toColor } = nextProps;

    this.setState(({ ui }) => ({
      ui: ui.withMutations(u => {
        fromColor && u.set('fromColor', fromColor);
        toColor && u.set('toColor', toColor);
        return u;
      }),
    }));
  }

  private handleFromColorChange = (color: string) => {
    const { onFromColorChange } = this.props;
    this.setState(({ ui }) => ({
      ui: ui.set('fromColor', color),
    }));
    onFromColorChange(color);
  };

  private handleToColorChange = (color: string) => {
    const { onToColorChange } = this.props;
    this.setState(({ ui }) => ({
      ui: ui.set('toColor', color),
    }));
    onToColorChange(color);
  };

  public render() {
    const {
      arrowClassName,
      colorClassName,
      className,
      disabled,
      fromPopoverPositioner,
      fromPopoverStyle,
      swatchClassName,
      toPopoverPositioner,
      toPopoverStyle,
    } = this.props;
    const { ui } = this.state;

    const containerClassName = classNames(
      'color-change',
      'color-change--std',
      { 'color-change--disabled': disabled },
      className,
    );

    const colorInputClassName = classNames(
      'color-change__color',
      colorClassName,
    );

    return (
      <div className={containerClassName}>
        <HistoricalColorPicker
          color={ui.get('fromColor')}
          componentClass="div"
          disabled={disabled}
          className={colorInputClassName}
          onChange={this.handleFromColorChange}
          popoverPositioner={fromPopoverPositioner}
          popoverStyle={fromPopoverStyle}
          swatchClassName={swatchClassName}
        />
        <ArrowRight
          className={classNames('color-change__arrow', arrowClassName)}
          height={12}
        />
        <HistoricalColorPicker
          color={ui.get('toColor')}
          componentClass="div"
          disabled={disabled}
          className={colorInputClassName}
          onChange={this.handleToColorChange}
          popoverPositioner={toPopoverPositioner}
          popoverStyle={toPopoverStyle}
          swatchClassName={swatchClassName}
        />
      </div>
    );
  }
}

export { IProps as ColorChangeInputProps };
