import * as React from 'react';

import { ChildView } from './types';

const { useContext } = React;

interface OptionTilesContextType {
  getTile: (id: ChildView) => HTMLElement;
}

interface OptionTilesProviderProps extends OptionTilesContextType {
  children?: React.ReactNode;
}

const OptionTilesContext = React.createContext<OptionTilesContextType>({
  getTile: () => null,
});

export function useOptionTiles() {
  return useContext(OptionTilesContext);
}

export const OptionTilesProvider: React.FC<OptionTilesProviderProps> = ({
  children,
  getTile,
}) => (
  <OptionTilesContext.Provider value={{ getTile }}>
    {children}
  </OptionTilesContext.Provider>
);
