import { PlatformVideoType } from 'blocks/AudiogramDestinations/types';
import { DestinationSteps } from 'blocks/AutomationOptions/types';
import {
  FacebookPostType,
  InstagramMediaType,
} from 'redux/middleware/api/podcast-service';

import { MixpanelWizardStep } from 'redux/modules/mixpanel/types';

import { AutogramWizardStep, AutomationWorkflowWizardState } from './types';

export const STEPS: AutogramWizardStep[] = [
  'podcast',
  'language',
  'destination',
  'episodes',
  'template',
  'customize',
  'preview',
  'completed',
];

export enum AutogramWizardStepIndex {
  PODCAST = 1,
  LANGUAGE = 2,
  DURATION = 3,
  STARTING_POINT = 4,
  TEMPLATE = 5,
}

export const MIXPANEL_WIZARD_STEP_MAP: Record<
  AutogramWizardStep,
  MixpanelWizardStep
> = {
  podcast: 'Select Audio',
  language: 'Language',
  destination: 'Destination',
  episodes: 'EpisodeSelection',
  template: 'Choose Template',
  customize: 'Customize',
  preview: 'Preview',
  completed: 'Completed',
};

export const DEFAULT_LANGUAGE = 'en-US';

export const DEFAULT_WIZARD_STATE: AutomationWorkflowWizardState = {
  aspectRatioName: 'square',
  creatingWorkflowPreview: false,
  destinationOptions: {
    stepView: DestinationSteps.CHOOSE_DESTINATION,
    automationOption: undefined,
    autopostVideoToYouTube: false,
    clipSelectionMethod: undefined,
    platform: undefined,
    videoType: undefined,
    platformVideoType: undefined,
    clipDuration: undefined,
    aspectRatioName: undefined,
    isDestinationOptionsComplete: undefined,
  },
  creatingWorkflow: false,
  podcastId: undefined,
  language: 'en-US',
  step: undefined,
  subscriptionPreviewId: undefined,
  subscriptionOptions: {
    autopost: {
      // although UI shows enabled by default, this is set as consequence of selecting full episode and not by default
      platform: undefined,
      madeForKids: false,
      visibility: 'private',
      categoryId: undefined,
    },
    durationSeconds: undefined,
    frequency: undefined,
    isCaptionEnabled: false,
    videoType: undefined,
    clipSelectionMethod: undefined,
  },
  templateId: undefined,
};

export const INSTAGRAM_MEDIA_TYPE_MAP: Record<
  Extract<PlatformVideoType, 'post' | 'reel' | 'story'>,
  InstagramMediaType
> = {
  // Instagram API doesn’t support `posts` as `reels` and `posts` are treated the same.
  // `reels` should be the assigned value every time `post` is selected.
  post: 'reels',
  reel: 'reels',
  story: 'stories',
};

export const FACEBOOK_POST_TYPE_MAP: Record<
  Extract<PlatformVideoType, 'post' | 'reel' | 'story'>,
  FacebookPostType
> = {
  post: 'facebookDefault',
  reel: 'facebookReel',
  story: 'facebookStory',
};
