import { List, Map } from 'immutable';
import { isUndefined, negate } from 'underscore';

import { AudioTransition } from 'utils/audio';
import { getValue } from 'utils/collections';
import { millisToSec } from 'utils/time';

const isDefined = negate(isUndefined);

function fadeDurationToTransition(durationMillis: number = 0): AudioTransition {
  const durationSec = millisToSec(durationMillis);
  const effect = durationSec === 0 ? 'cut' : 'fade';
  return { durationSec, effect };
}

function isOverlapping(oneStart, oneEnd, otherStart, otherEnd) {
  return oneStart < otherEnd && oneEnd > otherStart;
}

function createVideoAssetAudioClips(videos) {
  if (!videos) return undefined;

  return videos.valueSeq().reduce((acc, video) => {
    // if there's no audio, skip this asset. no audio levels to set
    if (!video.audioSrc || video.assetType === 'gif') return acc;

    const playFromMillis = video.playFromMillis || 0;
    const videoDurationMillis = video.endMillis - video.startMillis;
    const playToMillis = playFromMillis + videoDurationMillis;
    const fadeInDuration = video.audioFadeInDurationMillis;
    const fadeOutDuration = video.audioFadeOutDurationMillis;

    const audioLevels = isUndefined(video.audioLevel)
      ? undefined
      : [
          {
            endSec: millisToSec(videoDurationMillis),
            startSec: 0,
            transitionIn: fadeDurationToTransition(fadeInDuration),
            transitionOut: fadeDurationToTransition(fadeOutDuration),
            value: video.audioLevel,
          },
        ];

    acc.push({
      audioLevels,
      fromSeconds: millisToSec(playFromMillis),
      id: video.id,
      source: video.audioSrc,
      startOffsetSeconds: millisToSec(video.startMillis),
      toSeconds: millisToSec(playToMillis),
    });

    return acc;
  }, []);
}

function createAudioAssetAudioLevel(
  video,
  audioStartMillis,
  audioDurationMillis,
  defaultTransitionIn,
  defaultTransitionOut,
) {
  /*
   * if this value is 0, that means the video starts before the audio.  the
   * audio's fade should take precedence here
   */
  const startMillis = Math.max(0, video.startMillis - audioStartMillis);

  /*
   * if this value is audioDurationMillis, that means the video ends after the
   * audio. the audio's fade should take precedence here.
   */
  const endMillis = Math.min(
    audioDurationMillis,
    video.endMillis - audioStartMillis,
  );

  return {
    endSec: millisToSec(endMillis),
    startSec: millisToSec(startMillis),
    /*
     * re: the ternaries in the transition fields - see comments above for
     * startMillis and endMillis
     */
    transitionIn:
      startMillis === 0
        ? defaultTransitionIn
        : fadeDurationToTransition(video.audioFadeInDurationMillis),
    transitionOut:
      endMillis === audioDurationMillis
        ? defaultTransitionOut
        : fadeDurationToTransition(video.audioFadeOutDurationMillis),
    value: video.mainAudioLevel,
  };
}

function createAudioAssetAudioClips(audio, videos: any = {}) {
  if (!audio) return undefined;

  const audioStartMillis = audio.get('startMillis');
  const audioEndMillis = audio.get('endMillis');
  const playFromMillis = audio.get('playFromMillis', 0);
  const audioDurationMillis = audioEndMillis - audioStartMillis;
  const playToMillis = playFromMillis + audioDurationMillis;

  const audioTransitionIn = fadeDurationToTransition(
    audio.getIn(['transition', 'in', 'durationMilli'], 0),
  );
  const audioTransitionOut = fadeDurationToTransition(
    audio.getIn(['transition', 'out', 'durationMilli'], 0),
  );

  /*
   * set the audio's fade in/out as the base transition for the asset.  nothing
   * can change the duration of this fade, but the level can be modified by
   * an overlapping video.  if there are no video assets, this will provide
   * fade in/out for the audio asset
   */
  const initialAudioLevels = [
    {
      endSec: millisToSec(audioDurationMillis),
      startSec: 0,
      transitionIn: audioTransitionIn,
      transitionOut: audioTransitionOut,
      value: 1,
    },
  ];

  const { order: videoIds = List(), data: videosById = Map() } = videos;

  const audioLevels = videoIds.reduce((acc, id) => {
    const video = videosById.get(id);

    if (!video) return acc;

    // only apply the audio level from the video asset if it is defined and
    // overlaps the audio asset
    if (
      !isUndefined(video.mainAudioLevel) &&
      isOverlapping(
        video.startMillis,
        video.endMillis,
        audioStartMillis,
        audioEndMillis,
      )
    ) {
      const audioLevel = createAudioAssetAudioLevel(
        video,
        audioStartMillis,
        audioDurationMillis,
        audioTransitionIn,
        audioTransitionOut,
      );
      acc.push(audioLevel);
    }
    return acc;
  }, initialAudioLevels);

  return {
    audioLevels,
    fromSeconds: millisToSec(playFromMillis),
    id: audio.get('id').toString(),
    source: audio.get('src'),
    startOffsetSeconds: millisToSec(audioStartMillis),
    toSeconds: millisToSec(playToMillis),
  };
}

export function createAudioClips(audio = [], videos) {
  const videoClips = createVideoAssetAudioClips(videos.data);
  const mainAudioClip = createAudioAssetAudioClips(
    audio.find(a => getValue(a, ['audioTrackType']) === 'main'),
    videos,
  );
  const bgAudioClips = audio
    .filter(a => getValue(a, ['audioTrackType']) === 'background')
    .map(a => createAudioAssetAudioClips(a));

  return [mainAudioClip, ...bgAudioClips, ...videoClips].filter(isDefined);
}

export function hasTransition(clips?: any) {
  if (!clips) return false;

  const clipWithTransition = clips.find(clip => {
    const audioLevelWithFade = clip.audioLevels.find(audioLevel => {
      const inEffect = getValue(audioLevel, ['transitionIn', 'effect']);
      const outEffect = getValue(audioLevel, ['transitionOut', 'effect']);
      return inEffect === 'fade' || outEffect === 'fade';
    });
    return audioLevelWithFade !== undefined;
  });

  return clipWithTransition !== undefined;
}
