import React from 'react';
import LinkButton from 'components/LinkButton';
import { block } from './utils';

interface MediaCardContentButtonProps {
  buttonContent?: React.ReactNode;
}

const MediaCardContentButton: React.FC<MediaCardContentButtonProps> = ({
  buttonContent,
}) => {
  return (
    <LinkButton theme="rainbow" className={block('button-content')}>
      {buttonContent}
    </LinkButton>
  );
};

export default MediaCardContentButton;
