import * as React from 'react';

import { SelectField, SelectFieldProps } from 'components/Form';
import { getWaveOptionDisplayNameByValue } from 'redux/modules/display-pref/utils';
import { Omit, SoundwaveOption, SoundwaveType } from 'types';
import SpinnerInput from './SpinnerInput';

interface IProps
  extends Omit<
    SelectFieldProps,
    'label' | 'onChange' | 'value' | 'horizontal' | 'variant'
  > {
  value?: SoundwaveType;
  onChange?: (value: SoundwaveType) => void;
  generatingWaveform?: boolean;
  defaultValue?: string;
  locked?: boolean;
  options?: SoundwaveOption[];
}

const WaveTypeField: React.SFC<IProps> = ({
  disabled,
  generatingWaveform,
  defaultValue,
  locked,
  options,
  ...props
}) => {
  const commonProps = {
    disabled: disabled || locked,
  };

  const renderSelect = () => (
    <SelectField
      {...props}
      {...commonProps}
      variant={locked ? 'locked' : 'default'}
      defaultValueText={getWaveOptionDisplayNameByValue(options, defaultValue)}
    >
      {options.map((option: SoundwaveOption) => (
        <option key={option.value} value={option.value}>
          {option.displayName}
        </option>
      ))}
    </SelectField>
  );

  const renderSpinner = () => (
    <SpinnerInput {...commonProps} message="Creating Waveform" />
  );

  return !generatingWaveform ? renderSelect() : renderSpinner();
};

WaveTypeField.defaultProps = {
  defaultValue: undefined,
  locked: false,
  options: [],
  value: 'none',
};

export default WaveTypeField;
export { IProps as WaveTypeFieldProps };
