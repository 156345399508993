import React from 'react';
import { useDispatch } from 'react-redux';
import EpisodeListRow from 'blocks/AutomationCMS/components/EpisodeListRow';
import CardCta, { VideoCardCta } from 'components/CardCta';
import FontAwesome from 'components/FontAwesome';
import { H3 } from 'components/Heading';
import Modal from 'components/Modal';
import { onCreateVideoFromEpisodeRow } from 'redux/modules/mixpanel/actions';
import { hideModal } from 'redux/modules/modal';
import usePodcastFeedEpisode from '../../../../hooks/usePodcastFeedEpisode';
import { block } from './utils';

interface Props {
  episodeId?: string;
}

function EpisodeCreationMethodSelectModalContent(props: Props) {
  const { episodeId } = props;
  const dispatch = useDispatch();
  const { title, thumbnailImageUrl } = usePodcastFeedEpisode(episodeId);

  return (
    <Modal.Body className={block('body')}>
      <EpisodeListRow.Container className={block('episode')} collapsible>
        <EpisodeListRow.Artwork>
          <img src={thumbnailImageUrl} />
        </EpisodeListRow.Artwork>
        <EpisodeListRow.Description primary={<H3>{title}</H3>} />
      </EpisodeListRow.Container>
      <VideoCardCta
        iconStyle={{ height: 40 }}
        label="Create a video manually"
        onClick={() => {
          dispatch(onCreateVideoFromEpisodeRow('manual'));
          dispatch(hideModal('manual'));
        }}
      />
      <CardCta
        variant="rainbow"
        icon={<FontAwesome icon="magic" style={{ height: 40 }} />}
        label={
          <>
            Let us automatically
            <br />
            create a video for you
          </>
        }
        onClick={() => {
          dispatch(onCreateVideoFromEpisodeRow('automatic'));
          dispatch(hideModal('auto'));
        }}
      />
    </Modal.Body>
  );
}

export default EpisodeCreationMethodSelectModalContent;
