import React from 'react';
import { useDispatch } from 'react-redux';
import SocialProofSection from 'blocks/Pricing/components/SocialProofSection';
import { PurchaseableTier } from 'redux/middleware/api/plan-service';
import { pushModal } from 'redux/modules/modal/actions';
import { SubscriptionPeriod } from '../../types';
import PlanSelector from '../PlanSelector';
import EnterpriseBanner from './EnterpriseBanner';
import FreeSubscriptionFooter from './FreeSubscriptionFooter';

const { useCallback } = React;

export interface BaseFreeSubscriptionProps {
  className?: string;
  freePlanCta?: React.ReactElement;
  message?: string;
  subMessage?: React.ReactNode;
}

const BaseFreeSubscription: React.FC<BaseFreeSubscriptionProps> = ({
  className,
  freePlanCta,
  message,
  subMessage,
}) => {
  const dispatch = useDispatch();

  const handleUpgradeClick = useCallback(
    (tier: PurchaseableTier, subPeriod: SubscriptionPeriod) => {
      dispatch(
        pushModal({
          name: 'AddBillingCycle',
          params: {
            tier,
            defaultSubscriptionPeriod: subPeriod,
          },
        }),
      );
    },
    [dispatch],
  );

  return (
    <PlanSelector {...{ className, message, subMessage }}>
      <PlanSelector.PlanList>
        <PlanSelector.FreePlan cta={freePlanCta} />
        <PlanSelector.BasicPlan
          location="MyPlanPage"
          onUpgradeClick={handleUpgradeClick}
        />
        <PlanSelector.ProPlan
          location="MyPlanPage"
          onUpgradeClick={handleUpgradeClick}
        />
      </PlanSelector.PlanList>
      <SocialProofSection />
      <EnterpriseBanner />
      <FreeSubscriptionFooter />
    </PlanSelector>
  );
};

export default BaseFreeSubscription;
