import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onClickFullEpisodeBackCatalogGatedCard } from 'redux/modules/mixpanel';
import { pushModal } from 'redux/modules/modal';
import { isBasicOrFreeSelector } from 'redux/modules/pricing';
import SourceCardOptions from './SourceCardOptions';
import { SourceStepType } from './types';

interface SourceStepProps {
  onClick: (selected: SourceStepType) => void;
}

const SourceStep: React.FC<SourceStepProps> = ({ onClick }) => {
  const dispatch = useDispatch();
  const isFreeTier = useSelector(isBasicOrFreeSelector);

  const handleUpgradeToUnlockClick = useCallback((): void => {
    dispatch(onClickFullEpisodeBackCatalogGatedCard());
    dispatch(
      pushModal({
        name: 'BackCatalogUpsell',
      }),
    );
  }, [dispatch]);

  const handleBackCatalogClick = useCallback((): void => {
    onClick('BackCatalogUpsell');
  }, [onClick]);

  const handleManualCreationClick = useCallback((): void => {
    onClick('ManualCreation');
  }, [onClick]);

  return (
    <SourceCardOptions
      isFreeTier={isFreeTier}
      onBackCatalogClick={
        isFreeTier ? handleUpgradeToUnlockClick : handleBackCatalogClick
      }
      onManualCreationClick={handleManualCreationClick}
    />
  );
};

export default SourceStep;
