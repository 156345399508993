import { Button, Link, Spacer, Text } from '@sparemin/blockhead';
import React from 'react';

import AutomationPostModalAdditionalDetails from 'components/AutomationPostModalAdditionalDetails';
import AutomationPostModalHeader from 'components/AutomationPostModalHeader';
import InputFieldAsButton from 'components/Form/InputFieldAsButton';
import { EditThin } from 'components/icons';
import ModalContentWithMedia from 'components/Modal/ModalContentWithMedia';
import SocialAccountInfo from 'components/SocialAccountInfo';
import { Step } from 'components/SteppedModal';
import { TikTokUserAllowance } from 'containers/TikTokAutoPostModal/types';
import { TikTokPrivacyLevelOptionName } from 'redux/middleware/api/third-party-authentication-service/types';
import TikTokVisibilitySelector from '../../TikTokVisibilitySelector';
import { block } from '../../utils';
import { UsePostComposerBaseConfig } from './types';
import UserAllowance from './UserAllowance';

export interface UsePostComposerStepConfig extends UsePostComposerBaseConfig {
  captions: string;
  privacyLevel: TikTokPrivacyLevelOptionName;
  userAllowance: TikTokUserAllowance[];
  brandedContentSelected: boolean;
  allowSubmit: boolean;
  onCaptionsClick?: () => void;
  onPrivacyLevelChange?: (privacyLevel: TikTokPrivacyLevelOptionName) => void;
  onUserAllowanceChange?: (userAllowance: TikTokUserAllowance[]) => void;
  onAdditionalDetailsClick?: () => void;
  onHideModal?: () => void;
  onSubmit?: () => void;
}

export default function usePostComposerStep({
  username,
  avatarSrc,
  captions,
  privacyLevel,
  userAllowance,
  brandedContentSelected,
  allowSubmit,
  onSwitchAccountClick,
  onCaptionsClick,
  onPrivacyLevelChange,
  onUserAllowanceChange,
  onAdditionalDetailsClick,
  onHideModal,
  onSubmit,
}: UsePostComposerStepConfig): Step {
  return {
    id: 'composer',
    hideFooter: true,
    replaceBody: true,
    component: (
      <>
        <ModalContentWithMedia
          onClose={onHideModal}
          className="composer-step-content"
        >
          <ModalContentWithMedia.ModalContentWithMediaColumn>
            <img
              src="https://static.sparemin.com/static/webapp-assets/auto-posting-detail/auto-posting-detail-tik-tok-image-a-202403191010.png"
              className={block('composer-image-left')}
            />
          </ModalContentWithMedia.ModalContentWithMediaColumn>

          <ModalContentWithMedia.ModalContentWithMediaColumn className="composer-content-column">
            <Spacer orientation="vertical" align="flex-start" space="20px">
              <SocialAccountInfo
                {...{
                  avatarSrc,
                  onSwitchAccountClick,
                  username,
                }}
              />

              <AutomationPostModalHeader
                title="Let’s set up your video details"
                description="We'll post these details with each video. You can pull information from your RSS feed, such as episode titles, to make each post unique."
              />

              <InputFieldAsButton
                label="Example of your captions"
                content={captions}
                tooltipContent="Edit format of captions"
                icon={EditThin}
                onClick={onCaptionsClick}
                className={block('composer-single-line')}
              />

              <TikTokVisibilitySelector
                brandedContentSelected={brandedContentSelected}
                value={privacyLevel}
                onChange={onPrivacyLevelChange}
              />

              <UserAllowance {...{ userAllowance, onUserAllowanceChange }} />

              <AutomationPostModalAdditionalDetails
                description="Disclose promotional goods or services in your videos."
                onClick={onAdditionalDetailsClick}
              />
            </Spacer>

            <Spacer
              orientation="vertical"
              align="center"
              space="18px"
              className={block('fluid-content')}
            >
              <Button
                variant="solid"
                fluid
                className={block('composer-step-submit-button')}
                onPress={onSubmit}
                isDisabled={!allowSubmit}
              >
                confirm auto-posting details
              </Button>

              <Text variant="legal" className={block('legal-text')}>
                By posting, you agree to TikTok's
                {brandedContentSelected ? (
                  <>
                    {' '}
                    <Link
                      target="_blank"
                      href="https://www.tiktok.com/legal/page/global/bc-policy/en"
                    >
                      Branded Content Policy
                    </Link>{' '}
                    and{' '}
                  </>
                ) : (
                  ' '
                )}
                <Link
                  target="_blank"
                  href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                >
                  Music Usage Confirmation
                </Link>
              </Text>
            </Spacer>
          </ModalContentWithMedia.ModalContentWithMediaColumn>
        </ModalContentWithMedia>
      </>
    ),
  };
}
