import React from 'react';

import LegacyAddTextModal from 'containers/LegacyAddTextModal';

import AddTextModal from './AddTextModal';
import useAddTextModalSwitcher from './useAddTextModalSwitcher';

const AddTextModalContainer: React.FunctionComponent = () => {
  const {
    activeModal,
    onExited,
    onHide,
    onToggleLegacyMode,
    transitionOverlay,
  } = useAddTextModalSwitcher();

  return (
    <>
      <LegacyAddTextModal
        initialOverlay={transitionOverlay}
        onAddAssetHide={onHide}
        onExited={onExited}
        onSwitchLegacyMode={onToggleLegacyMode}
        show={activeModal === 'legacy'}
      />
      <AddTextModal
        initialOverlay={transitionOverlay}
        onExited={onExited}
        onHide={onHide}
        onSwitchToLegacy={onToggleLegacyMode}
        show={activeModal === 'current'}
      />
    </>
  );
};

export default AddTextModalContainer;
