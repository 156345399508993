import classNames from 'classnames';
import * as Immutable from 'immutable';
import * as React from 'react';
import _ from 'underscore';

import { Split } from '../../utils/constants';
import { prefix } from '../../utils/ui';
import ResizeHandle from './ResizeHandle';
import { Size } from './types';
import { block } from './utils';

const resizerSizePx = 1;

interface IDragData {
  deltaX: number;
  deltaY: number;
  lastX: number;
  lastY: number;
  node: any;
  x: number;
  y: number;
}

interface IProps {
  className?: string;

  /**
   * any numeric css value
   */
  defaultSize?: Size;
  minSize?: Size;

  /**
   * callbacks for resizer drag events
   */
  onResizerDrag?: (dragData: IDragData, e: MouseEvent) => void;
  onResizerDragStart?: (dragData: IDragData, e: MouseEvent) => void;
  onResizerDragStop?: (dragData: IDragData, e: MouseEvent) => void;

  /**
   * callback to render the first pane.
   * size => element
   */
  renderFirstPane?: (size: Size) => any;

  /**
   * callback to render the second pane
   * size => element
   */
  renderSecondPane?: (size: Size) => any;

  /**
   * control the component and set the size
   */
  size?: Size;

  /**
   * horizontal splits to top and bottom
   * veritcal splits to left and right
   */
  split?: Split;
}

interface IState {
  data: Immutable.Map<string, any>;
}

/**
 * Similar to react-split-pane.
 *
 * react-split-pane works ok but something about the navbars at the top of the page resulted in a
 * bad resize/drag experience.
 *
 * As the user drags the resizer, the "first pane" (left for split "vertical", top for split
 * "horizontal") changes size by means of inline height and width styles.  The second pane fills
 * up the remaining space using flex-grow.
 *
 * The component uses relative positioning to layout the 3 elements (first pane, resizer, second
 * pane).  This makes everything stay in place when the browser is resized, but makes dragging
 * the resize handle a little odd.  as the user drags, the first pane would resize, and in order
 * for the resizer to stay in the correct location, it should stay relatively positioned with no
 * translate. Unfortunately this is hard to do when using react-draggable as the resizer because
 * you cannot fix the draggable at a position if the user is dragging.  Trying to explicitly
 * set the draggable position results in the draggable actually moving, but then snapping back
 * to the specified location on drag stop.
 *
 * The workaround we're using here so that we can handle browser resize and keep our container
 * resizer in the correct location is:
 *  - when not dragging, set position relative
 *  - on drag start, switch to absolute positioning
 *  - on drag stop, set back to relative
 *
 * We have to keep the resizer position in state because we have to set translate to (0, 0) when
 * the resizer is not being dragged and is positined relatively.
 *
 */
export default class SplitPane extends React.Component<IProps, IState> {
  private firstPane: HTMLElement;

  public static defaultProps = {
    className: '',
    defaultSize: '40%',
    minSize: '50%',
    onResizerDrag: _.noop,
    onResizerDragStart: _.noop,
    onResizerDragStop: _.noop,
    renderFirstPane: _.noop,
    renderSecondPane: _.noop,
    size: '',
    split: Split.HORIZONTAL,
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      data: Immutable.Map({
        dragging: false,
        resizer: Immutable.Map({
          x: 0,
          y: 0,
        }),
        size: this.getDefaultSize(),
      }),
    };
  }

  public componentDidMount() {
    // record the coords we'll need to render the resizer with position absolute
    const resizerPos = this.getResizerPosition();
    const resizerDimension = this.isHorizontal() ? 'y' : 'x';

    this.setState(({ data }) => ({
      data: data.setIn(['resizer', resizerDimension], resizerPos),
    }));
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const { size: nextSize } = nextProps;
    const { size } = this.props;

    if (nextSize !== size) {
      this.setState(({ data }) => ({
        data: data.set('size', nextSize),
      }));
    }
  }

  private handleResizerDrag = (e: MouseEvent, dragData: IDragData) => {
    const { onResizerDrag } = this.props;
    const { x, y, deltaX, deltaY } = dragData;
    const delta = this.isHorizontal() ? deltaY : deltaX;

    this.setState(({ data }) => ({
      data: data.withMutations(d => {
        const dimension = this.isHorizontal() ? 'y' : 'x';
        d.update(
          'size',
          s => (_.isNumber(s) ? s : this.getFirstPaneSize()) + delta,
        );
        d.setIn(['resizer', dimension], this.isHorizontal() ? y : x);
      }),
    }));

    onResizerDrag(dragData, e);
  };

  private handleResizerDragStart = (e: MouseEvent, dragData: IDragData) => {
    const { onResizerDragStart } = this.props;

    const resizerPos = this.getResizerPosition();
    const resizerDimension = this.isHorizontal() ? 'y' : 'x';
    this.setState(({ data }) => ({
      data: data.withMutations(d =>
        d
          .set('dragging', true)
          .setIn(['resizer', resizerDimension], resizerPos),
      ),
    }));

    onResizerDragStart(dragData, e);
  };

  private handleResizerDragStop = (e: MouseEvent, dragData: IDragData) => {
    const { onResizerDragStop } = this.props;

    this.setState(({ data }) => ({
      data: data.set('dragging', false),
    }));

    onResizerDragStop(dragData, e);
  };

  private getDefaultSize(props: IProps = this.props) {
    const { defaultSize, minSize } = props;

    return !_.isUndefined(defaultSize) ? defaultSize : minSize;
  }

  private getFirstPaneSize() {
    const rect = this.firstPane.getBoundingClientRect();

    return this.isHorizontal() ? rect.height : rect.width;
  }

  /**
   * calculates the position of the resizer in relation to its parent so that when absolute
   * position is applied to the resizer, we know how to render it in the same location as
   * position relative
   */
  public getResizerPosition() {
    const parent = this.firstPane.parentNode as HTMLElement;
    const parentRect = parent.getBoundingClientRect();
    const paneRect = this.firstPane.getBoundingClientRect();
    const bottom = paneRect.bottom - parentRect.top;
    const right = paneRect.right - parentRect.left;

    return this.isHorizontal() ? bottom - resizerSizePx : right - resizerSizePx;
  }

  private isHorizontal(split?: Split) {
    const { split: splitProp } = this.props;
    return (split ?? splitProp) === Split.HORIZONTAL;
  }

  private getContainerClassName = () => {
    const { className, split } = this.props;

    return classNames({
      [block()]: true,
      [block({ horizontal: true })]: split === Split.HORIZONTAL,
      [block({ vertical: true })]: split === Split.VERTICAL,
      [className]: !!className,
    });
  };

  private renderFirstPane() {
    const { renderFirstPane } = this.props;
    const { data } = this.state;
    const firstPaneSizeStyle = this.isHorizontal()
      ? { height: data.get('size') }
      : { width: data.get('size') };
    const firstPaneStyle = prefix({ ...firstPaneSizeStyle });
    const firstPaneRef = el => {
      this.firstPane = el;
    };

    return (
      <div
        className={block('pane', { first: true })}
        style={firstPaneStyle}
        ref={firstPaneRef}
      >
        {renderFirstPane(data.get('size'))}
      </div>
    );
  }

  private renderSecondPane() {
    const { renderSecondPane } = this.props;
    const { data } = this.state;

    return (
      <div className={block('pane', { second: true })}>
        {renderSecondPane(data.get('size'))}
      </div>
    );
  }

  public render() {
    const { split } = this.props;
    const { data } = this.state;

    return (
      <div className={this.getContainerClassName()}>
        {this.renderFirstPane()}
        <ResizeHandle
          dragging={data.get('dragging')}
          onDrag={this.handleResizerDrag}
          onStart={this.handleResizerDragStart}
          onStop={this.handleResizerDragStop}
          position={{
            x: data.get('dragging') ? data.getIn(['resizer', 'x']) : 0,
            y: data.get('dragging') ? data.getIn(['resizer', 'y']) : 0,
          }}
          type={split}
        />

        {this.renderSecondPane()}
      </div>
    );
  }
}

export { IProps as SplitPaneProps };
export { IState as SplitPaneState };
