import React, { FC, useCallback } from 'react';
import AutomationCustomizeStep from 'blocks/AutomationWorkflowWizard/containers/AutomationCustomizeStep';
import { getCompatibilityType } from 'blocks/AutomationWorkflowWizard/containers/AutomationWorkflowWizard/utils';
import { TemplateEditorStateExport } from 'components/VideoTemplateEditor';
import { PodcastIdentifier, TemplateType, VideoTypes } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { CustomizeStepProps } from '../CustomizeStep';

import TemplateStep from '../TemplateStep';
import { customizeStepBlock as block } from './utils';

type PickedCustomizeProps = Pick<
  CustomizeStepProps,
  | 'onChangeView'
  | 'onSelectTemplate'
  | 'onSelectAspectRatio'
  | 'templateId'
  | 'view'
  | 'isLoading'
>;

export interface EpisodeWizardBackCatalogCustomizeStepProps
  extends PickedCustomizeProps {
  aspectRatio: number;
  podcastIdentifier: PodcastIdentifier;
  onSubmit?: (state: TemplateEditorStateExport) => void;
}

const EpisodeWizardBackCatalogCustomizeStep: FC<EpisodeWizardBackCatalogCustomizeStepProps> = ({
  aspectRatio,
  onChangeView,
  onSelectTemplate,
  onSelectAspectRatio,
  podcastIdentifier,
  templateId,
  view,
  isLoading,
  onSubmit,
}) => {
  const { podcastId } = podcastIdentifier ?? {};

  const handleBackClick = useCallback(() => {
    onChangeView('edit');
  }, [onChangeView]);

  const handleGoToTemplatesView = useCallback(() => {
    onChangeView('templates');
  }, [onChangeView]);

  const handleTemplateSelect = useCallback(
    id => {
      onSelectTemplate(id);
      handleBackClick();
    },
    [handleBackClick, onSelectTemplate],
  );

  return view === 'edit' ? (
    <div className={block()}>
      <AutomationCustomizeStep
        aspectRatio={aspectRatio}
        ctaLabel="start getting automatic videos"
        disabled={isLoading}
        onSubmit={onSubmit}
        templateId={templateId}
        videoType={VideoTypes.FULL_EPISODE}
        podcastId={podcastId}
        onTemplatesClick={handleGoToTemplatesView}
      />
    </div>
  ) : (
    <TemplateStep
      aspectRatioName={getAspectRatioName(aspectRatio)}
      compatibilityTypes={[getCompatibilityType(VideoTypes.FULL_EPISODE)]}
      initialSubStep="template-selection"
      onChangeAspectRatio={onSelectAspectRatio}
      onSelect={handleTemplateSelect}
      podcastId={podcastId}
      templateTypes={[
        'blank',
        TemplateType.HEADLINER_DEFAULT,
        TemplateType.USER_GENERATED,
      ]}
    />
  );
};

export default EpisodeWizardBackCatalogCustomizeStep;
