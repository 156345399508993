import cn from 'classnames';
import React, { forwardRef } from 'react';

import { RootCloseWrapper } from 'react-overlays';
import { createChainedFunction } from 'utils/functions';
import SelectContext from './SelectContext';
import SelectControl from './SelectControl';
import SelectMenu from './SelectMenu';
import { Option, Overrides, SelectMenuProps } from './types';
import { block } from './utils';

const { useState } = React;

type ContainerProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

type PickedMenuProps<T extends Option = Option> = Pick<
  SelectMenuProps<T>,
  'onChange' | 'options'
>;

export interface SelectProps<T extends Option = Option>
  extends PickedMenuProps<T>,
    ContainerProps,
    Pick<React.HTMLProps<HTMLDivElement>, 'onMouseOver' | 'onMouseOut'> {
  className?: string;
  overrides?: Overrides<T>;
  value?: SelectMenuProps['value'];
  disabled?: boolean;
  /**
   * Keeps the select open unless the user clicks outside of it,
   * this is use by the multiple-choice component (YoutubePlaylistSelector)
   */
  keepOpenOnClick?: boolean;
}

function Select<T extends Option = Option>(
  {
    className,
    onChange,
    options,
    value,
    overrides,
    disabled,
    keepOpenOnClick = false,
    placeholder,
    ...rest
  }: SelectProps<T>,
  ref?: React.Ref<HTMLDivElement>,
) {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const toggleOpen = () => setOpen(o => !o);

  return (
    <SelectContext.Provider value={overrides}>
      <div className={cn(block(), className)} {...rest} ref={ref}>
        <SelectControl
          {...{ value, placeholder }}
          {...(!disabled && { onClick: toggleOpen })}
        />
        <RootCloseWrapper
          disabled={!open}
          event="click"
          onRootClose={handleClose}
        >
          {!open ? null : (
            <SelectMenu
              options={options}
              onChange={
                keepOpenOnClick
                  ? createChainedFunction(onChange)
                  : createChainedFunction(onChange, handleClose)
              }
              value={value}
            />
          )}
        </RootCloseWrapper>
      </div>
    </SelectContext.Provider>
  );
}

export { Overrides, Option };

// generics and forwardRef does not play well together
// https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
export default forwardRef(Select) as <T extends Option>(
  p: SelectProps<T> & { ref?: React.Ref<HTMLDivElement> },
) => React.ReactElement;

export { default as SelectActionMenu } from './SelectActionMenu';
export { default as SelectOptions } from './SelectOptions';
