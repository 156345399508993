import { useCallback, useEffect } from 'react';

export default function usePageUnloadGuard(enabled: boolean = true) {
  const handleOnBeforeUnload = useCallback((e: Event) => {
    e.preventDefault();
    (e.returnValue as any) = '';
  }, []);

  const removeHandler = useCallback(() => {
    window.removeEventListener('beforeunload', handleOnBeforeUnload);
  }, [handleOnBeforeUnload]);

  useEffect(() => {
    if (enabled) {
      window.addEventListener('beforeunload', handleOnBeforeUnload);
      return removeHandler;
    }
    return undefined;
  }, [enabled, removeHandler, handleOnBeforeUnload]);
}
