import * as React from 'react';

import { SelectField, SelectFieldProps } from 'components/Form';
import { Omit, ProgressPositionId } from 'types';

interface IProps
  extends Omit<SelectFieldProps, 'label' | 'onChange' | 'value'> {
  onChange?: (value: ProgressPositionId) => void;
  value: ProgressPositionId;
}

interface ProgressPositionOption {
  value: ProgressPositionId;
  displayName: string;
}

const options: ProgressPositionOption[] = [
  {
    value: 'top',
    displayName: 'Top',
  },
  {
    value: 'middle',
    displayName: 'Middle',
  },
  {
    value: 'bottom',
    displayName: 'Bottom',
  },
  {
    value: 'custom',
    displayName: 'Custom',
  },
];

const ProgressPositionField: React.FC<IProps> = props => {
  const { disabled, onChange, value } = props;

  return (
    <SelectField
      disabled={disabled}
      horizontal={{
        control: { xs: 7 },
        label: { xs: 5 },
      }}
      label="Position"
      onChange={onChange}
      value={value}
    >
      {options.map(option => (
        <option
          key={option.value}
          value={option.value}
          hidden={option.value === 'custom'}
          disabled={option.value === 'custom'}
        >
          {option.displayName}
        </option>
      ))}
    </SelectField>
  );
};

export default ProgressPositionField;
