import React from 'react';
import { useDispatch } from 'react-redux';

import { ProCard } from 'components/icons';
import { CircularGradientVideoCamera } from 'components/icons/circular-gradient';
import { pushModal } from 'redux/modules/modal/actions';
import bem from 'utils/bem';

import PaidSubscription, {
  unlimitedFeatures,
} from '../components/PaidSubscription';
import PaymentDetailsGate from './PaymentDetailsGate';
import PaymentInfo from './PaymentInfo';

export interface UnlimitedSubscriptionProps {}

const block = bem('unlimited-subscription');

const UnlimitedSubscription: React.FC<UnlimitedSubscriptionProps> = () => {
  const dispatch = useDispatch();

  return (
    <PaidSubscription>
      <PaidSubscription.Header
        icon={<CircularGradientVideoCamera iconClassName={block('icon')} />}
        title="Unlimited"
      >
        <PaidSubscription.Header.Features features={unlimitedFeatures} />
      </PaidSubscription.Header>
      <PaidSubscription.Body>
        <PaymentDetailsGate>
          <PaymentInfo
            cardElement={<ProCard />}
            onUpdatePlanClick={() =>
              dispatch(pushModal({ name: 'AdjustPlan' }))
            }
          />
        </PaymentDetailsGate>
      </PaidSubscription.Body>
    </PaidSubscription>
  );
};

export default UnlimitedSubscription;
