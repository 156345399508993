import React from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'underscore';

import { DeleteModalContents } from 'components/DeleteModal';
import { deleteUserAccount } from 'redux/modules/auth';
import { showError, showSuccess } from 'redux/modules/notification';

interface DeleteAccountModalContentsProps {
  onHide?: () => void;
}

const DeleteAccountModalContents: React.FunctionComponent<DeleteAccountModalContentsProps> = props => {
  const { onHide = noop } = props;

  const dispatch = useDispatch();

  const handleDeleteAccount = React.useCallback(async () => {
    try {
      await dispatch(deleteUserAccount());
      onHide();

      dispatch(
        showSuccess({
          title: 'Deletion complete',
          message: 'Your account has been deleted',
        }),
      );
    } catch {
      dispatch(showError({ message: 'Error deleting account.' }));
    }
  }, [dispatch, onHide]);

  return (
    <DeleteModalContents
      bodyText={
        <p>
          This action cannot be undone, are you sure you want to proceed with
          account deletion? All your content will be permanently deleted.
        </p>
      }
      cancelButtonTitle="Cancel"
      deleteButtonTitle="Delete"
      onDelete={handleDeleteAccount}
      onCancel={onHide}
    />
  );
};

export default DeleteAccountModalContents;
