import dayjs, { Dayjs } from 'dayjs';
import { RecordOf } from 'immutable';
import * as React from 'react';

import { IRevisionItemData } from '../types';
import { block } from '../utils';

interface IProps {
  item: RecordOf<IRevisionItemData>;
  onClickRestoreToVersion: (item: RecordOf<IRevisionItemData>) => void;
}

const dateFormatDefault = 'MMM D, YYYY';
const dateFormatToday = '[Today]';
const timeFormat = 'h:mm:ss a';
const printDate = (date: Dayjs) => {
  const today = dayjs().startOf('day');

  return date.isSame(today, 'd')
    ? date.format(dateFormatToday)
    : date.format(dateFormatDefault);
};
const printTime = date => {
  return date.format(timeFormat);
};

const RevisionHistoryDropdownItem: React.SFC<IProps> = ({
  item,
  onClickRestoreToVersion,
}) => {
  const handleClickRestoreToVersion = () => onClickRestoreToVersion(item);
  const updatedAt = dayjs(Number(item.get('updatedAt')));

  return (
    <li
      className={block('dropdown-item')}
      onClick={handleClickRestoreToVersion}
    >
      <span className={block('dropdown-item-timestamp', { date: true })}>
        {printDate(updatedAt)}
      </span>
      <span className={block('dropdown-item-restore')}>Restore version</span>
      <span className={block('dropdown-item-timestamp', { time: true })}>
        {printTime(updatedAt)}
      </span>
    </li>
  );
};

export default RevisionHistoryDropdownItem;
export { IProps as RevisionHistoryDropdownItemProps };
