import classNames from 'classnames';
import Slider, { SliderProps } from 'rc-slider';
import * as React from 'react';

interface IProps extends SliderProps {
  className?: string;
}

const WrappedSlider: React.SFC<IProps> = ({
  className,
  disabled,
  ...sliderProps
}) => (
  <div
    className={classNames({
      'wrapped-slider': true,
      'wrapped-slider--disabled': disabled,
      [className]: !!className,
    })}
  >
    <Slider {...sliderProps} disabled={disabled} />
  </div>
);

export default WrappedSlider;
export { IProps as WrappedSliderProps };
