import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import { block } from './utils';

const AssetTileCheckbox: React.FC = () => (
  <div className={block('checkbox')}>
    <div className={block('checkbox-icon')}>
      <FontAwesome icon="check" />
    </div>
  </div>
);

export default AssetTileCheckbox;
