import cn from 'classnames';
import * as React from 'react';

import withWrapper from 'components/hoc/withWrapper';
import FreeTranscriptionBalance, {
  FreeTranscriptionBalanceProps,
} from 'containers/FreeTranscriptionBalance';
import { Omit } from 'types';
import { useEditorTranscriptionOptionsContext } from './EditorTranscriptionOptionsContext';
import { block } from './utils';

export type TranscriptionBalanceProps = Omit<
  FreeTranscriptionBalanceProps,
  'source'
>;

const TranscriptionBalance: React.FC<TranscriptionBalanceProps> = ({
  className,
  onClick,
}) => {
  const { showBalance } = useEditorTranscriptionOptionsContext();

  return (
    showBalance && (
      <div className={cn(block('balance-container'), className)}>
        <FreeTranscriptionBalance
          className={block('balance')}
          onClick={onClick}
          source="project"
        />
      </div>
    )
  );
};

export default withWrapper()(TranscriptionBalance);
