import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  createSubscriptionTypes,
  PodcastSubscriptionVideoFrequency,
} from 'redux/middleware/api/podcast-service';
import {
  editAutomationChangeStartingPoint,
  PartialUpdateAutomationData,
} from 'redux/modules/edit-automation';
import { replaceModal } from 'redux/modules/modal';
import { SelectableAutogramFrequency } from 'types';
import useEditAutomationModal from '../hooks/useEditAutomationModal';
import { getSubscriptionItem } from '../utils';

interface UseEditAutomationEpisodeConfig {
  subscriptionId?: number;
}

const useEditAutomationEpisode = ({
  subscriptionId,
}: UseEditAutomationEpisodeConfig) => {
  const {
    subscription,
    onGoBackToMain,
    onUpdateAutomation,
  } = useEditAutomationModal(subscriptionId);
  const dispatch = useDispatch();

  const handleOpenEpisodeSelection = useCallback(() => {
    dispatch(
      replaceModal({
        name: 'EditAutomationEpisodeSelection',
        params: {
          subscriptionId,
        },
      }),
    );
  }, [dispatch, subscriptionId]);

  const handleOpenSeasonSelection = useCallback(() => {
    dispatch(
      replaceModal({
        name: 'EditAutomationEpisodeSelectSeason',
        params: {
          subscriptionId,
        },
      }),
    );
  }, [dispatch, subscriptionId]);

  const handleUpdateSeasons = useCallback(
    async (
      videoFrequency: SelectableAutogramFrequency,
      targetedSeasons?: number[] | undefined,
    ) => {
      dispatch(editAutomationChangeStartingPoint(videoFrequency));

      const subscriptionItem = getSubscriptionItem(subscription);

      const data: PartialUpdateAutomationData = {
        subscriptionId: subscription.autoCreateSubscriptionId,
        subscriptionTypes: createSubscriptionTypes(subscriptionItem, {
          restartBackCatalog: true,
          videoFrequency,
          targetedSeasons,
        }),
      };

      await onUpdateAutomation(data, 'starting point');
    },
    [dispatch, onUpdateAutomation, subscription],
  );

  const handleConfirmEpisodeSelection = useCallback(
    async (
      videoFrequency: PodcastSubscriptionVideoFrequency,
    ): Promise<void> => {
      if (videoFrequency === 'onPublish') {
        await handleUpdateSeasons(videoFrequency);
        onGoBackToMain();
      } else {
        handleOpenSeasonSelection();
      }
    },
    [handleOpenSeasonSelection, handleUpdateSeasons, onGoBackToMain],
  );

  return {
    onOpenEpisodeSelection: handleOpenEpisodeSelection,
    onConfirmEpisodeSelection: handleConfirmEpisodeSelection,
    onUpdateSeasons: handleUpdateSeasons,
  };
};

export default useEditAutomationEpisode;
