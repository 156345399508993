import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WithRouterComponentProps } from 'components/hoc/withRouter';
import { showError } from 'redux/modules/notification/actions';
import { goToCreate } from 'redux/modules/router/actions';
import {
  isLoadingSelector,
  loadWizardData,
} from 'redux/modules/video-clip-wizard';
import { Dispatch } from 'redux/types';
import { isApplicationError } from 'utils/ApplicationError';
import { getTraceIdsFromQuery } from '../utils';
import VideoClipWizard from '../VideoClipWizard';
import LoadingAssetsSpinner from './LoadingAssetsSpinner';

interface IProps extends WithRouterComponentProps<string> {}

const ERROR_ID = 'video-clip-wizard-loader-error';

function VideoClipWizardLoader(props: IProps) {
  const { location } = props;

  const isLoading = useSelector(isLoadingSelector);
  const dispatch = useDispatch<Dispatch>();

  const { query } = location;
  const { traceId, customTraceId } = getTraceIdsFromQuery(query);

  useEffect(() => {
    dispatch(loadWizardData(customTraceId, traceId)).catch(err => {
      if (isApplicationError(err)) {
        dispatch(
          showError({
            message: err.message,
            dismissAfterSec: 0,
            code: err.code,
          }),
        );
      } else {
        dispatch(showError(err.message, 0, ERROR_ID));
      }

      dispatch(goToCreate());
    });
  }, [customTraceId, traceId, dispatch]);

  return isLoading ? <LoadingAssetsSpinner /> : <VideoClipWizard />;
}

export default memo(VideoClipWizardLoader);
export { IProps as VideoClipWizardLoaderProps };
