import cn from 'classnames';
import { Circle } from 'rc-progress';
import * as React from 'react';

import bem from 'utils/bem';

export interface CircleProgressProps {
  className?: string;
  percentage: number;
  overlay?: boolean;
  size?: 'md' | 'sm';
  label?: string;
}

export const block = bem('circle-progress');

const CircleProgress: React.FC<CircleProgressProps> = props => {
  const { className, percentage, size, overlay, label } = props;
  const value = Math.round(percentage || 0);

  return (
    <div className={cn(block({ [size]: !!size, overlay }), className)}>
      <div className={block('wrapper')}>
        <Circle
          className={block('progress')}
          percent={`${value}`}
          strokeWidth="5"
          strokeColor="#19b5fe"
          trailColor="#4a4f5d"
          trailWidth="3"
        />
        <span className={block('value')}>{value}%</span>
      </div>
      {label && <div className={block('label')}>{label}</div>}
    </div>
  );
};

export default CircleProgress;
