import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { WizardRouterOption, WizardStep } from 'blocks/OnboardingWizard/types';
import {
  onOnboardingWizardNext,
  onSignupComplete,
  setMixpanelPodcast,
  setMixpanelWizardRouterSelection,
} from 'redux/modules/mixpanel/actions';
import { OnboardingFlowNextProperties } from 'redux/modules/mixpanel/types';
import { showNotification } from 'redux/modules/notification';
import { saveOnboardingWizardResponse } from 'redux/modules/onboarding/actions';
import { Dispatch } from 'redux/types';
import { HeadlinerUsage } from 'types';

interface OnboardingWizardResponse {
  wizardRoute: WizardRouterOption;
  usage: HeadlinerUsage;
  subscribeToNewsletter: boolean;
  referrer: string;
}

export default function useOnboardingWizardDispatch() {
  const dispatch = useDispatch<Dispatch>();

  const onSubmit = useCallback(
    async (response: OnboardingWizardResponse) => {
      const { wizardRoute, usage, subscribeToNewsletter, referrer } = response;
      const isPodcast = wizardRoute === 'podcast';
      const podcastYesNo = isPodcast ? 'yes' : 'no';

      try {
        setMixpanelPodcast(isPodcast);
        setMixpanelWizardRouterSelection(wizardRoute);
        await dispatch(
          saveOnboardingWizardResponse({
            podcastYesNo,
            usage,
            subscribeToNewsletter,
            referrer,
          }),
        );
        dispatch(onSignupComplete());
      } catch (error) {
        dispatch(
          showNotification({
            message: 'Please try again or {{link}} so we can help',
            code: 'ER013',
            level: 'error',
            dismissAfterSec: 10,
            type: 'help',
            title: "Sorry, we couldn't update your account",
            actionLabel: 'contact us',
          }),
        );
      }
    },
    [dispatch],
  );

  const onNext = useCallback(
    (step: WizardStep, props?: OnboardingFlowNextProperties) => {
      dispatch(onOnboardingWizardNext(step, props));
    },
    [dispatch],
  );

  return { onNext, onSubmit };
}
