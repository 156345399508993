import { AspectRatioName } from 'types';
import bem from 'utils/bem';

import { templateStepSubSteps, TemplateStepSubSteps } from './types';

const DISPLAY_ASPECT_RATIOS: { [k in AspectRatioName]: number } = {
  landscape: 16 / 9,
  portrait: 9 / 16,
  square: 1,
};

export const block = bem('template-step');

export function getDisplayAspectRatio(name: AspectRatioName) {
  return DISPLAY_ASPECT_RATIOS[name];
}

export const isTemplateStepSubStep = (
  value?: string | number | (string | number)[],
): value is TemplateStepSubSteps => {
  return (
    typeof value === 'string' &&
    templateStepSubSteps.includes(value as TemplateStepSubSteps)
  );
};
