import cn from 'classnames';
import * as React from 'react';

import { H2 } from 'components/Heading';
import { block } from './utils';

export interface BannerPageHeaderProps {
  children?: React.ReactNode;
  className?: string;
  icon: JSX.Element;
  subtitle?: string;
  title: string;
}

/**
 * The icon, title, and opitonal subtitle for the <BannerPage />
 */

const BannerPageHeader: React.SFC<BannerPageHeaderProps> = ({
  children,
  className,
  icon,
  subtitle,
  title,
}) => (
  <div className={cn(block('header'), className)}>
    <div className={block('header-contents')}>
      <div className={block('title')}>
        <div className={block('icon')}>{icon}</div>
        <div className={block('text')}>
          <span className={block('name', { small: subtitle !== undefined })}>
            {title}
          </span>
          {subtitle && <H2 className={block('subtitle')}>{subtitle}</H2>}
        </div>
      </div>
      {children}
    </div>
  </div>
);

export default BannerPageHeader;
