import cn from 'classnames';
import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import IconButton, { IconButtonProps } from 'components/IconButton';
import { block } from './utils';

export interface AssetTileDeleteButtonProps {
  className?: string;
  onClick?: IconButtonProps['onButtonClick'];
}

const AssetTileDeleteButton: React.SFC<AssetTileDeleteButtonProps> = ({
  className,
  onClick,
}) => (
  <IconButton
    className={cn(block('delete-button'), className)}
    onButtonClick={onClick}
  >
    <FontAwesome icon="trash-alt" style={{ height: '52%', width: 'auto' }} />
  </IconButton>
);

export default AssetTileDeleteButton;
