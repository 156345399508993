import { List } from 'immutable';
import * as ids from 'short-id';

import { ThunkAction } from 'redux/types';
import { TrackType } from 'types';
import { getNewTrackIndex } from 'utils/embed/tracks';
import * as types from '../action-types';
import * as embedSelectors from '../selectors';
import {
  AddingToTrackAction,
  CreateTrackAction,
  CreateTrackOptions,
  ReplaceTrackElementAction,
} from '../types';
import { deleteCaptionsSource } from './captions';
import { saveConfiguration, setEmbedDuration } from './embed';

type DataId = string | number;

export const addingToTrack = (trackId: string): AddingToTrackAction => ({
  payload: { trackId },
  type: types.EMBED_ADDING_TO_TRACK_SET,
});

export const addingToMediaTrack = (): ThunkAction<AddingToTrackAction> => (
  dispatch,
  getState,
) => {
  const trackId = embedSelectors
    .replaceTrackElementSelector(getState())
    .get('trackId');
  return dispatch(addingToTrack(trackId));
};

export const clearEmbedTrackState = () => ({
  type: types.EMBED_TRACK_STATE_CLEAR,
});

export const cancelAddingToTrack = () => ({
  type: types.EMBED_ADDING_TO_TRACK_CLEAR,
});

export const selectTrackElement = <D extends DataId>(
  dataId: D,
  trackId: string,
) => ({
  payload: {
    dataId,
    trackId,
  },
  type: types.EMBED_TRACK_ELEMENT_SELECT,
});

export const clearSelectedTrackElement = () => ({
  type: types.EMBED_TRACK_ELEMENT_CLEAR,
});

export const clearReplacedTrackElement = () => ({
  type: types.EMBED_REPLACED_TRACK_ELEMENT_CLEAR,
});

export const replaceSelectedTrackElement = (): ThunkAction<ReplaceTrackElementAction> => (
  dispatch,
  getState,
) => {
  const selectedTrackElement = embedSelectors.selectedTrackElementSelector(
    getState(),
  );
  return dispatch({
    payload: selectedTrackElement,
    type: types.EMBED_TRACK_ELEMENT_REPLACE,
  });
};

export const selectTrackForStyling = (trackId: string) => ({
  payload: { trackId },
  type: types.EMBED_STYLE_TRACK_SELECT,
});

export const clearTrackForStyling = () => ({
  type: types.EMBED_STYLE_TRACK_CLEAR,
});

export const createTrack = (
  type: TrackType,
  {
    data = List([]),
    layerOrderType = 'grouped',
    name,
  }: CreateTrackOptions = {},
): ThunkAction<CreateTrackAction> => (dispatch, getState) => {
  const trackOrder = embedSelectors.tracksSelector(getState());
  const tracks = embedSelectors.tracksByIdSelector(getState());
  const newTrackIndex = getNewTrackIndex(
    trackOrder,
    tracks,
    type,
    layerOrderType,
  );

  return dispatch({
    payload: {
      data,
      name,
      type,
      id: ids.generate(),
      index: newTrackIndex,
    },
    type: types.EMBED_TRACK_CREATE,
  });
};

export const deleteTrack = (trackId: string): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const tracksById = embedSelectors.tracksByIdSelector(getState());
  const trackData = tracksById.getIn([trackId, 'data']);
  const trackType = tracksById.getIn([trackId, 'type']);
  const videos = embedSelectors.videosByIdSelector(getState());

  dispatch({
    payload: { trackId, trackData, trackType },
    type: types.EMBED_TRACK_DELETE,
  });

  if (!trackData.isEmpty()) {
    if (trackType === 'media') {
      trackData.forEach(id => {
        const videoId = videos.getIn([id, 'serverId']);
        dispatch(deleteCaptionsSource(videoId, 'video'));
      });
    }

    dispatch(setEmbedDuration(true));
    dispatch(saveConfiguration());
  }
};

export const updateTrack = <D extends DataId>(
  trackId: string,
  updateFn: (data: List<D>) => List<D>,
): ThunkAction<void> => (dispatch, getState) => {
  const tracksById = embedSelectors.mapStateToTracksById(getState());
  const track = tracksById.get(trackId);
  const updatedTrack = track.update('data', d => updateFn(d || List()));

  dispatch({
    payload: {
      track: updatedTrack,
    },
    type: types.EMBED_TRACK_UPDATE,
  });
};

export const moveTrack = (
  trackId: string,
  targetIndex: number,
): ThunkAction<Promise<void>> => dispatch => {
  dispatch({
    payload: { trackId, targetIndex },
    type: types.EMBED_TRACK_MOVE,
  });
  return dispatch(saveConfiguration());
};

export const addToTrack = <D extends DataId>(
  trackId: string,
  dataId: D,
): ThunkAction<void> => dispatch => {
  dispatch(
    updateTrack<D>(trackId, data => data.push(dataId)),
  );
  dispatch({ type: types.EMBED_ADDING_TO_TRACK_CLEAR });
};
