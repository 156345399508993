import { Button, Trash } from '@sparemin/blockhead';
import React from 'react';

import { block } from '../../../utils';

interface DeleteOverlayButtonProps {
  onDelete: () => void;
}

const DeleteOverlayButton: React.FunctionComponent<DeleteOverlayButtonProps> = props => {
  const { onDelete } = props;

  return (
    <Button className={block('delete-button')} onPress={onDelete} size="small">
      <Trash />
    </Button>
  );
};

export default DeleteOverlayButton;
