export enum Type {
  TEXT_WIZARD_CREATE_REQUEST = 'app/text-wizard/TEXT_WIZARD_CREATE_REQUEST',

  TEXT_WIZARD_SUMMARIZE_REQUEST = 'app/text-wizard/TEXT_WIZARD_SUMMARIZE_REQUEST',
  TEXT_WIZARD_SUMMARIZE_SUCCESS = 'app/text-wizard/TEXT_WIZARD_SUMMARIZE_SUCCESS',
  TEXT_WIZARD_SUMMARIZE_FAILURE = 'app/text-wizard/TEXT_WIZARD_SUMMARIZE_FAILURE',

  TEXT_WIZARD_TEXT_UPLOAD_REQUEST = 'app/text-wizard/TEXT_WIZARD_TEXT_UPLOAD_REQUEST',
  TEXT_WIZARD_TEXT_UPLOAD_SUCCESS = 'app/text-wizard/TEXT_WIZARD_TEXT_UPLOAD_SUCCESS',

  TEXT_WIZARD_TRANSCRIPT_PROCESS_REQUEST = 'app/text-wizard/TEXT_WIZARD_TRANSCRIPT_PROCESS_REQUEST',
  TEXT_WIZARD_TRANSCRIPT_PROCESS_SUCCESS = 'app/text-wizard/TEXT_WIZARD_TRANSCRIPT_PROCESS_SUCCESS',

  TEXT_WIZARD_ANALYZE_REQUEST = 'app/text-wizard/TEXT_WIZARD_ANALYZE_REQUEST',
  TEXT_WIZARD_ANALYZE_SUCCESS = 'app/text-wizard/TEXT_WIZARD_ANALYZE_SUCCESS',

  TEXT_WIZARD_CONFIGURATION_CREATE_REQUEST = 'app/text-wizard/TEXT_WIZARD_CONFIGURATION_CREATE_REQUEST',
  TEXT_WIZARD_CONFIGURATION_CREATE_SUCCESS = 'app/text-wizard/TEXT_WIZARD_CONFIGURATION_CREATE_SUCCESS',

  TEXT_WIZARD_PROJECT_CREATE_REQUEST = 'app/text-wizard/TEXT_WIZARD_PROJECT_CREATE_REQUEST',
  TEXT_WIZARD_PROJECT_CREATE_SUCCESS = 'app/text-wizard/TEXT_WIZARD_PROJECT_CREATE_SUCCESS',

  TEXT_WIZARD_CLEAR = 'TEXT_WIZARD_CLEAR',
}
