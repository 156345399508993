import * as React from 'react';

import { IconProps } from './types';

const Reset: React.FC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
    <path
      fill="currentColor"
      d="M11.714 0h-1.128c-.078 0-.152.032-.206.088-.054.057-.083.133-.08.211l.096 1.97C9.276.95 7.632.19 5.901.19 2.649.19-.002 2.846 0 6.1.002 9.359 2.644 12 5.9 12c1.462.002 2.872-.54 3.955-1.522.058-.052.093-.126.095-.205.002-.078-.028-.154-.084-.21l-.809-.81c-.106-.105-.276-.111-.39-.012-1.308 1.152-3.194 1.372-4.733.553-1.539-.82-2.41-2.507-2.186-4.238.224-1.73 1.496-3.14 3.193-3.54 1.697-.4 3.464.293 4.436 1.741l-2.416-.116c-.078-.004-.154.025-.21.079-.057.054-.089.129-.089.207v1.129c0 .158.128.285.286.285h4.766c.158 0 .286-.127.286-.285V.286C12 .128 11.872 0 11.714 0z"
    />
  </svg>
);

export default Reset;
