import * as React from 'react';
import Select, { Overrides } from 'components/Select';
import OrganizationOption from './OrganizationOption';
import {
  LinkedinOrganizationSelectorOption,
  LinkedinOrganizationSelectorProps,
} from './types';
import { block } from './utils';

const selectComponentOverrides: Overrides<LinkedinOrganizationSelectorOption> = {
  option: {
    component: props => <OrganizationOption {...props} />,
  },
};

const LinkedinOrganizationSelector: React.FC<LinkedinOrganizationSelectorProps> = ({
  onChange,
  value,
  options,
}) => (
  <Select
    className={block()}
    onChange={onChange}
    options={options}
    overrides={selectComponentOverrides}
    value={value}
    disabled={options.length === 0}
  />
);

export default LinkedinOrganizationSelector;
