import * as React from 'react';

function SkipCircle(props) {
  return (
    <svg width={35} height={35} viewBox="0 0 35 35" {...props}>
      <g fill="none" fillRule="evenodd">
        <rect
          width={33.5}
          height={33.5}
          x={0.75}
          y={0.75}
          stroke="#DCE1EB"
          strokeWidth={1.5}
          rx={16.75}
        />
        <path
          fill="#DCE1EB"
          fillRule="nonzero"
          d="M13.3 11.538v11.924c0 .186.142.338.315.338h1.26c.173 0 .315-.152.315-.338v-4.96l5.132 5.09c.54.48 1.378.078 1.378-.692V12.1c0-.77-.837-1.173-1.378-.692l-5.132 5.06v-4.93c0-.186-.142-.338-.315-.338h-1.26c-.173 0-.315.152-.315.338z"
        />
      </g>
    </svg>
  );
}

export default SkipCircle;
