import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import Minimap, { MinimapProps } from 'containers/Minimap';
import TimelineZoomSlider, {
  TimelineZoomSliderProps,
} from './TimelineZoomSlider';

type PickedMinimapProps = Pick<MinimapProps, 'viewport'>;
type PickedSliderProps = Pick<
  TimelineZoomSliderProps,
  'onZoomInClick' | 'onZoomOutClick'
>;

export interface TimelineUtilityBarProps
  extends PickedMinimapProps,
    PickedSliderProps {
  className?: string;
  onMinimapSeek?: MinimapProps['onSeek'];
  onMinimapWindowDrag?: MinimapProps['onViewportWindowDrag'];
  onZoomSliderChange?: TimelineZoomSliderProps['onChange'];
  onZoomSliderStop?: TimelineZoomSliderProps['onSlideStop'];
  zoomPct?: TimelineZoomSliderProps['value'];
}

const TimelineUtilityBar: React.SFC<TimelineUtilityBarProps> = ({
  className,
  onMinimapSeek,
  onMinimapWindowDrag,
  onZoomInClick,
  onZoomOutClick,
  onZoomSliderChange,
  onZoomSliderStop,
  viewport,
  zoomPct,
}) => {
  const containerClassName = cn(
    'timeline-util',
    'timeline-util--default',
    className,
  );

  return (
    <div className={containerClassName}>
      <TimelineZoomSlider
        className="timeline-util__zoom-slider"
        minusClassName="timeline-util__zoom-button"
        onChange={onZoomSliderChange}
        onSlideStop={onZoomSliderStop}
        onZoomInClick={onZoomInClick}
        onZoomOutClick={onZoomOutClick}
        plusClassName="timeline-util__zoom-button"
        sliderClassName="timeline-util__slider"
        value={zoomPct}
      />
      <Minimap
        className="timeline-util__minimap"
        needleClassName="timeline-util__minimap-needle"
        onViewportWindowDrag={onMinimapWindowDrag}
        onSeek={onMinimapSeek}
        viewportClassName="timeline-util__minimap-viewport"
        viewport={viewport}
      />
    </div>
  );
};

TimelineUtilityBar.defaultProps = {
  onMinimapSeek: noop,
  onMinimapWindowDrag: noop,
  onZoomInClick: noop,
  onZoomOutClick: noop,
  onZoomSliderStop: noop,
};

export default TimelineUtilityBar;
