import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
  MultiVideoCamera,
  VideoCameraAlt,
  VideoCameraAlt2,
} from 'components/icons';
import Infobox, { InfoboxProps } from 'components/Infobox';
import LinkButton from 'components/LinkButton';
import { PurchaseableTier, Tier } from 'redux/middleware/api/plan-service';
import { onCheckoutModalChangePlan } from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal';
import { useAddBillingCycleModal } from './AddBillingCycleModalContext';
import { block } from './utils';

export interface TierInfoboxProps {
  tier: PurchaseableTier;
}

interface TierInfoboxConfigValue extends Pick<InfoboxProps, 'icon' | 'theme'> {
  tierName: string;
}

const TIER_INFOBOX_CONFIG: Record<PurchaseableTier, TierInfoboxConfigValue> = {
  [Tier.BASIC]: {
    theme: 'purple',
    icon: <VideoCameraAlt2 />,
    tierName: 'Headliner Basic',
  },
  [Tier.PRO]: {
    theme: 'blue',
    icon: <MultiVideoCamera />,
    tierName: 'Headliner Pro',
  },
  [Tier.UNLIMITED]: {
    theme: 'gradient',
    icon: <VideoCameraAlt style={{ marginLeft: 3 }} />,
    tierName: 'Headliner Unlimited',
  },
};

const TierInfobox: React.FC<TierInfoboxProps> = ({ tier }) => {
  const dispatch = useDispatch();
  const { allowTierSelection } = useAddBillingCycleModal();

  const { icon, theme, tierName } = TIER_INFOBOX_CONFIG[tier];

  const handleSelectAnother = () => {
    dispatch(onCheckoutModalChangePlan);
    dispatch(
      replaceModal({
        name: 'TierSelection',
      }),
    );
  };

  return (
    <Infobox className={block('tier-infobox')} {...{ icon, theme }}>
      {tierName}
      {allowTierSelection && (
        <>
          {' '}
          (
          <LinkButton
            className={block('tier-infobox-link')}
            onClick={handleSelectAnother}
          >
            select another
          </LinkButton>
          )
        </>
      )}
    </Infobox>
  );
};

export default TierInfobox;
