import * as React from 'react';

import MailToSupport from 'components/MailToSupport';
import Disclaimer from './Disclaimer';
import PixabayDisclaimer from './PixabayDisclaimer';

export type SearchType = 'video' | 'image' | 'gif-video';

interface IProps {
  className?: string;
  engineList: string[];
  selectedEngine?: string;
  searchType: SearchType;
}

const getEmailSubject = (type: SearchType) => {
  if (type === 'video') return 'Connect Video Providers';
  if (type === 'image') return 'Connect Image Providers';

  return 'Connect Gif Providers';
};

const SearchFooter: React.SFC<IProps> = ({
  className,
  engineList,
  selectedEngine,
  searchType,
}) => {
  const additionalDisclaimer = selectedEngine === 'pixabay' &&
    engineList.indexOf('pixabay') >= 0 && (
      <PixabayDisclaimer mediaType={searchType} />
    );

  const emailSubject = getEmailSubject(searchType);

  return (
    <div className={className}>
      <Disclaimer className="search-results__info search-results__disclaimer">
        {additionalDisclaimer}
      </Disclaimer>
      {searchType !== 'gif-video' && (
        <span>
          {' '}
          Enterprise customers, please contact{' '}
          <MailToSupport subject={emailSubject}>support</MailToSupport> to
          connect your existing {searchType} providers.
        </span>
      )}
    </div>
  );
};

export default SearchFooter;
