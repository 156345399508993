import * as React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import AdjustPlanModalContents from './AdjustPlanModalContents';

const AdjustPlanModal: React.FC = () => (
  <ConnectedModal name="AdjustPlan" title="adjust my plan">
    <AdjustPlanModalContents />
  </ConnectedModal>
);

export default AdjustPlanModal;
