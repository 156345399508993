import { useToggle } from '@sparemin/blockhead';
import React from 'react';
import { isFunction, noop } from 'underscore';

import TextWorkspace from 'components/TextWorkspace';
import {
  getDraggingEnabledByTemplateId,
  getResizableEdgesByTemplateId,
} from 'utils/embed/text-overlay';

import useOverlayPosition from '../../hooks/useTextOverlayPosition';
import useTextOverlayStyle from '../../hooks/useTextOverlayStyle';
import { useTextOverlay } from '../../state/TextOverlayProvider';
import { block } from '../../utils';
import TextOverlayTextPreview from '../TextOverlayTextPreview';
import { RESIZE_HANDLES_STYLES } from './constants';

export interface TextOverlayPreviewPaneProps {
  aspectRatio: number;
  background: React.ReactElement | (({ viewport }) => React.ReactElement);
  editable: boolean;
  inputDisabled?: boolean;
  templateId?: string;
}

const TextOverlayPreviewPane: React.FunctionComponent<TextOverlayPreviewPaneProps> = props => {
  const {
    aspectRatio,
    background,
    editable,
    inputDisabled = false,
    templateId,
  } = props;

  const {
    boxSizeFitCheckerExtension,
    canvasSize,
    onChangeText,
    textValue,
  } = useTextOverlay();
  const { textAreaStyles, textBuilderStyles } = useTextOverlayStyle();

  const {
    toggleOff: turnOffInputMode,
    toggleOn: turnOnInputMode,
    value: inputModeEnabled,
  } = useToggle(false);

  const {
    onDrag,
    onDragStop,
    onResizeStart,
    onResizeStop,
    position,
    size,
  } = useOverlayPosition();

  const {
    onTextBoxResizeEnd,
    onTextBoxResizeStart,
    onTextBoxResize,
    textBoxClassModifier,
  } = boxSizeFitCheckerExtension;

  const draggingEnabled =
    !inputModeEnabled && getDraggingEnabledByTemplateId(templateId);

  return (
    <TextWorkspace
      aspectRatio={aspectRatio}
      background={
        <div>
          <div className={block('preview-fade')} />
          {isFunction(background)
            ? background({ viewport: canvasSize })
            : background}
        </div>
      }
      boxBorderClassName={block('resize-box-border', {
        'in-edition': inputModeEnabled,
      })}
      className={block('workspace')}
      containerSize={canvasSize}
      defaultSize={canvasSize}
      disabled={inputDisabled}
      draggingEnabled={draggingEnabled}
      hidePositionOverlay
      onTextBoxDrag={onDrag}
      onTextBoxDragStop={onDragStop}
      onTextBoxResize={onTextBoxResize}
      onTextBoxResizeStart={onTextBoxResizeStart(onResizeStart)}
      onTextBoxResizeStop={onTextBoxResizeEnd(onResizeStop)}
      onWorkspaceSizeChange={noop}
      positionOverlayClassName={block('position-overlay')}
      resizeHandleStyles={RESIZE_HANDLES_STYLES}
      resizingEnabled={!inputModeEnabled}
      textBoxClassName={block('text-box', textBoxClassModifier)}
      textBoxPosition={position}
      textBoxSize={size}
      resizableEdges={getResizableEdgesByTemplateId(templateId)}
    >
      <TextOverlayTextPreview
        editable={editable}
        inputModeEnabled={inputModeEnabled}
        onChangeText={onChangeText}
        onDisableInputMode={turnOffInputMode}
        onEnableInputMode={turnOnInputMode}
        textAreaStyles={textAreaStyles}
        textBuilderStyles={textBuilderStyles}
        textValue={textValue}
      />
    </TextWorkspace>
  );
};

export default TextOverlayPreviewPane;
