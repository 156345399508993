import React, { useCallback, useState } from 'react';
import BemCssTransition from 'components/BemCssTransition';
import InfiniteScroll, { InfiniteScrollProps } from 'components/InfiniteScroll';
import { cmsBlock as block } from '../utils';

type PickedInfiniteScrollProps = Pick<
  InfiniteScrollProps,
  'getScrollParent' | 'onLoadMore'
>;

interface Props extends PickedInfiniteScrollProps {
  active: boolean;
  hasMore: boolean;
  isLoading: boolean;
  children: React.ReactNode;
  loadingText?: string;
}

function AutomationCMSInfiniteScroll(props: Props) {
  const {
    active,
    hasMore,
    isLoading,
    loadingText,
    children,
    getScrollParent,
    onLoadMore,
  } = props;
  const [entered, setEntered] = useState<boolean>(false);

  const handleEntered = useCallback(() => setEntered(true), []);

  const handleExit = useCallback(() => setEntered(false), []);

  return (
    <BemCssTransition
      className={block('podcast-data')}
      delay={{
        enter: 150,
      }}
      in={active}
      onEntered={handleEntered}
      onExit={handleExit}
      unmountOnExit
      timeout={300}
    >
      <InfiniteScroll
        getScrollParent={getScrollParent}
        hasMore={entered && active && hasMore}
        isLoading={isLoading}
        onLoadMore={onLoadMore}
        spinnerClassName={block('podcast-data-spinner')}
        loadingText={loadingText}
        window={false}
      >
        {children}
      </InfiniteScroll>
    </BemCssTransition>
  );
}

export default AutomationCMSInfiniteScroll;
