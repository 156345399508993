import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePlanCancellation from 'blocks/Pricing/usePlanCancellation';

import { hideModal, pushModal } from 'redux/modules/modal';
import { subtierSelector } from 'redux/modules/pricing';
import { PlanCancellationReason } from 'types';
import { isProSubTier } from 'utils/pricing';
import { Props } from './types';

export default (props: Props) => {
  const { reason } = props;
  const dispatch = useDispatch();
  const { onSubmit } = usePlanCancellation({ reason });
  const subtier = useSelector(subtierSelector);

  const closeModal = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  const handleSubmit = useCallback(
    (details: string) => {
      if (
        isProSubTier(subtier) &&
        reason === PlanCancellationReason.DONT_RUN_OUT_OF_VIDEOS
      ) {
        dispatch(
          pushModal({
            name: 'DowngradeUpsellModal',
            params: { details, reason },
          }),
        );
        closeModal();
      } else {
        closeModal();
        onSubmit(details);
      }
    },
    [closeModal, dispatch, onSubmit, reason, subtier],
  );

  const onBack = () => {
    closeModal();
    dispatch(
      pushModal({
        name: 'CancelReason',
        params: { reason },
      }),
    );
  };

  return { onSubmit: handleSubmit, onBack };
};
