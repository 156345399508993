import React from 'react';

import RadioOptions, { RadioOptionsProps } from 'components/RadioOptions';
import { CaptionFormat } from 'types';
import { CAPTIONS_FORMAT_OPTIONS } from './constants';
import { block } from './utils';

export interface CaptionsFormatSelectorProps
  extends Pick<RadioOptionsProps, 'onChange' | 'value'> {
  options: CaptionFormat[];
}

const CaptionsFormatSelector: React.FC<CaptionsFormatSelectorProps> = ({
  onChange,
  options,
  value,
}) => (
  <RadioOptions
    listClassName={block('list')}
    optionClassName={block('option')}
    options={CAPTIONS_FORMAT_OPTIONS.filter(opt => options.includes(opt.value))}
    {...{ onChange, value }}
  />
);

export default CaptionsFormatSelector;
