import * as React from 'react';

import { IconProps } from './types';

const LinkedinGhost: React.SFC<IconProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" {...props}>
    <path fill="#e7e2dc" d="M0 0h128v128H0z" />
    <path
      fill="#788fa5"
      d="M88.41 84.67a32 32 0 10-48.82 0 66.13 66.13 0 0148.82 0z"
    />
    <path
      fill="#9db3c8"
      d="M88.41 84.67a32 32 0 01-48.82 0A66.79 66.79 0 000 128h128a66.79 66.79 0 00-39.59-43.33z"
    />
    <path
      fill="#56687a"
      d="M64 96a31.93 31.93 0 0024.41-11.33 66.13 66.13 0 00-48.82 0A31.93 31.93 0 0064 96z"
    />
  </svg>
);

export default LinkedinGhost;
