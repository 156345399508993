import _ from 'underscore';
import { UserPrefVideoSize } from 'redux/middleware/api/headliner-user-service';
import {
  AspectRatioName,
  FrameQuality,
  FrameRate,
  FrameSize,
  FullEpisodeFrameRate,
  VideoPreferenceOption,
} from 'types';

export const frameRateOptions: VideoPreferenceOption<FrameRate>[] = [
  {
    displayName: '24 FPS',
    value: 24,
  },
  {
    displayName: '25 FPS',
    value: 25,
  },
  {
    displayName: '30 FPS',
    value: 30,
  },
];

export const fullEpisodeFrameRateOptions: VideoPreferenceOption<
  FullEpisodeFrameRate
>[] = [
  {
    displayName: '24 FPS',
    value: 24,
  },
  {
    displayName: '25 FPS',
    value: 25,
  },
];

export const frameQualityOptions: VideoPreferenceOption<FrameQuality>[] = [
  {
    displayName: 'Low',
    value: 50,
  },
  {
    displayName: 'Medium',
    value: 75,
  },
  {
    displayName: 'High',
    value: 100,
  },
];

export const frameSizeOptions: VideoPreferenceOption<FrameSize>[] = [
  {
    displayName: 'HD (720p)',
    value: 'hd',
  },
  {
    displayName: 'Full HD (1080p)',
    value: 'fullHd',
  },
];

export function getOptionDisplayName(
  options: VideoPreferenceOption[],
  value: string | number,
) {
  const defaultValue = _.findWhere(options, { value });
  return defaultValue?.displayName;
}

export function getVideoResolution(
  videoSizeKey: FrameSize,
  aspectRatioName: AspectRatioName,
  videoSizes: UserPrefVideoSize[],
) {
  const videoSize = videoSizes?.find(size => size.name === videoSizeKey);
  return videoSize?.[aspectRatioName];
}
