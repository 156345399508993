import * as tinymce from 'tinymce';
import { isString } from 'underscore';

import { parseTextShadowString } from 'utils/ui';

interface ITextShadow {
  blur: number | string;
  color: string;
  x: number | string;
  y: number | string;
}

interface IComputedTextShadow {
  blur: number;
  color: string;
  x: number;
  y: number;
}

const DEFAULT_TEXT_SHADOW: Readonly<ITextShadow> = {
  blur: 0,
  color: 'transparent',
  x: 0,
  y: 0,
};

function convertNumericValue(
  val: string | number = 0,
  field: 'x' | 'y' | 'blur',
) {
  if (isString(val)) return val;
  if (field === 'x' || field === 'y') return `${val}em`;
  return `${val}px`;
}

// This must be a function, because tinymce will call it with new!
tinymce.PluginManager.add('textshadow', function TextshadowPlugin(editor) {
  editor.on('init', () => {
    editor.formatter.register('textshadow', {
      inline: 'span',
      styles: {
        textShadow: '%color %x %y %blur',
      },
    });

    editor.addCommand('textshadow', (__, value: Partial<ITextShadow> = {}) => {
      const valueWithDefaults = { ...DEFAULT_TEXT_SHADOW, ...value };
      editor.formatter.apply('textshadow', {
        blur: convertNumericValue(valueWithDefaults.blur, 'blur'),
        color: valueWithDefaults.color,
        x: convertNumericValue(valueWithDefaults.x, 'x'),
        y: convertNumericValue(valueWithDefaults.y, 'y'),
      });
      return true;
    });

    editor.addQueryValueHandler('textshadow', () => {
      const el = editor.selection.getStart();
      const shadow = editor.dom.getStyle(el as any, 'text-shadow', true);
      const fontSize = parseFloat(
        editor.dom.getStyle(el as any, 'font-size', true),
      );

      if (shadow === 'none') return DEFAULT_TEXT_SHADOW;
      const parsedShadow = parseTextShadowString(shadow);
      return {
        ...parsedShadow,
        x: parsedShadow.x / fontSize,
        y: parsedShadow.y / fontSize,
      };
    });
  });
});

declare module 'tinymce' {
  export interface Editor extends util.Observable {
    execCommand(
      command: 'textshadow',
      ui: boolean,
      value: Partial<ITextShadow>,
    ): boolean;
    queryCommandValue(command: 'textshadow'): IComputedTextShadow;
  }
}

export { ITextShadow as TextShadow };
