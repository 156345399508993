import cn from 'classnames';
import React from 'react';
import bem from 'utils/bem';

const block = bem('heading');

type HeadingElementProps = Omit<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >,
  'ref'
>;

export interface HeadingProps extends HeadingElementProps {
  level?: number;
  as?: keyof JSX.IntrinsicElements;
}

const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  (props, ref) => {
    const { className, level, as, ...rest } = props;
    const HTag = as ?? (`h${level}` as any);
    return (
      <HTag
        {...rest}
        className={cn(block({ [`level-${level}`]: true }), className)}
        ref={ref}
      />
    );
  },
);

Heading.defaultProps = {
  level: 1,
};

export default Heading;
