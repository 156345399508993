import * as React from 'react';
import { useSelector } from 'react-redux';
import { AutomatedWizardStatus } from 'redux/modules/automated-wizard/constants';
import { statusSelector } from 'redux/modules/automated-wizard/selectors';
import WizardProgressStep, {
  WizardSubmitProgress,
} from '../WizardProgressStep';

interface Props {
  onCompleted: () => void;
}

const { useMemo } = React;

export const AutomatedWizardProgressStep: React.FC<Props> = props => {
  const { onCompleted } = props;
  const status = useSelector(statusSelector);
  const completedSteps: WizardSubmitProgress[] = useMemo(() => {
    switch (status) {
      case AutomatedWizardStatus.CREATING_CONFIGURATION: {
        return [WizardSubmitProgress.AUDIO_UPLOADING];
      }
      case AutomatedWizardStatus.FINISHED: {
        return [
          WizardSubmitProgress.AUDIO_UPLOADING,
          WizardSubmitProgress.PROJECT_CREATING,
        ];
      }
      default: {
        return [];
      }
    }
  }, [status]);
  return (
    <WizardProgressStep
      steps={[
        WizardSubmitProgress.AUDIO_UPLOADING,
        WizardSubmitProgress.PROJECT_CREATING,
      ]}
      completedSteps={completedSteps}
      onCompleted={onCompleted}
    />
  );
};

export default AutomatedWizardProgressStep;
