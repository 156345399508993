import React from 'react';
import CardCta, { Variant } from 'components/CardCta/CardCta';
import { block } from './utils';

export interface CTAButton {
  id: string;
  icon: JSX.Element;
  label: string;
  description: string;
  action: () => void;
  variant?: Variant;
}

export interface CTAButtonsModalContentsProps {
  buttons: CTAButton[];
}

const CTAButtonsModalContents: React.FC<CTAButtonsModalContentsProps> = props => {
  const { buttons } = props;

  return (
    <div className={block('cta-col')}>
      <div className={block('cta-content')}>
        <div className={block('cta')}>
          {buttons.map(button => {
            const { id, label, description, icon, action, variant } = button;

            return (
              <CardCta
                key={id}
                label={label}
                description={<span>{description}</span>}
                icon={icon}
                onClick={action}
                variant={variant}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CTAButtonsModalContents;
