import cn from 'classnames';
import * as React from 'react';

import Button from 'components/Button';
import { IconicOptionProps } from '../../types';
import { iconicSelectorBlock as block } from '../../utils';

const { useCallback } = React;

const IconicOption: React.FC<IconicOptionProps> = ({
  className,
  color,
  href,
  icon,
  index,
  label,
  onClick,
  value,
}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<any>) => {
      onClick(value, e);
    },
    [onClick, value],
  );

  return (
    <Button
      className={cn(block('option'), className)}
      href={href}
      onClick={handleClick}
    >
      <div
        className={block('option-icon-container', {
          [`${index}`]: index !== undefined,
        })}
        style={{ backgroundColor: color }}
      >
        {icon}
      </div>
      <span className={block('option-label')}>{label}</span>
    </Button>
  );
};

export default IconicOption;
