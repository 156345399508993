import bem from 'utils/bem';

export const wizardBlock = bem('automation-wizard');
export const templateSelectorBlock = bem('automation-template-selector');
export const optionsCardBlock = bem('autogram-options-card');
export const autogramSuccessBlock = bem('autogram-success');
export const customizeStepBlock = bem('automation-customize-step');
export const autogramLongVideoOptionsBlock = bem('autogram-long-video-options');
export const destinationStepBlock = bem('destination-step');

type CanLoadInitialStepOptions = {
  podcastId?: string;
  workflowId?: number;
};

export function canLoadInitialStepIndex(
  stepIndex: number,
  opts?: CanLoadInitialStepOptions,
): boolean {
  if (stepIndex === 0) {
    // we can always load the first step - that's the default behavior of the wizard
    return true;
  }

  if (stepIndex === 1) {
    // language step - we need to at least have a podcast.  podcast id is a string
    return !!opts?.podcastId;
  }

  // from this point forward, we need to have a workflow id, which should have
  // all of the information needed for the rest of the steps.
  // workflowId is a number, but the id of a valid workflow will never be 0,
  // so converting to boolean with !! is safe
  return !!opts?.workflowId;
}
