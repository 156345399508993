import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import FadingScrollBars from 'components/FadingScrollBars';
import AICaptionsUpsellModal from 'containers/AICaptionsUpsellModal';
import FacebookPostModal from 'containers/FacebookPostModal';
import { SocialPostCaptionInfoPlatform } from 'redux/middleware/api/podcast-service';
import {
  canGenerateSocialPostCaptionsSelector,
  createSocialPostCaptions,
  hasSocialPostCaptionInfoSelector,
  podcastEpisodeIdSelector,
} from 'redux/modules/download';
import { block } from '../../utils';
import DownloadButton from '../DownloadButton';
import FacebookPostButton from '../FacebookPostButton';
import InstagramPostButton from '../InstagramPostButton';
import LinkedinPostButton from '../LinkedinPostButton';
import ThreadsPostButton from '../ThreadsPostButton';
import TiktokPostButton from '../TiktokPostButton';
import TwitterPostButton from '../TwitterPostButton';
import YoutubePostButton from '../YoutubePostButton';
import { SocialSectionProvider } from './SocialSectionContext';
import useSocialIntegration, {
  SocialIntegration,
} from './useSocialIntegration';

const { useCallback, useState } = React;

export interface DownloadPageSocialSectionProps {
  videoDurationMillis: number;
  videoFileName?: string;
}

const DownloadPageSocialSection: React.SFC<DownloadPageSocialSectionProps> = ({
  videoDurationMillis,
  videoFileName,
}) => {
  const dispatch = useDispatch();
  const episodeId = useSelector(podcastEpisodeIdSelector);
  const hasSocialPostCaptionInfo = useSelector(
    hasSocialPostCaptionInfoSelector,
  );
  const canGenerateSocialPostCaptions = useSelector(
    canGenerateSocialPostCaptionsSelector,
  );
  const socialIntegrations = useSocialIntegration();
  const isSingle = socialIntegrations.length === 1;

  const durationSeconds = videoDurationMillis / 1000;
  const [activeModal, setActiveModal] = useState<
    SocialPostCaptionInfoPlatform | 'AICaptionsUpsell'
  >();
  const handleModalHide = useCallback(() => setActiveModal(undefined), []);

  const handleToggleModalVisibility = (
    socialModal: SocialPostCaptionInfoPlatform | 'AICaptionsUpsell',
    show = true,
  ) => {
    setActiveModal(show ? socialModal : undefined);
  };

  const handleUnlockAICaptions = useCallback((): void => {
    setActiveModal('AICaptionsUpsell');
  }, []);

  const handleCreateSocialPostCaptions = useCallback((): void => {
    if (canGenerateSocialPostCaptions && !hasSocialPostCaptionInfo) {
      dispatch(createSocialPostCaptions(episodeId));
    }
  }, [
    canGenerateSocialPostCaptions,
    dispatch,
    episodeId,
    hasSocialPostCaptionInfo,
  ]);

  return (
    <SocialSectionProvider
      allowAiDisclaimer={canGenerateSocialPostCaptions}
      toggleModalVisibility={handleToggleModalVisibility}
      onUnlockAICaptions={handleUnlockAICaptions}
    >
      <div className={block('social-section', { single: isSingle })}>
        <FadingScrollBars horizontal sideFaderClassName={block('side-fader')}>
          <div className={block('social-buttons')}>
            {socialIntegrations.includes(SocialIntegration.TWITTER) && (
              <TwitterPostButton videoDurationSec={durationSeconds} />
            )}

            {socialIntegrations.includes(SocialIntegration.FACEBOOK) && (
              <FacebookPostButton
                videoDurationSec={durationSeconds}
                onAuthenticatedClick={() =>
                  handleToggleModalVisibility('facebook')
                }
              />
            )}

            {socialIntegrations.includes(SocialIntegration.YOUTUBE) && (
              <YoutubePostButton />
            )}

            {socialIntegrations.includes(SocialIntegration.LINKEDIN) && (
              <LinkedinPostButton videoDurationSec={durationSeconds} />
            )}

            {socialIntegrations.includes(SocialIntegration.TIKTOK) && (
              <TiktokPostButton videoDurationSec={durationSeconds} />
            )}

            {socialIntegrations.includes(SocialIntegration.INSTAGRAM) && (
              <InstagramPostButton videoDurationSec={durationSeconds} />
            )}

            {socialIntegrations.includes(SocialIntegration.THREADS) && (
              <ThreadsPostButton videoDurationSec={durationSeconds} />
            )}
          </div>
        </FadingScrollBars>

        <div className={block('download-button')}>
          {socialIntegrations.includes(SocialIntegration.DIRECT_DOWNLOAD) && (
            <DownloadButton fileName={videoFileName} single />
          )}
        </div>

        <FacebookPostModal
          show={activeModal === 'facebook'}
          onHide={handleModalHide}
        />

        <AICaptionsUpsellModal
          show={activeModal === 'AICaptionsUpsell'}
          onPlanUpgrade={handleCreateSocialPostCaptions}
        />
      </div>
    </SocialSectionProvider>
  );
};

export default DownloadPageSocialSection;
