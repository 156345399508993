import { Tier } from 'redux/middleware/api/plan-service/types';
import BasicPlan from '../Plan/BasicPlan/BasicPlan';
import FreePlan from '../Plan/FreePlan/FreePlan';
import ProPlan from '../Plan/ProPlan';
import UnlimitedPlan from '../Plan/UnlimitedPlan';
import PlanSelector, { PlanSelectorProps } from './PlanSelector';
import PlanSelectorPlanList, {
  PlanSelectorPlanListProps,
} from './PlanSelectorPlanList';
import withPlanSelector, {
  InjectedWithPlanSelectorProps,
} from './withPlanSelector';

const PlanSelectorBasicPlan = withPlanSelector(Tier.BASIC, 'Basic')(BasicPlan);
const PlanSelectorProPlan = withPlanSelector(Tier.PRO, 'Pro')(ProPlan);
const PlanSelectorUnlimitedPlan = withPlanSelector(
  Tier.UNLIMITED,
  'Unlimited',
)(UnlimitedPlan);

interface PlanSelectorComponents {
  BasicPlan: typeof PlanSelectorBasicPlan;
  FreePlan: typeof FreePlan;
  PlanList: typeof PlanSelectorPlanList;
  ProPlan: typeof PlanSelectorProPlan;
  UnlimitedPlan: typeof PlanSelectorUnlimitedPlan;
}

const PlanSelectorWithComponents: any = PlanSelector;
PlanSelectorWithComponents.PlanList = PlanSelectorPlanList;
PlanSelectorWithComponents.FreePlan = FreePlan;
PlanSelectorWithComponents.BasicPlan = PlanSelectorBasicPlan;
PlanSelectorWithComponents.ProPlan = PlanSelectorProPlan;
PlanSelectorWithComponents.UnlimitedPlan = PlanSelectorUnlimitedPlan;

export default PlanSelectorWithComponents as typeof PlanSelector &
  PlanSelectorComponents;

export {
  InjectedWithPlanSelectorProps,
  PlanSelector,
  PlanSelectorPlanList,
  PlanSelectorPlanListProps,
  PlanSelectorProps,
};
