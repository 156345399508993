import { useMachine } from '@xstate/react';
import { assign, Machine } from 'xstate';

type NavigationMachineEventType =
  | 'ADJUST_CLICK'
  | 'CLIPPER_CLOSE'
  | 'CUSTOM_CLICK'
  | 'DATA_LOAD_SUCCESS'
  | 'DATA_LOAD_FAILURE'
  | 'DISLIKE_CLIP'
  | 'INTRO_EXIT'
  | 'INTRO_EXITED'
  | 'SUBMIT_SUCCESS';

interface Event<T extends NavigationMachineEventType> {
  payload?: any;
  type: T;
}

interface IntroExitedEvent extends Event<'INTRO_EXITED'> {
  payload: {
    suggestionCount: number;
  };
}

interface DislikeClipEvent extends Event<'DISLIKE_CLIP'> {
  payload: {
    suggestionCount: number;
  };
}

export type NavigationMachineEvent =
  | Event<'ADJUST_CLICK'>
  | Event<'CLIPPER_CLOSE'>
  | Event<'CUSTOM_CLICK'>
  | Event<'DATA_LOAD_SUCCESS'>
  | Event<'DATA_LOAD_FAILURE'>
  | Event<'INTRO_EXIT'>
  | IntroExitedEvent
  | Event<'SUBMIT_SUCCESS'>
  | DislikeClipEvent;

export interface NavigationMachineContextType {
  clipAdjusted: boolean | undefined;
}

interface NavigationMachineSchema {
  states: {
    loading: {};
    intro: {
      states: {
        exiting: {};
        idle: {};
      };
    };
    select: {};
    clip: {};
    export: {};
    error: {};
  };
}

const hasNoSuggestions = (
  _: NavigationMachineContextType,
  event: NavigationMachineEvent,
) => {
  if (event.type !== 'INTRO_EXITED' && event.type !== 'DISLIKE_CLIP') {
    return undefined;
  }

  return event.payload.suggestionCount === 0;
};

const navigationMachine = Machine<
  NavigationMachineContextType,
  NavigationMachineSchema,
  NavigationMachineEvent
>(
  {
    id: 'nav',
    initial: 'loading',
    context: {
      clipAdjusted: undefined,
    },
    states: {
      loading: {
        on: {
          DATA_LOAD_FAILURE: 'error',
          DATA_LOAD_SUCCESS: 'intro',
        },
      },
      intro: {
        initial: 'idle',
        states: {
          idle: {
            on: {
              INTRO_EXIT: 'exiting',
            },
          },
          exiting: {
            on: {
              INTRO_EXITED: [
                { target: '#nav.clip', cond: 'hasNoSuggestions' },
                { target: '#nav.select' },
              ],
            },
          },
        },
      },
      clip: {
        on: {
          CLIPPER_CLOSE: 'select',
          SUBMIT_SUCCESS: 'export',
        },
        entry: [
          assign({
            clipAdjusted: true,
          }),
        ],
      },
      select: {
        on: {
          ADJUST_CLICK: {
            target: 'clip',
          },
          CUSTOM_CLICK: {
            target: 'clip',
          },
          DISLIKE_CLIP: {
            actions: assign({
              clipAdjusted: undefined,
            }),
            target: 'clip',
            cond: 'hasNoSuggestions',
          },
          SUBMIT_SUCCESS: {
            target: 'export',
          },
        },
        entry: [
          assign({
            clipAdjusted: false,
          }),
        ],
      },
      error: {
        type: 'final',
      },
      export: {
        type: 'final',
      },
    },
  },
  {
    guards: {
      hasNoSuggestions,
    },
  },
);

export function useNavigation() {
  return useMachine(navigationMachine);
}

export default navigationMachine;
