import { createSelector } from 'reselect';
import { isUndefined } from 'underscore';

import { videoSearchEnginesOverrideSelector } from 'redux/modules/display-pref/selectors';
import { isFreeSelector } from 'redux/modules/pricing';
import { State } from 'redux/types';
import { getValue } from 'utils/collections';
import { capitalize } from 'utils/string';
import { VideoSearchState } from './types';

const VIDEO_ENGINES = ['pixabay', 'textToVideo'];
const PRO_VIDEO_SEARCH_ENGINES = [];

export const videoSearchSelector = (state: State): VideoSearchState =>
  state.get('videoSearch');

const pixabaySelector = createSelector(videoSearchSelector, videoSearch =>
  getValue(videoSearch, 'pixabay'),
);

const engineAccessSelector = createSelector(
  videoSearchEnginesOverrideSelector,
  videoEngineOverrides =>
    videoEngineOverrides
      ? videoEngineOverrides
          .toJS()
          .filter(engine => VIDEO_ENGINES.indexOf(engine))
      : VIDEO_ENGINES,
);

export const searchResultsSelector = createSelector(
  [pixabaySelector, engineAccessSelector, isFreeSelector],
  (pixabay, engineAccess, isFree) => {
    const engines = { pixabay };
    return Object.keys(engines)
      .filter(
        name => engineAccess.indexOf(name) >= 0 && !isUndefined(engines[name]),
      )
      .filter(name => !isFree || !PRO_VIDEO_SEARCH_ENGINES.includes(name))
      .reduce((acc, key) => {
        const engine = engines[key];
        const data = getValue(engine, 'data');

        acc[key] = {
          data:
            data &&
            data.map(d => ({
              aspectRatio: {
                height: getValue(d, ['video', 'aspectRatioHeight']),
                width: getValue(d, ['video', 'aspectRatioWidth']),
              },
              durationMillis: getValue(d, ['video', 'durationMillis']),
              poster: getValue(d, ['previewThumbnail', 'url']),
              previewSrc: getValue(d, ['previewVideo', 'url']),
              src: getValue(d, ['video', 'url']),
            })),
          displayName: capitalize(key),
          error: engine.get('error'),
          isSearching: engine.get('isFetching'),
          pro: isFree && PRO_VIDEO_SEARCH_ENGINES.includes(key),
          query: engine.get('q'),
          totalResults: engine.get('totalResults'),
        };
        return acc;
      }, {});
  },
);

export const videoSearchQueriesSelector = createSelector(
  pixabaySelector,
  pixabay => {
    const query = pixabay && pixabay.q;
    return query ? [query] : [];
  },
);

export const defaultVideoSearchEngineSelector = createSelector(
  [engineAccessSelector, isFreeSelector],
  (engineAccess, isFree) => {
    // textToVideo is the default engine by default
    // if user's plan is FREE, none of the engines in PRO_VIDEO_SEARCH_ENGINES cannot be selected as default
    const defaultEngine = 'textToVideo';
    if (!isFree) return defaultEngine;

    const enabledEngines = engineAccess.filter(
      engine => isFree && !PRO_VIDEO_SEARCH_ENGINES.includes(engine),
    );

    return enabledEngines && enabledEngines[0];
  },
);
