import React from 'react';
import { useDispatch } from 'react-redux';

import SteppedModal from 'components/SteppedModal';
import { IStep } from 'components/SteppedModal/types';
import { onYouTubeAccountVerificationCheck } from 'redux/modules/mixpanel';
import { YouTubeAccountVerificationCheckSource } from 'redux/modules/mixpanel/types';
import { showError } from 'redux/modules/notification';
import { validateYoutubeAccountElegibility } from 'redux/modules/social';
import { Dispatch } from 'redux/types';

import useChannelNeededStep from './steps/useChannelNeededStep';
import useDisallowedAccountStep from './steps/useDisallowedAccountStep';
import useElegibleAccountStep from './steps/useElegibleAccountStep';
import useVerificationProgressStep from './steps/useVerificationProgressStep';
import useVerificationStep from './steps/useVerificationStep';

interface UseYoutubeAccountVerificationConfig {
  modalRef: React.MutableRefObject<SteppedModal>;
  onAccountVerificationFailure: () => void;
  onAccountVerificationSuccess: () => void;
  onHideModal: () => void;
  onSwitchAccount: () => void;
  source: YouTubeAccountVerificationCheckSource;
}

interface UseYoutubeAccountVerification {
  onAccountVerification: () => Promise<void>;
  verificationSteps: IStep[];
}

const useYoutubeAccountVerification = (
  config: UseYoutubeAccountVerificationConfig,
): UseYoutubeAccountVerification => {
  const {
    modalRef,
    onAccountVerificationFailure,
    onAccountVerificationSuccess,
    onHideModal,
    onSwitchAccount,
    source,
  } = config;

  const dispatch = useDispatch<Dispatch>();

  const handleClickChannelEducation = React.useCallback(() => {
    onHideModal();
  }, [onHideModal]);

  const handleDisallowedAccount = React.useCallback(() => {
    modalRef.current?.stepHistory.replace('disallowed-account');
  }, [modalRef]);

  const handleNoChannelAccount = React.useCallback(() => {
    modalRef.current?.stepHistory.replace('no-channel');
  }, [modalRef]);

  const handleElegibleAccount = React.useCallback(() => {
    modalRef.current?.stepHistory.replace('elegible-account');
  }, [modalRef]);

  const handleStartVerification = React.useCallback(() => {
    modalRef.current?.stepHistory.replace('verify-account');
    dispatch(
      onYouTubeAccountVerificationCheck({
        source,
        status: 'AwaitingVerification',
      }),
    );
  }, [dispatch, modalRef, source]);

  const handleVerificationError = React.useCallback(() => {
    dispatch(
      showError({
        message: 'YouTube account verification failed',
        code: 'IN019',
      }),
    );
    onAccountVerificationFailure();
  }, [dispatch, onAccountVerificationFailure]);

  // This function is invoked externally and runs the verification flow. During the
  // verification the progress step is shown and when resolved the proper step set
  const handleAccountVerification = React.useCallback(async () => {
    modalRef.current?.stepHistory.replace('verification-in-progress');
    try {
      const longUploadsStatus = await dispatch(
        validateYoutubeAccountElegibility(),
      );

      // The action to take when verifying the account is defined by the longUploadsStatus
      // value obtained from the channel request.
      // Only when allowed the success callback will be invoked.
      switch (longUploadsStatus) {
        case 'allowed':
          onAccountVerificationSuccess();
          break;
        case 'disallowed':
          handleDisallowedAccount();
          dispatch(
            onYouTubeAccountVerificationCheck({ source, status: 'Disallowed' }),
          );
          break;
        case 'eligible':
          handleElegibleAccount();
          dispatch(
            onYouTubeAccountVerificationCheck({ source, status: 'Eligible' }),
          );
          break;
        default:
          // As defined by the BE when the cannel validation returns no status, it can be
          // assumed that no channel is created for the account.
          handleNoChannelAccount();
          dispatch(
            onYouTubeAccountVerificationCheck({
              source,
              status: 'ChannelNeeded',
            }),
          );
          break;
      }
    } catch {
      handleVerificationError();
    }
  }, [
    dispatch,
    handleDisallowedAccount,
    handleElegibleAccount,
    handleNoChannelAccount,
    handleVerificationError,
    modalRef,
    onAccountVerificationSuccess,
    source,
  ]);

  const disallowedAccount = useDisallowedAccountStep({
    onSwitchAccount,
  });

  const elegibleAccount = useElegibleAccountStep({
    onStartVerification: handleStartVerification,
    onSwitchAccount,
  });

  const noChannel = useChannelNeededStep({
    onClickEducationLink: handleClickChannelEducation,
    onSwitchAccount,
  });

  const verifyAccount = useVerificationStep({
    onAccountDisallowed: handleDisallowedAccount,
    onAccountVerified: onAccountVerificationSuccess,
    onSwitchAccount,
  });

  const verificationInProgress = useVerificationProgressStep();

  return {
    onAccountVerification: handleAccountVerification,
    verificationSteps: [
      disallowedAccount,
      elegibleAccount,
      noChannel,
      verifyAccount,
      verificationInProgress,
    ],
  };
};

export default useYoutubeAccountVerification;
