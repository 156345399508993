import * as React from 'react';
import { noop } from 'underscore';

import { cardWizardBlock } from '../../utils';

const { useCallback } = React;

export interface StepperIndicatorProps {
  active?: boolean;
  completed?: boolean;
  onClick?: (params: any) => void;
  params?: any;
}

const StepperIndicator: React.FC<StepperIndicatorProps> = ({
  active,
  completed,
  onClick,
  params,
}) => {
  const handleClick = useCallback(() => {
    onClick(params);
  }, [onClick, params]);

  return (
    <button
      className={cardWizardBlock('indicator', { active, completed })}
      disabled={!completed}
      onClick={handleClick}
      type="button"
    >
      <div className={cardWizardBlock('indicator-fill')} />
    </button>
  );
};

StepperIndicator.defaultProps = {
  onClick: noop,
};

export default StepperIndicator;
