import * as React from 'react';
import List from 'components/List';

import EpisodeListEpisodeRow from '../containers/EpisodeListEpisodeRow';
import { episodeListBlock as block } from '../utils';
import EpisodeListAutomationsRow from './EpisodeListAutomationsRow';

const { useCallback, useState } = React;

export interface AutomationCMSEpisodeListProps {
  episodes: string[];
  workflows: number[];
}

const AutomationCMSEpisodeList: React.FC<AutomationCMSEpisodeListProps> = ({
  episodes,
  workflows,
}) => {
  const [selectedEpisode, setSelectedEpisode] = useState<string>();

  const handleRowClick = useCallback(
    id => {
      if (id === selectedEpisode) {
        setSelectedEpisode(undefined);
      } else {
        setSelectedEpisode(id);
      }
    },
    [selectedEpisode],
  );

  const handleAutomationsRowClick = useCallback(() => {
    handleRowClick('automations');
  }, [handleRowClick]);

  return (
    <List className={block()}>
      <EpisodeListAutomationsRow
        active={selectedEpisode === 'automations'}
        workflows={workflows}
        onClick={handleAutomationsRowClick}
      />
      {episodes.map(id => (
        <EpisodeListEpisodeRow
          active={id === selectedEpisode}
          episodeId={id}
          key={id}
          onClick={handleRowClick}
          workflows={workflows}
        />
      ))}
    </List>
  );
};

AutomationCMSEpisodeList.defaultProps = {
  episodes: [],
  workflows: [],
};

export default AutomationCMSEpisodeList;
