import { getTextPresets as getTextPresetsAction } from 'redux/middleware/api/headliner-user-service';
import { ThunkAction } from 'redux/types';

import { Type } from './action-types';

export const getTextPresets = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  dispatch({
    type: Type.TEXT_PRESETS_REQUEST,
  });

  try {
    const textPresets = await dispatch(getTextPresetsAction());
    dispatch({
      type: Type.TEXT_PRESETS_REQUEST_SUCCESS,
      payload: { presets: textPresets.response.result },
    });
  } catch (err) {
    dispatch({
      type: Type.TEXT_PRESETS_REQUEST_FAILURE,
    });
  }
};
