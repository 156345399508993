import * as React from 'react';

function EmojiSad(props) {
  return (
    <svg width={100} height={100} viewBox="0 0 100 100" {...props}>
      <path
        fill="#6E6EDE"
        d="M50 0C22.379 0 0 22.379 0 50s22.379 50 50 50 50-22.379 50-50S77.621 0 50 0zm0 90.323C27.762 90.323 9.677 72.238 9.677 50S27.762 9.677 50 9.677 90.323 27.762 90.323 50 72.238 90.323 50 90.323zm-9.677-50c0-3.569-2.883-6.452-6.452-6.452s-6.452 2.883-6.452 6.452c0 3.568 2.883 6.451 6.452 6.451s6.452-2.883 6.452-6.451zm25.806-6.452a6.445 6.445 0 00-6.452 6.452c0 3.568 2.883 6.451 6.452 6.451s6.452-2.883 6.452-6.451a6.445 6.445 0 00-6.452-6.452zM50 56.451c-7.177 0-17.903 4.295-19.315 12.34-.403 2.378 1.815 4.334 4.134 3.649 6.29-1.936 11.975-3.085 15.181-3.085 3.206 0 8.891 1.149 15.181 3.085 2.299.705 4.537-1.27 4.134-3.65C67.903 60.746 57.177 56.452 50 56.452z"
      />
    </svg>
  );
}

export default EmojiSad;
