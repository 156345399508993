import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import { Col } from 'components/Grid';
import LoadingOverlay from 'components/LoadingOverlay';
import { MixedPartial } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { DEFAULT_SLIDE_TRANSITION } from 'utils/embed/slideshow';
import SlideForm, { SlideFormData, SlideFormProps } from './SlideForm';
import SlidePlacer, { SlidePlacerProps, SlidePlacerValue } from './SlidePlacer';
import { block } from './utils';

interface AnyObject {
  [k: string]: any;
}

interface State extends SlideFormData {
  placement: SlidePlacerValue;
}

type PickedFormProps = Pick<
  SlideFormProps,
  'minDurationMillis' | 'onCropperOpen'
>;
type PickedPlacerProps = Pick<
  SlidePlacerProps,
  'defaultFitType' | 'onStatusChange'
>;

export interface SlideEditorProps extends PickedFormProps, PickedPlacerProps {
  aspectRatio: number;
  className?: string;
  defaultState: MixedPartial<State, 'effect' | 'transition'>;
  disabled?: boolean;
  onMount?: () => void;
  isSaving?: boolean;
  params?: AnyObject;
  slideId?: string;
  src?: SlidePlacerProps['source'];
  style?: React.CSSProperties;
}

export type SlideEditorValues = State;

export default class SlideEditor extends React.Component<
  SlideEditorProps,
  State
> {
  public static defaultProps: Partial<SlideEditorProps> = {
    disabled: false,
    onMount: noop,
  };

  public state: Readonly<State> = {
    effect: 'none',
    transition: DEFAULT_SLIDE_TRANSITION,
    ...this.props.defaultState,
  };

  public componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  private handleFormChange = (data: SlideFormData) => this.setState(data);

  private handlePlacementChange = (placement: SlidePlacerValue) => {
    this.setState(current => {
      const changed = Object.keys(placement).some(
        key => !placement[key].eq(current.placement?.[key]),
      );
      if (changed) {
        return { placement };
      }
      return undefined;
    });
  };

  public getValues(): SlideEditorValues {
    const { params } = this.props;

    return {
      ...this.state,
      ...params,
    };
  }

  public render() {
    const {
      aspectRatio,
      className,
      defaultFitType,
      disabled,
      isSaving,
      minDurationMillis,
      onCropperOpen,
      onStatusChange,
      slideId,
      src,
      style,
    } = this.props;
    const { placement, blurRadius } = this.state;

    const ratioName = getAspectRatioName(aspectRatio);

    return (
      <div className={cn(block(), className)} style={style}>
        {isSaving && <LoadingOverlay title="Saving image to project" />}
        <Col xs={7} className={block('cropper-col')} divider>
          <div
            className={block('workspace-container', {
              [ratioName]: !!ratioName,
            })}
          >
            <SlidePlacer
              aspectRatio={aspectRatio}
              defaultFitType={defaultFitType}
              onChange={this.handlePlacementChange}
              onStatusChange={onStatusChange}
              slideId={slideId}
              source={src}
              value={placement}
              blurRadius={blurRadius}
            />
          </div>
        </Col>
        <Col xs={5} className={block('form-col')} divider>
          <SlideForm
            onChange={this.handleFormChange}
            value={this.state}
            {...{ disabled, minDurationMillis, onCropperOpen }}
          />
        </Col>
      </div>
    );
  }
}
