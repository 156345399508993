import { SoundwaveType } from 'types';
import {
  Blob,
  Bricks,
  Burst,
  Curve,
  Equalizer,
  Line,
  LinearDots,
  Pixel,
  Pulse,
  RoundBars,
  Wave,
  WaveBars,
  WideRoundBars,
} from './components/Waveforms';

const waveForms = {
  blob: Blob,
  bricks: Bricks,
  burst: Burst,
  curve: Curve,
  equalizer: Equalizer,
  line: Line,
  linearDots: LinearDots,
  pixel: Pixel,
  pulse: Pulse,
  roundBars: RoundBars,
  wave: Wave,
  waveBars: WaveBars,
  wideRoundBars: WideRoundBars,
};

const LOCK_ASPECT_RATIO: SoundwaveType[] = [
  'linearDots',
  'blob',
  'burst',
  'pulse',
];

export const isAspectRatioLocked = (type: SoundwaveType) => {
  return LOCK_ASPECT_RATIO.includes(type);
};

export const getWaveFormByType = (type: SoundwaveType) =>
  waveForms[type] || undefined;
