import * as React from 'react';

import { IconProps } from './types';

const Audiogram: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="6"
    viewBox="0 0 20 6"
    {...props}
  >
    <path
      d="M19.52 0a.48.48 0 0 1 .48.48v5.04a.48.48 0 1 1-.96 0l-.001-2.173H1.002L.96 3.343V5.52a.48.48 0 1 1-.96 0V.48a.48.48 0 1 1 .96 0v1.872a.505.505 0 0 1 .042-.002H19.04L19.04.48a.48.48 0 0 1 .48-.48z"
      fill="fill"
      fillRule="evenodd"
    />
  </svg>
);

export default Audiogram;
