import * as React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import LockedWatermarkSelectModalContent from './LockedWatermarkSelectModalContent';

interface Props {}

const LockedWatermarkSelectModal: React.FC<Props> = () => {
  return (
    <ConnectedModal name="AddWatermark" title="Select a watermark" size="lg">
      <LockedWatermarkSelectModalContent />
    </ConnectedModal>
  );
};

LockedWatermarkSelectModal.displayName = 'LockedWatermarkSelectModal';

export default LockedWatermarkSelectModal;
