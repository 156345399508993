import * as React from 'react';
import { useSelector } from 'react-redux';
import useDuration from 'hooks/useDuration';
import { creationConfigurationIdSelector } from 'redux/modules/wizard-export/selectors';
import { WizardExportVideoStep } from '../ExportVideoStep';

interface Props {
  source: File | string;
  onError: (error: Error) => void;
}

function EpisodeWizardExportVideoStep(props: Props) {
  const { source, onError } = props;
  const widgetId = useSelector(creationConfigurationIdSelector);
  const durationSec = useDuration(source);
  return (
    <WizardExportVideoStep
      widgetId={widgetId}
      estimatedDurationSec={Math.max(durationSec * 2, 60)}
      pollIntervalMillis={30000}
      onError={onError}
    />
  );
}

export default EpisodeWizardExportVideoStep;
