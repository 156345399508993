import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import useRedirectOnClose from 'hooks/useRedirectOnClose';
import FullLengthTemplateCompatibilityModalContents from './FullLengthTemplateCompatibilityModalContents';
import { block } from './utils';

const FullLengthTemplateCompatibilityModal: React.FC = () => {
  const { onClickLink: handleRedirect, onExited } = useRedirectOnClose();

  return (
    <ConnectedModal
      name="FullLengthTemplateCompatibility"
      title="Full-length templates"
      className={block()}
      onExited={onExited}
    >
      <FullLengthTemplateCompatibilityModalContents
        onRedirect={handleRedirect}
      />
    </ConnectedModal>
  );
};

export default FullLengthTemplateCompatibilityModal;
