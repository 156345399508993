import { Link, Text } from '@sparemin/blockhead';
import React from 'react';
import { block } from './utils';

export interface PrivacyPolicyMessageProps {}

const PrivacyPolicyMessage: React.FC<PrivacyPolicyMessageProps> = () => {
  return (
    <div className={block()}>
      <Text variant="legal" className={block('message')}>
        See our{' '}
        <Link href={spareminConfig.legalUrls.privacyPolicyUrl} target="__blank">
          Privacy Policy
        </Link>{' '}
        to learn more about how Headliner uses and shares information.
      </Text>
    </div>
  );
};

export default PrivacyPolicyMessage;
