import {
  ButtonBase,
  FadingContainer,
  getThemeColor,
  ListBox,
  Minus,
  Plus,
  Popover,
  PopoverProps,
  TextField,
  useTheme,
  useToggle,
} from '@sparemin/blockhead';
import * as React from 'react';

import { block, MAX_SIZE, MIN_SIZE, SIZE_OPTIONS } from './utils';

type PickedPopoverProps = Pick<PopoverProps, 'offset' | 'placement'>;

interface FontSizeInputProps extends PickedPopoverProps {
  maxSize?: number;
  minSize?: number;
  onChange: (value: number) => void;
  value: number;
}

const FontSizeInput: React.FunctionComponent<FontSizeInputProps> = props => {
  const {
    maxSize = MAX_SIZE,
    minSize = MIN_SIZE,
    onChange,
    offset,
    placement = 'bottom',
    value,
  } = props;

  const filteredSizeOptions = React.useMemo(
    () => SIZE_OPTIONS.filter(size => size >= minSize && size <= maxSize),
    [maxSize, minSize],
  );

  const {
    toggleOff: closeMenu,
    toggleOn: openMenu,
    value: menuVisible,
  } = useToggle(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const theme = useTheme();

  const handlFocus = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>): void => {
      openMenu();
      e.target.select();
    },
    [openMenu],
  );

  const handleBlur = React.useCallback(() => {
    closeMenu();
  }, [closeMenu]);

  const handleClickDecrease = React.useCallback(() => {
    onChange(value - 1);
  }, [onChange, value]);

  const handleClickIncrease = React.useCallback(() => {
    onChange(value + 1);
  }, [onChange, value]);

  const handleSizeChange = React.useCallback(
    (_, nextFontSize: string) => {
      const nextNumericValue = nextFontSize ? parseInt(nextFontSize, 10) : 0;
      // when the the input changes to a value that is below the minimum value
      // the font size is set to the minimum value and the text selection is
      // forced for allowing the user to write a brand new value
      if (nextNumericValue < minSize) {
        const minSizeStr = String(minSize);
        onChange(minSize);
        inputRef.current.value = String(minSize);
        inputRef.current.setSelectionRange(
          minSizeStr.length - 1,
          minSizeStr.length,
        );
      } else if (nextNumericValue >= minSize && nextNumericValue <= maxSize) {
        onChange(nextNumericValue);
      }
    },
    [maxSize, minSize, onChange],
  );

  const handleSizeSelectionChange = React.useCallback(
    (nextSelection: Set<string>): void => {
      const nextNumericValue = parseInt(
        nextSelection.values().next()?.value ?? '',
        10,
      );

      if (nextNumericValue >= minSize && nextNumericValue <= maxSize) {
        onChange(nextNumericValue);
      }
    },
    [maxSize, minSize, onChange],
  );

  const isIncreaseDisabled = value >= maxSize;
  const isDecreaseDisabled = value <= minSize;

  return (
    <div className={block('container')}>
      <TextField
        ref={inputRef}
        className={block('input')}
        endAdornment={
          <ButtonBase
            className={block('input-adornment', { end: true })}
            isDisabled={isIncreaseDisabled}
            onClick={handleClickIncrease}
          >
            <Plus className={block('plus-icon')} />
          </ButtonBase>
        }
        inputMode="decimal"
        label="Size"
        onBlur={handleBlur}
        onChange={handleSizeChange}
        onFocus={handlFocus}
        startAdornment={
          <ButtonBase
            className={block('input-adornment', { start: true })}
            isDisabled={isDecreaseDisabled}
            onClick={handleClickDecrease}
          >
            <Minus className={block('minus-icon')} />
          </ButtonBase>
        }
        value={String(value)}
      />
      <Popover
        className={block('size-popover')}
        isOpen={menuVisible}
        offset={offset}
        placement={placement}
        triggerElement={inputRef.current}
      >
        <FadingContainer
          axis="y"
          backgroundColor={getThemeColor('l2')({ theme })}
          threshold={0.05}
        >
          <div className={block('size-popover-content')}>
            <ListBox
              className={block('size-popover-list')}
              density="compact"
              onSelectionChange={handleSizeSelectionChange}
              selectedKeys={[String(value)]}
            >
              {filteredSizeOptions.map(option => (
                <ListBox.Item key={option}>{option}</ListBox.Item>
              ))}
            </ListBox>
          </div>
        </FadingContainer>
      </Popover>
    </div>
  );
};

export default FontSizeInput;
