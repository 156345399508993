import { createMap, DeepPartial, IAudioInfo, IImmutableMap } from 'types';
import { getValue } from 'utils/collections';
import merge from 'utils/deepmerge';
import { defaultTrackIndex } from 'utils/embed/tracks';

// TODO this should be defined by the API / redux layer (which isn't in TS yet)
type Audio = IImmutableMap<{
  audioFadeInDurationInMillis: number;
  audioFadeOutDurationInMillis: number;
  id: number;
  startMillis: number;
  endMillis: number;
  playFromMillis: number;
  language?: string;
}>;

type Recording = IImmutableMap<{
  recordingId: number;
  durationMillis: number;
}>;

const AUDIO_TRACK_INDEX = defaultTrackIndex('audio');
const DEFAULT_AUDIO_OBJECT: Partial<IAudioInfo> = {
  durationMilli: 0,
  layerId: AUDIO_TRACK_INDEX,
  playOffsetMilli: 0,
  startAtMilli: 0,
  transition: {
    in: {
      durationMilli: 0,
      effect: 'fade',
    },
    out: {
      durationMilli: 0,
      effect: 'fade',
    },
  },
};

export function formatAudioForConfig(
  audio: Audio,
  trackIndex: number = AUDIO_TRACK_INDEX,
): IAudioInfo {
  return merge(DEFAULT_AUDIO_OBJECT, {
    durationMilli: audio.get('endMillis') - audio.get('startMillis'),
    layerId: trackIndex,
    playOffsetMilli: audio.get('playFromMillis'),
    recordingId: audio.get('id'),
    startAtMilli: audio.get('startMillis'),
    language: audio.get('language'),
    transition: {
      in: {
        durationMilli: getValue(
          audio,
          ['transition', 'in', 'durationMilli'],
          0,
        ),
        effect: 'fade',
      },
      out: {
        durationMilli: getValue(
          audio,
          ['transition', 'out', 'durationMilli'],
          0,
        ),
        effect: 'fade',
      },
    },
  });
}

export function createAudioInfoFromRecording(
  recording: Recording,
  overrides: DeepPartial<IAudioInfo> = {},
) {
  return merge.all([
    DEFAULT_AUDIO_OBJECT,
    {
      durationMilli: recording.get('durationMillis'),
      playOffsetMilli: 0,
      recordingId: recording.get('recordingId'),
      startAtMilli: 0,
    },
    overrides,
  ]);
}

export function formatAudioFromConfig(audio: IAudioInfo) {
  return createMap({
    endMillis: audio.startAtMilli + audio.durationMilli,
    id: audio.recordingId,
    playFromMillis: audio.playOffsetMilli || 0,
    startMillis: audio.startAtMilli,
    language: audio.language,
    transition: {
      in: {
        durationMilli: getValue(
          audio,
          ['transition', 'in', 'durationMilli'],
          0,
        ),
        effect: 'fade',
      },
      out: {
        durationMilli: getValue(
          audio,
          ['transition', 'out', 'durationMilli'],
          0,
        ),
        effect: 'fade',
      },
    },
  });
}

export function getTrackDataFromAudioConfig(audio: IAudioInfo) {
  if (!audio) return undefined;

  return {
    audioId: audio.recordingId,
    trackIndex: audio.layerId,
  };
}

export function addDefaultAudioLayers(layers: string[]): string[] {
  if (layers.includes('audio')) {
    return layers;
  }

  return [...layers, 'audio', 'audio'];
}

export default {
  formatAudioForConfig,
  formatAudioFromConfig,
  getTrackDataFromAudioConfig,
  addDefaultAudioLayers,
};
