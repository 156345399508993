import * as React from 'react';

import VideoPlayer from 'components/VideoPlayer';
import { block } from '../utils';

export interface DownloadPreviewSectionProps {
  aspectRatioName?: string;
  videoUrl: string;
  onContextMenu?: React.MouseEventHandler<HTMLDivElement>;
}

const DownloadPreviewSection: React.SFC<DownloadPreviewSectionProps> = ({
  videoUrl,
  onContextMenu,
}) => {
  const url = new URL(videoUrl);
  url.searchParams.append('src', 'preview');
  const uniqueUrl = url.toString();

  return (
    <div className={block('preview-section')} {...{ onContextMenu }}>
      <VideoPlayer src={uniqueUrl} />
    </div>
  );
};

export default DownloadPreviewSection;
