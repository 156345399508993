import React from 'react';
import { useDispatch } from 'react-redux';
import { PodcastSubscription } from 'redux/middleware/api/podcast-service';

import { editAutomationInitAudioSelection } from 'redux/modules/edit-automation';
import { replaceModal } from 'redux/modules/modal';

import { getSubscriptionItem } from '../utils';

import EditAutomationAudioSelection from './EditAutomationAudioSelection';
import { getAudioSelectionType } from './utils';

interface EditAutomationAudioSelectionContainerProps {
  subscription?: PodcastSubscription;
}

const EditAutomationAudioSelectionContainer: React.FunctionComponent<EditAutomationAudioSelectionContainerProps> = props => {
  const { subscription } = props;

  const dispatch = useDispatch();

  const subscriptionItem = getSubscriptionItem(subscription);
  const currentSelectedKey = getAudioSelectionType(subscriptionItem);

  const handleEdit = React.useCallback(() => {
    dispatch(editAutomationInitAudioSelection(currentSelectedKey));
    dispatch(
      replaceModal({
        name: 'EditAutomationAudioSelectionTypeModal',
        params: {
          subscriptionId: subscription?.autoCreateSubscriptionId,
        },
      }),
    );
  }, [currentSelectedKey, dispatch, subscription]);

  return (
    <EditAutomationAudioSelection
      onEdit={handleEdit}
      subscriptionItem={subscriptionItem}
    />
  );
};

export default EditAutomationAudioSelectionContainer;
