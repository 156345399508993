import * as React from 'react';

import { MultiVideoCamera } from 'components/icons';
import bem from 'utils/bem';
import PaidSubscriptionSuccessMessage from './PaidSubscriptionSuccessMessage';

const block = bem('pro-success-message');

const ProSuccessMessage: React.SFC = () => (
  <PaidSubscriptionSuccessMessage
    icon={
      <MultiVideoCamera className={block('icon')} height={85} width="100%" />
    }
    message="Welcome to Headliner Pro!"
  />
);

export default ProSuccessMessage;
