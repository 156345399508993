import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  hasPodcastResultsSelector,
  isLoadingPodcastsSelector,
  searchTermSelector,
} from 'redux/modules/podcast-search';
import { State } from 'redux/types';
import PodcastSearch, { PodcastSearchProps as Props } from './PodcastSearch';

type StateProps = Pick<Props, 'defaultValue' | 'hasResults' | 'isLoading'>;

const defaultValueSelector = createSelector(
  searchTermSelector,
  searchTerm => searchTerm || '',
);

const mapStateToProps = (state: State): StateProps => ({
  defaultValue: defaultValueSelector(state),
  hasResults: hasPodcastResultsSelector(state),
  isLoading: isLoadingPodcastsSelector(state),
});

const component = connect<StateProps>(mapStateToProps, undefined, undefined, {
  forwardRef: true,
})(PodcastSearch);

export type PodcastSearchProps = React.ComponentPropsWithoutRef<
  typeof component
>;

export default component;
