import { SuperAgentStatic } from 'superagent';
import * as superagentDefaults from 'superagent-defaults';

import * as types from './types';

function getContentType(
  args: types.GetContentTypeArgs,
  request: SuperAgentStatic,
): Promise<types.GetContentTypeResult> {
  const [url] = args;
  return request
    .get('/api/v1/media/contentType')
    .query({ url })
    .then(({ body }) => body);
}

export const handle: types.IHandle = (
  method: any,
  args: any,
  token?: string,
): any => {
  const request = superagentDefaults();
  if (token) {
    request.set('Authorization', `Bearer ${token}`);
  }

  switch (method) {
    case types.ServiceMethod.GET_CONTENT_TYPE:
      return getContentType(args, request);
  }

  return undefined;
};
