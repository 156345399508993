import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { PodcastSubscription } from 'redux/middleware/api/podcast-service';
import { loadMyWorkflows } from 'redux/modules/automation-cms';
import {
  editAutomation,
  PartialUpdateAutomationData,
} from 'redux/modules/edit-automation';
import { podcastSubscriptionsSelector } from 'redux/modules/entities';
import { onEditAutomation } from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal';
import { showNotification, showSuccess } from 'redux/modules/notification';

import { Dispatch } from 'redux/types';
import { AutogramMixpanelEditSource } from 'types';
import { mapMixpanelEditEventData } from '../utils';

const createPodcastSubscriptionSelector = (id: number) =>
  createSelector([podcastSubscriptionsSelector], (subs):
    | PodcastSubscription
    | undefined => {
    if (!subs || !id) return undefined;
    return subs.get(String(id))?.toJS();
  });

const useEditAutomationModal = (subscriptionId: number) => {
  const dispatch = useDispatch<Dispatch>();

  const podcastSubscriptionSelector = React.useMemo(
    () => createPodcastSubscriptionSelector(subscriptionId),
    [subscriptionId],
  );

  const subscription = useSelector(podcastSubscriptionSelector);

  const updateWorkflowsData = React.useCallback(() => {
    // refetchs automation projects information for getting updated data
    return dispatch(loadMyWorkflows());
  }, [dispatch]);

  const onGoBackToMain = React.useCallback(() => {
    dispatch(
      replaceModal({
        name: 'EditAutomationModal',
        params: {
          subscriptionId,
        },
      }),
    );
  }, [dispatch, subscriptionId]);

  const handleUpdateSuccess = React.useCallback(async () => {
    // then it shows the notification and opens main edit automation modal again
    dispatch(
      showSuccess({
        dismissAfterSec: 5,
        message: "You'll see these changes in upcoming videos",
        title: 'Your automation has been updated',
      }),
    );
    onGoBackToMain();
  }, [dispatch, onGoBackToMain]);

  const handleUpdateError = React.useCallback(() => {
    dispatch(
      showNotification({
        message: 'Please try again or {{link}} so we can help',
        level: 'error',
        dismissAfterSec: 5,
        code: 'ER013',
        title: "Sorry, we couldn't make this update",
        type: 'help',
        actionLabel: 'contact us',
      }),
    );
  }, [dispatch]);

  const onDispatchEditAutomationEvent = React.useCallback(
    (
      updateAutomationData: PartialUpdateAutomationData,
      source: AutogramMixpanelEditSource,
    ) => {
      const mixpanelEventData = mapMixpanelEditEventData(
        source,
        updateAutomationData,
        subscription,
      );
      dispatch(onEditAutomation(mixpanelEventData));
    },
    [dispatch, subscription],
  );

  const onUpdateAutomation = React.useCallback(
    async (
      updateAutomationData: PartialUpdateAutomationData,
      source: AutogramMixpanelEditSource,
    ) => {
      try {
        // sends mixpanel event
        onDispatchEditAutomationEvent(updateAutomationData, source);
        // call api for updating the automation
        await dispatch(
          editAutomation(updateAutomationData, updateWorkflowsData),
        );
        handleUpdateSuccess();
      } catch {
        handleUpdateError();
      }
    },
    [
      dispatch,
      handleUpdateError,
      handleUpdateSuccess,
      onDispatchEditAutomationEvent,
      updateWorkflowsData,
    ],
  );

  return { onGoBackToMain, onUpdateAutomation, subscription };
};

export default useEditAutomationModal;
