import cn from 'classnames';
import React, { useState } from 'react';
import { SubscriptionPeriod } from 'blocks/Pricing/types';
import { PlanSelectorProvider } from './PlanSelectorContext';
import PlanSelectorHeader, {
  PlanSelectorHeaderProps,
} from './PlanSelectorHeader';

import { block } from './utils';

export interface PlanSelectorProps
  extends Pick<PlanSelectorHeaderProps, 'message' | 'subMessage'> {
  children?: React.ReactNode;
  className?: string;
  defaultPeriod?: SubscriptionPeriod;
}

const PlanSelector: React.FC<PlanSelectorProps> = ({
  children,
  className,
  defaultPeriod = 'yearly',
  message,
  subMessage,
}) => {
  const [period, setPeriod] = useState(defaultPeriod);

  return (
    <div className={cn(block(), className)}>
      <PlanSelectorHeader
        {...{ message, subMessage }}
        onSubscriptionPeriodChange={setPeriod}
        subscriptionPeriod={period}
      />
      <PlanSelectorProvider period={period}>{children}</PlanSelectorProvider>
    </div>
  );
};

export default PlanSelector;
