import React from 'react';
import Button from 'components/Button';
import { block } from './utils';

type Props = React.ComponentPropsWithoutRef<typeof Button>;

function NonIdealStateButton(props: Props) {
  return <Button fluid {...props} className={block('button')} />;
}

export default NonIdealStateButton;
