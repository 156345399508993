import cn from 'classnames';
import * as React from 'react';

import PostTaskBanner from '../containers/PostTaskBanner';
import { PostTaskProps } from '../types';
import { block } from '../utils';

const PostTask: React.SFC<PostTaskProps> = ({
  banner,
  className,
  footerButton,
  title,
  subTitle,
}) => (
  <div className={cn(block(), className)}>
    <div className={block('body')}>
      <div className={block('body-title')}>{title}</div>
      <div className={block('body-subtitle')}>{subTitle}</div>

      {banner}

      {footerButton && (
        <div className={block('footer-button')}>{footerButton}</div>
      )}
    </div>
  </div>
);

PostTask.defaultProps = {
  banner: <PostTaskBanner />,
};

export default PostTask;
