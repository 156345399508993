import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import NewBadge from 'components/NewBadge';
import { episodeListBlock as block } from '../../utils';

interface Props {
  active: boolean;
}

const GeneratingBadge: React.FC<Props> = props => {
  const { active } = props;
  return (
    <CSSTransition
      in={active}
      appear
      timeout={{
        enter: 300,
        exit: 600,
      }}
      classNames={block('badge')}
    >
      <NewBadge className={block('badge')}>Generating new videos</NewBadge>
    </CSSTransition>
  );
};

export default GeneratingBadge;
