import cn from 'classnames';
import * as React from 'react';

import { useDispatch } from 'react-redux';
import Modal, { ModalFooterButtonProps } from 'components/Modal';
import { hideModal } from 'redux/modules/modal';
import bem from 'utils/bem';

export interface ButtonProps extends ModalFooterButtonProps {
  key: string | number;
}

export interface Props {
  className?: string;
  children?: React.ReactNode;
  headerRow?: React.ReactNode;
  onClose?: (args: ButtonProps) => void;
  title?: string | React.ReactNode;
  titleClassName?: string;
  icon?: React.ReactNode;
  details?: React.ReactNode;
  detailsClassName?: string;
  actions?: ButtonProps[];
}

const block = bem('dialog-content');

const DialogContent: React.SFC<Props> = props => {
  const {
    actions = [],
    children,
    className,
    details,
    detailsClassName,
    headerRow,
    icon,
    onClose,
    title,
    titleClassName,
  } = props;

  const dispatch = useDispatch();
  const handleClose = onClose || ((value?: any) => dispatch(hideModal(value)));

  return (
    <>
      <Modal.Body className={cn(block('body'), className)}>
        {headerRow}
        <span className={block('icon')}>{icon}</span>
        <span className={cn(block('title'), titleClassName)}>{title}</span>
        <span className={cn(block('details'), detailsClassName)}>
          {details}
        </span>
        {children}
      </Modal.Body>
      {actions.length > 0 && (
        <Modal.Footer>
          <Modal.FooterButtons fluid>
            {actions.map(action => (
              <Modal.FooterButton
                {...action}
                onClick={(...args) => {
                  handleClose(action.params);
                  action.onClick?.(...args);
                }}
              />
            ))}
          </Modal.FooterButtons>
        </Modal.Footer>
      )}
    </>
  );
};

export default DialogContent;
