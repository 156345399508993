import * as React from 'react';

import { PostTaskBanner as PostTaskBannerIcon } from 'components/icons';
import PostTaskBannerLink, {
  PostTaskBannerLinkProps,
} from '../../components/PostTaskBannerLink';

export interface PostTaskBannerProps
  extends Pick<PostTaskBannerLinkProps, 'onClick'> {
  userEmail: string;
}

const { useMemo } = React;

const PostTaskBanner: React.SFC<PostTaskBannerProps> = ({
  userEmail,
  onClick,
}) => {
  const random = useMemo(() => Math.round(Math.random()), []);

  const getImage = () => {
    if (random === 1) {
      return (
        <img src="https://static.sparemin.com/static/webapp-assets/post-task/native-mobile-app-201912101622.gif" />
      );
    }

    return <PostTaskBannerIcon />;
  };
  const getHref = () => {
    if (random === 1) {
      return 'https://mobile.headliner.app/';
    }

    return `https://www.headliner.app/host-request/?email=${encodeURIComponent(
      userEmail,
    )}`;
  };

  return (
    <PostTaskBannerLink href={getHref()} image={getImage()} onClick={onClick} />
  );
};

export default PostTaskBanner;
