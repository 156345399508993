import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import * as React from 'react';
import FontAwesome from 'components/FontAwesome';
import FormField, { FormFieldProps } from './FormField';
import InputAdornment from './InputAdornment';
import { block } from './utils';

interface Props extends FormFieldProps {
  onCardChange: (event: StripeCardElementChangeEvent) => void;
}

function CardField(props: Props) {
  const { light, onCardChange, ...rest } = props;

  const style = React.useMemo(
    () => ({
      base: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        color: light ? '#23282d' : '#bec8d2',
        fontSize: '14px',
        lineHeight: '1.3',
        '::placeholder': {
          color: light ? '#646e82' : '#8ca0c3',
        },
        transform: 'translateX(20px)',
      },
      invalid: {
        color: '#c82828',
      },
    }),
    [light],
  );

  return (
    <FormField
      {...rest}
      light={light}
      startAdornment={
        <InputAdornment>
          <FontAwesome icon="credit-card" regular />
        </InputAdornment>
      }
    >
      <div className={block('card-field')}>
        <CardElement
          className={block('card-element')}
          onChange={onCardChange}
          options={{
            hideIcon: true,
            style,
          }}
        />
      </div>
    </FormField>
  );
}

export default CardField;
