import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import LoadingMessage from 'components/LoadingMessage';
import {
  cancelWaitForPodcastFeedRefresh,
  isRefreshingPodcastFeedSelector,
} from 'redux/modules/podcast';
import { episodeExplorerBlock as block } from '../../utils';
import EpisodeList, { EpisodeListProps } from '../EpisodeList';
import RefreshFeedButton from './RefreshFeedButton';

const { useEffect, useRef } = React;

export interface EpisodeExplorerProps extends EpisodeListProps {
  artworkUrl: string;
  isLoading?: boolean;
  isProcessingEpisode?: boolean;
  hasResults?: boolean;
  podcastId: string;
  canRefreshFeed?: boolean;
  onMount?: (hasResults: boolean) => Promise<void> | void;
}

const EpisodeExplorer: React.FC<EpisodeExplorerProps> = ({
  artworkUrl,
  hasResults,
  isLoading,
  isProcessingEpisode,
  podcastId,
  canRefreshFeed,
  onMount,
  ...props
}) => {
  const [isHovering, setIsHovering] = React.useState(false);

  const onMountRef = useRef(() => onMount(hasResults));

  const isRefreshingFeed = useSelector(isRefreshingPodcastFeedSelector);

  const dispatch = useDispatch();

  const createOnMouseHandler = (isHoveringContainer: boolean) => () => {
    setIsHovering(isHoveringContainer);
  };

  useEffect(() => {
    const mountPromise = onMountRef.current();

    return () => {
      if (mountPromise) {
        (mountPromise as any).cancel?.();
      }

      dispatch(cancelWaitForPodcastFeedRefresh(podcastId));
    };
  }, [dispatch, podcastId]);

  return (
    <div
      className={block()}
      onMouseEnter={createOnMouseHandler(true)}
      onMouseLeave={createOnMouseHandler(false)}
    >
      <div className={block('podcast-artwork-container')}>
        <img className={block('podcast-artwork')} src={artworkUrl} />

        {hasResults && !isLoading && !isRefreshingFeed && (
          <RefreshFeedButton
            podcastId={podcastId}
            isVisible={isHovering && canRefreshFeed && !isRefreshingFeed}
          />
        )}
      </div>
      <div className={block('episodes')}>
        {(() => {
          if (isProcessingEpisode) {
            return <LoadingMessage message="Processing Episode" noFadeIn />;
          }

          if ((!hasResults && isLoading) || isRefreshingFeed) {
            return <LoadingMessage message="Loading episodes" />;
          }

          return <EpisodeList {...props} />;
        })()}
      </div>
    </div>
  );
};

export default EpisodeExplorer;
