import animationDetector from 'animated-gif-detector';
import Pica from 'pica';

import { getMaxDimensions } from 'utils/aspect-ratio';
import { getRatio, percentageOf } from './numbers';

const pica = new Pica({
  features: ['ww', 'js'],
});

const IMAGE_AREA_MAX = 1920 * 1080;

export function toBlob(canvas, mimeType) {
  return new Promise(resolve => {
    canvas.toBlob(blob => resolve(blob), mimeType);
  });
}

export function convertFileToDataUrl(file) {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  // TODO what about errors?
  return new Promise(resolve => {
    reader.addEventListener('load', () => resolve(reader.result));
  });
}

export function loadImage(imageSource) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error('error loading image'));
    img.src = imageSource;
  });
}

export function getResizeSize(width, height, maxWidth, maxHeight) {
  const expectedAspectRatio = maxWidth / maxHeight;
  const imageAspectRatio = width / height;

  let resizeWidth = maxWidth < width ? maxWidth : width;
  let resizeHeight = maxHeight < height ? maxHeight : height;

  if (imageAspectRatio < expectedAspectRatio) {
    resizeHeight = resizeWidth * (height / width);
  } else if (imageAspectRatio > expectedAspectRatio) {
    resizeWidth = resizeHeight * (width / height);
  }

  return { resizeWidth, resizeHeight };
}

export function getDataUrlMimeType(dataUrl) {
  // Regex from here: https://miguelmota.com/bytes/base64-mime-regex/
  const matches = dataUrl.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  if (matches && matches.length > 0) {
    return matches[1];
  }

  return null;
}

/**
 * @param {Blob} src
 * @param {Number} aspectRatio
 */
export async function downscaleImage(src, aspectRatio) {
  const url = URL.createObjectURL(src);
  try {
    const img = await loadImage(url);
    const imageArea = img.width * img.height;
    const ratio = aspectRatio || img.width / img.height;

    if (imageArea < IMAGE_AREA_MAX) {
      return src;
    }

    const { height: maxHeight, width: maxWidth } = getMaxDimensions(
      IMAGE_AREA_MAX,
      ratio,
    );

    const { resizeHeight, resizeWidth } = getResizeSize(
      img.width,
      img.height,
      maxWidth,
      maxHeight,
    );

    const canvas = document.createElement('canvas');
    canvas.width = resizeWidth;
    canvas.height = resizeHeight;

    const opts = src.type === 'image/png' ? { alpha: true } : undefined;
    await pica.resize(img, canvas, opts);
    return await toBlob(canvas, src.type);
  } finally {
    URL.revokeObjectURL(url);
  }
}

export function isAnimatedGif(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      const buffer = new Buffer(e.target.result);
      resolve(animationDetector(buffer));
    };
    reader.onerror = err => reject(err);
    reader.readAsArrayBuffer(file);
  });
}

export function convertSvgToPng(src, width, height) {
  if (width && height && src && src.includes('image/svg+xml')) {
    const canvas = document.createElement('canvas');
    const image = document.createElement('img');
    const containerWidth = Math.round(width);
    const containerHeight = Math.round(height);

    image.src = src;
    canvas.width = containerWidth;
    canvas.height = containerHeight;
    canvas
      .getContext('2d')
      .drawImage(image, 0, 0, containerWidth, containerHeight);

    return canvas.toDataURL();
  }

  return src;
}

/**
 * some things to keep in mind:
 *  - ratio > 1 means width > height
 *  - ratio < 1 means width < height
 *  - ratio === 1 means width === height
 *
 * @param {HTMLImageElement} svgImage
 * @param {Number} targetAspectRatio
 */
function getSvgToPngDimensions(svgImage, targetAspectRatio) {
  const ratio = svgImage.naturalWidth / svgImage.naturalHeight;
  const { height: targetHeight, width: targetWidth } = getMaxDimensions(
    IMAGE_AREA_MAX,
    targetAspectRatio,
  );

  if (ratio > 1) {
    return {
      height: Math.round(targetWidth / ratio),
      width: targetWidth,
    };
  }
  return {
    height: targetHeight,
    width: Math.round(targetHeight * ratio),
  };
}

/**
 * @param {Blob} image
 * @param {Number} aspectRatio
 */
export async function svgToPng(image, aspectRatio) {
  if (image.type !== 'image/svg+xml') return image;

  const url = URL.createObjectURL(image);
  try {
    // load image
    const img = await loadImage(url);

    // get max size of image in this aspect ratio and create canvas of that size
    const { height, width } = getSvgToPngDimensions(img, aspectRatio);
    const canvas = document.createElement('canvas');
    canvas.height = height;
    canvas.width = width;
    canvas.getContext('2d').drawImage(img, 0, 0, canvas.width, canvas.height);

    return await toBlob(canvas, 'image/png');
  } finally {
    URL.revokeObjectURL(url);
  }
}

function getDimensions(ratioOrDimensions) {
  if (typeof ratioOrDimensions === 'number') {
    return getRatio(ratioOrDimensions);
  }

  const { height, width } = ratioOrDimensions;
  return [width, height];
}

/**
 * Fits an image into a canvas.  If the image doesn't fit perfectly in the
 * canvas, it will be centered and surrounded by enough transparency to fill
 * the canvas.
 *
 * @param {string | Blob} src image source
 * @param {number} canvasRatio aspect ratio of the canvas
 */
export function fitImage(src, canvasRatio) {
  const url = typeof src === 'string' ? src : URL.createObjectURL(src);

  return new Promise((resolve, reject) => {
    const image = document.createElement('img');

    image.addEventListener('error', () =>
      reject(new Error('Error loading image')),
    );

    image.addEventListener('load', () => {
      try {
        const [canvasWidth, canvasHeight] = getDimensions(canvasRatio);

        // need canvas dimensions in which we can place the source image. to do
        // this, the canvas size is maximized by first fitting the canvas inside
        // of the source image
        const canvasScale = Math.max(
          image.naturalHeight / canvasHeight,
          image.naturalWidth / canvasWidth,
        );

        const scaledCanvasHeight = canvasHeight * canvasScale;
        const scaledCanvasWidth = canvasWidth * canvasScale;

        // this scaling factor is used to fit the original image into the new
        // canvas dimensions created by the canvasScale
        // TODO is this always 1?
        const imageScale = Math.min(
          scaledCanvasHeight / image.naturalHeight,
          scaledCanvasWidth / image.naturalWidth,
        );

        const canvas = document.createElement('canvas');
        canvas.height = scaledCanvasHeight;
        canvas.width = scaledCanvasWidth;

        // center the image
        const x = scaledCanvasWidth / 2 - (image.naturalWidth / 2) * imageScale;
        const y =
          scaledCanvasHeight / 2 - (image.naturalHeight / 2) * imageScale;

        canvas
          .getContext('2d')
          .drawImage(
            image,
            x,
            y,
            image.naturalWidth * imageScale,
            image.naturalHeight * imageScale,
          );

        // this metadata matches the metadata returned from cropperjs
        const metadata = {
          // the image.  left and top are relative to the container
          canvas: {
            height: image.naturalHeight,
            left: x,
            top: y,
            width: image.naturalWidth,
          },
          constrained: false,
          // container matches the target aspect ratio and is what we're fitting
          // the image into
          container: {
            height: scaledCanvasHeight,
            width: scaledCanvasWidth,
          },
          // crop area based on the natrual size of the image.  we take the whole
          // canvas as the crop area so that we get transparency for whatever the
          // image doesn't cover.  the crop area is relative to the canvas, not
          // the container
          crop: {
            height: scaledCanvasHeight,
            width: scaledCanvasWidth,
            x: -x,
            y: -y,
          },
        };

        // export as a png for transparency if the image doesn't fill the canvas
        // resolve(canvas.toDataURL('image/png'));
        // canvas.toBlob(resolve, 'image/png');
        canvas.toBlob(blob => {
          resolve([blob, metadata]);
        }, 'image/png');
      } catch (err) {
        reject(new Error('Error fitting image'));
      } finally {
        URL.revokeObjectURL(url);
      }
    });

    image.crossOrigin = 'anonymous';
    image.src = url;
  });
}

export function scaleImageToNewViewport(
  oldViewport,
  newViewport,
  imageViewportSize,
) {
  if (!imageViewportSize.height || !imageViewportSize.width) return undefined;

  const imageAspectRatio =
    ((parseFloat(imageViewportSize.width) / 100) * oldViewport.width) /
    ((parseFloat(imageViewportSize.height) / 100) * oldViewport.height);

  const newImageWidth = imageViewportSize.width;
  const newImageHeight =
    ((parseFloat(imageViewportSize.width) / 100) * newViewport.width) /
    imageAspectRatio;

  return {
    height: `${percentageOf(newImageHeight, newViewport.height)}vh`,
    width: newImageWidth,
  };
}

export default {
  convertFileToDataUrl,
  getDataUrlMimeType,
};
