import * as React from 'react';

import LinkButton from 'components/LinkButton';
import useTemplatesButton from './useTemplatesButton';
import { optionsBlock as block } from './utils';

export interface TemplatesButtonProps {}

const TemplatesButton: React.FC<TemplatesButtonProps> = () => {
  const { disabled, onClick } = useTemplatesButton();

  return (
    <LinkButton
      className={block('templates-button')}
      {...{ disabled, onClick }}
    >
      ← CHOOSE ANOTHER TEMPLATE
    </LinkButton>
  );
};

export default TemplatesButton;
