import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { every, isEmpty } from 'underscore';
import SteppedModal from 'components/SteppedModal';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import LinkedInPostModal from 'containers/LinkedInPostModal';
import { LinkedinPostModalSteps } from 'containers/LinkedInPostModal/steps/types';
import useAccessDeniedStep from 'containers/LinkedInPostModal/steps/useAccessDeniedStep';
import useAuthenticationProgressStep from 'containers/LinkedInPostModal/steps/useAuthenticationProgressStep';
import useAuthorizationStep from 'containers/LinkedInPostModal/steps/useAuthorizationStep';
import useAutopostingComposerStep from 'containers/LinkedInPostModal/steps/useAutopostingComposerStep';
import { UseComposerStepValue } from 'containers/LinkedInPostModal/steps/useAutopostingComposerStep/types';
import { linkedinUserDataSelector } from 'redux/modules/social';
import { LinkedInSheetDefaultValues } from './types';

export interface LinkedInAutoPostModalProps {}

const LinkedInAutoPostModal: React.FC<LinkedInAutoPostModalProps> = () => {
  const [activeStep, setActiveStep] = useState('');

  const modalRef = useRef<SteppedModal>(null);

  const linkedInUserData = useSelector(linkedinUserDataSelector);

  const { params, show, onExited, onHide } = useConnectedModal(
    'LinkedInAutoPost',
  );

  const { subscriptionItem } = params ?? {};

  const { description, linkedinAuthorId } =
    subscriptionItem?.autoPostVideoPreference.options || {};

  const initialValues = React.useMemo((): LinkedInSheetDefaultValues => {
    return {
      description:
        description ??
        'Here’s a new clip from <Episode Title>! Listen to the full episode here: <Link To Episode>',
      linkedinAuthorId,
    };
  }, [description, linkedinAuthorId]);

  const replaceModalStep = useCallback((id: LinkedinPostModalSteps): void => {
    modalRef.current?.stepHistory.replace(id);
  }, []);

  const handleGoToProgressStep = useCallback(() => {
    replaceModalStep('authentication-in-progress');
  }, [replaceModalStep]);

  const handleAuthorizationError = useCallback((): void => {
    replaceModalStep('access-denied');
  }, [replaceModalStep]);

  const handleGoToComposerStep = useCallback(() => {
    if (every(linkedInUserData, isEmpty)) {
      return undefined;
    }

    return replaceModalStep('composer');
  }, [linkedInUserData, replaceModalStep]);

  const handleAuthenticationSuccess = useCallback(async () => {
    replaceModalStep('composer');
  }, [replaceModalStep]);

  const handleHide = useCallback(
    (values?: UseComposerStepValue) => {
      handleGoToComposerStep();
      onHide(values);
    },
    [handleGoToComposerStep, onHide],
  );

  const handleExited = useCallback(() => {
    handleGoToComposerStep();
    onExited();
  }, [handleGoToComposerStep, onExited]);

  const handleSubmit = useCallback(
    (values: UseComposerStepValue): void => {
      handleHide(values);
    },
    [handleHide],
  );

  const authorization = useAuthorizationStep({
    onAuth: handleGoToProgressStep,
    onAuthSuccess: handleAuthenticationSuccess,
    onError: handleAuthorizationError,
  });

  const authenticationProgress = useAuthenticationProgressStep();

  const accessDenied = useAccessDeniedStep({
    onError: () => null,
    onReviseAccess: () => null,
  });

  const composer = useAutopostingComposerStep({
    initialValues,
    onHideModal: handleHide,
    onSubmit: handleSubmit,
  });

  const isComposerStep = activeStep === 'composer';

  return (
    <LinkedInPostModal
      {...{ show }}
      ref={modalRef}
      title={!isComposerStep && 'auto-post videos'}
      defaultStep={authorization.id}
      steps={[authorization, authenticationProgress, accessDenied, composer]}
      className={isComposerStep && 'composer-step-container'}
      onStepChange={setActiveStep}
      onHide={() => handleHide()}
      onExited={handleExited}
    />
  );
};

export default LinkedInAutoPostModal;
