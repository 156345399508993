import 'wavesurfer.js';

import * as React from 'react';
import uncontrollable from 'uncontrollable';
import { isFunction } from 'underscore';

import { AudioClipOffsets } from 'types';
import AudioClipperContainer, {
  ContainerProps,
  RenderProps,
} from './AudioClipperContainer';
import AudioClipperInner, {
  IAudioClipperInner,
  InnerProps,
} from './AudioClipperInner';

const { useCallback } = React;

type PickedFromContainer = Pick<
  ContainerProps,
  | 'defaultAudioClipDurationMillis'
  | 'defaultPositionMillis'
  | 'maxDurationMillis'
  | 'minDurationMillis'
  | 'onAudioProcess'
  | 'onError'
  | 'onClearError'
  | 'onPause'
  | 'onPlay'
  | 'onReady'
  | 'onRegionUpdate'
  | 'onPresetChange'
  | 'onStop'
  | 'playing'
  | 'region'
  | 'onMediaError'
>;

type PickedFromInner = Pick<
  InnerProps,
  | 'audioPeaks'
  | 'backend'
  | 'className'
  | 'containerMode'
  | 'controlsClassName'
  | 'disabled'
  | 'formClassName'
  | 'initialPresetKey'
  | 'renderOverlay'
  | 'src'
  | 'captionsControl'
>;

type Props = PickedFromContainer & PickedFromInner;

export class AudioClipperComponent extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    maxDurationMillis: spareminConfig.uploadRecordingMaxDuration * 1000,
    minDurationMillis: spareminConfig.minAudioDurationMillis,
  };

  private inner: IAudioClipperInner;
  private container: AudioClipperContainer;

  public get clip(): AudioClipOffsets {
    return this.inner.clip;
  }

  public pause() {
    this.container.pause();
  }

  public play() {
    this.container.play();
  }

  public playSelection() {
    this.container.playSelection();
  }

  public seek(millis: number) {
    this.container.seekTo(millis);
  }

  private renderInner = (props: RenderProps) => {
    const {
      audioPeaks,
      backend,
      className,
      containerMode,
      controlsClassName,
      disabled,
      formClassName,
      initialPresetKey,
      captionsControl,
      renderOverlay,
      src,
      minDurationMillis,
      maxDurationMillis,
      onMediaError,
    } = this.props;

    return (
      <AudioClipperInner
        {...props}
        audioPeaks={audioPeaks}
        backend={backend}
        className={className}
        containerMode={containerMode}
        controlsClassName={controlsClassName}
        disabled={disabled}
        formClassName={formClassName}
        initialPresetKey={initialPresetKey}
        captionsControl={captionsControl}
        renderOverlay={renderOverlay}
        src={src}
        minDurationMillis={minDurationMillis}
        maxDurationMillis={maxDurationMillis}
        ref={el => (this.inner = el)}
        onError={onMediaError}
      />
    );
  };

  public render() {
    const {
      defaultAudioClipDurationMillis,
      defaultPositionMillis,
      maxDurationMillis,
      minDurationMillis,
      onAudioProcess,
      onPresetChange,
      onError,
      onClearError,
      onPause,
      onPlay,
      onReady,
      onRegionUpdate,
      onStop,
      playing,
      region,
    } = this.props;

    return (
      <AudioClipperContainer
        ref={el => (this.container = el)}
        render={this.renderInner}
        {...{
          defaultAudioClipDurationMillis,
          defaultPositionMillis,
          maxDurationMillis,
          minDurationMillis,
          onAudioProcess,
          onPresetChange,
          onClearError,
          onError,
          onPause,
          onPlay,
          onReady,
          onRegionUpdate,
          onStop,
          playing,
          region,
        }}
      />
    );
  }
}

export type AudioClipperProps = Props & {
  defaultRegion?: Props['region'];
};

export type UncontrolledAudioClipper = React.FC<AudioClipperProps>;
const UncontrolledAudioClipper = uncontrollable(AudioClipperComponent, {
  region: 'onRegionUpdate',
});

const ForwardedAudioClipper = React.forwardRef<
  AudioClipperComponent,
  AudioClipperProps
>((props, ref: any) => {
  const handleUncontrolledRef = useCallback(
    (el: any) => {
      if (ref) {
        if (isFunction(ref)) {
          ref(el?.inner);
        } else {
          ref.current = el?.inner;
        }
      }
    },
    [ref],
  );

  return <UncontrolledAudioClipper {...props} ref={handleUncontrolledRef} />;
});

type AudioClipper = typeof ForwardedAudioClipper;
const AudioClipper = ForwardedAudioClipper;

export default AudioClipper;
