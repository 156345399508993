import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import BemCssTransition from 'components/BemCssTransition';
import ExportVideoStep from './ExportVideoStep';
import { ExportVideoStepProps } from './types';
import useExportVideoStepTransitions from './useExportVideoStepTransitions';
import { createEmbedExportSelector } from './utils';

const WizardExportVideoStep: React.FC<ExportVideoStepProps> = props => {
  const { widgetId } = props;
  const { status } = useSelector(
    useMemo(() => createEmbedExportSelector(widgetId), [widgetId]),
  );
  const animationConfig = useExportVideoStepTransitions({
    exportStatus: status,
  });

  return (
    <BemCssTransition {...animationConfig.step}>
      <ExportVideoStep {...props} />
    </BemCssTransition>
  );
};

export default WizardExportVideoStep;
