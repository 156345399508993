import { isString } from 'underscore';

import {
  putImageBlob,
  putImageData,
} from 'redux/middleware/api/image-service/actions';
import { ThunkAction } from 'redux/types';
import { getValue } from 'utils/collections';

export const uploadImage = (
  imageData: string | Blob,
): ThunkAction<Promise<{ id: string; url: string }>> => async dispatch => {
  const res = isString(imageData)
    ? await dispatch(putImageData(imageData))
    : await dispatch(putImageBlob(imageData));
  const id = getValue(res, ['response', 'result']);
  const url = getValue(res, ['response', 'entities', 'images', id, 'url']);
  return { id, url };
};
