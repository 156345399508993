import { useYouTubeLogin, UseYouTubeLoginConfig } from '@sparemin/auth';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'underscore';
import { tokenSelector } from 'redux/modules/auth';
import { showError } from 'redux/modules/notification';
import {
  clearYoutubeUser,
  getYoutubeUser,
  youtubeAuthorizeSuccess,
} from 'redux/modules/social';

export interface UseYouTubeAuthenticatorConfig
  extends Pick<UseYouTubeLoginConfig, 'force'> {
  onAuthSuccess?: () => void;
  onError?: (message: string) => void;
}

export default function useYouTubeAuthenticator({
  onAuthSuccess,
  onError,
  force,
}: UseYouTubeAuthenticatorConfig) {
  const dispatch = useDispatch();
  const spareminToken = useSelector(tokenSelector);

  return useYouTubeLogin({
    force,
    spareminToken,
    onError: err => {
      const { message } = err;

      if (message === 'access_denied') {
        onError?.(message);
      } else {
        dispatch(showError('Error authenticating with YouTube'));
      }
    },
    onSuccess: data => {
      dispatch(clearYoutubeUser());

      if (data) {
        dispatch(
          youtubeAuthorizeSuccess(
            pick(data.tokenInfo, 'accessToken', 'idToken', 'providerUserId'),
          ),
        );
      }

      dispatch(getYoutubeUser());
      onAuthSuccess?.();
    },
  });
}
