import * as React from 'react';

import FontAwesome from 'components/FontAwesome';
import bem from 'utils/bem';

const messageBlock = bem('selected-message');

const SelectedMessage = ({ fileType, additionalMessage }) => (
  <div className={messageBlock()}>
    <div className={messageBlock('title')}>{`${fileType} selected`}</div>
    <FontAwesome className={messageBlock('icon')} icon="check" />
    {additionalMessage && (
      <div className={messageBlock('additional-message')}>
        {additionalMessage}
      </div>
    )}
  </div>
);

export default SelectedMessage;
