import { PopoverProps, useToggle } from '@sparemin/blockhead';
import cn from 'classnames';
import { EmojiData, NimblePicker } from 'emoji-mart';
import data from 'emoji-mart/data/twitter.json';
import React from 'react';
import FontAwesome from 'components/FontAwesome';

import ToolbarButtonV2 from '../ToolbarButtonV2';
import ToolbarPopover from '../ToolbarPopover';
import { block as textToolbarBlock } from '../utils';

type PickedPopoverProps = Pick<PopoverProps, 'offset' | 'placement'>;

interface EmojiPickerProps extends PickedPopoverProps {
  onSelectEmoji?: (emoji: EmojiData) => void;
  title?: string;
}

const DEFAULT_TITLE = 'Pick your emoji...';

const EmojiPicker: React.FunctionComponent<EmojiPickerProps> = props => {
  const {
    onSelectEmoji,
    offset = 8,
    placement = 'top-end',
    title = DEFAULT_TITLE,
  } = props;

  const {
    toggleOff: closeMenu,
    toggleOn: openMenu,
    value: menuVisible,
  } = useToggle(false);

  const buttonRef = React.useRef<HTMLDivElement>(null);

  return (
    <div>
      <ToolbarButtonV2 ref={buttonRef} onPress={openMenu}>
        <FontAwesome
          className={cn(
            textToolbarBlock('text-align'),
            textToolbarBlock('icon'),
          )}
          icon="smile"
          regular
        />
      </ToolbarButtonV2>
      <ToolbarPopover
        className={textToolbarBlock('popover-menu', { 'emoji-picker': true })}
        isOpen={menuVisible}
        offset={offset}
        placement={placement}
        triggerElement={buttonRef.current}
        onClose={closeMenu}
      >
        <NimblePicker
          autoFocus
          custom={[]}
          data={data}
          native
          onSelect={onSelectEmoji}
          emoji="point_up"
          title={title}
        />
      </ToolbarPopover>
    </div>
  );
};

export default EmojiPicker;
