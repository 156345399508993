import { MixpanelWizardStep } from 'redux/modules/mixpanel/types';
import { Step } from './types';

export const MIXPANEL_WIZARD_STEP_MAP: Record<Step, MixpanelWizardStep> = {
  aspectRatio: 'Choose Aspect Ratio',
  editScript: 'Edit Script',
  importText: 'Import Text',
  uploadAudio: 'Select Audio',
  create: 'loading',
};
