import cn from 'classnames';
import * as React from 'react';

import BackgroundField from './BackgroundField';
import DynamicStyleOptions from './DynamicStyleOptions';
import FontOptions from './FontOptions';
import HighlightField from './HighlightField';
import LineSpacingField from './LineSpacingField';
import PaddingOptions from './PaddingOptions';
import PositionOptions from './PositionOptions';
import TextAlignmentField from './TextAlignmentField';
import TextShadowOptions from './TextShadowOptions';
import TimeOptions from './TimeOptions';
import { block } from './utils';

interface IProps {
  children?: React.ReactNode;
  className?: string;
}

export default class TextBoxOptions extends React.Component<IProps> {
  public static Background = BackgroundField;
  public static DynamicStyle = DynamicStyleOptions;
  public static Highlight = HighlightField;
  public static LineSpacing = LineSpacingField;
  public static Padding = PaddingOptions;
  public static Position = PositionOptions;
  public static TextAlign = TextAlignmentField;
  public static TextShadow = TextShadowOptions;
  public static Time = TimeOptions;
  public static Font = FontOptions;

  public render() {
    const { children, className } = this.props;

    return <form className={cn(block(), className)}>{children}</form>;
  }
}

export { IProps as TextBoxOptionsProps };
