import * as React from 'react';

import { IconProps } from './types';

const ArrowDown: React.FC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="18"
    height="11"
    fill="currentColor"
    {...props}
  >
    <defs>
      <path
        d="M.336 7.108 6.834.35a1.111 1.111 0 0 1 1.62 0l1.08 1.123c.449.467.449 1.223 0 1.685L4.933 7.953l4.605 4.79c.45.467.45 1.222 0 1.684l-1.08 1.128a1.111 1.111 0 0 1-1.62 0L.342 8.798a1.223 1.223 0 0 1-.005-1.69z"
        id="a"
      />
    </defs>
    <g transform="rotate(-90 5.508 4.46)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#a" />
      <g mask="url(#b)" fill="currentColor">
        <path d="M-2.724-.047h15.385v16H-2.724z" />
      </g>
    </g>
  </svg>
);

export default ArrowDown;
