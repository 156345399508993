import { createApiAction } from '../utils';
import { ACTION_KEY, EventType, ServiceMethod } from './constants';
import { PingAction } from './types';

export function apiCallAction<M extends ServiceMethod, A = undefined>(
  method: M,
  args?: A,
) {
  return createApiAction(ACTION_KEY, method, args);
}

export function sendDownloadPageLoad(videoId: number): PingAction {
  return apiCallAction(ServiceMethod.PING, [
    EventType.VIDEO_VIEW,
    videoId,
    window.location.href,
  ]);
}

export function sendDownloadPageAction(videoId: number): PingAction {
  return apiCallAction(ServiceMethod.PING, [
    EventType.VIDEO_ACTION,
    videoId,
    window.location.href,
  ]);
}
