import { REGISTER_SUCCESS } from '../auth/action-types';
import { Type as OnboardingType } from '../onboarding/action-types';
import { Type } from './action-types';
import { stateFactory } from './factories';
import { SurveyAction, SurveyState } from './types';

const defaultState = stateFactory();

export default function(
  state: SurveyState = defaultState,
  action: SurveyAction,
) {
  switch (action.type) {
    case Type.COHORT_SET_SURVEY_DATA: {
      const { data, status } = action.payload;
      return state.withMutations(s =>
        s.set('data', data).set('status', status),
      );
    }

    case Type.COHORT_SURVEY_STATUS_GET_SUCCESS: {
      const { status } = action.payload;
      return state.set('status', status);
    }

    case REGISTER_SUCCESS:
      return state.set('status', 'incomplete');

    case OnboardingType.ONBOARDING_WIZARD_COMPLETE_SUCCESS:
      return state.set('status', 'completed');

    default:
      return state;
  }
}
