import cn from 'classnames';
import * as React from 'react';

import GradientIcon from 'components/GradientIcon';
import Tooltip from 'components/Tooltip';
import { Tier } from 'redux/middleware/api/plan-service';
import { block } from './utils';

export interface PlanFeatureProps {
  className?: string;
  disabled?: boolean;
  icon: JSX.Element;
  id: string;
  label: React.ReactNode;
  theme?: Tier;
  tooltip?: string;
}

const PlanFeature: React.SFC<PlanFeatureProps> = ({
  className,
  disabled,
  icon,
  id,
  label,
  theme,
  tooltip,
}) => {
  const iconElement = React.cloneElement(icon, {
    className: cn(
      block('plan-feature-icon-img', { [theme]: !!theme }),
      icon.props.className,
    ),
    style: {
      height: 'auto',
      width: '100%',
      ...icon.props.style,
    },
  });

  return (
    <Tooltip
      className={block('feature-tooltip')}
      id={id}
      content={tooltip}
      show={!tooltip || disabled ? false : undefined}
      placement="top"
      preventHideOnHover={false}
    >
      <li className={cn(block('feature', { disabled }), className)}>
        <div className={block('feature-icon')}>
          {theme !== Tier.UNLIMITED ? (
            iconElement
          ) : (
            <GradientIcon>{iconElement}</GradientIcon>
          )}
        </div>
        <span className={block('feature-label')}>{label}</span>
      </li>
    </Tooltip>
  );
};

export default PlanFeature;
