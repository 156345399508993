import { AnimationConfig } from './types';
import { block } from './utils';

const DEFAULT_ANIMATION_DELAY_MS = 1000;
const DEFAULT_ANIMATION_DURATION_MS = 600;

interface UseExportVideoStepTransitionsConfig {
  exportStatus: string;
}

export default function useExportVideoStepTransitions({
  exportStatus,
}: UseExportVideoStepTransitionsConfig): AnimationConfig {
  return {
    discoSection: {
      in: true,
      timeout: DEFAULT_ANIMATION_DELAY_MS + DEFAULT_ANIMATION_DURATION_MS,
      transitionClassName: block('disco-section-animation'),
    },
    infoBlock: {
      key: exportStatus === 'completed' ? 'completed' : 'not-completed',
      transitionClassName: block('info-block-animation'),
      timeout: DEFAULT_ANIMATION_DURATION_MS,
    },
    mainSection: {
      in: true,
      timeout: DEFAULT_ANIMATION_DELAY_MS + DEFAULT_ANIMATION_DURATION_MS,
      transitionClassName: block('main-section-animation'),
    },
    step: {
      in: true,
      mountOnEnter: true,
      timeout: DEFAULT_ANIMATION_DURATION_MS,
      transitionClassName: block('animation'),
    },
  };
}
