import * as React from 'react';
import { H1 } from 'components/Heading';
import {
  MultiVideoCamera,
  VideoCameraAlt,
  VideoCameraAlt2,
} from 'components/icons';
import {
  PurchaseableTier,
  Tier,
} from 'redux/middleware/api/plan-service/types';
import { block } from './utils';

interface Props {
  tier?: PurchaseableTier;
}

const ICON_BY_TIER = {
  [Tier.BASIC]: (
    <VideoCameraAlt2 className={block('icon')} height={52} width="100%" />
  ),
  [Tier.PRO]: (
    <MultiVideoCamera className={block('icon')} height={85} width="100%" />
  ),
  [Tier.UNLIMITED]: (
    <VideoCameraAlt className={block('icon')} height={85} width="100%" />
  ),
};

const LABEL_BY_TIER = {
  [Tier.BASIC]: 'Headliner Basic',
  [Tier.PRO]: 'Headliner Pro',
  [Tier.UNLIMITED]: 'Unlimited',
};

function PaidSubscriptionSuccessModalContent(props: Props) {
  const { tier } = props;
  return (
    <div className={block('content')}>
      {ICON_BY_TIER[tier]}
      <H1 className={block('title')}>
        Welcome to
        <br />
        {LABEL_BY_TIER[tier]}!
      </H1>
    </div>
  );
}

export default PaidSubscriptionSuccessModalContent;
