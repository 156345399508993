import cn from 'classnames';
import * as React from 'react';
import { block } from './utils';

interface Props {
  className?: string;
}

export const BannerContent: React.FC<Props> = props => {
  const { children, className } = props;
  return <p className={cn(block('details'), className)}>{children}</p>;
};

export default BannerContent;
