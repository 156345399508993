export enum Type {
  TWITTER_USER_DATA_GET_AWAIT_REQUEST = 'app/social/twitter/USER_DATA_GET_AWAIT_REQUEST',
  TWITTER_USER_DATA_GET_AWAIT_SUCCESS = 'app/social/twitter/USER_DATA_GET_AWAIT_SUCCESS',
  TWITTER_USER_DATA_GET_AWAIT_FAILURE = 'app/social/twitter/USER_DATA_GET_AWAIT_FAILURE',

  TWITTER_USER_DATA_GET_REQUEST = 'app/social/twitter/USER_DATA_GET_REQUEST',
  TWITTER_USER_DATA_GET_SUCCESS = 'app/social/twitter/USER_DATA_GET_SUCCESS',
  TWITTER_USER_DATA_GET_FAILURE = 'app/social/twitter/USER_DATA_GET_FAILURE',

  TWITTER_USER_DATA_CLEAR = 'app/social/twitter/USER_DATA_CLEAR',

  TWITTER_VIDEO_POST_REQUEST = 'app/social/twitter/VIDEO_POST_REQUEST',
  TWITTER_VIDEO_POST_SUCCESS = 'app/social/twitter/VIDEO_POST_SUCCESS',
  TWITTER_VIDEO_POST_FAILURE = 'app/social/twitter/VIDEO_POST_FAILURE',

  TWITTER_VIDEO_POST_AWAIT_REQUEST = 'app/social/twitter/VIDEO_POST_AWAIT_REQUEST',
  TWITTER_VIDEO_POST_AWAIT_SUCCESS = 'app/social/twitter/VIDEO_POST_AWAIT_SUCCESS',
  TWITTER_VIDEO_POST_AWAIT_FAILURE = 'app/social/twitter/VIDEO_POST_AWAIT_FAILURE',

  TWITTER_AUTHORIZE_SUCCESS = 'app/social/twitter/AUTHORIZE_SUCCESS',
}
