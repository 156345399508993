import { RequestStatus } from 'types';
import { PostStatus } from '../constants';
import { Type } from './action-types';
import { YoutubeAction, YoutubeState, youtubeStateFactory } from './types';

const defaultState = youtubeStateFactory();

export default function(
  state: YoutubeState = defaultState,
  action: YoutubeAction,
) {
  switch (action.type) {
    case Type.YOUTUBE_USER_DATA_GET_SUCCESS: {
      const { profileImageUrl, username } = action.payload;

      return state
        .set('username', username)
        .set('profileImageUrl', profileImageUrl);
    }

    case Type.YOUTUBE_USER_DATA_CLEAR:
      return youtubeStateFactory();

    case Type.YOUTUBE_VIDEO_POST_REQUEST:
      return state.set('postStatus', PostStatus.IN_PROGRESS);

    case Type.YOUTUBE_CHANNEL_GET_REQUEST:
      return state.withMutations(s => {
        s.setIn(['channelInfo', 'postStatus'], RequestStatus.REQUEST);
        s.setIn(['channelInfo', 'providerUserId'], undefined);
        s.setIn(['channelInfo', 'id'], undefined);
        s.setIn(['channelInfo', 'title'], undefined);
        return s;
      });

    case Type.YOUTUBE_CHANNEL_GET_FAILURE:
      return state.setIn(['channelInfo', 'postStatus'], RequestStatus.FAILURE);

    case Type.YOUTUBE_CHANNEL_GET_SUCCESS:
      return state.withMutations(s => {
        s.setIn(['channelInfo', 'postStatus'], RequestStatus.SUCCESS);
        s.setIn(
          ['channelInfo', 'providerUserId'],
          action.payload.providerUserId,
        );
        s.setIn(['channelInfo', 'id'], action.payload.id);
        s.setIn(['channelInfo', 'title'], action.payload.title);
        return s;
      });

    case Type.YOUTUBE_VIDEO_POST_SUCCESS: {
      const { socialShareId } = action.payload;
      return state.set('socialShareId', socialShareId);
    }

    case Type.YOUTUBE_VIDEO_POST_FAILURE:
    case Type.YOUTUBE_VIDEO_POST_AWAIT_FAILURE:
      return state.set('postStatus', PostStatus.FAILURE);

    case Type.YOUTUBE_VIDEO_POST_AWAIT_SUCCESS:
      return state.set('postStatus', PostStatus.SUCCESS);

    case Type.YOUTUBE_AUTHORIZE_SUCCESS: {
      return state.merge(action.payload);
    }

    case Type.PLAYLISTS_GET_REQUEST: {
      return state.set('playlists', {
        data: [],
        status: 'pending',
      });
    }

    case Type.PLAYLISTS_GET_SUCCESS: {
      const playlists = action.payload;

      return state.set('playlists', {
        data: playlists,
        status: 'resolved',
      });
    }

    case Type.PLAYLISTS_GET_FAILURE:
      return state.set('playlists', {
        data: [],
        status: 'rejected',
      });

    case Type.CATEGORIES_GET_SUCCESS: {
      const categories = action.payload;

      return state.set('categories', {
        data: categories,
      });
    }

    case Type.CATEGORIES_GET_FAILURE:
      return state.set('categories', {
        data: [],
      });

    case Type.PLAYLIST_CREATE_REQUEST:
      return state.withMutations(s => {
        s.set('createPlaylistStatus', RequestStatus.REQUEST);
        s.set('lastCreatedPlaylistId', undefined);
        return s;
      });

    case Type.PLAYLIST_CREATE_SUCCESS: {
      const { playlistId } = action.payload;

      return state.withMutations(s => {
        s.set('createPlaylistStatus', RequestStatus.SUCCESS);
        s.set('lastCreatedPlaylistId', playlistId);
        return s;
      });
    }

    case Type.PLAYLIST_CREATE_FAILURE:
      return state.set('createPlaylistStatus', RequestStatus.FAILURE);

    default:
      return state;
  }
}
