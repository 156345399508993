import { createSelector } from 'reselect';
import { socialSharesSelector } from 'redux/modules/entities';
import { stateSelector } from '../selectors';

export const threadsSelector = createSelector(stateSelector, state =>
  state.get('threads'),
);

export const threadsUserIdSelector = createSelector(
  threadsSelector,
  threads => threads.threadsUserId,
);

export const threadsProviderUserIdSelector = createSelector(
  threadsSelector,
  threads => threads.providerUserId,
);

export const threadsUserDataSelector = createSelector(
  threadsSelector,
  threads => ({
    accessToken: threads.accessToken,
    idToken: threads.idToken,
    providerUserId: threads.providerUserId,
    username: threads.username,
    profileImageUrl: threads.profileImageUrl,
  }),
);

export const threadsUserNameSelector = createSelector(
  threadsSelector,
  threads => threads.username,
);

export const threadsAvatarSrcSelector = createSelector(
  threadsSelector,
  threads => threads.profileImageUrl,
);

export const threadsSocialShareIdSelector = createSelector(
  threadsSelector,
  threads => threads.socialShareId,
);

export const threadsSocialSharesSelector = createSelector(
  threadsSocialShareIdSelector,
  socialSharesSelector,
  (id, shares) =>
    !id || !shares || shares.size === 0
      ? undefined
      : shares.get(String(id))?.toJS(),
);

export const threadsPostStatusSelector = createSelector(
  threadsSelector,
  threads => threads.postStatus,
);
