import * as React from 'react';
import { UseHook } from 'components/UseHook';

import useAuthModal, {
  UseAuthModalConfig,
  UseAuthModalResult,
} from './useAuthModal';

export interface UseAuthModalHookProps extends UseAuthModalConfig {
  children: (props: UseAuthModalResult) => React.ReactElement | null;
}

const UseAuthModalHook = ({
  children,
  ...props
}: UseAuthModalHookProps): React.ReactElement | null => (
  <UseHook args={[props]} hook={useAuthModal}>
    {children}
  </UseHook>
);

export default UseAuthModalHook;
