import { Button, Spacer, Tooltip } from '@sparemin/blockhead';
import React, { useCallback } from 'react';

import LoadingMessage from 'components/LoadingMessage';

import { Season } from 'redux/modules/automation-cms/types';
import SeasonsSelector, {
  getNormalizedSeasonsList,
  useSeasonsSelector,
} from './SeasonsSelector';
import { block } from './utils';

export interface AutogramSeasonsStepProps {
  podcastId: string;
  targetedSeasons: number[];
  onTargetedSeasonsChange: (targetedSeasons: number[]) => void;
  onSkipSeasonsSelection: () => void;
  onSubmit: () => void;
}

const AutogramSeasonsStep: React.FC<AutogramSeasonsStepProps> = props => {
  const {
    podcastId,
    targetedSeasons,
    onTargetedSeasonsChange,
    onSkipSeasonsSelection,
    onSubmit,
  } = props;

  const handleOnSeasonsLoaded = useCallback(
    (seasons: Season[]): void => {
      const normalizedSeasons = getNormalizedSeasonsList(seasons);

      if (!normalizedSeasons.length) {
        return onSkipSeasonsSelection();
      }

      return onTargetedSeasonsChange(normalizedSeasons);
    },
    [onSkipSeasonsSelection, onTargetedSeasonsChange],
  );

  const {
    isLoading,
    seasons,
    totalEpisodes,
    onTargetedListChange,
    onAllEpisodesClick,
  } = useSeasonsSelector({
    podcastId,
    onTargetedSeasonsChange,
    onSeasonsLoaded: handleOnSeasonsLoaded,
  });

  const handleChange = useCallback(
    (season: number, isSelected: boolean): void => {
      onTargetedListChange(targetedSeasons, season, isSelected);
    },
    [onTargetedListChange, targetedSeasons],
  );

  return isLoading ? (
    <LoadingMessage message="Analyzing feed" noFadeIn />
  ) : (
    <Spacer
      orientation="vertical"
      align="center"
      space={4.8}
      className={block()}
    >
      <SeasonsSelector
        {...{ seasons, totalEpisodes, targetedSeasons }}
        onChange={handleChange}
        onAllEpisodesClick={onAllEpisodesClick}
      />

      <Tooltip
        contents="Select at least one season to continue"
        arrow
        isDisabled={!!targetedSeasons?.length}
      >
        <Button disabled={!targetedSeasons?.length} onPress={onSubmit}>
          Continue with these seasons
        </Button>
      </Tooltip>
    </Spacer>
  );
};

export default AutogramSeasonsStep;
