import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { videoExportProgressSelector } from 'redux/modules/embed-export/selectors';
import {
  embedLoadingSelector,
  embedWidgetIdSelector,
} from 'redux/modules/embed/selectors';
import { State } from 'redux/types';
import { PropsOf } from 'types';
import { isExportComplete } from '../../utils';
import VideoExportModal, {
  VideoExportModalProps as Props,
} from './VideoExportModal';

type StateProps = Pick<Props, 'isExported'>;

const widgetIdSelector = createSelector(
  [embedLoadingSelector, embedWidgetIdSelector],
  (isLoading, wid) => (!isLoading ? wid : undefined),
);

const isExportedSelector = createSelector(
  [widgetIdSelector, videoExportProgressSelector],
  (widgetId, status) => isExportComplete(status, widgetId),
);

const mapStateToProps = (state: State): StateProps => ({
  isExported: isExportedSelector(state),
});

const component = connect(mapStateToProps)(VideoExportModal);
export type VideoExportModalProps = PropsOf<typeof component>;
export default component;
