import {
  createEmptyState,
  createModifiedState,
  createStateFromTemplate,
  exportState,
  exportTextOverlays,
  getBackgroundColor,
  getProgressAnimationOptions,
  getSoundwave,
  getStateContent,
  getTimerOptions,
  isErrorAction,
} from './state';
import * as stateUtils from './state/state-utils';

export { default, VideoTemplateEditorProps } from './VideoTemplateEditor';
export { default as VideoTemplateEditorContextProvider } from './context';
export { default as EditorPane, EditorPaneProps } from './EditorPane';

export const EditorState = {
  createEmptyState,
  createModifiedState,
  createStateFromTemplate,
  exportState,
  exportTextOverlays,
  getBackgroundColor,
  getProgressAnimationOptions,
  getSoundwave,
  getTimerOptions,
  getContent: getStateContent,
  ...stateUtils,
};

export const ActionUtils = {
  isErrorAction,
};

export * from './types';
