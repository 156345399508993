import cn from 'classnames';
import * as React from 'react';

import StyleTab from 'blocks/AssetsPane/components/StyleTab';
import MediaTab, { MediaTabProps } from 'blocks/AssetsPane/containers/MediaTab';
import TranscriptTab, {
  TranscriptTabProps,
} from 'blocks/AssetsPane/containers/TranscriptTab';
import FadingScrollBars from 'components/FadingScrollBars';
import Tabs, { TabsPaddings, TabsThemes } from 'components/Tabs';
import useVideoEditorPlaybackTimeContext from 'containers/VideoEditor/useVideoEditorPlaybackTimeContext';
import usePrevious from 'hooks/usePrevious';
import { TranscriptStatus } from 'utils/constants';
import { block } from '../../utils';

type PickedTranscriptProps = Pick<
  TranscriptTabProps,
  | 'onCopyPhraseToTimeline'
  | 'onKeywordClick'
  | 'onPause'
  | 'onPlayPhrase'
  | 'playing'
  | 'syncTranscriptWithPlayback'
>;

type PickedMediaProps = Pick<MediaTabProps, 'onAssetClick'>;

export interface AssetsPaneProps
  extends PickedTranscriptProps,
    PickedMediaProps {
  className?: string;
  isVideoEditorReady?: boolean;
  onModalHide?: () => void;
  onModalShow?: () => void;
  transcriptStatus: TranscriptStatus;
}

const { useCallback, useEffect, useState } = React;

const AssetsPane: React.SFC<AssetsPaneProps> = ({
  className,
  isVideoEditorReady,
  onAssetClick,
  onCopyPhraseToTimeline,
  onKeywordClick,
  onModalHide,
  onModalShow,
  onPause,
  onPlayPhrase,
  playing,
  syncTranscriptWithPlayback,
  transcriptStatus,
}) => {
  const [activeTabKey, setActiveTabKey] = useState('transcript');
  const prevIsVideoEditorReady = usePrevious(isVideoEditorReady);

  const videoEditorPlaybackTimeContext = useVideoEditorPlaybackTimeContext();
  const positionMillis = videoEditorPlaybackTimeContext.positionSec * 1000;

  useEffect(() => {
    if (
      !prevIsVideoEditorReady &&
      isVideoEditorReady &&
      (transcriptStatus === TranscriptStatus.TRANSCRIPT_STATUS_NONE ||
        transcriptStatus === TranscriptStatus.TRANSCRIPT_STATUS_NA)
    ) {
      setActiveTabKey('style');
    }
  }, [isVideoEditorReady, transcriptStatus, prevIsVideoEditorReady]);

  const handleTabSelect = useCallback(
    (tabKey: string) => setActiveTabKey(tabKey),
    [setActiveTabKey],
  );

  return (
    <div className={cn(block(), className)}>
      <Tabs
        activeTabKey={activeTabKey}
        defaultActiveTabKey="transcript"
        id="assets-tab"
        onTabSelect={handleTabSelect}
        padding={TabsPaddings.MD}
        theme={TabsThemes.DARK}
        tabs={[
          {
            content: (
              <FadingScrollBars sideFaderClassName={block('side-fader')}>
                <TranscriptTab
                  onCopyPhraseToTimeline={onCopyPhraseToTimeline}
                  onKeywordClick={onKeywordClick}
                  onModalHide={onModalHide}
                  onModalShow={onModalShow}
                  onPause={onPause}
                  onPlayPhrase={onPlayPhrase}
                  playing={playing}
                  positionMillis={positionMillis}
                  syncTranscriptWithPlayback={syncTranscriptWithPlayback}
                  transcriptStatus={transcriptStatus}
                />
              </FadingScrollBars>
            ),
            tabKey: 'transcript',
            title: 'Transcript',
          },
          {
            content: <MediaTab onAssetClick={onAssetClick} />,
            tabKey: 'media',
            title: 'Media',
          },
          {
            content: (
              <StyleTab onModalShow={onModalShow} onModalHide={onModalHide} />
            ),
            tabKey: 'style',
            title: 'Style',
          },
        ]}
      />
    </div>
  );
};

export default AssetsPane;
