import React from 'react';

import { IconProps } from './types';

const Microphone: React.FC<IconProps> = ({
  fill = 'currentColor',
  ...props
}) => (
  <svg
    viewBox="0 0 11 15"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M5.077 10.313c1.53 0 2.77-1.26 2.77-2.813V2.812C7.846 1.26 6.606 0 5.076 0s-2.77 1.26-2.77 2.813V7.5c0 1.553 1.24 2.813 2.77 2.813zm4.615-4.688h-.461a.465.465 0 0 0-.462.469V7.5c0 2.191-1.86 3.95-4.061 3.732-1.918-.19-3.323-1.942-3.323-3.899v-1.24a.465.465 0 0 0-.462-.468H.462A.465.465 0 0 0 0 6.094V7.27c0 2.626 1.845 4.968 4.385 5.323v1H2.769a.465.465 0 0 0-.461.47v.468c0 .26.206.469.461.469h4.616c.255 0 .461-.21.461-.469v-.469a.465.465 0 0 0-.461-.468H5.769v-.99c2.473-.344 4.385-2.5 4.385-5.104V6.094a.465.465 0 0 0-.462-.469z"
        id="wujsazo0ba"
      />
    </defs>
    <g fill={fill} fillRule="evenodd">
      <mask id="tcgoaq19ub" fill="#fff">
        <use xlinkHref="#wujsazo0ba" />
      </mask>
      <use fill="#8CA0C3" fillRule="nonzero" xlinkHref="#wujsazo0ba" />
      <g mask="url(#tcgoaq19ub)" fill={fill}>
        <path d="M0 0h10.154v15H0z" />
      </g>
    </g>
  </svg>
);

export default Microphone;
