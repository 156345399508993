import * as React from 'react';

import { IconProps } from './types';

const Picture: React.SFC<IconProps> = ({
  fill = 'currentColor',
  stroke,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 11" {...props}>
    <path
      fill={fill}
      stroke={stroke}
      fillRule="nonzero"
      d="M15 9.5v-8A1.5 1.5 0 0 0 13.5 0h-12A1.5 1.5 0 0 0 0 1.5v8A1.5 1.5 0 0 0 1.5 11h12A1.5 1.5 0 0 0 15 9.5zM5 3a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM2 7.5l1.735-1.735a.375.375 0 0 1 .53 0L5.5 7l4.235-4.235a.375.375 0 0 1 .53 0L13 5.5V9H2V7.5z"
    />
  </svg>
);

export default Picture;
