import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';

import {
  personalFonts as personalFontsSelector,
  recentFontsSelector,
} from 'redux/modules/fonts/selectors';

import { Font } from './types';

const useFonts = (fonts: Font[]) => {
  const personalFonts = useSelector(personalFontsSelector) ?? [];
  const recentFonts = useSelector(recentFontsSelector) ?? [];

  const processFontsForDisplay = React.useCallback(
    (fontsToProcess: Font[] = []) => {
      return fontsToProcess.map(({ familyName, name }) => ({
        id: familyName,
        name: <span style={{ fontFamily: familyName }}>{name}</span>,
        textValue: name,
      }));
    },
    [],
  );

  const processedFonts = React.useMemo(() => {
    const newRecentFonts = recentFonts.reduce((acc, recentFont) => {
      const font = _.findWhere(fonts, { name: recentFont });
      if (font) acc.push(font);
      return acc;
    }, []);

    return {
      userFonts: processFontsForDisplay(personalFonts),
      allFonts: processFontsForDisplay(fonts),
      newRecentFonts: processFontsForDisplay(newRecentFonts),
    };
  }, [fonts, personalFonts, processFontsForDisplay, recentFonts]);

  return processedFonts;
};

export default useFonts;
