import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CreateProfileFormValue } from 'blocks/Authentication/types';
import {
  onSignupComplete,
  onSignupError,
  setMixpanelWizardRouterSelection,
} from 'redux/modules/mixpanel/actions';
import { MixpanelWizardRouterSelection } from 'redux/modules/mixpanel/types';
import { saveOnboardingWizardResponse } from 'redux/modules/onboarding';

export interface UseCreateProfileFormDispatchConfig {
  origin?: MixpanelWizardRouterSelection;
}

export default function useCreateProfileFormDispatch(
  config?: UseCreateProfileFormDispatchConfig,
) {
  const { origin } = config ?? {};
  const [error, setError] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const clearError = useCallback(() => setError(undefined), []);

  const onSubmit = useCallback(
    async ({
      emailsEnabled,
      podcast,
      userRole,
      referrer,
    }: CreateProfileFormValue) => {
      clearError();
      setIsSubmitting(true);

      try {
        await dispatch(
          saveOnboardingWizardResponse({
            podcastYesNo: podcast,
            usage: userRole,
            subscribeToNewsletter: emailsEnabled,
            referrer,
          }),
        );
        // send origin to mixpanel since the user doesn't see the wizard router
        // in the auth modal
        setMixpanelWizardRouterSelection(origin);
        dispatch(onSignupComplete());
      } catch {
        setError('Error saving profile');
      } finally {
        setIsSubmitting(false);
      }
    },
    [clearError, dispatch, origin],
  );

  const onError = useCallback(message => dispatch(onSignupError(message)), [
    dispatch,
  ]);

  useEffect(() => {
    if (error) {
      onError(error);
    }
  }, [error, onError]);

  return { clearError, error, isSubmitting, onError, onSubmit };
}
