import _, { isArray } from 'underscore';
import { PodcastTemplateConfig } from 'redux/middleware/api/podcast-service';
import { ISlideshowItem, Timing, ValueOf } from 'types';
import bem from 'utils/bem';
import { AssetInfo, AssetItemType } from './types';

export const block = bem('video-frame-preview');

/**
 * This function removes a specific asset from a podcast template configuration object.
 * It removes assets from lists within properties or from the root of the configuration object.
 *
 * @param {PodcastTemplateConfig} config - The current configuration object.
 * @param {number} layerId - The ID of the layer to be removed from the configuration.
 * @param {AssetItemType} type - The type of asset to be removed from the configuration.
 *
 * @returns A modified version of the configuration object with the specified asset removed based on the `layerId`
 * parameter. If the `layerId` and `type` parameters are not provided or the `type` does not exist in the input
 * object, the function returns the original configuration object.
 */
const getEmbedConfigWithoutAsset = (
  config: PodcastTemplateConfig,
  slideOutOfFrame: AssetInfo,
): PodcastTemplateConfig => {
  if (!slideOutOfFrame || !_.has(config, slideOutOfFrame?.type)) {
    return config;
  }

  const { type, id } = slideOutOfFrame;

  const currentConfig = config[type];

  // Removing assets within a property list.
  if (_.isArray(currentConfig)) {
    const newConfig = (currentConfig as ValueOf<ISlideshowItem>[]).filter(
      element => {
        if (_.has(element, 'id') && element?.['id'] !== id) {
          return element;
        }

        return undefined;
      },
    );

    return {
      ...config,
      [type]: newConfig,
    };
  }

  // Removing assets from the root configuration object.
  if (_.isObject(currentConfig) && slideOutOfFrame?.layerId <= 0) {
    return {
      ...config,
      [type]: undefined,
    };
  }

  return config;
};

export const getEmbedConfigurationWithoutHiddenAssets = (
  config: PodcastTemplateConfig,
  slidesOutOfFrame: AssetInfo[],
) => {
  return slidesOutOfFrame.reduce((prevConfig, slide) => {
    return getEmbedConfigWithoutAsset(prevConfig, slide);
  }, config);
};

export const getAssetMiddlePoint = (assetInfo: Timing): number => {
  if (!assetInfo) {
    return 0;
  }

  const { startMillis, endMillis } = assetInfo;

  return Math.round((endMillis - startMillis) / 2) + startMillis;
};

export const getAssetInfoWithinObject = (
  asset,
  type: AssetItemType,
): AssetInfo => {
  const { id, layerId, startMilli: startMillis, endMilli: endMillis } =
    asset || {};

  return { id, layerId, type, startMillis, endMillis };
};

export const getAssetInfoWithinArray = (
  config: PodcastTemplateConfig,
  type: AssetItemType,
): AssetInfo[] => {
  const currentConfig = config[type];

  if (!currentConfig || !isArray(currentConfig)) {
    return [];
  }

  return currentConfig.map(asset => getAssetInfoWithinObject(asset, type));
};
