import * as React from 'react';

import { SelectField, SelectFieldProps } from 'components/Form';
import { FrameRate, Omit } from 'types';
import { int } from 'utils/numbers';
import { frameRateOptions, getOptionDisplayName } from 'utils/video-export';
import VideoExportFormField from './VideoExportFormField';

interface IProps extends Omit<SelectFieldProps, 'label' | 'onChange'> {
  onChange?: (val: FrameRate) => void;
  value?: FrameRate;
}

export default class FrameRateField extends React.Component<IProps> {
  private handleChange = (val: string) =>
    this.props.onChange(int(val) as FrameRate);

  public render() {
    const { onChange, value, ...props } = this.props;

    return (
      <VideoExportFormField xs={12} sm={6}>
        <SelectField
          {...props}
          label="Frame Rate"
          onChange={this.handleChange}
          value={value}
          defaultValueText={getOptionDisplayName(frameRateOptions, value)}
        >
          {frameRateOptions.map(option => (
            <option key={option.value} value={Number(option.value)}>
              {option.displayName}
            </option>
          ))}
        </SelectField>
      </VideoExportFormField>
    );
  }
}

export { IProps as FrameRateFieldProps };
