import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExternalMedia } from 'redux/middleware/api/audio-proxy-service/actions';
import { makeArtworkUrlSelector } from 'redux/modules/podcast-search/selectors';
import { Dispatch } from 'redux/types';
import { PodcastIdentifier } from 'types';
import useMemoizedCallback from './useMemoizedCallback';

export default function usePodcastCover(
  podcastIdentifier: Partial<PodcastIdentifier> | null,
) {
  const { episodeId, podcastId } = podcastIdentifier ?? {};
  const dispatch = useDispatch<Dispatch>();
  const artworkUrl = useSelector(
    useMemo(() => makeArtworkUrlSelector(podcastId, episodeId), [
      podcastId,
      episodeId,
    ]),
  );

  const getArtworkBlob = useMemoizedCallback(
    async () => {
      if (!artworkUrl) return undefined;
      const { response } = await dispatch(getExternalMedia(artworkUrl));
      return response;
    },
    { promise: true },
    [artworkUrl],
  );

  return {
    artworkUrl,
    getArtworkBlob,
  };
}
