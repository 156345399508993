import { useSelector } from 'react-redux';
import { useAsyncAudioClipperError } from 'containers/AsyncAudioClipper';
import { useBlocker } from 'hooks/useBlocker';
import { CreationStatus } from 'redux/middleware/api/creation-service/types';
import { hasLoadErrorSelector } from 'redux/modules/audio-wizard';
import {
  creationStatusSelector,
  pollStatusSelector,
  statusSelector,
} from 'redux/modules/wizard-export/selectors';
import { RequestStatus } from 'types';
import { MIXPANEL_WIZARD_STEP_MAP, WizardStep } from './constants';

const useAudiogramWizardBlocker = (stepId: WizardStep) => {
  const { hasError } = useAsyncAudioClipperError();
  const creationStatus = useSelector(creationStatusSelector);
  const hasLoadError = useSelector(hasLoadErrorSelector);
  const requestStatus = useSelector(statusSelector);
  const pollStatus = useSelector(pollStatusSelector);
  const shouldAllow =
    hasError ||
    hasLoadError ||
    stepId === WizardStep.ASPECT_RATIO ||
    stepId === WizardStep.EXPORTING ||
    requestStatus === RequestStatus.FAILURE ||
    pollStatus === RequestStatus.FAILURE ||
    (stepId === WizardStep.SUBMITTING &&
      creationStatus === CreationStatus.COMPLETED) ||
    (stepId === WizardStep.SUBMITTING &&
      creationStatus === CreationStatus.FAILED) ||
    (stepId === WizardStep.SUBMITTING &&
      creationStatus === CreationStatus.EXPORTING_VIDEO);

  return useBlocker(!shouldAllow, MIXPANEL_WIZARD_STEP_MAP[stepId]);
};

export default useAudiogramWizardBlocker;
