import { createSelector } from 'reselect';
import { isUndefined } from 'underscore';

import { imageSearchEnginesOverrideSelector } from 'redux/modules/display-pref/selectors';
import { isFreeSelector } from 'redux/modules/pricing';
import { getValue } from 'utils/collections';
import { capitalize } from 'utils/string';
import { isAfterNow } from '../../../utils/time';
import {
  gettyCreativeExpiresAtMomentSelector,
  gettyExpiresAtMomentSelector,
  hasGettyAccessSelector,
  hasGettyCreativeAccessSelector,
  hasThinkstockAccessSelector,
  thinkstockExpiresAtMomentSelector,
} from '../oauth/selectors';
import {
  DEFAULT_IMAGE_SEARCH_ENGINES,
  ENABLED_IMAGE_SEARCH_ENGINES,
  PRO_IMAGE_SEARCH_ENGINES,
} from './constants';

export const apSelector = state => state.getIn(['imageSearch', 'ap']);
export const gettySelector = state => state.getIn(['imageSearch', 'getty']);
export const googleSelector = state => state.getIn(['imageSearch', 'google']);
export const microsoftSelector = state =>
  state.getIn(['imageSearch', 'microsoft']);
export const shutterstockSelector = state =>
  state.getIn(['imageSearch', 'shutterstock']);
export const thinkstockSelector = state =>
  state.getIn(['imageSearch', 'thinkstock']);
export const pixabaySelector = state => state.getIn(['imageSearch', 'pixabay']);
export const usaTodaySelector = state =>
  state.getIn(['imageSearch', 'usaToday']);
export const gettyCreativeSelector = state =>
  state.getIn(['imageSearch', 'gettyCreative']);
export const tonlSelector = state => state.getIn(['imageSearch', 'tonl']);

const engineStatesSelector = createSelector(
  [
    apSelector,
    gettySelector,
    googleSelector,
    microsoftSelector,
    shutterstockSelector,
    thinkstockSelector,
    pixabaySelector,
    usaTodaySelector,
    gettyCreativeSelector,
    tonlSelector,
  ],
  (
    ap,
    getty,
    google,
    microsoft,
    shutterstock,
    thinkstock,
    pixabay,
    usaToday,
    gettyCreative,
    tonl,
  ) => ({
    ap,
    getty,
    google,
    microsoft,
    shutterstock,
    thinkstock,
    pixabay,
    usaToday,
    gettyCreative,
    tonl,
  }),
);

/*
 * TODO this doesn't need to return a map of engine => boolean, it can just return an array of
 * valid engine strings
 */
export const engineAccessSelector = createSelector(
  [
    imageSearchEnginesOverrideSelector,
    hasGettyAccessSelector,
    hasThinkstockAccessSelector,
    hasGettyCreativeAccessSelector,
  ],
  (imageEngineOverrides, getty, thinkstock, gettyCreative) => {
    const engines = !imageEngineOverrides
      ? DEFAULT_IMAGE_SEARCH_ENGINES
      : imageEngineOverrides
          .toJS()
          .filter(engine => ENABLED_IMAGE_SEARCH_ENGINES.indexOf(engine) >= 0);

    const hasAccess = { getty, thinkstock, gettyCreative };
    return engines.reduce((acc, engine) => {
      const accessible = isUndefined(hasAccess[engine]) || hasAccess[engine];
      acc[engine] = accessible;
      return acc;
    }, {});
  },
);

// These are the image search engines that need to have their authentication renewed
export const expiredEngineSelector = createSelector(
  [
    engineAccessSelector,
    gettyExpiresAtMomentSelector,
    gettyCreativeExpiresAtMomentSelector,
    thinkstockExpiresAtMomentSelector,
  ],
  (
    engineAccess,
    gettyExpireMoment,
    gettyCreativeExpireMoment,
    thinkstockExpireMoment,
  ) => {
    const engineExpirationTime = {
      getty: gettyExpireMoment,
      gettyCreative: gettyCreativeExpireMoment,
      thinkstock: thinkstockExpireMoment,
    };

    const accessibleEngines = Object.keys(engineAccess);

    return Object.keys(engineExpirationTime).filter(
      engine =>
        engineExpirationTime[engine] &&
        !isAfterNow(engineExpirationTime[engine]) &&
        accessibleEngines.indexOf(engine) > -1,
    );
  },
);

function getEngineDisplayName(engine) {
  switch (engine) {
    case 'gettyCreative':
      return 'Getty Creative';
    case 'ap':
      return 'AP';
    case 'usaToday':
      return 'USA Today';
    case 'tonl':
      return 'TONL';
    default:
      return capitalize(engine);
  }
}

export const searchResultsSelector = createSelector(
  [engineStatesSelector, engineAccessSelector, isFreeSelector],
  (engineStates, engineAccess, isFree) =>
    Object.keys(engineStates)
      .filter(name => !isFree || !PRO_IMAGE_SEARCH_ENGINES.includes(name))
      .reduce(
        (acc, engine) =>
          engineAccess[engine]
            ? {
                ...acc,
                [engine]: {
                  data: engineStates[engine].get('data'),
                  displayName: getEngineDisplayName(engine),
                  totalResults: engineStates[engine].get('totalResults'),
                  error: engineStates[engine].get('error'),
                  isSearching: engineStates[engine].get('isFetching'),
                  query: engineStates[engine].get('q'),
                  pro: isFree && PRO_IMAGE_SEARCH_ENGINES.includes(engine),
                },
              }
            : acc,
        {},
      ),
);

export const isSearchingSelector = createSelector(
  [engineStatesSelector, engineAccessSelector],
  (engineStates, engineAccess) =>
    Object.keys(engineStates).reduce(
      (acc, engine) =>
        engineAccess[engine]
          ? acc || engineStates[engine].get('isFetching')
          : acc,
      false,
    ),
);

export const imageSearchQueriesSelector = createSelector(
  engineStatesSelector,
  engineStates =>
    Object.keys(engineStates).reduce((queries, name) => {
      const query = getValue(engineStates, [name, 'q']);
      if (query) {
        queries.push(query);
      }
      return queries;
    }, []),
);

export const defaultImageSearchEngineSelector = createSelector(
  [engineAccessSelector, isFreeSelector],
  (engineAccess, isFree) => {
    // as per business requirement, if getty is available, use that as default
    // selected search engine. else, just take the first one
    // if user's plan is FREE, none of the engines in PRO_IMAGE_SEARCH_ENGINES cannot be selected as default
    const defaultEngine = 'textToImage';

    if (!isFree && engineAccess[defaultEngine]) {
      return defaultEngine;
    }

    const enabledEngines = Object.keys(engineAccess).filter(engine =>
      isFree
        ? !PRO_IMAGE_SEARCH_ENGINES.includes(engine) && engineAccess[engine]
        : engineAccess[engine],
    );

    return enabledEngines && enabledEngines[0];
  },
);
