import { Button } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { ChipListElement } from './types';
import { block } from './utils';

interface ChipElementProps {
  element: ChipListElement;
  onClick: (value: string) => void;
}

const ChipElement: React.FC<ChipElementProps> = ({ element, onClick }) => {
  const { label, value } = element;

  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  return (
    <Button className={block('chip-element-button')} onClick={handleClick}>
      {label}
    </Button>
  );
};

export default ChipElement;
