import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProjectHistory from 'blocks/ProjectHistory';
import { InfiniteScrollProps } from 'components/InfiniteScroll';
import { getProjectHistory } from 'redux/modules/project/actions';
import {
  isLoadingHistorySelector,
  projectHistoryHasMoreSelector,
} from 'redux/modules/project/selectors';
import AutomationCMSInfiniteScroll from '../components/AutomationCMSInfiniteScroll';
import { cmsBlock as block } from '../utils';

type PickedInfiniteProps = Pick<InfiniteScrollProps, 'getScrollParent'>;

interface Props extends PickedInfiniteProps {
  active: boolean;
}

function ProjectFeedData(props: Props) {
  const { getScrollParent, active } = props;
  const dispatch = useDispatch();
  const [itemsRendered, setItemsRendered] = useState(true);
  const hasMore = useSelector(projectHistoryHasMoreSelector);
  const isLoading = useSelector(isLoadingHistorySelector);
  const handleItemsRendered = useCallback(() => setItemsRendered(true), []);

  const handleLoadMore = useCallback(
    pg => {
      setItemsRendered(false);
      dispatch(getProjectHistory(pg !== 1));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(getProjectHistory(false));
  }, [dispatch]);

  return (
    <AutomationCMSInfiniteScroll
      getScrollParent={getScrollParent}
      hasMore={itemsRendered && hasMore}
      isLoading={isLoading}
      active={active}
      onLoadMore={handleLoadMore}
    >
      <div className={block('heading')}>
        <span className={block('podcast-title')}>All projects</span>
      </div>
      <ProjectHistory
        isLoadingHistory={isLoading}
        onItemsRendered={handleItemsRendered}
      />
    </AutomationCMSInfiniteScroll>
  );
}

export default ProjectFeedData;
