import { combineReducers } from 'redux-immutable';

import { Type } from './action-types';
import { engineFactory } from './factories';
import { Engine, GifSearchAction } from './types';

const defaultState = engineFactory();

const createReducer = (engine: Engine) => (
  state = defaultState,
  action: GifSearchAction,
) => {
  switch (action.type) {
    case Type.GIFS_GET_REQUEST: {
      const { engine: actionEngine } = action.payload;
      if (actionEngine === engine) {
        return state.withMutations(s =>
          s.set('isFetching', true).set('error', false),
        );
      }
      return state;
    }

    case Type.GIFS_GET_SUCCESS: {
      const { content, queries, engine: actionEngine } = action.payload;
      if (actionEngine === engine) {
        return state.withMutations(s =>
          s
            .update('data', d => (d && d.concat(content)) || content)
            .set('error', false)
            .set('page', queries.current.page + 1)
            .set('pageSize', queries.current.pageSize)
            .set('totalResults', queries.current.totalResults)
            .set('q', queries.current.q)
            .set('isFetching', false),
        );
      }
      return state;
    }

    case Type.GIFS_GET_FAILURE: {
      const { engine: actionEngine } = action.payload;
      if (actionEngine === engine) {
        return state.withMutations(s =>
          s.set('isFetching', false).set('error', true),
        );
      }
      return state;
    }

    case Type.GIF_SEARCH_RESULTS_CLEAR: {
      const { engine: actionEngine } = action.payload;
      if (actionEngine === engine) {
        return defaultState;
      }
      return state;
    }

    case Type.GIF_SEARCH_RESULTS_CLEAR_ALL:
      return defaultState;

    default:
      return state;
  }
};

export default combineReducers({
  tenor: createReducer('tenor'),
  giphy: createReducer('giphy'),
  giphySticker: createReducer('giphySticker'),
});
