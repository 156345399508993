import React, { useCallback } from 'react';

import { Crop } from 'components/icons';

import OptionTile from '../assets/OptionTile';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { useModalManager } from '../ModalManager';
import { hasWatermark } from '../state';

export interface CropWatermarkTileProps {
  className?: string;
}

const CropWatermarkTile: React.FC<CropWatermarkTileProps> = props => {
  const { className } = props;
  const dispatch = useTemplateDispatch();
  const { showModal } = useModalManager();
  const state = useTemplateState();

  const disabled = !hasWatermark(state);

  const handleCrop = useCallback(() => {
    // However tile will be disabled, this ensures no action is dispatched
    // if asset type is not an watermark.
    if (disabled) return;

    const { watermark } = state;

    showModal('crop', {
      defaultMetadata: watermark.metadata,
      fileType:
        typeof watermark.originalUrl === 'string'
          ? undefined
          : watermark.originalUrl?.type,
      imgSrc: watermark.originalUrl,
      onSubmit: (src, metadata) => {
        dispatch({
          payload: { src, metadata },
          type: 'WATERMARK_CROP',
        });
      },
    });
  }, [disabled, dispatch, showModal, state]);

  return (
    <OptionTile
      className={className}
      disabled={disabled}
      disabledTooltipText="Only available for watermark"
      onClick={handleCrop}
      icon={<Crop style={{ height: 40, width: 'auto' }} />}
      label="crop"
    />
  );
};

export default CropWatermarkTile;
