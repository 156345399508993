import { ColorPaletteState } from '@sparemin/blockhead';
import React, { useCallback, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'underscore';
import { AiImageType } from 'redux/middleware/api/image-search-service';
import { hasTextToVideoAccessSelector } from 'redux/modules/display-pref';
import { isPollingImageToVideoSelector } from 'redux/modules/image-to-video/selectors';
import { onVideoSearch } from 'redux/modules/mixpanel';

import { AspectRatioName } from 'types';
import AIMediaLoader from '../AIMediaLoader';
import ProvidersList, { ProvidersListProps } from '../ProvidersList';
import SearchFooter from '../SearchFooter';
import SearchResults, {
  IFooterProps,
  OnLoadMore,
  SearchResultsProps,
} from '../SearchResults';
import { EngineRegistry } from '../types';
import { searchResultsBlock } from '../utils';
import { VideoSearchResults as VideoSearchResultsType } from './types';
import useTextToVideo from './useTextToVideo';
import useVideoSearchResultsTopSlot from './useVideoSearchResultsTopSlot';
import { block } from './utils';
import VideoTile from './VideoTile';

export interface VideoSerchResultsProps
  extends Pick<SearchResultsProps, 'onEngineSelect'>,
    Pick<ProvidersListProps, 'navContainerClassName'> {
  query: string;
  selectedEngine?: string;
  results: VideoSearchResultsType;
  aspectRatioName?: AspectRatioName;
  imageType?: AiImageType;
  colorPaletteState: ColorPaletteState;
  onImageTypeChange: (value: AiImageType) => void;
  onAspectRatioNameChange: (value: AspectRatioName) => void;
  onGenerate?: () => void;
  onLoadMoreResults?: OnLoadMore;
  onVideoClick?: (url: string, poster: string, engine: string) => void;
  onUnlockFeatureClick?: () => void;
  onTextToVideoSuccess: (url: string, poster: string) => void;
}

const VideoSearchResults: React.FC<VideoSerchResultsProps> = ({
  query,
  selectedEngine = 'textToVideo',
  results,
  aspectRatioName,
  imageType,
  colorPaletteState,
  onImageTypeChange,
  onAspectRatioNameChange,
  onGenerate,
  onLoadMoreResults,
  onVideoClick,
  onUnlockFeatureClick,
  onEngineSelect,
  onTextToVideoSuccess,
}) => {
  const isPollingImageToVideo = useSelector(isPollingImageToVideoSelector);
  const hasTextToVideoAccess = useSelector(hasTextToVideoAccessSelector);
  const dispatch = useDispatch();

  const handleVideoClick = useCallback(
    (src: string, poster: string, engine: string) => () => {
      onVideoClick(src, poster, engine);
    },
    [onVideoClick],
  );

  const handleGenerate = useCallback(() => {
    dispatch(onVideoSearch('AI', query));
    onGenerate();
  }, [dispatch, onGenerate, query]);

  const {
    engine: textToVideoEngine,
    renderInitialContent: textToVideoInitialContent,
    renderErrorMessage: textToVideoErrorMessage,
    renderResults: textToVideoResults,
    selectedImage: textToVideoSelectedImage,
    hasError: textToVideoHasError,
  } = useTextToVideo({ onUnlockFeatureClick, onTextToVideoSuccess });

  const initialContent =
    selectedEngine === 'textToVideo' && textToVideoInitialContent;

  const engines = useMemo((): EngineRegistry => {
    return Object.keys(results).reduce(
      (acc, engine) => {
        const engineData = results[engine];

        acc[engine] = {
          displayName: engineData.displayName,
          errorMessage: engineData.error,
          hasMoreResults:
            engineData.data && engineData.data.length < engineData.totalResults,
          hasResults: !!engineData.data,
          isSearching: engineData.isSearching,
          pro: !!engineData.pro,
        };

        return acc;
      },
      {
        ...(hasTextToVideoAccess && { textToVideo: textToVideoEngine }),
      },
    );
  }, [hasTextToVideoAccess, results, textToVideoEngine]);

  const topSlot = useVideoSearchResultsTopSlot({
    hasQuery: Boolean(query?.length),
    selectedEngine,
    engines: pick(engines, [selectedEngine]),
    aspectRatioName,
    imageType,
    colorPaletteState,
    onImageTypeChange,
    onAspectRatioNameChange,
    onGenerate: handleGenerate,
  });

  const handleRenderProvidersList = useCallback(() => {
    return (
      <div className={searchResultsBlock('multiple-providers-list')}>
        <ProvidersList
          title="AI generated"
          engines={pick(engines, ['textToVideo'])}
          isDisabled={isPollingImageToVideo}
        />

        <ProvidersList
          engines={pick(engines, ['pixabay'])}
          noProvidersMessage="Sorry, but there are no video providers for this account."
          isDisabled={isPollingImageToVideo}
        />
      </div>
    );
  }, [engines, isPollingImageToVideo]);

  const handleRenderFooter = useCallback(
    (footerProps: IFooterProps) => {
      if (selectedEngine !== 'textToVideo') {
        return (
          <SearchFooter
            className={footerProps.className}
            engineList={Object.keys(results)}
            selectedEngine={selectedEngine}
            searchType="video"
          />
        );
      }

      return undefined;
    },
    [results, selectedEngine],
  );

  const handleRenderResults = useCallback(
    (engine: string) => {
      if (engine === 'textToVideo') {
        if (textToVideoHasError) {
          return [textToVideoErrorMessage];
        }

        return [textToVideoResults];
      }

      return results[engine].data.map(result => (
        <Col
          className={block('video-result')}
          xs={12}
          sm={6}
          lg={4}
          onClick={handleVideoClick(result.src, result.poster, engine)}
          key={result.src}
        >
          <VideoTile {...result} src={result.previewSrc || result.src} />
        </Col>
      ));
    },
    [
      handleVideoClick,
      results,
      textToVideoErrorMessage,
      textToVideoHasError,
      textToVideoResults,
    ],
  );

  return (
    <SearchResults
      id="media-search__video-results"
      active={selectedEngine}
      className={block('video-results')}
      customLoader={
        selectedEngine === 'textToVideo' ? (
          <AIMediaLoader
            aspectRatio={aspectRatioName}
            sourceImageUrl={textToVideoSelectedImage}
            targerMediaType="video"
          />
        ) : (
          undefined
        )
      }
      engines={engines}
      itemSelector={`.${block('video-result')}`}
      onEngineSelect={onEngineSelect}
      onLoadMore={onLoadMoreResults}
      initialContent={initialContent}
      providersList={handleRenderProvidersList()}
      renderFooter={handleRenderFooter}
      renderResults={handleRenderResults}
      topSlot={topSlot}
    />
  );
};

export default VideoSearchResults;
