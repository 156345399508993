import cn from 'classnames';
import * as React from 'react';

import PlanFeature, { PlanFeatureProps } from './PlanFeature';
import { block } from './utils';

export interface PlanFeatureListProps extends Pick<PlanFeatureProps, 'theme'> {
  className?: string;
  featureClassName?: string;
  features: PlanFeatureProps[];
}

const PlanFeatureList: React.SFC<PlanFeatureListProps> = ({
  className,
  featureClassName,
  features,
  theme,
}) => (
  <>
    <ul className={cn(block('features'), className)}>
      {features.map(feature => (
        <PlanFeature
          className={featureClassName}
          {...feature}
          theme={theme}
          key={feature.id}
        />
      ))}
    </ul>
  </>
);

export default PlanFeatureList;
