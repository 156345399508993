import { Button } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BetaBadge from 'components/BetaBadge';
import { ImageSpeechBubble, ProBadge } from 'components/icons';
import {
  AiAsset,
  IMediaMetadata,
} from 'redux/middleware/api/image-search-service';
import { isFreeSelector } from 'redux/modules/clip-select';
import { onClickAnimateImage } from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal';
import {
  imageHasTextToImagesSelector,
  imageTextToImageHasErrorSelector,
  imageTextToImagesSelector,
  isImagePollingTextToImageSelector,
} from 'redux/modules/text-to-image/selectors';
import {
  AIGenerationErrorMessage,
  AIGenerationInitialContents,
  AIGenerationResults,
  useImageToVideo,
} from '../AIGeneration';
import { UseAiAssetGenerationResults } from '../types';
import { block } from './utils';

export interface UseTextToImageProps {
  onImageClick?: (
    url: string,
    metadata: IMediaMetadata,
    engine: string,
  ) => void;
  onTextToImageSuccess: (url: string, poster: string) => void;
  onAnimateGatedClick?: () => void;
}

export default function useTextToImage({
  onImageClick,
  onTextToImageSuccess,
  onAnimateGatedClick,
}: UseTextToImageProps): UseAiAssetGenerationResults {
  const [selectedImage, setSelectedImage] = useState<string | undefined>();

  const images = useSelector(imageTextToImagesSelector);
  const isFree = useSelector(isFreeSelector);
  const isTextToImageLoading = useSelector(isImagePollingTextToImageSelector);
  const hasTextToImage = useSelector(imageHasTextToImagesSelector);
  const hasTextToImageError = useSelector(imageTextToImageHasErrorSelector);

  const {
    isLoading: isImageToVideoLoading,
    hasError: hasImageToVideoError,
    onAnimate,
  } = useImageToVideo({
    image: selectedImage,
    onImageToVideoSuccess: onTextToImageSuccess,
  });

  const dispatch = useDispatch();

  const isLoading = isTextToImageLoading || isImageToVideoLoading;
  const hasError = hasTextToImageError || hasImageToVideoError;
  const hasResults = hasTextToImage || hasError;

  const handleAnimate = useCallback(
    (image: AiAsset): void => {
      dispatch(
        onClickAnimateImage(isFree ? 'Gated' : 'Ungated', 'textToImage'),
      );

      if (isFree) {
        onAnimateGatedClick?.();

        dispatch(
          replaceModal({
            name: 'AnimateImagesUpsell',
          }),
        );

        return undefined;
      }

      const { url } = image;

      setSelectedImage(url);
      onAnimate(url);

      return undefined;
    },
    [dispatch, isFree, onAnimate, onAnimateGatedClick],
  );

  return {
    hasError,
    engine: {
      displayName: 'Text to image',
      hasResults,
      isLoading,
    },
    renderInitialContent: (
      <AIGenerationInitialContents
        icon={<ImageSpeechBubble className={block('ai-generated-icon')} />}
        title="Create any image you can imagine!"
        subtitle="Type any prompt above and create an image that is uniquely yours."
      />
    ),
    renderResults: (
      <AIGenerationResults
        aiAssets={images}
        overlayActions={image => (
          <>
            <Button
              variant="ghost"
              className={block('ai-generated-overlay-action')}
              onPress={() => onImageClick(image.url, null, 'textToImage')}
            >
              Add to canvas
            </Button>
            <Button
              variant="ghost"
              className={block('ai-generated-overlay-action')}
              onPress={() => handleAnimate(image)}
            >
              Animate
              {isFree ? <ProBadge /> : <BetaBadge />}
            </Button>
          </>
        )}
      />
    ),
    renderErrorMessage: (
      <AIGenerationErrorMessage
        title="Sorry, something went wrong"
        subtitle="Please try to submit your prompt again to generate your images."
      />
    ),
    selectedImage,
  };
}
