import cn from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EpisodeListEpisodeDescription from 'blocks/AutomationCMS/components/EpisodeListEpisodeDescription';
import EpisodeListRow from 'blocks/AutomationCMS/components/EpisodeListRow';
import BemCSSTransition from 'components/BemCssTransition';
import Button from 'components/Button';
import { H4 } from 'components/Heading';
import { InfoGradient } from 'components/icons';
import LinkButton from 'components/LinkButton';
import {
  podcastArtworkSelector,
  podcastInfoSelector,
} from 'redux/modules/clip-select';

import { pushModal } from 'redux/modules/modal';
import { RequestStatus } from 'types';
import { headerBlock as block } from '../utils';
import { useClipSelectAnimation } from './ClipSelectAnimationContext';
import { useClipSelect } from './ClipSelectContext';
import { useClipSelectNavigation } from './ClipSelectNavigationContext/ClipSelectNavigationContext';

export interface ClipSelectHeaderProps {
  className?: string;
}

const ClipSelectHeader: React.FC<ClipSelectHeaderProps> = ({ className }) => {
  const dispatch = useDispatch();
  const { submitStatus, visibleSuggestionIds } = useClipSelect();
  const [, send] = useClipSelectNavigation();
  const animations = useClipSelectAnimation();
  const imgSrc = useSelector(podcastArtworkSelector);
  const podcastInfo = useSelector(podcastInfoSelector);

  const handleReturnClick = useCallback(() => {
    send({ type: 'CLIPPER_CLOSE' });
  }, [send]);

  const handleLearnClick = useCallback(() => {
    dispatch(pushModal({ name: 'ClipSelectEducation' }));
  }, [dispatch]);

  return (
    <div className={cn(block(), className)}>
      <EpisodeListRow
        className={block('podcast-row')}
        active={false}
        style={{ height: 'auto' }}
      >
        <EpisodeListRow.Container
          className={block('podcast-row-container')}
          collapsible
        >
          <EpisodeListRow.Artwork>
            <img src={imgSrc} />
          </EpisodeListRow.Artwork>
          {podcastInfo && (
            <EpisodeListEpisodeDescription
              title={podcastInfo.episodeName}
              podcastName={podcastInfo.podcastName}
              durationMillis={podcastInfo.durationMillis}
              publishedAtMillis={podcastInfo.publishedAtMillis}
            />
          )}
        </EpisodeListRow.Container>
      </EpisodeListRow>
      {visibleSuggestionIds?.length > 0 ? (
        <>
          <BemCSSTransition {...animations.learnButton}>
            <LinkButton onClick={handleLearnClick}>
              <InfoGradient className={block('learn-icon')} />{' '}
              <H4 className={block('learn-text')}>Learn how we select clips</H4>
            </LinkButton>
          </BemCSSTransition>
          <BemCSSTransition {...animations.closeClipperButton}>
            <div>
              <Button
                disabled={submitStatus === RequestStatus.REQUEST}
                onClick={handleReturnClick}
                type="button"
                theme="submit-alt"
              >
                return to suggested clips
              </Button>
            </div>
          </BemCSSTransition>
        </>
      ) : null}
    </div>
  );
};

export default ClipSelectHeader;
