import { TooltipProps } from '@sparemin/blockhead';
import { sortBy, union } from 'underscore';

import { Option } from 'components/Select';
import {
  TikTokPrivacyLevelOption,
  TikTokPrivacyLevelOptionName,
} from 'redux/middleware/api/third-party-authentication-service/types';
import { TikTokVisibilityOption } from './types';

export const getSelectedOptionValue = (option: Option): string | undefined => {
  return String(option.value);
};

export const getVisibilityOptionTooltipConfig = (
  option: TikTokPrivacyLevelOptionName,
  isDisabled?: boolean,
): TooltipProps => {
  switch (option) {
    case 'selfOnly': {
      return {
        contents: 'Branded content visibility cannot be set to private.',
        isDisabled: !isDisabled,
      };
    }

    default: {
      return {
        contents: '',
        isDisabled: true,
      };
    }
  }
};

export const formatVisibilityOptions = (
  privacyLevelOptions: TikTokPrivacyLevelOption[],
  disabledKeys: TikTokPrivacyLevelOptionName[] = [],
): TikTokVisibilityOption[] =>
  union(
    sortBy(
      privacyLevelOptions?.map(({ label, name }) => {
        const isDisabled = disabledKeys.includes(name);

        return {
          label,
          value: name,
          isDisabled,
          tooltipConfig: getVisibilityOptionTooltipConfig(name, isDisabled),
        };
      }),
      option => option.label,
    ),
  );

export const getVisibilityOption = (
  options: Option[],
  value?: string,
): Option => {
  return options.find(opt => opt.value === value);
};
