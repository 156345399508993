import React from 'react';

import { IconProps } from './types';

const TiktokRounded: React.FunctionComponent<IconProps> = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <filter
        x="-25%"
        y="-21.9%"
        width="150%"
        height="150%"
        filterUnits="objectBoundingBox"
        id="d5tt1pxtva"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="2.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <circle id="g8ri8wsweb" cx="16" cy="16" r="16" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="rotate(180 16 16)" fill="#000">
        <use filter="url(#d5tt1pxtva)" xlinkHref="#g8ri8wsweb" />
        <circle
          stroke="#464E5E"
          strokeLinejoin="inherit"
          cx="16"
          cy="16"
          r="15.5"
        />
      </g>
      <g fillRule="nonzero">
        <path
          d="M20.117 13.776A6.93 6.93 0 0 0 24 14.948V12.32c-.274 0-.547-.027-.816-.08v2.069a6.93 6.93 0 0 1-3.883-1.172V18.5c0 2.683-2.306 4.859-5.15 4.859a5.346 5.346 0 0 1-2.868-.822A5.29 5.29 0 0 0 14.966 24c2.845 0 5.15-2.176 5.15-4.86v-5.364zm1.006-2.653a3.57 3.57 0 0 1-1.006-2.145V8.64h-.773c.194 1.047.858 1.941 1.779 2.483zm-8.04 9.356a2.133 2.133 0 0 1-.481-1.346c0-1.228 1.055-2.223 2.356-2.223.243 0 .484.035.715.104v-2.687a5.502 5.502 0 0 0-.815-.045v2.092a2.489 2.489 0 0 0-.715-.104c-1.302 0-2.357.995-2.357 2.223 0 .868.528 1.62 1.297 1.986z"
          fill="#FF004F"
        />
        <path
          d="M19.301 13.136a6.93 6.93 0 0 0 3.883 1.172v-2.07a3.958 3.958 0 0 1-2.061-1.115c-.921-.542-1.585-1.436-1.78-2.483h-2.029v10.5c-.005 1.225-1.058 2.216-2.356 2.216a2.403 2.403 0 0 1-1.876-.877c-.768-.367-1.296-1.118-1.296-1.986 0-1.228 1.055-2.223 2.356-2.223.25 0 .49.037.715.104v-2.092c-2.794.055-5.042 2.209-5.042 4.858 0 1.323.56 2.522 1.468 3.398.82.52 1.806.822 2.868.822 2.844 0 5.15-2.176 5.15-4.86v-5.364z"
          fill="#FFF"
        />
        <path
          d="M23.184 12.239v-.56a4.057 4.057 0 0 1-2.061-.556 3.955 3.955 0 0 0 2.061 1.116zm-3.84-3.599a3.55 3.55 0 0 1-.043-.302V8h-2.803v10.5c-.004 1.225-1.057 2.216-2.356 2.216-.38 0-.74-.086-1.06-.237.43.533 1.11.877 1.876.877 1.298 0 2.351-.991 2.356-2.216V8.64h2.03zm-4.486 5.642v-.595a5.507 5.507 0 0 0-.707-.046c-2.845 0-5.151 2.176-5.151 4.86 0 1.682.906 3.165 2.284 4.037-.909-.876-1.468-2.075-1.468-3.398 0-2.649 2.247-4.803 5.042-4.858z"
          fill="#00F2EA"
        />
      </g>
    </g>
  </svg>
);

export default TiktokRounded;
