import * as React from 'react';

import { Omit } from 'types';
import Track, { TrackProps } from '../../components/Track';
import { block } from '../../utils';
import TextOverlayAsset from '../TextOverlayAsset';

type PickedTrackProps = Omit<TrackProps, 'children' | 'className'>;

export interface TextTrackProps extends PickedTrackProps {
  assetIds: string[];
  className?: string;
  onAssetClick?: (id: string) => void;
  pxPerSec: number;
}

export default class TextTrack extends React.Component<TextTrackProps> {
  public static defaultProps: Partial<TextTrackProps> = {
    assetIds: [],
  };

  private handleClick = (id: string) => () => this.props.onAssetClick(id);

  public render() {
    const { assetIds, id, pxPerSec, onAssetClick, ...trackProps } = this.props;

    return (
      <Track id={id} {...trackProps} className={block('track', { text: true })}>
        {assetIds.map(assetId => (
          <TextOverlayAsset
            key={assetId}
            id={assetId}
            onClick={this.handleClick(assetId)}
            pxPerSec={pxPerSec}
          />
        ))}
      </Track>
    );
  }
}
