import { connect } from 'react-redux';

import {
  performDpa,
  sendDownloadPageActionEvent,
} from 'redux/modules/download';
import { onClickPostFacebookVideo } from 'redux/modules/mixpanel/actions';
import { Dispatch } from 'redux/types';
import { PropsOf } from 'types';
import FacebookPostButton, {
  FacebookPostButtonProps,
} from './FacebookPostButton';

type DispatchProps = Pick<FacebookPostButtonProps, 'onClick'>;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onClick: () => {
    dispatch(performDpa);
    dispatch(onClickPostFacebookVideo());
    dispatch(sendDownloadPageActionEvent());
  },
});

const component = connect(null, mapDispatchToProps)(FacebookPostButton);
export type FacebookButtonProps = PropsOf<typeof component>;
export default component;
