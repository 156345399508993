import { createSelector } from 'reselect';
import { State } from 'redux/types';
import { ImageToVideoState } from './types';

const stateSelector = (state: State): ImageToVideoState =>
  state.get('imageToVideo');

export const isPollingImageToVideoSelector = createSelector(
  stateSelector,
  state => state.isPollingImageToVideo,
);

export const imageToVideoSelector = createSelector(
  stateSelector,
  state => state.videos,
);

export const hasImageToVideoSelector = createSelector(
  imageToVideoSelector,
  videos => !!videos?.length,
);

export const imageToVideoHasErrorSelector = createSelector(
  stateSelector,
  state => state.hasError,
);

export const imageToVideoGenerationSucceededSelector = createSelector(
  stateSelector,
  state => state.imageToVideoGenerationSucceeded,
);

export const imageToVideoSourceImageUrlSelector = createSelector(
  stateSelector,
  state => state.sourceImageUrl,
);

export const shouldCancelPollingSelector = createSelector(
  stateSelector,
  state => state.shouldCancelPolling,
);
