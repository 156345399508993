import { Heading, Link, Spacer } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { Alert } from 'components/icons';
import Modal from 'components/Modal';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import { block } from './utils';

interface QualityReductionAlertModalContentsProps {}

const QualityReductionAlertModalContents: React.FC<QualityReductionAlertModalContentsProps> = () => {
  const { onHide } = useConnectedModal('QualityReductionAlert');

  const handleContinue = useCallback(
    (shouldContinueWithVideo: boolean) => (): void => {
      onHide(shouldContinueWithVideo);
    },
    [onHide],
  );

  return (
    <>
      <Modal.Body>
        <Spacer
          orientation="vertical"
          align="center"
          className={block('contents')}
        >
          <Spacer orientation="vertical" align="center" space="13px">
            <Alert width={100} height={100} className={block('alert-icon')} />

            <Heading level={1}>Your video quality may be reduced</Heading>

            <Heading level={2}>
              To ensure that your video can be uploaded to YouTube and social
              platforms, we may need to reduce its quality. This change should
              barely be noticeable, but if you prefer to avoid quality reduction
              you can{' '}
              <Link
                href="https://learn.headliner.app/hc/en-us/articles/21330472591255-How-to-reformat-your-videos-to-avoid-quality-reduction-issues-on-Make"
                target="_blank"
              >
                learn how to avoid it here
              </Link>
              .
            </Heading>
          </Spacer>
        </Spacer>
      </Modal.Body>

      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton
            theme="submit-alt"
            onClick={handleContinue(false)}
          >
            Upload another file
          </Modal.FooterButton>

          <Modal.FooterButton theme="submit" onClick={handleContinue(true)}>
            Continue with this video
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default QualityReductionAlertModalContents;
