import * as React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import { block } from './utils';
import VerifyEmailModalContent from './VerifyEmailModalContent';

const UpdateEmailModal = () => {
  return (
    <ConnectedModal
      name="VerifyEmail"
      title="Verify Your Email"
      className={block()}
    >
      <VerifyEmailModalContent />
    </ConnectedModal>
  );
};

export default UpdateEmailModal;
