import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import useQuery from 'hooks/useQuery';
import {
  createMethodSelector,
  isLoadingSelector,
  loadingStateSelector,
} from 'redux/modules/download';
import { ProjectCreationMethod, RequestStatus } from 'types';
import { isAutogramReview } from '../utils';

interface UseDownloadPageStateResult {
  createMethod: ProjectCreationMethod;
  isAutogramReview: boolean;
  isLoading: boolean;
  loadingState: RequestStatus;
  wid: string | undefined;
}

export default function useDownloadPageState(): UseDownloadPageStateResult {
  const query = useQuery();
  const {
    params: { wid },
  } = useRouteMatch<{ wid?: string }>();
  const createMethod = useSelector(createMethodSelector);
  const loadingState = useSelector(loadingStateSelector);
  const isLoading = useSelector(isLoadingSelector);
  const autogramReview = isAutogramReview(query);

  return {
    createMethod,
    isLoading,
    loadingState,
    wid,
    isAutogramReview: autogramReview,
  };
}
