import React from 'react';
import { useDispatch } from 'react-redux';

import DialogContent from 'components/DialogContent';
import { H2 } from 'components/Heading';
import { CircularGradientWatermark } from 'components/icons/circular-gradient';
import { Tier } from 'redux/middleware/api/plan-service';
import { onUnlockThisFeature } from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal';

const RemoveWatermarksModalContents: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const handleUpgrade = React.useCallback(() => {
    dispatch(onUnlockThisFeature('AutogramWatermarks'));
    dispatch(
      replaceModal({
        name: 'AddBillingCycle',
        params: {
          tier: Tier.PRO,
          defaultSubscriptionPeriod: 'yearly',
          disableSuccessRedirect: true,
        },
      }),
    );
  }, [dispatch]);

  return (
    <DialogContent
      actions={[
        {
          children: 'upgrade to remove our watermarks',
          key: 'confirm',
          onClick: handleUpgrade,
          theme: 'rainbow',
        },
      ]}
      icon={<CircularGradientWatermark />}
      title="Want to remove our watermarks?"
      details={
        <H2>
          We’ll automatically create as many videos as you’d like, but after you
          exceed a certain limit we’ll begin adding a Headliner watermark to
          each video.
        </H2>
      }
    />
  );
};

export default RemoveWatermarksModalContents;
