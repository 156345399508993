import { connect } from 'react-redux';

import { Tier } from 'redux/middleware/api/plan-service';
import { lockedFieldsSelector } from 'redux/modules/display-pref';
import { exportEmbedVideo } from 'redux/modules/embed-export/actions';
import {
  onClickExportUpgrade,
  onClickProFeature,
} from 'redux/modules/mixpanel/actions';
import { replaceModal } from 'redux/modules/modal';
import { showError } from 'redux/modules/notification/actions';
import {
  exportBalanceSelector,
  headlinerWatermarkEnabledSelector,
  loadMyPricingData,
} from 'redux/modules/pricing';
import { Dispatch, State, ThunkAction } from 'redux/types';
import { ExportUpgradeFrom, PropsOf, PropUpgradeFrom } from 'types';

import { defaultFormStateSelector } from '../../selectors';
import VideoExportModalContents, {
  ExportConfig,
  VideoExportModalContentsProps as Props,
} from './VideoExportModalContents';

type StateProps = Pick<
  Props,
  'defaultFormState' | 'lockedFields' | 'videoBalance'
>;
type DispatchProps = Pick<
  Props,
  'onError' | 'onExport' | 'onMount' | 'onUpgrade' | 'onUpgradePlan'
>;

const handleExport = (config: ExportConfig): ThunkAction<any> => (
  dispatch,
  getState,
) =>
  dispatch(
    exportEmbedVideo({
      ...config,
      headlinerWatermarkEnabled: headlinerWatermarkEnabledSelector(getState()),
    }),
  );

const mapStateToProps = (state: State): StateProps => ({
  defaultFormState: defaultFormStateSelector(state),
  lockedFields: lockedFieldsSelector(state),
  videoBalance: exportBalanceSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onError: message => dispatch(showError(message)),
  onExport: config => dispatch(handleExport(config)),
  onMount: hasLimitedExports =>
    hasLimitedExports && dispatch(loadMyPricingData()),
  onUpgrade: (from: ExportUpgradeFrom) => {
    dispatch(onClickExportUpgrade(from));
  },
  onUpgradePlan: (from?: PropUpgradeFrom) => {
    if (from) {
      dispatch(onClickProFeature({ from }));
    }
    dispatch(
      replaceModal({
        name: 'AddBillingCycle',
        params: {
          tier: Tier.PRO,
          defaultSubscriptionPeriod: 'yearly',
          disableSuccessRedirect: true,
        },
      }),
    );
  },
});

const component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoExportModalContents);

export type VideoExportModalContentsProps = PropsOf<typeof component>;

export default component;
