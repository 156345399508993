import * as React from 'react';
import { IconProps } from './types';

function PlayButtonCircle(props: IconProps) {
  return (
    <svg width={16} height={16} {...props}>
      <path
        d="M8 0a8 8 0 110 16A8 8 0 018 0zM6.08 5.398V10.6c0 .47.494.752.88.517l4.274-2.6a.61.61 0 000-1.033l-4.275-2.6a.579.579 0 00-.879.515z"
        fill="#19B5FE"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default PlayButtonCircle;
