import _ from 'underscore';

import { getValue } from 'utils/collections';
import { rampToGainAtTime, setGainAtTime } from './common';
import {
  scheduleGainChanges,
  shouldUseImmediateTransition,
} from './gain-mixer';
import { IAudioLevel, IAudioSource, IWebAudioSource } from './types';

interface ISources {
  [id: string]: IWebAudioSource;
}

export default class BaseAudio {
  protected audioCtx: AudioContext;
  protected durationSeconds: number;
  protected pausedAt: number;
  protected playing: boolean;
  protected startedAt: number;
  protected sources: ISources = {};
  protected masterGain: number;

  constructor(
    ctx: AudioContext,
    durationSeconds: number,
    sources: IAudioSource[],
  ) {
    this.audioCtx = ctx;
    this.sources = sources.reduce((acc, source) => {
      acc[source.id] = source;
      acc[source.id].gainNode = ctx.createGain();
      return acc;
    }, {} as ISources);

    this.durationSeconds = durationSeconds;
    this.masterGain = 1;
    this.resetPlayTrackingVariables();
  }

  private resetPlayTrackingVariables() {
    this.startedAt = 0;
    this.pausedAt = 0;
    this.playing = false;
  }

  protected addSources(sources: IAudioSource[]) {
    if (!sources) return;
    sources.forEach(newSource => {
      const oldSource = this.sources[newSource.id];

      if (oldSource) {
        this.deleteSource(oldSource.id);
      }

      this.sources[newSource.id] = newSource;
    });
  }

  protected deleteSource(id: string | string[]) {
    if (_.isUndefined(id)) return;
    (Array.isArray(id) ? id : [id]).forEach(sourceId => {
      if (!this.sources[sourceId]) return;
      const { node } = this.sources[sourceId];

      if (node) {
        node.disconnect();
      }

      delete this.sources[sourceId];
    });
  }

  protected startPlayback(offsetSec?: number) {
    if (this.playing) return;

    /*
     * https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
     *
     * NOTE we might have to revisit this.  the link above provides an example where execution
     * continues in resume().then().  the same link provides a PR as an example of how easy it is to
     * work with chrome's new autoplay rules and the code in the PR doesn't wait for the resume()
     * promise to resolve.
     */
    if (this.audioCtx.state === 'suspended') {
      this.audioCtx.resume();
    }

    const offset = !_.isUndefined(offsetSec) ? offsetSec : this.pausedAt;

    /*
     * where playback begins.  easiest to think about this by looking at getCurrentTime.
     *
     * for example:
     *  the audio is stopped and seeked to 5sec.
     *  audio context current time is 123sec
     *  startedAt = 123 - 5 = 118sec
     *
     *  now we know we are 5sec into the audio, so if no time passes and we issue getCurrentTime
     *  immediately after this, we'll get currentTime - startedAt = elapsed which is
     *  123 - 118 = 5sec, which is the correct elapsed time
     */
    this.startedAt = this.audioCtx.currentTime - this.pausedAt;
    this.pausedAt = 0;
    this.playing = true;

    // it's possible to play audio that has no sound
    Object.keys(this.sources).forEach(key => {
      const source = this.sources[key];
      source.node = this.audioCtx.createBufferSource();
      source.node.buffer = source.buffer;

      this.rescheduleGainChanges(source);

      if (source.gainNode) {
        source.node.connect(source.gainNode);
        source.gainNode.connect(this.audioCtx.destination);
      } else {
        source.node.connect(this.audioCtx.destination);
      }

      // time audio should start after play begins (might have silence in front of it)
      const startAudioAt = Math.max(
        this.startedAt + source.startOffsetSeconds,
        0,
      );

      const fromSeconds = source.fromSeconds || 0;
      const toSeconds = !_.isUndefined(source.toSeconds)
        ? source.toSeconds
        : source.buffer.duration;

      /*
       * offset after which the audio should start playing.  this is relative to the actual audio
       * source, so 0 is the beginning regardless of audio context time
       */
      const startAudioFrom = Math.max(
        offset - source.startOffsetSeconds + fromSeconds,
        fromSeconds,
      );
      const audioDuration = Math.max(toSeconds - startAudioFrom, 0);
      source.node.start(startAudioAt, startAudioFrom, audioDuration);
    });
  }

  protected stopPlayback() {
    Object.keys(this.sources).forEach(key => {
      const source = this.sources[key];
      if (!source.node) return;

      source.node.disconnect();
      source.node.stop(0);
      source.node = null;
    });

    this.resetPlayTrackingVariables();
  }

  protected pausePlayback() {
    const elapsed = this.audioCtx.currentTime - this.startedAt;
    this.stopPlayback();
    this.pausedAt = elapsed;
  }

  private seekWhilePaused(sec: number) {
    this.pausedAt = sec;
  }

  private seekWhilePlaying(sec: number) {
    this.pausePlayback();
    this.seekWhilePaused(sec);
    if (sec < this.durationSeconds) {
      this.startPlayback();
    }
  }

  protected seekTo(sec: number) {
    if (this.playing) {
      this.seekWhilePlaying(sec);
    } else {
      this.seekWhilePaused(sec);
    }
  }

  protected get currentTime() {
    if (this.pausedAt) {
      return this.pausedAt;
    }

    if (this.startedAt) {
      return this.audioCtx.currentTime - this.startedAt;
    }

    return 0;
  }

  private getSourceGainNode(id: string) {
    const source = this.sources[id];

    if (source.gainNode) {
      return source.gainNode;
    }

    const gainNode = this.audioCtx.createGain();
    source.gainNode = gainNode;
    return gainNode;
  }

  protected setMasterGain(val: number) {
    this.masterGain = val;
    Object.keys(this.sources).forEach(id => {
      const gainNode = this.getSourceGainNode(id);
      const newVal = gainNode.gain.value * this.masterGain;
      setGainAtTime(gainNode, newVal, this.audioCtx.currentTime);
      this.rescheduleGainChanges(this.sources[id]);
    });
  }

  private clearScheduledGainChanges(id: string) {
    const { gainNode } = this.sources[id];
    gainNode && gainNode.gain.cancelScheduledValues(0);
  }

  private scheduleGainChanges(
    source: IWebAudioSource,
    contextSec: number = this.startedAt,
  ) {
    scheduleGainChanges(source, transition => {
      const gainNode = this.getSourceGainNode(source.id);

      /*
       * don't schedule this transition if startSec is in the past.
       *
       * anytime the playhead stops (or seeks while pause) we record the time.
       * so if you seek to 5sec, this.pausedAt === 5.
       *
       * if you seek to 5sec before the audioContext.currentTime reaches 5sec,
       * then startSec below will be negative.
       */
      const startSec =
        transition.atSec + source.startOffsetSeconds + contextSec;

      switch (transition.effect) {
        case 'cut':
          setGainAtTime(
            gainNode,
            transition.toGain * this.masterGain,
            startSec,
            shouldUseImmediateTransition(transition),
          );
          break;

        case 'fade': {
          const { fromGain, toGain, durationSec } = transition;
          rampToGainAtTime(
            gainNode,
            fromGain * this.masterGain,
            toGain * this.masterGain,
            startSec,
            durationSec,
          );
          break;
        }
      }
    });
  }

  private rescheduleGainChanges(source: IWebAudioSource, contextSec?: number) {
    this.clearScheduledGainChanges(source.id);
    this.scheduleGainChanges(source, contextSec);
  }

  private updateSourceTiming(
    id: string,
    prop: 'startOffsetSeconds' | 'fromSeconds' | 'toSeconds',
    value: number,
  ) {
    if (_.isUndefined(value)) return;
    const source = this.sources[id];
    source[prop] = value;

    if (this.playing) {
      this.pausePlayback();
      this.startPlayback();
    }
  }

  public setSourceAudioOffsetSeconds(id: string, offsetSeconds: number) {
    this.updateSourceTiming(id, 'startOffsetSeconds', offsetSeconds);
  }

  public setSourceFromSeconds(id: string, fromSeconds: number) {
    this.updateSourceTiming(id, 'fromSeconds', fromSeconds);
  }

  public setSourceToSeconds(id: string, toSeconds: number) {
    this.updateSourceTiming(id, 'toSeconds', toSeconds);
  }

  public setAudioLevels(id: string, audioLevels: IAudioLevel[]) {
    const source = this.sources[id];
    if (!source || !audioLevels) return;

    source.audioLevels = audioLevels;
    this.rescheduleGainChanges(source);
  }

  public getAudioLevels(id: string): IAudioLevel[] {
    return getValue(this.sources, [id, 'audioLevels']);
  }

  public getSourceAudioOffset(id: string): number {
    return getValue(this.sources, [id, 'startOffsetSeconds']);
  }

  public getSourceFromSeconds(id: string): number {
    return getValue(this.sources, [id, 'fromSeconds']);
  }

  public getSourceToSeconds(id: string): number {
    return getValue(this.sources, [id, 'toSeconds']);
  }

  public get isPlaying() {
    return this.playing;
  }

  public get duration() {
    return this.durationSeconds;
  }

  public set duration(seconds) {
    this.durationSeconds = seconds;
  }

  public get sourceIds() {
    return Object.keys(this.sources);
  }
}
