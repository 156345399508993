import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import CreateProfileModal from 'blocks/Authentication/containers/CreateProfileModal';
import UserAuthenticationModal from 'blocks/Authentication/containers/UserAuthenticationModal';
import AuthNavBar from 'blocks/AuthNavBar';
import AddAutomationUpgradeModal from 'blocks/AutomationCMS/containers/AddAutomationUpgradeModal';
import EpisodeCreationMethodSelectModal from 'blocks/AutomationCMS/containers/EpisodeCreationMethodSelectModal';
import EpisodeExceedsLimitsModal from 'blocks/AutomationCMS/containers/EpisodeExceedsLimitsModal';
import EpisodeTranscriptionUpgradeModal from 'blocks/AutomationCMS/containers/EpisodeTranscriptionUpgradeModal';
import EpisodeUnsupportedLanguageModal from 'blocks/AutomationCMS/containers/EpisodeUnsupportedLanguageModal';
import {
  ClaimPodcastModal,
  ClaimPodcastResultModal,
} from 'blocks/ClaimPodcastModal';
import AddClipCaptionsModal from 'blocks/ClipSelect/containers/AddClipCaptionsModal';
import ClipSelecteEducationModal from 'blocks/ClipSelect/containers/ClipSelectEducationModal';
import {
  DownloadEpisodeCaptionsModal,
  DownloadProjectCaptionsModal,
  EmptyEpisodeTranscriptModal,
  EpisodeTranscriptionErrorModal,
  TranscribeEpisodeModal,
  UnlockTranscriptsModal,
} from 'blocks/DownloadCaptionsModal';
import {
  EmailVerificationFailureModal,
  EmailVerificationSuccessModal,
} from 'blocks/EmailVerification';
import { MobileAppPromptPage } from 'blocks/MobileAppPrompt';
import { FreeTrialWelcomeModal, PromoUnavailableModal } from 'blocks/Pricing';
import AddBillingCycleModal from 'blocks/Pricing/containers/AddBillingCycleModal';
import AdjustPlanModal from 'blocks/Pricing/containers/AdjustPlanModal';
import BackCatalogDisclaimerModal from 'blocks/Pricing/containers/BackCatalogDisclaimerModal';
import CancellationDetailsModal from 'blocks/Pricing/containers/CancellationDetailsModal';
import CancellationReasonModal from 'blocks/Pricing/containers/CancellationReasonModal';
import ChangeBillingCycleModal from 'blocks/Pricing/containers/ChangeBillingCycleModal';
import DowngradeUpsellModal from 'blocks/Pricing/containers/DowngradeUpsellModal';
import {
  DurationLimitErrorModal,
  DurationLimitModal,
} from 'blocks/Pricing/containers/DurationLimitModal';
import FreeTrialReminderModal from 'blocks/Pricing/containers/FreeTrialReminderModal';
import LegacyDowngradeModal from 'blocks/Pricing/containers/LegacyDowngradeModal';
import PaidSubscriptionSuccessModal from 'blocks/Pricing/containers/PaidSubscriptionSuccessModal';
import PauseSubscriptionDetailsModal from 'blocks/Pricing/containers/PauseSubscriptionDetailsModal';
import PlanCancelledModal from 'blocks/Pricing/containers/PlanCancelledModal';
import PlanDowngradeModal from 'blocks/Pricing/containers/PlanDowngradeModal';
import { TierSelectionModal } from 'blocks/Pricing/containers/TierSelectionModal';
import TryEddyAtCancellation from 'blocks/Pricing/containers/TryEddyAtCancellationModal';
import UpdateCreditCardModal from 'blocks/Pricing/containers/UpdateCreditCardModal/UpdateCreditCardModal';
import UpgradePlanModal from 'blocks/Pricing/containers/UpgradePlanModal';
import SaveAsTemplateIntroModal from 'blocks/SaveAsTemplate/components/SaveAsTemplateIntroModal';
import TextOverlaySwitchModal from 'blocks/TextOverlaySwitchModal';
import { CanvaProvider } from 'components/CanvaContext';
import CommonSvgProperties from 'components/CommonSvgProperties';
import { FontFamilyProIndicatorProvider } from 'components/TextToolbar';
import AdjustmentNeededModal from 'containers/AdjustmentNeededModal/AdjustmentNeededModal';
import AnimateImagesUpsellModal from 'containers/AnimateImagesUpsellModal/AnimateImagesUpsellModal';
import AudiogramDestinationModal from 'containers/AudiogramDestinationModal';
import BackCatalogUpsellModal from 'containers/BackCatalogUpsellModal';
import BlankProjectModal from 'containers/BlankProjectModal';
import { CaptionsUpsellModal } from 'containers/CaptionsUpsellModal';
import ChangeAspectRatioModal from 'containers/ChangeAspectRatioModal';
import DeleteAccountModal from 'containers/DeleteAccountModal';
import DeleteTemplateModal from 'containers/DeleteTemplateModal/DeleteTemplateModal';
import EddyPromoModal from 'containers/EddyPromoModal';
import EditAutomationModal from 'containers/EditAutomationModal';
import ExitRetryPeriodModal from 'containers/ExitRetryPeriodModal';
import ExtractAudioFeatureFakeModal from 'containers/ExtractAudioFeatureFakeModal';
import FacebookAutoPostModal from 'containers/FacebookAutoPostModal';
import FakeFeatureModal, {
  FakeFeatureChosenModal,
} from 'containers/FakeFeatureModal';
import FileSizeUpsellModal from 'containers/FileSizeUpsellModal';
import { UnlimitedTranscriptionModal } from 'containers/FreeTranscriptionBalance';
import FullEpisodesExportModal from 'containers/FullEpisodesExportModal';
import FullLengthTemplateCompatibilityModal from 'containers/FullLengthTemplateCompatibilityModal';
import IncorrectFileTypeModal from 'containers/IncorrectFileTypeModal';
import InstagramAutoPostModal from 'containers/InstagramAutoPostModal';
import { IntroOutroUpsellModal } from 'containers/IntroOutroUpsellModal';
import LinkedInAutoPostModal from 'containers/LinkedInAutoPostModal';
import ManualAudiogramModal from 'containers/ManualAudiogramModal';
import Notifications from 'containers/Notifications';
import OpenTemplateModal from 'containers/OpenTemplateModal';
import PodcastConnectedModal from 'containers/PodcastConnectedModal';
import PodcastConnectModal from 'containers/PodcastConnectModal';
import PodcastIdModal from 'containers/PodcastIdModal';
import ProjectCopyModal from 'containers/ProjectCopyModal';
import QualityReductionAlertModal from 'containers/QualityReductionAlertModal';
import RefreshTemplateListModal from 'containers/RefreshTemplateListModal/RefreshTemplateListModal';
import RemoveWatermarksModal from 'containers/RemoveWatermarksModal';
import SaveAsTemplateModal from 'containers/SaveAsTemplateModal';
import SkipEditorCaptionsModal from 'containers/SkipEditorCaptionsModal';
import TemplateLengthSelectorModal from 'containers/TemplateLengthSelectorModal';
import ThreadsAutoPostModal from 'containers/ThreadsAutoPostModal';
import TikTokAutoPostingUpsell from 'containers/TikTokAutoPostingUpsellModal';
import TikTokAutoPostModal from 'containers/TikTokAutoPostModal';
import TiktokUploadedModal from 'containers/TiktokUploadedModal';
import TwitterAutoPostModal from 'containers/TwitterAutoPostModal';
import { UnpauseSubscriptionModal } from 'containers/UnpauseSubscriptionModal';
import UnsavedWorkModal from 'containers/UnsavedWorkModal';
import WatermarkUpsellModal from 'containers/WatermarkUpsellModal';
import YoutubeAutoPostModal from 'containers/YoutubeAutoPostModal';
import YoutubePermissionsRevokedModal from 'containers/YoutubePermissionsRevokedModal';
import bem from 'utils/bem';
import { isDownloadActive } from 'utils/routes';
import Routes from './Routes';
import useWebappLoad from './useWebappLoad';

export const block = bem('main-app');

export interface AppProps {
  children?: React.ReactNode;
  rehydrated?: boolean;
  username?: string;
  onWebappLoad?: (username: string) => void;
}
/**
 * animation reference: https://github.com/ReactTraining/react-router/blob/v3/examples/animations/app.js
 */
const App: React.FC<AppProps> = () => {
  const rehydrated = useWebappLoad();

  if (!isMobile || isDownloadActive()) {
    return (
      <>
        <CommonSvgProperties />
        <CanvaProvider>
          <FontFamilyProIndicatorProvider>
            <DndProvider backend={Backend}>
              <div className={block()}>
                <AuthNavBar />
                <CreateProfileModal />
                <UserAuthenticationModal />
                <div className="container-fluid">
                  <Notifications />
                  {rehydrated && <Routes />}
                </div>
              </div>
            </DndProvider>
            <ChangeAspectRatioModal />
            <ClaimPodcastModal />
            <ClaimPodcastResultModal />
            <DurationLimitErrorModal />
            <DeleteAccountModal />
            <DurationLimitModal />
            <EditAutomationModal />
            <EmailVerificationSuccessModal />
            <EmailVerificationFailureModal />
            <CancellationReasonModal />
            <CancellationDetailsModal />
            <FreeTrialReminderModal />
            <PlanDowngradeModal />
            <LegacyDowngradeModal />
            <FakeFeatureModal />
            <FakeFeatureChosenModal />
            <FileSizeUpsellModal />
            <FullEpisodesExportModal />
            <FullLengthTemplateCompatibilityModal />
            <EddyPromoModal />
            <QualityReductionAlertModal />
            <RefreshTemplateListModal />
            <IntroOutroUpsellModal />
            <CaptionsUpsellModal />
            <DowngradeUpsellModal />
            <PauseSubscriptionDetailsModal />
            <PaidSubscriptionSuccessModal />
            <AddBillingCycleModal />
            <UpgradePlanModal />
            <ChangeBillingCycleModal />
            <AdjustPlanModal />
            <OpenTemplateModal />
            <ManualAudiogramModal />
            <PodcastConnectModal />
            <PodcastConnectedModal />
            <BlankProjectModal />
            <TiktokUploadedModal />
            <SaveAsTemplateModal />
            <DeleteTemplateModal />
            <SkipEditorCaptionsModal />
            <TemplateLengthSelectorModal />
            <SaveAsTemplateIntroModal />
            <TikTokAutoPostingUpsell />
            <TikTokAutoPostModal />
            <InstagramAutoPostModal />
            <LinkedInAutoPostModal />
            <TwitterAutoPostModal />
            <ThreadsAutoPostModal />
            <UnlimitedTranscriptionModal />
            <UnpauseSubscriptionModal />
            <FacebookAutoPostModal />
            <WatermarkUpsellModal />
            <IncorrectFileTypeModal />
            <PodcastIdModal />
            <ExitRetryPeriodModal />
            <ExtractAudioFeatureFakeModal />
            <UnsavedWorkModal />
            <UpdateCreditCardModal />
            <AdjustmentNeededModal />
            <PlanCancelledModal />
            <AddClipCaptionsModal />
            <ClipSelecteEducationModal />
            <EpisodeCreationMethodSelectModal />
            <AddAutomationUpgradeModal />
            <YoutubeAutoPostModal />
            <DownloadProjectCaptionsModal />
            <DownloadEpisodeCaptionsModal />
            <UnlockTranscriptsModal />
            <TranscribeEpisodeModal />
            <EpisodeTranscriptionErrorModal />
            <EmptyEpisodeTranscriptModal />
            <TierSelectionModal />
            <TryEddyAtCancellation />
            <ProjectCopyModal />
            <BackCatalogUpsellModal />
            <AnimateImagesUpsellModal />
            <BackCatalogDisclaimerModal />
            <AudiogramDestinationModal />
            <FreeTrialWelcomeModal />
            <PromoUnavailableModal />
            <RemoveWatermarksModal />
            <YoutubePermissionsRevokedModal />
            <EpisodeUnsupportedLanguageModal />
            <EpisodeTranscriptionUpgradeModal />
            <EpisodeExceedsLimitsModal />
            <TextOverlaySwitchModal />
          </FontFamilyProIndicatorProvider>
        </CanvaProvider>
      </>
    );
  }

  return (
    <>
      <CommonSvgProperties />
      <MobileAppPromptPage />
    </>
  );
};

export default App;
