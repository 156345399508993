import * as React from 'react';

import usePromise from 'hooks/usePromise';
import SearchResultList, {
  SearchResultListProps,
} from '../../components/SearchResultList';
import { episodeExplorerBlock as block } from '../../utils';
import EpisodeSearchResult, {
  EpisodeSearchResultProps,
} from '../EpisodeSearchResult';

const { useCallback } = React;

type ResultListProps = Pick<
  SearchResultListProps,
  'defaultScrollOffset' | 'height' | 'onScroll'
>;

export interface EpisodeListProps extends ResultListProps {
  hasMore?: boolean;
  initialPage?: number;
  isLoading?: boolean;
  onEpisodeClick?: EpisodeSearchResultProps['onClick'];
  onLoadPage?: () => Promise<void>;
  results?: string[];
}

const EpisodeList: React.SFC<EpisodeListProps> = ({
  defaultScrollOffset,
  hasMore,
  height,
  isLoading,
  onEpisodeClick,
  onLoadPage,
  onScroll,
  results,
}) => {
  const { withPromise } = usePromise();

  const getItemKey = useCallback(index => results[index], [results]);

  return (
    <SearchResultList<string>
      className={block('episode-list')}
      containerClassName={block('list-wrapper')}
      defaultScrollOffset={defaultScrollOffset}
      getItemKey={getItemKey}
      hasMore={hasMore}
      height={height}
      isLoading={isLoading}
      itemSize={40}
      onLoadPage={withPromise(onLoadPage)}
      onScroll={onScroll}
      results={results}
      threshold={2}
    >
      {props => <EpisodeSearchResult {...props} onClick={onEpisodeClick} />}
    </SearchResultList>
  );
};

export default EpisodeList;
