import { Button } from '@sparemin/blockhead';
import React from 'react';

import { H1 } from 'components/Heading';
import ProgressOverlay from 'components/ProgressOverlay';

import { block } from './utils';

interface AnimationCardProps {
  canCancel: boolean;
  isCancelling: boolean;
  onCancelGeneration: () => void;
  sourceImageUrl?: string;
}

const AnimationCard: React.FunctionComponent<AnimationCardProps> = props => {
  const { canCancel, isCancelling, onCancelGeneration, sourceImageUrl } = props;

  return (
    <div className={block('animation-card')}>
      <div
        className={block('animation-card-bg')}
        style={{
          // by using this instead of a regular img element, the logic for
          // centering and cropping the background image can be simplified
          backgroundImage: `url(${sourceImageUrl})`,
        }}
      />
      <div className={block('animation-card-fade')}>
        <ProgressOverlay in />
      </div>
      <div className={block('animation-card-content')}>
        <H1 className={block('animation-card-title')}>Animating your idea</H1>
        <p className={block('animation-card-text')}>
          This may take a few minutes to generate.
        </p>
        {canCancel && (
          <Button
            color="light"
            isDisabled={isCancelling}
            onPress={onCancelGeneration}
            size="small"
            variant="frosted"
          >
            cancel
          </Button>
        )}
      </div>
    </div>
  );
};

export default AnimationCard;
