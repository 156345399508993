import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import DeleteTrackModalContents from './DeleteTrackModalContents';
import { block } from './utils';

export interface DeleteTrackModalProps {
  trackId?: string;
}

const DeleteTrackModal: React.SFC<DeleteTrackModalProps> = ({ trackId }) => (
  <ConnectedModal
    backdrop="static"
    className={block()}
    name="DeleteTrackModal"
    title="Delete Track"
  >
    <DeleteTrackModalContents trackId={trackId} />
  </ConnectedModal>
);

export default DeleteTrackModal;
