import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';

import DurationLimitModalContents from './DurationLimitModalContentsContainer';

const DurationLimitModal: React.FunctionComponent = () => (
  <ConnectedModal name="DurationLimitModal" title="duration limit">
    <DurationLimitModalContents />
  </ConnectedModal>
);

export default DurationLimitModal;
