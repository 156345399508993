import React, { useCallback } from 'react';
import { LayerOrderControlsButtonProps } from './assets/LayerOrderControls/LayerOrderControlsButton';
import {
  checkCanLayerMove,
  getTooltipMessages,
  LAYER_CONTROL_BUTTONS_ICONS,
} from './assets/LayerOrderControls/utils';
import { useEditorState } from './context/VideoEditorStateContext';
import { useTemplateDispatch } from './context/VideoTemplateDispatchContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import { LayerMoveOption } from './types';
import { getLayerIdByAssetId } from './utils';

interface UseLayerOrder {
  handleChangeLayerPosition: (moveTo: LayerMoveOption) => void;
  getLayerControlButtonsSettings: (
    moveTo: LayerMoveOption,
  ) => LayerOrderControlsButtonProps;
}

const useLayerOrder = (): UseLayerOrder => {
  const state = useTemplateState();
  const { selectedAsset } = useEditorState();
  const { isComplex } = state;
  const dispatch = useTemplateDispatch();

  const selectedLayerId = getLayerIdByAssetId(state, selectedAsset.id);
  const possibleLayerMovements = checkCanLayerMove(state, selectedLayerId);
  const tooltipMessages = getTooltipMessages(possibleLayerMovements, isComplex);

  const handleChangeLayerPosition = useCallback(
    (moveTo: LayerMoveOption): void => {
      if (selectedLayerId && possibleLayerMovements[moveTo]) {
        dispatch({
          payload: { layerId: selectedLayerId, moveTo },
          type: 'LAYER_POSITION_CHANGE',
        });
      }
    },
    [dispatch, possibleLayerMovements, selectedLayerId],
  );

  const getLayerControlButtonsSettings = useCallback(
    (moveTo: LayerMoveOption): LayerOrderControlsButtonProps => {
      const Icon = LAYER_CONTROL_BUTTONS_ICONS[moveTo];

      return {
        icon: <Icon />,
        tooltipText: tooltipMessages[moveTo],
        disabled: !possibleLayerMovements[moveTo] || isComplex,
        onClick: () => handleChangeLayerPosition(moveTo),
      };
    },
    [
      handleChangeLayerPosition,
      isComplex,
      possibleLayerMovements,
      tooltipMessages,
    ],
  );

  return {
    handleChangeLayerPosition,
    getLayerControlButtonsSettings,
  };
};

export default useLayerOrder;
