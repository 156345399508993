import * as React from 'react';

import { IconProps } from './types';

const TiktokLogo: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    viewBox="0 0 20 22"
    {...props}
  >
    <g fill="none">
      <path
        fill="#FF004F"
        d="M14.301 7.703a8.557 8.557 0 004.934 1.552v-3.48a5.08 5.08 0 01-1.036-.106v2.739a8.558 8.558 0 01-4.934-1.552v7.1c0 3.553-2.93 6.432-6.544 6.432-1.349 0-2.602-.4-3.643-1.088a6.58 6.58 0 004.679 1.935c3.614 0 6.544-2.88 6.544-6.431v-7.1zm1.279-3.51a4.81 4.81 0 01-1.279-2.84V.906h-.982a4.87 4.87 0 002.26 3.287zM5.364 16.575a2.897 2.897 0 01-.61-1.782c0-1.624 1.34-2.941 2.993-2.941.308 0 .614.046.908.137V8.432a6.715 6.715 0 00-1.036-.058v2.768a3.045 3.045 0 00-.909-.137c-1.653 0-2.993 1.317-2.993 2.941 0 1.15.67 2.144 1.647 2.629z"
      />
      <path
        fill="#FFF"
        d="M13.265 6.856A8.558 8.558 0 0018.2 8.408v-2.74a4.973 4.973 0 01-2.62-1.475A4.87 4.87 0 0113.32.906h-2.58v13.897c-.006 1.62-1.344 2.933-2.993 2.933a3.007 3.007 0 01-2.384-1.161 2.937 2.937 0 01-1.646-2.628c0-1.625 1.34-2.942 2.993-2.942.317 0 .622.048.909.137V8.374c-3.55.072-6.406 2.923-6.406 6.43 0 1.75.71 3.337 1.865 4.496a6.594 6.594 0 003.643 1.088c3.614 0 6.544-2.88 6.544-6.431V6.856z"
      />
      <path
        fill="#00F2EA"
        d="M18.2 5.669v-.74a4.997 4.997 0 01-2.62-.736 4.969 4.969 0 002.62 1.476zM13.32.906a4.884 4.884 0 01-.055-.4V.06H9.704v13.898c-.006 1.62-1.344 2.931-2.994 2.931a3.04 3.04 0 01-1.347-.313 3.007 3.007 0 002.384 1.16c1.65 0 2.987-1.311 2.993-2.931V.906h2.58zM7.618 8.374v-.789a6.72 6.72 0 00-.898-.06c-3.614 0-6.545 2.88-6.545 6.432 0 2.226 1.152 4.189 2.902 5.343a6.353 6.353 0 01-1.865-4.497c0-3.506 2.855-6.357 6.406-6.43z"
      />
    </g>
  </svg>
);

export default TiktokLogo;
