import * as React from 'react';

import { IconProps } from './types';

const Progress: React.SFC<IconProps> = ({
  fill = 'currentColor',
  stroke = 'currentColor',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="15"
    viewBox="0 0 42 15"
    {...props}
  >
    <defs>
      <path
        d="M.718.394h5.265c.186 0 .365.072.498.201l11.696 11.296a.718.718 0 0 1-.5 1.234H.718A.718.718 0 0 1 0 12.407V1.112C0 .715.321.394.718.394z"
        id="l4f6ds4cua"
      />
    </defs>
    <g stroke={stroke} fill={fill} fillRule="evenodd">
      <path
        strokeWidth=".718"
        fill={stroke}
        strokeLinecap="square"
        d="m12.909.754 13.637 13.125M9.273.754l13.636 13.125M16.546.754l13.636 13.125M20.182.754l13.636 13.125M23.818.754l13.637 13.125M27.273.754l13.636 13.125"
      />
      <path
        strokeWidth=".5"
        d="m6.255 1.398 12.02 11.426-17.284.805L.523 1.866l5.732-.468z"
      />
    </g>
  </svg>
);

export default Progress;
