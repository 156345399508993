import { Record, RecordOf } from 'immutable';

interface IUiState {
  isLoadingData: boolean;
  addSlideEndMillis: number;
  videoEditorRendered: boolean;
  renderLoadingOverlay: boolean;
}

export interface IState {
  ui: RecordOf<IUiState>;
}

export const uiFactory = Record<IUiState>({
  addSlideEndMillis: undefined,
  isLoadingData: undefined,
  renderLoadingOverlay: undefined,
  videoEditorRendered: undefined,
});
