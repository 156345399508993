import cn from 'classnames';
import * as React from 'react';

import AssetTile, { AssetTileProps } from 'components/AssetTile';
import { AspectRatioName } from 'types';
import AssetTileCheckbox from './AssetTileCheckbox';
import { block } from './utils';

type PickedTileProps = Omit<AssetTileProps, 'onClick'>;

export interface Props extends PickedTileProps {
  checked: boolean;
  value: any;
  className?: string;
  aspectRatioName: AspectRatioName;
  onToggle: (checked: boolean, value: any) => void;
}
const AssetTileToggle: React.FC<Props> = props => {
  const {
    aspectRatioName,
    checked,
    onToggle,
    className,
    value,
    children,
    onDeleteClick,
    ...rest
  } = props;

  return (
    <AssetTile
      permanent
      {...rest}
      className={cn(
        className,
        block({
          [aspectRatioName]: true,
          checked,
        }),
      )}
      onClick={() => {
        onToggle(!checked, value);
      }}
      onDeleteClick={(params, event) => {
        event.stopPropagation();
        onDeleteClick?.(params, event);
      }}
    >
      {children}
      <AssetTileCheckbox />
    </AssetTile>
  );
};

export default AssetTileToggle;
