import { EditorDialog } from '@sparemin/blockhead';
import React from 'react';

import TextOverlayControlPane, {
  TextOverlayControlPaneProps,
} from './components/TextOverlayControlPane/TextOverlayControlPane';
import TextOverlayPreviewPane, {
  TextOverlayPreviewPaneProps,
} from './components/TextOverlayPreviewPane';
import useTextOverlaySubmit from './hooks/useTextOverlaySubmit';
import { useTextOverlay } from './state/TextOverlayProvider';
import { TextOverlayV2 } from './types';
import { block, DEFAULT_SUBMIT_BTN_TEXT, DEFAULT_TITLE } from './utils';

type PickedTextOverlayPreviewPaneProps = Pick<
  TextOverlayPreviewPaneProps,
  'aspectRatio' | 'background' | 'inputDisabled' | 'templateId'
>;

type PickedTextOverlayControlPaneProps = Pick<
  TextOverlayControlPaneProps,
  'footerLeftActions'
>;

export interface TextOverlayModalViewProps
  extends PickedTextOverlayPreviewPaneProps,
    PickedTextOverlayControlPaneProps {
  editable?: boolean;
  isCompatibilityMode?: boolean;
  onExited?: () => void;
  onHide: () => void;
  onSubmit: (overlay: TextOverlayV2) => void;
  onSwitchToLegacy?: (partialOverlay: TextOverlayV2) => void;
  shouldDisableSubmitIfEmpty: boolean;
  show: boolean;
  showEmoji?: boolean;
  showPresetSelector: boolean;
  submitButtonText?: string;
  subTitle?: string;
  title: string;
}

const TextOverlayModalView: React.FunctionComponent<TextOverlayModalViewProps> = props => {
  const {
    aspectRatio,
    background,
    editable,
    footerLeftActions,
    inputDisabled,
    isCompatibilityMode,
    onExited,
    onHide,
    onSubmit,
    onSwitchToLegacy,
    shouldDisableSubmitIfEmpty,
    show,
    showEmoji = false,
    showPresetSelector = false,
    submitButtonText = DEFAULT_SUBMIT_BTN_TEXT,
    subTitle,
    templateId,
    title,
  } = props;

  const { onChangeCanvasSize } = useTextOverlay();

  const handleSubmit = useTextOverlaySubmit({ onHide, onSubmit });

  const handleChangeCanvasSize = React.useCallback(
    (width: number, height: number): void => {
      onChangeCanvasSize({ height, width });
    },
    [onChangeCanvasSize],
  );

  const modalTitle = title || DEFAULT_TITLE;
  return (
    <EditorDialog
      aspectRatio={aspectRatio}
      backdropClassName={block('backdrop')}
      className={block()}
      controlPaneContent={
        <TextOverlayControlPane
          footerLeftActions={footerLeftActions}
          isCompatibilityMode={isCompatibilityMode}
          onHide={onHide}
          onSubmit={handleSubmit}
          onSwitchToLegacy={onSwitchToLegacy}
          shouldDisableSubmitIfEmpty={shouldDisableSubmitIfEmpty}
          showEmoji={showEmoji}
          showPresetSelector={showPresetSelector}
          submitButtonText={submitButtonText}
          subTitle={subTitle}
          title={modalTitle}
        />
      }
      isDismissable={false}
      isOpen={show}
      onExited={onExited}
      onClose={onHide}
      onPreviewDimensionsChange={handleChangeCanvasSize}
      previewPaneContent={
        <TextOverlayPreviewPane
          aspectRatio={aspectRatio}
          background={background}
          editable={editable}
          inputDisabled={inputDisabled}
          templateId={templateId}
        />
      }
    />
  );
};

export default TextOverlayModalView;
