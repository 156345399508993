import * as React from 'react';
import { IconProps } from './types';

function ProCard(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={320}
      height={215}
      viewBox="0 0 320 215"
      {...props}
    >
      <defs>
        <filter
          id="prefix__a"
          width="125%"
          height="140%"
          x="-12.5%"
          y="-14.3%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy={10} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation={10}
          />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
        </filter>
        <filter
          id="prefix__d"
          width="100%"
          height="100%"
          x="0%"
          y="0%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur in="SourceGraphic" />
        </filter>
        <filter
          id="prefix__e"
          width="100%"
          height="100%"
          x="0%"
          y="0%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur in="SourceGraphic" />
        </filter>
        <filter
          id="prefix__f"
          width="100%"
          height="100%"
          x="0%"
          y="0%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur in="SourceGraphic" />
        </filter>
        <filter
          id="prefix__g"
          width="100%"
          height="100%"
          x="0%"
          y="0%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur in="SourceGraphic" />
        </filter>
        <linearGradient
          id="prefix__c"
          x1="0%"
          x2="100%"
          y1="30.469%"
          y2="69.531%"
        >
          <stop offset="0%" stopColor="#5DBEFC" />
          <stop offset="100%" stopColor="#DD92FE" />
        </linearGradient>
        <rect id="prefix__b" width={280} height={175} x={0} y={0} rx={10.002} />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(20 10)">
          <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
          <use fill="url(#prefix__c)" xlinkHref="#prefix__b" />
        </g>
        <g transform="translate(98.339 38)">
          <ellipse
            fill="#6E6EDE"
            style={{
              mixBlendMode: 'hard-light',
            }}
            cx={74.289}
            cy={69.042}
            filter="url(#prefix__d)"
            rx={50.021}
            ry={49.665}
          />
          <ellipse
            fill="#E77AC0"
            style={{
              mixBlendMode: 'hard-light',
            }}
            cx={53.958}
            cy={66.204}
            filter="url(#prefix__e)"
            rx={50.021}
            ry={49.665}
          />
          <ellipse
            fill="#19B5FE"
            style={{
              mixBlendMode: 'hard-light',
            }}
            cx={50.021}
            cy={49.665}
            filter="url(#prefix__f)"
            rx={50.021}
            ry={49.665}
          />
          <ellipse
            fill="#68D3CF"
            style={{
              mixBlendMode: 'hard-light',
            }}
            cx={71.244}
            cy={52.601}
            filter="url(#prefix__g)"
            rx={50.021}
            ry={49.665}
          />
        </g>
      </g>
    </svg>
  );
}

export default ProCard;
