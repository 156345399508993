import React from 'react';
import DialogContent from 'components/DialogContent';
import { Alert } from 'components/icons';

export interface SkipEditorCaptionsModalContentsProps {}

const SkipEditorCaptionsModalContents: React.FC<SkipEditorCaptionsModalContentsProps> = () => (
  <DialogContent
    actions={[
      {
        children: 'export without captions',
        key: 'remove-captions',
        params: false,
        theme: 'submit-alt',
      },
      {
        children: 'export with captions',
        key: 'keep-captions',
        params: true,
        theme: 'submit',
      },
    ]}
    details={
      <>
        Captions are enabled but won’t be editable before export.
        <br />
        If you prefer to edit them, open the advanced editor instead.
      </>
    }
    icon={<Alert />}
    title="Captions won't be editable"
  />
);
export default SkipEditorCaptionsModalContents;
