import { Set } from 'immutable';

import { capitalize } from 'utils/string';
import { Notifications } from './types';

export function getNotificationIdsToClear(
  notifications: Notifications,
  nextNotifications: Notifications,
) {
  const existingids = notifications.get('ids', Set<string>());
  const nextids = nextNotifications.get('ids', Set<string>());
  return existingids.subtract(nextids);
}

export function getNotificationsToShow(
  notifications: Notifications,
  nextNotifications: Notifications,
) {
  const existingids = notifications.get('ids', Set());
  const nextids = nextNotifications.get('ids', Set());
  const newids = nextids.subtract(existingids);
  return newids.map(id => nextNotifications.getIn(['data', id.toString()]));
}

export function getTitle(level: string) {
  return capitalize(level);
}
