import React from 'react';
import { useSelector } from 'react-redux';

import usePodcastTabs from 'blocks/AutomationCMS/hooks/usePodcastTabs';
import useYoutubePermissionRevokedListener from 'blocks/AutomationCMS/hooks/useYoutubePermissionRevokedListener';
import { favoritePodcastIdsSelector } from 'redux/modules/podcast-search/selectors';

import PodcastSidebar from '../../components/PodcastSidebar';
import { cmsBlock as block } from '../../utils';
import AutomationCMSModals from '../AutomationCMSModals';
import useAutomationCMSModalVisibility from '../AutomationCMSModals/useAutomationCMSModalVisibility';
import AutomationCMSRichBody, {
  AutomationCMSRichBodyProps,
} from '../AutomationCMSRichBody';
import AutomationCMSModalContext from './AutomationCMSModalContext';

type PickedBodyProps = Pick<AutomationCMSRichBodyProps, 'getScrollParent'>;

export interface AutomationCMSRichProps extends PickedBodyProps {}

const AutomationCMSRich: React.FC<AutomationCMSRichProps> = ({
  getScrollParent,
}) => {
  const podcastIds = useSelector(favoritePodcastIdsSelector) ?? [];

  const {
    activePodcast,
    activePodcastIndex,
    setActivePodcast,
  } = usePodcastTabs(podcastIds);

  const {
    dispatch,
    hideModal,
    projectId,
    showModalType,
    showPodcastDeleteModal,
    workflowId,
    episodeId,
  } = useAutomationCMSModalVisibility();

  // It listens to the URL query params indicating a revoked permissions email was used
  // for accessing to the page.
  useYoutubePermissionRevokedListener({
    onSetActivePodcast: setActivePodcast,
    podcastIds,
  });

  return (
    <div className={block()}>
      <PodcastSidebar
        onDeletePodcast={showPodcastDeleteModal}
        onSelectPodcast={setActivePodcast}
        podcasts={podcastIds}
        selectedPodcastIndex={activePodcastIndex}
      />
      <AutomationCMSModalContext.Provider value={dispatch}>
        <>
          <AutomationCMSRichBody
            getScrollParent={getScrollParent}
            podcastFeedId={activePodcast}
            podcasts={podcastIds}
          />
          <AutomationCMSModals
            onHide={hideModal}
            podcastId={activePodcast}
            {...{ projectId, showModalType, workflowId, episodeId }}
          />
        </>
      </AutomationCMSModalContext.Provider>
    </div>
  );
};

export default AutomationCMSRich;
