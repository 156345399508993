import FontFaceObserver from 'fontfaceobserver';
import { first, last } from 'underscore';
import WebFont from 'webfontloader';

import {
  IManualTranscriptChunk,
  ISubChunk,
  Word,
} from 'redux/middleware/api/transcript-service/types';
import { getFamilyName } from 'utils/fonts';
import { getIndexOfLastVisibleWord } from 'utils/ui';
import { parseChunk } from './core';

interface Font {
  name: string;
  familyName: string;
  family: string;
  url: string;
}

interface CaptionsStyles {
  containerStyle: React.CSSProperties;
  textStyle: React.CSSProperties;
}

function createSubchunks(words: Word[], styles: CaptionsStyles): ISubChunk[] {
  if (!words?.length) {
    return [];
  }

  // words with timings removed so that they can be passed to the caption fit code
  const wordStrings = words.map(
    (word, idx, ary) => `${word.text}${idx < ary.length - 1 ? ' ' : ''}`,
  );

  const subchunks: ISubChunk[] = [];

  let startIndex = 0;
  while (startIndex < wordStrings.length) {
    const lastVisibleIndex = getIndexOfLastVisibleWord(
      wordStrings.slice(startIndex),
      styles.containerStyle,
      styles.textStyle,
    );

    // copy all words that fit into a new subchunk
    const visibleWords = words.slice(
      startIndex,
      lastVisibleIndex + startIndex + 1,
    );
    subchunks.push({
      endMillis: last(visibleWords).endMillis,
      id: undefined,
      startMillis: first(visibleWords).startMillis,
      text: visibleWords.map(word => word.text).join(' '),
      words: visibleWords,
    });

    // startIndex += 1;
    startIndex += lastVisibleIndex + 1;
  }

  return subchunks;
}

async function loadFont(style: React.CSSProperties, fonts: Font[]) {
  const name = getFamilyName(style.fontFamily);
  const weight = style.fontWeight || 400;
  const fontStyle = style.fontStyle || 'normal';

  const font = fonts.find(f => f.familyName === name);

  if (!font) {
    return;
  }

  if (font?.url) {
    WebFont.load({
      classes: false,
      custom: {
        families: [name],
        urls: [font.url],
      },
    });
  }

  if (name) {
    await new FontFaceObserver(name, { weight, style: fontStyle }).load();
  }
}

export default async function rechunk(
  transcript: IManualTranscriptChunk[],
  styles: CaptionsStyles,
  fonts: Font[],
): Promise<IManualTranscriptChunk[]> {
  await Promise.all([
    loadFont(styles.containerStyle, fonts),
    loadFont(styles.textStyle, fonts),
  ]);

  return transcript.map(chunk => {
    const words = parseChunk(chunk);
    const subchunks = createSubchunks(words, styles);
    return { ...chunk, subchunks };
  });
}
