import Immutable from 'immutable';
import { combineReducers } from 'redux-immutable';

import { types as embedTypes } from '../embed';
import * as types from './types';

const defaultState = Immutable.Map();

const createReducer = engine => (state = defaultState, action) => {
  switch (action.type) {
    case types.IMAGES_GET_REQUEST: {
      const { engine: actionEngine } = action.payload;
      if (actionEngine === engine) {
        return state.withMutations(s =>
          s.set('isFetching', true).set('error', false),
        );
      }
      return state;
    }

    case types.IMAGES_GET_SUCCESS: {
      if (action.response.engine === engine) {
        return state.withMutations(s =>
          s
            .update(
              'data',
              d =>
                (d && d.concat(action.response.content)) ||
                action.response.content,
            )
            .set('error', false)
            .set('page', action.response.queries.current.page + 1)
            .set('pageSize', action.response.queries.current.pageSize)
            .set('totalResults', action.response.queries.current.totalResults)
            .set('q', action.response.queries.current.q)
            .set('isFetching', false),
        );
      }
      return state;
    }

    case types.IMAGES_GET_FAILURE: {
      if (action.payload.engine === engine) {
        return state.withMutations(s =>
          s.set('isFetching', false).set('error', true),
        );
      }
      return state;
    }

    case embedTypes.EMBED_EDITOR_LOAD_REQUEST:
      return defaultState;

    case types.IMAGE_SEARCH_RESULTS_CLEAR:
      if (action.payload.engine === engine) {
        return defaultState;
      }
      return state;

    case types.IMAGE_SEARCH_RESULTS_CLEAR_ALL:
      return defaultState;

    default:
      return state;
  }
};

export default combineReducers({
  ap: createReducer('ap'),
  getty: createReducer('getty'),
  gettyCreative: createReducer('gettyCreative'),
  google: createReducer('google'),
  microsoft: createReducer('microsoft'),
  pixabay: createReducer('pixabay'),
  shutterstock: createReducer('shutterstock'),
  thinkstock: createReducer('thinkstock'),
  usaToday: createReducer('usaToday'),
  tonl: createReducer('tonl'),
});
