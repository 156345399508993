import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { onUnsavedWorkAlert } from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal/actions';
import { Dispatch } from 'redux/types';
import usePageUnloadGuard from './usePageUnloadGuard';
import usePreviousRef from './usePreviousRef';

export function useBlocker(when = true, message?: string) {
  const dispatch = useDispatch<Dispatch>();
  const { block } = useHistory();
  const location = useLocation();
  const prevLocationRef = usePreviousRef(location);

  const confirm = async (): Promise<boolean> => {
    const result = await dispatch(pushModal({ name: 'UnsavedWork' }));
    dispatch(onUnsavedWorkAlert(result, message));
    return result;
  };

  // only required for history@4.x.x, newer versions handle 'beforeunload' event too
  usePageUnloadGuard(when);

  // useLayoutEffect is important here, because unblocking should run before any
  // useEffect which might trigger navigation
  useLayoutEffect(() => {
    if (!when) {
      return undefined;
    }
    const unblock = block(nextLocation => {
      // ignore route update
      if (
        prevLocationRef.current.pathname === nextLocation.pathname &&
        prevLocationRef.current.search === nextLocation.search
      ) {
        return undefined;
      }

      return message;
    });
    return unblock;
  }, [block, when, message, prevLocationRef]);

  return confirm;
}
