import { connect } from 'react-redux';

import { EMBED_ADDING_TO_TRACK_SET } from 'redux/modules/embed/action-types';
import {
  mediaTracksSelector,
  tracksSelector,
} from 'redux/modules/embed/selectors';
import { pushModal } from 'redux/modules/modal/actions';
import { Dispatch, ThunkAction } from 'redux/types';
import { PropsOf } from 'types';

import MediaTab, { MediaTabProps as Props } from './MediaTab';

type DispatchProps = Pick<Props, 'onAddMedia'>;

const handleAddMedia = (): ThunkAction<void> => (dispatch, getState) => {
  const trackIds = tracksSelector(getState());
  const mediaTracks = mediaTracksSelector(getState());

  const trackId = trackIds.find(id => mediaTracks.has(id));
  dispatch({ type: EMBED_ADDING_TO_TRACK_SET, payload: { trackId } });
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onAddMedia: () => {
    dispatch(handleAddMedia());
    dispatch(pushModal({ name: 'AddMediaModal' }));
  },
});

const component = connect(null, mapDispatchToProps)(MediaTab);

export type MediaTabProps = PropsOf<typeof component>;
export default component;
