import { YoutubeCategories } from 'redux/middleware/api/podcast-service/types';
import bem from 'utils/bem';
import { YoutubeCategorySelectorOption } from './types';

export const block = bem('youtube-category-selector');

export const categoryToOption = (
  category: YoutubeCategories,
): YoutubeCategorySelectorOption => {
  return {
    label: category.title,
    value: category.id,
  };
};

export const formatYoutubeCategories = (
  categories: YoutubeCategories[] = [],
): YoutubeCategorySelectorOption[] =>
  [
    { id: undefined, title: 'Default' },
    ...[...categories].sort((c1, c2) => c1.title.localeCompare(c2.title)),
  ].map(categoryToOption);

export const getCategoryOption = (
  options: YoutubeCategorySelectorOption[],
  value: number,
): YoutubeCategorySelectorOption => {
  return options.find(opt => opt.value === value);
};
