import * as React from 'react';
import ContentProEmpty from './components/ContentProEmpty';
import ContentProFonts from './components/ContentProFonts';
import Disclaimer from './Disclaimer';
import { useFontSettings } from './useFontSettings';

function ContentPro() {
  const { personalFonts } = useFontSettings();

  return (
    <>
      {personalFonts?.length ? (
        <ContentProFonts fonts={personalFonts} />
      ) : (
        <ContentProEmpty />
      )}
      <Disclaimer />
    </>
  );
}

export default ContentPro;
