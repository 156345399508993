import { LocationDescriptor } from 'history';
import React from 'react';
import { createChainedFunction } from 'react-bootstrap/lib/utils';
import { Link } from 'react-router-dom';

import {
  ActionStepCard,
  ActionStepCardEditButton,
} from 'components/ActionStepCard';
import LinkButton from 'components/LinkButton';
import { Popper, PopperTrigger } from 'components/Popper';

import { getAspectRatioName } from 'utils/aspect-ratio';
import { Dimension } from '../types';
import { block, getDimensionsText } from './utils';

interface EditAutomationVisualsProps {
  dimension?: Dimension;
  editAspectRatioLink: LocationDescriptor<unknown>;
  editTemplateLink: LocationDescriptor<unknown>;
  imageUrl: string;
  isGenerating: boolean;
  onEditAspectRatio: () => void;
  onEditTemplate: () => void;
  progressOverlay: React.ReactNode;
}

const EditAutomationVisuals: React.FunctionComponent<EditAutomationVisualsProps> = props => {
  const {
    dimension,
    editAspectRatioLink,
    editTemplateLink,
    imageUrl,
    isGenerating,
    onEditAspectRatio,
    onEditTemplate,
    progressOverlay,
  } = props;

  const [menuOpen, setMenuOpen] = React.useState(false);

  const placeholderAspectRatioName = getAspectRatioName(
    dimension?.height,
    dimension?.width,
  );

  const handleCloseMenu = React.useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleOpenMenu = React.useCallback(() => {
    setMenuOpen(true);
  }, []);

  const handleEditAspectRatio = createChainedFunction(
    handleCloseMenu,
    onEditAspectRatio,
  ) as () => {};
  const handleEditTemplate = createChainedFunction(
    handleCloseMenu,
    onEditTemplate,
  ) as () => {};

  const image = isGenerating ? (
    <div
      className={block('thumbnail-placeholder', {
        [placeholderAspectRatioName]: true,
      })}
    >
      {progressOverlay}
    </div>
  ) : (
    imageUrl
  );

  const renderAction = React.useCallback(
    () => (
      <div style={{ position: 'relative' }}>
        <PopperTrigger open={menuOpen} onClose={handleCloseMenu}>
          <Popper className={block('menu')}>
            <LinkButton
              className={block('menu-button')}
              componentClass={Link}
              onClick={handleEditAspectRatio}
              to={editAspectRatioLink}
            >
              <span className={block('menu-button-text')}>
                Change aspect ratio
              </span>
            </LinkButton>
            <LinkButton
              className={block('menu-button', { last: true })}
              componentClass={Link}
              onClick={handleEditTemplate}
              to={editTemplateLink}
            >
              <span className={block('menu-button-text')}>Change template</span>
            </LinkButton>
          </Popper>
        </PopperTrigger>
        <ActionStepCardEditButton onClickEdit={handleOpenMenu} />
      </div>
    ),
    [
      editAspectRatioLink,
      editTemplateLink,
      handleCloseMenu,
      handleEditAspectRatio,
      handleEditTemplate,
      handleOpenMenu,
      menuOpen,
    ],
  );

  return (
    <ActionStepCard
      description={getDimensionsText(dimension)}
      image={image}
      renderAction={renderAction}
      title="Visuals"
    />
  );
};

export default EditAutomationVisuals;
