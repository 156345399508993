import { divideEvenly } from 'utils/numbers';

function nextMultiple(n: number, multiple: number) {
  return n === 0 || multiple === 0 ? 0 : Math.ceil(n / multiple) * multiple;
}

export function getTicks(startSec: number, endSec: number, stepSec: number) {
  // first tick that exists in range [startMillis, endMillis]
  const start = nextMultiple(startSec, stepSec);

  // total number of ticks in [startMillis, endMillis]
  const nTicks = Math.floor((endSec - start) / stepSec) + 1;

  // create the ticks
  return Array(nTicks)
    .fill(0)
    .map((_, i) => start + i * stepSec);
}

export function getTickScale(pxPerSec: number) {
  const createResponse = (majorSec: number, stepSec: number) => ({
    majorSec,
    stepSec,
  });

  if (pxPerSec < 5) {
    return createResponse(30, 5);
  }

  if (pxPerSec < 20) {
    return createResponse(10, 1);
  }

  if (pxPerSec > 100) {
    return createResponse(1, 0.5);
  }

  return createResponse(5, 1);
}

export function formatTime(sec: number) {
  const { value: minutes, remainder: seconds } = divideEvenly(sec, 60);
  const minString = (() => {
    if (minutes === 0) return '';
    return `${minutes < 10 ? '0' : ''}${minutes}m`;
  })();
  const secString = (() => {
    return `${seconds < 10 ? '0' : ''}${seconds}s`;
  })();
  return [minString, secString].join(' ');
}
