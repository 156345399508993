import React from 'react';

import Banner from 'components/Banner';
import Button from 'components/Button';
import { HeadlinerMark } from 'components/icons';

import { DISCO_ADS_PROMO_LINK } from 'utils/constants';
import { block } from './utils';

interface SelfServeDiscoBannerProps {
  onClickGetListens: () => void;
}

const SelfServeDiscoBanner: React.FunctionComponent<SelfServeDiscoBannerProps> = props => {
  const { onClickGetListens } = props;

  return (
    <Banner
      icon={<HeadlinerMark height={45} width={45} className={block('icon')} />}
      theme="rainbow"
    >
      <div>
        <Banner.Title>Get podcast listens in Headliner</Banner.Title>
        <Banner.Content className={block('text')}>
          Promote clips of your podcast in Headliner with our new ad product!
        </Banner.Content>
      </div>
      <Banner.Actions>
        <Button
          className={block('button')}
          href={DISCO_ADS_PROMO_LINK}
          onClick={onClickGetListens}
          rel="noreferrer noopener"
          target="_blank"
          theme="submit"
        >
          get listens
        </Button>
      </Banner.Actions>
    </Banner>
  );
};

export default SelfServeDiscoBanner;
