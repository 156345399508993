import * as React from 'react';

import { NumericField, NumericFieldProps } from 'components/Form';
import { Omit } from 'types';
import SlideOptionsField from './SlideOptionsField';

type Props = Omit<NumericFieldProps, 'label'>;

const SlideAttributionYField: React.SFC<Props> = ({ className, ...props }) => (
  <SlideOptionsField className={className}>
    <NumericField {...props} label="Attribution Y" />
  </SlideOptionsField>
);

export default SlideAttributionYField;
export { Props as SlideAttributionYFieldProps };
