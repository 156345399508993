import * as React from 'react';

import { Omit } from 'types';
import ClippableTrackAsset, {
  ClippableTrackAssetProps,
} from '../ClippableTrackAsset';
import VideoAssetThumbnail, {
  VideoAssetThumbnailProps,
} from './VideoAssetThumbnail';

type InheritedProps = Omit<ClippableTrackAssetProps, 'position'>;

export interface VideoAssetProps
  extends InheritedProps,
    Omit<VideoAssetThumbnailProps, 'src'> {
  position: ClippableTrackAssetProps['position']['x'];
  posterClassName?: string;
  posterSrc?: string;
}

const VideoAsset: React.SFC<VideoAssetProps> = ({
  aspectRatio,
  position,
  posterClassName,
  posterSrc,
  scaling,
  ...props
}) => (
  <ClippableTrackAsset {...props} position={{ x: position, y: 0 }}>
    <VideoAssetThumbnail
      aspectRatio={aspectRatio}
      className={posterClassName}
      scaling={scaling}
      src={posterSrc}
    />
  </ClippableTrackAsset>
);

export default VideoAsset;
