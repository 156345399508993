import cn from 'classnames';
import * as React from 'react';
import LottieProgressCheckmark from 'components/LottieProgressCheckmark';
import ProgressListContext from './ProgressListContext';
import { block } from './utils';

interface Props {
  icon: React.ReactNode;
  secondaryIcon?: React.ReactNode;
  color: 'pink' | 'green' | 'purple' | 'blue' | 'orange' | 'red' | 'rainbow';
  loading: boolean;
  className?: string;
  offsetInDegrees?: number;
  style?: React.CSSProperties;
}

export const ProgressListItem: React.FC<Props> = props => {
  const {
    children,
    icon,
    secondaryIcon,
    color = 'blue',
    offsetInDegrees,
    loading,
    className,
    ...rest
  } = props;

  const onComplete = React.useContext(ProgressListContext);

  return (
    <li className={cn(block('item', { [color]: true }), className)} {...rest}>
      <div className={block('icon')}>{icon}</div>
      <div className={block('text')}>{children}</div>
      <div>
        {secondaryIcon || (
          <LottieProgressCheckmark
            {...{ loading, offsetInDegrees, onComplete }}
          />
        )}
      </div>
    </li>
  );
};

export default ProgressListItem;
