import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Modal';
import { onCompleteProAdjusment } from 'redux/modules/mixpanel/actions';
import { showSuccess } from 'redux/modules/notification';
import { showNotification } from 'redux/modules/notification/actions';

import { planNameSelector } from 'redux/modules/pricing/selectors';
import { Omit } from 'types';
import { getSubscriptionPeriod } from 'utils/pricing';
import { SubscriptionPeriod } from '../../types';
import useBillingCycle from '../../useBillingCycle';
import {
  BillingCycleModalContent,
  useBillingCycleState,
} from '../BillingCycleModal';
import {
  block,
  getPlanOptionsFromCurrentPlan,
  isDowngradingCycle,
} from './utils';

type PickedBillingCycleModalProps = Omit<
  React.ComponentProps<typeof BillingCycleModalContent>,
  | 'children'
  | 'options'
  | 'subscriptionPeriod'
  | 'onSubscriptionPeriodChange'
  | 'onSubmit'
>;

export interface Props extends PickedBillingCycleModalProps {
  onSubmit?: (period: SubscriptionPeriod) => void;
}

const ChangeBillingCycleModalContent: React.FC<Props> = ({
  onSubmit,
  onHide,
  disabled,
  ...props
}) => {
  const dispatch = useDispatch();
  const subscribedPlanName = useSelector(planNameSelector);
  const { billingPeriodEndDate } = useBillingCycle();
  const options = getPlanOptionsFromCurrentPlan(subscribedPlanName);
  const initialPeriod = getSubscriptionPeriod(subscribedPlanName);

  const [subPeriod, , handleSubPeriodChange] = useBillingCycleState(
    getSubscriptionPeriod(subscribedPlanName),
    options,
  );

  const isDowngradingToMonthly = isDowngradingCycle(initialPeriod, subPeriod);
  const disclaimerText = isDowngradingToMonthly
    ? 'Adjustments to billing cycle take effect at the end of the current cycle.'
    : "If you change your billing interval, we'll reimburse you for any unused time and then re-bill you for the new amount.";

  const onError = () => {
    dispatch(
      showNotification({
        message: 'Please try again or {{link}} so we can help',
        code: 'ER013',
        level: 'error',
        type: 'help',
        title: "Sorry, we couldn't update your billing cycle",
        actionLabel: 'contact us',
      }),
    );
  };
  const onSaved = (period: SubscriptionPeriod) => {
    // a different description should be shown when downgrading from yearly to monthly
    const notificationMessage = isDowngradingCycle(initialPeriod, period)
      ? `We'll start billing you ${period} on ${billingPeriodEndDate}`
      : `Your subscription is now billed ${period}`;
    dispatch(
      showSuccess({
        dismissAfterSec: 10,
        title: "You're all set",
        message: notificationMessage,
      }),
    );
    dispatch(onCompleteProAdjusment(period));
    onHide();
  };

  return (
    <BillingCycleModalContent
      loadingMessage="Updating Subscription"
      onSubscriptionPeriodChange={handleSubPeriodChange}
      options={options}
      subscriptionPeriod={subPeriod}
      disabled={disabled}
      onError={onError}
      onSaved={onSaved}
      onHide={onHide}
      {...props}
    >
      {({ billingCycleSelector, bodyClassName, footer, loadingOverlay }) => (
        <>
          <Modal.Body className={bodyClassName}>
            <p className={block('text')}>{disclaimerText}</p>
            {billingCycleSelector}
            {loadingOverlay}
          </Modal.Body>
          {footer}
        </>
      )}
    </BillingCycleModalContent>
  );
};

export default ChangeBillingCycleModalContent;
