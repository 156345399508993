import { AssetTileProps } from 'components/AssetTile';
import { AspectRatioName } from 'types';

export const templateStepSubSteps = [
  'aspect-ratio',
  'template-selection',
] as const;

type PickedTileProps = Pick<
  AssetTileProps,
  'background' | 'displayName' | 'onMouseOut' | 'onMouseOver'
>;

export type TemplateStepSubSteps = typeof templateStepSubSteps[number];

export interface TemplateSelectorTileProps extends PickedTileProps {
  aspectRatioName: AspectRatioName;
  children?: React.ReactNode;
  className?: string;
  id: string;
  isCompatible?: boolean;
  permanent: boolean;
  onClick?: (id: string) => void;
  onIncompatibleTemplateClick?: (id: string) => void;
  onDeleteClick?: (templateId: string) => void;
}
