import cn from 'classnames';
import * as React from 'react';

import { block } from './utils';

export interface BannerPageProps {
  backgroundClassName?: string;
  backgroundImageSrc: string;
  children?: React.ReactNode;
  className?: string;
}

/**
 * Layout with a full-width image that spans the top portion of the page.
 *
 * This component is used to create a few different Pricing settings pages. This
 * type of page also has an icon, title, and optional subtitle in the middle
 * of the page which can be added by rendering <BannerPageHeader /> as one of
 * the children.
 */

const BannerPage: React.SFC<BannerPageProps> = ({
  backgroundClassName,
  backgroundImageSrc,
  children,
  className,
}) => (
  <div className={cn(block(), className)}>
    <div
      className={cn(block('background'), backgroundClassName)}
      style={{ backgroundImage: `url(${backgroundImageSrc})` }}
    />
    {children}
  </div>
);

export default BannerPage;
