import React from 'react';
import { VideoCameraAlt } from 'components/icons';
import bem from 'utils/bem';
import PaidSubscriptionSuccessMessage from './PaidSubscriptionSuccessMessage';

export interface UnlimitedSuccessMessageProps {}

const block = bem('unlimited-success-message');

const UnlimitedSuccessMessage: React.FC<UnlimitedSuccessMessageProps> = () => (
  <PaidSubscriptionSuccessMessage
    icon={<VideoCameraAlt className={block('icon')} height={85} width="100%" />}
    message="Welcome to Headliner Unlimited!"
  />
);

export default UnlimitedSuccessMessage;
