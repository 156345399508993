import * as React from 'react';
import { IconProps } from './types';

function VideoCameraSpeechBubble(props: IconProps) {
  return (
    <svg
      width="110"
      height="100"
      viewBox="0 0 110 100"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="0%"
          y1="8.678%"
          x2="100%"
          y2="91.322%"
          id="wfol3p4a1a"
        >
          <stop stopColor="#5DBEFC" offset="0%" />
          <stop stopColor="#DD92FE" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M24 0h62c13.255 0 24 10.745 24 24v34.668c0 13.255-10.745 24-24 24H68.043a8 8 0 0 0-4.997 1.752L44.39 99.343A3 3 0 0 1 39.515 97V86.668a4 4 0 0 0-4-4H24c-13.255 0-24-10.745-24-24V24C0 10.745 10.745 0 24 0z"
          fill="url(#wfol3p4a1a)"
        />
        <path
          d="M65.871 21.632H43.12a2.683 2.683 0 0 0-2.573 3.442l1.886 6.385c.11.373.377.68.732.84l9.315 4.23a4.947 4.947 0 0 1 .016 9.003l-9.337 4.28a1.341 1.341 0 0 0-.726.837l-1.876 6.296a2.683 2.683 0 0 0 2.571 3.449h22.744c2.786 0 5.043-2.16 5.043-4.825V26.457c0-2.665-2.257-4.825-5.043-4.825zm-35.007 1.395-3.131 10.756a2.683 2.683 0 0 1-1.596 1.747l-7.606 2.986a2.683 2.683 0 0 0 0 4.994l7.606 2.985c.774.304 1.363.95 1.596 1.748l3.13 10.756a2.683 2.683 0 0 0 5.152 0l3.131-10.756a2.683 2.683 0 0 1 1.596-1.748l7.605-2.985a2.683 2.683 0 0 0 0-4.994l-7.605-2.986a2.683 2.683 0 0 1-1.596-1.747l-3.13-10.756a2.683 2.683 0 0 0-5.152 0zm54.99 2.174L75.48 32.153a2.683 2.683 0 0 0-1.19 2.229v13.27c0 .895.447 1.73 1.191 2.229l10.373 6.942c2.236 1.497 5.317-.03 5.317-2.645V27.845c0-2.603-3.07-4.14-5.317-2.644z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default VideoCameraSpeechBubble;
