import * as React from 'react';
import { noop } from 'underscore';

import CreateTabCard from 'blocks/ProjectsPage/components/CreateTabCard/CreateTabCard';
import TemplateTabs, { UserGeneratedEmpty } from 'blocks/TemplateTabs';
import { SaveAsTemplate as SaveAsTemplateIcon } from 'components/icons';
import { TemplateType } from 'types';
import {
  TEMPLATE_TAB_KEY_ALL,
  TEMPLATE_TAB_KEY_USER_GENERATED,
  TEMPLATE_TABS,
} from 'utils/constants';
import ProjectTemplateItem from '../../components/ProjectTemplateItem/ProjectTemplateItem';
import { TemplateItem, Templates } from '../../types';
import { blockProjectTemplates, getTemplatesByFilter } from '../../utils';

interface IProps {
  onClickDeleteTemplate?: (templateId: string) => void;
  onClickTemplate?: (template: TemplateItem) => void;
  onMount?: () => void;
  projectTemplates: Templates;
}

export default class ProjectTemplates extends React.Component<IProps> {
  public static defaultProps = {
    onMount: noop,
  };

  public componentDidMount() {
    const { onMount } = this.props;

    onMount();
  }

  private getAllTemplates() {
    const {
      onClickTemplate,
      projectTemplates,
      onClickDeleteTemplate,
    } = this.props;

    return getTemplatesByFilter(
      projectTemplates,
      TEMPLATE_TAB_KEY_ALL,
    ).map((template: TemplateItem) => (
      <ProjectTemplateItem
        id={template.id}
        key={`all-${template.id}`}
        template={template}
        onClickDeleteTemplate={onClickDeleteTemplate}
        onClickTemplate={onClickTemplate}
      />
    ));
  }

  private getUserTemplates() {
    const {
      onClickTemplate,
      projectTemplates,
      onClickDeleteTemplate,
    } = this.props;

    return getTemplatesByFilter(
      projectTemplates,
      TemplateType.USER_GENERATED,
    ).map((template: TemplateItem) => (
      <ProjectTemplateItem
        id={template.id}
        key={`user-${template.id}`}
        template={template}
        onClickDeleteTemplate={onClickDeleteTemplate}
        onClickTemplate={onClickTemplate}
      />
    ));
  }

  private getTabContent() {
    return [
      {
        items: this.getAllTemplates(),
        key: TEMPLATE_TAB_KEY_ALL,
        title: TEMPLATE_TABS[TEMPLATE_TAB_KEY_ALL],
      },
      {
        emptyContent: <UserGeneratedEmpty from="createTab" cta="learnMore" />,
        items: this.getUserTemplates(),
        key: TemplateType.USER_GENERATED,
        title: TEMPLATE_TABS[TEMPLATE_TAB_KEY_USER_GENERATED],
      },
    ];
  }

  public render() {
    const { projectTemplates } = this.props;

    if (Object.keys(projectTemplates).length === 0) return null;

    return (
      <div className={blockProjectTemplates()}>
        <CreateTabCard>
          <TemplateTabs
            contentItems={this.getTabContent()}
            overrides={{
              tabsContent: ({ children }) => (
                <CreateTabCard.Contents
                  className={blockProjectTemplates('tabs-content')}
                >
                  <div className={blockProjectTemplates('tabs-content-items')}>
                    {children}
                  </div>
                </CreateTabCard.Contents>
              ),
              header: ({ children }) => (
                <CreateTabCard.Header
                  icon={
                    <div className={blockProjectTemplates('header-icon')}>
                      <SaveAsTemplateIcon width={27} height="auto" />
                    </div>
                  }
                  subtitle="Try ours or save your own."
                  title="Templates"
                >
                  <ul className={blockProjectTemplates('tab-titles')}>
                    {children}
                  </ul>
                </CreateTabCard.Header>
              ),
            }}
          />
        </CreateTabCard>
      </div>
    );
  }
}

export { IProps as ProjectTemplatesProps };
