import { PropsOf } from 'types';
import VideoAsset from '../../components/VideoAsset';
import connect from './connect';

export { connect };

const component = connect(VideoAsset);

export type VideoClipAssetProps = PropsOf<typeof component>;
export default component;
