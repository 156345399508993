import * as Immutable from 'immutable';
import _ from 'underscore';

import { isCircleWaveType } from 'redux/modules/display-pref/utils';
import {
  ISoundwave,
  SoundWaveGeneration,
  SoundWaveGenerationValue,
  SoundwavePositionId,
  SoundwaveType,
} from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { getValue, omitUndefined } from 'utils/collections';
import { getComplementaryColor } from 'utils/color';
import { float } from 'utils/numbers';

const waveTypesWithSecondaryColor = [];

export const hasWaveTypeSecondaryColor = (waveType: SoundwaveType) =>
  waveTypesWithSecondaryColor.includes(waveType);

const defaultSoundwaveConfig = Immutable.fromJS({
  enabled: false,
  framesPerSecond: 30,
  position: {
    properties: {
      left: '0',
      top: '0',
    },
    type: 'absolute',
  },
  style: {
    color: '#ffffff',
    height: '30vh',
    lineWidth: '3px',
    pattern: 'none',
    radiusMultiplier: 2,
    secondaryColor: undefined,
    width: '100vw',
  },
});

export const defaultSoundwaveState = Immutable.fromJS({
  waveColor: '#ffffff',
  wavePosition: {
    left: 0,
    top: 0,
  },
  waveSecondaryColor: undefined,
  waveSize: {
    height: '30vh',
    width: '100vw',
  },
  waveType: 'none',
});

export const formatSoundwaveForConfig = (
  soundwave,
  trackIndex: number,
): ISoundwave => {
  if (!soundwave || soundwave.isEmpty() || trackIndex === undefined)
    return undefined;

  const soundwaveConfig = Immutable.fromJS(
    omitUndefined({
      enabled: soundwave.get('waveType') !== 'none',
      endMilli: soundwave.getIn(['time', 'endMillis']),
      layerId: trackIndex >= 0 ? trackIndex : undefined,
      position: {
        properties: !soundwave.get('wavePosition')
          ? undefined
          : {
              left: getValue(soundwave, ['wavePosition', 'left']),
              top: getValue(soundwave, ['wavePosition', 'top']),
            },
      },
      startMilli: soundwave.getIn(['time', 'startMillis']),
      style: {
        color: soundwave.get('waveColor'),
        height: getValue(soundwave, ['waveSize', 'height']),
        lineWidth: soundwave.get('waveType') === 'curve' ? '1px' : undefined,
        pattern: soundwave.get('waveType'),
        secondaryColor: hasWaveTypeSecondaryColor(soundwave.get('waveType'))
          ? getComplementaryColor(soundwave.get('waveColor'))
          : undefined,
        width: getValue(soundwave, ['waveSize', 'width']),
      },
      waveformPrefId: soundwave.get('waveformPrefId'),
      preferredWaveformType: soundwave.get('waveGeneration'),
    }),
  );

  /*
   * mergeDeep will prioritize the right size, but sometimes we have undefined's on the right side
   * and in that case we want to take what's on the left.  use mergeDeepWith so we have control to
   * do this.
   */
  const newConfig = defaultSoundwaveConfig.mergeDeepWith(
    (oldVal, newVal) => (_.isUndefined(newVal) ? oldVal : newVal),
    soundwaveConfig,
  );

  return newConfig.toJS();
};

export const formatSoundwaveFromConfig = soundwave => {
  if (
    soundwave &&
    soundwave.position &&
    soundwave.position.properties &&
    soundwave.style
  ) {
    const position = soundwave.position.properties;

    return Immutable.fromJS({
      waveColor: soundwave.style.color,
      wavePosition: {
        left:
          position.left ||
          defaultSoundwaveState.getIn(['wavePosition', 'left']),
        top:
          position.top || defaultSoundwaveState.getIn(['wavePosition', 'top']),
      },
      waveSecondaryColor: soundwave.style.secondaryColor || undefined,
      waveSize: {
        height:
          soundwave.style.height ||
          defaultSoundwaveState.getIn(['waveSize', 'height']),
        width:
          soundwave.style.width ||
          defaultSoundwaveState.getIn(['waveSize', 'width']),
      },
      waveType: soundwave.style.pattern,
      time: {
        endMillis: soundwave.endMilli,
        startMillis: soundwave.startMilli,
      },
      waveformPrefId: soundwave.waveformPrefId,
      waveGeneration: soundwave.preferredWaveformType,
    });
  }

  // return defaultSoundwaveState;
  return undefined;
};

const getCircleTopDimensionsByRatio = (ratioName: string) => {
  switch (ratioName) {
    default:
    case 'square':
      return {
        height: '50vh',
        left: '25vw',
        top: '0vh',
        width: '50vw',
      };

    case 'landscape':
      return {
        height: '50vh',
        left: '35.9375vw',
        top: '0vh',
        width: '28.125vw',
      };

    case 'portrait':
      return {
        height: '28.17vh',
        left: '25vw',
        top: '0vh',
        width: '50vw',
      };
  }
};

const getCircleMiddleDimensionsByRatio = (ratioName: string) => {
  switch (ratioName) {
    default:
    case 'square':
      return {
        height: '50vh',
        left: '25vw',
        top: '25vh',
        width: '50vw',
      };

    case 'landscape':
      return {
        height: '50vh',
        left: '35.9375vw',
        top: '25vh',
        width: '28.125vw',
      };

    case 'portrait':
      return {
        height: '28.17vh',
        left: '25vw',
        top: '35.915vh',
        width: '50vw',
      };
  }
};

const getCircleBottomDimensionsByRatio = (ratioName: string) => {
  switch (ratioName) {
    default:
    case 'square':
      return {
        height: '50vh',
        left: '25vw',
        top: '50vh',
        width: '50vw',
      };

    case 'landscape':
      return {
        height: '50vh',
        left: '35.9375vw',
        top: '50vh',
        width: '28.125vw',
      };

    case 'portrait':
      return {
        height: '28.17vh',
        left: '25vw',
        top: '71.83vh',
        width: '50vw',
      };
  }
};

export const soundwavePositionToDimensions = (
  position?: SoundwavePositionId,
  aspectRatio?: number,
  waveType?: SoundwaveType,
) => {
  if (aspectRatio && waveType && isCircleWaveType(waveType)) {
    const aspectRatioName = getAspectRatioName(aspectRatio);
    if (position === 'top') {
      return getCircleTopDimensionsByRatio(aspectRatioName);
    }

    if (position === 'middle') {
      return getCircleMiddleDimensionsByRatio(aspectRatioName);
    }

    if (position === 'bottom') {
      return getCircleBottomDimensionsByRatio(aspectRatioName);
    }
  }

  if (position === 'prisa-default') {
    return { top: '44vh', left: 0, width: '100vw', height: '12vh' };
  }

  if (position === 'top') {
    if (waveType === 'linearDots') {
      return { top: 0, left: '33.5vw', width: '33vw', height: '15vh' };
    }

    return { top: 0, left: 0, width: '100vw', height: '30vh' };
  }

  if (position === 'middle') {
    if (waveType === 'linearDots') {
      return {
        height: '15vh',
        left: '33.5vw',
        top: `42.5vh`,
        width: '33vw',
      };
    }

    return {
      height: '30vh',
      left: 0,
      top: `35vh`,
      width: '100vw',
    };
  }

  if (position === 'bottom') {
    if (waveType === 'linearDots') {
      return {
        height: '15vh',
        left: '33.5vw',
        top: `85vh`,
        width: '33vw',
      };
    }

    return {
      height: '30vh',
      left: 0,
      top: `70vh`,
      width: '100vw',
    };
  }

  if (position === 'padded-bottom') {
    return {
      height: '13.88vh',
      left: '5.56vw',
      top: '68.05vh',
      width: '88.88vw',
    };
  }

  if (position === 'lower-third') {
    return {
      height: '30vh',
      left: '0vw',
      top: '50.979381443298955vh',
      width: '100vw',
    };
  }

  return undefined;
};

export const soundwaveDimensionsToPosition = (
  dimensions,
): SoundwavePositionId => {
  const top = float(dimensions.top as string);
  const left = float(dimensions.left as string);
  const height = float(dimensions.height as string);

  if (top === 44 && height === 12) {
    return 'prisa-default';
  }

  if (top === 0) {
    return 'top';
  }

  if (top === 50 - height / 2) {
    return 'middle';
  }

  if (top === 100 - height) {
    return 'bottom';
  }

  if (top === 68.05 && left === 5.56) {
    return 'padded-bottom';
  }

  if (top === 50.979381443298955 && left === 0 && height === 30) {
    return 'lower-third';
  }

  return 'custom';
};

export const getPositionIdFromWave = (wave): SoundwavePositionId =>
  soundwaveDimensionsToPosition({
    height: getValue(wave, ['waveSize', 'height']),
    left: getValue(wave, ['wavePosition', 'left']),
    top: getValue(wave, ['wavePosition', 'top']),
    width: getValue(wave, ['waveSize', 'width']),
  });

export const mapSoundwaveGeneration = (
  waveGeneration: SoundWaveGeneration,
): SoundWaveGenerationValue =>
  waveGeneration === 'amplitudeBased' ? 'fast' : 'slow';

// TODO common soundwave type
export function hasSoundwave(soundwave: any) {
  const type = getValue(soundwave, 'waveType');
  return type !== undefined && type !== 'none';
}
export default {
  formatSoundwaveForConfig,
  formatSoundwaveFromConfig,
};
