import * as React from 'react';

import Banner from 'components/Banner';
import Button from 'components/Button';
import FontAwesome from 'components/FontAwesome';
import LinkButton from 'components/LinkButton';
import { block } from '../../utils';
import useVerificationState from './useVerificationState';

export const VerificationReminderBanner: React.FC = () => {
  const {
    email,
    loading,
    onResendEmail,
    onChangeEmail,
  } = useVerificationState();

  return (
    <Banner theme="rainbow" icon={<FontAwesome icon="envelope" size="3x" />}>
      <div>
        <Banner.Title>
          <FontAwesome icon="envelope" size="lg" /> Please verify your email
        </Banner.Title>
        <Banner.Content>
          We sent a verification to {email}.
          <br />
          Can’t find it? Check your spam or{' '}
          <LinkButton
            theme="cta"
            className={block('resend-link')}
            onClick={onResendEmail}
            disabled={loading}
          >
            tap here to resend it
          </LinkButton>
          <span>choose an option to the right</span>.
        </Banner.Content>
        <footer className={block('verification-banner-footer')}>
          To change your email, login on desktop & visit Account Settings.
        </footer>
      </div>
      <Banner.Actions>
        <Button theme="submit" onClick={onResendEmail} disabled={loading}>
          Resend Verification
        </Button>
        <LinkButton
          theme="cta"
          className={block('link')}
          onClick={onChangeEmail}
          disabled={loading}
        >
          Change Email
        </LinkButton>
      </Banner.Actions>
    </Banner>
  );
};

export default VerificationReminderBanner;
