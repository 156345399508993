import * as React from 'react';
import { TooltipRenderProps } from 'react-joyride';

import Button from 'components/Button';
import { blockTooltip } from '../../utils';

interface TooltipProps extends TooltipRenderProps {
  tooltipProps: {
    'aria-modal': boolean;
    ref: () => void;
    role: string;
  };
}

type Ref = (el: HTMLElement) => void;

const getNextButtonText = (index: number, size: number): string =>
  index === size - 1 ? 'Done' : 'Next';

const Tooltip = React.forwardRef((props: TooltipProps, closeButtonRef: Ref) => {
  const {
    continuous,
    index,
    size,
    step,
    backProps,
    closeProps,
    primaryProps,
    skipProps,
    tooltipProps,
  } = props;

  return (
    <div {...tooltipProps} className={blockTooltip()}>
      <Button
        {...closeProps}
        onClick={skipProps.onClick}
        title="Close"
        theme="ghost"
        size="small"
        className={blockTooltip('button', { close: true })}
        domRef={closeButtonRef}
      >
        X
      </Button>

      {step.title && <div className={blockTooltip('title')}>{step.title}</div>}

      <div className={blockTooltip('content')}>{step.content}</div>

      <div className={blockTooltip('footer')}>
        {index > 0 && (
          <Button
            {...backProps}
            title="Back"
            theme="ghost"
            size="small"
            className={blockTooltip('button', { back: true })}
          >
            Back
          </Button>
        )}

        {continuous && (
          <Button
            {...primaryProps}
            title={getNextButtonText(index, size)}
            theme="submit"
            className={blockTooltip('button')}
          >
            {getNextButtonText(index, size)}
          </Button>
        )}

        {!continuous && (
          <Button
            {...closeProps}
            title="Close"
            theme="submit"
            className={blockTooltip('button')}
          >
            Close
          </Button>
        )}
      </div>
    </div>
  );
});

export default Tooltip;
export { TooltipProps };
