import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteModal from 'components/DeleteModal';
import Modal from 'components/Modal';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import { removeWatermark } from 'redux/modules/embed/actions';
import { watermarkStatusSelector } from 'redux/modules/embed/selectors';
import { hideModal, replaceModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';

interface Props {}

const LockedWatermarkEditModal: React.FC<Props> = () => {
  const { show, onHide, onExited } = useConnectedModal('EditWatermark');
  const dispatch = useDispatch<Dispatch>();
  const status = useSelector(watermarkStatusSelector);
  const onDelete = async () => {
    await dispatch(removeWatermark());
    dispatch(hideModal());
  };
  const onChange = () => {
    dispatch(
      replaceModal({
        name: 'AddWatermark',
      }),
    );
  };

  return (
    <DeleteModal
      title="Edit watermark"
      bodyText="How would you like to edit this watermark?"
      show={show}
      onCancel={onHide}
      onExited={onExited}
      footer={
        <Modal.Footer>
          <Modal.FooterButtons fluid>
            <Modal.FooterButton
              onClick={onDelete}
              theme="delete"
              disabled={!!status}
            >
              Remove from video
            </Modal.FooterButton>
            <Modal.FooterButton
              onClick={onChange}
              theme="submit"
              disabled={!!status}
            >
              Change selection
            </Modal.FooterButton>
          </Modal.FooterButtons>
        </Modal.Footer>
      }
    />
  );
};

LockedWatermarkEditModal.displayName = 'LockedWatermarkEditModal';

export default LockedWatermarkEditModal;
