import { actions } from 'redux/middleware/api/audiogram-data-service';
import * as types from '../action-types';
import { selectAddingToTrack } from '../selectors';
import {
  createEmbedConfigurationFromState,
  saveConfiguration,
  setEmbedDuration,
} from './embed';
import { addToTrack } from './tracks';

export const updateSoundwave = soundwave => dispatch => {
  dispatch({
    type: types.EMBED_SOUNDWAVE_UPDATE,
    payload: soundwave,
  });
  dispatch(setEmbedDuration(true));
};

export const saveSoundwave = soundwave => dispatch => {
  dispatch(updateSoundwave(soundwave));
  return dispatch(saveConfiguration());
};

export const soundwaveResolved = versionId => dispatch =>
  dispatch(actions.isWaveformResolved(versionId));

export const getSoundwave = versionId => dispatch =>
  dispatch(actions.getDataByRecordingVersion(versionId));

export const removeSoundwave = () => (dispatch, getState) => {
  dispatch({ type: types.EMBED_SOUNDWAVE_REMOVE });
  dispatch(setEmbedDuration(true));
  return dispatch(createEmbedConfigurationFromState());
};

export const addSoundwave = soundwave => (dispatch, getState) => {
  dispatch(saveSoundwave(soundwave));

  const addingToTrackId = selectAddingToTrack(getState());

  dispatch(addToTrack(addingToTrackId, 'waveform'));
};

export default {
  updateSoundwave,
  soundwaveResolved,
  getSoundwave,
};
