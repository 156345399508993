import {
  AudiogramDestinationConfig,
  MultiPathAudiogramDestination,
} from 'blocks/AudiogramDestinations/types';
import facebook from './facebook';
import instagram from './instagram';
import tiktok from './tiktok';
import youtube from './youtube';

const audiogramDestinationConfigMap: Record<
  Exclude<MultiPathAudiogramDestination, 'threads'>,
  AudiogramDestinationConfig
> = {
  youtube,
  instagram,
  facebook,
  tiktok,
};

export default audiogramDestinationConfigMap;
