import React, { useMemo } from 'react';

import PodcastHeading from 'blocks/AutomationCMS/components/PodcastHeading';
import { InfiniteScrollProps } from 'components/InfiniteScroll';
import AutomationCMSEpisodeList from '../../components/AutomationCMSEpisodeList';
import AutomationCMSInfiniteScroll from '../../components/AutomationCMSInfiniteScroll';
import PodcastFeedDataContext from './PodcastFeedDataContext';
import usePodcastFeedEpisodes from './usePodcastFeedEpisodes';

type PickedInfiniteProps = Pick<InfiniteScrollProps, 'getScrollParent'>;

export interface PodcastFeedDataProps extends PickedInfiniteProps {
  active: boolean;
  podcastFeedId: string;
}

const PodcastFeedData: React.FC<PodcastFeedDataProps> = ({
  active,
  getScrollParent,
  podcastFeedId,
}) => {
  const {
    episodes,
    hasMore,
    isFetching,
    loadMoreEpisodes,
    workflows,
    canRefreshFeed,
  } = usePodcastFeedEpisodes(podcastFeedId);

  const contextValue = useMemo(
    () => ({
      podcastId: podcastFeedId,
    }),
    [podcastFeedId],
  );

  return (
    <PodcastFeedDataContext.Provider value={contextValue}>
      <AutomationCMSInfiniteScroll
        getScrollParent={getScrollParent}
        hasMore={hasMore}
        isLoading={isFetching}
        active={active}
        onLoadMore={loadMoreEpisodes}
        loadingText="Loading episodes"
      >
        <PodcastHeading
          podcastId={podcastFeedId}
          showRefreshButton={canRefreshFeed}
        />

        {episodes && workflows && (
          <AutomationCMSEpisodeList episodes={episodes} workflows={workflows} />
        )}
      </AutomationCMSInfiniteScroll>
    </PodcastFeedDataContext.Provider>
  );
};

export default PodcastFeedData;
