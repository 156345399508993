import * as React from 'react';
import ResizeDetector from 'react-resize-detector';
import { isUndefined } from 'underscore';

import VideoEditorPlaybackTimeContext from 'containers/VideoEditor/VideoEditorPlaybackTimeContext';
import { offsetX } from 'utils/dom';
import Playhead, { PlayheadProps } from '../../containers/Playhead/Playhead';
import { block, positionToSec, secToPosition } from '../../utils';
import RulerCanvas from './RulerCanvas';

export interface RulerProps {
  draggingPlayhead?: boolean;
  onPlayheadDragStart?: () => void;
  onPlayheadDragStop?: () => void;
  onSeek?: (positionSec: number) => void;
  pxPerSec: number;
  startSec: number;
  endSec: number;
}

interface State {
  height: number;
  width: number;
}

export default class Ruler extends React.Component<RulerProps, State> {
  public static defaultProps: Partial<RulerProps> = {
    draggingPlayhead: false,
  };

  public state: Readonly<State> = {
    height: undefined,
    width: undefined,
  };

  /*
   * stop propagation otherwise playhead can snap back to 0 if user drags
   * and and then releases mouse button quickly
   */
  private handlePlayheadClick: PlayheadProps['onClick'] = e =>
    e.stopPropagation();

  private handlePlayheadDrag: PlayheadProps['onDrag'] = (_, data) => {
    const { onSeek, pxPerSec, startSec } = this.props;
    const leftEdge = secToPosition(startSec, pxPerSec);
    const position = leftEdge + data.x;
    onSeek(positionToSec(position, pxPerSec));
  };

  private handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const { onSeek, pxPerSec, startSec } = this.props;
    const leftEdge = secToPosition(startSec, pxPerSec);
    const position = leftEdge + offsetX(e);
    onSeek(positionToSec(position, pxPerSec));
  };

  private handleResize = (width: number, height: number) =>
    this.setState({ height, width });

  public render() {
    const {
      draggingPlayhead,
      endSec,
      onPlayheadDragStart,
      onPlayheadDragStop,
      pxPerSec,
      startSec,
    } = this.props;
    const { height, width } = this.state;

    return (
      <VideoEditorPlaybackTimeContext.Consumer>
        {({ positionSec }) => (
          <div
            className={block('ruler')}
            onClick={this.handleClick}
            style={{ cursor: 'pointer' }}
          >
            <ResizeDetector
              handleWidth
              handleHeight
              onResize={this.handleResize}
            />
            {isUndefined(height) || isUndefined(width) ? null : (
              <RulerCanvas
                height={height}
                width={width}
                pxPerSec={pxPerSec}
                startSec={startSec}
                endSec={endSec}
              />
            )}
            <Playhead
              bounds={
                !draggingPlayhead
                  ? undefined
                  : {
                      left: 0,
                      right: width,
                    }
              }
              hide={positionSec < startSec || positionSec > endSec}
              position={secToPosition(positionSec - startSec, pxPerSec)}
              onClick={this.handlePlayheadClick}
              onDrag={this.handlePlayheadDrag}
              onStart={onPlayheadDragStart}
              onStop={onPlayheadDragStop}
            />
          </div>
        )}
      </VideoEditorPlaybackTimeContext.Consumer>
    );
  }
}
