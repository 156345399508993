import * as React from 'react';
import { IconProps } from './types';

function IHeart(props: IconProps) {
  return (
    <svg width={47} height={35} {...props}>
      <path
        d="M34.375 0c-4.43 0-8.46 2.425-10.742 5.792C21.35 2.29 17.322 0 12.891 0 5.774 0 0 5.792 0 12.932c0 4.58 2.82 7.813 5.774 10.777l11.682 11.046c.672.538 1.612.134 1.612-.674V25.46c0-2.425 2.014-4.446 4.43-4.58 2.418 0 4.432 2.02 4.432 4.58v8.621c0 .808.94 1.212 1.611.674l11.683-11.046c2.954-2.964 5.774-6.332 5.774-10.777C47.132 5.792 41.358 0 34.375 0zM11.951 22.766c-.134.134-.403.27-.672.27-.268 0-.402-.136-.537-.27-3.894-3.368-5.774-7.005-5.774-10.642 0-3.099 1.88-6.601 4.432-8.217.402-.27.94-.135 1.208.269.269.404.134.943-.269 1.212-2.014 1.213-3.49 4.176-3.49 6.736 0 3.098 1.745 6.196 5.236 9.295.134.404.134 1.077-.134 1.347zm5.64-3.233c-.135.27-.403.404-.806.404-.134 0-.269 0-.403-.135-2.551-1.482-4.297-4.31-4.297-7.14 0-2.694 1.477-5.118 3.894-6.33.403-.27.94 0 1.209.403.268.405 0 .943-.403 1.213-1.746.943-2.954 2.829-2.954 4.85 0 2.155 1.343 4.445 3.491 5.657.403 0 .537.539.269 1.078zm6.042-2.29a3.904 3.904 0 01-3.894-3.907 3.903 3.903 0 013.894-3.906 3.902 3.902 0 013.894 3.906c-.134 2.155-1.88 3.907-3.894 3.907zm7.117 2.56c-.134.134-.269.134-.403.134-.268 0-.671-.135-.806-.404-.268-.404-.134-.943.403-1.213 2.014-1.212 3.492-3.367 3.492-5.657 0-2.021-1.075-3.907-2.955-4.85-.402-.27-.537-.808-.402-1.212.268-.404.805-.54 1.208-.404 2.417 1.212 3.894 3.637 3.894 6.33-.134 2.964-1.88 5.793-4.431 7.275zm11.548-7.545c0 3.638-1.88 7.14-5.774 10.642-.134.135-.403.27-.537.27-.269 0-.537-.135-.672-.27-.268-.404-.268-.943.135-1.212 3.491-3.098 5.237-6.197 5.237-9.295 0-2.56-1.612-5.388-3.492-6.735-.403-.27-.537-.809-.268-1.213.268-.404.805-.538 1.208-.27 2.283 1.483 4.163 4.985 4.163 8.083z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default IHeart;
