import cn from 'classnames';
import * as React from 'react';

import CircularGradientEnterprise from 'components/icons/circular-gradient/CircularGradientEnterprise';
import {
  enterpriseFeatures,
  PaidSubscriptionHeader,
  PaidSubscriptionHeaderProps,
} from '../../components/PaidSubscription';
import { block } from './utils';

type PickedHeaderProps = Pick<PaidSubscriptionHeaderProps, 'title'>;

export interface EnterpriseSubscriptionHeaderProps extends PickedHeaderProps {
  className?: string;
}

const EnterpriseSubscriptionHeader: React.FC<EnterpriseSubscriptionHeaderProps> = ({
  className,
  ...props
}) => {
  return (
    <PaidSubscriptionHeader
      className={cn(block('header'), className)}
      icon={<CircularGradientEnterprise className={block('icon')} />}
      {...props}
    >
      <PaidSubscriptionHeader.Features features={enterpriseFeatures} />
    </PaidSubscriptionHeader>
  );
};

export default EnterpriseSubscriptionHeader;
