import { fromJS } from 'immutable';
import React from 'react';

import { TextShadowV2 } from 'components/TextToolbar';

import { useTextOverlay } from '../state/TextOverlayProvider';

interface UseTextOverlayEffects {
  onChangeHighlightColor: (color: string) => void;
  onChangeShadowStyle: (shadowStyle: TextShadowV2) => void;
  selectedHighlightColor: string;
  selectedShadowStyle: TextShadowV2;
}

const useTextOverlayEffects = (): UseTextOverlayEffects => {
  const { draftEditorData, onUpdateStyle } = useTextOverlay();

  const selectedHighlightColor = draftEditorData.getIn([
    'editor',
    'textStyle',
    'textHighlight',
  ]);
  const selectedShadowStyle = draftEditorData
    .getIn(['editor', 'textStyle', 'textShadow'])
    ?.toJS();

  const handleChangeHighlightColor = React.useCallback(
    (color: string): void => {
      onUpdateStyle(editorStyle => editorStyle.set('textHighlight', color));
    },
    [onUpdateStyle],
  );

  const handleChangeShadowStyle = React.useCallback(
    (shadowStyle: TextShadowV2): void => {
      onUpdateStyle(editorStyle =>
        editorStyle.set('textShadow', fromJS(shadowStyle)),
      );
    },
    [onUpdateStyle],
  );

  return {
    onChangeHighlightColor: handleChangeHighlightColor,
    onChangeShadowStyle: handleChangeShadowStyle,
    selectedHighlightColor,
    selectedShadowStyle,
  };
};

export default useTextOverlayEffects;
