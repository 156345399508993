import cn from 'classnames';
import * as React from 'react';
import {
  FixedSizeList,
  FixedSizeListProps,
  ListChildComponentProps,
  OuterProps,
} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { noop } from 'underscore';

import Spinner from 'components/Spinner';
import { block } from '../utils';
import SearchResultListScrollBars from './SearchResultListScrollBars';

const { useCallback, useMemo, useRef } = React;

type PickedListProps = Pick<FixedSizeListProps, 'onScroll'>;

export interface SearchResultRenderProps<T = any>
  extends ListChildComponentProps {
  data: T[];
  result: T;
}

export interface SearchResultListProps<T = any> extends PickedListProps {
  className?: string;
  children?: React.ReactType<SearchResultRenderProps<T>>;
  containerClassName?: string;
  defaultScrollOffset?: number;
  getItemKey?: (index: number, results: T[]) => React.Key;
  hasMore?: boolean;
  height: number;
  isLoading?: boolean;
  itemSize: number;
  onLoadPage?: (startOffset: number, endOffset: number) => Promise<void>;
  results?: T[];
  threshold?: number;
}

/**
 * Virtualized, infinite loading list with custom scrollbars.
 *
 * react-window-infinite-loader is used instead of
 * react-infinite-scroller (which is used elsewhere) becuase of virtualiztation.
 * Virtualization and infinite loading both need some of the same information
 * (e.g. scroll offset) and it's difficult to make the two libraries work
 * together.
 *
 * For example, react-infinite-scroller needs to serve as the wrapper around
 * the list items.  Because the list is virtualized, the list items are rendered
 * by react-window.  The wrapper react-window uses can be replaced via the
 * `innerElementType` prop, but the react-window component doesn't support
 * passing props to the `innerElementType`, which means we wouldn't be able to
 * wire up the react-infinite-scroller to do anything.
 *
 * The props issue also makes it difficult for the infinite scroller and the
 * virtualizer to share scroll data.
 *
 * react-window-infinite-loader was designed for use with
 * react-window, so the integration is simple and well documented, so these
 * libraries were used.
 */

export default function SearchResultList<T = any>({
  children,
  className,
  containerClassName,
  defaultScrollOffset,
  getItemKey,
  hasMore,
  height,
  isLoading,
  itemSize,
  onLoadPage,
  onScroll,
  results,
  threshold,
}: SearchResultListProps<T>) {
  const listRef = useRef<FixedSizeList>();

  const isItemLoaded = useCallback(
    index => !hasMore || index < results.length,
    [hasMore, results],
  );

  const handleGetItemKey = useCallback(
    (index, data) =>
      !isItemLoaded(index) ? 'spinner' : getItemKey(index, data),
    [getItemKey, isItemLoaded],
  );

  const totalResults = hasMore ? results.length + 1 : results.length;

  const InnerElement = useMemo(() => {
    if (!containerClassName) return undefined;
    return props => <div {...props} className={containerClassName} />;
  }, [containerClassName]);

  const OuterElement = useMemo(
    () =>
      React.forwardRef<any, OuterProps>(({ ...props }, ref) => (
        <SearchResultListScrollBars {...props} ref={ref} />
      )),
    [],
  );

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={totalResults}
      threshold={threshold}
      loadMoreItems={isLoading ? () => Promise.resolve() : onLoadPage}
    >
      {({ onItemsRendered, ref }) => (
        <FixedSizeList
          className={cn(block('result-list'), className)}
          height={height}
          initialScrollOffset={defaultScrollOffset}
          innerElementType={InnerElement}
          itemCount={totalResults}
          itemKey={handleGetItemKey}
          itemSize={itemSize}
          onItemsRendered={onItemsRendered}
          onScroll={onScroll}
          outerElementType={OuterElement}
          ref={el => {
            // @ts-ignore
            ref(el);
            listRef.current = el;
          }}
          width="100%"
        >
          {listItemProps =>
            !isItemLoaded(listItemProps.index) ? (
              <div
                className={block('result-spinner')}
                style={{
                  ...listItemProps.style,
                  height: itemSize,
                }}
              >
                <Spinner />
              </div>
            ) : (
              React.createElement(children, {
                ...listItemProps,
                result: results[listItemProps.index],
              })
            )
          }
        </FixedSizeList>
      )}
    </InfiniteLoader>
  );
}

SearchResultList.defaultProps = {
  children: () => null,
  defaultScrollOffset: 0,
  onLoadPage: noop,
  results: [],
};
