import * as React from 'react';
import { RouteChildrenProps } from 'react-router';

import { Omit } from 'types';
import { callRender } from 'utils/react';
import { getAccessToken } from 'utils/routes';

interface RenderProps extends Omit<IProps, 'authenticate' | 'render'> {
  token?: string;
}

interface IProps extends RouteChildrenProps {
  /**
   * key used for the access token in the query string. defaults to "accessToken"
   */
  accessTokenName?: string;

  /**
   * called in two cases:
   *  - on mount when an access token is found in the query string
   *  - on update when the access token changes and is not undefined
   */
  authenticate: (accessToken: string) => void;

  /**
   * true if authentication is in progress
   */
  isAuthenticating: boolean;

  /**
   * true if the user is authenticated
   */
  isAuthenticated: boolean;

  /**
   * render the component.  can use props to determine what to render (busy state, error state,
   * protected component, etc.)
   */
  render: (props: RenderProps) => JSX.Element;
}

export default class QueryStringAuthenticator extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    accessTokenName: 'accessToken',
  };

  public componentDidMount() {
    this.callAuthenticate();
  }

  private callAuthenticate(props: Readonly<IProps> = this.props) {
    const { authenticate } = props;
    const accessToken = this.getAccessToken();

    if (accessToken) {
      authenticate(accessToken);
    }
  }

  private getAccessToken(props: Readonly<IProps> = this.props) {
    const { accessTokenName } = props;
    return getAccessToken(this.props, accessTokenName);
  }

  public render() {
    const { render } = this.props;
    return callRender(render, {
      ...this.props,
      token: this.getAccessToken(),
    });
  }
}

export { IProps as QueryStringAuthenticatorProps };
