import cn from 'classnames';
import * as React from 'react';

import Button from 'components/Button';
import { FileUploaderDropzoneProps } from './FileUploaderDropzone';
import MediaImporterOptions from './MediaImporterOptions';
import {
  block,
  getSupportedFileMaxInfoInText,
  getSupportedFormatsInText,
} from './utils';

type PickedProps = Pick<
  FileUploaderDropzoneProps,
  | 'title'
  | 'footer'
  | 'image'
  | 'supportedFileTypes'
  | 'supportedFileMaxSizeInMb'
  | 'additionalRestrictions'
  | 'templateDimensionsInfo'
  | 'uploadMethod'
  | 'onClickMediaImportOption'
>;

interface IProps extends PickedProps {
  onClickUploadButton: () => void;
}

const FileUploaderDropzoneBody: React.SFC<IProps> = ({
  additionalRestrictions,
  footer,
  image,
  onClickUploadButton,
  supportedFileMaxSizeInMb,
  supportedFileTypes,
  title,
  templateDimensionsInfo,
  uploadMethod,
  onClickMediaImportOption,
}) => (
  <div className={block('body')}>
    <div className={block('header')}>
      <div className={block('file-info')}>
        <div className={block('image')}>
          <div className={block('image-icon')}>{image}</div>
        </div>

        <div
          className={block('description', {
            'two-lines': !supportedFileMaxSizeInMb && !additionalRestrictions,
          })}
        >
          <div className={block('title')}>{title}</div>

          <div
            className={block('file-data', {
              extensions: true,
              'extensions-three-or-more': supportedFileTypes.length >= 3,
            })}
          >
            {getSupportedFormatsInText(supportedFileTypes)}
          </div>

          {(supportedFileMaxSizeInMb || additionalRestrictions) && (
            <div className={block('file-data', { 'max-values': true })}>
              {getSupportedFileMaxInfoInText(
                supportedFileMaxSizeInMb,
                additionalRestrictions,
              )}
            </div>
          )}
        </div>
      </div>

      {templateDimensionsInfo && (
        <div className={block('template-dimensions-info')}>
          {templateDimensionsInfo} <span>recommended</span>
        </div>
      )}

      {uploadMethod === 'chooseFile' && (
        <Button
          className={block('button')}
          onClick={onClickUploadButton}
          theme="ghost-bordered"
        >
          or choose a file
        </Button>
      )}
    </div>

    {uploadMethod === 'mediaImporter' && (
      <MediaImporterOptions
        {...{ onClickUploadButton, onClickMediaImportOption }}
      />
    )}

    {footer && (
      <div className={block('footer')}>
        {React.cloneElement(footer, {
          className: cn(block('footer-button'), footer.props.className),
        })}
      </div>
    )}
  </div>
);

export default FileUploaderDropzoneBody;
export { IProps as FileUploaderDropzoneBodyProps };
