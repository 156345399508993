import * as React from 'react';
import { noop } from 'underscore';

import PaddingOptionsField, {
  PaddingOptionsFieldProps,
} from './PaddingOptionsField';

export interface IPadding {
  bottom?: number | '';
  left?: number | '';
  right?: number | '';
  top?: number | '';
}

interface IProps {
  disabled?: boolean;
  value?: IPadding;
  onBlur?: (value: IPadding) => void;
  onChange?: (value: IPadding) => void;
}

export default class PaddingOptions extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    disabled: false,
    onBlur: noop,
    onChange: noop,
  };

  private handlePaddingChange: PaddingOptionsFieldProps['onChange'] = (
    val,
    edge,
  ) => {
    const { onChange, value } = this.props;
    onChange({
      ...value,
      [edge]: val,
    });
  };

  private handlePaddingBlur: PaddingOptionsFieldProps['onBlur'] = (
    val,
    edge,
  ) => {
    const { onBlur, value } = this.props;
    onBlur({
      ...value,
      [edge]: val,
    });
  };

  public render() {
    const { disabled, value } = this.props;
    return (
      <div>
        <PaddingOptionsField
          label="Padding Top"
          value={value.top}
          disabled={disabled}
          onChange={this.handlePaddingChange}
          onBlur={this.handlePaddingBlur}
          args="top"
        />
        <PaddingOptionsField
          label="Padding Left"
          value={value.left}
          disabled={disabled}
          onChange={this.handlePaddingChange}
          onBlur={this.handlePaddingBlur}
          args="left"
        />
        <PaddingOptionsField
          label="Padding Right"
          value={value.right}
          disabled={disabled}
          onChange={this.handlePaddingChange}
          onBlur={this.handlePaddingBlur}
          args="right"
        />
      </div>
    );
  }
}

export { IProps as PaddingOptionsProps };
