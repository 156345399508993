import * as React from 'react';

import MailTo, { MailToProps } from 'components/MailTo';
import { MAIL_TO_SUPPORT_LINK } from 'utils/constants';

export interface MailToSupportProps
  extends Pick<MailToProps, 'subject' | 'className' | 'body' | 'render'> {
  children?: React.ReactNode;
  subject: string;
}

const SupportLink: React.SFC<MailToSupportProps> = ({ children, ...props }) => (
  <MailTo {...props} to={MAIL_TO_SUPPORT_LINK}>
    {children}
  </MailTo>
);

SupportLink.defaultProps = {
  children: 'support',
};

export default SupportLink;
