import React from 'react';

import { editorModalPreviewBlock as block } from '../../utils';

interface EditorModalFadeLayerProps {
  visible: boolean;
}

const EditorModalFadeLayer: React.FunctionComponent<EditorModalFadeLayerProps> = props => {
  const { visible } = props;

  // Overlay fade is only necessary for supporting the legacy text overlay modal.
  // The new TextOverlay modal applies the fade on its own.
  if (!visible) {
    return null;
  }

  return <div className={block('overlay-fade')} />;
};

export default EditorModalFadeLayer;
