import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'underscore';

import usePromise from 'hooks/usePromise';
import { showError } from 'redux/modules/notification/actions';
import { goToCreate } from 'redux/modules/router';
import { AddAudioMeta, AudioSourceType } from 'types';
import { createChainedFunction } from 'utils/functions';
import { useAudioSource } from '../AddAudioStep';
import useAudioRegion from '../useAudioRegion';
import {
  UploadAudioStatus,
  UseAudioUploadConfig,
  UseAudioUploadValue,
} from './types';

export default function useAudioUpload({
  onAudioAdded,
  onAudioUploadError,
  onChange = noop,
}: UseAudioUploadConfig): UseAudioUploadValue {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<UploadAudioStatus>();
  const uploadAudioPromise = usePromise<void>();
  const [audioSourceType, setAudioSourceType] = useState<AudioSourceType>(
    'upload',
  );
  const { audioSource, setAudioSource } = useAudioSource();
  const audioRegion = useAudioRegion();
  const onChangeRef = useRef(onChange);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  const handleAudioUploadError = useCallback(
    (err: Error) => {
      if (onAudioUploadError) {
        onAudioUploadError(err);
      } else {
        dispatch(
          showError({
            code: 'ER004',
            message: 'Error uploading audio',
          }),
        );
        dispatch(goToCreate());
      }
    },
    [dispatch, onAudioUploadError],
  );

  const handleAudioAdded = (
    src: string | File,
    type: AudioSourceType,
    meta?: AddAudioMeta,
  ) => {
    setAudioSourceType(type);

    if (src) {
      setStatus('uploading');
      uploadAudioPromise
        .setPromise(onAudioAdded(src, meta))
        .then(() => setStatus('done'))
        .catch(err => {
          setStatus('error');
          handleAudioUploadError(err);
        });
    }
  };

  const reset = () => {
    audioRegion.reset();
    uploadAudioPromise.cancelPromise();
    setStatus(undefined);
    setAudioSource(undefined);
  };

  useEffect(() => {
    onChangeRef.current({
      audioSourceType,
      source: audioSource,
      uploadAudioStatus: status,
    });
  }, [audioSourceType, audioSource, status]);

  const afterUploadTransfer = <A extends any[], R>(fn: (...args: A) => R) => (
    ...args: A
  ) => {
    if (uploadAudioPromise.promise) {
      return uploadAudioPromise.promise.then(() => fn(...args));
    }

    return Promise.resolve(fn(...args));
  };

  return {
    afterUploadTransfer,
    audioRegion,
    audioSourceType,
    reset,
    onAudioAdded: createChainedFunction(handleAudioAdded, setAudioSource),
    source: audioSource,
    uploadAudioStatus: status,
  };
}
