import * as React from 'react';

import Modal, { ModalProps } from 'components/Modal';
import Content from './ConfirmationContent';
import Icon from './ConfirmationIcon';
import Body from './ConfirmationModalBody';
import Title from './ConfirmationTitle';
import { block } from './utils';

type PickedModalProps = Pick<ModalProps, 'show' | 'onExited'>;
type OnClick = () => void;

interface IProps extends PickedModalProps {
  confirmButtonText?: string;
  cancelButtonText?: string;
  onCancel?: OnClick;
  onConfirm?: OnClick;
  title: string;
}

interface IConfirmationModal extends React.FC<IProps> {
  Icon: typeof Icon;
  Title: typeof Title;
  Content: typeof Content;
  Body: typeof Body;
}

const ConfirmationModal: IConfirmationModal = ({
  children,
  confirmButtonText,
  cancelButtonText,
  onCancel,
  onConfirm,
  onExited,
  show,
  title,
}) => (
  <Modal
    className={block()}
    onHide={onCancel}
    show={show}
    backdrop="static"
    title={title}
    onExited={onExited}
  >
    <Body>{children}</Body>
    <Modal.Footer>
      <Modal.FooterButtons fluid>
        <Modal.FooterButton onClick={onCancel}>
          {cancelButtonText}
        </Modal.FooterButton>
        <Modal.FooterButton onClick={onConfirm} theme="submit">
          {confirmButtonText}
        </Modal.FooterButton>
      </Modal.FooterButtons>
    </Modal.Footer>
  </Modal>
);

ConfirmationModal.defaultProps = {
  confirmButtonText: 'ok',
  cancelButtonText: 'Cancel',
};

ConfirmationModal.Icon = Icon;
ConfirmationModal.Content = Content;
ConfirmationModal.Title = Title;
ConfirmationModal.Body = Body;

export default ConfirmationModal;
export { IProps as ConfirmationModalProps };
