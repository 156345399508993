import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { createSelector } from 'reselect';
import PodcastSearch from 'blocks/PodcastSearch';
import useDefaultIntegration, {
  UseDefaultIntegrationConfig,
} from 'blocks/PodcastSearch/useDefaultIntegration';
import useFavoriteIntegration from 'blocks/PodcastSearch/useFavoriteIntegration';
import useRssIntegration from 'blocks/PodcastSearch/useRssIntegration';
import Tabs, { TabsProps } from 'components/Tabs';
import SampleAudioLibrary from 'containers/SampleAudioLibrary';
import { audioSelectorMethodBlacklist } from 'redux/modules/display-pref';
import { modalParamsSelector } from 'redux/modules/modal/selectors';
import { AddAudioMeta, FileSource } from 'types';
import { ApplicationError } from 'utils/ApplicationError';
import { omitUndefined } from 'utils/collections';
import AddAudioModalDurationUpsell from './AddAudioModalDurationUpsell';
import AudioUploadTab from './AudioUploadTab';
import { AUDIO_SELECTOR_TO_TAB_KEY, block } from './utils';

interface Props extends Pick<TabsProps, 'activeTabKey' | 'onTabSelect'> {
  statusMessage?: string;
  onDropAccepted?: (
    file: File | string,
    source: FileSource,
    metadata?: AddAudioMeta,
    fileName?: string,
  ) => void;
  onUploadError?: (
    error: ApplicationError,
    file: File,
    isPodcast?: boolean,
  ) => void;
}

const tabKeysSelector = createSelector(
  audioSelectorMethodBlacklist,
  blacklist => {
    const tabKeyBlacklist = blacklist.map(
      selector => AUDIO_SELECTOR_TO_TAB_KEY[selector],
    );
    const defaultTabKeys = [1, 2, 3];
    return defaultTabKeys.filter(key => !tabKeyBlacklist.includes(key));
  },
);

const NoHeader = () => null;

const SelectAudioTabs: React.FC<Props> = ({
  activeTabKey,
  onDropAccepted,
  onTabSelect,
  onUploadError,
  statusMessage,
}) => {
  const podcastSearchRef = useRef();
  const tabKeys: number[] = useSelector(tabKeysSelector);
  const modalParams = useSelector(modalParamsSelector);

  useEffect(() => {
    if (activeTabKey === 2 && podcastSearchRef.current) {
      (podcastSearchRef.current as any).focus();
    }
  }, [activeTabKey]);

  useEffect(() => {
    if (!tabKeys.includes(activeTabKey as number)) {
      onTabSelect(tabKeys[0]);
    }
  }, [activeTabKey, onTabSelect, tabKeys]);

  const handleAudioAddedFromSearch: UseDefaultIntegrationConfig['onAudioAdded'] = useCallback(
    (source, _, metadata) => {
      onDropAccepted(source, FileSource.PODCAST, metadata);
    },
    [onDropAccepted],
  );

  const tabs = [
    {
      content: (
        <>
          <AddAudioModalDurationUpsell />
          <AudioUploadTab
            onDropAccepted={onDropAccepted}
            onError={onUploadError}
            statusMessage={statusMessage}
            defaultFile={modalParams?.file}
          />
        </>
      ),
      tabKey: 1,
      title: 'Upload',
      titleClassName: block('tab-title'),
    },
    {
      content: (
        <div className={block('podcast-content')}>
          <AddAudioModalDurationUpsell
            containerClassName={block('audio-duration-upsell-container', {
              'podcast-search': true,
            })}
          />
          <PodcastSearch
            className={block('podcast-search')}
            integrations={[
              useDefaultIntegration({
                onAudioAdded: handleAudioAddedFromSearch,
                priority: 2,
              }),
              useRssIntegration({
                episodeExplorerEnabled: true,
                onAudioAdded: handleAudioAddedFromSearch,
                priority: 1,
              }),
              useFavoriteIntegration({
                onAudioAdded: handleAudioAddedFromSearch,
                priority: 0,
              }),
            ]}
            ref={podcastSearchRef}
            usePlanEpisodeMaxDuration
          />
        </div>
      ),
      tabKey: 2,
      title: <>Podcasts</>,
      titleClassName: block('tab-title'),
    },
    {
      content: <SampleAudioLibrary />,
      tabKey: 3,
      title: 'Audio Library',
      titleClassName: block('tab-title'),
    },
  ].filter(tab => tabKeys.includes(tab.tabKey));

  return (
    <Tabs
      activeTabKey={activeTabKey}
      id="selected-audio-tabs"
      onTabSelect={onTabSelect}
      overrides={omitUndefined({
        header: tabs.length === 1 ? NoHeader : undefined,
      })}
      tabs={tabs}
    />
  );
};

export default SelectAudioTabs;
