import cn from 'classnames';
import * as React from 'react';

import bem from 'utils/bem';

export interface InfoboxProps {
  children?: React.ReactNode;
  className?: string;
  icon: React.ReactElement<{ className: string }>;
  theme?: 'gradient' | 'pink' | 'blue' | 'purple';
}

const block = bem('infobox');

const Infobox: React.FC<InfoboxProps> = ({
  children,
  className,
  icon,
  theme,
}) => (
  <div className={cn(block({ [theme]: !!theme }), className)}>
    <div className={block('badge')}>
      {React.cloneElement(icon, {
        className: cn(block('icon'), icon.props.className),
      })}
    </div>
    {children}
  </div>
);

export default Infobox;
