import cn from 'classnames';
import * as React from 'react';
import Button from './SearchbarButton';
import Input from './SearchbarInput';
import { block } from './utils';

export interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
  className?: string;
  compact?: boolean;
  light?: boolean;
}

interface ISearchbar extends React.FC<Props> {
  Button: typeof Button;
  Input: typeof Input;
}

const Searchbar: ISearchbar = props => {
  const { className, children, light, compact, onSubmit } = props;

  return (
    <form
      className={cn(block({ compact, light }), className)}
      onSubmit={event => {
        event.preventDefault();
        onSubmit?.(event);
      }}
    >
      {children}
    </form>
  );
};

Searchbar.Input = Input;
Searchbar.Button = Button;
Searchbar.displayName = 'Searchbar';

export default Searchbar;
