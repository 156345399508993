import React from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import AICaptionsUpsellModalContents from './AICaptionsUpsellModalContents';

export interface AICaptionsUpsellModalProps {
  show?: boolean;
  onPlanUpgrade: () => void;
}

const AICaptionsUpsellModal: React.FC<AICaptionsUpsellModalProps> = ({
  show,
  onPlanUpgrade,
}) => (
  <ConnectedModal
    show={show}
    name="AICaptionsUpsell"
    title="Your personal copywriter"
  >
    <AICaptionsUpsellModalContents onPlanUpgrade={onPlanUpgrade} />
  </ConnectedModal>
);

export default AICaptionsUpsellModal;
