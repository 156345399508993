import { Button, Heading, Spacer } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BetaBadge from 'components/BetaBadge';
import { Tier } from 'redux/middleware/api/plan-service';
import { onUnlockThisFeature } from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal';
import { isFreeSelector } from 'redux/modules/pricing';
import { block } from './utils';

export interface AIGenerationInitialContentsProps {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  /**
   * When set to true this will show the `Unlock this feature` button for free users.
   */
  showUnlockThisFeature?: boolean;
  onUnlockFeatureClick?: () => void;
  // When set to true this will show the beta badge
  beta?: boolean;
}

const AIGenerationInitialContents: React.FC<AIGenerationInitialContentsProps> = ({
  icon,
  title,
  subtitle,
  showUnlockThisFeature = false,
  onUnlockFeatureClick,
  beta = false,
}) => {
  const isFree = useSelector(isFreeSelector);

  const dispatch = useDispatch();

  const handleUpgrade = useCallback(() => {
    onUnlockFeatureClick?.();

    dispatch(onUnlockThisFeature('TextToVideo'));

    dispatch(
      replaceModal({
        name: 'AddBillingCycle',
        params: {
          tier: Tier.PRO,
          defaultSubscriptionPeriod: 'yearly',
          disableSuccessRedirect: true,
        },
      }),
    );
  }, [dispatch, onUnlockFeatureClick]);

  return (
    <Spacer
      orientation="vertical"
      align="center"
      justify="center"
      space={2}
      className={block('ai-generated-contents')}
    >
      {icon && icon}

      {title && (
        <Spacer align="center">
          <Heading level={1}>{title}</Heading>
          {beta && <BetaBadge />}
        </Spacer>
      )}

      {subtitle && <Heading level={2}>{subtitle}</Heading>}

      {showUnlockThisFeature && isFree && (
        <Button
          variant="special"
          className={block('ai-generated-unlock-button')}
          onPress={handleUpgrade}
        >
          unlock this feature
        </Button>
      )}
    </Spacer>
  );
};

export default AIGenerationInitialContents;
