import * as React from 'react';
import { InfinitySolid } from 'components/icons';
import { block } from './utils';

interface Props {
  value: number;
}

const NumberValue: React.FC<Props> = props => {
  const { value, children } = props;
  const isFinite = Number.isFinite(value);

  return (
    <>
      {isFinite ? (
        <span className={block('number', { infinity: !isFinite })}>
          {value}
        </span>
      ) : (
        <InfinitySolid className={block('number', { infinity: !isFinite })} />
      )}
      {children}
    </>
  );
};

export default NumberValue;
