import classNames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import _, { isNumber } from 'underscore';
import { DestinationPreset } from 'blocks/AudiogramDestinations/destinationPresets/types';
import LoadingOverlay from 'components/LoadingOverlay';
import TimelineZoomSlider from 'components/TimelineZoomSlider';
import WaveSurfer, {
  ClipRegion,
  Minimap,
  WaveSurferContainer,
  WaveSurferProps,
} from 'components/WaveSurfer';
import MultiCanvas from 'components/WaveSurfer/MultiCanvas';
import TimelinePlugin from 'components/WaveSurfer/TimelinePlugin';
import { RegionObject, WaveSurferFunctions } from 'components/WaveSurfer/types';
import WheelScrollPlugin from 'components/WaveSurfer/WheelScrollPlugin';
import useKeyBind from 'hooks/useKeyBind';
import useStaticCallback from 'hooks/useStaticCallback';
import { showError } from 'redux/modules/notification';
import { AudioClipOffsets } from 'types';
import { AUDIO_CLIPPER_WAVESURFER_PIXEL_RATIO } from 'utils/constants';
import { clamp } from 'utils/numbers';
import { RenderProps } from './AudioClipperContainer';
import ClippingForm from './ClippingForm';
import PlaybackControls from './PlaybackControls';
import { ClipperContainerMode, SocialPresetOption } from './types';
import { block, isPresetBlocked, SOCIAL_PRESET_POPOVER_OPTIONS } from './utils';
import WaveformLoading from './WaveformLoading';

export interface RenderOverlayProps {
  audioLoading: boolean;
}

export type RenderOverlay = (props: RenderOverlayProps) => JSX.Element;

interface IProps extends RenderProps {
  audioPeaks?: WaveSurferProps['peaks'];
  backend: 'MediaElement' | 'WebAudio';
  className?: string;
  containerMode?: ClipperContainerMode;
  controlsClassName?: string;
  disabled?: boolean;
  formClassName?: string;
  initialPresetKey?: DestinationPreset['key'];
  renderOverlay?: RenderOverlay;
  src: string | Blob;
  minDurationMillis: number;
  maxDurationMillis: number;
  onError: (message: string) => void;
  captionsControl?: React.ReactNode;
}

const defaultRenderOverlay = ({ audioLoading }) =>
  audioLoading && <LoadingOverlay title="Loading Audio" small />;

export interface IAudioClipperInner {
  clip: AudioClipOffsets;
}

const AudioClipperInner = forwardRef<IAudioClipperInner, IProps>(
  (props, ref) => {
    const {
      audioPeaks,
      backend = 'MediaElement',
      className,
      controlsClassName,
      data,
      disabled,
      formClassName,
      initialPresetKey,
      containerMode = 'default',
      captionsControl,
      pause,
      play,
      playSelection,
      renderOverlay = defaultRenderOverlay,
      setRegionDuration,
      src,
      stop,
      zoom,
      minDurationMillis,
      maxDurationMillis,
      onPresetChange,
      setAudioLoading,
      setDuration,
      setRegion,
      setWaveSurferReady,
      setPlaybackPos,
      setBuffer,
      onError,
    } = props;

    const [selectedPreset, setSelectedPreset] = React.useState<
      DestinationPreset | undefined
    >(SOCIAL_PRESET_POPOVER_OPTIONS[initialPresetKey]);

    const { region, durationMillis } = data;
    const isBlockingPresetActive = isPresetBlocked(selectedPreset);
    const clipRegionMaxDurationMillis = isBlockingPresetActive
      ? selectedPreset?.durationMs
      : maxDurationMillis;

    const dispatch = useDispatch();

    const wavesurferRef = useRef<WaveSurferFunctions>();
    const mountedRef = useRef(false);
    const plugins = useMemo(
      () => [TimelinePlugin.create({}) as any, WheelScrollPlugin.create({})],
      [],
    );

    useEffect(() => {
      if (mountedRef.current) {
        setAudioLoading(true);
        setDuration(undefined);
        setRegion({});
        setWaveSurferReady(false);
      } else {
        mountedRef.current = true;
      }
    }, [setAudioLoading, setDuration, setRegion, setWaveSurferReady, src]);

    const handleClearSelectedPreset = useCallback((): void => {
      setSelectedPreset(undefined);
      onPresetChange?.(undefined);
    }, [onPresetChange]);

    const handleSelectPreset = useCallback(
      (preset: SocialPresetOption): void => {
        setSelectedPreset(preset);
        onPresetChange?.(preset);
      },
      [onPresetChange, setSelectedPreset],
    );

    const handleSpaceKeyDown = useStaticCallback(() => {
      // do not interfer with button's default handler.
      if (
        document.activeElement?.tagName?.toLowerCase() === 'button' ||
        disabled
      ) {
        return;
      }

      if (data.playing) {
        pause();
      } else {
        play();
      }
    });

    useKeyBind(
      useMemo(
        () => ({
          bindings: {
            ' ': {
              onKeyDown: handleSpaceKeyDown,
            },
          },
          useCapture: true,
        }),
        [handleSpaceKeyDown],
      ),
    );

    const handleWavesurferAudioprocess = positionSeconds => {
      setPlaybackPos(positionSeconds * 1000);
    };

    const handleWavesurferReady = () => {
      setWaveSurferReady(true);
      wavesurferRef.current.withWaveSurfer(wavesurfer => {
        setBuffer((wavesurfer.backend as any).buffer);
        setDuration(wavesurfer.getDuration() * 1000);
        setAudioLoading(false);
      });
    };

    const handleWavesurferSeek = progress => {
      const { seek } = props;
      seek(progress);
    };

    const handleRegionUpdate = useStaticCallback(
      ({ start, end }: RegionObject) => {
        if (!disabled) {
          setRegion({
            endMillis: end * 1000,
            startMillis: start * 1000,
          });
        }
      },
    );

    const handleDurationBlur = (millis: number) => {
      setRegionDuration(millis);
    };

    const handleMaxLengthExceeded = React.useCallback(() => {
      if (isBlockingPresetActive) {
        dispatch(
          showError({
            title:
              'To select a longer clip just remove the duration limit at the bottom of this screen.',
          }),
        );
      }
    }, [dispatch, isBlockingPresetActive]);

    const handleEndTimeBlur = (millis: number) => {
      const clampedEndMillis = clamp(millis, minDurationMillis, durationMillis);
      const startMillis = Math.max(
        Math.min(region.startMillis, clampedEndMillis - minDurationMillis),
        clampedEndMillis - clipRegionMaxDurationMillis,
        0,
      );
      const endMillis = Math.max(
        clampedEndMillis,
        startMillis + minDurationMillis,
      );
      setRegion({ startMillis, endMillis });
    };

    const handleStartTimeBlur = (millis: number) => {
      const clampedStartMillis = clamp(
        millis,
        0,
        durationMillis - minDurationMillis,
      );
      const endMillis = Math.min(
        Math.max(region.endMillis, clampedStartMillis + minDurationMillis),
        clampedStartMillis + clipRegionMaxDurationMillis,
        durationMillis,
      );
      const startMillis = Math.min(
        clampedStartMillis,
        endMillis - minDurationMillis,
      );
      setRegion({ startMillis, endMillis });
    };

    useImperativeHandle(ref, () => ({
      get clip(): AudioClipOffsets {
        return {
          endMillis: data.region.endMillis,
          originalDurationMillis: data.durationMillis,
          startMillis: data.region.startMillis,
        };
      },
    }));

    const startDisabled = !!data.endErrorMessage || !!data.durationErrorMessage;
    const endDisabled = !!data.startErrorMessage || !!data.durationErrorMessage;
    const durationDisabled = !!data.startErrorMessage || !!data.endErrorMessage;

    return (
      <WaveSurfer
        peaks={audioPeaks}
        src={src}
        onAudioprocess={handleWavesurferAudioprocess}
        onReady={handleWavesurferReady}
        onSeek={handleWavesurferSeek}
        onError={onError}
        options={{
          backend,
          cursorColor: '#FFF',
          cursorWidth: 2,
          interact: !disabled,
          progressColor: '#f3f5f8',
          responsive: true,
          waveColor: '#f3f5f8',
          pixelRatio: AUDIO_CLIPPER_WAVESURFER_PIXEL_RATIO,
          plugins,
          renderer: MultiCanvas as any,
        }}
        playing={data.playing}
        position={
          _.isUndefined(data.seekToMillis) ? null : data.seekToMillis / 1000
        }
        ref={wavesurferRef}
        volume={data.volume}
        zoom={data.zoom}
        onFinish={stop}
      >
        <div
          id="audio-clipper-inner-wrapper"
          className={classNames(
            'audio-clipper',
            'audio-clipper--default',
            className,
          )}
        >
          {renderOverlay({ audioLoading: data.audioLoading })}
          <div className={block('overview')}>
            <Minimap className={block('minimap')} />
            <TimelineZoomSlider
              className={block('zoom-slider')}
              minusClassName={block('zoom-slider-button')}
              onSlideStop={zoom}
              onZoomInClick={() => zoom(Math.min(data.zoom + 5, 100))}
              onZoomOutClick={() => zoom(Math.max(data.zoom - 5, 1))}
              plusClassName={block('zoom-slider-button')}
              sliderClassName={block('slider')}
              value={data.zoom}
            />
          </div>
          <div className={block('wavesurfer')}>
            {src && (
              <>
                <WaveformLoading
                  show={
                    data.waveSurferReady &&
                    backend === 'MediaElement' &&
                    (!audioPeaks || audioPeaks.length === 0)
                  }
                />
                <WaveSurferContainer className={block('wavesurfer-container')}>
                  {data.region.startMillis !== undefined &&
                    data.region.endMillis !== undefined && (
                      <ClipRegion
                        color="rgba(40, 182, 239, 0.5)"
                        end={data.region.endMillis / 1000}
                        start={data.region.startMillis / 1000}
                        minLength={
                          isNumber(minDurationMillis)
                            ? Math.floor(minDurationMillis / 1000)
                            : undefined
                        }
                        maxLength={
                          isNumber(clipRegionMaxDurationMillis)
                            ? Math.floor(clipRegionMaxDurationMillis / 1000)
                            : undefined
                        }
                        onMaxLengthExceeded={handleMaxLengthExceeded}
                        onRegionUpdated={handleRegionUpdate}
                      />
                    )}
                </WaveSurferContainer>
              </>
            )}
          </div>
          <div className={block('bottom')}>
            <PlaybackControls
              className={controlsClassName}
              disabled={disabled}
              onPause={pause}
              onPlay={play}
              onPlaySelection={playSelection}
              positionMillis={data.positionMillis}
              playing={data.playing}
              durationMillis={data.durationMillis}
            />
            <ClippingForm
              className={formClassName}
              containerMode={containerMode}
              disabled={disabled}
              durationDisabled={durationDisabled}
              endMillis={data.region.endMillis}
              endDisabled={endDisabled}
              maxMillis={data.durationMillis}
              onDurationSubmit={handleDurationBlur}
              onEndTimeSubmit={handleEndTimeBlur}
              onStartTimeSubmit={handleStartTimeBlur}
              onDurationChange={setRegionDuration}
              startDisabled={startDisabled}
              startMillis={data.region.startMillis}
              minDurationMillis={minDurationMillis}
              maxDurationMillis={clipRegionMaxDurationMillis}
              onClearSelectedPreset={handleClearSelectedPreset}
              onStartPreview={playSelection}
              onEndPreview={playSelection}
              onPickPreset={handleSelectPreset}
              selectedPreset={selectedPreset}
            />
            {captionsControl && (
              <div className={block('captions')}>{captionsControl}</div>
            )}
          </div>
        </div>
      </WaveSurfer>
    );
  },
);

export { IProps as InnerProps };
export default AudioClipperInner;
