import React, { useCallback } from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import QualityReductionAlertModalContents from './QualityReductionAlertModalContents';
import { block } from './utils';

export interface QualityReductionAlertModalProps {}

const QualityReductionAlertModal: React.FC<QualityReductionAlertModalProps> = () => {
  const { onHide } = useConnectedModal('QualityReductionAlert');

  const handleHide = useCallback((): void => {
    // If closing the modal without clicking one of the footer
    // buttons, continue creation with the current video.
    onHide(true);
  }, [onHide]);

  return (
    <ConnectedModal
      name="QualityReductionAlert"
      title="Quality Reduction Alert"
      className={block()}
      onHide={handleHide}
    >
      <QualityReductionAlertModalContents />
    </ConnectedModal>
  );
};

export default QualityReductionAlertModal;
