import { handle } from './service';
import { ACTION_KEY, ServiceMethod } from './types';

export * from './types';
export * from './actions';
export default {
  handle,
  ActionKey: ACTION_KEY,
  Method: ServiceMethod,
};
