import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { resendVerificationEmail } from 'redux/modules/auth/actions';
import { hideModal, replaceModal } from 'redux/modules/modal';
import { showNotification, showSuccess } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { getEmailSettingsPath } from 'utils/routes';

export default () => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  const onChangeEmail = () => {
    history.push(getEmailSettingsPath());
    dispatch(replaceModal({ name: 'UpdateEmail' }));
  };
  const onResendEmail = async (email: string) => {
    try {
      dispatch(hideModal());
      await dispatch(resendVerificationEmail());
      dispatch(
        showSuccess(
          `We’ve emailed ${email}`,
          10,
          undefined,
          'Verification email sent',
        ),
      );
    } catch (error) {
      dispatch(
        showNotification({
          message: 'Please try again or {{link}} so we can help',
          code: 'ER013',
          level: 'error',
          dismissAfterSec: 10,
          type: 'help',
          title: "Sorry, we couldn't send your invites",
          actionLabel: 'contact us',
        }),
      );
    }
  };

  return { onChangeEmail, onResendEmail };
};
