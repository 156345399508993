import { Select } from '@sparemin/blockhead';
import React from 'react';

import FontFamilyProIndicator from '../FontFamilyProIndicator';

import { Font } from './types';
import useFonts from './useFonts';
import { block } from './utils';

export interface FontFamilySelectorProps {
  disabled?: boolean;
  fonts?: Font[];
  onChange?: (font: Font) => void;
  value?: string;
}

const FontFamilySelector: React.FunctionComponent<FontFamilySelectorProps> = props => {
  const { fonts, onChange, value } = props;

  const { userFonts, allFonts, newRecentFonts: recentFonts } = useFonts(fonts);

  const handleFontClick = React.useCallback(
    (familyName: string) => {
      const nextFont = fonts.find(font => font.familyName === familyName);
      if (nextFont) {
        onChange(nextFont);
      }
    },
    [fonts, onChange],
  );

  const fontOptions = React.useMemo(
    () =>
      [
        {
          id: 'recent_fonts',
          name: 'Recent Fonts',
          textValue: 'Recent Fonts',
          items: recentFonts,
        },
        {
          id: 'user_fonts',
          name: 'My Fonts',
          textValue: 'My Fonts',
          items: userFonts,
        },
        {
          id: 'all_fonts',
          name: 'All Fonts',
          textValue: 'All Fonts',
          items: allFonts,
        },
      ].filter(fontGroup => fontGroup.items.length),
    [allFonts, recentFonts, userFonts],
  );

  return (
    <div className={block('container')}>
      <Select
        label="Font"
        fluid
        footer={
          <FontFamilyProIndicator
            contentClassName={block('footer-container')}
          />
        }
        items={fontOptions}
        onSelectionChange={handleFontClick}
        selectedKey={value}
      />
    </div>
  );
};

export default FontFamilySelector;
