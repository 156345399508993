import * as React from 'react';

import { ProBadge } from 'components/icons';
import Tooltip from 'components/Tooltip';
import TemplateSelectorTile from './TemplateSelectorTile';
import { TemplateSelectorTileProps } from './types';
import { block } from './utils';

const ProGatedTemplateTile: React.FC<TemplateSelectorTileProps> = ({
  aspectRatioName,
  background,
  children,
  className,
  displayName,
  id,
  onMouseOut,
  onMouseOver,
  onClick,
}) => {
  return (
    <Tooltip
      content={
        <>
          <b>PRO template:</b> upgrade to unlock it
        </>
      }
      placement="top"
      className={block('tooltip')}
    >
      <TemplateSelectorTile
        {...{
          background,
          onMouseOver,
          onMouseOut,
          className,
          onClick,
          id,
          aspectRatioName,
        }}
        permanent
        displayName={
          <>
            <ProBadge className={block('badge')} /> {displayName}
          </>
        }
      >
        {children}
      </TemplateSelectorTile>
    </Tooltip>
  );
};

export default ProGatedTemplateTile;
