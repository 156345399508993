import { createSelector } from 'reselect';
import { socialSharesSelector } from 'redux/modules/entities';
import { stateSelector } from '../selectors';

export const twitterSelector = createSelector(stateSelector, state =>
  state.get('twitter'),
);

export const twitterUsernameSelector = createSelector(
  twitterSelector,
  twitter => twitter.username,
);

export const twitterAvatarSrcSelector = createSelector(
  twitterSelector,
  twitter => twitter.profileImageUrl,
);

export const twitterSocialShareIdSelector = createSelector(
  twitterSelector,
  twitter => twitter.socialShareId,
);

export const twitterProviderUserIdSelector = createSelector(
  twitterSelector,
  twitter => twitter.providerUserId,
);

export const twitterUserDataSelector = createSelector(twitterSelector, tt => ({
  accessToken: tt.accessToken,
  idToken: tt.idToken,
  providerUserId: tt.providerUserId,
  accessTokenSecret: tt.accessTokenSecret,
}));

export const twitterIsLoading = createSelector(
  twitterSelector,
  twitter => !twitter.username,
);

export const twitterSocialSharesSelector = createSelector(
  twitterSocialShareIdSelector,
  socialSharesSelector,
  (id, shares) =>
    !id || !shares || shares.size === 0
      ? undefined
      : shares.get(String(id))?.toJS(),
);
