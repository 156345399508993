import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import FullEpisodesExportModalContent from './FullEpisodesExportModalContent';

const FullEpisodesExportModal = () => (
  <ConnectedModal name="FullEpisodesExport" title="pro feature">
    <FullEpisodesExportModalContent />
  </ConnectedModal>
);

export default FullEpisodesExportModal;
