import { Record } from 'immutable';

import { State } from './types';

export const stateFactory = Record<State>(
  {
    data: undefined,
    status: undefined,
  },
  'survey',
);
