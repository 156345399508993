import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { omit } from 'underscore';

import { DefaultMetadata } from 'components/Cropper/types';
import CropperModalBody from 'components/CropperModal/CropperModalBody';
import { ModalFooterButton } from 'components/Modal';
import SlideEditor, { SlideEditorValues } from 'components/SlideEditor';
import SteppedModal from 'components/SteppedModal/SteppedModal';
import useEditAssetModal from 'containers/ConnectedModal/useEditAssetModal';
import { ImageCropperInstance } from 'containers/ImageCropper/ImageCropper';
import useImageProcessor from 'hooks/useImageProcessor';
import usePrevious from 'hooks/usePrevious';
import { saveConfiguration } from 'redux/modules/embed/actions/embed';
import {
  removeSlide,
  updateSlide,
} from 'redux/modules/embed/actions/slideshow';
import {
  addingToMediaTrack,
  addingToTrack,
  clearSelectedTrackElement,
  replaceSelectedTrackElement,
} from 'redux/modules/embed/actions/tracks';
import {
  aspectRatioSelector,
  selectedSlideIdSelector,
  selectedTrackElementSelector,
  slidesSelector,
} from 'redux/modules/embed/selectors';
import { onReplaceAsset } from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal/actions';
import { Dispatch } from 'redux/types';
import { getSlideBlurRadius, getSlidePlacement } from 'utils/embed/slideshow';
import EditSlideEditor, { EditSlideEditorProps } from './EditSlideEditor';

const { useCallback, useEffect, useRef, useState } = React;

export interface IProps {
  className?: string;
  defaultCropMetadata?: DefaultMetadata & { constrained?: boolean };
  defaultImageUrl: string;
  disabled?: boolean;
  onDelete?: () => void;
  onReplace?: () => void;
  onReplaceHide?: () => void;
  onSave?: (opts: SlideEditorValues) => void;
}

const slideSelector = createSelector(
  [selectedSlideIdSelector, slidesSelector],
  (slideId, slides) => slides?.get(slideId),
);

const defaultCropMetadataSelector = createSelector(slideSelector, slide => {
  if (!slide) return undefined;

  const cropData = slide.get('cropData');
  if (!cropData) return undefined;

  return omit(cropData.toJS(), 'constrained');
});

const defaultPlacementSelector = createSelector(slideSelector, slide =>
  getSlidePlacement(slide),
);

const defaultStateSelector = createSelector(
  slideSelector,
  defaultPlacementSelector,
  (slide, placement) =>
    slide && {
      placement,
      constrain: slide.getIn(['cropData', 'constrained'], true),
      effect: slide.get('imageEffect'),
      endMillis: slide.get('endMilli'),
      startMillis: slide.get('startMilli'),
      transition: slide.get('entryTransition'),
      blurRadius: getSlideBlurRadius(slide),
    },
);

const imageUrlSelector = createSelector(slideSelector, slide =>
  slide?.get('imageUrl', slide?.get('originalImageUrl')),
);

const originalImageUrlSelector = createSelector(
  slideSelector,
  slide => slide?.get('originalImageUrl') || slide?.get('imageUrl'),
);

export interface EditSlideModalProps {}

const EditSlideModal: React.FC<EditSlideModalProps> = () => {
  const dispatch = useDispatch<Dispatch>();
  const aspectRatio = useSelector(aspectRatioSelector);
  const trackId = useSelector(selectedTrackElementSelector)?.get('trackId');
  const defaultCropMetadata = useSelector(defaultCropMetadataSelector);
  const defaultState = useSelector(defaultStateSelector);
  const defaultImageUrl = useSelector(imageUrlSelector);
  const originalImageUrl = useSelector(originalImageUrlSelector);

  const { originalFile } = useImageProcessor(originalImageUrl, aspectRatio);
  const { show, onHide, onExited } = useEditAssetModal('EditSlideModal');
  const [croppedImageSrc, setCroppedImageSrc] = useState<string | Blob>(
    defaultImageUrl,
  );
  const [cropMetadata, setCropMetadata] = useState<
    DefaultMetadata & { constrained?: boolean }
  >(defaultCropMetadata);
  const [slideEditorValues, setSlideEditorValues] = useState<
    EditSlideEditorProps['defaultState']
  >(defaultState);

  const modalRef = useRef<SteppedModal>();
  const editorRef = useRef<SlideEditor>();
  const cropperRef = useRef<ImageCropperInstance>();

  const prevShow = usePrevious(show);

  useEffect(() => {
    if (!prevShow && show) {
      setCroppedImageSrc(defaultImageUrl);
      setCropMetadata(defaultCropMetadata);
      setSlideEditorValues(defaultState);
    } else if (prevShow && !show) {
      setCroppedImageSrc(undefined);
      setSlideEditorValues(undefined);
    }
  }, [defaultCropMetadata, defaultImageUrl, defaultState, prevShow, show]);

  const handleCropperOpen = useCallback(() => {
    setSlideEditorValues(editorRef.current.getValues());
    modalRef.current.stepHistory.push('crop');
  }, []);

  const handleReplace = useCallback(() => {
    dispatch(onReplaceAsset('image'));
    dispatch(addingToMediaTrack());
    dispatch(replaceSelectedTrackElement());
    onHide();
    dispatch(pushModal({ name: 'AddMediaModal' }));
    dispatch(addingToTrack(trackId));
  }, [dispatch, onHide, trackId]);

  const handleDelete = useCallback(() => {
    dispatch((_, getState) => {
      const slideId = selectedSlideIdSelector(getState());
      onHide();
      dispatch(removeSlide(slideId));
    });
  }, [dispatch, onHide]);

  const handleSave = useCallback(async () => {
    const {
      placement,
      blurRadius,
      ...values
    } = await editorRef.current.getValues();
    dispatch(async (_, getState) => {
      const slideId = selectedSlideIdSelector(getState());
      onHide();
      await dispatch(
        updateSlide(slideId, {
          ...values,
          blurRadius: blurRadius?.toString(),
          cropData: cropMetadata,
          croppedImage: croppedImageSrc,
          placement: {
            height: placement.height.toString(),
            left: placement.left.toString(),
            top: placement.top.toString(),
            width: placement.width.toString(),
          },
        }),
      );
      dispatch(saveConfiguration());
      dispatch(clearSelectedTrackElement());
    });
  }, [cropMetadata, croppedImageSrc, dispatch, onHide]);

  const handleCrop = async () => {
    const { croppedImage, cropData } = await cropperRef.current.getValues();
    setCroppedImageSrc(croppedImage);
    setCropMetadata(cropData);
    modalRef.current.stepHistory.replace('place');
  };

  return (
    <SteppedModal
      baseClassName="edit-slide-modal"
      defaultStep="place"
      onExited={onExited}
      onHide={onHide}
      ref={modalRef}
      show={show}
      steps={[
        {
          component: (
            <EditSlideEditor
              className="edit-slide-modal__editor"
              defaultState={slideEditorValues}
              onCropperOpen={handleCropperOpen}
              ref={editorRef}
              src={croppedImageSrc}
            />
          ),
          id: 'place',
          renderFooterButtons: ({ cancel, submit }) => [
            <ModalFooterButton {...cancel} key="cancel" onClick={onHide}>
              cancel
            </ModalFooterButton>,
            <ModalFooterButton key="replace" onClick={handleReplace}>
              Replace
            </ModalFooterButton>,
            <ModalFooterButton
              key="delete"
              onClick={handleDelete}
              theme="delete"
            >
              Delete
            </ModalFooterButton>,
            <ModalFooterButton
              {...submit}
              key="save"
              onClick={handleSave}
              theme="submit"
            >
              save
            </ModalFooterButton>,
          ],
        },
        {
          component: (
            <CropperModalBody
              defaultMetadata={cropMetadata}
              defaultConstrainImage={cropMetadata?.constrained}
              fileType={originalFile?.type}
              imgSrc={originalImageUrl}
              ref={cropperRef}
            />
          ),
          id: 'crop',
          onSubmit: handleCrop,
          submitButtonLabel: 'Crop',
        },
      ]}
      title="Image Properties"
    />
  );
};

export default EditSlideModal;
