import dayjs from 'dayjs';
import * as Immutable from 'immutable';
import _ from 'underscore';

import embedUtils from '../../../../utils/embed';
import { uploadImage } from '../../common/actions';
import * as types from '../action-types';
import * as embedSelectors from '../selectors';
import { createEmbedConfigurationFromState, setEmbedDuration } from './embed';
import { addToTrack } from './tracks';

const updateSlideshowAction = (slides, slidesById) => ({
  type: types.EMBED_SLIDESHOW_UPDATE,
  payload: { slides, slidesById },
});

const addToSlideshow = newSlide => (dispatch, getState) => {
  const slideIds = embedSelectors.embedSlideIdsSelector(getState());
  const slides = embedSelectors.slidesSelector(getState());

  const {
    slides: updatedSlides,
    slidesById: updatedSlidesById,
  } = embedUtils.addToSlideshow(Immutable.fromJS(newSlide), slideIds, slides);

  return dispatch(updateSlideshowAction(updatedSlides, updatedSlidesById));
};

const removeFromSlideshow = slideId => dispatch =>
  dispatch({
    type: types.EMBED_SLIDE_DELETE,
    payload: { slideId },
  });

/**
 *
 * @param {import('./types').AddSlideOptions} options
 * @returns
 */
export const addSlide = ({
  cropData,
  croppedImage,
  effect,
  endMillis,
  imageMetadata,
  originalImage,
  placement,
  startMillis,
  sourceImageOrigin,
  transition,
  blurRadius,
}) => (dispatch, getState) => {
  dispatch({ type: types.EMBED_SLIDE_ADD_REQUEST });

  const aspectRatio = embedSelectors.aspectRatioDimensionsSelector(getState());

  const trackId =
    embedSelectors.selectAddingToTrack(getState()) ||
    embedSelectors.firstMediaTrackIdSelector(getState());

  const futNewSlide = dispatch(uploadImage(originalImage))
    .then(({ url: originalUrl }) => {
      if (croppedImage === originalImage) {
        return [originalUrl, originalUrl];
      }
      return dispatch(uploadImage(croppedImage)).then(({ url: croppedUrl }) => {
        return [originalUrl, croppedUrl];
      });
    })
    .then(([originalImgUrl, imgUrl]) =>
      embedUtils.createSlide(
        imgUrl,
        transition,
        startMillis,
        endMillis,
        effect,
        imageMetadata,
        aspectRatio,
        originalImgUrl,
        cropData,
        placement,
        sourceImageOrigin,
        blurRadius,
      ),
    )
    .then(slide => ({
      ...slide,
      updatedAt: dayjs().valueOf(),
    }));

  return futNewSlide
    .then(newSlide => {
      dispatch(addToSlideshow(newSlide));
      dispatch(addToTrack(trackId, newSlide.id, 'image'));
    })
    .then(() => dispatch(setEmbedDuration(true)))
    .then(() => dispatch(createEmbedConfigurationFromState()))
    .then(() =>
      dispatch({
        type: types.EMBED_SLIDE_ADD_SUCCESS,
      }),
    )
    .catch(error => {
      dispatch({
        ...error,
        type: types.EMBED_SLIDE_ADD_FAILURE,
      });
    });
};

const doUpdateSlideOrder = (dispatch, getState) => {
  const slidesById = embedSelectors.slidesSelector(getState());
  const orderedSlideIds = embedUtils.sortSlides(slidesById);

  dispatch(updateSlideshowAction(orderedSlideIds, slidesById));
};

const debouncedDoUpdateSlideOrder = _.debounce(doUpdateSlideOrder, 250);

/**
 * sorts all of the slides ascending by startMillis. even "a lot" of slides for a video shouldn't
 * cause sorting to become a bottleneck, but in the context of timeline dragging, it
 * might slow things down considerably if we have to sort the slides on each drag event.
 *
 * passing immediate = true will sort the slides and update now, while immediate = false will
 * call the debounced version of the function.
 */
const updateSlideOrder = (immediate = false) =>
  immediate ? doUpdateSlideOrder : debouncedDoUpdateSlideOrder;

/**
 *
 * @param {string} slideId
 * @param {import('./types').UpdateSlideOptions} options
 * @returns
 */
export const updateSlide = (
  slideId,
  {
    croppedImage,
    startMillis,
    endMillis,
    effect,
    transition,
    cropData,
    placement,
    blurRadius,
  },
) => async (dispatch, getState) => {
  const slidesById = embedSelectors.slidesSelector(getState());
  const slide = slidesById.get(slideId);

  // only update the image if the crop has changed
  const hasImageChanged =
    cropData &&
    !Immutable.fromJS(cropData.crop).equals(slide.getIn(['cropData', 'crop']));

  const url = !hasImageChanged
    ? await Promise.resolve(slide.get('imageUrl'))
    : await (async () => {
        dispatch({ type: types.EMBED_SLIDE_EDIT_IMAGE_REQUEST });
        try {
          const { url: uploadedUrl } = await dispatch(
            // uploadSlideImage(croppedImageBlob),
            uploadImage(croppedImage),
          );
          dispatch({ type: types.EMBED_SLIDE_EDIT_IMAGE_SUCCESS });
          return uploadedUrl;
        } catch (err) {
          dispatch({ type: types.EMBED_SLIDE_EDIT_IMAGE_FAILURE });
          throw err;
        }
      })();

  dispatch({
    type: types.EMBED_SLIDE_UPDATE,
    payload: {
      slideId,
      slide: {
        cropData,
        placement,
        endMilli: endMillis,
        entryTransition: transition,
        imageEffect: effect,
        imageUrl: url,
        startMilli: startMillis,
        blurRadius,
      },
    },
  });
  dispatch(setEmbedDuration(true));
  dispatch(updateSlideOrder());
};

export const removeSlide = slideId => dispatch => {
  dispatch({ type: types.EMBED_SLIDE_REMOVE_REQUEST });
  dispatch(removeFromSlideshow(slideId));
  dispatch(setEmbedDuration(true));

  return dispatch(createEmbedConfigurationFromState())
    .then(() =>
      dispatch({
        type: types.EMBED_SLIDE_REMOVE_SUCCESS,
      }),
    )
    .catch(error =>
      dispatch({
        ...error,
        type: types.EMBED_SLIDE_REMOVE_FAILURE,
      }),
    );
};

export const setEmbedSlides = slides => dispatch =>
  dispatch({
    type: types.EMBED_SLIDES_SET,
    payload: { slides },
  });

export const clearAddSlideStatus = () => dispatch =>
  dispatch({
    type: types.EMBED_ADD_SLIDE_STATUS_CLEAR,
  });

export default {
  addSlide,
  updateSlide,
  removeSlide,
  setEmbedSlides,
  clearAddSlideStatus,
};
