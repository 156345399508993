import classNames from 'classnames';
import * as React from 'react';
import _ from 'underscore';

import Modal, { ModalProps } from 'components/Modal';
import ConnectedModal from 'containers/ConnectedModal';
import { getAspectRatioName } from 'utils/aspect-ratio';

interface ICancelButtonConfig {
  className: string;
  text: string;
}

export interface IProps {
  aspectRatio: number;
  className?: string;
  onExited?: ModalProps['onExited'];
  onHide?: ModalProps['onHide'];
  renderBody?: () => React.ReactNode;
  renderFooter?: (cancelButton: ICancelButtonConfig) => React.ReactNode;
  show?: boolean;
  title: string;
  name: 'AddWatermark' | 'EditWatermark';
}

const LogoModal: React.SFC<IProps> = ({
  aspectRatio,
  className,
  onExited,
  onHide,
  renderBody,
  renderFooter,
  show,
  title,
  name,
}) => {
  const ratioName = getAspectRatioName(aspectRatio);
  const cancelButtonConfig = {
    className: 'logo-modal__cancel',
    text: 'Cancel',
  };
  const modalClassName = classNames(
    'logo-modal',
    'logo-modal--default',
    `logo-modal--${ratioName}`,
    className,
  );

  return (
    <ConnectedModal
      name={name}
      backdrop="static"
      className={modalClassName}
      onExited={onExited}
      onHide={onHide}
      show={show}
      title={title}
    >
      <Modal.Body className="logo-modal__body">{renderBody()}</Modal.Body>

      <Modal.Footer className="logo-modal__footer">
        {renderFooter(cancelButtonConfig)}
      </Modal.Footer>
    </ConnectedModal>
  );
};

LogoModal.defaultProps = {
  onExited: _.noop,
  onHide: _.noop,
  renderBody: () => null,
  renderFooter: () => null,
  show: false,
};

export default LogoModal;
