import React from 'react';
import { SelectField, SelectFieldProps } from 'components/Form';
import { VideoPreferenceOption } from 'types';
import { getOptionDisplayName } from 'utils/video-export';
import { block } from '../utils';

// custom type is used instead of SelectFieldProps' `value` prop because `value`
// includes string[], which we don't need to support
type VideoSettingsValue = string | number;

interface Props extends Pick<SelectFieldProps, 'onChange' | 'variant'> {
  label: string;
  options: VideoPreferenceOption<VideoSettingsValue>[];
  value: VideoSettingsValue;
  onLockedClick?: () => void;
}

function VSFieldset({
  variant = 'default',
  label,
  options,
  value,
  onChange,
  onLockedClick,
}: Props) {
  const selectClassName = block('option-select');

  return (
    <fieldset className={block('fieldset')}>
      <label className={block('label')}>{label}</label>
      <SelectField
        controlClassName={block('select-control', { locked: true })}
        value={value}
        onChange={onChange}
        inputClassName={block('select-input')}
        variant={variant}
        defaultValueText={getOptionDisplayName(options, value)}
        lockedWrapperClassName={selectClassName}
        disabled={variant === 'locked'}
        lockedTooltipContent="PRO feature: click to upgrade and unlock it"
        onLockedClick={onLockedClick}
      >
        {options.map(option => (
          <option key={option.displayName} value={option.value}>
            {option.displayName}
          </option>
        ))}
      </SelectField>
    </fieldset>
  );
}

export default VSFieldset;
