import * as React from 'react';

import { AppStoreIcon, ArrowRight, TiktokIcon } from 'components/icons';
import LoadingOverlay from 'components/LoadingOverlay';
import SocialAccountInfo, {
  SocialAccountInfoProps,
} from 'components/SocialAccountInfo';
import { block } from './utils';

type PickedSocialAccountProps = Pick<
  SocialAccountInfoProps,
  'avatarSrc' | 'onSwitchAccountClick' | 'username'
>;

export interface TiktokPostComposerProps extends PickedSocialAccountProps {
  isUploading?: boolean;
  readonly?: boolean;
}

const TiktokPostComposer: React.FC<TiktokPostComposerProps> = ({
  isUploading = false,
  avatarSrc,
  onSwitchAccountClick,
  username,
}) => (
  <>
    {isUploading && <LoadingOverlay title="Uploading Video" />}
    <div className={block('body-container')}>
      <div className={block('icons')}>
        <AppStoreIcon
          className={block('icon-headliner')}
          width="86"
          height="86"
        />

        <ArrowRight className={block('arrow')} height={26} />

        <TiktokIcon className={block('icon-tiktok')} width="86" height="86" />
      </div>

      <p className={block('body-title')}>Share to TikTok</p>

      <p className={block('body-description')}>
        We’ll upload your video to the TikTok mobile app,
        <br />
        where you can make adjustments before posting it.
      </p>

      <SocialAccountInfo
        {...{ avatarSrc, onSwitchAccountClick, username }}
        className={block('social')}
      />
    </div>
  </>
);

export default TiktokPostComposer;
