import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from 'components/ConfirmationModal';
import { SaveAsTemplateCentered } from 'components/icons';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import Modal from 'components/Modal';
import Video from 'components/Video';
import { Tier } from 'redux/middleware/api/plan-service/types';
import { onUnlockThisFeature } from 'redux/modules/mixpanel/actions';
import { modalParamsSelector } from 'redux/modules/modal';
import { replaceModal } from 'redux/modules/modal/actions';
import { block } from './utils';

const VIDEO_SOURCES = [
  'https://static.sparemin.com/static/webapp-assets/videos/pricing/202102121520/feature-gate-save-template.webm',
  'https://static.sparemin.com/static/webapp-assets/videos/pricing/202102121520/feature-gate-save-template.mp4',
];

function SaveAsTemplateIntroModalContent() {
  const dispatch = useDispatch();
  const modalParams = useSelector(modalParamsSelector);

  const handleUpgradeClick = () => {
    dispatch(
      onUnlockThisFeature(modalParams?.unlockThisFeature || 'SaveTemplate'),
    );
    dispatch(
      replaceModal({
        name: 'AddBillingCycle',
        params: {
          tier: Tier.PRO,
          defaultSubscriptionPeriod: 'yearly',
          disableSuccessRedirect: true,
        },
      }),
    );
  };

  return (
    <>
      {modalParams?.showAnimatedVideo ? (
        <Video sources={VIDEO_SOURCES} />
      ) : (
        <CircularGradient className={block('template-icon-container')}>
          <SaveAsTemplateCentered
            width="38%"
            height="unset"
            style={{ position: 'relative' }}
          />
        </CircularGradient>
      )}
      <ConfirmationModal.Body>
        <ConfirmationModal.Title>
          You discovered a premium feature!
        </ConfirmationModal.Title>
        <ConfirmationModal.Content>
          Turn any project into a template to quickly create more videos in the
          same style. Upgrade to unlock this feature and much more.
        </ConfirmationModal.Content>
      </ConfirmationModal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton theme="rainbow" onClick={handleUpgradeClick}>
            Unlock this feature
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
}

export default SaveAsTemplateIntroModalContent;
