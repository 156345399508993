import * as React from 'react';

import { IconProps } from './types';

const Resolution: React.SFC<IconProps> = ({
  fill = 'currentColor',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    {...props}
  >
    <g fill={fill} fillRule="nonzero">
      <path d="M2 9.885V12.5h3V9.885H2zm-1-2h5a1 1 0 0 1 1 1V13.5a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8.885a1 1 0 0 1 1-1z" />
      <path d="M2 4.192h8a1 1 0 0 1 1 1V12.5h2v-10H2v1.692zM1 .5h13a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-12a1 1 0 0 1 1-1zm1 5.692V12.5h7V6.192H2z" />
    </g>
  </svg>
);

export default Resolution;
