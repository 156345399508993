import React from 'react';

import LoadingOverlay from 'components/LoadingOverlay';
import { Step } from 'components/SteppedModal';
import { InstagramPostModalStep } from './types';

const useAuthenticationProgressStep = (): Step<InstagramPostModalStep> => {
  return {
    id: 'authentication-in-progress',
    title: 'Authenticating with Instagram',
    fluidFooterButtons: true,
    hideFooter: true,
    component: <LoadingOverlay small />,
  };
};

export default useAuthenticationProgressStep;
