import * as React from 'react';

import { IconProps } from './types';

const VideoFile: React.SFC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 84 96"
    {...props}
  >
    <defs>
      <filter
        id="sarq19ngda"
        width="220.8%"
        height="196.7%"
        x="-60.4%"
        y="-41.7%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="1"
          result="shadowSpreadOuter1"
        />
        <feOffset dy="4" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="8"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
      </filter>
      <path
        id="bcujjrk0rb"
        d="M3 0h31.757c.796 0 1.56.316 2.122.879L47.12 11.12c.563.563.879 1.326.879 2.122V57c0 1.657-1.343 3-3 3H3c-1.657 0-3-1.343-3-3V3c0-1.657 1.343-3 3-3z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g transform="translate(-1027 -176) translate(477 160) translate(538) translate(30 30)">
                <use
                  fill="#000"
                  filter="url(#sarq19ngda)"
                  xlinkHref="#bcujjrk0rb"
                />
                <use fill="#323746" xlinkHref="#bcujjrk0rb" />
              </g>
              <path
                fill="currentColor"
                fillRule="nonzero"
                d="M26.401 24H17.48c-.817 0-1.479.669-1.479 1.494v9.012c0 .825.662 1.494 1.479 1.494H26.4c.817 0 1.479-.669 1.479-1.494v-9.012c0-.825-.662-1.494-1.479-1.494zm6.011 1.267l-3.452 2.32v4.83l3.452 2.316c.668.448 1.588-.01 1.588-.792v-7.883c0-.779-.917-1.24-1.588-.791z"
                transform="translate(-1027 -176) translate(477 160) translate(538) translate(30 30)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default VideoFile;
