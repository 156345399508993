import * as React from 'react';

import Button, { ButtonProps } from 'components/Button';
import { Omit } from 'types';
import FormField, { FormFieldProps } from './FormField';
import { block } from './utils';

type InputProps = Omit<ButtonProps, 'size'>;

interface IProps extends InputProps {
  className?: string;
}

export type ButtonFieldProps = FormFieldProps<IProps>;

const ButtonField: React.SFC<ButtonFieldProps> = ({ children, ...props }) => {
  return (
    <FormField<InputProps> {...props}>
      <Button className={block('button-input')} theme="input" size="small">
        {children}
      </Button>
    </FormField>
  );
};

export default ButtonField;
