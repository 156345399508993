import request, { SuperAgentRequest, SuperAgentStatic } from 'superagent';
import { isBoolean, isEmpty, isNumber, isString } from 'underscore';

// TODO update existing calls
export function attachFields(
  req: SuperAgentRequest,
  fields: { [k: string]: any },
): SuperAgentRequest {
  if (!req || !fields || isEmpty(fields)) return req;

  Object.keys(fields).forEach(fieldName => {
    const value = fields[fieldName];

    if (value !== undefined && value !== null) {
      if (
        isString(value) ||
        isBoolean(value) ||
        isNumber(value) ||
        Array.isArray(value)
      ) {
        req.field(fieldName, value);
      } else {
        req.attach(fieldName, value);
      }
    }
  });

  return req;
}

export function preSignedUpload(
  url: string,
  file: Blob,
  fields: any,
  onProgress?: (value: number) => void,
  req: SuperAgentStatic = request,
) {
  const post = req.post(url);

  attachFields(post, fields);
  attachFields(post, { file });

  return onProgress
    ? post.on('progress', event => onProgress(event.percent))
    : post;
}

// superagent considers 4xx to be errors, so have to check the error object
// to find the response status
export function isNotFound(err: any) {
  const status = err?.status;
  return status === 403 || status === 404;
}

export default { attachFields };
