import Immutable from 'immutable';

import * as constants from './constants';
import * as types from './types';

const defaultState = Immutable.Map();

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.CONTENT_TYPE_GET_REQUEST: {
      if (!action.payload) return state;
      const { url } = action.payload;

      return defaultState
        .set('url', url)
        .set('getContentTypeStatus', constants.STATUS_PENDING);
    }

    case types.CONTENT_TYPE_GET_SUCCESS: {
      if (!action.payload) return state;

      const { animated, contentType, url } = action.payload;
      if (url !== state.get('url')) return state;

      return state
        .set('animated', animated)
        .set('contentType', contentType)
        .set('getContentTypeStatus', constants.STATUS_SUCCESS);
    }

    case types.CONTENT_TYPE_GET_FAILURE: {
      if (!action.payload) return state;
      const { url } = action.payload;
      if (url !== state.get('url')) return state;

      return state.set('getContentTypeStatus', constants.STATUS_FAILURE);
    }

    default:
      return state;
  }
}
