import { useCallback, useEffect } from 'react';
import WaveSurferJs from 'wavesurfer.js';

import { GetWaveSurfer, WaveSurferProps } from './types';

export default function usePlayback(
  { playing }: WaveSurferProps,
  getWaveSurfer: GetWaveSurfer,
) {
  const setPlayback = useCallback(
    (ws: WaveSurferJs = getWaveSurfer()) => {
      if (!ws) return;
      if (playing && !ws.isPlaying()) {
        ws.play()?.catch(() => {
          // prevent The play() request was interrupted by a call to pause()
        });
      } else if (!playing && ws.isPlaying()) {
        ws.pause();
      }
    },
    [playing, getWaveSurfer],
  );

  useEffect(
    () => {
      const ws = getWaveSurfer();

      if (ws) {
        if (ws.isReady) {
          setPlayback(ws);
        } else {
          const fn = () => setPlayback(ws);
          ws.on('ready', fn);
          return () => ws.un('ready', fn);
        }
      }
      return undefined;
    },
    // NB: this hook should only run when playing changes.  getWaveSurfer is
    // stable so doesn't cause the hook to run as it changes.  setPlayback will
    // only change when playing changes, so it's also safe to list as a dependency
    [playing, getWaveSurfer, setPlayback],
  );

  return setPlayback;
}
