import * as React from 'react';
import LanguageSelector from 'components/LanguageSelector';
import TranscriptionFormContext from './TranscriptionFormContext';
import { block } from './utils';

interface Props {}

const { useContext, useCallback } = React;

const TranscriptionLanguageSelect: React.FC<Props> = () => {
  const { value, onChange, disabled } = useContext(TranscriptionFormContext);

  const handleLanguageChange = useCallback(
    (language: string) => {
      onChange({ ...value, language });
    },
    [onChange, value],
  );

  return (
    <LanguageSelector
      className={block('language-field')}
      disabled={disabled || value?.transcribe === false}
      horizontal={{
        control: { lg: 6, xs: 12 },
        label: { lg: 6, xs: 12 },
      }}
      labelClassName={block('label')}
      onChange={handleLanguageChange}
      value={value.language}
    />
  );
};

TranscriptionLanguageSelect.displayName = 'Transcription.LanguageSelect';

export default TranscriptionLanguageSelect;
