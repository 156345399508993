import classNames from 'classnames';
import { is, Record, RecordOf } from 'immutable';
import * as React from 'react';
import _ from 'underscore';

import { TextOverlay } from 'blocks/TextOverlayModal';
import DraggableWorkspace, {
  OnSizeChange,
} from 'components/DraggableWorkspace';
import Rnd, { RndProps } from 'components/Rnd';
import {
  createMap,
  Dimensions,
  IImmutableMap,
  Position,
  Size,
  SoundwaveType,
} from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import bem from 'utils/bem';
import embedUtils from 'utils/embed';
import { scalePixelToVhVw, scaleVhVwToPixel } from 'utils/embed/ui';
import { setStyle } from 'utils/ui';
import { getWaveFormByType, isAspectRatioLocked } from '../../utils';

export interface AudioWaveImageProps {
  aspectRatio?: number;
  background: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  lockedWavePosition?: boolean;
  onWorkspaceSizeChange?: OnSizeChange;
  textOverlay?: TextOverlay;
  waveType?: SoundwaveType;
  waveColor?: string;
  /**
   * The expectation is that the wavePosition and waveSize passed in here will always
   * be in vh and vw values.  This component is responsible for doing any conversions,
   * and will always pass vh and vw values out in the corresponding change functions below
   */
  // TODO consider using records instead of maps for these props
  wavePosition?: IImmutableMap<Partial<Position<string | number>>>;
  waveSecondaryColor?: string;
  waveSize?: IImmutableMap<Partial<Size<string | number>>>;
  onWavePositionChange?: (pos: Position<string>) => void;
  onWaveSizeChange?: (posAndSize: Dimensions<string>) => void;
}

interface IUiState {
  dragging: boolean;
  resizing: boolean;
  workspaceHeight: number;
  workspaceWidth: number;
  wavePositionLeft: number;
  wavePositionTop: number;
  waveHeight: number;
  waveWidth: number;
}

const uiFactory = Record<IUiState>({
  dragging: false,
  resizing: false,
  waveHeight: undefined,
  wavePositionLeft: undefined,
  wavePositionTop: undefined,
  waveWidth: undefined,
  workspaceHeight: undefined,
  workspaceWidth: undefined,
});

interface IState {
  ui: RecordOf<IUiState>;
}

const block = bem('audio-wave-image');

export default class AudioWaveImage extends React.Component<
  AudioWaveImageProps,
  IState
> {
  private workspace;

  public static defaultProps: Partial<AudioWaveImageProps> = {
    aspectRatio: 1,
    lockedWavePosition: false,
    onWavePositionChange: _.noop,
    onWaveSizeChange: _.noop,
    onWorkspaceSizeChange: _.noop,
    textOverlay: undefined,
    waveColor: '#ffffff',
    wavePosition: createMap<Partial<Position<string | number>>>({
      left: 0,
      top: 0,
    }),
    waveSecondaryColor: undefined,
    waveSize: createMap<Partial<Size<string | number>>>({
      height: '30vh',
      width: '100vw',
    }),
    waveType: 'none',
  };

  constructor(props: AudioWaveImageProps) {
    super(props);

    // The initial values for the wave position is set to 0 since we don't know the workspace
    // size in the beginning and we will need to recalculate it anyways
    this.state = {
      ui: uiFactory({
        dragging: false,
        resizing: false,
        waveHeight: 0,
        wavePositionLeft: 0,
        wavePositionTop: 0,
        waveWidth: 0,
        workspaceHeight: 0,
        workspaceWidth: 0,
      }),
    };
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<AudioWaveImageProps>,
  ) {
    const {
      wavePosition: nextWavePosition,
      waveSize: nextWaveSize,
    } = nextProps;
    const { wavePosition, waveSize } = this.props;
    const { ui } = this.state;

    if (!is(wavePosition, nextWavePosition) || !is(waveSize, nextWaveSize)) {
      const {
        waveLeft,
        waveTop,
        waveHeight,
        waveWidth,
      } = this.convertWaveToPixels(
        ui.get('workspaceHeight'),
        ui.get('workspaceWidth'),
        nextProps,
      );

      this.setState(({ ui: oldUi }) => ({
        ui: oldUi.withMutations(u =>
          u
            .set('wavePositionLeft', waveLeft)
            .set('wavePositionTop', waveTop)
            .set('waveHeight', waveHeight)
            .set('waveWidth', waveWidth),
        ),
      }));
    }
  }

  // Converts the wave position and wave size to pixel values using the new workspace size
  private convertWaveWithWorkspace = (height, width) => {
    const {
      waveLeft,
      waveTop,
      waveHeight,
      waveWidth,
    } = this.convertWaveToPixels(height, width, this.props);

    this.setState(({ ui }) => ({
      ui: ui.withMutations(u =>
        u
          .set('workspaceHeight', height)
          .set('workspaceWidth', width)
          .set('wavePositionLeft', waveLeft)
          .set('wavePositionTop', waveTop)
          .set('waveHeight', waveHeight)
          .set('waveWidth', waveWidth),
      ),
    }));
  };

  private convertWaveToPixels = (
    height: number,
    width: number,
    props: Readonly<AudioWaveImageProps>,
  ) => {
    const { wavePosition, waveSize } = props;

    const waveLeft = scaleVhVwToPixel(wavePosition.get('left'), width);
    const waveTop = scaleVhVwToPixel(wavePosition.get('top'), height);
    const waveHeight = scaleVhVwToPixel(waveSize.get('height'), height);
    const waveWidth = scaleVhVwToPixel(waveSize.get('width'), width);

    return { waveLeft, waveTop, waveHeight, waveWidth };
  };

  private convertPixelsToVhVw = (x: number, y: number) => {
    const { ui } = this.state;
    const height = ui.get('workspaceHeight');
    const width = ui.get('workspaceWidth');

    const vh = scalePixelToVhVw(y, height, 'vh');
    const vw = scalePixelToVhVw(x, width, 'vw');

    return { vh, vw };
  };

  private handleWorkspaceSizeChange: OnSizeChange = workspaceSize => {
    const { onWorkspaceSizeChange } = this.props;
    const { height, width } = workspaceSize;

    this.convertWaveWithWorkspace(height, width);
    onWorkspaceSizeChange(workspaceSize);
  };

  private handleDrag: RndProps['onDrag'] = (__, dragData) => {
    const { x, y } = dragData;
    this.setState(({ ui }) => ({
      ui: ui.withMutations(u =>
        u.set('wavePositionLeft', x).set('wavePositionTop', y),
      ),
    }));
  };

  private handleDragStart = () => {
    this.setState(({ ui }) => ({
      ui: ui.withMutations(u => u.set('dragging', true)),
    }));
  };

  private handleDragStop: RndProps['onDragStop'] = (__, dragData) => {
    const { onWavePositionChange } = this.props;
    const { x, y } = dragData;

    const convertedPos = this.convertPixelsToVhVw(x, y);

    const style = { cursor: 'default' };
    setStyle(document.body, style);

    this.setState(({ ui }) => ({
      ui: ui.withMutations(u => u.set('dragging', false)),
    }));

    onWavePositionChange({ left: convertedPos.vw, top: convertedPos.vh });
  };

  private handleResize = (__, ___, ____, _____, position) => {
    this.setState(({ ui }) => ({
      ui: ui.withMutations(u =>
        u
          .set('wavePositionLeft', position.x)
          .set('wavePositionTop', position.y),
      ),
    }));
  };

  private handleResizeStart = () => {
    this.setState(({ ui }) => ({
      ui: ui.withMutations(u => u.set('resizing', true)),
    }));
  };

  private handleResizeStop = (__, ___, ref, ____, position) => {
    const { onWaveSizeChange } = this.props;
    const rect = ref.getBoundingClientRect();

    const convertedSize = this.convertPixelsToVhVw(rect.width, rect.height);
    const convertedPos = this.convertPixelsToVhVw(position.x, position.y);

    this.setState(({ ui }) => ({
      ui: ui.withMutations(u => u.set('resizing', false)),
    }));

    onWaveSizeChange({
      height: convertedSize.vh,
      left: convertedPos.vw,
      top: convertedPos.vh,
      width: convertedSize.vw,
    });
  };

  private showWave = () => {
    const { waveType } = this.props;

    return waveType && waveType !== 'none';
  };

  private renderTextOverlay = () => {
    const { textOverlay } = this.props;

    if (!textOverlay || !this.workspace) return null;

    // workspace has different sizes than textOverlay's viewport
    // so we need to calculate top, left and font-size based on ratio
    const workspaceHeight =
      this.workspace && this.workspace.getInstance().size.height;
    const workspaceWidth =
      this.workspace && this.workspace.getInstance().size.width;
    const viewportHeight = textOverlay.getIn(['viewport', 'height'], 1);
    const viewportWidth = textOverlay.getIn(['viewport', 'width'], 1);
    const ratioHeight =
      viewportHeight > workspaceHeight
        ? workspaceHeight / viewportHeight
        : viewportHeight / workspaceHeight;
    const ratioWidth =
      viewportWidth > workspaceWidth
        ? workspaceWidth / viewportWidth
        : viewportWidth / workspaceWidth;
    const left = textOverlay.getIn(['position', 'left'], 0) * ratioWidth;
    const top = textOverlay.getIn(['position', 'top'], 0) * ratioHeight;
    const style = {
      height: textOverlay.getIn(['size', 'height']) * ratioHeight,
      transform: `translate(${left}px, ${top}px)`,
      width: textOverlay.getIn(['size', 'width']) * ratioWidth,
    };
    const textHtml = embedUtils.scaleInlineStyles(
      textOverlay.get('textHtml'),
      'fontSize',
      val => `${val * ratioWidth}px`,
    );

    return (
      <div
        className={block('text-overlay')}
        style={style}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: textHtml }}
      />
    );
  };

  private workspaceRef = el => (this.workspace = el);

  public render() {
    const {
      background,
      children,
      className,
      waveType,
      waveColor,
      aspectRatio,
      lockedWavePosition,
    } = this.props;
    const { ui } = this.state;

    const ratioName = getAspectRatioName(aspectRatio);
    const ratioClass = ratioName && block({ [ratioName]: ratioName });

    const containerClassName = classNames(block(), className, ratioClass);

    const waveStyle = {
      color: waveColor,
      fill: waveColor,
    };

    const wavePosition = {
      x: ui.get('wavePositionLeft'),
      y: ui.get('wavePositionTop'),
    };

    const waveSize = {
      height: ui.get('waveHeight'),
      width: ui.get('waveWidth'),
    };

    const lockAspectRatio = isAspectRatioLocked(waveType);
    const enableResizing = {
      bottom: true,
      bottomLeft: true,
      bottomRight: true,
      left: true,
      right: true,
      top: true,
      topLeft: true,
      topRight: true,
    };

    const WaveForm = getWaveFormByType(waveType);

    return (
      <div className={containerClassName}>
        <DraggableWorkspace
          aspectRatio={aspectRatio}
          className={block('draggable')}
          onSizeChange={this.handleWorkspaceSizeChange}
          ref={this.workspaceRef}
        >
          {this.renderTextOverlay()}

          {background}
          <div className={block('sizer')}>
            {this.showWave() && (
              <Rnd
                active
                className={block('rnd')}
                bounds="parent"
                enableResizing={!lockedWavePosition && enableResizing}
                lockAspectRatio={lockAspectRatio}
                position={wavePosition}
                size={waveSize}
                onDragStart={this.handleDragStart}
                onDrag={this.handleDrag}
                onDragStop={this.handleDragStop}
                onResizeStart={this.handleResizeStart}
                onResizeStop={this.handleResizeStop}
                onResize={this.handleResize}
                disableDragging={lockedWavePosition}
              >
                <WaveForm className={block('wave')} style={waveStyle} />
              </Rnd>
            )}
            {children}
          </div>
        </DraggableWorkspace>
      </div>
    );
  }
}
