import * as React from 'react';
import Modal, { ModalProps } from 'components/Modal';
import OptionTile from '../assets/OptionTile';
import { useEditorState } from '../context/VideoEditorStateContext';
import { addImageModalBlock as block } from '../utils';

export interface AddImageModalProps extends Pick<ModalProps, 'onExited'> {
  onCancel?: () => void;
  onClick?: (integrationId: string, e: React.MouseEvent<HTMLElement>) => void;
  show?: boolean;
}

const AddMediaModal: React.FC<AddImageModalProps> = ({
  onCancel,
  onClick,
  onExited,
  show,
}) => {
  const { integrations } = useEditorState();
  const { staticImageIntegrations } = integrations;

  return (
    <Modal
      className={block()}
      title="add media"
      show={show}
      onHide={onCancel}
      onExited={onExited}
    >
      <Modal.Body className={block('body')}>
        {staticImageIntegrations.map(integration => (
          <OptionTile
            key={integration.id}
            icon={integration.staticSelectionModalProps.icon}
            label={integration.staticSelectionModalProps.label}
            onClick={onClick}
            params={integration.id}
          />
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default AddMediaModal;
