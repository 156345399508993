import cn from 'classnames';
import * as React from 'react';
import { Tooltip as BsTooltip, Overlay, OverlayProps } from 'react-bootstrap';
import * as ReactDOM from 'react-dom';
import { isUndefined, noop } from 'underscore';
import bem from 'utils/bem';

import { createChainedFunction } from 'utils/functions';
import TooltipTarget from './TooltipTarget';

const block = bem('hl-tooltip');

interface IProps {
  animation?: boolean;
  children?: React.ReactNode;
  className?: string;
  container?: React.ReactNode;
  content: React.ReactNode;
  defaultShow?: boolean;
  delayHide?: number;
  delayShow?: number;
  onEnter?: () => void;
  onExit?: () => void;
  id: string;
  placement?: 'left' | 'top' | 'bottom' | 'right';
  preventHideOnHover?: boolean;
  /**
   * Omitting this prop or passing undefined will show the Tooltip only on hover
   */
  show?: boolean;
  target?: React.ReactInstance;
  rootClose?: boolean;
  onHide?: () => void;
  belowModal?: boolean;
  hideArrow?: boolean;
}

interface IState {
  show: boolean;
}

export default class Tooltip extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    animation: true,
    defaultShow: false,
    delayHide: 0,
    delayShow: 400,
    onEnter: noop,
    onExit: noop,
    preventHideOnHover: true,
  };

  public static Target = TooltipTarget;

  private overlay: React.ReactElement<OverlayProps>;
  private mountNode: HTMLElement;
  private hoverShowDelayId: number;
  private hoverHideDelayId: number;

  public state: IState = {
    show: this.managesShow() ? this.props.defaultShow : this.props.show,
  };

  public componentDidMount() {
    this.mountNode = document.createElement('div');
    this.renderOverlay();
  }

  public componentDidUpdate() {
    this.renderOverlay();
  }

  public componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.mountNode);
    this.mountNode = undefined;

    window.clearTimeout(this.hoverHideDelayId);
    window.clearTimeout(this.hoverShowDelayId);

    this.hoverShowDelayId = undefined;
    this.hoverShowDelayId = undefined;
  }

  private handleMouseOver = () => {
    const { delayShow } = this.props;
    const { show } = this.state;

    if (!isUndefined(this.hoverHideDelayId)) {
      clearTimeout(this.hoverHideDelayId);
      this.hoverHideDelayId = undefined;
      return;
    }

    if (show || !isUndefined(this.hoverShowDelayId)) {
      return;
    }

    this.hoverShowDelayId = window.setTimeout(() => {
      this.hoverShowDelayId = undefined;
      this.show();
    }, delayShow);
  };

  private handleMouseOut = () => {
    const { delayHide, preventHideOnHover } = this.props;
    const { show } = this.state;

    if (!isUndefined(this.hoverShowDelayId)) {
      clearTimeout(this.hoverShowDelayId);
      this.hoverShowDelayId = undefined;
      return;
    }

    if (!show || !isUndefined(this.hoverHideDelayId)) {
      return;
    }

    const delay = preventHideOnHover ? Math.max(delayHide, 100) : delayHide;

    this.hoverHideDelayId = window.setTimeout(() => {
      this.hoverHideDelayId = undefined;
      this.hide();
    }, delay);
  };

  private createOverlay() {
    const {
      animation,
      className,
      container,
      content,
      id,
      onEnter,
      onExit,
      placement,
      preventHideOnHover,
      target = this,
      rootClose,
      onHide,
      belowModal,
      hideArrow,
    } = this.props;

    const mouseEventTooltipProps = !preventHideOnHover
      ? {}
      : {
          onMouseOut: this.handleMouseOut,
          onMouseOver: this.handleMouseOver,
        };

    return (
      <Overlay
        animation={animation}
        container={container}
        show={this.isShow()}
        onEnter={onEnter}
        onExit={onExit}
        placement={placement}
        target={target}
        shouldUpdatePosition
        rootClose={rootClose}
        onHide={onHide}
      >
        <BsTooltip
          className={cn(
            block({ 'below-modal': belowModal, 'hide-arrow': hideArrow }),
            className,
          )}
          id={id}
          {...mouseEventTooltipProps}
        >
          {content}
        </BsTooltip>
      </Overlay>
    );
  }

  private show = () => {
    if (!this.managesShow()) return;
    this.setState({ show: true });
  };

  public hide = () => {
    if (!this.managesShow()) return;
    this.setState({ show: false });
  };

  private managesShow() {
    const { show } = this.props;
    return isUndefined(show);
  }

  private isShow() {
    const { show: showProp } = this.props;
    const { show: showState } = this.state;

    return this.managesShow() ? showState : showProp;
  }

  private renderOverlay() {
    ReactDOM.unstable_renderSubtreeIntoContainer(
      this,
      this.overlay,
      this.mountNode,
    );
  }

  public render() {
    const { children } = this.props;

    const child = React.Children.only(children) as React.ReactElement;
    const childProps = child.props;

    this.overlay = this.createOverlay();

    return React.cloneElement(child, {
      onMouseOut: createChainedFunction(
        childProps.onMouseOut,
        this.handleMouseOut,
      ),
      onMouseOver: createChainedFunction(
        childProps.onMouseOver,
        this.handleMouseOver,
      ),
    });
  }
}

export { IProps as TooltipProps };
