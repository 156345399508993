import React from 'react';
import { IconProps } from './types';

const Exclamation: React.FC<IconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="60"
    height="60"
    viewBox="0 0 60 60"
    {...props}
  >
    <defs>
      <linearGradient id="yjvm3qmqtb" x1="0%" x2="100%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#5DBEFC" />
        <stop offset="100%" stopColor="#DD92FE" />
      </linearGradient>
      <linearGradient
        id="fzxi5s4kdd"
        x1="0%"
        x2="100%"
        y1="10.494%"
        y2="89.506%"
      >
        <stop offset="0%" stopColor="#5DBEFC" />
        <stop offset="100%" stopColor="#DD92FE" />
      </linearGradient>
      <filter
        id="90q63zl9qe"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" />
      </filter>
      <circle id="5bzs0h80fa" cx="30" cy="30" r="30" />
      <path
        id="lkoe81l1nf"
        d="M4.4 16.877c-2.43 0-4.4 1.953-4.4 4.362C0 23.647 1.97 25.6 4.4 25.6c2.43 0 4.4-1.953 4.4-4.361 0-2.41-1.97-4.362-4.4-4.362zM.223 1.2l.71 12.895a1.143 1.143 0 001.145 1.075h4.644c.61 0 1.113-.472 1.146-1.075L8.578 1.2A1.142 1.142 0 007.43 0H1.37C.71 0 .187.548.223 1.2z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="1qe2yy8t9c" fill="#fff">
        <use xlinkHref="#5bzs0h80fa" />
      </mask>
      <use fill="url(#yjvm3qmqtb)" xlinkHref="#5bzs0h80fa" />
      <g mask="url(#1qe2yy8t9c)">
        <path fill="#000" d="M-11.2-6.4h82.8v73.6h-82.8z" />
        <path
          fill="url(#fzxi5s4kdd)"
          d="M0 0h82.8v73.6H0z"
          filter="url(#90q63zl9qe)"
          transform="translate(-11.2 -6.4)"
        />
      </g>
      <g transform="translate(25.6 17.6)">
        <mask id="c3iubu7myg" fill="#fff">
          <use xlinkHref="#lkoe81l1nf" />
        </mask>
        <use fill="#282E37" fillRule="nonzero" xlinkHref="#lkoe81l1nf" />
        <g fill="#282E38" mask="url(#c3iubu7myg)">
          <path d="M-36.8-24H46v73.6h-82.8z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Exclamation;
