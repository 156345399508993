import * as React from 'react';

import { useDispatch } from 'react-redux';
import MaxDurationShareButton, {
  MaxDurationShareButtonProps,
} from 'blocks/DownloadPage/components/MaxDurationShareButton';
import { FacebookButton, FacebookButtonProps } from 'components/SocialButton';
import useFacebookAuthenticator from 'hooks/useFacebookAuthenticator';
import { showError } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { createChainedFunction } from 'utils/functions';

const MAX_VIDEO_DURATION_SEC = 36000; // 10 hours

type PickedButtonProps = Pick<FacebookButtonProps, 'disabled'>;
type PickedMaxDurationButtonProps = Pick<
  MaxDurationShareButtonProps,
  'videoDurationSec'
>;

export interface FacebookPostButtonProps
  extends PickedButtonProps,
    PickedMaxDurationButtonProps {
  onAuthenticatedClick?: () => void;
  onClick?: () => void;
}

const FacebookPostButton: React.FC<FacebookPostButtonProps> = ({
  disabled,
  onAuthenticatedClick,
  onClick,
  videoDurationSec,
}) => {
  const dispatch = useDispatch<Dispatch>();

  const { authenticating, withAuthentication } = useFacebookAuthenticator({
    onError: error => dispatch(showError({ message: error.message })),
  });

  return (
    <MaxDurationShareButton
      maxVideoDurationSec={MAX_VIDEO_DURATION_SEC}
      platform="facebook"
      videoDurationSec={videoDurationSec}
    >
      <FacebookButton
        disabled={disabled || authenticating}
        onClick={createChainedFunction(
          onClick,
          withAuthentication(onAuthenticatedClick),
        )}
        params={withAuthentication}
        size="small"
      />
    </MaxDurationShareButton>
  );
};

export default FacebookPostButton;
