import { useEffect, useState } from 'react';
import { WaveSurferOptions, WaveSurferProps } from './types';

const DEFAULT_OPTIONS: Partial<WaveSurferOptions> = {
  autoCenter: true,
  backend: 'WebAudio',
  responsive: true,
  hideScrollbar: true,
};

function createOptions(options: WaveSurferOptions): WaveSurferOptions {
  return {
    ...DEFAULT_OPTIONS,
    ...options,
  };
}

/**
 * merges options with defaults
 */
export default function useOptions({ options }: WaveSurferProps) {
  const [mergedOpts, setMergedOpts] = useState(createOptions(options));

  useEffect(() => {
    setMergedOpts(createOptions(options));
  }, [options]);

  return mergedOpts;
}
