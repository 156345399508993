import fileExtension from 'file-extension';
import md5 from 'js-md5';
import request from 'superagent';
import { isString } from 'underscore';

export function isFileLike(obj: unknown): obj is Blob {
  return obj instanceof File || obj instanceof Blob;
}

export function hasValidType(file: File, accept: string) {
  for (const t of accept.split(',')) {
    const type = t.trim();
    if (type.startsWith('.')) {
      if (file.name.toLowerCase().endsWith(type.toLowerCase())) {
        return true;
      }
    }
    if (new RegExp(type.replace(/\*/g, '.*'), 'ig').test(file.type)) {
      return true;
    }
  }

  return false;
}

export function getFileExtension(file: File) {
  if (file.name) {
    return fileExtension(file.name);
  }

  return '';
}

export function getFileNameWithtoutExtension(file: File | string) {
  if (isString(file)) {
    return '';
  }

  if (isString(file?.name)) {
    return file.name.substr(0, file.name.lastIndexOf('.')) || file.name;
  }

  return '';
}

export function downloadFile(url: string, name: string) {
  return request
    .get(url)
    .responseType('blob')
    .then(({ body, type }) => new File([body], name, { type }));
}

export function getFileSize(
  source: File | string,
  mode: RequestMode = 'no-cors',
) {
  if (!source) return Promise.resolve(0);

  if (source instanceof File) {
    return Promise.resolve(source.size);
  }

  // wrap fetch in bluebird promise
  const result = fetch(source, {
    method: 'HEAD',
    mode,
  }).then(res => Number(res.headers.get('content-length')));
  return Promise.resolve(result);
}

function getFileAsArrayBuffer(file: Blob): Promise<ArrayBuffer> {
  // arrayBuffer should be defined on file for all modern browsers, however when
  // tests run, they use node's File object, which doesn't have arrayBuffer defined.
  // rather than only use the FileReader logic if process.env.NODE_ENV is 'test', there's
  // probably no harm in using this polyfill in prod as well
  if ('arrayBuffer' in file) return file.arrayBuffer();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as any);
    reader.onerror = () => reject();
    reader.readAsArrayBuffer(file);
  });
}

export async function md5File(file: Blob): Promise<string> {
  const buffer = await getFileAsArrayBuffer(file);
  return md5.hex(buffer);
}

export function megabytesToBytes(
  valueInMegaBytes?: number,
): number | undefined {
  if (!valueInMegaBytes) return undefined;

  return valueInMegaBytes * 1000000;
}

export default { isFileLike, hasValidType, getFileExtension };
