import * as React from 'react';
import { Amazon, Fox, ImDb, Pfizer, SiriusXm, Wistia } from 'components/icons';
import IHeart from 'components/icons/IHeart';
import bem from 'utils/bem';
import Testimonial from './Testimonial';

export const block = bem('social-proof-section');

function SocialProofSection() {
  return (
    <section className={block()}>
      <div className={block('testimonials')}>
        <Testimonial
          avatarSrc="https://static.sparemin.com/static/webapp-assets/social/202012081311/profile-photo-babak-nivi.jpg"
          name="Babak Nivi"
          podcastName="The Naval Podcast"
        >
          “We use this for every episode of the Naval and Spearhead podcasts…
          It’s more fun than just posting to podcast apps.”
        </Testimonial>
        <Testimonial
          avatarSrc="https://static.sparemin.com/static/webapp-assets/social/202012081311/profile-photo-michael-ayalon.jpg"
          name="Michael Ayalon"
          podcastName="Fraternity Foodie Podcast"
        >
          “[We] got tons more podcast downloads with Headliner. It's a great
          product and super easy to use.”
        </Testimonial>
      </div>
      <div className={block('divider')}>
        <span>TRUSTED BY</span>
      </div>
      <div className={block('customers')}>
        <IHeart />
        <SiriusXm />
        <ImDb />
        <Amazon />
        <Fox />
        <Wistia />
        <Pfizer />
      </div>
    </section>
  );
}

export default SocialProofSection;
