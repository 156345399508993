import { List } from 'immutable';

import { Type } from './action-types';
import { descriptFactory, queryFactory, stateFactory } from './factories';
import { PodcastSearchAction, PodcastSearchState } from './types';

const defaultState = stateFactory();

export default function(
  state: PodcastSearchState = defaultState,
  action: PodcastSearchAction,
) {
  switch (action.type) {
    case Type.PODCAST_SEARCH_REQUEST: {
      const { integration, query } = action.payload;

      return state.withMutations(s => {
        s.set('searchTerm', query);
        s.set('isLoadingPodcasts', true);
        s.set('searchHandlerIntegration', integration);

        if (integration === 'descript') {
          s.set('descript', undefined);
        }
        return s;
      });
    }

    case Type.PODCAST_SEARCH_SUCCESS: {
      return state.withMutations(s => {
        s.set('isLoadingPodcasts', false).set('query', undefined);
        switch (action.payload.integration) {
          case 'listennotes': {
            const { podcasts, query } = action.payload;
            s.set('query', queryFactory(query));
            s.update('results', (results = List()) =>
              results.concat(podcasts).toList(),
            );
            break;
          }
          case 'rss': {
            const { podcasts } = action.payload;
            s.set('results', List(podcasts));
            break;
          }
          case 'descript': {
            const { audioUrl, transcriptUrl } = action.payload;
            s.set('descript', descriptFactory({ audioUrl, transcriptUrl }));
            break;
          }
          case 'favorite': {
            const { podcasts } = action.payload;
            s.set('results', List(podcasts));
            break;
          }
          default: {
            break;
          }
        }
        return s;
      });
    }

    case Type.PODCAST_SEARCH_FAILURE: {
      const { integration } = action.payload;
      return state.withMutations(s => {
        s.set('isLoadingPodcasts', false);

        if (integration === 'rss') {
          s.set('results', List([]));
        }
        return s;
      });
    }

    case Type.PODCAST_EPISODES_GET_REQUEST:
      return state.set('isLoadingEpisodes', true);

    case Type.PODCAST_EPISODES_GET_SUCCESS: {
      const { nextPublishMillis } = action.payload;
      return state.withMutations(s => {
        s.set('nextEpisodePublishMillis', nextPublishMillis);
        s.set('isLoadingEpisodes', false);
        return s;
      });
    }

    case Type.PODCAST_EPISODES_GET_FAILURE:
      return state.set('isLoadingEpisodes', false);

    case Type.PODCAST_SEARCH_RESULTS_CLEAR:
      return defaultState.set('favorites', state.favorites);

    case Type.PODCAST_SELECTED_PODCAST_SET: {
      const { podcastId } = action.payload;
      return state.set('selectedPodcastId', podcastId);
    }

    case Type.PODCAST_SELECTED_EPISODE_SET: {
      const { episodeId } = action.payload;
      return state.set('selectedEpisodeId', episodeId);
    }

    case Type.PODCAST_EPISODES_CLEAR:
      return state.withMutations(s => {
        s.set('nextEpisodePublishMillis', undefined);
        s.set('episodeListScrollOffset', 0);

        if (s.get('searchHandlerIntegration') === 'favorite') {
          s.set('searchHandlerIntegration', undefined);
          s.set('results', undefined);
        }

        return s;
      });

    case Type.PODCAST_SEARCH_SCROLL_OFFSET_SET: {
      const { listType, offset } = action.payload;
      const key =
        listType === 'podcast'
          ? 'podcastListScrollOffset'
          : 'episodeListScrollOffset';
      return state.set(key, offset);
    }

    case Type.FAVORITE_PODCASTS_GET_SUCCESS: {
      const { result } = action.payload;
      return state.set('favorites', List(result));
    }

    case Type.FAVORITE_PODCAST_DELETE_REQUEST: {
      const { podcastId } = action.payload;
      return state.update('favorites', ids =>
        ids.delete(ids.indexOf(podcastId)),
      );
    }

    default:
      return state;
  }
}
