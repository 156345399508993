import * as React from 'react';

import { useWheel } from 'hooks/useWheel';

import DraggableSlideshowAsset from '../assets/DraggableSlideshowAsset';
import DraggableVideoAsset from '../assets/DraggableVideoAsset';
import LottieAsset from '../assets/LottieAsset';
import { RndAssetCallback } from '../assets/RndAsset';
import { useChildViewState, useNavigation } from '../context/NavigationContext';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { isLottie } from '../state/state-utils';
import useMediaLayer from '../useMediaLayer';
import { canvasBlock as block } from '../utils';

import Layer from './Layer';
import {
  DOUBLECLICK_CHANGE_TYPE_MAP,
  PLACEMENT_CHANGE_TYPE_MAP,
  WHEEL_CHANGE_TYPE_MAP,
} from './utils';

const { useCallback } = React;

export interface MediaLayerProps {
  layerId?: string;
  autoplayVideo?: boolean;
  useDefaultVideoStyle?: boolean;
}

const MediaLayer: React.FC<MediaLayerProps> = ({
  layerId,
  autoplayVideo = true,
  useDefaultVideoStyle = true,
}) => {
  const [state] = useNavigation();
  const { service: childService } = useChildViewState();
  const dispatch = useTemplateDispatch();

  const mediaLayer = useMediaLayer({ layerId });
  const handleLayerDoubleClick = useCallback(() => {
    // if media layer could not be initiaized, action is not dispatched
    if (mediaLayer.type === 'none') {
      return;
    }

    const { id } = mediaLayer;
    dispatch({
      // adjust action type depending on image or videoclip
      type: DOUBLECLICK_CHANGE_TYPE_MAP[mediaLayer.type],
      payload: { id },
    });
  }, [dispatch, mediaLayer]);

  const handlePlacementChange: RndAssetCallback = useCallback(
    value => {
      // if media layer could not be initiaized, action is not dispatched
      if (mediaLayer.type === 'none') {
        return;
      }

      const { id } = mediaLayer;
      dispatch({
        // adjust action type depending on image or videoclip
        type: PLACEMENT_CHANGE_TYPE_MAP[mediaLayer.type],
        payload: {
          id,
          placement: value,
        },
      });
    },
    [dispatch, mediaLayer],
  );

  const handleLayerWheel = useWheel(
    useCallback(
      (e: React.WheelEvent<Element>) => {
        // if media layer could not be initiaized, action is not dispatched
        if (mediaLayer.type === 'none') {
          return;
        }

        dispatch({
          // adjust action type depending on image or videoclip
          type: WHEEL_CHANGE_TYPE_MAP[mediaLayer.type],
          payload: {
            event: e,
            id: mediaLayer.id,
          },
        });
      },
      [dispatch, mediaLayer],
    ),
  );

  // this condition remains from when watermark was shown at ucs.
  // if it is brought back, this condition should be reviewed.
  const isLayerActive =
    state.matches({ child: 'image' }) && childService?.state.matches('open');

  // asset is initialized with undefined, if type is not image or videoclip
  // no asset will be shown. this condition should not happen.
  let Asset;

  if (mediaLayer.type === 'image') {
    // for image types a draggableSlideshow/lottie component will be shown.
    const { id } = mediaLayer;
    Asset = isLottie(mediaLayer.asset) ? (
      <LottieAsset key={id} slideId={id} />
    ) : (
      <DraggableSlideshowAsset
        key={id}
        id={id}
        onDragStop={handlePlacementChange}
        onResizeStop={handlePlacementChange}
        params={id}
      />
    );
    // for video clips, a draggableVideo component will be shown.
  } else if (mediaLayer.type === 'videoClip') {
    const { id } = mediaLayer;
    Asset = (
      <DraggableVideoAsset
        key={id}
        id={id}
        onDragStop={handlePlacementChange}
        onResizeStop={handlePlacementChange}
        params={id}
        autoplayVideo={autoplayVideo}
        useDefaultVideoStyle={useDefaultVideoStyle}
      />
    );
  }

  return (
    <Layer
      active={isLayerActive}
      className={block('media-layer')}
      onDoubleClick={handleLayerDoubleClick}
      onWheel={handleLayerWheel}
    >
      <>{Asset}</>
    </Layer>
  );
};

export default MediaLayer;
