import * as React from 'react';
import { noop } from 'underscore';

import Button from 'components/Button';
import FontAwesome from 'components/FontAwesome';
import { H1, H2 } from 'components/Heading';
import { HeadlinerColor } from 'components/icons';
import LinkButton from 'components/LinkButton';
import RefreshPageButton from 'components/RefreshPageButton';
import bem from 'utils/bem';

export interface IProps {
  onClickErrorRefresh?: () => void;
  onLoad?: () => void;
  onLogoutClick?: (e: React.MouseEvent<any>) => void;
}

const block = bem('error-page');

export default class ErrorPage extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onClickErrorRefresh: noop,
    onLoad: noop,
  };

  public componentDidMount() {
    const { onLoad } = this.props;
    onLoad();
  }

  public render() {
    const { onClickErrorRefresh, onLogoutClick } = this.props;

    function LogOutAndRefresh(e: React.MouseEvent<any, MouseEvent>) {
      onLogoutClick(e);
      window.location.reload();
    }

    return (
      <div className={block()}>
        <div className={block('contents')}>
          <a href="/" className={block('logo')}>
            <HeadlinerColor />
          </a>
          <hgroup className={block('headings')}>
            <H1 className={block('heading-primary')}>We need a refresh</H1>
            <H2 className={block('heading-secondary')}>
              Something's wrong, please refresh this page so we can fix it.
            </H2>
          </hgroup>
          <RefreshPageButton
            className={block('refresh-button')}
            onClickRefreshButton={onClickErrorRefresh}
            shape="chip"
          >
            Refresh this page
          </RefreshPageButton>
          <div className={block('log-out')}>
            <p className={block('log-out-title')}>
              Refreshing didn't solve it?
            </p>
            <span className={block('log-out-subtitle')}>
              If the above button isn’t working, please try to log out and then
              log back in again. This usually does the trick!
            </span>
            <Button
              className={block('log-out-button')}
              shape="chip"
              theme="submit-alt"
              onClick={e => LogOutAndRefresh(e)}
            >
              Log Out
            </Button>
          </div>
          <span className={block('contact-support')}>
            <FontAwesome
              icon="headset"
              className={block('contact-support-icon')}
            />
            If all else fails, please email{' '}
            <LinkButton href="mailto: support@headliner.app" theme="cta">
              support@headliner.app
            </LinkButton>
          </span>
        </div>
      </div>
    );
  }
}
