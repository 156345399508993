import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { projectNameSelector } from 'redux/modules/download/selectors';
import { youtubeSelector } from 'redux/modules/social';
import { PostStatus } from 'redux/modules/social/constants';
import { MAX_CHARS_TITLE } from './utils';

const usernameSelector = createSelector(
  youtubeSelector,
  youtube => youtube.username,
);

const avatarSrcSelector = createSelector(
  youtubeSelector,
  youtube => youtube.profileImageUrl,
);

const postStatusSelector = createSelector(
  youtubeSelector,
  youtube => youtube.postStatus,
);

interface UseYoutubePostModalConfig {
  defaultTitle?: string;
  defaultTags?: string[];
}

function formatTitle(val: string) {
  if (typeof val !== 'string') return undefined;
  return val.substring(0, MAX_CHARS_TITLE);
}

export default function useYoutubePostModalSelector(
  config: UseYoutubePostModalConfig = {},
) {
  const { defaultTitle, defaultTags } = config;

  const avatarSrc = useSelector(avatarSrcSelector);
  const postStatus: PostStatus = useSelector(postStatusSelector);
  const projectName = useSelector(projectNameSelector);
  const username = useSelector(usernameSelector);

  return {
    avatarSrc,
    postStatus,
    username,
    defaultTitle: formatTitle(defaultTitle || projectName),
    defaultTags,
  };
}
