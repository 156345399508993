import React, { FunctionComponent, useMemo } from 'react';

import {
  ActionStepCard,
  ActionStepCardEditButton,
} from 'components/ActionStepCard';
import { EmailOutput } from 'components/icons';
import { SubscriptionItem } from 'redux/middleware/api/podcast-service';

import { OutputOption } from 'redux/modules/edit-automation';
import AutomationAction from './AutomationAction';
import { ICON_BY_OUTPUT_OPTION_MAP, ICON_DIMENSION } from './constants';

interface EditAutomationOutputProps {
  editable: boolean;
  email: string;
  onEdit: () => void;
  outputOption: OutputOption;
  description?: string;
  subscriptionItem: SubscriptionItem;
}

const DESCRIPTION_PLACEHOLDER = '...';

const EditAutomationOutput: FunctionComponent<EditAutomationOutputProps> = props => {
  const {
    editable,
    email,
    onEdit,
    outputOption,
    description = DESCRIPTION_PLACEHOLDER,
    subscriptionItem,
  } = props;

  const descriptionText = outputOption === 'EMAIL' ? email : description;
  const image =
    outputOption === 'EMAIL' ? (
      <EmailOutput height={ICON_DIMENSION} width={ICON_DIMENSION} />
    ) : (
      ICON_BY_OUTPUT_OPTION_MAP[
        subscriptionItem.autoPostVideoPreference.platform
      ]
    );

  const renderActions = useMemo(() => {
    if (outputOption === 'EMAIL') {
      return () => <ActionStepCardEditButton onClickEdit={onEdit} />;
    }

    return () => (
      <AutomationAction
        output={outputOption}
        onClickChangeDestination={onEdit}
        subscriptionItem={subscriptionItem}
      />
    );
  }, [onEdit, outputOption, subscriptionItem]);

  return (
    <ActionStepCard
      description={descriptionText}
      image={image}
      modifiers={['ellipsized-description']}
      renderAction={editable ? renderActions : undefined}
      title="Output"
    />
  );
};

export default EditAutomationOutput;
