export const UPLOAD_AUDIO_REQUEST = 'app/recording-upload/UPLOAD_AUDIO_REQUEST';
export const UPLOAD_AUDIO_SUCCESS = 'app/recording-upload/UPLOAD_AUDIO_SUCCESS';
export const UPLOAD_AUDIO_FAILURE = 'app/recording-upload/UPLOAD_AUDIO_FAILURE';

export const UPLOAD_AWAIT_IMPORT_REQUEST =
  'app/recording-upload/UPLOAD_AWAIT_IMPORT_REQUEST';
export const UPLOAD_AWAIT_IMPORT_SUCCESS =
  'app/recording-upload/UPLOAD_AWAIT_IMPORT_SUCCESS';
export const UPLOAD_AWAIT_IMPORT_FAILURE =
  'app/recording-upload/UPLOAD_AWAIT_IMPORT_FAILURE';
