import {
  claimPodcastOwnership,
  getOwnedPodcasts,
} from 'redux/middleware/api/podcast-service/actions';
import { ThunkAction } from 'redux/types';

import { Type } from './action-types';

export const claimPodcastInitFlow = () => ({ type: Type.CLAIM_PODCAST_INIT });

export const claimPodcastRequest = (
  podcastId: string,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.CLAIM_PODCAST_REQUEST, payload: { podcastId } });

  try {
    const res = await dispatch(claimPodcastOwnership(podcastId));
    dispatch({
      type: Type.CLAIM_PODCAST_SUCCESS,
      payload: { claimResponse: res.response },
    });
  } catch (err) {
    dispatch({ type: Type.CLAIM_PODCAST_FAILURE });
    throw err;
  }
};

export const getOwnedPodcastsRequest = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  dispatch({ type: Type.GET_OWNED_PODCASTS_REQUEST });

  try {
    const res = await dispatch(getOwnedPodcasts());
    dispatch({
      type: Type.GET_OWNED_PODCASTS_SUCCESS,
      payload: { result: res.response.result },
    });
  } catch (err) {
    dispatch({ type: Type.GET_OWNED_PODCASTS_FAILURE });
  }
};
